import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'

export default class ComponentSelect extends Component {
    constructor(props) {
        super(props)
        this.change = this.change.bind(this);
        this.changeUnits = this.changeUnits.bind(this);
    }

    change(thisValue) {
        var thisUnit = this.props.unit;
        if (this.props.editableUnits) {
            this.props.editableUnits.forEach(u => {
                if (this.props.value.indexOf(u) > -1) {
                    thisUnit = u;
                }
            })
        }

        if (isNaN(thisValue) === true) {
            thisValue = this.props.value.replace(thisUnit, "");
        }
        thisValue = Number(thisValue);

        var finalVal = thisValue + thisUnit;
        if (thisUnit == "%" && thisValue > 100) {
            finalVal = "100" + thisUnit;
        }

        this.props.updateVal(this.props.path, finalVal);
    }

    changeUnits(event) {

        var nextType = "";
        var thisVal = this.props.value;
        var thisUnit = this.props.unit;
        if (this.props.editableUnits) {
            this.props.editableUnits.forEach(u => {
                if (thisVal.indexOf(u) > -1) {
                    thisUnit = u;
                }
            })
        }

        if (thisUnit == "auto") {
            thisVal = "100";
        }
        var nextVal = Number(thisVal.replace(thisUnit, ""));

        for (var x in this.props.editableUnits) {
            x = Number(x);
            if (this.props.editableUnits[x] === thisUnit) {
                if (x < this.props.editableUnits.length - 1) {
                    nextType = this.props.editableUnits[x + 1];
                    break;
                }
                else {
                    nextType = this.props.editableUnits[0];
                    break;
                }
            }
        }

        if (nextType == "auto") {
            nextVal = "";
        }

        if (nextType == "%" && nextVal > 100) {
            nextVal = 100;
        }
        nextVal = nextVal + nextType

        this.props.updateVal(this.props.path, nextVal);

    }
    render() {

        var thisVal = this.props.value;
        var thisUnit = this.props.unit;
        if (this.props.editableUnits) {
            this.props.editableUnits.forEach(u => {
                if (thisVal.indexOf(u) > -1) {
                    thisUnit = u;
                }
            })
        }
        var readOnly = true;
        var thisUnitTxt = thisUnit;
        if (thisUnit == "auto") {

            readOnly = true;
            thisUnitTxt = "Change"
        }
        if (thisUnit != "auto") {
            readOnly = false;
            thisVal = thisVal.replace(thisUnit, '')
        }
        var style = {};
        if (this.props.elementData.optionFullWidth) style = { flex: "100%" }

        let rightIcon = thisUnitTxt;
        if (this.props.editableUnits) rightIcon = <a onClick={this.changeUnits}>{thisUnitTxt}</a>;

        return <div className="dd_toolHolder" style={style}>
            <div className="dd_inputTool">
                <UILIB.TextInput
                    label={this.props.title}
                    value={thisVal}
                    className={this.props.className}
                    type="text"
                    onChange={(event) => { this.change(event.currentTarget.value) }}
                    readOnly={readOnly}
                    iconRight={rightIcon}
                />
            </div>

        </div>

    }
}
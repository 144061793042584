import React from 'react'
import moment from 'moment'
import globe from './images/facebook/facebook_globe.svg'
import menu from './images/facebook/facebook_menu.svg'
import like from './images/facebook/facebook_like.svg'
import comment from './images/facebook/facebook_comment.svg'
import share from './images/facebook/facebook_share.svg'
import write from './images/facebook/facebook_write.svg'

export default function SocialFacebookPreview({ account, startDate, content, images = [] }) {
    const now = moment(startDate)
    return (
        <div style={{ width: 480, borderRadius: 8, backgroundColor: 'white', fontFamily: 'inter', lineHeight: '13px' }}>
            {/* header */}
            <div style={{ padding: '12px 16px', display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: 8 }}>
                    <img style={{ height: 40, width: 40, borderRadius: '20px', alignSelf: 'stretch' }} src={account.image} />
                </div>
                <div style={{ flexGrow: 1 }}>
                    <div style={{ fontSize: 14, fontWeight: '600', color: 'black', letterSpacing: '0.20px', marginBottom: 7 }}>{account.name}</div>
                    <div style={{ display: 'flex', color: '#65676B', fontWeight: '400', fontSize: 13, flexDirection: 'row', alignItems: 'center', }}>{now.format('MMMM DD')} at {now.format('h:mm A')} <img style={{ marginLeft: 10 }} src={globe} /></div>
                </div>
                <div style={{ marginLeft: 8 }}>
                    <img src={menu} />
                </div>
            </div>

            {/* content */}
            <div style={{ padding: '0 16px 16px 16px', fontSize: '15px', fontWeight: '400', lineHeight: '20px', wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: content }}>
            </div>

            {/* images */}
            {!!images.length && <div>
                <img src={images[0].url || images[0]} style={{
                    backgroundColor: '#e0e0e0',
                    width: '100%',
                    position: 'relative',
                    justifyContent: 'center',
                }} />
            </div>}

            {/* footer */}
            <div style={{ padding: '0 16px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '4px 0', borderBottom: '1px #CFD0D4 solid' }}>
                    <img src={like} />
                    <img src={comment} />
                    <img src={share} />
                </div>
                <div style={{ padding: '8px 0', display: 'flex', alignItems: 'center' }}>
                    <img src={account.image} style={{ height: 32, width: 32, borderRadius: '20px', marginRight: 6 }} />
                    <img src={write} />
                </div>
            </div>
        </div>
    )
}
import React from 'react'

export default class SubNavHolder extends React.Component {
    constructor(props) {
        super(props);
        this.clickSubNav = this.clickSubNav.bind(this);
    }

    clickSubNav(event) {
        this.props.onChange(event, Number(event.target.id));
    }
    render() {
        var childrenWithProps = React.Children.map(this.props.children, (child, index) => {
            if (!child) return
            var isSelected = 0;
            var subNavId = index;
            if (child.props.subNavId !== undefined) {
                subNavId = child.props.subNavId;
            }
            if (subNavId == this.props.index) {
                isSelected = 1;
            }

            return React.cloneElement(child, {
                selected: isSelected,
                onClick: this.clickSubNav,
                subNavId: subNavId
            })

        });

        return <div className="subNavHolder m-mar-0">
            {childrenWithProps.map((theObj, index) => {
                return <span key={"subNavSpan" + index}>{theObj}</span>
            })}
        </div>
    }
}

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import queryString from 'query-string'
import axios from 'axios'
import DbConn from '~/Classes/siteVars.js'
import { loginAndGetToken } from '../../../data/actions/userActions'
import UILIB from '../../../Common-Objects/Lib'
import ReCaptcha from '../../../Common-Objects/Captcha'

export default function Soft2faAccept() {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const site = useSelector(state => state.siteMaster)
    const [error, setError] = useState(false)
    const [captchaToken, setCapatchaToken] = useState('')

    const checkToken = async (token) => {
        try {
            const res = await axios.post(DbConn.getConnString() + '/soft2faconfirm', {
                token,
                captchaToken
            }, { withCredentials: true })
            if (res.data.error) return setError('Invalid Link')
            dispatch(loginAndGetToken(null, null, res.data.token))
        } catch (e) {
            setError('Invalid Link')
        }
    }

    useEffect(() => {
        if (!site?.siteId) return
        const query = queryString.parse(location.search);
        if (!query.token) return setError('Invalid Link')
        if (!captchaToken && site.siteId === 1) return
        checkToken(query.token)
    }, [captchaToken, site])

    useEffect(() => {
        if (user.loggedIn) {
            const query = queryString.parse(location.search);
            let redirectUrl = query.redirect || '/cp'
            window.open(redirectUrl, '_self')
        }
    }, [user])

    return (
        <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {site.siteId === 1 && <ReCaptcha onComplete={r => setCapatchaToken(r)} />}
            <UILIB.Paper className="with_header" style={{ margin: '0 64px', minWidth: 400 }}>
                <div className="paper_header" style={{ backgroundColor: site.colours["$primary"], color: 'white' }}>
                    Validating Login
                </div>
                <div className="paper_content">
                    {!error && <div className="loading">Validating</div>}
                    {!!error && <div>{error}</div>}
                </div>
            </UILIB.Paper>
        </div>
    )
}
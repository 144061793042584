import i18n from '~/i18n'
import axios from '~/data/http/axios';

class SessionTools {

    static async checkSession(path) {
        try {

            let sessions = await axios.post('/accountMaster/usersOnSamePage', { path: path })
            if (sessions && sessions.data && sessions.data.count) {
                return true
            }
            return false;
        }
        catch (err) {
            return false;
        }
    }
}


export default SessionTools;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".on5bcLc88OfCo_9_J3if{display:flex;flex-direction:column;gap:24px;box-sizing:content-box;max-width:640px;padding:32px;margin:0 auto}.dEK9qxPbin5Dam7hLYSi{padding:80px 32px;gap:32px}.CSmb1RCKDIqJIOjIrDZJ{display:flex;flex-direction:column;gap:12px;margin:8px 0}.P59FL2b5ZP73StyujVmc{position:fixed;top:24px;left:24px}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/components/dialog/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,sBAAA,CACA,eAAA,CACA,YAAA,CACA,aAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,YAAA,CAGF,sBACE,cAAA,CACA,QAAA,CACA,SAAA","sourcesContent":[".contentInner {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  box-sizing: content-box;\n  max-width: 640px;\n  padding: 32px;\n  margin: 0 auto;\n}\n\n.contentInnerFullScreen {\n  padding: 80px 32px;\n  gap: 32px;\n}\n\n.header {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  margin: 8px 0;\n}\n\n.closeButton {\n  position: fixed;\n  top: 24px;\n  left: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentInner": "on5bcLc88OfCo_9_J3if",
	"contentInnerFullScreen": "dEK9qxPbin5Dam7hLYSi",
	"header": "CSmb1RCKDIqJIOjIrDZJ",
	"closeButton": "P59FL2b5ZP73StyujVmc"
};
export default ___CSS_LOADER_EXPORT___;

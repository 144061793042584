import React, { Component } from 'react'
import { DropTarget, DragSource } from 'react-dnd'
import ItemTypes from '../../../dragDropItemTypes'
import { findDOMNode } from 'react-dom'
import UILIB from '~/Common-Objects/Lib'

const dragSource = {
    beginDrag(props) {
        return { path: "", isNew: 0, content: props.content }
    },
    endDrag(props, monitor) {
    }
}

@DragSource(ItemTypes.NEWHOLDER, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
}))
export default class NewHolderObject extends Component {
    constructor(props) {
        super(props)

    }

    render() {

        const { canDrop, isOver, connectDragSource, isDragging } = this.props
        return connectDragSource(

            <div className="newElementObject" id={this.props.id} alt={this.props.title} title={this.props.title}>
                <img src={this.props.toolbarIcon} className="newElementObject-icon" />
                <div className="newElementObject-text">{this.props.title}</div>
            </div>
        )

    }
}
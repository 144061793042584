import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import EmailValidator from '~/Classes/emailValidator'
import i18n from '~/i18n'
import WysiwygEditor from '../../../templates/add/dragDrop/rightTools/elementEditor/components/wysiwyg'
import AutomationFunctions from '../automationFunctions'
import AutoMergeTags from '~/pages/cp/includes/mergeTags/campaignMergeTags';
import DesignerMergeTags from '~/pages/cp/includes/mergeTags/mergeTags';
import HelpPopup from '../helpPopup/helpPopup';
@connect((store) => {
    return { siteMaster: store.siteMaster }
})
class DrawerStepNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerContent: undefined,
            step: this.props.step,
            errors: {},
            showAdvanced: false,
            advancedLoading: true,
            trackingDomains: [],
            cdnDomains: [],
            hasWorkflowEmails: false
        }

        this.updateValue = this.updateValue.bind(this);
        this.saveStep = this.saveStep.bind(this);
        this.updateFromAddress = this.updateFromAddress.bind(this)
        this.updateToAddress = this.updateToAddress.bind(this);
        this.addToAddress = this.addToAddress.bind(this);
        this.removeToAddress = this.removeToAddress.bind(this);
        this.updateContent = this.updateContent.bind(this);

        this.openMergeTags = this.openMergeTags.bind(this);
        this.closeMergeTags = this.closeMergeTags.bind(this);
        this.insertMergeTag = this.insertMergeTag.bind(this);
    }

    componentDidMount() {
        var workflowEmails = AutomationFunctions.getWorkflowEmails(this.props.step.guid, this.props.steps);
        var hasWorkflowEmails = false;
        var initialStepTriggerType = this.props.steps[0].triggerType.value;
        if (workflowEmails.length || (initialStepTriggerType == "5" || initialStepTriggerType == "8" || initialStepTriggerType == "9" || initialStepTriggerType == "10" || initialStepTriggerType == "11")) hasWorkflowEmails = true
        this.setState({ hasWorkflowEmails })
    }

    updateValue(event) {
        var step = this.state.step;
        step.stepOptions[event.target.name].value = event.target.value;
        step.complete = false;
        this.setState(step)
    }

    updateFromAddress(event) {
        var step = this.state.step;
        var options = step.stepOptions;
        options.fromAddress.value = event.target.value
        options.replyAddress.value = event.target.value
        this.setState({
            step
        })

    }
    updateToAddress(value, index) {
        var step = this.state.step;
        var toAddresses = step.stepOptions.toAddresses
        toAddresses[index].value = value;
        this.setState({
            step
        })
    }
    addToAddress() {
        var step = this.state.step;
        var toAddresses = step.stepOptions.toAddresses
        toAddresses.push({ "value": "", "required": true })
        this.setState({
            step
        })
    }
    removeToAddress(index) {
        var step = this.state.step;
        var toAddresses = step.stepOptions.toAddresses
        toAddresses.splice(index, 1)
        this.setState({
            step
        })
    }
    updateContent(notUsed, theValue) {
        var step = this.state.step;
        step.stepOptions.content.value = theValue
        this.setState({
            step
        })
    }
    saveStep(createEditTemplate) {
        var errors = {};
        var error = false;
        var step = this.state.step;

        var promises = [];

        if (!step.stepOptions.subject.value || step.stepOptions.subject.value.length < 3) {
            errors.subject = i18n.t('automation:add.notification.errSubject');
            error = true;
        }
        if (this.props.isThemeDesigner != true) {
            if (!step.stepOptions.fromName.value || step.stepOptions.fromName.value.length < 3) {
                errors.fromName = i18n.t('automation:add.notification.errName');
                error = true;
            }
            if (!step.stepOptions.fromAddress.value || !EmailValidator.validateEmail(step.stepOptions.fromAddress.value)) {
                errors.fromAddress = i18n.t('automation:add.notification.errAddress');
                error = true;
            }
            else {
                promises.push(EmailValidator.checkEmailAuthed(step.stepOptions.fromAddress.value).then(res => {

                    if (res.error || !res.domainExists || !res.domainVerified || (this.props.siteMaster.usersMustBeAuthenticated && !res.domainAuthenticated)) {
                        errors.fromAddress = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.notification.errAuth') }}></span>;
                        error = true;
                    }

                }));
            }
        }
        if (!step.stepOptions.content.value || step.stepOptions.content.value.length < 3) {
            errors.content = i18n.t('automation:add.notification.errContent')
            error = true
        }

        Promise.all(promises).then(errs => {

            this.setState({ errors })

            if (!error) {
                step.complete = true;
                step.wizardComplete = true;
                this.props.saveStep();
            }

        });
    }

    openMergeTags(fieldName) {
        var drawerContent = <AutoMergeTags hasWorkflowEmails={false} includeSubscriber={false} includeSubCustomFields={true} close={this.closeMergeTags} insertMergeTag={this.insertMergeTag} automationId={this.props.automationData.id} fieldName={fieldName} />
        if (this.props.isThemeDesigner) {
            drawerContent = <DesignerMergeTags
                fromDesigner={true}
                close={this.closeMergeTags}
                onTagClick={(value) => { this.insertMergeTag(value, fieldName) }} filter={["1"]} />
        }
        this.setState({ drawerContent })
    }

    closeMergeTags() {
        this.setState({ drawerContent: undefined })
    }

    insertMergeTag(value, fieldName) {
        this.props.step.stepOptions[fieldName].value += " " + value;
        this.setState({ drawerContent: undefined })
    }

    render() {
        const { readOnly } = this.props;

        if (this.state.drawerContent) return this.state.drawerContent;

        const inputProps = {
            disabled: readOnly
        };

        return <div data-test="drawer-notification-step">
            <div className="automationStepContentHolder">
                <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="mar-b25">{i18n.t('automation:add.notification.header')}</h4>
                    <HelpPopup type={"notification"} />
                </div>
                <div className="mar-b25">{i18n.t('automation:add.notification.subheader')}</div>
                <UILIB.TextInput
                    outerClassName="mar-b25"
                    label={i18n.t('automation:add.notification.subject')}
                    labelOptions={this.props.isThemeDesigner ? <button className="a" onClick={() => this.openMergeTags("subject")} {...inputProps}>Designer Merge Tags</button> : ""}
                    name="subject"
                    onChange={this.updateValue}
                    value={this.state.step.stepOptions.subject.value}
                    placeholder={i18n.t('automation:add.notification.subjectPlace')}
                    error={this.state.errors.subject}
                    type="text"
                    {...inputProps}
                />

                <div className="quickFlex">
                    <div style={{ flex: "1" }}>
                        <UILIB.TextInput
                            outerClassName="mar-b25 mar-r10"
                            label={i18n.t('automation:add.notification.from')}
                            labelOptions={this.props.isThemeDesigner ? <button className="a" onClick={() => this.openMergeTags("fromName")} {...inputProps}>Designer Merge Tags</button> : ""}
                            name="fromName"
                            onChange={this.updateValue}
                            value={this.state.step.stepOptions.fromName.value}
                            placeholder={i18n.t('automation:add.notification.fromPlace')}
                            error={this.state.errors.fromName}
                            type="text"
                            {...inputProps}
                        />
                    </div>
                    <div style={{ flex: "1" }}>
                        <UILIB.TextInput
                            outerClassName="mar-b25"
                            label={i18n.t('automation:add.notification.fromA')}
                            labelOptions={this.props.isThemeDesigner ? <button className="a" onClick={() => this.openMergeTags("fromAddress")} {...inputProps}>Designer Merge Tags</button> : ""}
                            name="fromAddress"
                            onChange={this.updateFromAddress}
                            value={this.state.step.stepOptions.fromAddress.value}
                            placeholder={i18n.t('automation:add.notification.fromAPlace')}
                            error={this.state.errors.fromAddress}
                            type="email"
                            {...inputProps}
                        />
                    </div>
                </div>


                <div className="txtInput_label">{i18n.t('automation:add.notification.toA')}</div>
                {this.state.step.stepOptions.toAddresses.map((toAddress, index) => {
                    return <UILIB.TextInput
                        className="mar-b10"
                        key={"recipient_" + index}
                        name="recipient"
                        onChange={(event) => { this.updateToAddress(event.target.value, index) }}
                        value={toAddress.value}
                        placeholder={i18n.t('automation:add.notification.toAPlace')}
                        error={this.state.errors.fromAddress}
                        type="email"
                        iconRight={index > 0 ? <UILIB.Icons icon="bin" onClick={() => { this.removeToAddress(index) }} /> : ""}
                        {...inputProps}
                    />
                })}
                {this.state.step.stepOptions.toAddresses.length < 5 &&
                    <UILIB.Button
                        text={i18n.t('templates:preview.add')}
                        onClick={this.addToAddress}
                        iconLeft={<UILIB.Icons icon="plus" />}
                        className="mar-b25"
                        {...inputProps}
                    />
                }

                {!readOnly && (
                    <>
                        <div className="txtInput_label">{i18n.t('automation:add.notification.content')}</div>
                        <WysiwygEditor updateVal={this.updateContent} additionalTools={["campaignMergeTags", "mpzLinkPicker", "unlink"]} AutomationId={this.props.automationData.id} hasWorkflowEmails={this.state.hasWorkflowEmails} wysiwygMode="simple" wysiwygHeight="200px" standalone={true} error={this.state.errors.content} value={this.state.step.stepOptions.content.value} {...inputProps} />
                    </>
                )}
            </div>

            <div className="automationStepSaveButtonHolder">
                {readOnly ? (
                    <>
                        <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                    </>
                ) : (
                    <UILIB.Button className="button-primary" text={i18n.t('save')} onClick={this.saveStep} />
                )}
            </div>
        </div>
    }
}

export default DrawerStepNotification;
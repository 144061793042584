import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import axios from "~/data/http/axios"
import UILIB from "~/Common-Objects/Lib"
import NumberFormatFunctions from "~/Classes/numberFormatFunctions"
import Sends from './sends'
import Opens from './opens'
import Clicks from './clicks'
import Unsubs from './unsubs'
import i18n from '~/i18n'

export default function Resends({ campaign }) {
    const site = useSelector(state => state.siteMaster)
    const [stats, setStats] = useState(null)
    const [view, setView] = useState('sends')

    useEffect(() => {
        axios.get('/campaign/' + campaign.id + '/stats/resent').then(res => {
            setStats(res.data)
        })
    }, [campaign])

    const colours = site.colours;

    if (!stats) return <UILIB.LoadingIcons iconType="2" />
    return (
        <div>
            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
                    <UILIB.Paper>
                        <UILIB.Row className="mar-b10">
                            <UILIB.Column xs={12} margin={0}>
                                <p className=" text-xsml uppercase no-marg">{i18n.t('campaigns:resend.main.sent')}</p>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row className="mar-b5">
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                <div className="quickFlex">
                                    <p className=" text-mxl no-marg">{NumberFormatFunctions.formatNumber(stats.resends) || 0}</p>
                                </div>
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
                    <UILIB.Paper className="dashHeight">
                        <UILIB.Row className="mar-b10">
                            <UILIB.Column xs={12} margin={0}>
                                <p className="text-secondary text-xsml uppercase no-marg">{i18n.t('campaigns:resend.main.opened')}</p>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row className="mar-b5">
                            <UILIB.Column xs={12} sm={12} md={12} lg={12}>
                                <div className="quickFlex">
                                    <p className="text-primary text-mxl no-marg">{NumberFormatFunctions.formatNumber(stats.opens) || 0}</p>
                                    <p className="text-grey text-sml no-marg pad-left">/ {NumberFormatFunctions.formatNumber(stats.resends) || 0}</p>
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                <UILIB.SmallLineGraph width="100%" height="4" textAlign={"end-xs text-grey"} hideText={false} onlyPercent={true} val1={stats.opens || 0} val2={stats.resends || 0} />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
                    <UILIB.Paper className="dashHeight">
                        <UILIB.Row className="mar-b10">
                            <UILIB.Column xs={12} margin={0}>
                                <p className="text-secondary text-xsml uppercase no-marg">{i18n.t('campaigns:resend.main.clicked')}</p>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row className="mar-b5">
                            <UILIB.Column xs={12} sm={12} md={12} lg={12}>
                                <div className="quickFlex">
                                    <p className="text-primary text-mxl no-marg">{NumberFormatFunctions.formatNumber(stats.clicks) || 0}</p>
                                    <p className="text-grey text-sml no-marg pad-left">/ {NumberFormatFunctions.formatNumber(stats.resends) || 0}</p>
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                <UILIB.SmallLineGraph width="100%" height="4" textAlign={"end-xs text-grey"} hideText={false} onlyPercent={true} val1={stats.clicks || 0} val2={stats.resends || 0} />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
                    <UILIB.Paper>
                        <UILIB.Row className="mar-b10">
                            <UILIB.Column xs={12} margin={0}>
                                <p className="text-xsml uppercase no-marg">{i18n.t('campaigns:resend.main.unsubs')}</p>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row className="mar-b5">
                            <UILIB.Column xs={12} sm={12} md={12} lg={12}>
                                <div className="quickFlex">
                                    <p className="ttext-mxl no-marg">{NumberFormatFunctions.formatNumber(stats.unsubscribes) || 0}</p>
                                    <p className="text-sml  no-marg pad-left">/ {NumberFormatFunctions.formatNumber(stats.resends) || 0}</p>
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                <UILIB.SmallLineGraph width="100%" height="4" textAlign={"end-xs"} hideText={false} color={colours.$primary} onlyPercent={true} val1={stats.unsubscribes || 0} val2={stats.resends || 0} />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row className="hide-xs hide-sm">
                <UILIB.Column xs={12} className="center-xs">
                    <div className={"subNavChip " + (view === 'sends' ? 'subNavSelected' : '')} onClick={() => setView('sends')}>{i18n.t('campaigns:resend.sends.nav')}</div>
                    <div className={"subNavChip " + (view === 'opens' ? 'subNavSelected' : '')} onClick={() => setView('opens')}>{i18n.t('campaigns:resend.opens.nav')}</div>
                    <div className={"subNavChip " + (view === 'clicks' ? 'subNavSelected' : '')} onClick={() => setView('clicks')}>{i18n.t('campaigns:resend.clicks.nav')}</div>
                    <div className={"subNavChip " + (view === 'unsubs' ? 'subNavSelected' : '')} onClick={() => setView('unsubs')}>{i18n.t('campaigns:resend.unsubs.nav')}</div>
                </UILIB.Column>
                <UILIB.Column xs={12}>
                    {view === 'sends' && <Sends campaign={campaign} />}
                    {view === 'opens' && <Opens campaign={campaign} />}
                    {view === 'clicks' && <Clicks campaign={campaign} />}
                    {view === 'unsubs' && <Unsubs campaign={campaign} />}
                </UILIB.Column>
            </UILIB.Row>
        </div>
    )
}
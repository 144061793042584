import React from "react";
import { Route, Switch, useParams, useRouteMatch, Redirect } from "react-router-dom";
import {
  emailChannelTabOptions,
  webchatChannelTabOptions,
  facebookMessengerChannelTabOptions
} from "~/Classes/conversations";
import UILIB from "~/Common-Objects/Lib";
import i18n from "~/i18n";
import SettingsLayout from "../../components/settingsLayout";
import SettingsNavItem from "../../components/settingsNavItem";
import Button from "../../components/button";
import Breadcrumbs from "../../components/breadcrumbs";
import WebchatChannelGeneral from "../webchat/general";
import WebchatChannelWebsites from "../webchat/websites";
import WebchatChannelDesign from "../webchat/design";
import WebchatChannelVisibility from "../webchat/visibility";
import WebchatChannelAgentAvailability from "../webchat/agentAvailability";
import WebchatChannelAvailabilityMessages from "../webchat/availabilityMessages";
import WebchatChannelWelcomeMessages from "../webchat/welcomeMessages";
import WebchatChannelPrompts from "../webchat/prompts";
import EmailChannelGeneral from "../email/general";
import EmailChannelForwarding from "../email/forwarding";
import EmailChannelSignature from "../email/signature";
import FacebookMessengerChannelGeneral from "../facebookMessenger/general";
import FacebookMessengerChannelWelcomeMessages from "../facebookMessenger/welcomeMessages";
import FacebookMessengerChannelVisibility from "../facebookMessenger/visibility";

export default function ChannelTemplate({
  inbox,
  channel,
  onUpdateChannel,
  ...rest
}) {
  const { path, url } = useRouteMatch();
  const { inboxId, settingName } = useParams();

  function getTabs() {
    switch (channel.type) {
      case 1:
        return emailChannelTabOptions;
      case 2:
        return webchatChannelTabOptions;
      case 3:
        return facebookMessengerChannelTabOptions;
      default:
        return [];
    }
  }

  const tabs = getTabs();
  const currentTab = tabs.find((tab) => tab.value === settingName);

  const pathWithoutSettingName = path.slice(0, path.lastIndexOf("/"));
  const urlWithoutSettingName = url.slice(0, url.lastIndexOf("/"));

  const commonProps = { inbox, channel, onUpdateChannel, ...rest };

  return (
    <SettingsLayout
      heading={i18n.t(currentTab?.label)}
      subheading={currentTab?.subheading}
      view={currentTab?.view}
      backButton={
        <Button
          to={`/cp/conversations/${inboxId}/channels`}
          variant="secondary"
          size="s"
          iconLeft={<UILIB.Icon name="chevron-left" />}
        >
          {i18n.t("chat:channel.backButton")}
        </Button>
      }
      sideNavList={
        <ul>
          {tabs.map(({ label, value }) => {
            return (
              <li key={value}>
                <SettingsNavItem
                  to={`${urlWithoutSettingName}/${value}`}
                  selected={settingName === value}
                >
                  {i18n.t(label)}
                </SettingsNavItem>
              </li>
            );
          })}
        </ul>
      }
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumbs.Item to={`/cp/conversations/${inboxId}/general`}>
            <UILIB.Icon name="inbox" />
            {inbox?.name}
          </Breadcrumbs.Item>

          <Breadcrumbs.Item to={`/cp/conversations/${inboxId}/channels`}>
            <UILIB.Icon name="stack" />
            {i18n.t("chat:settings.nav.channels")}
          </Breadcrumbs.Item>

          <Breadcrumbs.Item to={urlWithoutSettingName}>
            <UILIB.Icon name={parseChannelTypeIcon(channel?.type)} style={{ height: 20, width: 20 }} />
            {channel?.name}
          </Breadcrumbs.Item>

          <Breadcrumbs.Item>{i18n.t(currentTab?.label)}</Breadcrumbs.Item>
        </Breadcrumbs>
      }
    >
      <Switch>
        {channel.type === 1 && (
          <>
            <Route exact path={`${pathWithoutSettingName}/general`}>
              <EmailChannelGeneral {...commonProps} />
            </Route>
            <Route exact path={`${pathWithoutSettingName}/forwarding`}>
              <EmailChannelForwarding {...commonProps} />
            </Route>
            <Route exact path={`${pathWithoutSettingName}/signature`}>
              <EmailChannelSignature {...commonProps} />
            </Route>
          </>
        )}

        {channel.type === 2 && (
          <>
            <Route exact path={`${pathWithoutSettingName}/general`}>
              <WebchatChannelGeneral {...commonProps} />
            </Route>
            <Route exact path={`${pathWithoutSettingName}/websites`}>
              <WebchatChannelWebsites {...commonProps} />
            </Route>

            <Route exact path={`${pathWithoutSettingName}/design`}>
              <WebchatChannelDesign {...commonProps} />
            </Route>

            <Route exact path={`${pathWithoutSettingName}/visibility`}>
              <WebchatChannelVisibility {...commonProps} />
            </Route>

            <Route exact path={`${pathWithoutSettingName}/agent-availability`}>
              <WebchatChannelAgentAvailability {...commonProps} />
            </Route>

            <Route exact path={`${pathWithoutSettingName}/availability-messages`}>
              <WebchatChannelAvailabilityMessages {...commonProps} />
            </Route>

            <Route exact path={`${pathWithoutSettingName}/welcome-messages`}>
              <WebchatChannelWelcomeMessages {...commonProps} />
            </Route>

            <Route exact path={`${pathWithoutSettingName}/prompts`}>
              <WebchatChannelPrompts {...commonProps} />
            </Route>
          </>
        )}

        {channel.type === 3 && (
          <>
            <Route exact path={`${pathWithoutSettingName}/general`}>
              <FacebookMessengerChannelGeneral {...commonProps} />
            </Route>
            <Route exact path={`${pathWithoutSettingName}/welcome-messages`}>
              <FacebookMessengerChannelWelcomeMessages {...commonProps} />
            </Route>
            <Route exact path={`${pathWithoutSettingName}/visibility`}>
              <FacebookMessengerChannelVisibility {...commonProps} />
            </Route>
          </>
        )}
      </Switch>
    </SettingsLayout>
  );
}

function parseChannelTypeIcon(type) {
  switch (type) {
    case 1:
      return "mail-open";
    case 2:
      return "chat";
    case 3:
      return "facebook";
  }
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import EmailTools from '~/Classes/emailValidator';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import i18n from '~/i18n';
import Setup2fa from './2fa'
import NewUserForm from '~/pages/cp/includes/bb_topNav/newUserForm/newUserForm'
import ChangeEmailPop from './changeEmailPop';
import Sessions from './sessions'
// import TranspondSiteTracking from '~/Classes/siteTrackingHelpers';
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

export default class AccountDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoaded: false,
            alertBar: null,
            pageLoaded: false,
            saving: false,
            avatarUploadProgress: 0,
            accountUserData: {},
            timeZoneData: {},
            alertMessage: '',
            alertOpen: false,
            passwords: {
                password: "",
                password1: "",
                password2: ""
            },
            inputErrors: {
                firstName: "",
                lastName: "",
                emailAddress: "",
                password1: "",
                password2: ""
            }
        };
        this.loadMe = this.loadMe.bind(this);
        this.clickAvatarUpload = this.clickAvatarUpload.bind(this);
        this.goAvatarUpload = this.goAvatarUpload.bind(this);
        this.goAvatarUploadProgress = this.goAvatarUploadProgress.bind(this);
        this.updateInput = this.updateInput.bind(this);
        this.saveAccountDetails = this.saveAccountDetails.bind(this);
        this.updatePasswordInput = this.updatePasswordInput.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.save2fa = this.save2fa.bind(this);
        this.saveYourDetails = this.saveYourDetails.bind(this);
        this.saveSettings = this.saveSettings.bind(this);
        this.activateAccount = this.activateAccount.bind(this);
    }

    componentDidMount() {
        this.loadMe()
        // TranspondSiteTracking.manualPageChange(null, null, null, null, null, "Payment", { value: 2 })
        // TranspondSiteTracking.manualPageChange(null, null, null, null, null, "DaveANugget", { hasDogs: true, likesGirls: false, livesNearMe: "YES" })
    }

    activateAccount() {
        var theDrawer = <NewUserForm from="account" closeNewUserForm={() => { }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, false, "100vw", 'full'));
    }

    //Account stuff
    loadMe() {
        axios
            .get('/accountUser/me')
            .then((res) => {

                var accountUserData = res.data.AccountUserData;
                accountUserData.createdAtNew = DateTimeFunctions.formatDateTime(accountUserData.createdAt, 2);
                accountUserData.createdAtDiff = DateTimeFunctions.timeBetween(accountUserData.createdAt);

                //get timezones
                var timeZones = DateTimeFunctions.getTimeZones();
                this.setState({ accountUserData: res.data.AccountUserData, timeZoneData: timeZones, pageLoaded: true });
            });
    }
    //avatar stuff
    clickAvatarUpload() {
        this.refs.avatarUploadInput.click();
    }
    goAvatarUpload(event) {

        var self = this;
        self.setState({ alertOpen: true, alertMessage: i18n.t('account:my.uploadingImage') })
        var thisFile = event.currentTarget.files[0];
        let data = new FormData();
        data.append('file', thisFile);
        axios.post('/accountuser/avatar', data, {
            onUploadProgress: progressEvent => self.goAvatarUploadProgress(progressEvent)
        })
            .then(function (res) {
                self.setState({ alertOpen: true, alertMessage: i18n.t('account:my.uploadingImage') })
                self.setState({ avatarUploadProgress: 0, alertOpen: true, alertMessage: i18n.t('account:my.uploadedImage') }, self.loadMe)
            })
            .catch(error => {
                self.setState({ avatarUploadProgress: 0, alertOpen: true, alertMessage: i18n.t('account:my.uploadError') })
            })

    }
    goAvatarUploadProgress(uploadEvent) {
        var loaded = uploadEvent.loaded;
        var total = uploadEvent.total;
        var totalLoaded = Math.round((loaded / total) * 100);
        this.setState({ avatarUploadProgress: totalLoaded });
    }
    //inputs and stuff
    updateInput(event) {
        let value = event.currentTarget.value;
        if (event.target.type && event.target.type == "checkbox") {
            value = event.target.checked;
        }

        var userData = this.state.accountUserData;
        userData[event.currentTarget.name] = value;
        console.log(value, event.currentTarget.name)
        this.setState({ accountUserData: userData });
    }
    updatePasswordInput(event) {
        var passwordData = this.state.passwords;
        passwordData[event.currentTarget.name] = event.currentTarget.value;
        this.setState({ passwords: passwordData });
    }

    saveYourDetails() {
        let accountUserData = this.state.accountUserData;
        let userDataNew = {
            firstName: accountUserData.firstName,
            lastName: accountUserData.lastName,
            emailAddress: accountUserData.emailAddress
        }

        var errorState = this.state.inputErrors;
        errorState.firstName = "";
        errorState.lastName = "";
        errorState.emailAddress = "";

        var failed = 0;
        if (userDataNew.firstName.length < 1) {
            errorState.firstName = i18n.t('account:my.errorFirstName');
            failed = 1;
        }
        if (userDataNew.lastName.length < 1) {
            errorState.lastName = i18n.t('account:my.errorFirstName');
            failed = 1;
        }
        if (EmailTools.validateEmail(userDataNew.emailAddress) === false) {
            errorState.emailAddress = i18n.t('account:my.errorEmail');
            failed = 1;
        }

        if (failed) {
            this.setState({ inputErrors: errorState });
            return;
        }

        if (userDataNew.emailAddress != this.props.user.userData.emailAddress) {
            //changed email address
            var drawerContent = <ChangeEmailPop newEmail={userDataNew.emailAddress} confirm={() => { this.saveAccountDetails(userDataNew) }} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "600px"));
            return;
        }
        this.saveAccountDetails(userDataNew);

    }
    saveSettings() {
        let accountUserData = this.state.accountUserData;
        let userDataNew = {
            language: accountUserData.language,
            timeZone: accountUserData.timeZone,
            dateFormat: accountUserData.dateFormat
        }
        i18n.changeLanguage(userDataNew.language)
        this.saveAccountDetails(userDataNew);
    }
    save2fa(event) {
        let value = event.currentTarget.value;
        if (event.target.type && event.target.type == "checkbox") {
            value = event.target.checked;
        }
        let userDataNew = {
            twoFAEnabled: value
        };
        let accountUserData = this.state.accountUserData;
        accountUserData.twoFAEnabled = value;
        this.setState({ accountUserData: accountUserData }, () => { this.saveAccountDetails(userDataNew) });
    }

    saveAccountDetails(fieldsToSave) {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        this.setState({ alertMessage: "Saving Details", alertOpen: true, saving: true })
        axios.put('/accountuser', (fieldsToSave)).then((res) => {
            this.setState({ alertMessage: "Details Saved", alertOpen: true, saving: false }, this.loadMe)
        }).catch(error => {
            var errorTxt = error;
            if (error.data && error.data.error) errorTxt = error.data.error;
            this.setState({ alertMessage: "Error: " + errorTxt, alertOpen: true, saving: false })
        });
    }

    //password changing
    changePassword() {
        var self = this;

        var pass = this.state.passwords.password;
        var pass1 = this.state.passwords.password1;
        var pass2 = this.state.passwords.password2;
        var inputErrors = this.state.inputErrors;
        var failed = 0;

        inputErrors.password1 = "";
        inputErrors.password2 = "";
        inputErrors.password = "";

        if (pass1 != pass2) {
            inputErrors.password1 = i18n.t('account:my.errorPasswordMatch');
            inputErrors.password2 = i18n.t('account:my.errorPasswordMatch');
            this.setState({ inputErrors: inputErrors });
            return;
        }
        self.setState({ alertMessage: i18n.t('account:my.savingPassword'), alertOpen: true })
        var data = { password: pass1, currentPassword: pass };
        axios.put('/accountuser', data)
            .then(function (res) {
                self.setState({
                    alertMessage: i18n.t('account:my.savedPassword'),
                    alertOpen: true,
                    passwords: { password1: '', password2: '', password: '' }
                }, self.loadMe)
            })
            .catch(error => {
                if (error.status == "400") {
                    if (error.data.error === 'Current Password Invalid') {
                        inputErrors.password = error.data.error;
                    } else {
                        inputErrors.password1 = error.data.error;
                    }
                    self.setState({ inputErrors: inputErrors, alertBar: null })
                }
                else {
                    self.setState({ alertMessage: i18n.t('account:my.savePasswordError'), alertOpen: true })
                }
            })

    }


    render() {

        const languages = [
            { label: 'English', value: 'en' },
            { label: 'Finnish', value: 'fi' },
            { label: 'French', value: 'fr' },
            { label: 'German', value: 'de' },
            { label: 'Portuguese', value: 'pt' },
            { label: 'Spanish', value: 'es' },
        ];
        const dateFormats = ['DD/MM/YYYY', "MM/DD/YYYY", "DD.MM.YYYY", "MM.DD.YYYY"];
        if (this.state.pageLoaded === false) {
            return <div><UILIB.LoadingIcons iconType="2" /></div>
        }
        else {

            var dateJoinedDisplay = i18n.t('account:my.dateJoinedDisplay');
            if (dateJoinedDisplay && dateJoinedDisplay.length && dateJoinedDisplay != "dateJoinedDisplay") {
                dateJoinedDisplay = dateJoinedDisplay.replace(/\[createdDate\]/g, this.state.accountUserData.createdAtNew);
                dateJoinedDisplay = dateJoinedDisplay.replace(/\[timeSinceCreated\]/g, this.state.accountUserData.createdAtDiff)
            }
            else {
                dateJoinedDisplay = this.state.accountUserData.createdAtNew + ' (' + this.state.accountUserData.createdAtDiff + ')';
            }
            var totalLoginsText = i18n.t('account:my.totalLoginsText');
            if (totalLoginsText && totalLoginsText.length && totalLoginsText != "totalLoginsText") {
                totalLoginsText = totalLoginsText.replace(/\[loginCount\]/g, this.state.accountUserData.loginCount)
            }

            return <div>

                {(!this.props.accountMaster.accountMaster.approved && !this.props.accountMaster.accountMaster.doneNewUserQuestions) && <UILIB.Paper className="paper-orange quickFlex" style={{ padding: "15px 20px", justifyContent: "space-between", alignItems: "center" }}>
                    <h4 className="mar-b0 mar-t0">Your account requires approval.</h4> <UILIB.Button text={"Request Approval"} onClick={() => { this.activateAccount() }} />
                </UILIB.Paper>}
                {((!this.props.accountMaster.accountMaster.approved && this.props.accountMaster.accountMaster.doneNewUserQuestions)) && <UILIB.Paper className="paper-orange quickFlex" style={{ padding: "15px 20px", justifyContent: "space-between", alignItems: "center" }}>
                    <h4 className="mar-b0 mar-t0">Your account is being reviewed.</h4>
                </UILIB.Paper>}
                <UILIB.Row>
                    <UILIB.Column xs={12} md={6} >
                        <UILIB.Paper>
                            <div style={{ display: "flex", justifyContent: "space-between" }} className="mar-b25">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <UILIB.Avatar style={{ height: "80px", width: "80px" }} className="mar-r15" src={this.state.accountUserData.profilePicFileName} uploadProgress={this.state.avatarUploadProgress}></UILIB.Avatar>
                                    <div className='quickFlex'>
                                        <UILIB.Button iconLeft={<UILIB.Icons icon="upload" />} onClick={this.clickAvatarUpload}>{i18n.t('company:my.uploadImage')}</UILIB.Button>
                                        <div className='text-sml text-heavy text-grey mar-l10'>max size 2mb</div>
                                        <input type="file" ref="avatarUploadInput" style={{ display: 'none' }} onChange={this.goAvatarUpload} />
                                    </div>
                                </div>

                            </div>
                            <UILIB.Row>
                                <UILIB.Column xs={12} md={6} className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('account:my.firstName')} type="text" placeholder={i18n.t('account:my.firstName')} name="firstName" error={this.state.inputErrors.firstName} value={this.state.accountUserData.firstName} onChange={this.updateInput} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} md={6} className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('account:my.lastName')} type="text" placeholder={i18n.t('account:my.lastName')} name="lastName" error={this.state.inputErrors.lastName} value={this.state.accountUserData.lastName} onChange={this.updateInput} />
                                </UILIB.Column>

                                {!this.props.siteMaster.hideChangePassword && <UILIB.Column xs="12" className="mar-b25">
                                    <UILIB.TextInput
                                        label={i18n.t('emailAddress')}
                                        type="email"
                                        placeholder={i18n.t('emailAddressPlaceholder')}
                                        explainer={!this.props.accountMaster.accountMaster.approved ? 'You cannot change your email address until we have approved your account' : ''}
                                        disabled={!this.props.accountMaster.accountMaster.approved}
                                        name="emailAddress"
                                        error={this.state.inputErrors.emailAddress}
                                        value={this.state.accountUserData.emailAddress}
                                        onChange={this.updateInput} />
                                </UILIB.Column>}

                                <UILIB.Column xs={12}>
                                    <UILIB.Button saving={this.state.saving} className="button-primary" onClick={this.saveYourDetails}>{i18n.t('account:my.saveDetails')}</UILIB.Button>
                                </UILIB.Column>

                            </UILIB.Row>
                        </UILIB.Paper>

                        {!this.props.siteMaster.hideChangePassword && <UILIB.Paper>
                            <h4 className="mar-b25">{i18n.t('account:my.header2')}</h4>
                            <UILIB.Row>
                                <UILIB.Column xs={12} md={4} className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('account:my.currentPassword')} type="password" placeholder={i18n.t('account:my.currentPasswordPlaceholder')} name="password" error={this.state.inputErrors.password} value={this.state.passwords.password} onChange={this.updatePasswordInput} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} md={4} className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('account:my.newPassword')} type="password" autocomplete="new-password" placeholder={i18n.t('account:my.newPasswordPlaceholder')} name="password1" error={this.state.inputErrors.password1} value={this.state.passwords.password1} onChange={this.updatePasswordInput} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} md={4} className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('account:my.confirmPassword')} type="password" autocomplete="new-password" placeholder={i18n.t('account:my.confirmPasswordPlaceholder')} name="password2" error={this.state.inputErrors.password2} value={this.state.passwords.password2} onChange={this.updatePasswordInput} />
                                </UILIB.Column>
                                <UILIB.Column xs={12}>
                                    <UILIB.Button saving={this.state.saving} className="button-primary" onClick={this.changePassword}>{i18n.t('account:my.updatePassword')}</UILIB.Button>
                                </UILIB.Column>
                            </UILIB.Row>
                        </UILIB.Paper>}

                        {!this.props.siteMaster.hideChangePassword && <Setup2fa accountUserData={this.state.accountUserData} reload={this.loadMe} />}

                    </UILIB.Column>

                    <UILIB.Column xs={12} md={6}>
                        <UILIB.Paper >
                            <h4 className="mar-b25">{i18n.t('account:my.header3')}</h4>
                            <UILIB.Row>
                                <UILIB.Column xs={12} md={6} className="mar-b25">
                                    <div className="txtInput_label">{i18n.t('account:my.dateJoined')}</div>
                                    {dateJoinedDisplay}
                                </UILIB.Column>
                                <UILIB.Column xs={12} md={6} className="mar-b25">
                                    <div className="txtInput_label">{i18n.t('account:my.totalLogins')}</div>
                                    {totalLoginsText}
                                </UILIB.Column>
                            </UILIB.Row>
                        </UILIB.Paper>

                        <UILIB.Paper className="">
                            <h4 className="mar-b25">{i18n.t('account:my.header4')}</h4>
                            <UILIB.Row>
                                <UILIB.Column xs={12} md={6} className="mar-b25">
                                    <UILIB.Select label={i18n.t('account:my.language')} name="language" onChange={this.updateInput} data={languages} value={this.state.accountUserData.language} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} md={6} className="mar-b25">
                                    <UILIB.Select label={i18n.t('account:my.timezone')} name="timeZone" onChange={this.updateInput} data={this.state.timeZoneData} value={this.state.accountUserData.timeZone} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} md={12} className="mar-b25">
                                    <UILIB.Select label={i18n.t('account:my.dateFormat')} name="dateFormat" onChange={this.updateInput} data={dateFormats} value={this.state.accountUserData.dateFormat} />
                                </UILIB.Column>
                                <UILIB.Column xs="12" margin="0">
                                    <UILIB.Button saving={this.state.saving} className="button-primary" onClick={this.saveSettings}>{i18n.t('account:my.saveDetails')}</UILIB.Button>
                                </UILIB.Column>
                            </UILIB.Row>
                        </UILIB.Paper>


                        <UILIB.Paper className="">
                            <Sessions />
                        </UILIB.Paper>

                    </UILIB.Column>


                </UILIB.Row>
                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
            </div >
        }
    };
};
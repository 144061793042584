import React from 'react'
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import ElementPicker from './elementPicker/elementPicker'
import MarketPlace from '../marketPlace/marketPlace'
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})

export default class DragDrop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCat: 0,
            minimized: false
        };

        this.minimize = this.minimize.bind(this);
    }

    minimize() {
        this.setState({ minimized: !this.state.minimized });
    }

    render() {
        var style = {};
        // if (this.props.componentSelected) style.width = "0px";
        if (this.props.marketOpen) {
            return <div className="elementsPanel animatedPanel" style={{ width: "400px" }}>
                <MarketPlace openMarket={this.props.openMarket} components={this.props.marketPlaceComponents} />
            </div>
        }
        if (this.state.minimized) style.width = "0px";
        return <div className="elementsPanel animatedPanel" style={style}>
            <div className="dd-leftTools-minimizeIcon" onClick={this.minimize}>
                {!this.state.minimized && <UILIB.Icons icon="arrowLeft" />}
                {this.state.minimized && <UILIB.Icons icon="arrowRight" />}
            </div>
            {!this.state.minimized && <>
                <div className="dd-leftTools-topTools">
                    <UILIB.Toggle name="showGuideLines" after="Show Guide Lines" value={this.props.showGuideLines} onChange={this.props.showHideGuideLines} />
                </div>
                <div className="dd-leftTools-categorySelect">
                    <UILIB.Select data={[
                        { "label": i18n.t('templates:add.drag.wizard.elements'), "value": 0 },
                        { "label": i18n.t('templates:add.drag.wizard.layouts'), "value": 1 },
                        { "label": i18n.t('templates:add.drag.wizard.blocks'), "value": 2 },
                        { "label": i18n.t('templates:add.drag.wizard.savedBlocks'), "value": 3 }
                    ]} onChange={(ev) => {
                        this.setState({ currentCat: ev.target.value })
                    }} value={this.state.currentCat} />
                </div>
                <div className="tools">
                    {this.state.currentCat == 0 && <ElementPicker type="element" components={this.props.components} openMarket={this.props.openMarket} isForm={this.props.isForm} insertElement={this.props.insertElement} />}
                    {this.state.currentCat == 1 && <ElementPicker type="layout" components={this.props.layouts} openMarket={this.props.openMarket} isForm={this.props.isForm} insertElement={this.props.insertElement} />}
                    {this.state.currentCat == 2 && <ElementPicker type="block" components={this.props.blocks} openMarket={this.props.openMarket} isForm={this.props.isForm} insertElement={this.props.insertElement} />}
                    {this.state.currentCat == 3 && <ElementPicker type="userBlock" components={this.props.userBlocks} openMarket={this.props.openMarket} isForm={this.props.isForm} updateBlocks={this.props.updateBlocks} insertElement={this.props.insertElement} />}
                </div>
                <div className="avatar">
                    <div className="avatarImage" style={{ marginRight: "10px" }}>
                        <UILIB.Avatar for={'user_' + this.props.user.userID} src={this.props.user.userData.profilePicFileName} />
                    </div>
                    <div className="text-sml avatarName">{this.props.user.userData.fullname}</div>
                </div>
            </>}
        </div>
    }
}
import i18n from '~/i18n'

class AutomationFunctions {

    constructor() {
    }

    static getWorkflowEmails(guid, steps) {
        var workflowEmails = [];

        function getSteps(ourGuid, theSteps, emailsFound) {
            var finish = false
            let tmpEmailsFound = JSON.parse(JSON.stringify(emailsFound))
            theSteps.forEach(st => {
                if (finish) return
                if (st.type == "email" && st.complete == true) tmpEmailsFound.push(st);
                if (st.steps && Array.isArray(st.steps)) {
                    st.steps.forEach(st2 => {
                        getSteps(ourGuid, st2, tmpEmailsFound)
                    })
                }
                if (st.guid == ourGuid) {

                    workflowEmails = tmpEmailsFound
                    finish = true
                    return;
                }
            })
        }

        getSteps(guid, steps, [])

        return workflowEmails;
    }

    static getStepByGuid(guid, steps) {
        var step = {};
        function getStep(ourGuid, theSteps) {
            theSteps.forEach(st => {
                if (st.guid == ourGuid) {
                    step = st
                    return;
                }
                if (st.steps && Array.isArray(st.steps)) {
                    st.steps.forEach(st2 => {
                        getStep(ourGuid, st2)
                    })
                }
            })
        }

        getStep(guid, steps)
        return step;
    }

    static getStepsByType(type, steps) {
        var stepsFound = [];
        function getStep(type, theSteps) {
            theSteps.forEach(st => {
                if (st.type == type) {
                    stepsFound.push(st)
                    return;
                }
                if (st.steps && Array.isArray(st.steps)) {
                    st.steps.forEach(st2 => {
                        getStep(type, st2)
                    })
                }
            })
        }

        getStep(type, steps)
        return stepsFound;
    }
    static checkAllComplete(steps) {
        var failedSteps = [];

        function checkSteps(theSteps) {
            theSteps.forEach(st => {
                if (st.complete != true && st.type) {
                    if (st.type != "yes" && st.type != "no") {
                        failedSteps.push(st.guid);
                    }
                }
                if (st.steps && Array.isArray(st.steps)) {
                    st.steps.forEach(st2 => {
                        checkSteps(st2)
                    })
                }
                if (st.triggerSteps && Array.isArray(st.triggerSteps)) {
                    checkSteps(st.triggerSteps)
                }
            })
        }

        checkSteps(steps)
        return failedSteps;
    }

    static countByType(steps, type) {
        var totalFound = 0;
        function checkSteps(theSteps) {
            theSteps.forEach(st => {
                if (st.type == type) {
                    totalFound++;
                }
                if (st.steps && Array.isArray(st.steps)) {
                    st.steps.forEach(st2 => {
                        checkSteps(st2)
                    })
                }
            })
        }

        checkSteps(steps)
        return totalFound;
    }

    static delayTypes() {
        return [
            { label: i18n.t('automation:add.delay.minutes'), value: "minutes" },
            { label: i18n.t('automation:add.delay.hours'), value: "hours" },
            { label: i18n.t('automation:add.delay.days'), value: "days" },
            { label: i18n.t('automation:add.delay.weeks'), value: "weeks" },
            { label: i18n.t('automation:add.delay.months'), value: "months" },
            { label: i18n.t('automation:add.delay.next_time'), value: "next_time" },
            { label: i18n.t('automation:add.delay.next_day'), value: "next_day" },
            { label: i18n.t('automation:add.delay.next_monthday'), value: "next_monthday" },
            { label: i18n.t('automation:add.delay.next_yearday'), value: "next_yearday" }
        ]
    }

    static delayMins() {
        return ['00', '15', '30', '45'];
    }

    static delayHours() {
        return ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
    }

    static delayWeekDays() {
        return [
            { label: i18n.t('automation:add.delay.sunday'), value: "0" },
            { label: i18n.t('automation:add.delay.monday'), value: "1" },
            { label: i18n.t('automation:add.delay.tuesday'), value: "2" },
            { label: i18n.t('automation:add.delay.wednesday'), value: "3" },
            { label: i18n.t('automation:add.delay.thursday'), value: "4" },
            { label: i18n.t('automation:add.delay.friday'), value: "5" },
            { label: i18n.t('automation:add.delay.saturday'), value: "6" },]
    }

    static delayDaysOfMonth() {
        var daysOfMonth = [];
        var dCnt = 1;
        while (dCnt < 29) {
            daysOfMonth.push({ label: i18n.t('automation:add.delay.dayOfMonth', { number: dCnt }), value: String(dCnt) })
            dCnt++;
        }
        return daysOfMonth;
    }

    static humanizeStep(step) {
        if (!step) return '';
        let condition = '';
        switch (step.type) {
            case 'trigger':
                return i18n.t('automation:add.step.trigger', { name: step.name })
            case 'email':
                return `Email: ${step.stepOptions.campaign.subject}`
            case 'sms':
                return `SMS: ${step.stepOptions.subject.value}`
            case 'webhooks':
                return `Webhook: ${step.stepOptions.url.value}`
            case 'condition':
                if (step.stepOptions.triggerType.value == '0' || step.stepOptions.triggerType.value == '3') condition = 'Email Activity'
                if (step.stepOptions.triggerType.value == '1') condition = 'Custom Field Value'
                if (step.stepOptions.triggerType.value == '2') condition = 'Segment Membership'
                if (step.stepOptions.triggerType.value == '7') condition = 'Group Membership'
                if (step.stepOptions.triggerType.value == '9') condition = 'Form Activity'
                if (step.stepOptions.triggerType.value == '10') condition = 'In Other Automation'
                if (step.stepOptions.triggerType.value == '11') condition = 'Tags'
                return `Condition Step: ${condition}`
            case 'delay':
                return `Delay`
            case 'wait':
                if (step.stepOptions.triggerType.value == '0' || step.stepOptions.triggerType.value == '3') condition = 'Email Activity'
                if (step.stepOptions.triggerType.value == '1') condition = 'Custom Field Value'
                if (step.stepOptions.triggerType.value == '2') condition = 'Segment Membership'
                if (step.stepOptions.triggerType.value == '7') condition = 'Group Membership'
                if (step.stepOptions.triggerType.value == '9') condition = 'Form Activity'
                if (step.stepOptions.triggerType.value == '10') condition = 'In Other Automation'
                if (step.stepOptions.triggerType.value == '11') condition = 'Tags'
                return `Wait: ${condition}`
            case 'action':
                let action = ''
                if (step.stepOptions.actionType.value == '0') action = 'Update a custom field'
                if (step.stepOptions.actionType.value == '1') action = 'Copy to a group'
                if (step.stepOptions.actionType.value == '2') action = 'Move to a group'
                if (step.stepOptions.actionType.value == '3') action = 'Remove from a group'
                if (step.stepOptions.actionType.value == '4') action = 'Unsubscribe the contact'
                if (step.stepOptions.actionType.value == '5') action = 'Update the contacts status'
                if (step.stepOptions.actionType.value == '6') action = 'Restart Automation'
                if (step.stepOptions.actionType.value == '7') action = 'Denylist Contact'
                if (step.stepOptions.actionType.value == '8') action = 'Remove Contact from Denylist'
                if (step.stepOptions.actionType.value == '9') action = 'Add a tag'
                if (step.stepOptions.actionType.value == '10') action = 'Remove a tag'
                return `Action: ${action}`
            case 'split':
                return `Split Contacts Evenly`
            case 'goal':
                let goal = 'End the Automation'
                if (step.stepOptions.goalEndAction.value == '2') goal = 'Send to another Automation'
                return `Goal: ${goal}`
            case 'score':
                return `Score the contact`
            case 'notification':
                return `Notification for: ${step.stepOptions.toAddresses.map(a => a.value).join(', ')}`
            case 'redirect':
                return `Redirect the contact`
            case 'initial':
                return `Initial Step`
            default:
                return ''
        }
    }
}

export default AutomationFunctions;
import React from 'react';

export default class OneColumn extends React.Component {
    render() {
        var styles = {
            cls1: {
                fill: 'rgba(0,0,0,0.05)'
            },
            cls2: {
                fill: '#fff'
            },
            cls3: {
                fill: 'rgba(0,0,0,0.2)'
            },
            cls4: {
                fill: 'rgba(0,0,0,0.1)'
            },
            cls5: {
                filter: 'url(#Rectangle_202)'
            }
        }
        return <svg id="Group_278" data-name="Group 278" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="svg-holder" viewBox="16645 7101 253 257">
            <defs>
                <filter id="Rectangle_202" x="16656" y="7107" width="230" height="251" filterUnits="userSpaceOnUse">
                    <feOffset dy="-5" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.039" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>

            <rect id="Rectangle_201" data-name="Rectangle 201" className="outer" width="253" height="253" rx="3" transform="translate(16645 7101)" />
            <g transform="matrix(1, 0, 0, 1, 0, 0)">
                <path id="Rectangle_202-2" data-name="Rectangle 202" style={styles.cls2} d="M3,0H209a3,3,0,0,1,3,3V233a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(16665 7121)" />
            </g>

            <rect id="Rectangle_203" data-name="Rectangle 203" className="inner" width="40.25" height="40.25" rx="3" transform="translate(16676 7171)" />
            <rect id="Rectangle_249" data-name="Rectangle 249" className="inner" width="40.25" height="40.25" rx="3" transform="translate(16676 7291)" />
            <rect id="Rectangle_246" data-name="Rectangle 246" className="inner" width="40.25" height="40.25" rx="3" transform="translate(16726 7171)" />
            <rect id="Rectangle_250" data-name="Rectangle 250" className="inner" width="40.25" height="40.25" rx="3" transform="translate(16726 7291)" />
            <rect id="Rectangle_248" data-name="Rectangle 248" className="inner" width="40.25" height="40.25" rx="3" transform="translate(16777 7171)" />
            <rect id="Rectangle_251" data-name="Rectangle 251" className="inner" width="40.25" height="40.25" rx="3" transform="translate(16777 7291)" />
            <rect id="Rectangle_247" data-name="Rectangle 247" className="inner" width="40.25" height="40.25" rx="3" transform="translate(16827 7171)" />
            <rect id="Rectangle_252" data-name="Rectangle 252" className="inner" width="40.25" height="40.25" rx="3" transform="translate(16827 7291)" />
            <g id="Group_272" data-name="Group 272" transform="translate(0 -109)">
                <rect id="Rectangle_205" data-name="Rectangle 205" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16676 7340)" />
                <rect id="Rectangle_209" data-name="Rectangle 209" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16676 7364)" />
                <rect id="Rectangle_207" data-name="Rectangle 207" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16676 7352)" />
                <rect id="Rectangle_211" data-name="Rectangle 211" style={styles.cls4} width="20" height="4" rx="2" transform="translate(16676 7376)" />
            </g>
            <g id="Group_273" data-name="Group 273" transform="translate(50 -109)">
                <rect id="Rectangle_205-2" data-name="Rectangle 205" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16676 7340)" />
                <rect id="Rectangle_209-2" data-name="Rectangle 209" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16676 7364)" />
                <rect id="Rectangle_207-2" data-name="Rectangle 207" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16676 7352)" />
                <rect id="Rectangle_211-2" data-name="Rectangle 211" style={styles.cls4} width="20" height="4" rx="2" transform="translate(16676 7376)" />
            </g>
            <g id="Group_275" data-name="Group 275" transform="translate(101 -109)">
                <rect id="Rectangle_205-3" data-name="Rectangle 205" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16676 7340)" />
                <rect id="Rectangle_209-3" data-name="Rectangle 209" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16676 7364)" />
                <rect id="Rectangle_207-3" data-name="Rectangle 207" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16676 7352)" />
                <rect id="Rectangle_211-3" data-name="Rectangle 211" style={styles.cls4} width="20" height="4" rx="2" transform="translate(16676 7376)" />
            </g>
            <g id="Group_274" data-name="Group 274" transform="translate(151 -109)">
                <rect id="Rectangle_205-4" data-name="Rectangle 205" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16676 7340)" />
                <rect id="Rectangle_209-4" data-name="Rectangle 209" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16676 7364)" />
                <rect id="Rectangle_207-4" data-name="Rectangle 207" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16676 7352)" />
                <rect id="Rectangle_211-4" data-name="Rectangle 211" style={styles.cls4} width="20" height="4" rx="2" transform="translate(16676 7376)" />
            </g>
            <path id="Rectangle_205-5" data-name="Rectangle 205" style={styles.cls4} d="M3,0H37a3,3,0,0,1,3,3V3a0,0,0,0,1,0,0H0A0,0,0,0,1,0,3V3A3,3,0,0,1,3,0Z" transform="translate(16827 7351)" />
            <path id="Rectangle_253" data-name="Rectangle 253" style={styles.cls4} d="M3,0H37a3,3,0,0,1,3,3V3a0,0,0,0,1,0,0H0A0,0,0,0,1,0,3V3A3,3,0,0,1,3,0Z" transform="translate(16777 7351)" />
            <path id="Rectangle_254" data-name="Rectangle 254" style={styles.cls4} d="M3,0H37a3,3,0,0,1,3,3V3a0,0,0,0,1,0,0H0A0,0,0,0,1,0,3V3A3,3,0,0,1,3,0Z" transform="translate(16726 7351)" />
            <path id="Rectangle_255" data-name="Rectangle 255" style={styles.cls4} d="M3,0H37a3,3,0,0,1,3,3V3a0,0,0,0,1,0,0H0A0,0,0,0,1,0,3V3A3,3,0,0,1,3,0Z" transform="translate(16676 7351)" />
            <rect id="Rectangle_219" data-name="Rectangle 219" className="inner" width="192" height="20" rx="3" transform="translate(16675 7131)" />
        </svg>


    }
}
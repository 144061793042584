import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { propToCssModule } from "~/Classes/styles";
import Icon from "../Icon";
import ReactTooltip from 'react-tooltip';
import * as styles from "./index.module.scss";

const Button = React.forwardRef(
  (
    {
      variant = "primary",
      size = "r",
      type = "button",
      href,
      to,
      loading,
      iconLeft,
      iconRight,
      iconOnly,
      className,
      children,
      ...rest
    },
    forwardedRef
  ) => {
    if (iconOnly && !rest["aria-label"]) {
      console.error(
        'Buttons with only an icon must have an "aria-label" attribute.'
      );
    }

    let RootTag = "button";
    if (href) {
      RootTag = "a";
    } else if (to) {
      RootTag = Link;
    }

    const classes = classnames(styles.root, className, {
      [styles[`rootVariant${propToCssModule(variant)}`]]: variant,
      [styles[`rootSize${propToCssModule(size)}`]]: size,
      [styles.rootLoading]: loading,
      [styles.rootIconOnly]: iconOnly,
    });

    return (
      <RootTag
        ref={forwardedRef}
        type={RootTag === "button" ? type : undefined}
        href={href}
        to={to}
        className={classes}
        {...rest}
      >
        {iconLeft && <div className={styles.iconLeft}>{iconLeft}</div>}

        <div className={styles.children} data-test="button-children">
          {children}
        </div>

        {iconRight && <div className={styles.iconRight}>{iconRight}</div>}

        <div className={styles.iconLoading} data-test="icon-loading">
          <Icon name="loading" />
        </div>

        {rest["data-tip"] && rest["data-for"] && <ReactTooltip id={rest["data-for"]} effect="solid" />}
      </RootTag>
    );
  }
);

export default Button;

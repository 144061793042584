import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib';
import NumberFunctions from '~/Classes/numberFormatFunctions';
import { connect } from 'react-redux';

@connect((store) => {
    return { account: store.accountMaster }
})

class Plan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPlan: this.props.plans ? (this.props.selected || this.props.plans[0]).id : null
        }
        this.onSelect = this.onSelect.bind(this);
        this.select = this.select.bind(this);
    }

    onSelect(ev) {
        this.setState({
            selectedPlan: ev.target.value
        })
    }

    select() {
        this.props.select(this.props.type, this.props.plans ? this.props.plans.find(p => {
            return p.id == this.state.selectedPlan
        }) : undefined)
    }

    render() {
        var price = this.props.price;
        var plan = this.props.plans ? this.props.plans.find(p => p.id == this.state.selectedPlan) : undefined;
        if (!price && plan && plan.friendlyPriceRaw) {
            price = plan.friendlyPriceRaw;
            if (this.props.markup) {
                price = Math.round(price * (1 + (this.props.markup / 100)));
            }
            price = plan.symbol + NumberFunctions.formatNumber(price, 2);
        }

        var planText = 'select a plan';
        if (plan != null) {
            planText = plan.name;
        }

        var text = 'Change Plan'
        if (this.props.currentPlan && this.props.type === 'free') text = 'Current Plan'

        return (
            <div className={"subscription-plan " + this.props.className + (this.props.currentPlan ? ' current' : '')}>
                <UILIB.Row className="mar-b15">
                    <UILIB.Column xs={12} margin={0}>
                        <div className="center-xs text-heavy uppercase">{this.props.name}</div>
                    </UILIB.Column>
                </UILIB.Row>
                <UILIB.Row>
                    <UILIB.Column xs={12} className="center-xs center-sm center-md center-lg subtitle">
                        <span className="text-md text-light text-grey">{this.props.description}</span>
                    </UILIB.Column>
                </UILIB.Row>
                <UILIB.Row>
                    <UILIB.Column xs={12} margin={0}>
                        <div className="mar-b20 featureList">
                            {this.props.ticks.map((tick, index) => {
                                return <div className="mar-b10 feature" key={index}>
                                    <span className="text-sml quickFlex"><i className="icon-checkmark-circle icon20 pad-right"></i>{tick}</span>
                                </div>
                            })}
                        </div>
                    </UILIB.Column>
                </UILIB.Row>
                <UILIB.Row className="mar-b10">
                    {!!this.props.plans && <UILIB.Column xs={12} margin={0}>
                        <div className="form-group mar-b0">
                            <UILIB.Select disabled={this.props.disabled} value={this.state.selectedPlan} onChange={this.onSelect} data={this.props.plans.map(plan => {
                                return {
                                    label: plan.name,
                                    value: plan.id
                                }
                            })} />
                        </div>
                    </UILIB.Column>}
                </UILIB.Row>
                <UILIB.Row className="priceWrap">
                    <UILIB.Column xs={12} margin={0}>
                        <UILIB.Row className="center-xs">
                            <div className={"text-heavy " + (this.props.comingsoon ? "text-lg" : "text-xl")}>{price}
                                {this.props.account.accountMaster.addVAT && price != "free" && <span style={{ fontSize: "10px" }}>+ VAT</span>}
                            </div>
                        </UILIB.Row>
                        {!this.props.price && <UILIB.Row className="center-xs">
                            <div className="text-sml text-grey mar-b20">a month</div>
                        </UILIB.Row>}
                    </UILIB.Column>
                </UILIB.Row>
                <div className="priceBtnFix">
                    <UILIB.Button className={"no-shadow button-md full-width " + this.props.className} disabled={this.props.disabled} text={this.props.disabled ? 'Current Plan' : "Change Plan"} onClick={this.select} />
                </div>
            </div>
        );
    }
}

export default Plan;
import React from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import moment from 'moment';
import axios2 from 'axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';

@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster }
})

export default class CampaignIndepthChart extends React.Component {
    constructor(props) {
        super(props);

        this._ismounted = false;
        this.timer = null;

        this.state = {
            dataLoaded: false,
            graphData: []
        }

        this.loadData = this.loadData.bind(this);
        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();
    }

    componentDidMount() {
        if (this.props.campaignId != 0) {
            this._ismounted = true;
            this.loadData();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        this._ismounted = false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate != this.props.startDate || prevProps.endDate != this.props.endDate || this.props.showBots !== prevProps.showBots || this.props.campaignId != prevProps.campaignId) {
            clearTimeout(this.timer);
            this.setState({}, this.loadData)
        }
    }

    loadData(ignore) {
        if (!this._ismounted) return;
        if (!this.state.dataLoaded) {
            this.source.cancel('I cancelled this')
            this.source = this.CancelToken.source();
        }
        if (!ignore) this.setState({
            dataLoaded: false
        })
        var qS = "?1=2";

        if (this.props.startDate) qS += "&startDate=" + moment(this.props.startDate).format("YYYY-MM-DD");
        if (this.props.endDate) qS += "&endDate=" + moment(this.props.endDate).format("YYYY-MM-DD 23:59:59");

        if (this.props.showBots) qS += '&bots=true'

        axios.get(`/campaign/${this.props.campaignId}/stats/graph/indepth/${this.props.type}${qS}`, {
            cancelToken: this.source.token
        }).then(_resp => {
            var graphData = [];
            _resp.data.Stats.forEach(stat => {

                var data = {
                    date: DateTimeFunctions.formatDateTime(stat.date),
                    [this.props.type]: Number(stat.count)
                }
                if (this.props.type == "bounced") {
                    data.hardBounced = Number(stat.hardBounced);
                    data.softBounced = Number(stat.count);
                }
                if (this.props.type == "sent") {
                    data.projectedSends = stat.projectedCount ? Number(stat.projectedCount) : 0;
                }
                graphData.push(data)
            })

            if (this._ismounted) this.timer = setTimeout(() => this.loadData(true), 20000)
            this.setState({ graphData, dataLoaded: true })
        });
    }


    render() {
        //campaignId={this.state.campaignId} startDate={this.props.startDate} endDate={this.props.endDate} seatchText={this.state.searchText} 
        if (!this.state.dataLoaded) return <div>
            <h4 className="mar-b25">{this.props.title}</h4>
            <UILIB.LoadingIcons iconType="2" />
        </div>
        if (this.state.dataLoaded) {
            return <div>
                <h4 className="mar-b25">{this.props.title}</h4>
                <ResponsiveContainer height={110}>
                    <AreaChart data={this.state.graphData}
                        margin={{ top: 0, right: 0, left: 20, bottom: 0 }}>
                        <XAxis dataKey="date" axisLine={false} tickLine={false} padding={{ left: 15 }} tick={{ fontSize: 10, fill: '#999', fontFamily: "Open Sans", fontWeight: 600 }} />
                        <YAxis axisLine={false} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontFamily: "Open Sans", fontWeight: 600 }} />
                        <Tooltip content={<UILIB.CustomTooltip showLabel={true} />} />
                        <defs>
                            <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={this.props.siteMaster.colours.$primary} stopOpacity={0.3} />
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                            </linearGradient>
                            <linearGradient id="colorUvGrey" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={this.props.siteMaster.colours.$grey} stopOpacity={0.3} />
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                            </linearGradient>
                            <linearGradient id="colorUvSecondary" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={this.props.siteMaster.colours.$secondary} stopOpacity={0.3} />
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                            </linearGradient>
                        </defs>

                        {(this.props.type != "bounced") && <Area
                            type="monotone"
                            fill="url(#colorUv1)"
                            dataKey={this.props.type}
                            stroke={this.props.siteMaster.colours.$primary}
                            strokeWidth={2}
                            fillOpacity={1}
                            activeDot={{ stroke: this.props.siteMaster.colours.$primary, strokeWidth: 2, fill: 'white' }} />
                        }
                        {(this.props.type == "sent") && <Area
                            type="monotone"
                            fill="url(#colorUvGrey)"
                            dataKey={"projectedSends"}
                            stroke={this.props.siteMaster.colours.$grey}
                            strokeWidth={2}
                            fillOpacity={1}
                            activeDot={{ stroke: this.props.siteMaster.colours.$grey, strokeWidth: 2, fill: 'white' }} />
                        }
                        {(this.props.type == "bounced") && <Area
                            type="monotone"
                            fill="url(#colorUv1)"
                            dataKey={"hardBounced"}
                            stroke={this.props.siteMaster.colours.$primary}
                            strokeWidth={2}
                            fillOpacity={1}
                            activeDot={{ stroke: this.props.siteMaster.colours.$primary, strokeWidth: 2, fill: 'white' }} />
                        }
                        {(this.props.type == "bounced") && <Area
                            type="monotone"
                            fill="url(#colorUvSecondary)"
                            dataKey={"softBounced"}
                            stroke={this.props.siteMaster.colours.$secondary}
                            strokeWidth={2}
                            fillOpacity={1}
                            activeDot={{ stroke: this.props.siteMaster.colours.$secondary, strokeWidth: 2, fill: 'white' }} />
                        }
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        }
    }
}
import React from 'react'

export default function IconsDivider({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";

    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 10.0001C3 9.73484 3.10536 9.48049 3.29289 9.29295C3.48043 9.10542 3.73478 9.00006 4 9.00006H16C16.2652 9.00006 16.5196 9.10542 16.7071 9.29295C16.8946 9.48049 17 9.73484 17 10.0001C17 10.2653 16.8946 10.5196 16.7071 10.7072C16.5196 10.8947 16.2652 11.0001 16 11.0001H4C3.73478 11.0001 3.48043 10.8947 3.29289 10.7072C3.10536 10.5196 3 10.2653 3 10.0001Z" fill={fillColor} />
        </svg>
    )
}
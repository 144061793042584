import React, { Component } from "react";
import UILIB from "~/Common-Objects/Lib";
import axios from "~/data/http/axios";
import { connect } from "react-redux";

import AutomationGlobalOptions from "./automationGlobalOptions";
import AutomationOption from "./automationOption";
import AddStepDrawer from "./addStepDrawer";

import DrawerStepInitial from "./steps/drawerStepInitial";
import DrawerEmailStep from "./steps/drawerEmailStep";
import DrawerDelayStep from "./steps/drawerDelayStep";
import DrawerWaitStep from "./steps/drawerWaitStep";
import DrawerSmsStep from "./steps/drawerSmsStep";
import DrawerSplitStep from "./steps/drawerSplitStep";

import DrawerConditionStep from "./steps/drawerConditionStep";
import DrawerActionStep from "./steps/drawerActionStep";
import DrawerGoalStep from "./steps/drawerGoalStep";
import NotificationStep from "./steps/drawerNotificationStep";
import WebhookStep from "./steps/drawerWebhookStep";
import RedirectStep from "./steps/drawerRedirectStep";

import ConfirmRemoveStepDrawer from "./confirmRemoveStepDrawer";
import DrawerViewRunningStep from "./drawerRunningStep";
import DrawerActivateFirstRun from "./drawerActivateFirstRun";

import CombineElement from "./combineElement";

import AutomationFunctions from "./automationFunctions";

import Pipedrive from "./integrations/pipedrive/configure";
import GenericConfigure from "./integrations/generic/settings";

import * as siteMaster from "~/data/actions/siteActions"; //now we can use user actions here
import moment from "moment";
import HelperFunctions from "~/Classes/helpers";
import queryString from "query-string";
import jQuery from "jquery";
import i18n from "~/i18n";

import EmailPreviewer from "~/pages/cp/includes/emailPreviewer/emailPreviewer";
import PermissionChecker from "~/Classes/permissions";
import NewUserForm from "../../includes/bb_topNav/newUserForm/newUserForm";
import AutomationAddContact from "./addContact";
import AutomationFindContact from "./findContact";
import AutomationOptionContact from "./optionContact";
import AutomationCancelTest from "./cancelTest";

function findNextStep(steps, currentStep) {
	var nextStep = undefined;
	function loopSteps(theSteps) {
		var foundStep = false;

		theSteps.forEach((st) => {
			if (foundStep == true && !nextStep) {
				nextStep = st;
			}
			if (st.guid == currentStep.guid) {
				foundStep = true;
			}
			if (st.steps && Array.isArray(st.steps)) {
				st.steps.forEach((st2) => {
					loopSteps(st2);
				});
			}
		});
	}
	loopSteps(steps);

	return nextStep;
}

function findNextLane(steps, currentStep) {
	var foundArr, parent;
	function loopSteps(arr) {
		arr.forEach((st) => {
			if (st.guid == currentStep.guid) {
				foundArr = arr;
				return;
			}
			if (st.steps && Array.isArray(st.steps)) {
				st.steps.forEach((st2) => {
					parent = st2;
					loopSteps(st2);
				});
			}
		});
		if (foundArr) return;
	}
	loopSteps(steps);
	if (foundArr && parent) {
		return parent;
	}
}

@connect((store) => {
	return {
		user: store.user,
		permissionStore: store.permission,
		siteMaster: store.siteMaster,
		account: store.accountMaster,
	};
})
class AutomationsNew extends Component {
	constructor(props) {
		super(props);
		this.statTimer = null;
		this.state = {
			automationId: 0,
			pageLoaded: false,
			conditionPermission: undefined,
			loadingText: "",
			loadingPct: 1,
			automationData: {},
			groups: [],
			segments: [],
			customFields: [],
			campaigns: [],
			tags: [],
			activateError: "",
			stepStats: [],
			applications: [],
			alertMessage: "",
			alertOpen: false,
			zoom: 1,
			automations: [],
			isThemeDesigner: false,
			pageErrored: "",
			redirectOnSaveUrl: "",
			chooseAStepSource: {},
			chooseAStepChoosing: false,
			nameError: "",
			editedStep: undefined,
			mailboxes: [],
			forms: [],
			linktags: [],
			siteTrackerEvents: [],
			SubscriberId: null,
			contact: undefined,
			contactHistory: undefined,
			allowEdit: false
		};
		this.allowForEdit = this.allowForEdit.bind(this);
		this.keyPress = this.keyPress.bind(this);
		this.changeNameTick = this.changeNameTick.bind(this);
		this.renderSteps = this.renderSteps.bind(this);
		this.addStepDrawer = this.addStepDrawer.bind(this);
		this.goAddNewStep = this.goAddNewStep.bind(this);
		this.removeStep = this.removeStep.bind(this);
		this.confirmRemoveStep = this.confirmRemoveStep.bind(this);
		this.searchForChildren = this.searchForChildren.bind(this);
		this.editStep = this.editStep.bind(this);
		this.loadAutomation = this.loadAutomation.bind(this);
		this.getGroups = this.getGroups.bind(this);
		this.getSegments = this.getSegments.bind(this);
		this.getCustomFields = this.getCustomFields.bind(this);
		this.saveStep = this.saveStep.bind(this);
		this.saveAutomation = this.saveAutomation.bind(this);
		this.activateClicked = this.activateClicked.bind(this);
		this.getStepStats = this.getStepStats.bind(this);
		this.changeAutomationName = this.changeAutomationName.bind(this);
		this.goActivateWorkflow = this.goActivateWorkflow.bind(this);
		this.goCloseActivateDrawer = this.goCloseActivateDrawer.bind(this);
		this.fullScreenChangedSize = this.fullScreenChangedSize.bind(this);
		this.goZoom = this.goZoom.bind(this);
		this.goTo = this.goTo.bind(this);
		this.unmouting = false;
		this.loadEmailPreview = this.loadEmailPreview.bind(this);
		this.getApplications = this.getApplications.bind(this);
		this.getApplicationFields = this.getApplicationFields.bind(this);
		this.getApplicationGetters = this.getApplicationGetters.bind(this);
		this.reRender = this.reRender.bind(this);
		this.setupAutomationTriggers = this.setupAutomationTriggers.bind(this);
		this.addAutomation = this.addAutomation.bind(this);
		this.showGlobalOptions = this.showGlobalOptions.bind(this);
		this.setGlobalOptions = this.setGlobalOptions.bind(this);
		this.chooseAStepAndReturn = this.chooseAStepAndReturn.bind(this);
		this.chooseAStepReturn = this.chooseAStepReturn.bind(this);
		this.getStepWizardText = this.getStepWizardText.bind(this);
		this.getTags = this.getTags.bind(this);
		this.getMailboxes = this.getMailboxes.bind(this);
		this.saveAndGo = this.saveAndGo.bind(this);
		this.checkTier = this.checkTier.bind(this);
		this.checkAutomationTier = this.checkAutomationTier.bind(this);
		this.moveStepsToBranch = this.moveStepsToBranch.bind(this);
		this.showTierForbidsDrawer = this.showTierForbidsDrawer.bind(this);
		this.getForms = this.getForms.bind(this);
		this.addTrigger = this.addTrigger.bind(this);
		this.checkAllRedirectStepsValid = this.checkAllRedirectStepsValid.bind(this);
		this.findContact = this.findContact.bind(this);
		this.addContact = this.addContact.bind(this);
		this.firstLoad = true;


	}

	componentDidMount() {
		const query = queryString.parse(this.props.location.search);
		var themeDesigner = query.themeDesigner;
		var redirect = query.redirect;

		if (themeDesigner) {
			var themeId = query.themeId;
			if (!themeId) {
				this.setState({
					pageErrored: "Theme ID Not provided",
				});
				return;
			}
			this.setState({
				loadingText: "Loading Theme",
				loadingPct: 50,
			});
			axios
				.get("/agency/automationtheme/" + themeId)
				.then((response) => {
					this.setState({
						isThemeDesigner: true,
						automationId: themeId,
						automationData: response.data,
						loadingText: "Loading Theme",
						loadingPct: 100,
						pageLoaded: true,
						redirectOnSaveUrl: redirect,
						conditionPermission: {
							allowed: true,
							allowedNumber: 999,
							currentNumber: 4,
							err: "",
							remaining: 995,
							tierName: "Legacy",
						},
						automationPermissions: {
							allowed: true,
							allowedNumber: 999,
							currentNumber: 4,
							err: "",
							remaining: 995,
							tierName: "Legacy",
						},
					});
				})
				.catch((err) => {
					this.setState({
						pageErrored: "Theme ID Not provided",
					});
				});
		} else {
			axios.get("/automation?recordLimit=100").then((response) => {
				this.setState({
					automations: response.data.Automations,
				});
			});

			this.checkTier();

			if (this.props.match.params.automationId) {
				this.setState({
					SubscriberId: query.SubscriberId,
					automationId: this.props.match.params.automationId,
					redirectOnSaveUrl: redirect,
				}, this.loadAutomation);
			} else {
				this.createAutomation();
			}

			this.checkAutomationTier();
			this.getApplications();
			this.getMailboxes();
			this.getForms();
			this.getSiteTrackerEvents();
		}
	}

	componentWillUnmount() {
		this.unmouting = true;
		clearTimeout(this.statTimer);
		clearTimeout(this.timer);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.pageLoaded && this.state.pageLoaded) {
			// check if its a wizard and open the initial step if needed
			if (
				this.state.automationData.status === "Draft" &&
				this.state.automationData.steps[0].wizardComplete === false
			) {
				this.editStep(this.state.automationData.steps[0]);
			}
		}

		if (
			this.state.pageLoaded &&
			this.state.automationData.steps &&
			this.state.automationData.steps.length
		) {
			//remove existing line
			jQuery(".redirector").remove();

			function parseSteps(steps, parentLevel) {
				steps.forEach((step) => {
					if (
						step.type == "redirect" &&
						step.stepOptions.redirectGuid &&
						step.stepOptions.redirectGuid.value
					) {
						// <div style={{ height: "2px", width: "2px", backgroundColor: "black", position: "absolute" }} className="redirector" >{step.stepOptions.redirectGuid.value}</div>
						var parentId = step.guid;
						var childId = step.stepOptions.redirectGuid.value;

						//child is the step its forwarding to
						//parent is the redirect step
						var child = jQuery("#optionHolder_" + childId);
						var parent = jQuery("#optionHolder_" + parentId);

						var childElPosition = {
							top: child.offset().top - jQuery(".automationOuter").offset().top,
							left:
								child.offset().left - jQuery(".automationOuter").offset().left,
						};
						var parentElPosition = {
							top:
								parent.offset().top - jQuery(".automationOuter").offset().top,
							left:
								parent.offset().left - jQuery(".automationOuter").offset().left,
						};

						var childHeight = child.height();
						var parentWidth = parent.outerWidth();
						var parentHeight = parent.height();

						var leftPosition = parentElPosition.left;

						var height =
							parentElPosition.top -
							childElPosition.top -
							childHeight +
							24 +
							24;
						var topPosition = childElPosition.top + childHeight - 24;

						var heightDifference = parentElPosition.top - childElPosition.top;
						var leftDifference = parentElPosition.left - childElPosition.left;

						var goingDown = false;
						var goingLeft = false;
						if (heightDifference < 0) goingDown = true;
						if (leftDifference > 0) {
							goingLeft = true;
						}

						var widthToParent =
							childElPosition.left - parentElPosition.left + 20;

						if (goingLeft) {
							leftPosition = leftPosition + parentWidth;
							leftPosition += 30;
							widthToParent = parentElPosition.left - childElPosition.left;
						} else {
							leftPosition -= 30;
						}

						if (goingDown) {
							topPosition = parentElPosition.top + parentHeight - 24;
							height = height * -1;
						}

						//add the new one
						jQuery(".automationOuter")
							.append(`<div class="redirector" id="redirector_${parentId}"
                        style="
                            left: ${leftPosition}px; 
                            height: ${height}px;
                            width: 0px;
                            border: 1px dashed blue;
                            position: absolute;
                            top: ${topPosition}px;
                            z-index: -99999;
                            ">
                                <div style="
                                width: ${widthToParent}px; 
                                ${goingDown ? "bottom: -2px;" : "top: -2px;"} 
                                position: absolute;
                                border: 1px dashed blue;
                                ${goingLeft ? "right" : "left"}: 3px;">
                                
                                    <div style="
                                    border: solid blue;
                                    border-width: 0 3px 3px 0;
                                    display: inline-block;
                                    padding: 3px;
                                    position: absolute;
                                    ${goingLeft ? "left" : "right"}: -3px;
                                    top: -4px;
                                    transform: rotate(${goingLeft ? "135deg" : "-45deg"
								});
                                    -webkit-transform: rotate(${goingLeft ? "135deg" : "-45deg"
								});
                                    "></div>
                                </div>
                                <div style="
                                    width: 30px; 
                                    ${goingDown ? "top: -2px;" : "bottom: -2px;"
								} 
                                    position: absolute; 
                                    border: 1px dashed blue;
                                    left: ${goingLeft ? "-30px" : "2px"};"/>
                                    
                             </div>`);
					}
					if (step.steps && step.steps.length) {
						step.steps.forEach((childStep) => {
							parseSteps(childStep, parentLevel + 4);
						});
					}
				});
			}

			parseSteps(this.state.automationData.steps, 1);
		}

		if (prevProps.siteMaster?.siteDrawer?.isOpen && !this.props.siteMaster?.siteDrawer?.isOpen) {
			this.setState({ highlightStep: undefined })
		}

		if (prevState.SubscriberId !== this.state.SubscriberId) {
			this.setState({ contact: undefined, contactHistory: undefined })
			if (this.state.SubscriberId) {
				axios.get('/automation/' + this.state.automationId + '/subscriber/' + this.state.SubscriberId).then(res => {
					this.setState({ contact: res.data.contact, contactHistory: res.data.history })
				})
			}
		}
	}

	getForms() {
		axios.get("/group/0/form").then((res) => {
			this.setState({
				forms: res.data.Forms,
			});
		});
	}
	async getSiteTrackerEvents() {
		let siteTrackerEvents = await axios.get('/accountMaster/tracker/events')
		siteTrackerEvents = siteTrackerEvents.data.map(m => { return { label: m.name, value: m.id, system: m.system } });
		this.setState({
			siteTrackerEvents
		})
	}

	checkTier() {
		axios.get("/accountMaster/tier/automationConditions").then((res) => {
			this.setState({
				conditionPermission: res.data,
			});
		});
	}

	checkAutomationTier() {
		axios.get("/automation/tierPermission").then((res) => {
			this.setState({
				automationPermissions: res.data,
			});
		});
	}

	upgrade() {
		this.saveAutomation(true, true).then(() => {
			this.props.history.push("/cp/company/subscription");
		});
	}

	reRender() {
		this.setState({});
	}

	getMailboxes() {
		axios.get("/accountMaster/mailbox").then((res) => {
			this.setState({
				mailboxes: res.data.Mailboxes,
			});
		});
	}

	saveAndGo(path) {
		this.saveAutomation(true).then(() => {
			if (path) this.props.history.push(path);
		});
	}

	getApplications() {
		axios.get("/accountMaster/application").then((response) => {
			this.setState(
				{
					applications: response.data.Applications,
				},
				this.getApplicationFields
			);
		});
	}

	getApplicationFields() {
		// add extra steps you want here to render the step block correctly, such as loading custom fields
		this.getApplicationGetters();
	}

	getApplicationGetters() {
		let apps = this.state.applications.filter(
			(a) => a.Application.options.hooks && a.Application.options.hooks.length
		);
		var promises = [];
		apps.forEach((app) => {
			app.Application.options.hooks.forEach((hook) => {
				if (hook.getters) {
					hook.getters.forEach((getter) => {
						promises.push(
							axios
								.get(
									`/application/${app.Application.shortName}/${getter.endpoint}`
								)
								.then((res) => {
									app[getter.property] = res.data;
								})
						);
					});
				}
				if (hook.dynamicBuild) {
					promises.push(
						axios
							.get(
								`/application/${app.Application.shortName}/${hook.dynamicBuild}`
							)
							.then((res) => {
								res.data.forEach((row) => {
									if (hook.build.find((f) => f.value === row.value)) return; // we already have it mapped manually so ignore
									hook.build.push(row);
								});
							})
					);
				}
			});
		});
		Promise.all(promises).then(() => {
			this.setState({});
		});
	}

	createAutomation() {
		var self = this;
		var autoData = {
			name: i18n.t("automation:overview.newAutomation"),
			guid: 0,
			status: "Draft",
			version: 1,
			steps: [
				{
					type: "initial",
					triggerType: { value: "-1", required: true },
					stepOptions: {},
					complete: false,
					guid: HelperFunctions.createLocalGuid(),
					parentGuid: 0,
				},
			],
		};

		axios.post("/automation", autoData).then((response) => {
			window.open(
				"/cp/automation/addnew/" + response.data.Automation.id,
				"_self"
			);
		});
	}
	loadAutomation(refresh) {
		var self = this;
		if (this.unmouting) return;
		this.setState({ loadingText: "Loading Automation" });
		axios.get("/automation/" + this.state.automationId).then((response) => {
			if (response.data.Automation.version != 1) {
				self.props.history.push("/cp/automation");
			} else {
				if (
					response.data.Automation.status === "Running" ||
					response.data.Automation.status === "Archived" ||
					response.data.Automation.status === "Testing"
				) {
					this.getStepStats();
				} else if (response.data.Automation.status === "Starting") {
					this.timer = setTimeout(() => this.loadAutomation(true), 10000);
				}
				this.setState(
					{
						automationData: response.data.Automation,
						loadingText: "Grabbing Custom Fields",
						loadingPct: 20,
					},
					() => {
						if (!refresh) this.getCustomFields(true);
					}
				);
			}
		});
	}
	saveAutomation(dontClose, forceSave) {
		if (!this.state.automationData.name.trim()) {
			if (forceSave) {
				this.state.automationData.name = "New Automation";
			} else {
				return this.setState({
					nameError: " ",
					alertMessage: "Automation Name Required",
					alertOpen: true,
				});
			}
		} else {
			this.setState({
				nameError: "",
			});
		}
		this.setState({
			alertMessage: i18n.t("automation:overview.savingPleaseWait"),
			alertOpen: true,
		});
		var self = this;
		var saveUrl = "/automation/" + this.state.automationId;
		if (this.state.isThemeDesigner) {
			saveUrl = "/agency/automationtheme/" + this.state.automationId;
		}
		if (this.state.allowEdit) {
			this.setState({ allowEdit: false });
		}
		return axios.put(saveUrl, this.state.automationData).then((response) => {
			this.setupAutomationTriggers();
			if (dontClose === true) {
				this.setState({ alertMessage: "Automation Saved", alertOpen: true });
			} else {
				if (this.state.redirectOnSaveUrl) {
					if (this.state.redirectOnSaveUrl.indexOf('/') === 0) {
						this.props.history.push(this.state.redirectOnSaveUrl)
					} else {
						window.open(this.state.redirectOnSaveUrl, "_self");
					}
				} else {
					self.props.history.push("/cp/automation");
				}
			}
		});
	}
	setupAutomationTriggers() {
		let step = this.state.automationData.steps[0];
		if (!parseInt(step.triggerType.value)) {
			let app = this.state.applications.find(
				(a) =>
					a.Application.appName.toLowerCase() ===
					step.triggerType.value.toLowerCase()
			);
			if (app && app.Application.options.triggerSetup) {
				axios
					.get(app.Application.options.triggerSetup + this.state.automationId)
					.catch(() => { });
			}
		}
	}
	getCustomFields(getSegments) {
		var self = this;
		axios.get("/customfield").then((response) => {
			let hasDiffTypes = false
			const firstType = response.data.CustomFields[0]?.entityType
			if (firstType && !response.data.CustomFields.every(f => f.entityType === firstType)) {
				hasDiffTypes = true
			}

			function parseEntityType(type) {
				switch (type) {
					case 1: return i18n.t("subscribers:customfields.contactFields");
					case 2: return i18n.t("subscribers:customfields.orgFields");
					case 3: return i18n.t("subscribers:customfields.contactOrgFields")
					default: return ''
				}
			}

			var customFields = response.data.CustomFields.map((fd) => {
				var custField = {
					label: fd.fieldDesc,
					value: fd.id,
					fieldType: fd.fieldType,
					mergeTag: fd.fieldName,
					readOnly: fd.readOnly,
					group: hasDiffTypes ? parseEntityType(fd.entityType) : i18n.t("subscribers:customfields.customFields"),
					iconRight:
						fd.Application && fd.Application.appLogoUri ? (
							<UILIB.Avatar
								src={fd.Application.appLogoUri}
								style={{ height: 20, width: 20 }}
							/>
						) : (
							""
						),
				};
				if (fd.options && fd.options.length) {
					custField.options = fd.options;
				}
				return custField;
			});
			customFields.unshift({
				label: "Email Address",
				value: "emailAddress",
				fieldType: "TEXT",
				group: i18n.t("subscribers:customfields.systemFields")
			});
			customFields.unshift({
				value: "score",
				label: i18n.t("subscribers:segments.edit.fields.score"),
				fieldType: "NUMBER",
				group: i18n.t("subscribers:customfields.systemFields")
			});
			customFields.unshift({
				value: "createdAt",
				label: i18n.t("subscribers:segments.edit.fields.created"),
				fieldType: "DATE",
				group: i18n.t("subscribers:customfields.systemFields")
			});
			customFields.unshift({
				value: "updatedAt",
				label: i18n.t("subscribers:segments.edit.fields.updated"),
				fieldType: "DATE",
				group: i18n.t("subscribers:customfields.systemFields")
			});
			customFields.unshift({
				value: "lastOpened",
				label: i18n.t("subscribers:segments.edit.fields.opened"),
				fieldType: "DATE",
				group: i18n.t("subscribers:customfields.systemFields")
			});
			customFields.unshift({
				value: "lastClicked",
				label: i18n.t("subscribers:segments.edit.fields.clicked"),
				fieldType: "DATE",
				group: i18n.t("subscribers:customfields.systemFields")
			});
			customFields.unshift({
				value: "lastBounced",
				label: "Last Bounced",
				fieldType: "DATE",
				group: i18n.t("subscribers:customfields.systemFields")
			});
			customFields.unshift({
				value: "lastSent",
				label: i18n.t("subscribers:segments.edit.fields.lastSent"),
				fieldType: "DATE",
				optionsType: "DATE",
				group: i18n.t("subscribers:customfields.systemFields")
			});
			self.setState(
				{ customFields, loadingText: "Working out Segments", loadingPct: 40 },
				() => {
					if (getSegments) {
						this.getSegments(true);
					} else {
						this.editStep();
					}
				}
			);
		});
	}
	getSegments(getCampaigns) {
		var self = this;
		axios
			.get("/segment?notsystem=true&count=false&sortColumn=name")
			.then((response) => {
				var segments = response.data.Segments.map((seg) => {
					return {
						label: seg.name,
						value: seg.id,
						iconRight: seg.CapsuleId ? (
							<UILIB.SquareChip>Capsule</UILIB.SquareChip>
						) : (
							""
						),
						readOnly: seg.readOnly,
						CapsuleId: seg.CapsuleId,
						queryObj: seg.queryObj,
					};
				});
				self.setState(
					{ segments, loadingText: "Retrieving Campaigns", loadingPct: 60 },
					() => {
						if (getCampaigns) {
							this.getCampaigns(true);
						} else {
							this.editStep();
						}
					}
				);
			});
	}
	getCampaigns(getGroups) {
		var self = this;
		axios
			.get(
				"/campaign?sortColumn=startDate&sortOrder=desc&searchText=&startRecord=0&recordLimit=50&hideStats=true&all=true"
			)
			.then((response) => {
				var campaigns = response.data.Campaigns.map((cam) => {
					var campaignType = "";
					var campaignLabel =
						"#" +
						cam.id +
						": " +
						cam.campaignName +
						". Subject: " +
						cam.subject +
						". (" +
						(campaignType ? `${campaignType} - ` : "") +
						cam.status +
						")";
					switch (cam.type) {
						case 3:
							campaignType = "Automation";
							campaignLabel =
								"#" +
								cam.id +
								": Automation Campaign. Subject: " +
								cam.subject +
								". (" +
								(campaignType ? `${campaignType} - ` : "") +
								cam.status +
								")";
							break;
						case 10:
							campaignType = "Split Test";
							break;
						case 20:
							campaignType = "Direct Mail";
							break;
						case 30:
							campaignLabel = cam.campaignName
							campaignType = "Social Post"
							break;
					}

					return {
						label: campaignLabel,
						value: cam.id,
						templateId: cam.TemplateId,
						campaignType: campaignType,
						campaignName: cam.campaignName,
						campaignStatus: cam.status,
						replyAddress: cam.replyAddress,
						fromAddress: cam.fromAddress,
					};
				});
				self.setState(
					{ campaigns, loadingText: "Finding Groups", loadingPct: 90 },
					() => {
						if (getGroups) {
							this.getGroups(true);
						} else {
							this.editStep();
						}
					}
				);
			});
	}
	getGroups(firstLoad) {
		var self = this;
		this.getTags();
		axios.get("/group?hideUsers=true&recordLimit=1000").then((response) => {
			var groups = response.data.Groups.map((grp) => {
				return { label: grp.groupName, value: grp.id };
			});
			self.setState({ groups, pageLoaded: true }, () => {
				if (!firstLoad) {
					this.editStep();
				}
				if (self.firstLoad) {
					try {
						// let element = this.refs.holder;
						let element = document.getElementById("automationOuter");
						let outerHolder = document.getElementById("automationOuterHolder");

						var width =
							element.getBoundingClientRect().width / 2 - window.innerWidth / 2;
						if (width) {
							jQuery(outerHolder).scrollLeft(width);
						}
						// document.getElementsByClassName('automationOption')[0].scrollIntoView()
					} catch (e) { }
					self.firstLoad = false;
				} else {
					var query = queryString.parse(this.props.location.search);
					if (query.reselect && query.reselect == "true" && query.step) {
						//we're coming back from a template edit, and we need to respawn the templte picker
						var step = AutomationFunctions.getStepByGuid(
							query.step,
							this.state.automationData.steps
						);
						if (step) {
							self.editStep(step, { reselectTemplate: true });
						}
					}
				}
			});
		});
	}
	getTags() {
		axios.get("/tags?tagType=0").then((res) => {
			this.setState({ tags: res.data });
		});

		axios.get("/tags?tagType=1").then((res) => {
			this.setState({ linktags: res.data });
		});
	}
	getStepStats() {
		if (this.unmouting) return;
		var self = this;
		axios
			.get("/automation/" + this.state.automationId + "/v2/stats/steps")
			.then((response) => {
				self.setState({ stepStats: response.data.Stats });
				this.statTimer = setTimeout(this.getStepStats, 2000);
			})
			.catch((err) => {
				this.statTimer = setTimeout(this.getStepStats, 2000);
			});
	}
	addStepDrawer(parentStep, dataId) {
		var drawerContent = (
			<AddStepDrawer
				reRender={this.reRender}
				chosenStepType={this.goAddNewStep}
				save={this.saveAutomation}
				conditionPermission={this.state.conditionPermission}
				applications={this.state.applications}
				themeapp={
					this.state.isThemeDesigner
						? this.state.automationData.Application
						: null
				}
				parentStep={parentStep}
				dataId={dataId}
				trigger={this.state.automationData.steps[0]}
				history={this.props.history}
			/>
		);
		this.props.dispatch(
			siteMaster.alterSiteDrawer(
				true,
				true,
				"right",
				drawerContent,
				true,
				"600px",
				undefined
			)
		);
	}
	addAutomation(auto) {
		let automations = this.state.automations;
		automations.push(auto);
		this.setState({ automations });
	}

	goAddNewStep(stepType, parentStep, dataId, secondary) {
		var parentGuid = parentStep && parentStep.guid ? parentStep.guid : 0;
		if (
			Array.isArray(parentStep) &&
			(parentStep[0].type === "yes" || parentStep[0].type === "no")
		)
			parentGuid = parentStep[0].guid;

		var guid = HelperFunctions.createLocalGuid();
		var newStepData = {
			type: stepType,
			stepOptions: {},
			complete: false,
			guid: guid,
			parentGuid: parentGuid,
		};

		if (stepType === "email") {
			newStepData.stepOptions.subject = { value: "", required: true };
			newStepData.stepOptions.fromName = {
				value: this.props.user.userData.firstName,
				required: true,
			};
			newStepData.stepOptions.fromAddress = {
				value: this.props.user.userData.emailAddress,
				required: true,
			};
			newStepData.stepOptions.replyAddress = {
				value: this.props.user.userData.emailAddress,
				required: false,
			};
			newStepData.stepOptions.campaign = {};
		}
		if (stepType === "sms") {
			newStepData.stepOptions.subject = { value: "", required: true };
			newStepData.stepOptions.customFieldId = { value: "-1", required: true };
			newStepData.stepOptions.campaign = { options: {} };
		}
		if (stepType === "notification") {
			newStepData.stepOptions.subject = { value: "", required: true };
			newStepData.stepOptions.fromName = {
				value: this.props.user.userData.firstName,
				required: true,
			};
			newStepData.stepOptions.fromAddress = {
				value: this.props.user.userData.emailAddress,
				required: true,
			};
			newStepData.stepOptions.replyAddress = {
				value: this.props.user.userData.emailAddress,
				required: false,
			};
			newStepData.stepOptions.toAddresses = [
				{
					value: this.props.user.userData.emailAddress,
				},
			];
			newStepData.stepOptions.content = { value: "", required: true };
		}
		if (stepType === "redirect") {
			newStepData.stepOptions.redirectGuid = { value: "", required: true };
		}
		if (stepType === "webhook") {
			newStepData.stepOptions.method = { value: "POST", required: true };
			newStepData.stepOptions.url = { value: "", required: true };
			newStepData.stepOptions.headers = {
				value: [{ key: "Content-Type", value: "application/json" }],
				required: false,
			};
			newStepData.stepOptions.body = { value: "", required: false };
		}

		if (stepType === "delay") {
			newStepData.stepOptions.delay_unit = { value: "hours", required: true };
			newStepData.stepOptions.delay_value = { value: "1", required: true };
			newStepData.stepOptions.delay_time_hours = {
				value: "12",
				required: false,
			};
			newStepData.stepOptions.delay_time_mins = {
				value: "00",
				required: false,
			};
			newStepData.stepOptions.delay_daysOfWeek = { value: [], required: false };
			newStepData.stepOptions.delay_dayOfMonth = {
				value: "1",
				required: false,
			};
			newStepData.stepOptions.delay_exactDate = {
				value: moment().format("YYYY-MM-DD"),
				required: false,
			};
		}

		if (stepType === "wait") {
			newStepData.stepOptions.triggerType = { value: "0" };
			newStepData.stepOptions.workflowEmailGuid = { value: "-1" };
			newStepData.stepOptions.workflowEmailActionType = { value: "0" };
			newStepData.stepOptions.customFieldId = { value: "-1" };
			newStepData.stepOptions.customFieldAction = { value: "=" };
			newStepData.stepOptions.customFieldValue = { value: "" };
			newStepData.stepOptions.segmentId = { value: "-1" };
			newStepData.stepOptions.campaignId = { value: "-1" };
			newStepData.stepOptions.linkClicked = { value: "" };
			newStepData.stepOptions.trigger = { value: "" };
			newStepData.stepOptions.groupId = { value: "-1" };
			newStepData.stepOptions.subscriberState = { value: "" };
		}

		if (stepType === "condition" || stepType === "score") {
			newStepData.stepOptions.triggerType = { value: "-1" };
			newStepData.stepOptions.workflowEmailGuid = { value: "-1" };
			newStepData.stepOptions.workflowEmailActionType = { value: "0" };
			newStepData.stepOptions.customFieldId = { value: "-1" };
			newStepData.stepOptions.customFieldAction = { value: "=" };
			newStepData.stepOptions.customFieldValue = { value: "" };
			newStepData.stepOptions.segmentId = { value: "-1" };
			newStepData.stepOptions.campaignId = { value: "" };
			newStepData.stepOptions.linkClicked = { value: "" };
			newStepData.stepOptions.groupId = { value: "-1" };
			newStepData.stepOptions.subscriberState = { value: "" };
			if (stepType !== "score")
				newStepData.steps = [
					[{ type: "yes", guid: guid }],
					[{ type: "no", guid: guid }],
				];
			if (secondary) {
				this.moveStepsToBranch(secondary, parentStep, newStepData, dataId);
			}

			if (stepType === "score") {
				newStepData.stepOptions.scoreChange = { value: "inc" };
				newStepData.stepOptions.scoreValue = { value: 1 };
			}
		}

		if (stepType === "action") {
			newStepData.stepOptions.actionType = { value: "-1", required: true };
			newStepData.stepOptions.customFieldId = { value: "-1" };
			newStepData.stepOptions.customFieldValue = { value: "" };
			newStepData.stepOptions.groupId = { value: "-1" };
			newStepData.stepOptions.tagId = { value: "" };
		}
		if (stepType === "goal") {
			newStepData.stepOptions.name = { value: "New Goal", required: true };
			newStepData.stepOptions.triggerType = { value: "-1" };
			newStepData.stepOptions.triggerPosition = { value: "0" };
			newStepData.stepOptions.workflowEmailGuid = { value: "-1" };
			newStepData.stepOptions.workflowEmailActionType = { value: "0" };
			newStepData.stepOptions.customFieldId = { value: "-1" };
			newStepData.stepOptions.customFieldAction = { value: "=" };
			newStepData.stepOptions.customFieldValue = { value: "" };
			newStepData.stepOptions.segmentId = { value: "-1" };
			newStepData.stepOptions.campaignId = { value: "-1" };
			newStepData.stepOptions.linkClicked = { value: "" };
			newStepData.stepOptions.goalEndAction = { value: "0" };
			newStepData.stepOptions.scoreOperator = { value: "gt" };
			newStepData.stepOptions.scoreValue = { value: 10 };
			newStepData.stepOptions.groupId = { value: "-1" };
			newStepData.stepOptions.subscriberState = { value: "" };
		}

		if (stepType === "split") {
			newStepData.stepOptions.splits = { value: 2, required: true };
			newStepData.steps = [
				[{ guid: HelperFunctions.createLocalGuid() }],
				[{ guid: HelperFunctions.createLocalGuid() }],
			];
			this.moveStepsToBranch("yes", parentStep, newStepData, dataId);
		}

		if (stepType === "pipedrive") {
			newStepData.stepOptions.triggerType = { value: "0", required: true };
			newStepData.stepOptions.field = { value: "", required: true };
			newStepData.stepOptions.value = { value: undefined };
			newStepData.stepOptions.activity = {
				subject: { value: "", required: true },
				done: { value: "", required: false },
				type: { value: "", required: true },
				due_date: { value: "", required: false },
				due_time: { value: "", required: false },
				duration: { value: "", required: false },
				user_id: { value: "", required: false },
				note: { value: "", required: false },
			};
		}

		if (parseInt(stepType)) {
			let app = this.state.applications.find(
				(a) => a.ApplicationId === stepType
			);
			if (this.state.isThemeDesigner && this.state.automationData.Application) {
				app = {
					Application: this.state.automationData.Application,
					ApplicationId: this.state.automationData.Application.id,
				};
			}
			let hook = app.Application.options.hooks[secondary];
			newStepData = {
				type: stepType,
				stepOptions: {},
				complete: true,
				guid: HelperFunctions.createLocalGuid(),
				parentGuid: parentGuid,
				action: hook.action,
			};
			hook.build.forEach((prop) => {
				newStepData.stepOptions[prop.value] = {
					value: "",
					required: !!prop.required,
				};
			});
		}

		parentStep.splice(dataId + 1, 0, newStepData);

		if (stepType === "email") {
			//add a delay step after the new email step
			newStepData = {
				type: "delay",
				stepOptions: {
					delay_unit: { value: "days", required: true },
					delay_value: { value: "2", required: true },
					delay_time_hours: { value: "12", required: false },
					delay_time_mins: { value: "00", required: false },
					delay_daysOfWeek: { value: [], required: false },
					delay_dayOfMonth: { value: "1", required: false },
					delay_exactDate: {
						value: moment().format("YYYY-MM-DD"),
						required: false,
					},
				},
				complete: true,
				guid: HelperFunctions.createLocalGuid(),
				parentGuid: parentGuid,
			};
			parentStep.splice(dataId + 2, 0, newStepData);
		}

		this.props.dispatch(
			siteMaster.alterSiteDrawer(false, true, "right", null, true)
		);

		this.renderSteps(this.state.automationData.steps);
		this.editStep(parentStep[dataId + 1]);
	}

	moveStepsToBranch(secondary, parentStep, newStepData, dataId) {
		//move subsequent stuff to the yes or no branch
		var dataLoc = 0;
		if (secondary == "no") dataLoc = 1;
		parentStep.forEach((st, index) => {
			if (index > dataId) {
				newStepData.steps[dataLoc].push(st);
			}
		});
		parentStep.splice(dataId + 1, parentStep.length - 1);
	}

	removeStep(parentStep, dataId, guid) {
		this.setState({ removingStep: true, parentStep, dataId })
		const step = AutomationFunctions.getStepByGuid(guid, this.state.automationData.steps);
		var drawerContent = (
			<ConfirmRemoveStepDrawer
				status={this.state.automationData.status}
				parentStep={parentStep}
				guid={guid}
				step={step}
				AutomationId={this.state.automationId}
				dataId={dataId}
				removeHighlight={() => this.setState({ highlightStep: null })}
				confirmRemoveStep={this.confirmRemoveStep}
				chooseAStepAndReturn={this.chooseAStepAndReturn}
			/>
		);
		this.props.dispatch(
			siteMaster.alterSiteDrawer(
				true,
				true,
				"right",
				drawerContent,
				true,
				"500px",
				undefined
			)
		);
	}

	async confirmRemoveStep(parentStep, dataId, deleteChildrenType, currentSubscribers, destination) {
		this.setState({ removingStep: false })
		var ourStep = parentStep[dataId];
		var foundRedirect = false;
		//check this step isnt pointed at from a redirect
		function parseSteps(steps, parentLevel) {
			try {
				steps.forEach((step) => {
					if (
						step.type == "redirect" &&
						step.stepOptions.redirectGuid &&
						step.stepOptions.redirectGuid.value
					) {
						// <div style={{ height: "2px", width: "2px", backgroundColor: "black", position: "absolute" }} className="redirector" >{step.stepOptions.redirectGuid.value}</div>
						if (step.stepOptions.redirectGuid.value == ourStep.guid)
							foundRedirect = true;
					}
					if (step.steps && step.steps.length) {
						step.steps.forEach((s) => parseSteps(s, parentLevel + 4));
					}
				});
			} catch (e) { }
		}
		parseSteps(this.state.automationData.steps, 1);

		if (foundRedirect) {
			this.setState({
				alertMessage:
					"Another step is redirecting here. Please remove the redirect.",
				alertOpen: true,
			});
			return;
		}

		if (currentSubscribers && currentSubscribers !== 'ignore') {
			await axios.put('/automation/' + this.state.automationId + '/step/' + ourStep.guid + '/subscribers', { action: currentSubscribers, destination })
		}

		if (
			deleteChildrenType &&
			(deleteChildrenType == 1 || deleteChildrenType == 2)
		) {
			let ourStep = JSON.parse(JSON.stringify(parentStep[dataId]));
			let children = null;
			if (deleteChildrenType == 1) {
				children = ourStep.steps[0];
			}
			if (deleteChildrenType == 2) {
				children = ourStep.steps[1];
			}
			//get rid of the YES or NO entry
			children.splice(0, 1);
			parentStep.splice(dataId, 1);
			children.forEach((child) => {
				parentStep.push(child);
			});
		} else {
			parentStep.splice(dataId, 1);
		}

		this.renderSteps(this.state.automationData.steps);

		this.props.dispatch(
			siteMaster.alterSiteDrawer(false, true, "right", null, true)
		);
	}

	searchForChildren(step, dataToFind) {
		var found = 0;
		if (step.type == dataToFind) found += 260;
		if (Array.isArray(step)) {
			step.forEach((st) => {
				found += this.searchForChildren(st, dataToFind);
			});
		}
		if (step.steps) {
			step.steps.forEach((st) => {
				found += this.searchForChildren(st, dataToFind);
			});
		}
		return found;
	}

	editStep(step, extraParams, forceViewTriggerSettings = false) {
		this.setState({ editedStep: step });
		var self = this;
		var isRunning =
			this.state.automationData.status === "Running" ||
			this.state.automationData.status === "Starting" ||
			this.state.automationData.status === "Archived" ||
			this.state.automationData.status === "Errored" ||
			this.state.automationData.status === "Testing";

		var drawerContent;
		if (!step) step = this.state.currentStep;
		var width = "500px";
		if (forceViewTriggerSettings === false && isRunning) {
			width = "700px";
			const nextStep = findNextStep(this.state.automationData.steps, step);
			drawerContent = (
				<DrawerViewRunningStep
					options={this.state.automationData.options}
					automationId={this.state.automationId}
					nextStep={nextStep}
					step={step}
					stats={self.state.stepStats}
					goTo={this.goTo}
					loadEmailPreview={this.loadEmailPreview}
					onViewTriggerSettingsClick={() => {
						this.editStep(step, null, true);
					}}
				/>
			);
		} else {
			const commonProps = {
				step,
				saveStep: this.saveStep,
				readOnly: forceViewTriggerSettings,
				onBackClick: () => this.editStep(step),
				automationId: this.state.automationId
			};

			switch (step.type) {
				case "initial":
					drawerContent = (
						<DrawerStepInitial
							applications={this.state.applications}
							forms={this.state.forms}
							linktags={this.state.linktags}
							app={this.state.automationData.Application}
							groups={this.state.groups}
							customFields={this.state.customFields}
							segments={this.state.segments}
							getGroups={this.getGroups}
							getSegments={this.getSegments}
							getCustomFields={this.getCustomFields}
							campaigns={this.state.campaigns}
							automationStatus={this.state.automationData.status}
							firstRunAddExistng={this.state.automationData.addExisting}
							isThemeDesigner={this.state.isThemeDesigner}
							{...commonProps}
						/>
					);
					break;
				case "email":
					drawerContent = (
						<DrawerEmailStep
							history={this.props.history}
							automationData={this.state.automationData}
							{...extraParams}
							isThemeDesigner={this.state.isThemeDesigner}
							{...commonProps}
						/>
					);
					width = "700px";
					break;
				case "sms":
					drawerContent = (
						<DrawerSmsStep
							automationData={this.state.automationData}
							{...extraParams}
							isThemeDesigner={this.state.isThemeDesigner}
							{...commonProps}
						/>
					);
					width = "700px";
					break;
				case "delay":
					drawerContent = (
						<DrawerDelayStep
							isThemeDesigner={this.state.isThemeDesigner}
							{...commonProps}
						/>
					);
					break;
				case "wait":
					drawerContent = (
						<DrawerWaitStep
							steps={this.state.automationData.steps}
							apps={this.state.applications}
							customFields={this.state.customFields}
							segments={this.state.segments}
							getSegments={this.getSegments}
							campaigns={this.state.campaigns}
							isThemeDesigner={this.state.isThemeDesigner}
							groups={this.state.groups}
							{...commonProps}
						/>
					);
					break;
				case "score":
				case "condition":
					drawerContent = (
						<DrawerConditionStep
							steps={this.state.automationData.steps}
							apps={this.state.applications}
							customFields={this.state.customFields}
							segments={this.state.segments}
							getSegments={this.getSegments}
							campaigns={this.state.campaigns}
							groups={this.state.groups}
							forms={this.state.forms}
							isThemeDesigner={this.state.isThemeDesigner}
							{...commonProps}
						/>
					);
					break;
				case "action":
					drawerContent = (
						<DrawerActionStep
							setTags={(tags) => this.setState({ tags })}
							tags={this.state.tags}
							applications={this.state.applications}
							steps={this.state.automationData.steps}
							customFields={this.state.customFields}
							segments={this.state.segments}
							groups={this.state.groups}
							getGroups={this.getGroups}
							getSegments={this.getSegments}
							getCustomFields={this.getCustomFields}
							isThemeDesigner={this.state.isThemeDesigner}
							{...commonProps}
						/>
					);
					break;
				case "goal":
					drawerContent = (
						<DrawerGoalStep
							automationId={this.state.automationId}
							steps={this.state.automationData.steps}
							automations={this.state.automations}
							addAutomation={this.addAutomation}
							customFields={this.state.customFields}
							segments={this.state.segments}
							groups={this.state.groups}
							getGroups={this.getGroups}
							getSegments={this.getSegments}
							getCustomFields={this.getCustomFields}
							campaigns={this.state.campaigns}
							isThemeDesigner={this.state.isThemeDesigner}
							apps={this.state.applications}
							{...commonProps}
						/>
					);
					break;
				case "notification":
					width = "700px";
					drawerContent = (
						<NotificationStep
							steps={this.state.automationData.steps}
							customFields={this.state.customFields}
							segments={this.state.segments}
							groups={this.state.groups}
							getGroups={this.getGroups}
							getSegments={this.getSegments}
							getCustomFields={this.getCustomFields}
							campaigns={this.state.campaigns}
							isThemeDesigner={this.state.isThemeDesigner}
							automationData={this.state.automationData}
							{...commonProps}
						/>
					);
					break;
				case "redirect":
					width = "700px";
					drawerContent = (
						<RedirectStep
							chooseAStepAndReturn={this.chooseAStepAndReturn}
							steps={this.state.automationData.steps}
							customFields={this.state.customFields}
							segments={this.state.segments}
							groups={this.state.groups}
							getGroups={this.getGroups}
							getSegments={this.getSegments}
							getCustomFields={this.getCustomFields}
							campaigns={this.state.campaigns}
							isThemeDesigner={this.state.isThemeDesigner}
							{...commonProps}
						/>
					);
					break;
				case "webhook":
					width = "700px";
					drawerContent = (
						<WebhookStep
							steps={this.state.automationData.steps}
							customFields={this.state.customFields}
							segments={this.state.segments}
							groups={this.state.groups}
							getGroups={this.getGroups}
							getSegments={this.getSegments}
							getCustomFields={this.getCustomFields}
							campaigns={this.state.campaigns}
							isThemeDesigner={this.state.isThemeDesigner}
							{...commonProps}
						/>
					);
					break;
				case "split":
					width = "500px";
					drawerContent = <DrawerSplitStep {...commonProps} />;
					break;
				case "pipedrive":
					drawerContent = (
						<Pipedrive
							steps={this.state.automationData.steps}
							applications={this.state.applications}
							customFields={this.state.customFields}
							isThemeDesigner={this.state.isThemeDesigner}
							{...commonProps}
						/>
					);
					break;
				default:
					if (parseInt(step.type)) {
						let accApp = this.state.applications.find(
							(a) => a.ApplicationId === step.type
						);
						if (
							this.state.isThemeDesigner &&
							this.state.automationData.Application
						) {
							accApp = {
								Application: this.state.automationData.Application,
								ApplicationId: this.state.automationData.Application.id,
							};
						}
						drawerContent = (
							<GenericConfigure
								steps={this.state.automationData.steps}
								accApp={accApp}
								customFields={this.state.customFields}
								campaigns={this.state.campaigns}
								isThemeDesigner={this.state.isThemeDesigner}
								{...commonProps}
							/>
						);
					}
					break;
			}
		}
		this.props.dispatch(
			siteMaster.alterSiteDrawer(false, true, "right", null, true)
		);
		this.setState({ currentStep: step }, () => {
			this.props.dispatch(
				siteMaster.alterSiteDrawer(
					true,
					true,
					"right",
					drawerContent,
					true,
					width,
					undefined
				)
			);
		});
	}

	chooseAStepAndReturn(step) {
		this.props.dispatch(
			siteMaster.alterSiteDrawer(false, true, "right", null, false)
		);
		this.setState({ chooseAStepSource: step, chooseAStepChoosing: true });
	}

	chooseAStepReturn(chosenStep) {
		var step = AutomationFunctions.getStepByGuid(
			this.state.chooseAStepSource.guid,
			this.state.automationData.steps
		);
		var chosenStepGuid = chosenStep.guid;
		if (this.state.removingStep) {
			this.setState({ removingStep: true, highlightStep: chosenStepGuid })
			var drawerContent = (
				<ConfirmRemoveStepDrawer
					status={this.state.automationData.status}
					parentStep={this.state.parentStep}
					guid={this.state.chooseAStepSource.guid}
					AutomationId={this.state.automationId}
					dataId={this.state.dataId}
					step={JSON.parse(JSON.stringify(this.state.chooseAStepSource))}
					removeHighlight={() => this.setState({ highlightStep: null })}
					confirmRemoveStep={this.confirmRemoveStep}
					chooseAStepAndReturn={this.chooseAStepAndReturn}
					currentContacts={'move'}
					destination={chosenStepGuid}
				/>
			);
			this.setState({ chooseAStepChoosing: false, chooseAStepSource: {} }, this.renderSteps);
			return this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px", undefined));
		} else {
			step.stepOptions.redirectGuid.value = chosenStepGuid;
		}
		//check all steps between chosen step and our step to make sure there is a delay or wait, and a condition
		var hasRedirectErrors = this.checkRedirectStepIsValid(
			chosenStep,
			this.state.chooseAStepSource
		);

		if (hasRedirectErrors != "") {
			this.setState({ alertMessage: hasRedirectErrors, alertOpen: true });
		}
		step.complete = true;
		step.wizardComplete = true;
		this.saveStep();
		this.setState(
			{ chooseAStepChoosing: false, chooseAStepSource: {} },
			this.renderSteps
		);
	}

	checkAllRedirectStepsValid() {
		let redirectSteps = AutomationFunctions.getStepsByType(
			"redirect",
			this.state.automationData.steps
		);
		let response = { error: false, message: "" };

		redirectSteps.forEach((redirectStep) => {
			let chosenStep = AutomationFunctions.getStepByGuid(
				redirectStep.stepOptions.redirectGuid.value,
				this.state.automationData.steps
			);
			let inValid = this.checkRedirectStepIsValid(chosenStep, redirectStep);
			if (inValid) {
				response.error = true;
				response.message = inValid;
			}
		});
		return response;
	}

	checkRedirectStepIsValid(chosenStep, redirectStep) {
		var chosenStepGuid = chosenStep.guid;
		if (chosenStep.type == "redirect") {
			return "Cannot redirect to a redirect step";
		}

		var errorFound = "";
		var origSteps = this.state.automationData.steps;

		let stepsAfterChosen = [];
		function parseSteps(theSteps, foundChosen, tmpSteps) {
			var tmpFoundChosen = JSON.parse(JSON.stringify(foundChosen));
			var foundCondition = false;
			theSteps.forEach((st) => {
				var isChosenStep = false;
				if (st.type == "no" || st.type == "yes") return;
				if (st.guid == chosenStepGuid) {
					tmpFoundChosen = true;
					isChosenStep = true;
				}
				if (st.type == "condition") {
					foundCondition = true;
					if (tmpFoundChosen) {
						tmpSteps.push([[], []]);
					}
					parseSteps(
						st.steps[0],
						tmpFoundChosen,
						tmpFoundChosen ? tmpSteps[tmpSteps.length - 1][0] : tmpSteps
					);
					parseSteps(
						st.steps[1],
						tmpFoundChosen,
						tmpFoundChosen ? tmpSteps[tmpSteps.length - 1][1] : tmpSteps
					);
				}

				if (tmpFoundChosen && !isChosenStep) {
					tmpSteps.push({ type: st.type, guid: st.guid });
				}
			});
		}
		parseSteps(origSteps, false, stepsAfterChosen);

		if (!stepsAfterChosen.length) return "";

		let foundOkExitStep = false;
		let foundOkDelayStep = false;

		function markSteps(steps, errored, foundGuid) {
			steps.forEach((step) => {
				if (step.guid == chosenStepGuid) {
					foundGuid = true;
				}
				if (foundGuid) step.pathError = errored;
				if (step.steps) {
					step.steps.forEach((st2) => {
						markSteps(st2, errored, foundGuid);
					});
				}
			});
		}

		function checkSteps(steps) {
			var foundCond = false;
			var foundDelay = false;
			let condSteps = steps.filter((st) => Array.isArray(st));

			steps.forEach((step) => {
				if (step.type == "delay") {
					foundOkDelayStep = true;
				}
			});

			condSteps.forEach((st) => {
				checkSteps(st);
			});

			if (condSteps.length == 0) {
				if (steps.length == 0) {
					foundOkExitStep = true;
				} else {
					let lastStep = steps[steps.length - 1];
					if (lastStep.type != "redirect") foundOkExitStep = true;
				}
			}
		}

		checkSteps(stepsAfterChosen, false);

		var errorFound = "";
		if (!foundOkDelayStep) {
			chosenStep.pathError = true;
			markSteps(origSteps, true, false);
			errorFound = "Please add at least one delay step in the area marked red.";
		} else {
			chosenStep.pathError = false;
			markSteps(origSteps, false, false);
		}

		return errorFound;
	}

	saveStep(saveAutomation, forceSave, dontMoveToNextWizardStep, dontCloseDrawer) {
		if (!dontCloseDrawer) this.props.dispatch(siteMaster.alterSiteDrawer(false));
		if (this.state.editedStep) this.state.editedStep.lastUpdated = new Date(); //so we know when it was last updated for debugging
		if (saveAutomation) this.saveAutomation(true, forceSave);

		if (
			this.state.editedStep &&
			this.state.editedStep.wizardComplete === true &&
			!dontMoveToNextWizardStep
		) {
			let nextStep = findNextStep(
				this.state.automationData.steps,
				this.state.editedStep
			);
			if (!nextStep) {
				// TODO
				// it could be that the next step is down another condition lane so we have to look up for the nearest condition and check the next array item
				const nextArr = findNextLane(
					this.state.automationData.steps,
					this.state.editedStep
				);
				if (nextArr) {
					nextStep = nextArr[0];
					if (!nextStep.stepOptions && nextArr[1]) nextStep = nextArr[1];
				}
			}
			if (nextStep && nextStep.wizardComplete === false) {
				setTimeout(() => this.editStep(nextStep), 400);
			}
		}
		this.setState({
			editedStep: null,
		});
	}

	getStepWizardText(step) {
		let text = i18n.t("automation:add.completeStep");
		return text;
	}

	renderSteps(stepsData, prevStep, incomingNextStepsUnreachable) {
		var self = this;
		if (!stepsData) stepsData = this.state.automationData.steps;

		var hasDoneInitialStep = false;
		var foundInitialStep = this.state.automationData.steps.find(
			(st) => st.type == "initial"
		);
		if (foundInitialStep && foundInitialStep.complete == true)
			hasDoneInitialStep = true;
		if (foundInitialStep && foundInitialStep.wizardComplete === false)
			hasDoneInitialStep = true;
		if (this.state.isThemeDesigner) hasDoneInitialStep = true;
		if (!this.state.chooseAStepChoosing) {
			this.checkAllRedirectStepsValid();
		}
		if (this.state.automationData.steps && this.state.automationData.steps.length > 1) {
			hasDoneInitialStep = true;
		}

		let nextStepsUnreachable = false;
		if (incomingNextStepsUnreachable) nextStepsUnreachable = true;
		return stepsData.map((step, dataId) => {
			if (dataId) {
				prevStep = stepsData[dataId - 1];
			}
			var outerStyle = {};
			if (step.type == "no" || step.type == "yes") {
				outerStyle.marginTop = "2px";
				outerStyle.paddingTop = "0";
			}

			if (step.type === "combine") {
				outerStyle.marginTop = 30;
			}

			let linesClass = "";
			if (step.pathError) linesClass = "automation-line-error";

			if (hasDoneInitialStep || step.type == "initial") {
				let disabled = false;
				if (prevStep) {
					if (!this.state.isThemeDesigner) {
						if (
							(prevStep.type == "yes" || prevStep.type == "no") &&
							step.wizardComplete === false
						) {
							disabled = true;
						} else if (prevStep.wizardComplete === false) {
							disabled = true;
						}
					}
				}
				var holderClasses = "automationLine " + linesClass;
				if (step.type != "initial" && step.type != "yes" && step.type != "no")
					holderClasses += " lineAbove";
				if (disabled) holderClasses += " automationDisabled";
				else if (nextStepsUnreachable) holderClasses += " nextStepsUnreachable"

				let thisStepSetsUnreachable = false;
				if (step.type === "goal" && step?.stepOptions?.triggerPosition?.value === "0") {
					if (step?.stepOptions?.triggerType?.value == "4") {
						nextStepsUnreachable = true;
						thisStepSetsUnreachable = true;
					}
				}


				let highlight = (this.state.highlightStep === step.guid && this.state.removingStep)
				if (this.state.contactHistory && this.state.contactHistory.some(h => h.StepGuid === step.guid)) highlight = true

				let contactOnStep = !!(this.state.contact?.AutomationQueues?.length && this.state.contact.AutomationQueues[0].NextGuid === step.guid)
				if (contactOnStep) {
					highlight = true
					const nextStep = findNextStep(this.state.automationData.steps, step);
					contactOnStep = <AutomationOptionContact AutomationId={this.state.automationId} step={step} contact={this.state.contact} history={this.state.contactHistory} nextStep={nextStep} refresh={() => {
						const backup = { SubscriberId: this.state.SubscriberId }
						this.setState({ SubscriberId: null }, () => this.setState(backup))
					}} />
				}

				return (
					<div
						style={outerStyle}
						key={dataId}
						id={step.guid}
						className={holderClasses}
					>
						{step.type === "combine" && (
							<CombineElement
								steps={this.state.automationData.steps}
								step={step}
							/>
						)}

						{step.type == "yes" && (
							<div className="automationYesNo">
								<UILIB.Icons
									icon="thumbsUp"
									color="#fff"
									style={{ width: 20, height: 20, margin: "0 auto" }}
								/>
							</div>
						)}
						{step.type == "no" && (
							<div className="automationYesNo isNo">
								<UILIB.Icons
									icon="thumbsDown"
									color="#fff"
									style={{ width: 20, height: 20, margin: "0 auto" }}
								/>
							</div>
						)}

						{/* {dataId > 0 && this.state.automationData.status == "Running" && <div className="automationAddStep lineBelow" style={{ width: "100%", cursor: "initial" }}><span className="automationQueuedHolder">{stepStatsQueued} queued</span></div>} */}

						{step.type != "yes" && step.type != "no" && (
							<div>
								<AutomationOption
									id={"optionHolder_" + step.guid}
									automationId={this.state.automationId}
									forms={this.state.forms}
									options={this.state.automationData.options}
									disabled={disabled}
									allSteps={this.state.automationData.steps}
									step={step}
									automations={this.state.automations}
									applications={this.state.applications}
									dataId={dataId}
									removeStep={this.removeStep}
									stepsData={stepsData}
									tags={this.state.tags}
									linktags={this.state.linktags}
									editStep={(event) => {
										if (this.state.chooseAStepChoosing) {
											this.chooseAStepReturn(event);
										} else if (!disabled) {
											this.editStep(event);
										}
									}}
									groupsData={this.state.groups}
									segmentsData={this.state.segments}
									customFieldsData={this.state.customFields}
									campaignsData={this.state.campaigns}
									activateError={this.state.activateError}
									automationStatus={this.state.automationData.status}
									stepStats={self.state.stepStats}
									choosing={this.state.chooseAStepChoosing}
									choosingSourceGuid={
										this.state.chooseAStepSource
											? this.state.chooseAStepSource.guid
											: ""
									}
									highlightStep={highlight}
									contactOnStep={contactOnStep}
									isThemeDesigner={this.state.isThemeDesigner}
									Application={this.state.automationData.Application}
									mailboxes={this.state.mailboxes}
									saveAndGo={this.saveAndGo}
									addTrigger={this.addTrigger}
									siteTrackerEvents={this.state.siteTrackerEvents}
								/>
								{thisStepSetsUnreachable && <div className="thisStepUnreachable">
									<div className="thisStepUnreachableIcon">
										<UILIB.Icons icon="warning" color="red" style={{ width: 20, height: 20, margin: "0 auto" }} />
									</div>
									<div className="thisStepUnreachableTextHolder">Step prevents further steps from being reached</div>
								</div>}
							</div>
						)}

						{!this.state.isThemeDesigner &&
							!disabled &&
							step.wizardComplete === false && (
								<div className="wizardToDo" onClick={() => this.editStep(step)}>
									{this.getStepWizardText(step)}
								</div>
							)}

						{step.type == "initial" && hasDoneInitialStep == false && (
							<div className="automationWizardInitialStep">
								{i18n.t("automation:add.wizard.addTrigger")}
							</div>
						)}

						{step.type !== "condition" &&
							step.type !== "split" &&
							step.type !== "redirect" &&
							this.state.automationData.status !== "Running" &&
							this.state.automationData.status !== "Starting" &&
							this.state.automationData.status !== "Archived" &&
							this.state.automationData.status !== "Errored" &&
							this.state.automationData.status !== "Testing" &&
							hasDoneInitialStep &&
							!nextStepsUnreachable && (
								<div className={"automationAddStep lineAbove " + linesClass}>
									{this.state.chooseAStepChoosing == false && (
										<div
											className={
												"automationAddStepButton  " +
												(step.wizardComplete === false &&
													!this.state.isThemeDesigner
													? "disabled"
													: "")
											}
											onClick={() =>
												step.wizardComplete === false &&
													!this.state.isThemeDesigner
													? {}
													: this.addStepDrawer(stepsData, dataId)
											}
										>
											<UILIB.Icons icon="plus" style={{ display: "flex" }} />
										</div>
									)}
								</div>
							)}

						{step.type == "initial" &&
							hasDoneInitialStep == true &&
							stepsData.length == 1 && (
								<div className="automationWizardInitialStep">
									{i18n.t("automation:add.wizard.addFirstStep")}
								</div>
							)}

						{step.steps && step.steps.length && (
							<div
								className={"automationSplitHolder " + linesClass}
								id={"split_" + step.guid}
							>
								{step.steps.map((s, i) => (
									<div
										key={i}
										className={"automationSplit " + linesClass}
										id={s[0].guid}
									>
										{this.renderSteps(s, step, nextStepsUnreachable)}
									</div>
								))}
							</div>
						)}
					</div>
				);
			}
		});
	}

	activateClicked(event) {
		var self = this;
		// var isActivating = event.target.checked;
		var isActivating = event
		this.setState({
			alertMessage: i18n.t("automation:overview.savingPleaseWait"),
			alertOpen: true,
		});
		if (isActivating == true) {
			let redirectsOk = this.checkAllRedirectStepsValid();
			if (redirectsOk.error == true) {
				return this.setState({
					activateError: redirectsOk.message,
				})
			}
			if (!this.props.account.accountMaster.doneNewUserQuestions) {
				// show user form
				const drawerContent = <NewUserForm from="automation" />;
				return this.props.dispatch(
					siteMaster.alterSiteDrawer(
						true,
						true,
						"right",
						drawerContent,
						true,
						"100vw",
						undefined
					)
				)
			} else if (!this.props.account.accountMaster.approved) {
				return this.setState({
					activateError: "You cannot start Automations until your account has been approved",
				})
			} else if (this.props.account.accountMaster.paused) {
				return this.setState({
					activateError: "Account Paused: " + this.props.account.accountMaster.pausedReason,
				})
			}

			//check the workflow is all complete
			this.setState({
				activateError: "",
			});
			var failedSteps = AutomationFunctions.checkAllComplete(
				this.state.automationData.steps
			);

			if (failedSteps && failedSteps.length) {
				this.setState({
					activateError: i18n.t("automation:overview.pleaseCompleteAllSteps"),
				});
			} else if (this.state.automationData.steps.length < 2) {
				this.setState({
					activateError: i18n.t("automation:overview.pleaseAddMoreThanOneStep"),
				});
			} else {
				const initial = this.state.automationData.steps[0];
				if (
					this.state.automationData.status == "Draft" &&
					(initial.triggerType.value == "0" ||
						initial.triggerType.value == "1" ||
						(Array.isArray(initial.triggerSteps) &&
							initial.triggerSteps.length &&
							initial.triggerSteps.some(
								(st) =>
									st.triggerType.value == "0" || st.triggerType.value == "1"
							)))
				) {
					var drawerContent = (
						<DrawerActivateFirstRun
							activateOption={this.goCloseActivateDrawer}
							automation={this.state.automationData}
							initialStep={initial}
						/>
					);
					this.props.dispatch(
						siteMaster.alterSiteDrawer(
							true,
							true,
							"right",
							drawerContent,
							true,
							"500px",
							undefined
						)
					);
				} else {
					this.setState({ activateError: "" }, this.goActivateWorkflow);
				}
			}
		} else {
			clearTimeout(this.statTimer);
			var automation = this.state.automationData;
			automation.status = "Paused";
			axios
				.put("/automation/" + this.state.automationId + "/pause")
				.then((response) => {
					self.setState({ automationData: automation });
				})
				.catch((err) => {
					this.setState({ activateError: "Error saving automation" });
				});
		}

		if (this.state.allowEdit) {
			this.setState({ allowEdit: false })
		}
	}

	showTierForbidsDrawer(tierPermissions) {
		let drawerContent = (
			<UILIB.TierLimitHolder
				type={tierPermissions.type || "Automation"}
				err={tierPermissions.err}
				allowedNumber={tierPermissions.allowedNumber}
				tierName={tierPermissions.tierName}
			/>
		);
		this.props.dispatch(
			siteMaster.alterSiteDrawer(
				true,
				true,
				"right",
				drawerContent,
				true,
				"600px",
				undefined
			)
		);
	}

	goCloseActivateDrawer(addExistingSubscribers, options) {
		this.props.dispatch(
			siteMaster.alterSiteDrawer(false, true, "right", null, true)
		);
		this.goActivateWorkflow(addExistingSubscribers, options);
	}

	goActivateWorkflow(addExistingSubscribers, options) {
		var automation = this.state.automationData;
		automation.options = options || automation.options;
		var oldStatus = automation.status;
		automation.status = oldStatus === "Draft" ? "Starting" : "Running";
		var self = this;
		if (!parseInt(automation.steps[0].triggerType.value)) {
			// integration so check for triggerSetter
			let app = this.state.applications.find(
				(a) => a.Application.appName === automation.steps[0].triggerType.value
			);
			if (
				app &&
				app.Application &&
				app.Application.options &&
				app.Application.options.triggerSetter
			) {
				// this would create any required webhooks from this automation
				axios.post(app.Application.options.triggerSetter).catch(() => { });
			}
		}
		axios.put("/automation/" + this.state.automationId, automation).then(() => {
			if (this.props.account.accountMaster.paused) return
			var qS = "resume";
			if (oldStatus == "Draft")
				qS = "start?addExisting=" + addExistingSubscribers;
			axios
				.put("/automation/" + this.state.automationId + "/" + qS)
				.then((response) => {
					self.setState({ automationData: automation }, this.getStepStats);
					if (oldStatus === "Draft") {
						this.timer = setTimeout(() => this.loadAutomation(true), 1000);
					}
				})
				.catch((err) => {
					if (err && err.data && err.data.error && err.data.error.tierName) {
						this.showTierForbidsDrawer(err.data.error);
						automation.status = oldStatus;
					} else {
						self.setState({ activateError: "Error saving automation" });
					}
				});
		});
	}

	allowForEdit() {
		this.setState({ allowEdit: true })
	}

	changeAutomationName(event) {
		var auto = this.state.automationData;
		auto.name = event.target.value;
		let nameError = "";
		if (!auto.name || !auto.name.trim()) {
			nameError = " ";
		}
		this.setState({ automationData: auto, nameError });
	}

	fullScreenChangedSize(element) {
		var width = jQuery(element).width();
		if (width) {
			jQuery(element).scrollLeft(width / 2);
		}
	}

	goZoom(zoom) {
		var zm = this.state.zoom;
		if (zoom) {
			zm += 0.1;
		} else {
			zm -= 0.1;
		}
		if (zm > 1) {
			zm = 1;
		}
		if (zm < 0.3) {
			zm = 0.3;
		}
		this.setState({ zoom: zm });
	}

	goTo(url, target) {
		if (target) {
			this.props.dispatch(
				siteMaster.alterSiteDrawer(false, true, "right", null, false)
			);
			window.open(url, target);
			return;
		}
		this.props.history.push(url);
	}
	loadEmailPreview(templateId) {
		var drawerContent = (
			<EmailPreviewer templateId={templateId} history={this.props.history} />
		);
		this.props.dispatch(
			siteMaster.alterSiteDrawer(
				true,
				true,
				"right",
				drawerContent,
				true,
				"100vw",
				"full"
			)
		);
	}
	showGlobalOptions() {
		var drawerContent = (
			<AutomationGlobalOptions
				automation={this.state.automationData}
				onChange={this.setGlobalOptions}
			/>
		);
		this.props.dispatch(
			siteMaster.alterSiteDrawer(
				true,
				true,
				"right",
				drawerContent,
				true,
				"500px",
				undefined
			)
		);
	}
	setGlobalOptions(automationData) {
		this.setState({ automationData: automationData });
	}
	unarchive() {
		const drawerContent = (
			<UILIB.DrawerConfirm
				header={"Unarchive?"}
				subheader="This will allow Contacts to enter the Automation again"
				showCancel={true}
				confirm={() => {
					this.setState({
						alertMessage: "Unarchiving Automation",
						alertOpen: true,
					});
					axios
						.put("/automation/" + this.state.automationId + "/unarchive")
						.then(() => {
							this.setState({
								alertMessage: "Automation Unrchived",
								alertOpen: true,
							});
							this.loadAutomation();
						});
				}}
			/>
		);
		this.props.dispatch(
			siteMaster.alterSiteDrawer(
				true,
				true,
				"right",
				drawerContent,
				true,
				null,
				true
			)
		);
	}
	countConditionSteps(steps) {
		let count = 0;
		function loopSteps(theSteps) {
			theSteps.forEach((st) => {
				if (st.type == "condition") {
					count++;
					st.steps.forEach((st2) => {
						loopSteps(st2);
					});
				} else if (st.steps && st.steps.length) {
					st.steps.forEach((st2) => {
						loopSteps(st2);
					});
				}
			});
		}
		loopSteps(steps);
		return count;
	}

	addContact() {
		this.setState({
			activateError: '',
		})
		var failedSteps = AutomationFunctions.checkAllComplete(
			this.state.automationData.steps
		)
		if (failedSteps && failedSteps.length) {
			this.setState({
				activateError: i18n.t("automation:overview.pleaseCompleteAllSteps"),
			})
		} else if (this.state.automationData.steps.length < 2) {
			this.setState({
				activateError: i18n.t("automation:overview.pleaseAddMoreThanOneStep"),
			})
		} else {
			this.saveAutomation(true, true)
			const content = <AutomationAddContact AutomationId={this.state.automationId} status={this.state.automationData.status} finished={(newStatus) => {
				if (newStatus) {
					const data = this.state.automationData
					data.status = newStatus
					this.setState({ automationData: data })
				}
			}} />
			this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", content, true, "500px", undefined))
		}
	}

	cancelTest() {
		const drawer = <AutomationCancelTest AutomationId={this.state.automationId} finished={(newStatus) => {
			if (newStatus) {
				const data = this.state.automationData
				data.status = newStatus
				this.setState({ automationData: data })
			}
		}} />
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, true, "500px", undefined));
	}

	findContact() {
		const content = <AutomationFindContact finished={(contactId) => {
			this.setState({ SubscriberId: contactId })
			this.props.dispatch(siteMaster.alterSiteDrawer(false, true, 'right', null))
		}} />;
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", content, true, "500px", undefined));

	}

	keyPress(event) {
		if (event.key === 'Enter') {
			this.saveAutomation(true);
			this.setState({ allowEdit: false })
		}
	}

	async changeNameTick() {
		if (!this.state.automationData.name.trim()) {
			return this.setState({
				nameError: " ",
				alertMessage: "Automation Name Required",
				alertOpen: true,
			})
		}
		await axios.put('/automation/' + this.state.automationId, {
			name: this.state.automationData.name.trim()
		})
		this.setState({
			allowEdit: false,
			nameError: "",
			alertMessage: "Name Saved",
			alertOpen: true,
		})
	}

	addTrigger(ev) {
		ev.preventDefault();
		ev.stopPropagation();
		let automationData = this.state.automationData;
		let triggerStep = automationData.steps[0];
		let newStep = {
			type: "initial",
			triggerType: { value: "-1", required: true },
			stepOptions: {},
			complete: false,
			guid: HelperFunctions.createLocalGuid(),
			parentGuid: 0,
		};
		if (triggerStep.triggerSteps && Array.isArray(triggerStep.triggerSteps)) {
			triggerStep.triggerSteps.push(newStep);
		} else {
			triggerStep.triggerSteps = [newStep];
		}

		this.setState({ automationData: automationData });
	}
	render() {
		if (this.state.pageErrored) {
			return (
				<div style={{ width: "100%", height: "100%", position: "fixed" }}>
					<div
						style={{
							margin: "0 auto",
							marginTop: "20px",
							fontSize: "12px",
							textAlign: "center",
							display: "block",
							position: "relative",
							top: "50%",
							transform: "translateY(-50%)",
						}}
					>
						Invalid Theme Id Specified
					</div>
				</div>
			);
		}

		if (!this.state.pageLoaded || !this.state.conditionPermission) {
			return (
				<div style={{ width: "100%", height: "100%", position: "fixed" }}>
					{this.state.loadingText != "" && (
						<div
							style={{
								margin: "0 auto",
								marginTop: "20px",
								fontSize: "12px",
								textAlign: "center",
								display: "block",
								position: "relative",
								top: "50%",
								transform: "translateY(-50%)",
							}}
						>
							<div
								style={{ width: "200px", margin: "auto", marginBottom: "10px" }}
							>
								<UILIB.SmallLineGraph
									width="100%"
									height="5"
									hideText={true}
									val1={this.state.loadingPct}
									val2={100}
								/>
							</div>
							{this.state.loadingText}
						</div>
					)}
					{!this.state.loadingText && <UILIB.LoadingIcons iconType="2" />}
				</div>
			);
		}

		const hasTooManyConditionSteps =
			this.state.conditionPermission.allowedNumber === -1
				? false
				: this.countConditionSteps(this.state.automationData.steps) >
				this.state.conditionPermission.allowedNumber;

		var holderWidth =
			260 +
			260 *
			AutomationFunctions.countByType(
				this.state.automationData.steps,
				"condition"
			);

		const status = this.state.automationData.status

		let statusChipText, statusChipClass = 'square-chip-red'
		if (status === "Running" || status === "Starting") {
			statusChipClass = 'square-chip-green'
		} else if (status === 'Testing') {
			statusChipClass = 'square-chip-blue'
		} else if (status === 'Paused') {
			statusChipClass = 'square-chip-orange'
		}
		switch (status) {
			case 'Draft':
				statusChipText = i18n.t("automation:overview.draft")
				break;
			case 'Paused':
				statusChipText = i18n.t("automation:overview.paused")
				break;
			case 'Errored':
				statusChipText = i18n.t("automation:overview.errored")
				break;
			case 'Running':
				statusChipText = i18n.t("automation:overview.running")
				break;
			case 'Starting':
				statusChipText = i18n.t("automation:overview.starting")
				break;
			case 'Archived':
				statusChipText = i18n.t("automation:overview.archived")
				break;
			case 'Testing':
				statusChipText = i18n.t("automation:overview.testing")
				break;

		}

		return (
			<div className="fullScreenHolder">
				{this.state.chooseAStepChoosing && (
					<div
						className="nav-banner quickFlex paper-red"
						style={{ justifyContent: "center", alignItems: "center" }}
					>
						<div>{i18n.t("automation:overview.chooseAStep")}</div>
					</div>
				)}

				{this.state.chooseAStepChoosing == false && (
					<div className="dragDropToolBar" id="dragDropToolBar">
						<UILIB.Column xs={12} sm={12} md={5} style={{ display: 'flex', alignItems: 'center' }}>
							<UILIB.Button onClick={() => this.saveAutomation(false, true)} iconLeft={<UILIB.Icons icon='cross' />} className="mar-r15 button-sml" >{i18n.t("automation:add.topnav.close")}</UILIB.Button>
							<div className="templateTitle">
								{!this.state.CampaignId && (
									<>
										{PermissionChecker("automations", this.props.permissionStore.permissions, "write") && (
											!this.state.allowEdit ? (
												<div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 8, alignItems: 'center' }}>
													<div>{this.state.automationData.name}</div>
													<div>
														<UILIB.Icons style={{ cursor: 'pointer' }} onClick={this.allowForEdit} icon='pencil' />
													</div>
													<div>
														<UILIB.SquareChip className={statusChipClass} style={{ height: 16, fontSize: 10 }}>
															{statusChipText}
														</UILIB.SquareChip>
													</div>
												</div>
											) : (
												<UILIB.TextInput
													name="automationName"
													value={this.state.automationData.name}
													placeholder={i18n.t("automation:overview.yourWorkflowName")}
													onKeyPress={this.keyPress}
													error={this.state.nameError}
													onChange={this.changeAutomationName}
													onEnterPress={() => this.changeNameTick()}
													style={{ boxShadow: 'none', marginBottom: 0, padding: '8px 12px' }}
													iconRight={<UILIB.Icons onClick={() => this.changeNameTick()} icon='tick' />}
													iconRightStyle={{ top: 8 }}
												/>
											)
										)}
										{!PermissionChecker("automations", this.props.permissionStore.permissions, "write") && (
											<span style={{ color: "white" }}>
												{this.state.automationData.name}
											</span>
										)}
									</>
								)}
							</div>
						</UILIB.Column>

						{<UILIB.Column xs={12} sm={12} md={7} style={{ display: 'flex', justifyContent: 'end', flexWrap: 'wrap' }} >
							{!this.state.isThemeDesigner && (
								<div className="actions">
									{!!this.state.contact && <div className="actions-holder mar-r10">
										<UILIB.SquareChip>
											<div className="quickFlex">
												{i18n.t("automation:add.topnav.viewing", { email: this.state.contact.emailAddress })}<UILIB.Icons style={{ height: 15, width: 15 }} icon="cross" onClick={() => this.setState({ SubscriberId: undefined })} />
											</div>
										</UILIB.SquareChip>
										<div className="mar-l10">
											{!!this.state.contact.AutomationQueues.length && <UILIB.SquareChip className="square-chip-green">{i18n.t("automation:add.topnav.viewingIn")}</UILIB.SquareChip>}
											{!this.state.contact.AutomationQueues.length && !!this.state.contactHistory.length && <UILIB.SquareChip className="square-chip-yellow">{i18n.t("automation:add.topnav.vieiwngNotInt")}</UILIB.SquareChip>}
											{!this.state.contact.AutomationQueues.length && !this.state.contactHistory.length && <UILIB.SquareChip>{i18n.t("automation:add.topnav.viewingNeverIn")}</UILIB.SquareChip>}
										</div>

									</div>}
									{/* track contact */}
									{!this.state.allowEdit && status != "Draft" && status != "Errored" && (
										<div className="actions-holder">
											<UILIB.Button
												onClick={this.findContact}
												iconLeft={<UILIB.Icons icon="magnifier" />}
												className='button-sml'
											>
												{i18n.t("automation:add.topnav.track")}
											</UILIB.Button>
										</div>
									)}
									{/* add contact */}
									{status == "Running" && (
										<div className="actions-holder mar-r10">
											<UILIB.Button
												onClick={this.addContact.bind(this)}
												iconLeft={<UILIB.Icons icon="person" />}
												className='button-sml'
											>
												{i18n.t("automation:add.topnav.add")}
											</UILIB.Button>
										</div>
									)}

									{/* testing */}
									{(status == "Draft" ||
										status == "Paused" ||
										status == "Testing" ||
										status == "Archived") &&
										(
											<div className="actions-holder">
												<UILIB.Button iconLeft={<UILIB.Icons icon="beaker" />} className="button-sml" onClick={this.addContact}>{i18n.t("automation:add.topnav.test")}</UILIB.Button>
											</div>
										)
									}

									{status == "Errored" && this.state.automationData.error != "Campaigns Quarantined" && <>
										<div className="actions-holder">
											<UILIB.SquareChip className="square-chip-large square-chip-red mar-r10">
												{i18n.t("automation:add.topnav.error", { error: this.state.automationData.error })}
											</UILIB.SquareChip>
										</div>
									</>}
									{(status === "Errored" && this.state.automationData.error == "Campaigns Quarantined") && (
										<div className="actions-holder">
											<UILIB.SquareChip className="square-chip-large square-chip-purple">{i18n.t("automation:add.topnav.inReview")}</UILIB.SquareChip>
										</div>
									)}

									{status === "Archived" && (
										<div className="actions-holder">
											<UILIB.Button
												text={i18n.t("automation:add.topnav.unArchive")}
												onClick={this.unarchive.bind(this)}
											/>
										</div>
									)}
									{status == "Starting" && (
										<div className="actions-holder loading mar-r10">{i18n.t("automation:add.topnav.starting")}</div>
									)}
									{/* settings or options */}
									{status != "Running" &&
										status !== "Starting" &&
										status !== "Archived" &&
										status != "Errored" && (
											<div className="actions-holder mar-r10">
												<UILIB.Button
													text={i18n.t("automation:add.topnav.settings")}
													iconLeft={<UILIB.Icons icon="cog" />}
													onClick={this.showGlobalOptions}
													className='button-sml'
												/>
											</div>
										)
									}

								</div>
							)}
							<div className="actions-holder" style={{ display: 'flex' }}>
								{/* save changes */}
								{status != "Running" &&
									status !== "Starting" &&
									status !== "Archived" &&
									status !== "Errored" &&
									status !== "Testing" && (
										<UILIB.Button
											saving={this.state.saving}
											onClick={() => this.saveAutomation(true)}
											className="button-tertiary button-sml mar-r10"
										>
											{i18n.t("automation:add.topnav.save")}

										</UILIB.Button>
									)}

								{PermissionChecker("automations", this.props.permissionStore.permissions, "write") && status !== "Testing" && <UILIB.Button
									onClick={() => {
										this.activateClicked(status != "Running" &&
											status !== "Starting" &&
											status !== "Archived" &&
											status !== "Testing" ? true : false)
									}}
									className="button-primary button-sml"
									iconLeft={status != "Running" && status !== "Starting" && status !== "Archived" ? <UILIB.Icons icon="play" /> : <UILIB.Icons icon="paused" />}
								>
									{status != "Running" && status !== "Starting" && status !== "Archived" && (<span>{i18n.t("automation:add.topnav.start")}</span>)}
									{(status == "Running" || status == "Starting" || status == "Archived") && (<span>{i18n.t("automation:add.topnav.pause")}</span>)}
								</UILIB.Button>}


								{(status == "Testing") && (
									<div className="actions-holder">
										<UILIB.Button className="button-red button-sml" onClick={this.cancelTest.bind(this)}>{i18n.t("automation:add.topnav.cancelTest")}</UILIB.Button>
									</div>
								)}

							</div>
							{this.state.isThemeDesigner && (
								<div className="actions">
									<div className="actions-holder">
										<UILIB.Button
											className="button-primary"
											text={i18n.t("automation:overview.saveAndExit")}
											iconRight={<UILIB.Icons icon="arrowRight" />}
											onClick={() => this.saveAutomation(false, true)}
										/>
									</div>
								</div>
							)}
						</UILIB.Column>}

					</div>
				)}

				{this.state.activateError && this.state.activateError != "" && (
					<div
						className="nav-banner quickFlex paper-red"
						style={{ justifyContent: "space-between", alignItems: "center" }}
					>
						<div>{this.state.activateError}</div>
						<div>
							<UILIB.Icons
								icon="cross"
								onClick={() => {
									this.setState({ activateError: "" });
								}}
							/>
						</div>
					</div>
				)}

				{status != "Running" &&
					status !== "Starting" &&
					status !== "Archived" &&
					!this.state.automationPermissions.allowed && (
						<div className="automation-warning" style={{ position: "initial" }}>
							<div>{i18n.t("automation:add.topnav.maxWarning")}</div>
							<UILIB.ButtonSimple className="button-simple-lightorange mar-l10" onClick={this.upgrade.bind(this)}>{i18n.t("automation:add.topnav.upgrade")}</UILIB.ButtonSimple>
						</div>
					)}

				{status != "Running" &&
					status !== "Errored" &&
					status !== "Starting" &&
					hasTooManyConditionSteps && (
						<div className="automation-warning" style={{ position: "initial" }}>
							<div>{i18n.t("automation:add.topnav.maxConditions")}</div>
							<UILIB.ButtonSimple className="button-simple-lightorange mar-l10" onClick={this.upgrade.bind(this)}>{i18n.t("automation:add.topnav.upgrade")}</UILIB.ButtonSimple>
						</div>
					)}

				<div
					className={
						"autoZoomHolder " +
						(this.state.activateError && this.state.activateError != ""
							? "lower"
							: "")
					}
				>
					<div className="zoomPlus">
						<UILIB.Icons
							icon="zoomIn"
							onClick={() => {
								this.goZoom(true);
							}}
							style={{ width: "20px", height: "20px" }}
						/>
					</div>
					<div className="zoomMinus">
						<UILIB.Icons
							icon="zoomOut"
							onClick={() => {
								this.goZoom(false);
							}}
							style={{ width: "20px", height: "20px" }}
						/>
					</div>
				</div>

				<div
					className="fullScreenMid automationHolder"
					ref={this.fullScreenChangedSize}
					id="automationOuterHolder"
				>
					<div
						className="automationOuter"
						id="automationOuter"
						style={{ transform: "scale(" + this.state.zoom + ")" }}
					>
						{this.renderSteps(this.state.automationData.steps)}
						<div style={{ height: "40px" }} />
					</div>
				</div>

				<UILIB.SnackBar
					message={this.state.alertMessage}
					open={this.state.alertOpen}
					autoclose={true}
					dismiss={() => this.setState({ alertOpen: false })}
				/>
				{this.state.isThemeDesigner && (
					<div
						style={{
							width: "200px",
							background: "#e43",
							position: "fixed",
							bottom: "25px",
							right: "-50px",
							textAlign: "center",
							lineHeight: "44px",
							letterSpacing: "1px",
							color: "#f0f0f0",
							transform: "rotate(-45deg)",
							fontSize: "14px",
						}}
					>
						Designer Mode
					</div>
				)}
			</div>
		);
	}
}

export default AutomationsNew;

import React from 'react'

import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';

@connect((store) => {
    return { user: store.user }
})
export default class BlackListDrawer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };

    }

    render() {



        return <div>

            <h4 className="mar-b25">{this.props.domain.domainName} Blacklists</h4>
            <div className="mar-b10">Your domain name appears to have been blacklisted via the following blacklist services</div>
            <div className="mar-b25">Please contact each blacklist listed and ask to be removed, using the links below.</div>


            <h4 className="mar-b25">Blacklists</h4>
            {this.props.domain.blacklists.map((blacklist, index) => {
                return <UILIB.WarningHolder key={"bl_" + index} className="text-left quickFlex" style={{ border: "1px solid red" }}>
                    <div style={{ flex: 1, fontWeight: "bold" }} className="text-left">{blacklist.name}</div>
                    <div style={{ flex: 1 }} className="text-right"><UILIB.Button text="Delist" className="button-sml" onClick={() => {
                        window.open(blacklist.delist, '_blank')
                    }} /></div>
                </UILIB.WarningHolder>
            })
            }
        </div>
    }
};


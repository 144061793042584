import React from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import { Switch } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react'
import ViewTemplate from './viewTemplate.jsx'
import ViewPage from './overview/overview.jsx'
import VisitorsPage from './visitors/visitors.jsx'
import BehaviorPage from './behavior/behavior.jsx'
import CampaignsPage from './campaigns/campaigns.jsx'
import EventsPage from './events/events.jsx';

import moment from 'moment';


export default class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            trackerId: this.props.match.params.trackerId,
            tracker: {},
            loaded: false,
            startDate: moment().subtract(7, 'day'), endDate: moment()
        };
        this.loadTracker = this.loadTracker.bind(this);
        this.updateDate = this.updateDate.bind(this);
        this.updateTracker = this.updateTracker.bind(this);
    }
    componentDidMount() {
        this.loadTracker()
    }

    loadTracker() {
        axios.get('/accountMaster/tracker/' + this.state.trackerId + '?startDate=' + this.state.startDate.format("YYYY-MM-DD") + '&endDate=' + this.state.endDate.format("YYYY-MM-DD")).then(response => {
            this.setState({
                tracker: response.data.Tracker,
                loaded: true
            })
        }).catch(err => {
            this.props.history.push('/cp/trackers')
        })

    }

    async updateTracker(tracker) {
        //do stuff
        this.setState({ tracker })
        await axios.put(`/accountMaster/tracker/${tracker.id}`, { webChatOptions: tracker.webChatOptions, webChat: tracker.webChat })
        return true;
    }

    updateDate(dates) {
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if (dates.startDate) startDate = dates.startDate;
        if (dates.endDate) endDate = dates.endDate;
        this.setState({ startDate: startDate, endDate: endDate }, this.loadTracker)
    }

    render() {
        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />
        return <ViewTemplate history={this.props.history} tracker={this.state.tracker} updateDate={this.updateDate} startDate={this.state.startDate} endDate={this.state.endDate}>
            <Switch>
                <ApmRoute exact path="/cp/trackers/:trackerId" render={(props) => <ViewPage history={this.props.history} tracker={this.state.tracker} updateDate={this.updateDate} startDate={this.state.startDate} endDate={this.state.endDate} />} />
                <ApmRoute path="/cp/trackers/:trackerId/visitors" render={(props) => <VisitorsPage history={this.props.history} tracker={this.state.tracker} updateDate={this.updateDate} startDate={this.state.startDate} endDate={this.state.endDate} />} />
                <ApmRoute path="/cp/trackers/:trackerId/pages" render={(props) => <BehaviorPage history={this.props.history} tracker={this.state.tracker} updateDate={this.updateDate} startDate={this.state.startDate} endDate={this.state.endDate} />} />
                <ApmRoute path="/cp/trackers/:trackerId/campaigns" render={(props) => <CampaignsPage history={this.props.history} tracker={this.state.tracker} updateDate={this.updateDate} startDate={this.state.startDate} endDate={this.state.endDate} />} />
                <ApmRoute path="/cp/trackers/:trackerId/events" render={(props) => <EventsPage history={this.props.history} tracker={this.state.tracker} updateDate={this.updateDate} startDate={this.state.startDate} endDate={this.state.endDate} />} />
            </Switch>
        </ViewTemplate>

    }
};

import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib';

export default class SaveBlockDrawer extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        return <div>
            <h4 className="mar-b25">Delete this Block?</h4>
            <div className="mar-b25">Are you SURE you want to delete this block? There's no going back!</div>

            <UILIB.Button text="Delete Block" onClick={this.props.reallyDelete} className="button-primary" />
        </div>


    }
}



import React from 'react'
import SiteLogoWhite from '../../assets/images/mpzLogos/mpzLogo.svg'
import SiteLogoBlack from '../../assets/images/mpzLogos/mpzLogoBlack.png'
import { connect } from 'react-redux';

@connect((store) => {
    return { siteMaster: store.siteMaster }
})

export default class SiteLogo extends React.Component {
    render() {
        var thisSiteLogo = SiteLogoWhite;
        if (this.props.logoType === "black") {
            thisSiteLogo = SiteLogoBlack;
        }

        if (this.props.siteMaster.siteLogo) {
            thisSiteLogo = this.props.siteMaster.siteLogo;
        }

        if (this.props.linksTo) {
            return <span className="siteLogo"><a onClick={this.props.linksTo}>
                <img src={thisSiteLogo} alt={this.props.siteMaster.siteName} title={this.props.siteMaster.siteName} className={this.props.className} style={this.props.style} />
            </a></span>
        }
        else {
            return <span className="siteLogo">
                <img src={thisSiteLogo} alt={this.props.siteMaster.siteName} title={this.props.siteMaster.siteName} className={this.props.className} style={this.props.style} />

            </span>
        }

    }
}

import React, { useState, useEffect } from "react";
import classnames from "classnames";
import axios from "~/data/http/axios";
import TextFunctions from "~/Classes/textFunctions";
import UILIB from "~/Common-Objects/Lib";
import Dialog from "../../../../components/dialog";
import Button from "../../../../components/button";
import TextInput from "../../../../components/textInput";
import * as styles from "./index.module.scss";
import i18n from "~/i18n";

export default function AddSourceDialog({ inbox, isOpen, onSubmit, ...rest }) {
  const [submitting, setSubmitting] = useState(false);
  const [url, setUrl] = useState("");
  const [provider, setProvider] = useState(null);
  const [providerLoading, setProviderLoading] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  useEffect(() => {
    if (!url) setProvider(null);
  }, [url]);

  async function updateUrl(value) {
    setProvider(null);
    setFormErrors(null);
    setUrl(value);
  }

  async function detectProvider() {
    setProviderLoading(true);

    try {
      if (!url) {
        setProvider(null);
        return;
      }

      const { data } = await axios.post(
        `/inboxes/${inbox.id}/ai/detectprovider`,
        { url }
      );

      setProvider(data.provider);

    } catch (error) {
      setFormErrors({ ...formErrors, url: error.data.error });
    } finally {
      setProviderLoading(false);
    }
  }

  async function handleSubmit() {
    setSubmitting(true);
    try {
      await validateForm();
      const values = { url, provider };
      await onSubmit(values);
    } finally {
      setSubmitting(false);
    }
  }

  async function validateForm() {
    try {
      let errors = null;
      if (!url) {
        errors = { ...errors, url: i18n.t("chat:ai.sources.addSource.errUrl") };
      }
      if (errors) throw errors;
      setFormErrors(null);
    } catch (error) {
      setFormErrors(error);
      throw error;
    }
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      title={i18n.t("chat:ai.sources.addSource.header")}
      description={i18n.t("chat:ai.sources.addSource.subheader")}
      submitting={submitting}
      {...rest}
    >
      <div>

        <div className={styles.providerInputWrapper}>
          <TextInput
            label={i18n.t("chat:ai.sources.addSource.url")}
            placeholder={"https://my.knowledgebase.com"}
            value={url}
            error={formErrors?.url}
            onChange={(e) => updateUrl(e.target.value)}
            onEnterPress={detectProvider}
          />

          {providerLoading && (
            <div className={styles.providerInputSpinner}>
              <UILIB.Icons icon="loading" />
            </div>
          )}
        </div>

        {!provider && <Button
          className="mar-t40"
          type="button"
          size="s"
          loading={providerLoading}
          disabled={providerLoading}
          onClick={detectProvider}
        >
          {i18n.t("chat:ai.sources.addSource.continue")}
        </Button>}



        {provider && <>
          {provider === "unknown" && <>
            <div className={styles.provider}>
              <h3 className="text-md mar-b4">{i18n.t("chat:ai.sources.addSource.noRecog")}</h3>
              <p className="mar-b0">{i18n.t("chat:ai.sources.addSource.noRecogDesc")}</p>
            </div></>}
          {provider !== "unknown" && <>
            <div className={styles.provider}>
              <h3 className="text-md mar-b4">{i18n.t("chat:ai.sources.addSource.recog", { provider: TextFunctions.capitalizeFirstLetter(provider) })}</h3>
              <p className="mar-b0">{i18n.t("chat:ai.sources.addSource.recogDesc", { provider })}</p>
            </div>
          </>}
          <Button
            className="mar-t40"
            type="button"
            size="s"
            loading={submitting}
            disabled={providerLoading}
            onClick={handleSubmit}
          >
            {i18n.t("chat:ai.sources.addSource.button")}
          </Button>
        </>}
      </div>

    </Dialog >
  );
}





import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from '../../../../../data/http/axios'
import UILIB from '../../../../../Common-Objects/Lib'
import i18n from '../../../../../i18n'
import Overview from './overview'
import Clicks from './clicks'
import DateTimeFunctions from '../../../../../Classes/dateTimeFunctions'

export default function ViewSocialCampaign() {
    const { campaignId, view } = useParams()
    const history = useHistory()
    const [campaign, setCampaign] = useState(null)
    const [accApps, setAccApps] = useState(null)
    const [posts, setPosts] = useState(null)
    const [renaming, setRenaming] = useState(false)
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')

    const getCampaign = async () => {
        const res = await axios.get('/campaign/' + campaignId)
        setCampaign(res.data.Campaign)
        setName(res.data.Campaign.campaignName)
    }

    const getPosts = async () => {
        const res = await axios.get('/campaign/' + campaignId + '/socialpost')
        setPosts(res.data)
    }

    const getApps = async () => {
        const res = await axios.get('/accountMaster/application')
        setAccApps(res.data.Applications)
    }

    const changeView = (val, returnUrl) => {
        if (returnUrl) return `/cp/campaigns/viewsocial/${campaignId}/${val}`
        history.push(`/cp/campaigns/viewsocial/${campaignId}/${val}`)
    }

    const changeName = e => {
        setName(e.currentTarget.value)
    }

    const cancelNameChange = () => {
        setName(campaign.campaignName)
        setRenaming(false)
    }

    const saveName = async () => {
        if (name.trim().length < 3) return setNameError('Name must be at least 3 characters')
        setNameError('')
        await axios.put('/campaign/' + campaignId, {
            campaign: {
                campaignName: name.trim()
            }
        })
        setCampaign({ ...campaign, campaignName: name.trim() })
        setRenaming(false)
    }

    useEffect(() => {
        getCampaign()
        getPosts()
    }, [campaignId])

    useEffect(() => {
        getApps()
    }, [])

    if (!campaign || !accApps || !posts) return <UILIB.LoadingIcons />

    const menuOptions = [
        {
            label: i18n.t('social:view.nav.overview'),
            value: ''
        },
        {
            label: i18n.t('social:view.nav.clicks'),
            value: 'clicks'
        }
    ]

    return (
        <UILIB.Paper>
            {!renaming && <div className="mar-b10 quickFlex">
                <h1 className="page-header mar-r10">{campaign.campaignName}</h1>
                <UILIB.Icons
                    style={{ width: "20px", height: "20px" }}
                    icon="pencil"
                    onClick={() => setRenaming(true)}
                />
            </div>}
            {renaming && <div className="mar-b25 quickFlex">
                <UILIB.TextInput
                    outerStyle={{ flex: "1" }}
                    autoFocus={true}
                    value={name}
                    onEnterPress={saveName}
                    onChange={changeName}
                    onEscapePress={cancelNameChange}
                    error={nameError}
                />
                <UILIB.Icons
                    style={{ flex: "0", width: "20px", height: "20px" }}
                    icon="tick"
                    onClick={saveName}
                    className="mar-l10"
                />
            </div>}
            {/* <h1 className="page-header mar-b10">{campaign.campaignName}</h1> */}
            <p style={{ fontSize: 14, lineHeight: '20px', color: '#595C61' }}><span style={{ fontWeight: 500, color: '#171819' }}>Date Started: </span>{DateTimeFunctions.formatDateTime(campaign.startDate)}</p>
            <UILIB.Row>
                <UILIB.Column xs={12} hide={["md", "lg"]}>
                    <UILIB.Select data={menuOptions} onChange={e => changeView(e.target.value)} value={view || ''} />
                </UILIB.Column>
                <UILIB.Column xs={12} hide={["xs", "sm"]} className="sub-nav-holder" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="mar-b25">{menuOptions.map((option, index) => {
                        return <UILIB.ButtonSimple key={"tab_" + index} to={changeView(option.value, true)} className="button-simple-fullsize sub-nav-button" selected={(view || '') == option.value}>{option.label}</UILIB.ButtonSimple>
                    })}
                    </div>
                </UILIB.Column>
                <UILIB.Column xs={12}>
                    {!view && <Overview campaign={campaign} apps={accApps} />}
                    {view === 'clicks' && <Clicks campaign={campaign} apps={accApps} posts={posts} />}
                </UILIB.Column>
            </UILIB.Row>
        </UILIB.Paper>
    )
}
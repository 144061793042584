// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._Dq76XWhPkMiTzinXmYC{display:flex;flex-direction:column;gap:32px}.wEWkZTT6feEgFSsovAu1{display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));gap:16px;align-self:stretch}.gbXROEKOJnp5NHWw12zN{display:flex;flex-direction:column;gap:12px;width:100%}.aKhPxiYbsP_4bMvjmOAw{display:flex;align-items:center;gap:12px;width:100%}.xgxZvUycrG4yqXeD70of{align-self:flex-start}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/channel/webchat/agentAvailability/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,+CAAA,CACA,QAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,UAAA,CAGF,sBACE,qBAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n}\n\n.halfWidthWrapper {\n  display: grid;\n  grid-template-columns: repeat(2, minmax(0, 1fr));\n  gap: 16px;\n  align-self: stretch;\n}\n\n.scheduleWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  width: 100%;\n}\n\n.inputWithInlineActions {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  width: 100%;\n}\n\n.submitButton {\n  align-self: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_Dq76XWhPkMiTzinXmYC",
	"halfWidthWrapper": "wEWkZTT6feEgFSsovAu1",
	"scheduleWrapper": "gbXROEKOJnp5NHWw12zN",
	"inputWithInlineActions": "aKhPxiYbsP_4bMvjmOAw",
	"submitButton": "xgxZvUycrG4yqXeD70of"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import LoadingIcon1 from '../../assets/images/loadingIcons/Loading_icon.gif'

export default function LoadingIcons({ iconType = "2", noClass = false, className = "", width, style }) {
    var theClass = "loadingIcon";
    if (noClass) {
        theClass = "";
    }
    if (className) theClass += " " + className

    switch (iconType) {
        case "1":
            return <img src={LoadingIcon1} className={theClass} width={width} style={style} />
        case "2":
            return <div className={theClass + " spinner"} style={style}></div>
        case "3":
            return <div className={"loadingIcon_bubbles " + className} style={style} >
                <div className="loadingIcon_bubbles_bubble loadingIcon_bubbles_bubble1"></div>
                <div className="loadingIcon_bubbles_bubble loadingIcon_bubbles_bubble2"></div>
                <div className="loadingIcon_bubbles_bubble loadingIcon_bubbles_bubble3"></div>
            </div>
    }
}
import React from "react";
import { useDispatch } from "react-redux";
import Header from "../../../components/header";
import i18n from "~/i18n";
import SettingsSection from "../../../components/settingsSection";
import EmailChannelSignatureComponent from "../settings/signature";

export default function EmailChannelSignature({
    channel,
    onUpdateChannel,
}) {
    const dispatch = useDispatch();

    return (
        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
            <Header type="email" option="signature" />

            <SettingsSection heading={i18n.t("chat:channel.email.signature.header")} subheading={i18n.t("chat:channel.email.signature.subheader")}>
                <EmailChannelSignatureComponent channel={channel} onUpdateChannel={onUpdateChannel} />
            </SettingsSection>
        </div>
    );
}

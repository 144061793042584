import React from 'react'

export default class IconsShoppingBag extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 2.00006C8.93914 2.00006 7.92173 2.42149 7.17158 3.17163C6.42143 3.92178 6.00001 4.9392 6.00001 6.00006V7.00006H5.00001C4.75383 7.00004 4.51629 7.09084 4.33288 7.25505C4.14947 7.41927 4.03309 7.64537 4.00601 7.89006L3.00601 16.8901C2.99054 17.0298 3.00472 17.1713 3.04762 17.3052C3.09052 17.4391 3.16118 17.5624 3.25498 17.6672C3.34878 17.7719 3.46362 17.8557 3.59199 17.9131C3.72037 17.9704 3.8594 18.0001 4.00001 18.0001H16C16.1406 18.0001 16.2796 17.9704 16.408 17.9131C16.5364 17.8557 16.6512 17.7719 16.745 17.6672C16.8388 17.5624 16.9095 17.4391 16.9524 17.3052C16.9953 17.1713 17.0095 17.0298 16.994 16.8901L15.994 7.89006C15.9669 7.64537 15.8505 7.41927 15.6671 7.25505C15.4837 7.09084 15.2462 7.00004 15 7.00006H14V6.00006C14 4.9392 13.5786 3.92178 12.8284 3.17163C12.0783 2.42149 11.0609 2.00006 10 2.00006ZM12 7.00006V6.00006C12 5.46963 11.7893 4.96092 11.4142 4.58585C11.0391 4.21077 10.5304 4.00006 10 4.00006C9.46957 4.00006 8.96087 4.21077 8.58579 4.58585C8.21072 4.96092 8.00001 5.46963 8.00001 6.00006V7.00006H12ZM6.00001 10.0001C6.00001 9.73484 6.10536 9.48049 6.2929 9.29295C6.48044 9.10542 6.73479 9.00006 7.00001 9.00006C7.26522 9.00006 7.51958 9.10542 7.70711 9.29295C7.89465 9.48049 8.00001 9.73484 8.00001 10.0001C8.00001 10.2653 7.89465 10.5196 7.70711 10.7072C7.51958 10.8947 7.26522 11.0001 7.00001 11.0001C6.73479 11.0001 6.48044 10.8947 6.2929 10.7072C6.10536 10.5196 6.00001 10.2653 6.00001 10.0001ZM13 9.00006C12.7348 9.00006 12.4804 9.10542 12.2929 9.29295C12.1054 9.48049 12 9.73484 12 10.0001C12 10.2653 12.1054 10.5196 12.2929 10.7072C12.4804 10.8947 12.7348 11.0001 13 11.0001C13.2652 11.0001 13.5196 10.8947 13.7071 10.7072C13.8947 10.5196 14 10.2653 14 10.0001C14 9.73484 13.8947 9.48049 13.7071 9.29295C13.5196 9.10542 13.2652 9.00006 13 9.00006Z" fill={color} />
        </svg>

    }
}        
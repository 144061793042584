import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'
import IncTypeSelectOptions from './incTypeSelectOptions';


@connect((store) => {
    return { user: store.user }
})
export default class IncAddCustomField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputfield: '',
            pageLoaded: false,
            fieldTypes: {},
            errors: {
                fieldName: '',
                fieldDesc: '',
            },
            important: false,
            fieldName: '',
            fieldDesc: '',
            required: '0',
            fieldType: '',
            fieldTypeDesc: '',
            drawerContent: '',
            entityType: 1
        };

        this.closeDrawer = this.closeDrawer.bind(this);
        this.getCustomFieldTypes = this.getCustomFieldTypes.bind(this);
        this.updateInput = this.updateInput.bind(this);
        this.goAddNewField = this.goAddNewField.bind(this);
        this.inputChange = this.inputChange.bind(this)
        this.saveNewField = this.saveNewField.bind(this);
    }
    componentDidMount() {
        this.getCustomFieldTypes();
    }

    getCustomFieldTypes() {
        var self = this
        axios
            .get('/customfield/fieldtypes')
            .then((res) => {
                var fieldTypes = [];
                var data = res.data;
                data.push({ label: "COUNTRY", type: "COUNTRY", explainer: "A list of countries to choose from" })
                data.push({ label: "MULTI", type: "MULTI", explainer: "Multiple free text entries" })
                data = data.map((field) => {

                    var label = i18n.t(`fieldTypes.${field.type}.label`);
                    var explainer = i18n.t(`fieldTypes.${field.type}.explainer`);
                    return { label, type: field.type, explainer }
                })

                if (self.props.forceType) {
                    data.forEach((field) => {
                        if (field.type == self.props.forceType) {
                            fieldTypes.push({ label: field.label, value: field.type, explainer: field.explainer })
                        }
                    })
                }
                else {
                    fieldTypes = data.map((field) => {
                        return { label: field.label, value: field.type, explainer: field.explainer }
                    })
                }


                self.setState({
                    fieldTypes: fieldTypes,
                    pageLoaded: true,
                    fieldType: fieldTypes[0].value,
                    fieldTypeDesc: fieldTypes[0].explainer
                })
            })
    }

    closeDrawer() {
        if (!this.props.standalone) {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
        } else {
            this.props.getCustomFields()
        }
    }

    updateInput(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if ([event.target.name] == "fieldDesc") {
            var upperTrimmed = event.target.value
            this.setState({ fieldName: upperTrimmed.replace(/[^a-zA-Z0-9_]/g, "").toUpperCase() })
        }
        if ([event.target.name] == "fieldName") {
            this.setState({ fieldName: event.target.value.replace(/[^a-zA-Z0-9_]/g, "") })
        }
        if ([event.target.name] == 'fieldType') {

            var ourField = this.state.fieldTypes.find(ft => ft.value == event.target.value);
            console.log(this.state.fieldTypes, event.target.value, ourField)
            if (ourField) {
                this.setState({
                    fieldTypeDesc: ourField.explainer
                })
            }
        }
    }

    inputChange(event) {
        if (event.key === 'Enter') {
            this.goAddNewField();
        }
    }

    goAddNewField() {
        var self = this;
        var data = {
            fieldName: this.state.fieldName,
            fieldDesc: this.state.fieldDesc,
            fieldType: this.state.fieldType,
            isRequired: this.state.required,
            important: this.state.important,
            entityType: parseInt(this.state.entityType)
        }

        var isErr = false;
        var errObj = this.state.errors;
        errObj.fieldName = '';
        errObj.fieldDesc = '';
        if (data.fieldName.length < 3) {
            isErr = true;
            errObj.fieldName = i18n.t('subscribers:customfields.addError1');
        }
        if (data.fieldDesc.length < 3) {
            isErr = true;
            errObj.fieldDesc = i18n.t('subscribers:customfields.addError2');
        }

        if (isErr === true) {
            this.setState({ errors: errObj })
            return;
        }
        if (data.fieldType == "SELECT" || data.fieldType == "MULTISELECT") {
            data.options = [];
            this.setState({ drawerContent: <IncTypeSelectOptions saveData={this.saveNewField} fieldData={data} /> })
            return;
        }
        this.saveNewField(data);
    }


    saveNewField(data) {
        var self = this;
        if (data.fieldType === 'MULTI') {
            data.fieldType = 'MULTISELECT'
            delete data.options
        }
        axios.post('/customfield', data).then((res) => {
            if (self.props.alert && typeof self.props.alert === 'function') {
                self.props.alert('Field Added')
            }
            if (!self.props.standalone) {
                self.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
                if (self.props.getCustomFields) {
                    self.props.getCustomFields(res.data.CustomField);
                }
                return;
            }
            self.props.getCustomFields(res.data.CustomField);
        }).catch(err => {
            var errDat = this.state.errors;
            errDat.fieldDesc = err.data.error;
            self.setState({ errors: errDat });
        })
    }

    render() {

        if (this.state.drawerContent) {
            return <div>{this.state.drawerContent}</div>
        }

        var requiredData = [{ value: 0, label: i18n.t('subscribers:customfields.no') }, { value: 1, label: i18n.t('subscribers:customfields.yes') }];

        if (this.state.pageLoaded === true) {
            return <div>


                <h4 className="mar-b25">{i18n.t('subscribers:customfields.addHeader')}</h4>
                <div className="mar-b25">{i18n.t('subscribers:customfields.addSubHeader')}</div>

                <UILIB.TextInput outerClassName="mar-b25" type="text" name="fieldDesc" label={i18n.t('subscribers:customfields.fieldName')} value={this.state.fieldDesc} onChange={this.updateInput} onKeyPress={this.inputChange} error={this.state.errors.fieldDesc} focus={true} />
                <UILIB.TextInput outerClassName="mar-b25" type="text" name="fieldName" label={i18n.t('subscribers:customfields.mergeTag')} value={this.state.fieldName} onChange={this.updateInput} error={this.state.errors.fieldName} />
                <UILIB.Select outerClassName="mar-b25" explainer={this.state.fieldTypeDesc} data={this.state.fieldTypes} value={this.state.fieldType} onChange={this.updateInput} label={i18n.t('subscribers:customfields.fieldType')} name="fieldType" />
                <UILIB.Select data={[
                    { label: 'Subscribers Only', value: 1 },
                    { label: 'Organisations Only', value: 2 },
                    { label: 'Subscribers and Organisations', value: 3 }]}
                    onChange={this.updateInput}
                    name="entityType"
                    label="Assign To"
                    explainer="Wether the field is bound to subscribers, organisations or both for filtering"
                    value={this.state.entityType}
                    outerClassName="mar-b25" />

                <UILIB.Select outerClassName="mar-b25" label={i18n.t('subscribers:customfields.important')} data={requiredData} value={this.state.important} explainer="Important fields show first in contact details" onChange={this.updateInput} name="important" />
                <UILIB.Select outerClassName="mar-b25" label={i18n.t('subscribers:customfields.required')} data={requiredData} value={this.state.required} onChange={this.updateInput} name="required" />
                <UILIB.Button text={i18n.t('subscribers:customfields.addButton')} className="button-primary" iconLeft={<UILIB.Icons icon="plus" />} onClick={this.goAddNewField} />

            </div>
        }
        else {
            return <div></div>
        }
    }
};


import React from 'react'

export default function IconsPerson({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0003 10C10.9949 10 11.9487 9.60491 12.6519 8.90165C13.3552 8.19839 13.7503 7.24456 13.7503 6.25C13.7503 5.25544 13.3552 4.30161 12.6519 3.59835C11.9487 2.89509 10.9949 2.5 10.0003 2.5C9.00573 2.5 8.0519 2.89509 7.34864 3.59835C6.64538 4.30161 6.25029 5.25544 6.25029 6.25C6.25029 7.24456 6.64538 8.19839 7.34864 8.90165C8.0519 9.60491 9.00573 10 10.0003 10ZM15.919 17.5C16.6915 17.5 17.2853 16.7988 17.009 16.0763C16.4671 14.6563 15.5068 13.4345 14.2551 12.5725C13.0034 11.7104 11.5195 11.2489 9.99966 11.2489C8.47986 11.2489 6.9959 11.7104 5.74422 12.5725C4.49255 13.4345 3.53219 14.6563 2.99029 16.0763C2.71529 16.7988 3.30779 17.5 4.08029 17.5H15.919Z" fill={fillColor}/>
        </svg>
        

    )
}
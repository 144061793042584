import React from "react";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { connect } from "react-redux";
import EmailTools from "~/Classes/emailValidator";
import i18n from "~/i18n";

//CONNECT TO STORE
@connect((store) => {
  return { user: store.user, siteMaster: store.siteMaster };
})
export default class SetDefaultPreviewDomain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <h2 className="h4 mar-b25">
          {i18n.t("company:domains.setToDefaultPreviewDomainDrawerHeader")}
        </h2>
        <div className="mar-b25">
          {i18n.t("company:domains.setToDefaultPreviewDomainDrawerSubHeader")}
        </div>

        <UILIB.Button
          text={i18n.t("continue")}
          className="button-primary"
          onClick={() => {
            this.props.onSelected(this.props.domain);
          }}
        />
      </div>
    );
  }
}

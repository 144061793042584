import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';
import UILIB from '~/Common-Objects/Lib';

export default class DivCanvasHolder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mouseOver: false
        }
        this.clickHolder = this.clickHolder.bind(this);
        this.mouseOver = this.mouseOver.bind(this);
        this.mouseOut = this.mouseOut.bind(this);
    }
    clickHolder(event) {
        event.stopPropagation();
        this.props.clickElement(this.props.path)
    }
    mouseOver(event) {
        event.stopPropagation();
        this.setState({ mouseOver: true });
    }
    mouseOut(event) {
        this.setState({ mouseOver: false })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ mouseOver: false })
    }



    render() {

        const { canDrop, isOver, connectDropTarget, connectDragSource, isDragging, moveElement, connectDragPreview } = this.props

        var isSelected = false;
        var isSelectedClass = "";

        if (this.props.selectedElement.path === this.props.path) {
            isSelected = true;
            isSelectedClass = "dd-element-selected";
        }
        if (this.state.mouseOver && isDragging != true) {
            isSelectedClass = "dd-element-selected";
        }
        if (this.props.showGuideLines) {
            isSelectedClass = "showGuideLines " + isSelectedClass
        }

        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "table", this.props.localVariables)
        }
        return <div className="elementHolder" onClick={this.clickHolder} onMouseOver={this.mouseOver} onMouseOut={this.mouseOut}>
            <div className={"elementHolderTools " + isSelectedClass}>
                {isSelected &&
                    <div className="ui-dd-tools ui-dd-tools-top">

                    </div>
                }
                {isSelected && <div className="ui-dd-tools ui-dd-tools-left" alt="Move" title="Move" >

                </div>}

            </div>

            <table {...eleProps}>

                {this.props.children}

            </table>
        </div>

    }
}



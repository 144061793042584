import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import Theme from '~/pages/cp/templates/add/dragDrop/themes/theme';
import InfiniteScroll from 'react-infinite-scroller';

class SavedThemes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            groups: [],
            templates: [],
            group: undefined,
            count: 0,
            selectedTemplate: undefined,
            limit: 6,
            page: 0,
            searchText: '',
            hasMore: false
        }

        this.getGroups = this.getGroups.bind(this);
        this.getTemplates = this.getTemplates.bind(this);
        this.select = this.select.bind(this);
        this.updateSearchText = this.updateSearchText.bind(this);
        this.selectGroup = this.selectGroup.bind(this);
    }

    componentDidMount() {
        this.getGroups()
    }

    getGroups() {
        axios.get('/templateGroup')
            .then(response => {
                this.setState({
                    groups: response.data.TemplateGroups.filter(g => g.id !== '-999')
                }, this.getTemplates)
            })
            .catch(console.log)
    }

    getTemplates(reload) {

        var queryStr = `?recordLimit=${this.state.limit}&startRecord=${reload ? 0 : this.state.templates.length}&sortColumn=id&sortOrder=desc`;
        if (this.state.searchText) queryStr += '&searchText=' + this.state.searchText;
        if (this.state.group) queryStr += '&groupID=' + this.state.group.id;
        axios.get('/template' + queryStr).then(response => {
            var templates = reload ? [] : this.state.templates;
            templates = templates.concat(response.data.Templates)
            this.setState({
                templates,
                count: response.data.countTotal,
                loaded: true,
                hasMore: templates.length < response.data.countTotal
            })
        })
    }

    select(template) {
        this.setState({
            selectedTemplate: template.id
        })
    }

    updateSearchText(ev) {
        this.setState({
            searchText: ev.currentTarget.value
        }, () => {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => this.getTemplates(true), 250)
        })
    }

    selectGroup(group) {
        this.setState({
            group
        }, () => this.getTemplates(true))
    }

    render() {
        if (!this.state.loaded) return <div style={{ marginTop: '50px' }}>
            <UILIB.LoadingIcons iconType="2" className="mar-b30" />
        </div>

        let headerText = this.state.group ? this.state.group.groupName : "Filter By Template Group"
        if (headerText === 'deleted') {
            headerText = i18n.t('deleted')
        }

        const loader = <UILIB.LoadingLine key={0}></UILIB.LoadingLine>;
        return <div>
            <UILIB.Row>

                <UILIB.Column xs={12} sm={this.state.groups.length ? 6 : 12} className="mar-b25">
                    <UILIB.TextInput
                        name="searchText"
                        clickToChangeClicked={this.getTemplates}
                        onEnterPress={this.getTemplates}
                        onChange={this.updateSearchText}
                        value={this.state.searchText}
                        style={{ paddingRight: "35px" }}
                        placeholder={i18n.t('campaigns:standard.step2.saved.search')}
                        iconLeft={<UILIB.Icons icon="magnifier" onClick={() => { }} />}
                    />
                </UILIB.Column>

                {!!this.state.groups.length && <UILIB.Column xs={12} sm={6} className="quickFlex end-xs mar-b25">
                    <UILIB.ButtonSelect
                        headerText={headerText} data={
                            [<a key={-1} onClick={() => this.selectGroup(undefined)}>All Templates</a>].concat(
                                this.state.groups.map((group, index) => {
                                    let name = group.groupName;
                                    if (name === 'deleted') {
                                        name = i18n.t('deleted')
                                    }
                                    return <a key={index} onClick={() => this.selectGroup(group)}>{name}</a>
                                }))
                        } />
                </UILIB.Column>}
            </UILIB.Row>


            <UILIB.Paper className="mar-b25">
                <div ref={(ref) => this.scrollParentRef = ref}>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={() => this.getTemplates(false)}
                        hasMore={this.state.hasMore}
                        loader={loader}
                        useWindow={false}
                        getScrollParent={() => this.scrollParentRef}
                    >
                        <UILIB.Row>
                            {!this.state.templates.length && <div style={{ width: '100%' }} className="center-xs center-sm center-md center-lg mar-t30"><span >{i18n.t('campaigns:standard.step2.saved.none')}</span></div>}
                            {this.state.templates.map((template, index) => {
                                return <UILIB.Column xs={12} sm={6} md={4} lg={3} className="themes-advanced" key={index}>
                                    <Theme theme={template} className="extraSpace" select={() => this.props.onSelect(template)} />
                                </UILIB.Column>
                            })}
                        </UILIB.Row>
                    </InfiniteScroll>
                </div>
            </UILIB.Paper>
        </div>
    }
}

export default SavedThemes;
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import axios from "~/data/http/axios";
import Button from "../../components/button";
import TextInput from "../../components/textInput";
import DeleteInboxDialog from "./components/deleteInboxDialog";
import Header from "../../components/header";
import i18n from "~/i18n";

export default function InboxGeneral({ inbox, onUpdateName }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [inboxName, setInboxName] = useState(inbox.name);
  const [isSavingInboxName, setIsSavingInboxName] = useState(false);
  const [isDeleteInboxDialogOpen, setIsDeleteInboxDialogOpen] = useState(false);

  async function updateInboxName() {
    setIsSavingInboxName(true);

    try {
      await axios.put(`/inboxes/${inbox.id}`, { name: inboxName });
      if (onUpdateName) await onUpdateName();
      dispatch(alterSnackbar(true, i18n.t("chat:settings.general.nameUpdated")));
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    } finally {
      setIsSavingInboxName(false);
    }
  }

  async function deleteInbox(inboxToDelete) {
    try {
      await axios.delete(`/inboxes/inbox/${inbox.id}`);
      dispatch(alterSnackbar(true, i18n.t("chat:settings.general.deleted")));
      history.push("/cp/conversations");
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  return (
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
      <Header type="inbox" option="general" />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          updateInboxName();
        }}
      >
        <TextInput
          className="mar-b24"
          label={i18n.t("chat:settings.general.name")}
          value={inboxName}
          disabled={isSavingInboxName}
          required
          onChange={(e) => setInboxName(e.target.value)}
        />

        <Button type="submit" size="s" loading={isSavingInboxName}>
          {i18n.t("chat:settings.general.updateName")}
        </Button>
      </form>

      <hr />

      <div>
        <h2 className="text-md mar-b4">{i18n.t("chat:settings.general.deleteInbox")}</h2>
        <p className="text-grey mar-b24">
          {i18n.t("chat:settings.general.deleteWarning")}
        </p>

        <Button
          variant="danger"
          size="s"
          onClick={() => setIsDeleteInboxDialogOpen(true)}
        >
          {i18n.t("chat:settings.general.delete")}
        </Button>
      </div>

      {/* Conditionally rendering as a simple way of resetting state when the dialog is closed/opened */}
      {isDeleteInboxDialogOpen && (
        <DeleteInboxDialog
          inbox={inbox}
          isOpen={true}
          onSubmit={deleteInbox}
          onOpenChange={(isOpen) => setIsDeleteInboxDialogOpen(isOpen)}
        />
      )}
    </div>
  );
}

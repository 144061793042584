import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})
export default class IncGoUpdateTemplateGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groupId: undefined,
            newGroup: false,
            newGroupName: '',
            groups: [],
            newGroupErr: ""
        };

        this.save = this.save.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.newGroup = this.newGroup.bind(this);
        this.updateNewGroupName = this.updateNewGroupName.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
    }

    componentDidMount() {
        var obj = {
            groupId: this.props.groupId,
            groups: this.props.groups
        }
        if (!this.props.groups.length) {
            obj.newGroup = true;
        }
        this.setState(obj)
    }

    componentDidUpdate(prevProps) {
        var obj = {};
        var update = false;

        if (this.props.groups && this.props.groups.length > this.state.groups.length) {
            obj.groups = this.props.groups;
            update = true;
        }

        if (update) {
            this.setState(obj)
        }
    }
    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    newGroup(event) {
        var checked = event.currentTarget.checked;
        this.setState({
            newGroup: checked
        })
    }
    updateGroup(evt) {
        this.setState({
            groupId: evt.target.value
        })
    }

    updateNewGroupName(evt) {
        this.setState({
            newGroupName: evt.target.value
        })
    }

    save() {
        var self = this;
        if (this.state.newGroup) {
            axios.post('/templateGroup',
                {
                    groupName: this.state.newGroupName
                })
                .then(response => {
                    axios.put('/template/' + self.props.templateId,
                        {
                            groupID: response.data.TemplateGroup.id,
                            templateName: this.props.templateName
                        })
                        .then(() => {
                            self.closeDrawer();
                            self.props.tableDataGetter();
                        })
                }).catch(err => {
                    self.setState({ newGroupErr: err.data.error });
                })
        } else {

            axios.put('/template/' + this.props.templateId,
                {
                    groupID: self.state.groupId || null
                })
                .then(() => {
                    self.closeDrawer();
                    self.props.tableDataGetter();
                })
                .catch(self.closeDrawer)
        }
    }

    render() {
        return <div>

            <h4 className="mar-b25">{i18n.t('templates:index.groupHeader')}</h4>

            {!!this.state.groups && !!this.state.groups.length && <UILIB.Select outerClassName="mar-b25" data={this.state.groups.map(g => {
                return {
                    value: g.id,
                    label: g.groupName
                }
            })} onChange={this.updateGroup} value={this.state.groupId} placeholder="No Folder" />}

            {!!this.state.groups && !!this.state.groups.length &&
                <UILIB.CheckBox outerClassName="mar-b25" onChange={this.newGroup}>{i18n.t('templates:index.groupCreate')}</UILIB.CheckBox>
            }

            {this.state.newGroup && <UILIB.TextInput outerClassName="mar-b25" type="text" value={this.state.newGroupName} onChange={this.updateNewGroupName} placeholder={i18n.t('templates:index.newGroup')} error={this.state.newGroupErr} />}

            <UILIB.Button text={i18n.t('save')} className="button-primary" iconRight={<UILIB.Icons icon="tick" />} onClick={this.save} />

        </div >
    }
};


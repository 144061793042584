import React from 'react'
import axios from 'axios'
import DBConnection from '~/Classes/siteVars';
import queryString from 'query-string';

import jquery from 'jquery';

import './templatePreview.css';
import { connect } from 'react-redux';
@connect((store) => {
    return {
        user: store.user, siteMaster: store.siteMaster
    }
})

export default class TemplatePreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accountMasterId: null,
            subscriberId: null,
            campaignId: null,
            templateId: null,
            templateHTML: "",
            loaded: 0,
            showClicks: false,
            templateOwnerName: ""
        }

        this.loadTemplate = this.loadTemplate.bind(this);
    }

    componentDidMount() {

        //remove site style.
        try {
            var child = document.getElementById("mpMainStyle");
            child.parentNode.removeChild(child);
        } catch (e) { }

        const query = queryString.parse(this.props.location.search);
        var key = query.key
        var templateId = query.tid;
        var showClicks = query.showClicks;
        var type = query.type;
        var bots = query.bots;

        if (!templateId || isNaN(templateId)) {
            templateId = null;
        }
        if (!showClicks) {
            showClicks = false;
        }
        this.setState({
            key: key,
            templateId: templateId,
            showClicks: showClicks,
            type,
            bots
        }, this.loadTemplate)

    }

    loadTemplate() {
        var baseUrl = DBConnection.getConnString()
        axios.get(baseUrl + `/templatePreview?key=${this.state.key}&templateId=${this.state.templateId}&showClicks=${this.state.showClicks}&type=${this.state.type}${this.state.bots ? '&bots=true' : ''}`)
            .then(response => {
                var templateHtml = response.data.templateHTML;
                var newDiv = document.createElement("div");
                newDiv.style.display = "none";
                newDiv.id = "tmpDiv"
                newDiv.innerHTML = templateHtml;
                jquery("#tmpDiv a").attr("target", "_blank");
                templateHtml = newDiv.innerHTML;
                jquery('#tmpDiv').remove();
                this.setState({
                    templateHTML: templateHtml,
                    loaded: 1
                })
            }).catch(err => {
                console.log(err);
                this.setState({ loaded: 2 })
            })
    }
    render() {

        if (this.state.loaded === 0) {
            return <div></div>
        }
        if (this.state.loaded === 1) {



            return <div>
                <div dangerouslySetInnerHTML={{ __html: this.state.templateHTML }} />
            </div>
        }
        if (this.state.loaded === 2) {
            return <div style={{ padding: "100px", textAlign: "center" }}>Error Retrieving Email</div>
        }
    }

}
import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';

export default class Td extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "td", this.props.localVariables)
        }
        var thisContent = "";
        if (this.props.elementData.content && this.props.elementData.content.value) {
            thisContent = DragDropFunctions.lookupLocalVariables(this.props.localVariables, this.props.elementData.content.value);
        }

        if (this.props.elementData.content && this.props.elementData.content.value) {
            return <td {...eleProps}>
                <span dangerouslySetInnerHTML={{ __html: thisContent }} />
                {this.props.children}
            </td>
        }
        else {
            return <td {...eleProps}>
                {this.props.children}
            </td>
        }

    }
}



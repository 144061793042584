import React from 'react';

export default class DragDropBlocks extends React.Component {

    render() {

        var found = false;
        if (this.props.type === "1col") {
            found = true;
            return <svg id="Layer_1" viewBox="0 0 50 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" x="0px" y="0px" width="50px" height="37px">
                <g>
                    <rect className="svg-fill primary" x="3" y="3" width="44" height="3" rx="1.5" ry="1.5" />
                    <rect className="svg-fill primary" x="3" y="10" width="44" height="3" rx="1.5" ry="1.5" />
                    <rect className="svg-fill primary" x="3" y="17" width="44" height="3" rx="1.5" ry="1.5" />
                    <rect className="svg-fill primary" x="3" y="24" width="44" height="3" rx="1.5" ry="1.5" />
                    <rect className="svg-fill primary" x="3" y="31" width="44" height="3" rx="1.5" ry="1.5" />
                </g>
            </svg>
        }

        if (this.props.type === "2col") {
            found = true;
            return <svg id="Layer_1" viewBox="0 0 50 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" x="0px" y="0px" width="50px" height="37px">
                <g>	<path className="svg-fill primary" d="M 4.5 3 L 22.5 3 C 23.3284 3 24 3.6716 24 4.5 L 24 4.5 C 24 5.3284 23.3284 6 22.5 6 L 4.5 6 C 3.6716 6 3 5.3284 3 4.5 L 3 4.5 C 3 3.6716 3.6716 3 4.5 3 Z" fill="#ffffff" />
                    <path className="svg-fill primary" d="M 27.5 3 L 45.5 3 C 46.3284 3 47 3.6716 47 4.5 L 47 4.5 C 47 5.3284 46.3284 6 45.5 6 L 27.5 6 C 26.6716 6 26 5.3284 26 4.5 L 26 4.5 C 26 3.6716 26.6716 3 27.5 3 Z" fill="#ffffff" />
                    <path className="svg-fill primary" d="M 4.5 10 L 22.5 10 C 23.3284 10 24 10.6716 24 11.5 L 24 11.5 C 24 12.3284 23.3284 13 22.5 13 L 4.5 13 C 3.6716 13 3 12.3284 3 11.5 L 3 11.5 C 3 10.6716 3.6716 10 4.5 10 Z" fill="#ffffff" />
                    <path className="svg-fill primary" d="M 27.5 10 L 45.5 10 C 46.3284 10 47 10.6716 47 11.5 L 47 11.5 C 47 12.3284 46.3284 13 45.5 13 L 27.5 13 C 26.6716 13 26 12.3284 26 11.5 L 26 11.5 C 26 10.6716 26.6716 10 27.5 10 Z" fill="#ffffff" />
                    <path className="svg-fill primary" d="M 4.5 17 L 22.5 17 C 23.3284 17 24 17.6716 24 18.5 L 24 18.5 C 24 19.3284 23.3284 20 22.5 20 L 4.5 20 C 3.6716 20 3 19.3284 3 18.5 L 3 18.5 C 3 17.6716 3.6716 17 4.5 17 Z" fill="#ffffff" />
                    <path className="svg-fill primary" d="M 27.5 17 L 45.5 17 C 46.3284 17 47 17.6716 47 18.5 L 47 18.5 C 47 19.3284 46.3284 20 45.5 20 L 27.5 20 C 26.6716 20 26 19.3284 26 18.5 L 26 18.5 C 26 17.6716 26.6716 17 27.5 17 Z" fill="#ffffff" />
                    <path className="svg-fill primary" d="M 4.5 24 L 22.5 24 C 23.3284 24 24 24.6716 24 25.5 L 24 25.5 C 24 26.3284 23.3284 27 22.5 27 L 4.5 27 C 3.6716 27 3 26.3284 3 25.5 L 3 25.5 C 3 24.6716 3.6716 24 4.5 24 Z" fill="#ffffff" />
                    <path className="svg-fill primary" d="M 27.5 24 L 45.5 24 C 46.3284 24 47 24.6716 47 25.5 L 47 25.5 C 47 26.3284 46.3284 27 45.5 27 L 27.5 27 C 26.6716 27 26 26.3284 26 25.5 L 26 25.5 C 26 24.6716 26.6716 24 27.5 24 Z" fill="#ffffff" />
                    <path className="svg-fill primary" d="M 4.5 31 L 22.5 31 C 23.3284 31 24 31.6716 24 32.5 L 24 32.5 C 24 33.3284 23.3284 34 22.5 34 L 4.5 34 C 3.6716 34 3 33.3284 3 32.5 L 3 32.5 C 3 31.6716 3.6716 31 4.5 31 Z" fill="#ffffff" />
                    <path className="svg-fill primary" d="M 27.5 31 L 45.5 31 C 46.3284 31 47 31.6716 47 32.5 L 47 32.5 C 47 33.3284 46.3284 34 45.5 34 L 27.5 34 C 26.6716 34 26 33.3284 26 32.5 L 26 32.5 C 26 31.6716 26.6716 31 27.5 31 Z" fill="#ffffff" />
                </g>
            </svg>
        }
        if (found === false) {
            return <div></div>
        }

    }
}
import React, { useMemo } from "react";
import classnames from "classnames";
import { propToCssModule } from "~/Classes/styles";
import SiteVars from "~/Classes/siteVars";
import * as styles from "./index.module.scss";
import Group from "./Group";

const Avatar = ({
  size = 32,
  src,
  name,
  status,
  className,
  color,
  style,
  ...rest
}) => {
  const letter = name?.charAt(0)?.toUpperCase();
  const colors = [
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "purple",
    "pink",
    "teal",
  ];

  const assignedColor = useMemo(() => assignColor(), [letter, color]);

  function shouldDisplayLetter() {
    if (!src && name) return true;

    return false;
  }

  function assignColor() {
    if (color) return color;

    if (!letter) return colors[Math.floor(Math.random() * colors.length)];

    if (letter.match(/^[a-zA-Z]$/) !== null) {
      const alphabetPosition = letter.charCodeAt(0) - "A".charCodeAt(0);
      const colorIndex = alphabetPosition % colors.length;
      const assignedColor = colors[colorIndex];
      return assignedColor;
    }
  }


  const classes = classnames(styles.root, className, "avatar", {
    [styles[`rootSize${propToCssModule(size)}`]]: size,
    [styles[`rootColor${propToCssModule(assignedColor)}`]]: assignedColor,
  });

  const genStyle = () => {
    let fStyle = style || {};
    if (color) {
      fStyle.backgroundColor = color;
    }
    let newSrc = src;
    if (newSrc && newSrc.indexOf("http") === -1) {
      newSrc = `${SiteVars.repoEndPoint}${newSrc}`;
    }

    if (newSrc && newSrc.includes("http")) {
      newSrc = newSrc.replace("(", "%28").replace(")", "%29");
      fStyle.backgroundImage = `url(${newSrc})`;
      fStyle.backgroundSize = "cover";
      fStyle.backgroundPosition = "center";
      fStyle.backgroundRepeat = "no-repeat";
    }

    return fStyle
  }

  const fStyle = genStyle();
  return (
    <div
      className={classes}
      style={fStyle}
      data-size={size}
      alt={name}
      title={name}
      {...rest}
    >
      {shouldDisplayLetter() && letter}
      {status === "online" && <span className={styles.statusOnline}></span>}
    </div>
  );
};

Avatar.Group = Group;

export default Avatar;

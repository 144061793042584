// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E7XWwXWXknRVUd69tZkP{display:flex;flex-direction:column;background:var(--grey-0);border:1px solid var(--grey-200);border-radius:8px;padding:24px;transition:all .2s ease;width:100%}.E7XWwXWXknRVUd69tZkP .mce-content-body{outline:none;padding-bottom:0 !important}.mce-tinymce-inline{background:var(--gray-950);color:var(--gray-0);border-radius:12px;box-shadow:var(--shadow-mid)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/channel/email/settings/signature/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,wBAAA,CACA,gCAAA,CACA,iBAAA,CACA,YAAA,CACA,uBAAA,CACA,UAAA,CAEA,wCACE,YAAA,CACA,2BAAA,CAIJ,oBACE,0BAAA,CACA,mBAAA,CACA,kBAAA,CACA,4BAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  background: var(--grey-0);\n  border: 1px solid var(--grey-200);\n  border-radius: 8px;\n  padding: 24px;\n  transition: all 0.2s ease;\n  width: 100%;\n\n  :global(.mce-content-body) {\n    outline: none;\n    padding-bottom: 0 !important;\n  }\n}\n\n:global(.mce-tinymce-inline) {\n  background: var(--gray-950);\n  color: var(--gray-0);\n  border-radius: 12px;\n  box-shadow: var(--shadow-mid);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "E7XWwXWXknRVUd69tZkP"
};
export default ___CSS_LOADER_EXPORT___;

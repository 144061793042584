import React from 'react'

export default class IconsPaused extends React.Component {

    render() {
        let color = "#7246B1";
        let className = "icons-icon";
        if (this.props.color) color = this.props.color;

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.75 3C5.55109 3 5.36032 3.07902 5.21967 3.21967C5.07902 3.36032 5 3.55109 5 3.75V16.25C5 16.664 5.336 17 5.75 17H7.25C7.44891 17 7.63968 16.921 7.78033 16.7803C7.92098 16.6397 8 16.4489 8 16.25V3.75C8 3.55109 7.92098 3.36032 7.78033 3.21967C7.63968 3.07902 7.44891 3 7.25 3H5.75ZM12.75 3C12.5511 3 12.3603 3.07902 12.2197 3.21967C12.079 3.36032 12 3.55109 12 3.75V16.25C12 16.664 12.336 17 12.75 17H14.25C14.4489 17 14.6397 16.921 14.7803 16.7803C14.921 16.6397 15 16.4489 15 16.25V3.75C15 3.55109 14.921 3.36032 14.7803 3.21967C14.6397 3.07902 14.4489 3 14.25 3H12.75Z" fill={color}/>
        </svg>
        
        

    }
}

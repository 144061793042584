import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import DBConnection from '~/Classes/siteVars';
import axios from 'axios';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { I18n } from 'react-i18next';

import MPZBlackLogo from '~/assets/images/mpzLogos/mpzmail-logo-black.svg';

//CONNECT TO STORE
@connect((store) => {
    return { siteMaster: store.siteMaster }
})

export default class UpdateTemplate extends React.Component {
    constructor(props, context) {
        super(props);
        this.state = {
            groups: [],
            customFields: [],
            subscriber: undefined,
            masterErrorMsg: '',
            isReady: false,
            emailAddress: '',
            unsubscribeText: '',
            updateText: '',
            SubscriberId: undefined,
            SenderId: undefined,
            AccountMasterId: undefined,
            foundSubscriber: false,
            showMore: false

        };
        this.update = this.update.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);
        this.delete = this.delete.bind(this);
        this.getSubscriber = this.getSubscriber.bind(this);
        this.toggle = this.toggle.bind(this);
        this.updateField = this.updateField.bind(this);
        this.showMore = this.showMore.bind(this)
    }

    componentDidMount() {
        this.getSubscriber()
    }

    getSubscriber() {
        const query = queryString.parse(this.props.location.search);
        var email = query.email;
        var key = query.key;
        var baseUrl = DBConnection.getConnString()
        axios.get(baseUrl + '/getsub?key=' + key)
            .then(response => {
                this.setState({
                    subscriber: response.data.Subscriber,
                    customFields: response.data.CustomFields,
                    groups: response.data.Groups,
                    key: key,
                    isReady: true,
                    foundSubscriber: true
                })
            }).catch(err => {
                this.setState({ foundSubscriber: false, isReady: true })
            })
    }

    toggle(index, event) {
        var groups = this.state.groups;
        groups[index].unsubscribe = event.target.checked;
        this.setState({
            groups
        })
    }

    update(event) {
        var baseUrl = DBConnection.getConnString()
        axios.put(baseUrl + '/update?key=' + this.state.key, this.state.subscriber)
            .then(response => {
                this.setState({
                    updateText: 'Successfully Updated',
                    emailAddress: this.state.subscriber.emailAddress
                })
            })
            .catch(err => {

            })
    }

    unsubscribe() {

        var groups = this.state.groups.filter(g => g.unsubscribe).map(group => group.id)
        var baseUrl = DBConnection.getConnString()
        axios.get(baseUrl + '/unsubscribe?groups=' + JSON.stringify(groups) + '&key=' + this.state.key)
            .then(response => {
                this.setState({
                    unsubscribeText: 'Successfully Unsubscribed'
                }, this.getSubscriber)
            })
            .catch(err => {

            })
    }

    delete() {
        var baseUrl = DBConnection.getConnString()
        axios.delete(baseUrl + '/deletesub?email=' + this.state.emailAddress + '&key=' + this.state.key, this.state.subscriber)
            .then(() => {
                this.setState({
                    updateText: 'Successfully Deleted'
                }, this.getSubscriber)
            })
            .catch(err => {

            })
    }

    updateField(field, event) {
        var sub = this.state.subscriber;
        if (field === 'emailAddress') {
            sub.emailAddress = event.target.value;
        } else {
            sub.customFields[field] = event.target == undefined ? event : event.target.value;
        }
        this.setState({
            subscriber: sub
        })
    }

    showMore() {
        this.setState({
            showMore: true
        })
    }

    render() {
        if (this.state.isReady) {
            if (this.state.foundSubscriber === false) {
                return (<I18n ns="translations">
                    {
                        (t, { i18n }) => (<div className="container-fluid" style={{ marginTop: 50 }}>
                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={8} md={6} className="col-sm-offset-2 col-md-offset-3">
                                    <UILIB.Paper className="primary">
                                        <div className="title" style={{ textAlign: "center" }}>
                                            {t('public:update.notFound')}
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                            {t('public:update.notFoundDetail')}
                                        </div>
                                    </UILIB.Paper>
                                </UILIB.Column>
                            </UILIB.Row>
                        </div>)
                    }
                </I18n>)
            }
            else {
                var groups = this.state.groups.map((group, index) => <div key={index}><UILIB.CheckBox name={group.name} onChange={event => { this.toggle(index, event) }} style={{ border: '1px solid #9d90df' }}>{group.name}</UILIB.CheckBox></div>)

                var fields = []
                var hidden = []
                if (this.state.subscriber.customFields && this.state.customFields.length > 0) {
                    Object.keys(this.state.subscriber.customFields).forEach(key => {
                        var field = this.state.customFields.find(f => f.id == key)
                        if (field) {
                            if (!field.hidden && !field.customerHidden) {
                                fields.push(<div className="form-group" key={"cf-" + field.id}>
                                    <label htmlFor="">{field.fieldDesc}</label>
                                    {field.fieldType == 'DATE' && <UILIB.TextInput type={field.fieldType} placeholder={field.fieldName} value={this.state.subscriber.customFields[key]} onChange={(evt, val) => this.updateField(key, val)} />}
                                    {field.fieldType !== 'DATE' && <UILIB.TextInput type={field.fieldType} placeholder={field.fieldName} value={this.state.subscriber.customFields[key]} onChange={evt => this.updateField(key, evt)} />}
                                </div>)
                            } else {
                                hidden.push(<div className="form-group" key={"cf-" + field.id}>
                                    <label htmlFor="">{field.fieldDesc}</label>
                                    {field.fieldType == 'DATE' && <UILIB.TextInput disabled={true} type={field.fieldType} placeholder={field.fieldName} value={this.state.subscriber.customFields[key]} onChange={(evt, val) => this.updateField(key, val)} />}
                                    {field.fieldType !== 'DATE' && <UILIB.TextInput disabled={true} type={field.fieldType} placeholder={field.fieldName} value={this.state.subscriber.customFields[key]} onChange={evt => this.updateField(key, evt)} />}
                                </div>)
                            }
                        }
                    })
                }

                return (<I18n ns="translations">
                    {
                        (t, { i18n }) => (<div className="container-fluid" style={{ marginTop: 50 }}>
                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={8} md={6} className="col-sm-offset-2 col-md-offset-3">
                                    <UILIB.Paper className="primary">
                                        <div className="title">
                                            {t('public:update.details')}:
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">{t('public:update.email')}</label>
                                            <UILIB.TextInput type="text" placeholder={t('public:update.email')} value={this.state.subscriber.emailAddress} onChange={evt => this.updateField('emailAddress', evt)} />
                                        </div>
                                        {fields}
                                        {hidden.length > 0 && <UILIB.Row>
                                            {!this.state.showMore && <UILIB.Column xs={12} className="center-xs">
                                                <UILIB.Button text="Show More" className="button-md outline secondary" onClick={this.showMore} />
                                            </UILIB.Column>}
                                            {this.state.showMore && <UILIB.Column xs={12}>
                                                {hidden}
                                            </UILIB.Column>}
                                        </UILIB.Row>}
                                        <UILIB.Button className="outline button-md mar-r10 mar-b10" onClick={this.update}>{t('public:update.update')}</UILIB.Button>
                                        <UILIB.Button className="outline button-md" onClick={this.delete}>{t('public:update.delete')}</UILIB.Button>
                                        {this.state.updateText}
                                    </UILIB.Paper>
                                </UILIB.Column>
                            </UILIB.Row>
                            <UILIB.Row>
                                {!!groups.length && <UILIB.Column xs={12} sm={8} md={6} className="col-sm-offset-2 col-md-offset-3">
                                    <UILIB.Paper className="secondary">
                                        <UILIB.Row>
                                            <UILIB.Column xs={12}>
                                                <div className="title">
                                                    {t('public:update.groups')}:
                                                </div>
                                            </UILIB.Column>
                                        </UILIB.Row>
                                        <UILIB.Row>
                                            <UILIB.Column xs={12}>
                                                {t('public:update.groupsSelect')}
                                            </UILIB.Column>
                                        </UILIB.Row>

                                        <UILIB.Row>
                                            <UILIB.Column xs={12}>
                                                {groups}
                                            </UILIB.Column>
                                        </UILIB.Row>
                                        <UILIB.Row>
                                            <UILIB.Column xs={12}>
                                                <UILIB.Button className="outline button-md" onClick={this.unsubscribe}>{t('public:update.unsubscribe')}</UILIB.Button>
                                            </UILIB.Column>
                                        </UILIB.Row>
                                        {this.state.unsubscribeText}
                                    </UILIB.Paper>
                                </UILIB.Column>}
                            </UILIB.Row>
                            {this.props.siteMaster.siteLogo && this.props.siteMaster.siteLogo.length > 0 &&
                                <UILIB.Row>
                                    <UILIB.Column xs={12} sm={8} md={6} className="col-sm-offset-2 col-md-offset-3 text-center">
                                        <div style={{ fontSize: "14px", color: "#AAAAAA", marginBottom: "10px" }}>{t('public:unsub.poweredBy')}</div>
                                        {this.props.siteMaster.siteId === 1 &&
                                            <div><a href="/"><img src={MPZBlackLogo} style={{ maxWidth: "100px", border: "0" }} /></a></div>
                                        }
                                        {!this.props.siteMaster.siteId === 1 &&
                                            <div><a href="/"><img src={this.props.siteMaster.siteLogo} style={{ maxWidth: "100px", border: "0" }} /></a></div>
                                        }
                                        <div style={{ fontSize: "12px", color: "#AAAAAA" }}>{t('public:unsub.marketing')}</div>

                                    </UILIB.Column>
                                </UILIB.Row>
                            }
                        </div>)
                    }
                </I18n>)
            }
        } else {
            return (null)
        }


    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import UILIB from '../../../../Common-Objects/Lib';
import axios from '~/data/http/axios';
import WysiwygEditor from '../../templates/add/dragDrop/rightTools/elementEditor/components/wysiwyg'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import MergeTags from '../../includes/mergeTags/mergeTags';
import { alterSnackbar } from '../../../../data/actions/siteActions';
@connect((store) => {
    return { permissionStore: store.permission, siteMaster: store.siteMaster, account: store.accountMaster, accountUser: store.user }
})
class CreateConversationHolder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: this.props.subscriber?.emailAddress || "",
            subject: "",
            text: "",
            subscriberId: this.props.subscriber?.id || -1,
            trackerId: -1,
            inboxId: this.props.inboxId,
            channelId: 0,
            channels: undefined,
            trackers: undefined,
            errors: {
                emailAddress: "",
                subject: "",
                text: ""
            },
            drawerContent: "",
            subscriberState: ""
        }

        this.sendMessage = this.sendMessage.bind(this);
        this.openMergeTags = this.openMergeTags.bind(this);
        this.closeMergeTags = this.closeMergeTags.bind(this);
        this.insertMergeTag = this.insertMergeTag.bind(this);
        this.loadChannels = this.loadChannels.bind(this)
        this.loadTrackers = this.loadTrackers.bind(this)
        this.getSubscriberStatus = this.getSubscriberStatus.bind(this)
    }

    componentDidMount() {
        if (this.state.inboxId > 0) this.loadChannels()
        if (this.props.currentSiteId) this.setState({ currentSiteId: this.props.currentSiteId })
    }
    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.inboxId !== this.state.inboxId) {
            this.loadChannels()
        }

        if (prevState.channelId !== this.state.channelId) {
            this.loadTrackers()
        }

        if ((this.state.subscriberId && prevState.subscriberId !== this.state.subscriberId) || (this.state.trackerId > 0 && prevState.trackerId !== this.state.trackerId)) {
            this.getSubscriberStatus()
        }
    }

    getSubscriberStatus() {
        this.setState({ subscriberState: '' })
        if (!this.state.subscriberId || this.state.trackerId < 0) return
        axios.get('/subscriber/' + this.state.subscriberId + '/chatstatus?SiteTrackerId=' + this.state.trackerId).then(res => {
            this.setState({ subscriberState: res.data.status })
        })
    }

    loadChannels() {
        this.setState({ channels: undefined })
        axios.get('/inboxes/' + this.state.inboxId + '/channel').then(res => {
            this.setState({
                channels: res.data,
                channelId: res.data[0].id
            })
        })
    }

    loadTrackers() {
        const channel = this.state.channelId && this.state.channels ? this.state.channels.find(c => c.id === this.state.channelId) : null
        if (!channel) return
        if (channel.type === 2) {
            axios.get('/accountMaster/tracker?InboxChannelId=' + channel.id).then(res => {
                this.setState({
                    trackers: res.data.Trackers,
                    trackerId: res.data.Trackers.length === 1 ? res.data.Trackers[0].id : this.state.trackerId
                })
            })
        }
    }

    async sendMessage() {
        const errors = {
            emailAddress: "",
            subject: "",
            text: ""
        }
        let errored = false;
        if (this.state.subscriberId < 1) {
            errors.emailAddress = "Please select a subscriber"
            errored = true;
        }
        if (!this.state.subject || this.state.subject.length < 4) {
            errors.subject = "Please enter a longer subject"
            errored = true;
        }
        if (!this.state.text || this.state.text.length < 10) {
            errors.text = "Please enter a longer message"
            errored = true;
        }
        this.setState({ errors })
        if (errored) return;

        try {
            const newChat = await axios.post("/inboxes/chat", {
                SubscriberId: this.state.subscriberId,
                text: this.state.text,
                subject: this.state.subject,
                InboxId: this.state.inboxId,
                InboxChannelId: this.state.channelId,
                SiteTrackerId: this.state.trackerId
            })

            this.props.dispatch(alterSnackbar(true, 'Message Sent'))
            this.props.created(newChat.data.id)

        } catch (e) {
            console.log(e)
            this.props.dispatch(alterSnackbar(true, e.data.error))
        }
    }

    openMergeTags(fieldName) {
        var drawerContent = <MergeTags close={this.closeMergeTags} onTagClick={(value) => { this.insertMergeTag(value, fieldName) }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
    }

    insertMergeTag(value, fieldName) {
        let newVal = this.state[fieldName] += " " + value;
        this.setState({ [fieldName]: newVal }, this.closeMergeTags)
    }

    closeMergeTags() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null));
    }

    render() {
        const channel = this.state.channelId && this.state.channels ? this.state.channels.find(c => c.id === this.state.channelId) : null
        return <>
            <UILIB.Column xs={12} sm={this.props.standalone ? 12 : 8} md={this.props.standalone ? 12 : 9} style={{ height: "100%" }}>
                <UILIB.Paper>
                    {!this.props.standalone && <h4 className="mar-b25">Start a new Conversation</h4>}
                    {!this.props.subscriber && <UILIB.AutoComplete
                        outerClassName="mar-b25"
                        iconLeft={<UILIB.Icons icon="person" />}
                        label="Search for Contact by Email"
                        url="/subscriber?search={{name}}"
                        parser={d => d.Subscribers.map(a => ({ label: a.emailAddress, value: a.id }))}
                        onChange={e => { this.setState({ emailAddress: e.label, subscriberId: e.value }) }}
                        error={this.state.errors.emailAddress}
                    />}
                    <UILIB.TextInput
                        name="subject"
                        label="Subject"
                        labelOptions={<a onClick={() => this.openMergeTags("subject")} className="text-xsml">Merge Tags</a>}
                        value={this.state.subject}
                        onChange={(ev) => { this.setState({ subject: ev.target.value }) }}
                        outerClassName="mar-b25"
                        error={this.state.errors.subject} />

                    {this.props.inboxes.length > 1 && <UILIB.Select
                        label="Inbox"
                        data={this.props.inboxes.map(t => ({ label: t.name, value: t.id }))}
                        outerClassName="mar-b25"
                        value={this.state.inboxId}
                        onChange={(ev) => this.setState({ inboxId: ev.target.value })}
                        name="inboxId"
                    />}
                    {this.state.channels?.length > 1 && <UILIB.Select
                        label="Channel"
                        data={(this.state.channels || []).map(c => ({ label: c.name, value: c.id }))}
                        value={this.state.channelId}
                        disabled={!this.state.channels}
                        outerClassName="mar-b25"
                        onChange={e => this.setState({ channelId: e.target.value })}
                        explainer={channel?.type === 2 ? 'If the contact is offline we will send them an email' : ''}
                        explainerEnd={this.state.subscriberState === 0 ? <UILIB.SquareChip>Offline</UILIB.SquareChip> : this.state.subscriberState === 1 ? <UILIB.SquareChip className="square-chip-green">Online</UILIB.SquareChip> : ""}
                    />}
                    {channel?.type === 2 && this.state.trackers?.length > 1 && <UILIB.Select
                        label="Select the website to show it on"
                        data={this.state.trackers.map(t => ({ label: t.host, value: t.id }))}
                        outerClassName="mar-b25"
                        value={this.state.trackerId}
                        onChange={(ev) => this.setState({ trackerId: ev.target.value })}
                        name="trackerId"

                    />}
                    <div className="txtInput_label">Your message</div>
                    <WysiwygEditor
                        updateVal={(val1, val2) => { this.setState({ text: val2 }) }}
                        wysiwygHeight="300px"
                        standalone={true}
                        hideMergeTags={true}
                        error={this.state.errors.text}
                        value={this.state.text}
                        outerClassName="mar-b25"

                    />

                    {(this.state.currentSiteId && this.state.currentSiteId > 0) && <UILIB.WarningHolder className="mar-t10">
                        Note: If the contact is online we will send this via web chat. If they aren't we will send via email.
                    </UILIB.WarningHolder>}

                    <div style={{ display: "flex" }} className="mar-t25">
                        <UILIB.Button
                            text="Send"
                            iconRight={<UILIB.Icons icon="envelope" />}
                            onClick={this.sendMessage}
                            className="mar-r10 button-primary"
                        />

                        <UILIB.Button
                            text="Cancel"
                            iconLeft={<UILIB.Icons icon="cross" />}
                            onClick={this.props.close}
                        />
                    </div>
                </UILIB.Paper>
            </UILIB.Column>
        </>
    }
}

export default CreateConversationHolder;

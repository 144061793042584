import React, { Component } from 'react';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import FileManager from '~/pages/cp/includes/fileManager/fileManager'
import thestore from '~/data/store/store'


export default class LinkHolder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            theVal: "",
            emailSubject: "",
            emailMessage: "",
            linkError: false,
            linkType: 0
        }

        this.clickText = this.clickText.bind(this);
        this.changeText = this.changeText.bind(this)
        this.saveLink = this.saveLink.bind(this);
        this.stopClick = this.stopClick.bind(this);
        this.openFilePicker = this.openFilePicker.bind(this);
        this.changeLinkType = this.changeLinkType.bind(this);

    }


    componentDidMount() {
        if (this.props.currVal) {
            var theVal = this.props.currVal;
            var emailMessage = "";
            var emailSubject = "";
            var ourState = thestore.getState();
            var ourAccMasterId = 0;
            if (ourState.accountMaster && ourState.accountMaster.id) {
                ourAccMasterId = ourState.accountMaster.id
            }
            var linkType = 0;
            if (theVal.toLowerCase().indexOf("mailto:") === 0) {
                theVal = theVal.replace(/mailto\:/g, '')

                if (theVal.indexOf('?') > 0) {
                    var tmpDat1 = theVal.split('?')
                    var theVal = tmpDat1[0];
                    var tmpDat = tmpDat1[1];
                    var emailData = [tmpDat]
                    if (tmpDat.indexOf('&') > 0) {
                        emailData = tmpDat.split('&')
                    }
                    emailData.forEach(tLine => {
                        if (tLine.indexOf('=') > 0) {
                            var tDat = tLine.split('=')
                            if (tDat[0] == "subject") {
                                emailSubject = tDat[1]
                            }
                            if (tDat[0] == "body") {
                                emailMessage = tDat[1];
                            }
                        }
                    })
                }

                linkType = 1;
            }
            if (theVal.toLowerCase().indexOf(`/userContent/${ourAccMasterId}`) > -1) {
                linkType = 2;
            }

            this.setState({ theVal, linkType, emailMessage, emailSubject })
        }
    }
    clickText(event) {
        event.stopPropagation();
        event.target.focus();
    }

    stopClick(event) {
        event.stopPropagation();
    }

    changeText(event) {
        event.stopPropagation();
        var state = this.state;
        if (event.target.name == "linkHolder") {
            state.theVal = event.target.value
        }
        else {
            state[event.target.name] = event.target.value;
        }
        this.setState(state)
    }

    saveLink(event) {
        event.preventDefault();
        event.stopPropagation();
        var thisLink = this.state.theVal;
        thisLink = thisLink.trim();

        var errored = false;
        if (this.state.linkType == "0") {
            if (thisLink.indexOf("http://") == -1 && thisLink.indexOf("https://") == -1 && thisLink.indexOf("}}") == -1) {
                thisLink = "http://" + thisLink;
            }
            if (thisLink.indexOf(".") == -1 && thisLink.indexOf("}}") == -1) {
                errored = true;
            }
        }
        if (this.state.linkType == "1") {
            if (thisLink.indexOf("mailto:") == -1 && thisLink.indexOf("}}") == -1) {
                thisLink = "mailto:" + thisLink;
            }
            if (thisLink.indexOf("@") == -1 && thisLink.indexOf("}}") == -1) {
                errored = true;
            }
        }
        if (this.state.linkType == "1") {
            if (thisLink.indexOf("mailto:") == -1) {
                thisLink = "mailto:" + thisLink;
            }
            if (thisLink.indexOf("@") == -1 && thisLink.indexOf("}}") == -1) {
                errored = true;
            }

            if (!errored) {
                if (this.state.emailSubject && this.state.emailSubject.length) {
                    var delim = '?';
                    if (thisLink.indexOf('?') > 0) delim = '&';
                    thisLink += `${delim}subject=${this.state.emailSubject}`;
                }
                if (this.state.emailMessage && this.state.emailMessage.length) {
                    var delim = '?';
                    if (thisLink.indexOf('?') > 0) delim = '&';
                    thisLink += `${delim}body=${this.state.emailMessage}`
                }
            }

        }

        if (errored == true) {
            this.setState({ linkError: true })
            return;
        }

        this.setState({ linkError: false })
        this.props.linkToolUpdate(thisLink);
    }

    openFilePicker() {
        var self = this;
        var theDrawer = <FileManager onFileClick={(theFile) => { this.props.linkToolUpdate(theFile.url); this.props.startBlur(); thestore.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true, null)); }} />
        self.props.stopBlur();
        thestore.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '100vw', undefined, (event) => { self.props.startBlur(true); }));
    }

    changeLinkType(event) {
        var linkType = event.target.value;
        this.setState({ linkType: linkType, theVal: "", linkError: false })
    }

    render() {
        var linkStyle = {
            width: "130px",
            marginRight: "5px"
        };

        var standardInputStyle = {
            width: "100%",
            display: "block",
            marginBottom: "5px"
        }
        if (this.state.linkError) {
            linkStyle.border = "1px solid #FF0000"
        }
        if (this.state.linkType == "1") {
            linkStyle.display = "block";
            linkStyle.width = "100%"
            linkStyle.marginRight = "0";
            linkStyle.marginBottom = "5px"
        }

        return <div className="wysiLinkHolder" style={{ padding: "10px", width: "200px", overflow: "auto", height: "auto", bottom: "inherit" }}>

            <select className="wysiLinkInput wysiTool" style={standardInputStyle} name="linkType" onChange={this.changeLinkType} value={this.state.linkType} onMouseDown={this.clickText} >
                <option value="0">A website address</option>
                <option value="1">An email Address</option>
                <option value="2">A file (pdf)</option>
            </select>

            {this.state.linkType == "0" && <div>
                <input className="wysiLinkInput wysiTool" name="linkHolder" style={linkStyle} type="text" placeholder="http://" onMouseDown={this.clickText} value={this.state.theVal} onChange={this.changeText} />
                <button className="wysiLinkButton" onMouseDown={this.saveLink}>SAVE</button>
            </div>}

            {this.state.linkType == "1" && <div>
                <input className="wysiLinkInput wysiTool" name="linkHolder" style={linkStyle} type="text" placeholder="your@emailaddress.com" onMouseDown={this.clickText} value={this.state.theVal} onChange={this.changeText} />
                <input className="wysiLinkInput wysiTool" name="emailSubject" style={standardInputStyle} type="text" placeholder="Email Subject (optional)" onMouseDown={this.clickText} value={this.state.emailSubject} onChange={this.changeText} />
                <input className="wysiLinkInput wysiTool" name="emailMessage" style={standardInputStyle} type="text" placeholder="Email Message (optional)" onMouseDown={this.clickText} value={this.state.emailMessage} onChange={this.changeText} />
                <button className="wysiLinkButton" onMouseDown={this.saveLink} style={{ width: "100%" }}>SAVE</button>
            </div>}

            {this.state.linkType == "2" && <div>
                {(this.state.theVal && this.state.theVal.length > 0) && <div>
                    <a href={this.state.theVal} target="_blank"
                        style={{
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                            display: "block",
                            marginBottom: "10px"
                        }}>{this.state.theVal}</a>
                </div>}

                <button className="wysiLinkButton" onMouseDown={this.openFilePicker} style={{ width: "100%" }}>Choose a File</button>
            </div>}

        </div >

    }
}
import React, { useState, useEffect } from "react";
import axios from "~/data/http/axios";
import { useSelector } from "react-redux";
import UILIB from "~/Common-Objects/Lib";
export default function InboxPage({ labId, onClose }) {

    const account = useSelector((state) => state.accountMaster);
    const [lab, setLab] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getLab();
    }, []);

    const getLab = async () => {
        let labData = await axios.get('/accountmaster/labs/' + labId);
        setLab(labData.data);
        setLoading(false);
    };

    const disableBeta = async () => {
        try {
            await axios.delete(`/accountmaster/labs/${lab.id}`);
            onClose();
        }
        catch (err) {
            onClose();
        }
    }
    if (loading) return <></>;

    return (
        <UILIB.Dialog.Root open={true}>
            <UILIB.Dialog.Portal >
                <UILIB.Dialog.Overlay />
                <UILIB.Dialog.Content>
                    <div style={{ padding: "40px" }}>
                        <h4 className="mar-b10">Are you sure?</h4>
                        <div className="mar-b10">Disabling the <strong>{lab.fullName}</strong> beta feature will mean this feature is no longer available to your users.</div>
                        <div className="mar-b25">Anything you have created, sent, etc should still be available to view.</div>
                        <div>
                            <UILIB.Button className="button-red mar-r10" text={"Yes, disable feature"} onClick={disableBeta} />
                            <UILIB.Button text="Cancel" onClick={onClose} />
                        </div>
                    </div>

                </UILIB.Dialog.Content>
            </UILIB.Dialog.Portal >
        </UILIB.Dialog.Root>
    )
}
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import Disconnect from '../genericCp/disconnect';

@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})
class GenericNoConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appId: 0,
            loading: true,
            appData: {},
            appBinding: {}

        };

        this.getApp = this.getApp.bind(this);
        this.getAppBinding = this.getAppBinding.bind(this);
        this.popDisconnect = this.popDisconnect.bind(this);
    }

    componentDidMount() {
        if (!this.props.match.params.appId) {
            this.props.history.push('/cp/company/integrations/')
            return;
        }
        this.setState({ appId: this.props.match.params.appId }, this.getApp);
    }

    getApp() {
        var self = this;
        axios.get("/application/" + this.state.appId).then(response => {
            self.setState({
                appData: response.data.Application
            }, self.getAppBinding)
        }).catch(() => {
            self.props.history.push('/cp/company/integrations/')
        })
    }

    getAppBinding() {
        axios.get("/application/account/app/" + this.state.appData.id).then(response => {
            this.setState({
                appBinding: response.data.Application,
                loading: false
            })
        })
    }

    popDisconnect() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <Disconnect appName={this.state.appData.appName} goDisconnect={() => {
            axios.delete('/accountMaster/application/' + this.state.appBinding.id).then(res => {
                this.props.history.push('/cp/company/integrations')
            })
        }} />, true, "500px"));
    }

    render() {

        if (this.state.loading) return <div>
            <UILIB.LoadingIcons iconType="2" className="mar-b15" />
            <div className="text-center">Loading the App</div>
        </div>



        return <div>
            <UILIB.Row className="middle-xs mar-b10">
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="col-md-offset-2">
                    <div className="quickFlex">
                        <div className="integration-avatar rounded" style={{ backgroundImage: "url(" + this.state.appData.appLogoUri + ")" }}></div>
                        <p className="no-marg pad-left text-lg">{this.state.appData.appName}. </p>
                    </div>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="end-xs">
                    <p className="no-marg">

                        <UILIB.Button className="button-sml grey" onClick={this.popDisconnect}>Disconnect</UILIB.Button>
                    </p>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={12} md={8} lg={8} className="col-md-offset-2">
                    <UILIB.Paper>
                        <h4>{this.state.appData.appDescription}</h4>
                        <div dangerouslySetInnerHTML={{ __html: this.state.appData.appLongDescription }} />
                        <UILIB.Button text="Go to Groups" className="button-md" onClick={() => { this.props.history.push('/cp/subscribers/groups') }} />
                    </UILIB.Paper>
                </UILIB.Column>

            </UILIB.Row>

        </div>
    };
}

export default GenericNoConfig;
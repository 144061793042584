import React, { useState, useEffect } from 'react'
import axios from '~/data/http/axios';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib';
import EmailPreviewer from '~/pages/cp/includes/emailPreviewer/emailPreviewer'
import DragDropFunctions from './dragDropFunctions';
import DragDropStage from './dragDropStage/dragDropStage'
import HTML5Backend from 'react-dnd-html5-backend'
import LeftTools from './leftTools/leftTools'
import RightTools from './rightTools/rightTools'
import { DragDropContext } from 'react-dnd';
import Site from './site';
import PopCampaignChangeTemplate from './popCampaignChangeTemplate'
import queryString from 'query-string';
import i18n from '~/i18n'
import PopPreviewForm from './popPreviewForm'
import CampaignChecker from './campaignChecker'
import SessionTools from '../../../../../Classes/sessionTools';
import HistoryDrawer from './historyDrawer';
import CheckLinksButton from '../checkLinksButton';
import CheckMergeTagsButton from '../checkMergeButton';
import DragDropFormOptions from './form/options';
//CONNECT TO STORE 
@connect((store) => {
    return { user: store.user, browser: store.browser, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

@DragDropContext(HTML5Backend)

export default class DragDrop extends React.Component {
    constructor(props) {
        super(props);

        this.saveTimer;
        this.historyTimer;
        this.saveMessageTimer;

        this.state = {
            otherSessionDetected: false,
            doneWizard: false,
            wizardStep: 0,
            doneFormWizard: false,
            formWizardStep: 0,
            campaign: undefined,
            type: '',
            templateID: 0,
            templateName: "",
            templateNameErr: false,
            history: [],
            socketRoomName: "",
            socketUsers: [],
            socketMyId: -1,
            socketIHaveControl: true,
            templateJson: {},
            historyLogs: [],
            formJson: {},
            components: [],
            layouts: [],
            blocks: [],
            userBlocks: [],
            alertMessage: "",
            alertOpen: false,
            popups: {
                exiting: false
            },
            templateDisplayHTML: null,
            currentDrawer: null,
            selectedElement: { isSelected: false, path: "" },
            selectedWysiId: 0,
            screenSize: "100%",
            pageLoaded: false,
            creatingTemplate: false,
            marketOpen: false,
            templateSimpleJson: {},
            marketPlaceComponents: [],
            templateType: 0,
            loadingText: "",
            loadingPct: 1,
            showGuideLines: false,
            otherCampaigns: null,
            saved: false,
            applications: [],
            autoSave: true,
            warnAboutNewerTemplate: false,
            mergeTagWarnings: []
        };

        this.linksExitPage = this.linksExitPage.bind(this);
        this.saveTemplate = this.saveTemplate.bind(this);
        this.loadTemplate = this.loadTemplate.bind(this);
        this.loadElements = this.loadElements.bind(this);
        this.loadLayouts = this.loadLayouts.bind(this);
        this.goExitPage = this.goExitPage.bind(this);
        this.updateElementsData = this.updateElementsData.bind(this);
        this.clickElement = this.clickElement.bind(this);
        this.deselectElement = this.deselectElement.bind(this);
        this.selectElement = this.selectElement.bind(this);
        this.updateTemplateName = this.updateTemplateName.bind(this);
        this.changeScreenSize = this.changeScreenSize.bind(this);
        this.openEmailPreview = this.openEmailPreview.bind(this);
        this.loadBlocks = this.loadBlocks.bind(this);
        this.loadMergeTags = this.loadMergeTags.bind(this);
        this.reselectTemplate = this.reselectTemplate.bind(this);
        this.saveAsSite = this.saveAsSite.bind(this);
        this.saveFaveColor = this.saveFaveColor.bind(this);
        this.wizardNext = this.wizardNext.bind(this);
        this.formWizardNext = this.formWizardNext.bind(this);
        this.convertToNewDataFormat = this.convertToNewDataFormat.bind(this);
        this.openMarket = this.openMarket.bind(this);
        this.loadUserBlocks = this.loadUserBlocks.bind(this);
        this.reloadUserBlocks = this.reloadUserBlocks.bind(this);
        this.injectElements = this.injectElements.bind(this);
        this.loadTheme = this.loadTheme.bind(this);
        this.loadMarketPlaceBlocks = this.loadMarketPlaceBlocks.bind(this);
        this.previewForm = this.previewForm.bind(this);
        this.historyBack = this.historyBack.bind(this);
        this.showHideGuideLines = this.showHideGuideLines.bind(this);
        this.insertElement = this.insertElement.bind(this);
        this.initSockets = this.initSockets.bind(this);
        this.socketRoomUpdates = this.socketRoomUpdates.bind(this);
        this.socketDataUpdates = this.socketDataUpdates.bind(this);
        this.checkLinks = this.checkLinks.bind(this)
        this.getHistory = this.getHistory.bind(this);
        this.showHistory = this.showHistory.bind(this);
        this.restoreFromHistory = this.restoreFromHistory.bind(this);
        this.loadApplications = this.loadApplications.bind(this);
        this.mergeTagTest = this.mergeTagTest.bind(this)
    }

    componentDidMount() {

        var doneWizard = false;
        var doneFormWizard = false;
        const query = queryString.parse(this.props.location.search);
        var type = query.type;
        var themetype = query.themetype;
        var from = query.from;
        var form = query.form;
        var groupId = query.groupId;
        var formId = query.formId;
        var siteTemplateType = query.sitetype;
        var step = parseInt(query.step)
        var page = query.page;
        var redirect = query.redirect
        if (this.props.user.userData.firstLoad && this.props.user.userData.firstLoad.doneDragDropWizard) {
            doneWizard = true;
        }
        if (this.props.user.userData.firstLoad && this.props.user.userData.firstLoad.doneDragDropFormWizard) {
            doneFormWizard = true;
        }
        let isLandingPage = false
        let landingPage = ''
        if (this.props.match.params.templateID) {
            if (this.props.match.params.templateID == this.props.accountMaster?.accountMaster?.options?.unsubscribeTemplateId) {
                isLandingPage = true
                landingPage = 'unsubscribe'
            }
            if (this.props.match.params.templateID == this.props.accountMaster?.accountMaster?.options?.updateTemplateId) {
                isLandingPage = true
                landingPage = 'update'
            }
        }

        DragDropFunctions.formFiles = []

        this.setState({
            redirect,
            templateName: redirect && !this.state.templateName && redirect.indexOf('https://') === 0 ? 'Free Email Template' : this.state.templateName
        })
        DragDropFunctions.formFiles = []

        if (type) {
            var thisTemplateID = this.props.match.params.templateID;
            DragDropFunctions.templateType = type;
            this.setState({
                type,
                siteTemplateType,
                templateID: thisTemplateID,
                doneWizard: doneWizard,
                doneFormWizard: doneFormWizard,
                themetype
            }, () => {
                if (thisTemplateID) {
                    this.loadTemplate();
                } else {
                    this.loadApplications("elements");
                }
            })
        } else if (from) {
            var themeResp;
            axios.get('/theme/' + from).then(response => {
                themeResp = response;
                var templateJson = response.data.Theme.templateJson;

                templateJson = DragDropFunctions.replaceGlobalOptions(templateJson, true);
                templateJson = DragDropFunctions.replaceDefaultColors(templateJson);

                var theHTML = themeResp.data.Theme.templateHTML;
                if (this.props.user.userData.firstLoad && this.props.user.userData.firstLoad.doneDragDropWizard) {
                    doneWizard = true;
                }
                if (this.props.user.userData.firstLoad && this.props.user.userData.firstLoad.doneDragDropFormWizard) {
                    doneFormWizard = true;
                }

                return axios.post('/template',
                    {
                        "templateName": "Unnamed Template",
                        "templateType": 0,
                        "templateHTML": theHTML,
                        "templateJson": templateJson
                    }).then(_template => {

                        window.open('/cp/templates/add/dragDrop/' + _template.data.Template.templateID, '_self');
                        return;
                    })

            })
        } else if (form && groupId) {

            if (formId) {
                DragDropFunctions.templateType = "form";
                axios.get('/group/' + groupId + '/form/' + formId).then(response => {
                    this.setState({ formOptions: response.data.Form })
                    const form = response.data.Form;
                    if (form.FormFiles?.length) {
                        if ((page === 'confirm' && form.options?.serve === 'email') || (page === 'confirmed' && form.options?.serve === 'emailLanding') || (!page && form.options?.serve === 'clickButton'))
                            DragDropFunctions.formFiles = form.FormFiles
                    }
                    DragDropFunctions.formData = form
                    if (page) {
                        switch (page) {
                            case 'confirm':
                                axios.get('/template/' + response.data.Form.formConfirmationTemplateId)
                                    .then(res => {
                                        var theHTML = res.data.template.templateHTML;
                                        var templateName = res.data.template.templateName;
                                        var templateJson = res.data.template.templateJson;
                                        templateJson = DragDropFunctions.replaceGlobalOptions(templateJson);
                                        this.setState({ doneWizard: doneWizard, doneFormWizard: doneFormWizard, templateHTML: theHTML, templateName: templateName, templateJson: templateJson, CampaignId: res.data.template.CampaignId, step, page, overrideId: response.data.Form.formConfirmationTemplateId }, () => { this.loadApplications("elements") });
                                    })
                                break;
                            case 'created':
                                axios.get('/group/' + groupId + '/form/' + response.data.Form.formCreatedFormId)
                                    .then(res => {
                                        var html = res.data.Form.formHTML;
                                        var json = res.data.Form.formJson;
                                        var name = res.data.Form.formName;
                                        json = DragDropFunctions.replaceGlobalOptions(json);

                                        this.setState({ templateHTML: html, templateJson: json, type: 'form', doneWizard: doneWizard, doneFormWizard: doneFormWizard, form: true, groupId, formId, templateName: name, step, page, overrideId: response.data.Form.formCreatedFormId }, () => { this.loadApplications("formElements") });
                                    })
                                break;
                            case 'update':
                                axios.get('/group/' + groupId + '/form/' + response.data.Form.formUpdatedFormId)
                                    .then(res => {
                                        var html = res.data.Form.formHTML;
                                        var json = res.data.Form.formJson;
                                        var name = res.data.Form.formName;
                                        json = DragDropFunctions.replaceGlobalOptions(json);

                                        this.setState({ templateHTML: html, templateJson: json, type: 'form', doneWizard: doneWizard, doneFormWizard: doneFormWizard, form: true, groupId, formId, templateName: name, step, page, overrideId: response.data.Form.formUpdatedFormId }, () => { this.loadApplications("formElements") });
                                    })
                                break;
                            case 'confirmed':
                                axios.get('/group/' + groupId + '/form/' + response.data.Form.formConfirmedFormId)
                                    .then(res => {
                                        var html = res.data.Form.formHTML;
                                        var json = res.data.Form.formJson;
                                        var name = res.data.Form.formName;
                                        json = DragDropFunctions.replaceGlobalOptions(json);
                                        this.setState({ templateHTML: html, templateJson: json, type: 'form', doneWizard: doneWizard, doneFormWizard: doneFormWizard, form: true, groupId, formId, templateName: name, step, page, overrideId: response.data.Form.formConfirmedFormId }, () => { this.loadApplications("elements") });
                                    })
                                break
                        }
                    } else {
                        var html = response.data.Form.formHTML;
                        var json = response.data.Form.formJson;
                        json = DragDropFunctions.replaceGlobalOptions(json);
                        var name = response.data.Form.formName;
                        if (!json) {
                            axios.get('/theme/form').then(response => {
                                var html = response.data.Theme.templateHTML;
                                var json = response.data.Theme.templateJson;
                                this.setState({ templateHTML: html, templateJson: json, type: 'form', doneWizard: doneWizard, doneFormWizard: doneFormWizard, form: true, groupId, formId, templateName: name, step, page }, () => { this.loadApplications("formElements") });
                            })
                        } else {
                            this.setState({ templateHTML: html, templateJson: json, type: 'form', doneWizard: doneWizard, doneFormWizard: doneFormWizard, form: true, groupId, formId, templateName: name, step, page }, () => { this.loadApplications("formElements") });
                        }
                    }
                })
            }
        } else {
            var thisTemplateID = this.props.match.params.templateID;

            if (!thisTemplateID) {
                this.setState({ creatingTemplate: true, doneWizard: doneWizard, doneFormWizard: doneFormWizard }, () => { this.loadApplications("elements") })
            }
            else {
                this.setState({ templateID: thisTemplateID, doneWizard: doneWizard, doneFormWizard: doneFormWizard, isLandingPage, landingPage }, this.loadTemplate)
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.saved && this.state.saved) {
            clearTimeout(this.saveMessageTimer)
            this.saveMessageTimer = setTimeout(() => {
                this.setState({ saved: false })
            }, 1500)
        }
    }

    componentWillUnmount() {
        clearInterval(this.saveTimer);
        //SocketUtils.leaveRoom(this.state.socketRoomName);
    }

    componentWillReceiveProps(nextProps) {
        var doneWizard = false;
        if (nextProps.user.userData.firstLoad && nextProps.user.userData.firstLoad.doneDragDropWizard) {
            doneWizard = true;
            if (!this.state.doneWizard) {
                this.setState({
                    doneWizard: doneWizard
                })
            }
        }
    }

    initSockets(roomName) {
        this.setState({ socketRoomName: roomName });
        //SocketUtils.joinRoom(roomName, this.socketRoomUpdates, this.socketDataUpdates)
    }

    socketRoomUpdates(data) {
        if (data.action == "joinedRoom") {
            var mySocketId = data.mySocketId;
            var hasControl = data.hasControl;

            this.setState({ socketUsers: data.clients, socketMyId: mySocketId, socketIHaveControl: hasControl })
        }
        if (data.action == "playerJoinedRoom") {
            var socketUsers = this.state.socketUsers;
            if (!socketUsers.find(sk => { sk.socketId == data.data.socketId })) {
                socketUsers.push(data.data)
                this.setState({ socketUsers })
            }
        }
        if (data.action == "playerLeftRoom") {
            var newControllerId = data.data.newControllerId;
            var socketIHaveControl = newControllerId == this.state.socketMyId;
            var socketUsers = this.state.socketUsers.filter(f => f.socketId != data.data.socketId);
            this.setState({ socketUsers, socketIHaveControl });
        }
    }
    socketDataUpdates(data) {
        if (data.action == "templateUpdate") {
            var templateData = JSON.parse(data.data.data)
            this.setState({ templateJson: templateData })
        }
    }

    linksExitPage() {
        var popups = this.state.popups;
        popups.exiting = true;
        this.setState({ popups: popups })
    }

    async getHistory() {
        try {
            let history = await axios.get("/template/history/" + this.state.templateID);
            this.setState({ historyLogs: history.data.logs || [] })
        }
        catch (err) { }
    }

    showHistory() {
        let drawerContent = <HistoryDrawer templateId={this.state.templateID} layouts={this.state.layouts} blocks={this.state.blocks} components={this.state.components} restore={this.restoreFromHistory} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "100vw"));
    }

    restoreFromHistory(templateJson) {
        let self = this;
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        this.setState({ templateJson: templateJson }, () => {
            self.saveTemplate(false, undefined, true);
        })
    }

    mergeTagTest() {
        var thisHTML = DragDropFunctions.generateHtmlFromJson(this.state.templateJson, false, this.state.templateJson.globalVariables);
        let landingPage = this.state.landingPage
        if (this.state.templateID == this.props.accountMaster.accountMaster.options?.unsubscribeTemplateId) landingPage = 'unsubscribe'
        if (this.state.templateID == this.props.accountMaster.accountMaster.options?.updateTemplateId) landingPage = 'update'
        return axios.post('/template/mergeTagsTest', { bodyHtml: thisHTML, type: landingPage }).then(templateErrs => {
            if (!templateErrs.data.success) {
                return Promise.reject({ friendlyError: true, errorText: i18n.t('templates:add.drag.mergeTagWrong') + templateErrs.data.error });
            }
        })
    }

    goExitPage(doPrompt) {

        if (doPrompt === true) {
            var drawerContent = <UILIB.DrawerConfirm header={i18n.t('templates:index.saveChanges')} showCancel={true} confirm={() => this.saveTemplate(true)} cancel={() => {

                const query = queryString.parse(this.props.location.search);

                if (this.state.type === 'site') {

                } else if (this.state.type == 'form') {

                    if (query.redirect) {

                        this.props.history.push(query.redirect)

                    } else {
                        this.props.history.push('/cp/groups/' + this.state.groupId + '/forms');
                    }
                } else {

                    if (this.state.CampaignId) {
                        if (query.redirect) {

                            this.props.history.push(query.redirect);

                        } else {
                            this.props.history.push('/cp/campaigns/add/standard/' + this.state.CampaignId + '/3?from=template');
                        }
                    } else if (query.redirect) {
                        if (query.redirect.indexOf("https://") > -1) {
                            var finalQuery = query.redirect;
                            if (finalQuery.indexOf('{TEMPLATEID}') > -1) {
                                finalQuery = finalQuery.replace(/\{TEMPLATEID\}/g, this.state.templateID)
                            }
                            window.open(finalQuery, '_top');
                        }
                        else {
                            this.props.history.push(query.redirect);
                        }
                    } else {
                        this.props.history.push('/cp/templates/');
                    }
                }
            }} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
        }
        else {
            this.saveTemplate(true)
        }
    }

    saveTemplate(doExitPage, nextAction, suppressNotification, force) {
        if (suppressNotification && !this.state.autoSave && !force) return
        if (force) {
            this.setState({ warnAboutNewerTemplate: false })
        }
        SessionTools.checkSession("/template/" + this.state.templateID).then((resp) => {
            this.setState({ otherSessionDetected: resp })
        })

        this.getHistory();

        var self = this;

        if (!suppressNotification) {
            if (self.state.templateName === "" || self.state.templateName.length < 3) {
                self.setState({
                    alertMessage: i18n.t('templates:add.drag.longer'),
                    alertOpen: true,
                    templateNameErr: true
                });
                return;
            }
            let files = DragDropFunctions.formFiles
            const query = queryString.parse(this.props.location.search);
            if (!this.state.page && this.state.formOptions?.options?.serve === 'email') files = []
            if (this.state.page === 'confirm' && this.state.formOptions?.options?.serve !== 'email') files = []
            if (this.state.page === 'confirmed' && this.state.formOptions?.options?.serve !== 'emailLanding') files = []
            if (!query.formId) files = []
            var errCheckResults = DragDropFunctions.checkForErrors(self.state.templateJson, true, this.state.form && !this.state.page, files);

            if (errCheckResults.hasError) {
                self.setState({
                    alertMessage: errCheckResults.errorMessage,
                    alertOpen: true,
                    templateNameErr: false
                })
                return;
            }

            self.setState({
                alertMessage: i18n.t('templates:add.drag.saving'),
                alertOpen: true,
                templateNameErr: false
            });
        }

        var thisHTML = DragDropFunctions.generateHtmlFromJson(self.state.templateJson, false, self.state.templateJson.globalVariables);
        var templateType = self.state.templateJson.mpzTemplateType ? self.state.templateJson.mpzTemplateType : ''
        var saveProps = {};

        if (this.state.form) {
            saveProps.formName = self.state.templateName;
            saveProps.formJson = DragDropFunctions.convertToSimpleJson(self.state.templateJson);
            saveProps.formHTML = thisHTML;

            saveProps.formType = 0;
            var promise
            if (this.state.formId) {
                promise = axios.put('/group/' + this.state.groupId + '/form/' + (this.state.overrideId || this.state.formId), saveProps)
            } else {
                promise = axios.post('/group/' + this.state.groupId + '/form', saveProps)
            }
            return promise.then(res => {
                self.setState({
                    formId: self.state.formId || res.data.Form.id,
                    alertMessage: i18n.t('templates:add.drag.formSave'), alertOpen: true
                }, () => {
                    if (doExitPage) {
                        const query = queryString.parse(this.props.location.search)
                        var url = '/cp/groups/' + this.state.groupId + '/forms'
                        if (query.redirect) {
                            self.props.history.push(query.redirect);
                        } else {
                            self.props.history.push(url);
                        }
                    }
                    if (nextAction && nextAction === "preview") {
                        this.previewForm();
                    }
                });
            })
        }
        else if (this.state.type == "theme") {


            var obj = {
                templateJson: DragDropFunctions.convertToSimpleJson(this.state.templateJson),
                templateHTML: DragDropFunctions.generateHtmlFromJson(this.state.templateJson, false, this.state.templateJson.globalVariables)
            }
            if (!this.state.themetype || this.state.themetype < 3) {
                obj.name = this.state.templateName
            }
            axios.put('/agency/theme/' + this.state.templateID, obj).then(() => {
                this.setState({
                    alertOpen: true,
                    alertMessage: i18n.t('templates:add.drag.themeSaved')
                })
                if (doExitPage) {
                    const query = queryString.parse(this.props.location.search);

                    if (query && query.redirect) {
                        window.open(query.redirect, "_self")
                    }
                    else {
                        window.close();
                    }
                }
            })
        } else {

            //check parse errors
            return this.mergeTagTest().then(() => {
                saveProps.templateName = self.state.templateName;
                saveProps.templateJson = templateType ? self.state.templateJson : DragDropFunctions.convertToSimpleJson(self.state.templateJson);
                saveProps.templateHTML = thisHTML;
                saveProps.updatedAt = this.state.updatedAt

                var promise;
                let q = '?'
                if (force) q += '&force=true'
                if (self.state.overrideId) {
                    promise = axios.put('/template/' + self.state.overrideId + q, saveProps)
                } else if (self.state.templateID) {
                    if (self.state.type == 'site') {
                        promise = axios.put('/agency/template/' + self.state.templateID + q, template)
                    } else {
                        promise = axios.put('/template/' + self.state.templateID + q, saveProps)
                    }
                } else {
                    promise = axios.post('/template', saveProps)
                }

                return promise

            }).then((res) => {
                self.setState({
                    saved: true,
                    autoSave: force ? true : this.state.autoSave
                })
                if (suppressNotification) {
                    self.setState({
                        templateID: res.data.Template.templateID || self.state.templateID,
                        updatedAt: res.data.Template.updatedAt,
                    });
                    return;
                }

                const updateObj = {
                    templateID: res.data.Template.templateID || self.state.templateID,
                    updatedAt: res.data.Template.updatedAt,
                    alertMessage: i18n.t('templates:add.drag.saved'), alertOpen: true
                }

                self.setState(updateObj, () => {
                    if (doExitPage) {
                        const query = queryString.parse(this.props.location.search);
                        if (query.redirect) {
                            if (query.redirect.indexOf("https://") > -1) {
                                var finalQuery = query.redirect;
                                if (finalQuery.indexOf('{TEMPLATEID}') > -1) {
                                    finalQuery = finalQuery.replace(/\{TEMPLATEID\}/g, this.state.templateID)
                                }
                                window.open(finalQuery, '_top');
                            }
                            else {
                                self.props.history.push(query.redirect)
                            }
                        } else if (self.state.overrideId) {
                            if (query.redirect) {
                                this.props.history.push(query.redirect)
                            } else {
                                this.props.history.push('/cp/groups/' + this.state.groupId + '/forms');
                            }
                        } else if (self.state.CampaignId) {
                            if (query.redirect)
                                self.props.history.push(query.redirect)
                            else
                                self.props.history.push('/cp/campaigns/add/standard/' + self.state.CampaignId + '/3');

                        } else {
                            self.props.history.push('/cp/templates/');
                        }
                    }
                    if (nextAction && nextAction === "preview") {
                        self.openEmailPreview();
                    }
                });
            }).catch((error) => {
                console.log(JSON.stringify(error, null, 2))
                if (error.status === 409) {
                    this.setState({
                        autoSave: false,
                        warnAboutNewerTemplate: true
                    })
                } else if (!suppressNotification) {
                    var errMessage = i18n.t('templates:add.drag.errorSaving')
                    if (error.friendlyError) {
                        errMessage = error.errorText
                    }
                    self.setState({
                        alertMessage: errMessage, alertOpen: true
                    });
                }
                return 'sausage'
            })
        }
    }

    loadTemplate() {
        this.setState({
            warnAboutNewerTemplate: false,
            pageLoaded: false
        })
        if (this.state.type == 'site') {

            //this.initSockets("template_" + this.state.templateID)
            axios.get('/agency/template/site/' + this.state.templateID + '?type=' + this.state.siteTemplateType)
                .then(res => {
                    var theHTML = res.data.template.templateHTML || res.data.template.templateHtml;
                    var templateName = res.data.template.templateName || res.data.template.name;
                    var templateJson = res.data.template.templateJson;
                    this.setState({ templateHTML: theHTML, templateName: templateName, templateJson: templateJson, loadingText: i18n.t('templates:add.drag.loadingText.step2'), loadingPct: 10 }, () => { this.loadApplications("elements") });
                })
        } else if (this.state.type == 'theme') {
            //this.initSockets("theme_" + this.state.templateID)
            axios.get('/theme/' + this.state.templateID)
                .then(res => {
                    var theHTML = res.data.Theme.templateHTML;
                    var templateName = res.data.Theme.name;
                    var templateJson = res.data.Theme.templateJson;
                    this.setState({ templateHTML: theHTML, templateName: templateName, templateJson: templateJson, loadingText: i18n.t('templates:add.drag.loadingText.step2'), loadingPct: 10 }, () => {
                        if (this.state.themetype == 2) {
                            this.loadApplications("formElements")
                        } else {
                            this.loadApplications("elements")
                        }
                    });
                })
        } else {

            var templateRes;
            var self = this;
            //this.initSockets("template_" + this.state.templateID)
            axios.get('/template/' + this.state.templateID).then((res) => {

                templateRes = res;
                var templateJson = res.data.template.templateJson;
                templateJson = DragDropFunctions.replaceGlobalOptions(templateJson);

                let isLandingPage = this.state.isLandingPage
                let landingPage = this.state.landingPage
                if (templateRes.data.template.templateType === 5 && templateRes.data.template.templateName === 'Unsubscribe') {
                    isLandingPage = true
                    landingPage = 'unsubscribe'
                }
                if (templateRes.data.template.templateType === 5 && templateRes.data.template.templateName === 'Update your Profile') {
                    isLandingPage = true
                    landingPage = 'update'
                }

                var theHTML = templateRes.data.template.templateHTML;
                var templateName = templateRes.data.template.templateName;
                if (templateRes.data.template.CampaignId) {
                    axios.get('/campaign/' + templateRes.data.template.CampaignId).then(response => {
                        this.setState({
                            campaign: response.data.Campaign
                        })
                    })
                } else {
                    axios.get('/template/' + this.state.templateID + '/children').then(res => {
                        if (res.data.campaigns && res.data.campaigns.length) {
                            this.setState({ otherCampaigns: res.data.campaigns })
                        }
                    })
                }
                self.setState({ templateHTML: theHTML, templateName: templateName, updatedAt: res.data.template.updatedAt, templateJson: templateJson, CampaignId: templateRes.data.template.CampaignId, templateType: res.data.template.templateType, isLandingPage, landingPage, loadingText: i18n.t('templates:add.drag.loadingText.step2'), loadingPct: 10 }, () => { self.loadApplications("elements") });
            }).catch(function (error) {
                console.log(error)
            })
        }

    }

    async loadApplications(nextType) {

        let apps = await axios.get('/accountMaster/application');
        apps = apps.data && apps.data.Applications ? apps.data.Applications : [];
        this.setState({
            applications: apps
        }, () => {
            if (nextType == "formElements") {
                this.loadFormElements()
                return;
            }
            this.loadElements();
        })
    }

    async loadElements() {
        let components = await axios.get('/templateComponent/0')

        if ((this.state.page === 'confirm' && this.state.formOptions.options?.serve === 'email') || (this.state.page === 'confirmed' && this.state.formOptions.options?.serve === 'emailLanding')) {
            const gatedComponents = await axios.get('/templateComponent/5')
            components.data.TemplateComponents.push(...gatedComponents.data.TemplateComponents)
        }

        components = components.data.TemplateComponents.filter(comp => {
            if (!comp.forAppId) return true;
            let foundApp = this.state.applications.find(app => app.ApplicationId == comp.forAppId);
            if (foundApp) {
                if (comp && comp.options) {
                    comp.options.toolbarIcon = foundApp.Application.appLogoUri;
                }
                return true
            }
            return false;
        })
        components = components.map(theComp => {
            var thisOpts = DragDropFunctions.replaceGlobalOptions(theComp.options);
            thisOpts.forAppId = theComp.forAppId
            thisOpts.marketPlace = 0;
            return thisOpts
        })

        this.setState({
            components: components,
            loadingText: i18n.t('templates:add.drag.loadingText.step3'),
            loadingPct: 20
        }, this.loadMarketPlaceBlocks)
    }

    async loadFormElements() {
        try {
            let self = this;
            let components = await axios.get('/templateComponent/3')

            if (this.state.page === 'confirm' && this.state.formOptions.options?.serve === 'email') {
                const gatedComponents = await axios.get('/templateComponent/5')
                components.data.TemplateComponents.push(...gatedComponents.data.TemplateComponents)
            }

            if (!this.state.page && this.state.formOptions?.options?.serve === 'clickButton') {
                const gatedComponents = await axios.get('/templateComponent/6')
                components.data.TemplateComponents.push(...gatedComponents.data.TemplateComponents.map(t => {
                    t.options.content.warning = 'hidden until after submit'
                    return t
                }))
            }

            components = components.data.TemplateComponents.filter(comp => {
                if (!comp.forAppId) return true;
                let foundApp = this.state.applications.find(app => app.ApplicationId == comp.forAppId);
                if (foundApp && foundApp.appSettings && foundApp.appSettings.groupId && foundApp.appSettings.groupId == this.state.groupId) {
                    if (comp && comp.options) {
                        comp.options.toolbarIcon = foundApp.Application.appLogoUri;
                    }
                    return true
                }
                return false;
            })
            components = components.map(theComp => {
                var thisOpts = DragDropFunctions.replaceGlobalOptions(theComp.options);
                thisOpts.forAppId = theComp.forAppId
                return thisOpts
            })

            this.setState({
                components: components,
                loadingText: i18n.t('templates:add.drag.loadingText.step3'),
                loadingPct: 20
            }, () => {
                if (this.state.form || this.state.themetype == 2) {
                    self.loadLayouts();
                }
                else {
                    self.loadBlocks();
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    loadMarketPlaceBlocks() {
        var self = this;
        axios.get('/templateComponent/99').then(response => {
            var components = self.state.components;
            var marketPlaceComponents = response.data.TemplateComponents;
            var newComponents = response.data.TemplateComponents.map(theComp => {
                var thisOpts = DragDropFunctions.replaceGlobalOptions(theComp.options);
                thisOpts.marketPlace = 1;
                return thisOpts
            })
            components = components.concat(newComponents);
            self.setState({
                components,
                marketPlaceComponents,
                loadingText: i18n.t('templates:add.drag.loadingText.step4'),
                loadingPct: 30
            }, this.loadLayouts)
        })
    }

    loadLayouts() {
        var url = '/templateComponent/1';

        if (this.state.form && this.state.page != "confirmed") {
            url = '/templateComponent/4'
        }
        var self = this;
        axios.get(url).then(data => {
            self.setState({
                layouts: data.data.TemplateComponents.map(theComp => {
                    var thisOpts = DragDropFunctions.replaceGlobalOptions(theComp.options);
                    return thisOpts
                }),
                loadingText: i18n.t('templates:add.drag.loadingText.step5'),
                loadingPct: 60
            }, () => {
                if (this.state.form && this.state.page != "confirmed") {
                    self.convertToNewDataFormat();
                } else {
                    self.loadBlocks();
                }
            })
        })
    }

    loadBlocks() {
        var self = this;
        axios.get('/templateComponent/2').then(data => {
            var blocks = data.data.TemplateComponents.map(theComp => {
                var thisOpts = DragDropFunctions.replaceGlobalOptions(theComp.options);
                if (!thisOpts.content.version || thisOpts.content.version != 3) {
                    //convert to new format
                    thisOpts.content = DragDropFunctions.convertToNewDataFormat(thisOpts.content)
                }
                //convert to html
                var tmpContent = DragDropFunctions.convertToFullJson(thisOpts.content, "_ELEMENTSINSERTHERE_", this.state.layouts, [], this.state.components)
                thisOpts.content = tmpContent[0];
                return thisOpts
            }).sort((a, b) => a.title.indexOf('GDPR') > -1 ? 1 : -1)

            self.setState({
                blocks,
                loadingText: i18n.t('templates:add.drag.loadingText.step6'),
                loadingPct: 70
            }, self.loadUserBlocks)
        })
    }

    loadUserBlocks() {
        var self = this;
        axios.get('/templateComponent/100').then(data => {
            let userBlocks = data.data.TemplateComponents.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            })

            userBlocks = userBlocks.map(theComp => {

                var thisOpts = DragDropFunctions.replaceGlobalOptions(theComp.options);
                thisOpts.componentId = theComp.id
                if (!thisOpts.content.version || thisOpts.content.version != 3) {
                    //convert to new format
                    thisOpts.content = DragDropFunctions.convertToNewDataFormat(thisOpts.content)
                }
                //convert to html
                var tmpContent = DragDropFunctions.convertToFullJson(thisOpts.content, "_ELEMENTSINSERTHERE_", this.state.layouts, [], this.state.components)
                thisOpts.content = tmpContent[0];

                return thisOpts
            })
            self.setState({
                userBlocks,
                loadingText: i18n.t('templates:add.drag.loadingText.step5'),
                loadingPct: 60
            }, self.convertToNewDataFormat)
        })
    }

    reloadUserBlocks() {
        var self = this;
        axios.get('/templateComponent/100').then(data => {
            let userBlocks = data.data.TemplateComponents.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            })
            userBlocks = userBlocks.map(theComp => {

                var thisOpts = DragDropFunctions.replaceGlobalOptions(theComp.options);
                thisOpts.componentId = theComp.id
                if (!thisOpts.content.version || thisOpts.content.version != 3) {
                    //convert to new format
                    thisOpts.content = DragDropFunctions.convertToNewDataFormat(thisOpts.content)
                }
                //convert to html
                var tmpContent = DragDropFunctions.convertToFullJson(thisOpts.content, "_ELEMENTSINSERTHERE_", this.state.layouts, [], this.state.components)
                thisOpts.content = tmpContent[0];

                return thisOpts
            })
            self.setState({
                userBlocks
            })
        })
    }

    async convertToNewDataFormat() {

        var templateSimpleJson;
        if (this.state.templateJson.mpzTemplateType && this.state.templateJson.mpzTemplateType == "raw") {
            templateSimpleJson = this.state.templateJson
        }
        else {
            if (this.state.templateJson && !this.state.templateJson.version) {
                templateSimpleJson = DragDropFunctions.convertToNewDataFormat(this.state.templateJson, this.state.form)
            } else {
                templateSimpleJson = this.state.templateJson;
            }
        }

        if (this.state.page === 'confirm' && this.state.formOptions.options?.serve === 'email') {
            const gatedComponents = await axios.get('/templateComponent/5')
            if (gatedComponents.data.TemplateComponents.length) {
                templateSimpleJson = DragDropFunctions.emailFilesReplace(templateSimpleJson, '{{{__link}}}', gatedComponents.data.TemplateComponents[0])
            }
        }

        if (!this.state.page && this.state.formOptions?.options?.serve === 'clickButton') {
            const gatedComponents = await axios.get('/templateComponent/6')
            if (gatedComponents.data.TemplateComponents.length) {
                templateSimpleJson = DragDropFunctions.formFilesReplace(templateSimpleJson, gatedComponents.data.TemplateComponents[0])
            }
        }

        this.setState({ templateSimpleJson, loadingText: i18n.t('templates:add.drag.loadingText.step7'), loadingPct: 80 }, this.loadTheme);
    }

    loadTheme() {
        var self = this;
        var url = '/theme/98';
        if (this.state.form && this.state.page != "confirmed") url = '/theme/99'
        axios.get(url)
            .then(res => {
                var bodyJson = res.data.Theme.templateJson;
                self.injectElements(bodyJson);
            })
    }

    injectElements(bodyJson) {
        var templateJson = ""
        if (this.state.templateJson.mpzTemplateType && this.state.templateJson.mpzTemplateType == "raw") {
            templateJson = this.state.templateJson;
        }
        else {
            templateJson = DragDropFunctions.convertToFullJson(this.state.templateSimpleJson, bodyJson, this.state.layouts, this.state.blocks, this.state.components)
        }

        if (this.state.form || this.state.templateType == 5 || this.state.type === 'site') {
            this.setState({ templateJson, loadingText: i18n.t('templates:add.drag.loadingText.step8'), loadingPct: 99 }, this.loadMergeTags)
        }
        else {
            DragDropFunctions.checkAndInsertFreeBanner(templateJson).then(_templateJson => {
                templateJson = _templateJson;
                this.setState({ templateJson, loadingText: i18n.t('templates:add.drag.loadingText.step8'), loadingPct: 99 }, this.loadMergeTags)
            })
        }
    }

    loadMergeTags() {

        //start auto saver
        if (this.state.type !== 'theme') this.saveTimer = setInterval(() => { this.saveTemplate(false, undefined, true) }, 20000);

        this.getHistory();

        var self = this;
        let type = this.state.type
        if (this.state.templateID == this.props.accountMaster?.accountMaster?.options?.unsubscribeTemplateId) type = "6"
        if (this.state.templateID == this.props.accountMaster?.accountMaster?.options?.updateTemplateId) type = "6"
        DragDropFunctions.getCustomFields(type, this.state.campaign).then((res) => {

            if ((!this.state.form || (this.state.form && !this.state.formId)) && self.state.layouts.length) {
                var templateJson = JSON.stringify(self.state.templateJson);
                templateJson = templateJson.replace(/\"\[INITIALLAYOUT\]\"/g, "[" + JSON.stringify(self.state.layouts[0].content) + "]");
                templateJson = JSON.parse(templateJson);
                var history = [];
                history.push(templateJson);
                self.setState({
                    templateJson: templateJson,
                    history,
                    pageLoaded: true
                }, this.mergeTagTest)
            } else {
                var history = [];
                history.push(this.state.templateJson)
                self.setState({
                    history,
                    pageLoaded: true
                })
            }

        })
    }

    updateTemplateName(ele) {
        this.setState({ templateName: ele.currentTarget.value });
    }
    updateElementsData(newJson, path) {
        var self = this;
        var isSelected = true;
        if (!path || path === undefined || path === "") {
            isSelected = false;
        }
        if (path === null) {
            isSelected = this.state.selectedElement.isSelected;
            path = this.state.selectedElement.path;
        }
        newJson = JSON.stringify(newJson);
        newJson = JSON.parse(newJson.replace(/\[RANDOMNO\]/g, Math.floor(Math.random() * 999999)))

        // function difference(object, base) {
        //     function changes(object, base) {
        //         return _.transform(object, function (result, value, key) {
        //             if (!_.isEqual(value, base[key])) {
        //                 result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
        //             }
        //         });
        //     }
        //     return changes(object, base);
        // }

        // var simplifiedNew = JSON.stringify(newJson);
        // SocketUtils.sendData(this.state.socketRoomName, {
        //     templateId: this.state.templateID,
        //     data: simplifiedNew
        // }, "templateUpdate")

        if (this.historyTimer) clearTimeout(this.historyTimer);
        this.historyTimer = setTimeout(function () {
            var history = self.state.history;
            history.push(newJson);
            if (history.length > 30) history.splice(0, 1);
            self.setState({ history });

        }, 1000)

        this.setState({ selectedElement: { isSelected: isSelected, path: path }, templateJson: newJson });
    }

    clickElement(path, selectedWysi) {

        this.setState({ selectedElement: { isSelected: false, path: "", selectedWysiId: 0 }, marketOpen: false }, () => { this.selectElement(path) });
    }
    selectElement(path) {

        this.setState({ selectedElement: { isSelected: true, path: path }, marketOpen: false })
    }
    deselectElement() {
        this.setState({ selectedElement: { isSelected: false, path: "", selectedWysiId: 0 } })
    }

    changeScreenSize(type) {
        var screenSize = "100%";
        if (type === 1) {
            screenSize = "768px";
        }
        if (type === 2) {
            screenSize = "480px";
        }
        this.setState({ screenSize: screenSize });
    }

    openEmailPreview() {
        var drawerContent = <EmailPreviewer templateId={this.state.templateID} history={this.props.history} subject={this.state.campaign ? 'TEST: ' + (this.state.campaign.subject || this.state.campaign.campaignName) : ''} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
    }

    previewForm() {

        var drawerContent = <PopPreviewForm FormId={this.state.overrideId || this.state.formId} AccountMasterId={this.props.accountMaster.id} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));

    }

    reselectTemplate() {
        var query = queryString.parse(this.props.location.search);
        var drawerContent = <PopCampaignChangeTemplate campaignId={this.state.CampaignId} history={this.props.history} redirect={query.redirect} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    saveFaveColor(color, action) {
        var theElementData = this.state.templateJson;
        if (action == "add") {
            var found = theElementData.globalVariables.favoriteColors.find(theCol => theCol === color);
            if (!found) {
                theElementData.globalVariables.favoriteColors.push(color);
                this.setState({ templateJson: theElementData });
            }
        }
        if (action == "remove") {
            var colIndex = theElementData.globalVariables.favoriteColors.indexOf(color);
            if (colIndex && colIndex > -1) {
                theElementData.globalVariables.favoriteColors.splice(colIndex, 1);
                this.setState({ templateJson: theElementData });
            }
        }
    }

    wizardNext() {
        var currStep = this.state.wizardStep + 1;
        var doneWiz = false;
        if (currStep >= 7) {
            doneWiz = true;
            var firstLoad = { doneDragDropWizard: false };
            if (typeof this.props.user.userData.firstLoad === "object" & this.props.user.userData.firstLoad != undefined) {
                firstLoad = this.props.user.userData.firstLoad;
            }
            firstLoad.doneDragDropWizard = true;
            axios.put("/accountUser", { firstLoad: firstLoad })
        }
        this.setState({ wizardStep: currStep, doneWizard: doneWiz })
    }

    formWizardNext() {
        var currStep = this.state.formWizardStep + 1;
        var doneWiz = false;
        if (currStep >= 6) {
            doneWiz = true;
            var firstLoad = { doneDragDropFormWizard: false };
            if (typeof this.props.user.userData.firstLoad === "object" & this.props.user.userData.firstLoad != undefined) {
                firstLoad = this.props.user.userData.firstLoad;
            }
            firstLoad.doneDragDropFormWizard = true;
            axios.put("/accountUser", { firstLoad: firstLoad })
        }
        this.setState({ formWizardStep: currStep, doneFormWizard: doneWiz })
    }

    saveAsSite() {
        var template = {
            type: 1,
            templateJson: DragDropFunctions.convertToSimpleJson(this.state.templateJson),
            templateHTML: DragDropFunctions.generateHtmlFromJson(this.state.templateJson, false, this.state.templateJson.globalVariables)
        }

        var self = this;
        function finish(name, type, skip) {
            template.templateName = name;
            template.setTemplate = type;

            if (self.state.templateID && self.state.templateID != -1) {
                axios.put('/agency/template/' + self.state.templateID, template).then(response => {
                    if (!skip) self.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
                    self.setState({
                        alertOpen: true,
                        alertMessage: i18n.t('templates:index.templateSave')
                    })
                }).catch(err => {
                    self.setState({
                        alertOpen: true,
                        alertMessage: i18n.t('templates:index.errorCreatingTemplate')
                    })
                })
            } else {
                axios.post('/agency/template', template).then(response => {
                    if (!skip) self.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
                    self.setState({
                        alertOpen: true,
                        alertMessage: i18n.t('templates:index.templateCreated')
                    })
                }).catch(err => {
                    self.setState({
                        alertOpen: true,
                        alertMessage: i18n.t('templates:index.errorCreatingTemplate')
                    })
                })
            }
        }

        console.log(this.state.siteTemplateType)
        if (['unsubscribeDefaultPage', 'updateDefaultPage', 'junkDefaultPage', 'soft2fa'].indexOf(this.state.siteTemplateType) > -1) {
            finish(this.state.templateName, this.state.siteTemplateType, true)
        } else {
            var drawerContent = <Site name={this.state.templateName} type={this.state.siteTemplateType} finish={finish} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, null, null));
        }
    }

    openMarket() {
        var isOpen = false;
        if (this.state.marketOpen) {
            isOpen = false
        }
        else {
            isOpen = true;
        }
        this.setState({ marketOpen: isOpen })

    }

    historyBack() {
        if (this.state.history.length > 1) {
            var theHistory = this.state.history
            theHistory.splice(theHistory.length - 1, 1);
            var newJson = theHistory[theHistory.length - 1]
            var currSelectedElement = this.state.selectedElement
            this.setState({ templateJson: newJson, history: theHistory }, () => {
                this.deselectElement()
            });
        }
    }

    showHideGuideLines() {
        var showGuideLines = true;
        if (this.state.showGuideLines == true) showGuideLines = false;
        this.setState({ showGuideLines })
    }

    insertElement(element, type) {
        //inserts an element at the end of the json and scrolls to it
        //currently just displaying a warning
        this.setState({
            alertMessage: i18n.t('templates:index.clickandDragonTemplate'),
            alertOpen: true,
            templateNameErr: false
        })
        return;
    }

    async checkLinks() {
        await this.saveTemplate(false, false, false);
    }

    formOptions() {
        const drawerContent = <DragDropFormOptions form={DragDropFunctions.formData} app={this.state.applications.find(a => a.appSettings?.groupId === DragDropFunctions.formData?.GroupId)} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, 500));
    }

    render() {
        var doneWizard = 0;
        var doneFormWizard = 0;

        var templateNameCSS = {};
        var templateNameClass = ''
        if (this.state.templateNameErr === true) {
            templateNameCSS.borderBottom = "2px solid red";
        }

        var templateType = "";
        if (this.state.templateJson && this.state.templateJson.mpzTemplateType) {
            templateType = this.state.templateJson.mpzTemplateType;
        }

        if (this.state.pageLoaded) {
            const app = this.state.applications.find(a => a.appSettings?.groupId === DragDropFunctions.formData?.GroupId)
            const showFormPopup = app?.ApplicationId === 8 && this.state.form && !DragDropFunctions.formData?.wizard?.capsule && DragDropFunctions.formData?.formType !== 4

            return <div>
                {this.state.warnAboutNewerTemplate && <UILIB.Modal width="900px">
                    <h4 className="mar-b15">Newer Template Detected</h4>
                    <div className="mar-b15">
                        There is a newer version of this template stored, please check this template is not being updated elsewhere
                    </div>
                    <div className="mar-b15">
                        Auto saving is disabled
                    </div>
                    <div className="quickFlex" style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <UILIB.Button className="button-md button-primary mar-r10" onClick={() => this.saveTemplate(false, null, false, true)}>Force Save</UILIB.Button>
                        <UILIB.Button className="button-md button-secondary" onClick={() => this.loadTemplate()}>Load Saved Template</UILIB.Button>
                    </div>
                </UILIB.Modal>}

                {showFormPopup && <UILIB.Modal width="900px" paperClass={"with_header"} titleStyle={{ backgroundColor: '#453dd8', color: 'white' }} title={<div className="quickFlex">
                    <UILIB.Icons icon="capsule" style={{ height: 24, width: 24 }} color="white" />
                    <div className='mar-l20 text-heavy' style={{ fontSize: 16 }}>Capsule Form Settings</div>
                </div>}>
                    <FormCapsulePopup form={DragDropFunctions.formData} close={() => this.setState({})} />
                </UILIB.Modal>}

                <div className="dragDropToolBar" id="dragDropToolBar">
                    <div className="templateTitle">
                        {!this.state.CampaignId && <UILIB.TextInput style={templateNameCSS} className={"txtInput editor " + templateNameClass} value={this.state.templateName} onChange={this.updateTemplateName} placeholder={this.state.form ? i18n.t('templates:index.enterAFormName') : i18n.t('templates:index.enterATemplateName')} />}
                        {(this.state.CampaignId && this.state.campaign && this.state.campaign.subject) && <div>
                            <div>{this.state.campaign.subject}</div>
                            <div style={{ fontSize: "10px" }}>{this.state.campaign.campaignName}</div>
                        </div>}
                    </div>
                    <div className="actions">
                        <div className='action-holder quickFlex'>
                            <UILIB.SquareChip className={"square-chip-large" + (!this.state.saved ? " square-chip-hide" : "")} iconRight={<UILIB.Icons icon="floppydisk" />}>Template Saved</UILIB.SquareChip>
                        </div>

                        {(this.state.history.length && this.state.history.length > 1) &&
                            <div className="actions-holder">
                                <div className="button">
                                    <UILIB.Icons icon="undo"
                                        alt={i18n.t('templates:index.undo')}
                                        title={i18n.t('templates:index.undo')}
                                        onClick={this.historyBack}
                                        style={{ width: "20px", height: "20px" }}
                                    />
                                </div>
                            </div>
                        }

                        {!this.state.type && (
                            <div className="actions-holder">
                                <CheckMergeTagsButton onStart={() => DragDropFunctions.generateHtmlFromJson(this.state.templateJson, false, this.state.templateJson.globalVariables)} onClick={this.checkLinks} id={this.state.templateID} landingPage={this.state.landingPage} />
                            </div>
                        )}

                        {!this.state.type && (
                            <div className="actions-holder">
                                <CheckLinksButton templateId={this.state.templateID} onClick={this.checkLinks} />
                            </div>
                        )}

                        {!this.state.type && this.state.historyLogs && this.state.historyLogs.length > 1 && <div className="actions-holder">
                            <UILIB.Button onClick={this.showHistory} text={"History"} />
                        </div>
                        }

                        {(this.state.CampaignId && (!this.state.redirect || this.state.redirect.indexOf("automation/addnew") < 0)) &&
                            <div className="actions-holder">
                                <UILIB.Button onClick={this.reselectTemplate} text={i18n.t('templates:add.drag.changeTemplate')} />
                            </div>
                        }
                        {this.state.type === 'site' &&
                            <div className="actions-holder">
                                <UILIB.Button onClick={this.saveAsSite} text={"Save Site Template"} />
                            </div>
                        }

                        {this.state.form && !this.state.page && <div className="actions-holder">
                            <UILIB.Button onClick={this.formOptions.bind(this)}>Form Options</UILIB.Button>
                        </div>}

                        {(!this.state.type && this.state.templateType != 5) &&
                            <div className="actions-holder">
                                <UILIB.Button
                                    onClick={() => { this.saveTemplate(false, "preview") }}
                                    text={i18n.t('templates:index.preview')}
                                    iconLeft={<UILIB.Icons icon="eye" style={{ width: "18px", height: "18px" }} />}
                                />
                            </div>
                        }
                        {((!this.state.type || this.state.type == 'form') && this.state.form) &&
                            <div className="actions-holder">
                                <UILIB.Button
                                    onClick={() => { this.saveTemplate(false, "preview") }}
                                    text={i18n.t('templates:index.preview')}
                                    iconLeft={<UILIB.Icons icon="eye" style={{ width: "18px", height: "18px" }} />}
                                />
                            </div>
                        }

                        {((this.state.type == 'form' || this.state.redirect)
                            ||
                            (this.state.type !== 'site' && this.state.CampaignId && this.state.type != 'form')
                            ||
                            (this.state.type !== 'site' && !this.state.CampaignId && this.state.type != 'form')
                        )
                            &&
                            <div className="actions-holder">
                                <UILIB.Button
                                    onClick={() => { this.goExitPage(false) }}
                                    text={i18n.t('templates:add.drag.saveContinue')}
                                    iconRight={<UILIB.Icons icon="arrowRight" />}
                                    className="button-primary"
                                />
                            </div>
                        }

                    </div>
                </div>

                {
                    this.state.otherSessionDetected && <div className="nav-banner quickFlex paper-orange"
                        style={{ justifyContent: "center", alignItems: "center" }}>
                        <UILIB.Icons icon="warning" className="mar-r10" />
                        <div>
                            Warning: You appear to be editing this template on multiple browsers or devices.  Please close other browsers that are editing this template.
                        </div>
                    </div>
                }

                <div className="eContainer">
                    {(!templateType || templateType != "raw") && <LeftTools showHideGuideLines={this.showHideGuideLines} showGuideLines={this.state.showGuideLines} isForm={this.state.form} components={this.state.components} openMarket={this.openMarket} marketOpen={this.state.marketOpen} componentSelected={this.state.selectedElement.isSelected} layouts={this.state.layouts} blocks={this.state.blocks} userBlocks={this.state.userBlocks} updateBlocks={this.reloadUserBlocks} insertElement={this.insertElement} marketPlaceComponents={this.state.marketPlaceComponents} />}

                    <DragDropStage showGuideLines={this.state.showGuideLines} HTML5Backend={this.HTML5Backend} templateJson={this.state.templateJson} updateElementsData={this.updateElementsData} components={this.state.components} clickElement={this.clickElement} deselectElement={this.deselectElement} selectedElement={this.state.selectedElement} screenSize={this.state.screenSize} saveFaveColor={this.saveFaveColor} updateBlocks={this.reloadUserBlocks} templateId={this.state.templateID} />

                    <RightTools landingPage={this.state.landingPage} isLandingPage={this.state.isLandingPage} campaign={this.state.campaign} templateId={this.state.templateID} isForm={this.state.form} saveTemplate={this.saveTemplate} selectedElement={this.state.selectedElement} templateJson={this.state.templateJson} deselectElement={this.deselectElement} updateElementsData={this.updateElementsData} saveFaveColor={this.saveFaveColor} updateBlocks={this.reloadUserBlocks} marketOpen={this.state.marketOpen} templateType={templateType} />

                    <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />


                    {this.state.doneWizard === false && <div><div className="wizardOverlay"></div>
                        {this.state.wizardStep === 0 && <div style={{ position: "fixed", top: "50%", left: "50%", marginLeft: "-250px", marginTop: "-200px", width: "500px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.wizard.header')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.wizard.subHeader1')}<br /><br />
                                {i18n.t('templates:add.drag.wizard.subHeader2')}
                            </div>

                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.wizardNext} />
                        </div>}


                        {this.state.wizardStep === 1 && <div style={{ right: "340px", top: "50px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.wizard.styleHeader')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.wizard.styleContent')}
                            </div>

                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.wizardNext} />

                            <div className="ddWArrowRight" style={{ right: "-30px", top: "50px" }} />
                        </div>}

                        {this.state.wizardStep === 2 && <div style={{ left: "230px", top: "200px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.wizard.elementHeader')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.wizard.elementContent')}
                            </div>

                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.wizardNext} />

                            <div className="ddWArrowLeft" style={{ left: "-30px", top: "50px" }} />
                        </div>}

                        {this.state.wizardStep === 3 && <div style={{ left: "230px", top: "45px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.wizard.changeTypeHeader')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.wizard.changeTypeContent')}
                            </div>

                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.wizardNext} />

                            <div className="ddWArrowLeft" style={{ left: "-30px", top: "50px" }} />
                        </div>}

                        {this.state.wizardStep === 4 && <div style={{ left: "150px", top: "150px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.wizard.editing')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.wizard.editingContent')}
                            </div>

                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.wizardNext} />

                            <div className="ddWArrowRight" style={{ right: "-30px", top: "50px" }} />
                        </div>}

                        {this.state.wizardStep === 5 && <div style={{ right: "200px", top: "10px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.wizard.previewSaveHeader')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.wizard.previewSaveContent')}
                            </div>

                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.wizardNext} />

                            <div className="ddWArrowRight" style={{ right: "-30px", top: "-5px" }} />
                        </div>}

                        {this.state.wizardStep === 6 && <div style={{ position: "fixed", top: "50%", left: "50%", marginLeft: "-250px", marginTop: "-200px", width: "500px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.wizard.finish')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.wizard.finishContent1')}<br /><br />
                                {i18n.t('templates:add.drag.wizard.finishContent2')}
                            </div>
                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.wizardNext} />
                        </div>}
                    </div>}

                    {this.state.doneFormWizard === false && this.state.type === "form" && <div><div className="wizardOverlay"></div>
                        {this.state.formWizardStep === 0 && <div style={{ position: "fixed", top: "50%", left: "50%", marginLeft: "-250px", marginTop: "-200px", width: "500px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.formWizard.step1.header')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.formWizard.step1.subHeader1')}<br /><br />
                                {i18n.t('templates:add.drag.formWizard.step1.subHeader2')}
                            </div>

                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.formWizardNext} />
                        </div>}


                        {this.state.formWizardStep === 1 && <div style={{ left: "220px", top: "150px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.formWizard.step2.header')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.formWizard.step2.subHeader1')}
                            </div>

                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.formWizardNext} />

                            <div className="ddWArrowLeft" style={{ left: "-30px", top: "50px" }} />
                        </div>}

                        {this.state.formWizardStep === 2 && <div style={{ left: "200px", top: "200px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.formWizard.step3.header')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.formWizard.step3.subHeader1')}
                            </div>

                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.formWizardNext} />

                            <div className="ddWArrowRight" style={{ right: "-30px", top: "50px" }} />
                        </div>}

                        {this.state.formWizardStep === 3 && <div style={{ right: "350px", top: "50px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.formWizard.step4.header')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.formWizard.step4.subHeader1')}
                            </div>

                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.formWizardNext} />

                            <div className="ddWArrowRight" style={{ right: "-30px", top: "50px" }} />
                        </div>}

                        {this.state.formWizardStep === 4 && <div style={{ right: "200px", top: "0px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.formWizard.step5.header')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.formWizard.step5.subHeader1')}
                            </div>

                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.formWizardNext} />

                            <div className="ddWArrowRight" style={{ right: "-30px", top: "0px" }} />
                        </div>}

                        {this.state.formWizardStep === 5 && <div style={{ position: "fixed", top: "50%", left: "50%", marginLeft: "-250px", marginTop: "-200px", width: "500px" }} className="dragDropWizardPop">
                            <div className="title">{i18n.t('templates:add.drag.formWizard.step6.header')}</div>
                            <div className="text">
                                {i18n.t('templates:add.drag.formWizard.step6.subHeader1')}<br /><br />
                                {i18n.t('templates:add.drag.formWizard.step6.subHeader2')}
                            </div>
                            <UILIB.Button text={i18n.t('templates:add.drag.wizard.continue')} className="button-sml" onClick={this.formWizardNext} />
                        </div>}
                    </div>}

                    {!this.state.CampaignId && !this.state.type && <CampaignChecker campaigns={this.state.otherCampaigns} close={() => this.setState({ otherCampaigns: null })} />}

                </div >

            </div >
        }
        else {
            return <div style={{ width: "100%", height: "100%", position: "fixed" }}>

                {(this.state.loadingText != '') && <div style={{ margin: "0 auto", marginTop: "20px", fontSize: "12px", textAlign: "center", display: "block", position: "relative", top: "50%", transform: "translateY(-50%)" }}>
                    <div style={{ width: "200px", margin: "auto", marginBottom: "10px" }}>
                        <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.loadingPct} val2={100} />
                    </div>
                    {this.state.loadingText}
                </div>}
            </div>
        }


    };
}


function FormCapsulePopup({ form, close = () => { } }) {
    const [owner, setOwner] = useState(form?.options.capsuleOwner || '')
    const [owners, setOwners] = useState(null)
    const [teams, setTeams] = useState(null)

    const save = async () => {
        await axios.put(`/group/${form.GroupId}/form/${form.id}`, {
            wizard: {
                ...form.wizard, capsule: true
            },
            options: {
                ...form.options, capsuleOwner: owner
            }
        })
        form.wizard.capsule = true
        form.options.capsuleOwner = owner
        close()
    }

    const selectOwner = e => {
        setOwner(e.target.value)
    }

    useEffect(() => {
        axios.get('/application/capsule/users?skipOwning=true').then(res => setOwners(res.data.map(d => {
            d.value = 'owner-' + d.value
            return d
        })))
        axios.get('/application/capsule/teams').then(res => setTeams(res.data.map(d => {
            d.value = 'team-' + d.value
            return d
        })))
    }, [])

    const capsuleOwners = [{ label: 'Unassigned', value: 'none' }, ...(owners || []).map(o => {
        o.group = 'Owner'
        return o
    }), ...(teams || []).map(t => {
        t.group = 'Team'
        return t
    })]

    return <div>
        <div className="mar-b15">
            You have bound this form to your Capsule group. Any form signups will be added to Capsule, or updated if they already exist.
        </div>
        <div className="mar-b30">
            <strong>Important:</strong> For new contacts to be added successfully, make sure to add “required” <strong>First Name</strong> and <strong>Last Name</strong> fields.
        </div>
        {!!owners && !!teams && <>
            <hr></hr>
            <div className="mar-b15">
                New contacts automatically get added to Capsule with the owner set to the user who added the integration. You can change this below to a specific user/team or no-one.
            </div>
            <UILIB.Select
                outerClassName="mar-b25"
                label="Capsule Assigned User or Team"
                placeholder="Integration Owner"
                data={capsuleOwners}
                value={owner}
                onChange={selectOwner}
                explainer="Signups will be added to Capsule. Select which user/team to assign them to"
            />
            <div className="quickFlex" style={{ justifyContent: 'center', alignItems: 'center' }}>
                <UILIB.Button className="button-md button-primary min-width" onClick={save}>Save & Close</UILIB.Button>
            </div>
        </>}
        {!owners || !teams && <UILIB.LoadingIcons />}
    </div>
}
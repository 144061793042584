import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UILIB from "~/Common-Objects/Lib";
import { useHistory } from "react-router-dom";
import axios from "~/data/http/axios";
import i18n from "~/i18n";

import SmsInterest from "./smsInterest";

export default function FeedbackPopups({ }) {

    const account = useSelector((state) => state.accountMaster);
    const site = useSelector((state) => state.siteMaster);
    const [currentFeedbackType, setCurrentFeedbackType] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        if (!account || !account.accountMaster || !account.accountMaster.options) return;
        if (!site || !site.siteId || site.siteId != 1) return; //Only transpond
        if (!account?.accountMaster?.options?.feedback?.smsInterest) {
            setCurrentFeedbackType("smsInterest");
        }
    }, []);

    const submitFeedback = async (isInterested) => {
        try {
            await axios.post("/accountMaster/feedback", { isInterested, type: currentFeedbackType });
            setCurrentFeedbackType(undefined);
        } catch (err) {

            setError(i18n.t('company:feedbackPopups.feedbackPostError'));
            console.error(err);
        }
    }

    return (
        <>
            {currentFeedbackType && <>
                {currentFeedbackType == "smsInterest" && <SmsInterest submitFeedback={submitFeedback} error={error} />}
            </>}
        </>
    );
}

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';


//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class TrustPilotAddBusiness extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            businessData: [],
            appData: {},
            isLoaded: false,
            currDomain: "",
            currDomainErr: ""
        };

        this.getApp = this.getApp.bind(this);
        this.getBusinessData = this.getBusinessData.bind(this);
        this.addDomain = this.addDomain.bind(this);
    }

    componentDidMount() {
        this.getApp();
    }

    getApp() {
        var self = this;
        axios.get("/application/36").then(_theApp => {
            self.setState({ appData: _theApp.data.Application }, self.getBusinessData)
        })
    }

    getBusinessData() {
        var self = this;
        axios.get("/application/account/app/36").then(_theResp => {
            var bizData = [];

            if (_theResp.data.Application.appSettings && _theResp.data.Application.appSettings.businessData) {
                bizData = _theResp.data.Application.appSettings.businessData;
            }

            self.setState({ appData: _theResp.data.Application, businessData: bizData, isLoaded: true })
        })
    }

    addDomain() {

        if (!this.state.currDomain || this.state.currDomain.length < 3 || this.state.currDomain.indexOf(".") < 0) {
            this.setState({ currDomainErr: "Invalid domain name" });
            return;
        }
        axios.post("/application/trustpilot/addbusiness", { domainName: this.state.currDomain }).then(_theResp => {
            this.setState({ currDomain: "", currDomainErr: "" }, this.getBusinessData);
        }).catch(exception => {
            this.setState({ currDomainErr: exception.data.error })
        })
    }

    render() {

        if (!this.state.isLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>

        return <div>
            <UILIB.Row>
                <UILIB.Column xs={12} margin={0}>
                    <UILIB.Paper>
                        <UILIB.Row>
                            <UILIB.Column xs={12}>
                                <h4 className="mar-b25">Trust Pilot is Connected!</h4>
                                <p><strong>Before we can get started, we need to know which of your Businesses you would like to sync from TrustPilot.</strong></p>
                                <p>Please enter the website address of the business or businesses you have added to TrustPilot...</p>
                            </UILIB.Column>
                            <UILIB.Column xs={12} margin={0}>
                                <div className="form-group mar-b0" >
                                    <label>Your website url, as registered on Trust Pilot</label>
                                    <UILIB.Row>
                                        <UILIB.Column xs="12" sm="10" md="11" lg="11">
                                            <UILIB.TextInput placeholder="yourwebsite.com" value={this.state.currDomain} onChange={(event) => { this.setState({ currDomain: event.target.value }) }} error={this.state.currDomainErr} />
                                        </UILIB.Column>
                                        <UILIB.Column xs="12" sm="2" md="1" lg="1" className="text-right" margin={0}>
                                            <UILIB.Button className="button-sml" text="Add" onClick={this.addDomain} />
                                        </UILIB.Column>
                                    </UILIB.Row>

                                </div>

                            </UILIB.Column>
                            {this.state.businessData && this.state.businessData.length > 0 && <UILIB.Column xs={12} margin={0}>
                                {this.state.businessData.map((bu, id) => {
                                    return <UILIB.SquareChip value={bu.name} className="square-chip-secondary" />
                                })}
                            </UILIB.Column>
                            }
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>

                {this.state.businessData && this.state.businessData.length > 0 &&
                    <UILIB.Column xs={12} className="center-xs">
                        <UILIB.Button text="Save and Continue" onClick={() => { this.props.history.push("/cp/company/integrations/apps/trustpilot") }} />
                    </UILIB.Column>
                }
            </UILIB.Row>

        </div >

    };


};



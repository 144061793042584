import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import UILIB from '~/Common-Objects/Lib.jsx';
import axios from '~/data/http/axios';
import DateTimeFunctions from '../../../../Classes/dateTimeFunctions';
import AccountDetailsHolder from './accountDetailsHolder'
import WysiwygEditor from '../../templates/add/dragDrop/rightTools/elementEditor/components/wysiwyg'
import { parseStatus } from './helpers';
import { SupportSocket } from '~/Classes/socketsSupport';

@connect((store) => {
    return { permissionStore: store.permission, siteMaster: store.siteMaster, account: store.accountMaster, accountUser: store.user }
})
class ConversationHolder extends Component {
    constructor(props) {
        super(props);

        this.refreshTimer;
        this.wysiClear;
        this.state = {
            loaded: false,
            conversation: {},
            chatLines: [],
            newChatLine: ""
        }

        this.init = this.init.bind(this);
        this.loadChatLines = this.loadChatLines.bind(this);
        this.submitMessage = this.submitMessage.bind(this);
        this.chatWindowScrollToBottom = this.chatWindowScrollToBottom.bind(this);
        this.sockets = new SupportSocket();
    }

    componentDidMount() {
        this.init()
    }

    componentDidUpdate(prevProps) {

        if (this.props.activeConversationId != prevProps.activeConversationId) {
            this.init()
        }
    }

    async init() {
        let conversation
        sockets.disconnect()
        if (this.props.activeConversationId) {
            conversation = (await axios.get('/inboxes/chat/' + this.props.activeConversationId)).data
        }
        this.setState({
            conversation,
            chatLines: [],
            newChatLine: ""
        }, () => {
            if (conversation) {
                sockets.init(conversation.uuid, conversation.AccountMasterId, conversation.SiteTrackerId, () => {
                    this.props.reload()
                    this.loadChatLines()
                })
                this.loadChatLines();
            }
        });
    }

    componentWillUnmount() {
        sockets.disconnect()
    }

    async loadChatLines() {
        //load the conversation
        const chatLines = await axios.get(`/inboxes/chat/${this.props.activeConversationId}/lines`)
        const hasNewLines = chatLines.data.length > this.state.chatLines.length
        this.setState({
            chatLines: chatLines.data,
            loaded: true
        }, () => {
            if (hasNewLines) {
                this.chatWindowScrollToBottom()
            }
        })
    }

    updateChatStatus(chatId, status) {
        return axios.put(`/chat/${chatId}`, { status }).catch(console.log)
    }

    async submitMessage(close) {
        let chatLine = this.state.newChatLine;
        if (close) await this.updateChatStatus(this.state.conversation.id, 2)
        if (chatLine.length < 1) {
            if (close) this.props.reload()
            return;
        }
        this.setState({ newChatLine: "" });
        let newChatLine = await axios.post(`/inboxes/chat/${this.state.conversation.id}`, {
            text: chatLine
        })

        if (!close) {
            await this.updateChatStatus(this.state.conversation.id, 1)
        }
        this.props.reload()

        let chatLines = this.state.chatLines;
        chatLines.push(newChatLine.data)

        if (this.wysiClear) {
            console.log('CLEAR!')
            this.wysiClear();
        }
        this.setState({ chatLines }, () => {
            this.chatWindowScrollToBottom();
            this.props.scrollTopConversations();
        })

    }

    async chatWindowScrollToBottom() {
        setTimeout(() => {
            let el = document.getElementById("chatHolder");
            if (el) el.scrollTop = el.scrollHeight;
        }, 0)
    }

    updateValue(val1, val2) {
        this.setState({ newChatLine: val2 })
    }

    render() {
        if (!this.state.loaded) return <UILIB.LoadingIcons />
        if (!this.state.conversation) return <UILIB.Column xs={12} sm={8} style={{ display: "flex", flex: "1", height: "100%", justifyContent: "center" }}>
            <div style={{ textAlign: "center" }}>
                <div className="mar-b25" style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src="https://openclipart.org/image/800px/177286" style={{ height: "100%" }} />
                </div>
                <h4 className="mar-b10">It's all quiet here</h4>
                <div>Select a conversation on the left to get chatting!</div>
            </div>
        </UILIB.Column>

        return <>
            <UILIB.Column xs={12} sm={8} md={7} style={{ height: "100%" }}>
                <UILIB.Paper style={{ height: "100%", display: "flex", flexDirection: "column", padding: "0px" }} className="mar-b0">
                    <div className={"support-webChat_main_chatLine-chatHolder-header"}>
                        <div className="quickFlex" style={{ justifyContent: 'space-between' }}>
                            <div>{this.state.conversation.Inbox.name} - {parseStatus(this.state.conversation.status)}</div>
                            <div>Last Message: {DateTimeFunctions.formatDateTime(this.state.chatLines[this.state.chatLines.length - 1]?.createdAt || this.state.conversation.updatedAt)}</div>
                        </div>

                    </div>
                    <div id="chatHolder" style={{ flex: 1, padding: "25px", overflow: "auto", display: "flex", flexDirection: "column" }}>
                        {this.state.chatLines.map((conv, index) => {
                            return <ChatRow key={index} conv={conv} showStatus={index === this.state.chatLines.length - 1} AccountUserId={this.props.accountUser.accountUserId} />
                        })}
                    </div>
                    <div style={{ padding: "20px", paddingTop: "0px" }}>
                        <WysiwygEditor
                            hideMergeTags={true}
                            updateVal={this.updateValue.bind(this)}
                            wysiwygHeight="250px"
                            standalone={true}
                            value={this.state.newChatLine}
                            clearContentAfterUpdate={true}
                            clearContent={clearContent => {
                                this.wysiClear = clearContent
                            }}
                        />

                        <div className="text-right mar-t10">
                            <UILIB.Button text="Send" className="button-primary mar-r10" onClick={() => this.submitMessage(false)} />
                            <UILIB.Button text={this.state.newChatLine ? "Send & Close" : "Close"} onClick={() => this.submitMessage(true)} />
                        </div>
                    </div>

                </UILIB.Paper>
            </UILIB.Column>
            <UILIB.Column xs={0} sm={0} md={2} className="hide-xs hide-sm" style={{ height: '100%' }} >
                <AccountDetailsHolder conversation={this.state.conversation} changeConversation={this.props.selectConversation} />
            </UILIB.Column>
        </>

    }
}

export default ConversationHolder;

function ChatRow({ AccountUserId, conv, showStatus = false }) {
    let owner = false;
    if (AccountUserId === conv.AccountUserId) owner = true;
    let status
    if (conv.type && showStatus) {
        status = <div className='support-webChat_main_chatLine-status' data-tip data-for="chat-row-status">
            {(conv.read || conv.delivered) && <UILIB.Icons icon="tickCircle" color="green" />}
            {!conv.read && !conv.delivered && <UILIB.Icons icon="questionCircle" color="grey" />}
            {conv.read && <UILIB.Icons icon="tickCircle" color="green" className="support-webChat_main_chatLine-status-second" />}
            {!conv.read && conv.delivered && <UILIB.Icons icon="questionCircle" color="grey" className="support-webChat_main_chatLine-status-second" />}
            <ReactTooltip id="chat-row-status" effect="solid">{conv.read ? 'Read' : conv.delivered ? 'Delivered' : 'Pending'}</ReactTooltip>
        </div>
    }
    return <div className={"support-webChat_main_chatLine" + (owner ? " support-webChat_main_chatLine-owner" : "")}>
        <div className={"support-webChat_main_chatLine-header"}>
            {owner && <div className="text-heavy">You</div>}
            {!owner && <div><a onClick={() => window.open(`/cp/subscriber/${conv.SubscriberId}`, '_blank')}>{conv.Subscriber.emailAddress}</a></div>}
            <div>{DateTimeFunctions.formatDateTime(conv.createdAt)}</div>
        </div>
        <div className={"support-webChat_main_chatLine-chatHolder"} dangerouslySetInnerHTML={{ __html: conv.text }}></div>
        {status}
    </div>
}
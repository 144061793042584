import React, { useEffect, useState } from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import MergeTags from '~/pages/cp/includes/mergeTags/mergeTags'
import SmsPhone from '~/pages/cp/includes/sms/phone'
import SmsIPhone from '~/pages/cp/includes/sms/iphone'
import helpers from '~/Classes/helpers'
import numberFormatFunctions from '~/Classes/numberFormatFunctions'
import moment from 'moment'
import AiContentGenerator from '~/pages/cp/includes/ai/contentGenerator'

import { useDispatch } from 'react-redux'

import { alterSiteDrawer } from '~/data/actions/siteActions'

export default function SmsContent({ campaign, updateCampaign = () => { }, saveCampaign = () => { }, changeStep = () => { } }) {
    const [otherCampaigns, setOtherCampaigns] = useState([])
    const [template, setTemplate] = useState(null)
    const [error, setError] = useState(null)
    const [saving, setSaving] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        axios.get('/campaign?type=6&sortOrder=desc&hideStats=true').then(res => {
            setOtherCampaigns(
                Array.from(
                    new Set(
                        res.data.Campaigns.map(c => c.subject).filter(c => helpers.smsIsValidSubject(c).valid)
                    )
                )
            )
        })
    }, [])

    const createFromScratch = async () => {
        // create a blank template and bind it to the campaign
        const result = await axios.post('/template', {
            CampaignId: campaign.id,
            public: true,
            templateHTML: 'to opt out visit {{__OPTOUT}}',
            templateName: 'Sms Template',
            templateType: 6
        })
        campaign.TemplateId = result.data.Template.templateID
        axios.get('/template/' + campaign.TemplateId).then(res => setTemplate(res.data.template))
        await saveCampaign()
    }

    const setSubject = (e) => {
        campaign.subject = e.currentTarget.value
        updateCampaign()
    }

    const toggleTracking = () => {
        const options = campaign.options || {}
        options.doNotTrackLinks = !options.doNotTrackLinks
        campaign.options = options
        updateCampaign()

    }

    const save = async (step = 3) => {
        setError('')
        setSaving(true)
        const messages = []
        if (template.templateHTML.indexOf('{{__OPTOUT}}') === -1) {
            messages.push('Content must contain {{__OPTOUT}} so people can unsubscribe from your messages')
        }

        const subjectChecker = helpers.smsIsValidSubject(campaign.subject)
        if (subjectChecker.messages.length) {
            messages.push(subjectChecker.messages)
        }

        const [subjectResponse, contentResponse] = await Promise.all([
            axios.get('/campaign/checksms?content=' + campaign.subject),
            axios.get('/campaign/checksms?content=' + template.templateHTML)
        ])

        if (subjectResponse.data.length) {
            messages.push(`From Name cannot contain ${subjectResponse.data.map(d => `"${d}"`).join(' or ')}. `)
        }
        if (contentResponse.data.length) {
            messages.push(`Content cannot contain ${contentResponse.data.map(d => `"${d}"`).join(', ')}`)
        }
        if (messages.length) {
            setSaving(false)
            return setError(messages.join('<br />'))
        }
        await axios.put('/template/' + template.id, template)
        setSaving(false)
        changeStep(step, true)

    }

    const openAi = () => {
        const content = <AiContentGenerator contentType="sms" insertText={text => {
            text = text.replace(/<br\/>/gi, '\n')
            if (text.indexOf('{{__OPTOUT}}') === -1) text += ' To opt out visit {{__OPTOUT}}'
            setTemplate({ ...template, templateHTML: text })
        }} close={() => dispatch(alterSiteDrawer(false, false, "right", null, true, 700, 'drawer_dark'))} />
        dispatch(alterSiteDrawer(true, false, "right", content, true, 700, 'drawer_dark'))
    }

    useEffect(() => {
        if (campaign.TemplateId) {
            axios.get('/template/' + campaign.TemplateId).then(res => setTemplate(res.data.template))
        } else {
            createFromScratch()
        }
    }, [campaign.TemplateId])



    if (!template) return <UILIB.LoadingIcons iconType="2" />

    const result = helpers.getSmsCredits(campaign, template)
    const timestamp = moment(campaign.startDate).format('DD MMM, HH:mm')

    const tracking = !campaign.options || !campaign.options.doNotTrackLinks

    const subjectChecker = helpers.smsIsValidSubject(campaign.subject)

    const overLength = result.html.length > 160

    return <UILIB.Row>
        <UILIB.Column xs={12}>
            <UILIB.Row>

                <UILIB.Column xs={12} md={6} lg={6} margin={0} className="mar-b30">

                    {/* content editor */}
                    <UILIB.Paper style={{ padding: '24px' }}>
                        <h4 className="mar-b20">Content
                            {Boolean(result.links.length) && <div className="text-sml text-heavy" style={{ paddingTop: 10, color: '#666666' }}>
                                {tracking ? `Links will be replaced with 17 characters to track them ` : `Links are not being tracked `}
                                {tracking ? <a onClick={toggleTracking}>Stop Tracking</a> : <a onClick={toggleTracking}>Start Tracking</a>}
                            </div>}
                        </h4>

                        {/* subject/from name */}
                        <div className='mar-b20'>
                            <h5 className=" text-sml no-marg mar-b5">From Name</h5>
                            <div style={{ color: '#666666' }} className={"mar-b10 " + (!subjectChecker.valid ? 'text-red' : '')}>Must be between 3 and 11 characters long and have no spaces.</div>
                            <UILIB.TextInput value={campaign.subject} onChange={setSubject} error={!subjectChecker.valid && ' '} suggestions={otherCampaigns} />
                        </div>

                        <h5 className=" text-sml no-marg mar-b5">Text</h5>

                        <UILIB.TextArea value={template.templateHTML} maxlength={160} aiClick={openAi} ai={true} onChange={e => setTemplate({ ...template, templateHTML: e.target.value })} rows={error ? 7 : 7} error={error} />
                        <div className="smsInfo text-xsml">
                            <div className="">{numberFormatFunctions.formatNumber(result.html.length)} / 160</div>
                            <div className=" text-right">{result.messages > 1 ? `${result.messages.length} credits per SMS` : '1 credit per SMS'}</div>
                        </div>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} md={6} lg={6} margin={0} className="mar-b30">
                    {/* content view */}
                    <div className="quickFlex" style={{ justifyContent: 'center', backgroundColor: '#F8F9FB', borderRadius: 8, padding: 40 }}>
                        {/* <SmsPhone from={campaign.subject} content={result.html} timestamp={timestamp} /> */}
                        <SmsIPhone from={campaign.subject} content={result.html} timestamp={timestamp} />
                    </div>
                </UILIB.Column>

                <UILIB.StickyNavBottom>
                    <UILIB.Column xs={12}>
                        <div className="flexSpaceBetween">
                            <UILIB.Button text="Back" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={() => save(1)} />
                            <UILIB.Button text="Continue" disabled={saving} iconRight={<UILIB.Icons icon="arrowRight" />} className="button-primary " onClick={() => save()} />
                        </div>
                    </UILIB.Column>
                </UILIB.StickyNavBottom>
            </UILIB.Row>
        </UILIB.Column>
    </UILIB.Row >
}
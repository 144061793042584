import React from 'react'

export default function IconsLinkedinColor({ color = "", style = {} }) {
    let fillColor = "#0A66C2";
    if (color) fillColor = color;
    let className = "icons-icon";

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} className={className}>
            <g clipPath="url(#clip0_29_19868)">
                <path className="animate-svg" d="M17.0409 17.0411H14.0776V12.4003C14.0776 11.2936 14.0578 9.869 12.5363 9.869C10.9929 9.869 10.7567 11.0748 10.7567 12.3197V17.0408H7.79337V7.49733H10.6382V8.80155H10.678C11.2581 7.80971 12.3367 7.21732 13.4849 7.25994C16.4884 7.25994 17.0422 9.23557 17.0422 11.8057L17.0409 17.0411ZM4.44969 6.1928C3.49994 6.19297 2.72987 5.42316 2.7297 4.47338C2.72953 3.52361 3.49932 2.75353 4.44907 2.75336C5.39883 2.75318 6.1689 3.52299 6.16907 4.47277C6.16915 4.92887 5.98805 5.36632 5.6656 5.68888C5.34316 6.01145 4.90578 6.19272 4.44969 6.1928ZM5.93138 17.0411H2.96492V7.49733H5.93138V17.0411ZM18.5183 0.00136924H1.47582C0.670359 -0.00772061 0.00983024 0.637487 0 1.44296V18.5565C0.00949368 19.3624 0.669969 20.0082 1.47582 19.9998H18.5183C19.3258 20.0098 19.9888 19.364 20 18.5565V1.44172C19.9885 0.634661 19.3254 -0.0105097 18.5183 4.6389e-06" fill={fillColor} />
            </g>
            <defs>
                <clipPath id="clip0_29_19868">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
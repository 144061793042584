import React from 'react'
import axios from '~/data/http/axios';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import ViewTemplate from './viewTemplate.jsx'
import CampaignOverview from './overview/overview'
import CampaignOverviewSms from './sms'
import CampaignSent from './sent/sent'
import CampaignOpened from './opened/opened'
import CampaignClicked from './clicked/index'
import CampaignClickedView from './clicked/view/clickedView'
import CampaignUnsubscribed from './unsubscribed/unsubscribed'
import CampaignBounced from './bounced/bounced';
import CampaignUsers from './users/users';
import CampaignAnalytics from './analytics/analytics'
import CampaignTracking from './tracking/tracking'
import CampaignReplies from './replies'
import CampaignPollResults from './pollResults/index';
import EventsView from '../shared/events';

import ABSplit from './ab'
import moment from 'moment';
import PermissionChecker from '~/Classes/permissions';
import PermissionRouter from '~/Common-Objects/Router/permissionRoute'
import Resends from './resends';
import UILIB from '~/Common-Objects/Lib.jsx';
import CampaignExports from './exports/exports.jsx';
@connect((store) => {
  return { site: store.siteMaster, permissionStore: store.permission }
})
export default class ViewCampaign extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      campaignId: this.props.match.params.campaignId,
      campaign: {},
      pageLoaded: false,
      startDate: null,
      endDate: null,
      changedDate: false,
      isAutomation: false,
      hasTrackers: false
    };
    this.loadCampaign = this.loadCampaign.bind(this);
    this.updateDate = this.updateDate.bind(this);
  }



  componentWillUnmount() {
    this._mounted = false
  }
  componentDidMount() {
    this._mounted = true;
    this.loadCampaign();

    axios.get('/accountMaster/tracker').then(res => {
      this.setState({ hasTrackers: !!res.data.Trackers?.length })
    })
  }

  loadCampaign() {
    if (!this._mounted) return
    var self = this;
    axios.get('/campaign/' + this.props.match.params.campaignId)
      .then((res) => {
        var isAutomation = false;
        if (res.data.Campaign.Automation && res.data.Campaign.Automation.name) {
          isAutomation = true;
        }
        var startDate = (res.data.Campaign.startDate ? moment(res.data.Campaign.startDate) : moment()).startOf('day').subtract(1, 'day');
        var endDate = moment().add(1, "day");
        if (res.data.Campaign.lastActionDate) {
          let finishedDate = moment(res.data.Campaign.lastActionDate)
          if (finishedDate < endDate) {
            endDate = finishedDate.add(1, 'week')
          }
        }

        self.setState({ campaign: res.data.Campaign, startDate: startDate, endDate: endDate, pageLoaded: true, isAutomation: isAutomation });

        if (this._mounted) {
          var campaign = res.data.Campaign
          if (campaign.type === 10 && !campaign.parentCampaignId) {
            setTimeout(this.loadCampaign, 10000)
          }
        }
      })
      .catch(ex => {
        self.props.history.push('/cp/campaigns/');
      })
  }

  updateDate(dates) {
    this.setState({ startDate: dates.startDate, endDate: dates.endDate, changedDate: true })
  }

  render() {
    if (this.state.pageLoaded) {
      var canExport = PermissionChecker("reporting", this.props.permissionStore.permissions, "write");
      var canViewContacts = PermissionChecker("subscribers", this.props.permissionStore.permissions, "read");
      const isSms = this.state.campaign.type === 6
      return <ViewTemplate history={this.props.history} campaign={this.state.campaign} campaignId={this.props.match.params.campaignId} updateDate={this.updateDate} isAutomation={this.state.isAutomation} startDate={this.state.startDate} endDate={this.state.endDate} canExport={canExport}>
        <Switch>
          <Route exact path="/cp/campaigns/view/:campaignId" render={(props) => (
            isSms ?
              <CampaignOverviewSms {...props} campaign={this.state.campaign} isAutomation={this.state.isAutomation} /> :
              <CampaignOverview {...props} canExport={canExport} campaign={this.state.campaign} startDate={this.state.startDate} endDate={this.state.endDate} updateDate={this.updateDate} history={this.props.history} reload={this.loadCampaign} isAutomation={this.state.isAutomation} canViewContacts={canViewContacts} />
          )} history={this.props.history} isAutomation={this.state.isAutomation} />

          <Route exact path="/cp/campaigns/view/:campaignId/tests" render={(props) => <ABSplit {...props} canExport={canExport} campaign={this.state.campaign} history={this.props.history} isAutomation={this.state.isAutomation} />} history={this.props.history} isAutomation={this.state.isAutomation} />
          <Route exact path="/cp/campaigns/view/:campaignId/clicked" render={(props) => (<CampaignClicked {...props} canExport={canExport} campaign={this.state.campaign} startDate={this.state.startDate} endDate={this.state.endDate} updateDate={this.updateDate} isAutomation={this.state.isAutomation} />)} changedDate={this.state.changedDate} isAutomation={this.state.isAutomation} history={this.props.history} />
          <Route exact path="/cp/campaigns/view/:campaignId/users" component={CampaignUsers} history={this.props.history} canExport={canExport} />
          <Route exact path="/cp/campaigns/view/:campaignId/analytics" render={(props) => (<CampaignAnalytics {...props} canExport={canExport} startDate={this.state.startDate} endDate={this.state.endDate} changedDate={this.state.changedDate} updateDate={this.updateDate} isAutomation={this.state.isAutomation} />)} history={this.props.history} isAutomation={this.state.isAutomation} />
          <PermissionRouter
            permissionName="subscribers"
            method="read"
            exact path="/cp/campaigns/view/:campaignId/sent"
            component={(props) => (<CampaignSent {...props} canExport={canExport} startDate={this.state.startDate} endDate={this.state.endDate} changedDate={this.state.changedDate} updateDate={this.updateDate} isAutomation={this.state.isAutomation} />)}
          />
          <PermissionRouter
            permissionName="subscribers"
            method="read"
            exact path="/cp/campaigns/view/:campaignId/opened"
            component={(props) => (<CampaignOpened {...props} canExport={canExport} startDate={this.state.startDate} endDate={this.state.endDate} changedDate={this.state.changedDate} updateDate={this.updateDate} isAutomation={this.state.isAutomation} />)}
          />
          <PermissionRouter
            permissionName="subscribers"
            method="read"
            exact path="/cp/campaigns/view/:campaignId/clicked/:linkID"
            component={(props) => (<CampaignClickedView {...props} canExport={canExport} startDate={this.state.startDate} endDate={this.state.endDate} changedDate={this.state.changedDate} updateDate={this.updateDate} isAutomation={this.state.isAutomation} />)}
          />
          <PermissionRouter
            permissionName="subscribers"
            method="read"
            exact path="/cp/campaigns/view/:campaignId/bounced"
            component={(props) => (<CampaignBounced {...props} canExport={canExport} startDate={this.state.startDate} endDate={this.state.endDate} changedDate={this.state.changedDate} updateDate={this.updateDate} isAutomation={this.state.isAutomation} />)}
          />
          <PermissionRouter
            permissionName="subscribers"
            method="read"
            exact path="/cp/campaigns/view/:campaignId/unsubscribed"
            component={(props) => (<CampaignUnsubscribed {...props} canExport={canExport} startDate={this.state.startDate} endDate={this.state.endDate} changedDate={this.state.changedDate} updateDate={this.updateDate} isAutomation={this.state.isAutomation} />)}
          />
          <PermissionRouter
            permissionName="subscribers"
            method="read"
            exact path="/cp/campaigns/view/:campaignId/tracking"
            component={(props) => (<CampaignTracking {...props} canExport={canExport} startDate={this.state.startDate} endDate={this.state.endDate} changedDate={this.state.changedDate} updateDate={this.updateDate} isAutomation={this.state.isAutomation} />)}
          />
          <PermissionRouter
            permissionName="subscribers"
            method="read"
            exact path="/cp/campaigns/view/:campaignId/replies"
            component={(props) => (<CampaignReplies {...props} canExport={canExport} startDate={this.state.startDate} endDate={this.state.endDate} changedDate={this.state.changedDate} updateDate={this.updateDate} isAutomation={this.state.isAutomation} />)}
          />
          <PermissionRouter
            permissionName="subscribers"
            method="read"
            exact path="/cp/campaigns/view/:campaignId/resends"
            component={(props) => (<Resends {...props} canExport={canExport} campaign={this.state.campaign} startDate={this.state.startDate} endDate={this.state.endDate} changedDate={this.state.changedDate} updateDate={this.updateDate} isAutomation={this.state.isAutomation} />)}
          />
          <PermissionRouter
            permissionName="subscribers"
            method="read"
            exact path="/cp/campaigns/view/:campaignId/events"
            component={(props) => (<EventsView {...props} hasSiteTracking={this.state.hasTrackers} canExport={canExport} campaignId={this.state.campaign.id} startDate={this.state.startDate} endDate={this.state.endDate} changedDate={this.state.changedDate} updateDate={this.updateDate} isAutomation={this.state.isAutomation} />)}
          />
          <PermissionRouter
            permissionName="subscribers"
            method="read"
            exact path="/cp/campaigns/view/:campaignId/exports"
            component={(props) => (<CampaignExports {...props} campaign={this.state.campaign} />)}
          />
          {this.state.campaign.polls?.length > 0 && (
            <PermissionRouter
              permissionName="subscribers"
              method="read"
              exact path="/cp/campaigns/view/:campaignId/polls"
            >
              <CampaignPollResults campaign={this.state.campaign} />
            </PermissionRouter>
          )}
        </Switch>
      </ViewTemplate>
    }
    else {
      return <UILIB.LoadingIcons iconType="2" />
    }

  }
};


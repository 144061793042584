import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "~/data/http/axios";
import { handleGenericError } from "~/data/actions/siteActions";
import { agentRoles } from "~/Classes/conversations";
import UILIB from "~/Common-Objects/Lib";
import Dialog from "../../../../components/dialog";
import Button from "../../../../components/button";
import TextInput from "../../../../components/textInput";
import * as styles from "./index.module.scss";

export default function AddEditAgentDialog({
  agentToEdit,
  agents,
  isOpen,
  onAddSubmit,
  onEditSubmit,
  hasChat = false,
  ...rest
}) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined); // Has to be undefined otherwise the Radix placeholder won't show
  const [selectedRole, setSelectedRole] = useState(agentRoles[0].value);
  const [maxChats, setMaxChats] = useState(agentToEdit?.maxChats || 0);
  const [submitting, setSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  useEffect(() => {
    // We only want to load data if the modal is actually opened
    if (isOpen) loadInitialData();
  }, [isOpen]);

  async function loadInitialData() {
    try {
      if (agentToEdit) {
        setSelectedRole(agentToEdit.role);
      } else {
        await loadUsers();
      }
    } finally {
      setLoading(false);
    }
  }

  async function loadUsers() {
    try {
      const { data } = await axios.get("/accountMaster/users");
      setUsers(data.AccountUsers);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function handleSubmit() {
    setSubmitting(true);

    try {
      await validateForm();

      if (agentToEdit) {
        await onEditSubmit(agentToEdit.id, selectedRole, maxChats);
      } else {
        await onAddSubmit(selectedUser, selectedRole, maxChats);
      }
    } finally {
      setSubmitting(false);
    }
  }

  async function validateForm() {
    try {
      if (!agentToEdit && !selectedUser) {
        throw "Please select a User";
      }

      setFormErrors(null);
    } catch (error) {
      setFormErrors({ user: error });
      throw error;
    }
  }

  const role = agentRoles.find((role) => role.value === selectedRole);

  return (
    <Dialog
      fullScreen
      open={isOpen}
      title={`${agentToEdit ? `Edit` : `Add`} Agent`}
      description={
        agentToEdit
          ? "Edit this agents user role."
          : "Add a Transpond user to Inbox and set what their user role is."
      }
      submitting={submitting}
      {...rest}
    >
      {loading ? (
        <UILIB.LoadingIcons noClass />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          {!agentToEdit && (
            <UILIB.SelectNew.Root
              className="mar-b24"
              value={selectedUser}
              error={formErrors?.user}
              onValueChange={(val) => setSelectedUser(val)}
            >
              <UILIB.SelectNew.Trigger
                fullWidth
                label="User"
                placeholder="Select User…"
              />

              <UILIB.SelectNew.Content fullWidth>
                <UILIB.SelectNew.Viewport>
                  {users
                    .filter((user) => {
                      return !agents.some((agent) => {
                        return agent.AccountUserId === user.id;
                      });
                    })
                    .map((user) => {
                      return (
                        <UILIB.SelectNew.Item
                          key={user.id}
                          value={user.id.toString()}
                        >
                          <UILIB.AvatarNew
                            src={user.profilePicFileName}
                            size={20}
                            name={user.firstName}
                          />
                          {user.firstName} {user.lastName}
                        </UILIB.SelectNew.Item>
                      );
                    })}
                </UILIB.SelectNew.Viewport>
              </UILIB.SelectNew.Content>
            </UILIB.SelectNew.Root>
          )}

          <h3 className="text-sml mar-b12 fw-medium">User role</h3>

          {agentRoles.map((role) => {
            const { label, value, subheading } = role;

            return (
              <UILIB.RadioTile
                key={value}
                name="role"
                value={value}
                checked={selectedRole === value}
                className="mar-b12"
                onChange={(e) => setSelectedRole(Number(e.target.value))}
              >
                <div>
                  <h4 className={styles.radioHeading}>{label}</h4>
                  <p className={styles.radioSubheading}>{subheading}</p>
                </div>
              </UILIB.RadioTile>
            );
          })}

          {/* {role?.maxChats && hasChat && <div>
            <h3 className="text-sml mar-b12 fw-medium">Max number of active chats</h3>
            <TextInput value={maxChats} type="number" onChange={e => setMaxChats(Number(e.target.value))} />
          </div>} */}

          <div className={styles.footer}>
            <Button type="submit" size="s" loading={submitting}>
              {`${agentToEdit ? `Save` : `Add`} Agent`}
            </Button>

            <UILIB.Hint
              className="mar-l0 mar-r0"
              iconLeft={<UILIB.Icons icon="questionCircle" />}
              target="_blank"
              href="https://kb.transpond.io/article/107-customizing-your-account#staff-and-roles"
            >
              Learn more about user roles
            </UILIB.Hint>
          </div>
        </form>
      )}
    </Dialog>
  );
}

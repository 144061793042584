import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import IncDisconnect from './incDisconnect';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})


export default class CapsuleConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: true,
            appData: {},
            appBinding: {}
        };

        this.getApp = this.getApp.bind(this);
        this.getAppBinding = this.getAppBinding.bind(this);
        this.popDisconnect = this.popDisconnect.bind(this);
        this.goDisconnect = this.goDisconnect.bind(this);
        this.createWebHooks = this.createWebHooks.bind(this);
    }

    componentDidMount() {
        this.getApp();
    }


    getApp() {
        var self = this;
        axios.get("/application/7").then(_theApp => {
            self.setState({ appData: _theApp.data.Application }, self.getAppBinding)
        })
    }

    getAppBinding() {
        var self = this;
        axios.get("/application/account/app/" + this.state.appData.id).then(_theResp => {
            var groupId = -1;
            if (_theResp.data.Application.appSettings && _theResp.data.Application.appSettings.groupId) groupId = _theResp.data.Application.appSettings.groupId;

            self.setState({ appBinding: _theResp.data.Application, groupId: groupId }, self.createWebHooks)
        })
    }

    createWebHooks() {
        if (this.state.appBinding.appSettings.createdWebhooks) {
            console.log("hook");
            return;
        }

        var self = this;
        axios.get('/application/goldmine/setupIntegration').then(() => {
            this.setState({ loading: false })
        }).catch(err => {
            self.setState({ error: "There was an error connecting Goldmine", loading: false })
        })
    }

    popDisconnect() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <IncDisconnect goDisconnect={this.goDisconnect} />, true, "500px"));
    }

    goDisconnect() {
        var self = this;
        axios.delete('/accountMaster/application/' + this.state.appBinding.id).then(res => {
            self.props.history.push('/cp/company/integrations')
        })
    }


    render() {



        return <div>
            <UILIB.Row className="middle-xs mar-b10">
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="col-md-offset-2">
                    <div className="quickFlex">
                        <div className="integration-avatar rounded" style={{ backgroundImage: "url(" + this.state.appData.appLogoUri + ")" }}></div>
                        <p className="no-marg pad-left">GoldMine CRM. Connected</p>
                    </div>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="end-xs">
                    <p className="no-marg">
                        <UILIB.Button className="button-sml grey" onClick={this.popDisconnect}>
                            Disconnect
                        </UILIB.Button>
                    </p>
                </UILIB.Column>
            </UILIB.Row>
            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={8} lg={8} className="col-md-offset-2">
                    <UILIB.Paper className="height-100 mar-b0">
                        <h4 className="mar-b25">You are Connected!</h4>
                        <div className="mar-b25">Good News! Your Goldmine account is now connected and you are ready to start importing contacts, sending campaigns and much much more.</div>
                        <div className="mar-b25">
                            <h4 className="mar-b25">Adding Contacts</h4>
                            To import your Goldmine Contacts, simply click the CONTACTS tab, add a GROUP and then click the ADD CONTACTS option and choose Goldmine.
                        </div>
                        <div className="mar-b25">
                            <h4 className="mar-b25">Sending Campaigns</h4>
                            Now you are connected, you can also do some awesome stuff when it comes to sending a campaign. When you Create a New Campaign, simply select a Group that has been imported from Goldmine,
                            and new options will appear for sending Campaign data back to your CRM.
                        </div>
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
        </div>
    };


};



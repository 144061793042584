import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

class UndoImport extends Component {
    render() {
        return (<div>

            <h4 className="mar-b25">{i18n.t('subscribers:add.import.undo.header')}</h4>

            <div className="text-heavy mar-b15">{i18n.t('subscribers:add.import.undo.text1')}</div>

            <div className="mar-b15 text-red">{i18n.t('subscribers:add.import.undo.text2')}</div>

            <div className="mar-b15">{i18n.t('subscribers:add.import.undo.text3')}</div>

            <div className="quickFlex">
                <UILIB.Button onClick={this.props.confirm} className="mar-r10 button-red" text={i18n.t('yes')} iconRight={<UILIB.Icons icon="tick" />} />
                <UILIB.Button onClick={this.props.cancel} className="grey button-md outline full-width" text={i18n.t('no')} />
            </div>
        </div>
        );
    }
}

export default UndoImport;
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import HelpPopup from './helpPopup/helpPopup';
class AddStepHolder extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let className = "automation-addStepHolder quickFlex " + this.props.className
        if (this.props.disabled) className += " automation-addStepHolder-disabled"
        return <UILIB.Paper onClick={this.props.onClick} className={className} style={this.props.style} alt={this.props.alt} title={this.props.alt}>
            <div className="automation-addStepHolder-icon">{this.props.icon}</div>
            <div className="automation-addStepHolder-details">
                <div className="automation-addStepHolder-title mar-b5">{this.props.title}</div>
                <div className="automation-addStepHolder-subTitle">{this.props.subTitle}</div>
            </div>
            {!!this.props.helpType && <HelpPopup type={this.props.helpType} />}
        </UILIB.Paper>
    }
}

export default AddStepHolder;
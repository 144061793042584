import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

class CloneSegmentDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {

        return (
            <div>
                <h4 className="mar-b25">{i18n.t('campaigns:standard.step1.cloneSegment.title')}</h4>
                <div className="mar-b25">{i18n.t('campaigns:standard.step1.cloneSegment.subTitle')}</div>
                <div className="mar-b25" dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step1.cloneSegment.subSubTitle') }}></div>

                <UILIB.Button className="button-primary" text={i18n.t('campaigns:standard.step1.cloneSegment.editThisSegment')} onClick={this.props.goClone} />
            </div>
        );
    }
}

export default CloneSegmentDrawer;
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import axios from '../../../../data/http/axios'
import UILIB from '../../../../Common-Objects/Lib'
import i18n from '../../../../i18n'
import PermissionChecker from '../../../../Classes/permissions';
import FormatNumberFuncs from '../../../../Classes/numberFormatFunctions'
import ArrowDown from '../../../../assets/images/icons/nav-arrow-down.svg';
import * as siteActions from '../../../../data/actions/siteActions'
import RemoveOrg from './remove'
import CreateOrg from './create'

export default function Organisations() {
    const [data, setData] = useState(null)
    const [total, setTotal] = useState(null)
    const [page, setPage] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [limit, setLimit] = useState(15)
    const [tags, setTags] = useState([])
    const history = useHistory()
    const permissionStore = useSelector(state => state.permission)
    const searchTimer = useRef()
    const dispatch = useDispatch()

    const getOrgs = async () => {
        let query = '?limit=' + limit + '&offset=' + (page * limit)
        if (searchText) query += '&searchText=' + searchText
        if (tags.some(t => t.selected)) {
            query += '&tags=' + (tags.filter(t => t.selected).map(t => t.id).join('&tags='))
        }
        const res = await axios.get('/organisation' + query)
        setData(res.data.Organisations)
        setTotal(res.data.total)
    }

    const deleteOrg = (org) => {
        const drawer = <RemoveOrg organisation={org} onFinish={r => {
            if (r) getOrgs()
            dispatch(siteActions.alterSiteDrawer(false))
        }} />
        dispatch(siteActions.alterSiteDrawer(true, true, 'right', drawer, true, 400))
    }

    const create = () => {
        const drawer = <CreateOrg onCancel={() => {
            dispatch(siteActions.alterSiteDrawer(false))
        }} />
        dispatch(siteActions.alterSiteDrawer(true, true, 'right', drawer, true, 400))

    }

    const setTagFilter = (tag) => {
        const _tags = [...tags]
        const index = _tags.findIndex(t => t.id === tag.id)
        console.log(_tags, index)
        if (_tags[index].selected) {
            _tags[index].selected = false
        } else {
            _tags[index].selected = true
        }
        setTags(_tags)
    }

    useEffect(() => {
        if (searchTimer.current) clearTimeout(searchTimer.current)
        searchTimer.current = setTimeout(getOrgs, 400)
    }, [searchText])

    useEffect(() => {
        getOrgs()
    }, [page, limit, tags])

    useEffect(() => {
        axios.get('/tags?tagType=0').then(res => {
            setTags(res.data)
        })
        return function () {
            if (searchTimer.current) clearTimeout(searchTimer.current)
        }
    }, [])

    if (!data) return <UILIB.LoadingIcons iconType="2" />

    const writeAccess = PermissionChecker("subscribers", permissionStore.permissions, "write")

    const table = data.map((row) => {
        return {
            name: {
                headerValue: 'Name',
                value: <Link to={'/cp/subscribers/organisations/' + row.id} style={{ textDecoration: 'none' }}>{row.name}</Link>
            },
            emailAddress: {
                headerValue: 'Email',
                value: row.emailAddress
            },
            subscribers: {
                headerValue: 'Contacts',
                value: <UILIB.SquareChip className="square-chip-large square-chip-purple square-chip-min-width">{FormatNumberFuncs.formatNumber(row.Subscribers)}</UILIB.SquareChip>,
                align: 'center',
                width: 150
            },
            tags: {
                headerValue: 'Tags',
                value: <UILIB.Tags outerClassName="inline" tagsSelected={row.Tags} disableEdit={true} />,
            }
        }
    })

    let tagsFilterText = i18n.t('subscribers:overview.selectTags');
    if (tags.some(t => t.selected)) {
        tagsFilterText = tags.filter(t => t.selected).map(t => t.tagName).join(', ')
    }

    return <div>
        <UILIB.Row>
            <UILIB.Column lg={6} md={6} sm={6} xs={12} className="mar-b25">
                <UILIB.TextInput
                    placeholder={"Search for Organisation"}
                    iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                    value={searchText}
                    onChange={e => setSearchText(e.currentTarget.value)}
                />
            </UILIB.Column>
            <UILIB.Column lg={6} md={6} sm={6} xs={6} hide={["xs"]} style={{ textAlign: "right" }} className="mar-b25">
                {writeAccess === true &&
                    <UILIB.Button
                        className="button-primary"
                        iconLeft={<UILIB.Icons icon="plus" />}
                        text={"Create Organisation"}
                        onClick={create}
                    />
                }
            </UILIB.Column>
            <UILIB.Column xs={12} sm={6}>
                {!!tags.length && <div className="quickFlex">
                    <UILIB.ButtonSelect
                        fixedHeightLarge={true}
                        autoClose
                        headerText={tagsFilterText}
                        filter={false}  >
                        <ul>
                            {tags.map((tag) => {
                                return <li key={tag.id}><a><UILIB.CheckBox name={tag.id} onChange={() => setTagFilter(tag)} checked={tag.selected}>{tag.tagName}{tag.Application ? ` (${tag.Application.appName})` : ''}</UILIB.CheckBox></a></li>
                            })}
                        </ul>
                    </UILIB.ButtonSelect>
                </div>}
            </UILIB.Column>
            <UILIB.Column xs={12} sm={6} hide={["xs", "sm"]} className="mar-b25" style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                <div>{i18n.t('showing')}</div>
                <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={limit === 15} onClick={() => { setLimit(15) }}>15</UILIB.ButtonSimple>
                <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={limit === 100} onClick={() => { setLimit(100) }}>100</UILIB.ButtonSimple>
                <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={limit === 250} onClick={() => { setLimit(250) }}>250</UILIB.ButtonSimple>
                <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={limit === 500} onClick={() => { setLimit(500) }}>500</UILIB.ButtonSimple>
            </UILIB.Column>
        </UILIB.Row>

        <UILIB.DataTable1
            noResultsTxt={<span> No Organisations Found </span>}
            tableData={table}
            width="100%"
            className="mar-b25"
        />

        <UILIB.Row>
            <UILIB.Column xs={12} hide={["xs", "sm"]} className="center-xs">
                <UILIB.PagingBlock
                    totalRows={total}
                    pageSize={limit}
                    numberOfLinks="10"
                    currentPage={page}
                    changePage={p => setPage(p)}
                    text={i18n.t('page') + ":"} />
            </UILIB.Column>
        </UILIB.Row>


    </div >
}
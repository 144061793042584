import React, { useReducer, useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { wizardStepsReducer, initialWizardStepsState } from "~/Classes/wizard";
import EmailValidation from "~/Classes/emailValidator";
import { alterSnackbar, handleGenericError } from "~/data/actions/siteActions";
import copy from "copy-to-clipboard";
import queryString from 'query-string';
import { alterSiteDrawer } from "../../../../../data/actions/siteActions";
import AuthenticateDomain from "../../../company/domainsNew/components/authenticateDomain";
import i18n from "~/i18n";

export default function ConnectEmailChannelPage({ userRole = null }) {
  const { inboxId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [inbox, setInbox] = useState(null)
  const [channel, setChannel] = useState(null);
  const [email, setEmail] = useState("");
  const [emailName, setEmailName] = useState("");
  const [saving, setSaving] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [domain, setDomain] = useState(null);

  const [domainVerified, setDomainVerified] = useState(false);
  const [domainVerifiedError, setDomainVerifiedError] = useState(false);

  const [domainAuthenticated, setDomainAuthenticated] = useState(false);
  const [domainAuthenticatedError, setDomainAuthenticatedError] = useState(false);

  const [steps, updateSteps] = useReducer(
    wizardStepsReducer,
    initialWizardStepsState(5)
  );

  useEffect(() => {
    if (userRole > 0) return window.open("/", "_self");
    const query = queryString.parse(location.search)
    if (query.ChannelId) {
      axios.get(`/inboxes/${inboxId}/channel/${query.ChannelId}`).then(res => {
        setChannel(res.data)
      })
    } else {
      setChannel({})
    }
  }, [])

  useEffect(() => {
    axios.get(`/inboxes/${inboxId}`).then(res => {
      setInbox(res.data)
    })
  }, [inboxId])

  useEffect(() => {
    if (channel && inbox && !channel.settings?.replyName) {
      setEmailName(inbox.name)
    }
  }, [channel, inbox])

  useEffect(() => {
    if (channel?.settings?.replyAddress) {
      setEmail(channel.settings.replyAddress)
      setEmailName(channel.settings.replyName)
      updateSteps({ type: "complete", index: 0 })
      validateStep2()
    }
  }, [channel])

  useEffect(() => {
    if (domain && domain.verified && domain.authenticated) {
      updateSteps({ type: "complete", index: 1 })
    }
  }, [domain])

  useEffect(() => {
    if (domainVerified) {
      validateStep3()
    }
  }, [domainVerified])

  function editEmail(e) {
    if (e?.preventDefault) e.preventDefault()
    updateSteps({ type: "collapse", index: 0 })
    if (domain) {
      setDomain(null)
      updateSteps({ type: "reset", index: 1 })
      updateSteps({ type: "reset", index: 2 })
      updateSteps({ type: "reset", index: 3 })
    }
  }

  async function handleStep1Submit(e) {
    if (e?.preventDefault) e.preventDefault()
    await validateStep1();
    const newChannel = await connectChannel();
    setChannel(newChannel)
  }
  async function handleStep2Submit(e) {
    e.preventDefault();
    //updateSteps({ type: "complete", index: 1 })
  }
  async function handleFinalStepSubmit(e) {
    e.preventDefault();
    setSaving(true);

    try {
      await axios.put(`/inboxes/${inboxId}/channel/${channel.id}`, {
        status: 'running'
      })
      history.goBack();
    } catch (error) {
      setSaving(false);
    }
  }
  async function validateStep1() {
    try {
      let error
      if (!email) {
        error = i18n.t("chat:channel.connect.email.step1.errRequired")
      } else if (!EmailValidation.validateEmail(email)) {
        error = i18n.t("chat:channel.connect.email.step1.errInvalid")
      }
      if (error) {
        setFormErrors({ email: error });
        throw new Error(error);
      } else {
        setFormErrors(null);
      }
    } catch (error) {
      throw error;
    }
  }


  const validateStep2 = async () => {
    setDomainVerified(false);
    setDomainVerifiedError(false);
    const res = await axios.get('/accountMaster/domain?domain=' + channel.settings?.replyAddress.split('@')[1])
    let domainGood = false;
    if (res.data.domains.length > 0) {
      if (res.data.domains[0].verified) {
        domainGood = true;
      }
    }
    if (domainGood) {
      setDomainVerified(true);
      updateSteps({ type: "complete", index: 1 })
    }
    else {
      setDomainVerified(domainGood);
      setDomainVerifiedError(true);
    }
  }

  const validateStep3 = async () => {
    dispatch(alterSiteDrawer(false, true, 'right', undefined, true, 460))
    setDomainAuthenticated(false);
    setDomainAuthenticatedError(false);
    const res = await axios.get('/accountMaster/domain?domain=' + channel.settings?.replyAddress.split('@')[1])
    let domainGood = false;
    if (res.data.domains.length > 0) {
      if (res.data.domains[0].authenticated) {
        domainGood = true;
      }
    }
    if (domainGood) {
      setDomainAuthenticated(true);
      updateSteps({ type: "complete", index: 2 })
    }
    else {
      setDomainAuthenticated(false);
      setDomainAuthenticatedError(true);
    }
  }


  function handleStep4Submit(e) {
    e.preventDefault();
    updateSteps({ type: "complete", index: 3 })
  }

  async function connectChannel() {
    try {
      let url = `/inboxes/${inboxId}/channel`
      let method = 'post'
      if (channel.id) {
        url += `/${channel.id}`
        method = 'put'
      }
      await axios.post('/accountMaster/domain', { emailAddress: email, strict: true })
      const { data } = await axios[method](url, {
        name: email,
        type: 1,
        status: 'draft',
        replyAddress: email.toLowerCase(),
        settings: { ...channel.settings, replyAddress: email.toLowerCase(), replyName: emailName }
      });
      return data;
    } catch (error) {
      if (error?.data?.error || error?.message) {
        setFormErrors({ email: error?.data?.error || error?.message });
      }
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  const authenticateDrawer = () => {
    const drawer = <AuthenticateDomain domain={channel.settings?.replyAddress.split('@')[1]} onAuthenticate={validateStep3} />
    dispatch(alterSiteDrawer(true, true, 'right', drawer, true, 460))
  }

  function copyToClipboard(string) {
    copy(string);
    dispatch(alterSnackbar(true, "Copied to clipboard"));
  }

  const forwardToEmail = `${channel?.AccountMasterId}_${channel?.id}@mailin.mpzemail.com`;

  if (!channel || !inbox) return <UILIB.LoadingIcons />

  return (
    <div className="wizard-page connect-email-channel-page">
      <a onClick={() => history.goBack()} className="wizard-page__close">
        <UILIB.Icons icon="cross" color="black" />
      </a>

      <div className="wizard-page__inner">
        <div className="center-xs mar-b60">
          <h1 className="h2 mar-b10">{channel.id ? i18n.t("chat:channel.connect.email.headerComplete") : i18n.t("chat:channel.connect.email.headerCreate")}</h1>
          <p className="text-md text-grey">
            {i18n.t("chat:channel.connect.email.subheader")}
          </p>
        </div>

        <ol className="faux-list">
          <li>
            <UILIB.WizardStep heading={i18n.t("chat:channel.connect.email.step1.header")} onClick={() => {
              updateSteps({ type: "collapse", index: 0 })
              updateSteps({ type: "reset", index: 1 })
              updateSteps({ type: "reset", index: 2 })
              updateSteps({ type: "reset", index: 3 })
              updateSteps({ type: "reset", index: 4 })
            }}
              {...steps[0]}>
              <p className="text-grey">{i18n.t("chat:channel.connect.email.step1.subheader")}</p>
              <div className="row">
                <div className="col-xs">
                  <UILIB.TextInput type="email" label={i18n.t("chat:channel.connect.email.step1.email")} name="email" value={email} onEnterPress={handleStep1Submit} placeholder="E.g. help@yourcompany.com" onChange={(e) => setEmail(e.target.value)} error={formErrors?.email} />
                </div>
                <div className="col-xs">
                  <UILIB.TextInput name="emailName" label={i18n.t("chat:channel.connect.email.step1.reply")} value={emailName} onEnterPress={handleStep1Submit} placeholder="Support" onChange={(e) => setEmailName(e.target.value)} />
                </div>
              </div>
              <UILIB.Button className="button-primary mar-t18" onClick={handleStep1Submit}>{i18n.t("chat:channel.connect.email.step1.next")}</UILIB.Button>
            </UILIB.WizardStep>
          </li>

          <li>
            <UILIB.WizardStep heading={i18n.t("chat:channel.connect.email.step2.header")} {...steps[1]}>
              <p className="text-grey" dangerouslySetInnerHTML={{ __html: i18n.t("chat:channel.connect.email.step2.subheader", { email: email }) }}></p>
              <div className="quickFlex">
                {domainVerified && <UILIB.Button className="button-primary mar-t18" onClick={handleStep2Submit}>{i18n.t("chat:channel.connect.email.step2.next")}</UILIB.Button>}
                {!domainVerified && <UILIB.Button className="button-primary mar-r10" onClick={validateStep2}>{i18n.t("chat:channel.connect.email.step2.check")}</UILIB.Button>}
                {domainVerifiedError && <UILIB.FormError>{i18n.t("chat:channel.connect.email.step2.notVerified")}</UILIB.FormError>}
              </div>
            </UILIB.WizardStep>
          </li>

          <li>
            <UILIB.WizardStep heading={i18n.t("chat:channel.connect.email.step3.header")}  {...steps[2]}>
              <p className="text-grey" dangerouslySetInnerHTML={{ __html: i18n.t("chat:channel.connect.email.step3.subheader") }}></p>
              <div className="quickFlex">
                {domainAuthenticated && <UILIB.Button className="button-primary mar-t18" onClick={validateStep3}>{i18n.t("chat:channel.connect.email.step3.next")}</UILIB.Button>}
                {!domainAuthenticated && <UILIB.Button className="button-primary mar-r10" onClick={authenticateDrawer}>{i18n.t("chat:channel.connect.email.step3.addDns")}</UILIB.Button>}
                {domainAuthenticatedError && <UILIB.FormError>{i18n.t("chat:channel.connect.email.step3.notAuthed")}</UILIB.FormError>}
              </div>
            </UILIB.WizardStep>
          </li>

          <li>
            <UILIB.WizardStep heading={i18n.t("chat:channel.connect.email.step4.header")}  {...steps[3]}>
              <p className="text-grey" dangerouslySetInnerHTML={{ __html: i18n.t("chat:channel.connect.email.step4.subheader", { email: email }) }} ></p>
              <UILIB.TextInput value={forwardToEmail} readOnly iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => copyToClipboard(forwardToEmail)} />} />
              <UILIB.Button className="button-primary mar-t18" onClick={handleStep4Submit} >{i18n.t("chat:channel.connect.email.step4.next")}</UILIB.Button>
            </UILIB.WizardStep>
          </li>

          <li>
            <UILIB.WizardStep heading={i18n.t("chat:channel.connect.email.step5.header")}  {...steps[4]}>
              <p className="text-grey" dangerouslySetInnerHTML={{ __html: i18n.t("chat:channel.connect.email.step5.subheader", { replyAddress: channel.settings?.replyAddress }) }}></p>
              <ConfirmForward channel={channel} onFinish={handleFinalStepSubmit} />
            </UILIB.WizardStep>
          </li>

        </ol>
      </div>
    </div >
  );
}


function ConfirmForward({ channel, onFinish = () => { } }) {
  const [finding, setFinding] = useState(false)
  const [found, setFound] = useState(false)
  const [testCounter, setTestCounter] = useState(0)
  const [tries, setTries] = useState(0)
  const [error, setError] = useState('')
  const findTestTimer = useRef()

  useEffect(() => {
    return function () {
      if (findTestTimer.current) clearTimeout(findTestTimer.current)
    }
  }, [])

  useEffect(() => {
    if (finding) {
      findTest()
    }
  }, [finding])

  useEffect(() => {
    if (testCounter > 0) {
      findTestTimer.current = setTimeout(findTest, 3000)
    }
  }, [testCounter])

  const findTest = async () => {
    const res = await axios.get(`/inboxes/${channel.InboxId}/channel/${channel.id}/test`)
    if (res.data.success) {
      setFound(true)
      setFinding(false)
      clearTimeout(findTestTimer.current)
      setTestCounter(0);
    }
    else {
      let testCounterClone = testCounter + 1;
      if (testCounterClone > 10) {
        clearTimeout(findTestTimer.current)
        setFinding(false)
        setTestCounter(0);
      }
      else {
        setTestCounter(testCounterClone)
      }
    }
  }

  const sendTestEmail = async () => {
    try {
      await axios.post(`/inboxes/${channel.InboxId}/channel/${channel.id}/test`)
      setTries(tries + 1);
      setFinding(true)
    } catch (error) {
      setError(error?.data?.error || error?.message || 'Something went wrong')
    }
  }

  const forwardToEmail = `${channel?.AccountMasterId}_${channel?.id}@mailin.mpzemail.com`;

  return (
    <div>
      {!finding && <>
        {!found && <>
          {tries > 0 && <UILIB.FormError className="mar-b15">{i18n.t("chat:channel.connect.email.step5.notReceived", { forward: forwardToEmail })}</UILIB.FormError>}
          {!!error && <UILIB.FormError className="mar-b15">{error}</UILIB.FormError>}
          <UILIB.Button className="button-primary" iconLeft={<UILIB.Icons icon={'envelope'} />} onClick={sendTestEmail}>{tries > 0 ? i18n.t("chat:channel.connect.email.step5.resend") : i18n.t("chat:channel.connect.email.step5.send")}</UILIB.Button>
        </>}
        {found && <UILIB.Button className="button-primary" onClick={onFinish}>{i18n.t("chat:channel.connect.email.step5.complete")}</UILIB.Button>}
      </>}
      {finding && <>
        <div className="text-heavy">{i18n.t("chat:channel.connect.email.step5.finding")}</div>
        <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={testCounter} val2={10} />
      </>}

    </div>
  )
}
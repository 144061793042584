import React from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

//CONNECT TO STORE

@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster }
})

export default class SpamTester extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templateId: 0,
            campaignId: 0,
            loaded: false,
            error: false,
            testResults: {}
        }

        this.doSpamTest = this.doSpamTest.bind(this);
    }

    componentDidMount() {
        if (this.props.templateId) {
            this.setState({ templateId: this.props.templateId, error: false }, this.doSpamTest)
        }
        else {
            this.setState({ campaignId: this.props.campaignId, error: false }, this.doSpamTest)
        }
    }

    doSpamTest() {

        var url = `/template/spamTest/${this.state.templateId}`
        if (this.state.campaignId != 0) url = `/campaign/spamTest/${this.state.campaignId}`
        var self = this;
        axios
            .get(url)
            .then((res) => {

                self.setState({ testResults: res.data.results, loaded: true })
            }).catch(function (error) {
                self.setState({ loaded: true, error: true })
                console.log(error);
            })

    }

    render() {

        if (!this.state.loaded) {
            return <div style={{ marginTop: '50px' }} className="text-center">
                <h4 className="mar-b25">{i18n.t('spamtester:testing')}</h4>
                <UILIB.LoadingIcons iconType="2" className="mar-b30" />
            </div>
        }

        if (this.state.error || this.state.testResults === 'Error') {
            return <div style={{ marginTop: '50px' }} className="text-center">
                <h4 className="text-red mar-b25">{i18n.t('spamtester:warningHeader')}</h4>
                <div className="text-red">{i18n.t('spamtester:warningContent')}</div>
            </div>
        }

        if (this.state.testResults.score <= 0) {
            return <div style={{ marginTop: '50px' }} className="text-center">
                <h4 className="text-green mar-b25">{i18n.t('spamtester:passHeader')}</h4>
                <div className="text-green">{i18n.t('spamtester:passContent', {
                    type: this.state.templateId ? 'Template' : 'Campaign'
                })}</div>
            </div>
        }

        var scoreClass = "text-yellow";
        var scoreText = i18n.t('spamtester:failLow', {
            type: this.state.templateId ? 'Template' : 'Campaign'
        })
        if (this.state.testResults.score >= 1 && this.state.testResults.score < 2.5) {
            scoreClass = "text-orange";
            scoreText = i18n.t('spamtester:failMedium', {
                type: this.state.templateId ? 'Template' : 'Campaign'
            })
        }
        if (this.state.testResults.score >= 2.5) {
            scoreClass = "text-red";
            scoreText = i18n.t('spamtester:failHigh', {
                type: this.state.templateId ? 'Template' : 'Campaign'
            })
        }


        return <div style={{ marginTop: '50px' }} className="text-center">
            <p className="mar-b0">{i18n.t('spamtester:score')}</p>
            <h4 style={{ fontSize: "60px" }} className={scoreClass}>{this.state.testResults.score}</h4>

            <h4 className>{i18n.t('spamtester:failed')}</h4>
            <div className="mar-b20">{scoreText}</div>

            <h4 className="mar-b20">{i18n.t('spamtester:failedReason')}</h4>


            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={8} lg={8} className="col-md-offset-2 col-lg-offset-2">

                    {
                        this.state.testResults.rules.map(rule => {
                            var levelClass = "square-chip-green";
                            if (rule.score < 1) levelClass = "square-chip-yellow";
                            if (rule.score >= 1 && rule.score < 2.5) levelClass = "square-chip-orange";
                            if (rule.score >= 2.5) levelClass = "square-chip-red";
                            return <UILIB.Row>
                                <UILIB.Column xs={12} sm={2} md={2} lg={2} className=" text-left">
                                    <UILIB.SquareChip hasButton={false} value={rule.score} className={levelClass} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={10} md={10} lg={10} className=" text-left">
                                    {rule.friendlyDescription} {rule.urls && !!rule.urls.length && rule.urls.map(url => { return <strong>{url}</strong> })}
                                </UILIB.Column>
                            </UILIB.Row>
                        })
                    }
                </UILIB.Column>
            </UILIB.Row>
        </div>

    };
}

import React, { Component } from 'react';
import axios from '../../../../data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import EmailValidator from '~/Classes/emailValidator'
import SiteVars from '~/Classes/siteVars';
import i18n from '~/i18n'
import TemplateSelector from '../../campaigns/add/steps/step2/standardCampaignStep2'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import TemplateImageLoader from '../../automation/addNew/templateImageLoader'
import MultiEmailPicker from '../multiEmailPicker/multiEmailPicker'
import DedicatedServerSelector from '~/pages/cp/includes/dedicatedServerSelector/dedicatedServerSelector'
// props -
// campaign (full campaign if updating or default props if creating)
// onCreate (fn which returns created campaign)
// onUpdate (fn which returns updated campaign) 
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})
class CampaignInADrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign: {},
            created: false,
            errors: {},
            showAdvanced: false,
            advancedLoading: true,
            trackingDomains: [],
            cdnDomains: [],
            mailservers: [],
            mailServerGroups: [],
            errored: false,
            tierPermissions: {}
        }

        this.saveCampaign = this.saveCampaign.bind(this)
        this.updateValue = this.updateValue.bind(this)
        this.showHideAdvanced = this.showHideAdvanced.bind(this)
        this.openMultiEmailPicker = this.openMultiEmailPicker.bind(this)
        this.finishedMultiEmailPicker = this.finishedMultiEmailPicker.bind(this)
        this.updateBatching = this.updateBatching.bind(this)
        this.createEditTemplate = this.createEditTemplate.bind(this)
        this.updateFromAddress = this.updateFromAddress.bind(this)
        this.checkFromAddress = this.checkFromAddress.bind(this)
    }

    componentDidMount() {
        if (!this.props.campaign.id) {
            axios.post('/campaign', this.props.campaign).then(response => {
                this.setState({
                    campaign: response.data.Campaign
                }, () => {
                    this.props.onCreate(this.state.campaign)
                    if (this.state.campaign.multiAddressRules?.length) {
                        this.showHideAdvanced()
                    }
                })
            }).catch(err => {
                if (err && err.data && err.data.error && err.data.error.tierName) {
                    this.setState({ tierPermissions: err.data.error, errored: true })
                }
                else {
                    console.log(err);
                }
            })
        } else {
            this.setState({
                campaign: JSON.parse(JSON.stringify(this.props.campaign))
            }, () => {
                if (this.state.campaign.multiAddressRules?.length) {
                    this.showHideAdvanced()
                }
            })
        }
    }

    saveCampaign(finish) {
        return axios.put('/campaign/' + this.state.campaign.id, { campaign: this.state.campaign }).then(() => {
            if (finish) {
                this.props.onUpdate(this.state.campaign)
                this.props.dispatch(siteMaster.alterSiteDrawer(false, false));
            }
        })

    }

    updateValue(ev) {
        var campaign = this.state.campaign
        campaign[ev.currentTarget.name] = ev.currentTarget.value
        this.setState({ campaign })
    }

    updateOptions(ev) {
        var campaign = this.state.campaign
        if (!campaign.options) campaign.options = {}
        campaign.options[ev.currentTarget.name] = ev.currentTarget.value
        this.setState({ campaign })
    }


    showHideAdvanced() {
        var show = this.state.showAdvanced;
        var self = this;
        if (show) { show = false }
        else { show = true }

        if (show) {
            var trackingDomains;
            var cdnDomains;
            var mailservers;
            var mailServerGroups;

            axios.get('/accountMaster/domain').then(res => {
                this.setState({
                    sendingDomains: res.data.domains
                })
            })

            this.setState({ advancedLoading: true, showAdvanced: true })
            return axios.get('/accountMaster/tracking')
                .then((_domains) => {
                    var domains = _domains.data.Domains;
                    domains = domains.filter(d => d.authenticated)
                    trackingDomains = domains;
                    return axios.get('/accountMaster/cdn')
                }).then((_cdns) => {
                    var cdns = _cdns.data.Domains.filter(d => d.authenticated);
                    cdnDomains = cdns;
                }).then(() => {
                    var promises = [];
                    if (self.props.accountMaster.accountMaster.options.allowMailServerSelection) {
                        promises.push(axios.get('/accountMaster/mailservers')
                            .then(response => {
                                mailservers = response.data.MailServers;
                                return axios.get('/mailServer/group')
                            }).then(msG => {

                                mailServerGroups = msG.data.Groups
                            })
                        )
                    }
                    return Promise.all(promises)
                }).then(() => {
                    var selectedCdnDomain = 0;
                    var selectedTrackingDomain = 0;
                    if (this.state.campaign.TrackingDomainId) {
                        selectedTrackingDomain = this.state.campaign.TrackingDomainId;
                    } else if (trackingDomains && trackingDomains.filter(t => t.authenticated).length) {
                        selectedTrackingDomain = trackingDomains.filter(t => t.authenticated)[0];
                        this.state.campaign.TrackingDomainId = selectedTrackingDomain.id;
                    }

                    if (this.state.campaign.CdnDomainId) {
                        selectedCdnDomain = this.state.campaign.CdnDomainId;
                    } else if (cdnDomains && cdnDomains.filter(t => t.authenticated).length) {
                        selectedCdnDomain = cdnDomains.filter(t => t.authenticated)[0];
                        this.state.campaign.CdnDomainId = selectedCdnDomain.id;
                    }

                    if (!this.state.campaign.batchUnit) this.state.campaign.batchUnit = 'minutes'

                    self.setState({
                        advancedLoading: false,
                        trackingDomains,
                        cdnDomains,
                        mailservers,
                        mailServerGroups,
                        selectedTrackingDomain,
                        selectedCdnDomain
                    })
                })
        }
        else {
            this.setState({ showAdvanced: show, advancedLoading: false })
        }

    }

    openMultiEmailPicker() {
        var campaign = {};
        var self = this;
        if (this.props.campaign) campaign = this.props.campaign;
        var origDrawerWidth = "600px";
        if (this.props.siteMaster && this.props.siteMaster.siteDrawer && this.props.siteMaster.siteDrawer.width) origDrawerWidth = this.props.siteMaster.siteDrawer.width; this.props.dispatch(siteMaster.alterSiteDrawerWidth("900px"));
        this.props.dispatch(siteMaster.alterSiteDrawerWidth("900px"));
        var drawerContent = <MultiEmailPicker createdRules={(finalRules, defaultEmail, defaultFromName, defaultReplyAddress, ruleSetName) => {
            self.props.dispatch(siteMaster.alterSiteDrawerWidth(origDrawerWidth));
            self.finishedMultiEmailPicker(finalRules, defaultEmail, defaultFromName, defaultReplyAddress, ruleSetName);
        }} campaign={campaign} trackingDomains={this.state.trackingDomains} cdnDomains={this.state.cdnDomains} sendingDomain={this.state.sendingDomains} />
        this.setState({ drawerContent })
    }

    finishedMultiEmailPicker(finalRules, defaultEmail, defaultFromName, defaultReplyAddress, ruleSetName) {

        var theCampaign = this.state.campaign;
        theCampaign.replyAddress = defaultReplyAddress;
        theCampaign.fromName = defaultFromName;
        theCampaign.fromAddress = defaultEmail;
        theCampaign.multiAddressRules = finalRules;
        if (!theCampaign.options) theCampaign.options = {};
        if (ruleSetName && ruleSetName.length) {
            theCampaign.options.multiAddressRuleName = ruleSetName;
        }

        this.checkFromAddress(defaultEmail)
        this.setState({ campaign: theCampaign, drawerContent: undefined }, () => { this.saveCampaign(false) });
    }

    updateBatching(event) {
        var theCampaign = this.state.campaign;
        theCampaign[event.target.name] = event.target.value;
        this.setState({ campaign: theCampaign }, () => { this.saveCampaign(false) });

    }

    createEditTemplate() {
        this.saveCampaign().then(() => {
            var campaign = this.state.campaign;
            if (!campaign.Template) {
                campaign.Template = { templateType: 0 };
            }

            var params = 'redirect=' + encodeURI(this.props.redirect)
            var drawerContent = <TemplateSelector params={params} campaign={campaign} history={this.props.history} saveCampaign={() => this.saveCampaign()} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, false, "right", drawerContent, true, '100vw', 'full campaign-holder'));
        })
    }

    checkFromAddress(fromAddress) {
        if (!fromAddress || !fromAddress.length || fromAddress.indexOf('@') === -1) return
        const domain = fromAddress.split('@')[1]
        if (!domain) return
        if (this.state.trackingDomains?.length) {
            const found = this.state.trackingDomains.find(d => d.domainName.indexOf(domain) > -1)
            if (found) this.updateValue({ currentTarget: { name: 'TrackingDomainId', value: found.id } })
        }
        if (this.state.cdnDomains?.length) {
            const found = this.state.cdnDomains.find(d => d.domainName.indexOf(domain) > -1)
            if (found) this.updateValue({ currentTarget: { name: 'CdnDomainId', value: found.id } })
        }
    }

    updateFromAddress() {
        EmailValidator.checkEmailAuthed(this.state.campaign.fromAddress).then(res => {
            var errors = this.state.errors
            errors.fromAddress = "";
            if (res.error || !res.domainExists || !res.domainVerified || (this.props.siteMaster.usersMustBeAuthenticated && !res.domainAuthenticated)) {
                errors.fromAddress = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.email.errAuth') }}></span>;
            } else {
                // check tracking and cdn domains and update them
                this.checkFromAddress(this.state.campaign.fromAddress)
            }
            this.setState({
                errors
            })
        })
    }


    render() {
        if (this.state.errored) return <UILIB.TierLimitHolder type="AB Split Tests" err={this.state.tierPermissions.err} allowedNumber={this.state.tierPermissions.allowedNumber} tierName={this.state.tierPermissions.tierName} />
        if (this.state.drawerContent) return this.state.drawerContent;

        var campaign = this.state.campaign
        var isValid = true;
        var isComplete = true;
        if (!campaign.subject || (campaign.subject && campaign.subject.length < 4)) {
            isValid = false
            isComplete = false
        }
        if (!campaign.fromName || (campaign.fromName && campaign.fromName.length < 3)) {
            isValid = false
            isComplete = false
        }
        if (!campaign.fromAddress || (campaign.fromAddress && !EmailValidator.validateEmail(campaign.fromAddress))) {
            isValid = false;
            isComplete = false;
        }
        if (!campaign.TemplateId) isComplete = false
        if (!this.props.hideName && !campaign.campaignName || (campaign.campaignName && campaign.campaignName.length < 3)) isComplete = false
        var campaignTemplateImage;
        if (campaign.TemplateId) {
            campaignTemplateImage = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + campaign.TemplateId + ".jpg"
        }

        const usingMultiSenderRules = !!(campaign.multiAddressRules && campaign.multiAddressRules.length)

        let preHeaderAllowed = true;
        let preheaderInfo = i18n.t('campaigns:standard.step3.preheaderTextInfo')

        if (this.state.campaign?.Template?.templateType !== 0) {
            // not drag and drop so cant reliably inject preheader text
            preHeaderAllowed = false;
            preheaderInfo = i18n.t('campaigns:standard.step3.preheaderTextDisabled')
        }

        return (
            <div>
                <div className="automationStepContentHolder">
                    {!this.props.hideName &&
                        <UILIB.TextInput outerClassName="mar-b25" label={this.props.nameLabel || i18n.t('campaigns:main.campaignName')} name="campaignName" value={this.state.campaign.campaignName} onChange={this.updateValue} error={this.state.errors.campaignName} placeholder="Campaign Name" />
                    }
                    <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('campaigns:standard.subject')} name="subject" value={this.state.campaign.subject} onChange={this.updateValue} error={this.state.errors.subject} placeholder="Email Subject" />
                    {!usingMultiSenderRules && <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('campaigns:standard.fromName')} name="fromName" value={this.state.campaign.fromName} onChange={this.updateValue} error={this.state.errors.fromName} placeholder="From Name" />}
                    {!usingMultiSenderRules && <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('campaigns:standard.fromAddress')} type="email" name="fromAddress" value={this.state.campaign.fromAddress} onChange={this.updateValue} onBlur={this.updateFromAddress} error={this.state.errors.fromAddress} placeholder="name@mydomain.com" />}
                    {!usingMultiSenderRules && <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('campaigns:standard.replyAddress')} type="email" name="replyAddress" value={this.state.campaign.replyAddress} onChange={this.updateValue} error={this.state.errors.replyAddress} placeholder="name@mydomain.com" />}

                    {!!this.state.campaign?.id && <UILIB.TextInput
                        label={i18n.t('campaigns:standard.step3.preheaderText')}
                        id="preheaderText"
                        name="preheaderText"
                        explainer={preheaderInfo}
                        onChange={this.updateOptions.bind(this)}
                        disabled={!preHeaderAllowed}
                        value={this.state.campaign.options?.preheaderText}
                        outerClassName="mar-b25" />}

                    {isValid && <div className={"quickFlex mar-b25" + (campaignTemplateImage ? " paper-class" : "")}>
                        {campaignTemplateImage &&
                            <div className="campaign-thumbnail mar-r20" style={{ width: "100px", height: "80px" }}>
                                <TemplateImageLoader imageUrl={campaignTemplateImage} />
                            </div>
                        }
                        <div style={{ flex: "1" }}>
                            <UILIB.Button iconLeft={<UILIB.Icons icon="pencil" />} className="button-primary" text={i18n.t('automation:add.email.editEmailContent')} onClick={this.createEditTemplate} />
                            {this.state.errors.campaign && <UILIB.WarningHolder className="warning-holder-nopadding text-red mar-t10">{this.state.errors.campaign}</UILIB.WarningHolder>}
                        </div>
                    </div>}

                    {!usingMultiSenderRules && <UILIB.Button
                        className="mar-b25"
                        iconRight={<UILIB.Icons icon={this.state.showAdvanced ? "arrowDown" : "arrowRight"} />}
                        onClick={this.showHideAdvanced}
                        text={i18n.t('campaigns:standard.step1.advanced')}
                    />}

                    {this.state.showAdvanced && <div>
                        {this.state.advancedLoading && <UILIB.LoadingIcons iconType="2" />}
                        {!this.state.advancedLoading &&
                            <div>
                                {/* Sending domains */}
                                {!usingMultiSenderRules && (!!this.state.cdnDomains?.length || !!this.state.trackingDomains?.length) &&
                                    <div>
                                        {!!this.state.trackingDomains?.length &&
                                            <UILIB.Select outerClassName="mar-b25" label={i18n.t('campaigns:standard.step3.tracking')} value={this.state.campaign.TrackingDomainId} name="TrackingDomainId" onChange={this.updateValue} data={this.state.trackingDomains.map(domain => {
                                                return {
                                                    label: domain.domainName,
                                                    value: domain.id
                                                }
                                            })} />
                                        }
                                        {!!this.state.cdnDomains?.length &&
                                            <UILIB.Select outerClassName="mar-b25" label={i18n.t('campaigns:standard.step3.cdn')} value={this.state.campaign.CdnDomainId} name="CdnDomainId" onChange={this.updateValue} data={this.state.cdnDomains.map(domain => {
                                                return {
                                                    label: domain.domainName,
                                                    value: domain.id
                                                }
                                            })} />
                                        }
                                    </div>
                                }

                                <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('campaigns:standard.multiSenderRules')} name="senderRules" value={campaign.multiAddressRules && campaign.multiAddressRules.length ? campaign.multiAddressRules.length + " Rules. Click Here to Edit" : "Click Here to add Rules"} onClick={this.openMultiEmailPicker} readOnly={true} />

                                {!this.props.hideBatching && <div className="quickFlex" style={{ justifyContent: "space-between" }}>
                                    <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('campaigns:main.batchSize')} name="batchSize" type="number" value={campaign.batchSize ? campaign.batchSize : 0} min={0} onChange={this.updateValue} />
                                    <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('campaigns:main.delay')} name="batchDelay" type="number" value={campaign.batchDelay ? campaign.batchDelay : 0} min={0} onChange={this.updateValue} />
                                    <UILIB.Select outerClassName="mar-b25" label={i18n.t('campaigns:main.unit')} name="batchUnit" value={campaign.batchUnit} data={['minutes', 'hours']} min={0} onChange={this.updateValue} />
                                </div>}

                                {this.state.mailservers && this.state.mailservers.length &&
                                    <DedicatedServerSelector mailservers={this.state.mailservers} campaign={campaign} mailserverGroups={this.state.mailServerGroups} updateCampaign={() => this.setState({})} />
                                }
                            </div>}
                    </div>}
                </div>

                <div className="automationStepSaveButtonHolder">
                    <UILIB.Button className="button-primary" text={i18n.t('save')} onClick={() => this.saveCampaign(true)} iconLeft={<UILIB.Icons icon="tick" />} />
                </div>

            </div >
        );
    }
}

export default CampaignInADrawer;
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import UILIB from '../Lib';
import * as siteMaster from '../../data/actions/siteActions'; //now we can use user actions here

export default function Modal({ children, width = "600px", noClickAway = false, paperClass = "", titleStyle = {}, title = "" }) {
    const dispatch = useDispatch()
    const removeModal = () => {
        if (noClickAway) return
        dispatch(siteMaster.alterFullpage(""));
    }

    useEffect(() => {
        document.body.classList.add('remove-overflow')
        return function () {
            document.body.classList.remove('remove-overflow')
        }
    }, [])

    const paperStyle = { width: "100%", maxWidth: width, boxShadow: "-2px 2px 14px -2px #AAAAAA" }
    if (!!title) paperClass += " with_header"

    return (
        <div style={{ position: "absolute", left: 0, top: 0, width: "100vw", height: "100vh", zIndex: 9999998 }}>
            <div style={{
                position: "absolute",
                zIndex: 1,
                width: "100vw",
                height: "100vh",
                backgroundColor: "#EEEEEE", opacity: "0.6"
            }}
                onClick={removeModal}>

            </div>
            <div style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 2
            }}>
                <UILIB.Paper className={paperClass} style={paperStyle}>
                    {!!title && <div className="paper_header" style={titleStyle}>{title}</div>}
                    {!!title && <div className="paper_content">{children}</div>}
                    {!title && children}
                </UILIB.Paper>
            </div>
        </div>
    )
}
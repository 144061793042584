import React, { useState, useEffect } from "react";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";
import axios from "~/data/http/axios";
import i18n from "~/i18n";

export default function FindReplyDialog({ inboxId, currentContent, onSelect = () => { } }) {
    const [searchText, setSearchText] = useState("");
    const [templates, setTemplates] = useState(null)
    const [newTemplateName, setNewTemplateName] = useState("")
    const [saving, setSaving] = useState(false)
    const [newTemplateNameErr, setNewTemplateNameErr] = useState("")

    const saveTemplate = async () => {
        if (newTemplateName.length < 3) {
            setNewTemplateNameErr("Please enter a longer name")
            return;
        }
        setSaving(true)
        try {
            await axios.post('/inboxes/' + inboxId + '/template', { name: newTemplateName, text: currentContent })
        }
        catch (e) {
            setNewTemplateNameErr(e?.data?.error || "Error")
            setSaving(false)
            return;
        }
        setSaving(false)
        onSelect()
    }

    useEffect(() => {
        axios.get('/inboxes/' + inboxId + '/template').then(res => setTemplates(res.data))
    }, [inboxId])

    const filtered = (templates || []).filter(t => {
        if (!searchText) return true;
        return t.name.toLowerCase().includes(searchText.toLowerCase()) || (t.text || "").toLowerCase().includes(searchText.toLowerCase())
    })

    return (
        <UILIB.Dialog.Portal>
            <UILIB.Dialog.Overlay />
            <UILIB.Dialog.Content className={styles.content}>
                <div className="inbox-reply_finder">
                    <div className={styles.search}>
                        <UILIB.TextInput placeholder={i18n.t("chat:inbox.conversation.composer.replySearch")} value={searchText} onChange={e => setSearchText(e.target.value)} />
                    </div>
                    <div className="inbox-reply_finder-replies">
                        {filtered.map(row => {
                            let text = (row.text || "").replace(/<[^>]*>?/gm, '');
                            return <div key={row.id} className="inbox-reply_finder-reply" onClick={() => onSelect(row.text)}>
                                <div className="title">{row.name}</div>
                                <div className="desc">{text}</div>
                            </div>
                        })}
                    </div>
                    {!!currentContent && <div className="inbox-reply_finder-footer">
                        <div className="inbox-reply_finder-footer_title">{i18n.t("chat:inbox.conversation.composer.replySave")}</div>
                        <div className="inbox-reply_finder-footer_form">
                            <UILIB.TextInput onEnterPress={saveTemplate} focus outerClassName="inbox-reply_finder-footer_input" placeholder={i18n.t("chat:inbox.conversation.composer.replySaveName")} value={newTemplateName} onChange={e => setNewTemplateName(e.target.value)} error={newTemplateNameErr} />
                            <UILIB.ButtonNew disabled={saving || newTemplateName.length < 3} onClick={saveTemplate}>{i18n.t("chat:inbox.conversation.composer.replySaveButton")}</UILIB.ButtonNew>
                        </div>
                    </div>}
                </div>
            </UILIB.Dialog.Content>
        </UILIB.Dialog.Portal >
    );
}

import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib.jsx';

class QuickLink extends Component {
    render() {
        return (
            <div className={(this.props.noMarg ? '' : 'mar-b20') + " quickFlex center-xs start-sm width-100"}>
                {!!this.props.icon && <span className={this.props.icon + " text-lg text-primary"}></span>}
                {!!this.props.image && <img src={this.props.image} style={{ borderRadius: '50%' }} height="24" width="24" />}
                <p className="pad-left-1 no-marg m-mar-b10">{this.props.title}</p>
                <div className="mar-l-auto">
                    <UILIB.Button text={this.props.buttonText} className="button-sml min-width-xs" onClick={this.props.onClick} />
                </div>
            </div>
        );
    }
}

export default QuickLink;
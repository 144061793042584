import React from "react";
import * as RadixAlertDialog from "@radix-ui/react-alert-dialog";
import classnames from "classnames";
import * as styles from "./index.module.scss";

const AlertDialog = { ...RadixAlertDialog };

const Overlay = React.forwardRef(({ className, ...rest }, forwardedRef) => {
  const classes = classnames(styles.overlay, className);

  return (
    <RadixAlertDialog.Overlay
      ref={forwardedRef}
      className={classes}
      {...rest}
    />
  );
});

const Content = React.forwardRef(({ className, ...rest }, forwardedRef) => {
  const classes = classnames(styles.content, className);

  return (
    <RadixAlertDialog.Content
      ref={forwardedRef}
      className={classes}
      {...rest}
    />
  );
});

const Title = ({ className, ...rest }) => {
  const classes = classnames("h4 mar-b8", className);

  return <RadixAlertDialog.Content className={classes} {...rest} />;
};

const Description = ({ className, ...rest }) => {
  const classes = classnames(styles.description, className);

  return <RadixAlertDialog.Description className={classes} {...rest} />;
};

AlertDialog.Overlay = Overlay;
AlertDialog.Content = Content;
AlertDialog.Title = Title;
AlertDialog.Description = Description;

export default AlertDialog;

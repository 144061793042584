import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import UILIB from '../../../../../../Common-Objects/Lib'
import axios from '../../../../../../data/http/axios'

export default function CapsuleSyncReport({ importJob, site, goBack = () => { } }) {
    const [type, setType] = useState('duped')
    const [pageSize, setPageSize] = useState(15)
    const [page, setPage] = useState(0)
    const [data, setData] = useState([])
    const [customFields, setCustomFields] = useState([])
    const [exporting, setExporting] = useState(false)
    const history = useHistory()

    const timer = useRef(null)

    const getCustomFields = async () => {
        const res = await axios.get('/customfield')
        setCustomFields(res.data.CustomFields)
        getData()
    }

    const getData = async () => {
        let params = '?limit=' + pageSize + '&skip=' + (page * pageSize);

        switch (type) {
            case 'duped':
                params += '&type=4'
                break;
            case 'invalid':
                params += '&type=0'
                break;
        }

        const res = await axios.get('/group/' + importJob.GroupId + '/import/' + importJob.id + '/failedimport' + params)
        setData(res.data.FailedImports)
    }


    const exportData = async () => {
        setExporting(true)
        const res = await axios.get('/group/' + importJob.GroupId + '/import/' + importJob.id + '/failedimport/export')
        loopExportCheck(res.data.ExportId)
    }

    const loopExportCheck = async (id) => {
        const res = await axios.get('/group/' + importJob.GroupId + '/import/' + importJob.id + '/failedimport/export/' + id)

        if (res.data.ExportJob && res.data.ExportJob.status) {
            if (res.data.ExportJob.status = "Finished" && res.data.ExportJob.filePath) {
                window.open(res.data.ExportJob.filePath, '_self');
                setExporting(false)
            }
            else {
                timer.current = setTimeout(() => loopExportCheck(id), 2000)
            }
        }
    }

    useEffect(() => {
        getData()
    }, [pageSize, type, page])

    useEffect(() => {
        getCustomFields()

        return function () {
            if (timer.current) clearTimeout(timer.current)
        }
    }, [])

    const types = [
        { label: `Duplicates (${importJob.recordsDupes})`, value: 'duped' },
        { label: `Invalid (${importJob.recordsInvalid})`, value: 'invalid' },
    ]

    const nameCf = customFields.find(cf => cf.fieldName === '_capsule_name')

    const table = data.map(row => {
        return {
            emailAddress: {
                value: row.emailAddress || '-',
                headerValue: 'Email address'
            },
            name: {
                headerValue: 'Name',
                value: nameCf ? row.raw[nameCf.id] : ''
            },
            reason: {
                headerValue: 'Reason',
                value: row.reason,
                orderBy: false
            },
            app: {
                align: 'right',
                headerValue: ' ',
                value: <UILIB.Button text="View & Fix" iconRight={<UILIB.Icons icon="arrowRight" />} className="button-sml mar-r10" target="_blank" href={site.url + '/party/' + row.ApplicationDataId} />
            }
        }
    })

    let total = 0
    if (type === 'duped') total = importJob.recordsDupes
    if (type === 'invalid') total = importJob.recordsInvalid

    return (
        <>
            <div style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 25 }}>Syncing Report</div>
            <div style={{ fontSize: 14, marginBottom: 40 }}>Here you can view all the contacts with duplicate or invalid email addresses.
                These contacts will not have been imported into Transpond.
                Amending their details in Capsule will automatically add them to Transpond.</div>

            <div className="flexSpaceBetween mar-b25">
                <UILIB.ButtonSelect headerText={types.find(t => t.value === type).label} data={types.map(t => <a key={t.value} onClick={() => setType(t.value)}>{t.label}</a>)} />
                <div className='quickFlex'>
                    <div style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
                        <div>Show per page: </div>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={pageSize === 15} onClick={() => setPageSize(15)}>15</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={pageSize === 100} onClick={() => setPageSize(100)}>100</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={pageSize === 250} onClick={() => setPageSize(250)}>250</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={pageSize === 500} onClick={() => setPageSize(500)}>500</UILIB.ButtonSimple>
                    </div>
                    <UILIB.Button onClick={exportData} disabled={exporting} iconRight={<UILIB.Icons style={{ height: 20, width: 20 }} icon={exporting ? 'loading' : "download"} />}>Export</UILIB.Button>
                </div>
            </div>

            <UILIB.DataTable1
                width="100%"
                tableData={table}
                className="mar-b25"
            />

            <UILIB.PagingBlock totalRows={total} pageSize={pageSize} numberOfLinks="10" currentPage={page} changePage={setPage} text={"Page:"} />

            <div className='mar-t30'>
                <div className="flexSpaceBetween">
                    <UILIB.Button onClick={goBack} iconLeft={<UILIB.Icons icon="arrowLeft" />}>Back to Sync</UILIB.Button>
                    <UILIB.Button onClick={() => history.push('/cp')} className="button-primary" iconRight={<UILIB.Icons icon="arrowRight" />}>Go to Transpond</UILIB.Button>
                </div>
            </div>
        </>
    )
}
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import UILIB from "~/Common-Objects/Lib";
import { channelTypes } from "~/Classes/conversations";
import AlertDialog from "../../../../components/alertDialog";
import * as styles from "./index.module.scss";
import i18n from "~/i18n";

export default function AddChannelDialog({
  inboxChannels,
  isOpen,
  onSubmit,
  ...rest
}) {
  const isMounted = useRef(true);
  const account = useSelector((state) => state.accountMaster);
  const [channelType, setChannelType] = useState(channelTypes[0].value);
  const [submitting, setSubmitting] = useState(false);
  const [requiresEmailChan, setRequiresEmailChan] = useState(false);

  useEffect(() => {
    const emailChannelsCnt = inboxChannels.filter((c) => c.type === 1 && c.status == "running").length;
    if (emailChannelsCnt < 1) {
      setRequiresEmailChan(true);
    }
    return () => {
      isMounted.current = false;
    };
  });

  async function handleSubmit() {
    setSubmitting(true);

    try {
      await onSubmit(channelType);
    } finally {
      // Sometimes we may want to redirect after the onSubmit() function is called, and so the component won't be mounted.
      // We need this conditional to avoid memory leaks.
      if (isMounted.current) {
        setSubmitting(false);
      }
    }
  }

  return (
    <AlertDialog
      open={isOpen}
      title={i18n.t("chat:settings.channels.add.header")}
      description={i18n.t("chat:settings.channels.add.subheader")}
      ctaText={i18n.t("chat:settings.channels.add.button")}
      ctaVariant="primary"
      submitting={submitting}
      onCtaClick={handleSubmit}
      {...rest}
    >
      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
        {channelTypes.map((channel) => {
          if (channel.beta) {
            if (!account.accountMaster.beta?.[channel.beta]) return null
          }
          const { label, value, subheading, type } = channel;
          const alreadyEnabled = channel.value === 1 && inboxChannels.length > 0 && inboxChannels.filter((c) => c.type === 1 && c.status === 'running').length > 0;
          const checked = channelType === value;
          const chanDisabled = requiresEmailChan && value != 1;
          return (
            <UILIB.RadioTile
              key={value}
              disabled={chanDisabled}
              name="channelType"
              value={value}
              checked={checked}
              className="mar-b12"
              onChange={(e) => setChannelType(Number(e.target.value))}
            >
              <div>
                <h3 className={styles.radioHeading}>{i18n.t(label)}</h3>
                <p className={styles.radioSubheading}>{i18n.t(subheading)}</p>
                {alreadyEnabled && <UILIB.Badge className="mar-t5">{i18n.t("chat:settings.channels.add.only1")}</UILIB.Badge>}
                {chanDisabled && <UILIB.Badge className="mar-t5">{i18n.t("chat:settings.channels.add.requiresEmail")}</UILIB.Badge>}
              </div>

            </UILIB.RadioTile>
          );
        })}
      </form>
    </AlertDialog>
  );
}

import React, { useEffect, useRef, useState } from 'react'
import UILIB from '../../../../../Common-Objects/Lib'
import allowedFonts from '../../../../../Classes/fonts'
import ExampleTemplate1 from './exampleTemplate1';
import ExampleTemplate2 from './exampleTemplate2';
import ExampleTemplate3 from './exampleTemplate3';
import axios from '../../../../../data/http/axios';
import SiteVars from '~/Classes/siteVars';
import i18n from '~/i18n';

export default function ThemeSelector({ job, setJob = () => { }, save = () => { }, next = () => { }, prev = () => { } }) {

    const fileUploadInput = useRef();
    const [logoIndex, setLogoIndex] = useState(-1)
    const [fileError, setFileError] = useState("");
    const [fileUploading, setFileUploading] = useState(false);
    const changeLogo = (dir) => {
        let newIndex = logoIndex + dir;
        if (dir === 1 && newIndex >= job.results.images.length) newIndex = 0;
        if (dir === -1 && newIndex < 0) newIndex = job.results.images.length - 1;
        job.selected.logo = job.results.images[newIndex];
        setLogoIndex(newIndex);
        setJob(job);

    }

    const selectTheme = (colours) => {
        const j = { ...job }
        const selected = { ...j.selected }
        selected.colours = colours
        j.selected = selected
        setJob(j)
        save(j)
        next()
    }

    const uploadFileChanged = async (e) => {
        try {
            setFileError("");
            let files = []
            if (e.preventDefault) {
                e.preventDefault();
                files = e.target.files;
            }
            else {
                files = e;
            }

            if (!files.length) {
                setFileError("Invalid File - Please try again")
                return;
            }
            setFileUploading(true);
            let file = files[0];
            var data = new FormData();
            data.append('file', file);
            data.append('friendlyName', file.name);

            let newFile = await axios.post('/fileManager/' + job.results.fileManagerFolderId + '/file', data, {
                onUploadProgress: progressEvent => uploadFileProgress(progressEvent)
            })
            setFileUploading(false);
            if (!newFile.data || !newFile.data.File) {
                return setFileError("Error uploading - Please try again")
            }

            //see if the logo already exists
            let fileName = newFile.data.File.fileName;
            let fileUrl = SiteVars.repoEndPoint + newFile.data.File.filePath + newFile.data.File.fileName
            let newLogoIndex = job.results.images.findIndex(i => i.url == fileUrl)
            if (newLogoIndex < 0) {
                job.results.images.push({
                    url: fileUrl,
                    name: fileName
                })
                newLogoIndex = job.results.images.length - 1;
                job.selected.logo = job.results.images[newLogoIndex]


            }
            else {
                job.selected.logo = job.results.images[newLogoIndex];
            }
            setLogoIndex(newLogoIndex);
            setJob(job);
        }
        catch (err) {
            console.log(err);
            setFileError("Error uploading - Please try again")
        }

    }

    const uploadFileProgress = async (ev) => {

    }

    const clickUploadButton = () => {
        fileUploadInput.current.click();
    }

    useEffect(() => {

        let foundLogoIndex = job.results.images.findIndex(i => i.url == job.selected.logo.url)
        setLogoIndex(foundLogoIndex);

    }, [])


    let colors = job.results.colors;

    const foundFonts = allowedFonts.filter(f => job.results.fonts.some(t => t.toLowerCase().indexOf(f.label.toLowerCase()) > -1))

    let logo;
    if (job.selected && job.selected.logo) {
        logo = job.selected.logo;
    }
    else {
        logo = { url: "https://cdn1.ourmailsender.com/userContent/6/fileManager/29429/logo-placeholder-black.png", name: "" }
        job.selected.logo = logo
    }



    const colourCombos = []
    if (job.results.headerStyles.length && job.results.buttonStyles.length) {
        colourCombos.push([job.results.headerStyles[0].color, job.results.buttonStyles[0].color])
    }
    let tCnt = 0;
    while (tCnt < colors.length) {
        let cCnt = 0;
        while (cCnt < colors.length) {
            let found = colourCombos.find(c => c[0] == colors[tCnt] && c[1] == colors[cCnt])
            if (!found) {
                colourCombos.push([colors[tCnt], colors[cCnt]])
            }
            cCnt++;
        }
        tCnt++;
    }


    if (!colourCombos.length || !job.selected || !job.selected.font || !logo) {
        return <>
            <h4 className="mar-b25">{i18n.t('templates:brandAnalyzer.themeSelectorPage.siteAnalyzer')}</h4>
            <UILIB.WarningHolder style={{ textAlign: "left" }}>
                <div className="quickFlex mar-b15">
                    <UILIB.Icons icon="alert" color="red" className="mar-r10" />
                    <h3>{i18n.t('templates:brandAnalyzer.themeSelectorPage.couldntFindWebsiteContent')}</h3>
                </div>
                <div className="mar-b15">
                    {i18n.t('templates:brandAnalyzer.themeSelectorPage.couldntFindLogo')}
                </div>
                <UILIB.Button className="primary" text="Try another URL" onClick={prev} />
            </UILIB.WarningHolder>
        </>
    }

    return (
        <UILIB.Row>
            <UILIB.Column xs={12} sm={10} className="col-sm-offset-1 mar-b25 text-heavy text-center">
                {i18n.t('templates:brandAnalyzer.themeSelectorPage.completedAnalyzing')}

            </UILIB.Column>
            <UILIB.Column xs={12} >
                <UILIB.Paper className="mar-b0" >

                    <div className="text-heavy mar-b25">Logo</div>
                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={6} md={5}>
                            <UILIB.Paper style={{
                                display: "flex",
                                alignItems: "center",
                                height: "200px",
                                backgroundImage: "url(https://cdn1.ourmailsender.com/siteContent/brandAnalyzer/transparentBg.png)",
                                backgroundRepeat: "repeat",
                                padding: "32px 10px"
                            }}>
                                <div style={{ flex: 0 }}>
                                    <UILIB.Icons icon="arrowLeft" circle={true} onClick={() => { changeLogo(-1) }} />
                                </div>
                                <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", height: "190px" }} className="mar-l10 mar-r10" >
                                    {(logo && logo.url) && <img src={logo.url} style={{ width: "100%", maxWidth: "190px", maxHeight: "190px" }} />}
                                </div>
                                <div style={{ flex: 0 }}>
                                    <UILIB.Icons icon="arrowRight" circle={true} onClick={() => { changeLogo(1) }} />
                                </div>

                            </UILIB.Paper>
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} md={7}>
                            <div className="mar-b25">
                                <div className="text-heavy mar-b10">
                                    {i18n.t('templates:brandAnalyzer.themeSelectorPage.foundCompanyLogo1')}
                                </div>
                                <div>
                                    {i18n.t('templates:brandAnalyzer.themeSelectorPage.foundCompanyLogo2')}

                                </div>
                                <div>
                                    {i18n.t('templates:brandAnalyzer.themeSelectorPage.foundCompanyLogo3')}

                                </div>
                            </div>

                            <input type="file" ref={fileUploadInput} style={{ display: 'none' }} onChange={uploadFileChanged} />
                            <UILIB.Button
                                className="button-secondary"
                                iconLeft={<UILIB.Icons icon="upload" />}
                                text={i18n.t('templates:brandAnalyzer.themeSelectorPage.uploadDifferentLogo')}
                                onClick={clickUploadButton}
                            />
                            {(fileError && fileError.length) && <UILIB.FormError key={"err"}>{fileError}</UILIB.FormError>}

                        </UILIB.Column>
                    </UILIB.Row>


                    <div className="text-heavy mar-b15">{i18n.t('templates:brandAnalyzer.themeSelectorPage.selectColourTheme')}</div>
                    <div className="mar-b25">
                        {i18n.t('templates:brandAnalyzer.themeSelectorPage.selectColourTheme1')}
                        <br />
                        {i18n.t('templates:brandAnalyzer.themeSelectorPage.selectColourTheme2')}

                    </div>
                    <UILIB.Paper style={{ height: "500px", overflow: "auto" }}>
                        <UILIB.Row>
                            {colourCombos.map((colourArr, cIndex) => {
                                return <UILIB.Column xs={12} key={"example_" + cIndex} className="mar-b25">
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className="mar-b25">
                                        <div className="text-heavy">{i18n.t('templates:brandAnalyzer.themeSelectorPage.theme')} {cIndex + 1}</div>
                                        <UILIB.Button
                                            className="button-primary"
                                            text={i18n.t('templates:brandAnalyzer.themeSelectorPage.selectTheme')}
                                            iconRight={<UILIB.Icons icon="arrowRight" />}
                                            onClick={() => { selectTheme(colourArr) }}
                                        />
                                    </div>
                                    <UILIB.Row>
                                        <UILIB.Column xs={12} sm={6} md={4} className="mar-b25">
                                            <ExampleTemplate1 colours={colourArr} logo={logo} font={job.selected ? job.selected.font : undefined} />
                                        </UILIB.Column>
                                        <UILIB.Column xs={12} sm={6} md={4} className="mar-b25 hide-xs">
                                            <ExampleTemplate2 colours={colourArr} logo={logo} font={job.selected ? job.selected.font : undefined} />
                                        </UILIB.Column>
                                        <UILIB.Column xs={12} sm={6} md={4} className="mar-b25 hide-xs hide-sm">
                                            <ExampleTemplate3 colours={colourArr} logo={logo} font={job.selected ? job.selected.font : undefined} />
                                        </UILIB.Column>
                                    </UILIB.Row>

                                </UILIB.Column>
                            })}
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Paper>
            </UILIB.Column>
        </UILIB.Row >
    )
}
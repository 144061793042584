import React from 'react'
import ReactTooltip from 'react-tooltip'
import Icons from '../Icons'

export default class SquareChip extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        var thisClass = "square-chip ";
        if (this.props.className) {
            thisClass += this.props.className;
        }

        if (this.props.onClick) thisClass += ' clickable'

        let iconLeft = "";
        if (this.props.iconLeft) {
            iconLeft = <div className="square-chip-iconLeft" style={this.props.iconLeftStyle}>{this.props.iconLeft}</div>
        }

        let iconRight = "";
        if (this.props.iconRight) {
            iconRight = <div className="square-chip-iconRight" style={this.props.iconRightStyle}>{this.props.iconRight}</div>
        }

        let floating = "";
        if (this.props.floating) {
            floating = <div style={{ position: 'absolute', top: -5, right: -5 }}>{(Array.isArray(this.props.floating) ? this.props.floating : [this.props.floating]).map(icon => {
                return <Icons icon={icon} className="mar-r5" style={{ height: 10, width: 10 }} />
            })}</div>
        }

        let children
        const style = this.props.style || {}

        if (this.props.leftEdgeContent) {
            style.borderRadius = '0 4px 4px 0'
        }

        if (this.props.tooltip) {
            children = <div className={thisClass} data-tip data-for={'chip' + this.props.for} style={style} onClick={this.props.onClick}>
                {floating}
                <ReactTooltip effect='solid' className={"tooltip " + thisClass} id={'chip' + this.props.for} delayShow={this.props.tooltipDelayShow}>{this.props.tooltip}</ReactTooltip>
                {iconLeft}
                <div className="square-chip-content" style={this.props.innerStyle}>{this.props.value || this.props.children}</div>
                {iconRight}
            </div>
        } else {
            children = <div className={thisClass} style={style} onClick={this.props.onClick}>
                {floating}
                {iconLeft}
                <div className="square-chip-content" style={this.props.innerStyle}>{this.props.value || this.props.children}</div>
                {iconRight}
            </div>
        }

        if (this.props.leftEdgeContent) {
            return <div style={{ display: 'flex' }}>
                {this.props.leftEdgeContent}
                {children}
            </div>
        } else {
            return children
        }


    }
}

import React from "react";

export default function IconReplies({ color = "currentColor", ...rest }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path fillRule="evenodd" clipRule="evenodd" d="M12 5C7.69 5 4 8.033 4 12C4 14.024 4.978 15.825 6.499 17.085C6.48505 17.7065 6.3048 18.3128 5.977 18.841C5.90904 18.9507 5.87054 19.0761 5.86524 19.2051C5.85994 19.3341 5.888 19.4622 5.94673 19.5772C6.00546 19.6921 6.09285 19.7899 6.20046 19.8612C6.30807 19.9325 6.43226 19.9748 6.561 19.984C7.96247 20.089 9.3561 19.6967 10.497 18.876C10.984 18.958 11.487 19 12 19C16.31 19 20 15.967 20 12C20 8.033 16.31 5 12 5Z"
                stroke={color} strokeWidth="1.5" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13ZM10 12C10 12.2652 9.89464 12.5196 9.70711 12.7071C9.51957 12.8946 9.26522 13 9 13C8.73478 13 8.48043 12.8946 8.29289 12.7071C8.10536 12.5196 8 12.2652 8 12C8 11.7348 8.10536 11.4804 8.29289 11.2929C8.48043 11.1054 8.73478 11 9 11C9.26522 11 9.51957 11.1054 9.70711 11.2929C9.89464 11.4804 10 11.7348 10 12ZM15 13C15.2652 13 15.5196 12.8946 15.7071 12.7071C15.8946 12.5196 16 12.2652 16 12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11C14.7348 11 14.4804 11.1054 14.2929 11.2929C14.1054 11.4804 14 11.7348 14 12C14 12.2652 14.1054 12.5196 14.2929 12.7071C14.4804 12.8946 14.7348 13 15 13Z"
                fill={color} />
        </svg>
    )
}

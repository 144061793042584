import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';

export default class A extends Component {
    constructor(props) {
        super(props)

        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        event.preventDefault();

    }

    render() {
        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "a", this.props.localVariables)
        }

        var thisContent;

        if (this.props.elementData.content && this.props.elementData.content.value && this.props.localVariables) {
            thisContent = this.props.elementData.content.value;
            if (thisContent && thisContent.indexOf("localVariables_.")) {
                var localVar = this.props.localVariables[thisContent.replace('_localVariables_.', '')];
                if (localVar) {
                    thisContent = localVar.value;
                }
            }
        }

        if (this.props.localVariables.isHideMe && DragDropFunctions.formData?.options?.serve === 'clickButton') {
            if (!eleProps.className) eleProps.className = "dd-form-overlay"
            if (eleProps.className.indexOf('dd-form-overlay') === -1) eleProps.className += ' dd-form-overlay'
        }

        return <span {...eleProps} onClick={this.onClick}>
            <span dangerouslySetInnerHTML={{ __html: thisContent }} />
            {this.props.children}
        </span>



    }
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';
import moment from 'moment';
import dateTimeFunction from '~/Classes/dateTimeFunctions';
import FailedPaymentConfirmDrawer from './failedPaymentConfirm'
import SiteVars from '~/Classes/siteVars'
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission }
})

export default class Payments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(24, "month"),
            endDate: moment(),
            paymentData: [],
            pageLoaded: false,
            pageSize: 1000,
            currentDrawer: null,
            alertMessage: "",
            alertOpen: false,
            processingPayment: false,
            paymentId: 0,
            stripeLoaded: false
        };

        this.updateDate = this.updateDate.bind(this);
        this.getPayments = this.getPayments.bind(this);
        this.tableUpdater = this.tableUpdater.bind(this);
        this.download = this.download.bind(this);
        this.payInvoice = this.payInvoice.bind(this);
        this.addedCard = this.addedCard.bind(this);
        this.exitPaymentMethod = this.exitPaymentMethod.bind(this);
        this.goPayInvoice = this.goPayInvoice.bind(this);
        this.addCard = this.addCard.bind(this);
    }

    updateDate(dates) {
        this.setState({ startDate: dates.startDate, endDate: dates.endDate }, this.getPayments)
    }

    componentDidMount() {
        this.getPayments();
        const self = this

        async function checkStripe() {
            if (!self.stripe) {
                console.log('getting stripe')
                if (window.Stripe) {
                    self.stripe = Stripe(SiteVars.stripeKey);
                    self.setState({ stripeLoaded: true })
                } else {
                    await new Promise((resolve, reject) => {
                        setTimeout(resolve, 300)
                    })
                    checkStripe()
                }
            }
        }
        checkStripe()
    }


    getPayments() {
        axios.get('/accountMaster/payments?startDate=' + this.state.startDate.startOf('day').format("YYYY-MM-DD HH:mm:ss") + "&endDate=" + this.state.endDate.endOf('day').format("YYYY-MM-DD HH:mm:ss")).then(res => {

            var payments = res.data.payments.map((thisLine, index) => {

                var totalPrice = thisLine.totalPrice ? thisLine.totalPrice : thisLine.price;
                var thisPrice = Number(totalPrice).toFixed(2);
                var thisVAT = Number(thisLine.vatAmount).toFixed(2);

                var status = <UILIB.SquareChip className="square-chip-green">{i18n.t('company:payments.statusPaid')}</UILIB.SquareChip>

                if (!thisLine.hasPaid) {
                    if (thisLine.StripeAuthPayment && thisLine.StripeAuthPayment.id) {
                        status = <UILIB.SquareChip className="square-chip-yellow">Authorization Required</UILIB.SquareChip>
                    } else {
                        status = <UILIB.SquareChip className="square-chip-red">{i18n.t('company:payments.statusNotPaid')}</UILIB.SquareChip>
                    }
                }

                let currencySymbol = "£"
                if (thisLine.currency && thisLine.currency == "USD") currencySymbol = "$"


                var tmpDat = {
                    paymentDate: {
                        headerValue: i18n.t('company:payments.date'),
                        value: dateTimeFunction.formatDateTime(thisLine.paymentDate),
                        orderBy: false
                    },
                    description: {
                        headerValue: i18n.t('company:payments.item'),
                        value: <strong>{thisLine.productName + ' - ' + thisLine.description}</strong>,
                        orderBy: false
                    },
                    price: {
                        headerValue: i18n.t('company:payments.price'),
                        value: <strong>{currencySymbol}{Number(thisLine.price).toFixed(2)}</strong>,
                        orderBy: false
                    },
                    vatAmount: {
                        headerValue: 'VAT',
                        value: currencySymbol + thisVAT,
                        orderBy: false
                    },
                    total: {
                        headerValue: i18n.t('company:payments.total'),
                        value: <strong>{currencySymbol}{thisPrice}</strong>,
                        orderBy: false
                    },
                    status: {
                        headerValue: i18n.t('company:payments.status'),
                        value: status,
                        orderBy: false,
                        width: 100,
                        align: "center"
                    },
                    paymentMethod: {
                        headerValue: i18n.t('company:payments.method'),
                        value: thisLine.paymentMethod == 'stripe' ? 'Card' : thisLine.paymentMethod,
                        orderBy: false
                    },
                    payCol:
                    {
                        headerValue: "",
                        value: "",
                        orderBy: false,
                        width: 0
                    },
                    optionsCol:
                    {
                        headerValue: " ",
                        value: <UILIB.OptionsDropdown popWidth="150px">
                            <ul>
                                <li>
                                    <a onClick={() => { this.download(thisLine.id) }}>{i18n.t('company:payments.invoice')}</a>

                                </li>
                            </ul>
                        </UILIB.OptionsDropdown>
                        ,
                        orderBy: false,
                        fixed: true,
                        width: 20
                    }
                }

                if (!thisLine.hasPaid) {

                    if (thisLine.StripeAuthPayment && thisLine.StripeAuthPayment.id) {

                        tmpDat.payCol.value = <UILIB.Button text="Authorize" className="button-sml outline primary" onClick={() => this.props.history.push('/cp/company/authpayment')} />
                    } else {
                        tmpDat.payCol.value = <UILIB.Button text="Pay Now" className="button-sml outline primary" onClick={() => { this.payInvoice(thisLine.id) }} disabled={this.state.processingPayment} />
                    }
                }
                return tmpDat;

            })


            this.setState({ paymentData: payments, pageLoaded: true })
        })
    }

    tableUpdater() {

    }

    download(id) {
        axios.get('/accountMaster/payments/' + id + '/invoice',
            {
                responseType: 'blob'
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'invoice.pdf');
                document.body.appendChild(link);
                link.click();
            })
    }

    payInvoice(paymentId) {
        this.setState({ paymentId: paymentId,  processingPayment: true }, this.goPayInvoice)
    }

    goPayInvoice() {
        var self = this;

        this.setState({
            alertOpen: true,
            alertMessage: 'Processing Payment',
            currentDrawer: null,
            processingPayment: true
        })
        axios.put('/accountMaster/payment/' + self.state.paymentId).then(response => {
            if (response.data.Success) {
                self.setState({
                    alertOpen: true,
                    alertMessage: 'Payment Processed',
                    processingPayment: false,
                    currentDrawer: null
                }, self.getPayments)
            } else {
                this.props.history.push('/cp/company/authpayment')
            }
        }).catch(err => {

            var drawer = <UILIB.Drawer standalone={true} isOpen={true} width="500px" showClose={true} close={this.exitPaymentMethod} className="right" >
                <FailedPaymentConfirmDrawer reChard={this.goPayInvoice} addCard={this.addCard} err={err.data.error} />
            </UILIB.Drawer >
            self.setState({
                alertOpen: true,
                alertMessage: 'Error Processing Payment',
                processingPayment: false,
                error: err.data.error,
                currentDrawer: drawer
            })
        })
    }

    addCard() {
        var self = this;
        this.setState({
            currentDrawer: null
        }, () => {
            var drawer = <UILIB.Drawer standalone={true} isOpen={true} width="100%" showClose={true} close={self.exitPaymentMethod} className="full" >
                <UILIB.AddCard stripe={self.stripe} close={() => { self.addedCard() }} />
            </UILIB.Drawer>
            self.setState({
                currentDrawer: drawer
            })
        })
    }

    addedCard() {
        this.setState({
            currentDrawer: null
        }, () => {
            this.goPayInvoice();
        })
    }

    exitPaymentMethod() {
        this.setState({ currentDrawer: null })
    }

    render() {

        if (!this.state.pageLoaded || !this.state.stripeLoaded) {
            return <UILIB.LoadingIcons iconType="2" />
        }

        return <div>
            <UILIB.Row className="mar-b10 middle-lg middle-md end-xs">
                <UILIB.Column xl="12" xs="12" sm="12" md="3" lg="3">
                    <div className="form-group form-group-icon mar-b0">
                        <UILIB.TextInput type="multiDate" startDate={this.state.startDate} endDate={this.state.endDate} updateDate={this.updateDate}></UILIB.TextInput>
                    </div>
                </UILIB.Column>
            </UILIB.Row>

            {this.state.paymentData.length === 0 && <div style={{ textAlign: "center" }}>{i18n.t('company:payments.noPaymentsDate')}</div>}
            {this.state.paymentData.length > 0 &&
                <UILIB.Paper>
                    <UILIB.DataTable1
                        noResultsTxt={<div>{i18n.t('company:payments.noPayments')}</div>}
                        tableData={this.state.paymentData}
                        loadingData={this.state.pageLoaded}
                        dataUpdater={this.tableUpdater}
                        width="100%"
                        pageSize={this.state.pageSize}
                        hasCheckBoxes="no"
                    />
                </UILIB.Paper>
            }

            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />

            {(this.state.currentDrawer && this.state.currentDrawer != null) && <div>{this.state.currentDrawer}</div>}

        </div>
    }

};



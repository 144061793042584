import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import i18n from '~/i18n'

export default class CustomFieldPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customFields: [],
            ready: false
        }

        this.pick = this.pick.bind(this);
    }

    componentDidMount() {
        axios.get('/customField').then(response => {
            this.setState({
                ready: true,
                customFields: response.data.CustomFields
            })
        })
    }

    pick(field) {
        this.props.onPick('{{' + field.fieldName + '}}');
    }

    render() {
        if (!this.state.ready) return <UILIB.LoadingIcons iconType="2" />
        return <div>
            <h4 className="mar-b25">{i18n.t('campaigns:standard.step3.insertCustomFields')}</h4>
            <UILIB.Row>
                <UILIB.Column sm={6} hide={["xs"]} className="text-sml" margin={0}
                    style={{ borderBottom: "1px solid #CCCCCC", paddingBottom: "5px" }}>
                    {i18n.t('campaigns:mergeTags.fieldName')}
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="text-sml " margin={0} style={{ borderBottom: "1px solid #CCCCCC", paddingBottom: "5px" }}>{i18n.t('campaigns:mergeTags.mergeTag')}</UILIB.Column>
                {this.state.customFields.map((field, index) => <UILIB.Column xs={12} margin={0} key={index} style={{ borderBottom: "1px solid #EEEEEE" }}>
                    <UILIB.Row className="highlightable" onClick={() => this.pick(field)}>
                        <UILIB.Column sm={6} hide={["xs"]} className="text-sml mar-t5 mar-b5" margin={0}>{field.fieldDesc}</UILIB.Column>
                        <UILIB.Column xs={12} sm={6} className="text-sml mar-t5 mar-b5" margin={0}>{"{{" + field.fieldName + "}}"}</UILIB.Column>
                    </UILIB.Row>
                </UILIB.Column>)}

            </UILIB.Row>
        </div>

    }

}

import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

import AddEvent from './addEvent';
import DeleteEvent from './deleteEvent';

@connect((store) => {
    return { site: store.siteMaster, accountMaster: store.accountMaster }
})
class SiteTrackingEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            events: [],
            tableData: []
        }
        this.getEvents = this.getEvents.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.addedEvent = this.addedEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
    }

    componentDidMount() {
        this.getEvents();
    }

    async getEvents() {
        let events = await axios.get(`/accountMaster/tracker/events`)
        events = events.data;
        let tableData = events.map(row => {
            return [
                {
                    headerValue: "Id",
                    value: row.id,
                    width: 40
                },
                {
                    headerValue: "Event",
                    value: <strong>{row.name}</strong>,
                },
                {
                    headerValue: "Hits",
                    value: row.hits
                },
                {
                    headerValue: "Value",
                    value: row.value ? this.props.accountMaster.accountMaster.currencySymbol + NumberFunctions.formatNumber(row.value, 2) : ""
                },
                {
                    headerValue: "Values Available",
                    value: row.detectedValues ? row.detectedValues.map(m => {
                        return <UILIB.SquareChip className="mar-r5 mar-b5">{m.name}</UILIB.SquareChip>
                    }) : ""
                },
                {
                    headerValue: "",
                    value: row.system ? <><UILIB.SquareChip>System</UILIB.SquareChip></> : <UILIB.Icons icon="bin" color="#FF0000" onClick={() => { this.deleteEvent(row) }} />,
                    width: 50,
                    align: "center"
                }
            ]
        })
        this.setState({ tableData, loading: false, events: events })
    }

    addEvent() {
        var drawerContent = <AddEvent tracker={this.props.tracker} close={this.addedEvent} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    addedEvent() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
        this.getEvents();
    }

    deleteEvent(event) {
        var drawerContent = <DeleteEvent tracker={this.props.tracker} event={event} close={this.addedEvent} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        return (
            <>
                <UILIB.Row>
                    <UILIB.Column xs={12} sm={6} md={8}>
                        <UILIB.Paper>
                            <div className="quickFlex mar-b25" style={{ justifyContent: "space-between" }}>
                                <h4>Your Events</h4>
                                <UILIB.Button iconLeft={<UILIB.Icons icon="plus" />} text="Add an Event" onClick={this.addEvent} />
                            </div>
                            <UILIB.DataTable1
                                noResultsTxt={i18n.t('campaigns:sitetracking.trackingvistors.noDataFound')}
                                tableData={this.state.tableData}
                                dataUpdater={() => { }}
                                width="100%"
                                pageSize="100"
                                hasCheckBoxes="no"
                                sortedColumn={""}
                                sortedDirection={""}
                                loadingData={this.state.loading}
                            />
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} md={4}>
                        <UILIB.Paper>
                            <h4 className="mar-b25">What are Events?</h4>
                            <div className="mar-b10">Site Tracking events enable you to track the things your customers do around your website or service.</div>
                            <div className="mar-b10">You will then be able to attribute success to your marketing efforts, by seeing the number of registrations, payments and more
                                that your customers have completed because of campaigns</div>
                            <div className="mar-b10">There are two built in events...</div>
                            <UILIB.Paper className="mar-b15" style={{ padding: "15px" }}>
                                <div className="text-heavy">Registrations</div>
                                <div>Send this event when a customer registers or signs up, etc.</div>
                            </UILIB.Paper>
                            <UILIB.Paper className="mar-b15" style={{ padding: "15px" }}>
                                <div className="text-heavy">Payments</div>
                                <div>Send this event whenever a payment is made.
                                    You can also supply a <strong>value</strong> with this event so you can track the success of your marketing
                                </div>
                            </UILIB.Paper>
                            <div>You can also <a onClick={this.addEvent}>add your own</a> custom events.</div>
                        </UILIB.Paper>

                        <UILIB.Paper>
                            <h4 className="mar-b25">How to use Events</h4>

                            <div className="mar-b10">There are two ways you can send events in when customers do things on your site or service.</div>
                            <UILIB.Paper>
                                <div className="quickFlex mar-b10" style={{ justifyContent: "space-between" }}>
                                    <div className="text-heavy">When they reach a page</div>
                                    <UILIB.SquareChip className="square-chip-green">Easy</UILIB.SquareChip>
                                </div>
                                <div className="mar-b10">Automatically trigger an event when a customer visits a specific page.</div>
                                <div className="text-heavy" >To get started, click into one of your sites, then the Pages tab, then click the Add Event button next to a page.</div>
                            </UILIB.Paper>

                            <UILIB.Paper>
                                <div className="quickFlex mar-b10" style={{ justifyContent: "space-between" }}>
                                    <div className="text-heavy">Programatically</div>
                                    <UILIB.SquareChip className="square-chip-orange">Advanced</UILIB.SquareChip>
                                </div>
                                <div className="mar-b10">Use the API or extend the Site Tracking Javascript to push events and custom events.</div>
                                <UILIB.Button text="Get Started" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => {
                                    this.props.history.push('/cp/trackers/advanced')
                                }} />
                            </UILIB.Paper>

                        </UILIB.Paper>
                    </UILIB.Column >
                </UILIB.Row >
            </>
        );
    }
}
export default SiteTrackingEvents;
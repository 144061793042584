import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'

export default class ComponentSelect extends Component {
    constructor(props) {
        super(props)
        this.change = this.change.bind(this);
    }

    change(theVal) {
        this.props.updateVal(this.props.path, theVal);
    }

    render() {
        var style = {};
        if (this.props.elementData.optionFullWidth) style = { flex: "100%" }

        return <div className="dd_toolHolder" style={style}>
            <div className="dd_inputTool">
                <p className="txtInput_label">{this.props.title}</p>
                <div className="inputWrapper">
                    <div className="dd_alignHolder">
                        {this.props.options.map((theOpt, index) => {
                            var theType = theOpt.value;
                            var theIcon = theOpt.value;
                            switch (theIcon) {
                                case "top":
                                    theIcon = "icon-align-top"
                                    break
                                case "middle":
                                    theIcon = "icon-align-center-horizontal"
                                    break
                                case "bottom":
                                    theIcon = "icon-align-bottom"
                                    break
                            }
                            var selected = "";
                            if (this.props.value === theType) {
                                selected = " selected";
                            }

                            return <UILIB.ButtonSimple
                                key={"tAlign_" + theType}
                                className="button-simple-fullsize"
                                selected={this.props.value === theType}
                                onClick={() => { this.change(theType) }}
                                iconLeft={<span className={theIcon} />}
                            />

                        })}

                    </div>
                </div>
            </div>

        </div>



    }
}
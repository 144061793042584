import React from 'react'

export default class IconsSmiley extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 18.0001C12.1217 18.0001 14.1566 17.1572 15.6569 15.6569C17.1571 14.1566 18 12.1218 18 10.0001C18 7.87833 17.1571 5.8435 15.6569 4.34321C14.1566 2.84292 12.1217 2.00006 10 2.00006C7.87827 2.00006 5.84344 2.84292 4.34315 4.34321C2.84285 5.8435 2 7.87833 2 10.0001C2 12.1218 2.84285 14.1566 4.34315 15.6569C5.84344 17.1572 7.87827 18.0001 10 18.0001ZM7 9.00006C7.26522 9.00006 7.51957 8.8947 7.70711 8.70717C7.89464 8.51963 8 8.26528 8 8.00006C8 7.73484 7.89464 7.48049 7.70711 7.29295C7.51957 7.10542 7.26522 7.00006 7 7.00006C6.73478 7.00006 6.48043 7.10542 6.29289 7.29295C6.10536 7.48049 6 7.73484 6 8.00006C6 8.26528 6.10536 8.51963 6.29289 8.70717C6.48043 8.8947 6.73478 9.00006 7 9.00006ZM14 8.00006C14 8.26528 13.8946 8.51963 13.7071 8.70717C13.5196 8.8947 13.2652 9.00006 13 9.00006C12.7348 9.00006 12.4804 8.8947 12.2929 8.70717C12.1054 8.51963 12 8.26528 12 8.00006C12 7.73484 12.1054 7.48049 12.2929 7.29295C12.4804 7.10542 12.7348 7.00006 13 7.00006C13.2652 7.00006 13.5196 7.10542 13.7071 7.29295C13.8946 7.48049 14 7.73484 14 8.00006ZM13.536 13.5351C13.6288 13.4422 13.7025 13.3319 13.7527 13.2105C13.8029 13.0891 13.8287 12.9591 13.8287 12.8277C13.8287 12.6964 13.8027 12.5663 13.7524 12.445C13.7021 12.3236 13.6284 12.2134 13.5355 12.1206C13.4426 12.0277 13.3323 11.9541 13.2109 11.9039C13.0896 11.8536 12.9595 11.8278 12.8281 11.8279C12.6968 11.8279 12.5667 11.8538 12.4454 11.9041C12.3241 11.9544 12.2138 12.0282 12.121 12.1211C11.5584 12.6835 10.7955 12.9994 10 12.9994C9.20451 12.9994 8.44158 12.6835 7.879 12.1211C7.69149 11.9334 7.43712 11.828 7.17185 11.8279C6.90658 11.8278 6.65214 11.9331 6.4645 12.1206C6.27686 12.3081 6.17139 12.5624 6.1713 12.8277C6.1712 13.093 6.27649 13.3474 6.464 13.5351C6.92831 13.9995 7.47956 14.3679 8.08628 14.6193C8.69299 14.8706 9.34328 15 10 15C10.6567 15 11.307 14.8706 11.9137 14.6193C12.5204 14.3679 13.0717 13.9995 13.536 13.5351Z" fill={color} />
        </svg>




    }
}        
import React from 'react'
import UILIB from '~/Common-Objects/Lib'

/*
CARD
Cards used for navigation and stuff. Includes a lovely big icon, title, text and button

PROPS:
icon            REACT               The icon to display as a react component
title           REACT/TEXT          The title. Can be react or text
subTitle        REACT/TEXT          The sub title.
buttonText      REACT/TEXT          The button text/react.
*/

/*
STYLING is take from WhiteBox
*/

export default class Card extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return <UILIB.Paper className="cardholder" style={this.props.style}>
            <div className="cardholder-icon">
                {this.props.icon}
            </div>
            <h4 className="cardholder-title">
                {this.props.title}
            </h4>
            <div className="cardholder-subtitle">
                {this.props.subTitle}
            </div>
            <div className="cardholder-cta">
                <UILIB.Button onClick={this.props.onClick}>{this.props.buttonText}</UILIB.Button>
            </div>
        </UILIB.Paper>

    }
}

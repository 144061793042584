import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import i18n from '~/i18n'

export default class PopCampaignChangeTemplate extends Component {
    constructor(props) {
        super(props)

        this.reallyClearTemplate = this.reallyClearTemplate.bind(this);
    }

    reallyClearTemplate() {
        var self = this;
        var qs = '&reselect=true';
        if (this.props.hasOwnProperty('redirect') && this.props.redirect && this.props.redirect.length) {
            if (this.props.redirect.indexOf("?") < 0) {
                qs = "?reselect=true"
            }
        }

        if (this.props.hasOwnProperty('redirect') && this.props.redirect && this.props.redirect.length && this.props.redirect.indexOf('/automation/') > -1) {
            this.props.history.push(this.props.redirect + qs)
            return;
        }
        axios.put('/campaign/' + this.props.campaignId,
            {
                campaign: {
                    TemplateId: null
                }
            })
            .then(() => {
                if (this.props.redirect) {
                    this.props.history.push(this.props.redirect + qs)
                } else {
                    this.props.history.push('/cp/campaigns/add/standard/' + self.props.campaignId + '/2')
                }
            })
    }

    render() {

        return <div>

            <h4 className="mar-b25">{i18n.t('templates:add.drag.change.header')}</h4>
            <div className="mar-b25">{i18n.t('templates:add.drag.change.subHeader')}</div>

            <UILIB.Button text={i18n.t('templates:add.drag.change.button')} className="button-primary" onClick={this.reallyClearTemplate} />


        </div>
    }
}



import React from 'react'

export default class IconsCircle extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon no-fill";
        return <svg className={className} style={this.props.style} width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="3" height="3" rx="1.5" stroke={color} />
        </svg>
    }
}        
import React from 'react'
import Icons from '../Icons'

export default class SocialSharer extends React.Component {
    constructor(props) {
        super(props);
        this.openSharer = this.openSharer.bind();

    }

    openSharer(link) {
        window.open(link, "popup", "width=480,height=240");
    }

    render() {

        var url = "";
        var title = "";
        var summary = "";
        var imageUri = "";
        var description = "";
        var hashTags = ""; //command seperated (word1,word2)
        var size = "40px";

        if (this.props.url) url = encodeURI(this.props.url);
        if (this.props.title) title = this.props.title;
        if (this.props.summary) summary = this.props.summary;
        if (this.props.imageUri) imageUri = this.props.imageUri;
        if (this.props.description) description = this.props.description;
        if (this.props.hashTags) hashTags = this.props.hashTags;

        url = encodeURIComponent(url);
        var ourId = Math.round(Math.random() * 9999999);

        var links = {
            facebook: { color: "#3B5998", link: `https://www.facebook.com/sharer/sharer.php?u=${url}`, icon: <Icons icon="facebookColorRounded" style={{ height: 24, width: 24 }} color="#1877F2" /> },
            twitter: { color: "#CB1F27", link: `https://twitter.com/intent/tweet/?url=${url}&text=${summary}&hashtags=${hashTags}`, icon: <Icons icon="xColorRounded" style={{ height: 24, width: 24 }} color="#000000" /> },
            linkedIn: { color: "#007BB5", link: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${summary}`, icon: <Icons icon="linkedInColorRounded" style={{ height: 24, width: 24 }} color="#0A66C2" /> },
            pInterest: { color: "#CB1F27", link: `https://www.pinterest.com/pin/create/button/?url=${url}&media=${imageUri}&description=${summary}`, icon: <Icons icon="pinterestColorRounded" style={{ height: 24, width: 24 }} color="#CB1F27" /> }
        }


        if (this.props.size) size = this.props.size;
        var sizeNo = Number(size.replace("px", ""));
        var lineHeight = String(sizeNo / 2) + "px";


        return <div style={{ ...this.props.style, display: 'flex' }}>
            {Object.keys(links).map((theLink, index) => {
                var thisName = theLink;
                var thisObj = links[theLink];
                return <a key={'socialShare_' + ourId + '_' + index} href={thisObj.link} target="popup" style={{ fontSize: size, color: thisObj.color, lineHeight: lineHeight, marginRight: 5 }} onClick={() => this.openSharer(thisObj.link)}>{thisObj.icon}</a>
            })
            }
        </div >
    };
};
import React from 'react'

export default class IconsPlus extends React.Component {

    render() {
        let color = "#7246B1";
        let className = "icons-icon";
        if (this.props.color) color = this.props.color;

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 5.00006C10.2652 5.00006 10.5196 5.10542 10.7071 5.29295C10.8946 5.48049 11 5.73484 11 6.00006V9.00006H14C14.2652 9.00006 14.5196 9.10542 14.7071 9.29295C14.8946 9.48049 15 9.73484 15 10.0001C15 10.2653 14.8946 10.5196 14.7071 10.7072C14.5196 10.8947 14.2652 11.0001 14 11.0001H11V14.0001C11 14.2653 10.8946 14.5196 10.7071 14.7072C10.5196 14.8947 10.2652 15.0001 10 15.0001C9.73478 15.0001 9.48043 14.8947 9.29289 14.7072C9.10536 14.5196 9 14.2653 9 14.0001V11.0001H6C5.73478 11.0001 5.48043 10.8947 5.29289 10.7072C5.10536 10.5196 5 10.2653 5 10.0001C5 9.73484 5.10536 9.48049 5.29289 9.29295C5.48043 9.10542 5.73478 9.00006 6 9.00006H9V6.00006C9 5.73484 9.10536 5.48049 9.29289 5.29295C9.48043 5.10542 9.73478 5.00006 10 5.00006Z" fill={color} />
        </svg>

    }
}

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';

@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster }
})
export default class addHostedServerSetupInfo extends React.Component {



    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            errorText: ""
        }

        this.lookupServer = this.lookupServer.bind(this);
        this.doneDns = this.doneDns.bind(this);
    }

    componentWillMount() {
        this.lookupServer();
    }

    lookupServer() {

        axios.get("/mailServer/add/" + this.props.serverId).then(data => {
            if (data.data.MailServer.options.awaitingDNSSetup || 1 == 1) {
                this.setState({ serverData: data.data.MailServer, loaded: true })
            }
            else {
                this.props.close();
            }
        })
    }

    doneDns() {
        axios.put("/agency/mailserver/" + this.props.serverId + "/donedns").then(() => {
            this.props.close()
        }).catch(err => {
            this.setState({ errorTxt: err })
        })

    }
    render() {
        if (!this.state.loaded) return <div className="loading">Loading</div>
        let spfHost = "servers.ourmailsender.com"
        if (this.props.siteMaster.spfRecordUrl) spfHost = this.props.siteMaster.spfRecordUrl + ".ourmailsender.com"
        return (

            <div>

                <h4 className="mar-b25" onClick={this.props.close}>Setting up your new Server</h4>
                <div className="mar-b25">Please use the following details to fully configure your server. </div>


                <UILIB.Paper className="paper-primary mar-b25" >
                    <div>Create an MX record</div>
                    <div>Host: {this.state.serverData.name}:</div>
                    <div>Value: mail.mpzmail.com</div>
                </UILIB.Paper>

                <UILIB.Paper className="paper-primary mar-b25">
                    <div>Create an A record:</div>
                    <div>Host: {this.state.serverData.name}:</div>
                    <div>Value: {this.state.serverData.options.host}</div>
                </UILIB.Paper>

                <UILIB.Paper className="paper-primary mar-b25">
                    <div>Create an AAAA record:</div>
                    <div>Host: {this.state.serverData.name}:</div>
                    <div>Value: {this.state.serverData.options.hostIpV6}</div>
                </UILIB.Paper>

                <UILIB.Paper className="paper-primary mar-b25">
                    <div>Create a CNAME record:</div>
                    <div>Host:  mp1._domainkey.{this.state.serverData.name}</div>
                    <div>Value:  dkim.ourmailsender.com</div></UILIB.Paper>

                <UILIB.Paper className="paper-primary mar-b25">
                    <div>Create a TXT record:</div>
                    <div>Host:  {this.state.serverData.name}</div>
                    <div>Value:  v=spf1 include:${spfHost} ip4:${this.state.serverData.host} ?all</div>
                </UILIB.Paper>

                {this.state.errorTxt && <div className="text-red mar-b25">{this.state.errorTxt}</div>}
                <UILIB.Button className="mar-r10 mar-b10" text="I've done the DNS and waited 30 minutes" onClick={this.doneDns} />
                <UILIB.Button className="secondary " text="Come back later" onClick={this.props.close} />

            </div >

        );
    }
};


import React from "react";

export default function IconTickCircleDouble({ color = "#10B982", ...rest }) {
  return (
    <svg
      className="icons-icon"
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6029 7.20987C13.8126 7.20804 14.0147 7.28868 14.1656 7.43441L14.3909 7.65976C14.3072 6.08634 13.6452 4.59431 12.5255 3.47452C12.3583 3.30734 12.1828 3.15036 12 3.00398C11.8172 3.15036 11.6417 3.30734 11.4745 3.47452C10.8368 4.11219 10.3476 4.87056 10.0291 5.69594C10.1421 5.63878 10.2682 5.60875 10.3971 5.60987C10.6069 5.61169 10.8075 5.69582 10.9558 5.84415C11.1042 5.99248 11.1883 6.19313 11.1901 6.40288C11.1919 6.61264 11.1113 6.81472 10.9656 6.96561L9.60813 8.32305C9.68791 9.9028 10.3506 11.4016 11.4745 12.5255C11.6417 12.6927 11.8172 12.8497 12 12.996C12.1828 12.8497 12.3583 12.6927 12.5255 12.5255C13.3157 11.7353 13.8779 10.7597 14.1698 9.70103L13.0344 8.56561C12.8886 8.41472 12.808 8.21264 12.8098 8.00288C12.8117 7.79313 12.8958 7.59248 13.0441 7.44415C13.1924 7.29582 13.3931 7.21169 13.6029 7.20987ZM11.1375 2.42181C10.1878 1.88772 9.10875 1.60001 7.99998 1.60001C6.30259 1.60001 4.67472 2.27429 3.47449 3.47452C2.27426 4.67476 1.59998 6.30262 1.59998 8.00001C1.59998 9.69739 2.27426 11.3253 3.47449 12.5255C4.67472 13.7257 6.30259 14.4 7.99998 14.4C9.10874 14.4 10.1878 14.1123 11.1375 13.5782C11.0106 13.4676 10.8872 13.3524 10.7674 13.2326C9.67264 12.1378 8.95632 10.7354 8.70264 9.22854L7.76557 10.1656C7.61555 10.3156 7.41211 10.3998 7.19998 10.3998C6.98784 10.3998 6.7844 10.3156 6.63438 10.1656L5.03438 8.56561C4.88865 8.41472 4.80801 8.21264 4.80984 8.00288C4.81166 7.79313 4.89579 7.59248 5.04412 7.44415C5.19245 7.29582 5.3931 7.21169 5.60285 7.20987C5.81261 7.20804 6.01469 7.28868 6.16558 7.43441L7.19998 8.46881L8.66767 7.00111C8.88476 5.40739 9.61722 3.91759 10.7674 2.76742C10.8872 2.64765 11.0106 2.53241 11.1375 2.42181Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 14.4C17.6974 14.4 19.3252 13.7257 20.5255 12.5255C21.7257 11.3253 22.4 9.69739 22.4 8.00001C22.4 6.30262 21.7257 4.67476 20.5255 3.47452C19.3252 2.27429 17.6974 1.60001 16 1.60001C14.3026 1.60001 12.6747 2.27429 11.4745 3.47452C10.2743 4.67476 9.59998 6.30262 9.59998 8.00001C9.59998 9.69739 10.2743 11.3253 11.4745 12.5255C12.6747 13.7257 14.3026 14.4 16 14.4ZM18.9656 6.96561C19.1113 6.81472 19.1919 6.61264 19.1901 6.40288C19.1883 6.19313 19.1042 5.99248 18.9558 5.84415C18.8075 5.69582 18.6069 5.61169 18.3971 5.60987C18.1873 5.60804 17.9853 5.68868 17.8344 5.83441L15.2 8.46881L14.1656 7.43441C14.0147 7.28868 13.8126 7.20804 13.6029 7.20987C13.3931 7.21169 13.1924 7.29582 13.0441 7.44415C12.8958 7.59248 12.8117 7.79313 12.8098 8.00288C12.808 8.21264 12.8886 8.41472 13.0344 8.56561L14.6344 10.1656C14.7844 10.3156 14.9878 10.3998 15.2 10.3998C15.4121 10.3998 15.6156 10.3156 15.7656 10.1656L18.9656 6.96561Z"
        fill={color}
      />
    </svg>
  );
}

import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions';
import i18n from '~/i18n';
import PermissionChecker from '~/Classes/permissions';
import AddDrawer from './add'
import siteVars from '~/Classes/siteVars';
import ArrowDownPurple from '~/assets/images/icons/arrow-down-purple.svg';
import ReportSizeWarning from './reportSizeWarning'
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})
class CampaignReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            loading: true,
            count: 0,
            page: 0,
            limit: 15,
            orderBy: 'createdAt',
            orderDir: 'DESC',
            users: [],
            selectedUser: '',
            selectedType: 'all',
            types: { all: i18n.t('allReports'), campaign: i18n.t('campaignStats'), bounce: i18n.t('bounces'), open: i18n.t('campaignOpens'), click: i18n.t('campaignClicks'), unsubscribe: i18n.t('unsubscribes') }
        }
        this.getUsers = this.getUsers.bind(this)
        this.getReports = this.getReports.bind(this)
        this.createReport = this.createReport.bind(this)
        this.download = this.download.bind(this)
        this.delete = this.delete.bind(this)
        this.tableUpdater = this.tableUpdater.bind(this)
        this.changeType = this.changeType.bind(this)
        this.changeUser = this.changeUser.bind(this)
        this.changeName = this.changeName.bind(this)
        this.actuallyDownload = this.actuallyDownload.bind(this);
    }

    componentDidMount() {
        this.getUsers()
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    getUsers() {
        axios.get('/accountUser').then(response => {
            var allUsersDat = i18n.t('campaigns:reports.allUsers').split(" ");
            var allFirst = "All";
            var allLast = "Users";
            if (allUsersDat.length) {
                allFirst = allUsersDat[0];
                allLast = allUsersDat[1];
            }
            this.setState({
                users: [{ id: '', firstName: allFirst, lastName: allLast }].concat(response.data.AccountUsers)
            }, () => {
                this.timer = setInterval(() => this.getReports(true), 5000)
                this.getReports()
            })
        })
    }

    getReports(ignoreLoading) {
        if (!ignoreLoading) this.setState({
            loading: true
        })
        var queryStr = `?offset=${this.state.page * this.state.limit}&limit=${this.state.limit}&orderby=${this.state.orderBy}&orderdir=${this.state.orderDir}`
        if (this.state.selectedUser) {
            queryStr += '&AccountUserId=' + this.state.selectedUser.id
        }
        if (this.state.selectedType !== 'all') {
            queryStr += '&type=' + this.state.selectedType
        }
        if (this.state.nameFilter && this.state.nameFilter.length > 2) {
            queryStr += '&name=' + this.state.nameFilter
        }
        var canWrite = PermissionChecker("reporting", this.props.permissionStore.permissions, "write");

        axios.get('/reports' + queryStr).then(response => {
            this.setState({
                loading: false,
                count: response.data.count,
                reports: response.data.Reports.map((report, index) => {
                    var status
                    switch (report.status) {
                        case 'queued':
                            status = <UILIB.SquareChip className="grey mar-b0 uppercase">{i18n.t('campaigns:reports.queued')}</UILIB.SquareChip>
                            break;
                        case 'processing':
                            status = <UILIB.SquareChip className="secondary mar-b0 uppercase">{i18n.t('campaigns:reports.processing')}</UILIB.SquareChip>
                            break;
                        case 'finished':
                            status = <UILIB.SquareChip className="primary mar-b0 uppercase">{i18n.t('campaigns:reports.finished')}</UILIB.SquareChip>
                            break;
                        case 'error':
                            status = <UILIB.SquareChip className="red mar-b0 uppercase">{i18n.t('campaigns:reports.error')}</UILIB.SquareChip>
                            break;
                        default:
                            status = <UILIB.SquareChip className="grey mar-b0 uppercase">{report.status}</UILIB.SquareChip>
                    }

                    var fileSize = report.fileSize;
                    if (fileSize == undefined || isNaN(fileSize)) {
                        fileSize = "N/A"
                    }
                    else {
                        if (fileSize <= 1) {
                            fileSize = "< 1";
                        }
                        fileSize += "mb"
                    }

                    return {
                        name: {
                            headerValue: i18n.t('campaigns:reports.name'),
                            value: report.status == "finished" ? <a onClick={() => { this.download(report) }}>{report.name}</a> : report.name,
                            orderBy: true
                        },
                        fileSize: {
                            headerValue: i18n.t('campaigns:reports.fileSize'),
                            value: fileSize,
                            orderBy: true
                        },
                        type: {
                            headerValue: i18n.t('campaigns:reports.reportType'),
                            value: this.state.types[report.type],
                            orderBy: true
                        },
                        startDate: {
                            headerValue: i18n.t('campaigns:reports.startDate'),
                            value: DateTimeFunctions.formatDateTime(report.filters.startDate, 2)
                        },
                        endDate: {
                            headerValue: i18n.t('campaigns:reports.endDate'),
                            value: DateTimeFunctions.formatDateTime(report.filters.endDate, 2)
                        },
                        createdAt: {
                            headerValue: 'Created Date',
                            value: DateTimeFunctions.formatDateTime(report.createdAt, 2),
                            orderBy: true
                        },
                        status: {
                            headerValue: <div className="center-xs">{i18n.t('campaigns:reports.status')}</div>,
                            value: <div className="center-xs">{status}</div>,
                            orderBy: false,
                            width: 150
                        },
                        AccountUserId: {
                            headerValue: i18n.t('user'),
                            value: report.AccountUser ? <UILIB.Avatar style={{ height: '30px', width: '30px' }} tooltip={report.AccountUser.fullname} src={report.AccountUser.profilePicFileName} /> : '',
                            orderBy: false
                        },

                        optionsCol: {
                            headerValue: " ",
                            value: (report.status == "finished" || report.status == 'error') && <UILIB.OptionsDropdown popWidth="150px">
                                <ul>
                                    {report.status == "finished" && <li>
                                        <a onClick={() => this.download(report)}>{i18n.t('campaigns:reports.download')}</a>
                                    </li>}
                                    {(canWrite == true && (report.status == "finished" || report.status == 'error')) && <li>
                                        <a onClick={() => { this.delete(report) }}>{i18n.t('campaigns:reports.delete')}</a>
                                    </li>}
                                </ul>
                            </UILIB.OptionsDropdown>,
                            orderBy: false,
                            width: 20,
                            fixed: true
                        }
                    }
                }),
            })
        })
    }

    changeType(type) {
        this.setState({
            selectedType: type
        }, this.getReports)
    }

    changeUser(user) {
        this.setState({
            selectedUser: user
        }, this.getReports)
    }

    changeName(ev) {
        this.setState({
            nameFilter: ev.currentTarget.value
        }, () => {
            if (this.nameTimer) clearTimeout(this.nameTimer)
            this.nameTimer = setTimeout(this.getReports, 750)
        })
    }

    createReport() {
        var drawerContent = <AddDrawer close={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null, true));
        }} download={this.download}
        />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true, '100vw', 'full'));
    }

    download(report) {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null, true));
        if (!isNaN(report.fileSize) && report.fileSize != undefined && report.fileSize > 9) {
            var drawerContent = <ReportSizeWarning fileSize={report.fileSize} actuallyDownload={() => { this.actuallyDownload(report) }} close={() => {
                this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null, true));
            }} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true));
            return;
        }
        this.actuallyDownload(report);
    }

    actuallyDownload(report) {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null, true));
        axios.get('/reports/' + report.id + '/file').then(res => {
            const link = document.createElement('a');
            link.href = res.data.url
            link.setAttribute('download', report.name + '.csv')
            document.body.appendChild(link);
            link.click();
        })

    }

    delete(report) {
        var drawer = <UILIB.DrawerConfirm header={i18n.t('campaigns:reports.areYouSure') + " " + report.name + "?"} showCancel={true} confirm={() => {
            this.setState({
                alertMessage: i18n.t('campaigns:reports.deleting') + report.name,
                alertOpen: true
            })
            axios.delete('/reports/' + report.id).then(() => {
                this.setState({
                    alertMessage: i18n.t('campaigns:reports.deleted') + report.name,
                    alertOpen: true
                })
                this.getReports()
            })
        }} />

        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawer, true));
    }

    tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {
        var updateObj = {}
        if (currentPage !== undefined && currentPage !== null) {
            updateObj.page = currentPage
        }

        if (sortDirection !== undefined && sortDirection !== null) {
            updateObj.orderDir = sortDirection
        }

        if (sortColumn !== undefined && sortColumn !== null) {
            updateObj.orderBy = sortColumn
        }

        this.setState(updateObj, this.getReports)
    }

    render() {
        // if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        var canWrite = PermissionChecker("reporting", this.props.permissionStore.permissions, "write");
        return (
            <UILIB.Paper>
                <div className="page-header mar-b25">{i18n.t('campaigns:reports.reports')}</div>

                <UILIB.Row>
                    <UILIB.Column lg={6} md={6} sm={6} xs={12} className="mar-b25">
                        <UILIB.TextInput
                            placeholder={i18n.t('campaigns:reports.searchForReportName')}
                            onChange={this.changeName}
                            iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                            value={this.state.nameFilter}
                        />
                    </UILIB.Column>
                    <UILIB.Column lg={6} md={6} sm={6} xs={6} hide={["xs"]} style={{ textAlign: "right" }} className="mar-b25">


                        {canWrite &&
                            <UILIB.Button className="mar-l10 button-primary" iconLeft={<UILIB.Icons icon="plus" color="white" />} onClick={() => this.createReport()}>{i18n.t('campaigns:reports.createReport')}</UILIB.Button>
                        }
                    </UILIB.Column>
                </UILIB.Row>


                <div className="quickFlex mar-b25">
                    <UILIB.ButtonSelect
                        headerText={this.state.selectedUser ? this.state.selectedUser.fullname : i18n.t('campaigns:reports.allUsers')}
                        autoClose
                        data={this.state.users.map((user, index) => {
                            return <a key={"st_" + index} className="quickFlex" onClick={() => this.changeUser(user)}>
                                {!!user.profilePicFileName && <UILIB.Avatar style={{ height: '30px', width: '30px' }} src={user.profilePicFileName} />} <span className="mar-l10">{user.fullname}</span>
                            </a>
                        })}
                        className="mar-r10"
                    />

                    <UILIB.ButtonSelect
                        headerText={this.state.types[this.state.selectedType]}
                        autoClose
                        data={Object.keys(this.state.types).map((row, index) => {
                            return <a key={"cs_" + index} onClick={() => this.changeType(row)}>{this.state.types[row]}</a>
                        })}
                    />

                </div>

                <UILIB.DataTable1
                    noResultsTxt={<span> {i18n.t('campaigns:reports.noReportsFound')} <a onClick={this.createReport}> {i18n.t('campaigns:reports.runReport')} </a></span>}
                    tableData={this.state.reports}
                    loadingData={this.state.loadingData}
                    dataUpdater={this.tableUpdater}
                    width="100%"
                    pageSize="100"
                    hasCheckBoxes="no"
                    sortedColumn={this.state.orderBy}
                    sortedDirection={this.state.orderDir}
                    className="mar-b25"
                />

                <UILIB.Row>
                    <UILIB.Column xs={12} hide={["xs", "sm"]} className="center-xs">
                        <UILIB.PagingBlock totalRows={this.state.count} pageSize={this.state.limit} numberOfLinks="10" currentPage={this.state.page} changePage={this.tableUpdater} text={i18n.t('page') + ":"} />
                    </UILIB.Column>
                </UILIB.Row>

                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
            </UILIB.Paper >
        );
    }
}

export default CampaignReports;
import React from 'react'

export default function IconsLinkedinColor({ color = "", style = {} }) {
    let fillColor = "#1877F2";
    if (color) fillColor = color;
    let className = "icons-icon";

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} className={className}>
            <g clipPath="url(#clip0_29_19825)">
                <path className="animate-svg" fillRule="evenodd" clipRule="evenodd" d="M10 0C15.5229 0 20 4.47715 20 10C20 14.9913 16.3432 19.1283 11.5626 19.8785V12.8906H13.8927L14.336 10H14.3359L14.3359 10H11.5626V8.12417C11.5626 7.33335 11.95 6.56251 13.1922 6.56251H14.4532V4.10157C14.4532 4.10157 14.4532 4.10157 14.4531 4.10156C14.4531 4.10156 13.5101 3.94061 12.5177 3.91093C12.4169 3.90791 12.3155 3.90625 12.2146 3.90625C9.93043 3.90625 8.4375 5.29063 8.4375 7.79688V10H5.89844V12.8906H5.89852V12.8906H8.4375V19.8785C3.65686 19.1283 0 14.9913 0 10C0 4.47715 4.47715 0 10 0Z" fill={fillColor} />
            </g>
            <defs>
                <clipPath id="clip0_29_19825">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
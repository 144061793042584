import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NewUserForm from '../../../includes/bb_topNav/newUserForm/newUserForm';
import { alterSiteDrawer } from '../../../../../data/actions/siteActions';

//CONNECT TO STORE
@connect((store) => {
    return {
        user: store.user,
        siteMaster: store.siteMaster,
        account: store.accountMaster
    }
})

export default class CleanTemplate extends React.Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
            pageLoaded: false,
            jobs: [],
            groupId: this.props.match.params.groupID

        };
        this.getJobs = this.getJobs.bind(this);
        this.startListClean = this.startListClean.bind(this);
    }

    componentDidMount() {
        if (this.props.siteMaster.listCleanDisabled) return this.props.history.push("/cp")
        this.getJobs();
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer)
    }

    startListClean() {
        this.props.history.push('/cp/subscribers/clean/startclean/' + this.state.groupId)
    }

    activate() {
        const drawer = <NewUserForm from="clean" />
        this.props.dispatch(alterSiteDrawer(true, true, 'right', drawer, true, '100vw'))
    }


    getJobs() {
        var self = this;
        axios.get('/listcleaner?groupId=' + this.state.groupId)
            .then(response => {

                var tableData = response.data.ListCleanJobs.map(item => {

                    return {
                        id: {
                            headerValue: "#",
                            value: item.id,
                            width: 50,
                            align: "center"
                        },
                        createdAt: {
                            headerValue: i18n.t('subscribers:listClean.overview.startDate'),
                            value: DateTimeFunctions.formatDateTime(item.createdAt, 2)
                        },
                        status: {
                            headerValue: i18n.t('subscribers:listClean.overview.status'),
                            value: <UILIB.SquareChip className={item.status > 4 ? "square-chip-green" : "square-chip-orange"}>{item.status < 5 ? "Processing" : "Finished"}</UILIB.SquareChip>,
                            align: "left"
                        },
                        group: {
                            headerValue: i18n.t('subscribers:listClean.overview.group'),
                            value: item.groups.map((g, i) => g ?
                                <UILIB.SquareChip
                                    key={i}
                                    className="mar-r10"
                                    onClick={() => this.props.history.push('/cp/groups/' + g.id)}
                                    iconLeft={<UILIB.Icons icon="magnifier" style={{ width: 15, height: 15 }} />}>{g.groupName}
                                </UILIB.SquareChip>
                                : <UILIB.SquareChip
                                    key={i}
                                    className="mar-r10">
                                    {i18n.t('subscribers:listClean.overview.noGroupFound')}
                                </UILIB.SquareChip>
                            )
                        },
                        processed: {
                            headerValue: i18n.t('subscribers:listClean.overview.processed'),
                            value: <UILIB.SmallLineGraph width="100" height="5" val1={item.doneCount} val2={item.subCount || 0} />,
                            align: "center"
                        },
                        perfect: {
                            headerValue: i18n.t('subscribers:listClean.overview.perfect'),
                            value: <UILIB.SmallLineGraph width="100" height="5" val1={item.perfectCount} val2={item.subCount || 0} />,
                            align: "center"
                        },
                        imperfect: {
                            headerValue: i18n.t('subscribers:listClean.overview.imperfect'),
                            value: <UILIB.SmallLineGraph width="100" height="5" val1={item.imperfectCount} val2={item.subCount || 0} />,
                            align: "center"
                        },
                        failed: {
                            headerValue: i18n.t('subscribers:listClean.overview.failed'),
                            value: <UILIB.SmallLineGraph width="100" height="5" val1={item.failedCount} val2={item.subCount || 0} />,
                            align: "center"
                        },
                        optionsCol:
                        {
                            headerValue: " ",
                            value: <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                <UILIB.Button className="button-sml" text="View" onClick={() => { this.props.history.push("/cp/subscribers/clean/" + item.id) }} />
                            </div>,
                            orderBy: false,
                            fixed: true,
                            width: 20
                        }

                    }
                })

                self.setState({ pageLoaded: true, jobs: tableData }, () => {
                    self.timer = setTimeout(self.getJobs, 10000)
                });
            });
    }

    render() {

        if (!this.state.pageLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>

        const allowed = this.props.account.accountMaster.approved && !this.props.account.accountMaster.deny

        return <div>
            {this.props.siteMaster.siteId == 1 && <UILIB.Hint style={{ width: 'fit-content' }} className="mar-l0 mar-b25 hide-xs" href="https://kb.transpond.io/article/113-how-to-clean-your-lists" iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>Find out what list cleans are, and how they can hugely help your campaigns</UILIB.Hint>}
            <UILIB.Row>

                <UILIB.Column xs={12}>

                    {!!this.state.jobs.length &&
                        <UILIB.Paper>
                            <h4 className="mar-b25">{i18n.t('subscribers:listClean.overview.listCleanHistoryTitle')}</h4>
                            <p className="mar-b25">{i18n.t('subscribers:listClean.overview.listCleanHistorySubTitle')}</p>
                            <UILIB.DataTable1 noResultsTxt={<span> {i18n.t('noResults')}</span>} tableData={this.state.jobs} loadingData={false}
                                width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn={"createdAt"}
                                sortedDirection={"desc"}
                            />
                        </UILIB.Paper>
                    }
                    {!this.state.jobs.length &&
                        <UILIB.Paper>
                            <h4 className="mar-b25">You have not cleaned this group yet.</h4>
                            <p>The email addresses in this group will be automatically scanned to find and remove invalid, non existant and expired email addresses.</p>
                            <p>Once the scan is finished you can choose to remove any invalid or dubious addresses found.</p>
                            <p className="mar-b25">Removing invalid email addresses is extremely important and will greatly increase the chance of your email reaching the inbox.</p>
                            <div className="quickFlex">
                                {!allowed && <div className='text-orange text-heavy mar-r10'>Your account must be approved before you can clean your list</div>}
                                {!allowed && !this.props.account.accountMaster.doneNewUserQuestions && <UILIB.Button className="button-orange" onClick={this.activate.bind(this)}>Request Approval</UILIB.Button>}
                                {allowed && <UILIB.Button
                                    className="button-primary"
                                    iconRLeft={<UILIB.Icons icon="sparkle" />}
                                    onClick={this.startListClean}
                                    text={i18n.t('subscribers:listClean.overview.startListClean')} />}
                            </div>
                        </UILIB.Paper>
                    }

                </UILIB.Column>

            </UILIB.Row>


        </div >
    };
};
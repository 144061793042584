import React from "react";

export default function IconMessageSentCheck({
  color = "currentColor",
  ...rest
}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8569 5.69129C11.9147 5.61157 11.9563 5.52124 11.9793 5.42543C12.0023 5.32962 12.0062 5.23022 11.9908 5.13291C11.9754 5.03559 11.941 4.94226 11.8896 4.85825C11.8381 4.77425 11.7707 4.7012 11.6911 4.64329C11.6115 4.58537 11.5212 4.54373 11.4255 4.52073C11.3298 4.49773 11.2306 4.49382 11.1334 4.50924C11.0362 4.52465 10.9429 4.55908 10.859 4.61057C10.7751 4.66205 10.7022 4.72957 10.6443 4.80929L7.16546 9.59929L5.28769 7.71929C5.21855 7.64769 5.13586 7.59059 5.04445 7.55133C4.95304 7.51206 4.85473 7.49142 4.75526 7.4906C4.6558 7.48979 4.55716 7.50881 4.46512 7.54656C4.37308 7.58432 4.28946 7.64005 4.21916 7.7105C4.14886 7.78095 4.09327 7.86472 4.05565 7.9569C4.01803 8.04909 3.99912 8.14786 4.00003 8.24745C4.00094 8.34703 4.02165 8.44543 4.06096 8.53692C4.10026 8.6284 4.15737 8.71113 4.22895 8.78029L6.72598 11.2803C6.80251 11.357 6.89474 11.4161 6.9963 11.4537C7.09787 11.4912 7.20635 11.5063 7.31429 11.4978C7.42223 11.4894 7.52705 11.4576 7.62154 11.4047C7.71603 11.3517 7.79794 11.2789 7.86163 11.1913L11.8569 5.69129Z"
        fill={color}
      />
    </svg>
  );
}

import axios from 'axios';
import DbConn from '~/Classes/siteVars.js'
import i18n from '~/i18n';
import axiosInstance from '../http/axios';

export function loginAndGetToken(userName, password, token, twoFaCode, AccountMasterId, captchaToken) {
    return function (dispatch) {
        console.log('loginAndGetToken')
        if (token) {
            dispatch({ type: "LOGGEDIN_SUCCESS", payload: { token } })
        } else {
            axios.post(getBaseUrl() + '/auth', {
                emailAddress: userName,
                password: password,
                url: window.location.host.split('/')[0],
                twoFaCode: twoFaCode,
                captchaToken,
                AccountMasterId
            }, {
                withCredentials: true
            }).then(function (response) {
                if (response.data.error) {
                    dispatch({ type: "LOGGEDIN_FAILED", payload: response.data })
                }
                else {
                    //SET ACCOUNTMASTER ID IN ACCOUNTMASTER STORE
                    dispatch({ type: "LOGGEDIN_SUCCESS_ACCOUNTMASTER", payload: response.data })
                    //SET TOKEN AND USER ID IN USERS STORE
                    dispatch({ type: "LOGGEDIN_SUCCESS", payload: response.data });
                }
            }).catch(function (error) {
                //send error.message
                console.log(error);
                dispatch({ type: "LOGGEDIN_FAILED", payload: error.response.data.error })
            });
        }
    }
}


export function logoutAndClear() {
    return function (dispatch) {
        axiosInstance.get('/logout').then(() => {
            dispatch({ type: "LOGOUT_USER", payload: '' });
        }).catch(() => {
            dispatch({ type: "LOGOUT_USER", payload: '' });
        })
    }
}

export function loadUserRecord() {
    return function (dispatch) {
        if (window.location.search && window.location.search.indexOf('redirect=') > -1) return
        var token
        var user = localStorage.getItem('reduxPersist:user')
        if (user && typeof user == 'string') {
            user = JSON.parse(user)
        }

        if (user && user.token) token = user.token

        if (!token) {
            var queryStr = 'redirect=' + encodeURI(window.location.pathname + window.location.search)
            return window.open(window.location.origin + '?' + queryStr, '_self')
        }

        var config = { headers: { 'X-Access-Token': token }, withCredentials: true };

        var accountUserData = {};
        var permissionsData = {};
        axios.get(getBaseUrl() + '/accountUser/me', config)
            .then(function (response) {
                accountUserData = response.data.AccountUserData;
                return axios.get(getBaseUrl() + '/accountUser/me/permissions', config)
            }).then((response) => {
                permissionsData = response.data.Permissions;
                dispatch({ type: "LOADUSER_PERMISSIONS", payload: permissionsData });
                dispatch({ type: "LOADUSER_SUCCESS", payload: accountUserData });

                if (accountUserData.language) {
                    i18n.changeLanguage(accountUserData.language)
                }
            })
            .catch(() => {
                if (window.location.search.indexOf('redirect=') === -1) {
                    var queryStr = 'redirect=' + encodeURI(window.location.pathname + window.location.search)
                    console.log(queryStr)
                    window.location = '?' + queryStr
                }
            })

    }
}

export function changeAccounts(id, router) {
    return function (dispatch) {
        var token = localStorage.getItem('access_token')
        var user = localStorage.getItem('reduxPersist:user')
        if (user && typeof user == 'string') {
            user = JSON.parse(user)
            if (user.token) token = user.token
        }

        var config = { headers: { 'Authorization': 'Bearer ' + token }, withCredentials: true };

        axios.post(getBaseUrl() + '/accountMaster/change', { id }, config)
            .then(function (response) {
                dispatch({ type: "CHANGE_ACCOUNTMASTER", payload: response.data });
                dispatch({ type: "USER_CHANGE_ACCOUNTMASTER", payload: response.data });
                axios.get(getBaseUrl() + '/logout', config).then(() => {
                    window.location = window.location
                })
                // console.log(router)
            })
            .catch(() => {
                axiosInstance.get('/logout').then(() => {
                    dispatch({ type: "LOGOUT_USER", payload: '' });
                }).catch(() => {
                    dispatch({ type: "LOGOUT_USER", payload: '' });
                })
            })
    }
}

export function updateFields(fields) {
    return function (dispatch) {
        dispatch({ type: "CHANGE_FIELDS", payload: fields });
    }
}

export function updateSystemFields(fields) {
    return function (dispatch) {
        dispatch({ type: "CHANGE_SYSTEM_FIELDS", payload: fields });
    }
}

export function updateLimit(name, limit) {
    return function (dispatch) {
        dispatch({ type: "CHANGE_LIMIT", payload: { name, limit } });
    }
}

function getBaseUrl() {
    var baseUrl = DbConn.getConnString();
    return baseUrl
}
import React from 'react'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

export default class TelephoneField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            valid: true,
        }
        this.goChange = this.goChange.bind(this);
        this.onSelectFlag = this.onSelectFlag.bind(this);
    }

    componentDidMount() {
        var value = this.props.value;
        if (typeof this.props.value == "object" || Array.isArray(this.props.value) || value == null || value == undefined) {
            value = "";
        }
        this.setState({ value: value })
    }

    goChange(isValid, newNumber, fullObj, fullNumber, extension) {
        this.setState({ value: newNumber, valid: isValid })
        this.props.onChange(isValid, fullNumber)
    }

    onSelectFlag(currentNumber, fullObj, fullNumber, isValid) {

        this.setState({ valid: isValid })
        if (currentNumber) {
            this.props.onChange(isValid, fullNumber)
        }

    }
    render() {

        var inputClasses = "";
        if (this.props.className) inputClasses = this.props.className;
        if (!this.state.valid && inputClasses.indexOf('invalid') < 0) {
            inputClasses += " invalid "
        }
        if (this.state.valid && inputClasses.indexOf('invalid') > -1) {
            inputClasses = inputClasses.replace(/invalid/g, '')
        }
        let allowDropdown = true
        if (this.props.hasOwnProperty('allowDropdown')) {
            allowDropdown = this.props.allowDropdown
        }
        const style = { ...this.props.style } || {}
        style.width = '100%'
        return <div style={style}>
            <IntlTelInput
                onlyCountries={this.props.onlyCountries}
                defaultCountry={this.props.defaultCountry}
                allowDropdown={allowDropdown}
                excludeCountries={this.props.excludeCountries}
                name={this.props.name}
                value={this.state.value}
                onPhoneNumberChange={this.goChange}
                onSelectFlag={this.onSelectFlag}
                inputClassName={inputClasses}
                id={this.props.id}
                disabled={this.props.disabled}
                readOnly={this.props.readOnly}
                style={style}
            />
        </div>

    }
}

import React from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class TemplateImportFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadingFile: false,
            finishedUploading: false,
            processingFile: false,
            uploadStatus: 0,
            fileButtonText: i18n.t('templates:add.advanced.uploadSelect'),
            uploadFileName: undefined,
            uploadedFile: null,
            templateName: '',
            groups: [],
            template: {},
            groupId: undefined,
            newGroup: false,
            newGroupName: '',
            errors: {
                fileErrorMsg: '',
                templateName: '',
                newGroupName: ''
            }
        };

        this.clickFile = this.clickFile.bind(this);
        this.fileAddedToInput = this.fileAddedToInput.bind(this);
        this.sendRequestToAPI = this.sendRequestToAPI.bind(this);
        this.updateName = this.updateName.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.updateNewGroupName = this.updateNewGroupName.bind(this);
        this.uploadProgress = this.uploadProgress.bind(this);
        this.save = this.save.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
    }

    componentDidMount() {
        this.getGroups();
    }

    getGroups() {
        var groups = [{
            label: i18n.t('templates:add.advanced.none'),
            value: ''
        }, {
            label: i18n.t('templates:add.advanced.createGroup'),
            value: 'new'
        }]
        axios.get('/templateGroup')
            .then(response => {
                this.setState({
                    groups: groups.concat(response.data.TemplateGroups.map(g => {
                        return {
                            label: g.groupName,
                            value: g.id
                        }
                    }))
                })
            })
            .catch(console.log)
    }

    clickFile() {
        this.refs.fileUploadInput.click();
    }
    fileAddedToInput(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        var thisFileName = file.name;
        this.setState({
            uploadFileName: thisFileName,
            uploadedFile: file,
            fileButtonText: thisFileName
        })
    }

    sendRequestToAPI() {

        var hasErrors = false;
        var fileErrorMsg = "";
        if (!this.state.uploadFileName) {
            fileErrorMsg = <UILIB.FormError>Select a File</UILIB.FormError>;
            return this.setState({ fileErrorMsg: fileErrorMsg });
        }
        var theFileNameDat = this.state.uploadFileName.split(".");
        var theFileNameExt = theFileNameDat[theFileNameDat.length - 1];

        if (theFileNameExt != "html" && theFileNameExt != "zip") {
            fileErrorMsg = <UILIB.FormError>{i18n.t('templates:add.advanced.uploadError')}</UILIB.FormError>;
            this.setState({ fileErrorMsg: fileErrorMsg });
            return;
        }

        this.setState({ uploadingFile: true, processingFile: false, finishedUploading: false, fileErrorMsg: null });
        var self = this;


        let data = new FormData();
        data.append('file', this.state.uploadedFile);
        axios.post('/template/uploadhtml', data,
            {
                onUploadProgress: progressEvent => self.uploadProgress(progressEvent)
            })
            .then(function (res) {
                self.setState({
                    template: res.data.Template,
                    uploadingFile: false,
                    finishedUploading: true,
                    processingFile: false
                })
            })
            .catch(function (error) {
                var errorTxt = "There was an error uploading your file."
                console.log(error)
                if (error.data && error.data.error) errorTxt = error.data.error;
                self.setState({ uploadingFile: false, processingFile: false, finishedUploading: false, fileErrorMsg: errorTxt });
            });
    }

    uploadProgress(uploadEvent) {
        var loaded = uploadEvent.loaded;
        var total = uploadEvent.total;
        var totalLoaded = Math.round((loaded / total) * 100);

        var state = this.state;
        state.uploadStatus = totalLoaded;
        if (totalLoaded >= 100) state.processingFile = true;

        this.setState(state);

    }

    updateName(evt) {
        this.setState({
            templateName: evt.target.value
        })
    }

    updateGroup(evt) {
        var value = evt.target.value;
        if (value == 'new') {
            this.setState({
                groupId: value,
                newGroup: true
            })
        } else {
            this.setState({
                groupId: value,
                newGroup: false
            })
        }
    }

    updateNewGroupName(evt) {
        this.setState({
            newGroupName: evt.target.value
        })
    }

    save() {
        var self = this;
        if (!this.state.templateName) {
            var errors = this.state.errors;
            errors.templateName = i18n.t('templates:add.advanced.invalidName');
            return this.setState({
                errors
            })
        }
        if (this.state.newGroup && !this.state.newGroupName) {
            var errors = this.state.errors;
            errors.newGroupName = i18n.t('templates:add.advanced.invalidGroup');
            return this.setState({
                errors
            })
        }

        if (!this.props.campaignId && this.state.newGroup && this.state.newGroupName) {
            axios.post('/templateGroup',
                {
                    groupName: this.state.newGroupName
                })
                .then(response => {
                    axios.put('/template/' + self.state.template.id,
                        {
                            templateName: self.state.templateName,
                            groupID: response.data.TemplateGroup.id
                        })
                        .then(() => self.props.finish(self.state.template))
                        .catch(err => {
                            self.setState()
                        })
                })
        } else {
            axios.put('/template/' + this.state.template.id,
                {
                    templateName: this.state.templateName,
                    groupID: this.state.groupId,
                    CampaignId: this.props.campaignId
                })
                .then(() => self.props.finish(self.state.template))
                .catch(this.closeDrawer)
        }
    }

    render() {
        return <div>
            <h4 className="mar-b25">{i18n.t('templates:add.advanced.uploadHeader')}</h4>

            {this.state.uploadingFile && !this.state.finishedUploading && !this.state.processingFile && <span>
                <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.uploadStatus} val2={100} />
                <div className="text-heavy" style={{ textAlign: "center", marginTop: "10px" }}>{i18n.t('templates:add.advanced.uploadProgress')} - {this.state.uploadStatus}%</div>
            </span>}

            {this.state.uploadingFile && !this.state.finishedUploading && this.state.processingFile && <span>
                <UILIB.LoadingIcons iconType="2" />
                <div className="text-heavy" style={{ textAlign: "center", marginTop: "10px" }}>Processing File</div>
                <p style={{ textAlign: "center", marginTop: "10px" }}>Please wait, this can take a minute.</p>
            </span>}


            {!this.state.uploadingFile && !this.state.finishedUploading && !this.state.processingFile && <div>
                <div className="mar-b25">{i18n.t('templates:add.advanced.uploadSubHeader')}</div>

                <UILIB.Button className="mar-b25" onClick={this.clickFile}>{this.state.fileButtonText}</UILIB.Button>
                <input type="file" ref="fileUploadInput" style={{ display: 'none' }} onChange={this.fileAddedToInput} />
                {this.state.fileErrorMsg && <UILIB.WarningHolder className="mar-b25">{this.state.fileErrorMsg}</UILIB.WarningHolder>}

                <UILIB.Button className="button-primary" onClick={this.sendRequestToAPI}>{i18n.t('templates:add.advanced.uploadButton')}</UILIB.Button>

            </div>
            }
            {!this.state.uploadingFile && this.state.finishedUploading && !this.state.processingFile && <div>
                <h4 classname="mar-b25">{i18n.t('templates:add.advanced.uploadComplete')}</h4>

                <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('templates:add.advanced.name')} value={this.state.templateName} onChange={this.updateName} placeholder={i18n.t('templates:add.advanced.name')} error={this.state.errors.templateName} />

                {!this.props.campaignId && <div>

                    <UILIB.Select outerClassName="mar-b25" label={i18n.t('templates:add.advanced.group')} value={this.state.groupId} onChange={this.updateGroup} data={this.state.groups} />

                </div>}
                {this.state.newGroup && <div>
                    <label htmlFor="">{i18n.t('templates:add.advanced.newGroup')}</label>
                    <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('templates:add.advanced.newGroup')} value={this.state.newGroupName} onChange={this.updateNewGroupName} placeholder={i18n.t('templates:add.advanced.newGroup')} error={this.state.errors.newGroupName} />
                </div>}
                <UILIB.Button className="button-primary" onClick={this.save}>{i18n.t('save')}</UILIB.Button>
            </div>
            }
        </div>
    };
}

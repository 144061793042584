import React from 'react'
import ReactDOM from 'react-dom'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import codes from './codeExamples'
import params from './codeParameters';
import AceEditor from 'react-ace';
import copy from 'copy-to-clipboard';
import i18n from '~/i18n';

import 'brace/mode/javascript';
import 'brace/mode/python';
import 'brace/mode/php';
import 'brace/mode/csharp';
import 'brace/mode/golang';
import 'brace/mode/ruby'
import 'brace/mode/powershell';

import 'brace/theme/github';
import 'brace/theme/xcode';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, site: store.siteMaster, account: store.accountMaster }
})

export default class TransactionSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: 'javascript',
            codes: codes,
            options: [],
            token: '',
            loaded: false,
            height: 600
        };
        this.getToken = this.getToken.bind(this)
        this.renderCode = this.renderCode.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this)
    }

    componentDidMount() {
        this.getToken()
    }

    componentDidUpdate() {
        if (this.refs.params) {
            var node = ReactDOM.findDOMNode(this.refs.params)
            if (node) {
                var height = node.clientHeight;
                if (height !== this.state.height) {
                    this.setState({
                        height
                    })
                }
            }
        }
    }

    getToken() {
        axios.get('/accountMaster/apikey?transaction=true').then(response => {
            var keys = response.data.ApiKeys
            if (keys && keys.length) {
                this.setState({
                    token: keys[0].apiKey
                }, this.renderCode)
            }
        })
    }

    copyToClipboard(value) {
        copy(value)
        this.setState({
            alertMessage: i18n.t('campaigns:transactions.copiedToClipBoard'),
            alertOpen: true
        })
    }

    renderCode() {
        var obj = {}
        Object.keys(codes).forEach(prop => {
            var code = codes[prop]
            code = code.replace('{{url}}', this.props.site.trackingDomain)
            code = code.replace('{{fromAddress}}', this.props.user.userData.emailAddress)
            code = code.replace('{{fromName}}', this.props.account.accountMaster.accountName)
            code = code.replace('{{replyAddress}}', this.props.user.userData.emailAddress)
            code = code.replace('{{toAddress}}', this.props.user.userData.emailAddress)
            code = code.replace('{{toName}}', this.props.user.userData.fullname)
            code = code.replace('{{token}}', this.state.token)
            obj[prop] = code
        })
        this.setState({
            options: Object.keys(codes),
            codes: obj,
            loaded: true
        })
    }

    render() {
        if (!this.state.loaded) return <div></div>
        return <div>

            <div className="page-header mar-b25">{i18n.t('campaigns:transactions.settings')}</div>

            <UILIB.Row>
                <UILIB.Column xs={12} md={6} margin={0}>

                    <UILIB.Paper>
                        <h4 className="mar-b25">Send Via the Api</h4>
                        <UILIB.TextInput
                            type="text"
                            value={'https://' + this.props.site.trackingDomain + '/transaction'}
                            outerClassName="mar-b25"
                            label={i18n.t('campaigns:transactions.apiEndPoint')}
                        />

                        <UILIB.TextArea
                            value={this.state.token}
                            outerClassName="mar-b25"
                            style={{ height: "100px" }}
                            label={i18n.t('campaigns:transactions.yourAccessToken')}
                            iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => this.copyToClipboard(this.state.token)} alt={i18n.t('campaigns:transactions.copyToClipBoard')} title={i18n.t('campaigns:transactions.copyToClipBoard')} />}
                        />

                    </UILIB.Paper>

                    <UILIB.Paper >
                        <UILIB.Row>
                            <UILIB.Column xs={12} margin={0} ref="params">
                                <h4 className="mar-b25">{i18n.t('campaigns:transactions.authentication')}</h4>
                                <div className="mar-b25" dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:transactions.authenticationHeader') }} />

                                <h4 className="mar-b25">{i18n.t('campaigns:transactions.parameters')}</h4>
                                {params.request.map((row, index) => {

                                    return <UILIB.TextInput
                                        key={"auth_" + index}
                                        type="text"
                                        explainer={i18n.t('campaigns:transactions.fields.' + row.description)}
                                        value={row.field}
                                        outerClassName="mar-b25"
                                        iconRight={row.required && <UILIB.SquareChip className="square-chip-orange" value={i18n.t('campaigns:transactions.required')} />}
                                    />
                                })
                                }
                                <h4 className="mar-b25">{i18n.t('campaigns:transactions.response')}</h4>
                                {params.response.map((row, index) => {
                                    return <UILIB.TextInput
                                        key={"authR_" + index}
                                        type="text"
                                        explainer={i18n.t('campaigns:transactions.fields.' + row.description)}
                                        value={row.field}
                                        outerClassName="mar-b25"
                                        iconRight={row.required && <UILIB.SquareChip className="square-chip-orange" value={i18n.t('campaigns:transactions.required')} />}
                                    />

                                }
                                )}
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>

                    <UILIB.Paper >
                        <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignContent: "center" }}>
                            <h4 className="no-marg">{i18n.t('campaigns:transactions.sampleCode')}</h4>
                            <UILIB.ButtonSelect
                                headerText={this.state.code}
                                iconRight={<UILIB.Icons icon="smallArrowDown" />}
                                data={this.state.options.map((option) => {
                                    return <a onClick={() => this.setState({ code: option })}>{option}</a>
                                })} />
                        </div>


                        <UILIB.Paper style={{ padding: "0px", position: "relative" }}>

                            <div style={{
                                position: "absolute", top: "15px", right: "15px", zIndex: "1"
                            }}>
                                <UILIB.Icons icon="copyClipboard" style={{ width: "20px", height: "20px" }}
                                    alt={i18n.t('campaigns:transactions.copyToClipBoard')} title={i18n.t('campaigns:transactions.copyToClipBoard')}
                                    onClick={() => this.copyToClipboard(this.state.codes[this.state.code])}
                                />
                            </div>
                            <AceEditor
                                mode={this.state.code === 'curl' ? 'powershell' : this.state.code}
                                theme="xcode"
                                name="code"
                                width="100%"

                                readOnly={true}
                                wrapEnabled={true}
                                showPrintMargin={false}
                                editorProps={{ $blockScrolling: true }}
                                value={this.state.codes[this.state.code]}
                            />

                        </UILIB.Paper>

                    </UILIB.Paper>


                </UILIB.Column>
                <UILIB.Column xs={12} md={6} margin={0}>
                    <UILIB.Paper >
                        <h4 className="mar-b25">Send Via SMTP</h4>

                        <UILIB.TextInput
                            type="text"
                            value={this.props.site.trackingDomain}
                            outerClassName="mar-b25"
                            label={i18n.t('campaigns:transactions.smtpHost')}
                            iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => this.copyToClipboard(this.props.site.trackingDomain)} alt={i18n.t('campaigns:transactions.copyToClipBoard')} title={i18n.t('campaigns:transactions.copyToClipBoard')} />}
                        />

                        <UILIB.TextInput
                            type="text"
                            value={"25 or 587"}
                            outerClassName="mar-b25"
                            label={i18n.t('campaigns:transactions.smtpPort')}

                        />

                        <div className="text-primary text-heavy mar-b25">SMTP Authentication</div>
                        <UILIB.TextInput
                            type="text"
                            value={this.props.account.id + "__" + this.props.user.userData.emailAddress}
                            outerClassName="mar-b25"
                            label={i18n.t('campaigns:transactions.userName')}
                            iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => this.copyToClipboard(this.props.account.id + "__" + this.props.user.userData.emailAddress)} alt={i18n.t('campaigns:transactions.copyToClipBoard')} title={i18n.t('campaigns:transactions.copyToClipBoard')} />}
                        />
                        <UILIB.TextInput
                            type="text"
                            value={i18n.t('campaigns:transactions.yourPasswordFor') + " " + this.props.site.siteName}
                            outerClassName="mar-b25"
                            label={i18n.t('campaigns:transactions.password')}
                        />

                        <div className="text-primary text-heavy mar-b25">{i18n.t('campaigns:transactions.extraOptions')}</div>
                        <div className="mar-b25">{i18n.t('campaigns:transactions.extraOptionsDesc')}</div>


                        <UILIB.TextInput
                            type="text"
                            explainer={i18n.t('campaigns:transactions.mpzOverwriteEnvelopeFromDesc')}
                            outerClassName="mar-b25"
                            value={this.props.site.siteId === 1 ? 'mpz_overwrite_envelope_from' : 'mp_overwrite_envelope_from'}
                            iconRight={<UILIB.Icons
                                icon="copyClipboard"
                                onClick={() => this.copyToClipboard(this.props.site.siteId === 1 ? 'mpz_overwrite_envelope_from' : 'mp_overwrite_envelope_from')}
                                alt={i18n.t('campaigns:transactions.copyToClipBoard')}
                                title={i18n.t('campaigns:transactions.copyToClipBoard')} />
                            }
                        />

                        <UILIB.TextInput
                            type="text"
                            explainer={i18n.t('campaigns:transactions.fields.trackOpensDesc')}
                            outerClassName="mar-b25"
                            value={this.props.site.siteId === 1 ? 'mpz_track_opens' : 'mp_track_opens'}
                            iconRight={<UILIB.Icons
                                icon="copyClipboard"
                                onClick={() => this.copyToClipboard(this.props.site.siteId === 1 ? 'mpz_track_opens' : 'mp_track_opens')}
                                alt={i18n.t('campaigns:transactions.copyToClipBoard')}
                                title={i18n.t('campaigns:transactions.copyToClipBoard')} />
                            }
                        />

                        <UILIB.TextInput
                            type="text"
                            explainer={i18n.t('campaigns:transactions.fields.trackLinksDesc')}
                            outerClassName="mar-b25"
                            value={this.props.site.siteId === 1 ? 'mpz_track_links' : 'mp_track_links'}
                            iconRight={<UILIB.Icons
                                icon="copyClipboard"
                                onClick={() => this.copyToClipboard(this.props.site.siteId === 1 ? 'mpz_track_links' : 'mp_track_links')}
                                alt={i18n.t('campaigns:transactions.copyToClipBoard')}
                                title={i18n.t('campaigns:transactions.copyToClipBoard')} />
                            }
                        />

                    </UILIB.Paper>

                </UILIB.Column>
            </UILIB.Row>



            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
        </div >
    }

};
import React, { Fragment, useEffect, useState } from 'react'
import axios from '~/data/http/axios'
import UILIB from '~/Common-Objects/Lib'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { useDispatch } from 'react-redux';
import i18n from '~/i18n'

export default function SegmentUsers({ id }) {
    const [segment, setSegment] = useState(null)
    const [users, setUsers] = useState(null)
    const [roles, setRoles] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        axios.get('/segment/' + id).then(res => {
            setSegment(res.data.Segments[0])
            setRoles(res.data.Segments[0].SegmentUserBinds)
        })
        axios.get('/accountMaster/users?orderBy=emailAddress').then(res => {
            setUsers(res.data.AccountUsers)
        })
    }, [])

    const changePermission = (userId, level) => {
        const index = roles.findIndex(r => r.AccountUserId === userId)
        if (index === -1) {
            setRoles([...roles, { AccountUserId: userId, SegmentId: segment.id, accessLevel: level }])
        } else {
            const _roles = [...roles]
            _roles[index].accessLevel = level
            setRoles(_roles)
        }
    }

    const changePublic = (e) => {
        setSegment({ ...segment, public: !segment.public })
    }

    const save = async () => {
        await axios.put('/segment/' + id, {
            public: segment.public,
            users: roles
        })
        cancel()
    }

    const cancel = () => {
        dispatch(siteMaster.alterSiteDrawer(false))
    }

    if (!users || !segment) return <UILIB.LoadingIcons iconType="2" />
    const permissions = ['NONE', 'READ', 'WRITE']

    return <div>

        <h4 className="mar-b25">{i18n.t('subscribers:segments.users.header')}</h4>

        <UILIB.CheckBox explainer={segment.public ? i18n.t('subscribers:segments.users.subheader1') : i18n.t('subscribers:segments.users.subheader2')} outerClassName="mar-b15" checked={!segment.public} onChange={changePublic}>{i18n.t('subscribers:segments.users.toggle')}</UILIB.CheckBox>


        {!segment.public && users.map(user => {
            const role = roles.find(r => r.AccountUserId === user.id)
            let text = ''
            if (user.roleId === 1) {
                text = 'ADMIN'
            } else if (role) {
                text = role.accessLevel
            } else {
                text = 'NONE'
            }
            return <UILIB.Row key={user.id} className="mar-b25">
                <UILIB.Column xs={6} style={{ overflow: 'auto' }}>{user.emailAddress}</UILIB.Column>
                <UILIB.Column xs={6} className={"form-group " + (user.roleId !== 1 && 'no-marg')}>
                    {user.roleId === 1 ? 'ADMIN' : <UILIB.Select data={permissions} value={text} onChange={(e) => changePermission(user.id, e.target.value)} />}
                </UILIB.Column>
            </UILIB.Row>
        })}

        <div className="row">
            <div className="col-xs-6">
                <UILIB.Button text={i18n.t('save')} className="button-primary button-fullwidth" onClick={save} iconLeft={<UILIB.Icons icon="tick" />} />
            </div>
            <div className="col-xs-6">
                <UILIB.Button text={i18n.t('cancel')} className="button-fullwidth" onClick={cancel} iconLeft={<UILIB.Icons icon="cross" />} />
            </div>
        </div>


    </div>
}
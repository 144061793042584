'use strict';


class TranspondSiteTracking {

    static async initialise(key) {

        if (!key)
            return Promise.reject("No key specified");

        try {
            let exists = this.checkScriptExists();
            if (!exists) {
                let url = "https://api.transpond.io/tracker?am=" + key;
                if (window.location.hostname === "localhost") {
                    url = "http://localhost:8081/tracker?am=" + key;
                } else if (window.location.hostname === "web.staging.dcloud.mpztesting.com") {
                    url = "https://api.staging.dcloud.mpztesting.com/tracker?am=" + key;
                }
                const script = document.createElement("script");
                script.src = url
                script.async = true;
                script.id = "transp-loader";
                document.body.appendChild(script);
            }
        }
        catch (err) {
            return Promise.reject(err);
        }
    }
    static checkScriptExists() {
        let exists = document.getElementById("transp-loader");
        if (exists) {
            return true;
        }
        else {
            return false;
        }
    }

    static pageChange(emailAddress, customFields, groupId, uuid, host, origin) {
        try {
            let exists = this.checkScriptExists();
            if (!exists) {
                return;
            }
            transPageChange(emailAddress, customFields, groupId, host, origin, uuid);
        }
        catch (err) { }
    }

    static manualPageChange(path, query, emailAddress, customFields, groupId, mpEventName, mpEventData, uuid, host, origin) {
        try {
            let exists = this.checkScriptExists();
            if (!exists) return;
            manualTracking(
                path,
                query,
                emailAddress,
                customFields,
                groupId,
                mpEventName,
                mpEventData,
                uuid,
                host,
                origin
            );
        }
        catch (err) { }
    }
}

export default TranspondSiteTracking;

import React, { Component } from 'react'
import Canvas from './canvas/canvas'

export default class DragDropStage extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.clickStage = this.clickStage.bind(this);

    }

    clickStage(event) {
        this.props.deselectElement();
    }
    componentDidMount() {
    }

    render() {
        var templateBodyBackgroundColor = "";
        if (this.props.templateJson && this.props.templateJson.globalVariables && this.props.templateJson.globalVariables.bodyBg && this.props.templateJson.globalVariables.bodyBg.value) {
            templateBodyBackgroundColor = this.props.templateJson.globalVariables.bodyBg.value;
        }
        return <div className="contentArea" id="dd_contentArea" onClick={this.clickStage} style={{ backgroundColor: templateBodyBackgroundColor }}>

            <div className="canvas" style={{ maxWidth: this.props.screenSize }}>

                <Canvas elementsData={this.props.templateJson} updateElementsData={this.props.updateElementsData} clickElement={this.props.clickElement} deselectElement={this.props.deselectElement} selectedElement={this.props.selectedElement} saveFaveColor={this.props.saveFaveColor} updateBlocks={this.props.updateBlocks} showGuideLines={this.props.showGuideLines} templateId={this.props.templateId} />

            </div>

        </div>



    }
}

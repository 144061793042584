import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';

export default class DivEditable extends Component {
    constructor(props) {
        super(props)

    }

    render() {

        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "div", this.props.localVariables)
        }

        var thisContent = "";
        if (this.props.elementData.content && this.props.elementData.content.value) {
            thisContent = this.props.elementData.content.value;
            if (thisContent.indexOf('_localVariables_') >= 0) {
                thisContent = DragDropFunctions.lookupLocalVariables(this.props.localVariables, thisContent);
            }
            return <span {...eleProps}>
                <span dangerouslySetInnerHTML={{ __html: thisContent }} />
                {this.props.children}
            </span>
        }

        return <span {...eleProps}>
            {this.props.children}
        </span>




    }
}
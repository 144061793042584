import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";

function Breadcrumbs({ className, children, ...rest }) {
  const compoundChildren = React.Children.map(children, (child, index) => {
    switch (child.type) {
      case Breadcrumbs.Item: {
        return React.cloneElement(child, {
          current: index + 1 === React.Children.count(children),
        });
      }
    }
  });

  const classes = classnames(styles.root, className);

  return (
    <nav className={classes} aria-label="Breadcrumbs" {...rest}>
      <ol className="faux-list">{compoundChildren}</ol>
    </nav>
  );
}

Breadcrumbs.Item = ({ current, to, children, ...rest }) => {
  return (
    <li className={styles.breadcrumb} {...rest}>
      {current ? (
        <span className={styles.breadcrumbInner}>{children}</span>
      ) : (
        <>
          <Link className={styles.breadcrumbInner} to={to}>
            {children}
          </Link>
          <UILIB.Icon className={styles.divider} name="chevron-right" />
        </>
      )}
    </li>
  );
};

export default Breadcrumbs;

import React from 'react'
import WhiteBoxHeader from '../WhiteBoxHeader/WhiteBoxHeader'
import UILIB from '~/Common-Objects/Lib.jsx';

export default class WhiteBox extends React.Component {

    render() {


        if (this.props.isHidden === true) {
            return null
        }
        return <UILIB.Paper onClick={this.props.onClick} style={this.props.style} className={this.props.boxType}>
            {this.props.header ? <WhiteBoxHeader>
                {this.props.header}
            </WhiteBoxHeader> : null}

            {this.props.children}

        </UILIB.Paper>
    }


}

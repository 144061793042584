import React, { useEffect, useRef, useState } from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import i18n from '~/i18n';

export default function UrlInput({ job, setJob = () => { }, next = () => { } }) {

    const timer = useRef(null)
    const [url, setUrl] = useState('')
    const [analyzing, setAnalyzing] = useState(false)

    const [results, setResults] = useState(null)
    const [error, setError] = useState('')
    const [progressPct, setProgressPct] = useState(-1);

    const start = async () => {
        try {
            setResults(null)
            setError('')
            setAnalyzing(true)

            let uri = url
            if (uri && uri.indexOf('http') !== 0) uri = 'https://' + uri
            const res = await axios.post('/accountMaster/siteanalyzer', { url: uri })
            setProgressPct(0);
            setJob(res.data)
        } catch (e) {
            setError(i18n.t('templates:brandAnalyzer.urlPicker.invalidWebsite'))
            setAnalyzing(false)
        }
    }


    const loop = async () => {

        const res = await axios.get('/accountMaster/siteanalyzer/' + job.id)
        setJob(res.data)
        setUrl(res.data.url)
        if (res.data.error) {
            setError(i18n.t('templates:brandAnalyzer.urlPicker.errorUnableToRetrieve'))
            setAnalyzing(false)
            setProgressPct(0);
            return;
        }

        let newProg = progressPct;
        if (progressPct === 0) { newProg = 30 }
        else if (progressPct === 30) { newProg = 60 }
        else if (progressPct === 60) { newProg = 90 }

        setProgressPct(newProg);

        if (res.data.status === "complete" && progressPct === 90) {
            setProgressPct(100);
            setAnalyzing(false);
            setResults(res.data.results)
            next();
        }
    }

    useEffect(() => {
        if (analyzing && job.id) {
            timer.current = setTimeout(() => { loop() }, 1000)
        }

        return function () {
            if (timer.current) clearTimeout(timer.current)
        }
    }, [job])


    return (
        <>
            <div className='mar-b15 text-center text-heavy'>{i18n.t('templates:brandAnalyzer.urlPicker.headerText')}</div>
            <div className='mar-b25 text-center'>{i18n.t('templates:brandAnalyzer.urlPicker.headerText2')}</div>
            <div className='mar-b25 text-center'>{i18n.t('templates:brandAnalyzer.urlPicker.subHeaderText')}</div>
            <div className="quickFlex mar-b25" style={{ alignItems: 'flex-start', }}>
                <UILIB.TextInput
                    outerStyle={{ flexGrow: 1 }}
                    focus
                    value={url}
                    error={error}
                    onChange={e => setUrl(e.currentTarget.value)}
                    onEnterPress={start}
                    disabled={!!analyzing || !!results}
                    placeholder="yourwebsite.com" />
                <UILIB.Button
                    onClick={start}
                    disabled={analyzing || !!results}
                    className="button-primary mar-l10"
                    iconRight={<UILIB.Icons icon={analyzing ? "loading" : "arrowRight"} />}
                >{i18n.t('templates:brandAnalyzer.urlPicker.analyzeWebsite')}</UILIB.Button>
            </div>

            {(analyzing) && <>
                <div style={{ marginTop: "50px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                    <div style={{ width: "100%", maxWidth: "180px", display: "flex", justifyContent: "center" }}>
                        <CircularProgressbarWithChildren value={progressPct} styles={buildStyles({
                            pathColor: "#BDD38A",
                            trailColor: "#F8F3F1"
                        })}>
                            {progressPct === 0 && <img
                                src="https://cdn1.ourmailsender.com/siteContent/brandAnalyzer/brand-analyzer-colors-waiting.png"
                                style={{ width: "30%" }}
                            />}
                            {progressPct === 30 && <img
                                src="https://cdn1.ourmailsender.com/siteContent/brandAnalyzer/brand-analyzer-image-done.png"
                                style={{ width: "30%" }}
                            />}

                            {progressPct === 60 && <img
                                src="https://cdn1.ourmailsender.com/siteContent/brandAnalyzer/brand-analyzer-images-done.png"
                                style={{ width: "30%" }}
                            />}

                            {progressPct === 90 && <img
                                src="https://cdn1.ourmailsender.com/siteContent/brandAnalyzer/brand-analyzer-company-info-done.png"
                                style={{ width: "30%" }}
                            />}
                        </CircularProgressbarWithChildren>
                    </div>

                    <div className="mar-t25 text-center">
                        <div className="text-heavy">{i18n.t('templates:brandAnalyzer.urlPicker.analyzingWebsite')}</div>
                        <div>{(progressPct == 0 || progressPct === 30) && i18n.t('templates:brandAnalyzer.urlPicker.inspectingColourScheme')}</div>
                        <div>{progressPct == 60 && i18n.t('templates:brandAnalyzer.urlPicker.downloadingImages')}</div>
                        <div>{progressPct == 90 && i18n.t('templates:brandAnalyzer.urlPicker.inspectingCompany')}</div>
                        <div>{progressPct == 100 && i18n.t('templates:brandAnalyzer.urlPicker.complete')}</div>
                    </div>

                </div>
            </>
            }
        </>


    )
}
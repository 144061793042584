import React, { Component } from 'react';
import axios from '../../../../../data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import StripeAddCard from '~/pages/cp/includes/upgrade/stripeAddCard'

class AddHostedServerPay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageLoaded: false,
            manuallyAddingCard: false,
            paymentCards: [],
            defaultCard: "",
            paymentCardId: "",
            paymentMethod: "",
            serverService: {}
        }

        this.getPaymentCards = this.getPaymentCards.bind(this);
        this.buy = this.buy.bind(this);

    }

    componentWillMount() {
        this.getHostService(false)
    }

    getHostService() {
        axios.get("/mailServer/add/serverservice").then(data => {
            this.setState({ serverService: data.data })
            this.getPaymentCards();
        })
    }
    getPaymentCards() {
        axios.get('/accountMaster/paymentMethod/stripe').then(response => {

            var defaultCard;
            var paymentMethod = "card"
            if (response.data.customer && response.data.customer.default_source) {
                defaultCard = response.data.customer.default_source
                paymentMethod = "card"
            }
            this.setState({
                manuallyAddingCard: false,
                paymentCards: response.data.customer.sources.data,
                defaultCard,
                paymentCardId: defaultCard,
                paymentMethod,
                pageLoaded: true
            })
        }).catch(console.log)
    }

    buy() {
        this.setState({
            pageLoaded: false
        })
        var self = this;
        axios.post('/mailServer/add/purchase', {
            serverName: this.props.serverName,
            serverDomain: this.props.serverDomain,
            serverDomainName: this.props.serverDomainTxt
        }).then(response => {

            self.props.serverPurchased(self.props.serverName, self.props.serverDomainTxt, response.data.job);

        }).catch(err => {

            var error = "There was a problem processing your payment";

            if (err.data && err.data.error) error = err.data.error;
            this.setState({
                pageLoaded: true,
                paymentError: error
            })
        })
    }
    render() {

        if (!this.state.pageLoaded) return <div className="loading">Loading</div>

        var finalPrice = 0;

        if (this.state.serverService.chargeable && this.state.serverService.chargeable == true) finalPrice = 39.99;
        var selectedPaymentCard = this.state.paymentCardId;
        if (!selectedPaymentCard || !selectedPaymentCard.length) selectedPaymentCard = this.state.defaultCard;
        return <div>
            {this.state.paymentError && this.state.paymentError.length > 0 && <div className="mar-b25 text-red">{this.state.paymentError}</div>}


            <h4 className="mar-b25">Payment</h4>
            <div className="mar-b25">Dedicated servers cost £39.99 per month. Please use the following options to pay for the new server. Your payments will end when you delete the server.</div>


            <UILIB.Row className="mar-b25">
                <UILIB.Column xs={8} margin={0} className="mar-b10 text-right text-sml">Price:</UILIB.Column>
                <UILIB.Column xs={4} margin={0} className="mar-b10 text-right text-sml">£{finalPrice} per month</UILIB.Column>

                <UILIB.Column xs={8} className="text-right text-sml">Total to pay now:</UILIB.Column>
                <UILIB.Column xs={4} className="text-lg text-right">£{finalPrice}</UILIB.Column>
            </UILIB.Row >


            {
                (finalPrice > 0 && this.state.paymentMethod && this.state.paymentMethod == "card" && this.state.paymentCards && this.state.paymentCards.length > 0 && !this.state.manuallyAddingCard) &&
                <div className="mar-25">
                    {!!this.state.reloadingCard && <UILIB.LoadingIcons iconType="2" />}
                    {!this.state.reloadingCard && <div className=" mar-b0">

                        <UILIB.Select placeholder="Select an existing payment card..." data={this.state.paymentCards.map((card) => {
                            return { label: card.brand + " ending " + card.last4 + " (exp " + card.exp_month + "/" + card.exp_year + ")", value: card.id }
                        })} onChange={(event) => { this.setState({ selectedPaymentCard: event.target.value }) }} value={selectedPaymentCard} />
                        <div className="mar-t10" style={{ textAlign: "right", fontSize: "14px" }}>
                            or <a onClick={() => this.setState({ manuallyAddingCard: true })}>Add a new Card</a>
                        </div>
                    </div>}
                </div>
            }

            {
                (finalPrice > 0 && this.state.paymentMethod && this.state.paymentMethod == "card" && (!this.state.paymentCards || !this.state.paymentCards.length || this.state.manuallyAddingCard)) &&
                <div className="mar-b25">

                    <StripeAddCard close={() => this.getPaymentCards(true)} hasCloseButton={this.state.manuallyAddingCard} />

                </div>
            }

            {
                (finalPrice == 0 || (this.state.paymentCardId && this.state.paymentCardId.length > 0)) && <div>
                    <p className="mar-b20">By clicking the "Continue" button below you agree to our Terms and Conditions of use.</p>
                    {this.state.paymentError && this.state.paymentError.length > 0 && <div className="mar-b20 text-red">{this.state.paymentError}</div>}
                    <UILIB.Button text={"Pay £" + finalPrice} onClick={this.buy} />
                </div>
            }


        </div >
    }
}

export default AddHostedServerPay;
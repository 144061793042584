import React from 'react'
import BB_TopNav from "./includes/bb_topNav/bb_topNav"
import { connect } from 'react-redux';
import NpsScore from './includes/npsScore/npsScore';
import axios from '../../data/http/axios';
import Enable2fa from './includes/2fa';

@connect((store) => {
  return { accountUser: store.user, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

export default class ControlTemplateFP extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popup: "",
      show2Fa: false
    }

    this.doNpsScore = this.doNpsScore.bind(this);
    this.checkAccount = this.checkAccount.bind(this)
  }

  componentDidMount() {
    this.doNpsScore();
    this.checkAccount()
  }

  async checkAccount() {
    const [userRes, accountRes] = await Promise.all([axios.get('/accountUser/me'), axios.get('/accountMaster')])

    const account = accountRes.data.AccountMaster
    const user = userRes.data.AccountUserData
    if (account.options?.allUsersRequire2fa && !user.twoFAEnabled) {
      this.setState({ show2Fa: true, popup: <Enable2fa user={user} reload={this.checkAccount} /> })
    } else {
      if (this.state.show2Fa) {
        this.setState({ show2Fa: false, popup: "" })
      }
    }
  }

  doNpsScore() {
    if (!this.props.siteMaster || !this.props.siteMaster.npsEnabled || this.props.accountUser.userData.roleId !== 1) return
    if (this.props.accountUser && this.props.accountUser.loggedIn && this.props.accountUser.dataUpToDate && this.props.accountUser.userData) {
      axios.get('/accountMaster/nps').then(res => {
        if (res.data.active) {
          this.setState({
            popup: <NpsScore close={() => this.setState({ popup: "" })} />
          })
        }
      })
    }
  }

  render() {
    if (this.props.history.location.pathname === '/cp/capsule') return this.props.children

    return <BB_TopNav history={this.props.history}>
      <div className="container-fluid">{this.props.children}</div>
      {this.state.popup}
    </BB_TopNav>

  };
};

import React, { Component } from 'react';
import axios from '~/data/http/axios';
import ReactDOM from 'react-dom';
import MPZColorPicker from '../../../../rightTools/elementEditor/components/colorPickerComponent'
import DragDropFunctions from '../../../../dragDropFunctions';
import LinkHolder from './tools/linkHolder'
import MergeTags from './tools/mergeTags'
import Rangy from 'rangy/lib/rangy-selectionsaverestore';
import jQuery from 'jquery';

export default class Wysiwyg extends Component {

    constructor(props) {
        super(props);

        var fontsObject = {};
        for (var index in DragDropFunctions.fontArray) {
            var theFont = DragDropFunctions.fontArray[index]
            fontsObject[theFont.value.replace(/'/g, "")] = theFont.label;
        }

        this.state = {
            currentMenu: -1,
            currentTool: null,
            offsetPixels: 0,
            oTools: {},
            pageLoaded: false,
            fontsObject: fontsObject,
            selectedRange: null,
            globalColours: [],
            offsetMenuPixels: 0,
            resizedMenu: false
        }
        this.buttonClick = this.buttonClick.bind(this);
        this.menuClick = this.menuClick.bind(this);
        this.colorToolUpdate = this.colorToolUpdate.bind(this);
        this.loadTools = this.loadTools.bind(this);
        this.resizeScreen = this.resizeScreen.bind(this);
        this.linkToolUpdate = this.linkToolUpdate.bind(this);
        this.getSelectionCharacterOffsetWithin = this.getSelectionCharacterOffsetWithin.bind(this)


    }

    componentDidMount() {
        var globalColours = DragDropFunctions.getTemplateColours(this.props.elementsData.globalVariables);
        this.setState({ globalColours: globalColours })
        this.loadTools();
    }


    buttonClick(event, theTool, theVal, toolIndex) {

        var selectedRange = this.state.selectedRange;

        if (!event && !theVal) {
            return;
        }

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if ((theVal == null || theVal == undefined) && (event != undefined && event != null)) {
            var selectedRange = Rangy.saveSelection();
        }

        document.execCommand("styleWithCSS", false, true);
        var theRes;


        if (theTool.customCommand && theVal == null) {
            var theCustomVal = theTool.customCommand(this, theTool);
            var theCommand = theTool.command;
            if (typeof theCustomVal === "object") {
                theCommand = theCustomVal.command;
                theCustomVal = theCustomVal.value;
            }
            document.execCommand(theCommand, false, theCustomVal)
        }
        else {

            if (theVal && theVal != null && theVal.length) {
                if (theTool.command === "forecolor" || theTool.command == "createLink" || theTool.command == "insertText") {
                    //restore the original selected text before execing the command
                    Rangy.restoreSelection(this.state.selectedRange);
                    Rangy.removeMarkers(this.state.selectedRange)
                    document.querySelectorAll('.rangySelectionBoundary').forEach(function (a) {
                        a.remove()
                    })
                }

                if (theTool.command == "fontsize") {
                    // document.execCommand("fontSize", false, "6")
                    //clear all font sizing
                    var innerHtml = jQuery("#" + this.props.thisId).html();

                    if (innerHtml.indexOf("<div") != 0) {
                        innerHtml = "<div style='font-size: " + theVal + "'>" + innerHtml + "</div>";
                        jQuery("#" + this.props.thisId).html(innerHtml);
                    }
                    jQuery("#" + this.props.thisId).children().css("font-size", theVal);
                    jQuery("#" + this.props.thisId + " *").children().each(function (theEl, theEl2) {
                        var styleAttr = jQuery(theEl2).attr('style');
                        if (typeof styleAttr !== typeof undefined && styleAttr !== false) {
                            jQuery(theEl2).css("font-size", "");
                        }
                    });
                }
                else {
                    document.execCommand(theTool.command, false, theVal)
                }

            }
            else {
                if (theTool.command != "insertText" || (theTool.command == "insertText" && theVal)) {
                    document.execCommand(theTool.command)
                }
            }
        }
        var currentMenu = -1;
        var currentTool = null;
        if (theTool.values) {
            currentMenu = toolIndex;
            currentTool = "menu"
        }
        if (theTool.command === "createLink") {
            currentMenu = toolIndex;
            currentTool = "link"
        }
        if (theTool.command === "insertText") {
            currentMenu = toolIndex;
            currentTool = "tags"
        }
        if (theTool.command === "forecolor") {
            if (this.props.selectedProps.color.nodeType === "color") {
                document.execCommand("forecolor", false, null)
            }
            else {
                currentMenu = toolIndex;
                currentTool = "color"
            }
        }

        this.setState({ currentMenu: currentMenu, currentTool: currentTool, selectedRange: selectedRange, resizedMenu: false })
        this.props.focusedElementClick();
    }

    getSelectionCharacterOffsetWithin(element) {

        function getCaretPosition(node) {
            var range = window.getSelection().getRangeAt(0),
                preCaretRange = range.cloneRange(),
                caretPosition,
                tmp = document.createElement("div");

            preCaretRange.selectNodeContents(node);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            tmp.appendChild(preCaretRange.cloneContents());
            caretPosition = tmp.innerHTML.length;
            return caretPosition;
        }

        var textPosition = getCaretPosition(element),
            htmlContent = element.innerHTML,
            textIndex = 0,
            htmlIndex = 0,
            insideHtml = false,
            htmlBeginChars = ['&', '<'],
            htmlEndChars = [';', '>'];


        if (textPosition == 0) {
            return 0;
        }

        while (textIndex < textPosition) {

            htmlIndex++;

            // check if next character is html and if it is, iterate with htmlIndex to the next non-html character
            while (htmlBeginChars.indexOf(htmlContent.charAt(htmlIndex)) > -1) {
                // console.log('encountered HTML');
                // now iterate to the ending char
                insideHtml = true;

                while (insideHtml) {
                    if (htmlEndChars.indexOf(htmlContent.charAt(htmlIndex)) > -1) {
                        if (htmlContent.charAt(htmlIndex) == ';') {
                            htmlIndex--; // entity is char itself
                        }
                        // console.log('encountered end of HTML');
                        insideHtml = false;
                    }
                    htmlIndex++;
                }
            }
            textIndex++;
        }

        // in htmlIndex is caret position inside html
        return { start: Number(textPosition), end: Number(textPosition) + 2 };
    }
    menuClick(event, index) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ currentMenu: index })
    }


    linkToolUpdate(theVal, theTool) {

        this.buttonClick(null, theTool, theVal, undefined)
    }


    colorToolUpdate(theColor, theTool) {

        var finalCol = "";
        if (typeof theColor === "object") {
            finalCol = theColor.hex;
        }
        else {
            finalCol = theColor;
        }

        document.execCommand("foreColor", false, finalCol)

        Rangy.restoreSelection(this.state.selectedRange);
        // Rangy.restoreSelection(this.state.selectedRange);

        // var selectedNodes = [];
        // var sel = Rangy.getSelection();
        // sel.focusOffset = 0;
        // for (var i = 0; i < sel.rangeCount; ++i) {
        //     selectedNodes = selectedNodes.concat(sel.getRangeAt(i).getNodes());
        // }


        // if (selectedNodes[0] && selectedNodes[0].nodeName && selectedNodes[0].nodeName.toLowerCase() == "a" || selectedNodes[0].parentNode && selectedNodes[0].parentNode.nodeName.toLowerCase() == "a") {

        //     var range = Rangy.createRange();
        //     var startNode = selectedNodes[0];

        //     if (selectedNodes[0].parentNode && selectedNodes[0].parentNode.nodeName.toLowerCase() == "a") {
        //         startNode = selectedNodes[0].parentNode;
        //     }

        //     var endNode = selectedNodes[selectedNodes.length - 1]
        //     var endOffSet = sel.focusOffset;

        //     range.setStartBefore(startNode);
        //     range.setEnd(endNode, endOffSet);

        //     sel.removeAllRanges();
        //     sel.addRange(range);
        // }
        // if (selectedNodes[selectedNodes.length - 1] && selectedNodes[selectedNodes.length - 1].nodeName && selectedNodes[selectedNodes.length - 1].nodeName.toLowerCase() == "a" || selectedNodes[selectedNodes.length - 1].parentNode && selectedNodes[selectedNodes.length - 1].parentNode.nodeName.toLowerCase() == "a") {

        //     var range = Rangy.createRange();
        //     var endNode = selectedNodes[selectedNodes.length - 1];

        //     if (selectedNodes[selectedNodes.length - 1].parentNode && selectedNodes[selectedNodes.length - 1].parentNode.nodeName.toLowerCase() == "a") {
        //         endNode = selectedNodes[selectedNodes.length - 1].parentNode;
        //     }

        //     var startNode = selectedNodes[0]
        //     var startOffSet = sel.anchorOffset;

        //     range.setStart(startNode, startOffSet);
        //     range.setEndAfter(endNode);

        //     sel.removeAllRanges();
        //     sel.addRange(range);
        // }


        // document.execCommand("foreColor", false, finalCol)


        // selectedNodes.forEach(tN => {
        //     if (tN.nodeName && tN.nodeName.toLowerCase() == "a") {
        //         tN.style.color = finalCol;
        //         tN.innerHTML = tN.text;
        //     }
        // })


        Rangy.removeMarkers(this.state.selectedRange)
        document.querySelectorAll('.rangySelectionBoundary').forEach(function (a) {
            a.remove()
        })

        //this.buttonClick(null, theTool, finalCol)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.theEvent != undefined) {
            this.setState({ currentMenu: -1 })
        }
    }

    componentDidMount() {
        this.loadTools();
        window.addEventListener("scroll", this.resizeScreen, true);

    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.resizeScreen, true);
        document.querySelectorAll('.rangySelectionBoundary').forEach(function (a) {
            a.remove()
        })
        if (this.state.selectedRange != null) {
            Rangy.removeMarkers(this.state.selectedRange)
        }
    }

    componentDidUpdate() {

        //re position drop downs that need to be (For example long ones that need to move left)
        if (this.state.currentTool === "menu" && this.state.resizedMenu === false) {
            var theTool = this.state.oTools.buttons[this.state.currentMenu];
            if (theTool && theTool.hasOwnProperty("menuPosition") && theTool.menuPosition === "left") {
                var parentWidth = document.getElementById("selectedTool").getBoundingClientRect().width;
                var menuWidth = document.getElementById("wysiDropDown").getBoundingClientRect().width - parentWidth;
                this.setState({ offsetMenuPixels: menuWidth * -1, resizedMenu: true });
            }
            else {
                this.setState({ offsetMenuPixels: 0, resizedMenu: true });
            }
        }

    }



    loadTools() {

        var buttons = [];

        if (this.props.wysiwygMode != "simple") {
            buttons.push({
                "command": "formatblock",
                "text": "Format",
                "cssprop": "formatBlock",
                "values": {
                    "<div>": "None",
                    "<h1>": "Heading 1 (H1)",
                    "<h2>": "Heading 2 (H2)",
                    "<h3>": "Heading 3 (H3)",
                    "<h4>": "Heading 4 (H4)",
                }
            })
            buttons.push({
                "command": "fontname",
                "text": "Font Type",
                "cssprop": "font-family",
                "values": this.state.fontsObject
            });
            buttons.push({
                "command": "fontsize",
                "text": "Size",
                "cssprop": "font-size",
                "values": {
                    "8px": "8px",
                    "9px": "9px",
                    "10px": "10px",
                    "11px": "11px",
                    "12px": "12px",
                    "13px": "13px",
                    "14px": "14px",
                    "16px": "16px",
                    "18px": "18px",
                    "20px": "20px",
                    "22px": "22px",
                    "24px": "24px",
                    "26px": "26px",
                    "28px": "28px",
                    "30px": "30px",
                    "34px": "34px",
                    "38px": "38px",
                    "40px": "40px",
                    "44px": "44px",
                    "48px": "48px",
                    "50px": "50px",
                    "54px": "54px",
                    "58px": "58px",
                    "60px": "60px",
                    "64px": "64px",
                    "68px": "68px",
                    "70px": "70px",
                    "74px": "74px",
                    "78px": "78px",
                    "80px": "80px",
                    "84px": "84px",
                    "88px": "88px",
                    "90px": "90px",
                    "94px": "94px",
                    "98px": "98px",
                    "100px": "100px",
                    "110px": "110px",
                    "120px": "120px"
                }
            });
        }

        buttons.push({
            "text": "Bold",
            "command": "bold",
            "icon": "icon-bold",
            "cssprop": "font-weight",
            "cssval": "bold"
        });

        buttons.push({
            "text": "Strikethrough",
            "command": "strikeThrough",
            "icon": "icon-strikethrough",
            "cssprop": "text-decoration-line",
            "cssval": "line-through"
        });

        buttons.push({
            "text": "Italic",
            "command": "italic",
            "icon": "icon-italic",
            "cssprop": "font-style",
            "cssval": "italic"
        });

        buttons.push({
            "text": "Underline",
            "command": "underline",
            "icon": "icon-underline",
            "cssprop": "text-decoration-line",
            "cssval": "underline"
        });
        if (this.props.wysiwygMode != "simple") {
            buttons.push({
                "text": "Delete indentation",
                "command": "outdent",
                "icon": "icon-indent-decrease",
                "cssprop": "",
                "cssval": ""
            });
        }
        if (this.props.wysiwygMode != "simple") {
            buttons.push({
                "text": "Add indentation",
                "command": "indent",
                "icon": "icon-indent-increase",
                "cssprop": "",
                "cssval": ""
            });
        }
        if (this.props.wysiwygMode != "simple") {
            buttons.push({
                "text": "Dotted list",
                "command": "insertunorderedlist",
                "icon": "icon-list",
                "cssprop": "ul",
                "cssval": "ul"
            });
            buttons.push({
                "text": "Numbered list",
                "command": "insertorderedlist",
                "icon": "icon-list2",
                "cssprop": "ol",
                "cssval": "ol"
            });
        }
        if (this.props.wysiwygMode != "simple") {
            buttons.push({
                "text": "Left align",
                "command": "justifyleft",
                "icon": "icon-align-left",
                "cssprop": "text-align",
                "cssval": ["", "left"],
            });
        }
        if (this.props.wysiwygMode != "simple") {
            buttons.push({
                "text": "Center align",
                "command": "justifycenter",
                "icon": "icon-align-center-vertical",
                "cssprop": "text-align",
                "cssval": "center"
            });
        }
        if (this.props.wysiwygMode != "simple") {
            buttons.push({
                "text": "Right align",
                "command": "justifyright",
                "icon": "icon-align-right",
                "cssprop": "text-align",
                "cssval": "right"
            });
        }
        if (this.props.wysiwygMode != "simple") {
            buttons.push({
                "text": "Hyperlink",
                "command": "createLink",
                "icon": "icon-link",
                "cssprop": "a",
                "cssval": "a"
            });
        }
        if (this.props.wysiwygMode != "simple") {
            buttons.push({
                "text": "Unlink",
                "command": "unlink",
                "icon": "icon-unlink",
                "cssprop": "a",
                "cssval": "a",
                "unselectedHidden": true
            });
        }
        if (this.props.wysiwygMode != "simple") {
            buttons.push({
                "text": "Color",
                "command": "forecolor",
                "cssprop": "color",
                "cssval": "color",
            });
        }
        if (this.props.wysiwygMode != "simple") {
            buttons.push({
                "text": "Tags",
                "fixedIconText": "Tags",
                "command": "insertText"
            });
        }

        var oTools = { "buttons": buttons };
        this.setState({ oTools: oTools, pageLoaded: true }, this.resizeScreen);

    }

    resizeScreen() {

        var newX = 0;
        var newY = 0;
        var marginTop = 38;
        if (!this.props.theEle || !this.props.theEle.parentNode || !document.getElementById("wysiEditor2")) return;
        var parent = this.props.theEle.parentNode;

        var ourEditableContent = document.getElementById(this.props.thisId);
        function getSelectedParent(parent) {
            if (parent && parent.className == "elementHolder") {

                return parent;
            }
            else {
                if (!parent.parentNode) return;
                return getSelectedParent(parent.parentNode)
            }
        }
        var ourSelectedParent = getSelectedParent(parent);
        var ddToolBarHeight = document.getElementById("dragDropToolBar").getBoundingClientRect().height;
        var ourHeight = document.getElementById("wysiEditor2").getBoundingClientRect().height;
        var ourY = ourSelectedParent.getBoundingClientRect().top - ddToolBarHeight - ourHeight;
        var contentY = (document.getElementById(this.props.thisId).getBoundingClientRect().top + document.getElementById(this.props.thisId).getBoundingClientRect().height) - ddToolBarHeight - ourHeight;

        if (ourY < 0) newY = (ourY * -1);
        if (contentY < 0) return;

        newY -= marginTop;


        newX = ourSelectedParent.getBoundingClientRect().width / 2;
        newX -= document.getElementById("wysiEditor2").getBoundingClientRect().width / 2;

        document.getElementById("wysiEditor2").style.top = newY + "px";
        document.getElementById("wysiEditor2").style.left = newX + "px";
    }

    render() {
        if (this.state.pageLoaded) {

            this.resizeScreen();
            return (

                <div className="wysiEditor" id="wysiEditor2">
                    {this.state.selectedStart} {this.state.selectedEnd}
                    {this.state.oTools.buttons.map((theTool, toolIndex) => {

                        var selected = "";
                        var currProp = theTool.cssprop;
                        var currVal = "";
                        var toolSelectedID = "";
                        var toolValue = "";
                        var rawValue = "";
                        if (typeof currProp === "object") {
                            for (var each in currProp) {
                                if (Object.keys(this.props.selectedProps).includes(currProp[each])) {
                                    if (typeof theTool.values === "object") {
                                        if (Object.keys(theTool.values).includes(this.props.selectedProps[currProp[each]].nodeType)) {
                                            currVal = this.props.selectedProps[currProp[each]].nodeType;
                                        }
                                    }
                                    else {
                                        currVal = this.props.selectedProps[currProp[each]].nodeType;
                                    }
                                }
                            }
                        }
                        else {

                            if (this.props.selectedProps[currProp] && this.props.selectedProps[currProp].hasOwnProperty("nodeType")) {
                                currVal = this.props.selectedProps[currProp].nodeType;
                                if (this.props.selectedProps[currProp].hasOwnProperty("nodeContent")) {
                                    toolValue = this.props.selectedProps[currProp].nodeContent;
                                }
                            }
                            if (theTool.cssprop === "color") {
                                if (currVal === "" || currVal === undefined || currVal === null) {
                                    if (this.props.theEle) {
                                        currVal = this.props.theEle.style.color
                                    }
                                    else {
                                        currVal = "#FF0000"
                                    }
                                }
                                rawValue = currVal;
                                currVal = <div style={{ border: "1px solid grey", boxSizing: "border-box", backgroundColor: currVal, width: "14px", height: "14px", display: "inline-block" }} />
                            }
                            if (theTool.text == "Tags") {
                                currVal = "Tags";
                            }
                            if (currVal === "" && !theTool.icon) {
                                currVal = Object.keys(theTool.values)[0];
                            }


                            if (this.props.selectedProps[currProp] && this.props.selectedProps[currProp].nodeType === theTool.cssval) {
                                selected = " selected";
                            }
                            if (typeof theTool.cssval === "object") {
                                if (theTool.cssval.includes(currVal)) {
                                    selected = " selected";
                                }
                            }
                        }

                        if (theTool.values && this.state.currentTool === "menu" && this.state.currentMenu === toolIndex) {
                            toolSelectedID = "selectedTool"
                        }

                        if (selected === "" && theTool.unselectedHidden) {
                            return;
                        }
                        if (theTool.unselectedHidden) {
                            selected = "";
                        }
                        var currValDisplayFixedWidth = {};
                        if (theTool.command == "fontname") {
                            currValDisplayFixedWidth = { maxWidth: "90px", width: "90px", textAlign: "left" };
                        }
                        currValDisplayFixedWidth.lineHeight = "normal";

                        var currValDisplay = currVal;
                        if (theTool.values && theTool.command == "formatblock") {
                            currValDisplay = theTool.values[currVal];
                            if (!currValDisplay || currValDisplay == "" || currValDisplay == " " || currValDisplay == undefined || currValDisplay == "None") {
                                currValDisplay = theTool.text
                            }
                        }
                        if (currValDisplay && typeof currValDisplay == "string" && currValDisplay.length > 10) currValDisplay = currValDisplay.substring(0, 9) + "..";
                        if (theTool.fixedIconText) {
                            currValDisplay = theTool.fixedIconText;
                            selected = "";
                            toolSelectedID = "";
                        }

                        return <div key={"wysiTo-" + toolIndex} className={"wysiIconHolder" + selected} id={toolSelectedID} onMouseDown={(event) => { this.buttonClick(event, theTool, null, toolIndex) }} alt={theTool.text} title={theTool.text} style={currValDisplayFixedWidth}>

                            {theTool.icon && <span className={theTool.icon}></span>}
                            {!theTool.icon && currValDisplay}
                            <div className="wysiFloatTool" id={"wysitool-" + theTool.text}></div>

                            {theTool.values && this.state.currentTool === "menu" && this.state.currentMenu === toolIndex &&
                                <div className={"wysiDropDown " + theTool.menuPosition} id="wysiDropDown" style={{ left: this.state.offsetMenuPixels + "px" }}>
                                    {Object.keys(theTool.values).map((theVal, tIndx) => {
                                        var optSelected = "";
                                        if (theVal === currVal || theTool.values[theVal] === currVal) {
                                            optSelected = " selected";
                                        }
                                        if (theTool.command == "fontname") {
                                            return <div key={"wysiT-" + tIndx} className={"wysiDropDownOpt" + optSelected} onMouseDown={(event) => { this.buttonClick(event, theTool, theVal) }} style={{ fontFamily: theTool.values[theVal], fontSize: "14px" }}>{theTool.values[theVal]}</div>
                                        }
                                        return <div key={"wysiT-" + tIndx} className={"wysiDropDownOpt" + optSelected} onMouseDown={(event) => { this.buttonClick(event, theTool, theVal) }}>{theTool.values[theVal]}</div>
                                    })}
                                </div>
                            }
                            {this.state.currentTool === "link" && this.state.currentMenu === toolIndex && <LinkHolder linkToolUpdate={(val) => { this.linkToolUpdate(val, theTool) }} currVal={toolValue} stopBlur={this.props.stopBlur} startBlur={this.props.startBlur} />}
                            {this.state.currentTool === "color" && this.state.currentMenu === toolIndex && <MPZColorPicker color={rawValue} onChange={(val) => { this.colorToolUpdate(val, theTool) }} disableAlpha={true} globalColours={this.state.globalColours} favoriteColors={this.props.elementsData.globalVariables.favoriteColors} saveFaveColor={this.props.saveFaveColor} />}
                            {this.state.currentTool === "tags" && this.state.currentMenu === toolIndex && <MergeTags mergeToolUpdate={(val) => { this.linkToolUpdate(val, theTool) }} stopBlur={this.props.stopBlur} startBlur={this.props.startBlur} />}
                        </div>
                    })}
                </div>
            )
        }
        else {
            return <div></div>
        }
    }
}
import React from "react";
import * as styles from "./index.module.scss";

import ArrowLeft from "./svg/arrowLeft";
import ArrowRight from "./svg/arrowRight";
import Chat from "./svg/chat";
import Check from "./svg/check";
import ChevronDown from "./svg/chevronDown";
import ChevronLeft from "./svg/chevronLeft";
import ChevronRight from "./svg/chevronRight";
import ChevronUp from "./svg/chevronUp";
import Cog from "./svg/cog";
import Copy from "./svg/copy";
import Cross from "./svg/cross";
import CrossCircle from "./svg/crossCircle";
import CrossLarge from "./svg/crossLarge";
import DotsVertical from "./svg/dotsVertical";
import Download from "./svg/download";
import DropdownArrow from "./svg/dropdownArrow";
import EmojiSmile from "./svg/emojiSmile";
import ExternalLink from "./svg/externalLink";
import Inbox from "./svg/inbox";
import Loading from "./svg/loading";
import Logout from "./svg/logout";
import Magnifier from "./svg/magnifier";
import MailOpen from "./svg/mailOpen";
import MessageReadCheck from "./svg/messageReadCheck";
import MessageSentCheck from "./svg/messageSentCheck";
import Office from './svg/office';
import PaperClip from "./svg/paperClip";
import Pencil from "./svg/pencil";
import People3 from "./svg/people3";
import Plus from "./svg/plus";
import Refresh from "./svg/refresh";
import Sparkle from "./svg/sparkle";
import Stack from "./svg/stack";
import Star from "./svg/star";
import StarOutline from "./svg/starOutline";
import Swatch from "./svg/swatch";
import Trash from "./svg/trash";
import Person from "./svg/person";
import Upload from "./svg/upload";
import Replies from "./svg/replies";
import Facebook from './svg/facebook';

export default function Icon({ name, ...rest }) {
  const iconProps = {
    className: styles.root,
    ...rest,
  };

  switch (name) {
    case "arrow-left":
      return <ArrowLeft {...iconProps} />;

    case "arrow-right":
      return <ArrowRight {...iconProps} />;

    case "chat":
      return <Chat {...iconProps} />;

    case "check":
      return <Check {...iconProps} />;

    case "chevron-down":
      return <ChevronDown {...iconProps} />;

    case "chevron-left":
      return <ChevronLeft {...iconProps} />;

    case "chevron-right":
      return <ChevronRight {...iconProps} />;

    case "chevron-up":
      return <ChevronUp {...iconProps} />;

    case "cog":
      return <Cog {...iconProps} />;

    case "copy":
      return <Copy {...iconProps} />;

    case "cross":
      return <Cross {...iconProps} />;

    case "cross-circle":
      return <CrossCircle {...iconProps} />;

    case "cross-large":
      return <CrossLarge {...iconProps} />;

    case "dots-vertical":
      return <DotsVertical {...iconProps} />;

    case "download":
      return <Download {...iconProps} />;

    case "dropdown-arrow":
      return <DropdownArrow {...iconProps} />;

    case "emoji-smile":
      return <EmojiSmile {...iconProps} />;

    case "external-link":
      return <ExternalLink {...iconProps} />;

    case "inbox":
      return <Inbox {...iconProps} />;

    case "logout":
      return <Logout {...iconProps} />;

    case "loading":
      return <Loading {...iconProps} />;

    case "magnifier":
      return <Magnifier {...iconProps} />;

    case "mail-open":
      return <MailOpen {...iconProps} />;

    case "message-read-check":
      return <MessageReadCheck {...iconProps} />;

    case "message-sent-check":
      return <MessageSentCheck {...iconProps} />;

    case "office":
      return <Office {...iconProps} />;

    case "paper-clip":
      return <PaperClip {...iconProps} />;

    case "pencil":
      return <Pencil {...iconProps} />;

    case "people3":
      return <People3 {...iconProps} />;

    case "plus":
      return <Plus {...iconProps} />;

    case "refresh":
      return <Refresh {...iconProps} />;

    case "sparkle":
      return <Sparkle {...iconProps} />;

    case "stack":
      return <Stack {...iconProps} />;

    case "star":
      return <Star {...iconProps} />;

    case "star-outline":
      return <StarOutline {...iconProps} />;

    case "swatch":
      return <Swatch {...iconProps} />;

    case "trash":
      return <Trash {...iconProps} />;

    case "person":
      return <Person {...iconProps} />;
    case "upload":
      return <Upload {...iconProps} />;
    case "replies":
      return <Replies {...iconProps} />;
    case "facebook":
      return <Facebook {...iconProps} />;
  }

  console.error(`Unknown icon name: ${name}`);

  return null;
}

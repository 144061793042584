import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})
export default class IncGoDeletSub extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
        };

        this.deleteSub = this.deleteSub.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    deleteSub() {
        var subscriberID = this.props.subscriberID;
        var self = this;

        var self = this;
        axios.delete('/subscriber/' + subscriberID).then((data) => {
            self.props.removedSubscriber("delete");
            self.closeDrawer();
        })
    }

    render() {

        return <div>


            <h4 className="mar-b25">{i18n.t('subscribers:delete.header')}</h4>
            <div className="mar-b25">{i18n.t('subscribers:delete.subHeader')}</div>

            <UILIB.Button
                text={i18n.t('subscribers:delete.button')}
                className="button-red"
                onClick={this.deleteSub}
                iconRight={<UILIB.Icons icon="bin" />}
            />

        </div>
    }
};


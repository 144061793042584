import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';
import textFunctions from '~/Classes/textFunctions';
class ReportSizeWarning extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }

    }


    render() {

        var warningText = i18n.t('campaigns:reports.sizeWarning.warningText');
        warningText = warningText.replace(/\[FILESIZE\]/g, textFunctions.checkForHtml(this.props.fileSize))
        return <div>
            <h4 className="mar-b25">{i18n.t('campaigns:reports.sizeWarning.header')}</h4>
            <div className="mar-b25" dangerouslySetInnerHTML={{ __html: warningText }}></div>
            <div className="quickFlex">
                <UILIB.Button text={i18n.t('campaigns:reports.sizeWarning.download')} className="button-primary mar-r10" onClick={this.props.actuallyDownload} />
                <UILIB.Button text={i18n.t('campaigns:reports.sizeWarning.forgetIt')} onClick={this.props.close} />
            </div>
        </div>


    }
}

export default ReportSizeWarning;
import React from 'react'

export default class IconsTick extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.707 5.29303C16.8945 5.48056 16.9998 5.73487 16.9998 6.00003C16.9998 6.26519 16.8945 6.5195 16.707 6.70703L8.70701 14.707C8.51948 14.8945 8.26517 14.9998 8.00001 14.9998C7.73485 14.9998 7.48054 14.8945 7.29301 14.707L3.29301 10.707C3.11085 10.5184 3.01006 10.2658 3.01234 10.0036C3.01461 9.74143 3.11978 9.49062 3.30519 9.30521C3.4906 9.1198 3.74141 9.01464 4.00361 9.01236C4.26581 9.01008 4.51841 9.11087 4.70701 9.29303L8.00001 12.586L15.293 5.29303C15.4805 5.10556 15.7348 5.00024 16 5.00024C16.2652 5.00024 16.5195 5.10556 16.707 5.29303Z" fill={color} />
        </svg>
    }
}        
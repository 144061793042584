import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import i18n from '~/i18n'
import TemplateImageLoader from '~/pages/cp/automation/addNew/templateImageLoader.jsx'

@connect((store) => {
    return { accountMaster: store.accountMaster }
})
class FormConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {}
        }
        this.updateField = this.updateField.bind(this);
        this.checkForm = this.checkForm.bind(this);
        this.edit = this.edit.bind(this)
        this.next = this.next.bind(this)
    }

    componentDidMount() {
        this.checkForm()
    }

    updateField(ev, options) {
        var form = this.props.form;
        if (options) {
            form.options[ev.currentTarget.name] = ev.currentTarget.value
        } else {
            form[ev.currentTarget.name] = ev.currentTarget.value
        }
        this.checkForm()
        this.props.update()
    }

    checkForm() {
        var errors = {}
        var options = this.props.form.options;

        if (options.onConfirm === 'redirect' && !options.confirmedRedirectUrl) errors.confirmedRedirectUrl = i18n.t('form:step5.errUrl');
        this.setState({
            errors
        })
    }

    edit() {
        this.props.save().then(() => {
            var redirect = '/cp/groups/' + this.props.groupId + '/forms/create/' + this.props.form.id + '/6';
            this.props.history.push('/cp/templates/add/dragdrop?form=false&groupId=' + this.props.groupId + '&formId=' + this.props.form.id + '&page=confirmed&redirect=' + redirect);
        })
    }

    next() {
        this.props.form.wizard.step5 = true
        this.props.update()
        if (this.props.form.options.onConfirm === 'landing') {
            this.edit()
        } else {
            this.props.changeStep(6, true)
        }
    }


    render() {
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} md={4} className="mar-b25">
                        <div className="campaign-thumbnail" style={{ width: "100%", height: "100%", minHeight: "150px" }}>
                            <TemplateImageLoader imageUrl={this.props.screenshotUrl} form={this.props.form.formType === 1} />
                        </div>
                    </UILIB.Column>
                    <UILIB.Column xs={12} md={8}>
                        <UILIB.Paper>
                            <h4 className="mar-b25">{this.props.form.options?.serve === 'emailLanding' ? 'You must have a landing page to show the file downloads' : i18n.t('form:step5.header')}</h4>


                            <UILIB.Select outerClassName="mar-b25" name="onConfirm" value={this.props.form.options.onConfirm} data={[
                                { label: i18n.t('form:step5.landing'), value: 'landing' },
                                { label: i18n.t('form:step5.redirect'), value: 'redirect', disabled: this.props.form.options?.serve === 'emailLanding' }
                            ]} onChange={ev => this.updateField(ev, true)} />


                            {this.props.form.options.onConfirm !== 'landing' &&
                                <UILIB.TextInput outerClassName="mar-b25" value={this.props.form.options.confirmedRedirectUrl} error={this.state.errors.confirmedRedirectUrl} placeholder="redirect url" name="confirmedRedirectUrl" onChange={ev => this.updateField(ev, true)} />
                            }

                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
                <div className="quickFlex end-xs">

                    <UILIB.Button
                        iconRight={<UILIB.Icons icon="arrowRight" />}
                        className="button-primary"
                        disabled={!!Object.keys(this.state.errors).length}
                        text={this.props.form.options.onConfirm === 'landing' ? i18n.t('form:step5.design') : i18n.t('form:step5.next')}
                        onClick={this.next} />

                </div>
            </div>
        );
    }
}

export default FormConfirm
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Uj9gnMaAksvQbWZ4lviU{position:relative;width:100%}.Uj9gnMaAksvQbWZ4lviU input{padding-right:38px}.xQoQqlvOyTLmhp25b46E{position:absolute;right:10px;top:50%;transform:translateY(-50%);color:var(--grey-700)}.B2WCQcsBHL51OGj9kVWd{display:flex;flex-direction:column;gap:8px;text-align:center}.tBwU4mrfBkPiYbAW6BFi{font-size:14px;line-height:18px;font-weight:600}.oeh1oK2wrbAgGfkag7zw{font-size:12px;line-height:18px;color:var(--grey-800)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/inbox/components/leftSidebar/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA,CAEA,4BACE,kBAAA,CAIJ,sBACE,iBAAA,CACA,UAAA,CACA,OAAA,CACA,0BAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,iBAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,eAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,qBAAA","sourcesContent":[".searchWrapper {\n  position: relative;\n  width: 100%;\n\n  input {\n    padding-right: 38px;\n  }\n}\n\n.closeSearchBtn {\n  position: absolute;\n  right: 10px;\n  top: 50%;\n  transform: translateY(-50%);\n  color: var(--grey-700);\n}\n\n.emptyState {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  text-align: center;\n}\n\n.emptyStateTitle {\n  font-size: 14px;\n  line-height: 18px;\n  font-weight: 600;\n}\n\n.emptyStateDescription {\n  font-size: 12px;\n  line-height: 18px;\n  color: var(--grey-800);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchWrapper": "Uj9gnMaAksvQbWZ4lviU",
	"closeSearchBtn": "xQoQqlvOyTLmhp25b46E",
	"emptyState": "B2WCQcsBHL51OGj9kVWd",
	"emptyStateTitle": "tBwU4mrfBkPiYbAW6BFi",
	"emptyStateDescription": "oeh1oK2wrbAgGfkag7zw"
};
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react';
import SiteVars from '../../../Classes/siteVars'

class ConfirmCardPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            complete: false,
            error: ''
        }
        this.stripe = Stripe(SiteVars.stripeKey);
    }

    componentDidMount() {
        // get any pending payments for this account
        this.stripe.confirmCardPayment(this.props.clientSecret, {
            payment_method: this.props.paymentMethod
        }).then((result) => {
            console.log(result)
            if (result.error) {
                this.props.error(result.error.message)
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    this.props.success()
                } else {
                    this.props.error(result.paymentIntent.status)
                }
            }
        });
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 mar-t30 center-xs">
                        <div className="loading">Processing Payment</div>
                    </div>
                </div>
            </div>
        );
    }

}

export default ConfirmCardPayment;
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import dateTimeFunctions from '../../../../../../Classes/dateTimeFunctions'
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})
export default class ProgressSubscribers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageLoaded: false,
            pageSize: 15,
            currentPage: 0,
            customfields: [],
            subscribers: [],
            tableData: [],
            totalSubs: 0,
            loadingData: 1,
            groupId: undefined,
            importId: undefined,
            type: undefined,
            onlyUpdates: false,
            onlyCreates: false
        };

        this.closeDrawer = this.closeDrawer.bind(this);
        this.getCustomFields = this.getCustomFields.bind(this);
        this.getSubscribers = this.getSubscribers.bind(this);
        this.buildTable = this.buildTable.bind(this);
        this.changePage = this.changePage.bind(this);
        this.delete = this.delete.bind(this);
        this.update = this.update.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
    }

    componentDidMount() {
        this.getCustomFields()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.update !== this.props.update) {
            this.setState({
                subscribers: [],
                tableData: [],
                pageLoaded: false
            }, this.getCustomFields)
        }
    }

    getCustomFields() {
        axios.get('/customfield').then(response => {
            this.setState({
                customfields: response.data.CustomFields
            }, this.getSubscribers)
        })
    }

    getSubscribers() {
        this.setState({
            loadingData: 1
        })
        var params = '?limit=' + this.state.pageSize + '&skip=' + (this.state.currentPage * this.state.pageSize);
        if (this.props.type == 'updated') {
            params += '&onlyUpdates=true';
        }

        if (this.props.type == 'new') {
            params += '&onlyCreates=true';
        }

        axios.get('/group/' + this.props.groupId + '/import/' + this.props.importId + '/subscribers' + params)
            .then(response => {
                var totalSubs = 0;
                if (this.props.type === 'updated') {
                    totalSubs = this.props.importer.recordsExist
                } else if (this.props.type === 'new') {
                    totalSubs = this.props.importer.newSubscribersTotal
                }
                this.setState({
                    subscribers: response.data.Subscribers,
                    tableData: [],
                    totalSubs
                }, this.buildTable)
            })
            .catch(console.log)
    }

    changePage(page) {
        this.setState({
            currentPage: page
        }, this.getSubscribers)
    }

    delete(ids) {
        axios.delete('/group/' + this.props.groupId + '/import/' + this.props.importId + '/failedimport', {
            data: { ids }
        }).then(response => {
            this.getSubscribers()
        })
    }

    update(id) {

    }

    buildTable() {
        var tableData = this.state.subscribers.map(thisLine => {
            var result = {
                emailAddress: {
                    headerValue: i18n.t('emailAddress'),
                    value: thisLine.emailAddress || '--NONE--',
                    orderBy: false
                },
                active: {
                    headerValue: 'Active',
                    value: thisLine.active ? 'Yes' : 'No'
                },
                unsubscribe: {
                    headerValue: 'Unsubscribed',
                    value: thisLine.unsubscribe ? 'Yes' : 'No'
                },
                blackListed: {
                    headerValue: 'Denylisted',
                    value: thisLine.blackListed ? 'Yes' : 'No'
                },
                bounce: {
                    headerValue: 'Bounced',
                    value: thisLine.bounce ? 'Yes' : 'No'
                }
            }
            this.state.customfields.forEach(customfield => {
                let value = thisLine.customFields && thisLine.customFields[customfield.id] !== undefined ? thisLine.customFields[customfield.id] : ''
                if (customfield.fieldType === 'DATE' && value) {
                    value = dateTimeFunctions.formatDateTime(value)
                }
                result[customfield.fieldName] = {
                    headerValue: customfield.fieldDesc,
                    value,
                    orderBy: false
                }
            })
            result.createdAt = {
                headerValue: i18n.t('created'),
                value: dateTimeFunctions.formatDateTime(thisLine.createdAt, "2"),
                orderBy: false
            }
            return result
        })

        this.setState({
            tableData: tableData,
            pageLoaded: true,
            loadingData: 0
        })
    }

    changePageSize(size) {
        this.setState({
            pageSize: size
        }, this.getSubscribers)
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }


    render() {
        var text = 'Subscribers';

        return <div style={{ marginTop: "40px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className="mar-b25">
                <h4>{this.props.type == 'new' ? i18n.t('subscribers:add.import.progress.success') : i18n.t('subscribers:add.import.progress.updated')}</h4>
                <div className="hide-xs" style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                    <div>{i18n.t('showing')}</div>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.pageSize === 15} onClick={() => { this.changePageSize(15) }}>15</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 500} onClick={() => { this.changePageSize(500) }}>500</UILIB.ButtonSimple>
                </div>
            </div>

            <UILIB.Paper>
                <UILIB.DataTable1
                    noResultsTxt={<span> {i18n.t('subscribers:add.import.progress.noSubs')} </span>}
                    tableData={this.state.tableData}
                    dataUpdater={this.changePage}
                    loadingData={this.state.loadingData}
                    width="100%"
                    pageSize={this.state.pageSize}
                    hasCheckBoxes="multi"
                    className="mar-b25"
                />
            </UILIB.Paper>

            <div style={{ textAlign: "center" }}>
                <UILIB.PagingBlock
                    totalRows={this.state.totalSubs}
                    pageSize={this.state.pageSize}
                    numberOfLinks="10"
                    currentPage={this.state.currentPage}
                    changePage={this.changePage}
                    text={i18n.t('page') + ":"} />
            </div>
        </div>
    }
};


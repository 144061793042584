import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApmRoute } from '@elastic/apm-rum-react'

import UILIB from '~/Common-Objects/Lib.jsx';

import Subscriber from '../widgets/subscriber'

@connect((store) => {
    return { siteMaster: store.siteMaster, user: store.user, accountMaster: store.accountMaster }
})
class WidgetTemplate extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.user.dataUpToDate == true) {
            if (this.props.user.loggedIn === false) {
                var queryString = window.location.search;
                var pushUrl = '/?redirect=' + window.location.pathname;

                if (window.location.pathname && window.location.pathname.indexOf('oAuth/response') > 0) {
                    var urlData = window.location.pathname.split("/");
                    var thisAppId = urlData[urlData.length - 1]
                    pushUrl = "/appConnect/?appid=" + thisAppId
                    if (queryString && queryString.length > 1) {
                        queryString = "&" + queryString.substring(1, queryString.length)
                        pushUrl += queryString;
                    }
                }

                window.open(pushUrl, '_self');
                return <div></div>
            }
            return <div className="integrationsHolder">
                <Router>
                    <Switch>
                        <ApmRoute path="/widgets/subscriber/:id" component={Subscriber} />
                        <Redirect to="/" push />
                    </Switch>
                </Router>
            </div>
        }
        else {
            return <UILIB.LoadingOverlay fullscreen={true} />
        }
    }
}

export default WidgetTemplate;
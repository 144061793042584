// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VNwwjhXUAF5ful5KbogQ{font-size:12px;font-weight:normal;color:var(--grey-900);margin-bottom:32px}.VNwwjhXUAF5ful5KbogQ ol{display:flex;gap:4px}.VNwwjhXUAF5ful5KbogQ a{color:var(--grey-700);font-weight:inherit;text-decoration:none}.VNwwjhXUAF5ful5KbogQ a:hover{color:var(--grey-900);text-decoration:underline}.VNwwjhXUAF5ful5KbogQ a svg{color:var(--grey-600)}.vUJU5WjBCl4P0zZwYKWq{display:flex;align-items:center;gap:4px}.yAwKudfeWCHHPtDZqEu4{display:flex;align-items:center;gap:6px}.hL8elPal3iUBZ5pro7CI{color:var(--grey-400)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/components/breadcrumbs/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,kBAAA,CACA,qBAAA,CACA,kBAAA,CAEA,yBACE,YAAA,CACA,OAAA,CAGF,wBACE,qBAAA,CACA,mBAAA,CACA,oBAAA,CAEA,8BACE,qBAAA,CACA,yBAAA,CAGF,4BACE,qBAAA,CAKN,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,qBAAA","sourcesContent":[".root {\n  font-size: 12px;\n  font-weight: normal;\n  color: var(--grey-900);\n  margin-bottom: 32px;\n\n  ol {\n    display: flex;\n    gap: 4px;\n  }\n\n  a {\n    color: var(--grey-700);\n    font-weight: inherit;\n    text-decoration: none;\n\n    &:hover {\n      color: var(--grey-900);\n      text-decoration: underline;\n    }\n\n    svg {\n      color: var(--grey-600);\n    }\n  }\n}\n\n.breadcrumb {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n\n.breadcrumbInner {\n  display: flex;\n  align-items: center;\n  gap: 6px;\n}\n\n.divider {\n  color: var(--grey-400);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "VNwwjhXUAF5ful5KbogQ",
	"breadcrumb": "vUJU5WjBCl4P0zZwYKWq",
	"breadcrumbInner": "yAwKudfeWCHHPtDZqEu4",
	"divider": "hL8elPal3iUBZ5pro7CI"
};
export default ___CSS_LOADER_EXPORT___;

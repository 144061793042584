import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import i18n from '~/i18n'

class CampaignReplies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            tableData: [],
            total: this.props.match.params.campaignId,
            sortColumn: 'replyDate',
            sortDirection: 'desc',
            searchText: '',
            startRecord: 0,
            pageSize: 50,
            campaignId: this.props.match.params.campaignId,
        }

        this.getReplies = this.getReplies.bind(this)
        this.goViewSubscriber = this.goViewSubscriber.bind(this)
        this.tableUpdater = this.tableUpdater.bind(this)
        this.changePageSize = this.changePageSize.bind(this)
        this.changeSearchText = this.changeSearchText.bind(this)
        this.goSearchText = this.goSearchText.bind(this)
        this.keyPress = this.keyPress.bind(this)
    }

    componentDidMount() {
        this._ismounted = true
        this.getReplies()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.endDate != this.props.endDate || prevProps.startDate != this.props.startDate) {
            this.getReplies();
        }
    }

    componentWillUnmount() {
        this._ismounted = false
    }

    goViewSubscriber(id) {
        this.props.history.push('/cp/subscriber/' + id);
    }

    changePageSize(pageSize) {
        this.tableUpdater(null, null, null, pageSize, null);
    }

    changeSearchText(event) {
        this.setState({ searchText: event.target.value })
    }
    goSearchText() {
        this.tableUpdater(0, null, null, null, null);
    }
    keyPress(event) {
        if (event.key === 'Enter') {
            this.goSearchText();
        }
    }

    tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {
        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!currentPage && currentPage != "0") {
            currentPage = this.state.currentPage;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var startRecord = currentPage * pageSize;

        this.setState({ sortColumn: sortColumn, sortDirection: sortDirection, pageSize: pageSize, currentPage: currentPage, searchText: searchText, startRecord: startRecord }, this.getReplies);

    }

    getReplies(sortColumn, sortDirection, startRecord, pageSize, searchText) {
        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!startRecord) {
            startRecord = this.state.startRecord;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var query = '?sortColumn=' + sortColumn + '&sortOrder=' + sortDirection + '&searchText=' + searchText + '&startRecord=' + startRecord + '&recordLimit=' + this.state.pageSize;
        if (this.props.startDate) {
            query += '&startDate=' + this.props.startDate.format('YYYY-MM-DD')
        }
        if (this.props.endDate) {
            query += '&endDate=' + this.props.endDate.format('YYYY-MM-DD 23:59:59')
        }

        axios.get('/campaign/' + this.state.campaignId + '/stats/replies' + query).then(response => {
            this.setState({
                total: response.data.countTotal,
                loaded: true,
                tableData: response.data.Replies.map(row => {
                    return {
                        subemailAddress: {
                            headerValue: i18n.t('campaigns:replies.subscriber'),
                            value: <a onClick={() => { this.goViewSubscriber(row.SubscriberId) }}>{row.subemailAddress}</a>,
                            orderBy: true
                        },
                        replyDate: {
                            headerValue: i18n.t('campaigns:replies.replyDate'),
                            value: <span>{DateTimeFunctions.formatDateTime(row.replyDate, 1)}</span>,
                            orderBy: true
                        },
                        lastOpened: {
                            headerValue: i18n.t('campaigns:replies.replyAddress'),
                            value: row.mailemailAddress,
                            orderBy: true
                        },
                        optionsCol:
                        {
                            headerValue: " ",
                            value: <UILIB.OptionsDropdown popWidth="150px" history={this.history}>
                                <ul>
                                    <li>
                                        <a onClick={() => { this.goViewSubscriber(row.SubscriberId) }}>{i18n.t('campaigns:overview.viewSubscriber')}</a>
                                    </li>
                                </ul>
                            </UILIB.OptionsDropdown>
                            ,
                            orderBy: false,
                            fixed: true,
                            width: 20
                        }
                    }
                })
            })
        })
    }

    render() {
        return <div>

            <div style={{ display: "flex", justifyContent: "right" }} className="mar-b25">
                <UILIB.TextInput type="multiDate" startDate={this.props.startDate} endDate={this.props.endDate} updateDate={this.props.updateDate}></UILIB.TextInput>
            </div>

            <UILIB.Paper>
                <UILIB.Row>
                    <UILIB.Column md={6} sm={12} xs={12} className="mar-b25" style={{ display: "flex" }}>
                        <UILIB.TextInput
                            outerStyle={{ flex: "1" }}
                            clickToChangeClicked={this.changeSearchText}
                            placeholder={i18n.t('searchForEmail')}
                            onChange={this.changeSearchText}
                            onKeyPress={this.keyPress}
                            iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                        />
                    </UILIB.Column>
                    <UILIB.Column md={6} hide={["sm", "xs"]} className="mar-b25" style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                        <div>{i18n.t('showing')}</div>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.pageSize === 10} onClick={() => { this.changePageSize(10) }}>10</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 50} onClick={() => { this.changePageSize(50) }}>50</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>

                    </UILIB.Column>
                    <UILIB.Column xs={12} className="mar-b25">
                        <UILIB.DataTable1 noResultsTxt={<span style={{ whiteSpace: 'pre-wrap' }}> {i18n.t('noResults')}
                            {!this.state.total && <span> - {i18n.t('campaigns:replies.toProcessAddEmail')} <a onClick={() => this.props.history.push('/cp/company/mailboxes')}>{i18n.t('campaigns:replies.here')}</a> </span>}</span>} tableData={this.state.tableData} loadingData={this.state.loadingData}
                            dataUpdater={this.tableUpdater} width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn={this.state.sortColumn}
                            sortedDirection={this.state.sortDirection}
                        />
                    </UILIB.Column>
                    <UILIB.Column xs={12}>
                        <div style={{ textAlign: "center" }}>
                            <UILIB.PagingBlock totalRows={this.state.totalRows} pageSize={this.state.pageSize} numberOfLinks="10" currentPage={this.state.currentPage}
                                changePage={this.tableUpdater} text={i18n.t('page') + ":"} />
                        </div>
                    </UILIB.Column>
                </UILIB.Row>
            </UILIB.Paper>


        </div >
    }
}

export default CampaignReplies;
import socketIOClient from "socket.io-client";
import config from '~/Classes/siteVars.js';
import axios from "~/data/http/axios";

export class SupportSocket {

    constructor() {
        this.supportSocket = undefined;
    }

    async init(uuid, accountMasterId, trackerId, callback, channelType) {
        let authToken = await axios.post(`/inboxes/socketauth`, { uuid, accountMasterId, trackerId, channelType })
        if (!authToken) return;
        authToken = authToken.data;
        this.supportSocket = socketIOClient(
            config.supportSocketServer + "/dynamic_" + uuid + "_" + accountMasterId, {
            transports: ['websocket'],
            "path": "/chat",
            "query": {
                token: authToken
            },
            "forceNew": true
        }
        );

        return this.listeners(callback);
    }

    listeners(newEvent = () => { }) {
        this.supportSocket.on("connect", (data) => {
            console.log("connected");
            newEvent({
                event: "connected",
                data
            })
        });

        this.supportSocket.on("visitor_sent_newMessage", data => {
            console.log("visitor_sent_newMessage");
            newEvent({
                event: "visitor_sent_newMessage",
                data
            })
        })

        this.supportSocket.on("visitor_read_message", data => {
            console.log("visitor_read_message");
            newEvent({
                event: "visitor_read_message",
                data
            })
        })

        this.supportSocket.on("visitorTyping", data => {
            newEvent({
                event: "visitorTyping",
                data
            })
        });

        this.supportSocket.on("connect_error", (err) => {
            console.log("connect_error");
            newEvent({
                event: "connect_error"
            })
        })
        this.supportSocket.on("custom_connect_error", (err) => {
            console.log("connect_error");
            newEvent({
                event: "connect_error"
            })
        })
        this.supportSocket.on("disconnect", data => {
            console.log("disconnected_event");
            newEvent({
                event: "disconnected",
                data
            })
        })
    }

    emit_agentTyping(chatId) {
        if (this.supportSocket) {
            this.supportSocket.emit("systemSendTyping", { ChatId: chatId });
        }
    }

    disconnect() {
        if (this.supportSocket && this.supportSocket.connected) {
            this.supportSocket.disconnect();
            this.supportSocket.close()
        }
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import MoreOptions from '~/Common-Objects/Logos/moreOptions';
import i18n from '~/i18n'
import DateTimeFunctions from '~/Classes/dateTimeFunctions'
import PermissionChecker from '~/Classes/permissions';
import SiteVars from '~/Classes/siteVars';
import * as siteMaster from '~/data/actions/siteActions';
import TemplateOptions from './templateOptions'
import IncGoDeleteTemplate from './incGoDeleteTemplate.jsx';
import axios from '@/data/http/axios';
import textFunctions from '~/Classes/textFunctions';

@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, permissionStore: store.permission, siteMaster: store.siteMaster }
})
class Template extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOptions: false,
            deleting: false,
            templateImageLoaded: false
        }
        this.unmounting = false;
        this.showOptions = this.showOptions.bind(this)
        this.hideOptions = this.hideOptions.bind(this)
        this.checkTemplateImageExists = this.checkTemplateImageExists.bind(this)
        this.deleteTemplate = this.deleteTemplate.bind(this)
        this.convertToPlainText = this.convertToPlainText.bind(this);
    }

    componentDidMount() {
        this.checkTemplateImageExists()
    }

    componentWillUnmount() {
        this.unmounting = true
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.siteMaster.siteDrawer.isOpen && !this.props.siteMaster.siteDrawer.isOpen) {
    //         this.setState({ deleting: false })
    //     }
    // }

    showOptions() {
        this.setState({
            showOptions: true
        })
    }

    hideOptions() {
        this.setState({
            showOptions: false
        })
    }

    checkTemplateImageExists() {

        if (this.unmounting) return
        if (this.state.templateImageLoaded) return;

        var self = this;
        var img = new Image();
        img.onload = function () { self.setState({ templateImageLoaded: true }) };
        img.onerror = function () {
            self.imageCheckTimer = setTimeout(self.checkTemplateImageExists, 2000);
        }
        img.src = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + this.props.template.id + ".jpg";
    }

    deleteTemplate() {
        const theDrawer = <IncGoDeleteTemplate templateId={this.props.template.id} goDelete={() => {
            this.setState({ deleting: true })
            axios.delete('/template/' + this.props.template.id).then((res) => {
                this.props.dispatch(siteMaster.alterSiteDrawer(false))
                this.props.getGroups(() => {
                    if (this.unmounting) return
                    this.setState({ deleting: false })
                });
            });
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true));
    }

    convertToPlainText(template) {
        var self = this;
        axios.post('/template/createPlainText/' + template.id).then((res) => {
            var newTemplate = res.data.Template;
            self.props.history.push(`/cp/templates/add/wysiwyg/${newTemplate.id}`)
        });
    }

    render() {
        var template = this.props.template;
        let chipClass = 'square-chip-green'
        var type = i18n.t('templates:index.drag')
        if (template.templateType == '1') {
            type = i18n.t('templates:index.code')
            chipClass = "square-chip-blue"
        }
        if (template.templateType == '2') {
            type = i18n.t('templates:index.wysiwig');
            chipClass = "square-chip-purple"
        }
        if (template.templateType == '4') {
            type = i18n.t('templates:index.upload')
            chipClass = "square-chip-yellow"
        }
        chipClass += " mar-b10";

        var screenshotUrl = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + template.id + ".jpg?rnd=" + this.props.now
        var groupName = '';
        if (template.TemplateGroupId) {
            var group = this.props.groups.find(g => g.id == template.TemplateGroupId);
            if (group) groupName = group.groupName;
        }

        var isDeleted = false;
        if (this.props.deleted) {
            isDeleted = true;
            groupName = "Deleted"
        }

        let canEdit = !isDeleted && PermissionChecker('templates', this.props.permissionStore.permissions, "write");
        let canSend = !isDeleted && PermissionChecker('campaigns', this.props.permissionStore.permissions, "write");
        return <UILIB.Column xs={12} sm={6} md={4} lg={4} className="mar-b25" key={this.props.index}>
            <div className="templateCard" key={this.props.index}>
                {this.state.deleting && <div className="templateDeleting">
                    <UILIB.LoadingIcons iconType="2" />
                </div>}
                <div className="tcImage">
                    <div className="tcImageImgHolder">
                        {this.state.templateImageLoaded &&
                            <div className="tcImageImg" style={{ backgroundImage: "url(" + screenshotUrl + ")" }} />
                        }
                        {!this.state.templateImageLoaded && <UILIB.LoadingIcons iconType="2" />}
                    </div>
                    {!isDeleted && <div className="toolbar">
                        {!!template.createdBy && <div className="avatar">
                            <UILIB.Avatar style={{ height: '40px', width: '40px' }} for={'template' + template.id} tooltip={template.createdBy.fullname} src={template.createdBy.profilePicFileName} />
                        </div>}

                        <UILIB.SquareChip className={(template.public ? 'square-chip-green' : 'square-chip-red')} for={'template' + template.id} tooltip={template.public ? i18n.t('templates:index.allUsersCan') : i18n.t('templates:index.onlyAdminsCan')}>{template.public ? i18n.t('templates:index.public') : i18n.t('templates:index.private')}</UILIB.SquareChip>

                        {PermissionChecker('templates', this.props.permissionStore.permissions, "write") && <div className="moreOptions" onClick={this.showOptions}>
                            <MoreOptions width="4px" />
                            <UILIB.DropDown isOpen={this.state.showOptions} preventDefault={true} onClose={this.hideOptions}>
                                <TemplateOptions deleted={this.props.deleted} template={template} restoreDeleted={this.props.restoreDeleted} goEditTemplate={this.props.goEditTemplate} openEmailPreview={this.props.openEmailPreview} openSpamTest={this.props.openSpamTest} exportHtml={this.props.exportHtml} exportPdf={this.props.exportPdf} goChangeGroup={this.props.goChangeGroup} inviteUsers={this.props.inviteUsers} duplicate={this.props.duplicate} goDeleteTemplate={this.deleteTemplate} togglePublic={this.props.togglePublic} convertToPlainText={this.convertToPlainText} share={this.props.share} sendAsCampaign={this.props.sendAsCampaign} />
                            </UILIB.DropDown>
                        </div>}
                    </div>
                    }
                    <div className="groups">
                        <p>{groupName}</p>
                    </div>
                </div>
                <div className="tcContent">
                    <UILIB.SquareChip value={type} className={chipClass} />
                    <h4 className="mar-b5" style={{ wordBreak: 'break-all' }}>{template.templateName}</h4>
                    {!!template.lastEditedBy && <p className="edit" dangerouslySetInnerHTML={{
                        __html: i18n.t('templates:index.lastEdited2', {
                            firstName: textFunctions.checkForHtml(template.lastEditedBy.firstName),
                            lastName: textFunctions.checkForHtml(template.lastEditedBy.lastName),
                            date: DateTimeFunctions.humanizeDuration(template.lastUpdated, this.props.user.userData.language),
                            rawDate: DateTimeFunctions.formatDateTime(template.lastUpdated)
                        })
                    }}></p>}
                    <div className="typeWrap">

                        {(canEdit || canSend) && <div className="quickFlex">

                            {canEdit && <div className="tcButton">
                                <UILIB.Button
                                    onClick={() => this.props.goEditTemplate(template.id, template.templateType)}
                                    iconLeft={<UILIB.Icons icon="pencil" />}
                                    className="button-primary  button-sml"
                                >
                                    {i18n.t('edit')}
                                </UILIB.Button>
                            </div>}

                            {canSend && <div className="tcButton">
                                <UILIB.Button
                                    onClick={() => this.props.sendAsCampaign(template.id)}
                                    iconLeft={<UILIB.Icons icon="envelope" />}
                                    className="button-sml mar-l10"
                                >
                                    {i18n.t('sendACampaign')}
                                </UILIB.Button>
                            </div>}



                        </div>}

                        {(isDeleted && canEdit) && <div className="tcButton">
                            <UILIB.Button
                                className="button-sml"
                                onClick={() => this.props.restoreDeleted(template.id)}
                            >
                                Restore
                            </UILIB.Button>
                        </div>}
                    </div>
                </div>
            </div>
        </UILIB.Column>
    }
}

export default Template;
import React, { useEffect, useState } from 'react'
import axios from '../../../data/http/axios'
import UILIB from '../../../Common-Objects/Lib'

export default function SubscriberSms({ id, subscriber }) {
    const [status, setStatus] = useState(null)

    const getStatus = async (id) => {
        setStatus(null)
        const result = await axios.get('/subscriber/' + id + '/sms')
        setStatus(result.data)
    }

    useEffect(() => {
        getStatus(id)
    }, [id])

    if (!status) return <UILIB.LoadingIcons iconType="2" />

    const phoneNumbers = subscriber.customFields.filter(cf => cf.fieldType === 'TELNO' && !!cf.value)

    return <UILIB.Paper className="mar-t20 mar-b0">
        <h4 className='mar-b20'>SMS Status</h4>
        <div className='mar-b20'>
            {!status.bounced && !status.unsubscribe && <UILIB.SquareChip value="Active" className="square-chip-large square-chip-green" />}
            {status.bounced && <UILIB.SquareChip value="Bounced" className="square-chip-large square-chip-red" />}
            {!status.bounced && status.unsubscribe && <UILIB.SquareChip value="Unsubscribed" className="square-chip-large square-chip-orange" />}
        </div>

        <h4 className='mar-b20'>Valid Phone Numbers</h4>
        {phoneNumbers.map(cf => {
            return <UILIB.SquareChip key={cf.id} className="mar-r5">{cf.value}</UILIB.SquareChip>
        })}
        {!phoneNumbers.length && <div>No phone numbers found</div>}
    </UILIB.Paper>
}
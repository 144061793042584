import React from 'react'

export default class IconsSplit extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.8738 3.29289C14.4833 2.90237 13.8501 2.90237 13.4596 3.29289C13.0691 3.68342 13.0691 4.31658 13.4596 4.70711L15.9192 7.16669H13.3333C12.1841 7.16669 11.0819 7.62323 10.2692 8.43589C9.45655 9.24855 9 10.3507 9 11.5V17.3334C9 17.8856 9.44772 18.3334 10 18.3334C10.5523 18.3334 11 17.8856 11 17.3334V11.5C11 10.8812 11.2458 10.2877 11.6834 9.8501C12.121 9.41252 12.7145 9.16669 13.3333 9.16669H15.9191L13.4596 11.6262C13.0691 12.0167 13.0691 12.6499 13.4596 13.0404C13.8501 13.431 14.4833 13.431 14.8738 13.0404L19.0405 8.87377C19.1401 8.77408 19.2144 8.65858 19.2632 8.53534C19.3012 8.43953 19.3248 8.33645 19.3314 8.22876C19.3341 8.18642 19.334 8.14394 19.3312 8.10159C19.3248 8.00191 19.3038 7.90621 19.2703 7.81659C19.2218 7.68638 19.1451 7.56425 19.0405 7.45956L14.8738 3.29289Z" fill={color} />
            <path d="M6.54376 1.29289C6.93428 1.68342 6.93428 2.31658 6.54376 2.70711L4.08418 5.16669H6.66998C7.81925 5.16669 8.92145 5.62323 9.73411 6.43589C9.85214 6.55392 9.96266 6.67806 10.0654 6.80757C9.77859 6.9837 9.51107 7.19402 9.2692 7.43589C9.02462 7.68047 8.81229 7.95129 8.63496 8.24172C8.54504 8.10129 8.43965 7.96986 8.31989 7.8501C7.88231 7.41252 7.28882 7.16669 6.66998 7.16669H4.08422L6.54376 9.62623C6.93428 10.0167 6.93428 10.6499 6.54376 11.0404C6.15323 11.431 5.52007 11.431 5.12954 11.0404L0.966772 6.87767C0.783519 6.69641 0.669983 6.44481 0.669983 6.16669C0.669988 5.91575 0.762422 5.68638 0.915098 5.51081C0.930371 5.49323 0.946303 5.47613 0.962876 5.45956L5.12954 1.29289C5.52007 0.902369 6.15323 0.902369 6.54376 1.29289Z" fill={color} />
        </svg>




    }
}

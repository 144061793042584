import React from 'react'
import Alarm from '~/assets/images/icons/alarm.svg';
// import { } from 'Common-Objects/'
import UILIB from '~/Common-Objects/Lib';
import axios from '~/data/http/axios';
import dateTimeFunctions from '../../../../../Classes/dateTimeFunctions';
import { connect } from 'react-redux';

import i18n from '~/i18n';
@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster }
})

export default class bb_notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navOpen: false,
            alert: false,
            alerts: []
        }
        this.navClicked = this.navClicked.bind(this);
        this.getAlerts = this.getAlerts.bind(this);
        this.clickLink = this.clickLink.bind(this);
        this.deleteAll = this.deleteAll.bind(this);
        this.parseJson = this.parseJson.bind(this);
        this.timer;

    }

    componentDidMount() {
        this.getAlerts()
        this.timer = setInterval(this.getAlerts, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getAlerts() {
        if (this.props.changingAccounts) return
        var currentAlerts = this.state.alerts
        axios.get('/accountuser/alert?ignoreLog=1')
            .then(response => {
                var alert = false
                var newAlerts = response.data.Alerts;
                if (!currentAlerts.length && newAlerts.length) {
                    alert = true
                } else if (currentAlerts.length && newAlerts.length) {
                    if (currentAlerts[0].id !== newAlerts[0].id) {
                        alert = true
                    }
                }
                this.setState({
                    alerts: newAlerts,
                    alert
                })
            }).catch(() => { })
    }

    navClicked() {
        var isNavOpen = this.state.navOpen;
        if (isNavOpen == false) {
            isNavOpen = true;
        } else {
            isNavOpen = false;
        }
        this.setState({ navOpen: isNavOpen });
    }

    clickLink(alert, followLink) {
        if (followLink) {
            if (alert.link.indexOf('http') === 0) {
                window.open(alert.link, '_blank');
            } else {
                this.props.history.push(alert.link);
            }
        }

        axios.delete('/accountuser/alert/' + alert.id).then(this.getAlerts)
    }

    deleteAll() {
        axios.delete('/accountuser/alert/all').then(this.getAlerts)
    }

    parseJson(json) {
        try {
            JSON.parse(json);
        } catch (e) {
            return { valid: false, body: json };
        }
        return { valid: true, body: JSON.parse(json) };
    }

    render() {

        var isGermanEtc = false;
        if (this.props.user.userData && this.props.user.userData.language && this.props.user.userData.language == "de") {
            isGermanEtc = true;
        }
        var self = this;
        return <div className="pos-rel" onClick={this.navClicked}>
            <div className="notification-wrapper">
                <UILIB.Button iconLeftStyle={{ marginRight: "5px" }} iconLeft={<UILIB.Icons className={this.state.alerts.length ? 'shake' : ''} icon="bell" style={{ height: "20px", width: "20px" }} />}>
                    <div className="notification-button-alerts">{this.state.alerts.length}</div>
                </UILIB.Button>
            </div>
            <UILIB.DropDown isOpen={this.state.navOpen} preventDefault={true} className="notification" onClose={this.navClicked} id="alertDrop">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="text-heavy">
                    <div>{i18n.t('nav:notifications.youHave')} <span>{this.state.alerts.length}</span> {i18n.t('nav:notifications.notifications')}</div>
                    {!!this.state.alerts.length && <div className="text-red text-clickable" onClick={this.deleteAll}>{i18n.t('nav:notifications.clearAll')}</div>}
                </div>
                <ul>
                    {this.state.alerts.map(alert => {

                        var label = alert.label;
                        var message = alert.body;
                        var linkText = alert.linkText
                        var formatted = this.parseJson(message);
                        switch (alert.label) {
                            case "Subscriber Import Finished":
                            case "Contact Import Finished":
                                {
                                    label = i18n.t('nav:notifications.alerts.labels.subscriberImportFinished')
                                    if (formatted.valid) {
                                        message = i18n.t('nav:notifications.alerts.messages.subscriberImportFinished').replace(/\[GROUPNAME\]/g, formatted.body.groupName);
                                        message = message.replace(/\[SUBCOUNT\]/g, formatted.body.subscriberCount);
                                    }
                                    else {
                                        var groupName = message.split(/Import finished for group /g)[1].split(/ with /)[0];
                                        var subCount = message.split(/new subscribers/g)[0].split(/ with /)[1];
                                        message = i18n.t('nav:notifications.alerts.messages.subscriberImportFinished').replace(/\[GROUPNAME\]/g, '"' + groupName + '"');
                                        message = message.replace(/\[SUBCOUNT\]/g, subCount);
                                    }
                                    linkText = i18n.t('nav:notifications.alerts.links.view')
                                    break;
                                }
                            case "Segment Copy Complete":
                                {
                                    label = i18n.t('nav:notifications.alerts.labels.segmentCopyComplete')
                                    if (formatted.valid) {
                                        message = i18n.t('nav:notifications.alerts.messages.segmentCopyComplete').replace(/\[SEGMENTNAME\]/g, formatted.segmentName);
                                        message = message.replace(/\[GROUPNAME\]/g, formatted.groupName);
                                    }
                                    else {
                                        segment.name + ' Finished Copying to ' + group.groupName
                                        var segmentName = message.split(/ Finished Copying to /g)[0];
                                        var groupName = message.split(/ Finished Copying to /g)[1];
                                        message = i18n.t('nav:notifications.alerts.messages.segmentCopyComplete').replace(/\[GROUPNAME\]/g, '"' + groupName + '"');
                                        message = message.replace(/\[SEGMENTNAME\]/g, segmentName);
                                    }
                                }
                            case "Failed to Start Campaign":
                                {
                                    label = i18n.t('nav:notifications.alerts.labels.failedtoStartCampaign')
                                    if (formatted.valid) {
                                        message = i18n.t('nav:notifications.alerts.messages.failedtoStartCampaign').replace(/\[CAMPAIGNNAME\]/g, formatted.campaignName);
                                        message = message.replace(/\[ERROR\]/g, formatted.error);
                                    }
                                }
                            default: {
                                break;
                            }
                        }


                        return <li key={alert.id}>
                            <div className="headline">
                                <div>{label}</div>
                                <div className="date">
                                    {(!isGermanEtc) && <span>{dateTimeFunctions.humanizeDuration(alert.createdAt, this.props.user.userData.language)} {i18n.t('nav:notifications.ago')}</span>}
                                    {(isGermanEtc) && <span>{dateTimeFunctions.formatDateTime(alert.createdAt, 2)}</span>}
                                </div>
                            </div>
                            <div className="message">
                                <div>{message}
                                    {!!alert.link && <a style={{ marginLeft: 5 }} className="text-primary" onClick={() => this.clickLink(alert, true)}>{linkText}</a>}
                                    <a style={{ marginLeft: 5 }} className="text-red" onClick={() => this.clickLink(alert)}>{i18n.t('nav:notifications.clear')}</a>
                                </div>
                            </div>
                        </li>
                    })}
                </ul>
            </UILIB.DropDown>

        </div>
    }
}

import React from 'react'
import NumberFormat from '~/Classes/numberFormatFunctions'
import UILIB from '../../../../Common-Objects/Lib';
import i18n from '~/i18n';
import { connect } from 'react-redux';

@connect((store) => {
    return {
        siteMaster: store.siteMaster
    }
})

export default class NewSubscribers extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        let increaseHolder = <UILIB.Indicator className="indicator-grey">0.00%</UILIB.Indicator>;
        if ((this.props.subscribers.increase || this.props.subscribers.decrease) !== Infinity) {
            if (this.props.subscribers.increase) increaseHolder = <UILIB.Indicator leftIcon={<UILIB.Icons icon="arrowUp" color={this.props.siteMaster.colours["$status-green"]} />} className=" indicator-green">{NumberFormat.formatNumber(this.props.subscribers.increase, 2)}%</UILIB.Indicator>
            if (this.props.subscribers.decrease) increaseHolder = <UILIB.Indicator leftIcon={<UILIB.Icons icon="arrowDown" color={this.props.siteMaster.colours["$status-red"]} />} className="indicator-red">{NumberFormat.formatNumber(this.props.subscribers.decrease, 2)}%</UILIB.Indicator>
        }
        return <UILIB.Paper className="m-mar-b0" iconRight={<UILIB.Icons icon="pencil" color="grey" />} iconRightClick={this.props.edit}>

            <h4 className="mar-b5">{i18n.t('dashboard:welcomePage.newSubscribers')}</h4>
            <div className="mar-b24">{i18n.t('dashboard:welcomePage.thisMonth')}</div>
            <div className="quickFlex">
                <h4 className="mar-r16 statistic">{NumberFormat.formatNumber(this.props.subscribers.current)}</h4>
                {increaseHolder}
                <div style={{ height: "40px" }} />
            </div>


        </UILIB.Paper >
    }
}

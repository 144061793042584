import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

export default class AccountTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = { tabIndex: 0 }
        this.tabChange = this.tabChange.bind(this);
        this.setInitialTab = this.setInitialTab.bind(this);

    }

    componentDidMount() {
        this.setInitialTab();
    }

    setInitialTab() {

        var theCurrPath = this.props.history.location.pathname.split("/");

        if (theCurrPath.length > 2) {

            switch (theCurrPath[3]) {
                default:
                    {
                        this.setState({ tabIndex: 0 });
                    }
            }

        }

    }

    tabChange(event) {
        var self = this;
        var index;
        if (event.currentTarget.id) {
            index = event.currentTarget.id !== undefined;
        }
        else {
            index = event.currentTarget.value !== undefined;
        }
        index = String(index);

        self.setState({ tabIndex: index });
        switch (index) {
            case "0":
                this
                    .props
                    .history
                    .push('/cp/account/');
                break;
        }
    }

    render() {

        return <UILIB.Paper>
            <div className="page-header mar-b25">{i18n.t('account:my.myAccount')}</div>
            {this.props.children}
        </UILIB.Paper>
    }
}
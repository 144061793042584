import React from 'react'
import { useSelector } from 'react-redux'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import i18n from "~/i18n";
import { useDispatch } from "react-redux";
import * as userActions from '~/data/actions/userActions';
import { useHistory } from "react-router-dom";

export default function InboxLeftSidebarFooter({ inboxes = [], userRole = null, activeInbox = {} }) {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const history = useHistory();
    const toggle = async () => {
        await axios.put('/accountUser', { chatOnline: !user.userData.chatOnline })
    }

    const showOnlineStatus = inboxes.some(inbox => {
        return inbox.InboxChannels.some(c => c.type === 2 && c.status === 'running')
    })

    let avatarClasses = "mar-r10 "
    if (showOnlineStatus) {
        avatarClasses += " avatarHolderStatus avatarHolderStatus-15 "
        if (user.userData.chatOnline) avatarClasses += "green"
        else avatarClasses += "grey"
    }

    const goLogout = () => {
        dispatch(userActions.logoutAndClear());
        window.open("/", "_self");
    }

    return (
        <div className="inbox-left-sidevar__footer">
            <UILIB.DropdownMenu.Root>
                <UILIB.DropdownMenu.Trigger
                    disabled={false}
                    variant="primary"
                    size="s"

                >
                    <div className="quickFlex">
                        <UILIB.Avatar src={user.userData.profilePicFileName} className={avatarClasses} style={{ width: 15, height: 15 }} />
                        <div className="text-heavy text-sml">{user.userData.firstName} {user.userData.lastName}</div>
                    </div>
                </UILIB.DropdownMenu.Trigger>
                <UILIB.DropdownMenu.Portal>
                    <UILIB.DropdownMenu.Content align="start">

                        <UILIB.DropdownMenu.RadioGroup value={1} onClick={() => { history.push(`/cp/conversations/${activeInbox.id}/preferences`, { prevPath: location.pathname }) }}>
                            <UILIB.DropdownMenu.RadioItem value="unassigned" >
                                {i18n.t("chat:userPreferences.yourPreferences")}
                            </UILIB.DropdownMenu.RadioItem>
                        </UILIB.DropdownMenu.RadioGroup>
                        <UILIB.DropdownMenu.Separator />
                        <UILIB.DropdownMenu.RadioGroup value={1} onClick={goLogout}>
                            <UILIB.DropdownMenu.RadioItem value="unassigned" >
                                <UILIB.Icon name="logout" color="#9B9FA6" /> Logout
                            </UILIB.DropdownMenu.RadioItem>
                        </UILIB.DropdownMenu.RadioGroup>

                    </UILIB.DropdownMenu.Content>
                </UILIB.DropdownMenu.Portal>
            </UILIB.DropdownMenu.Root >

            {showOnlineStatus && userRole < 2 && <UILIB.Toggle before={user.userData.chatOnline ? i18n.t("chat.inbox.leftBar.available") : i18n.t("chat.inbox.leftBar.away")} checked={user.userData.chatOnline} onChange={toggle} />}
        </div >
    )
}
import React from 'react'
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { connect } from 'react-redux';

const Map = ReactMapboxGl({
    accessToken: "pk.eyJ1IjoiY29udGFjdGdhdGUiLCJhIjoiODI5ZDJlOWNiNGM5YTM4OGM1Mzk2ODA2MjI4MTI2YmQifQ.iEJBZeGfK-7O2nOkPxIBDA"
});

@connect((store) => {
    return {
        siteMaster: store.siteMaster
    }
})


export default class MPZMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mapZoom: [1]
        }
    }

    render() {
        var features = this.props.data.features.map((feature, index) => {
            return <Feature key={"mapC_" + index} coordinates={feature.geometry.coordinates} properties={{
                'marker-color': '#3bb2d0',
                'marker-size': 'large',
                'marker-symbol': 'circle-15'
            }} />
        })

        return <Map
            // style="mapbox://styles/contactgate/cjg3exzgh160u2rp1nkgnn4na"
            style="mapbox://styles/contactgate/cjxq92mlz0c5s1cne6vo6tw6q"
            containerStyle={{
                height: this.props.height || "500px",
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "4px",
            }}
            zoom={this.state.mapZoom}
            onZoom={(event) => { this.setState({ mapZoom: [event.getZoom()] }) }}
        >
            <Layer type="heatmap">
                {features}
            </Layer>
        </Map >;
    }
}

import React, { useState } from 'react'
import moment from 'moment'
import axios from '~/data/http/axios'
import UILIB from '~/Common-Objects/Lib'
import SmsPhone from '~/pages/cp/includes/sms/phone'
import NewUserForm from '~/pages/cp/includes/bb_topNav/newUserForm/newUserForm'
import Topup from '~/pages/cp/company/sms/topup'
import helpers from '~/Classes/helpers'
import i18n from '~/i18n'
import { useDispatch, useSelector } from 'react-redux'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here

export default function SmsSendPreview({ campaign, content }) {
    const accountMaster = useSelector(state => state.accountMaster)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneValid, setPhoneValid] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState('')
    const dispatch = useDispatch()

    const sendPreview = async () => {
        setSending(true)
        setError(null)
        try {
            await axios.post('/transaction/sms', {
                toAddress: phoneNumber,
                subject: campaign.subject,
                content
            })
            setMessage('SMS Sent')
            setTimeout(() => setMessage(''), 5000)
        } catch (e) {
            setError(e.data.error)
        }
        setSending(false)
    }

    const updatePhoneNumber = (e) => {
        setPhoneNumber(e.currentTarget.value)
        setPhoneValid(e.valid)
    }

    const activateAccount = () => {
        const theDrawer = <NewUserForm closeNewUserForm={() => { }} />
        dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, false, "100vw", 'full '));
    }

    const topup = () => {
        const theDrawer = <Topup onClose={() => {
            dispatch(siteMaster.alterSiteDrawer(false));
        }} />
        dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, false, "500px"));
    }

    const timestamp = (moment(campaign.startDate) < moment() ? moment() : moment(campaign.startDate)).format('DD MMM, HH:mm')
    const result = helpers.getSmsCredits(campaign, content)

    return (
        <UILIB.Paper className="mar-b20">
            <h4 className='mar-b20'>{i18n.t('campaigns:standard.step3.testSms')}</h4>
            <UILIB.Row>
                {/* <UILIB.Column xs={6} className="center-xs">
                    <div style={{ height: 300, overflow: 'hidden', justifyContent: 'center', textAlign: '-webkit-center', backgroundColor: '#F8F9FB', borderRadius: 8, padding: 40 }}>
                        <SmsPhone from={campaign.subject} content={result.html} timestamp={timestamp} />
                    </div>
                </UILIB.Column> */}
                {/* <span>({(accountMaster.accountMaster.smsCredits || 0)} credits available)</span> */}
                <UILIB.Column xs={6}>
                    <div className="mar-b10">
                        <UILIB.TextInput type="telno"
                            label={<span style={{ fontWeight: 600 }}>Phone Number <div style={{ fontWeight: 300, fontSize: 12 }}>This will use {result.messages} {result.messages > 1 ? 'credits' : 'credit'}</div> </span>}
                            onChange={updatePhoneNumber}
                            value={phoneNumber}
                            defaultCountry="gb"
                            onlyCountries={['gb']}
                            allowDropdown={false}
                            error={error}
                        />
                    </div>
                    {accountMaster.accountMaster.approved && Boolean(accountMaster.accountMaster.smsCredits) && <span>
                        <UILIB.Button className="button-sml outline" text={"Send Test SMS"} iconRight={<UILIB.Icons icon='arrowRight' />} onClick={sendPreview} />
                    </span>}
                    {!accountMaster.accountMaster.approved && <span className='mar-t15' style={{ display: 'flex', gap: 5, alignItems: 'baseline' }}>
                        <UILIB.Button onClick={activateAccount}>{i18n.t('campaigns:standard.step3.activateAccount')}</UILIB.Button>
                        <div className="mar-b20">{i18n.t('campaigns:standard.step3.activate')}</div>
                    </span>}
                    {!Boolean(accountMaster.accountMaster.smsCredits) && <span className='mar-t15' style={{ display: 'flex', gap: 5, alignItems: 'baseline' }}>
                        <UILIB.Button className="button-sml outline primary" text="Topup" onClick={topup}></UILIB.Button>
                        <div className="mar-b10">No SMS Credits Available</div>
                    </span>}
                    {Boolean(message) && <div className="text-primary text-sml mar-t10">{message}</div>}
                </UILIB.Column>
            </UILIB.Row>
        </UILIB.Paper>
    )
}
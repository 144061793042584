import React, { useState, useEffect, useRef } from 'react'
import axios from '../../../../../../data/http/axios'
import UILIB from '../../../../../../Common-Objects/Lib'
import DateTimeFunctions from '../../../../../../Classes/dateTimeFunctions'
import i18n from '../../../../../../i18n'

export default function CampaignExports({ campaign }) {
    const [reports, setReports] = useState(null)
    const timer = useRef(null)

    const getExports = async () => {
        console.log('hello')
        const res = await axios.get('/campaign/' + campaign.id + '/export')
        setReports(res.data)
        if (res.data.some(r => !r.finished)) timer.current = setTimeout(getExports, 5000)
    }

    const download = async (exportJob) => {
        if (exportJob.location) {
            let exportJobRes = await axios.get('/campaign/' + campaign.id + '/export/' + exportJob.id + '/file')
            const link = document.createElement('a');
            link.href = exportJobRes.data.url
            document.body.appendChild(link);
            link.click();
        }
    }

    useEffect(() => {
        if (campaign.id) getExports()
        return function () {
            if (timer.current) clearTimeout(timer.current)
        }
    }, [campaign.id])

    if (!reports) return <UILIB.LoadingIcons />

    const data = reports.map(report => {
        let statusClass = ''
        if (report.finished) statusClass = 'square-chip-primary'
        else if (report.status === 'queued') statusClass = 'square-chip-secondary'
        else if (report.status === 'errored') statusClass = 'square-chip-red'
        const types = { clicked: 'Clicked', clickedFull: 'Clicked Detail', bounced: 'Bounced', opened: 'Opened', unsubscribed: 'Unsubscribed', sent: 'Sent', }
        return {
            datetime: {
                headerValue: 'Start Date',
                value: DateTimeFunctions.formatDateTime(report.createdAt)
            },
            type: {
                headerValue: 'Type',
                value: types[report.filters.type] || "Sent"
            },
            status: {
                headerValue: 'Status',
                value: <UILIB.SquareChip className={statusClass}>{report.status}</UILIB.SquareChip>
            },
            download: {
                align: 'right',
                headerValue: ' ',
                value: report.finished ? <UILIB.Button className="button-sml button-primary" onClick={() => download(report)}>{i18n.t('campaigns:reports.download')}</UILIB.Button> : ''
            }
        }
    })

    return (
        <div>
            <UILIB.Paper>
                <h4 className="mar-b25">{i18n.t('campaigns:exports.header')}</h4>
                <UILIB.DataTable1
                    noResultsText="No Exports Found"
                    tableData={data}
                />
            </UILIB.Paper>

        </div>
    );
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K_9uaGx7CR4QyrGXTXbr label{font-size:13px;font-weight:500;margin-bottom:4px}.K_9uaGx7CR4QyrGXTXbr .Kvp87s8e7oxJxONZHL3i{color:var(--grey-800);margin-bottom:24px}.K_9uaGx7CR4QyrGXTXbr textarea{resize:vertical}.K_9uaGx7CR4QyrGXTXbr .txtInput{padding:8.5px 10px;border-color:var(--grey-300)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/components/textArea/index.module.scss"],"names":[],"mappings":"AACE,4BACE,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,4CACE,qBAAA,CACA,kBAAA,CAGF,+BACE,eAAA,CAGF,gCACE,kBAAA,CACA,4BAAA","sourcesContent":[".root {\n  label {\n    font-size: 13px;\n    font-weight: 500;\n    margin-bottom: 4px;\n  }\n\n  .description {\n    color: var(--grey-800);\n    margin-bottom: 24px;\n  }\n\n  textarea {\n    resize: vertical;\n  }\n\n  :global(.txtInput) {\n    padding: 8.5px 10px;\n    border-color: var(--grey-300);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "K_9uaGx7CR4QyrGXTXbr",
	"description": "Kvp87s8e7oxJxONZHL3i"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as siteActions from '../../../../../../data/actions/siteActions'
import UILIB from '../../../../../../Common-Objects/Lib'
import axios from '../../../../../../data/http/axios'
import DrawerAddCampaignStep1 from '../../../../campaigns/add/drawerAddCampaignStep1'
import AutomationThemeSelector from '../../../../includes/automations/automationThemeSelector'
import FormPicker from '~/pages/cp/includes/formPicker'

export default function QuickLinks({ groupId, appData }) {
    const permissionStore = useSelector(state => state.permission)
    const history = useHistory()
    const dispatch = useDispatch()

    const addNewAutomaton = () => {
        const drawer = <AutomationThemeSelector scratch={() => history.push('/cp/automation/addnew')} select={theme => {
            axios.post('/automation/theme/' + (theme.id || theme)).then(response => {
                history.push('/cp/automation/addnew/' + response.data.Automation.id)
            })
        }} />
        dispatch(siteActions.alterSiteDrawer(true, true, "left", drawer, true, '100vw', 'full'));
    }

    const addNewCampaign = () => {
        const drawerContent = <DrawerAddCampaignStep1 history={history} />
        dispatch(siteActions.alterSiteDrawer(true, false, "left", drawerContent, true, 500, "drawer_nopad"));
    }

    const addForm = () => {
        var drawer = <FormPicker save={formAdded} />
        dispatch(siteActions.alterSiteDrawer(true, true, "right", drawer, true, "100%"));
    }

    const formAdded = (theme, themeType, options) => {
        var form = {
            formName: '',
            wizard: {
                step1: true
            },
            options,
            ThemeId: theme.id,
            formType: themeType,
        }
        return axios.post('/group/' + groupId + '/form', form).then(response => {
            var redirect = '/cp/groups/' + groupId + '/forms/create/' + response.data.Form.id + '/2'
            history.push('/cp/templates/add/dragdrop?form=true&groupId=' + groupId + '&formId=' + response.data.Form.id + '&redirect=' + redirect);
        })
    }

    return (
        <UILIB.Row>
            <UILIB.Column xs={12} className="no-marg">
                <h4 className='mar-b20'>Quick Links</h4>
            </UILIB.Column>
            {permissionStore.permissions.campaigns !== 'none' && <UILIB.Column xs={12} sm={6} className="mar-b25">
                <UILIB.Paper className="height-100 mar-b0">
                    <h4 className="mar-b10 quickFlex"><div style={{ display: 'inline-flex', marginRight: 10 }}><UILIB.Icons icon="envelopeOpen" circle={true} /></div> Send</h4>
                    <p>Create and send email campaigns, AB split tests and more to your contacts en-mass. <br />Then watch in real time as your emails get opened, links get clicked and much more.</p>
                    <div className='quickFlex'>
                        {permissionStore.permissions.campaigns === 'write' && <UILIB.Button iconLeft={<UILIB.Icons icon="plus" />} text="Create a Campaign" className="mar-r10" onClick={addNewCampaign} />}
                        <UILIB.Button text="View Campaigns" className="button-primary" onClick={() => history.push('/cp/campaigns')} />
                    </div>
                </UILIB.Paper>
            </UILIB.Column>}
            {permissionStore.permissions.automations !== 'none' && <UILIB.Column xs={12} sm={6} className="mar-b25">
                <UILIB.Paper className="height-100 mar-b0">
                    <h4 className="mar-b10 quickFlex"><div style={{ display: 'inline-flex', marginRight: 10 }}><UILIB.Icons icon="lightning" circle={true} /></div> Automate</h4>
                    <p>Automations are a great way to automatically make things happen based on your contacts activity. <br />For example, you could send a welcome email when someone gets added to {appData.appName}.</p>
                    <div className='quickFlex'>
                        {permissionStore.permissions.automations === 'write' && <UILIB.Button iconLeft={<UILIB.Icons icon="plus" />} text="Add an Automation" className="mar-r10" onClick={addNewAutomaton} />}
                        <UILIB.Button text="View Automations" className="button-primary" onClick={() => history.push('/cp/automation')} />
                    </div>
                </UILIB.Paper>
            </UILIB.Column>}
            {permissionStore.permissions.forms !== 'none' && <UILIB.Column xs={12} sm={6} className="mar-b25">
                <UILIB.Paper className="height-100 mar-b0">
                    <h4 className="mar-b10 quickFlex"><div style={{ display: 'inline-flex', marginRight: 10 }}><UILIB.Icons icon="document" circle={true} /></div>Capture</h4>
                    <p>Design forms and capture new contacts, or find out a little more about existing contacts. <br />You can design fully hosted forms, or even forms you can embed on your website, social media page and more.</p>
                    <div className='quickFlex'><UILIB.Button iconLeft={<UILIB.Icons icon="plus" />} text="Create a Form" onClick={addForm} /></div>
                </UILIB.Paper>
            </UILIB.Column>}
            {permissionStore.permissions.company === 'write' && <UILIB.Column xs={12} sm={6} className="mar-b25">
                <UILIB.Paper className="height-100 mar-b0">
                    <h4 className="mar-b10 quickFlex"><div style={{ display: 'inline-flex', marginRight: 10 }}><UILIB.Icons icon="world" circle={true} /></div> Track</h4>
                    <p>Track contacts movements and interactions on your website after they have interacted with your emails. <br />Setup alerts when contacts visit certain pages, track conversions and much more.</p>
                    <div className='quickFlex'><UILIB.Button iconLeft={<UILIB.Icons icon="plus" />} text="Setup Website Tracking" onClick={() => history.push(`/cp/trackers`)} /></div>
                </UILIB.Paper>
            </UILIB.Column>}
        </UILIB.Row>
    )
}
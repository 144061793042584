import React from 'react'

export default class IconsLineArrowRight extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.29303 14.707C7.10556 14.5195 7.00024 14.2652 7.00024 14C7.00024 13.7349 7.10556 13.4806 7.29303 13.293L10.586 10L7.29303 6.70704C7.11087 6.51844 7.01008 6.26584 7.01236 6.00364C7.01463 5.74144 7.1198 5.49063 7.30521 5.30522C7.49062 5.11981 7.74143 5.01465 8.00363 5.01237C8.26583 5.01009 8.51843 5.11088 8.70703 5.29304L12.707 9.29304C12.8945 9.48057 12.9998 9.73488 12.9998 10C12.9998 10.2652 12.8945 10.5195 12.707 10.707L8.70703 14.707C8.5195 14.8945 8.26519 14.9998 8.00003 14.9998C7.73487 14.9998 7.48056 14.8945 7.29303 14.707Z" fill={color} />
        </svg>






    }
}        
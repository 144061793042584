// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LCO7on0_L3mYUMmGPW5z{display:flex;flex-direction:column;gap:32px}.kg504S_OsM1sxVtYbdGE{align-self:flex-start}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/channel/webchat/availabilityMessages/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,sBACE,qBAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n}\n\n.submitButton {\n  align-self: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LCO7on0_L3mYUMmGPW5z",
	"submitButton": "kg504S_OsM1sxVtYbdGE"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from '../../../../data/http/axios'
import UILIB from '../../../../Common-Objects/Lib'
import FormatNumberFuncs from '../../../../Classes/numberFormatFunctions'

export default function SiteVisitsPanel({ edit }) {
    const [stats, setStats] = useState(null)
    const siteMaster = useSelector(state => state.siteMaster)

    const getStats = async () => {
        const res = await axios.get('/accountMaster/tracker/stats')
        setStats(res.data)
    }

    useEffect(() => {
        getStats()
    }, [])

    let increase = false
    let diff = 0
    if (stats) {
        increase = stats[0] > stats[1]
        diff = increase
            ? Math.round((stats[0] / stats[1]) * 100) - 100
            : 100 - (Math.round((100 / stats[1]) * stats[0]))
    }
    const color = increase ? siteMaster.colours["$status-green"] : siteMaster.colours["$status-red"]

    return <UILIB.Paper className="m-mar-b0" iconRight={<UILIB.Icons icon="pencil" color="grey" />} iconRightClick={edit}>

        <h4 className="mar-b20">Site Visits</h4>
        <div>
            {!stats && <UILIB.LoadingIcons />}
            {!!stats && <>
                <div className="quickFlex mar-b10">
                    <h4 className="mar-r16 statistic">{FormatNumberFuncs.formatNumber(stats[0])}</h4>
                    {!isNaN(diff) && <UILIB.Indicator leftIcon={<UILIB.Icons icon={increase ? "arrowUp" : "arrowDown"} color={color} />} className={increase ? "indicator-green" : "indicator-red"}>{diff}%</UILIB.Indicator>}
                    {isNaN(diff) && <UILIB.Indicator className="indicator-grey">0.00%</UILIB.Indicator>}
                </div>
                <div>people visited your sites this month</div>
            </>}
        </div>


    </UILIB.Paper>
}
import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import * as siteMaster from '../../../data/actions/siteActions'

@connect((store) => {
    return { siteMaster: store.siteMaster }
})

export default class Drawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            firstRun: true
        }
        this.closeDrawer = this.closeDrawer.bind(this);
        this.handleAllClicks = this.handleAllClicks.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.standalone && this.props.isOpen !== undefined) {
            if (this.props.isOpen !== this.state.isOpen) {
                this.setState({ isOpen: this.props.isOpen, firstRun: false })
            }
        } else {
            var thisDrawer = this.props.siteMaster.siteDrawer;
            var thisPage = this.props.siteMaster.currentPage;

            if (thisDrawer && thisDrawer != prevProps.siteMaster.siteDrawer) {
                this.setState({ isOpen: thisDrawer.isOpen, firstRun: false })

                const path = window.location.pathname
                if (thisDrawer.isOpen && path !== '/cp/subscribers' && path.indexOf('/cp/groups/') !== 0) {
                    document.body.classList.add('remove-overflow')
                } else {
                    document.body.classList.remove('remove-overflow')
                }
            }

            if (thisPage != prevProps.siteMaster.currentPage) {
                document.body.classList.remove('remove-overflow')
                this.setState({ isOpen: false, firstRun: true })
            }
        }
    }

    componentDidMount() {
        if (this.props.standalone) {
            if (this.props.persist) {
                // this.setState({ firstRun: false })
            } else if (this.props.isOpen || this.props.isOpen === undefined) {
                this.setState({
                    isOpen: true, firstRun: false
                })
                document.body.classList.add('remove-overflow')
            }
        }
        if (this.props.clickOutside === true) {
            document.addEventListener('click', this.handleAllClicks, false);
        }
    }

    componentWillUnmount() {
        if (!this.props.ignoreOverflow) {
            document.body.classList.remove('remove-overflow')
            if (this.props.clickOutside === true) {
                document.removeEventListener('click', this.handleAllClicks, false);
            }
        }
    }

    closeDrawer(event) {
        if (event && typeof event.stopPropagation === 'function') {
            event.stopPropagation();
            event.preventDefault();
        }
        if (this.props.standalone) {
            if (this.props.startClose && typeof this.props.startClose === 'function') {
                this.props.startClose()
            }
            this.setState({
                isOpen: false
            }, () => {
                setTimeout(this.props.close, 500)
            })
        } else {
            if (this.props.siteMaster.siteDrawer.onClose) {
                this.props.siteMaster.siteDrawer.onClose(event);
            }

            this.props.dispatch(siteMaster.alterSiteDrawer(false, this.props.siteMaster.siteDrawer.showClose, this.props.siteMaster.siteDrawer.direction, null, false, this.props.siteMaster.siteDrawer.width, this.props.siteMaster.siteDrawer.className));
        }
    }

    handleAllClicks(event) {
        if (!ReactDOM.findDOMNode(this).contains(event.target) && !ReactDOM.findDOMNode(this).contains(event.currentTarget) && event.target.className.indexOf("header-label") == -1) {
            this.closeDrawer();
        }
    }

    render() {
        var theClasses = "";
        if (this.state.firstRun === false) {
            if (this.state.isOpen === true) {
                theClasses += " slideIn";
            }
            else {
                theClasses += " slideOut";
            }
        }

        if (this.props.standalone) {
            var theStyle = {};
            if (this.props.style) {
                theStyle = { ...this.props.style };
            }

            if (this.props.width) {
                theStyle.width = this.props.width;
            }

            if (this.props.className) {
                theClasses += " " + this.props.className;
            }

            return <div className={"uiDrawer" + theClasses} style={theStyle} ref="theDrawer">
                {this.props.showClose && <div className="exit cyt-cross-drawer-pop" onClick={this.closeDrawer}>
                    <span className="icon-cross2"></span>
                </div>}
                <div className="uiDrawerInner">
                    {this.props.children}
                </div>
            </div>
        } else {
            if (this.props.siteMaster.siteDrawer && this.props.siteMaster.siteDrawer.content && React.isValidElement(this.props.siteMaster.siteDrawer.content)) {
                var theStyle = {};

                if (this.props.siteMaster.siteDrawer.width) {
                    theStyle.width = this.props.siteMaster.siteDrawer.width;
                }

                if (this.props.siteMaster.siteDrawer.className) {
                    theClasses += " " + this.props.siteMaster.siteDrawer.className;
                }

                return <div className={"uiDrawer" + theClasses} style={theStyle} ref="theDrawer">
                    {this.props.siteMaster.siteDrawer.showClose && <div className="exit cyt-cross-drawer" onClick={this.closeDrawer}>
                        <span className="icon-cross2"></span>
                    </div>}
                    <div className="uiDrawerInner">
                        {this.props.siteMaster.siteDrawer.content}
                    </div>
                </div>
            }
            else {
                return <div className={"uiDrawer" + theClasses}></div>
            }
        }
    }
}



import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
@connect((store) => {
    return { user: store.user }
})
export default class IncViewOpens extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            loadedData: false
        };
        this.closeDrawer = this.closeDrawer.bind(this);
        this.getHistory = this.getHistory.bind(this);
    }
    componentDidMount() {
        this.getHistory();
    }

    getHistory() {
        axios.get('/campaign/' + this.props.campaignId + '/stats/opened/' + this.props.subscriberId).then(_res => {
            var theTableData = _res.data.Opened
                .map(thisLine => {
                    let img = "";
                    if (thisLine.country && thisLine.country != "" && thisLine.country != "-") {
                        img = `https://cdn1.ourmailsender.com/siteContent/countries/raw/${thisLine.country.toLowerCase()}.png`
                    }

                    return {
                        country: {
                            headerValue: i18n.t('country'),
                            value: <img src={img} width="20" alt={thisLine.country ? thisLine.country : ""} title={thisLine.country ? thisLine.country : ""} />,
                            width: 20
                        },
                        openDate: {
                            headerValue: i18n.t('campaigns:nav.openedPopup.openDate'),
                            value: <span>{DateTimeFunctions.formatDateTime(thisLine.openDate, 1)}</span>,
                            orderBy: false
                        },
                        device: {
                            headerValue: i18n.t('campaigns:nav.openedPopup.device'),
                            value: thisLine.device,
                            orderBy: false
                        },
                        robot: {
                            headerValue: "Robot",
                            value: thisLine.isBot ? <UILIB.Icons icon="tick" /> : "",
                            orderBy: false
                        }
                    }
                })
            this.setState({ loadedData: true, tableData: theTableData })
        })
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }




    render() {

        if (!this.state.loadedData) {
            return <div><UILIB.LoadingIcons iconType="2" /></div>
        }
        return <div>

            <h4 className="mar-b25">{this.props.emailAddress}</h4>
            <div className="mar-b25">{i18n.t('campaigns:nav.openedPopup.title')}</div>
            <UILIB.DataTable1 noResultsTxt={<span> {i18n.t('noResults')}</span>} tableData={this.state.tableData} loadingData={this.state.loadedData} hasCheckBoxes="no" />


        </div>
    }
};


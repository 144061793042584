import React from 'react'

export default function IconsLifeRing({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.5 10.0001C3.5 8.56596 3.96443 7.24033 4.7511 6.16537L6.46263 7.87691C6.08956 8.49714 5.875 9.22355 5.875 10.0001C5.875 10.7766 6.08956 11.503 6.46263 12.1232L4.7511 13.8348C3.96443 12.7598 3.5 11.4342 3.5 10.0001ZM4.0374 16.0579C5.57167 17.5682 7.67701 18.5001 10 18.5001C12.323 18.5001 14.4283 17.5682 15.9625 16.058C15.9793 16.0432 15.9958 16.0279 16.0118 16.0119C16.0278 15.9958 16.0432 15.9794 16.0579 15.9626C17.5682 14.4283 18.5 12.323 18.5 10.0001C18.5 7.67707 17.5681 5.57173 16.0578 4.03746C16.0431 4.02068 16.0278 4.00427 16.0118 3.98827C15.9958 3.97227 15.9794 3.95692 15.9626 3.94223C14.4283 2.43192 12.323 1.50006 10 1.50006C7.67698 1.50006 5.57161 2.43195 4.03733 3.94229C4.02057 3.95696 4.00419 3.97229 3.98821 3.98827C3.97223 4.00425 3.9569 4.02063 3.94223 4.03739C2.43189 5.57167 1.5 7.67704 1.5 10.0001C1.5 12.3231 2.43186 14.4284 3.94217 15.9627C3.95686 15.9795 3.9722 15.9959 3.98821 16.0119C4.00421 16.0279 4.02062 16.0432 4.0374 16.0579ZM6.16532 15.249L7.87685 13.5374C8.49708 13.9105 9.22348 14.1251 10 14.1251C10.7765 14.1251 11.5029 13.9105 12.1232 13.5374L13.8347 15.249C12.7597 16.0356 11.4341 16.5001 10 16.5001C8.5659 16.5001 7.24027 16.0356 6.16532 15.249ZM15.2489 13.8347L13.5374 12.1232C13.9104 11.503 14.125 10.7766 14.125 10.0001C14.125 9.22355 13.9104 8.49714 13.5374 7.87691L15.2489 6.16538C16.0356 7.24033 16.5 8.56596 16.5 10.0001C16.5 11.4342 16.0356 12.7598 15.2489 13.8347ZM13.8347 4.75116L12.1232 6.46269C11.5029 6.08962 10.7765 5.87506 10 5.87506C9.22348 5.87506 8.49708 6.08962 7.87685 6.46269L6.16531 4.75116C7.24027 3.96449 8.5659 3.50006 10 3.50006C11.4341 3.50006 12.7597 3.9645 13.8347 4.75116ZM7.875 10.0001C7.875 8.82646 8.8264 7.87506 10 7.87506C11.1736 7.87506 12.125 8.82646 12.125 10.0001C12.125 11.1737 11.1736 12.1251 10 12.1251C8.8264 12.1251 7.875 11.1737 7.875 10.0001Z" fill={fillColor} />
        </svg>
    )
}
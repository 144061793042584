import React from "react";

export default function IconStarOutline({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.58265L9.31822 6.65832C8.87387 8.01113 7.60881 8.9076 6.20286 8.9076H4.05735L5.77438 10.1416C6.92482 10.9685 7.41712 12.4462 6.97146 13.803L6.30405 15.8349L8.08172 14.5573C9.22699 13.7342 10.773 13.7342 11.9183 14.5573L13.6959 15.8349L13.0285 13.803C12.5829 12.4462 13.0752 10.9685 14.2256 10.1416L15.9426 8.9076H13.7971C12.3912 8.9076 11.1261 8.01113 10.6818 6.65832L10 4.58265ZM11.2152 1.8734C10.8327 0.708868 9.16727 0.708868 8.78476 1.8734L7.41809 6.0342C7.24703 6.55499 6.75642 6.9076 6.20286 6.9076H1.78022C0.542414 6.9076 0.0277571 8.47446 1.02917 9.19418L4.60715 11.7657C5.055 12.0876 5.24239 12.6581 5.07133 13.1789L3.70466 17.3397C3.32216 18.5042 4.66955 19.4726 5.67096 18.7529L9.24894 16.1813C9.69679 15.8595 10.3032 15.8595 10.7511 16.1813L14.329 18.7529C15.3305 19.4726 16.6778 18.5042 16.2953 17.3397L14.9287 13.1789C14.7576 12.6581 14.945 12.0876 15.3928 11.7657L18.9708 9.19418C19.9722 8.47446 19.4576 6.9076 18.2198 6.9076H13.7971C13.2436 6.9076 12.753 6.55499 12.5819 6.0342L11.2152 1.8734Z"
        fill={color}
      />
    </svg>
  );
}

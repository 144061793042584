import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'

class SaveChangesDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return (
            <div>
                <h4 className="mar-b25">Save Changes?</h4>
                <p>Would you like to save your changes before you exit?</p>

                <div className="quickFlex">
                    <UILIB.Button className="button-md primary mar-r5" text="Yes" onClick={() => { this.props.confirm(true) }} />
                    <UILIB.Button className="button-md secondary" text="No" onClick={() => { this.props.confirm(false) }} />
                </div>

            </div>
        );
    }
}

export default SaveChangesDrawer;
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import AddGroupView from '~/pages/cp/subscribers/groups/incGoAddGroup';

class SelectGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            group: undefined
        }

        this.addAGroup = this.addAGroup.bind(this)
        this.changeGroup = this.changeGroup.bind(this)
        this.select = this.select.bind(this)
    }

    componentDidMount() {
        axios.get('/group').then(response => {
            this.setState({
                groups: response.data.Groups
            })
        })
    }

    addAGroup() {
        var drawerContent = <AddGroupView addedGroup={(response) => {
            var group = response.data.groupId;
            this.setState({ group }, this.getGroups);
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    changeGroup(ev) {
        this.setState({
            group: ev.currentTarget.value
        })
    }

    select() {
        this.props.finish(this.state.group)
    }

    render() {
        return (
            <div>
                {this.state.groups.length &&
                    <div className="quickFlex">
                        <div className="form-group mar-b0"><UILIB.Select data={this.state.groups} placeholder="--Select a Group--" name="groupId" value={this.state.group} onChange={this.changeGroup} /></div>
                        <span style={{ marginLeft: "10px" }}>or</span>
                        <UILIB.Button text="Add a new Group" className="button-sml secondary outline" style={{ marginLeft: "10px" }} onClick={this.addAGroup} />
                    </div>
                }
                {!!this.state.group && <div>
                    <button className="button button-md outline primary" onClick={this.select}>Select</button>
                </div>}
            </div>
        );
    }
}

export default SelectGroup;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer
} from 'recharts';
import UILIB from '~/Common-Objects/Lib';
import axios from '~/data/http/axios';
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
import NumberFormat from '~/Classes/numberFormatFunctions';
import moment from 'moment';
import i18n from '~/i18n';
import Welcome from './welcome';
import Overview from './overview';

const Map = ReactMapboxGl({
    accessToken: "pk.eyJ1IjoiY29udGFjdGdhdGUiLCJhIjoiODI5ZDJlOWNiNGM5YTM4OGM1Mzk2ODA2MjI4MTI2YmQifQ.iEJBZeGfK-7O2nOkPxIBDA"
});

@connect((store) => {
    return { siteMaster: store.siteMaster, user: store.user }
})
class TransactionsAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            setup: true,
            campaign: {},
            stats: {},
            filtered: {},
            startDate: moment().subtract(1, 'week'),
            endDate: moment(),
            geos: {
                type: "FeatureCollection", features: []
            },
            chartData: [],
            groupOptions: [{
                value: 'HOUR',
                label: i18n.t('campaigns:transactions.24Hours'),
                color: 'purple',
                format: 'HH:mm'
            }, {
                value: 'DAYOFWEEK',
                label: i18n.t('campaigns:transactions.week'),
                color: 'red',
                format: 'ddd'
            }, {
                value: 'MONTHNAME',
                label: i18n.t('campaigns:transactions.month'),
                color: 'green',
                format: 'MM-DD'
            }, {
                value: 'YEAR',
                label: i18n.t('campaigns:transactions.year'),
                color: 'blue',
                format: 'MMM'
            }],
            currentGroupBy: 1,
        }

        this.timer;
        this.unmounting = false
        this.getStats = this.getStats.bind(this)
        this.updateDate = this.updateDate.bind(this)
        this.getGraph = this.getGraph.bind(this);
        this.changeTimePeriod = this.changeTimePeriod.bind(this)
    }

    componentDidMount() {
        this.getGraph()
        this.getStats().then(result => {
            if (result) {
                this.timer = setInterval(() => {
                    this.getStats()
                    this.getGraph()
                }, 1000 * 10)
            }
        })
    }

    componentWillUnmount() {
        this.unmounting = true;
        if (this.timer) clearInterval(this.timer)
    }

    changeTimePeriod(index) {
        this.setState({
            currentGroupBy: index
        }, this.getGraph)
    }

    getStats() {
        // this.setState({
        //     loaded: true,
        //     setup: false
        // })
        // return Promise.resolve(false)
        if (this.unmounting) return

        return axios.get('/transaction/overview?startDate=' + this.state.startDate.startOf('day').toDate() + '&endDate=' + this.state.endDate.endOf('day').toDate()).then(response => {
            if (this.unmounting) return
            if (response.data.Campaign) {
                var obj = {
                    loaded: true,
                    campaign: response.data.Campaign,
                    stats: {},
                    filtered: response.data.Campaign.filtered,
                    geos: this.state.geos
                }
                obj.campaign.CampaignStats.forEach(stat => {
                    obj.stats[stat.key] = stat.value
                })
                obj.geos.features = response.data.Campaign.filtered.geos.map(row => {
                    return {
                        type: "Feature",
                        properties: {},
                        geometry: {
                            type: "Point",
                            coordinates: [row.longitude, row.latitude]
                        }
                    }
                })

                this.setState(obj)
                return true
            } else {
                // transactions not setup yet so show some other info
                this.setState({
                    loaded: true,
                    setup: false
                })
                return false
            }
        })
    }

    getGraph() {
        if (this.unmounting) return
        var startDate = moment();
        var endDate = moment().endOf('day')
        switch (this.state.currentGroupBy) {
            case 0:
                startDate.subtract(23, 'hours');
                endDate = moment()
                break;
            case 1:
                startDate.subtract(6, 'days').startOf('day');
                break;
            case 2:
                startDate.subtract(4, 'weeks').startOf('week');
                endDate = moment().endOf('week')
                break;
            case 3:
                startDate.subtract(11, 'months');
                endDate = moment().endOf('month')
                break;
        }

        axios.get('/transaction/graph?group=' + this.state.groupOptions[this.state.currentGroupBy].value + '&startDate=' + startDate.startOf('day').toDate() + '&endDate=' + endDate.endOf('day').toDate()).then(response => {
            if (this.unmounting) return
            var chartData = response.data.Stats.map(row => {
                var date = moment(row.str).locale(this.props.user.userData.language).format(this.state.groupOptions[this.state.currentGroupBy].format)
                return {
                    date: date.toUpperCase(),
                    [i18n.t('sends')]: row.sent,
                    [i18n.t('opens')]: row.opened,
                    [i18n.t('clicks')]: row.clicked,
                    [i18n.t('bounces')]: row.bounced
                }
            })
            this.setState({
                chartData
            })
        })
    }

    updateDate(dates) {
        this.setState({ startDate: dates.startDate, endDate: dates.endDate }, this.getStats)
    }

    render() {
        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />
        if (this.state.setup) {

            return <Overview
                history={this.props.history}
                siteMaster={this.props.siteMaster}
                geos={this.state.geos}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                filtered={this.state.filtered}
                groupOptions={this.state.groupOptions}
                currentGroupBy={this.state.currentGroupBy}
                chartData={this.state.chartData}
                changeTimePeriod={this.changeTimePeriod}
                updateDate={this.updateDate}
            />
        } else {
            return <Welcome history={this.props.history} />
        }
    }
}

export default TransactionsAnalytics;
import React from 'react'

export default function IconDownload3({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#F4F6FA" />
            <g clipPath="url(#clip0_3180_8885)">
                <path d="M12.6667 16.0001L16.0001 19.3334M16.0001 19.3334L19.3334 16.0001M16.0001 19.3334V12.6667M24.3334 16.0001C24.3334 20.6025 20.6025 24.3334 16.0001 24.3334C11.3977 24.3334 7.66675 20.6025 7.66675 16.0001C7.66675 11.3977 11.3977 7.66675 16.0001 7.66675C20.6025 7.66675 24.3334 11.3977 24.3334 16.0001Z" stroke={fillColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_3180_8885">
                    <rect width="20" height="20" fill="white" transform="translate(6 6)" />
                </clipPath>
            </defs>
        </svg>
    )
}
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class StaffCard extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {

        var theUser = this.props.userData;
        var theRoles = this.props.rolesData;

        var isOurUser = false;
        var ourCurrRoleTxt;
        if (theUser.id === this.props.user.userID) {
            isOurUser = true;
        }
        var fullName = null;
        if (theUser.firstName != null) {
            fullName = theUser.firstName;
        }
        if (theUser.lastName != null) {
            fullName += " " + theUser.lastName;
        }
        if (fullName === null) {
            fullName = "No Name Yet";
        }
        if (this.props.writeAccess === true && this.props.roleAccess === true) {
            ourCurrRoleTxt = theRoles.find(a => theUser.roleId === a.id).name;
        }
        var cardType = "primary";
        var buttType = "";

        if (ourCurrRoleTxt == "admin") {
            cardType = "secondary";
            buttType = "secondary";
        }
        if (theUser.registered === false) {
            cardType = "";
        }

        let avatarClasses = "mar-r10 "
        if (theUser.chatOnline) avatarClasses += "avatarHolderStatus green"

        return <UILIB.Paper className="cards-user">

            {!isOurUser && this.props.writeAccess === true && <div className="cards-user-delete">
                <UILIB.Icons icon="bin" onClick={() => this.props.onDelete(theUser.id)} />
            </div>}
            {theUser.twoFAEnabled && <div className="cards-user-2fa">
                <UILIB.SquareChip className="square-chip-purple" iconLeft={<UILIB.Icons icon="shieldtick" />}>2FA Enabled</UILIB.SquareChip>
            </div>}


            <div className="cards-user-avatar">
                <UILIB.Avatar
                    style={{ height: 'auto', width: '100%', maxWidth: "50px", maxHeight: "50spx" }}
                    src={theUser.profilePicFileName}
                    className={avatarClasses}
                />
            </div>
            <div className="cards-user-name">
                <h4>{fullName}</h4>
            </div>
            <div className="cards-user-email">
                <p>{theUser.emailAddress}</p>
            </div>
            {!this.props.inbox && <div className="cards-user-userType">
                <p>
                    {!theUser.registered && <UILIB.SquareChip
                        className="square-chip-orange">{i18n.t('company:staff.awaitingConfirmation')}
                    </UILIB.SquareChip>
                    }
                    {theUser.registered && <UILIB.SquareChip
                        iconLeft={<UILIB.Icons icon="tick" />}
                        className="square-chip-green">{i18n.t('company:staff.confirmed')}
                    </UILIB.SquareChip>}
                </p>
            </div>}
            {this.props.writeAccess === true && this.props.roleAccess === true &&
                <div className="cards-user-options">
                    <UILIB.Select disabled={!this.props.inbox && (isOurUser || theRoles.length === 1)} data={theRoles.map(r => ({ label: r.name, value: r.id }))} value={theUser.roleId} onChange={e => this.props.updateRole(this.props.dataId, e.currentTarget.value)} />
                </div>
            }
        </UILIB.Paper>

    }
}

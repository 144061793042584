import React from 'react'

export default function IconDrag({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5.875" y="3.875" width="2.25" height="2.25" rx="1.125" fill={fillColor} stroke={fillColor} strokeWidth="0.25" />
            <rect x="5.875" y="8.875" width="2.25" height="2.25" rx="1.125" fill={fillColor} stroke={fillColor} strokeWidth="0.25" />
            <rect x="5.875" y="13.875" width="2.25" height="2.25" rx="1.125" fill={fillColor} stroke={fillColor} strokeWidth="0.25" />
            <rect x="10.875" y="3.875" width="2.25" height="2.25" rx="1.125" fill={fillColor} stroke={fillColor} strokeWidth="0.25" />
            <rect x="10.875" y="8.875" width="2.25" height="2.25" rx="1.125" fill={fillColor} stroke={fillColor} strokeWidth="0.25" />
            <rect x="10.875" y="13.875" width="2.25" height="2.25" rx="1.125" fill={fillColor} stroke={fillColor} strokeWidth="0.25" />
        </svg>
    )
}
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import * as siteMaster from '~/data/actions/siteActions';

export default function ValidateCountry({ country, account, reload = () => { } }) {
    const [checked, setChecked] = useState(false)
    const [valid, setValid] = useState(true)
    const [noVatReason, setNoVatReason] = useState('')
    const [error, setError] = useState('')
    const [vatNumber, setVatNumber] = useState(account.vatNumber)
    const dispatch = useDispatch()

    const checkVat = async () => {
        const result = await axios.post('/accountMaster/checkVat', {
            country: country.code,
            vatNumber: account.vatNumber
        })
        if (result.valid) {
            save()
        } else {
            setChecked(true)
            setValid(false)
        }
    }

    const save = async () => {
        const obj = { ...account, vatNumber }
        if (!valid) {
            if (!noVatReason) return setError('Required')
            obj.noVatReason = noVatReason
        }
        await axios.put('/accountMaster', obj)
        dispatch(siteMaster.alterSiteDrawer(false, true, 'right', null))
    }

    const cancel = () => {
        dispatch(siteMaster.alterSiteDrawer(false, true, 'right', null))
    }

    return (
        <div>
            <div className="mar-b25">We need to validate your company when you are in the EU</div>

            <UILIB.TextInput label="VAT Number" value={vatNumber} onChange={e => setVatNumber(e.target.value)} placeholder="VAT Number" />

            <div className="center-xs mar-b25">
                <UILIB.Button text="Validate" className="button-primary" onClick={checkVat} />
            </div>
            {checked && <div>
                {!valid && <div>
                    <div className="mar-b10">Your VAT number is not valid, your account will need to be reverified manually</div>
                    <div className="mar-b10">Please provide a reason for your VAT Number being invalid or provide another way to verify your company</div>

                    <UILIB.TextArea outerClassName="mar-b25" value={noVatReason} rows="3" onChange={e => setNoVatReason(e.target.value)} placeholder="(website/company Id etc.)" error={error} />

                    <div className="quickFlex">
                        <UILIB.Button className="button-primary mar-r10" text="Save" onClick={save} />
                        <UILIB.Button text="Cancel" onClick={cancel} />
                    </div>
                </div>}
            </div>}
        </div >
    )

}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

export default class CleanTemplateView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoaded: false,
            jobResults: [],
            totalRecords: 0,
            pageSize: 10,
            currentPage: 0,
            loading: false
        };

        this.tableUpdater = this.tableUpdater.bind(this);
        this.getJobResults = this.getJobResults.bind(this);

    }

    componentDidMount() {
        if (this.props.siteMaster.listCleanDisabled) return this.props.history.push("/cp")
        this.getJobResults();
    }



    getJobResults() {
        var self = this;
        this.setState({
            loading: true
        })
        axios.get('/listcleaner/' + this.props.jobId + '/results?status=' + this.props.rType + '&limit=' + this.state.pageSize + '&offset=' + this.state.startRecord).then(_resp => {

            var tableData = _resp.data.results.map((item) => {

                var validColor = "red";
                if (item.valid == 1) validColor = "green"
                if (item.disposable == 1) validColor = "orange"
                if (item.free == 1) validColor = "orange"

                return {
                    emailAddress: {
                        headerValue: i18n.t('emailAddress'),
                        value: item.emailAddress
                    },

                    valid: {
                        headerValue: "Valid",
                        value: <UILIB.SquareChip value={item.valid == "1" ? "Valid" : "Not Valid"} className={item.valid == "1" ? "square-chip-green" : "square-chip-red"} />,
                        align: "center"
                    },
                    acceptAll: {
                        headerValue: "Catch All",
                        value: <UILIB.CheckBox checked={item.acceptAll} />,
                        align: "center"

                    },
                    disposable: {
                        headerValue: "Disposable",
                        value: <UILIB.CheckBox checked={item.disposable} />,
                        align: "center"
                    },
                    free: {
                        headerValue: "free address",
                        value: <UILIB.CheckBox checked={item.free} />,
                        align: "center"
                    },
                    noMx: {
                        headerValue: "No Mailserver",
                        value: <UILIB.CheckBox checked={item.noMx} />,
                        align: "center"
                    },
                    role: {
                        headerValue: "Role Based",
                        value: <UILIB.CheckBox checked={item.role} />,
                        align: "center"
                    }
                }
            })
            self.setState({ jobResults: tableData, totalRecords: _resp.data.total, pageLoaded: true, loading: false })
        })
    }

    tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {

        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!currentPage && currentPage != "0") {
            currentPage = this.state.currentPage;
        }
        var startRecord = currentPage * pageSize;
        this.setState({ currentPage: currentPage, startRecord: startRecord }, this.getJobResults);

    }

    render() {

        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />

        return <div>
            <h4 className="mar-b25" style={{ textTransform: "capitalize" }}>{this.props.rType} Email Addresses</h4>
            <div className="mar-b25">Here are the {this.state.totalRecords} {this.props.rType} email addresses that were found</div>

            <UILIB.Paper isLoading={this.state.loading}>
                <UILIB.DataTable1 noResultsTxt={<span> {i18n.t('subscribers:nav.listCleanResultsNone')}</span>} tableData={this.state.jobResults} loadingData={false}
                    width="100%" pageSize="10" hasCheckBoxes="no" sortedColumn={"createdAt"}
                    sortedDirection={"desc"} />
            </UILIB.Paper>

            <div className="center-xs">
                <UILIB.PagingBlock totalRows={this.state.totalRecords} pageSize={this.state.pageSize} numberOfLinks="10" currentPage={this.state.currentPage}
                    changePage={this.tableUpdater} text={i18n.t('page') + ":"} />
            </div>
        </div >
    };
};
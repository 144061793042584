import React from 'react'
import config from '~/Classes/siteVars.js';

export default class SocketUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }

    }

    componentDidMount() {

    }



    render() {
        var ourData = this.props.data;

        var ourWidth = document.body.clientWidth;
        var widthDiff = ourWidth / ourData.screenWidth;
        var posX = ourData.mouseX * widthDiff;
        if ((posX + 30) > ourWidth) posX = posX - 50;
        if (ourData.location == this.props.history.location.pathname) {
            return <div className="socketUser" alt={ourData.fullname} title={ourData.fullname} style={{
                position: "absolute", width: "40px", height: "40px", backgroundColor: "white",
                left: posX + "px",
                top: ourData.mouseY + "px",
                backgroundImage: `url(${config.repoEndPoint + ourData.profilePicture})`,
                backgroundSize: "cover",
                borderRadius: "50px"
            }}></div>
        }
        else {
            return <div></div>
        }

    };
};


import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import EmailTools from '~/Classes/emailValidator';
import i18n from '~/i18n';
import tierPermissions from '~/Classes/tierPermissions';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster }
})

export default class addStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: "",
            inputErrors: {
                emailAddress: ""
            },
            role: undefined,
            roles: null,
            errored: false,
            tierPermissions: {}
        };

        this.updateInput = this.updateInput.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
    }

    componentDidMount() {
        axios.get('/role').then(res => this.setState({ roles: res.data.Roles, role: res.data.Roles[0].id }))
    }

    updateInput(ev) {
        this.setState({ [ev.currentTarget.name]: ev.currentTarget.value })
    }

    checkEmail() {
        var thisEmail = this.state.emailAddress.trim();
        var inputErrors = this.state.inputErrors;
        inputErrors.emailAddress = "";
        if (EmailTools.validateEmail(thisEmail) === false) {
            inputErrors.emailAddress = "Invalid Email Address";
            this.setState({ inputErrors: inputErrors })
            return;
        }
        this.setState({ inputErrors: inputErrors });
        var data = { emailAddress: thisEmail, RoleId: this.state.role };
        axios.post("/accountMaster/register", data).then(res => {
            this.props.onAddedUser();
        }).catch(err => {
            if (err && err.data && err.data.error && err.data.error.tierName) {
                this.setState({ tierPermissions: err.data.error, errored: true })
            }
            else {
                console.log(err);
            }
        })
    }

    render() {
        let userPermissions = tierPermissions("users", this.props.accountMaster)
        if (userPermissions && userPermissions.limit > 0 && this.props.userCount >= userPermissions.limit) {
            return <UILIB.TierLimitHolder type={"user"} err={"tierOverLimit"} allowedNumber={userPermissions.limit} tierName={userPermissions.tierName} />
        }
        if (!this.state.roles) return <UILIB.LoadingIcons iconType="2" />
        if (this.state.errored) return <UILIB.TierLimitHolder type="User" err={this.state.tierPermissions.err} allowedNumber={this.state.tierPermissions.allowedNumber} tierName={this.state.tierPermissions.tierName} />
        return <div>
            <h4 className="mar-b25">{i18n.t('company:staff.addStaffHeader')}</h4>
            <UILIB.TextInput label={i18n.t('emailAddress')} focus={true} type="text" placeholder={i18n.t('emailAddressPlaceholder')} name="emailAddress" error={this.state.inputErrors.emailAddress} value={this.state.emailAddress} onChange={this.updateInput} outerClassName="mar-b25" />
            {this.state.roles.length > 1 && <UILIB.Select label="Role" outerClassName="mar-b25" value={this.state.role} onChange={e => this.setState({ role: e.currentTarget.value })} data={this.state.roles.map(r => ({ label: r.name, value: r.id }))} />}
            <UILIB.Button text={i18n.t('continue')} className="button-primary" onClick={this.checkEmail} />

        </div>
    }
};



import React from 'react'
import { useState } from "react";
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import Help from '~/Common-Objects/Logos/help'

export default function CampaignAdvancedOptions({ campaign, totalSubs, showGroups = true, showSegments = true, isSms = false, scrollIntoViewTarget, totalExcluded = 0, updateCampaign = () => { }, saveCampaign = () => { }, countSubs = () => { }, addGroups = () => { }, addSegments = () => { }, changedAddedGroups = () => { }, editSegment = () => { }, close = () => { }, groupsExcluded = [], segmentsExcluded = [], canEditSegments }) {
    const siteMaster = useSelector(state => state.siteMaster)
    const history = useHistory()

    const [showBatchSize, setShowBatchSize] = useState((campaign.batchSize > 0 || campaign.batchDelay > 0) ? true : false)
    const [showLimit, setShowLimit] = useState((campaign.limit > 0) ? true : false)

    const update = (ev) => {

        if (ev.target.name == "addUtmTracking") {
            campaign[ev.currentTarget.name] = ev.currentTarget.checked
        } else if (ev.target.name == "filterSegments") {
            if (!campaign.options) campaign.options = {};
            campaign.options.filterSegments = ev.currentTarget.checked;
            countSubs()
        } else if (ev.target.name == 'doNotTrackLinks') {
            if (!campaign.options) campaign.options = {};
            campaign.options.doNotTrackLinks = !ev.currentTarget.checked;
        } else if (ev.target.name == 'doNotTrackOpens') {
            if (!campaign.options) campaign.options = {};
            campaign.options.doNotTrackOpens = !ev.currentTarget.checked;
        } else if (ev.target.name == "fixedCustomFields") {
            if (!campaign.options) campaign.options = {};
            campaign.options.fixedCustomFields = ev.currentTarget.checked;
        } else {
            campaign[ev.currentTarget.name] = ev.currentTarget.value
        }
        updateCampaign()
    }

    var isSplitTest = history.location.pathname.indexOf('/ab/') > -1


    const changeBatchSending = () => {
        setShowBatchSize(!showBatchSize)
        if (showBatchSize) {
            campaign.batchSize = 0
            campaign.batchDelay = 0
            campaign.batchUnit = 'minutes'
            updateCampaign()
            saveCampaign()
        }
    }

    const changeShowLimit = () => {
        setShowLimit(!showLimit)
        if (showLimit) {
            campaign.limit = 0
            updateCampaign()
            saveCampaign()
        }
    }

    const hasSendTimes = campaign?.options?.betweenEnd && campaign?.options?.betweenStart

    let estimatedBatchTime
    if (campaign.batchSize > 0 && campaign.batchDelay > 0 && campaign.batchUnit && totalSubs) {
        let inMinutes = campaign.batchUnit === 'minutes' ? campaign.batchDelay : campaign.batchDelay * 60
        estimatedBatchTime = Math.ceil((totalSubs / campaign.batchSize) * inMinutes)
        // convert minutes to hours
        if (estimatedBatchTime > 60) {
            // show days if it's more than 24 hours
            if (estimatedBatchTime > 1440) {
                estimatedBatchTime = Math.ceil(estimatedBatchTime / 1440) + ' days'
            } else {
                estimatedBatchTime = Math.ceil(estimatedBatchTime / 60) + ' hours'
            }
        } else {
            estimatedBatchTime += ' minutes'
        }
    }

    return (
        <>
            {/* <h4 ref={scrollIntoViewTarget} className='mar-b10'>{i18n.t('campaigns:main.advancedOptions')}</h4> */}
            <div ref={scrollIntoViewTarget} className='mar-b10'></div>
            <UILIB.Row className="mar-t10 mar-b5">

                {/* exclusions */}
                {<UILIB.Column xs={12}>

                    <UILIB.Paper style={{ padding: 24 }}>
                        <div className="txtInput_label cyt-advance-strong-headers" style={{ fontSize: 16, lineHeight: '24px' }}><strong>Exclusions</strong></div>
                        <div className='mar-b25' style={{ color: 'gray' }} dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:main.groupsSegmentsNotIncluded') }} />

                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={12} md={6}>
                                {
                                    <UILIB.TextInput
                                        label={i18n.t('campaigns:standard.step1.groups')}
                                        placeholder={i18n.t('campaigns:standard.step1.searchGroup')}
                                        onClick={() => addGroups(false)}
                                        readOnly={true}
                                        iconLeft={<UILIB.Icons icon='magnifier' />}
                                        className='mar-b10 cyt-adv-opt-excl-grp'
                                    />
                                }

                                {(totalExcluded) > 0 && <div>
                                    {groupsExcluded.map((theGroup, index) => {
                                        return <UILIB.SquareChip
                                            tooltip={"Group"}
                                            for={theGroup.id}
                                            key={"groupex_" + index}
                                            className={"mar-r10 square-chip-green"}
                                            iconRight={
                                                <UILIB.Icons icon="cross" style={{ height: 14, width: 14 }} onClick={() => changedAddedGroups(theGroup.id, false, true, true)} alt="Remove" title="Remove" />
                                            }>
                                            {theGroup.name || theGroup.groupName}
                                        </UILIB.SquareChip>
                                    })}

                                    <a className="text-xsml" onClick={() => addGroups(false)}></a>
                                </div>
                                }

                            </UILIB.Column>

                            <UILIB.Column xs={12} sm={12} md={6}>
                                {
                                    <UILIB.TextInput
                                        label={i18n.t('campaigns:standard.step1.segments')}
                                        placeholder={i18n.t('campaigns:standard.step1.searchSegment')}
                                        onClick={() => addSegments(false)}
                                        readOnly={true}
                                        iconLeft={<UILIB.Icons icon='magnifier' />}
                                        className='mar-b10 cyt-adv-opt-excl-seg'
                                    />
                                }

                                {(totalExcluded) > 0 && <div>
                                    {segmentsExcluded.map((theGroup, index) => {
                                        return <UILIB.SquareChip
                                            tooltip={"Segment"}
                                            for={theGroup.id}
                                            key={"segmentEx_" + index}
                                            className={"mar-r10 square-chip-purple"}
                                            iconRight={<UILIB.Icons icon="cross" style={{ height: 14, width: 14 }} onClick={() => changedAddedGroups(theGroup.id, false, false, true)} alt="Remove" title="Remove" />}>
                                            <span>{theGroup.name}{theGroup.temporary && canEditSegments && <UILIB.Icons icon="pencil" style={{ marginLeft: "10px" }} onClick={() => editSegment(theGroup.id)} />}</span>
                                        </UILIB.SquareChip>

                                    })}
                                    <a className="text-xsml" onClick={() => addSegments(false)}></a>
                                </div>
                                }
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>}


                {/* tracking */}
                {<UILIB.Column xs={12}>

                    <UILIB.Paper style={{ padding: 24 }}>
                        <div className="txtInput_label cyt-advance-strong-headers" style={{ fontSize: 16, lineHeight: '24px' }}><strong>Tracking</strong></div>
                        <div className='mar-b25' style={{ color: 'gray' }} dangerouslySetInnerHTML={{ __html: 'Customise options for email tracking' }} />

                        <UILIB.Row>
                            <UILIB.Column xs={12} className='mar-b20'>
                                <UILIB.CheckBox checked={!campaign.options.doNotTrackLinks} onClick={update} name="doNotTrackLinks" > {i18n.t('campaigns:main.trackLinks')} </UILIB.CheckBox>
                            </UILIB.Column>

                            {!isSms && <UILIB.Column xs={12} className='mar-b20'>
                                <UILIB.CheckBox checked={!campaign.options.doNotTrackOpens} onClick={update} name="doNotTrackOpens" > {i18n.t('campaigns:main.trackOpens')} </UILIB.CheckBox>
                            </UILIB.Column>}

                            <UILIB.Column xs={12} className='mar-b20'>
                                <UILIB.CheckBox checked={campaign.addUtmTracking} onClick={update} name="addUtmTracking" > {i18n.t('campaigns:main.addUtmTrackingToLinks')} </UILIB.CheckBox>
                            </UILIB.Column>

                            <UILIB.Column xs={12} className="mar-b5">
                                <UILIB.CheckBox checked={campaign.options.fixedCustomFields} onClick={update} name="fixedCustomFields" >
                                    {i18n.t('campaigns:standard.step1.fixedCustomFields')}
                                </UILIB.CheckBox>
                                <div className='mar-l26' style={{ color: 'gray', 'font-size': 12 }} dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step1.fixedCustomFieldsTooltip') }} />
                            </UILIB.Column>
                        </UILIB.Row>

                    </UILIB.Paper>

                </UILIB.Column>}


                {/* batch sending */}
                {!isSplitTest && <UILIB.Column xs={12} >

                    <UILIB.Paper style={{ padding: 24 }} className='cyt-adv-opt-batch-sending'>
                        <div style={{ position: 'absolute', top: 38, right: 20 }}>
                            <UILIB.Toggle checked={showBatchSize} onChange={() => changeBatchSending()} />
                        </div>
                        <div className="txtInput_label cyt-advance-strong-headers" style={{ fontSize: 16, lineHeight: '24px' }}><strong>Batch Sending</strong></div>
                        <div style={{ color: 'gray' }} dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:main.batchSendingSlowDown') }} />

                        <UILIB.Row className={showBatchSize ? 'mar-t25' : 'display-no'} >

                            <div className="col-xs-12 col-md-6 mar-b25">
                                <div className="txtInput_label mar-b5" style={{ fontSize: 16, lineHeight: '24px' }}>Batch Size</div>
                                <div className='mar-b5' style={{ color: 'gray', 'font-size': 12 }} dangerouslySetInnerHTML={{ __html: 'The number of recipients in each batch ' }} />
                                <UILIB.TextInput type="number" max={5000} min={0} value={campaign.batchSize} name="batchSize" onChange={update} placeholder={i18n.t('campaigns:main.setTo0ToSendFast')} />
                            </div>

                            <div className="col-xs-12 col-md-6 mar-b10">
                                <div className="txtInput_label mar-b5" style={{ fontSize: 16, lineHeight: '24px' }}>Time Period</div>
                                <div className='mar-b5' style={{ color: 'gray', 'font-size': 12 }} dangerouslySetInnerHTML={{ __html: 'The time each batch of emails should be dispersed over ' }} />

                                <div className="row">
                                    <div className="col-xs-12 col-md-6 mar-b25">
                                        <UILIB.TextInput type="number" value={campaign.batchDelay} name="batchDelay" min={0} onChange={update} />
                                    </div>
                                    <div className="col-xs-12 col-md-6 mar-b10">
                                        <UILIB.Select value={campaign.batchUnit} data={['minutes', 'hours']} name="batchUnit" min={0} onChange={update} />
                                    </div>
                                </div>

                            </div>

                            <UILIB.Column xs={12}>
                                <div className="quickFlex">
                                    <div style={{ color: 'gray', marginRight: 8 }}>Estimated send duration: </div>
                                    <div style={{ fontSize: 16, lineHeight: '24px' }}><strong>{estimatedBatchTime}</strong></div>
                                </div>
                            </UILIB.Column>

                        </UILIB.Row>

                    </UILIB.Paper>

                </UILIB.Column>}


                {/* email limit */}
                {<UILIB.Column xs={12} className="mar-b25 cyt-adv-opt-email-limit">

                    <UILIB.Paper style={{ padding: 24 }}>

                        <div style={{ position: 'absolute', top: 38, right: 20 }}>
                            <UILIB.Toggle checked={showLimit} onChange={() => changeShowLimit()} />
                        </div>
                        <div className="txtInput_label cyt-advance-strong-headers" style={{ fontSize: 16, lineHeight: '24px' }}><strong>{!isSms && <span>Email</span>} Limit</strong></div>
                        <div style={{ color: 'gray' }} dangerouslySetInnerHTML={{ __html: 'Limit sending to a random number of recipients.' }} />

                        <div className={showLimit ? 'mar-t25' : 'display-no'}>
                            <UILIB.TextInput className="col-xs-12" label={i18n.t('campaigns:main.limitSendingToRandom')} outerStyle={{ width: "100%", maxWidth: "400px" }} type="number" value={campaign.limit} name="limit" min={0} onChange={update} placeholder={i18n.t('campaigns:main.setTo0ToSendAll')} />
                        </div>

                    </UILIB.Paper>
                </UILIB.Column>}


            </UILIB.Row>
            {/* <UILIB.Button iconRight={<UILIB.Icons icon="cross" />} onClick={close}>Close</UILIB.Button> */}
        </>
    )
}
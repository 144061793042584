import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFormatFunctions from '~/Classes/numberFormatFunctions';

export default class AffiliateCustomers extends React.Component {
    constructor(props) {
        super(props);

        let timer;

        this.state = {
            customers: [],
            pageLoaded: false,
            tableLoaded: false
        };

        this.getCustomers = this.getCustomers.bind(this);
    }

    componentDidMount() {
        this.getCustomers();
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer)
    }
    getCustomers() {
        var self = this;
        axios.get(`/accountUser/affiliate/customers`).then(_customers => {

            var customers = _customers.data.Customers.map(customer => {
                return {
                    companyLogo: {
                        headerValue: "",
                        value: <UILIB.Avatar style={{ height: '30px', width: '30px' }} src={customer.companyLogo} />,
                        orderBy: false
                    },
                    accountName: {
                        headerValue: "Account",
                        value: customer.accountName,
                        orderBy: false
                    },
                    createdAt: {
                        headerValue: "Registered",
                        value: DateTimeFunctions.formatDateTime(customer.createdAt, 2),
                        orderBy: false
                    },
                    planName: {
                        headerValue: "Current Plan",
                        value: customer.planName,
                        orderBy: false
                    },
                    planPrice: {
                        headerValue: "Current Plan Price",
                        value: <span>&pound;{NumberFormatFunctions.formatNumber(customer.planPrice, 2)}</span>,
                        orderBy: false
                    },
                }
            })
            self.props.updateStats("customers", customers.length)
            self.setState({ customers: customers, pageLoaded: true, tableLoaded: true }, () => {
                self.timer = setTimeout(self.getCustomers, 30000)
            })
        })
    }

    render() {
        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />
        if (!this.state.customers.length) {
            return <div style={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #DDDDDD",
            }}>
                <div style={{ display: "flex" }}>
                    <div style={{ fontSize: "40px" }} className="mar-r15">😞</div>
                    <div>
                        <h4 className="mar-b5">You have no Affiliate Customers Yet</h4>
                        <p>Make sure to start sharing your affiliate link!</p>
                    </div>

                </div>
            </div>
        }

        return <div style={{
            border: "1px solid #DDDDDD",
            padding: "10px",
            height: "300px",
            overflow: "auto"
        }}>


            <UILIB.DataTable1
                noResultsTxt={"No affiliate customers match your filters"}
                tableData={this.state.customers}
                loadingData={this.state.tableLoaded}
                dataUpdater={() => { }}
                width="100%"
                pageSize="100"
                hasCheckBoxes="no"
                sortedColumn={"createdAt"}
                sortedDirection={"desc"}
            />

        </div>
    }

};



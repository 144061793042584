import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import helpers from '~/Classes/helpers'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

class TransactionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewUrl: "",
            status: "",
            statusColor: "square-chip-green",
            bounceMessage: "",
            loaded: false,
            transactionId: -1,
            transaction: null
        }

        this.init = this.init.bind(this)
        this.getTransaction = this.getTransaction.bind(this);
        this.showHistoryDetails = this.showHistoryDetails.bind(this)
    }

    componentDidMount() {
        this.getTransaction();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.transactionId != this.props.transactionId) {
            this.getTransaction();
        }
    }

    getTransaction() {
        axios.get('/transaction/' + this.props.transactionId).then(response => {
            this.setState({ transaction: response.data }, this.init)
        }).catch(err => {

        })
    }


    async init() {
        this.setState({ loaded: false, transactionId: this.state.transaction.id })
        const updateObj = { loaded: true }
        if (this.state.transaction.type === 1) {
            if (this.state.transaction.SendingQueue) {
                var key = await helpers.createKey(this.state.transaction.SendingQueue.SubscriberID, this.state.transaction.SendingQueue.id, this.state.transaction.SendingQueue.randomOrder, this.state.transaction.CampaignId)
                updateObj.previewUrl = `/preview?key=${key}`
            }
        }

        var status = "Sent";
        var statusColor = "square-chip-green";
        var promises = [];
        const obj = this.state.transaction.SendingQueue || this.state.transaction.SmsSend || {}
        if (obj.sent && !obj.bounced) {
            status = "Sent";
        }
        else if (obj.bounced) {
            status = "Bounced"
            statusColor = "square-chip-red"
            promises.push(
                axios.get("/campaign/" + this.state.transaction.CampaignId + "/stats/bounced?sendingQueueId=" + this.state.transaction.SendingQueue.id)
            )
        }
        else if (obj.sent == -1) {
            status = "Mail Server Rejected"
            statusColor = "square-chip-red"
        } else if (this.state.transaction.error) {
            status = this.state.transaction.error;
            statusColor = "square-chip-red"
        }

        updateObj.status = status
        updateObj.statusColor = statusColor

        Promise.all(promises).then((inDat) => {
            var bounceMessage
            if (inDat && inDat.length) {
                bounceMessage = inDat[0].data.Bounces[0].message
            }
            updateObj.bounceMessage = bounceMessage
            this.setState(updateObj)
        }).catch(() => {
            this.setState(updateObj)
        })

    }

    showHistoryDetails(id) {
        const transaction = this.state.transaction
        const history = transaction.SendingQueue.SendingQueueHistories.find(t => t.id === id)
        if (history) history.show = !history.show
        this.setState({ transaction })
    }

    render() {

        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />
        var fromDetails = [];
        if (this.state.transaction.fromName) {
            fromDetails.push(<div key={"T1"}>{this.state.transaction.fromName}</div>)
        }
        if (this.state.transaction.fromAddress) {
            fromDetails.push(<div key={"T2"}>{this.state.transaction.fromAddress}</div>)
        }
        if (this.state.transaction.replyAddress && this.state.transaction.replyAddress != this.state.transaction.fromAddress) {
            fromDetails.push(<div key={"T3"}>Reply: {this.state.transaction.replyAddress}</div>)
        }

        var toDetails = [];
        if (this.state.transaction.toName) {
            toDetails.push(<div key={"T4"}>{this.state.transaction.toName}</div>)
        }
        if (this.state.transaction.toAddress) {
            toDetails.push(<div key={"T5"}>{this.state.transaction.toAddress}</div>)
        }

        var createdDate = DateTimeFunctions.formatDateTime(this.state.transaction.createdAt);
        var sendDate = DateTimeFunctions.formatDateTime(this.state.transaction.sendDate);

        const history = this.state.transaction.SendingQueue ? this.state.transaction.SendingQueue.SendingQueueHistories || [] : []

        let body = this.state.transaction.type === 2 ? this.state.transaction.html || '' : ''
        body = body.replace(/\n/gi, '<br />')

        return (
            <div>
                <h4 className="mar-b25">Viewing Transaction {this.state.transaction.id}</h4>
                <UILIB.Row>
                    {Boolean(fromDetails.length) && <UILIB.Column xs={12}><UILIB.TextInput
                        type="plaintext"
                        label="From"
                        value={fromDetails}
                        readOnly="true"
                        outerClassName="mar-b25"
                        outerStyle={{ width: "100%" }}
                    />
                    </UILIB.Column>
                    }
                    <UILIB.Column xs={12}>
                        <UILIB.TextInput
                            type="plaintext"
                            label="To"
                            value={toDetails}
                            readOnly="true"
                            outerClassName="mar-b25"
                            outerStyle={{ width: "100%" }}
                        />

                    </UILIB.Column>
                    <UILIB.Column xs={12}>
                        <UILIB.TextInput
                            type="plaintext"
                            label="Subject"
                            value={this.state.transaction.subject}
                            readOnly="true"
                            outerClassName="mar-b25"
                            outerStyle={{ width: "100%" }}
                        />
                    </UILIB.Column>
                    {this.state.transaction.type === 1 && <UILIB.Column xs={12}>
                        <div className="txtInput_label">Email Body</div>
                        <div><iframe src={this.state.previewUrl} style={{ border: "1px solid grey", width: "100%", height: "200px" }}></iframe></div>
                    </UILIB.Column>}
                    {this.state.transaction.type === 2 && <UILIB.Column xs={12}>
                        <div className="txtInput_label">SMS Body</div>
                        <div dangerouslySetInnerHTML={{ __html: body }} ></div>
                    </UILIB.Column>}
                    {Boolean(this.state.transaction.attachments && this.state.transaction.attachments.length) && <UILIB.Column xs={12}>
                        <div className="txtInput_label">Attachments</div>
                        {this.state.transaction.attachments.map((att, index) => {
                            var attNameDat = (att.url || att).split("/");
                            var attName = attNameDat[attNameDat.length - 1]
                            return <div key={"att_" + index}>{attName}</div>
                        })}
                    </UILIB.Column>}
                    <UILIB.Column xs={12}>
                        <UILIB.TextInput
                            type="plaintext"
                            label="Received Transaction"
                            value={createdDate}
                            readOnly="true"
                            outerClassName="mar-b25"
                            outerStyle={{ width: "100%" }}
                        />

                    </UILIB.Column>
                    {Boolean(sendDate) && <UILIB.Column xs={12}>
                        <UILIB.TextInput
                            type="plaintext"
                            label="Sent Email"
                            value={sendDate}
                            readOnly="true"
                            outerClassName="mar-b25"
                            outerStyle={{ width: "100%" }}
                        />

                    </UILIB.Column>}
                    <UILIB.Column xs={12}>
                        <UILIB.SquareChip value={this.state.status} className={this.state.statusColor} />
                        {Boolean(this.state.bounceMessage && this.state.bounceMessage.length > 0) && <UILIB.WarningHolder style={{ textAlign: "left" }}>
                            {this.state.bounceMessage}
                        </UILIB.WarningHolder>}

                    </UILIB.Column>
                    {Boolean(history.length) && <UILIB.Column xs={12}>
                        <div className="txtInput_label">Events:</div>
                        {history.map(row => {
                            let color = "square-chip-green"
                            if (row.action === 'bounced') color = 'square-chip-red'
                            if (row.action === 'deferred') color = 'square-chip-purple'
                            let text = row.action
                            text = text.charAt(0).toUpperCase() + text.slice(1)
                            return <UILIB.Row>
                                <UILIB.Column xs={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    {DateTimeFunctions.formatDateTime(row.date)}
                                </UILIB.Column>
                                <UILIB.Column xs={6}>
                                    <div className="quickFlex">
                                        <UILIB.SquareChip value={text} className={color} />
                                        <UILIB.Icons icon="magnifier" style={{ cursor: "pointer" }} alt="Show Details" title="Show Details" onClick={() => this.showHistoryDetails(row.id)} />
                                    </div>
                                </UILIB.Column>
                                {row.show && <UILIB.Column xs={12}>
                                    <UILIB.WarningHolder style={{ textAlign: "left" }}>
                                        {row.data}
                                    </UILIB.WarningHolder>
                                </UILIB.Column>
                                }
                            </UILIB.Row>
                        })}
                    </UILIB.Column>}
                </UILIB.Row>
            </div >
        );
    }
}

export default TransactionDetails;
import React from 'react'
import UILIB from '~/Common-Objects/Lib.jsx';
import { connect } from 'react-redux';
import axios from 'axios';
import DbConn from '~/Classes/siteVars.js'
import axios2 from 'axios'
@connect((store) => {
    return { siteMaster: store.siteMaster, browser: store.browser, user: store.user, accountMaster: store.accountMaster }
})

export default class LatestNews extends React.Component {
    constructor(props) {
        super(props)

    }

    render() {
        return <UILIB.Row>
            <UILIB.Column xs={12} margin={0}>
                <UILIB.Paper style={{
                    display: "flex",
                    padding: "16px 20px"
                }}
                >
                    <UILIB.Icons icon="megaphone" circle={true} style={{ width: "20px", height: "20px" }} className="mar-r10" />
                    <div style={{ fontWeight: "600" }}>We’ve recently updated the layout within Transpond to offer you a better experience.</div>
                </UILIB.Paper >
            </UILIB.Column>
        </UILIB.Row >
    };
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RGEYITt9qFEyO4HFvC4g{border-radius:8px;box-shadow:var(--shadow-extra-small)}.LCovUqrGGUdx9oFrpx9V{color:var(--grey-900)}.Nio9F1E3cSdyUTtbGWEo{box-shadow:none}.Nio9F1E3cSdyUTtbGWEo:hover,.Nio9F1E3cSdyUTtbGWEo:focus{box-shadow:var(--shadow-extra-small)}.Nio9F1E3cSdyUTtbGWEo.c3DN8bXyKEnkHyT4hYLe{color:var(--grey-800)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/components/button/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,oCAAA,CAGF,sBACE,qBAAA,CAGF,sBACE,eAAA,CAEA,wDAEE,oCAAA,CAGF,2CACE,qBAAA","sourcesContent":[".root {\n  border-radius: 8px;\n  box-shadow: var(--shadow-extra-small);\n}\n\n.rootVariantSecondary {\n  color: var(--grey-900);\n}\n\n.rootVariantGhost {\n  box-shadow: none;\n\n  &:hover,\n  &:focus {\n    box-shadow: var(--shadow-extra-small);\n  }\n\n  &.rootIconOnly {\n    color: var(--grey-800);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "RGEYITt9qFEyO4HFvC4g",
	"rootVariantSecondary": "LCovUqrGGUdx9oFrpx9V",
	"rootVariantGhost": "Nio9F1E3cSdyUTtbGWEo",
	"rootIconOnly": "c3DN8bXyKEnkHyT4hYLe"
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect } from "react"
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import i18n from '~/i18n'

export default function () {
    const location = useLocation()
    const site = useSelector(state => state.siteMaster)
    useEffect(() => {
        let title = site.siteName
        if (window.location.origin === 'https://web.staging.dcloud.mpztesting.com') {
            title = 'TEST | ' + title
        } else if (window.location.origin === 'http://localhost:8080' || window.location.origin === 'http://localhost:8082' || window.location.origin === 'http://127.0.0.1:8080') {
            title = 'DEV | ' + title
        }
        switch (location.pathname.toLowerCase()) {
            case '/cp/company/sms':
                title += ` | ${i18n.t('nav:sms')}`
                break;
            case '/cp/campaigns':
                title += ` | ${i18n.t('nav:campaigns')}`
                break;
            case '/cp/subscribers/groups':
                title += ` | ${i18n.t('nav:groups')}`
                break;
            case '/cp/subscribers':
                title += ` | ${i18n.t('nav:subscribers')}`
                break;
            case '/cp/templates':
                title += ` | ${i18n.t('nav:templates')}`
                break;
            case '/cp/automation':
                title += ` | ${i18n.t('nav:automation')}`
                break;
            case '/cp/forms':
                title += ` | ${i18n.t('nav:forms')}`
                break;
            case '/cp/transactions':
            case '/cp/transactions/list':
            case '/cp/transactions/settings':
                title += ` | ${i18n.t('nav:transactions')}`
                break;
            case '/cp/company/integrations':
                title += ` | ${i18n.t('nav:integrations')}`
                break;
            case '/cp/trackers':
                title += ` | ${i18n.t('nav:siteTracking')}`
                break;
            case '/cp/company/subscription':
                title += ` | ${i18n.t('nav:subscription')}`
                break;
        }
        document.title = title
    }, [site, location])
    return null
}
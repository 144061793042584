import React, { useState } from "react";
import TextInput from "../textInput";
import AlertDialog from "../alertDialog";
import i18n from "~/i18n";

export default function DeleteAiAssistantDialog({
  assistant,
  isOpen,
  onSubmit,
  ...rest
}) {
  const [deleteAssistantName, setDeleteAssistantName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  async function handleSubmit() {
    setSubmitting(true);

    try {
      await validateForm();
      await onSubmit(assistant);
    }
    catch (error) {
      {
        return { error: true };
      }
    }
    finally {
      setSubmitting(false);
    }
  }

  async function validateForm() {
    try {
      if (deleteAssistantName !== assistant.name) {
        throw i18n.t("chat:settings.ai.deleteDialog.notMatch");
      }

      setFormErrors(null);
    } catch (error) {
      setFormErrors({ deleteAssistantName: error });
      throw error;
    }
  }

  return (
    <AlertDialog
      open={isOpen}
      title={i18n.t("chat:settings.ai.deleteDialog.header")}
      description={i18n.t("chat:settings.ai.deleteDialog.description", { name: assistant?.name })}
      ctaText={i18n.t("chat:settings.ai.deleteDialog.confirm")}
      submitting={submitting}
      onCtaClick={handleSubmit}
      {...rest}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextInput
          label={i18n.t("chat:settings.ai.deleteDialog.name")}
          value={deleteAssistantName}
          disabled={submitting}
          // Issue with autofocus when opening dialog from a dropdown https://github.com/radix-ui/primitives/issues/1615
          autoFocus
          required
          onChange={(e) => setDeleteAssistantName(e.target.value)}
          error={formErrors?.deleteAssistantName}
        />
      </form>
    </AlertDialog>
  );
}

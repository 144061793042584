import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import * as styles from "./index.module.scss";

export default function SettingsNavItem({
  selected,
  className,
  children,
  ...rest
}) {
  const classes = classnames(styles.root, {
    [styles.selected]: selected,
  });

  return (
    <Link className={classes} {...rest}>
      {children}
    </Link>
  );
}

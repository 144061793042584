import React from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import TextFunctions from "~/Classes/textFunctions";
import { connect } from 'react-redux';
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import moment from 'moment';
import i18n from '~/i18n'

const placeholder = [{
    name: '1',
    value: 25
}, {
    name: '2',
    value: 25
}, {
    name: '3',
    value: 25
}, {
    name: '4',
    value: 25
}]

@connect((store) => {
    return {
        filters: store.filters,
        siteMaster: store.siteMaster
    }
})


export default class CampaignPieGraph extends React.Component {
    constructor(props) {
        super(props);
        var siteColours = this.props.siteMaster.colours;

        this.state = {
            pageLoaded: false,
            statOptions: [{
                label: i18n.t('country'),
                value: 'country'
            }, {
                label: i18n.t('device'),
                value: 'device'
            }, {
                label: i18n.t('link'),
                value: 'link'
            }],
            stat: this.props.stat,
            unique: false,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            colors: [
                siteColours.$primary,
                siteColours.$secondary,
                siteColours['$status-red'],
                siteColours['$status-yellow'],
                siteColours['$status-green'],
                siteColours['$status-orange'],
                siteColours['$status-blue']
            ],
            graphData: []
        };
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        var unique = false;
        if (this.props.unique) unique = true;
        this.setState({ unique: unique }, this.getStats);
    }

    componentDidUpdate(prevProps) {
        var refresh = false;
        if (this.props.stat !== this.state.stat) refresh = true;
        if (this.props.startDate !== this.state.startDate) refresh = true;
        if (this.props.endDate !== this.state.endDate) refresh = true;

        if (refresh) {
            this.setState({ stat: this.props.stat, startDate: this.props.startDate, endDate: this.props.endDate }, this.loadData);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getStats() {
        this.loadData();
        this.timer = setInterval(this.loadData, 5000)
    }

    loadData() {
        var queryStr = '?ignoreLog=1&stat=' + this.state.stat + '&unique=' + this.state.unique;
        if (this.state.startDate || this.state.startDate) {
            queryStr += '&start=' + (this.state.startDate).format('YYYY-MM-DD');
        }
        if (this.state.endDate) {
            queryStr += '&end=' + (this.state.endDate).format('YYYY-MM-DD');
        }
        axios.get('/campaign/' + this.props.campaignId + '/stats/graph' + queryStr)
            .then(response => {
                var graphData = []
                response.data.Stats.forEach((row, i) => {
                    if (i === 6) {
                        graphData.push({
                            name: 'Other',
                            value: parseInt(row.value)
                        })
                    } else if (i > 6) {
                        graphData[graphData.length - 1].value += parseInt(row.value)
                    } else {
                        graphData.push({
                            name: row.str ? TextFunctions.capitalizeFirstLetter(row.str) : 'Unknown',
                            value: row.value
                        })

                    }
                })

                this.setState({
                    graphData: graphData,
                    pageLoaded: true
                })
            })
            .catch(console.log)
    }

    render() {

        var headerText = "";
        if (this.props.stat == 'device') {
            headerText = i18n.t('device')
        }
        if (this.props.stat == 'country') {
            headerText = i18n.t('country')
        }
        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />
        return <div className={this.props.className}>

            <UILIB.Row className="middle-lg middle-md">
                <UILIB.Column xs={12} sm={12} md={9}>
                    <h4>{headerText} Breakdown</h4>
                    <div className='mar-t10'>{i18n.t('campaigns:graph.between')} {moment(this.props.startDate).format("MMM DD")} &amp; {moment(this.props.endDate).format("MMM DD")}</div>
                </UILIB.Column>
            </UILIB.Row>


            <UILIB.Row>
                <UILIB.Column xs={12}>
                    {!!this.state.graphData.length && <ResponsiveContainer height={450}>
                        <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                            <Pie dataKey="value" nameKey="name" data={this.state.graphData} innerRadius={75} activeIndex={this.state.active}>
                                {this.state.graphData.map((entry, index) => (
                                    <Cell className="pie-cell" key={`cell-${index}`} fill={this.state.colors[index]} />
                                ))}
                            </Pie>
                            <Legend verticalAlign="bottom" iconType="circle" iconSize={11} content={UILIB.CustomPieLegend} />
                            <Tooltip content={<UILIB.CustomTooltip overrideColor={true} />} />
                        </PieChart>
                    </ResponsiveContainer>}
                    {!this.state.graphData.length && <div style={{ opacity: 0.2 }}>
                        <ResponsiveContainer height={450}>
                            <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                <Pie dataKey="value" nameKey="name" data={placeholder} innerRadius={95} >
                                    {placeholder.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={this.state.colors[index]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>}
                    {!this.state.graphData.length && <div style={{ position: 'absolute', height: '100%', width: '100%', top: '50%', left: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 20 }}>
                        No Data Available
                        <div style={{ fontSize: 14, marginTop: 10, textAlign: 'center' }}>There is no campaign data for this time period</div>
                    </div>}

                </UILIB.Column>
            </UILIB.Row>
        </div>
    }
}
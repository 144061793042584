import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import PermissionChecker from '~/Classes/permissions';

import i18n from '~/i18n'

import IncAddCustomField from './incAddCustomField.jsx'
import IncDeleteCustomField from './incDeleteCustomField.jsx'
import IncDeleteCustomFields from './incDeleteCustomFields.jsx'
import IncTypeSelectOptions from './incTypeSelectOptions.jsx'
import IncRenameCustomField from './incRenameCustomField.jsx'
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})
export default class CustomFields extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            customFields: [],
            customFieldsData: [],
            groupID: this.props.match.params.groupID,
            loadingData: 1,
            sortColumn: "fieldDesc",
            sortDirection: "Asc",
            newCustomField: false,
            tickedFields: [],
            alertMessage: '',
            alertOpen: false
        };

        this.getCustomFields = this.getCustomFields.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.fieldsRequiredClicked = this.fieldsRequiredClicked.bind(this);
        this.goAddCustomField = this.goAddCustomField.bind(this);
        this.goDeleteCustomField = this.goDeleteCustomField.bind(this);
        this.goEditSelectOptions = this.goEditSelectOptions.bind(this);
        this.saveEditSelectOptions = this.saveEditSelectOptions.bind(this);
        this.fieldsHiddenClicked = this.fieldsHiddenClicked.bind(this)
        this.checkBoxes = this.checkBoxes.bind(this);
        this.countChecked = this.countChecked.bind(this);
        this.deleteCustomFieldsDrawer = this.deleteCustomFieldsDrawer.bind(this);
        this.goRenameCustomField = this.goRenameCustomField.bind(this);
        this.fieldsImportantClicked = this.fieldsImportantClicked.bind(this)

    }
    componentDidMount() {
        this.getCustomFields();
    }
    handleChange(event) {
        this.setState({ newTemplateErr: false });
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        });
    }

    checkBoxes(event) {
        var thisDataID = event.target.name.replace('checkBox_', '');
        if (isNaN(thisDataID)) return;
        thisDataID = Number(thisDataID) + 1;
        var isChecked = event.target.checked;
        var newState = this.state.customFields;
        var checkBox = React.cloneElement(newState[thisDataID].checkBox.value, { checked: isChecked });
        newState[thisDataID].checkBox.isChecked = isChecked;
        newState[thisDataID].checkBox.value = checkBox;

        this.setState({
            customFields: newState
        }, this.countChecked);
    }

    countChecked() {
        //count number of text boxes checked
        var tickedFields = this.state.customFields.filter(sub => sub.checkBox.isChecked);
        this.setState({ tickedFields });
    }
    getCustomFields() {
        var self = this
        var newArray = this.state.customFields;
        var canEdit = PermissionChecker('customFields', this.props.permissionStore.permissions, "write");
        axios
            .get('/customfield?orderBy=fieldDesc&orderDir=asc')
            .then((res) => {

                var customFields = res
                    .data.CustomFields
                    .map((thisLine, index) => {
                        var edit = canEdit && !thisLine.ApplicationId ? <UILIB.OptionsDropdown popWidth="150px">
                            <ul>
                                <li>
                                    <a onClick={() => { this.goRenameCustomField(thisLine) }}>{i18n.t('subscribers:customfields.rename')}</a>
                                </li>
                                {(thisLine.fieldType == "SELECT" || thisLine.fieldType == "MULTISELECT") && Array.isArray(thisLine.options) && <li>
                                    <a onClick={() => { this.goEditSelectOptions(thisLine.id) }}>{i18n.t('subscribers:customfields.editOptions')}</a>
                                </li>}
                                <li>
                                    <a onClick={() => { this.goDeleteCustomField(thisLine.id) }}>{i18n.t('subscribers:customfields.delete')}</a>
                                </li>

                            </ul>

                        </UILIB.OptionsDropdown> : <span></span>

                        return {
                            checkBox: {
                                headerValue: '',
                                orderBy: false,
                                value: canEdit ? <UILIB.CheckBox
                                    outerClassName="no-marg"
                                    checked={false}
                                    onChange={this.checkBoxes}
                                    name={"checkBox_" + index} /> : "",
                                isCheckBox: true,
                                width: 20,

                                order: -1
                            },
                            fieldDesc: {
                                headerValue: i18n.t('subscribers:customfields.fieldName'),
                                value: <span className="text-heavy">{thisLine.fieldDesc}</span>,
                                orderBy: false
                            },
                            fieldName: {
                                headerValue: i18n.t('subscribers:customfields.mergeTag'),
                                value: "{{" + thisLine.fieldName + "}}",
                                orderBy: false
                            },
                            fieldType: {
                                headerValue: i18n.t('subscribers:customfields.fieldType'),
                                value: thisLine.fieldType === 'MULTISELECT' && !Array.isArray(thisLine.options) ? 'MULTI' : thisLine.fieldType,
                                orderBy: false
                            },
                            entityType: {
                                headerValue: 'Applies To',
                                value: thisLine.entityType === 1 ? 'Subscribers Only' : thisLine.entityType === 2 ? 'Organisations Only' : 'Subscribers and Organisations',
                                orderBy: false
                            },
                            important: {
                                headerValue: i18n.t('subscribers:customfields.important'),
                                value: <UILIB.CheckBox outerClassName="no-marg quickFlex justify-center" disabled={thisLine.readOnly || !canEdit} name={"important-" + index} checked={thisLine.important} onChange={self.fieldsImportantClicked} />,
                                orderBy: false,
                                align: "center"
                            },
                            isRequired: {
                                headerValue: i18n.t('subscribers:customfields.required'),
                                value: <UILIB.CheckBox outerClassName="no-marg quickFlex justify-center" disabled={thisLine.readOnly || !canEdit} name={"required-" + index} checked={thisLine.isRequired} onChange={self.fieldsRequiredClicked} />,
                                orderBy: false,
                                align: "center"
                            },
                            customerHidden: {
                                headerValue: i18n.t('subscribers:customfields.hidden'),
                                value: <UILIB.CheckBox outerClassName="no-marg quickFlex justify-center" disabled={thisLine.readOnly || !canEdit} name={"customerHidden-" + index} checked={thisLine.customerHidden} onChange={self.fieldsHiddenClicked} />,
                                orderBy: false,
                                align: "center"
                            },
                            fieldId: {
                                headerValue: "Field ID",
                                value: thisLine.id,
                                orderBy: false
                            },
                            Application: {
                                headerValue: "Application",
                                value: !!thisLine.Application && <UILIB.Avatar style={{ height: 30, width: 30, border: '1px solid grey' }} for={thisLine.id} tooltip={thisLine.Application.appName} src={thisLine.Application.appLogoUri} />,
                                orderBy: false,
                                align: 'center'
                            },
                            optionsCol: {
                                headerValue: "  ",
                                value: edit,
                                orderBy: false,
                                fixed: true,
                                fixedSml: true,
                                width: 20
                            }

                        }
                    })

                //insert email field at the top
                customFields.splice(0, 0, {
                    checkBox: {
                        headerValue: '',
                        value: '',
                        orderBy: false,
                        isChecked: false,
                        width: 20
                    },
                    fieldDesc: { headerValue: i18n.t('subscribers:customfields.fieldName'), value: <span className="text-heavy">{i18n.t('emailAddress')}</span>, orderBy: false },
                    fieldName: { headerValue: i18n.t('subscribers:customfields.mergeTag'), value: "{{SUB_EMAILADDRESS}}", orderBy: false, align: "left" },
                    fieldType: { headerValue: i18n.t('subscribers:customfields.fieldType'), value: "TEXT", orderBy: false, align: "left" },
                    entityType: { headerValue: 'Applies To', value: "Subscribers Only", orderBy: false },
                    important: {
                        headerValue: i18n.t('subscribers:customfields.important'),
                        value: <UILIB.CheckBox outerClassName="no-marg quickFlex justify-center" disabled checked={true} />,
                        orderBy: false,
                        align: "center",
                        helper: "Show first in contact details"
                    },
                    isRequired: { headerValue: i18n.t('subscribers:customfields.required'), value: <UILIB.CheckBox outerClassName="no-marg quickFlex justify-center" name={"required-" + -1} disabled checked={true} />, orderBy: false, align: "center" },
                    customerHidden: { headerValue: i18n.t('subscribers:customfields.hidden'), value: <UILIB.CheckBox outerClassName="no-marg quickFlex justify-center" name={"customerHidden-" + -1} disabled checked={false} />, orderBy: false, align: "center" },
                    fieldId: { headerValue: "Field ID", value: '', orderBy: false },
                    Application: { headerValue: res.data.CustomFields.some(cf => cf.Application) && "Application", orderBy: false, value: '', align: 'center' },
                    optionsCol: { headerValue: " ", value: <span></span>, width: 20, fixed: true, orderBy: false, fixedSml: true }
                });

                self.setState({ customFields: customFields, customFieldsData: res.data.CustomFields, loadingData: 0, tickedFields: [] });
            })
    }

    goAddCustomField() {
        var drawerContent = <IncAddCustomField alert={message => {
            this.setState({ alertMessage: message, alertOpen: true })
        }} groupId={this.state.groupID} getCustomFields={this.getCustomFields} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "700px"));
    }

    goDeleteCustomField(fieldId) {
        var drawerContent = <IncDeleteCustomField alert={message => {
            this.setState({ alertMessage: message, alertOpen: true })
        }} groupId={this.state.groupID} getCustomFields={this.getCustomFields} fieldId={fieldId} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    goRenameCustomField(field) {
        var drawerContent = <IncRenameCustomField alert={message => {
            this.setState({ alertMessage: message, alertOpen: true })
        }} groupId={this.state.groupID} getCustomFields={this.getCustomFields} field={field} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }
    fieldsHiddenClicked(evt) {

        var thisVal = evt.target.checked;
        var thisName = evt.target.name.split("-")[1];
        var custFields = this.state.customFieldsData;
        custFields[thisName].customerHidden = thisVal;

        var customFields = this.state.customFields;
        var checkBox = React.cloneElement(customFields[Number(thisName) + 1].customerHidden.value, { checked: thisVal });
        customFields[Number(thisName) + 1].customerHidden.value = checkBox;

        this.setState({ customFieldsData: custFields, customFields: customFields })

        axios.put('/customfield/' + custFields[thisName].id, custFields[thisName])
    }

    fieldsRequiredClicked(evt) {

        var thisVal = evt.target.checked;
        var thisName = evt.target.name.split("-")[1];
        var custFields = this.state.customFieldsData;
        custFields[thisName].isRequired = thisVal;

        var customFields = this.state.customFields;
        var checkBox = React.cloneElement(customFields[Number(thisName) + 1].isRequired.value, { checked: thisVal });
        customFields[Number(thisName) + 1].isRequired.value = checkBox;

        this.setState({ customFieldsData: custFields, customFields: customFields })

        axios.put('/customfield/' + custFields[thisName].id, custFields[thisName])
    }

    fieldsImportantClicked(evt) {
        var thisVal = evt.target.checked;
        var thisName = evt.target.name.split("-")[1];
        var custFields = this.state.customFieldsData;
        custFields[thisName].important = thisVal;

        var customFields = this.state.customFields;
        var checkBox = React.cloneElement(customFields[Number(thisName) + 1].important.value, { checked: thisVal });
        customFields[Number(thisName) + 1].important.value = checkBox;

        this.setState({ customFieldsData: custFields, customFields: customFields })

        axios.put('/customfield/' + custFields[thisName].id, custFields[thisName])
    }

    goEditSelectOptions(fieldId) {
        var ourField = this.state.customFieldsData.find(f => f.id == fieldId);
        if (!ourField) return;
        var drawerContent = <IncTypeSelectOptions fieldData={ourField} saveData={this.saveEditSelectOptions} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "700px"));
    }

    saveEditSelectOptions(fieldData) {
        var self = this;
        axios
            .put('/customfield/' + fieldData.id, fieldData)
            .then((res) => {
                self.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
                self.props.getCustomFields(res.data.CustomField);
            }).catch(err => {
                console.log(err)
            })
    }

    deleteCustomFieldsDrawer() {
        var drawerContent = <IncDeleteCustomFields alert={message => {
            this.setState({ alertMessage: message, alertOpen: true })
        }} getCustomFields={this.getCustomFields} fields={this.state.tickedFields} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }
    render() {

        var self = this;
        var canEdit = PermissionChecker('customFields', this.props.permissionStore.permissions, "write");
        return <div>


            {(canEdit == true) && <UILIB.Row>
                <UILIB.Column xs={12} sm={6} className="mar-b25">
                    {(this.state.tickedFields.length > 0 && canEdit == true) && <UILIB.Button
                        text={"Delete " + this.state.tickedFields.length + (this.state.tickedFields.length > 1 ? " Fields" : " Field")}
                        className="button-red"
                        iconLeft={<UILIB.Icons icon="cross" />}
                        onClick={this.deleteCustomFieldsDrawer} />}
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="end-xs mar-b25">
                    {this.props.siteMaster.siteId === 1 && <UILIB.Hint style={{ width: 'fit-content', marginRight: canEdit ? 20 : 0, display: 'inline-flex' }} iconLeft={<UILIB.Icons icon="questionCircle" />} href="https://kb.transpond.io/article/68-what-are-custom-fields">Learn about Custom Fields</UILIB.Hint>}
                    <UILIB.Button
                        onClick={this.goAddCustomField}
                        className="button-primary"
                        iconLeft={<UILIB.Icons icon="plus" />}>
                        {i18n.t('subscribers:customfields.addField')}
                    </UILIB.Button>
                </UILIB.Column>
            </UILIB.Row>}

            <UILIB.DataTable1
                noResultsTxt={<span> {i18n.t('subscribers:customfields.noFields')}</span>}
                tableData={this.state.customFields}
                loadingData={this.state.loadingData}
                dataUpdater={this.tableUpdater}
                width="100%"
                pageSize="100"
                hasCheckBoxes="no"
                sortedColumn={this.state.sortColumn}
                sortedDirection={this.state.sortDirection}
                className="mar-b25"
            />
            {(this.state.tickedFields.length > 0 && canEdit == true) && <UILIB.Button
                text={"Delete " + this.state.tickedFields.length + (this.state.tickedFields.length > 1 ? " Fields" : " Field")}
                className="button-red"
                iconLeft={<UILIB.Icons icon="cross" />}
                onClick={this.deleteCustomFieldsDrawer} />}


            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />


        </div>

    }
};

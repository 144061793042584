import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class addStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleName: "",
            inputErrors: {
                roleName: ""
            }
        };

        this.updateInput = this.updateInput.bind(this);
        this.checkForm = this.checkForm.bind(this);
    }

    componentDidMount() { }

    updateInput(ev) {
        this.setState({ [ev.currentTarget.name]: ev.currentTarget.value })
    }

    checkForm() {
        var roleName = this.state.roleName;
        var inputErrors = this.state.inputErrors;
        inputErrors.roleName = "";
        if (roleName.length < 3) {
            inputErrors.roleName = i18n.t('company:roles.addRoleError');
            this.setState({ inputErrors: inputErrors })
            return;
        }
        this.setState({ inputErrors: inputErrors });
        var data = { name: roleName };
        axios.post("/role", data).then(res => {
            var theID = res.data.Role.id;
            this.props.onAddedRole(theID);
        }).catch(err => {
            console.log(err);
            inputErrors.roleName = err.data.error;
            this.setState({ inputErrors: inputErrors })
        })
    }

    render() {
        return <div>
            <h4 className="mar-b25">{i18n.t('company:roles.addRoleHeader')}</h4>
            <div className="mar-b25">{i18n.t('company:roles.addRoleSubHeader')}</div>


            <UILIB.TextInput className="mar-b10" onEnterPress={this.checkForm} label={i18n.t('company:roles.addRoleLabel')} focus={true} type="text" placeholder={i18n.t('company:roles.addRolePlaceholder')} name="roleName" error={this.state.inputErrors.roleName} value={this.state.roleName} onChange={this.updateInput} />

            <UILIB.Button text={i18n.t('continue')} className="button-primary" onClick={this.checkForm} />


        </div>
    }
};



import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux'
import UILIB from '../../../../Common-Objects/Lib';
import axios from '~/data/http/axios';
import DateTimeFunctions from '../../../../Classes/dateTimeFunctions';
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import { parseStatus } from './helpers';
import { alterSiteDrawer } from '../../../../data/actions/siteActions';
const Map = ReactMapboxGl({
    accessToken: "pk.eyJ1IjoiY29udGFjdGdhdGUiLCJhIjoiODI5ZDJlOWNiNGM5YTM4OGM1Mzk2ODA2MjI4MTI2YmQifQ.iEJBZeGfK-7O2nOkPxIBDA"
});

export default function AccountDetailsHolder({ conversation, changeConversation = () => { } }) {
    const [subscriber, setSubscriber] = useState(null)
    const [geolocation, setGeolocation] = useState(null)
    const [subscriberStats, setSubscriberStats] = useState(null)
    const [status, setStatus] = useState(null)
    const [chats, setChats] = useState([])
    const dispatch = useDispatch()

    const getSubscriberDetails = async () => {
        const sub = (await axios.get(`/subscriber/${conversation.SubscriberId}`)).data.Subscriber
        const subscriberStats = (await axios.get('/subscriber/' + conversation.SubscriberId + '/stats')).data.Stats
        let geolocation = { longitude: 0, latitude: 0 };
        if (subscriberStats.geolocation && subscriberStats.geolocation.longitude) geolocation = subscriberStats.geolocation;

        const chats = (await axios.get(`/subscriber/${conversation.SubscriberId}/chats`)).data

        setSubscriber(sub)
        setSubscriberStats(subscriberStats)
        setGeolocation(geolocation)
        setChats(chats.filter(c => c.id !== conversation.id))
    }

    const expand = () => {
        dispatch(alterSiteDrawer(true, true, 'right', <ExpandedSubscriber subscriber={subscriber} />, true, 600))
    }

    const viewAllConversations = () => {
        dispatch(alterSiteDrawer(true, true, 'right', <OtherChats chats={chats} onClick={c => {
            dispatch(alterSiteDrawer(false, true, 'right', null))
            changeConversation(c)
        }} />, true))
    }

    const getStatus = async () => {
        if (conversation.SiteTrackerId && subscriber?.id) {
            const res = await axios.get('/subscriber/' + subscriber.id + '/chatstatus?SiteTrackerId=' + conversation.SiteTrackerId)
            setStatus(res.data.status)
        }
    }

    useEffect(() => {
        getStatus()
    }, [subscriber])

    useEffect(() => {
        getSubscriberDetails()
    }, [conversation.SubscriberId, conversation.id])

    if (!subscriber || !geolocation) return <UILIB.Paper style={{ height: "100%" }}><UILIB.LoadingIcons /></UILIB.Paper>

    return (
        <UILIB.Paper style={{ height: "100%", overflowY: 'auto', position: 'relative' }}>
            {status !== null && <div style={{ position: 'absolute', top: 15, right: 15 }}>
                <UILIB.SquareChip iconLeft={status === 1 ? <UILIB.Icons icon="circleSolid" /> : ''} className={status === 1 ? 'square-chip-green' : ''}>{status === 1 ? 'Online' : 'Offline'}</UILIB.SquareChip>
            </div>}
            <h3 className="mar-b25">Contact Details</h3>

            <div className="mar-b15">
                <Map
                    style="mapbox://styles/contactgate/cl9mrlyyz001t16nuzmha2j4j"
                    center={[geolocation.longitude, geolocation.latitude]}
                    id="mapHolder"
                    zoom={[8]}
                    containerStyle={{
                        height: 120,
                        width: "100%",
                        boxSizing: "border-box",
                        borderRadius: 8
                    }}>
                    <Marker coordinates={[geolocation.longitude, geolocation.latitude]} longitude={geolocation.longitude} latitude={geolocation.latitude}>
                        <UILIB.Icons icon="radiobutton" color={"green"} />
                    </Marker>
                </Map>
            </div>

            <div style={{ display: "flex", alignItems: "center" }} className="mar-b15">
                <UILIB.Avatar style={{ top: 3, height: 'auto', width: '100%', maxWidth: "40px", maxHeight: "40px", marginRight: "10px" }} src={subscriber.subscriberImage} />
                <h4><a href={"/cp/subscriber/" + subscriber.id} target='_blank'>{subscriber.emailAddress}</a></h4>
            </div>

            <div className="mar-b15" style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Created:</div>
                <div>{DateTimeFunctions.formatDateTime(subscriber.createdAt, 2)}</div>
            </div>
            <div className='mar-b15'>
                <div style={{ overflow: 'auto' }}>
                    {subscriber.customFields.filter(f => f.important).map((customField) => <CustomField key={customField.id} customField={customField} />)}

                    <UILIB.Button className="button-sml" onClick={expand}>View All Custom Fields</UILIB.Button>
                </div>
            </div>
            {!!chats.length && <div>
                <div className="mar-b10" style={{ fontWeight: "bold" }}>Last 4 Conversations:</div>
                <div className='mar-b10' style={{ maxHeight: 500, overflow: 'auto' }}>{<OtherChats limit={4} chats={chats} onClick={changeConversation} />}</div>
                <UILIB.Button className="button-sml" onClick={viewAllConversations}>View All</UILIB.Button>
            </div>}

        </UILIB.Paper>
    )
}

function OtherChats({ chats = [], limit = 999, onClick = () => { } }) {
    return chats.slice(0, limit).map(chat => {
        const statusColor = chat.status === 0 ? 'square-chip-green' : chat.status === 1 ? 'square-chip-yellow' : ''
        return <div key={chat.id} className='border-bottom-plain cursor-pointer hover-background' style={{ padding: '10px 0' }} onClick={() => onClick(chat.id)}>
            <div className='mar-b5'>Status: <UILIB.SquareChip className={statusColor}>{parseStatus(chat.status)}</UILIB.SquareChip></div>
            <div>Last Message: {DateTimeFunctions.formatDateTime(chat.updatedAt)}</div>
        </div>
    })
}

function ExpandedSubscriber({ subscriber }) {
    return (
        <div>
            <h4 className='mar-b20'>{subscriber.emailAddress}</h4>
            {subscriber.customFields.map((customField) => <CustomField key={customField.id} customField={customField} />)}
        </div>
    )
}

function CustomField({ customField }) {
    let value = customField.value
    if (value === undefined || value === null || value === "") value = "-BLANK-"
    if (customField.fieldType === 'DATE' && customField.value) value = DateTimeFunctions.formatDateTime(customField.value)
    if (customField.fieldType === 'SELECT' && customField.value) {
        const option = customField.options.find(o => o.value == customField.value)
        if (option) value = option.label
    }
    if (customField.fieldType === 'BOOLEAN') value = value ? 'True' : 'False'

    return <>
        <div className="txtInput_label">{customField.fieldDesc} {customField.Application ? <UILIB.Avatar src={customField.Application.appLogoUri} tooltip={customField.Application.appName} for={'cf_' + customField.id} style={{ height: 20, width: 20, top: 5 }} /> : ''}</div>
        <div className='mar-b10 text-grey text-heavy'>{value}</div>
    </>
}
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import axios from '~/data/http/axios';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import GenericStatsHolder from '../../shared/components/genericStatsHolder';

import NumberFormatFunctions from '~/Classes/numberFormatFunctions';
@connect((store) => {
  return {
    siteMaster: store.siteMaster
  }
})
export default class CampaignsAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoaded: false,
      domainsStats: [],
      campaignStats: {},
      worstDomains: [],
      geos: {},
      top100DomainStats: [],
      campaignId: this.props.match.params.campaignId,
      openingCountries: []
    }

    this.getDomainStats = this.getDomainStats.bind(this);
    this.getTop100DomainStats = this.getTop100DomainStats.bind(this);
    this.getWorstDomainStats = this.getWorstDomainStats(this);
    this.getCampaignStats = this.getCampaignStats.bind(this);
    this.getOpeningCountries = this.getOpeningCountries.bind(this);
  }

  componentDidMount() {
    this.getDomainStats()
  }

  getDomainStats() {
    axios.get("/campaign/" + this.state.campaignId + "/stats/domainStats").then(_domainStats => {

      var tmpData = _domainStats.data.DomainStats;
      var graphData = []
      if (tmpData.length < 3) {
        var x = 0;
        while (x < 3 - tmpData.length) {
          tmpData.push({ domain: "", totSent: 0, hasOpened: 0, hasClicked: 0, hasBounced: 0 })
        }
      }
      tmpData.forEach(row => {
        graphData.push({
          domain: row.domain,
          // sent: Number(row.totSent),
          opened: Number(row.hasOpened),
          clicked: Number(row.hasClicked),
          // bounced: Number(row.hasBounced)
        })
      })

      this.setState({
        domainsStats: graphData
      }, this.getWorstDomainStats)
    })
  }

  getWorstDomainStats() {
    axios.get("/campaign/" + this.state.campaignId + "/stats/worstDomains").then(_domainStats => {

      var tmpData = _domainStats.data.DomainStats;
      var graphData = []
      if (tmpData.length < 3) {
        var x = 0;
        while (x < 3 - tmpData.length) {
          tmpData.push({ domain: "", totSent: 0, hasOpened: 0, hasClicked: 0, hasBounced: 0 })
        }
      }
      tmpData.forEach(row => {
        graphData.push({
          domain: row.domain,
          // sent: Number(row.totSent),
          opened: Number(row.hasOpened),
          clicked: Number(row.hasClicked),
          // bounced: Number(row.hasBounced)
        })
      })


      this.setState({
        worstDomains: tmpData
      }, this.getCampaignStats)
    })
  }

  getCampaignStats() {
    var self = this;
    axios.get('/campaign/' + this.state.campaignId + '/stats?ignoreLog=1')
      .then((res) => {

        var stats = res.data.Stats

        if (this.props.isAutomation == true) {

          stats['Total Subscribers'] = stats['Total Sent']
        }

        var geos = self.state.geos
        geos.features = res.data.Maps.map(row => {
          return {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: [row.longitude, row.latitude]
            }
          }
        })
        self.setState({
          stats: stats,
          geos: {
            type: "FeatureCollection",
            features: res.data.Maps.map(row => {
              return {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Point",
                  coordinates: [row.longitude, row.latitude]
                }
              }
            })
          },
        }, this.getOpeningCountries);

      })
      .catch(ex => {
        console.log(ex)
      })
  }

  async getOpeningCountries() {
    let openingCountries = await axios.get(`/campaign/${this.state.campaignId}/stats/countryoverview`)

    let tableData = openingCountries.data.map(c => {
      let img = "";
      if (c.country != "" && c.country != "-") {
        img = `https://cdn1.ourmailsender.com/siteContent/countries/raw/${c.country.toLowerCase()}.png`
      }
      return {
        img: {
          headerValue: "",
          value: <img src={img} width="20" />,
          orderBy: false,
          width: 20
        },
        country: {
          headerValue: i18n.t('country'),
          value: c.countryName,
          orderBy: false
        },
        cnt: {
          headerValue: i18n.t('opens'),
          value: c.cnt,
          orderBy: false
        },
        options: {
          headerValue: "",
          value: <UILIB.Button text={i18n.t('view')} onClick={() => { this.updateCountryCode(c.country) }} />,
          orderBy: false,
          align: "right"
        }
      }
    })
    this.setState({ openingCountries: tableData }, this.getTop100DomainStats)
  }

  getTop100DomainStats() {
    axios.get("/campaign/" + this.state.campaignId + "/stats/domainStatsTop100").then(_domainStats => {
      var totDelivered = this.state.stats['Total Subscribers'] - this.state.stats['Hard Bounces'] - this.state.stats['Soft Bounces'] - this.state.stats['Total Dropped'];
      this.setState({
        top100DomainStats: _domainStats.data.DomainStats.map(stat => {
          var openedPct = ((stat.hasOpened / stat.totalSent) * 100).toFixed(2);
          return {

            domain: {
              headerValue: i18n.t('campaigns:overview.domain'),
              value: stat.domain,
              orderBy: false
            },
            sent: {
              headerValue: i18n.t('campaigns:overview.sent'),
              value: stat.totalSent,
              orderBy: false
            },
            opens: {
              headerValue: i18n.t('campaigns:overview.opened'),
              value: stat.hasOpened,
              orderBy: false
            },
            openedPct: {
              headerValue: i18n.t('campaigns:overview.pctSent'),
              value: openedPct + "%",
              orderBy: false
            }
          }
        }),
        pageLoaded: true
      })
    })
  }

  updateCountryCode(country) {
    this.props.history.push(`/cp/campaigns/view/${this.state.campaignId}/opened?country=${country}`)
  }
  render() {
    if (!this.state.pageLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>;
    var totDelivered = this.state.stats['Total Subscribers'] - this.state.stats['Hard Bounces'] - this.state.stats['Soft Bounces'] - this.state.stats['Total Dropped'];
    var undelivered = this.state.stats['Hard Bounces'] + this.state.stats['Soft Bounces'] + this.state.stats['Total Dropped'];


    return <div>
      <UILIB.Row>
        <UILIB.Column xs={12} sm={6}>
          <GenericStatsHolder value={totDelivered} limit={this.state.stats['Total Subscribers']} header={i18n.t('campaigns:overview.delivered')} link={null} color={this.props.siteMaster.colours['$status-green']} />
        </UILIB.Column>
        <UILIB.Column xs={12} sm={6}>
          <GenericStatsHolder value={undelivered} limit={this.state.stats['Total Subscribers']} header={i18n.t('campaigns:overview.unDelivered')} link={null} color={this.props.siteMaster.colours['$status-orange']} />
        </UILIB.Column>

        <UILIB.Column xs={12} sm={6}>

          <UILIB.Paper>

            <h4 className="mar-b25">{i18n.t('campaigns:overview.bestPerformingDomains')}</h4>

            <ResponsiveContainer height={250}>
              <BarChart data={this.state.domainsStats}
                margin={{ top: 0, right: 0, left: 20, bottom: 0 }}>
                <XAxis dataKey="domain" axisLine={false} tickLine={false} padding={{ left: 15 }} tick={{ fontSize: 10, fill: '#999', fontFamily: "Open Sans", fontWeight: 600 }} />
                <YAxis axisLine={false} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontFamily: "Open Sans", fontWeight: 600 }} />
                <Tooltip content={<UILIB.CustomTooltip overrideColor={true} showLabel={true} />} />

                <Bar
                  name={i18n.t('campaigns:main.statsOpened')}
                  dataKey="opened"
                  strokeWidth={0}
                  fillOpacity={0.8}
                  fill={this.props.siteMaster.colours.$primary}
                />
                <Bar
                  name={i18n.t('campaigns:main.statsClicked')}
                  dataKey="clicked"
                  strokeWidth={0}
                  fillOpacity={0.8}
                  fill={this.props.siteMaster.colours["$secondary"]}
                />

              </BarChart>
            </ResponsiveContainer>
          </UILIB.Paper>
        </UILIB.Column>
        <UILIB.Column xs={12} sm={6}>
          <UILIB.Paper>
            <h4 className="mar-b25">{i18n.t('campaigns:overview.worstPerformingDomains')}</h4>
            <ResponsiveContainer height={250}>
              <BarChart data={this.state.worstDomains}
                margin={{ top: 0, right: 0, left: 20, bottom: 0 }}>
                <XAxis dataKey="domain" axisLine={false} tickLine={false} padding={{ left: 15 }} tick={{ fontSize: 10, fill: '#999', fontFamily: "Open Sans", fontWeight: 600 }} />
                <YAxis axisLine={false} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontFamily: "Open Sans", fontWeight: 600 }} />
                <Tooltip content={<UILIB.CustomTooltip overrideColor={true} showLabel={true} />} />

                <Bar
                  name={i18n.t('campaigns:main.totalSent')}
                  dataKey="totalSent"
                  strokeWidth={0}
                  fillOpacity={0.8}
                  fill={this.props.siteMaster.colours.$primary}
                />
                <Bar
                  name={i18n.t('campaigns:main.statsOpened')}
                  dataKey="hasOpened"
                  strokeWidth={0}
                  fillOpacity={0.8}
                  fill={this.props.siteMaster.colours["$secondary"]}
                />


              </BarChart>
            </ResponsiveContainer>
          </UILIB.Paper>

        </UILIB.Column >

        <UILIB.Column xs={12} sm={12} md={6}>
          <UILIB.Paper>
            <h4 className="mar-b25">{i18n.t('campaigns:overview.bestPerformingDomains100')}</h4>

            <div style={{ maxHeight: "745px", overflow: "auto" }}>
              <UILIB.DataTable1 noResultsTxt={<span>{i18n.t('noResults')}</span>} tableData={this.state.top100DomainStats} loadingData={false}
                dataUpdater={() => { }} width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn={undefined}
                sortedDirection={undefined}
              />
            </div>

          </UILIB.Paper>
        </UILIB.Column>

        <UILIB.Column xs={12} sm={12} md={6}>
          <UILIB.Paper>
            <h4 className="mar-b25">{i18n.t('campaigns:overview.countriesMostOpened')}</h4>

            <div style={{ maxHeight: "745px", overflow: "auto" }}>
              <UILIB.DataTable1 noResultsTxt={<span>{i18n.t('noResults')}</span>} tableData={this.state.openingCountries} loadingData={false}
                dataUpdater={() => { }} width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn={undefined}
                sortedDirection={undefined}
              />
            </div>

          </UILIB.Paper>
        </UILIB.Column>

      </UILIB.Row >
    </div >
  }
}
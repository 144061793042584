import React from 'react'
import axios from '~/data/http/axios';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import EmailTools from '~/Classes/emailValidator';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import i18n from '~/i18n'
import EmailPreviewer from '~/pages/cp/includes/emailPreviewer/emailPreviewer'
import ReactTooltip from 'react-tooltip'
import SiteVars from '~/Classes/siteVars';
import EmailValidator from '~/Classes/emailValidator'
import AddDomainPop from './addDomainPop';
import NewUserForm from '~/pages/cp/includes/bb_topNav/newUserForm/newUserForm'
import SpamTesterAdvanced from '~/pages/cp/includes/spamTester/spamTesterAdvanced'
import moment from 'moment';
import MultiEmailPicker from '~/pages/cp/includes/multiEmailPicker/multiEmailPicker'
import DedicatedServerSelector from '~/pages/cp/includes/dedicatedServerSelector/dedicatedServerSelector'
import SendDailyUpdateEmail from './sendDailyUpdateEmail'
import tierPermissions from '~/Classes/tierPermissions';
import textFunctions from '~/Classes/textFunctions';
import CampaignAdvancedOptions from '../advanced';
import CampaignIntegration from '../integration'
import AuthDomainPop from '~/pages/cp/company/domains/authDomainPop';
import CampaignAutomate from '../../automate';
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

export default class StandardCampaignStep3 extends React.Component {
    constructor(props) {
        super(props);

        this.imageCheckTimer = null;
        this.updateTimer = null;

        this.state = {
            showAdvanced: false,
            errors: [],
            goodStuff: [],
            errorFields: { fromName: "", fromAddress: "", replyAddress: "", subject: "", sendUpdateEmailsRecipient: "" },
            warnings: [],
            trackingDomains: [],
            cdnDomains: [],
            mailservers: [],
            mailserverGroups: [],
            domainName: '',
            emailSent: false,
            validateAddress: '',
            subscriberCount: 0,
            now: Date.now(),
            previewEmailAddresses: [{
                emailAddress: this.props.user.userData.emailAddress,
                error: '',
                domainError: ''
            }],
            quickPreviewEmailAddress: '',
            quickPreviewEmailAddressError: '',
            quickPreviewEmailAddressErrorDomainAuth: '',
            alertMessage: "",
            alertOpen: false,
            domainExists: false,
            domainOrigEmailSentTo: "",
            showVerifyDomain: false,
            showVerifyDomainType: "new",
            domainVerified: false,
            domainBlocked: false,
            domainAuthenticated: false,
            templateImageLoaded: false,
            fromAddresses: [],
            matchingFromAddress: [],
            showSuggestions: false,
            sending: false,
            Mailboxes: [],
            pageLoaded: false,
            timeblocks: [],
            advOptions: {
                showLimitSendingTimes: false,
                showResendAfter: false
            },
            subjectWarnings: [],
            tierErrored: false,
            tierPermissionsErrored: {},
            triedToSave: false,
            weekDays: [{ value: 1, label: "Monday" }, { value: 2, label: "Tuesday" }, { value: 3, label: "Wednesday" }, { value: 4, label: "Thursday" }, { value: 5, label: "Friday" }, { value: 6, label: "Saturday" }, { value: 0, label: "Sunday" }],
            replyAddressPopoverOpen: false,
            MailboxesC: [],
            useFromAddress: true
        }

        this.updateValue = this.updateValue.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.getPreviousFromAddresses = this.getPreviousFromAddresses.bind(this)
        this.changeFromAddress = this.changeFromAddress.bind(this)
        this.showFromSuggestions = this.showFromSuggestions.bind(this)
        this.selectPreviousFromAddress = this.selectPreviousFromAddress.bind(this)

        this.checkFromEmail = this.checkFromEmail.bind(this);
        this.checkForm = this.checkForm.bind(this);
        this.send = this.send.bind(this);
        this.save = this.save.bind(this);
        this.quickSave = this.quickSave.bind(this);
        this.openEmailPreview = this.openEmailPreview.bind(this);
        this.quickPreviewSend = this.quickPreviewSend.bind(this);
        this.openValidateDomain = this.openValidateDomain.bind(this);
        this.hideVerifyDomain = this.hideVerifyDomain.bind(this);
        this.authenticateDomain = this.authenticateDomain.bind(this);
        this.goValidateDomain = this.goValidateDomain.bind(this);
        this.checkSubject = this.checkSubject.bind(this);

        this.init = this.init.bind(this);

        this.updatePreview = this.updatePreview.bind(this);
        this.addPreview = this.addPreview.bind(this);

        this.checkTemplateImageExists = this.checkTemplateImageExists.bind(this);
        this.activateAccount = this.activateAccount.bind(this);
        this.openMultiEmailPicker = this.openMultiEmailPicker.bind(this);
        this.finishedMultiEmailPicker = this.finishedMultiEmailPicker.bind(this);

        this.updateOptions = this.updateOptions.bind(this)
        this.changeAdvOptions = this.changeAdvOptions.bind(this);
        this.showAdvanced = this.showAdvanced.bind(this)
        this.hideAdvanced = this.hideAdvanced.bind(this)
        this.startSpamTest = this.startSpamTest.bind(this);

        this.showAppOptions = this.showAppOptions.bind(this)
    }

    componentDidMount() {
        this.init();
    }

    async init() {
        var cdns = [];
        var domains = [];

        this.getPreviousFromAddresses()

        var timeblocks = []
        var time = moment('00:00', 'HH:mm')
        for (var i = 0; i < 48; i++) {
            let timeUtc = time.clone().utc();
            timeblocks.push({ label: time.format('HH:mm'), value: timeUtc.format() })
            time.add(30, 'minutes')
        }
        this.setState({ timeblocks })

        const sendingDomainsR = await axios.get('/accountMaster/domain')
        this.setState({
            sendingDomains: sendingDomainsR.data.domains
        })


        const domainsR = await axios.get('/accountMaster/tracking')
        domains = domainsR.data.Domains;
        domains = domains.filter(d => d.authenticated)
        if (domains && domains.length) {
            this.props.campaign.TrackingDomainId = this.props.campaign.TrackingDomainId || domains[0].id
        }

        const cdnsR = await axios.get('/accountMaster/cdn');
        cdns = cdnsR.data.Domains.filter(d => d.authenticated);
        if (cdns && cdns.length) {
            this.props.campaign.CdnDomainId = this.props.campaign.CdnDomainId || cdns[0].id
        }
        this.props.updateCampaign()
        this.setState({
            quickPreviewEmailAddress: this.props.user.userData.emailAddress,
            cdnDomains: cdns,
            subscriberCount: this.props.subscriberCount,
            sendingLimitReached: this.props.sendingLimitReached,
            trackingDomains: domains,
            pageLoaded: true
        }, () => {
            this.checkFromEmail(this.props.campaign.fromAddress)
            this.checkForm()
        })

        if (this.props.accountMaster.accountMaster.options.allowMailServerSelection) {

            const maiLServersR = await axios.get('/accountMaster/mailservers')
            let ms = maiLServersR.data.MailServers;
            const msGR = await axios.get('/mailServer/group')
            this.setState({
                mailservers: ms,
                mailserverGroups: msGR.data.Groups
            })
        }

        //old reply tracking
        const mailBoxesR = await axios.get('/accountMaster/mailbox')
        this.setState({
            Mailboxes: mailBoxesR.data.Mailboxes
        })

        //conversations tracking
        const mailBoxesC = await axios.get('/inboxes/emailChannels')
        this.setState({
            MailboxesC: mailBoxesC.data
        })


        if (this.props.campaign.fromAddress) {
            this.checkFromEmail(this.props.campaign.fromAddress);
        }
        if (this.props.campaign.subject) {
            this.checkSubject()
        }
        // this.checkForm();

        if (this.props.campaign.options) {
            var advOptions = this.state.advOptions;
            if (this.props.campaign.options.betweenStart || this.props.campaign.options.betweenEnd) {
                advOptions.showLimitSendingTimes = true;
            }
            if (this.props.campaign.resendAfter) {
                advOptions.showResendAfter = true;
            }
            if (this.props.campaign.dynamicRssFound) {
                if (this.props.campaign.options.recurringType && this.props.campaign.options.recurringType == "daily") {
                    if (!this.props.campaign.options.recurringWeekDays || !Array.isArray(this.props.campaign.options.recurringWeekDays)) {
                        this.props.campaign.options.recurringWeekDays = [0, 1, 2, 3, 4, 5, 6]
                    }
                }
            }
            this.setState({ advOptions })
        }
        this.checkTemplateImageExists();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.sendingLimitReached !== this.props.sendingLimitReached || prevProps.subscriberCount !== this.props.subscriberCount || prevProps.calculatedPrice !== this.props.calculatedPrice)
            this.checkForm()

        if (prevProps.campaign && this.props.campaign && (prevProps.campaign.fromAddress !== this.props.campaign.fromAddress)) {
            this.checkFromEmail(this.props.campaign.fromAddress);
        }
    }

    componentWillUnmount() {
        if (this.imageCheckTimer) {
            clearTimeout(this.imageCheckTimer)
        }
    }

    getPreviousFromAddresses() {
        axios.get('/campaign?fields=fromAddress&sortColumn=fromAddress').then(response => {
            this.setState({
                fromAddresses: response.data.Campaigns.map(c => c.fromAddress).filter((v, i, s) => v && s.indexOf(v) === i)
            })
        })
    }

    updateValue(ev, inState, options) {

        var theVal = ev.currentTarget.value;
        const name = ev.currentTarget.name;

        if (ev.currentTarget.type == "checkbox") {
            theVal = false;
            if (ev.currentTarget.checked == true) {
                theVal = true;
            }
        }
        if (name == "replyAddress" || name == "fromAddress") theVal = theVal.toLowerCase();

        if (name === 'resendAfter') {
            if (isNaN(theVal) || theVal === '') theVal = 0
            else theVal = parseInt(theVal)
        }

        if (inState) {
            this.setState({
                [name]: theVal
            }, this.checkForm)
        } else {
            if (options) {
                if (!this.props.campaign.options) this.props.campaign.options = {};
                this.props.campaign.options[name] = theVal;
            } else {
                this.props.campaign[name] = theVal;
            }
            this.props.updateCampaign()
            this.checkForm();
        }
    }

    showFromSuggestions() {
        this.changeFromAddress()
        this.setState({
            showSuggestions: true
        })
    }

    selectPreviousFromAddress(address) {
        this.setState({
            showSuggestions: false
        })
        this.props.campaign.fromAddress = address
        this.props.updateCampaign()
        this.checkFromEmail(address)
    }

    changeFromAddress(ev) {
        var theVal = ev ? ev.currentTarget.value : this.props.campaign.fromAddress ? this.props.campaign.fromAddress : ''
        if (ev) {
            theVal = ev.currentTarget.value;
            if (EmailTools.validateEmail(ev.currentTarget.value)) {
                if (this.checkTimer) clearTimeout(this.checkTimer)
                this.checkTImer = setTimeout(() => this.checkFromEmail(ev.currentTarget.value), 250)
            }
            this.updateValue(ev)
        }

        theVal = theVal.toLowerCase()
        // compare the current email with past reply addresses used and suggest autocomplete
        var matches = this.state.fromAddresses.filter(r => r.toLowerCase().indexOf(theVal) > -1 && r.toLowerCase() !== theVal)
        this.setState({
            matchingFromAddress: matches
        })
    }

    checkSubject(ev) {
        if (ev) {
            this.props.campaign[ev.currentTarget.name] = ev.currentTarget.value;
            this.props.updateCampaign()
            this.checkForm();
        }
        if (this.subjectTimer) clearTimeout(this.subjectTimer)
        this.subjectTimer = setTimeout(() => {
            var subject = this.props.campaign.subject;
            axios.get('/campaign/checksubject?subject=' + encodeURIComponent(subject)).then(res => {
                this.setState({
                    subjectWarnings: res.data
                }, this.checkForm)
            })
        }, 1000)
    }

    setStartDate(event, value) {
        var campaign = this.props.campaign;
        campaign.startDate = value
        this.props.updateCampaign();
        this.checkForm();
    }

    async checkFromEmail(value) {
        var self = this;
        if (this.state.checkingReplyAddress) return
        this.setState({
            checkingReplyAddress: true
        })

        if (value && value.indexOf('@') > -1 && EmailTools.validateEmail(self.props.campaign.fromAddress)) {
            try {
                var domain = value.split('@')[1].toLowerCase()
                const response = await axios.get('/campaign/checkdomain?email=' + encodeURIComponent(value))
                var isVerified = false;
                var originalEmailAddress = "";
                var originalDomainID = 0;
                var isAuthenticated = false;
                if (response.data.hasOwnProperty("Domain") === false) {
                    isVerified = true;
                }
                else {
                    isVerified = response.data.Domain.verified;
                    originalEmailAddress = response.data.Domain.originalEmailAddress;
                    originalDomainID = response.data.Domain.id;
                    isAuthenticated = response.data.Domain.authenticated;
                }

                if (response.data.Domain && !response.data.Domain.authenticated) {
                    const authResponse = await axios.put('/accountMaster/domain/' + response.data.Domain.id + '/authenticate')
                    if (authResponse.data.Success) {
                        this.setState({ checkingReplyAddress: false })
                        return this.checkFromEmail(value)
                    }
                }

                self.setState({
                    domainExists: response.data.Exists,
                    domainName: '@' + domain,
                    domainVerified: isVerified,
                    domainOrigEmailSentTo: originalEmailAddress,
                    domainId: originalDomainID,
                    domainBlocked: false,
                    domainAuthenticated: isAuthenticated,
                    checkingReplyAddress: false
                }, self.checkForm)
            } catch (err) {
                if (err.data.error) {
                    self.setState({
                        domainVerified: false,
                        domainBlocked: true,
                        domainAuthenticated: false,
                        checkingReplyAddress: false
                    }, self.checkForm);
                } else {
                    self.checkForm;
                }
            }

            // look for domains in the cdn and tracking domains and only show the ones that match the domain
            var filteredTrackingDomains = this.state.trackingDomains.filter(d => d.domainName.indexOf(domain) > -1)
            var filteredCDNDomains = this.state.cdnDomains.filter(d => d.domainName.indexOf(domain) > -1)



            if (filteredTrackingDomains && filteredTrackingDomains.length) {
                var foundCurrent = filteredTrackingDomains.find(f => f.id == this.props.campaign.TrackingDomainId)
                if (!foundCurrent) {
                    this.props.campaign.TrackingDomainId = filteredTrackingDomains[0].id
                }
            }
            if (filteredCDNDomains && filteredCDNDomains.length) {
                var foundCurrent = filteredCDNDomains.find(f => f.id == this.props.campaign.CdnDomainId)
                if (!foundCurrent) {
                    this.props.campaign.CdnDomainId = filteredCDNDomains[0].id
                }
            }

            this.setState({
                filteredTrackingDomains,
                filteredCDNDomains,

            })
        } else {
            this.setState({
                filteredTrackingDomains: this.state.trackingDomains,
                filteredCDNDomains: this.state.cdnDomains,
                checkingReplyAddress: false
            })
        }
    }

    openValidateDomain(theType) {
        this.setState({ showVerifyDomain: true, showVerifyDomainType: theType })
    }

    hideVerifyDomain(stuff) {
        this.setState({ showVerifyDomain: false })
    }

    openMultiEmailPicker() {

        var drawerContent = <MultiEmailPicker createdRules={this.finishedMultiEmailPicker} campaign={this.props.campaign} trackingDomains={this.state.trackingDomains} cdnDomains={this.state.cdnDomains} sendingDomain={this.state.sendingDomains} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw'));
    }

    finishedMultiEmailPicker(finalRules, defaultEmail, defaultFromName, defaultReplyAddress, ruleSetName) {

        var theCampaign = this.props.campaign;
        theCampaign.replyAddress = defaultReplyAddress;
        theCampaign.fromName = defaultFromName;
        theCampaign.fromAddress = defaultEmail;
        theCampaign.multiAddressRules = finalRules;

        var cOptions = theCampaign.options;
        if (ruleSetName && ruleSetName.length) {
            cOptions.multiAddressRuleName = ruleSetName;
        }

        this.props.updateCampaign()
        this.checkForm();

        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", undefined, true));

    }

    openEmailPreview() {
        var turnOffTracking = false;
        if (this.props.campaign && this.props.campaign.options && this.props.campaign.options.doNotTrackLinks && this.props.campaign.options.doNotTrackLinks == true) {
            turnOffTracking = true;
        }
        var drawerContent = <EmailPreviewer
            templateId={this.props.campaign.TemplateId}
            subject={'TEST: ' + (this.props.campaign.subject || this.props.campaign.campaignName)}
            history={this.props.history}
            turnOffTracking={turnOffTracking}
            fromCampaignId={this.props.campaign.id}
            fromAddress={this.state.errorFields.fromAddress ? undefined : this.props.campaign.fromAddress}
            fromName={this.props.campaign.fromName}
            CdnDomainId={this.props.campaign.CdnDomainId}
            TrackingDomainId={this.props.campaign.TrackingDomainId}
        />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
    }

    quickPreviewSend() {

        var self = this;
        var hasErrors = false;
        var previewEmailAddresses = this.state.previewEmailAddresses
        previewEmailAddresses.forEach(preview => {
            if (preview.emailAddress) preview.emailAddress = preview.emailAddress.trim()
            if (preview.emailAddress && !EmailValidator.validateEmail(preview.emailAddress)) {
                hasErrors = true;
                preview.error = i18n.t('campaigns:standard.step3.emailInvalid')
            }
        })
        this.setState({
            previewEmailAddresses: previewEmailAddresses
        })
        if (hasErrors) {
            return
        }
        this.setState({ alertOpen: true, alertMessage: i18n.t('campaigns:standard.step3.sendingPreview') })


        var body = {
            emailAddress: previewEmailAddresses.filter(p => p.emailAddress).map(p => p.emailAddress),
            subject: this.props.campaign.subject ? 'Test: ' + this.props.campaign.subject : ''
        }
        if (this.props.campaign && this.props.campaign.options && this.props.campaign.options.doNotTrackLinks && this.props.campaign.options.doNotTrackLinks == true) {
            body.trackLinks = false;
            body.trackOpens = false;
        }
        body.TrackingDomainId = this.props.campaign.TrackingDomainId;
        body.CdnDomainId = this.props.campaign.CdnDomainId;
        body.fromAddress = this.props.campaign.fromAddress;
        if (this.state.errorFields.fromAddress || !this.state.useFromAddress) delete body.fromAddress;
        body.fromName = this.props.campaign.fromName;
        body.preheaderText = this.props.campaign.options?.preheaderText
        axios
            .post('/template/preview/' + this.props.campaign.TemplateId,
                body
            )
            .then((res) => {
                self.setState({ alertOpen: true, alertMessage: i18n.t('campaigns:standard.step3.sentPreview') })

            }).catch(function (error) {
                var quickPreviewEmailAddressErrorDomainAuth = '';
                if (error.data.error.fromAddress) {
                    quickPreviewEmailAddressErrorDomainAuth = <div className="errorHolderWarning">
                        <div className="text-red mar-b15">{i18n.t('campaigns:standard.step3.mustVerifyAccountEmail')} <strong>{self.props.user.userData.emailAddress}</strong> {i18n.t('campaigns:standard.step3.beforeYouCanSendPreview')}</div>

                        <UILIB.Button className="button-sml min-shadow outline red" text="Verify your Address" onClick={self.goValidateDomain} />
                    </div>
                }
                self.setState({ quickPreviewEmailAddressError: i18n.t('campaigns:standard.step3.errorPreview'), alertOpen: true, alertMessage: i18n.t('campaigns:standard.step3.errorPreview'), quickPreviewEmailAddressErrorDomainAuth: quickPreviewEmailAddressErrorDomainAuth })
            })
    }

    async authenticateDomain() {
        const res = await axios.get('/accountMaster/domain?domain=' + this.props.campaign.fromAddress.split('@')[1].toLowerCase())
        if (res.data.domains.length) {
            const drawerContent = <AuthDomainPop domain={res.data.domains[0]} onAuthed={() => this.checkFromEmail(this.props.campaign.fromAddress)} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, 'right', drawerContent, true, 600))
        } else {
            window.open('/cp/company/domains', '_blank');
        }
    }

    updateOptions(ev) {
        var options = this.props.campaign.options || {}
        var value = ev.currentTarget.value;
        if (ev.target.name == "recurringType") {
            if (value == "none") {
                options.recurringValue = "0"
            }
            if (value == "daily") {
                options.recurringValue = "1"
                options.recurringWeekDays = [0, 1, 2, 3, 4, 5, 6]
            }
            if (value == "weekly") {
                options.recurringValue = "1"
            }
            if (value == "monthly") {
                options.recurringValue = "1"
            }
        }

        if (ev.target.name == "recurringWeekDays") {
            value = Number(value);
            let recurringOptions = options[ev.currentTarget.name];
            if (!recurringOptions || !Array.isArray(recurringOptions)) recurringOptions = [];
            if (recurringOptions.indexOf(value) > -1) {
                recurringOptions.splice(recurringOptions.indexOf(value), 1)
            }
            else {
                recurringOptions.push(value)
            }
            value = recurringOptions;
        }
        options[ev.currentTarget.name] = value
        options.timezone = this.props.user.userData.timeZone
        this.props.updateCampaign()
        this.checkForm();
    }

    activateAccount() {
        var theDrawer = <NewUserForm from="campaign" closeNewUserForm={() => { }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, false, "100vw", 'full'));
    }

    showAdvanced() {
        this.setState({ showAdvanced: true })
    }

    hideAdvanced() {
        this.setState({ showAdvanced: false })
        this.checkForm()
    }

    checkForm() {
        let showBilling = true;
        if (this.props.siteMaster.hidePricing) showBilling = false;
        if (this.props.accountMaster.accountMaster.invoice) showBilling = false;

        var campaign = this.props.campaign;
        var errors = [];
        var goodStuff = [];
        var warnings = [];

        if (this.props.campaign.options.doNotTrackOpens) {
            warnings.push({ value: <div>You have set opens to not be tracked, no open stats will show for this campaign <a onClick={this.showAdvanced}>Change</a></div> })
        }
        if (this.props.campaign.options.doNotTrackLinks) {
            warnings.push({ value: <div>You have set links to not be tracked, no link stats will show for this campaign <a onClick={this.showAdvanced}>Change</a></div> })
        }

        var errorFields = { fromName: "", fromAddress: "", replyAddress: "", subject: "", sendUpdateEmailsRecipient: "", recurringWeekDays: "" };

        if (!campaign.Segments.length && !campaign.Groups.length) {
            errors.push({ value: <span><a onClick={() => { this.props.changeStep(1) }}>No Group or Segment selected</a></span> })
        }


        if (!campaign.fromName || campaign.fromName.length < 3) {
            errors.push({ value: <span><a onClick={() => this.fromName.focus()}>{i18n.t('campaigns:standard.step3.errorFrom')}</a></span> })
            errorFields.fromName = " ";
        }
        else {
            goodStuff.push({ value: <span>{i18n.t('campaigns:standard.step3.validFrom')}</span> })
        }

        if (!campaign.replyAddress || EmailTools.validateEmail(campaign.replyAddress) === false) {
            errors.push({ value: <span><a onClick={() => this.replyAddress.focus()}>{i18n.t('campaigns:standard.step3.errorReplyAddress')}</a></span> })
            errorFields.replyAddress = " ";
        } else {
            goodStuff.push({ value: <span>{i18n.t('campaigns:standard.step3.validFromAddress')}</span> })
        }

        if (!campaign.fromAddress || EmailTools.validateEmail(campaign.fromAddress) === false) {
            errors.push({ value: <span><a onClick={() => this.fromAddress.focus()}>{i18n.t('campaigns:standard.step3.errorFromAddress')}</a></span> })
            errorFields.fromAddress = " ";
        } else {
            if (this.state.domainBlocked === true) {
                errors.push({ value: <span>{i18n.t('campaigns:standard.step3.blockedReplyAddress')}</span> })
                errorFields.fromAddress = " ";
            }
            else {
                if (this.state.domainExists === false) {
                    errors.push({ value: <span><a onClick={() => { this.openValidateDomain("new") }}>{i18n.t('campaigns:standard.step3.verifyFromAddress')}</a></span> })
                    errorFields.fromAddress = " ";
                }
                else {
                    if (this.state.domainVerified === false) {
                        errors.push({ value: <span><a onClick={() => { this.openValidateDomain("existing") }}>{i18n.t('campaigns:standard.step3.awaitingFromAddress')}</a></span> })
                        errorFields.fromAddress = " ";
                    }
                    else {
                        goodStuff.push({ value: <span>{i18n.t('campaigns:standard.step3.validReplyAddress')}</span> })
                        if (this.state.domainAuthenticated === false && (!this.props.siteMaster.usersMustBeAuthenticated || this.props.siteMaster.usersMustBeAuthenticated == false)) {
                            warnings.push({ value: <span><a onClick={() => { this.authenticateDomain() }} dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step3.authReplyAddress', { domain: textFunctions.checkForHtml(campaign.fromAddress.split("@")[1]) }) }}></a></span> })
                        }
                        if (this.state.domainAuthenticated === false && this.props.siteMaster.usersMustBeAuthenticated) {
                            errors.push({ value: <span><a onClick={() => { this.props.history.push('/cp/company/domains') }} dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step3.authReplyAddressRequired', { domain: textFunctions.checkForHtml(campaign.fromAddress.split("@")[1]) }) }}></a></span> })
                        }

                    }
                }
            }
        }


        if (!campaign.subject || campaign.subject.length < 4) {
            errors.push({ value: <span><a onClick={() => { this.subject.focus() }}>{i18n.t('campaigns:standard.step3.errorSubject')}</a></span> })
            errorFields.subject = " ";
        }
        else if (this.state.subjectWarnings.length && !this.state.mailservers.length && this.props.siteMaster.siteId == 1) {
            var warningsText = "";
            var bannedText = "";

            this.state.subjectWarnings.forEach(w => {
                if (w.score >= 5) {
                    bannedText += w.text + ",";
                }
                else {
                    warningsText += w.text + ",";
                }
            })

            if (warningsText.length > 1) {
                warningsText = warningsText.substr(0, warningsText.length - 1)
                warningsText = <span dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step3.subjectWarning', { warnings: textFunctions.checkForHtml(warningsText) }) }}></ span>
                warnings.push({ value: warningsText })
            }
            if (bannedText.length > 1) {
                bannedText = bannedText.substr(0, bannedText.length - 1)
                bannedText = <span dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step3.subjectWarning', { warnings: textFunctions.checkForHtml(bannedText) }) }}></ span>
                errors.push({ value: bannedText })
            }

        } else {
            goodStuff.push({ value: <span>{i18n.t('campaigns:standard.step3.validSubject')}</span> })
        }


        if (!this.props.subscriberCount || this.props.subscriberCount === 0 || (!this.props.campaign.Groups.length && !this.props.campaign.Segments.length)) {
            errors.push({ value: <span><a onClick={() => { this.props.changeStep(1) }}>{i18n.t('campaigns:standard.step3.noSubs')}</a></span> })
        } else {
            let groupsAndSegments = this.props.campaign.Groups.concat(this.props.campaign.Segments).map(s => s.name).join(', ')
            goodStuff.push({
                value: <span>
                    <span data-for="textSubs" data-tip={groupsAndSegments} className="cursor-pointer" onClick={() => this.props.changeStep(1)} dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step3.subs', { subscriberCount: NumberFunctions.formatNumber(this.props.campaign.limit && this.props.campaign.limit > 0 && this.props.subscriberCount > this.props.campaign.limit ? this.props.campaign.limit : this.props.subscriberCount) }) }} />
                    <ReactTooltip effect='solid' className={"tooltip fix-font"} id="textSubs" ></ReactTooltip>
                </span>
            })
            if (this.props.sendingLimitReached && this.props.accountMaster.accountMaster.billedOnContacts) {
                errors.push({
                    value: <span>
                        You have exceeded your contact limit.
                        {showBilling && <span>Please <a className="text-red" style={{ textDecoration: "underline" }} onClick={() => { this.props.history.push('/cp/company/subscription') }}>upgrade your account</a></span>}
                        {!showBilling && <span>Please <a href={"mailto:" + this.props.siteMaster.supportEmail}>contact support</a></span>}
                    </span>
                })
            }
            else if (this.props.sendingLimitReached && !this.props.newBillingPlan) {
                errors.push({ value: <span>{i18n.t('campaigns:standard.step3.exceedingLimit')} <a onClick={() => { this.props.changeStep(1) }}>{i18n.t('campaigns:standard.step3.changeFilters')}</a> or <a onClick={() => { this.props.history.push('/cp/company/subscription') }}>{i18n.t('campaigns:standard.step3.upgradePlan')}</a></span> })
            } else if (this.props.sendingLimitReached && this.props.newBillingPlan && this.props.calculatedPrice) {
                let price = this.props.calculatedPrice.symbol + this.props.calculatedPrice.price
                warnings.push({
                    value: <span dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step3.exceedingLimitUpgrade', { plan: this.props.newBillingPlan.name, price }) }}></span>
                })
            }
        }

        if (!campaign.Template) {
            errors.push({ value: <span><a onClick={() => { this.props.changeStep(2) }}>{i18n.t('campaigns:standard.step3.errorTemplate')}</a></span> })
        } else {
            goodStuff.push({ value: <span>{i18n.t('campaigns:standard.step3.validTemplate')}</span> })
        }


        if (this.props.accountMaster.accountMaster.type && (!this.state.filteredTrackingDomains || !this.state.filteredTrackingDomains.length)) {
            errors.push({ value: <span><a onClick={() => { this.props.history.push('/cp/company/domains') }}>{i18n.t('campaigns:standard.step3.mustSetupTrackingDomain')}</a></span> })
        }

        if (this.props.accountMaster.accountMaster.type && (!this.state.filteredCDNDomains || !this.state.filteredCDNDomains.length)) {
            errors.push({ value: <span><a onClick={() => { this.props.history.push('/cp/company/domains') }}>{i18n.t('campaigns:standard.step3.mustSetupCDNDomain')}</a></span> })
        }

        if (!this.props.accountMaster.accountMaster.approved && this.props.accountMaster.accountMaster.doneNewUserQuestions) {
            errors.push({ value: <span>{i18n.t('campaigns:standard.step3.activateAccount')}</span> })
        }

        if (this.props.accountMaster.accountMaster.paused) {
            errors.push({ value: <span>Account Paused</span> })
        }

        if (campaign.sendUpdateEmails == true) {
            var sendUpdateEmailsFailed = false;
            if (!campaign.sendUpdateEmailsRecipient || !campaign.sendUpdateEmailsRecipient.length) {
                sendUpdateEmailsFailed = true;
            }
            if (campaign.sendUpdateEmailsRecipient.indexOf("||")) {
                var tmpEmails = campaign.sendUpdateEmailsRecipient.split("||")
                tmpEmails.forEach((tEmail, ind) => {
                    if (ind < tmpEmails.length - 1 && EmailTools.validateEmail(tEmail) === false) {
                        sendUpdateEmailsFailed = true;
                    }
                })
            }
            else {
                if (EmailTools.validateEmail(campaign.sendUpdateEmailsRecipient) === false) {
                    sendUpdateEmailsFailed = true;
                }
            }
            if (sendUpdateEmailsFailed) {
                errors.push({ value: <span>{i18n.t('campaigns:standard.step3.pleaseEnterValidDailyUpdateEmail')}</span> })
                errorFields.sendUpdateEmailsRecipient = " ";
            }
        }

        if (this.props.campaign.dynamicRssFound) {
            if (this.props.campaign.options.recurringType && this.props.campaign.options.recurringType == "daily") {
                if (this.props.campaign.options.recurringValue == "1") {
                    if (!this.props.campaign.options.recurringWeekDays || !Array.isArray(this.props.campaign.options.recurringWeekDays) || this.props.campaign.options.recurringWeekDays.length < 1) {
                        errors.push({ value: <span>{i18n.t('campaigns:standard.step3.pleaseSelectAtLeastOneDynamicRssday')}</span> })
                        errorFields.recurringWeekDays = " ";
                    }
                }
            }
        }

        if (moment() > moment(campaign.startDate)) {
            goodStuff.push({ value: <span dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step3.campaignWillSendImmediately') }}></span> })
        }

        this.setState({
            errors, goodStuff, errorFields, warnings
        })

        if (!errors.length) {
            clearTimeout(this.updateTimer);
            this.updateTimer = setTimeout(this.quickSave, 1000)
        }

        return errors
    }

    goValidateDomain() {
        this.props.history.push('/cp/company/domains')
    }
    send() {
        var self = this;
        var errors = this.checkForm()

        this.setState({ triedToSave: true })

        if (!errors.length) {
            this.setState({ sending: true })
            this.props.saveCampaign().then(resp => {
                axios.put('/campaign/' + this.props.campaign.id + '/start')
                    .then(() => {
                        this.props.history.push('/cp/campaigns/add/finished/' + self.props.campaign.id)
                    })
                    .catch(err => {
                        if (err && err.data && err.data.error && err.data.error.tierName) {
                            this.setState({ tierPermissionsErrored: err.data.error, tierErrored: true, sending: false })
                        }
                        else {
                            this.setState({
                                sending: false,
                                errors: [{
                                    value: err.data.error,
                                    click: this.props.siteMaster.hidePricing || this.props.accountMaster.accountMaster.invoice ? () => { } : () => this.props.history.push('/cp/company/subscription')
                                }]
                            })
                        }
                    })
            }).catch(err => {
                this.setState({
                    sending: false,
                    errors: [{ value: err.data.error }]
                })
            })
        }
    }

    quickSave() {
        this.props.saveCampaign()
    }

    save(action) {
        var self = this;
        this.props.saveCampaign()
            .then(() => {
                if (action && action == "spamTest") {
                    const errors = this.checkForm()
                    this.setState({ triedToSave: true })
                    if (!errors.length) {
                        this.startSpamTest();
                    }
                    return;
                }
                self.props.history.push('/cp/campaigns')
            })
    }

    startSpamTest() {
        var drawerContent = <SpamTesterAdvanced campaignId={this.props.campaign.id} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
        return;
    }

    checkTemplateImageExists() {
        if (this.state.templateImageLoaded) return;
        var self = this;
        var img = new Image();
        img.onload = function () { self.setState({ templateImageLoaded: true }) };
        img.onerror = function () {
            self.imageCheckTimer = setTimeout(self.checkTemplateImageExists, 2000);
        }
        img.src = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + this.props.campaign.Template.id + ".jpg";
    }

    addPreview() {
        var previewEmailAddresses = this.state.previewEmailAddresses
        previewEmailAddresses.push({ emailAddress: '', error: '' })
        this.setState({ previewEmailAddresses })
    }

    updatePreview(index, value) {
        var previewEmailAddresses = this.state.previewEmailAddresses
        previewEmailAddresses[index].emailAddress = value;
        this.setState({ previewEmailAddresses })
    }

    changeAdvOptions(event) {
        var advOptions = this.state.advOptions;
        if (event.target.name == "checkedSendBetween") {
            advOptions.showLimitSendingTimes = event.target.checked
            if (event.target.checked == false) {
                //clear start/end times
                this.props.campaign.options.betweenStart = undefined
                this.props.campaign.options.betweenEnd = undefined
                this.props.updateCampaign();
            }
        }
        if (event.target.name == "checkedResendAfter") {
            advOptions.showResendAfter = event.target.checked;
            if (event.target.checked == false) {
                //reset send
                this.props.campaign.resendAfter = 0;
                this.props.updateCampaign();
            }
        }
        this.setState({ advOptions })
    }

    showAppOptions() {
        const content = <CampaignIntegration apps={this.props.apps} campaign={this.props.campaign} saveCampaign={this.props.saveCampaign} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, 'right', content, true, '100vw'))
    }

    automate() {
        const content = <CampaignAutomate CampaignId={this.props.campaign.id} campaignName={this.props.campaign.campaignName} save={this.props.saveCampaign} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, 'right', content, true, 500))
    }


    render() {
        if (!this.state.pageLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>

        var screenshotUrl = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + this.props.campaign.Template.id + ".jpg?now=" + this.state.now
        var sendDisabled = false;

        var submitButtonClass = "button-primary";
        if (this.state.errors.length > 0) {

            sendDisabled = true;
        }


        if (!this.props.accountMaster.accountMaster.approved) {
            sendDisabled = true;
        }

        if (this.state.sending) {
            return <div><div><UILIB.LoadingIcons iconType="2" /></div></div>
        }

        const goodStuff = this.state.goodStuff.slice()
        let handleReplies = this.state.Mailboxes.findIndex(m => m.emailAddress === this.props.campaign.replyAddress) > -1
        if (!handleReplies && this.state.MailboxesC.length > 0) {
            handleReplies = this.state.MailboxesC.findIndex(m => m.emailAddress === this.props.campaign.replyAddress) > -1
        }
        if (handleReplies) {
            goodStuff.push({
                value: <span>Tracking replies for <strong>{this.props.campaign.replyAddress}</strong></span>
            })
        }

        var groupsSendingTo = this.props.campaign.Groups.filter(row => row.include).map(g => { return { id: "g_" + g.id, type: 0, name: g.name } })
        var segsSendingTo = this.props.campaign.Segments.filter(row => row.include).map(g => { return { id: "g_" + g.id, type: 1, name: g.name } })
        var groupsNotSendingTo = this.props.campaign.Groups.filter(row => !row.include).map(g => { return { id: "g_" + g.id, type: 0, name: g.name } })
        var segsNotSendingTo = this.props.campaign.Segments.filter(row => !row.include).map(g => { return { id: "g_" + g.id, type: 1, name: g.name } })


        var groupsSegsSendingTo = groupsSendingTo.concat(segsSendingTo);
        var groupsSegsNotSendingTo = groupsNotSendingTo.concat(segsNotSendingTo);

        let rssTierPermissions = tierPermissions("dynamicContent", this.props.accountMaster)

        let total = this.props.campaign.limit && this.props.campaign.limit > 0 && this.props.subscriberCount > this.props.campaign.limit ? this.props.campaign.limit : this.props.subscriberCount
        if (!this.props.campaign.Groups.length && !this.props.campaign.Segments.length) total = 0
        let startCampaignButtonText = i18n.t('campaigns:standard.step3.sendImmediate', { total: NumberFunctions.formatNumber(total) });
        if (this.props.campaign && this.props.campaign.startDate) {
            if (typeof this.props.campaign.startDate == "object") {
                let minutesSinceNow = this.props.campaign.startDate.diff(moment(), "minutes")
                if (minutesSinceNow > 0) {
                    startCampaignButtonText = i18n.t('campaigns:standard.step3.scheduleCampaign', { total: NumberFunctions.formatNumber(total) });
                }
            }
        }

        let aiEnabled = this.props.siteMaster.siteId == 1;
        if (!aiEnabled) aiEnabled = this.props.siteMaster.aiEnabled;
        const importFromWebDetected = this.props.campaign.options.importFromWebUrl ? true : false;

        let preHeaderAllowed = true;
        let preheaderInfo = i18n.t('campaigns:standard.step3.preheaderTextInfo')

        if (this.props.campaign.Template?.templateType !== 0) {
            // not drag and drop so cant reliably inject preheader text
            preHeaderAllowed = false;
            preheaderInfo = i18n.t('campaigns:standard.step3.preheaderTextDisabled')
        }

        const hasBatchSending = this.props.campaign?.batchDelay > 0 && this.props.campaign?.batchSize > 0 && this.props.campaign?.batchUnit

        let sendingDomain = this.state.sendingDomains.find(sd => sd.defaultPreviewDomain)
        if (!sendingDomain) {
            sendingDomain = this.state.sendingDomains.find(sd => sd.domainName === this.props.user.userData.emailAddress.split('@')[1].toLowerCase())
        }
        const defaultFromAddress = sendingDomain?.originalEmailAddress || this.props.user.userData.emailAddress

        return <div>

            <UILIB.Row style={{ marginBottom: 80 }}>
                <UILIB.Column xs={12} sm={8}>
                    <UILIB.Paper>
                        <h4 className="mar-b25" dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step3.title', { campaignName: textFunctions.checkForHtml(this.props.campaign.campaignName) }) }} />
                        {(this.props.campaign.multiAddressRules == undefined || this.props.campaign.multiAddressRules == null || !this.props.campaign.multiAddressRules || this.props.campaign.multiAddressRules.length <= 0) &&
                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={12} md={4} className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('campaigns:standard.step3.fromName')} inputRef={(input) => { this.fromName = input; }} type="text" name="fromName" onChange={this.updateValue} value={this.props.campaign.fromName} placeholder={i18n.t('campaigns:standard.step3.fromName')} error={this.state.triedToSave && this.state.errorFields.fromName} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} md={4} style={{ position: "relative" }} className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('campaigns:standard.step3.fromAddress')} inputRef={(input) => { this.fromAddress = input; }} name="fromAddress" id="fromAddress" onChange={this.changeFromAddress} onFocus={this.showFromSuggestions} onBlur={ev => this.checkFromEmail(ev.currentTarget.value)} value={this.props.campaign.fromAddress} placeholder={i18n.t('campaigns:standard.step3.fromAddress')} error={this.state.triedToSave && this.state.errorFields.fromAddress} />
                                    <div className={"addCampaign-dropDownResults" + (this.state.showSuggestions && !!this.state.matchingFromAddress.length ? ' addCampaign-dropDownResults-show' : '')}>
                                        {this.state.matchingFromAddress.map((fromAddress, index) => {
                                            return <div className="addCampaign-dropDownResults-dropDownResult text-sml cursor-pointer" key={index} onClick={() => this.selectPreviousFromAddress(fromAddress)}>
                                                <a>{fromAddress}</a>
                                            </div>
                                        })}
                                    </div>
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} md={4} className="mar-b25">

                                    <UILIB.PopOver
                                        style={{ width: "100%" }}
                                        defaultOpen={false}
                                        open={this.state.replyAddressPopoverOpen && this.state.MailboxesC.length > 0}
                                        onOpenChange={(state) => {
                                            if (this.state.MailboxesC.length > 0) {
                                                this.setState({ replyAddressPopoverOpen: state })
                                            }
                                        }}
                                        onOpenAutoFocus={(e) => e.preventDefault()}
                                        side={"bottom"}
                                        trigger={<UILIB.TextInput
                                            label={i18n.t('campaigns:standard.step3.replyAddress')}
                                            inputRef={(input) => { this.replyAddress = input; }}
                                            for={"replyAddress"} iconStyle={{ top: 0 }}
                                            tooltipClassName="fix-font"
                                            iconTooltip={"replies will be tracked"}
                                            type="text"
                                            id="replyAddress"
                                            name="replyAddress"
                                            onChange={this.updateValue}
                                            value={this.props.campaign.replyAddress}
                                            placeholder={i18n.t('campaigns:standard.step3.replyAddress')}
                                            error={this.state.triedToSave && this.state.errorFields.replyAddress}
                                            autocomplete="new-password"
                                            iconRight={
                                                <UILIB.Icons
                                                    icon="avatars3"
                                                    data-for="rules" data-tip="Dynamic Rules"
                                                    onClick={this.openMultiEmailPicker}
                                                />
                                            }
                                        />}
                                    >
                                        <div>
                                            <div className="txtInput_label">Reply Handling</div>
                                            <div className="mar-b10">Capture and Analyze Replies</div>
                                            <div className="txtInput_label mar-b10">Select a <a href="/cp/conversations" target="_blank">Conversations</a> Email Channel</div>
                                            {this.state.MailboxesC.map((mb, mbi) => {
                                                return <div key={"mb" + mbi}>
                                                    <a onClick={() => { this.props.campaign.replyAddress = mb.emailAddress; this.setState({ replyAddressPopoverOpen: false }) }}>{mb.emailAddress}</a>
                                                </div>
                                            })}
                                        </div>
                                    </UILIB.PopOver>


                                </UILIB.Column>
                            </UILIB.Row>
                        }

                        {this.props.campaign.multiAddressRules && this.props.campaign.multiAddressRules.length > 0 && <UILIB.Row>
                            <UILIB.Column xs={8}>
                                <p style={{ marginBottom: "0px" }}>{i18n.t('campaigns:standard.step3.youHaveSetupMultiSenderRules')}.</p>
                                {this.props.campaign.options && this.props.campaign.options.multiAddressRuleName && <p>Rule Set: {this.props.campaign.options.multiAddressRuleName}</p>}
                            </UILIB.Column>
                            <UILIB.Column xs={4} className="text-right">
                                <UILIB.Button
                                    text={i18n.t('campaigns:multiEmailPicker.editRules')}
                                    className="button-sml"
                                    onClick={this.openMultiEmailPicker}
                                    iconRight={<UILIB.Icons icon="pencil" />} />
                            </UILIB.Column>
                        </UILIB.Row>}

                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={12} >

                                <UILIB.TextInput
                                    label={i18n.t('campaigns:standard.step3.subject')}
                                    id="subjectTextField"
                                    inputRef={(input) => { this.subject = input; }}
                                    name="subject"
                                    onChange={this.checkSubject}
                                    value={this.props.campaign.subject}
                                    placeholder={i18n.t('campaigns:standard.step3.subject')}
                                    error={this.state.triedToSave && this.state.errorFields.subject}
                                    mergeTags={true}
                                    includeRssMergeTags={true}
                                    aiContent={aiEnabled}
                                    outerClassName="mar-b25"
                                    type="emojiPicker" />

                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={12} >
                                <UILIB.TextInput
                                    label={i18n.t('campaigns:standard.step3.preheaderText')}
                                    id="preheaderText"
                                    name="preheaderText"
                                    explainer={preheaderInfo}
                                    onChange={this.updateOptions}
                                    disabled={!preHeaderAllowed}
                                    value={this.props.campaign.options?.preheaderText}
                                    mergeTags={true}
                                    includeRssMergeTags={true}
                                    type="emojiPicker" />
                            </UILIB.Column>
                        </UILIB.Row>

                        {(this.props.campaign.multiAddressRules == undefined || this.props.campaign.multiAddressRules == null || !this.props.campaign.multiAddressRules || this.props.campaign.multiAddressRules.length <= 0) && <UILIB.Row>
                            {this.state.filteredTrackingDomains && !!this.state.filteredTrackingDomains.length &&
                                <UILIB.Column xs={12} className="mar-t25">


                                    <UILIB.Select
                                        label={i18n.t('campaigns:standard.step3.tracking')}
                                        value={this.props.campaign.TrackingDomainId}
                                        name="TrackingDomainId"
                                        onChange={this.updateValue}
                                        data={this.state.filteredTrackingDomains.map(domain => {
                                            return {
                                                label: domain.domainName,
                                                value: domain.id
                                            }
                                        })} />
                                </UILIB.Column>

                            }
                            {this.state.filteredCDNDomains && !!this.state.filteredCDNDomains.length &&
                                <UILIB.Column xs={12} className="mar-t25">

                                    <UILIB.Select
                                        label={i18n.t('campaigns:standard.step3.cdn')}
                                        value={this.props.campaign.CdnDomainId}
                                        name="CdnDomainId"
                                        onChange={this.updateValue}
                                        data={this.state.filteredCDNDomains.map(domain => {
                                            return {
                                                label: domain.domainName,
                                                value: domain.id
                                            }
                                        })} />
                                </UILIB.Column>
                            }
                        </UILIB.Row>
                        }
                        <DedicatedServerSelector mailservers={this.state.mailservers} campaign={this.props.campaign} mailserverGroups={this.state.mailserverGroups} updateCampaign={() => this.setState({})} />

                    </UILIB.Paper>

                    <UILIB.Paper>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={6} className="mar-b25">
                                <h4 >{i18n.t('campaigns:standard.step3.scheduleTitle')}</h4>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} className="end-xs mar-b25">
                                <span className="mar-r5">{this.props.user.userData.timeZone}</span>
                                <span data-tip={i18n.t('campaigns:standard.step3.startTimeHelper')}>
                                    <ReactTooltip effect='solid' />
                                    <UILIB.Help title="help" width="12px" />
                                </span>
                            </UILIB.Column>

                            <UILIB.Column xs={12} sm={12} className="mar-b25">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <UILIB.TextInput
                                            label={i18n.t('campaigns:standard.step3.startDate')}
                                            type="datetime"
                                            name="startDate"
                                            onChange={this.setStartDate}
                                            disallowPast={true}
                                            value={this.props.campaign.startDate}
                                            placeholder={i18n.t('campaigns:standard.step3.startDatePlaceholder')}
                                        />
                                    </div>
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6}>

                                <UILIB.CheckBox
                                    checked={this.state.advOptions.showLimitSendingTimes}
                                    name="checkedSendBetween"
                                    onChange={this.changeAdvOptions}
                                >
                                    <span className="txtInput_label">{i18n.t('campaigns:standard.step3.limitSendingTimes')}</span>
                                </UILIB.CheckBox>

                                {this.state.advOptions.showLimitSendingTimes &&
                                    <div className="mar-t15">
                                        <div className="txtInput_label">{i18n.t('campaigns:standard.step3.sendingTimesDescription')}</div>
                                        <div className="quickFlex">
                                            <UILIB.Select disabled={hasBatchSending} outerstyle={{ flexGrow: 1, marginRight: "10px" }} data={this.state.timeblocks} value={this.props.campaign.options.betweenStart} name="betweenStart" onChange={this.updateOptions}></UILIB.Select>
                                            <UILIB.Select disabled={hasBatchSending} outerstyle={{ flexGrow: 1 }} data={this.state.timeblocks} value={this.props.campaign.options.betweenEnd} name="betweenEnd" onChange={this.updateOptions}></UILIB.Select>
                                        </div>
                                        {hasBatchSending && <div className="txtInput_explainer">
                                            Disabled when using batch sending options
                                        </div>}
                                    </div>}
                            </UILIB.Column>

                            {(!this.props.campaign.dynamicRssFound && !importFromWebDetected) && <UILIB.Column xs={12} sm={6} >

                                <UILIB.CheckBox
                                    checked={this.state.advOptions.showResendAfter}
                                    name="checkedResendAfter"
                                    onChange={this.changeAdvOptions}
                                >
                                    <span className="txtInput_label">{i18n.t('campaigns:standard.step3.autoResend')}</span>
                                </UILIB.CheckBox>

                                {this.state.advOptions.showResendAfter &&
                                    <div className="mar-t15">
                                        <div className="txtInput_label">{i18n.t('campaigns:standard.step3.autoResendDesc')}</div>
                                        <div className="quickFlex">
                                            <UILIB.TextInput type="number" min="0" name="resendAfter" onChange={this.updateValue} value={this.props.campaign.resendAfter} placeholder="0" />
                                            <div className="mar-l5">{i18n.t('campaigns:standard.step3.autoResendDays')}</div>
                                        </div>
                                    </div>
                                }
                            </UILIB.Column>}
                        </UILIB.Row>
                    </UILIB.Paper>

                    {(this.props.campaign.dynamicRssFound || importFromWebDetected) && <UILIB.Paper>
                        <UILIB.Row>
                            <UILIB.Column xs={12} className="mar-b25">
                                <h4 className="mar-b10">{i18n.t('campaigns:standard.step3.resendTitle')}</h4>
                                {importFromWebDetected && <div>
                                    <div>{i18n.t('campaigns:standard.step3.resendImportFromWeb')}</div>
                                    <div><strong>{i18n.t('campaigns:standard.step3.resendImportFromWebWarning')}</strong></div>

                                </div>}
                                {this.props.campaign.dynamicRssFound && <div>
                                    <div>{i18n.t('campaigns:standard.step3.resendImportFromRSS')}</div>
                                </div>}
                            </UILIB.Column>


                            {rssTierPermissions && rssTierPermissions.allowed == false && <UILIB.Column xs={12}>
                                <UILIB.WarningHolder className="text-left">
                                    <div className="text-heavy mar-b10">Please Upgrade</div>
                                    <div className="mar-b25">Your current tier does not include dynamic content or repeat content/RSS scheduling. Please upgrade your account</div>
                                    <UILIB.Button text="Upgrade" className="button-primary" onClick={() => { window.open('/cp/company/subscription', '_self') }} />
                                </UILIB.WarningHolder>
                            </UILIB.Column>}

                            {!rssTierPermissions || (rssTierPermissions && rssTierPermissions.allowed == true) && <UILIB.Column
                                xs={12}
                                sm={this.props.campaign.options.recurringType && this.props.campaign.options.recurringType != "none" ? 6 : 12}
                            >
                                <UILIB.Select
                                    label={i18n.t('campaigns:standard.step3.rssSendEvery')}
                                    data={[{ value: "none", label: "Don't Resend" }, { value: "daily", label: "Daily" }, { value: "weekly", label: "Weekly" }, { value: "monthly", label: "Monthly" }]}
                                    value={this.props.campaign.options.recurringType}
                                    name="recurringType"
                                    onChange={this.updateOptions}>
                                </UILIB.Select>

                            </UILIB.Column>}

                            {this.props.campaign.options.recurringType && this.props.campaign.options.recurringType != "none" && <UILIB.Column xs={12} sm={6} >

                                {this.props.campaign.options.recurringType == "daily" && <div>
                                    <UILIB.TextInput
                                        label={i18n.t('campaigns:standard.step3.rssSendDaily')}
                                        type="number"
                                        min="1"
                                        name="recurringValue"
                                        onChange={this.updateOptions}
                                        value={this.props.campaign.options.recurringValue}
                                        placeholder="7" />

                                    {(this.props.campaign.options.recurringValue == 1) &&
                                        <UILIB.Select
                                            outerClassName="mar-t15"
                                            label={i18n.t('campaigns:standard.step3.rssSendDailyWeekDays')}
                                            name="recurringWeekDays"
                                            value={this.props.campaign.options.recurringWeekDays}
                                            data={this.state.weekDays}
                                            onChange={this.updateOptions}
                                            multiple={true}
                                            error={this.state.errorFields.recurringWeekDays}
                                        />
                                    }
                                </div>}

                                {this.props.campaign.options.recurringType == "weekly" && <div>
                                    <UILIB.Select label={i18n.t('campaigns:standard.step3.rssSendWeekly')} data={this.state.weekDays} value={this.props.campaign.options.recurringValue} name="recurringValue" onChange={this.updateOptions}></UILIB.Select>
                                </div>}

                                {this.props.campaign.options.recurringType == "monthly" && <div className="form-group mar-b0">
                                    <UILIB.Select label={i18n.t('campaigns:standard.step3.rssSendMonthly')}
                                        data={[
                                            { value: "1", label: "1st" }, { value: "2", label: "2nd" }, { value: "3", label: "3rd" }, { value: "4", label: "4th" },
                                            { value: "5", label: "5th" }, { value: "6", label: "6th" },
                                            { value: "7", label: "7th" }, { value: "8", label: "8th" }, { value: "9", label: "9th" },
                                            { value: "10", label: "10th" }, { value: "11", label: "11th" }, { value: "12", label: "12th" },
                                            { value: "13", label: "13th" }, { value: "14", label: "14th" }, { value: "15", label: "15th" },
                                            { value: "16", label: "16th" }, { value: "17", label: "17th" }, { value: "18", label: "18th" },
                                            { value: "19", label: "19th" }, { value: "20", label: "20th" }, { value: "21", label: "21st" },
                                            { value: "22", label: "22nd" }, { value: "23", label: "23rd" }, { value: "24", label: "24th" },
                                            { value: "25", label: "25th" }, { value: "26", label: "26th" }, { value: "27", label: "27th" },
                                            { value: "28", label: "28th" }, { value: "99", label: "On the last day of each month" }
                                        ]} value={this.props.campaign.options.recurringValue} name="recurringValue" onChange={this.updateOptions}></UILIB.Select>
                                </div>}

                            </UILIB.Column>}
                        </UILIB.Row>
                    </UILIB.Paper>
                    }

                    <UILIB.Paper>
                        <h4 className="mar-b25" >{i18n.t('campaigns:standard.step3.sendToGroups')}</h4>
                        {groupsSegsSendingTo.map((row, index) => {
                            return <UILIB.SquareChip
                                tooltip={(row.type == 0 ? "Group: " : "Segment: ") + row.name}
                                for={row.name}
                                key={"groupInc_" + index}
                                className={"mar-r10 " + (row.type == 0 ? "square-chip-green" : "square-chip-purple")}
                            >
                                {row.name}
                            </UILIB.SquareChip>

                        })}
                        {(groupsSegsNotSendingTo && groupsSegsNotSendingTo.length > 0) && <div className="mar-t15">
                            <h4 className="mar-b25" >{i18n.t('campaigns:standard.step3.notSendToGroups')}</h4>
                            {groupsSegsNotSendingTo.map((row, index) => {
                                return <UILIB.SquareChip
                                    tooltip={(row.type == 0 ? "Group: " : "Segment: ") + row.name}
                                    for={row.name}
                                    key={"groupExc_" + index}
                                    className={"mar-r10 " + (row.type == 0 ? "square-chip-orange" : "square-chip-red")}
                                >
                                    {row.name}
                                </UILIB.SquareChip>

                            })}
                        </div>}

                    </UILIB.Paper>

                    <UILIB.Paper>
                        <h4 className="mar-b25">{i18n.t('campaigns:standard.step3.template')}</h4>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={6} margin={0}>
                                <UILIB.Paper className="template-themeselector-theme">
                                    <div className="template-themeselected-theme-toolBar" >
                                        <UILIB.Icons icon="magnifier" onClick={this.openEmailPreview} style={{ width: "15px", height: "15px" }} />
                                    </div>
                                    <div className="template-themeselected-theme-image" style={{ backgroundImage: "url(" + screenshotUrl + ")" }} />
                                </UILIB.Paper>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} margin={0}>
                                <div className="txtInput_label">{i18n.t('campaigns:standard.step3.sendTest')}</div>

                                <div className="mar-b10">
                                    {this.state.previewEmailAddresses.map((preview, index) => {
                                        return <div key={index} className="form-group mar-b5">
                                            <UILIB.TextInput type="email" name="previewEmailTo" disabled={!this.props.accountMaster.accountMaster.approved} onChange={event => this.updatePreview(index, event.target.value)} value={preview.emailAddress} placeholder={i18n.t('campaigns:standard.step3.enterEmailAddressHere')} error={preview.error} />
                                        </div>
                                    })}
                                    {this.state.quickPreviewEmailAddressErrorDomainAuth}
                                </div>
                                {this.state.previewEmailAddresses.length < 4 && this.props.accountMaster.accountMaster.approved &&
                                    <a className="quickFlex mar-b25 " onClick={this.addPreview} style={{ alignItems: "center" }}>
                                        <UILIB.Icons icon="plus" className="mar-r5" />
                                        {i18n.t('templates:preview.add')}
                                    </a>
                                }
                                <div className="quickFlex" style={{ alignItems: 'center' }}>
                                    <UILIB.Button className="button-primary"
                                        iconLeft={<UILIB.Icons icon="envelope" />}
                                        text={i18n.t('campaigns:standard.step3.sendButt')}
                                        disabled={this.state.previewEmailAddresses.every(p => !p.emailAddress) || !!this.state.quickPreviewEmailAddressErrorDomainAuth || !this.props.user.userData.registered}
                                        onClick={this.quickPreviewSend} />
                                    {this.props.campaign.fromAddress !== defaultFromAddress && <UILIB.Select outerClassName="mar-l10" outerstyle={{ flexGrow: 1 }} data={[
                                        { label: `Send from ${this.props.campaign.fromAddress}`, value: true },
                                        { label: `Send from ${defaultFromAddress}`, value: false }
                                    ]} value={this.state.useFromAddress} onChange={e => this.setState({ useFromAddress: e.target.value })} />}

                                </div>
                                {!this.props.accountMaster.accountMaster.approved && <div className='mar-t10'>
                                    {this.props.user.userData.registered && <>
                                        {this.props.accountMaster.accountMaster.doneNewUserQuestions && <div className="mar-b20 text-orange text-heavy">While your account is being approved you can try out your first campaign by sending emails to yourself</div>}
                                        {!this.props.accountMaster.accountMaster.doneNewUserQuestions && <div className="mar-b20 text-orange text-heavy">Request approval to start sending full campaigns. You can try out sending yourself a test email while your account is being approved</div>}
                                    </>}
                                    {!this.props.user.userData.registered && <div className="mar-b20 text-red text-heavy">You must confirm your email address before you can send a preview</div>}
                                </div>}
                                {!this.props.accountMaster.accountMaster.doneNewUserQuestions && <div>
                                    <UILIB.Button className="button-orange" onClick={this.activateAccount}>Request Approval</UILIB.Button>
                                </div>}
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>

                    <UILIB.Paper>
                        <SendDailyUpdateEmail campaign={this.props.campaign} updateData={this.props.updateCampaign} checkForm={this.checkForm} />
                    </UILIB.Paper>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={4} margin={0}>

                    {!!goodStuff.length &&
                        <UILIB.Paper>
                            {goodStuff.map((error, index) => {
                                var classes = "addCampaign-checksholder";
                                return <div key={"checkOk_" + index} className={classes}>
                                    <UILIB.Icons icon="tickCircle" />
                                    <div>{error.value}</div>
                                </div>
                            })}
                        </UILIB.Paper>

                    }
                    {this.state.errors.length > 0 && this.state.triedToSave &&
                        <UILIB.Paper>
                            {this.state.errors.map((error, index) => {
                                var classes = "addCampaign-checksholder addCampaign-checksholder-bad";
                                return <div key={"checkErr_" + index} className={classes}>
                                    <UILIB.Icons icon="crossCircle" />
                                    <div>{error.value}</div>
                                </div>
                            })}
                        </UILIB.Paper>
                    }

                    {this.state.warnings.length > 0 &&
                        <UILIB.Paper>
                            {this.state.warnings.map((warning, index) => {
                                var classes = "addCampaign-checksholder addCampaign-checksholder-warn";
                                return <div key={"checkWarn_" + index} className={classes}>
                                    <UILIB.Icons icon="alert" />
                                    <div>{warning.value}</div>
                                </div>
                            })}
                        </UILIB.Paper>
                    }

                    {this.state.tierErrored &&
                        <UILIB.TierLimitHolder type="Dynamic Content / RSS Scheduling" err={this.state.tierPermissionsErrored.err} allowedNumber={this.state.tierPermissionsErrored.allowedNumber} tierName={this.state.tierPermissionsErrored.tierName} />
                    }

                    {this.props.siteMaster.siteId == 1 && this.props.accountMaster.accountMaster.options?.slowDown && this.props.accountMaster.accountMaster.options?.slowDownType === 'auto' && this.props.accountMaster.accountMaster.approved && <UILIB.WarningHolder>
                        We're still getting to know you so sending will be at reduced speeds. <a target="_blank" href="https://kb.transpond.io/article/152-new-account-limits">Learn more</a>
                    </UILIB.WarningHolder>}
                </UILIB.Column>

            </UILIB.Row >

            <UILIB.StickyNavBottom>
                {!this.props.accountMaster.accountMaster.doneNewUserQuestions && <div className="quickFlex" style={{ justifyContent: "space-between" }}>
                    <UILIB.Button onClick={this.save}>{i18n.t('campaigns:standard.step3.save')}</UILIB.Button>
                    <div className='quickFlex'>
                        <div className='text-orange text-heavy mar-r10'>You must have your account approved before you can send a campaign</div>
                        <UILIB.Button className={"button-orange"} onClick={this.activateAccount}>Request Approval</UILIB.Button>
                    </div>
                </div>}
                {this.props.accountMaster.accountMaster.doneNewUserQuestions && !this.props.accountMaster.accountMaster.approved && <div className='end-xs quickFlex'>
                    <div className='text-primary text-heavy mar-r10'>Your account must be approved before you can send a campaign</div>
                    <UILIB.Button onClick={this.save}>{i18n.t('campaigns:standard.step3.save')}</UILIB.Button>
                </div>}
                {this.props.accountMaster.accountMaster.approved &&
                    <div className="quickFlex" style={{ justifyContent: "space-between" }}>
                        <UILIB.Button onClick={this.save}>{i18n.t('campaigns:standard.step3.save')}</UILIB.Button>
                        <div className='quickFlex' style={{ alignItems: 'center' }}>
                            {this.state.triedToSave && sendDisabled && <span className='text-red text-heavy mar-r10'>Fix all errors before sending</span>}
                            {this.props.showApps && this.props.apps.map(app => <UILIB.Button key={app.ApplicationId} iconLeft={<UILIB.Avatar src={app.Application.appLogoUri} style={{ height: 20, width: 20 }} />} className="mar-r10" onClick={this.showAppOptions}>{app.Application.appName} Options</UILIB.Button>)}
                            <UILIB.Button className="mar-r10" iconRight={<UILIB.Icons icon="lightning" />} onClick={this.automate.bind(this)}>Automate</UILIB.Button>
                            <UILIB.Button
                                disabled={sendDisabled && this.state.triedToSave}
                                className="mar-r10"
                                onClick={() => { this.save("spamTest") }}>
                                {i18n.t('campaigns:standard.step3.spamTest')}
                            </UILIB.Button>
                            <UILIB.Button
                                className={submitButtonClass}
                                onClick={this.send}
                                disabled={sendDisabled && this.state.triedToSave}>
                                {startCampaignButtonText}
                            </UILIB.Button>
                        </div>
                    </div>
                }
            </UILIB.StickyNavBottom>

            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={(event => { this.setState({ alertOpen: false, alertMessage: "" }) })} />
            {
                this.state.showVerifyDomain && <UILIB.Drawer close={this.hideVerifyDomain} standalone={true} width={500} showClose={true} clickOutside={true}>
                    <AddDomainPop history={this.props.history} onAddedDomain={this.hideVerifyDomain} theEmail={this.props.campaign.fromAddress} theType={this.state.showVerifyDomainType} domainOrigEmailSentTo={this.state.domainOrigEmailSentTo} domainId={this.state.domainId} />
                </UILIB.Drawer>
            }
            <UILIB.Drawer standalone={true} isOpen={this.state.showAdvanced} width={800} showClose={true} startClose={this.hideAdvanced}>
                <CampaignAdvancedOptions
                    campaign={this.props.campaign}
                    close={this.hideAdvanced}
                    countSubs={this.props.countSubs}
                    isSms={this.props.campaign.type === 6}
                    showGroups={false}
                    updateCampaign={this.props.updateCampaign}
                />
            </UILIB.Drawer>


        </div >

    }
}
import React from 'react'
import axios from '~/data/http/axios';
import classnames from "classnames";
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import * as user from '~/data/actions/userActions'; //now we can use user actions here

@connect((store) => {
    return { user: store.user, browser: store.browser, accountMaster: store.accountMaster, siteMaster: store.siteMaster, permissionStore: store.permission }
})

export default class banner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            createAccount: false,
            companyName: "",
            inputErrors: {
                companyName: ""
            }
        }
        this.createNewAccount = this.createNewAccount.bind(this);
        this.changeAccount = this.changeAccount.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (prevProps.loadedAccounts === false && this.props.loadedAccounts === true) {
        this.setState({ loading: false });
      }
    }

    changeAccount(companyId) {
        this.setState({ loading: true })
        this.props.dispatch(user.changeAccounts(companyId, this.props.history));
        this.props.onChangeAccount();
    }

    async createNewAccount(e) {
        e.preventDefault();
        this.setState({ loading: true });

        var companyName = this.state.companyName;
        var inputErrors = this.state.inputErrors;
        inputErrors.companyName = "";

        if (companyName.length < 3) {
            inputErrors.companyName = i18n.t('nav:addCompany.error');
            this.setState({ inputErrors: inputErrors, loading: false });
            return;
        }

        try {
            var data = { accountName: companyName };
            let newAcc = await axios.post("/accountMaster", data);
            this.changeAccount(newAcc.data.AccountMaster.id);
        }
        catch (err) {
            inputErrors.companyName = err.data.error;
            this.setState({ inputErrors: inputErrors, loading: false })
        }
    }

    handleTransitionEnd() {
      if (this.props.isOpen === false && this.state.createAccount === true) {
        this.setState({ createAccount: false });
      }
    }

    render() {
        var disableRegistration = false;
        if (this.props.siteMaster && this.props.siteMaster.disableRegistration) {
            disableRegistration = true;
            if (this.props.user.userData.isAgencyAdmin) {
                disableRegistration = false;
            }
        }

        const classes = classnames("companySwitcher", this.props.className, {
            open: this.props.isOpen
        });

        const accountClasses = classnames("accounts", {
          open: this.state.createAccount === false
        });

        const createCompanyFormClasses = classnames("create-company-form", {
          open: this.state.createAccount
        });

        return (
            <div className={classes} onTransitionEnd={() => this.handleTransitionEnd()}>
              <div className={accountClasses}>
                <div className="active-account">
                  <div className="company">
                    <UILIB.Avatar src={this.props.activeAccount.companyLogo} />
                    <span className="name">{this.props.activeAccount.accountName}</span>
                    <UILIB.Icons icon="tick" />
                  </div>

                  <hr className="light-hr" />
                </div>

                {this.state.loading ? (
                  <UILIB.LoadingIcons icon="2" />
                ) : (
                  <>
                    {!this.props.accounts.length && (
                      <div className="warning-holder">
                        You don't belong to any other companies in{" "}
                        {this.props.siteMaster.siteName}
                      </div>
                    )}

                    {this.props.accounts.length > 0 && (
                      <ul className="accounts-list fauxList">
                        {this.props.accounts.map((theAccount) => {
                          return (
                            <li key={theAccount.id}>
                              <button
                                className="company"
                                onClick={() => {
                                  this.changeAccount(theAccount.id);
                                }}
                              >
                                <UILIB.Avatar src={theAccount.companyLogo} />
                                <span className="name">{theAccount.name}</span>
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </>
                )}
                
                {disableRegistration == false && (
                  <div className="add-new-company-button-wrapper">
                    <UILIB.Button
                      className="button-fullwidth outline"
                      iconLeft={<UILIB.Icons icon="plus" />}
                      onClick={() => this.setState({ createAccount: true })}
                    >
                      {i18n.t("nav:addNewCompany")}
                    </UILIB.Button>
                  </div>
                )}
              </div>

              <form className={createCompanyFormClasses} onSubmit={this.createNewAccount}>
                {this.state.loading ? (
                  <UILIB.LoadingIcons icon="2" />
                ) : (
                  <>
                    <p className="mar-b25">
                      {i18n.t("nav:addCompany.description")}
                    </p>

                    <UILIB.TextInput
                      outerClassName="mar-b15"
                      label={i18n.t("nav:addCompany.label")}
                      focus={true}
                      type="text"
                      placeholder={i18n.t("nav:addCompany.placeholder")}
                      name="companyName"
                      error={this.state.inputErrors.companyName}
                      value={this.state.companyName}
                      onChange={(ev) => {
                        this.setState({ companyName: ev.target.value });
                      }}
                    />

                    <UILIB.Button
                      type="submit"
                      className="button-primary button-fullwidth"
                      iconRight={<UILIB.Icons icon="arrowRight" />}
                    >
                      Create Company
                    </UILIB.Button>
                  </>
                )}
              </form>
            </div>
          );
    }
};



import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib';
import i18n from '~/i18n'
import { connect } from 'react-redux';
import EmailTools from '~/Classes/emailValidator';
import axios from '~/data/http/axios';
import AddDomainPop from '../../../../../../campaigns/add/steps/step3/addDomainPop'
import CopyExistingTemplate from '~/pages/cp/groups/group/forms/includes/copyExistingTemplate'
import SkipDrawer from './skip'
import TemplateImageLoader from '~/pages/cp/automation/addNew/templateImageLoader.jsx'

@connect((store) => {
    return { accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})
class FormUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: { confirmationName: '', confirmationEmail: '', confirmationSubject: '' },
            showVerifyDomain: false,
            showVerifyDomainType: 'new',
            showCopyForm: false,
            showImportForm: false,
            loaded: true,
            buildingNewTemplate: false,
            skipping: false
        }
        this.updateField = this.updateField.bind(this);
        this.checkForm = this.checkForm.bind(this);
        this.edit = this.edit.bind(this)
        this.save = this.save.bind(this)
        this.checkReplyToEmail = this.checkReplyToEmail.bind(this)
        this.showVerifyDomain = this.showVerifyDomain.bind(this)
        this.hideVerifyDomain = this.hideVerifyDomain.bind(this)
        this.copy = this.copy.bind(this)
        this.import = this.import.bind(this)
        this.copyForm = this.copyForm.bind(this)
        this.skipStep = this.skipStep.bind(this)
        this.confirmSkip = this.confirmSkip.bind(this)
    }

    componentDidMount() {

        this.checkForm();
        if (this.props.form && this.props.form.confirmationEmail && this.props.form.confirmationEmail.length) {
            this.checkReplyToEmail(this.props.form.confirmationEmail);
        }
    }


    updateField(ev, options) {
        var form = this.props.form;
        if (options) {
            form.options[ev.currentTarget.name] = ev.currentTarget.value
        } else {
            form[ev.currentTarget.name] = ev.currentTarget.value
        }
        this.props.update()
        this.checkForm()
    }

    checkForm() {
        var form = this.props.form;
        var errors = { confirmationName: '', confirmationEmail: '', confirmationSubject: '' };

        if (!form.confirmationName || form.confirmationName.length < 3) {
            errors.confirmationName = i18n.t('form:step4.errFromName');
        }

        if (!form.confirmationEmail || EmailTools.validateEmail(form.confirmationEmail) === false) {
            errors.confirmationEmail = i18n.t('form:step4.errAddress');
        } else {
            if (this.state.domainExists === false) {
                errors.confirmationEmail = <span><a onClick={() => { this.showVerifyDomain("new") }}>{i18n.t('form:step4.errDomain')}</a></span>
            }
            else {
                if (this.state.domainVerified === false) {
                    errors.confirmationEmail = <span><a onClick={() => { this.showVerifyDomain("existing") }}>{i18n.t('form:step4.errVerify')}</a></span>
                }
                if (this.props.siteMaster.usersMustBeAuthenticated && !this.state.domainAuthenticated) {
                    errors.confirmationEmail = <span><a onClick={() => { this.props.history.push('/cp/company/domains') }}>{i18n.t('form:step4.errAuthenticate')}</a></span>
                }
            }
        }


        if (!form.confirmationSubject || form.confirmationSubject.length < 4) {
            errors.confirmationSubject = i18n.t('form:step4.errSubject')
        }

        this.setState({
            errors
        })

        return errors
    }

    showVerifyDomain(type) {
        this.setState({
            showVerifyDomainType: type,
            showVerifyDomain: true
        })
    }

    hideVerifyDomain() {
        this.setState({
            showVerifyDomain: false,
            showCopyForm: false,
            showImportForm: false
        })
    }

    checkReplyToEmail(value) {
        var self = this;

        if (value.indexOf('@') > -1 && EmailTools.validateEmail(this.props.form.confirmationEmail)) {
            var domain = value.split('@')[1].toLowerCase()
            return axios.get('/accountMaster/domain/check?email=' + value)
                .then(response => {
                    var isVerified = false;
                    var originalEmailAddress = "";
                    var originalDomainID = 0;
                    var isAuthenticated = false;
                    if (response.data.hasOwnProperty("Domain") === false) {
                        isVerified = true;
                    }
                    else {
                        isVerified = response.data.Domain.verified;
                        originalEmailAddress = response.data.Domain.originalEmailAddress;
                        originalDomainID = response.data.Domain.id;
                        isAuthenticated = response.data.Domain.authenticated;
                    }
                    self.setState({
                        domainExists: response.data.Exists,
                        domainName: '@' + domain,
                        domainVerified: isVerified,
                        domainOrigEmailSentTo: originalEmailAddress,
                        domainId: originalDomainID,
                        domainBlocked: false,
                        domainAuthenticated: isAuthenticated
                    }, self.checkForm)
                })
                .catch(err => {
                    if (err.data.error) {
                        self.setState({
                            domainVerified: false,
                            domainBlocked: true,
                            domainAuthenticated: false
                        }, self.checkForm);
                    } else {
                        self.checkForm;
                    }
                })
        }
    }

    edit() {
        this.props.save().then(() => {
            var redirect = '/cp/groups/' + this.props.groupId + '/forms/create/' + this.props.form.id + '/5'
            if (this.props.form.options.emailRawHtml) {
                this.props.history.push('/cp/templates/add/fromCode/' + this.props.form.formConfirmationTemplateId + '?form=true&groupId=' + this.props.groupId + '&formId=' + this.props.form.id + '&redirect=' + redirect + '&page=confirm');
            } else {
                this.props.history.push('/cp/templates/add/dragdrop?form=false&groupId=' + this.props.groupId + '&formId=' + this.props.form.id + '&redirect=' + redirect + '&page=confirm');
            }
        })
    }

    save() {
        this.checkReplyToEmail(this.props.form.confirmationEmail)
            .then(() => {
                var errors = this.checkForm()
                var hasErrors = false
                for (var prop in errors) {
                    if (errors[prop]) hasErrors = true
                }
                if (hasErrors) {
                    return false
                } else {
                    this.props.form.wizard.step4 = true
                    this.props.update()
                    this.edit()
                }
            })
    }

    copy() {
        this.setState({
            showCopyForm: true
        })
    }

    copyForm(form) {
        this.hideVerifyDomain()
        this.setState({
            buildingNewTemplate: true
        })
        axios.post('/template/copy', {
            id: form.formConfirmationTemplateId
        }).then(response => {
            this.props.form.formConfirmationTemplateId = response.data.Template.id
            this.props.form.wizard.step4 = true
            this.props.form.options.emailRawHtml = (form.options || {}).emailRawHtml
            this.edit()
        })
    }

    import() {
        // wipe the current template and set the type to wysiwyg
        var form = this.props.form
        form.options.emailRawHtml = true
        form.wizard.step4 = true
        this.edit()
        // this.props.save().then(() => {
        //     var redirect = '/cp/groups/' + this.props.groupId + '/forms/create/' + this.props.form.id + '/5'
        //     this.props.history.push('/cp/templates/add/fromCode/' + this.props.form.formConfirmationTemplateId + '?form=true&groupId=' + this.props.groupId + '&formId=' + this.props.form.id + '&redirect=' + redirect + '&page=confirm');
        // })
    }

    skipStep() {
        this.setState({
            skipping: true
        })
    }

    confirmSkip() {
        this.props.form.sendConfirmation = false;
        this.props.update()
        this.props.save().then(() => {
            this.props.history.push('/cp/groups/' + this.props.groupId + '/forms/create/' + this.props.form.id + '/6')
        })
    }


    render() {
        var hasErrors = false
        for (var prop in this.state.errors) {
            if (this.state.errors[prop]) hasErrors = true
        }
        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} md={4} className="mar-b25">
                        <div className="campaign-thumbnail" style={{ width: "100%", height: "100%", minHeight: "150px" }}>
                            <TemplateImageLoader imageUrl={this.props.screenshotUrl} form={this.props.form.formType === 1} />
                        </div>
                    </UILIB.Column>
                    <UILIB.Column xs={12} md={8}>
                        <UILIB.Paper>
                            <h4 className="mar-b25">{this.props.form.options?.serve === 'email' ? 'We send an email for the contact to download the file(s)' : i18n.t('form:step4.header')}</h4>
                            <UILIB.Row>
                                <UILIB.Column xs={12} md={4} className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('form:step4.subject')} value={this.props.form.confirmationSubject} error={this.state.errors.confirmationSubject} placeholder={i18n.t('form:step4.subject')} name="confirmationSubject" onChange={this.updateField} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} md={4} className="mar-b25" >
                                    <UILIB.TextInput label={i18n.t('form:step4.from')} value={this.props.form.confirmationName} error={this.state.errors.confirmationName} placeholder={i18n.t('form:step4.fromPlaceholder')} name="confirmationName" onChange={this.updateField} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={4} className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('form:step4.email')} type="email" value={this.props.form.confirmationEmail} error={this.state.errors.confirmationEmail} placeholder="email@address.com" name="confirmationEmail" onBlur={ev => this.checkReplyToEmail(ev.target.value)} onChange={this.updateField} />
                                </UILIB.Column>
                            </UILIB.Row>
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
                <div className="quickFlex end-xs mar-b15">

                    {/* <UILIB.Button disabled={hasErrors} text={i18n.t('form:step4.fromOtherForm')} onClick={this.copy} className="mar-r10" />
                    <UILIB.Button disabled={hasErrors} text={i18n.t('form:step4.InputRawHTML')} onClick={this.import} className={"mar-r10"} /> */}

                    <UILIB.Button
                        iconRight={<UILIB.Icons icon="arrowRight" />}
                        className="button-primary"
                        disabled={hasErrors}
                        text={i18n.t('form:step4.design')}
                        onClick={this.save} />

                </div>
                {this.props.form.options?.serve !== 'email' && this.props.form.options?.serve !== 'emailLanding' && <div className="end-xs">
                    <a onClick={this.skipStep}>{i18n.t('form:step4.SkipTest')}</a>
                </div>}


                {
                    this.state.showVerifyDomain && <UILIB.Drawer close={this.hideVerifyDomain} isOpen={true} standalone={true} width={300} showClose={true} clickOutside={true}>
                        <AddDomainPop history={this.props.history} onAddedDomain={this.hideVerifyDomain} theEmail={this.props.form.confirmationEmail} theType={this.state.showVerifyDomainType} domainOrigEmailSentTo={this.state.domainOrigEmailSentTo} domainId={this.state.domainId} />
                    </UILIB.Drawer>
                }
                {
                    this.state.showCopyForm && <UILIB.Drawer close={this.hideVerifyDomain} isOpen={true} standalone={true} width={300} showClose={true} clickOutside={true}>
                        <CopyExistingTemplate onSelect={this.copyForm} />
                    </UILIB.Drawer>
                }
                {
                    this.state.showImportForm && <UILIB.Drawer close={this.hideVerifyDomain} isOpen={true} standalone={true} width={300} showClose={true} clickOutside={true}>
                        <ImportForm onSelect={this.importForm} />
                    </UILIB.Drawer>
                }
                {
                    this.state.skipping && <UILIB.Drawer close={() => this.setState({ skipping: false })} isOpen={true} standalone={true} width={300} showClose={true} clickOutside={true}>
                        <SkipDrawer onConfirm={this.confirmSkip} onCancel={() => this.setState({ skipping: false })} />
                    </UILIB.Drawer>
                }
                {
                    this.state.buildingNewTemplate && <UILIB.Row>
                        <UILIB.Column xs={12} className="center-xs text-primary loading">
                            Loading
                        </UILIB.Column>
                    </UILIB.Row>
                }
            </div >
        );
    }
}

export default FormUpdate
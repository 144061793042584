import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';

class ApplicationFilterDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = { groups: [], loaded: false, totalEmails: -1, totalContacts: -1, results: {} }
        this.getGroups = this.getGroups.bind(this)
        this.toggleGroup = this.toggleGroup.bind(this)
        this.getInput = this.getInput.bind(this)
        this.finish = this.finish.bind(this)
        this.updateCheckbox = this.updateCheckbox.bind(this);
        this.respawnGroups = this.respawnGroups.bind(this);
    }

    componentDidMount() {
        this.getGroups()
    }

    getGroups() {
        axios.get(this.props.app.Application.options.importFilter).then(response => {
            this.setState({
                groups: response.data.Groups,
                loaded: true
            })
        })
    }

    toggleGroup(ev, index) {
        var groups = this.state.groups
        if (index !== undefined) {
            groups[index].selected = ev.currentTarget.checked
        }
        else {
            groups.forEach((group, index) => {
                if (index == ev.currentTarget.value) group.selected = true
                else group.selected = false
            })
        }


        this.setState({ groups }, this.respawnGroups)
    }

    respawnGroups() {
        var totalContacts = -1;
        var totalEmails = -1;
        var groups = this.state.groups;
        groups.forEach(group => {
            if (group.selected && group.emailAddresses && group.contacts && totalEmails == -1 && totalContacts == -1) {
                totalEmails = 0;
                totalContacts = 0;
            }
            if (group.selected && group.emailAddresses) {
                totalEmails += Number(group.emailAddresses);
                if (this.state.results && this.state.results.IncludeAdditionals && group.additionalEmailAddresses) {
                    totalEmails += Number(group.additionalEmailAddresses);
                }
            }
            if (group.selected && group.contacts) {
                totalContacts += Number(group.contacts);
                if (this.state.results && this.state.results.IncludeAdditionals && group.additionalContacts) {
                    totalContacts += Number(group.additionalContacts);
                }
            }

        })
        this.setState({ totalContacts, totalEmails })
    }

    finish() {
        return this.props.next(this.state.groups.filter(g => g.selected).map(g => encodeURIComponent(g.id)), this.state.results)
    }

    getInput(query, index) {
        switch (query.type) {
            case 'BOOLEAN':
                return <UILIB.CheckBox checked={this.state.results[query.field]} name={query.field} onChange={this.updateCheckbox}>{query.label}</UILIB.CheckBox>

        }
    }

    updateCheckbox(ev) {
        var results = this.state.results
        results[ev.currentTarget.name] = ev.currentTarget.checked
        this.setState({
            results
        }, this.respawnGroups)
    }

    render() {
        if (!this.state.loaded) return <div className="mar-t30"><UILIB.LoadingIcons iconType="2" /></div>

        return (
            <div>

                <h4 className="mar-b25">Import from {this.props.app.Application.appName}</h4>
                <div className="mar-b25">Select the {this.props.app.Application.appName} group{this.props.app.Application.options.importFilterMultiple ? 's' : ''} to import</div>


                {this.props.app.Application.options.importFilterMultiple && this.state.groups.map((group, index) => {
                    return <div key={index} className="mar-b15">
                        <UILIB.CheckBox checked={group.selected} onChange={ev => this.toggleGroup(ev, index)}><span className="uppercase text-sml pad-left">{group.name}{group.total !== undefined ? ' (' + group.total + ')' : ''}</span></UILIB.CheckBox>
                    </div>
                })}
                {!this.props.app.Application.options.importFilterMultiple && <div className="mar-b15"><UILIB.Select onChange={this.toggleGroup} placeholder="Select a group here…" data={this.state.groups.map((group, index) => {
                    return {
                        label: group.name + (group.total !== undefined ? ' (' + group.total + ')' : ''),
                        value: index
                    }
                })} value={this.state.groups.findIndex(g => g.selected)} /></div>}


                {this.state.groups.filter(g => g.selected).length > 0 && !!this.props.app.Application.options && !!this.props.app.Application.options.additionalFilters && !!this.props.app.Application.options.additionalFilters.query && <div className="mar-b25">
                    {this.props.app.Application.options.additionalFilters.query.map(this.getInput)}
                </div>}


                {this.state.totalContacts > -1 && this.state.totalEmails > -1 && <div className="mar-b25">
                    <UILIB.Row>
                        <UILIB.Column xs={6}>
                            Total Contacts:
                        </UILIB.Column>
                        <UILIB.Column xs={6}>
                            {this.state.totalContacts}

                        </UILIB.Column>
                        <UILIB.Column xs={6}>
                            Total Email Addresses:
                        </UILIB.Column>
                        <UILIB.Column xs={6}>
                            {this.state.totalEmails}
                        </UILIB.Column>
                    </UILIB.Row>

                </div>
                }


                <div className="quickFlex">
                    <UILIB.Button text="Cancel" onClick={this.props.cancel} className="mar-r10" />
                    <UILIB.Button text="Next" onClick={this.finish} disabled={!this.state.groups.filter(g => g.selected).length} className="button-primary" />
                </div>
            </div >
        );
    }
}

export default ApplicationFilterDrawer;
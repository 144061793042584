import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';

export default class Captcha extends Component {
    constructor(props) {
        super(props)
        this.state = {

        };
    }

    componentDidMount() {

    }



    render() {

        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "div", this.props.localVariables)
        }
        var widgetSize = "normal";
        var widgetTheme = "light";

        if (this.props.localVariables && this.props.localVariables.widgetSize && this.props.localVariables.widgetSize.value) widgetSize = this.props.localVariables.widgetSize.value;
        if (this.props.localVariables && this.props.localVariables.widgetTheme && this.props.localVariables.widgetTheme.value) widgetTheme = this.props.localVariables.widgetTheme.value;
        var imgUrl = `https://cdn1.ourmailsender.com/siteContent/assets/templates/elementIcons/captcha_${widgetSize}_${widgetTheme}.png`;
        return <div {...eleProps}>
            <img src={imgUrl} />
        </div >
    }
}
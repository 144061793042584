import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import AutomationFunctions from '../automationFunctions'
import AutoMergeTags from '~/pages/cp/includes/mergeTags/campaignMergeTags';
import HelpPopup from '../helpPopup/helpPopup';

class DrawerWebHookStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: this.props.step,
            errors: {},
            hasWorkflowEmails: false,
            drawerContent: ""
        }

        this.saveStep = this.saveStep.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.openMergeTags = this.openMergeTags.bind(this);
        this.closeMergeTags = this.closeMergeTags.bind(this);
        this.insertMergeTag = this.insertMergeTag.bind(this);
        this.addHeader = this.addHeader.bind(this);
        this.updateHeaderValue = this.updateHeaderValue.bind(this);
        this.deleteHeader = this.deleteHeader.bind(this);
    }

    componentDidMount() {
        var workflowEmails = AutomationFunctions.getWorkflowEmails(this.props.step.guid, this.props.steps);
        var hasWorkflowEmails = false;
        var initialStepTriggerType = this.props.steps[0].triggerType.value;
        if (workflowEmails.length || (initialStepTriggerType == "5" || initialStepTriggerType == "8" || initialStepTriggerType == "9" || initialStepTriggerType == "10" || initialStepTriggerType == "11")) hasWorkflowEmails = true
        this.setState({ hasWorkflowEmails })
    }

    updateValue(event) {
        var step = this.state.step;
        step.stepOptions[event.target.name].value = event.target.value;
        step.complete = false;
        this.setState(step)
    }

    updateHeaderValue(index, event) {
        var step = this.state.step;
        step.stepOptions.headers.value[index][event.target.name] = event.target.value;
        this.setState({ step })
    }

    deleteHeader(hIndex) {
        var step = this.state.step;
        step.stepOptions.headers.value.splice(hIndex, 1);
        this.setState({ step })
    }
    saveStep(createEditTemplate) {
        var errors = {};
        var error = false;
        var step = this.state.step;

        var promises = [];


        if (!step.stepOptions.url.value || step.stepOptions.url.value.length < 3) {
            errors.subject = i18n.t('automation:add.webhook.errUrl');
            error = true;
        }
        else {
            var tmpVal = step.stepOptions.url.value.toLowerCase();
            if (tmpVal.indexOf("https://") < 0 && tmpVal.indexOf("http://") < 0) {
                errors.subject = i18n.t('automation:add.webhook.errUrlHttp');
                error = true;
            }
        }


        Promise.all(promises).then(errs => {

            this.setState({ errors })

            if (!error) {
                step.complete = true;
                step.wizardComplete = true;
                this.props.saveStep();
            }

        });
    }

    openMergeTags(fieldName) {
        var drawerContent = <AutoMergeTags hasWorkflowEmails={false} includeSubscriber={true} includeSubCustomFields={true} close={this.closeMergeTags} insertMergeTag={this.insertMergeTag} fieldName={fieldName} />
        this.setState({ drawerContent })
    }

    closeMergeTags() {
        this.setState({ drawerContent: undefined })
    }

    insertMergeTag(value, fieldName) {
        this.props.step.stepOptions[fieldName].value += " " + value;
        this.setState({ drawerContent: undefined })
    }

    addHeader() {
        var step = this.state.step;
        var stepHeaders = step.stepOptions.headers.value;
        stepHeaders.push({ "key": "", "value": "" })
        this.setState({ step })
    }

    render() {
        const { readOnly } = this.props;

        if (this.state.drawerContent) return this.state.drawerContent;

        const inputProps = {
            disabled: readOnly
        };

        return <div data-test="drawer-webhook-step">
            <div className="automationStepContentHolder">

                <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="mar-b25">{i18n.t('automation:add.webhook.header')}</h4>
                    <HelpPopup type={"webhook"} />
                </div>
                <div className="mar-b25">{i18n.t('automation:add.webhook.subheader')}</div>


                <UILIB.TextInput
                    outerClassName="mar-b25"
                    label={i18n.t('automation:add.webhook.url')}
                    labelOptions={<button className="a text-xsml" onClick={() => this.openMergeTags("url")} {...inputProps}>Merge Tags</button>}
                    name="url"
                    onChange={this.updateValue}
                    value={this.state.step.stepOptions.url.value}
                    placeholder={i18n.t('automation:add.webhook.urlPlace')}
                    error={this.state.errors.subject} type="text"
                    {...inputProps}
                />

                <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.webhook.method')} name="method" onChange={this.updateValue} value={this.state.step.stepOptions.method.value} placeholder={i18n.t('automation:add.webhook.method')} error={this.state.errors.method} data={[{ label: "POST", value: "POST" }, { label: "PUT", value: "PUT" }, { label: "PATCH", value: "PATCH" }, { label: "GET", value: "GET" }, { label: "DELETE", value: "DELETE" }]} {...inputProps} />



                <UILIB.Paper className="mar-b25">
                    <div className="text-heavy mar-b25">{i18n.t('automation:add.webhook.headers')}</div>

                    {this.state.step.stepOptions.headers.value.map((hv, hIndex) => {
                        return <div className="quickFlex" style={{ alignItems: "center" }} key={"hv_" + hIndex}>
                            <div style={{ flex: "1" }} className="mar-r10 mar-b10">
                                <UILIB.TextInput type="text" placeholder="Key" name="key" value={hv.key} onChange={(event) => { this.updateHeaderValue(hIndex, event) }} {...inputProps} />
                            </div>
                            <div style={{ flex: "1" }} className="mar-r10 mar-b10">
                                <UILIB.TextInput type="text" placeholder="Value" name="value" value={hv.value} onChange={(event) => { this.updateHeaderValue(hIndex, event) }} {...inputProps} />
                            </div>
                            <div style={{ flex: "0" }}>
                                <button onClick={() => { this.deleteHeader(hIndex) }} {...inputProps}>
                                    <UILIB.Icons icon="cross" />
                                </button>
                            </div>
                        </div>
                    })}
                    <UILIB.Button text={i18n.t('automation:add.webhook.addHeader')} onClick={this.addHeader} {...inputProps} />
                </UILIB.Paper>

                {
                    this.state.step.stepOptions.method.value != "DELETE" && this.state.step.stepOptions.method.value != "GET" && <>

                        <UILIB.TextArea
                            label={i18n.t('automation:add.webhook.body') + " " + this.state.step.stepOptions.method.value}
                            labelOptions={<button className="a text-xsml" onClick={() => this.openMergeTags("body")} {...inputProps}>Merge Tags</button>}
                            name="body"
                            onChange={this.updateValue}
                            value={this.state.step.stepOptions.body.value}
                            placeholder={i18n.t('automation:add.webhook.bodyPlace') + " " + this.state.step.stepOptions.method.value}
                            error={this.state.errors.body}
                            style={{ height: "250px" }}
                            {...inputProps}
                        />
                    </>
                }

            </div>

            <div className="automationStepSaveButtonHolder">
                {readOnly ? (
                    <>
                        <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                    </>
                ) : (
                    <UILIB.Button className="button-primary" text={i18n.t('save')} onClick={this.saveStep} />
                )}
            </div>
        </div>
    }
}

export default DrawerWebHookStep;
import React, { Component } from 'react'
import { DragSource } from 'react-dnd'
import ItemTypes from '../../../dragDropItemTypes'
import Icons from '../../../../../../../../Common-Objects/UI/Icons'

const dragSource = {
    beginDrag(props) {
        return { path: "", isNew: 0, content: props.content }
    },
    endDrag(props, monitor) {
        if (props.onStartDrag) props.onStartDrag();
        if (monitor.didDrop() === false) {
        }
    }
}

@DragSource(ItemTypes.NEWITEM, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
}))
export default class NewElementObject extends Component {
    constructor(props) {
        super(props)

    }

    render() {

        const { canDrop, isOver, connectDragSource, isDragging } = this.props

        var thisIconName = this.props.title;
        thisIconName = thisIconName.replace(/ /g, '').toLowerCase();
        if (this.props.icon) thisIconName = this.props.icon;
        let icon = <Icons className="newElementObject-icon" icon={thisIconName} color="#A9A9AD" />;

        if (this.props.element && this.props.element.forAppId) {
            icon = <img src={this.props.toolbarIcon} style={{
                width: "100%", height: "100%", maxWidth: "20px", maxHeight: "25px"
            }}
                className="mar-t5 mar-r10" />
        }
        return connectDragSource(

            <div className="newElementObject" id={this.props.id} alt={this.props.title} title={this.props.title} style={this.props.style}>
                {icon}
                {/* <img className="newElementObject-icon" src={"https://cdn1.ourmailsender.com/siteContent/assets/templates/elementIcons/" + thisIconName + ".png?rnd=3"} /> */}
                <div className="newElementObject-text">{this.props.title}</div>
            </div>

        )

    }
}



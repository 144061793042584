import React from 'react'

export default class IconsArrowDown extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.7009 10.2871C14.8883 10.4747 14.9937 10.729 14.9937 10.9941C14.9937 11.2593 14.8883 11.5136 14.7009 11.7011L10.7009 15.7011C10.5133 15.8886 10.259 15.9939 9.99387 15.9939C9.7287 15.9939 9.4744 15.8886 9.28687 15.7011L5.28687 11.7011C5.10471 11.5125 5.00392 11.2599 5.0062 10.9977C5.00847 10.7355 5.11364 10.4847 5.29905 10.2993C5.48446 10.1139 5.73527 10.0087 5.99747 10.0065C6.25966 10.0042 6.51227 10.105 6.70087 10.2871L8.99387 12.5801L8.99387 4.99414C8.99387 4.72892 9.09922 4.47457 9.28676 4.28703C9.4743 4.0995 9.72865 3.99414 9.99387 3.99414C10.2591 3.99414 10.5134 4.0995 10.701 4.28703C10.8885 4.47457 10.9939 4.72892 10.9939 4.99414L10.9939 12.5801L13.2869 10.2871C13.4744 10.0997 13.7287 9.99435 13.9939 9.99435C14.259 9.99435 14.5133 10.0997 14.7009 10.2871Z" fill={color} />
        </svg>

    }
}        


import React from 'react'

export default function IconsPinterestColorRounded({ color = "", style = {} }) {
    let className = "icons-icon";
    if (!style.borderRadius) style.borderRadius = "6px";
    return (
        <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill={color} />
            <g clip-path="url(#clip0_800_12303)">
                <path d="M4 12.0001C4 15.276 5.96988 18.0903 8.78869 19.3276C8.76619 18.7689 8.78469 18.0983 8.92794 17.4905C9.08175 16.8411 9.95731 13.1313 9.95731 13.1313C9.95731 13.1313 9.70175 12.6204 9.70175 11.8655C9.70175 10.6799 10.3889 9.7945 11.2446 9.7945C11.9723 9.7945 12.3239 10.3411 12.3239 10.9956C12.3239 11.7271 11.8573 12.8212 11.6174 13.8347C11.4169 14.6833 12.0429 15.3755 12.8801 15.3755C14.3958 15.3755 15.4167 13.4287 15.4167 11.1221C15.4167 9.36869 14.2358 8.05631 12.0878 8.05631C9.66106 8.05631 8.14925 9.86606 8.14925 11.8876C8.14925 12.5846 8.35475 13.0761 8.67662 13.4567C8.82463 13.6315 8.84519 13.7018 8.79163 13.9026C8.75325 14.0497 8.66512 14.4041 8.62862 14.5446C8.57538 14.7472 8.41119 14.8196 8.22806 14.7448C7.11031 14.2885 6.58975 13.0644 6.58975 11.6884C6.58975 9.41587 8.50637 6.69088 12.3074 6.69088C15.3617 6.69088 17.3721 8.90112 17.3721 11.2737C17.3721 14.412 15.6273 16.7566 13.0554 16.7566C12.1917 16.7566 11.3793 16.2897 11.101 15.7594C11.101 15.7594 10.6366 17.6026 10.5382 17.9586C10.3686 18.5754 10.0366 19.1919 9.733 19.6724C10.4691 19.89 11.2327 20.0005 12.0003 20.0004C16.4181 20.0004 20.0001 16.4186 20.0001 12.0001C20.0001 7.58181 16.4181 4 12.0003 4C7.58213 4 4 7.58181 4 12.0001Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_800_12303">
                    <rect width="16" height="16" fill="white" transform="translate(4 4)" />
                </clipPath>
            </defs>
        </svg>




    )
}
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';
class AddMailbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            mailbox: undefined,
            processing: false,
            error: '',
            connectionType: '',
            tierErrored: false,
            tierPermissions: {}
        }
        this.updateValue = this.updateValue.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidMount() {
        if (this.props.mailbox) {
            this.setState({
                mailbox: this.props.mailbox,
                loading: false
            })
        } else {
            this.setState({
                mailbox: {
                    emailAddress: '',
                    user: '',
                    password: '',
                    host: '',
                    port: 993,
                    tls: true,

                },
                loading: false
            })
        }
    }

    updateValue(ev) {
        var mailbox = this.state.mailbox
        mailbox[ev.currentTarget.name] = ev.currentTarget.type === 'checkbox' ? ev.currentTarget.checked : ev.currentTarget.value
        this.setState({ mailbox })
    }

    save() {
        this.setState({
            processing: true
        })
        var promise;
        if (this.state.mailbox.id) {
            promise = axios.put('/accountMaster/mailbox/' + this.state.mailbox.id, this.state.mailbox)
        } else {
            promise = axios.post('/accountMaster/mailbox', this.state.mailbox)
        }
        promise.then(() => {
            this.setState({
                processing: false
            })
            this.props.finish()
        }).catch(err => {
            console.log(err);
            if (this.state.mailbox.id) {
                this.setState({
                    processing: false,
                    error: err.data.error,
                    tierErrored: false
                })
            }
            else {
                if (err && err.data && err.data.error && err.data.error.tierName) {
                    this.setState({ processing: false, error: "", tierPermissions: err.data.error, tierErrored: true, loading: false })
                } else if (err && err.data && err.data.error && typeof err.data.error == "string") {
                    this.setState({ processing: false, error: err.data.error, tierErrored: false, loading: false })
                }
            }

        })
    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        if (this.state.tierErrored) return <UILIB.TierLimitHolder type="Mailbox" err={this.state.tierPermissions.err} allowedNumber={this.state.tierPermissions.allowedNumber} tierName={this.state.tierPermissions.tierName} />
        return (
            <div>
                <h4 className="mar-b25">{this.state.mailbox.id ? i18n.t('company:mailboxes.update') : i18n.t('company:mailboxes.add')} {i18n.t('company:mailboxes.mailbox')}</h4>
                <div className="mar-b25">{i18n.t('company:mailboxes.imapSettings')}</div>
                <UILIB.TextInput outerClassName="mar-b20" label={i18n.t('company:mailboxes.emailAddress')} value={this.state.mailbox.emailAddress} name="emailAddress" placeholder={i18n.t('company:mailboxes.emailAddress')} type="email" onChange={this.updateValue} />
                <UILIB.TextInput outerClassName="mar-b20" label={i18n.t('company:mailboxes.imapUserName')} value={this.state.mailbox.user} name="user" placeholder={i18n.t('company:mailboxes.imapUserName')} type="text" onChange={this.updateValue} />
                <UILIB.TextInput outerClassName="mar-b20" label={i18n.t('company:mailboxes.imapPassword')} value={this.state.mailbox.password} name="password" placeholder={i18n.t('company:mailboxes.imapPassword')} type="password" onChange={this.updateValue} />
                <UILIB.TextInput outerClassName="mar-b20" label={i18n.t('company:mailboxes.imapHost')} value={this.state.mailbox.host} name="host" placeholder="mail.yourdomain.com" type="text" onChange={this.updateValue} />
                <UILIB.TextInput outerClassName="mar-b20" label={i18n.t('company:mailboxes.imapHostPort')} value={this.state.mailbox.port} name="port" placeholder="993" type="number" onChange={this.updateValue} />
                <UILIB.CheckBox outerClassName="mar-b25" checked={this.state.mailbox.tls} onChange={this.updateValue} name="tls">{i18n.t('company:mailboxes.isTLSEnabled')}</UILIB.CheckBox>
                <div className="quickFlex">
                    <UILIB.Button className="button-primary mar-r10" saving={this.state.processing} text={i18n.t('company:mailboxes.save')} onClick={this.save} />
                    <UILIB.Button text={i18n.t('company:mailboxes.cancel')} disabled={this.state.processing} onClick={this.props.cancel} />
                </div>
                {!!this.state.error && !this.state.processing && <div className="text-red text-heavy mar-t10">{this.state.error}</div>}
            </div>
        );
    }
}

export default AddMailbox;
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import InboxDropdownMenu from "./inboxDropdownMenu";
import InboxStatusDropdownMenu from "./inboxStatusDropdownMenu";
import NewConversationDialog from "../newConversationDialog";
import InboxButton from "../shared/inboxButton";
import Footer from './inboxLeftSideBarFooter'
import * as styles from "./index.module.scss";
import axios from "../../../../../../data/http/axios";
import InfiniteScroll from 'react-infinite-scroller';
import i18n from "~/i18n";

export default function InboxLeftSidebar({
  inboxes,
  activeInbox,
  conversations,
  activeConversation,
  changeConversation,
  changeInbox,
  className,
  conversationsStatusFilter,
  conversationsOrder,
  changeFilters,
  conversationSearchText,
  doingWizard = false,
  createConversation = () => { },
  setAddingInbox = () => { },
  loadingConversations = false,
  userRole = null,
  aiEnabled = false,
  isLoading,
  hasMore,
  changingStatus = false,
  totalConversations = 0,
  currentConversationsPage = 0,
  changeConversationsPage = () => { },
  totalConversationsToShow = 0,
  changingPage = false,
  ...rest
}) {
  const [isSearching, setIsSearching] = useState(false);
  const [newConversationOpen, setNewConversationOpen] = useState(false);
  const [users, setUsers] = useState([])

  const classes = classnames("inbox-left-sidebar", className);
  const activeConversationId = activeConversation ? activeConversation.id : null;


  const creatingConversation = (contactId, emailAddress) => {
    setNewConversationOpen(false);
    createConversation(contactId, emailAddress);
  }



  useEffect(() => {
    axios.get('/accountUser').then(res => setUsers(res.data.AccountUsers))
  }, [])

  const total = activeInbox.stats[conversationsStatusFilter + 'Chats'] || 0
  return (<>
    <section className={classes} {...rest}>
      <header className="inbox-left-sidebar__header">
        {!isSearching ? (
          <>
            <InboxDropdownMenu activeInbox={activeInbox} inboxes={inboxes} setAddingInbox={setAddingInbox} changeInbox={changeInbox} userRole={userRole} />

            <div className="inbox-left-sidebar__header-right">
              <InboxButton
                variant="ghost"
                size="s"
                iconOnly
                aria-label={doingWizard ? i18n.t("chat:inbox.pleaseAddAChannelFirst") : "Search"}
                onClick={() => setIsSearching(true)}
                disabled={doingWizard}
              >
                <UILIB.Icon name="magnifier" />
              </InboxButton>

              <UILIB.Dialog.Root open={newConversationOpen} onOpenChange={setNewConversationOpen}>
                <UILIB.Dialog.Trigger asChild>
                  <InboxButton
                    disabled={doingWizard || userRole > 1}
                    size="s"
                    alt={i18n.t("chat:inbox.leftBar.newMessageAlt")}
                    title={doingWizard ? i18n.t("chat:inbox.pleaseAddAChannelFirst") : i18n.t("chat:inbox.leftBar.newMessageAlt")}
                  >
                    {i18n.t("chat:inbox.leftBar.newMessage")}
                  </InboxButton>
                </UILIB.Dialog.Trigger>
                {newConversationOpen && <NewConversationDialog createConversation={creatingConversation} activeInbox={activeInbox} />}
              </UILIB.Dialog.Root>
            </div>
          </>
        ) : (
          <div className={styles.searchWrapper}>
            <UILIB.TextInput
              autoFocus
              placeholder={i18n.t("chat:inbox.leftBar.search")}
              iconLeft={<UILIB.Icons icon="magnifier" color="#000000" />}
              value={conversationSearchText}
              onChange={(e) => changeFilters(undefined, undefined, e.target.value)}
            />

            <button
              className={styles.closeSearchBtn}
              onClick={() => { setIsSearching(false); changeFilters(undefined, undefined, "") }}
            >
              <UILIB.Icon name="cross-circle" />
            </button>
          </div>
        )}
      </header>

      <div className="inbox-left-sidebar__filters">
        <InboxStatusDropdownMenu
          activeInbox={activeInbox}
          conversationsStatusFilter={conversationsStatusFilter}
          setConversationsStatusFilter={(val) => { changeFilters(val, undefined, undefined) }}
          disabled={doingWizard || changingStatus}
          title={doingWizard ? i18n.t("chat:inbox.pleaseAddAChannelFirst") : ""}
          aiEnabled={aiEnabled}
        />

        <UILIB.SelectNewDefault
          value={conversationsOrder}
          disabled={doingWizard}
          data={[
            { value: "newest", label: i18n.t("chat:inbox.leftBar.newest") },
            { value: "oldest", label: i18n.t("chat:inbox.leftBar.oldest") }
          ]}
          align="end"
          triggerProps={{
            variant: "ghost",
            className: "fw-medium",
          }}
          onValueChange={(val) => changeFilters(undefined, val, undefined)}
          className="inbox-left-sidebar__filters__sort"
          size="s"
          title={doingWizard ? i18n.t("chat:inbox.pleaseAddAChannelFirst") : ""}
        />
      </div>

      <div className="inbox-left-sidebar__scroller">

        <h2 className="visually-hidden">{i18n.t("chat:inbox.leftBar.chats")}</h2>

        {loadingConversations && <UILIB.LoadingIcons />}
        {!loadingConversations && conversations.length === 0 && <>
          <UILIB.Paper className={styles.emptyState}>
            <div className={styles.emptyStateTitle}>{i18n.t("chat:inbox.leftBar.empty")}</div>
            <div className={styles.emptyStateDescription}>{i18n.t("chat:inbox.leftBar.emptySubheader")}</div>
          </UILIB.Paper>
        </>}
        {!loadingConversations && !changingPage && conversations.length > 0 && <ul
          className="inbox-left-sidebar__chats-list faux-list"
        >{conversations.map((conversation) => {
          return (
            <li key={"conv_" + conversation.id}>
              <UILIB.ChatTeaser
                AccountUserId={conversation.AccountUserId}
                AccountUser={users.find(user => user.id === conversation.AccountUserId)}
                active={conversation.id === activeConversationId}
                conversation={conversation}
                onClick={() => { changeConversation(conversation.id) }}
              />
            </li>
          );
        })}
        </ul>}
      </div>
      {(totalConversations > totalConversationsToShow) && <div className="inbox-left-sidebar__paging">
        <UILIB.Paging totalItems={totalConversations} currentPage={currentConversationsPage} pageSize={totalConversationsToShow} pagesToShow={10} loading={changingPage} changePage={changeConversationsPage} />
      </div>}
      <Footer inboxes={inboxes} userRole={userRole} activeInbox={activeInbox} />
    </section>
  </>
  );
}
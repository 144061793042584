import React from 'react'

export default function IconCheckbox({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.27273 3H15.7273C16.4305 3 17 3.56955 17 4.27273V15.7273C17 16.4305 16.4305 17 15.7273 17H4.27273C3.56955 17 3 16.4305 3 15.7273V4.27273C3 3.56955 3.56955 3 4.27273 3ZM13.707 8.70698C13.8892 8.51838 13.99 8.26578 13.9877 8.00358C13.9854 7.74138 13.8802 7.49057 13.6948 7.30516C13.5094 7.11975 13.2586 7.01458 12.9964 7.01231C12.7342 7.01003 12.4816 7.11082 12.293 7.29298L9.00001 10.586L7.70701 9.29298C7.51841 9.11082 7.26581 9.01003 7.00361 9.01231C6.74141 9.01458 6.4906 9.11975 6.30519 9.30516C6.11978 9.49057 6.01461 9.74138 6.01234 10.0036C6.01006 10.2658 6.11085 10.5184 6.29301 10.707L8.29301 12.707C8.48054 12.8945 8.73485 12.9998 9.00001 12.9998C9.26517 12.9998 9.51948 12.8945 9.70701 12.707L13.707 8.70698Z" fill={fillColor} />
        </svg>
    )
}
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import SiteVars from '~/Classes/siteVars';
import copy from 'copy-to-clipboard';
import ReactTooltip from 'react-tooltip'
import i18n from '~/i18n'

import FoldersView from './folders'
import TilesView from './tiles';
import DetailsView from './details';

import Unsplash from './unsplash';

import DeleteFile from './utils/deleteFile';
import RenameFile from './utils/renameFile';
import MoveFile from './utils/moveFile';

import { launchAviary } from '~/Classes/aviaryEditor'


class FileManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            folders: [],
            files: [],
            searchText: '',
            limit: 10,
            page: 0,
            currentFolder: undefined,
            currentFile: undefined,
            currentView: 'tile',
            currentStep: 0,
            renaming: false,
            deleting: false,
            moving: false,
            alertMessage: '',
            alertOpen: false,
            fileButtonText: i18n.t('templates:fileManager.uploadImage'),
            hasMoreItems: false,
            aviaryFile: "",
            tick: Date.now(),
            fileTypes: "all",
            searching: true,
            loadingFolders: true,
            addingFolder: false,
            fileExists: false,
            fileExistsFiles: undefined,
            fileExistsFilesList: undefined
        }

        this.getFolders = this.getFolders.bind(this);
        this.getFiles = this.getFiles.bind(this);
        this.updateSearchText = this.updateSearchText.bind(this);
        this.changeStep = this.changeStep.bind(this);
        this.folderSelected = this.folderSelected.bind(this);
        this.fileSelected = this.fileSelected.bind(this);

        this.selectFile = this.selectFile.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.moveFile = this.moveFile.bind(this);
        this.renameFile = this.renameFile.bind(this);
        this.stopAll = this.stopAll.bind(this);

        this.confirmDelete = this.confirmDelete.bind(this);
        this.confirmRename = this.confirmRename.bind(this);
        this.confirmMove = this.confirmMove.bind(this);
        this.addFolder = this.addFolder.bind(this);

        this.clickFile = this.clickFile.bind(this);
        this.fileAddedToInput = this.fileAddedToInput.bind(this);
        this.uploadNewFileProgress = this.uploadNewFileProgress.bind(this);

        this.setFolder = this.setFolder.bind(this);

        this.copyToClipboard = this.copyToClipboard.bind(this);

        this.aviaryEdit = this.aviaryEdit.bind(this);
        this.aviarySave = this.aviarySave.bind(this);
    }

    componentDidMount() {

        var fileTypes = "all";
        if (this.props.fileTypes) fileTypes = this.props.fileTypes;

        var fileButtonText = (fileTypes == "images" ? i18n.t('templates:fileManager.uploadImage') : i18n.t('templates:fileManager.uploadFile'))
        this.setState({ fileTypes: fileTypes, fileButtonText }, this.getFolders);
    }

    aviaryEdit(id, fileObject) {
        launchAviary(fileObject, this.state.currentFolder.folderID, this.aviarySave);
    }
    aviarySave() {
        this.setState({ tick: Date.now() }, () => { this.getFiles(true) });
    }
    getFolders(refresh, id) {
        axios.get('/fileManager')
            .then(response => {
                if (!refresh) refresh = !this.state.currentFolder;
                var folder
                var folders = response.data.folders;
                if (this.props.system !== false) {
                    folders.unshift({
                        folderID: "system",
                        folderName: "System",
                        rootFolder: 0
                    })
                }
                if (id) {
                    folder = folders.find(f => f.folderID === id)
                }


                this.setState({
                    folders: folders,
                    loadingFolders: false,
                    currentFolder: folder || this.state.currentFolder || folders[this.props.system === false ? 0 : 1]
                }, () => {
                    if (refresh) this.getFiles(true)
                })
            })
    }

    getFiles(reset) {
        this.setState({
            searching: true,
            hasMoreItems: false
        })

        let searchText = encodeURIComponent(this.state.searchText);

        var queryStr = `?limit=${this.state.limit}&offset=${reset ? 0 : this.state.files.length}&searchText=${searchText}&fileType=${this.state.fileTypes}`
        axios.get('/fileManager/' + this.state.currentFolder.folderID + '/file' + queryStr)
            .then(response => {
                var files = reset ? response.data.Files : this.state.files;

                if (!reset) {
                    files = files.concat(response.data.Files);
                }
                if (reset) {

                    if (this.state.currentFolder.folderID && this.state.currentFolder.folderID == "system") {
                        files.unshift({
                            dateAdded: "2020-06-26T08:54:36.000Z",
                            fileExtension: "SYS",
                            fileId: -99999,
                            fileName: "{{__COMPANYLOGO}}",
                            filePath: "",
                            friendlyName: "Company Logo",
                        })
                    }
                }



                this.setState({
                    currentFile: undefined,
                    files,
                    uploading: false,
                    searching: false,
                    countTotal: response.data.countTotal,
                    hasMoreItems: !!(response.data.countTotal > files.length)
                })
            })
    }

    updateSearchText(ev) {
        this.setState({
            searchText: ev.currentTarget.value
        }, () => {
            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(() => this.getFiles(true), 250);
        })
    }

    changeStep(id) {
        this.setState({
            currentStep: id
        })
    }

    folderSelected(folder) {
        this.setState({
            currentFolder: folder
        }, () => this.getFiles(true))
    }

    fileSelected(file) {
        this.setState({
            currentFile: file
        })
    }

    selectFile(file, folderId) {
        file = file || this.state.currentFile;
        if (this.props.onFileClick && typeof this.props.onFileClick === 'function') {
            var finalUrl = SiteVars.repoEndPoint + file.filePath + file.fileName;
            if (file.fileExtension == "SYS") {
                finalUrl = file.fileName;
            }

            this.props.onFileClick({ url: finalUrl, name: file.friendlyName, size: file.fileSize, imageHeight: file.imageHeight, type: file.fileExtension, imageWidth: file.imageWidth, id: file.fileId })
        } else {

            this.setState({
                currentStep: 0
            }, () => {
                this.getFolders(true, folderId)
            })
        }
    }

    renameFile(file) {
        this.setState({
            renaming: true,
            currentFile: file || this.state.currentFile
        })
    }

    deleteFile(file) {
        this.setState({
            deleting: true,
            currentFile: file || this.state.currentFile
        })
    }

    moveFile(file) {
        this.setState({
            moving: true,
            currentFile: file || this.state.currentFile
        })
    }

    stopAll() {
        this.setState({
            renaming: false,
            deleting: false,
            moving: false,
            addingFolder: false
        })
    }

    confirmDelete() {
        this.setState({
            alertMessage: i18n.t('templates:fileManager.deleting'),
            alertOpen: true
        })

        var currentFile = this.state.currentFile;
        if (!Array.isArray(currentFile)) {
            currentFile = [currentFile]
        }

        var promises = [];
        currentFile.forEach(thisFile => {
            promises.push(axios.delete('/fileManager/' + this.state.currentFolder.folderID + '/file/' + thisFile.fileId));
        })

        Promise.all(promises)
            .then(() => {
                this.setState({
                    alertMessage: i18n.t('templates:fileManager.deleted'),
                    alertOpen: true,
                    deleting: false
                }, () => this.getFiles(true))
            })
            .catch(err => {
                this.setState({
                    alertMessage: i18n.t('templates:fileManager.errorDeleting'),
                    alertOpen: true
                })
                console.log(err)
            })


    }
    confirmRename(name) {
        this.setState({
            alertMessage: i18n.t('templates:fileManager.updating'),
            alertOpen: true
        })
        axios.put('/fileManager/' + this.state.currentFolder.folderID + '/file/' + this.state.currentFile.fileId,
            {
                friendlyName: name
            })
            .then(() => {
                var file = this.state.currentFile;
                file.friendlyName = name;
                this.setState({
                    currentFile: file,
                    alertMessage: i18n.t('templates:fileManager.updated'),
                    alertOpen: true,
                    renaming: false
                })
            })
            .catch(err => {
                this.setState({
                    alertMessage: i18n.t('templates:fileManager.errorUpdating'),
                    alertOpen: true
                })
                console.log(err)
            })
    }

    confirmMove(folderId) {
        this.setState({
            alertMessage: i18n.t('templates:fileManager.moving'),
            alertOpen: true
        })
        axios.put('/fileManager/' + this.state.currentFolder.folderID + '/file/' + this.state.currentFile.fileId,
            {
                FolderId: folderId
            })
            .then(() => {
                var file = this.state.currentFile;
                file.friendlyName = name;
                this.setState({
                    currentFile: file,
                    alertMessage: i18n.t('templates:fileManager.moved'),
                    alertOpen: true,
                    moving: false
                }, () => this.getFiles(true))
            })
            .catch(err => {
                this.setState({
                    alertMessage: i18n.t('templates:fileManager.errorMoving'),
                    alertOpen: true
                })
                console.log(err)
            })
    }

    addFolder(folderName) {
        this.setState({
            alertMessage: i18n.t('templates:fileManager.creating'),
            alertOpen: true
        })

        axios.post('/fileManager',
            {
                folderName: folderName
            })
            .then(response => {
                this.setState({
                    currentFolder: response.data.Folder,
                    alertMessage: i18n.t('templates:fileManager.created'),
                    alertOpen: true
                }, () => this.getFolders(true))
            })
            .catch(err => {
                this.setState({
                    alertMessage: i18n.t('templates:fileManager.errorCreating'),
                    alertOpen: true
                })
                console.log(err)
            })
    }

    clickFile() {
        this.refs.fileUploadInput.click();
    }

    fileAddedToInput(e, overwrite) {

        let files;

        if (e.preventDefault) {
            e.preventDefault();
            files = e.target.files;
        }
        else {
            files = e;
        }

        if (!files || !files.length) return
        this.setState({ uploading: true })

        var promises = [];
        var fileCnt = 0;
        if (!overwrite) {
            for (var ourFile in files) {
                //make sure filename doesnt already exist
                if (!files[fileCnt]) continue;
                var fileName = files[fileCnt].name;
                var fileNameSrch = fileName;
                if (fileName.length > 100) {
                    return this.setState({ alertMessage: 'Filename too long', alertOpen: true })
                }
                if (fileNameSrch) {
                    var fileDat = fileNameSrch.split('.')
                    fileNameSrch = fileNameSrch.replace('.' + fileDat[fileDat.length - 1], '')
                    fileNameSrch = encodeURIComponent(fileNameSrch)
                }
                var queryStr = `?limit=1&offset=0&searchText=${fileNameSrch}`;
                promises.push(axios.get('/fileManager/' + this.state.currentFolder.folderID + '/file' + queryStr).then(dat => {
                    if (dat.data.countTotal && dat.data.countTotal > 0) {
                        return dat.data.Files[0].fileName
                    }
                    return undefined;
                }))
                fileCnt++;
            }
        }

        Promise.all(promises).then(foundFiles => {

            if (foundFiles && foundFiles.length) {
                var foundExisting = [];
                foundFiles.forEach(f => {
                    if (f && f != undefined) foundExisting.push(f);
                })
                if (foundExisting.length) {
                    this.setState({ fileExists: true, fileExistsFiles: files, fileExistsFilesList: foundExisting, uploading: false })
                    return;
                }
            }

            this.setState({
                fileExists: false,
                fileExistsFile: undefined,
                fileExistsFilesList: undefined,
                uploading: true,
                fileButtonText: i18n.t('templates:fileManager.uploading')
            })

            var theCnt = 0;
            for (var theFile in files) {
                console.log(theFile)
                if (!files[theCnt]) {
                    continue;
                }

                var data = new FormData();
                data.append('file', files[theCnt]);
                data.append('friendlyName', files[theCnt].name);

                theCnt++;
                axios.post('/fileManager/' + this.state.currentFolder.folderID + '/file', data, {
                    onUploadProgress: progressEvent => this.uploadNewFileProgress(progressEvent)
                })
                    .then(() => {
                        var fileButtonText = (this.state.fileTypes == "images" ? i18n.t('templates:fileManager.uploadImage') : i18n.t('templates:fileManager.uploadFile'))
                        this.setState({ newFileUploadProgress: 0, fileButtonText }, () => this.getFiles(true));
                        this.refs.fileUploadInput.value = null
                    }).catch(err => {

                        var errOut = i18n.t('templates:fileManager.errorUploadingFile');
                        if (err && err.data && err.data.error) errOut = err.data.error;

                        this.setState({
                            uploading: false,
                            alertMessage: errOut,
                            alertOpen: true,
                            fileButtonText: i18n.t('templates:fileManager.uploadImage'),
                            newFileUploadProgress: 0
                        })
                        console.log(err);
                    });
            }
        })
    }

    uploadNewFileProgress(uploadEvent) {
        var loaded = uploadEvent.loaded;
        var total = uploadEvent.total;
        var totalLoaded = Math.round((loaded / total) * 100);

        this.setState({ newFileUploadProgress: totalLoaded });

    }

    setFolder(ev) {
        this.setState({
            currentFolder: this.state.folders.find(f => f.folderID == ev.currentTarget.value)
        }, () => this.getFiles(true))
    }

    copyToClipboard(code) {
        this.setState({
            alertOpen: true,
            alertMessage: i18n.t('templates:fileManager.copy')
        })
        copy(code)
    }

    render() {
        if (this.state.loadingFolders) return <UILIB.LoadingIcons iconType="2" />
        if (this.state.fileExists) return <div className="file-manager">
            <h4 className="mar-b25">File(s) Already Exists</h4>
            <p><strong>The following file(s) you are attempting to upload already exist in this folder.</strong></p>
            <p className="mar-b20">You can choose to overwrite the existing file(s), or you can choose to forget it, rename the files and re-upload.</p>
            <div className="mar-b30">
                {this.state.fileExistsFilesList.map(f => {
                    return <div className="text-md mar-b5"><span className="icon-warning mar-r10 text-red" />{f}</div>
                })}</div>
            <UILIB.Button text="Overwrite Existing File(s)" className="button-md mar-b10 mar-r10 primary" onClick={() => {
                this.fileAddedToInput(this.state.fileExistsFiles, true);
            }} />
            <UILIB.Button text="Forget it" className="button-md mar-b10 secondary" onClick={() => { this.setState({ fileExists: false, fileExistsFiles: undefined, fileExistsFilesList: undefined }) }} />

        </div>

        return (
            <div className="file-manager" style={{ display: "flex", flexDirection: "column" }}>
                <div className="mar-b25">
                    <UILIB.ButtonSimple
                        className="button-simple-fullsize"
                        selected={this.state.currentStep == 0}
                        onClick={() => { this.changeStep(0) }}
                    >
                        {i18n.t('templates:fileManager.myImage')}
                    </UILIB.ButtonSimple>

                    <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-l5 hide-xs"
                        selected={this.state.currentStep == 1}
                        onClick={() => { this.changeStep(1) }}
                    >
                        {i18n.t('templates:fileManager.unsplash.navTitle')}
                    </UILIB.ButtonSimple>
                </div>

                {this.state.currentStep == 1 && <Unsplash onSelect={this.selectFile} />}
                {this.state.currentStep == 0 && <UILIB.Row style={{ height: "calc(100% - 160px)" }}>

                    <FoldersView loading={this.state.loadingFolders} folders={this.state.folders} currentFolder={this.state.currentFolder} folderSelected={this.folderSelected} add={this.addFolder} getFolders={this.getFolders} />

                    <UILIB.Column hide={['md', 'lg']} xs={12} sm={12} margin={0}>
                        <div className="mar-b25">
                            <UILIB.Select
                                label={i18n.t('templates:folder.yourFolders')}
                                data={this.state.folders.map((folder) => {
                                    return {
                                        label: folder.folderName,
                                        value: folder.folderID
                                    }
                                })} value={this.state.currentFolder ? this.state.currentFolder.id : undefined} onChange={this.setFolder}></UILIB.Select>
                        </div>

                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={12} md={8} lg={9} margin={0}>
                        <div className="filemanager-rightheader">
                            <UILIB.TextInput
                                outerStyle={{ flexGrow: 1 }}
                                name="searchText"
                                className="noShadow"
                                onChange={this.updateSearchText}
                                value={this.state.searchText}
                                style={{ paddingRight: "35px" }}
                                placeholder={i18n.t('templates:fileManager.searchHere')}
                                iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}

                            />

                            <div style={{ display: "flex" }} className="mar-l20">
                                <UILIB.ButtonSimple
                                    className="button-simple-fullsize"
                                    selected={this.state.currentView == 'tile'}
                                    onClick={() => { this.setState({ currentView: 'tile' }) }}
                                    title={i18n.t('templates:fileManager.tile')}
                                    alt={i18n.t('templates:fileManager.tile')}
                                    iconLeft={<UILIB.Icons icon="grid" />}
                                />
                                <UILIB.ButtonSimple
                                    className="button-simple-fullsize mar-l5"
                                    selected={this.state.currentView == 'detail'}
                                    onClick={() => { this.setState({ currentView: 'detail' }) }}
                                    title={i18n.t('templates:fileManager.list')}
                                    alt={i18n.t('templates:fileManager.list')}
                                    iconLeft={<UILIB.Icons icon="list" />}
                                />
                                {this.state.currentStep == 0 && this.state.currentFolder.folderID != "system" && <div className="mar-l20">
                                    <input type="file" ref="fileUploadInput" style={{ display: 'none' }} onChange={this.fileAddedToInput} multiple />
                                    <UILIB.Button
                                        className="button-primary"
                                        iconLeft={<UILIB.Icons icon="upload" />}
                                        text={this.state.fileButtonText}
                                        onClick={this.clickFile} />
                                </div>}
                            </div>
                        </div>

                        {this.state.uploading && <UILIB.LoadingLine className="mar-b20 mar-t20" />}
                        {this.state.currentView == 'tile' && <TilesView onAdd={this.fileAddedToInput} searching={this.state.searching} hasMore={this.state.hasMoreItems} tick={this.state.tick} loadMore={this.getFiles} files={this.state.files} currentFile={this.state.currentFile} copy={this.copyToClipboard} fileSelected={this.fileSelected} select={this.selectFile} rename={this.renameFile} delete={this.deleteFile} move={this.moveFile} aviaryEdit={this.aviaryEdit} />}
                        {this.state.currentView == 'detail' && <DetailsView searching={this.state.searching} hasMore={this.state.hasMoreItems} files={this.state.files} loadMore={this.getFiles} tick={this.state.tick} currentFile={this.state.currentFile} copy={this.copyToClipboard} fileSelected={this.fileSelected} select={this.selectFile} rename={this.renameFile} delete={this.deleteFile} move={this.moveFile} aviaryEdit={this.aviaryEdit} />}
                    </UILIB.Column >
                </UILIB.Row >}

                {this.state.renaming && <UILIB.Drawer standalone={true} isOpen={true} showClose={true} close={this.stopAll}><RenameFile file={this.state.currentFile} save={this.confirmRename} /></UILIB.Drawer>}
                {this.state.deleting && <UILIB.Drawer width={500} standalone={true} isOpen={true} showClose={true} close={this.stopAll}><DeleteFile deleteFile={this.confirmDelete} file={this.state.currentFile} /></UILIB.Drawer>}
                {this.state.moving && <UILIB.Drawer standalone={true} isOpen={true} showClose={true} close={this.stopAll}><MoveFile folders={this.state.folders} currentFolder={this.state.currentFolder} move={this.confirmMove} /></UILIB.Drawer>}

                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />


            </div >
        );
    }
}

export default FileManager;
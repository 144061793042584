import React from 'react'
import Warning from '~/assets/images/icons/help-purple.svg';

export default class WarningHolder extends React.Component {
    render() {
        var rndNo = Math.floor((Math.random() * 999999) + 1);
        return <p className="warningHolderWarning" key={"err-" + rndNo}>
            <span><img src={Warning} alt="Warning" width="11px" /></span>
            {this.props.children}
        </p>
    }
}

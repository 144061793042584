import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Settings from './settings'
import Analytics from './analytics'
import Template from './template'
import List from './transactions'
import Transaction from '~/pages/cp/includes/transactions/viewTransactionDrawer'

import PermissionRouter from '~/Common-Objects/Router/permissionRoute'

class TransactionsIndex extends Component {
    render() {
        return (
            <Template history={this.props.history}>
                <Switch>
                    <PermissionRouter permissionName="campaigns" method="read" exact path="/cp/transactions" component={Analytics} />
                    <PermissionRouter permissionName="campaigns" method="read" exact path="/cp/transactions/list" component={List} />
                    <PermissionRouter permissionName="campaigns" method="read" exact path="/cp/transactions/settings" component={Settings} />
                    <PermissionRouter permissionName="campaigns" method="read" exact path="/cp/transactions/list/:id" component={Transaction} />
                    <Redirect to="/cp/home" push />
                </Switch>
            </Template>
        );
    }
}

export default TransactionsIndex;
import React from 'react'

export default function IconLink({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.1426 7.53126C11.3184 7.35569 11.5566 7.25708 11.8051 7.25708C12.0535 7.25708 12.2918 7.35569 12.4676 7.53126C12.8739 7.93752 13.1962 8.41983 13.4161 8.95066C13.636 9.48149 13.7491 10.0504 13.7491 10.625C13.7491 11.1996 13.636 11.7685 13.4161 12.2993C13.1962 12.8302 12.8739 13.3125 12.4676 13.7188L9.96757 16.2188C9.20101 16.9843 8.17766 17.4382 7.09564 17.4926C6.01362 17.547 4.94991 17.198 4.11043 16.5132C3.27096 15.8284 2.71544 14.8564 2.55142 13.7855C2.3874 12.7146 2.62655 11.621 3.22257 10.7163C3.36216 10.5148 3.5751 10.376 3.81577 10.3296C4.05644 10.2832 4.30571 10.333 4.51017 10.4681C4.71463 10.6033 4.85803 10.8131 4.90965 11.0528C4.96126 11.2924 4.91699 11.5426 4.78632 11.75C4.44542 12.2672 4.30865 12.8925 4.40251 13.5047C4.49637 14.117 4.81418 14.6726 5.29435 15.0639C5.77452 15.4552 6.38285 15.6543 7.00145 15.6226C7.62005 15.591 8.20487 15.3308 8.64257 14.8925L11.1426 12.3925C11.6112 11.9237 11.8745 11.2879 11.8745 10.625C11.8745 9.96209 11.6112 9.32632 11.1426 8.85751C10.967 8.68172 10.8684 8.44344 10.8684 8.19501C10.8684 7.94657 10.967 7.70704 11.1426 7.53126Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.85754 12.4688C8.68176 12.6443 8.44348 12.7429 8.19504 12.7429C7.9466 12.7429 7.70832 12.6443 7.53254 12.4688C7.12624 12.0625 6.80394 11.5802 6.58405 11.0494C6.36415 10.5185 6.25098 9.94959 6.25098 9.37502C6.25098 8.80044 6.36415 8.2315 6.58405 7.70067C6.80394 7.16984 7.12624 6.68753 7.53254 6.28127L10.0325 3.78126C10.7991 3.0157 11.8225 2.56178 12.9045 2.50738C13.9865 2.45299 15.0502 2.802 15.8897 3.48683C16.7292 4.17167 17.2847 5.14362 17.4487 6.21451C17.6127 7.28541 17.3736 8.37907 16.7775 9.28377C16.638 9.48523 16.425 9.62402 16.1843 9.6704C15.9437 9.71678 15.6944 9.66706 15.4899 9.5319C15.2855 9.39673 15.1421 9.18687 15.0905 8.94727C15.0388 8.70767 15.0831 8.45738 15.2138 8.25001C15.5547 7.73285 15.6915 7.10755 15.5976 6.49529C15.5037 5.88303 15.1859 5.32742 14.7058 4.93612C14.2256 4.54483 13.6173 4.34572 12.9987 4.37739C12.3801 4.40905 11.7952 4.66924 11.3575 5.10751L8.85754 7.60751C8.38886 8.07633 8.12558 8.7121 8.12558 9.37502C8.12558 10.0379 8.38886 10.6737 8.85754 11.1425C9.0331 11.3183 9.13172 11.5566 9.13172 11.805C9.13172 12.0535 9.0331 12.2917 8.85754 12.4675V12.4688Z" fill={fillColor} />
        </svg>

    )
}
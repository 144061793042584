import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';


@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, site: store.siteMaster }
})


export default class DomainCard extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {

        var theDomain = this.props.domain;
        var roleClass = "cards-domain-red";
        var buttClass = "grey";

        var domainStatus = i18n.t('company:domains.statusVerification');
        var verified = theDomain.verified;
        var authenticated = theDomain.authenticated;
        var isFreeAccount = (this.props.accountMaster.accountMaster.accountType === 'free' && this.props.site.siteId === 1) ? true : false;

        if (verified) {
            roleClass = "cards-domain-secondary";
            domainStatus = i18n.t('company:domains.verified');
        }
        if (authenticated) {
            roleClass = "cards-domain-primary";
            domainStatus = i18n.t('company:domains.statusVerifiedAuthed');
        }

        let invalidSettings = false;
        if (!authenticated) invalidSettings = true;
        if (this.props.cardType === "tracking" || this.props.cardType === "cdn") {
            if (!this.props.domain.sslDone && !isFreeAccount) {
                invalidSettings = true;
            }
        }
        return <UILIB.Paper className={"cards-domain " + roleClass}>

            {(invalidSettings && this.props.accountMaster.id === 18500) && <div title="Domain has one or more issues" alt="Domain has one or more issues" style={{
                position: "absolute",
                backgroundColor: "#990000",
                borderRadius: "20px",
                width: "15px",
                height: "15px",
                fontSize: "10px",
                lineHeight: "15px",
                color: "white"
            }}>!!</div>}
            {this.props.writeAccess && <UILIB.Icons icon="bin" color="#A9A9AD" className="cards-domain-delete" onClick={() => { this.props.deleteDomain(theDomain.id) }} />}
            <div className="cards-domain-name">{theDomain.domainName}</div>

            <div className="cards-domain-status">
                {(this.props.cardType === "domain" && !verified) && <div className="cards-domain-status-line">
                    <UILIB.Icons icon="cross" className="cards-domain-status-line-icon-notok" />
                    {i18n.t('company:domains.verified')}:
                    {i18n.t('company:domains.messageNotVerified1')}
                    {theDomain.originalEmailAddress}
                    {i18n.t('company:domains.messageNotVerified2')}
                </div>}
                {(this.props.cardType === "domain" && verified) && <div className="cards-domain-status-line">
                    <UILIB.Icons icon="tick" />
                    {i18n.t('company:domains.verified')}:
                    {i18n.t('company:domains.messageVerified')}
                </div>}
                {!authenticated && this.props.cardType === "domain" && <div className="cards-domain-status-line">
                    <UILIB.Icons icon="cross" className="cards-domain-status-line-icon-notok" />
                    {i18n.t('company:domains.authenticated')}: {i18n.t('company:domains.messageNotAuthed')}
                </div>}
                {!authenticated && this.props.cardType === "cdn" && <div className="cards-domain-status-line">
                    <UILIB.Icons icon="cross" className="cards-domain-status-line-icon-notok" />
                    <span dangerouslySetInnerHTML={{ __html: i18n.t('company:domains.card.cdnNotAuthed') }}></span>
                </div>}
                {!authenticated && this.props.cardType === "tracking" && <div className="cards-domain-status-line">
                    <UILIB.Icons icon="cross" className="cards-domain-status-line-icon-notok" />

                    <span dangerouslySetInnerHTML={{
                        __html: i18n.t('company:domains.authenticated') + ": " + i18n.t('company:domains.card.trackingNotAuthed', {
                            siteTrackingDomain: this.props.site.trackingDomain
                        })
                    }}>
                    </span>
                </div>}
                {authenticated && <div className="cards-domain-status-line">
                    <UILIB.Icons icon="tick" />
                    {i18n.t('company:domains.authenticated')}: {i18n.t('company:domains.messageAuthed')}
                </div>}

                {authenticated && (this.props.cardType === "tracking" || this.props.cardType === "cdn") && this.props.domain.sslDone && <div className="cards-domain-status-line">
                    <UILIB.Icons icon="tick" />
                    SSL Generated: {this.props.cardType === "cdn" ? "Images " : "Links "} in emails secured!
                </div>}
                {authenticated && (this.props.cardType === "tracking" || this.props.cardType === "cdn") && !this.props.domain.sslDone && isFreeAccount && <div className="cards-domain-status-line">
                    <UILIB.Icons icon="tick" />
                    SSL: Not generated. Please <a onClick={() => this.props.history.push('/cp/company/subscription')}>upgrade your account</a>
                    to a paid plan.
                </div>}
                {authenticated && (this.props.cardType === "tracking" || this.props.cardType === "cdn") && !this.props.domain.sslDone && !isFreeAccount && <div className="cards-domain-status-line">
                    <UILIB.Icons icon="cross" className="cards-domain-status-line-icon-notok" />
                    {i18n.t('company:domains.card.' + (this.props.cardType === "tracking" ? 'trackingNoSSL' : 'cdnNoSSL'))}
                    {this.props.domain.domainErr && this.props.domain.domainErr.length && <div className="mar-t10">The error was: "{this.props.domain.domainErr}"</div>}
                </div>}
                {(this.props.site.siteId != 2 && theDomain && theDomain.blacklists && theDomain.blacklists.length > 0) && <div className="cards-domain-status-line">
                    <UILIB.Icons icon="cross" className="cards-domain-status-line-icon-notok" />
                    Blacklisted: Appears on  <a className="text-red" style={{ fontWeight: "bold", textDecoration: "underline" }} onClick={() => this.props.showBlacklists(theDomain)}>{theDomain.blacklists.length} blacklist(s)</a>.
                </div>}


            </div>

            <div className="cards-domain-buttons">
                {(this.props.cardType == "domain") && <UILIB.Button show={!verified} className={"button-primary"} style={{ textAlign: "center", marginRight: "5px" }} onClick={() => this.props.resendAuthEmail(theDomain)}>{i18n.t('company:domains.resend')}</UILIB.Button>}
                <UILIB.Button show={(verified || verified === undefined) && !authenticated} className={"button-primary"} style={{ textAlign: "center" }} onClick={() => this.props.authDomain(theDomain)}>{i18n.t('company:domains.auth')}</UILIB.Button>


                {(this.props.cardType === "domain" && authenticated && !this.props.domain.defaultPreviewDomain) &&
                    <UILIB.Icons data-tip="Make Default Domain" data-for={'domain_' + theDomain.id} icon="envelope" className="mar-r10" onClick={() => this.props.makeDefaultPreviewDomain(theDomain)} alt={i18n.t('company:domains.makeDefaultPreviewDomain')} title={i18n.t('company:domains.makeDefaultPreviewDomain')} />
                }
                {(this.props.cardType === "domain" && authenticated && this.props.domain.defaultPreviewDomain) &&
                    <UILIB.Icons data-tip="Default Domain" data-for={'domain_' + theDomain.id} icon="envelope" className="mar-r10 cards-domain-buttons-icon-active" alt={i18n.t('company:domains.defaultPreviewDomain')} title={i18n.t('company:domains.defaultPreviewDomain')} />
                }
                {(this.props.showSetupMxRecord == true && this.props.cardType === "domain" && authenticated == true && this.props.domain.mxRecordAdded == false) &&
                    <UILIB.Icons data-tip="MX Records" data-for={'domain_' + theDomain.id} icon="cog" className="mar-r10" onClick={() => this.props.setupMxRecord(theDomain)} alt={i18n.t('company:domains.setupMxRecord')} title={i18n.t('company:domains.setupMxRecord')} />
                }
                {(this.props.showSetupMxRecord == true && this.props.cardType === "domain" && authenticated == true && this.props.domain.mxRecordAdded == true) &&
                    <UILIB.Icons data-tip="MX Records" data-for={'domain_' + theDomain.id} icon="cog" className="mar-r10 cards-domain-buttons-icon-active" alt={i18n.t('company:domains.setupMxRecord')} title={i18n.t('company:domains.setupMxRecord')} />
                }

                {(this.props.cardType === "domain" && authenticated && this.props.domain.defaultPreviewDomain) &&
                    <UILIB.Icons data-tip="Change Email" data-for={'domain_email' + theDomain.id} icon="envelopeOpen" className="mar-r10" onClick={() => this.props.changeEmailAddress(theDomain)} alt={i18n.t('company:domains.defaultPreviewDomain')} title={i18n.t('company:domains.defaultPreviewDomain')} />
                }
            </div>

            <ReactTooltip id={'domain_' + theDomain.id} effect="solid" />
        </UILIB.Paper >
    }
}


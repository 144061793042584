import React from 'react'
import axios from 'axios'
import DBConn from '~/Classes/siteVars.js'
import { connect } from 'react-redux';
import queryString from 'query-string';
import UILIB from '~/Common-Objects/Lib'
@connect((store) => {
    return {
        user: store.user, siteMaster: store.siteMaster
    }
})

export default class VerifyDomain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenError: ""
        }
    }

    componentDidMount() {
        const ourToken = this.props.match.params.token;

        const query = queryString.parse(this.props.location.search);
        var accountMasterId = query.am;

        var baseUrl = DBConn.getConnString()
        axios.put(baseUrl + '/verifyDomain/' + ourToken + '?am=' + accountMasterId).then(res => {
            if (res.data.Success) {
                this.props.history.push('/cp/company/domains');
            }
            else {
                this.setState({ tokenError: "This token was invalid or has expired" })
            }
        }).catch(err => {
            this.setState({ tokenInvalid: true })
        })
    }

    render() {
        return <div className="quickFlex" style={{ alignItems: "center", justifyContent: "center", height: "100vh" }}>
            <UILIB.Paper className="with_header">
                <div className="paper_header" style={{ backgroundColor: this.props.siteMaster.colours["$primary"], color: 'white' }}>
                    <div className="quickFlex" style={{ alignItems: "center" }}>
                        <UILIB.Icons icon="alert" color={"#FFFFFF"} style={{ width: "20px", height: "20px" }} />
                        <div className='mar-l10 text-heavy' style={{ fontSize: 16 }}>Something went wrong</div>
                    </div>
                </div>
                <div className="paper_content">
                    {this.state.tokenError}
                    <UILIB.Button text="Continue" onClick={() => { this.props.history.push('/') }} />
                </div>
            </UILIB.Paper>
        </div>

    };
};
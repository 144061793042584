import React from 'react'
import axios from '~/data/http/axios';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import { Controlled as CodeMirror } from 'react-codemirror2'
import MergeTags from '~/pages/cp/includes/mergeTags/mergeTags'
import FileManager from '~/pages/cp/includes/fileManager/fileManager'
import i18n from '~/i18n'
import EmailPreviewer from '~/pages/cp/includes/emailPreviewer/emailPreviewer'
import ReactTooltip from 'react-tooltip'
import TextFunctions from '../../../../../Classes/textFunctions';
import helpers from '~/Classes/helpers'
import queryString from 'query-string';
import CheckLinksButton from '../checkLinksButton';
import AiContentGenerator from '../../../includes/ai/contentGenerator';

require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript')
require('codemirror/lib/codemirror.css')
require('codemirror/mode/markdown/markdown');

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})


export default class FromCode extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            codeMirrorCode: "",
            templateHTML: "",
            templateID: 0,
            templateName: "",
            isSaving: "",
            popups: {
                exiting: false
            },
            alertMessage: '',
            alertOpen: false,
            CampaignId: undefined,
            iframeSrc: '',
            pageLoaded: false,
            rightWidth: document.body.clientWidth / 2,
            leftWidth: document.body.clientWidth / 2,
            isDragging: false,
            fromSource: 'campaign',
            canBeConvertedToDD: false,
            templateNameErr: false
        };

        let timer;
        var codeMirrorLocal = "";
        this.linksExitPage = this.linksExitPage.bind(this);
        this.codeMirrorUpdateCode = this.codeMirrorUpdateCode.bind(this);
        this.createNewTemplate = this.createNewTemplate.bind(this);
        this.saveTemplate = this.saveTemplate.bind(this);
        this.loadTemplate = this.loadTemplate.bind(this);
        this.onMergeTagClick = this.onMergeTagClick.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.updateTextBox = this.updateTextBox.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.openEmailPreview = this.openEmailPreview.bind(this);
        this.openDrawer = this.openDrawer.bind(this);
        this.reselectTemplate = this.reselectTemplate.bind(this);

        this.dragStart = this.dragStart.bind(this);
        this.dragEnd = this.dragEnd.bind(this);
        this.dragging = this.dragging.bind(this);

        this.convertToDD = this.convertToDD.bind(this);
        this.updateTemplateName = this.updateTemplateName.bind(this);
        this.checkLinks = this.checkLinks.bind(this);
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        if (query.form) {
            this.setState({
                fromForm: true
            })
        }

        if (query.redirect) {
            var fromSource = "campaign";
            if (query.redirect.indexOf('/automation/')) {
                fromSource = "automation"
            }
            this.setState({
                redirect: query.redirect,
                fromSource: fromSource
            })
        }
        var thisTemplateID = this.props.match.params.templateID;
        if (!thisTemplateID) {
            this.createNewTemplate();
        }
        else {
            helpers.createKey(0, 0, 0, 0).then(key => {
                var thisIframeSrc = "/preview?key=" + key + "&tid=" + thisTemplateID;
                this.setState({ templateID: thisTemplateID, iframeSrc: thisIframeSrc }, this.loadTemplate)
            })

        }

        document.addEventListener("mousedown", this.dragStart, false);
        document.addEventListener("mouseup", this.dragEnd, false);
        document.addEventListener("mousemove", this.dragging, false);
    }
    componentWillUnmount() {

        document.removeEventListener("mousedown", this.dragStart, false);
        document.removeEventListener("mouseup", this.dragEnd, false);
        document.removeEventListener("mousemove", this.dragging, false);
    }

    dragStart(event) {
        if (event.target.id == "moveBar") {
            this.setState({ isDragging: true });
        }
    }
    dragEnd() {
        this.setState({ isDragging: false });
    }
    dragging(event) {
        if (this.state.isDragging) {
            event.preventDefault();
            var currX = document.body.clientWidth - event.clientX;
            if (currX < 350) currX = 350;
            var currY = document.body.clientWidth - currX;

            this.setState({ rightWidth: currX, leftWidth: currY });
        }
    }
    async createNewTemplate() {
        const query = queryString.parse(this.props.location.search);
        var from = query.from;

        var templateHtml = '<div></div>';
        if (from) {
            //from theme
            const theme = await axios.get('/theme/' + from)
            templateHtml = theme.data.Theme.templateHTML
        }
        const res = await axios.post('/template', {
            "templateName": "Unnamed Template",
            "templateType": 1,
            "templateHTML": templateHtml
        })
        var key = await helpers.createKey(0, 0, 0, 0)
        var thisIframeSrc = "/preview?key=" + key + "&tid=" + res.data.Template.templateID;
        this.setState({ templateID: res.data.Template.templateID, iframeSrc: thisIframeSrc }, this.refreshPage)

    }

    refreshPage() {
        window.open('/cp/templates/add/fromCode/' + this.state.templateID, '_self');
    }

    linksExitPage(doPrompt) {
        if (!this.state.templateName || this.state.templateName.length < 3) {
            this.setState({
                alertMessage: i18n.t('templates:add.drag.longer'),
                alertOpen: true,
                templateNameErr: true
            });
        }
        else {
            this.saveTemplate(1)
        }
    }

    updateTextBox(event) {
        var theName = event.currentTarget.name;
        var theVal = event.currentTarget.value;
        this.setState({ [theName]: theVal })
    }

    updateTemplateName(ele) {
        this.setState({ templateName: ele.currentTarget.value });
    }

    reselectTemplate() {
        axios.put('/campaign/' + this.state.CampaignId,
            {
                campaign: {
                    TemplateId: null
                }
            })
            .then(() => {
                this.props.history.push('/cp/campaigns/add/standard/' + this.state.CampaignId + '/2')
            })
    }

    saveTemplate(doExitPage) {

        if (this.state.fromForm) {
            if (this.state.templateHTML.indexOf('href="{{{__link}}}"') === -1) {
                return this.setState({
                    alertOpen: true,
                    alertMessage: 'A link with the {{{__link}}} merge tag as the url is required'
                })
            }
            // check the __link mergetag exists
        }

        var self = this;
        self.setState({
            alertMessage: i18n.t('templates:add.wysi.alertSaving'), alertOpen: true
        });

        var saveProps = {};
        if (self.state.templateName) {
            saveProps.templateName = self.state.templateName;
        }
        saveProps.templateType = 1;
        saveProps.templateHTML = self.state.templateHTML;

        return axios.post('/template/mergeTagsTest', { bodyHtml: self.state.templateHTML }).then(templateErrs => {
            if (!templateErrs.data.success) {
                return Promise.reject({ friendlyError: true, errorText: i18n.t('templates:add.wysi.mergeTagProblem') + templateErrs.data.error });
            }
            return axios.put('/template/' + self.state.templateID,
                saveProps
            )
        }).then((res) => {
            var canBeConvertedToDD = false;
            if (res.data.canBeConvertedToDD) {
                canBeConvertedToDD = true;
            }
            self.setState({
                alertMessage: i18n.t('templates:add.wysi.alertSaved'), alertOpen: true, canBeConvertedToDD
            });


            switch (doExitPage) {
                case 0:

                    document.getElementById('theIframe').contentWindow.location.reload();
                    break;
                case 1:
                    if (self.state.redirect) {
                        return self.props.history.push(self.state.redirect)
                    }
                    if (self.state.CampaignId) {
                        self
                            .props
                            .history
                            .push('/cp/campaigns/add/standard/' + self.state.CampaignId + '/3');
                    } else {
                        self
                            .props
                            .history
                            .push('/cp/templates/');
                    }
                    break;
                case 2:
                    self.openEmailPreview();
                    break
                case 3:
                    var url = "/cp/templates/add/wysiwyg/" + this.state.templateID;
                    if (this.state.redirect && this.state.redirect.length) {
                        url += "?redirect=" + this.state.redirect
                    }
                    this.props.history.push(url)
                    break
            }

        }).catch((error) => {

            var errMessage = i18n.t('templates:add.wysi.alertError')
            if (error.friendlyError) {
                errMessage = error.errorText
            }
            self.setState({
                alertMessage: errMessage, alertOpen: true
            });

        })
    }

    loadTemplate() {
        axios
            .get('/template/' + this.state.templateID
            )
            .then((res) => {

                var theHTML = res.data.template.templateHTML;
                var templateName = res.data.template.templateName;
                this.codeMirrorLocal = theHTML;
                this.setState({ codeMirrorCode: theHTML, templateHTML: theHTML, templateName: templateName, CampaignId: res.data.template.CampaignId, pageLoaded: true });

            }).catch(function (error) {
            })
    }
    codeMirrorUpdateCode(value) {

        // var sanOptions = { allowedTags: SanitizeHTML.defaults.allowedTags.concat(['html', 'body', 'head', 'meta', 'title', 'img', '!doctype', 'doctype', 'style']), allowedAttributes: false }
        // var sanitizedHTML = SanitizeHTML(value, sanOptions);
        if (value) {
            value = TextFunctions.removeScriptCapableTags(value);
        }
        clearTimeout(this.timer);
        this.timer = setTimeout(() => { this.saveTemplate(0) }, 2000);
        this.codeMirrorLocal = value;
        this.setState({
            codeMirrorCode: value,
            templateHTML: value
        });
    }


    onMergeTagClick(tag) {


        var doc = this.codeMirrorInst.editor.getDoc();
        var cursor = doc.getCursor();
        doc.replaceRange(tag, cursor);

        this.closeDrawer("mergeTags");
    }

    openDrawer(ourDrawer) {

        if (ourDrawer === "fileManager") {
            var theDrawer = <FileManager onFileClick={(res) => { this.onMergeTagClick(res.url) }} fileTypes={"all"} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '100vw', 'fix-height'));
        }
        if (ourDrawer === "mergeTags") {
            var theDrawer = <MergeTags onTagClick={this.onMergeTagClick} form={this.state.fromForm} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, "400px"));
        }
        if (ourDrawer === "aiContentGenerator") {

            var theDrawer = <AiContentGenerator contentType="paragraph" insertText={this.onMergeTagClick} close={() => {
                this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
            }} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, false, "right", theDrawer, true, "700px", "drawer_dark"));
        }
    }
    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true, null));
    }


    closePopup(eleName) {
        var popups = this.state.popups;
        popups[eleName] = false;
        this.setState({ popups: popups })
    }

    openEmailPreview() {
        var drawerContent = <EmailPreviewer templateId={this.state.templateID} history={this.props.history} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
    }

    convertToDD() {
        var self = this;
        return axios.put('/template/convertToDragDrop/' + self.state.templateID).then(() => {
            this.props.history.push('/cp/templates/add/dragDrop/' + self.state.templateID)
        }).catch(err => {
            var errMessage = i18n.t('templates:add.wysi.alertError')
            if (err && err.data && err.data.error) {
                errMessage = err.data.error
            }
            self.setState({
                alertMessage: errMessage, alertOpen: true
            });

        })
    }

    checkLinks() {
        return this.saveTemplate(0);
    }

    render() {
        var codeMirrorOpts = {
            mode: 'markdown',
            lineNumbers: true
        };

        if (!this.state.pageLoaded) return <div style={{ marginTop: '50px' }}>
            <UILIB.LoadingIcons iconType="2" className="mar-b30" />
        </div>

        var templateNameCSS = {};
        var templateNameClass = ''
        if (this.state.templateNameErr === true) {
            templateNameCSS.borderBottom = "2px solid red";
        }

        let aiEnabled = this.props.siteMaster.siteId == 1;
        if (!aiEnabled) aiEnabled = this.props.siteMaster.aiEnabled;

        return <div className="fullScreenHolder">


            <div className="dragDropToolBar" id="dragDropToolBar" style={{ flex: "0" }}>
                <div className="templateTitle">
                    {!this.state.CampaignId && <UILIB.TextInput style={templateNameCSS} className={"txtInput editor " + templateNameClass} value={this.state.templateName} onChange={this.updateTemplateName} placeholder={this.state.form ? i18n.t('templates:index.enterAFormName') : i18n.t('templates:index.enterATemplateName')} />}
                </div>
                <div className="actions">
                    {!this.state.fromForm && <div className="actions-holder">
                        <UILIB.Button
                            onClick={() => { this.saveTemplate(3) }}
                            text={i18n.t('templates:add.wysi.editInWysiwygEditor')}
                        />
                    </div>}
                    {(this.state.CampaignId && this.state.fromSource == 'campaign') && <div className="actions-holder">
                        <UILIB.Button
                            onClick={() => { this.reselectTemplate() }}
                            text={i18n.t('templates:add.wysi.changeTemplate')}
                        />
                    </div>}

                    <div className="actions-holder">
                        <CheckLinksButton templateId={this.state.templateID} onClick={this.checkLinks} />
                    </div>

                    <div className="actions-holder">
                        <UILIB.Button
                            onClick={() => { this.saveTemplate(2) }}
                            text={i18n.t('templates:index.preview')}
                            iconLeft={<UILIB.Icons icon="eye" style={{ height: "18px", width: "18px" }} />}
                        />
                    </div>
                    <div className="actions-holder">
                        <UILIB.Button
                            className="button-primary"
                            onClick={() => { this.state.fromForm ? this.saveTemplate(1) : this.linksExitPage(this.state.CampaignId ? 0 : 1) }}
                            text={i18n.t('templates:add.drag.saveContinue')}
                            iconRight={<UILIB.Icons icon="arrowRight" />}
                        />
                    </div>

                </div>
            </div>
            {
                this.state.canBeConvertedToDD && <div style={{ flex: "0" }} className="nav-banner paper-orange text-center">
                    <span className="text-sml text-md mar-r10">Can be converted to Drag and Drop</span>
                    <UILIB.Button text="Convert" onClick={this.convertToDD} />
                </div>
            }
            <div style={{ display: "flex", flex: "1" }}>
                <div style={{ flex: "1", maxWidth: this.state.leftWidth + "px" }}>
                    {this.state.templateHTML && this.state.templateHTML.length && <iframe width="100%" height="99%" style={{ border: "0" }} src={this.state.iframeSrc} id="theIframe"></iframe>}
                </div>


                <div style={{ flex: "1", display: "flex", position: "relative", flexDirection: "column", maxWidth: this.state.rightWidth + "px" }} >
                    <div style={{ width: "5px", height: "100%", backgroundColor: "#999999", cursor: "move", position: "absolute", left: "-5px", top: "0px" }} id="moveBar"></div>
                    <div className="editorTabs" style={{ flex: "0" }}>
                        <div className="editorTab selected">{i18n.t('templates:add.code.copypaste')}</div>
                    </div>
                    <div style={{ flex: "1 1 0", overflow: "hidden" }}>
                        <CodeMirror ref={(element) => { this.codeMirrorInst = element; }} autoCursor={true} autoFocus={true} options={codeMirrorOpts} value={this.codeMirrorLocal} onBeforeChange={(editor, data, value) => { this.codeMirrorUpdateCode(value) }} />
                    </div>
                    <div style={{ display: "flex", flex: "0", padding: "20px", justifyContent: "space-between", alignItems: "center" }} >
                        <div>
                            <UILIB.Button onClick={() => { this.openDrawer("mergeTags") }} className="mar-r10">{i18n.t('templates:mergetags.header')}</UILIB.Button>
                            <UILIB.Button onClick={() => { this.openDrawer("fileManager") }}>{i18n.t('templates:fileManager.header')}</UILIB.Button>
                        </div>
                        {(aiEnabled == true) &&
                            <div
                                className="tinyMce_bottomTools_aiAssistant border-darkmode-purple-to-blue-horizontal"
                                onClick={() => { this.openDrawer("aiContentGenerator") }}
                            >
                                <UILIB.Icons className="tinyMce_bottomTools_aiAssistant_icon" icon="sparkle2" color="#FFFFFF" style={{ width: "20px", height: "20px" }} />
                                <div className="tinyMce_bottomTools_aiAssistant_label">{i18n.t('templates:aiContentAssistant.aiContentAssistant')}</div>
                            </div>
                        }
                    </div>


                </div>


            </div>
            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
        </div >


    };
}
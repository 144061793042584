import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";
import moment from "moment";
import axios from "~/data/http/axios";
import DateTimeFunctions from "../../../../../../../Classes/dateTimeFunctions";
import { getUserSettings, setUserSettings } from "../../../../../../../Classes/userSettings";
import { alterSiteDrawer, alterSnackbar } from "../../../../../../../data/actions/siteActions";
import SegmentEditor from "../../../../../includes/segmentEditor/segmentEditor";
import Button from "../../../../../../../Common-Objects/UI/Button";
import TierPermissions from "../../../../../../../Classes/tierPermissions";
import ReactTooltip from 'react-tooltip'
import i18n from "~/i18n";
import ChatFields from './chatFields'
import { CustomField, EditCustomField } from './customField'

export default function InboxRightSidebar({
  activeConversation = {},
  creatingConversation = null,
  changeConversation,
  className,
  subscriber,
  subscriberId,
  doingWizard = false,
  wizardConversation = false,
  getSubscriber = () => { },
  channelSummary = [],
  pipeLineSummary = [],
  apps = []
}) {
  const [previousConversations, setPreviousConversations] = useState([]);
  const [settings, setSettings] = useState(null)
  const [fields, setFields] = useState(null)
  const [editingCustomField, setEditingCustomField] = useState(null)
  const account = useSelector((state) => state.accountMaster);

  const canUseChatFields = TierPermissions("chatCustomFields", account).allowed

  useEffect(() => {
    getUserSettings().then(setSettings)
  }, [])

  useEffect(() => {
    if (activeConversation.id != 0 && !wizardConversation) {
      loadInitialData();
      getFields();
    }
  }, [activeConversation.id]);


  const loadInitialData = async () => {
    try {
      await getPreviousConversations();
    } catch (error) {
    }
  }

  const getFields = async () => {
    try {
      if (!canUseChatFields) return
      const { data } = await axios.get(`/inboxes/${activeConversation.InboxId}/field`)
      setFields(data)
    } catch (error) {
      console.log(error)
      setFields([])
    }
  }

  const getPreviousConversations = async () => {
    try {
      let { data } = await axios.get(
        `/inboxes/chat/subscriberhistory/${subscriberId}?limit=20`
      );
      data = data.filter(f => f.id !== activeConversation.id)
      setPreviousConversations(data);
    } catch (error) {
    }
  }

  const tagsChanged = async (newTags) => {
    await axios.put(`/subscriber/${subscriberId}/tags`, { tags: newTags.map(tag => tag.id) })
    getSubscriber(subscriberId);
  }

  const toggleSection = async (section) => {
    const inboxSettings = { ...(settings.inbox || {}) };
    const sections = { ...(inboxSettings.sections || {}) }
    sections[section] = !sections[section];
    inboxSettings.sections = sections;
    await setUserSettings({ ...settings, inbox: inboxSettings })
    setSettings({ ...settings, inbox: inboxSettings })
  }

  const updateField = async (id, value) => {
    const customFields = [...subscriber.customFields]
    const field = customFields.find(cf => cf.id === id)
    if (field) {
      field.value = value
      await axios.put(`/subscriber/${subscriberId}`, { customFields })
      getSubscriber(subscriberId)
    }
  }

  const downloadAttachment = async (file) => {
    if (file.location.indexOf('https://') === 0) {
      window.open(file.location, '_blank')
    } else {
      if (file.status !== 1) return
      const res = await axios.get(`/inboxes/chat/${activeConversation.id}/attachment/${file.id}`)
      window.open(res.data.link, '_blank')
    }
  }

  const inboxSettings = settings?.inbox || {};
  const sections = inboxSettings?.sections || {}
  if (!sections.hasOwnProperty('details')) sections['details'] = true;
  for (const app of apps) if (!sections.hasOwnProperty(app.ApplicationId + '')) sections[app.ApplicationId] = false;
  if (!sections.hasOwnProperty('customFields')) sections['customFields'] = false;
  if (!sections.hasOwnProperty('tags')) sections['tags'] = true;
  if (!sections.hasOwnProperty('segments')) sections['segments'] = false;
  if (!sections.hasOwnProperty('pipelines')) sections['pipelines'] = true;
  if (!sections.hasOwnProperty('conversations')) sections['conversations'] = true;
  if (!sections.hasOwnProperty('conversation')) sections['conversation'] = true;
  if (!sections.hasOwnProperty('addToCapsule')) sections['addToCapsule'] = true;
  if (!sections.hasOwnProperty('attachments')) sections['attachments'] = false;
  if (!sections.hasOwnProperty('chatFields')) sections['chatFields'] = false;


  if (!settings) return <UILIB.LoadingIcons />

  const hasCapsule = apps.some(app => app.ApplicationId === 8)
  const isAddedToCasule = hasCapsule && subscriber.ApplicationId === 8

  const attachments = (activeConversation.ChatAttachments || [])

  return (
    <section className="inbox-right-sidebar inbox-page__right-sidebar">
      {!!editingCustomField && <EditCustomField
        customField={editingCustomField.field}
        current={editingCustomField.current}
        finish={(value) => {
          updateField(editingCustomField.field.id, value)
          setEditingCustomField(null)
        }}
        close={() => setEditingCustomField(null)}
      />}
      <Details subscriber={subscriber} activeConversation={activeConversation} />
      {(!wizardConversation) && <div className="inbox-right-sidebar__sections">
        {subscriber.customFields.some(cf => cf.important) && <Section heading={i18n.t("chat:inbox.rightBar.details.header")} name="details" isOpen={sections.details} toggleOpen={toggleSection} >
          <dl>
            {subscriber.customFields.filter(cf => cf.important).map((customField) => <CustomField
              onEdit={(e) => {
                if (e.target.tagName === 'A') return
                const found = subscriber.customFields.find(cf => cf.id === customField.id)
                setEditingCustomField({ field: customField, current: found?.value })
              }}
              customField={customField}
              key={"important" + customField.id} />)}
          </dl>
        </Section>}

        <Section heading={i18n.t("chat:inbox.rightBar.conversation.header")} name="conversation" isOpen={sections.conversation} toggleOpen={toggleSection} >
          <CurrentPage SubscriberId={subscriberId} />
        </Section>

        {!!fields?.length && <Section heading={i18n.t("chat:inbox.rightBar.chatFields.header")} name="chatFields" isOpen={sections.chatFields} toggleOpen={toggleSection}>
          <ChatFields InboxId={activeConversation.InboxId} ChatId={activeConversation.id} />
        </Section>}

        <Section heading={i18n.t("chat:inbox.rightBar.tags.header")} count={subscriber.Tags.length} name="tags" isOpen={sections.tags} toggleOpen={toggleSection}>
          <div className="inbox-right-sidebar__sections_content">
            <UILIB.Tags tagsSelected={subscriber.Tags} readOnly={subscriber.LinkTags} tagsChanged={tagsChanged} tagType={0} addStyle={{}} />
          </div>
        </Section>

        {hasCapsule && !isAddedToCasule && <Section heading={i18n.t("chat:inbox.rightBar.capsuleAdd.header")} name="addToCapsule" isOpen={sections.addToCapsule} toggleOpen={toggleSection}>
          <div className="inbox-right-sidebar__sections_content">
            <AddToCapsule subscriber={subscriber} getSubscriber={getSubscriber} />
          </div>
        </Section>}

        {!!attachments.length && <Section heading={i18n.t("chat:inbox.rightBar.attachments.header")} count={attachments.length} name="attachments" isOpen={sections.attachments} toggleOpen={toggleSection}>
          <div className="inbox-right-sidebar__sections_content">
            <div className="inbox-compose__attachments">
              {attachments.map((file) => {
                let classes = 'inbox-compose__attachments__attachment '
                let tooltip = DateTimeFunctions.formatDateTime(file.createdAt)
                if (file.status === 2) {
                  classes += 'inbox-compose__attachments__attachment_invalid'
                  tooltip = file.error
                }
                else classes += 'inbox-compose__attachments__attachment_done'


                return <div className={classes} key={file.id} onClick={() => downloadAttachment(file)} data-for={'attachment' + file.id} data-tip >
                  <ReactTooltip effect='solid' className="tooltip" id={'attachment' + file.id} >{tooltip}</ReactTooltip>
                  <div className="inbox-compose__attachments__attachment_label">
                    {file.filename}
                  </div>
                  <div className="inbox-compose__attachments__attachment_size">
                    {humanizeBytes(file.size)}
                  </div>
                </div>
              })}
            </div>
          </div>
        </Section>}

        <Section heading={i18n.t("chat:inbox.rightBar.fields.header")} isOpen={sections.customFields} name="customFields" toggleOpen={toggleSection} >
          <dl>
            {subscriber.customFields.filter(cf => !cf.important && !cf.ApplicationId).sort((a, b) => {
              if (a.fieldDesc.toLowerCase() < b.fieldDesc.toLowerCase()) return -1;
              if (a.fieldDesc.toLowerCase() > b.fieldDesc.toLowerCase()) return 1;
              return 0;
            }).map((customField) => <CustomField
              onEdit={(e) => {
                if (e.target.tagName === 'A') return
                const found = subscriber.customFields.find(cf => cf.id === customField.id)
                setEditingCustomField({ field: customField, current: found?.value })
              }}
              customField={customField}
              key={"notimportant" + customField.id} />)}
          </dl>
        </Section>

        {apps.filter(app => subscriber.customFields.some(cf => cf.ApplicationId === app.ApplicationId && !cf.important)).map((app) => {
          return <Section heading={i18n.t("chat:inbox.rightBar.fields.appHeader", { appName: app.Application.appName })} key={'app_fields_' + app.ApplicationId} name={app.ApplicationId} isOpen={sections[app.ApplicationId]} toggleOpen={toggleSection}>

            <dl>
              {subscriber.customFields.filter(cf => !cf.important && cf.ApplicationId === cf.ApplicationId).sort((a, b) => {
                if (a.fieldDesc.toLowerCase() < b.fieldDesc.toLowerCase()) return -1;
                if (a.fieldDesc.toLowerCase() > b.fieldDesc.toLowerCase()) return 1;
                return 0;
              }).map((customField) => <CustomField
                onEdit={(e) => {
                  if (e.target.tagName === 'A') return
                  const found = subscriber.customFields.find(cf => cf.id === customField.id)
                  setEditingCustomField({ field: customField, current: found?.value })
                }}
                customField={customField}
                key={app.Application.appName + '_' + customField.id} />)}
            </dl>
          </Section>
        })}

        <Segments SubscriberId={subscriber.id} name="segments" isOpen={sections.segments} toggleOpen={toggleSection} />

        {(pipeLineSummary && pipeLineSummary.length > 0) && <Section heading={i18n.t("chat:inbox.rightBar.pipelines.header")} count={pipeLineSummary.length} name="pipelines" isOpen={sections.pipelines} toggleOpen={toggleSection}>
          <div className="inbox-right-sidebar__sections_content">
            <ul className={classnames(styles.pipelineList, "faux-list")} >
              {pipeLineSummary.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((opportunity, pIndex) => {
                let currency = "";
                switch (opportunity.value?.currency) {
                  case "GBP": currency = "£"; break;
                  case "USD": currency = "$"; break;
                  case "EUR": currency = "€"; break;
                }
                let partyName = opportunity.party?.name
                if (!partyName && opportunity.party?.firstName && opportunity.party?.lastName) partyName = `${opportunity.party.firstName} ${opportunity.party.lastName}`
                else if (!partyName && opportunity.party?.firstName) partyName = opportunity.party.firstName
                else if (!partyName && opportunity.party?.lastName) partyName = opportunity.party.lastName

                const app = apps.find(f => f.ApplicationId === 8)
                const url = app?.appSettings?.subdomain ? `https://${app.appSettings.subdomain}.capsulecrm.com/opportunity/${opportunity.id}` : undefined

                return <li key={"opp_" + pIndex}>
                  <button type="button" className={styles.pipelineTeaser} onClick={() => {
                    if (url) window.open(url, '_blank')
                  }}>
                    <UILIB.AvatarNew src={opportunity.party?.pictureURL} name={partyName} />
                    <div className={styles.pipelineContent}>
                      <div className={styles.pipelineMeta}>
                        <span>{partyName}</span>
                        <span>{opportunity.milestone?.name}</span>
                      </div>

                      <div className="fw-medium">
                        <span>{opportunity.name}</span>
                        <span>{currency}{opportunity?.value?.amount}</span>
                      </div>
                    </div>
                  </button>
                </li>
              }
              )}
            </ul>
          </div>
        </Section>}

        <Section heading={i18n.t("chat:inbox.rightBar.previous.header")} count={previousConversations.length} name="conversations" isOpen={sections.conversations} toggleOpen={toggleSection}>
          <div className="inbox-right-sidebar__sections_content">
            <ul className={classnames(styles.previousList, "faux-list")}>
              {previousConversations.map((conversation) => {
                return (
                  <li key={conversation.id} onClick={() => { changeConversation(conversation.id) }}>
                    <button type="button" className={styles.previousTeaser}>
                      <div className={styles.previousContent}>
                        {conversation.status === 0 && (
                          <UILIB.SquareChip className="square-chip-primary">
                            {i18n.t("chat:inbox.rightBar.previous.open")}
                          </UILIB.SquareChip>
                        )}
                        {conversation.status === 2 && (
                          <UILIB.SquareChip className="square-chip-secondary">
                            {i18n.t("chat:inbox.rightBar.previous.closed")}
                          </UILIB.SquareChip>
                        )}

                        <span className="text-overflow text-xsml fw-medium">
                          {conversation.lastMessage}
                        </span>
                      </div>

                      <time
                        className={classnames(styles.previousDate, "text-xsml")}
                        dateTime={moment(conversation.updatedAt).format(
                          "HH:MM"
                        )}
                      >
                        {moment(conversation.updatedAt).format("Do MMMM, YYYY")}
                      </time>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </Section>
      </div>}
    </section >
  );
}

function Section({ heading, children, className, isOpenDefault = true, name, count, isOpen, toggleOpen = () => { }, ...rest }) {
  const triggerId = window.crypto.randomUUID();
  const contentId = window.crypto.randomUUID();

  return (
    <div className="inbox-right-sidebar__sections_section" {...rest}>
      <button
        type="button"
        id={triggerId}
        aria-expanded={isOpen}
        aria-controls={contentId}
        className={classnames(styles.sectionTriggerBtn, "inbox-right-sidebar__sections_header")}
        onClick={() => toggleOpen(name)}
      >
        <h3 className="inbox-right-sidebar__sections_title">{heading}</h3>
        {!!count && <div className="inbox-right-sidebar__count-chip">{count}</div>}
        <UILIB.Icon name={isOpen ? "chevron-down" : "chevron-up"} />
      </button>

      <div
        id={contentId}
        role="region"
        aria-labelledby={triggerId}
        hidden={!isOpen}
      >
        {children}
      </div>
    </div>
  );
}

function Details({ subscriber, activeConversation }) {
  let src = subscriber.subscriberImage
  if (src && src.indexOf('blankAvatar.png') > -1) src = undefined
  return (
    <div className="inbox-right-sidebar__primaryDetails">
      <UILIB.AvatarNew
        className="inbox-right-sidebar__primaryDetails_avatar"
        src={src}
        name={subscriber.emailAddress}
        size={48}
        status={activeConversation?.vistorWebChatOnline ? "online" : "offline"}
      />

      <h3 className="text-md">{subscriber.emailAddress}</h3>

      <span className="text-grey text-xsml">{i18n.t("chat:inbox.rightBar.details.firstAdded", { date: DateTimeFunctions.formatDateTime(subscriber.createdAt, 2) })}</span>
      <UILIB.ScoreNew className="inbox-right-sidebar__primaryDetails_score" score={subscriber.score || 0} />

      {!!subscriber.id && <UILIB.Icons className="inbox-right-sidebar__primaryDetails_link" icon="external" color="grey" onClick={() => window.open('/cp/subscriber/' + subscriber.id, '_blank')} />}
    </div>
  )
}



function CurrentPage({ SubscriberId }) {
  const timer = useRef(null)
  const [lastPage, setLastPage] = useState(undefined)

  const getPage = async () => {
    const { data } = await axios.get(`/subscriber/${SubscriberId}/page`)
    setLastPage(data.page)
  }

  useEffect(() => {
    timer.current = setInterval(getPage, 10000)
    getPage()
    return function () {
      clearInterval(timer.current)
    }
  }, [SubscriberId])

  if (lastPage === undefined) return <UILIB.LoadingIcons className="mar-b0" style={{ width: 20, height: 20, marginTop: "-16px" }} />
  if (lastPage === null) return <UILIB.WarningHolder>{i18n.t("chat:inbox.rightBar.conversation.never")}</UILIB.WarningHolder>
  let lastPageUrl = "";
  if (lastPage.SiteTracker && lastPage.SiteTracker.url) lastPageUrl = lastPage.SiteTracker.url
  if (lastPage.SiteTrackerPath && lastPage.SiteTrackerPath.path) lastPageUrl += lastPage.SiteTrackerPath.path
  if (lastPageUrl === "") lastPageUrl = i18n.t("chat:inbox.rightBar.conversation.unknown");
  return (
    <dl>
      <div>
        <dt>{i18n.t("chat:inbox.rightBar.conversation.last")}</dt>
        <dd style={{ overflowX: 'auto' }}>{lastPageUrl}</dd>
      </div>

      <div >
        <dt>{i18n.t("chat:inbox.rightBar.conversation.lastVisited")}</dt>
        <dd>{DateTimeFunctions.formatDateTime(lastPage.createdAt)}</dd>
      </div>

    </dl>
  )
}

function Segments({ SubscriberId, ...rest }) {
  const dispatch = useDispatch()
  const [segments, setSegments] = useState()
  useEffect(() => {
    axios.get('/subscriber/' + SubscriberId + '/segments').then(res => setSegments(res.data))
  }, [SubscriberId])

  const view = (id) => {
    dispatch(alterSiteDrawer(true, true, 'right', <SegmentEditor segmentId={id} readonly={true} />, true, '100vw'))
  }

  if (!segments) return null

  return (
    <Section heading={i18n.t("chat:inbox.rightBar.segments.header")} count={segments.length} {...rest}>
      <div className="inbox-right-sidebar__sections_content">
        {segments.map(segment => <UILIB.SquareChip key={segment.id} className="square-chip mar-r5 mar-b5" onClick={() => view(segment.id)}>{segment.name}</UILIB.SquareChip>)}
      </div>
    </Section>
  )
}

function AddToCapsule({ subscriber, getSubscriber = () => { } }) {
  const dispatch = useDispatch()
  const addToCapsule = async () => {
    try {
      const res = await axios.post('/application/capsule/contact/' + subscriber.id)
      if (res.data?.ApplicationId === 8) {
        dispatch(alterSnackbar(true, i18n.t("chat:inbox.rightBar.capsuleAdd.added")))
        getSubscriber(subscriber.id)
      } else {
        dispatch(alterSnackbar(true, i18n.t("chat:inbox.rightBar.capsuleAdd.err")))
      }
    } catch (e) {
      dispatch(alterSnackbar(true, i18n.t("chat:inbox.rightBar.capsuleAdd.err")))
    }
  }

  const fields = subscriber.customFields.filter(cf => cf.ApplicationId === 8 && ["_capsule_firstName", "_capsule_lastName"].indexOf(cf.fieldName) > -1)
  const hasName = fields.some(f => !!f.value)

  return (
    <div className="inbox-right-sidebar__add-to-capsule">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="mar-b15">
        <rect width="32" height="32" rx="8" fill="#453DD8" />
        <g clipPath="url(#clip0_3331_16570)">
          <path d="M17.9983 8.45203C17.7031 8.45203 17.4629 8.6981 17.4629 9.00041C17.4629 9.30273 17.7031 9.5488 17.9983 9.5488C18.2935 9.5488 18.5337 9.30273 18.5337 9.00041C18.5337 8.6981 18.2935 8.45203 17.9983 8.45203Z" fill="white" />
          <path d="M17.9983 10.9795C17.7031 10.9795 17.4629 11.2256 17.4629 11.5279C17.4629 11.8302 17.7031 12.0763 17.9983 12.0763C18.2935 12.0763 18.5337 11.8302 18.5337 11.5279C18.5337 11.2256 18.2935 10.9795 17.9983 10.9795Z" fill="white" />
          <path d="M17.9983 13.5068C17.7031 13.5068 17.4629 13.7528 17.4629 14.0551C17.4629 14.3575 17.7031 14.6035 17.9983 14.6035C18.2935 14.6035 18.5337 14.3575 18.5337 14.0551C18.5337 13.7528 18.2935 13.5068 17.9983 13.5068Z" fill="white" />
          <path d="M20.5149 8.45209C20.2198 8.45209 19.9795 8.69816 19.9795 9.00047C19.9795 9.30279 20.2198 9.54886 20.5149 9.54886C20.8101 9.54886 21.0503 9.30279 21.0503 9.00047C21.0503 8.69816 20.8101 8.45209 20.5149 8.45209Z" fill="white" />
          <path d="M20.5149 10.9795C20.2198 10.9795 19.9795 11.2256 19.9795 11.5279C19.9795 11.8302 20.2198 12.0763 20.5149 12.0763C20.8101 12.0763 21.0503 11.8302 21.0503 11.5279C21.0503 11.2256 20.8101 10.9795 20.5149 10.9795Z" fill="white" />
          <path d="M20.5149 13.5068C20.2198 13.5068 19.9795 13.7528 19.9795 14.0551C19.9795 14.3575 20.2198 14.6035 20.5149 14.6035C20.8101 14.6035 21.0503 14.3575 21.0503 14.0551C21.0503 13.7528 20.8101 13.5068 20.5149 13.5068Z" fill="white" />
          <path d="M22.9856 8.45203C22.6904 8.45203 22.4502 8.6981 22.4502 9.00041C22.4502 9.30273 22.6904 9.5488 22.9856 9.5488C23.2807 9.5488 23.521 9.30273 23.521 9.00041C23.5244 8.6981 23.2842 8.45203 22.9856 8.45203Z" fill="white" />
          <path d="M22.9866 10.9795C22.6914 10.9795 22.4512 11.2256 22.4512 11.5279C22.4512 11.8302 22.6914 12.0763 22.9866 12.0763C23.2817 12.0763 23.522 11.8302 23.522 11.5279C23.5254 11.2256 23.2852 10.9795 22.9866 10.9795Z" fill="white" />
          <path d="M22.9866 13.5068C22.6914 13.5068 22.4512 13.7528 22.4512 14.0551C22.4512 14.3575 22.6914 14.6035 22.9866 14.6035C23.2817 14.6035 23.522 14.3575 23.522 14.0551C23.522 13.7528 23.2852 13.5068 22.9866 13.5068Z" fill="white" />
          <path fillRule="evenodd" clipRule="evenodd" d="M7.99902 11.5333C7.99902 9.76605 9.40246 8.33337 11.1337 8.33337C12.8649 8.33337 14.2684 9.76605 14.2684 11.5333V20.3339C14.2684 22.1012 12.8649 23.5339 11.1337 23.5339C9.40247 23.5339 7.99902 22.1012 7.99902 20.3339V11.5333ZM8.89539 20.2553C8.92418 18.9844 9.95101 17.9775 11.1866 18.0071C12.4245 18.0367 13.4033 19.0929 13.3721 20.3639C13.3434 21.6348 12.3165 22.6417 11.081 22.6121C9.84545 22.5824 8.8666 21.5262 8.89539 20.2553ZM10.3865 21.1337H11.8835V19.5937H10.3865V21.1337ZM11.2343 9.31044C12.4705 9.34125 13.4501 10.3971 13.4221 11.6687C13.3942 12.9404 12.3694 13.9463 11.1332 13.9155C9.89694 13.8847 8.91741 12.8288 8.94534 11.5572C8.97326 10.2855 9.99807 9.27963 11.2343 9.31044Z" fill="white" />
          <path fillRule="evenodd" clipRule="evenodd" d="M17.208 16.937V23.6969H23.8078V16.937H17.208ZM22.467 21.8408H18.551V21.0042H22.467V21.8408ZM18.551 19.6263H22.467V18.7896H18.551V19.6263Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_3331_16570">
            <rect width="16" height="16" fill="white" transform="translate(8 8)" />
          </clipPath>
        </defs>
      </svg>

      <div className="inbox-right-sidebar__add-to-capsule__title">{i18n.t("chat:inbox.rightBar.capsuleAdd.noMatch")}</div>
      <div className="inbox-right-sidebar__add-to-capsule__desc">{hasName ? i18n.t("chat:inbox.rightBar.capsuleAdd.noMatchDesc") : i18n.t("chat:inbox.rightBar.capsuleAdd.noMatchDescNoName")}</div>
      <Button variant="secondary" size="s" onClick={addToCapsule} iconLeft={<UILIB.Icon name="plus" />}>{i18n.t("chat:inbox.rightBar.capsuleAdd.add")}</Button>
    </div>
  )
}

function humanizeBytes(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}
import React, { Component } from 'react'
import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import SiteVars from '~/Classes/siteVars';
import store from '~/data/store/store';
import axios from '~/data/http/axios';
import i18n from '~/i18n'
import lodashGet from 'lodash/get'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import moment from 'moment';
import fontsHelper from '~/Classes/fonts'
import ddDefaultsHelper from '~/Classes/dragAndDropDefaults'
class DragDropFunctions {

    constructor() {
        var globalVars = {};
        var fontsFound = [];
    }

    static generateHtmlFromJson(theJson, forStage, globalVars, appendClassToStyles, localVariables) {
        this.globalVars = globalVars;

        if (!localVariables) localVariables = {}
        var templateType = "";
        if (theJson.mpzTemplateType) {
            templateType = theJson.mpzTemplateType;
        }
        var newHTML = this.processNode({ elements: [theJson] }, forStage, appendClassToStyles, localVariables);
        newHTML = this.cleanupHtml(newHTML);

        if (!forStage && !templateType) {
            var fontsUsed = this.findFontsReturnScripts(newHTML);
            var fontsUsedData = fontsUsed.filter(theFont => theFont.src == "google");
            if (fontsUsedData.length > 0) {
                var newFonts = "";
                fontsUsedData.forEach(theFont => {
                    //var theFontName = theFont.label.replace(/ /g, '+');
                    // newFonts += `<style type="text/css"> 
                    //     @import url(https://fonts.googleapis.com/css?family=${theFontName}:400,700,400italic,700italic);
                    //  </style>
                    //  <link href="https://fonts.googleapis.com/css?family=${theFontName}:400,700,400italic,700italic" rel="stylesheet" type="text/css">`;
                    var theFontName = theFont.label.replace(/ /g, '');
                    var theFontFolder = theFontName.toLowerCase();
                    newFonts += `<style type="text/css"> 
                    @font-face {
                        font-family: ${theFont.label};
                        src: url(https://cdn1.ourmailsender.com/fonts/${theFontFolder}/${theFontName}-Regular.ttf);
                      }
                      </style>
                    `
                    // @import url(https://cdn1.ourmailsender.com/fonts/${theFontFolder}/${theFontName}-Regular.ttf);
                });
                if (newHTML.indexOf("<head>") > -1) {
                    newHTML = newHTML.replace(/\<head\>/gmi, "<head>" + newFonts);
                }
                else {
                    newHTML = newFonts + newHTML;
                }
            }
            newHTML = newHTML.replace(/<br\/>/g, '<br>\n')
            newHTML = newHTML.replace(/<br \/>/g, '<br>\n')

            newHTML = this.findLinksHardCodeColor(newHTML);
            newHTML = this.findHeadingsAndInline(newHTML);
        }
        if (forStage) {
            var cssOverrides = `<style type="text/css">
                                .desktop-hide
                                {
                                    display: revert !important;
                                    opacity: 0.6;
                                }
                                .mobiledesktop-hide
                                {
                                    display: revert !important;
                                    opacity: 0.6;
                                }
                                </style>`
            newHTML = newHTML.replace(/\<head\>/gmi, "<head>" + cssOverrides);
        }
        var extraCSS = ``;
        newHTML = newHTML.replace(/\<head\>/gmi, "<head>" + extraCSS);
        newHTML = this.generateTableOfContents(newHTML);

        return newHTML;
    }

    static cleanupHtml(theHTML) {

        theHTML = theHTML.replace(/font-size: -webkit-/g, 'font-size: ');

        //look for font names and replace "'s with \"s
        for (var theIndex in this.fontArray) {
            var theFont = this.fontArray[theIndex].label;
            const regex = /""/gm;
            var match = "&quot;" + theFont + "&quot;";
            theHTML = theHTML.replace(new RegExp(match, 'g'), "'" + theFont + "'");
        }

        // theHTML = theHTML.replace(/font-size: x-small;/g, "font-size: 10px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size: small;/g, "font-size: 13px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size: medium;/g, "font-size: 16px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size: large;/g, "font-size: 18px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size: x-large;/g, "font-size: 24px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size: xx-large;/g, "font-size: 32px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size: xxx-large;/g, "font-size: 60px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size:x-small;/g, "font-size: 10px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size:small;/g, "font-size: 13px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size:medium;/g, "font-size: 16px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size:large;/g, "font-size: 18px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size:x-large;/g, "font-size: 24px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size:xx-large;/g, "font-size: 32px; line-height: normal;")
        // theHTML = theHTML.replace(/font-size:xxx-large;/g, "font-size: 60px; line-height: normal;")

        return theHTML;
    }

    static processNode(theJson, forStage, appendClassToStyles, localVars, repeatVariables) {

        //repeat variables are optional - they come in when we are looping nodes later on

        var htmlOut = "";
        var theData = theJson.elements;
        if (theJson.columns) {
            theData = theJson.columns;
        }

        theData.map((element, elIndex) => {
            if (!element) return

            var theHTML = "";
            var theProps = "";

            if (element.stageOnly && element.stageOnly == true && !forStage) return;
            if (element.renderOnly && element.renderOnly == true && forStage) return;
            if (element.localVariables) {
                localVars = element.localVariables;
            }
            if (element.props) {
                theProps = this.processProps(element, localVars, forStage, repeatVariables);
            }

            if (element.enabled) {
                //if we do have an enabled flag, just make sure its true or false. If false dont display the component
                if (element.enabled == false || element.enabled == "0" || element.enabled == "0%" || element.enabled == "0px") return;
                if (element.enabled.indexOf("localVariables_") > 0) {
                    if (localVars[element.enabled.replace("_localVariables_.", "")].value == "false" || localVars[element.enabled.replace("_localVariables_.", "")].value == "0" || localVars[element.enabled.replace("_localVariables_.", "")].value == "0%" || localVars[element.enabled.replace("_localVariables_.", "")].value == "0px") {
                        return;
                    }
                }
            }

            if (element.content) {

                if (forStage) {
                    var wysiStuff = "";
                    if (element.content.editableType && element.content.editableType === "Wysiwyg") {
                        theProps += " contenteditable=\"true\" id=\"" + element.content.eleId + "\" class=\"contentEditable\" "
                    }
                }

                var theValue = "";
                if (element.content.value) {
                    theValue = element.content.value;
                    theValue = this.processRepeatValue(repeatVariables, theValue);
                    if (theValue && theValue.indexOf("localVariables_.") > 0) {
                        theValue = this.lookupLocalVariables(localVars, theValue, false, forStage)
                    }
                    if (element.content.postProcess && element.content.postProcess.length) {

                        var tmpVal = theValue;
                        var postProcess = this.lookupLocalVariables(localVars, element.content.postProcess, false, forStage)
                        theValue = eval(postProcess)
                    }
                }
                else {
                    Object.keys(element.content).map(theVal => {
                        if (typeof element.content[theVal] === "object") {
                            if (appendClassToStyles && appendClassToStyles.length > 0 && theVal.indexOf('@media screen') < 0) {
                                theValue += appendClassToStyles + " " + theVal + " {";
                            }
                            else {
                                theValue += theVal + " {";
                            }

                            Object.keys(element.content[theVal]).map(keyVal => {

                                if (element.content[theVal][keyVal].value) {
                                    var ourVal = element.content[theVal][keyVal].value;
                                    ourVal = this.processRepeatValue(repeatVariables, ourVal);
                                    if (ourVal.indexOf("global_.") > 0) {
                                        if (this.globalVars[ourVal.split("_.")[1]]) {
                                            ourVal = this.globalVars[ourVal.split("_.")[1]].value;
                                        }
                                    }
                                    if (ourVal && ourVal.indexOf("localVariables_.") > 0) {
                                        ourVal = this.lookupLocalVariables(localVars, ourVal, false, forStage)
                                    }
                                    if (element.content[theVal][keyVal].postProcess && element.content[theVal][keyVal].postProcess.length) {

                                        var tmpVal = ourVal;
                                        var postProcess = this.lookupLocalVariables(localVars, element.content[theVal][keyVal].postProcess, false, forStage)
                                        ourVal = eval(postProcess)

                                    }
                                    switch (keyVal) {
                                        case "background-image":
                                            ourVal = "url(" + ourVal + ")"
                                            break;
                                    }
                                    theValue += keyVal + ":" + ourVal + "; ";
                                }
                                else {
                                    theValue += keyVal + " {"
                                    Object.keys(element.content[theVal][keyVal]).map(subVal => {
                                        var ourVal = element.content[theVal][keyVal][subVal].value;
                                        ourVal = this.processRepeatValue(repeatVariables, ourVal);
                                        if (ourVal.indexOf("global_.") > 0) {
                                            if (this.globalVars[ourVal.split("_.")[1]]) {
                                                ourVal = this.globalVars[ourVal.split("_.")[1]].value;
                                            }
                                        }
                                        if (ourVal && ourVal.indexOf("localVariables_.") > 0) {
                                            ourVal = this.lookupLocalVariables(localVars, element, false, forStage)
                                        }
                                        if (element.content[theVal][keyVal][subVal].postProcess && element.content[theVal][keyVal][subVal].postProcess.length) {

                                            var tmpVal = ourVal;
                                            var postProcess = this.lookupLocalVariables(localVars, element.content[theVal][keyVal][subVal].postProcess, false, forStage)
                                            ourVal = eval(postProcess)
                                        }
                                        switch (subVal) {
                                            case "background-image":
                                                ourVal = "url(" + ourVal + ")"
                                                break;
                                        }
                                        theValue += subVal + ": " + ourVal + "; ";

                                    });
                                    theValue += "}\n"
                                }
                            })
                            theValue += "}\n"
                        }
                    })
                }

                if (element.type) {

                    var closeTag = "</" + element.type + ">";
                    if (element.closeTag && element.closeTag.length) {
                        closeTag = element.closeTag;
                    }

                    if (element.content && element.content.value && typeof element.content.value == "object") {

                        element.content.value.forEach((theVal, valIndex) => {
                            var fiVal = "";
                            var fiLab = "";


                            if (typeof theVal == "object") {
                                fiVal = theVal.value;
                                fiLab = theVal.label;
                            }
                            else {
                                fiVal = theVal;
                                fiLab = theVal;
                            }
                            var tmpProps = theProps.replace(/_REPEATVALUE_/g, fiVal)

                            if (element.elements && element.elements.length > 0) {
                                //has sub els, process away
                                theHTML += "<" + element.type + tmpProps + ">" + this.processNode(element, forStage, appendClassToStyles, localVars, { value: fiVal, label: fiLab }) + closeTag;
                            }
                            else {
                                //doesnt have sub elements so just wrap the value up (Select options and stuff)
                                theHTML += "<" + element.type + tmpProps + ">" + fiLab + closeTag;
                            }

                        })

                    }
                    else if (element.elements) {
                        theHTML += "<" + element.type + theProps + ">" + this.processNode(element, forStage, appendClassToStyles, localVars) + closeTag;
                    }
                    else {
                        theHTML += "<" + element.type + theProps + ">" + theValue + closeTag;
                    }
                }



            }
            else {
                if (element.elements || element.columns) {

                    switch (element.type) {

                        case "html":

                            //insert outer table if body tag
                            theHTML += '<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">'
                            theHTML += "<html " + theProps + ">";
                            theHTML += this.processNode(element, forStage, appendClassToStyles, localVars, repeatVariables);
                            theHTML += "</html>";

                            break;

                        case "body":
                            //insert outer table if body tag
                            theHTML += "<body>";
                            theHTML += '<table width="100%" cellspacing="0" cellpadding="0" ' + theProps + '><tr><td valign="top">'
                            theHTML += this.processNode(element, forStage, appendClassToStyles, localVars, repeatVariables);
                            theHTML += '</td></tr></table>'
                            theHTML += "</body>";
                            break;

                        case "head":

                            theHTML += "<head>";
                            if (element.elements && element.elements.length) {
                                theHTML += this.processNode(element, forStage, appendClassToStyles, localVars, repeatVariables);
                            }
                            else {
                                theHTML += '<meta charset="UTF-8">';
                                theHTML += '<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">';
                                theHTML += '<meta name="x-apple-disable-message-reformatting">';
                                theHTML += '<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0">';
                                theHTML += '<!--[if gte mso 9]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]-->';

                            }
                            theHTML += "</head>";
                            break;

                        default:
                            let processParent = true;

                            if (element.type && element.type == "a" && theProps) {
                                if (theProps.indexOf(`href=""`) > -1) processParent = false;
                            }
                            if (element.type && processParent) {
                                theHTML += "<" + element.type + theProps + ">";
                            }
                            theHTML += this.processNode(element, forStage, appendClassToStyles, localVars, repeatVariables);
                            if (element.type && processParent) {
                                var closeTag = "</" + element.type + ">";
                                if (element.closeTag && element.closeTag.length) {

                                    closeTag = element.closeTag;
                                }
                                theHTML += closeTag;
                            }
                    }

                }
                else {
                    var tmpHtml = "";
                    if (element.type) {
                        var closeTag = "/>";
                        if (element.closeTag && element.closeTag.length) {
                            closeTag = element.closeTag;
                        }
                        tmpHtml = "<" + element.type + theProps + closeTag

                        if (!forStage) {

                            if (element.type == "poll") {
                                var pollContent = this.generatePollSlide(localVars.pollQuestions.value[0], localVars, this.globalVars)
                                tmpHtml = pollContent
                            }
                            if (element.type == "rssFeed") {

                                var rssContent = this.generateRssContent(localVars, this.globalVars)
                                tmpHtml = rssContent
                            }
                            if (element.type == "captcha") {

                                var captchaContent = this.generateCaptchaContent(localVars, this.globalVars)
                                tmpHtml = captchaContent
                            }
                        }
                    }
                    theHTML += tmpHtml;
                }
            }

            htmlOut += theHTML;
        })

        return htmlOut;

    }

    static processRepeatValue(repeatVariables, theValue) {

        if (repeatVariables) {

            switch (theValue) {
                case "_REPEATVALUE_":
                    {
                        if (repeatVariables.value) theValue = repeatVariables.value;
                    }
                    break;
                case "_REPEATLABEL_":
                    {
                        if (repeatVariables.label) theValue = repeatVariables.label;
                    } break;
            }
        }
        return theValue;
    }

    static processProps(element, localVars, forStage, repeatVariables) {

        var theProps = element.props;
        var elementType = element.type;

        var theOut = "";

        if (theProps) {
            Object.keys(theProps).map((theProp, index) => {
                if (typeof theProps[theProp] === "object" && theProp === "style") {
                    var subProps = "";
                    Object.keys(theProps[theProp]).map((subProp) => {

                        var theVal = theProps[theProp][subProp].value;
                        if (!forStage && theProps[theProp][subProp].finalValue) theVal = theProps[theProp][subProp].finalValue
                        theVal = this.processRepeatValue(repeatVariables, theVal)
                        if (theVal && theVal.indexOf("global_.") > 0) {
                            if (this.globalVars[theVal.split("_.")[1]]) {
                                theVal = this.globalVars[theVal.split("_.")[1]].value;
                            }
                        }
                        if (theVal && theVal.indexOf("localVariables_.") > 0) {
                            theVal = this.lookupLocalVariables(localVars, theVal, false, forStage)
                        }
                        if (theProps[theProp][subProp].postProcess && theProps[theProp][subProp].postProcess.length) {

                            var tmpVal = theVal;
                            var postProcess = this.lookupLocalVariables(localVars, theProps[theProp][subProp].postProcess, false, forStage)
                            theVal = eval(postProcess)

                        }


                        switch (subProp) {
                            case "background-image":
                                theVal = "url(" + theVal + ")"
                                break;
                            case "height":
                                {
                                    if (!forStage && theVal && element && element.type && element.type == "img") {
                                        theVal += " !important"
                                    }

                                    break;
                                }

                        }

                        if (subProp == "line-height") {
                            subProps += "mso-line-height-rule: exactly; line-height: " + theVal + ";"
                            theVal = "";
                        }


                        if (theProps[theProp][subProp].stageOnly && theProps[theProp][subProp].stageOnly == true && !forStage) return
                        if (theProps[theProp][subProp].renderOnly && theProps[theProp][subProp].renderOnly == true && forStage) return
                        if (theVal != "") {
                            subProps += subProp + ": " + theVal + "; ";
                        }
                    })

                    theOut += " " + theProp + "=\"" + subProps + "\""
                }
                else {
                    var theVal = theProps[theProp].value
                    theVal = this.processRepeatValue(repeatVariables, theVal)
                    if (theVal && theVal.indexOf("global_.") > 0) {
                        if (this.globalVars[theVal.split("_.")[1]]) {
                            theVal = this.globalVars[theVal.split("_.")[1]].value;
                        }
                    }
                    if (theVal && theVal.indexOf("localVariables_.") > 0) {
                        theVal = this.lookupLocalVariables(localVars, theVal, false, forStage)
                    }

                    if (theProps[theProp].postProcess && theProps[theProp].postProcess.length) {

                        var tmpVal = theVal;
                        var postProcess = this.lookupLocalVariables(localVars, theProps[theProp].postProcess, false, forStage)
                        theVal = eval(postProcess)

                    }

                    if (theProps[theProp].editable && theProps[theProp].editableInline) {
                        var theType = theProps[theProp].editableType;
                        var theID = theProps[theProp].eleId;
                        theOut += " class=\"editableInline\" ";
                    }

                    if (theProp === "width" || theProp === "height") {
                        if (!forStage && theProps[theProp].finalValue) theVal = theProps[theProp].finalValue
                        theVal = theVal.replace("px", "");
                    }


                    var okToShow = true;
                    // if value required is true and the value is null return nothing
                    if (theProps[theProp].valueRequired && theProps[theProp].valueRequired === true && theVal === "") okToShow = false;
                    //if hidden on final html
                    if (forStage && theProps[theProp].renderOnly && theProps[theProp].renderOnly == true) okToShow = false;
                    if (!forStage && theProps[theProp].stageOnly && theProps[theProp].stageOnly == true) okToShow = false;
                    //test
                    if (okToShow) theOut += " " + theProp + "=\"" + theVal + "\""


                }
            })
        }
        return theOut;
    }

    static convertToNewDataFormat(templateJson, isForm) {


        var currentColumn = 0;


        function parseObject(json) {
            Object.keys(json).forEach((theEl) => {
                if (Array.isArray(json[theEl])) {
                    json[theEl].forEach((theAr) => {
                        parseObject(theAr);
                    })
                } else {
                    if (theEl == "dragDrop") {
                        var dragDropType = json.dragDrop;
                        //layout
                        if (dragDropType == "canvasHolder") {
                            currentColumn = -1;
                            if (isForm) {
                                if (json.elements[0].elements[0].elements.length == 1) {
                                    var props = {
                                        backgroundColor: parseFindProp(json, 'Form', 'Colors', 'Color', 'ColorPicker', 'Background'),
                                        backgroundImage: parseFindProp(json, 'Form', 'Colors', 'Color', 'ImagePicker', 'Background Image'),
                                        borderColor: parseFindProp(json, 'Form', 'Border', 'Border', 'ColorPicker', 'Color'),
                                        borderRadius: parseFindProp(json, 'Form', 'Border', 'Border', 'UnitInput', 'Radius'),
                                        borderStyle: parseFindProp(json, 'Form', 'Border', 'Border', 'Select', 'Style'),
                                        borderWidth: parseFindProp(json, 'Form', 'Border', 'Border', 'UnitInput', 'Width'),
                                        maxWidth: parseFindProp(json, 'Form', 'Form Size', 'Size', 'UnitInput', 'Width'),
                                    }
                                    theObj.push({ id: 71, type: "holder", elements: [[]], props })
                                }

                                if (json.elements[0].elements[0].elements.length == 2) {
                                    //2 col form holder
                                    var props = {
                                        elementHolderBackgroundImage: parseFindProp(json, 'Form', 'Colors', 'Color', 'ImagePicker', 'Background Image'),
                                        elementHolderBorderColor: parseFindProp(json, 'Form', 'Border', 'Border', 'ColorPicker', 'Color'),
                                        elementHolderBorderRadius: parseFindProp(json, 'Form', 'Border', 'Border', 'UnitInput', 'Radius'),
                                        elementHolderBorderStyle: parseFindProp(json, 'Form', 'Border', 'Border', 'Select', 'Style'),
                                        elementHolderBorderWidth: parseFindProp(json, 'Form', 'Border', 'Border', 'UnitInput', 'Width'),
                                        elementHolderMaxWidth: parseFindProp(json, 'Form', 'Form Size', 'Size', 'UnitInput', 'Width'),
                                    }
                                    theObj.push({ id: 73, type: "holder", elements: [[], []], props })
                                }


                            }
                            else {
                                //single row
                                if (json.elements[0].elements[0].elements[0].elements.length == 1) {
                                    var cols = json.elements[0].elements[0].elements[0].elements[0].elements;
                                    //1col

                                    if (cols.length == 1) {
                                        var props = {
                                            elementHolderForegroundColor: parseFindProp(json, 'Style', 'Colors', '', 'ColorPicker', 'Foreground'),
                                            elementHolderBorderColor: parseFindProp(json, 'Style', 'Border', '', 'ColorPicker', 'Color'),
                                            elementHolderBorderStyle: parseFindProp(json, 'Style', 'Border', '', 'Select', 'Style'),
                                            elementHolderBorderWidth: parseFindProp(json, 'Style', 'Border', '', 'UnitInput', 'Width'),
                                            elementHolderPaddingBottom: parseFindProp(json, 'Style', 'Padding', '', 'UnitInput', 'Bottom'),
                                            elementHolderPaddingLeft: parseFindProp(json, 'Style', 'Padding', '', 'UnitInput', 'Left'),
                                            elementHolderPaddingRight: parseFindProp(json, 'Style', 'Padding', '', 'UnitInput', 'Right'),
                                            elementHolderPaddingTop: parseFindProp(json, 'Style', 'Padding', '', 'UnitInput', 'Top'),
                                            elementHolderBackgroundColor: parseFindProp(json, 'Style', 'Colors', '', 'ColorPicker', 'Background'),
                                            backgroundImage: parseFindProp(json, 'Style', 'Colors', '', 'ImagePicker', 'Background Image'),
                                            elementHolderMarginBottom: parseFindProp(json, 'Style', 'Margin', '', 'UnitInput', 'Bottom'),
                                            elementHolderMarginLeft: parseFindProp(json, 'Style', 'Margin', '', 'UnitInput', 'Left'),
                                            elementHolderMarginRight: parseFindProp(json, 'Style', 'Margin', '', 'UnitInput', 'Right'),
                                            elementHolderMarginTop: parseFindProp(json, 'Style', 'Margin', '', 'UnitInput', 'Top'),
                                        }
                                        theObj.push({ id: 14, type: "holder", elements: [[]], props })
                                    };
                                    //2col
                                    if (cols.length == 3) {
                                        //newJson, editableTab, category, categoryGroup, editableType, label, type
                                        var props = {
                                            spaceBetweenCells: parseFindProp(json, 'Style', 'Cells', '', 'UnitInput', 'Space Between'),
                                            backgroundColor1: parseFindProp(json, 'Style', 'Colors', 'Column 1', 'ColorPicker', 'Foreground'),
                                            borderColor1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'ColorPicker', 'Color'),
                                            borderStyle1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'Select', 'Style'),
                                            borderWidth1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'UnitInput', 'Width'),
                                            paddingBottom1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Bottom'),
                                            paddingLeft1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Left'),
                                            paddingRight1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Right'),
                                            paddingTop1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Top'),
                                            width1: parseFindProp(json, 'Style', 'Sizing', 'Column 1', 'UnitInput', 'Width'),
                                            backgroundColor2: parseFindProp(json, 'Style', 'Colors', 'Column 2', 'ColorPicker', 'Foreground'),
                                            borderColor2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'ColorPicker', 'Color'),
                                            borderStyle2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'Select', 'Style'),
                                            borderWidth2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'UnitInput', 'Width'),
                                            paddingBottom2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Bottom'),
                                            paddingLeft2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Left'),
                                            paddingRight2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Right'),
                                            paddingTop2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Top'),
                                            width2: parseFindProp(json, 'Style', 'Sizing', 'Column 2', 'UnitInput', 'Width'),
                                            outerBackgroundColor: parseFindProp(json, 'Style', 'Colors', 'Style', 'ColorPicker', 'Background'),
                                            outerBackgroundImage: parseFindProp(json, 'Style', 'Colors', 'Style', 'ImagePicker', 'Background Image'),
                                            outerPaddingBottom: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                            outerPaddingLeft: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Left'),
                                            outerPaddingRight: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Right'),
                                            outerPaddingTop: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Top'),
                                        }
                                        if (!props.width1 || props.width1 == "") delete props.width1;
                                        if (!props.width2 || props.width2 == "") delete props.width2;

                                        if (cols[0].props.width.value == "25%") {
                                            delete props.width1;
                                            delete props.width2;
                                            theObj.push({ id: 35, type: "holder", elements: [[], []], props });
                                        }
                                        else if (cols[0].props.width.value == "75%") {
                                            delete props.width1;
                                            delete props.width2;
                                            theObj.push({ id: 36, type: "holder", elements: [[], []], props });
                                        }
                                        else {

                                            theObj.push({ id: 17, type: "holder", elements: [[], []], props });
                                        }
                                    }
                                    //3col
                                    if (cols.length == 5) {
                                        //newJson, editableTab, category, categoryGroup, editableType, label, type
                                        var props = {
                                            spaceBetweenCells: parseFindProp(json, 'Style', 'Cells', '', 'UnitInput', 'Space Between'),
                                            backgroundColor1: parseFindProp(json, 'Style', 'Colors', 'Column 1', 'ColorPicker', 'Foreground'),
                                            borderColor1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'ColorPicker', 'Color'),
                                            borderStyle1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'Select', 'Style'),
                                            borderWidth1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'UnitInput', 'Width'),
                                            paddingBottom1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Bottom'),
                                            paddingLeft1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Left'),
                                            paddingRight1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Right'),
                                            paddingTop1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Top'),
                                            width1: parseFindProp(json, 'Style', 'Sizing', 'Column 1', 'UnitInput', 'Width'),
                                            backgroundColor2: parseFindProp(json, 'Style', 'Colors', 'Column 2', 'ColorPicker', 'Foreground'),
                                            borderColor2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'ColorPicker', 'Color'),
                                            borderStyle2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'Select', 'Style'),
                                            borderWidth2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'UnitInput', 'Width'),
                                            paddingBottom2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Bottom'),
                                            paddingLeft2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Left'),
                                            paddingRight2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Right'),
                                            paddingTop2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Top'),
                                            width2: parseFindProp(json, 'Style', 'Sizing', 'Column 2', 'UnitInput', 'Width'),
                                            backgroundColor3: parseFindProp(json, 'Style', 'Colors', 'Column 3', 'ColorPicker', 'Foreground'),
                                            borderColor3: parseFindProp(json, 'Style', 'Border', 'Column 3', 'ColorPicker', 'Color'),
                                            borderStyle3: parseFindProp(json, 'Style', 'Border', 'Column 3', 'Select', 'Style'),
                                            borderWidth3: parseFindProp(json, 'Style', 'Border', 'Column 3', 'UnitInput', 'Width'),
                                            paddingBottom3: parseFindProp(json, 'Style', 'Padding', 'Column 3', 'UnitInput', 'Bottom'),
                                            paddingLeft3: parseFindProp(json, 'Style', 'Padding', 'Column 3', 'UnitInput', 'Left'),
                                            paddingRight3: parseFindProp(json, 'Style', 'Padding', 'Column 3', 'UnitInput', 'Right'),
                                            paddingTop3: parseFindProp(json, 'Style', 'Padding', 'Column 3', 'UnitInput', 'Top'),
                                            width3: parseFindProp(json, 'Style', 'Sizing', 'Column 3', 'UnitInput', 'Width'),
                                            outerBackgroundColor: parseFindProp(json, 'Style', 'Colors', 'Style', 'ColorPicker', 'Background'),
                                            outerBackgroundImage: parseFindProp(json, 'Style', 'Colors', 'Style', 'ImagePicker', 'Background Image'),
                                            outerPaddingBottom: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                            outerPaddingLeft: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Left'),
                                            outerPaddingRight: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Right'),
                                            outerPaddingTop: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Top')
                                        }
                                        if (!props.width1 || props.width1 == "") delete props.width1;
                                        if (!props.width2 || props.width2 == "") delete props.width2;
                                        if (!props.width3 || props.width3 == "") delete props.width3;
                                        theObj.push({ id: 18, type: "holder", elements: [[], [], []], props });
                                    }
                                    //4col
                                    if (cols.length == 7) {
                                        //newJson, editableTab, category, categoryGroup, editableType, label, type
                                        var props = {
                                            spaceBetweenCells: parseFindProp(json, 'Style', 'Cells', '', 'UnitInput', 'Space Between'),
                                            backgroundColor1: parseFindProp(json, 'Style', 'Colors', 'Column 1', 'ColorPicker', 'Foreground'),
                                            borderColor1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'ColorPicker', 'Color'),
                                            borderStyle1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'Select', 'Style'),
                                            borderWidth1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'UnitInput', 'Width'),
                                            paddingBottom1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Bottom'),
                                            paddingLeft1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Left'),
                                            paddingRight1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Right'),
                                            paddingTop1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Top'),
                                            width1: parseFindProp(json, 'Style', 'Sizing', 'Column 1', 'UnitInput', 'Width'),
                                            backgroundColor2: parseFindProp(json, 'Style', 'Colors', 'Column 2', 'ColorPicker', 'Foreground'),
                                            borderColor2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'ColorPicker', 'Color'),
                                            borderStyle2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'Select', 'Style'),
                                            borderWidth2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'UnitInput', 'Width'),
                                            paddingBottom2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Bottom'),
                                            paddingLeft2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Left'),
                                            paddingRight2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Right'),
                                            paddingTop2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Top'),
                                            width2: parseFindProp(json, 'Style', 'Sizing', 'Column 2', 'UnitInput', 'Width'),
                                            backgroundColor3: parseFindProp(json, 'Style', 'Colors', 'Column 3', 'ColorPicker', 'Foreground'),
                                            borderColor3: parseFindProp(json, 'Style', 'Border', 'Column 3', 'ColorPicker', 'Color'),
                                            borderStyle3: parseFindProp(json, 'Style', 'Border', 'Column 3', 'Select', 'Style'),
                                            borderWidth3: parseFindProp(json, 'Style', 'Border', 'Column 3', 'UnitInput', 'Width'),
                                            paddingBottom3: parseFindProp(json, 'Style', 'Padding', 'Column 3', 'UnitInput', 'Bottom'),
                                            paddingLeft3: parseFindProp(json, 'Style', 'Padding', 'Column 3', 'UnitInput', 'Left'),
                                            paddingRight3: parseFindProp(json, 'Style', 'Padding', 'Column 3', 'UnitInput', 'Right'),
                                            paddingTop3: parseFindProp(json, 'Style', 'Padding', 'Column 3', 'UnitInput', 'Top'),
                                            width3: parseFindProp(json, 'Style', 'Sizing', 'Column 3', 'UnitInput', 'Width'),
                                            backgroundColor4: parseFindProp(json, 'Style', 'Colors', 'Column 4', 'ColorPicker', 'Foreground'),
                                            borderColor4: parseFindProp(json, 'Style', 'Border', 'Column 4', 'ColorPicker', 'Color'),
                                            borderStyle4: parseFindProp(json, 'Style', 'Border', 'Column 4', 'Select', 'Style'),
                                            borderWidth4: parseFindProp(json, 'Style', 'Border', 'Column 4', 'UnitInput', 'Width'),
                                            paddingBottom4: parseFindProp(json, 'Style', 'Padding', 'Column 4', 'UnitInput', 'Bottom'),
                                            paddingLeft4: parseFindProp(json, 'Style', 'Padding', 'Column 4', 'UnitInput', 'Left'),
                                            paddingRight4: parseFindProp(json, 'Style', 'Padding', 'Column 4', 'UnitInput', 'Right'),
                                            paddingTop4: parseFindProp(json, 'Style', 'Padding', 'Column 4', 'UnitInput', 'Top'),
                                            width4: parseFindProp(json, 'Style', 'Sizing', 'Column 4', 'UnitInput', 'Width'),
                                            outerBackgroundColor: parseFindProp(json, 'Style', 'Colors', 'Style', 'ColorPicker', 'Background'),
                                            outerBackgroundImage: parseFindProp(json, 'Style', 'Colors', 'Style', 'ImagePicker', 'Background Image'),
                                            outerPaddingBottom: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                            outerPaddingLeft: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Left'),
                                            outerPaddingRight: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Right'),
                                            outerPaddingTop: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Top')
                                        }
                                        if (!props.width1 || props.width1 == "") delete props.width1;
                                        if (!props.width2 || props.width2 == "") delete props.width2;
                                        if (!props.width3 || props.width3 == "") delete props.width3;
                                        if (!props.width4 || props.width4 == "") delete props.width4;
                                        theObj.push({ id: 19, type: "holder", elements: [[], [], [], []], props });
                                    }
                                    //8col
                                    if (cols.length == 15) {
                                        //newJson, editableTab, category, categoryGroup, editableType, label, type
                                        var props = {
                                            spaceBetweenCells: parseFindProp(json, 'Style', 'Cells', '', 'UnitInput', 'Space Between'),
                                            backgroundColor1: parseFindProp(json, 'Style', 'Colors', 'Column 1', 'ColorPicker', 'Foreground'),
                                            borderColor1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'ColorPicker', 'Color'),
                                            borderStyle1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'Select', 'Style'),
                                            borderWidth1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'UnitInput', 'Width'),
                                            paddingBottom1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Bottom'),
                                            paddingLeft1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Left'),
                                            paddingRight1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Right'),
                                            paddingTop1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Top'),
                                            width1: parseFindProp(json, 'Style', 'Sizing', 'Column 1', 'UnitInput', 'Width'),
                                            backgroundColor2: parseFindProp(json, 'Style', 'Colors', 'Column 2', 'ColorPicker', 'Foreground'),
                                            borderColor2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'ColorPicker', 'Color'),
                                            borderStyle2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'Select', 'Style'),
                                            borderWidth2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'UnitInput', 'Width'),
                                            paddingBottom2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Bottom'),
                                            paddingLeft2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Left'),
                                            paddingRight2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Right'),
                                            paddingTop2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Top'),
                                            width2: parseFindProp(json, 'Style', 'Sizing', 'Column 2', 'UnitInput', 'Width'),
                                            backgroundColor3: parseFindProp(json, 'Style', 'Colors', 'Column 3', 'ColorPicker', 'Foreground'),
                                            borderColor3: parseFindProp(json, 'Style', 'Border', 'Column 3', 'ColorPicker', 'Color'),
                                            borderStyle3: parseFindProp(json, 'Style', 'Border', 'Column 3', 'Select', 'Style'),
                                            borderWidth3: parseFindProp(json, 'Style', 'Border', 'Column 3', 'UnitInput', 'Width'),
                                            paddingBottom3: parseFindProp(json, 'Style', 'Padding', 'Column 3', 'UnitInput', 'Bottom'),
                                            paddingLeft3: parseFindProp(json, 'Style', 'Padding', 'Column 3', 'UnitInput', 'Left'),
                                            paddingRight3: parseFindProp(json, 'Style', 'Padding', 'Column 3', 'UnitInput', 'Right'),
                                            paddingTop3: parseFindProp(json, 'Style', 'Padding', 'Column 3', 'UnitInput', 'Top'),
                                            width3: parseFindProp(json, 'Style', 'Sizing', 'Column 3', 'UnitInput', 'Width'),
                                            backgroundColor4: parseFindProp(json, 'Style', 'Colors', 'Column 4', 'ColorPicker', 'Foreground'),
                                            borderColor4: parseFindProp(json, 'Style', 'Border', 'Column 4', 'ColorPicker', 'Color'),
                                            borderStyle4: parseFindProp(json, 'Style', 'Border', 'Column 4', 'Select', 'Style'),
                                            borderWidth4: parseFindProp(json, 'Style', 'Border', 'Column 4', 'UnitInput', 'Width'),
                                            paddingBottom4: parseFindProp(json, 'Style', 'Padding', 'Column 4', 'UnitInput', 'Bottom'),
                                            paddingLeft4: parseFindProp(json, 'Style', 'Padding', 'Column 4', 'UnitInput', 'Left'),
                                            paddingRight4: parseFindProp(json, 'Style', 'Padding', 'Column 4', 'UnitInput', 'Right'),
                                            paddingTop4: parseFindProp(json, 'Style', 'Padding', 'Column 4', 'UnitInput', 'Top'),
                                            width4: parseFindProp(json, 'Style', 'Sizing', 'Column 4', 'UnitInput', 'Width'),
                                            backgroundColor5: parseFindProp(json, 'Style', 'Colors', 'Column 5', 'ColorPicker', 'Foreground'),
                                            borderColor5: parseFindProp(json, 'Style', 'Border', 'Column 5', 'ColorPicker', 'Color'),
                                            borderStyle5: parseFindProp(json, 'Style', 'Border', 'Column 5', 'Select', 'Style'),
                                            borderWidth5: parseFindProp(json, 'Style', 'Border', 'Column 5', 'UnitInput', 'Width'),
                                            paddingBottom5: parseFindProp(json, 'Style', 'Padding', 'Column 5', 'UnitInput', 'Bottom'),
                                            paddingLeft5: parseFindProp(json, 'Style', 'Padding', 'Column 5', 'UnitInput', 'Left'),
                                            paddingRight5: parseFindProp(json, 'Style', 'Padding', 'Column 5', 'UnitInput', 'Right'),
                                            paddingTop5: parseFindProp(json, 'Style', 'Padding', 'Column 5', 'UnitInput', 'Top'),
                                            width5: parseFindProp(json, 'Style', 'Sizing', 'Column 5', 'UnitInput', 'Width'),
                                            backgroundColor6: parseFindProp(json, 'Style', 'Colors', 'Column 6', 'ColorPicker', 'Foreground'),
                                            borderColor6: parseFindProp(json, 'Style', 'Border', 'Column 6', 'ColorPicker', 'Color'),
                                            borderStyle6: parseFindProp(json, 'Style', 'Border', 'Column 6', 'Select', 'Style'),
                                            borderWidth6: parseFindProp(json, 'Style', 'Border', 'Column 6', 'UnitInput', 'Width'),
                                            paddingBottom6: parseFindProp(json, 'Style', 'Padding', 'Column 6', 'UnitInput', 'Bottom'),
                                            paddingLeft6: parseFindProp(json, 'Style', 'Padding', 'Column 6', 'UnitInput', 'Left'),
                                            paddingRight6: parseFindProp(json, 'Style', 'Padding', 'Column 6', 'UnitInput', 'Right'),
                                            paddingTop6: parseFindProp(json, 'Style', 'Padding', 'Column 6', 'UnitInput', 'Top'),
                                            width6: parseFindProp(json, 'Style', 'Sizing', 'Column 6', 'UnitInput', 'Width'),
                                            backgroundColor7: parseFindProp(json, 'Style', 'Colors', 'Column 7', 'ColorPicker', 'Foreground'),
                                            borderColor7: parseFindProp(json, 'Style', 'Border', 'Column 7', 'ColorPicker', 'Color'),
                                            borderStyle7: parseFindProp(json, 'Style', 'Border', 'Column 7', 'Select', 'Style'),
                                            borderWidth7: parseFindProp(json, 'Style', 'Border', 'Column 7', 'UnitInput', 'Width'),
                                            paddingBottom7: parseFindProp(json, 'Style', 'Padding', 'Column 7', 'UnitInput', 'Bottom'),
                                            paddingLeft7: parseFindProp(json, 'Style', 'Padding', 'Column 7', 'UnitInput', 'Left'),
                                            paddingRight7: parseFindProp(json, 'Style', 'Padding', 'Column 7', 'UnitInput', 'Right'),
                                            paddingTop7: parseFindProp(json, 'Style', 'Padding', 'Column 7', 'UnitInput', 'Top'),
                                            width7: parseFindProp(json, 'Style', 'Sizing', 'Column 7', 'UnitInput', 'Width'),
                                            backgroundColor8: parseFindProp(json, 'Style', 'Colors', 'Column 8', 'ColorPicker', 'Foreground'),
                                            borderColor8: parseFindProp(json, 'Style', 'Border', 'Column 8', 'ColorPicker', 'Color'),
                                            borderStyle8: parseFindProp(json, 'Style', 'Border', 'Column 8', 'Select', 'Style'),
                                            borderWidth8: parseFindProp(json, 'Style', 'Border', 'Column 8', 'UnitInput', 'Width'),
                                            paddingBottom8: parseFindProp(json, 'Style', 'Padding', 'Column 8', 'UnitInput', 'Bottom'),
                                            paddingLeft8: parseFindProp(json, 'Style', 'Padding', 'Column 8', 'UnitInput', 'Left'),
                                            paddingRight8: parseFindProp(json, 'Style', 'Padding', 'Column 8', 'UnitInput', 'Right'),
                                            paddingTop8: parseFindProp(json, 'Style', 'Padding', 'Column 8', 'UnitInput', 'Top'),
                                            width8: parseFindProp(json, 'Style', 'Sizing', 'Column 8', 'UnitInput', 'Width'),
                                            outerBackgroundColor: parseFindProp(json, 'Style', 'Colors', 'Style', 'ColorPicker', 'Background'),
                                            outerBackgroundImage: parseFindProp(json, 'Style', 'Colors', 'Style', 'ImagePicker', 'Background Image'),
                                            outerPaddingBottom: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                            outerPaddingLeft: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Left'),
                                            outerPaddingRight: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Right'),
                                            outerPaddingTop: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Top')
                                        }
                                        if (!props.width1 || props.width1 == "") delete props.width1;
                                        if (!props.width2 || props.width2 == "") delete props.width2;
                                        if (!props.width3 || props.width3 == "") delete props.width3;
                                        if (!props.width4 || props.width4 == "") delete props.width4;
                                        if (!props.width5 || props.width5 == "") delete props.width5;
                                        if (!props.width6 || props.width6 == "") delete props.width6;
                                        if (!props.width7 || props.width7 == "") delete props.width7;
                                        if (!props.width8 || props.width8 == "") delete props.width8;
                                        theObj.push({ id: 74, type: "holder", elements: [[], [], [], [], [], [], [], []], props });
                                    }

                                }
                                //two rows
                                if (json.elements[0].elements[0].elements[0].elements.length == 2) {
                                    var cols = json.elements[0].elements[0].elements[0].elements[0].elements;
                                    //single column, double column
                                    //newJson, editableTab, category, categoryGroup, editableType, label, type
                                    var props = {
                                        spaceBetweenCells: parseFindProp(json, 'Style', 'Cells', '', 'UnitInput', 'Space Between'),
                                        backgroundColor0: parseFindProp(json, 'Style', 'Colors', 'Column 0', 'ColorPicker', 'Foreground'),
                                        borderColor0: parseFindProp(json, 'Style', 'Border', 'Column 0', 'ColorPicker', 'Color'),
                                        borderStyle0: parseFindProp(json, 'Style', 'Border', 'Column 0', 'Select', 'Style'),
                                        borderWidth0: parseFindProp(json, 'Style', 'Border', 'Column 0', 'UnitInput', 'Width'),
                                        paddingBottom0: parseFindProp(json, 'Style', 'Padding', 'Column 0', 'UnitInput', 'Bottom'),
                                        paddingLeft0: parseFindProp(json, 'Style', 'Padding', 'Column 0', 'UnitInput', 'Left'),
                                        paddingRight0: parseFindProp(json, 'Style', 'Padding', 'Column 0', 'UnitInput', 'Right'),
                                        paddingTop0: parseFindProp(json, 'Style', 'Padding', 'Column 0', 'UnitInput', 'Top'),

                                        backgroundColor1: parseFindProp(json, 'Style', 'Colors', 'Column 1', 'ColorPicker', 'Foreground'),
                                        borderColor1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'ColorPicker', 'Color'),
                                        borderStyle1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'Select', 'Style'),
                                        borderWidth1: parseFindProp(json, 'Style', 'Border', 'Column 1', 'UnitInput', 'Width'),
                                        paddingBottom1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Bottom'),
                                        paddingLeft1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Left'),
                                        paddingRight1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Right'),
                                        paddingTop1: parseFindProp(json, 'Style', 'Padding', 'Column 1', 'UnitInput', 'Top'),

                                        backgroundColor2: parseFindProp(json, 'Style', 'Colors', 'Column 2', 'ColorPicker', 'Foreground'),
                                        borderColor2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'ColorPicker', 'Color'),
                                        borderStyle2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'Select', 'Style'),
                                        borderWidth2: parseFindProp(json, 'Style', 'Border', 'Column 2', 'UnitInput', 'Width'),
                                        paddingBottom2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Bottom'),
                                        paddingLeft2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Left'),
                                        paddingRight2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Right'),
                                        paddingTop2: parseFindProp(json, 'Style', 'Padding', 'Column 2', 'UnitInput', 'Top'),

                                        outerBackgroundColor: parseFindProp(json, 'Style', 'Colors', 'Style', 'ColorPicker', 'Background'),
                                        outerBackgroundImage: parseFindProp(json, 'Style', 'Colors', 'Style', 'ImagePicker', 'Background Image'),
                                        outerPaddingBottom: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                        outerPaddingLeft: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Left'),
                                        outerPaddingRight: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Right'),
                                        outerPaddingTop: parseFindProp(json, 'Style', 'Margin', 'Style', 'UnitInput', 'Top')
                                    }


                                    if (cols.length == 1) {
                                        theObj.push({ id: 67, type: "holder", elements: [[], [], []], props });
                                    }
                                    //double column, single column
                                    if (cols.length == 3) theObj.push({ id: 87, type: "holder", elements: [[], [], []], props });
                                }
                            }
                        }
                        if (dragDropType == "canvasColumn") {
                            currentColumn++;
                        }
                        if (dragDropType == "itemHolder") {

                            var parentObj = theObj[theObj.length - 1].elements[currentColumn]
                            if (!isForm) {
                                //standard drag drop elements
                                //if element version doesnt exist or is not 5.0.0 props are all over the place
                                //if element version is 5.0.0 props are all in local variables

                                if (json.editableTabs[0] == "Text") {

                                    var props = {
                                        textColor: parseFindProp(json, 'Text', 'Colors', '', 'ColorPicker', 'Text'),
                                        textLineHeight: parseFindProp(json, 'Text', 'Font', '', 'Select', 'Line Height'),
                                        innerAlign: parseFindProp(json, 'Text', 'Alignment', '', 'HorizontalAlign', 'Alignment'),
                                        innerVAlign: parseFindProp(json, 'Text', 'Alignment', '', 'VerticalAlign', 'Vertical Align'),
                                        innerPaddingTop: parseFindProp(json, 'Text', 'Padding', '', 'UnitInput', 'Top'),
                                        innerPaddingLeft: parseFindProp(json, 'Text', 'Padding', '', 'UnitInput', 'Left'),
                                        innerPaddingRight: parseFindProp(json, 'Text', 'Padding', '', 'UnitInput', 'Right'),
                                        innerPaddingBottom: parseFindProp(json, 'Text', 'Padding', '', 'UnitInput', 'Bottom'),
                                        innerBorderColor: parseFindProp(json, 'Text', 'Border', '', 'ColorPicker', 'Color'),
                                        innerBorderStyle: parseFindProp(json, 'Text', 'Border', '', 'Select', 'Style'),
                                        innerBorderWidth: parseFindProp(json, 'Text', 'Border', '', 'UnitInput', 'Width'),
                                        innerBackgroundColor: parseFindProp(json, 'Text', 'Colors', '', 'ColorPicker', 'Background'),
                                        outerPaddingTop: parseFindProp(json, 'Text', 'Margin', '', 'UnitInput', 'Top'),
                                        outerPaddingLeft: parseFindProp(json, 'Text', 'Margin', '', 'UnitInput', 'Left'),
                                        outerPaddingRight: parseFindProp(json, 'Text', 'Margin', '', 'UnitInput', 'Right'),
                                        outerPaddingBottom: parseFindProp(json, 'Text', 'Margin', '', 'UnitInput', 'Bottom'),
                                        textContent: parseFindProp(json, undefined, undefined, undefined, 'Wysiwyg', undefined)
                                    }
                                    parentObj.push({ id: 1, type: "element", props })
                                }
                                if (json.editableTabs[0] == "Image") {


                                    var props = {
                                        borderColor: parseFindProp(json, 'Image', 'Border', '', 'ColorPicker', 'Color'),
                                        borderStyle: parseFindProp(json, 'Image', 'Border', '', 'Select', 'Style'),
                                        borderWidth: parseFindProp(json, 'Image', 'Border', '', 'UnitInput', 'Width'),
                                        imageLink: parseFindProp(json, 'Image', 'Link', '', 'Text', 'Link'),
                                        imageSrc: parseFindProp(json, 'Image', 'Image', '', 'ImagePicker', 'Image'),
                                        imageTitle: parseFindProp(json, 'Image', 'Image', '', 'Text', 'Hover Text'),
                                        imageWidth: parseFindProp(json, 'Image', 'Image', '', 'UnitInput', 'Width'),
                                        innerAlign: parseFindProp(json, 'Image', 'Alignment', '', 'HorizontalAlign', 'Alignment'),
                                        innerVAlign: parseFindProp(json, 'Image', 'Alignment', '', 'VerticalAlign', 'Vertical Align'),
                                        outerBgColor: parseFindProp(json, 'Image', 'Colors', '', 'ColorPicker', 'Background'),
                                        outerPaddingBottom: parseFindProp(json, 'Image', '', 'Holder', 'UnitInput', 'Bottom'),
                                        outerPaddingLeft: parseFindProp(json, 'Image', '', 'Holder', 'UnitInput', 'Left'),
                                        outerPaddingRight: parseFindProp(json, 'Image', '', 'Holder', 'UnitInput', 'Right'),
                                        outerPaddingTop: parseFindProp(json, 'Image', '', 'Holder', 'UnitInput', 'Top')
                                    }

                                    parentObj.push({ id: 2, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "Divider") {
                                    var props = {
                                        borderTopColor: parseFindProp(json, 'Divider', 'Style', '', 'ColorPicker', 'Color'),
                                        borderTopStyle: parseFindProp(json, 'Divider', 'Style', '', 'Select', 'Style'),
                                        borderTopWidth: parseFindProp(json, 'Divider', 'Style', '', 'UnitInput', 'Height'),
                                        backgroundColor: parseFindProp(json, 'Divider', 'Colors', '', 'ColorPicker', 'Background'),
                                        paddingBottom: parseFindProp(json, 'Divider', 'Padding', '', 'UnitInput', 'Bottom'),
                                        paddingLeft: parseFindProp(json, 'Divider', 'Padding', '', 'UnitInput', 'Left'),
                                        paddingRight: parseFindProp(json, 'Divider', 'Padding', '', 'UnitInput', 'Right'),
                                        paddingTop: parseFindProp(json, 'Divider', 'Padding', '', 'UnitInput', 'Top')
                                    }
                                    parentObj.push({ id: 3, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "Button") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType

                                    var props = {
                                        buttonLink: parseFindProp(json, 'Button', 'Link', '', 'Text', 'Link'),
                                        buttonFontColor: parseFindProp(json, 'Button', 'Font', '', 'ColorPicker', 'Colour'),
                                        buttonFontfamily: parseFindProp(json, 'Button', 'Font', '', 'Select', 'Font'),
                                        buttonFontSize: parseFindProp(json, 'Button', 'Font', '', 'UnitInput', 'Size'),
                                        buttonPadding: parseFindProp(json, 'Button', 'Padding', '', 'UnitInput', 'Padding'),
                                        outerPaddingTop: parseFindProp(json, 'Button', 'Padding', '', 'UnitInput', 'Top'),
                                        outerPaddingRight: parseFindProp(json, 'Button', 'Padding', '', 'UnitInput', 'Right'),
                                        outerPaddingLeft: parseFindProp(json, 'Button', 'Padding', '', 'UnitInput', 'Left'),
                                        outerPaddingBottom: parseFindProp(json, 'Button', 'Padding', '', 'UnitInput', 'Bottom'),
                                        outerBackgroundColor: parseFindProp(json, 'Button', 'Colors', 'Holder', 'ColorPicker', 'Background'),
                                        buttonWidth: parseFindProp(json, 'Button', 'Size', '', 'UnitInput', 'Width'),
                                        borderWidth: parseFindProp(json, 'Button', 'Border', '', 'UnitInput', 'Width'),
                                        borderColor: parseFindProp(json, 'Button', 'Border', '', 'ColorPicker', 'Color'),
                                        borderRadius: parseFindProp(json, 'Button', 'Border', '', 'UnitInput', 'Border Radius'),
                                        borderStyle: parseFindProp(json, 'Button', 'Border', '', 'Select', 'Style'),
                                        buttonBgColor: parseFindProp(json, 'Button', 'Colors', 'Button Style', 'ColorPicker', 'Background'),
                                        textContent: parseFindProp(json, undefined, undefined, undefined, 'Wysiwyg', undefined)
                                    }
                                    var buttonAlign = parseFindProp(json, undefined, undefined, undefined, undefined, 'Alignment');
                                    if (!buttonAlign) buttonAlign = "center";
                                    props.buttonAlign = buttonAlign;

                                    parentObj.push({ id: 4, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "Video") {

                                    var props = {
                                        paddingBottom: parseFindProp(json, 'Video', 'Padding', '', 'UnitInput', 'Bottom'),
                                        paddingTop: parseFindProp(json, 'Video', 'Padding', '', 'UnitInput', 'Top'),
                                        paddingRight: parseFindProp(json, 'Video', 'Padding', '', 'UnitInput', 'Right'),
                                        paddingTop: parseFindProp(json, 'Video', 'Padding', '', 'UnitInput', 'Top'),
                                        backgroundColor: parseFindProp(json, 'Video', 'Colors', '', 'ColorPicker', 'Background'),
                                        videoAlignH: parseFindProp(json, 'Video', 'Alignment', '', 'HorizontalAlign', 'Alignment'),
                                        videoAlignV: parseFindProp(json, 'Video', 'Alignment', '', 'VerticalAlign', 'Vertical Align'),
                                        videoTitle: parseFindProp(json, 'Video', 'Video', '', 'Text', 'Hover Text'),
                                        videoWidth: parseFindProp(json, 'Video', 'Video', '', 'UnitInput', 'Width'),
                                        videoScreenshot: parseFindProp(json, '', '', '', '', '', 'videoScreenshot'),
                                        videoUrl: parseFindProp(json, 'Video', 'Video', '', 'Video', 'Video URL')
                                    }
                                    parentObj.push({ id: 7, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "Social Icons") {
                                    var props = {
                                        emailEnabled: parseFindProp(json, '', '', '', '', '', 'emailEnabled'),
                                        emailIconUrl: parseFindProp(json, '', '', '', '', '', 'emailIconUrl'),
                                        emailLinkUrl: parseFindProp(json, '', '', '', '', '', 'emailLinkUrl'),
                                        faceBookIconUrl: parseFindProp(json, '', '', '', '', '', 'faceBookIconUrl'),
                                        faceBookLinkUrl: parseFindProp(json, '', '', '', '', '', 'faceBookLinkUrl'),
                                        facebookEnabled: parseFindProp(json, '', '', '', '', '', 'facebookEnabled'),
                                        googleplusEnabled: parseFindProp(json, '', '', '', '', '', 'googleplusEnabled'),
                                        googleplusIconUrl: parseFindProp(json, '', '', '', '', '', 'googleplusIconUrl'),
                                        googleplusLinkUrl: parseFindProp(json, '', '', '', '', '', 'googleplusLinkUrl'),
                                        iconPadding: parseFindProp(json, '', '', '', '', '', 'iconPadding'),
                                        iconSet: parseFindProp(json, '', '', '', '', '', 'iconSet'),
                                        iconSize: parseFindProp(json, '', '', '', '', '', 'iconSize'),
                                        instagramEnabled: parseFindProp(json, '', '', '', '', '', 'instagramEnabled'),
                                        instagramIconUrl: parseFindProp(json, '', '', '', '', '', 'instagramIconUrl'),
                                        instagramLinkUrl: parseFindProp(json, '', '', '', '', '', 'instagramLinkUrl'),
                                        linkedinEnabled: parseFindProp(json, '', '', '', '', '', 'linkedinEnabled'),
                                        linkedinIconUrl: parseFindProp(json, '', '', '', '', '', 'linkedinIconUrl'),
                                        linkedinLinkUrl: parseFindProp(json, '', '', '', '', '', 'linkedinLinkUrl'),
                                        twitterEnabled: parseFindProp(json, '', '', '', '', '', 'twitterEnabled'),
                                        twitterIconUrl: parseFindProp(json, '', '', '', '', '', 'twitterIconUrl'),
                                        twitterLinkUrl: parseFindProp(json, '', '', '', '', '', 'twitterLinkUrl'),
                                        websiteEnabled: parseFindProp(json, '', '', '', '', '', 'websiteEnabled'),
                                        websiteIconUrl: parseFindProp(json, '', '', '', '', '', 'websiteIconUrl'),
                                        websiteLinkUrl: parseFindProp(json, '', '', '', '', '', 'websiteLinkUrl'),
                                        youtubeEnabled: parseFindProp(json, '', '', '', '', '', 'youtubeEnabled'),
                                        youtubeIconUrl: parseFindProp(json, '', '', '', '', '', 'youtubeIconUrl'),
                                        youtubeLinkUrl: parseFindProp(json, '', '', '', '', '', 'youtubeLinkUrl'),
                                        tikTokEnabled: parseFindProp(json, '', '', '', '', '', 'tikTokEnabled'),
                                        tikTokIconUrl: parseFindProp(json, '', '', '', '', '', 'tikTokIconUrl'),
                                        tikTokLinkUrl: parseFindProp(json, '', '', '', '', '', 'tikTokLinkUrl'),
                                        alignment: parseFindProp(json, 'Social Icons', 'Alignment', '', 'HorizontalAlign', 'Alignment'),
                                        backgroundColor: parseFindProp(json, 'Social Icons', 'Colors', '', 'ColorPicker', 'Background'),
                                        paddingBottom: parseFindProp(json, 'Social Icons', 'Padding', '', 'UnitInput', 'Bottom'),
                                        paddingLeft: parseFindProp(json, 'Social Icons', 'Padding', '', 'UnitInput', 'Left'),
                                        paddingRight: parseFindProp(json, 'Social Icons', 'Padding', '', 'UnitInput', 'Right'),
                                        paddingTop: parseFindProp(json, 'Social Icons', 'Padding', '', 'UnitInput', 'Top'),
                                    }

                                    if (!props.websiteEnabled) props.websiteEnabled = false;
                                    if (!props.websiteIconUrl) props.websiteIconUrl = 'https://cdn1.ourmailsender.com/siteContent/assets/templates/socialMediaIcons/website-{_localVariables_.iconSet}.png';
                                    parentObj.push({ id: 91, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "Countdown") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType
                                    var props = {
                                        imageUrl: parseFindProp(json, '', '', '', '', '', 'imageUrl'),
                                        imageTitle: parseFindProp(json, 'Countdown', 'Image', '', 'Text', 'Hover Text'),
                                        link: parseFindProp(json, 'Countdown', 'Link', '', 'Text', 'Link'),
                                        borderColor: parseFindProp(json, 'Countdown', 'Border', '', 'ColorPicker', 'Color'),
                                        borderStyle: parseFindProp(json, 'Countdown', 'Border', '', 'Select', 'Style'),
                                        borderWidth: parseFindProp(json, 'Countdown', 'Border', '', 'UnitInput', 'Width'),
                                        imageWidth: parseFindProp(json, 'Countdown', 'Image', '', 'UnitInput', 'Width'),
                                        marginBottom: parseFindProp(json, 'Countdown', 'Margin', '', 'UnitInput', 'Bottom'),
                                        marginLeft: parseFindProp(json, 'Countdown', 'Margin', '', 'UnitInput', 'Left'),
                                        marginRight: parseFindProp(json, 'Countdown', 'Margin', '', 'UnitInput', 'Right'),
                                        marginTop: parseFindProp(json, 'Countdown', 'Margin', '', 'UnitInput', 'Top'),
                                        verticalAlign: parseFindProp(json, 'Countdown', 'Alignment', '', 'VerticalAlign', 'Vertical Align'),
                                        horizontalAlign: parseFindProp(json, 'Countdown', 'Alignment', '', 'HorizontalAlign', 'Alignment'),
                                        countDownBgColor: parseFindProp(json, 'Countdown', 'Countdown', '', 'ColorPicker', 'Background Color'),
                                        countDownFont: parseFindProp(json, 'Countdown', 'Countdown', '', 'Select', 'Font'),
                                        countDownFontColor: parseFindProp(json, 'Countdown', 'Countdown', '', 'ColorPicker', 'Font Color'),
                                        countDownFormat: parseFindProp(json, 'Countdown', 'Countdown', '', 'Select', 'Display Format'),
                                        countdownDatetime: parseFindProp(json, 'Countdown', 'Countdown', '', 'Text', 'Date')
                                    }
                                    parentObj.push({ id: 98, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "HTML") {
                                    var props = {
                                        fontColor: parseFindProp(json, 'HTML', 'Colors', '', 'ColorPicker', 'Text'),
                                        fontLineHeight: parseFindProp(json, 'HTML', 'Font', '', 'Select', 'Line Height'),
                                        htmlContent: parseFindProp(json, undefined, undefined, undefined, undefined, undefined, undefined, 'html')
                                    }
                                    parentObj.push({ id: 99, type: "element", props })
                                }
                                if (json.editableTabs[0] == "TrustPilot Rating Vertical") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType

                                    var props = {
                                        buid: parseFindProp(json, 'TrustPilot', 'Trustpilot Business', 'Settings', 'Select', 'Choose'),
                                        showTPLogo: parseFindProp(json, 'TrustPilot', 'Trustpilot Logo', 'Settings', 'Select', 'Visible'),
                                        logoWidth: parseFindProp(json, 'TrustPilot', 'Trustpilot Logo', 'Settings', 'UnitInput', 'Width'),
                                        paddingBottom: parseFindProp(json, 'TrustPilot', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                        paddingLeft: parseFindProp(json, 'TrustPilot', 'Margin', 'Style', 'UnitInput', 'Left'),
                                        paddingRight: parseFindProp(json, 'TrustPilot', 'Margin', 'Style', 'UnitInput', 'Right'),
                                        paddingTop: parseFindProp(json, 'TrustPilot', 'Margin', 'Style', 'UnitInput', 'Top'),
                                        backgroundColor: parseFindProp(json, 'TrustPilot', 'Colors', 'Style', 'ColorPicker', 'Background'),
                                        borderColor: parseFindProp(json, 'TrustPilot', 'Border', 'Style', 'ColorPicker', 'Color'),
                                        borderStyle: parseFindProp(json, 'TrustPilot', 'Border', 'Style', 'Select', 'Style'),
                                        borderWidth: parseFindProp(json, 'TrustPilot', 'Border', 'Style', 'UnitInput', 'Width'),
                                        elementPaddingBottom: parseFindProp(json, 'TrustPilot', 'Padding', 'Style', 'UnitInput', 'Bottom'),
                                        elementPaddingLeft: parseFindProp(json, 'TrustPilot', 'Padding', 'Style', 'UnitInput', 'Left'),
                                        elementPaddingRight: parseFindProp(json, 'TrustPilot', 'Padding', 'Style', 'UnitInput', 'Right'),
                                        elementPaddingTop: parseFindProp(json, 'TrustPilot', 'Padding', 'Style', 'UnitInput', 'Top'),
                                        bodyFontColor: parseFindProp(json, 'TrustPilot', 'Colors', 'Style', 'ColorPicker', 'Text'),
                                        bodyFont: parseFindProp(json, 'TrustPilot', 'Font', 'Style', 'Select', 'Font'),
                                        bodyFontSize: parseFindProp(json, 'TrustPilot', 'Font', 'Style', 'UnitInput', 'Size'),
                                        tpImgWidth: parseFindProp(json, 'TrustPilot', 'Rating Stars', 'Settings', 'UnitInput', 'Width'),
                                        tpBuidData: parseFindProp(json, '', '', '', '', '', 'tpBuidData'),
                                        tpData: parseFindProp(json, '', '', '', '', '', 'tpData'),
                                        tpIMG: parseFindProp(json, '', '', '', '', '', 'tpIMG'),
                                        tpReviews: parseFindProp(json, '', '', '', '', '', 'tpReviews'),
                                        tpScore: parseFindProp(json, '', '', '', '', '', 'tpScore')
                                    }
                                    parentObj.push({ id: 100, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "TrustPilot Rating Horizontal") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType
                                    var props = {
                                        buid: parseFindProp(json, 'TrustPilot', 'Trustpilot Business', 'Settings', 'Select', 'Choose'),
                                        showTPLogo: parseFindProp(json, 'TrustPilot', 'Trustpilot Logo', 'Settings', 'Select', 'Visible'),
                                        logoWidth: parseFindProp(json, 'TrustPilot', 'Trustpilot Logo', 'Settings', 'UnitInput', 'Width'),
                                        paddingBottom: parseFindProp(json, 'TrustPilot', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                        paddingLeft: parseFindProp(json, 'TrustPilot', 'Margin', 'Style', 'UnitInput', 'Left'),
                                        paddingRight: parseFindProp(json, 'TrustPilot', 'Margin', 'Style', 'UnitInput', 'Right'),
                                        paddingTop: parseFindProp(json, 'TrustPilot', 'Margin', 'Style', 'UnitInput', 'Top'),
                                        backgroundColor: parseFindProp(json, 'TrustPilot', 'Colors', 'Style', 'ColorPicker', 'Background'),
                                        borderColor: parseFindProp(json, 'TrustPilot', 'Border', 'Style', 'ColorPicker', 'Color'),
                                        borderStyle: parseFindProp(json, 'TrustPilot', 'Border', 'Style', 'Select', 'Style'),
                                        borderWidth: parseFindProp(json, 'TrustPilot', 'Border', 'Style', 'UnitInput', 'Width'),
                                        elementPaddingBottom: parseFindProp(json, 'TrustPilot', 'Padding', 'Style', 'UnitInput', 'Bottom'),
                                        elementPaddingLeft: parseFindProp(json, 'TrustPilot', 'Padding', 'Style', 'UnitInput', 'Left'),
                                        elementPaddingRight: parseFindProp(json, 'TrustPilot', 'Padding', 'Style', 'UnitInput', 'Right'),
                                        elementPaddingTop: parseFindProp(json, 'TrustPilot', 'Padding', 'Style', 'UnitInput', 'Top'),
                                        fontColor: parseFindProp(json, 'TrustPilot', 'Colors', 'Style', 'ColorPicker', 'Text'),
                                        fontFamily: parseFindProp(json, 'TrustPilot', 'Font', 'Style', 'Select', 'Font'),
                                        fontSize: parseFindProp(json, 'TrustPilot', 'Font', 'Style', 'UnitInput', 'Size'),
                                        starsWidth: parseFindProp(json, 'TrustPilot', 'Rating Stars', 'Settings', 'UnitInput', 'Width'),
                                        tpBuidData: parseFindProp(json, '', '', '', '', '', 'tpBuidData'),
                                        tpData: parseFindProp(json, '', '', '', '', '', 'tpData'),
                                        tpIMG: parseFindProp(json, '', '', '', '', '', 'tpIMG'),
                                        tpReviews: parseFindProp(json, '', '', '', '', '', 'tpReviews'),
                                        tpScore: parseFindProp(json, '', '', '', '', '', 'tpScore')
                                    }
                                    parentObj.push({ id: 101, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "TrustPilot Reviews") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType
                                    var props = {
                                        buid: parseFindProp(json, 'TrustPilot', 'Trustpilot Business', 'Settings', 'Select', 'Choose'),
                                        showTPLogo: parseFindProp(json, 'TrustPilot', 'Trustpilot Logo', 'Settings', 'Select', 'Visible'),
                                        logoWidth: parseFindProp(json, 'TrustPilot', 'Trustpilot Logo', 'Settings', 'UnitInput', 'Width'),
                                        paddingBottom: parseFindProp(json, 'TrustPilot', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                        paddingLeft: parseFindProp(json, 'TrustPilot', 'Margin', 'Style', 'UnitInput', 'Left'),
                                        paddingRight: parseFindProp(json, 'TrustPilot', 'Margin', 'Style', 'UnitInput', 'Right'),
                                        paddingTop: parseFindProp(json, 'TrustPilot', 'Margin', 'Style', 'UnitInput', 'Top'),
                                        backgroundColor: parseFindProp(json, 'TrustPilot', 'Colors', 'Style', 'ColorPicker', 'Background'),
                                        borderColor: parseFindProp(json, 'TrustPilot', 'Border', 'Style', 'ColorPicker', 'Color'),
                                        borderStyle: parseFindProp(json, 'TrustPilot', 'Border', 'Style', 'Select', 'Style'),
                                        borderWidth: parseFindProp(json, 'TrustPilot', 'Border', 'Style', 'UnitInput', 'Width'),
                                        elementPaddingBottom: parseFindProp(json, 'TrustPilot', 'Padding', 'Style', 'UnitInput', 'Bottom'),
                                        elementPaddingLeft: parseFindProp(json, 'TrustPilot', 'Padding', 'Style', 'UnitInput', 'Left'),
                                        elementPaddingRight: parseFindProp(json, 'TrustPilot', 'Padding', 'Style', 'UnitInput', 'Right'),
                                        elementPaddingTop: parseFindProp(json, 'TrustPilot', 'Padding', 'Style', 'UnitInput', 'Top'),
                                        fontColor: parseFindProp(json, 'TrustPilot', 'Colors', 'Style', 'ColorPicker', 'Text'),
                                        fontFamily: parseFindProp(json, 'TrustPilot', 'Font', 'Style', 'Select', 'Font'),
                                        fontSize: parseFindProp(json, 'TrustPilot', 'Font', 'Style', 'UnitInput', 'Size'),
                                        starsWidth: parseFindProp(json, 'TrustPilot', 'Rating Stars', 'Settings', 'UnitInput', 'Width'),
                                        filterStars: parseFindProp(json, 'TrustPilot', 'Filters', 'Settings', 'Select', 'Star Rating'),
                                        tpBuidData: parseFindProp(json, '', '', '', '', '', 'tpBuidData'),
                                        tpData: parseFindProp(json, '', '', '', '', '', 'tpData'),
                                        tpIMG: parseFindProp(json, '', '', '', '', '', 'tpIMG'),
                                        tpReviews: parseFindProp(json, '', '', '', '', '', 'tpReviews'),
                                        tpScore: parseFindProp(json, '', '', '', '', '', 'tpScore')
                                    }
                                    parentObj.push({ id: 102, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "Powered by MPZ" || json.editableTabs[0] == "Powered by Transpond") {
                                    var props = {
                                    }
                                    parentObj.push({ id: 82, type: "element", props: props })
                                }
                            }
                            if (isForm) {
                                //form inputs
                                if (json.editableTabs[0] == "Input") {
                                    if (json.version == "5.0.0") {
                                        var props = {
                                            borderColor: parseFindProp(json, '', '', '', '', '', 'borderColor'),
                                            borderRadius: parseFindProp(json, '', '', '', '', '', 'borderRadius'),
                                            borderStyle: parseFindProp(json, '', '', '', '', '', 'borderStyle'),
                                            borderWidth: parseFindProp(json, '', '', '', '', '', 'borderWidth'),
                                            inputField: parseFindProp(json, '', '', '', '', '', 'inputField'),
                                            inputType: parseFindProp(json, '', '', '', '', '', 'inputType'),
                                            marginBottom: parseFindProp(json, '', '', '', '', '', 'marginBottom'),
                                            marginTop: parseFindProp(json, '', '', '', '', '', 'marginTop'),
                                            paddingBottom: parseFindProp(json, '', '', '', '', '', 'paddingBottom'),
                                            paddingLeft: parseFindProp(json, '', '', '', '', '', 'paddingLeft'),
                                            paddingRight: parseFindProp(json, '', '', '', '', '', 'paddingRight'),
                                            paddingTop: parseFindProp(json, '', '', '', '', '', 'paddingTop'),
                                            placeHolder: parseFindProp(json, '', '', '', '', '', 'placeHolder'),
                                            required: parseFindProp(json, '', '', '', '', '', 'required')
                                        }
                                        parentObj.push({ id: 68, type: "element", props: props })
                                    }
                                    else {
                                        //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType
                                        var props = {
                                            borderColor: parseFindProp(json, 'Input', 'Border', 'Style', 'ColorPicker', 'Color'),
                                            borderRadius: parseFindProp(json, 'Input', 'Border', 'Style', 'UnitInput', 'Radius'),
                                            borderStyle: parseFindProp(json, 'Input', 'Border', 'Style', 'Select', 'Style'),
                                            borderWidth: parseFindProp(json, 'Input', 'Border', 'Style', 'UnitInput', 'Width'),
                                            inputField: parseFindProp(json, '', '', '', '', '', 'inputField'),
                                            inputType: parseFindProp(json, '', '', '', '', '', 'inputType'),
                                            marginBottom: parseFindProp(json, 'Input', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                            marginTop: parseFindProp(json, 'Input', 'Margin', 'Style', 'UnitInput', 'Top'),
                                            paddingBottom: parseFindProp(json, 'Input', 'Padding', 'Style', 'UnitInput', 'Bottom'),
                                            paddingLeft: parseFindProp(json, 'Input', 'Padding', 'Style', 'UnitInput', 'Left'),
                                            paddingRight: parseFindProp(json, 'Input', 'Padding', 'Style', 'UnitInput', 'Right'),
                                            paddingTop: parseFindProp(json, 'Input', 'Padding', 'Style', 'UnitInput', 'Top'),
                                            placeHolder: parseFindProp(json, 'Input', 'Custom Field', 'Input', 'Text', 'Placeholder'),
                                            required: parseFindProp(json, '', '', '', '', '', 'required')
                                        }
                                        parentObj.push({ id: 68, type: "element", props: props })
                                    }
                                }
                                if (json.editableTabs[0] == "Button") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType
                                    var props = {
                                        outerAlignment: parseFindProp(json, 'Button', 'Alignment', 'Style', 'HorizontalAlign', 'Alignment'),
                                        buttonContent: parseFindProp(json, 'Button', '', 'Button', 'Text', 'Text'),
                                        textContent: parseFindProp(json, undefined, undefined, undefined, 'Wysiwyg', undefined),
                                        buttonBgColor: parseFindProp(json, 'Button', 'Colors', 'Style', 'ColorPicker', 'Background'),
                                        buttonBorderColor: parseFindProp(json, 'Button', 'Border', 'Style', 'ColorPicker', 'Color'),
                                        buttonBorderRadius: parseFindProp(json, 'Button', 'Border', 'Style', 'UnitInput', 'Radius'),
                                        buttonBorderStyle: parseFindProp(json, 'Button', 'Border', 'Style', 'Select', 'Style'),
                                        buttonBorderWidth: parseFindProp(json, 'Button', 'Border', 'Style', 'UnitInput', 'Width'),
                                        buttonTextColor: parseFindProp(json, 'Button', 'Font', 'Style', 'ColorPicker', 'Text'),
                                        buttonMarginBottom: parseFindProp(json, 'Button', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                        buttonMarginTop: parseFindProp(json, 'Button', 'Margin', 'Style', 'UnitInput', 'Top'),
                                        buttonPaddingTop: parseFindProp(json, 'Button', 'Padding', 'Style', 'UnitInput', 'Top'),
                                        buttonPaddingBottom: parseFindProp(json, 'Button', 'Padding', 'Style', 'UnitInput', 'Bottom'),
                                        buttonPaddingLeft: parseFindProp(json, 'Button', 'Padding', 'Style', 'UnitInput', 'Left'),
                                        buttonPaddingRight: parseFindProp(json, 'Button', 'Padding', 'Style', 'UnitInput', 'Right'),
                                        buttonWidth: parseFindProp(json, 'Button', 'Size', 'Style', 'UnitInput', 'Width')

                                    }
                                    parentObj.push({ id: 70, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "Text") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType
                                    var props = {
                                        textContent: parseFindProp(json, undefined, undefined, undefined, 'Wysiwyg', undefined),
                                        marginBottom: parseFindProp(json, 'Text', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                        marginLeft: parseFindProp(json, 'Text', 'Margin', 'Style', 'UnitInput', 'Left'),
                                        marginRight: parseFindProp(json, 'Text', 'Margin', 'Style', 'UnitInput', 'Right'),
                                        marginTop: parseFindProp(json, 'Text', 'Margin', 'Style', 'UnitInput', 'Top'),
                                        paddingBottom: parseFindProp(json, 'Text', 'Padding', 'Style', 'UnitInput', 'Bottom'),
                                        paddingLeft: parseFindProp(json, 'Text', 'Padding', 'Style', 'UnitInput', 'Left'),
                                        paddingRight: parseFindProp(json, 'Text', 'Padding', 'Style', 'UnitInput', 'Right'),
                                        paddingTop: parseFindProp(json, 'Text', 'Padding', 'Style', 'UnitInput', 'Top'),

                                    }
                                    parentObj.push({ id: 72, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "Image") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType
                                    var props = {
                                        imageSource: parseFindProp(json, 'Image', 'Image', 'Image', 'ImagePicker', 'Image'),
                                        marginBottom: parseFindProp(json, 'Image', 'Margin', 'Image', 'UnitInput', 'Bottom'),
                                        marginLeft: parseFindProp(json, 'Image', 'Margin', 'Image', 'UnitInput', 'Left'),
                                        marginRight: parseFindProp(json, 'Image', 'Margin', 'Image', 'UnitInput', 'Right'),
                                        marginTop: parseFindProp(json, 'Image', 'Margin', 'Image', 'UnitInput', 'Top'),
                                        paddingBottom: parseFindProp(json, 'Image', 'Padding', 'Image', 'UnitInput', 'Bottom'),
                                        paddingLeft: parseFindProp(json, 'Image', 'Padding', 'Image', 'UnitInput', 'Left'),
                                        paddingRight: parseFindProp(json, 'Image', 'Padding', 'Image', 'UnitInput', 'Right'),
                                        paddingTop: parseFindProp(json, 'Image', 'Padding', 'Image', 'UnitInput', 'Top'),
                                        textAlign: parseFindProp(json, 'Image', 'Alignment', 'Image', 'HorizontalAlign', 'Alignment'),
                                        title: parseFindProp(json, 'Image', 'Image', 'Image', 'Text', 'Hover Text'),
                                        width: parseFindProp(json, 'Image', 'Image', 'Image', 'UnitInput', 'Width')
                                    }
                                    parentObj.push({ id: 75, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "Error") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType
                                    var props = {
                                        textContent: parseFindProp(json, undefined, undefined, undefined, 'Wysiwyg', undefined),
                                        backgroundColor: parseFindProp(json, 'Error', 'Colors', 'Style', 'ColorPicker', 'Background Color'),
                                        color: parseFindProp(json, 'Error', 'Colors', 'Style', 'ColorPicker', 'Text Color'),
                                        paddingBottom: parseFindProp(json, 'Error', 'Padding', 'Style', 'UnitInput', 'Bottom'),
                                        paddingLeft: parseFindProp(json, 'Error', 'Padding', 'Style', 'UnitInput', 'Left'),
                                        paddingRight: parseFindProp(json, 'Error', 'Padding', 'Style', 'UnitInput', 'Right'),
                                        paddingTop: parseFindProp(json, 'Error', 'Padding', 'Style', 'UnitInput', 'Top'),
                                        textAlign: parseFindProp(json, 'Error', 'Alignment', 'Style', 'HorizontalAlign', 'Alignment'),
                                        marginBottom: parseFindProp(json, 'Error', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                        marginLeft: parseFindProp(json, 'Error', 'Margin', 'Style', 'UnitInput', 'Left'),
                                        marginRight: parseFindProp(json, 'Error', 'Margin', 'Style', 'UnitInput', 'Right'),
                                        marginTop: parseFindProp(json, 'Error', 'Margin', 'Style', 'UnitInput', 'Top'),
                                    }
                                    parentObj.push({ id: 92, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "CheckBox") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType
                                    var props = {
                                        inputField: parseFindProp(json, 'CheckBox', 'CheckBox', 'CheckBox', 'CustomField', 'Field'),
                                        marginBottom: parseFindProp(json, 'CheckBox', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                        marginTop: parseFindProp(json, 'CheckBox', 'Margin', 'Style', 'UnitInput', 'Top'),
                                        marginLeft: parseFindProp(json, 'CheckBox', 'Margin', 'Style', 'UnitInput', 'Left'),
                                        marginRight: parseFindProp(json, 'CheckBox', 'Margin', 'Style', 'UnitInput', 'Right'),
                                        content: parseFindProp(json, 'CheckBox', 'CheckBox', 'CheckBox', 'Text', 'Text'),
                                        value: parseFindProp(json, 'CheckBox', 'CheckBox', 'CheckBox', 'Text', 'Checked Value'),
                                        borderColor: parseFindProp(json, 'CheckBox', 'Border', 'Style', 'ColorPicker', 'Color'),
                                        borderRadius: parseFindProp(json, 'CheckBox', 'Border', 'Style', 'UnitInput', 'Radius'),
                                        borderStyle: parseFindProp(json, 'CheckBox', 'Border', 'Style', 'Select', 'Style'),
                                        borderWidth: parseFindProp(json, 'CheckBox', 'Border', 'Style', 'UnitInput', 'Width'),
                                        required: parseFindProp(json, 'CheckBox', 'CheckBox', 'CheckBox', 'Select', 'Required Field'),
                                    }
                                    parentObj.push({ id: 94, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "Select") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType
                                    var props = {
                                        inputField: parseFindProp(json, 'Select', 'Select', 'Select', 'CustomField', 'Field'),
                                        placeHolder: parseFindProp(json, 'Select', 'Select', 'Select', 'Text', 'Placeholder'),
                                        content: parseFindProp(json, 'Select', 'Select', 'Select', 'ArraySelect', 'Values'),
                                        required: parseFindProp(json, 'Select', 'Select', 'Select', 'Select', 'Is Required'),
                                        borderColor: parseFindProp(json, 'Select', 'Border', 'Style', 'ColorPicker', 'Color'),
                                        borderStyle: parseFindProp(json, 'Select', 'Border', 'Style', 'Select', 'Style'),
                                        borderWidth: parseFindProp(json, 'Select', 'Border', 'Style', 'UnitInput', 'Width'),
                                        borderRadius: parseFindProp(json, 'Select', 'Border', 'Style', 'UnitInput', 'Radius'),
                                        paddingTop: parseFindProp(json, 'Select', 'Padding', 'Style', 'UnitInput', 'Top'),
                                        paddingBottom: parseFindProp(json, 'Select', 'Padding', 'Style', 'UnitInput', 'Bottom'),
                                        paddingLeft: parseFindProp(json, 'Select', 'Padding', 'Style', 'UnitInput', 'Left'),
                                        paddingRight: parseFindProp(json, 'Select', 'Padding', 'Style', 'UnitInput', 'Right'),
                                        marginTop: parseFindProp(json, 'Select', 'Margin', 'Style', 'UnitInput', 'Top'),
                                        marginBottom: parseFindProp(json, 'Select', 'Margin', 'Style', 'UnitInput', 'Bottom')
                                    }
                                    parentObj.push({ id: 95, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "HiddenField") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType
                                    var props = {
                                        fieldType: parseFindProp(json, 'HiddenField', 'Custom Field', 'HiddenField', 'CustomField', 'Field'),
                                        value: parseFindProp(json, 'HiddenField', 'Custom Field', 'HiddenField', 'Text', 'Field Value')
                                    }
                                    parentObj.push({ id: 96, type: "element", props: props })
                                }
                                if (json.editableTabs[0] == "Radio") {
                                    //newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType
                                    var props = {
                                        inputField: parseFindProp(json, 'Radio', 'Radio', 'Radio', 'CustomField', 'Field'),
                                        content: parseFindProp(json, 'Radio', 'Radio', 'Radio', 'ArraySelect', 'Values'),
                                        required: parseFindProp(json, 'Radio', 'Radio', 'Radio', 'Select', 'Required'),
                                        innerMarginBottom: parseFindProp(json, 'Radio', 'Line Space', 'Style', 'UnitInput', 'Bottom'),
                                        marginBottom: parseFindProp(json, 'Radio', 'Margin', 'Style', 'UnitInput', 'Bottom'),
                                        marginTop: parseFindProp(json, 'Radio', 'Margin', 'Style', 'UnitInput', 'Top'),
                                        marginLeft: parseFindProp(json, 'Radio', 'Margin', 'Style', 'UnitInput', 'Left'),
                                        marginRight: parseFindProp(json, 'Radio', 'Margin', 'Style', 'UnitInput', 'Right'),
                                    }
                                    parentObj.push({ id: 97, type: "element", props: props })
                                }
                            }

                        }

                    }

                }

            })
        }

        function parseFindProp(newJson, editableTab, category, categoryGroup, editableType, label, directLocalVariable, elementType) {
            var theVal = "";
            if (directLocalVariable) {
                if (newJson.localVariables[directLocalVariable] && newJson.localVariables[directLocalVariable].value) {
                    theVal = newJson.localVariables[directLocalVariable].value;
                }
            }
            else {
                Object.keys(newJson).forEach((theEl) => {
                    if (Array.isArray(newJson[theEl])) {
                        newJson[theEl].forEach((thing) => {
                            var tmpVal = parseFindProp(thing, editableTab, category, categoryGroup, editableType, label, undefined, elementType)
                            if (tmpVal != "") theVal = tmpVal;
                        })
                    } else {
                        if (typeof newJson[theEl] == "object") {
                            var tmpVal = parseFindProp(newJson[theEl], editableTab, category, categoryGroup, editableType, label, undefined, elementType)
                            if (tmpVal != "") theVal = tmpVal;
                        }
                    }
                    if (elementType) {
                        if (newJson[theEl] && newJson[theEl].type && newJson[theEl].type == elementType && newJson[theEl].value) {
                            theVal = newJson[theEl].value;
                        }
                    }
                    else if (newJson[theEl].editable) {

                        if (!editableTab || newJson[theEl].editableTab == editableTab) {

                            if (!category || newJson[theEl].category == category) {

                                if (!categoryGroup || newJson[theEl].categoryGroup == categoryGroup) {
                                    if (!editableType || newJson[theEl].editableType == editableType) {
                                        if (!label || newJson[theEl].label == label) {
                                            theVal = newJson[theEl].value;
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
            }
            return theVal
        }

        function parseGlobalVars(thisJson) {
            var gOut = {};
            if (thisJson && thisJson.globalVariables) {
                Object.keys(thisJson.globalVariables).forEach(theKey => {
                    gOut[theKey] = thisJson.globalVariables[theKey].value;
                })
            }
            return gOut;
        }
        var theObj = [];

        var globVars = parseGlobalVars(templateJson);
        parseObject(templateJson);

        var finalObj = {
            globalVariables: globVars,
            structure: theObj,
            version: 3
        };

        if (templateJson && templateJson.elements && templateJson.elements[0].elements && templateJson.elements[0].elements[0].content && templateJson.elements[0].elements[0].content["@media screen and (max-width: 768px)"] && templateJson.elements[0].elements[0].content["@media screen and (max-width: 768px)"][".responsiveTd"]) {
            templateJson.elements[0].elements[0].content["@media screen and (max-width: 768px)"][".responsiveTd"]["padding-left"] = { "value": "0px !important" };
            templateJson.elements[0].elements[0].content["@media screen and (max-width: 768px)"][".responsiveTd"]["padding-right"] = { "value": "0px !important" };

        }
        return finalObj;
    }

    static createGUID() {
        var rnd = Math.round(Math.random() * 9999999999)
        return rnd;
    }
    static convertToFullJson(templateRawJson, bodyTemplate, layouts, blocks, elementsData) {


        //global variables first
        if (templateRawJson.globalVariables) {
            Object.keys(templateRawJson.globalVariables).forEach(theVar => {

                if (templateRawJson.globalVariables.hasOwnProperty(theVar) && bodyTemplate.globalVariables && bodyTemplate.globalVariables.hasOwnProperty(theVar)) {
                    if (Array.isArray(templateRawJson.globalVariables[theVar])) {
                        bodyTemplate.globalVariables[theVar] = templateRawJson.globalVariables[theVar];
                    }
                    else {
                        bodyTemplate.globalVariables[theVar].value = templateRawJson.globalVariables[theVar];
                    }
                }
            })
        }
        var finalObject = [];
        templateRawJson.structure.forEach(holder => {
            //lookup layout

            var ourLayout = layouts.find(theL => theL.content.ComponentId == holder.id)
            if (!ourLayout) { return }
            ourLayout = JSON.parse(JSON.stringify(ourLayout.content))
            //replace localvariables
            Object.keys(ourLayout.localVariables).forEach(localVar => {
                if (holder.props.hasOwnProperty(localVar)) {
                    if (!ourLayout.localVariables[localVar].hasOwnProperty("value")) {
                        ourLayout.localVariables[localVar] = { value: "" };
                    }
                    ourLayout.localVariables[localVar].value = holder.props[localVar];
                }
            })

            //loop element columns
            if (holder.elements) {
                holder.elements.forEach((column, columnIndex) => {
                    //loop elements in columns
                    var colEls = [];
                    column.forEach(element => {

                        var ourElement = elementsData.find(theEl => theEl.content.ComponentId == element.id);
                        if (!ourElement) {
                            return;
                        }
                        ourElement = JSON.parse(JSON.stringify(ourElement.content));

                        //FIX ELEMENTS HERE THAT HAVE HAD HAD THEIR LOCALVARIABLES ETC CHANGED TO TIDY UP OLD TEMPLATES
                        if (element.id == "98") {
                            //COUNTDOWN TIMER CHANGED FROM date and time to dateTime
                            if (element.props) {
                                if (element.props && element.props.imageUrl && element.props.imageUrl.indexOf("date={_localVariables_.countdownDate}&time={_localVariables_.countdownTime}") > -1) {
                                    element.props.imageUrl = element.props.imageUrl.replace("date={_localVariables_.countdownDate}&time={_localVariables_.countdownTime}", "datetime={_localVariables_.countdownDatetime}")
                                }
                                if (!element.props.countdownDatetime) {
                                    element.props.countdownDatetime = element.props.countdownDate + " " + element.props.countdownTime;
                                }
                            }
                        }

                        //replace localvariables
                        Object.keys(ourElement.localVariables).forEach(localVar => {
                            if (element.props.hasOwnProperty(localVar) && ourElement.localVariables.hasOwnProperty(localVar)) {
                                ourElement.localVariables[localVar].value = element.props[localVar];
                            }
                        })

                        function searchAndReplaceVariables(theObj, ourProps) {
                            Object.keys(theObj).forEach(ourObj => {
                                if (Array.isArray(theObj[ourObj])) {
                                    theObj[ourObj].forEach(subObj => {
                                        searchAndReplaceVariables(subObj, ourProps);
                                    })
                                }
                                else if (theObj[ourObj].hasOwnProperty("editableVariable")) {
                                    if (ourProps[theObj[ourObj].editableVariable]) {

                                        theObj[ourObj].value = ourProps[theObj[ourObj].editableVariable];
                                    }
                                }
                            })

                        }
                        //look for in element variables such as textContent, htmlContent in the "editableVariable" field
                        searchAndReplaceVariables(ourElement, element.props)

                        ourElement = JSON.stringify(ourElement);
                        ourElement = JSON.parse(ourElement.replace(/\[RANDOMNO\]/g, Math.floor(Math.random() * 999999)))
                        //give each element a temp Unique Id for referencing on stage later (Cos components are constantly remounted)
                        ourElement.tuuid = this.createGUID();

                        colEls.push(ourElement);
                    })


                    //find the column holder
                    function findElementHolder(newEls) {
                        newEls.forEach(tEl => {
                            if (tEl.hasOwnProperty("elementHolder") && tEl.elementHolder == columnIndex) {
                                tEl.elements = colEls;
                            }
                            else if (tEl.elements) {
                                findElementHolder(tEl.elements)
                            }
                        })
                    }
                    findElementHolder(ourLayout.elements)
                })
            }
            finalObject.push(ourLayout);
        })

        bodyTemplate = JSON.stringify(bodyTemplate);
        var finalObjectTxt = JSON.stringify(finalObject);

        //removing weird stuff
        finalObjectTxt = finalObjectTxt.replace(/\$\&.*?\;/g, '$ ')
        bodyTemplate = bodyTemplate.replace(/"_ELEMENTSINSERTHERE_"/g, finalObjectTxt)
        bodyTemplate = JSON.parse(bodyTemplate);

        return bodyTemplate;
    }

    static convertToSimpleJson(templateJson) {

        var finalObj = {
            globalVariables: {},
            structure: [],
            version: 3
        };
        //get global variables
        if (templateJson && templateJson.globalVariables) {
            Object.keys(templateJson.globalVariables).forEach(theKey => {
                var gVal;
                if (Array.isArray(templateJson.globalVariables[theKey])) {
                    gVal = templateJson.globalVariables[theKey]
                }
                else {
                    gVal = templateJson.globalVariables[theKey].value
                }
                finalObj.globalVariables[theKey] = gVal;
            })
        }
        //get structure

        function parseStructure(theEls, theStruct) {
            if (Array.isArray(theEls)) {
                theEls.forEach(theEl => {
                    parseStructure(theEl, theStruct);
                })
            } else if (typeof theEls == "object") {
                if (theEls.hasOwnProperty("ComponentType")) {
                    if (theEls.ComponentType == "Layout" || theEls.ComponentType == "FormLayout") {
                        //find props
                        var props = {};
                        Object.keys(theEls.localVariables).forEach(theP => {
                            props[theP] = theEls.localVariables[theP].value;
                        })
                        theStruct.push({
                            type: "holder",
                            elements: parseStructure(theEls.elements, []),
                            id: theEls.ComponentId,
                            props: props
                        })

                    }
                    if (theEls.ComponentType == "Element" || theEls.ComponentType == "FormElement") {
                        //find localvariables
                        var props = {};
                        Object.keys(theEls.localVariables).forEach(theP => {
                            props[theP] = theEls.localVariables[theP].value;
                        })
                        //find any editableVariable hidden in the element tree
                        function findEditableVariable(elements) {
                            if (Array.isArray(elements)) {
                                elements.forEach(theEl => {
                                    findEditableVariable(theEl)
                                })
                            } else if (typeof elements == "object") {
                                Object.keys(elements).forEach(theEl => {
                                    if (Array.isArray(elements[theEl])) {
                                        findEditableVariable(elements[theEl])
                                    }
                                    if (elements[theEl].hasOwnProperty("editableVariable")) {
                                        props[elements[theEl].editableVariable] = elements[theEl].value;
                                    }
                                })
                            }

                        }
                        findEditableVariable(theEls.elements)

                        theStruct.push({
                            type: "element",
                            id: theEls.ComponentId,
                            props: props
                        })
                    }
                }
                else if (theEls.hasOwnProperty("dragDrop")) {
                    if (theEls.dragDrop == "canvasColumn") {
                        theStruct.push(parseStructure(theEls.elements, []))
                    }
                }
                else {
                    if (theEls.hasOwnProperty("elements")) {
                        parseStructure(theEls.elements, theStruct);
                    }
                }
            }
            return theStruct;
        }
        finalObj.structure = parseStructure(templateJson.elements, finalObj.structure)
        return finalObj;

    }
    static findFontsReturnScripts(theSrc) {
        var fontsFound = [];

        if (typeof theSrc == "object") {
            theSrc = JSON.stringify(theSrc);
        }
        theSrc = theSrc.toLowerCase().replace(/ /g, '');

        for (var theIndex in this.fontArray) {
            var theFont = this.fontArray[theIndex].value.toLowerCase().replace(/ /g, '').split(",")[0];

            var theFont2 = theFont.replace(/\'/g, '\"');
            var theFont3 = theFont.replace(/\'/g, '');
            var theFont4 = theFont.replace(/\'/g, '\"');
            var theFont5 = theFont.replace(/\'/g, '\&quot\;');

            if (theSrc.indexOf(theFont) > 0 || theSrc.indexOf(theFont2) > 0 || theSrc.indexOf(theFont3) > 0 || theSrc.indexOf(theFont4) > 0 || theSrc.indexOf(theFont5) > 0) {
                fontsFound.push(this.fontArray[theIndex])
            }
        }
        return fontsFound;
    }
    static findLinksHardCodeColor(theSrc) {
        if (this.globalVars.linkColor && this.globalVars.linkColor.value) { } else { return }

        const regex = /<a(.*?)>(.*?)<\/a>/gm;
        let m;
        while ((m = regex.exec(theSrc)) !== null) {

            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            var linkColor = this.globalVars.linkColor.value
            m.forEach((match, groupIndex) => {
                //search sub nodes for a hard coded text color (ie a span has a color within the link) and use this instead of global link color
                if (groupIndex == 2) {
                    if (match.indexOf('style="') > 0) {
                        var theLinkProps = match.split(`style="`)[1].split(`"`)[0].split(`;`);
                        theLinkProps.forEach(theProp => {
                            var subProp = theProp.split(":")[0].trim();
                            if (subProp == "color") {
                                linkColor = theProp.split(":")[1].trim();
                            }
                        })
                    }
                }
            })

            //now loop all links, check for style color and if doesnt exist inject it
            m.forEach((match, groupIndex) => {

                if (groupIndex == 1) {
                    match = "<a" + match + ">";
                    var newMatch = match;
                    if (newMatch.indexOf('style="') < 1) {
                        newMatch = newMatch.replace('>', ' style="color: ' + linkColor + ';">')
                    }
                    else if (newMatch.indexOf('style="') > 0) {

                        var theLinkProps = newMatch.split(`style="`)[1].split(`"`)[0].split(`;`);
                        var foundColor = false;
                        theLinkProps.forEach(theProp => {
                            var subProp = theProp.split(":")[0].trim();
                            if (subProp == "color") {
                                foundColor = true;
                            }
                        })
                        if (!foundColor) newMatch = newMatch.replace('style="', 'style="color: ' + linkColor + '; ')
                    }
                    match = match.replace('[', '\[').replace(']', '\]')
                    match = this.escapeRegExp(match);
                    theSrc = theSrc.replace(new RegExp(match, 'g'), newMatch);
                }

            });
        }

        return theSrc
    }

    static escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    static findHeadingsAndInline(theSrc) {

        /*
        function doReplacements(tagType) {
            var tmpH1Props = [{ name: "margin", value: "0 0 20px 0" }];
            Object.keys(this.globalVars).forEach(gK => {
                if (gK.indexOf(tagType) == 0) {
                    var ourK = this.globalVars[gK];
                    if (ourK && ourK.value) {
                        var newVal = "";
                        switch (gK) {
                            case tagType + "Color": {
                                newVal = "color";
                                break;
                            }
                            case tagType + "Font": {
                                newVal = "font-family";
                                break;
                            }
                            case tagType + "FontSize": {
                                newVal = "font-size";
                                break;
                            }
                            case tagType + "FontStyle": {
                                newVal = "font-style";
                                break;
                            }
                            case tagType + "TextAlign": {
                                newVal = "text-align";
                                break;
                            }
                            case tagType + "FontWeight": {
                                newVal = "font-weight";
                                break;
                            }
                            case tagType + "LineHeight": {
                                newVal = "line-height";
                                break;
                            }
                            case tagType + "LetterSpacing": {
                                newVal = "letter-spacing";
                                break;
                            }
                        }
    
                        tmpH1Props.push({ name: newVal, value: ourK.value });
                    }
                }
            })
    
    
            const regex = /<h1(.*?)>(.*?)<\/h1>/gm;
            let m;
            while ((m = regex.exec(theSrc)) !== null) {
    
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
    
                var tmpH1PropsTmp = JSON.parse(JSON.stringify(tmpH1Props))
    
    
                var match1IsStyle = false;
                var foundStyle = false;
                if (m[0].indexOf('style=') > -1) foundStyle = true;
                m.forEach((match, groupIndex) => {
                    if (groupIndex == 1) {
                        if (match.indexOf("style=") > -1) {
                            match1IsStyle = true;
                            foundStyle = true;
                            var tmp = match.replace(`style="`, "").replace(`;"`, ";")
                            tmp = tmp.split(";");
                            tmp.forEach(t => {
                                if (!t || !t.length || t.indexOf(":") < 0) return;
                                var tName = t.split(":")[0].trim();
                                var tVal = t.split(":")[1].trim();
                                var foundV = tmpH1PropsTmp.find(t1 => t1.name.toLowerCase() == tName.toLowerCase().trim());
                                if (foundV) {
                                    foundV.value = tVal;
                                }
                                else {
                                    tmpH1PropsTmp.push({ name: tName, value: tVal })
                                }
                            })
                        }
                    }
                })
    
    
                if (match1IsStyle) {
                    var styleVars = "";
                    tmpH1PropsTmp.forEach(t => {
                        styleVars += t.name + ": " + t.value + "; "
                    })
                    var finalStyle = ` style="${styleVars}"`
                    theSrc = theSrc.replace(new RegExp(m[1], 'g'), finalStyle);
                }
                else if (foundStyle) {
                    var styleVars = "";
                    tmpH1PropsTmp.forEach(t => {
    
                        styleVars += t.name + ": " + t.value + "; "
                    })
                    var finalStyle = ` style="${styleVars}"`
                    theSrc = theSrc.replace(new RegExp("<h1>", 'g'), `<h1 ${finalStyle}>`);
                }
    
            }
        }
        doReplacements("h1");
        doReplacements("h2");
        doReplacements("h3");
        doReplacements("h4");
        */
        return theSrc
    }
    static lookupLocalVariables(localVariables, variableName, looped, forStage) {


        var finalVal = variableName;
        if (!localVariables || Object.keys(localVariables).length < 1) {

            return finalVal;
        }
        //first see if we have nested _localvariables with the string ie "Hello there {_localVariables_.firstName} how are you?"
        const regex = /{_localVariables.+?}/gm;
        let m;
        while ((m = regex.exec(variableName)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            m.forEach((match, groupIndex) => {
                var matchNew = match.replace('{', '').replace('}', '')
                finalVal = variableName.replace(match, this.lookupLocalVariables(localVariables, matchNew, false, forStage));
            });
        }

        variableName = variableName.replace('_localVariables_.', '')
        var variablePath = "value"
        if (variableName.indexOf("->") > 0) {
            var variableData = variableName.split("->");
            variableName = variableData[0];
            for (var theVar in variableData) {
                if (theVar > 0) {
                    variablePath += "." + variableData[theVar]
                }
            }
        }
        else {

            if (!forStage && localVariables[variableName] && typeof (localVariables[variableName]) == "object" && localVariables[variableName].hasOwnProperty('finalValue')) {

                variablePath = "finalValue"
            }
        }


        if (localVariables[variableName]) {
            //finalVal = localVariables[variableName][variablePath];
            finalVal = lodashGet(localVariables[variableName], variablePath)
        }
        if (typeof finalVal == "string" && finalVal.indexOf('_localVariables_.') >= 0) {
            finalVal = this.lookupLocalVariables(localVariables, finalVal, true, forStage);
        }
        if (finalVal && finalVal.length && finalVal.indexOf("global_.") > 0) {
            if (this.globalVars[finalVal.split("_.")[1]]) {
                finalVal = this.globalVars[finalVal.split("_.")[1]].value;
            }
        }

        if (localVariables[variableName] && localVariables[variableName].postProcess) {
            var postProcess = localVariables[variableName].postProcess;
            if (postProcess && postProcess.indexOf("global_.") > 0) {
                postProcess = this.replaceGlobalVariables(postProcess);
            }
            finalVal = new Function(postProcess.replace("[VALUE]", finalVal))();
        }

        if (finalVal == "true") finalVal = true;
        if (finalVal == "false") finalVal = false;


        return finalVal;
    }
    static replaceGlobalVariable(value) {
        if (value.indexOf("global_.") > 0) {
            if (this.globalVars[value.split("_.")[1]]) {
                value = this.globalVars[value.split("_.")[1]].value;
            }
        }
        return value;
    }
    static replaceGlobalVariables(value) {
        const regex = /{_global_.+?}/gm;
        let m;
        while ((m = regex.exec(value)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            m.forEach((match, groupIndex) => {
                var matchNew = match.replace('{', '').replace('}', '').replace('_global_.', '')
                if (matchNew && matchNew.length) {
                    value = value.replace(match, this.globalVars[matchNew].value);
                }
            });
        }

        return value;
    }
    static replaceLocalVariables(element, localVariables) {
        // processes json and replaces local variables(Used on the div editable)
        Object.keys(element).forEach((el) => {
            if (typeof element[el] == "object") {
                element[el] = this.replaceLocalVariables(element[el], localVariables)
            }
            else {
                if (typeof element[el] == "string" && element[el].indexOf("localVariables_") > 0) {
                    var vName = element[el].replace(/_localVariables_\./g, '');
                    element[el] = localVariables[vName].value;
                }
            }
        })
        return element;
    }
    static generatePropsForStage(theProps, globalVariables, parentType, localVariables) {
        //pass in a props object and we will return props to pass back to the object
        var tableProps = {};
        Object.keys(theProps).map((theProp, index) => {
            if (theProp === "style") {
                var tableStyle = {};
                if (tableProps[theProp]) {
                    tableStyle = tableProps[theProp]
                }
                Object.keys(theProps[theProp]).map(theStyle => {
                    var newStyleName = camelCase(theStyle);
                    var theVal = theProps[theProp][theStyle].value;
                    if (theVal && theVal.indexOf("global_.") > 0) {

                        if (globalVariables[theVal.split("_.")[1]]) {
                            theVal = globalVariables[theVal.split("_.")[1]].value;
                        }
                        else {
                            console.log("MISSING GLOBAL", theVal)
                        }
                    }
                    if (theVal && theVal.indexOf("localVariables_.") > 0) {
                        theVal = this.lookupLocalVariables(localVariables, theVal, false, true)
                    }

                    switch (newStyleName) {
                        case "backgroundImage":
                            theVal = "url(" + theVal + ")"
                            break;
                    }

                    tableStyle[newStyleName] = theVal;
                })
                tableProps[theProp] = tableStyle;
            }
            else {
                var theVal = theProps[theProp].value;
                if (theVal && theVal.indexOf("global_.") > 0) {

                    if (globalVariables[theVal.split("_.")[1]]) {
                        theVal = globalVariables[theVal.split("_.")[1]].value;
                    }
                    else {
                        console.log("MISSING GLOBAL", theVal)
                    }
                }
                if (theVal && theVal.indexOf("localVariables_.") > 0) {
                    theVal = this.lookupLocalVariables(localVariables, theVal, false, true)
                }

                switch (theProp) {
                    case "align":
                        if (!tableProps.style) {
                            tableProps.style = {};
                        }
                        if (parentType === "table") {
                            if (theVal === "left") {
                            }
                            if (theVal === "center") {
                                tableProps.style.margin = "0 auto"
                            }
                            if (theVal === "right") {
                                tableProps.style.marginRight = "0"
                                tableProps.style.marginLeft = "auto"
                            }
                        }
                        else {
                            tableProps.style.textAlign = theVal
                        }
                        break;
                    case "valign":
                        if (!tableProps.style) {
                            tableProps.style = {};
                        }
                        tableProps.style.verticalAlign = theVal
                        break;
                    case "width":
                        tableProps[theProp] = theVal.replace('px', '');
                        break;
                    case "height":
                        tableProps[theProp] = theVal.replace('px', '');
                        break;
                    case "class":
                        tableProps.className = theVal;
                        break;
                    case "colspan":
                        tableProps.colSpan = theVal;
                        break;
                    default:

                        tableProps[theProp] = theVal
                }

                // if toggle then it will add/remove the property accordingly, example: "required" on input fields
                if (theProps[theProp].toggle || theProps[theProp].toggle == "true") {
                    if (theVal == false || theVal == "false" || !theVal || theVal == undefined) {
                        delete tableProps[theProp]
                    }
                }

            }
        }
        )
        return tableProps;
    }
    static componentPolyfill(templateJson, layouts, blocks, elements) {
        //need to polyfill here eventually
        return templateJson
    }
    static checkForExternalData(localVariables) {
        var localVarsL = JSON.parse(JSON.stringify(localVariables))
        //checks to see if external data needs loading
        var self = this;
        var isUpdated = false;
        var promises = [];
        let errors = [];
        if (localVarsL) {
            Object.keys(localVarsL).forEach(lvKey => {
                if (localVarsL[lvKey].dataUrl) {
                    isUpdated = true;
                    var dataUrl = localVarsL[lvKey].dataUrl;
                    if (dataUrl.indexOf("_localVariables_") > -1) {
                        dataUrl = this.lookupLocalVariables(localVariables, dataUrl, false, true)
                    }

                    promises.push(
                        axios.get(dataUrl).then(res => {
                            if (localVarsL[lvKey] && localVarsL[lvKey].value) localVarsL[lvKey].value = res.data
                        }).catch(err => {
                            errors.push("errorRetrievingData");
                        })
                    )

                }
            })
        }
        return Promise.all(promises).then(() => {
            return { localVars: localVarsL, updated: isUpdated, errors };
        })
    }
    static movingUpDown(movingPath, targetPath) {
        var direction = 0;
        var movingDat = movingPath.split(".");
        var targetDat = targetPath.split(".")

        for (var each in movingDat) {
            var thisLine = Number(movingDat[each].split("]")[0].split("[")[1]);
            if (targetDat[each]) {
                var newLine = Number(targetDat[each].split("]")[0].split("[")[1]);
                if (newLine > thisLine) {
                    direction = 1;
                    return direction;
                }
                if (newLine < thisLine) {
                    direction = -1;
                    return direction;
                }
            }
        }
        return direction;
    }
    static getTemplateColours(globalVariables) {
        var colours = Object.keys(globalVariables).filter((theProp, index) => {
            var thisProp = globalVariables[theProp];
            return thisProp.editableType === "ColorPicker" && thisProp.value != ""
        }).map(theProp => {
            var thisProp = globalVariables[theProp];
            return thisProp.value;
        })
        colours.push("transparent")

        colours = colours.filter(function (item, pos) {
            return colours.indexOf(item) == pos;
        })

        return colours;
    }

    static getParentColor(elementsData, path) {
        let selectedColor = "#ffffff";
        try {
            if (elementsData && path) {
                let pathParts = path.replace(/content/gi, "");
                pathParts = pathParts.split("].");
                let foundColors = [];
                for (let i = pathParts.length - 1; i >= 0; i--) {
                    let tmpPath = pathParts.slice(0, i + 1).join("].") + "]";
                    let foundEl = lodashGet(elementsData, tmpPath)

                    if (i == pathParts.length - 1) {
                        //first element so check its children
                        function checkChildNotes(e) {
                            if (e && e.elements && e.elements.length) {
                                e.elements.forEach(el => {
                                    if (el && el.props && el.props.style && el.props.style["background-color"]) {
                                        foundColors.push(el.props.style["background-color"].value);
                                    }
                                    checkChildNotes(el);
                                })
                            }
                        }
                        checkChildNotes(foundEl);
                    }

                    let hasStyle = foundEl?.props?.style;
                    if (hasStyle) {
                        if (hasStyle["background-color"]) {
                            foundColors.push(hasStyle["background-color"].value);
                        }
                    }

                    if (foundEl && foundEl.localVariables) {
                        foundColors = foundColors.map(c => {
                            if (c && c.indexOf("_local") > -1) {
                                c = c.replace("_localVariables_.", "")
                                if (foundEl.localVariables[c] && foundEl.localVariables[c].value) {
                                    return foundEl.localVariables[c].value
                                }
                            }
                            return c;
                        })
                    }
                }

                foundColors = foundColors.map(c => {
                    if (c.indexOf("_global_") > -1) {
                        let globalVar = c.replace("_global_.", "")
                        const globalVars = elementsData.globalVariables;
                        if (globalVars && globalVars[globalVar]) {
                            return globalVars[globalVar].value;
                        }
                    }
                    return c;
                }).filter(f => f != "transparent" && f != "")

                if (foundColors && foundColors.length) {
                    selectedColor = foundColors[0];
                }
            }
        }
        catch (err) { }
        return selectedColor;
    }

    static checkForErrors(incomingJson, unused, isForm, formFiles = []) {

        var finalJson = cloneDeep(incomingJson);

        function checkForErrorsLoop(thisJson) {

            if (Array.isArray(thisJson)) {
                thisJson.forEach((ele, index) => {
                    if (typeof ele === "object") {
                        checkForErrorsLoop(ele);
                    }
                })
            }
            else {
                Object.keys(thisJson).forEach(ele => {

                    if (ele == "ComponentId") {
                        if (thisJson[ele] == "70") foundButton = true;
                        if (thisJson[ele] == "92") foundErrorMessage = true;
                    }

                    if (typeof thisJson[ele] == "object" && thisJson[ele]) {
                        try {
                            if (thisJson[ele].value?.indexOf('[FORMFILE_') === 0) {
                                const formId = parseInt(thisJson[ele].value.replace('[FORMFILE_', '').replace(']', ''))
                                const index = remainingFiles.findIndex(f => f.id === formId)
                                if (index > -1) remainingFiles.splice(index, 1)
                            }
                        }
                        catch (err) { }
                        if (thisJson[ele].valueBlankWillError) {
                            if (!thisJson[ele].value) {
                                //found a required value that is blank
                                thisJson[ele].valueRequiredError = "Please select a value";
                                foundError = true;
                                errorMessage = "You must complete the errors (Marked in Red)";
                                errorType = 1;
                            }
                            else {
                                if (thisJson[ele].value == "emailAddress") {
                                    foundEmailAddress = true;
                                }
                            }
                        }

                        checkForErrorsLoop(thisJson[ele]);
                    }
                })
            }
        }

        var foundError = false;
        var errorMessage = "";
        var errorType = 0;
        var foundEmailAddress = false;
        var foundButton = false;
        var foundErrorMessage = false;
        let remainingFiles = formFiles.slice()

        checkForErrorsLoop(finalJson);
        if (!foundError && isForm && !foundEmailAddress) {
            foundError = true;
            errorMessage = "You have not created a form field for Email Address";
            errorType = 2;
        }
        if (!foundError && isForm && !foundButton) {
            foundError = true;
            errorMessage = "You must drag a Submit button onto the stage";
            errorType = 2;
        }
        if (!foundError && isForm && !foundErrorMessage) {
            foundError = true;
            errorMessage = "You must drag an Error Message Holder onto the stage";
            errorType = 2;
        }

        if (!foundError && remainingFiles.length) {
            foundError = true;
            errorMessage = "You must drag all Gated Content buttons onto the stage";
            errorType = 2;
        }


        return { newJson: finalJson, hasError: foundError, errorMessage: errorMessage, errorType: errorType }

    }
    static replaceGlobalOptions(theJson, fromTheme) {
        var state = store.getState();
        var thisOpts = JSON.stringify(theJson);

        var thisFormattedCustomerAddress = i18n.t('company:addressFormat');
        if (thisFormattedCustomerAddress == "addressFormat" || !thisFormattedCustomerAddress.length) thisFormattedCustomerAddress = "[ACCOUNTNAME]. [ADDRESS1] [ADDRESS2], [ADDRESS3], [CITY] [COUNTY]. [POSTCODE]. [COUNTRY].";

        thisFormattedCustomerAddress = thisFormattedCustomerAddress.replace(/\[ACCOUNTNAME\]/g, state.accountMaster.accountMaster.accountName ? state.accountMaster.accountMaster.accountName : "");
        thisFormattedCustomerAddress = thisFormattedCustomerAddress.replace(/\[ADDRESS1\]/g, state.accountMaster.accountMaster.address1 ? state.accountMaster.accountMaster.address1 : "");
        thisFormattedCustomerAddress = thisFormattedCustomerAddress.replace(/\[ADDRESS2\]/g, state.accountMaster.accountMaster.address2 ? state.accountMaster.accountMaster.address2 : "");
        thisFormattedCustomerAddress = thisFormattedCustomerAddress.replace(/\[ADDRESS3\]/g, state.accountMaster.accountMaster.address3 ? state.accountMaster.accountMaster.address3 : "");
        thisFormattedCustomerAddress = thisFormattedCustomerAddress.replace(/\[CITY\]/g, state.accountMaster.accountMaster.city ? state.accountMaster.accountMaster.city : "");
        thisFormattedCustomerAddress = thisFormattedCustomerAddress.replace(/\[COUNTY\]/g, state.accountMaster.accountMaster.county ? state.accountMaster.accountMaster.county : "");
        thisFormattedCustomerAddress = thisFormattedCustomerAddress.replace(/\[COUNTRY\]/g, state.accountMaster.accountMaster.country ? state.accountMaster.accountMaster.country : "");
        thisFormattedCustomerAddress = thisFormattedCustomerAddress.replace(/\[POSTCODE\]/g, state.accountMaster.accountMaster.postCode ? state.accountMaster.accountMaster.postCode : "");

        thisFormattedCustomerAddress = thisFormattedCustomerAddress.replace(/, ,/g, ",")
        thisFormattedCustomerAddress = thisFormattedCustomerAddress.replace(/\. \./g, ".")
        thisFormattedCustomerAddress = thisFormattedCustomerAddress.split(',').map(a => a.trim()).filter(a => !!a).join(', ')
        thisFormattedCustomerAddress = thisFormattedCustomerAddress.split('.').map(a => a.trim()).filter(a => !!a).join('. ')

        thisOpts = thisOpts.replace(/\[REPOENDPOINT\]/g, SiteVars.repoEndPoint)
        thisOpts = thisOpts.replace(/\[APIENDPOINT\]/g, state.siteMaster.trackingDomain)
        thisOpts = thisOpts.replace(/\[SITEURL\]/g, location.protocol + '//' + location.host)
        thisOpts = thisOpts.replace(/\[SITENAME\]/g, state.siteMaster.siteName)
        // thisOpts = thisOpts.replace(/\[ACCOUNTNAME\]/g, state.accountMaster.accountMaster.accountName)
        // thisOpts = thisOpts.replace(/\{{ACCOUNTADDRESS}}\]/g, thisFormattedCustomerAddress)
        thisOpts = thisOpts.replace(/\[ACCOUNTNAME\]/g, state.accountMaster.accountMaster.accountName)
        thisOpts = thisOpts.replace(/\[FULLACCOUNTADDRESS\]/g, thisFormattedCustomerAddress)
        thisOpts = thisOpts.replace(/\[ACCOUNTEMAIL\]/g, state.user.userData.emailAddress)
        thisOpts = thisOpts.replace(/\[ACCOUNTWEBSITE\]/g, state.accountMaster.accountMaster.websiteAddress)
        thisOpts = thisOpts.replace(/\[ACCOUNTFACEBOOK\]/g, state.accountMaster.accountMaster.facebookAddress)
        thisOpts = thisOpts.replace(/\[ACCOUNTTWITTER\]/g, state.accountMaster.accountMaster.twitterAddress)
        thisOpts = thisOpts.replace(/\[ACCOUNTINSTAGRAM\]/g, state.accountMaster.accountMaster.instagramAddress)
        thisOpts = thisOpts.replace(/\[ACCOUNTLINKEDIN\]/g, state.accountMaster.accountMaster.linkedInAddress)
        thisOpts = thisOpts.replace(/\[ACCOUNTYOUTUBE\]/g, state.accountMaster.accountMaster.youTubeAddress)
        thisOpts = thisOpts.replace(/\[ACCOUNTTIKTOK\]/g, state.accountMaster.accountMaster.tikTokAddress)
        thisOpts = thisOpts.replace(/\[RSSADDRESS\]/g, state.accountMaster.accountMaster.rssAddress)
        thisOpts = thisOpts.replace(/\[DEFAULTCTAADDRESS\]/g, state.accountMaster.accountMaster.defaultCTAAddress)


        thisOpts = thisOpts.replace(/\"\[OPTIONS.HORIZONTALALIGN\]\"/g, JSON.stringify(this.horizontalAlign))
        thisOpts = thisOpts.replace(/\"\[OPTIONS.VERTICALALIGN\]\"/g, JSON.stringify(this.verticalAlign))
        thisOpts = thisOpts.replace(/\"\[OPTIONS.BORDERSTYLE\]\"/g, JSON.stringify(this.borderStyle))
        thisOpts = thisOpts.replace(/\"\[OPTIONS.INPUTTYPES\]\"/g, JSON.stringify(this.inputTypes))
        thisOpts = thisOpts.replace(/\"\[OPTIONS.MERGETAGS\]\"/g, JSON.stringify(this.mergeTags))


        //console.log(thisOpts)
        const regex = /{i18n_(.*?)}/gm;

        let m;

        while ((m = regex.exec(thisOpts)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            // The result can be accessed through the `m`-variable.
            m.forEach((match, groupIndex) => {
                if (groupIndex == 1) {
                    var regRe = new RegExp('\{i18n_' + match + '\}', 'gmi');
                    thisOpts = thisOpts.replace(regRe, i18n.t(match))
                }
            });
        }

        // thisOpts = thisOpts.replace(/\"\[OPTIONS.FONTARRAY\]\"/g, JSON.stringify(this.fontArray))



        thisOpts = thisOpts.replace(/\[\{\"label\":\"Arial\",\"value\":\"\'Arial\'\"\},\{\"label\":\"Arial Black\",\"value\":\"\'Arial Black\'\"\},\{\"label\":\"Comic Sans MS\",\"value\":\"\' Comic Sans MS\'\"\},\{\"label\":\"Impact\",\"value\":\"\'Impact\'\"\},\{\"label\":\"Lucida Sans Unicode\",\"value\":\"\'Lucida Sans Unicode\'\"\},\{\"label\":\"Impact\",\"value\":\"\'Impact\'\"\},\{\"label\":\"Tahoma\",\"value\":\"\'Tahoma\'\"\},\{\"label\":\"Trebuchet MS\",\"value\":\"\'Trebuchet MS\'\"\},\{\"label\":\"Verdana\",\"value\":\"\'Verdana\'\"\}\]/g, '\"[OPTIONS.FONTARRAY]\"');

        if (state.accountMaster.accountMaster.options && state.accountMaster.accountMaster.options.brandAnalyzer) {
            if (state.accountMaster.accountMaster.options.brandAnalyzer.colours && Array.isArray(state.accountMaster.accountMaster.options.brandAnalyzer.colours)) {
                if (state.accountMaster.accountMaster.options.brandAnalyzer.colours.length > 0 && state.accountMaster.accountMaster.options.brandAnalyzer.colours[0]) {
                    thisOpts = thisOpts.replace(/\[BRAND_COLOR1\]/g, state.accountMaster.accountMaster.options.brandAnalyzer.colours[0])
                }
                else {
                    thisOpts = thisOpts.replace(/\[BRAND_COLOR1\]/g, "#000000")
                }
                if (state.accountMaster.accountMaster.options.brandAnalyzer.colours.length > 1 && state.accountMaster.accountMaster.options.brandAnalyzer.colours[1]) {
                    thisOpts = thisOpts.replace(/\[BRAND_COLOR2\]/g, state.accountMaster.accountMaster.options.brandAnalyzer.colours[1])
                }
                else {
                    thisOpts = thisOpts.replace(/\[BRAND_COLOR2\]/g, "#000000")
                }
            }

            if (state.accountMaster.accountMaster.options.brandAnalyzer.font) {
                let tmpFont = state.accountMaster.accountMaster.options.brandAnalyzer.font;
                tmpFont = tmpFont.replace(/\"/g, "\\\"")
                thisOpts = thisOpts.replace(/\[BRAND_FONT\]/g, tmpFont)
            }
            if (state.accountMaster.accountMaster.options.brandAnalyzer.logo && state.accountMaster.accountMaster.options.brandAnalyzer.logo.url) {
                thisOpts = thisOpts.replace(/\[BRAND_LOGO\]/g, state.accountMaster.accountMaster.options.brandAnalyzer.logo.url)
            }
        }

        // console.log(thisOpts)
        theJson = JSON.parse(thisOpts);

        if (fromTheme) {
            //check for soft coded overrides in themes, like social stuff
            function checkForOverRides(thisJson) {

                if (Array.isArray(thisJson)) {
                    thisJson.forEach((ele, index) => {
                        if (typeof ele === "object") {
                            checkForOverRides(ele);
                        }
                    })
                }
                else {
                    Object.keys(thisJson).forEach(ele => {

                        if (typeof thisJson[ele] == "object" && thisJson[ele]) {

                            if (state.accountMaster.accountMaster.youTubeAddress && state.accountMaster.accountMaster.youTubeAddress.length) {
                                if (thisJson[ele].youtubeEnabled && typeof thisJson[ele].youtubeEnabled != "object") {
                                    thisJson[ele].youtubeEnabled = "true";
                                }
                                if (thisJson[ele].youtubeLinkUrl && typeof thisJson[ele].youtubeLinkUrl != "object") {
                                    thisJson[ele].youtubeLinkUrl = state.accountMaster.accountMaster.youTubeAddress;
                                }
                            }
                            else {
                                if (thisJson[ele].youtubeEnabled && typeof thisJson[ele].youtubeEnabled != "object") {
                                    thisJson[ele].youtubeEnabled = "false";
                                }
                                if (thisJson[ele].youtubeLinkUrl && typeof thisJson[ele].youtubeLinkUrl != "object") {
                                    thisJson[ele].youtubeLinkUrl = "";
                                }
                            }

                            if (state.accountMaster.accountMaster.linkedInAddress && state.accountMaster.accountMaster.linkedInAddress.length) {
                                if (thisJson[ele].linkedinEnabled && typeof thisJson[ele].linkedinEnabled != "object") {
                                    thisJson[ele].linkedinEnabled = "true";
                                }
                                if (thisJson[ele].linkedinLinkUrl && typeof thisJson[ele].linkedinLinkUrl != "object") {
                                    thisJson[ele].linkedinLinkUrl = state.accountMaster.accountMaster.linkedInAddress;
                                }
                            } else {
                                if (thisJson[ele].linkedinEnabled && typeof thisJson[ele].linkedinEnabled != "object") {
                                    thisJson[ele].linkedinEnabled = "false";
                                }
                                if (thisJson[ele].linkedinLinkUrl && typeof thisJson[ele].linkedinLinkUrl != "object") {
                                    thisJson[ele].linkedinLinkUrl = "";
                                }
                            }

                            if (state.accountMaster.accountMaster.instagramAddress && state.accountMaster.accountMaster.instagramAddress.length) {
                                if (thisJson[ele].instagramEnabled && typeof thisJson[ele].instagramEnabled != "object") {
                                    thisJson[ele].instagramEnabled = "true";
                                }
                                if (thisJson[ele].instagramLinkUrl && typeof thisJson[ele].instagramLinkUrl != "object") {
                                    thisJson[ele].instagramLinkUrl = state.accountMaster.accountMaster.instagramAddress;
                                }
                            } else {
                                if (thisJson[ele].instagramEnabled && typeof thisJson[ele].instagramEnabled != "object") {
                                    thisJson[ele].instagramEnabled = "false";
                                }
                                if (thisJson[ele].instagramLinkUrl && typeof thisJson[ele].instagramLinkUrl != "object") {
                                    thisJson[ele].instagramLinkUrl = "";
                                }
                            }

                            if (state.accountMaster.accountMaster.twitterAddress && state.accountMaster.accountMaster.twitterAddress.length) {
                                if (thisJson[ele].twitterEnabled && typeof thisJson[ele].twitterEnabled != "object") {
                                    thisJson[ele].twitterEnabled = "true";
                                }
                                if (thisJson[ele].twitterLinkUrl && typeof thisJson[ele].twitterLinkUrl != "object") {
                                    thisJson[ele].twitterLinkUrl = state.accountMaster.accountMaster.twitterAddress;
                                }
                            } else {
                                if (thisJson[ele].twitterEnabled && typeof thisJson[ele].twitterEnabled != "object") {
                                    thisJson[ele].twitterEnabled = "false";
                                }
                                if (thisJson[ele].twitterLinkUrl && typeof thisJson[ele].twitterLinkUrl != "object") {
                                    thisJson[ele].twitterLinkUrl = "";
                                }
                            }

                            if (state.accountMaster.accountMaster.facebookAddress && state.accountMaster.accountMaster.facebookAddress.length) {
                                if (thisJson[ele].facebookEnabled && typeof thisJson[ele].facebookEnabled != "object") {
                                    thisJson[ele].facebookEnabled = "true";
                                }
                                if (thisJson[ele].faceBookLinkUrl && typeof thisJson[ele].faceBookLinkUrl != "object") {
                                    thisJson[ele].faceBookLinkUrl = state.accountMaster.accountMaster.facebookAddress;
                                }
                            } else {
                                if (thisJson[ele].facebookEnabled && typeof thisJson[ele].facebookEnabled != "object") {
                                    thisJson[ele].facebookEnabled = "false";
                                }
                                if (thisJson[ele].faceBookLinkUrl && typeof thisJson[ele].faceBookLinkUrl != "object") {
                                    thisJson[ele].faceBookLinkUrl = "";
                                }
                            }

                            if (state.accountMaster.accountMaster.tikTokAddress && state.accountMaster.accountMaster.tikTokAddress.length) {
                                if (thisJson[ele].tikTokEnabled && typeof thisJson[ele].tikTokEnabled != "object") {
                                    thisJson[ele].tikTokEnabled = "true";
                                }
                                if (thisJson[ele].tikTokLinkUrl && typeof thisJson[ele].tikTokLinkUrl != "object") {
                                    thisJson[ele].tikTokLinkUrl = state.accountMaster.accountMaster.tikTokAddress;
                                }
                            } else {
                                if (thisJson[ele].tikTokEnabled && typeof thisJson[ele].tikTokEnabled != "object") {
                                    thisJson[ele].tikTokEnabled = "false";
                                }
                                if (thisJson[ele].tikTokLinkUrl && typeof thisJson[ele].tikTokLinkUrl != "object") {
                                    thisJson[ele].tikTokLinkUrl = "";
                                }
                            }

                            if (state.accountMaster.accountMaster.primaryEmailAddress && state.accountMaster.accountMaster.primaryEmailAddress.length) {
                                if (thisJson[ele].emailEnabled && typeof thisJson[ele].emailEnabled != "object") {
                                    thisJson[ele].emailEnabled = "true";
                                }
                                if (thisJson[ele].emailLinkUrl && typeof thisJson[ele].emailLinkUrl != "object") {
                                    thisJson[ele].emailLinkUrl = state.accountMaster.accountMaster.primaryEmailAddress;
                                    if (thisJson[ele].emailLinkUrl && thisJson[ele].emailLinkUrl.indexOf('mailto:') !== 0) {
                                        thisJson[ele].emailLinkUrl = 'mailto:' + thisJson[ele].emailLinkUrl;
                                    }
                                }
                                if (thisJson[ele].emailLinkUrl && thisJson[ele].emailLinkUrl.indexOf && thisJson[ele].emailLinkUrl.indexOf('mailto:') !== 0) {
                                    thisJson[ele].emailLinkUrl = 'mailto:' + thisJson[ele].emailLinkUrl;
                                }
                            } else {
                                if (thisJson[ele].emailEnabled && typeof thisJson[ele].emailEnabled != "object") {
                                    thisJson[ele].emailEnabled = "false";
                                }
                                if (thisJson[ele].emailLinkUrl && typeof thisJson[ele].emailLinkUrl != "object") {
                                    thisJson[ele].emailLinkUrl = "";
                                }
                            }

                            if (state.accountMaster.accountMaster.websiteAddress && state.accountMaster.accountMaster.websiteAddress.length) {
                                if (thisJson[ele].websiteEnabled && typeof thisJson[ele].websiteEnabled != "object") {
                                    thisJson[ele].websiteEnabled = "true";
                                }
                                if (thisJson[ele].websiteLinkUrl && typeof thisJson[ele].websiteLinkUrl != "object") {
                                    thisJson[ele].websiteLinkUrl = state.accountMaster.accountMaster.websiteAddress;
                                }

                            } else {
                                if (thisJson[ele].websiteEnabled && typeof thisJson[ele].websiteEnabled != "object") {
                                    thisJson[ele].websiteEnabled = "false";
                                }
                                if (thisJson[ele].websiteLinkUrl && typeof thisJson[ele].websiteLinkUrl != "object") {
                                    thisJson[ele].websiteLinkUrl = "";
                                }
                            }

                            if (state.accountMaster.accountMaster.rssAddress && state.accountMaster.accountMaster.rssAddress.length) {

                                if (thisJson[ele].rssFeed && thisJson[ele].rssFeed.value && thisJson[ele].rssFeed.value.rssUrl) {
                                    thisJson[ele].rssFeed.value.rssUrl = state.accountMaster.accountMaster.rssAddress;
                                }

                            } else {

                                if (thisJson[ele].rssFeed && thisJson[ele].rssFeed.value && thisJson[ele].rssFeed.value.rssUrl) {
                                    thisJson[ele].rssFeed.value.rssUrl = "";
                                }

                            }

                            checkForOverRides(thisJson[ele]);
                        }

                    })
                }
            }
            checkForOverRides(theJson);

        }

        return theJson;
    }
    static replaceDefaultColors(theJson) {

        var state = store.getState();
        if (!state.accountMaster.accountMaster.options || !state.accountMaster.accountMaster.options.templateDefaults) return theJson;

        var overrides = state.accountMaster.accountMaster.options.templateDefaults;
        if (theJson.globalVariables) {
            if (theJson.globalVariables.bodyBg && overrides.background && overrides.background.value) {
                theJson.globalVariables.bodyBg = overrides.background.value;
            }
            if (theJson.globalVariables.bodyFont && overrides.bodyFont && overrides.bodyFont.value) {
                theJson.globalVariables.bodyFont = overrides.bodyFont.value;
            }
            if (theJson.globalVariables.bodyFontColor && overrides.bodyFontColor && overrides.bodyFontColor.value) {
                theJson.globalVariables.bodyFontColor = overrides.bodyFontColor.value;
            }
            if (theJson.globalVariables.buttonBackgroundColor && overrides.buttonBackground && overrides.buttonBackground.value) {
                theJson.globalVariables.buttonBackgroundColor = overrides.buttonBackground.value;
            }
            if (theJson.globalVariables.buttonFont && overrides.buttonFont && overrides.buttonFont.value) {
                theJson.globalVariables.buttonFont = overrides.buttonFont.value;
            }
            if (theJson.globalVariables.buttonFontColor && overrides.buttonFontColor && overrides.buttonFontColor.value) {
                theJson.globalVariables.buttonFontColor = overrides.buttonFontColor.value;
            }
            if (theJson.globalVariables.h1Color && overrides.h1Color && overrides.h1Color.value) {
                theJson.globalVariables.h1Color = overrides.h1Color.value;
            }
            if (theJson.globalVariables.h1Font && overrides.h1Font && overrides.h1Font.value) {
                theJson.globalVariables.h1Font = overrides.h1Font.value;
            }
            if (theJson.globalVariables.elementHolderForegroundColor && theJson.globalVariables.elementHolderForegroundColor.value && overrides.innerBackground && overrides.innerBackground.value) {
                theJson.globalVariables.elementHolderForegroundColor.value = overrides.innerBackground.value;
            }

            theJson = JSON.stringify(theJson);

            if (overrides.background && overrides.background.value) theJson = theJson.replace(/\{\{__bgColor\}\}/ig, overrides.background.value);
            if (overrides.bodyFont && overrides.bodyFont.value) theJson = theJson.replace(/\{\{__bodyFont\}\}/ig, overrides.bodyFont.value);
            if (overrides.bodyFontColor && overrides.bodyFontColor.value) theJson = theJson.replace(/\{\{__bodyFontColor\}\}/ig, overrides.bodyFontColor.value);
            if (overrides.buttonBackground && overrides.buttonBackground.value) theJson = theJson.replace(/\{\{__buttonBackgroundColor\}\}/ig, overrides.buttonBackground.value);
            if (overrides.buttonFont && overrides.buttonFont.value) theJson = theJson.replace(/\{\{__buttonFont\}\}/ig, overrides.buttonFont.value);
            if (overrides.buttonFontColor && overrides.buttonFontColor.value) theJson = theJson.replace(/\{\{__buttonFontColor\}\}/ig, overrides.buttonFontColor.value);
            if (overrides.h1Color && overrides.h1Color.value) theJson = theJson.replace(/\{\{__h1Color\}\}/ig, overrides.h1Color.value);
            if (overrides.h1Font && overrides.h1Font.value) theJson = theJson.replace(/\{\{__h1Font\}\}/ig, overrides.h1Font.value);
            if (overrides.elementHolderForegroundColor && overrides.elementHolderForegroundColor.value) theJson = theJson.replace(/\{\{__elementHolderForegroundColor\}\}/ig, overrides.elementHolderForegroundColor.value);

            theJson = JSON.parse(theJson)
        }

        return theJson;
    }

    static lookupVariables(theOpt) {
        switch (theOpt) {
            case "[OPTIONS.HORIZONTALALIGN]":
                return this.horizontalAlign;
            case "[OPTIONS.VERTICALALIGN]":
                return this.verticalAlign;
            case "[OPTIONS.BORDERSTYLE]":
                return this.borderStyle;
            case "[OPTIONS.FONTARRAY]":
                return this.fontArray;
            case "[OPTIONS.INPUTTYPES]":
                return this.inputTypes;
            case "[OPTIONS.MERGETAGS]":
                return this.mergeTags;

            //can also replace hardcoded borderstyle, etc etc here later
            case `[{ "label": "Arial", "value": "'Arial'" }, { "label": "Arial Black", "value": "'Arial Black'" }, { "label": "Comic Sans MS", "value": "' Comic Sans MS'" }, { "label": "Impact", "value": "'Impact'" }, { "label": "Lucida Sans Unicode", "value": "'Lucida Sans Unicode'" }, { "label": "Impact", "value": "'Impact'" }, { "label": "Tahoma", "value": "'Tahoma'" }, { "label": "Trebuchet MS", "value": "'Trebuchet MS'" }, { "label": "Verdana", "value": "'Verdana'" }]`:
                return this.fontArray;
            case "[OPTIONS.FORMFILES]":
                const apiUrl = SiteVars.getConnString()
                return this.formFiles.filter(f => !!f.FileManagerFile || !!f.url).map((f) => ({ label: f.url || f.FileManagerFile.friendlyName, value: `[FORMFILE_${f.id}]` }));
        }

    }
    static checkAndInsertFreeBanner(theJson) {

        return new Promise(function (resolve, reject) {

            var theStore = store.getState();
            if (theStore.siteMaster.siteId != "1") {
                resolve(theJson);
            }
            var accType = theStore.accountMaster.accountMaster.accountType;
            var theJsonNew = cloneDeep(theJson);


            var location = theJsonNew.elements[1].elements[0];
            if (!theJson.mpzTemplateType || !theJson.mpzTemplateType == "raw") {
                location = theJsonNew.elements[1].elements[0].elements[0].elements[0].elements
            }
            if (accType == "free") {
                //check and add the banner if its not there 
                try {
                    var theNodes = location;
                    var lastIndex = theNodes.length - 1;
                    if (!theNodes[lastIndex].freeFooter) {
                        axios.get('/templateComponent/component/86').then(data => {
                            var theTemplateData = data.data.TemplateComponent.options.content;
                            location.push(theTemplateData)
                            resolve(theJsonNew);
                        })
                    } else {
                        resolve(theJsonNew);
                    }
                } catch (err) {
                    resolve(theJsonNew)
                }
            } else {
                //check and remove the banner if it is there
                var theNodes = location;
                var lastIndex = theNodes.length - 1;
                if (theNodes.length && theNodes[lastIndex].freeFooter) {
                    location.splice(lastIndex, 1);
                    resolve(theJsonNew);
                } else {
                    resolve(theJsonNew);
                }
            }
        })
    }
    static generatePollSlide(pollSlide, localVariables, globalVariables, fromStage) {


        if (!pollSlide || !pollSlide.value) return;
        var html = "";

        var textStyle = "";
        var headerStyle = "";

        var alignment = "center";
        if (localVariables && localVariables.innerAlign && localVariables.innerAlign.value) alignment = this.replaceGlobalVariable(localVariables.innerAlign.value);
        textStyle += `text-align: ${alignment}; `;
        headerStyle += `text-align: ${alignment}; `;

        var textColor = "white";
        if (localVariables && localVariables.textColor && localVariables.textColor.value) textColor = this.replaceGlobalVariable(localVariables.textColor.value);
        textStyle += `color: ${textColor}; `;

        var textFontFamily = "arial";
        if (localVariables && localVariables.textFontFamily && localVariables.textFontFamily.value) textFontFamily = this.replaceGlobalVariable(localVariables.textFontFamily.value);
        textStyle += `font-family: ${textFontFamily}; `;

        var textWeight = "normal";
        if (globalVariables && globalVariables.bodyFontFontWeight && globalVariables.bodyFontFontWeight.value) textWeight = globalVariables.bodyFontFontWeight.value;
        textStyle += `font-weight: ${textWeight}; `;

        var textHeight = "normal";
        if (localVariables && localVariables.textLineHeight && localVariables.textLineHeight.value) textHeight = this.replaceGlobalVariable(localVariables.textLineHeight.value);
        textStyle += `line-height: ${textHeight}; `;


        var headerTextColor = globalVariables.h2Color.value;
        if (localVariables && localVariables.headerTextColor && localVariables.headerTextColor.value) headerTextColor = this.replaceGlobalVariable(localVariables.headerTextColor.value);
        headerStyle += `color: ${headerTextColor}; `;

        var headerTextFontFamily = globalVariables.h2FontStyle.value;
        if (localVariables && localVariables.headerTextFontFamily && localVariables.headerTextFontFamily.value) headerTextFontFamily = this.replaceGlobalVariable(localVariables.headerTextFontFamily.value);
        headerStyle += `font-family: ${headerTextFontFamily}; `;

        var headerTextWeight = globalVariables.h2FontWeight.value;
        if (localVariables && localVariables.headerTextWeight && localVariables.headerTextWeight.value) headerTextWeight = this.replaceGlobalVariable(localVariables.headerTextWeight.value);
        headerStyle += `font-weight: ${headerTextWeight}; `;

        var headerTextHeight = globalVariables.h2LineHeight.value;
        if (localVariables && localVariables.headerTextLineHeight && localVariables.headerTextLineHeight.value) headerTextHeight = this.replaceGlobalVariable(localVariables.headerTextLineHeight.value);
        headerStyle += `line-height: ${headerTextHeight}; `;

        var buttonStyle = "text-align: center; font-weight: bold";
        var buttonHolderStyle = "margin: 0px auto; border-style: none; border-width: 0px; text-size-adjust: 100%;"
        var buttonInnerHolderStyle = "text-align: center; padding: 10px 20px; text-size-adjust: 100%; vertical-align: middle;";
        var buttonFontColor = textColor;

        if (globalVariables) {

            if (globalVariables.buttonFont && globalVariables.buttonFont.value) buttonStyle += `font-family: ${globalVariables.buttonFont.value}; `;
            if (globalVariables.buttonFontSize && globalVariables.buttonFontSize.value) buttonStyle += `font-size: ${globalVariables.buttonFontSize.value}; `;
            if (globalVariables.buttonFontColor && globalVariables.buttonFontColor.value) buttonStyle += `color: ${globalVariables.buttonFontColor.value}; `;

            if (globalVariables.buttonBackgroundColor && globalVariables.buttonBackgroundColor.value) buttonHolderStyle += `background-color: ${globalVariables.buttonBackgroundColor.value}; `;
            if (globalVariables.buttonBorderRadius && globalVariables.buttonBorderRadius.value) buttonHolderStyle += `border-radius: ${globalVariables.buttonBorderRadius.value}; `;
            if (globalVariables.buttonFontColor && globalVariables.buttonFontColor.value) buttonFontColor = globalVariables.buttonFontColor.value;

        }
        var pollId = "0";
        if (localVariables.pollId && localVariables.pollId.value) pollId = localVariables.pollId.value;
        var buttonHtml = `<table width="0" cellpadding="0" cellspacing="0" align="${alignment}">
                    <tr>
                        <td align="center">
                            <table cellpadding="0" cellspacing="0" style="${buttonHolderStyle}">
                                <tr>
                                    <td style="${buttonInnerHolderStyle}">
                                        <span class="showInEmail" style="${buttonStyle}">
                                            <a href="{{{__PREVIEW_URL}}}&poll=${pollId}" style="color: ${buttonFontColor}; text-decoration: none;">___BUTTONTEXT___</a>
                                        </span>
                                        <div class="hideInEmail" data-button="next" style="display: none;">
                                            ___BUTTONTEXT___
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table> `;

        switch (pollSlide.value) {
            case "intro":
                {
                    html = `<h2 style="${headerStyle}"> ${pollSlide.question.value}</h2> `
                    html += `<p style="${textStyle}"> ${pollSlide.description.value}</p> `
                    var tmpButtonHtml = buttonHtml.replace(/___BUTTONTEXT___/g, pollSlide.button.value)
                    tmpButtonHtml = tmpButtonHtml.replace(/\{\{\{__PREVIEW_URL\}\}\}\&poll=/g, "{{{__PREVIEW_URL}}}&pollSkip=1&poll=")
                    html += tmpButtonHtml;

                    break;
                }
            case "satisfaction":
                {

                    html = `<h2 style="${headerStyle}"> ${pollSlide.question.value}</h2> `
                    html += `<p style="${textStyle}"> ${pollSlide.description.value}</p> `
                    html += `<table cellpadding="0" cellspacing="0" align="${alignment}"> `;
                    var colourMono = "colour";
                    if (pollSlide.color.value == "mono") colourMono = "mono";
                    if (pollSlide.shape.value == "smiley") {
                        html += `<tr>`
                        html += `<td>`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="satisfaction-clicker" data-rating="0"><img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/smileys/${colourMono}/0.png" border="0"/></a> `;
                        html += `</td> `;
                        html += `<td width="10"></td> `;
                        html += `<td>`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="satisfaction-clicker" data-rating="2"><img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/smileys/${colourMono}/1.png" border="0" /></a> `;
                        html += `</td> `;
                        html += `<td width="10"></td> `;
                        html += `<td>`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="satisfaction-clicker" data-rating="4"><img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/smileys/${colourMono}/2.png" border="0" /></a> `;
                        html += `</td> `;
                        html += `<td width="10"></td> `;
                        html += `<td>`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="satisfaction-clicker" data-rating="6"><img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/smileys/${colourMono}/3.png" border="0" /></a> `;
                        html += `</td> `;
                        html += `<td width="10"></td> `;
                        html += `<td>`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="satisfaction-clicker" data-rating="9"><img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/smileys/${colourMono}/4.png" border="0" /></a> `;
                        html += `</td> `;
                        html += `</tr> `
                    }
                    if (pollSlide.shape.value == "star") {
                        html += `<tr>`
                        html += `<td>`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="satisfaction-clicker" data-rating="0"><img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/stars/${colourMono}/0.png" border="0" /></a> `;
                        html += `</td> `;
                        html += `<td width="10"></td> `;
                        html += `<td>`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="satisfaction-clicker" data-rating="2"><img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/stars/${colourMono}/1.png" border="0" /></a> `;
                        html += `</td> `;
                        html += `<td width="10"></td> `;
                        html += `<td>`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="satisfaction-clicker" data-rating="4"> <img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/stars/${colourMono}/0.png" border="0" /></a > `;
                        html += `</td> `;
                        html += `<td width="10"></td> `;
                        html += `<td>`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="satisfaction-clicker" data-rating="6"><img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/stars/${colourMono}/0.png" border="0" /></a > `;
                        html += `</td> `;
                        html += `<td width="10"></td> `;
                        html += `<td>`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="satisfaction-clicker" data-rating="9"><img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/stars/${colourMono}/0.png" border="0" /></a > `;
                        html += `</td> `;
                        html += `</tr> `
                    }
                    if (pollSlide.negativeLabel.value && pollSlide.positiveLabel.value) {
                        html += `<tr>`
                        html += `<td align="left" colspan="5"><span style="${textStyle}">${pollSlide.negativeLabel.value}</span></td> `

                        html += `<td align="right" colspan="5"><span style="${textStyle}">${pollSlide.positiveLabel.value}</span></td> `
                        html += `</tr> `
                    }

                    html += `</table> `
                    break;
                }

            case "promoter":
                {
                    html = `<h2 style="${headerStyle}">${pollSlide.question.value}</h2> `
                    html += `<p style="${textStyle}">${pollSlide.description.value}</p>`
                    html += `<table cellpadding="0" cellspacing="0" border="0" width="100%"><tr>`;


                    var tmpCnt = 0;
                    while (tmpCnt < 10) {
                        html += `<td style="text-align: center;" width="10%">`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}">
                        <table width="100%" cellpadding="0" cellspacing="0" style="border: 2px solid ${textColor}; border-radius: 10px;">
                            <tr>
                                <td style="text-align: center; height: 45px;">
                                    <a href="{{{__PREVIEW_URL}}}&poll=${pollId}" style="line-height: 45px; text-decoration: none; color: ${textColor};" class="promoter-clicker" data-rating="${tmpCnt}"><span style="text-decoration: none;">${tmpCnt + 1}</span></a>
                                </td>
                                            </tr>
                                        </table>
                                    </a>`;
                        html += `</td>`;
                        if (tmpCnt < 9) {
                            html += `<td width="10px"><img src="http://cdn1.ourmailsender.com/siteContent/assets/templates/common/spacer.png" width="10"></td>`;
                        }

                        tmpCnt++;
                    }

                    if (pollSlide.negativeLabel.value && pollSlide.positiveLabel.value) {
                        html += `<tr>`
                        html += `<td colspan="10" align="left"><span style="${textStyle}">${pollSlide.negativeLabel.value}</span></td>`
                        html += `<td colspan="9" align="right"><span style="${textStyle}">${pollSlide.positiveLabel.value}</span></td>`
                        html += `</tr>`
                    }

                    html += `</tr></table>`

                    break;
                }

            case "multiple":
                {
                    html = `<h2 style="${headerStyle}">${pollSlide.question.value}</h2> `
                    html += `<p style="${textStyle}">${pollSlide.description.value}</p> `
                    html += `<table cellpadding="0" cellspacing="0" align="${alignment}" width="100%"><tr>`;

                    var allowMulti = false;
                    if (pollSlide.allowMulti && pollSlide.allowMulti.value && pollSlide.allowMulti.value == "true") {
                        allowMulti = true;
                    }
                    var colCnt = 0;
                    let multipleChoiceColWidth = 100;
                    let multipleChoseMarginBottom = 0;
                    if (pollSlide.answers.value.length > 1) {
                        multipleChoiceColWidth = 50;
                    }
                    if (pollSlide.answers.value.length > 2) {
                        multipleChoseMarginBottom = 10;
                    }

                    pollSlide.answers.value.forEach(answer => {
                        html += `<td align="left" style="border: 1px solid ${textColor}; padding: 20px; width: ${multipleChoiceColWidth}%;">`;

                        html += `<table width="100%" cellpadding="0" cellspacing="0">
                        <tr>`;

                        if (allowMulti) {
                            html += `<td style="width: 25px;">
                                                <table width="20" cellpadding="0" cellspacing="0" border="1" bordercolor="999999">
                                                    <tr>
                                                        <td>
                                                            <a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="showInEmail" style="line-height: 20px; width: 20px; text-decoration: none;">&nbsp;&nbsp;&nbsp;</a>
                                                            <div class="hideInEmail" style="display: none;">
                                                                <input type="checkbox" value="${answer.value}"/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                    </td>`;

                        }
                        else {
                            html += `<td style="width: 25px;">
                                       
                                                <table width="20" cellpadding="0" cellspacing="0" border="1" bordercolor="999999">
                                                    <tr>
                                                        <td>
                                                            <a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="showInEmail" style="line-height: 20px; width: 20px; text-decoration: none;">&nbsp;&nbsp;&nbsp;</a>
                                                            <div class="hideInEmail" style="display: none;">
                                                                <input type="radio" value="${answer.value}"/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            
                                        
                                    </td>`;
                        }
                        html += `
                                <td width="10px"><img src="http://cdn1.ourmailsender.com/siteContent/assets/templates/common/spacer.png" width="10"></td>
                                <td>
                                    <span style="${textStyle}">${answer.label}</span>
                                </td>
                            </tr>
                            </table>
                            </td>`;

                        if (colCnt == 0) {
                            html += `<td width="10px"><img src="http://cdn1.ourmailsender.com/siteContent/assets/templates/common/spacer.png" width="10"></td>`;
                        }
                        colCnt++;
                        if (colCnt > 1) {
                            html += "</tr>";
                            if (multipleChoseMarginBottom > 0) {
                                html += `<tr><td colspan="10" style="height: ${multipleChoseMarginBottom}px;"></td></tr>`;
                            }
                            html += "<tr>";
                            colCnt = 0;
                        }

                    });

                    html += `</tr></table>`
                    html = html.replace(/\<tr\>\<\/tr>/g, "")
                    break;
                }

            case "question":
                {
                    html = `<h2 style="${headerStyle}">${pollSlide.question.value}</h2>`
                    html += `<p style="${textStyle}">${pollSlide.description.value}</p>`

                    var inputType = "text";
                    if (pollSlide.inputType && pollSlide.inputType.value && pollSlide.inputType.value == "textarea") inputType = "textarea"

                    var placeHolder = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
                    if (pollSlide.showPlaceHolder && pollSlide.showPlaceHolder.value) {
                        if (pollSlide.placeHolder && pollSlide.placeHolder.value) {
                            placeHolder = pollSlide.placeHolder.value;
                        }
                    }
                    html += `<table width="100%" cellpadding="0" cellspacing="0">
                        <tr>
                            <td>`
                    if (inputType == "text") {
                        html += ` 
                                    <table width="100%" cellpadding="0" cellspacing="0" style="width: 100%; background: white; border-radius: 10px;" border="2" bordercolor="999999">
                                        <tr>
                                            <td style="background: white; text-align: left; font-size: 12px; color: #CCCCCC; padding: 5px 10px;">
                                                <a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="showInEmail" style="line-height: 20px; width: 20px; text-decoration: none;">${placeHolder}</a>
                                                <div class="hideInEmail" style="display: none;"><input type="text" style="width: 100%; height: 20px;" placeholder=${placeHolder}"/></div>
                                            </td>
                                        </tr>
                                        </table>
                                    `
                    }
                    if (inputType == "textarea") {
                        html += ` 
                                        <table width="100%" cellpadding="0" cellspacing="0" style="width: 100%; background: white; border-radius: 10px;" border="2" bordercolor="999999">
                                        <tr>
                                            <td style="height: 100px; background: white; text-align: left; font-size: 12px; color: #CCCCCC; padding: 10px;" valign="top">
                                                <a href="{{{__PREVIEW_URL}}}&poll=${pollId}" class="showInEmail" style="line-height: 20px; width: 20px; text-decoration: none;">${placeHolder}</a>
                                                <div class="hideInEmail" style="display: none;"><textarea style="width: 100%; height: 100px;"></textarea></div>
                                            </td>
                                        </tr>
                                        </table>
                                    `
                    }
                    html += `</td>
                            </tr>
                            <tr>
                                <td style="height: 20px; ">
                                </td>
                            </tr>
                            <tr>
                             <td>`

                    html += buttonHtml.replace(/___BUTTONTEXT___/g, pollSlide.button.value);

                    html += `</td>
                            </tr>
                            </table>`
                    break;
                }
            case "like":
                {
                    html = `<h2 style="${headerStyle}">${pollSlide.question.value}</h2>`
                    html += `<p style="${textStyle}">${pollSlide.description.value}</p>`
                    html += `<table cellpadding="0" cellspacing="0" align="${alignment}">`;
                    var colourMono = "colour";
                    if (pollSlide.color.value == "mono") colourMono = "mono";
                    if (pollSlide.shape.value == "thumbs") {
                        html += "<tr>";
                        html += `<td align="center">`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" style="line-height: 45px; text-decoration: none; color: ${textColor};" class="promoter-clicker" data-rating="9">
                                    <img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/likes/thumbs/${colourMono}/1.png" border="0"/>
                                </a>`;
                        html += `</td>`;
                        html += `<td width="10"></td>`;
                        html += `<td align="center">`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" style="line-height: 45px; text-decoration: none; color: ${textColor};" class="promoter-clicker" data-rating="0">
                                    <img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/likes/thumbs/${colourMono}/0.png" border="0"/>
                                </a>`;
                        html += `</td>`;
                        html += "</tr>";
                    }
                    if (pollSlide.shape.value == "hearts") {
                        html += "<tr>";
                        html += `<td align="center">`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" style="line-height: 45px; text-decoration: none; color: ${textColor};" class="promoter-clicker" data-rating="9">
                                <img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/likes/hearts/${colourMono}/1.png" border="0"/>
                            </a>`;
                        html += `</td>`;
                        html += `<td width="10"></td>`;
                        html += `<td align="center">`;
                        html += `<a href="{{{__PREVIEW_URL}}}&poll=${pollId}" style="line-height: 45px; text-decoration: none; color: ${textColor};" class="promoter-clicker" data-rating="0">
                                    <img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/polls/likes/hearts/${colourMono}/0.png" border="0"/>
                                </a>`;
                        html += `</td>`;
                        html += "</tr>";
                    }

                    if (pollSlide.negativeLabel.value && pollSlide.positiveLabel.value) {
                        html += `<tr>`
                        html += `<td align="center"><span style="${textStyle}">${pollSlide.positiveLabel.value}</span></td>`
                        html += `<td width="10"></td>`;
                        html += `<td align="center"><span style="${textStyle}">${pollSlide.negativeLabel.value}</span></td>`
                        html += `</tr>`
                    }

                    html += "</table>";
                    break;
                }
            case "outro":
                {
                    html = `<h2 style="${headerStyle}">${pollSlide.question.value}</h2>`
                    html += `<p style="${textStyle}">${pollSlide.description.value}</p>`
                    if (pollSlide.button.value && pollSlide.link.value) {
                        html += buttonHtml.replace(/___BUTTONTEXT___/g, pollSlide.button.value);
                    }
                    break;
                }

        }

        return html;
    }

    static generateCaptchaContent(localVariables, globalVariables) {
        var align = "center";
        var widgetSize = "normal";
        var widgetTheme = "light";
        if (localVariables && localVariables.textAlign && localVariables.textAlign.value) align = localVariables.textAlign.value;
        if (localVariables && localVariables.widgetSize && localVariables.widgetSize.value) widgetSize = localVariables.widgetSize.value;
        if (localVariables && localVariables.widgetTheme && localVariables.widgetTheme.value) widgetTheme = localVariables.widgetTheme.value;

        var html = `<div style="align-self: ${align}">
                        <div class="g-recaptcha" id="mpmRecaptcha" data-theme="${widgetTheme}" data-size="${widgetSize}"></div>
                     </div>`;
        return html;
    }
    static generateRssContent(localVariables, globalVariables) {
        var rssOk = false;
        var rssItems = [];
        var feedType = localVariables.rssFeed.value.rssFeedType;
        var rssId = localVariables.rssId.value;
        if (localVariables.rssFeed.value.rssItems && localVariables.rssFeed.value.rssItems.length) {
            rssOk = true;
            if (feedType == "0") {
                rssItems = JSON.parse(JSON.stringify(localVariables.rssFeed.value.rssItems.filter(rss => rss.checked == true)))
            }
            if (feedType == "1") {
                rssItems = JSON.parse(JSON.stringify(localVariables.rssFeed.value.rssItems));
            }
        }

        var autoFeedItemCount = localVariables.rssFeed.value.autoFeedItemCount;
        var rssFields = localVariables.rssFeed.value.rssFields;
        var offset = Number(localVariables.rssFeed.value.offset) || 0
        let autoFeedInitialItemCount = localVariables.rssFeed.value.autoFeedInitialItemCount || 1;
        if (autoFeedItemCount == -9999) {
            autoFeedItemCount = autoFeedInitialItemCount;
            offset = 0;
        }

        if (feedType == "1") {
            if (offset) {
                rssItems.splice(0, offset)
            }
            rssItems.splice(autoFeedItemCount, rssItems.length);
        }


        var linkText = localVariables.linkText.value;

        //outer styling
        var textStyle = "";
        var alignment = "center";
        if (localVariables && localVariables.innerAlign && localVariables.innerAlign.value) alignment = this.replaceGlobalVariable(localVariables.innerAlign.value);
        textStyle += `text-align: ${alignment}; `;
        var textColor = "white";
        if (localVariables && localVariables.textColor && localVariables.textColor.value) textColor = this.replaceGlobalVariable(localVariables.textColor.value);
        textStyle += `color: ${textColor}; `;
        var textFontFamily = "arial";
        if (localVariables && localVariables.textFontFamily && localVariables.textFontFamily.value) textFontFamily = this.replaceGlobalVariable(localVariables.textFontFamily.value);
        textStyle += `font-family: ${textFontFamily}; `;
        var textHeight = "normal";
        if (localVariables && localVariables.textLineHeight && localVariables.textLineHeight.value) textHeight = this.replaceGlobalVariable(localVariables.textLineHeight.value);
        textStyle += `line-height: ${textHeight}; `;
        var textSize = "14px";
        if (localVariables && localVariables.textFontSize && localVariables.textFontSize.value) textSize = this.replaceGlobalVariable(localVariables.textFontSize.value);
        textStyle += `font-size: ${textSize}; `;
        var textFontWeight = "normal";
        if (localVariables && localVariables.textFontWeight && localVariables.textFontWeight.value) textFontWeight = this.replaceGlobalVariable(localVariables.textFontWeight.value);
        textStyle += `font-weight: ${textFontWeight}; `;

        var titleStyle = "";
        if (localVariables && localVariables.innerAlign && localVariables.innerAlign.value) alignment = this.replaceGlobalVariable(localVariables.innerAlign.value);
        titleStyle += `text-align: ${alignment}; `;
        textColor = "white";
        if (localVariables && localVariables.titleColor && localVariables.titleColor.value) textColor = this.replaceGlobalVariable(localVariables.titleColor.value);
        titleStyle += `color: ${textColor}; `;
        textFontFamily = "arial";
        if (localVariables && localVariables.titleFontFamily && localVariables.titleFontFamily.value) textFontFamily = this.replaceGlobalVariable(localVariables.titleFontFamily.value);
        titleStyle += `font-family: ${textFontFamily}; `;
        textHeight = "normal";
        if (localVariables && localVariables.titleLineHeight && localVariables.titleLineHeight.value) textHeight = this.replaceGlobalVariable(localVariables.titleLineHeight.value);
        titleStyle += `line-height: ${textHeight}; `;
        textSize = "14px";
        if (localVariables && localVariables.titleFontSize && localVariables.titleFontSize.value) textSize = this.replaceGlobalVariable(localVariables.titleFontSize.value);
        titleStyle += `font-size: ${textSize}; `;
        textFontWeight = "bold";
        if (localVariables && localVariables.titleFontWeight && localVariables.titleFontWeight.value) textFontWeight = this.replaceGlobalVariable(localVariables.titleFontWeight.value);
        titleStyle += `font-weight: ${textFontWeight}; `;
        titleStyle += `text-decoration: none; `

        //padding
        var paddingStyle = "";
        var outerPaddingBottom = "";
        if (localVariables && localVariables.outerPaddingBottom && localVariables.outerPaddingBottom.value) outerPaddingBottom = this.replaceGlobalVariable(localVariables.outerPaddingBottom.value);
        paddingStyle += `padding-bottom: ${outerPaddingBottom}; `;
        var outerPaddingTop = "";
        if (localVariables && localVariables.outerPaddingTop && localVariables.outerPaddingTop.value) outerPaddingTop = this.replaceGlobalVariable(localVariables.outerPaddingTop.value);
        paddingStyle += `padding-top: ${outerPaddingTop}; `;
        var outerPaddingLeft = "";
        if (localVariables && localVariables.outerPaddingLeft && localVariables.outerPaddingLeft.value) outerPaddingLeft = this.replaceGlobalVariable(localVariables.outerPaddingLeft.value);
        paddingStyle += `padding-left: ${outerPaddingLeft}; `;
        var outerPaddingRight = "";
        if (localVariables && localVariables.outerPaddingRight && localVariables.outerPaddingRight.value) outerPaddingRight = this.replaceGlobalVariable(localVariables.outerPaddingRight.value);
        paddingStyle += `padding-right: ${outerPaddingRight}; `;


        //item styling
        var itemStyle = "";
        var innerBorderColor = "";
        if (localVariables && localVariables.innerBorderColor && localVariables.innerBorderColor.value) innerBorderColor = this.replaceGlobalVariable(localVariables.innerBorderColor.value);
        itemStyle += `border-color: ${innerBorderColor}; `;
        var innerBorderStyle = "";
        if (localVariables && localVariables.innerBorderStyle && localVariables.innerBorderStyle.value) innerBorderStyle = this.replaceGlobalVariable(localVariables.innerBorderStyle.value);
        itemStyle += `border-style: ${innerBorderStyle}; `;
        var innerBorderWidth = "";
        if (localVariables && localVariables.innerBorderWidth && localVariables.innerBorderWidth.value) innerBorderWidth = this.replaceGlobalVariable(localVariables.innerBorderWidth.value);
        itemStyle += `border-width: ${innerBorderWidth}; `;
        var innerBackgroundColor = "";
        if (localVariables && localVariables.innerBackgroundColor && localVariables.innerBackgroundColor.value) innerBackgroundColor = this.replaceGlobalVariable(localVariables.innerBackgroundColor.value);
        itemStyle += `background-color: ${innerBackgroundColor}; `;

        //button styling
        var buttonFontSize = "";
        var buttonFontColor = "";
        var buttonFontFamily = "";
        var buttonAlign = "";
        var buttonBorderColor = "";
        var buttonBorderStyle = "";
        var buttonBorderWidth = "";
        var buttonBorderRadius = "";
        var buttonWidth = "";
        var buttonBgColor = "";
        var buttonPadding = "";

        if (localVariables && localVariables.buttonFontSize && localVariables.buttonFontSize.value) buttonFontSize = this.replaceGlobalVariable(localVariables.buttonFontSize.value);
        if (localVariables && localVariables.buttonFontColor && localVariables.buttonFontColor.value) buttonFontColor = this.replaceGlobalVariable(localVariables.buttonFontColor.value);
        if (localVariables && localVariables.buttonFontFamily && localVariables.buttonFontFamily.value) buttonFontFamily = this.replaceGlobalVariable(localVariables.buttonFontFamily.value);
        if (localVariables && localVariables.buttonAlign && localVariables.buttonAlign.value) buttonAlign = this.replaceGlobalVariable(localVariables.buttonAlign.value);
        if (localVariables && localVariables.buttonBorderColor && localVariables.buttonBorderColor.value) buttonBorderColor = this.replaceGlobalVariable(localVariables.buttonBorderColor.value);
        if (localVariables && localVariables.buttonBorderStyle && localVariables.buttonBorderStyle.value) buttonBorderStyle = this.replaceGlobalVariable(localVariables.buttonBorderStyle.value);
        if (localVariables && localVariables.buttonBorderWidth && localVariables.buttonBorderWidth.value) buttonBorderWidth = this.replaceGlobalVariable(localVariables.buttonBorderWidth.value);
        if (localVariables && localVariables.buttonBorderRadius && localVariables.buttonBorderRadius.value) buttonBorderRadius = this.replaceGlobalVariable(localVariables.buttonBorderRadius.value);
        if (localVariables && localVariables.buttonWidth && localVariables.buttonWidth.value) buttonWidth = this.replaceGlobalVariable(localVariables.buttonWidth.value);
        if (localVariables && localVariables.buttonBgColor && localVariables.buttonBgColor.value) buttonBgColor = this.replaceGlobalVariable(localVariables.buttonBgColor.value);
        if (localVariables && localVariables.buttonPadding && localVariables.buttonPadding.value) buttonPadding = this.replaceGlobalVariable(localVariables.buttonPadding.value);

        //image styling
        var imageMarginBottom = "15px";
        if (localVariables && localVariables.imageMarginBottom && localVariables.imageMarginBottom.value) imageMarginBottom = this.replaceGlobalVariable(localVariables.imageMarginBottom.value);

        var spaceBetweenItems = "15px";
        if (localVariables && localVariables.spaceBetweenItems && localVariables.spaceBetweenItems.value) spaceBetweenItems = this.replaceGlobalVariable(localVariables.spaceBetweenItems.value);

        var hasAuthor = rssFields.find(f => f.value == "author" && f.checked) ? true : false;
        var hasCategory = rssFields.find(f => f.value == "category" && f.checked) ? true : false;
        var hasContent = rssFields.find(f => f.value == "content" && f.checked) ? true : false;
        var hasCreated = rssFields.find(f => f.value == "created" && f.checked) ? true : false;
        var hasDescription = rssFields.find(f => f.value == "description" && f.checked) ? true : false;
        var hasMedia = rssFields.find(f => f.value == "media" && f.checked) ? true : false;
        var hasTitle = rssFields.find(f => f.value == "title" && f.checked) ? true : false;
        var hasUrl = rssFields.find(f => f.value == "url") ? true : false;
        var hasUrlSelected = rssFields.find(f => f.value == "url" && f.checked) ? true : false;
        var html = ``;
        if (!rssItems.length) {
            html += `<div><img src="http://cdn1.ourmailsender.com/siteContent/assets/templates/common/add-rss.png" style="width: 100%;"/></div>`;
        }
        else {

            var rssLayout = localVariables.rssLayout ? localVariables.rssLayout.value : 0;

            var colCnt = 0;
            rssItems.forEach((item, index) => {

                var tmpHtml = "";
                var img = "";
                if (hasMedia) {
                    if (item.media) {
                        img = item.media;
                    }
                }

                if (hasMedia && img) {
                    var imageWidth = "100%";

                    if (localVariables && localVariables.imageWidth && localVariables.imageWidth.value) {
                        imageWidth = localVariables.imageWidth.value;
                    }

                    var imageBorderColor = "";
                    var imageBorderStyle = "";
                    var imageBorderWidth = "0px";
                    if (localVariables && localVariables.imageBorderColor && localVariables.imageBorderColor.value) imageBorderColor = localVariables.imageBorderColor.value;
                    if (localVariables && localVariables.imageBorderStyle && localVariables.imageBorderStyle.value) imageBorderStyle = localVariables.imageBorderStyle.value;
                    if (localVariables && localVariables.imageBorderWidth && localVariables.imageBorderWidth.value) imageBorderWidth = localVariables.imageBorderWidth.value;

                    var imageBgColor = "";
                    if (localVariables && localVariables.imageBgColor && localVariables.imageBgColor.value) imageBgColor = localVariables.imageBgColor.value;

                    var imagePaddingLeft = "0px";
                    var imagePaddingRight = "0px";
                    var imagePaddingTop = "0px";
                    var imagePaddingBottom = "0px";
                    if (localVariables && localVariables.imagePaddingLeft && localVariables.imagePaddingLeft.value) imagePaddingLeft = localVariables.imagePaddingLeft.value;
                    if (localVariables && localVariables.imagePaddingRight && localVariables.imagePaddingRight.value) imagePaddingRight = localVariables.imagePaddingRight.value;
                    if (localVariables && localVariables.imagePaddingTop && localVariables.imagePaddingTop.value) imagePaddingTop = localVariables.imagePaddingTop.value;
                    if (localVariables && localVariables.imagePaddingBottom && localVariables.imagePaddingBottom.value) imagePaddingBottom = localVariables.imagePaddingBottom.value;


                    tmpHtml += `
                                <table width="100%">
                                    <tr>
                                        <td align="${alignment}" cellpadding="0" cellspacing="0" style="background-color: ${imageBgColor}; padding-left: ${imagePaddingLeft}; padding-right: ${imagePaddingRight}; padding-top: ${imagePaddingTop}; padding-bottom: ${imagePaddingBottom};">
                               `
                    if (hasUrl) {
                        tmpHtml += `        <a href="${item.url}" style="color: ${buttonFontColor}; display: block; text-align: center; mso-line-height-rule: exactly; line-height: 100%;letter-spacing: normal; text-decoration: none; -ms-text-size-adjust: 100%; mso-line-height-rule: exactly; -webkit-text-size-adjust: 100%; " title="${linkText}" target="_blank">`
                    }
                    tmpHtml += `            <img width="${imageWidth}" src="${img}" style="text-align: right; width: 100%; border: ${imageBorderWidth} ${imageBorderStyle} ${imageBorderColor}; text-decoration:none; vertical-align: baseline;" border="0">`
                    if (hasUrl) {
                        tmpHtml += `        </a>`
                    }
                    tmpHtml += `         </td>
                                    </tr>
                                </table>
                               
                                <table width="100%" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <td style="height: ${imageMarginBottom};" class="responsiveTd">
                                            <div style="height: ${imageMarginBottom};"></div>
                                        </td>
                                    </tr>
                                </table>`
                }

                tmpHtml += `<table width="100%" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td style="${paddingStyle}">`
                if (hasTitle) {
                    tmpHtml += `<table width="100%" cellpadding="0" cellspacing="0"><tr><td><div style="${titleStyle}">`
                    if (hasUrl) {
                        tmpHtml += `<a href="${item.url}" target="_blank" style="${titleStyle}">`;
                    }
                    tmpHtml += item.title;
                    if (hasUrl) {
                        tmpHtml += `</a>`;
                    }
                    tmpHtml += `</div></td></tr><tr><td style="height: 15px;"><div style="height: 15px;"></div></td></tr></table>`;
                }
                if (hasDescription) tmpHtml += `<table width="100%" cellpadding="0" cellspacing="0"><tr><td><div style="${textStyle} width: 100%; word-break: break-word;">${item.description}</div></td></tr><tr><td style="height: 15px;"><div style="height: 15px;"></div></td></tr></table>`;
                if (hasContent) tmpHtml += `<table width="100%" cellpadding="0" cellspacing="0"><tr><td><div style="${textStyle} width: 100%; word-break: break-word;">${item.content}</div></td></tr><tr><td style="height: 15px;"><div style="height: 15px;"></div></td></tr></table>`;

                if (hasAuthor || hasCategory || hasCreated) {
                    tmpHtml += `<table width="100%" cellpadding="0" cellspacing="0"><tr><td><div style="${textStyle} width: 100%; word-break: break-word;">`
                    if (hasAuthor) tmpHtml += `&#8226; ${item.author} &nbsp; `
                    if (hasCategory) tmpHtml += `&#8226; ${item.category} &nbsp; `
                    if (hasCreated) {
                        var createdDate = DateTimeFunctions.formatDateTime(moment(item.created).format(), 2)
                        tmpHtml += `&#8226; ${createdDate}`
                    }
                    tmpHtml += `</div></td></tr><tr><td <td style="height: 15px;"><div style="height: 15px;"></div></td></tr></table>`;
                }

                if (hasUrlSelected) {

                    tmpHtml += `<table align="${buttonAlign}" style="border-collapse: initial; mso-table-lspace: 0pt; mso-table-rspace: 0pt; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; " width="${buttonWidth}" cellpadding="0" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <td align="center" style="padding: ${buttonPadding}; border-color: ${buttonBorderColor}; border-style: ${buttonBorderStyle}; border-width: ${buttonBorderWidth}; border-radius: ${buttonBorderRadius}; background-color: ${buttonBgColor}; -ms-text-size-adjust: 100%; mso-line-height-rule: exactly; -webkit-text-size-adjust: 100%; " valign="middle">
                                                <a href="${item.url}" style="color: ${buttonFontColor}; display: block; text-align: center; mso-line-height-rule: exactly; line-height: 100%;letter-spacing: normal; text-decoration: none; -ms-text-size-adjust: 100%; mso-line-height-rule: exactly; -webkit-text-size-adjust: 100%; " title="${linkText}" target="_blank">
                                                    <span style="color: ${buttonFontColor}; display: block; position: relative; font-size: ${buttonFontSize}; text-align: center; font-family: ${buttonFontFamily}; mso-line-height-rule: exactly; line-height: 100%;letter-spacing: normal; text-decoration: none; -ms-text-size-adjust: 100%; mso-line-height-rule: exactly; -webkit-text-size-adjust: 100%; ">
                                                    ${linkText}
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>`

                }
                tmpHtml += `</td>
                            </tr>
                            </table>`

                if (rssLayout == 0 || !rssLayout || rssItems.length == 1 || (rssLayout == 2 && index == 0)) {
                    html += `<table width="100%" cellpadding="0" cellspacing="0" border="0" class="responsiveTbl">`;
                    html += `<tr>`
                    html += `<td style="${itemStyle}" width="100%" class="responsiveTd">`
                    html += tmpHtml
                    html += `</td>`
                    html += `</tr>`
                    if (index < rssItems.length - 1) {
                        html += `<tr>`
                        html += `<td style="height: ${spaceBetweenItems};" class="responsiveTd"></td>`
                        html += `</tr>`
                    }
                    html += "</table>"

                }
                if ((rssLayout == 1 && rssItems.length > 1 || (rssLayout == 2 && index > 0))) {

                    if (colCnt == 0) {
                        html += `<table width="100%" cellpadding="0" cellspacing="0" border="0" class="responsiveTbl">`;
                        html += `<tr>`
                    }
                    html += `<td style="${itemStyle}" width="50%" valign="top" class="responsiveTd">`
                    html += tmpHtml
                    html += `</td>`

                    if (colCnt == 0) {
                        html += `<td width="15" style="width: 15px;" class="responsiveTd">`
                        html += `<img src="http://cdn1.ourmailsender.com/siteContent/assets/templates/common/spacer.png" width="15">`
                        html += `</td>`
                        if (index == rssItems.length - 1) {
                            html += `<td width="50%" valign="top" class="responsiveTd"></td>`
                        }
                    }

                    if (colCnt == 1) {
                        html += `</tr>`
                        html += `<tr>`
                        html += `<td style="height: ${spaceBetweenItems};" class="responsiveTd" colspan="2"></td>`
                        html += `</tr>`
                        html += `</table>`
                    }
                    if (colCnt == 0 && index == rssItems.length - 1) {
                        html += `</tr>`
                        html += `</table>`
                    }
                    colCnt++;
                    if (colCnt == 2) colCnt = 0;
                }


            })
        }

        html = `<mpRss rssId="${rssId}">${html}</mpRss>`;
        return html;
    }
    static getCustomFields(theType, campaign) {
        let query = '?type=' + theType
        if (campaign && campaign.AutomationId) {
            query += ('&AutomationId=' + campaign.AutomationId)
        }
        return axios.get('/mergeTags' + query)
            .then((res) => {
                var theMergeTags = {};
                var theCustomFields = {};

                res.data.MergeTags.map(theTag => {
                    theMergeTags[theTag.tag] = theTag.description;
                })

                theCustomFields["emailAddress"] = { description: "Email Address", disabled: false, required: true, type: "email" };
                theCustomFields["companyName"] = { description: "Company Name", disabled: false, required: true, type: "text", helper: "This will create a parent organisation for this contact if the company does not already exist" };
                res.data.MergeTags.map(theTag => {
                    theCustomFields[theTag.tag] = {
                        description: theTag.description,
                        disabled: false,
                        readOnly: theTag.readOnly,
                        required: theTag.isRequired ? theTag.isRequired : false,
                        type: theTag.fieldType ? theTag.fieldType : "TEXT",
                        options: theTag.options ? theTag.options : {},
                        Application: theTag.Application
                    }
                })


                DragDropFunctions.customFields = theCustomFields;
                DragDropFunctions.mergeTags = theMergeTags;
                DragDropFunctions.rawTags = res.data.MergeTags
            })
    }
    static getVideoThumb(templateId, url) {
        return axios.get('/template/' + templateId + '/videoimage?url=' + url)
    }

    static generateTableOfContents(content) {
        if (content.indexOf("{{__TOCSUMMARY}}") > -1) {
            let dataBack = this.injectTocAnchors(content);
            console.log(dataBack)
            let tocsDetected = dataBack.tocsDetected;
            let newContent = dataBack.elementData;
            let contentOut = "<ul>"
            tocsDetected.forEach(tocDetected => {
                contentOut += `<li><a href="#${tocDetected.value}">${tocDetected.label}</a></li>`
            })
            contentOut += "</ul>"
            content = newContent.replace("{{__TOCSUMMARY}}", contentOut)
        }
        return content;
    }

    static injectTocAnchors(elementData) {
        let regex = /<*?class="[^"]*?mp_toc_title[^"]*?".*?>(.*?)<\/.*?>/gi
        let m;
        let tocsDetected = [];
        let foundCnt = 0;
        while ((m = regex.exec(elementData)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            // The result can be accessed through the `m`-variable.
            let fullHtml = m[0];
            let text = m[1];
            let linkId = "mp_toc_" + foundCnt;

            let newHtml = fullHtml.replace(text, `${text}<a id="${linkId}"></a>`)
            elementData = elementData.replace(fullHtml, newHtml)
            tocsDetected.push({
                label: text,
                value: linkId
            })
            foundCnt++;
        }

        return { elementData: elementData, tocsDetected: tocsDetected };
    }

    static findAllAnchors(elementsData) {
        let anchorsDetected = [];
        try {
            let templateString = elementsData;
            let regex = /<a.*?(name|id)="(.*?)".*?>/gi
            if (typeof templateString == "object") {
                templateString = JSON.stringify(elementsData);
                regex = /<a.*?(name|id)=\\"(.*?)\\".*?>/gi
            }

            let m;
            while ((m = regex.exec(templateString)) !== null) {

                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {

                    if (groupIndex == 2) {
                        if (match && match.length) {
                            let found = anchorsDetected.find(fa => fa == "#" + match)
                            if (found) {
                                found.cnt++;
                            }
                            else {

                                anchorsDetected.push({
                                    label: "#" + match,
                                    value: "#" + match,
                                    cnt: 1
                                })
                            }
                        }
                    }
                });
            }
        }
        catch (err) { }
        return anchorsDetected;
    }

    static emailFilesReplace(templateJson, buttonLink, replace) {
        const self = this
        if (!this.formFiles.length) return templateJson
        let found = false
        function loop(elements) {
            if (found) return
            let indexToReplace, replacements
            for (let index = 0; index < elements.length; index++) {
                const element = elements[index];
                if (element.props?.buttonLink === buttonLink) {
                    // take a copy of this element and replace it with how ever many files there are
                    replacements = self.formFiles.map((file) => {
                        const obj = { ...JSON.parse(JSON.stringify(element)), ...JSON.parse(JSON.stringify(replace)) }
                        obj.props.buttonLink = `[FORMFILE_${file.id}]`
                        obj.props.textContent = 'Download File'
                        obj.props.formFile = file.id
                        return obj
                    })
                    indexToReplace = index
                    found = true
                }
                if (Array.isArray(element.elements)) {
                    return loop(element.elements)
                }
            }
            if (indexToReplace && replacements) {
                elements.splice(indexToReplace, 1, ...replacements)
            }
        }
        loop(templateJson.structure ? templateJson.structure[0].elements[0] : templateJson.elements[1].elements)
        return templateJson
    }

    static formFilesReplace(templateJson, replace) {
        const self = this
        if (!this.formFiles.length) return templateJson
        let found = false
        function loop(elements) {
            if (found) return
            let indexToReplace, replacements
            for (let index = 0; index < elements.length; index++) {
                const element = elements[index];
                if (element.id === "70") {
                    // take a copy of this element and replace it with how ever many files there are
                    replacements = self.formFiles.filter(f => !elements.some(e => e.props?.buttonLink === `[FORMFILE_${f.id}]`)).map((file) => {
                        const obj = { ...JSON.parse(JSON.stringify(element)), ...JSON.parse(JSON.stringify(replace)) }
                        obj.props.buttonContent = 'Download File'
                        obj.props.buttonLink = `[FORMFILE_${file.id}]`
                        obj.props.formFile = file.id
                        return obj
                    })
                    indexToReplace = index
                    found = true
                }
                if (Array.isArray(element.elements)) {
                    return loop(element.elements)
                }
            }
            if (indexToReplace && replacements?.length) {
                elements.splice(indexToReplace + 1, 0, ...replacements)
            }
        }
        loop(templateJson.structure ? templateJson.structure[0].elements[0] : templateJson.elements[1].elements)
        return templateJson
    }

    //Global Data SEts
    static fontArray = fontsHelper;
    static fontStyle = ddDefaultsHelper.fontStyle;
    static fontWeight = ddDefaultsHelper.fontWeight;
    static lineHeight = ddDefaultsHelper.lineHeight;
    static letterSpacing = ddDefaultsHelper.letterSpacing;
    static borderStyle = ddDefaultsHelper.borderStyle;
    static textDecoration = ddDefaultsHelper.textDecoration;
    static horizontalAlign = ddDefaultsHelper.horizontalAlign;
    static verticalAlign = ddDefaultsHelper.verticalAlign;
    static mergeTags = {};
    static inputTypes = [{ label: "Text", value: 'text' }, { label: "Number", value: "number" }, { label: "Email", value: "email" }, { label: "Date", value: 'date' }, { label: "Time", value: 'time' }, { label: 'Password', value: 'password' }]
    static customFields = {};
    static templateType = "";
    static formFiles = []
}

export default DragDropFunctions;
import React from 'react'
import ReactDOM from 'react-dom'
import jQuery from 'jquery';

export default class Tabs extends React.Component {
    constructor(props) {
        super(props);

        this.changeTab = this.changeTab.bind(this);
        this.resizeIndicator = this.resizeIndicator.bind(this);
        this.tabs = []
    }

    componentDidUpdate() {
        this.changeTab(this.props.value, false);
    }

    changeTab(theTab, doOnChange, returnUrl) {
        if (returnUrl && this.props.supportReturnUrl) {
            return this.props.onChange(theTab, true);
        }

        if (doOnChange) {
            this.props.onChange(theTab);
        }
        if (this.props.ignoreIndex && this.props.ignoreIndex.indexOf(theTab) > -1) return;
        this.resizeIndicator(theTab);
    }


    resizeIndicator(theTab) {
        var thisEl = ReactDOM.findDOMNode(this.tabs[theTab]);
        if (!thisEl) return;
        var specs = thisEl.getBoundingClientRect();
        var parentSpecs = {
            left: 0
        };

        var parentEl = document.getElementsByClassName("uiTabs");
        if (parentEl) {
            parentSpecs = parentEl[0].getBoundingClientRect();
        }

        var thisLeft = specs.left - parentSpecs.left;
        var thisWidth = specs.width;

        this.refs.tabsIndicator.style.left = thisLeft + "px";
        this.refs.tabsIndicator.style.width = thisWidth + "px";
    }


    render() {

        var childrenWithProps = React.Children.map(this.props.children, (child, index) => {

            if (!child) return null

            var isSelected = false;
            if (index === this.props.value) {
                isSelected = true;
            }
            if (child.type == 'div') {
                return React.cloneElement(child)
            } else {
                return React.cloneElement(child, {
                    changeTab: this.changeTab,
                    url: this.changeTab(index, true, true),
                    ref: ref => this.tabs[index] = ref,
                    tabId: index,
                    isSelected: isSelected
                })
            }
        });

        return <div className="uiTabs">
            {childrenWithProps}
            <div className="tabsIndicator" ref="tabsIndicator" />
        </div>

    }
}

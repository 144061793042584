import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})
export default class IncGoDeleteTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.closeDrawer = this.closeDrawer.bind(this);
        this.deleteTemplates = this.deleteTemplates.bind(this);
        this.restoreTemplates = this.restoreTemplates.bind(this);
    }

    deleteTemplates() {
        var self = this;
        var templateIds = this.props.tickedTemplates.map(theR => theR.checkBox.rowId);
        if (this.props.onDelete && typeof this.props.onDelete === 'function') this.props.onDelete(templateIds)
        axios.delete('/template', { data: { templateIds: templateIds } })
            .then((res) => {

                self.props.reloadAfterTickedAndChanged();
                self.closeDrawer();
            }).catch(() => {
                self.props.reloadAfterTickedAndChanged();
                self.closeDrawer();
            });
    }

    restoreTemplates() {
        var self = this;
        var templateIds = this.props.tickedTemplates.map(theR => theR.checkBox.rowId);
        axios.post('/template/restoreTemplates', { templateIds: templateIds })
            .then((res) => {
                self.props.reloadAfterTickedAndChanged();
                self.closeDrawer();
            }).catch(() => {
                self.props.reloadAfterTickedAndChanged();
                self.closeDrawer();
            });
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    render() {
        var restoreHeader = i18n.t('templates:index.restoreHeader').replace(/\[totTicked\]/g, this.props.totTicked)
        var restoreSubHeader = i18n.t('templates:index.restoreSubHeader').replace(/\[totTicked\]/g, this.props.totTicked)
        var restoreButt = i18n.t('templates:index.restoreButt').replace(/\[totTicked\]/g, this.props.totTicked)

        var deleteHeader = i18n.t(this.props.totTicked > 1 ? 'templates:index.deleteHeader' : 'templates:index.deleteHeaderSingle').replace(/\[totTicked\]/g, this.props.totTicked)
        var deleteSubHeader = i18n.t(this.props.totTicked > 1 ? 'templates:index.deleteSubHeader' : 'templates:index.deleteSubHeaderSingle').replace(/\[totTicked\]/g, this.props.totTicked)
        var deleteButt = i18n.t(this.props.totTicked > 1 ? 'templates:index.deleteButt' : 'templates:index.deleteButtSingle').replace(/\[totTicked\]/g, this.props.totTicked)

        if (this.props.totTicked === 1) {

        }

        return <div>
            {(this.props.groupId && this.props.groupId == -999) && <div>
                <h4 className="mar-b25">{restoreHeader}</h4>
                <div className="mar-b25">{restoreSubHeader}</div>
                <UILIB.Button text={restoreButt} className="button-primary" onClick={this.restoreTemplates} />
            </div>
            }
            {(!this.props.groupId || this.props.groupId != -999) && <div>
                <h4 className="mar-b25">{deleteHeader}</h4>
                <div className="mar-b25">{deleteSubHeader}</div>

                <UILIB.Button text={deleteButt} className="button-primary" onClick={this.deleteTemplates} />
            </div>}
        </div>
    }
};


import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import DateTimeFunctions from '~/Classes/dateTimeFunctions'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';
import PermissionChecker from '~/Classes/permissions';
import copy from 'copy-to-clipboard';
import AddKey from './add';
import DeleteKeyDrawer from './delete';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission }
})

export default class CompanyDomains extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 1,
            apiKeys: [],
            tableData: []
        }

        this.getKeys = this.getKeys.bind(this);
        this.updateField = this.updateField.bind(this);
        this.addKey = this.addKey.bind(this);
        this.deleteKey = this.deleteKey.bind(this)
        this.buildTable = this.buildTable.bind(this);
        this.edit = this.edit.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
    }

    componentDidMount() {
        this.getKeys()
    }

    getKeys() {
        axios.get('/accountMaster/apikey').then(response => {
            this.setState({
                loading: 0,
                apiKeys: response.data.ApiKeys
            }, this.buildTable)
        })
    }

    buildTable() {
        var canEdit = PermissionChecker('company', this.props.permissionStore.permissions, "write");
        var keys = this.state.apiKeys;
        this.setState({
            loading: 0,
            tableData: keys.map((row, index) => {
                var edit = <UILIB.OptionsDropdown popWidth="150px">
                    <ul>
                        <li>
                            <a onClick={() => { this.deleteKey(index) }}>{i18n.t('delete')}</a>
                        </li>
                    </ul>

                </UILIB.OptionsDropdown>

                if (!canEdit) edit = <span></span>
                return {
                    name: {
                        headerValue: i18n.t('company:api.name'),
                        value: row.editname
                            ? <UILIB.TextInput
                                iconRight={<UILIB.Icons icon="tick" onClick={() => { if (canEdit) { this.edit(index, 'name', true) } }} />}
                                name="name"
                                onChange={ev => this.updateField(ev, index, false)}
                                focus={true}
                                onBlur={ev => { if (canEdit) this.edit(index, 'name', false, ev) }} value={row.name}
                                onEnterPress={() => { if (canEdit) this.edit(index, 'name') }}
                            />
                            : <div className="table-edit">{row.name} {(canEdit == true) && <UILIB.Icons className="mar-l5" icon="pencil" onClick={() => this.edit(index, 'name')} />}</div>,
                        orderBy: false
                    },
                    apiKey: {
                        headerValue: i18n.t('company:api.key'),
                        value: <UILIB.TextInput
                            value={row.apiKey}
                            iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => this.copyToClipboard(row.apiKey)} />}
                        />,
                        orderBy: false,
                        width: 350
                    },
                    domain: {
                        headerValue: i18n.t('company:api.domain'),
                        value: row.editdomain
                            ? <UILIB.TextInput
                                iconRight={<UILIB.Icons icon="tick" onClick={() => this.edit(index, 'domain', true)} />}
                                name="domain"
                                onChange={ev => this.updateField(ev, index, false)}
                                focus={true}
                                onBlur={ev => this.edit(index, 'domain', false, ev)} value={row.domain}
                                onEnterPress={() => { this.edit(index, 'domain') }}
                            />
                            : <div className="table-edit">{row.domain || '--BLANK--'} {(canEdit == true) && <UILIB.Icons className="mar-l5" icon="pencil" onClick={() => this.edit(index, 'domain')} />}</div>,
                        orderBy: false,
                        helper: i18n.t('company:api.domainHelper')
                    },
                    isEnabled: {
                        headerValue: i18n.t('company:api.enabled'),
                        value: <UILIB.CheckBox className={!canEdit ? 'disabled' : ''} disabled={!canEdit} name={"enabled"} checked={row.enabled} onChange={ev => this.updateField(ev, index, true)} />,
                        orderBy: false,
                        width: 150,
                    },
                    enabled: {
                        headerValue: i18n.t('company:api.write'),
                        value: <UILIB.CheckBox className={!canEdit ? 'disabled' : ''} disabled={!canEdit} name={"write"} checked={row.write} onChange={ev => this.updateField(ev, index, true)} />,
                        orderBy: false,
                        width: 150,
                        helper: i18n.t('company:api.writeHelper')
                    },
                    expiry: {
                        headerValue: i18n.t('company:api.expiry'),
                        value: row.expiry ? DateTimeFunctions.formateDateTime(row.expiry, 2) : i18n.t('company:api.never'),
                        orderBy: false
                    },
                    optionsCol: {
                        headerValue: "  ",
                        value: edit,
                        orderBy: false,
                        fixed: true,
                        width: 20
                    }
                }
            })
        })
    }

    edit(index, name, undo, ev) {
        var keys = this.state.apiKeys;
        var key = keys[index]
        key['edit' + name] = !key['edit' + name]
        if (undo) {
            key[name] = key['backup' + name]
        }
        if (!key['edit' + name] && !undo) {
            this.setState({
                alertOpen: true,
                alertMessage: i18n.t('company:api.updating')
            })

            axios.put('/accountMaster/apikey/' + key.id, key).then(() => {
                this.setState({
                    apiKeys: keys
                }, this.buildTable)
            })
        } else {
            key['backup' + name] = key[name]
            this.setState({
                apiKeys: keys
            }, this.buildTable)
        }
    }

    updateField(event, index, update) {
        var keys = this.state.apiKeys;
        var key = keys[index];
        if (event.currentTarget.type == 'checkbox') {
            key[event.currentTarget.name] = !key[event.currentTarget.name];
        } else {
            key[event.currentTarget.name] = event.currentTarget.value;
        }

        if (update) {
            this.setState({
                apiKeys: keys
            }, this.buildTable)

            this.setState({
                alertOpen: true,
                alertMessage: i18n.t('company:api.updating')
            })

            axios.put('/accountMaster/apikey/' + key.id, key)
        } else {
            this.setState({
                apiKeys: keys
            }, this.buildTable)
        }
    }

    addKey() {
        var drawerContent = <AddKey confirm={(name) => {
            this.setState({
                alertOpen: true,
                alertMessage: i18n.t('company:api.creating')
            })
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", undefined, true));
            axios.post('/accountMaster/apikey', {
                name
            }).then(response => {
                var keys = this.state.apiKeys;
                keys.push(response.data.ApiKey)
                this.setState({
                    apiKeys: keys
                }, this.buildTable)
            })
        }} />

        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    deleteKey(index) {
        var keys = this.state.apiKeys;
        var table = this.state.tableData;
        var key = keys[index];
        var drawerContent = <DeleteKeyDrawer confirm={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", undefined, true));
            this.setState({
                alertOpen: true,
                alertMessage: i18n.t('company:api.deleting')
            })
            axios.delete('/accountMaster/apikey/' + key.id).then(this.getKeys)
        }} />

        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    copyToClipboard(key) {
        this.setState({
            alertOpen: true,
            alertMessage: i18n.t('company:api.copy')
        })
        copy(key)
    }

    render() {

        // var canWrite = PermissionChecker('apikeys', this.props.permissionStore.permissions, "write");
        return <div>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={6} className="mar-b25 quickFlex">
                    <UILIB.Hint
                        iconLeft={<UILIB.Icons icon="questionCircle" />}
                        className="mar-l0"
                        href="/apidocs"
                    >
                        {i18n.t('company:api.apiLinkText')}
                    </UILIB.Hint>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="text-right mar-b25" >

                    <UILIB.Button
                        className="button-primary"
                        onClick={this.addKey}
                        iconLeft={<UILIB.Icons icon="plus" />}>
                        {i18n.t('company:api.create')}
                    </UILIB.Button>

                </UILIB.Column>

                <UILIB.Column xs={12} >
                    <UILIB.Paper>

                        <UILIB.DataTable1
                            noResultsTxt={<span> {i18n.t('company:api.noKeys')}</span>}
                            tableData={this.state.tableData}
                            loadingData={this.state.loading}
                            width="100%"
                            pageSize="100"
                            hasCheckBoxes="no"
                        />


                    </UILIB.Paper>

                </UILIB.Column>
            </UILIB.Row>


            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
        </div>

    };


};



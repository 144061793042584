
import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import UILIB from '../../../../../Common-Objects/Lib'
import axios from '../../../../../data/http/axios'
import i18n from '../../../../../i18n'
import { SocialPreview, SupportedApps, findLinks } from './shared'
import SocialAutomate from './automate'
import moment from 'moment'
import { alterSiteDrawer } from '../../../../../data/actions/siteActions'
import NewUserForm from '../../../includes/bb_topNav/newUserForm/newUserForm'

export default function SocialCampaignCreateSchedule({ campaign, refresh = () => { } }) {
    const user = useSelector(state => state.user)
    const account = useSelector(state => state.accountMaster)
    const history = useHistory()
    const dispatch = useDispatch()
    const [accApps, setAccApps] = useState(null)
    const [startDate, setStartDate] = useState(moment(campaign.startDate))
    const [siteTrackers, setSiteTrackers] = useState(null)

    const save = async (destination) => {
        await axios.put('/campaign/' + campaign.id, {
            campaign: {
                startDate
            }
        })
        if (destination) history.push(destination)
    }

    const send = async () => {
        await axios.put('/campaign/' + campaign.id, {
            campaign: {
                startDate
            }
        })
        await axios.put('/campaign/' + campaign.id + '/start')
        history.push('/cp/campaigns')
    }

    const automate = () => {
        const content = <SocialAutomate CampaignId={campaign.id} campaignName={campaign.campaignName} save={save} />
        dispatch(alterSiteDrawer(true, true, 'right', content, true, 500))
    }

    const toggleLinkTracking = async () => {
        await axios.put('/campaign/' + campaign.id, {
            campaign: {
                options: {
                    ...campaign.options,
                    doNotTrackLinks: !campaign.options.doNotTrackLinks
                }
            }
        })
        await refresh()
    }

    const activateAccount = () => {
        var theDrawer = <NewUserForm from="social" closeNewUserForm={() => { }} />
        dispatch(alterSiteDrawer(true, true, "right", theDrawer, false, "100vw", 'full'));
    }

    useEffect(() => {
        axios.get('/accountMaster/application').then(res => {
            setAccApps(res.data.Applications)
        })
        axios.get('/accountMaster/tracker').then(res => {
            setSiteTrackers(res.data.Trackers)
        })
    }, [])

    if (!accApps || !siteTrackers) return <UILIB.LoadingIcons />

    const links = []
    for (const campaign of campaign.Campaigns) {
        links.push(...findLinks(campaign.Template.templateJson.content))
    }
    const domains = Array.from(new Set(links.map(l => l.replace('https://', '').replace('http://', '').replace('www.', '').split('?')[0].split('/')[0])))
    const missingTrackers = domains.filter(d => !siteTrackers.some(s => s.host.toLowerCase() === d.toLowerCase()))

    const canAutomate = !missingTrackers.length && !!domains.length
    const automationTooltip = !canAutomate ? i18n.t('social:schedule.automateDisabled') : ''

    let sendDisabled = false
    if (!account.accountMaster.approved) {
        sendDisabled = true;
    }

    return <div>
        <UILIB.Row style={{ marginBottom: 50 }}>
            <UILIB.Column xs={12} sm={12} md={12} lg={6}>
                <UILIB.Paper>
                    <h4 className="mar-b25">{i18n.t('social:schedule.header')}</h4>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                            <UILIB.RadioTile className="mar-b10" name="sendNow" key={1} value={1} checked={moment(startDate) <= moment()} onChange={() => setStartDate(moment())}>{i18n.t('social:schedule.now')}</UILIB.RadioTile>
                            <UILIB.RadioTile name="sendNow" key={0} value={0} checked={moment(startDate) > moment()} onChange={() => setStartDate(moment().add(1, 'hour'))}>{i18n.t('social:schedule.later')}</UILIB.RadioTile>
                        </div>
                    </div>
                    {moment(startDate) > moment() && <div className="row mar-t10">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                            <UILIB.TextInput
                                label={i18n.t('social:schedule.datetime')}
                                type="datetime"
                                name="startDate"
                                onChange={(t, d) => setStartDate(d)}
                                disallowPast={true}
                                value={startDate}
                                placeholder={i18n.t('campaigns:standard.step3.startDatePlaceholder')}
                            />
                            <div className="text-sml text-grey mar-t10">{i18n.t('social:schedule.timezone', { timezone: user.userData.timeZone })}</div>
                        </div>
                    </div>}
                </UILIB.Paper>
                <UILIB.Paper className="mar-b0">
                    <h4 className="mar-b15">{i18n.t('social:schedule.tracking.header')}</h4>
                    {!!domains.length && <p className="mar-b25">{i18n.t('social:schedule.tracking.domains')}</p>}
                    {!domains.length && <div>{i18n.t('social:schedule.tracking.nodomains')}</div>}

                    {!!missingTrackers.length && <UILIB.WarningHolder className="mar-b25">
                        <div>
                            <p>{i18n.t('social:schedule.tracking.missing')}</p>
                            {missingTrackers.map(t => <div className="mar-b5" key={t}><strong key={t}>{t}</strong></div>)}
                            <UILIB.Button className="button-primary mar-t10" onClick={() => save('/cp/trackers')}>{i18n.t('social:schedule.tracking.setup')}</UILIB.Button>
                        </div>
                    </UILIB.WarningHolder>}

                    {!!domains.length && !missingTrackers.length && <p className="text-green quickFlex text-heavy mar-b25" style={{ alignItems: 'center' }}><UILIB.Icons className="mar-r10 quickFlex" style={{ alignItems: 'center' }} icon="tickCircle" color="#43b7b2" />{i18n.t('social:schedule.tracking.added')}</p>}
                    {!!domains.length && <UILIB.Toggle after={!campaign.options.doNotTrackLinks ? i18n.t('social:schedule.tracking.enabled') : i18n.t('social:schedule.tracking.disabled')} checked={!campaign.options.doNotTrackLinks} onChange={toggleLinkTracking} />}
                </UILIB.Paper>
            </UILIB.Column>
            <UILIB.Column xs={12} sm={12} md={12} lg={6}>
                <Carousel campaigns={campaign.Campaigns} startDate={startDate} />
            </UILIB.Column>
        </UILIB.Row>
        <UILIB.StickyNavBottom>
            <div className="quickFlex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                <div className="quickFlex" style={{ alignItems: 'center' }}>
                    <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} className={'button-primary mar-r10'} onClick={() => save(`/cp/campaigns/add/social/${campaign.id}/3`)}>{i18n.t('social:common.back')}</UILIB.Button>
                    <UILIB.Button onClick={() => save('/cp/campaigns')}>{i18n.t('campaigns:standard.step3.save')}</UILIB.Button>
                </div>
                {account.accountMaster.approved && <div className="quickFlex" style={{ justifyContent: "space-between" }}>
                    <UILIB.Button className="mar-r10" disabled={!canAutomate} tooltip={automationTooltip} for="socialAutomate" iconRight={<UILIB.Icons icon="lightning" />} onClick={automate}>{i18n.t('social:schedule.automate')}</UILIB.Button>
                    <UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon="share" />} onClick={send}>{moment(startDate) > moment() ? i18n.t('social:schedule.schedule') : i18n.t('social:schedule.send')}</UILIB.Button>
                </div>}
                {!account.accountMaster.approved && <div className="quickFlex" style={{ justifyContent: "space-between" }}>
                    {account.accountMaster.doneNewUserQuestions && <div className="text-orange text-heavy">{i18n.t('social:schedule.pendingApproval')}</div>}
                    {!account.accountMaster.doneNewUserQuestions && <UILIB.Button className="button-orange" onClick={activateAccount}>{i18n.t('social:schedule.requestApproval')}</UILIB.Button>}
                </div>}
            </div>
        </UILIB.StickyNavBottom>
    </div>
}

function Carousel({ campaigns, startDate }) {
    const [index, setIndex] = useState(0);
    const interval = useRef()
    const preview = useRef(null)

    useEffect(() => {
        interval.current = setInterval(() => {
            setIndex((index + 1) % campaigns.length);
        }, 5000);

        return () => clearInterval(interval.current);
    }, [index, campaigns]);

    const left = () => {
        clearInterval(interval.current)
        setIndex(index === 0 ? campaigns.length - 1 : index - 1)
    }

    const right = () => {
        clearInterval(interval.current)
        setIndex(index === campaigns.length - 1 ? 0 : index + 1)
    }

    return (
        <div className="carousel col-lg-height-100" style={{ minHeight: preview?.current ? preview.current.offsetHeight + 300 : 600 }}>
            <div className="carousel-item-arrow-left">
                <UILIB.Icons icon="arrowLeft" onClick={left} />
            </div>
            <UILIB.Paper className="paper-grey col-lg-height-100">
                {campaigns.map((campaign, i) => {
                    const app = SupportedApps.find(a => a.code === campaign.options.AppCode)
                    return <div
                        className="carousel-item"
                        key={campaign.id}
                        style={{
                            transform: `translateX(${100 * (i - index)}%)`
                        }}
                    >
                        <h4 style={{ textAlign: 'center', marginBottom: 64, marginTop: 32 }}>{i18n.t('social:channels.' + app.code)}</h4>
                        <SocialPreview
                            ref={preview}
                            SocialAccountId={campaign.options.SocialAccountId}
                            text={campaign.Template.templateJson.content}
                            images={campaign.Template.templateJson.images}
                            startDate={startDate}
                            provider={app}
                            warning={false}
                        />
                    </div>
                })}
            </UILIB.Paper>
            <div className="carousel-item-arrow-right">
                <UILIB.Icons icon="arrowRight" onClick={right} />
            </div>
        </div>
    );
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import PermissionChecker from '~/Classes/permissions';
import i18n from '~/i18n'
import NewUserForm from '../includes/bb_topNav/newUserForm/newUserForm';
import * as siteActions from '../../../data/actions/siteActions'
@connect((store) => {
    return { permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

class TransactionTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIndex: 0,
        }
        this.tabChange = this.tabChange.bind(this);
        this.setInitialTab = this.setInitialTab.bind(this);
    }

    componentDidMount() {
        this.setInitialTab();
    }

    componentDidUpdate(prevProps) {
        if (prevProps != this.props)
            this.setInitialTab();
    }

    activate() {
        const drawerContent = <NewUserForm from="email" />
        this.props.dispatch(siteActions.alterSiteDrawer(true, true, 'right', drawerContent, true, '100vw'))
    }

    setInitialTab() {
        var foundTab = 0;
        var theCurrPath = this.props.history.location.pathname.split("/");
        if (theCurrPath.length > 3) {
            if (theCurrPath[3] == 'analytics') {
                foundTab = 1;
                this.setState({ tabIndex: 0 });
            }
            if (theCurrPath[3] == 'list') {
                foundTab = 1;
                this.setState({ tabIndex: 1 });
            }
            if (theCurrPath[3] == 'settings') {
                foundTab = 1;
                this.setState({ tabIndex: 2 });
            }
        }

        if (foundTab === 0) {
            this.setState({ tabIndex: 0 });
        }
    }

    tabChange(event, returnUrl) {
        var index;
        if (event.currentTarget && event.currentTarget.id !== undefined) {
            index = event.currentTarget.id;
        }
        else if (event.currentTarget && event.currentTarget.value !== undefined) {
            index = event.currentTarget.value;
        }
        else {
            index = event;
        }
        index = String(index);

        if (!returnUrl) this.setState({ tabIndex: index });
        let url
        switch (index) {
            case "0":
                url = '/cp/transactions';
                break;
            case "1":
                url = '/cp/transactions/list';
                break;
            case "2":
                url = '/cp/transactions/settings';
                break;
        }
        if (returnUrl) return url;
        this.props.history.push(url);
    }

    render() {

        const account = this.props.accountMaster.accountMaster
        if (!account.approved) {

        }

        var thisMenuOptions = [
            { label: i18n.t('campaigns:transactions.analytics'), value: 0, permission: 'company', method: "read" },
            { label: i18n.t('campaigns:transactions.transactions'), value: 1, permission: 'company', method: "read" },
            { label: i18n.t('campaigns:transactions.settings'), value: 2, permission: 'company', method: "read" }
        ]

        var finalMenuOptions = thisMenuOptions.filter((thisOpt) => {
            var permissionName = thisOpt.permission
            if (!permissionName || PermissionChecker(permissionName, this.props.permissionStore.permissions, thisOpt.method)) {
                return true
            }
        });


        return <UILIB.Paper>
            <UILIB.Row>
                <UILIB.Column md={6} className="hide-xs hide-sm show-md show-lg mar-b25">
                    {finalMenuOptions.map((thisOpt, index) => {
                        return <UILIB.ButtonSimple key={"tab_" + index} to={this.tabChange(thisOpt.value, true)} className="button-simple-fullsize mar-l5" selected={this.state.tabIndex == thisOpt.value}>{thisOpt.label}</UILIB.ButtonSimple>
                    })}
                </UILIB.Column>
                <UILIB.Column md={6} className="pad-left mar-b25 hide-xs hide-sm show-md show-lg quickFlex end-xs">
                    {!account.approved && account.doneNewUserQuestions && <div className='text-orange text-heavy'>
                        You cannot send emails until we have approved your account
                    </div>}
                    {!account.doneNewUserQuestions && <div className='text-orange text-heavy quickFlex'>
                        <div className='mar-r10'>You cannot send emails until we have approved your account</div>
                        <UILIB.Button className="button-orange" onClick={this.activate.bind(this)}>Request Approval</UILIB.Button>
                    </div>}
                    {this.props.siteMaster.siteId == 1 && account.approved && <UILIB.Hint
                        className="mar-l10 mar-r0"
                        href="https://kb.transpond.io/hc/en-us/sections/360001658492-Transactional-Emails"
                        iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>
                        Find out more about Transactional Emails.
                    </UILIB.Hint>}
                </UILIB.Column>
                <UILIB.Column xs={12} className="hide-md hide-lg mar-b25" >

                    <UILIB.Select data={thisMenuOptions} onChange={this.tabChange} value={this.state.tabIndex} />

                </UILIB.Column>
            </UILIB.Row>
            {this.props.children}
        </UILIB.Paper >

    }
}

export default TransactionTemplate;
import React from 'react'
import UILIB from '../../../Lib'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'

export default class SelectBox extends React.Component {
    constructor(props) {
        super(props);
        this.changeOption = this.changeOption.bind(this);
        this.select = this.select.bind(this)

        var options = []
        if (this.props.time) {
            var start = moment().startOf('day')
            while (start.isSame(moment(), 'day')) {
                options.push(start.format('HH:mm'))
                start.add(this.props.time, 'minutes')
            }
        }
        this.state = {
            options,
            filterText: ''
        }
    }

    changeOption(event) {
        if (typeof this.props.onChange !== 'function') return
        this.props.onChange(event);
    }

    select(option) {
        if (typeof this.props.onChange !== 'function') return
        this.props.onChange({
            currentTarget: {
                id: this.props.id,
                name: this.props.name,
                value: option.value,
                group: option.group
            },
            target: {
                id: this.props.id,
                name: this.props.name,
                value: option.value,
                group: option.group
            },
            multiple: this.props.multiple
        })
    }

    updateFilter(val) {
        this.setState({ filterText: val })
        if (this.props.updateFilter) {
            this.props.updateFilter(val);
        }
    }


    render() {
        const { headerText } = this.props;
        var theOpts = this.props.data || this.state.options;
        var optsOut = [];
        var currOptions = this.props.value;
        if (currOptions === null) currOptions = undefined
        // if (typeof currOptions === "string") currOptions = currOptions.toLowerCase();
        var multiOptions = [];
        if (this.props.multiple) {
            if (Array.isArray(currOptions)) {
                multiOptions = currOptions;
            }
        }
        var errorTxt = [];
        var thisClasses = "selectbox";
        if (this.props.className) thisClasses += " " + this.props.className;

        if (this.props.error && this.props.error.length > 0) {
            if (this.props.error != " ") {
                errorTxt.push(<UILIB.FormError key={"err"}>{this.props.error}</UILIB.FormError>);
            }
        }

        let groups = []
        var found = 0;
        if (Array.isArray(theOpts)) {
            found = 1;
            optsOut = theOpts.map((o, i) => {
                if (typeof o === 'object') {


                    var val = o.value;
                    // if (typeof val == "string") val = val.toLowerCase();
                    var props = { value: val, label: o.label, key: i, selected: val == this.props.value, iconRight: o.iconRight, iconLeft: o.iconLeft, tooltip: o.tooltip };
                    if (multiOptions.some(mO => mO == o.value)) {
                        props.selected = true;
                    }

                    if (!props.selected) {
                        if (typeof mO === "string" && typeof o.value === "string") {
                            if (mO.toLowerCase() == o.value.toLowerCase()) props.selected = true;
                        }
                    }

                    if (o.disabled) {
                        props.disabled = true;
                    }

                    if (o.group && !groups.some(g => g === o.group)) groups.push(o.group)
                    props.group = o.group
                    return props
                } else {
                    var val = o;
                    // if (typeof val == "string") val = val.toLowerCase();
                    var props = { value: val, label: val, key: i, selected: val === this.props.value };
                    if (multiOptions.find(mO => mO == o)) {
                        props.selected = true;
                    }
                    return props
                }
            })
        } else if (typeof theOpts === "object") {
            found = 1;
            optsOut = Object.keys(theOpts).map((o, i) => {
                if (typeof theOpts[o] === 'object') {
                    var props = { value: theOpts[o].index, label: o, key: theOpts[o].index, selected: theOpts[o].index === this.props.value };
                    if (multiOptions.find(mO => mO == theOpts[o].index)) {
                        props.selected = true;
                    }
                    return props
                }
                else {
                    var val = theOpts[o];
                    // if (typeof val == "string") val = val.toLowerCase();
                    var props = { value: val, key: theOpts[o], label: o, selected: val === this.props.value };
                    if (multiOptions.find(mO => {
                        if (typeof mO === 'string') {
                            return mO.toLowerCase() === theOpts[o]
                        } else {
                            return mO == theOpts[o]
                        }
                    })) {
                        props.selected = true;
                    }
                    return props
                }
            })
        } else {
            for (var x in theOpts) {
                var val = theOpts[x];
                // if (typeof val == "string") val = val.toLowerCase();
                var props = { value: val, key: x, label: x, selected: val === this.props.value };
                if (multiOptions.find(mO => mO == theOpts[x])) {
                    props.selected = true;
                }

                optsOut.push(props)
            }
        }
        var thisStyle = {
            width: "100%"
        }


        if (this.props.placeholder && !this.props.hideplaceholder && (!this.props.value || this.props.value == "-1") && !optsOut.some(o => o.group)) {
            optsOut.unshift({ label: this.props.placeholder, value: undefined, selected: false, placeholder: true })
        }

        if (this.props.style) {
            if (this.props.style.width) {
                thisStyle.width = this.props.style.width;
            }
            if (this.props.style.marginBottom) {
                thisStyle.marginBottom = this.props.style.marginBottom;
            }
            if (this.props.style.height) {
                thisStyle.height = this.props.style.height
            }
            if (this.props.style.paddingBottom) {
                thisStyle.paddingBottom = this.props.style.paddingBottom
            }
        }

        const outerStyle = Object.assign({ position: 'relative' }, this.props.outerstyle || {})

        let outerClassName = "textInputWrapper";
        if (this.props.outerClassName) outerClassName += " " + this.props.outerClassName;

        let label = "";
        if ((this.props.label && this.props.labelOptions) || this.props.labelOptions) {
            label = <div className="txtInput_label_flex">
                <div className="txtInput_label">{this.props.label}</div>
                <div className="txtInput_labelOptions">{this.props.labelOptions}</div>
            </div>
        } else if (this.props.label) {
            label = <div className="txtInput_label">{this.props.label}</div>
        }

        let explainer = "";
        if (this.props.explainer) {
            explainer = <div className="txtInput_explainer">{this.props.explainer}</div>
        }

        if (this.props.explainerEnd) {
            explainer = <div className='quickFlex' style={{ justifyContent: 'space-between' }}>{explainer} {this.props.explainerEnd}</div>
        }


        var iconRight = [];
        if (this.props.iconRight) {
            iconRight.push(this.props.iconRight)
        }
        if (iconRight.length) {
            iconRight = <div className="textInputIcon">{iconRight}</div>
            thisStyle.paddingRight = "40px"
        }

        let header = <div>
            {!!this.props.inlineLabel && <span style={{ color: '#595c61' }}>{this.props.inlineLabel}: </span>}
            <span className="text-heavy">{this.props.placeholder || this.props.placeholderNoSelect || 'Select'}</span>
        </div>
        if (this.props.value !== undefined || optsOut.some(o => o.value === undefined)) {

            let found = optsOut.find(d => d.value == this.props.value)
            if (!found && this.props.otherData?.length) {
                found = this.props.otherData.find(d => d.value == this.props.value)
            }
            if (found) {
                header = <div style={{ display: 'flex', width: '100%', overflow: 'hidden' }}>
                    {!!found.iconLeft && !this.props.hideIconInHeader &&
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><UILIB.Icons icon={found.iconLeft} style={{ "margin-right": 3 }} /></div>
                    }
                    {!!this.props.inlineLabel &&
                        <div style={{ color: '#595c61' }}>{this.props.inlineLabel}: </div>
                    }
                    <div className={!!this.props.inlineLabel ? "text-heavy" : ""} style={{ whiteSpace: 'nowrap', flexGrow: 1, textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden', ...this.props.headerContentStyle }}>{found.label}</div>
                    {!!found.iconRight && !this.props.hideIconInHeader && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><UILIB.Icons icon={found.iconRight} style={{ "margin-left": 3 }} /></div>}
                </div>
            }
        }

        if (this.props.multiple && Array.isArray(this.props.value) && this.props.value.length) {
            const values = this.props.value.map(v => optsOut.find(o => o.value == v)).filter(v => !!v)
            if (values.length) {
                if (values.length === 1) {
                    header = values[0].label
                } else {
                    header = <div style={{ display: 'flex', width: '100%', overflow: 'hidden' }}>
                        <div style={{ whiteSpace: 'nowrap', flexGrow: 1, textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden' }}>{values.length + ' selected'}</div>
                    </div>
                }
            }
        }

        if (this.props.fixedHeader) {
            header = this.props.fixedHeader
        }

        if (this.props.filter && this.state.filterText) {
            optsOut = optsOut.filter(o => {
                if (typeof o.label !== 'string') return true
                return o.label.toLowerCase().indexOf(this.state.filterText.toLowerCase()) > -1
            })
        }

        let options = []
        if (groups.length) {
            // const placeholder = optsOut.find(o => o.placeholder = true)
            // if (placeholder) {
            //     options.push(<Option item={placeholder} selected={placeholder.selected} onSelect={this.select} disabled={placeholder.disabled} iconRight={placeholder.iconRight} />)
            // }
            groups.forEach(group => {
                const groupOptions = optsOut.filter(o => o.group === group).map(o => <Option item={o} group={o.group} tooltip={o.tooltip} selected={o.selected} onSelect={this.select} disabled={o.disabled} iconRight={o.iconRight} checkbox={this.props.multiple} />)
                options.push(<Group key={'select_group_' + group} name={group} options={groupOptions} />)
            })
            const noGroup = optsOut.filter(o => !o.group && !o.placeholder)
            if (noGroup.length) {
                const groupOptions = noGroup.map(o => <Option item={o} selected={o.selected} tooltip={o.tooltip} onSelect={this.select} disabled={o.disabled} iconRight={o.iconRight} iconLeft={o.iconLeft} checkbox={this.props.multiple} />)
                options.push(<Group key={'select_group_blank'} name="Other" options={groupOptions} />)
            }
        } else {
            options = optsOut.map((o, i) => <Option key={i} item={o} selected={o.selected} tooltip={o.tooltip} onSelect={this.select} disabled={o.disabled} iconRight={o.iconRight} iconLeft={o.iconLeft} checkbox={this.props.multiple} />)
        }

        let useUl = true
        if (!options.length && !this.props.hideNoOptions) options = [<Option key={-1} item={{ label: '', value: '' }} selected={false} onSelect={() => { }} />]
        if (!options.length && this.props.hideNoOptions) useUl = false

        let buttonClass = "button-plain"
        if (this.props.error) {
            buttonClass += " button-red-outline"
        }

        return (
            <div style={outerStyle} className={outerClassName}>
                {headerText && (
                    <div>{headerText}</div>
                )}

                {label}

                <UILIB.ButtonSelect btnClass={buttonClass}
                    hideIconRight={this.props.hideIconRight}
                    onBlur={this.props.onBlur}
                    dropdownStyle={this.props.dropdownStyle}
                    filter={this.props.filter}
                    filterText={this.props.filterText}
                    updateFilter={this.updateFilter.bind(this)}
                    fixedHeight={true}
                    clearAll={this.props.clearAll}
                    selectAll={this.props.selectAll}
                    autoClose={!this.props.multiple}
                    iconRight={this.props.iconRight}
                    iconLeft={this.props.iconLeft}
                    inputType={this.props.inputType}
                    onOpen={this.props.onOpen}
                    onClose={this.props.onClose}
                    customHeader={this.props.customHeader}
                    // data={options}
                    disabled={this.props.disabled}
                    autoWidth={this.props.autoWidth}
                    headerText={header}
                    style={thisStyle}
                    name={this.props.name}
                    className={thisClasses}
                    loadingData={this.props.loadingData}
                    fixedHeightLarge={this.props.fixedHeightLarge}
                    align={this.props.align}
                    topCta={this.props.topCta}
                    topAuthWidth={this.props.topAuthWidth}
                >
                    {useUl && <ul>{options}</ul>}
                    {!useUl && options}
                </UILIB.ButtonSelect>

                {errorTxt}
                {explainer}
            </div>
        );
    }
}

function Option({ item, onSelect, selected, disabled, iconRight, iconLeft, checkbox, tooltip }) {
    let defaultTick = !iconLeft && !iconRight ? true : !!iconRight ? true : false
    return (
        <li data-tip data-for={'options' + item.value || item.id}>
            <a style={{ display: 'flex', width: '100%', overflow: 'hidden', cursor: disabled ? 'not-allowed' : 'pointer' }} className={disabled ? 'disabled' : ''} disabled={disabled} onClick={() => disabled ? {} : onSelect(item)}>

                {!!iconLeft && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{<UILIB.Icons icon={iconLeft} />}</div>}
                {!!selected && defaultTick && !checkbox && <UILIB.Icons icon="tick" style={{ height: 20, width: 20 }} className="mar-r10" />}
                {!selected && defaultTick && !checkbox && <div style={{ height: 20, width: 20 }} className="mar-r10"><div style={{ height: 20, width: 20 }}></div></div>}
                {checkbox && <UILIB.CheckBox checked={!!selected} disabled={disabled} onChange={() => onSelect(item)} />}
                <div style={{ whiteSpace: 'nowrap', flexGrow: 1, textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.label}</div>
                {!!selected && !!iconLeft && !checkbox && <UILIB.Icons icon="tick" style={{ height: 20, width: 20 }} className="mar-r10" />}
                {!selected && !!iconLeft && !checkbox && <div style={{ height: 20, width: 20 }} className="mar-r10"><div style={{ height: 20, width: 20 }}></div></div>}
                {!!iconRight && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{<UILIB.Icons icon={iconRight} />}</div>}
            </a>
            {tooltip && <ReactTooltip effect='float' id={'options' + item.value || item.id}>{tooltip}</ReactTooltip>}

        </li>
    )
}

function Group({ name, options }) {
    return (
        <>
            <div className='select-group'>{name}</div>
            {options}
        </>
    )
}
import React from 'react'
import { connect } from 'react-redux';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import EmailValidator from '~/Classes/emailValidator'
import PermissionChecker from '~/Classes/permissions';
import * as siteActions from '../../../../data/actions/siteActions'
import helpers from '~/Classes/helpers'
import NewUserForm from '../bb_topNav/newUserForm/newUserForm';
import textFunctions from '~/Classes/textFunctions';
import DBConnection from '~/Classes/siteVars';
//CONNECT TO STORE

@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, permissionStore: store.permission }
})

export default class EmailPreviewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templateId: 0,
            templateName: "",
            emailAddresses: [],
            screenSize: "100%",
            alertMessage: "",
            alertOpen: false,
            errorMessage: "",
            groupData: [],
            currentGroup: -1,
            iframeSrc: "",
            loaded: false,
            currentSubs: [],
            previewSubOffset: 0,
            campaignData: {},
            downloadingPdf: false,
            after: undefined,
            previewEmail: '',
            previewId: 0,
            reset: 0
        }

        this.loadTemplate = this.loadTemplate.bind(this);
        this.goSendPreview = this.goSendPreview.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.addEmailAddress = this.addEmailAddress.bind(this);
        this.changeGroup = this.changeGroup.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.goValidateDomain = this.goValidateDomain.bind(this);
        this.nextSubscriber = this.nextSubscriber.bind(this);
        this.prevSubscriber = this.prevSubscriber.bind(this);
        this.genIframe = this.genIframe.bind(this);
        this.loadCampaign = this.loadCampaign.bind(this);
        this.downloadPdf = this.downloadPdf.bind(this);
    }

    componentDidMount() {
        var emailAddresses = [{ emailAddress: this.props.user.userData.emailAddress, errorText: "" }];
        this.setState({ templateId: this.props.templateId, emailAddresses: emailAddresses }, () => {
            if (this.props.fromCampaignId) {
                this.loadCampaign();
            }
            else {
                this.loadTemplate();
            }
        });
    }

    loadCampaign() {
        axios.get(`/campaign/${this.props.fromCampaignId}`).then(_campaignData => {
            this.setState({ campaignData: _campaignData.data.Campaign }, this.loadTemplate)
        })
    }
    async loadTemplate() {
        var thisTemplateData;
        let groupData
        const res = await axios.get(`/template/${this.state.templateId}`)
        thisTemplateData = res.data;
        if (PermissionChecker('subscribers', this.props.permissionStore.permissions, "read")) {
            groupData = await axios.get('/group');
        }
        var templateName = thisTemplateData.template.templateName;
        var theIframeSrc = await this.genIframe(0);
        this.setState({ iframeSrc: theIframeSrc, CampaignId: thisTemplateData.template.CampaignId, templateName: templateName, groupData: groupData ? groupData.data.Groups : [], loaded: true });
    }


    changeEmail(event, index) {
        var theEmails = this.state.emailAddresses;
        theEmails[index].emailAddress = event.target.value;
        this.setState({ emailAddresses: theEmails });
    }

    addEmailAddress() {
        var theEmails = this.state.emailAddresses;
        if (theEmails.length < 5) {
            theEmails.push([{ emailAddress: "", errorText: "" }]);
        }
        this.setState({ emailAddresses: theEmails })
    }

    goValidateDomain() {
        this.props.history.push('/cp/company/domains')
    }

    goSendPreview() {
        var self = this;

        var emailsToSend = [];
        var failed = false;
        var origEmails = this.state.emailAddresses;
        for (var email in origEmails) {
            var thisEmail = origEmails[email].emailAddress;
            var okToGo = false;
            if (EmailValidator.validateEmail(thisEmail) === true) {
                emailsToSend.push(thisEmail);
                origEmails[email].errorText = ""
                okToGo = true;
            }
            if (!thisEmail) {
                okToGo = true;
                origEmails[email].errorText = ""
            }
            if (okToGo == false) {
                failed = true;
                origEmails[email].errorText = i18n.t('templates:preview.invalid')
            }
        }

        if (failed === false) {
            var previewData = {
                emailAddress: emailsToSend,
                subject: this.props.subject
            }
            if (this.state.currentGroup && this.state.currentGroup >= 0) {
                if (this.state.currentSubs && this.state.currentSubs.length && this.state.currentSubs[this.state.previewSubOffset]) {
                    var ourSubId = this.state.currentSubs[this.state.previewSubOffset].subscriberId;
                    previewData.previewAsSubscriberId = ourSubId;
                }
            } else if (this.state.previewEmail && this.state.previewId) {
                previewData.previewAsSubscriberId = parseInt(this.state.previewId);
            }

            if (this.props.turnOffTracking && this.props.turnOffTracking == true) {
                previewData.trackLinks = false;
                previewData.trackOpens = false;
            }

            previewData.fromAddress = this.props.fromAddress
            previewData.fromName = this.props.fromName
            previewData.CdnDomainId = this.props.CdnDomainId
            previewData.TrackingDomainId = this.props.TrackingDomainId

            axios
                .post('/template/preview/' + this.state.templateId,
                    previewData
                )
                .then((res) => {
                    this.props.dispatch(siteActions.alterSnackbar(true, i18n.t('templates:preview.sent')))
                    self.setState({ emailAddresses: origEmails })

                }).catch(function (error) {

                    var alertMessage = i18n.t('templates:preview.error');
                    var errorMessage = "";
                    if (error.data.error.replyAddress) {
                        errorMessage = <UILIB.Column xs={12} sm={12} md={12} margin={0}>
                            <div className="text-red mar-b15">You must verify your account email address <strong>{self.props.user.userData.emailAddress}</strong> before you can send preview emails</div>

                            <UILIB.Button className="button-sml min-shadow outline red" text="Verify your Email Address" onClick={self.goValidateDomain} />
                        </UILIB.Column>;
                    }

                    this.props.dispatch(siteActions.alterSnackbar(true, error.data.error || alertMessage))
                    self.setState({ emailAddresses: origEmails, errorMessage: errorMessage })
                })
        }
        else {
            self.setState({ emailAddresses: origEmails })
        }
    }
    changeScreenSize(type) {
        var screenSize = "100%";
        if (type === 1) {
            screenSize = "768px";
        }
        if (type === 2) {
            screenSize = "480px";
        }
        this.setState({ screenSize: screenSize });
    }
    async changeGroup(event) {
        var newGroupId = event.currentTarget.value;
        if (isNaN(newGroupId)) {
            return;
        }

        this.setState({ currentGroup: newGroupId, reloading: true });

        if (newGroupId == 0) {
            var queryStr = '?ignoreLog=1&limit=50offset=0';
            if (this.state.campaignData.options && this.state.campaignData.options.filterSegments) {
                queryStr += '&filterSegments=true'
            }

            const response = await axios.post('/campaign/countresults' + queryStr, {
                Segments: this.state.campaignData.Segments,
                Groups: this.state.campaignData.Groups
            })
            if (response.data && response.data.rows && response.data.rows.length) {
                var ourSubs = response.data.rows;
                var ourSubId = 0;
                if (ourSubs && ourSubs.length > 0) {
                    ourSubId = ourSubs[0].id;
                }
                var theIframeSrc = await this.genIframe(ourSubId);
                var currentSubs = response.data.rows.map(sub => { return { subscriberId: sub.id, emailAddress: sub.emailAddress } })
                this.setState({ iframeSrc: theIframeSrc, reloading: false, previewSubOffset: 0, currentSubs: currentSubs });
            } else {
                this.setState({ reloading: false, previewSubOffset: 0 });
            }

        }
        else {
            const response = await axios.get('/group/' + newGroupId + '/subscriber?recordLimit=50&active=true')
            if (response.data && response.data.Subscribers && response.data.Subscribers.length) {
                var ourSubs = response.data.Subscribers;
                var ourSubId = 0;
                if (ourSubs && ourSubs.length > 0) {
                    ourSubId = ourSubs[0].subscriberId;
                }
                var theIframeSrc = await this.genIframe(ourSubId);
                this.setState({ iframeSrc: theIframeSrc, reloading: false, previewSubOffset: 0, currentSubs: response.data.Subscribers });
            } else {
                this.setState({ reloading: false, previewSubOffset: 0 });
            }


        }
    }

    async nextSubscriber() {
        var subId = this.state.previewSubOffset + 1;
        if (subId > 50) subId = 0;
        var ourSubId = 0;
        var ourSubs = this.state.currentSubs;

        if (!ourSubs[subId]) {
            subId = 0;
            ourSubId = ourSubs[0].subscriberId;
        } else {
            ourSubId = ourSubs[subId].subscriberId;
        }


        var iframeSrc = await this.genIframe(ourSubId);
        this.setState({ iframeSrc: iframeSrc, previewSubOffset: subId })
    }
    async prevSubscriber() {
        var subId = this.state.previewSubOffset - 1;
        var ourSubs = this.state.currentSubs;
        if (subId < 0) subId = ourSubs.length - 1;

        var ourSubId = 0;
        if (!ourSubs[subId]) {
            subId = 0;
            ourSubId = ourSubs[0].subscriberId;
        } else {
            ourSubId = ourSubs[subId].subscriberId;
        }
        var iframeSrc = await this.genIframe(ourSubId);
        this.setState({ iframeSrc: iframeSrc, previewSubOffset: subId })
    }

    async genIframe(ourSubId) {
        var key = await helpers.createKey(ourSubId, 0, 0, 0)
        var baseUrl = DBConnection.getConnString()
        let theIframeSrc = baseUrl + `/templatePreview?key=${key}&templateId=${this.state.templateId}&showClicks=false&type=&bots=&html=true`
        //var theIframeSrc = "/preview?key=" + key + "&tid=" + this.state.templateId;
        return theIframeSrc;
    }
    closeAlert() {
        this.setState({ alertOpen: false, alertMessage: "" });
    }
    downloadPdf() {
        this.setState({ downloadingPdf: true })
        axios.get('/template/' + this.state.templateId + '/exportpdf',
            {
                responseType: 'blob'
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'template_' + this.state.templateId + '.pdf');
                document.body.appendChild(link);
                link.click();
                this.setState({ downloadingPdf: false })
            })
    }

    activate() {
        this.setState({ drawerContent: <NewUserForm from="email" /> })
    }

    async previewRandom() {
        this.setState({ searching: true, reset: Date.now() })
        let q = '?limit=1'
        if (this.state.after) {
            this.state.after.forEach(a => q += '&after=' + a)
        }
        const res = await axios.get('/campaign/' + this.state.CampaignId + '/contacts' + q)
        this.setState({ searching: false })
        if ((!res.data.hits.hits || !res.data.hits.hits.length)) {
            if (this.state.after) {
                return this.setState({ after: null }, this.previewRandom)
            } else {
                return this.props.dispatch(siteActions.alterSnackbar(true, 'No Contacts Found'))
            }
        }
        this.setState({
            after: res.data.hits.hits[0].sort
        })
        const subId = res.data.hits.hits[0]._id.split('_')[1]
        const iframeSrc = await this.genIframe(subId)
        this.setState({ iframeSrc, previewEmail: res.data.hits.hits[0]._source.emailAddress, previewId: subId })

    }

    async updateEmailSearch(ev) {
        const iframeSrc = await this.genIframe(ev.value)
        this.setState({ previewEmail: ev.label, iframeSrc, previewId: ev.value })
    }

    render() {
        if (this.state.loaded) {
            var groupData = JSON.parse(JSON.stringify(this.state.groupData));
            if (!groupData) groupData = [];
            if (this.props.fromCampaignId) {
                groupData.unshift({ id: 0, groupName: "Campaign Recipients" })
            }

            const approved = this.props.accountMaster.accountMaster.approved

            return <div className="emailPreviewHolder">
                <UILIB.Row>
                    <UILIB.Column xs={12} sm={8} className="text-center mar-b10" hide={["xs"]}>
                        <UILIB.ButtonSimple
                            toolTipFor="desktop"
                            className="button-simple-fullsize mar-r5"
                            selected={this.state.screenSize === "100%" && "selected"}
                            onClick={() => { this.changeScreenSize(0) }}
                            tooltip="Desktop">
                            <UILIB.Icons icon="pc" />
                        </UILIB.ButtonSimple>

                        <UILIB.ButtonSimple
                            toolTipFor="tablet"
                            className="button-simple-fullsize mar-r5"
                            selected={this.state.screenSize === "768px" && "selected"}
                            onClick={() => { this.changeScreenSize(1) }}
                            tooltip="Tablet">
                            <UILIB.Icons icon="tablet" />
                        </UILIB.ButtonSimple>

                        <UILIB.ButtonSimple
                            toolTipFor="mobile"
                            className="button-simple-fullsize "
                            selected={this.state.screenSize === "480px" && "selected"}
                            onClick={() => { this.changeScreenSize(2) }}
                            tooltip="Mobile">
                            <UILIB.Icons icon="mobile" />
                        </UILIB.ButtonSimple>

                    </UILIB.Column>
                </UILIB.Row>

                <UILIB.Row>
                    <UILIB.Column xs={12} sm={8} className="mar-b25">
                        <div className="innerPreviewHolder" style={{ maxWidth: this.state.screenSize }}>
                            {!this.state.reloading && <iframe width="100%" height="100%" style={{ border: "0" }} src={this.state.iframeSrc}></iframe>}
                            {this.state.reloading && <UILIB.LoadingIcons iconType="2" />}
                        </div>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={4}>

                        <div className="emailPreviewOptions">

                            <h4 className="mar-b25">{i18n.t('templates:preview.header')}</h4>

                            <UILIB.Paper>
                                <div className="txtInput_label">{i18n.t('templates:preview.email')}</div>
                                {this.state.emailAddresses.map((theEmail, index) => {
                                    return <div style={{ marginBottom: "15px" }} key={"emailT" + index}>
                                        <UILIB.TextInput placeholder="Enter email address" value={theEmail.emailAddress} name="groupName" disabled={!approved} focus={true} onChange={(event) => { this.changeEmail(event, index) }} error={theEmail.errorText} />
                                    </div>
                                })}
                                {!approved && this.props.user.userData.registered && <div>
                                    <div className='text-orange'>You can only send previews to yourself until we have approved your account</div>
                                    {!this.props.accountMaster.accountMaster.doneNewUserQuestions && <UILIB.Button className="button-orange mar-t10" onClick={this.activate.bind(this)}>Request Approval</UILIB.Button>}
                                </div>}
                                {!approved && !this.props.user.userData.registered && <div>
                                    <div className='text-red'>You must confirm your email address before you can send previews</div>
                                </div>}
                                {this.state.emailAddresses.length < 5 && approved && <div className="quickFlex" style={{ alignItems: "center" }}>
                                    <UILIB.Button
                                        text={i18n.t('templates:preview.add')}
                                        onClick={this.addEmailAddress}
                                        iconLeft={<UILIB.Icons icon="plus" />}
                                    />

                                </div>}
                            </UILIB.Paper>



                            {groupData.length > 0 && !this.state.CampaignId &&
                                <UILIB.Paper className="mar-b25">
                                    <div>
                                        <UILIB.Select label={i18n.t('templates:preview.sub')} placeholder={i18n.t('templates:preview.selectAGroup')} data={
                                            groupData.map((theGroup, index) => {
                                                return { value: theGroup.id, label: theGroup.groupName }
                                            })} onChange={this.changeGroup} value={this.state.currentGroup} />
                                    </div>
                                    {groupData.length > 0 && this.state.currentGroup >= 0 && <div className="quickFlex mar-t15" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} text={i18n.t('templates:preview.prev')} onClick={this.prevSubscriber} />
                                        <UILIB.Button iconRight={<UILIB.Icons icon="arrowRight" />} text={i18n.t('templates:preview.next')} onClick={this.nextSubscriber} />
                                    </div>}
                                    {this.state.currentSubs && this.state.currentSubs[this.state.previewSubOffset] && <UILIB.Paper className="center-xs mar-t10 mar-b0" style={{ padding: "10px" }}>
                                        <div>Viewing as: </div>
                                        {this.state.currentSubs[this.state.previewSubOffset].emailAddress}
                                    </UILIB.Paper>}
                                </UILIB.Paper>
                            }

                            {!!this.state.CampaignId && <UILIB.Paper>
                                <div className='mar-b10 text-heavy'>See how the content will look for a contact in this campaign</div>
                                <div className="quickFlex" style={{ alignItems: 'center' }}>

                                    <UILIB.Button disabled={this.state.searching} text="Random Contact" className="mar-r10" onClick={this.previewRandom.bind(this)} />
                                    <UILIB.AutoComplete
                                        reset={this.state.reset}
                                        outerStyle={{ flexGrow: 1 }}
                                        placeholder="Or a specific email address"
                                        url="/subscriber?search={{name}}"
                                        parser={d => d.Subscribers.map(a => ({ label: a.emailAddress, value: a.id }))}
                                        onChange={this.updateEmailSearch.bind(this)}
                                    />
                                </div>
                                {!!this.state.previewEmail && <div className="mar-t20">
                                    Previewing as: <strong>{this.state.previewEmail}</strong>
                                </div>}
                            </UILIB.Paper>}


                            <div className="mar-b25">
                                <div className="quickFlex">
                                    <UILIB.Button iconLeft={<UILIB.Icons icon="envelope" />} className="button-primary mar-r10" text={i18n.t('templates:preview.send')} disabled={!this.state.emailAddresses.length || !this.state.emailAddresses[0].emailAddress || !this.props.user.userData.registered} onClick={this.goSendPreview} />
                                    {this.props.user.userData.registered && <UILIB.Button text={i18n.t('templates:preview.downloadPdf')} onClick={this.downloadPdf} disabled={this.state.downloadingPdf} />}
                                </div>
                            </div>
                            {this.state.errorMessage}

                            {this.props.showSettings && <div className="mar-t30">


                                {(this.state.campaignData.Groups.length > 0 || this.state.campaignData.Segments.length > 0) && <div className="mar-b25">
                                    <h4 className="mar-b25">{i18n.t('campaigns:standard.step1.groupsAndSegments')}</h4>
                                    {this.state.campaignData.Groups.map(g => <UILIB.SquareChip key={"Group_" + g.id} tooltip={'Group'} for={g.id} value={g.groupName} className="square-chip-primary" />)}
                                    {this.state.campaignData.Segments.map(s => <UILIB.SquareChip key={"Seg_" + s.id} tooltip={'Segment'} for={s.id} value={s.name} className="square-chip-purple" />)}
                                </div>}

                                {!!this.state.campaignData.batchSize && !!this.state.campaignData.batchDelay && !!this.state.campaignData.batchUnit && <div>
                                    <span dangerouslySetInnerHTML={{
                                        __html: i18n.t('campaigns:main.batchSettings', {
                                            batchSize: textFunctions.checkForHtml(this.state.campaignData.batchSize),
                                            batchDelay: textFunctions.checkForHtml(this.state.campaignData.batchDelay),
                                            batchUnit: textFunctions.checkForHtml(this.state.campaignData.batchUnit)
                                        })
                                    }}></span>
                                </div>}
                            </div>}
                        </div>
                    </UILIB.Column>
                </UILIB.Row>

                {/* <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={this.closeAlert} /> */}

                <UILIB.Drawer standalone={true} width="100vw" isOpen={!!this.state.drawerContent} showClose={true} startClose={() => this.setState({ drawerContent: undefined })}>
                    {this.state.drawerContent}
                </UILIB.Drawer>
            </div >
        }
        else {
            return <div></div>
        }


    };
}

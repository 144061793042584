import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import DBConnection from '~/Classes/siteVars';
import axios from 'axios';
import queryString from 'query-string';
import { connect } from 'react-redux';

//CONNECT TO STORE
@connect((store) => {
    return { siteMaster: store.siteMaster }
})
export default class JunkTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: '',
            junkText: '',
            key: undefined,
            sender: {},
            foundSender: false,
            showDetail: true,
            pageLoaded: false
        };
        this.send = this.send.bind(this);
        this.updateField = this.updateField.bind(this);
        this.retrieveJunkEmail = this.retrieveJunkEmail.bind(this);
    }

    componentDidMount() {

        const query = queryString.parse(this.props.location.search);
        var key = query.key

        this.setState({
            key
        }, this.retrieveJunkEmail)
    }

    retrieveJunkEmail() {
        var baseUrl = DBConnection.getConnString()
        axios.get(baseUrl + `/retrievejunk?key=${this.state.key}`).then(theRes => {
            var showDetail = true;
            var foundSender = true;
            if (theRes.data.siteMaster.id == 2) showDetail = false;
            this.setState({ foundSender: foundSender, sender: theRes.data, showDetail: showDetail, pageLoaded: true })
        })
    }

    send(event) {
        this.setState({
            junkText: ''
        }, () => {
            var baseUrl = DBConnection.getConnString()
            axios.get(baseUrl + `/junk?key=${this.state.key}&message=${encodeURIComponent(this.state.message)}`)
                .then(response => {
                    this.setState({
                        junkText: 'Report Sent'
                    })
                })
                .catch(err => {
                    console.log(err.response.data.error)
                    this.setState({
                        junkText: 'Report Cannot Be Sent'
                    })
                })
        })

    }

    updateField(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {

        if (!this.state.pageLoaded) return <div></div>

        return <div className="container-fluid" style={{ marginTop: 32 }}>
            <UILIB.Row>
                {this.state.showDetail && <UILIB.Column xs={12} md={12} lg={6} className="col-lg-offset-3">
                    <div style={{ backgroundColor: this.state.sender.siteMaster.colours.$altBackgroundColor, padding: "20px" }} className="text-center">
                        <img src={this.state.sender.siteMaster.siteLogo} width="200" />
                        <div style={{ color: this.state.sender.siteMaster.colours.$white }}>Junk Reporting Programme</div>
                    </div>

                </UILIB.Column>}


                <UILIB.Column xs={12} md={12} lg={6} className="col-lg-offset-3">
                    {this.state.showDetail && <UILIB.Paper>
                        <div className="title">
                            Reporting a Junk Email:
                        </div>
                        <div className="mar-b20"><strong>We're really sorry to hear you have received a spam that has originated from our system.</strong>
                            <div className="mar-b10" />
                            We work really hard to ensure our users do not send unwanted emails from our network and reports from people such as yourself make a big difference.
                            Please check through the following sections, get in direct contact with the sender if you can,
                            and then complete the junk report form towards the bottom of the page. Be sure to include as much detail as you can so we can investigate the source of this spam.</div>
                    </UILIB.Paper>}

                    {this.state.showDetail && this.state.foundSender && <UILIB.Paper>
                        <div className="title">
                            Who Sent This Email...
                        </div>

                        <div className="mar-b20">
                            The information we have of the person who sent this email is listed below. <div className="mar-b10" />
                            We encourage you to communicate directly with the sender using these details...
                            <div className="mar-b20"></div>
                            <strong>{this.state.sender.accountName}</strong>
                            <div></div>
                            {this.state.sender.address1 ? this.state.sender.address1 + ", " : ""}
                            {this.state.sender.address2 ? this.state.sender.address2 + ", " : ""}
                            {this.state.sender.address3 ? this.state.sender.address3 + "," : ""}
                            <div></div>
                            {this.state.sender.town ? this.state.sender.town + ", " : ""}
                            {this.state.sender.county ? this.state.sender.county + ", " : ""}
                            {this.state.sender.country ? this.state.sender.country + "." : ""}
                            {this.state.sender.postcode ? this.state.sender.postcode + "." : ""}
                            {this.state.sender.website ? <div className="t-20">Website: <a href={this.state.sender.website} target="_blank">{this.state.sender.website}</a></div> : ""}


                        </div>
                    </UILIB.Paper>}

                    <UILIB.Paper className="secondary">
                        <div className="title">
                            Send us your Junk Report:
                        </div>
                        <div className="mar-b20">
                            Please complete the following form with as much detail as possible about the email, or emails you have received. Please include what you were sent, when you were sent emails and wether you remember visiting the website or contacting the sender in the past.
                            <div className="mar-b10"></div>
                            A member of {this.state.foundSender && this.state.showDetail ? <span>the {this.state.sender.siteMaster.siteName} team </span> : <span>our team</span>} will then contact the sender and find out what's been going on!
                        </div>
                        <div className="form-group">

                            <UILIB.TextArea placeholder="Please type your junk report in as much detail as possible here..." name="message" rows="4" value={this.state.message} onChange={this.updateField} />
                        </div>
                        <UILIB.Button className="outline button-md min-width" onClick={this.send}>Submit Junk Report</UILIB.Button>
                        {this.state.junkText}
                    </UILIB.Paper>

                    {this.state.showDetail &&
                        <UILIB.Paper>
                            <div className="title">
                                About {this.state.sender.siteMaster.siteName}
                            </div>
                            <div className="mar-b20">

                                {this.state.sender.siteMaster.siteName} is a self service Email Marketing Platform that enables people to capture email subscribers via Opt In Forms, import and manage existing subscribers, design beautiful email templates and finally send campaigns and automatic emails.
                            </div>
                            <div className="mar-b10">
                                We send millions of emails a day, and sometimes it can be a challenge figuring out when somebody is sending to people they shouldn't be. We really appreciate your time in letting us know. Without reports like this weeding out spammers is all the more difficult.
                            </div>
                            <div className="text-lg">
                                Thank You! ❤️
                            </div>
                        </UILIB.Paper>
                    }
                </UILIB.Column>
            </UILIB.Row>
        </div >

    }
}

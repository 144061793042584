import React, { Component } from 'react';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import i18n from '~/i18n'
import { response } from '../../transactions/settings/codeParameters';

@connect((store) => {
    return { siteMaster: store.siteMaster }
})
class AutomationThemeSelector extends Component {
    constructor(props) {
        super(props);
        this.state = { themes: [], loaded: false, tierPermissions: {} }

        this.clickedTheme = this.clickedTheme.bind(this);
        this.getTierPermission = this.getTierPermission(this);
    }

    componentDidMount() {
        this.getThemes()
    }


    getThemes() {
        axios.get('/automation/themes').then(response => {
            this.setState({
                themes: response.data.Themes
            }, this.getTierPermission)
        })
    }

    getTierPermission() {

        axios.get("/automation/tierPermission").then(_res => {
            this.setState({ tierPermissions: _res.data, loaded: true, })
        }).catch(err => {
            console.log(err)
        })
    }

    clickedTheme(themeType, theme) {
        if (themeType == "theme") {
            this.props.select(theme)
        }
        else {
            this.props.scratch();
        }
        this.setState({ loaded: false })
    }

    render() {
        if (!this.state.loaded) return <div className="mar-t30"><UILIB.LoadingIcons iconType="2" /></div>
        const count = this.state.themes.length
        let columns = 4
        if (count > 6) columns = 3
        if (count > 10) columns = 2

        let notAllowed = "";
        // if (this.state.tierPermissions) {
        //     if (this.state.tierPermissions.err) {
        //         if (this.state.tierPermissions.err == "tierForbids") notAllowed = "tierForbids";
        //     }
        // }

        return (
            <>
                <h4 className="mar-b30 mar-t40">{i18n.t('automation:add.header')}</h4>

                {/* {notAllowed && <UILIB.TierLimitHolder type="Automation" err={notAllowed} allowedNumber={this.state.tierPermissions.allowedNumber} tierName={this.state.tierPermissions.tierName} />} */}

                <UILIB.Row>
                    <UILIB.Column xs={12} sm={6} md={columns} lg={columns} >
                        <Card
                            icon={<UILIB.Icons icon="pencil" circle={true} />}
                            title={i18n.t('automation:overview.frmScratch')}
                            content={i18n.t('automation:overview.createBrandNewAutomation')}
                            button={<UILIB.Button
                                className="button-primary"
                                iconRight={<UILIB.Icons icon="arrowRight" />}
                                onClick={() => this.clickedTheme("scratch")}
                                disabled={!!notAllowed}>{i18n.t('automation:overview.btnSelect')}</UILIB.Button>}

                        />
                    </UILIB.Column>
                    {this.state.themes.map((theme, index) => {

                        return <UILIB.Column key={"theme_" + index} xs={12} sm={6} md={columns} lg={columns}>
                            <Card
                                icon={theme.Application && theme.Application.appLogoUri ? <UILIB.Avatar src={theme.Application.appLogoUri} style={{ height: 30, width: 30 }} /> : theme.icon && theme.icon.indexOf('icon-') === 0 ? <span style={{ fontSize: 24, color: this.props.siteMaster.colours.$primary }} className={theme.icon} /> : <UILIB.Icons icon={theme.icon} circle={true} />}
                                title={i18n.t('automation:themes.' + theme.id + '_name', theme.name)}
                                chip={theme.Application ? <UILIB.SquareChip value={theme.Application.appName} className="square-chip-blue mar-t20" /> : theme.featureChipText && theme.featureChipText.length && <UILIB.SquareChip value={theme.featureChipText} className="square-chip-green mar-t20" />}
                                content={i18n.t('automation:themes.' + theme.id + '_desc', theme.description)}
                                button={<UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => this.clickedTheme("theme", theme)} disabled={!!notAllowed}>{i18n.t('automation:overview.btnSelect')}</UILIB.Button>}
                            />

                        </UILIB.Column>

                    })}
                </UILIB.Row>
            </>
        );
    }
}

export default AutomationThemeSelector;

function Card({ icon, title, content, button, chip }) {
    return (
        <UILIB.Paper style={{ height: 230 }}>
            <div style={{ display: 'flex', overflow: 'hidden', height: '100%' }}>
                <div className="mar-r20">{icon}</div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h4 style={{ marginBottom: 10 }}>{title}</h4>
                    <div style={{
                        whiteSpace: 'pre-line',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>{content}</div>
                    {chip}
                    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start', marginTop: 10 }}>
                        {button}
                    </div>
                </div>
            </div>
        </UILIB.Paper>
    )
}
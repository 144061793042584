import React, { Component } from 'react';
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';
import Theme from './theme';
import PopPreviewTheme from './popPreviewTheme';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, browser: store.browser, permissionStore: store.permission, accountMaster: store.accountMaster }
})


class ThemesComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingGroups: true,
            loadingThemes: true,
            groups: [],
            themes: [],
            selectedGroup: undefined,
            lastGroup: undefined,
            count: 0,
            limit: 12,
            page: 0,
            searchText: '',
            selectedTheme: undefined,
            previewTheme: false
        }

        let searchTimer;

        this.getGroups = this.getGroups.bind(this);
        this.getThemes = this.getThemes.bind(this);
        this.selectGroup = this.selectGroup.bind(this);
        this.search = this.search.bind(this);
        this.updateSearchText = this.updateSearchText.bind(this);
        this.previewTheme = this.previewTheme.bind(this);
        this.selectTheme = this.selectTheme.bind(this);
    }

    componentDidMount() {
        this.getGroups()
    }

    getGroups() {
        axios.get('/themegroup')
            .then(response => {
                var groups = []
                var index = response.data.ThemeGroups.findIndex(g => g.name == 'Featured')
                if (index > -1) groups = response.data.ThemeGroups.splice(index, 1)
                groups = groups.concat(response.data.ThemeGroups)
                let defaultGroupId = undefined;
                if (groups.length && groups[0].id) defaultGroupId = groups[0].id;
                if (this.props.defaultThemeGroupId) {
                    if (this.props.defaultThemeGroupId == 1) {
                        //find the branded group
                        let foundDefaultGroup = groups.find(g => g.name == "Branded")
                        if (foundDefaultGroup) defaultGroupId = foundDefaultGroup.id;
                    }

                }

                groups.splice(1, 0,
                    {
                        "ApiKeyId": null,
                        "SiteMasterId": null,
                        "createdAt": "2022-01-01T00:00:00.000Z",
                        "id": -5000,
                        "name": "Blank",
                        "updatedAt": "2022-01-01T00:00:00.000Z"
                    })


                this.setState({
                    selectedGroup: defaultGroupId,
                    lastGroup: defaultGroupId,
                    groups,
                    loadingGroups: false
                }, this.getThemes)
            })
    }

    getThemes() {

        let themeGroupType = 1;
        let themeGroupId = this.state.selectedGroup;
        if (this.state.selectedGroup === -5000) {
            themeGroupType = 0;
            themeGroupId = 0;
        }
        var queryStr = `?type=${themeGroupType}&orderBy=createdAt&orderDir=desc&limit=${this.state.limit}&offset=${this.state.limit * this.state.page}`;
        if (this.state.searchText && this.state.searchText.length > 2) queryStr += '&search=' + this.state.searchText;
        var url = '/theme';
        if (themeGroupId) {
            url = '/themegroup/' + themeGroupId + '/themes';
        }
        axios.get(url + queryStr)
            .then(response => {
                this.setState({
                    themes: response.data.Themes,
                    count: response.data.count,
                    loadingThemes: false
                })
            })
    }

    selectGroup(id) {
        this.setState({
            selectedGroup: id,
            lastGroup: id,
            searchText: ''
        }, this.getThemes)
    }

    search() {
        this.setState({
            lastGroup: this.state.selectedGroup || this.state.lastGroup,
            selectedGroup: this.state.searchText ? undefined : this.state.selectedGroup,
        }, this.getThemes)
    }

    updateSearchText(ev) {
        this.setState({
            searchText: ev.currentTarget.value,
            selectedGroup: ev.currentTarget.value ? undefined : this.state.lastGroup
        }, () => {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer)
            }
            this.searchTimer = setTimeout(this.search, 200)
        })
    }


    previewTheme(event, theme) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ previewTheme: true, selectedTheme: theme })

    }

    selectTheme(theme) {
        this.setState({ previewTheme: false }, () => { this.props.onSelect(theme) });

    }
    render() {
        if (!this.state.loadingGroups) {
            return (
                <div>
                    <UILIB.Row className="mar-b25">
                        <UILIB.Column md={4} lg={3} hide={['sm', 'xs']}>
                            <UILIB.Paper className="height100">
                                <div className="mar-b15">
                                    <UILIB.TextInput
                                        name="searchText"
                                        className="noShadow"
                                        clickToChangeClicked={this.changeSearch}
                                        onEnterPress={this.search}
                                        onChange={this.updateSearchText}
                                        value={this.state.searchText}
                                        style={{ paddingRight: "35px" }}
                                        placeholder={i18n.t('templates:add.searchHere')}
                                        iconLeft={<UILIB.Icons icon="magnifier" onClick={() => { }} />}
                                    />
                                </div>
                                {this.state.groups.map((group, index) => {
                                    return <div key={"themeTab_" + index} className="template-themeselector-category">
                                        <UILIB.ButtonSimple
                                            key={"theme" + index}
                                            className="button-simple-fullsize"
                                            selected={this.state.selectedGroup == group.id}
                                            onClick={() => { this.selectGroup(group.id) }}>
                                            {group.name}
                                        </UILIB.ButtonSimple>
                                    </div>
                                })}
                            </UILIB.Paper>
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={12} hide={['md', 'lg']}>
                            <UILIB.Select outerClassName="mar-b25" onChange={(ev) => { this.selectGroup(ev.target.value) }} value={this.state.selectedGroup} data={this.state.groups.map((group, index) => {
                                return {
                                    label: group.name,
                                    value: group.id
                                }
                            })} />

                            <UILIB.TextInput
                                name="searchText"
                                clickToChangeClicked={this.changeSearch}
                                onEnterPress={this.search}
                                onChange={this.updateSearchText}
                                value={this.state.searchText}
                                style={{ paddingRight: "35px" }}
                                placeholder={i18n.t('templates:add.searchHere')}
                                iconLeft={<UILIB.Icons icon="magnifier" onClick={() => { }} />}
                                outerClassName="mar-b25"
                            />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={12} md={8} lg={9}>
                            <UILIB.Paper >
                                {this.state.loadingThemes && <div style={{ marginTop: '50px' }}>
                                    <UILIB.LoadingIcons iconType="2" width="100px" />
                                </div>}

                                {!this.state.loadingThemes && <UILIB.Row>
                                    {!this.state.themes.length && <div style={{ width: '100%' }} className="center-xs">{i18n.t('templates:add.noTemplatesFound')}</div>}

                                    {this.state.themes.map((theme, index) => {
                                        return <UILIB.Column xs={12} sm={6} md={6} lg={4} key={index}>
                                            <Theme theme={theme} select={() => { this.selectTheme(theme) }} preview={(event) => this.previewTheme(event, theme)} />
                                        </UILIB.Column>
                                    })}

                                </UILIB.Row>}
                            </UILIB.Paper>
                        </UILIB.Column>

                    </UILIB.Row >
                    {this.state.previewTheme && <UILIB.Drawer close={() => this.setState({ previewTheme: false })} isOpen={true} standalone={true} width={"100%"} showClose={true}>
                        <PopPreviewTheme theme={this.state.selectedTheme} selectTemplate={() => this.selectTheme(this.state.selectedTheme)} />
                    </UILIB.Drawer>}
                </div>
            );
        } else {
            return <div style={{ marginTop: '50px' }}>
                <UILIB.LoadingIcons iconType="2" className="mar-b30" />
            </div>
        }
    }
}

export default ThemesComponent;
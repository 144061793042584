import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here

@connect((store) => {
    return { user: store.user, account: store.accountMaster, siteMaster: store.siteMaster, permissionStore: store.permission }
})

class DowngradeToFree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            really: "",
            reallyError: "",
            reason: "",
            downgradeQuestions: [
                { value: "costSaving", label: "Cost-saving reasons" },
                { value: "fewerFeatures", label: "Need for fewer features" },
                { value: "valueForMoney", label: "Unsatisfied with the value for money" },
                { value: "betterAlternative", label: "Found a better alternative" },
                { value: "tempBudgetConstraint", label: "Temporary budget constraints" },
                { value: "missingFeatures", label: "Missing important features / functionality" },
                { value: "noTimeToUse", label: "No time to use the software" },
                { value: "dontKnowHowToUse", label: "Didn't know how to use the software" },
                { value: "poorSupport", label: "Poor support from Transpond team" },
                { value: "badUi", label: "Didn't like the User Interface" },
                { value: "other", label: "Other (Please specify)" }
            ],
            downgradeAnswer: ""
        }
        this.goDownGrade = this.goDownGrade.bind(this);
    }

    goDownGrade() {
        var self = this;
        if (!this.state.really || this.state.really.toLowerCase() != "really cancel") {
            this.setState({ reallyError: "Please type \"REALLY CANCEL\" into this box to proceed" })
            return;
        }
        this.setState({ reallyError: "" })

        axios.put('/accountMaster/plan/cancel', { notes: this.state.reason, reason: this.state.downgradeAnswer }).then(_res => {
            self.props.close(true);
        })
    }

    render() {
        if (this.props.currentlyFree) return <>
            <div className="mar-b25 text-heavy">You are currently on the Free Plan</div>
            <div className="mar-b25">Your account will refresh in {this.props.daysLeft} days, when your free contact limit will be reset.</div>

        </>

        if (this.props.nextFree) return <>
            <div className="mar-b25 text-heavy">You are already moving to the free plan</div>
            <div className="mar-b25">Your account will be downgraded to a Free plan in {this.props.daysLeft} days when your current billing period ends.</div>

        </>

        return (
            <div>
                <div className="mar-b25 text-heavy">Cancel your current subscription, and return to a free account.</div>
                <div className="mar-b25">By clicking the "Cancel my Subscription"" button below, your account will be downgraded to a Free plan in {this.props.daysLeft} days when your current billing period ends. No further charges will be made</div>

                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <UILIB.Select
                        className="mar-b25"
                        label="What prompted your decision to switch from a paid plan to a free plan?"
                        data={this.state.downgradeQuestions}
                        value={this.state.downgradeAnswer}
                        onChange={(ev) => { this.setState({ downgradeAnswer: ev.target.value }) }}
                    />

                    {this.state.downgradeAnswer && <>
                        <UILIB.TextArea outerClassName="mar-b25" label="We are always trying to improve, can you give us any more details?" value={this.state.reason}
                            onChange={ev => this.setState({ reason: ev.target.value })} />

                        <UILIB.TextInput outerClassName="mar-b25" label="Finally, Please type REALLY CANCEL below to continue.." name="really" value={this.state.really} onChange={(event) => { this.setState({ really: event.target.value }) }} error={this.state.reallyError} />


                        <div className="quickFlex">
                            <UILIB.Button className="button-red" text="Cancel My Subscription" disabled={!this.state.really || this.state.really.toLowerCase() != "really cancel"} onClick={this.goDownGrade} />
                        </div>


                    </>}
                </div>


            </div >
        );
    }
}

export default DowngradeToFree;
import i18n from '~/i18n'
class Industries {
    static industryArray(translationLanguage) {
        let industries = i18n.t('dashboard:Industries', { returnObjects: true });

        let output = Object.keys(industries).map(key => {
            let label = industries[key];
            if (typeof label === 'object') {
                if (label[key.toLowerCase()]) {
                    label = label[key.toLowerCase()];
                } else {
                    // just grab the first one
                    for (let lang in label) {
                        if (typeof label === 'string') break;
                        label = label[lang];
                        break;
                    }
                }
            }
            return { label: label, value: key }
        })
        return output;
    }
}

export default Industries;
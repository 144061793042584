import React, { useEffect, useState } from 'react'
import axios from '~/data/http/axios'
import UILIB from '~/Common-Objects/Lib'
import EmailValidator from '~/Classes/emailValidator'

async function checkLink(templateId, link) {
    const res = await axios.post('/template/' + templateId + '/links/validate', {
        link
    })
    return res.data.valid
}

export default function EditLink({ link, CampaignId, TemplateId, onFinish = () => { } }) {
    const [url, setUrl] = useState(link.link)
    const [valid, setValid] = useState(false)
    const [linkChanged, setLinkChanged] = useState(false)

    const updateLink = async () => {
        const result = await axios.put('/campaign/' + CampaignId + '/templatelink/' + link.id, {
            link: url
        })
        onFinish(result.data)
    }

    const testLink = async () => {
        setValid(null)
        setLinkChanged(false)
        if (url && url.trim()) {
            const isValid = await checkLink(TemplateId, url)
            setValid(isValid)
        } else {
            setValid(false)
        }
    }

    const changeUrl = e => {
        setUrl(e.currentTarget.value)
        setLinkChanged(true)
    }

    useEffect(() => {
        testLink()
    }, [link.id])

    let error = ''
    let classes = ''
    if (valid !== null) {
        if (!url || url === 'http://' || url === 'http://') {
            error = 'URL Blank'
            if (!link.src) {
                classes += ' text-red'
            }
        } else if (url.indexOf('mailto:') === 0 && url.indexOf('{{') === -1) {
            let emailAddress = url.split('mailto:')[1]
            if (emailAddress) {
                emailAddress = emailAddress.split('?')[0]
            }
            if (!EmailValidator.validateEmail(emailAddress)) {
                classes += ' text-red'
                error = 'Invalid Email Address'
            } else {
                classes += ' text-green-auto'
                error = 'Email Valid'
            }
        } else if (url.indexOf('tel://') === 0) {
            if (url === 'tel://') {
                classes += ' text-red'
                error = 'Telephone Blank'
            } else {
                classes += ' text-green-auto'
                error = 'Telephone Valid'
            }
        } else {
            if (!valid) {
                classes += ' text-red'
                error = 'URL Invalid'
            } else {
                classes += ' text-green-auto'
                error = 'URL Valid'
            }
        }
    }

    return (
        <div>
            <h4 className="mar-b25">Change Link</h4>

            <UILIB.TextInput label="URL" value={url} onChange={changeUrl} error={!url ? ' ' : ''} />

            <div>
                {!linkChanged && valid === null && <p className="loading">Checking Link</p>}
                {linkChanged && <p>Link Changed...please test</p>}
                {!linkChanged && valid !== null && <p className={classes}>{error}</p>}
            </div>

            <UILIB.Button className="button-md button-primary mar-r10" text="Save" onClick={updateLink} disabled={!valid} iconLeft={<UILIB.Icons icon="tick" />} />
            <UILIB.Button className="button-md mar-r10" text="Test" onClick={testLink} iconLeft={<UILIB.Icons icon="cog" />} />
            <UILIB.Button className="button-md" text="Cancel" onClick={onFinish} iconLeft={<UILIB.Icons icon="cross" />} />

        </div >
    )
}
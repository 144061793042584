import React, { Component } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import clone from 'lodash/clone'
import each from 'lodash/each'
import lodashGet from 'lodash/get'
import lodashSet from 'lodash/set'
import invoke from 'lodash/invoke'
import DragDropFunctions from '../../dragDropFunctions';
import Table from './table/table'
import TableCanvasHolder from './table/tableCanvasHolder'
import TableItemHolder from './table/tableItemHolder'
import TableOnlyOptions from './table/tableOnlyOptions'
import Tr from './tr/tr'
import Td from './td/td'
import TdCanvasColumn from './td/tdCanvasColumn'
import Div from './div/div'
import DivItemHolder from './div/divItemHolder'
import DivEditable from './div/divEditable'
import DivColumnHolder from './div/divColumnHolder'
import DivCanvas from './div/divCanvas'
import A from './a/a'
import Img from './img/img'
import Span from './span/span'
import Tbody from './tbody/tbody'
import Label from './label'
import Input from './input'
import TextArea from './textarea'
import Button from './button'
import Checkbox from './checkbox'
import Select from './select'
import SelectOption from './select/option'
import Hr from './hr/hr'
import Poll from './poll/poll'
import moment from 'moment'
import axios from '~/data/http/axios';
import RssFeed from './rssFeed/rssFeed'
import Captcha from './captcha/captcha'
export default class Canvas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            imagesReloading: {}
        }
        this.moveElement = this.moveElement.bind(this);
        this.insertElement = this.insertElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.copyElement = this.copyElement.bind(this);
        this.addColumn = this.addColumn.bind(this);
        this.replacePropertyValue = this.replacePropertyValue.bind(this);
        this.updateElement = this.updateElement.bind(this);
        this.updateImage = this.updateImage.bind(this);
        this.checkForImageUpdates = this.checkForImageUpdates.bind(this);
    }

    componentDidMount() {
        this.unmounting = false
        this.checkForImageUpdates();
    }

    componentWillUnmount() {
        this.unmounting = true
    }

    moveElement(currentElementPath, hoveredElementPath, insertingIntoParent) {
        var elementsData = cloneDeep(this.props.elementsData);
        var movingElement = cloneDeep(lodashGet(elementsData, currentElementPath));
        var hoveredElement = cloneDeep(lodashGet(elementsData, hoveredElementPath));
        movingElement = this.replacePropertyValue("eleId", movingElement);

        var movingIndex = currentElementPath.substring(currentElementPath.lastIndexOf("[")).replace('[', '').replace(']', '')
        var targetIndex = hoveredElementPath.substring(hoveredElementPath.lastIndexOf("[")).replace('[', '').replace(']', '')

        var movingPathShort = currentElementPath.substring(0, currentElementPath.lastIndexOf(".")) + ".elements"
        invoke(elementsData, movingPathShort + '.splice', movingIndex, 1);

        var finalElementPath = hoveredElementPath;
        var targetPathShort = "";

        if (insertingIntoParent === true) {

            targetPathShort = hoveredElementPath + ".elements"
            var upOrDown = DragDropFunctions.movingUpDown(currentElementPath, hoveredElementPath);
            if (upOrDown === -1 && hoveredElement.elements.length > 1) {
            }
            else {
                //its an empty element (ie a column)
                targetPathShort = hoveredElementPath + ".elements"
                invoke(elementsData, targetPathShort + '.push', movingElement);
                //set the current path to 0
                finalElementPath = hoveredElementPath + ".elements[0]"
            }
        }
        else {
            targetPathShort = hoveredElementPath.substring(0, hoveredElementPath.lastIndexOf(".")) + ".elements"
            invoke(elementsData, targetPathShort + '.splice', targetIndex, 0, movingElement);
        }

        this.props.updateElementsData(elementsData, finalElementPath);

    }

    insertElement(hoveredElementPath, currentElementContent, insertingIntoParent) {
        var copyContent = cloneDeep(currentElementContent);
        var elementsData = cloneDeep(this.props.elementsData);
        copyContent = this.replacePropertyValue("eleId", currentElementContent);
        var newElementPath = hoveredElementPath;

        var elPathShort = "";
        if (!insertingIntoParent) {
            var newIndex = Number(hoveredElementPath.substring(hoveredElementPath.lastIndexOf("[")).replace('[', '').replace(']', '')) + 1;
            newElementPath = hoveredElementPath.substring(0, hoveredElementPath.lastIndexOf(".")) + ".elements[" + newIndex + "]"
            elPathShort = hoveredElementPath.substring(0, hoveredElementPath.lastIndexOf(".")) + ".elements"
            invoke(elementsData, elPathShort + '.splice', newIndex, 0, copyContent);


        } else {
            elPathShort = hoveredElementPath
            invoke(elementsData, elPathShort + '.elements.push', copyContent);
            var hoveredElement = lodashGet(elementsData, hoveredElementPath);
            if (!hoveredElement.length) {
                newElementPath += ".elements[0]"
            }

        }

        this.props.updateElementsData(elementsData, newElementPath);

    }

    copyElement(elementPath) {
        var elementsData = cloneDeep(this.props.elementsData);
        var copyEl = cloneDeep(lodashGet(elementsData, elementPath));
        copyEl = this.replacePropertyValue("eleId", copyEl);
        copyEl = this.replacePropertyValue("rssId", copyEl);

        var elPathShort = elementPath.substring(0, elementPath.lastIndexOf(".")) + ".elements"
        var elementIndex = elementPath.substring(elementPath.lastIndexOf("[")).replace('[', '').replace(']', '')
        invoke(elementsData, elPathShort + '.splice', elementIndex, 0, copyEl);

        var newElementPath = elPathShort + ".elements[" + elementIndex + "]"

        this.props.updateElementsData(elementsData, elementPath);
    }

    deleteElement(elementPath) {
        var elementsData = cloneDeep(this.props.elementsData);
        var elPathShort = elementPath.substring(0, elementPath.lastIndexOf(".")) + ".elements"
        var elementIndex = elementPath.substring(elementPath.lastIndexOf("[")).replace('[', '').replace(']', '')
        invoke(elementsData, elPathShort + '.splice', elementIndex, 1);
        this.props.updateElementsData(elementsData, "");
    }

    addColumn(holderIndex) {
        var elementsData = cloneDeep(this.props.elementsData);
        var thisEl = cloneDeep(elementsData.elements[1].elements[holderIndex].elements[0].columns);
        var newCol = cloneDeep(thisEl[thisEl.length - 1]);
        thisEl.splice(thisEl.length - 1, 0, newCol)

        //adjust widths of all cols - might wanna remove this later i dunno
        var colWidth = Math.round(100 / thisEl.length);

        thisEl.map((theEl, index) => {
            theEl.props.width.value = colWidth + "%";
            return theEl;
        })

        thisEl = this.replacePropertyValue("eleId", thisEl);
        //add the new cols
        elementsData.elements[1].elements[holderIndex].elements[0].columns = thisEl;

        this.props.updateElementsData(elementsData, holderIndex, null, null, null);

    }

    updateElement(newContent, targetPath, overRideFinalPath) {

        var finalPath = ".elements";
        var elementsData = cloneDeep(this.props.elementsData);
        var elPathShort = targetPath.substring(0, targetPath.lastIndexOf(".")) + finalPath
        if (overRideFinalPath) {
            if (overRideFinalPath == "NONE") {
                elPathShort = targetPath;
            }
            else {
                elPathShort = targetPath + overRideFinalPath
            }
        }
        var copyEl = cloneDeep(lodashGet(elementsData, elPathShort));
        if (!Array.isArray(copyEl)) {
            lodashSet(elementsData, elPathShort, newContent)
        }
        else {
            invoke(elementsData, elPathShort + '.splice', 0, 1, newContent);
        }
        this.props.updateElementsData(elementsData, null);
    }

    replacePropertyValue(searchKey, object) {
        const newObject = clone(object);
        each(object, (val, key) => {
            if (key === searchKey) {
                var newVal = "";
                newVal = Math.floor(Math.random() * 999999);

                newObject[key] = newVal;
            } else if (typeof (val) === 'object' || typeof (val) === 'array') {
                newObject[key] = this.replacePropertyValue(searchKey, object[key]);
            }
        });
        return newObject;
    }

    updateImage(imageData) {
        var updateDate = moment().format();
        var queue = this.state.imagesReloading;
        if (queue[imageData.tuuid]) return;
        queue[imageData.tuuid] = { tuuid: imageData.tuuid, updateDate: updateDate, imageWidth: imageData.imageWidth, imageHeight: imageData.imageHeight, imageUri: imageData.imageUri };
        this.setState({ imagesReloading: queue })
    }

    checkForImageUpdates() {
        if (this.unmounting) return
        var self = this;
        function findImageByTUUID(tuuid, elements, path) {
            var foundPath = "";
            if (elements && typeof elements == "object") {
                Object.keys(elements).forEach(key => {

                    if (Array.isArray(elements[key]) || typeof elements[key] == "object") {
                        var tmpPath = path;
                        if (Array.isArray(elements[key])) {
                            tmpPath += key + "[";
                        }
                        else { tmpPath += key + "]."; }
                        foundPath += findImageByTUUID(tuuid, elements[key], tmpPath)
                    }
                    else {

                        if (key === "tuuid") {
                            if (elements[key] == tuuid) foundPath = path;
                        }
                    }
                })
            }
            return foundPath;
        }

        if (self.state.imagesReloading && Object.keys(self.state.imagesReloading).length) {
            var promises = [];

            var imagesReloading = this.state.imagesReloading;
            var foundUpdate = false;
            Object.keys(imagesReloading).forEach(image => {
                var thisImage = imagesReloading[image];
                if (moment().diff(thisImage.updateDate, 'seconds') >= 0) {
                    foundUpdate = true;
                    promises.push(
                        axios.post(`/template/resizeImage/${self.props.templateId}`, { imageUri: thisImage.imageUri, imageHeight: thisImage.imageHeight, imageWidth: thisImage.imageWidth }).then(_res => {
                            return { result: _res.data, thisImage, tuuid: image }
                        }).catch(err => {
                            return { result: false, thisImage, tuuid: image }
                        })
                    )
                }
            })
            if (foundUpdate) {


                Promise.all(promises).then((_response) => {

                    _response.forEach(_img => {
                        delete imagesReloading[_img.tuuid];
                        if (_img && _img.result && _img.result.newFileUri) {
                            //get our local image 

                            var imagePath = findImageByTUUID(_img.thisImage.tuuid, self.props.elementsData, "")
                            imagePath = imagePath.substr(0, imagePath.length - 1)
                            var ourData = lodashGet(self.props.elementsData, imagePath)
                            if (ourData && ourData.localVariables && ourData.localVariables.imageSrc) {
                                ourData.localVariables.imageSrc.value = _img.result.newFileUri;
                            }
                            try {
                                ourData.elements[0].elements[0].elements[0].elements[0].elements[0].elements[0].elements[0].props.src.value = _img.result.newFileUri;
                            }
                            catch (err) { }
                            lodashSet(self.props.elementsData, imagePath, ourData)
                        }
                    })

                    self.props.updateElementsData(self.props.elementsData, null);

                    setTimeout(self.checkForImageUpdates, 500);
                })
            }
            else {
                setTimeout(self.checkForImageUpdates, 500);
            }
        }
        else {
            setTimeout(self.checkForImageUpdates, 500);
        }

    }

    render() {
        var propsToPass = {
            selectedElement: this.props.selectedElement,
            elementsData: this.props.elementsData,
            moveElement: this.moveElement,
            insertElement: this.insertElement,
            deleteElement: this.deleteElement,
            copyElement: this.copyElement,
            addColumn: this.addColumn,
            updateElement: this.updateElement,
            clickElement: this.props.clickElement,
            globalVariables: this.props.elementsData.globalVariables,
            saveFaveColor: this.props.saveFaveColor,
            currentParentPath: "",
            updateBlocks: this.props.updateBlocks,
            showGuideLines: this.props.showGuideLines,
            templateId: this.props.templateId,
            updateImage: this.updateImage
        };


        var thisStyle = this.props.elementsData.elements.find((item) => { return item.type === "style" });
        if (thisStyle === undefined) {
            if (this.props.elementsData.elements[0].type === "head")
                thisStyle = this.props.elementsData.elements[0];
        }
        var finalStyle = thisStyle ? DragDropFunctions.generateHtmlFromJson(thisStyle, true, this.props.elementsData.globalVariables, ".templateBody") : ""


        var self = this;
        function parseHTML(theEl, currentPath, localVariables, tuuid) {
            if (theEl.renderOnly) return;
            return theEl.elements.map((subEl, index) => {
                var theOut;
                var tmpPath;
                if (currentPath == "") {
                    tmpPath = currentPath + "elements[" + index + "]";
                }
                else {
                    tmpPath = currentPath + ".elements[" + index + "]";
                }
                if (subEl && subEl.localVariables) {
                    localVariables = subEl.localVariables;
                }

                if (subEl && subEl.tuuid) {
                    tuuid = { tuuid: subEl.tuuid };
                    if (subEl.imgTemp) tuuid.imgTemp = subEl.imgTemp
                }


                if (subEl && subEl.version) {
                    propsToPass.currentParentPath = tmpPath;
                }

                if (subEl && subEl.enabled) {
                    //if we do have an enabled flag, just make sure its true or false. If false dont display the component
                    if (subEl.enabled == false || subEl.enabled == "0" || subEl.enabled == "0%" || subEl.enabled == "0px") return;
                    if (subEl.enabled.indexOf("localVariables_") > 0) {

                        if (localVariables[subEl.enabled.replace("_localVariables_.", "")].value == "false" || localVariables[subEl.enabled.replace("_localVariables_.", "")].value == "0" || localVariables[subEl.enabled.replace("_localVariables_.", "")].value == "0%" || localVariables[subEl.enabled.replace("_localVariables_.", "")].value == "0px") {
                            return;
                        }
                    }
                }

                var imageReloading = false;
                if (self.state.imagesReloading[tuuid.tuuid]) imageReloading = true;

                if (subEl && subEl.type) {
                    switch (subEl.type) {
                        case "table":
                            switch (subEl.dragDrop) {
                                case "canvasHolder":
                                    theOut = <TableCanvasHolder key={"tbl_" + index} elementData={subEl} path={tmpPath} localVariables={localVariables} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </TableCanvasHolder>;
                                    break;
                                case "itemHolder":
                                    theOut = <TableItemHolder key={"tbl2_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </TableItemHolder>;
                                    break;
                                case "onlyOptions":
                                    //allows clicks for options but no dragging
                                    theOut = <TableOnlyOptions key={"tbl_" + index} elementData={subEl} path={tmpPath} localVariables={localVariables} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </TableOnlyOptions>;
                                    break;
                                default:
                                    theOut = <Table key={"tbl3_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath}  {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </Table>;
                            }

                            break;

                        case "tr":
                            theOut = <Tr key={"tr_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                            </Tr>;
                            break;

                        case "tbody":
                            theOut = <Tbody key={"tbody_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                            </Tbody>;
                            break;
                        case "td":
                            switch (subEl.dragDrop) {
                                case "canvasColumn":
                                    theOut = <TdCanvasColumn key={"td_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </TdCanvasColumn>;
                                    break;
                                case "itemHolder":
                                    theOut = <DivItemHolder elementType="td" key={"div3_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </DivItemHolder>;
                                    break;
                                default:
                                    theOut = <Td key={"td2_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </Td>;
                            }
                            break;

                        case "div":
                            // if (subEl.content && subEl.content.editable) {
                            //     theOut = <DivEditable key={"div1_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass} >
                            //         {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                            //     </DivEditable>
                            // }
                            // else {

                            switch (subEl.dragDrop) {

                                case "canvasHolder":
                                    theOut = <DivCanvas key={"div1_" + index} elementData={subEl} path={tmpPath} localVariables={localVariables} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </DivCanvas>;
                                    break;
                                case "itemHolder":
                                    theOut = <DivItemHolder key={"div4_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </DivItemHolder>;
                                    break;
                                case "canvasColumn":
                                    theOut = <DivColumnHolder key={"div3_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </DivColumnHolder>;
                                    break;
                                default:
                                    theOut = <Div key={"div2_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </Div>
                            }
                            // }

                            break;

                        case "a":
                            switch (subEl.dragDrop) {

                                case "itemHolder":
                                    theOut = <DivItemHolder elementType="a" key={"span_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>

                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}

                                    </DivItemHolder>;
                                    break;
                                default:
                                    theOut = <A key={"a_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </A>
                            }

                            break;
                        case "span":
                            switch (subEl.dragDrop) {

                                case "itemHolder":
                                    theOut = <DivItemHolder elementType="span" key={"span_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </DivItemHolder>;
                                    break;
                                default:
                                    theOut = <Span key={"span_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </Span>
                            }
                            break;
                        case "img":
                            switch (subEl.dragDrop) {

                                case "itemHolder":
                                    theOut = <DivItemHolder elementType="span" key={"span_" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                        <Img elementData={subEl} localVariables={localVariables} tuuid={tuuid} path={tmpPath} imageReloading={imageReloading} {...propsToPass}>
                                            {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                        </Img>
                                    </DivItemHolder>;
                                    break;
                                default:
                                    theOut = <Img key={"img_" + index} elementData={subEl} localVariables={localVariables} tuuid={tuuid} path={tmpPath} imageReloading={imageReloading} {...propsToPass}>
                                        {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                                    </Img>
                            }
                            break;
                        case "hr":
                            theOut = <Hr key={"hr" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                            </Hr>
                            break;
                        case "label":
                            theOut = <Label key={"label" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass} >
                                {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                            </Label>
                            break;
                        case "input":
                            theOut = <Input key={"input" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass} >
                                {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                            </Input>
                            break;
                        case "textarea":
                            theOut = <TextArea key={"textarea" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass} >
                                {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                            </TextArea>
                            break;
                        case "button":
                            theOut = <Button key={"input" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass} >
                                {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                            </Button>
                            break;
                        case "checkbox":
                            theOut = <Checkbox key={"checkbox" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass} />
                            break;
                        case "select":
                            theOut = <Select key={"select" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass}>
                                {subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}
                            </Select>
                            break;
                        case "option":
                            theOut = <SelectOption key={"selectOption" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass} />
                            break;
                        case "poll":
                            theOut = <Poll key={"poll" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass} />
                            break;
                        case "rssFeed":
                            theOut = <RssFeed key={"rss" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass} />
                            break;
                        case "captcha":
                            theOut = <Captcha key={"rss" + index} elementData={subEl} localVariables={localVariables} path={tmpPath} {...propsToPass} />
                            break;
                        default:
                            theOut = <div key={index}>{subEl.elements && parseHTML(subEl, tmpPath, localVariables, tuuid)}</div>
                    }
                }
                return theOut;
            })
        }


        var theEls = [];
        // var elToStart = this.props.elementsData.elements.length > 1 ? this.props.elementsData.elements[1] : this.props.elementsData
        // var pathToStart = this.props.elementsData.elements.length > 1 ? "elements[1]" : "elements[0]"

        var elToStart = this.props.elementsData;

        var pathToStart = "";

        theEls = parseHTML(elToStart, pathToStart, {}, 0)


        return <div className="dragAndDropCanvas" style={{ height: "100%" }}>
            {DragDropFunctions.fontArray.filter(theFontStyle => theFontStyle.src == "google").map((theFont, index) => {
                return <link key={"linkk_" + index} href={"https://fonts.googleapis.com/css?family=" + theFont.label} rel="stylesheet" />
            })}
            <div dangerouslySetInnerHTML={{ __html: finalStyle }} />

            <div className="templateBody">
                {theEls}
            </div>
        </div >

    }
}




import React from 'react'

export default function IconPinterest({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon"

    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 3C6.13425 3 3 6.13425 3 10C3 12.9657 4.84508 15.4985 7.44908 16.5182C7.38783 15.9646 7.33242 15.1147 7.47358 14.5103C7.60075 13.9643 8.29433 11.0308 8.29433 11.0308C8.29433 11.0308 8.08492 10.6113 8.08492 9.99183C8.08492 9.01825 8.649 8.292 9.35133 8.292C9.94867 8.292 10.2368 8.74058 10.2368 9.27783C10.2368 9.87867 9.85475 10.7764 9.657 11.6082C9.49192 12.3053 10.0064 12.8735 10.6936 12.8735C11.9378 12.8735 12.8939 11.5616 12.8939 9.66808C12.8939 7.99217 11.6899 6.82025 9.97025 6.82025C7.97875 6.82025 6.80975 8.31417 6.80975 9.85825C6.80975 10.4597 7.04133 11.1048 7.33067 11.456C7.38783 11.5254 7.396 11.5861 7.37908 11.6567C7.326 11.8777 7.20817 12.3532 7.18483 12.4506C7.1545 12.5789 7.08333 12.6058 6.95092 12.5439C6.0765 12.1367 5.52992 10.8587 5.52992 9.832C5.52992 7.62408 7.13408 5.59583 10.1552 5.59583C12.5836 5.59583 14.4707 7.326 14.4707 9.63892C14.4707 12.0516 12.9493 13.9929 10.8382 13.9929C10.1289 13.9929 9.46217 13.6242 9.23408 13.1891C9.23408 13.1891 8.88292 14.5255 8.79775 14.8533C8.63967 15.4612 8.21325 16.2236 7.92742 16.6885C8.5825 16.8903 9.27842 17 10 17C13.8657 17 17 13.8657 17 10C17 6.13425 13.8657 3 10 3Z" fill={fillColor} />
        </svg>
    )
}
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import AutomationOption from '../automationOption';
import AutomationFunctions from '../automationFunctions';
import HelpPopup from '../helpPopup/helpPopup';
class DrawerRedirectStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            drawerContent: undefined,
            step: {},
            errors: {
                redirectGuid: ""
            }
        }

        this.updateValue = this.updateValue.bind(this);
        this.saveStep = this.saveStep.bind(this);
    }

    componentDidMount() {

        this.setState({ step: this.props.step, steps: this.props.steps });
    }

    updateValue(event) {
        var fieldName = event.target.name;
        var step = this.state.step;
        step.stepOptions[fieldName].value = event.target.value;
        this.setState(step)
    }


    saveStep() {
        var errors = {};
        var error = false;
        var step = this.state.step;

        if (!step.stepOptions.redirectGuid.value || step.stepOptions.redirectGuid.value.length < 3) {
            error = true;
            errors.redirectGuid = i18n.t('automation:add.redirect.noStepError')
        }

        this.setState({ errors })

        if (!error) {
            //finish
            step.complete = true;
            step.wizardComplete = true;
            this.props.saveStep()
        }

    }

    render() {
        const { readOnly } = this.props;

        if (this.state.drawerContent) return this.state.drawerContent;
        if (!this.state.step.stepOptions) return <div></div>

        var chosenStep = undefined;
        if (this.state.step.stepOptions.redirectGuid && this.state.step.stepOptions.redirectGuid.value) {
            chosenStep = AutomationFunctions.getStepByGuid(this.state.step.stepOptions.redirectGuid.value, this.props.steps)
            // chosenStep = this.state.steps.find(st => st.guid == this.state.step.stepOptions.redirectGuid.value)
        }

        const inputProps = {
            disabled: readOnly
        };

        return <div data-test="drawer-redirect-step">
            <div className="automationStepContentHolder">
                <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="mar-b25">{i18n.t('automation:add.redirect.header')}</h4>
                    <HelpPopup type={"redirect"} />
                </div>
                <div className="mar-b25">{i18n.t('automation:add.redirect.subheader')}</div>
                {!chosenStep && <UILIB.WarningHolder>
                    <div className="txtInput_label">Select a step to redirect to when the contact reaches this step</div>
                    {this.state.errors.redirectGuid && <UILIB.WarningHolder className="mar-b10">{this.state.errors.redirectGuid}</UILIB.WarningHolder>}
                    <UILIB.Button text="Choose a Step" onClick={() => { this.props.chooseAStepAndReturn(this.props.step) }} {...inputProps} />
                </UILIB.WarningHolder>}
                {chosenStep && <div>
                    <h4 className="mar-b25">Currently redirects to...</h4>
                    <UILIB.WarningHolder>

                        <div className="mar-t30 mar-b40">
                            <AutomationOption id={"optionHolder_tmp_" + chosenStep.guid}
                                allSteps={this.props.steps}
                                step={chosenStep}
                                automations={[]}
                                applications={[]}
                                dataId={999}
                                removeStep={() => { }}
                                stepsData={[]}
                                editStep={() => { }} groupsData={this.props.groups}
                                segmentsData={this.props.segments}
                                customFieldsData={this.props.customFields}
                                campaignsData={this.props.campaigns}
                                activateError={undefined}
                                automationStatus={undefined}
                                stepStats={[]}
                                choosing={false}
                                choosingSourceGuid={""}
                                hideAllOptions={true}
                                disabled={readOnly}
                            />

                        </div>
                        <UILIB.Button text="Choose another Step" onClick={() => { this.props.chooseAStepAndReturn(this.props.step) }} {...inputProps} />
                    </UILIB.WarningHolder>
                </div>}
            </div>

            <div className="automationStepSaveButtonHolder">
                {readOnly ? (
                    <>
                        <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                    </>
                ) : (
                    <UILIB.Button className="button-primary mar-t20" text={i18n.t('save')} onClick={this.saveStep} />
                )}
            </div>
        </div>
    }
}

export default DrawerRedirectStep;
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';

class StripeAuthPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            paymentMethod: '',
            clientSecret: '',
            order: undefined,
            complete: false,
            error: ''
        }
        this.stripe;

        this.redirect = this.redirect.bind(this)
        this.success = this.success.bind(this)
        this.error = this.error.bind(this)
    }

    componentDidMount() {
        // get any pending payments for this account
        axios.get('/accountMaster/payment/stripeauth').then(response => {
            if (!response.data) {
                this.redirect()
            } else {
                this.setState({
                    loading: false,
                    paymentMethod: response.data.paymentMethod,
                    clientSecret: response.data.clientSecret,
                    order: response.data.Order
                })
            }
        })
    }

    redirect() {
        this.props.history.push('/cp/company/payments')
    }

    success() {
        // update the order to be complete and move the customer to payment screen
        axios.put('/accountMaster/payment/stripeauth/' + this.state.order.id).then(() => {
            console.log('COMPLETE')
            this.setState({
                error: '',
                paymentMethod: '',
                complete: true
            }, () => {
                setTimeout(this.redirect, 2000)
            })
        }).catch(err => {
            console.log(err)
            this.setState({
                paymentMethod: '',
                error: err.response.message || err.response
            })
        })

    }

    error(err) {
        // show the error
        this.setState({
            error: err
        })
    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        return (
            <div>
                {this.state.complete && <div className="row">
                    <div className="col-xs-12 center-xs mar-t30">
                        <div className="loading">Payment Complete...redirecting</div>
                    </div>
                </div>}

                {!!this.state.paymentMethod && <UILIB.AuthStripe amount={this.state.order.price} paymentMethod={this.state.paymentMethod} clientSecret={this.state.clientSecret} success={this.success} error={this.error} />}

                {!!this.state.error && <div className="row">
                    <div className="col-xs-12 center-xs mar-t30 ">
                        <div className="text-red mar-b30">{this.state.error}</div>
                        <p><a href=".">refresh</a> page to try again</p>
                        <p>or view your <a onClick={this.redirect}>invoices</a></p>
                    </div>
                </div>}
            </div>
        );
    }

}

export default StripeAuthPayment;
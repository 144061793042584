import React from "react";
import UILIB from "~/Common-Objects/Lib";
import OptionComponent from './components/option'
import moment from 'moment';

class ChatHelpers {

    constructor() {
    }
    static splitConvIntoGroups(chatLines, accountUser, users = [], subscriber) {
        let outPut = [];
        chatLines.forEach((chatLine, index) => {
            let previousLine = chatLines[index - 1];
            const isMe = accountUser.accountUserId == chatLine.AccountUserId;
            let isAi = chatLine.type == 2;
            let name = chatLine.AccountUserId ? chatLine.AccountUserName : chatLine.Subscriber ? chatLine.Subscriber.emailAddress : "";
            let avatar = chatLine.AccountUserId ? users.find(u => u.id === chatLine.AccountUserId)?.profilePicFileName : "";
            let isStaff = chatLine.AccountUserId ? true : false;
            if (chatLine.type === 0 && !chatLine.AccountUserName) avatar = subscriber?.subscriberImage;
            if (typeof avatar === 'string' && avatar.split('/').slice(-1)[0] === 'blankAvatar.png') avatar = null;

            let groupChanged = index === 0;
            if (previousLine) {
                if (previousLine.AccountUserId != chatLine.AccountUserId) groupChanged = true;
                if (previousLine.subscriberId != chatLine.subscriberId) groupChanged = true;
                if (previousLine.createdAt) {
                    let previousDate = moment(previousLine.createdAt);
                    let currentDate = moment(chatLine.createdAt);
                    if (!previousDate.isSame(currentDate, "minute")) groupChanged = true;
                }
            }

            if (chatLine.type == 4) groupChanged = true;
            if (previousLine && previousLine.type == 4 && chatLine.type != 4) groupChanged = true;

            if (groupChanged) {
                let read = false;
                let delivered = false;
                if (!isMe) {
                    read = chatLine.operatorRead;
                    delivered = chatLine.operatorDelivered;
                }
                else {
                    read = chatLine.read;
                    delivered = chatLine.delivered;
                }

                outPut.push({
                    status: read ? "read" : delivered ? "delivered" : "",
                    isMe: isMe,
                    isAi: isAi,
                    name: name,
                    isStaff: isStaff,
                    avatar: avatar,
                    createdAt: chatLine.createdAt,
                    channelType: chatLine.channelType,
                    chatLines: [chatLine]
                })
            }
            else {
                outPut[outPut.length - 1].chatLines.push(chatLine)
            }
        })
        return outPut;
    }
    static processChatLine(chatLine, isMe, isAi, history, userRole, isAttachment = false, download = () => { }) {
        let output = <UILIB.ChatMessage
            message={isAttachment ? chatLine.filename : chatLine.text}
            size={isAttachment ? chatLine.size : null}
            sent={chatLine.delivered ? "sent" : ""}
            note={chatLine.note}
            isMe={isMe}
            isAi={isAi}
            isAttachment={isAttachment}
            key={chatLine.id}
            download={download}
            draft={chatLine.draft}
        />


        if (chatLine.text == "[[WIZARD__SETUPEMAIL]]") {
            output = <>
                <OptionComponent className="mar-t10 mar-r10"
                    onClick={() => { userRole == 0 ? history.push(`/cp/conversations/${chatLine.InboxId}/channels/connect/email`) : undefined }}
                    leftIcon={<UILIB.Icon name="mail-open" color="#6C42DE" style={{ height: 20, width: 20 }} />}
                    text="Email"
                    subText="Connect an email address such as support@your.co"
                    rightIcon={<UILIB.Icons icon="chevronRight" color="#999999" style={{ height: 20, width: 20 }} />}
                />
                {/* <OptionComponent className="mar-t10"
                    onClick={() => { userRole == 0 ? history.push(`/cp/conversations/${chatLine.InboxId}/channels/connect/webchat`) : undefined }}
                    leftIcon={<UILIB.Icon name="chat" color="#6C42DE" style={{ height: 20, width: 20 }} />}
                    text="Live Chat"
                    rightIcon={<UILIB.Icons icon="chevronRight" color="#999999" style={{ height: 20, width: 20 }} />}
                /> */}
            </>
        }

        return output;

    }
}





export default ChatHelpers;
import React, { useState, useEffect } from "react";
import * as styles from "./index.module.scss";
import classNames from "classnames";
export default function OptionComponent({
    leftIcon = null,
    text = "",
    subText = "",
    rightIcon = null,
    className,
    onClick = () => { }
}) {
    const rootClasses = classNames(styles.root, className);

    return (
        <button className={rootClasses} onClick={onClick}>
            {leftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}
            <div>
                <div className={styles.text}>{text}</div>
                {(subText != "") && <div className={styles.subText}>{subText}</div>}
            </div>
            {rightIcon && <div className={styles.rightIcon}>{rightIcon}</div>}
        </button>
    );
}

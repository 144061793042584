import React, { useState, useEffect } from "react";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import Button from "../button";
import * as styles from "./index.module.scss";

export default function Dialog({
  open = false,
  title,
  description,
  submitting = false,
  fullScreen,
  size,
  container,
  onOpenChange,
  className,
  children,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (onOpenChange) onOpenChange(isOpen);
  }, [isOpen]);

  // This function is called whenever the "Cancel" button is clicked or the escape key is pressed.
  function handleOpenChange(e) {
    if (submitting === false) {
      setIsOpen(e);
    }
  }

  return (
    <UILIB.Dialog.Root open={isOpen} onOpenChange={handleOpenChange} {...rest}>
      <UILIB.Dialog.Portal container={container}>
        <UILIB.Dialog.Overlay />
        <UILIB.Dialog.Content fullScreen={fullScreen} size={size}>
          <div
            className={classnames(styles.contentInner, {
              [styles.contentInnerFullScreen]: fullScreen,
            })}
          >
            {(title || description) && (
              <div className={styles.header}>
                <UILIB.Dialog.Title className="h2">{title}</UILIB.Dialog.Title>

                {description && (
                  <UILIB.Dialog.Description
                    className="text-grey mar-b0"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
              </div>
            )}

            {children}
          </div>

          {fullScreen && (
            <UILIB.Dialog.Close className={styles.closeButton} asChild>
              <Button
                variant="secondary"
                size="s"
                disabled={submitting}
                iconLeft={<UILIB.Icon name="cross-large" />}
              >
                Cancel
              </Button>
            </UILIB.Dialog.Close>
          )}
        </UILIB.Dialog.Content>
      </UILIB.Dialog.Portal>
    </UILIB.Dialog.Root>
  );
}

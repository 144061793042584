import React from 'react'
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { siteMaster: store.siteMaster, user: store.user, accountMaster: store.accountMaster }
})

export default class CampaignsProgress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        const sent = this.props.campaign.status === 'Finished' ? this.props.stats["Total Subscribers"] : this.props.stats['Total Sent']
        return <UILIB.Paper>
            <h4 className="mar-b5">{i18n.t('campaigns:overview.progress')}: {sent || 0} / {this.props.stats['Total Subscribers'] || 0}</h4>
            <div className="mar-b20">{this.props.pctSent}%</div>
            <UILIB.SmallLineGraph width="100%" height="5" color={this.props.siteMaster.colours.$primary} hideText={true} extra={this.props.resent} val1={sent} val2={this.props.stats['Total Subscribers']} />
        </UILIB.Paper>
    }
}
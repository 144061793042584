import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'


export default class ComponentExplainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {

    }
    componentWillUnmount() {

    }


    render() {


        var style = {};
        if (this.props.elementData.optionFullWidth) style = { flex: "100%" }

        return <div className="dd_toolHolder" style={style}>
            <div className="dd_inputTool">
                <UILIB.WarningHolder>{this.props.value}</UILIB.WarningHolder>
            </div>
        </div>


    }
}



import React, { Component } from 'react';
import axios from '~/data/http/axios';
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib';
import DragDropFunctions from '../dragDropFunctions';
import NewElementObject from '../leftTools/elementPicker/newElementObject/newElementObject';


@connect((store) => {
    return { siteMaster: store.siteMaster }
})


class marketPlaceDragDrop extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        var bgImage = "";
        var thisOpts = DragDropFunctions.replaceGlobalOptions(this.props.app.options)
        bgImage = thisOpts.appBgImage;

        var hasAppInstalled = false;
        if (!this.props.app.forAppId) hasAppInstalled = true;
        if (!hasAppInstalled) {
            hasAppInstalled = this.props.userApps.find(tApp => tApp.ApplicationId == this.props.app.forAppId)
        }


        return <div className="marketPlaceItemHolder" style={{ marginBottom: "15px" }}>

            <div className="appImage" style={{ backgroundImage: "url(" + bgImage + ")" }} />
            <div className="appTitle" >{this.props.app.name}</div>
            <div className="appButton">
                {hasAppInstalled && <NewElementObject id={"newEl-" + this.props.index} title={"Drag Me"} icon="button" content={thisOpts.content} onStartDrag={this.props.onDrag} style={{ backgroundColor: "white", padding: "5px", borderRadius: "10px", display: "flex", justifyContent: "center" }} toolBarContent={<UILIB.Button text="Drag to Stage" className="primary" />} />}
                {!hasAppInstalled && <UILIB.Button text="Install" className="primary" onClick={() => { window.open('/cp/company/integrations?show=' + this.props.app.forAppId, '_blank') }} />}
            </div>

        </div >



    }


}

export default marketPlaceDragDrop;
import React, { useState, useEffect, useRef } from "react";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";
import axios from "~/data/http/axios";
import EmailValidator from "~/Classes/emailValidator";
import InboxButton from "../shared/inboxButton";
import axios2 from "axios";
import i18n from "~/i18n";

export default function NewConversationDialog({
  activeInbox = {},
  createConversation = (e) => { },
  ...rest }) {

  const [emailAddress, setEmailAddress] = useState("");
  const [contacts, setContacts] = useState([]);
  const [searching, setSearching] = useState(false);
  const cancelToken = useRef(axios2.CancelToken)
  const source = useRef(null)
  const timer = useRef(null);

  useEffect(() => {
    return function () {
      if (source.current) source.current.cancel('cancelled')
      if (timer.current) clearTimeout(timer.current);
    }
  }, [])

  const loadContacts = async () => {
    setSearching(true)
    if (source.current) {
      source.current.cancel('cancelled')
    }
    const newSource = cancelToken.current.source()
    source.current = newSource

    if (emailAddress.length < 3) {
      setContacts([])
      return setSearching(false)
    }

    const contacts = await axios.get(`/subscriber?search=${emailAddress}`, { cancelToken: newSource.token })
    setContacts(contacts.data.Subscribers);
    setSearching(false)
  }

  const searchContact = async (e) => {
    let email = e.target.value;
    setEmailAddress(email);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      loadContacts()
    }, 300)
  }

  const createNewContact = async () => {
    let contact = await axios.post(`/inboxes/${activeInbox.id}/subscriber`, { emailAddress: emailAddress })
    createConversation(contact.data.Subscriber.id)
  }

  const allowCreateNewContact = !contacts.length && emailAddress.length > 3 && EmailValidator.validateEmail(emailAddress);


  return (
    <UILIB.Dialog.Portal {...rest}>
      <UILIB.Dialog.Overlay />
      <UILIB.Dialog.Content className={styles.content}>
        <UILIB.Dialog.Title className="visually-hidden">
          {i18n.t("chat:inbox.leftBar.new.title")}
        </UILIB.Dialog.Title>

        <UILIB.Dialog.Description className="visually-hidden">
          {i18n.t("chat:inbox.leftBar.new.desc")}
        </UILIB.Dialog.Description>

        <div className={styles.search}>
          <UILIB.TextInput
            placeholder={i18n.t("chat:inbox.leftBar.new.search")}
            value={emailAddress}
            onChange={searchContact}
            iconRight={allowCreateNewContact ?

              <InboxButton style={{ padding: 5 }} onClick={createNewContact}>{i18n.t("chat:inbox.leftBar.new.start")}</InboxButton>

              : ""} />
        </div>

        {searching && <div className={styles.loading}>
          <UILIB.LoadingIcons />
        </div>}

        {(contacts && contacts.length > 0) && !searching && <>
          <div className={styles.sectionHeaders}>{i18n.t("chat:inbox.leftBar.new.existing")}</div>
          <div className={styles.sectionList}>
            {contacts.map((contact, index) => {
              return <button key={"contact_" + index} type="button" className={styles.contactLine} onClick={() => { createConversation(contact.id) }}>
                <div className={styles.contactLineAvatar}><UILIB.AvatarNew src={(contact.accountImage || "").indexOf('blankAvatar.png') > -1 ? '' : contact.accountImage} size={24} name={contact.emailAddress} /></div>
                <div className={styles.contactLineEmail}>{contact.emailAddress}</div>
                <div className={styles.contactLineContactType}>
                  <UILIB.Icon name={contact.type === 0 ? "person" : "office"} />
                  <div>
                    {contact.type === 0 ? i18n.t("chat:inbox.leftBar.new.person") : i18n.t("chat:inbox.leftBar.new.org")}
                  </div>
                </div>
              </button>
            })}
          </div>

          {/* <footer className={styles.footer}>
            <ul className={classnames(styles.controlsList, "faux-list")}>
              <li>
                <span className={styles.key}>↑</span>{" "}
                <span className={styles.key}>↓</span> to navigate
              </li>
              <li>
                <span className={styles.key}>⏎</span> to select
              </li>
              <li>
                <span className={styles.key}>esc</span> to close
              </li>
            </ul>
          </footer> */}
        </>
        }
      </UILIB.Dialog.Content>
    </UILIB.Dialog.Portal >
  );
}

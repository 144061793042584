import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
class Switcher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            form: JSON.parse(JSON.stringify(this.props.form))
        }
        this.save = this.save.bind(this)
        this.updateField = this.updateField.bind(this)
        this.checkForm = this.checkForm.bind(this)
    }

    buildForm() {
        switch (this.props.step) {
            case 1:
                return <div>
                    <h4 className="mar-b25">{i18n.t('form:step2.header')}</h4>

                    <UILIB.Select name="onCreate" value={this.state.form.options.onCreate} data={[
                        { label: i18n.t('form:step2.redirect'), value: 'redirect' },
                        { label: i18n.t('form:step2.message'), value: 'message' }
                    ]} onChange={ev => this.updateField(ev, true)}
                        outerClassName="mar-b25"
                    />

                    {
                        this.state.form.options.onCreate !== 'replace' && <div>
                            {this.state.form.options.onCreate == 'redirect' && <UILIB.TextInput outerClassName="mar-b25" value={this.state.form.options.createRedirectUrl} error={this.state.errors.createRedirectUrl} placeholder={i18n.t('form:step2.url')} name="createRedirectUrl" onChange={ev => this.updateField(ev, true)} />}
                            {this.state.form.options.onCreate == 'message' && <UILIB.TextInput outerClassName="mar-b25" value={this.state.form.options.createMessage} error={this.state.errors.createMessage} placeholder={i18n.t('form:step2.success')} name="createMessage" onChange={ev => this.updateField(ev, true)} />}
                        </div>
                    }
                </div>

            case 2:
                return <div>
                    <h4 className="mar-b25">{i18n.t('form:step3.header')}</h4>

                    <UILIB.Select name="onUpdate" value={this.state.form.options.onUpdate} data={[
                        { label: i18n.t('form:step3.redirect'), value: 'redirect' },
                        { label: i18n.t('form:step3.message'), value: 'message' },
                        { label: i18n.t('form:step3.update'), value: 'update' }
                    ]} onChange={ev => this.updateField(ev, true)}
                        outerClassName="mar-b25" />

                    {this.state.form.options.onUpdate !== 'replace' && this.state.form.options.onUpdate !== 'update' && <div>
                        {this.state.form.options.onUpdate == 'redirect' && <UILIB.TextInput outerClassName="mar-b25" value={this.state.form.options.updateRedirectUrl} error={this.state.errors.updateRedirectUrl} placeholder={i18n.t('form:step3.url')} name="updateRedirectUrl" onChange={ev => this.updateField(ev, true)} />}
                        {this.state.form.options.onUpdate == 'message' && <UILIB.TextInput outerClassName="mar-b25" value={this.state.form.options.updateMessage} error={this.state.errors.updateMessage} placeholder={i18n.t('form:step3.success')} name="updateMessage" onChange={ev => this.updateField(ev, true)} />}
                    </div>
                    }
                </div >
            case 3: return
            case 4:
                return <div>
                    <h4 className="mar-b25">{i18n.t('form:step5.header')}</h4>

                    <UILIB.Select name="onConfirm" value={this.state.form.options.onConfirm} data={[
                        { label: i18n.t('form:step5.landing'), value: 'landing' },
                        { label: i18n.t('form:step5.redirect'), value: 'redirect' }
                    ]} onChange={ev => this.updateField(ev, true)}
                        outerClassName="mar-b25" />

                    {this.state.form.options.onConfirm !== 'landing' && <div>
                        <UILIB.TextInput outerClassName="mar-b25" value={this.state.form.options.confirmedRedirectUrl} error={this.state.errors.confirmedRedirectUrl} placeholder={i18n.t('form:step4.url')} name="confirmedRedirectUrl" onChange={ev => this.updateField(ev, true)} />
                    </div>}

                </div>
        }
    }

    save() {
        var errors = this.checkForm()
        if (Object.keys(errors).length) return
        axios.put('/group/' + this.state.form.GroupId + '/form/' + this.state.form.id, this.state.form).then(() => {
            var step
            switch (this.props.step) {
                case 1:
                    if (this.props.form.options.onCreate !== 'replace' && this.state.form.options.onCreate === 'replace')
                        step = 1;
                    break;
                case 2:
                    if (this.props.form.options.onUpdate !== 'replace' && this.state.form.options.onUpdate === 'replace')
                        step = 2;
                    break;
                case 3:
                    break;
                case 4:
                    if (this.props.form.options.onConfirm !== 'replace' && this.state.form.options.onConfirm === 'replace')
                        step = 4;
                    break;
            }
            this.props.onSave(step)
        })
    }

    updateField(ev, options) {
        var form = this.state.form
        if (options) {
            form.options[ev.currentTarget.name] = ev.currentTarget.value
        } else {
            form[ev.currentTarget.name] = ev.currentTarget.value
        }
        this.setState({
            form
        }, this.checkForm)
    }

    checkForm() {
        var errors = {}
        var options = this.state.form.options;
        switch (this.props.step) {
            case 1:
                if (options.onCreate === 'redirect' && !options.createRedirectUrl) errors.createRedirectUrl = i18n.t('form:step2.errUrl');
                if (options.onCreate === 'message' && !options.createMessage) errors.createMessage = i18n.t('form:step2.errMessage');
                break;
            case 2:
                if (options.onUpdate === 'redirect' && !options.updateRedirectUrl) errors.updateRedirectUrl = i18n.t('form:step2.errUrl');
                if (options.onUpdate === 'message' && !options.updateMessage) errors.updateMessage = i18n.t('form:step2.errMessage');
                break;
            case 3:
                break;
            case 4:
                if (options.onConfirm === 'redirect' && !options.confirmedRedirectUrl) errors.confirmedRedirectUrl = i18n.t('form:step2.errUrl');
                break;
        }

        this.setState({
            errors
        })
        return errors
    }

    render() {
        return <div>
            {this.buildForm()}
            <div className="quickFlex">
                <UILIB.Button text={i18n.t('save')} className="button-primary mar-r10" onClick={this.save} />
                <UILIB.Button text={i18n.t('cancel')} onClick={this.props.onCancel} />
            </div>
        </div>
    }
}

export default Switcher;
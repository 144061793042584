import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import ArrowDown from '~/assets/images/icons/nav-arrow-down.svg';

class GenericAppFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availableFields: [],
            ready: false,
            filter: ''
        }
        this.getAvailableFields = this.getAvailableFields.bind(this)
        this.toggleField = this.toggleField.bind(this)
        this.toggleAllFields = this.toggleAllFields.bind(this)
        this.filterFields = this.filterFields.bind(this)
        this.addFilter = this.addFilter.bind(this)
        this.removeFilter = this.removeFilter.bind(this)
        this.updateFilter = this.updateFilter.bind(this);
        this.updateVal = this.updateVal.bind(this)
    }

    componentDidMount() {
        this.getAvailableFields()
        if (!this.props.appBinding.appSettings.filtersOption) {
            this.props.appBinding.appSettings.filtersOption = 'all'
        }
    }

    getAvailableFields() {
        let queryStr = '?'
        if (this.props.group) {
            queryStr += 'type=' + this.props.group.value
        }
        axios.get(`/application/${this.props.app.shortName}/availablefields` + queryStr).then(response => {
            if (!this.props.appBinding.appSettings) this.props.appBinding.appSettings = {};
            var settings = this.props.appBinding.appSettings;
            if (this.props.group) {
                if (!settings.fields) settings.fields = {}
                if (!settings.fields[this.props.group.value] || !settings.fields[this.props.group.value].length) {
                    settings.fields[this.props.group.value] = response.data.filter(f => f.required)
                }
            } else {
                if (!settings.fields || !settings.fields.length) {
                    settings.fields = response.data.filter(f => f.required)
                }
            }
            this.setState({
                availableFields: response.data,
                ready: true
            })
        })
    }

    toggleField(ev) {
        if (!this.props.appBinding.appSettings) this.props.appBinding.appSettings = {};
        var settings = this.props.appBinding.appSettings;

        var arr = []
        if (this.props.group) {
            if (!settings.fields) settings.fields = {}
            if (!settings.fields[this.props.group.value]) settings.fields[this.props.group.value] = []
            arr = settings.fields[this.props.group.value]
        } else {
            if (!settings.fields) settings.fields = [];
            arr = settings.fields
        }

        var field = this.state.availableFields.find(f => f.name === ev.currentTarget.name)


        if (ev.currentTarget.checked) {
            arr.push(field)
        } else {
            var index = arr.findIndex(f => f.name === field.name)
            if (index > -1) {
                arr.splice(index, 1)
            }
        }
        if (this.props.onChange && typeof this.props.onChange === 'function') {
            this.props.onChange()
        }
        this.setState({})
    }

    toggleAllFields(on) {
        if (!this.props.appBinding.appSettings) this.props.appBinding.appSettings = {};
        var settings = this.props.appBinding.appSettings;

        if (this.props.group) {
            if (!settings.fields) settings.fields = {}
            if (!settings.fields[this.props.group.value]) settings.fields[this.props.group.value] = []
            if (on) {
                settings.fields[this.props.group.value] = this.state.availableFields
            } else {
                settings.fields[this.props.group.value] = this.state.availableFields.filter(f => f.required)
            }
        } else {
            if (!settings.fields) settings.fields = [];
            if (on) {
                settings.fields = this.state.availableFields
            } else {
                settings.fields = this.state.availableFields.filter(f => f.required)
            }
        }

        if (this.props.onChange && typeof this.props.onChange === 'function') {
            this.props.onChange()
        }
        this.setState({})
    }

    filterFields(val) {
        this.setState({
            filter: val
        })
    }

    addFilter() {
        var filters = this.props.appBinding.appSettings.filters || []
        filters.push({ field: '', operator: '', value: '' })
        this.props.appBinding.appSettings.filters = filters
        if (this.props.onChange && typeof this.props.onChange === 'function') {
            this.props.onChange()
        }
        this.setState({})
    }

    removeFilter(index) {
        var filters = this.props.appBinding.appSettings.filters || []
        filters.splice(index, 1)
        this.props.appBinding.appSettings.filters = filters
        if (this.props.onChange && typeof this.props.onChange === 'function') {
            this.props.onChange()
        }
        this.setState({})
    }

    updateFilter(ev, index) {
        var filters = this.props.appBinding.appSettings.filters || []
        if (filters[index]) {
            filters[index][ev.currentTarget.name] = ev.currentTarget.value
        }
        this.props.appBinding.appSettings.filters = filters
        if (this.props.onChange && typeof this.props.onChange === 'function') {
            this.props.onChange()
        }
        this.setState({})
    }

    updateVal(ev) {
        let settings = this.props.appBinding.appSettings
        settings[ev.target.name] = ev.target.value
        this.setState({})
    }

    render() {
        if (!this.state.ready) return <UILIB.LoadingIcons iconType="2" />

        var availableFields = this.state.availableFields.slice()
        if (this.state.filter) {
            availableFields = availableFields.filter(f => f.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1)
        }

        availableFields = availableFields.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        })

        var filters = this.props.appBinding.appSettings.filters || []

        var operators = [{
            label: 'Equals',
            value: 'equal'
        }, {
            label: 'Not Equals',
            value: 'not equal'
        }, {
            label: 'Is Blank',
            value: 'blank'
        }, {
            label: 'Is Not Blank',
            value: 'not blank'
        }]

        var fields = this.props.appBinding.appSettings.fields
        if (this.props.group) {
            if (!this.props.appBinding.appSettings.fields) this.props.appBinding.appSettings.fields = {}
            if (!this.props.appBinding.appSettings.fields[this.props.group.value]) this.props.appBinding.appSettings.fields[this.props.group.value] = []
            fields = this.props.appBinding.appSettings.fields[this.props.group.value]
        }

        return (
            <div>
                <UILIB.ButtonSelect fixedHeightLarge={true} outerClassName="mar-b10" icon={ArrowDown} headerText="Select Fields" filter={true} updateFilter={this.filterFields} selectAll={() => this.toggleAllFields(true)} clearAll={() => this.toggleAllFields(false)}
                    data={availableFields && !!availableFields.length ? availableFields.map((field) => {
                        var selected = false
                        selected = fields.some(p => p.name === field.name)
                        return <a><UILIB.CheckBox key={field.name} name={field.name} onChange={this.toggleField} disabled={field.required} checked={selected}>{field.name}</UILIB.CheckBox></a>
                    }) : []}
                >
                </UILIB.ButtonSelect>
                <div>
                    {fields.map(p => {
                        var prop = this.state.availableFields.find(property => property.name === p.name)
                        if (!prop) return ''
                        return <UILIB.SquareChip key={prop.value} className="square-chip-primary mar-r5 square-chip-large" iconRight={!prop.required ? <UILIB.Icons icon="crossCircle" onClick={() => this.toggleField({
                            currentTarget: {
                                checked: false,
                                name: prop.name
                            }
                        })} /> : undefined}>{prop.name}</UILIB.SquareChip>
                    })}
                </div>
                <UILIB.WarningHolder style={{ textAlign: "left" }}>
                    <UILIB.Header>Filter</UILIB.Header>
                    <div className="mar-b25">If required add rules below to filter which contacts are imported  based on their data.</div>
                    <div className="mar-b20">
                        <UILIB.Select data={[{ value: 'all', label: 'All Must Match' }, { value: 'any', label: 'Any Can Match' }]} name="filtersOption" onChange={this.updateVal} value={this.props.appBinding.appSettings.filtersOption} placeholder="select if ALL or ANY of the rules need to match" />
                    </div>
                    {!!filters.length && <div className="row mar-b10 text-heavy">
                        <div className="col-xs-3">Field</div>
                        <div className="col-xs-3">Operator</div>
                        <div className="col-xs-4">Value</div>
                        <div className="col-xs-2"></div>
                    </div>}
                    {filters.map((filter, index) => {
                        var field = fields.find(f => (f.name === filter.field) || ('_' + this.props.app.shortName + '_' + f.name === filter.field));
                        return <div key={'filter' + index} className="row mar-b10">
                            <div className="col-xs-3">
                                <UILIB.Select value={filter.field} name="field" placeholder="Select Field" onChange={ev => this.updateFilter(ev, index)} data={fields.map(f => {
                                    return {
                                        label: f.name,
                                        value: `_${this.props.app.shortName}_` + f.name
                                    }
                                })}>
                                </UILIB.Select>
                            </div>
                            <div className="col-xs-3">
                                <UILIB.Select value={filter.operator} name="operator" placeholder="Select Operator" onChange={ev => this.updateFilter(ev, index)} data={operators} />
                            </div>
                            <div className="col-xs-4">
                                {filter.operator !== 'blank' && filter.operator !== 'not blank' && <div>
                                    {!!field && !!field.options && !!field.options.length && <UILIB.Select value={filter.value} name="value" placeholder="select value" onChange={ev => this.updateFilter(ev, index)} data={field.options.map(o => {
                                        return {
                                            label: o.label,
                                            value: o.id || o.value
                                        }
                                    })} />}
                                    {(!field || !field.options || !field.options.length) && <UILIB.TextInput className="" value={filter.value} name="value" onChange={ev => this.updateFilter(ev, index)} />}
                                </div>}
                            </div>
                            <div className="col-xs-2" style={{ textAlign: "right" }}>
                                <UILIB.Button className="button-red" text="Delete" onClick={() => this.removeFilter(index)} />
                            </div>
                        </div>
                    })}
                    <UILIB.Button text={!!filters.length ? "Add Another Rule" : "Add a Rule"} className="primary outline button-sml" onClick={this.addFilter} />
                </UILIB.WarningHolder>
            </div>
        );
    }
}

export default GenericAppFields;
import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib';
import i18n from '~/i18n'
import axios from '~/data/http/axios';

export default class SaveBlockDrawer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newBlockName: "",
            errorText: "",
            tierPermissions: {},
            loading: true
        }

        this.updateVal = this.updateVal.bind(this);
        this.saveVal = this.saveVal.bind(this);
    }

    componentWillMount() {
        axios.get('/accountMaster/tier/customTemplateBlocks').then((data) => {
            this.setState({
                tierPermissions: data.data,
                loading: false
            })
        })
    }

    updateVal(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    saveVal() {
        var errorText = "";
        if (!this.state.newBlockName || !this.state.newBlockName.length || this.state.newBlockName.length < 4) {
            errorText = i18n.t('templates:add.advanced.longerName');
        }
        if (errorText != "") {
            return this.setState({ errorText })
        }
        var saveProps = this.props.saveProps;
        saveProps.name = this.state.newBlockName;
        saveProps.options.title = this.state.newBlockName;
        this.props.saveBlockFinal(saveProps, true);
    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        const perms = { ...this.state.tierPermissions }
        if (!perms.err && perms.allowedNumber <= perms.currentNumber) {
            perms.err = 'tierOverLimit'
        }
        if (perms.err) {
            return <UILIB.TierLimitHolder type="Custom Block" err={perms.err} allowedNumber={perms.allowedNumber} tierName={perms.tierName} />
        }
        return <div>
            <h4 className="mar-b25">{i18n.t('templates:add.advanced.saveThisBlock')}</h4>
            <div className="mar-b25">{i18n.t('templates:add.advanced.saveBlock1')}</div>
            <div className="mar-b25">{i18n.t('templates:add.advanced.saveBlock2')}</div>

            <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('templates:add.newBlockName')} name="newBlockName" value={this.state.newBlockName} placeholder={i18n.t('templates:add.myNewBlock')} onChange={this.updateVal} error={this.state.errorText} />

            <UILIB.Button text={i18n.t('templates:add.saveBlock')} onClick={this.saveVal} className="button-primary" />
        </div>


    }
}



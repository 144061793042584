
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import { connect } from 'react-redux';
import i18n from '~/i18n'


//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class PollResultsAnswers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoaded: false,
            tableData: []
        }

        this.getPollResults = this.getPollResults.bind(this);

    }

    componentDidMount() {
        this.getPollResults();
    }

    goViewSubscriber(subscriberId) {
        window.open('/cp/subscriber/' + subscriberId, '_blank');
    }

    getPollResults() {
        axios.get('/campaign/' + this.props.campaignId + '/polls/' + this.props.pollId + '/' + this.props.questionIndex).then(response => {

            let questionType = this.props.question.value;
            var tableData = response.data.PollAnswers
                .map(thisLine => {
                    let answer = thisLine.answer;
                    if (questionType == "satisfaction") {
                        if (answer == "0") answer = "1/5"
                        if (answer == "2") answer = "2/5"
                        if (answer == "4") answer = "3/5"
                        if (answer == "6") answer = "4/5"
                        if (answer == "9") answer = "5/5"
                    }
                    if (questionType == "promoter") {
                        answer = (Number(answer) + 1) + "/10"
                    }
                    if (questionType == "like") {
                        if (answer == "9") answer = "Like"
                        if (answer == "0") answer = "Dislike"
                    }
                    return {
                        date: {
                            headerValue: i18n.t('date'),
                            value: DateTimeFunctions.formatDateTime(thisLine.createdAt, 1)
                        },
                        emailAddress: {
                            headerValue: i18n.t('emailAddress'),
                            value: <a onClick={() => { this.goViewSubscriber(thisLine.SubscriberId) }}>{thisLine.Subscriber && thisLine.Subscriber.emailAddress ? thisLine.Subscriber.emailAddress : "Deleted"}</a>,
                        },
                        answer: {
                            headerValue: i18n.t('campaigns:polls.answer'),
                            value: answer
                        }
                    }
                })

            this.setState({ pageLoaded: true, tableData })
        })
    }

    render() {

        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />
        return <div>

            <h4 className="mar-b25">{i18n.t('campaigns:polls.questionResults')}</h4>
            <div className="mar-b25">{i18n.t('campaigns:polls.questionResultsHeader')}</div>
            <UILIB.DataTable1 noResultsTxt={<span> {i18n.t('noResults')}</span>} tableData={this.state.tableData} loadingData={false}
                dataUpdater={undefined} width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn="date"
                sortedDirection="desc"
            />

        </div>
    }
}
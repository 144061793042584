import React from 'react'
import Icons from '../../UI/Icons'

export default class TableSortLabel extends React.Component {
    render() {
        var classes = "header-label";
        const direction = (this.props.direction || 'ASC').toUpperCase()

        return <div onClick={this.props.onClick} className={classes} style={{ display: 'flex', alignItems: 'center', justifyContent: this.props.align || "left" }}>
            <div>{this.props.children}</div>
            {this.props.active && <Icons icon="arrowDown" color="#A9A9AD" style={{ height: 16, width: 16 }} className={"mar-l10 " + (direction === 'ASC' ? 'flip' : '')} />}

        </div>


    }
}

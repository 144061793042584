import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import IncDisconnect from './incDisconnect';

import ChangeGroup from './changeGroup';
import ApplicationTransactionsGraph from '../components/applicationTransactionsGraph'
import Fields from './changeFields'
import Actions from '../genericCp/hooks2'
import ViewActions from '../genericCp/actions'
import CreateAutomation from '../../../../includes/automations/automationThemeSelector'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class SalesMateConfig extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            appData: {},
            appBinding: {},
            groupData: [],
            customFields: [],
            contactsGroupId: -1,
            importing: false,
            importId: -1,
            importProgress: 0,
            configureActions: false,
            refresh: true
        };

        this.getApp = this.getApp.bind(this);
        this.getAppBinding = this.getAppBinding.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.checkForImportUpdate = this.checkForImportUpdate.bind(this);
        this.resetGroup = this.resetGroup.bind(this);
        this.popDisconnect = this.popDisconnect.bind(this);
        this.goDisconnect = this.goDisconnect.bind(this);
        this.fields = this.fields.bind(this)
        this.performSync = this.performSync.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidMount() {
        this.getApp();
        axios.get('/customfield').then(res => {
            this.setState({
                customFields: res.data.CustomFields
            })
        })
    }

    componentWillUnmount() {
        if (this.theTimer) clearTimeout(this.theTimer)
    }

    getApp() {
        var self = this;
        axios.get("/application/38").then(_theApp => {
            self.setState({ appData: _theApp.data.Application }, self.getAppBinding)
        })
    }

    getAppBinding() {
        var self = this;
        return axios.get("/application/account/app/" + self.state.appData.id).then(_theResp => {
            var contactsGroupId = -1;

            //theyve not added a key
            if (!_theResp.data.Application.appSettings || !_theResp.data.Application.appSettings.keyAdded) {
                self.props.history.push('/cp/company/integrations/apps/salesmate/addAccount')
                return;
            }
            //theyve not done field bindings
            if (!_theResp.data.Application.appSettings || !_theResp.data.Application.appSettings.fields || !Object.keys(_theResp.data.Application.appSettings.fields).length) {
                self.props.history.push('/cp/company/integrations/apps/salesmate/changeFields')
                return;
            }

            if (_theResp.data.Application.appSettings && _theResp.data.Application.appSettings.contactsGroupId) contactsGroupId = _theResp.data.Application.appSettings.contactsGroupId;
            self.setState({ appBinding: _theResp.data.Application, contactsGroupId: contactsGroupId }, self.getGroups)
        })
    }


    getGroups() {
        var self = this;
        axios.get("/group").then(_theGroups => {
            self.setState({ groupData: _theGroups.data.Groups, isLoaded: true })
        })
    }


    resetGroup() {
        this.setState({ contactsGroupId: -1 })
    }

    updateGroup(contactsGroupId) {
        var self = this;
        var appBinding = this.state.appBinding;
        if (!appBinding.appSettings) appBinding.appSettings = {};

        if (!contactsGroupId) contactsGroupId = appBinding.appSettings.contactsGroupId

        appBinding.appSettings.contactsGroupId = contactsGroupId;
        axios.put("/application/account/" + this.state.appBinding.id, this.state.appBinding).then(_theRes => {
            return axios.get("/application/salesmate/setupGroupAndImport?resync=true")
        }).then(_res => {
            self.setState({ contactsGroupId: contactsGroupId, importing: true, isLoaded: false, importId: _res.data.contactsJob.id }, () => {
                self.checkForImportUpdate();
                self.getGroups();
            });

        })
    }

    save() {
        this.setState({
            configureActions: false
        })
        axios.put("/application/account/" + this.state.appBinding.id, this.state.appBinding)
    }

    checkForImportUpdate() {
        axios.get('/group/' + this.state.contactsGroupId + '/import/' + this.state.importId).then(res => {
            this.setState({ importProgress: res.data.PercentComplete })
            if (res.data.PercentComplete < 100) {
                this.theTimer = setTimeout(this.checkForImportUpdate, 1000);
            }
            else {
                this.setState({ importing: false, importProgress: 100, refresh: this.state.refresh + 1 }, this.getAppBinding)
            }
        })
    }

    popDisconnect() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <IncDisconnect goDisconnect={this.goDisconnect} />, true, "500px"));
    }

    goDisconnect() {
        var self = this;
        axios.get('/application/salesmate/disconnect').then(res => {
            self.props.history.push('/cp/company/integrations')
        })
    }

    performSync() {
        axios.get('/application/salesmate/resync').then(response => {
            if (response.data.id) {
                this.setState({
                    importing: true,
                    importId: response.data.id
                }, this.checkForImportUpdate)
            }
        })
    }

    fields() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <Fields finish={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false))
            this.getAppBinding().then(() => this.performSync())
        }} />, true, "100vw"));
    }

    createAutomation() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <CreateAutomation
            scratch={() => this.props.history.push('/cp/automation/addnew')}
            select={this.selectTheme.bind(this)}
        />, true, "100%"));
    }

    selectTheme(theme) {
        axios.post('/automation/theme/' + theme.id).then(response => {
            this.setState({
                alertMessage: i18n.t('automation:overview.automationDuplicated'),
                alertOpen: true
            })
            this.props.history.push('/cp/automation/addnew/' + response.data.Automation.id)
        })
    }


    render() {

        if (!this.state.isLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>
        var currentContactsGroup = this.state.groupData.find(theRow => theRow.id == this.state.contactsGroupId);
        if (!currentContactsGroup || this.state.contactsGroupId == '' || this.state.contactsGroupId == '-1') {
            return <ChangeGroup appBinding={this.state.appBinding} updateGroup={this.updateGroup} />
        }

        return <div>
            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={6} lg={6} className="mar-b25">
                    <div className="quickFlex">
                        <div className="integration-avatar rounded" style={{ backgroundImage: "url(" + this.state.appData.appLogoUri + ")" }}></div>
                        <p className="no-marg pad-left">Salesmate is Connected.</p>
                    </div>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} lg={6} className="end-xs mar-b25">
                    <UILIB.Button className="button-red" iconLeft={<UILIB.Icons icon="cross" />} onClick={this.popDisconnect}>
                        Disconnect
                    </UILIB.Button>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} lg={6} className="mar-b25">
                    <UILIB.Paper className="height-100">
                        <h4 className="mar-b20">Contacts</h4>
                        <p>Salesmate is currently connected and is automatically syncing your contacts with the following group.</p>
                        <div style={{ height: 'calc(100% - 80px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div>
                                <UILIB.Button className="mar-r10" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => this.props.history.push('/cp/groups/' + this.state.contactsGroupId)}>{currentContactsGroup.groupName}</UILIB.Button>
                                <UILIB.Button onClick={this.resetGroup} iconRight={<UILIB.Icons icon="switch" />} >Change Group</UILIB.Button>
                            </div>
                            <div>
                                <UILIB.Button className="button-primary mar-r10" iconRight={<UILIB.Icons icon="cog" />} onClick={this.fields}>Configure Fields</UILIB.Button>
                                <UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon="cog" />} onClick={() => this.setState({ configureActions: true })}>Configure Actions</UILIB.Button>
                            </div>
                        </div>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} lg={6}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">Sync history</h4>
                        <ApplicationTransactionsGraph applicationId={this.state.appData.id} refresh={this.state.refresh} />
                        <div className="no-marg text-right">
                            {!this.state.importing && <UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon="undo" />} text="Re-Sync" onClick={this.performSync} />}
                            {this.state.importing && <div style={{ textAlign: "center" }}>
                                {!!this.state.importProgress
                                    ? <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.importProgress} val2={100} />
                                    : <UILIB.LoadingLine />
                                }</div>}
                        </div>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={12} lg={12}>
                    <UILIB.Paper>
                        <UILIB.Row>
                            <UILIB.Column xs={12} margin={0}>
                                <h4 className="mar-b20">Automation</h4>
                                <div className='mar-b10'>Automations are a great way to automatically make things happen based on your contacts activity. For example, you could send a welcome email when someone is added to a Salesmate Group.</div>
                                <UILIB.Button text="Add an Automation" iconLeft={<UILIB.Icons icon="plus" />} onClick={this.createAutomation.bind(this)} />
                            </UILIB.Column>
                        </UILIB.Row>

                    </UILIB.Paper>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={12} md={12} lg={12}>
                    <ViewActions appData={this.state.appData} appBinding={this.state.appBinding} history={this.props.history} setup={() => this.setState({ configureActions: true })} />
                </UILIB.Column>
            </UILIB.Row>
            {this.state.configureActions && <UILIB.Drawer standalone={true} width={'100vw'} showClose={true} isOpen={true}>
                <Actions appSettings={this.state.appBinding.appSettings} app={this.state.appData} appBinding={this.state.appBinding} customFields={this.state.customFields} onChange={this.save} onClose={this.save} />
            </UILIB.Drawer>}
        </div>

    };


};



import React, { Fragment, useEffect, useRef, useState } from "react"
import AutomationFunctions from './automationFunctions'

export default function CombineElement({ steps, step }) {
    const ref = useRef(null)
    const [parent, setParent] = useState(null)
    const [lane, setLane] = useState(null)

    useEffect(() => {
        if (!ref.current) return
        const el = ref.current.closest('.automationSplitHolder')
        const parentStep = AutomationFunctions.getStepByGuid(el.id.replace('split_', ''), steps)
        if (parentStep) {
            let foundIndex = -1
            parentStep.steps.forEach((children, index) => {
                const split = ref.current.closest('.automationSplit')
                if (split && split.id === children[0].guid) {
                    foundIndex = index
                }
            })
            setLane(foundIndex)
            ref.current.closest('.automationLine').style.left = (0 - ((foundIndex - 1) * 100)) + '%'
            step.parentSplit = parentStep.guid
        }
        setParent(el)
    }, [ref.current])

    const lines = parent ? parent.childElementCount : 0
    console.log(parent, ref.current)
    return (
        <Fragment>
            <div ref={ref} className="automationCombineHolder" style={{ width: parent ? parent.offsetWidth : 0 }}>
                {new Array(lines).fill(1).map((line, index) => {
                    let classes = 'automationCombineLine'
                    if (index === 0) classes += ' automationCombineLineFirst'
                    if (index < lines - 1) classes += ' automationCombineLineBottom'
                    if (index === lines - 2) classes += ' automationCombineLineLast'
                    return <div key={index} className={classes}></div>
                })}
            </div>
            {/* this is just an empty div to force the element down as its fixed */}
            <div style={{ height: 30 }}></div>
        </Fragment>)
}
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UILIB from '../../../../Common-Objects/Lib'
import axios from '../../../../data/http/axios'
import helpers from '../../../../Classes/helpers'
import { alterSiteDrawer } from '../../../../data/actions/siteActions'

const options = [
    {
        "label": "Active",
        "value": "active"
    },
    {
        "label": "In-Active",
        "value": "inactive"
    }
]

export default function GroupAutomate({ GroupId, groupName }) {
    const site = useSelector(state => state.siteMaster)
    const history = useHistory()
    const dispatch = useDispatch()
    const [trigger, setTrigger] = useState('')
    const [name, setName] = useState(groupName + ' Automation')

    const create = async () => {
        const res = await axios.post('/automation', {
            name,
            version: 1,
            status: 'Draft',
            steps: [{
                "guid": helpers.createLocalGuid(),
                "type": "initial",
                "complete": true,
                "parentGuid": 0,
                "stepOptions": {
                    "groupId": { "value": GroupId, "required": true },
                    "subscriberState": { "value": trigger }
                },
                "triggerType": { "value": "0", "required": true },
                "wizardComplete": true
            }],
            options: {
                source: 'Group Subscribers'
            }
        })
        history.push('/cp/automation/addnew/' + res.data.Automation.id + '?redirect=/cp/groups/' + GroupId)
        dispatch(alterSiteDrawer(false, true, 'right', null))
    }

    return (
        <div>
            <div className="quickFlex mar-b10" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <h4>Create an new Automation</h4>
                {site.siteId === 1 && <UILIB.Hint href="https://kb.transpond.io/article/61-what-is-automation" iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>Learn More</UILIB.Hint>}
            </div>

            <p className='mar-b25 text-sml'>
                You can start a new automation based on when contacts join this group. <br /><br />Then perform lots of actions such as automatically sending the contact to a chain of emails.
            </p>

            <UILIB.Select outerClassName="mar-b25" label="Should active or inactive contacts enter this automation?" data={options} value={trigger} onChange={e => setTrigger(e.target.value)} />

            {!!trigger && <UILIB.TextInput label="Give this new Automation a name" value={name} onChange={e => setName(e.target.value)} outerClassName="mar-b25" />}

            {!!trigger && !!name && <>
                <UILIB.WarningHolder className="mar-b25">This will create the automation and take you to the automation designer so you can setup any actions you want to happen when this automation is triggered</UILIB.WarningHolder>
                <UILIB.Button className="button-primary" onClick={create}>Create Automation</UILIB.Button>
            </>}
        </div>
    )
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import BrandAnaylzerComponent from '../includes/brandanalyzer';
import UILIB from '../../../Common-Objects/Lib';
class BrandAnalyzer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }
    render() {
        return <UILIB.Paper>
            <BrandAnaylzerComponent id={-1} />
        </UILIB.Paper>
    }
}

export default BrandAnalyzer;
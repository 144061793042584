import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import textFunctions from '~/Classes/textFunctions';
class FormOverview extends Component {
    render() {
        var create = ''
        if (this.props.form.options.onCreate === 'replace') create = i18n.t('form:edit.overview.createReplace')
        if (this.props.form.options.onCreate === 'redirect') create = i18n.t('form:edit.overview.createRedirect', {
            url: textFunctions.checkForHtml(this.props.form.options.createRedirectUrl)
        })
        if (this.props.form.options.onCreate === 'message') create = i18n.t('form:edit.overview.createMessage', {
            message: textFunctions.checkForHtml(this.props.form.options.createMessage)
        })

        var update = ''
        if (this.props.form.options.onUpdate === 'replace') update = i18n.t('form:edit.overview.updateReplace')
        if (this.props.form.options.onUpdate === 'redirect') update = i18n.t('form:edit.overview.updateRedirect', {
            url: textFunctions.checkForHtml(this.props.form.options.updateRedirectUrl)
        })
        if (this.props.form.options.onUpdate === 'message') update = i18n.t('form:edit.overview.updateMessage', {
            message: textFunctions.checkForHtml(this.props.form.options.updateMessage)
        })
        if (this.props.form.options.onUpdate === 'update') update = i18n.t('form:edit.overview.updateUpdate')

        var confirm = ''
        if (this.props.form.options.onConfirm === 'landing') confirm = i18n.t('form:edit.overview.confirmedLanding');
        if (this.props.form.options.onConfirm === 'redirect') confirm = i18n.t('form:edit.overview.confirmedLanding', {
            url: textFunctions.checkForHtml(this.props.form.options.confirmedRedirectUrl)
        });

        return (
            <UILIB.Paper>

                <div className="mar-b25 text-md " dangerouslySetInnerHTML={{ __html: create }}></div>
                <UILIB.Button className="mar-b25" text={i18n.t('form:edit.overview.change')} onClick={() => this.props.change(1)} iconLeft={<UILIB.Icons icon="pencil" />} />

                <div className="divider2"></div>

                <div className="mar-b25 text-md " dangerouslySetInnerHTML={{ __html: update }}></div>
                <UILIB.Button className="mar-b25" text={i18n.t('form:edit.overview.change')} onClick={() => this.props.change(2)} iconLeft={<UILIB.Icons icon="pencil" />} />

                <div className="divider2"></div>
                <div>
                    {!!this.props.form.sendConfirmation && <>
                        <div className="mar-b25 text-md " dangerouslySetInnerHTML={{
                            __html: i18n.t('form:edit.overview.confirm', {
                                name: textFunctions.checkForHtml(this.props.form.confirmationName),
                                email: textFunctions.checkForHtml(this.props.form.confirmationEmail),
                                subject: textFunctions.checkForHtml(this.props.form.confirmationSubject)
                            })
                        }} />
                        <UILIB.Button className="mar-b25" text={i18n.t('form:edit.overview.change')} onClick={this.props.editEmail} iconLeft={<UILIB.Icons icon="pencil" />} />
                    </>}
                    {!this.props.form.sendConfirmation && <>
                        <div className="mar-b25 text-md ">You have set it to not send confirmation emails</div>
                        <UILIB.Button className="mar-b25" text="Enable" onClick={this.props.enableConfirmation} />
                    </>}
                </div>
                {this.props.form.sendConfirmation == true && <div className="divider2"></div>}
                {
                    this.props.form.sendConfirmation == true && <div>
                        <div className="mar-b25 text-md " dangerouslySetInnerHTML={{ __html: confirm }}></div>

                        <UILIB.Button className="mar-b25" text={i18n.t('form:edit.overview.change')} onClick={() => this.props.change(4)} iconLeft={<UILIB.Icons icon="pencil" />} />
                    </div>
                }

            </UILIB.Paper >
        );
    }
}

export default FormOverview;
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '../../../../../../Common-Objects/Lib';
import DateTimeFunctions from '../../../../../../Classes/dateTimeFunctions';
import moment from 'moment'

class ActionHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lastTimestamp: '',
            lastSubscriber: '',
            total: 0,
            rows: [],
            loading: true,
            loadingmore: false,
            expanded: undefined,
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            emailAddress: ''
        }
        this.getResults = this.getResults.bind(this)
        this.updateDate = this.updateDate.bind(this)
        this.updateEmail = this.updateEmail.bind(this)
        this.timeout
    }

    componentDidMount() {
        this.getResults()
    }

    expand(row) {
        this.setState({
            expanded: row
        })
    }

    updateDate(dates) {
        this.setState({
            startDate: dates.startDate.startOf('day'),
            endDate: dates.endDate.endOf('day'),
            lastTimestamp: '',
            lastSubscriber: '',
            rows: [],
            total: true,
            loading: true
        }, this.getResults)
    }

    updateEmail(ev) {
        if (this.timeout) clearTimeout(this.timeout)
        this.setState({
            emailAddress: ev.currentTarget.value
        }, () => {
            this.timeout = setTimeout(() => {
                this.setState({
                    lastTimestamp: '',
                    lastSubscriber: '',
                    rows: [],
                    total: true,
                    loading: true
                }, this.getResults)
            }, 200)
        })
    }

    getResults() {
        this.setState({
            loadingmore: true
        })
        let queryStr = '?limit=20&startDate=' + this.state.startDate + '&endDate=' + this.state.endDate + '&emailAddress=' + this.state.emailAddress
        if (this.state.lastTimestamp && this.state.lastSubscriber) {
            queryStr += '&lastTimestamp=' + this.state.lastTimestamp + '&lastSubscriber=' + this.state.lastSubscriber
        }
        axios.get('/application/account/' + this.props.accApp.id + '/actions/' + this.props.action + queryStr).then(response => {
            this.setState({
                loading: false,
                loadingmore: false,
                total: response.data.count,
                rows: this.state.rows.concat(response.data.rows.map(row => {
                    return {
                        timestamp: {
                            headerValue: 'Date/Time',
                            value: DateTimeFunctions.formatDateTime(row.timestamp, "3")
                        },
                        emailAddress: {
                            headerValue: 'Email Address',
                            value: row.Subscriber ? <a onClick={() => this.props.goTo('/cp/subscribers/' + row.SubscriberId)}>{row.Subscriber.emailAddress}</a> : 'DELETED'
                        },
                        source: {
                            headerValue: 'Source',
                            value: row.source === 'account' ? 'Account Wide' : row.source === 'campaign' ? 'Campaign Trigger' : 'Automation Action'
                        },
                        campaign: {
                            headerValue: 'Campaign',
                            value: row.Campaign ? <UILIB.SquareChip className="square-chip-green mar-b0 smallChip" iconRight={<UILIB.Icons icon="arrowRight" style={{ height: 14, width: 14 }} />} hasButton={true} onClick={() => this.props.goTo('/cp/campaigns/view/' + row.CampaignId)} value={row.Campaign.campaignName} /> : ''
                        },
                        automation: {
                            headerValue: 'Automation',
                            value: row.Automation ? <UILIB.SquareChip className="square-chip-green mar-b0 smallChip" iconRight={<UILIB.Icons icon="arrowRight" style={{ height: 14, width: 14 }} />} hasButton={true} onClick={() => this.props.goTo('/cp/automation/addnew/' + row.AutomationId)} value={row.Automation.name} /> : ''
                        },
                        expand: {
                            headerValue: ' ',
                            value: <UILIB.SquareChip className="square-chip-purple mar-b0 smallChip clickable" value="Expand" onClick={() => this.expand(row)} />
                        }
                    }
                })),
                lastTimestamp: response.data.rows[response.data.rows.length - 1] ? response.data.rows[response.data.rows.length - 1].sort[0] : null,
                lastSubscriber: response.data.rows[response.data.rows.length - 1] ? response.data.rows[response.data.rows.length - 1].sort[1] : null
            })
        })
    }

    render() {
        let hook = this.props.app.options.hooks.find(h => h.action === this.props.action)
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} md={12} >
                        <h4 className="mar-b25">{hook.name} Actions</h4>
                    </UILIB.Column>
                    <UILIB.Column xs={12} md={4} className="mar-b25">
                        <div className="mar-b0">
                            <UILIB.TextInput type="multiDate" startDate={this.state.startDate} endDate={this.state.endDate} updateDate={this.updateDate}></UILIB.TextInput>
                        </div>
                    </UILIB.Column>
                    <UILIB.Column xs={12} md={4} className="mar-b25">
                        <UILIB.TextInput className="txtInput search no-marg" type="email" placeholder="Search for Email Address" value={this.state.emailAddress} onChange={this.updateEmail}></UILIB.TextInput>
                    </UILIB.Column>
                    <UILIB.Column xs={12} md={12} className="mar-b25">
                        <UILIB.DataTable1
                            noResultsTxt={this.state.loadingmore ? <span className="loading">Searching</span> : 'No Actions Found'}
                            tableData={this.state.rows}
                            dataUpdater={this.tableUpdater}
                            loadingData={this.state.loading}
                            width="100%"
                            pageSize="20"
                            hasCheckBoxes="no"
                        />
                    </UILIB.Column>
                    {!!this.state.lastTimestamp && !!this.state.lastSubscriber && <UILIB.Column xs={12} className="center-xs">
                        <UILIB.Button className={"outline primary button-md " + (this.state.loadingmore ? 'loading' : '')} text={this.state.loadingmore ? 'Loading' : "Load More"} disabled={this.state.loadingmore} onClick={this.getResults} />
                    </UILIB.Column>}
                </UILIB.Row>

                <UILIB.Drawer standalone={true} width={500} isOpen={!!this.state.expanded} showClose={true} close={() => this.setState({ expanded: undefined })}>
                    {!!this.state.expanded && <UILIB.Row>
                        <UILIB.Column xs={12} className="mar-b25">
                            <UILIB.TextInput label="Method" value={this.state.expanded.method} readOnly={true} />
                        </UILIB.Column>
                        <UILIB.Column xs={12} className="mar-b25">
                            <UILIB.TextInput label="URL" value={this.state.expanded.url} readOnly={true} />
                        </UILIB.Column>
                        {!!this.state.expanded.body && <UILIB.Column xs={12}>
                            <UILIB.TextArea label="Body" value={this.state.expanded.body ? JSON.stringify(JSON.parse(this.state.expanded.body), null, 2) : ''} readOnly={true} rows="99" className="text-sml" />
                        </UILIB.Column>}
                    </UILIB.Row>}
                </UILIB.Drawer>
            </div>
        );
    }
}

export default ActionHistory;
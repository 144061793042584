import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';
import axios from '~/data/http/axios';

export default class addStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: "",
            password: "",
            inputErrors: {
                emailAddress: "",
                password: ""
            }
        };

        this.updateInput = this.updateInput.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
    }

    componentDidMount() { }

    updateInput(ev) {
        this.setState({ [ev.currentTarget.name]: ev.currentTarget.value })
    }

    checkEmail() {
        var thisEmail = this.state.emailAddress;
        var inputErrors = this.state.inputErrors;
        let errored = false;

        inputErrors.emailAddress = "";
        inputErrors.password = "";

        if (thisEmail != this.props.newEmail) {
            inputErrors.emailAddress = i18n.t('account:email.changeEmailError');
            errored = true;
        }

        if (!this.state.password || this.state.password.length < 5) {
            inputErrors.password = i18n.t('account:email.changeEmailPasswordError');
            errored = true;
        }

        if (errored) {
            this.setState({ inputErrors })
            return;
        }


        axios.post('/accountuser/passwordcheck', { password: this.state.password }).then(resp => {
            this.setState({ inputErrors });
            this.props.confirm();
        }).catch(err => {
            inputErrors.password = i18n.t('account:email.changeEmailPasswordError');
            this.setState({ inputErrors })
        })
    }

    render() {
        return <div>
            <h4 className="mar-b25">{i18n.t('account:email.changeEmailHeader')}</h4>
            <div className="text-heavy mar-b25">{i18n.t('account:email.changeEmailSubHeader')}</div>

            <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('account:email.changeEmailLabel')} focus={true} type="text" placeholder={i18n.t('emailAddressPlaceholder')} name="emailAddress" error={this.state.inputErrors.emailAddress} value={this.state.emailAddress} onChange={this.updateInput} />

            <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('account:email.changeEmailPasswordHeader')} type="password" placeholder={i18n.t('account:email.changeEmailPasswordPlaceholder')} name="password" error={this.state.inputErrors.password} value={this.state.password} onChange={this.updateInput} />


            <div className="text-heavy text-red mar-b25">{i18n.t('account:email.changeEmailLogoutWarning')}</div>

            <UILIB.Button text={i18n.t('continue')} className="button-primary" onClick={this.checkEmail} />

        </div>
    }
};



import React, { Fragment } from 'react'
export default class ProgressNav extends React.Component {
    render() {
        // let steps = [
        //     {
        //         title: "The Title",
        //         onClick: () => { },
        //         selected: false,
        //         complete: false
        //     }
        // ]
        let steps = [];
        if (this.props.steps && this.props.steps.length) {
            steps = this.props.steps;
        }

        let className = "addcampaign-stepsholder mar-b25";
        if (this.props.className) {
            className += "addcampaign-stepsholder " + this.props.className;
        }

        return <div className={className}>
            {steps.map((step, index) => {
                let outerClass = "addcampaign-stepholder-step";
                if (step.complete) outerClass += " addcampaign-stepholder-step-complete";
                if (step.selected) outerClass += " addcampaign-stepholder-step-selected";
                return <Fragment key={index}>
                    <div className={outerClass} onClick={step.onClick}>
                        <div className={"addcampaign-stepholder-step-circle"}>
                            {index + 1}
                        </div>
                        <span className="addcampaign-stepholder-step-name hide-xs">{step.title}</span>
                    </div>
                    {index < steps.length - 1 && <div className="addcampaign-stepholder-divider" />}
                </Fragment>
            })
            }


        </div >
    }
}

import React, { Component } from 'react'
import NewElementObject from './newElementObject/newElementObject'
import LayoutHolder from './layoutHolder/layoutHolder'
import BlockHolder from './blockHolder/blockHolder'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import UILIB from '../../../../../../../Common-Objects/Lib'
import axios from '../../../../../../../data/http/axios'

//CONNECT TO STORE 
@connect((store) => {
    return { siteMaster: store.siteMaster }
})


export default class ElementPicker extends Component {
    constructor(props) {
        super(props)
        this.state = { permissions: null }

        this.insertElement = this.insertElement.bind(this);
        //when we are pulling element from DB inser the elementID below (Hardcoded to 0 for now)
    }

    componentDidMount() {
        axios.get('/accountMaster/tier/customTemplateBlocks').then(res => {
            this.setState({ permissions: res.data })
        })
    }

    insertElement(theEl) {
        this.props.insertElement(theEl.content);
    }

    render() {

        if (this.props.type == "element") {
            return <div>
                {this.props.components.filter(theC => { if (!theC.marketPlace || theC.marketPlace == 0) return true }).map((thisEl, index) => {
                    if (thisEl.category === "element") {
                        return <div key={"newEl-" + index} data-tip={thisEl.title} className="lElementHolder" onClick={() => { this.insertElement(thisEl) }}><ReactTooltip effect='solid' className="tooltip green" />
                            <NewElementObject id={"newEl-" + index} title={thisEl.title} content={thisEl.content} toolbarIcon={thisEl.toolbarIcon} element={thisEl} />
                        </div>
                    }
                })}
                {!this.props.isForm &&
                    <div onClick={this.props.openMarket} className="lElementHolder" style={{ marginTop: "20px" }}>
                        <div className="newElementObject" style={{ justifyContent: "center", alignItems: "center" }}>
                            <UILIB.Icons className="newElementObject-icon" style={{ margin: '2px 5px 0 0' }} icon="shoppingbag" />
                            <div className="newElementObject-text text-primary text-heavy">App Market</div>
                        </div>
                    </div>
                }
            </div>
        }

        if (this.props.type == "layout") {

            return <div>{this.props.components.map((thisEl, index) => {
                return <div key={"newLay-" + index} data-tip={thisEl.title} className="lElementHolder" onClick={() => { this.insertElement(thisEl) }}><ReactTooltip effect='solid' className="tooltip green" />
                    <LayoutHolder id={"newHL-" + index} title={thisEl.title} content={thisEl.content} toolbarIcon={thisEl.toolbarIcon} />
                </div>
            })}
            </div>
        }
        if (this.props.type == "block") {

            return <div>{this.props.components.map((thisEl, index) => {
                return <div key={"newLay-" + index} data-tip={thisEl.title} className="lElementHolder" onClick={() => { this.insertElement(thisEl) }}><ReactTooltip effect='solid' className="tooltip green" />
                    <BlockHolder id={"newHL-" + index} title={thisEl.title} content={thisEl.content} toolbarIcon={thisEl.toolbarIcon} />
                </div>
            })}
            </div>
        }

        if (this.props.type == "userBlock" && this.state.permissions) {
            if (this.state.permissions.err && this.state.permissions.err === 'tierForbids') {
                return <UILIB.TierLimitHolder {...this.state.permissions} type="Saved Blocks" small={true} />
            }
            if (!this.props.components || !this.props.components.length) {
                return <div>
                    <div className="text-sml text-heavy mar-b10">{i18n.t('templates:add.drag.block.noBlocks')}</div>
                    <p className="text-sml">{i18n.t('templates:add.drag.block.noBlocksDetail')}</p>
                </div>
            }
            return <div>{this.props.components.map((thisEl, index) => {
                return <div key={"newLay-" + index} data-tip={thisEl.title} className="lElementHolder" onClick={() => { this.insertElement(thisEl) }}><ReactTooltip effect='solid' className="tooltip green" />
                    <BlockHolder type="userBlock" id={"newHL-" + index} title={thisEl.title} content={thisEl.content} toolbarIcon={thisEl.toolbarIcon} blockId={thisEl.componentId} updateBlocks={this.props.updateBlocks} />
                </div>
            })}
            </div>
        }

        return <div></div>
    }
}



import React, { useState, useEffect } from "react";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import i18n from "~/i18n";

export default function AiAssistantWizardScanArticles({
    inboxId = 0,
    aiId = 0,
    contentProvider = ""
}) {

    let articlesTimer;
    const [articlesCount, setArticlesCount] = useState(0);

    useEffect(() => {
        if (!inboxId || !aiId || contentProvider == "unknown") return;
        articlesTimer = setInterval(listenForContentUpdates, 1000);
        return () => {
            clearInterval(articlesTimer);
        }
    }, [inboxId, aiId]);

    const listenForContentUpdates = async () => {
        let count = await axios.get('/inboxes/' + inboxId + '/ai/' + aiId + '/embeddings/total')
        count = count.data.total;
        setArticlesCount(count);
    };

    if (contentProvider == "unknown") return (
        <>
            <p className="text-grey">{i18n.t("chat:settings.ai.wizard.step4.unknownSource.scanningDesc")}</p>


        </>
    );

    return (
        <>
            <p className="text-grey">{i18n.t("chat:settings.ai.wizard.step4.scanningDesc")}</p>
            <UILIB.Paper style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <UILIB.LoadingIcons style={{ width: 20, height: 20 }} className="mar-b15" />
                <h5 className="mar-b5">{i18n.t("chat:settings.ai.wizard.step4.scanning")}</h5>
                {i18n.t("chat:settings.ai.wizard.step4.found", { count: articlesCount })}
            </UILIB.Paper>
            <p className="text-grey">{i18n.t("chat:settings.ai.wizard.step4.scanningOutro")}</p>
        </>
    );
}
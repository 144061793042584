import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area, CartesianGrid } from 'recharts';
import moment from 'moment';


@connect((store) => {
    return {
        filters: store.filters,
        siteMaster: store.siteMaster,
        user: store.user
    }
})
class EventGraph extends Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
            colors: [this.props.siteMaster.colours["$text-green"], this.props.siteMaster.colours["$text-yellow"], "#A9A9AD", "#43B7B2", "#33257E", "#DD4124"],
        };

    }

    componentDidMount() {
    }


    render() {
        var height = 320;
        if (this.props.height) height = this.props.height;

        if (!this.props.graphData || !this.props.graphData.length) return <div style={{ height: height + "px" }}><UILIB.LoadingIcons iconType="2" /></div>


        return <div>



            <ResponsiveContainer height={height}>
                <AreaChart data={this.props.graphData}
                    margin={{ top: 0, right: 0, left: 20, bottom: 0 }}>
                    <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                    <YAxis axisLine={false} hide={true} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />

                    <CartesianGrid horizontal={false} strokeDasharray="2 2" stroke="#DADADC" />
                    <defs>
                        {
                            Object.keys(this.props.graphData[0]).filter(f => f != "date").map((line, index) => {
                                let color = this.state.colors[index];
                                if (!color) color = this.props.siteMaster.colours.$primary;
                                return <linearGradient id={"color_" + line} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={color} stopOpacity={0.3} />
                                    <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                                </linearGradient>
                            })
                        }
                    </defs>
                    <Tooltip />
                    {Object.keys(this.props.graphData[0]).filter(f => f != "date").map((line, index) => {

                        return <Area
                            dot={false}
                            type="monotone"
                            dataKey={line}
                            fill={`url(#color_${line})`}
                            stroke={this.props.siteMaster.colours.$primary}
                            strokeWidth={2}
                            fillOpacity={1}
                            activeDot={{ stroke: this.props.siteMaster.colours.$primary, strokeWidth: 2, fill: 'white' }}
                        />
                    })
                    }


                </AreaChart>
            </ResponsiveContainer>

        </div>
    }
}

export default EventGraph;
import React from 'react'
import Button from '../UI/Forms/Button/Button'

export default function TagRemovalWarning({ onCancel = () => { }, onConfirm = () => { } }) {
    return (
        <div>
            <div style={{ fontSize: 20 }} className="page-header text-center mar-b10">This will remove the Tag from the Contact in Capsule</div>
            <div style={{ fontSize: 16 }} className="text-center mar-b20">Do you wish to continue?</div>

            <div className="row">
                <div className="col-xs-6">
                    <Button text="Remove Tag" className="button-red button-fullwidth" onClick={onConfirm} />
                </div>
                <div className="col-xs-6">
                    <Button text="Cancel" className="button-fullwidth" onClick={onCancel} />
                </div>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import helpers from '~/Classes/helpers'

import ReactTooltip from 'react-tooltip'
import Topup from '~/pages/cp/company/sms/topup'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'
import NumberFunction from '~/Classes/numberFormatFunctions'
import moment from 'moment'

export default function SmsSummary({ campaign, template, subscriberCount, sendingLimitReached, messages, saveErrors = [], checkSendingLimit = () => { }, setCanStart = () => { }, changeStep = () => { } }) {
    const accountMaster = useSelector(state => state.accountMaster)
    const [contentErrors, setContentErrors] = useState([])
    const [checkingContent, setCheckingContent] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!campaign || !template) return
        (async () => {
            setCheckingContent(true)
            setCanStart(false)
            const [subjectResponse, contentResponse] = await Promise.all([
                axios.get('/campaign/checksms?content=' + campaign.subject),
                axios.get('/campaign/checksms?content=' + template.templateHTML)
            ])
            const messages = []
            if (subjectResponse.data.length) {
                messages.push(`From Name cannot contain ${subjectResponse.data.map(d => `"${d}"`).join(' or ')}. `)
            }
            if (contentResponse.data.length) {
                messages.push(`Content cannot contain ${contentResponse.data.map(d => `"${d}"`).join(', ')}`)
            }
            setContentErrors(messages)
            setCheckingContent(false)
        })()
    }, [campaign, template])


    const topup = () => {
        const theDrawer = <Topup onClose={() => {
            dispatch(siteMaster.alterSiteDrawer(false));
            checkSendingLimit()
        }} />
        dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, false, "500px"));
    }

    if (checkingContent) return <UILIB.LoadingIcons iconType="2" />

    const goodStuff = []
    const errors = []
    const warnings = []

    if (!subscriberCount) {
        errors.push({ value: <span><a onClick={() => changeStep(1)}>There are no valid contacts to send to</a></span> })
    } else if (sendingLimitReached) {
        errors.push({
            value: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className="text-heavy">Credit limit reached </span>
                <span><a onClick={topup}>Topup</a></span>
            </div>
        })
    } else {
        const groupsAndSegments = campaign.Groups.concat(campaign.Segments).map(s => s.name).join(', ')
        // goodStuff.push({
        //     value: <span style={{display: 'flex', justifyContent: 'between'}}>
        //         <span data-for="textSubs" data-tip={groupsAndSegments} className="cursor-pointer" onClick={() => changeStep(1)} dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step3.subs', { subscriberCount: subscriberCount }) }} />
        //         <ReactTooltip effect='solid' className={"tooltip fix-font"} id="textSubs" ></ReactTooltip>
        //     </span>
        // })
        // goodStuff.push({
        //     value: <div style={{display: 'flex', justifyContent: 'space-between'}}>
        //         <div dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step3.subs') }} />
        //         <div>

        //         <span data-for="textSubs" data-tip={groupsAndSegments} className="cursor-pointer" onClick={() => changeStep(1)}>{subscriberCount}</span>
        //         <ReactTooltip effect='solid' className={"tooltip fix-font"} id="textSubs" ></ReactTooltip>
        //         </div>
        //     </div>
        // })

    }

    if (template.templateHTML.indexOf('{{__OPTOUT}}') === -1) {
        errors.push({ value: <span><a onClick={() => changeStep(2)}>{`Content must contain {{__OPTOUT}} so people can unsubscribe from your messages`}</a></span> })

    } else if (!contentErrors.length) {
        goodStuff.push({
            value: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className="text-heavy">SMS Content is ok </span>
            </div>
        })

    }

    const subjectChecker = helpers.smsIsValidSubject(campaign.subject)
    if (subjectChecker.messages.length) {
        subjectChecker.messages.forEach(m => errors.push({ value: <span><a onClick={() => changeStep(2)}>{m}</a></span> }))
    } else if (!contentErrors.length) {
        goodStuff.push({
            value: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className="text-heavy">From Name is ok </span>
                <span style={{ color: '#999' }}>{campaign.subject}</span>
            </div>

        })
        goodStuff.push({
            value: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className="text-heavy">Credits are available </span>
                <span style={{ color: '#999' }}>{NumberFunction.formatNumber(!!accountMaster.accountMaster.smsCredits ? accountMaster.accountMaster.smsCredits : 0)} Available</span>
            </div>
        })

    }

    if (contentErrors.length) {
        errors.push(...contentErrors)
    }

    // if (moment() > moment(campaign.startDate)) {
    //     goodStuff.push({ value: <span dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step3.campaignWillSendImmediately') }}></span> })
    // }

    setCanStart(!Boolean(errors.length))

    return (
        <UILIB.Paper className="mar-b0 campaignGoodStuffHolder">
            <UILIB.Row>
                <h4 className='mar-l10 mar-b15'>Checks</h4>
                {goodStuff.length > 0 &&
                    <UILIB.Column xs={12}>
                        {goodStuff.map((error, index) => {
                            var classes = "addCampaign-checksholder";
                            return <div key={"checkOk_" + index} style={{ display: 'flex' }}>
                                <UILIB.Icons icon="tickCircle" className={classes} />
                                <div style={{ width: '100%' }}>{error.value}</div>
                            </div>
                        })}
                    </UILIB.Column>
                }
                {(errors.length > 0 || saveErrors.length > 0) &&
                    <UILIB.Column xs={12} margin={0}>
                        {errors.concat(saveErrors).map((error, index) => {
                            var classes = "addCampaign-checksholder addCampaign-checksholder-bad";
                            return <div key={"checkErr_" + index} style={{ display: 'flex' }} >
                                <UILIB.Icons icon="crossCircle" className={classes} />
                                <div style={{ width: '100%' }}>{error.value}</div>
                            </div>
                        })}
                    </UILIB.Column>
                }
                {warnings.length > 0 &&
                    <UILIB.Column xs={12} margin={0}>
                        {warnings.map((warning, index) => {
                            var classes = "addCampaign-checksholder addCampaign-checksholder-warn";
                            return <div key={"checkWarn_" + index} style={{ display: 'flex' }} >
                                <UILIB.Icons icon="alert" className={classes} />
                                <div style={{ width: '100%' }}>{warning.value}</div>
                            </div>
                        })}
                    </UILIB.Column>
                }
            </UILIB.Row>
        </UILIB.Paper>
    )
}
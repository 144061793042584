import React from "react";

export default function IconsArrowLeftSmall({ color = "#7246B1", ...rest }) {
  return (
    <svg
      className="icons-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.32841 9.256C5.88599 9.65334 5.88599 10.3467 6.32841 10.744L10.5818 14.564C11.2256 15.1421 12.25 14.6853 12.25 13.82L12.25 6.18001C12.25 5.31474 11.2256 4.85786 10.5818 5.43602L6.32841 9.256Z"
        fill={color}
      />
    </svg>
  );
}

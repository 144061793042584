import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import Overview from './overview'
import Deploy from './deploy'
import Editor from './editor'
import EmailSettings from './email'
import Switcher from './switcher'
import ChangeEmailTemplate from './changeEmailTemplate'
import CopyExistingTemplate from '~/pages/cp/groups/group/forms/includes/copyExistingTemplate'
import EnableSendConfirmation from './enableConfirmation';
import SelectGatedContent from '~/Common-Objects/GatedContent/selectGatedContent';
import TierPermissions from '~/Classes/tierPermissions';
import TierLimitHolder from '~/Common-Objects/UI/Cards/TierLimitHolder';

@connect((store) => {
    return { permissionStore: store.permission, accountMaster: store.accountMaster }
})
export default class FormEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {},
            formId: this.props.match.params.formId,
            currentStep: this.props.match.params.step || 0,
            loadedData: false,
            now: Date.now(),
            edittingName: false
        }
        this.loadForm = this.loadForm.bind(this);
        this.saveForm = this.saveForm.bind(this)
        this.edit = this.edit.bind(this)
        this.changeStep = this.changeStep.bind(this)
        this.editEmail = this.editEmail.bind(this)
        this.editOptions = this.editOptions.bind(this)
        this.alterStep = this.alterStep.bind(this)
        this.changeEmailTemplate = this.changeEmailTemplate.bind(this);
        this.goChangeEmailTemplate = this.goChangeEmailTemplate.bind(this);
        this.finishChangeEmailTemplate = this.finishChangeEmailTemplate.bind(this);
        this.addFile = this.addFile.bind(this)
        this.saveFileSettings = this.saveFileSettings.bind(this)
        this.addLink = this.addLink.bind(this)
        this.removeContent = this.removeContent.bind(this)
        this.handleContentAccessChange = this.handleContentAccessChange.bind(this)
        this.enableConfirmation = this.enableConfirmation.bind(this)
        this.onSaveUrl = this.onSaveUrl.bind(this)
    }

    componentDidMount() {
        this.loadForm()
    }

    componentDidUpdate(presProps, prevState) {
        var newOptions = this.state.form.options
        var step = parseInt(this.state.currentStep)
        if (step === 1) {
            if (newOptions && newOptions.onCreate !== 'replace') {
                return this.setState({
                    currentStep: 0
                })
            }
        } else if (step === 2) {
            if (newOptions && newOptions.onUpdate !== 'replace') {
                return this.setState({
                    currentStep: 0
                })
            }
        } else if (step === 4) {
            if (newOptions && newOptions.onConfirm !== 'landing') {
                return this.setState({
                    currentStep: 0
                })
            }
        }
    }

    loadForm(step) {
        var self = this;
        return axios.get('/group/' + this.props.match.params.groupId + '/form/' + this.state.formId).then(response => {
            self.setState({
                form: response.data.Form,
                loadedData: true,
                currentStep: step !== undefined ? step : this.state.currentStep
            })
        });
    }


    edit() {
        var url
        if (this.state.form.options.emailRawHtml && this.state.currentStep == 3) {
            url = '/cp/templates/add/fromCode/' + this.state.form.formConfirmationTemplateId + '?form=true&groupId=' + this.props.match.params.groupId + '&formId=' + this.state.form.id
        } else {
            url = '/cp/templates/add/dragdrop?form=true&groupId=' + this.props.match.params.groupId + '&formId=' + this.state.formId
        }
        var redirect = '/cp/groups/' + this.props.match.params.groupId + '/forms/edit/' + this.state.formId
        switch (parseInt(this.state.currentStep)) {
            case 0:
                redirect += '/0'
                break;
            case 1:
                url += '&page=created'
                redirect += '/1'
                break;
            case 2:
                url += '&page=update'
                redirect += '/2'
                break;
            case 3:
                url += '&page=confirm'
                redirect += '/3'
                break;
            case 4:
                url += '&page=confirmed'
                redirect += '/4'
                break;
        }
        url += '&redirect=' + redirect
        this.props.history.push(url)
    }

    saveForm(redirectUri) {
        var form = this.state.form
        form.updated = Date.now()
        this.setState({
            form
        })
        return axios.put('/group/' + this.state.form.GroupId + '/form/' + this.state.formId, this.state.form).then(() => {
            this.setState({
                alertOpen: true,
                alertMessage: i18n.t('form:edit.index.alertSaved')
            })
            if (redirectUri) this.props.history.push(redirectUri);
            return this.state.formId
        }).catch(console.log)
    }

    editEmail() {
        var drawerContent = <EmailSettings form={this.state.form} onSave={settings => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
            var form = Object.assign(this.state.form, settings)
            this.setState({
                form
            }, () => this.saveForm())
        }} onCancel={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    editOptions(option) {
        var drawerContent = <UILIB.DrawerConfirm input={true} value={this.state.form.options[option]} confirmText={i18n.t('save')} cancelText={i18n.t('cancel')} confirm={value => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
            var form = this.state.form
            form.options[option] = value
            this.setState({
                form
            }, () => this.saveForm())
        }} cancel={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    alterStep(step) {
        var drawerContent = <Switcher form={this.state.form} step={step} onSave={newStep => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
            this.loadForm(newStep)
        }} onCancel={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    changeStep(stepId) {
        this.setState({
            currentStep: stepId
        })
    }

    changeEmailTemplate() {
        var drawerContent = <ChangeEmailTemplate onCancel={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        }} goChangeEmailTemplate={this.goChangeEmailTemplate} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }


    goChangeEmailTemplate(editorType) {

        var drawerContent = <CopyExistingTemplate onSelect={this.finishChangeEmailTemplate} editorType={editorType} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px")); CopyExistingTemplate

    }

    enableConfirmation() {
        var drawerContent = <EnableSendConfirmation onFinish={async (enable) => {
            if (enable) {
                await axios.put(`/group/${this.state.form.GroupId}/form/${this.state.form.id}`, {
                    sendConfirmation: true
                })
                this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right"));
                await this.loadForm()
                this.changeStep(3)
                this.editEmail()
            } else {
                this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right"));
            }
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, 400));

    }

    finishChangeEmailTemplate(form, editorType) {
        axios.post('/template/copy', {
            id: form.formConfirmationTemplateId
        }).then(response => {

            var existingForm = this.state.form;
            existingForm.options.emailRawHtml = false
            existingForm.formConfirmationTemplateId = response.data.Template.id
            existingForm.wizard.step4 = true
            existingForm.options.emailRawHtml = editorType == 1

            var redirectUri = '/cp/templates/add/dragdrop?form=true&groupId=' + this.props.match.params.groupId + '&formId=' + this.state.formId + '&page=confirm&redirect=/cp/groups/' + this.props.match.params.groupId + '/forms/edit/' + this.state.form.id;
            if (editorType == 1) {
                redirectUri = '/cp/templates/add/fromCode/' + this.state.form.formConfirmationTemplateId + '?form=true&groupId=' + this.props.match.params.groupId + '&formId=' + this.state.form.id + '&page=confirm&redirect=/cp/groups/' + this.props.match.params.groupId + '/forms/edit/' + this.state.form.id;
            }
            this.setState({ form: existingForm }, () => { this.saveForm(redirectUri) })
        })
    }

    async saveFileSettings(file, settings) {
        const { form } = this.state;
        await axios.put(`/group/${form.GroupId}/form/${form.id}/file/${file.id}`, { settings });
        this.loadForm();
    }

    async removeContent(content) {
        const { form } = this.state;
        await axios.delete(`/group/${form.GroupId}/form/${form.id}/file/${content.id}`);
        this.loadForm();
    }

    async addLink(url) {
        const { form } = this.state;
        await axios.post(`/group/${form.GroupId}/form/${form.id}/file`, {
            type: 1,
            url,
            settings: {}
        });
        this.loadForm();
    }

    async addFile(file) {
        const { form } = this.state;
        await axios.post(`/group/${form.GroupId}/form/${form.id}/file`, {
            type: 0,
            FileManagerFileId: file.id,
            settings: {}
        });
        this.loadForm();
    }

    async onSaveUrl(id, value) {
        const form = this.state.form;
        const files = form.FormFiles
        const file = files.find(f => f.id === id)
        if (file) {
            file.url = value
            await axios.put('/group/' + this.state.form.GroupId + '/form/' + this.state.formId + '/file/' + id, { url: value })
            this.setState({ form })
        }
    }

    async handleContentAccessChange(access) {
        const { form } = this.state;
        await axios.put(`/group/${form.GroupId}/form/${form.id}`, {
            options: {
                ...form.options,
                serve: access
            }
        });
        this.loadForm();
    }

    render() {
        if (!this.state.loadedData) {
            return <div></div>
        }

        var formIdToEdit
        switch (parseInt(this.state.currentStep)) {
            case 0:
                formIdToEdit = this.state.form.id;
                break;
            case 1:
                formIdToEdit = this.state.form.formCreatedFormId;
                break;
            case 2:
                formIdToEdit = this.state.form.formUpdatedFormId
                break;
            case 3:
                formIdToEdit = this.state.form.formConfirmationTemplateId
                break;
            case 4:
                formIdToEdit = this.state.form.formConfirmedFormId
                break;
        }

        const gatedContentPermissions = TierPermissions('gatedContent', this.props.accountMaster)
        let deploy
        if (this.state.form.options?.serve && !gatedContentPermissions.allowed) {
            deploy = <TierLimitHolder type={"Gated Content"}
                err={"tierForbids"}
                tierName={this.props.accountMaster.tier.name} />
        } else {
            deploy = <UILIB.Paper>
                <h4 className="mar-b25">{i18n.t('form:edit.deploy.deploy')}</h4>
                <Deploy form={this.state.form} />
            </UILIB.Paper>
        }

        return <UILIB.Paper>
            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={12}>
                    {this.state.edittingName && <div className="mar-b25 quickFlex">
                        <UILIB.TextInput
                            outerStyle={{ flex: "1" }}
                            autoFocus={true}
                            value={this.state.form.formName}
                            name="campaignName"
                            onChange={(ev) => {
                                let form = this.state.form;
                                form.formName = ev.target.value;
                                this.setState(form);
                            }}
                        />
                        <UILIB.Icons
                            style={{ flex: "0", width: "15px", height: "15px" }}
                            icon="tick"
                            onClick={() => this.setState({ edittingName: false }, this.saveForm)}
                            className="mar-l10"
                        />
                    </div>
                    }
                    {!this.state.edittingName && <h4 className="mar-b25 quickFlex" style={{ alignItems: "flex-start" }}>
                        <div className="mar-r10">{this.state.form.formName}</div>
                        <UILIB.Icons
                            style={{ width: "15px", height: "15px" }}
                            icon="pencil"
                            onClick={() => this.setState({ edittingName: true })}
                        />
                    </h4>
                    }
                </UILIB.Column>

                <UILIB.Column xs={12} sm={12} className="mar-b25">

                    <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-l5"
                        selected={this.state.currentStep == 0}
                        onClick={() => { this.changeStep(0) }}>
                        {i18n.t('form:edit.form')}
                    </UILIB.ButtonSimple>

                    {this.state.form.options.onCreate === 'replace' && <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-l5"
                        selected={this.state.currentStep == 1}
                        onClick={() => { this.changeStep(1) }}>
                        {i18n.t('form:edit.submitted')}
                    </UILIB.ButtonSimple>}
                    {!!this.state.form.options.onUpdate === 'replace' && <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-l5"
                        selected={this.state.currentStep == 2}
                        onClick={() => { this.changeStep(2) }}>
                        {i18n.t('form:edit.updated')}
                    </UILIB.ButtonSimple>}
                    {!!this.state.form.sendConfirmation && <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-l5"
                        selected={this.state.currentStep == 3}
                        onClick={() => { this.changeStep(3) }}>
                        {i18n.t('form:edit.email')}
                    </UILIB.ButtonSimple>}
                    {!!this.state.form.sendConfirmation && this.state.form.options.onConfirm === 'landing' && <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-l5"
                        selected={this.state.currentStep == 4}
                        onClick={() => { this.changeStep(4) }}>
                        {i18n.t('form:edit.confirmed')}
                    </UILIB.ButtonSimple>}

                    {!!this.state.form.options?.serve && (
                        <UILIB.ButtonSimple
                            className="button-simple-fullsize mar-l5"
                            selected={this.state.currentStep == 5}
                            onClick={() => { this.changeStep(5) }}>
                            Files
                        </UILIB.ButtonSimple>
                    )}
                </UILIB.Column>

                {this.state.currentStep === 5 ? (
                    <SelectGatedContent
                        content={this.state.form.FormFiles}
                        contentAccess={this.state.form.options.serve}
                        onAddFile={this.addFile}
                        onSaveFileSettings={this.saveFileSettings}
                        onAddLink={this.addLink}
                        onDelete={this.removeContent}
                        onContentAccessChange={this.handleContentAccessChange}
                        onSaveUrl={this.onSaveUrl}
                        running={true}
                        changePage={this.changeStep}
                    />
                ) : (
                    <>
                        <UILIB.Column xs={12} sm={12} md={8}>
                            <Editor id={formIdToEdit} form={this.state.currentStep != 3} edit={this.edit} now={this.state.now} changeEmailTemplate={this.changeEmailTemplate} />
                        </UILIB.Column>

                        <UILIB.Column xs={12} sm={12} md={4} >
                            <Overview form={this.state.form} goTo={step => this.setState({ currentStep: step })} editEmail={this.editEmail} editOption={this.editOptions} enableConfirmation={this.enableConfirmation} change={this.alterStep} updated={this.state.form.updated} />
                            {deploy}
                        </UILIB.Column>
                    </>
                )}
            </UILIB.Row>

            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
        </UILIB.Paper>

    };
};
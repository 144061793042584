import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';

export default class Poll extends Component {
    constructor(props) {
        super(props)

        this.clickCatcher = this.clickCatcher.bind(this);

    }


    clickCatcher(event) {
        event.preventDefault();
    }

    render() {
        var pollQuestions = this.props.localVariables.pollQuestions.value;
        var questionSelected = this.props.localVariables.tmpCurrentPollId.value;
        if (!questionSelected) questionSelected = 0;
        var pollHtml = DragDropFunctions.generatePollSlide(pollQuestions[questionSelected], this.props.localVariables, this.props.globalVariables, true)

        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "a", this.props.localVariables)
        }


        return <div {...eleProps} dangerouslySetInnerHTML={{ __html: pollHtml }} onClick={this.clickCatcher}></div>



    }
}
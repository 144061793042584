import React, { useEffect, useState } from 'react';
import UILIB from '~/Common-Objects/Lib';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import InboxButton from '../../shared/inboxButton';
import i18n from '~/i18n';

export function CustomField({ customField, onEdit = () => { } }) {
	const [editing, setEditing] = useState(false);
	const [value, setValue] = useState(customField.value)

	const onKeyPress = (e) => {
		if (e.key === 'Escape') {
			setValue(customField.value)
			setEditing(false)
		}
	}

	useEffect(() => {
		setValue(customField.value)
	}, [customField.value])

	useEffect(() => {
		if (editing) {
			document.addEventListener("keydown", onKeyPress);
		} else {
			document.removeEventListener("keydown", onKeyPress);
		}
		return function () {
			document.removeEventListener("keydown", onKeyPress);
		}
	}, [editing])

	return <div style={{ cursor: 'pointer', display: 'block' }} onClick={e => {
		if (customField.readOnly) return
		onEdit(e)
	}}>
		<div style={{ fontSize: 12, color: 'var(--grey-700)', marginBottom: value === undefined ? 0 : 4, display: 'block' }}>{customField.fieldDesc}</div>
		<div style={{ fontSize: 12, display: 'block' }}>{customFieldValue(customField, value)}</div>
	</div>
}

export function EditCustomField({ customField, current, close = () => { }, finish = () => { } }) {
	const [value, setValue] = useState(current)
	const [error, setError] = useState(null)
	const [dateOpen, setDateOpen] = useState(false)

	const onChangeValue = (e, v) => {
		if (customField.fieldType === 'DATE') {
			if (v.isValid()) setValue(v)
		} else if (customField.fieldType === 'BOOLEAN') {
			setValue(Boolean(e.target.value))
		} else if (customField.fieldType === 'MULTISELECT') {
			const val = Array.isArray(value) ? [...value] : [value]
			const index = value.indexOf(e.target.value)
			if (index > -1) val.splice(index, 1)
			else val.push(e.target.value)
			setValue(val)
		} else {
			setValue(e.target.value)
		}
	}

	const submit = async () => {
		try {
			await finish(value)
		} catch (e) {
			console.log(e)
			setError('Invalid Value')
		}
	}

	const style = {}
	if (customField.fieldType === 'DATE' && dateOpen) {
		style.height = 350
	}

	if ((customField.fieldType === 'SELECT' || customField.fieldType === 'MULTISELECT') && dateOpen) {
		if (customField.options?.length > 5) {
			style.height = 350
		} else {
			style.minHeight = 50 * customField.options?.length + 50
		}
	}

	return (
		<UILIB.Dialog.Root open={true} onOpenChange={close}>
			<UILIB.Dialog.Portal >
				<UILIB.Dialog.Overlay />
				<UILIB.Dialog.Content className="inbox__generic-modal">
					<div style={style}>
						<div className="inbox__generic-modal__header">
							Edit {customField.fieldDesc}
						</div>
						<div className="inbox__generic-modal__description">
							{customField.fieldType === 'TEXT' && <UILIB.TextInput onEnterPress={submit} error={error} focus type="text" value={value} onChange={onChangeValue} />}
							{customField.fieldType === 'NUMBER' && <UILIB.TextInput onEnterPress={submit} error={error} focus type="number" value={value} onChange={onChangeValue} />}
							{customField.fieldType === 'SELECT' && <UILIB.Select error={error} onOpen={() => setDateOpen(true)} onClose={() => setDateOpen(false)} data={customField.options} value={value} onChange={onChangeValue} />}
							{customField.fieldType === 'MULTISELECT' && <UILIB.Select error={error} onOpen={() => setDateOpen(true)} onClose={() => setDateOpen(false)} multiple data={customField.options} value={value} onChange={onChangeValue} />}
							{customField.fieldType === 'BOOLEAN' && <UILIB.Select error={error} data={[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]} value={value} onChange={onChangeValue} />}
							{customField.fieldType === 'DATE' && <UILIB.TextInput onEnterPress={submit} error={error} handleCalendarOpen={() => setDateOpen(true)} handleCalendarClose={() => setDateOpen(false)} type="date" value={value} onChange={onChangeValue} />}
							{customField.fieldType === 'TELNO' && <UILIB.TextInput onEnterPress={submit} error={error} type="telno" value={value} onChange={onChangeValue} />}
						</div>
						<div className="inbox__generic-modal__cta">
							<InboxButton onClick={submit} variant="secondary">{i18n.t("chat:inbox.rightBar.chatFields.finish")}</InboxButton>
							<InboxButton onClick={close} variant="tertiary">Cancel</InboxButton>
						</div>
					</div>
				</UILIB.Dialog.Content>
			</UILIB.Dialog.Portal>
		</UILIB.Dialog.Root>
	)
}

function customFieldValue(customField, value) {
	if (value === undefined) value = customField.value;
	if (customField.fieldType === 'DATE' && value) value = DateTimeFunctions.formatDateTime(value)
	if (customField.fieldType === 'BOOLEAN') value = value ? "Yes" : "No";
	if (customField.fieldType === 'SELECT' && value) {
		if (!customField.options || customField.options.length === 0) return value;
		value = customField.options.find(f => f.value === value)?.label;
	}
	if (customField.fieldType === 'MULTISELECT' && value) {
		if (!customField.options || customField.options.length === 0) return value;
		if (!Array.isArray(value)) value = customField.options.find(f => f.value === value)?.label;
		else value = value.map(v => customField.options.find(f => f.value === v)?.label).join(', ')
	}
	return value
}
import React from 'react'

export default class IconsArrowDown extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.29303 15.707C4.10556 15.5195 4.00024 15.2652 4.00024 15C4.00024 14.7349 4.10556 14.4806 4.29303 14.293L9.29303 9.29303C9.48056 9.10556 9.73487 9.00024 10 9.00024C10.2652 9.00024 10.5195 9.10556 10.707 9.29303L15.707 14.293C15.8892 14.4816 15.99 14.7342 15.9877 14.9964C15.9854 15.2586 15.8803 15.5094 15.6948 15.6948C15.5094 15.8803 15.2586 15.9854 14.9964 15.9877C14.7342 15.99 14.4816 15.8892 14.293 15.707L10 11.414L5.70703 15.707C5.5195 15.8945 5.26519 15.9998 5.00003 15.9998C4.73487 15.9998 4.48056 15.8945 4.29303 15.707ZM4.29303 9.70703C4.10556 9.5195 4.00024 9.26519 4.00024 9.00003C4.00024 8.73487 4.10556 8.48056 4.29303 8.29303L9.29303 3.29303C9.48056 3.10556 9.73487 3.00024 10 3.00024C10.2652 3.00024 10.5195 3.10556 10.707 3.29303L15.707 8.29303C15.8892 8.48163 15.99 8.73423 15.9877 8.99643C15.9854 9.25863 15.8803 9.50944 15.6948 9.69485C15.5094 9.88026 15.2586 9.98543 14.9964 9.9877C14.7342 9.98998 14.4816 9.88919 14.293 9.70703L10 5.41403L5.70703 9.70703C5.5195 9.8945 5.26519 9.99982 5.00003 9.99982C4.73487 9.99982 4.48056 9.8945 4.29303 9.70703Z" fill={color} />
        </svg>




    }
}        
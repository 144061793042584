import React, { useState, useEffect } from "react";
import axios from "~/data/http/axios";
import { useSelector } from "react-redux";
import UILIB from "~/Common-Objects/Lib";
export default function InboxPage({ labId, onClose }) {

    const account = useSelector((state) => state.accountMaster);
    const [lab, setLab] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getLab();
    }, []);

    const getLab = async () => {
        let labData = await axios.get('/accountmaster/labs/' + labId);
        setLab(labData.data);
        setLoading(false);
    };

    if (loading) return <></>;

    return (
        <UILIB.Dialog.Root open={true}>
            <UILIB.Dialog.Portal >
                <UILIB.Dialog.Overlay />
                <UILIB.Dialog.Content>
                    <div style={{ padding: "40px" }}>
                        <h4 className="mar-b10">{lab.shortDescription}</h4>
                        <div className="mar-b25" dangerouslySetInnerHTML={{ __html: lab.description }}></div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <UILIB.Button className="button-primary mar-r10" text={"Close"} onClick={onClose} />
                            {lab.helpLink && <UILIB.Button
                                iconRight={<UILIB.Icons icon="external" />}
                                text={"Need Help?"}
                                onClick={() => { window.open(lab.helpLink, "_blank") }} />}
                        </div>
                    </div>

                </UILIB.Dialog.Content>
            </UILIB.Dialog.Portal >
        </UILIB.Dialog.Root>
    )
}
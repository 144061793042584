import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import CreateFieldDrawer from '~/pages/cp/subscribers/customfields/incAddCustomField';
import SelectFields from './selectFields'

class DynamicsFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            lookups: {
                contact: [],
                lead: []
            },
            customFields: [],
            mapping: {},
            fieldMapping: {
                contact: {},
                lead: {}
            },
            creating: {
                open: false
            }
        }

        this.getLookups = this.getLookups.bind(this)
        this.getLookupFields = this.getLookupFields.bind(this)
        this.mapCurrentSetup = this.mapCurrentSetup.bind(this)
        this.getCustomFields = this.getCustomFields.bind(this)
        this.updateMapping = this.updateMapping.bind(this)
        this.updateUpdateMappingFrom = this.updateUpdateMappingFrom.bind(this)
        this.updateUpdateMappingTo = this.updateUpdateMappingTo.bind(this)
        this.save = this.save.bind(this)
        this.createField = this.createField.bind(this)
        this.closeDrawer = this.closeDrawer.bind(this)
    }

    componentDidMount() {
        this.getLookups()
    }

    getLookups() {
        axios.get('/application/dynamics/lookups').then(response => {
            this.setState({
                lookups: response.data
            }, this.getCustomFields)
        })
    }

    getCustomFields() {
        axios.get('/customfield').then(response => {
            this.setState({
                customFields: response.data.CustomFields
            }, this.mapCurrentSetup)
        })
    }

    mapCurrentSetup() {
        var fieldMapping = this.state.fieldMapping;
        if (this.props.app.appSettings.lookups) {
            this.props.app.appSettings.lookups.contact.forEach(row => {
                fieldMapping.contact[row.field] = { checked: true, lookup: row.lookup, from: row.from, to: row.to }
                this.getLookupFields(row.lookup)
            })
            this.props.app.appSettings.lookups.lead.forEach(row => {
                fieldMapping.lead[row.field] = { checked: true, lookup: row.lookup, from: row.from, to: row.to }
                this.getLookupFields(row.lookup)
            })
        }
        this.setState({ fieldMapping, loading: false })
    }

    getLookupFields(lookup) {
        axios.get('/application/dynamics/lookups/' + lookup).then(response => {
            var mapping = this.state.mapping;
            mapping[lookup] = response.data.Fields
            this.setState({ mapping })
        })
    }

    updateMapping(type, lookup, ev) {
        var fieldMapping = this.state.fieldMapping;
        var mapping = fieldMapping[type]
        var name = ev.currentTarget.name
        if (ev.currentTarget.checked) {
            mapping[name] = { checked: true, from: '', field: lookup.target, to: '' }
        } else {
            delete mapping[name]
        }
        this.setState({ fieldMapping }, () => {
            if (!this.state.mapping[lookup.target]) {
                this.getLookupFields(lookup.target)
            }
        })
    }

    updateUpdateMappingFrom(type, ev) {
        var fieldMapping = this.state.fieldMapping;
        var mapping = fieldMapping[type];
        mapping[ev.currentTarget.name].from = ev.currentTarget.value;
        this.setState({ fieldMapping })
    }

    updateUpdateMappingTo(type, ev) {
        var fieldMapping = this.state.fieldMapping;
        var mapping = fieldMapping[type];
        mapping[ev.currentTarget.name].to = ev.currentTarget.value;
        this.setState({ fieldMapping })
    }

    createField(type, lookup) {
        var creating = this.state.creating
        creating.open = true;
        creating.type = type;
        creating.lookup = lookup
        this.setState({
            creating
        })
    }

    closeDrawer(field) {
        var creating = this.state.creating
        creating.open = false;
        if (field) {
            var customFields = this.state.customFields
            var fieldMapping = this.state.fieldMapping;
            var mapping = fieldMapping[creating.type];
            mapping[creating.lookup.name].to = field.fieldName;
            customFields.push(field)
            this.setState({ fieldMapping, customFields }, this.getCustomFields)
        }
        creating.type = '';
        creating.lookup = undefined;
        this.setState({
            creating
        })
    }

    save() {
        var binding = this.props.app
        // binding.appSettings.lookups = { contact: [], lead: [] };
        // var fieldMapping = this.state.fieldMapping;
        // Object.keys(fieldMapping.contact).forEach(prop => {
        //     binding.appSettings.lookups.contact.push({
        //         from: fieldMapping.contact[prop].from,
        //         lookup: fieldMapping.contact[prop].field,
        //         field: prop,
        //         to: fieldMapping.contact[prop].to
        //     })
        // })
        // Object.keys(fieldMapping.lead).forEach(prop => {
        //     binding.appSettings.lookups.lead.push({
        //         from: fieldMapping.lead[prop].from,
        //         lookup: fieldMapping.lead[prop].field,
        //         field: prop,
        //         to: fieldMapping.lead[prop].to
        //     })
        // })

        axios.put("/application/account/" + binding.id, binding).then(() => {
            return axios.put('/application/dynamics/fields')
        }).then(() => {
            return axios.get('/application/dynamics/picklists')
        }).then(this.props.onClose)
    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12}>
                        <UILIB.HeaderText>Select Dynamics Fields to Include</UILIB.HeaderText>
                    </UILIB.Column>
                    <UILIB.Column xs={12} margin={0}>
                        <SelectFields appBinding={this.props.app} />
                    </UILIB.Column>
                    <UILIB.Column xs={12}>
                        <UILIB.Button text="Save" onClick={this.save} className="button-md outline primary" />
                    </UILIB.Column>
                    {/* <UILIB.Column xs={12}>
                        <UILIB.HeaderText>Select Dynamics Joins to Include</UILIB.HeaderText>
                    </UILIB.Column>
                    <UILIB.Column xs={12} md={6}>
                        <UILIB.Paper className="primary">
                            <UILIB.SubHeaderText>Contacts</UILIB.SubHeaderText>
                            <UILIB.Row>
                                <UILIB.Column xs={12} margin={0}>
                                    <UILIB.Row className="text-heavy">
                                        <UILIB.Column xs={5}>Dynamics Object</UILIB.Column>
                                        <UILIB.Column xs={5}>From Dynamics Field</UILIB.Column>
                                        <UILIB.Column xs={2}></UILIB.Column>
                                    </UILIB.Row>
                                </UILIB.Column>
                                {this.state.lookups.contact.map((lookup, index) => {
                                    return <UILIB.Column key={index} xs={12} margin={0}>
                                        <UILIB.Row>
                                            <UILIB.Column xs={5}>
                                                <UILIB.CheckBox checked={!!this.state.fieldMapping.contact[lookup.name]} name={lookup.name} onChange={ev => this.updateMapping('contact', lookup, ev)}>{lookup.displayName}</UILIB.CheckBox>
                                            </UILIB.Column>
                                            <UILIB.Column xs={5} className="form-group">

                                                {!!this.state.fieldMapping.contact[lookup.name] && !this.state.mapping[lookup.target] && <span className="loading text-white">Loading</span>}
                                                {!!this.state.fieldMapping.contact[lookup.name] && !!this.state.mapping[lookup.target] && <UILIB.Select placeholder="--Select--" data={this.state.mapping[lookup.target].map(f => {
                                                    return {
                                                        label: f.displayName,
                                                        value: f.name
                                                    }
                                                })} value={this.state.fieldMapping.contact[lookup.name].from} name={lookup.name} onChange={ev => this.updateUpdateMappingFrom('contact', ev)} />}
                                            </UILIB.Column>
                                            <UILIB.Column xs={2}>
                                                {!!this.state.fieldMapping.contact[lookup.name] && <UILIB.Button text="Create" className="button-sml outline full-width" onClick={() => this.createField('contact', lookup)} />}
                                            </UILIB.Column>
                                        </UILIB.Row>
                                    </UILIB.Column>
                                })}
                            </UILIB.Row>
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={12} md={6}>
                        <UILIB.Paper className="secondary">
                            <UILIB.SubHeaderText>Leads</UILIB.SubHeaderText>
                            <UILIB.Row>
                                <UILIB.Column xs={12} margin={0}>
                                    <UILIB.Row className="text-heavy">
                                        <UILIB.Column xs={5}>Dynamics Object</UILIB.Column>
                                        <UILIB.Column xs={5}>From Dynamics Field</UILIB.Column>
                                        <UILIB.Column xs={2}></UILIB.Column>
                                    </UILIB.Row>
                                </UILIB.Column>
                                {this.state.lookups.lead.map((lookup, index) => {
                                    return <UILIB.Column key={index} xs={12} margin={0}>
                                        <UILIB.Row>
                                            <UILIB.Column xs={5}>
                                                <UILIB.CheckBox checked={!!this.state.fieldMapping.lead[lookup.name]} name={lookup.name} onChange={ev => this.updateMapping('lead', lookup, ev)}>{lookup.displayName}</UILIB.CheckBox>
                                            </UILIB.Column>
                                            <UILIB.Column xs={5} className="form-group">
                                                {!!this.state.fieldMapping.lead[lookup.name] && !this.state.mapping[lookup.target] && <span className="loading text-white">Loading</span>}
                                                {!!this.state.fieldMapping.lead[lookup.name] && !!this.state.mapping[lookup.target] && <UILIB.Select placeholder="--Select--" data={this.state.mapping[lookup.target].map(f => {
                                                    return {
                                                        label: f.displayName,
                                                        value: f.name
                                                    }
                                                })} value={this.state.fieldMapping.lead[lookup.name].from} name={lookup.name} onChange={ev => this.updateUpdateMappingFrom('lead', ev)} />}
                                            </UILIB.Column>
                                            <UILIB.Column xs={2}>
                                                {!!this.state.fieldMapping.lead[lookup.name] && <UILIB.Button text="Create" className="button-sml outline full-width" onClick={() => this.createField('lead', lookup)} />}
                                            </UILIB.Column>
                                        </UILIB.Row>
                                    </UILIB.Column>
                                })}
                            </UILIB.Row>
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={12}>
                        <UILIB.Button text="Save" onClick={this.save} className="button-md outline primary" />
                    </UILIB.Column> */}
                </UILIB.Row>
                {this.state.creating.open && <UILIB.Drawer close={this.closeDrawer} standalone={true} showClose={true} clickOutside={true}>
                    <CreateFieldDrawer getCustomFields={this.closeDrawer} standalone={true} />
                </UILIB.Drawer>}
            </div>
        );
    }
}

export default DynamicsFields;
import React, { useState, useEffect } from "react";
import DateTimeFunctions from "~/Classes/dateTimeFunctions";
import { useDispatch } from "react-redux";
import UILIB from "~/Common-Objects/Lib";
import { handleGenericError } from "~/data/actions/siteActions";
import axios from "~/data/http/axios";
import Dialog from "../../../../components/dialog";
import Button from "../../../../components/button";
import * as styles from "./index.module.scss";
import i18n from "~/i18n";

export default function ViewHistoryItemDialog({
  historyItem,
  isOpen,
  ...rest
}) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [historyItemDetail, setHistoryItemDetail] = useState(null);

  useEffect(() => {
    if (isOpen) loadInitialData();
  }, [isOpen]);

  async function loadInitialData() {
    try {
      await loadHistoryItem();
    } finally {
      setLoading(false);
    }
  }

  async function loadHistoryItem() {
    try {
      const { data } = await axios.get(
        `/inboxes/${historyItem.InboxId}/ai/${historyItem.InboxAiId}/history/${historyItem.id}`
      );
      setHistoryItemDetail(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  return (
    <Dialog className={styles.root} open={isOpen} size="s" {...rest}>
      <div className={styles.header}>
        <time
          className={styles.timestamp}
          dateTime={DateTimeFunctions.formatDateTime(historyItem?.createdAt)}
        >
          {DateTimeFunctions.formatDateTime(historyItem?.createdAt)}
        </time>

        <UILIB.Dialog.Title className="h4">
          {historyItem?.Subscriber?.emailAddress || i18n.t("chat:ai.history.view.unknown")}
        </UILIB.Dialog.Title>
      </div>

      {loading ? (
        <UILIB.LoadingIcons noClass />
      ) : (
        <div className={styles.contentWrapper}>
          <div className={styles.box}>
            <h3>{i18n.t("chat:ai.history.view.question")}</h3>
            <p>{historyItemDetail.prompt}</p>
          </div>

          <div className={styles.box}>
            <h3>{i18n.t("chat:ai.history.view.response")}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: historyItemDetail.response || i18n.t("chat:ai.history.view.noResponse"),
              }}
            />
          </div>

          <div>
            <h3>{i18n.t("chat:ai.history.view.success")}</h3>
            <UILIB.Badge color={historyItem.success ? "teal" : "red"}>
              {historyItem.success ? i18n.t("chat:ai.history.view.yes") : i18n.t("chat:ai.history.view.no")}
            </UILIB.Badge>
          </div>
        </div>
      )}

      <UILIB.Dialog.Close asChild>
        <Button className={styles.closeBtn} size="s">
          {i18n.t("chat:ai.history.view.close")}
        </Button>
      </UILIB.Dialog.Close>
    </Dialog>
  );
}

import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { aiAssistantTabOptions } from "~/Classes/conversations";
import { handleGenericError } from "~/data/actions/siteActions";
import AiAssistantTemplate from "./template";

export default function InboxAiAssistant({ userRole = null }) {
  const { path, url } = useRouteMatch();
  const { inboxId, assistantId } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [assistant, setAssistant] = useState(null);
  const [inbox, setInbox] = useState(null);

  useEffect(() => {
    if (userRole > 0) return window.open("/", "_self");
    loadInitialData();
  }, []);

  async function loadInitialData() {
    try {
      await Promise.all([loadAssistant(), loadInbox()]);
    } finally {
      setLoading(false);
    }
  }

  async function loadAssistant() {
    try {
      const { data } = await axios.get(`/inboxes/${inboxId}/ai/${assistantId}`);
      setAssistant(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function loadInbox() {
    try {
      const { data } = await axios.get(`/inboxes/${inboxId}`);
      setInbox(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  const toggleAiStatus = async function () {
    try {
      console.log("toggleAiStatus")
      const { data } = await axios.put(
        `/inboxes/${inboxId}/ai/${assistantId}/togglestatus`
      );
      loadAssistant();
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  if (loading) return <UILIB.LoadingIcons className="mar-t24" />;

  return (
    <Switch>
      <Route exact path={`${path}/:settingName`}>
        <AiAssistantTemplate
          inbox={inbox}
          assistant={assistant}
          refresh={loadInitialData}
          toggleAiStatus={toggleAiStatus}
          loadInitialData={loadInitialData}
        />
      </Route>

      {/* Redirect if "naked" route */}
      <Route exact path={path}>
        <Redirect to={`${url}/${aiAssistantTabOptions[0].value}`} />
      </Route>
    </Switch>
  );
}

import React from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import queryString from 'query-string';
import UILIB from '~/Common-Objects/Lib';
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission }
})

export default class OAuth1Response extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appId: undefined,
            oauth_token: undefined,
            oauth_verifier: undefined,
            app: undefined,
            loadedData: false,
            query: {}
        }
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        const appId = this.props.match.params.appId;

        this.setState({ appId: appId, oauth_token: query.oauth_token, oauth_verifier: query.oauth_verifier }, this.getApp)
    }

    async getApp() {
        if (!this.state.appId || !this.state.oauth_token || !this.state.oauth_verifier) return this.setState({ error: 'Something went wrong, please try again later' })

        try {
            const res = await axios.get("/application/" + this.state.appId)
            const appData = res.data.Application
            this.setState({
                app: appData
            })

            const res2 = await axios.post('/application/external/oAuth/connect1/' + this.state.appId, {
                oauth_token: this.state.oauth_token,
                oauth_verifier: this.state.oauth_verifier

            })
            if (!res2.data.Success) {
                return this.setState({
                    error: 'Something went wrong. Please try again.'
                })
            }
            if (appData.appConfigUri) {
                let appConfigUri = appData.appConfigUri;
                if (appConfigUri.indexOf("[APPID]")) appConfigUri = appConfigUri.replace("[APPID]", this.state.appId)
                if (res2.data.id) appConfigUri = appConfigUri += '?id=' + res2.data.id
                window.location.href = appConfigUri
            }
            else {
                window.location.href = '/cp/company/integrations/apps/integrationnoconfig/' + this.state.appId
            }
        } catch (e) {
            console.log(e)
            this.setState({
                error: e.data.error
            })
        }
    }

    render() {

        return <div>
            <UILIB.LoadingIcons iconType="2" className="mar-b15" />
            {!this.state.error && <h4 className="text-center">Hooking Up the app... Wait a moment</h4>}
            {!!this.state.error && <UILIB.WarningHolder>{this.state.error}</UILIB.WarningHolder>}
        </div>
    };


};



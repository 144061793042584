// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SEQQ7M0Sv3YnjfnpeCBA{font-size:14px;font-weight:500;line-height:normal;margin-bottom:2px}.DxBgCqbjAzdSNGGG0nNS{font-size:13px;line-height:normal;color:var(--grey-800);margin-bottom:0}.vw2ogGlMO0Mgn_NDXwHg{display:flex;align-items:center;justify-content:space-between;gap:30px;margin-top:40px}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/inboxSettings/fields/addEditFieldDialog/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,kBAAA,CACA,iBAAA,CAGF,sBACE,cAAA,CACA,kBAAA,CACA,qBAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".radioHeading {\n  font-size: 14px;\n  font-weight: 500;\n  line-height: normal;\n  margin-bottom: 2px;\n}\n\n.radioSubheading {\n  font-size: 13px;\n  line-height: normal;\n  color: var(--grey-800);\n  margin-bottom: 0;\n}\n\n.footer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 30px;\n  margin-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioHeading": "SEQQ7M0Sv3YnjfnpeCBA",
	"radioSubheading": "DxBgCqbjAzdSNGGG0nNS",
	"footer": "vw2ogGlMO0Mgn_NDXwHg"
};
export default ___CSS_LOADER_EXPORT___;

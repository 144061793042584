import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'
import utils from '../../../dragDropFunctions'

export default class ComponentVideo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: "",
            generatingVideo: false
        }
        this.change = this.change.bind(this);
        this.generateVideo = this.generateVideo.bind(this);
        this.generateVideoRequest = this.generateVideoRequest.bind(this);
    }

    change(event) {
        var thisVal = event.currentTarget.value;
        this.setState({ error: "" });
        this.props.updateVal(this.props.path, { value: thisVal, thumbUrl: "" });

    }

    async generateVideo() {
        var thisVal = this.props.value;
        var theURL = "";
        var error = "";
        if (thisVal.indexOf("?v=") >= 0) {

            this.setState({ generatingVideo: true })

            var urlD = thisVal.split("?v=")
            var youtubeVidID = urlD[1];
            if (youtubeVidID.indexOf("&") > 0) {
                youtubeVidID = youtubeVidID.split("&")[0];
            }
            youtubeVidID = youtubeVidID.trim();
            theURL = `https://img.youtube.com/vi/${youtubeVidID}/maxresdefault.jpg`

            let vidData = await this.generateVideoRequest(`https://img.youtube.com/vi/${youtubeVidID}/maxresdefault.jpg`)
            if (vidData.error) {
                //try lower quality
                vidData = await this.generateVideoRequest(`https://img.youtube.com/vi/${youtubeVidID}/hqdefault.jpg`)
            }
            if (!vidData.error) {
                this.setState({ generatingVideo: false })
                this.props.updateVal(this.props.path, { value: thisVal, thumbUrl: vidData.url });
            }
            if (vidData.error) {
                error = "There was an error, please check the video url"
            }
        }
        else {
            error = "Invalid Youtube Video URL"
        }
        if (error) {
            this.setState({ error, generatingVideo: false })
        }
    }

    async generateVideoRequest(url) {
        let vidBack = {
            error: false,
            url: ""
        }
        try {
            var response = await utils.getVideoThumb(this.props.templateId, url)
            if (response && response.data && response.data.url) {
                vidBack.error = false;
                vidBack.url = response.data.url;
            }
        }
        catch (err) {
            vidBack.error = true;
        }
        return vidBack;
    }
    render() {
        var currVal = this.props.value;
        if (typeof currVal == "object") currVal = "";
        var style = {};
        var savingStyle = {}
        if (this.props.elementData.optionFullWidth) style = { flex: "100%" }
        return <div className="dd_toolHolder" style={style}>
            <div className="dd_inputTool" style={{ display: "flex", alignItems: "start", width: "100%" }}>

                <UILIB.TextInput
                    outerStyle={{ flex: 1 }}
                    value={currVal}
                    label={this.props.title}
                    onChange={this.change}
                    placeHolder={this.props.title}
                    readOnly={this.state.generatingVideo}
                    disabled={this.state.generatingVideo}
                    iconRight={this.state.generatingVideo ? <UILIB.LoadingIcons iconType="2" style={{ height: "20px", width: "20px" }} /> : ""}
                    error={this.state.error ? this.state.error : ""}
                />

                <UILIB.Button className="button-primary mar-l10" style={{ marginTop: "23px" }} text="Save" onClick={this.generateVideo} disabled={this.state.generatingVideo} />
            </div>
        </div>


    }
}



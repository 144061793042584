import React, { useState, useRef, useEffect } from "react";
import UILIB from "~/Common-Objects/Lib";
import InboxButton from "../../shared/inboxButton";
import i18n from "~/i18n";
import axios from "~/data/http/axios";
import axios2 from "axios";
import EmailValidator from "../../../../../../../Classes/emailValidator";
export default function ChangeContact({
    activeInbox = {},
    activeConversation = {},
    changedContact = () => { }
}) {

    const [firstRun, setFirstRun] = useState(true);
    const [pickerOpen, setPickerOpen] = useState(false);
    const [searchEmailAddress, setSearchEmailAddress] = useState("");
    const [searchEmailAddressError, setSearchEmailAddressError] = useState("");
    const [searching, setSearching] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [foundExactMatch, setFoundExactMatch] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [searchTimer, setSearchTimer] = useState(null);

    const source = useRef(null)
    const cancelToken = useRef(axios2.CancelToken)

    useEffect(() => {
        setFirstRun(false);
    }, []);

    useEffect(() => {
        if (firstRun) return;
        setSearchEmailAddress("");
        setSearchEmailAddressError("");
        setSearching(false);
        setContacts([]);
        setFoundExactMatch(false);
        setIsEmailValid(false);

    }, [pickerOpen])

    useEffect(() => {
        if (firstRun) return;
        setSearching(true);
        setSearchEmailAddressError("");
        if (searchTimer) clearTimeout(searchTimer);
        if (source.current) {
            source.current.cancel('cancelled')
        }
        setSearchTimer(setTimeout(() => {
            searchForCustomer();
        }, 1000));

    }, [searchEmailAddress]);

    const searchForCustomer = async () => {

        const newSource = cancelToken.current.source()
        source.current = newSource
        const contacts = await axios.get(`/subscriber?search=${searchEmailAddress}`, { cancelToken: newSource.token })
        setContacts(contacts.data.Subscribers);

        setFoundExactMatch(!!contacts.data.Subscribers.find((contact) => contact.emailAddress === searchEmailAddress));
        const validEmail = EmailValidator.validateEmail(searchEmailAddress);
        setIsEmailValid(validEmail);

        setSearching(false);
    }

    const createCustomer = async () => {
        try {
            let contact = await axios.post(`/inboxes/${activeInbox.id}/subscriber`, { emailAddress: searchEmailAddress })
            goChangeContact(contact.data.Subscriber.id)
        }
        catch (e) {
            setSearchEmailAddressError("Error creating contact");
        }
    }

    const goChangeContact = async (subscriberId) => {
        try {
            await axios.put(`/inboxes/chat/${activeConversation.id}/changecontact`, { subscriberId: subscriberId })
            setPickerOpen(false);
            changedContact();
        }
        catch (e) {
            setSearchEmailAddressError("Error changing contact");
        }
    }

    const changeEmailAddress = (e) => {
        setSearchEmailAddress(e.target.value)
    }

    return (
        <>
            <UILIB.DropdownMenu.Root open={pickerOpen} onOpenChange={(e) => { setPickerOpen(e) }}>
                <UILIB.DropdownMenu.Trigger asChild>
                    <InboxButton
                        variant="ghost"
                        size="xs"
                        iconOnly
                        aria-label={i18n.t("chat:inbox.changeContact.changeContact")}
                        data-tip={i18n.t("chat:inbox.changeContact.changeContact")} data-for="changecontact"
                    >
                        <UILIB.Icon name="pencil" width="15" />
                    </InboxButton>

                </UILIB.DropdownMenu.Trigger>

                <UILIB.DropdownMenu.Portal >
                    <UILIB.DropdownMenu.Content align="start" className="chat-channel-changeContact">
                        <div className="chat-channel-changeContact-header">{i18n.t("chat:inbox.changeContact.header")}</div>
                        <div className="chat-channel-changeContact-subheader">{i18n.t("chat:inbox.changeContact.subHeader")}</div>

                        <div className="chat-channel-changeContact-searchHolder">
                            <UILIB.TextInput
                                outerStyle={{ flex: 1 }}
                                placeholder={i18n.t("chat:inbox.changeContact.enterCustomerEmail")}
                                value={searchEmailAddress}
                                onChange={(e) => { changeEmailAddress(e) }}
                                iconRight={searching ? <UILIB.LoadingIcons style={{ width: 20, height: 20 }} /> : <></>}
                                error={searchEmailAddressError}
                            />
                        </div>


                        {!searching && searchEmailAddress.length > 0 && <>
                            {(contacts.length > 0) && <>
                                <div className="chat-channel-changeContact-sectionHeaders">{i18n.t("chat:inbox.leftBar.new.existing")}</div>
                                <div className="chat-channel-changeContact-sectionList">
                                    {contacts.map((contact, index) => {
                                        return <button key={"contact_" + index} type="button" onClick={() => { goChangeContact(contact.id) }} className="chat-channel-changeContact-contactLine">
                                            <div className="chat-channel-changeContact-contactLineAvatar"><UILIB.AvatarNew src={(contact.accountImage || "").indexOf('blankAvatar.png') > -1 ? '' : contact.accountImage} size={24} name={contact.emailAddress} /></div>
                                            <div className="chat-channel-changeContact-contactLineEmail">{contact.emailAddress}</div>
                                            <div className="chat-channel-changeContact-contactLineContactType">
                                                <UILIB.Icon name={contact.type === 0 ? "person" : "office"} />
                                                <div>
                                                    {contact.type === 0 ? i18n.t("chat:inbox.leftBar.new.person") : i18n.t("chat:inbox.leftBar.new.org")}
                                                </div>
                                            </div>
                                        </button>
                                    })}
                                </div>
                            </>
                            }
                            {(contacts.length === 0 && isEmailValid == false) && <div className="chat-channel-changeContact-customerNotFound">
                                {i18n.t("chat:inbox.changeContact.enterValidEmail")}
                            </div>}

                            {(!foundExactMatch && isEmailValid == true) && <div className="chat-channel-changeContact-customerNotFound">

                                <InboxButton style={{ padding: 5 }} disabled={!isEmailValid} onClick={createCustomer}>{i18n.t("chat:inbox.changeContact.createNewContact")}</InboxButton>
                            </div>}
                        </>}

                    </UILIB.DropdownMenu.Content>
                </UILIB.DropdownMenu.Portal>
            </UILIB.DropdownMenu.Root >

        </>

    );
}


import React from 'react'
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';

@connect((store) => {
    return { user: store.user }
})
export default class IncStripeChangeGroup extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return <div>

            <h4 className="mar-b25">Change the Group</h4>
            <div className="mar-b25">You are about to change the default group that Stripe syncs contacts with.</div>
            <div className="mar-b25">If you proceed, your Stripe contacts will be automatically added to this group. </div>
            <div className="mar-b25">If you delete the group in the future, your Stripe contacts will stop being synced.</div>
            <div className="mar-b25">You can change the group at any time.</div>

            <UILIB.Button text="Select this Group" className="outline primary" onClick={this.props.changeTheGroup} />

        </div>
    }
};


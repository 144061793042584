import React from 'react'

export default class IconsBin extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 2.00006C8.81434 2.00016 8.63237 2.05194 8.47447 2.14961C8.31658 2.24728 8.18899 2.38698 8.106 2.55306L7.382 4.00006H4C3.73478 4.00006 3.48043 4.10542 3.29289 4.29295C3.10536 4.48049 3 4.73484 3 5.00006C3 5.26528 3.10536 5.51963 3.29289 5.70717C3.48043 5.8947 3.73478 6.00006 4 6.00006V16.0001C4 16.5305 4.21071 17.0392 4.58579 17.4143C4.96086 17.7893 5.46957 18.0001 6 18.0001H14C14.5304 18.0001 15.0391 17.7893 15.4142 17.4143C15.7893 17.0392 16 16.5305 16 16.0001V6.00006C16.2652 6.00006 16.5196 5.8947 16.7071 5.70717C16.8946 5.51963 17 5.26528 17 5.00006C17 4.73484 16.8946 4.48049 16.7071 4.29295C16.5196 4.10542 16.2652 4.00006 16 4.00006H12.618L11.894 2.55306C11.811 2.38698 11.6834 2.24728 11.5255 2.14961C11.3676 2.05194 11.1857 2.00016 11 2.00006H9ZM7 8.00006C7 7.73484 7.10536 7.48049 7.29289 7.29295C7.48043 7.10542 7.73478 7.00006 8 7.00006C8.26522 7.00006 8.51957 7.10542 8.70711 7.29295C8.89464 7.48049 9 7.73484 9 8.00006V14.0001C9 14.2653 8.89464 14.5196 8.70711 14.7072C8.51957 14.8947 8.26522 15.0001 8 15.0001C7.73478 15.0001 7.48043 14.8947 7.29289 14.7072C7.10536 14.5196 7 14.2653 7 14.0001V8.00006ZM12 7.00006C11.7348 7.00006 11.4804 7.10542 11.2929 7.29295C11.1054 7.48049 11 7.73484 11 8.00006V14.0001C11 14.2653 11.1054 14.5196 11.2929 14.7072C11.4804 14.8947 11.7348 15.0001 12 15.0001C12.2652 15.0001 12.5196 14.8947 12.7071 14.7072C12.8946 14.5196 13 14.2653 13 14.0001V8.00006C13 7.73484 12.8946 7.48049 12.7071 7.29295C12.5196 7.10542 12.2652 7.00006 12 7.00006Z" fill={color} />
        </svg>


    }
}        
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import i18n from '~/i18n'

export default class IncGoChangeStatus extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            currentSegment: 'active',
            pageLoaded: true,
            errorHolder: null
        };

        this.goChangeStatus = this.goChangeStatus.bind(this);
        this.goClickStatus = this.goClickStatus.bind(this);
    }

    goChangeStatus() {
        var self = this;
        var theSubs = this.props.subscribers.filter(sub => sub.checkBox.isChecked == true);
        var idsToCopy = theSubs.map((element, index) => {
            return element.subscriberData.id;
        })
        this.setState({ pageLoaded: false })
        axios.post('/subscriber/changeStatus', {
            subscriberIds: idsToCopy,
            newStatus: this.state.currentSegment
        }).then((res) => {
            self.props.reloadAfterTickedAndChanged();
        }).catch(err => {
            self.setState({ errorHolder: <UILIB.FormError>{err.data.error}</UILIB.FormError> });
        });

    }

    goClickStatus(e) {
        this.setState({ currentSegment: e.currentTarget.value })
    }

    render() {
        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />
        if (this.state.pageLoaded === true) {

            return <div>

                <div className="quickFlex mar-b25">
                    <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.goBack}>{i18n.t('goBack')}</UILIB.Hint>
                </div>

                <h4 className="mar-b25">{i18n.t('subscribers:overview.changeHeader')}</h4>
                <div className="mar-b25">{i18n.t('subscribers:overview.changeSubHeader')}</div>

                <UILIB.Select
                    onChange={this.goClickStatus}
                    data={[{ value: 'active', label: i18n.t('Active') }, { value: 'bounced', label: i18n.t('Bounced') }, { value: 'blacklisted', label: i18n.t('Blacklisted') }]}
                    value={this.state.currentSegment}
                    outerClassName="mar-b25"
                />

                <UILIB.Button
                    text={i18n.t('subscribers:overview.changeButton')}
                    className="button-primary"
                    onClick={this.goChangeStatus}
                    iconRight={<UILIB.Icons icon="tick" />} />

            </div>

        }
        else {
            return <div></div>
        }
    }
};


import React, { Component } from 'react';
import axios from '~/data/http/axios';
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib';
import DragDropFunctions from '../dragDropFunctions';

import MarketPlaceItem from './marketPlaceItem';

@connect((store) => {
    return { siteMaster: store.siteMaster }
})
class marketPlace extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userApps: [],
            loading: true
        }

        this.getUserApps = this.getUserApps.bind(this);
    }

    componentDidMount() {
        this.getUserApps();
    }


    getUserApps() {
        var self = this;
        axios.get('/application/account').then(data => {
            self.setState({ userApps: data.data.Applications, loading: false })
        })
    }

    render() {
        if (!this.props.components || !this.props.components.length) return <div></div>;


        return (
            <div className="marketPlace">
                <div className="marketPlaceHolder">
                    <h4 className="mar-b25">App Marketplace</h4>

                    <div className="mar-b25">Add elements from the world's most popular platforms to your emails...</div>
                    <div className="marketPlaceClose icon-cross" onClick={this.props.openMarket}></div>
                </div>

                <div className="marketPlaceHolder" style={{ border: "0px" }}>
                    {this.state.loading && <div><UILIB.LoadingIcons iconType="2" /></div>}
                    {!this.state.loading &&
                        <UILIB.Row>
                            {this.props.components.map((theC, index) => {

                                return <UILIB.Column xs={12} sm={6} key={"app_" + index}>
                                    <MarketPlaceItem index={index} id={"newEl-" + index} app={theC} userApps={this.state.userApps} onDrag={this.props.openMarket} content={theC.content} />
                                </UILIB.Column>


                            })}
                        </UILIB.Row>

                    }
                </div>
            </div>
        );
    }


}

export default marketPlace;
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions';
import { connect } from 'react-redux';
import i18n from '~/i18n'
import IncViewOpens from './incViewOpens';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here

import siteVars from '~/Classes/siteVars';
import InDepthChart from '../../shared/graphs/indepthChart'
import SelectFields from '../fieldSelect'

import queryString from 'query-string';
import GenericStatsHolder from '../../shared/components/genericStatsHolder';

//CONNECT TO STORE
@connect((store) => {
  return { user: store.user }
})

export default class CampaignsViewOpened extends React.Component {
  constructor(props) {
    super(props);

    this._ismounted = false;
    this.timer = null;
    this.state = {
      campaignId: this.props.match.params.campaignId,
      pageLoaded: false,
      tableData: {},
      loadingData: 1,
      sortColumn: "lastOpened",
      sortDirection: "desc",
      searchText: "",
      pageSize: 50,
      totalRows: 0,
      currentPage: 0,
      startRecord: 0,
      countries: [],
      countryCode: "",
      totalOpens: 0,
      totalSubscribers: 0,
      showBots: false
    }
    this.loadStats = this.loadStats.bind(this);
    this.tableUpdater = this.tableUpdater.bind(this);
    this.changePageSize = this.changePageSize.bind(this);
    this.changeSearchText = this.changeSearchText.bind(this);
    this.goSearchText = this.goSearchText.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.goViewSubscriber = this.goViewSubscriber.bind(this);
    this.exportCampaign = this.exportCampaign.bind(this);
    this.viewOpens = this.viewOpens.bind(this);
    this.getCountries = this.getCountries.bind(this);
    this.changeCountry = this.changeCountry.bind(this);
  }

  componentDidMount() {
    this._ismounted = true
    const query = queryString.parse(this.props.location.search);
    if (query.country) {
      this.setState({ countryCode: query.country });
    }
    this.getCountries()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.endDate != this.props.endDate || prevProps.startDate != this.props.startDate) {
      clearTimeout(this.timer);
      this.loadStats();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this._ismounted = false
  }

  goViewSubscriber(subID) {
    this.props.history.push('/cp/subscriber/' + subID);
  }

  exportCampaign() {
    let drawerContent = <SelectFields filter={"opened"}
      showBots={this.state.showBots}
      startDate={this.props.startDate}
      endDate={this.props.endDate}
      campaignId={this.state.campaignId}
      finished={() => {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", undefined, true, '500px'));
      }} />
    this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '500px'));
  }

  async getCountries() {
    let countries = await axios.get("/countries?simple=true")
    let openingCountries = await axios.get(`/campaign/${this.state.campaignId}/stats/countryoverview?bots=${this.state.showBots}`)
    openingCountries = openingCountries.data;

    countries = countries.data.Countries;
    countries = countries.filter(c => openingCountries.find(oc => oc.country == c.code))
    countries = countries.map(c => { return { label: c.name, value: c.code } });
    countries.unshift({ label: "All Countries", value: "" })

    this.setState({ countries: countries }, this.loadStats)
  }

  async loadStats(sortColumn, sortDirection, startRecord, pageSize, searchText) {
    if (!this._ismounted) return;
    if (!sortColumn) {
      sortColumn = this.state.sortColumn;
    }
    if (!sortDirection) {
      sortDirection = this.state.sortDirection;
    }
    if (!startRecord) {
      startRecord = this.state.startRecord;
    }
    if (!pageSize) {
      pageSize = this.state.pageSize;
    }
    if (!searchText) {
      searchText = this.state.searchText;
    }

    if (searchText?.length > 3) {
      sortColumn = "lastOpened"
      this.setState({ sortColumn: "lastOpened" })
    }

    var query = '?sortColumn=' + sortColumn + '&sortOrder=' + sortDirection + '&searchText=' + searchText + '&startRecord=' + startRecord + '&recordLimit=' + this.state.pageSize;
    if (this.props.startDate) {
      query += '&startDate=' + this.props.startDate.format('YYYY-MM-DD')
    }
    if (this.props.endDate) {
      query += '&endDate=' + this.props.endDate.format('YYYY-MM-DD 23:59:59')
    }

    if (this.state.countryCode) query += "&countryCode=" + this.state.countryCode
    query += "&bots=" + this.state.showBots;
    var self = this;
    let res = await axios.get('/campaign/' + self.state.campaignId + '/stats/opened' + query)
    var theTableData = res.data.Opened
      .map(thisLine => {
        return {
          gravatar: {
            headerValue: "",
            value: <UILIB.Avatar style={{ height: 'auto', width: '100%', maxWidth: "40px", maxHeight: "40px", marginRight: "10px" }} src={thisLine.gravatar} />,
            width: 50,
            orderBy: false
          },
          emailAddress: {
            headerValue: i18n.t('emailAddress'),
            value: <a onClick={() => { this.goViewSubscriber(thisLine.SubscriberId) }}>{thisLine.emailAddress}</a>,
            orderBy: true
          },
          lastOpened: {
            headerValue: i18n.t('campaigns:nav.opened'),
            value: <span>{DateTimeFunctions.formatDateTime(thisLine.openDate, 1)}</span>,
            orderBy: true
          },
          sendDate: {
            headerValue: i18n.t('campaigns:nav.sent'),
            value: <span>{DateTimeFunctions.formatDateTime(thisLine.sendDate, 1)}</span>,
            orderBy: true
          },
          openCount: {
            headerValue: i18n.t('campaigns:nav.timesOpened'),
            value: <a onClick={() => { this.viewOpens(thisLine.SubscriberId, thisLine.emailAddress) }}>{NumberFunctions.formatNumber(thisLine.openCount)}</a>,
            orderBy: true
          },
          optionsCol: {
            headerValue: " ",
            value: <UILIB.OptionsDropdown popWidth="150px" history={this.history}>
              <ul>
                <li>
                  <a onClick={() => { this.goViewSubscriber(thisLine.SubscriberId) }}>{i18n.t('campaigns:overview.viewSubscriber')}</a>
                </li>
              </ul>
            </UILIB.OptionsDropdown>
            ,
            orderBy: false,
            fixed: true,
            width: 20
          },
          robot: {
            headerValue: "Robot",
            value: <>{thisLine.bot ? "Possible" : ""}</>,
            orderBy: false
          }
        }
      })
    //load total count
    let stats = await axios.get('/campaign/' + self.state.campaignId + '/stats?ignoreLog=1&startDate=' + this.props.startDate.format('YYYY-MM-DD') + '&endDate=' + this.props.endDate.format('YYYY-MM-DD') + "&bots=" + this.state.showBots)
    stats = stats.data.Stats;
    let totalOpens = 0;
    let totalSubscribers = 0;
    if (stats && stats["Unique Opens"]) totalOpens = stats["Unique Opens"];
    if (stats && stats["Total Subscribers"]) totalSubscribers = stats["Total Subscribers"];

    self.setState({ tableData: theTableData, loadingData: 0, totalRows: res.data.countTotal, pageLoaded: true, totalOpens, totalSubscribers });
    clearTimeout(self.timer)
    if (self._ismounted) self.timer = setTimeout(self.loadStats, 20000)
  }

  tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {
    clearTimeout(this.timer);
    var thisState = {};
    if (!sortDirection) {
      sortDirection = this.state.sortDirection;
    }
    if (!sortColumn) {
      sortColumn = this.state.sortColumn;
    }
    if (!pageSize) {
      pageSize = this.state.pageSize;
    }
    if (!currentPage && currentPage != "0") {
      currentPage = this.state.currentPage;
    }
    if (!searchText) {
      searchText = this.state.searchText;
    }

    var startRecord = currentPage * pageSize;

    this.setState({ loadingData: 1, sortColumn: sortColumn, sortDirection: sortDirection, pageSize: pageSize, currentPage: currentPage, searchText: searchText, startRecord: startRecord }, this.loadStats);

  }
  changePageSize(pageSize) {
    this.tableUpdater(null, null, null, pageSize, null);
  }

  //GROUP NAME SEARCH STUFF
  changeSearchText(event) {
    this.setState({ searchText: event.target.value })
  }
  goSearchText() {
    this.tableUpdater(0, null, null, null, null);
  }
  keyPress(event) {
    if (event.key === 'Enter') {
      this.goSearchText();
    }
  }

  viewOpens(subscriberId, emailAddress) {
    var drawerContent = <IncViewOpens subscriberId={subscriberId} campaignId={this.state.campaignId} emailAddress={emailAddress} />
    this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
  }

  changeCountry(newCode) {
    clearTimeout(this.timer)
    this.setState({ countryCode: newCode }, this.loadStats)
  }
  render() {

    let selectedCountryName = "";
    if (this.state.countries.find(t => t.value === this.state.countryCode)) {
      selectedCountryName = this.state.countries.find(t => t.value === this.state.countryCode).label;
    }
    return <div>


      <div className="campaign-view-controls mar-b25">

        <UILIB.Toggle after={i18n.t('campaigns:overview.showBotOpens')} checked={this.state.showBots} name="showBots" onChange={(ev) => {
          this.setState({ showBots: ev.currentTarget.checked }, this.goSearchText)
        }} className="mar-r10 m-mar-b10"></UILIB.Toggle>

        <UILIB.TextInput type="multiDate" startDate={this.props.startDate} endDate={this.props.endDate} updateDate={this.props.updateDate}></UILIB.TextInput>

        {(this.props.canExport == true) &&
          <UILIB.Button
            className="mar-l10 m-mar-l0 m-mar-t10"
            onClick={this.exportCampaign}
            text={i18n.t('subscribers:nav.export') + " " + i18n.t('campaigns:nav.opened')}
            iconRight={<UILIB.Icons icon="download" />}
          />
        }
      </div>


      <UILIB.Row>
        <UILIB.Column xs={12} sm={3} margin={0}>
          <GenericStatsHolder
            value={this.state.totalOpens}
            limit={this.state.totalSubscribers}
            header={i18n.t('campaigns:overview.subscribersOpened')}
            link=""
            linkText=""
            linkIcon="arrowRight"
          />
        </UILIB.Column>
        <UILIB.Column xs={12} sm={9} margin={0}>
          <UILIB.Paper className="mar-b25">
            <InDepthChart title={i18n.t('campaigns:overview.opensTimeline')} type="opened" campaignId={this.state.campaignId} startDate={this.props.startDate} endDate={this.props.endDate} seatchText={this.state.searchText} changedDate={this.props.changedDate} />
          </UILIB.Paper>
        </UILIB.Column>
      </UILIB.Row>




      <UILIB.Paper>
        <UILIB.Row>
          <UILIB.Column md={6} sm={12} xs={12} className="mar-b25">
            <UILIB.TextInput
              clickToChangeClicked={this.goSearchText}
              placeholder={i18n.t('searchForEmail')}
              onChange={this.changeSearchText}
              onKeyPress={this.keyPress}
              iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
            />
          </UILIB.Column>

          <UILIB.Column md={6} hide={["sm", "xs"]} className="mar-b25" style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
            <div>{i18n.t('showing')}</div>
            <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.pageSize === 10} onClick={() => { this.changePageSize(10) }}>10</UILIB.ButtonSimple>
            <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 50} onClick={() => { this.changePageSize(50) }}>50</UILIB.ButtonSimple>
            <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
            <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>

          </UILIB.Column>
          <UILIB.Column xs={12} className="quickFlex">

            <UILIB.ButtonSelect
              className="mar-r5 mar-b25"
              selected={this.state.countries.findIndex(t => t.value === this.state.countryCode)}
              headerText={selectedCountryName}
              autoClose
              fixedHeight={true}
              data={this.state.countries.map(row => <a key={"cs_" + row.value}
                onClick={() => this.changeCountry(row.value)}>{row.label}</a>)}
            />

          </UILIB.Column>
          <UILIB.Column xs={12} className="mar-b25">
            <UILIB.DataTable1 noResultsTxt={<span> {i18n.t('noResults')}</span>} tableData={this.state.tableData} loadingData={this.state.loadingData}
              dataUpdater={this.tableUpdater} width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn={this.state.sortColumn}
              sortedDirection={this.state.sortDirection}
            />
          </UILIB.Column>
          <UILIB.Column xs={12}>
            <div style={{ textAlign: "center" }}>
              <UILIB.PagingBlock totalRows={this.state.totalRows} pageSize={this.state.pageSize} numberOfLinks="10" currentPage={this.state.currentPage}
                changePage={this.tableUpdater} text={i18n.t('page') + ":"} />
            </div>
          </UILIB.Column>
        </UILIB.Row>
      </UILIB.Paper >



    </div>
  }
}
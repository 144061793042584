import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'

class DrawerEditStep extends Component {
    constructor(props) {
        super(props);

        this.state = { doingCustomFields: false, newAutomation: {}, currentTrigger: {}, customFieldId: undefined, link: "", mounted: false, previousCampaignLinks: [] }
        this.changeCustomField = this.changeCustomField.bind(this);
        this.customFieldPicked = this.customFieldPicked.bind(this);
        this.linkPicked = this.linkPicked.bind(this);
        this.changeLink = this.changeLink.bind(this);
        this.getCampaignLinks = this.getCampaignLinks.bind(this);
        this.setMounted = this.setMounted.bind(this);
    }

    componentDidMount() {

        if (!this.props.currentTrigger.hasOptions) {
            this.props.updateTrigger(this.props.newAutomation, this.props.automationDataId);
            return;
        }

        if (this.props.previousStep != undefined) {
            this.getCampaignLinks();
        }
        else {
            this.setMounted();
        }
    }

    getCampaignLinks() {
        var self = this;
        axios.get('/template/' + this.props.previousStep.campaign.TemplateId + '/links').then(response => {
            var theLinks = response.data.Links;
            self.setState({ previousCampaignLinks: theLinks }, self.setMounted)
        }).catch(error => {
            console.log(error);
        });
    }

    setMounted() {
        var customFieldId = undefined
        var link = "";
        if (this.props.newAutomation.customFieldId) customFieldId = this.props.newAutomation.customFieldId;
        if (this.props.newAutomation.link) link = this.props.newAutomation.link;
        this.setState({ customFieldId: customFieldId, mounted: true, link: link })
    }

    changeCustomField(event) {
        var customFieldId = event.target.value;
        this.setState({ customFieldId: customFieldId })
    }

    customFieldPicked() {
        var newAutomation = this.props.newAutomation;
        newAutomation.customFieldId = this.state.customFieldId;
        this.props.updateTrigger(newAutomation, this.props.automationDataId);
    }

    changeLink(event) {
        var link = event.target.value;
        this.setState({ link: link })
    }
    linkPicked() {
        var newAutomation = this.props.newAutomation;
        newAutomation.link = this.state.link;
        this.props.updateTrigger(newAutomation, this.props.automationDataId);
    }


    render() {

        if (!this.state.mounted) return <div><UILIB.LoadingIcons iconType="2" /></div>
        var customFields = this.props.customFields;
        if (this.props.currentTrigger.hasOptions.dataType == "DATE") {
            customFields = this.props.customFields.filter(theField => theField.fieldType == "DATE");

            if (!customFields) return <div>
                <h4 className="mar-b25">No Date Fields</h4>
                <div className="mar-b25">You haven't setup any Date Fields yet, so we can't setup this automation type for you.</div>
                <UILIB.Button text="add a custom field" className="outline primary button-sml" onClick={() => this.props.history.push('/cp/subscribers/customfields')} />
            </div>
        }


        return <div>
            <h4 className="mar-b25">{this.props.currentTrigger.hasOptions.label}</h4>
            <div className="mar-b25">{this.props.currentTrigger.hasOptions.longDesc}</div>
            {this.props.currentTrigger.hasOptions.dataType == "CAMPAIGNLINKS" && <div>
                <div className="form-group">
                    <label htmlFor="">Link Clicked</label>
                    <UILIB.Select data={this.state.previousCampaignLinks.map(c => {
                        return {
                            label: c,
                            value: c
                        }
                    })} onChange={ev => this.changeLink(ev)} placeholder="Select Link" value={this.state.link} />
                </div>
                {this.state.link && <UILIB.Button text="Continue" className="outline primary button-sml" onClick={this.linkPicked} />}
            </div>}
            {this.props.currentTrigger.hasOptions.dataType != "CAMPAIGNLINKS" && <div>
                <div className="form-group">
                    <label htmlFor="">Contact Field to Use</label>
                    <UILIB.Select data={customFields.map(c => {
                        return {
                            label: c.fieldDesc,
                            value: c.id
                        }
                    })} onChange={ev => this.changeCustomField(ev)} placeholder="Select Field" value={this.state.customFieldId} />

                </div>
                {this.state.customFieldId && <UILIB.Button text="Continue" className="outline primary button-sml" onClick={this.customFieldPicked} />}
            </div>
            }

        </div>


    }
}

export default DrawerEditStep;
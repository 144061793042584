import axios from '~/data/http/axios';

class IntegrationHelpers {

    static async startoAuth(ourTrackingDomain, appData, userToken, windowOrigin) {
        if (ourTrackingDomain === "api.transpond.io") ourTrackingDomain = "api.mpzmail.com";
        var theURI = appData.oAuthAuthoriseUri;
        theURI = theURI.replace(/\[OAUTHCLIENTID\]/g, appData.oAuthAppId);
        theURI = theURI.replace(/\[RANDNO\]/g, userToken);
        var redirectURI = windowOrigin;
        if (redirectURI === 'https://app.transpond.io') redirectURI = 'https://web.mpzmail.com'
        var toReplaceWith = redirectURI + "/cp/company/integrations/oAuth/response/" + appData.id
        if (appData.options && appData.options.backendAuth) {
            toReplaceWith = 'https://' + ourTrackingDomain + '/api/oauth/app/' + appData.id + '/oauth'
        }
        theURI = theURI.replace(/\[OAUTHREDIRECTURL\]/g, toReplaceWith);

        //if there are extra params defined, add them on
        var okToGo = true
        var errors = {}
        if (appData.options && appData.options.oAuthoriseUriParams && appData.options.oAuthoriseUriParams.length) {
            appData.options.oAuthoriseUriParams.forEach(theOpt => {
                if (theOpt.required) {
                    if (!theOpt.value) {
                        errors[theOpt.key] = 'Required'
                        okToGo = false
                    } else if (theOpt.isUrl) {
                        // test its a url
                        var regex = new RegExp(/^(https):\/\/[^\s$.?#].[^\s]*$/gm)
                        if (theOpt.value.indexOf('https://') !== 0) {
                            errors[theOpt.key] = 'Invalid Url - must start with https://'
                            okToGo = false
                        } else if (!regex.test(theOpt.value)) {
                            errors[theOpt.key] = 'Invalid Url - must start with https://'
                            okToGo = false
                        }
                    }
                }
                theURI += "&" + theOpt.key + "=" + theOpt.value;
            })
        }
        if (!okToGo) {
            return {
                errors,
                success: false,
                uri: ""
            }
        }
        return {
            errors: null,
            success: true,
            uri: theURI
        }
    }
}

export default IntegrationHelpers;
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '../../../../Common-Objects/Lib';
import i18n from '~/i18n'
import NumberFunction from '~/Classes/numberFormatFunctions'
import { connect } from 'react-redux';

@connect((store) => {
    return { account: store.accountMaster }
})
export default class BulkEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            option: '',
            groups: [],
            statuses: ['Active', 'In-Active', 'Unsubscribed', 'Blacklisted'],
            segment: false,
            useFilters: true,
            selectedGroup: undefined,
            processing: false,
            tags: [],
            addingTag: false,
            addingTagError: "",
            newTagName: "",
            addingTagSave: false,
            loading: false,
            selectedTag: -1
        }

        this.getGroups = this.getGroups.bind(this);
        this.confirm = this.confirm.bind(this);
        this.clickOption = this.clickOption.bind(this);
        this.setGroup = this.setGroup.bind(this);
        this.setAll = this.setAll.bind(this)
        this.addTag = this.addTag.bind(this);
        this.getTags = this.getTags.bind(this);
    }

    componentDidMount() {
        this.getGroups()
    }

    clickOption(option) {
        this.setState({
            option
        })
    }

    setAll(ev) {
        this.setState({
            useFilters: ev.currentTarget.checked
        })
    }

    async getGroups() {
        let groups = await axios.get('/group')
        groups = groups.data.Groups.filter(g => g.id !== this.props.id).map((group) => {
            return {
                label: group.groupName,
                value: group.id,
            }
        });

        this.setState({
            groups
        }, this.getTags)

    }

    async getTags() {
        let tags = await axios.get(`/tags?tagType=0`)
        tags = tags.data.map(m => {
            return {
                value: m.id,
                label: m.tagName,
                ApplicationId: m.Application?.id,
                iconRight: m.Application ? <UILIB.Avatar for={m.id + '_app'} tooltip={m.Application.appName} src={m.Application.appLogoUri} style={{ height: 20, width: 20 }} /> : ''
            }
        });

        this.setState({
            tags,
            loading: false
        })
    }

    setGroup(ev) {
        this.setState({
            selectedGroup: ev.currentTarget.value
        })
    }

    async addTag() {
        this.setState({ addingTagSave: true })
        try {
            let allTags = await axios.post('/tags', {
                tagType: 0,
                tagName: this.state.newTagName
            })
            let newTag = allTags.data.find(f => f.tagName == this.state.newTagName && f.tagType === 0)
            this.setState({ selectedTag: newTag ? newTag.id : -1, addingTag: false, addingTagError: "", newTagName: "", addingTagSave: false }, this.getTags)
        }
        catch (err) {
            let error = "Unable to create Tag, please try again;"
            if (err.data && err.data.error) error = err.data.error
            this.setState({ addingTagSave: false, addingTagError: error })
        }
    }

    confirm() {
        var promise;
        const body = {}
        if (this.state.useFilters) {
            body.tags = this.props.selectedTags.map(t => t.id || t)
            body.linkTags = this.props.selectedLinkTags.map(t => t.id || t)
            body.searchText = this.props.searchText
            if (typeof this.props.segment === 'object') {
                body.SegmentId = this.props.segment.id
            } else {
                body.systemStatus = this.props.segment
            }
        }
        if (this.props.isBlackList) {
            body.SegmentId = this.props.segment.id
        }
        switch (this.state.option) {
            case 'delete':
                body.action = 'remove'
                promise = axios.post('/group/' + this.props.id + '/subscriber/bulk', body)
                break;
            case 'copy':
                body.action = 'copy'
                body.destination = this.state.selectedGroup
                promise = axios.post('/group/' + this.props.id + '/subscriber/bulk', body)
                break;
            case 'move':
                body.action = 'move'
                body.destination = this.state.selectedGroup
                promise = axios.post('/group/' + this.props.id + '/subscriber/bulk', body)
                break;
            case 'change':
                body.action = 'status'
                body.destination = this.state.selectedGroup
                promise = axios.post('/group/' + this.props.id + '/subscriber/bulk', body)
                break;
            case 'tag':
                body.action = 'tag'
                body.destination = this.state.selectedTag
                promise = axios.post('/group/' + this.props.id + '/subscriber/bulk', body)
                break;
        }

        this.setState({
            error: '',
            processing: true
        })

        if (promise) {
            promise.then((response) => {
                if (response.data.success) {
                    this.props.close(true);
                } else {
                    this.setState({
                        error: 'You can only have one bulk job in progress at a time',
                        processing: false
                    })
                }
            }).catch(e => {
                console.log(e.data)
                this.setState({
                    error: 'You can only have one bulk job in progress at a time',
                    processing: false
                })
            })
        } else {
            this.props.close(false);
        }
    }

    render() {

        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />

        if (this.state.addingTag) return <>

            <h4 className="mar-b25">Add a new Tag</h4>
            <UILIB.TextInput
                error={this.state.addingTagError}
                outerClassName="mar-b15"
                placeholder="New Tag Name"
                value={this.state.newTagName}
                onChange={e => this.setState({ newTagName: e.currentTarget.value })}
            />
            <UILIB.Button
                onClick={this.addTag}
                disabled={this.state.addingTagSave}
                className="button-md button-primary mar-r10"
                text="Add New"
            />
            <UILIB.Button
                onClick={() => { this.setState({ addingTag: false, addingTagError: "", newTagName: "", addingTagSave: "" }) }}
                disabled={this.state.addingTagSave}
                className="button-sml outline grey full-width"
                text={i18n.t('subscribers:overview.cancel')}
            />

        </>

        let totalSubs = NumberFunction.formatNumber((this.state.useFilters ? this.props.segmentTotal : this.props.totalSubs))
        let fontSize = 13
        if (totalSubs.length > 3) fontSize = 11
        if (totalSubs.length > 4) fontSize = 10
        if (totalSubs.length > 5) fontSize = 9

        const tag = this.state.tags.find(t => t.value === this.state.selectedTag)

        let continueDisabled = false
        if ((this.state.option == 'copy' || this.state.option == 'move' || this.state.options == 'change') && !this.state.selectedGroup) continueDisabled = true;
        if (this.state.option == 'tag' && this.state.selectedTag <= 0) continueDisabled = true;
        return (
            <div>

                <h4 className="mar-b25">{i18n.t('subscribers:overview.bulkEditSubscribers')}</h4>
                {!this.state.useFilters && <div className="mar-b25">{i18n.t('subscribers:overview.thisWillApplyToAllSubs')}</div>}

                {!this.state.option && <div>

                    <UILIB.CheckBox
                        outerClassName="mar-b25"
                        checked={this.state.useFilters}
                        onChange={this.setAll}
                    >
                        Apply to current filtered contacts
                    </UILIB.CheckBox>

                    <ul className="list avatar with-lines with-arrow with-notification">
                        <li className="middle-md" onClick={() => { this.clickOption("delete") }} >
                            <div className="notify">
                                <span style={{ fontSize }}>{totalSubs}</span>
                            </div>
                            <p>{i18n.t('delete')}</p>
                            <div className="arrow">
                                <UILIB.Icons icon="arrowRight" />
                            </div>
                        </li>
                        {!this.props.isBlackList && <li className="middle-md" onClick={() => { this.clickOption("copy") }} >
                            <div className="notify">
                                <span style={{ fontSize }}>{totalSubs}</span>
                            </div>
                            <p>{i18n.t('subscribers:overview.optionCopy')}</p>
                            <div className="arrow">
                                <UILIB.Icons icon="arrowRight" />
                            </div>
                        </li>}
                        {!this.props.isBlackList && <li className="middle-md" onClick={() => { this.clickOption("move") }} >
                            <div className="notify">
                                <span style={{ fontSize }}>{totalSubs}</span>
                            </div>
                            <p>{i18n.t('subscribers:overview.optionMove')}</p>
                            <div className="arrow">
                                <UILIB.Icons icon="arrowRight" />
                            </div>
                        </li>}
                        {!this.props.isBlackList && <li className="middle-md" onClick={() => { this.clickOption("tag") }} >
                            <div className="notify">
                                <span style={{ fontSize }}>{totalSubs}</span>
                            </div>
                            <p>{i18n.t('subscribers:overview.optionTag')}</p>
                            <div className="arrow">
                                <UILIB.Icons icon="arrowRight" />
                            </div>
                        </li>}
                        {!this.props.isBlackList && <li className="middle-md" onClick={() => { this.clickOption("change") }} >
                            <div className="notify">
                                <span style={{ fontSize }}>{totalSubs}</span>
                            </div>
                            <p>{i18n.t('subscribers:overview.changeStatus')}</p>
                            <div className="arrow">
                                <UILIB.Icons icon="arrowRight" />
                            </div>
                        </li>}
                    </ul>


                </div>}
                {!!this.state.option && <div>
                    {this.state.option == 'delete' && <div className="mar-b25">{i18n.t('subscribers:overview.thisWillDeleteAllSubsGroup')}</div>}
                    {this.state.option == 'copy' && <div>
                        <div className="mar-b25">{i18n.t('subscribers:overview.thisWillAddTheseSubsGroup')}</div>
                        <UILIB.Select outerClassName="mar-b25" data={this.state.groups} value={this.state.selectedGroup} placeholder={i18n.t('subscribers:overview.selectGroup')} onChange={this.setGroup} />
                    </div>}
                    {this.state.option == 'move' && <div>
                        <div className="mar-b25">{i18n.t('subscribers:overview.thisWillRemoveAndAddGroup')}</div>
                        <UILIB.Select outerClassName="mar-b25" data={this.state.groups} value={this.state.selectedGroup} placeholder={i18n.t('subscribers:overview.selectGroup')} onChange={this.setGroup} />
                    </div>}
                    {this.state.option == 'change' && <div>
                        <div className="mar-b25">{i18n.t('subscribers:overview.selectYourNewStatus')}</div>
                        <UILIB.Select
                            outerClassName="mar-b25"
                            data={this.state.statuses.map(s => {
                                return {
                                    label: i18n.t(s),
                                    value: s
                                }
                            })} value={this.state.selectedGroup} placeholder={i18n.t('subscribers:overview.selectStatus')} onChange={this.setGroup} />
                        {this.state.selectedGroup === 'Active' && <UILIB.WarningHolder className="mar-b25">This will not remove the Denylisted or Bounced status from contacts</UILIB.WarningHolder>}
                    </div>}
                    {this.state.option == 'tag' && <div>
                        <div className="mar-b25">{i18n.t('subscribers:overview.selectATagToAdd')}</div>
                        <UILIB.Select
                            outerClassName="mar-b25"
                            data={this.state.tags}
                            value={this.state.selectedTag}
                            placeholder={i18n.t('subscribers:overview.selectTag')}
                            onChange={(ev) => { this.setState({ selectedTag: ev.target.value }) }}
                            label={<div className="text-right"><a onClick={() => { this.setState({ addingTag: true }) }}>Add a new Tag</a></div>}
                        />
                        {(this.state.selectedTag && this.state.selectedTag > 0) && <UILIB.WarningHolder className="mar-b25">
                            <div className="text-heavy">Warning!</div>
                            By continuing you will automatically add the tag to ALL contacts in this group.
                        </UILIB.WarningHolder>}

                        {!!tag && tag.ApplicationId === 8 && <UILIB.WarningHolder className="mar-b25">
                            <div className="text-heavy">Warning!</div>
                            By selecting a Capsule tag this change will also appear in Capsule
                        </UILIB.WarningHolder>}
                    </div>}
                    <div className="quickFlex mar-b20">
                        <UILIB.Button className="button-primary mar-r10" iconRight={<UILIB.Icons icon="tick" />} disabled={continueDisabled} text={i18n.t('subscribers:overview.confirm')} onClick={this.confirm} />
                        <UILIB.Button text={i18n.t('subscribers:overview.cancel')} className="button-sml outline grey full-width" onClick={() => this.setState({ option: undefined })} />
                    </div>
                    {!!this.state.error && <div className='text-red'>{this.state.error}</div>}
                    {this.state.processing && <UILIB.LoadingLine />}

                </div>}
            </div>
        );
    }
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';

@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})
export default class addHostedServer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            domains: [],
            pageLoaded: false,
            serverName: "",
            customServerDomain: "",
            serverDomain: "",
            errors: {},
            addError: ""
        }

        this.getServerHosts = this.getServerHosts.bind(this);
        this.continue = this.continue.bind(this);
        this.isAlphaNumeric = this.isAlphaNumeric.bind(this);
    }

    componentWillMount() {
        this.getServerHosts();
    }

    getServerHosts() {
        axios.get("/mailServer/add/hostdomains").then(data => {
            var domains = data.data;
            domains.push({ id: -99999, name: "Your own Domain" })
            this.setState({ domains: domains, pageLoaded: true })
        })
    }

    isAlphaNumeric(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (!(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)) { // lower alpha (a-z)
                return false;
            }
        }
        return true;
    };

    async continue() {
        let errors = {};
        let errored = false;
        if (!this.state.serverName || this.state.serverName.length < 3) {
            errored = true;
            errors.serverName = "Please enter a longer server name"
        }

        if (!this.isAlphaNumeric(this.state.serverName)) {
            errored = true;
            errors.serverName = "Please enter only letters or numbers"
        }

        if (this.state.serverDomain == -99999) {
            if (!this.state.customServerDomain || this.state.customServerDomain.length < 3) {
                errored = true;
                errors.customServerDomain = "Please enter a longer domain name"
            }
        }
        else {
            if (!this.state.serverDomain) {
                errored = true;
                errors.serverDomain = "Please select a server domain"
            }
        }
        this.setState({ errors })
        if (errored) return;
        var serverDomain = this.state.serverDomain;
        var serverDomainTxt = "";
        if (this.state.serverDomain == -99999) {
            serverDomain = this.state.customServerDomain
            serverDomainTxt = serverDomain
        }
        else {
            serverDomainTxt = this.state.domains.find(d => d.id == serverDomain).name;
        }

        let existing = await axios.post("/mailServer/add/checkservername", { name: this.state.serverName + "." + serverDomainTxt })
        if (!existing.data.success) {
            errors.serverName = "This server name already exists";
            this.setState({ errors })
            return;
        }

        this.props.addHostedServerPay(this.state.serverName, serverDomain, serverDomainTxt);
    }

    render() {
        if (!this.state.pageLoaded) return <div className="loading">Loading</div>

        let okToContinue = false;
        let finalServerName = "";
        if (this.state.serverName.length > 2 && this.state.serverDomain) {
            if (this.state.serverDomain == -99999 && this.state.customServerDomain.length > 3 && this.state.customServerDomain.indexOf(".") > -1) {
                okToContinue = true;
                finalServerName = this.state.serverName + "." + this.state.customServerDomain;
            }
            if (this.state.serverDomain > 0) {
                okToContinue = true;
                finalServerName = this.state.serverName + "." + this.state.domains.find(d => d.id == this.state.serverDomain).name;
            }
        }
        return (
            <div>
                <h4 className="mar-b25">Add a Hosted Server</h4>
                <div className="mar-b25">Purchase a pre-configured, hosted email server from {this.props.siteMaster.siteName}</div>

                <div className="mar-b10">Choose your new server name</div>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} className="mar-b25" >
                    <UILIB.TextInput placeholder="mail12345" name="serverName" value={this.state.serverName} outerStyle={{ flex: "1" }} onChange={(ev) => { this.setState({ serverName: ev.target.value }) }} error={this.state.errors.serverName} />
                    <div style={{ flex: "0 0 20px", textAlign: "center" }}>.</div>
                    <UILIB.Select data={this.state.domains.map(h => { return { value: h.id, label: h.name } })} outerStyle={{ flex: "1" }} value={this.state.serverDomain} placeholder="Select a domain" onChange={(ev) => { this.setState({ serverDomain: ev.target.value }) }} error={this.state.errors.serverDomain} />
                </div>
                {this.state.serverDomain == -99999 && <div className="mar-t15 mar-b25">
                    <UILIB.TextInput placeholder="yourdomain.com" name="customServerDomain" value={this.state.customServerDomain} onChange={(ev) => { this.setState({ customServerDomain: ev.target.value }) }} error={this.state.errors.customServerDomain} />
                </div>}
                {
                    okToContinue && <div className="mar-b25">
                        <div className="mar-b10">Your new dedicated mail server will be called</div>
                        <h4>{finalServerName}</h4>

                    </div>
                }
                <UILIB.Button text="Continue" onClick={this.continue} disabled={okToContinue == false} />
            </div >
        );
    }

};


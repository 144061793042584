import React from 'react'
import UILIB from '~/Common-Objects/Lib';
import NumberFormatFunctions from '~/Classes/numberFormatFunctions';

import { connect } from 'react-redux';
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { siteMaster: store.siteMaster, user: store.user, accountMaster: store.accountMaster }
})

export default class GenericStatsHolder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let value = this.props.value;
        if (!isNaN(value)) {
            value = NumberFormatFunctions.formatNumber(this.props.value) || 0;
        }

        let paperClass = "dashHeight"
        if (this.props.className) paperClass += " " + this.props.className
        let outerStyle = {};
        if (this.props.outerStyle) outerStyle = this.props.outerStyle;

        return <UILIB.Paper className={paperClass} style={outerStyle}>
            <h4 className={this.props.subHeader ? "mar-b5" : "mar-b24"}>{this.props.header}</h4>
            {this.props.subHeader && <div className="mar-b24">{this.props.subHeader}</div>}
            <div className="quickFlex mar-b24" style={{ justifyContent: "space-between" }}>
                <div className="quickFlex" >
                    <h4 className="mar-r16 statistic">{value}</h4>
                    {(this.props.limit > -1) && <span className="statistic-sub">/ {NumberFormatFunctions.formatNumber(this.props.limit) || 0}</span>}
                    {(this.props.limitText) && <span className="mar-l5">{this.props.limitText}</span>}
                </div>
                {(this.props.link && this.props.linkText) && <div>
                    <UILIB.Button
                        onClick={this.props.link}
                        iconRight={this.props.linkIcon ? <UILIB.Icons icon={this.props.linkIcon} /> : ""}
                        iconLeft={this.props.linkIconLeft ? <UILIB.Icons icon={this.props.linkIconLeft} /> : ""}
                    >{this.props.linkText}
                    </UILIB.Button>
                </div>}
            </div>
            {(this.props.limit > -1) && <div>
                <UILIB.SmallLineGraph color={this.props.color} width="100%" height="4" textAlign={"end-xs text-grey"} hideText={false} onlyPercent={true} val1={this.props.value || 0} val2={this.props.limit || 0} />
            </div>}
            {this.props.additionalInfo && this.props.additionalInfo}
            {(this.props.limit == -1) && <div style={{ height: 24 }}></div>}
        </UILIB.Paper >
    }
}
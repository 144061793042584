import React from 'react'
import Help from '~/Common-Objects/Logos/help'
import ReactTooltip from 'react-tooltip'

export default class HeaderText extends React.Component {
    render() {
        if (this.props.tooltip) {
            return <h4 className={"page-title " + this.props.className} style={this.props.style}>{this.props.children} <span>
                <Help title="help" width="16px" data-tip data-for={'header' + this.props.for} /></span>
                <ReactTooltip effect='solid' className={"tooltip " + this.props.className} id={'header' + this.props.for} >{this.props.tooltip}</ReactTooltip>
            </h4>
        } else {
            return <h4 className={"page-title " + this.props.className} style={this.props.style}>{this.props.children}</h4>
        }

    }
}

import React, { useEffect, useState } from 'react'
import axios from '../../../../data/http/axios'
import UILIB from '../../../../Common-Objects/Lib'
import EmailValidator from '../../../../Classes/emailValidator'

const systemLinks = ['{{{__UNSUBSCRIBE_URL}}}', '{{{__UPDATE_URL}}}', '{{{__JUNK_URL}}}', '{{{__PREVIEW_URL}}}']
async function checkLink(templateId, link) {
    const res = await axios.post('/template/' + templateId + '/links/validate', {
        link
    })
    return res.data.valid
}

export default function LinkChecker({ templateId }) {
    const [links, setLinks] = useState(null)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        axios.get('/template/' + templateId + '/validateLinks').then(res => {
            setLinks(res.data)
        })
    }, [templateId])

    useEffect(() => {
        (async () => {
            if (links && links.length) {
                const errs = {}
                const dupeChecker = []
                for (const link of links) {
                    if (!link.href) continue
                    if (systemLinks.indexOf(link.href) > -1) continue
                    if (link.href.indexOf('mailto:') === 0) continue
                    if (link.href.indexOf('tel:') === 0) continue
                    if (dupeChecker.indexOf(link.href) > -1) continue
                    checkLink(templateId, link.href).then(r => {
                        errs[link.href] = r
                        setErrors({ ...errs })
                    })

                }
            }
        })()
    }, [links])

    if (!links) {
        return <UILIB.LoadingIcons iconType="2" />
    }

    const nonSystemLinks = links.filter(l => systemLinks.indexOf(l.href) === -1)

    return (
        <div data-test="link-checker">
            <h4 className="mar-b25">Found {nonSystemLinks.length} Links</h4>
            {nonSystemLinks.map((link, index) => {
                let classes = 'linkChecker '
                let error = ''
                if (!link.href || link.href === 'http://' || link.href === 'https://') {
                    error = 'Blank Link'
                    if (!link.src) {
                        classes += ' red'
                    } else {
                        classes += ' grey'
                    }
                } else if (link.href.indexOf('mailto:') === 0 && link.href.indexOf('{{') === -1) {
                    let emailAddress = link.href.split('mailto:')[1]
                    if (emailAddress) {
                        emailAddress = emailAddress.split('?')[0]
                    }
                    if (!EmailValidator.validateEmail(emailAddress)) {
                        classes += ' red'
                        error = 'Invalid Email Address'
                    } else {
                        classes += ' green'
                        error = 'Valid'
                    }
                } else if (link.href.indexOf('tel://') === 0) {
                    if (link.href === 'tel://') {
                        classes += ' red'
                        error = 'Blank Link'
                    } else {
                        classes += ' green'
                        error = 'Valid'
                    }
                } else {
                    if (!errors.hasOwnProperty(link.href)) {
                        classes += ' grey'
                        error = <span className="loading">Checking Link</span>
                    } else if (!errors[link.href]) {
                        classes += ' red'
                        error = 'Invalid Link'
                    } else {
                        classes += ' green'
                        error = 'Valid'
                    }
                }

                let imgClasses = 'linkImage'
                if (link.src && (link.src.indexOf('round-white.png') > -1 || link.src.indexOf('square-white.png') > -1)) {
                    // its one of ours so set the bgcolor
                    imgClasses += ' dark'
                }

                return <div key={index} className={classes}>
                    {!!link.src && <img className={imgClasses} src={link.src} />}
                    <div className="linkTextHolder">
                        {!link.src && <div className="linkCheckerText">{link.text || link.href}</div>}
                        <div className="linkCheckerUrl">{link.href || 'NO LINK'}</div>
                    </div>
                    <div className="error">{error}</div>
                </div>
            })}
        </div>
    )
}
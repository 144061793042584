import React from 'react'
import Icons from '../UI/Icons'

export default function CustomPieLegend({ payload }) {
    return (
        <div className='chart-custom-pie-legend'>
            {payload.map((row, i) => {
                return <div className="chart-custom-pie-legend-item" key={i}>
                    <Icons icon="radiobutton" className="vertical-center" color={row.color} />
                    <div className='mar-l10'>{row.payload.name || row.value}</div>
                </div>
            })}
        </div>
    )
}
import React from 'react';
import UILIB from '~/Common-Objects/Lib'

export default function Campaigns({ campaigns = [], mailserver }) {
    return (
        <div>
            <h4 className="mar-b25">Campaigns {mailserver.name} sent</h4>
            {campaigns.map((campaign) => {
                return <div style={{ display: "flex", borderBottom: "1px dotted grey", paddingBottom: "10px" }} key={"c_" + campaign.id} className="mar-b10" >
                    <div style={{ flex: "1" }}>
                        {campaign.campaignName} <strong>({campaign.sends} Sends)</strong>
                    </div>
                    <div>
                        <UILIB.Button className="button-sml" text="View Campaign" onClick={() => { window.open(`/cp/campaigns/view/${campaign.CampaignId}`, '_blank') }} />
                    </div>
                </div>
            })}
        </div>
    );
}
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import axios from "~/data/http/axios";
import {
  alterSiteDrawer,
  handleGenericError,
} from "~/data/actions/siteActions";
import UILIB from "~/Common-Objects/Lib";
import i18n from "~/i18n";
import { mapEmptyAnswersToQuestion } from "~/Classes/polls";
import { useQuery } from "~/Classes/url";
import PollResultsOverview from "./components/overview";
import PollResultsQuestion from "./components/question";
import PollResultsAnswers from "./components/answers";

export default function PollResults() {
  const { campaignId } = useParams();
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);
  const [polls, setPolls] = useState([]);
  const [activePoll, setActivePoll] = useState(null);

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    if (polls.length === 0) return;

    setActivePoll(getInitialPoll());
  }, [polls]);

  useEffect(() => {
    if (polls.length > 1 && activePoll) {
      history.replace({ search: `poll=${activePoll.pollId}` });
    }
  }, [polls, activePoll]);

  async function loadInitialData() {
    try {
      await Promise.all([loadCampaignStats(), loadPolls()]);
    } finally {
      setLoading(false);
    }
  }

  async function loadCampaignStats() {
    try {
      const { data } = await axios.get(
        `/campaign/${campaignId}/stats?ignoreLog=1`
      );

      setStats(data.Stats);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function loadPolls() {
    try {
      const { data } = await axios.get(`/campaign/${campaignId}/polls`);

      setPolls(data.Polls);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  function getInitialPoll() {
    if (query.get("poll")) {
      return polls.find((poll) => poll.pollId === query.get("poll"));
    }

    return polls[0];
  }

  async function exportPollResults(pollId) {
    try {
      const { data } = await axios.get(
        `/campaign/${campaignId}/polls/${pollId}/export`
      );

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `poll_${pollId}_export.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  function viewPollResponses(question, questionIndex) {
    const drawerContent = (
      <PollResultsAnswers
        pollId={activePoll.pollId}
        question={question}
        questionIndex={questionIndex}
        campaignId={campaignId}
      />
    );

    dispatch(
      alterSiteDrawer(true, true, "right", drawerContent, true, "900px")
    );
  }

  function handleChangePoll(pollId) {
    const selectedPoll = polls.find((poll) => poll.pollId === pollId);

    setActivePoll(selectedPoll);
  }

  const pollSelectOptions = polls.map((poll, index) => {
    return {
      label: `Poll #${index + 1}`,
      value: poll.pollId,
    };
  });

  if (loading) return <UILIB.LoadingIcons />;

  return (
    <div className="campaign-poll-results">
      <div className="campaign-poll-results__header">
        {polls.length > 1 && (
          <UILIB.Select
            data={pollSelectOptions}
            value={activePoll?.pollId}
            onChange={(e) => handleChangePoll(e.target.value)}
          />
        )}

        <h2
          className={classnames("h3", {
            "visually-hidden": polls.length > 1,
          })}
        >
          Poll #{polls.indexOf(activePoll) + 1}
        </h2>

        <UILIB.Button
          iconRight={<UILIB.Icons icon="download" />}
          onClick={() => {
            exportPollResults(activePoll.pollId);
          }}
        >
          {i18n.t("campaigns:polls.export")}
        </UILIB.Button>
      </div>

      {activePoll && (
        <UILIB.Row>
          <UILIB.Column xs={12} sm={12} md={3} lg={3} margin={0}>
            <UILIB.Paper>
              <PollResultsOverview
                completed={activePoll.answered}
                total={stats?.["Total Sent"]}
              />
            </UILIB.Paper>
          </UILIB.Column>

          <UILIB.Column xs={12} sm={12} md={9} lg={9} margin={0}>
            {activePoll.questions.map((question, index) => {
              const { value: questionType } = question;
              const questionDataFormatted = mapEmptyAnswersToQuestion(question);

              let questionAnswers = [];
              if (questionType === "question") {
                questionAnswers = question.answers.value
                  .filter((answer) => answer.value !== null)
                  .slice(0, 3);
              }
              if (question.value === "intro" || question.value === "outro") return <></>
              return (
                <UILIB.Paper key={index}>
                  <PollResultsQuestion
                    displayIndex={index}
                    question={questionDataFormatted}
                    noOfResponses={activePoll.answered}
                    onViewResponsesClick={() =>
                      viewPollResponses(question, index)
                    }
                  >
                    {questionType === "question" &&
                      questionAnswers.length > 0 && (
                        <div className="campaign-poll-results-question__latest-responses">
                          <h4 className="text-md">
                            {i18n.t("campaigns:polls.latestResponses")}
                          </h4>

                          <ul className="faux-list">
                            {questionAnswers.map((answer) => (
                              <li key={answer.value}>{answer.value}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                  </PollResultsQuestion>
                </UILIB.Paper>
              );
            })}
          </UILIB.Column>
        </UILIB.Row>
      )}
    </div>
  );
}

import React from 'react'
import axios from 'axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import LeftPanel from './loginAttractMode.jsx';
import RegisterComplete from './registerComplete';
import DbConn from '~/Classes/siteVars.js'
// import i18n from '~/i18n'
import { I18n } from 'react-i18next';
import queryString from 'query-string';
import * as user from '~/data/actions/userActions'; //now we can use user actions here
import GoogleAnalytics from '~/Classes/googleAnalytics';
import TranspondTracking from '../../Classes/siteTrackingHelpers.jsx';
//CONNECT TO STORE
@connect((store) => {
	return {
		user: store.user,
		siteMaster: store.siteMaster
	}
})

export default class RegisterForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			companyName: '',
			emailAddress: '',
			password: '',
			fullName: '',
			firstName: '',
			lastName: '',
			masterErrorMsg: '',
			confirmEmail: '',
			confirmPassword: '',
			confirmEmailError: '',
			confirmPasswordError: '',
			accountNameError: '',
			firstNameError: '',
			lastNameError: '',
			emailAddressError: '',
			complete: false,
			newsletterSignup: false,
			welcome: true,
			isMobile: false,
			affiliateId: '',
			mpztid: '',
			approvedTerms: false,
			termsError: ""
		};
		this.inputChange = this.inputChange.bind(this);
		this.checkRegistration = this.checkRegistration.bind(this);
		this.redirectToPage = this.redirectToPage.bind(this);
		this.checkNewsletter = this.checkNewsletter.bind(this);
		this.updateDimensions = this.updateDimensions.bind(this)
		this.login = this.login.bind(this)
		this.checkInputs = this.checkInputs.bind(this)
	}

	componentDidMount() {
		const query = queryString.parse(location.search);
		const welcome = query.welcome;
		const affiliateId = query.aff;
		const mpztid = query.mpztid;
		this.setState({
			welcome: !welcome,
			affiliateId: affiliateId,
			mpztid: mpztid
		}, this.updateDimensions)
		window.addEventListener("resize", this.updateDimensions);
	}

	updateDimensions() {
		this.setState({ isMobile: window.innerWidth < 1025 })
	}

	redirectToPage(pageURL) {
		this.props.history.push('/' + pageURL);
	}

	inputChange(event) {
		this.setState({
			[event.currentTarget.name]: event.currentTarget.value
		}, () => {
			if (this.state.hasCheckedForErrors) {
				this.checkInputs()
			}
		});
	}

	login() {
		this.props.dispatch(user.loginAndGetToken(this.state.emailAddress, this.state.password));
	}

	checkInputs() {
		var obj = { confirmEmailError: '', confirmPasswordError: '', accountNameError: '', firstNameError: '', lastNameError: '', hasCheckedForErrors: true, termsError: "" }
		var hasErrors = false;


		if (!this.state.companyName || this.state.companyName.length < 3) {
			hasErrors = true;
			if (!this.state.companyName) {
				obj.accountNameError = 'Please enter a Company Name'
			} else {
				obj.accountNameError = 'Please enter a longer Company Name'
			}
		} else {
			try {
				var regex = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi
				if (regex.test(this.state.companyName)) {
					hasErrors = true;
					obj.accountNameError = 'Company Names cannot contain URLs or Links'
				}
			} catch (e) {

			}
		}

		if (!this.state.firstName || this.state.firstName.length < 3) {
			hasErrors = true;
			if (!this.state.firstName) {
				obj.firstNameError = 'Please enter a First Name'
			} else {
				obj.firstNameError = 'Please enter a longer First Name'
			}
		}

		if (!this.state.lastName || this.state.lastName.length < 3) {
			hasErrors = true;
			if (!this.state.lastName) {
				obj.lastNameError = 'Please enter a Last Name'
			} else {
				obj.lastNameError = 'Please enter a longer Last Name'
			}
		}


		let requiresTerms = false;
		if ((this.props.siteMaster.termsUrl && this.props.siteMaster.termsUrl.length > 5) || (this.props.siteMaster.privacyUrl && this.props.siteMaster.privacyUrl.length > 5)) {
			requiresTerms = true;
		}

		if (requiresTerms && !this.state.approvedTerms) {
			hasErrors = true;
			obj.termsError = "Please accept the terms and conditions of use"
		}


		this.setState(obj)
		return hasErrors
	}

	checkRegistration() {

		var hasErrors = this.checkInputs()
		if (hasErrors) return


		//todo default time zone here
		var theTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		var self = this;
		this.setState({ masterErrorMsg: "", emailAddressError: "" });

		var query = queryString.parse(location.search);

		var theData = {
			accountName: self.state.companyName,
			emailAddress: self.state.emailAddress,
			password: self.state.password,
			firstName: self.state.firstName,
			lastName: self.state.lastName,
			url: window.location.host.split('/')[0],
			redirect: query.redirect,
			affiliateAccountUserId: self.state.affiliateId,
			newsletter: self.state.newsletterSignup
		}

		var baseUrl = DbConn.getConnString()

		axios.post(baseUrl + '/register', theData, { withCredentials: true })
			.then(function (response) {

				var respData = response.data;

				if (!respData.accountMasterId) {
					if (response.data.error.message === 'Email Address already exists') {
						this.setState({
							emailAddressError: 'Email Address already exists'
						})
						return

					} else if (response.data.error.message === 'Company name already exists') {
						this.setState({
							accountNameError: 'Company name already exists'
						})
						return;
					} else {
						self.setState({
							masterErrorMsg: response.data.error.message
						});
						return;
					}
				}
				if (respData.accountMasterId && self.props.siteMaster.siteId != 1) {
					self.setState({
						complete: true
					})
					return;
				}


				if (respData.accountMasterId) {
					try {
						if (self.props.siteMaster.siteId == 1) {
							if (self.state.mpztid) {
								let host = "transpond.io"
								let origin = "https://transpond.io"
								TranspondTracking.pageChange(self.state.emailAddress, null, 124555, self.state.mpztid, host, origin);
							}
							TranspondTracking.manualPageChange(null, null, self.state.emailAddress, null, null, "Registration", {
								accountName: self.state.companyName,
								accountMasterId: respData.accountMasterId
							})
							console.log("FIRE TAG EVENT START")
							GoogleAnalytics.tagManagerEvent("Register", respData.accountMasterId, respData.accountUserId, self.state.emailAddress)
						}
					}
					catch (err) {
						console.log(err);
					}

					self.setState({
						complete: true
					})
					return;
				}
			})
			.catch(error => {
				console.log(error);
				if (error.response.data.error === 'Email Address already exists') {
					this.setState({
						emailAddressError: 'Email Address already exists'
					})
					return

				} else if (error.response.data.error === 'Company name already exists') {
					this.setState({
						accountNameError: 'Company name already exists'
					})
					return;
				} else {
					self.setState({
						masterErrorMsg: error.response.data.error
					});
					return;
				}
			});
	}

	checkNewsletter(event) {
		console.log(event.currentTarget.checked)
		this.setState({ newsletterSignup: event.currentTarget.checked });
	}
	render() {
		var mainMD = 8;
		if (this.props.siteMaster.siteName != "MPZMail" && this.props.siteMaster.siteName != "Transpond") {
			mainMD = 12;
		}

		var site = <span>{this.props.siteMaster.siteName}</span>

		let requiresTerms = false;
		let termsTxt = [<span>I agree to the </span>];
		if ((this.props.siteMaster.termsUrl && this.props.siteMaster.termsUrl.length > 5) || (this.props.siteMaster.privacyUrl && this.props.siteMaster.privacyUrl.length > 5)) {
			requiresTerms = true;
			if (this.props.siteMaster.termsUrl && this.props.siteMaster.termsUrl.length > 5 && this.props.siteMaster.privacyUrl && this.props.siteMaster.privacyUrl.length > 5) {
				termsTxt.push(<span>
					<a href={this.props.siteMaster.termsUrl} target="_blank">Terms and Conditions of use</a>
					<span> and the </span>
					<a href={this.props.siteMaster.privacyUrl} target="_blank">Privacy Policy</a>
				</span>)
			} else if (this.props.siteMaster.termsUrl && this.props.siteMaster.termsUrl.length > 5) {
				termsTxt.push(<a href={this.props.siteMaster.termsUrl} target="_blank">Terms and Conditions of use</a>)
			} else if (this.props.siteMaster.privacyUrl && this.props.siteMaster.privacyUrl.length > 5) {
				termsTxt.push(<a href={this.props.siteMaster.privacyUrl} target="_blank">Privacy Policy</a>)
			}



		}
		return (<I18n ns="translations">
			{
				(t, { i18n }) => (<div className="login-holder">

					{(this.props.siteMaster.siteName === "MPZMail" || this.props.siteMaster.siteName === "Transpond") &&
						<LeftPanel history={this.props.history} registerPage={true} />
					}
					{this.state.complete && <RegisterComplete history={this.props.history} login={this.login} />}

					{!this.state.complete && <div className="login-right-panel">
						<div className="login-right-panel-login">
							<UILIB.Paper className="login-right-panel-login_loginBox">
								<h3 className="mar-b5" dangerouslySetInnerHTML={{ __html: t('login:register.create', { site: this.props.siteMaster.siteName }) }} />
								<div className="paper_subheading mar-b40">
									{t('login:register.subHeader')}
								</div>

								<UILIB.TextInput maxLength="255" className="mar-b24" type="text" name="companyName" label={t('login:register.company')} onChange={this.inputChange} onKeyPress={this.inputChange} focus={true} error={this.state.accountNameError} />

								<div className="row">
									<div className="col-xs-12 col-sm-6">
										<UILIB.TextInput maxLength="255" className="mar-b24" type="text" name="firstName" label={t('login:register.first')} onKeyPress={this.inputChange} onChange={this.inputChange} error={this.state.firstNameError} />
									</div>
									<div className="col-xs-12 col-sm-6">
										<UILIB.TextInput maxLength="255" className="mar-b24" type="text" name="lastName" label={t('login:register.last')} onKeyPress={this.inputChange} onChange={this.inputChange} error={this.state.lastNameError} />
									</div>
								</div>

								<UILIB.TextInput maxLength="255" className="mar-b24" type="email" name="emailAddress" label={t('login:login.emailPlaceholder')} onChange={this.inputChange} onKeyPress={this.inputChange} error={this.state.emailAddressError} />

								<UILIB.TextInput maxLength="255" className="mar-b24" type="password" name="password" label={t('login:login.passwordPlaceholder')} onChange={this.inputChange} onKeyPress={this.inputChange} error={this.state.masterErrorMsg} />

								{(requiresTerms || this.props.siteMaster.siteId == 1) && <hr />}

								{requiresTerms && <div className="checkboxWrapper mar-b25">
									<UILIB.CheckBox disabled={false} onClick={(event) => {
										let checked = event.target.checked;
										this.setState({ approvedTerms: checked }, this.checkInputs)
									}} checked={this.state.approvedTerms} style={{ borderColor: this.state.termsError ? "1px solid red" : "" }} className="mar-r5" />
									{termsTxt}
									{this.state.termsError && <UILIB.FormError key={"err"}>{this.state.termsError}</UILIB.FormError>}
								</div>}


								{this.props.siteMaster.siteId == 1 &&
									<div className="checkboxWrapper mar-b25">
										<UILIB.CheckBox disabled={false} onClick={this.checkNewsletter} checked={this.state.newsletterSignup} className="mar-b24 mar-r5" />
										Receive news and updates about Transpond
									</div>
								}
								{(requiresTerms || this.props.siteMaster.siteId == 1) && <hr />}

								<UILIB.Button className="button-primary mar-r20" text={t('login:register.button')} onClick={this.checkRegistration} />
								<a onClick={() => { this.redirectToPage('') }}>{t('login:register.haveAccount')}</a>

							</UILIB.Paper>
						</div>
					</div>}
				</div>)
			}
		</I18n>)
	}
};


import React from "react";
import classnames from "classnames";
import * as styles from "./index.module.scss";

export default function RadioButton({ value, className, children, ...rest }) {
  const compoundChildren = React.Children.map(children, (child) => {
    // Prevent the child button component from being tabbable as the label already is.
    return React.cloneElement(child, {
      tabIndex: -1,
    });
  });

  const classes = classnames(styles.root, className);

  return (
    <div className={classes}>
      <input
        type="radio"
        id={value}
        value={value}
        className="visually-hidden"
        {...rest}
      />

      <label htmlFor={value}>{compoundChildren}</label>
    </div>
  );
}

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import * as siteMaster from '~/data/actions/siteActions';
import PermissionChecker from '~/Classes/permissions';
import ExportDrawer from './exportDrawer';

//CONNECT TO STORE
@connect((store) => {
    return {
        user: store.user, site: store.siteMaster,
        permissionStore: store.permission
    }
})

export default class ViewCampaignTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            campaign: this.props.campaign,
            campaignId: this.props.campaignId,
            currentPath: '',
            editingName: false,
        }

        this.tabChange = this.tabChange.bind(this);
        this.setInitialTab = this.setInitialTab.bind(this);
        this.openExport = this.openExport.bind(this);
    }

    componentDidMount() {
        this.setInitialTab()
    }
    componentDidUpdate(prevProps) {
        var path = this.props.history.location.pathname.split("/")[5];
        if (path !== this.state.currentPath) {
            this.setInitialTab();
        } else {
            if (this.props.campaign.type === 10 && this.props.campaign.Campaigns) {
                if (prevProps.campaign.Campaigns.length !== this.props.campaign.Campaigns.length) {
                    this.setInitialTab()
                }
            }
        }
    }
    setInitialTab() {

        var theCurrPath = this.props.history.location.pathname.split("/");

        var theTab = 0;
        if (theCurrPath.length > 2) {
            switch (theCurrPath[5]) {
                case "analytics":
                    {
                        theTab = 1;
                        break;
                    }
                case "sent":
                    {
                        theTab = 2;
                        break;
                    }
                case "opened":
                    {
                        theTab = 3;
                        break;
                    }
                case "clicked":
                    {
                        theTab = 4;
                        break;
                    }
                case "bounced":
                    {
                        theTab = 5;
                        break;
                    }
                case "unsubscribed":
                    {
                        theTab = 6;
                        break;
                    }
                case "users":
                    {
                        theTab = 7;
                        break;
                    }
                case "tracking":
                    {
                        theTab = 8;
                        break;
                    }
                case "events":
                    {
                        theTab = 13;
                        break;
                    }
                case "replies":
                    {
                        theTab = 9;
                        break;
                    }
                case "tests":
                    {
                        theTab = 10;
                        break;
                    }
                case "polls":
                    {
                        theTab = 11;
                        break;
                    }
                case "resends":
                    {
                        theTab = 12;
                        break;
                    }
                case "exports":
                    {
                        theTab = 14;
                        break;
                    }
                default:
                    {
                        theTab = 0;
                    }
            }
        }
        if (theTab != this.state.theTab) {
            this.setState({ tabIndex: theTab, currentPath: theCurrPath[5] });
        }
    }
    tabChange(event, returnUrl) {
        var self = this;
        var index;
        if (event.currentTarget && event.currentTarget.id !== undefined) {
            index = event.currentTarget.id;
        }
        else if (event.currentTarget && event.currentTarget.value !== undefined) {
            index = event.currentTarget.value;
        }
        else {
            index = event;
        }
        index = String(index);


        var theURL = "";

        if (!returnUrl) self.setState({ tabIndex: index });
        switch (index) {
            case "0":
                theURL = '/cp/campaigns/view/' + this.state.campaignId;
                break;
            case "1":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/analytics';
                break;
            case "2":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/sent';
                break;
            case "3":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/opened';
                break;
            case "4":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/clicked';
                break;
            case "5":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/bounced';
                break;
            case "6":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/unsubscribed';
                break;
            case "7":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/users';
                break;
            case "8":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/tracking';
                break;
            case "9":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/replies';
                break;
            case "10":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/tests';
                break;
            case "11":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/polls';
                break;
            case "12":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/resends';
                break;
            case "13":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/events';
                break;
            case "14":
                theURL = '/cp/campaigns/view/' + this.state.campaignId + '/exports';
                break;
        }
        if (returnUrl) return theURL;
        this.props.history.push(theURL);
    }

    openExport() {
        var drawerContent = <ExportDrawer startDate={this.props.startDate} endDate={this.props.endDate} campaignId={this.props.campaignId} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "600px"));
    }

    saveCampaign() {
        const campaign = { ...this.state.campaign }
        return axios.put('/campaign/' + this.state.campaignId, { campaign }).catch(err => {
            var errorMessage = "There was a problem saving the campaign";
            if (err && err.data && err.data.error) errorMessage = err.data.error;
            this.setState({ alertOpen: true, alertMessage: errorMessage })
        })
    }

    saveCampaignName(event) {
        var campaign = this.state.campaign;
        campaign.campaignName = event.target.value;
        this.setState({ campaign });
    }

    saveCampaignNameOnly() {
        return axios.put('/campaign/' + this.state.campaignId, { campaign: { campaignName: this.state.campaign.campaignName } }).catch(err => {
            var errorMessage = "There was a problem saving the campaign";
            if (err && err.data && err.data.error) errorMessage = err.data.error;
            this.setState({ alertOpen: true, alertMessage: errorMessage })
        })
    }

    render() {
        var canViewSubscribers = PermissionChecker('subscribers', this.props.permissionStore.permissions, "read");

        var thisMenuOptions = [
            {
                label: i18n.t('campaigns:nav.overview'),
                value: 0,
                allowed: true
            },
            {
                label: i18n.t('campaigns:nav.tests'),
                value: 10,
                allowed: true
            },
            {
                label: i18n.t('campaigns:nav.analytics'),
                value: 1,
                allowed: true
            },
            {
                label: i18n.t('campaigns:nav.sent'),
                value: 2,
                allowed: canViewSubscribers
            },
            {
                label: i18n.t('campaigns:resend.nav'),
                value: 12,
                allowed: canViewSubscribers
            },
            {
                label: i18n.t('campaigns:nav.opened'),
                value: 3,
                allowed: canViewSubscribers
            },
            {
                label: i18n.t('campaigns:nav.clicked'),
                value: 4,
                allowed: canViewSubscribers
            },
            {
                label: i18n.t('campaigns:nav.bounced'),
                value: 5,
                allowed: canViewSubscribers
            },
            {
                label: i18n.t('campaigns:nav.unsubscribed'),
                value: 6,
                allowed: canViewSubscribers
            },
            {
                label: i18n.t('campaigns:nav.pollResults'),
                value: 11,
                allowed: canViewSubscribers
            },
            {
                label: i18n.t('campaigns:nav.tracking'),
                value: 8,
                allowed: canViewSubscribers
            },
            {
                label: 'Events',
                value: 13,
                allowed: canViewSubscribers
            },
            {
                label: i18n.t('campaigns:nav.replies'),
                value: 9,
                allowed: canViewSubscribers
            },
            {
                label: i18n.t('campaigns:nav.exports'),
                value: 14,
                allowed: canViewSubscribers
            },
            {
                label: i18n.t('campaigns:nav.users'),
                value: 7,
                allowed: true
            }
        ]

        if (this.state.campaign.type !== 10 || this.state.campaign.parentCampaignId) {
            let foundIndex = thisMenuOptions.findIndex(f => f.value == 10)
            thisMenuOptions.splice(foundIndex, 1);

        } else {
            if (!this.state.campaign.Campaigns.find(c => c.type === 11)) {
                thisMenuOptions = [{
                    label: i18n.t('campaigns:nav.tests'),
                    value: 10
                }]
            }
        }

        if (!this.state.campaign.polls || !this.state.campaign.polls.length) {
            let foundIndex = thisMenuOptions.findIndex(f => f.value == 11)
            thisMenuOptions.splice(foundIndex, 1);
        }

        if (this.state.campaign.type === 6) {
            // hide opens and replies
            let foundIndex = thisMenuOptions.findIndex(f => f.value == 3)
            thisMenuOptions.splice(foundIndex, 1);
            foundIndex = thisMenuOptions.findIndex(f => f.value == 9)
            thisMenuOptions.splice(foundIndex, 1);
        }


        if (this.state.campaign.type === 6) {
            // remove the analyics tab for sms campaigns
            let foundIndex = thisMenuOptions.findIndex(f => f.value == 1)
            thisMenuOptions.splice(foundIndex, 1);
        }

        if (!this.state.campaign.options || !this.state.campaign.options.resent) {
            let foundIndex = thisMenuOptions.findIndex(f => f.value == 12)
            thisMenuOptions.splice(foundIndex, 1);
        }

        var header = this.state.campaign.campaignName
        if (this.state.campaign.Automation) {
            header = this.state.campaign.Automation.name + ' - ' + this.state.campaign.subject
        }
        thisMenuOptions = thisMenuOptions.filter(f => f.allowed)

        if (this.state.campaign.type == 10) {
            header = <div className="quickFlex">
                <div>{header}</div>
                <UILIB.SquareChip value="A/B Split Test" className="xsmallChip square-chip-primary mar-l10 mar-b0" />

            </div>
        }
        return <UILIB.Paper>

            <UILIB.Row className="mar-b25">
                <UILIB.Column xs={12} className="mar-b30" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        {this.state.editingName ? (
                            <div className="mar-b25 quickFlex">
                                <UILIB.TextInput
                                    outerStyle={{ flex: "1" }}
                                    autoFocus={true}
                                    value={this.state.campaign.campaignName}
                                    name="campaignName"
                                    onChange={(e) => this.saveCampaignName(e)}
                                />
                                <UILIB.Icons
                                    style={{ flex: "0", width: "20px", height: "20px" }}
                                    icon="tick"
                                    onClick={() =>
                                        this.setState({ editingName: false }, this.saveCampaignNameOnly)
                                    }
                                    className="mar-l10"
                                />
                            </div>
                        ) : (
                            <>
                                <div className="quickFlex mar-b5" style={{ alignItems: "baseline", }}>
                                    <UILIB.Hint
                                        className="mar-l0 mar-b25"
                                        iconLeft={<UILIB.Icons icon="arrowLeft" />}
                                        onClick={() => { this.props.history.push('/cp/campaigns') }}>
                                        {i18n.t('Back')}
                                    </UILIB.Hint>
                                    <h1 className="page-header mar-r10">
                                        {header}
                                    </h1>
                                    <UILIB.Icons
                                        style={{ width: "20px", height: "20px" }}
                                        icon="pencil"
                                        onClick={() => this.setState({ editingName: true })}
                                    />
                                </div>
                                <div className="fw-medium"><span style={{ color: '#999' }}>Campaign ID:</span> <strong>{this.state.campaign.id} </strong>
                                    <span><UILIB.SquareChip className="square-chip-green mar-l10">{this.state.campaign.status}</UILIB.SquareChip></span>
                                </div>
                            </>
                        )}
                    </div>
                    {this.props.canExport && this.state.tabIndex !== 14 && <div>
                        <UILIB.Button className='button-sml' style={{ borderRadius: 10 }} text="Reports" onClick={() => window.open('/cp/campaigns/reports', '_blank')} />
                    </div>}
                </UILIB.Column>

                <UILIB.Column xs={12} hide={["md", "lg"]}>
                    <UILIB.Select data={thisMenuOptions} onChange={this.tabChange} value={this.state.tabIndex} />
                </UILIB.Column>
                <UILIB.Column xs={12} hide={["xs", "sm"]} className="sub-nav-holder">
                    {thisMenuOptions.map((option, index) => {
                        var label = option.label;
                        if (option.value == 11 && this.state.campaign.pollAnswers) {
                            label = <span>
                                {label}
                                <span style={{ marginLeft: "5px", color: "white", textTransform: "uppercase", borderRadius: "100px", backgroundColor: "green", padding: "1px 7px", fontSize: "9px" }}>
                                    {this.state.campaign.pollAnswers}
                                </span>
                            </span>;
                        }
                        return <UILIB.ButtonSimple key={"tab_" + index} to={this.tabChange(option.value, true)} className="button-simple-fullsize sub-nav-button" selected={this.state.tabIndex == option.value}>{label}</UILIB.ButtonSimple>
                    })}
                </UILIB.Column>

                <UILIB.Column xs={12} className="mar-t30">
                    {this.props.children}
                </UILIB.Column>

            </UILIB.Row>

        </UILIB.Paper >
    }
}
import React, { useState, useEffect } from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';

export default function TwitterConnect({ }) {
    const [error, setError] = useState(null)

    useEffect(() => {
        axios.get('/application/twitter/authlink').then(res => {
            if (res.data.url) window.location = res.data.url
            else {
                setError('Something went wrong. Please try again later.')
            }
        }).catch(e => {
            console.log(e)
            setError('Something went wrong. Please try again later.')
        })
    }, [])

    return (
        <div>
            {!error && <UILIB.LoadingIcons />}
            {!!error && <UILIB.WarningHolder>{error}</UILIB.WarningHolder>}
        </div>
    )
}
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UILIB from '../../../Common-Objects/Lib'
import axios from '../../../data/http/axios'
import helpers from '../../../Classes/helpers'
import { alterSiteDrawer } from '../../../data/actions/siteActions'

export default function SiteTrackingAutomate({ TrackerId, trackerName }) {
    const site = useSelector(state => state.siteMaster)
    const history = useHistory()
    const dispatch = useDispatch()
    const [trigger, setTrigger] = useState('')
    const [visits, setVisits] = useState(0)
    const [name, setName] = useState(trackerName + ' Automation')
    const [pages, setPages] = useState(null)

    const create = async () => {
        const res = await axios.post('/automation', {
            name,
            version: 1,
            status: 'Draft',
            steps: [{
                "guid": helpers.createLocalGuid(),
                "type": "initial",
                "complete": true,
                "parentGuid": 0,
                "stepOptions": {
                    "url": {
                        "value": trigger,
                        "required": true
                    },
                    "visits": {
                        "value": visits,
                        "required": true
                    },
                    "groupId": {
                        "value": "-1",
                        "required": true
                    },
                    "trackingId": {
                        "value": TrackerId,
                        "required": true
                    }
                },
                "triggerType": {
                    "value": "7",
                    "required": true
                },
                "wizardComplete": true
            }]
        })
        history.push('/cp/automation/addnew/' + res.data.Automation.id + '?redirect=/cp/trackers/' + TrackerId)
        dispatch(alterSiteDrawer(false, true, 'right', null))
    }

    useEffect(() => {
        axios.get(`/accountMaster/tracker/${TrackerId}/path`).then(res => {
            setPages(res.data.TrackerPaths)
        })
    }, [TrackerId])

    if (!pages) return <UILIB.LoadingIcons />

    return (
        <div>
            <div className="quickFlex mar-b10" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <h4>Create an new Automation</h4>
                {site.siteId === 1 && <UILIB.Hint href="https://kb.transpond.io/article/61-what-is-automation" iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>Learn More</UILIB.Hint>}
            </div>

            <p className='mar-b25 text-sml'>
                You can start a new automation based on when people visit your website. <br /><br />Then perform lots of actions such as automatically sending the contact to a chain of emails or changing their status.
            </p>

            <UILIB.Select outerClassName="mar-b25" label="Which page on your website should trigger this automation?" filter={true} data={[{ label: 'Any Page', value: '' }, ...pages.map(p => ({ label: p.path, value: p.path }))]} value={trigger} onChange={e => setTrigger(e.target.value)} />
            <UILIB.TextInput outerClassName="mar-b25"  value={visits} label="Only when the have visited x times (0 for their first visit)" type="number" onChange={e => setVisits(e.target.value)} />

            <UILIB.TextInput label="Give this new Automation a name" value={name} onChange={e => setName(e.target.value)} outerClassName="mar-b25" />

            {!!name && <>
                <UILIB.WarningHolder className="mar-b25">This will create the automation and take you to the automation designer so you can setup any actions you want to happen when this automation is triggered</UILIB.WarningHolder>
                <UILIB.Button className="button-primary" onClick={create}>Create Automation</UILIB.Button>
            </>}
        </div>
    )
}
import React from 'react'
import moment from 'moment'
import more from './images/twitter/twitter_more.svg'
import actions from './images/twitter/twitter_actions.svg'

export default function SocialTwitterPreview({ account, startDate, content, images = [] }) {
    const now = moment(startDate)

    const hashtagRegex = /#(\w+)/g;
    content = content.replace(hashtagRegex, `<span style="color: #1D9BF0;">#$1</span>`)

    const linkRegex = /(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?/gi;
    for (const link of content.split('<br />').join(' <br />').match(linkRegex) || []) {
        let str = link
        if (str.length > 23) str = str.substring(0, 23) + '...';
        content = content.replace(link, `<span style="color: #1D9BF0;">${str}</span>`)
    }
    return (
        <div style={{ width: 480, backgroundColor: 'white', fontFamily: 'inter', padding: '12px 16px 0 16px', borderRadius: 8 }}>
            <div style={{ display: 'flex' }}>
                <img src={account.image} style={{ borderRadius: '50%', height: 40, width: 40, marginRight: 12 }} />
                <div style={{ flexGrow: 1, display: 'block' }}>
                    {/* header */}
                    <div style={{ display: 'flex', fontSize: '15px' }}>
                        <div style={{ flexGrow: 1, display: 'flex' }}>
                            <div style={{ marginRight: 4, color: '#0F1419', fontWeight: 700, lineHeight: '20px' }}>{account.name}</div>
                            <div style={{ marginRight: 4, color: '#536471', fontWeight: 400, lineHeight: '20px' }}>@{account.username}</div>
                            <div style={{ marginRight: 4, color: '#536471', fontWeight: 400, lineHeight: '20px' }}>.</div>
                            <div style={{ marginRight: 4, color: '#536471', fontWeight: 400, lineHeight: '20px' }}>{now.format('MMM DD')}</div>
                        </div>
                        <img src={more} />
                    </div>

                    {/* content */}
                    <div style={{ color: '#050505', fontSize: '15px', fontWeight: 400, lineHeight: '20px', marginBottom: 8, wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: content }}></div>

                    {/* image */}
                    {!!images.length && <img src={images[0].url || images[0]} style={{
                        backgroundColor: '#e0e0e0',
                        width: '100%',
                        position: 'relative',
                        justifyContent: 'center',
                        borderRadius: 16,
                        border: '1px solid #E1E8ED'
                    }} />}

                    {/* footer */}
                    <img src={actions} />
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UILIB from '../../../../../../Common-Objects/Lib'
import axios from '../../../../../../data/http/axios'
import EmailValidator from '../../../../../../Classes/emailValidator'
import SyncReport from './syncReport'
import GoogleAnalytics from '~/Classes/googleAnalytics';

async function createDefaultGroup(user, company, basic) {
    const obj = {
        groupName: 'Capsule',
        friendlyName: 'Contacts',
        defaultFromName: company,
        signupReminderText: 'Added from Capsule CRM'
    }
    const domain = user.emailAddress.split('@')[1]
    const checker = await axios.get('/accountMaster/domain/checker?domain=' + domain)
    if (checker.data.free || checker.data.disposable) {
        obj.defaultFromEmailAddress = user.emailAddress
    } else {
        obj.defaultFromEmailAddress = 'noreply@' + domain
    }
    if (basic) return obj
    const res = await axios.post('/group', obj)
    return res.data.group
}

export default function SetupCapsule({ onFinish = () => { }, inline = false }) {
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const [showGroupOptions, setShowGroupOptions] = useState(null)
    const [showPasswordOptions, setShowPasswordOptions] = useState(true)

    const [showImportReport, setShowImportReport] = useState(false)

    const [app, setApp] = useState(null)
    const [site, setSite] = useState(null)
    const [me, setMe] = useState(null)
    const [status, setStatus] = useState({})

    const [groups, setGroups] = useState(null)
    const [group, setGroup] = useState(null)
    const [newGroup, setNewGroup] = useState({})
    const [groupError, setGroupError] = useState({ groupName: '', friendlyName: '', defaultFromName: '', defaultFromEmailAddress: '', signupReminderText: '' })

    const [syncing, setSyncing] = useState({ tags: 0, lists: 0, fields: 0, contacts: 0 })
    const [importJob, setImportJob] = useState(null)
    const [progress, setProgress] = useState(0)

    const importTimer = useRef(null)
    const dispatch = useDispatch()

    const account = useSelector(state => state.accountMaster)
    const user = useSelector(state => state.user)

    const [newsletterChecked, setNewsletterChecked] = useState(false);

    const savePassword = async () => {
        let cont = false
        setPasswordError('')
        if (newsletterChecked) {
            try {
                let nFirstName = user ? user.userData.firstName : "";
                let nEmail = user ? user.userData.emailAddress : "";
                axios.post('/accountUser/newslettersignup', { firstName: nFirstName, emailAddress: nEmail })
            }
            catch (err) { }
        }
        try {
            await axios.put('/accountUser', { password, skipLogout: true })
            setShowPasswordOptions(false)
            cont = true
        } catch (e) {
            setPasswordError(e.data.error)
        }
        return cont
    }

    const savePasswordAndGroup = async () => {
        let cont = true
        if (showPasswordOptions) {
            cont = await savePassword()
        }
        if (cont && showGroupOptions) {
            await saveGroup()
        }
        GoogleAnalytics.tagManagerEvent("Capsule_Register_PasswordConfirm", account.id, user.accountUserId, user?.userData?.emailAddress)
    }

    const saveGroup = async () => {
        try {
            setGroupError({ groupName: '', friendlyName: '', defaultFromName: '', defaultFromEmailAddress: '', signupReminderText: '' })
            const err = {}
            if (!newGroup.groupName || newGroup.groupName.length < 3) err.groupName = 'Invalid Group Name'
            if (!newGroup.friendlyName || newGroup.friendlyName.length < 3) err.friendlyName = 'Invalid Friendly Name'
            if (!newGroup.defaultFromName || newGroup.defaultFromName.length < 3) err.defaultFromName = 'Invalid From Name'
            if (!newGroup.defaultFromEmailAddress || newGroup.defaultFromEmailAddress.length < 3 || !EmailValidator.validateEmail(newGroup.defaultFromEmailAddress)) {
                err.defaultFromEmailAddress = 'Invalid From Address'
            }
            if (!newGroup.signupReminderText || newGroup.signupReminderText.length < 3) err.signupReminderText = 'Invalid Reminder Text'

            setGroupError(err)
            if (Object.keys(err).length) return

            const res = await axios.post('/group', newGroup)
            setGroup(res.data.group.id)
            await axios.put("/application/account/" + app.id, {
                appSettings: { ...app.appSettings, groupId: res.data.group.id }
            })
            setShowGroupOptions(false)
        } catch (e) {
            if (e.data.error.existingGroupId) {
                await axios.put("/application/account/" + app.id, {
                    appSettings: { ...app.appSettings, groupId: e.data.error.existingGroupId }
                })
                setShowGroupOptions(false)
            } else {
                setGroupError(e => ({ ...e, groupName: e.data.error }))
            }
        }
    }

    const getStatus = async () => {
        const res = await axios.get('/application/capsule/status')
        setStatus(res.data)
    }

    const startSync = async (force) => {
        try {
            const res = await axios.get('/group/' + group + '/import/inprogress')
            if (res.data && !force) {
                setProgress(res.data.importStatus >= 20 ? 100 : 20)
                setImportJob(res.data)
                setSyncing({ tags: 2, lists: 2, fields: 2, contacts: res.data.importStatus >= 20 ? 2 : 1 })
                await getStatus()
            } else {
                setSyncing({ tags: 0, lists: 0, fields: 0, contacts: 0 })
                setImportJob(null)
                setSyncing(s => ({ ...s, tags: 1 }))
                const tagsSync = await axios.get('/application/capsule/synctags')
                setStatus(s => ({ ...s, tags: tagsSync.data.total }))
                setSyncing(s => ({ ...s, tags: 2 }))
                setProgress(5)

                setSyncing(s => ({ ...s, fields: 1 }))
                const fieldsSync = await axios.get('/application/capsule/syncfields')
                setStatus(s => ({ ...s, fields: fieldsSync.data.total }))
                setSyncing(s => ({ ...s, fields: 2 }))
                setProgress(10)

                setSyncing(s => ({ ...s, lists: 1 }))
                const listsSync = await axios.get('/application/capsule/synclists')
                setStatus(s => ({ ...s, segments: listsSync.data.total }))
                setSyncing(s => ({ ...s, lists: 2 }))
                setProgress(15)

                setSyncing(s => ({ ...s, contacts: 1 }))
                const contactsSync = await axios.get('/application/capsule/synccontacts')
                setProgress(20)
                setImportJob(contactsSync.data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getJob = async () => {
        const res = await axios.get('/group/' + group + '/import/' + importJob.id)
        setImportJob(res.data.ImportJob)
        const percentComplete = res.data.ImportJob.percentComplete
        setProgress(Math.ceil(20 + (percentComplete * 0.8)))
        if (res.data.ImportJob.importStatus >= 20) {
            setSyncing(s => ({ ...s, contacts: 2 }))
            setProgress(100)
            getStatus()
        }
    }

    useEffect(() => {
        if (showGroupOptions === false && showPasswordOptions === false) {
            startSync()
        }
    }, [showGroupOptions, showPasswordOptions])

    useEffect(() => {
        if (importJob && importJob.importStatus < 20) {
            importTimer.current = setTimeout(getJob, 2000)
        }
    }, [importJob])

    useEffect(() => {
        (async () => {
            try {
                const res2 = await axios.get('/application/capsule/site')
                const res3 = await axios.get('/application/account/app/8')

                setSite(res2.data)
                setApp(res3.data.Application)

                const userRes = await axios.get('/accountUser/me')
                setMe(userRes.data.AccountUserData)
                if (!userRes.data.AccountUserData.resetting) {
                    setShowPasswordOptions(false)
                } else {
                    setShowPasswordOptions(true)
                }

                const res = await axios.get('/group')
                setGroups(res.data.Groups)
                if (res.data.Groups.length && !res3.data.Application.appSettings.groupId) {
                    const foundGroup = res.data.Groups.find(g => g.groupName === 'Capsule')
                    if (foundGroup) {
                        setGroup(foundGroup.id)
                        await axios.put("/application/account/" + res3.data.Application.id, {
                            appSettings: { ...res3.data.Application.appSettings, groupId: foundGroup.id }
                        })
                        setShowGroupOptions(false)
                    } else {
                        setNewGroup(await createDefaultGroup(userRes.data.AccountUserData, account.accountMaster.accountName, true))
                        setShowGroupOptions(true)
                    }
                } else if (!res3.data.Application.appSettings.groupId) {
                    // create a default group
                    try {
                        const group = await createDefaultGroup(userRes.data.AccountUserData, account.accountMaster.accountName)
                        setGroups(g => [...g, group])
                        setGroup(group.id)
                        await axios.put("/application/account/" + res3.data.Application.id, {
                            appSettings: { ...res3.data.Application.appSettings, groupId: group.id }
                        })
                        setShowGroupOptions(false)
                    } catch (e) {
                        // its probably a disosable domain so get them to create a group manually
                        setNewGroup(await createDefaultGroup(userRes.data.AccountUserData, account.accountMaster.accountName, true))
                        setShowGroupOptions(true)
                    }
                }

                if (res3.data && res3.data.Application.appSettings && res3.data.Application.appSettings.groupId) {
                    setGroup(res3.data.Application.appSettings.groupId)
                    setShowGroupOptions(false)
                    getStatus()
                }
            } catch (e) {
                console.log(e)
            }
        })()

        return function () {
            if (importTimer.current) {
                clearTimeout(importTimer.current)
            }
        }
    }, [])

    if (!groups || !app) return <div className='mar-t40'><UILIB.LoadingIcons iconType="2" /></div>

    const totalProcessed = importJob ? importJob.importStatus === 20 ? importJob.recordsTotal : importJob.recordsInvalid + importJob.recordsExist + importJob.newSubscribersImported + importJob.recordsDupes : 0
    const totalValid = importJob ? importJob.recordsExist + importJob.newSubscribersImported : 0
    const importStatus = importJob ? importJob.importStatus < 4 ? 1 : 2 : 0

    const hasErrors = importJob && !!(importJob.recordsInvalid || importJob.recordsDupes)
    return (
        <>
            {!inline && <nav className='cp-header mar-b40'>
                <div className="cp-header-start">
                    <div className="cp-header-start-company-switcher no-click" onClick={() => { }}>
                        <UILIB.Avatar style={{ height: 'auto', marginRight: '15px' }} src={account.accountMaster.companyLogo}></UILIB.Avatar>
                        <div className="no-marg text-heavy cp-header-start-company-switcher_company-name">{account.accountMaster.accountName}</div>
                    </div>
                </div>
            </nav>}
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-xs-12 col-md-8 col-md-offset-2">
                        <UILIB.Paper style={{ padding: "52px" }}>
                            <div className="quickFlex mar-b20">
                                <img src={'https://cdn1.ourmailsender.com/siteContent/capsule/logomark_light-1.png'} height="40" style={{ borderRadius: 4 }} />
                                <UILIB.Icons icon="plus" color="#666" className="pad-left pad-right" style={{ height: "20px" }} />
                                <img src={'https://cdn1.ourmailsender.com/siteContent/transpondLogos/logomark_dark.png'} height="40" style={{ borderRadius: 4 }} />
                            </div>
                            {(showPasswordOptions || showGroupOptions) && <>
                                <div className="mar-b10 page-header">Welcome to Transpond. Finish your account setup</div>
                                <div style={{ fontSize: 14 }} className="mar-b25">You're almost there, we just need a few more details to setup your new Transpond account and begin importing your Capsule contacts</div>

                                {showPasswordOptions && <UILIB.Paper>
                                    <h4 className="mar-b25">Create your Transpond account password</h4>
                                    <UILIB.TextInput
                                        outerClassName="mar-b25"
                                        label="Password"
                                        type="password"
                                        value={password}
                                        onChange={e => setPassword(e.currentTarget.value)}
                                        error={passwordError}
                                        explainer={!passwordError ? <div className="explainer">Minimum of 10 characters and must include 1 number or 1 capital letter</div> : ""}
                                    />

                                    <hr />
                                    <UILIB.CheckBox
                                        value="1"
                                        checked={newsletterChecked}
                                        onClick={() => {
                                            if (newsletterChecked == false) {
                                                setNewsletterChecked(true)
                                            }
                                            else {
                                                setNewsletterChecked(false);
                                            }
                                        }}
                                    >
                                        Receive the latest Transpond news and updates
                                    </UILIB.CheckBox>

                                </UILIB.Paper>}

                                {showGroupOptions && <UILIB.Paper>
                                    <h4 className="mar-b10">Create group for your Capsule contacts</h4>
                                    <div className="mar-b25">We've used some of your Capsule details already. You can edit these below.</div>

                                    <UILIB.TextInput label="Group Name" outerClassName="mar-b25" value={newGroup.groupName} error={groupError.groupName} onChange={e => setNewGroup({ ...newGroup, groupName: e.currentTarget.value })} />
                                    <UILIB.TextInput label="Friendly Group Name" outerClassName="mar-b25" value={newGroup.friendlyName} error={groupError.friendlyName} onChange={e => setNewGroup({ ...newGroup, friendlyName: e.currentTarget.value })} />
                                    <UILIB.TextInput outerClassName="mar-b25" label="From Address Name" value={newGroup.defaultFromName} error={groupError.defaultFromName} onChange={e => setNewGroup({ ...newGroup, defaultFromName: e.currentTarget.value })} />
                                    <UILIB.TextInput outerClassName="mar-b25" label="From Email Address" type="email" value={newGroup.defaultFromEmailAddress} error={groupError.defaultFromEmailAddress} onChange={e => setNewGroup({ ...newGroup, defaultFromEmailAddress: e.currentTarget.value })} />
                                    <UILIB.TextArea outerClassName="mar-b25" label="Reminder Text" value={newGroup.signupReminderText} error={groupError.signupReminderText} onChange={e => setNewGroup({ ...newGroup, signupReminderText: e.currentTarget.value })} />

                                </UILIB.Paper>}

                                <UILIB.Button className="button-primary button-md" text="Complete your account setup and import data" onClick={savePasswordAndGroup} />
                            </>}

                            {!showPasswordOptions && !showGroupOptions && !showImportReport && <>
                                <div className="mar-b10 page-header">We are syncing your Capsule data</div>
                                <div style={{ fontSize: 14, marginBottom: 52 }}>This will only take a minute. Once completed you will be able to continue into your Transpond account</div>

                                <div className='mar-t40'>
                                    <div className={"row middle-xs"}>
                                        <div className="col-xs-12 col-md-7">
                                            <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 10 }}>Syncing tags</div>
                                            <p className={syncing.fields === 0 ? 'mar-b30 text-grey' : 'mar-b30'}>Your Capsule tags will be copied and applied to your Transpond tags. Changes to tags in Transpond or Capsule will be synced automatically.</p>
                                        </div>
                                        <div className="col-xs-12 col-md-3 col-md-offset-2">
                                            <div className="mar-b20">
                                                {syncing.tags === 0 && <UILIB.SmallLineGraph color={'#7246B1'} className="no-marg" height={4} width="100%" val2={100} val1={0} hideText={true} />}
                                                {syncing.tags === 1 && <UILIB.LoadingLine outerStyle={{ marginTop: '4px !important' }} color={'#7246B1'} className="no-marg" height={4} />}
                                                {syncing.tags === 2 && <UILIB.SmallLineGraph color={'#7246B1'} height={4} width="100%" val2={100} val1={100} overrideText={<div className='text-sml end-xs text-heavy'>Total: {status.tags}</div>} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="divider2 light no-marg mar-b30"></div>

                                    <div className={"row middle-xs"}>
                                        <div className="col-xs-12 col-md-7">
                                            <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 10 }}>Syncing custom fields</div>
                                            <p className={syncing.fields === 0 ? 'mar-b30 text-grey' : 'mar-b30'}>Your Capsule custom fields will be added as 'Contact Data' on Transpond contacts. Changes to these in either Transpond or Capsule will be synced automatically.</p>
                                        </div>
                                        <div className="col-xs-12 col-md-3 col-md-offset-2">
                                            <div className="mar-b20">
                                                {syncing.fields === 0 && <UILIB.SmallLineGraph color={'#312679'} className="no-marg" height={4} width="100%" val2={100} val1={0} hideText={true} />}
                                                {syncing.fields === 1 && <UILIB.LoadingLine outerStyle={{ marginTop: '4px !important' }} color={'#312679'} className="no-marg" height={4} />}
                                                {syncing.fields === 2 && <UILIB.SmallLineGraph color={'#312679'} height={4} width="100%" val2={100} val1={100} overrideText={<div className='text-sml end-xs text-heavy'>Total: {status.fields}</div>} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="divider2 light no-marg mar-b30"></div>

                                    <div className={"row middle-xs"}>
                                        <div className="col-xs-12 col-md-7">
                                            <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 10 }}>Syncing lists</div>
                                            <p className={syncing.fields === 0 ? 'mar-b30 text-grey' : 'mar-b30'}>Your lists appear as 'Segments' in Transpond. Changes to either lists or segments in Capsule or Transpond will be synced automatically.</p>
                                        </div>
                                        <div className="col-xs-12 col-md-3 col-md-offset-2">
                                            <div className="mar-b20">
                                                {syncing.lists === 0 && <UILIB.SmallLineGraph color={'#65B5B1'} className="no-marg" height={4} width="100%" val2={100} val1={0} hideText={true} />}
                                                {syncing.lists === 1 && <UILIB.LoadingLine outerStyle={{ marginTop: '4px !important' }} color={'#65B5B1'} className="no-marg" height={4} />}
                                                {syncing.lists === 2 && <UILIB.SmallLineGraph color={'#65B5B1'} height={4} width="100%" val2={100} val1={100} overrideText={<div className='text-sml end-xs text-heavy'>Total: {status.segments}</div>} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="divider2 light no-marg mar-b30"></div>


                                    <div className={"row middle-xs"}>
                                        <div className="col-xs-12 col-md-7">
                                            <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 10 }}>Syncing contacts</div>
                                            <p className={syncing.fields === 0 ? 'mar-b30 text-grey' : 'mar-b30'}>Each organisation and person contact in Capsule will be imported into Transpond and updated automatically.</p>
                                        </div>
                                        <div className="col-xs-12 col-md-3 col-md-offset-2">
                                            <div className="mar-b20">
                                                {importStatus === 0 && <UILIB.SmallLineGraph color={'#FF9425'} className="no-marg" height={4} width="100%" val2={100} val1={0} hideText={true} />}
                                                {importStatus === 1 && <UILIB.LoadingLine outerStyle={{ marginTop: '4px !important' }} color={'#FF9425'} className="no-marg" height={4} />}
                                                {syncing.contacts === 1 && importStatus === 2 && !!importJob && <UILIB.SmallLineGraph color={'#FF9425'} height={4} width="100%" val2={importJob.recordsTotal} val1={totalProcessed} overrideText={<div className='text-sml end-xs text-heavy loading mar-r10'>Syncing Contacts</div>} />}
                                                {syncing.contacts === 2 && <UILIB.SmallLineGraph color={'#FF9425'} height={4} width="100%" val2={importJob.recordsTotal} val1={totalProcessed} overrideText={<div className='text-sml end-xs text-heavy'>We've found {totalValid} valid email addresses</div>} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="divider2 light no-marg mar-b30"></div>

                                    {/* {hasErrors && <div style={{ width: '100%', backgroundColor: '#FF9425', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '18px 24px', marginBottom: 30 }}>
                                        <div className='quickFlex'>
                                            <UILIB.Icons icon="warning" color="white" className="mar-r10" />
                                            <div className='text-white text-heavy'>There was a problem syncing {importJob.recordsInvalid + importJob.recordsDupes} of your Capsule contacts</div>
                                        </div>
                                        <UILIB.ButtonSimple iconRight={<UILIB.Icons icon="arrowRight" color="white" />} onClick={() => setShowImportReport(true)} className="button-simple-lightorange button-simple-fullsize">View report</UILIB.ButtonSimple>
                                    </div>} */}

                                    <div className="end-xs">
                                        <UILIB.Button className="button-md button-primary" text="Go to my Transpond Account" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => onFinish(importJob)} />
                                        {/* <UILIB.Button className="button-md" text="Resync" onClick={startSync} /> */}
                                    </div>
                                </div>
                            </>}

                            {!showPasswordOptions && !showGroupOptions && showImportReport && <>
                                <SyncReport importJob={importJob} site={site} goBack={() => setShowImportReport(false)} />
                            </>}
                        </UILIB.Paper>
                    </div>
                </div>
            </div>
        </ >
    )
}

import React from 'react'

export default class IconsLightning extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.3 1.04608C11.503 1.10992 11.6803 1.23686 11.8062 1.40843C11.9321 1.58001 12 1.78727 12 2.00008V7.00008H16C16.1829 7 16.3623 7.05009 16.5188 7.1449C16.6752 7.23972 16.8026 7.37562 16.8872 7.53782C16.9717 7.70002 17.0102 7.88231 16.9983 8.06484C16.9865 8.24738 16.9248 8.42317 16.82 8.57308L9.81999 18.5731C9.69816 18.7476 9.52382 18.8787 9.32233 18.9473C9.12084 19.0159 8.90271 19.0185 8.69968 18.9546C8.49666 18.8906 8.31932 18.7636 8.19348 18.5919C8.06764 18.4203 7.99985 18.2129 7.99999 18.0001V13.0001H3.99999C3.81707 13.0002 3.63763 12.9501 3.4812 12.8553C3.32478 12.7604 3.19735 12.6245 3.1128 12.4623C3.02824 12.3001 2.9898 12.1178 3.00164 11.9353C3.01349 11.7528 3.07518 11.577 3.17999 11.4271L10.18 1.42708C10.302 1.25286 10.4764 1.12209 10.6778 1.05375C10.8792 0.985417 11.0972 0.983079 11.3 1.04708V1.04608Z" fill={color} />
        </svg>
    }
}        
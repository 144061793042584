import React from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';

export default class RequiresSiteTracking extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <UILIB.Row>
            <UILIB.Column xs={12} sm={8} className="col-sm-offset-2">
                <UILIB.Paper>
                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={6}>
                            <h4 className="mar-b25">Discover more about your Campaign Performance</h4>
                            <div className="text-heavy mar-b25">Events enable you to track when customers go on to Register or Pay for your service or products.</div>
                            <div className="mar-b25">You can then see exactly how successful your campaigns have been at converting subscribers into customers.</div>
                            <div className="mar-b25">Enabling events is easy, but does require the Site Tracking feature to be setup.</div>
                            <div className="quickFlex" style={{ alignItems: "center" }}>
                                <UILIB.Button className="mar-r10 mar-b0" text="Get Started" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => { this.props.history.push('/cp/trackers') }} />
                                <UILIB.Button className="mar-b0" text="Find out more about Events" iconLeft={<UILIB.Icons icon="questionCircle" />} onClick={() => { this.props.history.push('/cp/trackers/events') }} />
                            </div>
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6}>
                            <img
                                src="https://cdn1.ourmailsender.com/siteContent/siteTracking/siteTrackingScreenshot.png"
                                style={{ width: "100%", border: "1px solid #CCCCCC", borderRadius: "10px", overflow: "hidden" }} />
                        </UILIB.Column>
                    </UILIB.Row>
                </UILIB.Paper >
            </UILIB.Column >
        </UILIB.Row >
    }
}
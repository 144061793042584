import React from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import textFunctions from '~/Classes/textFunctions';
export default class EditNotification extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            path: {},
            emailError: "",
            loaded: false
        }
        this.changeSendNotifications = this.changeSendNotifications.bind(this);
        this.changeNotificationType = this.changeNotificationType.bind(this);
        this.changeNotificationsToEmailAddress = this.changeNotificationsToEmailAddress.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentDidMount() {
        this.setState({ path: this.props.path, loaded: true })
    }
    changeSendNotifications(event) {
        var isTracked = event.target.checked;
        var path = this.state.path;
        path.sendNotifications = isTracked;
        this.setState({ path })
    }

    changeNotificationType(event) {
        var path = this.state.path;
        path.sendNotificationsToType = event.target.value;
        this.setState({ path })
    }
    changeNotificationsToEmailAddress(event) {
        var path = this.state.path;
        path.sendNotificationsToEmailAddress = event.target.value;
        this.setState({ path });
    }

    saveChanges() {
        this.setState({ emailError: "" })
        if (this.state.path.sendNotifications) {
            if (this.state.path.sendNotificationsToType == 0) {
                var tmpEmail = this.state.path.sendNotificationsToEmailAddress;
                if (!tmpEmail || tmpEmail.length < 5 || tmpEmail.indexOf("@") < 0 || tmpEmail.indexOf(".") < 0) {
                    this.setState({ emailError: i18n.t('campaigns:sitetracking.trackingpages.invalidEmail') })
                    return;
                }
            }
        }
        this.props.updatePath(this.state.path, true)
    }

    render() {

        if (!this.state.loaded) return <div></div>
        var whereNotifications = [{
            label: i18n.t('campaigns:sitetracking.trackingpages.theFollowingEmail'),
            value: 0
        }, {
            label: i18n.t('campaigns:sitetracking.trackingpages.theSenderEmailText'),
            value: 1
        }]

        var introText = i18n.t('campaigns:sitetracking.trackingpages.notificationHeaderDesc')
        introText = introText.replace(/\[PAGENAME\]/g, textFunctions.checkForHtml(this.props.tracker.host) + textFunctions.checkForHtml(this.props.path.path))

        return (


            <div>
                <h4 className="mar-b25">{i18n.t('campaigns:sitetracking.trackingpages.notificationHeader')}</h4>
                <p className="mar-b25" dangerouslySetInnerHTML={{ __html: introText }}></p>

                <UILIB.Toggle outerClassName="mar-b25" after={i18n.t('campaigns:sitetracking.trackingpages.enableNotifications')} onChange={this.changeSendNotifications} value={this.state.path.sendNotifications} />

                {this.state.path.sendNotifications && <div>

                    <UILIB.Select
                        outerClassName="mar-b25"
                        placeholder={i18n.t('campaigns:sitetracking.trackingpages.whereShouldWeSendNotifications')}
                        data={whereNotifications}
                        value={this.state.path.sendNotificationsToType}
                        onChange={this.changeNotificationType}
                    />

                    {this.state.path.sendNotificationsToType == 0 &&
                        <UILIB.TextInput outerClassName="mar-b25" placeholder="email@address.com" name="sendNotificationsToEmailAddress" value={this.state.path.sendNotificationsToEmailAddress} onChange={this.changeNotificationsToEmailAddress} error={this.state.emailError} />
                    }
                </div>
                }

                <UILIB.Button className="button-primary" text={i18n.t('campaigns:sitetracking.trackingpages.save')} onClick={this.saveChanges} />


            </div >
        );
    }
}

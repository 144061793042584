import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '~/i18n'
import UILIB from '../../../../Common-Objects/Lib'
import AceEditor from 'react-ace';
import 'brace/mode/html';
import copy from 'copy-to-clipboard';
import axios from '~/data/http/axios'

const inline = `<script>
var mpEmailAddress = "test@test.com";
var mpGroupId = 74175;
var mpPath = "/path.html"
var mpQuery = "?query=1"
var mpCustomFields = {
    "First Name": "Colin",
    "Age": 19,
    "Birthdate": new Date("2015-03-25").toISOString(),
    "Male: true
}
//Event Tracking (Optional)
var mpEventName = "my event name"
var mpEventData = {"myCustom":"dataHere"}
</script>`

const programatically = `<script>
manualTracking(
    mpPath,
    mpQuery,
    mpEmailAddress,
    mpCustomFields,
    mpGroupId,
    mpEventName, 
    mpEventData
)
</script>`

@connect((store) => {
    return { site: store.siteMaster, account: store.accountMaster }
})
class SiteTrackingAdvanced extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertOpen: "",
            alertMessage: "",
            apiDoc: {},
            pageLoaded: false,
            apiPostTable: [],
            apiResponseTable: []
        }
        this.getApiDocs = this.getApiDocs.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
    }

    componentDidMount() {
        this.getApiDocs();
    }
    async getApiDocs() {
        let apiDoc = await axios.get('/api/apiDoc/122')
        apiDoc = apiDoc.data.ApiDocs;

        let apiPostTable = JSON.parse(apiDoc.postData).map(row => {
            return [
                {
                    headerValue: "",
                    value: row.fieldName,
                },
                {
                    headerValue: "",
                    value: row.required ? <UILIB.SquareChip value="Required" className="square-chip-red" /> : "",
                },
                {
                    headerValue: "",
                    value: row.dataType
                },
                {
                    headerValue: "",
                    value: row.description
                }
            ]
        })
        let apiResponseTable = JSON.parse(apiDoc.responseData).map(row => {
            return [
                {
                    headerValue: "",
                    value: row.fieldName,
                },
                {
                    headerValue: "",
                    value: row.dataType
                },
                {
                    headerValue: "",
                    value: row.description
                }
            ]
        })
        this.setState({ apiDoc, pageLoaded: true, apiPostTable, apiResponseTable })
    }
    copyToClipboard(key) {
        this.setState({
            alertOpen: true,
            alertMessage: i18n.t('campaigns:sitetracking.trackingsites.copiedToClipboard')
        })
        copy(this.props.embedCode)
    }

    render() {
        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />
        return <>

            <UILIB.Row className="mar-b25">
                <UILIB.Column xs={12} sm={8}>
                    <UILIB.Paper style={{ height: "100%" }}>
                        <h4 className="mar-b10">Advanced</h4>
                        <div className="mar-b10">Advanced options for pushing events and custom site tracking information via code.</div>
                        <div>Use either the Javascript code samples to push extra data or trigger events client side, or use the API to send data backend.</div>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={4}>
                    <UILIB.Paper className="mar-b0 text-center">
                        <h4 className="mar-b10">Site Tracking Javascript</h4>
                        <div className="mar-b10">Deploy this anywhere where you would like to track visitors</div>
                        <UILIB.TextInput

                            value={this.props.embedCode}
                            className="txtInput"
                            iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => { this.copyToClipboard() }} />}
                            readOnly={true}
                        />
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={6}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">Javascript</h4>
                        <div >{i18n.t('campaigns:sitetracking.trackingsites.moreOptionsDesc')}</div>
                        {this.props.site && this.props.site.siteId == 1 && <div >Find out more about advanced web tracking options and how you can use them by <a href="https://kb.transpond.io/article/51-site-tracking-extra-functions" target="_blank">clicking here</a>.</div>}

                        <h4 className="mar-b15 mar-t15">{i18n.t('campaigns:sitetracking.trackingsites.availableOptions')}</h4>
                        <UILIB.Paper>
                            <table width="100%" cellPadding="10" cellSpacing="0" className="mar-b25">
                                <tr>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}><UILIB.SquareChip>mpEmailAddress</UILIB.SquareChip></td>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}>{i18n.t('campaigns:sitetracking.trackingsites.availableOpts.mpEmailAddress')}</td>
                                </tr>
                                <tr>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}><UILIB.SquareChip>mpGroupId</UILIB.SquareChip></td>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}>{i18n.t('campaigns:sitetracking.trackingsites.availableOpts.mpGroupId')}</td>
                                </tr>
                                <tr>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}><UILIB.SquareChip>mpPath</UILIB.SquareChip></td>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}>{i18n.t('campaigns:sitetracking.trackingsites.availableOpts.mpPath')}</td>
                                </tr>
                                <tr>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}><UILIB.SquareChip>mpQuery</UILIB.SquareChip></td>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}>{i18n.t('campaigns:sitetracking.trackingsites.availableOpts.mpQuery')}</td>
                                </tr>
                                <tr>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}><UILIB.SquareChip>mpCustomFields</UILIB.SquareChip></td>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}>{i18n.t('campaigns:sitetracking.trackingsites.availableOpts.mpCustomFields')}</td>
                                </tr>
                                <tr>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}><UILIB.SquareChip>mpEventName</UILIB.SquareChip></td>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}>{i18n.t('campaigns:sitetracking.trackingsites.availableOpts.mpEventName')}</td>
                                </tr>
                                <tr>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}><UILIB.SquareChip>mpEventData</UILIB.SquareChip></td>
                                    <td className="text-sml" style={{ verticalAlign: "top" }}>{i18n.t('campaigns:sitetracking.trackingsites.availableOpts.mpEventData')}</td>
                                </tr>

                            </table>
                        </UILIB.Paper>

                        <h4 className="mar-b15">Code Samples</h4>

                        <UILIB.Paper>
                            <h4 className="mar-b15">{i18n.t('campaigns:sitetracking.trackingsites.inlineVariables')}</h4>
                            <div className="mar-b15">{i18n.t('campaigns:sitetracking.trackingsites.inlineVariablesDesc')}</div>
                            <UILIB.Paper className="mar-b0" style={{ padding: 0 }}>
                                <AceEditor
                                    height={240}
                                    mode={'html'}
                                    theme="xcode"
                                    name="code"
                                    width="100%"
                                    readOnly={true}
                                    wrapEnabled={true}
                                    showPrintMargin={false}
                                    editorProps={{ $blockScrolling: true }}
                                    value={inline}
                                />
                            </UILIB.Paper>
                        </UILIB.Paper>

                        <UILIB.Paper>
                            <h4 className="mar-b15">{i18n.t('campaigns:sitetracking.trackingsites.functionVariables')}</h4>
                            <div className="mar-b15">{i18n.t('campaigns:sitetracking.trackingsites.functionVariablesDesc')}</div>
                            <UILIB.Paper className="mar-b0" style={{ padding: 0 }}>
                                <AceEditor
                                    height={180}
                                    mode={'html'}
                                    theme="xcode"
                                    name="code"
                                    width="100%"
                                    readOnly={true}
                                    wrapEnabled={true}
                                    showPrintMargin={false}
                                    editorProps={{ $blockScrolling: true }}
                                    value={programatically}
                                />
                            </UILIB.Paper>
                        </UILIB.Paper>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">API</h4>
                        <div className="mar-b15" dangerouslySetInnerHTML={{ __html: this.state.apiDoc.headerDescription }} />
                        <hr />
                        <div className="text-heavy mar-b10">Request</div>
                        <div className="mar-b15">Send a <strong>POST</strong> to the api endpoint</div>
                        <hr />
                        <div className="text-heavy mar-b10">Api Endpoint</div>
                        <UILIB.Paper style={{ padding: "15px" }}>{"https://" + this.props.site.trackingDomain + this.state.apiDoc.requestUrl}</UILIB.Paper>
                        <hr />
                        <div className="text-heavy mar-b10">Request</div>
                        <UILIB.Paper style={{ padding: 15 }}>
                            <UILIB.DataTable1
                                noResultsTxt={""}
                                tableData={this.state.apiPostTable}
                                dataUpdater={() => { }}
                                width="100%"
                                pageSize="100"
                                hasCheckBoxes="no"
                                sortedColumn={""}
                                sortedDirection={""}
                                loadingData={false}
                            />
                        </UILIB.Paper>
                        <hr />
                        <div className="text-heavy mar-b10">Response</div>
                        <UILIB.Paper style={{ padding: 15 }}>
                            <UILIB.DataTable1
                                noResultsTxt={""}
                                tableData={this.state.apiResponseTable}
                                dataUpdater={() => { }}
                                width="100%"
                                pageSize="100"
                                hasCheckBoxes="no"
                                sortedColumn={""}
                                sortedDirection={""}
                                loadingData={false}
                            />
                        </UILIB.Paper>
                        <hr />
                        <div className="text-heavy mar-b10">Sample Request</div>
                        <UILIB.Paper style={{ padding: 0 }}>
                            <AceEditor
                                height={100}
                                mode={'json'}
                                theme="xcode"
                                name="code"
                                width="100%"
                                readOnly={true}
                                wrapEnabled={true}
                                showPrintMargin={false}
                                editorProps={{ $blockScrolling: true }}
                                value={JSON.stringify(JSON.parse(this.state.apiDoc.requestExample), null, 2)}
                            />
                        </UILIB.Paper>

                        <hr />
                        <div className="text-heavy mar-b10">Sample Response</div>
                        <UILIB.Paper className="mar-b0" style={{ padding: 0 }}>
                            <AceEditor
                                height={100}
                                mode={'json'}
                                theme="xcode"
                                name="code"
                                width="100%"
                                readOnly={true}
                                wrapEnabled={true}
                                showPrintMargin={false}
                                editorProps={{ $blockScrolling: true }}
                                value={JSON.stringify(JSON.parse(this.state.apiDoc.responseExample), null, 2)}
                            />
                        </UILIB.Paper>


                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
        </>
    }
}

export default SiteTrackingAdvanced;
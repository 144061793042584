import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

import { connect } from 'react-redux';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster }
})

export default class AddSubscriber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupName: "",
            groupID: 0,
            customFieldInputs: "",
            customFields: [],
            emailAddress: "",
            emailError: false,
            formError: "",
            emailsAdded: [],
            alertMessage: '',
            alertOpen: false,
            isBlackList: false,
            requiredOnly: false,
            customFieldsLoaded: false,
            status: 'active',
            addToApp: false,
            app: undefined,
        };

        this.getCustomFields = this.getCustomFields.bind(this);
        this.checkForm = this.checkForm.bind(this);
        this.updateTextField = this.updateTextField.bind(this);
        this.saveSubscriber = this.saveSubscriber.bind(this);
        this.updateMultiSelectField = this.updateMultiSelectField.bind(this);
        this.toggleRequired = this.toggleRequired.bind(this);
        this.getApps = this.getApps.bind(this)
    }

    componentDidMount() {

        var isBlacklist = true;
        if (this.props.accountMaster.accountMaster.blackListGroupId != this.props.match.params.groupID) {
            isBlacklist = false;
        }

        this.setState({
            groupID: this.props.match.params.groupID,
            isBlackList: isBlacklist
        }, this.getCustomFields);
    }

    getCustomFields() {
        axios.get('/customfield').then((res) => {
            this.setState({
                customFieldsLoaded: true,
                customFields: res.data.CustomFields
            }, this.getApps)
        }).catch(() => {
            this.setState({ alertOpen: true, alertMessage: i18n.t('subscribers:add.single.alertError') });
        })
    }

    getApps() {
        axios.get('/accountMaster/application').then(res => {
            this.setState({
                app: res.data.Applications.find(a => a.appSettings.groupId == this.state.groupID && !!a.Application.options.importOnCreate)
            })
        })
    }

    updateBoolField(field, ev) {
        var _customFields = this.state.customFields

        _customFields.map(_field => {
            if (_field.fieldName == field.fieldName) {
                _field.value = ev.target.checked
            }
        })

        this.setState({ customFields: _customFields });
    }


    updateTextField(field, evt) {

        if (typeof field === "object" && field.fieldType && field.fieldType === "DATE") {
            field.value = evt;
        }
        else {
            if (evt.target) {
                if (evt.target.name == "emailAddress") {
                    this.setState({ emailAddress: evt.target.value })
                    return
                }
            }
            field.value = evt.target.value
        }

        var _customFields = this.state.customFields

        _customFields.map(_field => {
            if (_field.fieldName == field.fieldName) {
                if (_field && _field.fieldType && _field.fieldType.toLowerCase() == "number" && !isNaN(field.value)) {
                    field.value = Number(field.value)
                }
                _field.value = field.value
                if (_field && _field.fieldType && _field.fieldType.toLowerCase() == "telno") {
                    if (evt.hasOwnProperty('valid') && !evt.valid && _field.value) {
                        _field.error = 'Invalid Phone Number'
                    } else {
                        _field.error = ''
                    }
                }

            }
        })

        this.setState({ customFields: _customFields });
    }

    updateMultiSelectField(field, theOpt) {
        var newVal = field.value;
        if (!Array.isArray(newVal)) newVal = [];
        var hasVal = newVal.indexOf(theOpt.value)
        if (hasVal > -1) {
            newVal.splice(hasVal, 1);
        }
        else {
            newVal.push(theOpt.value)
        }

        var _customFields = this.state.customFields
        _customFields.map(_field => {
            if (_field.fieldName == field.fieldName) {
                _field.value = newVal
            }
        })

        // console.log(_customFields);
        this.setState({ customFields: _customFields });
    }


    checkForm() {

        var self = this;
        var _customFields = self.state.customFields

        var hasError = false


        if (self.state.emailAddress == "") {
            self.setState({ emailError: i18n.t('subscribers:add.single.emailRequired') })
            hasError = true;
        } else {
            self.setState({ emailError: false })
        }

        _customFields.map(field => {
            if (field.isRequired && (field.value == "" || field.value == undefined)) {
                field.error = i18n.t('subscribers:add.single.required')
                hasError = true;
            } else {
                field.error = undefined
            }
        })
        var formError = "";
        if (hasError) formError = "Please complete all required fields marked red above.";
        self.setState({ customFields: _customFields, formError: formError }, () => { if (hasError === false) { self.saveSubscriber() } });
    };

    saveSubscriber() {
        var newFields = {};
        for (var x in this.state.customFields) {
            var fieldID = this.state.customFields[x].id;
            var fieldVal = this.state.customFields[x].value;
            if (this.state.customFields[x].fieldType && this.state.customFields[x].fieldType == "BOOLEAN" && (fieldVal == undefined)) {
                fieldVal = false;
            }
            newFields[fieldID] = fieldVal;
        }
        this.setState({ alertOpen: true, alertMessage: i18n.t('subscribers:add.single.alertCreating') });

        var theData = { "emailAddress": this.state.emailAddress, "customFields": newFields }

        if (this.state.isBlackList) {
            theData.blackListed = true
        } else {
            if (this.state.status === 'inactive') theData.active = false;
            if (this.state.status === 'unsubscribed') theData.unsubscribe = true;
        }

        let url = '/group/' + this.state.groupID + "/subscriber"
        if (this.state.app) {
            // check for required fields
            let addToApp = true
            if (this.state.app.Application.options.importOnCreateRequired && Array.isArray(this.state.app.Application.options.importOnCreateRequired)) {
                this.state.app.Application.options.importOnCreateRequired.forEach(field => {
                    const found = this.state.customFields.find(cf => cf.fieldName === field)
                    if (found && !theData.customFields[found.id]) {
                        addToApp = false
                    }
                })
            }
            if (addToApp) {
                url += '?webhooksInbound=' + this.state.app.Application.options.importOnCreate
            }
        }
        axios.post(url, theData).then((res) => {
            //reset custom field vals
            if (this.state.addAnother) {
                var _customFields = this.state.customFields;
                var _customFieldsN = _customFields.map(theField => {
                    if (theField.hasOwnProperty("value")) {
                        theField.value = "";
                    }
                    return theField;
                })
                var emailsAdded = this.state.emailsAdded;
                emailsAdded.push([this.state.emailAddress]);
                this.setState({ emailsAdded: emailsAdded, emailAddress: "", customFields: _customFieldsN, alertOpen: true, alertMessage: i18n.t('subscribers:add.single.alertCreated'), requiredOnly: false })
            } else {
                this.props.history.push('/cp/subscriber/' + res.data.subscriberId)
            }
        }).catch((error) => {
            console.log(error)
            this.setState({
                alertOpen: true, alertMessage: i18n.t('subscribers:add.single.alertAddedError'),
                formError: <UILIB.FormError>{error.data.error}</UILIB.FormError>
            });
        })


    }

    toggleRequired() {
        var requiredOnly = this.state.requiredOnly;
        if (requiredOnly) {
            requiredOnly = false
        } else {
            requiredOnly = true
        }
        this.setState({ requiredOnly })
    }
    render() {

        var classCount = this.state.customFields.length;
        var statusOptions = [{
            label: i18n.t('translations:Active'),
            value: 'active'
        }, {
            label: i18n.t('translations:In-Active'),
            value: 'inactive'
        }, {
            label: i18n.t('translations:Unsubscribed'),
            value: 'unsubscribed'
        }]

        const requiredFields = []
        if (this.state.app && this.state.app.Application.options.importOnCreateRequired && Array.isArray(this.state.app.Application.options.importOnCreateRequired)) {
            this.state.app.Application.options.importOnCreateRequired.forEach(field => {
                const found = this.state.customFields.find(cf => cf.fieldName === field)
                if (found) requiredFields.push(found.fieldDesc)
            })
        }

        return <div>
            <UILIB.Row>
                {!this.state.customFieldsLoaded && <UILIB.Column xs={12} sm={12} md={8} margin={0}>
                    <UILIB.Paper>
                        <UILIB.LoadingIcons iconType="2" />
                    </UILIB.Paper>
                </UILIB.Column>
                }
                {this.state.customFieldsLoaded && <UILIB.Column xs={12} sm={12} md={8} margin={0}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">
                            {i18n.t('subscribers:add.single.details')}
                        </h4>

                        <UILIB.TextInput type="email" outerClassName="mar-b15" label={i18n.t('emailAddress')} placeholder={i18n.t('emailAddressPlaceholder')} name="emailAddress" value={this.state.emailAddress} error={this.state.emailError} onChange={(evt) => this.updateTextField(undefined, evt)} focus={true} />


                        {this.state.customFields.map((field, index) => {


                            if (this.state.requiredOnly && field.isRequired != true) {
                                return;
                            }

                            var thisVal = "";
                            if (field.hasOwnProperty("value")) {
                                thisVal = field.value;
                            }

                            let name = field.fieldDesc
                            if (field.Application) {
                                name = <div>{name} <UILIB.Avatar style={{ height: 20, width: 20, top: 5, marginLeft: 5, backgroundColor: 'white', border: '1px solid grey' }} for={field.id + '-App'} tooltip={field.Application.appName} src={field.Application.appLogoUri} /></div>
                            }


                            if (field.fieldType.toLowerCase() === "date") {
                                return <UILIB.TextInput key={field.id} outerClassName="mar-b15" label={field.fieldDesc} type={'datetime'} placeholder={field.fieldDesc} name={field.fieldName} value={thisVal} error={field.error} onChange={this.updateTextField} field={field} />
                            }
                            if (field.fieldType.toLowerCase() === 'boolean') {
                                return <div key={field.id} className="mar-b15">
                                    <UILIB.CheckBox checked={thisVal} onChange={ev => this.updateBoolField(field, ev)} name={field.fieldName}>{field.fieldDesc}</UILIB.CheckBox>
                                    {field.error && field.error.length && <p className="errorHolderWarning">Required Field</p>}
                                </div>
                            }
                            if (field.fieldType.toLowerCase() === 'select') {
                                var theOpts = JSON.parse(JSON.stringify(field.options));
                                if (!theOpts) theOpts = []

                                theOpts.unshift({ value: "", label: "" })
                                return <UILIB.Select outerClassName="mar-b15" label={field.fieldDesc} value={thisVal} onChange={ev => this.updateTextField(field, ev)} name={field.fieldName} data={theOpts} error={field.error} />
                            }

                            if (field.fieldType.toLowerCase() === 'multiselect') {
                                var theOpts = JSON.parse(JSON.stringify(field.options))
                                if (!theOpts) theOpts = [];
                                theOpts.unshift({ value: "", label: "" })
                                var currVals = thisVal;
                                if (!Array.isArray(currVals)) currVals = [currVals];
                                var multiHeader = "";

                                currVals.forEach((v, i) => {
                                    theOpts.forEach(tO => {
                                        if (tO.value == v) {
                                            tO.selected = true;
                                            multiHeader += tO.label + ", ";
                                        }
                                    })
                                })
                                if (multiHeader != "") {
                                    multiHeader = multiHeader.substr(0, multiHeader.length - 2)
                                }
                                if (multiHeader == "") {
                                    multiHeader = i18n.t('subscribers:index.clickHereToSelect')
                                }

                                return <div className="mar-b15" key={field.id}>
                                    <UILIB.ButtonSelect label={field.fieldDesc} headerText={multiHeader} filter={false}>
                                        {theOpts && !!theOpts.length && <ul>
                                            {theOpts.map((theOpt, optIndex) => {
                                                return <li key={theOpt.value}><a><UILIB.CheckBox key={theOpt.value} name={theOpt.label} onChange={() => this.updateMultiSelectField(field, theOpt)} checked={theOpt.selected} /> <span className="pad-left">{theOpt.label}</span></a></li>
                                            })}
                                        </ul>}
                                        {(!theOpts || !theOpts.length) && <span>There are no values associated with this field</span>}

                                    </UILIB.ButtonSelect>
                                    {field.error && field.error.length && <p className="errorHolderWarning">Required Field</p>}

                                </div>
                            }

                            return <UILIB.TextInput outerClassName="mar-b15" key={field.id} label={field.fieldDesc} id={field.id} type={field.fieldType} placeholder={field.fieldDesc} name={field.fieldName} value={thisVal} error={field.error} onChange={evt => this.updateTextField(field, evt)} />

                        })}

                        {this.state.formError && <UILIB.Paper className="paper-red">{this.state.formError}</UILIB.Paper>}
                        <UILIB.Button className="button-primary" onClick={this.checkForm} text={i18n.t('subscribers:add.single.buttonAdd')} iconLeft={<UILIB.Icons icon="plus" />} />
                        {Boolean(this.state.app) && <div className="mar-t10 mar-b10 text-sml text-grey text-heavy">This Contact will be added to {this.state.app.Application.appName}</div>}
                        {!!requiredFields.length && <div className="mar-t10 mar-b10 text-sml text-grey text-heavy">{requiredFields.join(', ')} Required to be added to {this.state.app.Application.appName}</div>}
                        {this.state.formError && <UILIB.Button className="button-sml outline grey mar-l10" onClick={this.toggleRequired} text={this.state.requiredOnly ? 'Show All Fields' : 'Show Required Fields'} />}
                    </UILIB.Paper >

                </UILIB.Column >}

                <UILIB.Column xs={12} sm={12} md={4} margin={0}>

                    <UILIB.Row>
                        <UILIB.Column xs={12} margin={20}>
                            {this.state.emailsAdded.length > 0 &&
                                <UILIB.Paper className="paper-lightGrey">
                                    <h4 className="mar-b25">{i18n.t('subscribers:add.single.soFar')}</h4>

                                    {this.state.emailsAdded.map(email => {
                                        return <span key={"chip_" + email}><UILIB.SquareChip value={email} maxTextWidth="20ch" /></span>
                                    })}

                                </UILIB.Paper>
                            }
                        </UILIB.Column>
                        <UILIB.Column xs={12} margin={0}>
                            {!this.state.isBlackList && <UILIB.Paper className="paper-lightGrey">
                                <h4 className="mar-b25">
                                    {i18n.t('subscribers:add.single.addAs')}
                                </h4>
                                <div className="form-group no-marg">
                                    <UILIB.Select value={this.state.status} data={statusOptions} onChange={e => this.setState({ status: e.currentTarget.value })} />
                                </div>
                            </UILIB.Paper>}
                            {!this.state.isBlackList && <UILIB.Paper className="paper-primary">
                                <h4 className="mar-b10">
                                    {i18n.t('subscribers:add.single.infoHeader')}
                                </h4>
                                <p className="mar-b25">{i18n.t('subscribers:add.single.infoHeader2')}</p>
                                <h4 className="mar-b10">
                                    {i18n.t('subscribers:add.single.infoSubHeader')}
                                </h4>
                                <p>{i18n.t('subscribers:add.single.infoText')}</p>
                                <p>{i18n.t('subscribers:add.single.infoText2')}</p>

                            </UILIB.Paper>}

                            {this.state.isBlackList && <UILIB.Paper className="paper-primary">
                                <h4 className="mar-b25">
                                    {i18n.t('subscribers:add.fromGroup.addBlacklistNoS')},
                                </h4>
                                <p>{i18n.t('subscribers:add.fromGroup.blackListBox')}</p>

                            </UILIB.Paper>}

                        </UILIB.Column>
                    </UILIB.Row>
                </UILIB.Column>
            </UILIB.Row >
            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
        </div >
    };
};
import React from 'react'
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster }
})
export default class GenericAppDisconnect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disconnecting: false
        }
    }

    async onClick() {
        this.setState({ disconnecting: true })
        await this.props.goDisconnect()
        this.setState({ disconnecting: false })
    }

    render() {

        return <div>

            <h4 className="mar-b25">Really Disconnect?</h4>
            <div className="mar-b25">Are you sure you want to disconnect {this.props.appName} from {this.props.siteMaster.siteName}?</div>
            <div className="mar-b25">If you disconnect, your contacts will remain here but we will stop syncing with your {this.props.appName} account.</div>

            <UILIB.Button text="Disconnect!" iconRight={
                this.state.disconnecting ? <UILIB.Icons icon="loading" /> : ""
            } className="button-primary" disabled={this.state.disconnecting} onClick={this.onClick.bind(this)} />

        </div>
    }
};


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SYIfHg7IWsYBukkBYzWx{display:flex;align-items:center}.SYIfHg7IWsYBukkBYzWx .avatar{box-sizing:content-box}.SYIfHg7IWsYBukkBYzWx .avatar[data-size=\"16\"]{border:3px solid var(--grey-0);margin-left:-4px}.SYIfHg7IWsYBukkBYzWx .avatar[data-size=\"16\"]:last-child{margin-right:-4px}.SYIfHg7IWsYBukkBYzWx .avatar[data-size=\"24\"]{border:3px solid var(--grey-0);margin-left:-6px}.SYIfHg7IWsYBukkBYzWx .avatar[data-size=\"24\"]:last-child{margin-right:-6px}.SYIfHg7IWsYBukkBYzWx .avatar[data-size=\"32\"]{border:3px solid var(--grey-0);margin-left:-8px}.SYIfHg7IWsYBukkBYzWx .avatar[data-size=\"32\"]:last-child{margin-right:-8px}", "",{"version":3,"sources":["webpack://./src/Common-Objects/UI/Avatar/Group/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAEA,8BACE,sBAAA,CAEA,8CACE,8BAAA,CACA,gBAAA,CAEA,yDACE,iBAAA,CAIJ,8CACE,8BAAA,CACA,gBAAA,CAEA,yDACE,iBAAA,CAIJ,8CACE,8BAAA,CACA,gBAAA,CAEA,yDACE,iBAAA","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n\n  :global(.avatar) {\n    box-sizing: content-box;\n\n    &[data-size=\"16\"] {\n      border: 3px solid var(--grey-0);\n      margin-left: -4px;\n\n      &:last-child {\n        margin-right: -4px;\n      }\n    }\n\n    &[data-size=\"24\"] {\n      border: 3px solid var(--grey-0);\n      margin-left: -6px;\n\n      &:last-child {\n        margin-right: -6px;\n      }\n    }\n\n    &[data-size=\"32\"] {\n      border: 3px solid var(--grey-0);\n      margin-left: -8px;\n\n      &:last-child {\n        margin-right: -8px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SYIfHg7IWsYBukkBYzWx"
};
export default ___CSS_LOADER_EXPORT___;

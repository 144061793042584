import React from 'react'

export default class IconsYoutube extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 4C7.746 4 4.82046 4.56475 4.82046 4.56475L4.8131 4.57317C3.78651 4.73735 3 5.61949 3 6.69231V9.92308V9.92413V13.1538V13.1549C3.001 13.6673 3.18462 14.1625 3.51789 14.5517C3.85116 14.9408 4.31225 15.1984 4.81836 15.2782L4.82046 15.2814C4.82046 15.2814 7.746 15.8472 10 15.8472C12.254 15.8472 15.1795 15.2814 15.1795 15.2814L15.1806 15.2803C15.6873 15.2007 16.1489 14.9429 16.4824 14.5533C16.8159 14.1636 16.9995 13.6678 17 13.1549V13.1538V9.92413V9.92308V6.69231C16.9992 6.17976 16.8157 5.68428 16.4825 5.2949C16.1492 4.90551 15.6879 4.64774 15.1816 4.56791L15.1795 4.56475C15.1795 4.56475 12.254 4 10 4ZM8.38462 7.44531L12.6923 9.92308L8.38462 12.4008V7.44531Z" fill={color} />
        </svg>
    }
}        
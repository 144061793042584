import React from "react";

export default function IconCrossCircle({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18.5C12.1217 18.5 14.1566 17.6571 15.6569 16.1569C17.1571 14.6566 18 12.6217 18 10.5C18 8.37827 17.1571 6.34344 15.6569 4.84315C14.1566 3.34285 12.1217 2.5 10 2.5C7.87827 2.5 5.84344 3.34285 4.34315 4.84315C2.84285 6.34344 2 8.37827 2 10.5C2 12.6217 2.84285 14.6566 4.34315 16.1569C5.84344 17.6571 7.87827 18.5 10 18.5ZM8.707 7.793C8.5184 7.61084 8.2658 7.51005 8.0036 7.51233C7.7414 7.5146 7.49059 7.61977 7.30518 7.80518C7.11977 7.99059 7.0146 8.2414 7.01233 8.5036C7.01005 8.7658 7.11084 9.0184 7.293 9.207L8.586 10.5L7.293 11.793C7.19749 11.8852 7.12131 11.9956 7.0689 12.1176C7.01649 12.2396 6.9889 12.3708 6.98775 12.5036C6.9866 12.6364 7.0119 12.7681 7.06218 12.891C7.11246 13.0139 7.18671 13.1255 7.2806 13.2194C7.3745 13.3133 7.48615 13.3875 7.60905 13.4378C7.73194 13.4881 7.86362 13.5134 7.9964 13.5123C8.12918 13.5111 8.2604 13.4835 8.3824 13.4311C8.50441 13.3787 8.61475 13.3025 8.707 13.207L10 11.914L11.293 13.207C11.4816 13.3892 11.7342 13.49 11.9964 13.4877C12.2586 13.4854 12.5094 13.3802 12.6948 13.1948C12.8802 13.0094 12.9854 12.7586 12.9877 12.4964C12.99 12.2342 12.8892 11.9816 12.707 11.793L11.414 10.5L12.707 9.207C12.8892 9.0184 12.99 8.7658 12.9877 8.5036C12.9854 8.2414 12.8802 7.99059 12.6948 7.80518C12.5094 7.61977 12.2586 7.5146 11.9964 7.51233C11.7342 7.51005 11.4816 7.61084 11.293 7.793L10 9.086L8.707 7.793Z"
        fill={color}
      />
    </svg>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import UILIB from "~/Common-Objects/Lib";
import SendButton from "../../../shared/sendButton";
import * as styles from "./index.module.scss";
import axios from "~/data/http/axios";
import ComposerComponent from '../../../shared/composer';
import InboxButton from "../../../shared/inboxButton";
import { parseSignature } from "../helpers/signature";
import i18n from "~/i18n";

export default function ComposerNewChat({
  createdConversation = () => { },
  activeInbox = () => { },
  subscriber = {},
  channels = []
}) {
  const uploader = useRef(null);
  const user = useSelector(state => state.user)
  const sendTypes = [
    { label: "Send", value: "send" },
  ]
  const buttonType = "send";
  const [message, setMessage] = useState("");
  const [editorRef, setEditorRef] = useState()
  const [subject, setSubject] = useState("");
  const [errors, setErrors] = useState({ subject: "", message: "", channel: "" })
  const [channelId, setChannelId] = useState(channels && channels.length ? channels[0].id : null);
  const [files, setFiles] = useState([])

  useEffect(() => {
    if (channels && channels.length && channels.filter(f => f.type == 1).length > -1) {
      const firstEmailChan = channels.filter(f => f.type == 1)[0]
      setChannelId(firstEmailChan.id)
    }
  }, [])

  useEffect(() => {
    const channel = channels.find(f => f.id == channelId);
    const signature = parseSignature(channel?.settings?.signature, user)
    if (signature) {
      //add some space above signature
      const signatureFinal = "<br/><br/>" + signature;
      setMessage(signatureFinal)
      if (editorRef?.current?.setContent) {
        editorRef.current.setContent(signatureFinal)
      }
    }
  }, [channelId, user])

  const clearEditor = () => {
    setFiles([]);
    editorRef.current.editorCommands.execCommand('mceSetContent', "", "");
  }

  const sendMessage = async () => {

    let errored = false;
    let tmpErrors = { subject: "", message: "", channel: "" };
    if (!subject || subject.length < 2) {
      tmpErrors.subject = i18n.t("chat:inbox.conversation.composer.new.errSubject");
      errored = true;
    }

    if (!message || message.length < 2) {
      tmpErrors.message = i18n.t("chat:inbox.conversation.composer.new.errMessage");
      errored = true;
    }
    if (!selectedChannel) {
      tmpErrors.channel = i18n.t("chat:inbox.conversation.composer.new.errChannel")
      errored = true;
    }
    setErrors(tmpErrors);
    if (errored) return;
    try {
      const newChat = await axios.post("/inboxes/chat", {
        SubscriberId: subscriber.id,
        text: message,
        subject: subject,
        InboxId: activeInbox.id,
        ChannelId: selectedChannel.id
      })

      const promises = []
      for (const file of files) {
        const formData = new FormData();
        formData.append("file", file);
        promises.push(axios.post(`/inboxes/chat/${newChat.data.id}/attachment?chatLineId=${newChat.data.chatLineId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }
        }))
      }
      const uploads = await Promise.all(promises)

      createdConversation(newChat.data.id)
    } catch (e) {
      console.log(e)
      errors.message = e.data ? e.data.error : i18n.t("chat:inbox.conversation.composer.new.err")
      setErrors(errors);

    }
  }

  const addFiles = (event) => {
    const arr = [...files]
    let error = ""
    if (event.target.files?.length) {
      for (const file of event.target.files) {
        if (file.size > 5242880) {
          error = i18n.t("chat:inbox.conversation.composer.new.fileLimit")
          continue
        }
        arr.push(file)
      }
    }
    if (error) dispatch(alterSnackbar(true, error))
    setFiles(arr)
    uploader.current.value = null;
  }

  const removeFile = (index) => {
    const arr = [...files]
    arr.splice(index, 1)
    setFiles(arr)
  }


  if (!channels || !channels.length) return <div>{i18n.t("chat:inbox.conversation.composer.new.noChannels")}</div>

  const selectedChannel = channels.find(f => f.id == channelId);
  const submitDisabled = !selectedChannel || !subject || subject.length < 2 || !message || message.length < 2;
  return (
    <>
      {(channels.length > 0) && <div className={styles.root} style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
        <div className={styles.label}>{i18n.t("chat:inbox.conversation.composer.new.channel")}:</div>
        <UILIB.SelectNewDefault
          value={channelId}
          data={channels.filter(f => f.subscriberOnline == true || f.type == 1).map(chan => { return { label: chan.name, value: chan.id } })}
          onValueChange={(val) => { setChannelId(val) }}
          error={errors.channel}
          placeholder={i18n.t("chat:inbox.conversation.composer.new.selectChannel")}
        />

      </div>
      }
      {(selectedChannel && selectedChannel.type == 1) && <div className={styles.root} style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
        <div className={styles.label}>{i18n.t("chat:inbox.conversation.composer.new.from")}:</div>
        <UILIB.SquareChip className="square-chip-grey">{selectedChannel.settings.replyAddress}</UILIB.SquareChip>

      </div>}
      <div className={styles.root} style={{ display: "flex", flexDirection: "row", alignItems: 'center', paddingRight: '0' }}>
        <div className={styles.label}>{i18n.t("chat:inbox.conversation.composer.new.subject")}:</div>
        <div className={styles.subject}>
          <UILIB.TextInput placeholder={i18n.t("chat:inbox.conversation.composer.new.subjectPlaceholder")}
            value={subject}
            onChange={(ev) => { setSubject(ev.target.value) }}
            className={styles.txtInputs}
            error={errors.subject}
          />
        </div>
      </div>
      <div className={styles.root}>
        <ComposerComponent
          editorDisabled={false}
          message={message}
          setMessage={setMessage}
          goClearEditor={clearEditor}
          setEditorRef={setEditorRef}
          sendMessage={sendMessage}

        />
        {files.length > 0 && <div className="inbox-compose__attachments">
          {Array.from(files).map((file, index) => {
            return <div key={index} className="inbox-compose__attachments__attachment">
              <div className="inbox-compose__attachments__attachment_label">
                {file.name}
              </div>
              <div className="inbox-compose__attachments__attachment_size">
                {humanizeBytes(file.size)}
              </div>
              <UILIB.Icons icon="cross" style={{ height: 16, width: 16 }} color="#595C61" onClick={() => removeFile(index)} />
            </div>
          })}</div>}
        <div className={styles.actions}>
          <div className={styles.actionsLeft}>

            <InboxButton
              variant="ghost"
              size="s"
              iconOnly
              aria-label="Insert attachment"
              data-tip={i18n.t("chat:inbox.conversation.composer.addAttachment")} data-for="attachment"
              onClick={() => {
                uploader.current.click()
              }}
            >
              <UILIB.Icon name="paper-clip" />
            </InboxButton>
            <input type="file" ref={uploader} style={{ display: 'none' }} onChange={addFiles} accept=".png,.jpg,.jpeg,.gif,.pdf" multiple />
          </div>

          <div className={styles.actionsRight}>
            {errors.message && <div className="text-red">{errors.message}</div>}
            <SendButton
              size="s"
              onClick={sendMessage}
              dropDownOptions={sendTypes}
              dropDownSelected={buttonType}
              dropDownChange={(value) => { workOutSendOptions(undefined, value) }}
              disabled={submitDisabled}
            >
              {i18n.t("chat:inbox.conversation.composer.new.send")}
            </SendButton>
          </div>
        </div>
      </div>
    </>
  );
}

function humanizeBytes(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}


import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here


@connect((store) => {
    return { siteMaster: store.siteMaster, browser: store.browser, user: store.user, accountMaster: store.accountMaster }
})
class AddApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            appId: undefined,
            loadedData: false,
            appData: undefined,
            externalField: '',
            externalFieldErr: '',
            errors: {}
        }
        this.getApp = this.getApp.bind(this);
        this.oAuthConnect = this.oAuthConnect.bind(this);
        this.redirectGo = this.redirectGo.bind(this);
        this.oAuthoriseUriParamsUpdate = this.oAuthoriseUriParamsUpdate.bind(this);
        this.updateField = this.updateField.bind(this)
        this.next = this.next.bind(this)
    }

    componentDidMount() {
        if (this.props.appId) {
            this.setState({
                appId: this.props.appId
            }, this.getApp)
        }
    }

    getApp() {
        axios.get('/application/' + this.state.appId).then(response => {
            if (response.data.Application.id === 85) {
                // redirect to facebook connect page
                this.props.history.push('/cp/company/integrations/apps/twitter/connect')
            } else {
                this.setState({
                    appData: response.data.Application,
                    loadedData: true
                })
            }
        })
    }


    async oAuthConnect() {

        let ourTrackingDomain = this.props.siteMaster.trackingDomain;
        if (ourTrackingDomain === "api.transpond.io") {
            ourTrackingDomain = "api.mpzmail.com";
        } else if (ourTrackingDomain === "localhost:8082") {
            ourTrackingDomain = "localhost:8082";
        }


        var theURI = this.state.appData.oAuthAuthoriseUri;
        theURI = theURI.replace(/\[OAUTHCLIENTID\]/g, this.state.appData.oAuthAppId);
        theURI = theURI.replace(/\[RANDNO\]/g, this.props.user.token);
        var redirectURI = window.location.origin;
        if (redirectURI === 'https://app.transpond.io') {
            redirectURI = 'https://web.mpzmail.com'
        } else if (redirectURI === 'http://localhost:8082') {
            redirectURI = 'http://localhost:8082'
        }
        var toReplaceWith = redirectURI + "/cp/company/integrations/oAuth/response/" + this.state.appData.id
        if (this.state.appData.options && this.state.appData.options.backendAuth) {
            toReplaceWith = 'https://' + ourTrackingDomain + '/api/oauth/app/' + this.state.appData.id + '/oauth'
        }
        theURI = theURI.replace(/\[OAUTHREDIRECTURL\]/g, toReplaceWith);

        //if there are extra params defined, add them on
        var okToGo = true
        var errors = {}
        if (this.state.appData.options && this.state.appData.options.oAuthoriseUriParams && this.state.appData.options.oAuthoriseUriParams.length) {
            this.state.appData.options.oAuthoriseUriParams.forEach(theOpt => {
                if (theOpt.required) {
                    if (!theOpt.value) {
                        errors[theOpt.key] = 'Required'
                        okToGo = false
                    } else if (theOpt.isUrl) {
                        // test its a url
                        var regex = new RegExp(/^(https):\/\/[^\s$.?#].[^\s]*$/gm)
                        if (theOpt.value.indexOf('https://') !== 0) {
                            errors[theOpt.key] = 'Invalid Url - must start with https://'
                            okToGo = false
                        } else if (!regex.test(theOpt.value)) {
                            errors[theOpt.key] = 'Invalid Url - must start with https://'
                            okToGo = false
                        }
                    }
                }
                theURI += "&" + theOpt.key + "=" + theOpt.value;
            })
        }
        if (!okToGo) {
            return this.setState({
                errors: errors
            })
        }
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, false));
        window.open(theURI, '_self');
    }

    redirectGo() {
        var theURI = this.state.appData.oAuthAuthoriseUri;
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, false));
        window.open(theURI, '_blank');
    }

    oAuthoriseUriParamsUpdate(event, index) {
        var appData = this.state.appData;
        appData.options.oAuthoriseUriParams[index].value = event.target.value;
        this.setState({ appData: appData })
    }

    updateField(ev) {
        this.setState({
            [ev.currentTarget.name]: ev.currentTarget.value
        })
    }

    next() {
        var externalFieldVal = this.state.externalField;
        var okGo = true;
        var extErr = "";

        if (this.state.appId == 38) {
            externalFieldVal = externalFieldVal.toLowerCase();
            externalFieldVal = externalFieldVal.replace(/https:\/\//g, '');
            externalFieldVal = externalFieldVal.replace(/http:\/\//g, '');

            if (externalFieldVal.length < 3) {
                okGo = false;
                extErr = "Please enter a valid URL";
            }
            if (externalFieldVal.indexOf('.') > 0) {
                okGo = false;
                extErr = "Incorrect Salesmate URL";
            }
            externalFieldVal = externalFieldVal + '.salesmate.io';
        }
        if (!okGo) {
            this.setState({ externalFieldErr: extErr })
            return;
        }

        this.setState({ externalFieldErr: "" });

        axios.post('/application/account/' + this.state.appId, {
            [this.state.appData.options.externalField]: externalFieldVal
        }).then(() => {
            if (this.state.appData.appConfigUri) {
                this.props.browse(this.state.appData.appConfigUri)
            }
            else {
                this.props.browse('/cp/company/integrations/apps/integrationconfig/' + this.state.appId)
            }
        })
    }

    render() {
        if (!this.state.loadedData) {
            return (
                <div></div>
            )
        }

        var authNotes = this.state.appData.authNotes;
        if (authNotes && authNotes.length) {
            authNotes = authNotes.replace(/{{ACCOUNTMASTERID}}/g, this.props.accountMaster.id)
        }
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} sm={12} md={6} lg={6} className="col-md-offset-3 col-lg-offset-3">
                        <UILIB.Paper>
                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={12} md={2}>
                                    <UILIB.Avatar style={{ height: 'auto', width: '100%', maxWidth: "65px", maxHeight: "65px" }} src={this.state.appData.appLogoUri}></UILIB.Avatar>
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={10}>

                                    <h4 className="mar-b25">{this.props.reconnect ? "Reconnect to " : ""}{this.state.appData.appName}</h4>

                                    {this.props.reconnect && <UILIB.WarningHolder className="mar-b25" style={{ textAlign: "left" }}>
                                        <div className="mar-b10 text-heavy">It looks like we have become disconnected from {this.state.appData.appName}.</div>
                                        <div>Please reconnect using the following options. You will not lose your previous settings.</div>
                                    </UILIB.WarningHolder>
                                    }
                                    {!this.props.reconnect &&
                                        <div className="mar-b25" dangerouslySetInnerHTML={{ __html: this.state.appData.appLongDescription }} />

                                    }

                                    <div className="mar-b25" dangerouslySetInnerHTML={{ __html: authNotes }} />

                                    {this.state.appData.authType === "redirect" && <div>
                                        <UILIB.Button text={i18n.t('company:integrations.add.continue')} className="button-primary" onClick={this.redirectGo} />
                                    </div>}
                                </UILIB.Column>

                                <UILIB.Column xs={12} className="end-xs">
                                    {this.state.appData.authType === 'external' && <div>
                                        {(this.state.appId != 38) && <div className="mar-b25" >
                                            <UILIB.TextInput data={this.state.externalField} onChange={this.updateField} name="externalField" error={this.state.externalFieldErr} />
                                        </div>
                                        }
                                        {(this.state.appId == 38) && <div style={{ alignItems: "baseline" }} className="mar-b25" >
                                            <div className="quickFlex">
                                                <div style={{ flexGrow: 1, marginRight: 10 }}>
                                                    <UILIB.TextInput data={this.state.externalField} onChange={this.updateField} name="externalField" placeholder="yoursite" error={this.state.externalFieldErr} />
                                                </div>
                                                <div>.salesmate.io</div>
                                            </div>
                                        </div>}

                                        <UILIB.Button text="Continue" className="button-primary" onClick={this.next} />
                                    </div>}
                                    {this.state.appData.authType === "oAuth" && <div>
                                        {this.state.appData.options && this.state.appData.options.oAuthoriseUriParams && <div className="mar-b25">
                                            {this.state.appData.options.oAuthoriseUriParams.map((theOpt, index) => {
                                                return <div key={index}>
                                                    <UILIB.TextInput label={theOpt.label} key={index} name={theOpt.value + "_" + index} error={this.state.errors[theOpt.key]} value={theOpt.value} onChange={(event) => { this.oAuthoriseUriParamsUpdate(event, index) }} />
                                                </div>
                                            })}
                                        </div>}
                                        <UILIB.Button text={(this.props.reconnect ? "Re-" : "") + i18n.t('company:integrations.add.oauth', { appName: this.state.appData.appName })} className="button-primary" onClick={this.oAuthConnect} />
                                    </div>}
                                </UILIB.Column>
                            </UILIB.Row>
                        </UILIB.Paper>
                    </UILIB.Column >
                </UILIB.Row >

            </div >
        );
    }
}

export default AddApp;
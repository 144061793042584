import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import UILIB from '../../../../Common-Objects/Lib'
import { alterSiteDrawer } from '../../../../data/actions/siteActions'

export default function ChangeChart({ current = "campaigns", onSave = () => { } }) {
    const dispatch = useDispatch()
    const [chart, setChart] = useState(current)

    const save = () => {
        onSave(chart)
        dispatch(alterSiteDrawer(false, true, 'right', null, true))
    }
    const data = [{
        label: 'Campaign Stats',
        value: 'campaigns',
    }, {
        label: 'Form Signups',
        value: 'forms',
    }, {
        label: 'Automation Actions',
        value: 'automations',
    }]

    return (
        <div>
            <h4 className='mar-b25'>Change Dashboard Chart</h4>
            <UILIB.Select outerClassName="mar-b25" data={data} label="Select Chart" value={chart} onChange={e => setChart(e.currentTarget.value)} />
            <UILIB.Button className="button-primary" text="Save" onClick={save} />
        </div>
    )
}
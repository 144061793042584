import React, { useEffect, useState } from 'react'
import UILIB from '../../../Common-Objects/Lib'
import axios from '../../../data/http/axios'

export default function Release({ id }) {
    const [release, setRelease] = useState(null)

    useEffect(() => {
        axios.get('/release/' + id).then(r => setRelease(r.data))
    }, [id])

    if (!release) return <UILIB.LoadingIcons />

    return (
        <div className='release'>
            <h4 className='mar-b25'>{release.version} - {release.title}</h4>
            <div className='release-content' dangerouslySetInnerHTML={{ __html: release.notes }}></div>
        </div>
    )
}
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import UILIB from '../../../../../Common-Objects/Lib'
import axios from '../../../../../data/http/axios'
import { SupportedApps, SocialPreview, connect, getSites, SocialImageSelector, findLinks, getMeta } from './shared'
import i18n from '../../../../../i18n'
import { alterSiteDrawer } from '../../../../../data/actions/siteActions'
import FileManager from '../../../includes/fileManager/fileManager'
import { launchAviary } from '../../../../../Classes/aviaryEditor'
import TierPermissions from '../../../../../Classes/tierPermissions'
import FormatNumberFuncs from '../../../../../Classes/numberFormatFunctions'

export default function SocialCampaignCreateCustomise({ campaign, refresh = () => { } }) {
    const history = useHistory()
    const site = useSelector(state => state.siteMaster)
    const user = useSelector(state => state.user)
    const timer = useRef()
    const [campaigns, setCampaigns] = useState(campaign.Campaigns)
    const [selected, setSelected] = useState(null)
    const [socialAccounts, setSocialAccounts] = useState(null)
    const [accApps, setAccApps] = useState(null)
    const [apps, setApps] = useState(null)
    const [popupLinks, setPopupLinks] = useState(null)
    const [adding, setAdding] = useState({})
    const [errors, setErrors] = useState([])

    const getSocials = async () => {
        const res = await axios.get('/social')
        setSocialAccounts(res.data)
    }

    const getLinks = async () => {
        const links = {}
        for (const app of apps) {
            const link = await connect(site, user, app)
            links[app.id] = link
        }
        setPopupLinks(links)
    }

    const save = async (url, force = false) => {
        if (!force) {
            const _errors = []
            for (const campaign of campaigns) {
                const app = SupportedApps.find(a => a.code === campaign.options.AppCode)
                if (app && app.limit && campaign.Template.templateJson.content.length > app.limit) {
                    _errors.push({ CampaignId: campaign.id, error: i18n.t('social:customise.errors.longWarning') })
                }
                if (app?.formats && campaign.Template.templateJson.images?.length) {
                    const image = campaign.Template.templateJson.images[0]
                    const type = image.type || image.fileExtension
                    if (type && !app.formats.includes(type.toUpperCase())) {
                        _errors.push({ CampaignId: campaign.id, image: i18n.t('social:customise.errors.badFormat') })
                    }
                }

                if (typeof campaign.Template.templateJson.content) campaign.Template.templateJson.content = campaign.Template.templateJson.content.trim()
                if (!campaign.Template.templateJson.images?.length && !campaign.Template.templateJson.content.length) {
                    _errors.push({ CampaignId: campaign.id, error: i18n.t('social:customise.errors.emptyContent') })
                }

                if (app.code === 'INSTAGRAM' && !campaign.Template.templateJson.images?.length) {
                    _errors.push({ CampaignId: campaign.id, image: i18n.t('social:customise.errors.instagramImageHeader') })
                }

                if (app.fileLimit && campaign.Template?.templateJson?.images && campaign.Template.templateJson.images.some(t => t.size > app.fileLimit)) {
                    _errors.push({ CampaignId: campaign.id, image: i18n.t('social:customise.errors.fileSize', { limit: app.fileLimit }) })
                }

                if (!campaign.options?.SocialAccountId || !socialAccounts.some(a => a.id === campaign.options.SocialAccountId)) {
                    _errors.push({ CampaignId: campaign.id, account: true })
                }
            }
            setErrors(_errors)
            if (_errors.length) return
        }
        for (const campaign of campaigns) {
            await axios.put('/campaign/' + campaign.id, { campaign: { options: campaign.options } })
            let image
            if (campaign.Template.templateJson.images?.length) image = campaign.Template.templateJson.images[0].url || campaign.Template.templateJson.images[0]
            const html = `<html style="padding: 0; margin: 0;">
            <body style="height: 100%; width: 100%; display: flex; justify-content: center;">
            <div>${campaign.Template.templateJson.images?.length ? `<img src="${image}"></div>` : campaign.Template.templateJson.content}</div>
            </body>
            </html>`
            await axios.put('/template/' + campaign.Template.id, { templateJson: campaign.Template.templateJson, templateHTML: html })
        }
        await refresh()
        if (url) {
            history.push(url)
        }
    }

    const updateTemplate = (c, e) => {
        const arr = [...campaigns]
        const found = arr.find(f => f.id === c.id)
        found.Template.templateJson[e.target.name] = e.target.value
        setCampaigns(arr)
    }

    const updateCampaignOptions = (c, e) => {
        const arr = [...campaigns]
        const found = arr.find(f => f.id === c.id)
        found.options[e.target.name] = e.target.value
        setCampaigns(arr)
    }

    const refreshApp = async (app) => {
        await getSites(app.code, true)
        await getSocials()
    }

    const addAccount = async (app, url) => {
        setAdding(r => ({ ...r, [app.code]: true }))
        openPopup(url)
        setAdding(r => ({ ...r, [app.code]: false }))
    }

    const openPopup = function (url) {
        // Define the size and position of the popup window
        const width = 1400;
        const height = 670;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        // Open the popup window
        window.open(url, '', `height=${height},width=${width},left=${left},top=${top},toolbar=no,menubar=no,status=no`);
        startTimer()
    }

    const startTimer = () => {
        if (timer.current) clearInterval(timer.current)
        timer.current = setInterval(() => getApps(), 2000)
    }

    const getApps = async () => {
        if (!apps) {
            const res = await axios.get('/application')
            const newApps = res.data.Applications.filter(a => SupportedApps.some(sa => sa.id === a.id))
            if (!apps || apps.length !== newApps.length) {
                setApps(newApps)
                setAdding({})
                if (timer.current) clearInterval(timer.current)
            }
        }
    }

    useEffect(() => {
        getApps()

        return function () {
            if (timer.current) clearTimeout(timer.current)
        }
    }, [])

    useEffect(() => {
        if (apps) {
            getLinks()
            for (const app of apps) {
                const baseApp = SupportedApps.find(a => a.id === app.id)
                if (baseApp) refreshApp(baseApp)
            }
        }
    }, [apps])

    useEffect(() => {
        if (campaigns?.length && selected === null) {
            for (const app of SupportedApps) {
                const found = campaigns.find(c => c.options.AppCode === app.code)
                if (found) {
                    setSelected(found.id + '')
                    break;
                }
            }
            axios.get('/accountMaster/application').then(res => setAccApps(res.data.Applications))
            getSocials()
        }
    }, [campaigns])

    useEffect(() => {
        if (socialAccounts?.length) {
            let updated = false
            const arr = [...campaigns]
            for (const campaign of arr) {
                if (!campaign.options.SocialAccountId) {
                    const socialAccount = socialAccounts.find(a => a.type === campaign.options.AppCode)
                    if (socialAccount) {
                        campaign.options.SocialAccountId = socialAccount.id
                        updated = true
                    }
                }
            }
            if (updated) setCampaigns(arr)
        }
    }, [socialAccounts])

    const selectedCampaign = selected && campaigns.length ? campaigns.find(c => c.id + '' === selected) : null
    const submitDisabled = campaigns.length && campaigns.some(c => c.options.AppCode === null || c.options.SocialAccountId === null)

    return (
        <div>
            <UILIB.Row style={{ marginBottom: 80 }}>
                <UILIB.Column xs={12} sm={12} md={12} lg={6}>
                    <h4 className="mar-b5">{i18n.t('social:customise.header')}</h4>
                    <p className="mar-b20">{i18n.t('social:customise.subheader')}</p>

                    {selected !== null && !!socialAccounts && !!accApps && <UILIB.MultiAccordian
                        items={
                            SupportedApps.filter(a => campaigns.some(c => c.options.AppCode === a.code)).map(app => {
                                const found = campaigns.find(c => c.options.AppCode === app.code)
                                const accounts = socialAccounts.filter(s => s.type === app.code)
                                const socialAccount = found ? accounts.find(s => s.id === found.options.SocialAccountId) : null
                                const error = errors.find(e => e.CampaignId === found.id)
                                const link = popupLinks?.[app.id]

                                return {
                                    header: <div className="quickFlex" style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                        <div className="quickFlex" style={{ alignItems: 'center' }}>
                                            <UILIB.Icons icon={app.icon} style={{ height: 20, width: 20 }} />
                                            <div className="mar-l10">{i18n.t('social:channels.' + app.code)}</div>
                                            {!!socialAccount && <UILIB.SquareChip className="mar-l10">{['INSTAGRAM', 'TWITTER'].includes(app.code) && '@'}{socialAccount.name}</UILIB.SquareChip>}
                                        </div>
                                        <div>
                                            {!!error && <UILIB.Icons icon="warning" color="#C13110" style={{ height: 20, width: 20 }} />}
                                        </div>
                                    </div>,
                                    content: !!found && <div>
                                        <div className="quickFlex mar-b15" style={{ alignItems: 'flex-end' }}>
                                            <div style={{ flexGrow: 1 }}>
                                                {!!accounts.length && <UILIB.Select
                                                    label="Page to publish to"
                                                    outerstyle={{ marginRight: 12 }}
                                                    data={accounts.map(a => ({ label: a.name, value: a.id, iconRight: <UILIB.Avatar src={a.image} style={{ height: 20, width: 20 }} /> }))}
                                                    value={found.options.SocialAccountId} name="SocialAccountId" error={error?.account} onChange={e => updateCampaignOptions(found, e)}
                                                />}
                                                {!accounts.length && <UILIB.TextInput label="Page to publish to" outerStyle={{ marginRight: 12 }} value={`No ${i18n.t('social:channels.' + app.code)} pages found`} readOnly={true} />}
                                            </div>
                                            {/* <UILIB.Button className="button-input button-primary mar-r10" onClick={() => refreshApp(app)} disabled={refreshing[app.code]}>{i18n.t('social:customise.refresh')}</UILIB.Button> */}
                                            {!!link && <UILIB.Button className="button-input button-primary" target="_blank" onClick={() => addAccount(app, link)} disabled={adding[app.code]}>Add Account</UILIB.Button>}
                                        </div>
                                        <CustomiseApp app={app} campaign={found} original={campaign.Template.templateJson.content} updateTemplate={updateTemplate} optimise={() => optimise(app.name, found)} error={error} />
                                    </div>,
                                    id: found.id + ''
                                }
                            })
                        }
                        active={selected}
                        setActive={setSelected}
                    />}

                    {selected === null || !socialAccounts || !accApps && <UILIB.LoadingIcons />}
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={12} lg={6}>
                    {!!selectedCampaign && <UILIB.Paper className="paper-grey col-lg-height-100" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <SocialPreview
                            SocialAccountId={selectedCampaign.options.SocialAccountId}
                            text={selectedCampaign.Template.templateJson.content}
                            images={selectedCampaign.Template.templateJson.images}
                            provider={SupportedApps.find(a => a.code === selectedCampaign.options.AppCode)}
                        />
                    </UILIB.Paper>}
                </UILIB.Column>
            </UILIB.Row>
            <UILIB.StickyNavBottom>
                <div className="quickFlex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                    <div className="quickFlex" style={{ alignItems: 'center' }}>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} className={'button-primary mar-r10'} onClick={() => save(`/cp/campaigns/add/social/${campaign.id}/2`, true)}>{i18n.t('social:common.back')}</UILIB.Button>
                        <UILIB.Button onClick={() => save('/cp/campaigns', true)}>{i18n.t('campaigns:standard.saveExit')}</UILIB.Button>
                    </div>
                    <div className="quickFlex" style={{ alignItems: 'center' }}>
                        {!!errors.length && <div className="text-red text-heavy mar-r10">{i18n.t('social:customise.errors.resolve')}</div>}
                        <UILIB.Button iconRight={<UILIB.Icons icon="arrowRight" />} className={'button-primary mar-l5'} disabled={submitDisabled} onClick={() => save(`/cp/campaigns/add/social/${campaign.id}/4`)}>{i18n.t('campaigns:standard.nextStep')}</UILIB.Button>
                    </div>
                </div>
            </UILIB.StickyNavBottom >
        </div>
    )
}

function CustomiseApp({ app, campaign, original, error, updateTemplate = () => { } }) {
    const site = useSelector(state => state.siteMaster)
    const dispatch = useDispatch()
    console.log(error)

    const openImageSelector = () => {
        const drawerContent = <FileManager fileTypes={"images"} system={false} onFileClick={e => {
            const arr = [...campaign.Template.templateJson.images]
            arr.push(e)
            updateTemplate(campaign, { target: { name: 'images', value: arr } })
            dispatch(alterSiteDrawer(false, true, 'right', null))
        }} />
        dispatch(alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'fix-height'));
    }

    const removeImage = (index) => {
        const arr = [...campaign.Template.templateJson.images]
        arr.splice(index, 1)
        updateTemplate(campaign, { target: { name: 'images', value: arr } })
    }

    const editImage = (index) => {
        const image = campaign.Template.templateJson.images[index]
        launchAviary(null, null, async newUrl => {
            const arr = [...campaign.Template.templateJson.images]
            const meta = await getMeta(newUrl)
            arr[index] = { ...arr[index], url: newUrl, imageWidth: meta.width, imageHeight: meta.height }
            updateTemplate(campaign, { target: { name: 'images', value: arr } })
        }, image.url || image, 'transform', app.code)
    }

    const optimise = () => {
        const drawerContent = <AiOptimise content={campaign.Template.templateJson.content} app={app} useResult={result => {
            result = result.replace(/<br\/>/gi, '\n')
            updateTemplate(campaign, { target: { name: 'content', value: result } })
            dispatch(alterSiteDrawer(false, true, 'right', null))
        }} />
        dispatch(alterSiteDrawer(true, false, "right", drawerContent, true, 600, 'drawer_dark'));
    }

    const convert = (index) => {
        const image = campaign.Template.templateJson.images[index]
        const drawerContent = <Convert currentFormat={image.type || image.fileExtension} url={image.url} allowedFormats={app.formats} finished={newFile => {
            dispatch(alterSiteDrawer(false, true, "right", null))
            const arr = [...campaign.Template.templateJson.images]
            arr[index] = newFile
            updateTemplate(campaign, { target: { name: 'images', value: arr } })
        }} />
        dispatch(alterSiteDrawer(true, true, "right", drawerContent, true, 500));
    }

    const reset = () => {
        updateTemplate(campaign, { target: { name: 'content', value: original } })
    }

    const warnings = []
    const errors = []
    if (app.code === 'INSTAGRAM') {
        if (findLinks(campaign.Template.templateJson.content).length) {
            warnings.push(<div className="quickFlex" style={{ alignItems: 'center' }}>
                <div style={{ display: 'block', flexGrow: 1 }}>
                    <div className="text-heavy">{i18n.t('social:customise.warnings.instagramHeader')}</div>
                    <div>{i18n.t('social:customise.warnings.instagramSubheader')}</div>
                </div>
                <UILIB.Button className="button-sml button-lighten" href="https://kb.transpond.io/article/163-social-campaigns" target="_blank">{i18n.t('social:common.learnMore')}</UILIB.Button>
            </div>)
        }

        if (!campaign.Template.templateJson.images?.length) {
            errors.push(<div className="quickFlex" style={{ alignItems: 'center' }}>
                <div style={{ display: 'block', flexGrow: 1 }}>
                    <div className="text-heavy">{i18n.t('social:customise.errors.instagramImageHeader')}</div>
                    {/* <div>{i18n.t('social:customise.warnings.instagramImageSubheader')}</div> */}
                </div>
            </div>)
        }
    }

    const images = campaign.Template.templateJson.images || []
    if (app.dimensions && images.length && images[0].imageWidth && images[0].imageHeight) {
        if (app.dimensions[0] !== images[0].imageWidth || app.dimensions[1] !== images[0].imageHeight) {
            warnings.push(<div className="quickFlex" style={{ alignItems: 'center' }}>
                <div className="text-heavy" style={{ flexGrow: 1 }}>
                    {i18n.t('social:customise.warnings.optimise', { app: i18n.t('social:channels.' + app.code), x: app.dimensions[0], y: app.dimensions[1] })}
                </div>
                <UILIB.Button className="button-sml button-lighten" onClick={() => editImage(0)}>{i18n.t('social:customise.warnings.resize')}</UILIB.Button>
            </div>)
        }
    }

    if (app.formats && images.length && (images[0].type || images[0].fileExtension)) {
        const type = images[0].type || images[0].fileExtension
        if (!app.formats.includes(type.toUpperCase())) {
            errors.push(<div className="quickFlex" style={{ alignItems: 'center' }}>
                <div style={{ display: 'block', flexGrow: 1 }}>
                    <div className="text-heavy">{i18n.t('social:customise.warnings.formatHeader', { app: i18n.t('social:channels.' + app.code) })}</div>
                    <div dangerouslySetInnerHTML={{
                        __html: i18n.t('social:customise.warnings.formatSubheader', {
                            formats: app.formats.join(', '),
                            format: type.toUpperCase()
                        })
                    }}></div>
                </div>
                <UILIB.Button className="button-sml button-lighten" onClick={() => convert(0)}>{i18n.t('social:customise.warnings.reformat')}</UILIB.Button>
            </div>)
        }
    }

    if (app.fileLimit && images.length && images[0].size > app.fileLimit) {
        errors.push(<div className="quickFlex" style={{ alignItems: 'center' }}>
            <div style={{ display: 'block', flexGrow: 1 }}>
                <div className="text-heavy">{i18n.t('social:customise.errors.fileSize', { appName: i18n.t('social:channels.' + app.code), limit: humanizeBytes(app.fileLimit) })}</div>
            </div>
        </div>)
    }

    const overLength = campaign.Template.templateJson.content.length > app.limit

    return (
        <div>
            <UILIB.TextArea
                id={campaign.id + 'content'}
                emoji={true}
                rows="8" label="Text"
                labelOptions={campaign.Template.templateJson.content !== original ? <a onClick={reset}>{i18n.t('social:customise.reset')}</a> : ''}
                maxlength={app.limit}
                style={{ resize: 'vertical' }}
                value={campaign.Template.templateJson.content}
                name="content"
                onChange={e => updateTemplate(campaign, e)}
                error={error?.error}
                errorClass="text-sml mar-t0"
                ai={true} aiClick={optimise} aiPrompt={i18n.t('social:customise.optimise', { app: i18n.t('social:channels.' + app.code) })}
            />
            <div className={"text-xsml mar-b15 " + (overLength ? 'text-red' : 'text-grey')}>
                {FormatNumberFuncs.formatNumber(campaign.Template.templateJson.content.length)} / {FormatNumberFuncs.formatNumber(app.limit)}
            </div>
            <div className="txtInput_label">{i18n.t('social:content.media')}</div>
            <div className="quickFlex" style={{ alignItems: 'center' }}>
                {campaign.Template.templateJson.images.map((image, index) => <SocialImageSelector key={index} image={image} onRemove={() => removeImage(index)} onEdit={() => editImage(index)} />)}
                {!campaign.Template.templateJson.images.length && <SocialImageSelector onAdd={openImageSelector} />}
            </div>
            {warnings.map((warning, index) => {
                let classes = "paper-warning mar-b0 text-sml"
                if (index) classes += " mar-t10"
                else classes += " mar-t20"
                return <UILIB.Paper key={"warning_" + index} className={classes}>
                    <div className="quickFlex">
                        <UILIB.Icons icon="warning" color="#E2AB1E" style={{ width: 20, height: 20 }} className="mar-r10" />
                        <div style={{ flexGrow: 1 }}>{warning}</div>
                    </div>
                </UILIB.Paper>
            })}
            {errors.map((warning, index) => {
                let classes = "paper-error mar-b0 text-sml"
                if (index || warnings.length) classes += " mar-t10"
                else if (!warnings.length) classes += " mar-t20"
                return <UILIB.Paper key={"warning_" + index} className={classes}>
                    <div className="quickFlex">
                        <UILIB.Icons icon="warning" color="#E7745A" style={{ width: 20, height: 20 }} className="mar-r10" />
                        <div style={{ flexGrow: 1 }}>{warning}</div>
                    </div>
                </UILIB.Paper>
            })}
        </div >
    )
}

function AiOptimise({ content, app, useResult = () => { } }) {
    const account = useSelector(state => state.accountMaster)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState('')
    const [error, setError] = useState('')

    const optimise = async () => {
        try {
            setIsLoading(true)
            const res = await axios.post('/ai/convertpost', {
                content, provider: app.code
            })
            setResult(res.data.text)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            if (err && err.data && err.data.error && err.data.error.err) {
                const permissions = TierPermissions('users', account)
                const errMessage = <UILIB.TierLimitHolder
                    type="AI Lookup"
                    darkmode={true}
                    err={err.data.error.err}
                    allowedNumber={err.data.error.allowedNumber}
                    tierName={permissions.tierName}
                />
                setError(errMessage)
            } else {
                const errMessage = <UILIB.WarningHolder darkmode={true}>{err.data.error}</UILIB.WarningHolder>
                setError(errMessage)
            }
        }
    }

    const cancel = () => {
        dispatch(alterSiteDrawer(false, true, 'right', null))
    }

    return (
        <>
            <UILIB.Button className="button-darkmode-trans mar-b20" text={i18n.t('templates:aiContentAssistant.close')} iconLeft={<UILIB.Icons icon="cross" color="#FFFFFF" />} onClick={cancel} />
            <h3 className="mar-b25" style={{ display: "flex", alignItems: "center" }}>
                <UILIB.Icons icon="sparkle2" style={{ width: "30px", height: "35px" }} className="mar-r10" />
                <div>{i18n.t('templates:aiContentAssistant.aiContentAssistant')}</div>
                <UILIB.SquareChip className="square-chip-blue mar-l10">{i18n.t('templates:aiContentAssistant.beta')}</UILIB.SquareChip>
            </h3>

            <p className="mar-b20">{i18n.t('social:customise.ai.content', { app: i18n.t('social:channels.' + app.code) })}</p>

            {!result && !isLoading && <>
                <div className="quickFlex">
                    <UILIB.Button className="button-primary button-mediumbutton-darkmode  border-darkmode-purple-to-blue-horizontal" onClick={optimise}>{i18n.t('social:customise.ai.optimise')}</UILIB.Button>
                    <UILIB.Button className="button-darkmode-trans mar-l10" onClick={cancel}>{i18n.t('social:customise.ai.cancel')}</UILIB.Button>
                </div>
                {error}
            </>}

            {isLoading && <div style={{ flex: "1", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <UILIB.AiLoadingIcon style={{ height: "200px", width: "400px" }} />
                <div>{i18n.t('templates:aiContentAssistant.aiGeneratingContent')}...</div>
            </div>}

            {!!result && <div>
                <h4>{i18n.t('social:customise.ai.result')}:</h4>
                <div className="mar-b25 border-darkmode-light-to-dark" style={{ display: "flex", flexDirection: "column", padding: "15px" }}>

                    <div className="mar-b15 text-white" style={{ maxHeight: "calc(100vh - 400px)", border: "0", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: result }}>
                    </div>
                </div>
                <div className="quickFlex">
                    <UILIB.Button className="button-primary button-mediumbutton-darkmode  border-darkmode-purple-to-blue-horizontal" onClick={() => useResult(result)}>{i18n.t('social:customise.ai.use')}</UILIB.Button>
                    <UILIB.Button className="button-darkmode-trans mar-l10" onClick={cancel}>{i18n.t('social:customise.ai.cancel')}</UILIB.Button>
                </div>
            </div>}

            <div className="mar-t30">
                {i18n.t('templates:aiContentAssistant.aiFooter')}
            </div>
        </>
    )
}

function Convert({ url, currentFormat, allowedFormats, finished = () => { } }) {
    const [format, setFormat] = useState('')
    const [converting, setConverting] = useState(false)
    const [error, setError] = useState('')

    const convert = async () => {
        setError('')
        setConverting(true)
        try {
            const res = await axios.post('/fileManager/file/convert', {
                url, format
            })
            finished(res.data)
        } catch (e) {
            console.log(e)
            console.log(e.data)
            setError(e.data.error)
        }
        setConverting(false)
    }

    return (
        <div>
            <h4 className="mar-b5">{i18n.t('social:customise.convert.header')}</h4>
            <p className="mar-b15">
                {i18n.t('social:customise.convert.subheader', { currentFormat })}
            </p>
            <UILIB.Select data={allowedFormats} placeholder={i18n.t('social:customise.convert.select')} value={format} onChange={e => setFormat(e.target.value)} outerClassName="mar-b15" />
            <UILIB.Button className="button-primary" disabled={!format || converting} onClick={convert}>{i18n.t('social:customise.convert.convert')}</UILIB.Button>
            {converting && <UILIB.LoadingIcons className='mar-t10' />}
            {!!error && <UILIB.WarningHolder className="mar-t10">{error}</UILIB.WarningHolder>}
        </div>
    )
}

function humanizeBytes(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}
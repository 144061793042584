import React, { useEffect, useState } from 'react'
import axios from '../../../../../../data/http/axios'
import UILIB from '../../../../../../Common-Objects/Lib'
import DateTimeFunctions from '../../../../../../Classes/dateTimeFunctions'

export default function FailedImportLine({ failed, application }) {
    const [customFields, setCustomFields] = useState(null)
    useEffect(() => {
        axios.get('/customfield').then(res => setCustomFields(res.data.CustomFields))
    }, [])
    if (!customFields) return <UILIB.LoadingIcons iconType="2" />

    const nameField = customFields.find(cf => cf.fieldName === '_capsule_name')
    const name = nameField ? failed.raw[nameField.id] || '' : ''

    const addedField = customFields.find(cf => cf.fieldName === '_capsule_createdAt')
    const added = addedField ? failed.raw[addedField.id] || '' : ''

    const updatedField = customFields.find(cf => cf.fieldName === '_capsule_updatedAt')
    const updated = updatedField ? failed.raw[updatedField.id] || '' : ''

    const isCapsule = application && application.id && application.id == 8

    return (
        <div>
            <div className="form-group">
                <label className='no-marg'>Name</label>
                <p>{name || 'NONE'}</p>
                <label className='no-marg'>Email Address</label>
                <p>{failed.emailAddress || 'NONE'}</p>
                {isCapsule && <>
                    <label className='no-marg'>Added to Capule</label>
                    <p>{added ? DateTimeFunctions.formatDateTime(added) : 'UNKNOWN'}</p>
                    <label className='no-marg'>Updated in Capule</label>
                    <p>{updated ? DateTimeFunctions.formatDateTime(updated) : 'UNKNOWN'}</p>
                    <UILIB.Button text="Fix in Capsule" className="button-md" onClick={() => window.open(application.link + failed.ApplicationDataId, 'blank')} />
                    <p className='text-center'>If you fix this in capsule the change will automatically appear in Transpond</p>
                </>
                }
            </div>
        </div >
    )
}
import React, { useReducer, useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { wizardStepsReducer, initialWizardStepsState } from "~/Classes/wizard";
import TextFunctions from "~/Classes/textFunctions";
import i18n from "~/i18n";

import ScanArticlesStep from "./scanArticlesStep";

export default function AiAssistantWizard({ userRole = null }) {
  const { inboxId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [steps, updateSteps] = useReducer(
    wizardStepsReducer,
    initialWizardStepsState(4)
  );
  const [loading, setLoading] = useState(true);
  const [channels, setChannels] = useState([]);
  const [others, setOthers] = useState(null)
  const [aiName, setAiName] = useState("")
  const [contentSourceUrl, setContentSourceUrl] = useState("")
  const [checkingContentProvider, setCheckingContentProvider] = useState(false)
  const [contentProvider, setContentProvider] = useState(null)
  const [formErrors, setFormErrors] = useState({})
  const [aiId, setAiId] = useState(null)
  const [channelsSelected, updateChannelsSelected] = useState([]);

  useEffect(() => {
    if (userRole > 0) return window.open("/", "_self");
    init();
  }, []);

  const init = async () => {
    const channels = await axios.get(`/inboxes/${inboxId}/channel`);
    setChannels(channels.data);
    const _others = await axios.get(`/inboxes/${inboxId}/ai`);
    setOthers(_others.data);
    setLoading(false);
  }


  const handleStep1Submit = async () => {
    if (aiName.length < 3) {
      setFormErrors({ aiName: i18n.t("chat:settings.ai.wizard.nameMin") })
      return
    }
    let existingAis = await axios.get(`/inboxes/${inboxId}/ai`)
    existingAis = existingAis.data;
    let found = existingAis.find(ai => ai.name.toLowerCase() === aiName.toLowerCase())
    if (found) {
      setFormErrors({ aiName: i18n.t("chat:settings.ai.wizard.nameExists") })
      return
    }
    updateSteps({ type: "complete", index: 0 })
  }

  //content source stuff
  const checkContentProvider = async () => {
    if (!contentSourceUrl) return
    setCheckingContentProvider(true)
    setContentProvider("");
    const { data } = await axios.post(
      `/inboxes/${inboxId}/ai/detectprovider`,
      { url: contentSourceUrl }
    );
    setCheckingContentProvider(false)
    setContentProvider(data.provider)
  }

  const skipContentProvider = () => {
    setContentProvider("unknown")
    setContentSourceUrl("http://")
    updateSteps({ type: "complete", index: 1 })
  }

  const handleStep2Submit = async () => {
    try {
      updateSteps({ type: "complete", index: 1 })
    }
    catch (err) {

    }
  }

  const handleStep3Submit = async () => {
    //save it all
    let newAi = await axios.post(`/inboxes/${inboxId}/ai`, { name: aiName, channels: channelsSelected })
    newAi = newAi.data;
    //add sources
    await axios.post(`/inboxes/${inboxId}/ai/${newAi.id}/sources`, { url: contentSourceUrl, provider: contentProvider })
    setAiId(newAi.id);
    updateSteps({ type: "complete", index: 2 })

  }
  const handleStep4Submit = () => {
    history.push(`/cp/conversations/${inboxId}/ai-assistants/${aiId}/overview`)
  }

  const setChannelsSelected = (channelId) => {
    let newChannelsSelected = [...channelsSelected];
    if (newChannelsSelected.indexOf(channelId) > -1) {
      newChannelsSelected = newChannelsSelected.filter(id => id !== channelId)
    }
    else {
      newChannelsSelected.push(channelId)
    }
    updateChannelsSelected(newChannelsSelected)
  };

  const editDoneSteps = (index) => {
    Object.keys(steps).forEach((key) => {
      if (key == index) {
        updateSteps({ type: "collapse", index: key })
      }
      else {
        updateSteps({ type: "reset", index: key })
      }
    });
  }

  if (loading) return <UILIB.LoadingIcons />


  return (
    <div className="wizard-page connect-email-channel-page">
      <a onClick={() => history.goBack()} className="wizard-page__close">
        <UILIB.Icons icon="cross" color="black" />
      </a>

      <div className="wizard-page__inner">
        <div className="center-xs mar-b60">
          <h1 className="h2 mar-b10">{i18n.t("chat:settings.ai.wizard.header")}</h1>
          <p className="text-md text-grey">
            {i18n.t("chat:settings.ai.wizard.subheader")}
          </p>
        </div>

        <ol className="faux-list">
          <li>
            <UILIB.WizardStep heading={i18n.t("chat:settings.ai.wizard.step1.header")} onClick={aiId ? undefined : () => { editDoneSteps(0) }} {...steps[0]}>
              <p className="text-grey">{i18n.t("chat:settings.ai.wizard.step1.subheader")}</p>

              <UILIB.TextInput type="text" label={i18n.t("chat:settings.ai.wizard.step1.name")} name="name" value={aiName} onEnterPress={handleStep1Submit} placeholder={i18n.t("chat:settings.ai.wizard.step1.placeHolder")} onChange={(e) => setAiName(e.target.value)} error={formErrors?.aiName} />

              <UILIB.Button className="button-primary mar-t18" onClick={handleStep1Submit} disabled={aiName.length < 3}>{i18n.t("chat:settings.ai.wizard.step1.next")}</UILIB.Button>
            </UILIB.WizardStep>
          </li>
          <li>
            <UILIB.WizardStep heading={i18n.t("chat:settings.ai.wizard.step2.header")} onClick={aiId ? undefined : () => {
              editDoneSteps(1);
            }} {...steps[1]}>
              <p className="text-grey">{i18n.t("chat:settings.ai.wizard.step2.subheader")}</p>
              <p className="text-grey">{i18n.t("chat:settings.ai.wizard.step2.subheader2")}</p>
              {(checkingContentProvider) && <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }} className="mar-t25 mar-b25">
                <UILIB.LoadingIcons type={2} style={{ width: 20, height: 20 }} className="mar-b15" />
                <div>{i18n.t("chat:settings.ai.wizard.step2.checking")}</div>
              </div>}
              {(!checkingContentProvider) && <>

                <div style={contentProvider ? { display: "flex", justifyContent: "space-between", alignItems: "center" } : undefined}>
                  <UILIB.TextInput
                    label={i18n.t("chat:settings.ai.wizard.step2.contentSourceUrl")}
                    placeholder={"https://my.knowledgebase.com"}
                    value={contentSourceUrl}
                    error={formErrors?.contentSourceUrl}
                    onChange={(e) => setContentSourceUrl(e.target.value)}
                    outerStyle={{ flex: 1 }}
                    onEnterPress={checkContentProvider}

                  />
                  <div><UILIB.Button
                    className={"button-primary mar-t18" + (contentProvider ? " mar-l10" : "")}
                    onClick={checkContentProvider}
                    disabled={contentSourceUrl.length < 3}
                  >{contentProvider ? i18n.t("chat:settings.ai.wizard.step2.change") : i18n.t("chat:settings.ai.wizard.step2.continue")}</UILIB.Button>

                    {!contentProvider &&
                      <UILIB.Button
                        className={"button-secondary mar-t18 mar-l10"}
                        onClick={skipContentProvider}
                        text={i18n.t("chat:settings.ai.wizard.step2.skipThisStep")}
                      />
                    }

                  </div>

                </div>
              </>
              }
              {contentProvider && <>
                <UILIB.Paper className="paper-dotted mar-t20 mar-b20">
                  {contentProvider === "unknown" && <>
                    <div >
                      <h3 className="text-md mar-b4">
                        {i18n.t("chat:settings.ai.wizard.step2.unknown")}
                      </h3>

                      <p className="mar-b0">
                        {i18n.t("chat:settings.ai.wizard.step2.unknownDesc")}
                      </p>
                    </div></>}
                  {contentProvider !== "unknown" && <>
                    <div >
                      <h3 className="text-md mar-b4">
                        {i18n.t("chat:settings.ai.wizard.step2.recog", { provider: TextFunctions.capitalizeFirstLetter(contentProvider) })}
                      </h3>

                      <p className="mar-b0">
                        {i18n.t("chat:settings.ai.wizard.step2.recogDesc", { provider: TextFunctions.capitalizeFirstLetter(contentProvider) })}
                      </p>
                    </div>
                  </>}
                </UILIB.Paper>
                <UILIB.Button className="button-primary mar-t18" onClick={handleStep2Submit}>{i18n.t("chat:settings.ai.wizard.step2.next")}</UILIB.Button>
              </>
              }

            </UILIB.WizardStep>
          </li>
          <li>
            <UILIB.WizardStep heading={i18n.t("chat:settings.ai.wizard.step3.header")} onClick={aiId ? undefined : () => {
              editDoneSteps(2);
            }} {...steps[2]}>
              <p className="text-grey">
                {i18n.t("chat:settings.ai.wizard.step3.subheader")}
              </p>
              {channels.map((channel) => {
                let disabled = others.some(other => other.settings?.channels?.length && other.settings.channels.indexOf(channel.id) > -1)
                return <UILIB.CheckBox
                  key={"chan_" + channel.id}
                  value={channel.id}
                  disabled={disabled}
                  checked={channelsSelected.indexOf(channel.id) > -1}
                  onChange={() => { setChannelsSelected(channel.id) }}
                  outerClassName="mar-b12"
                >
                  {channel.name} {disabled ? <UILIB.SquareChip className="grey mar-l10">{i18n.t("chat:ai.channels.assigned")}</UILIB.SquareChip> : ""}
                </UILIB.CheckBox>


              })}
              <UILIB.Button className="button-primary mar-t18" disabled={channelsSelected.length < 1} onClick={handleStep3Submit}>{i18n.t("chat:settings.ai.wizard.step3.next")}</UILIB.Button>
            </UILIB.WizardStep>
          </li>
          <li>
            <UILIB.WizardStep heading={i18n.t("chat:settings.ai.wizard.step4.header")} onClick={aiId ? undefined : () => {
              editDoneSteps(3);
            }} {...steps[3]}>
              <UILIB.Paper className="mar-b0">
                <ScanArticlesStep inboxId={inboxId} aiId={aiId} contentProvider={contentProvider} />
              </UILIB.Paper>
              <UILIB.Button className="button-primary" onClick={handleStep4Submit}>{i18n.t("chat:settings.ai.wizard.step4.continue")}</UILIB.Button>
            </UILIB.WizardStep>
          </li>



        </ol>
      </div>
    </div >
  );
}
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import BrandAnalyzerImg from "~/assets/images/illustrations/brand-analyzer.svg";
import CreateTemplateImg from "~/assets/images/illustrations/create-template.svg";
import AddContactsImg from "~/assets/images/illustrations/add-contacts.svg";
import SendCampaignImg from "~/assets/images/illustrations/send-campaign.svg";
import i18n from "~/i18n";

export default function ChecklistPanel({
  completedTasks = {},
  className,
  ...rest
}) {
  const [nextUncompletedTask, setNextUncompletedTask] = useState(null);

  useEffect(() => {
    for (const [key, value] of Object.entries(completedTasks)) {
      if (value === false) {
        setNextUncompletedTask(key);
        return;
      }
    }
  }, [completedTasks]);

  const classes = classnames(
    "frontpage-checklist-panel faux-list grid",
    className
  );

  return (
    <ol className={classes} {...rest}>
      <li>
        <UILIB.IllustrationCard
          className="illustration-card--purple"
          img={<img src={BrandAnalyzerImg} alt="" />}
          heading={i18n.t(
            "dashboard:gettingStarted.checklist.brandAnalyzer.heading"
          )}
          cta={
            <GettingStartedCta
              to="/cp/brandanalyzer"
              iconLeft={<UILIB.Icons icon="palette" />}
              completed={completedTasks?.brandAnalyzer}
              completeText={i18n.t(
                "dashboard:gettingStarted.checklist.brandAnalyzer.completedCta"
              )}
              highlight={nextUncompletedTask === "brandAnalyzer"}
            >
              {i18n.t("dashboard:gettingStarted.checklist.brandAnalyzer.cta")}
            </GettingStartedCta>
          }
        >
          <p>
            {i18n.t("dashboard:gettingStarted.checklist.brandAnalyzer.body")}
          </p>
        </UILIB.IllustrationCard>
      </li>

      <li>
        <UILIB.IllustrationCard
          className="illustration-card--green"
          img={<img src={CreateTemplateImg} alt="" />}
          heading={i18n.t(
            "dashboard:gettingStarted.checklist.createTemplate.heading"
          )}
          cta={
            <GettingStartedCta
              to="/cp/templates/add"
              iconLeft={<UILIB.Icons icon="pencil" />}
              completed={completedTasks?.createTemplate}
              completeText={i18n.t(
                "dashboard:gettingStarted.checklist.createTemplate.completedCta"
              )}
              highlight={nextUncompletedTask === "createTemplate"}
            >
              {i18n.t("dashboard:gettingStarted.checklist.createTemplate.cta")}
            </GettingStartedCta>
          }
        >
          <p>
            {i18n.t("dashboard:gettingStarted.checklist.createTemplate.body")}
          </p>
        </UILIB.IllustrationCard>
      </li>

      <li>
        <UILIB.IllustrationCard
          className="illustration-card--yellow"
          img={<img src={AddContactsImg} alt="" />}
          heading={i18n.t(
            "dashboard:gettingStarted.checklist.addContacts.heading"
          )}
          cta={
            <GettingStartedCta
              to="/cp/subscribers"
              iconLeft={<UILIB.Icons icon="avatars3" />}
              completed={completedTasks?.addContacts}
              completeText={i18n.t(
                "dashboard:gettingStarted.checklist.addContacts.completedCta"
              )}
              highlight={nextUncompletedTask === "addContacts"}
            >
              {i18n.t("dashboard:gettingStarted.checklist.addContacts.cta")}
            </GettingStartedCta>
          }
        >
          <p>{i18n.t("dashboard:gettingStarted.checklist.addContacts.body")}</p>
        </UILIB.IllustrationCard>
      </li>

      <li>
        <UILIB.IllustrationCard
          className="illustration-card--blue"
          img={<img src={SendCampaignImg} alt="" />}
          heading={i18n.t(
            "dashboard:gettingStarted.checklist.sendCampaign.heading"
          )}
          cta={
            <GettingStartedCta
              to="/cp/campaigns"
              iconLeft={<UILIB.Icons icon="megaphone" />}
              completed={completedTasks?.sendCampaign}
              completeText={i18n.t(
                "dashboard:gettingStarted.checklist.sendCampaign.completedCta"
              )}
              highlight={nextUncompletedTask === "sendCampaign"}
            >
              {i18n.t("dashboard:gettingStarted.checklist.sendCampaign.cta")}
            </GettingStartedCta>
          }
        >
          <p>
            {i18n.t("dashboard:gettingStarted.checklist.sendCampaign.body")}
          </p>
        </UILIB.IllustrationCard>
      </li>
    </ol>
  );
}

function GettingStartedCta({
  iconLeft,
  completed,
  completeText,
  highlight,
  children,
  ...rest
}) {
  const classes = classnames({
    "button-primary": highlight,
    "button-complete": completed,
  });

  return (
    <UILIB.Button
      className={classes}
      iconLeft={completed ? <UILIB.Icons icon="tickCircle" /> : iconLeft}
      {...rest}
    >
      {completed ? completeText : children}
    </UILIB.Button>
  );
}

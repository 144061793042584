import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'

const useNotificationApi = ({ disable = false } = {}) => {
    const [permission, setPermission] = useState('Notification' in window && Notification.permission)
    const [loading, setLoading] = useState(false)
    const site = useSelector(state => state.siteMaster)

    useEffect(() => {

    }, [])

    const requestPermission = async () => {
        if (Notification && permission !== 'granted' && !disable) {
            const permission = await Notification.requestPermission();
            setPermission(permission)
            setLoading(false)
        }
    }

    const clearPermission = () => {
        setPermission('default');
    };

    const trigger = async (title, options) => {
        if (permission === 'granted') {
            const result = await new Notification(title, options);
            return result
        }
    };

    return {
        permission,
        loading,
        requestPermission,
        clearPermission,
        trigger
    };
};

export default useNotificationApi;

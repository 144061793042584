import React from 'react'

export default function IconMegaphone({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 3.00008C17.9999 2.82969 17.9563 2.66214 17.8733 2.51335C17.7902 2.36455 17.6706 2.23944 17.5256 2.14988C17.3806 2.06032 17.2152 2.00929 17.045 2.00162C16.8748 1.99396 16.7054 2.02991 16.553 2.10608L8.763 6.00008H5C4.20435 6.00008 3.44129 6.31615 2.87868 6.87876C2.31607 7.44137 2 8.20443 2 9.00008C2 9.79573 2.31607 10.5588 2.87868 11.1214C3.44129 11.684 4.20435 12.0001 5 12.0001H5.28L7.051 17.3161C7.11735 17.5153 7.24472 17.6886 7.41505 17.8113C7.58538 17.9341 7.79004 18.0001 8 18.0001H9C9.26522 18.0001 9.51957 17.8947 9.70711 17.7072C9.89464 17.5196 10 17.2653 10 17.0001V12.6181L16.553 15.8941C16.7054 15.9702 16.8748 16.0062 17.045 15.9985C17.2152 15.9909 17.3806 15.9398 17.5256 15.8503C17.6706 15.7607 17.7902 15.6356 17.8733 15.4868C17.9563 15.338 17.9999 15.1705 18 15.0001V3.00008Z" fill={fillColor} />
        </svg>
    )
}
import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from '../../../../../../data/http/axios'
import UILIB from '../../../../../../Common-Objects/Lib'
import { SupportedApps } from '../../../../campaigns/social/create/shared'
import i18n from '../../../../../../i18n'
import DateTimeFunctions from '../../../../../../Classes/dateTimeFunctions'
import FormatNumberFuncs from '../../../../../../Classes/numberFormatFunctions'
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area, CartesianGrid, ReferenceDot } from 'recharts';

export default function SocialHistory({ socialAccount }) {
    const { socialAccountId } = useParams()
    const site = useSelector(state => state.siteMaster)
    const history = useHistory()
    const [account, setAccount] = useState(null)
    const [histories, setHistory] = useState(null)
    const [campaigns, setCampaigns] = useState(null)

    useEffect(() => {
        if (socialAccountId) {
            axios.get('/social/' + socialAccountId).then(res => setAccount(res.data))
        }
    }, [socialAccountId])

    useEffect(() => {
        if (socialAccount) {
            axios.get('/social/' + socialAccount.id).then(res => setAccount(res.data))
        }
    }, [socialAccount])

    useEffect(() => {
        if (account) {
            getHistory()
            getCampaigns()
        }
    }, [account])

    const getHistory = async () => {
        const res = await axios.get(`/social/${account.id}/history`)
        setHistory(res.data.reverse())
    }

    const getCampaigns = async () => {
        const res = await axios.get(`/social/${account.id}/campaigns`)
        setCampaigns(res.data)
    }

    if (!account) return <UILIB.LoadingIcons />

    const app = SupportedApps.find(a => a.code === account.type)

    const graphData = histories ? histories.map(row => {
        return {
            date: DateTimeFunctions.formatDateTime(row.createdAt, 2),
            followers: row.followers,
            impressions: row.impressions,
            engagement: row.engagement
        }
    }) : null

    let customLegendData = {}
    if (campaigns?.length) {
        for (const campaign of campaigns) {
            if (campaign.status !== 'Finished') continue
            const date = DateTimeFunctions.formatDateTime(campaign.startDate, 2)
            if (!customLegendData[date]) customLegendData[date] = []
            customLegendData[date].push({
                id: campaign.id,
                name: campaign.campaignName
            })
        }
    }

    for (const key in customLegendData) {
        customLegendData[key] = <div style={{ color: '#333', fontSize: 12 }}>{customLegendData[key].map((row) => <div key={key + '_' + row.id}>{row.name}</div>)}</div>
    }

    let header = 'Follower'
    if (graphData && graphData.some(d => d.impressions !== undefined)) header += ', Impressions and Engagement'

    console.log(site.colours)

    return (
        <div>
            <h3 className="mar-b25 quickFlex"><UILIB.Icons icon={app.icon} style={{ height: 30, width: 30, marginRight: 10 }} />{account.name} History</h3>
            <UILIB.Paper >
                {!!histories?.length && <h4 className="mar-b20">{header} History</h4>}
                {!histories && <UILIB.LoadingIcons />}
                {!!histories?.length && <ResponsiveContainer height={320}>
                    <AreaChart data={graphData} margin={{ top: 0, right: 0, left: 20, bottom: 0 }}>
                        <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                        <YAxis axisLine={false} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                        <CartesianGrid horizontal={false} strokeDasharray="2 2" stroke="#DADADC" />
                        <Tooltip content={<UILIB.CustomTooltip
                            classes="left"
                            name={["Followers", "Impressions", "Engagement"]}
                            showLabel={true}
                            followColor={true}
                            custom={customLegendData}
                        />} />
                        <defs>
                            <linearGradient id={"color_"} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={site.colours.$primary} stopOpacity={0.3} />
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                            </linearGradient>
                        </defs>
                        <defs>
                            <linearGradient id={"color_2"} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={site.colours['$status-blue']} stopOpacity={0.3} />
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                            </linearGradient>
                        </defs>
                        <defs>
                            <linearGradient id={"color_3"} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={site.colours['$status-green']} stopOpacity={0.3} />
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                            </linearGradient>
                        </defs>
                        <Area
                            name="Followers"
                            dot={false}
                            type="monotone"
                            dataKey="followers"
                            fill={`url(#color_`}
                            stroke={site.colours.$primary}
                            strokeWidth={2}
                            fillOpacity={1}
                            activeDot={{ stroke: site.colours.$primary, strokeWidth: 2, fill: 'white' }}
                        />
                        <Area
                            name="Impressions"
                            dot={false}
                            type="monotone"
                            dataKey="impressions"
                            fill={`url(#color_2`}
                            stroke={site.colours['$status-blue']}
                            strokeWidth={2}
                            fillOpacity={1}
                            activeDot={{ stroke: site.colours['$status-blue'], strokeWidth: 2, fill: 'white' }}
                        />
                        <Area
                            name="Engagement"
                            dot={false}
                            type="monotone"
                            dataKey="engagement"
                            fill={`url(#color_3`}
                            stroke={site.colours['$status-green']}
                            strokeWidth={2}
                            fillOpacity={1}
                            activeDot={{ stroke: site.colours['$status-green'], strokeWidth: 2, fill: 'white' }}
                        />

                        {!!campaigns?.length && campaigns.filter(c => c.status === 'Finished').map((row, index) => {
                            const data = graphData.find(d => d.date === DateTimeFunctions.formatDateTime(row.startDate, 2))
                            if (!data) return null
                            return <ReferenceDot
                                key={index}
                                isFront
                                x={DateTimeFunctions.formatDateTime(row.startDate, 2)}
                                y={data?.followers}
                                // y={0}
                                fill="red"
                                stroke="none"
                                shape={(props) => {

                                    return <svg x={props.cx - 10} y={props.cy - 10} width={20} height={20} >
                                        <clipPath id="circleClip">
                                            <circle cx="10" cy="10" r="10" fill="white" />
                                        </clipPath>
                                        <circle clip-path="url(#circleClip)" cx="10" cy="10" r="10" fill="#eee"
                                            stroke='#000000'
                                            strokeWidth='2' />
                                        <UILIB.Icons svgOnly={true} icon="share" />
                                    </svg>

                                }}
                            />
                        })}
                    </AreaChart>
                </ResponsiveContainer>}
                {!!histories && !histories.length && <div>No follower history found</div>}
            </UILIB.Paper>

            <UILIB.Paper >
                <h4>Recent Campaigns</h4>
                {!campaigns && <UILIB.LoadingIcons />}
                {!!campaigns && <UILIB.DataTable1
                    tableData={campaigns.slice(0, 30).map((row, index) => {
                        let link = '/cp/campaigns/viewsocial/' + row.id
                        if (row.status === "Draft") link = '/cp/campaigns/add/social/' + row.id
                        const obj = {
                            name: {
                                headerValue: 'Name',
                                value: <a onClick={() => history.push(link)}>{row.campaignName}</a>
                            },
                            date: {
                                headerValue: 'Start Date',
                                value: DateTimeFunctions.formatDateTime(row.startDate)
                            },
                            status: {
                                headerValue: 'Status',
                                value: <>
                                    {row.status === "Draft" && <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circle" />} className="small-icon">{i18n.t('campaigns:status.Draft')}</UILIB.SquareChip>}
                                    {row.status === "Awaiting Start" && <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circle" />} className="square-chip-blue small-icon">{i18n.t('campaigns:status.Awaiting Start')}</UILIB.SquareChip>}
                                    {row.status === "Queued" && <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circle" />} className="square-chip-purple small-icon">{i18n.t('campaigns:status.Queued')}</UILIB.SquareChip>}
                                    {(row.status === "Processing" || row.status === 'Checking') && <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circle" />} className="square-chip-purple small-icon">{i18n.t('campaigns:status.Processing')}</UILIB.SquareChip>}
                                    {row.status === "Ready for Sending" && <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circle" />} className="square-chip-purple small-icon">{i18n.t('campaigns:status.Ready for Sending')}</UILIB.SquareChip>}
                                    {row.status === "Sending" && <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circle" />} className="square-chip-purple small-icon">{i18n.t('campaigns:status.Sending')}</UILIB.SquareChip>}
                                    {row.status === "Paused" && <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circle" />} className="square-chip-yellow small-icon">{i18n.t('campaigns:status.Paused')}</UILIB.SquareChip>}
                                    {row.status === "UnPausing" && <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circle" />} className="square-chip-yellow small-icon">{i18n.t('campaigns:status.UnPausing')}</UILIB.SquareChip>}
                                    {row.status === "Finished" && <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-green small-icon">{i18n.t('campaigns:status.Finished')}</UILIB.SquareChip>}
                                    {row.status === "Errored" && <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circle" />} tooltip={row.error} for={'status' + index} className="square-chip-red small-icon">{i18n.t('campaigns:status.Errored')}</UILIB.SquareChip>}
                                </>
                            },
                            outreach: {
                                headerValue: 'Outreach',
                                value: FormatNumberFuncs.formatNumber(row.Campaign?.SocialPost?.outreach)
                            },
                            impressions: {
                                headerValue: 'Impressions',
                                value: FormatNumberFuncs.formatNumber(row.Campaign?.SocialPost?.impressions)
                            },
                            engagement: {
                                headerValue: 'Engagement',
                                value: FormatNumberFuncs.formatNumber(row.Campaign?.SocialPost?.engagement)
                            }
                        }
                        return obj
                    })}
                    noResultsTxt="No campaigns found"
                />}
            </UILIB.Paper>
        </div>
    )
}
import React from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import i18n from '~/i18n'
import Graph from './graph'
import ArrowDownPurple from '~/assets/images/icons/arrow-down-purple.svg';
import axios2 from 'axios'
import moment from 'moment';
import { connect } from 'react-redux';
import GenericStatsHolder from '../../../campaigns/views/shared/components/genericStatsHolder';
import ImpressionsDrawer from './impressionsDrawer';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
@connect((store) => {
    return {
        filters: store.filters,
        siteMaster: store.siteMaster,
        user: store.user
    }
})

export default class TrackerVisitors extends React.Component {

    constructor(props) {

        let timer;
        super(props);
        this.state = {
            loading: true,
            visitors: [],
            count: 0,
            page: 0,
            pageSize: 20,
            events: [],
            orderBy: 'lastVisit',
            orderDir: 'DESC',
            statusOption: 0,
            statusOptions: [{ label: i18n.t('campaigns:sitetracking.trackingvistors.allVisitors'), value: 0 }, { label: i18n.t('campaigns:sitetracking.trackingvistors.onlyRecognised'), value: 1 }, { label: i18n.t('campaigns:sitetracking.trackingvistors.onlyAnonymous'), value: 2 }],
            deviceOption: 0,
            deviceOptions: [
                { label: "All Devices", value: 0 },
                { label: "Desktop", value: 1 },
                { label: "Phone", value: 2 }
            ],
            sourceOption: 0,
            sourceOptions: [
                { label: "All Sources", value: 0 },
                { label: "Adwords", value: 1 },
                { label: "Facebook Ads", value: 2 },
                { label: "LinkedIn Ads", value: 3 }
            ],
            searchText: "",
            botToggle: true,
            loadingData: 1,
            eventId: 0,
            graphData: [],
            eventStats: [],
            detectedCampaigns: [],
            selectedUuid: ""
        }
        this.getVisitors = this.getVisitors.bind(this)
        this.tableUpdater = this.tableUpdater.bind(this)
        this.changeStatus = this.changeStatus.bind(this);
        this.changeDevice = this.changeDevice.bind(this);
        this.changeSearchText = this.changeSearchText.bind(this);
        this.changeToggle = this.changeToggle.bind(this);
        this.unmounting = false
        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();
        this.generateTable = this.generateTable.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.changeEventFilter = this.changeEventFilter.bind(this);
        this.openImpressionsDrawer = this.openImpressionsDrawer.bind(this);
    }

    componentDidMount() {
        this.getEvents()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate != this.props.startDate || prevProps.endDate != this.props.endDate) {
            this.setState({ loadingData: 1, page: 0 }, () => { this.getEvents() });
        }
    }

    componentWillUnmount() {
        this.unmounting = true
        if (this.timer) clearTimeout(this.timer)
    }

    async getEvents() {
        let events = await axios.get(`/accountMaster/tracker/events/${this.props.tracker.id}`)
        events = events.data.map(ev => { return { label: ev.name, value: ev.id } });
        events.unshift({ label: "All Events", value: 0 })
        this.setState({ events }, this.getVisitors)
    }

    async getVisitors(doExport) {
        if (this.state.loadingData) {
            this.source.cancel('I cancelled this')
            this.source = this.CancelToken.source();
        }

        if (this.timer) clearTimeout(this.timer);
        if (this.unmounting) return
        var searchText = this.state.searchText;
        if (searchText.length < 3) searchText = "";

        var queryStr = `?orderBy=${this.state.orderBy}&orderDir=${this.state.orderDir}&limit=${this.state.pageSize}&offset=${this.state.pageSize * this.state.page}` +
            `&startDate=${this.props.startDate.format("YYYY-MM-DD")}&endDate=${this.props.endDate.format("YYYY-MM-DD")}&filterVisitors=${this.state.statusOption}` +
            `&searchText=${searchText}&filterDevice=${this.state.deviceOption}&filterSource=${this.state.sourceOption}&eventId=${this.state.eventId}&uuid=${this.state.selectedUuid}`
        if (this.state.botToggle) queryStr += '&hideRobots=true'

        if (doExport) {
            queryStr += "&export=1"
            let response = await axios.get('/accountMaster/tracker/' + this.props.tracker.id + '/visitors' + queryStr, {
                responseType: 'blob',
                cancelToken: this.source.token
            })
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'trackingExport.xlsx');
            document.body.appendChild(link);
            link.click();
            this.setState({ loading: false })
        }
        else {
            let response = await axios.get('/accountMaster/tracker/' + this.props.tracker.id + '/visitors' + queryStr, {
                cancelToken: this.source.token
            })
            if (this.unmounting) return
            this.timer = setTimeout(this.getVisitors, 5000)

            let stats = response.data;
            let graphData = stats.graphData.map(row => {
                row.date = moment(row.str).locale(this.props.user.userData.language).format("DD MM YY")
                delete row.str;
                return row;
            })

            let detectedCampaigns = stats.detectedCampaigns.map(row => {
                row.date = moment(row.startDate).locale(this.props.user.userData.language).format("DD MM YY")
                return row
            })

            this.setState({
                loadingData: 0,
                visitorsRaw: response.data.TrackerLogs,
                eventStats: response.data.eventStats,
                count: response.data.count,
                graphData: graphData,
                detectedCampaigns: detectedCampaigns,
                loading: false
            }, this.generateTable);
        }
    }

    tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {

        if (isNaN(currentPage)) currentPage = this.state.page;
        this.setState({
            page: currentPage,
        }, this.generateTable)
    }

    generateTable() {

        let currentPage = this.state.page;
        let startRecord = currentPage * this.state.pageSize;
        let endRecord = startRecord + this.state.pageSize;
        let visitors = this.state.visitorsRaw.slice(startRecord, endRecord)

        let stats = {
            payments: 0,
            registrations: 0,
            totalVisitors: 0
        }
        visitors = visitors.map(row => {

            let deviceIcon = "alert";
            if (row.device == "desktop") deviceIcon = "pc";
            if (row.device == "phone") deviceIcon = "mobile";
            if (row.device == "bot") deviceIcon = "cog";


            var deviceTxt = <span>
                <UILIB.Icons icon={deviceIcon} alt={row.device} title={row.device} />
                {(row.closeCallCount && row.closeCallCount > 1) && <span className="mar-l5 icon-warning" style={{ color: "red" }} alt="Maybe Robot - Lots of clicks at same time" title="Maybe Robot - Lots of clicks at same time" />}
            </span>



            let events = [];
            if (row.eventHistory && row.eventHistory.length) {

                row.eventHistory.forEach(event => {
                    if (event.siteTrackerEventName) {
                        events.push(<UILIB.SquareChip className="mar-r5 mar-b5">{event.siteTrackerEventName}</UILIB.SquareChip>)
                    }
                })
            }

            let sources = [];
            if (row.siteTrackerSourceHistory && row.siteTrackerSourceHistory.length) {
                row.siteTrackerSourceHistory.forEach(source => {
                    if (source.AdCampaignId) {
                        sources.push(<>
                            <UILIB.Avatar
                                for={"AV_" + source.siteTrackerSourceId}
                                className="mar-r5 mar-b5"
                                style={{ display: "flex", height: 25, width: 25, backgroundColor: 'white', border: '1px solid grey' }}
                                src={source.adApplication.appLogoUri}

                                tooltip={source.adApplication.appName + ": " + source.campaignName}
                                onClick={() => { this.props.history.push('/cp/ads/campaign/' + source.AdCampaignId) }}
                            />
                        </>
                        )
                    }

                    if (source.CampaignId) {
                        sources.push(
                            <UILIB.Avatar
                                for={"AV_" + source.siteTrackerSourceId}
                                className="mar-r5 mar-b5"
                                style={{ display: "flex", height: 25, width: 25, backgroundColor: 'white', border: '1px solid grey' }}
                                iconSrc={<UILIB.Icons icon="envelope" style={{ height: 15, width: 15 }} />}
                                tooltip={source.campaignName}
                                onClick={() => { this.props.history.push('/cp/campaigns/view/' + source.CampaignId) }}
                            />

                        )
                    }

                })
            }


            let impressions = <a onClick={() => this.openImpressionsDrawer(row.uuid)}>{NumberFunctions.formatNumber(row.impressions)}</a>

            let rowOut = {
                lastVisit: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingvistors.lastVisit'),
                    value: DateTimeFunctions.formatDateTime(row.createdAt),
                    orderBy: false
                },
                subscriberId: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingvistors.subscriber'),
                    value: row.SubscriberId ? <a onClick={() => window.open('/cp/subscriber/' + row.SubscriberId, '_blank')} style={{ fontWeight: "bold" }}>{row.emailAddress}</a> : i18n.t('campaigns:sitetracking.trackingvistors.anonymous'),
                    orderBy: false
                },
                device: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingvistors.device'),
                    value: deviceTxt,
                    align: "center"
                },
                country: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingvistors.country'),
                    value: row.country
                },
                visits: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingvistors.visits'),
                    value: NumberFunctions.formatNumber(row.visits),
                    orderBy: false
                },

                impressions: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingvistors.impressions'),
                    value: impressions,
                    orderBy: false
                },
                userAgent: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingvistors.browser'),
                    value: row.userAgent,
                    orderBy: false
                },
                path: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingvistors.lastUrl'),
                    value: row.path
                },
                source: {
                    headerValue: this.state.selectedUuid ? "Source" : "Sources",
                    value: <div style={{ display: "flex" }}>{sources}</div>
                },
                eventName: {
                    headerValue: this.state.selectedUuid ? "Event" : "Events",
                    value: events
                }
            }
            if (this.state.selectedUuid) {
                delete rowOut.visits;
                delete rowOut.impressions;
            }
            return rowOut;
        })

        this.setState({ visitors, stats })
    }

    changeStatus(newStatus) {
        this.setState({ statusOption: newStatus, loadingData: 1 }, this.getVisitors)
    }
    changeDevice(newDevice) {
        this.setState({ deviceOption: newDevice, loadingData: 1 }, this.getVisitors)
    }

    changeSearchText(event) {
        this.setState({ searchText: event.target.value, loadingData: 1 }, () => {
            if (this.textTimer) clearTimeout(this.textTimer)
            this.textTimer = setTimeout(this.getVisitors, 300)
        });
    }
    changeSourceFilter(newSource) {
        this.setState({ sourceOption: newSource, loadingData: 1 }, this.getVisitors)
    }

    changeToggle(event) {

        this.setState({ [event.target.name]: event.target.checked, loadingData: 1 }, this.getVisitors)
    }
    changeEventFilter(eventId) {
        this.setState({ eventId: eventId }, this.getVisitors)
    }

    openImpressionsDrawer(uuid) {
        this.setState({ selectedUuid: uuid }, this.getVisitors)
    }
    render() {
        var visBetTxt = i18n.t('campaigns:sitetracking.trackingoverview.visitorsBetween');
        visBetTxt = visBetTxt.replace(/\[DATE1\]/g, DateTimeFunctions.formatDateTime(this.props.startDate, 2))
        visBetTxt = visBetTxt.replace(/\[DATE2\]/g, DateTimeFunctions.formatDateTime(this.props.endDate, 2))


        let registrationEventStats = 0;
        let paymentEventStats = 0;
        let paymentEvent = this.state.events.find(f => f.label == "Payment")
        let registrationEvent = this.state.events.find(f => f.label == "Registration")
        if (paymentEvent) {
            let found = this.state.eventStats.find(e => e.eventId == paymentEvent.value)
            if (found) paymentEventStats = found.count;
        }
        if (registrationEvent) {
            let found = this.state.eventStats.find(e => e.eventId == registrationEvent.value)
            if (found) registrationEventStats = found.count;
        }

        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} md={9} margin={0}>
                        <UILIB.Paper >
                            <p className="text-grey text-sml">{visBetTxt}</p>
                            <Graph
                                graphData={this.state.graphData}
                                height={375}
                                detectedCampaigns={this.state.detectedCampaigns}
                            />
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={0} md={3} margin={0} hide={["xs", "sm"]}>

                        <GenericStatsHolder header={"Registrations"} limit={this.state.count} limitText={"Visitors"} value={registrationEventStats} color={this.props.siteMaster.colours['$status-green']} />
                        <GenericStatsHolder header={"Payments"} limit={this.state.count} limitText={"Visitors"} value={paymentEventStats} color={this.props.siteMaster.colours['$status-yellow']} />
                    </UILIB.Column >

                    <UILIB.Column xs={12}>
                        <UILIB.Paper>
                            {!this.state.selectedUuid && <UILIB.Row>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">

                                    <UILIB.TextInput
                                        clickToChangeClicked={this.getVisitors}
                                        placeholder={i18n.t('campaigns:sitetracking.trackingvistors.searchForEmail')}
                                        onChange={this.changeSearchText}
                                        value={this.state.searchText}
                                        iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                                    />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="end-xs mar-b25">
                                    <UILIB.Button
                                        text="Export"
                                        className="button-primary"
                                        onClick={() => { this.getVisitors(true) }}
                                        iconLeft={<UILIB.Icons icon="arrowDown" />}
                                    />
                                </UILIB.Column>
                            </UILIB.Row>}

                            <UILIB.Row>
                                <UILIB.Column xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                                    <div style={{ display: "flex", alignItems: "baseline" }}>
                                        {this.state.selectedUuid && <>
                                            <UILIB.WarningHolder
                                                className="mar-r10 mar-b25"
                                                style={{ padding: "8px", display: "inline-block" }}
                                            >
                                                Viewing user #{this.state.selectedUuid}
                                                <UILIB.Button className="mar-l10 button-xs" text="Clear" onClick={() => { this.openImpressionsDrawer("") }} />
                                            </UILIB.WarningHolder>
                                        </>}
                                        {!this.state.selectedUuid && <UILIB.ButtonSelect
                                            outerClassName="mar-r10 mar-b25"
                                            selected={this.state.statusOption}
                                            headerText={this.state.statusOptions[this.state.statusOption].label}
                                            autoClose icon={ArrowDownPurple}
                                            data={this.state.statusOptions.map((status, index) => {
                                                return <a key={"st_" + index} onClick={() => this.changeStatus(status.value)}>{status.label}</a>
                                            })}
                                        />}
                                        <UILIB.ButtonSelect
                                            outerClassName="mar-r10 mar-b25"
                                            selected={this.state.deviceOption}
                                            headerText={this.state.deviceOptions[this.state.deviceOption].label}
                                            autoClose
                                            data={this.state.deviceOptions.map((status, index) => {
                                                return <a key={"st_" + index} onClick={() => this.changeDevice(status.value)}>{status.label}</a>
                                            })
                                            }
                                        />
                                        <UILIB.ButtonSelect
                                            outerClassName="mar-r10 mar-b25"
                                            selected={this.state.sourceOption}
                                            headerText={this.state.sourceOptions[this.state.sourceOption].label}
                                            autoClose
                                            data={this.state.sourceOptions.map((source, index) => {
                                                return <a key={"so_" + index} onClick={() => this.changeSourceFilter(source.value)}>{source.label}</a>
                                            })
                                            }
                                        />
                                        <UILIB.ButtonSelect
                                            outerClassName="mar-r10 mar-b25"
                                            selected={this.state.eventType}
                                            headerText={this.state.eventId ? this.state.events.find(f => f.value == this.state.eventId).label : "All Events"}
                                            autoClose
                                            data={this.state.events.map((event, index) => {
                                                return <a key={"ev_" + index} onClick={() => this.changeEventFilter(event.value)}>{event.label}</a>
                                            })
                                            }
                                        />

                                        <UILIB.Toggle
                                            name="botToggle"
                                            outerClassName="mar-b25"
                                            after={"Hide Robot Clicks"}
                                            value={this.state.botToggle}
                                            onChange={this.changeToggle} />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {this.state.selectedUuid && <>
                                            <span className="mar-r10">{this.state.count} Visits</span>
                                            <UILIB.Button text="Clear Filter" onClick={() => { this.openImpressionsDrawer("") }} />
                                        </>}
                                        {!this.state.selectedUuid && <>
                                            {this.state.count} Visitors
                                        </>}
                                    </div>
                                </UILIB.Column>

                            </UILIB.Row>

                            <UILIB.DataTable1
                                noResultsTxt={i18n.t('campaigns:sitetracking.trackingvistors.noDataFound')}
                                tableData={this.state.visitors}
                                dataUpdater={this.tableUpdater}
                                width="100%"
                                pageSize="100"
                                hasCheckBoxes="no"
                                sortedColumn={this.state.orderBy}
                                sortedDirection={this.state.orderDir}
                                loadingData={this.state.loadingData}
                            />
                        </UILIB.Paper>

                        <UILIB.Row>
                            <UILIB.Column xs={12} className="center-xs">
                                <UILIB.PagingBlock totalRows={this.state.count} pageSize={this.state.pageSize} numberOfLinks="10" currentPage={this.state.page}
                                    changePage={this.tableUpdater} text={i18n.t('page') + ":"} />
                            </UILIB.Column>
                        </UILIB.Row>

                    </UILIB.Column>
                </UILIB.Row >
            </div >
        );
    }
}

import React from 'react'
import i18n from '~/i18n'
import UILIB from '../../../Lib';

export default class ButtonSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            filterText: ''
        }
        this.closeDropDown = this.closeDropDown.bind(this);
        this.openPop = this.openPop.bind(this);
        this.updateFilterText = this.updateFilterText.bind(this)

    }

    componentDidMount() {

    }

    closeDropDown(event) {
        this.setState({ isOpen: false })
        if (this.props.onBlur && typeof this.props.onBlur) {
            this.props.onBlur()
        }
        if (typeof this.props.onClose === 'function') {
            this.props.onClose()
        }
    }
    openPop(event) {
        this.setState({ isOpen: true })
        if (typeof this.props.onOpen === 'function') {
            this.props.onOpen()
        }
    }

    updateFilterText(ev, clear) {
        if (ev) {
            ev.stopPropagation()
            ev.preventDefault()
        }
        this.setState({
            filterText: clear ? '' : ev.currentTarget.value
        }, () => {
            this.props.updateFilter(this.state.filterText)
        })
    }

    render() {
        var width = undefined;
        if (this.props.autoWidth) {
            width = 'auto';
        }

        if (this.props.width) {
            width = this.props.width
        }
        let dropDownClasses = "";
        if (this.props.dropDownClassName) dropDownClasses = this.props.dropDownClassName
        if (this.props.dropDirection && this.props.dropDirection !== null) {
            dropDownClasses += " " + this.props.dropDirection;
        }
        else {
            dropDownClasses += " bottom"
        }
        if (this.props.fixedHeight) {
            dropDownClasses += ' fixedHeight'
        }
        if (this.props.fixedHeightLarge) {
            dropDownClasses += ' fixedHeightLarge'
        }
        if (this.props.filter) {
            dropDownClasses += ' withFilter'
        }

        var inputType = "button";
        if (this.props.inputType == "text") inputType = "text"
        if (this.props.inputType == 'chip') inputType = 'chip'
        if (this.props.inputType == 'div') inputType = 'div'

        var btnClass = "button mid-shadow "
        if (this.props.btnClass) {
            btnClass += this.props.btnClass
        }

        let style = {}
        let buttonStyle = {};
        if (this.props.style) style = this.props.style;
        if (this.props.buttonStyle) buttonStyle = JSON.parse(JSON.stringify(this.props.buttonStyle));
        let combinedContent
        if (inputType === 'chip') {
            style.display = 'inline-block'
            combinedContent = <span>{this.props.headerText} {this.state.icon}</span>
        }


        var errorTxt = [];
        if (this.props.error) {
            buttonStyle.border = "2px solid red"
            if (this.props.error != " ") {
                errorTxt.push(<UILIB.FormError key={"err"}>{this.props.error}</UILIB.FormError>);
            }
        }

        let iconLeft = "";
        if (this.props.iconLeft) {
            iconLeft = <div className="button-iconLeft" style={this.props.iconLeftStyle}>{this.props.iconLeft}</div>
        }

        let iconRight = <div className="button-iconRight" style={this.props.iconRightStyle}><UILIB.Icons icon="smallArrowDown" color="#666666" className="mar-l5" /></div>;
        if (this.props.iconRight) {
            iconRight = <div className="button-iconRight" style={this.props.iconRightStyle}>{this.props.iconRight}</div>
        }

        if (this.props.hideIconRight) iconRight = ""


        let label = "";
        if ((this.props.label && this.props.labelOptions) || this.props.labelOptions) {
            label = <div className="txtInput_label_flex">
                <div className="txtInput_label">{this.props.label}</div>
                <div className="txtInput_labelOptions">{this.props.labelOptions}</div>
            </div>
        } else if (this.props.label) {
            label = <div className="txtInput_label">{this.props.label}</div>
        }

        let explainer = "";
        if (this.props.explainer) {
            explainer = <div className="txtInput_explainer">{this.props.explainer}</div>
        }

        let outerClassName = "textInputWrapper";
        if (this.props.outerClassName) outerClassName += " " + this.props.outerClassName;

        const dropDownStyle = { ...this.props.dropdownStyle }
        if (this.props.selectAll || this.props.filter) {
            dropDownStyle.width = 'fit-content'
            dropDownStyle.minWidth = '100%'
            // dropDownStyle.maxWidth = "100%"
        }

        let buttonClasses = btnClass
        if (!this.props.overrideButtonClass) buttonClasses += ' button-dropdown '
        buttonClasses += (' ' + this.props.className)

        dropDownStyle.overflow = 'auto'
        return <div style={style} className={outerClassName}>
            {label}
            {inputType === "button" && <UILIB.Button className={buttonClasses} disabled={this.props.disabled} onClick={this.openPop} style={buttonStyle}>
                {iconLeft}
                {this.props.headerText}
                {iconRight}
            </UILIB.Button>}
            {inputType === 'chip' && <UILIB.SquareChip className={this.props.className} onClick={this.openPop} value={combinedContent}>
            </UILIB.SquareChip>}
            {inputType === "text" && <select style={{ width: "100%" }} className={this.props.className} value={this.props.headerText} onClick={this.openPop}>
                <option disabled selected hidden>{this.props.headerText}</option>
            </select>}
            {inputType === "div" && <div style={{ display: 'flex', alignItems: 'center' }} className={this.props.className} onClick={this.openPop}>
                {iconLeft}
                {this.props.headerText}
                {iconRight}
            </div>}
            <UILIB.DropDown preventAutoClose={!this.props.autoClose} isOpen={this.state.isOpen == true && !this.props.disabled} id={this.state.id} onClose={this.closeDropDown} width={width} align={this.props.align || "left"} className={dropDownClasses} display={this.props.display} style={dropDownStyle}>
                {this.state.isOpen && <div onClick={this.props.autoClose ? this.closeDropDown : undefined}>
                    {(this.props.filter || this.props.selectAll || this.props.topCta) && <div className={'top-holder ' + (this.props.topAuthWidth ? 'noWidth' : '')}>
                        {this.props.filter && <div className='mar-b10'>
                            <UILIB.TextInput
                                value={this.state.filterText}
                                placeholder={this.props.filterText || i18n.t('filterHere')}
                                onChange={ev => this.updateFilterText(ev)}
                                iconRight={<UILIB.Icons icon="crossCircle" style={!!this.state.filterText ? {} : { display: 'none' }} onClick={(e) => this.updateFilterText(e, true)} />}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    ev.stopPropagation();
                                }} />
                        </div>}



                        {(!!this.props.selectAll || !!this.props.clearAll) && (
                            <div className='mar-b10'>
                                <div className="flexSpaceBetween">
                                    {!!this.props.selectAll && (
                                        <UILIB.Button text={i18n.t('selectAll')} className="button-small" onClick={this.props.selectAll} />
                                    )}

                                    {!!this.props.clearAll && (
                                        <UILIB.Button text={i18n.t('clearAll')} className="button-small" onClick={this.props.clearAll} />
                                    )}
                                </div>
                            </div>
                        )}

                        {!!this.props.topCta && <div className='mar-b10'>{this.props.topCta}</div>}
                    </div>}

                    {this.props.customHeader && <div style={{ padding: '16px 16px 0 16px' }}>{this.props.customHeader}</div>}

                    {this.props.loadingData && <div className="mar-b25">
                        <UILIB.LoadingIcons iconType="2" />
                    </div>}
                    {!this.props.loadingData && this.props.data && this.props.data.length > 0 ? <ul style={this.props.dataStyle}>{this.props.data.map((d, i) => {
                        if (this.props.selected !== i) return <li key={d.key || i}>{d.label || d.value || d}</li>
                    })}</ul> : this.props.children}
                </div>}
            </UILIB.DropDown>
            {errorTxt}
            {explainer}
        </div>
    }
}
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import Fields from './fields';
import AddGroupView from '~/pages/cp/subscribers/groups/incGoAddGroup';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';

@connect((store) => {
    return { user: store.user }
})
class HubspotInitial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupId: undefined,
            loaded: false,
            groups: []
        }

        this.getGroups = this.getGroups.bind(this)
        this.save = this.save.bind(this)
        this.changeGroup = this.changeGroup.bind(this)
        this.addAGroup = this.addAGroup.bind(this)
    }

    componentDidMount() {
        this.getGroups()
        this.setState({
            groupId: this.props.appBinding.appSettings ? this.props.appBinding.appSettings.groupId : undefined
        })
    }

    getGroups() {
        axios.get('/group').then(response => {
            this.setState({
                loaded: true,
                groups: response.data.Groups.map(g => {
                    return {
                        label: g.groupName,
                        value: g.id
                    }
                })
            })
        })
    }

    changeGroup(ev) {
        this.setState({
            groupId: ev.currentTarget.value
        })
    }

    addAGroup() {
        var drawerContent = <AddGroupView addedGroup={(response) => {
            var groupId = response.data.groupId;
            this.setState({ groupId }, this.getGroups);
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    save() {
        var binding = this.props.appBinding;
        if (!binding.appSettings) binding.appSettings = {}
        binding.appSettings.groupId = this.state.groupId
        axios.put("/application/account/" + binding.id, binding).then(() => {
            return axios.get('/application/hubspot/setup')
        }).then(this.props.finish)
    }


    render() {
        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />
        return (
            <div>
                <UILIB.Row>
                    {(!this.props.only || this.props.only === 'group') && <UILIB.Column xs={12} margin={0}>
                        <UILIB.Paper>
                            <h4 className="mar-b25">Select a Group to Sync With</h4>
                            <div className="mar-b25">Please select an existing Transpond group, or create a new one, where you would like contacts from Hubspot to be created.</div>
                            {this.state.groups.length &&
                                <div className="quickFlex">
                                    <div className="form-group mar-b0"><UILIB.Select data={this.state.groups} placeholder="--Select a Group--" name="groupId" value={this.state.groupId} onChange={this.changeGroup} /></div>
                                    <span style={{ marginLeft: "10px" }}>or</span>
                                    <UILIB.Button text="Add a new Group" className="button-sml secondary outline" style={{ marginLeft: "10px" }} onClick={this.addAGroup} />
                                </div>
                            }
                        </UILIB.Paper>
                    </UILIB.Column>}
                    {(!this.props.only || this.props.only === 'fields') && <UILIB.Column xs={12} margin={0}>
                        {(this.props.only != 'fields') && <UILIB.Paper>
                            <h4 className="mar-b25">Select which Fields to Sync</h4>
                            <Fields appBinding={this.props.appBinding} />
                        </UILIB.Paper>
                        }
                        {(this.props.only === 'fields') && <div>
                            <h4 className="mar-b25">Select which Fields to Sync</h4>
                            <Fields appBinding={this.props.appBinding} />
                        </div>
                        }
                    </UILIB.Column>
                    }

                    {this.state.groupId && this.state.groupId != -1 &&
                        <UILIB.Column xs={12} className="center-xs">
                            <UILIB.Button text="Save and Continue" onClick={this.save} />
                        </UILIB.Column>
                    }
                </UILIB.Row>
            </div>
        );
    }
}

export default HubspotInitial;
import React from 'react'

export default function IconsFilter({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 3.00006C3 2.73484 3.10536 2.48049 3.29289 2.29295C3.48043 2.10542 3.73478 2.00006 4 2.00006H16C16.2652 2.00006 16.5196 2.10542 16.7071 2.29295C16.8946 2.48049 17 2.73484 17 3.00006V6.00006C16.9999 6.26526 16.8946 6.51957 16.707 6.70706L12 11.4141V15.0001C11.9999 15.2653 11.8945 15.5196 11.707 15.7071L9.707 17.7071C9.56715 17.8469 9.38898 17.9421 9.19503 17.9806C9.00108 18.0192 8.80005 17.9994 8.61735 17.9237C8.43465 17.8481 8.27848 17.7199 8.1686 17.5555C8.05871 17.3911 8.00004 17.1978 8 17.0001V11.4141L3.293 6.70706C3.10545 6.51957 3.00006 6.26526 3 6.00006V3.00006Z" fill={fillColor} />
        </svg>
    )
}
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';
class AddApiKey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: ""
        }
        this.checkForm = this.checkForm.bind(this);
    }
    componentDidMount() {
    }

    checkForm() {
        let error = "";
        if (!this.state.name || !this.state.name.length) error = " ";
        this.setState({ error })
        if (error) return;
        this.props.confirm(this.state.name);
    }
    render() {

        return (
            <div>
                <h4 className="mar-b25">{i18n.t('company:api.create')}</h4>
                <div className="mar-b25">{i18n.t('company:api.createName')}</div>
                <UILIB.TextInput
                    outerClassName="mar-b25"
                    type="text"
                    value={this.state.name}
                    error={this.state.error}
                    onChange={(ev) => { this.setState({ name: ev.target.value }) }}
                />
                <UILIB.Button
                    className="button-primary"
                    text={i18n.t('company:api.create')}
                    onClick={this.checkForm} />
            </div >
        );
    }
}

export default AddApiKey;
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import UILIB from '../../../../Common-Objects/Lib'
import { alterSiteDrawer } from '../../../../data/actions/siteActions'

export default function ChangeTable({ current = "campaigns", onSave = () => { } }) {
    const dispatch = useDispatch()
    const [table, setTable] = useState('campaigns')

    const save = () => {
        onSave(table)
        dispatch(alterSiteDrawer(false, true, 'right', null, true))
    }

    const data = [{
        label: 'Campaigns',
        value: 'campaigns',
    }, {
        label: 'Automations',
        value: 'automations',
    }, {
        label: 'Forms',
        value: 'forms',
    }, {
        label: 'Site Visits',
        value: 'sitevisits',
    }]

    return (
        <div>
            <h4 className='mar-b25'>Change Dashboard Table</h4>
            <UILIB.Select outerClassName="mar-b25" data={data} label="Select Chart" value={table} onChange={e => setTable(e.currentTarget.value)} />
            <UILIB.Button className="button-primary" text="Save" onClick={save} />
        </div>
    )
}
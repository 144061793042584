import React from 'react'
import { connect } from 'react-redux';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import PermissionChecker from '~/Classes/permissions';
import i18n from '~/i18n'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import BasicView from './basic';
import ThemesView from './themes';
import CampaignsView from './campaigns';
import BrandAnalyzerRequiredModal from '../../../../includes/brandanalyzer/brandAnalyzerRequiredModal';

@connect((store) => {
    return { permissionStore: store.permission, accountMaster: store.accountMaster }
})
export default class TemplateDragType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            drawer: null
        }

        this.changeStep = this.changeStep.bind(this);
        this.selectTemplate = this.selectTemplate.bind(this);
        this.selectTheme = this.selectTheme.bind(this);
    }

    changeStep(event) {
        var index;
        if (event.currentTarget && event.currentTarget.id) {
            index = event.currentTarget.id;
        }
        else if (event.currentTarget) {
            index = event.currentTarget.value;
        }
        else {
            index = event;
        }
        this.setState({
            currentStep: index
        })
    }

    selectTheme(theme) {

        var url = "/cp/templates/add/dragDrop?from=" + theme.id;
        switch (theme.editorType) {
            case 1:
                url = "/cp/templates/add/fromCode?from=" + theme.id;
                break;
            case 2:
                url = "/cp/templates/add/wysiwyg?from=" + theme.id;
                break
        }

        if (theme.brandAnalyzer) {
            if (this.props.accountMaster.accountMaster.options) {
                if (!this.props.accountMaster.accountMaster.options.brandAnalyzer || !this.props.accountMaster.accountMaster.options.brandAnalyzer.colours || !Array.isArray(this.props.accountMaster.accountMaster.options.brandAnalyzer.colours) || !this.props.accountMaster.accountMaster.options.brandAnalyzer.font || !this.props.accountMaster.accountMaster.options.brandAnalyzer.logo) {
                    this.props.dispatch(siteMaster.alterFullpage(<BrandAnalyzerRequiredModal urlOnCompletion={url} />));
                    return;
                }
            }
        }
        this.props.history.push(url)
    }

    selectTemplate(template) {
        var newTemplate = Object.assign({}, template)
        delete newTemplate.id;
        delete newTemplate.CampaignId;
        if (newTemplate.templateType === 4) {
            newTemplate.templateType = 1;
        }

        axios.post('/template', newTemplate)
            .then(response => {
                newTemplate.id = response.data.Template.templateID
                this.props.history.push("/cp/templates/add/dragDrop/" + response.data.Template.templateID)
            })
    }

    render() {
        var thisMenuOptions = [{
            label: "Themes", value: 0
        }]

        if (PermissionChecker("campaigns", this.props.permissionStore.permissions, "write")) {
            thisMenuOptions.push({ label: "Campaigns", value: 2 })
        }
        return <div className="mar-t30">

            <h4 className="mar-b25">{i18n.t('templates:add.themesIndexHeader')}</h4>

            <div className="hide-sm hide-xs mar-b25">

                <UILIB.ButtonSimple
                    className="button-simple-fullsize mar-l5"
                    selected={this.state.currentStep == 0}
                    onClick={() => { this.changeStep(0) }}>
                    {i18n.t('templates:add.themes')}
                </UILIB.ButtonSimple>

                {PermissionChecker("campaigns", this.props.permissionStore.permissions, "write") &&
                    <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-l5"
                        selected={this.state.currentStep == 2}
                        onClick={() => { this.changeStep(2) }}>
                        {i18n.t('templates:add.campaigns')}
                    </UILIB.ButtonSimple>
                }

            </div>

            <div className="hide-md hide-lg mar-b25">
                <UILIB.Select data={thisMenuOptions} onChange={this.changeStep} value={this.state.currentStep} />
            </div>


            {this.state.currentStep == 2 && <CampaignsView onSelect={this.selectTemplate} />}
            {this.state.currentStep == 0 && <ThemesView onSelect={this.selectTheme} defaultThemeGroupId={this.props.defaultThemeGroupId} />}

        </div >

    }
}
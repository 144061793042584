import React from 'react'
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'

//CONNECT TO STORE

@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster }
})

export default class ServerBlacklists extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        var tableData = [];
        if (this.props.blacklists && this.props.blacklists && this.props.blacklists.length) {
            this.props.blacklists.forEach(blackList => {
                var tmpData = {
                    headerName: {
                        headerValue: "Black List",
                        value: <span>{blackList.name}</span>
                    }
                }
                tableData.push(tmpData)
            })
        }
        return <div>
            <UILIB.DataTable1 noResultsTxt={"No results yet"} tableData={tableData} loadingData={false} width="100%" pageSize="100" hasCheckBoxes="no" />

        </div>
    }
}

import React from 'react';
import { Link } from 'react-router-dom';
import axios from '~/data/http/axios';
import UILIB from '../../../../Common-Objects/Lib';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import queryString from 'query-string';

import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions';
import PermissionChecker from '~/Classes/permissions';

import AddGroupView from './incGoAddGroup';
import DeleteGroupView from './incGoDeleteGroup';
import CopyGroup from './copyGroup';

import i18n from '~/i18n'
import ReactTooltip from 'react-tooltip'
import axios2 from 'axios'
import { getUserSettings, setUserSettings } from '../../../../Classes/userSettings';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, browser: store.browser, permissionStore: store.permission, siteMaster: store.siteMaster, account: store.accountMaster }
})

export default class GroupsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            tableData: [],
            pageSize: 10,
            totalGroups: 0,
            page: 0,
            searchText: '',
            loadingData: 1,
            sortColumn: 'createdAt',
            sortDirection: 'DESC',
            writeAccess: PermissionChecker("subscribers", this.props.permissionStore.permissions, "write")
        };


        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();

        this.getGroups = this.getGroups.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.changeSearchText = this.changeSearchText.bind(this);
        this.tableUpdater = this.tableUpdater.bind(this);
        this.togglePublic = this.togglePublic.bind(this)
        this.copyGroup = this.copyGroup.bind(this)
    }

    componentDidMount() {
        getUserSettings().then(s => {
            if (!s.groups) s.groups = {}
            let save = false
            if (!s.groups.limit && this.props.account.tableLimits?.groups) {
                save = true
                s.groups.limit = this.props.account.tableLimits.groups
            }
            if (save) setUserSettings(s).catch(console.log)
            this.setState({ settings: s, pageSize: s.groups.limit || 10 }, this.getGroups)
            const query = queryString.parse(this.props.location.search);
            if (query.open) {
                this.addGroup()
            }
        })
    }

    componentWillUnmount() {
        this.source.cancel('I cancelled this')
        this.source = this.CancelToken.source();
        if (this.timer) clearTimeout(this.timer);
    }

    copyGroup(group) {
        var drawerContent = <CopyGroup group={group} finish={() => {
            this.getGroups()
            this.props.dispatch(siteMaster.alterSiteDrawer(false));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    togglePublic(group) {
        axios.put('/group/' + group.id, { public: !group.public }).then(() => this.getGroups())
    }

    getGroups() {
        var queryStr = `?startRecord=${this.state.page * this.state.pageSize}&recordLimit=${this.state.pageSize}&searchText=${this.state.searchText}&sortColumn=${this.state.sortColumn}&sortOrder=${this.state.sortDirection}&count=true&automations=true`
        axios.get('/group' + queryStr, {
            cancelToken: this.source.token
        }).then(response => {
            var loop = false
            this.setState({
                groups: response.data.Groups,
                totalGroups: response.data.countTotal,
                loadingData: 0,
                tableData: response.data.Groups.map((row, index) => {
                    if (row.sync) loop = true
                    return {
                        groupName: {
                            headerValue: i18n.t('subscribers:index.groupName'),
                            value: <span><Link to={'/cp/groups/' + row.id} style={{ fontWeight: "600", textDecoration: "none" }}>{row.groupName}</Link> {row.Applications && row.Applications.length ? row.Applications.map(a => {
                                return <UILIB.Avatar for={row.id + '-' + a.id} key={row.id + '-' + a.id} style={{ height: '20px', width: '20px', marginLeft: "5px", top: '5px' }} tooltip={a.appName + ' Group'} src={a.logo} />
                            }) : ''}</span>,
                            orderBy: true
                        },
                        allSubs: {
                            headerValue: 'Total Contacts',
                            value: <UILIB.SquareChip className="square-chip-large square-chip-purple square-chip-min-width">{NumberFunctions.formatNumber(row.all)}</UILIB.SquareChip>,
                            width: 150,
                            align: "center",
                            orderBy: false
                        },
                        totalSubscribers: {
                            headerValue: i18n.t('subscribers:index.activeContacts'),
                            value: <UILIB.SquareChip className="square-chip-large square-chip-purple square-chip-min-width">{NumberFunctions.formatNumber(row.totalSubscribers)}</UILIB.SquareChip>,
                            width: 150,
                            align: "center",
                            orderBy: true,
                            helper: "Not Denylisted/Bounced/Unsubscribed/In-Active"
                        },
                        forms: {
                            headerValue: i18n.t('subscribers:index.forms'),
                            value: <div data-tip={row.forms ? " " + i18n.t('subscribers:index.formsSetup') : i18n.t('subscribers:index.noForms')} >
                                <ReactTooltip effect='solid' />
                                <UILIB.Icons style={{ cursor: "pointer" }} icon={row.forms ? "tick" : "cross"} color={row.forms ? "" : "#DDDDDD"} onClick={() => { this.props.history.push('/cp/groups/' + row.id + '/forms') }} />
                            </div>,
                            width: 150,
                            align: "center",
                            orderBy: false
                        },
                        automations: {
                            headerValue: i18n.t('nav:automation'),
                            value: <div data-tip={row.automations && row.automations.length ? " " + i18n.t('subscribers:index.autosSetup') : i18n.t('subscribers:index.noAutos')}>
                                <ReactTooltip effect='solid' />
                                <UILIB.Icons style={{ cursor: "pointer" }} icon={row.automations && row.automations.length ? "tick" : "cross"} color={row.automations && row.automations.length ? "" : "#DDDDDD"} onClick={() => { this.props.history.push('/cp/automation') }} />
                            </div>,
                            width: 150,
                            align: "center",
                            orderBy: false
                        },
                        groupUsers: {
                            headerValue: i18n.t('subscribers:index.users'),
                            value: <div className="avatarGroup">{row.groupUsers.map(theUser => {
                                return <UILIB.Avatar className="mar-r5" key={"user_" + index + "_" + theUser.id} for={"user_" + index + "_" + theUser.id} tooltip={theUser.firstName + ' ' + theUser.lastName} src={theUser.profilePicFileName} onClick={() => { this.props.history.push('/cp/groups/' + row.id + "/users") }} style={{ width: "25px", height: "25px" }} />
                            })}</div>,
                            orderBy: false,
                            align: "right",
                            width: 150
                        },
                        public: {
                            headerValue: i18n.t('subscribers:index.public'),
                            value: <UILIB.SquareChip className={(row.public ? 'square-chip-green' : 'square-chip-red')} for={'campaign' + row.id} tooltip={row.public ? i18n.t('subscribers:index.allUsersCanSee') : i18n.t('subscribers:index.onlyAdminsCanSee')}>{row.public ? i18n.t('subscribers:index.public') : i18n.t('subscribers:index.private')}</UILIB.SquareChip>,
                            width: 90

                        },
                        createdAt: {
                            headerValue: i18n.t('created'),
                            value: DateTimeFunctions.formatDateTime(row.dateCreated, 2),
                            orderBy: true,
                            width: 150,
                            align: "center"
                        },


                        optionsCol: {
                            headerValue: " ",
                            value: <UILIB.OptionsDropdown popWidth="150px">
                                <ul>
                                    <li>
                                        <a onClick={() => { this.props.history.push('/cp/groups/' + row.id) }}>{i18n.t('view')}</a>
                                    </li>
                                    {this.state.writeAccess === true &&
                                        <li>
                                            <a onClick={() => { this.deleteGroup(row) }}>{i18n.t('delete')}</a>
                                        </li>
                                    }
                                    {this.state.writeAccess && <li>
                                        <a onClick={() => this.togglePublic(row)}>{row.public ? i18n.t('subscribers:index.makePrivate') : i18n.t('subscribers:index.makePublic')}</a>
                                    </li>}
                                    {this.state.writeAccess && <li>
                                        <a onClick={() => this.copyGroup(row)}>{i18n.t('subscribers:index.copyGroup')}</a>
                                    </li>}
                                </ul>

                            </UILIB.OptionsDropdown>,
                            orderBy: false,
                            fixed: true,
                            width: 20
                        }
                    }
                })
            }, () => {
                if (loop) {
                    this.timer = setTimeout(this.getGroups, 5000)
                }
            })
        }).catch(console.log)
    }

    tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {
        var updateObj = {}
        if (currentPage !== null && currentPage !== undefined) updateObj.page = currentPage;
        if (pageSize !== null && pageSize !== undefined) updateObj.pageSize = pageSize;
        if (searchText !== undefined) updateObj.searchText = searchText;
        if (sortDirection) updateObj.sortDirection = sortDirection;
        if (sortColumn) updateObj.sortColumn = sortColumn;

        this.setState(updateObj, this.getGroups)
    }

    addGroup() {
        var drawerContent = <AddGroupView addedGroup={this.getGroups} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    deleteGroup(deleteRow) {
        var drawerContent = <DeleteGroupView deletedGroup={this.getGroups} groupData={deleteRow} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "400px"));
    }

    changeSearchText(ev) {
        this.setState({
            page: 0,
            searchText: ev.currentTarget.value,
            page: ev.currentTarget.value ? this.state.page : 0
        }, () => {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(this.getGroups, 250)
        })
    }

    changePageSize(size) {
        const settings = this.state.settings
        settings.groups.limit = size
        setUserSettings(settings).catch(console.log)
        this.setState({
            settings,
            page: 0,
            pageSize: size
        }, this.getGroups)
    }

    render() {
        return <div>
            <UILIB.Row>
                <UILIB.Column xs={12} sm={6} className="mar-b25">
                    <UILIB.TextInput
                        placeholder={i18n.t('subscribers:index.searchForGroupName')}
                        value={this.state.searchText}
                        onChange={this.changeSearchText}
                        clickToChangeClicked={() => { }}
                        onEnterPress={() => { }}
                        className="no-marg"
                        iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                    />
                </UILIB.Column>

                <UILIB.Column xs={12} sm={6} className="end-xs mar-b25">
                    {(this.state.writeAccess === true) && <UILIB.Button
                        onClick={this.addGroup}
                        className="button-primary"
                        iconLeft={<UILIB.Icons icon="plus" />}>
                        {i18n.t('subscribers:index.addGroup')}
                    </UILIB.Button>}
                </UILIB.Column>
            </UILIB.Row>
            <UILIB.Row >
                <UILIB.Column xs={12} className="end-xs mar-b25" style={{ display: "flex", alignItems: "center" }} >
                    <div>{i18n.t('showing')}</div>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.pageSize === 10} onClick={() => { this.changePageSize(10) }}>10</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 50} onClick={() => { this.changePageSize(50) }}>50</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>
                </UILIB.Column>

            </UILIB.Row>


            <UILIB.DataTable1
                noResultsTxt={<span> {i18n.t('subscribers:index.noGroups1')}</span>}
                tableData={this.state.tableData}
                loadingData={this.state.loadingData}
                dataUpdater={this.tableUpdater}
                width="100%"
                pageSize="100"
                hasCheckBoxes="no"
                sortedColumn={this.state.sortColumn}
                sortedDirection={this.state.sortDirection}
                className="mar-b25"
            />

            <UILIB.Row>
                <UILIB.Column xs={12} hide={["xs", "sm"]} className="center-xs">
                    <UILIB.PagingBlock totalRows={this.state.totalGroups} pageSize={this.state.pageSize} numberOfLinks={10} currentPage={this.state.page}
                        changePage={this.tableUpdater} text={i18n.t('page') + ":"} />
                </UILIB.Column>
            </UILIB.Row>




        </div>
    };

};

import React, { Fragment } from 'react'
import UILIB from '~/Common-Objects/Lib'
import * as user from '../../data/actions/userActions'; //now we can use user actions here

import { connect } from 'react-redux';
import LeftPanel from './loginAttractMode.jsx';
import { I18n } from 'react-i18next';
import queryString from 'query-string';
import i18nR from '~/i18n';
import ReCaptcha from '../../Common-Objects/Captcha';

// import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
  return {
    user: store.user,
    siteMaster: store.siteMaster
  }
})
//NOW WE CAN USE FOR EXAMPLE {this.props.user.firstName} below - getting data from the store

export default class LoginTemplate extends React.Component {

  constructor(props, context) {
    super(props);
    this.state = {
      loaded: false,
      emailAddress: '',
      password: '',
      masterErrorMsg: '',
      masterErrorMsgTxt: '',
      twoFACode: '',
      redirect: '',
      AccountMasterId: '',
      captchaResponse: '',
      loggingIn: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.redirectToPage = this.redirectToPage.bind(this);
  }

  componentDidMount() {
    const query = queryString.parse(location.search);
    const token = query.usertoken;

    if (token) {
      this.props.dispatch(user.loginAndGetToken(null, null, token, null))
    } else {
      this.setState({
        loaded: true,
        AccountMasterId: query.AccountMasterId ? parseInt(query.AccountMasterId) || '' : '',
        redirect: query.redirect
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.loginErrors != this.props.user.loginErrors) {
      if (this.props.user.loginErrors === 'soft2fa required') {
        this.props.history.push('/soft2fa')
      }
    }
    if (this.props.user.loginErrors && this.props.user.loginErrors != "") {
      var masterErrorMsg = masterErrorMsg = i18nR.t('login:login.authenticationFailedRaw');
      if (prevState.loggingIn) {
        this.setState({ loggingIn: false })
      }
      if (masterErrorMsg != prevState.masterErrorMsgTxt) {
        this.setState({ masterErrorMsgTxt: masterErrorMsg, masterErrorMsg: <UILIB.FormError data-test="login-error">{masterErrorMsg}</UILIB.FormError> });
      }
    } else {
      if (prevState.masterErrorMsgTxt != "") {
        this.setState({ masterErrorMsg: "", masterErrorMsgTxt: '', loggingIn: false })
      }
    }
  }

  redirectToPage(pageURL) {
    if (this.state.redirect) {
      pageURL += (pageURL.indexOf('?') > -1 ? '&' : '?') + 'redirect=' + this.state.redirect
    }
    this.props.history.push('/' + pageURL);
  }

  handleSubmit(event) {
    //Check the login in the store, and in the despatcher, if the login is valid save to store, and forward t oCP
    let token = this.state.captchaResponse
    this.setState({ captchaResponse: '', loggingIn: true })
    this.props.dispatch(user.loginAndGetToken(this.state.emailAddress, this.state.password, undefined, this.state.twoFACode, this.state.AccountMasterId, token));
    if (event) event.preventDefault();
  }

  inputChange(event) {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
    else {
      this.setState({
        [event.currentTarget.name]: event.currentTarget.value
      });
    }

  }

  render() {
    var mainMD = 8;
    if (this.props.siteMaster.siteName != "MPZMail" && this.props.siteMaster.siteName != "Transpond") {
      mainMD = 12;
    }



    if (!this.state.loaded) return <div></div>

    var disableRegistration = false;
    if (this.props.siteMaster && this.props.siteMaster.disableRegistration) disableRegistration = true;

    let show2Fa = false;
    let captchaRequired = false
    let errorMessage = this.state.masterErrorMsg;
    let errorMessage2Fa = "";
    if (this.props.user.loginErrors) {
      if (this.props.user.loginErrors.indexOf("twoFACode Required") > -1) {
        errorMessage = "";
        show2Fa = true;
      }
      if (this.props.user.loginErrors.indexOf("twoFACode Failed") > -1) {
        errorMessage = "";
        errorMessage2Fa = "Incorrect 2FA Code";
        show2Fa = true;
      }
      if (this.props.user.loginErrors.indexOf('captcha') > -1) {
        errorMessage = "";
        captchaRequired = true
      }
    }
    return (<I18n ns="translations">
      {
        (t, { i18n }) => (
          <div className="login-holder">

            {(this.props.siteMaster.siteName === "MPZMail" || this.props.siteMaster.siteName === "Transpond") &&
              <LeftPanel history={this.props.history} />
            }
            <div className="login-right-panel">

              {!disableRegistration && <div className="login-right-panel-register">
                <span className="login-right-panel-register-text mar-r10">{t('login:login.noAccount')}</span>
                <UILIB.Button className="button-sml" onClick={() => this.redirectToPage('register?welcome=true')}>{t('login:login.signUp')}</UILIB.Button>
              </div>}



              <div className="login-right-panel-login">


                {this.props.siteMaster.siteId == 1 &&
                  <UILIB.Paper className="paper-primary" style={{ padding: "10px 16px", width: "80%", maxWidth: "660px", display: "flex" }}>
                    <UILIB.Icons icon="megaphone" circle={true} style={{ width: "20px", height: "20px" }} color={"#FFFFFF"} className="mar-r15" />
                    <div style={{ fontWeight: 500 }}>We’ve recently updated the layout within Transpond to offer you a better experience.</div>
                  </UILIB.Paper>
                }

                <UILIB.Paper className="login-right-panel-login_loginBox">

                  <h3 className="mar-b40">
                    {t('login:login.loginTo')} <span>{this.props.siteMaster.siteName}</span>
                  </h3>

                  <UILIB.TextInput maxLength="255" type="email" name="emailAddress" label={t('login:login.emailPlaceholder')} outerClassName="mar-b24" onChange={this.inputChange} onKeyPress={this.inputChange} focus={true} />

                  <UILIB.TextInput
                    maxLength="255"
                    type="password"
                    name="password"
                    label={t('login:login.passwordPlaceholder')}
                    outerClassName="mar-b24"
                    onChange={this.inputChange}
                    onKeyPress={this.inputChange}
                    labelOptions={(!this.props.siteMaster.hideForgottenPassword) && <a onClick={() => this.redirectToPage('reminder')}>{t('login:login.forgot')}</a>}
                  />

                  {captchaRequired && !this.state.captchaResponse && <ReCaptcha onComplete={r => this.setState({ captchaResponse: r })} />}

                  {errorMessage}


                  {show2Fa == true && <UILIB.Paper >
                    <h4 className="mar-b5">Two Factor Authentication</h4>
                    <div className="mar-b15">Please check your mobile authenticator, and enter the code for {this.props.siteMaster.siteName}</div>
                    <UILIB.TextInput maxLength="255" type="twoFACode" name="twoFACode" placeholder={"xxx xxx"} className="txtInput lg" onChange={this.inputChange} onKeyPress={this.inputChange} focus={true} error={errorMessage2Fa} />
                  </UILIB.Paper>}

                  <UILIB.Button className="button-primary" text={t('login:login.login')} onClick={this.handleSubmit} disabled={this.state.loggingIn} />

                </UILIB.Paper>

              </div>
            </div>
          </div>)
      }
    </I18n>)

  }

}

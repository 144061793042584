import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})
export default class IncDeleteCustomFields extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: ''
        };

        this.closeDrawer = this.closeDrawer.bind(this);
        this.goDeleteFields = this.goDeleteFields.bind(this);

    }

    goDeleteFields() {
        this.setState({ error: '' })
        var fieldIds = [];
        this.props.fields.forEach(field => {
            fieldIds.push(field.fieldId.value)
        })

        axios.post('/customfield/bulkdelete', { fieldIds })
            .then((res) => {
                this.props.getCustomFields();
                if (this.props.alert && typeof this.props.alert === 'function') {
                    this.props.alert('Fields Deleted')
                }
                this.closeDrawer();
            })
            .catch(e => {
                this.setState({
                    error: e.data.error
                })
            })
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    render() {
        var headerText = this.props.fields.length > 1 ? i18n.t('subscribers:customfields.deleteMultiHeader') : i18n.t('subscribers:customfields.deleteHeader');
        headerText = headerText.replace(/\[FIELDCOUNT\]/g, this.props.fields.length)
        return <div>

            <h4 className="mar-b25">{headerText}</h4>
            <div className="mar-b25">{i18n.t('subscribers:customfields.deleteMultiSubHeader1')}</div>

            <div className="mar-b25">
                {this.props.fields.map((field, index) => {
                    return <UILIB.SquareChip
                        key={"fieldD_" + index}
                        iconLeft={<UILIB.Icons icon="circle" />}
                        className="mar-r5"
                    >
                        {field.fieldDesc.value}
                    </UILIB.SquareChip>

                })}
            </div>

            <div className="mar-b25">{i18n.t('subscribers:customfields.deleteMultiSubHeader2')}</div>


            <UILIB.Button
                text={i18n.t('subscribers:customfields.deleteMultiButton')}
                className="button-red mar-b25"
                iconLeft={<UILIB.Icons icon="cross" />}
                onClick={this.goDeleteFields} />
            {!!this.state.error && <div>
                <p className='text-red'>{this.state.error}</p>
            </div>}
        </div >

    }
};


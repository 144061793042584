import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import i18n from '~/i18n'

//type 2 and type 3 merge tags
//apply to campaign sends only
//for example campaignName, campaignSubject
//type 2 is subscriber level (sub email, etc)
//type 3 is campaign level (campaign name, subject, etc)

class CampaignMergeTags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mergeTags: [],
            filter: '',
            loaded: false
        }
    }

    componentDidMount() {
        this.getMergeTags();
    }
    getMergeTags() {
        var types = []
        if (this.props.includeSubscriber) types.push('2');
        if (this.props.hasWorkflowEmails) types.push('3')
        if (this.props.includeSubCustomFields) types.push('4');
        if (this.props.includeRss) types.push('5');
        let q = '?type=' + types.join(',')
        if (this.props.AutomationId) q += '&AutomationId=' + this.props.AutomationId


        axios.get('/mergeTags' + q).then(results => {
            var mergeTags = results.data.MergeTags
            mergeTags.forEach(tag => {
                if (!tag.fieldType) {
                    var tmpTag = tag.tag.replace(/\{/g, '').replace(/\}/g, '')
                    var newDesc = i18n.t('mergeTags.' + tmpTag);
                    if (newDesc) {
                        tag.description = newDesc;
                    }
                }
            })

            this.setState({
                mergeTags: mergeTags, loaded: true
            })
        })
    }

    render() {
        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />;
        let others = {}
        let otherTypes = []
        let apps = {}
        let appTypes = []


        this.state.mergeTags.forEach(t => {
            if (t.type && !parseInt(t.type)) {
                if (!others[t.type]) {
                    others[t.type] = []
                    otherTypes.push(t.type)
                }
                others[t.type].push(t)
            }
            if (t.subType) {
                if (!apps[t.subType]) {
                    apps[t.subType] = []
                    appTypes.push(t.subType)
                }
                apps[t.subType].push(t)
            }
        })

        let systemTags = this.state.mergeTags.filter(theFilt => theFilt.type == 1)
        let customTags = this.state.mergeTags.filter(theFilt => theFilt.type == 2 && !theFilt.subType)
        let campaignTags = this.state.mergeTags.filter(theFilt => theFilt.type == 3 && !theFilt.subType)
        let rssTags = this.state.mergeTags.filter(theFilt => theFilt.type == 5 && !theFilt.subType);

        let groups = []
        if (systemTags.length) {
            groups.push({
                name: i18n.t('templates:mergetags.system'),
                type: "1",
                tags: systemTags
            })
        }
        if (rssTags.length) {
            groups.push({
                name: "RSS Tags",
                type: "5",
                tags: rssTags
            })
        }
        if (customTags.length) {
            groups.push({
                name: i18n.t('templates:mergetags.custom'),
                type: "2",
                tags: customTags
            })
        }
        if (campaignTags.length) {
            groups.push({
                name: "Campaign",
                type: "3",
                tags: campaignTags
            })
        }

        let filterOptions = [
            { label: i18n.t('all'), value: "" }
        ]

        if (!!systemTags.length) filterOptions.push({ value: "1", label: i18n.t('templates:mergetags.system') })
        if (!!rssTags.length) filterOptions.push({ value: "5", label: "RSS Tags" });
        if (!!customTags.length) filterOptions.push({ value: "2", label: i18n.t('templates:mergetags.custom') })
        if (!!campaignTags.length) filterOptions.push({ value: "3", label: "Campaign" });
        appTypes.forEach(t => { filterOptions.push({ value: t, label: t }) })
        otherTypes.forEach(t => { filterOptions.push({ value: t, label: t }) })

        return (
            <div>
                {!this.props.inDrawer && <div className="quickFlex mar-b25">
                    <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.close}>{i18n.t('subscribers:subscriber.back')}</UILIB.Hint>
                </div>}
                <h4 className="mar-b25">Merge Tags</h4>
                <div className="mar-b25">Please select from the following merge tags to insert.</div>

                <UILIB.Select
                    label={i18n.t('filter')}
                    value={this.state.filter}
                    onChange={e => this.setState({ filter: e.currentTarget.value })}
                    data={filterOptions}
                    outerClassName="mar-b25"
                />

                {(!this.state.filter || this.state.filter === "1") && !!systemTags.length && <UILIB.Paper>
                    <h4 className="mar-b15">{i18n.t('templates:mergetags.system')}</h4>
                    <UILIB.DataTable1
                        noResultsTxt={""}
                        tableData={systemTags.map(theTag => {
                            return [{
                                headerValue: " ",
                                value: <a onClick={() => { this.props.insertMergeTag(theTag.tag, this.props.fieldName) }}>{theTag.tag}</a>
                            },
                            {
                                headerValue: " ",
                                value: <div style={{ whiteSpace: "pre-wrap" }}>{theTag.description}</div>
                            }]
                        })}
                        loadingData={false}
                        dataUpdater={() => { }}
                        width="100%"
                        hasCheckBoxes="no"
                    />
                </UILIB.Paper>}
                {(!this.state.filter || this.state.filter === "5") && !!rssTags.length && <UILIB.Paper>
                    <h4 className="mar-b15">RSS Tags</h4>
                    <UILIB.DataTable1
                        noResultsTxt={""}
                        tableData={rssTags.map(theTag => {
                            return [{
                                headerValue: " ",
                                value: <a onClick={() => { this.props.insertMergeTag(theTag.tag, this.props.fieldName) }}>{theTag.tag}</a>
                            },
                            {
                                headerValue: " ",
                                value: <div style={{ whiteSpace: "pre-wrap" }}>{theTag.description}</div>
                            }]
                        })}
                        loadingData={false}
                        dataUpdater={() => { }}
                        width="100%"
                        hasCheckBoxes="no"
                    />
                </UILIB.Paper>}

                {(!this.state.filter || this.state.filter === "2") && !!customTags.length && <UILIB.Paper>
                    <h4 className="mar-b15">{i18n.t('templates:mergetags.custom')}</h4>
                    <UILIB.DataTable1
                        noResultsTxt={""}
                        tableData={customTags.map(theTag => {
                            return [{
                                headerValue: " ",
                                value: <a onClick={() => { this.props.insertMergeTag(theTag.tag, this.props.fieldName) }}>{theTag.tag}</a>
                            },
                            {
                                headerValue: " ",
                                value: <div style={{ whiteSpace: "pre-wrap" }}>{theTag.description}</div>
                            }]
                        })}
                        loadingData={false}
                        dataUpdater={() => { }}
                        width="100%"
                        hasCheckBoxes="no"
                    />
                </UILIB.Paper>}

                {(!this.state.filter || this.state.filter === "3") && !!campaignTags.length && <UILIB.Paper>
                    <h4 className="mar-b15">Campaign</h4>
                    <UILIB.DataTable1
                        noResultsTxt={""}
                        tableData={campaignTags.map(theTag => {
                            return [{
                                headerValue: " ",
                                value: <a onClick={() => { this.props.insertMergeTag(theTag.tag, this.props.fieldName) }}>{theTag.tag}</a>
                            },
                            {
                                headerValue: " ",
                                value: <div style={{ whiteSpace: "pre-wrap" }}>{theTag.description}</div>
                            }]
                        })}
                        loadingData={false}
                        dataUpdater={() => { }}
                        width="100%"
                        hasCheckBoxes="no"
                    />
                </UILIB.Paper>}

                {appTypes.map(prop => {
                    if (this.state.filter && this.state.filter != prop) return ''
                    return <UILIB.Paper key={prop}>
                        <h4 className="mar-b15">{prop}</h4>
                        <UILIB.DataTable1
                            noResultsTxt={""}
                            tableData={apps[prop].map(theTag => {
                                return [{
                                    headerValue: " ",
                                    value: <a onClick={() => { this.props.insertMergeTag(theTag.tag, this.props.fieldName) }}>{theTag.tag}</a>
                                },
                                {
                                    headerValue: " ",
                                    value: <div style={{ whiteSpace: "pre-wrap" }}>{theTag.description}</div>
                                }]
                            })}
                            loadingData={false}
                            dataUpdater={() => { }}
                            width="100%"
                            hasCheckBoxes="no"
                        />
                    </UILIB.Paper>
                })}

                {otherTypes.map(prop => {
                    if (this.state.filter && this.state.filter != prop) return ''
                    return <UILIB.Paper key={prop}>
                        <h4 className="mar-b15">{prop}</h4>
                        <UILIB.DataTable1
                            noResultsTxt={""}
                            tableData={others[prop].map(theTag => {
                                return [{
                                    headerValue: " ",
                                    value: <a onClick={() => { this.props.insertMergeTag(theTag.tag, this.props.fieldName) }}>{theTag.tag}</a>
                                },
                                {
                                    headerValue: " ",
                                    value: <div style={{ whiteSpace: "pre-wrap" }}>{theTag.description}</div>
                                }]
                            })}
                            loadingData={false}
                            dataUpdater={() => { }}
                            width="100%"
                            hasCheckBoxes="no"
                        />

                    </UILIB.Paper>
                })}

            </div>
        );
    }
}

export default CampaignMergeTags;
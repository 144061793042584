import { AdvancedUIToolbarItem } from 'photoeditorsdk';
import React, { Component } from 'react';
import UILIB from '../../../Lib';
class MultiOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
        this.onChange = this.onChange.bind(this)
        this.keyPress = this.keyPress.bind(this)
        this.remove = this.remove.bind(this)
    }

    onChange(ev) {
        this.setState({
            value: ev.currentTarget.value
        })
    }

    keyPress(ev) {
        let key = ev.key
        if (key === 'Enter' && this.state.value.trim()) {
            if (this.props.values.indexOf(this.state.value) === -1) this.props.values.push(this.state.value.trim())
            this.setState({
                value: ''
            })
        }
    }

    remove(index) {
        this.props.values.splice(index, 1)
        this.setState({})
    }

    render() {

        let label = "";
        if ((this.props.label && this.props.labelOptions) || this.props.labelOptions) {
            label = <div className="txtInput_label_flex">
                <div className="txtInput_label">{this.props.label}</div>
                <div className="txtInput_labelOptions">{this.props.labelOptions}</div>
            </div>
        } else if (this.props.label) {
            label = <div className="txtInput_label">{this.props.label}</div>
        }

        let explainer = "";
        if (this.props.explainer) {
            explainer = <div className="txtInput_explainer">{this.props.explainer}</div>
        }

        let outerClassName = "textInputWrapper";
        if (this.props.outerClassName) outerClassName += " " + this.props.outerClassName;



        return (
            <div className={outerClassName}>
                {label}
                <div className="quickFlex" style={{ flexWrap: 'wrap' }}>
                    {this.props.values.map((v, i) => {
                        return <UILIB.SquareChip key={v} className={"mar-r10 mar-b10"} iconRight={<UILIB.Icons icon="cross" />} onClick={() => { this.remove(i) }}>
                            {v}
                        </UILIB.SquareChip>
                    })}
                </div>
                <UILIB.TextInput type="text" value={this.state.value} onChange={this.onChange} onKeyPress={this.keyPress} placeholder="Type Here and Press Enter to Add" />
                {explainer}
            </div >
        );
    }
}

export default MultiOptions;
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import Logo from '../../Common-Objects/Logos/mpzLogo'
import i18n from '~/i18n'

export default class loginAttractMode extends React.Component {

    constructor(props) {
        super(props);
        this.redirectToPage = this.redirectToPage.bind(this);
    }

    redirectToPage(pageURL) {
        this.props.history.push('/' + pageURL);
    }

    render() {

        let header = i18n.t('login:login.leftSubHeader2');
        let subHeader = i18n.t('login:login.leftText');
        if (this.props.registerPage) {
            header = i18n.t('login:register.leftHeader');
            subHeader = i18n.t('login:register.leftText');
        }
        var leftPanelStyle = {};
        return <div className="login-left-panel" style={leftPanelStyle} >

            <div className="logo">
                <Logo title="Logo" width="100px" onClick={() => { this.props.history.push('/') }} style={{ cursor: "pointer" }} />
            </div>
            <div>
                <div className="login-left-panel_lowerContent">
                    <div className="login-left-panel_header">
                        {header}
                    </div>
                    <div className="login-left-panel_subheader">
                        {subHeader}
                    </div>
                </div>
                <div className="login-left_copyright">
                    <span>Made by MPZMail Ltd &copy; {(new Date()).getFullYear()}</span>
                </div>
            </div>
        </div>

    }

}

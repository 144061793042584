import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from '../../../data/http/axios'
import UILIB from '../../../Common-Objects/Lib'
import { alterSiteDrawer } from '../../../data/actions/siteActions'
import DateTimeFunctions from '../../../Classes/dateTimeFunctions'

export default function ContactLinkTags({ SubscriberId }) {
    const [linkTags, setLinksTags] = useState(null)
    const dispatch = useDispatch()

    const getTags = async () => {
        setLinksTags((await axios.get('/subscriber/' + SubscriberId + '/tags?tagType=1')).data)
    }

    const viewHistory = (tag) => {
        dispatch(alterSiteDrawer(true, true, 'right', <LinkTagHistory SubscriberId={SubscriberId} tag={tag} />, true, 800))
    }

    useEffect(() => {
        getTags()
    }, [SubscriberId])

    if (!linkTags) return <UILIB.LoadingIcons />

    console.log()

    return (
        <UILIB.Paper className="mar-t20 mar-b0">
            <h4 className='mar-b20'>Link Tags</h4>
            {!linkTags.length && <div>No Link Tags Found</div>}
            {linkTags.map(tag => <UILIB.SquareChip
                key={tag.id}
                className="square-chip square-chip-large mar-r10 mar-b10"
                onClick={() => viewHistory(tag)}
                iconRight={<UILIB.Icons icon="arrowRight" />}
            >
                {tag.tagName}
            </UILIB.SquareChip>)}
        </UILIB.Paper>
    )
}

function LinkTagHistory({ SubscriberId, tag }) {
    const history = useHistory()
    const [results, setResults] = useState(null)

    const getHistory = async () => {
        setResults((await axios.get('/subscriber/' + SubscriberId + '/tags/' + tag.id + '/history')).data.reverse())
    }

    useEffect(() => {
        getHistory()
    }, [SubscriberId, tag.id])

    if (!results) return <UILIB.LoadingIcons />

    const data = results.map(row => {
        return {
            date: {
                headerValue: 'Date',
                value: DateTimeFunctions.formatDateTime(row.createdAt)
            },
            link: {
                headerValue: 'Link',
                value: row.LinkTag.link,
            },
            campaign: {
                headerValue: 'Campaign',
                value: row.Campaign ? <a onClick={() => history.push('/cp/campaigns/view/' + row.CampaignId)}>{row.Campaign.campaignName}</a> : 'DELETED'
            }
        }
    })

    return (
        <div>
            <h4 className='mar-b25'>{tag.tagName} Clicks</h4>
            <UILIB.DataTable1
                tableData={data}
            />
        </div>
    )
}
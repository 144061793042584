import axios from 'axios';
import DBConnection from '../../Classes/siteVars';
import store from '../store/store';

var ua = navigator.userAgent.toLowerCase();
let isSafari = false
if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1) {
        isSafari = false
    } else {
        isSafari = true
    }
}

var axiosInstance = axios.create();

axiosInstance.interceptors.request.use(config => {
    var state = store.getState();
    config.withCredentials = true;
    if (state.user && state.user.token) {
        if (config.method !== 'OPTIONS') {
            config.headers.Authorization = 'Bearer ' + state.user.token
        }
        var baseUrl = DBConnection.getConnString();
        config.url = baseUrl + config.url;
        if (config.url.indexOf('?') > -1) {
            config.url += '&returnUser=true&returnMaster=true'
        } else {
            config.url += '?returnUser=true&returnMaster=true'
        }
        return config;
    } else {
        store.dispatch({ type: 'LOGOUT_USER', user: state.user });
        if (window.location.search.indexOf('redirect=') > -1) {
            window.location = '/' + window.location.search;
        } else {
            window.location = '/';
        }
        return Promise.reject('No Token');
    }
}, function (err) {
    var state = store.getState();
    store.dispatch({ type: 'LOGOUT_USER', user: state.user });
    window.location = '/';
    return Promise.reject('No Token');
})

axiosInstance.interceptors.response.use(function (response) {
    // Do something with response data
    // check if there is a x-user obj on the response headers, if so then bind that to redux
    var state = store.getState()
    if (response.headers['x-user'] && state.user && state.user.loggedIn) {
        var user = JSON.parse(decodeURIComponent(response.headers['x-user']))

        if (JSON.stringify(user) !== JSON.stringify(state.user.userData)) {
            store.dispatch({ type: "LOADUSER_SUCCESS", payload: user });
        }
    }
    if (response.headers['x-master'] && state.user && state.user.loggedIn) {
        var master = JSON.parse(decodeURIComponent(response.headers['x-master']))

        if (JSON.stringify(master) !== JSON.stringify(state.accountMaster.accountMaster)) {
            store.dispatch({ type: "CHANGE_ACCOUNTMASTER", payload: { AccountMaster: master } });
        }
    }
    if (response.headers['x-token']) {
        // update the stored token
        store.dispatch({ type: 'USER_CHANGE_ACCOUNTMASTER', payload: { token: response.headers['x-token'] } })
    }

    return response;
}, function (error) {
    if (error.message == 'Network Error' && window.location.pathname.indexOf('notfound') === -1) {
        if (error.config.url.indexOf('accountuser/alert') == -1 && !isSafari) {
            store.dispatch({ type: 'SET_CONNECTION_ERROR', payload: 'Connection Lost' });
            setTimeout(() => {
                store.dispatch({ type: 'SET_CONNECTION_ERROR', payload: '' });
            }, 2500)
        }
    } else if (error.response && (error.response.status === 401)) {
        var state = store.getState();
        store.dispatch({ type: 'LOGOUT_USER', user: state.user });
        window.location = '/';
    }
    return Promise.reject(error.response);
});



export default axiosInstance;
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import { connect } from 'react-redux';
import LineChart from './graph'
import PieChart from './pie'
import PermissionChecker from '~/Classes/permissions';

import GenericStatsHolder from '~/pages/cp/campaigns/views/shared/components/genericStatsHolder';
import FormAutomate from '../automate';
import { alterSiteDrawer } from '~/data/actions/siteActions';

@connect((store) => {
    return { permissionStore: store.permission, siteMaster: store.siteMaster, user: store.user }
})
class FormView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: undefined,
            loaded: false,
            groupOptions: [{
                value: 'HOUR',
                label: i18n.t('campaigns:overview.groupHour'),
                color: 'purple'
            }, {
                value: 'DAYOFWEEK',
                label: i18n.t('campaigns:overview.groupWeekday'),
                color: 'red'
            }, {
                value: 'WEEK',
                label: i18n.t('campaigns:overview.groupWeek'),
                color: 'green'
            }, {
                value: 'MONTHNAME',
                label: i18n.t('campaigns:overview.groupMonth'),
                color: 'blue'
            }, {
                value: 'YEAR',
                label: i18n.t('campaigns:overview.groupYear'),
                color: 'orange'
            }],
            currentGroupBy: 1,
            statOptions: [{
                label: i18n.t('campaigns:overview.statSends'),
                value: 'sends',
                link: 'sent'
            }, {
                label: i18n.t('campaigns:overview.statOpens'),
                value: 'opens',
                link: 'opened'
            }, {
                label: i18n.t('campaigns:overview.statClicks'),
                value: 'clicks',
                link: 'clicked'
            }, {
                label: i18n.t('campaigns:overview.statUnsubscribes'),
                value: 'unsubscribes',
                link: 'unsubscribed'
            }],
            currentStat: 1,
        }
        this.getForm = this.getForm.bind(this)
        this.viewSubs = this.viewSubs.bind(this)
    }

    componentDidMount() {
        this.getForm()
    }

    getForm() {
        axios.get('/group/' + this.props.match.params.groupId + '/form/' + this.props.match.params.formId)
            .then(response => {
                this.setState({
                    form: response.data.Form,
                    loaded: true
                })
            })
    }

    viewSubs() {
        this.props.history.push('/cp/groups/' + this.props.match.params.groupId + '/forms/view/' + this.props.match.params.formId + '/signups')
    }

    viewDownloads() {
        this.props.history.push('/cp/groups/' + this.props.match.params.groupId + '/forms/view/' + this.props.match.params.formId + '/downloads')
    }

    automate() {
        const drawerContent = <FormAutomate formName={this.state.form.formName} GroupId={this.props.match.params.groupId} FormId={this.props.match.params.formId} edit={false} />
        this.props.dispatch(alterSiteDrawer(true, true, 'right', drawerContent, true, 500))
    }

    render() {
        if (!this.state.loaded) return <div></div>

        var colours = this.props.siteMaster.colours;
        var canEdit = PermissionChecker('forms', this.props.permissionStore.permissions, "write");
        const showDownloads = !!this.state.form.FormFiles.length
        return <UILIB.Paper>


            <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignContent: "center" }}>
                <h4>{this.state.form.formName}</h4>
                <div className='quickFlex' style={{ alignItems: 'center' }}>
                    <UILIB.Button iconRight={<UILIB.Icons icon="lightning" />} onClick={this.automate.bind(this)}>Automate</UILIB.Button>
                    {(canEdit == true) && <UILIB.Button
                        className="button-primary mar-l10"
                        text={i18n.t('form:view.editDeploy')}
                        onClick={() => this.props.history.push('/cp/groups/' + this.props.match.params.groupId + '/forms/edit/' + this.props.match.params.formId)}
                        iconLeft={<UILIB.Icons icon="magnifier" />}
                    />}
                </div>
            </div>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={showDownloads ? 4 : 6}>
                    <GenericStatsHolder
                        value={this.state.form.impressions || 0}
                        limit={-1}
                        header={i18n.t('form:view.impressions')}
                    />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={showDownloads ? 4 : 6}>
                    <GenericStatsHolder
                        value={this.state.form.signups || 0}
                        limit={-1}
                        header={i18n.t('form:view.signups')}
                        link={this.viewSubs}
                        linkIcon="magnifier"
                        linkText={i18n.t('form:view.view')}
                    />
                </UILIB.Column>
                {showDownloads && <UILIB.Column xs={12} sm={showDownloads ? 4 : 6}>
                    <GenericStatsHolder
                        value={this.state.form.downloads || 0}
                        limit={-1}
                        header={i18n.t('form:view.downloads')}
                        link={this.viewDownloads.bind(this)}
                        linkIcon="magnifier"
                        linkText={i18n.t('form:view.view')}
                    />
                </UILIB.Column>}

                <UILIB.Column xs={12}>
                    <UILIB.Paper className="mar-b25">
                        <LineChart
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            groupId={this.props.match.params.groupId}
                            formId={this.props.match.params.formId}
                            groupByData={this.state.groupOptions[this.state.currentGroupBy]}
                            statOptions={this.state.statOptions[this.state.currentStat]}
                        ></LineChart>
                    </UILIB.Paper>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={12} md={6}>
                    <UILIB.Paper>
                        <PieChart className="full-height" friendlyName="Impressions" groupId={this.props.match.params.groupId} formId={this.props.match.params.formId} stat="device" source="FormOpens" groupByData={this.state.groupOptions[this.state.currentGroupBy]} />
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6}>
                    <UILIB.Paper>
                        <PieChart className="full-height" friendlyName="Signups" groupId={this.props.match.params.groupId} formId={this.props.match.params.formId} stat="device" source="FormSignups" groupByData={this.state.groupOptions[this.state.currentGroupBy]} />
                    </UILIB.Paper>
                </UILIB.Column>

            </UILIB.Row>
        </UILIB.Paper>
    }
}

export default FormView;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SZz53XlkvDPKEH2JFaCv{text-decoration:none;color:var(--grey-950);display:flex;align-items:center;justify-content:space-between;gap:12px;padding:8px;font-weight:500;border-radius:8px;transition:background .2s ease}.SZz53XlkvDPKEH2JFaCv:hover,.SZz53XlkvDPKEH2JFaCv:focus,.SZz53XlkvDPKEH2JFaCv.cktuxUcmCTCbVHzFa4xE{background:var(--grey-50)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/components/settingsNavItem/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,qBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CACA,8BAAA,CAEA,mGAGE,yBAAA","sourcesContent":[".root {\n  text-decoration: none;\n  color: var(--grey-950);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 12px;\n  padding: 8px;\n  font-weight: 500;\n  border-radius: 8px;\n  transition: background 0.2s ease;\n\n  &:hover,\n  &:focus,\n  &.selected {\n    background: var(--grey-50);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SZz53XlkvDPKEH2JFaCv",
	"selected": "cktuxUcmCTCbVHzFa4xE"
};
export default ___CSS_LOADER_EXPORT___;

import React, { Fragment } from 'react'
import axios from '~/data/http/axios';
import UILIB from '../../../Common-Objects/Lib';
import { connect, useDispatch } from 'react-redux';
import * as siteMaster from '../../../data/actions/siteActions'
import i18n from '~/i18n'

import moment from 'moment'
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
const Map = ReactMapboxGl({
    accessToken: "pk.eyJ1IjoiY29udGFjdGdhdGUiLCJhIjoiODI5ZDJlOWNiNGM5YTM4OGM1Mzk2ODA2MjI4MTI2YmQifQ.iEJBZeGfK-7O2nOkPxIBDA"
});
import IncDeleteSubscriber from './incGoDeleteSub';
import IncGoRemoveFromGroup from './incGoRemoveFromGroup';
import PermissionChecker from '~/Classes/permissions';

import FileDownload from 'js-file-download';
import DateTimeFunctions from '../../../Classes/dateTimeFunctions';
import helpers from '../../../Classes/helpers'
import ViewTransactionDrawer from '~/pages/cp/includes/transactions/viewTransactionDrawer'
import Sms from './sms'
import Organisation from './organisation'

import Man404 from '~/assets/images/genericpagenotfound/404guy.png'

import ContactDetails from './contactDetails'
import FormatNumberFuncs from '../../../Classes/numberFormatFunctions';
import ContactFields from './fields';
import ContactLinkTags from './linkTags';
import SubscriberSendMessage from './sendMessage';
import SubscriberAutomations from './automations';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

export default class SubscriberView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignStatsSortColumn: "date",
            campaignStatsSortDirection: "desc",
            subscriberData: {},
            subscriberID: 0,
            pageReady: false,
            statsLoaded: false,
            groupMembership: [],
            groups: [],
            saving: false,
            dataError: null,
            geo: {
                longitude: 51.5139573,
                latitude: -0.1148677
            },
            campaignStats: [],
            formSignupData: {},
            alertMessage: '',
            alertOpen: false,
            campaignHistoryMaxHeight: 0,
            writeAccess: true,
            stats: {},
            accApps: [],
            segments: [],
            audits: [],
            inboxes: [],
            displayedStats: {
                opens: true,
                sends: true,
                clicks: true,
                bounces: true,
                tracking: true,
                replies: true,
                automations: true
            },
            statsTabSelected: "all",
            subFilterReply: true,
            subFilterOpen: true,
            subFilterClick: true,
            subFilterSent: true,
            subFilterBounce: true,
            subFilterUnsub: true,
            failedToLoadSubscriber: false
        };

        this.lookupSubscriber = this.lookupSubscriber.bind(this);
        this.lookupForm = this.lookupForm.bind(this);
        this.updateEmailAddress = this.updateEmailAddress.bind(this)
        this.updateCustomField = this.updateCustomField.bind(this);
        this.deleteGroupConfirm = this.deleteGroupConfirm.bind(this);
        this.saveCustomFields = this.saveCustomFields.bind(this);
        this.getStats = this.getStats.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.removedSubscriber = this.removedSubscriber.bind(this);
        this.downloadAllData = this.downloadAllData.bind(this);
        this.toggleStat = this.toggleStat.bind(this)
        this.renderStats = this.renderStats.bind(this)
        this.previewTemplate = this.previewTemplate.bind(this);
        this.checkSegments = this.checkSegments.bind(this)
        this.addToGroup = this.addToGroup.bind(this)
        this.campaignStatsUpdater = this.campaignStatsUpdater.bind(this);
        this.updateSubFilter = this.updateSubFilter.bind(this);
        this.showTransaction = this.showTransaction.bind(this);
        this.exportSubscriberActions = this.exportSubscriberActions.bind(this);
        this.getAudits = this.getAudits.bind(this)
        this.updatePhoneNumber = this.updatePhoneNumber.bind(this)
        this.renderCustomField = this.renderCustomField.bind(this)
        this.sendMessage = this.sendMessage.bind(this)
    }

    componentDidUpdate() {
        if (this.state.pageReady && !this.state.failedToLoadSubscriber && this.state.campaignHistoryMaxHeight === 0) {
            var subDataHolderHeight = document.getElementById('col1').clientHeight;
            // var mapHolderHeight = this.state.sends.filter(s => s.clickCount).length ? 320 : 0;
            // var campaignHistoryMax = subDataHolderHeight - mapHolderHeight;
            if (subDataHolderHeight < 300) {
                subDataHolderHeight = 300;
            }
            this.setState({ campaignHistoryMaxHeight: 1900 })

        }
    }

    componentDidMount() {
        //see if we have write access to the subscribers
        var writeAccess = PermissionChecker("subscribers", this.props.permissionStore.permissions, "write");
        // console.log(writeAccess)
        this.setState({ writeAccess: writeAccess, subscriberID: this.props.match.params.subscriberID }, this.lookupSubscriber)

        this.checkSegments()

        axios.get('/application/account').then(res => {
            this.setState({
                accApps: res.data.Applications
            })
        })

        axios.get('/group/simple').then(res => {
            this.setState({
                groups: res.data.Groups
            })
        })

        axios.get('/subscriber/' + this.props.match.params.subscriberID + '/ratios').then(res => {
            this.setState({
                ratios: res.data
            })
        })

        axios.get('/inboxes?mine=true').then(res => {
            this.setState({ inboxes: res.data.filter(i => i.InboxChannels.some(c => c.status === 'running')) })
        })

    }

    checkSegments() {
        axios.get('/subscriber/' + this.props.match.params.subscriberID + '/segments').then(res => {
            this.setState({
                segments: res.data
            })
        })
    }

    getAudits() {
        if (!this.props.accountMaster.accountMaster.beta || !this.props.accountMaster.accountMaster.beta.subscriberAudit) {
            return this.renderStats()
        }
        axios.get('/subscriber/' + this.props.match.params.subscriberID + '/audit').then(res => {
            this.setState({
                audits: res.data
            }, this.renderStats)
        })
    }

    lookupSubscriber() {
        axios.get('/subscriber/' + this.state.subscriberID + '/groups').then((response) => {

            var subscriber = response.data.Subscriber;
            if (subscriber && subscriber.customFields) {
                const important = subscriber.customFields.filter(f => f.important)
                const notImportant = subscriber.customFields.filter(f => !f.important)
                function sort(a, b) {
                    return (a.fieldDesc > b.fieldDesc) ? 1 : ((b.fieldDesc > a.fieldDesc) ? -1 : 0)
                }
                subscriber.customFields = important.sort(sort).concat(notImportant.sort(sort))
            }

            this.setState({
                subscriberData: subscriber,
                groupMembership: response.data.Subscriber.Groups,
                pageReady: true,
                addingToGroup: false
            }, () => {
                if (response.data.Subscriber.importId && response.data.Subscriber.importId == "-10") {
                    this.lookupForm();
                }
                else {
                    this.getStats()
                }

                if (response.data.Subscriber.ApplicationDataId) {
                    axios.get('/subscriber/' + this.props.match.params.subscriberID + '/application').then(res => {
                        const applications = (Array.isArray(res.data) ? res.data : [res.data])
                        applications.forEach(a => {
                            if (a.link && a.link.indexOf('//party' > -1)) {
                                a.link = a.link.replace('//party', '/party')
                            }
                        })
                        this.setState({
                            applications
                        })
                    })
                }
            })
        }).catch(err => {
            this.setState({ failedToLoadSubscriber: true, pageReady: true })
        })
    }

    lookupForm() {
        var self = this;
        axios.get('/group/0/form/' + this.state.subscriberData.importDataId).then((response) => {
            self.setState({ signupForm: response.data.Form }, self.lookupFormSignup)
        }).catch(err => {
            self.getStats();
        })
    }

    lookupFormSignup() {
        var self = this;
        axios.get('/group/0/form/' + this.state.subscriberData.importDataId + '/subscriber/' + this.state.subscriberID).then((response) => {
            self.setState({ formSignupData: response.data.FormSignup }, self.getStats)
        }).catch(err => {
            self.getStats();
        })
    }

    downloadAllData() {
        var self = this;
        axios.get('/subscriber/' + self.state.subscriberID + '/downloadAllData')
            .then(function (res) {
                var userData = JSON.stringify(res.data.UserData);
                FileDownload(userData, 'Subscriber-' + self.state.subscriberID + '-Export.json');
            }).catch(err => {
                console.log(err);
            });
    }

    sendMessage() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, 'right', <SubscriberSendMessage subscriber={this.state.subscriberData} inboxes={this.state.inboxes} onClose={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, 'right', null))
            this.lookupSubscriber()
        }} />, true, 900))
    }

    toggleStat(stat) {
        var displayedStats = this.state.displayedStats
        displayedStats[stat] = !displayedStats[stat]
        this.setState({ displayedStats }, this.renderStats)
    }

    campaignStatsUpdater() {

    }

    showTransaction(transactionId) {
        var drawerContent = <ViewTransactionDrawer transactionId={transactionId} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }


    renderStats() {

        var createdAtTitle = "Contact created manually";
        let firstAddedIcon = "personAdd"
        if (this.state.subscriberData.GroupSubscriberImport) {
            if (this.state.subscriberData.GroupSubscriberImport.AccountApplicationId) {
                var app = this.state.accApps.find(a => a.id === this.state.subscriberData.GroupSubscriberImport.AccountApplicationId)
                if (app) {
                    createdAtTitle = 'Contact imported from ' + app.Application.appName;
                } else {

                    createdAtTitle = 'Contact imported from an external source';
                }
            } else {
                createdAtTitle = <div style={{ display: "flex" }}>
                    <UILIB.Avatar style={{ height: 'auto', width: '100%', maxWidth: "20px", maxHeight: "20px", marginRight: "10px" }} src={this.state.subscriberData.GroupSubscriberImport.importUserProfilePicFileName} tooltip={this.state.subscriberData.GroupSubscriberImport.importUserFirstName + " " + this.state.subscriberData.GroupSubscriberImport.importUserLastName}></UILIB.Avatar>
                    imported from file {this.state.subscriberData.GroupSubscriberImport.origFileName} by {this.state.subscriberData.GroupSubscriberImport.importUserFirstName + " " + this.state.subscriberData.GroupSubscriberImport.importUserLastName}
                </div>;
            }
        }
        else if (this.state.subscriberData.importId && this.state.subscriberData.importId == -10) {

            if (this.state.signupForm) {
                createdAtTitle = "Contact created from form " + this.state.signupForm.formName;
                if (this.state.formSignupData && this.state.formSignupData.ip) createdAtTitle += " with IP address " + this.state.formSignupData.ip;
            }
            else {
                createdAtTitle = "Contact created from a form that has now been deleted";
            }
        } else if (this.state.subscriberData.importId == -17) {
            createdAtTitle = "Contact created with the API";
        } else if (this.state.subscriberData.ApplicationId) {
            const app = this.state.accApps.find(a => a.ApplicationId == this.state.subscriberData.ApplicationId)
            if (app) {
                createdAtTitle = "Contact imported from " + app.Application.appName
                firstAddedIcon = <UILIB.Avatar src={app.Application.appLogoUri} style={{ height: 25, width: 25 }} />
            }
        }
        var results = [{
            createdAt: this.state.subscriberData.createdAt,
            firstActionEver: true,
            createdAtTitle: createdAtTitle
        }]

        results = results.concat(this.state.stats.clicks.map(s => { s.origType = "click"; return s }));
        results = results.concat(this.state.stats.opens.map(s => { s.origType = "open"; return s }));
        results = results.concat(this.state.stats.sends.map(s => { s.origType = "send"; return s }));
        results = results.concat(this.state.stats.bounces.map(s => { s.origType = "bounce"; return s }));
        results = results.concat(this.state.stats.tracking.map(s => { s.origType = "track"; return s }));
        results = results.concat(this.state.stats.replies.map(s => { s.origType = "reply"; return s }));
        results = results.concat(this.state.stats.automations.map(s => { s.origType = "auto"; return s }));
        results = results.concat(this.state.audits.map(s => { s.origType = "audit"; return s }));
        results.sort((a, b) => {
            return moment(b.sendDate || b.createdAt) - moment(a.sendDate || a.createdAt)
        });

        var tableData = results.map(row => {
            var createdAt;
            var type;
            var typeRaw;
            var icon;
            var lineDesc = "N/A";
            var actions = [];
            var textOut = "";
            var campaignNameOut = "";

            if (row.firstActionEver) {
                createdAt = row.createdAt;
                icon = firstAddedIcon;
                typeRaw = "firstAdded"
                lineDesc = <div>
                    {createdAtTitle}
                </div>
                textOut = createdAtTitle + " ";
            }
            else if (row.origType === 'audit') {
                createdAt = row.createdAt;
                icon = "history"
                typeRaw = "audit"
                let fieldName = typeof row.field === 'object' ? row.field.fieldDesc : row.field
                if (row.field.options?.length) {
                    if (row.field.fieldType === 'MULTISELECT') {
                        row.prevValue = row.prevValue.split(',').map(v => row.field.options.find(o => o.value == v)).filter(v => v !== undefined).map(v => v.label).join(', ')
                        row.nextValue = row.nextValue.split(',').map(v => row.field.options.find(o => o.value == v)).filter(v => v !== undefined).map(v => v.label).join(', ')
                    } else {
                        row.prevValue = row.field.options.find(o => o.value == row.prevValue)
                        if (row.prevValue) row.prevValue = row.prevValue.label
                        row.nextValue = row.field.options.find(o => o.value == row.nextValue)
                        if (row.nextValue) row.nextValue = row.nextValue.label
                    }
                } else if (row.field.fieldType) {
                    // parse the type
                    if (row.field.fieldType === 'DATE') {
                        row.prevValue = !row.prevValue ? row.prevValue : DateTimeFunctions.formatDateTime(row.prevValue, "3")
                        row.nextValue = !row.nextValue ? row.nextValue : DateTimeFunctions.formatDateTime(row.nextValue, "3")
                    }
                }
                if (fieldName === 'emailAddress') fieldName = 'Email Address'
                if (fieldName === 'Tags') {
                    lineDesc = <div>
                        <strong>Tags</strong> changed from {row.prevValue ? <UILIB.Tags style={{ display: 'inline-block' }} className="mar-b0" tagsSelected={Array.isArray(row.prevValue) ? row.prevValue : row.prevValue ? row.prevValue.split(',') : []} tagType={0} disableEdit={true} /> : <strong>--BLANK--</strong>} to <UILIB.Tags style={{ display: 'inline-block' }} className="mar-b0" tagsSelected={Array.isArray(row.nextValue) ? row.nextValue : row.nextValue ? row.nextValue.split(',') : []} tagType={0} disableEdit={true} />
                    </div>
                } else {
                    if (row.prevValue === undefined || row.prevValue === "") row.prevValue = "--BLANK--"
                    if (row.nextValue === undefined || row.nextValue === "") row.nextValue = "--BLANK--"
                    lineDesc = <div>
                        <strong>{fieldName}</strong> changed from <strong>{row.prevValue}</strong> to <strong>{row.nextValue}</strong>
                    </div>
                }
                textOut = fieldName + " changed"
            }
            else if (row.origType == "auto") {
                createdAt = row.createdAt;
                icon = "lightning";
                typeRaw = "automation";
                var automationName = row.name;
                if (automationName.length > 40) automationName = automationName.substr(0, 38) + "..";
                lineDesc = <div>
                    <div>Automation step processed <strong>{automationName}</strong></div>
                    <div dangerouslySetInnerHTML={{ __html: row.stepDesc }}></div>
                </div>
                textOut = `Automation step processed ${row.name} ${row.stepDesc}`
                actions.push(<UILIB.Button key={"auto-" + row.id} className="secondary" text="View Automation" onClick={() => { this.props.history.push("/cp/automation/addnew/" + row.AutomationId) }} />);
            } else if (row.Campaign && !row.url && (!row.Transaction && (!row.SendingQueue || (row.SendingQueue && !row.SendingQueue.Transaction)))) {
                createdAt = row.createdAt;
                icon = "envelopeOpen";
                typeRaw = "open";
                var campaignName = row.Campaign.campaignName;
                campaignNameOut = campaignName;
                if (campaignName.length > 40) campaignName = campaignName.substr(0, 38) + "..";
                lineDesc = <span>
                    {i18n.t('subscribers:subscriber.open')}
                    &nbsp;<strong>{campaignName}</strong>
                </span>
                textOut = `${i18n.t('subscribers:subscriber.open')} ${row.Campaign.campaignName}`
                actions.push(<UILIB.Button key={'open-' + row.id} className="secondary" text="View Campaign" onClick={() => { this.props.history.push("/cp/campaigns/view/" + row.Campaign.id) }} />);

                if (row.origType == "click" && row.TemplateLink && row.TemplateLink.link) {
                    var linkUrl = row.TemplateLink.link
                    if (linkUrl.length > 40) linkUrl = linkUrl.substr(0, 38) + "..";
                    lineDesc = <div>
                        <div>{i18n.t('subscribers:subscriber.click')}&nbsp;<strong><a href={row.TemplateLink.link} target="_blank">{linkUrl}</a></strong></div>
                        <div>in campaign <strong>{campaignName}</strong></div>
                    </div >;
                    textOut = `${i18n.t('subscribers:subscriber.click')} ${row.TemplateLink.link}`
                    icon = "button";
                    actions.push(<UILIB.Button key={'click-' + row.id} className="mar-l5 secondary" text="View Clicks" onClick={() => { this.props.history.push("/cp/campaigns/view/" + row.Campaign.id + "/clicked") }} />);
                    typeRaw = "click";
                }
                if (row.origType == "send") {
                    icon = "envelope";
                    createdAt = row.sendDate
                    lineDesc = <div>
                        {i18n.t('subscribers:subscriber.sent')} <strong>{campaignName}</strong>
                    </div>
                    textOut = `${i18n.t('subscribers:subscriber.sent')} ${row.Campaign.campaignName}`
                    typeRaw = "sent";
                    actions.push(<UILIB.Button key={'send-' + row.id} className="mar-l5 secondary" text={i18n.t('subscribers:subscriber.viewEmailSent')} onClick={() => { this.previewTemplate(row) }} />);
                }
                if (row.origType == "bounce") {
                    typeRaw = "bounce";
                    icon = "arrowBounce"
                    lineDesc = <div>
                        {row.type ? "Hard" : "Soft"} {i18n.t('subscribers:subscriber.bouncedAfterSending')} <strong>{campaignName}</strong>
                    </div>
                    textOut = `${row.type ? "Hard" : "Soft"} ${i18n.t('subscribers:subscriber.bouncedAfterSending')} ${row.Campaign.campaignName}`
                }
                if (row.unsubscribeDate) {
                    createdAt = row.unsubscribeDate;
                    typeRaw = "unsubscribe";
                    icon = "crossCircle";
                    lineDesc = <div>
                        Unsubscribed after being sent <strong>{campaignName}</strong>
                    </div>
                    textOut = `Unsubscribed after being sent ${row.Campaign.campaignName}`
                }
                if (row.origType == "reply") {
                    var mailBoxAddress = "";
                    if (row.Mailbox) mailBoxAddress = row.Mailbox.emailAddress
                    icon = "reply"
                    typeRaw = "reply";
                    lineDesc = <span>{i18n.t('subscribers:subscriber.repliedTo')} {mailBoxAddress} {i18n.t('subscribers:subscriber.from')} <a href={"/cp/campaigns/view/" + row.Campaign.id}>{campaignName}</a></span>
                    textOut = `${i18n.t('subscribers:subscriber.repliedTo')} ${mailBoxAddress} ${i18n.t('subscribers:subscriber.from')} ${row.Campaign.campaignName}`
                }


            }
            else if (row.Campaign && !row.url && (row.Transaction || (row.SendingQueue && row.SendingQueue.Transaction))) {
                var transId = 0;
                var transSubject = "";
                if (row.Transaction) {
                    transId = row.Transaction.id;
                    transSubject = row.Transaction.subject;
                }
                if (row.SendingQueue && row.SendingQueue.Transaction) {
                    transId = row.SendingQueue.Transaction.id;
                    transSubject = row.SendingQueue.Transaction.subject;
                }
                createdAt = row.createdAt;
                icon = "envelopeOpen";
                typeRaw = "open";
                lineDesc = <span>
                    Opened transaction <strong>{transId}: {transSubject}</strong>
                </span>
                textOut = `Opened transaction ${transId}: ${transSubject}`
                if (row.sendDate) {
                    icon = "envelope";
                    createdAt = row.sendDate
                    lineDesc = <span>
                        Sent transaction <strong>{transId}: {transSubject}</strong>
                    </span>
                    textOut = `Sent transaction ${transId}: ${transSubject}`
                    typeRaw = "sent";
                } else if (row.TemplateLink === null) {
                    icon = "button";
                    lineDesc = <span>
                        Clicked transaction <strong>{transId}: {transSubject}</strong>
                    </span>
                    textOut = `Clicked transaction ${transId}: ${transSubject}`
                    typeRaw = "click";
                }

                if (row.origType == "bounce") {
                    typeRaw = "bounce";
                    icon = "arrowBounce"
                    lineDesc = <div>
                        {row.type ? "Hard" : "Soft"} {i18n.t('subscribers:subscriber.bouncedAfterSending')} <strong>{transSubject}</strong>
                    </div>
                    textOut = `${row.type ? "Hard" : "Soft"} ${i18n.t('subscribers:subscriber.bouncedAfterSending')} ${transSubject}`
                }

                actions.push(<UILIB.Button key={'open-' + row.id} className="secondary" text="View Transaction" onClick={() => { this.showTransaction(transId) }} />);

            }
            else if (row.url) {
                createdAt = row.createdAt
                icon = "globe"
                typeRaw = "site";
                var fullUrl = "https://" + row.url + row.path
                var url = row.url + row.path;
                if (url.length > 40) url = url.substr(0, 38) + ".."

                if (row.device && row.device == "bot") {
                    icon = "surveillance"
                }
                var campaignName = "";
                if (row.Campaign) {
                    actions.push(<UILIB.Button key={'site-' + row.id} className="secondary" text="View Campaign" onClick={() => { this.props.history.push("/cp/campaigns/view/" + row.Campaign.id) }} />);
                    campaignName = row.Campaign.campaignName;
                    campaignNameOut = campaignName;
                    if (campaignName.length > 40) campaignName = campaignName.substr(0, 38) + "..";
                }

                let siteTrackerEvents = [];
                if (row.SiteTrackerEventLogs) {
                    row.SiteTrackerEventLogs.forEach(stEvLog => {
                        if (stEvLog.SiteTrackerEvent) {
                            let value = "";
                            if (stEvLog.value) value = " - " + FormatNumberFuncs.formatNumber(stEvLog.value, 2);
                            siteTrackerEvents.push(<UILIB.SquareChip>{stEvLog.SiteTrackerEvent.name + value}</UILIB.SquareChip>)
                        }
                    })
                }

                lineDesc = <div>
                    {(row.device && row.device == "bot") && <span style={{ color: "red" }}>ROBOT </span>}
                    {(row.SiteTrackerEventLogs && row.Site)}
                    {i18n.t('subscribers:subscriber.visited')} <a href={fullUrl}>{url}</a>
                    {!!row.Campaign && <div>from <strong>{campaignName}</strong></div>}
                    {(siteTrackerEvents && siteTrackerEvents.length > 0) && <div>{siteTrackerEvents}</div>}
                </div>
                if (row.device && row.device == "bot") textOut = "ROBOT ";
                textOut += `${i18n.t('subscribers:subscriber.visited')} ${fullUrl}`
                if (row.Campaign) textOut += ` from ${row.Campaign.campaignName}`

            }
            return {
                icon: {
                    headerValue: "",
                    value: typeof icon === 'string' ? <UILIB.Icons icon={icon} color="#A9A9AD" /> : icon,
                    rawValue: typeRaw,
                    textOut: textOut,
                    width: 10,
                    campaignName: campaignNameOut
                },
                createdAt: {
                    headerValue: "Date",
                    value: DateTimeFunctions.formatDateTime(createdAt, 3),
                    raw: createdAt
                },
                details: {
                    headerValue: "Details",
                    value: lineDesc
                },
                actions: {
                    headerValue: "",
                    value: actions,
                    align: "right"
                }
            }
        })

        tableData.sort((a, b) => {
            return new Date(b.createdAt.raw) - new Date(a.createdAt.raw)
        })

        tableData.sort((a, b) => {
            return new Date(b.createdAt.raw) - new Date(a.createdAt.raw)
        })

        this.setState({
            campaignStats: tableData
        })
    }

    getStats() {
        var self = this;
        axios.get('/subscriber/' + self.state.subscriberID + '/stats')
            .then(response => {
                var updateObj = {
                    stats: response.data.Stats,
                    statsLoaded: true,
                    sends: response.data.Stats.sends
                }
                if (response.data.Stats.geolocation) {
                    updateObj.geo = response.data.Stats.geolocation
                }
                self.setState(updateObj, this.getAudits)
            })
            .catch(function (error) {
                self.setState({ alertMessage: i18n.t('subscribers:subscriber.errorRetrieving'), alertOpen: true })
            });
    }

    saveCustomFields() {
        const data = JSON.parse(JSON.stringify(this.state.subscriberData))
        const multi = data.customFields.filter(cf => cf.fieldType === 'MULTISELECT' && !Array.isArray(cf.options))
        multi.forEach(cf => {
            if (typeof cf.value === 'string') {
                cf.value = cf.value.split(',').map(v => v.trim())
            }
        })

        const invalidPhonesNumber = data.customFields.filter(f => f.fieldType === 'TELNO' && f.valid === false && f.value)
        if (invalidPhonesNumber.length) {
            return this.setState({
                alertMessage: invalidPhonesNumber.map(f => f.fieldDesc).join(', ') + ' Invalid',
                alertOpen: true
            })
        }

        this.setState({ alertMessage: i18n.t('subscribers:subscriber.saving'), alertOpen: true, saving: true })
        axios.put("/subscriber/" + this.state.subscriberID, data)
            .then(() => {
                this.setState({ alertMessage: i18n.t('subscribers:subscriber.saved'), alertOpen: true, saving: false })
                this.checkSegments()
            })
            .catch(() => {
                this.setState({ alertMessage: i18n.t('subscribers:subscriber.saveError'), alertOpen: true, saving: false })
            })
    }

    updateEmailAddress(ev) {
        var subscriber = this.state.subscriberData
        subscriber.emailAddress = ev.currentTarget.value
        this.setState({
            subscriberData: subscriber
        })
    }

    updatePhoneNumber(index, value, valid) {
        var subscriber = this.state.subscriberData
        var field = subscriber.customFields[index]
        field.value = value
        field.valid = valid

        this.setState({ subscriberData: subscriber });
    }

    updateCustomField(index, value, fieldType, extraData) {


        var subscriber = this.state.subscriberData
        var field = subscriber.customFields[index]

        if (fieldType == "multiselect") {
            var newVal = field.value;
            if (!Array.isArray(newVal)) {
                if (newVal != undefined && newVal && newVal.length) {
                    newVal = [newVal];
                } else {
                    newVal = [];
                }
            }
            var hasVal = newVal.indexOf(value.value)
            if (hasVal > -1) {
                newVal.splice(hasVal, 1);
            }
            else {
                newVal.push(value.value)
            }
            value = newVal;

            //clean up
            if (extraData && Array.isArray(extraData)) {
                var finalData = [];
                value.forEach(oldVal => {
                    if (extraData.find(ex => ex.value == oldVal)) {
                        finalData.push(oldVal);
                    }
                })
                value = finalData;
            }
        }

        if (field && field.fieldType && field.fieldType.toLowerCase() == "number" && !isNaN(value)) {
            value = Number(value)
        }
        field.value = value

        this.setState({ subscriberData: subscriber });
    }

    deleteGroupConfirm(group, e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        var fromBlackList = false;
        if (!isNaN(group)) {
            group = { id: group }
            fromBlackList = true;
        }
        var drawerContent = <IncGoRemoveFromGroup subscriberID={this.state.subscriberID} groupID={group.id} fromBlackList={fromBlackList} removedSubscriber={this.removedSubscriber} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));

    }

    deleteConfirm() {
        var drawerContent = <IncDeleteSubscriber subscriberID={this.state.subscriberID} removedSubscriber={this.removedSubscriber} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    removedSubscriber(removeType) {
        if (removeType === "group") {
            this.lookupSubscriber();
            this.checkSegments()
        }
        if (removeType === "delete") {
            this.props.history.goBack();
        }
    }

    async previewTemplate(dataIn) {
        var key = await helpers.createKey(this.state.subscriberID, dataIn.sendingQueueId, dataIn.randomOrder, dataIn.CampaignId)
        window.open(`/preview?key=${key}`, '_blank')
    }

    addToGroup(groupId) {
        this.setState({ addingToGroup: true })
        axios.post('/group/' + groupId + '/subscriber/' + this.state.subscriberID).then(() => {
            this.lookupSubscriber()
            this.checkSegments()
        })
    }

    updateSubFilter(event) {
        this.setState({ [event.target.name]: event.target.checked })
    }

    exportSubscriberActions() {
        var csv = `"Date","Action Type","Campaign","Details"\n`;
        this.state.campaignStats.forEach(m => {
            csv += `"${m.createdAt.value}","${m.icon.rawValue}","${m.icon.campaignName}","${m.icon.textOut}"\n`
        })
        var pom = document.createElement('a');
        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute('download', `sub_${this.state.subscriberID}_actions.csv`);
        pom.click();
    }

    renderCustomField(field, index) {
        if (field.fieldType === "DATE") {
            if (field.value === null || field.value == "" || !moment(field.value).isValid()) field.value = undefined
            return <UILIB.TextInput outerClassName="mar-b25" key={"cf1_" + index} disabled={field.readOnly} label={field.fieldDesc} type={'datetime'} id={field.fieldName} value={field.value} onChange={(ev, val) => this.updateCustomField(index, val)} />
        }
        if (field.fieldType === 'BOOLEAN') {
            return <UILIB.CheckBox outerClassName="mar-b25" key={"cf2_" + index} disabled={field.readOnly} checked={field.value} onChange={ev => this.updateCustomField(index, ev.target.checked)} name={field.fieldName}>{field.fieldDesc}</UILIB.CheckBox>
        }
        if (field.fieldType === 'SELECT') {
            var theOpts = JSON.parse(JSON.stringify(field.options))
            if (!theOpts) theOpts = [];
            theOpts.unshift({ value: "", label: "" })
            return <UILIB.Select outerClassName="mar-b25" explainer={"Current Value: " + field.value} key={"cf3_" + index} disabled={field.readOnly} label={field.fieldDesc} value={field.value} onChange={ev => this.updateCustomField(index, ev.currentTarget.value)} name={field.fieldName} data={theOpts} />
        }

        if (field.fieldType === 'MULTISELECT' && Array.isArray(field.options)) {
            var theOpts = JSON.parse(JSON.stringify(field.options))
            if (!theOpts) theOpts = [];

            var currVals = field.value;
            if (!Array.isArray(currVals)) currVals = [currVals];

            var multiHeader = "";

            var newVals = currVals.map((v, i) => {

                theOpts.forEach(tO => {
                    if (tO.value == v) {
                        tO.selected = true;
                        multiHeader += tO.label + ", ";
                    }
                })
                return v
            })
            if (multiHeader != "") {
                multiHeader = multiHeader.substr(0, multiHeader.length - 2)
            }
            if (multiHeader == "") {
                multiHeader = i18n.t('subscribers:index.clickHereToSelect')
            }

            currVals = newVals.join(", ");

            return <div className="form-group" key={"cf3_" + index}>

                <UILIB.ButtonSelect
                    fixedHeight={true}
                    data={theOpts.map((theOpt) => {
                        return <UILIB.CheckBox key={theOpt.value} name={theOpt.label} onChange={() => this.updateCustomField(index, theOpt, 'multiselect', theOpts)} checked={theOpt.selected} >
                            {theOpt.label}
                        </UILIB.CheckBox>
                    })}
                    explainer={"Current Value(s): " + currVals}
                    label={field.fieldDesc}
                    outerClassName="mar-b25"
                    disabled={field.readOnly}
                    headerText={multiHeader} filter={false}
                >

                    {(!theOpts || !theOpts.length) && <span>There are no values associated with this field</span>}

                </UILIB.ButtonSelect>
            </div>
        }

        if (field.fieldType === 'MULTISELECT' && !Array.isArray(field.options)) {
            field.value = !Array.isArray(field.value) ? [] : field.value
            return <div className="form-group" key={"cf4_" + index}>
                <UILIB.Multi label={field.fieldDesc} values={field.value} outerClassName="mar-b25" disabled={field.readOnly} />
            </div>
        }

        if (field.fieldType === 'TELNO') {
            return <div className="form-group" key={"cf4_" + index}>
                <UILIB.TextInput outerClassName="mar-b25" disabled={field.readOnly} label={field.fieldDesc} style={{ width: '100%' }} type={field.fieldType.toLowerCase()} value={field.value} onChange={ev => this.updatePhoneNumber(index, ev.currentTarget.value, ev.valid)} />
            </div>
        }

        return <div className="form-group" key={"cf4_" + index} id={field.id}>

            <UILIB.TextInput outerClassName="mar-b25" disabled={field.readOnly} label={field.fieldDesc} type={field.fieldType.toLowerCase()} value={field.value} onChange={ev => this.updateCustomField(index, ev.currentTarget.value)} />
        </div>
    }

    render() {

        if (this.state.pageReady == false) {
            return <UILIB.LoadingOverlay />
        }

        if (this.state.failedToLoadSubscriber) {
            return <div>
                <UILIB.Row style={{ height: "100%", marginTop: "100px", textAlign: "center" }}>
                    <UILIB.Column xs={12} md={6} className="col-md-offset-3">
                        <div className="paper-class">
                            <img src={Man404} width="150" className="mar-b20" />

                            <h4 className="mar-b10">Invalid Contact</h4>
                            <div style={{ marginBottom: "30px" }}>
                                This contact no longer exists. Perhaps they were deleted, or chose to unsubscribe from all of your mailings.
                            </div>

                            <UILIB.Button text="Take me Back!" className="button-md" onClick={() => { this.props.history.goBack() }} />
                        </div>
                    </UILIB.Column>
                </UILIB.Row>

            </div>
        }
        if (!this.state.subscriberData.score) this.state.subscriberData.score = 0

        let availableGroups = this.state.groups;

        var totalSent = 0;
        var totalOpened = 0;
        var totalClicked = 0;
        if (this.state.ratios) {
            totalSent = this.state.ratios.total || 0;
            totalOpened = this.state.ratios.uniqueOpens || 0;
            totalClicked = this.state.ratios.uniqueClicks || 0;
        }

        let finalCampaignStats = this.state.campaignStats;

        if (this.state.statsTabSelected == "automation") {
            finalCampaignStats = finalCampaignStats.filter(f => f.icon.rawValue == "automation")
        }
        if (this.state.statsTabSelected == "campaigns") {
            finalCampaignStats = finalCampaignStats.filter(f => f.icon.rawValue == "open" || f.icon.rawValue == "click" || f.icon.rawValue == "sent" || f.icon.rawValue == "bounce" || f.icon.rawValue == "reply" || f.icon.rawValue == "unsubscribe")

            if (this.state.subFilterReply == false) finalCampaignStats = finalCampaignStats.filter(f => f.icon.rawValue != "reply")
            if (this.state.subFilterOpen == false) finalCampaignStats = finalCampaignStats.filter(f => f.icon.rawValue != "open")
            if (this.state.subFilterClick == false) finalCampaignStats = finalCampaignStats.filter(f => f.icon.rawValue != "click")
            if (this.state.subFilterSent == false) finalCampaignStats = finalCampaignStats.filter(f => f.icon.rawValue != "sent")
            if (this.state.subFilterBounce == false) finalCampaignStats = finalCampaignStats.filter(f => f.icon.rawValue != "bounce")
            if (this.state.subFilterUnsub == false) finalCampaignStats = finalCampaignStats.filter(f => f.icon.rawValue != "unsubscribe")

        }
        if (this.state.statsTabSelected == "tracking") {
            finalCampaignStats = finalCampaignStats.filter(f => f.icon.rawValue == "site")
        }
        if (this.state.statsTabSelected === 'audit') {
            finalCampaignStats = finalCampaignStats.filter(f => f.icon.rawValue === "audit")
        }

        return <UILIB.Paper>

            {/* HEADER OPTIONS*/}
            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={6} lg={6} className="quickFlex mar-b25">
                    <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={() => { this.props.history.goBack() }}>{i18n.t('subscribers:subscriber.back')}</UILIB.Hint>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} lg={6} hide={['xs', 'sm']} className="end-xs mar-b25 quickFlex">
                    {this.state.applications && this.state.applications.map(app => {
                        if (!app.link) return null
                        return <UILIB.Button
                            key={"butt_" + app.Application.id}
                            onClick={() => window.open(app.link, '_blank')}
                            className="mar-r10"
                            iconRight={<UILIB.Icons icon="newWindow" />}
                        >
                            View in {app.Application.appName}
                        </UILIB.Button>
                    })}
                    {this.state.writeAccess && !!this.state.inboxes?.length && <UILIB.Button
                        className="mar-r10"
                        onClick={this.sendMessage}
                    >
                        Send Message
                    </UILIB.Button>}
                    {this.state.writeAccess && <UILIB.Button
                        className="mar-r10"
                        onClick={this.downloadAllData}
                    >
                        {i18n.t('subscribers:subscriber.download')}
                    </UILIB.Button>}
                    {this.state.writeAccess && <UILIB.Button
                        className="button-red"
                        onClick={this.deleteConfirm}
                        iconLeft={<UILIB.Icons icon="bin" />}
                    >
                        {i18n.t('subscribers:subscriber.delete')}
                    </UILIB.Button>}
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row>

                <UILIB.Column xs={12} sm={12} md={4} lg={4} id="col1" >
                    <ContactDetails stats={this.state.stats} SubscriberId={this.state.subscriberID} />

                    <div id="subDataHolder">

                        {/*FIELDS ETC */}

                        <UILIB.Paper className="mar-b25">
                            <h4 className="mar-b25">{i18n.t('subscribers:subscriber.data')}</h4>
                            <ContactFields contact={this.state.subscriberData} apps={this.state.accApps} updateCustomField={this.updateCustomField} updateEmailAddress={this.updateEmailAddress} updatePhoneNumber={this.updatePhoneNumber} />

                            {/* <UILIB.Paper className="paper-lightGrey pad-25" style={{ padding: "25px", height: "100%", maxHeight: "400px", overflow: "auto" }} > */}


                            {/* <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('subscribers:subscriber.email')} name="emailAddress-emailAddress" type="email" headerText={i18n.t('subscribers:subscriber.address') + ':'} onChange={this.updateEmailAddress} value={this.state.subscriberData.emailAddress} />

                                {this.state.subscriberData.customFields.map(this.renderCustomField)} */}

                            {/* </UILIB.Paper > */}
                            {
                                this.state.writeAccess && <Fragment>
                                    <UILIB.Button className="button-primary" onClick={this.saveCustomFields} saving={this.state.saving}>{i18n.t('save')}</UILIB.Button>
                                    {this.state.subscriberData.ApplicationId === 8 && !!this.state.subscriberData.ApplicationDataId && <div className='mar-t10 hide-xs'>
                                        Updating this Contact will update the contact in Capsule
                                    </div>}
                                </Fragment>
                            }
                        </UILIB.Paper >

                        {/* Organisation */}
                        < Organisation SubscriberId={this.state.subscriberID} reloadSegments={this.checkSegments} />

                        {/* GROUPS  */}
                        < UILIB.Paper className="mar-b25" >
                            <h4 className="mar-b25">{i18n.t('subscribers:subscriber.memberOfGroups')}</h4>
                            {this.state.groupMembership.map(group => {
                                if (group.blackListGroup) return;
                                var hasDeleteButton = false;
                                if (this.state.groupMembership.length > 1) {
                                    hasDeleteButton = true;
                                }
                                var className = 'square-chip-large square-chip-green';
                                var tooltip = i18n.t('subscribers:subscriber.active');
                                if (!group.active) {
                                    className = 'square-chip-large square-chip-yellow';
                                    tooltip = i18n.t('subscribers:subscriber.notActive');
                                }
                                if (group.unsubscribe) {
                                    className = 'square-chip-large square-chip-orange';
                                    tooltip = i18n.t('subscribers:subscriber.unsubscribed');
                                };
                                if (this.state.subscriberData.bounce || this.state.subscriberData.blackListed) {
                                    className = 'square-chip-large square-chip-red';
                                    tooltip = this.state.subscriberData.bounce ? i18n.t('subscribers:subscriber.bounced') : i18n.t('subscribers:subscriber.blackListed');
                                };
                                if (group.blackListGroup == "1" || !this.state.writeAccess) {
                                    hasDeleteButton = false;
                                }

                                className += " mar-r10 mar-b10"
                                return <UILIB.SquareChip
                                    key={"GH_" + group.id}
                                    className={className}
                                    onClick={() => this.props.history.push('/cp/groups/' + group.id)}
                                    iconRight={<UILIB.Icons icon="cross" onClick={(e) => this.deleteGroupConfirm(group.id, e)} />}
                                    tooltip={tooltip}
                                    for={group.id}
                                >
                                    {group.groupName}
                                </UILIB.SquareChip>

                            })}

                            {!availableGroups.length && <div className={"mar-b10"}>
                                {i18n.t('subscribers:subscriber.notInAnyGroups')}
                            </div>}

                            <div>
                                {(!!availableGroups.length && this.state.writeAccess) && <UILIB.ButtonSelect
                                    style={{ width: "fit-content" }}
                                    headerText={this.state.addingToGroup ? i18n.t('subscribers:subscriber.addingToGroup') : i18n.t('subscribers:subscriber.addToGroup')}
                                >
                                    <ul>{availableGroups.map(g => {
                                        return <li key={'gm_' + g.id}><a onClick={() => this.addToGroup(g.id)}>{g.groupName}</a></li>
                                    })}</ul>
                                </UILIB.ButtonSelect>}


                            </div>
                        </UILIB.Paper >

                        {/* SEGMENTS */}
                        < UILIB.Paper className="mar-b0" >
                            <h4 className="mar-b25">{i18n.t('subscribers:subscriber.appearsInSegments')}</h4>
                            {this.state.segments.map(segment => {
                                return <UILIB.SquareChip key={"Seg_" + segment.id} className={"square-chip square-chip-large mar-r10 mar-b10"}>
                                    {segment.name}
                                </UILIB.SquareChip>
                            })}

                            {!this.state.segments.length && !this.state.subscriberData.blackListed && <div className={"mar-b10"}>
                                {i18n.t('subscribers:subscriber.notInAnySegments')}
                            </div>}

                            {this.state.subscriberData.blackListed && <UILIB.ButtonSimple
                                key={"bgh"}
                                className={"button-simple-black mar-b10"}
                                tooltip={i18n.t('subscribers:subscriber.blackListed')}
                                toolTipFor={"bgh"}
                                onClick={() => { this.props.history.push('/cp/groups/' + this.props.accountMaster.accountMaster.blackListGroupId) }}
                                iconRight={<UILIB.Icons icon="cross" onClick={() => this.deleteGroupConfirm(this.props.accountMaster.accountMaster.blackListGroupId)} />}
                            >
                                Blacklist
                            </UILIB.ButtonSimple>}


                        </UILIB.Paper >

                        <ContactLinkTags SubscriberId={this.props.match.params.subscriberID} />

                        {!!this.props.accountMaster.accountMaster.beta && this.props.accountMaster.accountMaster.beta.sms && <Sms id={this.props.match.params.subscriberID} subscriber={this.state.subscriberData} />
                        }

                        <SubscriberAutomations />
                    </div>
                </UILIB.Column >

                <UILIB.Column xs={12} sm={12} md={8} lg={8} style={{ position: "relative" }} >
                    {this.state.statsLoaded && <UILIB.Paper>
                        <UILIB.Row>
                            <UILIB.Column xs={12} style={{ fontWeight: 600, fontSize: 20, lineHeight: '24px', marginBottom: 24 }}>
                                Statistics
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={12} md={4} lg={4}>
                                <div className="quickFlex" >
                                    <h4 className="mar-r16 statistic">{totalOpened}</h4>
                                    <span className="statistic-sub">/ {FormatNumberFuncs.formatNumber(totalSent) || 0}</span>
                                </div>
                                {i18n.t('subscribers:subscriber.opens')}
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={12} md={4} lg={4}>
                                <div className="quickFlex" >
                                    <h4 className="mar-r16 statistic">{totalClicked}</h4>
                                    <span className="statistic-sub">/ {FormatNumberFuncs.formatNumber(totalSent) || 0}</span>
                                </div>
                                {i18n.t('subscribers:subscriber.clicks')}
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={12} md={4} lg={4}>
                                <div className="quickFlex" >
                                    <h4 className="mar-r16 statistic">{this.state.stats.tracking.length}</h4>
                                    <UILIB.Button className="button-sml mar-l10" iconLeft={<UILIB.Icons className="hide-sm hide-xs hide-md" icon="world" />} onClick={() => this.props.history.push("/cp/trackers")}>View / Setup</UILIB.Button>
                                </div>
                                {i18n.t('subscribers:subscriber.tracking')}
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>}

                    {/* Custom Nav */}
                    <div className="viewSubscriber-history" style={{ height: '100%' }}>
                        <div className="hide-sm hide-xs viewSubscriber-history-nav" style={{ display: "flex" }}>
                            <div className={"viewSubscriber-history-nav-option" + (this.state.statsTabSelected == "all" ? " viewSubscriber-history-nav-option-selected" : "")} onClick={() => { this.setState({ statsTabSelected: 'all' }) }}>
                                All History
                            </div>
                            <div className={"viewSubscriber-history-nav-option" + (this.state.statsTabSelected == "campaigns" ? " viewSubscriber-history-nav-option-selected" : "")} onClick={() => { this.setState({ statsTabSelected: 'campaigns' }) }}>
                                Campaigns
                            </div>
                            <div className={"viewSubscriber-history-nav-option" + (this.state.statsTabSelected == "tracking" ? " viewSubscriber-history-nav-option-selected" : "")} onClick={() => { this.setState({ statsTabSelected: 'tracking' }) }}>
                                Website Tracking
                            </div>
                            <div className={"viewSubscriber-history-nav-option" + (this.state.statsTabSelected == "automation" ? " viewSubscriber-history-nav-option-selected" : "")} onClick={() => { this.setState({ statsTabSelected: 'automation' }) }}>
                                Automations
                            </div>
                            {!!this.props.accountMaster.accountMaster.beta && this.props.accountMaster.accountMaster.beta.subscriberAudit &&
                                <div className={"viewSubscriber-history-nav-option" + (this.state.statsTabSelected == "audit" ? " viewSubscriber-history-nav-option-selected" : "")}
                                    onClick={() => { this.setState({ statsTabSelected: 'audit' }) }}>
                                    Audit
                                </div>}
                        </div>


                        {/* The table */}
                        <UILIB.Paper className="viewSubscriber-history-table">

                            <div className="hide-md hide-lg mar-b25">
                                <UILIB.Select data={[{ label: "All History", value: "all" }, { "label": "Campaigns", "value": "campaigns" }, { "label": "Website Tracking", "value": "tracking" }, { "label": "Automations", "value": "automations" }]} value={this.state.statsTabSelected} onChange={(event) => { this.setState({ statsTabSelected: event.target.value }) }} />
                            </div>

                            <div className="quickFlex mar-b20">
                                {this.state.statsTabSelected == "campaigns" && <div className="quickFlex">
                                    <UILIB.CheckBox outerClassName="mar-r10" name="subFilterSent" checked={this.state.subFilterSent} onClick={this.updateSubFilter}>Sent</UILIB.CheckBox>
                                    <UILIB.CheckBox outerClassName="mar-r10" name="subFilterOpen" checked={this.state.subFilterOpen} onClick={this.updateSubFilter}>Opened</UILIB.CheckBox>
                                    <UILIB.CheckBox outerClassName="mar-r10" name="subFilterClick" checked={this.state.subFilterClick} onClick={this.updateSubFilter}>Clicked</UILIB.CheckBox>
                                    <UILIB.CheckBox outerClassName="mar-r10" name="subFilterBounce" checked={this.state.subFilterBounce} onClick={this.updateSubFilter}>Bounced</UILIB.CheckBox>
                                    <UILIB.CheckBox outerClassName="mar-r10" name="subFilterReply" checked={this.state.subFilterReply} onClick={this.updateSubFilter}>Replied</UILIB.CheckBox>
                                    <UILIB.CheckBox name="subFilterUnsub" checked={this.state.subFilterUnsub} onClick={this.updateSubFilter}>Unsubscribed</UILIB.CheckBox>
                                </div>}
                                <div className="end-xs" style={{ flex: "1" }} >
                                    <UILIB.Button text="Export" className="button-sml button-primary" onClick={this.exportSubscriberActions} iconRight={<UILIB.Icons icon="download" />} />
                                </div>
                            </div>

                            <div style={{ zIndex: "1", height: this.props.accountMaster.accountMaster.beta && this.props.accountMaster.accountMaster.beta.sms ? 1800 : 1500, overflow: "auto" }}>
                                <div style={{ height: "100%" }}>

                                    <UILIB.DataTable1
                                        noResultsTxt={"No history available"}
                                        tableData={finalCampaignStats}
                                        loadingData={this.state.statsLoaded == false}
                                        dataUpdater={this.campaignStatsUpdater}
                                        width="100%"
                                        pageSize="100"
                                        hasCheckBoxes="no"
                                        sortedColumn={this.state.campaignStatsSortColumn}
                                        sortedDirection={this.state.campaignStatsSortDirection}
                                        outerStyle={{ height: "100%" }}
                                    />


                                </div>

                            </div>
                        </UILIB.Paper>
                    </div>
                </UILIB.Column>
            </UILIB.Row >



            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />

        </UILIB.Paper >

    }
}
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import axios from '~/data/http/axios';

@connect((store) => {
    return { user: store.user, account: store.accountMaster, siteMaster: store.siteMaster }
})
class AddCardStripe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            name: undefined,
            address_line1: undefined,
            address_city: undefined,
            address_state: undefined,
            address_zip: this.props.account.accountMaster.postCode || '',
            address_country: undefined,
            error: '',
            loaded: false
        }

        this.send = this.send.bind(this);
        this.updateValue = this.updateValue.bind(this);

        this.supportedCards = ['amazon', 'amex', 'cirrus', 'diners', 'discover', 'ebay', 'electron', 'eway', 'google', 'jcb', 'maestro', 'mastercard', 'paypal', 'sage', 'shopify', 'skill', 'solo', 'visa', 'western', 'worldpay'];
    }

    componentDidMount() {
        axios.get('/countries').then(res => {
            const countries = res.data.Countries
            if (this.props.account.accountMaster.country) {
                const country = countries.find(c => c.name === this.props.account.accountMaster.country)
                if (country) {
                    this.setState({ address_country: country.code })
                }
            }
            var elements = this.props.stripe.elements();

            this.cardNumber = elements.create('cardNumber', { classes: { base: 'txtInput noShadow' }, placeholder: 'Card Number' });
            this.cardNumber.mount(this.refs.cardNumber);

            this.cardExpiry = elements.create('cardExpiry', { classes: { base: 'txtInput noShadow' }, placeholder: 'Expiry Date' });
            this.cardExpiry.mount(this.refs.cardExpiry);

            this.cardCvc = elements.create('cardCvc', { classes: { base: 'txtInput noShadow' }, placeholder: 'CVC / CVV' });
            this.cardCvc.mount(this.refs.cardCvc);

            this.cardNumber.on('change', event => {
                if (event.brand && event.brand !== 'unknown' && this.supportedCards.indexOf(event.brand) > -1) {
                    this.setState({
                        image: <img src={"/public/cards/" + event.brand + ".png"} />
                    })
                } else {
                    this.setState({
                        image: undefined
                    })
                }
            })

            this.setState({
                countries: res.data.Countries,
                loaded: true
            })
        })
    }

    updateValue(ev) {
        this.setState({
            [ev.currentTarget.name]: ev.currentTarget.value
        })
    }

    send(ev) {
        ev.preventDefault();
        this.setState({
            alertMessage: 'Creating Card',
            alertOpen: true
        })
        this.props.stripe.createToken(this.cardNumber, {
            name: this.state.name,
            address_line1: this.state.address_line1,
            address_city: this.state.address_city,
            address_state: this.state.address_state,
            address_country: this.state.address_country,
        }).then(result => {
            if (result.error) {
                return Promise.reject(result.error.message);
            } else {
                return axios.post('/accountMaster/paymentMethod/stripe', { token: result.token.id })
            }
        }).then(() => {
            this.props.close()
        }).catch(err => {
            console.log(err)
            this.setState({
                alertMessage: 'Error Creating Card',
                alertOpen: true,
                error: err.data ? err.data.error || err.data.message || err.data : err
            })
        })
    }

    render() {
        return (
            <UILIB.Row style={{ display: !this.state.loaded ? 'none' : 'block' }} >
                <UILIB.Column xs={12} md={6} className="col-md-offset-3" margin={0}>

                    <UILIB.Paper className="text-center">

                        <h4 className="mar-b25">Add a payment method</h4>

                        <div className="quickFlex mar-b25" style={{ alignItems: "center", justifyContent: "center" }}>
                            We accept all major credit cards
                            <img src="/public/cards/visa.png" width="30px" className="mar-l10" />
                            <img src="/public/cards/mastercard.png" width="30px" className="mar-l10" />
                            <img src="/public/cards/amex.png" width="30px" className="mar-l10" />
                        </div>

                        <UILIB.Row>
                            <UILIB.Column xs={12} className="mar-b15">
                                <UILIB.TextInput placeholder="Cardholder Name" name="name" value={this.state.name} onChange={this.updateValue} />
                            </UILIB.Column>

                            <UILIB.Column xs={12} className="mar-b15">
                                <div className="textInputWrapper">
                                    <div ref="cardNumber" id="card-number"></div>
                                </div>
                            </UILIB.Column>

                            <UILIB.Column xs={6} className="mar-b25">
                                <div className="textInputWrapper">
                                    <div ref="cardExpiry" id="card-expiry"></div>
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={6} className="mar-b25">
                                <div className="textInputWrapper">
                                    <div ref="cardCvc" id="card-cvc"></div>
                                </div>
                            </UILIB.Column>

                            <UILIB.Column xs={12} className="mar-b15">
                                <UILIB.TextInput placeholder="Cardholder Address" name="address_line1" value={this.state.address_line1} onChange={this.updateValue} />
                            </UILIB.Column>

                            <UILIB.Column xs={4} className="mar-b15">
                                <UILIB.TextInput placeholder={this.state.address_country === 'UK' ? 'County' : "State"} name="address_state" value={this.state.address_state} onChange={this.updateValue} />
                            </UILIB.Column>
                            <UILIB.Column xs={4} className="mar-b15">
                                <UILIB.TextInput placeholder={this.state.address_country === 'US' ? 'ZIP' : "Post Code"} name="address_zip" value={this.state.address_zip} onChange={this.updateValue} />
                            </UILIB.Column>
                            <UILIB.Column xs={4} className="mar-b15">
                                <UILIB.Select data={this.state.countries.map(c => ({ label: c.name, value: c.code }))} placeholder="--Select Country--" name="address_country" value={this.state.address_country} onChange={this.updateValue} />
                            </UILIB.Column>

                            {!!this.state.error &&
                                <UILIB.Column xs={12} className="mar-b0">
                                    <UILIB.WarningHolder style={{ color: "red" }}>
                                        {this.state.error}
                                    </UILIB.WarningHolder>
                                </UILIB.Column>
                            }
                            {!this.state.addCard && <UILIB.Column xs={12} className="mar-t25 mar-b25">
                                <UILIB.Button text="Add Card" className="button-primary" onClick={this.send} iconLeft={<UILIB.Icons icon="plus" />} />
                            </UILIB.Column>}

                        </UILIB.Row>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <UILIB.Icons icon="shoppingbag" style={{ width: "15px", height: "15px" }} />
                            <div className="text-primary mar-l10">Secure SSL, your details are safe.</div>
                        </div>

                    </UILIB.Paper>
                    <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />

                </UILIB.Column>
            </ UILIB.Row >

        );
    }
}

export default AddCardStripe;
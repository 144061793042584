import React, { Fragment } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import ReactTooltip from 'react-tooltip'
import axios2 from 'axios'

import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import PermissionChecker from '~/Classes/permissions';
import NumberFunctions from '~/Classes/numberFormatFunctions'

import DeleteSegment from './deleteSegment';
import RenameSegment from './renameSegment';
import CopySegment from './copy'
import Users from './users'

import SegmentEditor from '~/pages/cp/includes/segmentEditor/segmentEditor'
import i18n from '~/i18n'



//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, browser: store.browser, permissionStore: store.permission, siteMaster: store.siteMaster }
})
export default class SegmentsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            segments: [],
            tableData: [],
            apps: [],
            loadingData: 1,
            loadingTableData: true,
            editing: false,
            firstLoad: true,
            total: 0,
            page: 0,
            limit: 10,
            alertOpen: false,
            alertMessage: '',
            searchText: '',
            orderBy: 'name',
            orderDir: 'asc'
        };

        this.timer = null;
        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();

        this.getSegments = this.getSegments.bind(this);
        this.goViewSegment = this.goViewSegment.bind(this);
        this.renameSegment = this.renameSegment.bind(this);
        this.goDeleteSegment = this.goDeleteSegment.bind(this);
        this.getSegments = this.getSegments.bind(this);
        this.closeAndGet = this.closeAndGet.bind(this);
        this.tableUpdater = this.tableUpdater.bind(this)
        this.changeSearchText = this.changeSearchText.bind(this)
        this.changePageSize = this.changePageSize.bind(this)
        this.copy = this.copy.bind(this)
        this.userAccess = this.userAccess.bind(this)
    }

    componentDidMount() {
        this.getSegments()
        axios.get('/accountMaster/application').then(res => this.setState({ apps: res.data.Applications }))
    }

    tableUpdater(page, orderDir, orderBy) {

        this.setState({
            page: !isNaN(page) ? page : this.state.page,
            orderDir: orderDir || this.state.orderDir,
            orderBy: orderBy || this.state.orderBy
        }, this.getSegments)
    }

    userAccess(segment) {
        var drawerContent = <Users id={segment.id} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    getSegments() {
        if (this.state.loadingTableData) {
            this.source.cancel('I cancelled this')
            this.source = this.CancelToken.source();
        }
        this.setState({ loadingTableData: true })
        var canEdit = PermissionChecker('segments', this.props.permissionStore.permissions, "write");

        var queryStr = `?count=true&basicView=true&automations=true&notsystem=true&startRecord=${this.state.limit * this.state.page}&recordLimit=${this.state.limit}&sortColumn=${this.state.orderBy}&sortOrder=${this.state.orderDir}&aggs=1`
        if (this.state.searchText && this.state.searchText.length > 2) {
            queryStr += '&searchText=' + this.state.searchText
        }
        axios.get('/segment' + queryStr, {
            cancelToken: this.source.token
        }).then(response => {
            this.setState({
                segments: response.data.Segments,
                total: response.data.total,
                firstLoad: false,
                loadingData: 0,
                loadingTableData: false,
                tableData: response.data.Segments.map((row, index) => {
                    var thisNameAndLink = row.readOnly ? i18n.t(row.name) : <a onClick={() => this.goViewSegment(row.id, !!row.CapsuleId)}>{row.name}</a>;
                    const foundCapsule = this.state.apps.some(a => a.ApplicationId === 8)
                    let avatar
                    if (row.CapsuleId && response.data.logo && foundCapsule) {
                        if (row.Capsule?.owner) {
                            avatar = <UILIB.Avatar
                                tooltip={row.Capsule.shared ? `Shared (${row.Capsule.owner})` : `Private (${row.Capsule.owner})`}
                                for={row.id} className="mar-l10" style={{ height: 20, width: 20 }} src={response.data.logo} />
                        } else {
                            avatar = <UILIB.Avatar
                                tooltip={row.Capsule ? row.Capsule.shared ? 'Shared' : 'Private' : undefined}
                                for={row.id} className="mar-l10" style={{ height: 20, width: 20, }} src={response.data.logo} />
                        }
                        thisNameAndLink = <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>{thisNameAndLink} {avatar}</div>
                    }
                    var thisOpts = <Fragment>
                        {canEdit && !row.readOnly && <UILIB.OptionsDropdown popWidth="150px">
                            <ul>
                                {(!row.CapsuleId || !foundCapsule) && <li>
                                    <a onClick={() => { this.renameSegment(row.id) }}>{i18n.t('subscribers:segments.rename')}</a>
                                </li>}
                                <li>
                                    <a onClick={() => this.goViewSegment(row.id, !!row.CapsuleId)}>{i18n.t(!row.CapsuleId ? 'subscribers:segments.view' : 'subscribers:segments.readonly')}</a>
                                </li>
                                <li>
                                    <a onClick={() => { this.copy(row) }}>{i18n.t('subscribers:segments.copySegment')}</a>
                                </li>
                                <li>
                                    <a onClick={() => this.userAccess(row)}>{i18n.t('subscribers:segments.users.option')}</a>
                                </li>
                                {(!row.CapsuleId || !foundCapsule) && < li >
                                    <a onClick={() => { this.goDeleteSegment(row.id, row.automations) }}>{i18n.t('delete')}</a>
                                </li>}
                            </ul>

                        </UILIB.OptionsDropdown>
                        }
                    </Fragment >

                    const aggs = row.aggs || {}
                    const sent = aggs.sent || 0
                    const opened = aggs.opened || 0
                    const clicked = aggs.clicked || 0
                    const active = aggs.active || 0
                    const sentAvg = (100 / row.subscriberCount) * sent
                    const openedAvg = (100 / row.subscriberCount) * opened
                    const clickedAvg = (100 / row.subscriberCount) * clicked
                    const activeAvg = (100 / row.subscriberCount) * active

                    const sentColor = sentAvg > 50 ? 'square-chip-green' : sentAvg > 25 ? 'square-chip-orange' : ''
                    const openColor = openedAvg > 25 ? 'square-chip-green' : openedAvg > 10 ? 'square-chip-orange' : ''
                    const clickColor = clickedAvg > 15 ? 'square-chip-green' : clickedAvg > 5 ? 'square-chip-orange' : ''
                    const activeColor = activeAvg > 50 ? 'square-chip-green' : activeAvg > 25 ? 'square-chip-orange' : ''

                    return {
                        name: {
                            headerValue: i18n.t('subscribers:segments.name'),
                            value: thisNameAndLink,
                            orderBy: true
                        },
                        automations: {
                            headerValue: i18n.t('nav:automation'),
                            value: row.automations ? row.automations.map((a, index) => {
                                return <UILIB.SquareChip
                                    key={"auto_" + index}
                                    style={{ cursor: "pointer" }}
                                    iconRight={<UILIB.Icons icon="arrowRight" />}
                                    className="square-chip-green mar-r5"
                                    onClick={() => this.props.history.push('/cp/automation/addnew/' + a.id)}
                                >
                                    {a.name}
                                </UILIB.SquareChip>
                            }) : '',
                            orderBy: false
                        },
                        count: {
                            headerValue: 'Total Contacts',
                            value: row.subscriberCount !== undefined ?
                                <UILIB.SquareChip className="square-chip-large square-chip-purple square-chip-min-width">{NumberFunctions.formatNumber(row.subscriberCount)}</UILIB.SquareChip>
                                : <UILIB.Icons data-tip="The rules in this segment limit live counting" style={{ height: 30, width: 30, display: 'flex', justifyContent: 'center' }} data-for={'segment' + row.id} icon="questionCircle" />,
                            actual: row.subscriberCount,
                            orderBy: false,
                            width: 150,
                            align: "center"
                        },
                        active: {
                            headerValue: 'Active Contacts',
                            value: <div>
                                <div style={{ fontWeight: 'bold', color: 'black' }}>{NumberFunctions.formatNumber(active)}</div>
                                <UILIB.SquareChip className={activeColor}>{(activeAvg || 0).toFixed(2)}%</UILIB.SquareChip>
                            </div>,
                            orderBy: false,
                            width: 150,
                            align: "center"
                        },
                        sent: {
                            headerValue: 'Sent Emails',
                            value: <div>
                                <div style={{ fontWeight: 'bold', color: 'black' }}>{NumberFunctions.formatNumber(sent)}</div>
                                <UILIB.SquareChip className={sentColor}>{(sentAvg || 0).toFixed(2)}%</UILIB.SquareChip>
                            </div>,
                            width: 150,
                            align: "center"
                        },
                        opened: {
                            headerValue: 'Opened',
                            value: <div>
                                <div style={{ fontWeight: 'bold', color: 'black' }}>{NumberFunctions.formatNumber(opened)}</div>
                                <UILIB.SquareChip className={openColor}>{(openedAvg || 0).toFixed(2)}%</UILIB.SquareChip>
                            </div>,
                            width: 150,
                            align: "center"
                        },
                        clicked: {
                            headerValue: 'Clicked',
                            value: <div>
                                <div style={{ fontWeight: 'bold', color: 'black' }}>{NumberFunctions.formatNumber(clicked)}</div>
                                <UILIB.SquareChip className={clickColor}>{(clickedAvg || 0).toFixed(2)}%</UILIB.SquareChip>
                            </div>,
                            width: 150,
                            align: "center"
                        },
                        score: {
                            headerValue: 'Avg Score',
                            value: <UILIB.Score score={aggs.score || 0} className="quickFlex justify-center" />,
                            width: 150,
                            align: "center"
                        },
                        createdAt: {
                            headerValue: i18n.t('created'),
                            value: DateTimeFunctions.formatDateTime(row.createdAt, 2),
                            orderBy: true,
                            width: 150,
                            align: "center"
                        },
                        optionsCol: {
                            headerValue: " ",
                            value: thisOpts,
                            orderBy: false,
                            fixed: true,
                            fixedSml: true,
                            width: 20
                        },
                        id: {
                            value: row.id
                        }
                    }
                })
            })
        })
    }



    goViewSegment(id, readOnly) {
        var drawerContent = <SegmentEditor history={this.props.history} readonly={readOnly} segmentId={id} close={this.closeAndGet} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "100%"));
    }

    copy(segment) {
        var drawerContent = <CopySegment segment={segment} close={newSegment => {
            this.closeAndGet()
            // console.log('new', newSegment)
            if (newSegment && newSegment.segmentId) {
                this.goViewSegment(newSegment.segmentId)
            }
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    closeAndGet() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
        this.getSegments();
    }

    renameSegment(id) {
        var drawerContent = <RenameSegment history={this.props.history} segmentId={id} close={this.closeAndGet} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    goDeleteSegment(id, automations) {
        var drawerContent = <DeleteSegment history={this.props.history} segmentId={id} close={this.closeAndGet} automations={automations} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    changeSearchText(ev) {
        if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.setState({ page: 0 }, this.getSegments)
        }, 200)
        this.setState({
            searchText: ev.currentTarget.value
        })
    }

    changePageSize(limit) {
        this.setState({ limit }, this.getSegments)
    }


    render() {
        if (this.state.firstLoad) return <UILIB.LoadingIcons />

        var tooMany = false
        var canEdit = PermissionChecker('segments', this.props.permissionStore.permissions, "write");
        // if (this.state.total >= 30) tooMany = true
        return <div>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={6} className="mar-b25">
                    <UILIB.TextInput
                        placeholder={i18n.t('subscribers:segments.searchPlaceholder')}
                        value={this.state.searchText}
                        onChange={this.changeSearchText}
                        clickToChangeClicked={() => { }}
                        onEnterPress={() => { }}
                        className="no-marg"
                        iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                    />
                </UILIB.Column>

                <UILIB.Column xs={12} sm={6} className="end-xs mar-b25">
                    {this.props.siteMaster.siteId === 1 && <UILIB.Hint style={{ width: 'fit-content', marginRight: canEdit ? 20 : 0, display: 'inline-flex' }} iconLeft={<UILIB.Icons icon="questionCircle" />} href="https://kb.transpond.io/article/70-what-is-a-segment">Learn about Segments</UILIB.Hint>}
                    {(canEdit === true) && <UILIB.Button
                        onClick={() => { this.goViewSegment() }}
                        className="button-primary"
                        iconLeft={<UILIB.Icons icon="plus" />}>
                        {tooMany ? i18n.t('subscribers:segments.tooMany') : i18n.t('subscribers:segments.add')}
                    </UILIB.Button>}
                </UILIB.Column>
            </UILIB.Row>
            <UILIB.Row >
                <UILIB.Column xs={12} className="end-xs mar-b25" style={{ display: "flex", alignItems: "center" }} >
                    <div>{i18n.t('showing')}</div>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.limit === 10} onClick={() => { this.changePageSize(10) }}>10</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.limit === 20} onClick={() => { this.changePageSize(20) }}>20</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.limit === 50} onClick={() => { this.changePageSize(50) }}>50</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.limit === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                </UILIB.Column>

            </UILIB.Row>


            <UILIB.DataTable1
                noResultsTxt={< span >  {this.state.firstLoad ? '' : i18n.t('subscribers:segments.noSegments')} </span>}
                tableData={this.state.tableData}
                loadingData={this.state.loadingTableData}
                dataUpdater={this.tableUpdater}
                sortedColumn={this.state.orderBy}
                sortedDirection={this.state.orderDir}
                width="100%"
                hasCheckBoxes="multi"
                pageSize="10"
                className="mar-b25"
            />

            <UILIB.Row>
                <UILIB.Column xs={12} hide={["xs", "sm"]} className="center-xs">
                    <UILIB.PagingBlock totalRows={this.state.total} pageSize={this.state.limit} numberOfLinks="10" currentPage={this.state.page}
                        changePage={this.tableUpdater} text={i18n.t('page') + ":"} />
                </UILIB.Column>
            </UILIB.Row>



            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />


        </div>
    };

};

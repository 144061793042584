import React from "react";

export default function IconArrowLeft({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.7068 14.695C9.51927 14.8825 9.26497 14.9878 8.9998 14.9878C8.73464 14.9878 8.48033 14.8825 8.2928 14.695L4.2928 10.695C4.10533 10.5075 4.00002 10.2532 4.00002 9.98801C4.00002 9.72284 4.10533 9.46854 4.2928 9.28101L8.2928 5.28101C8.4814 5.09885 8.73401 4.99806 8.9962 5.00034C9.2584 5.00261 9.50921 5.10778 9.69462 5.29319C9.88003 5.4786 9.9852 5.72941 9.98748 5.99161C9.98975 6.25381 9.88896 6.50641 9.7068 6.69501L7.4138 8.98801H14.9998C15.265 8.98801 15.5194 9.09337 15.7069 9.2809C15.8944 9.46844 15.9998 9.72279 15.9998 9.98801C15.9998 10.2532 15.8944 10.5076 15.7069 10.6951C15.5194 10.8827 15.265 10.988 14.9998 10.988H7.4138L9.7068 13.281C9.89427 13.4685 9.99959 13.7228 9.99959 13.988C9.99959 14.2532 9.89427 14.5075 9.7068 14.695Z"
        fill={color}
      />
    </svg>
  );
}

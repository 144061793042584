import React from 'react'
import axios from '~/data/http/axios';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';

import i18n from '~/i18n';

import AddRolePop from './addRolePop';
import DeleteRolePop from './deleteRolePop';

import PermissionChecker from '~/Classes/permissions'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission }
})

export default class CompanyRoles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoaded: false,
            roles: {},
            writeAccess: true
        };
        this.loadRoles = this.loadRoles.bind(this);
        this.togglePermHolder = this.togglePermHolder.bind(this);
        this.addRoleDrawer = this.addRoleDrawer.bind(this);
        this.addedRole = this.addedRole.bind(this);
        this.deleteRole = this.deleteRole.bind(this);
        this.deletedRole = this.deletedRole.bind(this);
    }

    componentDidMount() {
        //see if we have write access to the roles
        var writeAccess = PermissionChecker("roles", this.props.permissionStore.permissions, "write");
        this.setState({ writeAccess: writeAccess }, this.loadRoles())
    }

    //Load Roles
    loadRoles() {

        var rolesDat = [];
        axios.get('/role')
            .then((roleData) => {
                this.setState({ roles: roleData.data.Roles, pageLoaded: true });
            });
    }

    addRoleDrawer() {
        var drawerContent = <AddRolePop onAddedRole={this.addedRole} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    addedRole(theID) {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        this.props.history.push('/cp/company/roles/edit/' + theID);

    }

    deleteRole(theID) {
        var drawerContent = <DeleteRolePop roleId={theID} deletedRole={this.deletedRole} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }
    deletedRole() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        this.loadRoles();
    }
    togglePermHolder(theID) {
        var thisEl = this.refs["permGroup-" + theID];
        if (thisEl.style.display === "none") {
            thisEl.style.display = "block";
        }
        else {
            thisEl.style.display = "none";
        }
    }

    render() {


        if (this.state.pageLoaded === false) {
            return <div></div>
        }
        else {

            return <div >
                {this.state.writeAccess === true && <div className="text-right mar-b25">
                    <UILIB.Button
                        className="button-primary"
                        onClick={this.addRoleDrawer}
                        text={i18n.t('company:roles.addRole')}
                        iconLeft={<UILIB.Icons icon="plus" />}
                    />
                </div>}

                <UILIB.Row>
                    {this.state.roles.map((theRole, index) => {

                        return <UILIB.Column xs={12} sm={6} md={6} lg={3} key={index}>
                            <UILIB.RoleCard role={theRole} history={this.props.history} deleteRole={this.deleteRole} writeAccess={this.state.writeAccess} />
                        </UILIB.Column>
                    })}
                </UILIB.Row>

            </div >
        }
    };


};


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jE9G_Pl4ISwZ5jz0qeQ0{display:flex;flex-direction:column}.jE9G_Pl4ISwZ5jz0qeQ0 span{font-size:12px;color:#595c61}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/inbox/components/leftSidebar/inboxStatusDropdownMenu/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAEA,2BACE,cAAA,CACA,aAAA","sourcesContent":[".checkboxItemDetail {\n  display: flex;\n  flex-direction: column;\n\n  span {\n    font-size: 12px;\n    color: #595c61;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxItemDetail": "jE9G_Pl4ISwZ5jz0qeQ0"
};
export default ___CSS_LOADER_EXPORT___;

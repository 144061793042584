import React from 'react'

export default class IconsHandPointingUp extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 8.5C9 8.23478 9.10536 7.98043 9.29289 7.79289C9.48043 7.60536 9.73478 7.5 10 7.5C10.2652 7.5 10.5196 7.60536 10.7071 7.79289C10.8946 7.98043 11 8.23478 11 8.5C11 8.63261 11.0527 8.75979 11.1464 8.85355C11.2402 8.94732 11.3674 9 11.5 9C11.6326 9 11.7598 8.94732 11.8536 8.85355C11.9473 8.75979 12 8.63261 12 8.5C12 8.23478 12.1054 7.98043 12.2929 7.79289C12.4804 7.60536 12.7348 7.5 13 7.5C13.2652 7.5 13.5196 7.60536 13.7071 7.79289C13.8946 7.98043 14 8.23478 14 8.5C14 8.63261 14.0527 8.75979 14.1464 8.85355C14.2402 8.94732 14.3674 9 14.5 9C14.6326 9 14.7598 8.94732 14.8536 8.85355C14.9473 8.75979 15 8.63261 15 8.5C15 8.23478 15.1054 7.98043 15.2929 7.79289C15.4804 7.60536 15.7348 7.5 16 7.5C16.2652 7.5 16.5196 7.60536 16.7071 7.79289C16.8946 7.98043 17 8.23478 17 8.5V11C17 11.9193 16.8189 12.8295 16.4672 13.6788C16.1154 14.5281 15.5998 15.2997 14.9497 15.9497C14.2997 16.5998 13.5281 17.1154 12.6788 17.4672C11.8295 17.8189 10.9193 18 10 18C9.08075 18 8.1705 17.8189 7.32122 17.4672C6.47194 17.1154 5.70026 16.5998 5.05025 15.9497C4.40024 15.2997 3.88463 14.5281 3.53284 13.6788C3.18106 12.8295 3 11.9193 3 11V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8C4.26522 8 4.51957 8.10536 4.70711 8.29289C4.89464 8.48043 5 8.73478 5 9V11.5C5 11.6326 5.05268 11.7598 5.14645 11.8536C5.24021 11.9473 5.36739 12 5.5 12C5.63261 12 5.75979 11.9473 5.85355 11.8536C5.94732 11.7598 6 11.6326 6 11.5V4C6 3.73478 6.10536 3.48043 6.29289 3.29289C6.48043 3.10536 6.73478 3 7 3C7.26522 3 7.51957 3.10536 7.70711 3.29289C7.89464 3.48043 8 3.73478 8 4V8.5C8 8.63261 8.05268 8.75979 8.14645 8.85355C8.24021 8.94732 8.36739 9 8.5 9C8.63261 9 8.75979 8.94732 8.85355 8.85355C8.94732 8.75979 9 8.63261 9 8.5Z" fill={color} />
        </svg>




    }
}        
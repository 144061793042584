import React from 'react';

export default class IconsStarRatingIs extends React.Component {


    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99996 2.93475L12.1629 7.31673L16.9999 8.02372L13.4999 11.4327L14.3259 16.2487L9.99996 13.9737L5.67398 16.2487L6.49998 11.4327L3 8.02372L7.83697 7.31673L9.99996 2.93475Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    }
}

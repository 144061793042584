import React, { useState } from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { useSelector } from 'react-redux'

const timeblocks = []
const time = moment('00:00', 'HH:mm')
for (var i = 0; i < 48; i++) {
    timeblocks.push({ label: time.format('HH:mm'), value: time.format() })
    time.add(30, 'minutes')
}

export default function SmsSendStartTime({ campaign, updateCampaign = () => { } }) {
    const [advOptions, setAdvOptions] = useState({
        showLimitSendingTimes: false,
        showResendAfter: false
    })

    const user = useSelector(state => state.user)

    const updateOptions = (ev) => {
        var options = campaign.options || {}
        var value = ev.currentTarget.value;
        if (ev.target.name == "recurringType") {
            if (value == "none") {
                options.recurringValue = 0
            }
            if (value == "daily") {
                options.recurringValue = 1
            }
            if (value == "weekly") {
                options.recurringValue = "monday"
            }
            if (value == "monthly") {
                options.recurringValue = "1"
            }
        }
        options[ev.currentTarget.name] = value
        options.timezone = user.userData.timeZone

        updateCampaign()
    }

    const changeStartDate = (e, v) => {
        campaign.startDate = v;
        updateCampaign()
    }

    const changeAdvOptions = (event) => {
        const options = { ...advOptions }
        if (event.target.name == "checkedSendBetween") {
            options.showLimitSendingTimes = event.target.checked
            if (event.target.checked == false) {
                //clear start/end times
                campaign.options.betweenStart = undefined
                campaign.options.betweenEnd = undefined
                updateCampaign();
            }
        }
        if (event.target.name == "checkedResendAfter") {
            advOptions.showResendAfter = event.target.checked;
            if (event.target.checked == false) {
                //reset send
                campaign.resendAfter = 0;
                updateCampaign();
            }
        }
        setAdvOptions(options)
    }

    return (
        <UILIB.Paper className="mar-b20">
            <UILIB.Row>
                <UILIB.Column xs={12} sm={6} margin={0}>
                    <h4 className="mar-b25" >{i18n.t('campaigns:standard.step3.scheduleTitle')}</h4>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={6} margin={0}>
                    <div className="campaignTimeZone" style={{ position: "relative", textAlign: "right" }}>
                        {user.userData.timeZone}
                        <span data-tip={i18n.t('campaigns:standard.step3.startTimeHelper')}><ReactTooltip effect='solid' /><UILIB.Help title="help" width="12px" /></span>
                    </div>
                </UILIB.Column>

                {/* radio button for schedule */}

                <UILIB.Column xs={12} className="mar-b25">
                    <div className="row">
                        <div className="col-xs-6">
                            <UILIB.TextInput
                                label={i18n.t('campaigns:standard.step3.startDate')}
                                type="datetime"
                                name="startDate"
                                onChange={changeStartDate}
                                disallowPast={true}
                                value={campaign.startDate}
                                placeholder={i18n.t('campaigns:standard.step3.startDatePlaceholder')}
                            />
                        </div>
                    </div>
                </UILIB.Column>




                <UILIB.Column xs={12} sm={6} md={6} margin={0}>
                    <div>
                        <UILIB.Toggle checked={advOptions.showLimitSendingTimes} onChange={changeAdvOptions} name="checkedSendBetween"
                            after={<span>{i18n.t('campaigns:standard.step3.limitSendingTimes')} <div style={{ color: '#999', fontSize: 12 }}>Only send SMS between specific hours of the day</div>  </span>}
                        />

                    </div>
                    {/* <div>
                        <UILIB.CheckBox checked={advOptions.showLimitSendingTimes} name="checkedSendBetween" onChange={changeAdvOptions} className="mar-r10">
                            {i18n.t('campaigns:standard.step3.limitSendingTimes')}
                        </UILIB.CheckBox>
                    </div> */}
                    {advOptions.showLimitSendingTimes &&
                        <div className="form-group mar-t15 mar-b0">
                            {/* <label htmlFor="">{i18n.t('campaigns:standard.step3.sendingTimesDescription')}</label> */}
                            <div className="quickFlex">
                                <UILIB.Select label='Start Time' outerstyle={{ flexGrow: 1, marginRight: "20px" }} data={timeblocks} value={campaign.options.betweenStart} name="betweenStart" onChange={updateOptions}></UILIB.Select>
                                <UILIB.Select label='End Time' outerstyle={{ flexGrow: 1 }} data={timeblocks} value={campaign.options.betweenEnd} name="betweenEnd" onChange={updateOptions}></UILIB.Select>
                            </div>
                        </div>}
                </UILIB.Column>
            </UILIB.Row>
        </UILIB.Paper>
    )
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';

@connect((store) => {
    return { user: store.user }
})
export default class DeleteStaffPop extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
        };

        this.deleteDomain = this.deleteDomain.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    deleteDomain() {
        var domainId = this.props.domainId;
        self = this;
        axios.delete('/accountMaster/domain/' + domainId).then((res) => {
            this.closeDrawer();
            this.props.onDeleted();
        })
    }

    render() {

        return <div>

            <h4 className="mar-b25">{i18n.t('company:domains.deleteHeader')}</h4>
            <div className="mar-b25">{i18n.t('company:domains.deleteSubHeader')}</div>


            <UILIB.Button text={i18n.t('company:domains.deleteButton')} className="button-red" onClick={this.deleteDomain} />

        </div >
    }
};


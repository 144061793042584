import React, { useEffect, useState } from 'react'
import axios from '~/data/http/axios'
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions'
import i18n from '~/i18n'

export default function ResendClicks({ campaign }) {
    const [page, setPage] = useState(0)
    const [sends, setSends] = useState(null)
    const [total, setTotal] = useState(0)
    const [searchText, setSearchText] = useState('')
    const limit = 15

    const tableUpdater = (currentPage) => {
        setPage(currentPage)
    }

    const changeSearchText = event => {
        setSearchText(event.currentTarget.value)
    }

    const keyPress = event => {
        if (event.key === 'Enter') {
            search();
        }
    }

    const search = async () => {
        let query = `?type=clicks&offset=${page * limit}`
        if (searchText) query += `&search=${searchText}`
        const res = await axios.get('/campaign/' + campaign.id + '/stats/resent/detail' + query)
        setSends(res.data.rows)
        setTotal(res.data.count)
    }

    useEffect(() => {
        search()
    }, [campaign, page])

    if (!sends) return <UILIB.LoadingIcons iconType="2" />

    const data = sends.map(row => ({
        emailAddress: {
            headerValue: i18n.t('emailAddress'),
            value: row.Subscriber.emailAddress,
        },
        click: {
            headerValue: i18n.t('campaigns:resend.clicks.clicked'),
            value: DateTimeFunctions.formatDateTime(row.createdAt)
        },
        url: {
            headerValue: 'URL',
            value: row.TemplateLink ? row.TemplateLink.link : '--UNKNOWN--'
        }
    }))
    return (
        <UILIB.Row>
            <UILIB.Column xs={12} sm={6} md={4}>
                <UILIB.TextInput placeholder={i18n.t('searchForEmail')} onChange={changeSearchText} onKeyPress={keyPress} />
            </UILIB.Column>
            <UILIB.Column md={2} sm={12} xs={12}>
                <UILIB.Button onClick={search} style={{ minWidth: "150px" }}>{i18n.t('search')}</UILIB.Button>
            </UILIB.Column>
            <UILIB.Column xs={12}>
                <UILIB.Paper>
                    <UILIB.DataTable1 tableData={data} dataUpdater={tableUpdater} width="100%" noResultsTxt={i18n.t('campaigns:resend.clicks.none')} />
                </UILIB.Paper>
                <div style={{ textAlign: "center" }}>
                    <UILIB.PagingBlock totalRows={total} pageSize={limit} numberOfLinks="10" currentPage={page}
                        changePage={tableUpdater} text={i18n.t('page') + ":"} />
                </div>
            </UILIB.Column>
        </UILIB.Row>
    )
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';

import AddGroupView from '~/pages/cp/subscribers/groups/incGoAddGroup';
import ChangeGroupConfirm from './incChangeGroupConfirm';
import SelectFields from './selectFields'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class CapsuleConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactsGroupId: -1,
            leadsGroupId: -1,
            groupData: [],
            isLoaded: false
        };

        this.changeGroup = this.changeGroup.bind(this);
        this.addAGroup = this.addAGroup.bind(this);
        this.addedGroup = this.addedGroup.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.changeGroupConfirm = this.changeGroupConfirm.bind(this);
        this.changeTheGroup = this.changeTheGroup.bind(this);
    }

    componentDidMount() {
        this.getGroups();
    }

    getGroups() {
        var self = this;
        axios.get("/group").then(_theGroups => {
            self.setState({ groupData: _theGroups.data.Groups, isLoaded: true })
        })
    }

    changeGroup(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    addAGroup(contactsType) {
        var drawerContent = <AddGroupView addedGroup={(event) => { this.addedGroup(event, contactsType) }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    addedGroup(event, contactsType) {
        var groupId = event.data.groupId;
        this.setState({ [contactsType]: groupId }, this.getGroups());
    }


    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    changeGroupConfirm() {
        var drawerContent = <ChangeGroupConfirm changeTheGroup={this.changeTheGroup} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    changeTheGroup() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
        this.props.updateGroup(this.state.contactsGroupId, this.state.leadsGroupId);
    }


    render() {

        if (this.state.isLoaded == false) return <div></div>

        var groupData = [];
        groupData = this.state.groupData.map(theRow => {
            return { label: theRow.groupName, value: Number(theRow.id) }
        });
        groupData.unshift({ label: "Select a Group", value: -1 });

        return <div>
            <UILIB.Row>
                <UILIB.Column xs={12} margin={0}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">Dynamics is Connected!</h4>
                        <div className="mar-b25">We will automatically import and sync both Contacts and Leads from Dynamics. <br />
                            Select which fields you want to import. <br />
                            Please choose the groups which will be used to store each type of contact record...</div>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12}>
                    <UILIB.Paper>
                        <SelectFields appBinding={this.props.appBinding} />
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} margin={0}>
                    <UILIB.Paper className={this.state.contactsGroupId == -1 ? "secondary" : "primary"}>
                        <div className="text-md mar-b20">Contacts</div>
                        <div className="mar-b20">Please select the group which will be synced with your Dynamics Contacts</div>
                        <UILIB.Row margin={0}>
                            {groupData.length &&
                                <UILIB.Column xs={12}>
                                    <div className="quickFlex">
                                        <div className="form-group mar-b0"><UILIB.Select data={groupData} name="contactsGroupId" value={this.state.contactsGroupId} onChange={this.changeGroup} /></div>
                                        <span style={{ marginLeft: "10px" }}>or</span>
                                        <UILIB.Button text="Add a new Group" className="button-sml outline" style={{ marginLeft: "10px" }} onClick={() => this.addAGroup("contactsGroupId")} />
                                    </div>
                                </UILIB.Column>
                            }
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} margin={0}>
                    <UILIB.Paper className={this.state.leadsGroupId == -1 ? "secondary" : "primary"} >
                        <div className="text-md mar-b20">Leads</div>
                        <div className="mar-b20">Please select the group which will be synced with your Dynamics Leads</div>
                        <UILIB.Row margin={0}>
                            {groupData.length &&
                                <UILIB.Column xs={12}>
                                    <div className="quickFlex">
                                        <div className="form-group mar-b0"><UILIB.Select data={groupData} name="leadsGroupId" value={this.state.leadsGroupId} onChange={this.changeGroup} /></div>
                                        <span style={{ marginLeft: "10px" }}>or</span>
                                        <UILIB.Button text="Add a new Group" className="button-sml outline" style={{ marginLeft: "10px" }} onClick={() => this.addAGroup("leadsGroupId")} />
                                    </div>
                                </UILIB.Column>
                            }

                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} className="center-xs">
                    <UILIB.Button text="Save and Continue" disabled={this.state.contactsGroupId == -1 || this.state.leadsGroupId == -1 ? true : false} onClick={this.changeGroupConfirm} />
                </UILIB.Column>
            </UILIB.Row>

        </div >

    };


};



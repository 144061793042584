import React from 'react'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib';
import i18n from '~/i18n';
import axios from '~/data/http/axios';
import tierPermissions from '~/Classes/tierPermissions';
import DrawerAdCampaign from './adCampaign';

function setInterestedInFromProps(props) {
    let result = ""
    if (props.campaignType === 6 && !props.accountMaster.accountMaster?.beta?.sms) {
        result = "sms"
    }
    return result
}

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, site: store.siteMaster }
})
export default class DrawerAddCampaignStep1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCampaignType: this.props.selectSms || this.props.campaignType || 1,
            campaignName: "",
            creatingCampaign: false,
            campaignNameErr: "",
            popupModal: null,
            hasSocialsBeta: this.props.accountMaster.accountMaster.beta?.social,
            interestedInCampaign: setInterestedInFromProps(this.props)
        };

        this.startCampaign = this.startCampaign.bind(this);
        this.selectAdCampaign = this.selectAdCampaign.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    componentDidMount() {
        axios.get('/accountMaster/tier/abSplitTests').then(res => {
            this.setState({
                abSplitPermissions: res.data
            })
        })
    }

    selectCampaignType(campaignType) {
        this.setState({ selectedCampaignType: campaignType })
    }

    async startCampaign() {
        if (!this.state.selectedCampaignType) return;

        if (this.state.selectedCampaignType === 30 && !this.props.accountMaster.accountMaster?.beta?.social) {
            return false
        }
        if (this.state.selectedCampaignType === 6 && !this.props.accountMaster.accountMaster?.beta?.sms) {
            return false
        }

        var campaignName = this.state.campaignName;
        if (typeof campaignName === 'string') campaignName = campaignName.trim();
        if (campaignName === null || campaignName === undefined || campaignName.length < 4) {
            this.setState({ campaignNameErr: "Please enter a longer name", creatingCampaign: false, campaignName });
            return;
        }

        this.setState({ campaignNameErr: "", creatingCampaign: true })

        var theURL = "/cp/campaigns/add/standard/"
        switch (this.state.selectedCampaignType) {
            case 1:
                {
                    theURL = "/cp/campaigns/add/standard/:campaignId/1";
                    break;
                }
            case 10:
                {
                    theURL = "/cp/campaigns/add/ab/:campaignId";
                    break;
                }
            case 3:
                {
                    theURL = "/cp/campaigns/add/automation/:campaignId";
                    break;
                }
            case 6:
                {
                    theURL = "/cp/campaigns/add/sms/:campaignId";
                    break;
                }
            case 20:
                {
                    theURL = "/cp/campaigns/add/directMail/:campaignId";
                    break;
                }
            case 30:
                {
                    theURL = "/cp/campaigns/add/social/:campaignId";
                    break;
                }
            default:
                theURL = "/cp/campaigns/add/standard/:campaignId/1";
                break;
        }
        var options = {}
        if (this.props.user.userData.timeZone) {
            options.timezone = this.props.user.userData.timeZone
        }
        try {
            let res = await axios.post('/campaign', {
                campaignName,
                type: this.state.selectedCampaignType,
                startDate: this.props.startDate || new Date(),
                options,
                Groups: this.props.Groups
            })
            theURL = theURL.replace(':campaignId', res.data.Campaign.id);

            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null));
            this.props.history.push(theURL);
        }
        catch (err) {
            // console.log(err)
            var message = i18n.t('campaigns:add.nameError');
            if (err.data.error && typeof err.data.error == 'string') message = err.data.error;
            this.setState({ campaignAddOpen: false, campaignNameErr, creatingCampaign: false })
        }
    }

    selectAdCampaign() {

    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null));
    }


    render() {

        if (this.state.selectedCampaignType == 40) {
            return <DrawerAdCampaign history={this.props.history} exit={() => { this.setState({ selectedCampaignType: 1 }) }} />
        }


        let abPermissions = tierPermissions("abSplitTests", this.props.accountMaster)
        if (this.state.creatingCampaign || !this.state.abSplitPermissions) return <UILIB.LoadingIcons iconType="2" />

        let allowed = true;
        if (this.state.selectedCampaignType == 10 && abPermissions.allowed == false) {
            allowed = false;
        }

        if (this.state.interestedInCampaign != "") {

            let featureSubType = "";
            switch (this.state.interestedInCampaign) {
                case "sms": {
                    featureSubType = "SMS Campaigns";
                    break;
                }
            }
            return <UILIB.NewFeatureFeedback
                featureType="campaign"
                featureSubType={featureSubType}
                closeDrawer={() => { this.setState({ interestedInCampaign: "" }) }}
            />
        }

        return <div className="campaign-type-selector">
            <div className="campaign-type-selector__content">
                <div className="exit" onClick={this.closeDrawer}>
                    <span className="icon-cross2"></span>
                </div>
                <h4 className="mar-b10">{i18n.t('campaigns:main.createCampaign')}</h4>
                <div className="mar-b25">{i18n.t('campaigns:add.header')}</div>

                <UILIB.TextInput
                    outerStyle={{ marginBottom: 24 }}
                    // label={i18n.t('campaigns:main.campaignName')}
                    label={i18n.t('Name')}
                    value={this.state.campaignName}
                    onChange={(ev) => { this.setState({ campaignName: ev.target.value }) }}
                    error={this.state.campaignNameErr}
                    onEnterPress={this.startCampaign}
                    focus
                    className='cyt-new-campaign-text-input'
                />


                <div className="textInputWrapper" style={{ marginBottom: 12 }}>
                    <div className="txtInput_label">Type</div>
                </div>
                <Card
                    icon={<UILIB.Icons icon="envelope" />}
                    title={i18n.t('campaigns:add.plain')}
                    description={i18n.t('campaigns:add.plainText')}
                    featureTag={<UILIB.SquareChip className="square-chip-green">Popular</UILIB.SquareChip>}
                    selected={this.state.selectedCampaignType == 1}
                    onClick={() => { this.selectCampaignType(1) }}
                />

                <Card
                    icon={<UILIB.Icons icon="split" />}
                    title={i18n.t('campaigns:add.split')}
                    description={i18n.t('campaigns:add.splitText')}
                    selected={this.state.selectedCampaignType == 10}
                    onClick={() => { this.selectCampaignType(10) }}
                />


                {this.props.site.siteId === 1 && !!this.state.hasSocialsBeta && <>

                    <Card
                        icon={<UILIB.Icons icon="share" />}
                        title={i18n.t('campaigns:add.social')}
                        description={i18n.t('campaigns:add.socialText')}
                        featureTag={<UILIB.SquareChip className="square-chip-purple">Beta</UILIB.SquareChip>}
                        selected={this.state.selectedCampaignType == 30}
                        onClick={() => { this.selectCampaignType(30) }}
                    />
                </>}
                {this.props.site.siteId === 1 && !this.state.hasSocialsBeta && <>

                    <Card
                        icon={<UILIB.Icons icon="share" />}
                        title={i18n.t('campaigns:add.social')}
                        description={i18n.t('campaigns:add.socialText')}
                        featureTag={<UILIB.SquareChip className="square-chip-blue">Labs</UILIB.SquareChip>}
                        selected={this.state.selectedCampaignType == 30}
                        hoverButton={<UILIB.Button className="button-sml"
                            onClick={() => {
                                this.setState({
                                    popupModal: <UILIB.LabsEnable labId={9} onClose={(labAdded) => {
                                        this.setState({ popupModal: undefined, hasSocialsBeta: labAdded, selectedCampaignType: labAdded ? 30 : this.state.selectedCampaignType });
                                    }}
                                        includeLearnMore={true} />
                                })
                            }}
                            iconRight={<UILIB.Icons icon="arrowRight" />}>Enable</UILIB.Button>}
                    />
                </>}

                {!!this.props.accountMaster.accountMaster.beta && this.props.accountMaster.accountMaster.beta.ads && <>

                    <Card
                        icon={<UILIB.Icons icon="megaphone" />}
                        title={i18n.t('campaigns:add.adCampaign')}
                        description={i18n.t('campaigns:add.adCampaignText')}
                        featureTag={<UILIB.SquareChip className="square-chip-purple">Beta</UILIB.SquareChip>}
                        selected={this.state.selectedCampaignType == 40}
                        onClick={() => { this.selectCampaignType(40); this.selectAdCampaign(); }}
                    />
                </>}

                {this.props.site.siteId === 1 &&
                    <Card
                        icon={<UILIB.Icons icon="sms" />}
                        title={i18n.t('campaigns:add.sms')}
                        description={i18n.t('campaigns:add.smsText')}

                        selected={this.state.selectedCampaignType == 6}
                        onClick={() => {
                            if (this.props.accountMaster.accountMaster?.beta?.sms) {
                                this.selectCampaignType(6)
                            } else {
                                this.setState({ interestedInCampaign: "sms" })
                            }
                        }}
                    />}

                {!this.state.abSplitPermissions.allowed && this.state.selectedCampaignType == 10 && <UILIB.TierLimitHolder type="AB Split Tests" {...this.state.abSplitPermissions} />}
            </div>





            <div className="campaign-type-selector__sticky-footer">
                <div className="campaign-type-selector__sticky-footer__content">
                    <UILIB.Button
                        disabled={!allowed}
                        className="button-primary mar-r10 cyt-new-campaign-btn"
                        text={i18n.t('campaigns:add.nameButton')}
                        onClick={this.startCampaign}
                    />
                    <UILIB.Button onClick={this.closeDrawer}>Cancel</UILIB.Button>
                </div>
            </div>

            {this.state.popupModal}
        </div >

    };
}

function Card({ icon, selected, title, description, featureTag, hoverButton, onClick }) {
    return (
        <div className={"campaignFeatureCard" + (selected ? " campaignFeatureCard--selected" : "")} onClick={onClick} style={{ display: 'block' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="campaignFeatureCard__icon">
                    {icon}
                </div>
                <div className="campaignFeatureCard__end">
                    {!!hoverButton && <div className="campaignFeatureCard__hoverButton">{hoverButton}</div>}
                    {!hoverButton && <div>
                        {selected && <UILIB.Icons icon="tickCircle" style={{ height: 20, width: 20 }} color="#5934BF" />}
                        {!selected && <UILIB.Icons icon="circleThin" style={{ height: 20, width: 20 }} color="#D9DDE3" />}
                    </div>}
                </div>
            </div>
            <div className="campaignFeatureCard__content">
                <div className="campaignFeatureCard__title">{title} {featureTag}</div>
                <div className="campaignFeatureCard__description">{description}</div>
            </div>

        </div>
    )

}
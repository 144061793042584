import React from 'react'
import axios from 'axios'
import UILIB from '~/Common-Objects/Lib'
import DBConn from '~/Classes/siteVars.js'
import queryString from 'query-string';
import OfflineWeb from '~/assets/images/genericpagenotfound/noweb-desktop.png'
import { connect } from 'react-redux';

@connect((store) => {
    return { siteMaster: store.siteMaster }
})

export default class OAuthAuthorise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            dataIncorrect: false,
            appId: 0,
            redirect_uri: "",
            response_type: "",
            app_state: "",
            username: "",
            password: "",
            errors: {
                username: "",
                password: ""
            }
        }

        this.loadApp = this.loadApp.bind(this);
        this.changeLogin = this.changeLogin.bind(this);
        this.checkLogin = this.checkLogin.bind(this);
    }

    componentDidMount() {

        const query = queryString.parse(location.search);
        const response_type = query.response_type;
        const appid = query.appid;
        const redirect_uri = query.redirect_uri;
        const app_state = query.state;

        if (!appid || !redirect_uri) {
            this.setState({ dataIncorrect: true, dataLoaded: true })
            return;
        }
        this.setState({ appId: appid, redirect_uri: redirect_uri, response_type: response_type, app_state }, this.loadApp)
    }

    loadApp() {
        var baseUrl = DBConn.getConnString()
        axios.get(baseUrl + '/api/oauth/app/' + this.state.appId).then(_res => {
            this.setState({ appData: _res.data.Application, dataIncorrect: false, dataLoaded: true })
        }).catch(err => {
            this.setState({ dataIncorrect: true, dataLoaded: true })
        })

    }

    changeLogin(event) {
        var theState = this.state;
        theState[event.target.name] = event.target.value;
        this.setState(theState)
    }

    checkLogin() {

        var errors = { username: "", password: "" }
        var errored = false;

        if (!this.state.username) {
            errors.username = "Invalid username";
            errored = true;
        }
        if (!this.state.password) {
            error.password = "Invalid password";
            errored = true;
        }
        if (errored) {
            this.setState({ errors: errors })
            return;
        }

        var dataToPost = {
            appId: this.state.appId,
            username: this.state.username,
            password: this.state.password,
            redirect_uri: this.state.redirect_uri,
            url: window.location.host.split('/')[0]
        }

        var baseUrl = DBConn.getConnString()
        axios.post(baseUrl + '/api/oauth/app/' + this.state.appId + '/login', dataToPost).then(_res => {
            var finalUrl = this.state.redirect_uri + "?code=" + _res.data.code;
            if (this.state.app_state && this.state.app_state.length) {
                finalUrl += "&state=" + this.state.app_state
            }
            window.open(finalUrl, "_self")
        }).catch(err => {
            errors.username = err.response.data.error;
            errors.password = err.response.data.error;
            this.setState({ errors })
        });
    }

    render() {
        if (!this.state.dataLoaded) return <div></div>
        if (this.state.dataIncorrect) return <div className="quickFlex" style={{ alignItems: "center", justifyContent: "center", height: "100vh" }}>
            <UILIB.Paper className="with_header" style={{ width: "100%", minWidth: "300px", maxWidth: "600px" }}>
                <div className="paper_header" style={{ backgroundColor: this.props.siteMaster.colours["$primary"], color: 'white' }}>
                    <div className="quickFlex" style={{ alignItems: "center" }}>
                        <UILIB.Icons icon="alert" color={"#FFFFFF"} style={{ width: "20px", height: "20px" }} />
                        <div className='mar-l10 text-heavy' style={{ fontSize: 16 }}>Somethings Wrong</div>
                    </div>
                </div>
                <div className="paper_content">
                    <div className="mar-b10">It looks like something has not worked as expected.</div>
                    <div className="mar-b25">Please try again.</div>
                    <UILIB.Button className="button-primary" text="Continue" onClick={() => { this.props.history.push('/') }} />
                </div>
            </UILIB.Paper>
        </div>


        var ourSiteLogo = "http://cdn1.ourmailsender.com/siteContent/transpondLogos/logomark_dark.png";
        if (this.props.siteMaster.siteId != 1) ourSiteLogo = this.props.siteMaster.siteLogo;
        if (this.props.siteMaster.siteId == "4") ourSiteLogo = "http://cdn1.ourmailsender.com/siteContent/appLogos/myGoldMail.jpg";

        var ourAppDescription = this.state.appData.appDescription;
        if (ourAppDescription) ourAppDescription = ourAppDescription.replace(/transpond/gi, this.props.siteMaster.siteName)

        return <div className="container-fluid">
            <UILIB.Row className="center-xs middle-xs around-xs height-100-vh">
                <UILIB.Column>
                    <UILIB.Paper className="max-width-500">


                        <h4 className="mar-b25">Connect your account to <span className="text-heavy text-primary">{this.state.appData.appName}</span></h4>

                        <UILIB.Row className="middle-xs mar-b30">
                            <UILIB.Column xs={4} margin={0} className="end-xs">
                                <img src={ourSiteLogo} className="rounded" style={{ width: "50px" }} />
                            </UILIB.Column>
                            <UILIB.Column xs={4} margin={0}>
                                <span className="icon-tab text-lg2 text-grey"></span>
                            </UILIB.Column>
                            <UILIB.Column xs={4} margin={0} className="start-xs">
                                <img src={this.state.appData.appLogoUri} className="rounded" style={{ width: "50px" }} />
                            </UILIB.Column>
                        </UILIB.Row>


                        <div className="mar-b25">{ourAppDescription}</div>

                        <div className="text-left mar-b25">
                            <UILIB.TextInput label="Username" name="username" value={this.state.username} error={this.state.errors.username} onChange={this.changeLogin} type="email" outerClassName="mar-b10" />
                            <UILIB.TextInput label="Password" name="password" value={this.state.password} error={this.state.errors.password} onChange={this.changeLogin} type="password" />
                        </div>

                        <UILIB.Button className="button-primary" text="Login and Connect" onClick={this.checkLogin} />

                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
        </div >
    }

};
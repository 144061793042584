import React from 'react'
import axios from 'axios'
import UILIB from '~/Common-Objects/Lib'
import DBConn from '~/Classes/siteVars.js'
import queryString from 'query-string';
import { connect } from 'react-redux';

@connect((store) => {
    return { siteMaster: store.siteMaster }
})

export default class AppConnect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appData: {},
            appid: 0,
            code: "",
            appLoaded: false,
            redirect: ""
        }

        this.loadApp = this.loadApp.bind(this);
        this.login = this.login.bind(this);
        this.register = this.register.bind(this);
    }

    componentDidMount() {

        const query = queryString.parse(location.search);
        var appid = query.appid;
        var code = query.code;
        if (this.props.match && this.props.match.params && this.props.match.params.appId) appid = this.props.match.params.appId;


        var authorise = false
        if (appid && appid.indexOf('_') > -1) {
            var split = appid.split('_')
            appid = split[0]
            authorise = !!split[1]
        }
        // hubspot hack
        if (appid == 55) appid = 46

        var redirect = ''
        if (code) {
            redirect = "/cp/company/integrations/oAuth/response/" + appid + "?code=" + code;
        }
        this.setState({ appid: appid, code, redirect, authorise }, this.loadApp)
    }

    loadApp() {
        var baseUrl = DBConn.getConnString()
        axios.get(baseUrl + '/api/oauth/app/' + this.state.appid).then(_res => {
            var redirect = this.state.redirect
            var app = _res.data.Application
            var theURI
            if (!redirect && app.oAuthAuthoriseUri) {
                theURI = app.oAuthAuthoriseUri;
                theURI = theURI.replace(/\[OAUTHCLIENTID\]/g, app.oAuthAppId);
                // theURI = theURI.replace(/\[RANDNO\]/g, this.props.user.token);
                var redirectURI = window.location.origin;
                if (redirectURI === 'https://app.transpond.io') {
                    redirectURI = 'https://web.mpzmail.com'
                } else if (redirectURI === 'http://localhost:8082') {
                    redirectURI = 'http://localhost:8082'
                }
                var toReplaceWith = redirectURI + "/cp/company/integrations/oAuth/response/" + app.id
                if (app.options && app.options.backendAuth) {
                    let domain = this.props.siteMaster.trackingDomain
                    if (domain === 'api.transpond.io') {
                        domain = 'api.mpzmail.com'
                        toReplaceWith = 'https://' + domain + '/api/oauth/app/' + app.id + '/oauth'
                    } else if (domain === 'localhost:8082') {
                        domain = 'localhost:8082'
                        toReplaceWith = 'http://' + domain + '/api/oauth/app/' + app.id + '/oauth'
                    }
                }
                theURI = theURI.replace(/\[OAUTHREDIRECTURL\]/g, toReplaceWith);
                // redirect = encodeURIComponent(theURI)
                redirect = '/appconnect?appid=' + app.id + '_1'
            }
            if (this.state.authorise && theURI) {
                window.open(theURI, '_self');
            } else {
                this.setState({ appData: _res.data.Application, appLoaded: true, redirect })
            }
        }).catch(err => {
            this.setState({})
        })

    }

    login() {
        window.open('/?redirect=' + this.state.redirect, "_self")
    }

    register() {
        window.open('/register?redirect=' + this.state.redirect, "_self")
    }

    render() {
        if (!this.state.appLoaded) return <div></div>

        var ourSiteLogo = "http://cdn1.ourmailsender.com/siteContent/transpondLogos/logomark_dark.png";
        if (this.props.siteMaster.siteId != 1) ourSiteLogo = this.props.siteMaster.siteLogo;
        if (this.props.siteMaster.siteId == "4") ourSiteLogo = "http://cdn1.ourmailsender.com/siteContent/appLogos/myGoldMail.jpg";

        var ourAppDescription = this.state.appData.appDescription;
        if (ourAppDescription) ourAppDescription = ourAppDescription.replace(/transpond/gi, this.props.siteMaster.siteName)

        return <div className="container-fluid">
            <UILIB.Row className="height-100-vh center-xs middle-xs">
                <UILIB.Column xs={12} style={{ width: "100%", maxWidth: "600px" }}>
                    <UILIB.Paper>


                        <h4 className="mar-b25">Connect <span className="text-heavy text-primary">{this.props.siteMaster.siteName}</span>  to <span className="text-heavy text-primary">{this.state.appData.friendlyName || this.state.appData.appName}</span></h4>


                        <div className="quickFlex mar-b25">
                            <div style={{ flex: 1 }}>
                                <img src={ourSiteLogo} style={{ width: "50px" }} />
                            </div>
                            <div>
                                <UILIB.Icons icon="switch" color="grey" style={{ width: 30, height: 30 }} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <img src={this.state.appData.appLogoUri} className="rounded" style={{ width: "50px" }} />
                            </div>
                        </div>


                        <div className="mar-b25">{ourAppDescription}</div>

                        <h4 className="mar-b25">Do you already have a {this.props.siteMaster.siteName} account?</h4>

                        <div className="quickFlex center-xs mar-b25">
                            <UILIB.Button className="button-primary mar-r10" text="Yes, Login" onClick={this.login} />
                            <span>or <a onClick={this.register}> Create a new account</a></span>
                        </div>

                        <hr className="hide-xs" />

                        <UILIB.Row style={{ textAlign: "left" }} className="hide-xs">
                            <UILIB.Column xs={12} sm={6} md={4} className="quickFlex mar-b25" style={{ alignItems: "flex-start" }}>
                                <UILIB.Icons icon="tick" className="mar-r5" />
                                <span>Unlimited Contacts</span>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} md={4} className="quickFlex mar-b25" style={{ alignItems: "flex-start" }}>
                                <UILIB.Icons icon="tick" className="mar-r5" /><span>No Setup Fee</span>
                            </UILIB.Column>

                            <UILIB.Column xs={12} sm={6} md={4} className="quickFlex mar-b25" style={{ alignItems: "flex-start" }}>
                                <UILIB.Icons icon="tick" className="mar-r5" /> <span>Friendly Support</span>
                            </UILIB.Column>

                            <UILIB.Column xs={12} sm={6} md={4} className="quickFlex" style={{ alignItems: "flex-start" }}>
                                <UILIB.Icons icon="tick" className="mar-r5" /><span>Email Templates</span>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} md={4} className="quickFlex" style={{ alignItems: "flex-start" }}>
                                <UILIB.Icons icon="tick" className="mar-r5" /> <span>Campaign Tracking</span>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} md={4} className="quickFlex" style={{ alignItems: "flex-start" }}>
                                <UILIB.Icons icon="tick" className="mar-r5" /> <span>Automation</span>
                            </UILIB.Column>

                        </UILIB.Row>

                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
        </div >
    }

};
import React from 'react'
import UILIB from '~/Common-Objects/Lib'

export default function LowCreditsSettings({ account, changeLowSettings, onChange, saveAccount }) {
    const options = account.options || {}

    let lowCreditSetting = 'stop'
    if (options.autoTopupSms) {
        lowCreditSetting = 'topup'
    } else if (options.smsLowAlertEmail !== undefined) {
        lowCreditSetting = 'email'
    }
    return (
        <>
            <UILIB.Select label="Low Credit Settings" outerClassName="mar-b25" data={[
                { label: 'Stop Sending SMS', value: 'stop' },
                { label: 'Send Email Alert', value: 'email' },
                { label: 'Auto Top Up', value: 'topup' }
            ]} value={lowCreditSetting} onChange={changeLowSettings} />

            {lowCreditSetting === 'topup' && <>
                <UILIB.TextInput outerClassName="mar-b25" label="Topup when credit goes below xxx" value={options.autoTopupSmsThreshold} name="autoTopupSmsThreshold" onChange={onChange} type="number" />
                <UILIB.TextInput outerClassName="mar-b25" explainer="Minimum 10" label="Topup xxx Credits" value={options.autoTopupSmsAmount} name="autoTopupSmsAmount" onChange={onChange} type="number" />
                <UILIB.TextInput outerClassName="mar-b25" label="Notificaton Email Address" type="email" name="smsLowAlertEmail" onChange={onChange} value={options.smsLowAlertEmail} />
            </>}

            {lowCreditSetting === 'email' && <>
                <UILIB.TextInput outerClassName="mar-b25" label="Sent alert when credit goes below xxx" value={options.autoTopupSmsThreshold} name="autoTopupSmsThreshold" onChange={onChange} type="number" />
                <UILIB.TextInput outerClassName="mar-b25" label="Notificaton Email Address" type="email" name="smsLowAlertEmail" onChange={onChange} value={options.smsLowAlertEmail} />
            </>}


            <UILIB.Button text="Save Settings" className="button-primary" onClick={saveAccount} />

        </>
    )
}
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import SiteVars from '~/Classes/siteVars';
import queryString from 'query-string';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import TemplateSelector from '../../campaigns/add/steps/step2/standardCampaignStep2'
import EmailPreviewer from '~/pages/cp/includes/emailPreviewer/emailPreviewer'
import EmailValidator from '~/Classes/emailValidator'
import AddDomainPop from '../../campaigns/add/steps/step3/addDomainPop';
import EmailTools from '~/Classes/emailValidator';
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})
class AutomationStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            waitUnits: [{
                label: 'Hours',
                value: 'hours',
            }, {
                label: 'Days',
                value: 'days'
            }, {
                label: 'Weeks',
                value: 'weeks'
            }, {
                label: 'Months',
                value: 'months'
            }],
            links: [],
            quickPreviewEmailAddressErrorDomainAuth: '',
            fieldType: 'TEXT',
            now: Date.now()
        }

        this.processAction = this.processAction.bind(this)
        this.designTemplate = this.designTemplate.bind(this)
        this.saveCampaign = this.saveCampaign.bind(this)
        this.quickPreviewSend = this.quickPreviewSend.bind(this)
        this.openEmailPreview = this.openEmailPreview.bind(this)
        this.goValidateDomain = this.goValidateDomain.bind(this)
        this.processTrigger = this.processTrigger.bind(this)
        this.checkFromAddress = this.checkFromAddress.bind(this)
        this.checkCampaign = this.checkCampaign.bind(this)
        this.getLinks = this.getLinks.bind(this)
        this.checkTemplateImageExists = this.checkTemplateImageExists.bind(this)
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        if (query.reselect && query.step == this.props.index) {
            this.designTemplate()
        }
        if (this.props.step.campaign) {
            this.checkFromAddress()
        }
        this.getLinks()
    }

    processTrigger() {
        var result = <div></div>
        switch (this.props.step.trigger) {
            case 'datetime':
            case 'anniversary':
                result = <UILIB.Column xs={6} className="form-group">
                    <label htmlFor="">Field</label>
                    <UILIB.Select data={this.props.fields.filter(c => c.fieldType === 'DATE').map(c => {
                        return {
                            label: c.fieldDesc,
                            value: c.id
                        }
                    })} value={this.props.step.customFieldId} onChange={ev => this.props.updateStep(this.props.index, 'customFieldId', ev)} placeholder="Select DateTime Field" />
                </UILIB.Column>
                break;
            case 'update':
                result = <UILIB.Column xs={6} className="form-group">
                    <label htmlFor="">Field</label>
                    <UILIB.Select data={this.props.fields.map(c => {
                        return {
                            label: c.fieldDesc,
                            value: c.id
                        }
                    })} value={this.props.step.customFieldId} onChange={ev => this.props.updateStep(this.props.index, 'customFieldId', ev)} placeholder="Select Field" />
                </UILIB.Column>
                break;
            case 'link':
                result = <UILIB.Column xs={6} className="form-group">
                    <label htmlFor="">Link</label>
                    {this.props.firstStep && <UILIB.TextInput value={this.props.step.link} onChange={ev => this.props.updateStep(this.props.index, 'link', ev)} placeholder="Enter full url of the link" />}
                    {!this.props.firstStep && <UILIB.Select value={this.props.step.link} data={this.state.links} onChange={ev => this.props.updateStep(this.props.index, 'link', ev)} placeholder="Select Link" />}
                </UILIB.Column>
                break;
        }
        return result
    }

    processAction() {
        switch (this.props.step.action) {
            case 'email':
                this.checkTemplateImageExists()
                var backgroundImage = "url(" + SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + this.props.step.campaign.TemplateId + ".jpg)"

                return <UILIB.Row>
                    <UILIB.Column xs={12} sm={6} md={3} className="form-group">
                        <label>From Name</label>
                        <UILIB.TextInput placeholder="Email From Name" value={this.props.step.campaign.fromName} errors={this.props.errors.fromName} onChange={ev => this.props.updateStep(this.props.index, 'campaign.fromName', ev, this.checkCampaign)} />
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} md={3} className="form-group">
                        <label>From Address</label>
                        <UILIB.TextInput type="email" placeholder="Email From Address" value={this.props.step.campaign.fromAddress} onChange={this.checkFromAddress} error={this.props.errors.fromAddress} warning={this.props.errors.replyWarning} />
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} md={3} className="form-group">
                        <label>Reply Address</label>
                        <UILIB.TextInput type="email" placeholder="Email Reply Address" value={this.props.step.campaign.replyAddress} onChange={ev => this.props.updateStep(this.props.index, 'campaign.replyAddress', ev, this.checkCampaign)} error={this.props.errors.replyAddress} />
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} md={3} className="form-group">
                        <label>Subject</label>
                        <UILIB.TextInput type="email" placeholder="Email Subject" name="subject" value={this.props.step.campaign.subject} error={this.props.errors.subject} onChange={ev => this.props.updateStep(this.props.index, 'campaign.subject', ev, this.checkCampaign)} />
                    </UILIB.Column>
                    <UILIB.Column xs={12}>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={6} className="form-group">
                                <label>Time to Send</label>
                                <UILIB.Select name="sendTime" time={15} value={this.props.step.sendTime} onChange={(ev) => this.props.updateStep(this.props.index, 'sendTime', ev)} />
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6}>
                                <UILIB.Button text="Design Template" onClick={this.designTemplate} className="button-md full-width" />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Column>
                    {!!this.props.step.campaign.TemplateId && <UILIB.Column xs={12} sm={6}>
                        <div className="template-preview-sml" onClick={this.openEmailPreview}>
                            <div className="magnifyIcon icon-magnifier icon24 text-white" />
                            <div className="templateImage">
                                <div className="templateImageHolder">
                                    {this.state.templateImageLoaded &&
                                        <div className="templateImageImg" style={{ backgroundImage }} />
                                    }
                                    {!this.state.templateImageLoaded && <UILIB.LoadingIcons iconType="2" />}

                                </div>
                            </div>
                        </div>
                    </UILIB.Column>}
                    {!!this.props.step.campaign.TemplateId && <UILIB.Column xs={12} sm={6} margin={0}>
                        <div className="form-group mar-b15">
                            <label htmlFor="">{i18n.t('campaigns:standard.step3.sendTest')}</label>
                            <UILIB.TextInput type="email" name="previewEmailTo" onChange={(event) => { this.setState({ quickPreviewEmailAddress: event.target.value }) }} value={this.state.quickPreviewEmailAddress} placeholder="Enter email address here" error={this.state.quickPreviewEmailAddressError} />
                            {this.state.quickPreviewEmailAddressErrorDomainAuth}
                        </div>
                        {this.props.accountMaster.accountMaster.approved == true && this.state.quickPreviewEmailAddressErrorDomainAuth == '' && <span>
                            <UILIB.Button className="primary no-shadow" text="SEND" onClick={this.quickPreviewSend} />

                        </span>
                        }
                        {this.props.accountMaster.accountMaster.approved == false && <span>
                            {i18n.t('campaigns:standard.step3.activate')}
                        </span>
                        }
                    </UILIB.Column>}
                </UILIB.Row>
            case 'update':
                var fieldType = 'TEXT';
                if (this.props.step.actionUpdate) {
                    var found = this.props.fields.find(f => f.id == this.props.step.actionUpdate)
                    if (found) fieldType = found.fieldType
                }
                return <UILIB.Row>
                    <UILIB.Column xs={6} className="form-group">
                        <label htmlFor="">Field To Update</label>
                        <UILIB.Select value={this.props.step.actionUpdate} data={this.props.fields.map(c => {
                            return {
                                label: c.fieldDesc,
                                value: c.id
                            }
                        })} placeholder="Select field to update" error={this.props.errors.actionUpdate} onChange={ev => this.props.updateStep(this.props.index, 'actionUpdate', ev, this.checkCampaign)} />
                    </UILIB.Column>
                    <UILIB.Column xs={6} className="form-group">
                        <label htmlFor="">Value</label>
                        {fieldType === 'TEXT' && <UILIB.TextInput value={this.props.step.actionValue} error={this.props.errors.actionValue} placeholder="Set value to update" onChange={ev => this.props.updateStep(this.props.index, 'actionValue', ev, this.checkCampaign)} />}
                        {fieldType === 'NUMBER' && <UILIB.TextInput type="number" value={this.props.step.actionValue} error={this.props.errors.actionValue} placeholder="Set value to update" onChange={ev => this.props.updateStep(this.props.index, 'actionValue', ev, this.checkCampaign)} />}
                        {fieldType === 'DATE' && <UILIB.TextInput type="date" value={this.props.step.actionValue} error={this.props.errors.actionValue} placeholder="Set value to update" onChange={ev => this.props.updateStep(this.props.index, 'actionValue', ev, this.checkCampaign)} />}
                        {fieldType === 'BOOLEAN' && <UILIB.Select value={this.props.step.actionValue} error={this.props.errors.actionValue} placeholder="Set value to update" data={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} onChange={ev => this.props.updateStep(this.props.index, 'actionValue', ev, this.checkCampaign)} />}
                    </UILIB.Column>
                </UILIB.Row>
            case 'addToGroup':
            case 'moveToGroup':
            case 'removeFromGroup':
                return <UILIB.Row>
                    <UILIB.Column xs={6} className="form-group">
                        <label htmlFor="">Group</label>
                        <UILIB.Select value={this.props.step.actionGroup} data={this.props.groups.map(c => {
                            return {
                                label: c.groupName,
                                value: c.id
                            }
                        })} placeholder="Select Group" error={this.props.errors.actionGroup} onChange={ev => this.props.updateStep(this.props.index, 'actionGroup', ev, this.checkCampaign)} />
                    </UILIB.Column>
                </UILIB.Row>
        }

        return <div></div>
    }

    openEmailPreview() {
        var drawerContent = <EmailPreviewer templateId={this.props.step.campaign.TemplateId} history={this.props.history} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
    }

    quickPreviewSend() {
        var self = this;
        if (EmailValidator.validateEmail(this.state.quickPreviewEmailAddress) === false) {
            this.setState({ quickPreviewEmailAddressError: i18n.t('campaigns:standard.step3.emailInvalid') })
            return;
        }
        this.setState({ quickPreviewEmailAddressError: "", alertOpen: true, alertMessage: i18n.t('campaigns:standard.step3.sendingPreview') })

        axios
            .post('/template/preview/' + this.props.step.campaign.TemplateId,
                { emailAddress: this.state.quickPreviewEmailAddress }
            )
            .then((res) => {
                self.setState({ alertOpen: true, alertMessage: i18n.t('campaigns:standard.step3.sentPreview') })

            }).catch(function (error) {
                var quickPreviewEmailAddressErrorDomainAuth = '';
                if (error.data.error.fromAddress) {
                    quickPreviewEmailAddressErrorDomainAuth = <div className="errorHolderWarning">
                        <div className="text-red mar-b15">You must verify your account email address <strong>{self.props.user.userData.emailAddress}</strong> before you can send preview emails</div>

                        <UILIB.Button className="button-sml min-shadow outline red" text="Verify your Address" onClick={self.goValidateDomain} />
                    </div>
                }
                self.setState({ quickPreviewEmailAddressError: i18n.t('campaigns:standard.step3.errorPreview'), alertOpen: true, alertMessage: i18n.t('campaigns:standard.step3.errorPreview'), quickPreviewEmailAddressErrorDomainAuth: quickPreviewEmailAddressErrorDomainAuth })
            })
    }

    goValidateDomain() {
        this.props.history.push('/cp/company/domains')
    }

    getLinks() {
        if (!this.props.previousStep || !this.props.previousStep.campaign || !this.props.previousStep.campaign.TemplateId) return
        axios.get('/template/' + this.props.previousStep.campaign.TemplateId + '/links').then(response => {
            this.setState({
                links: response.data.Links
            })
        }).catch(console.error)
    }

    checkTemplateImageExists() {
        console.log('chehcking')
        if (this.state.templateImageLoaded) return;
        var self = this;
        var img = new Image();
        img.onload = function () { self.setState({ templateImageLoaded: true }) };
        img.onerror = function () { setTimeout(self.checkTemplateImageExists, 1000); };
        img.src = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + this.props.step.campaign.Template.id + ".jpg";
    }

    checkFromAddress(ev) {
        console.log('checking from address')
        if (ev) this.props.updateStep(this.props.index, 'campaign.fromAddress', ev)
        var self = this
        clearTimeout(this.replyAddressTimer)
        this.replyAddressTimer = setTimeout(() => {
            var value = self.props.step.campaign.fromAddress
            var errors = self.props.errors || {}
            if (value.indexOf('@') > -1 && EmailTools.validateEmail(self.props.step.campaign.fromAddress)) {
                var domain = value.split('@')[1].toLowerCase()
                axios.get('/campaign/checkdomain?email=' + value)
                    .then(response => {
                        var isVerified = false;
                        var originalEmailAddress = "";
                        var originalDomainID = 0;
                        var isAuthenticated = false;
                        var replyError = ''
                        if (response.data.hasOwnProperty("Domain") === false) {
                            isVerified = true;
                        }
                        else {
                            isVerified = response.data.Domain.verified;
                            originalEmailAddress = response.data.Domain.originalEmailAddress;
                            originalDomainID = response.data.Domain.id;
                            isAuthenticated = response.data.Domain.authenticated;
                        }
                        errors.fromAddress = ''
                        errors.replyWarning = ''
                        if (!isVerified) errors.fromAddress = 'From Address Not Verified'
                        if (!response.data.Exists) errors.fromAddress = 'Domain not recognised'
                        if (!replyError && !isAuthenticated) errors.replyWarning = 'Authenticate domain for better results'
                        self.props.updateErrors(errors)
                        self.setState({
                            domainExists: response.data.Exists,
                            domainName: '@' + domain,
                            domainVerified: isVerified,
                            domainOrigEmailSentTo: originalEmailAddress,
                            domainId: originalDomainID,
                            domainBlocked: false,
                            domainAuthenticated: isAuthenticated
                        }, self.checkCampaign)
                    })
                    .catch(err => {

                        if (err.data && err.data.error) {
                            errors.fromAddress = 'Invalid From Address'
                            self.props.updateErrors(errors)
                            self.setState({
                                replyError: 'Invalid From Address',
                                domainVerified: false,
                                domainBlocked: true,
                                domainAuthenticated: false
                            }, self.checkCampaign);
                        } else {
                            self.props.updateErrors(errors)
                            self.checkCampaign();
                        }
                    })
            }
        }, 250)
    }

    checkCampaign() {
        var errors = this.props.errors || {}
        if (this.props.step.action == 'email') {
            var campaign = this.props.step.campaign
            if (!campaign) return
            if (!campaign.fromName) errors.fromName = 'From Name Required'
            else if (campaign.fromName.length < 3) errors.fromName = 'Invalid Name Required'
            else errors.fromName = ''

            if (!campaign.replyAddress) errors.fromAddress = 'Reply Address Required'
            else if (!EmailTools.validateEmail(campaign.replyAddress)) errors.fromAddress = 'Invalid Reply Address'
            else errors.replyAddress = ''

            if (!campaign.subject) errors.subject = 'Subject Required'
            else if (campaign.subject.length < 3) errors.subject = 'Invalid Subject'
            else errors.subject = ''
        } else if (this.props.step.action == 'update') {
            if (!this.props.step.actionUpdate) {
                errors.actionUpdate = 'Select Field'
            }
            if (!this.props.step.actionValue) {
                errors.actionValue = 'Select Value'
            }
        } else if (this.props.step.action !== 'inactive' && this.props.step.action != 'active') {
            if (!this.props.step.actionGroup) {
                errors.actionGroup = 'Select a Group'
            }
        }

        this.props.updateErrors(errors)
        this.setState({
            now: Date.now()
        }, this.checkTemplateImageExists)
    }

    designTemplate() {
        var step = this.props.step
        var params = 'redirect=' + encodeURI('/cp/automation/view/' + this.props.id + '?step=' + this.props.index)

        if (step.campaign.TemplateId) {
            var templateType = step.campaign.Template.templateType;
            var theURL = '/cp/templates/add/fromCode/'
            if (templateType === 0) {
                theURL = "/cp/templates/add/dragDrop/";
            }
            if (templateType === 1) {
                theURL = "/cp/templates/add/fromCode/";
            }
            if (templateType === 2) {
                theURL = "/cp/templates/add/wysiwyg/";
            }
            this.props.history.push(theURL + step.campaign.TemplateId + '?' + params)
        } else {
            var campaign = step.campaign
            campaign.type = 3
            campaign.AutomationId = this.props.id
            campaign.campaignName = 'Automation ' + this.props.name + ' Step ' + (this.props.index + 1)

            this.saveCampaign().then(response => {
                step.CampaignId = response.data.Campaign.id
                step.campaign = response.data.Campaign
                this.props.save(true)
                var drawerContent = <TemplateSelector campaign={step.campaign} params={params} saveCampaign={this.saveCampaign} history={this.props.history} />
                this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full campaign-holder'));
            })
        }
    }

    saveCampaign() {
        var campaign = this.props.step.campaign
        if (campaign.id) {
            return axios.put('/campaign/' + campaign.id, { campaign })
        } else {
            return axios.post('/campaign', campaign)
        }
    }

    render() {
        var triggers
        if (this.props.firstStep) {
            triggers = this.props.triggers.initial
        } else {
            triggers = this.props.triggers[this.props.previousStep.action]
        }
        return (
            <UILIB.Row key={this.props.index}>
                <UILIB.Column xs={12} className="center-xs">
                    <ul className="campaignSteps">
                        <li className="selected last vertical">
                            <div className="vertical above" />
                            <div className="stepCircle fill">
                                <span>{this.props.index + 1}</span>
                            </div>
                            <div className="vertical" />
                        </li>
                    </ul>
                </UILIB.Column>
                <UILIB.Column xs={12} className="mar-t30">
                    <UILIB.Paper style={{ position: 'relative' }}>
                        <UILIB.Row>
                            {!this.props.firstStep && <div className="controls topRight">
                                <a className="icon-trash2" onClick={() => this.props.deleteStep(this.props.index)}></a>
                            </div>}
                            <UILIB.Column xs={12} className="center-xs">
                                <span className="text-md text-primary">Trigger</span>
                            </UILIB.Column>
                            <UILIB.Column xs={12} md={6} className="form-group">
                                <label htmlFor="">select trigger</label>
                                <UILIB.Select placeholder="Select a Trigger" data={triggers} value={this.props.step.trigger} onChange={ev => this.props.updateStep(this.props.index, 'trigger', ev)} />
                            </UILIB.Column>
                            {this.processTrigger()}
                        </UILIB.Row>

                        {!!this.props.step.trigger && <UILIB.Row>
                            <UILIB.Column xs={12} className="center-xs">
                                <span className="text-md text-primary">Action</span>
                            </UILIB.Column>
                            <UILIB.Column xs={12} md={6} className="form-group">
                                <label>select action</label>
                                <UILIB.Select placeholder="Select an Action" data={this.props.actions} value={this.props.step.action} onChange={ev => this.props.updateStep(this.props.index, 'action', ev, this.checkCampaign)} />
                            </UILIB.Column>
                            {this.props.step.action === 'email' && <UILIB.Column xs={1} className="form-group">
                                <label htmlFor="">delay</label>
                                <UILIB.TextInput type="number" value={this.props.step.delay.value} onChange={ev => this.props.updateStep(this.props.index, 'delay.value', ev)} />
                            </UILIB.Column>}
                            {this.props.step.action === 'email' && <UILIB.Column xs={3} className="form-group">
                                <label htmlFor="">unit</label>
                                <UILIB.Select data={this.state.waitUnits} value={this.props.step.delay.unit} onChange={ev => this.props.updateStep(this.props.index, 'delay.unit', ev)} />
                            </UILIB.Column>}
                            {this.props.step.action === 'email' && (this.props.step.trigger === 'datetime' || this.props.step.trigger === 'anniversary') && <UILIB.Column xs={2} className="form-group">
                                <label htmlFor="">before/after</label>
                                <UILIB.Select data={[{
                                    label: 'Before',
                                    value: 0
                                }, {
                                    label: 'After',
                                    value: 1
                                }]} value={this.props.step.delay.after} onChange={ev => this.props.updateStep(this.props.index, 'delay.after', ev)} />
                            </UILIB.Column>}
                        </UILIB.Row>}
                        {this.processAction()}
                    </UILIB.Paper>
                </UILIB.Column>
                {this.props.lastStep && this.props.step.action === 'email' && <UILIB.Column xs={12} className="center-xs">
                    <ul className="campaignSteps">
                        <li className="selected last vertical">
                            <div className="vertical above" />
                            <UILIB.Button text="add another trigger & action" className="button-sml primary full-width" onClick={this.props.addStep} />
                        </li>
                    </ul>
                </UILIB.Column>}
            </UILIB.Row>
        );
    }
}

export default AutomationStep;
import React from "react";
import classnames from "classnames";
import { propToCssModule } from "~/Classes/styles";
import Icon from "../Icon";
import * as styles from "./index.module.scss";

export default function Badge({
  color = "grey",
  size = "",
  dot,
  icon,
  avatar,
  onDismiss,
  className,
  children,
  ...rest
}) {
  const classes = classnames(styles.root, className, {
    [styles[`rootColor${propToCssModule(color)}`]]: color,
    [styles[`rootSize${propToCssModule(size.toUpperCase())}`]]: size.toUpperCase(),
  });

  return (
    <div className={classes} {...rest}>
      {dot && <span className={styles.dot} />}

      {icon && <div className={styles.iconWrapper}>{icon}</div>}

      {avatar && <div className={styles.avatarWrapper}>{avatar}</div>}

      <div className={styles.children}>{children}</div>

      {onDismiss && (
        <button
          aria-label="Dismiss"
          className={styles.dismissButton}
          onClick={onDismiss}
        >
          <Icon name="cross" />
        </button>
      )}
    </div>
  );
}

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

export default class CopyGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupName: "",
            groupID: 0,
            dataSource: 1,
            dataSourceId: -1,
            dataSourceStatus: -1,
            groups: [],
            segments: [],
            loaded: false,
            processing: false,
            app: undefined
        };

        this.lookupGroup = this.lookupGroup.bind(this);
        this.changeSource = this.changeSource.bind(this);
        this.changeSourceId = this.changeSourceId.bind(this);
        this.changeSourceStatus = this.changeSourceStatus.bind(this);
        this.loadGroups = this.loadGroups.bind(this);
        this.loadSegments = this.loadSegments.bind(this);
        this.goCopy = this.goCopy.bind(this);
    }

    componentDidMount() {
        var isBlackList = true;
        if (this.props.accountMaster.accountMaster.blackListGroupId != this.props.match.params.groupID) {
            isBlackList = false;
        }

        axios.get('/accountMaster/application').then(response => {
            this.setState({
                app: response.data.Applications.find(a => a.appSettings.groupId == this.props.match.params.groupID && a.Application.options.importOnCreate)
            })
        })

        this.setState({ groupID: this.props.match.params.groupID, isBlackList: isBlackList }, () => { this.lookupGroup() });
    }

    lookupGroup() {
        var self = this;
        axios.get('/group/' + this.state.groupID)
            .then((res) => {
                self.setState({ groupName: res.data.groupName }, this.loadGroups);
            })
            .catch(function (error) {
                console.log(error);
                self.props.history.push('/cp/groups/');
            });
    }
    loadGroups() {
        var self = this;
        axios.get('/group').then(res => {
            var groups = res.data.Groups.map(tG => {
                return { label: tG.groupName, value: tG.id }
            });

            self.setState({ groups }, this.loadSegments)
        })
    }

    loadSegments() {
        var self = this;
        axios.get('/segment?count=false').then(res => {
            self.setState({
                loaded: true, segments: res.data.Segments.map(tG => {
                    return { label: tG.name, value: tG.id }
                })
            })
        })
    }

    changeSource(event) {
        this.setState({ dataSource: event.target.value, dataSourceId: -1, dataSourceStatus: -1 })
    }

    changeSourceId(event) {
        this.setState({ dataSourceId: event.target.value })
    }
    changeSourceStatus(event) {
        this.setState({ dataSourceStatus: event.target.value })
    }

    goCopy() {
        var promise;

        const body = {
            source: this.state.dataSourceStatus,
            destination: this.state.groupID,
            delete: false,
            all: true
        }
        if (this.state.app) {
            body.webhooksInbound = this.state.app.Application.options.importOnCreate
        }
        if (this.state.dataSource == 1) {
            promise = axios.post('/group/' + this.state.dataSourceId + '/subscriber/copy', body)
        }

        this.setState({ processing: true })

        promise.then(() => {
            this.props.history.push('/cp/groups/' + this.state.groupID)
        });
    }

    render() {

        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />
        if (this.state.processing) return <UILIB.Row>
            <UILIB.Column xs={12} sm={12} md={6} margin={0} className="col-md-offset-3">
                <UILIB.Paper>
                    <h4 className="mar-b25">{i18n.t('subscribers:add.fromGroup.copyingSubscribers')}</h4>
                    <UILIB.LoadingLine className="mar-b20"></UILIB.LoadingLine>
                    <p>{i18n.t('subscribers:add.fromGroup.copyingSubscribersDesc1')}</p>
                    <p className="mar-b0">{i18n.t('subscribers:add.fromGroup.copyingSubscribersDesc2')}</p>
                </UILIB.Paper>
            </UILIB.Column>
        </UILIB.Row>


        var groupText = i18n.t('subscribers:add.fromGroup.groupText');
        groupText = groupText.replace(/\[GROUPSEGMENT\]/g, this.state.dataSource == 1 ? i18n.t('group') : i18n.t('segment'))

        var groupSelectText = i18n.t('subscribers:add.fromGroup.groupSelectText');
        groupSelectText = groupSelectText.replace(/\[GROUPSEGMENT\]/g, this.state.dataSource == 1 ? i18n.t('group') : i18n.t('segment'))

        return <div>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={6} lg={6}>

                    <h4 className="mar-b25">{i18n.t('subscribers:add.fromGroup.selectGroup')}</h4>

                    {this.state.dataSource != -1 && <UILIB.Select outerClassName="mar-b25" label={groupText} value={this.state.dataSourceId} onChange={this.changeSourceId} placeholder={groupSelectText} data={this.state.dataSource == 1 ? this.state.groups : this.state.segments} />}

                    {(this.state.dataSource == 1 && this.state.dataSourceId != -1) && <UILIB.Select outerClassName="mar-b25" label={i18n.t('subscribers:add.fromGroup.importSubscribersWhoAre')} value={this.state.dataSourceStatus} onChange={this.changeSourceStatus} placeholder={i18n.t('subscribers:add.fromGroup.selectAStatus')} data={[{ label: i18n.t('Active'), value: 'Active' }, { label: i18n.t('In-Active'), value: 'In-Active' }, { label: i18n.t('Unsubscribed'), value: 'Unsubscribed' }]} />}

                    {
                        ((this.state.dataSource == 1 && this.state.dataSourceId != -1 && this.state.dataSourceStatus != -1) || (this.state.dataSource == 2 && this.state.dataSourceId != -1)) && <div>
                            {!!this.state.app && <p className='text-md text-heavy text-primary'>Copying into this Group will also add the Contacts to {this.state.app.Application.appName}</p>}
                            <UILIB.Button
                                className="button-primary"
                                text={i18n.t('subscribers:add.fromGroup.import')}
                                onClick={this.goCopy}
                                iconRight={<UILIB.Icons icon="arrowRight" />}
                            />

                        </div>
                    }
                </UILIB.Column >


                <UILIB.Column xs={12} sm={12} md={6} lg={6}>
                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={12} md={12} lg={12}>

                            {!this.state.isBlackList && <UILIB.Paper className="paper-primary">
                                <h4 className="mar-b25">{i18n.t('subscribers:add.fromGroup.infoHeader')}</h4>
                                <p className="mar-b30">{i18n.t('subscribers:add.fromGroup.infoText2')}</p>
                                <h4 className="mar-b25">{i18n.t('subscribers:add.single.infoHeader')}</h4>
                                <p className="mar-b30">{i18n.t('subscribers:add.single.infoHeader2')}</p>
                                <h4 className="mar-b25">{i18n.t('subscribers:add.single.infoSubHeader')}</h4>
                                <p>{i18n.t('subscribers:add.single.infoText')}</p>
                                <p className="mar-b0">{i18n.t('subscribers:add.single.infoText2')}</p>
                            </UILIB.Paper>
                            }
                            {this.state.isBlackList && <UILIB.Paper className="paper-primary">
                                <h4 className="mar-b25">
                                    {i18n.t('subscribers:add.fromGroup.addBlacklistHeader')}
                                </h4>
                                <p>{i18n.t('subscribers:add.fromGroup.addBlacklistDesc')}</p>
                            </UILIB.Paper>}

                        </UILIB.Column>
                    </UILIB.Row>

                </UILIB.Column>
            </UILIB.Row >
        </div >
    };
};

import React from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import axios from '../../../../../data/http/axios';

export default class EditEvents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            selectedEvent: {},
            saving: true,
            error: ""
        }
        this.getEvents = this.getEvents.bind(this);
        this.selectEvent = this.selectEvent.bind(this);
        this.updateEventValue = this.updateEventValue.bind(this);
        this.saveEvent = this.saveEvent.bind(this);
    }

    componentDidMount() {
        this.getEvents()
    }

    async getEvents() {
        let events = await axios.get(`/accountMaster/tracker/events`);

        this.setState({
            saving: false,
            events: events.data
        })
    }

    selectEvent(ev) {
        let selectedEvent = this.state.events.find(e => e.id == ev.target.value)
        if (selectedEvent) {
            this.setState({ selectedEvent });
        }
    }

    updateEventValue(valName, newVal) {
        let selectedEvent = this.state.selectedEvent;
        let detectedValue = selectedEvent.detectedValues.find(e => e.name = valName)
        detectedValue.value = newVal;
        this.setState({ selectedEvent })

    }

    async saveEvent() {
        try {
            this.setState({ saving: true })
            await axios.post('/accountMaster/tracker/' + this.props.tracker.id + '/path/' + this.props.path.id, {
                eventId: this.state.selectedEvent.id,
                detectedValues: this.state.selectedEvent.detectedValues
            })
            this.props.close()
        }
        catch (err) {
            let error = "Error saving event"
            if (err.data && err.data.error) error = err.data.error;
            this.setState({ error, saving: false })
        }
    }
    render() {
        if (this.state.saving) {
            return <UILIB.LoadingIcons iconType="2" />
        }
        return (
            <div>
                <h4 className="mar-b25">Add an Event</h4>
                <p className="mar-b25">Select an event to trigger every time a visitor visits this page...</p>

                <UILIB.Select error={this.state.error} outerClassName="mar-b25" label="Event to trigger" placeholder="Select an Event" value={this.state.selectedEvent.id} data={this.state.events.map(e => { return { label: e.name, value: e.id } })} onChange={this.selectEvent} />
                {this.state.selectedEvent && this.state.selectedEvent.name && <>

                    {(this.state.selectedEvent.detectedValues && this.state.selectedEvent.detectedValues.length) && <div className="mar-b25">
                        <div className="mar-b15">You can also set a default value for the event properties here...</div>

                        {this.state.selectedEvent.detectedValues.map(val => {
                            return <div className="mar-b15">
                                <UILIB.TextInput label={val.name} name={val.name} value={val.value || ""} onChange={(ev) => { this.updateEventValue(val.name, ev.target.value) }} />
                            </div>
                        })}
                    </div>}

                    <UILIB.Button text="Save Event" iconLeft={<UILIB.Icons icon="tick" />} onClick={this.saveEvent} />
                </>
                }
            </div>
        );
    }
}

import React from 'react'

export default class WhiteBoxHeader extends React.Component {

    render() {

        return <div className="uiWhiteBoxHeader" style={this.props.style}>{this.props.children}</div>
    }


}

import React from "react";
import classnames from "classnames";
import i18n from "~/i18n";
import UILIB from "~/Common-Objects/Lib";
import FormatNumberFuncs from "../../../../../../../Classes/numberFormatFunctions";
export default function PollResultsQuestion({
  displayIndex,
  question,
  noOfResponses,
  onViewResponsesClick,
  className,
  children,
  ...rest
}) {
  const { answers, question: q, label } = question;
  let average = null;

  if (question.value === "satisfaction" || question.value === "promoter") {
    const answersTotal = answers.value.reduce((acc, current) => {
      return acc + current.answers * current.label;
    }, 0);

    average = (answersTotal / noOfResponses).toFixed(1);
  }

  const classes = classnames("campaign-poll-results-question", className);

  return (
    <div className={classes} {...rest}>
      <div className="campaign-poll-results-question__header">
        <div>
          <div className="campaign-poll-results-question__chips">
            <UILIB.SquareChip className="square-chip-secondary">
              {label}
            </UILIB.SquareChip>

            {average && (
              <UILIB.SquareChip>
                {i18n.t("campaigns:polls.average")} {average}
              </UILIB.SquareChip>
            )}
          </div>

          <h3 className="h5">
            Q{displayIndex} – {q.value}
          </h3>
        </div>

        <UILIB.Button className="button-sml" onClick={onViewResponsesClick}>
          {i18n.t("campaigns:polls.viewResponses")}
        </UILIB.Button>
      </div>

      <div className="campaign-poll-results-question__bars-scroller">
        <ul className="campaign-poll-results-question__bars faux-list">
          {answers.value.map((answer) => {
            let percentage = (answer.answers / noOfResponses) * 100 || 0;
            percentage = FormatNumberFuncs.formatNumber(percentage, 2)
            return (
              <li key={answer.label}>
                <UILIB.SimpleBar
                  percentage={percentage}
                  labelTop={`${answer.answers} – ${percentage}%`}
                  labelBottom={answer.label}
                />
              </li>
            );
          })}
        </ul>
      </div>

      {children}
    </div>
  );
}

import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import Action from './action'

class PipelineHooks extends Component {
    constructor(props) {
        super(props);
        this.state = { fields: [] }
        this.toggle = this.toggle.bind(this)
        this.addAction = this.addAction.bind(this)
        this.update = this.update.bind(this)
        this.delete = this.delete.bind(this)
        this.getAvailableFields = this.getAvailableFields.bind(this)
    }

    componentDidMount() {
        if (!this.props.appSettings) this.props.appSettings = {};
        if (!this.props.appSettings.webhooks) this.props.appSettings.webhooks = {
            send: false,
            open: false,
            click: false
        }

        if (!this.props.appSettings.actions) this.props.appSettings.actions = {}

        if (!this.props.appSettings.actions.open) this.props.appSettings.actions.open = []
        if (!this.props.appSettings.actions.click) this.props.appSettings.actions.click = []
        if (!this.props.appSettings.actions.unsubscribe) this.props.appSettings.actions.unsubscribe = []
        if (!this.props.appSettings.actions.junk) this.props.appSettings.actions.junk = []
        if (!this.props.appSettings.actions.bounce) this.props.appSettings.actions.bounce = []

        this.getAvailableFields()
    }

    getAvailableFields() {
        axios.get('/application/pipedrive/availablefields').then(response => {
            var notAllowedFields = ["phone", "email", "add_time", "update_time", "owner_id", "open_deals_count", "visible_to", "next_activity_date", "last_activity_date", "won_deals_count", "lost_deals_count", "closed_deals_count", "activities_count", "done_activities_count", "undone_activities_count", "email_messages_count", "last_incoming_mail_time", "last_outgoing_mail_time"]
            this.setState({
                fields: response.data.filter(f => f.field_type !== 'org' && f.field_type !== 'people' && f.field_type !== 'address' && f.field_type !== 'timerange' && f.field_type !== 'daterange' && f.name.indexOf('organisation ') !== 0 && f.name !== 'id' && notAllowedFields.indexOf(f.key) === -1),
                ready: true
            })
        })
    }

    addAction(action) {
        this.props.appSettings.actions[action].push({})
        this.setState({})
    }

    update(action, index, ev) {
        var actions = this.props.appSettings.actions[action]
        var obj = actions[index]
        if (obj) {
            obj[ev.currentTarget.name] = ev.currentTarget.value
        }
        this.setState({})
    }

    delete(action, index) {
        var actions = this.props.appSettings.actions[action]
        var obj = actions[index]
        if (obj) {
            var mainIndex = this.props.appSettings.actions[action].findIndex(a => a === obj)
            if (mainIndex > -1) {
                this.props.appSettings.actions[action].splice(mainIndex, 1)
            }
        }
        this.setState({})
    }

    toggle(hook) {
        this.props.appSettings.webhooks[hook] = !this.props.appSettings.webhooks[hook]
        this.setState({})
    }

    render() {
        if (!this.state.ready) return <UILIB.LoadingIcons iconType="2" />
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} sm={12} md={6}>
                        <UILIB.WarningHolder style={{ textAlign: "left" }}>
                            <Action action="open" app={this.props.app} fields={this.state.fields} addAction={this.addAction} actions={this.props.appSettings.actions.open} update={this.update} delete={this.delete} />
                        </UILIB.WarningHolder>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={12} md={6}>
                        <UILIB.WarningHolder style={{ textAlign: "left" }}>
                            <Action action="click" app={this.props.app} fields={this.state.fields} addAction={this.addAction} actions={this.props.appSettings.actions.click} update={this.update} delete={this.delete} />
                        </UILIB.WarningHolder>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={12} md={6}>
                        <UILIB.WarningHolder style={{ textAlign: "left" }}>
                            <Action action="unsubscribe" app={this.props.app} fields={this.state.fields} addAction={this.addAction} actions={this.props.appSettings.actions.unsubscribe} update={this.update} delete={this.delete} />
                        </UILIB.WarningHolder>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={12} md={6}>
                        <UILIB.WarningHolder style={{ textAlign: "left" }}>
                            <Action action="junk" app={this.props.app} fields={this.state.fields} addAction={this.addAction} actions={this.props.appSettings.actions.junk} update={this.update} delete={this.delete} />
                        </UILIB.WarningHolder>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={12} md={6}>
                        <UILIB.WarningHolder style={{ textAlign: "left" }}>
                            <Action action="bounce" app={this.props.app} fields={this.state.fields} addAction={this.addAction} actions={this.props.appSettings.actions.bounce} update={this.update} delete={this.delete} />
                        </UILIB.WarningHolder>
                    </UILIB.Column>
                </UILIB.Row>
            </div >
        );
    }
}

export default PipelineHooks;
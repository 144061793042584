import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import moment from 'moment';

class Action extends Component {
    constructor(props) {
        super(props);
        this.getAction = this.getAction.bind(this)
        this.setValue = this.setValue.bind(this)
    }

    getAction(action) {
        var bools = []
        var selects = ['enum', 'set']
        var numbers = ['double', 'monetary', 'int']
        var dates = ['date']
        var times = ['time']
        var field = this.props.fields.find(f => f.key == action.field)
        var options = ['campaignName', 'subject', 'fromName', 'fromAddress', 'replyName', 'replyAddress', 'link']
        var friendlyOptions = [
            { label: 'The campaign name', value: 'campaignName', system: true },
            { label: 'The email subject', value: 'subject', system: true },
            { label: 'The email from name', value: 'fromName', system: true },
            { label: 'The email from address', value: 'fromAddress', system: true },
            { label: 'The email reply name', value: 'replyName', system: true },
            { label: 'The email reply address', value: 'replyAddress', system: true },
            { label: 'The link they clicked', value: 'link', system: true },
            { label: 'A specific value', value: '', system: false }
        ]

        if (!field) return null
        if (bools.indexOf(field.field_type) > -1) {
            return <UILIB.Select data={[{
                label: 'Turn On',
                value: 'true'
            }, {
                label: 'Turn Off',
                value: 'false'
            }]} value={action.action} onChange={ev => this.setValue(action, ev.target.value)} />
        }

        if (selects.indexOf(field.field_type) > -1) {
            return <UILIB.Select data={field.options.map(o => {
                return {
                    value: o.id,
                    label: o.label
                }
            })} value={action.action} onChange={ev => this.setValue(action, ev.target.value)} />
        }

        var selectValue = ''
        var selectedSystemOption = friendlyOptions.find(opt => opt.value == action.value && opt.system);
        if (numbers.indexOf(field.field_type) > -1) {
            if (typeof action.value !== 'number') {
                selectValue = action.value
            }
            return <div className="row">
                <div className="col-xs-6">
                    <UILIB.Select data={['increment', 'decrement', { value: '', label: 'specific value' }]} value={selectValue} onChange={ev => {
                        var value = ev.target.value
                        if (value == 'increment' || value == 'decrement') {
                            this.setValue(action, value)
                        } else {
                            this.setValue(action, 0)
                        }
                    }} />
                </div>
                {(action.value !== 'increment' && action.value !== 'decrement' && !selectedSystemOption) && <div className="col-xs-6">
                    <UILIB.TextInput className="mar-l10" type="number" value={action.value} onChange={ev => this.setValue(action, ev.target.value)} />
                </div>
                }

            </div >
        }

        if (dates.indexOf(field.field_type) > -1) {
            if (!moment(action.value).isValid()) {
                selectValue = action.value
            }
            return <div className="row">
                <div className="col-xs-6">
                    <UILIB.Select outerstyle={{ minWidth: '200px' }} data={[{ label: 'Set to Open date', value: 'date' }, { label: 'Set a specific value', value: '' }]} value={selectValue} onChange={ev => {
                        var value = ev.target.value
                        if (value === 'date') {
                            this.setValue(action, 'date')
                        } else {
                            this.setValue(action, moment())
                        }
                    }} />
                </div>

                {action.value !== 'date' &&
                    <div className="col-xs-6">
                        <UILIB.TextInput className="mar-l10" type="date" value={action.value} onChange={(ev, date) => this.setValue(action, date)} />
                    </div>}
            </div>
        }

        if (times.indexOf(field.field_type) > -1) {
            if (!moment(action.value, 'HH:mm').isValid()) {
                selectValue = action.value
            }
            return <div className="row">
                <div className="col-xs-6">
                    <UILIB.Select outerstyle={{ minWidth: '200px' }} placeholder="Select" data={[{ label: 'Set to Open time', value: 'time' }, { label: 'Set a specific value', value: '' }]} value={selectValue} onChange={ev => {
                        var value = ev.target.value
                        if (value === 'time') {
                            this.setValue(action, 'time')
                        } else {
                            this.setValue(action, moment().format('HH:mm'))
                        }
                    }} />
                </div>

                {action.value !== 'time' &&
                    <div className="col-xs-6">
                        <UILIB.TextInput className="mar-l10" type="time" pattern="[0-9]{2}:[0-9]{2}" value={action.value} onChange={(ev) => this.setValue(action, ev.target.value)} />
                    </div>}
            </div>
        }


        if (this.props.action !== 'click') {
            // remove the link option if its not a click
            friendlyOptions.splice(6, 1)
        }

        if (options.indexOf(action.value) > -1) {
            selectValue = action.value
        }

        return <div className="row">
            <div className="col-xs-6">
                <UILIB.Select outerstyle={{ minWidth: '200px' }} data={friendlyOptions} value={selectValue} onChange={ev => {
                    var value = ev.target.value
                    if (value === '') {
                        this.setValue(action, '')
                    } else {
                        this.setValue(action, value)
                    }
                }} />
            </div>

            {!selectedSystemOption && <div className="col-xs-6">
                <UILIB.TextInput className="mar-l10" type="text" value={action.value} onChange={(ev) => this.setValue(action, ev.target.value)} />
            </div>}
        </div>
    }

    setValue(action, value) {
        action.value = value
        this.setState({})
    }

    render() {
        var texts = {
            open: `When a person opens an email...`,
            click: `When a person clicks an email...`,
            unsubscribe: `When a person unsubscribes...`,
            junk: `When a person marks an email as junk...`,
            bounce: `When an email bounces...`
        }

        return (
            <div >
                <div className="text-heavy mar-b10">{texts[this.props.action]}</div>
                {!!this.props.actions.length && this.props.actions.map((action, index) => {
                    return <div key={index} className="quickFlex">
                        <div className="form-group mar-r15" style={{ flex: "1" }}>
                            <label htmlFor="">Set</label>
                            <UILIB.Select placeholder="Select Field" data={this.props.fields.map(f => {
                                return {
                                    label: f.name,
                                    value: f.key
                                }
                            })} value={action.field} name="field" onChange={ev => this.props.update(this.props.action, index, ev)} />
                        </div>
                        <div style={{ flex: "1" }} className="mar-r15 form-group">
                            <label htmlFor="">To</label>
                            {!action.field && <div style={{ width: "100%", borderBottom: "1px solid #DDDDDD", height: "30px" }} />}
                            {action.field && this.getAction(action)}
                        </div>
                        <div className="form-group" style={{ flex: "0 0 100px", textAlign: "right", alignSelf: "flex-end" }}>
                            <UILIB.Button text="delete" className="grey" onClick={() => this.props.delete(this.props.action, index)} />
                        </div>
                    </div>
                })}
                <UILIB.Button text={!!this.props.actions.length ? "Add Another Action" : "Add an Action"} className="button-sml outline secondary min-width" onClick={() => this.props.addAction(this.props.action)} />
            </div>
        );
    }
}

export default Action;
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';

class AddService extends Component {
    constructor(props) {
        super(props)

        this.state = {
            services: [],
            service: undefined,
            category: 'All',
            search: ''
        }
        this.getServices = this.getServices.bind(this)
    }

    componentDidMount() {
        this.getServices();
    }

    getServices() {
        axios.get('/application').then(response => {
            this.setState({
                services: response.data.Applications.sort((a, b) => {
                    // sort by the "featured" boolean property
                    if (a.featured && !b.featured) return -1;
                    if (!a.featured && b.featured) return 1;
                    return 0;
                })
            })
        })
    }

    render() {
        var types = ['All']
        this.state.services.forEach(service => {
            if (service.category && types.indexOf(service.category) === -1) types.push(service.category)
        })

        // move the "Other" type to the end
        if (types.includes('Other')) {
            types = types.filter(t => t !== 'Other')
            types.push('Other')
        }

        var services = this.state.services;
        if (this.state.category !== 'All') services = services.filter(s => s.category === this.state.category)

        if (this.state.search?.length > 2) {
            services = services.filter(s => s.appName.toLowerCase().includes(this.state.search.toLowerCase()))
        }

        return (
            <div>

                <h4 className="mar-b25">{i18n.t('company:integrations.add.header')}</h4>
                <div className="row mar-b25">
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-10">
                        {types.map((type, index) =>
                            <UILIB.ButtonSimple
                                key={"integration_" + index}
                                className="button-simple-fullsize mar-l5"
                                selected={type === this.state.category}
                                onClick={() => { this.setState({ category: type }) }}>
                                {type}
                            </UILIB.ButtonSimple>
                        )}
                    </div>
                    <div className="hide-xs hide-sm col-md-4 col-lg-2">
                        <UILIB.TextInput placeholder="Search by name..." value={this.state.search} onChange={e => this.setState({ search: e.target.value })} />
                    </div>
                </div>


                <UILIB.Row>
                    {services.map((service, index) => {
                        let isInstalled = false;
                        let allowMultiple = false;
                        if (service.AccountApplications.length > 0) {
                            isInstalled = true;
                            if (service.AccountApplications[0].UniqueKey) allowMultiple = true;
                        }
                        return <UILIB.Column xs={12} sm={6} md={6} lg={4} key={service.id}>
                            <App app={service} isInstalled={isInstalled} allowMultiple={allowMultiple} onClick={() => this.props.finish(service)} />
                        </UILIB.Column>
                    })}
                </UILIB.Row>

            </div >
        );
    }
}

export default AddService;

function App({ app, isInstalled, allowMultiple, onClick = () => { } }) {
    return (
        <UILIB.Paper style={{ position: 'relative', padding: 24, marginBottom: 24, height: 'calc(100% - 24px)' }} >
            <div className="avatar" style={{ backgroundImage: "url(" + app.appLogoUri + ")", width: 48, height: 48, backgroundSize: "48px 48px", marginBottom: 16 }} />
            <div className="text-heavy" style={{ marginBottom: 4 }}>{app.appName}</div>
            <div className="text-grey">{app.appDescription}</div>

            <div style={{ position: 'absolute', top: 24, right: 24 }}>
                {!isInstalled && <UILIB.Button text={i18n.t('company:integrations.add.viewInstall')} className="button-primary button-sml" onClick={onClick} />}
                {isInstalled && !allowMultiple && <UILIB.Button text={i18n.t('company:integrations.add.installed')} className="button-sml" />}
                {allowMultiple && <UILIB.Button text={"Add Another Account"} className="button-primary button-sml" onClick={onClick} />}
            </div>
        </UILIB.Paper>
    )
}
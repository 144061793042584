import React from 'react'

export default class SubNavChip extends React.Component {
    render() {
        var isSelected = "";
        if (this.props.selected == "1") {
            isSelected = "subNavSelected";
        }
        if (this.props.className) {
            isSelected += " " + this.props.className + " ";
        }
        return <div className={"subNavChip " + isSelected} onClick={this.props.onClick} id={this.props.subNavId} name={this.props.subNavName} style={this.props.style}>
            {this.props.icon && <span className={"icon " + this.props.icon + " mar-r5"}></span>}
            {this.props.label}


        </div >
    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';
import ReactTooltip from 'react-tooltip'
import MaskedInput from 'react-text-mask'
import moment from 'moment'
import NewUserForm from '~/pages/cp/includes/bb_topNav/newUserForm/newUserForm';
import * as siteActions from '~/data/actions/siteActions'

@connect((store) => {
    return { user: store.user, account: store.accountMaster }
})
class AbSend extends Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
            errors: [],
            timeblocks: [],
            advOptions: {
                showLimitSendingTimes: this.props.campaign?.options?.betweenStart && this.props.campaign?.options?.betweenEnd ? true : false,
                showResendAfter: false
            },
            sliderWidthPct: 50,
            totalPerTest: -1,
            totalForFinalSend: 0,
            showAdvanced: false,
            pageLoaded: false,
            tierPermissions: {},
            tierErrored: false
        }

        this.updateValue = this.updateValue.bind(this)
        this.changeAdvOptions = this.changeAdvOptions.bind(this)
        this.save = this.save.bind(this)
        this.send = this.send.bind(this)
        this.dragSlider = this.dragSlider.bind(this);
        this.calcTotals = this.calcTotals.bind(this);
        this.showHideAdvanced = this.showHideAdvanced.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setStartTime = this.setStartTime.bind(this)

    }

    componentDidMount() {

        var timeblocks = []
        var time = moment('00:00', 'HH:mm')
        for (var i = 0; i < 48; i++) {
            timeblocks.push({ label: time.format('HH:mm'), value: time.format() })
            time.add(30, 'minutes')
        }

        this.setState({
            timeblocks,
            sliderWidthPct: this.props.campaign.options.splitToSend,
            pageLoaded: true
        })

        var slider = document.getElementById("slider");
        if (slider) {
            this.dragSlider(slider);
        }
    }


    componentDidUpdate() {
        var slider = document.getElementById("slider");
        if (slider) {
            this.dragSlider(slider);
        }
    }
    updateValue(ev) {
        this.props.campaign.options[ev.currentTarget.name] = ev.currentTarget.value
        this.props.updateCampaign()
        this.setState({})
    }

    changeAdvOptions(event) {
        var advOptions = this.state.advOptions;
        if (event.target.name == "checkedSendBetween") {
            advOptions.showLimitSendingTimes = event.target.checked
            if (event.target.checked == false) {
                //clear start/end times
                this.props.campaign.options.betweenStart = undefined
                this.props.campaign.options.betweenEnd = undefined
                this.props.updateCampaign();
            }
        }
        this.setState({ advOptions })
    }



    send() {
        var hasErrors = false;
        if (this.props.testsInvalid && this.props.testsInvalid.length) {
            var errors = this.props.testsInvalid.find(t => t.failed == true)
            if (errors && errors.failed) {
                this.props.history.push(`/cp/campaigns/add/ab/${this.props.campaign.id}/2`)
                return;
            }
        }
        this.props.saveCampaign().then(resp => {
            return axios.put('/campaign/' + this.props.campaign.id + '/start')
        }).then(() => {
            this.props.history.push('/cp/campaigns/view/' + this.props.campaign.id)
        }).catch(err => {
            if (err && err.data && err.data.error && err.data.error.tierName) {
                this.setState({ tierPermissions: err.data.error, tierErrored: true })
            }
            else {
                alert("Error starting campaign")
            }
        })
    }

    save() {
        this.props.saveCampaign().then(() => {
            this.props.history.push('/cp/campaigns')
        })
    }

    // schedule
    // configure how any to send per test (as a percentage and total)
    // select how to score them (most opens or most clicks)

    dragSlider(elmnt) {
        if (this.state.totalPerTest == -1) this.calcTotals();
        var self = this;
        var posX, posY, mouseX, mouseY, parentX, parentWidth, parentMinWidth, parentMaxWidth, sliderWidth;
        var maxPct = 75;
        var minPct = 1;
        elmnt.onmousedown = dragMouseDown;

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            mouseX = e.clientX;
            mouseY = e.clientY;
            var parent = document.getElementById("sliderOuter");
            parentX = parent.clientLeft;

            sliderWidth = elmnt.clientWidth;
            parentMaxWidth = ((parent.clientWidth / 100) * maxPct) - sliderWidth;
            parentMinWidth = ((parent.clientWidth / 100) * minPct)
            parentWidth = parent.clientWidth;
            document.onmouseup = closeDragElement;
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            posX = mouseX - e.clientX;
            mouseX = e.clientX;
            mouseY = e.clientY;

            var finalX = elmnt.offsetLeft - posX;

            if (finalX < (parentX + parentMinWidth)) finalX = (parentX + parentMinWidth);
            if (finalX > (parentX + parentMaxWidth)) finalX = parentMaxWidth;

            elmnt.style.left = (finalX) + "px";

            var pct = (finalX / parentWidth) * 100;
            self.props.campaign.options.splitToSend = pct;

            self.setState({ sliderWidthPct: pct }, self.calcTotals)
            if (self.timer) clearTimeout(self.timer)
            self.timer = setTimeout(() => {
                self.props.updateCampaign()
                self.props.saveCampaign()
            }, 1000);
        }


        function closeDragElement() {
            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    calcTotals() {
        return
        var pct = this.state.sliderWidthPct;
        var totalToSendTestTo = (this.props.subscriberCount / 100) * pct;
        var totalPerTest = Math.floor(totalToSendTestTo / this.props.campaigns.length);
        if (totalPerTest < 1) totalPerTest = 1;
        var totalForFinalSend = this.props.subscriberCount - (totalPerTest * this.props.campaigns.length);

        this.setState({ totalForFinalSend, totalPerTest })
    }

    showHideAdvanced() {
        var advanced = this.state.showHideAdvanced;
        if (advanced) {
            advanced = false;
        }
        else {
            advanced = true;
        }
        this.setState({ showHideAdvanced: advanced })
    }

    setStartDate(ev, newDate) {
        let date = this.props.campaign.startDate
        let time = moment(date)
        newDate.hour(time.hour()).minute(time.minute())
        this.props.campaign.startDate = newDate;
        this.setState({})
    }

    setStartTime(ev) {
        let value = ev.target.value
        let date = moment(this.props.campaign.startDate)
        let time = moment(value, 'HH:mm')
        date.hour(time.hour()).minute(time.minute())
        this.props.campaign.startDate = date;
        this.setState({})
    }

    activate() {
        const drawer = <NewUserForm from="campaign" />
        this.props.dispatch(siteActions.alterSiteDrawer(true, true, 'right', drawer, true, '100vw'))
    }

    render() {

        if (!this.props.subscriberCount || !this.state.pageLoaded) {
            return <UILIB.LoadingIcons iconType="2" />
        }

        var startDate = moment(this.props.campaign.startDate)
        var currentHour = String(startDate.hour());
        var currentMinute = String(startDate.minute());
        if (currentHour.length === 1) currentHour = "0" + currentHour;
        if (currentMinute.length === 1) currentMinute = "0" + currentMinute;
        var currentTime = currentHour + ":" + currentMinute;


        var scoreOptions = [{
            label: i18n.t('campaigns:abSplitTest.send.uniqueOpens'),
            value: 'Unique Opens'
        }, {
            label: i18n.t('campaigns:abSplitTest.send.totalOpens'),
            value: 'Total Opens'
        }, {
            label: i18n.t('campaigns:abSplitTest.send.uniqueClicks'),
            value: 'Unique Clicks'
        }, {
            label: i18n.t('campaigns:abSplitTest.send.totalClicks'),
            value: 'Total Clicks'
        }]

        var unitOptions = [{
            label: i18n.t('campaigns:abSplitTest.send.minutes'),
            value: 'minutes'
        }, {
            label: i18n.t('campaigns:abSplitTest.send.hours'),
            value: 'hours'
        }, {
            label: i18n.t('campaigns:abSplitTest.send.days'),
            value: 'days'
        }]

        var finished = true;
        var splitToSend = parseInt(this.props.campaign.options.splitToSend)
        if (!splitToSend) finished = false
        if (!this.props.campaign.options.scoreReason) finished = false

        var pct = this.state.sliderWidthPct;
        var totalToSendTestTo = (this.props.subscriberCount / 100) * pct;
        var totalPerTest = Math.floor(totalToSendTestTo / this.props.campaigns.length);
        if (totalPerTest < 1) totalPerTest = 1;
        var totalForFinalSend = this.props.subscriberCount - (totalPerTest * this.props.campaigns.length);


        var introText = i18n.t('campaigns:abSplitTest.send.intro').replace(/\[campaignCount\]/g, this.props.campaigns.length)
        var subText = i18n.t('campaigns:abSplitTest.send.subText').replace(/\[totalPerTest\]/g, totalPerTest)
        subText = subText.replace(/\[totalForFinalSend\]/g, totalForFinalSend);
        var testTotalText = i18n.t('campaigns:abSplitTest.send.testTotalText').replace(/\[totalPerTest\]/g, totalPerTest)

        var finalExplainer = i18n.t('campaigns:abSplitTest.send.finalExplainer').replace(/\[scoreThreshold\]/g, this.props.campaign.options.scoreThreshold ? this.props.campaign.options.scoreThreshold : 0)
        finalExplainer = finalExplainer.replace(/\[scoreReason\]/g, this.props.campaign.options.scoreReason)
        return (
            <div>
                <UILIB.Row className="mar-b30">
                    <UILIB.Column xs={12}>

                        <UILIB.Paper>

                            <h4 className="mar-b25">{introText}</h4>
                            <div className="mar-b25">{subText}</div>

                            <div id="sliderOuter" className="slider">
                                <div className="sliderInner" style={{ width: this.state.sliderWidthPct + "%" }}>
                                    {this.props.campaigns.map((campaign, index) => {
                                        var tStyle = {
                                            width: (100 / this.props.campaigns.length) + "%"
                                        }
                                        if (index == this.props.campaigns.length - 1) {
                                            tStyle.borderRight = "none";
                                        }
                                        return <div className="sliderCampaign" style={tStyle}>
                                            {this.state.sliderWidthPct > 15 && <div>
                                                <div className="sliderTitle">{i18n.t('campaigns:abSplitTest.send.test') + " " + (index + 1)}</div>
                                                <div className="sliderStat">{totalPerTest}</div>
                                            </div>}
                                        </div>
                                    })}
                                    <div className="sliderHandle" id="slider" >
                                        <UILIB.Icons icon="dotsVertical" className="sliderIcon" color="white" />

                                    </div>

                                </div>

                                <div className="sliderFinal" id="sliderTotal" style={{ width: 100 - this.state.sliderWidthPct + "%" }}>
                                    <div className="sliderTitle">{i18n.t('campaigns:abSplitTest.send.finalSend')}</div>
                                    <div className="sliderStat">{totalForFinalSend}</div>

                                </div>
                            </div>

                            <div className="quickFlex" style={{ justifyContent: "space-between" }}>
                                <div className="text-xsml mar-b0 text-right">{i18n.t('campaigns:abSplitTest.send.dragToAdjust')}</div>
                                {this.state.sliderWidthPct <= 15 &&
                                    <div className="text-xsml mar-b0">{testTotalText}</div>

                                }

                            </div>
                        </UILIB.Paper>

                        <UILIB.Paper>
                            <UILIB.Row>
                                <UILIB.Column xs={12} className="mar-b25">
                                    <h4>{i18n.t('campaigns:abSplitTest.send.wait')}...</h4>
                                </UILIB.Column>
                                <UILIB.Column xs={12} md={6} className="mar-b25">
                                    <UILIB.TextInput type="number" onChange={this.updateValue} name="splitDelayValue" value={this.props.campaign.options.splitDelayValue} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} md={6} className="form-group mar-b25">
                                    <UILIB.Select data={unitOptions} onChange={this.updateValue} name="splitDelayUnit" value={this.props.campaign.options.splitDelayUnit} />
                                </UILIB.Column>
                            </UILIB.Row>
                        </UILIB.Paper>

                        <UILIB.Paper>

                            <h4 className="mar-b25">{i18n.t('campaigns:abSplitTest.send.finalIntro')}</h4>
                            <div className="quickFlex mar-b25">
                                <div className="mar-r10"> {i18n.t('campaigns:abSplitTest.send.finalSubText')}</div>
                                <UILIB.Select data={scoreOptions} placeholder={i18n.t('campaigns:abSplitTest.send.selectScoreType')} onChange={this.updateValue} name="scoreReason" value={this.props.campaign.options.scoreReason} />
                            </div>

                            {this.props.campaign.options.scoreReason && <UILIB.Button
                                text={i18n.t('campaigns:standard.step1.advanced')}
                                onClick={this.showHideAdvanced}
                                iconRight={<UILIB.Icons icon={this.state.showHideAdvanced ? "arrowDown" : "arrowRight"} />}
                            />}


                            {this.state.showHideAdvanced && this.props.campaign.options.scoreReason &&
                                <UILIB.Paper className="mar-t25">
                                    <UILIB.TextInput explainer={finalExplainer} label={i18n.t('campaigns:abSplitTest.send.threshold') + " (%)"} type="number" onChange={this.updateValue} name="scoreThreshold" value={this.props.campaign.options.scoreThreshold ? this.props.campaign.options.scoreThreshold : 0} />
                                </UILIB.Paper>
                            }

                        </UILIB.Paper>


                        <UILIB.Paper>
                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <h4>{i18n.t('campaigns:standard.step3.scheduleTitle')}</h4>
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <div className="quickFlex" style={{ justifyContent: "flex-end", alignContent: "center" }}>
                                        <div className="mar-r5">{this.props.user.userData.timeZone}</div>
                                        <span data-tip={i18n.t('campaigns:standard.step3.startTimeHelper')} data-for="timezone"><ReactTooltip id="timezone" effect='solid' /><UILIB.Help title="help" width="12px" /></span>
                                    </div>
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} md={6} className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('campaigns:standard.step3.startDate')} type="date" name="startDate" onChange={this.setStartDate} disallowPast={true} value={this.props.campaign.startDate} placeholder={i18n.t('campaigns:standard.step3.startDatePlaceholder')} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} md={6} className="mar-b25">
                                    <div className="txtInput_label">{i18n.t('campaigns:standard.step3.startTime')}</div>
                                    <MaskedInput className="txtInput" mask={[/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/]} value={currentTime} placeholder="hh:mm" onChange={this.setStartTime} showMask={true} guide={false} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} md={6} margin={0}>

                                    <UILIB.CheckBox
                                        checked={this.state.advOptions.showLimitSendingTimes}
                                        name="checkedSendBetween"
                                        onChange={this.changeAdvOptions}
                                    >
                                        <span className="txtInput_label">{i18n.t('campaigns:standard.step3.limitSendingTimes')}</span>
                                    </UILIB.CheckBox>

                                    {this.state.advOptions.showLimitSendingTimes &&
                                        <div className="mar-t15">
                                            <div className="txtInput_label">{i18n.t('campaigns:standard.step3.sendingTimesDescription')}</div>
                                            <div className="quickFlex">
                                                <UILIB.Select outerstyle={{ flexGrow: 1, marginRight: "10px" }} data={this.state.timeblocks} value={this.props.campaign.options.betweenStart} name="betweenStart" onChange={this.updateValue}></UILIB.Select>
                                                <UILIB.Select outerstyle={{ flexGrow: 1 }} data={this.state.timeblocks} value={this.props.campaign.options.betweenEnd} name="betweenEnd" onChange={this.updateValue}></UILIB.Select>
                                            </div>
                                        </div>}
                                </UILIB.Column>
                            </UILIB.Row>
                        </UILIB.Paper>
                    </UILIB.Column >
                </UILIB.Row >


                {(this.state.tierErrored) && <UILIB.TierLimitHolder type="AB Split Tests" err={this.state.tierPermissions.err} allowedNumber={this.state.tierPermissions.allowedNumber} tierName={this.state.tierPermissions.tierName} />}

                <div className="quickFlex" style={{ justifyContent: "space-between" }}>
                    <UILIB.Button onClick={this.save}>{i18n.t('campaigns:standard.saveExit')}</UILIB.Button>
                    <div className='quickFlex'>
                        {!this.props.account.accountMaster.doneNewUserQuestions && <div className='text-orange text-heavy mar-r10'>You account must be approved before you can send emails</div>}
                        {!this.props.account.accountMaster.doneNewUserQuestions && <UILIB.Button
                            className={"button-orange"}
                            onClick={this.activate.bind(this)}>
                            Request Approval
                        </UILIB.Button>}
                        {this.props.account.accountMaster.doneNewUserQuestions && <UILIB.Button
                            className={"button-primary"}
                            onClick={this.send}
                            disabled={!finished || !this.props.account.accountMaster.approved}>
                            {i18n.t('campaigns:standard.step3.send')}
                        </UILIB.Button>}
                    </div>
                </div>

            </div >
        );
    }
}

export default AbSend;
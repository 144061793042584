import React from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import DbConn from '~/Classes/siteVars.js'
import i18n from '~/i18n'
import LeftPanel from './loginAttractMode.jsx';
import { I18n } from 'react-i18next';

@connect((store) => {
  return {
    siteMaster: store.siteMaster
  }
})
export default class ReminderTemplate extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = { emailAddress: '', masterErrorMsg: '' };
    this.handleChange = this.handleChange.bind(this);
    this.sendReminder = this.sendReminder.bind(this);
    this.redirectToPage = this.redirectToPage.bind(this);
  }

  redirectToPage(pageURL) {
    this.props.history.push('/' + pageURL);
  }

  handleChange(event) {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    });
  }

  sendReminder() {
    var self = this;
    self.setState({ masterErrorMsg: i18n.t('login:reminder.alertLooking') });
    var baseUrl = DbConn.getConnString()
    axios.post(baseUrl + '/forgotpassword',
      {
        emailAddress: this.state.emailAddress,
        siteMasterId: this.props.siteMaster.siteId
      })
      .then(function (response) {
        self.setState({ masterErrorMsg: i18n.t('login:login.checkYourEmail') });

      }).catch(error => {
        self.setState({ masterErrorMsg: error.response.data.error });
      });
  }

  render() {


    return (<I18n ns="translations">
      {
        (t, { i18n }) => (<div className="login-holder">

          {(this.props.siteMaster.siteName === "MPZMail" || this.props.siteMaster.siteName === "Transpond") &&
            <LeftPanel history={this.props.history} />
          }

          <div className="login-right-panel">
            <div className="login-right-panel-login">
              <UILIB.Paper className="login-right-panel-login_loginBox">



                <h3 className="mar-b5">
                  {t('login:reminder.header')} <span>{this.props.siteMaster.siteName}</span> {t('login:reminder.header2')}
                </h3>
                <div className="paper_subheading mar-b40">
                  {t('login:reminder.subHeader')}
                </div>

                <UILIB.TextInput
                  label={t('login:login.emailPlaceholder')}
                  labelOptions={<a onClick={() => { this.redirectToPage('') }}>{t('login:reminder.remembered')}</a>}
                  type="email"
                  name="emailAddress"
                  onChange={this.handleChange} onKeyPress={this.handleChange}
                  outerClassName="mar-b24"
                  error={this.state.masterErrorMsg}
                  maxLength="255"
                />

                < UILIB.Button className="button-primary" text={t('login:reminder.button')} onClick={this.sendReminder} />

              </UILIB.Paper>
            </div>
          </div>
        </div>)
      }
    </I18n>)


  }
};

// DOCS ARE AVAILABLE AND SHOULD BE UPDATED HERE
// Docs/commonObjects.html

import SiteLogo from './SiteLogo/SiteLogo';
import LoadingIcons from './LoadingIcons/loadingIcon1';
import LoadingOverlay from './LoadingIcons/loadingOverlay';
import AiLoadingIcon from './LoadingIcons/aiLoadingIcon';
import ModalWindow from './UI/ModalWindow/ModalWindow';
import WhiteBox from './UI/WhiteBox/WhiteBox';
import WhiteBoxHeader from './UI/WhiteBoxHeader/WhiteBoxHeader';
import Paper from './UI/Paper/Paper'
import Avatar from './Avatar/Avatar';
import AvatarNew from './UI/Avatar';
import Initials from './Avatar/Initials'
import ImageLoader from './ImageLoader/ImageLoader';
import SocialSharer from './UI/SocialSharer/SocialSharer'
import Calendar from './Calendar'
import Paging from './Paging'

/*NOTIFICATIONS*/
import SnackBar from './UI/Notifications/SnackBar/SnackBar'

/*DIALOGS*/
import AlertDialog from "./UI/AlertDialog";
import Dialog from "./UI/Dialog";
import Drawer from './UI/Drawer/Drawer.jsx'
import DrawerLink from './UI/Drawer/DrawerLink.jsx'
import DrawerConfirm from './UI/Drawer/DrawerConfirm';
import Chip from './UI/Chips/Chip.jsx'
import SquareChip from './UI/Chips/SquareChip.jsx';
import CircularChip from './UI/Chips/CircularChip.jsx';
/*FORMS STUFF*/
import TextInput from './UI/Forms/TextInput/TextInput';
import TextArea from './UI/Forms/TextArea/TextArea';
import Button from './UI/Forms/Button/Button'
import ButtonSelect from './UI/Forms/ButtonSelect/ButtonSelect'
import ButtonSimple from './UI/Forms/ButtonSimple/ButtonSimple'
import Select from './UI/Forms/Select/Select'
import SelectNew from "./UI/Select";
import SelectNewDefault from "./UI/Select/Default";
import CheckBox from './UI/Forms/CheckBox/CheckBox'
import CheckboxTile from "./UI/Forms/CheckboxTile";
import FormError from './UI/Forms/FormError/FormError'
import FormWarning from './UI/Forms/FormError/FormWarning'
import DatePicker from './UI/Forms/DatePicker/DatePicker'
import Wysiwyg from './UI/Forms/Wysiwyg/Wysiwyg'
import Divider from './UI/Divider/Divider'
import Toggle from './UI/Forms/Toggle/Toggle'
import Radio from './UI/Forms/Radio/Radio'
import RadioTile from "./UI/Forms/RadioTile";
import Score from './UI/Score'
import ScoreNew from "./UI/ScoreNew";
import DragBar from './UI/Forms/DragBar/DragBar'
import TelephoneInput from './UI/Forms/TelephoneInput/TelephoneInput'
import Multi from './UI/Forms/Multi'
import AutoComplete from './AutoComplete';
import TagSelect from './UI/TagSelect';
import WizardStep from './UI/Wizard/Step';
import SmileyPicker from './UI/Forms/TextInput/smileyPicker';

/*CONTROL PANEL BUILDING BlOCKS*/
import HeaderText from './Text/HeaderText/HeaderText'
import SubHeaderText from './Text/SubHeaderText/SubHeaderText'
import OptionsDropdown from './UI/OptionsDropdown/OptionsDropdown'
import Accordian from './UI/Accordian/Accordian'
import PlainAccordian from './UI/Accordian/PlainAccordian'
import MultiAccordian from './UI/Accordian/MultiAccordian';
import ActivityLog from './ActivityLog/ActivityLog';
import CircularProgress from './UI/Graphs/CircularProgress/CircularProgress'
import SmallLineGraph from './UI/Graphs/SmallLineGraph/SmallLineGraph'
import SmallLineGraphAlt from './UI/Graphs/SmallLineGraph/SmallLineGraphAlt'
import PieChart from './UI/Graphs/Pie/Pie';
import LoadingLine from './UI/Loading/LoadingLine'
import Tabs from './Tabs/Tabs';
import Tab from './Tabs/Tab/Tab';
import SubTabs from './Tabs/SubTabs';
import TextSelector from './UI/TextSelector/TextSelector';
import Help from './Logos/help';
import Tags from './Tags/Tags';
import ClickAway from './ClickAway'
import Hint from './UI/Hint/Hint'
import Header from './UI/Header'

/*CARDS*/
import Card from './UI/Cards/Card';
import StaffCard from './UI/Cards/StaffCard';
import RoleCard from './UI/Cards/RoleCard';
import BindCard from './UI/Cards/BindCard';
import DomainCard from './UI/Cards/DomainCard';
import RoleGroupCard from './UI/Cards/RoleGroupCard';
import TemplateCard from './UI/Cards/TemplateCard';
import WarningHolder from './UI/Cards/WarningHolder';
import TierLimitHolder from './UI/Cards/TierLimitHolder';
import IllustrationCard from './UI/Cards/IllustrationCard';

// Charts
import CustomTooltip from './Charts/CustomTooltip';
import CustomPieLegend from './Charts/CustomPieLegend';
import SimpleBar from './Charts/SimpleBar';

// Chat
import ChatMessageGroup from "./UI/Chat/MessageGroup";
import ChatMessage from "./UI/Chat/Message";
import ChatAttachment from "./UI/Chat/Attachment";
import ChatTeaser from "./UI/Chat/Teaser";


/*TABLES*/
import DataTable1 from './Tables/DataTable1/DataTable1'
import PagingBlock from './Tables/DataTablePaging/DataTablePaging'
import TableSortLabel from './Tables/TableSortLabel/TableSortLabel'
/*DROPDOWNS*/
import DropDown from './UI/DropDowns/DropDown'
import DropdownMenu from "./UI/DropdownMenu";

/*SUBNAVS*/
import SubNavHolder from './Navigation/SubNav/SubNavHolder'
import SubNavChip from './Navigation/SubNav/SubNavChip'
import StickyNavBottom from './Navigation/StickNavBottom';

/*GRIDS*/
import Row from './Grids/Row/Row'
import Column from './Grids/Column/Column'

/*DRAG DROP*/
import DragDropBlocks from './Logos/dragDropBlocks';
import ImageCustomiser from './ImageCustomiser/ImageCustomiser'
// Payments
import Stripe from './UI/Payment/stripe'
import CreditCard from './UI/Payment/card'
import PaymentPlans from './UI/Payment/plans'
import AddCard from './UI/Payment/addCardStripe'
import AuthStripe from './UI/Payment/confirmCardPayment'

import Icons from './UI/Icons/index';
import Svgs from './UI/Svgs/index';
import Indicator from './UI/Indicator/index';
import FeatureCard from './UI/Cards/featureCard';

import Modal from './Modal/index';

// hooks
import useViewPort from './hooks/UseViewport';
import useCallback from './hooks/useCallback';
import ProgressNav from './Navigation/ProgressNav';

import Notifications from './Notifications/notifications'
import PopOver from './UI/Popover/index';
import VideoPlayer from './UI/VideoPlayer';

import ButtonNew from "./UI/Button";
import Icon from './UI/Icon';
import Badge from './UI/Badge';

//labs
import LabsEnable from './Labs/enableFeature';
import LabsMoreInfo from './Labs/moreInfo';
import LabsDisconnect from './Labs/disconnect';
import NewFeatureFeedback from './NewFeatureFeedback';
export default {
    Badge,
    Button,
    ButtonNew,
    ButtonSelect,
    AlertDialog,
    Dialog,
    Drawer,
    DrawerLink,
    DrawerConfirm,
    WhiteBox,
    WhiteBoxHeader,
    TextInput,
    SiteLogo,
    LoadingIcons,
    AiLoadingIcon,
    LoadingOverlay,
    ModalWindow,
    HeaderText,
    SubHeaderText,
    TextArea,
    CheckBox,
    CheckboxTile,
    FormError,
    FormWarning,
    DataTable1,
    Select,
    SelectNew,
    SelectNewDefault,
    PagingBlock,
    SnackBar,
    DropDown,
    DropdownMenu,
    SubNavHolder,
    SubNavChip,
    ImageLoader,
    TableSortLabel,
    Paper,
    Card,
    Row,
    Column,
    Avatar,
    AvatarNew,
    Initials,
    DatePicker,
    Chip,
    SquareChip,
    CircularChip,
    StaffCard,
    RoleCard,
    BindCard,
    RoleGroupCard,
    OptionsDropdown,
    Accordian,
    MultiAccordian,
    ActivityLog,
    CircularProgress,
    SmallLineGraph,
    SmallLineGraphAlt,
    Wysiwyg,
    DomainCard,
    Tabs,
    Tab,
    LoadingLine,
    Divider,
    Toggle,
    Radio,
    RadioTile,
    TextSelector,
    DragDropBlocks,
    Stripe,
    CreditCard,
    PaymentPlans,
    Help,
    PieChart,
    AddCard,
    AuthStripe,
    SocialSharer,
    Score,
    ScoreNew,
    DragBar,
    TelephoneInput,
    Multi,
    ImageCustomiser,
    Calendar,
    Tags,
    ClickAway,
    AutoComplete,
    Icons,
    Icon,
    Indicator,
    ButtonSimple,
    Hint,
    FeatureCard,
    PlainAccordian,
    TemplateCard,
    Header,
    WarningHolder,
    TierLimitHolder,
    CustomTooltip,
    CustomPieLegend,
    SimpleBar,
    useViewPort,
    StickyNavBottom,
    ProgressNav,
    Modal,
    TagSelect,
    SubTabs,
    useNotifications: Notifications,
    IllustrationCard,
    WizardStep,
    ChatMessageGroup,
    ChatMessage,
    ChatAttachment,
    ChatTeaser,
    PopOver,
    VideoPlayer,
    Svgs,
    SmileyPicker,
    useCallback,
    LabsEnable,
    LabsMoreInfo,
    LabsDisconnect,
    Paging,
    NewFeatureFeedback
}
import React from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import WarningSvg from '~/assets/images/icons/warning.svg';
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class TemplateUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            code: '',
            templateName: '',
            newGroup: false,
            groupId: undefined,
            newGroupName: '',
            groups: [],
            errors: {
                templateName: '',
                newGroupName: '',
                code: ''
            },
            importError: '',
            importingTemplate: false
        };

        this.getGroups = this.getGroups.bind(this);
        this.updateField = this.updateField.bind(this);
        this.import = this.import.bind(this);
        this.createGroup = this.createGroup.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
    }

    componentDidMount() {
        this.getGroups()
    }

    getGroups() {
        axios.get('/templateGroup')
            .then(response => {
                var groups = [{
                    value: '',
                    label: i18n.t('templates:add.advanced.none')
                }, {
                    value: 'new',
                    label: i18n.t('templates:add.advanced.createGroup')
                }]
                this.setState({
                    groups: groups.concat(response.data.TemplateGroups.map(g => {
                        return {
                            label: g.groupName,
                            value: g.id
                        }
                    })),
                    ready: true
                })
            })
    }

    updateField(ev) {
        this.setState({
            [ev.currentTarget.name]: ev.currentTarget.value
        })
    }

    updateGroup(ev) {
        var value = ev.target.value;
        this.setState({
            groupId: value,
            newGroup: value === 'new'
        })
    }

    createGroup() {
        return axios.post('/templateGroup', {
            groupName: this.state.newGroupName
        })
    }

    import() {
        var errors = {
            templateName: '',
            newGroupName: '',
            code: ''
        }
        if (!this.state.code || this.state.code.length < 5) {
            errors.code = i18n.t('templates:add.advanced.importError')
            return this.setState({
                errors
            })
        }

        var promise;

        if (!this.state.templateName || this.state.templateName.length < 2) {
            errors.templateName = i18n.t('templates:add.advanced.invalidName')
            return this.setState({
                errors
            })
        }
        this.setState({ importingTemplate: true, errors })
        if (!this.props.campaignId && this.state.newGroup) {
            if (!this.state.newGroupName) {
                errors.newGroupName = i18n.t('templates:add.advanced.invalidGroup')
                return this.setState({
                    errors,
                    importingTemplate: false
                })
            }

            promise = this.createGroup()
                .then(response => {
                    return axios.post('/template/importcode', {
                        code: this.state.code,
                        groupID: response.data.TemplateGroup.id,
                        templateName: this.state.templateName,
                    })
                })
        } else {
            promise = axios.post('/template/importcode', {
                code: this.state.code,
                templateName: this.state.templateName,
                CampaignId: this.props.campaignId
            })
        }

        promise.then(response => {
            this.props.finish(response.data.Template)
        }).catch(err => {
            this.setState({ importingTemplate: false, importError: err.data && err.data.error ? err.data.error : i18n.t('templates:add.advanced.errorImporting') })
        })
    }

    render() {
        if (!this.state.ready) {
            return <div></div>
        } else {
            if (this.state.importingTemplate) {
                return <div>
                    <UILIB.LoadingIcons iconType="2" />
                    <h4 className="mar-b25">{i18n.t('templates:add.advanced.importingHeader')}</h4>
                    <div>{i18n.t('templates:add.advanced.pleaseWaitWhileImporting')}</div>
                </div>
            }
            return <div>
                <h4 className="mar-b25">{i18n.t('templates:add.advanced.importHeader')}</h4>

                <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('templates:add.advanced.importSubHeader')} type="text" value={this.state.code} placeholder={i18n.t('templates:add.advanced.importSubHeader')} onChange={this.updateField} name="code" error={this.state.errors.code} />
                <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('templates:add.advanced.name')} type="text" value={this.state.templateName} placeholder={i18n.t('templates:add.advanced.name')} onChange={this.updateField} name="templateName" error={this.state.errors.templateName} />

                {
                    !this.props.campaignId &&
                    <UILIB.Select outerClassName="mar-b25" label={i18n.t('templates:add.advanced.group')} type="text" value={this.state.groupId} data={this.state.groups} onChange={this.updateGroup} name="groupId" />
                }
                {
                    this.state.newGroup &&
                    <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('templates:add.advanced.newGroup')} type="text" value={this.state.newGroupName} onChange={this.updateField} name="newGroupName" error={this.state.errors.newGroupName} />
                }
                {!!this.state.importError && <UILIB.WarningHolder className="mar-b25">{this.state.importError}</UILIB.WarningHolder>}
                <UILIB.Button onClick={this.import} className="button-secondary">{i18n.t('templates:add.advanced.importButton')}</UILIB.Button>
            </div>
        }
    };
}

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import copy from 'copy-to-clipboard';
import textFunctions from '~/Classes/textFunctions';
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster }
})

export default class AuthDomainPop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: "",
            inputErrors: '',
            spfLoaded: false,
            spfExists: false,
            spfCorrect: false,
            spf: this.props.siteMaster.spfRecordUrl ? `v=spf1 include:${this.props.siteMaster.spfRecordUrl}.ourmailsender.com ?all` : `v=spf1 include:spf.ourmailsender.com ?all`,
            spfHost: this.props.siteMaster.spfRecordUrl || "spf",
            dkimLoaded: false,
            dkimExists: false,
            dkimCorrect: false,
            alertOpen: false,
            alertMessage: '',
            provider: ''
        };

        this.updateInput = this.updateInput.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
        this.detect = this.detect.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this)
    }

    componentDidMount() {
        axios.get('/accountMaster/domain/provider?domain=' + this.props.domain.domainName).then(res => {
            const registrar = res.data?.Registrar
            let provider
            if (registrar && registrar.toLowerCase().indexOf('godaddy') > -1) provider = 'godaddy'
            if (registrar && registrar.toLowerCase().indexOf('ionos') > -1) provider = 'ionos'
            if (registrar && registrar.toLowerCase().indexOf('123-reg') > -1) provider = '123-reg'
            if (registrar && registrar.toLowerCase().indexOf('heart') > -1) provider = 'heart'
            if (registrar && registrar.toLowerCase().indexOf('namecheap') > -1) provider = 'namecheap'
            if (registrar && registrar.toLowerCase().indexOf('cloudflare') > -1) provider = 'cloudflare'

            this.setState({ provider })
        })

        axios.get('/accountMaster/domain/resolve?type=txt&domain=' + this.props.domain.domainName).then(response => {
            let spf = response.data.find(r => r[0].indexOf('v=spf') === 0)
            if (spf) {
                spf = spf[0]
                let spfInclude = `include:${this.state.spfHost + ".ourmailsender.com"}`;
                let spfCorrect = spf.indexOf(spfInclude) > -1;

                if (!spfCorrect) {
                    let start = spf.indexOf('include:')
                    if (start > -1) {
                        spf = [spf.slice(0, start), ' ' + spfInclude + ' ', spf.slice(start)].join('')
                    }
                    else {
                        let all = spf.indexOf("~all");
                        if (!all) all = spf.indexOf("-all");
                        if (!all) all = spf.indexOf("?all");
                        if (all > -1) {
                            spf = [spf.slice(0, all), ' ' + spfInclude + ' ', spf.slice(all)].join('');
                        }
                    }
                }
                this.setState({
                    spf,
                    spfCorrect,
                    spfExists: true,
                    spfLoaded: true
                }, () => {
                    if (this.state.spfCorrect && this.state.dkimCorrect) {
                        this.checkEmail()
                    }
                })
            } else {
                this.setState({
                    spfLoaded: true
                })
            }
        })

        axios.get('/accountMaster/domain/resolve?type=cname&domain=mp1._domainkey.' + this.props.domain.domainName).then(response => {
            let dkim = response.data[0]
            if (!dkim) {
                this.setState({
                    dkimLoaded: true
                })
            } else if (dkim === 'dkim.ourmailsender.com') {
                this.setState({
                    dkimLoaded: true,
                    dkimExists: true,
                    dkimCorrect: true
                }, () => {
                    if (this.state.spfCorrect && this.state.dkimCorrect) {
                        this.checkEmail()
                    }
                })
            } else {
                this.setState({
                    dkimLoaded: true,
                    dkimExists: true
                })
            }
        })
    }

    updateInput(ev) {
        this.setState({ [ev.currentTarget.name]: ev.currentTarget.value })
    }

    copyToClipboard(val) {

        copy(val)
        this.setState({
            alertOpen: true,
            alertMessage: 'copied to clipboard'
        })
    }

    detect() {
        axios.get('/accountMaster/domain/resolve?type=soa&domain=' + this.props.domain.domainName).then(response => {
            console.log(response.data)
        })
    }

    checkEmail() {
        this.setState({
            inputErrors: ''
        })
        axios.put('/accountMaster/domain/' + this.props.domain.id + '/authenticate')
            .then(response => {
                var success = response.data.Success;
                var errors = response.data.errors;
                if (success) {
                    this.props.onAuthed();
                } else {
                    this.setState({
                        inputErrors: errors
                    })
                }
            })
            .catch(err => {
                this.setState({
                    inputErrors: err.response.data.error
                })
            })
    }

    render() {
        let dkimError = false;
        let spfError = false;
        let dkimErrorTxt = "";
        let spfErrorTxt = "";
        let dkimColor = "paper-primary";
        let spfColor = "paper-primary";

        if (!this.state.dkimCorrect) dkimColor = "paper-secondary"
        if (!this.state.spfCorrect) spfColor = "paper-secondary"
        if (this.state.inputErrors && this.state.inputErrors.length) {

            let spfErr = this.state.inputErrors.find(f => f.toLowerCase().indexOf("spf") > -1)
            if (spfErr) {
                spfError = true;
                spfErrorTxt = spfErr;
            }
            let dkimErr = this.state.inputErrors.find(f => f.toLowerCase().indexOf("dkim") > -1)
            if (dkimErr) {
                dkimError = true;
                dkimErrorTxt = dkimErr;
            }
        }

        let helpLink = "https://kb.transpond.io/article/60-how-to-manage-dns-in-popular-dns-control-panels"
        let helpText = "to view our guide on adding DNS records in popular domain providers."
        if (this.state.provider === 'godaddy') {
            helpLink += '#godaddy'
            helpText = "to view our guide on adding DNS records to your GoDaddy domain"
        }
        if (this.state.provider === 'ionos') {
            helpLink += '#ionos'
            helpText = "to view our guide on adding DNS records to your IONOS domain"
        }
        if (this.state.provider === '123-reg') {
            helpLink += '#123-ref'
            helpText = "to view our guide on adding DNS records to your 123-Reg domain"
        }
        if (this.state.provider === 'heart') {
            helpLink += '#heart-internet'
            helpText = "to view our guide on adding DNS records to your Heart Internet domain"
        }
        if (this.state.provider === 'namecheap') {
            helpLink += '#namecheap'
            helpText = "to view our guide on adding DNS records to your NameCheap domain"
        }
        if (this.state.provider === 'cloudflare') {
            helpLink += '#cloudflare'
            helpText = "to view our guide on adding DNS records to your CloudFlare domain"
        }


        return <div>
            <h4 className="mar-b25">{i18n.t('company:domains.authHeader')}</h4>
            <div className="mar-b25">
                <div dangerouslySetInnerHTML={{ __html: i18n.t('company:domains.authSubHeader') }} />
            </div>

            <UILIB.Paper className={dkimColor} isLoading={!this.state.dkimLoaded}>
                <h4 className="quickFlex" style={{ alignItems: "center" }}>
                    {this.state.dkimCorrect ? <UILIB.Icons icon="tickCircle" color="#FFFFFF" className="mar-r10" /> : ""}
                    DKIM
                </h4>

                {this.state.dkimLoaded && !this.state.dkimCorrect && <div >
                    {!this.state.dkimExists && <div className="mar-t15">
                        <div className="mar-b10"
                            dangerouslySetInnerHTML={{ __html: i18n.t('company:domains.dkimLabel', { domainName: textFunctions.checkForHtml(this.props.domain.domainName) }) }}
                        />
                        < div className="mar-b10" >

                            <UILIB.TextInput
                                iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => this.copyToClipboard("mp1._domainkey")} />}
                                value={"mp1._domainkey"}
                                className="mar-b0"
                            />

                        </div>
                        <UILIB.TextInput
                            label={i18n.t('company:domains.dkimLabel2')}
                            iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => this.copyToClipboard("dkim.ourmailsender.com")} />}
                            value={"dkim.ourmailsender.com"}
                        />
                    </div>}
                </div>}

                {dkimErrorTxt && <UILIB.Paper className="paper-red mar-t15 mar-b0" style={{ padding: "5px 10px" }}>{dkimErrorTxt}</UILIB.Paper>}
            </UILIB.Paper>

            <UILIB.Paper className={spfColor} isLoading={!this.state.spfLoaded}>
                <h4 className="quickFlex" style={{ alignItems: "center" }}>
                    {this.state.spfCorrect ? <UILIB.Icons icon="tickCircle" color="#FFFFFF" className="mar-r10" /> : ""}
                    SPF
                </h4>
                {this.state.spfLoaded && !this.state.spfCorrect && <div className="mar-t15">
                    {!this.state.spfExists &&
                        <div className="mar-b10" dangerouslySetInnerHTML={{ __html: i18n.t('company:domains.spfLabel') + " " + this.props.domain.domainName + " " + i18n.t('company:domains.spfLabel2') }} />
                    }
                    {this.state.spfExists &&
                        <div className="mar-b10" dangerouslySetInnerHTML={{ __html: i18n.t('company:domains.spfChange', { domainName: textFunctions.checkForHtml(this.props.domain.domainName) }) }}>
                        </div>}

                    <UILIB.TextInput
                        iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => this.copyToClipboard(this.state.spf)} />}
                        value={this.state.spf}
                    />
                </div>}

                {spfErrorTxt && <UILIB.Paper className="paper-red mar-t15 mar-b0" style={{ padding: "5px 10px" }}>{spfErrorTxt}</UILIB.Paper>}

            </UILIB.Paper>
            {this.props.siteMaster && this.props.siteMaster.siteId == 1 && <div className="mar-t25 mar-b25">
                <UILIB.Hint className="no-marg" style={{ width: '100%' }} href={helpLink}>
                    <div>
                        <div style={{ fontWeight: "bold" }}>Need help?</div>
                        <div><a>Click Here</a> {helpText}</div>

                    </div>
                </UILIB.Hint>
            </div>
            }
            <h4 className="mar-b15">{i18n.t('company:domains.authAllDone')}</h4>
            <UILIB.Button text={i18n.t('continue')} className="button-primary" onClick={this.checkEmail} />
            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />

        </div >
    }
};



import React from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import * as siteMaster from "~/data/actions/siteActions";
import DrawerStatsViewer from "./drawerStatsViewer";

const StepStatsHolder = ({
  type,
  step,
  automationId,
  onClick,
  className,
  automationStatus,
  ...rest
}) => {
  const dispatch = useDispatch();

  const drawerContent = (
    <DrawerStatsViewer type={type} step={step} automationId={automationId} />
  );

  function handleClick(e) {
    e.stopPropagation();

    if (onClick && typeof (onClick === "function")) {
      onClick();
    }

    openSiteDrawer(drawerContent);
  }

  function openSiteDrawer(drawerContent) {
    dispatch(
      siteMaster.alterSiteDrawer(
        true,
        true,
        "right",
        drawerContent,
        true,
        "700px"
      )
    );
  }

  if (automationStatus == "draft" || automationStatus == "Draft") return <></>
  return (
    <button
      type="button"
      className={classnames("stepStatsHolder", className, type)}
      data-test={`step-stats-holder-${type}`}
      onClick={handleClick}
      {...rest}
    />
  );
};

export default StepStatsHolder;

import React from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import i18n from '~/i18n'

export default class ViewCampaigns extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingCampaigns: true,
            campaigns: [],
            orderBy: "hits",
            orderDirection: "desc"
        }
        this.getCampaigns = this.getCampaigns.bind(this);
        this.tableUpdater = this.tableUpdater.bind(this);
    }

    componentDidMount() {
        this.getCampaigns();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate != this.props.startDate || prevProps.endDate != this.props.endDate) {
            this.getCampaigns();
        }
    }

    tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {

    }


    async getCampaigns() {
        let campaignResults = await axios.get('/accountMaster/tracker/campaign?trackerId=' + this.props.tracker.id + '&start=' + this.props.startDate.format("YYYY-MM-DD") + '&end=' + this.props.endDate.format("YYYY-MM-DD"));
        let campaignTable = campaignResults.data.map(campaign => {
            let clickUrl = `/cp/campaigns/view/${campaign.campaignId}`
            let typeChip = <UILIB.SquareChip value="Email Campaign" className="square-chip-primary" />
            if (campaign.campaignType == "ad") {
                clickUrl = `/cp/campaigns/viewad/${campaign.campaignId}`
                typeChip = <UILIB.SquareChip value="Ad Campaign" className="square-chip-secondary" />

            }


            return {
                campaign: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingCampaigns.campaignTracked'),
                    value: <a onClick={() => this.props.history.push(clickUrl)} style={{ fontWeight: "600" }}>{campaign.campaignName}</a>,
                },
                hits: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingCampaigns.hits'),
                    value: NumberFunctions.formatNumber(campaign.hits),
                    width: 100,
                    align: "center"
                },
                type: {
                    headerValue: "Type",
                    value: typeChip,
                    width: 100,
                    align: "center"
                },
                lastHit: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingCampaigns.lastHit'),
                    value: DateTimeFunctions.formatDateTime(campaign.lastHitDate),
                    width: 100,
                    align: "right"
                }
            }
        })

        this.setState({
            loadingCampaigns: false,
            campaigns: campaignTable
        })
    }


    render() {
        if (this.state.loadingCampaigns) {
            return <UILIB.LoadingIcons iconType="2" />
        }

        return (
            <div>

                <UILIB.Paper isLoading={this.state.loadingCampaigns} className="full-height-not-mob">

                    <UILIB.DataTable1
                        noResultsTxt={i18n.t('campaigns:sitetracking.trackingCampaigns.noDataFound')}
                        tableData={this.state.campaigns}
                        dataUpdater={this.tableUpdater}
                        loadingData={false}
                        width="100%"
                        pageSize="100"
                        hasCheckBoxes="no"
                        sortedColumn={this.state.orderBy}
                        sortedDirection={this.state.orderDir}
                    />

                </UILIB.Paper>

            </div>
        );
    }
}

import React from 'react'

export default class Column extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {

        var classes = "";

        if (this.props.className) {
            classes += this.props.className + " ";
        }
        if (this.props.xs !== undefined) {
            classes += " col-xs-" + this.props.xs + " ";
        }
        if (this.props.sm !== undefined) {
            classes += " col-sm-" + this.props.sm + " ";
        }
        if (this.props.md !== undefined) {
            classes += " col-md-" + this.props.md + " ";
        }
        if (this.props.lg !== undefined) {
            classes += " col-lg-" + this.props.lg + " ";
        }

        if (this.props.margin || this.props.margin === 0) {
            classes += "";
        }
        else {
            classes += " col-margin-bottom ";
        }
        if (this.props.hide && typeof (this.props.hide) === "object") {

            for (var x in this.props.hide) {
                classes += "hide-" + this.props.hide[x] + " ";
            }

        }


        return <div className={classes} style={this.props.style} id={this.props.id}>{this.props.children}</div>
    }
}

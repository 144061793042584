import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'
import CreateGroup from '~/pages/cp/subscribers/groups/incGoAddGroup'

@connect((store) => {
    return { user: store.user }
})
export default class IncGoAddGroup extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            loaded: false,
            groups: [],
            selectedGroup: undefined
        };

        this.getGroups = this.getGroups.bind(this);
        this.selectGroup = this.selectGroup.bind(this);
        this.create = this.create.bind(this);
    }

    componentDidMount() {
        this.getGroups()
    }

    getGroups() {
        axios.get('/group')
            .then(response => {
                this.setState({
                    groups: response.data.Groups,
                    loaded: true
                })
            })
    }

    create() {
        var drawer = <CreateGroup addedGroup={group => this.selectGroup(group.id)} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, true, "500px"));
    }

    selectGroup(id) {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
        this.props.history.push('/cp/groups/' + id + '/add')
    }

    render() {
        if (!this.state.loaded) return <div></div>
        else return <div>

            <h4 className="mar-b25">{i18n.t('subscribers:overview.addTitle')}</h4>

            {!this.state.groups.length &&
                <UILIB.WarningHolder className="mar-b25">{i18n.t('subscribers:overview.addNoGroups')}</UILIB.WarningHolder>
            }
            {!!this.state.groups.length && <UILIB.Select
                label={i18n.t('subscribers:settings.groupName')}
                placeholder={i18n.t('subscribers:settings.groupName')}
                value={this.state.selectedGroup}
                data={this.state.groups.map(group => {
                    return { label: group.groupName, value: group.id }
                })}
                name="selectedGroup"
                onChange={evt => this.selectGroup(evt.target.value)}
                outerClassName="mar-b25"
            />
            }
            <UILIB.Button iconLeft={<UILIB.Icons icon="plus" />} text={i18n.t('subscribers:index.addGroup')} className="button-primary" onClick={this.create} />

        </div >
    }
};


import React from 'react'

export default class IconsBeaker extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.5 3.52801V8.17201C8.5 8.90101 8.21 9.60001 7.695 10.116L6.478 11.332C7.69161 11.2904 8.90058 11.5019 10.028 11.953L10.53 12.154C11.8563 12.6846 13.3098 12.8116 14.708 12.519L12.305 10.116C11.7896 9.60034 11.5 8.9011 11.5 8.17201V3.52801C10.5003 3.49069 9.49965 3.49069 8.5 3.52801ZM13 3.61201L13.19 3.62701C13.289 3.63655 13.389 3.6263 13.484 3.59683C13.5791 3.56737 13.6673 3.51929 13.7436 3.4554C13.8198 3.3915 13.8826 3.31307 13.9283 3.22466C13.974 3.13626 14.0016 3.03966 14.0095 2.94047C14.0175 2.84129 14.0057 2.74152 13.9747 2.64696C13.9437 2.55241 13.8942 2.46496 13.8291 2.38972C13.764 2.31448 13.6846 2.25294 13.5955 2.2087C13.5063 2.16445 13.4093 2.13838 13.31 2.13201C11.1069 1.95514 8.89312 1.95514 6.69 2.13201C6.49407 2.15089 6.31342 2.24604 6.18702 2.39693C6.06062 2.54782 5.99861 2.74235 6.01436 2.93856C6.03011 3.13476 6.12236 3.31691 6.2712 3.4457C6.42005 3.5745 6.61357 3.63962 6.81 3.62701L7 3.61201V8.17201C7 8.50301 6.868 8.82101 6.634 9.05501L2.6 13.09C1.104 14.586 1.783 17.24 4.003 17.565C5.961 17.852 7.963 18 10 18C12.037 18 14.039 17.852 15.997 17.564C18.217 17.239 18.897 14.585 17.4 13.089L13.366 9.05501C13.1318 8.82078 13.0002 8.5032 13 8.17201V3.61201Z" fill={color} />
        </svg>
    }
}        
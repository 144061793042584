import React, { Component } from 'react';
import axios from '../../../../data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import moment from 'moment'
import Bounces from './bounces'
import Campaigns from './campaigns'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import AddGroup from './addGroup'
import DeleteFromGroup from './deleteFromGroup'
import NumberFormatFunctions from '~/Classes/numberFormatFunctions';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import MailServerStats from './mailServerStats';

import AddServerDrawer from './addServer/';
import AddManualServerDrawer from './addServer/addManualServer';
import AddHostedServer from './addServer/addHostedServer';
import AddHostedServerPay from './addServer/addHostedServerPay'
import AddHostedServerPurchased from './addServer/addHostedServerPurchased';
import AddHostedServerSetupInfo from './addServer/addHostedServerSetupInfo';
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, accountMaster: store.accountMaster }
})
class DedicatedServers extends Component {
    constructor(props) {
        super(props);

        this.jobTimer = null;
        this.statsTimer = null;
        this.unmounting = false;
        this.state = {
            alertMessage: "",
            alertOpen: false,
            bounces: 'day',
            loaded: false,
            mailservers: [],
            mailserversRaw: [],
            bouncesLoaded: false,
            sendsData: [],
            sendsLoaded: false,
            groupStats: [],
            groups: [],
            campaignData: [],
            sortDirection: "asc",
            sortColumn: "id",
            logSearchResults: "",
            bounceSummaryData: [],
            serverCreateJobs: [],
            bounceTypeList: [],
            selectedLogBounceType: "",
            updatedLogSearchResults: "",
            logSearchResultsLoading: false,
            loadedBounceLog: false
        }

        this.getGroups = this.getGroups.bind(this);
        this.getMailServers = this.getMailServers.bind(this)
        this.getBouncesSummary = this.getBouncesSummary.bind(this)
        this.updateBounceSummary = this.updateBounceSummary.bind(this)
        this.showBounces = this.showBounces.bind(this)
        this.pickGroup = this.pickGroup.bind(this)
        this.deleteGroupConfirm = this.deleteGroupConfirm.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.regenTable = this.regenTable.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.updateField = this.updateField.bind(this);
        this.saveNotes = this.saveNotes.bind(this);
        this.getCampaigns = this.getCampaigns.bind(this);
        this.showCampaigns = this.showCampaigns.bind(this);
        this.tableUpdater = this.tableUpdater.bind(this);
        this.logSearcher = this.logSearcher.bind(this);
        this.openStatsDrawer = this.openStatsDrawer.bind(this);
        this.addServer = this.addServer.bind(this);
        this.addManualServer = this.addManualServer.bind(this);
        this.addHostedServer = this.addHostedServer.bind(this);
        this.addedServer = this.addedServer.bind(this);
        this.addHostedServerPay = this.addHostedServerPay.bind(this);
        this.addHostedServerPurchased = this.addHostedServerPurchased.bind(this);
        this.addHostedServerSetupInfo = this.addHostedServerSetupInfo.bind(this);
        this.getPurchaseJobs = this.getPurchaseJobs.bind(this);
        this.deletePurchasedServer = this.deletePurchasedServer.bind(this);
        this.getBounceTypeList = this.getBounceTypeList.bind(this);
    }

    componentDidMount() {
        this.getGroups();
        this.getPurchaseJobs();
        this.getBounceTypeList();
        this.jobTimer = setInterval(() => this.getPurchaseJobs(), 10000)
    }

    componentWillUnmount() {
        this.unmounting = true;
        if (this.statsTimer) clearTimeout(this.statsTimer)
        if (this.jobTimer) clearTimeout(this.jobTimer)
    }

    updateBounceSummary(ev) {
        this.setState({
            bounces: ev.target.value,
            bouncesLoaded: false,
            sendsLoaded: false
        }, () => { this.regenTable(); this.getBouncesSummary(); })
    }

    getGroups() {
        if (this.unmounting) return
        clearTimeout(this.statsTimer)
        axios.get('/mailServer/group').then(_gDat => {
            this.setState({ groups: _gDat.data.Groups }, this.getMailServers)
        }).catch(err => {
            this.getMailServers()
        })
    }

    getMailServers() {
        if (this.unmounting) return
        clearTimeout(this.statsTimer)
        axios.get('/accountMaster/mailservers?dedicated=true').then(response => {
            // axios.get('/accountMaster/mailservers?').then(response => {
            this.setState({
                mailserversRaw: response.data.MailServers,
                loaded: true
            }, () => { this.regenTable(); this.getBouncesSummary() })
        })
    }
    deletePurchasedServer(serverId) {
        var drawer = <UILIB.DrawerConfirm header={'Are you sure you want to delete this mail server?'} showCancel={true} confirm={() => {
            axios.delete('/mailServer/add/' + serverId).then(this.getMailServers).catch(err => {
                let error = "Unable to delete server"
                if (err && err.data && err.data.error) error = err.data.error;
                this.setState({ alertOpen: true, alertMessage: error })

            })
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, true));
    }


    getBouncesSummary() {
        if (this.unmounting) return
        clearTimeout(this.statsTimer)
        axios.get('/accountMaster/mailserverbouncessummary?range=' + this.state.bounces).then(response => {

            var bounceSummaryData = response.data;
            this.setState({
                bounceSummaryData: bounceSummaryData,
                bouncesLoaded: true
            }, this.getCampaigns)
        })

    }

    getCampaigns() {
        if (this.unmounting) return
        clearTimeout(this.statsTimer)
        // get the bounces
        let q = `?toDate=${moment().format('YYYY-MM-DD')}`
        switch (this.state.bounces) {
            case 'day':
                q += `&fromDate=${moment().format('YYYY-MM-DD')}`
                break;
            case 'week':
                q += `&fromDate=${moment().startOf('week').format('YYYY-MM-DD')}`
                break;
            case 'month':
                q += `&fromDate=${moment().startOf('month').format('YYYY-MM-DD')}`
                break;
        }
        axios.get('/mailServer/campaigns' + q).then(response => {
            this.setState({
                campaignData: response.data
            }, this.getSends)
        })
        // axios.get('/accountMaster/mailservercampaigns?range=' + this.state.bounces).then(response => {
        //     this.setState({
        //         campaignData: response.data
        //     }, this.getSends)
        // })
    }

    getSends() {
        if (this.unmounting) return
        clearTimeout(this.statsTimer)
        // get the sends

        axios.get('/accountMaster/mailserversends?range=' + this.state.bounces).then(response => {
            this.setState({
                sendsData: response.data,
                sendsLoaded: true
            }, () => {
                this.regenTable();
                this.statsTimer = setTimeout(this.getGroups, 30000)
            })
        })
    }

    toggleEdit(index, field) {
        var mailservers = this.state.mailserversRaw
        var mailserver = mailservers[index]
        if (mailserver[field + 'Editing']) {
            mailserver[field + 'Editing'] = false
            delete mailserver[field + 'Backup']
        } else {
            mailserver[field + 'Editing'] = true
            mailserver[field + 'Backup'] = mailserver[field]
        }

        this.setState({
            mailserversRaw: mailservers
        }, this.regenTable
        )
    }
    cancelEdit(index, field) {
        var mailservers = this.state.mailserversRaw
        var mailserver = mailservers[index]

        mailserver[field + 'Editing'] = false
        mailserver[field] = mailserver[field + 'Backup']
        delete mailserver[field + 'Backup'];
        this.setState({
            mailserversRaw: mailservers
        },
            this.regenTable
        )
    }
    updateField(ev, index) {

        var mailservers = this.state.mailserversRaw
        var mailserver = mailservers[index]

        mailserver[ev.currentTarget.name] = ev.currentTarget.value
        this.setState({
            mailserversRaw: mailservers
        }, this.regenTable)
    }

    saveNotes(index, field) {
        var mailserver = this.state.mailserversRaw[index];
        if (!mailserver) return;
        delete mailserver[field + 'Backup'];
        delete mailserver[field + 'Editing'];

        var data = {
            notes: mailserver.notes
        }
        axios.put('/accountMaster/mailservers/' + mailserver.id, data).then(response => {
            this.regenTable()
        })
    }

    regenTable(regenStats) {

        var groupStats = [];
        var mailservers = this.state.mailserversRaw.map((row, index) => {
            var senderScore = row.senderScore ? 100 - row.senderScore : -1;

            var warmupStarted = moment(row.warmupStarted).startOf('day');
            var warmupDays = moment().startOf('day').diff(warmupStarted, 'days');

            if (row.maxWarmupDays > 0 && warmupDays > row.maxWarmupDays) {
                warmupDays = <span><div>Day {row.maxWarmupDays}</div><div style={{ fontSize: "10px" }}>Fixed</div></span>;
            }
            var senderScore = row.senderScore ? 100 - row.senderScore : -1;

            var inGroups = this.state.groups.filter(g => g.mailServers.find(ms => ms == row.id))
            var groupsText = <div><UILIB.Button text="Add to a Group" className="button-sml outline primary" onClick={() => { this.pickGroup(row) }} /></div>
            var groupsSortValue = "";
            if (inGroups && inGroups.length) {
                groupsText = [<div><a onClick={() => { this.pickGroup(row) }}>Add another group</a></div>];
                inGroups.forEach(g => {
                    groupsText.unshift(<UILIB.SquareChip key={"GH_" + g.id} for={g.id} value={<span>{g.name}</span>} hasButton={true} className={"square-chip-secondary"} iconRight={<UILIB.Icons icon="crossCircle" style={{ height: 14, width: 14 }} />} onClick={() => { this.deleteGroupConfirm(row, g) }} />)
                    groupsSortValue += g.name + ",";
                })
            }

            var notes = <div className="quickFlex">
                <div style={{ maxWidth: "300px", width: "100%", wordBreak: "break-all", whiteSpace: "pre-wrap" }}>{row.notes}</div>
                <a onClick={() => this.toggleEdit(index, 'notes')} className="pad-left icon-pencil3 text-primary"></a>
            </div>

            if (row.notesEditing) {
                notes = <div className="quickFlex">
                    <input className="txtInput" autoFocus={true} value={row.notes} name="notes" onChange={ev => this.updateField(ev, index)} onFocus={function (e) {
                        var val = e.target.value;
                        e.target.value = '';
                        e.target.value = val;
                    }} />
                    <a className="pad-left icon-check text-primary" onClick={() => this.saveNotes(index, 'notes')}></a>
                    <a className="pad-left icon-cross2 text-red" onClick={() => this.cancelEdit(index, 'notes')}></a>
                </div>
            }

            var hardBounces = <span className="loading">Loading</span>
            var hardBouncesSortVal = 0;
            var softBounces = <span className="loading">Loading</span>
            var softBouncesSortVal = 0;
            var sends = <span className="loading">Loading</span>
            var sendsSortVal = 0;
            var campaigns = <span className="loading">Loading</span>
            var campaignsSortVal = 0;

            var serverStats = this.state.bounceSummaryData.filter(d => d.MailServerId == row.id)
            var sendStats = this.state.sendsData.find(d => d.MailServerId == row.id)
            var campaignStats = this.state.campaignData.find(d => d.MailServerId == row.id)
            if (campaignStats) campaignStats = campaignStats.Campaigns
            var softBouncesData = [];
            var hardBouncesData = [];


            if (this.state.sendsLoaded) {


                sends = <UILIB.SquareChip hasButton={false} value={sendStats ? sendStats.sends : 0} buttonIcon="icon-magnifier" className={' cursor-pointer ' + (sendStats && sendStats.sends > 0 ? "square-chip-green" : "square-chip-grey")} />
                sendsSortVal = sendStats ? sendStats.sends : 0
                campaigns = <UILIB.SquareChip hasButton={true} onClick={() => this.showCampaigns(row)} value={campaignStats ? campaignStats.length : 0} buttonIcon="icon-magnifier" className={' cursor-pointer ' + (campaignStats && campaignStats.length > 0 ? "green" : "grey")} />
                campaignsSortVal = campaignStats ? campaignStats.length : 0

                if (serverStats && serverStats.length) {
                    var hard = 0
                    var soft = 0
                    var h = []
                    var s = []
                    serverStats.forEach(stat => {
                        if (stat.type) {
                            hard += parseInt(stat.count)
                            h.push(stat)
                        } else {
                            soft += parseInt(stat.count)
                            s.push(stat)
                        }
                    })

                    var hardClass = 'square-chip-green'
                    var softClass = 'square-chip-green'

                    if (hard < 10) hardClass = 'square-chip-green'
                    else if (hard < 50) hardClass = 'square-chip-yellow'
                    else if (hard < 100) hardClass = 'square-chip-orange'
                    else hardClass = 'red'

                    if (soft < 10) softClass = 'square-chip-green'
                    else if (soft < 50) softClass = 'square-chip-yellow'
                    else if (soft < 100) softClass = 'square-chip-orange'
                    else softClass = 'square-chip-red'

                    hardBounces = <UILIB.SquareChip onClick={() => this.showBounces(row.id, 'hardBounces')} hasButton={true} value={hard} buttonIcon="icon-magnifier" className={hardClass + ' cursor-pointer'} />
                    hardBouncesSortVal = hard;
                    softBounces = <UILIB.SquareChip onClick={() => this.showBounces(row.id, 'softBounces')} hasButton={true} value={soft} buttonIcon="icon-magnifier" className={softClass + ' cursor-pointer'} />
                    softBouncesSortVal = soft;
                    hardBouncesData = h
                    softBouncesData = s
                } else {
                    hardBounces = <UILIB.SquareChip hasButton={false} value={0} className="square-chip-green" />
                    softBounces = <UILIB.SquareChip hasButton={false} value={0} className="square-chip-green" />
                }
            }

            var needsDns = false;
            if (row.awaitingDNSSetup && row.usingCustomDomain) needsDns = true;

            let deleteOptions = <div></div>
            if (!row.needsDns && (row.manuallyCreated || row.serverService)) deleteOptions = <UILIB.Button className="button-sml red" text="Delete" onClick={() => { this.deletePurchasedServer(row.id) }} />;
            if (row.needsDns && (row.manuallyCreated || row.serverService)) deleteOptions = <UILIB.Button className="button-sml yellow" text="DNS Setup" onClick={() => { this.addHostedServerSetupInfo(row.id) }} />

            var outPut = {
                id: {
                    headerValue: "#",
                    value: row.id,
                    sortValue: row.id,
                    orderBy: true

                },
                name: {
                    headerValue: 'Name',
                    value: <div>
                        <div><strong>{row.name}</strong></div>
                        <div>{row.host}</div>
                    </div>,
                    sortValue: row.name,
                    orderBy: true
                },
                notes: {
                    headerValue: 'Notes',
                    value: notes,
                    width: 300
                },
                groups: {
                    headerValue: 'Group(s)',
                    value: groupsText,
                    sortValue: groupsSortValue,
                    orderBy: true
                },
                senderScore: {
                    headerValue: "SenderScore",
                    value: <UILIB.SquareChip hasButton={true} value={senderScore == -1 ? "N/A" : senderScore} className={senderScore > 90 ? 'square-chip-green' : senderScore > 70 ? 'square-chip-orange' : (senderScore < 70 && senderScore > -1) ? 'square-chip-red' : 'square-chip-grey'} onClick={() => { window.open(`https://www.senderscore.org/lookup.php?lookup=${row.host}`, '_blank') }} buttonIcon="icon-magnifier" />,
                    sortValue: senderScore,
                    align: "center",
                    width: 120,
                    orderBy: true
                },
                campaigns: {
                    headerValue: "Campaigns",
                    value: campaigns,
                    align: "center",
                    width: "120",
                    orderBy: true,
                    sortValue: campaignsSortVal
                },
                sends: {
                    headerValue: "Sends",
                    value: sends,
                    align: "center",
                    width: 120,
                    orderBy: true,
                    sortValue: sendsSortVal

                },
                softBounces: {
                    headerValue: "Soft Bounces",
                    value: softBounces,
                    align: "center",
                    width: 120,
                    bounces: softBouncesData,
                    orderBy: true,
                    sortValue: softBouncesSortVal
                },
                hardBounces: {
                    headerValue: "Hard Bounces",
                    value: hardBounces,
                    align: "center",
                    width: 120,
                    bounces: hardBouncesData,
                    orderBy: true,
                    sortValue: hardBouncesSortVal
                },
                serverAge: {
                    headerValue: "Age",
                    value: row.serverAge,
                    width: 40,
                    align: "center"
                },
                warmupStarted: {
                    headerValue: "Warmup",
                    value: warmupDays,
                    align: "center",
                    width: 80
                },
                blacklists: {
                    headerValue: 'Blacklists',
                    value: row.BlacklistServers.map(tB => {
                        var style = {
                            cursor: "pointer",
                            textDecoration: "underline"
                        };
                        var delist = false;
                        if (tB.delist && tB.delist.length) {
                            delist = tB.delist;
                            delist = delist.replace(/\[IP\]/g, row.host)
                        }

                        if (tB.name.indexOf("spamha") > 0) style.color = "#FF0000"
                        return <div key={row.id + '-' + tB.name} style={style} onClick={() => { window.open(delist, '_blank') }} >{tB.name}</div>
                    }),
                    orderBy: true,
                    sortValue: row.BlacklistServers.length
                },
                viewStats: {
                    headerValue: "",
                    value: <UILIB.Button className="button-sml" text="Stats" onClick={() => { this.openStatsDrawer(row) }} />,
                    align: "center"
                },
                deleteOption: {
                    headerValue: "",
                    value: deleteOptions,
                    align: "center"
                }
            }


            return outPut;
        })

        mailservers = mailservers.sort((a, b) => {

            if (this.state.sortDirection == "desc") {
                if (a[this.state.sortColumn].sortValue > b[this.state.sortColumn].sortValue) { return -1; }
                if (a[this.state.sortColumn].sortValue < b[this.state.sortColumn].sortValue) { return 1; }
            } else {
                if (a[this.state.sortColumn].sortValue < b[this.state.sortColumn].sortValue) { return -1; }
                if (a[this.state.sortColumn].sortValue > b[this.state.sortColumn].sortValue) { return 1; }
            }

            return 0;
        })
        this.setState({ mailservers }, () => {
            if (regenStats) {
                this.getMailServerStats()
            }
        })

    }

    showBounces(mailServerId, type) {
        var bType = "hard";
        if (type.toLowerCase() == "softbounces") bType = "soft"
        var drawerContent = <Bounces mailServerId={mailServerId} bounceType={bType} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '50vw'));

    }
    showCampaigns(mailServer) {
        var found = this.state.campaignData.find(d => d.MailServerId == mailServer.id)
        var drawerContent = <Campaigns campaigns={found ? found.Campaigns : []} mailserver={mailServer} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '50vw'));

    }

    openStatsDrawer(mailServer) {
        var drawerContent = <MailServerStats mailServer={mailServer} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw'));
    }
    pickGroup(server) {
        var drawerContent = <AddGroup getGroups={this.getGroups} server={server} finished={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", undefined, true, '500px'));
            this.getGroups();
        }} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '500px'));
    }

    deleteGroupConfirm(server, group) {
        var drawerContent = <DeleteFromGroup server={server} group={group} finished={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", undefined, true, '500px'));
            this.getGroups();
        }} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '500px'));
    }

    tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {
        this.setState({ sortDirection, sortColumn }, this.regenTable)
    }

    getBounceTypeList() {
        axios.get('/accountMaster/mailserverbouncetypes').then(response => {
            this.setState({
                loadedBounceLog: false, logSearchResultsLoading: false, bounceTypeList: response.data.map(bt => { return { label: bt.spamUrl, value: bt.spamUrl } })
            })
        })
    }

    logSearcher(selectedLogBounceType) {
        var self = this;
        self.setState({ selectedLogBounceType, loadedBounceLog: false })
        axios.get('/accountMaster/mailserverbouncemonitor?search=' + selectedLogBounceType).then(response => {
            var dataTable = response.data.map(row => {
                return {
                    id: {
                        headerValue: "Date",
                        value: DateTimeFunctions.formatDateTime(row.createdAt),
                        orderBy: false,
                        width: "150"
                    },
                    MailServerId:
                    {
                        headerValue: "MailServer",
                        value: row.MailServerId,
                        orderBy: false,
                        width: "50"
                    },
                    mailServerIp:
                    {
                        headerValue: "IP Address",
                        value: row.mailServerIp,
                        orderBy: false,
                        width: "50"
                    },
                    CampaignId:
                    {
                        headerValue: "Campaign Id",
                        value: row.CampaignId,
                        orderBy: false,
                        width: "50"
                    },
                    spamUrl: {
                        headerValue: "Spam URL",
                        value: row.spamUrl,
                        orderBy: false
                    },
                    sendingDomain: {
                        headerValue: "Sending Domain",
                        value: row.sendingDomain,
                        orderBy: false
                    }
                    ,
                    message: {
                        headerValue: "Message",
                        value: row.message,
                        orderBy: false
                    }
                }
            });

            self.setState({ loadedBounceLog: true, logSearchResults: dataTable, updatedLogSearchResults: Math.round(Math.random() * 9999999) })
        });
    }

    addServer() {
        var drawerContent = <AddServerDrawer addManualServer={this.addManualServer} addHostedServer={this.addHostedServer} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '600px'));
    }
    addManualServer() {
        var drawerContent = <AddManualServerDrawer close={this.addedServer} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '600px'));
    }
    addHostedServer() {
        var drawerContent = <AddHostedServer close={this.addedServer} addHostedServerPay={this.addHostedServerPay} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '600px'));
    }
    addHostedServerPay(serverName, serverDomain, serverDomainTxt) {
        var drawerContent = <AddHostedServerPay serverPurchased={this.addHostedServerPurchased} serverName={serverName} serverDomain={serverDomain} serverDomainTxt={serverDomainTxt} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '600px'));
    }

    addHostedServerPurchased(serverName, serverDomainTxt, job) {
        this.getPurchaseJobs()
        var drawer = <AddHostedServerPurchased serverName={serverName} serverDomainName={serverDomainTxt} close={this.addHostedServerSetupInfo} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, true, "500px"));
    }

    addHostedServerSetupInfo(serverId) {
        var drawer = <AddHostedServerSetupInfo serverId={serverId} close={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", undefined, true, "500px"));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, true, "500px"));
    }

    addedServer() {
        this.getMailServers();
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    getPurchaseJobs() {
        axios.get('/genericjob?type=createMailServer&status=pending&status=processing&status=error').then(response => {
            this.setState({
                serverCreateJobs: response.data.map(job => ({
                    name: {
                        headerValue: 'Name',
                        value: job.options.serverName,
                    },
                    domain: {
                        headerValue: 'Domain',
                        value: job.options.serverDomain
                    },
                    status: {
                        headerValue: 'Status',
                        value: job.status === 'error' ? job.status : <span className="loading">{job.status}</span>,
                    },
                    statusText: {
                        headerValue: 'Details',
                        value: job.status === 'error' ? job.error : job.statusText,
                    },
                    createdAt: {
                        headerValue: 'Created At',
                        value: DateTimeFunctions.formatDateTime(job.createdAt)
                    },
                    updatedAt: {
                        headerValue: 'Last Updated',
                        value: DateTimeFunctions.formatDateTime(job.updatedAt)
                    }
                }))
            })
        })
    }
    render() {
        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />

        var bounceOptions = [{
            value: 'day',
            label: 'Today'
        }, {
            value: 'week',
            label: 'This Week'
        }, {
            value: 'month',
            label: 'This Month'
        },]

        var unassignedGroupSends = 0;
        var unassignedGroupSoftBounces = 0;
        var unassignedGroupSoftBouncesPct = 0;
        if (this.state.sendsLoaded) {
            this.state.sendsData.forEach(send => {
                var groups = this.state.groups.find(g => g.mailServers.indexOf(send.MailServerId) > -1)
                if (!groups) {
                    unassignedGroupSends += Number(send.sends);
                }
            })
            this.state.bounceSummaryData.forEach(bounce => {
                if (bounce.type == 0) {
                    var groups = this.state.groups.find(g => g.mailServers.indexOf(bounce.MailServerId) > -1)
                    if (!groups) {
                        unassignedGroupSoftBounces += Number(bounce.count);
                    }
                }
            })
            unassignedGroupSoftBouncesPct = NumberFormatFunctions.formatNumber((unassignedGroupSoftBounces / unassignedGroupSends) * 100, 2) + "%"
        }
        return (
            <div>
                {!!this.state.serverCreateJobs.length && <div className="mar-b25">
                    <UILIB.Paper>
                        <h4 className="mar-b25">Building Mail Servers</h4>
                        <UILIB.DataTable1 tableData={this.state.serverCreateJobs} width="100%" />
                    </UILIB.Paper>
                </div>}

                <UILIB.Row>
                    <UILIB.Column xs={12} sm={6} margin={0}>
                        <h4>Dedicated Sending Servers</h4>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} margin={0} className="end-xs">
                        <div className="quickFlex" style={{ justifyContent: "flex-end" }}>
                            {!!this.state.mailservers.length && <UILIB.Button text="Reports" className="button-primary mar-r10" onClick={() => this.props.history.push('/cp/company/servers/reports')} />}
                            {!!this.state.mailservers.length && <UILIB.Button text="Delivery Manager" className="button-primary mar-r10" onClick={() => this.props.history.push('/cp/company/servers/deliverymanager')} />}
                            <UILIB.Button text="Add a Server" onClick={this.addServer} className="button-primary" iconLeft={<UILIB.Icons icon="plus" />} />
                        </div>
                    </UILIB.Column>
                    {!this.state.mailservers.length && <UILIB.Column xs={12} sm={6} md={4} lg={4} className="mar-t20 col-sm-offset-3 col-md-offset-4">
                        <UILIB.Paper className="center-xs">
                            You currently do not have any dedicated servers
                        </UILIB.Paper>
                    </UILIB.Column>}

                    {!!this.state.mailservers.length && <UILIB.Column xs={12}>
                        <UILIB.Select label="Bounce Date Range" outerClassName="mar-b25" data={bounceOptions} value={this.state.bounces} onChange={this.updateBounceSummary} disabled={!this.state.bouncesLoaded} />
                        <UILIB.Paper isLoading={this.state.loading} className="mar-b0">

                            <UILIB.DataTable1
                                tableData={this.state.mailservers}
                                width="100%"
                                pageSize="100"
                                hasCheckBoxes="no"
                                dataUpdater={this.tableUpdater}
                                sortedColumn={this.state.sortColumn}
                                sortedDirection={this.state.sortDirection}
                            />
                        </UILIB.Paper>
                    </UILIB.Column>}

                    {!!this.state.groups.length && <UILIB.Column xs={12}>
                        <UILIB.Paper className="mar-b0">
                            <h4 className="mar-b25">Group Summary</h4>

                            <UILIB.Row margin={0}>
                                {this.state.groups.map((group, index) => {

                                    var sendsTxt = "0";
                                    var softBouncesTxt = "0";
                                    var softBouncesPct = "";
                                    if (this.state.sendsLoaded) {

                                        var sends = 0;
                                        var softBounces = 0;
                                        this.state.sendsData.forEach(op => {
                                            if (group.mailServers.indexOf(op.MailServerId) > -1) {
                                                sends += Number(op.sends);
                                            }
                                        })
                                        this.state.bounceSummaryData.forEach(op => {
                                            if (group.mailServers.indexOf(op.MailServerId) > -1 && op.type == 0) {
                                                softBounces += Number(op.count);
                                            }
                                        })

                                        sendsTxt = NumberFormatFunctions.formatNumber(sends, 0);
                                        softBouncesTxt = NumberFormatFunctions.formatNumber(softBounces, 0);
                                        softBouncesPct = NumberFormatFunctions.formatNumber((softBounces / sends) * 100, 2) + "%";
                                    }
                                    return <UILIB.Column xs={12} sm={6} md={4} key={"gp_" + index}>
                                        <UILIB.Paper>
                                            <h4 className="mar-b25">{group.name}</h4>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ flex: 1, textAlignLast: "center" }}>
                                                    <div className="text-sml">Sends</div>
                                                    {this.state.sendsLoaded == false && <div className="loading mar-t10">Loading</div>}
                                                    {this.state.sendsLoaded == true && <div className="text-xl">{sendsTxt}</div>}
                                                </div>
                                                <div style={{ flex: 1, textAlignLast: "center" }}>
                                                    <div className="text-sml">Soft Bounces</div>
                                                    {this.state.sendsLoaded == false && <div className="loading mar-t10">Loading</div>}
                                                    {this.state.sendsLoaded == true && <div>
                                                        <div className="text-xl">
                                                            {softBouncesTxt}
                                                        </div>
                                                        <div className="text-sml">
                                                            ({softBouncesPct})
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </UILIB.Paper>
                                    </UILIB.Column>
                                })}
                                {(unassignedGroupSends > 0) && <UILIB.Column xs={12} sm={6} md={4}>
                                    <UILIB.Paper className="secondary mar-b0">
                                        <h4 className="mar-b25">Unassigned</h4>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ flex: 1, textAlignLast: "center" }}>
                                                <div className="text-sml">Sends</div>
                                                <div className="text-xl">{unassignedGroupSends}</div>
                                            </div>
                                            <div style={{ flex: 1, textAlignLast: "center" }}>
                                                <div className="text-sml">Soft Bounces</div>

                                                <div className="text-xl">
                                                    {unassignedGroupSoftBounces}
                                                </div>
                                                <div className="text-sml">
                                                    ({unassignedGroupSoftBouncesPct})
                                                </div>
                                            </div>
                                        </div>
                                    </UILIB.Paper>
                                </UILIB.Column>}
                            </UILIB.Row>
                        </UILIB.Paper>
                    </UILIB.Column>}


                    {!!this.state.mailservers.length && <UILIB.Column xs={12} className="mar-t30">

                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={12} margin={0}>
                                <UILIB.Paper>
                                    <div className="quickFlex" style={{ justifyContent: "space-between", alignContent: "center" }}>
                                        <h4 className="mar-b25">Log Monitor</h4>
                                        <div className="icon-refresh " style={{ fontSize: "30px", cursor: "pointer", lineHeight: '30px' }} onClick={() => {
                                            this.setState({ logSearchResultsLoading: true })
                                            this.getBounceTypeList();
                                            if (this.state.selectedLogBounceType) this.getBounceTypeList(this.state.selectedLogBounceType)
                                        }} />
                                    </div>
                                    {this.state.logSearchResultsLoading && <UILIB.LoadingIcons iconType="2" />}
                                    {!this.state.logSearchResultsLoading && <div>
                                        {(this.state.bounceTypeList.length <= 0) && <div>
                                            <strong>No primary bounce types found</strong><br />
                                            There have been no major bounce types detected over the past 7 days
                                        </div>}
                                        {(this.state.bounceTypeList.length > 0) &&
                                            <div>
                                                <div className="mar-b10">The following major bounce types have been found over the past 7 days. Please click one to view more details...</div>
                                                <div>
                                                    {this.state.bounceTypeList.map((bt, index) => {
                                                        let bTClassName = "grey"
                                                        if (bt.value.toLowerCase().indexOf("spamhaus") > -1 || bt.value.toLowerCase().indexOf("spamcop") > -1) {
                                                            bTClassName = "red";
                                                        }
                                                        if (this.state.selectedLogBounceType == bt.value) bTClassName = "primary";

                                                        return <UILIB.SquareChip key={"logSearchChip" + index} style={{ cursor: "pointer" }} className={bTClassName + " mar-r5 mar-b5"} value={bt.value} onClick={() => { this.logSearcher(bt.value) }} />
                                                    })}
                                                </div>
                                                {(this.state.selectedLogBounceType != "") &&
                                                    <div>
                                                        {(this.state.loadedBounceLog == false) && <UILIB.LoadingIcons iconType="2" />}
                                                        {(this.state.loadedBounceLog == true) && <UILIB.DataTable1
                                                            tableData={this.state.logSearchResults}
                                                            width="100%"
                                                            pageSize="20"
                                                            hasCheckBoxes="no"
                                                        />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    }
                                </UILIB.Paper>
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Column>
                    }

                </UILIB.Row>
                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />

            </div >
        );
    }
}

export default DedicatedServers;
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import PermissionChecker from '~/Classes/permissions'
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

class SiteTrackingNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabIndex: 0
        }

        this.tabChange = this.tabChange.bind(this);
        this.setInitialTab = this.setInitialTab.bind(this);
    }

    componentDidMount() {
        this.setInitialTab();
    }

    componentDidUpdate(prevProps) {
        if (prevProps != this.props) {
            if (prevProps.history && prevProps.history.location && this.props.history && this.props.history.location)
                if (prevProps.history.location.pathname != this.props.history.location.pathname) {
                    console.log("Changing", prevProps.history.location.pathname, this.props.history.location.pathname)
                    this.setInitialTab();
                }
        }
    }

    setInitialTab() {
        var foundTab = 0;
        var theCurrPath = this.props.history.location.pathname.split("/");
        if (theCurrPath.length > 3) {
            if (theCurrPath[3] == 'events') {
                foundTab = 1;
                this.setState({ tabIndex: 1 });
            }
            if (theCurrPath[3] == 'advanced') {
                foundTab = 1;
                this.setState({ tabIndex: 2 });
            }
        }
        if (foundTab === 0) {
            this.setState({ tabIndex: 0 });
        }
    }

    tabChange(event) {
        var index = event;

        if (event.currentTarget) {
            if (event.currentTarget.id !== undefined) index = event.currentTarget.id;
            if (event.currentTarget.value !== undefined) index = event.currentTarget.value;
        }

        index = String(index);
        this.setState({ tabIndex: index });
        switch (index) {
            case "0":
                this.props.history.push('/cp/trackers');
                break;
            case "1":
                this.props.history.push('/cp/trackers/events');
                break;
            case "2":
                this.props.history.push('/cp/trackers/advanced');
                break;
        }
    }

    render() {

        var thisMenuOptions = [
            {
                label: "Overview",
                index: 0
            }, {
                label: "Events",
                index: 1
            }, {
                label: "Advanced",
                index: 2
            }]


        return <div>
            <UILIB.Row className="mar-b25">
                <UILIB.Column xs={12} className="hide-md hide-lg" margin={0}>
                    <div className="form-group">
                        <UILIB.Select data={thisMenuOptions.map(o => {
                            return {
                                label: o.mobilelabel || o.label,
                                value: o.index
                            }
                        })} onChange={this.tabChange} value={this.state.tabIndex} />
                    </div>
                </UILIB.Column>

                <UILIB.Column xs={12} className="hide-xs hide-sm show-md show-lg sub-nav-holder" margin={0}>
                    {
                        thisMenuOptions.sort((a, b) => a.index - b.index).map((o) => {

                            return <UILIB.ButtonSimple
                                key={"tab_" + o.index}
                                className="button-simple-fullsize sub-nav-button"
                                selected={this.state.tabIndex == o.index}
                                onClick={() => this.tabChange(o.index)}>
                                {o.label}
                            </UILIB.ButtonSimple>
                        })
                    }
                </UILIB.Column >
            </UILIB.Row >
        </div>
    }
}

export default SiteTrackingNav;
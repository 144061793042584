import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFormatFunctions from '~/Classes/numberFormatFunctions';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import CampaignPieChart from '../../shared/graphs/pie';
import i18n from '~/i18n'
import Map from '../../shared/graphs/map'
import GenericStatsHolder from '../../shared/components/genericStatsHolder';
import InDepthChart from '../../shared/graphs/indepthChart'
import moment from 'moment';

//CONNECT TO STORE
@connect((store) => {
	return { siteMaster: store.siteMaster, user: store.user, accountMaster: store.accountMaster }
})

export default class CampaignsView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageLoaded: false,
			stats: {},
			geos: [],
			adEvents: []
		};
		this.getStats = this.getStats.bind(this);

	}

	componentDidMount() {
		this._ismounted = true;
		this.getStats()
	}

	componentDidUpdate(prevProps) {
		if (this.props.startDate != prevProps.startDate || this.props.endDate != prevProps.endDate) {
			this.getStats();
		}
	}

	componentWillUnmount() {
		this._ismounted = false;
	}

	async getStats() {
		try {
			console.log(this.props.adCampaign)
			let adEvents = await axios.get('/ads/campaigns/' + this.props.adCampaign.id + '/events?startDate=' + moment(this.props.campaign.startDate).format('YYYY-MM-DD') + '&endDate=' + moment().endOf("day").format('YYYY-MM-DD'))
			adEvents = adEvents.data;

			let campaignStats = await axios.get('/campaign/' + this.props.campaign.id + '/stats?startDate=' + this.props.startDate.format('YYYY-MM-DD') + '&endDate=' + this.props.endDate.format('YYYY-MM-DD'))
			campaignStats = campaignStats.data;
			let stats = campaignStats.Stats;
			let maps = campaignStats.Maps;
			this.setState({
				pageLoaded: true,
				adEvents,
				stats: stats,
				geos: {
					type: "FeatureCollection",
					features: maps.map(row => {
						return {
							type: "Feature",
							properties: {},
							geometry: {
								type: "Point",
								coordinates: [row.longitude, row.latitude]
							}
						}
					})
				}
			});
		}
		catch (err) {
			console.log(err);
		}
	}

	render() {

		if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />
		let campaign = this.props.campaign;

		let cpc = 0
		let totalClicks = this.state.stats["Total Clicks"];
		let totalSpend = this.props.adCampaign.spend;
		let spendSinceAdded = this.props.adCampaign.spendSinceAdded || totalSpend;
		if (totalClicks > 0 && totalSpend > 0) {
			cpc = spendSinceAdded / totalClicks;
		}
		cpc = NumberFormatFunctions.formatNumber(cpc, 2);

		let cpa = 0;
		let totalSalesRevenue = 0;
		if (this.state.adEvents && this.state.adEvents.length) {
			let registrationEventStat = this.state.adEvents.find(e => e.eventName == "Registration");
			if (registrationEventStat && registrationEventStat.count > 0) {
				cpa = spendSinceAdded / registrationEventStat.count;
			}
			let paymentEventStat = this.state.adEvents.find(e => e.eventName == "Payment");
			if (paymentEventStat && paymentEventStat.totalValue > 0) {
				totalSalesRevenue = paymentEventStat.totalValue
			}
		}
		cpa = NumberFormatFunctions.formatNumber(cpa, 2);
		return <div>
			<UILIB.Row>
				<UILIB.Column xs={12}>
					<UILIB.Paper>
						<InDepthChart
							title={i18n.t('campaigns:overview.clicksTimeline')}
							type="clicked"
							campaignId={this.props.campaign.id}
							startDate={this.props.startDate}
							endDate={this.props.endDate}
							searchText={""}
							changedDate={this.props.changedDate}
						/>
					</UILIB.Paper>
				</UILIB.Column>
			</UILIB.Row>

			<UILIB.Row className="mar-b25">
				<UILIB.Column xs={12} sm={12} md={6}>
					<UILIB.Paper className="mar-b20" style={{ height: "100%" }}>
						<h4 className="mar-b25">Campaign Details</h4>
						<UILIB.Row>
							<UILIB.Column xs={12} sm={12} md={6} margin="0">
								<label className="text-heavy">{i18n.t('campaigns:overview.dateStarted')}</label>
								<p>{DateTimeFunctions.formatDateTime(campaign.startDate, '1')}</p>
							</UILIB.Column>
							<UILIB.Column xs={12} sm={12} md={6} margin="0">
								<label className="text-heavy">Date Added to {this.props.siteMaster.siteName}</label>
								<p>{DateTimeFunctions.formatDateTime(campaign.createdAt, '1')}</p>
							</UILIB.Column>
							<UILIB.Column xs={12} sm={12} md={6} margin="0">
								<label className="text-heavy">{i18n.t('campaigns:overview.dateFinished')}</label>
								<p>{campaign.finishedDate ? DateTimeFunctions.formatDateTime(campaign.finishedDate, '1') : "Not Finished"}</p>
							</UILIB.Column>

							<UILIB.Column xs={12} sm={12} md={6} margin="0">
								<label className="text-heavy">Objective</label>
								<p>{this.props.adCampaign.objective}</p>
							</UILIB.Column>
							<UILIB.Column xs={12} sm={12} md={6} margin="0">
								<label className="text-heavy">Spend - All Time</label>
								<p>{this.props.accountMaster.accountMaster.currencySymbol + NumberFormatFunctions.formatNumber(this.props.adCampaign.spend, 2)}</p>
							</UILIB.Column>
							<UILIB.Column xs={12} sm={12} md={6} margin="0">
								<label className="text-heavy">Spend - Since Added to {this.props.siteMaster.siteName}</label>
								<p>{this.props.accountMaster.accountMaster.currencySymbol + NumberFormatFunctions.formatNumber(this.props.adCampaign.spendSinceAdded, 2)}</p>
							</UILIB.Column>
						</UILIB.Row>
					</UILIB.Paper>
				</UILIB.Column>

				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
					<GenericStatsHolder
						outerStyle={{ height: "100%" }}
						value={this.props.accountMaster.accountMaster.currencySymbol + NumberFormatFunctions.formatNumber(this.props.adCampaign.spendSinceAdded, 2)}
						subHeader={"The total amount spent on this campaign since it was added to " + this.props.siteMaster.siteName}
						limit={-1}
						color={this.props.siteMaster.colours['$status-green']}
						header={"Spend"}
						additionalInfo={<div><strong>{this.props.accountMaster.accountMaster.currencySymbol + NumberFormatFunctions.formatNumber(this.props.adCampaign.spend, 2)} </strong> spent all time</div>}
					/>
				</UILIB.Column>

				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
					<GenericStatsHolder
						outerStyle={{ height: "100%" }}
						value={this.props.accountMaster.accountMaster.currencySymbol + NumberFormatFunctions.formatNumber(totalSalesRevenue, 2)}
						subHeader={"The total sales originating from this campaign"}
						limit={-1}
						color={this.props.siteMaster.colours['$status-green']}
						header={"Revenue"}
					/>
				</UILIB.Column>
			</UILIB.Row>
			<UILIB.Row className="mar-b25">
				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
					<GenericStatsHolder
						value={this.state.stats["Unique Clicks"]}
						subHeader={"The number of unique visitors that have clicked your ad"}
						limit={-1}
						color={this.props.siteMaster.colours['$status-green']}
						header={i18n.t('campaigns:overview.uniqueClicks')}
						link={() => { this.props.history.push('/cp/campaigns/viewad/' + this.props.campaign.id + '/visitors') }}
						linkText={i18n.t('campaigns:overview.view')}
						linkIcon="arrowRight"
						outerStyle={{ height: "100%" }}
					/>
				</UILIB.Column>
				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
					<GenericStatsHolder
						value={this.state.stats["Total Clicks"]}
						subHeader={"The total number of non-unique clicks your ad has had"}
						limit={-1}
						color={this.props.siteMaster.colours['$status-green']}
						header={i18n.t('campaigns:overview.totalClicks')}
						link={() => { this.props.history.push('/cp/campaigns/viewad/' + this.props.campaign.id + '/visitors') }}
						linkText={i18n.t('campaigns:overview.view')}
						linkIcon="arrowRight"
						additionalInfo={<div><strong>{NumberFormatFunctions.formatNumber(this.props.adCampaign.originClicks, 2)} </strong> clicks all time</div>}
						outerStyle={{ height: "100%" }}
					/>
				</UILIB.Column>
				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
					<GenericStatsHolder
						value={this.props.accountMaster.accountMaster.currencySymbol + cpc}
						subHeader={"The average cost of each of the ad clicks we've detected"}
						limit={-1}
						color={this.props.siteMaster.colours['$status-green']}
						header={"CPC"}
						outerStyle={{ height: "100%" }}
					/>
				</UILIB.Column>
				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
					<GenericStatsHolder
						value={this.props.accountMaster.accountMaster.currencySymbol + cpa}
						subHeader={"The average cost to acquire a new customer from this ad"}
						limit={-1}
						color={this.props.siteMaster.colours['$status-green']}
						header={"CPA"}
						outerStyle={{ height: "100%" }}
					/>
				</UILIB.Column>
			</UILIB.Row>
			<UILIB.Row>
				<UILIB.Column xs={12} sm={6} md={3}>
					<UILIB.Paper>
						<CampaignPieChart

							startDate={this.props.startDate}
							endDate={this.props.endDate}
							campaignId={this.props.campaign.id}
							stat="country"
							groupByData={{
								value: 'DAYOFWEEK',
								label: i18n.t('campaigns:overview.groupWeekday'),
								color: 'red'
							}}
							unique={"true"}
						/>
					</UILIB.Paper>
				</UILIB.Column>
				<UILIB.Column xs={12} sm={6} md={3}>
					<UILIB.Paper>
						<CampaignPieChart
							startDate={this.props.startDate}
							endDate={this.props.endDate}
							campaignId={this.props.campaign.id}
							stat="device"
							groupByData={{
								value: 'DAYOFWEEK',
								label: i18n.t('campaigns:overview.groupWeekday'),
								color: 'red'
							}}
							unique={"true"}
						/>
					</UILIB.Paper>
				</UILIB.Column>
				<UILIB.Column xs={12} sm={12} md={6}>
					<Map data={this.state.geos} height={570} />
				</UILIB.Column>
			</UILIB.Row>

		</div >
	}
}
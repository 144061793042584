import React from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import DateTimeFunctions from '../../../Classes/dateTimeFunctions';
import i18n from '~/i18n';
import PermissionChecker from '~/Classes/permissions'
import AccountColours from './colours'
import * as siteMaster from '~/data/actions/siteActions';
import ValidateCountry from './validateCountry'
import SupportDrawer from './supportDrawer';
import CloseAccountDrawer from './closeAccountDrawer';
import Industries from '../../../Classes/industries';
import moment from 'moment';
import BrandAnalyzerExample1 from '../includes/brandanalyzer/themeSelector/exampleTemplate1';

@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class CompanyDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoaded: false,
            pageLoaded: false,
            avatarUploadProgress: 0,
            accountMasterData: {
                accountName: "",
                address1: "",
                city: "",
                county: "",
                country: "",
                postCode: ''
            },
            alertMessage: '',
            alertOpen: false,
            saving: false,
            countryData: [],
            inputErrors: {
                accountName: "",
                address1: "",
                city: "",
                county: "",
                country: "",
                postCode: ''
            },
            writeAccess: true
        };
        this.loadCompany = this.loadCompany.bind(this);
        this.updateCompanyDetails = this.updateCompanyDetails.bind(this);
        this.clickAvatarUpload = this.clickAvatarUpload.bind(this);
        this.goAvatarUpload = this.goAvatarUpload.bind(this);
        this.goAvatarUploadProgress = this.goAvatarUploadProgress.bind(this);
        this.updateInput = this.updateInput.bind(this);
        this.saveAccountName = this.saveAccountName.bind(this);
        this.saveAddressDetails = this.saveAddressDetails.bind(this);
        this.setColours = this.setColours.bind(this)
        this.closeAccount = this.closeAccount.bind(this)
        this.openSupportDrawer = this.openSupportDrawer.bind(this);
    }

    componentDidMount() {
        //see if we have write access to the company
        var writeAccess = PermissionChecker("company", this.props.permissionStore.permissions, "write");
        this.setState({ writeAccess: writeAccess }, this.loadCompany)
    }

    //Account stuff
    loadCompany() {
        var accMasterDat = [];
        axios
            .get('/accountMaster')
            .then((res) => {
                var accountMasterData = res.data.AccountMaster;
                accountMasterData.createdAtNew = DateTimeFunctions.formatDateTime(accountMasterData.createdAt, 2);
                accountMasterData.createdAtDiff = DateTimeFunctions.timeBetween(accountMasterData.createdAt);
                if (accountMasterData.address1 === null) { accountMasterData.address1 = "" };
                if (accountMasterData.address2 === null) { accountMasterData.address2 = "" };
                if (accountMasterData.address3 === null) { accountMasterData.address3 = "" };
                if (accountMasterData.city === null) { accountMasterData.city = "" };
                if (accountMasterData.county === null) { accountMasterData.county = "" };
                if (accountMasterData.country === null) { accountMasterData.country = "" };
                if (!accountMasterData.options) accountMasterData.options = {}
                if (!accountMasterData.options.colours) accountMasterData.options.colours = []

                accMasterDat = accountMasterData;
                return axios.get('/countries');
            })
            .then((countryDat) => {
                //map country data to name/value object ready for select box
                const countryData = countryDat.data.Countries.map(thisLine => ({ label: thisLine.name, value: thisLine.name, isEU: thisLine.isEU, code: thisLine.code }))
                this.setState({ accountMasterData: accMasterDat, countryData: countryData, pageLoaded: true });
            });
    }

    //avatar stuff
    clickAvatarUpload() {
        this.refs.avatarUploadInput.click();
    }

    goAvatarUpload(event) {

        var self = this;
        self.setState({ alertOpen: true, alertMessage: i18n.t('company:my.uploading') })
        var thisFile = event.currentTarget.files[0];
        let data = new FormData();
        data.append('file', thisFile);
        axios.post('/accountMaster/avatar', data, {
            onUploadProgress: progressEvent => self.goAvatarUploadProgress(progressEvent)
        })
            .then(function (res) {
                self.setState({ alertOpen: true, alertMessage: i18n.t('company:my.uploadFinished') }, self.loadCompany)
            })
            .catch(error => {
                self.setState({ avatarUploadProgress: 0, alertOpen: true, alertMessage: i18n.t('company:my.uploadError') })
            })

    }
    goAvatarUploadProgress(uploadEvent) {
        var loaded = uploadEvent.loaded;
        var total = uploadEvent.total;
        var totalLoaded = Math.round((loaded / total) * 100);
        this.setState({ avatarUploadProgress: totalLoaded });
    }

    //inputs and stuff
    updateInput(event, options) {

        var accountData = this.state.accountMasterData;
        var value = event.target.value

        if (event.target.type == "checkbox") {
            value = event.target.checked ? true : false
        }
        if (options) {
            accountData.options[event.target.name] = value
        } else {
            accountData[event.target.name] = value
        }
        this.setState({ accountMasterData: accountData });
    }

    //Save Account Name
    saveAccountName() {
        var errorState = this.state.inputErrors;
        errorState.accountName = "";

        var failed = 0;
        if (this.state.accountMasterData.accountName.length < 3) {
            errorState.accountName = i18n.t('company:my.nameError');
            failed = 1;
        }

        if (failed === 0) {
            this.updateCompanyDetails();
        }
        else {
            this.setState({ inputErrors: errorState });
        }
    }

    //Save Address Details
    saveAddressDetails() {
        var errorState = this.state.inputErrors;
        errorState.address1 = "";
        errorState.city = "";
        errorState.county = "";
        errorState.country = "";

        var failed = 0;
        if (this.state.accountMasterData.address1 === null || this.state.accountMasterData.address1.length < 1) {
            failed = 1;
            errorState.address1 = i18n.t('company:my.houseError');
        }
        if (this.state.accountMasterData.city === null || this.state.accountMasterData.city.length < 1) {
            failed = 1;
            errorState.city = i18n.t('company:my.cityError');
        }
        if (this.state.accountMasterData.country === null || this.state.accountMasterData.country.length < 1) {
            failed = 1;
            errorState.country = i18n.t('company:my.countryError');
        }
        if (this.state.accountMasterData.postCode === null || this.state.accountMasterData.postCode.length < 1) {
            failed = 1;
            errorState.postCode = i18n.t('company:my.postCodeError');
        }
        console.log(errorState)

        if (failed === 0) {
            this.updateCompanyDetails();
        }
        else {
            this.setState({ inputErrors: errorState });
        }

    }

    updateCompanyDetails() {
        const country = this.state.countryData.find(c => c.value === this.state.accountMasterData.country)
        const data = { ...this.state.accountMasterData }
        if (country) {
            data.isEU = country.isEU
        }
        var self = this;
        if (data.isEU && this.props.accountMaster.accountMaster.country !== this.state.accountMasterData.country) {
            // country has changed and they are in the EU so we need to validate the VAT number
            const drawer = <ValidateCountry account={this.state.accountMasterData} country={country} reload={this.loadCompany} />
            return this.props.dispatch(siteMaster.alterSiteDrawer(true, true, 'right', drawer, true, 400))
        }
        self.setState({ alertOpen: true, alertMessage: i18n.t('company:my.alertSaving'), saving: true })
        axios.put('/accountMaster', data).then((res) => {
            self.setState({ alertOpen: true, alertMessage: i18n.t('company:my.alertSaved'), saving: false })
        }).catch(() => {
            self.setState({ alertOpen: true, alertMessage: i18n.t('company:my.alertError'), saving: false })
        });
    }

    setColours(colours) {
        let accountMasterData = this.state.accountMasterData
        let options = accountMasterData.options
        options.colours = colours
        this.setState({ accountMasterData })
    }

    closeAccount() {
        const drawer = <CloseAccountDrawer close={() => { this.props.dispatch(siteMaster.alterSiteDrawer(false, true, 'right', null)) }} />
        return this.props.dispatch(siteMaster.alterSiteDrawer(true, true, 'right', drawer, true, 600))
    }

    openSupportDrawer() {
        const drawer = <SupportDrawer finish={this.loadCompany} close={() => { this.props.dispatch(siteMaster.alterSiteDrawer(false, true, 'right', null)) }} />
        return this.props.dispatch(siteMaster.alterSiteDrawer(true, true, 'right', drawer, true, 600))
    }

    cancelSupport() {
        axios.put('/accountMaster/uninvitesupport').then(() => this.loadCompany())
    }

    render() {


        if (this.state.pageLoaded === false) {
            return <div><UILIB.LoadingIcons iconType="2" /></div>
        }
        else {
            const colours = this.state.accountMasterData.options.colours
            const country = this.state.countryData.find(c => c.value === this.state.accountMasterData.country) || {}
            const showVat = country.isEU || this.state.accountMasterData.country === 'United Kingdom'

            let supportUntil = this.state.accountMasterData.options ? this.state.accountMasterData.options.allowAdminLoginUntil : ''
            if (supportUntil && moment(supportUntil) < moment()) supportUntil = null

            let doneBrandAnalyzer = true;
            if (!this.props.accountMaster.accountMaster.options.brandAnalyzer || !this.props.accountMaster.accountMaster.options.brandAnalyzer.colours || !Array.isArray(this.props.accountMaster.accountMaster.options.brandAnalyzer.colours) || !this.props.accountMaster.accountMaster.options.brandAnalyzer.font || !this.props.accountMaster.accountMaster.options.brandAnalyzer.logo) {
                doneBrandAnalyzer = false;
            }

            return <div>

                <UILIB.Row>

                    <UILIB.Column xs={12} md={6} lg={6}>
                        <UILIB.Paper>

                            <div className="quickFlex mar-b25" style={{ justifyContent: "space-between" }}>

                                <div style={{ display: "flex", alignItems: "center" }} className="mar-25">
                                    <UILIB.Avatar style={{ height: "80px", width: "80px" }} className="mar-r15" src={this.state.accountMasterData.companyLogo} uploadProgress={this.state.avatarUploadProgress}></UILIB.Avatar>
                                    {this.state.writeAccess && <div>
                                        <UILIB.Button iconLeft={<UILIB.Icons icon="upload" />} onClick={this.clickAvatarUpload}>{i18n.t('company:my.uploadImage')}</UILIB.Button>
                                        <input type="file" ref="avatarUploadInput" style={{ display: 'none' }} onChange={this.goAvatarUpload} />
                                    </div>}
                                </div>
                                {this.props.siteMaster.siteId === 1 && this.state.writeAccess && <div style={{ textAlign: 'center' }}>
                                    <UILIB.Button className="hide-xs" iconLeft={<UILIB.Icons icon="lifering" />} text={`Invite ${this.props.siteMaster.siteName} Support`} onClick={this.openSupportDrawer} />
                                    {!!supportUntil && <div className='text-xsml text-grey text-heavy'>Support has access until {DateTimeFunctions.formatDateTime(supportUntil)}</div>}
                                    {!!supportUntil && <a className='text-xsml text-red text-heavy' onClick={this.cancelSupport.bind(this)}>Revoke Access</a>}
                                </div>}

                            </div>



                            <UILIB.Row>
                                <UILIB.Column xs={12} className="mar-b25">
                                    <UILIB.TextInput
                                        type="text"
                                        label={i18n.t('company:my.companyName')}
                                        name="accountName"
                                        error={this.state.inputErrors.accountName}
                                        value={this.state.accountMasterData.accountName}
                                        disabled={this.props.siteMaster.siteId === 1}
                                        explainer={this.props.siteMaster.siteId === 1 ? 'Please contact support to change your account name' : ''}
                                        onChange={this.updateInput} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="text"
                                        label={i18n.t('company:my.house')}
                                        name="address1"
                                        error={this.state.inputErrors.address1}
                                        value={this.state.accountMasterData.address1}
                                        onChange={this.updateInput} />

                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="text"
                                        label={i18n.t('company:my.address1')}
                                        name="address2"
                                        value={this.state.accountMasterData.address2}
                                        onChange={this.updateInput}
                                        error={this.state.inputErrors.address2}
                                    />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="text"
                                        label={i18n.t('company:my.address2')}
                                        name="address3"
                                        value={this.state.accountMasterData.address3}
                                        onChange={this.updateInput}
                                        error={this.state.inputErrors.address3} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="text"
                                        label={i18n.t('company:my.city')}
                                        name="city"
                                        error={this.state.inputErrors.city}
                                        value={this.state.accountMasterData.city}
                                        onChange={this.updateInput} />

                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="text"
                                        label={i18n.t('company:my.county')}
                                        name="county"
                                        error={this.state.inputErrors.county}
                                        value={this.state.accountMasterData.county}
                                        onChange={this.updateInput} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="text"
                                        label={i18n.t('company:my.postCode')}
                                        name="postCode"
                                        error={this.state.inputErrors.postCode}
                                        value={this.state.accountMasterData.postCode}
                                        onChange={this.updateInput}
                                    />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.Select
                                        data={this.state.countryData}
                                        label={i18n.t('company:my.country')}
                                        name="country"
                                        filter={true}
                                        error={this.state.inputErrors.country}
                                        value={this.state.accountMasterData.country}
                                        onChange={this.updateInput} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="text"
                                        label={i18n.t('company:my.phoneNumber')}
                                        name="phoneNumber"
                                        error={this.state.inputErrors.phoneNumber}
                                        value={this.state.accountMasterData.phoneNumber}
                                        onChange={this.updateInput}
                                    />
                                </UILIB.Column>

                                {showVat && <UILIB.Column xs={12} className="mar-b25">
                                    <UILIB.TextInput type="text" label="VAT Number" name="vatNumber" error={this.state.inputErrors.vatNumber} value={this.state.accountMasterData.vatNumber} onChange={this.updateInput} />
                                </UILIB.Column>}

                                <UILIB.Column xs={12} className="mar-b25">
                                    <UILIB.Select
                                        filter={true}
                                        data={Industries.industryArray()}
                                        type="text"
                                        label="Industry"
                                        name="industry"
                                        error={this.state.inputErrors.industry}
                                        value={this.state.accountMasterData.industry}
                                        onChange={this.updateInput} />
                                </UILIB.Column>

                                {this.state.writeAccess && <UILIB.Column xs={12}>
                                    <UILIB.Button saving={this.state.saving} className="button-primary" onClick={this.saveAccountName}>{i18n.t('company:my.nameSave')}</UILIB.Button>
                                </UILIB.Column>}
                            </UILIB.Row>

                        </UILIB.Paper>

                        <UILIB.Paper>
                            <h4 className="mar-b25">{i18n.t('company:my.defaults')}</h4>
                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={6} md={6} lg={6} className="mar-b25">
                                    <UILIB.Select
                                        label={i18n.t('company:my.delimiter')}
                                        name="defaultDelimiter"
                                        value={this.state.accountMasterData.options.defaultDelimiter}
                                        onChange={ev => this.updateInput(ev, true)}
                                        data={[{ label: 'Comma ,', value: ',' }, { label: 'Semi Colon ;', value: ';' }, { label: 'Pipe |', value: '|' }]}>

                                    </UILIB.Select>
                                </UILIB.Column>
                                {!this.props.siteMaster.hidePricing && <UILIB.Column xs={12} sm={6} md={6} lg={3} className="mar-b25">

                                    <UILIB.TextInput label={i18n.t('company:my.currency')} name="currency" disabled={true} value={this.state.accountMasterData.currency} />
                                </UILIB.Column>}
                            </UILIB.Row>
                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={6} md={6} lg={6} className="mar-b25">
                                    <UILIB.Toggle name="trackMailToLinks" after="Track mailto: links" value={this.state.accountMasterData.options.trackMailToLinks} onChange={ev => this.updateInput(ev, true)} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} md={6} lg={6} className="mar-b25">
                                    <UILIB.Toggle name="captchaEnabled" after="Enable CAPTCHA on link clicks" value={this.state.accountMasterData.options.captchaEnabled} onChange={ev => this.updateInput(ev, true)} />
                                </UILIB.Column>
                            </UILIB.Row>

                            {this.state.writeAccess && <UILIB.Button saving={this.state.saving} className="button-primary" onClick={this.updateCompanyDetails}>{i18n.t('save')}</UILIB.Button>}
                        </UILIB.Paper>
                    </UILIB.Column>

                    <UILIB.Column xs={12} md={6} lg={6}>


                        {doneBrandAnalyzer && <UILIB.Paper style={{ padding: "0px", overflow: "hidden", display: "flex", justifyContent: "space-between" }}>
                            <div style={{ padding: "32px", flex: "1 1" }}>
                                <div className="quickFlex mar-b25" style={{ alignContent: "center" }}>
                                    <UILIB.Icons icon="tickCircle" style={{ height: 32, width: 32 }} className="mar-r5" />
                                    <h4>{i18n.t('company:brandAnalyzer.alreadyRun.title')}</h4>
                                </div>
                                <div className="mar-b10 text-heavy">{i18n.t('company:brandAnalyzer.alreadyRun.intro1')}</div>
                                <div className="mar-b15">{i18n.t('company:brandAnalyzer.alreadyRun.intro2')}</div>
                                <div className="mar-b25">{i18n.t('company:brandAnalyzer.alreadyRun.intro3')}</div>

                                {this.state.writeAccess && <UILIB.Button
                                    text={i18n.t('company:brandAnalyzer.alreadyRun.button')}
                                    className="button-primary"
                                    onClick={() => { this.props.history.push("/cp/brandanalyzer") }} />}
                            </div>

                            <div
                                className="hide-xs"
                                style={{
                                    flex: "1 1",
                                    maxHeight: "200px"
                                }}>
                                <BrandAnalyzerExample1 colours={this.props.accountMaster.accountMaster.options.brandAnalyzer.colours} logo={this.props.accountMaster.accountMaster.options.brandAnalyzer.logo} font={this.props.accountMaster.accountMaster.options.brandAnalyzer.font} />
                            </div>
                        </UILIB.Paper>}
                        {!doneBrandAnalyzer && <UILIB.Paper style={{ padding: "0px", overflow: "hidden", display: "flex", justifyContent: "space-between" }}>
                            <div style={{ padding: "32px", flex: "1 1" }}>
                                <h4 className="mar-b25">{i18n.t('company:brandAnalyzer.notRun.title')}</h4>
                                <div className="mar-b15 text-heavy">{i18n.t('company:brandAnalyzer.notRun.intro1')}</div>
                                <div className="mar-b25">{i18n.t('company:brandAnalyzer.notRun.intro2')}</div>
                                {this.state.writeAccess && <UILIB.Button
                                    text={i18n.t('company:brandAnalyzer.notRun.button')}
                                    className="button-primary"
                                    onClick={() => { this.props.history.push("/cp/brandanalyzer") }} />}
                            </div>
                            <div
                                className="hide-xs"
                                style={{
                                    flex: "1 1",
                                    backgroundImage: "url(https://cdn1.ourmailsender.com/siteContent/brandAnalyzer/popupCover.png)",
                                    backgroundPosition: "center bottom",
                                    backgroundSize: "cover"
                                }} />
                        </UILIB.Paper>}


                        <UILIB.Paper>
                            <div className='flexSpaceBetween verticalAlign mar-b25'>
                                <h4>{i18n.t('company:my.defaultContactDetails')}</h4>
                                <div className="txtInput_explainer no-marg">You can use these in email templates</div>
                            </div>

                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        iconLeft={<UILIB.Icons icon="globe" color="#A9A9AD" />}
                                        type="url"
                                        label={i18n.t('company:my.websiteAddress')}
                                        name="websiteAddress"
                                        error={this.state.inputErrors.websiteAddress}
                                        value={this.state.accountMasterData.websiteAddress}
                                        onChange={this.updateInput}
                                    />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        iconLeft={<UILIB.Icons icon="envelope" color="#A9A9AD" />}
                                        type="email"
                                        label={i18n.t('company:my.primaryEmailAddress')}
                                        name="primaryEmailAddress"
                                        error={this.state.inputErrors.primaryEmailAddress}
                                        value={this.state.accountMasterData.primaryEmailAddress}
                                        onChange={this.updateInput}
                                    />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        iconLeft={<UILIB.Icons icon="facebook" color="#A9A9AD" />}
                                        type="url"
                                        label={i18n.t('company:my.facebookAddress')}
                                        name="facebookAddress"
                                        error={this.state.inputErrors.facebookAddress}
                                        value={this.state.accountMasterData.facebookAddress}
                                        onChange={this.updateInput} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="url"
                                        label={i18n.t('company:my.twitterAddress')}
                                        name="twitterAddress"
                                        error={this.state.inputErrors.twitterAddress}
                                        value={this.state.accountMasterData.twitterAddress}
                                        onChange={this.updateInput}
                                        iconLeft={<UILIB.Icons icon="twitter" color="#A9A9AD" />}
                                    />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="url"
                                        label={i18n.t('company:my.instagramAddress')}
                                        name="instagramAddress"
                                        error={this.state.inputErrors.instagramAddress}
                                        value={this.state.accountMasterData.instagramAddress}
                                        onChange={this.updateInput}
                                        iconLeft={<UILIB.Icons icon="instagram" color="#A9A9AD" />}
                                    />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="url"
                                        label={i18n.t('company:my.linkedInAddress')}
                                        name="linkedInAddress"
                                        error={this.state.inputErrors.linkedInAddress}
                                        value={this.state.accountMasterData.linkedInAddress}
                                        onChange={this.updateInput}
                                        iconLeft={<UILIB.Icons icon="linkedin" color="#A9A9AD" />}
                                    />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="url"
                                        label={i18n.t('company:my.youTubeAddress')}
                                        name="youTubeAddress"
                                        error={this.state.inputErrors.youTubeAddress}
                                        value={this.state.accountMasterData.youTubeAddress}
                                        onChange={this.updateInput}
                                        iconLeft={<UILIB.Icons icon="youtube" color="#A9A9AD" />}
                                    />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="url"
                                        label={i18n.t('company:my.tikTokAddress')}
                                        name="tikTokAddress"
                                        error={this.state.inputErrors.tikTokAddress}
                                        value={this.state.accountMasterData.tikTokAddress}
                                        onChange={this.updateInput}
                                        iconLeft={<UILIB.Icons icon="tikTok" color="#A9A9AD" />}
                                    />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="url"
                                        label={i18n.t('company:my.rssAddress')}
                                        name="rssAddress"
                                        error={this.state.inputErrors.rssAddress}
                                        value={this.state.accountMasterData.rssAddress}
                                        onChange={this.updateInput}
                                        iconLeft={<UILIB.Icons icon="rss" color="#A9A9AD" />}
                                    />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    <UILIB.TextInput
                                        type="url"
                                        label={i18n.t('company:my.defaultCTAAddress')}
                                        name="defaultCTAAddress"
                                        error={this.state.inputErrors.defaultCTAAddress}
                                        value={this.state.accountMasterData.defaultCTAAddress}
                                        onChange={this.updateInput}
                                        iconLeft={<UILIB.Icons icon="handPointingUp" color="#A9A9AD" />}
                                    />
                                </UILIB.Column>
                                {this.state.writeAccess && <UILIB.Column xs={12}>
                                    <UILIB.Button saving={this.state.saving} className="button-primary" onClick={this.updateCompanyDetails}>{i18n.t('save')}</UILIB.Button>
                                </UILIB.Column>}
                            </UILIB.Row>
                        </UILIB.Paper>

                        <UILIB.Paper>
                            <div className='flexSpaceBetween verticalAlign mar-b25'>
                                <h4>{i18n.t('company:my.accountColoursHeading')}</h4>
                                <div className="txtInput_explainer no-marg">{i18n.t('company:my.accountColoursDesc')}</div>
                            </div>
                            <AccountColours colours={colours} setColours={this.setColours} className="mar-b25" />
                            {this.state.writeAccess && <div>
                                <UILIB.Button saving={this.state.saving} className="button-primary" onClick={this.updateCompanyDetails}>{i18n.t('save')}</UILIB.Button>
                            </div>
                            }
                        </UILIB.Paper>


                        <div className="quickFlex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                            <div>{i18n.t('company:my.accountId')} {this.props.accountMaster.accountMaster.id}</div>
                            <UILIB.Button iconRight={<UILIB.Icons icon="bin" />} disabled={!this.state.writeAccess} className="button-red" onClick={this.closeAccount}>{i18n.t('account:my.closeAccount')}</UILIB.Button>
                        </div>
                    </UILIB.Column >

                </UILIB.Row >

                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
            </div >
        }
    };
};

import React from 'react';

export default class ModalWindow extends React.Component {
  render() {
    var theHeader = "";
    if (this.props.isOpen === false) {
      return null
    }
    if (this.props.title) {
      theHeader = <div className="modalWindowHolderHeader">{this.props.title}</div>
    }

    return (
      <div>
        <div className="modalWindowHolder">

          {theHeader}

          <div className="modalWindowHolderInner">
            {this.props.children}
          </div>
        </div>
        <div className="modalBackgroundHolder" onClick={e => this.close(e)} />
      </div>

    )
  }

  close(e) {
    e.preventDefault()

    if (this.props.onClose) {
      this.props.onClose()
    }
  }
}
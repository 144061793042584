import React from 'react'

export default class IconsEye extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 12C10.5304 12 11.0391 11.7893 11.4142 11.4142C11.7893 11.0391 12 10.5304 12 10C12 9.46957 11.7893 8.96086 11.4142 8.58579C11.0391 8.21071 10.5304 8 10 8C9.46957 8 8.96086 8.21071 8.58579 8.58579C8.21071 8.96086 8 9.46957 8 10C8 10.5304 8.21071 11.0391 8.58579 11.4142C8.96086 11.7893 9.46957 12 10 12Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M2 10C3.06812 6.52257 6.24565 4 10 4C13.7543 4 16.9319 6.52257 18 10C16.9319 13.4774 13.7543 16 10 16C6.24565 16 3.06812 13.4774 2 10ZM13.3536 10C13.3536 10.9093 13.0003 11.7814 12.3714 12.4244C11.7424 13.0673 10.8894 13.4286 10 13.4286C9.11057 13.4286 8.25757 13.0673 7.62865 12.4244C6.99973 11.7814 6.64641 10.9093 6.64641 10C6.64641 9.09069 6.99973 8.21862 7.62865 7.57563C8.25757 6.93265 9.11057 6.57143 10 6.57143C10.8894 6.57143 11.7424 6.93265 12.3714 7.57563C13.0003 8.21862 13.3536 9.09069 13.3536 10Z" fill={color} />
        </svg>


    }
}        
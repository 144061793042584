import axios from "../../data/http/axios"
import axios2 from 'axios'
import React, { Fragment, useEffect, useRef, useState } from "react"
import TextInput from '../UI/Forms/TextInput/TextInput'
import ClickAway from '../ClickAway'
import Loading from '../LoadingIcons/loadingIcon1'

export default function AutoComplete({ outerClassName, outerStyle = {}, label, reset = 0, labelOptions, explainer, className, url = '', parser = () => { }, onChange = () => { }, onKeyUp = () => { }, onEnterPress = () => { }, setResults = () => { }, focus = false, inputName = "", placeholder = "", blank = "", defaultValue = "", error = "", iconRight = "" }) {

    const timer = useRef(null)
    const cancelToken = useRef(axios2.CancelToken)
    const source = useRef(null)
    const input = useRef(null)

    const [firstLoad, setFirstLoad] = useState(true)
    const [name, setName] = useState(defaultValue)
    const [data, setData] = useState([])
    const [show, setShow] = useState(false)
    const [searching, setSearching] = useState(false)

    const search = async (name) => {
        if (name && name.length) {
            setShow(true)
            setSearching(true)
            if (timer.current) {
                clearTimeout(timer.current)
            }
            timer.current = setTimeout(async () => {
                if (source.current) {
                    source.current.cancel('cancelled')
                }
                const newSource = cancelToken.current.source()
                source.current = newSource
                const results = await axios.get(url.replace('{{name}}', name).replace('{{id}}', ''), {
                    cancelToken: newSource.token
                })
                const data = parser(results.data)
                setData(data)
                setResults(data)
                setSearching(false)

            }, !name.length ? 0 : 200)
        }
    }

    const select = (option) => {
        setFirstLoad(false)
        setName(option.label)
        setShow(false)
        onChange(option)
    }

    const onChangeValue = e => {
        setFirstLoad(false)
        e.target.style = ''
        const value = e.target.value
        setName(value)
        search(value)
    }

    const onKeyUpValue = e => {
        e.preventDefault();
        e.stopPropagation();
        onKeyUp(e.target.value)
        return false;
    }

    useEffect(() => {
        (async () => {
            if (defaultValue && url.indexOf('{{id}}') > -1) {
                // search for that using the id instead of name
                setSearching(true)
                const results = await axios.get(url.replace('{{id}}', defaultValue).replace('{{name}}', ''))
                const data = parser(results.data)
                setData(data)
                setResults(data)
                setSearching(false)
                if (data.length) {
                    setName(data[0].label)
                    onChange(data[0])
                    setFirstLoad(false)
                }
            }
        })()
    }, [])

    useEffect(() => {
        if (reset) setName(defaultValue)
    }, [reset])

    let hideBox = false
    if (show && !data.length && !searching && !blank) hideBox = true


    if ((label && labelOptions) || labelOptions) {
        label = <div className="txtInput_label_flex">
            <div className="txtInput_label">{label}</div>
            <div className="txtInput_labelOptions">{labelOptions}</div>
        </div>
    } else if (label) {
        label = <div className="txtInput_label">{label}</div>
    }

    if (explainer) {
        explainer = <div className="txtInput_explainer">{explainer}</div>
    }

    const styles = { position: 'relative', ...outerStyle }

    const found = data.find(d => d.label === name)

    return (

        <Fragment>
            <form style={styles} className={outerClassName}>
                {label}
                <TextInput
                    className={className}
                    inputRef={input}
                    focus={focus}
                    onClick={() => search(name)}
                    value={firstLoad ? defaultValue : name}
                    onChange={onChangeValue}
                    onKeyUp={onKeyUpValue}
                    onEnterPress={onEnterPress}
                    onFocus={() => search(name)}
                    placeholder={placeholder}
                    iconRight={(found ? found.iconRight || undefined : undefined) || iconRight}
                    error={error}
                    autocomplete={'off'}
                    name={inputName}
                />
                {explainer}
                {
                    show && !hideBox && <ClickAway enabled={true} onClickAway={() => setShow(false)}>
                        <div className="autocomplete" style={{ width: input.current.offsetWidth }}>
                            {searching && <div style={{ padding: 10 }}>
                                <Loading iconType="2" />
                            </div>}
                            {!searching && data.map((c, i) => {
                                return <Option item={c} key={i} onSelect={() => select(c)} iconRight={c.iconRight} />
                            })}
                            {!data.length && !searching && <div>{blank}</div>}
                        </div>
                    </ClickAway>
                }
            </form >
        </Fragment >
    )
}

function Option({ item, onSelect, iconRight }) {
    return (
        <div className="item">
            <a onClick={() => onSelect(item)}>
                <div style={{ whiteSpace: 'nowrap', flexGrow: 1, textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.label}</div>
                {!!iconRight && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{iconRight}</div>}
            </a>
        </div>
    )
}
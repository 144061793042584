// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".F_k7pUhjKivVy_uIQbnQ{display:flex;flex-direction:column;gap:12px}.KFvLf7OwpSSP3s2WaiKb{display:flex;align-items:center;gap:12px;padding:14px 16px;border:1px solid var(--grey-200);border-radius:8px;position:relative}.BBAbcykkATOovcW9b015{color:var(--grey-500);margin-left:4px;height:20px;width:20px}.MmYciNVcxgD3Yx_Wc64Y{text-decoration:none}.MmYciNVcxgD3Yx_Wc64Y::after{content:\"\";position:absolute;inset:0}.gwSTQBCO0cVgGIGlnpg5{font-size:13px;line-height:normal;letter-spacing:normal}.UZKEXSNW9amqnKOCToB9{font-size:12px;color:var(--grey-800)}.q0NeDUBMDocawUNRvqkD{margin-left:auto}.MmEAEYfeTt9363s0ddI1{align-self:flex-start}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/inboxSettings/channels/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CACA,gCAAA,CACA,iBAAA,CACA,iBAAA,CAGF,sBACE,qBAAA,CACA,eAAA,CACA,WAAA,CACA,UAAA,CAGF,sBACE,oBAAA,CAEA,6BACE,UAAA,CACA,iBAAA,CACA,OAAA,CAIJ,sBACE,cAAA,CACA,kBAAA,CACA,qBAAA,CAGF,sBACE,cAAA,CACA,qBAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,qBAAA","sourcesContent":[".channelsList {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.teaser {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  padding: 14px 16px;\n  border: 1px solid var(--grey-200);\n  border-radius: 8px;\n  position: relative;\n}\n\n.teaserIcon {\n  color: var(--grey-500);\n  margin-left: 4px;\n  height: 20px;\n  width: 20px;\n}\n\n.teaserLink {\n  text-decoration: none;\n\n  &::after {\n    content: \"\";\n    position: absolute;\n    inset: 0;\n  }\n}\n\n.teaserName {\n  font-size: 13px;\n  line-height: normal;\n  letter-spacing: normal;\n}\n\n.teaserType {\n  font-size: 12px;\n  color: var(--grey-800);\n}\n\n.teaserBadge {\n  margin-left: auto;\n}\n\n.addButton {\n  align-self: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"channelsList": "F_k7pUhjKivVy_uIQbnQ",
	"teaser": "KFvLf7OwpSSP3s2WaiKb",
	"teaserIcon": "BBAbcykkATOovcW9b015",
	"teaserLink": "MmYciNVcxgD3Yx_Wc64Y",
	"teaserName": "gwSTQBCO0cVgGIGlnpg5",
	"teaserType": "UZKEXSNW9amqnKOCToB9",
	"teaserBadge": "q0NeDUBMDocawUNRvqkD",
	"addButton": "MmEAEYfeTt9363s0ddI1"
};
export default ___CSS_LOADER_EXPORT___;

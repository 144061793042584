import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';
import { useHistory } from 'react-router-dom'
import axios from '../../../../../../data/http/axios'
import UILIB from '../../../../../../Common-Objects/Lib'
import DateTimeFunctions from '../../../../../../Classes/dateTimeFunctions';
import { SupportedApps } from '../../../social/create/shared';
import i18n from '../../../../../../i18n';
import FormatNumberFuncs from '../../../../../../Classes/numberFormatFunctions';

export default function SocialCamapaignOverview({ campaign, apps }) {
    const site = useSelector(state => state.siteMaster)
    const [posts, setPosts] = useState(null)
    const history = useHistory()

    const getPosts = async () => {
        const res = await axios.get(`/campaign/${campaign.id}/socialpost`)
        setPosts(res.data)
    }

    useEffect(() => {
        getPosts()
    }, [campaign.id])

    if (!posts) return <UILIB.LoadingIcons />

    const totals = { outreach: 0, impressions: 0, engagement: 0 }
    const outreachData = []
    const impressionsData = []
    const engagementData = []
    for (const post of posts) {
        if (!post.SocialAccount) continue
        totals.outreach += post.outreach
        totals.impressions += post.impressions
        totals.engagement += post.engagement
        const baseApp = SupportedApps.find(a => a.code === post.SocialAccount.type)
        outreachData.push({
            name: i18n.t('social:channels.' + baseApp.code),
            value: post.outreach,
            fill: baseApp.colour
        })
        impressionsData.push({
            name: i18n.t('social:channels.' + baseApp.code),
            value: post.impressions,
            fill: baseApp.colour
        })
        engagementData.push({
            name: i18n.t('social:channels.' + baseApp.code),
            value: post.engagement,
            fill: baseApp.colour
        })
    }

    return (
        <UILIB.Row>
            <UILIB.Column xs={4}>
                <Stat
                    value={totals.outreach}
                    data={outreachData}
                    title={i18n.t('social:view.overview.outreach')}
                    subtitle={i18n.t('social:view.overview.outreachDesc')}
                />
            </UILIB.Column>
            <UILIB.Column xs={4}>
                <Stat
                    value={totals.impressions}
                    data={impressionsData}
                    title={i18n.t('social:view.overview.impressions')}
                    subtitle={i18n.t('social:view.overview.impressionsDesc')}
                />
            </UILIB.Column>
            <UILIB.Column xs={4}>
                <Stat
                    value={totals.engagement}
                    data={engagementData}
                    title={i18n.t('social:view.overview.engagement')}
                    subtitle={i18n.t('social:view.overview.engagementDesc')}
                />
            </UILIB.Column>

            {posts.map(post => {
                if (!post.SocialAccount) return null
                const app = apps.find(a => a.id === post.AccountApplicationId)
                const baseApp = SupportedApps.find(a => a.code === post.SocialAccount.type)
                const url = baseApp?.logo || app.Application.appLogoUri
                const outreachPercent = Math.round((post.outreach / totals.outreach) * 100) || 0
                const impressionsPercent = Math.round((post.impressions / totals.impressions) * 100) || 0
                const engagementPercent = Math.round((post.engagement / totals.engagement) * 100) || 0
                return (
                    <UILIB.Column xs={12} sm={12} md={6} lg={6}>
                        <UILIB.Paper>
                            <div className="quickFlex mar-b20" style={{ justifyContent: 'space-between' }}>
                                <div className="quickFlex" style={{ alignItems: 'center' }}>
                                    {!!baseApp && <UILIB.Icons icon={baseApp.icon} style={{ height: 24, width: 24, marginRight: 10 }} />}
                                    {!baseApp && <UILIB.Avatar src={url} style={{ height: 24, width: 24, marginRight: 10 }} />}
                                    <div className="mar-r10" style={{ fontSize: 16, lineHeight: '24px', fontWeight: 600 }}>{i18n.t('social:channels.' + baseApp.code)}</div>
                                    <UILIB.SquareChip>{post.SocialAccount.name}</UILIB.SquareChip>
                                </div>
                                <div className="quickFlex">
                                    <UILIB.Button className="button-sml mar-r10" onClick={() => history.push(`/cp/company/integrations/apps/social/${post.SocialAccount.id}`)} text={"Follower History"} />
                                    {!!baseApp.click && <UILIB.Button className="button-primary button-sml" onClick={() => baseApp.click(post)} iconRight={<UILIB.Icons icon="external" />}>View Post</UILIB.Button>}
                                </div>
                            </div>
                            {!baseApp?.hasStats ? <UILIB.Paper className="paper-warning text-center">
                                {i18n.t('social:view.overview.warning', { appName: baseApp.name })}
                            </UILIB.Paper> : <div className="row">
                                <div className="col-xs">
                                    <UILIB.Paper>
                                        <div className="mar-b10 text-md">{i18n.t('social:view.overview.outreach')}</div>
                                        {!baseApp?.hideOutreach && <div className="text-heavy text-lg quickFlex">{post.outreach} <UILIB.SquareChip className="mar-l10">{outreachPercent}%</UILIB.SquareChip></div>}
                                        {baseApp?.hideOutreach && <div className="text-heavy text-lg quickFlex"> <UILIB.SquareChip>Unavailable</UILIB.SquareChip></div>}
                                    </UILIB.Paper>
                                </div>
                                <div className="col-xs">
                                    <UILIB.Paper>
                                        <div className="mar-b10 text-md">{i18n.t('social:view.overview.impressions')}</div>
                                        {!baseApp?.hideImpressions && <div className="text-heavy text-lg quickFlex">{post.impressions} <UILIB.SquareChip className="mar-l10">{impressionsPercent}%</UILIB.SquareChip></div>}
                                        {baseApp?.hideImpressions && <div className="text-heavy text-lg quickFlex"> <UILIB.SquareChip>Unavailable</UILIB.SquareChip></div>}
                                    </UILIB.Paper>
                                </div>
                                <div className="col-xs">
                                    <UILIB.Paper>
                                        <div className="mar-b10 text-md">{i18n.t('social:view.overview.engagement')}</div>
                                        <div className="text-heavy text-lg quickFlex">{post.engagement} <UILIB.SquareChip className="mar-l10">{engagementPercent}%</UILIB.SquareChip></div>
                                    </UILIB.Paper>
                                </div>
                            </div>}
                            {baseApp?.code !== 'FACEBOOK' && baseApp?.code !== 'INSTAGRAM' && <div className="text-grey" style={{ fontSize: 12, lineHeight: '16px' }}>{i18n.t('social:view.overview.lastChecked', { date: DateTimeFunctions.formatDateTime(post.updatedAt) })}</div>}
                        </UILIB.Paper>
                    </UILIB.Column>
                )
            })}


        </UILIB.Row>
    )
}

function Stat({ title, subtitle, value, data }) {
    return <UILIB.Paper className="with_header">
        <div className="paper_header border-bottom-plain" style={{ padding: 32 }}>
            <div style={{ fontSize: 20, fontWeight: 600, lineHeight: '24px', marginBottom: 8 }}>{title}</div>
            <div style={{ fontSize: 14, lineHeight: '20px', marginBottom: 16, color: '#595C61' }}>{subtitle}</div>
            <div style={{ fontSize: 32, fontWeight: 600, lineHeight: '32px' }}>{FormatNumberFuncs.formatNumber(value)}</div>
        </div>
        <div className="paper_content">
            <div className="quickFlex">
                <ResponsiveContainer height={240}>
                    <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                        <Pie data={data} dataKey="value" nameKey="name" innerRadius={60} startAngle={-270} >
                            {data.map((entry, index) => <Cell className="pie-cell" key={`cell-${title}-${index}`} fill={entry.fill} />)}
                        </Pie>
                        <Legend verticalAlign="bottom" iconType="circle" iconSize={11} content={UILIB.CustomPieLegend} />
                        <Tooltip content={<UILIB.CustomTooltip overrideColor={true} />} />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    </UILIB.Paper>
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import EmailTools from '~/Classes/emailValidator';
import i18n from '~/i18n';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class AddDomainCampaignPop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: "",
            inputErrors: {
                emailAddress: "",
                sentEmail: false
            }
        };

        this.updateInput = this.updateInput.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
        this.allFinished = this.allFinished.bind(this);
        this.resendAuthEmail = this.resendAuthEmail.bind(this);
    }

    componentDidMount() {
        this.setState({ emailAddress: this.props.theEmail })
    }

    componentDidUpdate(prevProps) {
        if (this.props.theEmail != this.state.emailAddress) {
            this.setState({ emailAddress: this.props.theEmail })
        }
    }

    updateInput(ev) {
        this.setState({ [ev.currentTarget.name]: ev.currentTarget.value })
    }

    checkEmail() {
        var self = this;
        var thisEmail = this.state.emailAddress;
        var inputErrors = this.state.inputErrors;
        inputErrors.emailAddress = "";
        if (EmailTools.validateEmail(thisEmail) === false) {
            inputErrors.emailAddress = i18n.t('company:domains.alertInvalidEmail');
            this.setState({ inputErrors: inputErrors })
            return;
        }
        this.setState({ inputErrors: inputErrors });
        var data = { emailAddress: thisEmail };
        axios.post("/accountMaster/domain", data).then(res => {
            if (res.data.Required == false) {
                var errors = self.state.inputErrors;
                errors.emailAddress = i18n.t('company:domains.alertDomainCreate');
                self.setState({ inputErrors: errors })
            }
            else {
                self.setState({ sentEmail: true });
            }
        }).catch(err => {
            console.log(err);
            var theErr = self.state.inputErrors;
            theErr.emailAddress = err.data.error;
            self.setState({ inputErrors: theErr })
        })
    }

    resendAuthEmail(thisDomain) {
        var self = this;
        axios.get('/accountMaster/domain/resendAuthEmail/' + this.props.domainId)
            .then(() => {
                self.setState({ sentEmail: true });
            }).catch(err => {
                self.setState({ inputErrors: i18n.t('company:domains.alertErrorEmail') })
            })
    }

    allFinished() {
        this.props.onAddedDomain();
    }

    render() {

        return <div>
            {this.props.theType === "new" && <div>
                {!this.state.sentEmail && <div>
                    <h4 className="mar-b25">{i18n.t('company:domains.addDomain')}</h4>
                    <div className="mar-b25">
                        {i18n.t('company:domains.addDomainSubHeader')}
                    </div>



                    <UILIB.TextInput
                        label={i18n.t('company:domains.enterEmail')}
                        outerClassName="mar-b25"
                        focus={true} type="text"
                        placeholder={i18n.t('emailAddressPlaceholder')}
                        name="emailAddress"
                        error={this.state.inputErrors.emailAddress}
                        value={this.state.emailAddress}
                        onChange={this.updateInput} />

                    <div className="mar-b25">
                        <UILIB.Button
                            text={i18n.t('continue')}
                            className="button-primary" onClick={this.checkEmail}
                        />
                    </div>
                    <UILIB.Hint
                        style={{ margin: "0px" }}
                        iconLeft={<UILIB.Icons icon="questionCircle" />}>
                        <div>
                            Find out more about domain verification, <a onClick={() => { this.props.history.push('/cp/company/domains') }}>Click Here</a>
                        </div>
                    </UILIB.Hint>

                </div>
                }
                {this.state.sentEmail && <div>
                    <h4 className="mar-b25">We've sent you an email</h4>
                    <div className="mar-b25">
                        You should receive an email in the next few seconds. Click the link contained in the email to verify your email address and continue sending your campaign.
                    </div>
                    <UILIB.Button text={i18n.t('continue')} className="button-primary" onClick={this.allFinished} />
                </div>}

            </div>
            }

            {this.props.theType === "existing" && <div>
                {!this.state.sentEmail && <div>
                    <h4 className="mar-b25">Awaiting Verification</h4>
                    <div className="mar-b25">
                        We sent an email to <strong>{this.props.domainOrigEmailSentTo}</strong> containing a link you need to click to verify your Domain.<br /><br />
                        If you haven't received this email, click the following button to have another copy sent.
                    </div>

                    <UILIB.Button
                        text="Resend the email" className="button-primary" onClick={this.resendAuthEmail} />
                </div>
                }
                {this.state.sentEmail && <div>
                    <h4 className="mar-b25">We've sent you an email reminder</h4>
                    <div className="mar-b25">
                        You should receive an email in the next few seconds. Click the link contained in the email to verify your email address and continue sending your campaign.
                    </div>
                    <UILIB.Button
                        text={i18n.t('continue')}
                        className="button-primary" onClick={this.allFinished} />
                </div>}

            </div>
            }


        </div>
    }
};



import React, { useState } from "react";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import { useIsTranspond } from "~/Common-Objects/hooks/siteMaster";

// Not currently used anywhere as the legacy component has a ton of logic in it

export default function AuthenticateDomain({
  domain,
  domainType,
  className,
  onAuthenticate,
  onAuthenticated,
  ...rest
}) {
  const isTranspond = useIsTranspond();

  const [submitting, setSubmitting] = useState(false);

  async function handleAuthenticateClick(e) {
    e.preventDefault();

    setSubmitting(true);

    try {
      await onAuthenticate(domain, domainType);
      onAuthenticated && onAuthenticated(domainType);
    } finally {
      setSubmitting(false);
    }
  }

  const classes = classnames("authenticate-domain", className);

  return (
    <div className={classes} {...rest}>
      <h2 className="h4 mar-b15">Authenticate your Domain</h2>

      <p>
        In order for us to send emails that look just like they are coming from
        your own domain, you need to make two changes to your domain DNS and
        then click the “Authenticate your Domain” button below.
      </p>

      <ol className="mar-b25">
        <li>
          Add a CNAME record for <b>{domain.domainName}</b>, using the below
          details:
          <table className="simple-table mar-t15 mar-b25">
            <thead>
              <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <code className="code">CNAME</code>
                </td>
                <td>mp1._domainkey</td>
                <td>
                  <code className="code">dkim.ourmailsender.com</code>
                </td>
              </tr>
            </tbody>
          </table>
        </li>

        <li>
          Alter the existing TXT record that starts with{" "}
          <code className="code">v=spf</code> on {domain.domainName} to the
          value below:
          <br />
          <code className="code mar-t10">
            v=spf1 a mx include:spf.ourmailsender.com
          </code>
        </li>
      </ol>

      {isTranspond && (
        <div className="box mar-b25">
          <h3 className="text-md mar-b5">Need help?</h3>
          <p className="mar-b0">
            <a
              href="https://kb.transpond.io/article/60-how-to-manage-dns-in-popular-dns-control-panels"
              target="_blank"
            >
              Read our guide
            </a>{" "}
            on adding DNS records in popular domain providers such as{" "}
            <b>123-Reg, Cloudflare, GoDaddy, IONOS</b> and many more.
          </p>
        </div>
      )}

      <UILIB.Button
        type="button"
        className="button-primary"
        saving={submitting}
        onClick={handleAuthenticateClick}
      >
        Authenticate your Domain
      </UILIB.Button>
    </div>
  );
}

import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib';
import UpdateField from '../components/updateField';
import axios from '~/data/http/axios';
import AutoMergeTags from '~/pages/cp/includes/mergeTags/campaignMergeTags';
import AutomationFunctions from '../../automationFunctions'
import i18n from '~/i18n';

class PipedriveAutomationAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            app: undefined,
            ready: false,
            step: {},
            activityTypes: [],
            pipeDriveUsers: [],
            drawerContent: undefined,
            hasWorkflowEmails: false,
            workflowEmails: []
        }
        this.getAvailableFields = this.getAvailableFields.bind(this)
        this.saveStep = this.saveStep.bind(this)
        this.changeTriggerType = this.changeTriggerType.bind(this)
        this.updateActivity = this.updateActivity.bind(this);
        this.getActivityTypes = this.getActivityTypes.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.openMergeTags = this.openMergeTags.bind(this);
        this.closeMergeTags = this.closeMergeTags.bind(this);
        this.insertMergeTag = this.insertMergeTag.bind(this);
    }

    componentDidMount() {


        if (!this.props.step.stepOptions.hasOwnProperty("triggerType")) {
            this.props.step.stepOptions.triggerType = { value: "0", required: true }
        }
        if (!this.props.step.stepOptions.hasOwnProperty("activity")) {
            this.props.step.stepOptions.activity = {
                subject: { value: "", required: true },
                done: { value: "", required: false },
                type: { value: "", required: true },
                due_date: { value: "", required: false },
                due_time: { value: "", required: false },
                duration: { value: "", required: false },
                user_id: { value: "", required: false },
                note: { value: "", required: false }
            }
        }

        var workflowEmails = AutomationFunctions.getWorkflowEmails(this.props.step.guid, this.props.steps);
        var hasWorkflowEmails = false;
        var initialStepTriggerType = this.props.steps[0].triggerType.value;
        if (workflowEmails.length || (initialStepTriggerType == "5" || initialStepTriggerType == "8" || initialStepTriggerType == "9" || initialStepTriggerType == "10" || initialStepTriggerType == "11")) hasWorkflowEmails = true
        this.setState({ step: this.props.step, workflowEmails: workflowEmails, hasWorkflowEmails: hasWorkflowEmails }, this.getAvailableFields);
    }

    // options to give, update a field to a specific value (same as the actions in the integration)

    getAvailableFields() {
        var notAllowedFields = ["phone", "email", "add_time", "update_time", "owner_id", "open_deals_count", "visible_to", "next_activity_date", "last_activity_date", "won_deals_count", "lost_deals_count", "closed_deals_count", "activities_count", "done_activities_count", "undone_activities_count", "email_messages_count", "last_incoming_mail_time", "last_outgoing_mail_time"]
        function getType(type) {
            switch (type) {
                case 'enum':
                case 'set':
                    return 'select';
                case 'double':
                case 'monetary':
                case 'int':
                    return 'number';
                case 'date':
                    return 'date'
                default:
                    return 'text'
            }
        }
        var app = this.props.applications.find(a => a.Application.appName === 'Pipedrive')
        if (!app) return

        this.setState({
            app,
            fields: app.fields.filter(f => f.field_type !== 'org' && f.field_type !== 'people' && f.field_type !== 'address' && f.field_type !== 'timerange' && f.field_type !== 'daterange' && f.name.indexOf('organisation ') !== 0 && f.name !== 'id' && notAllowedFields.indexOf(f.key) === -1).map(f => {
                return {
                    label: f.name,
                    value: f.key,
                    type: getType(f.field_type),
                    options: f.options ? f.options.map(o => {
                        return {
                            value: o.id,
                            label: o.label
                        }
                    }) : undefined
                }
            })
        }, this.getActivityTypes)
    }

    getActivityTypes() {
        axios.get('/application/pipedrive/getactivitytypes').then(_acTypes => {
            this.setState({ activityTypes: _acTypes.data }, this.getUsers)
        })
    }

    getUsers() {
        axios.get('/application/pipedrive/getusers').then(_acTypes => {
            var userData = [{ label: "No Specific User", value: "0" }];
            var hasCustField = this.props.customFields.find(cf => cf.mergeTag == '_pipedrive_owner')
            if (hasCustField) {
                userData.push({ label: "The owning user of the contact", value: "pd_owner_" + hasCustField.id });
            }
            userData = userData.concat(_acTypes.data);
            this.setState({ ready: true, pipeDriveUsers: userData })
        })

    }

    changeTriggerType(event) {
        var step = this.state.step;
        step.complete = false;
        step.stepOptions.field.value = "";
        step.stepOptions.value.value = undefined;
        step.stepOptions.triggerType.value = event.target.value;
        this.props.step.stepOptions.triggerType.value = event.target.value;
        this.setState({ step: step })
    }

    updateActivity(event) {
        var theValue = event.target.value;
        var step = this.state.step;
        if (event.target.type == "checkbox") {
            if (event.target.checked) {
                theValue = "1"
            }
            else {
                theValue = "0"
            }
        }

        step.complete = false;
        step.stepOptions.activity[event.target.name].value = theValue;
        this.props.step.stepOptions.activity = step.stepOptions.activity;

        this.setState({ step: step })
    }

    saveStep() {
        var step = this.props.step
        var stepOptions = step.stepOptions
        var errors = {
            field: "",
            value: "",
            campaignid: "",
            activitySubject: "",
        }

        var foundError = false;
        if (this.state.step.stepOptions.triggerType.value == "0") {
            if (!step.stepOptions.field.value) {
                errors.field = 'Please Select a Field'
                foundError = true;
            }

            if (step.stepOptions.value.value === undefined) {
                errors.value = 'Please Select a Value'
                foundError = true;
            }

            if (['campaignName', 'subject', 'fromName', 'fromAddress', 'replyName', 'replyAddress', 'link'].indexOf(stepOptions.value.value) > -1) {
                if (!stepOptions.campaignId) {
                    errors.campaignId = 'Please select a campaign'
                    foundError = true;
                }
            } else {
                delete stepOptions.campaignId
            }
        }
        if (this.state.step.stepOptions.triggerType.value == "1") {
            if (!step.stepOptions.activity.subject.value || step.stepOptions.activity.subject.value.length < 3) {
                errors.activitySubject = 'Please Enter a Subject'
                foundError = true;
            }
            if (!step.stepOptions.activity.type.value) {
                errors.activityType = 'Please select an Activity Type'
                foundError = true;
            }
        }

        stepOptions.errors = errors

        if (foundError == false) {
            step.complete = true;
            this.props.saveStep()
        } else {
            this.setState({})
        }
    }

    openMergeTags(fieldName) {
        var drawerContent = <AutoMergeTags hasWorkflowEmails={this.state.hasWorkflowEmails} includeSubscriber={false} includeSubCustomFields={true} close={this.closeMergeTags} insertMergeTag={this.insertMergeTag} fieldName={fieldName} />
        this.setState({ drawerContent })
    }
    closeMergeTags() {
        this.setState({ drawerContent: undefined })
    }
    insertMergeTag(value, fieldName) {
        var step = this.state.step;
        step.stepOptions.activity[fieldName].value += " " + value;
        this.props.step.stepOptions.activity = step.stepOptions.activity;
        this.setState({ step: step, drawerContent: undefined })
    }
    render() {
        const { readOnly } = this.props;

        if (!this.state.ready) return <UILIB.LoadingIcons iconType="2" />
        if (this.state.drawerContent) return this.state.drawerContent;
        var errors = this.props.step.stepOptions.errors || {}

        const inputProps = {
            disabled: readOnly
        };

        return <div data-test="configure-pipedrive-integration-automation">
            <div>
                <h4 className="mar-b25">Pipedrive</h4>
                <div className="mar-b25">Update a Pipedrive field, or create Pipedrive Activity</div>

                <UILIB.Select outerClassName="mar-b25" data={[{ label: "Update a Pipedrive Field", value: "0" }, { label: "Add a Pipedrive Activity", value: "1" }]} value={this.state.step.stepOptions.triggerType.value} onChange={this.changeTriggerType} {...inputProps} />

                {(this.state.step.stepOptions.triggerType.value == "0") && <UpdateField fields={this.state.fields} step={this.props.step} steps={this.props.steps} />}
                {(this.state.step.stepOptions.triggerType.value == "1") && <div>
                    <UILIB.TextInput outerClassName="mar-b25" label="Activity Subject" labelOptions={<button className="a" onClick={() => { this.openMergeTags("subject") }} {...inputProps}>Merge Tags</button>} name="subject" onChange={this.updateActivity} value={this.state.step.stepOptions.activity.subject.value} error={errors.activitySubject} {...inputProps} />

                    <UILIB.Select outerClassName="mar-b25" label="Activity Type" name="type" data={this.state.activityTypes} placeholder="Select an Activity Type" value={this.state.step.stepOptions.activity.type.value} onChange={this.updateActivity} error={errors.activityType} {...inputProps} />

                    <UILIB.Select outerClassName="mar-b25" label="Assign to User" name="user_id" data={this.state.pipeDriveUsers} placeholder="Select a User" value={this.state.step.stepOptions.activity.user_id.value} onChange={this.updateActivity} {...inputProps} />


                    <UILIB.TextInput outerClassName="mar-b25" label="Notes" labelOptions={<button className="a" onClick={() => { this.openMergeTags("note") }} {...inputProps}>Merge Tags</button>} name="note" onChange={this.updateActivity} value={this.state.step.stepOptions.activity.note.value} {...inputProps} />

                    <UILIB.CheckBox outerClassName="mar-b25" label="Mark Activity Done/Complete" name="done" onClick={this.updateActivity} value={this.state.step.stepOptions.activity.done.value} checked={this.state.step.stepOptions.activity.done.value == "1" ? true : false} {...inputProps} />
                </div>}
            </div>

            <div className="automationStepSaveButtonHolder">
                {readOnly ? (
                    <>
                        <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                    </>
                ) : (
                    <UILIB.Button className="button-primary" text="Save" onClick={this.saveStep} />
                )}
            </div>

        </div >
    }
}

export default PipedriveAutomationAction;
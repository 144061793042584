import React from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import i18n from '~/i18n'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here

import AddEvent from './addEvent';
import DeleteEvent from './deleteEvent';

import Graph from './graph';

@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

export default class ViewCampaigns extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            tableData: [],
            loadingData: true,
            graphData: []
        }
        this.getEvents = this.getEvents.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.addedEvent = this.addedEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
    }

    componentDidMount() {
        this.getEvents();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate != this.props.startDate || prevProps.endDate != this.props.endDate) {
            this.setState({ loadingData: 1 }, () => { this.getEvents() });
        }
    }

    async getEvents() {
        this.setState({ loadingData: true })
        let events = await axios.get(`/accountMaster/tracker/events/${this.props.tracker.id}?startDate=${this.props.startDate.format("YYYY-MM-DD")}&endDate=${this.props.endDate.format("YYYY-MM-DD")}`)
        let tableData = events.data.map(row => {
            return [
                {
                    headerValue: "Id",
                    value: row.id,
                    width: 40
                },
                {
                    headerValue: "Event",
                    value: <strong>{row.name}</strong>,
                },
                {
                    headerValue: "Hits",
                    value: row.hits
                },
                {
                    headerValue: "Value",
                    value: row.value ? this.props.accountMaster.accountMaster.currencySymbol + NumberFunctions.formatNumber(row.value, 2) : ""
                },
                {
                    headerValue: "Values Available",
                    value: row.detectedValues ? row.detectedValues.map(m => {
                        return <UILIB.SquareChip className="mar-r5 mar-b5">{m.name}</UILIB.SquareChip>
                    }) : ""
                },
                {
                    headerValue: "",
                    value: row.system ? <><UILIB.SquareChip>System</UILIB.SquareChip></> : <UILIB.Icons icon="bin" color="#FF0000" onClick={() => { this.deleteEvent(row) }} />,
                    width: 50,
                    align: "center"
                }
            ]
        })

        let graphData = await axios.get(`/accountMaster/tracker/events/${this.props.tracker.id}/graph?startDate=${this.props.startDate.format("YYYY-MM-DD")}&endDate=${this.props.endDate.format("YYYY-MM-DD")}`)

        graphData = graphData.data.map(row => {
            row.date = DateTimeFunctions.formatDateTime(row.date, 2)
            return row;
        })
        this.setState({ tableData, loadingData: false, events: events.data, graphData: graphData })
    }

    addEvent() {
        var drawerContent = <AddEvent tracker={this.props.tracker} close={this.addedEvent} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    addedEvent() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
        this.getEvents();
    }

    deleteEvent(event) {
        var drawerContent = <DeleteEvent tracker={this.props.tracker} event={event} close={this.addedEvent} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }
    render() {
        if (this.state.loadingData == false && this.state.events.length <= 0) {
            return <>
                <UILIB.Paper>
                    <h4 className="mar-b10">You haven't added any events yet</h4>
                    <div className="mar-b25">You can use events to track when visitors do something on your website. For example registering.</div>
                    <UILIB.Button text="Add your first Event" onClick={this.addEvent} />
                </UILIB.Paper>
            </>
        }

        var visBetTxt = i18n.t('campaigns:sitetracking.trackingoverview.visitorsBetween');
        visBetTxt = visBetTxt.replace(/\[DATE1\]/g, DateTimeFunctions.formatDateTime(this.props.startDate, 2))
        visBetTxt = visBetTxt.replace(/\[DATE2\]/g, DateTimeFunctions.formatDateTime(this.props.endDate, 2))

        return <>
            <UILIB.Row>
                <UILIB.Column xs={12} margin={0}>
                    <UILIB.Paper>
                        <p className="text-grey text-sml">{visBetTxt}</p>
                        <Graph graphData={this.state.graphData} height={200} />
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} margin={0}>
                    <UILIB.Paper>
                        <div className="text-right mar-b25">
                            <UILIB.Button iconLeft={<UILIB.Icons icon="plus" />} text="Add an Event" onClick={this.addEvent} />
                        </div>
                        <UILIB.DataTable1
                            noResultsTxt={i18n.t('campaigns:sitetracking.trackingvistors.noDataFound')}
                            tableData={this.state.tableData}
                            dataUpdater={() => { }}
                            width="100%"
                            pageSize="100"
                            hasCheckBoxes="no"
                            sortedColumn={""}
                            sortedDirection={""}
                            loadingData={this.state.loadingData}
                        />
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
        </>
    }
}

import React, { useState } from 'react'
import UILIB from '../../../Common-Objects/Lib'
import axios from '../../../data/http/axios'

export default function AddSiteTrackingPage({ SiteTrackerId, onCreated = () => { }, onCancel = () => { } }) {
    const [path, setPath] = useState('')
    const [error, setError] = useState('')

    const onSave = async () => {
        try {
            setError('')
            if (path.indexOf('/') !== 0) {
                return setError('path must start with a /')
            }
            if (path.indexOf('?') > -1) {
                return setError('path cannot contain query strings (?)')
            }
            const newPath = await axios.post('/accountMaster/tracker/' + SiteTrackerId, {
                path: path.trim().toLowerCase()
            })
            onCreated(newPath.data)
        } catch (e) {
            setError(e.data.error)
        }
    }

    return (
        <div>
            <h4 className='mar-b25'>Add Site Tracker Page</h4>
            <div className="mar-b25">This should be everything after the domain name before any query strings</div>

            <UILIB.TextInput outerClassName="mar-b25" label="Path" value={path} placeholder="/the-path" onChange={e => setPath(e.currentTarget.value)} error={error} />

            <UILIB.Button className="button-primary mar-r10" text="Create" onClick={onSave} />
            <UILIB.Button text="Cancel" onClick={onCancel} />

        </div>
    )
}

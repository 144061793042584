import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";

export default function CheckboxTile({ value, className, children, ...rest }) {
  const siteMaster = useSelector((state) => state.siteMaster);
  const colors = siteMaster.colours;

  const classes = classnames("checkbox-tile", className);

  return (
    <div className={classes}>
      <input
        type="checkbox"
        id={value}
        value={value}
        className="visually-hidden"
        {...rest}
      />

      <label htmlFor={value}>
        {children}

        <UILIB.Icons
          className="checkbox-tile__tick"
          icon="tickCircle"
          color={colors.$primary}
        />
      </label>
    </div>
  );
}

import React, { Component } from 'react'
import TextFunctions from '../../../../../../../../../Classes/textFunctions';
import { Controlled as CodeMirror } from 'react-codemirror2'
require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript')
require('codemirror/lib/codemirror.css')
require('codemirror/mode/markdown/markdown');

export default class DivCodeEditor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            codeMirrorCode: ""
        }

        this.codeMirrorUpdateCode = this.codeMirrorUpdateCode.bind(this);
        this.clickCatcher = this.clickCatcher.bind(this);
    }

    componentDidMount() {
        this.setState({ codeMirrorCode: this.props.value })
    }
    codeMirrorUpdateCode(value) {
        if (value) {
            value = TextFunctions.removeScriptCapableTags(value);
        }
        this.setState({
            codeMirrorCode: value
        }, () => { this.props.updateHtml(value) })
    }

    clickCatcher(event) {

    }
    render() {
        var codeMirrorOpts = {
            mode: 'markdown',
            lineNumbers: true,
            lineWrapping: true
        };

        return <div onClick={this.clickCatcher} style={{ textAlign: "left" }}>
            <CodeMirror ref={(element) => { this.codeMirrorInst = element; }} autoCursor={true} autoFocus={true} options={codeMirrorOpts} value={this.state.codeMirrorCode} onBeforeChange={(editor, data, value) => { this.codeMirrorUpdateCode(value) }} style={{ width: "100%" }} />
        </div>


    }
}
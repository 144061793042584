import React, { useState } from "react";
import { useHistory } from 'react-router-dom'
import axios from "../../../../data/http/axios";
import UILIB from '../../../../Common-Objects/Lib'

export default function CreateOrganisation({ onCancel = () => { } }) {
    const [name, setName] = useState('')
    const [error, setError] = useState('')
    const history = useHistory()

    const create = async () => {
        setError('')
        if (!name) return setError('Name Required')
        try {
            const res = await axios.post('/organisation', { name })
            history.push('/cp/subscribers/organisations/' + res.data.Organisation.id)
        } catch (e) {
            setError(e && e.data ? e.data.error : 'Invalid Name')
        }
    }

    return (
        <div>
            <h4 className="mar-b25">Create Organisation</h4>


            <UILIB.TextInput outerClassName="mar-b25" label="Name" value={name} focus onEnterPress={create} onChange={e => setName(e.currentTarget.value)} error={error} placeholder="New Company Ltd" />

            <div className="quickFlex">
                <UILIB.Button text="Create" className="button-primary mar-r10" onClick={create} iconLeft={<UILIB.Icons icon="plus" />} />
                <UILIB.Button text="Cancel" className="grey button-sml full-width" onClick={onCancel} />
            </div>

        </div>
    )
}
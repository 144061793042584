import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import DateFunctions from '~/Classes/dateTimeFunctions';
import i18n from '~/i18n'
import { alterSnackbar } from '../../../../data/actions/siteActions';

@connect((store) => { return { siteMaster: store.siteMaster } })
class DrawerStatsViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tableData: [],
            searchTxt: "",
            stat: undefined,
            removing: false,
            deleting: false,
            updating: [],
            isUpdating: false,
            datetime: new Date(),
            page: 0
        }
        this.changeSearch = this.changeSearch.bind(this);
        this.retrieveData = this.retrieveData.bind(this);
        this.showStat = this.showStat.bind(this)
        this.hideStat = this.hideStat.bind(this)
        this.remove = this.remove.bind(this)
        this.edit = this.edit.bind(this)
        this.clickCheck = this.clickCheck.bind(this)
        this.checkAll = this.checkAll.bind(this)
    }

    componentDidMount() {
        this.retrieveData();
    }

    changeSearch(event) {
        this.setState({ searchTxt: event.target.value, page: 0 }, () => {
            if (!this.state.searchTxt || !this.state.searchTxt.length || this.state.searchTxt.length > 2)
                this.retrieveData()
        })
    }

    showStat(stat) {
        this.setState({ stat })
    }

    hideStat() {
        this.setState({ stat: undefined })
    }

    async remove() {
        this.setState({ deleting: true })
        const res = await axios.delete('/automation/' + this.props.automationId + '/subscriber/' + this.state.removing.id)
        if (!res.data.success) this.props.dispatch(alterSnackbar(true, 'Contact already removed from Automation'))
        else this.props.dispatch(alterSnackbar(true, 'Contact removed from Automation'))
        this.retrieveData()
    }

    async edit() {
        this.setState({ isUpdating: true })
        await axios.put(`/automation/${this.props.automationId}/step/${this.props.step.guid}`, {
            action: 'delay',
            date: this.state.datetime,
            ids: this.state.updating,
        })
        this.retrieveData()
        this.setState({ isUpdating: false, updating: [] })
    }

    next() {
        this.setState({
            page: this.state.page + 1
        }, () => {
            this.retrieveData()
        })
    }

    prev() {
        this.setState({
            page: this.state.page - 1
        }, () => {
            this.retrieveData()
        })
    }

    clickCheck(id) {
        const data = this.state.tableData
        const index = data.findIndex(d => d.id.id === id)
        if (index > -1) {
            const checked = data[index].checkbox.isChecked
            data[index].checkbox.value = React.cloneElement(data[index].checkbox.value, { checked: !checked })
            data[index].checkbox.isChecked = !checked
        }
        this.setState({ tableData: data })
    }

    checkAll() {
        const data = this.state.tableData
        const checked = data.some(d => d.checkbox.isChecked)
        data.forEach(d => {
            d.checkbox.value = React.cloneElement(d.checkbox.value, { checked: !checked })
            d.checkbox.headerValue = React.cloneElement(d.checkbox.headerValue, { checked: !checked })
            d.checkbox.isChecked = !checked
        })
        this.setState({ tableData: data })
    }

    retrieveData() {
        axios.get('/automation/' + this.props.automationId + '/v2/stats/stepResultsByType?type=' + this.props.type + '&guid=' + this.props.step.guid + '&searchText=' + this.state.searchTxt + '&offset=' + (this.state.page * 100)).then(res => {
            var showIntegration = res.data.Results.some(r => r.integrationAction)
            var showQueueId = res.data.Results.some(r => r.QueueId)

            var tableData = res.data.Results.map((stat) => {
                var data = {
                    id: {
                        id: stat.QueueId,
                        order: -99
                    },
                    emailAddress: {
                        headerValue: i18n.t('automation:add.stats.email'),
                        value: stat.id ? <Link to={"/cp/subscriber/" + stat.id} target="_blank">{stat.emailAddress}</Link> : 'CONTACT DELETED',
                        orderBy: false,
                        order: 0
                    },
                    createdAt: {
                        headerValue: i18n.t('automation:add.stats.date'),
                        value: <div className="quickFlex">
                            {DateFunctions.formatDateTime(stat.createdAt)}
                            {this.props.type == "queued" && <UILIB.Icons className="mar-l10" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }} icon="pencil" onClick={() => this.setState({ updating: [stat.QueueId] })} />}
                        </div>,
                        orderBy: false,
                        order: 1
                    }
                }
                if (showQueueId) {
                    data.QueueId = {
                        headerValue: "#",
                        value: stat.QueueId,
                        orderBy: false,
                        order: 2
                    }
                }
                if (this.props.type == "queued") {
                    data.createdAt.headerValue = i18n.t('automation:add.stats.queued')
                    data.checkbox = {
                        headerValue: <UILIB.CheckBox
                            outerClassName="no-marg"
                            checked={false}
                            onChange={this.checkAll} />,
                        value: <UILIB.CheckBox
                            outerClassName="no-marg"
                            checked={false}
                            onChange={() => this.clickCheck(stat.id)}
                            name={"sub" + stat.id} />,
                        isCheckBox: true,
                        width: 30,
                        isChecked: false,
                        order: -1
                    }
                }
                if (this.props.step.type === 'score') {
                    data.score = {
                        headerValue: i18n.t('automation:add.condition.scored'),
                        value: stat.ConditionMet ? this.props.step.stepOptions.scoreChange.value === 'inc' ? this.props.step.stepOptions.scoreValue.value : '-' + this.props.step.stepOptions.scoreValue.value : 0,
                        orderBy: false,
                        order: 3
                    }
                }
                if (showIntegration) {
                    data.integrationAction = {
                        headerValue: 'Action',
                        value: stat.integrationAction ? <UILIB.Button className="button-sml" text="View" onClick={() => this.showStat(stat.integrationAction)} /> : '',
                        orderBy: false,
                        order: 4
                    }
                }

                data.remove = {
                    headerValue: ' ',
                    value: stat.id ? <UILIB.Icons style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }} icon="crossCircle" color="red" onClick={() => this.setState({ removing: stat })} /> : '',
                    order: 99
                }
                return data;
            })

            this.setState({ tableData, loading: false, removing: false, deleting: false })
        })
    }

    shouldIncludeHeadings() {
        const { type } = this.props;
        return type === "queued" || type === "processed";
    }

    render() {
        if (this.state.loading) return <div><UILIB.LoadingIcons iconType="2" /></div>
        if (!!this.state.stat) return <>
            <UILIB.Button className="mar-b20" text="Back" onClick={this.hideStat} />
            <UILIB.TextInput outerClassName="mar-b20" value={this.state.stat.method} label="Method" />
            <UILIB.TextInput outerClassName="mar-b20" value={this.state.stat.url} label="URL" />
            {!!this.state.stat.body && <UILIB.TextArea label="Body" value={this.state.stat.body ? JSON.stringify(JSON.parse(this.state.stat.body), null, 2) : ''} rows="25" className="text-sml" />}
        </>

        if (!!this.state.removing) {
            return <div>
                <h3 className="mar-b25">Remove Contact from Automation</h3>
                <div className="mar-b25">This will remove this contact from the automation and allow them to rejoin again</div>
                <UILIB.Button disabled={this.state.deleting} onClick={this.remove} className="button-red mar-r10">Yes, Remove</UILIB.Button>
                <UILIB.Button disabled={this.state.deleting} onClick={() => this.setState({ removing: false })}>No, Cancel</UILIB.Button>
                {this.state.deleting && <UILIB.LoadingIcons className="mar-t20" />}
            </div>
        }

        if (!!this.state.updating.length) {
            return <div>
                <h3 className="mar-b25">Change the Date</h3>
                <div className="mar-b25">Alter the date these contacts will move onto the next step</div>
                <UILIB.TextInput type="datetime" value={this.state.datetime} onChange={(e, d) => this.setState({ datetime: d })} className="mar-b20" />
                <UILIB.Button disabled={this.state.deleting} onClick={this.edit} className="button-primary mar-r10">Save Changes</UILIB.Button>
                <UILIB.Button disabled={this.state.deleting} onClick={() => this.setState({ updating: [] })}>Cancel</UILIB.Button>
                {this.state.deleting && <UILIB.LoadingIcons className="mar-t20" />}
            </div>
        }

        const checkedCount = this.state.tableData.filter(d => d.checkbox?.isChecked)

        return (
            <div data-test="drawer-stats-viewer" style={{ position: 'relative', height: 'calc(100vh - 90px)', display: 'flex', flexDirection: 'column' }}>
                {this.shouldIncludeHeadings() && (
                    <>
                        <h2 className="h3 mar-b10">
                            {i18n.t(`automation:add.stats.${this.props.type}Headings.header`)}
                        </h2>

                        <p className="mar-b25">
                            {i18n.t(`automation:add.stats.${this.props.type}Headings.subheader`)}
                        </p>
                    </>
                )}

                <UILIB.TextInput outerClassName="mar-b25" name="searchTxt" value={this.state.searchTxt} onChange={this.changeSearch} placeholder={i18n.t('automation:add.stats.search')} />
                {!!checkedCount.length && <div><UILIB.Button className="button-sml mar-b20" onClick={() => this.setState({ updating: checkedCount.map(c => c.id.id) })}>Edit {checkedCount.length} Dates</UILIB.Button></div>}
                <UILIB.DataTable1 outerStyle={{ flexGrow: 1, overflowX: 'auto', marginBottom: 60 }} noResultsTxt={<span>{i18n.t('automation:add.stats.none')}</span>} tableData={this.state.tableData} loadingData={false}
                    dataUpdater={() => { }} width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn={"createdAt"}
                    sortedDirection={"desc"}
                />
                <div style={{ position: 'absolute', bottom: 0, padding: 10, display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <UILIB.Button className="button-sml" onClick={this.prev.bind(this)} disabled={this.state.page === 0} iconLeft={<UILIB.Icons icon="arrowLeft" />}>Previous</UILIB.Button>
                    <UILIB.Button className="button-sml" onClick={this.next.bind(this)} disabled={this.state.tableData.length < 100} iconRight={<UILIB.Icons icon="arrowRight" />}>Next</UILIB.Button>
                </div>
            </div>
        );
    }
}

export default DrawerStatsViewer;
import React, { Fragment } from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import moment from 'moment';
import momentTz from 'moment'
import i18n from '~/i18n'

import StandardStep1 from '../steps/step1/standardCampaignStep1'
import StandardStep2 from '../steps/step2/standardCampaignStep2'
import StandardStep3 from '../steps/step3/standardCampaignStep3'
import Integration from '../steps/integration'
import SmsContent from '../sms/content'
import SmsSend from '../sms/send'

//CONNECT TO STORE
@connect((store) => {
	return { user: store.user, permissionStore: store.permission }
})
export default class CampaignsTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			campaignId: 0,
			campaign: {
				batchSize: 0,
				batchDelay: 0,
				batchUnit: 'minutes',
				limit: 0,
			},
			campaignLoaded: false,
			subscriberCount: 0,
			applications: [],
			customFields: [],
			loadedApps: false,
			calculatedPrice: null,
			newBillingPlan: undefined,
			edittingName: false,
			alertMessage: '',
			alertOpen: false,
		}
		this.loadCampaign = this.loadCampaign.bind(this);
		this.changeStep = this.changeStep.bind(this);
		this.updateCampaign = this.updateCampaign.bind(this);
		this.saveCampaign = this.saveCampaign.bind(this);
		this.updateSubscriberCount = this.updateSubscriberCount.bind(this);
		this.countSubs = this.countSubs.bind(this);
		this.checkSendingLimit = this.checkSendingLimit.bind(this);
		this.doesSegmentIncludeCampaignsOrGroups = this.doesSegmentIncludeCampaignsOrGroups.bind(this);
		this.saveCampaignName = this.saveCampaignName.bind(this);
		this.closeAlert = this.closeAlert.bind(this);
	};

	componentDidMount() {
		axios.get('/accountMaster/application').then(response => {
			var apps = response.data.Applications

			this.setState({
				applications: apps,
				loadedApps: true
			})
		})

		var thisCampaignID = this.props.match.params.campaignId;
		if (!thisCampaignID) {
			this.props.history.push('/cp/campaigns/');
		}
		else {
			this.loadCampaign(thisCampaignID);
		}
	}

	loadCampaign(campaignID) {

		var self = this;
		return axios.get('/campaign/' + campaignID)
			.then(response => {

				var campaign = response.data.Campaign;
				if (campaign.status != 'Draft') {
					return this.props.history.push('/cp/campaigns')
				}

				if (campaign.startDate) {
					campaign.startDate = moment.tz(campaign.startDate, this.props.user.userData.timeZone);
				} else {
					campaign.startDate = moment.utc();
				}

				for (var prop in campaign) {
					if (campaign[prop] === null) campaign[prop] = undefined
				}

				if (campaign.Groups && campaign.Groups.length) {
					campaign.Groups = campaign.Groups.map(g => {
						return {
							id: g.id,
							include: g.CampaignGroup.include,
							name: g.groupName
						}
					})
				}

				if (campaign.Segments && campaign.Segments.length) {
					campaign.Segments = campaign.Segments.map(s => {
						return {
							id: s.id,
							include: s.CampaignSegment.include,
							name: s.name,
							queryObj: s.queryObj,
							temporary: s.temporary ? s.temporary : false
						}
					})
				}

				if (!campaign.sendUpdateEmailsRecipient || !campaign.sendUpdateEmailsRecipient.length) {
					campaign.sendUpdateEmailsRecipient = this.props.user.userData.emailAddress;
				}
				if (!campaign.batchSize) campaign.batchSize = 0;
				if (!campaign.batchDelay) campaign.batchDelay = 0;
				if (!campaign.batchUnit) campaign.batchUnit = 'minutes';
				if (!campaign.limit) campaign.limit = 0;
				if (!campaign.subject) campaign.subject = '';

				if (!campaign.options) campaign.options = {};
				if (campaign.options.doNotTrackLinks === undefined) campaign.options.doNotTrackLinks = false;
				if (campaign.options.addUtmTracking === undefined) campaign.options.addUtmTracking = false;

				if (campaign.type === 6) {
					axios.get('/customfield').then(res => this.setState({ customFields: res.data.CustomFields }))
				}

				self.setState({ campaign: campaign, campaignId: campaignID, campaignLoaded: true }, this.countSubs)
			})
			.catch(console.log);
	}

	changeStep(stepNo, save) {
		if (save) {
			axios.put('/campaign/' + this.state.campaignId, {
				campaign: this.state.campaign
			}).then(() => {
				if (this.state.campaign.type === 6) {
					this.props.history.push('/cp/campaigns/add/sms/' + this.state.campaignId + '/' + stepNo)
				} else {
					this.props.history.push('/cp/campaigns/add/standard/' + this.state.campaignId + '/' + stepNo)
				}
			}).catch(err => {
				var errorMessage = "There was a problem saving the campaign";
				if (err && err.data && err.data.error) errorMessage = err.data.error;
				this.setState({ alertOpen: true, alertMessage: errorMessage })
			})
		} else {
			this.props.history.push('/cp/campaigns/add/standard/' + this.state.campaignId + '/' + stepNo)
		}

	}

	saveCampaign() {
		const startDate = momentTz.tz(this.state.campaign.startDate.format('YYYY-MM-DD HH:mm:ss'), this.props.user.userData.timeZone)
		const campaign = { ...this.state.campaign, startDate }
		return axios.put('/campaign/' + this.state.campaignId, { campaign }).catch(err => {
			var errorMessage = "There was a problem saving the campaign";
			if (err && err.data && err.data.error) errorMessage = err.data.error;
			this.setState({ alertOpen: true, alertMessage: errorMessage })
		})
	}

	updateCampaign() {
		var campaign = this.state.campaign;
		campaign.lastUpdated = new Date()
		this.setState({
			campaign
		})
	}

	updateSubscriberCount(subCount) {
		this.setState({ subscriberCount: subCount }, this.checkSendingLimit)
	}

	doesSegmentIncludeCampaignsOrGroups(segment) {
		return segment.queryObj.and.some(f => f.customFieldId === 'campaignId' || f.customFieldId === 'GroupId') || segment.queryObj.or.some(f => f.customFieldId === 'campaignId' || f.customFieldId === 'GroupId')
	}

	countSubs() {
		var queryStr = '?ignoreLog=1'
		if (!this.state.campaign.options) this.state.campaign.options = {}
		if (this.state.campaign.options && this.state.campaign.options.filterSegments) {
			queryStr += '&filterSegments=true'
		}
		axios.post('/campaign/count' + queryStr, {
			Segments: this.state.campaign.Segments,
			Groups: this.state.campaign.Groups,
			smsField: this.state.campaign.options.smsField
		}).then(response => {
			this.setState({
				subscriberCount: response.data.Count
			}, this.checkSendingLimit)
		})
	}

	

	checkSendingLimit() {
		this.saveCampaign().then(() => {
			axios.get('/campaign/' + this.state.campaign.id + '/checksendinglimit').then(response => {
				this.setState(response.data)
				if (response.data.newBillingPlan) {
					axios.get('/accountMaster/plan/' + response.data.newBillingPlan.id).then(res => {
						this.setState({
							calculatedPrice: res.data
						})
					})
				}
			})
		})

	}

	saveCampaignName(event) {
		var campaign = this.state.campaign;
		campaign.campaignName = event.target.value;
		this.setState({ campaign });
	}

	closeAlert() {
		this.setState({ alertOpen: false, alertMessage: "" })
	}

	render() {

		var totalSubs = this.state.subscriberCount
		if (this.state.campaign.limit && Number(this.state.campaign.limit) > 0) {
			if (Number(this.state.campaign.limit) < totalSubs) totalSubs = Number(this.state.campaign.limit)
		}

		if (!this.state.campaignLoaded || !this.state.loadedApps) {
			return <div><UILIB.LoadingIcons iconType="2" /></div>
		}
		var theCampaign = this.state.campaign;

		const isSms = this.state.campaign.type === 6

		const apps = this.state.applications.filter(a => a.active && a.Application.options && a.Application.options.outboundClick && a.appSettings && a.appSettings.groupId && (theCampaign.Groups.some(g => g.id == a.appSettings.groupId && g.include) || !theCampaign.Groups.length))
		let showApps = !!apps.length
		if (isSms) showApps = false

		var currentStep = 1;
		var stepClasses = { step1: "", step2: "", step3: "", integration: "" }

		if (this.props.match.params.step) {
			currentStep = this.props.match.params.step;
		}



		if (theCampaign.Groups.length > 0 || theCampaign.Segments.length > 0 && this.state.subscriberCount > 0) {
			stepClasses.step1 = "addcampaign-stepholder-step-complete"
		}
		if (theCampaign.TemplateId != undefined && this.state.subscriberCount > 0) {
			stepClasses.step2 = "addcampaign-stepholder-step-complete"
			stepClasses.step3 = "addcampaign-stepholder-step-complete"
		}



		switch (parseInt(currentStep)) {
			case 1:
				stepClasses.step1 = "addcampaign-stepholder-step-selected"
				break;
			case 2:
				stepClasses.step2 = "addcampaign-stepholder-step-selected"
				break;
			case 3:
				stepClasses.step3 = "addcampaign-stepholder-step-selected"
				break;
			default:
				stepClasses.step1 = "addcampaign-stepholder-step-selected"
				break;
		}

		let steps = [];
		steps.push({
			title: i18n.t('campaigns:add.recipients'),
			onClick: () => { this.changeStep(1) },
			selected: stepClasses.step1 == "addcampaign-stepholder-step-selected",
			complete: stepClasses.step1 == "addcampaign-stepholder-step-complete"
		})
		if (!isSms) {
			steps.push({
				title: i18n.t('campaigns:add.template'),
				onClick: () => { if (stepClasses.step2 != "") { this.changeStep(2) } },
				selected: stepClasses.step2 == "addcampaign-stepholder-step-selected",
				complete: stepClasses.step2 == "addcampaign-stepholder-step-complete"
			})
		}
		if (isSms) {
			steps.push({
				title: "Content",
				onClick: () => { if (stepClasses.step2 != "") { this.changeStep(2) } },
				selected: stepClasses.step2 == "addcampaign-stepholder-step-selected",
				complete: stepClasses.step2 == "addcampaign-stepholder-step-complete"
			})
		}
		steps.push({
			title: i18n.t('campaigns:add.send'),
			onClick: () => { if (stepClasses.step3 != "") { this.changeStep(3) } },
			selected: stepClasses.step3 == "addcampaign-stepholder-step-selected",
			complete: stepClasses.step3 == "addcampaign-stepholder-step-complete"
		})
		return <UILIB.Paper style={{'padding-bottom': 80}}>
			<UILIB.Row>
				<UILIB.Hint className="mar-b10" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={() => { this.props.history.push('/cp/campaigns') }}>{i18n.t('campaigns:standard.step1.backToCampaign')}</UILIB.Hint>
				<UILIB.Column xs={12} sm={12} md={12}>
					{this.state.edittingName && <div className="mar-b25 quickFlex">						
						<UILIB.TextInput
							outerStyle={{ flex: "1" }}
							autoFocus={true}
							value={theCampaign.campaignName}
							name="campaignName"
							onChange={this.saveCampaignName} />
						<UILIB.Icons
							style={{ flex: "0", width: "20px", height: "20px" }}
							icon="tick"
							onClick={() => this.setState({ edittingName: false }, this.saveCampaign)}
							className="mar-l10"
						/>
					</div>
					}
					{!this.state.edittingName && <h4 className="mar-b25 quickFlex" style={{ alignItems: "center" }}>
						<div style={{color: 'gray'}} className="page-header mar-r5">{theCampaign.type == 6? "SMS:" : ''}</div>
						<div className="page-header mar-r10">{theCampaign.campaignName}</div>
						<UILIB.Icons
							style={{ width: "20px", height: "20px" }}
							icon="pencil"
							onClick={() => this.setState({ edittingName: true })}
						/>
					</h4>
					}

					<UILIB.ProgressNav steps={steps} />

				</UILIB.Column>
			</UILIB.Row>

			<div className="campaign-holder">
            {currentStep == 1 && <StandardStep1 customFields={this.state.customFields} campaign={this.state.campaign} saveCampaign={this.saveCampaign} sendingLimitReached={this.state.sendingLimitReached} newBillingPlan={this.state.newBillingPlan} calculatedPrice={this.state.calculatedPrice} groups={this.state.campaign.Groups} segments={this.state.campaign.Segments} changeStep={this.changeStep} updateCampaign={this.updateCampaign} updated={this.state.campaign.lastUpdated} history={this.props.history} updateSubscriberCount={this.updateSubscriberCount} />}
            {currentStep == 2 && !isSms && <StandardStep2 campaign={this.state.campaign} saveCampaign={this.saveCampaign} history={this.props.history} changeStep={this.changeStep} updateCampaign={this.updateCampaign} loadCampaign={this.loadCampaign} />}
            {currentStep == 2 && isSms && <SmsContent campaign={this.state.campaign} saveCampaign={this.saveCampaign} changeStep={this.changeStep} updateCampaign={this.updateCampaign} loadCampaign={this.loadCampaign} />}
            {currentStep == 3 && !isSms && <StandardStep3 showApps={showApps} apps={apps} customFields={this.state.customFields} newBillingPlan={this.state.newBillingPlan} calculatedPrice={this.state.calculatedPrice} campaign={this.state.campaign} sendingLimitReached={this.state.sendingLimitReached} subscriberCount={this.state.subscriberCount} countSubs={this.countSubs} saveCampaign={this.saveCampaign} changeStep={this.changeStep} updateCampaign={this.updateCampaign} history={this.props.history} updated={this.state.campaign.lastUpdated} />}
            {currentStep == 3 && isSms && <SmsSend checkSendingLimit={this.checkSendingLimit} newBillingPlan={this.state.newBillingPlan} calculatedPrice={this.state.calculatedPrice} campaign={this.state.campaign} sendingLimitReached={this.state.sendingLimitReached} subscriberCount={totalSubs} saveCampaign={this.saveCampaign} changeStep={this.changeStep} updateCampaign={this.updateCampaign} />}
			</div>

			<UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={this.closeAlert} />
		</UILIB.Paper >


	}
}
import React, { useEffect, useState, useReducer } from "react";
import { useDispatch } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { handleGenericError } from "~/data/actions/siteActions";
import DateTimeFunctions from "~/Classes/dateTimeFunctions";
import TextInput from "../../components/textInput";
import * as styles from "./index.module.scss";
import ViewHistoryItemDialog from "./components/viewHistoryItemDialog";
import Header from "../../components/header";
import i18n from "~/i18n";
import Button from "../../components/button";
import { useHistory } from "react-router-dom";

export default function AiAssistantHistory({ inbox, assistant }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [aiHistory, setAiHistory] = useState(null);
  const [selectedHistoryItem, setSelectedHistoryItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [isViewHistoryItemDialogOpen, setIsViewHistoryItemDialogOpen] =
    useState(false);

  const [tableData, updateTableData] = useReducer((state, action) => {
    return formatTableDataReducer(state, action, {
      setSelectedHistoryItem,
      setIsViewHistoryItemDialogOpen,
    },
      history);
  }, []);

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    loadHistory();
  }, [page]);

  async function loadInitialData() {
    try {
      await loadHistory();
    } finally {
      setLoading(false);
    }
  }

  async function loadHistory() {
    try {
      const { data } = await axios.get(
        `/inboxes/${inbox.id}/ai/${assistant.id
        }/history?limit=${limit}&offset=${page * limit}`
      );
      setAiHistory(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  useEffect(() => {
    if (aiHistory?.rows) updateTableData(aiHistory.rows);
  }, [aiHistory]);

  useEffect(() => {
    if (aiHistory?.rows) {
      const filteredData = aiHistory.rows.filter((aiHist) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        if (
          aiHist.Subscriber?.emailAddress
            .toLowerCase()
            .includes(lowerCaseSearchTerm)
        ) {
          return true;
        }

        return false;
      });

      updateTableData(filteredData);
    }
  }, [searchTerm]);

  if (loading) return <UILIB.LoadingIcons noClass />;

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inbox_settings_left_content">
      <Header type="ai" option="history" />
      <form>
        <TextInput
          value={searchTerm}
          placeholder={i18n.t("chat:ai.history.searchPlaceholder")}
          aria-label="Search for a history item"
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
      </form>

      {tableData.length > 0 && <UILIB.DataTable1 tableData={tableData} />}

      <UILIB.PagingBlock
        totalRows={aiHistory.count}
        pageSize={limit}
        numberOfLinks="10"
        currentPage={page}
        changePage={setPage}
        text={i18n.t("chat:ai.history.page") + ":"}
      />

      <ViewHistoryItemDialog
        historyItem={selectedHistoryItem}
        isOpen={isViewHistoryItemDialogOpen}
        onOpenChange={(isOpen) => setIsViewHistoryItemDialogOpen(isOpen)}
      />
    </div>
  );
}

function formatTableDataReducer(state, action, options, history) {
  return action.map((historyLine) => {
    const { createdAt, Subscriber, success } = historyLine;

    return {
      timestamp: {
        headerValue: i18n.t("chat:ai.history.timestamp"),
        value: DateTimeFunctions.formatDateTime(createdAt),
      },
      email: {
        headerValue: i18n.t("chat:ai.history.email"),
        value: (
          <button
            className={styles.tableEmail}
            onClick={() => {
              history.push(`/cp/subscriber/${Subscriber.id}`)
            }}
          >
            {Subscriber?.emailAddress || i18n.t("chat:ai.history.unknown")}
          </button>
        ),
      },
      success: {
        headerValue: i18n.t("chat:ai.history.customerSatisfied"),
        align: "center",
        value: (
          <UILIB.Badge color={success ? "teal" : "red"}>
            {success ? i18n.t("chat:ai.history.yes") : i18n.t("chat:ai.history.no")}
          </UILIB.Badge>
        )
      },
      options: {
        headerValue: "",
        width: "0",
        align: "right",
        value: (
          <Button
            size="s"
            onClick={() => {
              options.setSelectedHistoryItem(historyLine);
              options.setIsViewHistoryItemDialogOpen(historyLine);
            }}
          >
            {i18n.t("chat:ai.history.viewResponse")}
          </Button>

        ),
      }
    };
  });
}

const limit = 20;

import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import DragDropFunctions from '../../../dragDropFunctions';
import Wysiwyg from './wysiwyg/wysiwyg'
import clone from 'lodash/clone'
import each from 'lodash/each'
import jquery from 'jquery';

export default class DivEditable extends Component {
    constructor(props) {
        super(props)

        this.createMarkup = this.createMarkup.bind(this);
        this.captureClicks = this.captureClicks.bind(this);
        this.elementDidFocus = this.elementDidFocus.bind(this);
        this.elementDidBlur = this.elementDidBlur.bind(this);
        this.spawnWysi = this.spawnWysi.bind(this);
        this.focusedElementClick = this.focusedElementClick.bind(this);
        this.getSelectedText = this.getSelectedText.bind(this);
        this.findPropsInDom = this.findPropsInDom.bind(this);
        this.pasteFilter = this.pasteFilter.bind(this);
        this.cleanWordHtml = this.cleanWordHtml.bind(this);
        this.stopBlur = this.stopBlur.bind(this);
        this.startBlur = this.startBlur.bind(this);
        this.state = {
            selectedProps: {
                "font-weight": { nodeType: "" },
                "color": { nodeType: "" },
                "text-align": { nodeType: "" },
                "font-style": { nodeType: "" },
                "text-decoration": { nodeType: "" },
                "font-family": { nodeType: "" },
                "font-size": { nodeType: "" },
                "text-decoration-line": { nodeType: "" },
                "ul": { nodeType: "" },
                "ol": { nodeType: "" },
                "a": { nodeType: "" },
                "formatBlock": { nodeType: "" },
                "p": { nodeType: "" },
                "pre": { nodeType: "" },
                "strong": { nodeType: "" },
            },
            mouseOver: false,
            dontBlur: false
        }

    }

    componentDidMount() {
        this.refs.theEl.addEventListener('focusin', (event) => { this.elementDidFocus(event) })
        this.refs.theEl.addEventListener('focusout', (event) => { this.elementDidBlur(event) })
    }
    componentWillUnmount() {
        this.refs.theEl.removeEventListener('focusin', this.elementDidFocus);
        this.refs.theEl.removeEventListener('focusout', this.elementDidBlur);
    }
    stopBlur() {

        this.setState({ dontBlur: true })
    }
    startBlur(forceBlur) {
        var self = this;
        this.setState({ dontBlur: false }, () => {
            this.elementDidBlur(undefined, true)
        })
    }
    elementDidFocus(event) {
        var theEl;
        if (event.srcElement) {
            theEl = event.srcElement;
        }
        else {
            theEl = event.target;
        }
        if (event.originalTarget) {
            theEl = event.originalTarget;
        }
        var thisID = theEl.parentElement.id;
        if (!thisID) {
            thisID = theEl.id;
        }
        if (!thisID) {
            return;
        }
        var theToolbar = <div style={{ border: "1px solid black", padding: "10px" }} />

        var thisDiv = theEl.parentElement;
        var theEditor = document.createElement("div");
        theEditor.id = "wysiEditor";
        thisDiv.appendChild(theEditor);

        var thisEle = document.getElementById(thisID)
        thisEle.addEventListener('click', (event, theEl) => { this.focusedElementClick(event, thisEle) })
        thisEle.addEventListener('keyup', (event, theEl) => { this.focusedElementClick(event, thisEle) })

        this.spawnWysi(theEl)
    }

    elementDidBlur(event, forced) {
        if (this.state.dontBlur && !forced) { return };
        //if we are clicking a wysi tool like link holder, dont lose the wysi
        if (event && event.relatedTarget && event.relatedTarget.classList) {
            var targetClasses = event.relatedTarget.classList;
            for (var theClass in targetClasses) {
                if (targetClasses[theClass] === "wysiTool") {
                    return
                }
            }
        }

        //carry on
        var thisID = this.props.elementData.content.eleId;
        if (!forced) {
            var theEvent;
            if (event.srcElement) {
                theEvent = event.srcElement;
            }
            else {
                theEvent = event.target;
            }
            if (event.originalTarget) {
                theEvent = event.originalTarget;
            }

            thisID = theEvent.parentElement.id;

            if (!thisID) thisID = theEvent.id;
            if (!thisID) {
                return;
            }
        }
        //remove wysi
        ReactDOM.unmountComponentAtNode(document.getElementById('wysiEditor'));
        var thisDiv = document.getElementById(thisID).parentElement;
        if (!forced) {
            thisDiv = event.target.parentElement;
        }
        var wysiRemove = document.getElementById("wysiEditor");
        thisDiv.removeChild(wysiRemove)

        //remove listeners for focusable element
        var thisEle = document.getElementById(thisID);
        thisEle.removeEventListener('click', this.focusedElementClick)
        thisEle.removeEventListener('keyup', this.focusedElementClick)
        function replacePropertyValue(searchVal, object, newVal) {
            const newObject = clone(object);
            each(object, (val, key) => {
                if (val == searchVal) {
                    newObject.value = newVal;
                } else if (typeof (val) === 'object' || typeof (val) === 'array') {
                    newObject[key] = replacePropertyValue(searchVal, object[key], newVal);
                }
            });
            return newObject;
        }
        var innerHTML = thisEle.innerHTML;
        if (!forced) innerHTML = theEvent.innerHTML;

        var newContent = replacePropertyValue(thisID, this.props.elementData, innerHTML);

        this.props.updateElement(newContent, this.props.path);
    }

    spawnWysi(el) {
        if (el)
            ReactDOM.render(<Wysiwyg theEvent={el} theEle={el} elementsData={this.props.elementsData} thisId={this.props.elementData.content.eleId} selectedProps={this.state.selectedProps} focusedElementClick={this.focusedElementClick} saveFaveColor={this.props.saveFaveColor} wysiwygMode={this.props.elementData.content.wysiwygMode} stopBlur={this.stopBlur} startBlur={this.startBlur} />, document.getElementById('wysiEditor'));
    }

    focusedElementClick(event, thisEl) {
        this.getSelectedText(thisEl);
    }

    getSelectedText(el) {

        var selObj = window.getSelection();
        var thisNode = selObj.focusNode;
        var currProps = this.state.selectedProps;

        for (var each in currProps) {
            currProps[each] = { nodeType: "" };
        }
        var currPropsFinal = this.findPropsInDom(thisNode, currProps);
        if (currPropsFinal["font-size"].nodeType == "") {
            //we havent found a default font size so just use the global stage one
            if (this.props.globalVariables && this.props.globalVariables.bodyFontSize && this.props.globalVariables.bodyFontSize.value) {
                currPropsFinal["font-size"].nodeType = this.props.globalVariables.bodyFontSize.value;
            }
        }
        if (currPropsFinal["font-family"].nodeType == "") {
            //we havent found a default font size so just use the global stage one
            if (this.props.globalVariables && this.props.globalVariables.bodyFont && this.props.globalVariables.bodyFont.value) {
                currPropsFinal["font-family"].nodeType = this.props.globalVariables.bodyFont.value;
            }
        }
        this.setState({ selectedProps: currPropsFinal }, this.spawnWysi(el));
    }

    findPropsInDom(theNode, currPropsOrig) {

        if (theNode) {
            if (theNode.id) {
                return currPropsOrig;
            }
            if (theNode.nodeName) {
                if (currPropsOrig.hasOwnProperty(theNode.nodeName.toLowerCase())) {
                    var thisProp = { nodeType: theNode.nodeName.toLowerCase(), nodeContent: "" }
                    if (theNode.nodeName.toLowerCase() == "a") {
                        thisProp.nodeContent = theNode.getAttribute("href");
                    }
                    currPropsOrig[theNode.nodeName.toLowerCase()] = thisProp;
                }
                else {

                    if (theNode.nodeName.toLowerCase() == "h1") {
                        currPropsOrig["formatBlock"].nodeType = "<h1>";
                    }
                    if (theNode.nodeName.toLowerCase() == "h2") {
                        currPropsOrig["formatBlock"].nodeType = "<h2>";
                    }
                    if (theNode.nodeName.toLowerCase() == "h3") {
                        currPropsOrig["formatBlock"].nodeType = "<h3>";
                    }
                    if (theNode.nodeName.toLowerCase() == "h4") {
                        currPropsOrig["formatBlock"].nodeType = "<h4>";
                    }
                    if (theNode.nodeName.toLowerCase() == "h5") {
                        currPropsOrig["formatBlock"].nodeType = "<h5>";
                    }
                }
            }
            if (theNode.style) {
                //poll ten selected styles and/or nodes, break if not found one
                for (var i = 0; i < 10; i++) {
                    if (!theNode.style[i]) break
                    //lookup this style and see if we have it in our styles
                    if (currPropsOrig.hasOwnProperty(theNode.style[i]) && currPropsOrig[theNode.style[i]].nodeType == "") {
                        currPropsOrig[theNode.style[i]] = { nodeType: theNode.style[theNode.style[i]].replace(/\"/gi, "") }
                    }
                }
            }
            if (theNode.parentNode) {
                return this.findPropsInDom(theNode.parentNode, currPropsOrig);
            }
        }
        else {
            return currPropsOrig
        }
    }
    captureClicks(event) {
        //event.stopPropagation()
        //event.preventDefault();
    }

    createMarkup() {
        var theEl = DragDropFunctions.replaceLocalVariables(JSON.parse(JSON.stringify(this.props.elementData)), this.props.localVariables);
        var theHtml = DragDropFunctions.generateHtmlFromJson(theEl, true, this.props.globalVariables);
        theHtml = theHtml.trim();
        return { __html: theHtml };
    };

    pasteFilter(e) {
        if (e.target && e.target.className && e.target.className.indexOf('wysiTool')) return false

        e.preventDefault();
        var text = e.clipboardData.getData("text/plain");
        var cpHtml = e.clipboardData.getData("text/html");

        if (cpHtml && cpHtml.length > 3 && cpHtml != null && cpHtml != undefined) {
            text = cpHtml;
            var officeMetaRegexp = /<meta\s*name=(?:\"|\')?generator(?:\"|\')?\s*content=(?:\"|\')?microsoft/gi;
            var wordRegexp = /(class=\"?Mso|style=(?:\"|\')[^\"]*?\bmso\-|w:WordDocument|<o:\w+>|<\/font>)/;
            var isOfficeContent = officeMetaRegexp.test(text) || wordRegexp.test(text);

            if (isOfficeContent) text = this.cleanWordHtml(text);
        }
        //replace unwanted tags
        text = text.replace(/<img[^>]*>/g, "");
        text = text.replace(/<script[^>]*>/g, "");
        text = text.replace(/<div[^>]*>/g, "");
        text = text.replace(/<\/div[^>]*>/g, "");
        text = text.replace(/<p[^>]*>/g, "");
        text = text.replace(/<\/p[^>]*>/g, "<br/><br/>");
        text = text.replace(/<span[^>]*>/g, "");
        text = text.replace(/<\/span[^>]*>/g, "");
        text = text.replace(/<font[^>]*>/g, "");
        text = text.replace(/<\/font[^>]*>/g, "");

        text = text.replace(/<b [^>]*>/g, "<b>");
        text = text.replace(/<strong [^>]*>/g, "<strong>");
        text.replace(/\n/g, "<br/>")
        text = text.trim();
        function pasteHtmlAtCaret(html, selectPastedContent) {
            var sel, range;
            if (window.getSelection) {
                // IE9 and non-IE
                sel = window.getSelection();
                if (sel.getRangeAt && sel.rangeCount) {
                    range = sel.getRangeAt(0);
                    range.deleteContents();

                    // Range.createContextualFragment() would be useful here but is
                    // only relatively recently standardized and is not supported in
                    // some browsers (IE9, for one)
                    var el = document.createElement("div");
                    el.innerHTML = html;
                    var frag = document.createDocumentFragment(), node, lastNode;
                    while ((node = el.firstChild)) {
                        lastNode = frag.appendChild(node);
                    }
                    var firstNode = frag.firstChild;
                    range.insertNode(frag);

                    // Preserve the selection
                    if (lastNode) {
                        range = range.cloneRange();
                        range.setStartAfter(lastNode);
                        if (selectPastedContent) {
                            range.setStartBefore(firstNode);
                        } else {
                            range.collapse(true);
                        }
                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                }
            } else if ((sel = document.selection) && sel.type != "Control") {
                // IE < 9
                var originalRange = sel.createRange();
                originalRange.collapse(true);
                sel.createRange().pasteHTML(html);
                if (selectPastedContent) {
                    range = sel.createRange();
                    range.setEndPoint("StartToStart", originalRange);
                    range.select();
                }
            }
        }

        pasteHtmlAtCaret(text, false);
    }



    cleanWordHtml(content) {


        content = content.replace(/<!--[\s\S]+?-->/gi, '');
        content = content.replace(/<(!|script[^>]*>.*?<\/script(?=[>\s])|\/?(\?xml(:\w+)?|img|meta|link|style|\w:\w+)(?=[\s\/>]))[^>]*>/gi, '');
        content = content.replace(/<(\/?)s>/gi, "<$1strike>");
        content = content.replace(/&nbsp;/gi, ' ');
        content = content.replace(/<span\s+style\s*=\s*"\s*mso-spacerun\s*:\s*yes\s*;?\s*"\s*>([\s\u00a0]*)<\/span>/gi, function (str, spaces) {
            return (spaces.length > 0) ? spaces.replace(/./, " ").slice(Math.floor(spaces.length / 2)).split("").join("\u00a0") : '';
        });
        content = content.replace(/\<a name=".*">(.*)<\/a>/g, "$1");


        var tStripper = document.createElement("div");
        tStripper.id = "wordStripper";
        tStripper.innerHTML = "<div class='wordRep'>" + content + "</div>";

        jquery('span:empty', tStripper).remove();
        jquery("[class^='mso-Bo']", tStripper).removeAttr('class');

        var onList = false;
        var lastListLevel = 1;
        var listOut = "";
        var lastEl;
        jquery('.wordRep', tStripper).children().each(function () {

            var foundList = false;
            var _listLevel = "";
            var _listNumber = "";

            var elType = jquery(this)[0].tagName;

            if (elType == "P") {
                var str = jquery(this).attr('style');
                var matches = /mso-list:\w+ \w+([0-9]+) \w+([0-9]+)/.exec(str);
                if (matches) {
                    //is a list
                    _listLevel = parseInt(matches[1], 10);
                    _listNumber = parseInt(matches[2], 10);
                    foundList = true;
                }
            }

            if (onList && !foundList) {
                listOut += "</ul>"
                jquery(lastEl).replaceWith(listOut);
                listOut = "";
                lastListLevel = 1;
                onList = false;
                lastEl = undefined;
                return true;
            }
            else {
                if (lastEl) jquery(lastEl).remove();
            }

            if (foundList) {
                lastEl = this;
                var theTxt = jquery(this).text();
                theTxt = theTxt.substr(3, theTxt.length);

                if (!onList) {
                    onList = true;
                    listOut += "<ul>";
                }
                if (_listLevel > lastListLevel) {
                    var diff = _listLevel - lastListLevel;
                    lastListLevel = _listLevel;
                    listOut = listOut.substr(0, listOut.length - 5)
                    while (diff > 0) {
                        listOut += "<ul><li>"
                        diff--;
                    }
                    listOut += theTxt + "</li>";
                }
                else if (_listLevel < lastListLevel) {
                    var diff = lastListLevel - _listLevel;
                    lastListLevel = _listLevel;
                    while (diff > 0) {
                        listOut += "</ul></li>"
                        diff--;
                    }
                    listOut += "<li>" + theTxt + "</li>";
                }
                else {
                    listOut += "<li>" + theTxt + "</li>";
                }

            }
        })
        if (onList) {
            //we are still on a list (didnt find an ending element, so just write it out)
            listOut += "</ul>";
            jquery(lastEl).replaceWith(listOut);
            onList = false;
        }



        jquery('[style]', tStripper).removeAttr('style');
        jquery('[align]', tStripper).removeAttr('align');
        jquery('span', tStripper).replaceWith(function () { return jquery(this).contents(); });
        jquery("[class^='Mso-Bo']", tStripper).removeAttr('class');
        jquery('p:empty', tStripper).remove();


        content = tStripper.innerHTML;
        tStripper.remove();


        return content;
    }
    render() {


        var thisContent;
        if (this.props.elementData.content) {
            thisContent = this.props.elementData.content.value;
        }

        return <div>
            <div dangerouslySetInnerHTML={this.createMarkup()} ref="theEl" onClick={this.captureClicks} onPaste={this.pasteFilter} />
        </div>



    }
}
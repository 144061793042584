import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FormatNumberFuncs from '../../../../Classes/numberFormatFunctions';
import UILIB from '../../../../Common-Objects/Lib';
import axios from '../../../../data/http/axios';

export default function AutomationPanel({ edit }) {
    const [stats, setStats] = useState(null)
    const siteMaster = useSelector(state => state.siteMaster)

    const getStats = async () => {
        const res = await axios.get('/automation/stepstats')
        setStats(res.data)
    }

    useEffect(() => {
        getStats()
    }, [])

    let increase = false
    let diff = 0
    if (stats) {
        increase = stats.current.totalSubscribers.value > stats.previous.totalSubscribers.value
        diff = increase
            ? Math.round((stats.current.totalSubscribers.value / stats.previous.totalSubscribers.value) * 100) - 100
            : 100 - (Math.round((100 / stats.previous.totalSubscribers.value) * stats.current.totalSubscribers.value))
    }
    const color = increase ? siteMaster.colours["$status-green"] : siteMaster.colours["$status-red"]

    return <UILIB.Paper className="m-mar-b0" iconRight={<UILIB.Icons icon="pencil" color="grey" />} iconRightClick={edit}>

        <h4 className="mar-b20">Automations</h4>
        <div>
            {!stats && <UILIB.LoadingIcons />}
            {!!stats && <>
                <div className="quickFlex mar-b10">
                    <h4 className="mar-r16 statistic">{FormatNumberFuncs.formatNumber(stats.current.totalSubscribers.value)}</h4>
                    {!diff === Infinity && <UILIB.Indicator leftIcon={<UILIB.Icons icon={increase ? "arrowUp" : "arrowDown"} color={color} />} className={increase ? "indicator-green" : "indicator-red"}>{diff}%</UILIB.Indicator>}
                    {diff === Infinity && <UILIB.Indicator className="indicator-grey">0.00%</UILIB.Indicator>}
                </div>
                <div>contacts passed through your automations this month</div>
            </>}
        </div>


    </UILIB.Paper>
}
import React, { Fragment } from 'react'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib';
import i18n from '~/i18n';
import axios from '~/data/http/axios';
import IntegrationHelpers from '~/Classes/integrationhelpers';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

export default class DrawerAdCampaign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userApps: [],
            loaded: false,
            errors: ""
        };

        this.getApplications = this.getApplications.bind(this);
        this.connectApp = this.connectApp.bind(this);
    }

    componentDidMount() {
        this.getApplications();
    }

    async getApplications() {
        let userApps = await axios.get('/accountMaster/application')
        userApps = userApps.data.Applications;

        let apps = await axios.get('/application')
        apps = apps.data.Applications

        let connectedApps = apps.filter(f => f.id == 77 || f.id == 75 || f.id == 76).map(f => {
            f.connected = false;
            if (userApps.find(ua => ua.ApplicationId == f.id)) {
                f.connected = true;
            }
            return f
        })
        this.setState({ connectedApps, loaded: true })
    }

    async connectApp(app) {
        this.setState({ loaded: false })
        //go to integration setup
        let ourTrackingDomain = this.props.siteMaster.trackingDomain;
        let oauthResult = await IntegrationHelpers.startoAuth(ourTrackingDomain, app, this.props.user.token, window.location.origin)

        if (!oauthResult.success) {
            return this.setState({
                errors: oauthResult.errors
            })
        }
        window.open(oauthResult.uri, '_self');
    }

    render() {

        if (!this.state.loaded) return <UILIB.LoadingIcons iconType={"2"} />

        return <div>
            <UILIB.Hint
                className="mar-l0 mar-b25"
                iconLeft={<UILIB.Icons icon="arrowLeft" />}
                onClick={() => { this.props.exit() }}>
                {i18n.t('subscribers:subscriber.back')}
            </UILIB.Hint>

            <h3 className="mar-b15">Advertising Campaigns</h3>
            <p className="mar-b25">Connect to your Adwords, Facebook or Linked in ads, then track and compare their success.</p>

            {this.state.errors && <div className="mar-b25 text-red">{this.state.errors}</div>}

            <h4 className="mar-b25">Select a service to connect</h4>
            <div className="row" >
                {this.state.connectedApps.map((app, index) => {
                    let appName = app.appName;
                    return <div className="col-xs-12 col-sm-6 mar-b25" >
                        <UILIB.FeatureCard
                            iconLeft={<img src={app.appLogoUri} style={{ height: 30 }} />}
                            title={appName}
                            description={app.appDescription}
                            featureTag={app.connected ? <UILIB.SquareChip className="square-chip-green">Connected</UILIB.SquareChip> : ""}
                            selected={false}
                            cta={app.connected ? <UILIB.Button text="Configure" className="mar-t15" /> : <UILIB.Button text="Connect" className="mar-t15" />}
                            onClick={() => {
                                if (app.connected) {
                                    window.open(`/cp/company/integrations/apps/ads/` + app.id, '_self')
                                }
                                else {
                                    this.connectApp(app)
                                }
                            }}

                        />
                    </div>

                })}
            </div>

        </div>

    };
}
export function initialWizardStepsState(numberOfSteps) {
  const steps = Array.from(Array(numberOfSteps).keys()).map((step) => {
    return {
      step: step + 1,
      collapsed: step === 0 ? false : true,
      completed: false,
    };
  });

  return steps;
}

export function wizardStepsReducer(state, action) {
  if (action.index === undefined) throw Error("Missing action.index");

  const step = state[action.index];

  switch (action.type) {
    case "complete": {
      return {
        ...state,
        [action.index]: {
          ...step,
          collapsed: true,
          completed: true,
        },
        [action.index + 1]: {
          ...state[action.index + 1],
          collapsed: false,
        },
      };
    }

    case "collapse": {
      return {
        ...state,
        [action.index]: {
          ...step,
          collapsed: !step.collapsed,
        },
      };
    }

    case "reset": {
      return {
        ...state,
        [action.index]: {
          ...step,
          collapsed: true,
          completed: false,
        },
      };
    }
  }

  throw Error(`Unknown action: ${action.type}`);
}

import React from "react";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import classnames from "classnames";
import { propToCssModule } from "~/Classes/styles";
import Icon from "../Icon";
import * as styles from "./index.module.scss";

const DropdownMenu = { ...RadixDropdownMenu };

const Trigger = React.forwardRef(
  (
    {
      asChild,
      variant = "primary",
      size = "r",
      iconOnly,
      className,
      children,
      ...rest
    },
    forwardedRef
  ) => {
    if (iconOnly && !rest["aria-label"]) {
      console.error(
        'Dropdown Menu triggers with only an icon must have an "aria-label" attribute.'
      );
    }

    if (asChild) {
      return (
        <RadixDropdownMenu.Trigger
          asChild={asChild}
          children={children}
          {...rest}
        />
      );
    }

    const classes = classnames(styles.trigger, className, {
      [styles[`triggerVariant${propToCssModule(variant)}`]]: variant,
      [styles[`triggerSize${propToCssModule(size)}`]]: size,
      [styles.triggerIconOnly]: iconOnly,
    });

    return (
      <RadixDropdownMenu.Trigger
        ref={forwardedRef}
        className={classes}
        {...rest}
      >
        {children && <div className={styles.triggerChildren}>{children}</div>}

        {!iconOnly && <Icon name="dropdown-arrow" />}
      </RadixDropdownMenu.Trigger>
    );
  }
);

const Content = React.forwardRef(
  ({ className, align = "start", sideOffset = 4, ...rest }, forwardedRef) => {
    const classes = classnames(styles.content, className);

    return (
      <RadixDropdownMenu.Content
        ref={forwardedRef}
        className={classes}
        align={align}
        sideOffset={sideOffset}
        {...rest}
      />
    );
  }
);

const Label = ({ className, ...rest }) => {
  const classes = classnames(styles.label, className);

  return <RadixDropdownMenu.Label className={classes} {...rest} />;
};

const Item = React.forwardRef(
  ({ variant, className, ...rest }, forwardedRef) => {
    const classes = classnames(styles.item, className, {
      [styles[`itemVariant${propToCssModule(variant)}`]]: variant,
    });

    return (
      <RadixDropdownMenu.Item
        ref={forwardedRef}
        className={classes}
        {...rest}
      />
    );
  }
);

const CheckboxItem = ({ className, children, ...rest }) => {
  const classes = classnames(styles.checkboxItem, className);

  return (
    <RadixDropdownMenu.CheckboxItem className={classes} {...rest}>
      {children}

      <RadixDropdownMenu.ItemIndicator className={styles.itemIndicator}>
        <Icon name="check" />
      </RadixDropdownMenu.ItemIndicator>
    </RadixDropdownMenu.CheckboxItem>
  );
};

const RadioItem = ({ className, children, ...rest }) => {
  const classes = classnames(styles.radioItem, className);

  return (
    <RadixDropdownMenu.RadioItem className={classes} {...rest}>
      {children}

      <RadixDropdownMenu.ItemIndicator className={styles.itemIndicator}>
        <Icon name="check" />
      </RadixDropdownMenu.ItemIndicator>
    </RadixDropdownMenu.RadioItem>
  );
};

const Separator = ({ className, ...rest }) => {
  const classes = classnames(styles.separator, className);

  return <RadixDropdownMenu.Separator className={classes} {...rest} />;
};

DropdownMenu.Trigger = Trigger;
DropdownMenu.Content = Content;
DropdownMenu.Label = Label;
DropdownMenu.Item = Item;
DropdownMenu.CheckboxItem = CheckboxItem;
DropdownMenu.RadioItem = RadioItem;
DropdownMenu.Separator = Separator;

export default DropdownMenu;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import Button from "../../components/button";
import Header from "../../components/header";
import i18n from "~/i18n";

export default function AiAssistantChannels({ inbox, assistant, refresh }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [channels, setChannels] = useState(null);
  const [others, setOthers] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState(
    assistant.settings?.channels || []
  );

  useEffect(() => {
    loadInitialData();
  }, []);

  async function loadInitialData() {
    try {
      await loadChannels();
      const res = await axios.get(`/inboxes/${inbox.id}/ai`);
      const othersTmp = res.data.filter(ai => ai.id !== assistant.id);
      setOthers(othersTmp);
    } finally {
      setLoading(false);
    }
  }

  async function loadChannels() {
    try {
      const { data } = await axios.get(`/inboxes/${inbox.id}/channel`);
      setChannels(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  function handleChannelChange(id) {
    if (selectedChannels.includes(id)) {
      setSelectedChannels((prev) => prev.filter((channel) => channel !== id));
    } else {
      setSelectedChannels((prev) => [...prev, id]);
    }
  }

  async function saveChannels(e) {
    e.preventDefault();
    setSaving(true);

    try {
      await axios.put(`/inboxes/${inbox.id}/ai/${assistant.id}`, {
        settings: { ...assistant.settings, channels: selectedChannels },
      });

      await refresh();
      dispatch(alterSnackbar(true, i18n.t("chat:ai.channels.saved")));
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    } finally {
      setSaving(false);
    }
  }

  if (loading) return <UILIB.LoadingIcons noClass />;

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inbox_settings_left_content">
      <Header type="ai" option="channels" />
      <form onSubmit={saveChannels} disabled={saving}>
        <div className="mar-b24">
          {channels.map((channel) => {
            const { id, name } = channel;
            const disabled = others.some((ai) => ai.settings?.channels?.length && ai.settings?.channels?.indexOf(id) > -1);

            return (
              <UILIB.CheckBox
                key={id}
                name="access"
                value={id}
                disabled={disabled}
                checked={selectedChannels.includes(id)}
                onChange={() => handleChannelChange(id)}
                outerClassName="mar-b12"
              >
                {name} {disabled && <UILIB.SquareChip className="grey mar-l10">{i18n.t("chat:ai.channels.assigned")}</UILIB.SquareChip>}
              </UILIB.CheckBox>
            );
          })}
        </div>

        <Button type="submit" size="s" className="mar-t12" loading={saving}>
          {i18n.t("chat:ai.channels.save")}
        </Button>
      </form>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import axios from '~/data/http/axios';
import UILIB from '../../../../../../Common-Objects/Lib';
import { connect } from 'react-redux';
import * as siteMaster from '../../../../../../data/actions/siteActions'
import IncDisconnect from './incDisconnect';
import Setup from './setup'
import Actions from '../genericCp/actions'
import QuickLinks from '../genericCp/quickLinks';
import Hooks from '../genericCp/hooks2'
import AddApp from '../../addApp'
import ApplicationTransactionsGraph from '../components/applicationTransactionsGraph'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class CapsuleConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            capsuleSite: {},
            appData: {},
            appBinding: {},
            groupData: [],
            groupId: -1,
            importing: false,
            importId: -1,
            importJob: null,
            importProgress: 0,
            customFields: [],
            forceSetup: false,
            status: null,
            authenticationFailed: false
        };

        this.getCapsuleSite = this.getCapsuleSite.bind(this);
        this.getApp = this.getApp.bind(this);
        this.getAppBinding = this.getAppBinding.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.checkForImportUpdate = this.checkForImportUpdate.bind(this);
        this.resetGroup = this.resetGroup.bind(this);
        this.popDisconnect = this.popDisconnect.bind(this);
        this.goDisconnect = this.goDisconnect.bind(this);
        this.performSync = this.performSync.bind(this)
        this.getStatus = this.getStatus.bind(this)
    }

    componentDidMount() {
        this.getApp();
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer)
    }

    getApp() {
        var self = this;
        axios.get("/application/8").then(_theApp => {
            self.setState({ appData: _theApp.data.Application }, self.getAppBinding)
        })
    }

    getAppBinding() {
        var self = this;
        axios.get("/application/account/app/" + this.state.appData.id).then(_theResp => {
            var groupId = -1;
            if (_theResp.data.Application.appSettings && _theResp.data.Application.appSettings.groupId) groupId = _theResp.data.Application.appSettings.groupId;

            self.setState({ appBinding: _theResp.data.Application, groupId: groupId }, self.getCapsuleSite)
        })
    }

    getCapsuleSite() {
        axios.get("/application/capsule/site").then(_theSite => {
            this.setState({ capsuleSite: _theSite.data }, () => {
                if (this.state.capsuleSite.needsIntegration && !this.state.capsuleSite.invalidUser) {
                    this.performSync()
                }
                if (this.state.capsuleSite.invalidUser) {
                    return this.setState({
                        authenticationFailed: true
                    })
                }
                this.getGroups()
            })
        }).catch(err => {
            if (err.data.error && typeof err.data.error === 'string') {
                if (err.data.error.indexOf('401') === 0) {
                    return this.setState({
                        authenticationFailed: true
                    })
                }
            }
            this.props.history.push('/cp/company/integrations')
        })
    }


    getGroups() {
        axios.get("/group").then(_theGroups => {
            if (this.state.groupId == '' || this.state.groupId == '-1') {
                return this.props.history.push('/cp/capsule')
            }
            this.setState({
                groupData: _theGroups.data.Groups,
                isLoaded: true,
                forceSetup: false
            })
        })
        axios.get('/customfield').then(res => this.setState({ customFields: res.data.CustomFields }))
        this.getStatus()
    }


    resetGroup() {
        this.setState({ groupId: -1 })
    }

    updateGroup(newGroupId) {

        var self = this;
        var appBinding = this.state.appBinding;
        if (!appBinding.appSettings) appBinding.appSettings = {};

        appBinding.appSettings.groupId = newGroupId;
        axios.put("/application/account/" + this.state.appBinding.id, this.state.appBinding).then(_theRes => {
            return axios.get("/application/capsule/setupGroupAndImport")
        }).then(_res => {
            self.setState({ groupId: newGroupId, importing: true, importId: _res.data.id }, () => { self.checkForImportUpdate(); self.getGroups() });

        })

    }

    checkForImportUpdate() {
        var self = this;
        axios.get('/group/' + this.state.groupId + '/import/' + this.state.importId).then(res => {
            self.setState({ importProgress: res.data.PercentComplete, importJob: res.data.ImportJob })
            if (res.data.PercentComplete < 100) {
                this.timer = setTimeout(this.checkForImportUpdate, 1000);
            }
            else {
                self.setState({ importing: false, importProgress: 100 }, this.getAppBinding)
            }
        })
    }

    popDisconnect() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <IncDisconnect goDisconnect={this.goDisconnect} />, true, "500px"));
    }

    goDisconnect() {
        var self = this;
        axios.get('/application/capsule/disconnect').then(res => {
            self.props.history.push('/cp/company/integrations')
        })
    }

    performSync() {
        this.setState({ importing: true })
        axios.put('/application/capsule/resync').then(response => {
            this.setState({
                importId: response.data.id
            }, this.checkForImportUpdate)
        })
    }

    setupHooks() {
        const content = <div>
            <h4 className="mar-b25">Select which triggers send data back to Capsule</h4>
            <div className="mar-b25">You can also setup these triggers in a Campaign or within an Automation to use a more targetted trigger</div>
            <Hooks appSettings={this.state.appBinding.appSettings} app={this.state.appData} appBinding={this.state.appBinding} customFields={this.state.customFields}
                onChange={() => {
                    axios.put("/application/account/" + this.state.appBinding.id, this.state.appBinding).catch(() => { })
                }}
            />
            <div className='row mar-t20'>
                <div className="col-xs-12">
                    <UILIB.Button text="Close" className="button-primary" onClick={() => {
                        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, 'right', null))
                    }} />
                </div>
            </div>
        </div>
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", content, true, "100vw"));

    }

    finishSetup(job) {
        this.getApp()
        this.setState({
            importing: true,
            importId: job.id,
            importJob: job
        }, this.checkForImportUpdate);
    }

    getStatus() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, 'right', null))
        return axios.get('/application/capsule/status?skipSubs=true').then(res => this.setState({ status: res.data }))
    }

    async resyncLists() {
        this.props.dispatch(siteMaster.alterSnackbar(true, 'Syncing Lists'))
        await axios.get('/application/capsule/synclists')
        await this.getStatus()
        this.props.dispatch(siteMaster.alterSnackbar(true, 'Lists Synced'))
    }

    async resyncTags() {
        this.props.dispatch(siteMaster.alterSnackbar(true, 'Syncing Tags'))
        await axios.get('/application/capsule/synctags')
        await this.getStatus()
        this.props.dispatch(siteMaster.alterSnackbar(true, 'Tags Synced'))
    }

    async resyncFields() {
        this.props.dispatch(siteMaster.alterSnackbar(true, 'Syncing Fields'))
        await axios.get('/application/capsule/syncfields')
        await this.getStatus()
        this.props.dispatch(siteMaster.alterSnackbar(true, 'Fields Synced'))
    }

    selectLists() {
        const self = this
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, 'right', <SelectLists accApp={this.state.appBinding} onFinish={async selected => {
            const binding = self.state.appBinding
            binding.appSettings.lists = selected
            self.setState({ appBinding: binding })
            self.props.dispatch(siteMaster.alterSnackbar(true, 'Lists Updated'))
            await axios.put("/application/account/" + self.state.appBinding.id, binding)
            self.props.dispatch(siteMaster.alterSiteDrawer(false, false, 'right', null))
            self.resyncLists()
        }} />, true, 400))
    }

    reconnect() {
        var drawer = <AddApp history={this.props.history} reconnect={true} appId={8} browse={url => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, false));
            if (url.indexOf('http') === 0) {
                window.open(url, '_self')
            } else {
                this.props.history.push(url)
            }
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, false, '100vw', 'full'));
    }

    render() {
        if (this.state.authenticationFailed) return <AuthFailed accApp={this.state.appBinding} reconnect={this.reconnect.bind(this)} />
        if (!this.state.isLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>
        if (this.state.capsuleSite.alreadyConnected) return <AlreadyConnected />


        var currentGroup = this.state.groupData.find(theRow => theRow.id == this.state.groupId);
        if (!currentGroup || this.state.groupId == '' || this.state.groupId == '-1' || this.state.forceSetup) return <Setup onFinish={this.finishSetup.bind(this)} inline={true} />

        return <div>
            <UILIB.Paper>
                <UILIB.Row className="middle-xs">
                    <UILIB.Column xs={12} sm={12} md={6} lg={6}>
                        <div className="quickFlex">
                            <div className="integration-avatar rounded" style={{ backgroundImage: "url(" + this.state.appData.appLogoUri + ")" }}></div>
                            <div className="mar-l15" style={{ flexGrow: 1 }}>
                                <h4 className='mar-b5'>Capsule CRM</h4>
                                <div>Connected as "<a className="simple-link" target="_blank" href={this.state.capsuleSite.url}>{this.state.capsuleSite.companyName}</a>"</div>
                            </div>
                        </div>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={12} md={6} lg={6} className="end-xs">
                        <UILIB.Button className="button-red" onClick={this.popDisconnect}>
                            Disconnect
                        </UILIB.Button>
                    </UILIB.Column>
                </UILIB.Row>
            </UILIB.Paper>

            {!!this.state.capsuleSite.error && <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={12} lg={12}>
                    <UILIB.Paper>
                        <h4 className="no-marg">{this.state.capsuleSite.error}</h4>
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>}

            {!!this.state.capsuleSite.needsIntegration &&
                <UILIB.Paper className="paper-primary">
                    <div className='quickFlex '>
                        <UILIB.Icons icon="warning" color="white" style={{ height: 40, width: 40 }} className="mar-r20" />
                        <div style={{ flexGrow: 1 }}>
                            <h4 className='mar-b10'>There is a new version of the Capsule integration available</h4>
                            <div>We are now syncing your Tags, Lists and Custom Fields from Capsule. And contact marketing activity is available in Capsule</div>
                        </div>
                        <UILIB.Button>Learn More</UILIB.Button>
                    </div>
                </UILIB.Paper>}

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={6} lg={6}>
                    <UILIB.Paper className="mar-b0" style={{ height: 270 }}>
                        <h4 className="mar-b10 quickFlex"><div style={{ display: 'inline-flex', marginRight: 10 }}><UILIB.Icons icon="avatars3" circle={true} /></div> Contacts</h4>
                        <p>Capsule is connected and automatically syncing your contacts with the following group. If you add, update or delete a contact in Capsule it will be updated here.</p>
                        <div className='mar-b20'>
                            <UILIB.Button className="secondary button-sml" onClick={() => this.props.history.push('/cp/groups/' + this.state.groupId)} text={currentGroup.groupName} />
                            <span><a className='pad-left text-sml text-grey text-heavy' onClick={this.resetGroup}>Change Group</a></span>
                        </div>
                        {!this.state.importing && <div style={{ margin: 'auto 0' }} className="text-xsml">If something appears incorrect then you can trigger a full resync of your contacts and settings <a onClick={this.performSync}>here</a></div>}
                        {this.state.importing && <div className="mat-t10"><p>Performing Contact, Tag, List and Field Sync</p>
                            {!!this.state.importJob && <UILIB.SmallLineGraph width="100%" height="5" color={'#FF9425'}
                                val1={this.state.importJob ? this.state.importJob.percentComplete === 100 ? this.state.importJob.recordsTotal : this.state.importJob.recordsInvalid + this.state.importJob.recordsExist + this.state.importJob.newSubscribersImported : this.state.importProgress}
                                val2={this.state.importJob ? this.state.importJob.recordsTotal : 100} />}
                            {!this.state.importJob && <UILIB.LoadingLine outerStyle={{ marginTop: '3px !important' }} color={'#FF9425'} className="no-marg" height={10} />}
                        </div>}
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} lg={6} className="mar-b25">
                    <UILIB.Paper className="height-100 mar-b0">
                        <h4 className="mar-b10 quickFlex"><div style={{ display: 'inline-flex', marginRight: 10 }}><UILIB.Icons icon="chart" circle={true} /></div> Sync history</h4>
                        <ApplicationTransactionsGraph applicationId={this.state.appData.id} />
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>

            {!!this.state.status && <UILIB.Row>
                <UILIB.Column xs={12} md={4}>
                    <UILIB.Paper>

                        <h4 className="mar-b10 quickFlex"><div style={{ display: 'inline-flex', marginRight: 10 }}><UILIB.Icons icon="filter" circle={true} /></div> Lists</h4>
                        <div className='mar-b20'>{this.state.status.segments} Capsule Lists in Transpond as Segments</div>
                        <div className="quickFlex">
                            <UILIB.Button className="button-sml mar-r10" onClick={this.resyncLists.bind(this)}>Resync</UILIB.Button>
                            <UILIB.Button className="button-sml button-primary" onClick={this.selectLists.bind(this)}>Select</UILIB.Button>
                        </div>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} md={4}>
                    <UILIB.Paper>
                        <h4 className="mar-b10 quickFlex"><div style={{ display: 'inline-flex', marginRight: 10 }}><UILIB.Icons icon="tag" circle={true} /></div> Tags</h4>
                        <div className='mar-b20'>{this.state.status.tags} Capsule Tags in Transpond</div>
                        <div className="quickFlex">
                            <UILIB.Button className="button-sml" onClick={this.resyncTags.bind(this)}>Resync</UILIB.Button>
                        </div>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} md={4}>
                    <UILIB.Paper>

                        <h4 className="mar-b10 quickFlex"><div style={{ display: 'inline-flex', marginRight: 10 }}><UILIB.Icons icon="swatch" circle={true} /></div> Fields</h4>
                        <div className='mar-b20'>{this.state.status.fields} Capsule Fields in Transpond</div>
                        <div className="quickFlex">
                            <UILIB.Button className="button-sml" onClick={this.resyncFields.bind(this)}>Resync</UILIB.Button>
                        </div>
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>}

            <UILIB.Row className="mar-b60">
                <UILIB.Column xs={12} sm={12} md={12} lg={12} >
                    <Actions appData={this.state.appData} appBinding={this.state.appBinding} setup={this.setupHooks.bind(this)} history={this.props.history} subtitle={<p className='no-marg'>Triggers that update <b>Contacts</b> or create <b>Activities</b> in Capsule</p>} />
                </UILIB.Column>

                <UILIB.Column xs={12} sm={12} md={12} lg={12}>
                    <QuickLinks groupId={this.state.appBinding.appSettings.groupId} appData={this.state.appData} />
                </UILIB.Column>
            </UILIB.Row>
        </div >

    };


};


function SelectLists({ accApp, onFinish = () => { } }) {
    const [lists, setLists] = useState(null)
    const [selected, setSelected] = useState(Array.isArray(accApp.appSettings.lists) ? accApp.appSettings.lists : null)

    const select = (id) => {
        if (id === 'all') {
            setSelected(null)
        } else if (id === 'none') {
            setSelected([])
        } else {
            const arr = (selected || []).slice()
            const index = arr.indexOf(id)
            if (index > -1) {
                arr.splice(index, 1)

            } else {
                arr.push(id)
            }
            setSelected(arr)
        }
    }

    useEffect(() => {
        axios.get('/application/capsule/getlists').then(res => setLists(res.data))
    }, [])

    if (!lists) return <UILIB.LoadingIcons />

    return (
        <div>
            <h4 className='mar-b25'>Select Lists to Sync</h4>
            <div className='mar-b25'>By default we import all your Capsule lists as segments, if you only want some of your lists to stay in sync with Transpond then filter below. If you do this new lists will not automatically appear and you will need to select them here.</div>
            <UILIB.ButtonSelect headerText={selected === null ? 'All Lists' : selected.length === 0 ? 'No Lists' : `${selected.length} Lists`} outerClassName="mar-b25">
                <ul>
                    <li><UILIB.CheckBox checked={!selected} onClick={() => select('all')}>All Lists</UILIB.CheckBox></li>
                    <li><UILIB.CheckBox checked={selected && selected.length === 0} onClick={() => select('none')}>No Lists</UILIB.CheckBox></li>
                    {lists.map(l => <li>
                        <UILIB.CheckBox key={l.id} checked={selected && selected.some(s => s === l.id)} onClick={() => select(l.id)}>{l.name}</UILIB.CheckBox>
                    </li>)}
                </ul>
            </UILIB.ButtonSelect>
            <UILIB.Button onClick={() => onFinish(selected)}>Save</UILIB.Button>
        </div>
    )
}

function AuthFailed({ accApp, reconnect = () => { } }) {
    return (
        <UILIB.Row className="center-xs">
            <UILIB.Column xs={12} sm={10} md={8} lg={6}>
                <UILIB.Paper>
                    <h4 className='mar-b25'>Connection to Capsule Failed</h4>
                    <div className='mar-b25'>Your Capsule integration has been disconnected, click here to set the connection back up or go to the integration in your Capsule account and setup the connection again. The user you login with in Capsule will need to be an administrator</div>
                    <div className="quickFlex center-xs">
                        <UILIB.Button onClick={reconnect} className="mar-r10 button-primary">Reconnect</UILIB.Button>
                        {!!accApp.appSettings && !!accApp.appSettings.subdomain && <UILIB.Button target="_blank" href={'https://' + accApp.appSettings.subdomain + '.capsulecrm.com/settings/integrations'} className="mar-r10" iconRight={<UILIB.Icons icon="arrowRight" />}>View in Capsule</UILIB.Button>}
                    </div>

                </UILIB.Paper>
            </UILIB.Column>
        </UILIB.Row>
    )
}

function AlreadyConnected() {
    return (
        <UILIB.Row className="center-xs">
            <UILIB.Column xs={12} sm={10} md={8} lg={6}>
                <UILIB.Paper>
                    <h4 className='mar-b25'>A Capsule connection for this Transpond account is already detected in Capsule</h4>
                    <div className='mar-b25'>Please contact Capsule or Transpond support to resolve</div>
                    <div className="quickFlex center-xs">
                        <UILIB.Button href="https://transpond.io/contactus" target="_blank" className="mar-r10 button-primary">Contact Support</UILIB.Button>
                    </div>

                </UILIB.Paper>
            </UILIB.Column>
        </UILIB.Row>
    )
}
import axios from "../../../data/http/axios"
import UILIB from '../../../Common-Objects/Lib'
import React from 'react'

export default function IgnoreSite({ site, finish = () => { } }) {
    const toggle = async () => {
        await axios.put('/accountMaster/tracker/' + site.id + '/ignore')
        finish()
    }

    return (
        <div>
            <h4 className="mar-b25">{site.ignore ? 'Unarchive Site' : 'Archive Site'}</h4>
            <div className="mar-b25">When a site is archived we stop tracking visits to this page and hide it for you, the history is still there though</div>

            <UILIB.Button className="button-primary mar-r10" text={site.ignore ? 'Unarchive' : 'Archive'} onClick={toggle} />
            <UILIB.Button text="Cancel" onClick={finish} />

        </div >
    )
}
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

class AutomationGlobalOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delay: Boolean(this.props.automation.options.batchDelay && this.props.automation.options.batchSize && this.props.automation.options.batchUnit)
        }

        this.changeValue = this.changeValue.bind(this);
        this.changeOptions = this.changeOptions.bind(this)
    }


    changeValue(event) {
        var value = event.target.value;
        if (event.target.type == "checkbox") {
            value = event.target.checked;
        }
        var automation = this.props.automation;
        automation[event.target.name] = value;
        this.props.onChange(automation);
    }

    toggleDelay() {
        if (!this.state.delay) {
            return this.setState({ delay: true })
        }
        const options = this.props.automation.options || {}
        options.batchDelay = 0
        this.props.automation.options = options
        this.props.onChange(this.props.automation);
        this.setState({ delay: false })
    }

    changeOptions(event) {
        const options = this.props.automation.options || {}
        let value = event.currentTarget.value
        if (event.currentTarget.type === 'number') {
            value = Number(value)
        }
        options[event.currentTarget.name] = value
        this.props.onChange(this.props.automation);
        this.setState({})
    }


    render() {
        const units = [
            { label: 'minutes', value: 'minutes' },
            { label: 'hours', value: 'hours' }
        ]
        const options = this.props.automation.options || {}
        return <div>
            <h4 className="mar-b25">{i18n.t('automation:add.globalOptions.header')}</h4>
            <div className="mar-b25">{i18n.t('automation:add.globalOptions.subHeader')}</div>


            <UILIB.Toggle outerClassName="mar-b25" name="deletedSubsGetDeleted" after={i18n.t('automation:add.globalOptions.deletedSubsGetDeleted')} value={!!this.props.automation.deletedSubsGetDeleted} onChange={this.changeValue} />

            {this.props.automation.status === 'Paused' && <div>

                <UILIB.Toggle outerClassName="mar-b25" value={this.state.delay} after='Stagger Contacts on Start' onChange={this.toggleDelay.bind(this)} />

                {this.state.delay && <div className="row">
                    <div className="col-xs-4 mar-b25">
                        <UILIB.TextInput label="Batch Size" outerClassName="mar-b25" type="number" value={options.batchSize} name="batchSize" onChange={this.changeOptions} />
                    </div>
                    <div className="col-xs-4 mar-b25">
                        <UILIB.TextInput label="Delay" outerClassName="mar-b25" type="number" value={options.batchDelay} name="batchDelay" onChange={this.changeOptions} />
                    </div>
                    <div className="col-xs-4 mar-b25">
                        <UILIB.Select label="Unit" outerClassName="mar-b25" data={units} value={options.batchUnit} name="batchUnit" onChange={this.changeOptions} />
                    </div>
                </div>}
            </div>}

        </div>
    }
}

export default AutomationGlobalOptions;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import UILIB from '../../../../Common-Objects/Lib';
import axios from '~/data/http/axios';
import PermissionChecker from '~/Classes/permissions';
import i18n from '~/i18n';
import queryString from 'query-string';
import ConversationHolder from './conversationHolder';
import ConversationsHolder from './conversationsHolder';
import CreateConversationHolder from './createConversationHolder';

@connect((store) => {
    return { permissionStore: store.permission, siteMaster: store.siteMaster, account: store.accountMaster, accountUser: store.user }
})
class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            trackers: [],
            inboxes: [],
            inboxId: 0,
            conversations: [],
            currentFilterId: 'open',
            creatingConversation: false
        }
        this.loadInboxes = this.loadInboxes.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.loadConversations = this.loadConversations.bind(this);
        this.selectConversation = this.selectConversation.bind(this);
        this.scrollTopConversations = this.scrollTopConversations.bind(this);
        this.createConversation = this.createConversation.bind(this);
        this.reloadChat = this.reloadChat.bind(this)
    }

    componentDidMount() {
        this.loadInboxes()
    }

    componentWillUnmount() {
        clearTimeout(this.conversationTimer)
    }

    async changeFilter(ev) {
        this.setState({ [ev.target.name]: ev.target.value, activeConversationId: 0, creatingConversation: false }, () => {
            this.loadConversations()
        })
    }

    async loadInboxes() {
        const res = await axios.get('/inboxes?mine=true')
        this.setState({
            inboxes: res.data,
            inboxId: res.data.length === 1 ? res.data[0].id : this.state.inboxId
        }, () => {
            const query = queryString.parse(location.search);
            this.loadConversations(query.chatId)
        })
    }

    async loadConversations(newConversationId) {
        let qs = "?";
        if (this.state.inboxId) qs += "&InboxId=" + this.state.inboxId
        if (this.state.currentFilterId) qs += "&status=" + this.state.currentFilterId

        const res = await axios.get("/inboxes/chat" + qs)
        const conversations = res.data;
        let activeConversationId = this.state.activeConversationId;
        if (!activeConversationId && conversations.length) activeConversationId = conversations[0].id;
        if (newConversationId) activeConversationId = newConversationId
        this.setState({ conversations, activeConversationId, loading: false }, () => {
            this.conversationTimer = setTimeout(this.loadConversations, 10000)
        })
    }

    selectConversation(convId) {
        this.setState({ activeConversationId: convId, creatingConversation: false })
    }

    createConversation() {
        this.setState({ creatingConversation: true })
    }

    scrollTopConversations() {
        let el = document.getElementById("conversationsList");
        el.scrollTop = 0;
    }

    reloadChat() {
        axios.get('/inboxes/chat/' + this.state.activeConversationId).then(res => {
            const index = this.state.conversations.findIndex(c => c.id === this.state.activeConversationId)
            if (index > -1) {
                const conversations = this.state.conversations.slice()
                conversations[index] = res.data
                this.setState({ conversations })
            }
        })
    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons />
        if (!this.state.inboxes.length) {
            return <>
                <UILIB.Paper>
                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={6} md={4}>
                            <img
                                src="https://cdn1.ourmailsender.com/siteContent/noneholders/automation-none.png"
                                style={{ width: "100%" }} />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                            <UILIB.Icons icon="lightning" circle={true} style={{ height: 40, width: 40 }} className="mar-b15" />
                            <h4 className="mar-b15">{i18n.t('support:overview.noWebsitesHeader')}</h4>
                            <div>{i18n.t('support:overview.noWebsitesDescription')}</div>

                            {PermissionChecker('company', this.props.permissionStore.permissions, "write") && <div className="mar-t25">
                                <UILIB.Button
                                    className="button-primary"
                                    onClick={() => { this.props.history.push('/cp/inboxes') }}
                                    text={i18n.t('getStarted')}
                                    iconLeft={<UILIB.Icons icon="plus" />}
                                />
                            </div>
                            }
                        </UILIB.Column>
                    </UILIB.Row>
                </UILIB.Paper>
            </>
        }

        return <UILIB.Row style={{ display: "flex", height: "calc(100vh - 135px)", position: 'relative' }}>

            <UILIB.Column xs={12} sm={4} md={3} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <ConversationsHolder
                    inboxes={this.state.inboxes}
                    inboxId={this.state.inboxId}
                    changeFilter={this.changeFilter}
                    currentFilterId={this.state.currentFilterId}
                    conversations={this.state.conversations}
                    activeConversationId={this.state.activeConversationId}
                    selectConversation={this.selectConversation}
                    createConversation={this.createConversation}
                    loadConversations={() => this.loadConversations()}
                />
            </UILIB.Column >
            {this.state.creatingConversation && <CreateConversationHolder
                inboxes={this.state.inboxes}
                created={(newConvId) => { this.setState({ creatingConversation: false }, () => { this.loadConversations(newConvId) }) }}
                trackers={this.state.trackers}
                inboxId={this.state.inboxId}
                close={() => { this.setState({ creatingConversation: false }) }}
            />}
            {!this.state.creatingConversation && <ConversationHolder
                activeConversationId={this.state.activeConversationId}
                selectConversation={this.selectConversation}
                conversations={this.state.conversations}
                reload={this.reloadChat}
                reloadConversations={this.loadConversations}
                scrollTopConversations={this.scrollTopConversations}
            />}
        </UILIB.Row >
    }
}

export default Support
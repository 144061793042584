import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '../../../../data/http/axios';

class AddGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupName: "",
            error: "",
            groups: [],
            pageLoaded: false,
            currentGroupId: 0,
            addingGroup: false,
            addError: ""
        }

        this.createGroup = this.createGroup.bind(this);
        this.addToGroup = this.addToGroup.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.getGroups = this.getGroups.bind(this);
    }

    componentDidMount() {
        this.getGroups();
    }
    getGroups() {
        axios.get('/mailServer/group').then(_gDat => {
            this.setState({ groups: _gDat.data.Groups, pageLoaded: true })
        })
    }

    createGroup() {
        if (!this.state.groupName || this.state.groupName.length < 3) {
            this.setState({ error: "Enter a longer group name" })
            return;
        }
        this.setState({ error: "" })
        axios.post('/mailServer/group', { groupName: this.state.groupName }).then(_res => {
            this.setState({ currentGroupId: _res.data.group.id, addingGroup: false }, this.getGroups)
        }).catch(err => {
            var errTxt = "There was an error adding the group"
            if (err && err.data && err.data.error) errTxt = err.data.error
            this.setState({ error: errTxt })
        })
    }

    addToGroup() {
        axios.put('/mailServer/group/' + this.state.currentGroupId + "?serverId=" + this.props.server.id).then(_res => {
            this.props.finished();
        }).catch(err => {
            var errTxt = "There was an error adding the server"
            if (err && err.data && err.data.error) errTxt = err.data.error
            this.setState({ addError: errTxt })
        })
    }

    deleteGroup() {
        axios.delete('/mailServer/group/' + this.state.currentGroupId).then(_res => {
            this.setState({ currentGroupId: 0 }, this.getGroups);
            this.props.getGroups(false);
        }).catch(err => {
            var errTxt = "There was an error deleting the group"
            if (err && err.data && err.data.error) errTxt = err.data.error
            this.setState({ addError: errTxt })
        })
    }

    render() {
        if (!this.state.pageLoaded) return <div></div>

        var isAddingGroup = this.state.addingGroup;
        if (!this.state.groups.length) isAddingGroup = true;
        return (
            <div>
                <h4 className="mar-b25">Add {this.props.server.name} <br />to a Group</h4>
                <p>Server groups allow you to combine multiple email servers into a single group. Handy for sending to multiple servers in one click.</p>
                <p>Choose a group to add your server to..</p>

                {(!isAddingGroup) && <div>
                    <div className="form-group mar-b10">
                        <label>
                            Select a Group
                            <div style={{ position: "absolute", right: "0", top: "0" }}><a onClick={() => { this.setState({ addingGroup: true }) }}>Add a new Group</a></div>
                        </label>
                        <UILIB.Select name="groupSelect" placeholder="Select a Group" value={this.state.currentGroupId} onChange={(e) => {
                            this.setState({ currentGroupId: e.target.value })
                        }} data={this.state.groups.map(g => {
                            return {
                                label: g.name,
                                value: g.id
                            }
                        })} error={this.state.addError} />
                    </div>
                    {(this.state.currentGroupId > 0) && <div>
                        <div style={{ position: "relative", textAlign: "right" }}>
                            <a onClick={this.deleteGroup} style={{ fontSize: "11px", textTransform: "uppercase" }}>Delete this Group</a>
                        </div>
                        <div>
                            <UILIB.Button text="Add to this Group" className="button-sml" onClick={this.addToGroup} />
                        </div>
                    </div>}
                </div>}

                {(isAddingGroup) && <div>
                    <div className="form-group">
                        <label>Your new Group Name</label>
                        <UILIB.TextInput type="text" name="groupName" value={this.state.groupName} error={this.state.error} onChange={(ev) => {
                            this.setState({ groupName: ev.target.value })
                        }} />
                    </div>
                    <UILIB.Button text="Create Group" onClick={this.createGroup} className="button-sml" />
                    {(this.state.groups.length > 0) && <a onClick={() => { this.setState({ addingGroup: false }) }} style={{ marginLeft: "10px", fontSize: "12px" }}>Forget it</a>}
                </div>
                }
            </div>
        );
    }
}

export default AddGroup;
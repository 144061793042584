import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import UILIB from '../../../../Common-Objects/Lib';
import axios from '../../../../data/http/axios';
import i18n from '~/i18n';
import FormatNumberFuncs from '../../../../Classes/numberFormatFunctions';
import DateTimeFunctions from '../../../../Classes/dateTimeFunctions';

export default function FormTable({ edit = () => { } }) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const history = useHistory()

    const getData = async () => {
        setLoading(true)
        const results = await axios.get('/group/0/form?status=1&limit=5')
        setData(results.data.Forms.map(row => {
            var formChip = "";
            switch (row.formType) {
                case 0:
                    formChip = <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-purple">{i18n.t('form:index.hosted')}</UILIB.SquareChip>
                    break;
                case 1:
                    formChip = <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-orange">{i18n.t('form:index.embed')}</UILIB.SquareChip>
                    break;
                case 2:
                    formChip = <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-red">{i18n.t('form:index.popup')}</UILIB.SquareChip>
                    break;
                case 3:
                    formChip = <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-blue">HTML</UILIB.SquareChip>
                    break;
            }

            let app = '';
            if (row.app) {
                app = <UILIB.Avatar for={row.id + '-' + row.app.id} key={row.id + '-' + row.app.id} style={{ height: 20, width: 20, marginLeft: 5 }} tooltip={row.app.Application.appName + ' Group'} src={row.app.Application.appLogoUri} />
            }

            let impressionsClass = "square-chip-red";
            if (row.impressions > 0) impressionsClass = "square-chip-orange";
            if (row.impressions > 10) impressionsClass = "square-chip-green";

            const signupsAvg = (100 / row.impressions) * row.signups;
            const signupsColor = signupsAvg > 50 ? 'square-chip-green' : signupsAvg > 25 ? 'square-chip-orange' : ''
            return {
                formName: {
                    headerValue: i18n.t('form:index.name'),
                    value: row.formName,
                    orderBy: false
                },
                groupName: {
                    headerValue: i18n.t('group'),
                    value: <UILIB.SquareChip className="square-chip-large" iconRight={app ? app : ""}>{row.Group.groupName}</UILIB.SquareChip>,
                },
                formType: {
                    headerValue: i18n.t('form:index.type'),
                    value: formChip,
                    orderBy: false,
                    align: "center",
                    width: 60
                },
                impressions: {
                    headerValue: i18n.t('form:index.impressions'),
                    value: <UILIB.SquareChip className={"square-chip-large square-chip-min-width " + impressionsClass}>{FormatNumberFuncs.formatNumber(row.impressions)}</UILIB.SquareChip>,
                    orderBy: false,
                    helper: i18n.t('form:index.impressionsHelper'),
                    align: "center",
                    width: 160
                },
                signups: {
                    headerValue: i18n.t('form:index.signups'),
                    value: <div>
                        <div style={{ fontWeight: 'bold', color: 'black' }}>{FormatNumberFuncs.formatNumber(row.signups)}</div>
                        <UILIB.SquareChip className={signupsColor}>{(signupsAvg || 0).toFixed(2)}%</UILIB.SquareChip>
                    </div>,
                    orderBy: false,
                    helper: i18n.t('form:index.signupsHelper'),
                    align: "center",
                    width: 160
                },
                recent: {
                    headerValue: 'Most Recent Signup',
                    value: row.max ? DateTimeFunctions.formatDateTime(row.max, '2') : 'Never',
                    orderBy: false,
                    align: "center",
                    width: 60
                },
                createdAt: {
                    headerValue: i18n.t('form:index.created'),
                    value: DateTimeFunctions.formatDateTime(row.createdAt, '2'),
                    orderBy: false,
                    align: "center",
                    width: 60
                },
            }
        }))
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <UILIB.Paper isLoading={loading} iconRight={<UILIB.Icons icon="pencil" color="grey" />} iconRightClick={edit}>
            <div className="quickFlex mar-b24" style={{ justifyContent: "space-between" }}>
                <h4>Forms</h4>
                <UILIB.Row>
                    <UILIB.Column xs={12} margin={0} className="end-xs">
                        <UILIB.Button className="primary" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => history.push('/cp/forms')}>View Forms</UILIB.Button>
                    </UILIB.Column>
                </UILIB.Row>
            </div>
            {!!data && <UILIB.DataTable1
                noResultsTxt={i18n.t('form:index.noForms2')}
                tableData={data}
            />}
            {!data && <div style={{ height: 300 }}>
                <UILIB.LoadingIcons />
            </div>}
        </UILIB.Paper>
    )
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';

//CONNECT TO STORE
@connect((store) => {
    return {
        user: store.user,
        siteMaster: store.siteMaster,
        pageLoaded: false
    }
})

export default class CleanTemplate extends React.Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
            pageLoaded: false,
            jobs: []

        };
        this.getJobs = this.getJobs.bind(this);
        this.viewListClean = this.viewListClean.bind(this);
        this.startListClean = this.startListClean.bind(this);
    }

    componentDidMount() {
        if (this.props.siteMaster.listCleanDisabled) return this.props.history.push("/cp")
        this.getJobs();
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer)
    }

    startListClean() {
        this.props.history.push('/cp/subscribers/clean/startclean')
    }


    getJobs() {
        var self = this;
        axios.get('/listcleaner')
            .then(response => {

                var tableData = response.data.ListCleanJobs.map(item => {

                    return {
                        id: {
                            headerValue: "#",
                            value: item.id,
                            width: 50,
                            align: "center"
                        },
                        createdAt: {
                            headerValue: i18n.t('subscribers:listClean.overview.startDate'),
                            value: DateTimeFunctions.formatDateTime(item.createdAt, 2)
                        },
                        status: {
                            headerValue: i18n.t('subscribers:listClean.overview.status'),
                            value: <UILIB.SquareChip className={item.status > 4 ? "square-chip-green" : "square-chip-orange"}>{item.status < 5 ? "Processing" : "Finished"}</UILIB.SquareChip>,
                            align: "left"
                        },
                        group: {
                            headerValue: i18n.t('subscribers:listClean.overview.group'),
                            value: item.groups.map((g, i) => {
                                return g ? <UILIB.SquareChip
                                    key={i}
                                    className="mar-r10"
                                    onClick={() => this.props.history.push('/cp/groups/' + g.id)}
                                    iconRight={<UILIB.Icons icon="magnifier" style={{ width: 15, height: 15 }} />}
                                >{g.groupName}
                                </UILIB.SquareChip>
                                    : <UILIB.SquareChip
                                        className="mar-r10"
                                        key={i}>
                                        {i18n.t('subscribers:listClean.overview.noGroupFound')}
                                    </UILIB.SquareChip>
                            })
                        },
                        processed: {
                            headerValue: i18n.t('subscribers:listClean.overview.processed'),
                            value: <UILIB.SmallLineGraph width="100" height="5" val1={item.doneCount} val2={item.subCount || 0} />,
                            align: "center"
                        },
                        perfect: {
                            headerValue: i18n.t('subscribers:listClean.overview.perfect'),
                            value: <UILIB.SmallLineGraph width="100" height="5" val1={item.perfectCount} val2={item.subCount || 0} />,
                            align: "center"
                        },
                        imperfect: {
                            headerValue: i18n.t('subscribers:listClean.overview.imperfect'),
                            value: <UILIB.SmallLineGraph width="100" height="5" val1={item.imperfectCount} val2={item.subCount || 0} />,
                            align: "center"
                        },
                        failed: {
                            headerValue: i18n.t('subscribers:listClean.overview.failed'),
                            value: <UILIB.SmallLineGraph width="100" height="5" val1={item.failedCount} val2={item.subCount || 0} />,
                            align: "center"
                        },
                        optionsCol:
                        {
                            headerValue: " ",
                            value: <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                <UILIB.Button className="button-sml" text="View" onClick={() => { this.props.history.push("/cp/subscribers/clean/" + item.id) }} />
                            </div>,
                            orderBy: false,
                            fixed: true,
                            width: 20
                        }

                    }
                })

                self.setState({ pageLoaded: true, jobs: tableData }, () => {
                    self.timer = setTimeout(self.getJobs, 10000)
                });
            });
    }

    viewListClean(cleanId) {

    }

    render() {
        if (!this.state.pageLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>

        return <div>



            <UILIB.Row>
                <UILIB.Column xs={12} sm={6} className="mar-b25">
                    {this.props.siteMaster.siteId == 1 && <UILIB.Hint href="https://kb.transpond.io/article/113-how-to-clean-your-lists" className="hide-xs mar-b25 mar-l0" iconLeft={<UILIB.Icons icon="questionCircle" />}>Click here to find out what list cleans are, and how they can hugely help your campaigns</UILIB.Hint>}
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="mar-b25 end-xs">
                    <UILIB.Button
                        className="button-primary"
                        onClick={this.startListClean}
                        text={i18n.t('subscribers:listClean.overview.startListClean')}
                        iconLeft={<UILIB.Icons icon="sparkle" />}
                    />
                </UILIB.Column>

                <UILIB.Column xs={12}>

                    {!!this.state.jobs.length && <div>
                        <h4 className="mar-b25">{i18n.t('subscribers:listClean.overview.listCleanHistoryTitle')}</h4>
                        <p className="mar-b25">{i18n.t('subscribers:listClean.overview.listCleanHistorySubTitle')}</p>
                        <UILIB.DataTable1 noResultsTxt={<span> {i18n.t('subscribers:nav.listCleanNoResults')}</span>} tableData={this.state.jobs} loadingData={false}
                            width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn={"createdAt"}
                            sortedDirection={"desc"}
                        />
                    </div>
                    }
                    {!this.state.jobs.length &&
                        <UILIB.Paper>
                            <h4 className="mar-b25">You have not cleaned any lists yet.</h4>
                            <p>Start a list clean and all the emails in your chosen groups will be automatically scanned to find and remove invalid, non existant and expired email addresses.</p>
                            <p>Once the scan is finished you can choose to remove any invalid or dubious addresses found.</p>
                            <p className="mar-b25">Removing invalid email addresses is extremely important and will greatly increase the chance of your email reaching the inbox.</p>
                            <UILIB.Button
                                className="button-primary"
                                onClick={this.startListClean}
                                text={i18n.t('subscribers:listClean.overview.startListClean')}
                                iconLeft={<UILIB.Icons icon="sparkle" />}
                            />
                        </UILIB.Paper>
                    }

                </UILIB.Column>

            </UILIB.Row>


        </div >
    };
};
import React from 'react'

export default function IconsSwatch({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 2.00006C3.46957 2.00006 2.96086 2.21077 2.58579 2.58585C2.21071 2.96092 2 3.46963 2 4.00006V15.0001C2 15.7957 2.31607 16.5588 2.87868 17.1214C3.44129 17.684 4.20435 18.0001 5 18.0001C5.79565 18.0001 6.55871 17.684 7.12132 17.1214C7.68393 16.5588 8 15.7957 8 15.0001V4.00006C8 3.46963 7.78929 2.96092 7.41421 2.58585C7.03914 2.21077 6.53043 2.00006 6 2.00006H4ZM5 16.0001C5.26522 16.0001 5.51957 15.8947 5.70711 15.7072C5.89464 15.5196 6 15.2653 6 15.0001C6 14.7348 5.89464 14.4805 5.70711 14.293C5.51957 14.1054 5.26522 14.0001 5 14.0001C4.73478 14.0001 4.48043 14.1054 4.29289 14.293C4.10536 14.4805 4 14.7348 4 15.0001C4 15.2653 4.10536 15.5196 4.29289 15.7072C4.48043 15.8947 4.73478 16.0001 5 16.0001ZM10 14.2431L14.9 9.34306C15.2749 8.96801 15.4856 8.45939 15.4856 7.92906C15.4856 7.39873 15.2749 6.89012 14.9 6.51506L13.485 5.10006C13.1099 4.72512 12.6013 4.51449 12.071 4.51449C11.5407 4.51449 11.0321 4.72512 10.657 5.10006L10 5.75706V14.2431ZM16 18.0001H9.071L15.071 12.0001H16C16.5304 12.0001 17.0391 12.2108 17.4142 12.5858C17.7893 12.9609 18 13.4696 18 14.0001V16.0001C18 16.5305 17.7893 17.0392 17.4142 17.4143C17.0391 17.7893 16.5304 18.0001 16 18.0001Z" fill={fillColor} />
        </svg>
    )
}
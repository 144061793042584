import { combineReducers } from 'redux';
import user from './usersReducer';
import accountMaster from './accountMasterReducer';
import siteMaster from './siteMasterReducer';
import permission from './permissionReducer'


export default combineReducers({
    user: user,
    accountMaster: accountMaster,
    siteMaster: siteMaster,
    permission
})
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';


//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class SalesMateAddAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appData: {},
            isLoaded: false,
            sessionKey: "",
            keyError: ""
        };

        this.getApp = this.getApp.bind(this);
        this.save = this.save.bind(this);
        this.disconnect = this.disconnect.bind(this);
    }

    componentDidMount() {
        this.getApp();
    }

    getApp() {
        var self = this;
        axios.get("/application/account/app/38").then(_theApp => {
            self.setState({ appData: _theApp.data.Application, isLoaded: true })
        })
    }

    save() {
        var self = this;
        var appData = this.state.appData;
        appData.sessionKey = this.state.sessionKey;
        axios.post('/application/salesmate/addKey', appData).then(() => {
            self.props.history.push('/cp/company/integrations/apps/salesmate/changeFields')
        }).catch(err => {
            self.setState({ keyError: err.data.error })
        })
    }

    disconnect() {

        var self = this;
        axios.delete('/application/account/' + this.state.appData.id).then(res => {
            self.props.history.push('/cp/company/integrations?show=38')
        })

    }
    render() {

        if (this.state.isLoaded == false) return <div> <UILIB.LoadingIcons iconType="2" /></div>

        return <div>
            <UILIB.Paper>
                <UILIB.Row>
                    <UILIB.Column xs={12}>
                        <h4 className="mar-b25">Connect to Salesmate</h4>
                        <p><strong>Before we can get started, we need to connect to your Salesmate account.</strong></p>
                        <p>Please visit <a href={'https://' + this.state.appData.appSettings.crmurl + '/#/app/user/accesskey'} target="_blank">{this.state.appData.appSettings.crmurl + '/#/app/user/accesskey'}</a> and copy your <strong>Session Key</strong> into the box below</p>
                    </UILIB.Column>
                    <UILIB.Column xs={12} margin={0} className="mar-b25">
                        <UILIB.TextInput label="Your Salesmate Session Key" placeholder="" value={this.state.sessionKey} onChange={(event) => { this.setState({ sessionKey: event.target.value }) }} error={this.state.keyError} />
                    </UILIB.Column>

                    <UILIB.Column xs={6}>
                        <UILIB.Button text="Back" onClick={this.disconnect} />
                    </UILIB.Column>
                    <UILIB.Column xs={6} className="end-xs">
                        <UILIB.Button disabled={!this.state.sessionKey || !this.state.sessionKey.length} className="button-primary" text="Save and Continue" onClick={this.save} />
                    </UILIB.Column>
                </UILIB.Row>
            </UILIB.Paper>

        </div >

    };


};



import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'
import DragDropFunctions from '../../../dragDropFunctions';

export default class ComponentSelect extends Component {
    constructor(props) {
        super(props)
        this.change = this.change.bind(this);
    }

    change(event) {
        var thisVal = String(event.currentTarget.value);
        this.props.updateVal(this.props.path, thisVal);
    }

    render() {
        var thisOptions = this.props.options;
        if (thisOptions && typeof thisOptions != "object") {

            if (thisOptions.indexOf('OPTIONS') > 0) {
                thisOptions = DragDropFunctions.lookupVariables(thisOptions);
            }
        }


        var style = {};
        if (this.props.elementData.optionFullWidth) style = { flex: "100%" }
        var thisVal = this.props.value;
        if (thisVal == "arial") thisVal = "Arial";

        thisOptions = thisOptions.map(o => {
            if (typeof o !== "object") {
                return { label: String(o), value: String(o) }
            }
            return { ...o, value: String(o.value) }
        })

        return <div className="dd_toolHolder" style={style}>
            <div className="dd_inputTool">
                <UILIB.Select headerContentStyle={{ maxWidth: 450 }} label={this.props.title} data={thisOptions} onChange={this.change} value={thisVal} />
            </div>

        </div>




    }
}



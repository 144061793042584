import React, { useEffect, useState, useReducer } from "react";
import UILIB from "~/Common-Objects/Lib";
import Dialog from "../../../../../components/dialog";
import Button from "../../../../../components/button";
import TextInput from "../../../../../components/textInput";
import SettingsSection from "../../../../../components/settingsSection";
import * as styles from "./index.module.scss";
import axios from "~/data/http/axios";
import i18n from "~/i18n";

export default function AddEditPromptDialog({
  promptToEdit,
  isOpen,
  onChanged,
  channel,
  ...rest
}) {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState(1);
  const [suggestions, setSuggestions] = useState([""]);
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [locations, setLocations] = useState([""]);
  const [formErrors, setFormErrors] = useState(null);

  const [locationType, setLocationType] = useState(0);
  const [filterType, setFilterType] = useState(0);
  const [filterValues, setFilterValues] = useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    {
      load: false,
      delay: false,
      delayValue: 0,
      scrollToElement: false,
      scrollToElementType: "id",
      scrollToElementValue: "",
      scrollPercentage: false,
      scrollPercentageValue: 0,
    }
  );

  useEffect(() => {
    if (promptToEdit) {
      setName(promptToEdit.name);
      setType(promptToEdit.type);
      setSuggestions(promptToEdit.suggestions);
      setWelcomeMessage(promptToEdit.welcomeMessage);
      setLocations(promptToEdit.locations);
      setFilterValues(promptToEdit.filterValues);
      setFilterType(promptToEdit.filterType);
      setLocationType(promptToEdit.locationType);
      setType(promptToEdit.type);
    }
    setLoading(false);
  }, []);

  //array helpers
  function addItem(array) {
    return [...array, ""];
  }
  function updateItem(array, val, index) {
    return array.map((item, i) => {
      if (index === i) {
        return val;
      }

      return item;
    });
  }
  function duplicateItem(array, index) {
    return [...array.slice(0, index), array[index], ...array.slice(index)];
  }
  function deleteItem(array, index) {
    return array.filter((_, i) => i !== index);
  }
  async function handleSubmit() {
    setSubmitting(true);

    try {
      validateForm();
      await saveChanges();

    } finally {
      setSubmitting(false);
    }
  }
  async function saveChanges() {
    const body = {
      name,
      type,
      suggestions,
      welcomeMessage,
      locationType,
      locations,
      filterType,
      filterValues,
    };

    try {
      if (promptToEdit) {
        await axios.put(`/inboxes/${channel.InboxId}/channel/${channel.id}/prompt/${promptToEdit.id}`, body);
      }
      else {
        await axios.post(`/inboxes/${channel.InboxId}/channel/${channel.id}/prompt`, body)
      }
      onChanged();
    }
    catch (err) {
      console.log(err);
      setFormErrors({ saveError: i18n.t("chat:channel.chat.prompts.new.err") })
    }
  }
  function validateForm() {
    try {
      let errors = {};
      let foundError = false;
      if (!name || name.trim().length < 2) {
        errors.name = i18n.t("chat:channel.chat.prompts.new.errName");
        foundError = true;
      }
      if (locationType == 1) {
        if (locations.length == 0) {
          errors.locations = i18n.t("chat:channel.chat.prompts.new.errLocation");
          foundError = true;
        }
        for (let i = 0; i < locations.length; i++) {
          if (locations[i].trim().length < 2) {
            errors.locations = "One of your locations is blank";
            foundError = true;
          }
        }
      }
      if (filterType == 0) {
        if (isNaN(filterValues.delayValue) || !filterValues.delayValue || filterValues.delayValue < 0) {
          errors.delayValue = i18n.t("chat:channel.chat.prompts.new.errDelay");
          foundError = true;
        }
      }
      if (filterType == 1) {
        if (!filterValues.scrollToElementValue || filterValues.scrollToElementValue.trim().length < 2) {
          errors.scrollToElementValue = i18n.t("chat:channel.chat.prompts.new.errSCroll")
          foundError = true;
        }
      }
      if (filterType == 2) {
        if (isNaN(filterValues.scrollPercentage) || !filterValues.scrollPercentageValue || filterValues.scrollPercentageValue > 100 || filterValues.scrollPercentageValue < 0) {
          errors.scrollPercentageValue = i18n.t("chat:channel.chat.prompts.new.errScroll")
          foundError = true;
        }
      }

      if (type == 1) {
        if (welcomeMessage.trim().length < 2) {
          errors.welcomeMessage = i18n.t("chat:channel.chat.prompts.new.errMessage")
          foundError = true;
        }
      }
      if (type == 2) {
        if (suggestions.length == 0) {
          errors.suggestions = i18n.t("chat:channel.chat.prompts.new.errSuggestion")
          foundError = true;
        }
        for (let i = 0; i < suggestions.length; i++) {
          if (suggestions[i].trim().length < 1) {
            errors.suggestions = i18n.t("One of your suggestions is blank")
            foundError = true;
          }
        }
      }

      if (foundError == true) throw errors;
      setFormErrors(null);
    } catch (error) {
      setFormErrors(error);
      throw error;
    }
  }



  return (
    <Dialog
      fullScreen
      open={isOpen}
      title={promptToEdit ? i18n.t("chat:channel.chat.prompts.new.headerEdit") : i18n.t("chat:channel.chat.prompts.new.headerAdd")}
      description={i18n.t("chat:channel.chat.prompts.new.subheader")}
      submitting={submitting}
      {...rest}
    >
      {loading && <></>}
      {!loading && <>
        <form
          className={styles.form}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div>
            <TextInput
              label={i18n.t("chat:channel.chat.prompts.new.name")}
              value={name}
              className="mar-b24"
              error={formErrors?.name}
              onChange={(e) => setName(e.target.value)}
              placeholder={i18n.t("chat:channel.chat.prompts.new.namePlaceholder")}
            />
            <div className={styles.typesWrapper}>
              <UILIB.FeatureCard
                iconLeft={<UILIB.Svgs icon="conversationsPromptsNewConv" />}
                title={i18n.t("chat:channel.chat.prompts.new.new")}
                description={i18n.t("chat:channel.chat.prompts.new.newDesc")}
                selected={Number(type) === 1}
                onClick={() => { setType(1) }}
              />
              <UILIB.FeatureCard
                iconLeft={<UILIB.Svgs icon="conversationsPromptsSuggestion" />}
                title={i18n.t("chat:channel.chat.prompts.new.suggestions")}
                description={i18n.t("chat:channel.chat.prompts.new.suggestionsDesc")}
                selected={Number(type) === 2}
                onClick={() => { setType(2) }}
              />



            </div>
          </div>
          <hr />
          <SettingsSection
            heading={i18n.t("chat:channel.chat.prompts.new.location")}
          >

            <UILIB.SelectNewDefault
              value={locationType}
              data={[{ label: i18n.t("chat:channel.chat.prompts.new.locationAny"), value: 0 }, { label: i18n.t("chat:channel.chat.prompts.new.locationPages"), value: 1 }]}
              onValueChange={(val) => {
                setLocationType(Number(val))
              }}
            />
            {locationType == 1 && <>
              <div className={styles.teaser}>
                <strong>{i18n.t("chat:channel.chat.prompts.new.pages")}</strong>
                {locations.map((location, index) => {
                  return (
                    <div key={index} className={styles.inputWithInlineActions}>
                      <TextInput
                        value={location}
                        className="width-100"
                        placeholder="/your-page"
                        onChange={(e) =>
                          setLocations(
                            updateItem(locations, e.target.value, index)
                          )
                        }
                      />

                      {locations.length > 1 && <Button
                        variant="ghost"
                        size="s"
                        iconOnly
                        aria-label="Delete"
                        onClick={() => setLocations(deleteItem(locations, index))}
                      >
                        <UILIB.Icon name="trash" color="#CF4627" />
                      </Button>}
                    </div>
                  );
                })}

                {formErrors?.locations && <UILIB.FormError>{formErrors.locations}</UILIB.FormError>}

                <Button
                  variant="secondary"
                  size="s"
                  iconLeft={<UILIB.Icon name="plus" />}
                  onClick={() => setLocations(addItem)}
                >
                  {i18n.t("chat:channel.chat.prompts.new.pagesAdd")}
                </Button>

              </div>

            </>}
          </SettingsSection>

          <hr />

          <SettingsSection
            heading={i18n.t("chat:channel.chat.prompts.new.delay")}
          >
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <UILIB.SelectNewDefault
                value={filterType}
                data={[
                  { label: i18n.t("chat:channel.chat.prompts.new.delaySeconds"), value: 0 },
                  { label: i18n.t("chat:channel.chat.prompts.new.delayScroll"), value: 1 },
                  { label: i18n.t("chat:channel.chat.prompts.new.delayPercentage"), value: 2 },
                  { label: i18n.t("chat:channel.chat.prompts.new.delayImmediate"), value: 3 }
                ]}
                onValueChange={(val) => {
                  setFilterType(Number(val))
                }}
              />

              {filterType == 0 &&
                <div className={styles.suffixInputWrapper}>
                  <TextInput
                    value={filterValues.delayValue}
                    maxlength="2"
                    type="number"
                    error={formErrors?.delayValue}
                    onChange={(e) =>
                      setFilterValues({ delayValue: e.target.value })
                    }
                  />

                  <span>{i18n.t("chat:channel.chat.prompts.new.seconds")}</span>
                </div>}
              {filterType == 1 && <div className={styles.scrollToElementOptions}>
                <UILIB.SelectNewDefault
                  value={filterValues.scrollToElementType}
                  data={[
                    { label: i18n.t("chat:channel.chat.prompts.new.elementId"), value: "id" },
                    { label: i18n.t("chat:channel.chat.prompts.new.elementClass"), value: "class" }
                  ]}
                  onValueChange={(val) =>
                    setFilterValues({ scrollToElementType: val })
                  }
                />

                <TextInput
                  value={filterValues.scrollToElementValue}
                  onChange={(e) =>
                    setFilterValues({ scrollToElementValue: e.target.value })
                  }
                  error={formErrors?.scrollToElementValue}
                  className="width-100"
                />
              </div>}
              {filterType == 2 &&
                <div className={styles.suffixInputWrapper}>
                  <TextInput
                    type="number"
                    value={filterValues.scrollPercentageValue}
                    maxlength="2"
                    error={formErrors?.scrollPercentageValue}
                    onChange={(e) =>
                      setFilterValues({ scrollPercentageValue: e.target.value })
                    }
                  />

                  <span>%</span>
                </div>}
            </div>

          </SettingsSection>

          <hr />

          {Number(type) === 1 && (
            <SettingsSection heading={i18n.t("chat:channel.chat.prompts.new.message")}>
              <UILIB.TextArea
                outerStyle={{ width: "100%" }}
                style={{ height: "150px" }}
                placeholder={i18n.t("chat:channel.chat.prompts.new.messagePlaceholder")}
                onChange={(e) => { setWelcomeMessage(e.target.value) }}
                error={formErrors?.welcomeMessage}
                value={welcomeMessage} />
            </SettingsSection>
          )}

          {Number(type) === 2 && (
            <SettingsSection
              heading={i18n.t("chat:channel.chat.prompts.new.suggestion")}
            >
              {suggestions.length > 0 && (
                <div className={styles.suggestionsWrapper}>
                  {suggestions.map((suggestion, index) => {
                    return (
                      <div key={index} className={styles.inputWithInlineActions}>
                        <TextInput
                          value={suggestion}
                          className="width-100"
                          placeholder={suggestions.length > 1 ?
                            i18n.t("chat:channel.chat.prompts.new.suggestionPlaceholder1") :
                            i18n.t("chat:channel.chat.prompts.new.suggestionPlaceholder2")
                          }
                          onChange={(e) =>
                            setSuggestions(
                              updateItem(suggestions, e.target.value, index)
                            )
                          }
                        />

                        <Button
                          variant="ghost"
                          size="s"
                          iconOnly
                          aria-label="Duplicate"
                          onClick={() =>
                            setSuggestions(duplicateItem(suggestions, index))
                          }
                        >
                          <UILIB.Icon name="copy" />
                        </Button>

                        <Button
                          variant="ghost"
                          size="s"
                          iconOnly
                          aria-label="Delete"
                          onClick={() =>
                            setSuggestions(deleteItem(suggestions, index))
                          }
                          style={{ color: 'var(--red-700)' }}
                        >
                          <UILIB.Icon name="trash" />
                        </Button>
                      </div>
                    );
                  })}
                </div>
              )}
              {formErrors?.suggestions && <UILIB.FormError>{formErrors.suggestions}</UILIB.FormError>}
              <Button
                variant="secondary"
                size="s"
                iconLeft={<UILIB.Icon name="plus" />}
                onClick={() => setSuggestions(addItem)}
              >
                {suggestions.length ? <>{i18n.t("chat:channel.chat.prompts.new.addAnother")}</> : <>{i18n.t("chat:channel.chat.prompts.new.addSuggestion")}</>}
              </Button>
            </SettingsSection>
          )}

          <hr />

          {formErrors?.saveError && <UILIB.FormError>{formErrors?.saveError}</UILIB.FormError>}
          <Button
            className={styles.submitButton}
            type="submit"
            size="s"
            loading={submitting}
          >
            {promptToEdit ? i18n.t("chat:channel.chat.prompts.new.buttonUpdate") : i18n.t("chat:channel.chat.prompts.new.buttonCreate")}
          </Button>
        </form>
      </>}
    </Dialog>
  );
}

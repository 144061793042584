import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import i18n from '~/i18n'

export default class DeleteSegment extends React.Component {

    constructor(props, context) {
        super(props);

        this.state = {
            error: ''
        }

        this.confirm = this.confirm.bind(this);
    }

    confirm() {
        this.setState({
            error: ''
        })
        axios.delete('/segment/' + this.props.segmentId).then(this.props.close).catch(err => {
            console.log(err)
            this.setState({
                error: err.data.error
            })
        })
    }

    render() {
        var hasAutomations = false;
        if (this.props.automations && this.props.automations.length) {
            hasAutomations = true;
        }
        return <div>


            <h4 className="mar-b25">{i18n.t('subscribers:segments.deleteHeader')}</h4>
            <div className="mar-b25">{i18n.t('subscribers:segments.deleteSubHeader')}</div>

            {hasAutomations == true &&
                <UILIB.Paper className="paper-red">
                    <h4 className="mar-b15 text-white">{i18n.t('subscribers:segments.deleteWarning')}:</h4>
                    <div className="mar-b10">{i18n.t('subscribers:segments.deleteWarningAutomationsHeader')}</div>
                    {this.props.automations.map((auto, autoIndex) => {
                        return <UILIB.SquareChip
                            key={"segWarnChip_" + autoIndex}
                            iconLeft={<UILIB.Icons icon="circle" />}
                            className="mar-r5"
                        >
                            {auto.name}
                        </UILIB.SquareChip>
                    })}
                    <div className="mar-t10">{i18n.t('subscribers:segments.deleteWarningAutomationsFooter')}</div>
                </UILIB.Paper>
            }

            <div className="row mar-b25">
                <div className="col-xs-6">
                    <UILIB.Button text={i18n.t('delete')} disabled={hasAutomations} className="button-fullwidth button-red" onClick={this.confirm} iconLeft={<UILIB.Icons icon="tick" />} />
                </div>
                <div className="col-xs-6">
                    <UILIB.Button text={i18n.t('cancel')} className="button-fullwidth" onClick={this.props.close} iconLeft={<UILIB.Icons icon="cross" />} />
                </div>
            </div>
            {!!this.state.error && <p className="text-red">{this.state.error}</p>}
        </div >
    }
};


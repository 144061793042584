import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

export default class CampaignsViewClicked extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignId: this.props.campaignId,
            tableData: [],
            loadingData: 1,
            limit: 50,
            page: 0,
            count: 0,
            startDate: null,

        }
        this.loadStats = this.loadStats.bind(this);
        this.changePageSize = this.changePageSize.bind(this)
        this.changePage = this.changePage.bind(this)

    }

    componentDidMount() {
        this.loadStats()
    }

    componentDidUpdate(prevProps) {
        var update = false;
        var obj = {};
        if (this.props.startDate != prevProps.startDate) {
            obj.startDate = this.props.startDate;
            update = true;
        }
        if (this.props.endDate != prevProps.endDate) {
            obj.endDate = this.props.endDate;
            update = true;
        }
        if (this.props.showBots !== prevProps.showBots) update = true
        if (update) {
            this.setState(obj, this.loadStats)
        }
    }

    changePageSize(size) {
        console.log(size)
        this.setState({
            limit: size,
            page: 0
        }, this.loadStats)
    }

    changePage(page) {
        this.setState({
            page: page
        }, this.loadStats)
    }

    loadStats() {
        this.setState({ loadingData: 1 });

        var self = this;
        var queryStr = '?limit=' + this.state.limit + '&offset=' + this.state.page * this.state.limit
        if (this.props.showBots) queryStr += '&bots=true&'
        if (this.props.startDate) {
            queryStr += '&startDate=' + this.props.startDate.format('YYYY-MM-DD')
        }
        if (this.props.endDate) {
            queryStr += '&endDate=' + this.props.endDate.format('YYYY-MM-DD 23:59:59')
        }
        axios.get('/campaign/' + self.state.campaignId + '/stats/clickedSummary' + queryStr).then((res) => {
            var theTableData = res.data.LinksClicked
                .map(thisLine => {

                    return {
                        gravatar: {
                            headerValue: "",
                            value: <UILIB.Avatar style={{ height: 'auto', width: '100%', maxWidth: "40px", maxHeight: "40px", marginRight: "10px" }} src={thisLine.gravatar} />,
                            width: 50,
                            orderBy: false
                        },
                        emailAddress: {
                            headerValue: i18n.t('campaigns:overview.emailAddress'),
                            value: <a onClick={() => { this.props.history.push('/cp/subscriber/' + thisLine.subscriberId); }}>{thisLine.emailAddress}</a>,
                            orderBy: false
                        },
                        link: {
                            headerValue: i18n.t('campaigns:overview.linkUrl'),
                            value: <a onClick={() => { this.props.history.push('/cp/campaigns/view/' + this.state.campaignId + '/clicked/' + thisLine.linkId) }}>{thisLine.link}</a>,
                            orderBy: false
                        },
                        device: {
                            headerValue: i18n.t('campaigns:overview.device'),
                            value: thisLine.device,
                            orderBy: false
                        },
                        updatedAt: {
                            headerValue: i18n.t('campaigns:overview.clickDate'),
                            value: DateTimeFunctions.formatDateTime(thisLine.updatedAt, 3),
                            orderBy: false
                        }
                    }
                })
            self.setState({ tableData: theTableData, count: res.data.countTotal, loadingData: 0 });
        });
    }

    render() {

        return <UILIB.Paper>
            <UILIB.Row>
                <UILIB.Column xs={12} className="end-xs mar-b25 quickFlex">
                    <div>{i18n.t('showing')}</div>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.limit === 10} onClick={() => { this.changePageSize(10) }}>10</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.limit === 50} onClick={() => { this.changePageSize(50) }}>50</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.limit === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.limit === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>
                </UILIB.Column>
                <UILIB.Column xs={12} className="mar-b25">
                    <UILIB.DataTable1 noResultsTxt={<span> No Results</span>} tableData={this.state.tableData} loadingData={this.state.loadingData} width="100%" pageSize="100" hasCheckBoxes="no" />
                </UILIB.Column>
                <UILIB.Column xs={12} className="center-xs">
                    <UILIB.PagingBlock totalRows={this.state.count} pageSize={this.state.limit} numberOfLinks="10" currentPage={this.state.page}
                        changePage={this.changePage} text={i18n.t('page') + ":"} />
                </UILIB.Column>
            </UILIB.Row>

        </UILIB.Paper>
    }
}
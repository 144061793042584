import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import Button from "../../../components/button";
import SettingsSection from "../../../components/settingsSection";
import Header from "../../../components/header";
import i18n from "~/i18n";

export default function WebchatChannelWebsites({ inbox, channel }) {
  const { siteMaster } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const [addingNewWebsite, setAddingNewWebsite] = useState(false);
  const [newWebsiteName, setNewWebsiteName] = useState("");
  const [newWebsiteNameError, setNewWebsiteNameError] = useState("");
  const [savingNewWebsite, setSavingNewWebsite] = useState(false);

  const [accountMaster, updateAccountMaster] = useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    {
      loading: true,
      data: null,
    }
  );

  const [trackers, updateTrackers] = useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    { loading: true, data: [] }
  );

  const [websites, updateWebsites] = useReducer(websitesReducer, {
    initial: [],
    selected: new Set(),
  });

  useEffect(() => {
    loadInitialData();
  }, []);

  async function loadInitialData() {
    await Promise.all([loadAccountMaster(), loadTrackers()]);
  }

  async function loadAccountMaster() {
    try {
      const { data } = await axios.get("/accountMaster");
      updateAccountMaster({ data: data.AccountMaster });
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    } finally {
      updateAccountMaster({ loading: false });
    }
  }

  async function loadTrackers() {
    try {
      updateTrackers({ loading: true });
      const { data } = await axios.get("/accountMaster/tracker");
      updateTrackers({ data: data.Trackers });
      updateWebsites({
        type: "initialize",
        data: data.Trackers.filter(
          (tracker) => tracker.InboxChannelId === channel.id
        ).map((tracker) => tracker.id),
      });
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    } finally {
      updateTrackers({ loading: false });
    }
  }

  async function handleUpdateWebsitesSubmit(e) {
    e.preventDefault();
    setSaving(true);

    try {
      const toAdd = [...websites.selected].filter(
        (id) => !websites.initial.includes(id)
      );

      const toRemove = websites.initial.filter(
        (id) => ![...websites.selected].includes(id)
      );

      for (const id of toAdd) {
        await axios.put(`/accountMaster/tracker/${id}`, {
          InboxChannelId: channel.id,
        });
      }

      for (const id of toRemove) {
        await axios.put(`/accountMaster/tracker/${id}`, {
          InboxChannelId: null,
        });
      }

      dispatch(alterSnackbar(true, i18n.t("chat:channel.chat.websites.saved")));
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    } finally {
      setSaving(false);
    }
  }

  function copyToClipboard(string) {
    copy(string);
    dispatch(alterSnackbar(true, i18n.t("chat:channel.chat.websites.copied")));
  }

  const saveNewWebsite = async () => {
    try {
      setNewWebsiteNameError("");
      let tmpNewWebsiteName = newWebsiteName.toLowerCase().replace("https://", "").replace("http://", "").replace("www.", "").trim("");
      if (!tmpNewWebsiteName || tmpNewWebsiteName.length < 5) {
        setNewWebsiteNameError("Invalid URL");
        return
      }

      if (tmpNewWebsiteName.indexOf(".") < 0 && tmpNewWebsiteName.indexOf(":") < 0) {
        setNewWebsiteNameError("Invalid URL");
        return
      }

      if (tmpNewWebsiteName.length > 254) {
        setNewWebsiteNameError("URL is too long");
        return
      }

      setSavingNewWebsite(true);
      const resp = await axios.post('/accountMaster/tracker', { host: tmpNewWebsiteName })
      setSavingNewWebsite(false);
      setAddingNewWebsite(false);
      setNewWebsiteName("");
      loadTrackers();
    }
    catch (e) {
      console.log(e);
      setNewWebsiteNameError(e?.data?.error || "Error adding site")
      setSavingNewWebsite(false);
    }
  }
  const embedCode = `<script src="https://${siteMaster.trackingDomain}/tracker?am=${accountMaster.data?.base64id}"></script>`;
  const saveDisabled = [...websites.selected].length < 1;
  return (
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
      <Header type="webchat" option="websites" />
      <section className="mar-b24">
        {trackers.loading || trackers.data.length > 0 ? (
          <form onSubmit={handleUpdateWebsitesSubmit} disabled={saving}>
            {trackers.loading ? (
              <UILIB.LoadingIcons iconType="2" className="mar-l0" />
            ) : (
              <>
                <div className="mar-b25">
                  {trackers.data.map((tracker) => {
                    const { id, host, InboxChannelId: inboxChannelId } = tracker;
                    const webchatAlreadyEnabled = inboxChannelId !== null && inboxChannelId !== channel.id;

                    return (
                      <UILIB.CheckBox
                        key={id}
                        name="access"
                        value={id}
                        checked={websites.selected.has(id)}
                        disabled={webchatAlreadyEnabled}
                        onChange={() => updateWebsites(id)}
                        outerClassName="mar-b12"
                      >
                        {host}

                        {webchatAlreadyEnabled && (
                          <span className="fw-normal">
                            &nbsp;({i18n.t("chat:channel.chat.websites.inUse")})
                          </span>
                        )}
                      </UILIB.CheckBox>
                    );
                  })}
                </div>
                <Button
                  type="submit"
                  size="s"
                  className="mar-t12"
                  loading={saving}
                  disabled={saveDisabled}
                >
                  {i18n.t("chat:channel.chat.websites.save")}
                </Button>
              </>
            )}
          </form>
        ) : (
          <div className="box">
            <h3 className="text-md mar-b5">
              {i18n.t("chat:channel.chat.websites.noneDetected")}
            </h3>

            <p className="mar-b0" dangerouslySetInnerHTML={{ __html: i18n.t("chat:channel.chat.websites.read") }}>
            </p>
          </div>
        )}
      </section>

      <hr></hr>

      <SettingsSection heading={i18n.t("chat:channel.chat.websites.addNewWebsite")} subheading={i18n.t("chat:channel.chat.websites.addNewSubheader")}>
        {accountMaster.loading ? (
          <UILIB.LoadingIcons iconType="2" className="mar-l0" />
        ) : (
          <>
            <div className="text-heavy mar-b0">{i18n.t("chat:channel.chat.websites.trackingCode")}</div>
            <div className="width-100" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 8 }}>
              <UILIB.TextInput
                className="txtInput-greyedOut"
                outerClassName="width-100"
                style={{ padding: 6 }}
                value={embedCode}
                readOnly={true}
              />
              <Button variant="secondary" size="s"
                onClick={() => { copyToClipboard(embedCode) }}>{"Copy"}</Button>
            </div>

            <div className="text-heavy mar-b0">{i18n.t("chat:channel.chat.websites.websiteAddress")}</div>
            <UILIB.TextInput
              style={{ padding: 6 }}
              value={newWebsiteName}
              onChange={(ev) => { setNewWebsiteName(ev.target.value) }}
              outerClassName="width-100"
              placeholder="mywebsite.com"
              error={newWebsiteNameError}
            />

            <Button disabled={savingNewWebsite} className="mar-r10" variant="primary" size="s" iconRight={<UILIB.Icon name="arrow-right" />} onClick={saveNewWebsite}>{i18n.t("chat:channel.chat.websites.saveAndContinue")}</Button>


            <UILIB.Paper className="paper-information">
              <div dangerouslySetInnerHTML={{ __html: i18n.t("chat:channel.chat.websites.addTrackingMoreInfo") }} />
            </UILIB.Paper>
          </>
        )}
      </SettingsSection>
    </div >
  );
}

function websitesReducer(state, action) {
  if (action.type === "initialize") {
    return {
      ...state,
      initial: action.data,
      selected: new Set(action.data),
    };
  }

  const newState = { ...state };

  if (!state.selected.has(action)) {
    newState.selected.add(action);
  } else {
    newState.selected.delete(action);
  }

  return newState;
}

import React from 'react'

export default class IconsDownload extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 17.0001C3 16.7348 3.10536 16.4805 3.29289 16.293C3.48043 16.1054 3.73478 16.0001 4 16.0001H16C16.2652 16.0001 16.5196 16.1054 16.7071 16.293C16.8946 16.4805 17 16.7348 17 17.0001C17 17.2653 16.8946 17.5196 16.7071 17.7072C16.5196 17.8947 16.2652 18.0001 16 18.0001H4C3.73478 18.0001 3.48043 17.8947 3.29289 17.7072C3.10536 17.5196 3 17.2653 3 17.0001ZM6.293 9.29306C6.48053 9.10559 6.73484 9.00027 7 9.00027C7.26516 9.00027 7.51947 9.10559 7.707 9.29306L9 10.5861V3.00006C9 2.73484 9.10536 2.48049 9.29289 2.29295C9.48043 2.10542 9.73478 2.00006 10 2.00006C10.2652 2.00006 10.5196 2.10542 10.7071 2.29295C10.8946 2.48049 11 2.73484 11 3.00006V10.5861L12.293 9.29306C12.3852 9.19755 12.4956 9.12137 12.6176 9.06896C12.7396 9.01655 12.8708 8.98896 13.0036 8.98781C13.1364 8.98666 13.2681 9.01196 13.391 9.06224C13.5139 9.11252 13.6255 9.18677 13.7194 9.28067C13.8133 9.37456 13.8875 9.48621 13.9378 9.60911C13.9881 9.732 14.0134 9.86368 14.0123 9.99646C14.0111 10.1292 13.9835 10.2605 13.9311 10.3825C13.8787 10.5045 13.8025 10.6148 13.707 10.7071L10.707 13.7071C10.5195 13.8945 10.2652 13.9998 10 13.9998C9.73484 13.9998 9.48053 13.8945 9.293 13.7071L6.293 10.7071C6.10553 10.5195 6.00021 10.2652 6.00021 10.0001C6.00021 9.7349 6.10553 9.48059 6.293 9.29306Z" fill={color} />
        </svg>





    }
}        
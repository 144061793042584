import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'

export default class failedPaymentConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            paymentCards: []
        };
        this.changeCard = this.changeCard.bind(this);
    }

    componentDidMount() {
        this.getPaymentCards();
    }

    getPaymentCards() {
        axios.get('/accountMaster/paymentMethod/stripe').then(response => {

            this.setState({
                loaded: true,
                paymentCards: response.data.customer.sources.data,
            })
        }).catch(console.log)

    }

    changeCard(event) {
        var cardVal = event.target.value;
        if (cardVal == "ADDNEW") {
            this.props.addCard();
            return;
        }

        axios.put('/accountMaster/paymentMethod/stripe', {
            default_source: cardVal
        }).then(() => {
            this.props.reChard();
        }).catch(err => {
        })

    }
    render() {

        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />

        var cardData = this.state.paymentCards.map(cd => {
            return { "label": cd.brand + " ending " + cd.last4, "value": cd.id }
        });

        cardData.push({ "label": "Add a new Card", "value": "ADDNEW" })

        var err = "Error charging card";
        if (this.props.err) {
            err = this.props.err;
            if (err == "Cannot charge a customer that has no active card") {
                err = "No payment methods found";
            }
        }

        return <div>
            <h4 className="mar-b25">We couldn't process your payment </h4>
            <div className="mar-b25">We received the following message back from the payment provider...</div>
            <div className="text-red mar-b25">{err}</div>
            {(this.state.paymentCards && this.state.paymentCards.length > 0) && <UILIB.Select outerClassName="mar-b25" placeholder="Select another card to try" data={cardData} onChange={this.changeCard} />}
            {(!this.state.paymentCards || this.state.paymentCards.length <= 0) && <div className="mar-b25">
                <div className="mar-b15">Please add a new payment method to continue...</div>
                <UILIB.Button text="Add a Payment Method" className="button-primary" onClick={() => { window.open("/cp/company/subscription", "_self") }} />

            </div>}
        </div>
    }

};



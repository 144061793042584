import React from 'react'

export default function IconThumbsDown({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 9.5C18 9.89783 17.842 10.2794 17.5607 10.5607C17.2794 10.842 16.8978 11 16.5 11C16.1022 11 15.7206 10.842 15.4393 10.5607C15.158 10.2794 15 9.89783 15 9.5V3.5C15 3.10218 15.158 2.72064 15.4393 2.43934C15.7206 2.15804 16.1022 2 16.5 2C16.8978 2 17.2794 2.15804 17.5607 2.43934C17.842 2.72064 18 3.10218 18 3.5V9.5ZM14 9.667V4.237C14.0003 3.86544 13.897 3.50116 13.7018 3.18499C13.5067 2.86882 13.2273 2.61327 12.895 2.447L12.845 2.422C12.2892 2.14422 11.6763 1.99973 11.055 2H5.64C5.17747 1.99982 4.72918 2.15995 4.37145 2.45314C4.01371 2.74633 3.76866 3.15444 3.678 3.608L2.478 9.608C2.41999 9.89821 2.42712 10.1977 2.49886 10.4848C2.5706 10.7719 2.70516 11.0396 2.89285 11.2684C3.08054 11.4972 3.31667 11.6815 3.58422 11.808C3.85176 11.9346 4.14405 12.0001 4.44 12H8V16C8 16.5304 8.21072 17.0391 8.58579 17.4142C8.96086 17.7893 9.46957 18 10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V16.333C11 15.4675 11.2807 14.6254 11.8 13.933L13.2 12.067C13.7193 11.3746 14 10.5325 14 9.667V9.667Z" fill={fillColor}/>
        </svg>
    )
}

import React from 'react'
import NumberFormat from '~/Classes/numberFormatFunctions'
import UILIB from '../../../../Common-Objects/Lib';
import i18n from '~/i18n';
import { connect } from 'react-redux';
import UpgradeDrawer from '~/pages/cp/includes/upgrade/upgradeTiers';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here

@connect((store) => {
    return {
        siteMaster: store.siteMaster,
        accountMaster: store.accountMaster
    }
})

export default class NewSubscribers extends React.Component {

    constructor(props) {
        super(props);
        this.goUpgrade = this.goUpgrade.bind(this);
    }

    goUpgrade() {
        var drawerContent = <UpgradeDrawer history={this.props.history} close={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "1000px"));
    }


    render() {
        let isCreditAccount = this.props.accountMaster.accountMaster.billingFrequency == 2;
        let isBilledOnContacts = this.props.accountMaster.accountMaster.billedOnContacts;
        let showBilling = true;
        if (this.props.siteMaster.hidePricing) showBilling = false;
        if (this.props.accountMaster.accountMaster.invoice) showBilling = false;

        let header = i18n.t('dashboard:welcomePage.emailsSent')
        let subHeader = i18n.t('dashboard:welcomePage.yourEmailSendQuota')
        let limit = this.props.accountMaster.accountMaster.maxUniqueSends || 0;
        let value = limit - this.props.accountMaster.accountMaster.remainingEmails;


        if (isCreditAccount) {
            header = i18n.t('dashboard:welcomePage.credits')
            subHeader = i18n.t('dashboard:welcomePage.yourCreditsRemaining')
            value = this.props.accountMaster.accountMaster.credits || 0;
        }

        if (isBilledOnContacts) {
            header = i18n.t('dashboard:welcomePage.contacts')
            subHeader = i18n.t('dashboard:welcomePage.yourContactQuota')
            value = this.props.accountMaster.accountMaster.totalActiveSubscribers;
            limit = this.props.accountMaster.accountMaster.maxUniqueSends;
        }

        return <UILIB.Paper className="m-mar-b0">

            <div className="flexSpaceBetween">
                <h4 className="mar-b5">{header}</h4>
                {!!this.props.accountMaster.accountMaster.accountBillingTierName && <UILIB.SquareChip className="square-chip-green">{this.props.accountMaster.accountMaster.accountBillingTierName}</UILIB.SquareChip>}
            </div>
            <div className="mar-b24">{subHeader}</div>
            <div className="quickFlex" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "space-between" }}>
                <div className="quickFlex" style={{display: 'flex', flexWrap: 'wrap'}}>
                    <h4 className="mar-r16 statistic">{value}</h4>
                    {(isCreditAccount == false) && <span className="statistic-sub">/ {limit}</span>}
                </div>
                {showBilling && <div className='mar-t5'>
                    {(isCreditAccount == false) && <>
                        <UILIB.Button onClick={this.goUpgrade} iconLeft={<UILIB.Icons icon="doubleArrowUp" />}>{i18n.t('dashboard:bannerMessages.upgrade')}</UILIB.Button>
                    </>}
                    {(isCreditAccount == true) && <>
                        <UILIB.Button onClick={this.goUpgrade} iconLeft={<UILIB.Icons icon="doubleArrowUp" />}>Top Up</UILIB.Button>
                    </>}
                </div>}
            </div>


        </UILIB.Paper >
    }
}

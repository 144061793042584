import React, { Component, Fragment } from 'react';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import i18n from '~/i18n'

class DrawerStuckViewer extends Component {
    state = {
        count: 0,
        page: 0,
        table: [],
        rows: [],
        loading: true,
        getting: 0,
        searchText: '',
        checked: [],
        firstLoad: true
    }

    timeout = null;

    componentDidMount() {
        this.getStuck.bind(this)()
    }

    changeSearch(event) {
        this.setState({ searchText: event.target.value, page: 0 }, () => {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => this.getStuck(), 150)
        })
    }

    renderTable() {
        this.setState({
            table: this.state.rows.map((row, index) => {
                let status = i18n.t('Active');
                if (row.Subscriber.blackListed) status = i18n.t('Blacklisted')
                else if (row.Subscriber.bounce) status = i18n.t('Bounced')
                else if (row.Subscriber.GroupSubscribers.every(gs => gs.ununsubscribe)) status = i18n.t('Unsubscribed')
                else if (row.Subscriber.GroupSubscribers.every(gs => !gs.active)) status = i18n.t('In-Active')
                return {
                    id: { value: row.id },
                    checkBox: {
                        headerValue: <UILIB.CheckBox
                            disabled={this.state.processing}
                            checked={this.state.checked.length === this.state.rows.length}
                            onChange={this.checkAll.bind(this)} />,
                        orderBy: false,
                        value: <UILIB.CheckBox
                            disabled={this.state.processing}
                            checked={this.state.checked.indexOf(row.id) > -1}
                            onChange={() => this.checkBoxes.bind(this)(row.id)}
                            name={"row" + index} />,
                        isCheckBox: true,
                        width: 30,
                        isChecked: false,
                        order: -1
                    },
                    emailAddress: {
                        headerValue: i18n.t('emailAddress'),
                        value: <a onClick={() => this.props.goTo('/cp/subscriber/' + row.Subscriber.id)}>{row.Subscriber.emailAddress}</a>
                    },
                    status: {
                        headerValue: 'Status',
                        value: status
                    },
                    ActionDate: {
                        headerValue: i18n.t('automation:add.stuck.date'),
                        value: DateTimeFunctions.formatDateTime(row.ActionDate)
                    },
                    optionsCol: {
                        headerValue: " ",
                        value: <Fragment>
                            <UILIB.OptionsDropdown popWidth="150px">
                                <ul>
                                    <li><a onClick={() => this.retry.bind(this)(row)}>{i18n.t('automation:add.stuck.retry')}</a></li>
                                    <li><a onClick={() => this.next.bind(this)(row)}>{i18n.t('automation:add.stuck.move')}</a></li>
                                </ul>
                            </UILIB.OptionsDropdown>
                        </Fragment>,
                        orderBy: false,
                        fixed: true,
                        width: 20
                    }
                }
            })
        })
    }

    getStuck() {
        this.setState({
            getting: 1,
            checked: [],
        })
        let queryStr = `?limit=100&offset=${this.state.page * 100}`
        if (this.state.searchText) queryStr += '&searchText=' + this.state.searchText
        axios.get('/automation/' + this.props.automationId + '/v2/steps/' + this.props.step.guid + '/stuck' + queryStr).then(response => {
            this.setState({
                loading: false,
                processing: false,
                getting: 0,
                count: response.data.count,
                rows: response.data.rows,
                firstLoad: false
            }, this.renderTable.bind(this))
        })
    }

    checkAll(ev, clear) {
        if (clear || this.state.checked.length) {
            return this.setState({ checked: [] }, this.renderTable.bind(this))
        }
        return this.setState({ checked: this.state.rows.map(r => r.id) }, this.renderTable.bind(this))
    }

    checkBoxes(id) {
        let checked = this.state.checked
        let index = checked.indexOf(id)
        if (index > -1) {
            checked.splice(index, 1)
        } else {
            checked.push(id)
        }
        this.setState({ checked }, this.renderTable.bind(this))
    }

    retry(row, checked) {
        this.setState({
            processing: true
        }, this.renderTable)
        axios.put('/automation/' + this.props.automationId + '/step/' + this.props.step.guid, {
            ids: !checked ? [row.id] : this.state.checked,
            action: 'retry'
        }).then(() => {
            this.props.refresh()
            this.getStuck()
        })
    }

    next(row, checked) {
        this.setState({
            processing: true
        }, this.renderTable)
        axios.put('/automation/' + this.props.automationId + '/step/' + this.props.step.guid, {
            ids: !checked ? [row.id] : this.state.checked,
            action: 'move',
            destination: this.props.nextStep.guid
        }).then(() => {
            this.props.refresh()
            this.getStuck()
        })
    }

    nextpage() {
        this.setState({
            page: this.state.page + 1
        }, this.getStuck)
    }

    backpage() {
        if (this.state.page === 0) return
        this.setState({
            page: this.state.page - 1
        }, this.getStuck)
    }

    render() {
        if (this.state.loading && this.state.firstLoad) return <div><UILIB.LoadingIcons iconType="2" /></div>
        return <div>

            <UILIB.TextInput outerClassName="mar-b25" name="searchText" value={this.state.searchText} onChange={this.changeSearch.bind(this)} placeholder={i18n.t('automation:add.stats.search')} />

            <div className="quickFlex mar-b25">
                <div className="mar-r10">
                    <UILIB.Button disabled={!this.state.checked.length || this.state.processing} className="button-primary" onClick={() => this.retry.bind(this)(null, true)}>{i18n.t('automation:add.stuck.retry')} ({this.state.checked.length})</UILIB.Button>
                </div>
                <div>
                    <UILIB.Button disabled={!this.state.checked.length || this.state.processing} onClick={() => this.next.bind(this)(null, true)}>{i18n.t('automation:add.stuck.move')} ({this.state.checked.length})</UILIB.Button>
                </div>
            </div>

            <UILIB.DataTable1 noResultsTxt={<span>{i18n.t('automation:add.stats.none')}</span>}
                tableData={this.state.table}
                loadingData={this.state.getting}
                dataUpdater={() => { }}
                width="100%" pageSize="100"
                hasCheckBoxes={"multi"}
            />

            {this.state.count > 100 && <div className="quickFlex mar-t20" style={{ justifyContent: 'space-between' }}>
                <UILIB.Button onClick={this.backpage.bind(this)} disabled={this.state.page === 0}>Back</UILIB.Button>
                <UILIB.Button onClick={this.nextpage.bind(this)} disabled={this.state.table.length < 100}>Next</UILIB.Button>
            </div>}
        </div >
    }
}

export default DrawerStuckViewer;
import React from 'react'
import UILIB from '~/Common-Objects/Lib.jsx';
import i18n from '~/i18n';
export default class CampaignTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <UILIB.Paper className="m-mar-b0" iconRight={<UILIB.Icons icon="pencil" color="grey" />} iconRightClick={this.props.edit}>
            <div className="quickFlex mar-b24" style={{ justifyContent: "space-between" }}>
                <h4>{i18n.t('dashboard:welcomePage.mostRecentCampaigns')}</h4>
                {!!this.props.campaigns.length && <UILIB.Row>
                    <UILIB.Column xs={12} margin={0} className="end-xs">
                        <UILIB.Button className="primary" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => this.props.history.push('/cp/campaigns')}>{i18n.t('campaigns:main.tooltip')}</UILIB.Button>
                    </UILIB.Column>
                </UILIB.Row>}
            </div>
            <UILIB.Row className="mar-b20">
                <UILIB.Column xs={12} margin={0}>

                    <UILIB.DataTable1
                        noResultsTxt={this.props.canCreateCampaigns ? <span> {i18n.t('campaigns:main.noCampaignsFound')} {i18n.t('campaigns:main.addCampaign')} - <a onClick={this.props.addCampaign}>{i18n.t('create')}</a></span> : i18n.t('campaigns:main.noCampaignsFound2')}
                        tableData={this.props.campaigns}
                        width="100%"
                        pageSize="5"
                        hasCheckBoxes="no"
                        hideHeader={true}
                    />
                </UILIB.Column>
            </UILIB.Row>

        </UILIB.Paper>
    };
};

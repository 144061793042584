import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import PermissionChecker from '~/Classes/permissions'
import i18n from '~/i18n'
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

class ContactsMainNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabIndex: 0
        }

        this.tabChange = this.tabChange.bind(this);
        this.setInitialTab = this.setInitialTab.bind(this);
    }

    componentDidMount() {
        this.setInitialTab();
    }

    componentDidUpdate(prevProps) {
        if (prevProps != this.props) {
            this.setInitialTab();
        }
    }

    setInitialTab() {
        var foundTab = 0;
        var theCurrPath = this.props.history.location.pathname.split("/");
        var theCurrPathOrig = this.props.history.location.pathname;
        if (theCurrPath.length > 3) {
            if (theCurrPath[3] == 'groups') {
                foundTab = 1;
                this.setState({ tabIndex: 1 });
            }
            if (theCurrPath[3] == 'segments') {
                foundTab = 1;
                this.setState({ tabIndex: 5 });
            }
            if (theCurrPath[3] == 'customfields') {
                foundTab = 1;
                this.setState({ tabIndex: 3 });
            }
            if (theCurrPath[3] == 'export') {
                foundTab = 1;
                this.setState({ tabIndex: 9 });
            }
            if (theCurrPath[3] == 'analytics') {
                foundTab = 1;
                this.setState({ tabIndex: 10 });
            }

            if (theCurrPathOrig.indexOf('groups/' + this.props.accountMaster.accountMaster.blackListGroupId) > -1) {
                foundTab = 1;
                this.setState({ tabIndex: 6 });
            }
            // if (theCurrPath[3] == 'analytics') {
            //     foundTab = 1;
            //     this.setState({ tabIndex: 6 });
            // }
            if (theCurrPath[3] == 'clean') {
                foundTab = 1;
                this.setState({ tabIndex: 7 });
            }
            if (theCurrPath[3] == 'tags') {
                foundTab = 1;
                this.setState({ tabIndex: 4 });
            }
            if (theCurrPath[3] === 'organisations') {
                foundTab = 1
                this.setState({ tabIndex: 2 })
            }
        }

        if (foundTab === 0) {
            this.setState({ tabIndex: 0 });
        }
    }

    tabChange(event, returnUrl) {
        var index = event;

        if (event.currentTarget) {
            if (event.currentTarget.id !== undefined) index = event.currentTarget.id;
            if (event.currentTarget.value !== undefined) index = event.currentTarget.value;
        }

        index = String(index);
        if (!returnUrl) this.setState({ tabIndex: index });
        let url
        switch (index) {
            case "1":
                url = '/cp/subscribers/groups';
                break;
            case "0":
                url = '/cp/subscribers';
                break;
            case "5":
                url = '/cp/subscribers/segments';
                break;
            case "3":
                url = '/cp/subscribers/customfields';
                break;
            case "9":
                url = '/cp/subscribers/export';
                break;
            case "6":
                url = '/cp/groups/' + this.props.accountMaster.accountMaster.blackListGroupId;
                break;
            case "10":
                url = '/cp/subscribers/analytics';
                break;
            case "7":
                url = '/cp/subscribers/clean';
                break;
            case "4":
                url = '/cp/subscribers/tags';
                break;
            case "2":
                url = '/cp/subscribers/organisations';
                break;
        }
        if (returnUrl) {
            return url
        }
        this.props.history.push(url)
    }

    render() {

        var thisMenuOptions = [
            {
                label: i18n.t('subscribers:nav.all'),
                permission: 'subscribers',
                method: 'read',
                index: 0
            }, {
                label: i18n.t('subscribers:nav.groups'),
                permission: 'subscribers',
                method: 'read',
                index: 1
            }, {
                label: i18n.t('subscribers:nav.segments'),
                index: 5,
                permission: 'segments',
                method: 'read'
            }, {
                label: i18n.t('subscribers:nav.fields'),
                index: 3,
                permission: 'customFields',
                method: 'read',
            }, {
                label: 'Tags',
                index: 4,
                permission: 'subscribers',
                method: 'read'
            },
            {
                label: i18n.t('subscribers:nav.export'),
                index: 9,
                permission: 'reporting',
                method: 'read',
            },
            {
                label: i18n.t('subscribers:nav.blacklist'),
                index: 6,
                permission: 'subscribers',
                method: 'read',
            }, {
                label: 'Organisations',
                index: 2,
                permission: 'subscribers',
                method: 'read',
            }]


        if (this.props.siteMaster.siteId != 2 && !this.props.siteMaster.listCleanDisabled) {
            thisMenuOptions.push({
                label: <div>{i18n.t('subscribers:nav.listCleaning')} <UILIB.SquareChip className="square-chip-green mar-l5">New</UILIB.SquareChip></div>,
                mobilelabel: i18n.t('subscribers:nav.listCleaning'),
                index: 7,
                permission: 'subscribers',
                style: { border: "1px solid gray" },
                method: 'write'
            });
        }

        thisMenuOptions = thisMenuOptions.filter((o) => {
            var permissionName = o.permission
            if (o.beta) {
                if (!this.props.accountMaster.accountMaster.beta || !this.props.accountMaster.accountMaster.beta[o.beta]) return false
            }
            if (!permissionName || PermissionChecker(permissionName, this.props.permissionStore.permissions, o.method)) return true;
            return false;
        })

        return <div>
            <UILIB.Row className="mar-b25">
                <UILIB.Column xs={12} className="hide-md hide-lg" margin={0}>
                    <div className="form-group">
                        <UILIB.Select data={thisMenuOptions.map(o => {
                            return {
                                label: o.mobilelabel || o.label,
                                value: o.index
                            }
                        })} onChange={this.tabChange} value={this.state.tabIndex} />
                    </div>
                </UILIB.Column>

                <UILIB.Column xs={12} className="hide-xs hide-sm show-md show-lg sub-nav-holder" margin={0}>
                    {
                        thisMenuOptions.sort((a, b) => a.index - b.index).map((o) => {
                            let url = this.tabChange(o.index, true)
                            return <UILIB.ButtonSimple
                                key={"tab_" + o.index}
                                className="button-simple-fullsize sub-nav-button"
                                selected={this.state.tabIndex == o.index}
                                to={url}>
                                {o.label}
                            </UILIB.ButtonSimple>
                        })
                    }
                </UILIB.Column >
            </UILIB.Row >
        </div>
    }
}

export default ContactsMainNav;
import React from "react";
import { connect } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "../../../../Common-Objects/Lib";
import i18n from "~/i18n";
import Theme from "~/pages/cp/templates/add/dragDrop/themes/theme";
import SelectFormType from "./components/selectFormType";
import SelectGatedContent from "~/Common-Objects/GatedContent/selectGatedContent";

export const NORMAL_FORM = "form";
export const GATED_CONTENT = "gated";

@connect((store) => {
  return { accountMaster: store.accountMaster };
})

export default class FormPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      formType: NORMAL_FORM,
      themeType: 0,
      loadingThemes: true,
      themes: [],
      content: [],
      contentAccess: "email",
    };

    this.selectType = this.selectType.bind(this);
    this.changeThemeType = this.changeThemeType.bind(this);
    this.selectTheme = this.selectTheme.bind(this);
    this.getThemes = this.getThemes.bind(this);
    this.saveFileSettings = this.saveFileSettings.bind(this);
    this.removeContent = this.removeContent.bind(this);
    this.addLink = this.addLink.bind(this);
    this.addFile = this.addFile.bind(this);
    this.handleContentAccessChange = this.handleContentAccessChange.bind(this);
  }

  componentDidMount() {
    this.getThemes();
  }

  changeThemeType(newVal) {
    this.setState({ themeType: newVal }, this.getThemes);
  }

  selectType(type) {
    this.setState({ formType: type, step: type === NORMAL_FORM ? 2 : 1 });
  }

  getThemes() {
    axios
      .get("/theme?type=2&formType=" + this.state.themeType)
      .then((response) => {
        this.setState({
          loadingThemes: false,
          themes: response.data.Themes,
        });
      });
  }

  selectTheme(theme) {
    this.props.save(
      theme,
      this.state.themeType,
      this.state.formType === GATED_CONTENT
        ? { files: this.state.content, serve: this.state.contentAccess }
        : undefined
    );
  }

  saveFileSettings(file, settings) {
    const newContent = [...this.state.content];
    const fileIndex = newContent.findIndex((el) => el === file);
    newContent[fileIndex] = {
      ...file,
      settings,
    };

    this.setState({ content: newContent });
  }

  removeContent(item) {
    const newContent = [...this.state.content];
    const contentIndex = newContent.findIndex((el) => el === item);
    newContent.splice(contentIndex, 1);

    this.setState({ content: newContent });
  }

  addLink(url) {
    this.setState((prevState) => ({
      content: [...prevState.content, { type: 1, url, settings: {} }],
    }));
  }

  addFile(file) {
    this.setState((prevState) => ({
      content: [
        ...prevState.content,
        { type: 0, FileManagerFile: file, settings: {} },
      ],
    }));
  }

  async handleContentAccessChange(access) {
    this.setState({ contentAccess: access });
  }

  render() {
    var thisHeader = i18n.t("campaigns:overview.hostedForm");
    var thisSubHeader = i18n.t("campaigns:overview.hostedFormDesc");
    if (this.state.themeType == 1) {
      thisHeader = i18n.t("campaigns:overview.embeddedForm");
      thisSubHeader = i18n.t("campaigns:overview.embeddedFormDesc");
    }
    if (this.state.themeType == 2) {
      thisHeader = i18n.t("campaigns:overview.popupForm");
      thisSubHeader = i18n.t("campaigns:overview.popupFormDesc");
    }
    if (this.state.themeType == 3) {
      thisHeader = i18n.t("campaigns:overview.popupForm");
      thisSubHeader = i18n.t("campaigns:overview.popupFormDesc");
    }

    return (
      <div>
        {this.state.step === 0 && <SelectFormType onSelect={this.selectType} />}
        {this.state.step === 1 && (
          <>
            <div className="center-xs mar-b60">
              <h1 className="h2 mar-b10">Configure gated content</h1>
              <p className="text-md text-grey">
                Allow visitors to download files or gain access to links
              </p>
            </div>

            <UILIB.Row>
              <UILIB.Column xs={12} lg={10} className="col-lg-offset-1">
                <SelectGatedContent
                  content={this.state.content}
                  contentAccess={this.state.contentAccess}
                  onAddFile={this.addFile}
                  onSaveFileSettings={this.saveFileSettings}
                  onAddLink={this.addLink}
                  onDelete={this.removeContent}
                  onContentAccessChange={this.handleContentAccessChange}
                  onSubmit={() => this.setState({ step: 2 })}
                />
              </UILIB.Column>
            </UILIB.Row>
          </>
        )}

        {this.state.step === 2 && (
          <>
            <div className="text-center mar-b25">
              <UILIB.ButtonSimple
                className="button-simple-fullsize"
                selected={this.state.themeType == 0}
                onClick={() => {
                  this.changeThemeType(0);
                }}
              >
                {i18n.t("hosted")}
              </UILIB.ButtonSimple>
              <UILIB.ButtonSimple
                className="button-simple-fullsize mar-l5"
                selected={this.state.themeType == 1}
                onClick={() => {
                  this.changeThemeType(1);
                }}
              >
                {i18n.t("embedded")}
              </UILIB.ButtonSimple>
              {/* <UILIB.ButtonSimple
                className="button-simple-fullsize mar-l5"
                selected={this.state.themeType == 2}
                onClick={() => {
                  this.changeThemeType(2);
                }}
              >
                Popup
              </UILIB.ButtonSimple> */}
            </div>
            <div>
              <UILIB.Paper>
                {this.state.loadingThemes && (
                  <div style={{ marginTop: "50px" }}>
                    <UILIB.LoadingIcons iconType="2" width="100px" />
                  </div>
                )}

                {!this.state.loadingThemes && (
                  <div>
                    <h4 className="mar-b15">{thisHeader}</h4>
                    <div className="mar-b25">{thisSubHeader}</div>

                    {!this.state.themes.length && (
                      <div
                        style={{ width: "100%" }}
                        className="center-xs center-sm center-md center-lg mar-t30"
                      >
                        <span>
                          {i18n.t("campaigns:overview.NoFormTemplateFound")}
                        </span>
                      </div>
                    )}
                    {this.state.themes.length > 0 && (
                      <UILIB.Row>
                        {this.state.themes.map((theme, index) => {
                          return (
                            <UILIB.Column
                              xs={12}
                              sm={6}
                              md={4}
                              lg={4}
                              className="themes-advanced"
                              key={index}
                            >
                              <Theme
                                theme={theme}
                                className="extraSpace"
                                select={() => {
                                  this.selectTheme(theme);
                                }}
                                preview={(event) => this.selectTheme(theme)}
                              />
                            </UILIB.Column>
                          );
                        })}
                      </UILIB.Row>
                    )}
                  </div>
                )}
              </UILIB.Paper>
            </div>
          </>
        )}
      </div>
    );
  }
}

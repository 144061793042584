
module.exports = {

    request: [
        {
            field: 'fromName',
            required: false,
            description: 'fromNameDesc'
        }, {
            field: 'fromAddress',
            required: true,
            description: 'fromAddressDesc'
        }, {
            field: 'replyAddress',
            required: true,
            description: 'replyAddressDesc'
        }, {
            field: 'subject',
            required: true,
            description: 'subjectDesc'
        }, {
            field: 'toName',
            required: false,
            description: 'toNameDesc'
        }, {
            field: 'toAddress',
            required: true,
            description: 'toAddressDesc'
        }, {
            field: 'html',
            required: true,
            description: 'htmlDesc'
        },
        {
            field: 'attachments',
            required: false,
            description: 'atachmentsDesc'
        },
        {
            field: 'TemplateId',
            required: false,
            description: 'templateIdDesc'
        }, {
            field: 'sendDate',
            required: false,
            description: 'sendDateDesc'
        },
        {
            field: 'customFields',
            required: false,
            description: 'customFieldsDesc'
        }
        ,
        {
            field: 'trackOpens',
            required: false,
            description: 'trackOpensDesc'
        }
        ,
        {
            field: 'trackLinks',
            required: false,
            description: 'trackLinksDesc'
        }
    ],
    response: [{
        field: 'id',
        description: 'idDesc'
    }]
}
import React, { useState, useReducer, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import * as styles from "./index.module.scss";
import axios from "~/data/http/axios";
import Header from "../../../components/header";
import TextInput from "../../../components/textInput";
import Button from "../../../components/button";
import i18n from "~/i18n";
import SettingsSection from "../../../components/settingsSection";
import DeleteChannelDialog from "../../../inboxSettings/channels/components/deleteChannelDialog";

export default function EmailChannelGeneral({
    channel,
    onUpdateChannel,
}) {
    const dispatch = useDispatch();
    const history = useHistory()
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [name, setName] = useState(channel.name);
    const [replyName, setReplyName] = useState(channel?.settings?.replyName || "");
    const [errors, setError] = useState({});
    const [isDeleteChannelDialogOpen, setIsDeleteChannelDialogOpen] = useState(false);
    const [emailChannels, setEmailChannels] = useState([]);
    useEffect(() => {
        loadAllChannels();
    }, []);

    async function loadAllChannels() {
        try {
            const { data } = await axios.get(`/inboxes/${channel.InboxId}/channel`);
            setEmailChannels(data.filter((c) => c.type === 1));
        } catch (error) {
            dispatch(handleGenericError(error));
            throw error;
        }
        setLoading(false);
    }


    async function handleSubmit(e) {
        e.preventDefault();

        let errors = {};
        let errored = false;
        setError("")
        if (!name) { errored = true; errors.name = i18n.t("chat:channel.email.general.nameRequired"); }
        if (name.trim().length < 3) { errored = true; errors.name = i18n.t("chat:channel.email.general.nameLength"); }
        if (!replyName) { errored = true; errors.fromName = i18n.t("chat:channel.email.general.settings.fromNameRequired"); }
        if (replyName.trim().length < 3) { errored = true; errors.fromName = i18n.t("chat:channel.email.general.settings.fromNameLength"); }
        if (errored) {
            setError(errors);
            return;
        }
        setSubmitting(true);

        const settings = { ...channel.settings, replyName };
        const data = { name, settings };

        try {
            await onUpdateChannel(data);
            dispatch(alterSnackbar(true, i18n.t("chat:channel.email.general.saved")));
        } catch (error) {
            dispatch(handleGenericError(error));
        } finally {
            setSubmitting(false);
        }
    }

    async function handleDelete() {
        try {
            await axios.delete(`/inboxes/${channel.InboxId}/channel/${channel.id}`);
            history.push(`/cp/conversations/${channel.InboxId}/channels`);
        } catch (error) {
            dispatch(handleGenericError(error));
            throw error;
        }
    }

    let canDelete = false;
    const runningChannels = emailChannels.filter((c) => c.status === "running");
    if (runningChannels.length > 1) {
        canDelete = true;
    }
    else {
        if (runningChannels.length === 1 && runningChannels[0].id != channel.id) {
            canDelete = true;
        }
    }

    if (loading) return null;
    return (
        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
            <Header type="email" option="general" />
            <form className={styles.root} onSubmit={handleSubmit}>
                <TextInput
                    label={i18n.t("chat:channel.email.general.name")}
                    value={name}
                    error={errors.name}
                    onChange={(e) => setName(e.target.value)}
                    maxlength={100}
                />
                <Button
                    className={styles.submitButton}
                    type="submit"
                    size="s"
                    loading={submitting}
                >
                    {i18n.t("chat:channel.email.general.save")}
                </Button>
            </form>

            <hr></hr>

            <SettingsSection heading={i18n.t("chat:channel.email.general.settings.header")} subheading={i18n.t("chat:channel.email.general.settings.subHeader")}
                style={{ alignItems: "normal" }}>
                <form className={styles.root} onSubmit={handleSubmit}>
                    <TextInput
                        label={i18n.t("chat:channel.email.general.settings.defaultFromName")}
                        value={replyName}
                        error={errors.replyName}
                        onChange={(e) => setReplyName(e.target.value)}
                        style={{ width: "100%" }}
                        outerStyle={{ width: "100%" }}
                    />
                    <Button
                        className={styles.submitButton}
                        type="submit"
                        size="s"
                        loading={submitting}
                    >
                        {i18n.t("chat:channel.email.general.settings.save")}
                    </Button>
                </form>
            </SettingsSection>

            <hr></hr>
            <SettingsSection heading={i18n.t("chat:channel.email.general.deleteHeader")} subheading={i18n.t("chat:channel.email.general.deleteSubheader")}>

                {!canDelete && <div className="text-red">{i18n.t("chat:channel.email.general.unableToDelete")}</div>}

                <Button
                    className={styles.submitButton}
                    size="s"
                    variant="danger"
                    onClick={() => setIsDeleteChannelDialogOpen(true)}
                    disabled={!canDelete}
                >
                    {i18n.t("chat:channel.email.general.deleteButton")}
                </Button>
            </SettingsSection>

            <DeleteChannelDialog
                key={`delete-${channel?.id}`} // For resetting dialog state when closed/opened
                channel={channel}
                isOpen={isDeleteChannelDialogOpen}
                onSubmit={handleDelete}
                onOpenChange={(isOpen) => setIsDeleteChannelDialogOpen(isOpen)}

            />
        </div>
    );
}

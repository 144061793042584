import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
class GenericIntegrationOption extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        if (!this.props.accApp) {
            return <div id={this.props.id} className="automationOption">
                Integration no longer connected
                {!this.props.isRunning && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep() }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
            </div>
        }

        var classes = ' green'
        var outerStyle = {}
        var step = this.props.step
        var accApp = this.props.accApp
        var hook = accApp.Application.options.hooks.find(h => h.action === step.action)

        if (!this.props.isThemeDesigner) {
            hook.build.filter(b => b.required).forEach(item => {
                if (!step.stepOptions[item.value] || !step.stepOptions[item.value].value) {
                    classes = ' incomplete'
                }
            })
        }
        return (
            <div id={this.props.id} className={"automationOption " + classes} onClick={() => this.props.editStep(this.props.step)} style={this.props.style || outerStyle}>
                {this.props.statsQueuedHolder}
                <img src={accApp.Application.appLogoUri} height="40" alt="" />
                <div className="text-med mar-b10">{accApp.Application.appName}</div>
                <div><strong>{hook.name}</strong></div>
                {this.props.statsProcessedHolder}
                {this.props.contactOnStep}
                {!this.props.isRunning && <UILIB.Icons icon="bin" className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep() }} />}
            </div>
        );
    }
}

export default GenericIntegrationOption;
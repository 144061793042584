import React from 'react'

export default class Drawer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className="flex-clean-up" name={this.props.name} id={this.props.id} onClick={this.props.onClick}>
            <div className="uiDrawerLink" >
                {this.props.children}
            </div>
            <div className="notification">
                <span>{this.props.options}</span>
            </div>
        </div>

    }
}
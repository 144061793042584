import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';
import { connect } from 'react-redux';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import PermissionChecker from '~/Classes/permissions';

@connect((store) => {
    return { permissionStore: store.permission, siteMaster: store.siteMaster, user: store.user }
})
class ViewFormSubs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signups: [],
            total: 0,
            limit: 30,
            page: 0,
            searchText: '',
            loadingData: true,
            isLoading: true,
            active: false,
            exporting: false
        }
        this.getSignUps = this.getSignUps.bind(this)
        this.changePage = this.changePage.bind(this)
        this.changePageSize = this.changePageSize.bind(this)
        this.changeSearchText = this.changeSearchText.bind(this)
        this.toggleActive = this.toggleActive.bind(this)
        this.exportCsv = this.exportCsv.bind(this);
    }

    componentDidMount() {
        this.getSignUps()
    }

    getSignUps() {
        this.setState({
            loadingData: true,
            isLoading: true
        })
        var queryStr = '?limit=' + this.state.limit + '&offset=' + this.state.limit * this.state.page
        if (this.state.searchText && this.state.searchText.length > 3) {
            queryStr += '&searchText=' + this.state.searchText
        }
        if (this.state.active) {
            queryStr += '&active=true'
        }

        axios.get('/group/' + this.props.match.params.groupId + '/form/' + this.props.match.params.formId + '/subscriber' + queryStr).then(response => {
            this.setState({
                loadingData: false,
                isLoading: false,
                total: response.data.total,
                signups: response.data.signups.map(row => {
                    var active = row.emailAddress ? row.active ? true : false : undefined
                    if (active) {
                        active = <UILIB.SquareChip className="square-chip-red">Yes</UILIB.SquareChip>
                    } else if (active === false) {
                        active = <UILIB.SquareChip className="square-chip-green">No</UILIB.SquareChip>
                    } else {
                        active = <UILIB.SquareChip className="square-chip-grey">{i18n.t('form:view.deleted')}</UILIB.SquareChip>
                    }
                    const obj = {
                        createdAt: {
                            headerValue: i18n.t('form:view.signupDate'),
                            value: DateTimeFunctions.formatDateTime(row.createdAt),
                            orderBy: false,
                            order: 1
                        },
                        emailAddress: {
                            headerValue: i18n.t('emailAddress'),
                            value: row.emailAddress ? <a onClick={() => this.props.history.push('/cp/subscriber/' + row.SubscriberId)}>{row.emailAddress}</a> : '--DELETED--',
                            orderBy: false,
                            order: 2
                        },
                        device: {
                            headerValue: i18n.t('form:view.device'),
                            value: row.device,
                            orderBy: false,
                            order: 3 + row.customFields.length
                        },
                        country: {
                            headerValue: i18n.t('form:view.country'),
                            value: row.country,
                            orderBy: false,
                            order: 4 + row.customFields.length
                        },
                        active: {
                            headerValue: i18n.t('active'),
                            value: active,
                            orderBy: false,
                            order: 5 + row.customFields.length
                        }
                    }
                    let i = 0
                    for (const field of row.customFields) {
                        i++
                        obj[field.fieldDesc] = {
                            headerValue: field.fieldDesc,
                            value: field.value,
                            order: 2 + i
                        }
                    }
                    return obj
                })
            })
        })
    }

    exportCsv() {
        this.setState({ exporting: true })
        var queryStr = "";
        if (this.state.searchText && this.state.searchText.length > 3) {
            queryStr += '?searchText=' + this.state.searchText
        }
        if (this.state.active) {
            queryStr += `${queryStr.length ? "&" : "?"}active=true`
        }

        axios.get('/group/' + this.props.match.params.groupId + '/form/' + this.props.match.params.formId + '/subscriber/export' + queryStr).then(resp => {
            this.setState({ exporting: false })
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `form_${this.props.match.params.formId}_export.csv`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
    }


    changePage(currentPage, sortDirection, sortColumn, pageSize, searchText) {
        var obj = {}
        if (currentPage !== null && currentPage !== undefined) {
            obj.page = currentPage
        }

        if (sortDirection !== null && sortDirection !== undefined) {
            obj.orderBy = sortDirection
        }

        if (sortColumn !== null && sortColumn !== undefined) {
            obj.orderDir = sortColumn
        }

        if (pageSize !== null && pageSize !== undefined) {
            obj.limit = pageSize
        }

        this.setState(obj, this.getSignUps)
    }

    changePageSize(limit) {
        this.setState({
            limit
        }, this.getSignUps)
    }

    changeSearchText(ev) {
        var text = ev.currentTarget.value;
        this.setState({
            searchText: text,
            page: 0
        }, () => {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(this.getSignUps, 500)
        })
    }

    toggleActive() {
        this.setState({
            active: !this.state.active,
            page: 0
        }, this.getSignUps)
    }

    render() {
        var canExport = PermissionChecker('reporting', this.props.permissionStore.permissions, "read");
        return (
            <div>
                <UILIB.Row className="middle-lg middle-md">
                    <UILIB.Column md={6} sm={12} xs={12} className="mar-b25">

                        <UILIB.TextInput
                            placeholder={i18n.t('subscribers:overview.searchPlaceholder')}
                            style={{ minWidth: "250px" }}
                            value={this.state.searchText}

                            onChange={this.changeSearchText}
                            onKeyPress={this.searchKeyPress}
                            iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}

                        />

                    </UILIB.Column>
                    <UILIB.Column md={6} hide={["xs", "sm"]} className="end-xs mar-b25" style={{ display: "flex", alignItems: "center" }}>
                        <div>{i18n.t('showing')}</div>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.limit === 15} onClick={() => { this.changePageSize(15) }}>15</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.limit === 30} onClick={() => { this.changePageSize(30) }}>30</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.limit === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.limit === 150} onClick={() => { this.changePageSize(150) }}>150</UILIB.ButtonSimple>
                    </UILIB.Column>
                </UILIB.Row>

                <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignContent: "center" }}>

                    <UILIB.Button
                        text={this.state.active ? i18n.t('form:view.showAll') : i18n.t('form:view.onlyActive')}
                        onClick={this.toggleActive}
                    />

                    {(canExport == true && this.state.signups.length > 0) && <UILIB.Button
                        text="Export"
                        onClick={this.exportCsv}
                        className="button-primary"
                        disabled={this.state.exporting}
                        iconRight={<UILIB.Icons icon="arrowDown" />}
                    />}
                </div>

                <UILIB.DataTable1
                    noResultsTxt={i18n.t('form:view.noSignups')}
                    tableData={this.state.signups}
                    dataUpdater={this.changePage}
                    loadingData={this.state.loadingData}
                    width="100%"
                    pageSize={this.state.limit}
                    outerClassName="mar-b25" />



                <div className="center-xs">
                    <UILIB.PagingBlock
                        totalRows={this.state.total}
                        pageSize={this.state.limit}
                        numberOfLinks="10"
                        currentPage={this.state.page}
                        changePage={this.changePage}
                        text={i18n.t('page') + ":"} />
                </div>
            </div >
        );
    }
}

export default ViewFormSubs;
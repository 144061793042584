import React from 'react'

export default class IconsCalendar extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6 2.00006C5.73478 2.00006 5.48043 2.10542 5.29289 2.29295C5.10536 2.48049 5 2.73484 5 3.00006V4.00006H4C3.46957 4.00006 2.96086 4.21077 2.58579 4.58585C2.21071 4.96092 2 5.46963 2 6.00006V16.0001C2 16.5305 2.21071 17.0392 2.58579 17.4143C2.96086 17.7893 3.46957 18.0001 4 18.0001H16C16.5304 18.0001 17.0391 17.7893 17.4142 17.4143C17.7893 17.0392 18 16.5305 18 16.0001V6.00006C18 5.46963 17.7893 4.96092 17.4142 4.58585C17.0391 4.21077 16.5304 4.00006 16 4.00006H15V3.00006C15 2.73484 14.8946 2.48049 14.7071 2.29295C14.5196 2.10542 14.2652 2.00006 14 2.00006C13.7348 2.00006 13.4804 2.10542 13.2929 2.29295C13.1054 2.48049 13 2.73484 13 3.00006V4.00006H7V3.00006C7 2.73484 6.89464 2.48049 6.70711 2.29295C6.51957 2.10542 6.26522 2.00006 6 2.00006ZM6 7.00006C5.73478 7.00006 5.48043 7.10542 5.29289 7.29295C5.10536 7.48049 5 7.73484 5 8.00006C5 8.26528 5.10536 8.51963 5.29289 8.70717C5.48043 8.8947 5.73478 9.00006 6 9.00006H14C14.2652 9.00006 14.5196 8.8947 14.7071 8.70717C14.8946 8.51963 15 8.26528 15 8.00006C15 7.73484 14.8946 7.48049 14.7071 7.29295C14.5196 7.10542 14.2652 7.00006 14 7.00006H6Z" fill={color} />
        </svg>



    }
}        
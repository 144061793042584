import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import i18n from "i18next";
import UILIB from "~/Common-Objects/Lib";
import RegularFormImg from "~/assets/images/illustrations/regular-form.svg";
import GatedContentImg from "~/assets/images/illustrations/gated-content.svg";
import TierPermissions from "../../../../../Classes/tierPermissions";
import { NORMAL_FORM, GATED_CONTENT } from "../index";

export default function SelectFormType({ onSelect }) {
  const history = useHistory();
  const account = useSelector(state => state.accountMaster)
  const columnProps = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3,
    className: "mar-b24",
  };

  const gatedAllowed = TierPermissions("gatedContent", account)

  const select = (type) => {
    if (type == GATED_CONTENT && !gatedAllowed.allowed) {
      history.push('/cp/company/subscription')
    } else {
      onSelect(type);
    }
  }

  return (
    <>
      <div className="center-xs mar-b60">
        <h1 className="h2 mar-b10">
          {i18n.t("campaigns:overview.createANewForm")}
        </h1>
        <p className="text-md text-grey">
          {i18n.t("form:add.type.header")}
        </p>
      </div>

      <UILIB.Row className="center-xs">
        <UILIB.Column {...columnProps}>
          <UILIB.IllustrationCard
            className="illustration-card--purple"
            img={<img src={RegularFormImg} alt="" />}
            headingTag="h2"
            heading={i18n.t("form:add.type.regular")}
            cta={
              <UILIB.Button
                className="button-primary"
                onClick={() => select(NORMAL_FORM)}
              >
                {i18n.t("form:add.type.regularSelect")}
              </UILIB.Button>
            }
          >
            <p>
              {i18n.t("form:add.type.regularText")}
            </p>
          </UILIB.IllustrationCard>
        </UILIB.Column>

        <UILIB.Column {...columnProps}>
          <UILIB.IllustrationCard
            className="illustration-card--green"
            img={<img src={GatedContentImg} alt="" />}
            headingTag="h2"
            heading={i18n.t("form:add.type.gated")}
            cta={
              <UILIB.Button
                className={gatedAllowed.allowed ? "button-primary" : "button-grey"}
                onClick={() => select(GATED_CONTENT)}
              >
                {gatedAllowed.allowed ? i18n.t("form:add.type.gatedSelect") : i18n.t("form:add.type.gatedDisabled")}
              </UILIB.Button>
            }
          >
            <p>
              {i18n.t("form:add.type.gatedText")}
            </p>
          </UILIB.IllustrationCard>
        </UILIB.Column>
      </UILIB.Row>
    </>
  );
}

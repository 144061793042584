// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".m0WfZRLEB5eoIABOR0Ta{position:relative}.m0WfZRLEB5eoIABOR0Ta .txtInput{padding-left:36px}.HAxYSBcFwA8PWR9cQyIN{display:block;position:absolute;bottom:10px;left:12px;height:16px;width:16px;border-radius:4px;border:1px solid rgba(0,0,0,.1)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/components/colorInput/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEA,gCACE,iBAAA,CAIJ,sBACE,aAAA,CACA,iBAAA,CACA,WAAA,CACA,SAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,+BAAA","sourcesContent":[".root {\n  position: relative;\n\n  :global(.txtInput) {\n    padding-left: 36px;\n  }\n}\n\n.swatch {\n  display: block;\n  position: absolute;\n  bottom: 10px;\n  left: 12px;\n  height: 16px;\n  width: 16px;\n  border-radius: 4px;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "m0WfZRLEB5eoIABOR0Ta",
	"swatch": "HAxYSBcFwA8PWR9cQyIN"
};
export default ___CSS_LOADER_EXPORT___;

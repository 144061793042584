import React from 'react'

export default class IconsArrowUp extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.29895 9.7009C5.11148 9.51337 5.00616 9.25906 5.00616 8.9939C5.00616 8.72873 5.11148 8.47442 5.29895 8.2869L9.29895 4.2869C9.48648 4.09943 9.74079 3.99411 10.0059 3.99411C10.2711 3.99411 10.5254 4.09943 10.7129 4.2869L14.7129 8.2869C14.8951 8.4755 14.9959 8.7281 14.9936 8.9903C14.9913 9.25249 14.8862 9.50331 14.7008 9.68871C14.5154 9.87412 14.2645 9.97929 14.0023 9.98157C13.7402 9.98385 13.4876 9.88305 13.2989 9.7009L11.0059 7.4079V14.9939C11.0059 15.2591 10.9006 15.5135 10.7131 15.701C10.5255 15.8885 10.2712 15.9939 10.0059 15.9939C9.74073 15.9939 9.48638 15.8885 9.29884 15.701C9.11131 15.5135 9.00595 15.2591 9.00595 14.9939V7.4079L6.71295 9.7009C6.52542 9.88837 6.27111 9.99368 6.00595 9.99368C5.74079 9.99368 5.48648 9.88837 5.29895 9.7009Z" fill={color} />
        </svg>

    }
}        
import React, { Component } from 'react';
import { DropTarget, DragSource } from 'react-dnd'
import ItemTypes from './dndTypes'
import UILIB from '~/Common-Objects/Lib'

const dropSource = {
    drop(props, monitor, component) {
        const incomingDomain = monitor.getItem();
        if (props.group.id != incomingDomain.currentGroupId) {
            props.domainChangeGroup(incomingDomain.domainId, props.group.id, incomingDomain.currentGroupId)
        }
        return {}
    },
    hover(props, monitor, component) {

    }
}

@DropTarget([ItemTypes.DOMAIN], dropSource, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget()
}))

class DomainHolder extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.changeRotation = this.changeRotation.bind(this);
    }

    changeRotation(event, isNumeric) {
        var group = this.props.group;
        var value = event.target.value;
        if (isNumeric) value = Number(value);
        group.domainRotationSettings[event.target.name] = value;
        this.props.updateState(this.props.group.id, group);
    }

    render() {
        const { canDrop, isOver, connectDropTarget } = this.props

        var domain = this.props.domain;

        var rotateValue = [];
        var totDomains = 0;
        if (this.props.group.domains && Array.isArray(this.props.group.domains)) {
            totDomains = this.props.group.domains.length + 1
        }
        var tmpCnt = 0;
        while (tmpCnt < totDomains) {
            rotateValue.push(tmpCnt)
            tmpCnt++;
        }

        var rotationSettings = this.props.group.domainRotationSettings;
        if (!rotationSettings) rotationSettings = {};
        if (!rotationSettings.number) rotationSettings.number = 0;
        if (!rotationSettings.value) rotationSettings.value = 0;
        if (!rotationSettings.period) rotationSettings.period = "days";

        return connectDropTarget(
            <div style={{ height: "100%", width: "100%", minHeight: "200px", display: "flex", flexDirection: "column" }}>
                <div style={{ flex: "1", overflow: "auto", paddingLeft: "20px", paddingRight: "20px" }}>{this.props.children}</div>
                {this.props.footer && <div style={{ flex: "0 0 60px", borderTop: "1px solid grey", padding: "20px" }}>
                    <span className="mar-r10 mar-b0">Rotate</span>
                    <div className="mar-r10 mar-b0" style={{ display: "inline-block", width: "50px" }}>
                        <UILIB.Select data={rotateValue} name="number" value={rotationSettings.number} onChange={(event) => { this.changeRotation(event, true) }} />
                    </div>
                    <span className="mar-r10 mar-b0">
                        domains
                    </span>

                    {rotationSettings.number > 0 && <span>
                        <span className="mar-r10 mar-b0">
                            every
                        </span>

                        <div className="mar-r10 mar-b0" style={{ display: "inline-block", width: "50px" }}>
                            <UILIB.Select data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} name="value" value={rotationSettings.value} onChange={(event) => { this.changeRotation(event, true) }} />
                        </div>
                        <div className="mar-b0" style={{ display: "inline-block", width: "80px" }}>
                            <UILIB.Select data={[{ label: "Days", value: "days" }, { label: "Weeks", value: "weeks" }, { label: "Months", value: "months" }]}
                                name="period"
                                value={rotationSettings.period}
                                onChange={(event) => { this.changeRotation(event, true) }} />
                        </div>
                    </span>
                    }
                </div>}

            </div>
        )
    }
}
export default DomainHolder;
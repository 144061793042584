import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import PermissionChecker from '~/Classes/permissions';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'
import AutomationThemeSelector from '~/pages/cp/includes/automations/automationThemeSelector'
import axios2 from 'axios'
import { getUserSettings, setUserSettings } from '../../../Classes/userSettings';
import queryString from 'query-string';

@connect((store) => {
    return { permissionStore: store.permission, siteMaster: store.siteMaster, account: store.accountMaster }
})
class Automations extends Component {
    constructor(props) {
        super(props);

        let timer;
        const query = queryString.parse(location.search);

        this.state = {
            automations: [],
            total: 0,
            page: 0,
            limit: 10,
            sortColumn: 'id',
            sortDirection: 'DESC',
            searchText: '',
            loadingData: 1,
            searching: false,
            firstLoadAutomationCount: 0,
            firstLoad: true,
            statusOptions: [{
                label: i18n.t('automation:overview.all'),
                value: ""
            }, {
                label: i18n.t('automation:overview.running'),
                value: "Running"
            }, {
                label: i18n.t('automation:overview.paused'),
                value: "Paused"
            }, {
                label: i18n.t('automation:overview.drafts'),
                value: "Draft"
            }, {
                label: i18n.t('automation:overview.testing'),
                value: "Testing"
            }],
            statusOption: query.statusOption || "",
        }

        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();
        this.searchTimer = null;

        this.getAutomations = this.getAutomations.bind(this)
        this.tableUpdater = this.tableUpdater.bind(this)
        this.delete = this.delete.bind(this)
        this.pause = this.pause.bind(this)
        this.resume = this.resume.bind(this)
        this.add = this.add.bind(this)
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSearchText = this.changeSearchText.bind(this);
        this.duplicate = this.duplicate.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.selectTheme = this.selectTheme.bind(this);
        this.archive = this.archive.bind(this)
        this.unarchive = this.unarchive.bind(this)
        this.getAutomationStats = this.getAutomationStats.bind(this);
    }

    componentDidMount() {
        this.unmounting = false;
        getUserSettings().then(s => {
            if (!s.automations) s.automations = {}
            let save = false
            if (!s.automations.limit && this.props.account.tableLimits?.automations) {
                save = true
                s.automations.limit = this.props.account.tableLimits.automations
            }
            if (save) setUserSettings(s).catch(console.log)
            this.setState({ settings: s, limit: s.automations.limit || 10 }, this.getAutomations)
        })
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.permissionStore.permissions || !prevProps.permissionStore.permissions.automations) {
            this.getAutomations()
        } else if (prevProps.permissionStore.permissions.automations !== this.props.permissionStore.permissions.automations) {
            this.getAutomations()
        }
    }

    componentWillUnmount() {
        this.source.cancel('I cancelled this')
        this.source = this.CancelToken.source();
        this.unmounting = true;
        clearTimeout(this.timer);
        if (this.searchTimer) clearTimeout(this.searchTimer)
    }


    tableUpdater(page, orderDir, orderBy, limit, searchText) {
        var obj = {}
        if (page !== undefined && page !== null) obj.page = page;
        if (orderDir) obj.sortDirection = orderDir;
        if (orderBy) obj.sortColumn = orderBy;
        if (limit) obj.limit = limit;
        if (searchText) obj.searchText = searchText

        this.setState(obj, this.getAutomations)
    }

    changePageSize(newSize) {
        const settings = this.state.settings
        settings.automations.limit = newSize
        setUserSettings(settings).catch(console.log)
        this.setState({ page: 0, limit: newSize, settings }, () => { this.tableUpdater(undefined, undefined, undefined, newSize) })
    }

    delete(id) {
        var drawerContent = <UILIB.DrawerConfirm header={i18n.t('automation:overview.sureDelete')} showCancel={true} confirm={async () => {
            this.setState({
                alertMessage: i18n.t('automation:overview.deletingAutomation'),
                alertOpen: true
            })
            await axios.delete('/automation/' + id).then(() => {
                this.setState({
                    alertMessage: i18n.t('automation:overview.automationDeleted'),
                    alertOpen: true
                })
                this.getAutomations()
            })
        }} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, null, true));
    }

    pause(id) {
        axios.put('/automation/' + id + '/pause').then(this.getAutomations)
    }

    resume(id) {
        axios.put('/automation/' + id + '/resume').then(this.getAutomations)
    }

    changeSearchText(event) {
        var theVal = event.target.value;
        this.setState({ searchText: theVal, searching: true }, () => {
            if (this.searchTimer) clearTimeout(this.searchTimer)
            this.searchTimer = setTimeout(() => {
                this.getAutomations()
            }, 200)
        });
    }

    add() {
        var drawerContent = <AutomationThemeSelector scratch={() => { this.props.history.push('/cp/automation/addnew') }} select={this.selectTheme} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));

    }

    selectTheme(theme) {
        axios.post('/automation/theme/' + theme.id).then(response => {
            this.setState({
                alertMessage: i18n.t('automation:overview.automationDuplicated'),
                alertOpen: true
            })
            this.props.history.push('/cp/automation/addnew/' + response.data.Automation.id)
        })
    }
    duplicate(automationId) {
        var drawerContent = <UILIB.DrawerConfirm header={i18n.t('automation:overview.areYouSureDuplicate')} showCancel={true} confirm={() => {
            this.setState({
                alertMessage: i18n.t('automation:overview.duplicatingAutomation'),
                alertOpen: true
            })
            axios.put('/automation/' + automationId + '/copy').then(response => {
                this.setState({
                    alertMessage: i18n.t('automation:overview.automationDuplicated'),
                    alertOpen: true
                })
                this.props.history.push('/cp/automation/addnew/' + response.data.AutomationId)
            })
        }} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, null, true));
    }

    archive(automation) {
        const drawerContent = <UILIB.DrawerConfirm
            header={'Archive ' + automation.name + '?'}
            subheader="Archiving stops Contacts from entering the Automation but Contacts already in the Automation will continue to be processed"
            showCancel={true}
            confirm={() => {
                this.setState({
                    alertMessage: 'Archiving Automation',
                    alertOpen: true
                })
                axios.put('/automation/' + automation.id + '/archive').then(() => {
                    this.setState({
                        alertMessage: 'Automation Archived',
                        alertOpen: true
                    })
                    this.getAutomations()
                })
            }} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, null, true));
    }

    unarchive(automation) {
        const drawerContent = <UILIB.DrawerConfirm
            header={'Unarchive ' + automation.name + '?'}
            subheader="This will allow Contacts to enter the Automation again"
            showCancel={true}
            confirm={() => {
                this.setState({
                    alertMessage: 'Unarchiving Automation',
                    alertOpen: true
                })
                axios.put('/automation/' + automation.id + '/unarchive').then(() => {
                    this.setState({
                        alertMessage: 'Automation Unrchived',
                        alertOpen: true
                    })
                    this.getAutomations()
                })
            }} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, null, true));
    }

    async getAutomations() {

        try {
            var queryStr = this.state;
            clearTimeout(this.timer);
            if (this.unmounting) return
            var searchStatus = "";
            if (!this.state.loadingData) {
                this.source.cancel()
                this.source = this.CancelToken.source();
            }

            if (this.state.statusOption) {
                searchStatus = this.state.statusOption
                if (history.pushState) {
                    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname
                    newurl += `${newurl[newurl.length - 1] === '?' ? '&' : '?'}statusOption=${this.state.statusOption}`
                    window.history.pushState({ path: newurl }, '', newurl);
                }
            } else {
                if (history.pushState) {
                    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname
                    window.history.pushState({ path: newurl }, '', newurl);
                }
            }

            let automations = await axios.get('/automation?sortColumn=' + queryStr.sortColumn + '&sortOrder=' + queryStr.sortDirection + '&searchText=' + queryStr.searchText + '&startRecord=' + queryStr.page * queryStr.limit + '&recordLimit=' + queryStr.limit + '&status=' + searchStatus, {
                cancelToken: this.source.token
            })
            automations = automations.data;


            var canEdit = PermissionChecker('automations', this.props.permissionStore.permissions, "write")
            var canViewGroups = PermissionChecker('subscribers', this.props.permissionStore.permissions, "read")

            let firstLoadAutomationCount = this.state.firstLoadAutomationCount;
            if (this.state.firstLoad) {
                firstLoadAutomationCount = automations.count
            }

            this.setState({
                firstLoad: false,
                firstLoadAutomationCount,
                searching: false,
                loadingData: 0,
                total: automations.count,
                automations: automations.Automations.map((row, index) => {
                    if (row.steps && row.steps.length) {
                        row.steps.forEach(theStep => {
                            if (!isNaN(theStep.hits))
                                subsAffected += theStep.hits
                        })
                    }
                    var editLink = '/cp/automation/view/';
                    if (row.version == 0) {
                        if (row.status == 'Draft') editLink = '/cp/automation/add/';
                    }
                    if (row.version == 1) {
                        editLink = '/cp/automation/addnew/'
                    }
                    editLink += row.id

                    var groupValue = '';
                    if (row.groupName) {
                        if (canViewGroups == true) groupValue = <UILIB.SquareChip className="square-chip-purple" iconRight={<UILIB.Icons style={{ width: "15px", height: "15px" }} icon="magnifier" />} onClick={() => this.props.history.push('/cp/groups/' + row.groupId)}>{row.groupName}</UILIB.SquareChip>
                        if (canViewGroups == false) groupValue = <UILIB.SquareChip>{row.groupName}</UILIB.SquareChip>

                    }
                    if (row.groupId == "-1000") {
                        groupValue = <UILIB.SquareChip>Any Group</UILIB.SquareChip>
                    }
                    var hasOptions = false;
                    if (row.status == 'Draft' && canEdit == true) hasOptions = true;
                    if (row.status == 'Running') hasOptions = true;
                    if (row.status == 'Paused' && canEdit == true) hasOptions = true;
                    if (row.status == 'Starting' && canEdit == true) hasOptions = true;
                    if (canEdit && row.version === 1) hasOptions = true;
                    let statusChipColor = "";
                    if (row.status == "Running") statusChipColor = "square-chip-green";
                    if (row.status == "Paused") statusChipColor = "square-chip-orange";
                    if (row.status == "Starting") statusChipColor = "square-chip-yellow";
                    if (row.status == "Testing") statusChipColor = "square-chip-blue";

                    let automationExists = this.state.automations.find(f => f.idRaw.value == row.id)
                    let queueCount = <UILIB.SquareChip className="square-chip-large square-chip-purple square-chip-min-width"><UILIB.LoadingIcons iconType="2" style={{ width: "20px", height: "20px" }} /></UILIB.SquareChip>;
                    if (automationExists) queueCount = automationExists.queueCount.value;
                    var rows = {
                        idRaw: {
                            value: row.id
                        },
                        statusRaw: {
                            value: row.status
                        },
                        name: {
                            headerValue: i18n.t('automation:overview.name'),
                            value: <span>
                                {(canEdit == true || (canEdit == false && row.status == "Running")) && <Link to={editLink} style={{ fontWeight: "600", textDecoration: 'none' }}>{row.name}</Link>}
                                {(canEdit == false && row.status != "Running") && <span style={{ fontWeight: "600" }}>{row.name}</span>}
                            </span>,
                            orderBy: true
                        },
                        group: {
                            headerValue: i18n.t('automation:overview.groupHeading'),
                            value: groupValue,
                            orderBy: false
                        },
                        queueCount: {
                            headerValue: i18n.t('automation:overview.processed'),
                            value: queueCount,
                            orderBy: true,
                            align: "center",
                            width: 200
                        },
                        status: {
                            headerValue: i18n.t('automation:overview.status'),
                            value: <UILIB.SquareChip
                                iconLeft={<UILIB.Icons icon="circle" />}
                                className={statusChipColor + " small-icon"}>
                                {i18n.t('automation:overview.' + row.status.toLowerCase())}
                            </ UILIB.SquareChip >,
                            orderBy: true,
                            align: "center",
                            width: 200
                        },
                        createdAt: {
                            headerValue: i18n.t('automation:overview.createdDate'),
                            value: DateTimeFunctions.formatDateTime(row.createdAt, 2),
                            orderBy: true,
                            align: "center",
                            width: 200
                        },
                        updatedAt: {
                            headerValue: i18n.t('automation:overview.updatedDate'),
                            value: DateTimeFunctions.formatDateTime(row.updatedAt, 2),
                            orderBy: true,
                            align: "center",
                            width: 200
                        },

                        automationId: {
                            value: row.id,
                            getMe: (row.status == "Running" || row.status == "Paused" || row.status === 'Starting' || row.status === 'Archived'),
                        },
                        optionsCol:
                        {
                            headerValue: " ",
                            value: <Fragment>
                                {hasOptions && <UILIB.OptionsDropdown popWidth="150px">
                                    <ul>
                                        {(row.status == 'Draft' && canEdit == true) && <li>
                                            <a onClick={() => this.props.history.push(editLink)} > {i18n.t('edit')}</a>
                                        </li>}
                                        {(row.status == 'Running' || row.status === 'Testing') && <li>
                                            <a onClick={() => this.props.history.push(editLink)}>{i18n.t('view')}</a>
                                            {row.version != '1' && <a onClick={() => { this.pause(row.id) }}>{i18n.t('automation:overview.pause')}</a>}
                                        </li>}
                                        {(row.status == 'Paused' && canEdit == true) && <li>
                                            <a onClick={() => this.props.history.push(editLink)}>{i18n.t('view')}</a>
                                            {row.version != '1' && <a onClick={() => { this.resume(row.id) }}>{i18n.t('automation:overview.resume')}</a>}
                                        </li>}
                                        {canEdit && row.status !== 'Starting' && <li>
                                            <a onClick={() => this.delete(row.id)}>{i18n.t('delete')}</a>
                                        </li>}
                                        {canEdit && row.version === 1 && <li>
                                            <a onClick={() => this.duplicate(row.id)}>{i18n.t('campaigns:main.duplicate')}</a>
                                        </li>}
                                        {canEdit && (row.status === 'Running') && <li>
                                            <a onClick={() => this.archive(row)}>Archive</a>
                                        </li>}
                                        {canEdit && row.status === 'Archived' && <li>
                                            <a onClick={() => this.unarchive(row)}>Unarchive</a>
                                        </li>}
                                    </ul>
                                </UILIB.OptionsDropdown>}
                            </Fragment>,
                            orderBy: false,
                            fixed: true,
                            width: 20
                        }
                    }

                    return rows;
                })
            }, () => {
                this.getAutomationStats();

            })
        }
        catch (err) {
            console.log(err)
        }
    }

    async getAutomationStats() {
        if (this.unmounting) return
        let automationStats = [];
        let automationIds = this.state.automations.map(m => m.idRaw.value).join("&ids=")

        if (this.state.automations && this.state.automations.length) {
            automationStats = await axios.get(`/automation/queueLength?ids=${automationIds}`);
            automationStats = automationStats.data;
            let automations = this.state.automations;
            automationStats.forEach(automationStat => {

                let automation = automations.find(a => a.idRaw.value == automationStat.AutomationId)
                if (automation) {
                    automation.queueCount.value = <UILIB.SquareChip className="square-chip-large square-chip-grey square-chip-min-width">N/A</UILIB.SquareChip>;
                    if (automation.statusRaw && (automation.statusRaw.value == "Running" || automation.statusRaw.value == "Paused" || automation.statusRaw.value == "Starting" || automation.statusRaw.value == "Archived")) {
                        automation.queueCount.value = <UILIB.SquareChip className="square-chip-large square-chip-purple square-chip-min-width">
                            {NumberFunctions.formatNumber(automationStat.count)}
                        </UILIB.SquareChip>
                    }
                }
            })
            this.setState({ automations }, () => {
                this.timer = setTimeout(this.getAutomations, 10000);
            })
        }
        else {
            this.timer = setTimeout(this.getAutomations, 10000);
        }
    }

    changeStatus(newVal) {
        this.setState({ statusOption: newVal }, this.getAutomations);
    }

    render() {
        if (this.state.firstLoad) return <div><UILIB.LoadingIcons iconType="2" /></div>
        let noResultsText = <span>
            <div className="text-heavy mar-b10">{i18n.t('automation:overview.haventSetup')}</div>
            {this.props.siteMaster.siteId == 1 && <span><a onClick={() => { window.open('https://kb.transpond.io/hc/en-us/articles/360016170572-What-is-Automation-', '_blank') }}>{i18n.t('automation:overview.whatsAutomation')}</a> {this.props.permissionStore.permissions.automations === 'write' && '|'}</span>}
            {this.props.permissionStore.permissions.automations === 'write' && <a onClick={this.add}> {i18n.t('automation:overview.createFirst')} </a>}
        </span>
        if (this.state.searchText || this.state.searching) {
            noResultsText = <span>{i18n.t('automation:overview.none')}</span>
        }

        return (
            <UILIB.Paper>
                <div className="mar-b25 quickFlex">
                    <div className='page-header'>{i18n.t('automation:overview.automations')}</div>
                    {this.props.siteMaster.siteId == 1 && <UILIB.Hint className="hide-xs" href="https://kb.transpond.io/hc/en-us/articles/360016170572-What-is-Automation-" iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>Find out what Automations are and how they can power up your Business.</UILIB.Hint>}
                </div>

                <UILIB.Row>
                    <UILIB.Column xs={12} sm={6} className="mar-b25">
                        <UILIB.TextInput
                            placeholder={i18n.t('campaigns:main.searchPlaceholder')}
                            onChange={this.changeSearchText}
                            iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16, marginTop: "1px" }} color="#2B2F41" onClick={() => { }} />}
                        />
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} style={{ textAlign: "right" }} className="mar-b25">
                        {PermissionChecker('automations', this.props.permissionStore.permissions, "write") &&
                            <UILIB.Button
                                onClick={this.add}
                                text={i18n.t('automation:overview.createAutomation')}
                                iconLeft={<UILIB.Icons icon="plus" />}
                                className="button-primary"
                            />
                        }
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} className="mar-b25" style={{ display: "flex" }}>
                        <UILIB.Select
                            value={this.state.statusOption}
                            data={this.state.statusOptions}
                            inlineLabel="Status"
                            onChange={e => this.setState({ statusOption: e.target.value }, this.getAutomations)} />
                        {/* <UILIB.ButtonSelect
                            selected={this.state.statusOption}
                            headerText={this.state.statusOptions[this.state.statusOption].label}
                            autoClose
                            data={this.state.statusOptions.map((status, index) => {
                                return <a key={"st_" + index} onClick={() => this.changeStatus(index)}>{status.label}</a>
                            })
                            }
                        /> */}
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} style={{ display: "flex", alignItems: "center", justifyContent: "right" }} className="mar-b25">
                        <div>{i18n.t('showing')}</div>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.limit === 10} onClick={() => { this.changePageSize(10) }}>10</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.limit === 50} onClick={() => { this.changePageSize(50) }}>50</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.limit === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.limit === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>
                    </UILIB.Column>
                </UILIB.Row>

                {(this.state.firstLoadAutomationCount > 0) &&
                    <UILIB.DataTable1
                        noResultsTxt={noResultsText}
                        tableData={this.state.automations}
                        loadingData={this.state.loadingData}
                        dataUpdater={this.tableUpdater}
                        width="100%"
                        pageSize={this.state.limit}
                        hasCheckBoxes="no"
                        sortedColumn={this.state.sortColumn}
                        sortedDirection={this.state.sortDirection}
                        className="mar-b25"
                    />}
                {(this.state.firstLoadAutomationCount < 1) && <UILIB.Paper>
                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={6} md={4}>
                            <img
                                src="https://cdn1.ourmailsender.com/siteContent/noneholders/automation-none.png"
                                style={{ width: "100%" }} />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                            <UILIB.Icons icon="lightning" circle={true} style={{ height: 40, width: 40 }} className="mar-b15" />
                            <h4 className="mar-b15">{i18n.t('automation:overview.noAutomationsHeader')}</h4>
                            <div>{i18n.t('automation:overview.noAutomationsDescription')}</div>

                            {PermissionChecker('automations', this.props.permissionStore.permissions, "write") && <div className="mar-t25">
                                <UILIB.Button
                                    className="button-primary"
                                    onClick={this.add}
                                    text={i18n.t('automation:overview.createAutomation')}
                                    iconLeft={<UILIB.Icons icon="plus" />}
                                />
                            </div>
                            }
                        </UILIB.Column>
                    </UILIB.Row>
                </UILIB.Paper>}

                <UILIB.Row>
                    <UILIB.Column xs={12} className="center-xs">
                        <UILIB.PagingBlock totalRows={this.state.total} pageSize={this.state.limit} numberOfLinks={this.state.limit} currentPage={this.state.page}
                            changePage={this.tableUpdater} text={i18n.t('page') + ":"} />
                    </UILIB.Column>
                </UILIB.Row>

                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
            </UILIB.Paper>
        );
    }
}

export default Automations;
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib';


class Site extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            type: ''
        }

        this.updateField = this.updateField.bind(this);
        this.finish = this.finish.bind(this);
    }

    componentDidMount() {
        var state = {}
        if (this.props.type) {
            state.type = this.props.type
        }
        if (this.props.name) {
            state.name = this.props.name
        }
        this.setState(state)
    }

    updateField(ev) {
        this.setState({
            [ev.currentTarget.name]: ev.currentTarget.value
        })
    }

    finish() {
        this.props.finish(this.state.name, this.state.type)
    }

    render() {
        var options = [
            { label: 'Register', value: 'registerTemplateId' },
            { label: 'Forgotten Password', value: 'forgottenTemplateId' },
            { label: 'Invoice', value: 'invoiceTemplateId' },
            { label: 'Invite', value: 'inviteTemplateId' },
            { label: 'Summary', value: 'summaryTemplateId' },
            { label: 'Approve Account', value: 'accountApprovedTemplateId' },
            { label: 'Verify Domain', value: 'verifyDomainTemplateId' },
            { label: 'Campaign Update', value: 'campaignDailyUpdateEmailTemplateId' },
            { label: 'Authorize Payment', value: 'authorizeTemplateId' },
            { label: 'Group -> Daily Summary Email', value: 'groupDailyUpdateEmailTemplateId' },
            { label: 'SMS Warning', value: 'smsCreditWarningTemplateId' },
            { label: 'Invoice Email', value: 'emailInvoiceTemplateId' },
            { label: 'Campaign Error Email', value: 'campaignErrorTemplateId' },
            { label: 'Payment Failure Email', value: 'paymentErrorTemplateId' }
        ]

        return (
            <div>
                <h4 className="mar-b25">Select Name and Site Template</h4>
                <UILIB.TextInput label="Name" outerClassName="mar-b25" value={this.state.name} name="name" onChange={this.updateField} placeholder="Name" />
                <UILIB.Select outerClassName="mar-b25" placeholder="select template" data={options} value={this.state.type} name="type" onChange={this.updateField} />
                <UILIB.Button className="button-primary" text="Save" onClick={this.finish} />

            </div >
        );
    }
}

export default Site;
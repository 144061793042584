import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import AutomationFunctions from '../automationFunctions'
import segmentsHelper from '~/Classes/segments.jsx'
import SegmentEditor from '~/pages/cp/includes/segmentEditor/segmentEditor'
import i18n from '~/i18n'
import automationFunctions from '../automationFunctions';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import CampaignSelector from './utils/campaignSelector';
import TriggerFilters from '../integrations/generic/triggerFilters'
import HelpPopup from '../helpPopup/helpPopup';

@connect((store) => {
    return { siteMaster: store.siteMaster }
})
class DrawerWaitStep extends Component {
    constructor(props) {
        super(props);

        var stringOperators = segmentsHelper.getOperators('string');
        var intOperators = segmentsHelper.getOperators('int');
        var boolOperators = segmentsHelper.getOperators('bool');
        var dateOperators = segmentsHelper.getOperators('date');

        dateOperators = dateOperators.filter(obj => {
            return obj.value !== "between" && obj.value !== "not between";
        });

        var groupOperators = segmentsHelper.groupOperators;

        this.state = {
            drawerContent: undefined,
            step: this.props.step,
            errors: {},
            operators: {
                TEXT: stringOperators,
                NUMBER: intOperators,
                DATE: dateOperators,
                GROUP: groupOperators,
                'TRUE/FALSE': boolOperators,
                BOOLEAN: boolOperators,
                SELECT: boolOperators,
                MULTISELECT: boolOperators,
                TELNO: stringOperators
            },
            campaignLinks: []
        }

        this.updateValue = this.updateValue.bind(this);
        this.saveStep = this.saveStep.bind(this);
        this.updateDate = this.updateDate.bind(this);
        this.createSegment = this.createSegment.bind(this);
        this.createdSegment = this.createdSegment.bind(this);
        this.getCampaignLinks = this.getCampaignLinks.bind(this);
        this.viewEditSegment = this.viewEditSegment.bind(this);
    }

    componentDidMount() {
        //add fields if they dont exist
        if (this.props.step && this.props.step.stepOptions) {
            if (!this.props.step.stepOptions.groupId) this.props.step.stepOptions.groupId = { "value": "-1" }
            if (!this.props.step.stepOptions.subscriberState) this.props.step.stepOptions.subscriberState = { "value": "" }
            if (!this.props.step.stepOptions.timeout) this.props.step.stepOptions.timeout = { "value": "0" }
            if (!this.props.step.stepOptions.delay_value) this.props.step.stepOptions.delay_value = { "value": "1" }
            if (!this.props.step.stepOptions.delay_unit) this.props.step.stepOptions.delay_unit = { "value": "days" }
            if (!this.props.step.stepOptions.filters) this.props.step.stepOptions.filters = []

            this.setState({ step: this.props.step })
        }

        if (this.state.step.stepOptions.triggerType.value == "3" && this.state.step.stepOptions.campaignId.value != "-1" && (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5")) {
            this.getCampaignLinks()
        }
        if (this.state.step.stepOptions.triggerType.value == "0" && this.state.step.stepOptions.workflowEmailGuid.value != "-1" && (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5")) {
            this.getCampaignLinks()
        }
    }

    updateValue(event) {

        var fieldName = event.target.name;
        var step = this.state.step;
        step.stepOptions[fieldName].value = event.target.value;
        step.complete = false;

        if (fieldName == "triggerType") {
            step.stepOptions.customFieldId.value = "-1"
            step.stepOptions.customFieldAction.value = "="
            step.stepOptions.customFieldValue.value = "-1"
        }

        if (fieldName == "customFieldId") {
            step.stepOptions.customFieldAction.value = "="
            step.stepOptions.customFieldValue.value = "-1"
        }
        if (fieldName == "campaignId" || fieldName == "workflowEmailGuid" || (fieldName == "workflowEmailActionType" && (event.target.value == "3" || event.target.value == "5"))) {
            this.getCampaignLinks()
        }

        this.setState(step)
    }

    updateDate(variableName, newVal) {
        var step = this.state.step;
        step.stepOptions[variableName].value = newVal.format("YYYY-MM-DD");
        step.complete = false;

        this.setState(step)
    }

    saveStep() {
        var errors = {};
        var error = false;
        var step = this.state.step;

        if (step.stepOptions.triggerType.value == "0") {
            if (step.stepOptions.workflowEmailGuid.value == "-1") {
                error = true;
                errors.workflowEmailGuid = i18n.t('automation:add.wait.errEmail')
            }
        }

        if (step.stepOptions.triggerType.value == "1") {
            if (step.stepOptions.customFieldId.value == "-1") {
                error = true;
                errors.customFieldId = i18n.t('automation:add.wait.errField')
            }
            if (((step.stepOptions.customFieldValue.value + "") == "" || step.stepOptions.customFieldValue.value == "-1") && step.stepOptions.customFieldAction.value != "is blank" && step.stepOptions.customFieldAction.value != "is not blank") {
                error = true;
                errors.customFieldValue = i18n.t('automation:add.wait.errValue')
            }
        }
        if (step.stepOptions.triggerType.value == "2") {
            if (step.stepOptions.segmentId.value == "-1") {
                error = true;
                errors.segmentId = i18n.t('automation:add.wait.errSegment')
            }
        }
        if (step.stepOptions.triggerType.value == "3") {
            if (step.stepOptions.campaignId.value == "-1") {
                error = true;
                errors.campaignId = i18n.t('automation:add.wait.errCampaign')
            }
        }
        if (step.stepOptions.triggerType.value == "7") {
            if (step.stepOptions.groupId.value == "-1") {
                error = true;
                errors.groupId = i18n.t('automation:add.wait.errGroup')
            }
            if (step.stepOptions.subscriberState.value == "") {
                error = true;
                errors.subscriberState = i18n.t('automation:add.wait.errSubscriberState')
            }
        }


        this.setState({ errors })

        if (!error || this.props.isThemeDesigner) {
            //finish
            step.complete = true;
            step.wizardComplete = true;
            this.props.saveStep()
        }

    }

    createSegment() {
        var drawerContent = <SegmentEditor history={this.props.history} close={this.createdSegment} />
        this.setState({ drawerContent }, () => {
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("100vw"));
        })
    }
    createdSegment(segmentData) {
        var segmentId = segmentData.data.Segment.segmentId;
        var step = this.state.step;
        step.stepOptions.segmentId.value = segmentId;
        step.complete = false;
        this.setState({ drawerContent: undefined, step }, () => {
            this.props.getSegments(false);
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("500px"));
        })
    }



    viewEditSegment() {
        var step = this.state.step;
        var segmentId = step.stepOptions.segmentId.value;
        var drawerContent = <SegmentEditor history={this.props.history} close={() => {
            this.setState({ drawerContent: undefined })
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("500px"));
        }} segmentId={segmentId} />
        this.setState({ drawerContent }, () => {
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("100vw"));
        })
    }

    getCampaignLinks() {
        var self = this;
        this.setState({ campaignLinks: [] })

        if (this.state.step.stepOptions.triggerType.value == "3" && this.state.step.stepOptions.campaignId.value) {
            var campaign = this.props.campaigns.find(cp => cp.value == this.state.step.stepOptions.campaignId.value)
            axios.get('/template/' + campaign.templateId + '/links').then(response => {
                var linkData = response.data.Links.map(l => { return { label: l, value: l } })
                this.setState({ campaignLinks: linkData })
            })
        }

        if (this.state.step.stepOptions.triggerType.value == "0" && this.state.step.stepOptions.workflowEmailGuid.value) {
            var workflowEmails = AutomationFunctions.getWorkflowEmails(this.props.step.guid, this.props.steps);
            if (workflowEmails) {
                var workflowEmail = workflowEmails.find(wfs => wfs.guid == this.state.step.stepOptions.workflowEmailGuid.value)
                if (workflowEmail) {
                    var campaign = workflowEmail.stepOptions.campaign;
                    axios.get('/template/' + campaign.TemplateId + '/links').then(response => {
                        var linkData = response.data.Links.map(l => { return { label: l, value: l } })
                        this.setState({ campaignLinks: linkData })
                    })
                }
            }
        }

    }

    render() {
        const { readOnly } = this.props;

        if (this.state.drawerContent) return this.state.drawerContent;

        var triggerTypes = [
            { label: i18n.t('automation:add.wait.trigger0'), value: "0" },
            { label: i18n.t('automation:add.wait.trigger1'), value: "1" },
            { label: i18n.t('automation:add.wait.trigger2'), value: "2" },
            { label: i18n.t('automation:add.wait.trigger3'), value: "3" },
            { label: i18n.t('automation:add.wait.trigger7'), value: "7" },
        ]


        var typeData = automationFunctions.delayTypes();
        typeData = typeData.filter(f => f.value.indexOf("next_") < 0);

        let apps = this.props.apps;
        let selectedApp;
        let appsWithTriggers = apps.filter(a => a.Application.options && a.Application.options.triggers && a.Application.options.triggers.length)
        if (appsWithTriggers.length) {
            appsWithTriggers.forEach(app => {
                if (app.Application.appName === this.state.step.stepOptions.triggerType.value) selectedApp = app
                triggerTypes.push({
                    label: app.Application.appName + ' Action',
                    value: app.Application.appName
                })
            })
        }

        var workflowEmails = AutomationFunctions.getWorkflowEmails(this.props.step.guid, this.props.steps);


        var emailOptions = [
            { label: i18n.t('automation:add.wait.email0'), value: "0" },
            { label: i18n.t('automation:add.wait.email1'), value: "1" },
            { label: i18n.t('automation:add.wait.email2'), value: "2" },
            { label: i18n.t('automation:add.wait.email3'), value: "3" },
            { label: i18n.t('automation:add.wait.email4'), value: "4" },
            { label: i18n.t('automation:add.wait.email5'), value: "5" }
        ]

        var customFieldActions = this.state.operators.TEXT;
        var customFieldInputType = "TEXT"
        var selectedCustomField;

        if (this.state.step.stepOptions.customFieldId.value && this.state.step.stepOptions.customFieldId.value != "-1") {
            selectedCustomField = this.props.customFields.find(fd => fd.value == this.state.step.stepOptions.customFieldId.value)
            if (selectedCustomField) {
                customFieldActions = this.state.operators[selectedCustomField.fieldType]
                customFieldInputType = selectedCustomField.fieldType;
            }
        }

        var currentSegmentIsUser = false;
        if (this.state.step.stepOptions && this.state.step.stepOptions.segmentId && this.state.step.stepOptions.segmentId.value && this.state.step.stepOptions.segmentId.value > 0) {

            var currSeg = this.props.segments.find(s => s.value == this.state.step.stepOptions.segmentId.value)
            if (currSeg && !currSeg.readOnly && !currSeg.CapsuleId) currentSegmentIsUser = true;
        }

        var tmpGroups = JSON.parse(JSON.stringify(this.props.groups))


        const inputProps = {
            disabled: readOnly
        };

        return <div data-test="drawer-wait-step">
            <div className="automationStepContentHolder">
                <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="mar-b25">{i18n.t('automation:add.wait.header')}</h4>
                    <HelpPopup type={"wait"} />
                </div>
                <div className="mar-b25">{i18n.t('automation:add.wait.subheader')}</div>


                <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.wait.triggerType')} name="triggerType" data={triggerTypes} onChange={this.updateValue} value={this.state.step.stepOptions.triggerType.value} error={this.state.errors.triggerType} {...inputProps} />


                {(this.state.step.stepOptions.triggerType.value == "0" && !workflowEmails.length) && <div>
                    {(this.state.errors.workflowEmailGuid && this.state.errors.workflowEmailGuid.length > 0) && <UILIB.WarningHolder className="mar-b25">{i18n.t('automation:add.wait.pleaseAddCampaign')}</UILIB.WarningHolder>}
                    <UILIB.WarningHolder className="mar-b25">
                        {i18n.t('automation:add.wait.noEmails')}
                    </UILIB.WarningHolder>
                </div>}
                {(this.state.step.stepOptions.triggerType.value == "0" && workflowEmails.length > 0) && <div>

                    <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.wait.triggerEmail')} name="workflowEmailGuid" placeholder={i18n.t('automation:add.wait.selectWorkflow')} value={this.state.step.stepOptions.workflowEmailGuid.value} data={workflowEmails.map(wfe => { return { label: wfe.stepOptions.subject.value, value: wfe.guid } })} onChange={this.updateValue} error={this.state.errors.workflowEmailGuid} {...inputProps} />

                    {this.state.step.stepOptions.workflowEmailGuid.value != "-1" && <div>
                        <UILIB.Select outerClassName="mar-b25" data={emailOptions} name="workflowEmailActionType" onChange={this.updateValue} value={this.state.step.stepOptions.workflowEmailActionType.value} {...inputProps} />
                    </div>}

                    {this.state.step.stepOptions.workflowEmailGuid.value != "-1" && (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5") && <div>
                        {!this.state.campaignLinks.length && <div></div>}
                        {this.state.campaignLinks.length > 0 && <div>
                            <UILIB.Select outerClassName="mar-b25" name="linkClicked" placeholder={i18n.t('automation:add.wait.selectLink')} data={this.state.campaignLinks} onChange={this.updateValue} value={this.state.step.stepOptions.linkClicked.value} error={this.state.errors.linkClicked} {...inputProps} />
                        </div>}
                    </div>}
                </div>}

                {this.state.step.stepOptions.triggerType.value == "1" && !this.props.isThemeDesigner && <div>
                    <div>
                        <UILIB.Select outerClassName="mar-b25" placeholder={i18n.t('automation:add.wait.selectField')} data={this.props.customFields} name="customFieldId" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldId.value} error={this.state.errors.customFieldId} {...inputProps} />
                    </div>
                    {this.state.step.stepOptions.customFieldId.value != "-1" && <div>
                        <div>
                            <UILIB.Select outerClassName="mar-b25" data={customFieldActions} name="customFieldAction" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldAction.value} error={this.state.errors.customFieldAction} {...inputProps} />
                        </div>
                        {this.state.step.stepOptions.customFieldAction.value != "is blank" && this.state.step.stepOptions.customFieldAction.value != "is not blank" && <div>

                            {customFieldInputType == "TEXT" && <UILIB.TextInput outerClassName="mar-b25" type="text" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {customFieldInputType == "NUMBER" || (customFieldInputType == "DATE" && this.state.step.stepOptions.customFieldAction.value.indexOf("be") > -1) && <UILIB.TextInput outerClassName="mar-b25" type="number" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} />}
                            {customFieldInputType == "DATE" && (this.state.step.stepOptions.customFieldAction.value.indexOf("be") < 0) && <UILIB.TextInput outerClassName="mar-b25" type="date" name="customFieldValue" onChange={(event, newVal) => { this.updateDate("customFieldValue", newVal) }} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {customFieldInputType == "BOOLEAN" && <UILIB.Select outerClassName="mar-b25" name="customFieldValue" placeholder={i18n.t('automation:add.wait.selectValue')} data={[{ 'label': i18n.t('true'), 'value': true }, { 'label': i18n.t('false'), 'value': false }]} onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {(customFieldInputType == "SELECT" || customFieldInputType == 'MULTISELECT') && <UILIB.Select outerClassName="mar-b25" name="customFieldValue" placeholder={i18n.t('automation:add.wait.selectValue')} data={selectedCustomField.options ? selectedCustomField.options : []} onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {customFieldInputType == "TELNO" && <UILIB.TextInput outerClassName="mar-b25" type="TELNO" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}

                        </div>}

                    </div>
                    }
                </div>}


                {this.state.step.stepOptions.triggerType.value == "2" && !this.props.isThemeDesigner && <div>
                    {!this.props.segments.length &&
                        <UILIB.WarningHolder className="mar-b25">
                            <div><strong>{i18n.t('automation:add.wait.noSegments')}</strong></div>
                            <button className="a" onClick={this.createSegment} {...inputProps}>{i18n.t('automation:add.wait.pleaseCreateSegment')}</button>
                        </UILIB.WarningHolder>}
                    {this.props.segments.length > 0 &&
                        <div>
                            <div>
                                <UILIB.Select
                                    label={i18n.t('automation:add.wait.selectSegment')}
                                    labelOptions={<button className="a" onClick={this.createSegment} {...inputProps}>{i18n.t('automation:add.wait.createSegment')}</button>}
                                    placeholder={i18n.t('automation:add.wait.selectSegment')}
                                    name="segmentId"
                                    value={this.state.step.stepOptions.segmentId.value}
                                    data={this.props.segments}
                                    onChange={this.updateValue}
                                    error={this.state.errors.segmentId}
                                    {...inputProps}
                                />
                                {(currentSegmentIsUser == true) && <div className="mar-t10"><button className="a" onClick={this.viewEditSegment} {...inputProps}>{i18n.t('automation:add.wait.editSegment')}</button></div>}
                            </div>
                        </div>}
                </div>
                }


                {this.state.step.stepOptions.triggerType.value == "3" && !this.props.isThemeDesigner && <div>
                    {!this.props.campaigns.length &&
                        <UILIB.WarningHolder>
                            <div><strong>{i18n.t('automation:add.wait.noCampaigns')}</strong></div>
                        </UILIB.WarningHolder>}
                    {this.props.campaigns.length > 0 &&
                        <div>
                            <CampaignSelector
                                label={i18n.t('automation:add.wait.selectCampaign')}
                                outerClassName="mar-b25"
                                placeholder={i18n.t('automation:add.wait.selectCampaign')}
                                name="campaignId"
                                value={this.state.step.stepOptions.campaignId.value}
                                allowAnyCampaign={""}
                                onChange={this.updateValue}
                                error={this.state.errors.campaignId} {...inputProps}
                            />

                            {this.state.step.stepOptions.campaignId.value != "-1" && <div>
                                <UILIB.Select outerClassName="mar-b25" data={emailOptions} name="workflowEmailActionType" onChange={this.updateValue} value={this.state.step.stepOptions.workflowEmailActionType.value} {...inputProps} />
                            </div>}
                            {this.state.step.stepOptions.campaignId.value != "-1" && (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5") && <div>
                                <UILIB.TextInput label={i18n.t('automation:add.initial.link')} outerClassName="mar-b25" placeholder={i18n.t('automation:add.initial.enterLink')} name="linkClicked" value={this.state.step.stepOptions.linkClicked.value} onChange={this.updateValue} error={this.state.errors.link} {...inputProps} />
                            </div>}
                        </div>}
                </div>
                }

                {this.state.step.stepOptions.triggerType.value == "7" && !this.props.isThemeDesigner && <div>
                    {!tmpGroups && <UILIB.WarningHolder>
                        <div><strong>{i18n.t('automation:add.initial.noGroups')}</strong></div>
                    </UILIB.WarningHolder>}

                    {tmpGroups.length > 0 && <div>
                        <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.initial.selectGroup')} placeholder={i18n.t('automation:add.initial.selectGroupPlaceholder')} name="groupId" value={this.state.step.stepOptions.groupId.value} data={tmpGroups} onChange={this.updateValue} error={this.state.errors.groupId} {...inputProps} />

                        {this.state.step.stepOptions.groupId.value && <div>
                            <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.initial.selectState')} placeholder={i18n.t('automation:add.initial.selectStatePlaceholder')} name="subscriberState" value={this.state.step.stepOptions.subscriberState ? this.state.step.stepOptions.subscriberState.value : ''} data={[{ label: i18n.t('automation:add.initial.active'), value: "active" }, { label: i18n.t('automation:add.initial.inactive'), value: "inactive" }]} onChange={this.updateValue} error={this.state.errors.subscriberState} {...inputProps} />
                        </div>}
                    </div>}
                </div>}

                {!!selectedApp && <div>
                    <UILIB.Select outerClassName="mar-b25" label="Action" placeholder='Select Action' name="trigger" value={this.state.step.stepOptions.trigger.value} data={selectedApp.Application.options.triggers} onChange={this.updateValue} error={this.state.errors.trigger} {...inputProps} />
                    {!!this.state.step.stepOptions.trigger.value && <div>
                        <TriggerFilters filters={this.state.step.stepOptions.filters} wait={true} trigger={this.state.step.stepOptions.trigger.value} appId={selectedApp.ApplicationId} />
                    </div>}
                </div>}


                <div>
                    <UILIB.Select
                        outerClassName="mar-b25"
                        label="Timeout"
                        placeholder='Select Timeout'
                        name="timeout"
                        value={this.state.step.stepOptions.timeout ? this.state.step.stepOptions.timeout.value : "0"}
                        onChange={this.updateValue} error={this.state.errors.timeout}
                        data={[{ label: "No Timeout", value: "0" }, { label: "Wait until conditions are met or wait until... ", value: "1" }]}
                        {...inputProps}
                    />
                </div>
                {this.state.step.stepOptions.timeout && this.state.step.stepOptions.timeout.value && this.state.step.stepOptions.timeout.value != "0" && <div>

                    <div className="quickFlex">
                        <UILIB.TextInput
                            outerClassName="mar-r10 mar-b10"
                            outerStyle={{ flex: "1" }}
                            name="delay_value"
                            onChange={this.updateValue}
                            value={this.state.step.stepOptions.delay_value.value}
                            error={this.state.errors.delay_value}
                            type="number"
                            {...inputProps}
                        />
                        <UILIB.Select
                            name="delay_unit"
                            outerClassName="mar-b10"
                            outerStyle={{ flex: "1" }}
                            data={typeData}
                            onChange={this.updateValue}
                            value={this.state.step.stepOptions.delay_unit.value}
                            error={this.state.errors.delay_unit}
                            {...inputProps}
                        />
                    </div>
                    have passed, and then allow contacts to continue through the automation.
                </div>}

            </div>
            <div className="automationStepSaveButtonHolder">
                {readOnly ? (
                    <>
                        <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                    </>
                ) : (
                    <UILIB.Button className="button-primary" text={i18n.t('save')} onClick={this.saveStep} />
                )}
            </div>

        </div >
    }
}

export default DrawerWaitStep;
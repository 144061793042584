import React, { Component } from 'react';
import * as Popover from '@radix-ui/react-popover';

import './styles.css';

export default function PopOver({
  trigger,
  sideOffset = 0,
  side = "left",
  align = "top",
  alignOffset = 0,
  avoidCollisions = true,
  defaultOpen = undefined,
  children,
  close,
  forceAnchor = false,
  open = undefined,
  style = {},
  onOpenChange = () => { },
  onOpenAutoFocus = () => { },
  ...rest }) {

  return <Popover.Root defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
    {!forceAnchor && <Popover.Trigger style={style}>
      {trigger}
    </Popover.Trigger >}
    {forceAnchor && <Popover.Anchor asChild={true}></Popover.Anchor>}
    <Popover.Portal >
      <Popover.Content className="PopoverContent"
        sideOffset={sideOffset}
        side={side}
        align={align}
        alignOffset={alignOffset}
        avoidCollisions={avoidCollisions}
        onOpenAutoFocus={onOpenAutoFocus}
      >

        {children}

        {!!close && <Popover.Close className="PopoverClose" aria-label="Close">
          {close}
        </Popover.Close>}
        <Popover.Arrow className="PopoverArrow" />
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>

}
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import UILIB from '../../../../Common-Objects/Lib'
import { alterSiteDrawer } from '../../../../data/actions/siteActions'

export default function ChangePanel({ index, current = ['opens', 'newcontacts', 'billing'], onSave = () => { } }) {
    const dispatch = useDispatch()
    const [panels, setPanels] = useState(current)

    const update = e => {
        const arr = [...panels]
        arr[index] = e.currentTarget.value
        setPanels(arr)
    }

    const save = () => {
        onSave(panels)
        dispatch(alterSiteDrawer(false, true, 'right', null, true))
    }

    const data = [{
        label: 'Contact Opens',
        value: 'opens',
    }, {
        label: 'Contact Clicks',
        value: 'clicks',
    }, {
        label: 'New Contacts',
        value: 'newcontacts',
    }, {
        label: 'Automations',
        value: 'automations',
    }, {
        label: 'Site Visits',
        value: 'sitevisits',
    }]

    return (
        <div>
            <h4 className='mar-b25'>Change Dashboard Panel</h4>
            <UILIB.Select outerClassName="mar-b25" data={data} label="Select Panel" value={panels[index]} onChange={update} />
            <UILIB.Button className="button-primary" text="Save" onClick={save} />
        </div>
    )
}
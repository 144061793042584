import React from "react";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";
import DateTimeFunctions from "~/Classes/dateTimeFunctions";
import siteVars from "~/Classes/siteVars";
import ReactTooltip from 'react-tooltip';
export default function ChatTeaser({
  sent,
  received,
  active,
  className,
  conversation,
  AccountUser,
  AccountUserId,
  ...rest
}) {
  const classes = classnames(styles.root, className, {
    [styles.rootActive]: active,
  });
  let lastMessageDate = DateTimeFunctions.formatDateTime(conversation.lastMessageDate, "5");
  let image = conversation.Subscriber?.subscriberImage
  if (image && image.indexOf("blankAvatar.png") > -1) image = null

  let lastMessageStyle = "text-overflow ";
  if (conversation.lastMessageUserType) {
    lastMessageStyle += styles.lastMessageAgent;
  }
  else {
    lastMessageStyle += styles.lastMessageVisitor;
  }

  const subscriberEmailAddress = conversation?.Subscriber?.emailAddress || "Contact Deleted";
  const lastMessage = conversation.lastMessage;
  return (
    <button type="button" className={classes} {...rest}>
      <UILIB.AvatarNew
        size={32}
        className={styles.avatar}
        name={subscriberEmailAddress}
        src={image}
        status={conversation.Subscriber?.chatOnline ? "online" : "offline"}
      />

      <div className={styles.content}>
        <div className={styles.title}>
          <h3 className={classnames(styles.heading, "text-overflow")}>
            {subscriberEmailAddress}
          </h3>

          <time dateTime={lastMessageDate} className={classnames(styles.date)} data-for={'chatTeaserDate' + conversation.id} data-tip>
            <ReactTooltip effect='solid' className="tooltip" id={'chatTeaserDate' + conversation.id} >{DateTimeFunctions.formatDateTime(conversation.lastMessageDate)}</ReactTooltip>
            {lastMessageDate}
          </time>
        </div>

        <div className={styles.meta}>
          <span className={classnames(styles.lastMessage, lastMessageStyle)}>
            {lastMessage}
          </span>
          {!!AccountUser && <span><UILIB.Avatar tooltip={AccountUser.fullname} style={{ height: 16, width: 16 }} src={siteVars.repoEndPoint + AccountUser.profilePicFileName} /></span>}

          {(conversation.agentUnreadChatLines > 0) && <UILIB.AvatarNew size={16} name={conversation.agentUnreadChatLines + ""} />}
          {conversation.starred && <UILIB.Icon name="star" style={{ width: 10, height: 10 }} />}
        </div>

      </div>
    </button>
  );
}

import React from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import IncGoDeleteTemplate from './incGoDeleteTemplate.jsx';
import IncGoChangeGroup from './incGoChangeGroup';
import IncGoEditGroup from './incGoEditGroup';
import IncGoInviteUsers from './inGoInviteUsers';
import InfiniteScroll from 'react-infinite-scroller';
import copy from 'copy-to-clipboard';
import i18n from '~/i18n'
import PermissionChecker from '~/Classes/permissions';
import EmailPreviewer from '~/pages/cp/includes/emailPreviewer/emailPreviewer'
import SpamTester from '~/pages/cp/includes/spamTester/spamTester'
import FileManager from '~/pages/cp/includes/fileManager/fileManager'
import Template from './template'
import TableView from './tableView'
import BrandAnaylzerComponent from '../includes/brandanalyzer';
import axios2 from 'axios'

import SendAsCampaign from './sendAsCampaign';
import ShareTemplate from './shareTemplate';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class TemplatesTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            groups: [],
            loading: false,
            groupId: undefined,
            groupName: i18n.t('templates:index.groupPlaceholder'),
            loadingData: 0,
            sortColumn: "createdAt",
            sortName: i18n.t('templates:index.sortCreatedLabel'),
            sortDirection: "desc",
            pageSize: 20,
            currentPage: 0,
            searchText: "",
            startRecord: 0,
            totalTemplates: 0,
            hasMoreItems: false,
            alertOpen: false,
            alertMessage: "",
            now: Date.now(),
            doneFirstLoad: false,
            tabType: "Grid View",
            firstLoadTemplateCount: 0
        };

        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();

        this.getTemplates = this.getTemplates.bind(this);
        this.addNewTemplate = this.addNewTemplate.bind(this);
        this.goEditTemplate = this.goEditTemplate.bind(this);
        this.goDeleteTemplate = this.goDeleteTemplate.bind(this);
        this.changeSearchText = this.changeSearchText.bind(this);
        this.goSearchText = this.goSearchText.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.setSort = this.setSort.bind(this);
        this.setDirection = this.setDirection.bind(this);
        this.showCode = this.showCode.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.goEditGroup = this.goEditGroup.bind(this);
        this.deletedGroup = this.deletedGroup.bind(this);
        this.inviteUsers = this.inviteUsers.bind(this);
        this.openEmailPreview = this.openEmailPreview.bind(this);
        this.openFileManager = this.openFileManager.bind(this);
        this.duplicate = this.duplicate.bind(this)
        this.goChangeGroup = this.goChangeGroup.bind(this);
        this.openSpamTest = this.openSpamTest.bind(this);
        this.changeView = this.changeView.bind(this);
        this.changePage = this.changePage.bind(this);
        this.togglePublic = this.togglePublic.bind(this);
        this.restoreDeleted = this.restoreDeleted.bind(this);
        this.exportPdf = this.exportPdf.bind(this);
        this.sendAsCampaign = this.sendAsCampaign.bind(this);
        this.shareTemplate = this.shareTemplate.bind(this);
    }

    componentDidMount() {
        this.getGroups();
    }

    componentWillUnmount() {
        this.source.cancel('I cancelled this')
        this.source = this.CancelToken.source();
    }

    getGroups() {
        this.setState({ loading: true })
        axios.get('/templateGroup', {
            cancelToken: this.source.token
        }).then(response => {
            var templateGroups = response.data.TemplateGroups;
            templateGroups.forEach(tG => {
                if (tG.id == "-999") {
                    tG.groupName = i18n.t(tG.groupName)
                }
            })
            this.setState({
                groups: templateGroups
            }, () => this.getTemplates(true))
        }).catch(() => { })
    }

    changeGroup(group, reloadTemplates) {
        this.setState({
            groupId: group ? group.id : undefined,
            groupName: group ? group.groupName + '(' + group.templateCount + ')' : i18n.t('templates:index.groupPlaceholder')
        }, () => this.getTemplates(true))
    }

    loadMore(page) {
        var loading = this.state.loadingData;
        if (!loading) {
            this.getTemplates();
        }
    }

    showCode(id) {
        var templates = this.state.templates;
        var template = templates.find(t => t.id == id)
        template.showCode = true;
        this.setState({
            templates
        })
    }

    copyToClipboard(code) {
        this.setState({
            alertOpen: true,
            alertMessage: 'Code copied to clipboard'
        })
        copy(code)
    }

    setSort(option, name) {
        this.setState({
            sortColumn: option,
            sortName: name
        }, () => this.getTemplates(true))
    }

    setDirection(option) {
        this.setState({
            sortDirection: option
        }, () => this.getTemplates(true))
    }

    //GROUP NAME SEARCH STUFF
    changeSearchText(event) {
        this.setState({
            searchText: event.target.value,
            page: 0
        }, () => {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(this.goSearchText, 1000)
        })
    }
    goSearchText() {
        this.getTemplates(true);
    }

    goEditTemplate(templateID, templateType) {

        var theURL = "/cp/templates/add/fromCode/";

        if (templateType === 0) {
            theURL = "/cp/templates/add/dragDrop/";
        }
        if (templateType === 1) {
            theURL = "/cp/templates/add/fromCode/";
        }
        if (templateType === 2) {
            theURL = "/cp/templates/add/wysiwyg/";
        }
        this
            .props
            .history
            .push(theURL + templateID);
    }

    goDeleteTemplate(templateID) {
        var theDrawer = <IncGoDeleteTemplate templateId={templateID} tableDataGetter={this.getGroups} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true));
    }

    goChangeGroup(template) {

        var theDrawer = <IncGoChangeGroup templateId={template.id} groupId={template.TemplateGroupId} groups={this.state.groups} tableDataGetter={this.getGroups} templateName={template.templateName} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true));
    }

    goEditGroup() {
        var that = this;
        var theGroup = this.state.groups.find(group => group.id === this.state.groupId);
        var theDrawer = <IncGoEditGroup groupId={this.state.groupId} tableDataGetter={(newGroup) => { this.changeGroup(newGroup, true) }} deleteGroup={this.deletedGroup} group={theGroup} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true));
    }

    deletedGroup() {
        var self = this;
        axios.get('/templateGroup').then(response => {
            self.setState({
                groups: response.data.TemplateGroups,
                groupId: undefined,
                groupName: "Filter by Template Group"
            }, () => self.getTemplates(true))
        }).catch(() => { })
    }

    inviteUsers(template) {
        var theDrawer = <IncGoInviteUsers templateId={template.id} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '100vw'));
    }

    getTemplates(reset) {
        this.setState({ loadingData: 1, hasMoreItems: false });
        var self = this;
        var startRecord = reset ? 0 : this.state.templates.length;
        if (this.state.currentPage) {
            reset = true;
            startRecord = this.state.currentPage * this.state.pageSize;
        }

        var queryStr = `?startRecord=${startRecord}&recordLimit=${this.state.pageSize}&sortOrder=${this.state.sortDirection}&searchText=${this.state.searchText}&sortColumn=${this.state.sortColumn}&detail=basic`
        if (this.state.groupId) {
            queryStr += '&groupID=' + this.state.groupId;
        }
        axios.get('/template' + queryStr, {
            cancelToken: this.source.token
        }).then((res) => {
            var templates = reset ? res.data.Templates : self.state.templates;
            let firstLoadTemplateCount = this.state.firstLoadTemplateCount;
            if (!this.state.doneFirstLoad) {
                firstLoadTemplateCount = templates.length;
            }
            if (!reset) {
                templates = templates.concat(res.data.Templates);
            }
            self.setState({
                templates,
                countTotal: res.data.countTotal,
                hasMoreItems: !!(res.data.countTotal > templates.length),
                loadingData: 0,
                loading: false,
                doneFirstLoad: true,
                firstLoadTemplateCount
            });
        }).catch(() => { })
    }

    addNewTemplate() {
        this
            .props
            .history
            .push('/cp/templates/add');
    }

    openEmailPreview(templateId) {
        var drawerContent = <EmailPreviewer templateId={templateId} history={this.props.history} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
    }

    openSpamTest(templateId) {
        var drawerContent = <SpamTester templateId={templateId} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
    }

    openFileManager() {
        var theDrawer = <FileManager fileTypes={"all"} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '100vw', 'fix-height'));
    }

    duplicate(template) {

        var theDrawer = <UILIB.DrawerConfirm header={i18n.t('templates:duplicate.templates')} inputText={i18n.t('templates:duplicate.templateName')} input={true} value={template.templateName + ' - copy'} confirmText={i18n.t('templates:duplicate.duplicate')} confirm={(name) => {
            axios.post('/template/copy', {
                id: template.id,
                name
            }).then(this.getTemplates)
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true));
    }

    exportHtml(template) {
        axios
            .get('/template/' + template.id + '/exporthtml')
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'templateHtml_' + template.id + '.html');
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
            });
    }

    exportPdf(template) {
        axios.get('/template/' + template.id + '/exportpdf',
            {
                responseType: 'blob'
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'template_' + template.id + '.pdf');
                document.body.appendChild(link);
                link.click();
            })

    }

    changeView(type) {
        this.setState({ currentPage: 0, tabType: type }, () => { this.getTemplates(true) })
    }

    changePage(pageNo) {
        this.setState({ currentPage: pageNo }, () => { this.getTemplates(true) })
    }

    togglePublic(template) {
        axios.put('/template/' + template.id, {
            public: !template.public
        }).then(this.getTemplates)
    }

    restoreDeleted(template) {
        axios.put('/template/' + template + '/restore').then(this.getGroups)
    }

    sendAsCampaign(templateId) {
        let drawer = <SendAsCampaign history={this.props.history} templateId={templateId} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, true, "500px"));
    }

    shareTemplate(template) {
        let drawer = <ShareTemplate template={template} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, true, "500px"));
    }

    render() {
        if (!this.state.doneFirstLoad) return <div><UILIB.LoadingIcons iconType="2" /></div>
        const loader = <UILIB.LoadingLine key={0}></UILIB.LoadingLine>

        var sortOptions = [<a key="sort1" onClick={() => this.setSort('createdAt', i18n.t('templates:index.sortCreatedLabel'))}>{i18n.t('templates:index.sortCreated')}</a>,
        <a key="sort2" onClick={() => this.setSort('updatedAt', i18n.t('templates:index.sortUpdatedLabel'))}>{i18n.t('templates:index.sortUpdated')}</a>,
        <a key="sort3" onClick={() => this.setSort('templateName', i18n.t('templates:index.sortNameLabel'))}>{i18n.t('templates:index.sortName')}</a>];

        var directionOptions = [<a key="order1" onClick={() => this.setDirection('asc')}>{i18n.t('templates:index.asc')}</a>,
        <a key="order2" onClick={() => this.setDirection('desc')}>{i18n.t('templates:index.desc')}</a>];

        var sortArray =  [
            {
                value: "createdAt",                
                label: i18n.t('templates:index.sortCreatedLabel')
            },
            {
                value: "updatedAt",                
                label: i18n.t('templates:index.sortUpdatedLabel')
            },
            {
                value: "templateName",                
                label: i18n.t('templates:index.sortNameLabel')
            }
        ]

        var sortDirectionArray = [
            {
                value: "asc",                
                label: i18n.t('templates:index.asc')
            },
            {
                value: "desc",                
                label: i18n.t('templates:index.desc')
            }
        ]

        return <UILIB.Paper>

            <div className="mar-b25 quickFlex">
                <div className='page-header'>{i18n.t('templates:index.templates')}</div>
                {this.props.siteMaster.siteId === 1 && <UILIB.Hint iconLeft={<UILIB.Icons icon="questionCircle" />} href="https://kb.transpond.io/article/136-templates">Learn about Templates</UILIB.Hint>}
            </div>

            <UILIB.Row>
                <UILIB.Column lg={6} md={6} sm={6} xs={12} className="mar-b25">
                    <UILIB.TextInput
                        placeholder={i18n.t('templates:index.searchPlaceholder')}
                        onChange={this.changeSearchText}
                        onEnterPress={this.goSearchText}
                        iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16, marginTop: "1px" }} color="#2B2F41" onClick={() => { }} />}
                    />
                </UILIB.Column>
                <UILIB.Column lg={6} md={6} sm={6} xs={12} style={{ textAlign: "right" }} className="mar-b25">

                    <UILIB.Button
                        style={{ whiteSpace: "nowrap" }}
                        className="mar-l10"
                        iconLeft={<UILIB.Icons icon="image" />}
                        text={i18n.t('templates:index.fileManager')}
                        onClick={() => this.openFileManager()}
                    />

                    {PermissionChecker("templates", this.props.permissionStore.permissions, "write") &&
                        <UILIB.Button
                            style={{ whiteSpace: "nowrap" }}
                            className="button-primary mar-l10"
                            iconLeft={<UILIB.Icons icon="plus" />} t
                            text={i18n.t('templates:index.addTemplate')}
                            onClick={() => this.addNewTemplate()}
                        />
                    }
                </UILIB.Column>
            </UILIB.Row>



            <div className="templateHolder">

                <UILIB.Row className="hide-xs">
                    <UILIB.Column sm={10} xs={12} margin={0} className="quickFlex mar-b25">
                        {(() => {
                            if (this.state.groups.length) {
                                var groupsData = [<a key={'group' + -1} onClick={() => this.changeGroup(null)}>{i18n.t('templates:index.all')}</a>].concat(this.state.groups.map((group, index) => {
                                    return <a key={'group' + index} onClick={() => this.changeGroup(group)}>{group.groupName + ' (' + group.templateCount + ')'}</a>
                                }))
                                // return <UILIB.ButtonSelect className="mar-r5" headerText={this.state.groupName} autoClose data={groupsData} />

                                return <UILIB.Select
                                    outerClassName="mar-r5"
                                    inlineLabel="Folder"
                                    disabled={false}
                                    value={this.state.groupId}
                                    placeholderNoSelect="All"
                                    autoWidth={true}
                                    onChange={(e) => {
                                        const foundGroup = this.state.groups.find(g => g.id === e.currentTarget.value); 
                                        this.changeGroup(foundGroup)
                                    }}
                                    fixedHeightLarge={true}
                                    data={this.state.groups.map( g => {
                                        return {
                                            value: g.id,
                                            label: g.groupName + ' (' + g.templateCount + ')'
                                        }
                                    })}
                                />
                            }       
                        })()}
                        {!!this.state.groupId && this.state.groupId != -999 &&
                            <UILIB.Button iconRight={<UILIB.Icons icon="pencil" />} className="mar-r5" onClick={this.goEditGroup}>{i18n.t('templates:index.editGroup')}</UILIB.Button>
                        }
                        {/* <UILIB.ButtonSelect className="mar-r5" headerText={this.state.sortName} autoClose data={sortOptions} /> */}
                                                
                        <UILIB.Select
                            outerClassName="mar-r5"
                            inlineLabel="Sort"
                            disabled={false}
                            value={this.state.sortColumn}
                            placeholderNoSelect={this.state.sortName}
                            autoWidth={true}
                            onChange={(e) => {
                                const sortObject = sortArray.find(s => s.value === e.currentTarget.value)
                                this.setSort(sortObject.value, sortObject.label)                                
                            }}
                            fixedHeightLarge={true}
                            data={sortArray}
                        />

                        {/* <UILIB.ButtonSelect className="mar-r5" headerText={i18n.t('templates:index.' + this.state.sortDirection)} autoClose data={directionOptions} /> */}

                        <UILIB.Select
                            outerClassName="mar-r5"
                            inlineLabel="Direction"
                            disabled={false}
                            value={this.state.sortDirection}
                            placeholderNoSelect={i18n.t('templates:index.' + this.state.sortDirection)}
                            autoWidth={true}
                            onChange={(e) => {
                                const sortObject = sortDirectionArray.find(s => s.value === e.currentTarget.value)
                                this.setDirection(sortObject.value)                                
                            }}
                            fixedHeightLarge={true}
                            data={sortDirectionArray}
                        />

                    </UILIB.Column>
                    <UILIB.Column xs="12" sm="2" className="text-right mar-b25" hide={["xs"]}>
                        <UILIB.ButtonSimple className="button-simple-fullsize " selected={this.state.tabType == "Grid View"} onClick={() => { this.changeView('Grid View') }} iconLeft={<UILIB.Icons icon="grid" />} />
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.tabType == "List View"} onClick={() => { this.changeView('List View') }} iconLeft={<UILIB.Icons icon="list" />} />
                    </UILIB.Column>
                </UILIB.Row>


                {this.state.tabType === "Grid View" &&
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadMore}
                        hasMore={this.state.hasMoreItems}
                        loader={loader}>
                        <UILIB.Row>
                            {/* no templates */}
                            {this.state.loadingData == 0 && this.state.firstLoadTemplateCount < 1 &&
                                <UILIB.Paper>
                                    <UILIB.Row>
                                        <UILIB.Column xs={12} sm={6} md={4}>
                                            <img
                                                src="https://cdn1.ourmailsender.com/siteContent/noneholders/template-none.png"
                                                style={{ width: "100%" }} />
                                        </UILIB.Column>
                                        <UILIB.Column xs={12} sm={6} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                            <UILIB.Icons icon="template" circle={true} style={{ height: 40, width: 40 }} className="mar-b15" />
                                            <h4 className="mar-b15">{i18n.t('templates:index.noTemplatesHeader')}</h4>
                                            <div>{i18n.t('templates:index.noTeplatesDescription')}</div>

                                            {PermissionChecker('templates', this.props.permissionStore.permissions, "write") && this.state.groupId != -999 && <div className="mar-t25">
                                                <UILIB.Button
                                                    className="button-primary"
                                                    onClick={() => this.addNewTemplate()}
                                                    text={i18n.t('templates:index.addTemplate')}
                                                    iconLeft={<UILIB.Icons icon="plus" />}
                                                />
                                            </div>
                                            }
                                        </UILIB.Column>
                                    </UILIB.Row>
                                </UILIB.Paper>
                            }
                            {this.state.loadingData == 0 && this.state.templates.length < 1 && this.state.firstLoadTemplateCount > 0 && <UILIB.Column xs={12} hide={["xs", "sm"]} className="text-center">
                                <div style={{ marginBottom: "15px" }}>{i18n.t('templates:index.noTemplates')}</div>
                                {PermissionChecker('templates', this.props.permissionStore.permissions, "write") && this.state.groupId != -999 &&
                                    <UILIB.Button
                                        style={{ whiteSpace: "nowrap" }}
                                        className="button-primary mar-l10"
                                        iconLeft={<UILIB.Icons icon="plus" />} t
                                        text={i18n.t('templates:index.addTemplate')}
                                        onClick={() => this.addNewTemplate()}
                                    />}
                            </UILIB.Column>
                            }
                            {/* has templates */}
                            {this.state.templates.map((template, index) => {
                                return <Template key={"templateK" + template.id} index={index} template={template} groups={this.state.groups} getGroups={this.getGroups}
                                    goEditTemplate={this.goEditTemplate} openEmailPreview={this.openEmailPreview} openSpamTest={this.openSpamTest} goChangeGroup={this.goChangeGroup}
                                    inviteUsers={this.inviteUsers} duplicate={this.duplicate} goDeleteTemplate={this.goDeleteTemplate} exportHtml={this.exportHtml} exportPdf={this.exportPdf}
                                    togglePublic={this.togglePublic} now={this.state.now} deleted={this.state.groupId == -999 ? true : false} restoreDeleted={this.restoreDeleted} history={this.props.history}
                                    sendAsCampaign={this.sendAsCampaign} share={this.shareTemplate}
                                />
                            })}
                        </UILIB.Row>
                    </InfiniteScroll>
                }
                {this.state.tabType === "List View" &&
                    <TableView history={this.props.history} togglePublic={this.togglePublic} templates={this.state.templates}
                        goEditTemplate={this.goEditTemplate} changePage={this.changePage} totalTemplates={this.state.countTotal}
                        currentPage={this.state.currentPage} pageSize={this.state.pageSize} groupId={this.state.groupId} getGroups={this.getGroups}
                        openEmailPreview={this.openEmailPreview} openSpamTest={this.openSpamTest} goChangeGroup={this.goChangeGroup}
                        inviteUsers={this.inviteUsers} duplicate={this.duplicate} goDeleteTemplate={this.goDeleteTemplate}
                        exportHtml={this.exportHtml}
                        exportPdf={this.exportPdf}
                        share={this.shareTemplate}
                        sendAsCampaign={this.sendAsCampaign}

                    />

                }
                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
            </div>
        </UILIB.Paper>
    };
};

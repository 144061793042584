import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import textFunctions from '~/Classes/textFunctions';
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class addDomainAdromPop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: "",
            inputErrors: '',
            domainData: {},
            dataLoaded: false,
            alertMessage: "",
            alertOpen: false
        };

        this.loadAdromDomain = this.loadAdromDomain.bind(this);
    }

    componentDidMount() {
        this.loadAdromDomain();
    }

    loadAdromDomain() {
        if (this.state.dataLoaded) {
            this.setState({ alertMessage: <div style={{ marginBottom: "25px" }}>{i18n.t('company:domains.adromAuth.checking')}</div> })
        }
        var self = this;
        axios.post('/accountMaster/domain/adrom/getdnsconfig', { domainName: this.props.domain.domainName }).then((resp) => {

            var passed = true;
            if (resp.data.dkimConfig.status == "Error") passed = false;
            if (resp.data.spfConfig.status == "Error") passed = false;
            if (resp.data.dmarcConfig.status == "Error") passed = false;
            if (resp.data.mxConfig.status == "Error") passed = false;

            if (passed) {
                self.props.onAuthed();
            }
            else {
                self.setState({ domainData: resp.data, dataLoaded: true, domainName: this.props.domain.domainName, alertMessage: "" })
            }
        })
    }

    render() {

        var dkimClass = "secondary";
        var spfClass = "secondary";
        var mxClass = "secondary";
        var dmarcClass = "secondary";

        if (!this.state.dataLoaded) {
            return <div>Loading</div>
        }

        if (this.state.domainData.dkimConfig.status != "Error") dkimClass = "paper-green";
        if (this.state.domainData.spfConfig.status != "Error") spfClass = "paper-green";
        if (this.state.domainData.dmarcConfig.status != "Error") dmarcClass = "paper-green";
        if (this.state.domainData.mxConfig.status != "Error") mxClass = "paper-green";

        return <div>
            <h4 className="mar-b25">{i18n.t('company:domains.authHeader')}</h4>
            <div className="mar-b25">{i18n.t('company:domains.adromAuth.header')}</div>

            <UILIB.Paper className={dkimClass}>
                <h4 className="mar-b25">DKIM</h4>
                <UILIB.TextInput label={
                    <div dangerouslySetInnerHTML={{ __html: i18n.t('company:domains.adromAuth.dkim', { sudomain: textFunctions.checkForHtml(this.state.domainData.dkimConfig.subdomain) }) }}></div>
                }
                    disabled={true}
                    value={this.state.domainData.dkimConfig.dkimRecord}
                />
            </UILIB.Paper>

            <UILIB.Paper className={spfClass}>
                <h4 className="mar-b25">SPF</h4>
                <UILIB.TextInput
                    label={<div dangerouslySetInnerHTML={{ __html: i18n.t('company:domains.adromAuth.spf', { sudomain: textFunctions.checkForHtml(this.state.domainData.spfConfig.subdomain) }) }}></div>}
                    disabled={true}
                    value={this.state.domainData.spfConfig.spfRecord}
                />
            </UILIB.Paper >

            <UILIB.Paper className={mxClass}>
                <h4 className="mar-b25">MX</h4>
                <UILIB.TextInput
                    label={<div dangerouslySetInnerHTML={{ __html: i18n.t('company:domains.adromAuth.mx', { sudomain: textFunctions.checkForHtml(this.state.domainData.mxConfig.subdomain) }) }}></div>}
                    disabled={true}
                    value={this.state.domainData.mxConfig.mxRecords[0]}
                />

            </UILIB.Paper>

            <UILIB.Paper className={dmarcClass}>
                <h4 className="mar-b25">DMARC</h4>
                <UILIB.TextInput
                    label={<div dangerouslySetInnerHTML={{ __html: i18n.t('company:domains.adromAuth.dmarc', { sudomain: textFunctions.checkForHtml(this.state.domainData.dmarcConfig.subdomain) }) }}></div>}
                    disabled={true}
                    value={this.state.domainData.dmarcConfig.dmarcRecord}
                />
            </UILIB.Paper>


            <h4 className="mar-b25">{i18n.t('company:domains.authAllDone')}</h4>
            {this.state.alertMessage}

            <UILIB.Button text="Check Again" className="button-primary" onClick={this.loadAdromDomain} />


        </div >
    }
};



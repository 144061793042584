import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export function useIsTranspond() {
  const siteMaster = useSelector((state) => state.siteMaster);

  const [isTranspond, setIsTranspond] = useState(false);

  useEffect(() => {
    setIsTranspond(siteMaster.siteId === 1);
  }, [siteMaster]);

  return isTranspond;
}

import React, { useState, useEffect } from 'react';
import UILIB from '~/Common-Objects/Lib';
import axios from '~/data/http/axios';
import DateTimeFunctions from '../../../../../../../Classes/dateTimeFunctions';
import InboxButton from '../../shared/inboxButton';
import i18n from '~/i18n';

export default function ChatFields({ InboxId, ChatId }) {
    const [fields, setFields] = useState(null)
    const [values, setValues] = useState(null)
    const [editing, setEditing] = useState(null)

    const getFields = async () => {
        const { data } = await axios.get('/inboxes/' + InboxId + '/field')
        setFields(data)
    }

    const getValues = async () => {
        const { data } = await axios.get('/inboxes/chat/' + ChatId + '/chatFieldValues')
        setValues(data)
    }

    const edit = (field, e) => {
        if (e.target.tagName === 'A') return
        setEditing({ field, current: values.find(v => v.ChatFieldId === field.id)?.value })
    }

    const finish = async (value) => {
        await axios.post('/inboxes/' + InboxId + '/field/' + editing.field.id + '/value', { value, ChatId })
        setEditing(null)
        getValues()
    }

    useEffect(() => {
        getFields()
    }, [InboxId, ChatId])

    useEffect(() => {
        getValues()
    }, [fields])

    if (!fields || !values) return <UILIB.LoadingIcons />

    return <div>
        {!!editing && <Edit open={!!editing} {...editing} close={() => setEditing(null)} finish={finish} />}
        <dl>
            {fields.map(field => {
                let value
                const valueObj = values.find(v => v.ChatFieldId === field.id)
                if (valueObj) {
                    value = valueObj.value
                    if (field.type === 'date') {
                        value = DateTimeFunctions.formatDateTime(value)
                    } else if (field.type === 'select') {
                        const selectValue = field.options.find(v => v.value === value)
                        value = selectValue ? selectValue.label : ''
                    } else if (field.type === 'boolean') {
                        value = value ? 'Yes' : 'No'
                    } else if (field.type === 'textarea') {
                        if (value) {
                            value = value.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br /></span>
                            })
                        }
                    } else if (field.type === 'url') {
                        value = <a href={value} target="_blank">{value}</a>
                    }
                }
                return <div style={{ cursor: 'pointer', display: 'block' }} onClick={(e) => edit(field, e)} key={field.id}>
                    <div style={{ fontSize: 12, color: 'var(--grey-700)', marginBottom: value === undefined ? 0 : 4, display: 'block' }}>{field.name}</div>
                    <div style={{ fontSize: 12, display: 'block' }}>{value}</div>
                </div>
            })}
        </dl >
    </div >

}

function Edit({ field, current, close = () => { }, finish = () => { } }) {
    const [value, setValue] = useState(current)
    const [error, setError] = useState(null)
    const [dateOpen, setDateOpen] = useState(false)

    const submit = async () => {
        try {
            await finish(value)
        } catch (e) {
            console.log(e)
            setError('Invalid Value')
        }
    }

    const style = {}
    if (field.type === 'date' && dateOpen) {
        style.height = 350
    }

    if (field.type === 'select' && dateOpen) {
        if (field.options?.length > 5) {
            style.height = 350
        } else {
            style.minHeight = 50 * field.options?.length + 50
        }
    }

    return (
        <UILIB.Dialog.Root open={true} onOpenChange={close}>
            <UILIB.Dialog.Portal >
                <UILIB.Dialog.Overlay />
                <UILIB.Dialog.Content className="inbox__generic-modal">
                    <div style={style}>
                        <div className="inbox__generic-modal__header">
                            Edit {field.name}
                        </div>
                        <div className="inbox__generic-modal__description">
                            {field.type === 'text' && <UILIB.TextInput error={error} onEnterPress={submit} value={value} onChange={e => setValue(e.target.value)} />}
                            {field.type === 'url' && <UILIB.TextInput error={error} type="url" onEnterPress={submit} value={value} onChange={e => setValue(e.target.value)} />}
                            {field.type === 'textarea' && <UILIB.TextArea error={error} value={value} rows="4" onChange={e => setValue(e.target.value)} />}
                            {field.type === 'select' && <UILIB.Select error={error} onOpen={() => setDateOpen(true)} onClose={() => setDateOpen(false)} value={value} data={field.options} onChange={e => setValue(e.target.value)} />}
                            {field.type === 'number' && <UILIB.TextInput error={error} onEnterPress={submit} type="number" value={value} onChange={e => setValue(e.target.value)} />}
                            {field.type === 'date' && <UILIB.TextInput error={error} handleCalendarOpen={() => setDateOpen(true)} handleCalendarClose={() => setDateOpen(false)} type="datetime" value={value} onChange={(e, v) => setValue(v)} />}
                            {field.type === 'boolean' && <UILIB.CheckBox checked={value} onChange={e => setValue(e.target.checked)} />}
                        </div>
                        <div className="inbox__generic-modal__cta">
                            <InboxButton onClick={submit} variant="secondary">{i18n.t("chat:inbox.rightBar.chatFields.finish")}</InboxButton>
                            <InboxButton onClick={close} variant="tertiary">Cancel</InboxButton>
                        </div>
                    </div>
                </UILIB.Dialog.Content>
            </UILIB.Dialog.Portal>
        </UILIB.Dialog.Root>
    )
}
import React from 'react'
import { useSelector } from 'react-redux'
import axios from '~/data/http/axios'
import UILIB from '~/Common-Objects/Lib.jsx';
import TierPermissions from '../../../Classes/tierPermissions';
import i18n from '~/i18n'

export default function ToggleDisabled({ form, finished = () => { } }) {
    const account = useSelector(state => state.accountMaster)
    const toggle = async () => {
        await axios.put('/group/' + form.GroupId + '/form/' + form.id + '/disable')
        finished()
    }

    const gatedContentPermission = TierPermissions('gatedContent', account)
    const canEnable = !form.options?.serve || gatedContentPermission.allowed

    return (
        <div>
            <h3 className="mar-b20">{form.disabled ? i18n.t('form:disable.headerEnable') : i18n.t('form:disable.headerDisable')}</h3>
            {!form.disabled && <div>
                {i18n.t('form:disable.subHeaderDisable')}
            </div>}
            {form.disabled && !canEnable && <div>
                {i18n.t('form:disable.subHeaderNotAllowed')}
            </div>}
            {form.disabled && canEnable && <div>
                {i18n.t('form:disable.subHeaderEnable')}
            </div>}
            <div className="mar-t20 quickFlex">
                {(!form.disabled || canEnable) && <UILIB.Button className="button-primary mar-r10" onClick={toggle}>
                    {form.disabled ? i18n.t('form:disable.enable') : i18n.t('form:disable.disable')}
                </UILIB.Button>}
                {form.disabled && !canEnable && <UILIB.Button className="button-primary mar-r10" to="/cp/company/subscription">{i18n.t('form:disable.upgrade')}</UILIB.Button>}
                <UILIB.Button onClick={finished}>{i18n.t('form:disable.cancel')}</UILIB.Button>
            </div>
        </div>
    )
}
import React from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import SiteVars from '~/Classes/siteVars';

import Step2 from './steps/step2'
import Step3 from './steps/step3'
import Step4 from './steps/step4'
import Step5 from './steps/step5'
import Step6 from './steps/step6'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, accountMaster: store.accountMaster }
})

export default class FormCreation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formId: undefined,
            form: {
                formName: '',
                wizard: {}
            },
            loaded: false
        }

        this.changeStep = this.changeStep.bind(this);
        this.updateForm = this.updateForm.bind(this);
        this.saveForm = this.saveForm.bind(this);
        this.getForm = this.getForm.bind(this);
        this.editForm = this.editForm.bind(this)
    };

    componentDidMount() {
        if (parseInt(this.props.match.params.formId)) {
            this.setState({
                formId: this.props.match.params.formId
            }, this.getForm)
        } else {
            this.props.history.push('/cp/groups/' + this.props.match.params.formId + '/forms')
        }
    }

    getForm() {
        axios.get('/group/' + this.props.match.params.groupId + '/form/' + this.state.formId).then(response => {
            if (!response.data.Form.options) response.data.Form.options = {
                autoPopup: false,
                overlay: false
            }
            var theForm = response.data.Form;
            if (!theForm.wizard) theForm.wizard = { step1: false, step2: false, step3: false, step4: false, step5: false, step6: false }
            if (!theForm.options.createMessage) theForm.options.createMessage = "Thanks for Subscribing, we've sent you an email";

            this.setState({
                form: theForm,
                loaded: true
            })
        })
    }

    changeStep(stepNo, save) {
        this.props.history.push('/cp/groups/' + this.props.match.params.groupId + '/forms/create/' + this.state.formId + '/' + stepNo)
    }

    saveForm() {
        return axios.put('/group/' + this.props.match.params.groupId + '/form/' + this.state.formId, this.state.form).then(() => {
            return this.state.formId
        })
    }

    updateForm(cb) {
        var form = this.state.form;
        form.lastUpdated = new Date()
        this.setState({
            form
        }, () => {
            if (cb && typeof cb === 'function') {
                cb()
            }
        })
    }

    editForm() {
        this.saveForm().then(() => {
            var redirect = '/cp/groups/' + this.props.match.params.groupId + '/forms/create/' + this.state.form.id + '/2'
            this.props.history.push('/cp/templates/add/dragdrop?form=true&groupId=' + this.props.match.params.groupId + '&formId=' + this.state.form.id + '&redirect=' + redirect);
        })

    }

    render() {
        if (!this.state.loaded) {
            return <div></div>
        }
        var currentStep = 1;
        var stepClasses = { step1: "", step2: "", step3: "", step4: "", step5: "", step6: "" }

        var wizard = this.state.form.wizard

        if (this.props.match.params.step) currentStep = parseInt(this.props.match.params.step)
        if (wizard.step1) stepClasses.step1 = 'addcampaign-stepholder-step-complete'
        if (wizard.step2) stepClasses.step2 = 'addcampaign-stepholder-step-complete'
        if (wizard.step3) stepClasses.step3 = 'addcampaign-stepholder-step-complete'
        if (wizard.step4) stepClasses.step4 = 'addcampaign-stepholder-step-complete'
        if (wizard.step5) stepClasses.step5 = 'addcampaign-stepholder-step-complete'
        if (wizard.step6) stepClasses.step6 = 'addcampaign-stepholder-step-complete'
        stepClasses['step' + currentStep] = 'addcampaign-stepholder-step-selected'


        var screenshotUrl = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/form" + this.state.form.id + ".jpg?now=" + Date.now();
        if (currentStep === 4) {
            screenshotUrl = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/form" + this.state.form.formCreatedFormId + ".jpg?now=" + Date.now();
        }
        if (currentStep === 5) {
            screenshotUrl = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/form" + this.state.form.formConfirmedFormId + ".jpg?now=" + Date.now();
        }

        let steps = [];
        steps.push({
            title: "",
            onClick: () => { wizard.step1 ? this.editForm() : this.changeStep(1, true) },
            selected: stepClasses.step1 == "addcampaign-stepholder-step-selected",
            complete: stepClasses.step1 == "addcampaign-stepholder-step-complete"
        })
        steps.push({
            title: "",
            onClick: () => { wizard.step1 ? this.changeStep(2, true) : {} },
            selected: stepClasses.step2 == "addcampaign-stepholder-step-selected",
            complete: stepClasses.step2 == "addcampaign-stepholder-step-complete"
        })
        steps.push({
            title: "",
            onClick: () => { wizard.step2 ? this.changeStep(3, true) : {} },
            selected: stepClasses.step3 == "addcampaign-stepholder-step-selected",
            complete: stepClasses.step3 == "addcampaign-stepholder-step-complete"
        })
        steps.push({
            title: "",
            onClick: () => { wizard.step3 ? this.changeStep(4, true) : {} },
            selected: stepClasses.step4 == "addcampaign-stepholder-step-selected",
            complete: stepClasses.step4 == "addcampaign-stepholder-step-complete"
        })
        steps.push({
            title: "",
            onClick: () => { wizard.step4 ? this.changeStep(5, true) : {} },
            selected: stepClasses.step5 == "addcampaign-stepholder-step-selected",
            complete: stepClasses.step5 == "addcampaign-stepholder-step-complete"
        })
        steps.push({
            title: "",
            onClick: () => { wizard.step5 ? this.changeStep(6, true) : {} },
            selected: stepClasses.step6 == "addcampaign-stepholder-step-selected",
            complete: stepClasses.step6 == "addcampaign-stepholder-step-complete"
        })
        return <UILIB.Paper>

            <h4 className="page-header mar-b25">{i18n.t('form:add.addFormTitle')}</h4>
            <UILIB.ProgressNav steps={steps} />


            <div className="campaign-holder">
                {currentStep == 2 && <Step2 screenshotUrl={screenshotUrl} form={this.state.form} groupId={this.props.match.params.groupId} save={this.saveForm} history={this.props.history} changeStep={this.changeStep} update={this.updateForm} updated={this.state.form.lastUpdated} />}
                {currentStep == 3 && <Step3 screenshotUrl={screenshotUrl} form={this.state.form} groupId={this.props.match.params.groupId} save={this.saveForm} history={this.props.history} changeStep={this.changeStep} update={this.updateForm} updated={this.state.form.lastUpdated} />}
                {currentStep == 4 && <Step4 screenshotUrl={screenshotUrl} form={this.state.form} groupId={this.props.match.params.groupId} save={this.saveForm} history={this.props.history} changeStep={this.changeStep} update={this.updateForm} updated={this.state.form.lastUpdated} />}
                {currentStep == 5 && <Step5 screenshotUrl={screenshotUrl} form={this.state.form} groupId={this.props.match.params.groupId} save={this.saveForm} history={this.props.history} changeStep={this.changeStep} update={this.updateForm} updated={this.state.form.lastUpdated} />}
                {currentStep == 6 && <Step6 screenshotUrl={screenshotUrl} form={this.state.form} groupId={this.props.match.params.groupId} save={this.saveForm} history={this.props.history} changeStep={this.changeStep} update={this.updateForm} updated={this.state.form.lastUpdated} />}

            </div>

        </UILIB.Paper >
    }
}
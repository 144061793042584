import React from 'react'
import axios from '~/data/http/axios';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import ViewTemplate from './viewTemplate.jsx'
import UILIB from '~/Common-Objects/Lib.jsx';
import moment from 'moment';
import PermissionChecker from '~/Classes/permissions';

import CampaignOverview from './overview/overview.jsx';
import VisitorsPage from './visitors';
import EventsPage from '../shared/events';
@connect((store) => {
  return { site: store.siteMaster, permissionStore: store.permission }
})
export default class ViewCampaign extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      campaignId: this.props.match.params.campaignId,
      campaign: {},
      application: {},
      pageLoaded: false,
      startDate: null,
      endDate: null,
      changedDate: false,
      isAutomation: false,
      adCampaignAppId: 0,
      appCampaignId: 0,
      adCampaign: {},
      siteTrackers: [],
      hasSiteTracking: false
    };
    this.loadCampaign = this.loadCampaign.bind(this);
    this.updateDate = this.updateDate.bind(this);
  }

  componentWillUnmount() {
    this._mounted = false
  }
  componentDidMount() {
    this._mounted = true;
    this.loadCampaign();
  }

  async loadCampaign() {
    try {
      if (!this._mounted) return
      let campaign = await axios.get('/campaign/' + this.props.match.params.campaignId)
      campaign = campaign.data.Campaign;
      var startDate = (campaign.startDate ? moment(campaign.startDate) : moment()).startOf('day').subtract(1, 'day');
      var endDate = moment().add(1, "day");
      if (campaign.finishedDate) {
        endDate = moment(campaign.finishedDate)
      }

      let adCampaignAppId = campaign.options ? campaign.options.ApplicationId : 0;
      let appCampaignId = campaign.options ? campaign.options.appCampaignId : 0;
      let adCampaignId = campaign.options ? campaign.options.adCampaignId : 0;

      let adCampaign = await axios.get('/ads/campaigns/' + adCampaignId)
      adCampaign = adCampaign.data;
      let application = adCampaign.application;
      let siteTrackers = await axios.get('/accountMaster/tracker')
      siteTrackers = siteTrackers.data.Trackers;
      let hasSiteTracking = siteTrackers.length;

      this.setState({
        campaign: campaign,
        startDate: startDate,
        endDate: endDate,
        pageLoaded: true,
        application,
        adCampaignAppId,
        appCampaignId,
        adCampaign,
        siteTrackers,
        hasSiteTracking
      });
    }
    catch (err) {
      console.log(err);
      this.props.history.push('/cp/campaigns/');
    }
  }

  updateDate(dates) {
    this.setState({ startDate: dates.startDate, endDate: dates.endDate, changedDate: true })
  }

  render() {
    if (this.state.pageLoaded) {
      var canExport = PermissionChecker("reporting", this.props.permissionStore.permissions, "write");

      let props = {
        campaign: this.state.campaign,
        campaignId: this.props.match.params.campaignId,
        updateDate: this.updateDate,
        isAutomation: this.state.isAutomation,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        application: this.state.application,
        adCampaignAppId: this.state.adCampaignAppId,
        hasSiteTracking: this.state.hasSiteTracking,
        canExport: canExport,
        reload: this.loadCampaign,
        adCampaign: this.state.adCampaign
      }
      return <ViewTemplate
        history={this.props.history}
        {...props}
      >
        <Route exact path="/cp/campaigns/viewad/:campaignId" render={(routeProps) => (
          <CampaignOverview
            {...routeProps}
            {...props}
          />
        )} />
        <Route exact path="/cp/campaigns/viewad/:campaignId/visitors" render={(routeProps) => <VisitorsPage
          {...routeProps}
          {...props}
        />}
        />
        <Route exact path="/cp/campaigns/viewad/:campaignId/events" render={(routeProps) => <EventsPage
          {...routeProps}
          {...props}
        />}
        />

      </ViewTemplate>
    }
    else {
      return <UILIB.LoadingIcons iconType="2" />
    }

  }
};


import React from "react";
import classnames from "classnames";
import { propToCssModule } from "~/Classes/styles";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";

const ConversationsButton = React.forwardRef(
  ({ variant, iconOnly, className, ...rest }, forwardedRef) => {
    const classes = classnames(styles.root, className, {
      [styles[`rootVariant${propToCssModule(variant)}`]]: variant,
      [styles.rootIconOnly]: iconOnly,
    });

    return (<>
      <UILIB.ButtonNew
        ref={forwardedRef}
        variant={variant}
        iconOnly={iconOnly}
        className={classes}
        {...rest}
      />
    </>
    );
  }
);

export default ConversationsButton;

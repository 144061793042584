import React from 'react'
import i18n from '~/i18n';

export default function NoData({ primary, secondary, text = 'noDataText' }) {
    return (
        <div style={{ height: 322, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <svg width="213" height="88" viewBox="0 0 213 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="10.5" y1="88" x2="10.5" y2="-2.18557e-08" stroke="#DADADC" strokeDasharray="3 3" />
                <line x1="42.5" y1="88" x2="42.5" y2="-2.18557e-08" stroke="#DADADC" strokeDasharray="3 3" />
                <line x1="74.5" y1="88" x2="74.5" y2="-2.18557e-08" stroke="#DADADC" strokeDasharray="3 3" />
                <line x1="106.5" y1="88" x2="106.5" y2="-2.18557e-08" stroke="#DADADC" strokeDasharray="3 3" />
                <line x1="138.5" y1="88" x2="138.5" y2="-2.18557e-08" stroke="#DADADC" strokeDasharray="3 3" />
                <line x1="170.5" y1="88" x2="170.5" y2="-2.18557e-08" stroke="#DADADC" strokeDasharray="3 3" />
                <line x1="202.5" y1="88" x2="202.5" y2="-2.18557e-08" stroke="#DADADC" strokeDasharray="3 3" />
                <path d="M31.2473 58H21.2703C14.1555 58 7.10597 59.3576 0.5 62V88H212.5V58C205.882 58 199.312 56.8719 193.073 54.6642L184.654 51.6854C174.933 48.2455 165.742 43.4586 157.351 37.4649L145.151 28.7506C140.756 25.6116 135.81 23.3275 130.571 22.0177C115.672 18.2931 99.9181 22.7876 89.2275 33.8123L84.6968 38.4845C78.0182 45.3719 69.635 50.3683 60.3995 52.9658L53.3324 54.9534C46.1448 56.9749 38.7138 58 31.2473 58Z" fill="url(#paint0_linear_453_5121)" fillOpacity="0.32" />
                <path d="M0.5 62V62C7.10597 59.3576 14.1555 58 21.2703 58H31.2473C38.7138 58 46.1448 56.9749 53.3324 54.9534L60.3995 52.9658C69.635 50.3683 78.0182 45.3719 84.6968 38.4845L89.2275 33.8123C99.9181 22.7876 115.672 18.2931 130.571 22.0177V22.0177C135.81 23.3275 140.756 25.6116 145.151 28.7506L157.351 37.4649C165.742 43.4586 174.933 48.2455 184.654 51.6854L193.073 54.6642C199.312 56.8719 205.882 58 212.5 58V58" stroke={primary} />
                <path d="M32.3097 72.2738L22.6123 73.486C14.658 74.4802 7.06712 77.403 0.5 82V88H212.5V78C204.299 78 198.63 69.2732 191.14 65.9317L184.526 62.981C174.913 58.6918 164.638 56.0744 154.143 55.2415L144.01 54.4373C140.341 54.1461 136.657 54.1152 132.984 54.3448L121.602 55.0561C111.81 55.6681 102.496 59.5036 95.1122 65.9643L92.7652 68.018C81.5665 77.8168 65.8007 80.4659 52.0145 74.8653C45.7758 72.3308 38.9915 71.4386 32.3097 72.2738Z" fill="url(#paint1_linear_453_5121)" fillOpacity="0.32" />
                <path d="M0.5 82V82C7.06712 77.403 14.658 74.4802 22.6123 73.486L32.3097 72.2738C38.9915 71.4386 45.7758 72.3308 52.0145 74.8653V74.8653C65.8007 80.4659 81.5665 77.8168 92.7652 68.018L95.1122 65.9643C102.496 59.5036 111.81 55.6681 121.602 55.0561L132.984 54.3448C136.657 54.1152 140.341 54.1461 144.01 54.4373L154.143 55.2415C164.638 56.0744 174.913 58.6918 184.526 62.981L191.14 65.9317C198.63 69.2732 204.299 78 212.5 78V78" stroke={secondary} />
                <defs>
                    <linearGradient id="paint0_linear_453_5121" x1="106.5" y1="16" x2="106.5" y2="88" gradientUnits="userSpaceOnUse">
                        <stop stopColor={primary} />
                        <stop offset="1" stopColor={primary} stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_453_5121" x1="106.5" y1="54" x2="106.5" y2="88" gradientUnits="userSpaceOnUse">
                        <stop stopColor={secondary} />
                        <stop offset="1" stopColor={secondary} stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            <div style={{ fontSize: 20, marginTop: 20, fontWeight: 600 }}>{i18n.t('campaigns:main.chart.noDataHeader')}</div>
            <div style={{ fontSize: 14, marginTop: 10 }}>{i18n.t('campaigns:main.chart.' + text)}</div>
        </div>
    )
}
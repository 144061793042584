import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from '~/data/http/axios'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import dateTimeFunctions from '~/Classes/dateTimeFunctions'

export default function MailboxHistory() {
    const { id } = useParams()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [mailbox, setMailbox] = useState(null)
    const [results, setResults] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const [searchText, setSearchText] = useState('')
    const searchTimer = useRef(null)

    useEffect(() => {
        setTotal(0);
        setPage(0);
        axios.get('/accountMaster/mailbox/' + id).then(response => {
            setMailbox(response.data.Mailbox)
            getHistory()
        })
    }, [id])

    const getHistory = async (search) => {
        setLoading(true)
        const results = await axios.get('/accountMaster/mailbox/' + id + '/history?limit=30&offset=' + (page * 30) + '&searchText=' + (search || ''))
        setResults(results.data.rows)
        setTotal(results.data.count)
        setLoading(false)
    }

    const updateSearchText = (e) => {
        const value = e.target.value
        setSearchText(value)
        if (searchTimer.current) clearTimeout(searchTimer.current)
        searchTimer.current = setTimeout(() => getHistory(value), 250)
    }

    const updatePage = (value) => {
        setPage(value)
        getHistory()
    }

    if (!mailbox) return <UILIB.LoadingIcons iconType="2" />

    const data = results.map(row => {
        return {
            datetime: {
                headerValue: 'Date/Time',
                value: dateTimeFunctions.formatDateTime(row.createdAt),
                order: 1
            },
            emailAddress: {
                headerValue: "Email Address",
                value: row.Subscriber ? <a onClick={() => history.push('/cp/subscriber/' + row.SubscriberId)}>{row.Subscriber.emailAddress}</a> : '-DELETED-',
                order: 2
            },
            campaign: {
                headerValue: 'Campaign',
                value: row.Campaign ? <a onClick={() => history.push('/cp/campaigns/view/' + row.CampaignId)}>{row.Campaign.campaignName}</a> : '-DELETED-',
                order: 3
            },

        }
    })

    return <div>
        <UILIB.Row>
            <UILIB.Column xs={12} sm={12} md={6} lg={6} className="quickFlex mar-b25">
                <UILIB.Hint
                    className="mar-0"
                    iconLeft={<UILIB.Icons icon="arrowLeft" />}
                    onClick={() => { history.goBack() }}>
                    {i18n.t('subscribers:subscriber.back')}
                </UILIB.Hint>
            </UILIB.Column>
            <UILIB.Column xs={12} className="mar-b25">
                <h4>{i18n.t('company:mailboxes.replyTrackingHistory')} - {mailbox.emailAddress}</h4>
            </UILIB.Column>
            <UILIB.Column lg={6} md={6} sm={12} xs={12} className="mar-b25">
                <UILIB.TextInput
                    className="no-marg"
                    value={searchText}
                    placeholder={i18n.t('subscribers:overview.searchPlaceholder')}
                    onChange={updateSearchText}
                    iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}

                />
            </UILIB.Column>

            <UILIB.Column xs={12} className="mar-b25">
                <UILIB.Paper isLoading={loading}>
                    <UILIB.DataTable1
                        noResultsTxt={<span>No Replies Found</span>}
                        tableData={data}
                        loadingData={false}
                        width="100%"
                        pageSize={30}
                        hasCheckBoxes={false} />
                </UILIB.Paper>
            </UILIB.Column>
            <UILIB.Column xs={12} className="center-xs">
                <UILIB.PagingBlock
                    totalRows={total}
                    pageSize={30}
                    numberOfLinks="10"
                    currentPage={page}
                    changePage={updatePage}
                    text={i18n.t('page') + ":"} />
            </UILIB.Column>
        </UILIB.Row>
    </div>
}
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'

class Action extends Component {
    constructor(props) {
        super(props);
        this.getOptions = this.getOptions.bind(this)
    }

    getOptions(fieldName) {
        var field = this.props.fields.find(f => f.value === fieldName)
        if (!field) return []
        switch (field.type) {
            case 'BigInt':
            case 'Decimal':
            case 'Double':
            case 'Integer':
            case 'Money':
                return [{
                    label: 'Increment',
                    value: 'inc'
                }, {
                    label: 'Decrement',
                    value: 'dec'
                }]
            case 'Boolean':
                return [{
                    label: 'Turn On',
                    value: 'true'
                }, {
                    label: 'Turn Off',
                    value: 'false'
                }]
            case 'DateTime':
                return [{ label: 'Set Date', value: 'date' }]
            default:
                return [{ label: 'Write Action', value: 'action' }]
        }
    }

    render() {
        var texts = {
            open: `When a ${this.props.type} opens an email...`,
            click: `When a ${this.props.type} clicks an email...`,
            unsubscribe: `When a ${this.props.type} unsubscribes...`,
            junk: `When a ${this.props.type} marks an email as junk...`,
            bounce: `When a ${this.props.type} bounces...`
        }

        return (
            <div className="mar-b30">
                <div className="mar-b10">{texts[this.props.action]}</div>
                {!this.props.actions.length && <div className="text-sml text-grey text-heavy mar-b20">No Actions Created</div>}
                {!!this.props.actions.length && this.props.actions.map((action, index) => {
                    var options = []
                    if (action.field) {
                        options = this.getOptions(action.field)
                        if (!action.action) action.action = options.length ? options[0].value : ''
                    }
                    return <UILIB.Row key={index}>
                        <UILIB.Column xs={4} className="form-group">
                            <UILIB.Select data={this.props.fields} value={action.field} name="field" onChange={ev => this.props.update(this.props.type, this.props.action, index, ev)} />
                        </UILIB.Column>
                        <UILIB.Column xs={4} className="form-group">
                            {action.field && <UILIB.Select data={options} value={action.action} name="action" onChange={ev => this.props.update(this.props.type, this.props.action, index, ev)} />}
                        </UILIB.Column>
                        <UILIB.Column xs={3}>
                            <UILIB.Button text="delete" className="button-sml grey" onClick={() => this.props.delete(this.props.type, this.props.action, index)} />
                        </UILIB.Column>
                    </UILIB.Row>
                })}
                <UILIB.Button text="Add Action" className="button-sml outline secondary min-width" onClick={() => this.props.addAction(this.props.action, this.props.type)} />
            </div>
        );
    }
}

export default Action;
import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'
import jquery from 'jquery';

export default class ClusterHolder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showPop: false,
            popupStyle: {}
        }
        this.checkClick = this.checkClick.bind(this);
        this.resizePopup = this.resizePopup.bind(this);
    }

    componentDidMount() {
        window.addEventListener("click", this.checkClick)
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.checkClick)
    }

    checkClick(ev) {
        var target = jquery(ev.target);
        if ((ev.target.id && ev.target.id == "clusterIcon_" + this.props.index) || target.parents("div#clusterIcon_" + this.props.index).length) {
            this.setState({ showPop: true }, this.resizePopup)
        }
        else {
            this.setState({ showPop: false })
        }

    }

    resizePopup() {
        var popupStyle = {};
        var triggerRect = this.refs.ourHolder.getBoundingClientRect();
        var ourRect = this.refs.popupHolder;
        popupStyle.left = triggerRect.left - (ourRect.offsetWidth + 40);
        popupStyle.top = triggerRect.top - 25;

        var bottomPx = popupStyle.top + ourRect.offsetHeight;
        if (bottomPx > window.innerHeight) {
            popupStyle.top = window.innerHeight - ourRect.offsetHeight;
        }

        this.setState({ popupStyle })
    }


    render() {

        return <div className="dd_toolHolder"  >
            <div className="dd_inputTool">
                <p className="txtInput_label">{this.props.title}</p>
                <div className="inputWrapper">
                    <div className="clusterHolder">
                        <div className="clusterIcon" id={"clusterIcon_" + this.props.index} ref="ourHolder">{this.props.html}</div>
                    </div>
                </div>
            </div>
            {this.state.showPop && <div className="clusterPopupHolder" ref="popupHolder" onClick={(ev) => { ev.stopPropagation() }} style={this.state.popupStyle}>
                <h4 className="clusterPopTitle">{this.props.title}</h4>
                <div className="clusterPopContent">
                    {this.props.clusterMembers}
                </div>
                <div className="clusterPopFooter">
                    <UILIB.Button text="Save" className="button-primary" onClick={this.checkClick} />
                </div>
            </div>}
        </div>

    }
}
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import NumberFunction from '~/Classes/numberFormatFunctions'
import i18n from '~/i18n'

export default function SmsSendGroupSubscribers({ campaign, subscriberCount, save = () => { } }) {

    return (
        <UILIB.Paper className="mar-b20">
            <UILIB.Row>
                <UILIB.Column xs={12}>
                    {<h4 className="mar-b25">{i18n.t('campaigns:main.noOfRecipients')}</h4>}
                    <div className="quickFlex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div className="text-lg2 text-heavy cyt-view-total-subs" >{!!subscriberCount ? NumberFunction.formatNumber(subscriberCount) : 0}</div>
                        <UILIB.Button className='cyt-view-total-btn' text={i18n.t('campaigns:main.viewRecipients')} onClick={() => save()} />
                    </div>
                </UILIB.Column>
            </UILIB.Row>
        </UILIB.Paper>
    )

}
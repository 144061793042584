import React from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area, CartesianGrid } from 'recharts';
import moment from 'moment';
import i18n from '~/i18n'

@connect((store) => {
    return {
        filters: store.filters,
        siteMaster: store.siteMaster,
        user: store.user
    }
})

export default class CampaignLineGraph extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoaded: false,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            unique: true,
            colors: ["#A9A9AD", "#43B7B2", "#33257E", "#DD4124"],
            graphData: [],
            groupOptions: [{
                value: 'HOUR',
                label: i18n.t('campaigns:overview.groupHour'),
                color: 'purple',
                format: 'HH:mm'
            }, {
                value: 'DAYOFWEEK',
                label: i18n.t('campaigns:overview.groupWeekday'),
                color: 'red',
                format: 'ddd'
            }, {
                value: 'MONTHNAME',
                label: i18n.t('campaigns:overview.groupMonth'),
                color: 'green',
                format: 'MM-DD'
            }, {
                value: 'YEAR',
                label: i18n.t('campaigns:overview.groupYear'),
                color: 'blue',
                format: 'MMM'
            }],
            currentGroupBy: 1,
            statOptions: [{
                label: i18n.t('campaigns:overview.statSends'),
                value: 'sends',
                link: 'sent'
            }, {
                label: i18n.t('campaigns:overview.statOpens'),
                value: 'opens',
                link: 'opened'
            }, {
                label: i18n.t('campaigns:overview.statClicks'),
                value: 'clicks',
                link: 'clicked'
            }, {
                label: i18n.t('campaigns:overview.statUnsubscribes'),
                value: 'unsubscribes',
                link: 'unsubscribed'
            }],
            currentStat: 1,
            thisHeight: 300
        };

        this.loadData = this.loadData.bind(this);
        this.getStats = this.getStats.bind(this);
        this.changeGroupType = this.changeGroupType.bind(this);
        this.changeStatType = this.changeStatType.bind(this);
    }

    componentDidMount() {
        var thisHeight = 320;
        if (this.props.autoHeight) {
            let campaignEle = document.getElementById("campaignDetails");
            if (campaignEle && campaignEle.offsetHeight) {
                thisHeight = campaignEle.offsetHeight - 130;
            }
        }

        this.setState({ thisHeight: thisHeight }, this.getStats);
    }

    componentDidUpdate(prevProps) {
        var refresh = false;
        if (this.props.startDate !== this.state.startDate) refresh = true;
        if (this.props.endDate !== this.state.endDate) refresh = true;

        if (refresh) {

            this.setState({ startDate: this.props.startDate, endDate: this.props.endDate }, () => {
                this.loadData();
            })
        }
    }



    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getStats() {
        this.loadData();
        clearInterval(this.timer);
        this.timer = setInterval(this.loadData, 5000)
    }

    changeGroupType(index) {
        this.setState({
            currentGroupBy: index
        }, this.getStats)
    }

    changeStatType(ev) {
        this.setState({
            currentStat: ev.currentTarget.value
        }, this.getStats)
    }

    loadData() {
        var groupBy = this.state.groupOptions[this.state.currentGroupBy];
        var stat = this.state.statOptions[this.state.currentStat];
        var start = moment(this.state.startDate);
        var end = moment(this.state.endDate);

        switch (this.state.currentGroupBy) {
            case 0:
                start.subtract(23, 'hours');
                end = moment()
                break;
            case 1:
                start.subtract(6, 'days').startOf('day');
                break;
            case 2:
                start.subtract(4, 'weeks').startOf('week');
                end = moment().endOf('week')
                break;
            case 3:
                start.subtract(11, 'months');
                end = moment().endOf('month')
                break;
        }

        var queryStr = '?ignoreLog=1&group=' + groupBy.value + '&stat=' + stat.value + '&unique=' + this.state.unique;
        queryStr += '&start=' + (start).format('YYYY-MM-DD');
        queryStr += '&end=' + (end).format('YYYY-MM-DD');

        axios.get('/campaign/' + this.props.campaignId + '/stats/graph' + queryStr)
            .then(response => {

                var graphData = []
                response.data.Stats.forEach(row => {
                    graphData.push({
                        date: moment(row.str).locale(this.props.user.userData.language).format(groupBy.format),
                        [stat.value]: row.value
                    })
                })

                this.setState({
                    graphData: graphData,
                    pageLoaded: true
                })
            })
            .catch(console.log)
    }



    render() {



        if (this.state.pageLoaded) {
            return <UILIB.Paper id="campaignChartGraphHolder">
                <div>
                    <div className="quickFlex mar-b24" style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <UILIB.Select value={this.state.currentStat} onChange={this.changeStatType} data={this.state.statOptions.map((row, index) => {
                                return {
                                    label: row.label,
                                    value: index
                                }
                            })} />
                        </div>

                        <div>
                            {this.state.groupOptions.map((theOpt, index) => {
                                let selected = false;
                                if (index === this.state.currentGroupBy) selected = true;
                                let label = i18n.t('campaigns:main.chart.' + theOpt.label);
                                return <UILIB.ButtonSimple key={"gt_" + index} className={"mar-l5"} selected={selected} onClick={() => { this.changeGroupType(index) }}>
                                    {label}
                                </UILIB.ButtonSimple>
                            })
                            }
                        </div>
                    </div >

                    <ResponsiveContainer width="100%" height={this.state.thisHeight} >
                        <AreaChart data={this.state.graphData} margin={{ top: 0, right: 0, left: 20, bottom: 0 }}>
                            <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                            <YAxis axisLine={false} hide={true} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                            <Tooltip content={<UILIB.CustomTooltip name={this.state.statOptions[this.state.currentStat].label} />} />
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={this.props.siteMaster.colours.$primary} stopOpacity={0.3} />
                                    <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid horizontal={false} strokeDasharray="2 2" stroke="#DADADC" />
                            <Area
                                type="monotone"
                                dataKey={this.state.statOptions[this.state.currentStat].value}
                                fill="url(#colorUv)"
                                stroke={this.props.siteMaster.colours.$primary}
                                strokeWidth={2}
                                fillOpacity={1}
                                activeDot={{ stroke: this.props.siteMaster.colours.$primary, strokeWidth: 2, fill: 'white' }}
                            />
                        </AreaChart>
                    </ResponsiveContainer>

                </div >
            </UILIB.Paper>
        }
        else {
            return <UILIB.Paper className="full-height" id="campaignChartGraphHolder">
                <div style={{ minHeight: "400px" }}><UILIB.LoadingIcons iconType="2" /></div>
            </UILIB.Paper>
        }
    }
}
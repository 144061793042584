export function updateTier(tier) {
    return function (dispatch) {
        dispatch({ type: "CHANGE_TIER", payload: tier });
    }
}

export function changePanels(panels) {
    return function (dispatch) {
        dispatch({ type: 'CHANGE_PANELS', payload: panels })
    }
}

export function changeChart(chart) {
    return function (dispatch) {
        dispatch({ type: 'CHANGE_CHART', payload: chart })
    }
}

export function changeTable(table) {
    return function (dispatch) {
        dispatch({ type: 'CHANGE_TABLE', payload: table })
    }
}
import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';

export default class Table2 extends Component {
    constructor(props) {
        super(props)

    }

    render() {

        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "table", this.props.localVariables)
        }

        if (this.props.elementData.content && this.props.elementData.content.editableType && this.props.elementData.content.editableType == "ArraySelect") {
            return <div dangerouslySetInnerHTML={{ __html: DragDropFunctions.generateHtmlFromJson(this.props.elementData, true, this.props.globalVariables, undefined, this.props.localVariables) }} ref="theEl" />
        }
        return <table {...eleProps}>

            {this.props.children}
        </table>

    }
}



import React from "react";
import Select from "../index";

export default function SelectDefault({
  data,
  label,
  placeholder,
  align,
  fullWidth,
  triggerProps,
  popupStyle,
  ...rest
}) {
  return (
    <Select.Root fullWidth={fullWidth} {...rest}>
      <Select.Trigger
        label={label}
        placeholder={placeholder}
        fullWidth={fullWidth}
        {...triggerProps}
      />

      <Select.Portal>
        <Select.Content align={align} fullWidth={fullWidth} style={popupStyle}>
          <Select.Viewport>
            <Select.Group>
              {data.map((item) => {
                const { value, label } = item;

                return (
                  <Select.Item key={value} value={value}>
                    {label}
                  </Select.Item>
                );
              })}
            </Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}

import React from "react";
import { useDispatch } from "react-redux";
import * as siteMaster from "~/data/actions/siteActions";
import UILIB from "~/Common-Objects/Lib";
import MergeChecker from "./mergeTagChecker";

const CheckMergeTagsButton = ({ html, onStart, onClick, ...rest }) => {
    const dispatch = useDispatch();

    async function checkTags() {
        if (onClick && typeof (onClick === "function")) {
            const result = await onClick();
            if (typeof result === 'boolean' && result === false) return
        }

        if (onStart && typeof onStart === 'function') {
            html = onStart()
        }

        const drawerContent = <MergeChecker html={html} id={rest.id} type={rest.type} landingPage={rest.landingPage} />;

        dispatch(
            siteMaster.alterSiteDrawer(
                true,
                true,
                "right",
                drawerContent,
                true,
                "500px"
            )
        );
    }

    return (
        <UILIB.Button onClick={checkTags} {...rest}>
            Check Merge Tags
        </UILIB.Button>
    );
};

export default CheckMergeTagsButton;

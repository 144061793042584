import React from "react";

export default function IconMailOpen({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.94 6.41191C2.65235 6.5917 2.41515 6.84169 2.25071 7.13838C2.08627 7.43507 2 7.7687 2 8.10791V15.9999C2 16.5303 2.21071 17.0391 2.58579 17.4141C2.96086 17.7892 3.46957 17.9999 4 17.9999H16C16.5304 17.9999 17.0391 17.7892 17.4142 17.4141C17.7893 17.0391 18 16.5303 18 15.9999V8.10791C18 7.7687 17.9137 7.43507 17.7493 7.13838C17.5848 6.84169 17.3476 6.5917 17.06 6.41191L11.06 2.66191C10.7421 2.46325 10.3748 2.35791 10 2.35791C9.62516 2.35791 9.25786 2.46325 8.94 2.66191L2.94 6.41191ZM5.555 8.83492C5.44574 8.76203 5.32319 8.71138 5.19436 8.68586C5.06553 8.66033 4.93293 8.66044 4.80413 8.68616C4.67534 8.71188 4.55287 8.76272 4.44372 8.83577C4.33457 8.90882 4.24088 9.00266 4.168 9.11192C4.09512 9.22118 4.04447 9.34372 4.01894 9.47255C3.99342 9.60139 3.99352 9.73399 4.01924 9.86278C4.07119 10.1229 4.22434 10.3517 4.445 10.4989L9.445 13.8319C9.60933 13.9416 9.80245 14.0001 10 14.0001C10.1975 14.0001 10.3907 13.9416 10.555 13.8319L15.555 10.4989C15.7757 10.3517 15.9288 10.1229 15.9808 9.86278C16.0327 9.60267 15.9792 9.33258 15.832 9.11192C15.6848 8.89126 15.456 8.73811 15.1959 8.68616C14.9358 8.63421 14.6657 8.68772 14.445 8.83492L10 11.7979L5.555 8.83492Z"
        fill={color}
      />
    </svg>
  );
}

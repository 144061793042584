import React, { useState, useEffect } from "react";
import UILIB from "~/Common-Objects/Lib";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
import axios from "~/data/http/axios";
import i18n from "~/i18n";

import SettingsLayout from "../components/settingsLayout";
import Button from "../components/button";
import SettingsSection from "../components/settingsSection";
import TextInput from "../components/textInput";

export default function UserPreferences({ inboxUser = {}, updateInboxUser = () => { } }) {
    const history = useHistory();
    const user = useSelector(state => state.user);
    const { inboxId } = useParams();
    const [loading, setLoading] = useState(true);
    const [inbox, setInbox] = useState(null);
    const [tmpInboxUser, setTmpInboxUser] = useState(inboxUser);
    const [errors, setErrors] = useState({})
    const notifications = UILIB.useNotifications();

    useEffect(() => {
        initialize();
    }, [])

    async function initialize() {
        try {
            const { data } = await axios.get(`/inboxes/${inboxId}`);
            setInbox(data);
            setLoading(false);
        } catch (error) {
            dispatch(handleGenericError(error));
            throw error;
        }
    }

    const updateField = (userField, value) => {
        setErrors({})
        if (userField === "jobTitle") {
            setTmpInboxUser({ ...tmpInboxUser, [userField]: value });
        }
        else {
            setTmpInboxUser({ ...tmpInboxUser, options: { ...tmpInboxUser.options, [userField]: value } });
        }
    }

    const saveChanges = async () => {
        if (tmpInboxUser.jobTitle && tmpInboxUser.jobTitle.length > 255) {
            setErrors({ jobTitle: i18n.t("chat:userPreferences.jobTitle255Chars") })
            return;
        }

        //if we have a notification ticket, we need to request permission
        if (tmpInboxUser.options?.newConvNotification || tmpInboxUser.options?.assignedNotification || tmpInboxUser.options?.assignedActivityNotification || tmpInboxUser.options?.anyConvNotification) {
            await notifications.requestPermission();
        }

        updateInboxUser(tmpInboxUser);
    }

    if (loading) return <UILIB.LoadingIcons className="mar-t24" />;

    const roleText = tmpInboxUser.role == 0 ? "Admin" : tmpInboxUser.role == 1 ? "Agent" : "Visitor";

    return (
        <SettingsLayout
            heading=""
            subheading=""
            backButton={<Button onClick={() => {
                if (history.location.state && history.location.state.prevPath) {
                    return history.push(history.location.state.prevPath)
                }
                history.push("/cp/conversations")
            }
            } variant="secondary" size="s" iconLeft={<UILIB.Icon name="cross-large" />}>{i18n.t("chat:userPreferences.close")}</Button>}
            sideNavList={<ul></ul>}
            breadcrumbs={[]}
            hideLeftNav={true}
        >
            <div class="col-xs-12" style={{ width: "100%", maxWidth: "560px", margin: "auto", marginTop: 80 }}>
                <h1 className="h2 mar-b12">{i18n.t("chat:userPreferences.yourPreferences")}</h1>
                <div style={{ display: "flex", alignItems: "center" }} className="mar-b40">
                    <UILIB.SquareChip className="square-chip-secondary mar-r10">
                        {roleText}
                    </UILIB.SquareChip>
                    <p className="text-grey mar-b0">{inbox.name} {i18n.t("chat:userPreferences.inbox")} | {user.userData.emailAddress}</p>
                </div>

                <div className="mar-b40">
                    <div className="text-heavy">{i18n.t("chat:userPreferences.jobTitle")}</div>
                    <div className="text-grey mar-b5">{i18n.t("chat:userPreferences.jobTitleDesc")}</div>
                    <TextInput
                        className="width-100"
                        placeholder={i18n.t("chat:userPreferences.jobTitlePlaceHolder")}
                        value={tmpInboxUser.jobTitle}
                        onChange={(e) => { updateField("jobTitle", e.target.value) }}
                        error={errors.jobTitle}
                    />
                </div>

                <h4 className="mar-b40">Notifications</h4>

                <div className="row mar-b12" style={{ alignItems: "center" }}>
                    <div className="col-xs-12 col-sm-8">
                        <div className="text-heavy">{i18n.t("chat:userPreferences.newConversations")}</div>
                        <div className="text-grey">{i18n.t("chat:userPreferences.newConversationsDesc")}</div>
                    </div>
                    <div className="col-xs-12 col-sm-4" style={{ display: "flex" }}>
                        <UILIB.CheckBox name="access" checked={tmpInboxUser.options?.newConvEmail} disabled={false} onChange={(e) => { updateField("newConvEmail", e.target.checked) }} outerClassName="mar-r20">{i18n.t("chat:userPreferences.email")}</UILIB.CheckBox>
                        <UILIB.CheckBox name="access" checked={tmpInboxUser.options?.newConvNotification} disabled={false} onChange={(e) => { updateField("newConvNotification", e.target.checked) }}>{i18n.t("chat:userPreferences.browser")}</UILIB.CheckBox>
                    </div>
                </div>
                <div className="row mar-b12" style={{ alignItems: "center" }}>
                    <div className="col-xs-12 col-sm-8">
                        <div className="text-heavy">{i18n.t("chat:userPreferences.assignedConversation")}</div>
                        <div className="text-grey">{i18n.t("chat:userPreferences.assignedConversationDesc")}</div>
                    </div>
                    <div className="col-xs-12 col-sm-4" style={{ display: "flex" }}>
                        <UILIB.CheckBox name="access" checked={tmpInboxUser.options?.assignedEmail} disabled={false} onChange={(e) => { updateField("assignedEmail", e.target.checked) }} outerClassName="mar-r20" >{i18n.t("chat:userPreferences.email")}</UILIB.CheckBox>
                        <UILIB.CheckBox name="access" checked={tmpInboxUser.options?.assignedNotification} disabled={false} onChange={(e) => { updateField("assignedNotification", e.target.checked) }} >{i18n.t("chat:userPreferences.browser")}</UILIB.CheckBox>
                    </div>
                </div>
                <div className="row mar-b12" style={{ alignItems: "center" }}>
                    <div className="col-xs-12 col-sm-8">
                        <div className="text-heavy">{i18n.t("chat:userPreferences.assignedActivity")}</div>
                        <div className="text-grey">{i18n.t("chat:userPreferences.assignedActivityDesc")}</div>
                    </div>
                    <div className="col-xs-12 col-sm-4" style={{ display: "flex" }}>
                        <UILIB.CheckBox name="access" checked={tmpInboxUser.options?.assignedActivityEmail} disabled={false} onChange={(e) => { updateField("assignedActivityEmail", e.target.checked) }} outerClassName="mar-r20" >{i18n.t("chat:userPreferences.email")}</UILIB.CheckBox>
                        <UILIB.CheckBox name="access" checked={tmpInboxUser.options?.assignedActivityNotification} disabled={false} onChange={(e) => { updateField("assignedActivityNotification", e.target.checked) }} >{i18n.t("chat:userPreferences.browser")}</UILIB.CheckBox>
                    </div>
                </div>
                <div className="row" style={{ alignItems: "center" }}>
                    <div className="col-xs-12 col-sm-8">
                        <div className="text-heavy">{i18n.t("chat:userPreferences.anyConversation")}</div>
                        <div className="text-grey">{i18n.t("chat:userPreferences.anyConversationDesc")}</div>
                    </div>
                    <div className="col-xs-12 col-sm-4" style={{ display: "flex" }}>
                        <UILIB.CheckBox name="access" checked={tmpInboxUser.options?.anyConvEmail} disabled={false} onChange={(e) => { updateField("anyConvEmail", e.target.checked) }} outerClassName="mar-r20" >{i18n.t("chat:userPreferences.email")}</UILIB.CheckBox>
                        <UILIB.CheckBox name="access" checked={tmpInboxUser.options?.anyConvNotification} disabled={false} onChange={(e) => { updateField("anyConvNotification", e.target.checked) }} >{i18n.t("chat:userPreferences.browser")}</UILIB.CheckBox>
                    </div>
                </div>
                <div className="row mar-t40" >
                    <div className="col-xs-12">
                        <Button disabled={false} className="mar-r10" variant="primary" size="s" onClick={saveChanges}>{i18n.t("chat:userPreferences.save")}</Button>
                    </div>
                </div>

            </div>

        </SettingsLayout>
    );
}

import React from "react";
import classnames from "classnames";

export default function IllustrationCard({
  img,
  headingTag = "h3",
  heading,
  cta,
  className,
  children,
  ...rest
}) {
  const classes = classnames("illustration-card", className);

  const HeadingTag = headingTag;

  return (
    <div className={classes} {...rest}>
      <div className="illustration-card__illustration">{img}</div>

      <div className="illustration-card__content">
        <HeadingTag className="illustration-card__heading h4 mar-b10">
          {heading}
        </HeadingTag>

        <div className="illustration-card__body">{children}</div>

        <div className="illustration-card__cta">{cta}</div>
      </div>
    </div>
  );
}

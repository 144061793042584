import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import Disconnect from '../genericCp/disconnect';
import UntrackableCampaigns from './untrackableCampaigns';
import { SupportedApps } from '../../../../campaigns/social/create/shared';
import queryString from 'query-string'

const connectedApps = [
    { service: "facebook", appId: 77, connected: false, url: 'https://www.facebook.com/business/tools/ads-manager' },
    { service: "google", appId: 75, connected: false, url: 'https://ads.google.com/um/StartNow' },
    { service: "linkedin", appId: 76, connected: false, url: 'https://www.linkedin.com/campaignmanager' }
];

@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, account: store.accountMaster }
})
class GenericAdsConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appId: 0,
            loading: true,
            loadingAccounts: true,
            appData: {},
            appBinding: {},
            adAccounts: [],
            adAccountsTable: [],
            connectedApp: "",
            processing: false,
            deleting: false,
            campaignStats: {
                active: 0,
                inactive: 0
            },
            showUntrackable: false,
            campaigns: [],
            hasAds: false,
            socials: []
        };
        this.getApp = this.getApp.bind(this);
        this.getAppBinding = this.getAppBinding.bind(this);
        this.getAdAccounts = this.getAdAccounts.bind(this);
        this.getCampaigns = this.getCampaigns.bind(this);
        this.generateAccountsTable = this.generateAccountsTable.bind(this);
        this.popDisconnect = this.popDisconnect.bind(this);
        this.disconnectAdAccount = this.disconnectAdAccount.bind(this);
        this.connectAdAccount = this.connectAdAccount.bind(this);
        this.refreshSocials = this.refreshSocials.bind(this);
        this.query = queryString.parse(window.location.search)
    }

    componentDidMount() {

        if (!this.props.match.params.appId) {
            this.props.history.push('/cp/company/integrations/')
            return;
        }
        this.setState({ appId: this.props.match.params.appId }, this.getApp);
    }
    async getApp() {
        try {
            let response = await axios.get("/application/" + this.state.appId)
            this.setState({
                appData: response.data.Application
            }, this.getAppBinding)
        }
        catch (err) {
            this.props.history.push('/cp/company/integrations/')
        }
    }
    async getAppBinding() {
        try {
            let response = await axios.get("/application/account/app/" + this.state.appData.id + '/' + (this.query.id || ''))
            this.setState({
                appBinding: response.data.Application,
            }, this.getAdAccounts)
        }
        catch (err) {
            console.log("3")
            this.props.history.push('/cp/company/integrations/')
        }
    }
    async getAdAccounts() {
        let connectedApp = connectedApps.find(f => f.appId == this.state.appId)
        if (!connectedApp) {
            return this.props.history.push('/cp/company/integrations/');
        }
        try {
            const res = await axios.get(`/application/${connectedApp.service}/ads/accounts/` + (this.query.id || ''))
            let adAccounts = res.data.map(row => {
                row.selected = row.selected;
                return row
            })
            this.setState({ connectedApp, adAccounts, hasAds: true }, this.getCampaigns)
        } catch (e) {
            // must not have ads, but might have socials
            this.setState({ connectedApp, hasAds: false }, this.refreshSocials)
        }
    }

    async refreshSocials() {
        let socialName = []
        if (this.state.appId == 77) socialName = ['facebook', 'instagram']
        if (this.state.appId == 76) socialName = ['linkedin']
        if (this.state.appId == 85) socialName = ['twitter']
        if (socialName?.length) {
            for (const name of socialName) {
                try {
                    await axios.get('/application/' + name + '/sites/' + (this.query.id || ''))
                } catch (e) { }
            }
        }
        this.getSocials()
    }

    async getSocials() {
        const socials = (await axios.get('/social?followers=true')).data
        this.setState({
            socials: socials.filter(s => {
                if (this.query?.id) return s.ApplicationId === this.state.connectedApp.appId && s.AccountApplicationId === parseInt(this.query.id)
                return s.ApplicationId === this.state.connectedApp.appId
            }),
            loading: false,
            loadingAccounts: false
        })
    }

    async getCampaigns() {
        let campaigns = await axios.get('/ads/campaigns/?appId=' + this.state.appId)
        campaigns = campaigns.data;
        let active = campaigns.filter(f => f.utmApplied).length;
        let inactive = campaigns.filter(f => !f.utmApplied).length;
        let campaignStats = { active, inactive }
        this.setState({ campaignStats, campaigns: campaigns }, () => {
            this.generateAccountsTable();
        });
    }
    async generateAccountsTable() {
        try {
            const tableData = this.state.adAccounts.map((row, index) => {
                return {
                    name: {
                        headerValue: "Account Name",
                        value: <strong>{row.name}</strong>,
                        orderBy: false
                    },
                    id: {
                        headerValue: "Account Id",
                        value: row.link ? <div style={{ display: "flex" }}>
                            <a className="mar-r5" href={row.link} target="_blank">{row.id}</a>
                            <UILIB.Icons icon="external" />
                        </div> : row.id,
                        orderBy: false,
                        width: 250
                    },
                    status: {
                        headerValue: "Account Status",
                        value: row.enabled ? <UILIB.SquareChip value="Active" className="square-chip-green" /> : <UILIB.SquareChip value="In-Active" className="square-chip-red" />,
                        orderBy: false,
                        align: "center"
                    },
                    Connected: {
                        headerValue: "Connected",
                        value: row.selected ? <UILIB.Icons icon="tickCircle" /> : <UILIB.Icons icon="crossCircle" color="red" />,
                        orderBy: false,
                        align: "center"
                    },
                    options: {
                        headerValue: " ",
                        value: row.selected ?
                            <UILIB.Button text="Disconnect" className="button-sml button-red" iconLeft={<UILIB.Icons icon="cross" />} onClick={() => { this.disconnectAdAccount(row) }} />
                            : <UILIB.Button text="Connect" className="button-sml button-green" iconRight={<UILIB.Icons icon="tick" />} onClick={() => { this.connectAdAccount(row) }} />,
                        width: 10
                    }
                }
            })

            this.setState({ adAccountsTable: tableData }, this.refreshSocials)
        }
        catch (err) {
            console.log(err);
            this.props.history.push('/cp/company/integrations/')
        }
    }
    async connectAdAccount(adAccount) {
        try {
            this.setState({ loadingAccounts: true })
            await axios.post(`/application/${this.state.connectedApp.service}/ads/accounts`, { accounts: [adAccount] });
            this.getAdAccounts();
        } catch (e) {
            console.log(e);
            this.getAdAccounts();
        }
    }
    async disconnectAdAccount(adAccount) {
        try {
            this.setState({ loadingAccounts: true })
            await axios.delete(`/application/${this.state.connectedApp.service}/ads/accounts/${adAccount.id}`)
            this.getAdAccounts();
        }
        catch (err) {
            console.log(err);
            this.getAdAccounts();
        }
    }
    popDisconnect() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <Disconnect appName={this.state.appData.appName} goDisconnect={() => {
            return axios.delete('/accountMaster/application/' + this.state.appBinding.id).then(res => {
                this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null))
                this.props.history.push('/cp/company/integrations')
            })
        }} />, true, "500px"));
    }

    render() {

        if (this.state.loading) return <div>
            <UILIB.LoadingIcons iconType="2" className="mar-b15" />
            <div className="text-center">Loading the App</div>
        </div>

        let hasActiveAccounts = this.state.adAccounts.filter(f => f.selected).length;
        let hideStandard = false;
        if (this.state.showUntrackable) hideStandard = true;

        let isPopupFromSocial = !!window.opener;
        if (isPopupFromSocial) {
            if (window.opener.location?.pathname && window.opener.location.pathname.indexOf('/cp/campaigns/add/social/') === -1) {
                isPopupFromSocial = false;
            }
        }

        if (isPopupFromSocial) {
            return <div>

                <UILIB.Row className="middle-xs mar-b10">
                    <UILIB.Column xs={12} sm={12} md={8} lg={8} className="col-md-offset-2">
                        <UILIB.Paper>
                            <div className="text-center text-lg text-heavy">
                                You can close this window now
                            </div>
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
            </div>
        }

        return <div>

            <UILIB.Row className="middle-xs mar-b10">
                <UILIB.Column xs={12} sm={12} md={8} lg={8} className="col-md-offset-2">
                    <UILIB.Paper>
                        <div className="quickFlex" style={{ justifyContent: "space-between" }}>
                            <div className="quickFlex">
                                <div className="integration-avatar rounded" style={{ backgroundImage: "url(" + this.state.appData.appLogoUri + ")" }}></div>
                                <p className="no-marg pad-left text-lg">{this.state.appData.appName}. </p>
                            </div>
                            <div>
                                <UILIB.Button className="button-sml grey" onClick={this.popDisconnect}>Disconnect</UILIB.Button>
                            </div>
                        </div>

                        <hr className="mar-t25 mar-b25" />

                        {!!this.props.account.accountMaster.beta?.ads && <>
                            {this.state.showUntrackable && <UntrackableCampaigns campaigns={this.state.campaigns.filter(f => !f.utmApplied)} exit={() => { this.setState({ showUntrackable: false }) }} />}
                            {!hideStandard && <>
                                <h4 className="mar-b15">Your Advertising Accounts</h4>
                                <div className="mar-b5">Here's a list of the {this.state.appData.appName} advertising account we found you have access to.</div>
                                <div className="mar-b25">Please choose the accounts that you would like to connect to {this.props.siteMaster.siteName}</div>
                                {(!this.state.adAccounts.length) && <UILIB.WarningHolder style={{ maxWidth: "700px", width: "100%", margin: "0 auto" }}>
                                    <div className="mar-b10" style={{ fontWeight: "bold" }}>
                                        You don't appear to have any {this.state.connectedApp.service} advertising accounts at the moment.
                                    </div>
                                    <div className="mar-b25">Please create a new {this.state.connectedApp.service} advertising account.</div>
                                    {this.state.connectedApp.url ? <UILIB.Button iconRight={<UILIB.Icons icon="external" />} text="Create an Account" onClick={() => window.open(this.state.connectedApp.url, '_blank')} /> : ''}
                                </UILIB.WarningHolder>}

                                {(this.state.adAccounts.length > 0) && <>
                                    {this.state.loadingAccounts && <UILIB.LoadingIcons iconType="2" />}
                                    {!this.state.loadingAccounts && <>
                                        <UILIB.DataTable1
                                            noResultsTxt={""}
                                            tableData={this.state.adAccountsTable}
                                            dataUpdater={() => { }}
                                            loadingData={false}
                                            sortedColumn={""}
                                            sortedDirection={""}
                                            width="100%"
                                            isLoading={false}
                                            hasCheckBoxes={false}
                                            className="mar-b25"
                                        />
                                    </>
                                    }
                                </>}
                            </>}

                        </>}

                        {!!this.state.socials.length && <>
                            {!!this.props.account.accountMaster.beta?.ads && <hr className="mar-t25 mar-b25" />}
                            <h4 className="mar-b15">Your Social Accounts</h4>
                            <div className="mar-b5">Here's a list of the {this.state.appData.appName} social accounts we found you have access to.</div>
                            <UILIB.DataTable1
                                tableData={this.state.socials.map(row => {
                                    const app = SupportedApps.find(a => a.code === row.type)
                                    const obj = {
                                        icon: {
                                            headerValue: " ",
                                            value: <UILIB.Avatar src={row.image} style={{ height: 30, with: 30 }} />
                                        },
                                        provider: {
                                            headerValue: 'Provider',
                                            value: <UILIB.Icons icon={app.icon} style={{ height: 30, width: 30 }} />,
                                            align: 'center'
                                        },
                                        name: {
                                            headerValue: "Name",
                                            value: row.name
                                        },
                                        followers: {
                                            headerValue: "Followers",
                                            value: row.SocialHistory ? row.SocialHistory.followers : <UILIB.SquareChip>Sync Pending</UILIB.SquareChip>
                                        },
                                        options: {
                                            headerValue: ' ',
                                            value: <UILIB.Button className="button-sml button-primary" onClick={() => this.props.history.push('/cp/company/integrations/apps/social/' + row.id)}>History</UILIB.Button>
                                        }
                                    }
                                    if (row.type !== 'INSTAGRAM' && row.type !== 'FACEBOOK') delete obj.provider
                                    return obj
                                })}
                            />
                        </>}
                    </UILIB.Paper>

                    {(hideStandard == false && hasActiveAccounts > 0) && <>
                        <UILIB.Paper>
                            <h4 className="mar-b15">You're in business!</h4>
                            <div>We are now monitoring your {this.state.appData.appName} account and we are tracking your existing ad campaigns.</div>
                            <ul>
                                <li className="mar-b10">Whenever you add a new campaign, it will also be automatically tracked.</li>
                                <li className="mar-b10">You can now see your Ad Campaigns in the normal <a onClick={() => { this.props.history.push("/cp/campaigns") }}>Campaigns</a> area.</li>
                                <li className="mar-b10">And if you have <a onClick={() => { this.props.history.push('/cp/trackers') }}>Site Tracking</a> enabled you will also see visitors being attributed to your ad campaigns when they click through from one of your ads.</li>
                            </ul>
                        </UILIB.Paper>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={6}>
                                <UILIB.Paper className="paper-primary text-center">
                                    <h4 className="mar-b15">Campaigns Tracked</h4>
                                    <div className="text-lg2 mar-b15">{this.state.campaignStats.active}</div>
                                    <div className="mar-b25">We are tracking {this.state.campaignStats.active} campaigns, from your {hasActiveAccounts} {hasActiveAccounts > 1 ? "accounts" : "account"}</div>
                                    <UILIB.Button className="outline button-md" text="View" onClick={() => { this.props.history.push('/cp/campaigns/60') }} />
                                </UILIB.Paper>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6}>
                                <UILIB.Paper className="paper-orange text-center">
                                    <h4 className="mar-b15">Untrackable</h4>
                                    <div className="text-lg2 mar-b15">{this.state.campaignStats.inactive}</div>
                                    <div className="mar-b25">We are unable to track {this.state.campaignStats.inactive} campaigns, from your {hasActiveAccounts} {hasActiveAccounts > 1 ? "accounts" : "account"}</div>
                                    <UILIB.Button className="outline button-md" text="Find out why" onClick={() => { this.setState({ showUntrackable: true }) }} />
                                </UILIB.Paper>
                            </UILIB.Column>
                        </UILIB.Row>


                    </>
                    }
                </UILIB.Column>

            </UILIB.Row>

        </div >
    };
}

export default GenericAdsConfig;
import React from 'react'

export default function IconCloudUpload({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M5.49998 13.0001C4.6045 12.9989 3.74354 12.6545 3.09425 12.0378C2.44496 11.4212 2.05674 10.5791 2.00946 9.68484C1.96219 8.79061 2.25946 7.91228 2.84011 7.23057C3.42076 6.54887 4.24062 6.11565 5.13098 6.02007C4.86881 4.99195 5.0258 3.90181 5.5674 2.98944C6.10901 2.07708 6.99087 1.41723 8.01898 1.15507C9.04709 0.892899 10.1372 1.04989 11.0496 1.59149C11.962 2.1331 12.6218 3.01495 12.884 4.04307C13.4954 3.95793 14.1179 3.99951 14.7125 4.16523C15.3072 4.33095 15.8615 4.61726 16.3407 5.00635C16.82 5.39544 17.2142 5.87898 17.4986 6.42691C17.783 6.97484 17.9516 7.57543 17.994 8.19132C18.0363 8.80721 17.9515 9.42523 17.7448 10.0069C17.5381 10.5886 17.2138 11.1216 16.7923 11.5726C16.3708 12.0237 15.861 12.3832 15.2946 12.6287C14.7282 12.8743 14.1173 13.0007 13.5 13.0001H11V9.41307L12.293 10.7061C12.4816 10.8882 12.7342 10.989 12.9964 10.9867C13.2586 10.9845 13.5094 10.8793 13.6948 10.6939C13.8802 10.5085 13.9854 10.2577 13.9877 9.99547C13.9899 9.73327 13.8891 9.48067 13.707 9.29207L10.707 6.29207C10.5195 6.10459 10.2651 5.99928 9.99998 5.99928C9.73481 5.99928 9.48051 6.10459 9.29298 6.29207L6.29298 9.29207C6.11082 9.48067 6.01003 9.73327 6.0123 9.99547C6.01458 10.2577 6.11975 10.5085 6.30516 10.6939C6.49057 10.8793 6.74138 10.9845 7.00358 10.9867C7.26577 10.989 7.51838 10.8882 7.70698 10.7061L8.99998 9.41407V13.0001H5.49998Z" fill={fillColor}/>
            <path d="M9 13.0001H11V18.0001C11 18.2653 10.8946 18.5196 10.7071 18.7072C10.5196 18.8947 10.2652 19.0001 10 19.0001C9.73478 19.0001 9.48043 18.8947 9.29289 18.7072C9.10536 18.5196 9 18.2653 9 18.0001V13.0001Z" fill={fillColor}/>
        </svg>
    )
}
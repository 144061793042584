import React, { forwardRef } from 'react'
import UILIB from '../../Lib';

const Paper = forwardRef((props, ref) => {
  let theClassName = "";
  if (props.className) {
    theClassName = props.className;
  }

  return <div ref={ref} className={"paper-class " + theClassName} style={props.style} onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} id={props.id} alt={props.alt} title={props.title}>
    {(props.isLoading || props.loadingOverlay) && <div className="paper-overlay">
      <UILIB.LoadingIcons iconType="2" />
    </div>}
    {!!props.iconRight && <div className={'paper-icon-right' + (props.iconRightFixed ? ' paper-icon-right-fixed' : '')} onClick={props.iconRightClick}>{props.iconRight}</div>}
    {!props.isLoading && props.children}
  </div>
})

export default Paper
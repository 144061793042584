import React from "react";
import UILIB from "~/Common-Objects/Lib";
import i18n from "~/i18n";

export default function OtherFeaturesPanel() {
  function featureFactory(translationKey, ctaHref) {
    return {
      heading: i18n.t(
        `dashboard:gettingStarted.otherFeatures.${translationKey}.heading`
      ),
      body: i18n.t(
        `dashboard:gettingStarted.otherFeatures.${translationKey}.body`
      ),
      cta: i18n.t(
        `dashboard:gettingStarted.otherFeatures.${translationKey}.cta`
      ),
      ctaHref,
    };
  }

  const otherFeatures = [
    featureFactory("capture", "/cp/forms"),
    featureFactory("automate", "/cp/automation"),
    featureFactory("sendTransactions", "/cp/transactions"),
    featureFactory("siteTracking", "/cp/trackers"),
  ];

  return (
    <UILIB.Paper className="frontpage-other-features-panel">
      <h2 className="h4 mar-b10">
        {i18n.t("dashboard:gettingStarted.otherFeatures.heading")}
      </h2>

      <ul className="faux-list">
        {otherFeatures.map(({ heading, body, cta, ctaHref }) => {
          return (
            <li key={heading}>
              <div>
                <h3 className="text-md fw-medium mar-b5">{heading}</h3>
                <p className="color-secondary mar-b0">{body}</p>
              </div>

              <UILIB.Button to={ctaHref}>{cta}</UILIB.Button>
            </li>
          );
        })}
      </ul>
    </UILIB.Paper>
  );
}

import React from 'react';
import UILIB from '~/Common-Objects/Lib'

export default class AbTestDelete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return <div>
            <h4 className="mar-b25">
                Delete Test
            </h4>
            <div className="mar-b25">
                Are you sure you want to delete this test?
            </div>
            <UILIB.Button text="Delete it" onClick={() => { this.props.deleteCampaign() }} />
        </div>
    }
}

import React, { useEffect, useState } from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import textFunctions from '~/Classes/textFunctions';

async function getTemplate(id) {
    const result = await axios.get('/template/' + id)
    return result.data.template
}

export default function ({ from, timestamp, content, size = "", templateId, refresh }) {
    const [template, setTemplate] = useState(null)

    useEffect(() => {
        if (templateId) {
            getTemplate(templateId).then(setTemplate)
        }
    }, [refresh, templateId])

    if (content === null && !template) return <UILIB.LoadingIcons iconType="2" />

    let html = ((template ? textFunctions.checkForHtml(template.templateHTML) : textFunctions.checkForHtml(content)) || '').replace(/\n/gi, '<br />')

    return (
        <div className={"smsView " + size}>
            <div className="smsHeader">
                <div className="back">
                    <UILIB.Icons icon="arrowLeft" color="grey" style={{ height: 20, width: 20 }} />
                </div>
                <div className='title'>
                    {from}
                </div>
            </div>
            <div className="smsMessageHolder">
                <div className="smsMessage">
                    <div className="smsAvatar">
                        <UILIB.Icons icon="person" circle={true} style={{ width: 32, height: 32 }} />
                    </div>
                    <div className={"smsContent " + size} dangerouslySetInnerHTML={{ __html: html }}></div>
                    <div className="smsTimestamp">{timestamp}</div>
                </div>
                <div className="smsInput">Message</div>
            </div>
        </div>
    )
}
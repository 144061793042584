import React from 'react';

export default class MoreOptions extends React.Component {
    render() {
        return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 6 26" xmlSpace="preserve" {...this.props}>
            <circle className="svg-fill primary" cx="3" cy="3" r="2" />
            <circle className="svg-fill primary" cx="3" cy="23" r="2" />
            <circle className="svg-fill primary" cx="3" cy="13" r="2" />
        </svg>
    }
}
import React from 'react'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import * as user from '~/data/actions/userActions'; //now we can use user actions here
import { connect } from 'react-redux';
import PermissionChecker from '~/Classes/permissions'
import UILIB from '../../../../../Common-Objects/Lib'; ''
import i18n from '~/i18n';


@connect((store) => {
  return { siteMaster: store.siteMaster, browser: store.browser, user: store.user, permissionStore: store.permission, accountMaster: store.accountMaster }
})

export default class BBMobileNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: false
    }

    this.toggleNav = this.toggleNav.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.setRedux = this.setRedux.bind(this);
    this.goLogout = this.goLogout.bind(this);
    this.goMyAccount = this.goMyAccount.bind(this);
    this.goMyCompany = this.goMyCompany.bind(this);
  }

  toggleNav() {
    var onOff = false;
    if (this.state.showNav === false) {
      onOff = true;
    }
    else {
      onOff = false;
    }
    this.setState({ showNav: onOff });
  }

  goLogout() {
    this.toggleNav();
    this.props.dispatch(user.logoutAndClear());
  }

  goMyAccount() {
    this.toggleNav();
    this.props.history.push('/cp/account');
  }
  goMyCompany() {
    this.toggleNav();
    this.props.history.push('/cp/company');
  }
  changeTab(tabID) {

    this.toggleNav();
    this.setRedux(tabID);
    switch (tabID) {
      case 0:
        this.props.history.push('/cp/home');
        break;
      case 1:
        this.props.history.push('/cp/campaigns');
        break;
      case 2:
        this.props.history.push('/cp/subscribers');
        break;
      case 3:
        this.props.history.push('/cp/templates');
        break;
      case 4:
        this.props.history.push('/cp/transactions');
        break;
      case 5:
        this.props.history.push('/cp/automation');
        break;
      case 6:
        this.props.history.push('/cp/trackers');
        break;
      case 7:
        this.props.history.push('/cp/forms');
        break;
      case 8:
        this.props.history.push('/cp/conversations/inbox');
        break;
    }
  }

  setRedux(tabIndex) {
    this.props.dispatch(siteMaster.changeMainNavTab(tabIndex));
  }


  render() {

    const isMpz = this.props.siteMaster.siteId == 1;


    return <div>
      <UILIB.Icons icon="menu" style={{ height: 24, width: 24 }} color="#2B2F41" onClick={this.toggleNav} />

      <div className={"mobileNavBG " + (this.state.showNav ? 'open' : '')}>
        <UILIB.Icons className="mobileNavClose" onClick={this.toggleNav} icon="cross" />

        <div className="mobileNavHolder">
          <div className="mobileNavLink" onClick={() => { this.changeTab(0) }}><div className="mobile-nav-text">{i18n.t('nav:dashboard')}</div><UILIB.Icons icon="arrowRight" className="mobile-nav-icon" /></div>
          {PermissionChecker('campaigns', this.props.permissionStore.permissions, "read") && <div className="mobileNavLink" onClick={() => { this.changeTab(1) }}><div className="mobile-nav-text">{i18n.t('nav:campaigns')}</div><UILIB.Icons icon="arrowRight" className="mobile-nav-icon" /></div>}
          {PermissionChecker('subscribers', this.props.permissionStore.permissions, "read") && <div className="mobileNavLink" onClick={() => { this.changeTab(2) }}><div className="mobile-nav-text">{i18n.t('nav:subscribers')}</div><UILIB.Icons icon="arrowRight" className="mobile-nav-icon" /></div>}
          {PermissionChecker('templates', this.props.permissionStore.permissions, "read") && <div className="mobileNavLink" onClick={() => { this.changeTab(3) }}><div className="mobile-nav-text">{i18n.t('nav:templates')}</div><UILIB.Icons icon="arrowRight" className="mobile-nav-icon" /></div>}
          {PermissionChecker('automations', this.props.permissionStore.permissions, "read") && <div className="mobileNavLink" onClick={() => { this.changeTab(5) }}><div className="mobile-nav-text">{i18n.t('nav:automation')}</div><UILIB.Icons icon="arrowRight" className="mobile-nav-icon" /></div>}
          {PermissionChecker('subscribers', this.props.permissionStore.permissions, "read") && <div className="mobileNavLink" onClick={() => { this.changeTab(7) }}><div className="mobile-nav-text">{i18n.t('nav:forms')}</div><UILIB.Icons icon="arrowRight" className="mobile-nav-icon" /></div>}

          {isMpz && PermissionChecker('subscribers', this.props.permissionStore.permissions, "read") && <div className="mobileNavLink" onClick={() => { this.changeTab(8) }}><div className="mobile-nav-text">Conversations <UILIB.SquareChip className="mar-l5 square-chip-green">Beta</UILIB.SquareChip></div><UILIB.Icons icon="arrowRight" className="mobile-nav-icon" /></div>}
        </div>

        <div className="mobileNavHolder">

          {PermissionChecker('company', this.props.permissionStore.permissions, "read") && <div className="mobileNavLink" onClick={() => { this.changeTab(4) }}><div className="mobile-nav-text">{i18n.t('nav:transactions')}</div><UILIB.Icons icon="arrowRight" className="mobile-nav-icon" /></div>}
          {PermissionChecker('company', this.props.permissionStore.permissions, "read") && <div className="mobileNavLink" onClick={() => { this.changeTab(6) }}><div className="mobile-nav-text">Site Tracking</div><UILIB.Icons icon="arrowRight" className="mobile-nav-icon" /></div>}
          {!this.props.siteMaster.readOnlyUsers && <div className="mobileNavLink" onClick={() => { this.goMyAccount() }}><div className="mobile-nav-text">{i18n.t('nav:myProfile')}</div><UILIB.Icons icon="arrowRight" className="mobile-nav-icon" /></div>}
          {PermissionChecker('company', this.props.permissionStore.permissions, "read") && <div className="mobileNavLink" onClick={() => { this.goMyCompany() }}><div className="mobile-nav-text">{i18n.t('nav:myCompany')}</div><UILIB.Icons icon="arrowRight" className="mobile-nav-icon" /></div>}
        </div>
        <div className="mobileNavLogout">
          <div className="mobileNavLink" onClick={() => { this.goLogout() }}>
            <div className="mobile-nav-text">{i18n.t('nav:logout')}</div>
            <UILIB.Icons icon="logout" className="mobile-nav-icon" />
          </div>
        </div>
      </div>
    </div>

  }
}

import React, { useEffect, useState, useReducer } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import TextInput from "../../../components/textInput";
import Button from "../../../components/button";
import * as styles from "./index.module.scss";
import AddEditPromptDialog from "./components/addEditPromptDialog";
import DeletePromptDialog from "./components/deletePromptDialog";
import Header from "../../../components/header";
import axios from "~/data/http/axios";
import i18n from "~/i18n";

export default function WebchatChannelPrompts({ channel }) {
  const dispatch = useDispatch();

  const [prompts, setPrompts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, updateTableData] = useReducer((state, action) => {
    return formatTableDataReducer(state, action);
  }, []);
  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    updateTableData(prompts);
  }, [prompts]);

  useEffect(() => {
    getPrompts();
  }, [channel]);
  useEffect(() => {
    const filteredData = prompts.filter((prompt) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      if (prompt.name.toLowerCase().includes(lowerCaseSearchTerm)) {
        return true;
      }

      return false;
    });

    updateTableData(filteredData);
  }, [searchTerm]);


  async function getPrompts() {
    try {
      const { data } = await axios.get(`/inboxes/${channel.InboxId}/channel/${channel.id}/prompt`);
      setPrompts(data);
    } catch (error) {
      dispatch(handleGenericError(error));
    }
  }

  async function deletePrompt(prompt) {
    try {
      await axios.delete(`/inboxes/${channel.InboxId}/channel/${channel.id}/prompt/${prompt.id}`);
      getPrompts();
      setDrawer(null);
    } catch (error) {
      dispatch(handleGenericError(error));
    }
  }

  async function toggleStatus(prompt) {
    try {
      let newStatus = prompt.status ? 0 : 1;
      await axios.put(`/inboxes/${channel.InboxId}/channel/${channel.id}/prompt/${prompt.id}/status`, { status: newStatus });
      alterSnackbar(dispatch, { message: i18n.t("chat:channel.chat.prompts.savedPrompt") });
      getPrompts();
    } catch (error) {
      dispatch(handleGenericError(error));
    }
  }


  async function addedUpdatedPrompt(prompt) {
    getPrompts();
    setDrawer(null);
  }

  function openPromptDialog(prompt) {
    const newDrawer = <AddEditPromptDialog
      isOpen={true}
      selectedPrompt={prompt}
      onOpenChange={(isOpen) => { if (!isOpen) setDrawer(null) }}
      promptToEdit={prompt}
      channel={channel}
      onChanged={addedUpdatedPrompt}
    />
    setDrawer(newDrawer);
  }

  function openDeletePromptDialog(prompt) {
    const newDrawer = <DeletePromptDialog
      promptToDelete={prompt}
      isOpen={true}
      onSubmit={deletePrompt}
      onOpenChange={(isOpen) => { if (!isOpen) setDrawer(null) }}
    />
    setDrawer(newDrawer);
  }

  function formatTableDataReducer(state, action) {
    return action.map((prompt) => {
      const { name, locations, status, locationType } = prompt;

      const locationsToRender = locations.slice(0, 3);
      const otherLocationsCount = locations.length - locationsToRender.length;

      return {
        name: {
          headerValue: i18n.t("chat:channel.chat.prompts.name"),
          value: <span className="fw-medium">{name}</span>,
          value: (
            <button
              className={styles.tableName}
              onClick={() => {
                openPromptDialog(prompt);
              }}
            >
              {name}
            </button>
          ),
        },
        locations: {
          headerValue: i18n.t("chat:channel.chat.prompts.locations"),
          value: (
            <ul className={classnames(styles.tableLocations, "faux-list")}>
              {(locationType == 0) && <li>
                <UILIB.Badge>{i18n.t("chat:channel.chat.prompts.anyPage")}</UILIB.Badge>
              </li>}
              {(locationType == 1) && <>
                {locationsToRender.map((location, index) => {
                  return (
                    <li key={index}>
                      <UILIB.Badge>{location}</UILIB.Badge>
                    </li>
                  );
                })}

                {otherLocationsCount > 0 && (
                  <li>
                    <UILIB.Badge>+{otherLocationsCount}</UILIB.Badge>
                  </li>
                )}
              </>
              }
            </ul>
          ),
        },
        options: {
          headerValue: "",
          value: (
            <div className={styles.tableActions}>
              <UILIB.Badge color={status ? "teal" : undefined}>
                {status ? i18n.t("chat:channel.chat.prompts.active") : i18n.t("chat:channel.chat.prompts.inactive")}
              </UILIB.Badge>

              <UILIB.DropdownMenu.Root>
                <UILIB.DropdownMenu.Trigger asChild>
                  <Button
                    aria-label="Additional options"
                    variant="ghost"
                    size="s"
                    iconOnly
                  >
                    <UILIB.Icon name="dots-vertical" />
                  </Button>
                </UILIB.DropdownMenu.Trigger>

                <UILIB.DropdownMenu.Portal>
                  <UILIB.DropdownMenu.Content align="end">

                    <UILIB.DropdownMenu.Item
                      onSelect={() => {
                        toggleStatus(prompt)
                      }}
                    >
                      {status ? <>{i18n.t("chat:channel.chat.prompts.pause")}</> : <>{i18n.t("chat:channel.chat.prompts.activate")}</>}
                    </UILIB.DropdownMenu.Item>

                    <UILIB.DropdownMenu.Item
                      variant="danger"
                      onSelect={() => {
                        openDeletePromptDialog(prompt)
                      }}
                    >
                      {i18n.t("chat:channel.chat.prompts.delete")}
                    </UILIB.DropdownMenu.Item>
                  </UILIB.DropdownMenu.Content>
                </UILIB.DropdownMenu.Portal>
              </UILIB.DropdownMenu.Root>
            </div>
          ),
        },
      };
    });
  }

  return (
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
      <Header type="webchat" option="prompts" />
      <div className={styles.topRow}>
        <form>
          <TextInput
            value={searchTerm}
            placeholder={i18n.t("chat:channel.chat.prompts.searchPlaceholder")}
            aria-label="Search for a prompt"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </form>

        <Button
          size="s"
          iconLeft={<UILIB.Icon name="plus" />}
          onClick={() => {
            openPromptDialog(null);
          }}
        >
          {i18n.t("chat:channel.chat.prompts.add")}
        </Button>
      </div>

      {tableData.length > 0 && <UILIB.DataTable1 tableData={tableData} />}


      {drawer}

    </div>
  );
}

import React from 'react'

export default class IconsArrowDown extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.6" d="M4.24407 5.12713C4.64284 5.58759 5.35716 5.58759 5.75593 5.12713L8.76318 1.65465C9.32406 1.00701 8.864 0 8.00725 0H1.99275C1.136 0 0.675944 1.00701 1.23682 1.65465L4.24407 5.12713Z" fill={color} />
        </svg>



    }
}

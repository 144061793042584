import React from 'react'

export default function IconsAvatars2({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 6.00006C9 6.79571 8.68393 7.55877 8.12132 8.12138C7.55871 8.68399 6.79565 9.00006 6 9.00006C5.20435 9.00006 4.44129 8.68399 3.87868 8.12138C3.31607 7.55877 3 6.79571 3 6.00006C3 5.20441 3.31607 4.44135 3.87868 3.87874C4.44129 3.31613 5.20435 3.00006 6 3.00006C6.79565 3.00006 7.55871 3.31613 8.12132 3.87874C8.68393 4.44135 9 5.20441 9 6.00006ZM17 6.00006C17 6.39403 16.9224 6.78413 16.7716 7.14811C16.6209 7.51209 16.3999 7.84281 16.1213 8.12138C15.8427 8.39996 15.512 8.62094 15.1481 8.7717C14.7841 8.92246 14.394 9.00006 14 9.00006C13.606 9.00006 13.2159 8.92246 12.8519 8.7717C12.488 8.62094 12.1573 8.39996 11.8787 8.12138C11.6001 7.84281 11.3791 7.51209 11.2284 7.14811C11.0776 6.78413 11 6.39403 11 6.00006C11 5.20441 11.3161 4.44135 11.8787 3.87874C12.4413 3.31613 13.2044 3.00006 14 3.00006C14.7956 3.00006 15.5587 3.31613 16.1213 3.87874C16.6839 4.44135 17 5.20441 17 6.00006ZM12.93 17.0001C12.976 16.6731 13 16.3401 13 16.0001C13.0023 14.4289 12.4737 12.9031 11.5 11.6701C12.2601 11.2312 13.1223 11.0002 14 11.0002C14.8776 11.0002 15.7399 11.2312 16.4999 11.67C17.26 12.1089 17.8912 12.74 18.3301 13.5001C18.7689 14.2602 19 15.1224 19 16.0001V17.0001H12.93ZM6 11.0001C7.32608 11.0001 8.59785 11.5268 9.53553 12.4645C10.4732 13.4022 11 14.674 11 16.0001V17.0001H1V16.0001C1 14.674 1.52678 13.4022 2.46447 12.4645C3.40215 11.5268 4.67392 11.0001 6 11.0001Z" fill={fillColor} />
        </svg>

    )
}
import React, { useState, useReducer } from "react";
import { useDispatch } from "react-redux";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import Button from "../../../components/button";
import * as styles from "./index.module.scss";
import TextInput from "../../../components/textInput";
import Header from "../../../components/header";
import i18n from "~/i18n";

export default function WebchatChannelWelcomeMessages({
  channel,
  onUpdateChannel,
}) {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [values, updateValues] = useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    {
      welcomeEmailMessage: channel.settings.welcomeEmailMessage || "",
      emailPromptMessage: channel.settings.emailPromptMessage || "",
      invalidEmailMessage: channel.settings.invalidEmailMessage || "",
      existingMessage: channel.settings.existingMessage || "",
    }
  );

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitting(true);

    const data = { settings: values };

    try {
      await onUpdateChannel(data);
      dispatch(alterSnackbar(true, i18n.t("chat:channel.chat.welcomeMessages.saved"), "success"));
    } catch (error) {
      dispatch(handleGenericError(error));
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
      <Header type="webchat" option="welcome-messages" />
      <form className={styles.root} onSubmit={handleSubmit}>
        <TextInput
          label={i18n.t("chat:channel.chat.welcomeMessages.welcome")}
          description={i18n.t("chat:channel.chat.welcomeMessages.welcomeDesc")}
          value={values.welcomeEmailMessage}
          onChange={(e) => updateValues({ welcomeEmailMessage: e.target.value })}
        />

        <TextInput
          label={i18n.t("chat:channel.chat.welcomeMessages.email")}
          description={i18n.t("chat:channel.chat.welcomeMessages.emailDesc")}
          value={values.emailPromptMessage}
          onChange={(e) => updateValues({ emailPromptMessage: e.target.value })}
        />

        <TextInput
          label={i18n.t("chat:channel.chat.welcomeMessages.invalid")}
          description={i18n.t("chat:channel.chat.welcomeMessages.invalidDesc")}
          value={values.invalidEmailMessage}
          onChange={(e) => updateValues({ invalidEmailMessage: e.target.value })}
        />

        <TextInput
          label={i18n.t("chat:channel.chat.welcomeMessages.returning")}
          description={i18n.t("chat:channel.chat.welcomeMessages.returningDesc")}
          value={values.existingMessage}
          onChange={(e) => updateValues({ existingMessage: e.target.value })}
        />

        <Button
          className={styles.submitButton}
          type="submit"
          size="s"
          loading={submitting}
        >
          {i18n.t("chat:channel.chat.welcomeMessages.save")}
        </Button>
      </form>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import classnames from "classnames";

export default function SimpleBar({
  percentage,
  labelTop,
  labelBottom,
  animate = true,
  className,
  ...rest
}) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMounted(true);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const classes = classnames("simple-bar", className, {
    "simple-bar--animate": animate,
  });

  return (
    <div className={classes} {...rest}>
      {labelTop && <div className="simple-bar__label-top">{labelTop}</div>}

      <div className="simple-bar__bar">
        <span
          className="simple-bar__bar-filled"
          style={{ height: `${mounted ? percentage : 0}%` }}
        />
      </div>

      {labelBottom && (
        <div className="simple-bar__label-bottom">{labelBottom}</div>
      )}
    </div>
  );
}

import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import HookAddOption from './hookAddOption'
import axios from '~/data/http/axios';
import HookFilter from './hookFilter'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions';
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, permissionStore: store.permission }
})
class HookOptionsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [],
            actions: [
                { label: 'Opens the email', value: 'open', webhook: 'CampaignOpen' },
                { label: 'Clicks a link', value: 'click', webhook: 'CampaignClick', options: [] },
                { label: 'Unsubscribes', value: 'unsubscribe', webhook: 'CampaignUnsubscribe' },
                { label: 'Marks as junk', value: 'junk', webhook: 'CampaignJunkReport' },
                { label: 'Hard Bounces', value: 'bounce', webhook: 'CampaignBounce' },
            ],
            linksLoaded: true,
            webhooks: [],
            webhooksLoaded: false,
            doingAction: false,
            doingActionElement: undefined,
            drawerContent: undefined
        }
        this.getLinks = this.getLinks.bind(this);
        this.getWebhooks = this.getWebhooks.bind(this);
        this.addedOption = this.addedOption.bind(this);
        this.remove = this.remove.bind(this);
        this.addOption = this.addOption.bind(this);
        this.setFilters = this.setFilters.bind(this);
    }

    componentDidMount() {
        if (this.props.campaign) this.getLinks();
        this.getWebhooks();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.doingAction !== this.state.doingAction && typeof this.props.doingAction === 'function') {
            this.props.doingAction(this.state.doingAction)
        }
    }

    getLinks() {
        this.setState({ linksLoaded: false })

        var promise = Promise.resolve();
        if (this.props.campaign) {
            promise = axios.get('/template/' + this.props.campaign.TemplateId + '/links')
        }
        promise.then(response => {
            var links = [];
            var actions = [
                { label: 'Opens the email', value: 'open', webhook: 'CampaignOpen' },
                { label: 'Unsubscribes', value: 'unsubscribe', webhook: 'CampaignUnsubscribe' },
                { label: 'Marks as junk', value: 'junk', webhook: 'CampaignJunkReport' },
                { label: 'Hard Bounces', value: 'bounce', webhook: 'CampaignBounce' },
                { label: 'Clicks a link', value: 'click', webhook: 'CampaignClick', options: [undefined] }
            ]
            if (response && response.data) {
                links = response.data.Links;
                actions[4].options = [undefined].concat(links)
            }

            this.setState({
                links: links,
                actions: actions,
                linksLoaded: true
            })
        }).catch(console.error)
    }

    getWebhooks() {
        let webhooks = []
        if (this.props.campaign && this.props.campaign.options.webhooks) webhooks = this.props.campaign.options.webhooks
        if (this.props.campaign && !Array.isArray(this.props.campaign.options.webhooks)) webhooks = [];
        if (!this.props.campaign) {
            webhooks = this.props.webhooks
        }
        this.setState({
            webhooks: webhooks,
            webhooksLoaded: true
        })
    }

    remove(index) {
        var webhooks = this.state.webhooks
        webhooks.splice(index, 1)
        var self = this;
        this.setState({ webhooks }, () => { self.props.saveWebhooks(webhooks) })
    }


    addOption(existingIndex) {

        var existing = undefined;
        if (existingIndex != undefined) {
            existing = this.state.webhooks[existingIndex]
        }
        var theAction = <HookAddOption webhooks={this.state.webhooks} hooks={this.props.hooks} actions={this.state.actions} finished={this.addedOption} apps={this.props.apps} campaignId={this.props.campaign ? this.props.campaign.id : ''} existing={existing} />;
        this.setState({
            doingAction: true,
            doingActionElement: theAction
        })
    }
    addedOption(webhooks) {
        var self = this;
        this.setState({ webhooks, doingAction: false, doingActionElement: undefined }, () => { self.props.saveWebhooks(webhooks) })
    }

    setFilters(existingIndex) {

        var inDrawer = false;
        if (!this.props.campaign) inDrawer = true;
        var existing = this.state.webhooks[existingIndex]
        var drawerContent = <HookFilter CampaignId={this.props.campaign ? this.props.campaign.id : undefined} link={existing.options.link} type={existing.hookType} hook={existing.options} close={(newHook) => {
            var hooks = this.state.webhooks;
            hooks[existingIndex].options = newHook

            if (inDrawer) {
                this.setState({ drawerContent: undefined, webhooks: hooks })
            }
            else {
                this.setState({
                    webhooks: hooks
                })
                this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null));
            }
        }} />
        if (inDrawer) {
            this.setState({ drawerContent: drawerContent })
        }
        else {
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "600px", ""));
        }

    }

    render() {
        if (this.state.doingAction) return <div style={{ width: "100%" }}>
            <UILIB.Paper className="secondary">
                {this.state.doingActionElement}
            </UILIB.Paper>
        </div>

        if (this.state.drawerContent) {
            return <div style={{ width: "100%" }}>
                {this.state.drawerContent}
            </div>
        }

        return <div >
            {this.state.webhooks.map((hook, hookIndex) => {
                var hookParent = this.props.hooks.find(hk => hk.ApplicationId == hook.applicationId && hk.action == hook.options.action)
                var appName = "Not Found";
                var appLogo = "";
                var actionDesc = "This action has been superceded. Please remove it.";

                if (hookParent) {
                    appName = hookParent.appName;
                    appLogo = hookParent.logo;
                    var parentAction = this.state.actions.find(ac => ac.value == hook.hookType);
                    if (parentAction) {
                        var parentActionLabel = parentAction.label;
                        if (parentAction.value == "click" && hook.options.link) {
                            parentActionLabel += ` "${hook.options.link}"`;
                        }
                        actionDesc = <div>When a contact <strong>{parentActionLabel}</strong> then <strong>{hookParent.name}</strong> on {appName} </div>
                    }

                }

                return <div className="quickFlex mar-b10">
                    <img className="avatar mar-10" src={appLogo} height="40px" width="40px" title={appName} alt={appName} />
                    <div style={{ flexGrow: 1, marginRight: 10 }}>
                        {actionDesc}
                    </div>
                    <div className="quick-flex text-right">
                        <UILIB.Button text="Edit" className="button-primary mar-r5" onClick={() => this.addOption(hookIndex)} />
                        <UILIB.Button text="Remove" className="button-red" onClick={() => this.remove(hookIndex)} />
                    </div>
                </div>
            })}

            <UILIB.Button text={this.state.webhooks.length ? "Add another Action" : "Add an Action"} onClick={this.addOption} className="button-primary mar-t10" iconLeft={<UILIB.Icons icon="plus" />} />
        </div>
    }
}
export default HookOptionsView;
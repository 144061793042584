import React from 'react'

export default function IconsSms({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 5.125V9.625C18 9.92337 17.8815 10.2095 17.6705 10.4205C17.4595 10.6315 17.1734 10.75 16.875 10.75H14.0625L11.6562 12.6751C11.4925 12.806 11.25 12.6895 11.25 12.4798V10.75H10.125C9.82663 10.75 9.54048 10.6315 9.3295 10.4205C9.11853 10.2095 9 9.92337 9 9.625V5.125C9 4.82663 9.11853 4.54048 9.3295 4.3295C9.54048 4.11853 9.82663 4 10.125 4H16.875C17.1734 4 17.4595 4.11853 17.6705 4.3295C17.8815 4.54048 18 4.82663 18 5.125Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M5 2.00006C4.46957 2.00006 3.96086 2.21077 3.58579 2.58585C3.21071 2.96092 3 3.46963 3 4.00006V16.0001C3 16.5305 3.21071 17.0392 3.58579 17.4143C3.96086 17.7893 4.46957 18.0001 5 18.0001H11C11.5304 18.0001 12.0391 17.7893 12.4142 17.4143C12.7893 17.0392 13 16.5305 13 16.0001V12.8806L12.2809 13.4559C11.4624 14.1107 10.25 13.528 10.25 12.4798V11.75H10.125C9.56141 11.75 9.02091 11.5261 8.6224 11.1276C8.22388 10.7291 8 10.1886 8 9.625V5.125C8 4.56142 8.22388 4.02091 8.6224 3.6224C9.02091 3.22388 9.56142 3 10.125 3H12.732C12.6454 2.84993 12.5389 2.71051 12.4142 2.58585C12.0391 2.21077 11.5304 2.00006 11 2.00006H5ZM8 16.0001C8.26522 16.0001 8.51957 15.8947 8.70711 15.7072C8.89464 15.5196 9 15.2653 9 15.0001C9 14.7348 8.89464 14.4805 8.70711 14.293C8.51957 14.1054 8.26522 14.0001 8 14.0001C7.73478 14.0001 7.48043 14.1054 7.29289 14.293C7.10536 14.4805 7 14.7348 7 15.0001C7 15.2653 7.10536 15.5196 7.29289 15.7072C7.48043 15.8947 7.73478 16.0001 8 16.0001Z" fill={fillColor} />
        </svg>
    )
}
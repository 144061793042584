import React, { Component } from 'react';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib';
import helpers from '~/Classes/helpers'

import i18n from '~/i18n';

@connect((store) => {
    return { siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})
class popPreviewForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: '100%'
        }
        this.changeView = this.changeView.bind(this)
    }

    componentDidMount() {
        helpers.createKey(0, 0, 0, 0).then(key => {
            this.setState({ key })
        })
    }

    changeView(view) {
        var views = ['100%', '768px', '480px']
        this.setState({
            width: views[view]
        })
    }

    render() {
        if (!this.state.key) return <UILIB.LoadingIcons />

        return (
            <UILIB.Row className="emailPreviewHolder" margin={0}>
                <UILIB.Column xs={12} sm={6} className="mar-b25">
                    <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-r5"
                        selected={this.state.width === "100%" && "selected"}
                        onClick={() => { this.changeView(0) }}>
                        <UILIB.Icons icon="pc" />
                    </UILIB.ButtonSimple>

                    <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-r5"
                        selected={this.state.width === "768px" && "selected"}
                        onClick={() => { this.changeView(1) }}>
                        <UILIB.Icons icon="tablet" />
                    </UILIB.ButtonSimple>

                    <UILIB.ButtonSimple
                        className="button-simple-fullsize "
                        selected={this.state.width === "480px" && "selected"}
                        onClick={() => { this.changeView(2) }}>
                        <UILIB.Icons icon="mobile" />
                    </UILIB.ButtonSimple>

                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="mar-b25 end-xs">
                    <UILIB.Button
                        className="button-primary"
                        iconRight={<UILIB.Icons icon="arrowRight" />}
                        onClick={this.props.selectTemplate}>
                        <div dangerouslySetInnerHTML={{ __html: i18n.t('templates:add.selectAndContinue') }} />
                    </UILIB.Button>
                </UILIB.Column>

                <UILIB.Column xs={12} margin={0} className="mar-b0">
                    <div className="innerPreviewHolder" style={{ maxWidth: this.state.width, margin: "0 auto" }}>
                        <iframe
                            width="100%" height="100%" style={{ border: "0", margin: "0 auto" }} src={window.location.protocol + '//' + this.props.siteMaster.trackingDomain + "/api/preview?key=" + this.state.key + "&tid=" + this.props.theme.id + "&type=theme"}
                        />
                    </div >
                </UILIB.Column >
            </UILIB.Row>

        );
    }
}

export default popPreviewForm;
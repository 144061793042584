import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import EmailValidator from '~/Classes/emailValidator'
import TemplateSelector from '../../../campaigns/add/steps/step2/standardCampaignStep2'
import TemplateImageLoader from '../templateImageLoader.jsx'
import SiteVars from '~/Classes/siteVars';
import moment from 'moment'
import i18n from '~/i18n'
import MultiEmailPicker from '~/pages/cp/includes/multiEmailPicker/multiEmailPicker'
import DedicatedServerSelector from '~/pages/cp/includes/dedicatedServerSelector/dedicatedServerSelector'
import AutoMergeTags from '~/pages/cp/includes/mergeTags/campaignMergeTags';
import DesignerMergeTags from '~/pages/cp/includes/mergeTags/mergeTags';
import Help from '~/Common-Objects/Logos/help'
import ReactTooltip from 'react-tooltip'
import HelpPopup from '../helpPopup/helpPopup';

@connect((store) => {
    return { siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})
class DrawerStepEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerContent: undefined,
            step: this.props.step,
            errors: {},
            campaign: {},
            fromAddressError: '',
            showAdvanced: false,
            advancedLoading: true,
            trackingDomains: [],
            selectedTrackingDomain: -1,
            cdnDomains: [],
            selectedCdnDomain: -1,
            mailservers: [],
            mailServerGroups: [],
            pageLoading: true,
            changingTemplate: false
        }

        this.updateValue = this.updateValue.bind(this);
        this.updateTracking = this.updateTracking.bind(this);
        this.updateCampaignOptions = this.updateCampaignOptions.bind(this);
        this.saveStep = this.saveStep.bind(this);
        this.saveCampaign = this.saveCampaign.bind(this);
        this.createEditTemplate = this.createEditTemplate.bind(this);
        this.updateFromAddress = this.updateFromAddress.bind(this)
        this.showHideAdvanced = this.showHideAdvanced.bind(this);
        this.openMultiEmailPicker = this.openMultiEmailPicker.bind(this);
        this.finishedMultiEmailPicker = this.finishedMultiEmailPicker.bind(this);
        this.updateBatching = this.updateBatching.bind(this);

        this.openMergeTags = this.openMergeTags.bind(this);
        this.closeMergeTags = this.closeMergeTags.bind(this);
        this.insertMergeTag = this.insertMergeTag.bind(this);
        this.checkFromAddress = this.checkFromAddress.bind(this)

    }

    componentDidMount() {
        var step = this.props.step;
        var options = step.stepOptions;
        if (!options.replyAddress) {
            options.replyAddress = {
                value: ''
            }
        }
        this.setState({ step: this.props.step });

        var promises = []
        promises.push(axios.get('/accountMaster/domain').then(res => {
            this.setState({
                sendingDomains: res.data.domains
            })
        }))
        promises.push(axios.get('/accountMaster/cdn').then(res => {
            var selectedCdnDomain = 0;
            if (step.stepOptions.campaign.CdnDomainId) {
                selectedCdnDomain = step.stepOptions.campaign.CdnDomainId;
            }
            this.setState({
                cdnDomains: res.data.Domains.filter(d => d.authenticated),
                selectedCdnDomain
            })
        }).catch(() => { }))
        promises.push(axios.get('/accountMaster/tracking').then(res => {
            var selectedTrackingDomain = 0;
            if (step.stepOptions.campaign.TrackingDomainId) {
                selectedTrackingDomain = step.stepOptions.campaign.TrackingDomainId;
            }
            this.setState({
                trackingDomains: res.data.Domains.filter(d => d.authenticated),
                selectedTrackingDomain,
                pageLoading: false
            })
        }).catch(() => { }))

        if (this.props.step.stepOptions?.campaign?.id) {
            promises.push(axios.get('/campaign/' + this.props.step.stepOptions.campaign.id).then(res => {
                this.setState({
                    campaign: res.data.Campaign
                })
            }))
        }
        // handle defaults just in case they dont exist

        Promise.all(promises).then(() => {

            if (!this.props.isThemeDesigner && this.state.step.stepOptions.fromAddress && this.state.step.stepOptions.fromAddress.value) {
                this.checkFromAddress()
            }
        })

        if (this.props.reselectTemplate) {

            this.createEditTemplate(true);
        }

        if (this.state.step.stepOptions.campaign?.multiAddressRules?.length) {
            this.showHideAdvanced()
        }
    }

    componentWillUnmount() {
        if (this.checkerTimer) clearTimeout(this.checkerTimer)
    }

    updateValue(event) {
        var step = this.state.step;
        step.stepOptions[event.target.name].value = event.target.value;
        step.complete = false;
        this.setState(step)
    }

    updateCampaignOptions(event, string) {
        var step = this.state.step;
        if (!step.stepOptions.campaign.options) step.stepOptions.campaign.options = {};
        var value = string ? "" : false;
        if (!string) {
            if (event.target.checked) value = true;
        } else {
            value = event.target.value;
        }
        step.stepOptions.campaign.options[event.target.name] = value;
        this.setState({ step })
    }

    updateTracking(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    checkFromAddress() {
        const step = this.state.step
        let fromAddressError = ''

        if (this.props.isThemeDesigner && this.state.step.stepOptions.fromAddress && this.state.step.stepOptions.fromAddress.value.indexOf("{{") == 0) return "";

        return EmailValidator.checkEmailAuthed(this.state.step.stepOptions.fromAddress.value).then(res => {
            if (res.error || !res.domainExists || !res.domainVerified || (this.props.siteMaster.usersMustBeAuthenticated && !res.domainAuthenticated)) {
                fromAddressError = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.email.errAuth') }}></span>;
            } else {
                // set the tracking and cdn domains if we can
                let domainName = step.stepOptions.fromAddress.value.split('@')[1].toLowerCase()
                if (!step.stepOptions.campaign.CdnDomainId && this.state.cdnDomains.length) {
                    let domain = this.state.cdnDomains.find(d => d.authenticated && d.domainName.indexOf(domainName) > -1)
                    if (domain) {
                        step.stepOptions.campaign.CdnDomainId = domain.id
                    }
                }
                if (this.state.selectedCdnDomain && this.state.selectedCdnDomain > 0) {
                    step.stepOptions.campaign.CdnDomainId = this.state.selectedCdnDomain;
                }

                if (!step.stepOptions.campaign.TrackingDomainId && this.state.trackingDomains.length) {
                    let domain = this.state.trackingDomains.find(d => d.authenticated && d.domainName.indexOf(domainName) > -1)
                    if (domain) {
                        step.stepOptions.campaign.TrackingDomainId = domain.id
                    }
                }
                if (this.state.selectedTrackingDomain && this.state.selectedTrackingDomain > 0) {
                    step.stepOptions.campaign.TrackingDomainId = this.state.selectedTrackingDomain;
                }
                if (step.stepOptions.campaign.TrackingDomainId == -1) step.stepOptions.campaign.TrackingDomainId = null;
                if (step.stepOptions.campaign.CdnDomainId == -1) step.stepOptions.campaign.CdnDomainId = null;
            }
            this.setState({ fromAddressError })
            return fromAddressError
        })
    }


    saveStep(skipSave) {
        var errors = {};
        var error = false;
        var step = this.state.step;

        var promise = Promise.resolve();

        if (step.stepOptions.subject.value) step.stepOptions.subject.value = step.stepOptions.subject.value.trim()
        if (!step.stepOptions.subject.value || step.stepOptions.subject.value.length < 3) {
            errors.subject = i18n.t('automation:add.email.errSubject');
            error = true;
        }
        if (!step.stepOptions.fromName.value || step.stepOptions.fromName.value.length < 3) {
            errors.fromName = i18n.t('automation:add.email.errName');
            error = true;
        }

        if (this.props.isThemeDesigner && (step.stepOptions.fromAddress.value.indexOf("{{") != 0 && !EmailValidator.validateEmail(step.stepOptions.fromAddress.value))) {
            this.setState({ fromAddressError: i18n.t('automation:add.email.errAddress') })
            error = true;
        }

        if (!this.props.isThemeDesigner) {
            if (!step.stepOptions.fromAddress.value || !EmailValidator.validateEmail(step.stepOptions.fromAddress.value)) {
                this.setState({ fromAddressError: i18n.t('automation:add.email.errAddress') })
                error = true;
            }
            else {
                promise = this.checkFromAddress();
            }
        }

        if (!step.stepOptions.campaign || !step.stepOptions.campaign.id) {
            errors.campaign = i18n.t('automation:add.email.errBody')
            error = true
        }


        return promise.then(err => {
            this.setState({ errors })
            if (!error && !err) {

                var campaign = step.stepOptions.campaign
                campaign.subject = step.stepOptions.subject.value;
                campaign.fromAddress = step.stepOptions.fromAddress.value;
                campaign.replyAddress = (step.stepOptions.replyAddress && step.stepOptions.replyAddress.value) ? step.stepOptions.replyAddress.value : step.stepOptions.fromAddress.value;
                campaign.fromName = step.stepOptions.fromName.value;
                campaign.name = 'Automation ' + this.props.automationData.name + ' Step ' + step.guid
                if (!campaign.startDate) campaign.startDate = moment().format();

                this.saveCampaign(campaign).then(() => { }).catch(err => { })

                step.complete = true;
                step.wizardComplete = true;

                if (!skipSave) this.props.saveStep();
            } else {
                step.complete = false;
            }

            if (skipSave) {
                this.props.saveStep(true, true);
            }
        });
    }

    createEditTemplate(reselectTemplate, dontMoveToNextWizardStep) {

        this.setState({
            changingTemplate: true
        })
        var campaign = this.state.step.stepOptions.campaign
        campaign.type = 3
        campaign.AutomationId = this.props.automationData.id
        campaign.campaignName = 'Automation ' + this.props.automationData.name + ' Step ' + this.state.step.guid

        campaign.subject = this.state.step.stepOptions.subject.value;
        campaign.fromAddress = this.state.step.stepOptions.fromAddress.value;
        if (this.state.step.stepOptions.replyAddress && this.state.step.stepOptions.replyAddress.value) {
            campaign.replyAddress = this.state.step.stepOptions.replyAddress.value;
        } else {
            campaign.replyAddress = campaign.fromAddress;
        }
        campaign.fromName = this.state.step.stepOptions.fromName.value;
        if (this.props.reselectTemplate || reselectTemplate) {
            campaign.TemplateId = null;
            campaign.Template = null;
        }

        this.saveCampaign().then(response => {
            var newCampaign = response.data.Campaign;

            if (!newCampaign.Template) {
                newCampaign.Template = {};
                if (campaign.Template && campaign.Template.templateType) {
                    newCampaign.Template.templateType = campaign.Template.templateType;
                }
            }
            this.state.step.stepOptions.campaign = newCampaign;
            // this.state.step.complete = true;
            // this.state.step.wizardComplete = true;
            this.saveStep(true).then(() => {
                var params = 'redirect=' + encodeURIComponent('/cp/automation/addnew/' + this.props.automationData.id + '?step=' + this.state.step.guid)
                if (this.props.isThemeDesigner) {
                    params = 'redirect=' + encodeURIComponent(`/cp/automation/addnew/?step=${this.state.step.guid}&themeDesigner=true&themeId=${this.props.automationData.id}&redirect=https://admin.mpzmail.com/cp/sitetemplates/automation`);
                }


                var drawerContent = <TemplateSelector params={params} campaign={newCampaign} history={this.props.history} selectedTemplate={templateType => {
                    newCampaign.Template.templateType = templateType
                    this.props.saveStep(true, true, dontMoveToNextWizardStep)
                }} saveCampaign={this.saveCampaign} />
                this.props.dispatch(siteMaster.alterSiteDrawer(true, false, "right", drawerContent, true, '100vw', 'full campaign-holder'));
            })
            // this.props.saveStep(true, true)


        })
    }

    saveCampaign(campaign, dontMoveToNextWizardStep, dontCloseDrawer) {
        if (campaign) {
            this.state.step.stepOptions.campaign = campaign;
            this.props.saveStep(true, true, dontMoveToNextWizardStep, dontCloseDrawer);
        }
        else {
            campaign = this.state.step.stepOptions.campaign
        }
        this.setState({ random: Math.round(Math.random() * 9999999) });
        if (campaign.id) {
            delete campaign.templateHtml;
            return axios.put('/campaign/' + campaign.id, { campaign })
        } else {
            delete campaign.templateHtml;
            campaign.startDate = moment().format();
            return axios.post('/campaign', campaign)
        }
    }

    changeFomAddress(event) {
        var step = this.state.step;
        step.stepOptions.fromAddress.value = event.target.value;
        step.complete = false;
        this.setState(step, () => {
            if (this.checkerTimer) clearTimeout(this.checkerTimer)
            this.checkerTimer = setTimeout(this.checkFromAddress, 250)
        })

    }

    updateFromAddress() {
        var step = this.state.step;
        var options = step.stepOptions;
        if (!options.replyAddress.value && options.fromAddress.value && !this.state.errors.fromAddress) {
            options.replyAddress.value = options.fromAddress.value
            this.setState({
                step
            })
        }
    }

    showHideAdvanced() {
        var show = this.state.showAdvanced;
        var self = this;
        if (show) { show = false }
        else { show = true }

        if (show) {
            var mailservers;
            var mailServerGroups;
            this.setState({ advancedLoading: true, showAdvanced: true })

            var promises = [];
            if (self.props.accountMaster.accountMaster.options.allowMailServerSelection) {
                promises.push(axios.get('/accountMaster/mailservers')
                    .then(response => {
                        mailservers = response.data.MailServers;
                        return axios.get('/mailServer/group')
                    }).then(msG => {

                        mailServerGroups = msG.data.Groups
                    })
                )
            }
            return Promise.all(promises).then(() => {
                self.setState({
                    advancedLoading: false,
                    mailservers,
                    mailServerGroups
                })
            })
        }
        else {
            this.setState({ showAdvanced: show, advancedLoading: false })
        }

    }

    openMultiEmailPicker() {

        var drawerContent = <MultiEmailPicker createdRules={this.finishedMultiEmailPicker} campaign={this.state.step.stepOptions.campaign} trackingDomains={this.state.trackingDomains} cdnDomains={this.state.cdnDomains} sendingDomain={this.state.sendingDomains} />
        this.setState({ drawerContent })
        this.props.dispatch(siteMaster.alterSiteDrawerWidth('100vw'));
    }
    finishedMultiEmailPicker(finalRules, defaultEmail, defaultFromName, defaultReplyAddress, ruleSetName) {
        this.props.dispatch(siteMaster.alterSiteDrawerWidth('600px'));
        var theCampaign = this.state.step.stepOptions.campaign;
        theCampaign.replyAddress = defaultReplyAddress;
        theCampaign.fromName = defaultFromName;
        theCampaign.fromAddress = defaultEmail;
        theCampaign.multiAddressRules = finalRules;
        if (!theCampaign.options) theCampaign.options = {};
        if (ruleSetName && ruleSetName.length) {
            theCampaign.options.multiAddressRuleName = ruleSetName;
        }
        var step = this.state.step;
        step.stepOptions.campaign = theCampaign;
        this.saveCampaign(step.stepOptions.campaign, true, true);
        this.setState({ drawerContent: undefined, step })
    }

    updateBatching(event) {

        var step = this.state.step
        var campaign = this.state.step.stepOptions.campaign;
        campaign[event.target.name] = event.target.value;
        step.stepOptions.campaign = campaign;
        this.setState({
            step
        })
    }


    openMergeTags(fieldName) {
        var drawerContent = <AutoMergeTags hasWorkflowEmails={false} includeSubscriber={false} includeSubCustomFields={true} close={this.closeMergeTags} insertMergeTag={this.insertMergeTag} automationId={this.props.automationData.id} fieldName={fieldName} />
        if (this.props.isThemeDesigner) {
            drawerContent = <DesignerMergeTags
                fromDesigner={true}
                close={this.closeMergeTags}
                onTagClick={(value) => { this.insertMergeTag(value, fieldName) }} filter={["1"]} />
        }
        this.setState({ drawerContent })
    }

    closeMergeTags() {
        this.setState({ drawerContent: undefined })
    }

    insertMergeTag(value, fieldName) {
        this.props.step.stepOptions[fieldName].value += " " + value;
        this.setState({ drawerContent: undefined })
    }

    isEditEmailContentButtonDisabled() {
        const hasValidSubject = this.state.step.stepOptions.subject.value?.length > 3;
        const hasValidFromName =
            this.state.step.stepOptions.fromName.value?.length > 2;
        const hasValidFromAddress = EmailValidator.validateEmail(
            this.state.step.stepOptions.fromAddress.value
        );

        if (this.state.changingTemplate || this.props.readOnly) {
            return true;
        } else if (hasValidSubject && hasValidFromName && hasValidFromAddress) {
            return false;
        }

        return true;
    }

    render() {
        const { readOnly } = this.props;

        if (this.state.drawerContent) return this.state.drawerContent;
        if (!this.state.step.stepOptions) return <div></div>

        if (this.state.pageLoading) return <UILIB.LoadingIcons iconType="2" />
        var campaignTemplateImage;
        if (this.state.step.stepOptions.campaign && this.state.step.stepOptions.campaign.id) {
            campaignTemplateImage = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + this.state.step.stepOptions.campaign.TemplateId + ".jpg"
        }
        var doNotTrackOpens = false;
        var doNotTrackLinks = false;
        var fixedCustomFields = false;
        if (this.state.step.stepOptions.campaign && this.state.step.stepOptions.campaign.options) {
            if (this.state.step.stepOptions.campaign.options.doNotTrackLinks) doNotTrackLinks = true;
            if (this.state.step.stepOptions.campaign.options.doNotTrackOpens) doNotTrackOpens = true;
            if (this.state.step.stepOptions.campaign.options.fixedCustomFields) fixedCustomFields = true;
        }

        const inputProps = {
            disabled: readOnly,
        }

        const usingMultiSenderRules = !!this.state.step.stepOptions.campaign?.multiAddressRules?.length

        let preHeaderAllowed = true;
        let preheaderInfo = i18n.t('campaigns:standard.step3.preheaderTextInfo')

        if (this.state.campaign?.Template?.templateType !== 0) {
            // not drag and drop so cant reliably inject preheader text
            preHeaderAllowed = false;
            preheaderInfo = i18n.t('campaigns:standard.step3.preheaderTextDisabled')
        }

        return <div data-test="drawer-email-step">
            <div className="automationStepContentHolder">

                <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="mar-b25">{i18n.t('automation:add.email.header')}</h4>
                    <HelpPopup type={"email"} />
                </div>
                <div className="mar-b25">{i18n.t('automation:add.email.subheader')}</div>


                <UILIB.TextInput
                    label={i18n.t('automation:add.email.subject')}
                    labelOptions={this.props.isThemeDesigner ? <button className="a" onClick={() => this.openMergeTags("subject")} {...inputProps}>Designer Merge Tags</button> : <button onClick={() => this.openMergeTags("subject")} className="a text-xsml" {...inputProps}>Merge Tags</button>}
                    name="subject" onChange={this.updateValue}
                    value={this.state.step.stepOptions.subject.value}
                    placeholder={i18n.t('automation:add.email.subjectPlace')}
                    error={this.state.errors.subject} type="text"
                    outerClassName="mar-b25"
                    {...inputProps}
                />



                {!usingMultiSenderRules && <UILIB.TextInput
                    label={i18n.t('automation:add.email.from')}
                    labelOptions={this.props.isThemeDesigner ? <button className="a" onClick={() => this.openMergeTags("fromName")} {...inputProps}>Designer Merge Tags</button> : ""}
                    name="fromName"
                    onChange={this.updateValue}
                    value={this.state.step.stepOptions.fromName.value}
                    placeholder={i18n.t('automation:add.email.fromPlace')}
                    error={this.state.errors.fromName}
                    type="text"
                    outerClassName="mar-b25"
                    {...inputProps}
                />}

                {!usingMultiSenderRules && <UILIB.TextInput
                    label={i18n.t('automation:add.email.fromA')}
                    labelOptions={this.props.isThemeDesigner ? <button className="a" onClick={() => this.openMergeTags("fromAddress")} {...inputProps}>Designer Merge Tags</button> : ""}
                    name="fromAddress"
                    onChange={this.changeFomAddress.bind(this)}
                    value={this.state.step.stepOptions.fromAddress.value}
                    onBlur={this.updateFromAddress}
                    placeholder={i18n.t('automation:add.email.fromAPlace')}
                    error={this.state.fromAddressError}
                    type="email"
                    outerClassName="mar-b25"
                    {...inputProps}
                />}



                {!usingMultiSenderRules && <UILIB.TextInput
                    label={i18n.t('automation:add.email.replyA')}
                    labelOptions={this.props.isThemeDesigner ? <button className="a" onClick={() => this.openMergeTags("replyAddress")} {...inputProps}>Designer Merge Tags</button> : ""}
                    name="replyAddress"
                    onChange={this.updateValue}
                    value={this.state.step.stepOptions.replyAddress.value}
                    placeholder={i18n.t('automation:add.email.replyAPlace')}
                    error={this.state.errors.replyAddress}
                    type="email"
                    outerClassName="mar-b25"
                    {...inputProps}
                />}

                {!!this.state.campaign?.id && <UILIB.TextInput
                    label={i18n.t('campaigns:standard.step3.preheaderText')}
                    id="preheaderText"
                    name="preheaderText"
                    explainer={preheaderInfo}
                    onChange={e => this.updateCampaignOptions(e, true)}
                    disabled={!preHeaderAllowed}
                    value={this.state.step.stepOptions?.campaign?.options?.preheaderText}
                    outerClassName="mar-b25" />}

                <div>
                    <div className={"quickFlex mar-b25" + (campaignTemplateImage ? " paper-class" : "")}>
                        {campaignTemplateImage &&
                            <div className="campaign-thumbnail mar-r20" style={{ width: "100px", height: "80px" }}>
                                <TemplateImageLoader imageUrl={campaignTemplateImage} />
                            </div>
                        }
                        <div style={{ flex: "1" }}>
                            <UILIB.Button
                                iconLeft={<UILIB.Icons icon="pencil" />}
                                className="button-primary"
                                text={i18n.t('automation:add.email.editEmailContent')}
                                onClick={() => { this.createEditTemplate(false) }}
                                disabled={this.isEditEmailContentButtonDisabled()}
                            />
                            {campaignTemplateImage && <div className="text-xsml mar-t10">
                                or <button className="a" onClick={() => { this.createEditTemplate(true, true) }} {...inputProps}>Change Template</button>
                            </div>}
                            {this.state.errors.campaign && <UILIB.WarningHolder className="warning-holder-nopadding text-red mar-t10">{this.state.errors.campaign}</UILIB.WarningHolder>}
                        </div>
                    </div>


                    {(this.state.step.stepOptions.campaign && this.state.step.stepOptions.campaign.id) && <div className="mar-t15 ">

                        {(!usingMultiSenderRules) && <UILIB.Button
                            text={i18n.t('campaigns:standard.step1.advanced')}
                            iconRight={<UILIB.Icons icon={"arrowRight"} className={this.state.showAdvanced ? 'flipRight' : ''} />}
                            onClick={this.showHideAdvanced}
                            {...inputProps}
                        />}

                        {(this.state.showAdvanced) && <UILIB.Paper className="mar-t25">
                            {(this.state.advancedLoading) && <UILIB.LoadingIcons iconType="2" />}
                            {(!this.state.advancedLoading) &&
                                <div>
                                    <UILIB.Row margin={0}>
                                        <UILIB.Column xs={12} sm={6} className="mar-b25">
                                            <UILIB.Toggle label={i18n.t('campaigns:main.doNotTrackLinks')} before={i18n.t('campaigns:main.no')} after={i18n.t('campaigns:main.yes')} checked={doNotTrackLinks} name="doNotTrackLinks" onChange={this.updateCampaignOptions}></UILIB.Toggle>
                                        </UILIB.Column>
                                        <UILIB.Column xs={12} sm={6} className="mar-b25">
                                            <UILIB.Toggle label={i18n.t('campaigns:main.doNotTrackOpens')} before={i18n.t('campaigns:main.no')} after={i18n.t('campaigns:main.yes')} checked={doNotTrackOpens} name="doNotTrackOpens" onChange={this.updateCampaignOptions}></UILIB.Toggle>
                                        </UILIB.Column>

                                        {(!usingMultiSenderRules) && ((this.state.cdnDomains && this.state.cdnDomains.length > 0) || (this.state.trackingDomains && this.state.trackingDomains.length > 0)) && <>
                                            {(this.state.trackingDomains && this.state.trackingDomains.length > 0) &&
                                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                                    <UILIB.Select label={i18n.t('campaigns:standard.step3.tracking')}
                                                        value={this.state.selectedTrackingDomain}
                                                        name="selectedTrackingDomain"
                                                        onChange={this.updateTracking}
                                                        data={this.state.trackingDomains.map(domain => {
                                                            return {
                                                                label: domain.domainName,
                                                                value: domain.id
                                                            }
                                                        })} />
                                                </UILIB.Column>

                                            }
                                            {(this.state.cdnDomains && this.state.cdnDomains.length > 0) &&
                                                <UILIB.Column xs={12} sm={6} className="mar-b25">
                                                    <UILIB.Select
                                                        label={i18n.t('campaigns:standard.step3.cdn')}
                                                        value={this.state.selectedCdnDomain}
                                                        name="selectedCdnDomain"
                                                        onChange={this.updateTracking}
                                                        data={this.state.cdnDomains.map(domain => {
                                                            return {
                                                                label: domain.domainName,
                                                                value: domain.id
                                                            }
                                                        })} />
                                                </UILIB.Column>
                                            }
                                        </>
                                        }

                                        <UILIB.Column xs={12} className="mar-b25">

                                            <div className="form-group">
                                                <UILIB.TextInput
                                                    label="Multi Sender Rules"
                                                    name="senderRules"
                                                    value={this.state.step.stepOptions.campaign.multiAddressRules && this.state.step.stepOptions.campaign.multiAddressRules.length ? this.state.step.stepOptions.campaign.multiAddressRules.length + " Rules. Click Here to Edit" : "Click Here to add Rules"}
                                                    onClick={this.openMultiEmailPicker}
                                                    readOnly={true} />
                                            </div>

                                            {((this.state.mailservers && this.state.mailservers.length)) &&
                                                <DedicatedServerSelector
                                                    className="mar-t25"
                                                    mailservers={this.state.mailservers}
                                                    campaign={this.state.step.stepOptions.campaign}
                                                    mailserverGroups={this.state.mailServerGroups}
                                                    updateCampaign={() => this.setState({})}
                                                />
                                            }
                                        </UILIB.Column>

                                        {(this.props.siteMaster.siteId != 2 || this.props.accountMaster.accountMaster.options.fixedCustomFields || true) && <UILIB.Column xs={6} >
                                            <UILIB.Toggle label={<div>
                                                {i18n.t('campaigns:standard.step1.fixedCustomFields')}
                                                <Help className="mar-l5" title="help" width="16px" data-tip data-for={'fixedCustomFieldsTooltip'} />
                                                <ReactTooltip effect='solid' className={"tooltip"} id={'fixedCustomFieldsTooltip'}>{i18n.t('campaigns:standard.step1.fixedCustomFieldsTooltip')}</ReactTooltip>
                                            </div>} before={i18n.t('campaigns:main.no')} after={i18n.t('campaigns:main.yes')} checked={fixedCustomFields} name="fixedCustomFields" onChange={this.updateCampaignOptions}></UILIB.Toggle>
                                        </UILIB.Column>}
                                    </UILIB.Row>
                                </div>
                            }
                        </UILIB.Paper>}
                    </div>}
                </div>

            </div>
            <div className="automationStepSaveButtonHolder pad-t20">
                {readOnly ? (
                    <>
                        <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                    </>
                ) : (
                    <UILIB.Button className="button-primary" text={i18n.t('save')} onClick={() => this.saveStep()} />
                )}
            </div>

        </div >
    }
}

export default DrawerStepEmail;
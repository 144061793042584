import React from "react";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { connect } from "react-redux";
import EmailTools from "~/Classes/emailValidator";
import i18n from "~/i18n";

//CONNECT TO STORE
@connect((store) => {
  return { user: store.user, siteMaster: store.siteMaster };
})
export default class AddDomain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: this.props.emailAddress || "",
      inputErrors: {
        emailAddress: "",
      },
    };

    this.updateInput = this.updateInput.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
  }

  componentDidMount() {}

  updateInput(ev) {
    this.setState({ [ev.currentTarget.name]: ev.currentTarget.value });
  }

  checkEmail(e) {
    e.preventDefault();

    var self = this;
    var thisEmail = this.state.emailAddress;
    var inputErrors = this.state.inputErrors;
    inputErrors.emailAddress = "";
    if (EmailTools.validateEmail(thisEmail) === false) {
      inputErrors.emailAddress = i18n.t("company:domains.alertInvalidEmail");
      this.setState({ inputErrors: inputErrors });
      return;
    }
    this.setState({ inputErrors: inputErrors });
    var data = { emailAddress: thisEmail.toLowerCase() };
    axios
      .post("/accountMaster/domain", data)
      .then((res) => {
        if (res.data.Required == false) {
          var errors = self.state.inputErrors;
          errors.emailAddress = i18n.t("company:domains.alertDomainCreate");
          self.setState({ inputErrors: errors });
        } else {
          self.props.onAdded();
        }
      })
      .catch((err) => {
        console.log(err);
        var theErr = self.state.inputErrors;
        theErr.emailAddress = err.data.error;
        self.setState({ inputErrors: theErr });
      });
  }

  render() {
    return (
      <div>
        {this.props.siteMaster.siteId == 1 && (
          <UILIB.Hint
            className="uiDrawer__primary-hint"
            href="https://kb.transpond.io/article/106-email-configuration#how-to-setup-an-email-domain"
            iconLeft={<UILIB.Icons icon="questionCircle" />}
          >
            Domain Setup Guide
          </UILIB.Hint>
        )}

        <h2 className="h4 mar-b25">{i18n.t("company:domains.addDomain")}</h2>

        <p className="mar-b25">
          {i18n.t("company:domains.addDomainSubHeader")}
        </p>

        <form onSubmit={this.checkEmail}>
          <UILIB.TextInput
            outerClassName="mar-b15"
            label={i18n.t("company:domains.enterEmail")}
            focus={true}
            type="text"
            placeholder={i18n.t("emailAddressPlaceholder")}
            name="emailAddress"
            error={this.state.inputErrors.emailAddress}
            value={this.state.emailAddress}
            onChange={this.updateInput}
          />

          <UILIB.Button type="submit" className="button-primary">
            {i18n.t("continue")}
          </UILIB.Button>
        </form>
      </div>
    );
  }
}

import React from 'react'
import moment from 'moment'
import more from './images/instagram/instagram_more.svg'
import share from './images/instagram/instagram_share.svg'

export default function SocialInstagramPreview({ account, startDate, content, images = [] }) {
    const now = moment(startDate)
    return (
        <div style={{ width: 376, backgroundColor: 'white', fontFamily: 'inter', borderRadius: '8px' }}>
            {/* header */}
            <div style={{ padding: '11px 15px 11px 10px', display: 'flex', alignItems: 'center' }}>
                <img src={account.image} style={{ height: 32, width: 32, marginRight: 10, borderRadius: '50%' }} />
                <div style={{ fontSize: '13px', fontWeight: '600', lineHeight: '18px', flexGrow: 1 }}>{account.name}</div>
                <img src={more} />
            </div>

            {/* image */}
            {!!images.length && <img src={images[0].url || images[0]} style={{
                backgroundColor: '#e0e0e0',
                width: '100%',
                position: 'relative',
                justifyContent: 'center'
            }} />}

            {/* content */}
            <div style={{ padding: '16px' }}>
                <img src={share} />
                <div style={{ color: '#262626', fontSize: '13px', fontWeight: '600', lineHeight: '18px', marginBottom: 6 }}>1,337 likes</div>
                <div style={{ color: '#262626', fontSize: '12px', fontWeight: '400', lineHeight: '16px', marginBottom: 6, maxHeight: '32px', overflow: 'hidden', wordWrap: 'break-word', textOverflow: '... more', wordBreak: "break-all" }}>
                    <span style={{ fontWeight: 600 }}>{account.name} </span>
                    <span dangerouslySetInnerHTML={{ __html: content }}></span>
                </div>
                <div style={{ color: 'rgba(0, 0, 0, 0.40)', fontSize: '11px', fontWeight: 400, letterSpacing: '0.05px' }}>{now.format('DD MMMM')}</div>
            </div>
        </div >
    )
}
import React from 'react'
import axios from '~/data/http/axios';
import * as user from '~/data/actions/userActions'; //now we can use user actions here
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import PermissionChecker from '../../../../../Classes/permissions'
import i18n from '~/i18n';
import CompanySelect from '../bb_topNav_CompanySelect';

@connect((store) => {
    return { user: store.user, browser: store.browser, accountMaster: store.accountMaster, permissionStore: store.permission, siteMaster: store.siteMaster }
})
export default class bb_account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navOpen: false,
            accounts: [],
            loadedAccounts: false,
            accountDetails: {},
            companySwitcherOpen: false,
        }

        this.companySwitcherLeaveTimerRef = React.createRef(null);

        this.goLog = this.goLog.bind(this);
        this.navClicked = this.navClicked.bind(this);
        this.goNav = this.goNav.bind(this);
        this.hasPermission = this.hasPermission.bind(this);
        this.goOpen = this.goOpen.bind(this)
    }

    async getAccounts() {
        this.setState({ loadedAccounts: false });

        try {
            let accounts = await axios.get('/accountUser/me/accounts')
            this.setState({ accounts: accounts.data.Accounts, loadedAccounts: true })
        }
        catch (err) {
            this.setState({ accounts: [], loadedAccounts: true })
        }
    }

    navClicked() {
        this.setState((prevState) => ({ navOpen: !prevState.navOpen }), () => {
            if (this.state.navOpen) {
                this.getAccounts();
            }
        });
    }

    goLog() {
        this.props.dispatch(user.logoutAndClear());
    }

    goNav(thePage) {
        this.props.history.push(thePage);
        this.setState({ navOpen: false });
    }

    goOpen(url) {
        window.open(url, '_blank');
        this.setState({ navOpen: false });
    }

    hasPermission(name, method) {
        return PermissionChecker(name, this.props.permissionStore.permissions, method)
    }

    handleCompanySwitcherMouseEnter() {
        clearTimeout(this.companySwitcherLeaveTimerRef.current);
        this.setState({ companySwitcherOpen: true });
    }

    handleCompanySwitcherMouseLeave() {
        this.companySwitcherLeaveTimerRef.current = setTimeout(() => {
            this.setState({ companySwitcherOpen: false })
        }, 300);
    }

    render() {
        var showBilling = true;
        if (this.props.siteMaster.hidePricing) showBilling = false;
        if (this.props.accountMaster.accountMaster.invoice) showBilling = false;

        let canSwitchAccount = true;
        if (this.props.siteMaster.blockSwitchAccount) canSwitchAccount = false;

        const iconProps = { style: { height: 20, width: 20 }, color: "#A9A9AD" }

        return (
            <div className="pos-rel">
                <button type="button" className="account-menu-button" onClick={this.navClicked} data-test="trigger-button">
                    <span className="account-menu-button-name">{this.props.accountMaster.accountMaster.accountName}</span>
                    <UILIB.Avatar for="account" src={this.props.user.userData.profilePicFileName} />
                    <UILIB.Icons color="#666666" icon="smallArrowDown" />
                </button>

                <UILIB.DropDown isOpen={this.state.navOpen} onClose={() => this.setState({ navOpen: false })}>
                    <ul className="bb_account faux-list">
                        {canSwitchAccount && (
                            <li
                                onMouseEnter={() => this.handleCompanySwitcherMouseEnter()}
                                onMouseLeave={() => this.handleCompanySwitcherMouseLeave()}
                            >
                                <div className={"uiTabSubIcon"}>
                                    <UILIB.Icons icon="arrowLeftSmall" {...iconProps} />
                                </div>
                                <div className="uiTabSubText">Company Switcher</div>

                                <CompanySelect
                                    activeAccount={this.props.accountMaster.accountMaster}
                                    accounts={this.state.accounts}
                                    loadedAccounts={this.state.loadedAccounts}
                                    isOpen={this.state.companySwitcherOpen}
                                    onChangeAccount={this.props.onChangeAccount}
                                />
                            </li>
                        )}

                        {this.hasPermission('company', 'read') && <li onClick={() => this.goNav('/cp/company')}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="office" {...iconProps} /></div>
                            <div className="uiTabSubText">{i18n.t('nav:companyProfile')}</div>
                            {/* <div className={'uiTabSubIconEnd'}>{sub.endIcon}</div> */}
                        </li>}
                        {!this.props.siteMaster.readOnlyUsers && <li onClick={() => this.goNav('/cp/account/')}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="person" {...iconProps} /></div>
                            <div className="uiTabSubText">{i18n.t('nav:myProfile')}</div>
                        </li>}
                        <hr className="light-hr" />

                        {this.hasPermission('company', 'write') && showBilling && <li onClick={() => this.goNav('/cp/company/subscription')}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="doubleArrowUp" {...iconProps} /></div>
                            <div className="uiTabSubText">{i18n.t('nav:upgrade')}</div>
                        </li>}
                        {/* {this.hasPermission('company', 'write') && <li>
                                <a onClick={() => { this.goNav('/cp/company/sms') }}>SMS </a> <span style={{ fontWeight: "bold", marginLeft: "5px", color: "red", fontSize: "10px" }}>NEW</span>
                            </li>} */}
                        {this.hasPermission('company', 'write') && <li onClick={() => this.goNav('/cp/company/integrations')}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="integrations" {...iconProps} /></div>
                            <div className="uiTabSubText">{i18n.t('nav:integrations')}</div>
                        </li>}
                        {this.hasPermission('company', 'write') && <li onClick={() => this.goNav('/cp/transactions')}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="switch" {...iconProps} /></div>
                            <div className="uiTabSubText">{i18n.t('nav:transactions')}</div>
                        </li>}
                        {this.hasPermission('company', 'write') && <li onClick={() => this.goNav('/cp/trackers')}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="globe" {...iconProps} /></div>
                            <div className="uiTabSubText">{i18n.t('nav:siteTracking')}</div>
                        </li>}
                        {this.props.siteMaster.siteId == 1 && <li onClick={() => this.goNav('/cp/releases')}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="sparkle" {...iconProps} /></div>
                            <div className="uiTabSubText">Release History</div>
                        </li>}
                        {this.props.siteMaster.siteId == 1 && <li onClick={() => this.goNav('/cp/affiliate')}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="megaphone" {...iconProps} /></div>
                            <div className="uiTabSubText">{i18n.t('nav:affiliate')}</div>

                        </li>}
                        {this.props.siteMaster.siteId == 1 && this.props.user.userData.roleId === 1 && <li onClick={() => this.goNav('/cp/labs')}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="beaker" {...iconProps} /></div>
                            <div className="uiTabSubText">Labs</div>
                            <div className={'uiTabSubIconEnd'}><UILIB.SquareChip className="square-chip-green">New</UILIB.SquareChip></div>
                        </li>}
                        {this.props.siteMaster.siteId == 1 && <li onClick={() => this.goOpen("https://agency.mpzmail.com")}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="money" {...iconProps} /></div>
                            <div className="uiTabSubText">{i18n.t('nav:agencyReseller')}</div>
                            <div className={'uiTabSubIconEnd'}><UILIB.Icons icon="external" {...iconProps} /></div>
                        </li>}
                        <li onClick={() => this.goOpen("/apidocs")}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="document" {...iconProps} /></div>
                            <div className="uiTabSubText">{i18n.t('nav:apiDocs')}</div>
                            <div className={'uiTabSubIconEnd'}><UILIB.Icons icon="external" {...iconProps} /></div>
                        </li>
                        {this.props.siteMaster.supportUrl && this.props.siteMaster.supportUrl.length && <li onClick={() => this.goOpen(this.props.siteMaster.supportUrl)}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="lifering" {...iconProps} /></div>
                            <div className="uiTabSubText">{i18n.t('nav:help')}</div>
                            <div className={'uiTabSubIconEnd'}><UILIB.Icons icon="external" {...iconProps} /></div>
                        </li>}

                        <hr className="light-hr" />
                        <li onClick={this.goLog}>
                            <div className={'uiTabSubIcon'}><UILIB.Icons icon="logout" {...iconProps} color="#CC4E32" /></div>
                            <div className="uiTabSubText" style={{ color: '#CC4E32' }}>{i18n.t('nav:logout')}</div>
                        </li>
                    </ul>
                </UILIB.DropDown>
            </div>
        );
    }
}

import React from "react";
import { useDispatch } from "react-redux";
import * as siteMaster from "~/data/actions/siteActions";
import UILIB from "~/Common-Objects/Lib";
import LinkChecker from "./linkChecker";

const CheckLinksButton = ({ templateId, onClick, ...rest }) => {
  const dispatch = useDispatch();

  const drawerContent = <LinkChecker templateId={templateId} />;

  async function checkLinks() {
    if (onClick && typeof (onClick === "function")) {
      await onClick();
    }

    dispatch(
      siteMaster.alterSiteDrawer(
        true,
        true,
        "right",
        drawerContent,
        true,
        "500px"
      )
    );
  }

  return (
    <UILIB.Button onClick={checkLinks} {...rest}>
      Check Links
    </UILIB.Button>
  );
};

export default CheckLinksButton;

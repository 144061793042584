import { useEffect, useState } from "react"

export default function useViewPort() {
    const [width, setWidth] = useState(window.innerWidth)

    const handleResize = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return function () {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return width
}
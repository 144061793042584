import React from "react";
import { useSelector } from 'react-redux'
import i18n from "i18next";
import classNames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import * as domains from "~/Classes/domains";
import TextFunctions from "~/Classes/textFunctions";

const RED = "#CC4E32";
const GREEN = "#10B982";

function getHeading(status, type) {
  switch (status) {
    case domains.NOT_SETUP: {
      return null;
    }

    case domains.REQUIRES_VERIFICATION: {
      return {
        icon: <UILIB.Icons icon="warning" color={RED} />,
        title: i18n.t("company:domains.requiresVerification"),
        color: RED,
      };
    }

    case domains.REQUIRES_AUTHENTICATION: {
      return {
        icon: <UILIB.Icons icon="warning" color={RED} />,
        title: i18n.t("company:domains.requiresAuthentication"),
        color: RED,
      };
    }

    case domains.VERIFIED: {
      return {
        icon: <UILIB.Icons icon="tickCircle" color={GREEN} />,
        title: i18n.t("company:domains.verified"),
        color: GREEN,
      };
    }

    case domains.AUTHENTICATED: {
      if (type === domains.SENDING_DOMAIN) {
        return {
          icon: <UILIB.Icons icon="tickCircleDouble" color={GREEN} />,
          title: i18n.t("company:domains.authenticated"),
          color: GREEN,
        };
      }

      return {
        icon: <UILIB.Icons icon="tickCircle" color={GREEN} />,
        title: i18n.t("company:domains.authenticated"),
        color: GREEN,
      };
    }
  }
}

function getMessage(status, type, email, site) {
  switch (status) {
    case domains.NOT_SETUP: {
      if (type === domains.SENDING_DOMAIN) {
        return null;
      }

      return i18n.t(`company:domains.${type.id}.notSetupMsg`);
    }

    case domains.REQUIRES_VERIFICATION: {
      if (type === domains.SENDING_DOMAIN) {
        return i18n.t("company:domains.sending.requiresVerificationMsg", {
          email,
        });
      }

      return null;
    }

    case domains.REQUIRES_AUTHENTICATION: {
      if (type === domains.SENDING_DOMAIN) {
        return null;
      } else if (type === domains.TRACKING_DOMAIN) {
        return i18n.t("company:domains.tracking.requiresAuthenticationMsg", {
          domainName: site.trackingDomain,
        });
      }

      return i18n.t("company:domains.cdn.requiresAuthenticationMsg");
    }

    case domains.VERIFIED: {
      if (type === domains.SENDING_DOMAIN) {
        return i18n.t("company:domains.sending.verifiedMsg", { siteName: site.siteName });
      }

      return null;
    }

    case domains.AUTHENTICATED: {
      return i18n.t(`company:domains.${type.id}.authenticatedMsg`);
    }
  }
}

export default function DomainStatus({
  status,
  type,
  domainName,
  email,
  className,
  ...rest
}) {
  const site = useSelector(state => state.siteMaster)
  const heading = getHeading(status, type);

  const classes = classNames("domain-status text-xsml", className);

  return (
    <div className={classes} {...rest}>
      {heading && (
        <div
          className="domain-status__heading fw-medium"
          style={{ color: heading.color }}
        >
          {heading.icon}
          {heading.title}
        </div>
      )}

      <p
        className="mar-b0"
        dangerouslySetInnerHTML={{
          __html: TextFunctions.checkForHtml(
            getMessage(status, type, email, site)
          ),
        }}
      />
    </div>
  );
}

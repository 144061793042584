import i18n from '~/i18n'

class SegmentHelpers {

    static getOperators(opType) {
        return this[opType + "Operators"].slice().map(o => {
            o.label = i18n.t(o.label);
            return o;
        });
    }

    static stringOperators = [{
        value: '=', label: 'subscribers:segments.edit.operators.equal', iconLeft: "equals", positive: true
    }, {
        value: '!=', label: 'subscribers:segments.edit.operators.notEqual', iconLeft: "notEquals", iconLeft: "notEquals" , positive: false
    }, {
        value: 'start with', label: 'subscribers:segments.edit.operators.startWith', iconLeft: "startsWith" , positive: true
    }, {
        value: 'end with', label: 'subscribers:segments.edit.operators.endWith', iconLeft: "endsWith" , positive: true
    }, {
        value: 'contain', label: 'subscribers:segments.edit.operators.contain', iconLeft: "contains" , positive: true
    }, {
        value: 'not start with', label: 'subscribers:segments.edit.operators.notStartWith', iconLeft: "doesNotStartWith" , positive: false
    }, {
        value: 'not end with', label: 'subscribers:segments.edit.operators.notEndWith', iconLeft: "doesNotEndWith" , positive: false
    }, {
        value: 'not contain', label: 'subscribers:segments.edit.operators.notContain', iconLeft: "doesNotContain" , positive: false
    },
    {
        value: 'is blank', label: 'Is Blank', iconLeft: "isBlank" , positive: false
    }, {
        value: 'is not blank', label: 'Is Not Blank', iconLeft: "isNotBlank" , positive: true
    }]

    static intOperators = [{
        value: '=', label: 'subscribers:segments.edit.operators.equal', iconLeft: "equals", positive: true
    }, {
        value: '!=', label: 'subscribers:segments.edit.operators.notEqual', iconLeft: "notEquals", positive: false
    }, {
        value: 'in', label: 'subscribers:segments.edit.operators.in', iconLeft: "between", positive: true
    }, {
        value: 'not in', label: 'subscribers:segments.edit.operators.notIn', iconLeft: "notEquals", positive: false
    }, {
        value: '>', label: 'subscribers:segments.edit.operators.greaterThan', iconLeft: "greaterThan", positive: true
    }, {
        value: '<', label: 'subscribers:segments.edit.operators.lessThan', iconLeft: "lessThan", positive: true
    }, {
        value: '>=', label: 'subscribers:segments.edit.operators.greaterEqualThan', iconLeft: "greaterThanOrEquals", positive: true
    }, {
        value: '<=', label: 'subscribers:segments.edit.operators.lessEqualThan', iconLeft: "lessThanOrEquals", positive: true
    },
    {
        value: 'is blank', label: 'Is Blank', iconLeft: "isBlank", positive: false
    }, {
        value: 'is not blank', label: 'Is Not Blank', iconLeft: "isNotBlank", positive: true
    }]

    static boolOperators = [{
        value: '=', label: 'subscribers:segments.edit.operators.equal', iconLeft: "equals", positive: true
    }, {
        value: '!=', label: 'subscribers:segments.edit.operators.notEqual', iconLeft: "notEquals", positive: false
    }]

    static dateOperators = [{
        value: '=', label: 'subscribers:segments.edit.operators.equal', iconLeft: "equals", positive: true
    }, {
        value: '!=', label: 'subscribers:segments.edit.operators.notEqual', iconLeft: "notEquals", positive: false
    }, {
        value: '>', label: 'subscribers:segments.edit.operators.greaterThanDate', iconLeft: "greaterThan", positive: true
    }, {
        value: '<', label: 'subscribers:segments.edit.operators.lessThanDate', iconLeft: "lessThan", positive: true
    }, {
        value: '>=', label: 'subscribers:segments.edit.operators.greaterEqualThanDate', iconLeft: "greaterThanOrEquals", positive: true
    }, {
        value: '<=', label: 'subscribers:segments.edit.operators.lessEqualThanDate', iconLeft: "lessThanOrEquals", positive: true
    }, {
        value: 'between', label: 'subscribers:segments.edit.operators.between', iconLeft: "between", positive: true
    }, {
        value: 'not between', label: 'subscribers:segments.edit.operators.notBetween', iconLeft: "notEquals", positive: false
    },
    {
        value: 'is blank', label: 'subscribers:segments.edit.operators.isBlank', iconLeft: "isBlank", positive: false
    }, {
        value: 'is not blank', label: 'subscribers:segments.edit.operators.isNotBlank', iconLeft: "isNotBlank", positive: true
    },
    {
        value: 'be days after', label: 'subscribers:segments.edit.operators.beDaysAfter', iconLeft: "equals", positive: true
    },
    {
        value: 'be days before', label: 'subscribers:segments.edit.operators.beDaysBefore', iconLeft: "equals", positive: true
    },
    {
        value: 'be more days after', label: 'subscribers:segments.edit.operators.beMoreDaysAfter', iconLeft: "greaterThanOrEquals", positive: true
    },
    {
        value: 'be more days before', label: 'subscribers:segments.edit.operators.beMoreDaysBefore', iconLeft: "greaterThanOrEquals", positive: true
    },
    {
        value: 'be less days after', label: 'subscribers:segments.edit.operators.beLessDaysAfter', iconLeft: "lessThanOrEquals", positive: true
    },
    {
        value: 'be less days before', label: 'subscribers:segments.edit.operators.beLessDaysBefore', iconLeft: "lessThanOrEquals", positive: true
    }]

    static groupOperators = [{
        value: '=', label: 'subscribers:segments.edit.operators.equal', iconLeft: "equals", positive: true
    }, {
        value: '!=', label: 'subscribers:segments.edit.operators.notEqual', iconLeft: "notEquals", positive: false
    }]

    static selectOperators = [
        {
            value: '=', label: 'subscribers:segments.edit.operators.equal', iconLeft: "equals", positive: true
        }, {
            value: '!=', label: 'subscribers:segments.edit.operators.notEqual', iconLeft: "notEquals", positive: false
        },
        {
            value: 'is blank', label: 'Is Blank', iconLeft: "isBlank", positive: false
        }, {
            value: 'is not blank', label: 'Is Not Blank', iconLeft: "isNotBlank", positive: true
        }
    ]

    static linkTagOperators = [
        { value: '=', label: 'subscribers:segments.edit.operators.equal', iconLeft: "equals", positive: true },
        { value: '!=', label: 'subscribers:segments.edit.operators.notEqual', iconLeft: "notEquals", positive: false },
        { value: 'within', label: 'subscribers:segments.edit.operators.within', iconLeft: "between", positive: true },
        { value: 'not within', label: 'subscribers:segments.edit.operators.notWithin', iconLeft: "notEquals", positive: false },
    ]

    static scoreOperators = [{
        value: 'starRating', label: 'Star Rating Be', iconLeft: "starRatingIs", positive: true
    },
    {
        value: '=', label: 'Score Is', iconLeft: "equals", positive: true
    }, {
        value: '!=', label: 'Score Is Not ', iconLeft: "notEquals", positive: false
    }, {
        value: '>', label: 'Score Greater Than', iconLeft: "greaterThan", positive: true
    }, {
        value: '<', label: 'Score Less Than', iconLeft: "lessThan", positive: true
    }, {
        value: '>=', label: 'Score Greater or Equal To', iconLeft: "greaterThanOrEquals", positive: true
    }, {
        value: '<=', label: 'Score Less Than or Equal To', iconLeft: "lessThanOrEquals", positive: true
    }
    ]

}

export default SegmentHelpers;

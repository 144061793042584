import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import UILIB from '../../../../Common-Objects/Lib'
import { alterSiteDrawer, alterSnackbar } from '../../../../data/actions/siteActions'
import axios from '../../../../data/http/axios'
import i18n from '../../../../i18n'

export default function AutomationAddContact({ AutomationId, status, finished = () => { } }) {
    const [contact, setContact] = useState(null)
    const dispatch = useDispatch()

    const addContact = async () => {
        try {
            const res = await axios.post('/automation/' + AutomationId + '/subscriber/' + contact)
            dispatch(alterSnackbar(true, i18n.t("automation:add.addContactDrawer.added")))
            finished(res.data.status)
            dispatch(alterSiteDrawer(false, true, 'right', null))
        } catch (e) {
            console.log(e.data.error)
            dispatch(alterSnackbar(true, e.data.error))
        }

    }

    const inTest = status === 'Testing' || status === 'Draft' || status === 'Paused'
    let header = i18n.t("automation:add.addContactDrawer.header")
    if (inTest) {
        header = i18n.t("automation:add.addContactDrawer.headerTest")
    }

    return (
        <div>
            <h4 className='mar-b25'>{header}</h4>
            <div className='mar-b25'>{i18n.t("automation:add.addContactDrawer.content1")}</div>
            <div className='mar-b25'>{i18n.t("automation:add.addContactDrawer.content2")}</div>
            {inTest && <div className='mar-b25'>{i18n.t("automation:add.addContactDrawer.contentTest")}</div>}
            <UILIB.AutoComplete
                outerClassName="mar-b25"
                iconLeft={<UILIB.Icons icon="person" />}
                label={i18n.t("automation:add.addContactDrawer.search")}
                url="/subscriber?search={{name}}"
                parser={d => d.Subscribers.map(a => ({ label: a.emailAddress, value: a.id }))}
                onChange={e => setContact(e.value)}
            />
            <UILIB.Button disabled={!contact} className="button-primary" onClick={addContact} iconLeft={<UILIB.Icons icon="plus" />}>{i18n.t("automation:add.addContactDrawer.add")}</UILIB.Button>

        </div>
    )
}
import React, { useState, useReducer } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import * as styles from "./index.module.scss";
import axios from "~/data/http/axios";
import Header from "../../../components/header";
import TextInput from "../../../components/textInput";
import Button from "../../../components/button";
import i18n from "~/i18n";
import SettingsSection from "../../../components/settingsSection";
import DeleteChannelDialog from "../../../inboxSettings/channels/components/deleteChannelDialog";

export default function WebchatChannelGeneral({
    channel,
    onUpdateChannel,
}) {
    const dispatch = useDispatch();
    const history = useHistory()
    const [submitting, setSubmitting] = useState(false);
    const [name, setName] = useState(channel.name);
    const [error, setError] = useState("");
    const [isDeleteChannelDialogOpen, setIsDeleteChannelDialogOpen] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        setError("")
        if (!name) return setError(i18n.t("chat:channel.email.general.nameRequired"));
        if (name.trim().length < 3) return setError(i18n.t("chat:channel.email.general.nameLength"));
        if (name.length > 100) return setError(i18n.t("chat:channel.email.general.nameMaxLength"));
        setSubmitting(true);

        const data = { name };

        try {
            await onUpdateChannel(data);
            dispatch(alterSnackbar(true, i18n.t("chat:channel.email.general.saved")));
        } catch (error) {
            dispatch(handleGenericError(error));
        } finally {
            setSubmitting(false);
        }
    }


    async function handleDelete() {
        try {
            await axios.delete(`/inboxes/${channel.InboxId}/channel/${channel.id}`);
            history.push(`/cp/conversations/${channel.InboxId}/channels`);
        } catch (error) {
            dispatch(handleGenericError(error));
            throw error;
        }
    }

    return (
        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
            <Header type="webchat" option="general" />
            <form className={styles.root} onSubmit={handleSubmit}>
                <TextInput
                    label={i18n.t("chat:channel.email.general.name")}
                    value={name}
                    error={error}
                    onChange={(e) => setName(e.target.value)}
                    maxlength={100}
                />
                <Button
                    className={styles.submitButton}
                    type="submit"
                    size="s"
                    loading={submitting}
                >
                    {i18n.t("chat:channel.email.general.save")}
                </Button>
            </form>

            <hr></hr>
            <SettingsSection heading={i18n.t("chat:channel.email.general.deleteHeader")} subheading={i18n.t("chat:channel.email.general.deleteSubheader")}>
                <Button
                    className={styles.submitButton}
                    size="s"
                    variant="danger"
                    onClick={() => setIsDeleteChannelDialogOpen(true)}
                >
                    {i18n.t("chat:channel.email.general.deleteButton")}
                </Button>
            </SettingsSection>

            <DeleteChannelDialog
                key={`delete-${channel?.id}`} // For resetting dialog state when closed/opened
                channel={channel}
                isOpen={isDeleteChannelDialogOpen}
                onSubmit={handleDelete}
                onOpenChange={(isOpen) => setIsDeleteChannelDialogOpen(isOpen)}
            />
        </div>
    );
}

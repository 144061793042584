// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e4yH1T9xtK0HMUMPWFY_{display:flex;align-items:center;gap:16px}.e4yH1T9xtK0HMUMPWFY_ form{flex:1}.fgtDNSfVFPIANkaGZmFn:hover{text-decoration:underline}.YRTuMb8FUEDO3I57eHgr{display:flex;align-items:center;gap:8px}.DPEysusltUo_C4EfsR5g{display:flex;align-items:center;justify-content:flex-end;gap:12px}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/channel/webchat/prompts/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,2BACE,MAAA,CAKF,4BACE,yBAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,QAAA","sourcesContent":[".topRow {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n\n  form {\n    flex: 1;\n  }\n}\n\n.tableName {\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n.tableLocations {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.tableActions {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topRow": "e4yH1T9xtK0HMUMPWFY_",
	"tableName": "fgtDNSfVFPIANkaGZmFn",
	"tableLocations": "YRTuMb8FUEDO3I57eHgr",
	"tableActions": "DPEysusltUo_C4EfsR5g"
};
export default ___CSS_LOADER_EXPORT___;

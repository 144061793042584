import React from 'react'
import LoadingIcon1 from '../../assets/images/loadingIcons/Loading_icon.gif'
import ImageNotFound from '../../assets/images/imageNotFound/imageNotFound.jpg'
export default class LoadingIcons extends React.Component {

    constructor(props) {
        super(props);
        this.state = { loaded: false }
    }
    render() {
        return <div>
            {this.state.loaded == 0 &&
                <img src={LoadingIcon1} style={this.props.style} />
            }
            {this.state.loaded == 2 &&
                <img src={ImageNotFound} style={this.props.style} />
            }
            <img
                style={this.state.loaded === 1 ? this.props.style : { display: 'none' }}
                src={this.props.src}
                onLoad={() => this.setState({ loaded: 1 })}
                onError={() => this.setState({ loaded: 2 })}
            />
        </div>
    }
}

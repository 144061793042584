import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UILIB from '../../../../../Common-Objects/Lib'
import axios from '../../../../../data/http/axios'
import helpers from '../../../../../Classes/helpers'
import { alterSiteDrawer } from '../../../../../data/actions/siteActions'
import i18n from '../../../../../i18n'

export default function SocialAutomate({ CampaignId, campaignName, save = () => { } }) {
    const site = useSelector(state => state.siteMaster)
    const history = useHistory()
    const dispatch = useDispatch()
    const [trigger, setTrigger] = useState('9')
    const [name, setName] = useState(campaignName + ' Automation')

    const create = async () => {
        await save()
        const res = await axios.post('/automation', {
            name,
            version: 1,
            status: 'Draft',
            steps: [{
                "guid": helpers.createLocalGuid(),
                "type": "initial",
                "complete": true,
                "parentGuid": 0,
                "stepOptions": { "groupId": { "value": "-1", "required": true }, "campaignId": { "value": CampaignId, "required": true } },
                "triggerType": { "value": trigger, "required": true },
                "wizardComplete": true
            }],
            options: {
                source: 'Social Wizard'
            }
        })
        history.push('/cp/automation/addnew/' + res.data.Automation.id + '?redirect=/cp/campaigns/add/social/' + CampaignId + '/3')
        dispatch(alterSiteDrawer(false, true, 'right', null))
    }

    return (
        <div>
            <div className="quickFlex mar-b10" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <h4>{i18n.t('social:automate.header')}</h4>
                {site.siteId === 1 && <UILIB.Hint href="https://kb.transpond.io/article/61-what-is-automation" iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>Learn More</UILIB.Hint>}
            </div>

            <p className='mar-b25 text-sml'>{i18n.t('social:automate.info')}</p>

            {!!trigger && <UILIB.TextInput label={i18n.t('social:automate.name')} value={name} onChange={e => setName(e.target.value)} outerClassName="mar-b25" />}

            {!!trigger && !!name && <>
                <UILIB.WarningHolder className="mar-b25">{i18n.t('social:automate.warning')}</UILIB.WarningHolder>
                <UILIB.Button className="button-primary" onClick={create}>{i18n.t('social:automate.create')}</UILIB.Button>
            </>}
        </div>
    )
}
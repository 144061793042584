import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import UILIB from '../../../../../Common-Objects/Lib'
import axios from '../../../../../data/http/axios'
import i18n from '../../../../../i18n'
import PermissionChecker from '../../../../../Classes/permissions'
import EmailValidator from '../../../../../Classes/emailValidator'

export default function OrganisationFields({ organisation, refresh = () => { } }) {
    const [org, setOrg] = useState(JSON.parse(JSON.stringify(organisation)))
    const [customFields, setCustomFields] = useState(null)
    const [alert, setAlert] = useState('')
    const [alertOpen, setAlertOpen] = useState(false)
    const permissionStore = useSelector(state => state.permission)

    const updateCustomField = (index, value) => {
        const field = customFields[index]
        const fields = { ...org.customFields }
        fields[field.id] = value
        setOrg({ ...org, customFields: fields })
    }

    const updateName = (e) => {
        setOrg({ ...org, name: e.currentTarget.value })
    }

    const updateEmail = (e) => {
        setOrg({ ...org, emailAddress: e.currentTarget.value })
    }

    const save = async () => {
        if (!org.name) {
            setAlert('Name Required')
            return setAlertOpen(true)
        }

        if (org.emailAddress) {
            if (!EmailValidator.validateEmail(org.emailAddress)) {
                setAlert('Invalid Email Address')
                return setAlertOpen(true)
            }
        }
        setAlert('Organisation Updating')
        setAlertOpen(true)
        try {
            await axios.put('/organisation/' + org.id, org)
            setAlert('Organisation Updated')
            setAlertOpen(true)
            refresh()
        } catch (e) {
            setAlert(e.data.error)
            setAlertOpen(true)
        }
    }

    useEffect(() => {
        axios.get('/customfield').then(res => {
            setCustomFields(res.data.CustomFields.filter(f => f.entityType === 2 || f.entityType === 3))
        })
    }, [])

    const writeAccess = PermissionChecker('subscribers', permissionStore.permissions, 'write')

    return (
        <UILIB.Paper isLoading={!customFields}>
            <h4 className="mar-b25">Organisation Data</h4>

            <UILIB.Paper className="paper-lightGrey pad-25" style={{ height: "100%", maxHeight: "400px", overflow: "auto" }} >

                <UILIB.TextInput label="Name" outerClassName="mar-b25" value={org.name} onChange={updateName} />
                <UILIB.TextInput label="Email Address" outerClassName="mar-b25" value={org.emailAddress} onChange={updateEmail} />

                {!!customFields && !!org.customFields && customFields.map((field, index) => {
                    let logo = ''
                    if (field.Application) {
                        logo = <UILIB.Avatar style={{ height: 20, width: 20, top: 5, marginLeft: 5, backgroundColor: 'white', border: '1px solid grey' }} for={field.id + '-App'} tooltip={field.Application.appName} src={field.Application.appLogoUri} />
                    }

                    let value = org.customFields[field.id]

                    if (field.fieldType === "DATE") {
                        if (value === null || value == "" || !moment(value).isValid()) value = undefined
                        return <UILIB.TextInput outerClassName="mar-b25" key={"cf1_" + index} label={field.fieldDesc} disabled={field.readOnly} type={'datetime'} id={field.fieldName} value={value} onChange={(ev, val) => updateCustomField(index, val)} />

                    }
                    if (field.fieldType === 'BOOLEAN') {
                        return <UILIB.CheckBox outerClassName="mar-b25" key={"cf2_" + index} disabled={field.readOnly} checked={value} onChange={ev => updateCustomField(index, ev.target.checked)} name={field.fieldName}>{field.fieldDesc} {logo}</UILIB.CheckBox>
                    }
                    if (field.fieldType === 'SELECT') {
                        var theOpts = JSON.parse(JSON.stringify(field.options))
                        if (!theOpts) theOpts = [];
                        theOpts.unshift({ value: "", label: "" })
                        return <UILIB.Select key={"cf3_" + index} label={<span>{field.fieldDesc} {logo}</span>} explainer={<span>Current Value: {value}</span>} outerClassName="mar-b25" value={value} disabled={field.readOnly} onChange={ev => updateCustomField(index, ev.currentTarget.value)} name={field.fieldName} data={theOpts} />
                    }

                    if (field.fieldType === 'MULTISELECT' && Array.isArray(field.options)) {
                        var theOpts = JSON.parse(JSON.stringify(field.options))
                        if (!theOpts) theOpts = [];
                        theOpts.unshift({ value: "", label: "" })
                        var currVals = value;
                        if (!Array.isArray(currVals)) currVals = [currVals];

                        var multiHeader = "";

                        var newVals = currVals.map((v, i) => {
                            theOpts.forEach(tO => {
                                if (tO.value == v) {
                                    tO.selected = true;
                                    multiHeader += tO.label + ", ";
                                }
                            })
                            return <span className="mar-r5" key={index + "_v_" + i}>{v}</span>
                        })
                        if (multiHeader != "") {
                            multiHeader = multiHeader.substr(0, multiHeader.length - 2)
                        }
                        if (multiHeader == "") {
                            multiHeader = i18n.t('subscribers:index.clickHereToSelect')
                        }

                        currVals = newVals;
                        return <UILIB.ButtonSelect outerClassName="mar-b25" key={"cf3_" + index} explainer={<span>Current Value(s): {currVals}</span>} label={<span>{field.fieldDesc} {logo}</span>} headerText={multiHeader} filter={false} disabled={field.readOnly}>
                            {theOpts && !!theOpts.length && <ul>
                                {theOpts.map((theOpt, optIndex) => {
                                    return <li key={theOpt.value}><a><UILIB.CheckBox key={theOpt.value} name={theOpt.label} onChange={() => updateCustomField(index, theOpt, 'multiselect', theOpts)} checked={theOpt.selected} /> <span className="pad-left">{theOpt.label}</span></a></li>
                                })}
                            </ul>}
                            {(!theOpts || !theOpts.length) && <span>There are no values associated with this field</span>}

                        </UILIB.ButtonSelect>

                    }
                    if (field.fieldType === 'MULTISELECT' && !Array.isArray(field.options)) {
                        value = !Array.isArray(value) ? [] : value
                        return <UILIB.Multi key={"cf4_" + index} outerClassName="mar-b25" label={<span>{field.fieldDesc} {logo}</span>} values={value} disabled={field.readOnly} />
                    }
                    if (field.fieldType === 'TELNO') {
                        return <UILIB.TextInput key={"cf4_" + index} label={<span>{field.fieldDesc} {logo}</span>} outerClassName="mar-b25" disabled={field.readOnly} style={{ width: '100%' }} type={field.fieldType.toLowerCase()} value={value} onChange={ev => updateCustomField(index, ev.currentTarget.value, ev.valid)} />

                    }
                    return <div className="form-group" key={"cf4_" + index} id={field.id}>
                        <UILIB.TextInput outerClassName="mar-b25" key={"cf4_" + index} id={field.id} label={<span>{field.fieldDesc} {logo}</span>} disabled={field.readOnly} type={field.fieldType.toLowerCase()} value={value} onChange={ev => updateCustomField(index, ev.currentTarget.value)} />
                    </div>
                })}
            </UILIB.Paper>
            {
                writeAccess && <div>
                    <UILIB.Button text="Save Changes" className="button-primary" onClick={save} iconLeft={<UILIB.Icons icon="tick" />} />
                </div>
            }

            <UILIB.SnackBar dismiss={() => setAlertOpen(false)} open={alertOpen} message={alert} autoclose={true} />
        </UILIB.Paper >
    )
}
import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "~/data/http/axios";
import {
  alterSiteDrawer,
  alterSnackbar,
  handleGenericError,
} from "~/data/actions/siteActions";
import UILIB from "~/Common-Objects/Lib";
import {
  SENDING_DOMAIN,
  TRACKING_DOMAIN,
  CDN_DOMAIN,
  mergeSubdomainsUnderSendingDomains,
} from "~/Classes/domains";
import { useIsTranspond } from "~/Common-Objects/hooks/siteMaster";
import { useHasWriteAccess } from "~/Common-Objects/hooks/permissions";
import i18n from "~/i18n";
import AddDomain from "./components/legacy/addDomain";
import DeleteDomain from "./components/deleteDomain";
import AuthenticateDomain from "./components/legacy/authenticateDomain";
import SetDefaultPreviewDomain from "./components/legacy/setDefaultPreviewDomain";
import ChangePreviewEmail from "./components/legacy/changePreviewEmail";
import MxRecords from "./components/legacy/mxRecords";
import SendingDomain from "./components/sendingDomain";
import Subdomain from "./components/subdomain";
import AddSubdomain from "./components/addSubdomain";
import AuthenticateSubdomain from "./components/authenticateSubdomain";
import BuyDomainStep1 from "././components/legacy/buyDomainStep1";
import BuyDomainStep2 from "./components/legacy/buyDomainStep2";
import AddDomainAdrom from "./components/legacy/addDomainAdrom";
import AuthenticateDomainAdrom from "./components/legacy/authenticateDomainAdrom";

export default function Domains() {
  const siteMaster = useSelector((state) => state.siteMaster);
  const hasWriteAccess = useHasWriteAccess("domains");
  const dispatch = useDispatch();
  const isTranspond = useIsTranspond();

  const [loading, setLoading] = useState(true);
  const [isAdrom, setIsAdrom] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const [domains, updateDomains] = useReducer((state, action) => {
    const domains = { ...state, ...action };

    return mergeSubdomainsUnderSendingDomains(domains);
  }, []);

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    setIsAdrom(siteMaster.siteId === 2);
  }, [siteMaster]);

  async function loadInitialData() {
    try {
      await loadDomains();
    } finally {
      setLoading(false);
    }
  }

  async function loadDomains() {
    try {
      const [sending, tracking, cdn] = await Promise.all([
        axios.get(`/accountMaster/${SENDING_DOMAIN.path}`),
        axios.get(`/accountMaster/${TRACKING_DOMAIN.path}`),
        axios.get(`/accountMaster/${CDN_DOMAIN.path}`),
      ]);

      const domainsFormatted = {
        sending: sending.data.domains,
        tracking: tracking.data.Domains,
        cdn: cdn.data.Domains,
      };

      updateDomains(domainsFormatted);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  function dispatchSiteDrawer(content, width = "460px") {
    dispatch(alterSiteDrawer(true, true, "right", content, true, width));
  }

  function closeSiteDrawer() {
    dispatch(alterSiteDrawer(false));
  }

  function buyDomain() {
    dispatchSiteDrawer(
      <BuyDomainStep1 onContinue={handleBuyDomainContinue} />,
      "700px"
    );
  }

  function handleBuyDomainContinue(domainName, domainResult) {
    dispatchSiteDrawer(
      <BuyDomainStep2
        domainName={domainName}
        domainResult={domainResult}
        onBought={() => handleAddedDomain(SENDING_DOMAIN)}
        onCloseDrawer={closeSiteDrawer}
      />,
      "700px"
    );
  }

  function addSendingDomain() {
    if (isAdrom) {
      dispatchSiteDrawer(
        <AddDomainAdrom onAdded={() => handleAddedDomain(SENDING_DOMAIN)} />
      );
    } else {
      dispatchSiteDrawer(
        <AddDomain onAdded={() => handleAddedDomain(SENDING_DOMAIN)} />
      );
    }
  }

  async function handleAddDomain(values, domainType) {
    const data = { domainName: values.domainName };

    try {
      await axios.post(`/accountMaster/${domainType.path}`, data);
    } catch (error) {
      throw error;
    }
  }

  async function handleAddedDomain(domainType = SENDING_DOMAIN) {
    await loadDomains();
    dispatch(
      alterSnackbar(true, i18n.t(`company:domains.${domainType.id}.added`))
    );
    closeSiteDrawer();
  }

  function deleteDomain(domain, domainType = SENDING_DOMAIN) {
    dispatchSiteDrawer(
      <DeleteDomain
        domain={domain}
        domainType={domainType}
        onDelete={handleDeleteDomain}
        onDeleted={handleDeletedDomain}
      />
    );
  }

  async function handleDeleteDomain(domain, domainType) {
    try {
      await axios.delete(`/accountMaster/${domainType.path}/${domain.id}`);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function handleDeletedDomain(domainType) {
    await loadDomains();
    dispatch(
      alterSnackbar(true, i18n.t(`company:domains.${domainType.id}.deleted`))
    );
    closeSiteDrawer();
  }

  async function resendAuthEmail(domain) {
    try {
      await axios.get(`/accountMaster/domain/resendAuthEmail/${domain.id}`);
      dispatch(alterSnackbar(true, i18n.t("company:domains.alertEmailSent")));
    } catch (error) {
      dispatch(alterSnackbar(true, i18n.t("company:domains.alertErrorEmail")));
      throw error;
    }
  }

  function authenticateDomain(domain) {
    if (isAdrom) {
      dispatchSiteDrawer(
        <AuthenticateDomainAdrom
          domain={domain}
          onAuthenticated={handleAuthenticatedDomain}
        />
      );
    } else {
      dispatchSiteDrawer(
        <AuthenticateDomain
          domain={domain}
          onAuthenticated={handleAuthenticatedDomain}
        />
      );
    }
  }

  function openMxRecordsDrawer(domain) {
    dispatchSiteDrawer(
      <MxRecords domain={domain} onChecked={handleCheckedMxRecords} />,
      "100vw"
    );
  }

  function handleCheckedMxRecords() {
    dispatch(alterSnackbar(true, i18n.t("company:domains.mxRecord.checked")));
    closeSiteDrawer();
  }

  function setDefaultPreviewDomain(domain) {
    dispatchSiteDrawer(
      <SetDefaultPreviewDomain
        domain={domain}
        onSelected={handleSelectDefaultPreviewDomain}
      />
    );
  }

  async function handleSelectDefaultPreviewDomain(domain) {
    try {
      await axios.put(`/accountMaster/domain/${domain.id}/setPreviewDefault`);
      await loadDomains();
      dispatch(
        alterSnackbar(true, i18n.t("company:domains.defaultPreviewDomainSet"))
      );
      closeSiteDrawer();
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  function changeDomainPreviewAddress(domain) {
    dispatchSiteDrawer(
      <ChangePreviewEmail
        id={domain.id}
        onChanged={handleChangedPreviewEmail}
      />
    );
  }

  function handleChangedPreviewEmail() {
    dispatch(
      alterSnackbar(true, i18n.t("company:domains.previewEmailChanged"))
    );
    closeSiteDrawer();
  }

  function addSubdomain(domainType, topLevelDomain) {
    dispatchSiteDrawer(
      <AddSubdomain
        domainType={domainType}
        topLevelDomain={topLevelDomain}
        onAdd={handleAddDomain}
        onAdded={handleAddedDomain}
      />
    );
  }

  function authenticateSubdomain(domain, domainType) {
    dispatchSiteDrawer(
      <AuthenticateSubdomain
        domain={domain}
        domainType={domainType}
        onAuthenticate={handleAuthenticateDomain}
        onAuthenticated={handleAuthenticatedDomain}
      />,
      "600px"
    );
  }

  async function handleAuthenticateDomain(domain, domainType) {
    const EXPECTED_EXCEPTION = "expectedException";

    try {
      const { data } = await axios.put(
        `/accountMaster/${domainType.path}/${domain.id}/authenticate`
      );

      if (data.error) {
        dispatch(alterSnackbar(true, data.error));
        throw new Error(EXPECTED_EXCEPTION);
      }
    } catch (error) {
      if (error.message === EXPECTED_EXCEPTION) {
        throw error;
      }

      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function handleAuthenticatedDomain(domainType = SENDING_DOMAIN) {
    await loadDomains();
    dispatch(
      alterSnackbar(
        true,
        i18n.t(`company:domains.${domainType.id}.authenticated`)
      )
    );
    closeSiteDrawer();
  }

  function handleChangePage(page) {
    setCurrentPage(page);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function handleSearch(e) {
    setCurrentPage(0);
    setSearchTerm(e.target.value);
  }

  function showDmarc() {
    dispatchSiteDrawer(
      <DmarcDrawer />,
      "600px"
    );
  }

  const filteredDomains = domains.filter((domain) =>
    domain.domainName.includes(searchTerm)
  );

  const slicedDomains = filteredDomains.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );

  const sendingDomains = slicedDomains.filter(
    (domain) => domain.domainType === SENDING_DOMAIN.id
  );

  const trackingDomains = slicedDomains.filter(
    (domain) => domain.domainType === TRACKING_DOMAIN.id
  );

  const cdnDomains = slicedDomains.filter(
    (domain) => domain.domainType === CDN_DOMAIN.id
  );

  if (loading) return <UILIB.LoadingIcons />;

  return (
    <div className="company-domains">
      <h1 className="visually-hidden">{i18n.t("company:nav.domains")}</h1>

      <div className="company-domains__header">
        <UILIB.TextInput
          value={searchTerm}
          onChange={handleSearch}
          placeholder={i18n.t("company:domains.searchPlaceholder")}
        />

        {hasWriteAccess && (
          <div className="company-domains__primary-ctas">
            {isTranspond && (
              <UILIB.Button onClick={buyDomain}>
                {i18n.t("company:domains.buyDomain")}
              </UILIB.Button>
            )}

            <UILIB.Button
              className="button-primary"
              iconLeft={<UILIB.Icons icon="plus" />}
              onClick={addSendingDomain}
            >
              {i18n.t("company:domains.sendingAdd")}
            </UILIB.Button>
          </div>
        )}
      </div>

      {filteredDomains.length > 0 ? (
        <>
          {sendingDomains.length > 0 && (
            <div className="company-domains__sending-domains">
              <h2 className="h4 mar-b24">
                {i18n.t("company:domains.sending.header")}
              </h2>

              <ul className="faux-list">
                {sendingDomains.map((domain) => {
                  const dmarc = domains.length < 10 && <UILIB.Paper className="no-marg" style={{ padding: 16 }}>
                    <div className="quickFlex" style={{ alignItems: 'center' }}>
                      <div style={{ flexGrow: 1 }}>
                        <h5 className="text-md fw-medium quickFlex" style={{ marginBottom: 8 }}>
                          DMARC
                          {!!domain.dmarc && <UILIB.Icons style={{ marginLeft: 2, height: 16, width: 16 }} icon="tickCircle" color={"#10B982"} />}
                          {!domain.dmarc && <UILIB.Icons style={{ marginLeft: 2, height: 16, width: 16 }} icon="warning" color={"#CC4E32"} />}
                        </h5>
                        <div className="text-xsml">{i18n.t("company:domains.sending.dmarc.header")}</div>
                      </div>
                      <UILIB.Button onClick={showDmarc}>{i18n.t("company:domains.sending.dmarc.learnMore")}</UILIB.Button>
                    </div>
                  </UILIB.Paper>

                  return (
                    <li key={domain.id}>
                      <SendingDomain
                        domain={domain}
                        hideMxRecordsCta={isAdrom}
                        onDeleteClick={() =>
                          deleteDomain(domain, SENDING_DOMAIN)
                        }
                        onResendAuthEmailClick={() => resendAuthEmail(domain)}
                        onAuthenticateClick={() => authenticateDomain(domain)}
                        onMxRecordsClick={() => openMxRecordsDrawer(domain)}
                        onSetDefaultPreviewClick={() =>
                          setDefaultPreviewDomain(domain)
                        }
                        onChangePreviewAddressClick={() =>
                          changeDomainPreviewAddress(domain)
                        }
                      >
                        <ul className="faux-list">
                          {dmarc}
                          {domain.trackingSubdomains.length ? (
                            <>
                              {domain.trackingSubdomains.map((subdomain) => {
                                return (
                                  <li key={subdomain.id}>
                                    <Subdomain
                                      domain={subdomain}
                                      domainType={TRACKING_DOMAIN}
                                      headingLevel={5}
                                      onAuthenticateClick={() =>
                                        authenticateSubdomain(
                                          subdomain,
                                          TRACKING_DOMAIN
                                        )
                                      }
                                      onDeleteClick={() =>
                                        deleteDomain(subdomain, TRACKING_DOMAIN)
                                      }
                                    />
                                  </li>
                                );
                              })}
                            </>
                          ) : (
                            <li>
                              <Subdomain
                                domainType={TRACKING_DOMAIN}
                                headingLevel={5}
                                onAddClick={() => {
                                  addSubdomain(TRACKING_DOMAIN, domain);
                                }}
                              />
                            </li>
                          )}

                          {domain.cdnSubdomains.length ? (
                            <>
                              {domain.cdnSubdomains.map((subdomain) => {
                                return (
                                  <li key={subdomain.id}>
                                    <Subdomain
                                      domain={subdomain}
                                      domainType={CDN_DOMAIN}
                                      headingLevel={5}
                                      onAuthenticateClick={() =>
                                        authenticateSubdomain(
                                          subdomain,
                                          CDN_DOMAIN
                                        )
                                      }
                                      onDeleteClick={() =>
                                        deleteDomain(subdomain, CDN_DOMAIN)
                                      }
                                    />
                                  </li>
                                );
                              })}
                            </>
                          ) : (
                            <li>
                              <Subdomain
                                domainType={CDN_DOMAIN}
                                headingLevel={5}
                                onAddClick={() =>
                                  addSubdomain(CDN_DOMAIN, domain)
                                }
                              />
                            </li>
                          )}
                        </ul>
                      </SendingDomain>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {trackingDomains.length > 0 && (
            <div className="company-domains__other-domains company-domains__other-tracking-domains">
              <h3 className="h4 mar-b24">
                {i18n.t("company:domains.tracking.otherHeader")}
              </h3>

              <ul className="faux-list">
                {trackingDomains.map((domain) => {
                  return (
                    <li key={domain.id}>
                      <Subdomain
                        domain={domain}
                        onAuthenticateClick={() =>
                          authenticateSubdomain(domain, TRACKING_DOMAIN)
                        }
                        onDeleteClick={() =>
                          deleteDomain(domain, TRACKING_DOMAIN)
                        }
                        domainType={TRACKING_DOMAIN}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {cdnDomains.length > 0 && (
            <div className="company-domains__other-domains company-domains__other-cdn-domains">
              <h3 className="h4 mar-b24">
                {i18n.t("company:domains.cdn.otherHeader")}
              </h3>

              <ul className="faux-list">
                {cdnDomains.map((domain) => {
                  return (
                    <li key={domain.id}>
                      <Subdomain
                        domain={domain}
                        onAuthenticateClick={() =>
                          authenticateSubdomain(domain, CDN_DOMAIN)
                        }
                        onDeleteClick={() => deleteDomain(domain, CDN_DOMAIN)}
                        domainType={CDN_DOMAIN}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <UILIB.PagingBlock
            totalRows={filteredDomains.length}
            pageSize={pageSize}
            currentPage={currentPage}
            changePage={handleChangePage}
          />
        </>
      ) : (
        <UILIB.Paper className="company-domains__empty-state mar-b0">
          <h2 className="h4 mar-b10">
            {i18n.t("company:domains.blank.heading")}
          </h2>

          <p>{i18n.t("company:domains.blank.body")}</p>

          {isTranspond && (
            <UILIB.Hint
              href="https://kb.transpond.io/article/106-email-domain-configuration"
              target="_blank"
              iconLeft={<UILIB.Icons icon="questionCircle" />}
            >
              {i18n.t("company:domains.learnDomains")}
            </UILIB.Hint>
          )}
        </UILIB.Paper>
      )}
    </div>
  );
}


function DmarcDrawer() {
  return (
    <div>
      <div className="quickFlex mar-b15" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <h3 >{i18n.t("company:domains.sending.dmarc.whatIs")}</h3>
        <UILIB.Button href="https://mxtoolbox.com/dmarc/details/how-to-setup-dmarc" target="_blank">{i18n.t("company:domains.sending.dmarc.setup")}</UILIB.Button>
      </div>
      <p>{i18n.t("company:domains.sending.dmarc.start")}</p>
      <ol>
        <li><strong>{i18n.t("company:domains.sending.dmarc.reasons.reason1")}</strong> {i18n.t("company:domains.sending.dmarc.reasons.reason1Desc")}</li>
        <li><strong>{i18n.t("company:domains.sending.dmarc.reasons.reason2")}</strong> {i18n.t("company:domains.sending.dmarc.reasons.reason2Desc")}</li>
        <li><strong>{i18n.t("company:domains.sending.dmarc.reasons.reason3")}</strong> {i18n.t("company:domains.sending.dmarc.reasons.reason3Desc")}</li>
        <li><strong>{i18n.t("company:domains.sending.dmarc.reasons.reason4")}</strong> {i18n.t("company:domains.sending.dmarc.reasons.reason4Desc")}</li>
        <li><strong>{i18n.t("company:domains.sending.dmarc.reasons.reason5")}</strong> {i18n.t("company:domains.sending.dmarc.reasons.reason5Desc")}</li>
        <li><strong>{i18n.t("company:domains.sending.dmarc.reasons.reason6")}</strong> {i18n.t("company:domains.sending.dmarc.reasons.reason6Desc")}</li>
        <li><strong>{i18n.t("company:domains.sending.dmarc.reasons.reason7")}</strong> {i18n.t("company:domains.sending.dmarc.reasons.reason7Desc")}</li>
      </ol>
      <p>{i18n.t("company:domains.sending.dmarc.end")}</p>
    </div>
  )
}
import React from 'react'

export default function IconIntegrations({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 3.00006C4.46957 3.00006 3.96086 3.21077 3.58579 3.58585C3.21071 3.96092 3 4.46963 3 5.00006V7.00006C3 7.53049 3.21071 8.0392 3.58579 8.41427C3.96086 8.78935 4.46957 9.00006 5 9.00006H7C7.53043 9.00006 8.03914 8.78935 8.41421 8.41427C8.78929 8.0392 9 7.53049 9 7.00006V5.00006C9 4.46963 8.78929 3.96092 8.41421 3.58585C8.03914 3.21077 7.53043 3.00006 7 3.00006H5ZM5 11.0001C4.46957 11.0001 3.96086 11.2108 3.58579 11.5858C3.21071 11.9609 3 12.4696 3 13.0001V15.0001C3 15.5305 3.21071 16.0392 3.58579 16.4143C3.96086 16.7893 4.46957 17.0001 5 17.0001H7C7.53043 17.0001 8.03914 16.7893 8.41421 16.4143C8.78929 16.0392 9 15.5305 9 15.0001V13.0001C9 12.4696 8.78929 11.9609 8.41421 11.5858C8.03914 11.2108 7.53043 11.0001 7 11.0001H5ZM11 5.00006C11 4.46963 11.2107 3.96092 11.5858 3.58585C11.9609 3.21077 12.4696 3.00006 13 3.00006H15C15.5304 3.00006 16.0391 3.21077 16.4142 3.58585C16.7893 3.96092 17 4.46963 17 5.00006V7.00006C17 7.53049 16.7893 8.0392 16.4142 8.41427C16.0391 8.78935 15.5304 9.00006 15 9.00006H13C12.4696 9.00006 11.9609 8.78935 11.5858 8.41427C11.2107 8.0392 11 7.53049 11 7.00006V5.00006ZM14 11.0001C14.2652 11.0001 14.5196 11.1054 14.7071 11.293C14.8946 11.4805 15 11.7348 15 12.0001V13.0001H16C16.2652 13.0001 16.5196 13.1054 16.7071 13.293C16.8946 13.4805 17 13.7348 17 14.0001C17 14.2653 16.8946 14.5196 16.7071 14.7072C16.5196 14.8947 16.2652 15.0001 16 15.0001H15V16.0001C15 16.2653 14.8946 16.5196 14.7071 16.7072C14.5196 16.8947 14.2652 17.0001 14 17.0001C13.7348 17.0001 13.4804 16.8947 13.2929 16.7072C13.1054 16.5196 13 16.2653 13 16.0001V15.0001H12C11.7348 15.0001 11.4804 14.8947 11.2929 14.7072C11.1054 14.5196 11 14.2653 11 14.0001C11 13.7348 11.1054 13.4805 11.2929 13.293C11.4804 13.1054 11.7348 13.0001 12 13.0001H13V12.0001C13 11.7348 13.1054 11.4805 13.2929 11.293C13.4804 11.1054 13.7348 11.0001 14 11.0001Z" fill={fillColor} />
        </svg>
    )
}
import React from "react";
import * as RadixSelect from "@radix-ui/react-select";
import classnames from "classnames";
import _uniqueId from "lodash/uniqueId";
import { propToCssModule } from "~/Classes/styles";
import Icon from "../Icon";
import FormError from "../Forms/FormError/FormError";
import * as styles from "./index.module.scss";

const Select = { ...RadixSelect };

const Root = React.forwardRef(
  ({ positionStatic, fullWidth, error, className, ...rest }, forwardedRef) => {
    const classes = classnames(styles.root, className, {
      [styles.rootPositionStatic]: positionStatic,
      [styles.rootFullWidth]: fullWidth,
    });

    return (
      // You can't apply a className to the actual Root element so we need a wrapper
      <div className={classes}>
        <RadixSelect.Root ref={forwardedRef} {...rest} />
        {error && <FormError>{error}</FormError>}
      </div>
    );
  }
);

const Trigger = React.forwardRef(
  (
    {
      asChild,
      variant = "primary",
      size = "r",
      iconOnly,
      placeholder,
      label,
      fullWidth,
      className,
      ...rest
    },
    forwardedRef
  ) => {
    if (iconOnly && !rest["aria-label"]) {
      console.error(
        'Select triggers with only an icon must have an "aria-label" attribute.'
      );
    }

    const classes = classnames(styles.trigger, className, {
      [styles[`triggerVariant${propToCssModule(variant)}`]]: variant,
      [styles[`triggerSize${propToCssModule(size)}`]]: size,
      [styles.triggerIconOnly]: iconOnly,
      [styles.triggerFullWidth]: fullWidth,
    });

    const id = _uniqueId("select-trigger_");

    return (
      <>
        {label && (
          <label className={styles.triggerLabel} htmlFor={id}>
            {label}
          </label>
        )}

        {asChild ? (
          <RadixSelect.Trigger id={id} {...rest} />
        ) : (
          <RadixSelect.Trigger
            ref={forwardedRef}
            id={id}
            className={classes}
            {...rest}
          >
            <RadixSelect.Value placeholder={placeholder} />
            {!iconOnly && <Icon name="dropdown-arrow" />}
          </RadixSelect.Trigger>
        )}
      </>
    );
  }
);

const Content = React.forwardRef(
  (
    { className, sideOffset = 4, position = "popper", fullWidth, ...rest },
    forwardedRef
  ) => {
    const classes = classnames(styles.content, className, {
      [styles.contentFullWidth]: fullWidth,
    });

    return (
      <RadixSelect.Content
        ref={forwardedRef}
        className={classes}
        sideOffset={sideOffset}
        position={position}
        {...rest}
      />
    );
  }
);

const Label = ({ className, ...rest }) => {
  const classes = classnames(styles.label, className);

  return <RadixSelect.Label className={classes} {...rest} />;
};

const Item = ({ className, children, ...rest }) => {
  const classes = classnames(styles.item, className);

  return (
    <RadixSelect.Item className={classes} {...rest}>
      <RadixSelect.ItemText asChild>
        <div className={styles.itemText}>{children}</div>
      </RadixSelect.ItemText>

      <RadixSelect.ItemIndicator className={styles.itemIndicator}>
        <Icon name="check" />
      </RadixSelect.ItemIndicator>
    </RadixSelect.Item>
  );
};

const Separator = ({ className, ...rest }) => {
  const classes = classnames(styles.separator, className);

  return <RadixSelect.Separator className={classes} {...rest} />;
};

Select.Root = Root;
Select.Trigger = Trigger;
Select.Content = Content;
Select.Label = Label;
Select.Item = Item;
Select.Separator = Separator;

export default Select;

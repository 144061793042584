import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import { connect } from 'react-redux';
import i18n from '~/i18n'
import siteVars from '~/Classes/siteVars';
import InDepthChart from '../../shared/graphs/indepthChart'
import GenericStatsHolder from '../../shared/components/genericStatsHolder';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class CampaignsViewUnsubscribed extends React.Component {
    constructor(props) {
        super(props);

        this._ismounted = false;
        this.timer = null;

        this.state = {
            campaignId: this.props.match.params.campaignId,
            pageLoaded: false,
            tableData: {},
            loadingData: 1,
            sortColumn: "unsubscribeDate",
            sortDirection: "desc",
            searchText: "",
            pageSize: 50,
            totalRows: 0,
            currentPage: 0,
            startRecord: 0,
            totalUnsubscribed: 0,
            totalSubscribers: 0
        }
        this.loadStats = this.loadStats.bind(this);
        this.tableUpdater = this.tableUpdater.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSearchText = this.changeSearchText.bind(this);
        this.goSearchText = this.goSearchText.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.goViewSubscriber = this.goViewSubscriber.bind(this);
        this.exportCampaign = this.exportCampaign.bind(this)
    }

    componentDidMount() {
        this._ismounted = true
        this.loadStats()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.endDate != this.props.endDate || prevProps.startDate != this.props.startDate) {
            clearTimeout(this.timer);
            this.loadStats();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        this._ismounted = false
    }

    exportCampaign() {
        var self = this
        this.setState({
            alertMessage: 'Starting Export - this might take a while',
            alertOpen: true
        })
        function checkExport(id) {
            if (!self._ismounted) return
            axios.get('/campaign/' + self.state.campaignId + '/export/' + id + '?startDate=' + self.props.startDate.format('YYYY-MM-DD') + '&endDate=' + self.props.endDate.format('YYYY-MM-DD')).then(response => {
                if (response.data.ExportJob.finished) {
                    if (response.data.ExportJob.location) {
                        axios.get('/campaign/' + self.state.campaignId + '/export/' + id + '/file').then(res => {
                            const link = document.createElement('a');
                            link.href = res.data.url
                            document.body.appendChild(link);
                            link.click();
                        })
                    } else {
                        self.setState({
                            alertOpen: true,
                            alertMessage: response.data.ExportJob.status
                        })
                    }
                } else {
                    setTimeout(() => { checkExport(id) }, 500)
                }
            })
        }
        axios.put('/campaign/' + this.state.campaignId + '/export?filter=unsubscribed&startDate=' + this.props.startDate.format('YYYY-MM-DD') + '&endDate=' + this.props.endDate.format('YYYY-MM-DD')).then(response => {
            checkExport(response.data.ExportJob.id)
        })
    }

    goViewSubscriber(subID) {
        this.props.history.push('/cp/subscriber/' + subID);
    }

    async loadStats(sortColumn, sortDirection, startRecord, pageSize, searchText) {

        if (!this._ismounted) return;
        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!startRecord) {
            startRecord = this.state.startRecord;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var query = '?sortColumn=' + sortColumn + '&sortOrder=' + sortDirection + '&searchText=' + searchText + '&startRecord=' + startRecord + '&recordLimit=' + this.state.pageSize;
        if (this.props.startDate) {
            query += '&startDate=' + this.props.startDate.format('YYYY-MM-DD')
        }
        if (this.props.endDate) {
            query += '&endDate=' + this.props.endDate.format('YYYY-MM-DD 23:59:59')
        }

        var self = this;
        let res = await axios.get('/campaign/' + self.state.campaignId + '/stats/unsubscribed' + query)

        var theTableData = res.data.Opened
            .map(thisLine => {
                return {
                    gravatar: {
                        headerValue: " ",
                        value: <UILIB.Avatar style={{ height: 'auto', width: '100%', maxWidth: "40px", maxHeight: "40px", marginRight: "10px" }} src={thisLine.gravatar} />,
                        width: 50,
                        orderBy: false
                    },
                    emailAddress: {
                        headerValue: i18n.t('emailAddress'),
                        value: thisLine.SubscriberId ? <a onClick={() => { this.goViewSubscriber(thisLine.SubscriberId) }}>{thisLine.emailAddress}</a> : thisLine.emailAddress,
                        orderBy: true
                    },
                    sendDate: {
                        headerValue: i18n.t('campaigns:nav.sent'),
                        value: <span>{DateTimeFunctions.formatDateTime(thisLine.sendDate, 1)}</span>,
                        orderBy: true
                    },
                    unsubscribeDate: {
                        headerValue: i18n.t('campaigns:nav.unsubscribed'),
                        value: <span>{DateTimeFunctions.formatDateTime(thisLine.unsubscribeDate, 1)}</span>,
                        orderBy: true
                    },
                    blackListed: {
                        headerValue: i18n.t('blacklisted'),
                        value: thisLine.blackListed ? <UILIB.SquareChip value="Yes" className={"black"} /> : '',
                        orderBy: true
                    },
                    optionsCol:
                    {
                        headerValue: " ",
                        value: thisLine.SubscriberId ? <UILIB.OptionsDropdown popWidth="150px" history={this.history}>
                            <ul>
                                <li>
                                    <a onClick={() => { this.goViewSubscriber(thisLine.SubscriberId) }}>{i18n.t('campaigns:overview.viewSubscriber')}</a>
                                </li>
                            </ul>
                        </UILIB.OptionsDropdown> : <span></span>
                        ,
                        orderBy: false,
                        fixed: true,
                        width: 20
                    }
                }
            })
        //load total count
        let stats = await axios.get('/campaign/' + self.state.campaignId + '/stats?ignoreLog=1&startDate=' + this.props.startDate.format('YYYY-MM-DD') + '&endDate=' + this.props.endDate.format('YYYY-MM-DD'))
        stats = stats.data.Stats;
        let totalUnsubscribed = 0;
        let totalSubscribers = 0;
        if (stats && stats["Total Unsubscribes"]) totalUnsubscribed = stats["Total Unsubscribes"];
        if (stats && stats["Total Subscribers"]) totalSubscribers = stats["Total Subscribers"];
        self.setState({ tableData: theTableData, loadingData: 0, totalRows: res.data.countTotal, pageLoaded: true, totalUnsubscribed, totalSubscribers });
        clearTimeout(self.timer)
        if (self._ismounted) self.timer = setTimeout(self.loadStats, 20000)

    }

    tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {
        clearTimeout(this.timer);
        var thisState = {};
        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!currentPage && currentPage != "0") {
            currentPage = this.state.currentPage;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var startRecord = currentPage * pageSize;

        this.setState({ loadingData: 1, sortColumn: sortColumn, sortDirection: sortDirection, pageSize: pageSize, currentPage: currentPage, searchText: searchText, startRecord: startRecord }, this.loadStats);

    }
    changePageSize(pageSize) {
        this.tableUpdater(null, null, null, pageSize, null);
    }

    //GROUP NAME SEARCH STUFF
    changeSearchText(event) {
        this.setState({ searchText: event.target.value })
    }
    goSearchText() {
        this.tableUpdater(0, null, null, null, null);
    }
    keyPress(event) {
        if (event.key === 'Enter') {
            this.goSearchText();
        }
    }


    render() {
        return <div>
            <div className="mar-b25 campaign-view-controls">
                <UILIB.TextInput type="multiDate" startDate={this.props.startDate} endDate={this.props.endDate} updateDate={this.props.updateDate}></UILIB.TextInput>

                {(this.props.canExport == true) &&
                    <UILIB.Button
                        className="mar-l10 m-mar-l0 m-mar-t10"
                        onClick={this.exportCampaign}
                        text={i18n.t('subscribers:nav.export') + " " + i18n.t('campaigns:nav.unsubscribed')}
                        iconRight={<UILIB.Icons icon="download" />}
                    />
                }
            </div>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={3} margin={0}>
                    <GenericStatsHolder
                        value={this.state.totalUnsubscribed}
                        limit={this.state.totalSubscribers}
                        header={i18n.t('campaigns:overview.statUnsubscribes')}
                        link=""
                        linkText=""
                        linkIcon="arrowRight"
                    />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={9} margin={0}>
                    <UILIB.Paper className="mar-b25">
                        <InDepthChart title={i18n.t('campaigns:overview.unsubscribersTimeline')} type="unsubscribed" campaignId={this.state.campaignId} startDate={this.props.startDate} endDate={this.props.endDate} seatchText={this.state.searchText} changedDate={this.props.changedDate} />
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Paper>
                <UILIB.Row>
                    <UILIB.Column md={6} sm={12} xs={12} className="mar-b25">
                        <UILIB.TextInput
                            clickToChangeClicked={this.goSearchText}
                            placeholder={i18n.t('searchForEmail')}
                            onChange={this.changeSearchText}
                            onKeyPress={this.keyPress}
                            iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}

                        />
                    </UILIB.Column>

                    <UILIB.Column md={6} hide={["sm", "xs"]} className="mar-b25" style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                        <div>{i18n.t('showing')}</div>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.pageSize === 10} onClick={() => { this.changePageSize(10) }}>10</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 50} onClick={() => { this.changePageSize(50) }}>50</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>

                    </UILIB.Column>
                    <UILIB.Column xs={12} className="mar-b25">
                        <UILIB.DataTable1 noResultsTxt={<span> {i18n.t('noResults')}</span>} tableData={this.state.tableData} loadingData={this.state.loadingData}
                            dataUpdater={this.tableUpdater} width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn={this.state.sortColumn}
                            sortedDirection={this.state.sortDirection}
                        />
                    </UILIB.Column>
                    <UILIB.Column xs={12}>
                        <div style={{ textAlign: "center" }}>
                            <UILIB.PagingBlock totalRows={this.state.totalRows} pageSize={this.state.pageSize} numberOfLinks="10" currentPage={this.state.currentPage}
                                changePage={this.tableUpdater} text={i18n.t('page') + ":"} />
                        </div>
                    </UILIB.Column>
                </UILIB.Row>
            </UILIB.Paper >



        </div>
    }
}
import React from 'react'

export default class IconTransaction extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00003 4.99996C7.73481 4.99996 7.48046 5.10532 7.29292 5.29285C7.10539 5.48039 7.00003 5.73474 7.00003 5.99996C7.00003 6.26518 7.10539 6.51953 7.29292 6.70707C7.48046 6.8946 7.73481 6.99996 8.00003 6.99996H13.586L12.293 8.29296C12.1109 8.48156 12.0101 8.73416 12.0124 8.99636C12.0146 9.25856 12.1198 9.50937 12.3052 9.69478C12.4906 9.88019 12.7414 9.98535 13.0036 9.98763C13.2658 9.98991 13.5184 9.88912 13.707 9.70696L16.707 6.70696C16.8945 6.51943 16.9998 6.26512 16.9998 5.99996C16.9998 5.73479 16.8945 5.48049 16.707 5.29296L13.707 2.29296C13.6148 2.19745 13.5044 2.12127 13.3824 2.06886C13.2604 2.01645 13.1292 1.98886 12.9964 1.98771C12.8637 1.98655 12.732 2.01186 12.6091 2.06214C12.4862 2.11242 12.3745 2.18667 12.2806 2.28056C12.1867 2.37446 12.1125 2.48611 12.0622 2.60901C12.0119 2.7319 11.9866 2.86358 11.9878 2.99636C11.9889 3.12914 12.0165 3.26036 12.0689 3.38236C12.1213 3.50437 12.1975 3.61471 12.293 3.70696L13.586 4.99996H8.00003ZM12 15C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8947 14.5195 13 14.2652 13 14C13 13.7347 12.8947 13.4804 12.7071 13.2929C12.5196 13.1053 12.2652 13 12 13H6.41403L7.70703 11.707C7.80254 11.6147 7.87872 11.5044 7.93113 11.3824C7.98354 11.2604 8.01113 11.1291 8.01228 10.9964C8.01344 10.8636 7.98813 10.7319 7.93785 10.609C7.88757 10.4861 7.81332 10.3745 7.71943 10.2806C7.62553 10.1867 7.51388 10.1124 7.39098 10.0621C7.26809 10.0119 7.13641 9.98655 7.00363 9.98771C6.87085 9.98886 6.73963 10.0164 6.61763 10.0689C6.49562 10.1213 6.38528 10.1974 6.29303 10.293L3.29303 13.293C3.10556 13.4805 3.00024 13.7348 3.00024 14C3.00024 14.2651 3.10556 14.5194 3.29303 14.707L6.29303 17.707C6.48163 17.8891 6.73424 17.9899 6.99643 17.9876C7.25863 17.9854 7.50944 17.8802 7.69485 17.6948C7.88026 17.5094 7.98543 17.2586 7.9877 16.9964C7.98998 16.7342 7.88919 16.4816 7.70703 16.293L6.41403 15H12Z" fill={color} />
        </svg>



    }
}        
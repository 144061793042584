import React, { useEffect } from 'react'
const siteKey = '6LftXAwbAAAAAAbfFicF8ApB2RyCY2_CQWvM13Y6'

export default function ReCaptcha({ onComplete = () => { } }) {
    const loaded = () => {
        window.grecaptcha.ready(execute)
    }

    const execute = async () => {
        const token = await window.grecaptcha.execute(siteKey, { action: 'submit' })
        onComplete(token)
    }

    useEffect(() => {
        if (!document.getElementById('recaptcha')) {
            const script = document.createElement("script")
            script.src = "https://www.google.com/recaptcha/api.js?render=" + siteKey
            script.id = 'recaptcha'
            script.addEventListener("load", loaded)
            document.body.appendChild(script)
        } else {
            execute()
        }
    }, [])

    return (
        <div
            className="g-recaptcha"
            data-sitekey={siteKey}
            data-size="invisible"
        ></div>
    )
}
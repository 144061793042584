import React from 'react'
import allowedFonts from '../../../../../Classes/fonts';

// colours = [background, title, link, button]
export default function ExampleTemplate1({ colours, logo, font }) {
    //find our google font
    let fontInclude = "";
    const gFont = allowedFonts.find(f => f.value.toLowerCase() == font.toLowerCase())
    if (gFont && gFont.src == "google") {
        fontInclude = <link href={`https://fonts.googleapis.com/css?family=${gFont.label.replace(/ /g, '+')}: 400, 700, 400italic, 700italic`} rel="stylesheet" type="text/css" />
    }
    let bannerColor = colours[0];
    let buttonColor = colours[1];

    return (

        <div style={{ width: "100%", maxHeight: "370px", overflow: "hidden", borderRadius: "10px" }}>
            {fontInclude}
            <div style={{ backgroundColor: "#E5E7EB", display: "flex", justifyContent: "center", padding: "20px", paddingBottom: "0px" }}>
                <div style={{ width: "100%", backgroundColor: "#FFFFFF" }}>
                    <div style={{ marginBottom: "12px", width: "100%", backgroundColor: bannerColor, padding: "5px 10px", lineHeight: "5px", fontSize: "5px", color: "#FFFFFF", display: "flex", justifyContent: "space-between" }}>
                        <div>May 14 - May 20, 2022</div>
                        <div>View in your browser</div>
                    </div>

                    <div style={{ backgroundColor: "#FFFFFF", padding: "20px", paddingTop: "0px", paddingBottom: "0px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                            <div>
                                <img src={logo ? logo.url || logo.raw : ""} style={{ width: "100%", maxWidth: "30px", maxHeight: "30px" }} />
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/socialMediaIcons/twitter-round-black.png?rnd=55704157" style={{ width: "7px", marginRight: "3px" }} />
                                <img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/socialMediaIcons/instagram-round-black.png" style={{ width: "7px", marginRight: "3px" }} />
                                <img src="https://cdn1.ourmailsender.com/siteContent/assets/templates/socialMediaIcons/pinterest-round-black.png" style={{ width: "7px" }} />
                            </div>
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                            <img src="https://cdn1.ourmailsender.com/siteContent/brandAnalyzer/example1_img1.png" style={{ width: "100%" }} />
                        </div>
                        <div style={{ marginBottom: "30px" }}>
                            <div style={{ fontSize: "13px", fontWeight: "bold", marginBottom: "5px" }}>Hey There! We have some great news for you!</div>
                            <div style={{ fontSize: "8px", marginBottom: "10px", lineHeight: "12px" }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <a style={{ color: buttonColor }}>Click Here</a>.
                                Aenean auctor cursus eros vel suscipit. Ut eleifend massa ac velit iaculis, nec convallis nisi rutrum.
                                Fusce varius hendrerit arcu.
                            </div>
                            <div style={{ backgroundColor: buttonColor, color: "white", padding: "2px 4px", borderRadius: "5px", fontSize: "8px", display: "inline-block" }}>
                                Call to action button
                            </div>
                        </div>

                        <div style={{ backgroundColor: buttonColor, padding: "5px" }}>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
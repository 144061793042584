import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFormatFunctions from '~/Classes/numberFormatFunctions';
import moment from 'moment'
//CONNECT TO STORE

export default class AffiliateCustomerPayments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payments: [],
            pageLoaded: false,
            tableLoaded: false
        };

        this.getPayments = this.getPayments.bind(this);
    }

    componentDidMount() {
        this.getPayments();
    }
    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer)
    }
    getPayments() {
        var self = this;
        axios.get(`/accountUser/affiliate/customers/payments`).then(_payments => {

            var totalCommission = 0;
            var totalPaid = 0;
            var totalDue = 0

            var payments = _payments.data.Payments.filter(payment => payment.orderPrice > 0)
            payments = _payments.data.Payments.map(payment => {
                var thisCommission = (payment.orderPrice / 100) * 20
                totalCommission += thisCommission;

                var paidStatus = <UILIB.SquareChip className="grey mar-l10">Due Now</UILIB.SquareChip>
                if (payment.affiliatePaid) {
                    totalPaid += thisCommission;
                    paidStatus = <UILIB.SquareChip className="primary mar-l10">Paid {DateTimeFunctions.formatDateTime(payment.affiliatePaidDate, 2)}</UILIB.SquareChip>
                }
                else {
                    totalDue += thisCommission;
                    var dueDate = moment(payment.createdAt).add(4, "months")
                    var timeLeft = moment().diff(dueDate, "days")
                    dueDate = dueDate.format('YYYY-MM')
                    if (timeLeft > 90) {
                        paidStatus = <UILIB.SquareChip className={"mar-l10 red"}>Due {DateTimeFunctions.formatDateTime(dueDate, 2)}</UILIB.SquareChip>
                    }
                    else {
                        paidStatus = <UILIB.SquareChip className={"mar-l10 grey"}>Due {DateTimeFunctions.formatDateTime(dueDate, 2)}</UILIB.SquareChip>
                    }

                }

                return {
                    companyLogo: {
                        headerValue: "",
                        value: <UILIB.Avatar style={{ height: '30px', width: '30px' }} src={payment.companyLogo} />,
                        orderBy: false
                    },
                    accountName: {
                        headerValue: "Account",
                        value: payment.accountName,
                        orderBy: false
                    },
                    createdAt: {
                        headerValue: "Order Date",
                        value: DateTimeFunctions.formatDateTime(payment.createdAt, 2),
                        orderBy: false
                    },
                    orderDescription: {
                        headerValue: "Product",
                        value: payment.orderDescription,
                        orderBy: false
                    },
                    orderPrice: {
                        headerValue: "Price",
                        value: <span>&pound;{NumberFormatFunctions.formatNumber(payment.orderPrice, 2)}</span>,
                        orderBy: false
                    },
                    commission: {
                        headerValue: "Commission",
                        value: <span>&pound;{NumberFormatFunctions.formatNumber(thisCommission, 2)}</span>,
                        orderBy: false
                    },
                    affiliatePaid:
                    {
                        headerValue: "Commission Payment",
                        value: paidStatus,
                        orderBy: false
                    }

                }
            })
            self.props.updateStats("customerPayments", totalCommission)
            self.props.updateStats("customerPaymentsDue", totalDue)
            self.props.updateStats("customerPaymentsPaid", totalPaid)
            self.setState({ payments: payments, pageLoaded: true, tableLoaded: true }, () => {
                self.timer = setTimeout(self.getPayments, 30000)
            })
        })
    }

    render() {
        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />
        if (!this.state.payments.length) {
            return <div style={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #DDDDDD",
            }}>
                <div style={{ display: "flex" }}>
                    <div style={{ fontSize: "40px" }} className="mar-r15">💰</div>
                    <div>
                        <h4 className="mar-b5">No Payments Found</h4>
                        <p>There have been no subscription payments by customers yet.</p>
                    </div>

                </div>
            </div>
        }

        return <div style={{
            border: "1px solid #DDDDDD",
            padding: "10px",
            height: "300px",
            overflow: "auto"
        }}>
            <UILIB.DataTable1
                noResultsTxt={"No payments match your filters"}
                tableData={this.state.payments}
                loadingData={this.state.tableLoaded}
                dataUpdater={() => { }}
                width="100%"
                pageSize="100"
                hasCheckBoxes="no"
                sortedColumn={"createdAt"}
                sortedDirection={"desc"}
            />
        </div>
    }

};



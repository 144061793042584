export function createAnswer(label, value, answers = 0) {
  return {
    label,
    value,
    answers,
  };
}

export function mapEmptyAnswersToQuestion(question) {
  const { value: questionType, answers } = question;
  let dataFormatted = [];

  switch (questionType) {
    case "satisfaction":
      {
        dataFormatted = mapEmptySatisfactionAnswers(answers.value);
      }
      break;

    case "promoter":
      {
        dataFormatted = mapEmptyPromoterAnswers(answers.value);
      }
      break;

    case "question": {
      dataFormatted = mapQuestionAnswers(answers.value);
      break;
    }

    case "like": {
      dataFormatted = mapLikeDislikeAnswers(question);
      break;
    }

    default: {
      dataFormatted = answers.value;
    }
  }

  return { ...question, answers: { value: dataFormatted } };
}

export function mapEmptySatisfactionAnswers(answers) {
  const satisfactionValues = [0, 2, 4, 6, 9];

  return satisfactionValues.map((value, index) => {
    const matchingAnswer = answers.find((answer) => answer.value === value);

    if (matchingAnswer) {
      return createAnswer(
        index + 1,
        matchingAnswer.value,
        matchingAnswer.answers
      );
    }

    return createAnswer(index + 1, value);
  });
}

export function mapEmptyPromoterAnswers(answers) {
  const promoterValues = Array.from(Array(10).keys()).map((value) => value + 1);

  return promoterValues.map((value, index) => {
    // There is a quirk in the back end that requires a bit of a hack here…
    // When a user clicks on a number when answering a promoter score question, the result recorded in the DB
    // is stored on a zero-based index (e.g. if they click on 6/10 the value stored in the DB will be 5).
    // This means that when we get the results back, we need to +1 to both the label and the value.
    // Ideally we'd update the back end to return the correct data to us, but I have more confidence in this
    // front end change combined with front end tests.
    const matchingAnswer = answers.find((answer) => answer.value + 1 === value);

    if (matchingAnswer) {
      // Same as above applies here.
      return createAnswer(
        matchingAnswer.label + 1,
        matchingAnswer.value + 1,
        matchingAnswer.answers
      );
    }

    return createAnswer(index + 1, value);
  });
}

export function mapQuestionAnswers(answers) {
  const notAnsweredQuestionCount =
    answers.find((answer) => answer.value === null)?.answers || 0;

  const answeredQuestionCount =
    notAnsweredQuestionCount > 0 ? answers.length - 1 : answers.length;

  return [
    createAnswer("Answered", 1, answeredQuestionCount),
    createAnswer("Not answered", 0, notAnsweredQuestionCount),
  ];
}

export function mapLikeDislikeAnswers(question) {
  const { answers, shape, negativeLabel, positiveLabel } = question;
  const likeDislikeValues = [0, 9];

  return likeDislikeValues.map((value) => {
    const matchingAnswer = answers.value.find(
      (answer) => answer.value === value
    );

    if (matchingAnswer) {
      return createAnswer(
        likeDislikeLabel(
          shape.value,
          matchingAnswer.value,
          negativeLabel.value,
          positiveLabel.value
        ),
        matchingAnswer.value,
        matchingAnswer.answers
      );
    }

    return createAnswer(
      likeDislikeLabel(
        shape.value,
        value,
        negativeLabel.value,
        positiveLabel.value
      ),
      value
    );
  });
}

function likeDislikeLabel(shapeType, value, negativeLabel, positiveLabel) {
  let dislikeEmoji = "👎";
  let likeEmoji = "👍";

  if (shapeType === "hearts") {
    dislikeEmoji = "💔";
    likeEmoji = "❤️";
  }

  if (value === 0) {
    return `${dislikeEmoji} ${negativeLabel || "Dislike"}`;
  }

  return `${likeEmoji} ${positiveLabel || "Like"}`;
}

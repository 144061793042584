var fontArray = [
    { label: "Arial", value: 'Arial', "src": "mpz" },
    { label: "Arial Black", value: 'Arial Black', "src": "mpz" },
    { label: "Comic Sans MS", value: 'Comic Sans MS', "src": "mpz" },
    { label: "Lucida Sans Unicode", value: 'Lucida Sans Unicode', "src": "mpz" },
    { label: "Helvetica", value: 'Helvetica', "src": "mpz" },
    { label: "Impact", value: 'Impact', "src": "mpz" },
    { label: "Tahoma", value: 'Tahoma', "src": "mpz" },
    { label: "Trebuchet MS", value: 'Trebuchet MS', "src": "mpz" },
    { label: "Calibri", value: "Calibri", "src": "mpz" },
    { label: "Verdana", value: 'Verdana', "src": "mpz" },
    { label: "Century Gothic", value: 'Century Gothic', "src": "mpz" },
    { label: "Courier New", value: 'Courier New', "src": "mpz" },
    { label: "Garamond", value: 'Garamond', "src": "mpz" },
    { label: "Georgia", value: 'Georgia', "src": "mpz" },
    { label: "Times New Roman", value: 'Times New Roman', "src": "mpz" },
    { label: "Lucida", value: 'Lucida', "src": "mpz" },
    { label: "Trebuchet", value: 'Trebuchet', "src": "mpz" },
    {
        "label": "Open Sans",
        "value": "Open Sans,sans-serif",
        "src": "google"
    },
    {
        "label": "Open Sans Condensed",
        "value": "Open Sans Condensed,sans-serif",
        "src": "google"
    },
    {
        "label": "Libre Baskerville",
        "value": "Libre Baskerville,serif",
        "src": "google"
    },
    {
        "label": "Cabin",
        "value": "Cabin,sans-serif",
        "src": "google"
    },
    {
        "label": "Rubik",
        "value": "Rubik,sans-serif",
        "src": "google"
    },
    {
        "label": "Hind",
        "value": "Hind,sans-serif",
        "src": "google"
    },
    {
        "label": "Fjalla One",
        "value": "Fjalla One,sans-serif",
        "src": "google"
    },
    {
        "label": "Arvo",
        "value": "Arvo,serif",
        "src": "google"
    },
    {
        "label": "Lobster",
        "value": "Lobster,display",
        "src": "google"
    },
    {
        "label": "Libre Franklin",
        "value": "Libre Franklin,sans-serif",
        "src": "google"
    },
    {
        "label": "Exo 2",
        "value": "Exo 2,sans-serif",
        "src": "google"
    },
    {
        "label": "Karla",
        "value": "Karla,sans-serif",
        "src": "google"
    },
    {
        "label": "Pacifico",
        "value": "Pacifico,handwriting",
        "src": "google"
    },
    {
        "label": "Abel",
        "value": "Abel,sans-serif",
        "src": "google"
    },
    {
        "label": "Varela Round",
        "value": "Varela Round,sans-serif",
        "src": "google"
    },
    {
        "label": "Yanone Kaffeesatz",
        "value": "Yanone Kaffeesatz,sans-serif",
        "src": "google"
    },
    {
        "label": "Nunito Sans",
        "value": "Nunito Sans,sans-serif",
        "src": "google"
    },
    {
        "label": "Merriweather Sans",
        "value": "Merriweather Sans,sans-serif",
        "src": "google"
    },
    {
        "label": "Shadows Into Light",
        "value": "Shadows Into Light,handwriting",
        "src": "google"
    },
    {
        "label": "Dancing Script",
        "value": "Dancing Script,handwriting",
        "src": "google"
    },
    {
        "label": "Source Serif Pro",
        "value": "Source Serif Pro,serif",
        "src": "google"
    },
    {
        "label": "Source Code Pro",
        "value": "Source Code Pro,monospace",
        "src": "google"
    },
    {
        "label": "Abril Fatface",
        "value": "Abril Fatface,display",
        "src": "google"
    },
    {
        "label": "Ubuntu Condensed",
        "value": "Ubuntu Condensed,sans-serif",
        "src": "google"
    },
    {
        "label": "Bree Serif",
        "value": "Bree Serif,serif",
        "src": "google"
    },
    {
        "label": "Acme",
        "value": "Acme,sans-serif",
        "src": "google"
    },
    {
        "label": "Asap",
        "value": "Asap,sans-serif",
        "src": "google"
    },
    {
        "label": "Archivo Narrow",
        "value": "Archivo Narrow,sans-serif",
        "src": "google"
    },
    {
        "label": "Questrial",
        "value": "Questrial,sans-serif",
        "src": "google"
    },
    {
        "label": "Gloria Hallelujah",
        "value": "Gloria Hallelujah,handwriting",
        "src": "google"
    },
    {
        "label": "PT Sans Caption",
        "value": "PT Sans Caption,sans-serif",
        "src": "google"
    },
    {
        "label": "Encode Sans Condensed",
        "value": "Encode Sans Condensed,sans-serif",
        "src": "google"
    },
    {
        "label": "Kanit",
        "value": "Kanit,sans-serif",
        "src": "google"
    },
    {
        "label": "Exo",
        "value": "Exo,sans-serif",
        "src": "google"
    },
    {
        "label": "Signika",
        "value": "Signika,sans-serif",
        "src": "google"
    },
    {
        "label": "Amatic SC",
        "value": "Amatic SC,handwriting",
        "src": "google"
    },
    {
        "label": "Comfortaa",
        "value": "Comfortaa,display",
        "src": "google"
    },
    {
        "label": "Merienda",
        "value": "Merienda,handwriting",
        "src": "google"
    },
    {
        "label": "Hind Siliguri",
        "value": "Hind Siliguri,sans-serif",
        "src": "google"
    },
    {
        "label": "Play",
        "value": "Play,sans-serif",
        "src": "google"
    },
    {
        "label": "Maven Pro",
        "value": "Maven Pro,sans-serif",
        "src": "google"
    },
    {
        "label": "EB Garamond",
        "value": "EB Garamond,serif",
        "src": "google"
    },
    {
        "label": "Teko",
        "value": "Teko,sans-serif",
        "src": "google"
    },
    {
        "label": "Cairo",
        "value": "Cairo,sans-serif",
        "src": "google"
    },
    {
        "label": "Francois One",
        "value": "Francois One,sans-serif",
        "src": "google"
    },
    {
        "label": "Crete Round",
        "value": "Crete Round,serif",
        "src": "google"
    },
    {
        "label": "Rokkitt",
        "value": "Rokkitt,serif",
        "src": "google"
    },
    {
        "label": "Patua One",
        "value": "Patua One,display",
        "src": "google"
    },
    {
        "label": "Cinzel",
        "value": "Cinzel,serif",
        "src": "google"
    },
    {
        "label": "Righteous",
        "value": "Righteous,display",
        "src": "google"
    },
    {
        "label": "Heebo",
        "value": "Heebo,sans-serif",
        "src": "google"
    },
    {
        "label": "Vollkorn",
        "value": "Vollkorn,serif",
        "src": "google"
    },
    {
        "label": "Cuprum",
        "value": "Cuprum,sans-serif",
        "src": "google"
    },
    {
        "label": "Permanent Marker",
        "value": "Permanent Marker,handwriting",
        "src": "google"
    },
    {
        "label": "Alegreya",
        "value": "Alegreya,serif",
        "src": "google"
    },
    {
        "label": "Catamaran",
        "value": "Catamaran,sans-serif",
        "src": "google"
    },
    {
        "label": "Old Standard TT",
        "value": "Old Standard TT,serif",
        "src": "google"
    },
    {
        "label": "Domine",
        "value": "Domine,serif",
        "src": "google"
    },
    {
        "label": "Pathway Gothic One",
        "value": "Pathway Gothic One,sans-serif",
        "src": "google"
    },
    {
        "label": "Ropa Sans",
        "value": "Ropa Sans,sans-serif",
        "src": "google"
    },
    {
        "label": "Satisfy",
        "value": "Satisfy,handwriting",
        "src": "google"
    },
    {
        "label": "Concert One",
        "value": "Concert One,display",
        "src": "google"
    },
    {
        "label": "Great Vibes",
        "value": "Great Vibes,handwriting",
        "src": "google"
    },
    {
        "label": "Kaushan Script",
        "value": "Kaushan Script,handwriting",
        "src": "google"
    },
    {
        "label": "ABeeZee",
        "value": "ABeeZee,sans-serif",
        "src": "google"
    },
    {
        "label": "Courgette",
        "value": "Courgette,handwriting",
        "src": "google"
    },
    {
        "label": "Didact Gothic",
        "value": "Didact Gothic,sans-serif",
        "src": "google"
    },
    {
        "label": "Noticia Text",
        "value": "Noticia Text,serif",
        "src": "google"
    },
    {
        "label": "Rajdhani",
        "value": "Rajdhani,sans-serif",
        "src": "google"
    },
    {
        "label": "Cardo",
        "value": "Cardo,serif",
        "src": "google"
    },
    {
        "label": "Fira Sans Condensed",
        "value": "Fira Sans Condensed,sans-serif",
        "src": "google"
    },
    {
        "label": "Prompt",
        "value": "Prompt,sans-serif",
        "src": "google"
    },
    {
        "label": "Assistant",
        "value": "Assistant,sans-serif",
        "src": "google"
    },
    {
        "label": "News Cycle",
        "value": "News Cycle,sans-serif",
        "src": "google"
    },
    {
        "label": "Cookie",
        "value": "Cookie,handwriting",
        "src": "google"
    },
    {
        "label": "Quattrocento Sans",
        "value": "Quattrocento Sans,sans-serif",
        "src": "google"
    },
    {
        "label": "Alegreya Sans",
        "value": "Alegreya Sans,sans-serif",
        "src": "google"
    },
    {
        "label": "Poiret One",
        "value": "Poiret One,display",
        "src": "google"
    },
    {
        "label": "Istok Web",
        "value": "Istok Web,sans-serif",
        "src": "google"
    },
    {
        "label": "Josefin Slab",
        "value": "Josefin Slab,serif",
        "src": "google"
    },
    {
        "label": "Orbitron",
        "value": "Orbitron,sans-serif",
        "src": "google"
    },
    {
        "label": "Quattrocento",
        "value": "Quattrocento,serif",
        "src": "google"
    },
    {
        "label": "Monoton",
        "value": "Monoton,display",
        "src": "google"
    },
    {
        "label": "Arapey",
        "value": "Arapey,serif",
        "src": "google"
    },
    {
        "label": "Monda",
        "value": "Monda,sans-serif",
        "src": "google"
    },
    {
        "label": "Amiri",
        "value": "Amiri,serif",
        "src": "google"
    },
    {
        "label": "Lobster Two",
        "value": "Lobster Two,display",
        "src": "google"
    },
    {
        "label": "Cormorant Garamond",
        "value": "Cormorant Garamond,serif",
        "src": "google"
    },
    {
        "label": "Archivo Black",
        "value": "Archivo Black,sans-serif",
        "src": "google"
    },
    {
        "label": "Archivo",
        "value": "Archivo,sans-serif",
        "src": "google"
    },
    {
        "label": "Khand",
        "value": "Khand,sans-serif",
        "src": "google"
    },
    {
        "label": "Volkhov",
        "value": "Volkhov,serif",
        "src": "google"
    },
    {
        "label": "Caveat",
        "value": "Caveat,handwriting",
        "src": "google"
    },
    {
        "label": "Passion One",
        "value": "Passion One,display",
        "src": "google"
    },
    {
        "label": "Kalam",
        "value": "Kalam,handwriting",
        "src": "google"
    },
    {
        "label": "Russo One",
        "value": "Russo One,sans-serif",
        "src": "google"
    },
    {
        "label": "Fredoka One",
        "value": "Fredoka One,display",
        "src": "google"
    },
    {
        "label": "Tinos",
        "value": "Tinos,serif",
        "src": "google"
    },
    {
        "label": "Alfa Slab One",
        "value": "Alfa Slab One,display",
        "src": "google"
    },
    {
        "label": "Yantramanav",
        "value": "Yantramanav,sans-serif",
        "src": "google"
    },
    {
        "label": "Tangerine",
        "value": "Tangerine,handwriting",
        "src": "google"
    },
    {
        "label": "Sacramento",
        "value": "Sacramento,handwriting",
        "src": "google"
    },
    {
        "label": "Nanum Myeongjo",
        "value": "Nanum Myeongjo,serif",
        "src": "google"
    },
    {
        "label": "Bangers",
        "value": "Bangers,display",
        "src": "google"
    },
    {
        "label": "Economica",
        "value": "Economica,sans-serif",
        "src": "google"
    },
    {
        "label": "Luckiest Guy",
        "value": "Luckiest Guy,display",
        "src": "google"
    },
    {
        "label": "Basic",
        "value": "Basic,sans-serif",
        "src": "google"
    },
    {
        "label": "Handlee",
        "value": "Handlee,handwriting",
        "src": "google"
    },
    {
        "label": "Hind Madurai",
        "value": "Hind Madurai,sans-serif",
        "src": "google"
    },
    {
        "label": "BenchNine",
        "value": "BenchNine,sans-serif",
        "src": "google"
    },
    {
        "label": "Playfair Display SC",
        "value": "Playfair Display SC,serif",
        "src": "google"
    },
    {
        "label": "Pontano Sans",
        "value": "Pontano Sans,sans-serif",
        "src": "google"
    },
    {
        "label": "Gudea",
        "value": "Gudea,sans-serif",
        "src": "google"
    },
    {
        "label": "Cabin Condensed",
        "value": "Cabin Condensed,sans-serif",
        "src": "google"
    },
    {
        "label": "Chivo",
        "value": "Chivo,sans-serif",
        "src": "google"
    },
    {
        "label": "Cantarell",
        "value": "Cantarell,sans-serif",
        "src": "google"
    },
    {
        "label": "Pragati Narrow",
        "value": "Pragati Narrow,sans-serif",
        "src": "google"
    },
    {
        "label": "Neuton",
        "value": "Neuton,serif",
        "src": "google"
    },
    {
        "label": "Tajawal",
        "value": "Tajawal,sans-serif",
        "src": "google"
    },
    {
        "label": "Philosopher",
        "value": "Philosopher,sans-serif",
        "src": "google"
    },
    {
        "label": "M PLUS 1p",
        "value": "M PLUS 1p,sans-serif",
        "src": "google"
    },
    {
        "label": "Barlow",
        "value": "Barlow,sans-serif",
        "src": "google"
    },
    {
        "label": "Patrick Hand",
        "value": "Patrick Hand,handwriting",
        "src": "google"
    },
    {
        "label": "Sanchez",
        "value": "Sanchez,serif",
        "src": "google"
    },
    {
        "label": "Hammersmith One",
        "value": "Hammersmith One,sans-serif",
        "src": "google"
    },
    {
        "label": "Vidaloka",
        "value": "Vidaloka,serif",
        "src": "google"
    },
    {
        "label": "Khula",
        "value": "Khula,sans-serif",
        "src": "google"
    },
    {
        "label": "Frank Ruhl Libre",
        "value": "Frank Ruhl Libre,serif",
        "src": "google"
    },
    {
        "label": "Boogaloo",
        "value": "Boogaloo,display",
        "src": "google"
    },
    {
        "label": "Armata",
        "value": "Armata,sans-serif",
        "src": "google"
    },
    {
        "label": "Fira Sans Extra Condensed",
        "value": "Fira Sans Extra Condensed,sans-serif",
        "src": "google"
    },
    {
        "label": "Amaranth",
        "value": "Amaranth,sans-serif",
        "src": "google"
    },
    {
        "label": "Zilla Slab",
        "value": "Zilla Slab,serif",
        "src": "google"
    },
    {
        "label": "Yrsa",
        "value": "Yrsa,serif",
        "src": "google"
    },
    {
        "label": "Glegoo",
        "value": "Glegoo,serif",
        "src": "google"
    },
    {
        "label": "Sorts Mill Goudy",
        "value": "Sorts Mill Goudy,serif",
        "src": "google"
    },
    {
        "label": "Marck Script",
        "value": "Marck Script,handwriting",
        "src": "google"
    },
    {
        "label": "Unica One",
        "value": "Unica One,display",
        "src": "google"
    },
    {
        "label": "Ruda",
        "value": "Ruda,sans-serif",
        "src": "google"
    },
    {
        "label": "Audiowide",
        "value": "Audiowide,display",
        "src": "google"
    },
    {
        "label": "Ultra",
        "value": "Ultra,serif",
        "src": "google"
    },
    {
        "label": "Montserrat",
        "value": "Montserrat,sans-serif",
        "src": "google"
    },
    {
        "label": "Montserrat Alternates",
        "value": "Montserrat Alternates,sans-serif",
        "src": "google"
    },
    {
        "label": "Prosto One",
        "value": "Prosto One,display",
        "src": "google"
    },
    {
        "label": "Eczar",
        "value": "Eczar,serif",
        "src": "google"
    },
    {
        "label": "Paytone One",
        "value": "Paytone One,sans-serif",
        "src": "google"
    },
    {
        "label": "Architects Daughter",
        "value": "Architects Daughter,handwriting",
        "src": "google"
    },
    {
        "label": "Gentium Basic",
        "value": "Gentium Basic,serif",
        "src": "google"
    },
    {
        "label": "Signika Negative",
        "value": "Signika Negative,sans-serif",
        "src": "google"
    },
    {
        "label": "Oleo Script",
        "value": "Oleo Script,display",
        "src": "google"
    },
    {
        "label": "PT Mono",
        "value": "PT Mono,monospace",
        "src": "google"
    },
    {
        "label": "Shadows Into Light Two",
        "value": "Shadows Into Light Two,handwriting",
        "src": "google"
    },
    {
        "label": "Enriqueta",
        "value": "Enriqueta,serif",
        "src": "google"
    },
    {
        "label": "Antic Slab",
        "value": "Antic Slab,serif",
        "src": "google"
    },
    {
        "label": "Alice",
        "value": "Alice,serif",
        "src": "google"
    },
    {
        "label": "Yellowtail",
        "value": "Yellowtail,handwriting",
        "src": "google"
    },
    {
        "label": "Hind Vadodara",
        "value": "Hind Vadodara,sans-serif",
        "src": "google"
    },
    {
        "label": "Kreon",
        "value": "Kreon,serif",
        "src": "google"
    },
    {
        "label": "Sigmar One",
        "value": "Sigmar One,display",
        "src": "google"
    },
    {
        "label": "Prata",
        "value": "Prata,serif",
        "src": "google"
    },
    {
        "label": "Neucha",
        "value": "Neucha,handwriting",
        "src": "google"
    },
    {
        "label": "Bad Script",
        "value": "Bad Script,handwriting",
        "src": "google"
    },
    {
        "label": "Julius Sans One",
        "value": "Julius Sans One,sans-serif",
        "src": "google"
    },
    {
        "label": "Julius Sans One",
        "value": "Julius Sans One,sans-serif",
        "src": "google"
    },
    {
        "label": "Roboto",
        "value": "Roboto,sans-serif",
        "src": "google"
    },
    {
        "label": "Lato",
        "value": "Lato,sans-serif",
        "src": "google"
    },
    {
        "label": "Oswald",
        "value": "Oswald,sans-serif",
        "src": "google"
    },
    {
        "label": "Source Sans Pro",
        "value": "Source Sans Pro,sans-serif",
        "src": "google"
    },
    {
        "label": "Red Hat Display",
        "value": "Red Hat Display,sans-serif",
        "src": "google"
    },
    {
        "label": "Maitree",
        "value": "Maitree,sans-serif",
        "src": "google"
    },
    {
        "label": "Poppins",
        "value": "Poppins,sans-serif",
        "src": "google"
    },
    {
        "label": "Albert Sans",
        "value": "Albert Sans,sans-serif",
        "src": "google"

    }].sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase())
            return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase())
            return 1;
        return 0;
    });

module.exports = fontArray
import React from 'react'
import UILIB from '../../Lib';
import Help from '../../Logos/help';
import ReactTooltip from 'react-tooltip'
import ScrollHint from 'scroll-hint'

export default class DataTable1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingData: 1,
            tableData: [],
            tableDataFixed: [],
            firstRun: true
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps != this.props || this.state != nextState) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.loadingData != prevProps.loadingData) {
            this.setState({ loadingData: this.props.loadingData }, () => {
                if (!this.scrollhint) this.scrollhint = new ScrollHint('.js-scrollable', {
                    remainingTime: 2500,
                    appendToParent: true
                });
            });
        }
    }


    componentDidMount() {
        this.setState({ loadingData: this.props.loadingData });
    }



    openPop() {

    }

    render() {

        var thisLoadingHolder = [];
        for (var x = 0; x < this.props.pageSize; x++) {
            thisLoadingHolder.push(<tr key={"blank-" + x} >
                <td width="20%" style={{ padding: "10px" }}><div className="blankCellPadder" /></td>
                <td width="20%" style={{ padding: "10px" }}><div className="blankCellPadder" /></td>
                <td width="20%" style={{ padding: "10px" }}><div className="blankCellPadder" /></td>
                <td width="20%" style={{ padding: "10px" }}><div className="blankCellPadder" /></td>
                <td width="20%" style={{ padding: "10px" }}><div className="blankCellPadder" /></td>
            </tr>);
        }

        let outerClassName = "table-cont2 with-lines";
        if (this.props.className) outerClassName += " " + this.props.className;

        //Normal Code
        if (this.state.loadingData === 1) {

            return <table width="100%" className={outerClassName}>
                <tbody>
                    {thisLoadingHolder}
                </tbody>
            </table>

        }
        else {

            if (Object.keys(this.props.tableData).length == 0) {
                return <table className={outerClassName} cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td><div style={{ textAlign: "center", padding: "20px" }}>{this.props.noResultsTxt}</div></td>
                        </tr>
                    </tbody>
                </table>
            } else {

                var sortDir = (this.props.sortedDirection || '').toLowerCase();
                var sortCol = this.props.sortedColumn;
                var header = Object.keys(this.props.tableData[0]).filter(h => h !== 'className').map(key => {
                    var obj = this.props.tableData[0][key];
                    obj.key = key;
                    return obj;
                })

                var outerStyle = {

                }
                if (this.props.outerStyle) {
                    outerStyle = this.props.outerStyle;
                }

                if (header[0].order !== undefined) header.sort((a, b) => a.order - b.order);

                let holderClass = "tableHolder"
                let holderStyle = { height: this.props.height }
                if (!this.props.hideScrollable) {
                    holderClass += " js-scrollable"
                    outerStyle.position = "relative"
                } else {
                    holderStyle.overflow = 'initial'
                }

                if (this.props.holderStyle) {
                    holderStyle = { ...holderStyle, ...this.props.holderStyle }
                }

                let tableData = this.props.tableData

                if (this.props.paddingLines && this.props.pageSize && this.props.pageSize > 1 && this.props.tableData.length < this.props.pageSize) {
                    //add padding rows
                    let totRows = tableData.length;
                    let rCnt = 0;
                    while (rCnt <= (this.props.pageSize - totRows)) {
                        let outPut = tableData[0].map(col => {
                            return {
                                headerValue: col.headerValue,
                                value: <div />
                            }
                        })
                        tableData.push(outPut)
                        rCnt++;
                    }
                }

                return (
                    <div style={outerStyle}>
                        <div className={holderClass} style={holderStyle}>
                            <table className={outerClassName} cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        {header.map((thisObj, index) => {
                                            if (thisObj.hasOwnProperty("headerValue") === false) {
                                                return
                                            }

                                            var classes = "";
                                            if (thisObj.isCheckBox) {
                                                classes += " checkboxFix";
                                            }

                                            var theStyle = {};
                                            if (thisObj.headerStyle) {
                                                theStyle = { ...thisObj.headerStyle }
                                            }
                                            if (thisObj.align) {
                                                theStyle.textAlign = thisObj.align;
                                            }
                                            if (thisObj.width) {
                                                theStyle.width = thisObj.width + "px";
                                                theStyle.minWidth = thisObj.width + "px";
                                            }

                                            var finalSortDir = "";
                                            var nextSortDir = sortDir
                                            if (sortCol === thisObj.key) {

                                                nextSortDir = sortDir == 'asc' ? 'desc' : 'asc'
                                                if (sortDir == "asc") {
                                                    finalSortDir = "asc";
                                                } else {
                                                    finalSortDir = "desc";
                                                }

                                            }

                                            var thisClick = () => {
                                                if (thisObj.orderBy) {
                                                    this.props.dataUpdater(null, nextSortDir, thisObj.key, null);
                                                }
                                            };

                                            var helper = !!thisObj.helper && <>
                                                <Help width="16px" data-tip data-for={'table-helper-' + index} />
                                                <ReactTooltip effect='solid' className={"tooltip tooltipwrapper"} id={'table-helper-' + index} >{thisObj.helper}</ReactTooltip>
                                            </>

                                            var thisInnerHTML = <span>{thisObj.headerValue} {helper}</span>;

                                            if (thisObj.orderBy == true || sortCol === thisObj.key) {
                                                thisInnerHTML = <UILIB.TableSortLabel direction={finalSortDir}
                                                    align={theStyle.textAlign}
                                                    active={sortCol === thisObj.key}
                                                    onClick={thisClick}>{thisObj.headerValue} {helper}
                                                </UILIB.TableSortLabel>
                                            }


                                            return <th key={"headCol" + index} name={"headCol" + index} style={theStyle} className={classes}>{thisInnerHTML}</th>
                                        })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((theRow, rowIndex) => {
                                        var classes = ''
                                        let deleting = false;
                                        if (theRow.className) classes = theRow.className
                                        if (this.props.deleting && theRow.id && theRow.id.value) {
                                            if (this.props.deleting[theRow.id.value]) {
                                                classes += ' deleting'
                                                deleting = true
                                            }
                                        }
                                        return <tr key={"row-" + rowIndex} onClick={() => {
                                            if (typeof this.props.rowClick === 'function') this.props.rowClick(theRow)
                                        }} className={classes} data-tip={theRow.tooltip?.tip} data-for={theRow.tooltip?.for}>{header.map((theCol, colIndex) => {
                                            var thisObj = theRow[theCol.key];
                                            if (typeof thisObj !== 'object' || thisObj.hasOwnProperty("headerValue") === false) {
                                                return null
                                            }
                                            var theStyle = {};
                                            var tdClasses = "";
                                            var divClasses = "";
                                            var divStyle = {}
                                            if (thisObj.align) {
                                                theStyle.textAlign = thisObj.align;
                                                divStyle.display = 'flex'
                                                divStyle.justifyContent = 'center';
                                            }
                                            if (thisObj.fixed) {
                                                divClasses = "fixedCol";
                                                tdClasses = "tdFixedCol";

                                                if (thisObj.fixedSml) {
                                                    divClasses += " fixedColSml";
                                                    tdClasses += ' tdFixedColSml';
                                                }
                                            }

                                            if (thisObj.isCheckBox) {
                                                tdClasses += " checkboxFix"
                                            }

                                            if (thisObj.cellStyle) {
                                                theStyle = { ...theStyle, ...thisObj.cellStyle }
                                            }
                                            return <td className={tdClasses} key={"col-" + rowIndex + "-" + colIndex} name={"col-" + rowIndex + "-" + colIndex} style={theStyle}>
                                                <div className={divClasses} style={divStyle}>{deleting && thisObj.isCheckBox ? '' : thisObj.value}</div>
                                                {theRow.tooltip?.tip && theRow.tooltip?.for && <ReactTooltip id={theRow.tooltip?.for} effect="solid" />}
                                            </td>
                                        })}
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </ div>
                )

            }
        }

    }
}

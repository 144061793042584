const PermissionChecker = (name, permissions, method) => {
    var allowed = false
    let userPermissions = permissions;
    if (permissions.permissions && permissions.grouping) {
        userPermissions = permissions.permissions
        let parent = permissions.grouping.find((group) => group.children.indexOf(name) > -1)
        if (parent) {
            let parentPermission = permissions.permissions[parent.name];
            if (parentPermission == "none") {
                return false;
            }
        }
    }
    if (userPermissions[name]) {
        var ourPerm = userPermissions[name];
        if (ourPerm == method) allowed = true;
        if (method == "read" && ourPerm == "write") allowed = true;

    }
    return allowed
}

export default PermissionChecker
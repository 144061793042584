import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import i18n from '~/i18n'

export default class IncGoDeleteSubs extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            pageLoaded: true
        };

        this.goDeleteSubs = this.goDeleteSubs.bind(this);
    }

    goDeleteSubs() {
        var self = this;
        var theSubs = this.props.subscribers.filter(sub => sub.checkBox.isChecked == true);

        var idsToDelete = theSubs.map((element, index) => {
            return element.subscriberData.id;
        })

        this.setState({ pageLoaded: false })

        axios.delete('/subscriber', {
            data: { subscriberIds: idsToDelete }
        }).then((res) => {
            self.props.reloadAfterTickedAndChanged();
        });
    }

    render() {

        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />

        return <div>

            <div className="quickFlex mar-b25">
                <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.goBack}>{i18n.t('goBack')}</UILIB.Hint>
            </div>

            <h4 className="mar-b25">{i18n.t('subscribers:overview.deleteHeader')}</h4>
            <div className="mar-b25">{i18n.t('subscribers:overview.deleteSubHeader')}</div>

            <UILIB.Button
                text={i18n.t('subscribers:overview.deleteButton')}
                className="button-primary"
                onClick={this.goDeleteSubs}
                iconRight={<UILIB.Icons icon="tick" />}
            />
        </div >

    }
};


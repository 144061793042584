import React from 'react'

import { Switch, Redirect } from 'react-router-dom';

import SubscribersTemplate from './template'
import Subscribers from './subscribers';
import Groups from './groups/groups';
import CustomFields from './customfields/customFields';
import Segments from './segments/segments';
import Export from './export/export';
import PermissionRouter from '~/Common-Objects/Router/permissionRoute'
import Analytics from './analytics/analytics'
import ListClean from './clean/clean'
import StartListClean from './clean/startCleanJob'
import ViewListClean from './clean/viewCleanJob'
import Tags from './tags'
import Organisations from './organisations';
import Organisation from './organisations/organisation';
export default class SubscriberTemplateFP extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <SubscribersTemplate history={this.props.history}>
            <Switch>
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/subscribers" component={Subscribers} />
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/subscribers/groups" component={Groups} />
                <PermissionRouter permissionName="segments" method="read" exact path="/cp/subscribers/segments" component={Segments} />
                <PermissionRouter permissionName="customFields" method="read" exact path="/cp/subscribers/customfields" component={CustomFields} />
                <PermissionRouter permissionName="reporting" method="read" exact path="/cp/subscribers/export" component={Export} />
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/subscribers/analytics" component={Analytics} />
                <PermissionRouter permissionName="subscribers" method="write" exact path="/cp/subscribers/clean" component={ListClean} />
                <PermissionRouter permissionName="subscribers" method="write" exact path="/cp/subscribers/clean/startclean/:groupId?" component={StartListClean} />
                <PermissionRouter permissionName="subscribers" method="write" exact path="/cp/subscribers/clean/:jobId" component={ViewListClean} />
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/subscribers/tags" component={Tags} />
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/subscribers/organisations" component={Organisations} />
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/subscribers/organisations/:id" component={Organisation} />
                <Redirect to="/cp/home" push />
            </Switch>
        </SubscribersTemplate>

    }
};


import React, { Fragment } from 'react';

export default function LoadingLine({ className = "", height = 0, title = "", color = "", outerStyle = null }) {
    var classes = "loader-line"
    if (className) classes += " " + className
    let style = {}
    if (height) style.height = height
    if (outerStyle && typeof outerStyle === 'object') {
        style = { ...style, outerStyle }
    }

    const barStyle = {}
    if (color) {
        barStyle.backgroundColor = color
    }

    return (
        <Fragment>
            {!!title && <div style={{ margin: '0 10px' }}>{title}</div>}
            <div className={classes} style={style}>
                <div className="bar" style={barStyle}></div>
                <div className="bar" style={barStyle}></div>
            </div>
        </Fragment>
    );
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VMPCBRTQ1V4qllLwgNAd{display:inline-flex;gap:12px;align-items:center;border:1px solid var(--grey-200);border-radius:8px;padding:12px 16px}.QBCtabC7dzPTB6fUENf8{display:flex;align-items:center;justify-content:center;height:32px;width:32px;border-radius:100%;background:var(--grey-50);color:var(--grey-700)}.mSGJrlbbwQax26VLBYcQ{font-weight:500}.mVtVPriSudGEJL2kbMPn{font-size:12px;color:var(--grey-600)}", "",{"version":3,"sources":["webpack://./src/Common-Objects/UI/Chat/Attachment/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,QAAA,CACA,kBAAA,CACA,gCAAA,CACA,iBAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,UAAA,CACA,kBAAA,CACA,yBAAA,CACA,qBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,cAAA,CACA,qBAAA","sourcesContent":[".root {\n  display: inline-flex;\n  gap: 12px;\n  align-items: center;\n  border: 1px solid var(--grey-200);\n  border-radius: 8px;\n  padding: 12px 16px;\n}\n\n.iconWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 32px;\n  width: 32px;\n  border-radius: 100%;\n  background: var(--grey-50);\n  color: var(--grey-700);\n}\n\n.fileName {\n  font-weight: 500;\n}\n\n.fileSize {\n  font-size: 12px;\n  color: var(--grey-600);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "VMPCBRTQ1V4qllLwgNAd",
	"iconWrapper": "QBCtabC7dzPTB6fUENf8",
	"fileName": "mSGJrlbbwQax26VLBYcQ",
	"fileSize": "mVtVPriSudGEJL2kbMPn"
};
export default ___CSS_LOADER_EXPORT___;

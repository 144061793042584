import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import './index.scss';
import Icons from '../../Icons'

const MultiAccordian = ({ items = [], active, setActive = () => { } }) => (
    <Accordion.Root className="AccordionRoot" type="single" value={active} onValueChange={setActive} collapsible>
        {items.map((item, index) => {
            return <Accordion.Item className="AccordionItem" value={item.id} key={index}>
                <AccordionTrigger>{item.header}</AccordionTrigger>
                <AccordionContent>{item.content}</AccordionContent>
            </Accordion.Item>
        })}
    </Accordion.Root>
);

const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="AccordionHeader">
        <Accordion.Trigger
            className={classNames('AccordionTrigger', className)}
            {...props}
            ref={forwardedRef}
        >
            {children}
            <Icons icon="chevronDown" className="accordionIcon" color="#171819" />
        </Accordion.Trigger>
    </Accordion.Header>
));

const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
        className={classNames('AccordionContent', className)}
        {...props}
        ref={forwardedRef}
    >
        <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
));

export default MultiAccordian;
import React from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class TemplateImportFromWeb extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            url: '',
            templateName: '',
            newGroup: false,
            newGroupName: '',
            groups: [],
            groupId: undefined,
            processing: false,
            errors: {
                url: '',
                templateName: '',
                newGroupName: ''
            }
        };

        this.getGroups = this.getGroups.bind(this);
        this.updateField = this.updateField.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.import = this.import.bind(this);
    }

    componentDidMount() {
        this.getGroups();
    }

    updateField(ev) {
        this.setState({
            [ev.currentTarget.name]: ev.currentTarget.value
        })
    }

    getGroups() {
        axios.get('/templateGroup')
            .then(response => {
                var groups = [{
                    label: i18n.t('templates:add.advanced.none'),
                    value: ''
                }, {
                    label: i18n.t('templates:add.advanced.createGroup'),
                    value: 'new'
                }]
                this.setState({
                    groups: groups.concat(response.data.TemplateGroups.map(g => {
                        return {
                            label: g.groupName,
                            value: g.id
                        }
                    })),
                    ready: true
                })
            })
    }

    updateGroup(ev) {
        var value = ev.target.value;
        this.setState({
            groupId: value,
            newGroup: value === 'new'
        })
    }

    async import() {
        try {
            var errors = this.state.errors;
            errors.url = '';
            errors.templateName = '';
            this.setState({
                processing: true,
                errors
            })

            var errors = this.state.errors
            if (!this.state.url) {
                errors.url = i18n.t('templates:add.advanced.webError')
                return this.setState({
                    errors,
                    processing: false
                })
            }

            if (!this.state.templateName) {
                errors.templateName = i18n.t('templates:add.advanced.invalidName')
                return this.setState({
                    errors,
                    processing: false
                })
            }
            let newTemplate;
            if (!this.props.campaignId && this.state.newGroup) {
                if (!this.state.newGroupName) {
                    errors.newGroupName = i18n.t('templates:add.advanced.invalidGroup')
                    return this.setState({
                        errors,
                        processing: false
                    })
                }

                const templateGroup = await this.createGroup()

                newTemplate = await axios.post('/template/importweb', {
                    url: this.state.url,
                    groupID: templateGroup.data.TemplateGroup.id,
                    templateName: this.state.templateName
                })

            } else {
                newTemplate = await axios.post('/template/importweb', {
                    url: this.state.url,
                    templateName: this.state.templateName,
                    CampaignId: this.props.campaignId
                })
            }

            this.props.finish(newTemplate.data.Template, this.state.url)
        }
        catch (err) {
            console.log(err);
            var errors = this.state.errors;
            if (err.data.error.url) {
                errors.url = err.data.error.url
            }
            if (err.data.error.templateName) {
                errors.templateName = err.data.error.templateName
            }
            this.setState({
                processing: false,
                errors
            })
        }
    }

    render() {
        if (!this.state.ready) {
            return <div></div>
        } else {
            return <div>
                <h4 className="mar-b25">{i18n.t('templates:add.advanced.webHeader')}</h4>

                <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('templates:add.advanced.webSubHeader')} value={this.state.url} name="url" onChange={this.updateField} placeholder={i18n.t('templates:add.advanced.webPlaceholder')} error={this.state.errors.url} />

                <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('templates:add.advanced.name')} type="text" value={this.state.templateName} onChange={this.updateField} name="templateName" placeholder={i18n.t('templates:add.advanced.name')} error={this.state.errors.templateName} />

                {!this.props.campaignId &&
                    <UILIB.Select outerClassName="mar-b25" label={i18n.t('templates:add.advanced.group')} type="text" value={this.state.groupId} data={this.state.groups} onChange={this.updateGroup} name="groupId" />
                }
                {this.state.newGroup &&
                    <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('templates:add.advanced.newGroup')} type="text" value={this.state.newGroupName} onChange={this.updateField} placeholder={i18n.t('templates:add.advanced.newGroup')} name="newGroupName" error={this.state.errors.newGroupName} />
                }
                <UILIB.Button onClick={this.import} className="button-primary">{i18n.t('templates:add.advanced.importButton')}</UILIB.Button>
                {this.state.processing && <UILIB.LoadingLine className="mar-t20" />}
            </div>
        }
    };
}

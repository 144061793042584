import React, { useEffect, useState } from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'

export default function CampaignFolders({ campaign, onFinish = () => { } }) {
    const [folders, setFolders] = useState(null)
    const [selected, setSelected] = useState(null)
    const [adding, setAdding] = useState(false)
    const [newName, setNewName] = useState('')

    const loadFolders = async () => {
        const res = await axios.get('/campaign/folder')
        setFolders(res.data)
    }

    const select = e => {
        const arr = [...selected]
        const index = arr.indexOf(e.target.value)
        if (index > -1) {
            arr.splice(index, 1)
        } else {
            arr.push(e.target.value)
        }
        setSelected(arr)
    }

    const save = async () => {
        await axios.put('/campaign/' + campaign.id + '/folder', {
            folderId: selected
        })
        onFinish()
    }

    const add = async () => {
        if (newName && newName.trim().length > 3) {
            const newFolder = await axios.post('/campaign/folder', {
                name: newName.trim()
            })
            await loadFolders()
            select({ target: { value: newFolder.data.id } })
        }
    }

    useEffect(() => {
        loadFolders()
    }, [])

    useEffect(() => {
        axios.get('/campaign/' + campaign.id + '/folders').then(res => {
            setSelected(res.data.map(d => d.id))
        })
    }, [campaign])

    useEffect(() => {
        setNewName('')
        setAdding(false)
    }, [folders])

    if (!selected || !folders) return <UILIB.LoadingIcons />

    return (
        <div>
            <h3 className="mar-b25">{campaign.campaignName} Folders</h3>
            <UILIB.Select
                multiple={true}
                value={selected}
                data={folders.map(f => {
                    return { value: f.id, label: f.name }
                })}
                placeholder="Select Folders"
                onChange={select}
            />
            {!adding && <div className="quickFlex" style={{ justifyContent: 'flex-end' }}> <UILIB.Button onClick={() => setAdding(true)} text="Add new Folder" className="mar-t10 button-sml" style={{ marginLeft: 'auto' }} /></div>}
            {adding && <div className="quickFlex mar-t10">
                <UILIB.TextInput focus placeholder="Folder Name" value={newName} onEnterPress={add} onEscapePress={() => setAdding(false)} onChange={e => setNewName(e.target.value)} outerStyle={{ flexGrow: 1 }} />
                <UILIB.Button onClick={add} disabled={newName.length < 3} className="mar-l10 button-primary button-sml" iconLeftStyle={{ margin: 0 }} iconLeft={<UILIB.Icons icon="tick" />} />
                <UILIB.Button onClick={() => setAdding(false)} className="mar-l10 button-sml" iconLeftStyle={{ margin: 0 }} iconLeft={<UILIB.Icons icon="cross" />} />
            </div>}

            <div className="quickFlex mar-t10">
                <UILIB.Button className="button-primary mar-r10" onClick={save}>Save</UILIB.Button>
                <UILIB.Button onClick={onFinish}>Cancel</UILIB.Button>
            </div>
        </div>
    )
}
import React from 'react'
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

import ImportFile from './importFile';
import ImportFromWeb from './importWeb';
import ImportFromTemplate from './importSite';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class AdvancedTemplatesTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadHtml: false,
            importTemplate: false,
            downloadFromWeb: false
        };

        this.stopAll = this.stopAll.bind(this);
        this.startUploadHtml = this.startUploadHtml.bind(this);
        this.startImportTemplate = this.startImportTemplate.bind(this);
        this.startImportFromWeb = this.startImportFromWeb.bind(this);
        this.finish = this.finish.bind(this);
    }

    startUploadHtml() {
        this.setState({
            uploadHtml: true,
            importTemplate: false,
            downloadFromWeb: false
        })
    }
    startImportTemplate() {
        this.setState({
            uploadHtml: false,
            importTemplate: true,
            downloadFromWeb: false
        })
    }
    startImportFromWeb() {
        this.setState({
            uploadHtml: false,
            importTemplate: false,
            downloadFromWeb: true
        })
    }

    stopAll() {
        this.setState({
            uploadHtml: false,
            importTemplate: false,
            downloadFromWeb: false
        })
    }

    finish() {
        this.stopAll()
        this.props.history.push('/cp/templates');
    }

    render() {

        return <div>
            <h4 className="mar-b25">{i18n.t('templates:add.advanced.header')}</h4>
            <UILIB.Row>
                <UILIB.Column xs={12} sm={4}>
                    <UILIB.TemplateCard
                        icon={<UILIB.Icons icon="upload" circle={true} style={{ width: 30, height: 30 }} />}
                        title={i18n.t('templates:add.advanced.uploadTitle')}
                        content={i18n.t('templates:add.advanced.uploadSubTitle')}
                        button={<UILIB.Button text={i18n.t('templates:add.advanced.uploadButton')} className="" onClick={this.startUploadHtml} />}
                        style={{ height: "200px" }}
                    />

                </UILIB.Column>
                <UILIB.Column xs={12} sm={4}>
                    <UILIB.TemplateCard
                        icon={<UILIB.Icons icon="arrowDown" circle={true} style={{ width: 30, height: 30 }} />}
                        title={i18n.t('templates:add.advanced.importTitle')}
                        content={i18n.t('templates:add.advanced.importSubTitle')}
                        button={<UILIB.Button text={i18n.t('templates:add.advanced.importButton')} className="" onClick={this.startImportTemplate} />}
                        style={{ height: "200px" }}
                    />

                </UILIB.Column>
                <UILIB.Column xs={12} sm={4}>
                    <UILIB.TemplateCard
                        icon={<UILIB.Icons icon="world" circle={true} style={{ width: 30, height: 30 }} />}
                        title={i18n.t('templates:add.advanced.webTitle')}
                        content={i18n.t('templates:add.advanced.webSubTitle')}
                        button={<UILIB.Button text={i18n.t('templates:add.advanced.webButton')} className="" onClick={this.startImportFromWeb} />}
                        style={{ height: "200px" }}
                    />

                </UILIB.Column>

            </UILIB.Row>
            <UILIB.Drawer isOpen={this.state.uploadHtml} close={this.stopAll} standalone={true} showClose={true} >
                <ImportFile finish={this.finish} />
            </UILIB.Drawer>

            <UILIB.Drawer isOpen={this.state.importTemplate} close={this.stopAll} standalone={true} showClose={true}>
                <ImportFromTemplate finish={this.finish} />
            </UILIB.Drawer>

            <UILIB.Drawer close={this.stopAll} isOpen={this.state.downloadFromWeb} standalone={true} showClose={true}>
                <ImportFromWeb finish={this.finish} />
            </UILIB.Drawer>

        </div>

    };
}

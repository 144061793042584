import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

class FormDelete extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deleting: false
        }
        this.deleteForm = this.deleteForm.bind(this);
    }

    deleteForm() {
        this.setState({
            deleting: true
        })
        axios.delete('/group/' + this.props.groupId + '/form/' + this.props.formId).then(this.props.onClose)
    }

    render() {
        return (
            <div>

                <h4 className="mar-b25">{i18n.t('form:delete.header')}</h4>
                <div className="mar-b25">{i18n.t('form:delete.subHeader')}</div>

                <div className="row">
                    <div className="col-xs-6">
                        <UILIB.Button text={i18n.t('form:delete.delete')} disabled={this.state.deleting} onClick={this.deleteForm} className="button-primary button-fullwidth" />
                    </div>
                    <div className="col-xs-6">
                        <UILIB.Button text={i18n.t('form:delete.cancel')} disabled={this.state.deleting} onClick={this.props.cancel} className="button-fullwidth" />
                    </div>
                </div>
                {this.state.deleting &&
                    <UILIB.LoadingIcons iconType="2" />
                }

            </div>
        );
    }
}

export default FormDelete;
const TierPermissions = (name, accountMaster) => {

    var allowed = true
    var limit = -1; //no limit
    let tierName = accountMaster.tier ? accountMaster.tier.name : "";
    if (accountMaster && accountMaster.tier && accountMaster.tier.permissions) {
        let permissions = accountMaster.tier.permissions;
        var limit = 0;
        if (permissions[name]) {
            allowed = false;
            if (permissions[name].allowed) allowed = true;
            if (permissions[name].limit) limit = permissions[name].limit;
        }
    }
    return { allowed, limit, tierName }
}

export default TierPermissions
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';

import axios from '~/data/http/axios';
@connect((store) => {
    return { accountMaster: store.accountMaster, permissionStore: store.permission, siteMaster: store.siteMaster }
})
class updateOptionsDrawer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rnd: -1,
            options: {},
            pageLoaded: false
        }
        this.changeValue = this.changeValue.bind(this);
        this.saveValues = this.saveValues.bind(this);
        this.changeText = this.changeText.bind(this);
        this.getOptions = this.getOptions.bind(this);
    }

    componentDidMount() {
        setTimeout(this.getOptions, 1000);
    }
    async getOptions() {
        let options
        let group = {};
        if (this.props.group) {
            group = await axios.get('/group/' + this.props.group.id + '?skipCount=true')
            options = JSON.parse(JSON.stringify(group.data.Group.options || {}));
        } else {
            let account = await axios.get("/accountmaster")
            options = account.data.AccountMaster.options;
        }
        this.setState({ options, pageLoaded: true })
    }

    changeValue(event) {
        let options = this.state.options;
        options[event.target.name] = event.target.checked;
        this.setState({ options })
    }

    changeText(event) {
        let options = this.state.options;
        options[event.target.name] = event.target.value;
        this.setState({ options })
    }

    saveValues() {
        this.setState({ pageLoaded: false })
        if (this.props.group) {
            axios.put('/group/' + this.props.group.id + '/options', this.state.options).then((res) => {
                this.props.closeDrawer()
            }).catch(error => {
                this.props.closeDrawer()
            });
        } else {
            this.props.accountMaster.accountMaster.options = this.state.options;
            axios.put('/accountMaster', (this.props.accountMaster.accountMaster)).then((res) => {
                this.props.closeDrawer()
            }).catch(error => {
                this.props.closeDrawer()
            });
        }
    }
    render() {
        if (!this.state.pageLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>
        var thisOpts = this.state.options;

        var updateCanChooseGroups = true;
        var updateFixedGroup = false;
        var updateDisableDelete = false;
        var updateUnsubscribedText = "You've been Unsubscribed"
        var updateAlreadyUnsubscribedText = "You are not subscribed to any groups"
        var updateDeleteAllInfoText = "Delete all of my information"
        var updateUpdateButtonText = "Update";
        var updateUnsubButtonText = "Unsubscribe";
        if (thisOpts && thisOpts.hasOwnProperty("updateCanChooseGroups")) {
            updateCanChooseGroups = thisOpts.updateCanChooseGroups
        }
        if (thisOpts && thisOpts.hasOwnProperty("updateFixedGroup")) {
            updateFixedGroup = thisOpts.updateFixedGroup
        }
        if (thisOpts && thisOpts.hasOwnProperty("updateDisableDelete")) {
            updateDisableDelete = thisOpts.updateDisableDelete;
        }

        if (thisOpts && thisOpts.hasOwnProperty("updateDeleteAllInfoText")) {
            updateDeleteAllInfoText = thisOpts.updateDeleteAllInfoText;
        }
        if (thisOpts && thisOpts.hasOwnProperty("updateUpdateButtonText")) {
            updateUpdateButtonText = thisOpts.updateUpdateButtonText;
        }
        if (thisOpts && thisOpts.hasOwnProperty("updateUnsubButtonText")) {
            updateUnsubButtonText = thisOpts.updateUnsubButtonText;
        }

        if (thisOpts && thisOpts.hasOwnProperty("updateUnsubscribedText")) {
            updateUnsubscribedText = thisOpts.updateUnsubscribedText
        }
        if (thisOpts && thisOpts.hasOwnProperty("updateAlreadyUnsubscribedText")) {
            updateAlreadyUnsubscribedText = thisOpts.updateAlreadyUnsubscribedText
        }

        return <div>
            <h4 className="mar-b25">{i18n.t('company:landing.updateOptions.title')}</h4>
            <p>{i18n.t('company:landing.updateOptions.subTitle')}</p>


            <UILIB.Toggle className="mar-b25" name="updateCanChooseGroups" label={i18n.t('company:landing.updateOptions.subscribersCanChooseGroups')} value={updateCanChooseGroups} onChange={this.changeValue} />
            <UILIB.Toggle className="mar-b25" name="updateFixedGroup" label={i18n.t('company:landing.updateOptions.updateFixedGroup')} value={updateFixedGroup} onChange={this.changeValue} />
            <UILIB.Toggle className="mar-b25" name="updateDisableDelete" label={i18n.t('company:landing.updateOptions.updateDisableDelete')} value={updateDisableDelete} onChange={this.changeValue} />


            <UILIB.TextInput className="mar-b25" label={i18n.t('company:landing.updateOptions.deleteAllInfoHeader')} placeholder={i18n.t('company:landing.updateOptions.deleteAllInfoText')} name="updateDeleteAllInfoText" value={updateDeleteAllInfoText} onChange={this.changeText} />
            <UILIB.TextInput className="mar-b25" label={i18n.t('company:landing.updateOptions.updateButtonTextHeader')} placeholder={i18n.t('company:landing.updateOptions.updateButtonText')} name="updateUpdateButtonText" value={updateUpdateButtonText} onChange={this.changeText} />
            <UILIB.TextInput className="mar-b25" label={i18n.t('company:landing.updateOptions.unsubButtonText')} placeholder={i18n.t('company:landing.updateOptions.unsubButtonTextPlace')} name="updateUnsubButtonText" value={updateUnsubButtonText} onChange={this.changeText} />
            <UILIB.TextArea className="mar-b25" label={i18n.t('company:landing.updateOptions.unsubscribeText')} placeholder={i18n.t('company:landing.updateOptions.unsubscribeTextPlace')} name="updateUnsubscribedText" value={updateUnsubscribedText} onChange={this.changeText} />
            <UILIB.TextArea className="mar-b25" label={i18n.t('company:landing.updateOptions.alreadyUnsubscribedText')} placeholder={i18n.t('company:landing.updateOptions.alreadyUnsubscribedTextPlace')} name="updateAlreadyUnsubscribedText" value={updateAlreadyUnsubscribedText} onChange={this.changeText} />

            <UILIB.Button iconRight={<UILIB.Icons icon="tick" />} className="button-primary" text={i18n.t('translations:save')} onClick={this.saveValues} />
        </div>
    }
}

export default updateOptionsDrawer;
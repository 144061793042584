import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import i18n from '~/i18n'
import Deploy from '../../../edit/deploy'
import TemplateImageLoader from '~/pages/cp/automation/addNew/templateImageLoader.jsx'
import NewUserForm from '../../../../../../includes/bb_topNav/newUserForm/newUserForm';
import * as siteActions from '../../../../../../../../data/actions/siteActions'
import FormAutomate from '../../../automate';
@connect((store) => {
    return { accountMaster: store.accountMaster }
})
class FormConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {}
        }
        this.next = this.next.bind(this)
    }

    next(skipRedirect) {
        this.props.form.wizard.step6 = true
        this.props.form.status = 1;
        this.props.save()
        if (!skipRedirect) {
            var redirect = '/cp/groups/' + this.props.groupId + '/forms/edit/' + this.props.form.id;
            this.props.history.push(redirect);
        }
    }

    save() {
        this.props.form.wizard.step6 = true
        this.props.save()
        this.props.history.push('/cp/groups/' + this.props.groupId + '/forms')

    }

    activate() {
        const drawerContent = <NewUserForm from="form" />
        this.props.dispatch(siteActions.alterSiteDrawer(true, true, 'right', drawerContent, true, '100vw'))
    }

    automate() {
        const drawerContent = <FormAutomate formName={this.props.form.formName} GroupId={this.props.groupId} FormId={this.props.form.id} save={() => this.next(true)} edit={true} />
        this.props.dispatch(siteActions.alterSiteDrawer(true, true, 'right', drawerContent, true, 500))
    }


    render() {
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} md={4} className="mar-b25">
                        <div className="campaign-thumbnail" style={{ width: "100%", height: "100%", minHeight: "150px" }}>
                            <TemplateImageLoader imageUrl={this.props.screenshotUrl} form={this.props.form.formType === 1} />
                        </div>
                    </UILIB.Column>
                    <UILIB.Column xs={12} md={8}>
                        <UILIB.Paper>
                            {this.props.accountMaster.accountMaster.approved && <>
                                <h4 className="mar-b25">{i18n.t('form:step6.header')}</h4>
                                <Deploy form={this.props.form} />
                            </>}
                            {!this.props.accountMaster.accountMaster.approved && <>
                                <h4 className="mar-b25">{i18n.t('form:step6.header')}</h4>
                                <div>Forms cannot be deployed until we have approved your account</div>
                            </>}
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
                <div className="quickFlex end-xs">
                    <UILIB.Button className="mar-r10" iconRight={<UILIB.Icons icon="lightning" />} onClick={this.automate.bind(this)}>Automate</UILIB.Button>
                    {this.props.accountMaster.accountMaster.approved && <UILIB.Button
                        iconRight={<UILIB.Icons icon="arrowRight" />}
                        className="button-primary"
                        text={i18n.t('form:step6.finish')}
                        onClick={() => this.next()}
                    />}
                    {this.props.accountMaster.accountMaster.doneNewUserQuestions && !this.props.accountMaster.accountMaster.approved && <UILIB.Button
                        text="Save & Exit"
                        onClick={this.save.bind(this)}
                    />}
                    {!this.props.accountMaster.accountMaster.doneNewUserQuestions && <>
                        <div className="mar-r10 text-orange text-heavy">We must approve your account for you to use web forms</div>
                        <UILIB.Button
                            className="button-orange"
                            text="Request Approval"
                            onClick={this.activate.bind(this)}
                        />
                    </>}
                </div>
            </div >
        );
    }
}

export default FormConfirm
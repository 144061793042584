import React from 'react'

export default class IconsUpload extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 17C3 16.7348 3.10536 16.4805 3.29289 16.2929C3.48043 16.1054 3.73478 16 4 16H16C16.2652 16 16.5196 16.1054 16.7071 16.2929C16.8946 16.4805 17 16.7348 17 17C17 17.2652 16.8946 17.5196 16.7071 17.7071C16.5196 17.8947 16.2652 18 16 18H4C3.73478 18 3.48043 17.8947 3.29289 17.7071C3.10536 17.5196 3 17.2652 3 17ZM6.293 6.70703C6.10553 6.5195 6.00021 6.26519 6.00021 6.00003C6.00021 5.73487 6.10553 5.48056 6.293 5.29303L9.293 2.29303C9.48053 2.10556 9.73484 2.00024 10 2.00024C10.2652 2.00024 10.5195 2.10556 10.707 2.29303L13.707 5.29303C13.8892 5.48163 13.99 5.73424 13.9877 5.99643C13.9854 6.25863 13.8802 6.50944 13.6948 6.69485C13.5094 6.88026 13.2586 6.98543 12.9964 6.9877C12.7342 6.98998 12.4816 6.88919 12.293 6.70703L11 5.41403V13C11 13.2652 10.8946 13.5196 10.7071 13.7071C10.5196 13.8947 10.2652 14 10 14C9.73478 14 9.48043 13.8947 9.29289 13.7071C9.10536 13.5196 9 13.2652 9 13V5.41403L7.707 6.70703C7.51947 6.8945 7.26516 6.99982 7 6.99982C6.73484 6.99982 6.48053 6.8945 6.293 6.70703Z" fill={color} />
        </svg>





    }
}        
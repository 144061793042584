import React, { Component } from 'react';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import MoreOptions from '~/Common-Objects/Logos/moreOptions';
import SiteVars from '~/Classes/siteVars';
import i18n from '~/i18n'
import DateTimeFunctions from '../../../Classes/dateTimeFunctions'
import PermissionChecker from '~/Classes/permissions';

@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, permissionStore: store.permission }
})
class TemplateOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }


    render() {
        var template = this.props.template;
        return <ul>
            <li>
                <a onClick={() => this.props.goEditTemplate(template.id, template.templateType)}>{i18n.t('edit')}</a>
            </li>
            <li>
                <a onClick={() => this.props.sendAsCampaign(template.id)}>{i18n.t('sendACampaign')}</a>
            </li>
            <li>
                <a onClick={() => this.props.openEmailPreview(template.id)}>{i18n.t('templates:index.preview')}</a>
            </li>
            <li>
                <a onClick={() => this.props.openSpamTest(template.id)}>{i18n.t('templates:index.spamTest')}</a>
            </li>
            <li>
                <a onClick={() => this.props.exportHtml(template)}>{i18n.t('templates:index.exprtHtml')}</a>
            </li>
            {template.templateType == 0 && <li>
                <a onClick={() => this.props.convertToPlainText(template)}>Create Plain Text</a>
            </li>}
            <li>
                <a onClick={() => this.props.exportPdf(template)}>{i18n.t('templates:index.exprtpdf')}</a>
            </li>
            <li>
                <a onClick={() => this.props.goChangeGroup(template)}>{i18n.t('templates:index.changeGroup')}</a>
            </li>
            <li>
                <a onClick={() => this.props.inviteUsers(template)}>{i18n.t('templates:index.users')}</a>
            </li>
            <li>
                <a onClick={() => this.props.duplicate(template)}>{i18n.t('templates:index.duplicate')}</a>
            </li>
            <li>
                <a onClick={() => this.props.goDeleteTemplate(template.id)}>{i18n.t('delete')}</a>
            </li>
            <li>
                <a onClick={() => this.props.togglePublic(template)}>{template.public ? i18n.t('makePrivate') : i18n.t('makePublic')}</a>
            </li>
            <li>
                <a onClick={() => { this.props.share(template) }}>{i18n.t('share')}</a>
            </li>
        </ul>
    }
}

export default TemplateOptions;
import React from 'react'
import { Switch, Redirect } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react'
import queryStringParser from 'query-string';

import config from '~/Classes/siteVars.js';
//IMPORT PAGES
import CPTemplate from '~/pages/cp/template';
import FrontPage from '~/pages/cp/frontpage';
import Campaigns from '~/pages/cp/campaigns/campaigns';
import CampaignReports from '~/pages/cp/campaigns/reports';
import EmailCampaignsView from '~/pages/cp/campaigns/views/email';
import AdCampaignView from '~/pages/cp/campaigns/views/ads';
import SocialCampaignView from '~/pages/cp/campaigns/views/social';
import EmailCampaignsAddStandard from '~/pages/cp/campaigns/add/standard/standardCampaign'
import EmailCampaignsFinished from '~/pages/cp/campaigns/add/finishedAddingCampaign/finishedAddingCampaign'
import EmailCampaignsAddAB from '~/pages/cp/campaigns/add/ab'
import Subscribers from '~/pages/cp/subscribers';
import SubscriberGroup from '~/pages/cp/groups/group'
import AccountPage from '~/pages/cp/account/accountIndex'
import CompanyPage from '~/pages/cp/company/companyIndex'
import TemplatePage from '~/pages/cp/templates/templatesPage'
import TemplatePageAdd from '~/pages/cp/templates/add/templatesAdd'
import SubscriberView from '~/pages/cp/subscriber/viewSubscriber'
import Transactions from '~/pages/cp/transactions'
import Automations from '~/pages/cp/automation'
import CreateAutomation from '~/pages/cp/automation/add'
import ViewAutomation from '~/pages/cp/automation/view'
import ViewAutomationStats from '~/pages/cp/automation/view/stepStats'
import ViewAutomationCampaignStats from '~/pages/cp/automation/view/campaignStats'
import SiteTracker from '~/pages/cp/tracking'
import Forms from '~/pages/cp/forms'

import ConversationsPage from "~/pages/cp/conversations";

import SendOverView from '~/pages/cp/send'
import BrandAnalyzer from '~/pages/cp/brandanalyzer';

import Affiliate from '~/pages/cp/affiliate/affiliate'

import CapsuleSetup from './capsule'

import InteractChat from '~/pages/cp/interact/chat';

//import store data and user actions
import * as user from '../../data/actions/userActions'; //now we can use user actions here
import * as siteActions from '~/data/actions/siteActions'; //now we can use user actions here
import { updateTier } from '../../data/actions/accountActions'
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib.jsx';
import PermissionRouter from '../../Common-Objects/Router/permissionRoute'
import axios from '../../data/http/axios'
import jQuery from 'jquery';
import RouteWatcher from '../../Common-Objects/RouteWatcher';
import GoogleAnalytics from '../../Classes/googleAnalytics';

import ReleasesTemplate from '../cp/releases';
import SocialCampaignCreate from '../cp/campaigns/social/create';

import Labs from '../cp/labs';
import Rum from '../../Classes/rum';
@connect((store) => {
	return { siteMaster: store.siteMaster, user: store.user, accountMaster: store.accountMaster }
})

export default class MasterTemplateFP extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			processedCustomFooter: false
		}
		this.loadTier = this.loadTier.bind(this)
	}

	componentDidMount() {
		this.props.dispatch(user.loadUserRecord());
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.siteMaster && this.props.siteMaster) {
			if (prevProps.siteMaster.googleAnalyticsKey != this.props.siteMaster.googleAnalyticsKey) {
				try {
					GoogleAnalytics.trackGAPageView(this.props.history.location.pathname)
				}
				catch (err) { }
			}

			if (this.props.siteMaster.favicon && this.props.siteMaster.favicon.indexOf('rel="icon"') > -1 && !checkFavicon()) {
				document.head.insertAdjacentHTML('afterbegin', this.props.siteMaster.favicon);
			}
		}


		if (!prevProps.user.loggedIn && this.props.user.loggedIn) {
			Rum.addLabelsGlobal({
				AccountMasterId: this.props.accountMaster.id + "",
				AccountUserId: this.props.user.accountUserId
			})
			Rum.setUserContext(this.props.user.accountUserId)

			this.loadTier()

			const query = queryStringParser.parse(location.search);
			if (query.AccountMasterId && parseInt(query.AccountMasterId) !== this.props.accountMaster.id) {
				this.props.dispatch(user.changeAccounts(query.AccountMasterId, this.props.history));
			}

			if (query.AccountMasterId) {
				let path = '?'
				for (let prop in query) {
					if (prop !== 'AccountMasterId') path += `path=${query[path]}&`
				}
				var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + path
				window.history.pushState({ path: newurl }, '', newurl);
			}

			const host = window.location.host;
			switch (host) {
				case "localhost:8080":
				case "127.0.0.1:8080":
				case "localhost:8082":
				case "dev.web.mpzmail.com":
				case "test.web.mpzmail.com":
				case "web.capsule.mpztesting.com":
				case "web.staging.dcloud.mpztesting.com": {
					const res = await axios.get("/accountMaster/site");
					this.props.dispatch(siteActions.setSite(res.data));
					document.title = "DEV || " + res.data.siteName;
					const link = document.createElement("link");
					link.rel = "stylesheet";
					link.href = config.getConnString() + "/style/style.css?id=" + res.data.siteId;
					document.head.appendChild(link);
					setTimeout(() => {
						const current = document.getElementById("mpMainStyle");
						if (current) current.remove();
					}, 1000);

					// if (res.data.favicon) {
					//   processFavicon(res.data.favicon)
					// }
					break;
				}
				case "app.transpond.io":
					const link = document.createElement("link");
					link.rel = "stylesheet";
					link.href = config.getConnString() + "/style/style.css?id=1";
					document.head.appendChild(link);
					setTimeout(() => {
						const current = document.getElementById("mpMainStyle");
						if (current) current.remove();
					}, 1000);
					break;
			}
		}
		if (this.props.user.dataUpToDate && !this.props.user.loggedIn) {
			var queryString = window.location.search;
			var pushUrl;

			const query = queryStringParser.parse(location.search);
			if (query && query.AccountMasterId) {
				pushUrl = '/?AccountMasterId=' + query.AccountMasterId + '&redirect=' + encodeURI(window.location.pathname + window.location.search.replace(/AccountMasterId=([0-9]*)/, ''));
			} else {
				pushUrl = '/?redirect=' + encodeURI(window.location.pathname + window.location.search);
			}
			if (window.location.pathname && window.location.pathname.indexOf('oAuth/response') > 0) {
				var urlData = window.location.pathname.split("/");
				var thisAppId = urlData[urlData.length - 1]
				pushUrl = "/appConnect/?appid=" + thisAppId
				if (queryString && queryString.length > 1) {
					queryString = "&" + queryString.substring(1, queryString.length)
					pushUrl += queryString;
				}
			}
			this.props.history.push(pushUrl)
		}

		if (this.props.accountMaster && this.props.accountMaster.accountMaster && this.props.accountMaster.accountMaster.accountBillingTierId) {
			if (!prevProps.accountMaster || !prevProps.accountMaster.accountMaster) this.loadTier()
			else if (prevProps.accountMaster.id !== this.props.accountMaster.id) this.loadTier()
			else if (prevProps.accountMaster.accountMaster.accountBillingTierId !== this.props.accountMaster.accountMaster.accountBillingTierId) this.loadTier()
		}

		if (!this.state.processedCustomFooter && this.props.user && this.props.user.dataUpToDate) {
			if (this.props.siteMaster) {
				if (this.props.siteMaster.customFooter) {
					let footerFinal = this.props.siteMaster.customFooter;
					if (this.props.user && this.props.user.userData) {
						footerFinal = footerFinal.replace(/\[user_id\]/gi, this.props.user.accountUserId);
						footerFinal = footerFinal.replace(/\[user_email\]/gi, this.props.user.userData.emailAddress);
						footerFinal = footerFinal.replace(/\[user_createdat\]/gi, this.props.user.userData.createdAt);
						footerFinal = footerFinal.replace(/\[user_firstname\]/gi, this.props.user.userData.firstName);
						footerFinal = footerFinal.replace(/\[user_lastname\]/gi, this.props.user.userData.lastName);
						footerFinal = footerFinal.replace(/\[user_language\]/gi, this.props.user.userData.language);
						footerFinal = footerFinal.replace(/\[user_fullname\]/gi, this.props.user.userData.fullname);
						footerFinal = footerFinal.replace(/\[user_timezone\]/gi, this.props.user.userData.timeZone);
					}
					if (this.props.accountMaster && this.props.accountMaster.accountMaster) {
						footerFinal = footerFinal.replace(/\[account_id\]/gi, this.props.accountMaster.id);
						footerFinal = footerFinal.replace(/\[account_name\]/gi, this.props.accountMaster.accountMaster.accountName);
						footerFinal = footerFinal.replace(/\[account_billingid\]/gi, this.props.accountMaster.accountMaster.AccountBillingId);
						footerFinal = footerFinal.replace(/\[account_billingname\]/gi, this.props.accountMaster.accountMaster.billingName);
						footerFinal = footerFinal.replace(/\[account_address1\]/gi, this.props.accountMaster.accountMaster.address1);
						footerFinal = footerFinal.replace(/\[account_address2\]/gi, this.props.accountMaster.accountMaster.address2);
						footerFinal = footerFinal.replace(/\[account_address3\]/gi, this.props.accountMaster.accountMaster.address3);
						footerFinal = footerFinal.replace(/\[account_county\]/gi, this.props.accountMaster.accountMaster.county);
						footerFinal = footerFinal.replace(/\[account_country\]/gi, this.props.accountMaster.accountMaster.country);
						footerFinal = footerFinal.replace(/\[account_postcode\]/gi, this.props.accountMaster.accountMaster.postCode);
					}
					try {
						const el = document.getElementById('footerHolder')
						if (el) {
							jQuery("#footerHolder").html(footerFinal);
							this.setState({ processedCustomFooter: true })
						}
					}
					catch (err) {
						console.log(err)
						this.setState({ processedCustomFooter: true })
					}
				}

				// if (this.props.siteMaster.favicon) {
				//   try {
				//     console.log("HD")
				//     jQuery('head').append(this.props.siteMaster.favicon);
				//   }
				//   catch (err) {
				//     console.log(err)
				//   }
				// }
			}
		}
	}

	loadTier() {
		axios.get('/accountMaster/tier').then(res => {
			this.props.dispatch(updateTier(res.data.Tier));
		}).catch(() => { })
	}


	render() {
		if (this.props.user.dataUpToDate) {

			const query = queryStringParser.parse(location.search);
			if (query.AccountMasterId && parseInt(query.AccountMasterId) !== this.props.accountMaster.id) {
				return <UILIB.LoadingOverlay fullscreen={true} />
			}


			return <CPTemplate history={this.props.history} >

				<RouteWatcher />

				<Switch>
					<ApmRoute exact path="/cp/home" component={FrontPage} />

					<PermissionRouter permissionName="campaigns" method="read" path="/cp/send" component={SendOverView} />
					<PermissionRouter permissionName="reporting" method="read" exact path="/cp/campaigns/reports" component={CampaignReports} />
					<PermissionRouter permissionName="campaigns" method="read" path="/cp/campaigns/view/:campaignId" component={EmailCampaignsView} />
					<PermissionRouter permissionName="campaigns" method="read" path="/cp/campaigns/viewad/:campaignId" component={AdCampaignView} />
					<PermissionRouter permissionName="campaigns" method="read" path="/cp/campaigns/viewsocial/:campaignId/:view?" component={SocialCampaignView} />
					<PermissionRouter permissionName="campaigns" method="write" path="/cp/campaigns/add/standard/:campaignId?/:step?" component={EmailCampaignsAddStandard} />
					<PermissionRouter permissionName="campaigns" method="write" path="/cp/campaigns/add/social/:campaignId?/:step?" component={SocialCampaignCreate} />
					<PermissionRouter permissionName="campaigns" method="write" path="/cp/campaigns/add/sms/:campaignId?/:step?" component={EmailCampaignsAddStandard} />
					<PermissionRouter permissionName="campaigns" method="write" path="/cp/campaigns/add/finished/:campaignId?" component={EmailCampaignsFinished} />
					<PermissionRouter permissionName="campaigns" method="write" path="/cp/campaigns/add/ab/:campaignId?/:step?" component={EmailCampaignsAddAB} />
					<PermissionRouter permissionName="campaigns" method="read" path="/cp/campaigns/:type?" component={Campaigns} />

					<PermissionRouter permissionName="subscribers" method="read" path="/cp/groups/:groupID" component={SubscriberGroup} />
					<PermissionRouter permissionName="subscribers" method="read" path="/cp/subscribers" component={Subscribers} />
					<PermissionRouter permissionName="subscribers" method="read" exact path="/cp/subscriber/:subscriberID" component={SubscriberView} />
					<PermissionRouter permissionName="templates" method="read" exact path="/cp/templates" component={TemplatePage} />
					<PermissionRouter permissionName="templates" method="read" exact path="/cp/templates/add/:editorTypeId?/:defaultThemeGroupId?" component={TemplatePageAdd} />
					<PermissionRouter permissionName="automations" method="read" exact path="/cp/automation" component={Automations} />
					<PermissionRouter permissionName="automations" method="read" exact path="/cp/automation/add/:automationId?" component={CreateAutomation} />
					<PermissionRouter permissionName="automations" method="read" exact path="/cp/automation/view/:automationId" component={ViewAutomation} />
					<PermissionRouter permissionName="automations" method="read" exact path="/cp/automation/view/:automationId/stats/$stepId" component={ViewAutomationStats} />
					<PermissionRouter permissionName="automations" method="read" exact path="/cp/automation/view/:automationId/campaignStats/$stepId/:statType" component={ViewAutomationCampaignStats} />

					<PermissionRouter permissionName="company" method="write" exact path="/cp/labs" component={Labs} />

					<PermissionRouter permissionName="subscribers" method="read" exact path="/cp/conversations" component={ConversationsPage} />

					<ApmRoute path="/cp/brandanalyzer/" component={BrandAnalyzer} />
					<ApmRoute path="/cp/affiliate/" component={Affiliate} />
					<ApmRoute path="/cp/capsule" component={CapsuleSetup} />
					<ApmRoute path="/cp/account/" component={AccountPage} />
					<PermissionRouter permissionName="company" method="read" path="/cp/company/" component={CompanyPage} />
					<PermissionRouter permissionName="campaigns" method="read" path="/cp/transactions" component={Transactions} />
					<PermissionRouter permissionName="campaigns" method="read" path="/cp/trackers" component={SiteTracker} />

					<PermissionRouter exact permissionName="forms" method="read" path="/cp/forms" component={Forms} />
					<ApmRoute exact path="/cp/support" component={Subscribers} />
					<ApmRoute path="/cp/releases/:id?" component={ReleasesTemplate} />

					<Redirect to="/cp/home/" push />
				</Switch >
			</CPTemplate >
		}
		else {
			return <UILIB.LoadingOverlay fullscreen={true} />
		}
	};
};


function checkFavicon() {
	var favicon = undefined;
	var nodeList = document.getElementsByTagName("link");
	for (var i = 0; i < nodeList.length; i++) {
		if ((nodeList[i].getAttribute("rel") == "icon") || (nodeList[i].getAttribute("rel") == "shortcut icon")) {
			favicon = nodeList[i].getAttribute("href");
		}
	}
	return !!favicon;
}
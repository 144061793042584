import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import _uniqueId from "lodash/uniqueId";

export default function WizardStep({
  step,
  heading,
  headingTag = "h2",
  completed,
  collapsed,
  className,
  children,
  onClick,
  ...rest
}) {
  const collapsableRef = useRef();
  const [maxHeight, setMaxHeight] = useState(collapsed ? 0 : null);
  const uncollapsedHeight = collapsableRef.current?.offsetHeight;
  const highlighted = !completed && !collapsed;

  // Doesn't matter if we are collapsing or un-collapsing, we always want to set the maxHeight to the height of the content first.
  // This enables us to transition from an unknown height, as first we set the height and then transition to 0.
  useEffect(() => {
    setMaxHeight(uncollapsedHeight);
  }, [collapsed]);

  // When the maxHeight changes (which is every time the "collapsed" prop changes) set the maxHeight to 0 if it should be collapsed.
  // This is a bit complex to get your head around, but it allows us to first set the height of the unknown content (say 240px) and
  // then following that we can then set it to 0 which will allow us to transition between heights.
  useEffect(() => {
    if (collapsed) setMaxHeight(0);
  }, [maxHeight]);

  function handleRootClick(e) {
    if (!completed) return;

    // If a step has been completed then we want to allow users to click on it to reopen/close it again.
    // Once it has been reopened, we want to make sure that if they click on an interactive element such as an input then we don't close it.
    const clickableElements = ["INPUT", "BUTTON", "A"];
    if (!clickableElements.includes(e.target.nodeName)) {
      onClick();
    }
  }

  function handleTransitionEnd(e) {
    if (e.propertyName === "max-height") {
      if (!collapsed) {
        setMaxHeight(null);
      }
    }
  }

  const HeadingTag = headingTag;

  const classes = classnames("wizard-step", className, {
    "wizard-step--completed": completed,
    "wizard-step--collapsed": collapsed,
    "wizard-step--highlighted": highlighted,
  });

  const ariaId1 = _uniqueId();
  const ariaId2 = _uniqueId();

  return (
    <div
      id={ariaId1}
      className={classes}
      aria-controls={ariaId2}
      aria-expanded={!collapsed}
      role={completed ? "button" : undefined}
      // We only want to make it tabbable once the "edit" button has been removed
      tabIndex={completed && !collapsed ? "0" : undefined}
      onClick={onClick ? handleRootClick : undefined}
      {...rest}
    >
      <div className="wizard-step__body">
        {step && (
          <div className="wizard-step__step">
            {completed ? <UILIB.Icons icon="tick" color="white" /> : step}
          </div>
        )}

        <div className="wizard-step__content">
          <div className="wizard-step__header mar-b5">
            <HeadingTag className="h4">{heading}</HeadingTag>

            {onClick && <UILIB.Button
              className="wizard-step__edit-btn button-small"
              aria-controls={ariaId2}
              onClick={onClick}
            >
              Edit
            </UILIB.Button>}
          </div>

          <div
            id={ariaId2}
            className="wizard-step__children"
            role="region"
            aria-labelledby={ariaId1}
            style={{ maxHeight }}
            onTransitionEnd={handleTransitionEnd}
          >
            {!collapsed && <div ref={collapsableRef}>
              {children}
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';
import { DropTarget, DragSource } from 'react-dnd'
import ItemTypes from '../../../dragDropItemTypes'
import { findDOMNode } from 'react-dom'
import get from 'lodash/get'


const dropSource = {
    drop(props, monitor, component) {
    },
    hover(props, monitor, component) {
        const currentElementType = monitor.getItemType();

        if (monitor.isOver({ shallow: true }) === false) {
            return;
        }
        if (currentElementType === "newItem" && monitor.getItem().isNew === 0) {
            //do we have other items in this container
            if (props && props.elementData) {
                var columnHasItems = JSON.stringify(props.elementData).split(/itemHolder/g).length - 1;
                if (columnHasItems) return;
            }
            const currentElementPath = monitor.getItem().path;
            const currentElementContent = monitor.getItem().content;
            const hoveredElementPath = props.path;
            if (!currentElementContent.tuuid) {
                currentElementContent.tuuid = DragDropFunctions.createGUID();
            }
            monitor.getItem().path = hoveredElementPath + ".elements[0]";
            monitor.getItem().isNew = 1;
            props.insertElement(hoveredElementPath, currentElementContent, true);
            console.log("Return Column")
            return;
        }
        var thisPathData = get(props.elementsData, props.path);
        if (((currentElementType === "canvasItem") || (currentElementType === 'newItem' && monitor.getItem().isNew === 1)) && thisPathData.elements.length === 0) {
            var currentElementPath = monitor.getItem().path;
            const hoveredElementPath = props.path;
            const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (currentElementPath === hoveredElementPath) {
                return;
            }

            // // Dragging downwards
            // if (hoverClientY < hoverMiddleY) {
            //     return
            // }

            // // Dragging upwards
            // if (hoverClientY > hoverMiddleY) {
            //     return
            // }
            props.moveElement(currentElementPath, hoveredElementPath, true);
            monitor.getItem().path = hoveredElementPath + ".elements[0]";

        }
    }
}


@DropTarget([ItemTypes.CANVASITEM, ItemTypes.NEWITEM], dropSource, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget()
}))

export default class TdCanvasColumn extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { canDrop, isOver, connectDropTarget } = this.props
        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "td", this.props.localVariables)
        }

        if (this.props.children.length > 0) {
            return connectDropTarget(
                <div {...eleProps}>
                    {this.props.children}
                </div>
            )
        }
        else {
            return connectDropTarget(
                <div {...eleProps}>
                    <div style={{ fontSize: "14px", opacity: "0.3" }}>Drag and Drop Elements from the left, here</div>
                </div>
            )
        }

    }
}



import React from 'react'

export default function IconCaptcha({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";

    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.94776 1.30927C8.90951 1.31671 8.87122 1.32444 8.83288 1.33248L1.899 2.00434L3.77086 3.70457C2.64201 4.94737 1.3219 7.28582 1.36428 9.99999H5.70179C5.70819 8.97844 6.08124 7.85835 7.09219 6.74086L7.10346 6.73162L8.9506 8.4094L8.95468 1.32068L8.94776 1.32135V1.30927ZM9.94776 5.51749C11.0768 5.41774 12.3511 5.74473 13.6226 6.89694L11.9455 8.74761L19.0343 8.74347L18.3424 1.68859L16.6444 3.56242C15.3398 2.3802 12.8302 0.989079 9.94776 1.17961L9.94776 5.51749ZM3.43899 11L8.57377 10.9952L6.89735 12.8464C9.86233 15.5314 12.841 13.7318 13.9598 12.4964L16.9247 15.1813C11.8233 20.8148 6.10053 18.1957 3.8768 16.182L2.1794 18.0565L1.53852 11.5451C1.49966 11.3623 1.46769 11.1805 1.44219 11H3.43899Z" fill={fillColor} />
        </svg>
    )
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import AddUser from './invite'
import DeleteUser from './remove'
import i18n from '~/i18n'

// import AddStaffPop from './addStaffPop.jsx';
// import DeleteStaffPop from './deleteStaffPop.jsx';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class CampaignStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertBar: null,
            pageLoaded: false,
            myAccessLevel: '',
            accountUsersData: [],
            users: [],
            group: undefined,
            alertOpen: false,
            alertMessage: ''
        };
        this.loadStaff = this.loadStaff.bind(this);
        this.loadUsers = this.loadUsers.bind(this);
        this.addUser = this.addUser.bind(this);
        this.userSelected = this.userSelected.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.userDeleted = this.userDeleted.bind(this);
        this.updateBind = this.updateBind.bind(this);
    }

    componentDidMount() {
        this.loadStaff()
    }

    //Load Staff stuff
    loadStaff() {

        axios.get('/group/' + this.props.match.params.groupID).then(res => {
            this.setState({
                group: res.data.Group
            })
        })

        axios.get('/group/' + this.props.match.params.groupID + '/user')
            .then((usersData) => {
                var me = usersData.data.AccountUsers.find(au => au.AccountUserId == this.props.user.userID);
                this.setState({
                    accountUsersData: usersData.data.AccountUsers,
                    myAccessLevel: me ? me.accessLevel : undefined
                }, this.loadUsers);
            })
            .catch((err) => {
                console.log(err)
            });
    }

    loadUsers() {
        axios.get('/accountUser')
            .then(response => {
                var users = response.data.AccountUsers.filter(u => {
                    return !this.state.accountUsersData.find(user => user.AccountUserId == u.id)
                })
                this.setState({
                    users: users,
                    pageLoaded: true
                })
            })
            .catch((err) => {
                console.log(err)
            });
    }

    addUser() {
        var drawerContent = <AddUser users={this.state.users} add={this.userSelected} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, null, true));
    }

    userSelected(userId, accessLevel) {
        if (userId && accessLevel) {
            axios.post('/group/' + this.props.match.params.groupID + '/user', {
                AccountUserId: userId,
                accessLevel: accessLevel
            }).then(() => {
                this.setState({ alertOpen: true, alertMessage: i18n.t('subscribers:users.alertAdded') });
                this.loadStaff()
            }).catch(() => { })
        }
    }

    deleteUser(userId) {
        var drawerContent = <DeleteUser userId={userId} confirm={this.userDeleted} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, null, true));
    }

    userDeleted(userId) {
        var users = this.state.accountUsersData;
        var userIndex = users.findIndex(u => u.AccountUserId == userId);
        axios.delete('/group/' + this.props.match.params.groupID + '/user/' + userId).then(() => {
            this.setState({ alertMessage: i18n.t('subscribers:users.alertRemoved'), alertOpen: true });
            this.loadStaff()
        })
    }

    updateBind(userId, level) {
        var users = this.state.accountUsersData;
        var user = users.find(u => u.AccountUserId == userId);
        user.accessLevel = level;
        axios.put('/group/' + this.props.match.params.groupID + '/user/' + userId, {
            accessLevel: level
        }).then(() => {
            this.setState({
                accountUsersData: users,
                alertMessage: i18n.t('subscribers:users.alertUpdated'),
                alertOpen: true
            })
        })
    }

    render() {


        if (this.state.pageLoaded === false || !this.state.group) {
            return <UILIB.LoadingIcons />
        }
        else {
            return <div>

                <div className="end-xs">
                    <UILIB.Button className="button-primary" iconLeft={<UILIB.Icons icon="plus" />} onClick={this.addUser}>{i18n.t('subscribers:users.addButton')}</UILIB.Button>
                </div>
                <UILIB.Row className="text-center">

                    {this.state.accountUsersData.map((theUser, index) => {
                        var readOnly = false;
                        if (theUser.id == -1) readOnly = true;
                        return <UILIB.Column xs={12} sm={6} md={6} lg={3} key={index}>
                            <UILIB.BindCard CreatedById={this.state.group.CreatedById} userData={theUser.AccountUser} readOnly={readOnly} myLevel={this.state.myAccessLevel} bind={theUser} level={theUser.accessLevel} user={this.props.user} update={this.updateBind} onDelete={this.deleteUser} />
                        </UILIB.Column>

                    })}
                </UILIB.Row>

                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
            </div >
        }
    };


};



import React from "react";

export default function IconChat({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.505 2.36476C5.32653 2.12117 7.16226 1.99924 9 1.99976C10.863 1.99976 12.697 2.12376 14.495 2.36476C15.742 2.53176 16.675 3.47276 16.93 4.63276C16.7394 4.59721 16.5466 4.57416 16.353 4.56376C14.7855 4.47814 13.2145 4.47814 11.647 4.56376C9.229 4.69576 7.5 6.72676 7.5 8.99776V11.2378C7.5 12.6508 8.17 13.9728 9.26 14.7998L6.28 17.7798C6.17511 17.8845 6.04154 17.9558 5.89614 17.9847C5.75074 18.0136 5.60004 17.9988 5.46308 17.942C5.32611 17.8853 5.20903 17.7893 5.12661 17.6661C5.04419 17.5429 5.00013 17.398 5 17.2498V13.8068C4.499 13.7588 4 13.7008 3.505 13.6348C2.033 13.4378 1 12.1618 1 10.7198V5.27976C1 3.83876 2.033 2.56176 3.505 2.36476Z"
        fill={color}
      />
      <path
        d="M14 6C13.238 6 12.48 6.02 11.729 6.062C10.157 6.148 9 7.472 9 8.998V11.238C9 12.757 10.147 14.077 11.71 14.173C11.924 14.186 12.138 14.197 12.352 14.207C12.552 14.216 12.737 14.297 12.87 14.431L15.22 16.781C15.3249 16.8858 15.4586 16.9572 15.6041 16.986C15.7496 17.0149 15.9004 16.9999 16.0374 16.9431C16.1744 16.8863 16.2915 16.7901 16.3738 16.6667C16.4562 16.5433 16.5001 16.3983 16.5 16.25V14.18C17.953 13.985 19 12.717 19 11.265V8.998C19 7.472 17.843 6.148 16.271 6.062C15.5147 6.02068 14.7574 6.00001 14 6Z"
        fill={color}
      />
    </svg>
  );
}

import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import InitialSetup from './initial';
import Disconnect from './disconnect';
import ApplicationTransactionsGraph from '../components/applicationTransactionsGraph'
import AddApp from '../../addApp/'
import DrawerAddCampaignStep1 from '~/pages/cp/campaigns/add/drawerAddCampaignStep1';
import AutomationThemeSelector from '~/pages/cp/includes/automations/automationThemeSelector'
import Actions from './actions'
import QuickLinks from './quickLinks';

@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})
class GenericAppConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: true,
            appId: 0,
            appData: {},
            appBinding: {},
            groups: [],
            importing: false,
            importProgress: 0,
            importId: undefined,
            connectedSiteName: "",
            refresh: 0
        };

        this.unloading = false

        this.getApp = this.getApp.bind(this);
        this.getAppBinding = this.getAppBinding.bind(this);
        this.popDisconnect = this.popDisconnect.bind(this);
        this.getGroups = this.getGroups.bind(this)
        this.setup = this.setup.bind(this)
        this.checkForImportUpdate = this.checkForImportUpdate.bind(this)
        this.performSync = this.performSync.bind(this)
        this.getAvailableFields = this.getAvailableFields.bind(this)
        this.reAddApp = this.reAddApp.bind(this);
        this.addNewCampaign = this.addNewCampaign.bind(this);
    }

    componentDidMount() {
        if (!this.props.match.params.appId) {
            this.props.history.push('/cp/company/integrations/')
            return;
        }
        this.setState({ appId: this.props.match.params.appId }, this.getApp);
    }

    componentWillUnmount() {
        this.unloading = true
    }


    getApp(response) {
        var self = this;
        this.setState({
            loading: true
        })
        console.log(response)
        if (response && response.data) {
            if (Array.isArray(response.data)) {
                this.setState({
                    importing: true,
                    importIds: response.data
                }, this.checkForImportUpdate)
            } else {
                this.setState({
                    importing: true,
                    importId: response.data.id
                }, this.checkForImportUpdate)
            }
        }
        axios.get("/application/" + this.state.appId).then(response => {
            self.setState({
                appData: response.data.Application
            }, self.getAppBinding)
        }).catch(() => {
            self.props.history.push('/cp/company/integrations/')
        })
    }

    getAppBinding() {
        axios.get("/application/account/app/" + this.state.appData.id).then(response => {
            var groupId = -1;
            if (response.data.Application.appSettings && response.data.Application.appSettings.groupId) groupId = response.data.Application.appSettings.groupId;

            this.setState({
                appBinding: response.data.Application,
                groupId: groupId
            }, this.getConnectedSite)
        })
    }

    getConnectedSite() {
        axios.get(`/application/${this.state.appData.shortName}/site`).then(response => {
            this.setState({
                connectedSiteName: response.data.companyName,
                error: response.data.error
            }, this.getGroups)
        }).catch(err => {
            this.reAddApp();
        })
    }

    reAddApp() {
        var drawer = <AddApp history={this.props.history} reconnect={true} appId={this.state.appData.id} browse={url => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, false));
            if (url.indexOf('http') === 0) {
                window.open(url, '_self')
            } else {
                this.props.history.push(url)
            }
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, false, '100vw', 'full'));
    }

    getGroups() {
        axios.get('/group').then(response => {
            this.setState({
                groups: response.data.Groups,
                loading: false
            })
        })
    }

    getAvailableFields() {
        if (this.state.appData.options.fixedFields) return
        axios.get(`/application/${this.state.appData.shortName}/availablefields`).then(response => {
            this.setState({
                availableFields: response.data,
                ready: true
            })
        })
    }

    checkForImportUpdate() {
        if (this.unloading) return;
        var self = this;
        var groupId = this.state.appBinding.appSettings.groupId;
        var importId = this.state.importId
        if (this.state.importIds && this.state.importIds.length) {
            groupId = this.state.importIds[this.state.importIds.length - 1].GroupId
            importId = this.state.importIds[this.state.importIds.length - 1].id
        }
        if (!groupId || !importId) {
            return this.setState({
                importing: false
            })
        }
        axios.get('/group/' + groupId + '/import/' + importId).then(res => {
            self.setState({ importProgress: res.data.PercentComplete })
            if (res.data.PercentComplete < 100) {
                this.theTimer = setTimeout(this.checkForImportUpdate, 1000);
            }
            else {
                self.setState({ importing: false, importProgress: 100, refresh: this.state.refresh + 1 }, this.getAppBinding)
            }
        })
    }

    popDisconnect() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <Disconnect appName={this.state.appData.appName} goDisconnect={() => {
            axios.delete('/accountMaster/application/' + this.state.appBinding.id).then(res => {
                this.props.history.push('/cp/company/integrations')
            })
        }} />, true, "500px"));
    }

    setup() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <InitialSetup drawer={true} app={this.state.appData} appBinding={this.state.appBinding} finish={response => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false))
            this.getApp(response);
        }} />, true, "100vw"));
    }

    performSync() {
        axios.get(`/application/${this.state.appData.shortName}/setup?resync=true`).then(response => {
            if (Array.isArray(response.data) && response.data.length) {
                this.setState({
                    importing: true,
                    importIds: response.data
                }, this.checkForImportUpdate)
            } else {
                this.setState({
                    importing: true,
                    importId: response.data.id
                }, this.checkForImportUpdate)
            }
        })
    }

    addNewCampaign() {
        var drawerContent = <DrawerAddCampaignStep1 history={this.props.history} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, false, "left", drawerContent, true, 500, "drawer_nopad"));
    }

    addNewAutomaton() {
        const drawer = <AutomationThemeSelector scratch={() => this.props.history.push('/cp/automation/addnew')} select={theme => {
            axios.post('/automation/theme/' + (theme.id || theme)).then(response => {
                this.props.history.push('/cp/automation/addnew/' + response.data.Automation.id)
            })
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawer, true, '100vw', 'full'));
    }

    render() {

        if (this.state.loading) return <div>
            <UILIB.LoadingIcons iconType="2" className="mar-b15" />
            <div className="text-center">Loading the App</div>
        </div>
        var currentGroup = this.state.groups.find(g => g.id == this.state.appBinding.appSettings.groupId)
        var doneInitial = !!this.state.appBinding.appSettings.doneInitial
        if (currentGroup) doneInitial = true
        if (!doneInitial && !this.state.appData.options.skipSync) return <InitialSetup app={this.state.appData} appBinding={this.state.appBinding} finish={this.getApp} />

        var multipleGroups = this.state.appData.options.multipleGroups || []


        return <div>
            <UILIB.Row className="middle-xs">
                <UILIB.Column xs={12} sm={12} md={6} lg={6} className="mar-b25">
                    <div className="quickFlex">
                        <div className="integration-avatar rounded" style={{ backgroundImage: "url(" + this.state.appData.appLogoUri + ")" }}></div>
                        <p className="no-marg pad-left"><strong>{this.state.appData.appName}.</strong> {this.state.connectedSiteName ? "Connected to " + this.state.connectedSiteName : ''} </p>
                    </div>

                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} lg={6} className="end-xs mar-b25">
                    <p className="no-marg">
                        <UILIB.Button className="button-primary mar-r10" iconRight={<UILIB.Icons icon="cog" />} onClick={this.setup}>Settings</UILIB.Button>
                        <UILIB.Button className="button-red" onClick={this.popDisconnect}>Disconnect</UILIB.Button>
                    </p>
                </UILIB.Column>
                {!!this.state.error && <UILIB.Column xs={12} sm={12} md={12} lg={12} >
                    <div className="flexSpaceBetween verticalAlign">
                        <div className="text-red text-heavy">{this.state.error}</div>
                        <UILIB.Button className="red outline button-sml" text="Reconnect" onClick={this.reAddApp} />
                    </div>
                </UILIB.Column>}
            </UILIB.Row>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={12} lg={12} className="mar-b25" >
                    <UILIB.Paper className="mar-b0">
                        <h4 className="mar-b0 mar-r10" style={{ display: "inline-block" }}>You're Connected!</h4>
                        <span>Now use the tools below to start marketing...</span>
                    </UILIB.Paper>
                </UILIB.Column>
                {!this.state.appData.options.skipSync && <UILIB.Column xs={12} sm={12} md={6} lg={6} className="mar-b25">
                    {!multipleGroups.length && <UILIB.Paper className="height-100 mar-b0 ">
                        <UILIB.Header leftIcon={<UILIB.Icons icon="avatars3" circle={true} />}>Contacts</UILIB.Header>
                        {/* <h4 className="text-primary"><span className="icon-users2 " /> Contacts</h4> */}
                        <p>{this.state.appData.appName} is currently connected and is synching contacts into the following group. If you add, update or delete a contact on {this.state.appData.appName} it is automatically updated here.</p>
                        <UILIB.Button text={currentGroup.groupName} iconRight={<UILIB.Icons icon="arrowRight" />} className="button-primary" onClick={() => this.props.history.push('/cp/groups/' + this.state.groupId)} />
                    </UILIB.Paper>}
                    {!!multipleGroups.length && <UILIB.Paper className="height-100 mar-b0 ">
                        <UILIB.Header leftIcon={<UILIB.Icons icon="avatars3" circle={true} />}>{multipleGroups.map(g => g.label).join(' and ')}</UILIB.Header>
                        <p>{this.state.appData.appName} is currently connected and is synching {multipleGroups.map(g => g.label).join(' and ')} into the following groups. If you add, update or delete a contact on {this.state.appData.appName} it is automatically updated here.</p>
                        <div className="quickFlex">
                            {multipleGroups.map(group => {
                                let foundGroup
                                if (this.state.appBinding.appSettings[group.value + 'GroupId']) {
                                    foundGroup = this.state.groups.find(g => g.id == this.state.appBinding.appSettings[group.value + 'GroupId'])
                                }
                                if (foundGroup) {
                                    return <UILIB.Button iconRight={<UILIB.Icons icon="arrowRight" />} text={group.label + ' to ' + foundGroup.groupName} className="button-primary mar-r5" onClick={() => this.props.history.push(`/cp/groups/${foundGroup.id}`)} />
                                } else {
                                    return <UILIB.Button text={group.label + ' Not Synced'} disabled={true} className="button-primary mar-r5" />
                                }

                            })}
                        </div>
                    </UILIB.Paper>}
                </UILIB.Column>}
                {!this.state.appData.options.skipSync && <UILIB.Column xs={12} sm={12} md={6} lg={6} className="mar-b25">
                    <UILIB.Paper className="height-100 mar-b0">
                        <UILIB.Header leftIcon={<UILIB.Icons icon="chart" circle={true} />}>Sync History</UILIB.Header>
                        <ApplicationTransactionsGraph applicationId={this.state.appData.id} refresh={this.state.refresh} />
                        <div className="no-marg text-right">
                            {!this.state.importing && <UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon="undo" />} text="Re-Sync" onClick={this.performSync} />}
                            {this.state.importing && <div style={{ textAlign: "center" }}>
                                {!!this.state.importProgress
                                    ? <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.importProgress} val2={100} />
                                    : <UILIB.LoadingLine />
                                }</div>}
                        </div>
                    </UILIB.Paper>
                </UILIB.Column>}
                {!!this.state.appData.options.hooks && !!this.state.appData.options.hooks.length && <UILIB.Column xs={12} sm={12} md={12} lg={12}>
                    <Actions appData={this.state.appData} appBinding={this.state.appBinding} history={this.props.history} setup={this.setup} />
                </UILIB.Column>}
            </UILIB.Row>
            <UILIB.Row className="mar-b60">
                <UILIB.Column xs={12} sm={12} md={12} lg={12}>
                    <QuickLinks appData={this.state.appData} groupId={this.state.groupId} />
                </UILIB.Column>
            </UILIB.Row>
        </div>
    };
}

export default GenericAppConfig;
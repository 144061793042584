import React from 'react';

export default class OneColumn extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var styles = {
            cls1: {
                fill: 'rgba(0,0,0,0.05)'
            },
            cls2: {
                fill: '#fff'
            },
            cls3: {
                fill: 'rgba(0,0,0,0.2)'
            },
            cls4: {
                fill: 'rgba(0,0,0,0.1)'
            },
            cls5: {
                filter: 'url(#Rectangle_158)'
            }
        }
        return <svg id="Group_266" data-name="Group 266" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="svg-holder" viewBox="16868 6582 253 257">
            <defs>
                <filter id="Rectangle_158" x="16879" y="6588" width="230" height="251" filterUnits="userSpaceOnUse">
                    <feOffset dy="-5" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.039" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <rect id="Rectangle_157" data-name="Rectangle 157" className="outer" width="253" height="253" rx="3" transform="translate(16868 6582)" />
            <g style={styles.cls5} transform="matrix(1, 0, 0, 1, 0, 0)">
                <path id="Rectangle_158-2" data-name="Rectangle 158" style={styles.cls2} d="M3,0H209a3,3,0,0,1,3,3V233a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(16888 6602)" />
            </g>

        </svg>

    }
}
import React from "react";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";
import classNames from "classnames";

export default function SettingsLayout({
  loading,
  heading,
  subheading,
  backButton,
  sideNavList,
  breadcrumbs,
  children,
  rightContent,
  hideHeader,
  view,
  hideLeftNav
}) {
  return (
    <div className={styles.root}>

      {!hideLeftNav && <nav className={styles.sideNav}>
        <div className={styles.backButton}>{backButton}</div>

        <div className={styles.sideNavListWrapper}>{sideNavList}</div>
      </nav>}
      {hideLeftNav && backButton && <div className={styles.backButton} style={{ position: "absolute", left: 24, top: 24 }}>{backButton}</div>}

      <main className={styles.main} >
        {loading ? (
          <UILIB.LoadingIcons />
        ) : (
          <div className="row">
            <div className="col-xs-12">
              {breadcrumbs}
            </div>
            {children}
          </div>
        )}
      </main>
    </div>
  );
}

import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd';
import * as siteMaster from '~/data/actions/siteActions';

import Server from './server';
import ServerHolder from './serverHolder';
import Domain from './domain';
import DomainHolder from './domainHolder'
import UnassignedHolder from './unassigned';

import SaveChangesDrawer from './saveChangesDrawer';

@connect((store) => {
    return { user: store.user, permissionStore: store.permission }
})

@DragDropContext(HTML5Backend)

class DeliveryManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoaded: false,
            loadingPct: 0,
            loadingText: "Finding your servers...",
            mailServers: [],
            groups: [],
            domains: [],
            exitting: false,
            domainStats: [],
            randomNo: 0
        }

        this.loadServers = this.loadServers.bind(this);
        this.loadDomains = this.loadDomains.bind(this);
        this.loadGroups = this.loadGroups.bind(this);
        this.getDomainStats = this.getDomainStats.bind(this);

        this.serverChangeGroup = this.serverChangeGroup.bind(this);
        this.domainChangeGroup = this.domainChangeGroup.bind(this);
        this.toggleActive = this.toggleActive.bind(this);

        this.saveChanges = this.saveChanges.bind(this);
        this.goSaveChanges = this.goSaveChanges.bind(this);

        this.updateState = this.updateState.bind(this);
        this.toggleTriggerType = this.toggleTriggerType.bind(this);
        this.toggleTriggerValue = this.toggleTriggerValue.bind(this);

    }

    componentDidMount() {
        this.loadServers();
    }

    loadServers() {
        axios.get('/accountMaster/mailservers?dedicated=true').then(response => {
            this.setState({
                mailServers: response.data.MailServers,
                loadingPct: 50,
                loadingText: "Grabbing your Domains"
            }, this.loadDomains)
        })
    }

    loadDomains() {
        axios.get('/accountMaster/domain').then(response => {
            this.setState({
                domains: response.data.domains,
                loadingPct: 90,
                loadingText: "Diving into the Pools"
            }, this.loadGroups)
        })
    }
    loadGroups() {
        axios.get('/mailServer/group').then(_gDat => {
            var combined = _gDat.data.Groups.map(g => {
                var servers = this.state.mailServers.filter(server => g.mailServers && g.mailServers.indexOf(server.id) > -1)
                var domains = this.state.domains.filter(domain => g.domains && g.domains.indexOf(domain.id) > -1)
                g.serversTmp = servers;
                g.domainsTmp = domains;
                return g
            })

            var serversTmp = this.state.mailServers.filter((ms) => {
                if (!_gDat.data.Groups.find(g => g.mailServers && g.mailServers.indexOf(ms.id) > -1)) {
                    return true
                }
                return false
            })
            var domainsTmp = this.state.domains.filter((dm) => {
                if (!_gDat.data.Groups.find(g => g.domains && g.domains.indexOf(dm.id) > -1)) {
                    return true
                }
                return false
            })

            combined.push({
                name: "spare",
                id: -1,
                serversTmp: serversTmp,
                domainsTmp: domainsTmp
            })

            this.setState({ groups: combined, loadingPct: 100, loadingText: "", pageLoaded: true }, this.getDomainStats)
        })
    }

    getDomainStats() {
        axios.get(`/mailServer/deliveryManager/domain/stats`).then(_results => {
            this.setState({ domainStats: _results.data.stats });
        })
    }

    serverChangeGroup(serverId, newGroupId, currentGroupId) {
        var groups = this.state.groups;
        var currentGroup = groups.find(g => g.id == currentGroupId)
        var currIndex = currentGroup.serversTmp.findIndex(s => s.id == serverId)
        var newGroup = groups.find(g => g.id == newGroupId)
        var serverObj = currentGroup.serversTmp[currIndex]

        if (currentGroupId != -1) {
            var liveExistsIndex = currentGroup.liveMailServers.indexOf(serverId);
            currentGroup.liveMailServers.splice(liveExistsIndex, 1);
        }

        newGroup.serversTmp.push(serverObj)
        currentGroup.serversTmp.splice(currIndex, 1)

        this.setState({ groups })
    }

    domainChangeGroup(domainId, newGroupId, currentGroupId) {
        var groups = this.state.groups;
        var currentGroup = groups.find(g => g.id == currentGroupId)
        var currIndex = currentGroup.domainsTmp.findIndex(s => s.id == domainId)
        var newGroup = groups.find(g => g.id == newGroupId)
        var serverObj = currentGroup.domainsTmp[currIndex]

        if (currentGroupId != -1) {
            var liveExistsIndex = currentGroup.liveDomains.indexOf(domainId);
            currentGroup.liveDomains.splice(liveExistsIndex, 1);
        }
        newGroup.domainsTmp.push(serverObj)
        currentGroup.domainsTmp.splice(currIndex, 1)
        this.setState({ groups })
    }

    updateState(sId, data) {

        var groups = this.state.groups;
        var ourGroup = groups.findIndex(g => g.id == sId);
        groups[ourGroup] = data;
        this.setState({ groups: groups })
    }

    toggleActive(event, type, object, group) {
        event.stopPropagation();
        if (type == "domain") {

            var existsIndex = group.liveDomains.indexOf(object.id);
            if (existsIndex > -1) {
                group.liveDomains.splice(existsIndex, 1);
            }
            else {
                group.liveDomains.push(object.id)
            }
        }
        if (type == "server") {
            var existsIndex = group.liveMailServers.indexOf(object.id);
            if (existsIndex > -1) {
                group.liveMailServers.splice(existsIndex, 1);
            }
            else {
                group.liveMailServers.push(object.id)
            }
        }
        this.setState({});
    }

    saveChanges() {
        var drawerContent = <SaveChangesDrawer confirm={this.goSaveChanges} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '500px'));
    }

    async goSaveChanges(doIt) {

        this.setState({ exitting: true })
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", undefined, true, '500px'));

        if (doIt) {

            var saveData = JSON.parse(JSON.stringify(this.state.groups)).filter(g => g.id > -1).map(gp => {

                gp.domains = gp.domainsTmp.map(dT => { return dT.id })
                gp.mailServers = gp.serversTmp.map(mS => { return mS.id })
                delete gp.serversTmp;
                delete gp.domainsTmp;
                return gp;
            })
            var tmpCnt = 0;
            while (tmpCnt < saveData.length) {
                var group = saveData[tmpCnt];

                var url = `/mailServer/deliveryManager/${group.id}`
                var data = group;

                await axios.put(url, data)
                tmpCnt++;
            }
        }
        this.props.history.push("/cp/company/servers")
    }

    toggleTriggerType(ev, groupId) {
        let groups = JSON.parse(JSON.stringify(this.state.groups))
        var ourGroup = groups.find(g => g.id == groupId);
        ourGroup.triggerType = Number(ev.target.value);
        this.setState({ groups: groups })
    }

    toggleTriggerValue(ev, groupId) {
        let groups = JSON.parse(JSON.stringify(this.state.groups))
        var ourGroup = groups.find(g => g.id == groupId);
        ourGroup.triggerValue = ev.target.value;
        this.setState({ groups: groups })
    }

    render() {
        if (this.state.exitting) {
            return <div style={{ width: "100%", height: "100%", position: "fixed" }}>
                <div style={{ margin: "0 auto", marginTop: "20px", fontSize: "12px", textAlign: "center", display: "block", position: "relative", top: "50%", transform: "translateY(-50%)" }}>
                    <UILIB.LoadingIcons iconType="2" />
                </div>
            </div >
        }
        if (!this.state.pageLoaded) {
            return <div style={{ width: "100%", height: "100%", position: "fixed" }}>

                {(this.state.loadingText != '') && <div style={{ margin: "0 auto", marginTop: "20px", fontSize: "12px", textAlign: "center", display: "block", position: "relative", top: "50%", transform: "translateY(-50%)" }}>
                    <div style={{ width: "200px", margin: "auto", marginBottom: "10px" }}>
                        <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.loadingPct} val2={100} />
                    </div>
                    {this.state.loadingText}
                </div>}
            </div>
        }


        return <div>

            <div className="dragDropToolBar">
                <div style={{ color: "white" }}>
                    Delivery Manager
                </div>
                <div>
                    <a onClick={this.saveChanges} className="button outline">Exit</a>
                </div>
            </div>


            <div className="eContainer">
                <div className="contentArea">

                    <div style={{ display: "flex", flexDirection: "row", overflow: "auto", height: "100%" }}>

                        {this.state.groups.filter(g => g.id > -1).map((group, groupIndex) => {
                            return <div key={"g" + groupIndex} style={{ flex: "1 0 500px", marginLeft: "30px", marginTop: "30px", marginBottom: "30px", height: "calc(100% - 40px)" }}>

                                <div style={{ position: "absolute", top: "10px", right: "10px", display: "flex" }}>
                                    <div style={{ backgroundColor: "green", width: "15px", height: "15px", borderRadius: "5px", marginRight: "5px" }} />
                                    <div style={{ fontSize: "12px", marginRight: "5px" }}>Live</div>
                                    <div style={{ backgroundColor: "grey", width: "15px", height: "15px", borderRadius: "5px", marginRight: "5px" }} />
                                    <div style={{ fontSize: "12px" }}>Spare</div>
                                </div>
                                <div className="text-center mar-t15 mar-b15">
                                    <div className="quickFlex mar-b10" style={{ flexDirection: "row" }}>
                                        <div className="mar-r10"><label for="" style={{ width: "initial" }}>Trigger Type</label></div>
                                        <UILIB.Select data={[{ value: 0, label: "Group selection on campaign send" }, { value: 1, label: "From Name auto selects the group" }]} outerstyle={{ flex: "1" }} name="triggerType" value={group.triggerType} onChange={(ev) => { this.toggleTriggerType(ev, group.id) }} />
                                    </div>
                                    {(!group.triggerType || group.triggerType == 0) && <h4>{group.name}</h4>}
                                    {(group.triggerType == 1) && <div className="quickFlex" style={{ flexDirection: "row" }}>
                                        <div className="mar-r10"><label for="" style={{ width: "initial" }}>From Name</label></div>
                                        <UILIB.TextInput type="text" outerStyle={{ flex: "1" }} placeholder="John Smith" value={group.triggerValue} onChange={(ev) => { this.toggleTriggerValue(ev, group.id) }} />
                                    </div>}

                                </div>

                                <div style={{ display: "flex", flexDirection: "column", height: "calc(100% - 30px)" }}>

                                    <div style={{ border: "1px solid grey", borderRadius: "5px", height: "50%", marginBottom: "10px" }}>
                                        <div style={{ fontSize: "18px", padding: "5px 20px" }}>Servers ({group.serversTmp.length})</div>
                                        <div style={{ display: "flex", flexFlow: "wrap", height: "calc(100% - 15px)", alignContent: "flex-start", borderTop: "1px solid grey", paddingTop: "20px" }}>
                                            <ServerHolder group={group} serverChangeGroup={this.serverChangeGroup} footer={true} updateState={this.updateState}>
                                                {group.serversTmp.map((server, serverIndex) => {
                                                    return <Server key={"server_" + serverIndex} server={server} group={group} stage={true} toggleActive={this.toggleActive} />
                                                })}
                                            </ServerHolder>
                                        </div>
                                    </div>


                                    <div style={{ border: "1px solid grey", borderRadius: "5px", height: "50%", marginBottom: "10px" }}>
                                        <div style={{ fontSize: "18px", padding: "5px 20px" }}>Domains ({group.domainsTmp.length})</div>
                                        <div style={{ display: "flex", flexFlow: "wrap", height: "calc(100% - 15px)", alignContent: "flex-start", borderTop: "1px solid grey", paddingTop: "20px" }}>
                                            <DomainHolder group={group} domainChangeGroup={this.domainChangeGroup} footer={true} updateState={this.updateState}>
                                                {group.domainsTmp.map((domain, domainIndex) => {
                                                    var domainStats = this.state.domainStats.filter(ds => ds.sendingDomainId == domain.id)
                                                    return <Domain key={"domain" + domainIndex} domain={domain} group={group} stats={domainStats} stage={true} toggleActive={this.toggleActive} />
                                                })}
                                            </DomainHolder>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        })}

                    </div>
                </div>
                <div className="blocksPanel">
                    <UnassignedHolder groups={this.state.groups} serverChangeGroup={this.serverChangeGroup} domainChangeGroup={this.domainChangeGroup} />

                </div>

            </div>
        </div >
    }
}

export default DeliveryManager;
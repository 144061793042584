import { useState, useCallback } from 'react';
export default function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const setStateCallback = useCallback((newState, callback) => {
        return new Promise((resolve) => {
            setState(newState);
            if (callback && typeof callback === 'function') {
                callback();
            }
            resolve();
        })
    }, []);

    return [state, setStateCallback];
}
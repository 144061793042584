import React from 'react'
import UILIB from '~/Common-Objects/Lib';
import i18n from '~/i18n'


export default class RatingStarsExplainer extends React.Component {
    constructor(props) {
        super(props);

    }




    render() {
        return <div>
            <h4 className="mar-b25">{i18n.t('subscribers:ratings.explainer.title')}</h4>
            <div className="mar-b25">{i18n.t('subscribers:ratings.explainer.subTitle1')}</div>
            <div className="mar-b25">{i18n.t('subscribers:ratings.explainer.subTitle2')}</div>
            <h4 className="mar-b25">{i18n.t('subscribers:ratings.explainer.subTitle3')}</h4>

            <table className="bottomBorders" width="100%" cellPadding="10" cellSpacing="0">
                <tr>
                    <td>{i18n.t('subscribers:ratings.explainer.siteClicks')}</td>
                    <td>+ 20 {i18n.t('subscribers:ratings.explainer.points')}</td>
                </tr>
                <tr>
                    <td>{i18n.t('subscribers:ratings.explainer.opensEmail')}</td>
                    <td>+ 10 {i18n.t('subscribers:ratings.explainer.points')}</td>
                </tr>
                <tr>
                    <td>{i18n.t('subscribers:ratings.explainer.clicksLink')}</td>
                    <td>+ 10 {i18n.t('subscribers:ratings.explainer.points')}</td>
                </tr>
                <tr>
                    <td>{i18n.t('subscribers:ratings.explainer.opened50')}</td>
                    <td>+ 15 {i18n.t('subscribers:ratings.explainer.points')}</td>
                </tr>
                <tr>
                    <td>{i18n.t('subscribers:ratings.explainer.opened20')}</td>
                    <td>+ 5 {i18n.t('subscribers:ratings.explainer.points')}</td>
                </tr>
                <tr>
                    <td>{i18n.t('subscribers:ratings.explainer.clicked20')}</td>
                    <td>+ 15 {i18n.t('subscribers:ratings.explainer.points')}</td>
                </tr>
                <tr>
                    <td>{i18n.t('subscribers:ratings.explainer.clicked5')}</td>
                    <td>+ 5 {i18n.t('subscribers:ratings.explainer.points')}</td>
                </tr>
                <tr>
                    <td>{i18n.t('subscribers:ratings.explainer.bounced')}</td>
                    <td>- 5 {i18n.t('subscribers:ratings.explainer.points')}</td>
                </tr>
                <tr>
                    <td>{i18n.t('subscribers:ratings.explainer.blacklisted')}</td>
                    <td>- 50 {i18n.t('subscribers:ratings.explainer.points')}</td>
                </tr>
                <tr>
                    <td>{i18n.t('subscribers:ratings.explainer.unsub100')}</td>
                    <td>- 50 {i18n.t('subscribers:ratings.explainer.points')}</td>
                </tr>
                <tr>
                    <td>{i18n.t('subscribers:ratings.explainer.unsub50')}</td>
                    <td>- 25 {i18n.t('subscribers:ratings.explainer.points')}</td>
                </tr>
            </table>


        </div>


    };
}

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import UILIB from '../../../../Common-Objects/Lib'
import axios from '../../../../data/http/axios'
import ThemeSelector from './themeSelector'
import Summary from './summary'
import FinishedPage from './finished';
import UrlInputPage from './urlInput';
import { alterSnackbar } from '../../../../data/actions/siteActions'
import i18n from '~/i18n';


import 'react-circular-progressbar/dist/styles.css';

export default function SiteAnalyzer({ id }) {
    const dispatch = useDispatch()
    const timer = useRef(null)
    const [account, setAccount] = useState(null)
    const [job, setJob] = useState({})
    const [currStep, setCurrStep] = useState(0)

    const save = async (j, saveToAccount) => {
        await axios.put('/accountMaster/siteanalyzer/' + j.id, {
            selected: j.selected,
            socialLinks: j.results && j.results.socialLinks ? j.results.socialLinks : {},
            websiteAddress: j.url,
            saveToAccount: saveToAccount
        })
        setAlert({ message: 'Changes Saved', open: true })
    }

    const setAlert = (e) => {
        dispatch(alterSnackbar(e.open, e.message))
    }

    useEffect(() => {
        axios.get('/accountMaster').then(res => {
            setAccount(res.data.AccountMaster)
            if (id && id > 0) {
                axios.get('/accountMaster/siteanalyzer/' + id).then(jobRes => {
                    setJob(jobRes.data)
                    setCurrStep(1);
                }).catch(console.log)
            }
        })
        return function () {
            if (timer.current) clearTimeout(timer.current)
        }
    }, [])

    if (!account) return <UILIB.LoadingIcons iconType="2" />

    let steps = [
        {
            title: i18n.t('templates:brandAnalyzer.websiteUrl'),
            onClick: () => { if (currStep > 0) setCurrStep(0) },
            selected: currStep == 0,
            complete: currStep > 0
        },
        {
            title: i18n.t('templates:brandAnalyzer.themePicker'),
            onClick: () => { if (currStep > 1) setCurrStep(1) },
            selected: currStep == 1,
            complete: currStep > 1
        },
        {
            title: i18n.t('templates:brandAnalyzer.summary'),
            onClick: () => { if (currStep > 2) setCurrStep(2) },
            selected: currStep == 2,
            complete: currStep > 2
        },
        {
            title: i18n.t('templates:brandAnalyzer.finished'),
            onClick: () => { if (currStep > 3) setCurrStep(3) },
            selected: currStep == 3,
            complete: false
        }
    ];

    return (

        <UILIB.Row>
            <UILIB.Column xs={12} sm={12} md={10} className="col-md-offset-1">
                <div className="text-center mar-b25">
                    <span style={{ position: "relative" }}>
                        <span className="text-heavy" style={{ fontSize: "20px" }}>{i18n.t('templates:brandAnalyzer.brandAnalyzer')}</span>
                        <div style={{ position: "absolute", top: -10, right: -60 }}><UILIB.SquareChip className="square-chip-purple" value="BETA" /></div>
                    </span>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <UILIB.ProgressNav steps={steps} />
                </div>
                {currStep == 0 && <UrlInputPage job={job} setJob={setJob} save={save} next={() => { setCurrStep(1) }} />}
                {currStep == 1 && <ThemeSelector setJob={setJob} save={save} job={job} setAlert={setAlert} next={() => setCurrStep(2)} prev={() => {
                    setJob(null)
                    setCurrStep(0)
                }
                } />}
                {currStep == 2 && <Summary job={job} setJob={setJob} save={save} back={() => setCurrStep(1)} next={() => { save(job, true); setCurrStep(3) }} />}
                {currStep == 3 && <FinishedPage job={job} setJob={setJob} save={save} back={() => setCurrStep(2)} next={() => { save(job); }} />}
            </UILIB.Column>
        </UILIB.Row >
    )
}
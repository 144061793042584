import React from "react";

export default function IconDropdownArrow({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.39279 10.4914C7.71207 10.8639 8.28832 10.8639 8.6076 10.4914L11.6682 6.92064C12.113 6.40171 11.7443 5.60001 11.0608 5.60001H4.93957C4.25609 5.60001 3.88736 6.40171 4.33217 6.92065L7.39279 10.4914Z"
        fill={color}
      />
    </svg>
  );
}

import axios from '~/data/http/axios';

class EmailValidator {

    static validateEmail(theEmail) {

        var emailRegexpPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegexpPattern.test(theEmail);
    }

    static checkEmailAuthed(emailAddress) {
        if (!emailAddress) {
            return Promise.resolve({
                error: true
            })
        }

        if (emailAddress.indexOf('@') > -1 && this.validateEmail(emailAddress)) {
            var domain = emailAddress.split('@')[1].toLowerCase()
            return axios.get('/accountMaster/domain/check?email=' + emailAddress)
                .then(response => {
                    var isVerified = false;
                    var originalEmailAddress = "";
                    var originalDomainID = 0;
                    var isAuthenticated = false;
                    if (response.data.hasOwnProperty("Domain") === false) {
                        isVerified = true;
                    }
                    else {
                        isVerified = response.data.Domain.verified;
                        originalEmailAddress = response.data.Domain.originalEmailAddress;
                        originalDomainID = response.data.Domain.id;
                        isAuthenticated = response.data.Domain.authenticated;
                    }
                    return {
                        domainExists: response.data.Exists,
                        domainName: '@' + domain,
                        domainVerified: isVerified,
                        domainOrigEmailSentTo: originalEmailAddress,
                        domainId: originalDomainID,
                        domainBlocked: false,
                        domainAuthenticated: isAuthenticated
                    }

                })
                .catch(err => {
                    if (err.data.error) {

                        return {
                            domainVerified: false,
                            domainBlocked: true,
                            domainAuthenticated: false
                        }

                    } else {
                        return {
                            error: true
                        }
                    }
                })
        } else {
            return Promise.resolve({
                error: true
            })
        }
    }

}

export default EmailValidator;
import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';
import UILIB from '~/Common-Objects/Lib'
import clone from 'lodash/clone'
import CodeEditor from './codeEditor/codeEditor'

export default class DivStandalone extends Component {
    constructor(props) {
        super(props)
        this.clickCatcher = this.clickCatcher.bind(this);
        this.updateHtmlValue = this.updateHtmlValue.bind(this);
    }

    clickCatcher(event) {
        event.preventDefault();
    }

    updateHtmlValue(value) {
        var newObject = clone(this.props.elementData);
        newObject.content.value = value;
        this.props.updateElement(newObject, this.props.path)
    }

    render() {


        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "div", this.props.localVariables)
        }

        if (this.props.elementData.content && this.props.elementData.content.type && this.props.elementData.content.type == "html") {


            if (eleProps && !eleProps.style) eleProps.style = {}
            eleProps.style.minHeight = "10px";
            return <div >
                <div {...eleProps} >
                    <div dangerouslySetInnerHTML={{ __html: this.props.elementData.content.value }} className="remove-all-styles" onClick={this.clickCatcher} />
                </div>

            </div>
        }

        if (this.props.elementData.content && this.props.elementData.content.value) {
            var thisContent = this.props.elementData.content.value;
            if (thisContent.indexOf("_localVariables_") >= 0)
                thisContent = DragDropFunctions.lookupLocalVariables(this.props.localVariables, thisContent);
            return <div {...eleProps}>
                <div dangerouslySetInnerHTML={{ __html: thisContent }} onClick={this.clickCatcher} />
                {this.props.children}
            </div>
        }
        else {
            return <div {...eleProps}>
                {this.props.children}
            </div>
        }


    }
}
import React from 'react'
import { Redirect } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react'
import { connect } from 'react-redux';
import PermissionsChecker from '../../Classes/permissions'

@connect((store) => {
    return { permissionStore: store.permission, user: store.user, accountMaster: store.accountMaster }
})

export default class PermissionRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.checkPermission = this.checkPermission.bind(this)
    }

    checkPermission() {
        if (this.props.permissionName && this.props.permissionStore.loading === false) {
            return PermissionsChecker(this.props.permissionName, this.props.permissionStore.permissions, this.props.method);
        } else if (this.props.agency) {
            return this.props.user.userData.isAgencyAdmin && this.props.accountMaster.accountMaster.isAgencyAdmin;
        } else {
            return true;
        }
    }

    render() {
        if (this.checkPermission() && !this.props.disabled) {
            return <ApmRoute {...this.props}></ApmRoute>
        } else {
            return <Redirect to={{
                pathname: '/cp',
                state: { from: this.props.location }
            }} />
        }
    }
}

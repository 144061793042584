import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios from '../../../../../../data/http/axios'
import UILIB from '../../../../../../Common-Objects/Lib'
import Disconnect from '../genericCp/disconnect'
import { alterSiteDrawer } from '../../../../../../data/actions/siteActions'
import queryString from 'query-string'

export default function TwitterConfig() {
    const history = useHistory()
    const dispatch = useDispatch()
    const [accApp, setAccApp] = useState(null)
    const [error, setError] = useState(false)
    const [socials, setSocials] = useState(null)
    const [query, setQuery] = useState(queryString.parse(window.location.search))

    const refreshSocials = async () => {
        await axios.get('/application/twitter/sites/' + (query.id || ''))

        getSocials()
    }

    const getSocials = async () => {
        if (accApp) {
            await axios.get('/application/twitter/sites/' + (query.id || ''))
            const data = (await axios.get('/social?followers=true')).data

            setSocials(data.filter(s => {
                if (query?.id) return s.ApplicationId === 85 && s.AccountApplicationId === parseInt(query.id)
                return s.ApplicationId === 85
            }))
        }
    }

    const popDisconnect = () => {
        dispatch(alterSiteDrawer(true, true, "right", <Disconnect appName={"X"} goDisconnect={() => {
            axios.delete('/accountMaster/application/' + accApp.id).then(() => {
                history.push('/cp/company/integrations')
            })
        }} />, true, "500px"));
    }

    const addAnother = () => {
        dispatch(alterSiteDrawer(true, true, "right", <AddAnother />, true, "500px"));
    }

    useEffect(() => {
        let q = '?'
        if (query?.id) q += 'id=' + query.id
        axios.get("/application/account/app/85" + q).then(res => {
            setAccApp(res.data.Application)
        }).catch(e => {
            console.log(e)
            setError(true)
        })
    }, [])

    useEffect(() => {
        if (accApp) {
            refreshSocials()
        }
    }, [accApp])

    if (!accApp) return <UILIB.LoadingIcons />

    if (error) return <UILIB.WarningHolder>Integration Failed</UILIB.WarningHolder>

    let isPopupFromSocial = !!window.opener;
    if (isPopupFromSocial) {
        if (window.opener.location?.pathname && window.opener.location.pathname.indexOf('/cp/campaigns/add/social/') === -1) {
            isPopupFromSocial = false;
        }
    }

    if (isPopupFromSocial) {
        return <div>

            <UILIB.Row className="middle-xs mar-b10">
                <UILIB.Column xs={12} sm={12} md={8} lg={8} className="col-md-offset-2">
                    <UILIB.Paper>
                        <div className="text-center text-lg text-heavy">
                            You can close this window now
                        </div>
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
        </div>
    }

    return (
        <div>
            <div className="row" style={{ alignItems: 'center' }}>
                <div className="col-xs">
                    <h4 className="mar-b15">Your Social Accounts</h4>
                    <div className="mar-b5">Here's a list of the X (formally Twitter) social accounts we found you have access to.</div>
                </div>
                <div className="col-xs end-xs">
                    <UILIB.Button className="button-sml button-red" onClick={popDisconnect}>Disconnect</UILIB.Button>
                </div>
            </div>
            {!socials && <UILIB.LoadingIcons />}
            {!!socials && <UILIB.DataTable1
                tableData={socials.map(row => {
                    const obj = {
                        icon: {
                            headerValue: " ",
                            value: <UILIB.Avatar src={row.image} style={{ height: 30, with: 30 }} />,
                            width: 50
                        },
                        handle: {
                            headerValue: 'Handle',
                            value: '@' + row.options.username
                        },
                        name: {
                            headerValue: "Name",
                            value: row.name
                        },
                        followers: {
                            headerValue: "Followers",
                            value: row.SocialHistory ? row.SocialHistory.followers : <UILIB.SquareChip>Sync Pending</UILIB.SquareChip>
                        },
                        options: {
                            headerValue: ' ',
                            value: <UILIB.Button className="button-sml button-primary" onClick={() => history.push('/cp/company/integrations/apps/social/' + row.id)}>History</UILIB.Button>
                        }
                    }
                    return obj
                })}
            />}
        </div>
    )
}

function AddAnother() {
    const [error, setError] = useState(null)
    const connect = () => {
        axios.get('/application/twitter/authlink').then(res => {
            if (res.data.url) window.location = res.data.url
            else {
                setError('Something went wrong. Please try again later.')
            }
        }).catch(e => {
            console.log(e)
            setError('Something went wrong. Please try again later.')
        })
    }

    return (
        <div>
            <h3 className="mar-b20">Add Another X Account</h3>
            <UILIB.WarningHolder className="mar-b20">Ensure you have logged into another X account in this browser before continuing</UILIB.WarningHolder>
            <UILIB.Button className="button-primary" onClick={connect}>Connect</UILIB.Button>
            {!!error && <div className="text-red mar-t10">{error}</div>}
        </div>
    )
}
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'
import copy from 'copy-to-clipboard';
import IgnoreDrawer from './ignore'

@connect((store) => {
    return { site: store.siteMaster }
})
class SiteTracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trackers: [],
            loading: true,
            trackersData: [],
            showIgnored: false,
            newSiteUrl: '',
            addingSite: false,
            addedSite: false,
            newSiteError: ''
        }

        this.getTrackers = this.getTrackers.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this)
        this.toggleTrackerIgnore = this.toggleTrackerIgnore.bind(this)
        this.addNewSite = this.addNewSite.bind(this)
    }

    componentDidMount() {
        console.log(this.props)
        this.getTrackers();
    }

    async getTrackers() {
        this.props.getAccount();
        let query = ''
        if (this.state.showIgnored) query = '?showIgnored=true'
        let response = await axios.get('/accountMaster/tracker' + query)
        var trackersData = response.data.Trackers.map((tracker) => {
            var ourDomain = tracker.host;
            if (!tracker.host || !tracker.host.length) ourDomain = "unknown";
            return {
                domain: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingsites.domain'),
                    value: <a onClick={() => this.props.history.push('/cp/trackers/' + tracker.id)} style={{ fontWeight: "600" }}>{ourDomain}{tracker.ignore ? ' (ARCHIVED)' : ''}</a>,
                    orderBy: false
                },
                hits: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingsites.hits'),
                    value: <span>{NumberFunctions.formatNumber(tracker.hits)}</span>,
                    orderBy: false,
                    align: "center",
                    width: "100"
                },
                lastHit: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingsites.lastHit'),
                    value: <span> {DateTimeFunctions.formatDateTime(tracker.lastHit)}</span>,
                    orderBy: false,
                    align: "center",
                    width: "200"
                },
                optionsCol: {
                    headerValue: " ",
                    value: <UILIB.OptionsDropdown popWidth="150px">
                        <ul>
                            <li>
                                <a onClick={() => this.props.history.push('/cp/trackers/' + tracker.id)}>View</a>
                            </li>
                            <li>
                                <a onClick={() => this.toggleTrackerIgnore(tracker)}>{tracker.ignore ? 'Unarchive' : 'Archive'}</a>
                            </li>
                        </ul>
                    </UILIB.OptionsDropdown>,
                    fixed: true,
                    width: 20
                }
            }
        })
        let trackers = response.data.Trackers;
        this.setState({
            trackersData: trackersData,
            trackers: trackers,
            loading: false
        })
    }

    copyToClipboard(key) {
        this.setState({
            alertOpen: true,
            alertMessage: i18n.t('campaigns:sitetracking.trackingsites.copiedToClipboard')
        })
        copy(this.props.embedCode)
    }

    toggleIgnored() {
        this.setState({
            showIgnored: !this.state.showIgnored
        }, this.getTrackers)
    }

    toggleTrackerIgnore(tracker) {
        const drawer = <IgnoreDrawer site={tracker} finish={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false))
            this.getTrackers()
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, 'right', drawer, true, 400))
    }

    async addNewSite() {
        this.setState({ addingSite: true, newSiteError: '', addedSite: false })

        //replace http and https with space
        let newSiteUrl = this.state.newSiteUrl.toLowerCase().replace("https://", "").replace("http://", "").replace("www.", "").replace(" ", "")
        if (!newSiteUrl || newSiteUrl.length < 5) {
            this.setState({ newSiteError: 'Invalid URL', addingSite: false })
            return
        }

        if (newSiteUrl.indexOf(".") < 0 && newSiteUrl.indexOf(":") < 0) {
            this.setState({ newSiteError: 'Invalid URL', addingSite: false })
            return
        }
        if (newSiteUrl.length > 254) {
            this.setState({ newSiteError: 'URL too long', addingSite: false })
            return
        }

        try {
            const resp = await axios.post('/accountMaster/tracker', { host: newSiteUrl })
            this.setState({ newSiteUrl: '', addingSite: false, addedSite: !this.state.trackers.length == 0 }, () => {
                this.getTrackers();
            })
        }
        catch (e) {

            this.setState({ newSiteError: e?.data?.error || "Error adding site", addingSite: false })
        }

    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        return (
            <>
                {!this.state.trackers || this.state.trackers.length < 1 &&
                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={12} md={6} lg={7}>
                            <UILIB.Paper isLoading={this.state.loading} className="paper-primary text-white">
                                <h4 className="mar-b25 text-white">{i18n.t('campaigns:sitetracking.trackingsites.introHeading')}</h4>
                                <p className="text-white"><strong>{i18n.t('campaigns:sitetracking.trackingsites.introSubHeading')}</strong></p>
                                <p className="text-white" dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:sitetracking.trackingsites.introContent') }}></p>
                                <p className="text-white">
                                    {i18n.t('campaigns:sitetracking.trackingsites.introContent2')}
                                </p>
                            </UILIB.Paper>
                            {this.props.tierAllowed.allowed && <UILIB.Paper isLoading={this.state.loading}>
                                <h4 className="mar-b25">{i18n.t('campaigns:sitetracking.trackingsites.codeIntro')}</h4>
                                <div className="mar-b15">{i18n.t('campaigns:sitetracking.trackingsites.codeDescNew')}</div>

                                {!this.state.addingSite && !this.state.addedSite && <div>

                                    <UILIB.TextInput
                                        label={i18n.t('campaigns:sitetracking.trackingsites.trackingCode')}
                                        outerStyle={{ flex: "1" }}
                                        value={this.props.embedCode}
                                        iconRight={<UILIB.Icons icon="copyClipboard" onClick={this.copyToClipboard} />}
                                        readOnly={true}
                                        className="mar-b25"
                                    />

                                    <UILIB.TextInput
                                        label={i18n.t('campaigns:sitetracking.trackingsites.websiteUrl')}
                                        outerStyle={{ flex: "1" }}
                                        value={this.state.newSiteUrl}
                                        placeholder="https://yoursite.com"
                                        onChange={(e) => this.setState({ newSiteUrl: e.target.value })}
                                        className="mar-b25"
                                        error={this.state.newSiteError}
                                    />
                                    <UILIB.Button
                                        text={i18n.t('campaigns:sitetracking.trackingsites.addSite')}
                                        onClick={this.addNewSite}
                                    />
                                </div>}
                                {this.state.addingSite && <UILIB.LoadingIcons iconType="2" />}

                            </UILIB.Paper>}
                            {!this.props.tierAllowed.allowed && <UILIB.TierLimitHolder type="Site Tracker" err={this.props.tierAllowed.err} tierName={this.props.tierAllowed.tierName} allowedNumber={this.props.tierAllowed.allowedNumber} />}

                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={12} md={6} lg={5} hide={["xs", "sm"]}>
                            <img src="https://cdn1.ourmailsender.com/siteContent/assets/siteTracking/siteTrackingStats.png" style={{ width: "100%" }} />
                        </UILIB.Column>
                    </UILIB.Row>
                }
                {
                    this.state.trackers && this.state.trackers.length > 0 &&
                    <UILIB.Row>

                        <UILIB.Column xs={12} sm={12} md={8} >
                            <UILIB.Paper isLoading={this.state.loading}>
                                <h4 className="mar-b25">{i18n.t('campaigns:sitetracking.trackingsites.yourTrackedSites')}</h4>
                                <div className="mar-b25">{i18n.t('campaigns:sitetracking.trackingsites.yourTrackedSitesIntro')}</div>
                                <UILIB.Toggle outerClassName="mar-b25" after="Show Archived" checked={this.state.showIgnored} onChange={this.toggleIgnored.bind(this)} />
                                <div style={{ marginTop: 10 }}>

                                    <UILIB.DataTable1
                                        noResultsTxt={i18n.t('campaigns:sitetracking.trackingsites.noWebsites')}
                                        tableData={this.state.trackersData}
                                        loadingData={false}
                                        width="100%"
                                        pageSize="100"
                                        hasCheckBoxes="no"
                                    />
                                </div>
                            </UILIB.Paper>

                        </UILIB.Column>

                        <UILIB.Column xs={12} sm={12} md={4}>
                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={12} md={12} margin={0}>
                                    {this.props.tierAllowed.allowed && <UILIB.Paper className="primary" isLoading={this.state.loading}  >
                                        {!this.state.addingSite && !this.state.addedSite && <div>
                                            <h4 className="mar-b25"> {i18n.t('campaigns:sitetracking.trackingsites.addAnotherSite')}</h4>
                                            <div className="mar-b25">
                                                {i18n.t('campaigns:sitetracking.trackingsites.addAnotherSiteDesc')}

                                            </div>

                                            <UILIB.TextInput
                                                label={i18n.t('campaigns:sitetracking.trackingsites.trackingCode')}
                                                value={this.props.embedCode}
                                                iconRight={<UILIB.Icons icon="copyClipboard" onClick={this.copyToClipboard} />}
                                                readOnly={true}
                                                className="mar-b15"
                                            />
                                            <UILIB.TextInput
                                                label={i18n.t('campaigns:sitetracking.trackingsites.websiteUrl')}
                                                outerStyle={{ flex: "1" }}
                                                value={this.state.newSiteUrl}
                                                placeholder="https://yoursite.com"
                                                onChange={(e) => this.setState({ newSiteUrl: e.target.value })}
                                                error={this.state.newSiteError}
                                                className="mar-b15"
                                            />
                                            <UILIB.Button
                                                text={i18n.t('campaigns:sitetracking.trackingsites.addSite')}
                                                onClick={this.addNewSite}
                                            />
                                        </div>}
                                        {this.state.addingSite && <UILIB.LoadingIcons iconType="2" />}

                                        {this.state.addedSite && <div>
                                            <h4 className="mar-b25">{i18n.t('campaigns:sitetracking.trackingsites.siteAdded')}</h4>
                                            <div className="mar-b15">
                                                {i18n.t('campaigns:sitetracking.trackingsites.siteAddedDesc')}
                                            </div>
                                            <UILIB.Button
                                                text={i18n.t('campaigns:sitetracking.trackingsites.finish')}
                                                onClick={() => { this.setState({ addedSite: false }) }}
                                            />

                                        </div>}
                                    </UILIB.Paper>}
                                    {!this.props.tierAllowed.allowed && <UILIB.TierLimitHolder type="Site Tracker" err={this.props.tierAllowed.err} tierName={this.props.tierAllowed.tierName} allowedNumber={this.props.tierAllowed.allowedNumber} />}
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={12} margin={0}>
                                    <UILIB.Paper isLoading={this.state.loading}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", alignItems: "flex-start" }}>
                                            <UILIB.Icons icon="cog" className="mar-r15" style={{ width: 30, height: 30 }} />
                                            <div>
                                                <h4 className="mar-b10">{i18n.t('campaigns:sitetracking.trackingsites.moreOptions')}</h4>
                                                Developer tools to extend and progamatically manipulate Site Tracking.
                                            </div>
                                            <UILIB.Button
                                                style={{ flex: "0 0 100px" }}
                                                text="Show"
                                                iconRight={<UILIB.Icons icon={"arrowRight"} />}
                                                onClick={() => {
                                                    this.props.history.push("/cp/trackers/advanced")
                                                }} />
                                        </div>
                                    </UILIB.Paper>
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={12} margin={0}>
                                    <UILIB.Paper isLoading={this.state.loading}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", alignItems: "flex-start" }}>
                                            <UILIB.Icons icon="cloudUpload" className="mar-r15" style={{ width: 30, height: 30 }} />
                                            <div>
                                                <h4 className="mar-b10">Events</h4>
                                                Enrich your data by triggering events such as Registrations and Payments when your customers interact with your website.
                                            </div>
                                            <UILIB.Button
                                                style={{ flex: "0 0 100px" }}
                                                text="Show"
                                                iconRight={<UILIB.Icons icon={"arrowRight"} />}
                                                onClick={() => {
                                                    this.props.history.push("/cp/trackers/events")
                                                }} />
                                        </div>
                                    </UILIB.Paper>
                                </UILIB.Column>
                            </UILIB.Row >


                        </UILIB.Column >


                    </UILIB.Row >

                }
                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
            </>
        );
    }
}

export default SiteTracking;
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from '~/data/http/axios';
import UILIB from '../../../../Common-Objects/Lib';
import DateTimeFunctions from '../../../../Classes/dateTimeFunctions';


export default function SupportConversationsHolder({ inboxId, currentFilterId, activeConversationId, inboxes = [], conversations = [], changeFilter = () => { }, selectConversation = () => { }, createConversation = () => { } }) {
    const user = useSelector(state => state.user)

    const notifications = UILIB.useNotifications()

    const toggleAvailability = async () => {
        await axios.put('/accountUser', { chatOnline: !user.userData.chatOnline })
    }

    useEffect(() => {
        if (user.userData.chatOnline) {
            notifications.requestPermission()
        }
    }, [user.userData.chatOnline])

    const siteNavOptions = inboxes.map(t => {
        return {
            label: t.name,
            value: t.id
        }
    })
    siteNavOptions.unshift({
        label: "All Inboxes",
        value: 0
    })

    const filterOptions = [{
        label: "Open",
        value: 'open'
    }, {
        label: "Closed",
        value: 'closed'
    }]

    return (
        <>
            <UILIB.Paper style={{ flex: 0, padding: "0", marginBottom: "0px", borderBottomLeftRadius: "0", borderBottomRightRadius: "0" }}>
                <div style={{ padding: "20px" }}>
                    {inboxes.length > 1 && <UILIB.Select data={siteNavOptions}
                        outerClassName="mar-b10"
                        value={inboxId}
                        onChange={changeFilter}
                        name="inboxId"
                    />}

                    <UILIB.Select data={filterOptions}
                        outerClassName="mar-b0"
                        value={currentFilterId}
                        onChange={changeFilter}
                        name="currentFilterId"
                    />
                </div>
                <hr style={{ margin: "0", padding: "0" }} />
            </UILIB.Paper>
            <UILIB.Paper className="mar-b0" id="conversationsList" margin={0} style={{ flex: 1, overflow: "auto", borderRadius: "0", padding: "0px", overflowY: 'auto', overflowX: 'hidden' }}>
                {(!conversations.length) && <div style={{ padding: "40px", textAlign: "center" }}>
                    <h4 className="mar-b10">You're upto date!</h4>
                    There are no outstanding chats at the moment. Check back later!
                </div>}
                {conversations.map((conv) => {
                    return <ConversationSnippet key={conv.id} conv={conv} activeConversationId={activeConversationId} selectConversation={selectConversation} />
                })}

            </UILIB.Paper>
            <UILIB.Paper style={{ padding: "0", flex: 0, marginBottom: "0px", borderTopLeftRadius: "0", borderTopRightRadius: "0" }}>
                <hr style={{ margin: "0", padding: "0" }} />
                <div style={{ padding: "15px", display: "flex", justifyContent: "space-between" }}>
                    <UILIB.Toggle after={user.userData.chatOnline ? "Online" : 'Offline'} checked={user.userData.chatOnline} onChange={toggleAvailability} />
                    <div className='quickFlex'>
                        <UILIB.Icons icon="envelope" data-for="email" data-tip="Start Conversation" style={{ width: "25px", height: "25px" }} onClick={createConversation} />
                    </div>
                </div>
            </UILIB.Paper>
        </>
    )
}

function ConversationSnippet({ conv, activeConversationId, selectConversation = () => { } }) {
    return (
        <div className={"support-webChat_leftNav-chatOverview " + (conv.id == activeConversationId ? "selected" : "") + (conv.status === 1 ? ' read' : '')} onClick={() => selectConversation(conv.id)}>
            <div className="support-webChat_leftNav-chatOverview_avatar"><UILIB.Avatar src={conv.Subscriber.subscriberImage} /></div>
            <div className='support-webChat_leftNav-chatOverview_content'>
                <div>{conv.subject} {conv.id}</div>
                <div className="support-webChat_leftNav-chatOverview_userName">{conv.Subscriber.emailAddress}</div>
                <div className="support-webChat_leftNav-chatOverview_messagePreview">{conv.lastMessage}</div>
            </div>
            <div className="support-webChat_leftNav-chatOverview_timestamp">Last Message: {DateTimeFunctions.humanizeDuration(conv.updatedAt)} ago</div>
        </div>
    )
}

export default function reducer(

    state = {
        loggedIn: false,
        loginErrors: '',
        accountUserId: 0,
        token: '',
        dataUpToDate: false,
        userData: {}
    }, action) {
    switch (action.type) {
        case "persist/REHYDRATE":
            {
                //this is called when the user refreshes a page or something and needs to reimport the store // this is just for users .. can replicate on any other stores
                var incoming = action.payload.user;
                if (incoming) {

                    let newState = { ...state, ...incoming, dataUpToDate: true }
                    return newState;
                }
                return state
            }
        case "LOGGEDIN_SUCCESS":
            {
                var newState = {
                    ...state,
                    userID: action.payload.AccountUserId,
                    dataUpToDate: false,
                    token: action.payload.token,
                    loggedIn: true,
                    loginErrors: null,
                }
                localStorage.setItem('reduxPersist:user', JSON.stringify(newState))

                return newState;
            }
        case "LOGGEDIN_FAILED":
            {
                var newState = {
                    ...state,
                    loggedIn: false,
                    token: null,
                    loginErrors: action.payload
                }
                localStorage.setItem('reduxPersist:user', JSON.stringify(newState))
                return newState;
            }
        case "LOADUSER_SUCCESS":
            {
                var newState = {
                    ...state,
                    userData: action.payload
                }
                if (action.payload.id) {
                    newState.userID = action.payload.id
                    newState.accountUserId = action.payload.id
                }

                return newState;
            }
        case "LOGOUT_USER":
            {

                var newState = { ...state, "loggedIn": false, "token": null };
                return newState;
            }
        case "USER_CHANGE_ACCOUNTMASTER":
            {

                var newState = { ...state, "token": action.payload.token };
                return newState;
            }
    }
    return state;
}
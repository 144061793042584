import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'

export default class addHostedServerPurchased extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false
        }
        this.timer;
        this.lookupServer = this.lookupServer.bind(this);
    }

    componentWillMount() {
        this.lookupServer();
    }
    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer)
    }

    lookupServer() {
        if (this.timer) clearTimeout(this.timer);
        axios.post("/mailServer/add/findbyname", { serverName: this.props.serverName + "." + this.props.serverDomainName }).then(data => {
            this.setState({ serverData: data.data, loaded: true })
            if (!data.data.Success) {
                this.timer = setTimeout(this.lookupServer, 5000)
            }
            else {
                this.props.close(data.data.mailServer.id);
            }
        })
    }

    render() {
        if (!this.state.loaded) return <div className="loading">Loading</div>
        return (
            <div>
                <h4 className="mar-b25" onClick={this.props.close}>Setting up your new Server</h4>
                <div className="mar-b25">Please wait while your new server is setup. This can take around 5 minutes.</div>
                <div className="mar-b25">If you don't want to wait, you can continue using the control panel. Your new server will appear in the server list when done.</div>

                <UILIB.LoadingIcons iconType="2" />

            </div>
        );
    }

};


import React from 'react'

export default class IconsInstaGram extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.08219 3C4.83111 3 3 4.83281 3 7.08447V12.9178C3 15.1689 4.83281 17 7.08447 17H12.9178C15.1689 17 17 15.1672 17 12.9155V7.08219C17 4.83111 15.1672 3 12.9155 3H7.08219ZM14.0833 5.33333C14.4053 5.33333 14.6667 5.59467 14.6667 5.91667C14.6667 6.23867 14.4053 6.5 14.0833 6.5C13.7613 6.5 13.5 6.23867 13.5 5.91667C13.5 5.59467 13.7613 5.33333 14.0833 5.33333ZM10 6.5C11.9303 6.5 13.5 8.06975 13.5 10C13.5 11.9303 11.9303 13.5 10 13.5C8.06975 13.5 6.5 11.9303 6.5 10C6.5 8.06975 8.06975 6.5 10 6.5ZM10 7.66667C9.38116 7.66667 8.78767 7.9125 8.35008 8.35008C7.9125 8.78767 7.66667 9.38116 7.66667 10C7.66667 10.6188 7.9125 11.2123 8.35008 11.6499C8.78767 12.0875 9.38116 12.3333 10 12.3333C10.6188 12.3333 11.2123 12.0875 11.6499 11.6499C12.0875 11.2123 12.3333 10.6188 12.3333 10C12.3333 9.38116 12.0875 8.78767 11.6499 8.35008C11.2123 7.9125 10.6188 7.66667 10 7.66667Z" fill={color} />
        </svg>



    }
}        
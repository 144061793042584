import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import axios from '~/data/http/axios';
import AddCard from './addCardStripe';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import SiteVars from '~/Classes/siteVars'
import NewUserForm from '~/pages/cp/includes/bb_topNav/newUserForm/newUserForm';

@connect((store) => {
    return { user: store.user, account: store.accountMaster, siteMaster: store.siteMaster }
})
class StripeElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addCard: false,
            customer: undefined,
            cards: [],
            deleteDrawer: '',
            alertMessage: '',
            alertOpen: false,
            loading: true,
            stripeLoaded: false
        }

        this.stripe = null
        this.getCustomer = this.getCustomer.bind(this);
        this.makeDefault = this.makeDefault.bind(this);
        this.addCard = this.addCard.bind(this);
        this.deleteCard = this.deleteCard.bind(this);
        this.deleteCardGo = this.deleteCardGo.bind(this);
    }

    componentDidMount() {
        this.getCustomer()
        const self = this

        async function checkStripe() {
            if (!self.stripe) {
                console.log('getting stripe')
                if (window.Stripe) {
                    self.stripe = Stripe(SiteVars.stripeKey);
                    self.setState({ stripeLoaded: true })
                } else {
                    await new Promise((resolve, reject) => {
                        setTimeout(resolve, 300)
                    })
                    checkStripe()
                }
            }
        }
        checkStripe()
    }

    getCustomer() {
        axios.get('/accountMaster/paymentMethod/stripe').then(response => {
            this.setState({
                customer: response.data.customer,
                cards: response.data.customer.sources.data || [],
                loading: false
            })
        }).catch(console.log)
    }

    addCard() {
        var drawerContent = <AddCard stripe={this.stripe} close={() => {
            this.getCustomer();
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "100vw", 'full'));
    }

    makeDefault(cardId) {
        this.setState({
            alertMessage: 'Setting Default',
            alertOpen: true
        })
        axios.put('/accountMaster/paymentMethod/stripe', {
            default_source: cardId
        }).then(() => {
            var customer = this.state.customer;
            customer.default_source = cardId;
            this.setState({
                customer: customer,
                alertMessage: 'Default Set',
                alertOpen: true
            })
        }).catch(err => {
            this.setState({
                alertMessage: 'Error Settings Default',
                alertOpen: true
            })
            console.log(err)
        })
    }

    deleteCard(cardId) {

        var drawerContent = <UILIB.DrawerConfirm
            header="Delete Card?"
            showCancel={true}
            cancel={() => this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true, null, 'full'))}
            confirm={() => {
                this.deleteCardGo(cardId);
            }} />

        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    deleteCardGo(cardId) {

        let self = this;
        self.setState({
            alertMessage: 'Deleting Card',
            alertOpen: true
        })
        axios.delete('/accountMaster/paymentMethod/stripe/' + cardId)
            .then(() => {
                self.setState({
                    alertMessage: 'Card Deleted',
                    alertOpen: true,
                    deleteDrawer: ''
                })
                self.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true))
                self.getCustomer()
            }).catch(err => {
                self.setState({
                    alertMessage: 'Error Deleting Card',
                    alertOpen: true
                })
                console.log(err)
            })
    }

    requestApproval() {
        var theDrawer = <NewUserForm from="payment" closeNewUserForm={() => { }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, false, "100vw", 'full'));
    }

    render() {
        // if (this.props.account.accountMaster.accountType === 'free' && !this.state.cards.length) return <div></div>
        if (!this.state.stripeLoaded) return <UILIB.LoadingIcons iconType="2" />
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />


        const filled = this.props.account.accountMaster.doneNewUserQuestions

        return (
            <div>

                {this.state.cards.map((card, index) => {
                    return <div key={index} className="mar-b25">
                        <UILIB.CreditCard
                            default={this.state.customer.default_source}
                            card={card}
                            makeDefault={this.makeDefault}
                            deleteCard={this.deleteCard}
                            allowDelete={this.state.cards?.length > 1} />
                    </div>
                })}

                {this.props.writeAccess && <div>
                    <UILIB.Button
                        text="Add Payment Method"
                        onClick={this.addCard}
                        iconLeft={<UILIB.Icons icon="plus" />}
                    />

                </div>
                }
                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
                {this.state.deleteDrawer}
            </div>

        );
    }
}

export default StripeElement;
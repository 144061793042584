import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as siteActions from '../../../data/actions/siteActions'
import axios from '../../../data/http/axios'
import UILIB from '../../../Common-Objects/Lib'
import FormatNumberFuncs from '../../../Classes/numberFormatFunctions'
import Change from './changeOrg'

async function getOrgansisation(SubscriberId) {
    const res = await axios.get('/subscriber/' + SubscriberId + '/organisation')
    return res.data.Organisation
}

export default function SubscriberOrganisation({ SubscriberId, reloadSegments = () => { } }) {
    const [loading, setLoading] = useState(true)
    const [org, setOrg] = useState(null)
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        getOrgansisation(SubscriberId).then(o => {
            setOrg(o)
            setLoading(false)
        })
    }, [SubscriberId])

    const changeOrg = () => {
        const content = <Change SubscriberId={SubscriberId} onChange={() => {
            reloadSegments()
            getOrgansisation(SubscriberId).then(o => {
                setOrg(o)
            })
            dispatch(siteActions.alterSiteDrawer(false, true, 'right', null))
        }} onCancel={() => dispatch(siteActions.alterSiteDrawer(false, true, 'right', null))} />
        dispatch(siteActions.alterSiteDrawer(true, true, 'right', content, true, 400))
    }

    const startClear = () => {
        const drawer = <UILIB.DrawerConfirm header="Clear Organisation" subheader="Are you sure you want to stop this Contact being bound to this Organisation?" showCancel={true} confirm={clear}
            cancel={() => dispatch(siteActions.alterSiteDrawer(false, true, 'right', null))}
        />

        dispatch(siteActions.alterSiteDrawer(true, true, 'right', drawer, true, 400))
    }

    const clear = async () => {
        await axios.put('/subscriber/' + SubscriberId + '/organisation', {
            OrganisationId: null
        })
        reloadSegments()
        getOrgansisation(SubscriberId).then(o => {

            setOrg(o)
        })
    }

    const viewOrg = () => {
        history.push('/cp/subscribers/organisations/' + org.id)
    }

    return (
        <UILIB.Paper isLoading={loading} className="mar-b25">
            <h4 className="mar-b25">Organisation</h4>
            {!!org && <div className='text-heavy'>
                {org.name} <span className='text-grey text-sml'>({FormatNumberFuncs.formatNumber(org.Subscribers)} {org.Subscribers == 1 ? 'Subscriber' : 'Subscribers'})</span>
            </div >}
            {!org && !loading && <div>
                No Organisation
            </div>}
            <div className='mar-t20'>
                {!!org && <UILIB.Button text="View" onClick={viewOrg} className="button-primary mar-r10" />}
                <UILIB.Button text={org ? 'Change' : 'Assign'} onClick={changeOrg} className="mar-r10" />
                {!!org && <UILIB.Button text="Clear" onClick={startClear} className="button-red" />}
            </div>
        </UILIB.Paper >
    )
}
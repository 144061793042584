import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import Themes from '~/Common-Objects/Themes'
import Theme from '~/pages/cp/templates/add/dragDrop/themes/theme';

class BasicTheme extends Component {
    constructor(props) {
        super(props)
        this.state = {
            themes: [],
            count: 0,
            limit: 12,
            page: 0,
            loaded: false
        }

        this.getThemes = this.getThemes.bind(this);
    }

    componentDidMount() {
        this.getThemes()
    }

    getThemes() {
        var queryStr = `?type=0&limit=${this.state.limit}&offset=${this.state.limit * this.state.page}`
        axios.get('/theme' + queryStr)
            .then(response => {
                this.setState({
                    themes: response.data.Themes,
                    count: response.data.count,
                    loaded: true
                })
            })
    }

    render() {
        if (this.state.loaded) {
            var theme0 = this.state.themes.find(t => t.name == 'Blank');
            theme0.name = i18n.t('campaigns:standard.step2.basic.' + theme0.name)
            var theme1 = this.state.themes.find(t => t.name == '1 Column');
            theme1.name = i18n.t('campaigns:standard.step2.basic.' + theme1.name)
            var theme2 = this.state.themes.find(t => t.name == '2 Column');
            theme2.name = i18n.t('campaigns:standard.step2.basic.' + theme2.name)
            var theme3 = this.state.themes.find(t => t.name == '3 Column')
            theme3.name = i18n.t('campaigns:standard.step2.basic.' + theme3.name)
            var theme4 = this.state.themes.find(t => t.name == '4 Column');
            theme4.name = i18n.t('campaigns:standard.step2.basic.' + theme4.name)
            var theme5 = this.state.themes.find(t => t.name == '1-2 Column');
            theme5.name = i18n.t('campaigns:standard.step2.basic.' + theme5.name)
            var theme6 = this.state.themes.find(t => t.name == '2-1 Column');
            theme6.name = i18n.t('campaigns:standard.step2.basic.' + theme6.name)

            return (
                <UILIB.Paper>
                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={4} lg={3}><Theme theme={theme0} select={() => this.props.onSelect(theme0)}><Themes.Blank /></Theme> </UILIB.Column>

                        <UILIB.Column xs={12} sm={4} lg={3}><Theme theme={theme1} select={() => this.props.onSelect(theme1)}><Themes.Column1 /></Theme> </UILIB.Column>
                        <UILIB.Column xs={12} sm={4} lg={3}><Theme theme={theme2} select={() => this.props.onSelect(theme2)}><Themes.Column2 /></Theme> </UILIB.Column>
                        <UILIB.Column xs={12} sm={4} lg={3}><Theme theme={theme3} select={() => this.props.onSelect(theme3)}><Themes.Column3 /></Theme> </UILIB.Column>

                        <UILIB.Column xs={12} sm={4} lg={3} margin={0}><Theme theme={theme4} select={() => this.props.onSelect(theme4)}><Themes.Column4 /></Theme> </UILIB.Column>
                        <UILIB.Column xs={12} sm={4} lg={3} margin={0}><Theme theme={theme5} select={() => this.props.onSelect(theme5)}><Themes.Column12 /></Theme> </UILIB.Column>
                        <UILIB.Column xs={12} sm={4} lg={3} margin={0}><Theme theme={theme6} select={() => this.props.onSelect(theme6)}><Themes.Column21 /></Theme> </UILIB.Column>
                    </UILIB.Row>
                </UILIB.Paper>
            );
        } else {
            return <div style={{ marginTop: '50px' }}>
                <UILIB.LoadingIcons iconType="2" className="mar-b30" />
            </div>
        }
    }
}

export default BasicTheme;
import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'

export default class Expand extends Component {
  constructor(props) {
    super(props)
    this.change = this.change.bind(this);

    this.state = { selected: false }
  }

  change(event) {
    var selected = this.state.selected;
    if (selected === false) {
      selected = true;
    }
    else {
      selected = false;
    }
    this.setState({ selected: selected })
  }

  componentDidMount() {
    if (this.props.expanded) {
      this.setState({ selected: this.props.expanded })
    }
  }
  render() {

    var isSelected = "";


    if (this.state.selected === true) {
      isSelected = "dragDrop-expand-selected";

    }
    var children;
    if (this.state.selected) children = this.props.children;
    return <div>

      <div className={"dragDrop-expand " + isSelected} onClick={this.change}>
        <div className="dragDrop-expand-icon">
          <UILIB.Icons icon={"lineArrowRight"} className={this.state.selected ? 'flipRight' : ''} />
        </div>
        <div className="dragDrop-expand-text">{this.props.label}</div>
      </div>
      <div className="expandChildren">
        {children}
      </div>
    </div>
  }
}
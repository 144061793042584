import i18n from "../i18n";
export const REPLY = "reply";
export const NOTE = "note";

export const channelTypes = [
  {
    label: "chat:settings.channels.add.types.email",
    value: 1,
    subheading: "chat:settings.channels.add.types.emailDesc",
  },
  {
    label: "chat:settings.channels.add.types.chat",
    value: 2,
    subheading: "chat:settings.channels.add.types.chatDesc",
  },
  {
    label: "chat:settings.channels.add.types.facebook",
    value: 3,
    subheading: "chat:settings.channels.add.types.facebookDesc",
    beta: 'facebook_messenger'
  },
  // {
  //   label: "SMS",
  //   value: 4,
  // },
  // {
  //   label: "WhatsApp",
  //   value: 5,
  // },
];

export function parseChannelType(value) {
  const channel = channelTypes.find((t) => t.value === value);

  if (channel) return i18n.t(channel.label);

  return null;
}

export const aiProviders = [
  { label: "Document360", value: "document360" },
  { label: "Freshdesk", value: "freshdesk" },
  { label: "Helpscout", value: "helpscout" },
  { label: "Intercom", value: "intercom" },
  { label: "Stonly", value: "stonly" },
  { label: "Zendesk", value: "zendesk" },
  { label: "Wordpress Blog", value: "wordpressBlog" },
  { label: "Other", value: "manual" },
];

export const inboxTabOptions = [
  {
    label: "chat:settings.nav.general",
    value: "general",
    subheading: "chat:settings.nav.generalDesc",
  },
  {
    label: "chat:settings.nav.channels",
    value: "channels",
    subheading: "chat:settings.nav.channelsDesc",
  },
  {
    label: "chat:settings.nav.ai",
    value: "ai-assistants",
    subheading: "chat:settings.nav.aiDesc",
  },
  {
    label: "chat:settings.nav.agents",
    value: "agents",
    subheading: "chat:settings.nav.agentsDesc"
  },
  {
    label: "chat:settings.nav.replies",
    value: "templates",
    subheading: "chat:settings.nav.repliesDesc",
  },
  {
    label: "chat:settings.nav.fields",
    value: "fields",
    subheading: "chat:settings.nav.fieldsDesc",
    tierPermission: "chatCustomFields"
  },
];

export const emailChannelTabOptions = [
  {
    label: "chat:channel.email.nav.general",
    value: "general",
    subheading: "chat:channel.email.nav.generalDesc"
  },
  {
    label: "chat:channel.email.nav.forwarding",
    value: "forwarding",
    subheading: "chat:channel.email.nav.forwardingDesc"
  },
  {
    label: "chat:channel.email.nav.signature",
    value: "signature",
    subheading: "chat:channel.email.nav.signatureDesc"
  }
];

export const webchatChannelTabOptions = [
  {
    label: "chat:channel.chat.nav.general",
    value: "general",
    subheading: "chat:channel.chat.nav.generalDesc"
  },
  {
    label: "chat:channel.chat.nav.websites",
    value: "websites",
    subheading: "chat:channel.chat.nav.websitesDesc",
  },
  {
    label: "chat:channel.chat.nav.design",
    value: "design",
    subheading: "chat:channel.chat.nav.designDesc",
    view: 'split'
  },
  {
    label: "chat:channel.chat.nav.visibility",
    value: "visibility",
    subheading: "chat:channel.chat.nav.visibilityDesc",
  },
  {
    label: "chat:channel.chat.nav.availability",
    value: "agent-availability",
    subheading: "chat:channel.chat.nav.availabilityDesc",
  },
  {
    label: "chat:channel.chat.nav.availabilityMessages",
    value: "availability-messages",
    subheading: "chat:channel.chat.nav.availabilityMessagesDesc",
  },
  {
    label: "chat:channel.chat.nav.welcomeMessages",
    value: "welcome-messages",
    subheading: "chat:channel.chat.nav.welcomeMessagesDesc",
  },
  {
    label: "chat:channel.chat.nav.prompts",
    value: "prompts",
    subheading: "chat:channel.chat.nav.promptsDesc",
  },
];

export const facebookMessengerChannelTabOptions = [
  {
    label: "chat:channel.facebook.nav.general",
    value: "general",
    subheading: "chat:channel.facebook.nav.generalDesc"
  },
  {
    label: "chat:channel.facebook.nav.visibility",
    value: "visibility",
    subheading: "chat:channel.facebook.nav.visibilityDesc"
  },
  {
    label: "chat:channel.facebook.nav.welcome",
    value: "welcome-messages",
    subheading: "chat:channel.facebook.nav.welcomeDesc"
  }
]


export const aiAssistantTabOptions = [
  {
    label: "chat:ai.nav.overview",
    value: "overview",
    subheading: null,
    hideHeader: true
  },
  {
    label: "chat:ai.nav.sources",
    value: "sources",
    subheading: "chat:ai.nav.sourcesSub"
  },
  {
    label: "chat:ai.nav.articles",
    value: "articles",
    subheading: "chat:ai.nav.articlesSub"
  },
  {
    label: "chat:ai.nav.channels",
    value: "channels",
    subheading: "chat:ai.nav.channelsSub"
  },
  {
    label: "chat:ai.nav.settings",
    value: "settings",
    subheading: "chat:ai.nav.settingsSub"
  },
  {
    label: "chat:ai.nav.history",
    value: "history",
    subheading: "chat:ai.nav.historySub"
  },
  {
    label: "chat:ai.nav.test",
    value: "test",
    subheading: "chat:ai.nav.testSub"
  },
];

export const agentRoles = [
  {
    label: "Agent",
    value: 1,
    subheading: "Manage and respond to conversations, but not change settings..",
    maxChats: true
  },
  {
    label: "Admin",
    value: 2,
    subheading: "Full access to this Inbox, including all settings.",
    maxChats: true
  },
  {
    label: "Viewer",
    value: 3,
    subheading:
      "Able to view conversations and add notes, but not respond directly to customers or change settings.",
  },
];

export function parseAgentRole(value) {
  const role = agentRoles.find((t) => t.value === value);

  if (role) return role.label;

  return null;
}
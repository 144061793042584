import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import DateFormat from '~/Classes/dateTimeFunctions'
import moment from 'moment'
import i18n from '~/i18n'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we
import ViewTransactionDrawer from '~/pages/cp/includes/transactions/viewTransactionDrawer'
import queryString from 'query-string'

@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

class Transactions extends Component {
    constructor(props) {
        super(props)
        let timer = null;
        let exportTimer = null;
        this.state = {
            loading: 1,
            total: 0,
            orderBy: 't.id',
            orderDir: 'DESC',
            pageSize: 50,
            page: 0,
            startDate: moment().subtract(30, 'days'),
            endDate: moment(),
            transactions: [],
            filterOption: { label: i18n.t('campaigns:transactions.filterOptions.all'), value: 0 },
            filterOptions: [
                { label: i18n.t('campaigns:transactions.filterOptions.all'), value: "all" },
                { label: i18n.t('campaigns:transactions.filterOptions.sent'), value: "sent" },
                { label: i18n.t('campaigns:transactions.filterOptions.opened'), value: "opened" },
                { label: i18n.t('campaigns:transactions.filterOptions.clicked'), value: "clicked" },
                { label: i18n.t('campaigns:transactions.filterOptions.bounced'), value: "bounced" },
                { label: i18n.t('campaigns:transactions.filterOptions.errored'), value: "errored" },
            ],
            type: { label: i18n.t('campaigns:transactions.filterOptions.allTransactions'), value: "all" },
            types: [
                { label: i18n.t('campaigns:transactions.filterOptions.allTransactions'), value: 0 },
                { label: i18n.t('campaigns:transactions.filterOptions.email'), value: 1 },
                { label: i18n.t('campaigns:transactions.filterOptions.sms'), value: 2 },
            ],
            showBots: false,
            exporting: false,
            exportJobId: null
        }
        this.getResults = this.getResults.bind(this)
        this.changePage = this.changePage.bind(this)
        this.updateDate = this.updateDate.bind(this)
        this.updateSearch = this.updateSearch.bind(this)
        this.changeFilter = this.changeFilter.bind(this);
        this.changePageSize = this.changePageSize.bind(this)
        this.showTransaction = this.showTransaction.bind(this);
        this.retryTrans = this.retryTrans.bind(this);
        this.changeType = this.changeType.bind(this)
        this.manualSearch = this.manualSearch.bind(this)
        this.startExport = this.startExport.bind(this);
        this.monitorExport = this.monitorExport.bind(this);
    }

    componentDidMount() {
        const query = queryString.parse(window.location.search)
        if (query.type) {
            this.setState({
                type: this.state.types.find(t => t.value == query.type) || this.state.type
            }, this.getResults)
        } else {
            this.getResults()
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
        clearTimeout(this.exportTimer)
    }
    changePage(page, dir, prop) {
        var obj = {}
        if (page !== null) obj.page = page
        if (dir !== null) obj.orderDir = dir
        if (prop !== null) obj.orderBy = prop
        this.setState(obj, this.manualSearch)
    }

    updateDate(dates) {
        this.setState({
            startDate: dates.startDate,
            endDate: dates.endDate,
            page: 0
        }, this.manualSearch)
    }

    updateSearch(ev) {
        this.setState({
            searchText: ev.currentTarget.value,
            page: 0
        }, () => {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(this.manualSearch, 500)
        })
    }


    showTransaction(transactionId) {
        var drawerContent = <ViewTransactionDrawer transactionId={transactionId} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    retryTrans(trans) {

        axios.get(`/transaction/${trans.id}`).then(_trans => {

            _trans = _trans.data;
            var trackOpens = false;
            var trackLinks = false;
            var overwrite_envelope_from = false;
            if (_trans.options) {
                trackOpens = _trans.options.trackOpens;
                trackLinks = _trans.options.trackLinks;
                overwrite_envelope_from = _trans.options.overwrite_envelope_from;
            }
            var newTrans = {
                fromName: _trans.fromName,
                fromAddress: _trans.fromAddress,
                replyAddress: _trans.replyAddress,
                toAddress: _trans.toAddress,
                subject: _trans.subject,
                html: _trans.html,
                TemplateId: _trans.TemplateId,
                toName: _trans.toName,
                attachments: _trans.attachments,
                SubscriberId: _trans.SubscriberId,
                dontTrackLinks: trackLinks,
                trackOpens,
                trackLinks,
                overwrite_envelope_from
            }
            if (trans.type === 2) {
                newTrans.content = _trans.html
                delete newTrans.html
                return axios.post(`/transaction/sms`, newTrans)
            } else {
                return axios.post(`/transaction`, newTrans)
            }
        }).then(this.manualSearch)
    }

    manualSearch() {
        this.setState({ loading: true }, this.getResults)
    }


    async getResults(doExport) {
        if (this.timer) clearTimeout(this.timer)
        var queryString = `?limit=${this.state.pageSize}&page=${this.state.page}&orderBy=${this.state.orderBy}&orderDir=${this.state.orderDir}`
        if (this.state.startDate) queryString += '&start=' + this.state.startDate.toDate()
        if (this.state.endDate) queryString += '&end=' + this.state.endDate.endOf('day').toDate()
        if (this.state.searchText) queryString += '&search=' + encodeURIComponent(this.state.searchText)
        if (this.state.filterOption) queryString += "&filter=" + this.state.filterOption.value
        if (this.state.type) queryString += "&type=" + this.state.type.value
        if (this.state.showBots) queryString += "&bots=" + this.state.showBots

        try {

            let response = await axios.get('/transaction' + queryString)

            this.setState({
                loading: false,
                total: Number(response.data.total ? response.data.total.count : "0"),
                transactions: response.data.Transactions.map(row => {

                    let emailTypeIcon
                    if (row.type === 1) {
                        emailTypeIcon = <UILIB.Icons icon="envelope" title="Email" alt="Email" style={{ width: "20px", height: "20px" }} />
                    } else if (row.type === 2) {
                        emailTypeIcon = <UILIB.Icons icon="mobile" title="SMS" alt="SMS" style={{ width: "20px", height: "20px" }} />
                    }
                    var tableData = {
                        emailTypeIcon: {
                            headerValue: '',
                            value: emailTypeIcon,
                            orderBy: false,
                            width: 40,
                            align: 'center'
                        },
                        't.id': {
                            headerValue: "Created",
                            value: DateFormat.formatDateTime(row.createdAt),
                            orderBy: true,
                            width: 150
                        },
                        sendDate: {
                            headerValue: i18n.t('campaigns:transactions.sendDate'),
                            value: row.sendDate ? DateFormat.formatDateTime(row.sendDate) : <div>
                                {Boolean(row.failed) && <div className="text-red">{row.error}</div>}
                            </div>,
                            orderBy: true,
                            width: 150
                        },
                        sent: {
                            headerValue: i18n.t('campaigns:transactions.sent'),
                            value: row.sent == 1 || row.send === true ? <UILIB.CheckBox checked={true} disabled={true} className="green" onClick={() => this.showTransaction(row)} /> : <UILIB.CheckBox checked={false} disabled={true} onClick={() => this.showTransaction(row)} />,
                            orderBy: true,
                            align: "center"
                        },
                        openCount: {
                            headerValue: i18n.t('campaigns:transactions.opened'),
                            value: row.openCount > 0 ? <UILIB.CheckBox checked={true} disabled={true} className="green" onClick={() => this.showTransaction(row)} /> : <UILIB.CheckBox checked={false} disabled={true} onClick={() => this.showTransaction(row)} />,
                            orderBy: true,
                            align: "center"
                        },
                        clickCount: {
                            headerValue: i18n.t('campaigns:transactions.clicked'),
                            value: row.clickCount > 0 ? <UILIB.CheckBox checked={true} disabled={true} onClick={() => this.showTransaction(row)} /> : <UILIB.CheckBox checked={false} disabled={true} onClick={() => this.showTransaction(row)} />,
                            orderBy: true,
                            align: "center"
                        },
                        bounced: {
                            headerValue: i18n.t('campaigns:transactions.bounced'),
                            value: (row.bounced > 0 || row.sent === -1) ?
                                <UILIB.CheckBox checked={true} disabled={true} className="red" onClick={() => this.showTransaction(row)} />
                                : <UILIB.CheckBox checked={false} disabled={true} onClick={() => this.showTransaction(row)} />,
                            orderBy: true,
                            align: "center"
                        },
                        bot: {
                            headerValue: "Bot",
                            value: <UILIB.CheckBox checked={row.bot == 1} disabled={true} />
                        },
                        fromAddress: {
                            headerValue: i18n.t('campaigns:transactions.fromAddress'),
                            value: row.fromAddress,
                            orderBy: true
                        },
                        toAddress: {
                            headerValue: i18n.t('campaigns:transactions.toAddress'),
                            value: row.toAddress,
                            orderBy: true
                        },
                        subject: {
                            headerValue: i18n.t('campaigns:transactions.subject'),
                            value: row.subject,
                            orderBy: false
                        },

                        optionsCol:
                        {
                            headerValue: " ",
                            value: <UILIB.OptionsDropdown popWidth="150px">
                                <ul>
                                    <li>
                                        <a onClick={() => { this.showTransaction(row.id) }}>{i18n.t('View')}</a>
                                    </li>
                                    <li>
                                        <a onClick={() => this.retryTrans(row)}>{i18n.t('Re-Send')}</a>
                                    </li>

                                </ul>

                            </UILIB.OptionsDropdown>,
                            orderBy: false,
                            fixed: true,
                            width: 20

                        }

                    }

                    return tableData;
                })
            }, () => {
                this.timer = setTimeout(this.getResults, 5000)
            })

        }
        catch (err) {
            this.timer = setTimeout(this.getResults, 5000)
        }
    }

    changeFilter(index) {
        var newFilter = this.state.filterOptions[index];
        this.setState({ filterOption: newFilter, page: 0 }, this.manualSearch)
    }

    changeType(index) {
        var newFilter = this.state.types[index];
        this.setState({ type: newFilter, page: 0 }, this.manualSearch)
    }

    changePageSize(size) {
        this.setState({
            pageSize: size
        }, this.manualSearch)
    }

    async startExport() {
        clearTimeout(this.exportTimer)
        let exportJob = await axios.post('/transaction/export', {
            start: this.state.startDate,
            end: this.state.endDate,
            search: this.state.searchText,
            filter: this.state.filterOption.value,
            type: this.state.type.value,
            bots: this.state.showBots
        });
        this.setState({ exporting: true, exportJobId: exportJob.data.id }, this.monitorExport)
    }

    async monitorExport() {
        let exportJob = await axios.get(`/reports/${this.state.exportJobId}`);
        if (exportJob.data.Report.status === "finished") {
            let finalReport = await axios.get('/reports/' + this.state.exportJobId + '/file')
            const link = document.createElement('a');
            link.href = finalReport.data.url
            link.setAttribute('download', finalReport.name + '.xlsx')
            document.body.appendChild(link);
            link.click();
            this.setState({ exporting: false, exportJobId: null })
        } else {
            this.exportTimer = setTimeout(this.monitorExport, 2000)
        }
    }

    render() {

        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} sm={12} md={8} lg={9} className="mar-25">
                        <div className='page-header'>{i18n.t('campaigns:transactions.transactions')}</div>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={12} md={4} lg={3} className="end-xs mar-b25">
                        <UILIB.TextInput
                            type="multiDate"
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            updateDate={this.updateDate}></UILIB.TextInput>
                    </UILIB.Column>
                </UILIB.Row>
                <UILIB.Row className="middle-lg middle-md mar-b25">
                    <UILIB.Column xs={12} md={6} >
                        <UILIB.TextInput
                            className="no-marg"
                            clickToChangeClicked={this.getResults}
                            placeholder={i18n.t('campaigns:transactions.searchByEmailAddress')}
                            onChange={this.updateSearch}
                            onEnterPress={this.getResults}
                            iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                        />

                    </UILIB.Column>
                    <UILIB.Column xs={12} md={6} className="text-right">
                        <UILIB.Button
                            text="Export"
                            iconRight={this.state.exporting ? <UILIB.LoadingIcons iconType="2" style={{ width: 20, height: 20 }} /> : <UILIB.Icons icon="download" />}
                            onClick={this.startExport}
                            disabled={this.state.exporting} />
                    </UILIB.Column>
                </UILIB.Row>
                <UILIB.Row >
                    <UILIB.Column xs={12} sm={6} className="mar-b25 quickFlex">

                        {!!this.props.accountMaster.accountMaster.beta && this.props.accountMaster.accountMaster.beta.sms && <UILIB.ButtonSelect className="mar-r10" selected={this.state.type.value} headerText={this.state.type.label} autoClose
                            data={this.state.types.map((type, index) => {
                                return <a key={"ty_" + index} onClick={() => this.changeType(index)}>{type.label}</a>
                            })}
                        />
                        }
                        <UILIB.ButtonSelect
                            selected={this.state.filterOption.value}
                            headerText={this.state.filterOption.label}
                            autoClose
                            data={this.state.filterOptions.map((status, index) => {
                                return <a key={"st_" + index} onClick={() => this.changeFilter(index)}>{status.label}</a>
                            })}
                        />

                        <UILIB.Toggle after={"Show Robot Opens"} checked={this.state.showBots} name="showBots" onChange={(ev) => {
                            let val = ev.target.checked
                            this.setState({ showBots: val, loading: 1 }, this.getResults);

                        }} className="mar-l10"></UILIB.Toggle>

                    </UILIB.Column>
                    <UILIB.Column xs={12} md={6} hide={["xs", "sm"]}  >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <div>{i18n.t('showing')}</div>
                            <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.pageSize === 10} onClick={() => { this.changePageSize(10) }}>10</UILIB.ButtonSimple>
                            <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 50} onClick={() => { this.changePageSize(50) }}>50</UILIB.ButtonSimple>
                            <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                            <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>
                        </div>

                    </UILIB.Column>
                </UILIB.Row>
                <UILIB.Paper isLoading={this.state.loading}>
                    <UILIB.DataTable1
                        noResultsTxt={<span> {i18n.t('campaigns:transactions.noTransactionalEmailsSent')} </span>}
                        tableData={this.state.transactions}
                        dataUpdater={this.changePage}
                        sortedColumn={this.state.orderBy}
                        sortedDirection={this.state.orderDir}
                        width="100%"
                        pageSize={this.state.pageSize} />
                </UILIB.Paper>

                <UILIB.Row>
                    <UILIB.Column xs={12} hide={["xs", "sm"]} className="center-xs">
                        <UILIB.PagingBlock
                            totalRows={this.state.total}
                            pageSize={this.state.pageSize}
                            numberOfLinks="10"
                            currentPage={this.state.page}
                            changePage={this.changePage}
                            text={i18n.t('page') + ":"} />
                    </UILIB.Column>
                </UILIB.Row>
            </div >
        );
    }
}

export default Transactions;
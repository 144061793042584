import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';

export default class Input extends Component {
    constructor(props) {
        super(props)
    }



    render() {
        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "label", this.props.localVariables)
        }
        var thisContent = this.props.elementData.content.value;
        if (thisContent.indexOf("_localVariables_") >= 0)
            thisContent = DragDropFunctions.lookupLocalVariables(this.props.localVariables, thisContent);
        return <button  {...eleProps}>
            {this.props.elementData.content.value ? thisContent : this.props.children}
        </button>



    }
}
import React from 'react'

export default function IconsLinkedInColorRounded({ color = "", style = {} }) {
    let className = "icons-icon";
    if (!style.borderRadius) style.borderRadius = "6px";
    return (
        <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill={color} />
            <path d="M19.9988 19.9734H16.686V14.7853C16.686 13.5482 16.6639 11.9556 14.963 11.9556C13.2376 11.9556 12.9736 13.3035 12.9736 14.6953V19.973H9.66085V9.30429H12.8411V10.7623H12.8856C13.5341 9.6535 14.7399 8.99127 16.0234 9.03892C19.3811 9.03892 20.0002 11.2475 20.0002 14.1207L19.9988 19.9734ZM5.92294 7.84595C4.8612 7.84614 4.00034 6.98557 4.00015 5.92381C3.99996 4.86205 4.86051 4.00117 5.92225 4.00098C6.98399 4.00079 7.84485 4.86136 7.84504 5.92312C7.84513 6.43299 7.64268 6.92202 7.28221 7.28262C6.92175 7.64322 6.4328 7.84586 5.92294 7.84595ZM7.57933 19.9734H4.2631V9.30429H7.57933V19.9734Z" fill="white" />
        </svg>

    )
}
import React, { useState } from 'react'
import UILIB from '../../../Common-Objects/Lib'
import i18n from '../../../i18n'
import moment from 'moment'

const margin = "mar-b10"

export default function ContactFields({ contact, apps, updateCustomField = () => { }, updatePhoneNumber = () => { }, updateEmailAddress = () => { } }) {
    const [expanded, setExpanded] = useState({ other: false })

    const renderCustomField = (field, index) => {
        const fieldIndex = contact.customFields.findIndex(f => f.id == field.id)
        if (field.fieldType === "DATE") {
            if (field.value === null || field.value == "" || !moment(field.value).isValid()) field.value = undefined
            return <UILIB.TextInput outerClassName={margin} key={"cf1_" + index} disabled={field.readOnly} label={field.fieldDesc} type={'datetime'} id={field.fieldName} value={field.value} onChange={(ev, val) => updateCustomField(fieldIndex, val)} />
        }
        if (field.fieldType === 'BOOLEAN') {
            return <UILIB.CheckBox outerClassName={margin} key={"cf2_" + index} disabled={field.readOnly} checked={field.value} onChange={ev => updateCustomField(fieldIndex, ev.target.checked)} name={field.fieldName}>{field.fieldDesc}</UILIB.CheckBox>
        }
        if (field.fieldType === 'SELECT') {
            var theOpts = JSON.parse(JSON.stringify(field.options))
            if (!theOpts) theOpts = [];
            theOpts.unshift({ value: "", label: "" })
            return <UILIB.Select outerClassName={margin} explainer={"Current Value: " + field.value} key={"cf3_" + index} disabled={field.readOnly} label={field.fieldDesc} value={field.value} onChange={ev => updateCustomField(fieldIndex, ev.currentTarget.value)} name={field.fieldName} data={theOpts} />
        }

        if (field.fieldType === 'MULTISELECT' && Array.isArray(field.options)) {
            var theOpts = JSON.parse(JSON.stringify(field.options))
            if (!theOpts) theOpts = [];

            var currVals = field.value;
            if (!Array.isArray(currVals)) currVals = [currVals];

            var multiHeader = "";

            var newVals = currVals.map((v, i) => {

                theOpts.forEach(tO => {
                    if (tO.value == v) {
                        tO.selected = true;
                        multiHeader += tO.label + ", ";
                    }
                })
                return v
            })
            if (multiHeader != "") {
                multiHeader = multiHeader.substr(0, multiHeader.length - 2)
            }
            if (multiHeader == "") {
                multiHeader = i18n.t('subscribers:index.clickHereToSelect')
            }

            currVals = newVals.join(", ");

            return <div className="form-group" key={"cf3_" + index}>

                <UILIB.ButtonSelect
                    fixedHeight={true}
                    data={theOpts.map((theOpt) => {
                        return <UILIB.CheckBox key={theOpt.value} name={theOpt.label} onChange={() => updateCustomField(fieldIndex, theOpt, 'multiselect', theOpts)} checked={theOpt.selected} >
                            {theOpt.label}
                        </UILIB.CheckBox>
                    })}
                    explainer={"Current Value(s): " + currVals}
                    label={field.fieldDesc}
                    outerClassName={margin}
                    disabled={field.readOnly}
                    headerText={multiHeader} filter={false}
                >

                    {(!theOpts || !theOpts.length) && <span>There are no values associated with this field</span>}

                </UILIB.ButtonSelect>
            </div>
        }

        if (field.fieldType === 'MULTISELECT' && !Array.isArray(field.options)) {
            field.value = !Array.isArray(field.value) ? [] : field.value
            return <div className="form-group" key={"cf4_" + index}>
                <UILIB.Multi label={field.fieldDesc} values={field.value} outerClassName={margin} disabled={field.readOnly} />
            </div>
        }

        if (field.fieldType === 'TELNO') {
            return <div className="form-group" key={"cf4_" + index}>
                <UILIB.TextInput outerClassName={margin} disabled={field.readOnly} label={field.fieldDesc} style={{ width: '100%' }} type={field.fieldType.toLowerCase()} value={field.value} onChange={ev => updatePhoneNumber(fieldIndex, ev.currentTarget.value, ev.valid)} />
            </div>
        }

        return <div className="form-group" key={"cf4_" + index} id={field.id}>

            <UILIB.TextInput outerClassName={margin} disabled={field.readOnly} label={field.fieldDesc} type={field.fieldType.toLowerCase()} value={field.value} onChange={ev => updateCustomField(fieldIndex, ev.currentTarget.value)} />
        </div>
    }

    const important = contact.customFields.filter(f => f.important)
    const notImportant = contact.customFields.filter(f => !f.important && !f.ApplicationId)
    return (
        <div>
            <UILIB.TextInput outerClassName={margin} label={i18n.t('subscribers:subscriber.email')} name="emailAddress-emailAddress" type="email" headerText={i18n.t('subscribers:subscriber.address') + ':'} onChange={updateEmailAddress} value={contact.emailAddress} />
            {important.map(field => renderCustomField(field, 'important_' + field.id))}

            {!!notImportant.length && <UILIB.Paper style={{ padding: 20, marginTop: 20, maxHeight: 400, overflow: 'auto' }}>
                <div className="quickFlex" style={{ cursor: 'pointer' }} onClick={() => setExpanded(e => ({ ...e, other: !e.other }))}>
                    <UILIB.Icons icon="chevronRight" className={expanded.other ? 'flipRight' : ''} color="black" style={{ marginRight: 12, display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                    <div className='text-heavy'>{important.length ? 'More ' : ''}Custom Fields</div>
                </div>

                {expanded.other && <div className='mar-t10' style={{width: 328}}>
                    {notImportant.map(field => renderCustomField(field, 'notimportant_' + field.id))}
                </div>}
            </UILIB.Paper>}

            {apps.map(app => {
                const fields = contact.customFields.filter(f => f.ApplicationId === app.ApplicationId && !f.important)
                if (!fields.length) return null

                return <UILIB.Paper key={app.ApplicationId} style={{ padding: 20, marginTop: 20, maxHeight: 400, overflow: 'auto' }}>
                    <div className="quickFlex" style={{ cursor: 'pointer' }} onClick={() => setExpanded(e => ({ ...e, [app.ApplicationId]: !e[app.ApplicationId] }))}>
                        <UILIB.Icons icon="chevronRight" className={expanded[app.ApplicationId] ? 'flipRight' : ''} color="black" style={{ marginRight: 12, display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                        <div className='text-heavy'>{app.Application.appName} Fields</div>
                        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row-reverse' }}>
                            <UILIB.Avatar src={app.Application.appLogoUri} style={{ height: 25, width: 25 }} />
                        </div>
                    </div>

                    {expanded[app.ApplicationId] && <div className='mar-t10' style={{width: 328}}>
                        {fields.map(field => renderCustomField(field, app.ApplicationId + '_' + field.id))}
                    </div>}
                </UILIB.Paper>
            })}
        </div>
    )
}
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';

@connect((store) => {
    return {
        user: store.user,
        siteMaster: store.siteMaster
    }
})


class RegisterComplete extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        //fbq('track', 'CompleteRegistration');
    }

    render() {
        var mainMD = 8;
        if (this.props.siteMaster.siteName != "MPZMail" && this.props.siteMaster.siteName != "Transpond") {
            mainMD = 12;
        }


        return (<div className="login-right-panel">
            <div className="login-right-panel-login">
                <UILIB.Paper className="login-right-panel-login_loginBox">
                    <h3 className="mar-b10">Account Registered!</h3>
                    <div className="mar-b40">
                        Welcome to {this.props.siteMaster.siteName}. You can now login and start using the service using login button below. <br /><br />
                        We've also sent you a welcome email. Make sure you click the "Verify" link in the email to start sending campaigns.
                    </div>
                    <UILIB.Button className="button-primary" onClick={this.props.login}>Login</UILIB.Button>
                </UILIB.Paper >
            </div >
        </div >
        )
    }
}
export default RegisterComplete;
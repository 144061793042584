import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import DeleteFolder from './utils/deleteFolder';
import RenameFolder from './utils/renameFolder';
import AddFolder from './utils/addFolder';

class Folders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addingFolder: false,
            newFolderName: '',
            actualFolderName: '',
            renamingFolder: undefined,
            renaming: false,
            deleting: false,
            adding: false
        }

        this.updateValue = this.updateValue.bind(this);
        this.addFolder = this.addFolder.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.deleteFolder = this.deleteFolder.bind(this);
        this.renameFolder = this.renameFolder.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.confirmRename = this.confirmRename.bind(this);
        this.stopAll = this.stopAll.bind(this);
        this.addNewFolder = this.addNewFolder.bind(this);
    }

    addFolder() {
        this.setState({
            adding: true
        })
    }

    addNewFolder(folderName) {
        this.setState({ adding: false },
            this.props.add(folderName));
    }

    updateValue(ev) {
        var html = this.textInput.innerHTML;
        this.setState({
            actualFolderName: html
        })
    }

    keyPress(event) {
        if (event.key == 'Enter') {
            this.props.add(this.state.actualFolderName)
            this.setState({
                addingFolder: false,
                actualFolderName: '',
                folderName: ''
            })
            event.preventDefault()
            return false;
        }
    }

    renameFolder() {
        this.setState({
            renaming: true
        })
    }

    deleteFolder() {
        this.setState({
            deleting: true
        })
    }

    confirmRename(name) {
        this.setState({
            alertMessage: i18n.t('templates:folder.updating'),
            alertOpen: true
        })
        axios.put('/fileManager/' + this.props.currentFolder.folderID,
            {
                folderName: name
            })
            .then(() => {
                this.setState({
                    alertMessage: i18n.t('templates:folder.updated'),
                    alertOpen: true,
                    renaming: false
                })
                this.props.getFolders()
            }).catch(err => {
                this.setState({
                    alertMessage: i18n.t('templates:folder.errorUpdating'),
                    alertOpen: true
                })
                console.log(err)
            })
    }
    confirmDelete() {
        this.setState({
            alertMessage: i18n.t('templates:folder.deleting'),
            alertOpen: true
        })
        axios.delete('/fileManager/' + this.props.currentFolder.folderID)
            .then(() => {
                this.setState({
                    alertMessage: i18n.t('templates:folder.deleted'),
                    alertOpen: true,
                    deleting: false
                })
                this.props.folderSelected(this.props.folders[0])
                this.props.getFolders()
            }).catch(err => {
                this.setState({
                    alertMessage: i18n.t('templates:folder.errorDeleting'),
                    alertOpen: true
                })
                console.log(err)
            })
    }

    stopAll() {
        this.setState({
            renaming: false,
            deleting: false,
            adding: false
        })
    }

    render() {

        return (
            <UILIB.Column hide={['xs', 'sm']} md={4} lg={3} margin={0}>

                <UILIB.Paper className="paper-white" style={{ height: "100%", height: "calc(100vh - 150px)" }}>
                    <h4 className="mar-b25">{i18n.t('templates:folder.yourFolders')}</h4>
                    <div className="filemanager-folder-holder">

                        {this.props.folders.map((folder, index) => {
                            var classes = "filemanager-folder";
                            var selected = this.props.currentFolder && this.props.currentFolder.folderID == folder.folderID;
                            if (selected) classes += " filemanager-folder-selected"
                            return <div key={index} className={classes} key={index} onClick={() => this.props.folderSelected(folder)}>
                                <div className="filemanager-folder-left">
                                    <UILIB.Icons icon="folder" className="mar-r10" />
                                    <div className="filemanager-folder-title">{folder.folderName}</div>
                                </div>
                                {selected && !folder.rootFolder && folder.folderID != "system" && <div className="filemanager-folder-right">
                                    <UILIB.Icons className="mar-r10" icon="pencil" onClick={this.renameFolder} />
                                    <UILIB.Icons icon="bin" onClick={this.deleteFolder} />
                                </div>
                                }
                            </div>
                        })}
                        {this.props.loading && <UILIB.LoadingIcons iconType="2" />}
                        <UILIB.Drawer standalone={true} isOpen={this.state.deleting} startClose={this.stopAll} showClose={true}><DeleteFolder deleteFolder={this.confirmDelete} /></UILIB.Drawer>
                        <UILIB.Drawer standalone={true} isOpen={this.state.renaming} startClose={this.stopAll} showClose={true}><RenameFolder save={this.confirmRename} folder={this.props.currentFolder} /></UILIB.Drawer>
                        <UILIB.Drawer standalone={true} isOpen={this.state.adding} showClose={true} startClose={this.stopAll}><AddFolder save={this.addNewFolder} /></UILIB.Drawer>
                        <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />

                    </div>
                    <UILIB.Button
                        className="button-primary"
                        text={i18n.t('templates:fileManager.addFolder')}
                        iconRight={<UILIB.Icons icon="plus" />}
                        onClick={this.addFolder}

                    />
                </UILIB.Paper>

            </UILIB.Column >

        );
    }
}

export default Folders;
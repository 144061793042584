import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import axios from '~/data/http/axios';
import SiteVars from '~/Classes/siteVars';

@connect((store) => {
    return { accountMaster: store.accountMaster }
})
class CopyTemplate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            forms: []
        }
    }

    componentDidMount() {

        axios.get('/template/forms').then(response => {
            this.setState({
                loaded: true,
                forms: response.data.Forms
            })
        })
    }
    render() {
        if (!this.state.loaded) return <div></div>
        return (
            <div className="campaign-holder">
                <UILIB.Row>
                    {this.state.forms.map((form, index) => {
                        return <UILIB.Column xs={12} key={index} className="themes-advanced">
                            <UILIB.Row className="theme-holder" onClick={() => this.props.onSelect(form, this.props.editorType)}>
                                <UILIB.Column xs={12} className="text-primary center-xs mar-t10">
                                    {form.formName}
                                </UILIB.Column>
                                <UILIB.Column xs={12} className="center-xs">
                                    <img width="200" src={SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + form.formConfirmationTemplateId + ".jpg"} />
                                </UILIB.Column>
                            </UILIB.Row>
                        </UILIB.Column>
                    })}
                </UILIB.Row>
            </div>
        );
    }
}

export default CopyTemplate;
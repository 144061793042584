import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import DateTimeFunctions from '~/Classes/dateTimeFunctions'
import IncDisconnect from './incDisconnect';

import ChangeGroup from './changeGroup';
import ApplicationTransactionsGraph from '../components/applicationTransactionsGraph'
import FieldMapping from './fields'
import ConfigureActions from './actions'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class CapsuleConfig extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            isLoaded: false,
            dynamicsSite: {},
            appData: {},
            appBinding: {},
            groupData: [],
            contactsGroupId: -1,
            leadsGroupId: -1,
            importing: false,
            importId: -1,
            importProgress: 0
        };

        this.getDynamicsSite = this.getDynamicsSite.bind(this);
        this.getApp = this.getApp.bind(this);
        this.getAppBinding = this.getAppBinding.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.checkForImportUpdate = this.checkForImportUpdate.bind(this);
        this.resetGroup = this.resetGroup.bind(this);
        this.popDisconnect = this.popDisconnect.bind(this);
        this.goDisconnect = this.goDisconnect.bind(this);
        this.fieldMapping = this.fieldMapping.bind(this)
        this.configActions = this.configActions.bind(this)
    }

    componentDidMount() {
        this.getApp();
    }

    componentWillUnmount() {
        if (this.theTimer) clearTimeout(this.theTimer)
    }

    getApp() {
        var self = this;
        axios.get("/application/17").then(_theApp => {
            self.setState({ appData: _theApp.data.Application }, self.getAppBinding)
        })
    }

    getAppBinding() {
        var self = this;
        axios.get("/application/account/app/" + this.state.appData.id).then(_theResp => {
            var contactsGroupId = -1;
            var leadsGroupId = -1;

            if (_theResp.data.Application.appSettings && _theResp.data.Application.appSettings.contactsGroupId) contactsGroupId = _theResp.data.Application.appSettings.contactsGroupId;
            if (_theResp.data.Application.appSettings && _theResp.data.Application.appSettings.leadsGroupId) leadsGroupId = _theResp.data.Application.appSettings.leadsGroupId;

            self.setState({ appBinding: _theResp.data.Application, contactsGroupId: contactsGroupId, leadsGroupId: leadsGroupId }, self.getDynamicsSite)
        })
    }

    getDynamicsSite() {
        var self = this;
        axios.get("/application/dynamics/site").then(_theSite => {
            self.setState({ dynamicsSite: _theSite.data }, self.getGroups)
        }).catch(err => {
            this.props.history.push('/cp/company/integrations')
        })
    }

    getGroups() {
        var self = this;
        axios.get("/group").then(_theGroups => {
            self.setState({ groupData: _theGroups.data.Groups, isLoaded: true })
        })
    }


    resetGroup() {
        this.setState({ contactsGroupId: -1, leadsGroupId: -1 })
    }
    updateGroup(contactsGroupId, leadsGroupId) {
        var self = this;
        var appBinding = this.state.appBinding;
        if (!appBinding.appSettings) appBinding.appSettings = {};

        appBinding.appSettings.contactsGroupId = contactsGroupId;
        appBinding.appSettings.leadsGroupId = leadsGroupId;


        axios.put("/application/account/" + this.state.appBinding.id, this.state.appBinding).then(_theRes => {

            //need to re-enable below once importing is done
            // self.setState({ contactsGroupId: contactsGroupId, leadsGroupId: leadsGroupId }, this.getAppBinding());

            return axios.get("/application/dynamics/setupGroupAndImport?forceResync=true")
        }).then(_res => {
            self.setState({ contactsGroupId: contactsGroupId, leadsGroupId: leadsGroupId, importing: true, importId: _res.data.contactsJob.id }, () => {
                self.checkForImportUpdate();
                self.getGroups();
            });

        })

    }

    checkForImportUpdate() {
        var self = this;
        axios.get('/group/' + this.state.leadsGroupId + '/import/' + this.state.importId).then(res => {
            self.setState({ importProgress: res.data.PercentComplete })
            if (res.data.PercentComplete < 100) {
                this.theTimer = setTimeout(this.checkForImportUpdate, 1000);
            }
            else {
                self.setState({ importing: false, importProgress: 100 }, this.getAppBinding)
            }
        })
    }

    popDisconnect() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <IncDisconnect goDisconnect={this.goDisconnect} />, true, "500px"));
    }

    goDisconnect() {
        var self = this;
        axios.get('/application/dynamics/disconnect').then(res => {
            self.props.history.push('/cp/company/integrations')
        })
    }

    fieldMapping() {
        var theDrawer = <FieldMapping app={this.state.appBinding} onClose={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right"));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '100vw'));
    }


    configActions() {
        var theDrawer = <ConfigureActions app={this.state.appBinding} onClose={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right"));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '600px'));
    }

    render() {

        if (!this.state.isLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>
        var currentContactsGroup = this.state.groupData.find(theRow => theRow.id == this.state.contactsGroupId);
        var currentLeadsGroup = this.state.groupData.find(theRow => theRow.id == this.state.leadsGroupId)
        if (!currentLeadsGroup || !currentContactsGroup || this.state.contactsGroupId == '' || this.state.contactsGroupId == '-1' || this.state.leadsGroupId == '' || this.state.leadsGroupId == '-1') {
            return <ChangeGroup appBinding={this.state.appBinding} updateGroup={this.updateGroup} />
        }

        return <div>

            <UILIB.Row className="middle-xs mar-b10">
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="col-md-offset-2">
                    <div className="quickFlex">
                        <div className="integration-avatar rounded" style={{ backgroundImage: "url(" + this.state.appData.appLogoUri + ")" }}></div>
                        <p className="no-marg pad-left">Dynamics CRM. Connected as "{this.state.dynamicsSite.name}"</p>
                    </div>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="end-xs">
                    <p className="no-marg">
                        <UILIB.Button className="button-sml grey" onClick={this.popDisconnect}>
                            Disconnect
                        </UILIB.Button>
                    </p>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={8} lg={8} className="col-md-offset-2">
                    <UILIB.Paper>
                        <h4 className="mar-b25">Contacts and Leads</h4>
                        <div className="mar-b25">Dynamics is currently connected and is automatically syncing your contacts and leads with the following groups. If you add, update or delete a contact on Dynamics it is automatically updated here.</div>
                        <UILIB.Row >
                            <UILIB.Column xs={12} sm={6}>
                                <div className="text-md mar-b10">Contacts</div>
                                <div className="chip smallChip secondary" onClick={() => { this.props.history.push('/cp/groups/' + this.state.contactsGroupId); }}>{currentContactsGroup.groupName}</div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6}>
                                <div className="text-md mar-b10">Leads</div>
                                <div className="chip smallChip secondary" onClick={() => { this.props.history.push('/cp/groups/' + this.state.leadsGroupId); }}>{currentLeadsGroup.groupName}</div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={12}>
                                {!this.state.importing && this.state.appBinding.appSettings && this.state.appBinding.appSettings.lastSynced && <div className="text-xsml mar-t20 mar-b10">Last synced: {DateTimeFunctions.formatDateTime(this.state.appBinding.appSettings.lastSynced, 1)} - <a onClick={() => { this.updateGroup(this.state.contactsGroupId, this.state.leadsGroupId) }}>Re-sync</a></div>}
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row>
                            <UILIB.Column xs={12} md={4}>
                                <UILIB.Button className="full-width button-md outline primary" onClick={this.resetGroup}>Change Groups</UILIB.Button>
                            </UILIB.Column>
                            <UILIB.Column xs={12} md={4}>
                                <UILIB.Button className="full-width button-md outline primary" onClick={this.fieldMapping}>Configure Fields</UILIB.Button>
                            </UILIB.Column>
                            <UILIB.Column xs={12} md={4}>
                                <UILIB.Button className="full-width button-md outline primary" onClick={this.configActions}>Configure Actions</UILIB.Button>
                            </UILIB.Column>
                        </UILIB.Row>
                        {this.state.importing && <div style={{ marginTop: "10px" }}><p>Performing Sync</p> <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.importProgress} val2={100} /></div>}
                    </UILIB.Paper>
                </UILIB.Column>

            </UILIB.Row>

            <UILIB.Row className="mar-b60">
                <UILIB.Column xs={12} sm={12} md={8} lg={8} className="col-md-offset-2">
                    <UILIB.Paper className="height-100 mar-b0">
                        <UILIB.Row>
                            <UILIB.Column xs={12}>
                                <h4 className="mar-b25">Automation</h4>
                                <div className="mar-b25">Automations are a great way to automatically make things happen based on your contacts activity. For example, you could send a welcome email when someone gets added to Dynamics.</div>
                                <UILIB.Button text="Add an Automation" onClick={() => { this.props.history.push('/cp/automation') }} />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
        </div>

    };


};



import React, { Component, Fragment } from 'react';
import RatingStarsExplainer from './ratingStarsExplainer.jsx'

import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

import UILIB from '../../Lib';

@connect((store) => {
    return { user: store.user, permissionStore: store.permission }
})

class Score extends Component {
    constructor(props) {
        super(props);

        this.showRules = this.showRules.bind(this);
    }


    showRules() {
        if (this.props.hideRules) return
        var drawerContent = <RatingStarsExplainer />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "700px"));
    }

    render() {
        const row = this.props;
        const blankColor = "#A9A9AD";
        const solidColor = "#FF9425";
        let stars = [
            <UILIB.Icons key={0} icon="star" color={blankColor} className="star-rating-star star-rating-star-first" />,
            <UILIB.Icons key={1} icon="star" color={blankColor} className="star-rating-star" />,
            <UILIB.Icons key={2} icon="star" color={blankColor} className="star-rating-star" />,
            <UILIB.Icons key={3} icon="star" color={blankColor} className="star-rating-star" />,
            <UILIB.Icons key={4} icon="star" color={blankColor} className="star-rating-star" />
        ];
        if (row.score && row.score < 10) stars = [
            <UILIB.Icons key={0} icon="starSolid" color={solidColor} className="star-rating-star star-rating-star-first" />,
            <UILIB.Icons key={1} icon="star" color={blankColor} className="star-rating-star" />,
            <UILIB.Icons key={2} icon="star" color={blankColor} className="star-rating-star" />,
            <UILIB.Icons key={3} icon="star" color={blankColor} className="star-rating-star" />,
            <UILIB.Icons key={4} icon="star" color={blankColor} className="star-rating-star" />
        ];
        if (row.score >= 10 && row.score < 20) stars = [
            <UILIB.Icons key={0} icon="starSolid" color={solidColor} className="star-rating-star star-rating-star-first" />,
            <UILIB.Icons key={1} icon="starSolid" color={solidColor} className="star-rating-star" />,
            <UILIB.Icons key={2} icon="star" color={blankColor} className="star-rating-star" />,
            <UILIB.Icons key={3} icon="star" color={blankColor} className="star-rating-star" />,
            <UILIB.Icons key={4} icon="star" color={blankColor} className="star-rating-star" />
        ];
        if (row.score >= 20 && row.score < 30) stars = [
            <UILIB.Icons key={0} icon="starSolid" color={solidColor} className="star-rating-star star-rating-star-first" />,
            <UILIB.Icons key={1} icon="starSolid" color={solidColor} className="star-rating-star" />,
            <UILIB.Icons key={2} icon="starSolid" color={solidColor} className="star-rating-star" />,
            <UILIB.Icons key={3} icon="star" color={blankColor} className="star-rating-star" />,
            <UILIB.Icons key={4} icon="star" color={blankColor} className="star-rating-star" />
        ];
        if (row.score >= 30 && row.score < 40) stars = [
            <UILIB.Icons key={0} icon="starSolid" color={solidColor} className="star-rating-star star-rating-star-first" />,
            <UILIB.Icons key={1} icon="starSolid" color={solidColor} className="star-rating-star" />,
            <UILIB.Icons key={2} icon="starSolid" color={solidColor} className="star-rating-star" />,
            <UILIB.Icons key={3} icon="starSolid" color={solidColor} className="star-rating-star" />,
            <UILIB.Icons key={4} icon="star" color={blankColor} className="star-rating-star" />
        ];
        if (row.score >= 40) stars = [
            <UILIB.Icons key={0} icon="starSolid" color={solidColor} className="star-rating-star star-rating-star-first" />,
            <UILIB.Icons key={1} icon="starSolid" color={solidColor} className="star-rating-star" />,
            <UILIB.Icons key={2} icon="starSolid" color={solidColor} className="star-rating-star" />,
            <UILIB.Icons key={3} icon="starSolid" color={solidColor} className="star-rating-star" />,
            <UILIB.Icons key={4} icon="starSolid" color={solidColor} className="star-rating-star" />
        ];

        let className = "star-rating "
        if (this.props.className) className += this.props.className

        return <div className={className} onClick={this.showRules}>
            {stars}
        </div>

    }
}

export default Score;
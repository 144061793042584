import React from 'react'

export default class ConversationsPromptsNewConv extends React.Component {

    render() {
        let color = "#865ff1";
        if (this.props.color) color = this.props.color;

        return <svg width="85" height="72" style={this.props.style} viewBox="0 0 85 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.25 4.25H78.75V60C78.75 64.2802 75.2802 67.75 71 67.75H6.25V4.25Z" fill="white" stroke="url(#paint0_radial_2548_23565)" strokeWidth="0.5" />
            <g filter="url(#filter0_d_2548_23565)">
                <rect x="6" y="4" width="64" height="55" rx="8" fill="white" />
                <rect x="6.25" y="4.25" width="63.5" height="54.5" rx="7.75" stroke="#B5BAC0" strokeWidth="0.5" />
                <mask id="path-4-inside-1_2548_23565" fill="white">
                    <path d="M6 4H70V19H6V4Z" />
                </mask>
                <path d="M6 4H70V19H6V4Z" fill="#EEF1F5" />
                <path d="M70 18.5H6V19.5H70V18.5Z" fill="#B5BAC0" mask="url(#path-4-inside-1_2548_23565)" />
                <rect x="11.25" y="44.25" width="53.5" height="9.5" rx="3.75" fill="#F4F6FA" stroke="#B5BAC0" strokeWidth="0.5" />
                <path d="M20.5 24.5H55C56.933 24.5 58.5 26.067 58.5 28V30C58.5 31.933 56.933 33.5 55 33.5H24C22.067 33.5 20.5 31.933 20.5 30V24.5Z" stroke={color} />
                <rect x="24" y="28" width="13" height="2" rx="1" fill={color} />
                <rect x="39" y="28" width="5" height="2" rx="1" fill={color} />
                <rect x="46" y="28" width="7" height="2" rx="1" fill={color} />
                <path d="M20.5 24.5H55C56.933 24.5 58.5 26.067 58.5 28V30C58.5 31.933 56.933 33.5 55 33.5H24C22.067 33.5 20.5 31.933 20.5 30V24.5Z" fill={color} stroke={color} />
                <path d="M25 29H34" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
                <path d="M38 29H43" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
                <path d="M47 29H54" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 27C17 27.7956 16.6839 28.5587 16.1213 29.1213C15.5587 29.6839 14.7956 30 14 30C13.2044 30 12.4413 29.6839 11.8787 29.1213C11.3161 28.5587 11 27.7956 11 27C11 26.2044 11.3161 25.4413 11.8787 24.8787C12.4413 24.3161 13.2044 24 14 24C14.7956 24 15.5587 24.3161 16.1213 24.8787C16.6839 25.4413 17 26.2044 17 27ZM14.8571 26.1429C14.8571 26.3702 14.7668 26.5882 14.6061 26.7489C14.4453 26.9097 14.2273 27 14 27C13.7727 27 13.5547 26.9097 13.3939 26.7489C13.2332 26.5882 13.1429 26.3702 13.1429 26.1429C13.1429 25.9155 13.2332 25.6975 13.3939 25.5368C13.5547 25.376 13.7727 25.2857 14 25.2857C14.2273 25.2857 14.4453 25.376 14.6061 25.5368C14.7668 25.6975 14.8571 25.9155 14.8571 26.1429ZM14 27.4286C13.2179 27.4286 12.5334 27.8473 12.1593 28.473C12.38 28.7492 12.6601 28.9721 12.9787 29.1252C13.2974 29.2782 13.6465 29.3575 14 29.3571C14.3535 29.3575 14.7024 29.2781 15.021 29.1251C15.3396 28.972 15.6196 28.7491 15.8403 28.473C15.6503 28.1544 15.3807 27.8907 15.0581 27.7076C14.7355 27.5245 14.3709 27.4284 14 27.4286Z" fill="#7C8086" />
                <path d="M15 9L12.5 11.5L15 14" stroke="#7C8086" />
            </g>
            <defs>
                <filter id="filter0_d_2548_23565" x="4" y="2.5" width="68" height="59" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="0.5" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0941176 0 0 0 0 0.0980392 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2548_23565" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2548_23565" result="shape" />
                </filter>
                <radialGradient id="paint0_radial_2548_23565" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(79.5573 68.5872) rotate(-138.715) scale(97.8886 118.602)">
                    <stop stop-color="#9B9FA6" />
                    <stop offset="0.494792" stop-color="#9B9FA6" stop-opacity="0" />
                </radialGradient>
            </defs>
        </svg>






    }
}        
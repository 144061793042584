import React from "react";

export default function IconStack({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 11.27C1 11.024 1.033 10.778 1.099 10.54L2.622 5.019C2.78201 4.43871 3.12799 3.92695 3.60687 3.56224C4.08574 3.19753 4.67106 3.00001 5.273 3H14.727C15.3289 3.00001 15.9143 3.19753 16.3931 3.56224C16.872 3.92695 17.218 4.43871 17.378 5.019L18.901 10.539C18.967 10.778 19 11.024 19 11.271V15C19 15.5304 18.7893 16.0391 18.4142 16.4142C18.0391 16.7893 17.5304 17 17 17H3C2.46957 17 1.96086 16.7893 1.58579 16.4142C1.21071 16.0391 1 15.5304 1 15V11.27ZM4.068 5.418C4.14068 5.15418 4.29792 4.9215 4.5156 4.75567C4.73328 4.58984 4.99935 4.50002 5.273 4.5H14.727C15.0006 4.50002 15.2667 4.58984 15.4844 4.75567C15.7021 4.9215 15.8593 5.15418 15.932 5.418L17.455 10.938C17.461 10.958 17.465 10.979 17.47 11H14C13.8265 11 13.6559 11.0452 13.5051 11.1311C13.3543 11.217 13.2285 11.3407 13.14 11.49L12.534 12.51C12.4455 12.6593 12.3197 12.783 12.1689 12.8689C12.0181 12.9548 11.8475 13 11.674 13H8.236C8.05034 12.9999 7.86837 12.9481 7.71047 12.8504C7.55258 12.7528 7.42499 12.6131 7.342 12.447L6.894 11.553C6.81101 11.3869 6.68342 11.2472 6.52553 11.1496C6.36763 11.0519 6.18566 11.0001 6 11H2.53L2.545 10.938L4.068 5.418Z"
        fill={color}
      />
    </svg>
  );
}

import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import AutomationFunctions from '../../automationFunctions'

class AutomationUpdateIntegrationField extends Component {
    constructor(props) {
        super(props);

        this.getAction = this.getAction.bind(this)
        this.setValue = this.setValue.bind(this)
        this.updateValue = this.updateValue.bind(this)
    }

    getAction(action) {
        var bools = ['boolean']
        var selects = ['select', 'multiselect']
        var numbers = ['number']
        var dates = ['date']
        var field = this.props.fields.find(f => f.value == this.props.step.stepOptions.field.value)
        var currentValue = this.props.step.stepOptions.value.value

        var errors = this.props.step.stepOptions.errors || {}

        if (!field) return null
        if (bools.indexOf(field.type) > -1) {
            return <UILIB.Select data={[{
                label: 'Turn On',
                value: 'true'
            }, {
                label: 'Turn Off',
                value: 'false'
            }]} value={currentValue} placeholder="Select Value" onChange={ev => this.setValue(ev.target.value)} error={errors.value} />
        }

        if (selects.indexOf(field.type) > -1) {
            return <UILIB.Select data={field.options} placeholder="Select Value" value={currentValue} onChange={ev => this.setValue(ev.target.value)} error={errors.value} />
        }

        var selectValue = ''

        if (numbers.indexOf(field.type) > -1) {
            if (typeof currentValue !== 'number') {
                selectValue = currentValue
            }
            return <div className="quickFlex">
                <div style={{ flex: "1" }}>
                    <UILIB.Select data={['increment', 'decrement', { value: '', label: 'specific value' }]} value={selectValue} onChange={ev => {
                        var value = ev.target.value
                        if (value == 'increment' || value == 'decrement') {
                            this.setValue(value)
                        } else {
                            this.setValue(0)
                        }
                    }} error={errors.value} placeholder="Select Value" />
                </div>
                {(currentValue !== 'increment' && currentValue !== 'decrement') && <div style={{ flex: "1" }}>
                    <UILIB.TextInput className="mar-l10" type="number" value={currentValue} onChange={ev => this.setValue(ev.target.value)} error={errors.value} />
                </div>}

            </div>
        }

        if (dates.indexOf(field.type) > -1) {
            if (!moment(currentValue).isValid()) {
                selectValue = currentValue
            }
            return <div className="row">
                <div className="col-xs-6">
                    <UILIB.Select outerstyle={{ minWidth: '200px' }} data={[{ label: 'Set to Open date', value: 'date' }, { label: 'Set a specific value', value: '' }]} value={selectValue} onChange={ev => {
                        var value = ev.target.value
                        if (value === 'date') {
                            this.setValue('date')
                        } else {
                            this.setValue(moment())
                        }
                    }} error={errors.value} placeholder="Select Value" />
                </div>

                {currentValue !== 'date' &&
                    <div className="col-xs-6">
                        <UILIB.TextInput className="mar-l10" type="date" value={currentValue} onChange={(ev, date) => this.setValue(date)} error={errors.value} />
                    </div>}
            </div>
        }

        var workflowEmails = AutomationFunctions.getWorkflowEmails(this.props.step.guid, this.props.steps);

        var options = ['campaignName', 'subject', 'fromName', 'fromAddress', 'replyName', 'replyAddress', 'link']
        var friendlyOptions = [
            { label: 'The campaign name', value: 'campaignName' },
            { label: 'The email subject', value: 'subject' },
            { label: 'The email from name', value: 'fromName' },
            { label: 'The email from address', value: 'fromAddress' },
            { label: 'The email reply name', value: 'replyName' },
            { label: 'The email reply address', value: 'replyAddress' },
            { label: 'The link they clicked', value: 'link' },
            { label: 'A specific value', value: '' }
        ]
        if (!workflowEmails.length) {
            // remove the link option if its not a click
            friendlyOptions.splice(0, 7)
        }

        if (options.indexOf(currentValue) > -1) {
            selectValue = currentValue
        }

        return <div className="row">
            <div className="col-xs-6">
                <UILIB.Select outerstyle={{ minWidth: '200px' }} data={friendlyOptions} value={selectValue} onChange={ev => {
                    var value = ev.target.value
                    if (value === '') {
                        this.setValue('')
                    } else {
                        this.setValue(value)
                    }
                }} error={errors.value} placeholder="Select Value" />
            </div>

            {options.indexOf(currentValue) === -1 && <div className="col-xs-6">
                <UILIB.TextInput className="mar-l10" type="text" value={currentValue} onChange={(ev) => this.setValue(ev.target.value)} error={errors.value} />
            </div>}

            {options.indexOf(currentValue) > -1 && <div className="col-xs-6">
                <UILIB.Select className="mar-l10" placeholder="Select Campaign" data={workflowEmails.map(wfe => { return { label: wfe.stepOptions.subject.value, value: wfe.stepOptions.campaign.id } })} name="campaignId" value={this.props.step.stepOptions.campaignId} onChange={this.updateValue} error={errors.campaignId} />
            </div>}
        </div>
    }

    setValue(val) {
        this.props.step.stepOptions.value.value = val
        this.setState({})
    }

    updateValue(ev) {
        if (typeof this.props.step.stepOptions[ev.target.name] === 'object') {
            this.props.step.stepOptions[ev.target.name].value = ev.target.value
        } else {
            this.props.step.stepOptions[ev.target.name] = ev.target.value
        }

        this.setState({})
    }

    render() {
        var errors = this.props.step.stepOptions.errors || {}
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} className="form-group">
                        <label htmlFor="">Select Field to Update</label>
                        <UILIB.Select placeholder="Select Field" data={this.props.fields} name="field" value={this.props.step.stepOptions.field.value} onChange={this.updateValue} error={errors.field} />
                    </UILIB.Column>
                    {(this.props.step.stepOptions.field.value && this.props.step.stepOptions.field.value.length) && <UILIB.Column xs={12} className="form-group">
                        <label htmlFor="">What should the value be?</label>
                        {!!this.props.step.stepOptions.field.value && this.getAction()}
                    </UILIB.Column>}
                </UILIB.Row>
            </div>
        );
    }
}

export default AutomationUpdateIntegrationField;
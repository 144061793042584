import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import UILIB from '../../../../Common-Objects/Lib';
import axios from '../../../../data/http/axios';
import i18n from '~/i18n';
import FormatNumberFuncs from '../../../../Classes/numberFormatFunctions';
import DateTimeFunctions from '../../../../Classes/dateTimeFunctions';

export default function AutomationTable({ edit = () => { } }) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const history = useHistory()

    const getData = async () => {
        setLoading(true)
        const results = await axios.get('/automation?recordLimit=5&status=Running')
        if (!results.data.Automations.length) {
            setData([])
            setLoading(false)
            return
        }
        const counts = await axios.get('/automation/queueLength?ids=' + results.data.Automations.map(r => r.id).join('&ids='))
        setData(results.data.Automations.map(row => {
            var subsAffected = 0;
            if (row.steps && row.steps.length) {
                row.steps.forEach(theStep => {
                    if (!isNaN(theStep.hits)) subsAffected += theStep.hits
                })
            }

            let statusChipColor = "";
            if (row.status == "Running") statusChipColor = "square-chip-green";
            if (row.status == "Paused") statusChipColor = "square-chip-orange";
            if (row.status == "Starting") statusChipColor = "square-chip-yellow";

            const existing = counts.data.find(c => c.AutomationId == row.id)

            return {
                name: {
                    headerValue: i18n.t('automation:overview.name'),
                    value: row.name
                },
                group: {
                    headerValue: i18n.t('automation:overview.groupHeading'),
                    value: row.groupId == "-1000" ? <UILIB.SquareChip>Any Group</UILIB.SquareChip> : <UILIB.SquareChip>{row.groupName}</UILIB.SquareChip>
                },
                queueCount: {
                    headerValue: i18n.t('automation:overview.processed'),
                    value: <span>
                        {(row.status == "Running" || row.status == "Paused" || row.status === 'Starting' || row.status === 'Archived') &&
                            <UILIB.SquareChip className="square-chip-large square-chip-purple square-chip-min-width">{existing ? FormatNumberFuncs.formatNumber(existing.count) : <UILIB.LoadingIcons iconType="2" style={{ width: "15px", height: "15px" }} />}</UILIB.SquareChip>
                        }
                        {row.status == "Draft" && <UILIB.SquareChip className="square-chip-large square-chip-grey square-chip-min-width">N/A</UILIB.SquareChip>}
                    </span>,
                    orderBy: true,
                    align: "center",
                    width: 200
                },
                status: {
                    headerValue: i18n.t('automation:overview.status'),
                    value: <UILIB.SquareChip
                        iconLeft={<UILIB.Icons icon="circle" />}
                        className={statusChipColor + " small-icon"}>
                        {i18n.t('automation:overview.' + row.status.toLowerCase())}
                    </ UILIB.SquareChip >,
                    orderBy: true,
                    align: "center",
                    width: 200
                },
                createdAt: {
                    headerValue: i18n.t('automation:overview.createdDate'),
                    value: DateTimeFunctions.formatDateTime(row.createdAt, 2),
                    orderBy: true,
                    align: "center",
                    width: 200
                },
                updatedAt: {
                    headerValue: i18n.t('automation:overview.updatedDate'),
                    value: DateTimeFunctions.formatDateTime(row.updatedAt, 2),
                    orderBy: true,
                    align: "center",
                    width: 200
                }
            }
        }))
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <UILIB.Paper isLoading={loading} iconRight={<UILIB.Icons icon="pencil" color="grey" />} iconRightClick={edit}>
            <div className="quickFlex mar-b24" style={{ justifyContent: "space-between" }}>
                <h4>Automations</h4>
                <UILIB.Row>
                    <UILIB.Column xs={12} margin={0} className="end-xs">
                        <UILIB.Button className="primary" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => history.push('/cp/automation')}>View Automations</UILIB.Button>
                    </UILIB.Column>
                </UILIB.Row>
            </div>

            {!!data && <UILIB.DataTable1
                noResultsTxt={"No Running Automations"}
                tableData={data}
            />}
            {!data && <div style={{ height: 300 }}><UILIB.LoadingIcons /></div>}
        </UILIB.Paper>
    )
}
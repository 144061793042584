import Toggle from 'react-toggle'
import React, { Fragment } from 'react'

export default class SelectBox extends React.Component {
    constructor(props) {
        super(props);
    }

    changeOption(event) {
    }


    render() {
        var classes = "toggle";
        if (this.props.className) {
            classes += " " + this.props.className;
        }

        var otherProps = {
            defaultChecked: this.props.value
        };
        if (this.props.hasOwnProperty("checked")) {
            otherProps.checked = this.props.checked;
            delete otherProps.defaultChecked
        }

        let label = "";
        if ((this.props.label && this.props.labelOptions) || this.props.labelOptions) {
            label = <div className="txtInput_label_flex">
                <div className="txtInput_label">{this.props.label}</div>
                <div className="txtInput_labelOptions">{this.props.labelOptions}</div>
            </div>
        } else if (this.props.label) {
            label = <div className="txtInput_label">{this.props.label}</div>
        }

        let explainer = "";
        if (this.props.explainer) {
            explainer = <div className="txtInput_explainer">{this.props.explainer}</div>
        }

        let outerClassName = "textInputWrapper";
        if (this.props.outerClassName) outerClassName += " " + this.props.outerClassName;

        return <div className={outerClassName} style={this.props.outerStyle}>
            {label}
            <div className={classes}>
                {!!this.props.before && <span className="toggle-left">{this.props.before}</span>}
                <Toggle
                    icons={false}
                    name={this.props.name}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                    {...otherProps} />
                {!!this.props.after && <span className="toggle-right">
                    {!this.props.afterSecondary && this.props.after}
                    {!!this.props.afterSecondary && <div>
                        <div style={{ marginBottom: 2 }}>{this.props.after}</div>
                        <div style={{ color: 'var(--grey-700)', fontWeight: 400 }}>{this.props.afterSecondary}</div>
                    </div>}
                </span>}

            </div>
            {explainer}
        </div >
    }
}

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UILIB from '../../../Common-Objects/Lib'
import { alterSiteDrawer, alterSnackbar } from '../../../data/actions/siteActions'
import axios from '../../../data/http/axios'
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import DateTimeFunctions from '../../../Classes/dateTimeFunctions'

const Map = ReactMapboxGl({
    accessToken: "pk.eyJ1IjoiY29udGFjdGdhdGUiLCJhIjoiODI5ZDJlOWNiNGM5YTM4OGM1Mzk2ODA2MjI4MTI2YmQifQ.iEJBZeGfK-7O2nOkPxIBDA"
});

export default function ContactDetails({ SubscriberId, stats }) {
    const [contact, setContact] = useState(null)
    const siteMaster = useSelector(state => state.siteMaster)
    const dispatch = useDispatch()

    const getContact = async () => {
        let contactData = await axios.get('/subscriber/' + SubscriberId);
        contactData = contactData.data.Subscriber;
        setContact(contactData)
    }

    const changeStatus = async (status) => {
        dispatch(alterSiteDrawer(true, true, 'right', <ConfirmStatusChange status={status} subId={SubscriberId} onFinish={getContact} />))
    }

    const tagsChanged = async (newTags) => {
        await axios.put(`/subscriber/${SubscriberId}/tags`, { tags: newTags.map(tag => tag.id) })
        await getContact()
    }

    useEffect(() => {
        getContact()
    }, [SubscriberId])

    if (!contact) return <div className='contact-details contact-details-loading'></div>

    let statusText = 'Active'
    let iconLeft = <UILIB.Icons icon="tickCircle" color={siteMaster.colours['$status-green']} />
    let color = siteMaster.colours['$status-green']
    if (contact.blackListed) {
        statusText = 'Denylisted'
        iconLeft = <UILIB.Icons icon="warning" color="black" />
        color = "black"
    } else if (contact.bounce) {
        statusText = 'Bounced'
        iconLeft = <UILIB.Icons icon="warning" color={siteMaster.colours['$status-red']} />
        color = siteMaster.colours['$status-red']
    } else if (contact.GroupSubscribers.every(g => g.unsubscribe)) {
        statusText = 'Unsubscribed'
        iconLeft = <UILIB.Icons icon="questionCircle" color={siteMaster.colours['$status-orange']} />
        color = siteMaster.colours['$status-orange']
    } else if (contact.GroupSubscribers.some(g => g.unsubscribe)) {
        statusText = 'Unsubscribed in Some'
        iconLeft = <UILIB.Icons icon="questionCircle" color={siteMaster.colours['$status-orange']} />
        color = siteMaster.colours['$status-orange']
    } else if (contact.GroupSubscribers.every(g => !g.active)) {
        statusText = 'In-Active'
        iconLeft = <UILIB.Icons icon="questionCircle" color={siteMaster.colours['$status-yellow']} />
        color = siteMaster.colours['$status-yellow']
    } else if (contact.GroupSubscribers.some(g => !g.active)) {
        statusText = 'In-Active in Some'
        iconLeft = <UILIB.Icons icon="questionCircle" color={siteMaster.colours['$status-yellow']} />
        color = siteMaster.colours['$status-yellow']
    }

    let mapStyle = { width: "100%", backgroundImage: "url(https://cdn1.ourmailsender.com/siteContent/assets/misc/blankMap.jpg)", backgroundSize: "cover" }
    let mapBg
    if (stats.geolocation && stats.geolocation.longitude && stats.geolocation.longitude && stats.geolocation.longitude !== 51.5139573 && stats.geolocation.longitude !== -0.1148677) {
        mapStyle = undefined
        mapBg = <Map
            style="mapbox://styles/contactgate/cl9mrlyyz001t16nuzmha2j4j"
            // style="mapbox://styles/contactgate/cjg3exzgh160u2rp1nkgnn4na"
            center={[stats.geolocation.longitude, stats.geolocation.latitude - 0.015]}
            id="mapHolder"
            zoom={[8]}
            containerStyle={{
                height: 180,
                width: "100%",
                boxSizing: "border-box",
                borderRadius: 8
            }}>
            <Marker coordinates={[stats.geolocation.longitude, stats.geolocation.latitude]} longitude={stats.geolocation.longitude} latitude={stats.geolocation.latitude}>
                <UILIB.Icons icon="radiobutton" color={color} />
            </Marker>
        </Map>
    }

    return (
        <div className='contact-details'>
            <div className="contact-details-map" style={mapStyle}>
                {mapBg}
            </div>
            <div className='contact-details-data'>
                <div className="quickFlex" style={{ justifyContent: 'space-between', marginBottom: 12 }}>
                    <UILIB.Avatar style={{ height: 60, width: 60 }} src={contact.subscriberImage} />
                    <UILIB.ButtonSelect headerText={statusText} iconLeft={iconLeft}>
                        <ul>
                            <li><a onClick={() => changeStatus('active')}>Active</a></li>
                            <li><a onClick={() => changeStatus('inactive')}>In-Active</a></li>
                            <li><a onClick={() => changeStatus('unsubscribed')}>Unsubscribed</a></li>
                            <li><a onClick={() => changeStatus('bounce')}>Bounced</a></li>
                            <li><a onClick={() => changeStatus('blacklisted')}>Denylisted</a></li>
                        </ul>
                    </UILIB.ButtonSelect>
                </div>
                <div style={{ marginBottom: 8 }}>
                    <UILIB.Score score={contact.score} />
                </div>
                <div style={{ fontWeight: 600, fontSize: 18 }}>{contact.emailAddress}</div>
                <div style={{ marginBottom: 20 }}>
                    First added {DateTimeFunctions.formatDateTime(contact.createdAt)}
                </div>
                <UILIB.Tags tagsSelected={contact.Tags} readOnly={contact.LinkTags} tagsChanged={tagsChanged} tagType={0} addStyle={{}} />

            </div>
        </div>
    )
}


function ConfirmStatusChange({ status, subId, onFinish = () => { } }) {
    const dispatch = useDispatch()

    const confirm = async () => {
        await axios.put('/subscriber/' + subId + '/status', { status })
        dispatch(alterSnackbar(true, 'Status Updated'))
        dispatch(alterSiteDrawer(false, true, 'right', null))
        onFinish()
    }

    return (
        <div>
            <h4 className='mar-b25'>Change Contact Status</h4>
            <div className='mar-b25'>
                {status === 'active' && <div>Changing a contact to active will make them active in all groups and available for emailing</div>}
                {status === 'inactive' && <div>Changing a contact to in-active will make them in-active in all groups and not available for emailing</div>}
                {status === 'unsubscribed' && <div>Changing a contact to unsubscribed will make them unsubscribe them all from all groups and not available for emailing</div>}
                {status === 'bounce' && <div>Change contact status to bounced and not available for emailing</div>}
                {status === 'blacklisted' && <div>Change contact status to Denylisted and not available for emailing</div>}
            </div>
            <UILIB.Button onClick={confirm}>Confirm</UILIB.Button>
        </div>
    )
}
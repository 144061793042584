import React, { useReducer, useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { wizardStepsReducer, initialWizardStepsState } from "~/Classes/wizard";
import { handleGenericError } from "~/data/actions/siteActions";
import queryString from 'query-string';
import i18n from "~/i18n";

export default function ConnectFacebookMessengerChannelPage({ userRole = null }) {
    const { inboxId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const timer = useRef(null)
    const site = useSelector(state => state.siteMaster)
    const user = useSelector(state => state.user)

    const [inbox, setInbox] = useState(null)
    const [channel, setChannel] = useState(null);
    const [app, setApp] = useState(null)
    const [accApp, setAccApp] = useState(null);
    const [socialAccounts, setSocialAccounts] = useState(null);
    const [SocialAccountId, setSocialAccountId] = useState(null);
    const [refreshing, setRefreshing] = useState(false)
    const [saving, setSaving] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    const [steps, updateSteps] = useReducer(
        wizardStepsReducer,
        initialWizardStepsState(5)
    );

    useEffect(() => {
        if (userRole > 0) return window.open("/", "_self");
        const query = queryString.parse(location.search)
        if (query.ChannelId) {
            axios.get(`/inboxes/${inboxId}/channel/${query.ChannelId}`).then(res => {
                setChannel(res.data)
                if (res.data.SocialAccountId) {
                    setSocialAccountId(res.data.SocialAccountId)
                }
            })
        } else {
            setChannel({})
        }

        getAccApp()

        axios.get('/application/77').then(res => {
            setApp(res.data.Application)
        })

        return function () {
            if (timer.current) clearInterval(timer.current)
        }

    }, [])

    useEffect(() => {
        axios.get(`/inboxes/${inboxId}`).then(res => {
            setInbox(res.data)
        })
    }, [inboxId])

    useEffect(() => {
        if (accApp) {
            getSocialAccounts()
        }
    }, [accApp])

    async function getAccApp() {
        const res = await axios.get('/application/account/app/77')
        if (res.data.error) {

        } else {
            setAccApp(res.data.Application)
            if (timer.current) clearInterval(timer.current)
            updateSteps({ type: "complete", index: 0 })
        }

    }

    async function getSocialAccounts(force) {
        setRefreshing(true)
        let q = force ? '?force=true' : ''
        const res = await axios.get('/application/facebook/sites' + q)
        setSocialAccounts(res.data)
        setRefreshing(false)
    }

    async function handleStep1Submit(e) {
        if (e?.preventDefault) e.preventDefault()

        let ourTrackingDomain = site.trackingDomain;
        if (ourTrackingDomain === "api.transpond.io") {
            ourTrackingDomain = "api.mpzmail.com";
        } else if (ourTrackingDomain === "localhost:8082") {
            ourTrackingDomain = "localhost:8082";
        }

        var theURI = app.oAuthAuthoriseUri;
        theURI = theURI.replace(/\[OAUTHCLIENTID\]/g, app.oAuthAppId);
        theURI = theURI.replace(/\[RANDNO\]/g, user.token);
        var redirectURI = window.location.origin;
        if (redirectURI === 'https://app.transpond.io') {
            redirectURI = 'https://web.mpzmail.com'
        } else if (redirectURI === 'http://localhost:8082') {
            redirectURI = 'http://localhost:8082'
        }
        var toReplaceWith = redirectURI + "/cp/company/integrations/oAuth/response/" + app.id
        if (app.options && app.options.backendAuth) {
            toReplaceWith = 'https://' + ourTrackingDomain + '/api/oauth/app/' + app.id + '/oauth'
        }
        theURI = theURI.replace(/\[OAUTHREDIRECTURL\]/g, toReplaceWith);

        //if there are extra params defined, add them on
        var okToGo = true
        var errors = {}
        if (app.options && app.options.oAuthoriseUriParams && app.options.oAuthoriseUriParams.length) {
            app.options.oAuthoriseUriParams.forEach(theOpt => {
                if (theOpt.required) {
                    if (!theOpt.value) {
                        errors[theOpt.key] = 'Required'
                        okToGo = false
                    } else if (theOpt.isUrl) {
                        // test its a url
                        var regex = new RegExp(/^(https):\/\/[^\s$.?#].[^\s]*$/gm)
                        if (theOpt.value.indexOf('https://') !== 0) {
                            errors[theOpt.key] = 'Invalid Url - must start with https://'
                            okToGo = false
                        } else if (!regex.test(theOpt.value)) {
                            errors[theOpt.key] = 'Invalid Url - must start with https://'
                            okToGo = false
                        }
                    }
                }
                theURI += "&" + theOpt.key + "=" + theOpt.value;
            })
        }
        window.open(theURI, '', 'height=670,width=1400,toolbar=no,menubar=no,status=no');
        timer.current = setInterval(() => getAccApp(), 2000)
        if (accApp) updateSteps({ type: "complete", index: 0 })
    }

    async function handleStep2Submit(e) {
        if (e?.preventDefault) e.preventDefault()
        if (SocialAccountId) {
            const newChannel = await connectChannel()
            await handleFinalStepSubmit(newChannel)
        }
    }

    async function handleFinalStepSubmit(obj) {
        setSaving(true);

        try {
            await axios.put(`/inboxes/${inboxId}/channel/${obj.id}`, {
                status: 'running'
            })
            history.goBack();
        } catch (error) {
            console.log(error)
            setSaving(false);
        }
    }

    async function connectChannel() {
        try {
            const socialAccount = socialAccounts.find(a => a.id === SocialAccountId)
            let url = `/inboxes/${inboxId}/channel`
            let method = 'post'
            if (channel.id) {
                url += `/${channel.id}`
                method = 'put'
            }
            const { data } = await axios[method](url, {
                name: socialAccount.name,
                type: 3,
                SocialAccountId,
                status: 'draft',
                settings: { ...channel.settings }
            });
            setChannel(data)
            return data
        } catch (error) {
            if (error?.data?.error || error?.message) {
                setFormErrors({ email: error?.data?.error || error?.message });
            }
            dispatch(handleGenericError(error));
            throw error;
        }
    }

    if (!channel || !inbox || !app) return <UILIB.LoadingIcons />

    return (
        <div className="wizard-page connect-email-channel-page">
            <a onClick={() => history.goBack()} className="wizard-page__close">
                <UILIB.Icons icon="cross" color="black" />
            </a>

            <div className="wizard-page__inner">
                <div className="center-xs mar-b60">
                    <h1 className="h2 mar-b10">{channel.id ? i18n.t("chat:channel.connect.facebook.headerComplete") : i18n.t("chat:channel.connect.facebook.headerCreate")}</h1>
                    <p className="text-md text-grey">
                        {i18n.t("chat:channel.connect.facebook.subheader")}
                    </p>
                </div>

                <ol className="faux-list">
                    <li>
                        <UILIB.WizardStep heading={i18n.t("chat:channel.connect.facebook.step1.header")}  {...steps[0]}>
                            <p className="text-grey">{i18n.t("chat:channel.connect.facebook.step1.subheaderr")}</p>
                            <UILIB.Button className="button-primary mar-t18" onClick={handleStep1Submit}>{i18n.t("chat:channel.connect.facebook.step1.connect")}</UILIB.Button>
                        </UILIB.WizardStep>
                    </li>

                    <li>
                        <UILIB.WizardStep heading={i18n.t("chat:channel.connect.facebook.step2.header")}{...steps[1]}>
                            <p className="text-grey">{i18n.t("chat:channel.connect.facebook.step2.subheader")}</p>
                            {!socialAccounts && <UILIB.LoadingIcons />}
                            {!!socialAccounts && <div className="quickFlex" style={{ alignItems: 'flex-end' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <UILIB.Select outerstyle={{ marginRight: 12 }}
                                        data={socialAccounts.map(a => ({ label: a.name, value: a.id, iconRight: <UILIB.Avatar src={a.image} style={{ height: 20, width: 20 }} /> }))}
                                        value={SocialAccountId} name="SocialAccountId" onChange={e => setSocialAccountId(e.target.value)} />
                                </div>
                                <UILIB.Button className="button-input button-primary" onClick={() => getSocialAccounts(true)} disabled={refreshing}>{i18n.t("chat:channel.connect.facebook.step2.refresh")}</UILIB.Button>
                            </div>}
                            <UILIB.Button className="button-primary mar-t18" disabled={!SocialAccountId || saving} onClick={handleStep2Submit}>{i18n.t("chat:channel.connect.facebook.step2.create")}</UILIB.Button>
                        </UILIB.WizardStep>
                    </li>

                </ol>
            </div>
        </div >
    );
}
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import PermissionChecker from '~/Classes/permissions';
import TierPermissions from '../../../../Classes/tierPermissions';
import Add from './add'
import DeleteDrawer from './delete';

@connect((store) => {
    return { accountMaster: store.accountMaster, permissionStore: store.permission, siteMaster: store.siteMaster }
})
class Mailboxes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            tableData: []
        }
        this.getMailboxes = this.getMailboxes.bind(this)
        this.addMailbox = this.addMailbox.bind(this)
        this.editMailbox = this.editMailbox.bind(this)
        this.deleteMailbox = this.deleteMailbox.bind(this)
    }

    componentDidMount() {
        this.getMailboxes()
    }

    getMailboxes() {
        var writeAccess = PermissionChecker('company', this.props.permissionStore.permissions, "write")
        axios.get('/accountMaster/mailbox').then(response => {
            this.setState({
                tableData: response.data.Mailboxes.map(row => {
                    var obj = {
                        emailAddress: {
                            headerValue: i18n.t('company:mailboxes.emailAddress'),
                            value: row.emailAddress,
                            orderBy: false
                        },
                        user: {
                            headerValue: i18n.t('company:mailboxes.username'),
                            value: row.user,
                            orderBy: false
                        },
                        host: {
                            headerValue: i18n.t('company:mailboxes.host'),
                            value: row.host,
                            orderBy: false
                        },
                        port: {
                            headerValue: i18n.t('company:mailboxes.port'),
                            value: row.port,
                            orderBy: false
                        },
                        tls: {
                            headerValue: i18n.t('company:mailboxes.tls'),
                            value: row.tls ? 'Yes' : 'No',
                            orderBy: false
                        },
                        lastSync: {
                            headerValue: i18n.t('company:mailboxes.lastSync'),
                            value: row.lastSync ? DateTimeFunctions.formatDateTime(row.lastSync) : i18n.t('company:mailboxes.never'),
                            orderBy: false
                        },
                        nextSync: {
                            headerValue: i18n.t('company:mailboxes.nextSync'),
                            value: row.nextSync ? DateTimeFunctions.formatDateTime(row.nextSync) : i18n.t('company:mailboxes.soon'),
                            orderBy: false
                        },
                        active: {
                            headerValue: i18n.t('company:mailboxes.active'),
                            value: row.active ? i18n.t('company:mailboxes.yes') : i18n.t('company:mailboxes.no'),
                            orderBy: false
                        },
                        optionsCol:
                        {
                            headerValue: " ",
                            value: <UILIB.OptionsDropdown popWidth="150px">
                                <ul>
                                    {writeAccess && <li>
                                        <a onClick={() => { this.editMailbox(row) }}>{i18n.t('edit')}</a>
                                    </li>}
                                    {writeAccess && <li>
                                        <a onClick={() => { this.deleteMailbox(row) }}>{i18n.t('delete')}</a>
                                    </li>}
                                    <li>
                                        <a onClick={() => this.props.history.push('/cp/company/mailboxes/' + row.id)}>View History</a>
                                    </li>
                                </ul>
                            </UILIB.OptionsDropdown>,
                            orderBy: false,
                            fixed: true,
                            width: 20
                        }
                    }
                    return obj
                }),
                ready: true
            })
        })
    }

    addMailbox() {
        var drawerContent = <Add history={this.props.history} finish={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null));
            this.getMailboxes()
        }} cancel={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true, 500));
    }

    editMailbox(mailbox) {

        var drawerContent = <Add mailbox={mailbox} history={this.props.history} finish={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null));
            this.getMailboxes()
        }} cancel={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true, 500));
    }

    deleteMailbox(mailbox) {
        var drawerContent = <DeleteDrawer confirm={() => {
            axios.delete('/accountMaster/mailbox/' + mailbox.id).then(() => {
                this.getMailboxes();
                this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null));
            })
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true));
    }

    render() {
        var writeAccess = PermissionChecker('company', this.props.permissionStore.permissions, "write")
        var noneAvailable = <span>{i18n.t('company:mailboxes.noneFound')} {writeAccess && <a onClick={this.addMailbox}> {i18n.t('company:mailboxes.addAMailBox')} </a>}</span>


        const replyPermissions = TierPermissions("replyTracking", this.props.accountMaster)
        const allowed = replyPermissions.allowed
        console.log(replyPermissions)

        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} sm={6} className="mar-b25 quickFlex">
                        {this.props.siteMaster.siteId == 1 && <UILIB.Hint
                            iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}
                            href="https://kb.transpond.io/hc/en-us/articles/360025598991-Reply-Tracking-Quick-Start"
                            className="mar-l0"
                        >
                            Find out what Reply Tracking is, and how it works.
                        </UILIB.Hint>}
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} className="text-right mar-b25" >
                        {writeAccess &&
                            <UILIB.Button
                                className="button-primary"
                                onClick={this.addMailbox}
                                disabled={!allowed}
                                iconLeft={<UILIB.Icons icon="plus" />}>
                                {i18n.t('company:mailboxes.addAMailBox')}
                            </UILIB.Button>
                        }
                    </UILIB.Column>
                </UILIB.Row>



                {allowed && <UILIB.Paper isLoading={!this.state.ready}>
                    <UILIB.DataTable1
                        noResultsTxt={noneAvailable}
                        loadingData={!this.state.ready}
                        tableData={this.state.tableData}
                        loadingData={0}
                        width="100%"
                        pageSize="100"
                        hasCheckBoxes="no"
                    />
                </UILIB.Paper>}

                {!allowed && <UILIB.TierLimitHolder err="tierForbids" tierName={this.props.accountMaster.tier.name} type="Reply Tracking" />}

            </div>
        );
    }
}

export default Mailboxes;
class FormatNumberFuncs {

    static formatNumber(theNo, format) {
        if (theNo === '' || theNo === undefined || theNo === null) return ''
        if (isNaN(theNo) == true) {
            return 0
        }
        else {
            theNo = Number(theNo);
            switch (format) {
                case 0:
                    {
                        theNo = theNo.toFixed(0);
                        return theNo.toLocaleString();
                    }
                case 1:
                    {
                        theNo = theNo.toFixed(1);
                        return theNo.toLocaleString();
                    }
                case 2:
                    if (theNo % 1) {
                        theNo = theNo.toFixed(2);
                    }
                    return theNo.toLocaleString();
                case 3:
                    return ordinal_suffix_of(theNo)
                default:
                    return theNo.toLocaleString();
            }
        }
    }
}

export default FormatNumberFuncs;

function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

export default class ImportGroupsTemplate extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            groupID: 0,
            uploadFileName: "",
            uploadedFile: [],
            fileErrorMsg: "",
            uploadingFile: false,
            uploadStatus: 0,
            fileButtonText: i18n.t('subscribers:add.import.selectCsv'),
            containsHeader: true,
            currentStatus: "Active",
            statusData: [{ label: i18n.t('Active'), value: 'Active' }, { label: i18n.t('In-Active'), value: 'In-Active' }, { label: i18n.t('Unsubscribed'), value: 'Unsubscribed' }, { label: i18n.t('Bounced'), value: 'Bounced' }, { label: i18n.t('Blacklisted'), value: 'Blacklisted' }],
            otherGroupData: [{ label: i18n.t('subscribers:add.import.importThem'), value: 1 }, { label: i18n.t('subscribers:add.import.dontImportThem'), value: 0 }, { label: i18n.t('subscribers:add.import.removeFromOtherGroups'), value: 2 }],
            delimiterData: [{ label: 'Comma ,', value: ',' }, { label: 'Semi Colon ;', value: ';' }, { label: 'Pipe |', value: '|' }],
            blacklistActionData: [{ label: i18n.t('subscribers:add.import.leaveThemInBlacklist'), value: 0 }, { label: i18n.t('subscribers:add.import.removeFromBlacklist'), value: 1 }],
            currentDelimiter: ',',
            alertMessage: '',
            alertOpen: false,
            isBlackList: false,
            importDuplicates: 1,
            blacklistAction: 0,
            app: undefined
        };
        this.clickFile = this.clickFile.bind(this);
        this.fileAddedToInput = this.fileAddedToInput.bind(this);
        this.sendRequestToAPI = this.sendRequestToAPI.bind(this);
        this.uploadProgress = this.uploadProgress.bind(this);
        this.onChange = this.onChange.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
    }

    componentDidMount() {
        var self = this;
        var isBlackList = true;
        var statusData = [{ label: i18n.t('Blacklisted'), value: 'Blacklisted' }];
        var currentStatus = "Blacklisted";
        if (this.props.accountMaster.accountMaster.blackListGroupId != this.props.match.params.groupID) {
            isBlackList = false;
            statusData = [{ label: i18n.t('Active'), value: 'Active' }, { label: i18n.t('In-Active'), value: 'In-Active' }, { label: i18n.t('Unsubscribed'), value: 'Unsubscribed' }, { label: i18n.t('Bounced'), value: 'Bounced' }, { label: i18n.t('Blacklisted'), value: 'Blacklisted' }]
            currentStatus = "Active";
        }
        this.setState({ groupID: self.props.match.params.groupID, isBlackList: isBlackList, statusData: statusData, currentStatus: currentStatus, currentDelimiter: this.props.accountMaster.accountMaster.options.defaultDelimiter || this.state.currentDelimiter });
        axios.get('/accountMaster/application').then(res => {
            this.setState({
                app: res.data.Applications.find(a => a.appSettings.groupId == this.props.match.params.groupID && a.Application.options.importOnCreate)
            })
        })
    }

    goBack() {
        this
            .props
            .history
            .push('/cp/groups/' + this.state.groupID + '/add/')
    }

    clickFile(e) {
        e.stopPropagation()
        e.preventDefault()
        this.refs.fileUploadInput.click();
        return false
    }

    fileAddedToInput(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        var thisFileName = file.name;
        this.setState({
            uploadFileName: thisFileName,
            uploadedFile: file,
            fileButtonText: thisFileName
        })
    }

    sendRequestToAPI() {

        var hasErrors = false;
        var fileErrorMsg = "";
        var theFileNameDat = this.state.uploadFileName.split(".");
        var theFileNameExt = theFileNameDat[theFileNameDat.length - 1];
        theFileNameExt = theFileNameExt.toLowerCase();
        this.setState({ fileErrorMsg: "" })

        if (theFileNameExt != "csv" && theFileNameExt != 'xlsx') {
            fileErrorMsg = <UILIB.FormError>Please upload a CSV/XLSX File</UILIB.FormError>;
            this.setState({ fileErrorMsg: fileErrorMsg });
            return;
        }
        this.setState({ uploadingFile: true, fileErrorMsg: null, alertOpen: true, alertMessage: i18n.t('subscribers:add.import.alertUploading') });
        var self = this;


        let data = new FormData();
        data.append('file', this.state.uploadedFile);
        data.append('containsHeader', this.state.containsHeader);
        data.append('importDuplicates', this.state.importDuplicates);
        data.append('delimiter', this.state.currentDelimiter)
        data.append('subscriberState', this.state.currentStatus);
        data.append('blacklistAction', this.state.blacklistAction)
        if (this.state.app) {
            data.append('webhooksInbound', this.state.app.Application.options.importOnCreate)
        }
        axios.post('/group/' + this.state.groupID + '/import/file', data, {
            timeout: 1000 * 60 * 20,
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: progressEvent => self.uploadProgress(progressEvent)
        }).then(function (res) {
            self.props.history.push('/cp/groups/' + self.state.groupID + '/add/import/setup/' + res.data.importID);
        }).catch(function (error) {
            self.setState({ uploadingFile: false, fileErrorMsg: 'Invalid CSV/XLSX' });
        });

    }

    uploadProgress(uploadEvent) {
        var loaded = uploadEvent.loaded;
        var total = uploadEvent.total;
        var totalLoaded = Math.round((loaded / total) * 100);

        this.setState({ uploadStatus: totalLoaded });

    }

    onChange(event) {
        this.setState({
            [event.target.name]: event.target.checked
        })
    }

    changeStatus(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }



    render() {
        var theFileNameDat, theFileNameExt
        if (this.state.uploadFileName) {
            theFileNameDat = this.state.uploadFileName.split(".");
            theFileNameExt = theFileNameDat[theFileNameDat.length - 1];
        }

        return <div>


            {this.props.siteMaster.siteId == 1 && <UILIB.Row>
                <UILIB.Column xs={12} className="mar-b25">
                    <UILIB.Hint
                        className="hide-xs mar-l0"
                        iconLeft={<UILIB.Icons icon="questionCircle" />}>
                        <span dangerouslySetInnerHTML={{ __html: i18n.t('helplinks:subscribers.import') }} />
                    </UILIB.Hint>
                </UILIB.Column>
            </UILIB.Row>}

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={6} lg={6}>
                    {
                        (() => {
                            if (!this.state.uploadingFile) {
                                return <UILIB.Paper>
                                    <div className="mar-b25">
                                        <div className="quickFlex " style={{ alignItems: "end" }}>
                                            <div>
                                                <input type="file" ref="fileUploadInput" style={{ display: 'none' }} onChange={this.fileAddedToInput} />
                                                <UILIB.TextInput
                                                    label="Upload a file from your device"
                                                    value={this.state.fileButtonText}
                                                    onClick={this.clickFile} onChange={() => { }}
                                                    onFocus={this.clickFile} />
                                            </div>
                                            <UILIB.Button className="mar-l10" onClick={this.clickFile}>{i18n.t('subscribers:add.import.browse')}</UILIB.Button>
                                        </div>
                                        {this.state.fileErrorMsg}
                                    </div>

                                    <UILIB.Toggle className="mar-b25" label={i18n.t('subscribers:add.copy.header')} before={i18n.t('no')} after={i18n.t('yes')} value={this.state.containsHeader} name="containsHeader" onChange={this.onChange}></UILIB.Toggle>
                                    <UILIB.Select outerClassName="mar-b25" label={i18n.t('subscribers:add.import.whatDoYouWantToHappenGroup')} name="importDuplicates" data={this.state.otherGroupData} value={this.state.importDuplicates} onChange={this.changeStatus} />
                                    <UILIB.Select outerClassName="mar-b25" value={this.state.currentStatus} explainer={this.state.currentStatus === 'Active' ? i18n.t('subscribers:add.import.activeWarning') : ""} label={i18n.t('subscribers:add.import.markAs')} name="currentStatus" data={this.state.statusData} onChange={this.changeStatus} />
                                    <UILIB.Select label={i18n.t('subscribers:add.import.blacklistHeader')} outerClassName="mar-b25" name="blacklistAction" value={this.state.blacklistAction} data={this.state.blacklistActionData} onChange={this.changeStatus} />

                                    {theFileNameExt !== 'xlsx' && <UILIB.Select label={i18n.t('subscribers:add.import.delimiterLeaveAsDefault')} outerClassName="mar-b25" name="currentDelimiter" value={this.state.currentDelimiter} data={this.state.delimiterData} onChange={this.changeStatus} />}
                                    {this.state.app && <p className='text-md text-heavy text-primary'>Importing into this Group will also add the Contacts to {this.state.app.Application.appName}</p>}
                                    <UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={this.sendRequestToAPI}>{i18n.t('subscribers:add.import.upload')}</UILIB.Button>

                                </UILIB.Paper >
                            } else {
                                if (this.state.uploadStatus < 100) {
                                    return <span>
                                        <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.uploadStatus} val2={100} />
                                        <UILIB.SubHeaderText style={{ textAlign: "center", marginTop: "10px" }}>{i18n.t('subscribers:add.import.uploading')} - {this.state.uploadStatus}%</UILIB.SubHeaderText>
                                    </span>
                                } else {
                                    return <span>
                                        <span><UILIB.LoadingLine></UILIB.LoadingLine></span>
                                        <UILIB.SubHeaderText style={{ textAlign: "center", marginTop: "10px" }}>{i18n.t('subscribers:add.import.processing')}</UILIB.SubHeaderText>
                                    </span>
                                }
                            }
                        })()
                    }

                </UILIB.Column >


                <UILIB.Column xs={12} sm={12} md={6} lg={6}>

                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={12} md={12} lg={12}>

                            {!this.state.isBlackList && <UILIB.Paper className="paper-primary">
                                <h4 className="mar-b25">{i18n.t('subscribers:add.import.infoHeader')}</h4>
                                <p>{i18n.t('subscribers:add.import.infoText')}</p>
                                <p className="mar-b30">{i18n.t('subscribers:add.import.infoText2')}</p>
                                <h4 className="mar-b25">{i18n.t('subscribers:add.single.infoHeader')}</h4>
                                <p className="mar-b30">{i18n.t('subscribers:add.single.infoHeader2')}</p>
                                <h4 className="mar-b25">{i18n.t('subscribers:add.single.infoSubHeader')}</h4>
                                <p>{i18n.t('subscribers:add.single.infoText')}</p>
                                <p className="mar-b0">{i18n.t('subscribers:add.single.infoText2')}</p>
                            </UILIB.Paper>
                            }
                            {this.state.isBlackList && <UILIB.Paper className="paper-primary">
                                <h4 className="mar-b25">
                                    {i18n.t('subscribers:add.fromGroup.addBlacklistHeader')}
                                </h4>
                                <p>{i18n.t('subscribers:add.import.formImportingOne')}<br /><br />{i18n.t('subscribers:add.import.formImportingTwo')}</p>
                            </UILIB.Paper>}

                        </UILIB.Column>
                    </UILIB.Row>

                </UILIB.Column>

                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
            </UILIB.Row >

        </div >
    }
}
import React, { Component } from 'react';
import DrawerEditStep from './drawerEditStep'

class DrawerAddStep extends Component {
    constructor(props) {
        super(props);

        this.state = { doingCustomFields: false, newAutomation: {}, currentTrigger: {}, customFieldId: undefined }

        this.chooseTrigger = this.chooseTrigger.bind(this);
    }

    componentDidMount() {
    }

    chooseTrigger(theTrigger) {
        var newAutomation = this.props.newAutomation;
        newAutomation.trigger = theTrigger.value;
        if (!theTrigger.hasOptions) {
            this.props.selectTrigger(newAutomation);
            return;
        }
        this.setState({ newAutomation: newAutomation, currentTrigger: theTrigger, doingCustomFields: true })
    }



    render() {

        var thisTriggers = this.props.triggers.initial;
        if (this.props.previousStep != undefined) {
            if (this.props.triggers[this.props.previousStep.action]) {
                thisTriggers = this.props.triggers[this.props.previousStep.action];
            }
        }

        if (this.state.doingCustomFields) {
            return <DrawerEditStep newAutomation={this.state.newAutomation} customFields={this.props.customFields} currentTrigger={this.state.currentTrigger} history={this.props.history} updateTrigger={this.props.selectTrigger} previousStep={this.props.previousStep} />
        }
        return <div>
            <h4 className="mar-b5">Add a new Step</h4>
            <div className="mar-b25">Choose what will happen to trigger your new step...</div>

            <div>
                <ul className="list with-lines">
                    {thisTriggers.map((theTrigger, index) => {
                        return <li className="middle-md" onClick={() => { this.chooseTrigger(theTrigger) }} key={"trigger_" + index}>
                            <p style={{ marginRight: "20px" }}>
                                <div className={theTrigger.icon} style={{ fontSize: "30px" }} />
                            </p>
                            <p>{theTrigger.label}
                                <div className="text-grey">{theTrigger.longDesc}</div>
                            </p>
                        </li>
                    })}

                </ul>
            </div>

        </div>

    }
}

export default DrawerAddStep;
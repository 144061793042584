import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class finishPausedCampaign extends React.Component {
    constructor(props) {
        super(props);

        this.closeDrawer = this.closeDrawer.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    confirm() {
        this.closeDrawer()
        this.props.confirm(this.props.campaignId);
    }

    render() {

        return <div>
            <h4 className="mar-b25">Really Finish this Campaign?</h4>
            <div className="mar-b25">Are you sure you would like to stop sending this campaign and mark it as finished?</div>

            <UILIB.Button text={i18n.t('yes')} className="button-primary" onClick={this.confirm} />


        </div>

    }
};



import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class removeCampaign extends React.Component {
    constructor(props) {
        super(props);

        this.closeDrawer = this.closeDrawer.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    confirm() {
        this.closeDrawer()
        this.props.confirm(this.props.campaignId);
    }

    render() {

        return <div>
            <h4 className="mar-b25">{i18n.t('campaigns:main.deleteHeader')}</h4>
            <div className="row">
                <div className="col-xs-6">
                    <UILIB.Button text={i18n.t('yes')} className="button-primary button-fullwidth" onClick={this.confirm} />
                </div>
                <div className="col-xs-6">
                    <UILIB.Button text={i18n.t('cancel')} className="button-fullwidth" onClick={this.closeDrawer} />
                </div>
            </div>


        </div>

    }
};



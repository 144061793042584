import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import InboxButton from "../../../shared/inboxButton";
import SendButton from "../../../shared/sendButton";
import * as styles from "./index.module.scss";
import axios from "~/data/http/axios";
import ComposerComponent from '../../../shared/composer';
import { getSignature } from "../helpers/signature";
import FindReplyDialog from "../findReply";
import { alterSnackbar } from "../../../../../../../../data/actions/siteActions";
import SmileyPickerTool from "../toolSmileyPicker";
import i18n from "~/i18n";
import { useSelector } from "react-redux";

export default function Composer({
  className,
  activeConversation = {},
  sentMessage = () => { },
  creatingConversation = null,
  wizardConversation = false,
  closeConversation = () => { },
  sendAgentTyping = () => { },
  userRole = null
}) {

  const uploader = useRef(null);
  const draftTimer = useRef(null)
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const origSendTypes = [
    { label: i18n.t("chat:inbox.conversation.composer.send"), value: "send", messageType: "REPLY" },
    { label: i18n.t("chat:inbox.conversation.composer.sendClose"), value: "sendAndClose", messageType: "REPLY" },
    { label: i18n.t("chat:inbox.conversation.composer.addNote"), value: "addNote", messageType: "NOTE" },
    { label: i18n.t("chat:inbox.conversation.composer.addNoteClose"), value: "addNoteClose", messageType: "NOTE" },
  ]
  const messageTypes = [
    { value: "REPLY", label: i18n.t("chat:inbox.conversation.composer.reply"), defaultValue: "send" },
    { value: "NOTE", label: i18n.t("chat:inbox.conversation.composer.note"), defaultValue: "addNote" },
  ]

  const [firstLoad, setFirstLoad] = useState(true);
  const [messageType, setMessageType] = useState("REPLY");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [buttonType, setButtonType] = useState("send");
  const [sendTypes, setSendTypes] = useState(origSendTypes)
  const [sendButtonLabel, setSendButtonLabel] = useState();
  const [editorDisabled, setEditorDisabled] = useState(false);
  const [editorRef, setEditorRef] = useState()
  const [assignedTo, setAssignedTo] = useState(activeConversation.AccountUserId || undefined);
  const [lastTypedDate, setLastTypedDate] = useState(undefined);
  const [lastSentTypedDate, setLastSentTypedDate] = useState(undefined);
  const [findSavedReplyOpen, setFindSavedReplyOpen] = useState(false);
  const [files, setFiles] = useState([])
  const [sending, setSending] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [savedDraft, setSavedDraft] = useState(true);
  const [draft, setDraft] = useState();
  const [signature, setSignature] = useState("");

  const classes = classnames(
    styles.root,
    className,
    messageType == "NOTE" ? styles["rootVariantNote"] : "",
    editorDisabled ? styles["rootVariantDisabled"] : ""
  );

  useEffect(() => {
    init();
    workOutSendOptions();
  }, [])

  useEffect(() => {
    if (lastTypedDate === undefined) return;
    sendTyping();
  }, [lastTypedDate])

  useEffect(() => {
    if (firstLoad) return;
    if (message == draft && message.length) return;
    setSavingDraft(true)
    setSavedDraft(false)

    if (draftTimer.current) clearTimeout(draftTimer.current);
    draftTimer.current = setTimeout(() => {
      if (!message.length) {
        deleteDraft();
      }
      else {
        saveDraft();
      }
    }, 1000);

  }, [message])

  const init = async () => {
    try {
      const draftMessage = await axios.get('/inboxes/chat/' + activeConversation.id + "/draft")
      let finalContent = "";
      if (draftMessage.data.content) {
        finalContent = draftMessage.data.content;
        setDraft(draftMessage.data.content)
      }
      if (!finalContent) {
        const tmpSig = getSignature(activeConversation.InboxId);
        finalContent = tmpSig;
        setSignature(tmpSig);
      }
      setMessage(finalContent);
      setFirstLoad(false);
    } catch (e) {
      setFirstLoad(false);
    }
  }

  const saveDraft = async () => {
    setSavingDraft(true);
    await axios.put('/inboxes/chat/' + activeConversation.id + "/draft", {
      draftMessage: message
    })
    setSavingDraft(false)
    setSavedDraft(true)
  }

  const deleteDraft = async () => {
    await axios.delete('/inboxes/chat/' + activeConversation.id + "/draft")
    setSavingDraft(false)
    setSavedDraft(true)
  }

  const clearEditor = () => {
    editorRef.current.editorCommands.execCommand('mceSetContent', signature, "");
    setMessage(signature);
    setFiles([])
  }

  const sendMessage = async () => {
    let closing = buttonType == "close";
    let sendClosing = buttonType == "sendAndClose" || buttonType == "addNoteClose";

    if (!closing && (!message || message.length < 2 || message.replace(/&nbsp;/g, " ").trim().length < 1)) {
      return setMessageError(i18n.t("chat:inbox.conversation.composer.longer"))
    }
    setMessageError("");
    try {
      let newChatLine = undefined;
      if (closing) {
        return closeConversation(assignedTo);
      }
      else {
        setSending(true)
        //clear draft timer
        if (draftTimer.current) clearTimeout(draftTimer.current);
        let postUrl = `/inboxes/chat/${activeConversation.id}`
        if (wizardConversation) {
          postUrl += `/assistant`;
        }
        const promises = []
        for (const file of files) {
          const formData = new FormData();
          formData.append("file", file);
          promises.push(axios.post(`/inboxes/chat/${activeConversation.id}/attachment`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }
          }))
        }
        const uploads = await Promise.all(promises)

        newChatLine = await axios.post(postUrl, {
          text: message,
          messageType: messageType,
          actionType: buttonType,
          assignTo: assignedTo,
          closing: sendClosing,
          attachments: uploads.map(u => u.data.id)
        })
        setSending(false)
      }
      workOutSendOptions("REPLY");
      clearEditor();
      sentMessage(newChatLine, sendClosing);

    } catch (e) {
      console.log(e)
      setMessageError(e.data ? e.data.error : i18n.t("chat:inbox.conversation.composer.err"))
      setSending(false)
    }
  }
  const sendTyping = async () => {
    if (!activeConversation.vistorWebChatOnline) return;
    //only send if the last sent type date is more than five seconds ago, or blank
    if (lastSentTypedDate && new Date().getTime() - lastSentTypedDate.getTime() < 3000) return;
    setLastSentTypedDate(new Date());
    sendAgentTyping();
  };

  const changeMessageType = (val) => {
    workOutSendOptions(val);
  }

  const workOutSendOptions = (newMessageType, newButtonType) => {

    let finalMessageType = messageType;
    let finalButtonType = buttonType;
    if (newMessageType) {
      finalMessageType = newMessageType;
      let foundNewMessageType = messageTypes.find(f => f.value == newMessageType)
      if (foundNewMessageType && foundNewMessageType.defaultValue) {
        finalButtonType = foundNewMessageType.defaultValue;
      }
    }
    if (newButtonType) finalButtonType = newButtonType;

    let foundOptions = origSendTypes.filter(f => f.value == "sendAndClose" || f.value == "send");
    if (creatingConversation || activeConversation.status === 2 || wizardConversation) {
      foundOptions = origSendTypes.filter(f => f.value == "send")
    }
    if (finalMessageType == "NOTE") {
      foundOptions = origSendTypes.filter(f => f.value == "addNote")
      if (activeConversation.status !== 2) {
        foundOptions = origSendTypes.filter(f => f.value == "addNote" || f.value == "addNoteClose")
      }
    }

    let foundButton = foundOptions.find(f => f.value == finalButtonType);

    let finalButtonLabel = "";
    if (foundButton) {
      finalButtonLabel = foundButton.label;
      finalButtonType = foundButton.value;
    }

    setSendTypes(foundOptions);
    setSendButtonLabel(finalButtonLabel);
    setButtonType(finalButtonType);
    setMessageType(finalMessageType);
  }

  const addFiles = (event) => {
    const arr = [...files]
    let error = ""
    if (event.target.files?.length) {
      for (const file of event.target.files) {
        if (file.size > 5242880) {
          error = i18n.t("chat:inbox.conversation.composer.sizeLimit")
          continue
        }
        arr.push(file)
      }
    }
    if (error) dispatch(alterSnackbar(true, error))
    setFiles(arr)
    uploader.current.value = null;
  }

  const removeFile = (index) => {
    const arr = [...files]
    arr.splice(index, 1)
    setFiles(arr)
  }

  if (firstLoad) return null;

  return (
    <>
      {(!savedDraft || !!message) && <DraftStatus saved={savedDraft} saving={savingDraft} />}
      <div className={classes}>
        <ComposerComponent
          editorDisabled={editorDisabled || wizardConversation || userRole > 1}
          message={message}
          setMessage={setMessage}
          goClearEditor={clearEditor}
          setEditorRef={setEditorRef}
          typing={() => { setLastTypedDate(new Date()) }}
          userRole={userRole}
          sendMessage={sendMessage}
        />

        {files.length > 0 && <div className="inbox-compose__attachments">
          {Array.from(files).map((file, index) => {
            return <div key={index} className="inbox-compose__attachments__attachment">
              <div className="inbox-compose__attachments__attachment_label">
                {file.name}
              </div>
              <div className="inbox-compose__attachments__attachment_size">
                {humanizeBytes(file.size)}
              </div>
              <UILIB.Icons icon="cross" style={{ height: 16, width: 16 }} color="#595C61" onClick={() => removeFile(index)} />
            </div>
          })}</div>}

        <div className={styles.actions}>
          <div className={styles.actionsLeft}>
            {(!wizardConversation && !editorDisabled) &&
              <>
                <InboxButton
                  variant="ghost"
                  size="s"
                  iconOnly
                  aria-label="Insert attachment"
                  data-tip={i18n.t("chat:inbox.conversation.composer.addAttachment")} data-for="attachment"
                  disabled={userRole > 1}
                  onClick={() => {
                    uploader.current.click()
                  }}
                >
                  <UILIB.Icon name="paper-clip" />
                </InboxButton>
                <input type="file" ref={uploader} style={{ display: 'none' }} onChange={addFiles} accept=".png,.jpg,.jpeg,.gif,.pdf" multiple />
                <UILIB.Dialog.Root open={findSavedReplyOpen} onOpenChange={setFindSavedReplyOpen}>
                  <UILIB.Dialog.Trigger asChild>
                    <InboxButton
                      variant="ghost"
                      size="s"
                      iconOnly
                      aria-label="Saved Replies"
                      data-tip={i18n.t("chat:inbox.conversation.composer.savedReplies")} data-for="template"
                      disabled={userRole > 1}
                    >
                      <UILIB.Icon name="replies" />
                    </InboxButton>
                  </UILIB.Dialog.Trigger>
                  {findSavedReplyOpen && <FindReplyDialog onSelect={(v) => {
                    if (v && editorRef) {
                      // setMessage(v)
                      editorRef.current.setContent(v)
                    }
                    setFindSavedReplyOpen(false)
                  }} inboxId={activeConversation.InboxId} currentContent={message} />}
                </UILIB.Dialog.Root>

                <SmileyPickerTool userRole={userRole} onSelect={(smiley) => {
                  //add smiley to tinymce content
                  if (editorRef) {
                    editorRef.current.insertContent(smiley);
                  }

                }} />
              </>}
          </div>

          <div className={styles.actionsRight}>
            {messageError && messageError.length > 0 && <div className="text-red">{messageError}</div>}

            {(!wizardConversation) && <UILIB.SelectNewDefault
              disabled={userRole > 1}
              value={messageType}
              data={messageTypes}
              align="end"
              triggerProps={{ size: 's', variant: "ghost" }}
              onValueChange={changeMessageType}
              popupStyle={{ minWidth: 0 }}
              fullWidth={false}
            />}

            <SendButton
              size="s"
              onClick={sendMessage}
              dropDownOptions={sendTypes}
              dropDownSelected={buttonType}
              dropDownChange={(value) => { workOutSendOptions(undefined, value) }}
              disabled={wizardConversation || userRole > 1 || sending}
              title={wizardConversation ? i18n.t("chat:inbox.pleaseAddAChannelFirst") : ""}
              popupAlign="end"
              popupMinWidth={0}
            >
              {sendButtonLabel}
            </SendButton>
          </div>
        </div>
      </div>
    </>
  );
}

function humanizeBytes(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}

function DraftStatus({ saved = false, saving = false }) {
  if (saving) return <div className="inbox-center__composer__draft">
    <UILIB.LoadingIcons style={{ height: 20, width: 20 }} />
  </div>
  if (saved) return <div className="inbox-center__composer__draft">
    <UILIB.Icons icon="tickCircle" data-for="draft_status" data-tip="Draft Saved" />
  </div>
  return null;

}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';


//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class SalesMateBindFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appData: {},
            isLoaded: false,
            fields: []
        };

        this.getApp = this.getApp.bind(this);
        this.getFields = this.getFields.bind(this);
        this.toggleField = this.toggleField.bind(this);
        this.save = this.save.bind(this);

    }

    componentDidMount() {
        this.getApp();
    }

    getApp() {
        var self = this;
        axios.get("/application/account/app/38").then(_theApp => {
            self.setState({ appData: _theApp.data.Application }, this.getFields)
        })
    }

    getFields() {
        axios.get('/application/salesmate/fields').then(response => {
            this.setState({
                fields: response.data.Fields,
                isLoaded: true
            })
        })
    }

    toggleField(field) {
        var appData = this.state.appData
        appData.appSettings.setupFinished = false
        var fields = appData.appSettings.fields || {}
        if (fields[field.fieldName]) {
            delete fields[field.fieldName]
        } else {
            fields[field.fieldName] = { mergeTag: field.mergeTag, fieldDesc: field.displayName, name: field.fieldName }
        }
        appData.appSettings.fields = fields
        this.setState({
            appData
        })
    }

    save() {
        axios.put('/application/account/' + this.state.appData.id, this.state.appData).then(() => {
            if (this.props.finish) {
                this.props.finish()
            } else {
                this.props.history.push('/cp/company/integrations/apps/salesmate')
            }
        }).catch(err => {
        })
    }



    render() {

        if (this.state.isLoaded == false) return <div className='center-xs'>
            <h4 className='mar-b25'>Reading data from Salesmate, please wait</h4>
            <UILIB.LoadingIcons iconType="2" />
        </div>
        var fieldMapping = this.state.appData.appSettings.fields || {}

        return <div>
            <UILIB.Row>
                <UILIB.Column xs={12} margin={0}>
                    <UILIB.Paper>
                        <UILIB.Row>
                            <UILIB.Column xs={12} margin={0}>
                                <h4 className="mar-b25">Fields to Import</h4>
                                <div className='mar-b10'>Please select the fields you would like to Synchronise with Transpond from Salesmate.</div>
                                <UILIB.Row className="mar-b30">
                                    <UILIB.Column xs={12} sm={6} md={4} margin={0}>
                                        <UILIB.CheckBox checked={true} >Email Address</UILIB.CheckBox>
                                    </UILIB.Column>
                                    {this.state.fields.map((field, index) => {
                                        var checked = !!fieldMapping[field.fieldName]
                                        return <UILIB.Column xs={12} sm={6} md={4} margin={0} key={index} className="mar-b5">
                                            <UILIB.CheckBox checked={checked} onChange={() => this.toggleField(field)}>{field.displayName}</UILIB.CheckBox>
                                        </UILIB.Column>
                                    })}
                                </UILIB.Row>
                            </UILIB.Column>
                            <UILIB.Column xs={12} className="end-xs">
                                <UILIB.Button text="Save and Continue" onClick={this.save} />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>



            </UILIB.Row>

        </div >

    };


};



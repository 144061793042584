import React from 'react'
import ReactTooltip from 'react-tooltip';
import Icons from '../../Icons';
export default class CheckBox extends React.Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
    };

    onClick() {
        if (this.props.disabled) return
        this.ref.current.click()
    }

    render() {

        let outerClassName = "checkboxWrapper ";
        if (this.props.outerClassName) outerClassName += this.props.outerClassName;

        var isChecked = false;
        if (this.props.checked && this.props.checked != "0" && this.props.checked != 0) {
            isChecked = true;
            outerClassName += " checked"
        }

        if (this.props.disabled) {
            outerClassName += " disabled"
        }

        let label = "";
        if ((this.props.label && this.props.labelOptions) || this.props.labelOptions) {
            label = <div className="txtInput_label_flex">
                <div className="txtInput_label">{this.props.label}</div>
                <div className="txtInput_labelOptions">{this.props.labelOptions}</div>
            </div>
        } else if (this.props.label) {
            label = <div className="txtInput_label">{this.props.label}</div>
        }


        return <div className={outerClassName} style={this.props.outerStyle} onClick={this.onClick.bind(this)}>
            {label}
            <input
                type="checkbox"
                name={this.props.name}
                className={"checkbox " + this.props.className}
                onClick={this.props.onClick}
                onChange={this.props.onChange}
                checked={isChecked}
                disabled={this.props.disabled}
                style={this.props.style}
                ref={this.ref}
                data-tip={this.props["data-tip"]} data-for={this.props["data-for"]}
            />
            {this.props["data-tip"] && this.props["data-for"] && <ReactTooltip id={this.props["data-for"]} effect="solid" />}
            <div className="icon">
                <Icons icon="tick" color="white" style={{ height: 20, width: 18 }} />
            </div>
            {this.props.children}
        </div >

    }
}


import React from 'react'

export default class IconsArrowDown extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9877 7.9877C14.9877 7.72248 14.8824 7.46813 14.6948 7.28059C14.5073 7.09306 14.253 6.9877 13.9877 6.9877C13.7225 6.9877 13.4682 7.09306 13.2806 7.28059C13.0931 7.46813 12.9877 7.72248 12.9877 7.9877L12.9877 13.5737L11.6947 12.2807C11.5061 12.0985 11.2535 11.9977 10.9913 12C10.7291 12.0023 10.4783 12.1075 10.2929 12.2929C10.1075 12.4783 10.0023 12.7291 10.0001 12.9913C9.99779 13.2535 10.0986 13.5061 10.2807 13.6947L13.2807 16.6947C13.4683 16.8822 13.7226 16.9875 13.9877 16.9875C14.2529 16.9875 14.5072 16.8822 14.6947 16.6947L17.6947 13.6947C17.7903 13.6025 17.8664 13.4921 17.9188 13.3701C17.9713 13.2481 17.9988 13.1169 18 12.9841C18.0011 12.8513 17.9758 12.7196 17.9256 12.5967C17.8753 12.4739 17.801 12.3622 17.7071 12.2683C17.6132 12.1744 17.5016 12.1002 17.3787 12.0499C17.2558 11.9996 17.1241 11.9743 16.9913 11.9755C16.8586 11.9766 16.7273 12.0042 16.6053 12.0566C16.4833 12.109 16.373 12.1852 16.2807 12.2807L14.9877 13.5737L14.9877 7.9877ZM4.98774 11.9877C4.98774 12.2529 5.0931 12.5073 5.28064 12.6948C5.46817 12.8823 5.72253 12.9877 5.98774 12.9877C6.25296 12.9877 6.50731 12.8823 6.69485 12.6948C6.88238 12.5073 6.98774 12.2529 6.98774 11.9877L6.98774 6.4017L8.28074 7.6947C8.37299 7.79021 8.48333 7.86639 8.60534 7.9188C8.72734 7.97121 8.85856 7.9988 8.99134 7.99995C9.12412 8.00111 9.2558 7.9758 9.3787 7.92552C9.50159 7.87524 9.61324 7.80099 9.70714 7.7071C9.80103 7.6132 9.87528 7.50155 9.92556 7.37866C9.97584 7.25576 10.0011 7.12408 9.99999 6.9913C9.99884 6.85852 9.97125 6.7273 9.91884 6.6053C9.86644 6.48329 9.79025 6.37295 9.69474 6.2807L6.69474 3.2807C6.50721 3.09323 6.25291 2.98791 5.98774 2.98791C5.72258 2.98791 5.46827 3.09323 5.28074 3.2807L2.28074 6.2807C2.09858 6.4693 1.99779 6.72191 2.00007 6.9841C2.00235 7.2463 2.10752 7.49711 2.29292 7.68252C2.47833 7.86793 2.72914 7.9731 2.99134 7.97537C3.25354 7.97765 3.50614 7.87686 3.69474 7.6947L4.98774 6.4017L4.98774 11.9877Z" fill={color} />
        </svg>


    }
}        
import React from 'react'
import { connect } from 'react-redux';
import UILIB from '../../../../Common-Objects/Lib';
import i18n from '~/i18n'
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class SegmentRuleLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.changeField = this.changeField.bind(this);
        this.changeDateField = this.changeDateField.bind(this);
    }

    changeField(event) {
        var ourSegment = JSON.parse(JSON.stringify(this.props.segmentData));
        ourSegment[event.target.name] = event.target.value;
        this.props.updateSegment(ourSegment, this.props.dataId, event.target.name, this.props.segmentGroupId);
    }

    changeDateField(fieldName, newDateMoment) {
        var ourSegment = this.props.segmentData;
        var currVal = ourSegment.value;

        if (this.props.segmentData.operator == "between" || this.props.segmentData.operator == "not between") {
            if (fieldName == "value1") {
                currVal[0] = newDateMoment.utc().startOf('day').format();
            }
            if (fieldName == "value2") {
                currVal[1] = newDateMoment.utc().endOf('day').format();
            }
        }
        else if (this.props.segmentData.operator == "be days after" || this.props.segmentData.operator == "be days before" || this.props.segmentData.operator == "be more days after" || this.props.segmentData.operator == "be more days before" || this.props.segmentData.operator == "be less days after" || this.props.segmentData.operator == "be less days before") {
            if (isNaN(newDateMoment) || newDateMoment < 0) {
                currVal = 0;
            }
            else {
                currVal = newDateMoment;
            }
        }
        else {
            currVal = newDateMoment.utc().startOf('day').format();
        }
        ourSegment.value = currVal;

        this.props.updateSegment(ourSegment, this.props.dataId, "value", this.props.segmentGroupId);
    }

    render() {

        var hasError = "";
        let fieldError = "";
        var ourFieldDefinition = this.props.fields.find(f => f.value == this.props.segmentData.customFieldId)
        var currentOptionType = "TEXT";
        var options;
        if (!ourFieldDefinition) {
            hasError = " ";
            fieldError = "Field has been deleted, please remove this line or select another field.";
        }
        else {
            currentOptionType = ourFieldDefinition.optionsType;
            options = ourFieldDefinition.options;
            if (options && options.length) {
                options.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            }
        }

        if (currentOptionType == "SCORE" && this.props.segmentData.operator == 'starRating') {
            options = [{
                label: "0 Star",
                value: 1
            }, {
                label: "1 Star",
                value: 10
            }, {
                label: "2 Star",
                value: 20
            }, {
                label: "3 Star",
                value: 30
            }, {
                label: "4 Star",
                value: 40
            }, {
                label: "5 Star",
                value: 9999999
            }]
        }

        var foundOps = this.props.operators[currentOptionType];
        var hasOperators = true;
        if (!foundOps || !foundOps.length) hasOperators = false;

        var notFirst = 1;
        if (this.props.totalRules < 2) notFirst = 0;

        var customFieldValue1 = this.props.segmentData.value;
        var customFieldValue2 = ""; //for multi date fields etc
        if (currentOptionType == "DATE") {
            if (Array.isArray(customFieldValue1)) {
                customFieldValue2 = customFieldValue1[1]
                customFieldValue1 = customFieldValue1[0]
            }
        }
        if (currentOptionType == "BOOLEAN" && typeof customFieldValue1 != "boolean") {
            //set default value for bool fields to undefiend to stop FALSE getting defaulted UI wise
            customFieldValue1 = undefined;
        }
        var dropDownVals = [];
        if (currentOptionType == "GROUP") {
            //pre populate group dropdown data
            dropDownVals = this.props.groupData;
            let foundSelVal = dropDownVals.find(f => f.value == customFieldValue1)
            if (!foundSelVal && customFieldValue1) {
                hasError = "Group has been deleted, Please select another Group."
            }
        }
        if (currentOptionType == "CAMPAIGN") {
            //pre populate campaign dropdown data
            dropDownVals = this.props.campaignData;
            let foundSelVal = dropDownVals.find(f => f.value === customFieldValue1)
            if (!foundSelVal && customFieldValue1) {
                if (this.props.allCampaignData?.length) {
                    foundSelVal = this.props.allCampaignData.find(f => f.value === customFieldValue1)
                }
                if (!foundSelVal && customFieldValue1) {
                    hasError = "Campaign has been deleted, Please select another Campaign."
                }
            }
        }

        if (currentOptionType == "SUBSCRIBERTAGS") {
            //pre populate campaign dropdown data
            dropDownVals = this.props.subscriberTags;
            let foundSelVal = dropDownVals.find(f => f.value === customFieldValue1)
            if (!foundSelVal && customFieldValue1) {
                if (!this.props.isCapsule) {
                    hasError = "Tag has been deleted, please select another tag"
                }
                else {
                    hasError = "Tag has been deleted in Capsule. Please update this list in Capsule."
                }
            }
        }
        if (currentOptionType == "LINKTAGS") {
            //pre populate campaign dropdown data
            dropDownVals = this.props.linkTags;
        }

        if (currentOptionType == "COUNTRY") {
            dropDownVals = this.props.countryCodes;
        }

        if (this.props.errors && this.props.errors.lines[this.props.dataId]) {
            hasError = this.props.errors.lines[this.props.dataId];
        }

        var isPos = true;
        var operators = this.props.operators[currentOptionType];
        if (this.props.segmentData.andOr == "or") {
            operators = operators.filter(f => f.positive == true)
        }

        var lastMargin = "mar-b0"
        // if (this.props.dataId == this.props.totalRules - 1) lastMargin = "mar-b20";

        var operator = this.props.segmentData.operator;

        var currentInputType = "text";
        if (currentOptionType == "TEXT" || currentOptionType == "NUMBER" || currentOptionType == "TELNO") {
            currentInputType = currentOptionType;
            if (currentOptionType == "TELNO" && operator != "=" && operator != "!=") {
                currentInputType = "TEXT";
            }
        }

        if ((this.props.totalContains >= 2 || this.props.totalSubscribers && this.props.totalSubscribers > 100000) && operator != "contain" && operator != "not contain") {
            operators = operators.filter(f => f.value != "contain" && f.value != "not contain")
        }
        if (this.props.segmentData && this.props.segmentData.customFieldId == "sentWithin") {
            operators = operators.filter(f => f.value != "=" && f.value != "!=" && f.value != "in" && f.value != "not in")
        }

        let fields = this.props.fields
        if (this.props.contactOnlyFields) {
            fields = fields.map(field => {
                if (field.entityType === 2) return { ...field, disabled: true, label: field.label + ' (cannot have organisation only fields and contact only fields)' }
                return field
            })
        }

        return <div>
            {(this.props.dataId > 0) && <div className="mar-t10 mar-b10 mar-l20">
                {this.props.segmentData.andOr == "and" ? i18n.t('subscribers:segments.edit.and') : i18n.t('subscribers:segments.edit.or')}
            </div>
            }
            <UILIB.Row margin={0} className="middle-xs mar-b20">

                <UILIB.Column xs={4} margin={0}>
                    <UILIB.Select name="customFieldId" placeholder="Select a Field" filter={true} disabled={this.props.readonly} value={this.props.segmentData.customFieldId} data={fields} onChange={this.changeField} className={`${lastMargin} cyt-add-excl-dropdown1`} error={fieldError} />
                </UILIB.Column>

                {hasOperators &&
                    <UILIB.Column xs={3} margin={0}>
                        <UILIB.Select autoWidth={true} placeholder="Select an Operator" hideIconInHeader={false} disabled={this.props.readonly} value={this.props.segmentData.operator} name="operator" data={operators} onChange={this.changeField} className={`${lastMargin} cyt-add-excl-dropdown2`} />
                    </UILIB.Column>
                }
                <UILIB.Column margin={0} xs={hasOperators ? 4 : 6}>
                    {operator !== 'is blank' && operator !== 'is not blank' && <div>
                        {(currentOptionType == "CAMPAIGN") &&
                            <UILIB.Select
                                disabled={this.props.readonly}
                                name="value"
                                data={dropDownVals}
                                otherData={this.props.allCampaignData}
                                error={hasError}
                                onChange={this.changeField}
                                value={customFieldValue1}
                                className={lastMargin}
                                filter={true}
                                updateFilter={(filterText) => {
                                    this.props.loadCampaigns(filterText)
                                }}
                                filterText="Search"
                                loadingData={this.props.loadingCampaignData}
                            />
                        }
                        {(currentOptionType == "GROUP" || currentOptionType == "SUBSCRIBERTAGS" || currentOptionType == "COUNTRY") &&
                            <UILIB.Select disabled={this.props.readonly} name="value" data={dropDownVals} error={hasError} onChange={this.changeField} value={customFieldValue1} className={lastMargin} filter={true} />
                        }
                        {(currentOptionType == "SCORE") && <>
                            {this.props.segmentData.operator !== 'starRating' &&
                                <UILIB.TextInput disabled={this.props.readonly} name="value" type={currentInputType} onChange={this.changeField} value={customFieldValue1} error={hasError} className={lastMargin} />
                            }
                            {this.props.segmentData.operator == 'starRating' &&
                                <UILIB.Select disabled={this.props.readonly} name="value" placeholder="Please select a value" data={options} onChange={this.changeField} value={customFieldValue1} error={hasError} className={lastMargin} />
                            }
                        </>
                        }
                        {currentOptionType === 'LINKTAGS' && <div className='row'>
                            {(this.props.segmentData.operator === 'within' || this.props.segmentData.operator === 'not within') && <>
                                <div className="col-xs-6">
                                    <UILIB.TextInput type="number" value={this.props.segmentData.days} placerholder="X Days" name="days" onChange={this.changeField} />
                                </div>
                                <div className="col-xs-6">
                                    <UILIB.Select data={[{ label: i18n.t('subscribers:segments.edit.anyLinkTag'), value: -1 }].concat(this.props.linkTags)} name="value" value={this.props.segmentData.value} onChange={this.changeField} />
                                </div>
                            </>}
                            {this.props.segmentData.operator !== 'within' && this.props.segmentData.operator !== 'not within' && <>
                                <div className="col-xs-12">
                                    <UILIB.Select data={this.props.linkTags} value={this.props.segmentData.value} name="value" onChange={this.changeField} placeholder={i18n.t('subscribers:segments.edit.selectLinkTag')} />
                                </div>
                            </>}
                        </div>}

                        {(currentOptionType == "DATE" && (this.props.segmentData.operator != "between" && this.props.segmentData.operator != "not between" && this.props.segmentData.operator != "be days after" && this.props.segmentData.operator != "be days before" && this.props.segmentData.operator != "be more days after" && this.props.segmentData.operator != "be more days before" && this.props.segmentData.operator != "be less days after" && this.props.segmentData.operator != "be less days before")) &&
                            <UILIB.TextInput disabled={this.props.readonly} name="value" type={currentOptionType} onChange={this.changeDateField} value={customFieldValue1} error={hasError} className={lastMargin} />
                        }
                        {(currentOptionType == "DATE" && (this.props.segmentData.operator == "between" || this.props.segmentData.operator == "not between")) && (
                            <div class="row">
                                <div class="col-lg-6 width-100 m-mar-b10-lg">
                                    <UILIB.TextInput
                                        disabled={this.props.readonly}
                                        name="value1"
                                        type="DATE"
                                        onChange={(event, newVal) => {
                                            this.changeDateField("value1", newVal);
                                        }}
                                        value={customFieldValue1}
                                        error={hasError}
                                    />
                                </div>

                                <div class="col-lg-6 width-100">
                                    <UILIB.TextInput
                                        disabled={this.props.readonly}
                                        name="value2"
                                        type="DATE"
                                        onChange={(event, newVal) => {
                                            this.changeDateField("value2", newVal);
                                        }}
                                        value={customFieldValue2}
                                        error={hasError}
                                        className={lastMargin}
                                    />
                                </div>
                            </div>
                        )}
                        {(currentOptionType == "DATE" && (this.props.segmentData.operator == "be days after" || this.props.segmentData.operator == "be days before" || this.props.segmentData.operator == "be more days after" || this.props.segmentData.operator == "be more days before" || this.props.segmentData.operator == "be less days after" || this.props.segmentData.operator == "be less days before")) && <span>
                            <UILIB.TextInput disabled={this.props.readonly} name="value" type="NUMBER" onChange={(event) => { this.changeDateField(undefined, event.target.value) }} value={customFieldValue1} error={hasError} placeholder={i18n.t('subscribers:segments.edit.days')} />
                        </span>}

                        {(currentOptionType == "TEXT" || currentOptionType == "NUMBER" || currentOptionType == "TELNO" || currentOptionType == "DOMAIN") && <UILIB.TextInput disabled={this.props.readonly} name="value" type={currentInputType} onChange={this.changeField} value={customFieldValue1} error={hasError} className={lastMargin} />}
                        {currentOptionType == "MULTISELECT" && !Array.isArray(options) && <UILIB.TextInput disabled={this.props.readonly} name="value" type="TEXT" onChange={this.changeField} value={customFieldValue1} error={hasError} className={lastMargin} />}
                        {(currentOptionType == "SELECT" || currentOptionType == "MULTISELECT") && Array.isArray(options) && <UILIB.Select disabled={this.props.readonly} name="value" placeholder="Please select a value" data={options} onChange={this.changeField} value={customFieldValue1} error={hasError} className={lastMargin} />}
                        {currentOptionType == "BOOLEAN" && <UILIB.Select disabled={this.props.readonly} name="value" placeholder="Please select a value" data={[{ label: "True", value: true }, { label: "False", value: false }]} onChange={this.changeField} value={customFieldValue1} error={hasError} className={lastMargin} />}

                    </div>}
                </UILIB.Column>
                <UILIB.Column xs={1} margin={0} className="quickFlex" style={{ justifyContent: "end", alignItems: "center" }}>
                    {this.props.segmentData.operator !== 'contain' && this.props.segmentData.operator !== 'not contain' && <UILIB.Icons icon="copyClipboard" color={this.props.readonly ? 'grey' : ''} data-for={this.props.dataId + '-copy'} data-tip="Copy Rule" style={{ width: 30, height: 30 }} onClick={() => { this.props.readonly ? undefined : this.props.copyRule(this.props.dataId, this.props.segmentGroupId) }} />}
                    {(this.props.totalRules > 1) && <UILIB.Icons icon="crossCircle" className="mar-l10" data-for={this.props.dataId + '-delete'} data-tip="Delete Rule" color={this.props.readonly ? 'grey' : 'red'} style={{ width: 30, height: 30 }} onClick={() => { this.props.readonly ? undefined : this.props.deleteSegment(this.props.dataId, this.props.segmentGroupId) }} />}
                    {this.props.segmentData.orgOnly && <UILIB.Icons icon="organisations" className="mar-l10" color="grey" data-for={this.props.dataId + '-orgonly'} data-tip="Organisation Only Filter" style={{ width: 30, height: 30 }} />}
                    {!this.props.readonly && this.props.totalRules - 1 == this.props.dataId && <UILIB.Icons
                        icon="plus"
                        color={this.props.readonly ? 'grey' : ''}
                        className="mar-l10"
                        data-for={this.props.dataId + '-add'}
                        data-tip="Add Rule"
                        style={{ width: 30, height: 30 }}
                        onClick={() => { this.props.readonly ? undefined : this.props.addSegment(this.props.segmentGroupId) }}
                        alt={i18n.t('subscribers:segments.edit.addAnotherRule')}
                        title={i18n.t('subscribers:segments.edit.addAnotherRule')}
                    />}



                </UILIB.Column>

            </UILIB.Row >
        </div>
    }
}

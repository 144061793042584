import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import i18n from '~/i18n'

export default class IncGoCopySubs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            copyGroupID: 0,
            errorHolder: null,
            otherGroupData: {},
            pageLoaded: false
        };

        this.goCopySubs = this.goCopySubs.bind(this);
        this.goClickGroup = this.goClickGroup.bind(this);
        this.goGetGroups = this.goGetGroups.bind(this);
    }

    componentDidMount() {
        this.goGetGroups();
    }

    goGetGroups() {
        var self = this;

        axios.get('/group').then(groups => {
            var groupData = groups.data.Groups.filter(theGroup => {
                return theGroup.id != self.props.groupId
            })
            if (groupData.length > 0) {
                var groupID = groupData[0].id;
                self.setState({ otherGroupData: groupData, copyGroupID: groupID, pageLoaded: true });
            } else {
                self.setState({ otherGroupData: groupData, pageLoaded: true });
            }

        })
    }

    goCopySubs() {
        var self = this;
        var theSubs = this.props.subscribers.filter(sub => sub.checkBox.isChecked == true);
        var idsToCopy = theSubs.map((element, index) => {
            return element.subscriberData.id;
        })
        this.setState({ pageLoaded: false })
        axios.post('/group/' + this.props.groupId + '/subscriber/copy', {
            subscriberIds: idsToCopy,
            destination: this.state.copyGroupID,
            delete: true
        }).then((res) => {
            self.props.reloadAfterTickedAndChanged();

        }).catch(err => {
            self.setState({ errorHolder: err.data.error.message });
        });

    }

    goClickGroup(e) {
        this.setState({ copyGroupID: e.currentTarget.value })
    }

    render() {

        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />
        if (this.state.pageLoaded === true) {

            if (this.state.otherGroupData.length > 0) {
                return <div>
                    <div className="quickFlex mar-b25">
                        <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.goBack}>{i18n.t('goBack')}</UILIB.Hint>
                    </div>

                    <h4 className="mar-b25">{i18n.t('subscribers:overview.moveHeader')}</h4>
                    <div className="mar-b25">{i18n.t('subscribers:overview.moveSubHeader')}</div>

                    <UILIB.Select outerClassName="mar-b25" onChange={this.goClickGroup} data={this.state.otherGroupData.map(group => {
                        return { value: group.id, label: group.groupName }
                    })} value={this.state.copyGroupID} error={this.state.errorHolder} />


                    <UILIB.Button text={i18n.t('subscribers:overview.moveButton')} className="button-primary" onClick={this.goCopySubs} />

                </div>

            }
            else {
                return <div>
                    <div className="quickFlex mar-b25">
                        <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.goBack}>{i18n.t('goBack')}</UILIB.Hint>
                    </div>

                    <h4>{i18n.t('subscribers:overview.moveNoGroups')}</h4>

                </div >
            }
        }
        else {
            return <div></div>
        }
    }
};


import React, { Component } from 'react';
import axios from 'axios';
import axios2 from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import ReactTooltip from 'react-tooltip'
import i18n from '~/i18n'
import SiteVars from '~/Classes/siteVars';
class Unsplash extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            page: 0,
            total: 0,
            totalPages: 0,
            results: [],
            selectedFile: undefined,
            processing: false,
            hasSearched: false,
            loading: false
        }

        this.updateSearchText = this.updateSearchText.bind(this)
        this.search = this.search.bind(this)
        this.loadData = this.loadData.bind(this)
        this.loadMore = this.loadMore.bind(this)
        this.select = this.select.bind(this)
    }

    updateSearchText(ev) {
        this.setState({
            searchText: ev.target.value
        })
    }

    loadMore(page) {
        this.setState({
            page: parseInt(page)
        }, this.loadData)
    }

    loadData() {
        this.setState({ loading: true })
        axios.get('https://api.unsplash.com/search/photos/?query=' + this.state.searchText + '&per_page=12&page=' + (this.state.page + 1) + '&client_id=12b22f8790ea81e1073e22353379de9400c9b70e2a89bad5aabf84e26779a2a2')
            .then(response => {
                this.setState({
                    results: response.data.results,
                    total: response.data.total,
                    totalPages: response.data.total_pages,
                    loading: false
                })
            })
    }

    search() {

        var hasSearched = false;
        if (this.state.searchText) hasSearched = true;

        this.setState({
            page: 0,
            results: [],
            total: 0,
            totalPages: 0,
            hasSearched
        }, this.loadData)
    }

    select(file) {
        // download file, add to file manager under unsplash folder, select
        this.setState({
            processing: true
        })
        file.search = this.state.searchText
        axios2.post('/fileManager/' + -1 + '/file/unsplash', file).then(response => {
            this.setState({
                processing: false
            })
            this.props.onSelect(response.data.File, response.data.Folder.id)
        }).catch(console.log)
    }

    render() {
        const loader = <UILIB.LoadingLine key={0}></UILIB.LoadingLine>;

        if (!this.state.hasSearched) {
            return <div className="center-xs">
                <img src={SiteVars.repoEndPoint + "siteContent/appLogos/unsplashLogo.png"} style={{ width: "200px" }} className="mar-b25" />
                <div className="mar-b5">{i18n.t('templates:fileManager.unsplash.subTitle')}</div>
                <div className="mar-b25">{i18n.t('templates:fileManager.unsplash.subTitle2')}</div>
                <UILIB.TextInput
                    name="searchText"
                    outerClassName="mar-b25"
                    onEnterPress={this.search}
                    onChange={this.updateSearchText}
                    value={this.state.searchText}
                    placeholder={i18n.t('templates:fileManager.unsplash.searchPlaceholder')}
                    iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                />
                <a style={{ fontSize: "12px" }} href="https://unsplash.com/" target="_blank">{i18n.t('templates:fileManager.unsplash.unsplashLink')}</a>

            </div>
        }
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <UILIB.TextInput
                            name="searchText"
                            outerClassName="mar-b25"
                            onEnterPress={this.search}
                            onChange={this.updateSearchText}
                            value={this.state.searchText}
                            placeholder={i18n.t('templates:fileManager.unsplash.searchPlaceholder')}
                            iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                        />
                    </div>
                </div>

                <div className="filemanager-tiles-holder mar-b25" style={{ height: "calc(100vh - 260px)" }}>
                    {this.state.loading && <UILIB.LoadingIcons iconType="2" />}
                    {!this.state.total && !this.state.loading && <div>
                        {this.state.searchText ? i18n.t('templates:fileManager.unsplash.noImages') : i18n.t('templates:fileManager.unsplash.search')}
                    </div>}
                    <div className="row no-marg">
                        {this.state.results.map((file, index) => {
                            var classes = "filemanager-tile";
                            var selected = (this.state.selectedFile && this.state.selectedFile.id == file.id)
                            if (selected) classes += " filemanager-tile-selected";
                            var url = file.urls.small;

                            return <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <div className={classes} onClick={() => this.setState({ selectedFile: file })}>

                                    <div className="filemanager-tile-image" style={{ backgroundImage: "url(" + url + ")" }}>
                                        <img src={url} style={{ display: "none" }} />
                                    </div>

                                    {selected && <div className="filemanager-tile-controls">
                                        <div className="filemanager-tile-controls-selectIcon">
                                            {/* {!this.state.processing && <UILIB.Icons
                                                icon="arrowSelect"
                                                onClick={() => this.select(file)}
                                                alt={i18n.t('templates:fileManager.details.select')}
                                                title={i18n.t('templates:fileManager.details.select')}
                                            />} */}

                                            {!this.state.processing && <UILIB.Button text={i18n.t('templates:fileManager.details.select')} onClick={() => this.select(file)} />}
                                            {this.state.processing && <UILIB.LoadingIcons iconType="2" width="100px" />}
                                        </div>

                                        <div className="filemanager-tile-controls-lower">

                                            <div className="filemanager-tile-controls-name">
                                                {i18n.t('templates:fileManager.unsplash.photoBy')}
                                                <a className="text-white mar-r5 mar-l5" target="_blank" href={file.user.links.html + '?utm_source=MPZMail&utm_medium=referral'}>
                                                    {file.user.name}
                                                </a>
                                                on
                                                <a className="text-white mar-l5" target="_blank" href="https://unsplash.com/?utm_source=MPZMail&utm_medium=referral">
                                                    Unsplash
                                                </a>
                                            </div>
                                        </div>

                                    </div>}
                                </div>
                            </div>
                        })}
                    </div>
                </div>

                <div className="center-xs">
                    <UILIB.PagingBlock totalRows={this.state.total} pageSize={12} numberOfLinks="10" currentPage={this.state.page}
                        changePage={this.loadMore} text={"page:"} />
                </div>


            </div >
        );
    }
}

export default Unsplash;
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';

class DuplicateDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            selectedGroup: this.props.groupId,
            newName: '',
            loaded: false,
            creating: false
        }
        this.getGroups = this.getGroups.bind(this)
        this.update = this.update.bind(this)
        this.create = this.create.bind(this)
    }

    componentDidMount() {
        this.getGroups()
    }

    getGroups() {
        axios.get('/group?hideUsers=true').then(response => {
            this.setState({
                groups: response.data.Groups,
                loaded: true,
                newName: this.props.form.formName + ' - duplicate'
            })
        })
    }

    update(ev) {
        this.setState({
            [ev.currentTarget.name]: ev.currentTarget.value
        })
    }

    create() {
        this.setState({
            creating: true
        })
        axios.post('/group/' + this.props.groupId + '/form/' + this.props.form.id + '/duplicate', {
            name: this.state.newName,
            groupId: this.state.selectedGroup
        }).then(this.props.finish).catch(console.log)
    }

    render() {
        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />
        return (
            <div>
                <h4 className="mar-b25">Duplicate Form</h4>


                <UILIB.TextInput outerClassName="mar-b25" label={"New Form Name"} value={this.state.newName} name="newName" onChange={this.update} value={this.state.newName} />

                <UILIB.Select outerClassName="mar-b25" label="Select the group the create form for" onChange={this.update} name="selectedGroup" value={this.state.selectedGroup} data={this.state.groups.map(g => {
                    return {
                        label: g.groupName,
                        value: g.id
                    }
                })} />

                {this.state.creating && <div>
                    <UILIB.LoadingIcons iconType="2" />
                </div>
                }
                {!this.state.creating && <div className="row">
                    <div className="col-xs-6">
                        <UILIB.Button disabled={this.state.creating} className="button-primary button-fullwidth" text="Create" onClick={this.create} />
                    </div>
                    <div className="col-xs-6">
                        <UILIB.Button disabled={this.state.creating} className="button-fullwidth" text="Cancel" onClick={this.props.cancel} />
                    </div>


                </div>
                }
            </div >
        );
    }
}

export default DuplicateDrawer;
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import ArrowDown from '~/assets/images/icons/nav-arrow-down.svg';

class PipedriveFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availableFields: [],
            ready: false,
            filter: ''
        }
        this.getAvailableFields = this.getAvailableFields.bind(this)
        this.toggleField = this.toggleField.bind(this)
        this.toggleAllFields = this.toggleAllFields.bind(this)
        this.filterFields = this.filterFields.bind(this)
        this.addFilter = this.addFilter.bind(this)
        this.removeFilter = this.removeFilter.bind(this)
        this.updateFilter = this.updateFilter.bind(this);
    }

    componentDidMount() {
        this.getAvailableFields()
    }

    getAvailableFields() {
        axios.get('/application/pipedrive/availablefields').then(response => {
            this.setState({
                availableFields: response.data,
                ready: true
            })
        })
    }

    toggleField(ev) {
        if (!this.props.appBinding.appSettings) this.props.appBinding.appSettings = {};
        var settings = this.props.appBinding.appSettings;
        if (!settings.fields) settings.fields = [];

        var field = this.state.availableFields.find(f => f.name === ev.currentTarget.name)


        if (ev.currentTarget.checked) {
            settings.fields.push(field)
        } else {
            var index = settings.fields.findIndex(f => f.name === field.name)
            if (index > -1) {
                settings.fields.splice(index, 1)
            }
        }
        this.setState({})
    }

    toggleAllFields(on) {
        if (!this.props.appBinding.appSettings) this.props.appBinding.appSettings = {};
        var settings = this.props.appBinding.appSettings;
        if (!settings.fields) settings.fields = [];
        if (on) {
            settings.fields = this.state.availableFields
        } else {
            settings.fields = []
        }
        this.setState({})
    }

    filterFields(val) {
        this.setState({
            filter: val
        })
    }

    addFilter() {
        var filters = this.props.appBinding.appSettings.filters || []
        filters.push({ field: '', operator: '', value: '' })
        this.props.appBinding.appSettings.filters = filters
        this.setState({})
    }

    removeFilter(index) {
        var filters = this.props.appBinding.appSettings.filters || []
        filters.splice(index, 1)
        this.props.appBinding.appSettings.filters = filters
        this.setState({})
    }

    updateFilter(ev, index) {
        var filters = this.props.appBinding.appSettings.filters || []
        if (filters[index]) {
            filters[index][ev.currentTarget.name] = ev.currentTarget.value
        }
        this.props.appBinding.appSettings.filters = filters
        this.setState({})
    }

    render() {
        if (!this.state.ready) return <UILIB.LoadingIcons iconType="2" />

        var availableFields = this.state.availableFields.slice()
        if (this.state.filter) {
            availableFields = availableFields.filter(f => f.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1)
        }

        availableFields = availableFields.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        })

        var filters = this.props.appBinding.appSettings.filters || []

        var operators = [{
            label: 'Equals',
            value: 'equal'
        }, {
            label: 'Not Equals',
            value: 'not equal'
        }, {
            label: 'Is Blank',
            value: 'blank'
        }, {
            label: 'Is Not Blank',
            value: 'not blank'
        }]

        return (
            <div>
                <UILIB.ButtonSelect width={450} className="with-icon white mar-b10" icon={ArrowDown} headerText="Select Fields" filter={true} updateFilter={this.filterFields} selectAll={() => this.toggleAllFields(true)} clearAll={() => this.toggleAllFields(false)} >
                    {availableFields && !!availableFields.length && <table width="100%" cellpadding="0" cellspacing="0">
                        {availableFields.map((field) => {
                            var selected = false
                            if (this.props.appBinding.appSettings && this.props.appBinding.appSettings.fields) {
                                selected = this.props.appBinding.appSettings.fields.some(p => p.name === field.name)
                            }
                            return <tr key={field.value}>
                                <td width="10" style={{ verticalAlign: "top" }}>
                                    <UILIB.CheckBox key={field.name} name={field.name} onChange={this.toggleField} checked={selected} />
                                </td>
                                <td className="pad-left" style={{ verticalAlign: "top" }}>
                                    <div>{field.name}</div>
                                </td>
                            </tr>
                        })}
                    </table>}
                </UILIB.ButtonSelect>
                {!!this.props.appBinding.appSettings && !!this.props.appBinding.appSettings.fields && <div>
                    {this.props.appBinding.appSettings.fields.map(p => {
                        var prop = this.state.availableFields.find(property => property.name === p.name)
                        if (!prop) return ''
                        return <span key={prop.value}><UILIB.SquareChip className="square-chip-primary" value={prop.name} hasButton={true} iconRight={<UILIB.Icons icon="crossCircle" style={{ height: 14, width: 14 }} />} onClick={() => this.toggleField({
                            currentTarget: {
                                checked: false,
                                name: prop.name
                            }
                        })} /></span>
                    })}
                </div>}
                {!!this.props.appBinding.appSettings && !!this.props.appBinding.appSettings.fields && !!this.props.appBinding.appSettings.fields.length && <UILIB.WarningHolder className="mar-t15" style={{ textAlign: "left" }}>
                    <h4 className="mar-b25">Filter</h4>
                    <p>If required add rules below to filter which contacts are imported  based on their data.</p>
                    {!!filters.length && <div className="row mar-b20 text-heavy">
                        <div className="col-xs-3">Field</div>
                        <div className="col-xs-3">Operator</div>
                        <div className="col-xs-4">Value</div>
                        <div className="col-xs-2"></div>
                    </div>}
                    {filters.map((filter, index) => {
                        var field = this.props.appBinding.appSettings.fields.find(f => '_pipedrive_' + f.name === filter.field);
                        return <div key={'filter' + index} className="row">
                            <div className="form-group col-xs-3">
                                <UILIB.Select value={filter.field} name="field" placeholder="Select Field" onChange={ev => this.updateFilter(ev, index)} data={this.props.appBinding.appSettings.fields.map(f => {
                                    return {
                                        label: f.name,
                                        value: '_pipedrive_' + f.name
                                    }
                                })}>
                                </UILIB.Select>
                            </div>
                            <div className="form-group col-xs-3">
                                <UILIB.Select value={filter.operator} name="operator" placeholder="Select Operator" onChange={ev => this.updateFilter(ev, index)} data={operators}>
                                </UILIB.Select>
                            </div>
                            <div className="form-group col-xs-4">
                                {filter.operator !== 'blank' && filter.operator !== 'not blank' && <div>
                                    {!!field && !!field.options && !!field.options.length && <UILIB.Select value={filter.value} name="value" placeholder="select value" onChange={ev => this.updateFilter(ev, index)} data={field.options.map(o => {
                                        return {
                                            label: o.label,
                                            value: o.id || o.value
                                        }
                                    })} />}
                                    {(!field || !field.options || !field.options.length) && <UILIB.TextInput value={filter.value} name="value" onChange={ev => this.updateFilter(ev, index)} />}
                                </div>}
                            </div>
                            <div className="col-xs-2">
                                <UILIB.Button className="grey" text="delete" onClick={() => this.removeFilter(index)} />
                            </div>
                        </div>
                    })}
                    <UILIB.Button text={!!filters.length ? "Add Another Rule" : "Add a Rule"} className="primary outline button-sml" onClick={this.addFilter} />
                </UILIB.WarningHolder>}
            </div>
        );
    }
}

export default PipedriveFields;
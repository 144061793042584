import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

import ImportFile from '~/pages/cp/templates/add/advanced/importFile';
import ImportFromWeb from '~/pages/cp/templates/add/advanced/importWeb';
import ImportFromTemplate from '~/pages/cp/templates/add/advanced/importSite';

class AdvancedTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadHtml: false,
            importTemplate: false,
            downloadFromWeb: false
        };

        this.stopAll = this.stopAll.bind(this);
        this.startUploadHtml = this.startUploadHtml.bind(this);
        this.startImportTemplate = this.startImportTemplate.bind(this);
        this.startImportFromWeb = this.startImportFromWeb.bind(this);
        this.finish = this.finish.bind(this);
        this.finishImportFromWeb = this.finishImportFromWeb.bind(this);
    }

    startUploadHtml() {
        this.setState({
            uploadHtml: true,
            importTemplate: false,
            downloadFromWeb: false
        })
    }
    startImportTemplate() {
        this.setState({
            uploadHtml: false,
            importTemplate: true,
            downloadFromWeb: false
        })
    }
    startImportFromWeb() {
        this.setState({
            uploadHtml: false,
            importTemplate: false,
            downloadFromWeb: true
        })
    }
    stopAll() {
        this.setState({
            uploadHtml: false,
            importTemplate: false,
            downloadFromWeb: false
        })
    }

    finish(template) {
        this.stopAll()
        this.props.campaign.TemplateId = template.id;
        this.props.campaign.Template = template;
        this.props.saveCampaign(this.props.campaign).then(() => {
            switch (template.templateType) {
                case 0:
                    return this.props.history.push('/cp/templates/add/dragDrop/' + template.id)
                case 1:
                    return this.props.history.push('/cp/templates/add/fromCode/' + template.id)
                case 2:
                    return this.props.history.push('/cp/templates/add/wysiwyg/' + template.id)
                case 4:
                    return this.props.history.push('/cp/templates/add/fromCode/' + template.id)
            }

            this.props.changeStep(3);
        });
    }

    async finishImportFromWeb(template, importFromWebUrl) {
        this.stopAll()
        this.props.campaign.options.importFromWebUrl = importFromWebUrl;
        this.props.campaign.TemplateId = template.id;
        this.props.campaign.Template = template;
        await this.props.saveCampaign(this.props.campaign);
        return this.props.history.push('/cp/templates/add/fromCode/' + template.id)
    }

    render() {

        return <UILIB.Paper>
            <UILIB.Row >
                <UILIB.Column md={6} xs={12}>
                    <UILIB.TemplateCard
                        icon={<UILIB.Icons icon="list" circle={true} style={{ width: 30, height: 30 }} />}
                        title={i18n.t('templates:add.wysiTitle')}
                        content={i18n.t('templates:add.wysiSubTitle')}
                        button={<UILIB.Button text={i18n.t('templates:add.wysiButton')}
                            className=""
                            onClick={this.props.createFromWysi} />}
                        style={{ height: "200px" }}
                    />


                </UILIB.Column>
                <UILIB.Column md={6} xs={12}>
                    <UILIB.TemplateCard
                        icon={<UILIB.Icons icon="code" circle={true} style={{ width: 30, height: 30 }} />}
                        title={i18n.t('templates:add.codeTitle')}
                        content={i18n.t('templates:add.codeSubTitle')}
                        button={<UILIB.Button text={i18n.t('templates:add.codeButton')}
                            className=""
                            onClick={this.props.createFromCode} />}
                        style={{ height: "200px" }}
                    />


                </UILIB.Column>
                <UILIB.Column md={4} xs={12}>
                    <UILIB.TemplateCard
                        icon={<UILIB.Icons icon="upload" circle={true} style={{ width: 30, height: 30 }} />}
                        title={i18n.t('templates:add.advanced.uploadTitle')}
                        content={i18n.t('templates:add.advanced.uploadSubTitle')}
                        button={<UILIB.Button text={i18n.t('templates:add.advanced.uploadButton')}
                            className=""
                            onClick={this.startUploadHtml} />}
                        style={{ height: "200px" }}
                    />

                </UILIB.Column>
                <UILIB.Column md={4} xs={12}>
                    <UILIB.TemplateCard
                        icon={<UILIB.Icons icon="download" circle={true} style={{ width: 30, height: 30 }} />}
                        title={i18n.t('templates:add.advanced.importTitle')}
                        content={i18n.t('templates:add.advanced.importSubTitle')}
                        button={<UILIB.Button text={i18n.t('templates:add.advanced.importButton')}
                            className=""
                            onClick={this.startImportTemplate} />}
                        style={{ height: "200px" }}
                    />

                </UILIB.Column>
                <UILIB.Column md={4} xs={12}>
                    <UILIB.TemplateCard
                        icon={<UILIB.Icons icon="world" circle={true} style={{ width: 30, height: 30 }} />}
                        title={i18n.t('templates:add.advanced.webTitle')}
                        content={i18n.t('templates:add.advanced.webSubTitle')}
                        button={<UILIB.Button text={i18n.t('templates:add.advanced.webButton')}
                            className=""
                            onClick={this.startImportFromWeb} />}
                        style={{ height: "200px" }}
                    />

                </UILIB.Column>
            </UILIB.Row><UILIB.Drawer isOpen={this.state.uploadHtml} startClose={this.stopAll} standalone={true} showClose={true} >
                <ImportFile finish={this.finish} campaignId={this.props.campaign.id} />
            </UILIB.Drawer>
            <UILIB.Drawer isOpen={this.state.importTemplate} startClose={this.stopAll} standalone={true} showClose={true}>
                <ImportFromTemplate finish={this.finish} campaignId={this.props.campaign.id} />
            </UILIB.Drawer>

            <UILIB.Drawer isOpen={this.state.downloadFromWeb} startClose={this.stopAll} standalone={true} showClose={true}>
                <ImportFromWeb finish={this.finishImportFromWeb} campaignId={this.props.campaign.id} />
            </UILIB.Drawer>

        </UILIB.Paper>

    };
}

export default AdvancedTemplates;
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import AddUser from './invite'
import DeleteUser from './remove'
import i18n from '~/i18n'

// import AddStaffPop from './addStaffPop.jsx';
// import DeleteStaffPop from './deleteStaffPop.jsx';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class GroupStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertBar: null,
            pageLoaded: false,
            myAccessLevel: '',
            accountUsersData: [],
            users: [],
            alertMessage: '',
            alertOpen: false,
            campaign: undefined
        };
        this.loadStaff = this.loadStaff.bind(this);
        this.loadUsers = this.loadUsers.bind(this);
        this.addUser = this.addUser.bind(this);
        this.userSelected = this.userSelected.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.userDeleted = this.userDeleted.bind(this);
        this.updateBind = this.updateBind.bind(this);
    }

    componentDidMount() {
        axios.get('/campaign/' + this.props.match.params.campaignId).then(res => {
            this.setState({
                campaign: res.data.Campaign
            })
        })
        this.loadStaff()
    }

    //Load Staff stuff
    loadStaff() {
        axios.get('/campaign/' + this.props.match.params.campaignId + '/user')
            .then((usersData) => {
                var me = usersData.data.AccountUsers.find(au => au.AccountUserId == this.props.user.userID);
                this.setState({
                    accountUsersData: usersData.data.AccountUsers,
                    myAccessLevel: me ? me.accessLevel : undefined
                }, this.loadUsers);
            })
            .catch((err) => {
                console.log(err)
            });
    }

    loadUsers() {
        axios.get('/accountUser')
            .then(response => {
                var users = response.data.AccountUsers.filter(u => {
                    return !this.state.accountUsersData.find(user => user.AccountUserId == u.id)
                })
                this.setState({
                    users: users,
                    pageLoaded: true
                })
            })
            .catch((err) => {
                console.log(err)
            });
    }

    addUser() {
        var drawerContent = <AddUser users={this.state.users} add={this.userSelected} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, null, true));
    }

    userSelected(userId, accessLevel) {
        if (userId && accessLevel) {
            axios.post('/campaign/' + this.props.match.params.campaignId + '/user', {
                AccountUserId: userId,
                accessLevel: accessLevel
            }).then(() => {
                this.setState({ alertOpen: true, alertMessage: i18n.t('permissions:users.alertAdded') });
                this.loadStaff()
            }).catch(() => { })
        }
    }

    deleteUser(userId) {
        var drawerContent = <DeleteUser userId={userId} confirm={this.userDeleted} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, null, true));
    }

    userDeleted(userId) {
        var users = this.state.accountUsersData;
        var userIndex = users.findIndex(u => u.AccountUserId == userId);
        axios.delete('/campaign/' + this.props.match.params.campaignId + '/user/' + userId).then(() => {
            this.setState({ alertOpen: true, alertMessage: i18n.t('permissions:users.alertRemoved') });
            this.loadStaff()
        })
    }

    updateBind(userId, level) {
        var users = this.state.accountUsersData;
        var user = users.find(u => u.AccountUserId == userId);
        user.accessLevel = level;
        axios.put('/campaign/' + this.props.match.params.campaignId + '/user/' + userId, {
            accessLevel: level
        }).then(() => {
            this.setState({ alertOpen: true, alertMessage: i18n.t('permissions:users.alertUpdated'), accountUsersData: users });
        })
    }

    render() {


        if (this.state.pageLoaded === false || !this.state.campaign) {
            return <div><UILIB.LoadingIcons /></div>
        }
        else {
            return <UILIB.Paper>
                <h4 className="mar-b25">{i18n.t('campaigns:nav.users')}</h4>
                <UILIB.Row className="mar-b10">
                    <UILIB.Column xs={12} sm={6} margin={0}>
                        <p>{i18n.t('campaigns:users.intro')}</p>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} margin={0} style={{ textAlign: "right" }}>

                        <UILIB.Button className="button-md" onClick={this.addUser}>{i18n.t('permissions:users.addStaff')}</UILIB.Button>

                    </UILIB.Column>
                </UILIB.Row>

                <UILIB.Row className="text-center">

                    {this.state.accountUsersData.map((theUser, index) => {
                        return <UILIB.Column xs={12} sm={6} md={6} lg={3} key={theUser.AccountUserId}>
                            <UILIB.BindCard CreatedById={this.state.campaign.CreatedById} userData={theUser.AccountUser} myLevel={this.state.myAccessLevel} bind={theUser} level={theUser.accessLevel} user={this.props.user} update={this.updateBind} onDelete={this.deleteUser} />
                        </UILIB.Column>

                    })}
                </UILIB.Row>
                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />


            </UILIB.Paper>
        }
    };


};



import React from "react";

export default function IconFacebook({ color = "currentColor", ...rest }) {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"  {...rest}>
            <path d="M64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 47.9721 11.7019 61.2106 27 63.6113V41.25H18.875V32H27V24.95C27 16.93 31.7774 12.5 39.0869 12.5C42.5879 12.5 46.25 13.125 46.25 13.125V21H42.2149C38.2397 21 37 23.4667 37 25.9973V32H45.875L44.4562 41.25H37V63.6113C52.2981 61.2106 64 47.9721 64 32Z" fill={color} />
            <path d="M44.4562 41.25L45.875 32H37V25.9973C37 23.4667 38.2397 21 42.2149 21H46.25V13.125C46.25 13.125 42.5879 12.5 39.0869 12.5C31.7774 12.5 27 16.93 27 24.95V32H18.875V41.25H27V63.6113C28.6292 63.8669 30.299 64 32 64C33.701 64 35.3708 63.8669 37 63.6113V41.25H44.4562Z" fill="white" />
        </svg>
    )
}




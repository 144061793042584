import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import FileManager from '~/pages/cp/includes/fileManager/fileManager'
import SiteVars from '~/Classes/siteVars';
import { launchAviary } from '~/Classes/aviaryEditor'
import jquery from 'jquery';
import i18n from '~/i18n'
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster }
})

export default class ComponentText extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hostingType: 0,
            showPop: false,
            popupStyle: {}
        }
        this.openImagePicker = this.openImagePicker.bind(this);
        this.onFileClick = this.onFileClick.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
        this.editImage = this.editImage.bind(this);
        this.changeHostingType = this.changeHostingType.bind(this);
        this.changeImageUrl = this.changeImageUrl.bind(this);
        this.changeImageUrlFixed = this.changeImageUrlFixed.bind(this);
        this.resizePopup = this.resizePopup.bind(this);
        this.customiseImageToggle = this.customiseImageToggle.bind(this);
    }


    componentWillMount() {
        window.addEventListener("click", this.customiseImageToggle)
        var hostingType = 0;
        if (this.props.value.indexOf(SiteVars.repoEndPoint) < 0 && this.props.value.indexOf("cdn1.ourmailsender.com") < 0 && this.props.value != "") hostingType = 1;
        if (this.props.value.indexOf("/api/dynamicimage?") > -1) hostingType = 0;
        this.setState({ hostingType })
    }
    componentWillUnmount() {
        window.removeEventListener("click", this.customiseImageToggle)
    }

    openImagePicker() {
        var theDrawer = <FileManager onFileClick={this.onFileClick} fileTypes={"images"} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '100vw', 'fix-height'));
    }

    async onFileClick(newFile) {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true, null));
        await this.props.updateVal(this.props.path, newFile.url);
        if (this.props.localVariables && this.props.localVariables.fileSize) {
            await this.props.updateVal("localVariables.fileSize", newFile.size);
        }
        if (this.props.localVariables && this.props.localVariables.srcImageWidth) {
            await this.props.updateVal("localVariables.srcImageWidth", newFile.imageWidth || 0);
        }
        if (this.props.localVariables && this.props.localVariables.srcImageHeight) {
            await this.props.updateVal("localVariables.srcImageHeight", newFile.imageHeight || 0);
        }

    }
    onClearClick() {
        this.props.updateVal(this.props.path, "");
    }


    editImage() {
        var self = this;
        var filePath = this.props.value;
        if (filePath.indexOf("_mpi_") > -1) {
            var spDat = filePath.split("_mpi_");
            filePath = spDat[0] + spDat[spDat.length - 1];
        }
        launchAviary(null, null, (newImgUrl) => { self.onFileClick({ url: newImgUrl }); }, this.props.value);
    }

    changeHostingType() {
        var hostingType = this.state.hostingType;
        if (hostingType == 0) {
            hostingType = 1
        }
        else {
            hostingType = 0
        }
        this.setState({ hostingType })
    }

    changeImageUrl(event) {

        var value = event.target.value;
        this.props.updateVal(this.props.path, value);

    }

    changeImageUrlFixed(newUrl) {
        this.props.updateVal(this.props.path, newUrl);

    }


    customiseImageToggle(ev) {

        var target = jquery(ev.target);

        if ((ev.target.id && ev.target.id == "imageEditor_" + this.props.index) || target.parents("div#imageEditor_" + this.props.index).length) {
            this.setState({ showPop: true }, this.resizePopup)
        }
        else {
            this.setState({ showPop: false })
        }

    }
    resizePopup() {
        var popupStyle = {
            width: "500px"
        };
        var triggerRect = this.refs.ourHolder.getBoundingClientRect();
        var ourRect = this.refs.popupHolder;
        popupStyle.left = triggerRect.left - (ourRect.offsetWidth + 40);
        popupStyle.top = triggerRect.top - 25;

        var bottomPx = popupStyle.top + ourRect.offsetHeight;
        if (bottomPx > window.innerHeight) {
            popupStyle.top = window.innerHeight - ourRect.offsetHeight;
        }

        this.setState({ popupStyle })
    }
    render() {
        var style = {};
        if (this.props.elementData.optionFullWidth || this.props.fullWidth) style = { flex: "100%" }
        var imageUrl = this.props.value;
        if (this.props.value == "{{__COMPANYLOGO}}") {
            imageUrl = SiteVars.repoEndPoint + this.props.accountMaster.accountMaster.companyLogo;
        }

        var isEditable = false;
        var isDynamic = false;
        if (imageUrl.indexOf('/templates/') < 0 && imageUrl.indexOf('_mpi_') < 0 && imageUrl.indexOf('/siteContent/assets/templates/common/') < 0 && imageUrl.indexOf('userContent/' + this.props.accountMaster.id + '/') > 0) {
            isEditable = true;
        }
        if (imageUrl.indexOf("/api/dynamicimage?") > -1) {
            isEditable = true;
            isDynamic = true;
        }

        let tooBigToEdit = "";
        if (this.props.localVariables && this.props.localVariables.fileSize && this.props.localVariables.fileSize.value && this.props.localVariables.fileSize.value > 2000000) {
            tooBigToEdit = "Filesize is too large to allow customisation";
        }
        if (this.props.localVariables && this.props.localVariables.srcImageHeight && this.props.localVariables.srcImageHeight.value && this.props.localVariables.srcImageHeight.value > 1000) {
            tooBigToEdit = "The image height is bigger than 1000px so is not customisable";
        }
        if (this.props.localVariables && this.props.localVariables.srcImageWidth && this.props.localVariables.srcImageWidth.value && this.props.localVariables.srcImageWidth.value > 1000) {
            tooBigToEdit = "The image width is bigger than 1000px so is not customisable"
        }


        return <div className="dd_toolHolder" style={style}>
            <div className="dd_inputTool">
                <div className="txtInput_label">{this.props.title}</div>

                {this.state.hostingType == 0 && <div className="inputWrapper">
                    <div className="imgPickerHolder" style={{ alignItems: "center" }}>
                        {imageUrl && <div className="imgPreview" onClick={this.openImagePicker}>
                            <img src={imageUrl} />
                        </div>}
                        {!imageUrl && <div className="imgPreview" onClick={this.openImagePicker} style={{ backgroundImage: 'url(' + SiteVars.repoEndPoint + 'siteContent/assets/templates/common/empty-color.svg)' }}>
                        </div>}
                        <div style={{ width: "100%" }}>
                            <UILIB.Button className="mar-l10 mar-r10" text="Change Image" onClick={this.openImagePicker} />
                            <span style={{ fontSize: "11px" }} className="mar-r5">or</span>
                            <a onClick={this.changeHostingType}>{i18n.t('templates:add.drag.image.customUrl')}</a>
                            {isEditable == true && <div className="mar-t10" ref="ourHolder">
                                {!isDynamic && <UILIB.Button iconLeft={<UILIB.Icons icon="pencil" />} className="mar-l10" text={i18n.t('templates:add.drag.image.edit')} onClick={this.editImage} />}
                                <UILIB.Button
                                    disabled={tooBigToEdit.length > 0}
                                    iconLeft={<UILIB.Icons
                                        icon="alignCenter" />}
                                    className="mar-l10"
                                    text={i18n.t('templates:add.drag.image.customise')}
                                    onClick={this.customiseImageToggle}
                                    id={"imageEditor_" + this.props.index}
                                    title={tooBigToEdit}
                                />
                            </div>}
                        </div>

                    </div>
                </div>
                }
                {this.state.hostingType == 1 &&
                    <div className="quickFlex">
                        <div className="inputWrapper">
                            <div className="imgPickerHolder">
                                {imageUrl && <div className="imgPreview mar-r10">
                                    <img src={imageUrl} />
                                </div>}
                                {!imageUrl && <div className="imgPreview mar-r10" style={{ backgroundImage: 'url(' + SiteVars.repoEndPoint + 'siteContent/assets/templates/common/empty-color.svg)' }}>
                                </div>}
                            </div>
                        </div>
                        <div style={{ width: "100%" }}>
                            <div>
                                <UILIB.TextInput type="text" value={imageUrl} onChange={this.changeImageUrl} />
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <a onClick={this.changeHostingType} style={{ fontSize: "11px" }}>{i18n.t('templates:add.drag.image.swapBack')}</a>
                            </div>
                        </div>

                    </div>
                }
            </div>

            {
                this.state.showPop && <div className="clusterPopupHolder" ref="popupHolder" onClick={(ev) => { ev.stopPropagation() }} style={this.state.popupStyle}>
                    <div className="clusterPopTitle" style={{ position: "relative" }}>
                        {i18n.t('templates:add.drag.image.customise')}
                        <div style={{ position: "absolute", right: "10px", top: "15px" }}>
                            <UILIB.Button
                                text={i18n.t('templates:add.drag.image.exit')}
                                onClick={this.customiseImageToggle}
                            />
                        </div>
                    </div>
                    <div className="clusterPopContent">
                        <UILIB.ImageCustomiser imgSrc={imageUrl} onSave={this.changeImageUrlFixed} />
                    </div>
                </div>
            }
        </div >



    }
}



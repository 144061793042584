import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import axios from '~/data/http/axios';
import i18n from '~/i18n'
import UILIB from '~/Common-Objects/Lib'
import SiteVars from '~/Classes/siteVars';
import DateTimeFunctions from '~/Classes/dateTimeFunctions'


import TemplateImageLoader from '~/pages/cp/automation/addNew/templateImageLoader.jsx'

@connect((store) => {
    return { accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})
class ABOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.viewCampaign = this.viewCampaign.bind(this)
    }

    viewCampaign(id) {
        window.location.href = '/cp/campaigns/view/' + id
    }

    copy(campaign) {

        var theDrawer = <UILIB.DrawerConfirm header={i18n.t('campaigns:main.duplicateCampaign')} inputText={i18n.t('campaigns:main.campaignName')} input={true} value={this.props.campaign.campaignName + ' - copy'} confirmText="Copy" confirm={(name) => {
            axios.post('/campaign/copy', {
                specific: campaign.id,
                winning: true,
                id: this.props.campaign.id,
                name,
                type: 1
            }).then(res => {
                this.props.history.push('/cp/campaigns/add/standard/' + res.data.CampaignId + '/1')
            })
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true));
    }

    render() {
        var campaign = this.props.campaign
        var children = this.props.campaign.Campaigns.filter(c => c.type === 10)
        var final = this.props.campaign.Campaigns.find(c => c.type === 11)
        var winningCampaign
        var status = 'Building Tests'
        if (final) {
            // its fininshed the testing 
            if (final.status === 'Finished') status = 'Finished'
            else status = 'Sending Final Campaign'
            winningCampaign = children.find(c => c.id === campaign.options.bestCampaign)
        } else {
            // calculate the winning one the fly based on the current stats
            var highest = 0
            children.forEach(cam => {
                var stat = cam.CampaignStats.find(s => s.key === campaign.options.scoreReason)
                if (stat && (!winningCampaign || stat.value > highest)) {
                    highest = stat.value
                    winningCampaign = cam
                }
            })
        }

        var countdown = ''
        if (!final) {
            if (campaign.status === 'Processing') {
                countdown = DateTimeFunctions.formatDateTime(campaign.finishedDate)
            }
        }

        //var winningTextLine = "This {final ? 'was' : 'will be'} selected by the using the settings and template from the test with the most <strong className="text-primary">{campaign.options.scoreReason}</strong>{countdown ? ' at ' : ''}{countdown ? <strong className="text - secondary">{countdown}</strong> : ''"
        var winningTextLine = i18n.t('campaigns:abSplitTest.send.winningText');
        winningTextLine = winningTextLine.replace(/\[FINALTOGGLE\]/g, " " + (final ? i18n.t('campaigns:abSplitTest.send.was') : i18n.t('campaigns:abSplitTest.send.willBe')) + " ")
        var winningScoreReason = campaign.options.scoreReason;

        return (
            <div>
                {/* list of test highlighting the highest */}
                <UILIB.Paper>
                    <UILIB.DataTable1
                        tableData={children.map((row, index) => {
                            var stats = {}
                            row.CampaignStats.forEach(stat => {
                                stats[stat.key] = stat.value
                            })
                            return {
                                className: winningCampaign && row.id === winningCampaign.id ? 'bg-green' : '',
                                campaignImage: {
                                    headerValue: "",
                                    value: <span>
                                        <div className="campaign-thumbnail mar-l10"><TemplateImageLoader imageUrl={SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + row.TemplateId + ".jpg"} /></div>
                                    </span>
                                    ,
                                    orderBy: false,
                                    width: 100
                                },
                                campaignName: {
                                    headerValue: 'Test',
                                    value: <div className="flexSpaceBetween">
                                        <a style={{ color: winningCampaign && row.id === winningCampaign.id ? "#33257E" : '', margin: 'auto 0' }} onClick={() => this.viewCampaign(row.id)}>{row.campaignName || ("Test " + (index + 1))}</a>
                                        {winningCampaign && row.id === winningCampaign.id && <UILIB.SquareChip value={final ? i18n.t('campaigns:status.winner') : i18n.t('campaigns:status.winning')} className="square-chip-secondary no-marg" />}
                                        {row.status === 'Paused' && <UILIB.SquareChip value={i18n.t('campaigns:status.Queued')} className="square-chip-primary no-marg" />}
                                        {row.status === 'Draft' && <UILIB.SquareChip value={i18n.t('campaigns:status.Awaiting Start')} className="square-chip-primary no-marg" />}
                                    </div>,
                                    orderBy: false
                                },
                                statsOpened: {
                                    headerValue: i18n.t('campaigns:main.statsOpened'),
                                    value: <span>
                                        <UILIB.SmallLineGraph width="100" height="5" val1={stats['Unique Opens'] || 0} val2={stats['Total Subscribers'] || 0} />
                                    </span>,
                                    orderBy: false,
                                    width: 175
                                },
                                statsClicked: {
                                    headerValue: i18n.t('campaigns:main.statsClicked'),
                                    value: <UILIB.SmallLineGraph width="100" height="5" val1={stats['Unique Clicks'] || 0} val2={stats['Total Subscribers'] || 0} />,
                                    orderBy: false,
                                    width: 175
                                },
                                statsBounces: {
                                    headerValue: i18n.t('campaigns:main.statsBounced'),
                                    value: <UILIB.SmallLineGraph width="100" height="5" val1={stats['Hard Bounces'] || 0} val2={stats['Total Subscribers'] || 0} />,
                                    orderBy: false,
                                    width: 175
                                },
                                statsUnsubscribed: {
                                    headerValue: i18n.t('campaigns:main.statsUnsubscribed'),
                                    value: <UILIB.SmallLineGraph width="100" height="5" val1={stats['Total Unsubscribes'] || 0} val2={stats['Total Subscribers'] || 0} />,
                                    orderBy: false,
                                    width: 175
                                },
                                optionsCol: {
                                    headerValue: " ",
                                    value: <UILIB.OptionsDropdown popWidth="150px">
                                        <ul>
                                            <li><a onClick={() => this.viewCampaign(row.id)}>View</a></li>
                                            {/* <li><a onClick={() => this.copy(row)}>Duplicate</a></li> */}
                                        </ul>
                                    </UILIB.OptionsDropdown>,
                                    orderBy: false,
                                    fixed: true,
                                    width: 20
                                }
                            }
                        })}
                        width="100%"
                        pageSize="100"
                        hasCheckBoxes="no" />
                </UILIB.Paper>

                <h4 className="mar-b25">{i18n.t('campaigns:status.Queued')}</h4>
                <div className="mar-b25">
                    {winningTextLine}
                    <strong className="text-primary"> {winningScoreReason}</strong>
                    {countdown ? <span> at <strong className="text - secondary">{countdown}</strong> </span> : ''}
                </div>
                {!!(final) && <UILIB.Paper>
                    <UILIB.DataTable1
                        tableData={[final || winningCampaign].map(row => {
                            var stats = {}
                            row.CampaignStats.forEach(stat => {
                                stats[stat.key] = stat.value
                            })
                            var winner = children.findIndex(c => c.id == winningCampaign.id)
                            return {
                                className: final ? '' : 'bg-grey',
                                campaignImage: {
                                    headerValue: "",
                                    value: <span>
                                        <div className="campaign-thumbnail mar-l10"><TemplateImageLoader imageUrl={SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + row.TemplateId + ".jpg"} /></div>
                                    </span>
                                    ,
                                    orderBy: false,
                                    width: 100
                                },
                                campaignName: {
                                    headerValue: "Winning Test",
                                    value: <div className="flexSpaceBetween">
                                        <a style={{ margin: 'auto 0' }} onClick={() => this.viewCampaign(row.id)}>{row.campaignName || ('Test ' + (winner + 1))}</a>
                                        {!final && <UILIB.SquareChip value="Current Winner" className="square-chip-primary no-marg" />}
                                    </div>,
                                    orderBy: false
                                },
                                statsOpened: {
                                    headerValue: i18n.t('campaigns:main.statsOpened'),
                                    value: <span>
                                        <UILIB.SmallLineGraph width="100" height="5" val1={stats['Unique Opens'] || 0} val2={stats['Total Subscribers'] || 0} />
                                    </span>,
                                    orderBy: false,
                                    width: 175
                                },
                                statsClicked: {
                                    headerValue: i18n.t('campaigns:main.statsClicked'),
                                    value: <UILIB.SmallLineGraph width="100" height="5" val1={stats['Unique Clicks'] || 0} val2={stats['Total Subscribers'] || 0} />,
                                    orderBy: false,
                                    width: 175
                                },
                                statsBounces: {
                                    headerValue: i18n.t('campaigns:main.statsBounced'),
                                    value: <UILIB.SmallLineGraph width="100" height="5" val1={stats['Hard Bounces'] || 0} val2={stats['Total Subscribers'] || 0} />,
                                    orderBy: false,
                                    width: 175
                                },
                                statsUnsubscribed: {
                                    headerValue: i18n.t('campaigns:main.statsUnsubscribed'),
                                    value: <UILIB.SmallLineGraph width="100" height="5" val1={stats['Total Unsubscribes'] || 0} val2={stats['Total Subscribers'] || 0} />,
                                    orderBy: false,
                                    width: 175
                                },
                                optionsCol: {
                                    headerValue: " ",
                                    value: <UILIB.OptionsDropdown popWidth="150px">
                                        <ul>
                                            <li><a onClick={() => this.viewCampaign(row.id)}>View</a></li>
                                            {/* <li><a onClick={() => this.copy(row)}>Duplicate</a></li> */}
                                        </ul>
                                    </UILIB.OptionsDropdown>,
                                    orderBy: false,
                                    fixed: true,
                                    width: 20
                                }
                            }
                        })}
                        width="100%"
                        pageSize="100"
                        hasCheckBoxes="no" />
                </UILIB.Paper>}

            </div>
        );
    }
}

export default ABOverview;
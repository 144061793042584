import React, { useEffect, useState } from 'react'
import UILIB from '../../../../Common-Objects/Lib'
import axios from '../../../../data/http/axios'
import i18n from '../../../../i18n'
import copy from 'copy-to-clipboard';

export default function Setup2fa({ accountUserData, reload = () => { } }) {
    const [account, setAccount] = useState(null)
    const [code, setCode] = useState('')
    const [enalbing, setEnabling] = useState(false)
    const [disabling, setDisabling] = useState(false)
    const [image, setImage] = useState(null)
    const [error, setError] = useState('')
    const [saving, setSaving] = useState(false)
    const [tokens, setTokens] = useState(undefined)
    const [alert, setAlert] = useState({ open: false, message: '' })

    const enable = async () => {
        if (enalbing) {
            setCode('')
            setEnabling(false)
        } else if (accountUserData.twoFAEnabled) {
            setDisabling(!disabling)
        } else {
            const res = await axios.get('/accountUser/2fa')
            setImage(res.data.image)
            setEnabling(true)
        }
    }

    const save = async () => {
        setSaving(true)
        const res = await axios.put('/accountUser/2fa', { twoFaCode: parseInt(code) })
        setSaving(false)
        if (!res.data.success) {
            return setError('Code Incorrect')
        } else {
            setTokens(res.data.tokens)
            setAlert({ open: true, message: enalbing ? '2FA Enabled' : '2FA Disabled' })
            setCode('')
            setEnabling(false)
            setDisabling(false)
            reload()
        }
    }

    useEffect(() => {
        axios.get('/accountMaster').then(res => {
            setAccount(res.data.AccountMaster)
        })
    }, [])

    if (!account) return <UILIB.LoadingIcons />

    return (
        <UILIB.Paper>
            <h4 className="mar-b25">{i18n.t('account:my.twoFa.header')}</h4>
            <div className="mar-b25">{i18n.t('account:my.twoFa.subHeader')}</div>
            {accountUserData.registered && <UILIB.Toggle after={accountUserData.twoFAEnabled ? i18n.t('account:my.twoFa.enabled') : i18n.t('account:my.twoFa.disabled')} disabled={account?.options?.allUsersRequire2fa} name="twoFAEnabled" value={accountUserData.twoFAEnabled} onChange={enable} />}
            {!accountUserData.registered && <UILIB.WarningHolder>Please confirm your email address before enabling</UILIB.WarningHolder>}
            {account?.options?.allUsersRequire2fa && accountUserData.registered && accountUserData.twoFAEnabled && <UILIB.WarningHolder className="mar-t10">You cannot disable 2fa due to an account policy</UILIB.WarningHolder>}

            {enalbing && <UILIB.Row>
                <UILIB.Column xs={12} sm={6}>
                    {!image && <UILIB.LoadingIcons iconType="2" />}
                    {!!image && <img src={image} style={{ width: "100%" }} />}
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6}>
                    <h4 className="mar-b25">{i18n.t('account:my.twoFa.scanQrCode')}</h4>
                    <div className='mar-b25' dangerouslySetInnerHTML={{ __html: accountUserData.twoFAEnabled ? i18n.t('account:my.twoFa.instructions') : i18n.t('account:my.twoFa.firstLoad') }}></div>
                    <UILIB.TextInput error={error} outerClassName="mar-b25" value={code} label="2FA Code" onChange={e => setCode(e.currentTarget.value)} onEnterPress={save} placeholder="2FA Code" />
                    <UILIB.Button iconRight={<UILIB.Icons icon="floppydisk" />} text="Save" onClick={save} className="button-primary" />
                </UILIB.Column>
            </UILIB.Row>}

            {disabling && <UILIB.Row className="mar-t20">
                <UILIB.Column xs={12} sm={12} className="mar-b25">
                    <div className='text-heavy'>To disable two factor authentication please enter your code below</div>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12}>
                    <UILIB.TextInput error={error} outerClassName="mar-b25" value={code} label="2FA Code" onChange={e => setCode(e.currentTarget.value)} onEnterPress={save} placeholder="2FA Code" />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12}>
                    <UILIB.Button iconRight={<UILIB.Icons icon="floppydisk" />} text="Save" onClick={save} className="button-primary" />
                </UILIB.Column>
            </UILIB.Row>}

            <UILIB.SnackBar message={alert.message} open={alert.open} autoclose={true} dismiss={() => setAlert({ open: false })} />

            <UILIB.Drawer standalone={true} isOpen={!!tokens} close={() => setTokens(undefined)} showClose={true}>
                <Tokens tokens={tokens} copied={() => setAlert({ open: true, message: 'Tokens Copied to Clipboard' })} />
            </UILIB.Drawer>
        </UILIB.Paper >
    )
}

function Tokens({ tokens = [], copied = () => { } }) {
    const copyToClipBoard = () => {
        copy(tokens)
        copied()
    }

    return (
        <>
            <h4 className='mar-b25'>Recovery Tokens</h4>
            <p>Take a note of these recovery tokens</p>
            <p>If you lose access to your authentication app you can use one of these tokens to login to your account</p>
            <p className='mar-b25'>You will only be able to use each token once</p>
            <code className='code code-copy' onClick={copyToClipBoard} style={{ flexFlow: "wrap" }}>
                <div className='code-copy-icon'><UILIB.Icons icon="copyClipboard" /></div>
                {tokens.map(t => <div>{t}</div>)}
            </code>
        </>
    )
}
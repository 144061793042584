import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import EmailTools from '~/Classes/emailValidator';
import axios from '~/data/http/axios';
import AddDomainPop from '../../../../campaigns/add/steps/step3/addDomainPop'
import i18n from '~/i18n'
import { connect } from 'react-redux';
@connect((store) => {
    return { accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

class EmailSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                confirmationName: this.props.form.confirmationName,
                confirmationEmail: this.props.form.confirmationEmail,
                confirmationSubject: this.props.form.confirmationSubject,
                options: this.props.form.options || {}
            },
            errors: { confirmationName: '', confirmationEmail: '', confirmationSubject: '' },
            showVerifyDomain: false,
            showVerifyDomainType: 'new',
            trackingDomains: [],
            cdnDomains: [],
            trackingLoaded: true,
            cdnLoaded: true
        }
        this.update = this.update.bind(this)
        this.save = this.save.bind(this)
        this.checkReplyToEmail = this.checkReplyToEmail.bind(this)
        this.showVerifyDomain = this.showVerifyDomain.bind(this)
        this.hideVerifyDomain = this.hideVerifyDomain.bind(this)
        this.checkForm = this.checkForm.bind(this)
        this.updateOptions = this.updateOptions.bind(this)
    }

    componentDidMount() {
        if (this.props.accountMaster.accountMaster.SiteMasterId === 1 && this.props.accountMaster.accountMaster.accountType === 'free') {
            return this.setState({
                cdnLoaded: true,
                trackingLoaded: true
            })
        }
        axios.get('/accountMaster/cdn').then(response => {
            this.setState({
                cdnDomains: response.data.Domains.filter(d => d.authenticated),
                cdnLoaded: true
            })
        })
        axios.get('/accountMaster/tracking').then(response => {
            this.setState({
                trackingDomains: response.data.Domains.filter(d => d.authenticated),
                trackingLoaded: true
            })
        })
    }

    updateOptions(ev) {
        var form = this.state.form
        form.options[ev.currentTarget.name] = ev.currentTarget.value
        this.setState({ form })
    }

    update(ev) {
        var form = this.state.form
        form[ev.currentTarget.name] = ev.currentTarget.value
        this.setState({
            form
        })
    }

    checkReplyToEmail(value) {
        var self = this;

        if (value.indexOf('@') > -1 && EmailTools.validateEmail(this.state.form.confirmationEmail)) {
            var domain = value.split('@')[1].toLowerCase()
            return axios.get('/accountMaster/domain/check?email=' + value)
                .then(response => {
                    var isVerified = false;
                    var originalEmailAddress = "";
                    var originalDomainID = 0;
                    var isAuthenticated = false;
                    if (response.data.hasOwnProperty("Domain") === false) {
                        isVerified = true;
                    }
                    else {
                        isVerified = response.data.Domain.verified;
                        originalEmailAddress = response.data.Domain.originalEmailAddress;
                        originalDomainID = response.data.Domain.id;
                        isAuthenticated = response.data.Domain.authenticated;
                    }
                    self.setState({
                        domainExists: response.data.Exists,
                        domainName: '@' + domain,
                        domainVerified: isVerified,
                        domainOrigEmailSentTo: originalEmailAddress,
                        domainId: originalDomainID,
                        domainBlocked: false,
                        domainAuthenticated: isAuthenticated
                    }, self.checkForm)
                })
                .catch(err => {
                    if (err.data.error) {
                        self.setState({
                            domainVerified: false,
                            domainBlocked: true,
                            domainAuthenticated: false
                        }, self.checkForm);
                    } else {
                        self.checkForm;
                    }
                })
        }
    }

    checkForm() {
        var form = this.state.form;
        var errors = { confirmationName: '', confirmationEmail: '', confirmationSubject: '' };

        if (!form.confirmationName || form.confirmationName.length < 3) {
            errors.confirmationName = i18n.t('form:step4.errFromName');
        }

        if (!form.confirmationEmail || EmailTools.validateEmail(form.confirmationEmail) === false) {
            errors.confirmationEmail = i18n.t('form:step4.errAddress');
        } else {
            if (this.state.domainExists === false) {
                errors.confirmationEmail = <span><a onClick={() => { this.showVerifyDomain("new") }}>{i18n.t('form:step4.errDomain')}</a></span>
            }
            else {
                if (this.state.domainVerified === false) {
                    errors.confirmationEmail = <span><a onClick={() => { this.showVerifyDomain("existing") }}>{i18n.t('form:step4.errVerify')}</a></span>
                }
                if (this.props.siteMaster.usersMustBeAuthenticated && !this.state.domainAuthenticated) {
                    errors.confirmationEmail = <span><a onClick={() => { this.props.history.push('/cp/company/domains') }}>{i18n.t('form:step4.errAuthenticate')}</a></span>
                }
            }
        }


        if (!form.confirmationSubject || form.confirmationSubject.length < 4) {
            errors.confirmationSubject = i18n.t('form:step4.errSubject')
        }

        this.setState({
            errors
        })

        return errors
    }

    showVerifyDomain(type) {
        this.setState({
            showVerifyDomainType: type,
            showVerifyDomain: true
        })
    }

    hideVerifyDomain() {
        this.setState({
            showVerifyDomain: false
        })
    }

    save() {
        this.checkReplyToEmail(this.state.form.confirmationEmail)
            .then(() => {
                var errors = this.checkForm()
                var hasErrors = false
                for (var prop in errors) {
                    if (errors[prop]) hasErrors = true
                }
                if (hasErrors) {
                    return false
                } else {
                    this.props.onSave(this.state.form)
                }
            })
    }

    render() {
        if (!this.state.cdnLoaded || !this.state.trackingLoaded) return <UILIB.LoadingIcons iconType="2" />

        return (
            <div>

                <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('form:step4.from')} name="confirmationName" error={this.state.errors.confirmationName} value={this.state.form.confirmationName} onChange={this.update} />
                <UILIB.TextInput label={i18n.t('form:step4.email')} outerClassName="mar-b25" name="confirmationEmail" error={this.state.errors.confirmationEmail} type="email" onBlur={ev => this.checkReplyToEmail(ev.target.value)} value={this.state.form.confirmationEmail} onChange={this.update} />
                <UILIB.TextInput label={i18n.t('form:step4.subject')} outerClassName="mar-b25" name="confirmationSubject" error={this.state.errors.confirmationSubject} value={this.state.form.confirmationSubject} onChange={this.update} />

                <div className="quickFlex">
                    <UILIB.Button text={i18n.t('form:edit.save')} onClick={this.save} className="button-primary mar-r10" />
                    <UILIB.Button text={i18n.t('form:edit.cancel')} onClick={this.props.onCancel} />
                </div>

                {this.state.showVerifyDomain && <UILIB.Drawer close={this.hideVerifyDomain} isOpen={true} standalone={true} width={300} showClose={true} clickOutside={true}>
                    <AddDomainPop history={this.props.history} onAddedDomain={this.hideVerifyDomain} theEmail={this.state.form.confirmationEmail} theType={this.state.showVerifyDomainType} domainOrigEmailSentTo={this.state.domainOrigEmailSentTo} domainId={this.state.domainId} />
                </UILIB.Drawer>}
            </div>
        );
    }
}

export default EmailSettings;
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import StripeAddCard from '~/pages/cp/includes/upgrade/stripeAddCard'
import i18n from '~/i18n'
import NewUserForm from '../../includes/bb_topNav/newUserForm/newUserForm';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
//CONNECT TO STORE
@connect((store) => {
    return {
        user: store.user,
        siteMaster: store.siteMaster,
        accountMaster: store.accountMaster
    }
})

export default class StartCleanJob extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groupIDs: [],
            pageLoaded: false,
            manuallyAddingCard: false,
            paymentCards: [],
            defaultCard: undefined,
            paymentCardId: 0,
            paymentMethod: "card",
            reloadingCard: false,
            paymentError: "",
            cost: {},
            voucherCode: "",
            accountMaster: {},
            loadingCosts: true
        };

        this.loadAccountMaster = this.loadAccountMaster.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.removeGroup = this.removeGroup.bind(this);
        this.loadCosts = this.loadCosts.bind(this);

        this.updateValue = this.updateValue.bind(this);

        this.getPaymentCards = this.getPaymentCards.bind(this);
        this.addNewCard = this.addNewCard.bind(this);
        this.selectCard = this.selectCard.bind(this);

        this.startJob = this.startJob.bind(this);
        this.doUserQuestions = this.doUserQuestions.bind(this);
    }

    componentDidMount() {
        if (this.props.siteMaster.listCleanDisabled) return this.props.history.push("/cp")
        this.loadAccountMaster();
        this.getGroups();
    }

    componentWillUnmount() {
    }

    loadAccountMaster(justAccountMaster) {
        axios.get('/accountMaster')
            .then((res) => {
                this.setState({ accountMaster: res.data.AccountMaster }, () => {
                    if (!justAccountMaster) { this.getPaymentCards() }
                })
            })
    }

    getGroups() {
        var self = this;
        axios.get('/group?count=true').then(response => {

            var groupData = response.data.Groups;
            var loadingCosts = false;
            if (this.props.match.params.groupId) {
                var foundGroup = groupData.find(g => g.id == this.props.match.params.groupId)
                if (foundGroup) {
                    foundGroup.selected = true;
                    loadingCosts = true;
                }
            }

            self.setState({ groups: groupData, pageLoaded: true, loadingCosts }, () => {
                if (loadingCosts) {
                    this.loadCosts();
                }
            });
        });
    }

    addGroup(event) {
        var groups = this.state.groups;
        var found = groups.find(g => g.id == event.target.value)
        found.selected = true;
        this.setState({ groups: groups, loadingCosts: true }, this.loadCosts)
    }

    removeGroup(groupId) {
        var groups = this.state.groups;
        var found = groups.find(g => g.id == groupId)
        found.selected = false;
        this.setState({ groups: groups, loadingCosts: true }, this.loadCosts)
    }

    loadCosts() {
        var self = this;
        var groupIds = this.state.groups.filter(g => g.selected).map(g => g.id).join(",");
        axios.get('/listcleaner/cost?groupId=' + groupIds + (this.state.voucherCode ? "&voucher=" + this.state.voucherCode : "")).then(response => {
            self.setState({ cost: response.data, loadingCosts: false });
        });
    }



    updateValue(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    getPaymentCards() {
        axios.get('/accountMaster/paymentMethod/stripe').then(response => {

            var defaultCard;
            var paymentMethod = "card"
            if (response.data.customer && response.data.customer.default_source) {
                defaultCard = response.data.customer.default_source
                paymentMethod = "card"
            }
            this.setState({
                manuallyAddingCard: false,
                paymentCards: response.data.customer.sources.data,
                defaultCard,
                paymentCardId: defaultCard,
                paymentMethod
            }, this.getGroup)
        }).catch(console.log)
    }
    addNewCard() {
        var manuallyAddingCard = this.state.manuallyAddingCard;
        if (manuallyAddingCard) {
            manuallyAddingCard = false
        } else {
            manuallyAddingCard = true;
        }
        this.setState({ manuallyAddingCard, paymentCardId: "" })
    }
    selectCard(event) {
        var self = this;
        var newCardId = event.target.value
        this.setState({ reloadingCard: true })
        axios.put('/accountMaster/paymentMethod/stripe', {
            default_source: newCardId
        }).then(() => {
            self.setState({ manuallyAddingCard: false, paymentCardId: newCardId, reloadingCard: false })
        }).catch(err => {
            console.log(err)
        })
    }

    startJob() {
        var self = this;
        this.setState({ pageLoaded: false })
        var groupIds = this.state.groups.filter(g => g.selected).map(g => g.id).join(",");
        axios.post('/listcleaner?groupId=' + groupIds + (this.state.voucherCode ? "&voucher=" + this.state.voucherCode : "")).then(_resp => {
            self.props.history.push(`/cp/subscribers/clean`)
        }).catch(err => {
            self.setState({ paymentError: "There was an error processing your payment. Please try again or try another payment method.", pageLoaded: true })
        })
    }

    doUserQuestions() {
        var theDrawer = <NewUserForm from="clean" closeNewUserForm={() => { }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, false, "100vw", 'full'));
    }

    render() {
        if (!this.state.pageLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>

        var nonSelectedGroups = this.state.groups.filter(g => !g.selected).map(g => {
            return {
                label: g.groupName,
                value: g.id
            }
        })
        var selectedGroups = this.state.groups.filter(g => g.selected);
        var totalSubscribers = 0;
        selectedGroups.forEach(g => {
            totalSubscribers += g.count
        })

        var selectedPaymentCard = this.state.paymentCardId;
        if (!selectedPaymentCard || !selectedPaymentCard.length) selectedPaymentCard = this.state.defaultCard;


        return <div>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={8}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">Start a new List Clean</h4>
                        <p className="mar-b25">Simply choose a group or groups of contacts to clean, and we will automatically check every email address and let you know if it is good to send to, or not.</p>
                        {this.state.groups.length > 0 &&
                            <div className="mar-b25">
                                <UILIB.Select placeholder="Select a Group or Groups to Clean" value={0} data={nonSelectedGroups} onChange={this.addGroup} />
                            </div>
                        }
                        {this.state.groups.length <= 0 && <div>
                            <div className="text-strong">You don't have any groups setup.</div>
                            <p>Please add a group before attempting a list clean</p>
                        </div>}

                        {selectedGroups.map((g, index) => {
                            return <UILIB.SquareChip key={"g" + index} className={"mar-r10"} iconRight={<UILIB.Icons icon="cross" onClick={() => { this.removeGroup(g.id) }} />}>
                                {g.groupName}
                            </UILIB.SquareChip>
                        })}

                    </UILIB.Paper>

                    {selectedGroups.length > 0 && <div>
                        {this.state.loadingCosts && <UILIB.LoadingIcons iconType="2" />}
                        {!this.state.loadingCosts && this.state.cost.totalSubscribers <= 0 && <UILIB.Paper className="paper-red">
                            <h4 className="mar-b25">{i18n.t('subscribers:listClean.startClean.noSubscribers')}</h4>
                            <p>{i18n.t('subscribers:listClean.startClean.noSubscribersText')}</p>
                            <p>{i18n.t('subscribers:listClean.startClean.noSubscribersText2')}</p>
                        </UILIB.Paper>}

                        {!this.state.loadingCosts && totalSubscribers > 0 && <div>

                            {!this.props.accountMaster.accountMaster.approved && <div>

                                {(!this.props.accountMaster.accountMaster.doneNewUserQuestions) && <UILIB.Paper className="paper-red">
                                    <h4 className="mar-b25">{i18n.t('subscribers:listClean.startClean.accountAwaitingQuestionnaireHeader')}</h4>
                                    <p>{i18n.t('subscribers:listClean.startClean.accountAwaitingQuestionnaireText')}</p>
                                    <UILIB.Button
                                        className="button-primary"
                                        text={i18n.t('subscribers:listClean.startClean.accountAwaitingQuestionnaireButton')}
                                        onClick={this.doUserQuestions} />
                                </UILIB.Paper>}

                                {(this.props.accountMaster.accountMaster.doneNewUserQuestions) && <UILIB.Paper className="paper-red">
                                    <h4 className="mar-b25">{i18n.t('subscribers:listClean.startClean.accountAwaitingApprovalHeader')}</h4>
                                    <p>{i18n.t('subscribers:listClean.startClean.accountAwaitingApprovalText')}</p>

                                </UILIB.Paper>}

                            </div>}

                            {this.props.accountMaster.accountMaster.approved && <UILIB.Paper>
                                <UILIB.Row >
                                    {!!this.state.paymentError && <UILIB.Column xs={12} sm={12} className="mar-b15" >
                                        <UILIB.Paper className="paper-red" style={{ textAlign: "center" }}>
                                            {this.state.paymentError}
                                        </UILIB.Paper>
                                    </UILIB.Column>}
                                    <UILIB.Column xs={12} sm={3}>
                                        {i18n.t('subscribers:listClean.startClean.subscribersToCheck')}:
                                    </UILIB.Column>
                                    <UILIB.Column xs={12} sm={9} className="mar-b15">
                                        <div className="text-lg">{this.state.cost.totalSubscribers
                                        } <span className="text-sml">
                                                {i18n.t('subscribers:listClean.startClean.activeSubscribers')}
                                            </span></div>
                                    </UILIB.Column>
                                    <UILIB.Column xs={12} sm={3} className="mar-b15">
                                        {i18n.t('subscribers:listClean.startClean.cost')}:
                                    </UILIB.Column>
                                    <UILIB.Column xs={12} sm={9} className="mar-b15">
                                        <div className="text-lg mar-b10">
                                            <span>{this.state.cost.symbol + this.state.cost.price}</span>
                                            {!!Number(this.state.cost.vat) && <span className="text-sml mar-l10">+ {this.state.cost.symbol + this.state.cost.vat} vat</span>}
                                        </div>
                                    </UILIB.Column>


                                    <UILIB.Column xs={12} sm={3} className="mar-b40">
                                        {i18n.t('subscribers:listClean.startClean.voucherCode')}:
                                    </UILIB.Column>
                                    <UILIB.Column xs={12} sm={9} className="mar-b40">
                                        <div className="quickFlex">
                                            <div className="form-group mar-r10 mar-b0">
                                                <UILIB.TextInput type="text" name="voucherCode" onChange={this.updateValue} value={this.state.voucherCode} />
                                            </div>
                                            <UILIB.Button text={i18n.t('subscribers:listClean.startClean.apply')} className="button-sml" onClick={this.loadCosts} />
                                        </div>
                                    </UILIB.Column>
                                </UILIB.Row>

                                {((((this.state.paymentCards && this.state.paymentCards.length > 0 && !this.state.manuallyAddingCard) || this.state.cost.totalInc == 0))) &&
                                    <UILIB.Row className="text-sml">
                                        {(this.state.cost.totalInc > 0) && <UILIB.Column xs={12} sm={3} margin={0}>
                                            {i18n.t('subscribers:listClean.startClean.paymentMethod')}:
                                        </UILIB.Column>}
                                        {(this.state.cost.totalInc > 0) && <UILIB.Column xs={12} sm={9} margin={0}>
                                            {!!this.state.reloadingCard && <UILIB.LoadingIcons iconType="2" />}
                                            {!this.state.reloadingCard && <div className="form-group mar-b0">
                                                <UILIB.Select placeholder={i18n.t('subscribers:listClean.startClean.selectExistingPaymentCard')} data={this.state.paymentCards.map((card) => {
                                                    return { label: card.brand + " ending " + card.last4 + " (exp " + card.exp_month + "/" + card.exp_year + ")", value: card.id }
                                                })} onChange={this.selectCard} value={selectedPaymentCard} />
                                                <div className="mar-t10" style={{ textAlign: "right", fontSize: "14px" }}>
                                                    or <a onClick={this.addNewCard}>{i18n.t('subscribers:listClean.startClean.addANewCard')}</a>
                                                </div>
                                            </div>}
                                        </UILIB.Column>}

                                        {(this.state.cost.totalInc > 0) && <UILIB.Column xs={12} sm={3} margin={0}>

                                        </UILIB.Column>}
                                        <UILIB.Column xs={12} sm={9} margin={0}>
                                            <UILIB.Button disabled={this.state.reloadingCard} className="button-primary" text={`${i18n.t('subscribers:listClean.startClean.startCleaning')} (${this.state.cost.symbol + this.state.cost.totalInc})`} onClick={this.startJob} />
                                        </UILIB.Column>

                                    </UILIB.Row>
                                }
                                {(this.state.cost.totalInc != 0 && (this.state.paymentMethod == "card" && (!this.state.paymentCards || !this.state.paymentCards.length || this.state.manuallyAddingCard))) &&
                                    <UILIB.Row>
                                        <UILIB.Column xs={12} sm={12} margin={0}>
                                            <h4 className="mar-b25">{i18n.t('subscribers:listClean.startClean.addPaymentMethod')}</h4>
                                            <StripeAddCard close={this.getPaymentCards} hasCloseButton={this.state.manuallyAddingCard} />

                                        </UILIB.Column>

                                    </UILIB.Row>
                                }

                            </UILIB.Paper>
                            }
                        </div>}
                    </div>}
                </UILIB.Column>
                <UILIB.Column xs={12} sm={4}>
                    <UILIB.Paper className="paper-lightGrey">
                        <h4 className="mar-b25">
                            How it works...
                        </h4>
                        <p>The email addresses will be automatically scanned to find and remove invalid, non existant and expired email addresses.</p>
                        <p>Once the scan is finished you can choose to remove any invalid or dubious addresses found.</p>
                        <p>Removing invalid email addresses is extremely important and will greatly increase the chance of your email reaching the inbox.</p>


                    </UILIB.Paper>
                </UILIB.Column>

            </UILIB.Row>


        </div >
    };
};
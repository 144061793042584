import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
class addFolder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: ''
        }

        this.updateField = this.updateField.bind(this);
        this.saveFolder = this.saveFolder.bind(this);
    }

    updateField(ev) {
        this.setState({
            [ev.currentTarget.name]: ev.currentTarget.value
        })
    }

    saveFolder() {
        if (!this.state.name || this.state.name.length < 4) {
            this.setState({ errorTxt: i18n.t('templates:fileManager.popups.pleaseEnterALongerFolderName') })
        }
        else {
            this.setState({ errorTxt: "" });
            this.props.save(this.state.name);
        }
    }
    render() {
        return (<div>
            <h4 className="mar-b25">{i18n.t('templates:fileManager.popups.addANewFolder')}</h4>

            <UILIB.TextInput
                name="name"
                value={this.state.name}
                onChange={this.updateField}
                label={i18n.t('templates:fileManager.popups.folderName')}
                outerClassName="mar-b25"
                error={this.state.errorTxt}
            />
            <UILIB.Button
                text={i18n.t('templates:fileManager.popups.save')}
                className="button-primary"
                iconRight={<UILIB.Icons icon="plus" />}
                onClick={this.saveFolder} />

        </div>
        );
    }
}

export default addFolder;
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib';

class CreditCard extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        var classes = "quickFlex";
        if (this.props.default == this.props.card.id) classes += ' default'
        return (
            <div className={classes} style={{ justifyContent: "space-between", alignItems: "center" }}>
                <div className="quickFlex">
                    <div><img style={{ width: '32px', borderRadius: '3px' }} src={"/public/cards/" + this.props.card.brand.toLowerCase() + ".png"} /></div>
                    <div className="pad-left-1">xxxx-xxxx-xxxx-{this.props.card.last4}</div>
                </div>
                <div className="quickFlex" style={{ justifyContent: "end" }}>
                    {this.props.default == this.props.card.id && <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-purple">Primary</UILIB.SquareChip>}
                    {this.props.default !== this.props.card.id && <UILIB.Button text="Make Primary" onClick={() => this.props.makeDefault(this.props.card.id)} className="hide-xs button-small" />}
                    {this.props.allowDelete && <UILIB.Button onClick={() => this.props.deleteCard(this.props.card.id)} className="hide-xs button-small mar-l5" ><UILIB.Icons color="red" icon="bin" /></UILIB.Button>}
                </div>
            </div>
        );
    }
}

export default CreditCard;
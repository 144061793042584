import React, { Component } from 'react';
import { connect } from 'react-redux';
import DrawerEditAction from './drawerEditAction'

@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster, accountMaster: store.accountMaster, doingOptions: false, currentAction: {} }
})
class DrawerAddAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newAutomation: {},
            currentAction: {},
            doingOptions: false
        }
        this.selectAction = this.selectAction.bind(this);
    }

    componentDidMount() {
    }

    selectAction(theAction) {

        var newAutomation = this.props.newAutomation;
        newAutomation.action = theAction.value;
        if (!theAction.hasOptions) {
            this.props.addedStep(newAutomation);
            return;
        }
        this.setState({ newAutomation: newAutomation, currentAction: theAction, doingOptions: true })
    }



    render() {

        //find the current trigger
        var currTrigger = this.props.triggers.initial;
        if (this.props.previousStep != undefined) {
            if (this.props.triggers[this.props.previousStep.action]) {
                currTrigger = this.props.triggers[this.props.previousStep.action];
            }
        }
        currTrigger = currTrigger.find(theTrig => theTrig.value == this.props.newAutomation.trigger)

        if (this.state.doingOptions == true) {
            return <DrawerEditAction newAutomation={this.state.newAutomation} currAction={this.state.currentAction} customFields={this.props.customFields} history={this.props.history} groups={this.props.groups} updatedStep={this.props.addedStep} automationId={this.props.automationId} currentTrigger={currTrigger} />
        }

        var triggerText = currTrigger.longDesc.toLowerCase();
        var theActions = this.props.actions;

        if (currTrigger.value == "datetime" || currTrigger.value == "anniversary") {
            //if its a date time field only show the email actions
            theActions = theActions.filter(theAct => theAct.value == "email")
        }

        return <div>
            <h4 className="mar-b5">And what happens?</h4>
            <div className="mar-b25">What should happen <strong>{triggerText}</strong>?</div>
            <div>
                <ul className="list with-lines">
                    {theActions.map((theAction, index) => {
                        return <li className="middle-md" onClick={() => { this.selectAction(theAction) }} key={"action_" + index}>
                            <p style={{ marginRight: "20px" }}>
                                <div className={theAction.icon} style={{ fontSize: "30px" }} />
                            </p>
                            <p>{theAction.label}
                                <div className="text-grey">{theAction.longDesc}</div>
                            </p>
                        </li>
                    })}
                </ul>
            </div>
        </div>

    }
}

export default DrawerAddAction;
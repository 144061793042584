import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import UILIB from '../../../Common-Objects/Lib';
import axios from '../../../data/http/axios';
import DateTimeFunctions from '../../../Classes/dateTimeFunctions';
import AutomationFunctions from '../automation/addNew/automationFunctions';

export default function SubscriberAutomations() {
    const { subscriberID } = useParams();
    const history = useHistory();
    const [automations, setAutomations] = useState(null);
    const [removing, setRemoving] = useState(null)

    const remove = (queueItem) => {
        setRemoving(queueItem)
    }

    const removeAutomation = async () => {
        await axios.delete(`/automation/${removing.AutomationId}/subscriber/${subscriberID}`)
        await getAutomations()
        setRemoving(null)
    }

    const getAutomations = async () => {
        const res = await axios.get(`/subscriber/${subscriberID}/automations`)
        setAutomations(res.data)
    }

    useEffect(() => {
        getAutomations()
    }, [subscriberID]);

    const popoverStyle = { width: 500, borderRadius: 4 }
    if (removing) {
        popoverStyle.height = 185
        popoverStyle.display = 'flex'
        popoverStyle.justifyContent = 'center'
        popoverStyle.alignItems = 'center'
        popoverStyle.backgroundColor = 'grey'
    }

    const data = (automations || []).filter(a => !!a.Automation)

    return (
        <UILIB.Paper className="mar-t20 mar-b0">
            <h4 className='mar-b20'>Automations</h4>
            {!automations && <UILIB.LoadingIcons />}
            {!!automations && data.length === 0 && <div>Not in any Automations</div>}
            {!!automations && data.length > 0 && <div>
                {data.map(automation => {
                    const step = automation.Automation ? AutomationFunctions.getStepByGuid(automation.NextGuid, automation.Automation.steps) : null
                    let classes = 'mar-r5 mar-b10 square-chip-large'
                    let tooltip = ''
                    if (automation.Finished) {
                        classes += ' square-chip-grey'
                    } else if (automation.Automation?.status === 'Paused') {
                        classes += ' square-chip-yellow'
                    } else if (!automation.Automation) {
                        classes += ' square-chip-red'
                    } else {
                        classes += ' square-chip-green'
                    }
                    return <UILIB.PopOver
                        key={automation.id}
                        side="top"
                        avoidCollisions={true}
                        trigger={<UILIB.SquareChip
                            className={classes}
                            tooltip={tooltip}
                            for={automation.id}
                            iconRight={<UILIB.Icons icon='expand' />}
                        >
                            {automation.Automation?.name}
                        </UILIB.SquareChip>}
                    >
                        <div style={popoverStyle}>
                            {!!removing && <div style={{}}>
                                <div className="mar-b10" style={{ color: 'white', textAlign: 'center', fontSize: 18 }}>
                                    Remove Contact from <strong>{removing.Automation.name}</strong>?
                                </div>
                                <div className="mar-b20" style={{ color: 'white', textAlign: 'center' }}>
                                    This contact will be able to enter this Automation again.
                                </div>
                                <div className="quickFlex" style={{ justifyContent: 'center' }}>
                                    <UILIB.Button className="button-sml mar-r10 button-red" onClick={removeAutomation}>Yes, Delete</UILIB.Button>
                                    <UILIB.Button className="button-sml" onClick={() => setRemoving(null)}>No, Cancel</UILIB.Button>
                                </div>
                            </div>}
                            {!removing && <>
                                {!automation.Automation && <><div className="text-heavy text-lg mar-b10">Automation Deleted</div></>}
                                {!!automation.Automation && <>
                                    <div className="quickFlex mar-b10" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="text-heavy text-md">{automation.Automation?.name}</div>
                                        <UILIB.Button className="button-sml button-primary" onClick={() => history.push('/cp/automation/addnew/' + automation.AutomationId + '?SubscriberId=' + subscriberID)}>View Automation</UILIB.Button>
                                    </div>
                                    <hr className="mar-b10" />
                                    <div className="mar-b10"><strong>Status:</strong> {automation.Automation.status}</div>
                                    <div className="mar-b10"><strong>Added on:</strong> {DateTimeFunctions.formatDateTime(automation.createdAt)}</div>
                                    <div className="mar-b10"><strong>Last Action:</strong> {DateTimeFunctions.formatDateTime(automation.updatedAt)}</div>
                                    <hr className="mar-b10" />
                                    <div className="quickFlex" style={{ justifyContent: 'space-between', alignItem: 'center' }}>
                                        {!step && <div><strong>Step Not Found</strong></div>}
                                        {!!step && <div><strong>Step:</strong> {AutomationFunctions.humanizeStep(step)} {automation.Finished ? '' : '(' + DateTimeFunctions.formatDateTime(automation.ActionDate) + ')'}</div>}
                                        {!!step && automation.Finished && <div><strong>Finished</strong></div>}
                                        <UILIB.Button className="button-sml button-red" onClick={() => remove(automation)}>Remove</UILIB.Button>
                                    </div>
                                </>}
                            </>}

                        </div>
                    </UILIB.PopOver>
                })}
            </div>}
        </UILIB.Paper>
    )
}
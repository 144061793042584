import React from 'react'
import ReactTooltip from 'react-tooltip'

export default class Chip extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        var theValue = this.props.children || this.props.value;
        var hasButton = false;
        if (this.props.hasButton) {
            hasButton = this.props.hasButton;
        }

        var nameCSS = {};
        var mainClass = "chip ";
        var tooltipClass = "tooltip "

        if (hasButton === true) {
            nameCSS.marginRight = "30px";
        }
        if (this.props.maxTextWidth) {
            nameCSS.maxWidth = this.props.maxTextWidth;
        }
        if (this.props.className) {
            mainClass += this.props.className;
            tooltipClass += this.props.className;
        }


        var buttonIcon = "icon-cross-circle";
        if (this.props.buttonIcon) buttonIcon = this.props.buttonIcon;
        if (this.props.tooltip) {
            return <div className={mainClass} style={this.props.style} data-tip data-for={'chip' + this.props.for} onClick={this.props.onClick}>
                <ReactTooltip effect='solid' className={tooltipClass} id={'chip' + this.props.for} >{this.props.tooltip}</ReactTooltip>
                <div className="name" style={nameCSS} >{theValue}</div>
                {hasButton && <div className={"remove " + buttonIcon} onClick={this.props.buttonAction}></div>}
            </div>
        } else {
            return <div style={this.props.style} className={mainClass} onClick={this.props.onClick} alt={this.props.alt} title={this.props.title}>
                <div className="name" style={nameCSS} >{theValue}</div>
                {hasButton && <div className={"remove " + buttonIcon} onClick={this.props.buttonAction}></div>}
            </div>
        }

    }
}

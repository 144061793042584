export default function reducer(

    state = {
        accountMaster: {
            approved: true,
            deny: false,
            doneNewUserQuestions: true,
            accountMaster: {
                beta: {},
                options: {}
            }
        },
        tier: {},
        fields: {},
        systemFields: {},
        panels: undefined,
        chart: undefined,
        table: undefined,
        id: 0,
        refresh: false
    },

    action) {
    switch (action.type) {

        case "LOGGEDIN_SUCCESS_ACCOUNTMASTER":
            {

                var newState = { ...state, "id": action.payload.AccountMasterId, accountMaster: action.payload.AccountMaster };
                return newState;
            }
        case "CHANGE_ACCOUNTMASTER":
            {
                var newState = { ...state, id: action.payload.AccountMaster.id, accountMaster: action.payload.AccountMaster, refresh: true };
                return newState;
            }
        case "CHANGE_FIELDS":
            {
                var newState = { ...state };
                if (!newState.fields) newState.fields = {};
                newState.fields[state.id] = action.payload;
                return newState;
            }
        case "CHANGE_SYSTEM_FIELDS":
            {
                var newState = { ...state };
                if (!newState.systemFields) newState.systemFields = {};
                newState.systemFields[state.id] = action.payload;
                return newState;
            }
        case "CHANGE_LIMIT":
            {
                var newState = { ...state };
                if (!newState.tableLimits) newState.tableLimits = {}
                newState.tableLimits[action.payload.name] = action.payload.limit;
                return newState;
            }
        case 'CHANGE_TIER': {
            var newState = { ...state };
            newState.tier = action.payload
            return newState
        }
        case 'CHANGE_PANELS': {
            const newState = { ...state }
            newState.panels = action.payload
            return newState
        }
        case 'CHANGE_CHART': {
            const newState = { ...state }
            newState.chart = action.payload
            return newState
        }
        case 'CHANGE_TABLE': {
            const newState = { ...state }
            newState.table = action.payload
            return newState
        }
    }
    return state;
}

import React, { useEffect, useState } from "react";
import EmailValidator from "~/Classes/emailValidator";
import UILIB from "~/Common-Objects/Lib";
import axios from "~/data/http/axios";

export default function ChangePreviewEmail({ id, onChanged }) {
  const [domain, setDomain] = useState(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");

  const update = (e) => {
    setDomain({
      ...domain,
      originalEmailAddress: e.currentTarget.value + "@" + domain.domainName,
    });
  };

  const save = async (e) => {
    e.preventDefault();

    setError("");
    if (!EmailValidator.validateEmail(domain.originalEmailAddress)) {
      return setError("Invalid Email Address");
    }
    setSaving(true);
    await axios.put("/accountMaster/domain/" + id, {
      originalEmailAddress: domain.originalEmailAddress,
    });
    setSaving(false);
    onChanged();
  };

  useEffect(() => {
    axios.get("/accountMaster/domain/" + id).then((res) => setDomain(res.data));
  }, [id]);

  if (!domain) return <UILIB.LoadingIcons />;

  const split = domain.originalEmailAddress.split("@");
  const local = split.length > 1 ? split[0] : "";

  return (
    <div>
      <h2 className="h4 mar-b20">Change Preview Email Address</h2>
      <div className="mar-b20">
        When sending preview emails for templates this will be the from address
      </div>

      <form onSubmit={save}>
        <div className="change-preview-email__name mar-b25">
          <UILIB.TextInput
            type="text"
            value={local}
            focus
            required
            placeholder="no-reply"
            onChange={update}
          />

          <div className="code">
            <span>@{domain.domainName}</span>
          </div>
        </div>

        <div className="quickFlex">
          <UILIB.Button type="submit" saving={saving} className="button-primary">
            Save
          </UILIB.Button>
          {!!error && <div className="text-red mar-l10">{error}</div>}
        </div>
      </form>
    </div>
  );
}

import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';
import PermissionChecker from '~/Classes/permissions'

//CONNECT TO STORE
@connect((store) => {
    return {
        user: store.user,
        permissionStore: store.permission,
        siteMaster: store.siteMaster,
        accountMaster: store.accountMaster
    }
})

export default class CompanyTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = { tabIndex: 0 }
        this.tabChange = this.tabChange.bind(this);
        this.setInitialTab = this.setInitialTab.bind(this);
    }

    setInitialTab() {
        var theCurrPath = this.props.history.location.pathname.split("/");
        if (theCurrPath.length > 2) {
            switch (theCurrPath[3]) {
                case "staff": return 1;
                case "roles": return 2;
                case "subscription": return 3;
                case "domains": return 4;
                case "apikeys": return 5;
                case "integrations": return 6;
                case "payments": return 7;
                case "landingpages": return 8;
                case "mailboxes": return 9;
                case "servers": return 10;
                case "sms": return 11;
                default: return 0;
            }
        } else return 0;
    }

    tabChange(event, returnUrl) {
        var self = this;
        var index;
        if (event.currentTarget && event.currentTarget.id !== undefined) {
            index = event.currentTarget.id;
        }
        else if (event.currentTarget && event.currentTarget.value !== undefined) {
            index = event.currentTarget.value;
        }
        else {
            index = event;
        }
        index = String(index);

        if (!returnUrl) self.setState({ tabIndex: index });
        let url
        switch (index) {
            case "0":
                url = '/cp/company/';
                break;
            case "1":
                url = '/cp/company/staff';
                break;
            case "2":
                url = '/cp/company/roles';
                break;
            case "3":
                url = '/cp/company/subscription';
                break;
            case "4":
                url = '/cp/company/domains';
                break;
            case "5":
                url = '/cp/company/apikeys';
                break;
            case "6":
                url = '/cp/company/integrations';
                break;
            case "7":
                url = '/cp/company/payments';
                break;
            case "8":
                url = '/cp/company/landingpages';
                break;
            case "9":
                url = '/cp/company/mailboxes';
                break;
            case "10":
                url = '/cp/company/servers';
                break;
            case "11":
                url = '/cp/company/sms';
                break;
        }
        if (returnUrl) return url;
        self.props.history.push(url);
    }

    render() {
        var thisMenuOptions = [
            { label: [i18n.t('company:nav.myCompany')], permission: '', value: 0, method: "" },
            { label: [i18n.t('company:nav.staff')], permission: 'accountUsers', value: 1, method: "read" },
            { label: [i18n.t('company:nav.roles')], permission: 'roles', value: 2, method: "read" },
            { label: [i18n.t('company:nav.subscription')], permission: 'company', value: 3, method: "write" },
            {
                desktoplabel: <div>SMS <UILIB.SquareChip className="mar-l5 square-chip-green">New</UILIB.SquareChip></div>,
                label: 'SMS',
                value: 11,
                permission: 'company',
                style: { border: "1px solid gray" },
                method: 'write',
                beta: 'sms'
            },
            { label: "Payments", permission: '', value: 7, method: "" },
            { label: [i18n.t('company:nav.domains')], permission: 'domains', value: 4, method: "read" },
            { label: [i18n.t('company:nav.landingpages')], permission: 'company', value: 8, method: "write" },
            { label: [i18n.t('company:nav.replyTracking')], permission: 'company', value: 9, method: "write" },
            { label: [i18n.t('company:nav.integrations')], permission: 'company', value: 6, method: "write" },
            { label: [i18n.t('company:nav.api')], value: 5, admin: true },


        ]

        if (this.props.siteMaster.siteId === 1) {
            thisMenuOptions.push({ label: "Servers", permission: 'company', value: 10, method: "read" })
        }

        var showBilling = true;
        if (this.props.siteMaster.hidePricing) showBilling = false;
        if (this.props.accountMaster.accountMaster.invoice) showBilling = false;

        if (!showBilling) {
            thisMenuOptions = thisMenuOptions.filter(o => o.value !== 3 && o.value !== 7)
        }

        var finalMenuOptions = thisMenuOptions.filter((thisOpt) => {
            if (thisOpt.admin && this.props.user.userData.roleId !== 1) return false
            var permissionName = thisOpt.permission
            if (thisOpt.beta) {
                if (!this.props.accountMaster.accountMaster.beta || !this.props.accountMaster.accountMaster.beta[thisOpt.beta]) return false
            }
            if (!permissionName || PermissionChecker(permissionName, this.props.permissionStore.permissions, thisOpt.method)) {
                return true
            }
        });

        const tabIndex = this.setInitialTab()

        return <UILIB.Paper>
            <div className="mar-b25">
                <div className='page-header'>{i18n.t('company:nav.myCompany')}</div>
            </div>



            <UILIB.Row>
                <UILIB.Column xs={12} hide={["md", "lg"]} className="mar-b25">

                    <UILIB.Select data={finalMenuOptions} onChange={this.tabChange} value={tabIndex} />

                </UILIB.Column>
                <UILIB.Column xs={12} hide={["xs", "sm"]} className="mar-b25">
                    {finalMenuOptions.map((option, index) => {
                        var label = option.desktoplabel || option.label;
                        return <UILIB.ButtonSimple key={"tab_" + index} to={this.tabChange(option.value, true)} className="button-simple-fullsize mar-l5" selected={tabIndex == option.value}>{label}</UILIB.ButtonSimple>
                    })}
                </UILIB.Column>

            </UILIB.Row>

            {this.props.children}
        </UILIB.Paper>
    }
}
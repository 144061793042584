import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '../../../../Common-Objects/Lib.jsx';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import PermissionChecker from '~/Classes/permissions'
import i18n from '~/i18n';

import AddStaffPop from './addStaffPop.jsx';
import DeleteStaffPop from './deleteStaffPop.jsx';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission }
})

export default class CompanyStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoaded: false,
            alertBar: null,
            pageLoaded: false,
            accountUsersData: {},
            accountMasterRoles: {},
            deleteModal: null,
            alertMessage: '',
            alertOpen: false,
            writeAccess: true,
            roleAccess: true,
            account: undefined
        };
        this.loadStaff = this.loadStaff.bind(this);
        this.addStaffMemberDialogue = this.addStaffMemberDialogue.bind(this);
        this.addedUser = this.addedUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.updateRole = this.updateRole.bind(this);
        this.loadAccount = this.loadAccount.bind(this)
        this.toggleAll2fa = this.toggleAll2fa.bind(this)
    }

    componentDidMount() {
        //see if we have write access to the staff
        this.loadAccount()
        var writeAccess = PermissionChecker("accountUsers", this.props.permissionStore.permissions, "write");
        console.log(this.props.permissionStore.permissions)
        var roleAccess = writeAccess;
        this.setState({ writeAccess: writeAccess, roleAccess: roleAccess }, this.loadStaff())
    }

    loadAccount() {
        axios.get('/accountMaster').then(res => {
            this.setState({ account: res.data.AccountMaster })
        })
    }

    //Load Staff stuff
    loadStaff() {

        var usersDat = [];
        axios.get('/accountMaster/users')
            .then((usersData) => {
                usersDat = usersData.data.AccountUsers;
                if (this.state.roleAccess) {
                    return axios.get('/role')
                }
                else {
                    return undefined
                }
            }).then((roleData) => {
                var roleDat = [];
                if (roleData != undefined) {
                    roleDat = roleData.data.Roles
                }
                this.setState({ accountUsersData: usersDat, accountMasterRoles: roleDat, pageLoaded: true });
            });
    }


    //add staff
    addStaffMemberDialogue() {
        var drawerContent = <AddStaffPop userCount={this.state.accountUsersData.length} onAddedUser={this.addedUser} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }
    addedUser() {
        this.setState({ alertOpen: true, alertMessage: i18n.t('company:staff.alertStaffAdded') })
        this.loadStaff();
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
    }

    //delete staff
    deleteUser(userID) {
        var drawerContent = <DeleteStaffPop staffId={userID} onDeleted={this.loadStaff} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    //update role
    updateRole(staffDataId, roleId) {
        var ourUsers = this.state.accountUsersData;
        ourUsers[staffDataId].roleId = roleId;
        var ourUserID = ourUsers[staffDataId].id;
        this.setState({ accountUsersData: ourUsers, alertOpen: true, alertMessage: i18n.t('company:staff.alertUpdatingRole') })
        axios.put('/accountmaster/' + ourUserID, { RoleId: roleId }).then(res => {
            this.loadStaff();
        })
    }

    async toggleAll2fa() {
        const options = this.state.account.options || {}
        options.allUsersRequire2fa = !options.allUsersRequire2fa
        await axios.put('/accountMaster', { options })
        this.loadAccount()
    }

    render() {
        if (!this.state.pageLoaded || !this.state.account) return <UILIB.LoadingIcons iconType="2" />

        return <div>
            {this.state.writeAccess && <div className="mar-b25 quickFlex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    {this.props.user.userData.twoFAEnabled && this.props.user.userData.roleId === 1 && <UILIB.Toggle after={this.state.account.isAgencyAdmin ? 'Agency accounts must have 2fa enabled' : 'Require all users to have 2fa enabled'} onChange={this.toggleAll2fa} checked={this.state.account?.options?.allUsersRequire2fa} disabled={this.state.account.isAgencyAdmin} />}
                </div>
                <UILIB.Button
                    className="button-primary"
                    onClick={this.addStaffMemberDialogue}
                    text={i18n.t('company:staff.addStaff')}
                    iconLeft={<UILIB.Icons icon="plus" />}
                />
            </div>}
            <UILIB.Row>
                {this.state.accountUsersData.map((theUser, index) => {
                    return <UILIB.Column xs={12} sm={6} md={6} lg={3} key={theUser.id}>
                        <UILIB.StaffCard writeAccess={this.state.writeAccess} roleAccess={this.state.roleAccess} userData={theUser} dataId={index} rolesData={this.state.accountMasterRoles} updateRole={this.updateRole} onDelete={this.deleteUser} />
                    </UILIB.Column>
                })}
            </UILIB.Row>
            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
        </div>

    };


};



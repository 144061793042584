import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'
import axiosraw from 'axios'
import utils from '../../../dragDropFunctions'

export default class ComponentDailyMotionVideo extends Component {
    constructor(props) {
        super(props)

        this.change = this.change.bind(this);
        this.grabVideoThumb = this.grabVideoThumb.bind(this);
    }

    grabVideoThumb(vidId, origVal) {
        var url = "https://api.dailymotion.com/video/" + vidId + "?fields=thumbnail_large_url";
        axiosraw.get(url).then(vidData => {

            vidData = vidData.data
            var thumb = vidData.thumbnail_large_url ? vidData.thumbnail_large_url : ""
            return utils.getVideoThumb(this.props.templateId, thumb).then(response => {
                this.props.updateVal(this.props.path, { value: origVal, thumbUrl: response.data.url });
            })
        }).catch(err => {
            console.log(err);
        });

    }
    change(event) {

        var thisVal = event.currentTarget.value;
        this.props.updateVal(this.props.path, { value: thisVal, thumbUrl: "" });
        if (thisVal.toLowerCase().indexOf("dailymotion.com") == -1) {
            this.props.updateVal(this.props.path, { value: thisVal, thumbUrl: "" });
            return;
        }

        var vidUrl = thisVal.split(/\//g);
        var vidId = vidUrl[vidUrl.length - 1].split("?")[0].split("/")[0];
        if (vidId == "") {
            this.props.updateVal(this.props.path, { value: thisVal, thumbUrl: "" });
            return;
        }
        this.grabVideoThumb(vidId, thisVal)

    }

    render() {
        var style = {};
        if (this.props.elementData.optionFullWidth) style = { flex: "100%" }

        var errorText = "";
        if (this.props.value.toLowerCase().indexOf("dailymotion.com") == -1) {
            errorText = "Please enter a valid Daily Motion video url (ie https://dailymotion.com/video/video_id)"
        } else {
            var vidUrl = this.props.value.split(/\//g);
            var vidId = vidUrl[vidUrl.length - 1].split("?")[0].split("/")[0];
            if (vidId == "") {
                errorText = "Please enter a valid Daily Motion video url (ie https://dailymotion.com/video/video_id)"
            }
        }

        return <div className="dd_toolHolder" style={style}>
            <div className="dd_inputTool">
                <UILIB.TextInput label={this.props.title} value={this.props.value} onChange={this.change} placeholder={this.props.title} error={errorText} />
            </div>
        </div>

    }
}



import React from 'react'

export default function IconBookmark({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";

    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 4C5 3.46957 5.21071 2.96086 5.58579 2.58579C5.96086 2.21071 6.46957 2 7 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V18L10 15.5L5 18V4Z" fill={fillColor} />
        </svg>
    )
}
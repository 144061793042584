import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PermissionChecker from "~/Classes/permissions";

export function useHasWriteAccess(permissionName) {
  const { permissions } = useSelector((state) => state.permission);

  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    setHasAccess(PermissionChecker(permissionName, permissions, "write"));
  }, [permissions]);

  return hasAccess;
}

import React from 'react'
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';

import IconPlus from './plus';
import IconPlusChunky from './plusChunky';
import IconArrowDownSmall from './arrowDownSmall';
import IconBell from './bell';
import ArrowDown from './arrowDown';
import ArrowUp from './arrowUp';
import ArrowRight from './arrowRight';
import ArrowLeft from './arrowLeft';
import ArrowLeftSmall from './arrowLeftSmall';
import DoubleArrowUp from './doubleArrowUp';
import Help from './help';
import QuestionCircle from './questionCircle';
import Document from './document';
import Calendar from './calendar';
import List from './list';
import Envelope from './envelope';
import Lightning from './lightning';
import CopyClipboard from './copyClipboard';
import Grid from './grid';
import Tick from './tick';
import Share from './share';
import Cog from './cog';
import Cross from './cross';
import CrossChunky from './crossChunky';
import Magnifier from './magnifier';
import Circle from './circle';
import CircleSolid from './circleSolid';
import Bin from './bin';
import Image from './image';
import NewWindow from './newWindow';
import World from './world';
import Star from './star';
import StarSolid from './starSolid';
import Pencil from './pencil';
import Upload from './upload';
import Facebook from './facebook';
import Warning from './warning';
import Twitter from './twitter';
import Instagram from './instagram';
import LinkedIn from './linkedin';
import YouTube from './youtube';
import Rss from './rss';
import HandPointingUp from './handPointingUp';
import Folder from './folder';
import Wand from './wand';
import ArrowSelect from './arrowSelect';
import Split from './split';
import TickCircle from './tickCircle';
import Mobile from './mobile';
import Alert from './alert';
import Outbox from './outbox';
import PersonAdd from './personAdd';
import Template from './template';
import Stack from './stack';
import Capsule from './capsule'
import Pc from './pc'
import Avatars3 from './avatars3'
import Tablet from './tablet'
import Smiley from './smiley'
import CrossCircle from './crossCircle';
import DotsVertical from './dotsVertical';
import Money from './money';
import ShoppingBag from './shoppingbag';
import Undo from './undo';
import Eye from './eye';
import MoveIcon from './move';
import Loading from './loading'
import LifeRing from './lifeRing'
import Code from './code';
import LineArrowDown from './lineArrowDown';
import LineArrowRight from './lineArrowRight';
import AlignLeft from './alignLeft';
import AlignCenter from './alignCenter';
import AlignRight from './alignRight';
import Person from './person'
import Switch from './switch'
import Avatars2 from './avatars2'
import Tag from './tag'
import Filter from './filter'
import Office from './office'
import Swatch from './swatch'
import Sparkle from './sparkle'
import Sparkle2 from './sparkle2'
import Organisations from './organisations'
import Sms from './sms'
import Integrations from './integrations'
import Globe from './globe'
import Megaphone from './megaphone'
import External from './external'
import Logout from './logout'
import Pinterest from './pinterest'
import ZoomIn from './zoomIn'
import ZoomOut from './zoomOut'
import Clock from './clock'
import Download from './download'
import Download3 from './download3'
import BranchDown from './branchDown'
import Transaction from './transaction'
import ArrowsUpDown from './arrowsUpDown'
import Medal from './medal'
import ArrowBounce from './arrowBounce'
import EnvelopeOpen from './envelopeOpen'
import Text from './text'
import Divider from './divider'
import Button from './button'
import Heart from './heart'
import Chart from './chart'
import Input from './input'
import Select from './select'
import HiddenInput from './hiddenInput'
import RadioButton from './radioButton'
import TextArea from './textarea'
import Captcha from './captcha'
import CheckBox from './checkbox'
import Cake from './cake'
import Wave from './wave'
import Bookmark from './bookmark'
import Floppydisk from './floppydisk'
import ShieldTick from './shieldTick'
import Menu from './menu';
import CloudUpload from './cloudUpload';
import Expand from './expand';
import Contract from './contract';
import ThumbsUp from './thumbsUp';
import ThumbsDown from './thumbsDown';
import Reply from './reply';
import Surveillance from './surveillance';
import History from './history';
import ChevronLeft from './chevronLeft'
import ChevronRight from './chevronRight'
import ChevronDown from './chevronDown';
import Link from './link'
import Palette from './palette';
import TikTok from './tikTok';
import Save from './save'
import TickCircleDouble from './tickCircleDouble';
import Chat from './chat'
import Beaker from './beaker';
import Table from './table'
import Exclude from './exclude'
import CircleThin from './circleThin'
import PaperPlane from './paperPlane'
import Drag from './drag'
// icons that use their own colors unless disabled
import InstagramColor from './instagramColor';
import XColor from './xColor';
import LinkedInColor from './linkedinColor';
import FacebookColor from './facebookColor';
import FacebookColorRounded from './facebookColorRounded';
import PInterestColorRounded from './pinterestColorRounded';
import XColorRounded from './xColorRounded';
import LinkedInColorRounded from './linkedInColorRounded';
import InstagramColorRounded from './instagramColorRounded';
import IconsPlay from './play'
import IconsPaused from './paused'

// icons for segment operators
import Contains from './contains';
import DoesNotContain from './doesNotContain';
import DoesNotEndWith from './doesNotEndWith';
import DoesNotStartWith from './doesNotStartWith';
import EndsWith from './endsWith';
import Equals from './equals';
import IsBlank from './isBlank';
import IsNotBlank from './isNotBlank';
import NotEquals from './notEquals';
import StartsWith from './startsWith';
import Between from './between';
import Clicked from './clicked';
import GreaterThan from './greaterThan';
import GreaterThanOrEquals from './greaterThanOrEquals';
import LessThan from './lessThan';
import LessThanOrEquals from './lessThanOrEquals';
import NotClicked from './notClicked';
import StarRatingIs from './starRatingIs';

@connect((store) => {
    return {
        siteMaster: store.siteMaster
    }
})


export default class Icons extends React.Component {
    render() {
        let icon = undefined;
        let color = this.props.siteMaster.colours["$primary"];

        if (this.props.color) color = this.props.color;

        let style = {};
        if (this.props.style) style = JSON.parse(JSON.stringify(this.props.style));
        if (this.props.onClick) style.cursor = "pointer";

        let className = "icons-holder";
        if (this.props.className) className += " " + this.props.className;

        const outerStyle = { ...style, ...this.props.outerStyle }
        if (this.props.circle) {
            outerStyle.borderRadius = '50%';
            outerStyle.backgroundColor = color && color.indexOf('#') === 0 ? `${hexToRgbA(color, 0.2)}` : 'transparent'
            outerStyle.padding = 5
            outerStyle.display = 'flex';
            outerStyle.justifyContent = 'center';
            outerStyle.alignItems = 'center';
        }
        if (this.props.square) {
            outerStyle.borderRadius = '4px';
            outerStyle.backgroundColor = color && color.indexOf('#') === 0 ? `${hexToRgbA(color, 0.2)}` : 'transparent'
            outerStyle.padding = 5
            outerStyle.display = 'flex';
            outerStyle.justifyContent = 'center';
            outerStyle.alignItems = 'center';
        }

        switch (this.props.icon) {
            case "plus":
                icon = <IconPlus color={color} style={style} />;
                break;
            case "plusChunky":
                icon = <IconPlusChunky color={color} style={style} />;
                break;
            case "smallArrowDown":
                icon = <IconArrowDownSmall color={color} style={style} />;
                break;
            case "bell":
                icon = <IconBell color={color} style={style} />;
                break;
            case "arrowUp":
                icon = <ArrowUp color={color} style={style} />;
                break;
            case "arrowDown":
                icon = <ArrowDown color={color} style={style} />;
                break;
            case "arrowRight":
                icon = <ArrowRight color={color} style={style} />;
                break;
            case "arrowLeft":
                icon = <ArrowLeft color={color} style={style} />;
                break;
            case "arrowLeftSmall":
                icon = <ArrowLeftSmall color={color} style={style} />;
                break;
            case "doubleArrowUp":
                icon = <DoubleArrowUp color={color} style={style} />;
                break;
            case "help":
                icon = <Help color={color} style={style} />;
                break;
            case "questionCircle":
                icon = <QuestionCircle color={color} style={style} />;
                break;
            case "document":
                icon = <Document color={color} style={style} />;
                break;
            case "calendar":
                icon = <Calendar color={color} style={style} />;
                break;
            case "list":
                icon = <List color={color} style={style} />;
                break;
            case "envelope":
                icon = <Envelope color={color} style={style} />;
                break;
            case "lightning":
                icon = <Lightning color={color} style={style} />;
                break;
            case "copyClipboard":
                icon = <CopyClipboard color={color} style={style} />;
                break;
            case "grid":
                icon = <Grid color={color} style={style} />;
                break;
            case 'checkbox':
                icon = <CheckBox color={color} style={style} />;
                break;
            case "tick":
                icon = <Tick color={color} style={style} />;
                break;
            case "share":
                icon = <Share color={color} style={style} />;
                break;
            case "cog":
                icon = <Cog color={color} style={style} />;
                break;
            case "cross":
                icon = <Cross color={color} style={style} />;
                break;
            case "crossChunky":
                icon = <CrossChunky color={color} style={style} />;
                break;
            case "magnifier":
                icon = <Magnifier color={color} style={style} />;
                break;
            case "circle":
                icon = <Circle color={color} style={style} />;
                break;
            case "circleSolid":
                icon = <CircleSolid color={color} style={style} />;
                break;
            case "bin":
                icon = <Bin color={color} style={style} />;
                break;
            case "image":
                icon = <Image color={color} style={style} />;
                break;
            case "newWindow":
                icon = <NewWindow color={color} style={style} />;
                break;
            case "world":
                icon = <World color={color} style={style} />;
                break;
            case "star":
                icon = <Star color={color} style={style} />;
                break;
            case "starSolid":
                icon = <StarSolid color={color} style={style} />;
                break;
            case "pencil":
                icon = <Pencil color={color} style={style} />;
                break;
            case "upload":
                icon = <Upload color={color} style={style} />;
                break;
            case "facebook":
                icon = <Facebook color={color} style={style} />;
                break;
            case 'errormessageholder':
            case "warning":
                icon = <Warning color={color} style={style} />;
                break;
            case "twitter":
                icon = <Twitter color={color} style={style} />;
                break;
            case "instagram":
                icon = <Instagram color={color} style={style} />;
                break;
            case "linkedin":
                icon = <LinkedIn color={color} style={style} />;
                break;
            case 'youtubevideo':
            case "youtube":
                icon = <YouTube color={color} style={style} />;
                break;
            case "tikTok":
                icon = <TikTok color={color} style={style} />;
                break;
            case 'rssfeed':
            case "rss":
                icon = <Rss color={color} style={style} />;
                break;
            case 'submit':
            case "handPointingUp":
                icon = <HandPointingUp color={color} style={style} />;
                break;
            case "folder":
                icon = <Folder color={color} style={style} />;
                break;
            case "wand":
                icon = <Wand color={color} style={style} />;
                break;
            case "arrowSelect":
                icon = <ArrowSelect color={color} style={style} />;
                break;
            case "split":
                icon = <Split color={color} style={style} />;
                break;
            case "tickCircle":
                icon = <TickCircle color={color} style={style} />;
                break;
            case "tickCircleDouble":
                icon = <TickCircleDouble color={color} style={style} />;
                break;
            case "mobile":
                icon = <Mobile color={color} style={style} />;
                break;
            case "alert":
                icon = <Alert color={color} style={style} />;
                break;
            case 'outbox':
                icon = <Outbox color={color} style={style} />;
                break;
            case 'personAdd':
                icon = <PersonAdd color={color} style={style} />;
                break;
            case 'template':
                icon = <Template color={color} style={style} />;
                break;
            case 'stack':
                icon = <Stack color={color} style={style} />;
                break;
            case 'capsule':
                icon = <Capsule color={color} style={style} />;
                break
            case 'pc':
                icon = <Pc color={color} style={style} />;
                break
            case 'avatars3':
                icon = <Avatars3 color={color} style={style} />;
                break
            case 'tablet':
                icon = <Tablet color={color} style={style} />;
                break
            case 'smiley':
                icon = <Smiley color={color} style={style} />;
                break
            case 'crossCircle':
                icon = <CrossCircle color={color} style={style} />;
                break
            case 'dotsVertical':
                icon = <DotsVertical color={color} style={style} />;
                break
            case 'money':
                icon = <Money color={color} style={style} />;
                break
            case 'shoppingbag':
                icon = <ShoppingBag color={color} style={style} />;
                break
            case 'undo':
                icon = <Undo color={color} style={style} />;
                break
            case 'eye':
                icon = <Eye color={color} style={style} />;
                break
            case 'move':
                icon = <MoveIcon color={color} style={style} />;
                break
            case 'loading':
                icon = <Loading color={color} style={style} />;
                break;
            case 'lifering':
                icon = <LifeRing color={color} style={style} />;
                break;
            case 'customhtml':
            case 'code':
                icon = <Code color={color} style={style} />;
                break;
            case 'lineArrowDown':
                icon = <LineArrowDown color={color} style={style} />;
                break;
            case 'lineArrowRight':
                icon = <LineArrowRight color={color} style={style} />;
                break;
            case 'alignLeft':
                icon = <AlignLeft color={color} style={style} />;
                break;
            case 'alignRight':
                icon = <AlignRight color={color} style={style} />;
                break;
            case 'alignCenter':
                icon = <AlignCenter color={color} style={style} />;
                break;
            case 'person':
                icon = <Person color={color} style={style} />;
                break;
            case 'switch':
                icon = <Switch color={color} style={style} />;
                break;
            case 'avatars2':
                icon = <Avatars2 color={color} style={style} />;
                break;
            case 'tag':
                icon = <Tag color={color} style={style} />;
                break;
            case 'filter':
                icon = <Filter color={color} style={style} />;
                break;
            case 'office':
                icon = <Office color={color} style={style} />;
                break;
            case 'swatch':
                icon = <Swatch color={color} style={style} />;
                break;
            case 'sparkle':
                icon = <Sparkle color={color} style={style} />;
                break;
            case 'sparkle2':
                icon = <Sparkle2 color={color} style={style} />;
                break;
            case 'organisations':
                icon = <Organisations color={color} style={style} />;
                break;
            case 'sms':
                icon = <Sms color={color} style={style} />;
                break;
            case 'integrations':
                icon = <Integrations color={color} style={style} />;
                break;
            case 'globe':
                icon = <Globe color={color} style={style} />;
                break;
            case 'megaphone':
                icon = <Megaphone color={color} style={style} />;
                break;
            case 'external':
                icon = <External color={color} style={style} />;
                break;
            case 'logout':
                icon = <Logout color={color} style={style} />;
                break;
            case 'pinterest':
                icon = <Pinterest color={color} style={style} />;
                break;
            case 'zoomIn':
                icon = <ZoomIn color={color} style={style} />;
                break;
            case 'zoomOut':
                icon = <ZoomOut color={color} style={style} />;
                break;
            case 'countdown':
            case 'clock':
                icon = <Clock color={color} style={style} />;
                break;
            case 'download':
                icon = <Download color={color} style={style} />;
                break;
            case 'download3':
                icon = <Download3 color={color} style={style} />;
                break;
            case 'branchDown':
                icon = <BranchDown color={color} style={style} />;
                break;
            case 'transaction':
                icon = <Transaction color={color} style={style} />;
                break;
            case 'arrowsUpDown':
                icon = <ArrowsUpDown color={color} style={style} />;
                break;
            case 'medal':
                icon = <Medal color={color} style={style} />;
                break;
            case 'arrowBounce':
                icon = <ArrowBounce color={color} style={style} />;
                break;
            case 'envelopeOpen':
                icon = <EnvelopeOpen color={color} style={style} />;
                break;
            case 'text':
                icon = <Text color={color} style={style} />;
                break;
            case 'divider':
                icon = <Divider color={color} style={style} />;
                break;
            case 'button':
                icon = <Button color={color} style={style} />;
                break;
            case 'socialmediaicons':
            case 'heart':
                icon = <Heart color={color} style={style} />;
                break;
            case 'chart':
            case 'poll':
                icon = <Chart color={color} style={style} />;
                break;
            case 'input':
                icon = <Input color={color} style={style} />;
                break;
            case 'select':
                icon = <Select color={color} style={style} />;
                break;
            case 'hiddeninput':
                icon = <HiddenInput color={color} style={style} />;
                break;
            case 'radiobuttons':
            case 'radiobutton':
                icon = <RadioButton color={color} style={style} />;
                break;
            case 'textarea':
                icon = <TextArea color={color} style={style} />;
                break;
            case 'captcha':
                icon = <Captcha color={color} style={style} />;
                break;
            case 'cake':
                icon = <Cake color={color} style={style} />;
                break;
            case 'wave':
                icon = <Wave color={color} style={style} />;
                break;
            case 'bookmark':
                icon = <Bookmark color={color} style={style} />;
                break;
            case 'floppydisk':
                icon = <Floppydisk color={color} style={style} />;
                break;
            case 'shieldtick':
                icon = <ShieldTick color={color} style={style} />;
                break;
            case 'menu':
                icon = <Menu color={color} style={style} />;
                break;
            case 'cloudUpload':
                icon = <CloudUpload color={color} style={style} />;
                break;
            case 'expand':
                icon = <Expand color={color} style={style} />;
                break;
            case 'contract':
                icon = <Contract color={color} style={style} />;
                break;
            case 'thumbsUp':
                icon = <ThumbsUp color={color} style={style} />;
                break;
            case 'thumbsDown':
                icon = <ThumbsDown color={color} style={style} />;
                break;
            case 'reply':
                icon = <Reply color={color} style={style} />;
                break;
            case 'surveillance':
                icon = <Surveillance color={color} style={style} />;
                break;
            case 'history':
                icon = <History color={color} style={style} />;
                break;
            case 'chevronLeft':
                icon = <ChevronLeft color={color} style={style} />
                break;
            case 'chevronRight':
                icon = <ChevronRight color={color} style={style} />
                break;
            case 'chevronDown':
                icon = <ChevronDown color={color} style={style} />
                break;
            case 'link':
                icon = <Link color={color} style={style} />
                break;
            case 'palette':
                icon = <Palette color={color} style={style} />
                break;
            case 'gatedcontent':
            case 'save':
                icon = <Save color={color} style={style} />
                break;
            case 'chat':
                icon = <Chat color={color} style={style} />
                break;
            case "beaker":
                icon = <Beaker color={color} style={style} />;
                break;
            case "table":
                icon = <Table color={color} style={style} />;
                break;
            case "exclude":
                icon = <Exclude color={color} style={style} />;
                break;
            case 'circleThin':
                icon = <CircleThin color={color} style={style} />
                break;
            case 'paperPlane':
                icon = <PaperPlane color={color} style={style} />
                break;

            case "instagramColor":
                icon = <InstagramColor color={this.props.disabled ? '#B5BAC0' : ''} style={style} />;
                break;
            case "xColor":
                icon = <XColor color={this.props.disabled ? '#B5BAC0' : ''} style={style} />;
                break;
            case "linkedinColor":
                icon = <LinkedInColor color={this.props.disabled ? '#B5BAC0' : ''} style={style} />;
                break;
            case "facebookColor":
                icon = <FacebookColor color={this.props.disabled ? '#B5BAC0' : ''} style={style} />;
                break;
            case "beaker":
                icon = <Beaker color={color} style={style} />;
                break;
            case "drag":
                icon = <Drag color={color} style={style} />;
                break;
            case "facebookColorRounded":
                icon = <FacebookColorRounded color={color} style={style} />;
                break;
            case "pinterestColorRounded":
                icon = <PInterestColorRounded color={color} style={style} />;
                break;
            case "xColorRounded":
                icon = <XColorRounded color={color} style={style} />;
                break;
            case "linkedInColorRounded":
                icon = <LinkedInColorRounded color={color} style={style} />;
                break;
            case "instagramColorRounded":
                icon = <InstagramColorRounded color={color} style={style} />;
                break;
            case "play":
                icon = <IconsPlay color={color} style={style} />;
                break;
            case "paused":
                icon = <IconsPaused color={color} style={style} />;
                break;
            case "contains":
                icon = <Contains color={color} style={style} />;
                break;
            case "doesNotContain":
                icon = <DoesNotContain color={color} style={style} />;
                break;
            case "doesNotEndWith":
                icon = <DoesNotEndWith color={color} style={style} />;
                break;
            case "doesNotStartWith":
                icon = <DoesNotStartWith color={color} style={style} />;
                break;
            case "endsWith":
                icon = <EndsWith color={color} style={style} />;
                break;
            case "equals":
                icon = <Equals color={color} style={style} />;
                break;
            case "isBlank":
                icon = <IsBlank color={color} style={style} />;
                break;
            case "isNotBlank":
                icon = <IsNotBlank color={color} style={style} />;
                break;
            case "notEquals":
                icon = <NotEquals color={color} style={style} />;
                break;
            case "startsWith":
                icon = <StartsWith color={color} style={style} />;
                break;
            case "between":
                icon = <Between color={color} style={style} />;
                break;
            case "clicked":
                icon = <Clicked color={color} style={style} />;
                break;
            case "greaterThanOrEquals":
                icon = <GreaterThanOrEquals color={color} style={style} />;
                break;
            case "greaterThan":
                icon = <GreaterThan color={color} style={style} />;
                break;
            case "lessThan":
                icon = <LessThan color={color} style={style} />;
                break;
            case "lessThanOrEquals":
                icon = <LessThanOrEquals color={color} style={style} />;
                break;
            case "notClicked":
                icon = <NotClicked color={color} style={style} />;
                break;
            case "starRatingIs":
                icon = <StarRatingIs color={color} style={style} />;
                break;
            default:
                icon = ""
                break;
        }

        if (!icon) return null
        if (this.props.svgOnly) {
            if (this.props["data-tip"] && this.props["data-for"]) {
                icon["data-tip"] = this.props["data-tip"]
                icon["data-for"] = this.props["data-for"]
            }
            return icon
        }

        return <div onClick={this.props.onClick} className={className} style={outerStyle} alt={this.props.alt} title={this.props.title} data-tip={this.props["data-tip"]} data-for={this.props["data-for"]}>
            {icon}
            {this.props["data-tip"] && this.props["data-for"] && <ReactTooltip id={this.props["data-for"]} effect="solid" />}
        </div>


    }
}

function hexToRgbA(hex, opacity = 1) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
    }
    throw new Error('Bad Hex');
}
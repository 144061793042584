import React, { useCallback, useState, useEffect } from 'react';
import axios from '@/data/http/axios';
import UILIB from '@/Common-Objects/Lib'
import DateTimeFunction from '@/Classes/dateTimeFunctions'
import i18n from '@/i18n'

export default function SentHistory({ CampaignId, SendingQueueId }) {
    const [loading, setLoading] = useState(true)
    const [history, setHistory] = useState(null)

    const getData = useCallback(async () => {
        const results = await axios.get('/campaign/' + CampaignId + '/stats/sent/' + SendingQueueId + '/history')
        setHistory(results.data)
    })

    useEffect(() => {
        if (history) {
            setLoading(false)
        }
    }, [history])

    useEffect(() => {
        getData()
    }, [])

    if (loading) return <UILIB.LoadingIcons iconType="2" />

    const tableData = history.map(row => ({
        datetime: {
            headerValue: i18n.t('campaigns:overview.datetime'),
            value: DateTimeFunction.formatDateTime(row.date),
            orderBy: false
        },
        action: {
            headerValue: i18n.t('campaigns:overview.action'),
            value: row.action,
            orderBy: false,
        },
        data: {
            headerValue: i18n.t('campaigns:overview.details'),
            value: row.data,
            orderBy: false
        }
    }))

    return (
        <UILIB.DataTable1
            noResultsTxt={<span> {i18n.t('noResults')}</span>}
            tableData={tableData}
            width="100%"
        />
    )
}
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import IncAddCustomField from '~/pages/cp/subscribers/customfields/incAddCustomField'
import segmentsHelper from '~/Classes/segments.jsx'
import SegmentEditor from '~/pages/cp/includes/segmentEditor/segmentEditor'
import HelperFunctions from '~/Classes/helpers'
import AutomationFunctions from '../automationFunctions'
import axios from '~/data/http/axios'
import i18n from '~/i18n'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import CampaignSelector from './utils/campaignSelector';
import HelpPopup from '../helpPopup/helpPopup';
import TriggerFilters from '../integrations/generic/triggerFilters';
@connect((store) => {
    return { siteMaster: store.siteMaster }
})
class DrawerGoalStep extends Component {
    constructor(props) {
        super(props);

        var stringOperators = segmentsHelper.getOperators('string');
        var intOperators = segmentsHelper.getOperators('int');
        var boolOperators = segmentsHelper.getOperators('bool');
        var dateOperators = segmentsHelper.getOperators('date');

        dateOperators = dateOperators.filter(obj => {
            return obj.value !== "between" && obj.value !== "not between";
        });

        var groupOperators = segmentsHelper.groupOperators;

        this.state = {
            drawerContent: undefined,
            step: {},
            errors: {},
            operators: {
                TEXT: stringOperators,
                NUMBER: intOperators,
                DATE: dateOperators,
                GROUP: groupOperators,
                'TRUE/FALSE': boolOperators,
                BOOLEAN: boolOperators,
                SELECT: boolOperators,
                MULTISELECT: boolOperators,
                TELNO: stringOperators
            },
            campaignLinks: [],
            webhookDefinitions: [],
            automations: null,
            newAutomationName: '',
            creatingNewAutomation: false
        }

        this.updateValue = this.updateValue.bind(this);
        this.saveStep = this.saveStep.bind(this);
        this.updateDateValue = this.updateDateValue.bind(this);
        this.createField = this.createField.bind(this);
        this.createdField = this.createdField.bind(this);
        this.createSegment = this.createSegment.bind(this);
        this.createdSegment = this.createdSegment.bind(this);
        this.getCampaignLinks = this.getCampaignLinks.bind(this);
        this.viewEditSegment = this.viewEditSegment.bind(this);
        this.createNewAutomation = this.createNewAutomation.bind(this)
        this.updateNewAutomationName = this.updateNewAutomationName.bind(this)
        this.getAutomations = this.getAutomations.bind(this)
    }

    componentDidMount() {

        //add fields if they dont exist
        if (this.props.step && this.props.step.stepOptions) {
            if (!this.props.step.stepOptions.goalEndAutomation) this.props.step.stepOptions.goalEndAutomation = { value: "" }
            if (!this.props.step.stepOptions.groupId) this.props.step.stepOptions.groupId = { "value": "-1" }
            if (!this.props.step.stepOptions.subscriberState) this.props.step.stepOptions.subscriberState = { "value": "" }
            if (!this.props.step.stepOptions.filters) this.props.step.stepOptions.filters = [];
        }

        this.setState({ step: this.props.step }, () => {
            if (this.state.step.stepOptions.triggerType.value == "3" && this.state.step.stepOptions.campaignId.value != "-1" && (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5")) {
                this.getCampaignLinks()
            }
            if (this.state.step.stepOptions.triggerType.value == "0" && this.state.step.stepOptions.workflowEmailGuid.value != "-1" && (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5")) {
                this.getCampaignLinks()
            }

            if (this.state.step.stepOptions.triggerType.value == "10") {
                this.getAutomations()
            }

            try {

                const getters = []
                const names = []

                const check = (initial) => {
                    if (isNaN(initial.triggerType.value)) {
                        // its an integration
                        const app = this.props.apps.find(a => a.Application.appName === initial.triggerType.value)
                        if (app) {
                            if (names.indexOf(initial.stepOptions.trigger.value) > -1) return
                            names.push(initial.stepOptions.trigger.value)
                            getters.push(axios.get('/application/' + app.ApplicationId + '/webhookdefinition?trigger=' + initial.stepOptions.trigger.value))
                        }
                    }
                }
                check(this.props.steps[0])
                if (getters.length) {
                    Promise.all(getters).then(results => {
                        this.setState({
                            webhookDefinitions: results.filter(r => r.data.condition).map(r => r.data)
                        })
                    })
                }
            } catch (e) {
                console.log(e)
                console.log(this.props)
            }
        });
    }

    updateValue(event) {
        var fieldName = event.target.name;
        var step = this.state.step;
        if (!step.stepOptions[fieldName]) step.stepOptions[fieldName] = {}

        if (fieldName === 'otherAutomations') {
            const arr = step.stepOptions[fieldName].value || []
            const index = arr.indexOf(event.target.value)
            if (index > -1) arr.splice(index, 1)
            else arr.push(event.target.value)
            step.stepOptions[fieldName].value = arr
        } else {
            step.stepOptions[fieldName].value = event.target.value;
        }
        step.complete = false;

        if (fieldName == "triggerType") {
            step.stepOptions.customFieldId.value = "-1"
            step.stepOptions.customFieldAction.value = "="
            step.stepOptions.customFieldValue.value = ""
            step.stepOptions.triggerPosition.value = "0";
        }

        if (fieldName == "customFieldId") {
            step.stepOptions.customFieldAction.value = "="
            step.stepOptions.customFieldValue.value = ""
            const selectedCustomField = this.props.customFields.find(fd => fd.value == this.state.step.stepOptions.customFieldId.value)
            if (selectedCustomField && selectedCustomField.fieldType === 'BOOLEAN') {
                step.stepOptions.customFieldValue.value = true;
            }
        }

        if (fieldName == "campaignId" || fieldName == "workflowEmailGuid" || (fieldName == "workflowEmailActionType" && (event.target.value == "3" || event.target.value == "5"))) {
            this.getCampaignLinks()
        }

        if (fieldName === 'triggerType' && event.target.value == "10" && !this.state.automations) {
            this.getAutomations()
        }

        this.setState(step)
    }

    updateDateValue(event, dateEvent) {
        var step = this.state.step;
        step.stepOptions.customFieldValue.value = dateEvent.format("YYYY-MM-DD");
        step.complete = false;
        this.setState(step)
    }

    createField() {
        var drawerContent = <IncAddCustomField getCustomFields={this.createdField} standalone={true} />
        this.setState({ drawerContent })
    }
    createdField(newField) {
        var customFieldId = newField.id;
        var step = this.state.step;
        step.stepOptions.customFieldId.value = customFieldId;
        step.complete = false;
        this.setState({ drawerContent: undefined, step }, () => { this.props.getCustomFields(false); })
    }


    createSegment() {
        var drawerContent = <SegmentEditor history={this.props.history} close={this.createdSegment} />
        this.setState({ drawerContent }, () => {
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("100vw"));
        })
    }
    createdSegment(segmentData) {
        var segmentId = segmentData.data.Segment.segmentId;
        var step = this.state.step;
        step.stepOptions.segmentId.value = segmentId;
        step.complete = false;
        this.setState({ drawerContent: undefined, step }, () => {
            this.props.getSegments(false);
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("500px"));
        })
    }

    viewEditSegment() {
        var step = this.state.step;
        var segmentId = step.stepOptions.segmentId.value;
        var drawerContent = <SegmentEditor history={this.props.history} close={() => {
            this.setState({ drawerContent: undefined })
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("500px"));
        }} segmentId={segmentId} />
        this.setState({ drawerContent }, () => {
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("100vw"));
        })
    }


    getCampaignLinks() {
        var self = this;
        this.setState({ campaignLinks: [] })
        if (this.state.step.stepOptions.triggerType.value == "3" && this.state.step.stepOptions.campaignId.value) {
            var campaign = this.props.campaigns.find(cp => cp.value == this.state.step.stepOptions.campaignId.value)
            axios.get('/template/' + campaign.templateId + '/links').then(response => {
                var linkData = response.data.Links.map(l => { return { label: l, value: l } })
                this.setState({ campaignLinks: linkData })
            })
        }
        if (this.state.step.stepOptions.triggerType.value == "0" && this.state.step.stepOptions.workflowEmailGuid.value) {
            var workflowEmails = AutomationFunctions.getWorkflowEmails(this.props.step.guid, this.props.steps);
            if (workflowEmails) {
                var workflowEmail = workflowEmails.find(wfs => wfs.guid == this.state.step.stepOptions.workflowEmailGuid.value)
                if (workflowEmail) {
                    var campaign = workflowEmail.stepOptions.campaign;
                    axios.get('/template/' + campaign.TemplateId + '/links').then(response => {
                        var linkData = response.data.Links.map(l => { return { label: l, value: l } })
                        this.setState({ campaignLinks: linkData })
                    })
                }
            }
        }
    }

    async getAutomations() {
        const res = await axios.get('/automation?recordLimit=500')
        const automations = res.data.Automations.filter(a => a.id != this.props.automationId)
        this.setState({ automations })
    }

    updateNewAutomationName(ev) {
        this.setState({
            newAutomationName: ev.currentTarget.value
        })
    }

    createNewAutomation() {
        axios.post('/automation', {
            name: this.state.newAutomationName,
            version: 1,
            steps: [{
                guid: HelperFunctions.createLocalGuid(),
                complete: true,
                parentGuid: 0,
                type: "initial",
                stepOptions: { groupId: { value: '-1' } },
                triggerType: { required: true, value: "14" }
            }]
        }).then(response => {
            let step = this.props.step;
            step.stepOptions.goalEndAutomation.value = response.data.Automation.id
            this.props.addAutomation(response.data.Automation)
            this.setState({
                creatingNewAutomation: false
            })
        })
    }

    saveStep() {
        var errors = {};
        var error = false;
        var step = this.state.step;

        if (!step.stepOptions.name.value || step.stepOptions.name.value.length < 3) {
            error = true;
            errors.name = i18n.t('automation:add.goal.errName')
        }

        if (this.state.step.stepOptions.triggerType.value == "-1") {
            error = true;
            errors.triggerType = i18n.t('automation:add.goal.errType')
        }


        if (this.state.step.stepOptions.triggerType.value == "0" && this.state.step.stepOptions.workflowEmailGuid.value == "-1") {
            error = true;
            errors.workflowEmailGuid = i18n.t('automation:add.goal.errWorkflow')
        }

        if (this.state.step.stepOptions.triggerType.value == "1") {
            if (this.state.step.stepOptions.customFieldId.value == "-1") {
                error = true;
                errors.customFieldId = i18n.t('automation:add.goal.errField')
            }
            if (this.state.step.stepOptions.customFieldAction.value == "-1") {
                error = true;
                errors.customFieldAction = i18n.t('automation:add.goal.errAction')
            }
            if (step.stepOptions.customFieldValue.value === "" && step.stepOptions.customFieldAction.value != "is blank" && step.stepOptions.customFieldAction.value != "is not blank") {
                error = true;
                errors.customFieldValue = i18n.t('automation:add.wait.errValue')
            }
        }
        if (this.state.step.stepOptions.triggerType.value == "2") {
            if (this.state.step.stepOptions.segmentId.value == "-1") {
                error = true;
                errors.segmentId = i18n.t('automation:add.goal.errSegment')
            }
        }
        if (this.state.step.stepOptions.triggerType.value == "3") {
            if (this.state.step.stepOptions.campaignId.value == "-1") {
                error = true;
                errors.campaignId = i18n.t('automation:add.goal.errCampaign')
            }
            if (this.state.step.stepOptions.workflowEmailActionType.value == "-1") {
                error = true;
                errors.workflowEmailActionType = i18n.t('automation:add.goal.selectAnAction')
            }
        }

        if (step.stepOptions.triggerType.value == "7") {
            if (step.stepOptions.groupId.value == "-1") {
                error = true;
                errors.groupId = i18n.t('automation:add.wait.errGroup')
            }
            if (step.stepOptions.subscriberState.value == "") {
                error = true;
                errors.subscriberState = i18n.t('automation:add.wait.errSubscriberState')
            }
        }

        this.setState({ errors })

        if (!error || this.props.isThemeDesigner) {
            //finish
            step.complete = true;
            step.wizardComplete = true;
            this.props.saveStep()
        }

    }

    render() {
        const { readOnly } = this.props;

        if (this.state.drawerContent) return this.state.drawerContent;
        if (!this.state.step.stepOptions) return <div></div>

        var triggerTypes = [
            { label: i18n.t('automation:add.wait.trigger4'), value: "4" },
            { label: i18n.t('automation:add.wait.trigger0'), value: "0" },
            { label: i18n.t('automation:add.wait.trigger1'), value: "1" },
            { label: i18n.t('automation:add.wait.trigger2'), value: "2" },
            { label: i18n.t('automation:add.wait.trigger7'), value: "7" },
            { label: i18n.t('automation:add.wait.trigger3'), value: "3" },
            { label: i18n.t('automation:add.wait.trigger5'), value: "5" },
            { label: i18n.t('automation:add.wait.trigger6'), value: "6" },
            { label: "In Other Automations", value: "10" },
        ]

        if (this.state.webhookDefinitions.length) {
            triggerTypes.push(...this.state.webhookDefinitions.map(d => ({ label: d.label + ' Status', value: d.name })))
        }

        var emailOptions = [
            { label: i18n.t('automation:add.wait.email0'), value: "0" },
            { label: i18n.t('automation:add.wait.email1'), value: "1" },
            { label: i18n.t('automation:add.wait.email2'), value: "2" },
            { label: i18n.t('automation:add.wait.email3'), value: "3" },
            { label: i18n.t('automation:add.wait.email4'), value: "4" },
            { label: i18n.t('automation:add.wait.email5'), value: "5" },
            { label: i18n.t('automation:add.wait.email6'), value: "6" }
        ]

        var triggerPositionData = [{ value: "0", label: i18n.t('automation:add.action.thisPosition') }]


        var workflowEmails = AutomationFunctions.getWorkflowEmails(this.props.step.guid, this.props.steps);
        var customFieldActions = this.state.operators.TEXT;
        var customFieldInputType = "TEXT"
        var selectedCustomField;
        if (this.state.step.stepOptions.customFieldId.value && this.state.step.stepOptions.customFieldId.value != "-1") {
            selectedCustomField = this.props.customFields.find(fd => fd.value == this.state.step.stepOptions.customFieldId.value)
            if (selectedCustomField) {
                customFieldActions = this.state.operators[selectedCustomField.fieldType]
                customFieldInputType = selectedCustomField.fieldType;
            }
        }

        var theCustomFields = this.props.customFields;
        var okToFinish = false;
        if (this.state.step.stepOptions.triggerType.value == "0" && this.state.step.stepOptions.workflowEmailGuid.value != "-1") {
            okToFinish = true;
        }
        if (this.state.step.stepOptions.triggerType.value == "1" && this.state.step.stepOptions.customFieldId.value != "-1" && this.state.step.stepOptions.customFieldAction.value && this.state.step.stepOptions.customFieldValue.value !== "") {
            okToFinish = true;
        }
        if (this.state.step.stepOptions.triggerType.value == "2" && this.state.step.stepOptions.segmentId.value != "-1") {
            okToFinish = true;
        }
        if (this.state.step.stepOptions.triggerType.value == "3" && this.state.step.stepOptions.campaignId.value != "-1" && this.state.step.stepOptions.workflowEmailActionType.value) {
            okToFinish = true;
            if (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5") {
                if (!this.state.step.stepOptions.linkClicked?.value || this.state.step.stepOptions.linkClicked.length < 1) {
                    okToFinish = false;
                }
            }

        }
        if (this.state.step.stepOptions.triggerType.value == "4" || this.state.step.stepOptions.triggerType.value == "5") {
            okToFinish = true;
        }

        if (this.state.step.stepOptions.triggerType.value == "6") {
            okToFinish = true;
        }

        if (this.state.step.stepOptions.triggerType.value == "7" && this.state.step.stepOptions.groupId.value != "-1" && this.state.step.stepOptions.subscriberState.value != "") {
            okToFinish = true;
        }

        if (this.state.step.stepOptions.triggerType.value != "4") {
            triggerPositionData.push({ value: "1", label: i18n.t('automation:add.action.anywhere') })
        }

        if (this.state.step.stepOptions.triggerType.value == "10" && this.state.step.stepOptions.otherAutomations?.value?.length) {
            okToFinish = true;
        }

        if (isNaN(this.state.step.stepOptions.triggerType.value) && this.state.step.stepOptions.filters?.length) {
            okToFinish = true
        }

        var currentSegmentIsUser = false;
        if (this.state.step.stepOptions && this.state.step.stepOptions.segmentId && this.state.step.stepOptions.segmentId.value && this.state.step.stepOptions.segmentId.value > 0) {

            var currSeg = this.props.segments.find(s => s.value == this.state.step.stepOptions.segmentId.value)
            if (currSeg && !currSeg.readOnly && !currSeg.CapsuleId) currentSegmentIsUser = true;
        }

        let automations = this.props.automations.filter(a => a.id != this.props.automationId && a.trigger === "14").map(a => {
            return {
                label: a.name,
                value: a.id
            }
        })

        let webhookDefinition
        if (isNaN(this.state.step.stepOptions.triggerType.value)) {
            // find the integration its for
            webhookDefinition = this.state.webhookDefinitions.find(f => f.name === this.state.step.stepOptions.triggerType.value)
        }



        var tmpGroups = JSON.parse(JSON.stringify(this.props.groups))

        const inputProps = {
            disabled: readOnly
        };

        return <div data-test="drawer-goal-step">
            <div className="automationStepContentHolder">
                <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="mar-b25">{i18n.t('automation:add.goal.header')}</h4>
                    <HelpPopup type={"goal"} />
                </div>

                <div className="mar-b25">{i18n.t('automation:add.goal.subheader')}</div>

                <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('automation:add.goal.goalName')} type="text" name="name" onChange={this.updateValue} value={this.state.step.stepOptions.name.value} error={this.state.errors.name} {...inputProps} />

                <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.goal.goalTitle')} placeholder={i18n.t('automation:add.goal.selectGoal')} name="triggerType" data={triggerTypes} onChange={this.updateValue} value={this.state.step.stepOptions.triggerType.value} error={this.state.errors.triggerType} {...inputProps} />


                {(this.state.step.stepOptions.triggerType.value == "0" && !workflowEmails.length) && <div>
                    {this.state.errors.workflowEmailGuid && this.state.errors.workflowEmailGuid.length > 0 && <UILIB.WarningHolder className="mar-b25">{i18n.t('automation:add.goal.noEmailsAction')}</UILIB.WarningHolder>}

                    <UILIB.WarningHolder>
                        {i18n.t('automation:add.goal.noEmails')}
                    </UILIB.WarningHolder>
                </div>}
                {(this.state.step.stepOptions.triggerType.value == "0" && workflowEmails.length > 0) && <div>
                    <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.goal.campaignWhen')} name="workflowEmailGuid" placeholder={i18n.t('automation:add.goal.selectWorkflowEmail')} value={this.state.step.stepOptions.workflowEmailGuid.value} data={workflowEmails.map(wfe => { return { label: wfe.stepOptions.subject.value, value: wfe.guid } })} onChange={this.updateValue} error={this.state.errors.workflowEmailGuid} {...inputProps} />

                    {this.state.step.stepOptions.workflowEmailGuid.value != "-1" && <div >
                        <UILIB.Select outerClassName="mar-b25" data={emailOptions} name="workflowEmailActionType" onChange={this.updateValue} value={this.state.step.stepOptions.workflowEmailActionType.value} {...inputProps} />
                    </div>}
                    {this.state.step.stepOptions.workflowEmailGuid.value != "-1" && (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5") && <div>
                        {!this.state.campaignLinks.length && <div></div>}
                        {this.state.campaignLinks.length > 0 && <div>
                            <UILIB.Select outerClassName="mar-b25" name="linkClicked" placeholder={i18n.t('automation:add.wait.selectValue')} data={this.state.campaignLinks} onChange={this.updateValue} value={this.state.step.stepOptions.linkClicked.value} error={this.state.errors.linkClicked} {...inputProps} />
                        </div>}
                    </div>}
                </div>}

                {this.state.step.stepOptions.triggerType.value == "1" && theCustomFields.length < 1 && !this.props.isThemeDesigner && <UILIB.WarningHolder>
                    {i18n.t('automation:add.action.noFields')}<button className="a" onClick={this.createField} {...inputProps}>{i18n.t('automation:add.action.noFields')}</button>
                </UILIB.WarningHolder>}
                {this.state.step.stepOptions.triggerType.value == "1" && theCustomFields.length > 0 && !this.props.isThemeDesigner && <div>


                    <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.goal.fieldWhen')} labelOptions={<button className="a" onClick={this.createField} {...inputProps}>{i18n.t('automation:add.action.createField')}</button>} placeholder="Select a custom field" data={theCustomFields} name="customFieldId" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldId.value} error={this.state.errors.customFieldId} {...inputProps} />

                    {this.state.step.stepOptions.customFieldId.value != "-1" && <div>

                        <UILIB.Select outerClassName="mar-b25" data={customFieldActions} name="customFieldAction" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldAction.value} error={this.state.errors.customFieldAction} />

                        {this.state.step.stepOptions.customFieldAction.value != "is blank" && this.state.step.stepOptions.customFieldAction.value != "is not blank" && <div>

                            {customFieldInputType == "TEXT" && <UILIB.TextInput outerClassName="mar-b25" type="text" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {customFieldInputType == "NUMBER" || (customFieldInputType == "DATE" && this.state.step.stepOptions.customFieldAction.value.indexOf("be") > -1) && <UILIB.TextInput outerClassName="mar-b25" type="number" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {customFieldInputType == "DATE" && (this.state.step.stepOptions.customFieldAction.value.indexOf("be") < 0) && <UILIB.TextInput outerClassName="mar-b25" type="date" name="customFieldValue" onChange={(event, newVal) => { this.updateDateValue("customFieldValue", newVal) }} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {customFieldInputType == "BOOLEAN" && <UILIB.Select outerClassName="mar-b25" name="customFieldValue" placeholder={i18n.t('automation:add.wait.selectValue')} data={[{ 'label': i18n.t('true'), 'value': true }, { 'label': i18n.t('false'), 'value': false }]} onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {(customFieldInputType == "SELECT" || customFieldInputType == 'MULTISELECT') && <UILIB.Select outerClassName="mar-b25" name="customFieldValue" placeholder={i18n.t('automation:add.wait.selectValue')} data={selectedCustomField.options ? selectedCustomField.options : []} onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {customFieldInputType == "TELNO" && <UILIB.TextInput outerClassName="mar-b25" type="TELNO" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                        </div>}
                    </div>
                    }

                </div>}

                {this.state.step.stepOptions.triggerType.value == "2" && !this.props.isThemeDesigner && <div>
                    {!this.props.segments.length &&
                        <UILIB.WarningHolder>
                            <div><strong>{i18n.t('automation:add.wait.noSegments')}</strong></div>
                            <button className="a" onClick={this.createSegment} {...inputProps}>{i18n.t('automation:add.trigger4.createSegment')}</button>
                        </UILIB.WarningHolder>}
                    {this.props.segments.length > 0 &&
                        <div className="mar-b25">
                            <UILIB.Select outerClassName="mar-b5" label={i18n.t('automation:add.wait.selectSegment')} labelOptions={<button className="a" onClick={this.createSegment} {...inputProps}>{i18n.t('automation:add.wait.createSegment')}</button>} placeholder={i18n.t('automation:add.wait.selectSegment')} name="segmentId" value={this.state.step.stepOptions.segmentId.value} data={this.props.segments} onChange={this.updateValue} error={this.state.errors.segmentId} {...inputProps} />
                            {(currentSegmentIsUser == true) && <div><label><button className="a" onClick={this.viewEditSegment} {...inputProps}>View / Edit this Segment</button></label></div>}
                        </div>}
                </div>
                }

                {this.state.step.stepOptions.triggerType.value == "3" && !this.props.isThemeDesigner && <div>
                    {!this.props.campaigns.length &&
                        <UILIB.WarningHolder>
                            <div><strong>{i18n.t('automation:add.wait.noCampaigns')}</strong></div>
                        </UILIB.WarningHolder>}
                    {this.props.campaigns.length > 0 &&
                        <div>


                            <CampaignSelector
                                label={i18n.t('automation:add.wait.selectCampaign')}
                                outerClassName="mar-b25"
                                placeholder={i18n.t('automation:add.wait.selectCampaign')}
                                name="campaignId"
                                value={this.state.step.stepOptions.campaignId.value}
                                allowAnyCampaign={0}
                                onChange={this.updateValue}
                                error={this.state.errors.campaignId} {...inputProps}
                            />

                            {this.state.step.stepOptions.campaignId.value != "-1" && <div>
                                <UILIB.Select outerClassName="mar-b25" data={emailOptions} name="workflowEmailActionType" onChange={this.updateValue} value={this.state.step.stepOptions.workflowEmailActionType.value} {...inputProps} />
                            </div>}
                            {this.state.step.stepOptions.campaignId.value != "-1" && (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5") && <div>
                                {!this.state.campaignLinks.length && this.state.step.stepOptions?.campaignId?.value === 0 && <div>
                                    <UILIB.TextInput label="URL" placeholder="https://website.com/page" type="url" outerClassName="mar-b25" name="linkClicked" onChange={this.updateValue} value={this.state.step.stepOptions.linkClicked.value} error={this.state.errors.linkClicked} />
                                </div>}
                                {this.state.campaignLinks.length > 0 && <div>
                                    <UILIB.Select outerClassName="mar-b25" name="linkClicked" placeholder={i18n.t('automation:add.wait.selectLink')} data={this.state.campaignLinks} onChange={this.updateValue} value={this.state.step.stepOptions.linkClicked.value} error={this.state.errors.linkClicked} {...inputProps} />
                                </div>}
                            </div>}
                        </div>}
                </div>
                }

                {this.state.step.stepOptions.triggerType.value == "6" && <div>

                    <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.condition.scoreIs')} name="scoreOperator" value={this.state.step.stepOptions.scoreOperator.value} onChange={this.updateValue} data={[
                        { label: 'Greater Then', value: 'gt' },
                        { label: 'Less Then', value: 'lt' },
                        { label: 'Equals', value: 'eq' }
                    ]}
                        {...inputProps}
                    />

                    <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('automation:add.condition.scoreScore')} name="scoreValue" value={this.state.step.stepOptions.scoreValue.value} onChange={this.updateValue} {...inputProps} />

                </div>}

                {this.state.step.stepOptions.triggerType.value == "7" && <div>


                    {!tmpGroups && <UILIB.WarningHolder>
                        <div><strong>{i18n.t('automation:add.initial.noGroups')}</strong></div>
                    </UILIB.WarningHolder>}

                    {tmpGroups.length > 0 && <div>

                        <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.initial.selectGroup')} placeholder={i18n.t('automation:add.initial.selectGroupPlaceholder')} name="groupId" value={this.state.step.stepOptions.groupId.value} data={tmpGroups} onChange={this.updateValue} error={this.state.errors.groupId} {...inputProps} />

                        {this.state.step.stepOptions.groupId.value && <div>

                            <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.initial.selectState')} placeholder={i18n.t('automation:add.initial.selectStatePlaceholder')} name="subscriberState" value={this.state.step.stepOptions.subscriberState ? this.state.step.stepOptions.subscriberState.value : ''} data={[{ label: i18n.t('automation:add.initial.active'), value: "active" }, { label: i18n.t('automation:add.initial.inactive'), value: "inactive" }]} onChange={this.updateValue} error={this.state.errors.subscriberState} {...inputProps} />

                        </div>}
                    </div>}

                </div>}

                {this.state.step.stepOptions.triggerType.value == "10" && <div>
                    {!this.state.automations && <UILIB.LoadingIcons />}
                    {!!this.state.automations && <div className="mar-b20">
                        {!this.state.automations.length && <UILIB.WarningHolder>No Other Automations Found</UILIB.WarningHolder>}
                        {!!this.state.automations.length && <UILIB.Select name="otherAutomations" onChange={this.updateValue} data={this.state.automations.map(a => ({ label: a.name, value: a.id }))} value={this.state.step.stepOptions.otherAutomations?.value} multiple={true} label="Automations" />}
                    </div>}
                </div>}

                {!!webhookDefinition && !this.props.isThemeDesigner && <div>
                    <TriggerFilters refresh={() => this.setState({})} filters={this.state.step.stepOptions.filters || []} trigger={webhookDefinition.name} appId={webhookDefinition.ApplicationId} />
                </div>}

                {okToFinish && <div>

                    <UILIB.Select label={i18n.t('automation:add.action.then')} outerClassName="mar-b25" name="goalEndAction" data={[
                        { label: i18n.t('automation:add.action.end'), "value": "0" },
                        { label: i18n.t('automation:add.action.rejoin'), "value": "1" },
                        { label: i18n.t('automation:add.action.anotherAutomation'), "value": "2" },
                    ]} onChange={this.updateValue} value={this.state.step.stepOptions.goalEndAction.value} error={this.state.errors.goalEndAction} {...inputProps} />

                    {this.state.step.stepOptions.goalEndAction.value === "2" && <div>

                        {!this.state.creatingNewAutomation && <div className="mar-b25">
                            <UILIB.Select label={i18n.t('automation:add.action.selectAutomation')} outerClassName="mar-b5" name="goalEndAutomation" data={automations} focus onChange={this.updateValue} placeholder={i18n.t('automation:add.action.selectAutomation')} value={this.state.step.stepOptions.goalEndAutomation.value} error={this.state.errors.goalEndAutomation} {...inputProps} />
                            or <button className="a" onClick={() => this.setState({ creatingNewAutomation: true })} {...inputProps}>{i18n.t('automation:add.goal.createNewAutomation')}</button>
                        </div>}
                        {this.state.creatingNewAutomation && <UILIB.Paper>
                            <div className="mar-b25 text-heavy">{i18n.t('automation:add.goal.createNewAutomation')}</div>
                            <UILIB.TextInput outerClassName="mar-b25" value={this.state.newAutomationName} placeholder={i18n.t('automation:add.goal.newAutomationName')} onChange={this.updateNewAutomationName} {...inputProps} />
                            <div className="quickFlex">
                                <UILIB.Button className="button-primary mar-r10" text={i18n.t('create')} disabled={!this.state.newAutomationName} onClick={this.createNewAutomation} {...inputProps} />
                                <UILIB.Button text={i18n.t('cancel')} onClick={() => this.setState({ creatingNewAutomation: false })} {...inputProps} />
                            </div>
                        </UILIB.Paper>}
                        <UILIB.WarningHolder className="mar-b25">{i18n.t('automation:add.goal.selectAutomationInfo', { quote: i18n.t('automation:add.initial.anotherAutomation') })}</UILIB.WarningHolder>
                        <UILIB.Select
                            outerClassName="mar-b25"
                            value={this.state.step.stepOptions.goalMoveDelete?.value}
                            label="Should contacts be able to rejoin this Automation?"
                            onChange={this.updateValue}
                            name="goalMoveDelete"
                            data={[
                                { label: 'Do not allow contacts to rejoin this Automation', value: '0' },
                                { label: 'Allow contacts to rejoin this Automation', value: '1' }
                            ]}
                        />
                    </div>}

                    <UILIB.Select label={i18n.t('automation:add.action.for')} name="triggerPosition" data={triggerPositionData} onChange={this.updateValue} value={this.state.step.stepOptions.triggerPosition.value} error={this.state.errors.triggerPosition} {...inputProps} />

                </div>
                }
            </div>

            <div className="automationStepSaveButtonHolder">
                {readOnly ? (
                    <>
                        <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                    </>
                ) : (
                    <UILIB.Button className="button-primary" text={i18n.t('save')} onClick={this.saveStep} />
                )}
            </div>
        </div>
    }
}

export default DrawerGoalStep;
import React from 'react'
export default class TableSortLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { animationClass: "textIconDown", currentDirectionCSS: null }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.open != this.props.open) {

            var directionClass = "";
            var currentDirectionCSS = "";

            currentDirectionCSS = null;
            if (this.props.open === true) {
                directionClass = "textIconUp";

            }
            if (this.props.open === false) {
                directionClass = "textIconDown";
            }

            this.setState({ animationClass: directionClass, currentDirectionCSS: currentDirectionCSS })
        }
        return false;
    }


    render() {

        var classes = "textSelector ";
        classes += this.props.className;

        var imageClasses = "textIcon icon-chevron-down " + this.state.animationClass;
        if (this.props.className) {
            imageClasses += " " + this.props.className;
        }

        return <div onClick={this.props.onClick} className={classes}>
            {this.props.text}
            <div className={imageClasses} />
        </div>


    }
}

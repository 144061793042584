import React from 'react'

export default function IconsFacebookColorRounded({ color = "", style = {} }) {
    let className = "icons-icon";
    if (!style.borderRadius) style.borderRadius = "6px";
    return (

        <svg className={className} style={style} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_700_22594)">
                <rect width="24" height="24" fill={color} />
                <path d="M16.6696 15.4697L17.2017 12.001H13.8735V9.74997C13.8735 8.80098 14.3384 7.87598 15.8291 7.87598H17.3423V4.92285C17.3423 4.92285 15.969 4.68848 14.6561 4.68848C11.9151 4.68848 10.1235 6.34973 10.1235 9.35723V12.001H7.07666V15.4697H10.1235V25.1885C10.7345 25.2844 11.3607 24.001 11.9985 24.001C12.6364 24.001 13.2626 25.2844 13.8735 25.1885V15.4697H16.6696Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_700_22594">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
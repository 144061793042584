import React from 'react'
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import copy from 'copy-to-clipboard';

@connect((store) => {
    return { user: store.user }
})
export default class ShareTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alertOpen: "",
            alertMessage: ""
        };

        this.copyClipboard = this.copyClipboard.bind(this);
    }

    componentDidMount() {

    }

    copyClipboard(value) {
        copy(value);
        this.setState({ alertOpen: true, alertMessage: i18n.t('copiedToClipBoard') })
    }

    render() {

        return <div>
            <h4 className="mar-b15">{i18n.t('templates:index.shareHeader')}</h4>
            <div className="mar-b25">{i18n.t('templates:index.shareSubHeader')}</div>

            <UILIB.TextInput
                label={i18n.t('templates:index.theCode')}
                value={this.props.template.code}
                iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => { this.copyClipboard(this.props.template.code) }} />}
                explainer={i18n.t('templates:index.theCodeExplainer')}

            />
            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
        </div >
    }
};


import React, { useState, useEffect } from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import helpers from '~/Classes/helpers'
import numberFormatFunctions from '~/Classes/numberFormatFunctions'
import HelpPopup from '../helpPopup/helpPopup';

export default function DrawerSmsStep({ step, automationData, saveStep = () => { }, isThemeDesigner = false }) {
    const [campaign, setCampaign] = useState(step.stepOptions.campaign)
    const [otherCampaigns, setOtherCampaigns] = useState([])
    const [customFields, setCustomFields] = useState([])
    const [template, setTemplate] = useState(null)
    const [options, setOptions] = useState(step.stepOptions)
    const [errors, setErrors] = useState({})
    const [saving, setSaving] = useState(false)

    const saveCampaign = async (obj) => {
        const saveObj = {
            ...campaign,
            ...obj
        }
        setCampaign(saveObj)
        await axios.put('/campaign/' + options.campaign.id || campaign.id, { campaign: saveObj })
    }

    const saveTemplate = async () => {
        await axios.put('/template/' + step.stepOptions.campaign.TemplateId, template)
    }

    const createCampaign = async () => {
        const response = await axios.post('/campaign', {
            campaignName: 'Automation ' + automationData.id + ' Step ' + step.guid,
            type: 6,
            startDate: new Date(),
            subject: '',
            options: {},
            AutomationId: automationData.id
        })
        step.stepOptions.campaign = response.data.Campaign
        setOptions({ ...options, campaign: response.data.Campaign })
        setCampaign(response.data.Campaign)
        return response.data.Campaign
    }

    const createFromScratch = async () => {
        let newCampaign = campaign
        if (!campaign.id) {
            newCampaign = await createCampaign()
        }
        // create a blank template and bind it to the campaign
        const result = await axios.post('/template', {
            CampaignId: campaign.id,
            public: true,
            templateHTML: 'to opt out visit {{__OPTOUT}}',
            templateName: 'Sms Template',
            templateType: 6
        })
        newCampaign.TemplateId = result.data.Template.templateID
        newCampaign.subject = newCampaign.subject || ''
        newCampaign.options.smsField = newCampaign.options.smsField
        await saveCampaign(newCampaign)
        step.stepOptions.subject.value = newCampaign.subject
        step.stepOptions.customFieldId.value = newCampaign.options.smsField
        setOptions({ ...step.stepOptions })
        setTemplate(result.data.Template)
    }

    const closeStep = async () => {
        setSaving(true)
        const errors = {}
        const contentErrors = []
        if (!options.customFieldId.value || options.customFieldId.value === "-1") {
            errors.smsField = 'Required'
        }
        if (!template.templateHTML) {
            contentErrors.push('Content cannot be blank')
        }
        if (!options.subject.value) {
            errors.subject = 'Required'
        } else {
            const [subjectResponse, contentResponse] = await Promise.all([
                axios.get('/campaign/checksms?content=' + options.subject.value),
                axios.get('/campaign/checksms?content=' + template.templateHTML)
            ])

            if (template.templateHTML && template.templateHTML.indexOf('{{__OPTOUT}}') === -1) {
                contentErrors.push('Content must contain {{__OPTOUT}} so people can unsubscribe from your messages')
            }

            if (subjectResponse.data.length) {
                errors.subject = `From Name cannot contain ${subjectResponse.data.map(d => `"${d}"`).join(' or ')}. `
            }
            if (contentResponse.data.length) {
                contentErrors.push(`Content cannot contain ${contentResponse.data.map(d => `"${d}"`).join(', ')}`)
            }
        }
        if (contentErrors.length) {
            errors.content = contentErrors
        }
        setErrors(errors)
        if (Object.keys(errors).length) {
            setSaving(false)
            return
        }

        step.stepOptions.campaign = campaign
        step.complete = true
        await saveCampaign()
        await saveTemplate()
        step.stepOptions.refresh = new Date()
        saveStep()
    }

    const updateCampaignField = (e, option) => {
        if (e.currentTarget.name === 'subject') {
            step.stepOptions.subject.value = e.currentTarget.value
            setOptions({ ...step.stepOptions })
        }
        if (option) {
            const options = campaign.options || {}
            setCampaign({ ...campaign, options: { ...options, [e.currentTarget.name]: e.currentTarget.value } })
        } else {
            setCampaign({ ...campaign, [e.currentTarget.name]: e.currentTarget.value })
        }
    }

    const updateTemplateField = e => {
        setTemplate({
            ...template,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }

    const updateStepOptions = e => {
        let value = e.currentTarget.value
        if (e.currentTarget.name === 'customFieldId') {
            value = parseInt(value)
            const options = campaign.options || {}
            setCampaign({ ...campaign, options: { ...options, smsField: value } })
        }
        step.stepOptions[e.currentTarget.name].value = value
        setOptions({ ...step.stepOptions })
    }

    const toggleTrackLinks = () => {
        const options = campaign.options || {}
        setCampaign({ ...campaign, options: { ...options, doNotTrackLinks: !options.doNotTrackLinks } })
    }

    useEffect(() => {
        axios.get('/campaign?type=6&sortOrder=desc&hideStats=true').then(res => {
            setOtherCampaigns(
                Array.from(new Set(res.data.Campaigns.map(c => c.subject).filter(c => c && helpers.smsIsValidSubject(c).valid)))
            )
        })

        axios.get('/customfield').then(res => setCustomFields(res.data.CustomFields.filter(cf => cf.fieldType === 'TELNO')))

        if (step.stepOptions.campaign.TemplateId) {
            axios.get('/template/' + step.stepOptions.campaign.TemplateId).then(res => setTemplate(res.data.template))
        }

        if (step.stepOptions.campaign.id) {
            axios.get('/campaign/' + step.stepOptions.campaign.id).then(res => setCampaign(res.data.Campaign))
        } else {
            createFromScratch()
        }
    }, [])

    if (!campaign || !template) return <UILIB.LoadingIcons iconType="2" />

    let result
    if (template && campaign) {
        result = helpers.getSmsCredits(campaign, template)
    }
    const campaignOptions = campaign.options || {}
    return (
        <div>
            <div className="automationStepContentHolder">

                <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="mar-b25">SMS Field</h4>
                    <HelpPopup type={"sms"} />
                </div>
                <div className="mar-b25">The custom field the phone number is held on</div>

                <UILIB.Select outerClassName="mar-b25" error={errors.smsField} data={customFields.map(c => ({ label: c.fieldDesc, value: c.id }))} placeholder="Select Field" value={options.customFieldId.value} name="customFieldId" onChange={updateStepOptions} />
                <UILIB.TextInput outerClassName="mar-b25" label="From Name" explainer="Must be 3-11 characters long and have no spaces" error={errors.subject} value={campaign.subject} name="subject" onChange={updateCampaignField} suggestions={otherCampaigns} />

                {Boolean(template) && <div>
                    <UILIB.TextArea outerClassName="mar-b25" label="Content" explainer="160 characters limit for 1 credit, subsequent messages have a 153 character limit" error={errors.content} value={template.templateHTML} name="templateHTML" onChange={updateTemplateField} rows="8" />
                </div>}
                {Boolean(result) && <div className="smsInfo mar-b25">
                    <div className="txtInput_label">Content</div>
                    <div className="text-heavy">{numberFormatFunctions.formatNumber(result.html.length)} characters (max 1,280)</div>
                    <div className="text-right">{result.messages > 1 ? `${result.messages} credits per send` : '1 credit per send'}</div>
                </div>}

                <div className="mar-b20">
                    <UILIB.Toggle after="Track Links" checked={!campaignOptions.doNotTrackLinks} onChange={toggleTrackLinks} />
                </div>
            </div>
            <div className="automationStepSaveButtonHolder">
                <UILIB.Button className="button-primary" text="Save" disabled={saving} onClick={closeStep} />
            </div>
        </div>
    )

}
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import DBConnection from '~/Classes/siteVars';
import axios from 'axios';
import queryString from 'query-string';
import MPZBlackLogo from '~/assets/images/mpzLogos/mpzmail-logo-black.svg';
import { connect } from 'react-redux';

//CONNECT TO STORE
@connect((store) => {
    return { siteMaster: store.siteMaster }
})

export default class GDPRTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.updateGDPRFields = this.updateGDPRFields.bind(this)

        this.state = {
            message: '',
            emailAddress: "",
            action: undefined
        };

    }

    componentDidMount() {

        const query = queryString.parse(this.props.location.search);
        var key = query.key;
        var action = query.action;

        this.setState({
            key,
            action
        }, this.updateGDPRFields)
    }

    getSubscriber() {

    }

    updateGDPRFields() {
        var baseUrl = DBConnection.getConnString()
        axios.get(baseUrl + `/gdpr?key=${this.state.key}&action=${this.state.action}`)
            .then(response => {
            });
    }

    render() {

        if (this.state.action === "yes") {
            return <div className="container-fluid" style={{ marginTop: 32 }}>
                <UILIB.Row>
                    <UILIB.Column xs={12} md={12} lg={6} className="col-lg-offset-3">
                        <UILIB.Paper>
                            <div className="title">
                                You're In!
                            </div>

                            Thanks so much for opting to continue to receive our mails.
                            <br /><br />
                            You can change your preference at any time by clicking the "View Profile" link at the bottom of any of our future emails.

                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
                {this.props.siteMaster.siteLogo && this.props.siteMaster.siteLogo.length > 0 &&
                    <UILIB.Row>
                        <UILIB.Column xs={12} md={12} lg={6} className="col-lg-offset-3 text-center">
                            <div style={{ fontSize: "14px", color: "#AAAAAA", marginBottom: "10px" }}>Powered by</div>
                            {this.props.siteMaster.siteId === 1 &&
                                <div><a href="/"><img src={MPZBlackLogo} style={{ maxWidth: "100px", border: "0" }} /></a></div>
                            }
                            {!this.props.siteMaster.siteId === 1 &&
                                <div><a href="/"><img src={this.props.siteMaster.siteLogo} style={{ maxWidth: "100px", border: "0" }} /></a></div>
                            }
                            <div style={{ fontSize: "12px", color: "#AAAAAA" }}>Email Marketing</div>

                        </UILIB.Column>
                    </UILIB.Row>
                }
            </div>
        }
        if (this.state.action === "no") {
            return <div className="container-fluid" style={{ marginTop: 32 }}>
                <UILIB.Row>
                    <UILIB.Column xs={12} md={12} lg={6} className="col-lg-offset-3">
                        <UILIB.Paper>
                            <div className="title">
                                You're Out!
                            </div>

                            We've unsubscribed you from our mailing lists and we won't send you any of our future mail shots.
                            <br /><br />
                            You can change your preference at any time by click the "View Profile" link at the bottom of any emails we've sent you.
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
                {this.props.siteMaster.siteLogo && this.props.siteMaster.siteLogo.length > 0 &&
                    <UILIB.Row>
                        <UILIB.Column xs={12} md={12} lg={6} className="col-lg-offset-3 text-center">
                            <div style={{ fontSize: "14px", color: "#AAAAAA", marginBottom: "10px" }}>Powered by</div>
                            {this.props.siteMaster.siteId === 1 &&
                                <div><a href="/"><img src={MPZBlackLogo} style={{ maxWidth: "100px", border: "0" }} /></a></div>
                            }
                            {!this.props.siteMaster.siteId === 1 &&
                                <div><a href="/"><img src={this.props.siteMaster.siteLogo} style={{ maxWidth: "100px", border: "0" }} /></a></div>
                            }
                            <div style={{ fontSize: "12px", color: "#AAAAAA" }}>Email Marketing</div>

                        </UILIB.Column>
                    </UILIB.Row>
                }
            </div>
        }
        return <div></div>
    }
}

import React, { Component } from 'react';
import axios from '~/data/http/axios';
import SiteVars from '~/Classes/siteVars';
import UILIB from '~/Common-Objects/Lib'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';

@connect((store) => {
    return { siteMaster: store.siteMaster }
})

class AddServer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            serverType: 0
        }
        this.continue = this.continue.bind(this);
    }

    componentWillMount() {
    }

    continue() {
        if (this.state.serverType == 0) {
            this.props.addManualServer();
        }
        if (this.state.serverType == 1) {
            this.props.addHostedServer();
        }
    }

    render() {

        return (
            <div>
                <h4 className="mar-b25">Add a new Server</h4>
                <div className="mar-b25">Add your own mail server, or purchase an automatically created server hosted by {this.props.siteMaster.siteName}</div>

                <UILIB.Select outerClassName="mar-b25" label="Server Type" data={[{ label: "Add your own server details", value: 0 }, { label: "Purchase a Server from Transpond", value: 1 }]} value={this.state.serverType} onChange={(event) => { this.setState({ serverType: event.target.value }) }} />
                <UILIB.Button text="Continue" onClick={this.continue} />

            </div >
        );
    }
}

export default AddServer;
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';
import axios from '~/data/http/axios';
@connect((store) => {
    return { accountMaster: store.accountMaster, permissionStore: store.permission, siteMaster: store.siteMaster }
})
class unsubOptionsDrawer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rnd: -1,
            options: {},
            pageLoaded: false
        }
        this.changeValue = this.changeValue.bind(this);
        this.saveValues = this.saveValues.bind(this);
        this.changeText = this.changeText.bind(this);
        this.getOptions = this.getOptions.bind(this);
    }

    componentDidMount() {
        setTimeout(this.getOptions, 1000);
    }
    async getOptions() {
        let options
        let group = {};
        if (this.props.group) {
            group = await axios.get('/group/' + this.props.group.id + '?skipCount=true')
            options = JSON.parse(JSON.stringify(group.data.Group.options || {}));
        } else {
            let account = await axios.get("/accountmaster")
            options = account.data.AccountMaster.options;
        }
        this.setState({ options, pageLoaded: true })
    }


    changeValue(event) {
        let options = this.state.options
        options[event.target.name] = event.target.checked;
        this.setState({ options })
    }
    changeText(event) {
        let options = this.state.options
        options[event.target.name] = event.target.value;
        this.setState({ options })
    }

    saveValues() {
        this.setState({ pageLoaded: false })
        if (this.props.group) {
            axios.put('/group/' + this.props.group.id + '/options', this.state.options).then((res) => {
                this.props.closeDrawer()
            }).catch(error => {
                this.props.closeDrawer()
            });
        } else {
            this.props.accountMaster.accountMaster.options = this.state.options;
            axios.put('/accountMaster', (this.props.accountMaster.accountMaster)).then((res) => {
                this.props.closeDrawer()
            }).catch(error => {
                this.props.closeDrawer()
            });
        }
    }
    render() {
        if (!this.state.pageLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>
        var thisOpts = this.state.options
        var canChooseGroups = true;
        var fixedGroup = false;
        var unsubscribedText = "You've been Unsubscribed"
        var alreadyUnsubscribedText = "You are already Unsubscribed"
        var unsubButtonText = "Unsubscribe";
        var unsubFromAllText = "unsubscribe from all";

        if (thisOpts && thisOpts.hasOwnProperty("unsubCanChooseGroups")) {
            canChooseGroups = thisOpts.unsubCanChooseGroups
        }
        if (thisOpts && thisOpts.hasOwnProperty("unsubFixedGroup")) {
            fixedGroup = thisOpts.unsubFixedGroup
        }

        if (thisOpts && thisOpts.hasOwnProperty("unsubButtonText")) {
            unsubButtonText = thisOpts.unsubButtonText
        }
        if (thisOpts && thisOpts.hasOwnProperty("unsubFromAllText")) {
            unsubFromAllText = thisOpts.unsubFromAllText
        }

        if (thisOpts && thisOpts.hasOwnProperty("unsubscribedText")) {
            unsubscribedText = thisOpts.unsubscribedText
        }
        if (thisOpts && thisOpts.hasOwnProperty("alreadyUnsubscribedText")) {
            alreadyUnsubscribedText = thisOpts.alreadyUnsubscribedText
        }

        return <div>
            <h4 className="mar-b25">{i18n.t('company:landing.unsubOptions.title')}</h4>
            <p>{i18n.t('company:landing.unsubOptions.subTitle')}</p>


            <UILIB.Toggle className="mar-b25" name="unsubCanChooseGroups" label={i18n.t('company:landing.unsubOptions.subscribersCanChooseGroups')} value={canChooseGroups} onChange={this.changeValue} />
            <UILIB.Toggle className="mar-b25" name="unsubFixedGroup" label={i18n.t('company:landing.unsubOptions.unsubFixedGroup')} value={fixedGroup} onChange={this.changeValue} />

            <UILIB.TextInput className="mar-b25" label={i18n.t('company:landing.unsubOptions.unsubButtonText')} placeholder={i18n.t('company:landing.unsubOptions.unsubButtonTextPlace')} name="unsubButtonText" value={unsubButtonText} onChange={this.changeText} />
            <UILIB.TextInput className="mar-b25" label={i18n.t('company:landing.unsubOptions.unsubFromAllText')} placeholder={i18n.t('company:landing.unsubOptions.unsubFromAllTextPlace')} name="unsubFromAllText" value={unsubFromAllText} onChange={this.changeText} />
            <UILIB.TextArea className="mar-b25" label={i18n.t('company:landing.unsubOptions.unsubscribeText')} placeholder={i18n.t('company:landing.unsubOptions.unsubscribedText')} name="unsubscribedText" value={unsubscribedText} onChange={this.changeText} />
            <UILIB.TextArea className="mar-b25" label={i18n.t('company:landing.unsubOptions.alreadyUnsubscribedText')} placeholder={i18n.t('company:landing.unsubOptions.alreadyUnsubscribedTextPlace')} name="alreadyUnsubscribedText" value={alreadyUnsubscribedText} onChange={this.changeText} />

            <UILIB.Button iconRight={<UILIB.Icons icon="tick" />} className="button-primary" text={i18n.t('translations:save')} onClick={this.saveValues} />

        </div>
    }
}

export default unsubOptionsDrawer;
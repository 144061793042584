import axios from '~/data/http/axios'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import UILIB from '~/Common-Objects/Lib';

@connect((store) => {
    return { site: store.siteMaster }
})

export default class FieldSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: [],
            selected: [],
            filter: '',
            loaded: false,
            exporting: false
        }

        this.all = this.all.bind(this)
        this.toggle = this.toggle.bind(this)
        this.goExport = this.goExport.bind(this);
    }

    componentDidMount() {
        this._ismounted = true;
        axios.get('/customfield?orderBy=fieldName').then(response => {
            this.setState({
                loaded: true,
                fields: response.data.CustomFields,
                selected: response.data.CustomFields.map(f => f.id)
            })
        })
    }

    componentWillUnmount() {
        console.log(this.timer)
        if (this.timer) clearTimeout(this.timer)
        this._ismounted = false;
    }

    all(clear) {
        if (clear) {
            this.setState({
                selected: []
            })
        } else {
            this.setState({
                selected: this.state.fields.map(f => f.id)
            })
        }
    }

    toggle(ev) {
        let id = parseInt(ev.currentTarget.value)
        let selected = this.state.selected
        let index = selected.indexOf(id)
        if (index > -1) selected.splice(index, 1)
        else selected.push(id)
        this.setState({ selected })
    }

    async goExport() {
        let self = this;
        this.setState({
            exporting: true
        })

        async function checkExport(id) {
            console.log("EXPORT CHECK")
            if (!self._ismounted) return

            let exportJob = await axios.get('/campaign/' + self.props.campaignId + '/export/' + id + '?startDate=' + self.props.startDate.format('YYYY-MM-DD') + '&endDate=' + self.props.endDate.format('YYYY-MM-DD'))
            exportJob = exportJob.data.ExportJob;
            if (exportJob.finished) {
                if (exportJob.location) {
                    let exportJobRes = await axios.get('/campaign/' + self.props.campaignId + '/export/' + id + '/file')
                    const link = document.createElement('a');
                    link.href = exportJobRes.data.url
                    document.body.appendChild(link);
                    link.click();
                }
                self.props.finished();
            }
            else {
                self.timer = setTimeout(() => { checkExport(id) }, 500)
            }
        }
        let requestUrl = `/campaign/${this.props.campaignId}/export?filter=${this.props.filter}&startDate=${this.props.startDate.format('YYYY-MM-DD')}&endDate=${this.props.endDate.format('YYYY-MM-DD')}`
        if (this.props.showBots) {
            requestUrl += '&includeBots=' + self.props.showBots
        }
        let response = await axios.put(requestUrl, {
            fields: this.state.selected
        })

        checkExport(response.data.ExportJob.id)

    }

    render() {
        if (!this.state.loaded || this.state.exporting) return <UILIB.LoadingIcons iconType="2" />
        return (
            <div>
                <h4 className="mar-b25">Select Contact Fields to Include</h4>

                <UILIB.Select
                    outerClassName="mar-b25"
                    filter={true} dropdownStyle={{ maxWidth: '100%' }} selectAll={() => this.all()} headerText="Select Fields" clearAll={() => this.all(true)} updateFilter={filter => this.setState({ filter })}
                    multiple={true} data={this.state.fields.map(f => ({ label: f.fieldDesc, value: f.id }))} value={this.state.selected} onChange={this.toggle}
                />

                <div className="mar-b25">{this.state.selected.length === this.state.fields.length ? 'All' : this.state.selected.length} Fields Selected</div>

                <div className="quickFlex">
                    <UILIB.Button text="Select" className="button-primary mar-r10" onClick={this.goExport} />
                    <UILIB.Button text="Cancel" onClick={() => this.props.dispatch(siteMaster.alterSiteDrawer(false))} />
                </div>
            </div>
        )
    }
}

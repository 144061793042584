import React from 'react'
import allowedFonts from '../../../../../Classes/fonts';

// colours = [background, title, link, button]
export default function ExampleTemplate3({ colours, logo, font }) {
    //find our google font
    let fontInclude = "";
    const gFont = allowedFonts.find(f => f.value.toLowerCase() == font.toLowerCase())
    if (gFont && gFont.src == "google") {
        fontInclude = <link href={`https://fonts.googleapis.com/css?family=${gFont.label.replace(/ /g, '+')}: 400, 700, 400italic, 700italic`} rel="stylesheet" type="text/css" />
    }
    let bannerColor = colours[0];
    let buttonColor = colours[1];

    return (

        <div style={{ width: "100%", maxHeight: "370px", overflow: "hidden", borderRadius: "10px" }}>
            {fontInclude}
            <div style={{ backgroundColor: "#E5E7EB", display: "flex", justifyContent: "center", flexDirection: "column", padding: "20px", paddingBottom: "0px" }}>

                <div style={{ textAlign: "center", marginBottom: "10px" }}>
                    <img src={logo ? logo.url || logo.raw : ""} style={{ width: "100%", height: "100%", maxWidth: "30px", maxHeight: "30px" }} />
                </div>

                <div style={{ width: "100%", backgroundColor: "#FFFFFF" }}>

                    <div style={{ width: "100%", backgroundColor: bannerColor, padding: "6px" }}>
                    </div>

                    <div>
                        <img src="https://cdn1.ourmailsender.com/siteContent/brandAnalyzer/example3_img1.png" style={{ width: "100%" }} />
                    </div>

                    <div style={{ padding: "20px" }}>
                        <div style={{ fontSize: "13px", fontWeight: "bold", marginBottom: "10px", textAlign: "center" }}>Good things are coming!</div>
                        <div style={{ fontSize: "8px", marginBottom: "10px", lineHeight: "12px", textAlign: "center" }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. <a style={{ color: buttonColor }}>Click Here</a>.
                            Aenean auctor cursus eros vel suscipit. Ut eleifend massa ac velit iaculis, nec convallis nisi rutrum.
                            Fusce varius hendrerit arcu.
                        </div>
                    </div>

                </div>

            </div>
        </div >
    )
}
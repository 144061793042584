import React from 'react'
import FormatNumberFuncs from '../../../../../../Classes/numberFormatFunctions'
import meta from './images/linkedin/linkedin_meta.svg'
import actions from './images/linkedin/linkedin_actions.svg'
import input from './images/linkedin/linkedin_input.svg'

export default function SocialLinkedinPreview({ account, content, images = [] }) {
    return (
        <div style={{ width: 480, borderRadius: '8px', fontFamily: 'inter', backgroundColor: 'white' }}>
            {/* header */}
            <div style={{ padding: '12px 16px', display: 'flex', alignItems: 'center' }}>
                <img src={account.image} style={{ marginRight: 8, height: 48, width: 48 }} />
                <div style={{ display: 'block' }}>
                    <div style={{ color: '#191919', fontSize: '14px', fontWeight: 600, lineHeight: '20px' }}>{account.name}</div>
                    <div style={{ color: '#666666', fontSize: '12px', fontWeight: 400, lineHeight: '16px' }}>{FormatNumberFuncs.formatNumber(account.followers)} followers</div>
                    <img src={meta} />
                </div>
            </div>

            {/* content */}
            <div style={{ padding: '0 16px 8px 16px', color: '#191919', fontSize: '14px', fontWeight: '400', lineHeight: '20px', maxHeight: 80, overflow: 'hidden', wordWrap: 'break-word', textOverflow: '... more', wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: content }}>
            </div>

            {/* image */}
            {
                !!images.length && <img src={images[0].url || images[0]} style={{
                    backgroundColor: '#e0e0e0',
                    width: '100%',
                    position: 'relative',
                    justifyContent: 'center'
                }} />
            }

            {/* share */}
            <div style={{ padding: '0 16px' }}>
                <img src={actions} />
                <div style={{ display: 'flex', padding: '0 0 8px 0' }}>
                    <img style={{ marginRight: 6, height: 40, width: 40, borderRadius: '50%' }} src={account.image} />
                    <img src={input} />
                </div>
            </div>
        </div >
    )
}
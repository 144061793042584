import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

/*
CARD
Cards used for navigation and stuff. Includes a lovely big icon, title, text and button

PROPS:
icon            REACT               The icon to display as a react component
title           REACT/TEXT          The title. Can be react or text
subTitle        REACT/TEXT          The sub title.
buttonText      REACT/TEXT          The button text/react.
*/

/*
STYLING is take from WhiteBox
*/

export default class Card extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {

        var theRole = this.props.role;
        var viewEditTxt = "view";
        var buttClass = "button-grey";
        var buttIcon = <UILIB.Icons icon="magnifier" />
        if (!theRole.readOnly) {
            viewEditTxt = "viewEdit";
            buttClass = "button-primary";
            buttIcon = <UILIB.Icons icon="pencil" />
        }

        return <UILIB.Paper className={"cards-role"}>
            {!theRole.readOnly && this.props.writeAccess === true && <div className="cards-role-delete">
                <UILIB.Icons icon="bin" onClick={() => { this.props.deleteRole(theRole.id) }} />
            </div>}


            <div className="cards-role-name">
                <h4>{i18n.t(theRole.name)}</h4>
            </div>
            {this.props.writeAccess === true && <div className="cards-role-buttons">
                <UILIB.Button
                    className={"button-md min-shadow " + buttClass + " " + this.props.className}
                    onClick={(ev) => { this.props.history.push("/cp/company/roles/edit/" + theRole.id) }}
                    text={i18n.t('company:roles.' + viewEditTxt)}
                    iconLeft={buttIcon}
                />
            </div>}
        </UILIB.Paper>

    }
}

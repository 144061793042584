
import React from 'react'

export default class SelectBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderWidthPct: 0
        }
        this.dragSlider = this.dragSlider.bind(this);
    }

    componentDidMount() {
        var slider = document.getElementById("slider");
        if (slider) {
            var initialPosition = 0;
            if (this.props.value) {
                var parent = document.getElementById("sliderOuter");
                var maxVal = 100;
                if (this.props.maxVal) maxVal = this.props.maxVal
                initialPosition = (parent.clientWidth / maxVal) * this.props.value;
            }
            slider.style.left = (initialPosition) + "px";
            this.dragSlider(slider);
        }
    }
    componentDidUpdate() {
        var slider = document.getElementById("slider");
        if (slider) {
            this.dragSlider(slider);
        }
    }

    dragSlider(elmnt) {
        var self = this;
        var posX, posY, mouseX, mouseY, parentX, parentWidth, parentMinWidth, parentMaxWidth, sliderWidth;
        var maxVal = 100;
        var minVal = 0;
        if (this.props.maxVal) maxVal = this.props.maxVal;
        if (this.props.minVal) minVal = this.props.minVal;
        var minPct = 1;
        elmnt.onmousedown = dragMouseDown;

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            mouseX = e.clientX;
            mouseY = e.clientY;
            var parent = document.getElementById("sliderOuter");
            parentX = parent.clientLeft;

            sliderWidth = elmnt.clientWidth;
            parentMaxWidth = (parent.clientWidth);
            parentMinWidth = 0;
            parentWidth = parent.clientWidth;
            document.onmouseup = closeDragElement;
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            posX = mouseX - e.clientX;
            mouseX = e.clientX;
            mouseY = e.clientY;

            var finalX = elmnt.offsetLeft - posX;

            if (finalX < (parentX + parentMinWidth)) finalX = (parentX + parentMinWidth);
            if (finalX > (parentX + parentMaxWidth)) finalX = parentMaxWidth;

            elmnt.style.left = (finalX) + "px";

            var pct = (finalX / parentWidth) * 100;

            var returnVal = pct;
            if (self.props.minVal != undefined && self.props.maxVal != undefined) {
                if (!isNaN(self.props.minVal) && !isNaN(self.props.maxVal)) {
                    //returnVal = self.props.minVal + ((pct / 100) * (self.props.maxVal - self.props.minVal));
                    returnVal = self.props.maxVal / 100 * pct;
                }
            }
            returnVal = returnVal.toFixed(self.props.precision ? self.props.precision : 0);
            self.props.onChange(returnVal);
            self.setState({ sliderWidthPct: pct })
        }


        function closeDragElement() {
            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }


    render() {
        var classes = "toggleWrap";
        return <div>
            {this.props.label && <div className="txtInput_label">{this.props.label}</div>}
            <div id="sliderOuter" className="ui_sliderOuter">
                <div className="ui_sliderBar" style={{ backgroundColor: "#EEEEEE" }}></div>
                <div className="ui_sliderKnob" id="slider"></div>
            </div>
        </div>
    }
}

import React from 'react'
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';

@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster }
})
export default class IncCapsuleDisconnect extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return <div>

            <h4 className="mar-b25">Really Disconnect?</h4>
            <div className="mar-b25">Are you sure you want to disconnect Capsule from {this.props.siteMaster.siteName}?</div>
            <div className="mar-b25">If you disconnect, your contacts will remain here but we will stop syncing with your capsule account.</div>

            <UILIB.Button text="Disconnect!" className="button-primary" onClick={this.props.goDisconnect} />

        </div>
    }
};


import React, { useState, useReducer } from "react";
import { useDispatch } from "react-redux";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import UILIB from "~/Common-Objects/Lib";
import SettingsSection from "../../../components/settingsSection";
import Button from "../../../components/button";
import * as styles from "./index.module.scss";
import TextInput from "../../../components/textInput";
import Header from "../../../components/header";
import i18n from "~/i18n";

export default function WebchatChannelAvailabilityMessages({
  channel,
  onUpdateChannel,
}) {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [values, updateValues] = useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    {
      messagesAvailable: channel.settings.messagesAvailable || "",
      messagesAwayType: channel.settings.messagesAwayType || "",
      messagesAway: channel.settings.messagesAway || "",
      messagesOohType: channel.settings.messagesOohType || "",
      messagesOoh: channel.settings.messagesOoh || "",
    }
  );

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitting(true);

    const data = { settings: values };

    try {
      await onUpdateChannel(data);
      dispatch(alterSnackbar(true, i18n.t("chat:channel.chat.availabilityMessages.saved")));
    } catch (error) {
      dispatch(handleGenericError(error));
    } finally {
      setSubmitting(false);
    }
  }

  const awayTypeSelectOptions = [
    { label: i18n.t("chat:channel.chat.availabilityMessages.awayTypeOptions.message"), value: "message" },
    { label: i18n.t("chat:channel.chat.availabilityMessages.awayTypeOptions.hide"), value: "hide" },
    { label: i18n.t("chat:channel.chat.availabilityMessages.awayTypeOptions.default"), value: "default" },
  ];

  const oohTypeSelectOptions = [
    { label: i18n.t("chat:channel.chat.availabilityMessages.oohTypeOptions.message"), value: "message" },
    { label: i18n.t("chat:channel.chat.availabilityMessages.oohTypeOptions.hide"), value: "hide" },
    { label: i18n.t("chat:channel.chat.availabilityMessages.oohTypeOptions.returnTime"), value: "returnTime" },
  ];

  return (
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
      <Header type="webchat" option="availability-messages" />
      <form className={styles.root} onSubmit={handleSubmit}>
        <SettingsSection
          heading={i18n.t("chat:channel.chat.availabilityMessages.available")}
          subheading={i18n.t("chat:channel.chat.availabilityMessages.availableDesc")}
        >
          <TextInput
            className="width-100"
            label={i18n.t("chat:channel.chat.availabilityMessages.messagesAvailable")}
            value={values.messagesAvailable}
            onChange={(e) => updateValues({ messagesAvailable: e.target.value })}
          />
        </SettingsSection>
        <hr />

        <SettingsSection
          heading={i18n.t("chat:channel.chat.availabilityMessages.away")}
          subheading={i18n.t("chat:channel.chat.availabilityMessages.awayDesc")}
        >
          <UILIB.SelectNewDefault
            value={values.messagesAwayType}
            data={awayTypeSelectOptions}
            fullWidth
            onValueChange={(val) => updateValues({ messagesAwayType: val })}
          />

          {values.messagesAwayType === "message" && (
            <TextInput
              className="width-100"
              label={i18n.t("chat:channel.chat.availabilityMessages.awayMessage")}
              value={values.messagesAway}
              onChange={(e) => updateValues({ messagesAway: e.target.value })}
            />
          )}
        </SettingsSection>
        <hr />

        <SettingsSection
          heading={i18n.t("chat:channel.chat.availabilityMessages.ooh")}
          subheading={i18n.t("chat:channel.chat.availabilityMessages.oohDesc")}
        >
          <UILIB.SelectNewDefault
            value={values.messagesOohType}
            data={oohTypeSelectOptions}
            fullWidth
            onValueChange={(val) => updateValues({ messagesOohType: val })}
          />

          {values.messagesOohType === "message" && (
            <TextInput
              className="width-100"
              label={i18n.t("chat:channel.chat.availabilityMessages.oohMessage")}
              value={values.messagesOoh}
              onChange={(e) => updateValues({ messagesOoh: e.target.value })}
            />
          )}
        </SettingsSection>
        <hr />

        <Button
          className={styles.submitButton}
          type="submit"
          size="s"
          loading={submitting}
        >
          {i18n.t("chat:channel.chat.availabilityMessages.save")}
        </Button>
      </form>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import InboxRightSideBar from "./rightSidebar";
import InboxChatView from './chatView';
import UILIB from "~/Common-Objects/Lib";
import axios from "~/data/http/axios";
import i18n from "~/i18n";
import { SupportSocket } from "~/Classes/socketsSupport";

export default function Conversation({
    hasActiveConversation = false,
    creatingConversation = {},
    activeConversation = {},
    changeConversation = () => { },
    updateConversations = () => { },
    activeInbox = () => { },
    createdConversation = () => { },
    staff = [],
    doingWizard = false,
    apps = [],
    userRole = null,
    setMainChatLoading = () => { },
    ...rest }) {

    const [sockets] = useState(new SupportSocket());
    const [firstRun, setFirstRun] = useState(true);
    const [subscriberError, setSubscriberError] = useState(false);
    const [socketError, setSocketError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingChatLines, setLoadingChatLines] = useState(true);
    const [chatLines, setChatLines] = useState([]);
    const [subscriber, setSubscriber] = useState({});
    const [subscriberId, setSubscriberId] = useState(0);
    const [channels, setChannels] = useState([])
    const [channelSummary, setChannelSummary] = useState([])
    const [pipeLineSummary, setPipeLineSummary] = useState([])
    const [visitorTyping, setVisitorTyping] = useState(undefined);
    const [wizardConversation, setWizardConversation] = useState(false);


    useEffect(() => {
        init();
        return () => {
            if (sockets) sockets.disconnect();
        }
    }, [])
    useEffect(() => {
        if (!firstRun) init2();
    }, [firstRun, creatingConversation])

    useEffect(() => {
        getChatLines();
    }, [activeConversation.lastMessageDate])

    useEffect(() => {
        if (!firstRun) init2();
    }, [activeConversation.SubscriberId])

    const init = async () => {
        setFirstRun(true);
        setLoading(true);
        setChatLines([]);
        if (activeConversation.uuid && activeConversation.uuid.toLowerCase() == "wizard") {
            setWizardConversation(true);
        }
        setFirstRun(false);
    }

    const init2 = async (dontReload) => {
        setMainChatLoading(true);
        setLoading(true);
        setChannels([]);
        setChatLines([]);
        setSubscriber({});
        setLoadingChatLines(true);
        sockets.disconnect()
        let subId = activeConversation.SubscriberId;
        if (creatingConversation.subscriberId) {
            //creating conversation from subscriberId
            subId = creatingConversation.subscriberId;
            setLoadingChatLines(false);
        }
        else if (creatingConversation.emailAddress) {
            //creating a new contact
            let sub = await axios.post('/subscriber', {
                emailAddress: creatingConversation.emailAddress
            })
            subId = sub.data.Subscriber.id;
            setLoadingChatLines(false);
        }
        else if (activeConversation.uuid && activeConversation.uuid.toLowerCase() == "wizard") {
            setLoadingChatLines(false);
        }
        else if (activeConversation.uuid) {
            //everything else
            return await sockets.init(activeConversation.uuid, activeConversation.AccountMasterId, activeConversation.SiteTrackerId, socketActions, activeConversation.inboxChannelType);
        }
        getChatLines();
        getSubscriber(subId);

    }

    const socketActions = (event => {
        if (event && event.event == "connected") {
            getSubscriber(activeConversation.SubscriberId);
            setSocketError(false);
            getChatLines();
        }
        if (event && event.event == "connect_error") {
            setSocketError(true);
            return;
        }
        if (event && event.event == "visitor_sent_newMessage") {
            if (event.data && event.data.ChatId == activeConversation.id) {
                getChatLines();
            }
            updateConversations();
            return;
        }
        if (event && event.event == "visitor_read_message") {
            if (event.data && event.data.ChatId == activeConversation.id) {
                getChatLines();
            }
            updateConversations();
            return;
        }
        if (event && event.event == "visitorTyping") {
            if (event.data && event.data.chatId == activeConversation.id) {
                setVisitorTyping(new Date());
                setTimeout(() => {
                    setVisitorTyping(undefined);
                }, 4000);
            }
        }
    })

    const getSubscriber = async (subId) => {
        try {
            setSubscriberId(subId);
            let tmpSubscriber = {};
            let onlineChannels = [];
            let chanSummary = [];
            let pipelineSummary = [];
            if (wizardConversation) {
                tmpSubscriber = {
                    emailAddress: "support@transpond.io",
                    customFields: [],
                    Tags: []
                }
            }
            else {
                let { data } = await axios.get(`/subscriber/${subId}`);
                tmpSubscriber = data.Subscriber;
                onlineChannels = await axios.get('/inboxes/' + activeInbox.id + '/subscriber/' + tmpSubscriber.id + "/chatonlinechannels")
                onlineChannels = onlineChannels.data;
                chanSummary = await axios.get('/inboxes/chat/' + activeConversation.id + '/channelhistory');
                chanSummary = chanSummary.data;
                if (tmpSubscriber.ApplicationId && tmpSubscriber.ApplicationId == 8) {
                    try {
                        pipelineSummary = await axios.get('/application/capsule/contact/' + tmpSubscriber.id + '/opportunities');
                        pipelineSummary = pipelineSummary.data;
                    } catch (error) {
                        pipelineSummary = []
                    }
                }
            }

            setSubscriber(tmpSubscriber);
            setChannels(onlineChannels);
            setChannelSummary(chanSummary);
            setPipeLineSummary(pipelineSummary);
            setLoading(false);
            setMainChatLoading(false);
        }
        catch (error) {
            setLoading(false);
            setSubscriberError(true);
            setMainChatLoading(false);
            setWizardConversation(true);
        }
    }

    const getChatLines = async () => {
        let chatLines = (await axios.get('/inboxes/chat/' + activeConversation.id + '/lines')).data
        setChatLines(chatLines);
        setLoadingChatLines(false);
    }

    const sentMessage = (newChatLine, closed) => {
        if (newChatLine) {
            let newChatLines = JSON.parse(JSON.stringify(chatLines));
            newChatLines.push(newChatLine.data)
            setChatLines(newChatLines);
        }
        if (closed) {
            sockets.disconnect();
        }
        updateConversations(closed);
    }

    const sendAgentTyping = () => {
        sockets.emit_agentTyping(activeConversation.id);
    }
    if (socketError) {
        return <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ padding: 20, textAlign: "center" }}>
                <h4 className="mar-b10" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <UILIB.Icons icon="alert" className="mar-r10" style={{ height: "25", width: "25" }} />
                    {i18n.t("chat:inbox.connectionError")}
                </h4>
                <div className="mar-b10">{i18n.t("chat:inbox.connectionErrorDesc1")}</div>
                <div>{i18n.t("chat:inbox.connectionErrorDesc2")}</div>
            </div>
        </div>
    }

    if (loading) return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <div style={{ height: "110px" }}><UILIB.LoadingIcons /></div>
        <div>{i18n.t("chat:inbox.init")}</div>
    </div>

    return <>
        <InboxChatView
            className="inbox-page__center"
            activeConversation={activeConversation}
            creatingConversation={creatingConversation}
            sentMessage={sentMessage}
            createdConversation={createdConversation}
            chatLines={chatLines}
            loadingChatLines={loadingChatLines}
            activeInbox={activeInbox}
            subscriber={subscriber}
            channels={channels}
            staff={staff}
            doingWizard={doingWizard}
            wizardConversation={wizardConversation}
            visitorTyping={visitorTyping}
            sendAgentTyping={sendAgentTyping}
            updateConversations={updateConversations}
            userRole={userRole}
            reloadConversation={init2}
            subscriberError={subscriberError}
        />

        <InboxRightSideBar
            className="inbox-page__right-sidebar"
            activeConversation={activeConversation}
            changeConversation={changeConversation}
            creatingConversation={creatingConversation && creatingConversation.subscriberId ? true : false}
            subscriber={subscriber}
            subscriberId={subscriberId}
            getSubscriber={getSubscriber}
            staff={staff}
            doingWizard={doingWizard}
            wizardConversation={wizardConversation}
            channelSummary={channelSummary}
            pipeLineSummary={pipeLineSummary}
            apps={apps}
            userRole={userRole}
        />
    </>
}

import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib';
import i18n from '~/i18n'
class SkipConfirmWindow extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                <h4 className="mar-b25">{i18n.t('form:step4.skipHeader')}</h4>
                <div className="mar-b25">{i18n.t('form:step4.skipSubHeader')}</div>

                <div className="quickFlex">

                    <UILIB.Button text={i18n.t('form:step4.skipConfirm')} className="button-primary mar-r10" onClick={this.props.onConfirm} />
                    <UILIB.Button text={i18n.t('form:step4.skipCancel')} onClick={this.props.onCancel} />
                </div>
            </div>
        );
    }
}

export default SkipConfirmWindow;
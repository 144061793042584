import React from "react";
import classnames from "classnames";
import * as styles from "./index.module.scss";

export default function SettingsSection({
  heading,
  subheading,
  className,
  children,
  style = {}
}) {
  const classes = classnames(styles.root, className);
  return (
    <section className={classes} style={style}>
      <div className={styles.headingWrapper}>
        {heading && <h2 className={styles.heading}>{heading}</h2>}
        {subheading && <p className={styles.subheading}>{subheading}</p>}
      </div>

      {children}
    </section>
  );
}

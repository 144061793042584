import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import siteVars from '~/Classes/siteVars';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class ExportDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: null,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            exporting: false
        }

        this.updateDate = this.updateDate.bind(this);
        this.exportCampaign = this.exportCampaign.bind(this);
    }

    componentDidMount() {
        this._ismounted = true;
    }
    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer)
        clearInterval(this.state.timer);
        this._ismounted = false;
    }

    updateDate(dates) {
        this.setState({ startDate: dates.startDate, endDate: dates.endDate })
    }

    exportCampaign() {
        var self = this
        this.setState({
            exporting: true
        })
        function checkExport(id) {
            if (!self._ismounted) return
            axios.get('/campaign/' + self.props.campaignId + '/export/' + id).then(response => {
                if (response.data.ExportJob.finished) {
                    let url
                    if (response.data.ExportJob.url) {
                        url = response.data.ExportJob.url
                    } else if (response.data.ExportJob.location) {
                        url = siteVars.repoEndPoint + '/' + response.data.ExportJob.location;
                    } else {
                        self.setState({
                            exporting: false
                        })
                    }
                    if (url) {
                        const link = document.createElement('a');
                        link.href = url
                        document.body.appendChild(link);
                        link.click();
                    }
                    self.setState({ exporting: false })
                } else {
                    self.timer = setTimeout(() => { checkExport(id) }, 500)
                }
            })
        }
        axios.put('/campaign/' + this.props.campaignId + '/export?startDate=' + this.state.startDate.format('YYYY-MM-DD') + '&endDate=' + this.state.endDate.format('YYYY-MM-DD')).then(response => {
            checkExport(response.data.ExportJob.id)
        })
    }
    render() {


        return <div>

            <h4 className="mar-b25">{i18n.t('subscribers:nav.export')}</h4>
            <div className="mar-b25">{i18n.t('campaigns:overview.exportSubHeader')}</div>

            {(this.state.exporting == false) && <div>
                <UILIB.TextInput outerClassName="mar-b25" type="multiDate" startDate={this.state.startDate} endDate={this.state.endDate} updateDate={this.updateDate}></UILIB.TextInput>
                <UILIB.Button text={i18n.t('campaigns:overview.exportButton')} onClick={this.exportCampaign} iconRight={<UILIB.Icons icon="arrowDown" />} />
            </div>}
            {(this.state.exporting == true) && <div style={{ textAlign: "center" }}><UILIB.LoadingIcons iconType="2" /></div>}
        </div>
    }
}
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import i18n from "i18next";
import UILIB from "~/Common-Objects/Lib";
import { alterFullpage } from "~/data/actions/siteActions";
import AddLink from "../../pages/cp/includes/formPicker/components/addLink";
import FileManager from "../../pages/cp/includes/fileManager/fileManager";
import FileSettings from "../../pages/cp/includes/formPicker/components/fileSettings";
import AddContentButton from "../../pages/cp/includes/formPicker/components/addContentButton";

export default function SelectGatedContent({
  content = [],
  contentAccess = "email",
  onAddFile,
  onSaveFileSettings,
  onAddLink,
  onDelete,
  onContentAccessChange,
  onSubmit,
  onSaveUrl,
  running = false,
  changePage = () => { },
}) {
  const dispatch = useDispatch();

  const [editing, setEditing] = useState({})

  async function handleAddLink(url) {
    await onAddLink(url);
    dispatch(alterFullpage(null));
  }

  async function handleAddFile(file) {
    await onAddFile(file);
    dispatch(alterFullpage(null));
  }

  async function handleSaveFileSettings(file, settings) {
    await onSaveFileSettings(file, settings);
    dispatch(alterFullpage(null));
  }

  async function handleDelete(item) {
    onDelete(item);
  }

  async function handleContentAccessChange(e) {
    onContentAccessChange(e.target.value);
  }

  async function handleSubmit() {
    onSubmit();
  }

  function handleAddContentSelect(type) {
    if (type === "file") {
      openFileManager();
    } else {
      openUrlSelector();
    }
  }

  function openFileManager() {
    dispatch(
      alterFullpage(
        <UILIB.Drawer
          standalone={true}
          width="100vw"
          showClose={true}
          startClose={() => dispatch(alterFullpage(null))}
        >
          <FileManager onFileClick={handleAddFile} />
        </UILIB.Drawer>
      )
    );
  }

  function openUrlSelector() {
    dispatch(
      alterFullpage(
        <UILIB.Drawer
          standalone={true}
          width={500}
          showClose={true}
          startClose={() => dispatch(alterFullpage(null))}
        >
          <AddLink onAdd={handleAddLink} />
        </UILIB.Drawer>
      )
    );
  }

  function openFileSettings(file) {
    dispatch(
      alterFullpage(
        <UILIB.Drawer
          standalone={true}
          width={600}
          showClose={true}
          startClose={() => dispatch(alterFullpage(null))}
        >
          <FileSettings
            formFile={file}
            onSave={(settings) => handleSaveFileSettings(file, settings)}
          />
        </UILIB.Drawer>
      )
    );
  }

  function getFileLimit({ settings }) {
    if (settings?.limit) {
      if (settings.limit === "timed") {
        return `${i18n.t("form:gated.downloadLimit.timed")} (${settings.expiry} ${settings.expiryUnit})`;
      }

      return i18n.t("form:gated.downloadLimit.once");
    }

    return i18n.t("form:gated.downloadLimit.none");
  }

  function getFileName(file) {
    if (file.url) return <a href={file.url} target="_blank">{file.url}</a>;
    else return file.FileManagerFile.friendlyName || file.FileManagerFile.name;
  }

  function handleEditContent(id, value) {
    if (editing.hasOwnProperty(id)) {
      const obj = { ...editing }
      delete obj[id]
      setEditing(obj)
    } else {
      setEditing({ ...editing, [id]: value })
    }
  }

  function changeUrl(id, value) {
    setEditing({ ...editing, [id]: value })
  }

  async function handleSaveUrl(id) {
    await onSaveUrl(id, editing[id])
    const obj = { ...editing }
    delete obj[id]
    setEditing(obj)
  }

  const accessTypes = ["clickButton", "email", "emailLanding"];
  let warningText = 'After adding/removing content you will need to update the template'
  if (contentAccess === 'email') warningText = <div>After adding/removing content you will need to update the <a onClick={() => changePage(3)}>Email</a> with the new content</div>
  if (contentAccess === 'emailLanding') warningText = <div>After adding/removing content you will need to update the <a onClick={() => changePage(4)}>Confirmed Page</a> with the new content</div>

  return (
    <div className="select-gated-content">
      <div className="select-gated-content__top">
        <div className="select-gated-content__content select-gated-content__inner">
          {content.length > 0 ? (
            <div>
              <div className="select-gated-content__content-header">
                <h2 className="h4">
                  {i18n.t("form:gated.content")} {`(${content.length})`}
                </h2>

                <AddContentButton onSelect={handleAddContentSelect} />
              </div>

              <table className="select-gated-content__content-table simple-table mar-t15 mar-b25">
                <thead>
                  <tr>
                    <th />
                    <th>{i18n.t("form:gated.filenameUrl")}</th>
                    <th>{i18n.t("form:gated.downloadLimit.heading")}</th>
                    <th />
                  </tr>
                </thead>

                <tbody>
                  {content.map((item, index) => {
                    const isLink = item.type === 1;

                    return (
                      <tr key={index}>
                        <td><UILIB.Icons icon={isLink ? "link" : "document"} color="#A9A9AD" /></td>
                        <td>
                          {!editing.hasOwnProperty(item.id) && <div style={{
                            maxWidth: 450,
                            overflow: 'hidden',
                            textWrap: 'wrap',
                            textOverflow: 'ellipsis'
                          }}>
                            {getFileName(item)}
                          </div>}
                          {editing.hasOwnProperty(item.id) &&
                            <UILIB.TextInput
                              focus
                              value={editing[item.id]}
                              onChange={e => changeUrl(item.id, e.target.value)}
                              onEnterPress={() => handleSaveUrl(item.id)}
                              onEscapePress={() => handleEditContent(item.id)}
                              iconRight={<UILIB.Icons icon="tick" onClick={() => handleSaveUrl(item.id)} />}
                            />}
                        </td>
                        <td>{isLink ? "-" : getFileLimit(item)}</td>
                        <td>
                          <div className="select-gated-content__content-actions">
                            {!isLink && (
                              <UILIB.Button
                                className="button-small"
                                onClick={() => openFileSettings(item, index)}
                              >
                                {i18n.t("form:gated.downloadLimit.set")}
                              </UILIB.Button>
                            )}

                            {isLink && !!item.id && <button type="button" aria-label="Update url" onClick={() => handleEditContent(item.id, item.url)}>
                              <UILIB.Icons icon="pencil" color="black" />
                            </button>}

                            <button
                              type="button"
                              aria-label="Delete content"
                              onClick={() => handleDelete(item)}
                            >
                              <UILIB.Icons icon="bin" color="#000000" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {!!running && contentAccess !== 'clickButton' && <UILIB.WarningHolder>{warningText}</UILIB.WarningHolder>}
            </div>
          ) : (
            <div className="select-gated-content__empty-state mar-b0">
              <h2 className="h4 mar-b10">
                {i18n.t("form:gated.empty.heading")}
              </h2>
              <p className="text-grey">{i18n.t("form:gated.empty.body")}</p>

              <AddContentButton onSelect={handleAddContentSelect} />
            </div>
          )}
        </div>

        <div className="select-gated-content__access select-gated-content__inner">
          <h2 className="h4 mar-b10">{i18n.t("form:gated.access.heading")}</h2>
          <p className="text-grey mar-b24">
            {i18n.t("form:gated.access.body")}
          </p>

          {accessTypes.map((type) => {
            return (
              <UILIB.RadioTile
                key={type}
                name="access"
                value={type}
                checked={contentAccess === type}
                className="mar-b10"
                onChange={handleContentAccessChange}
              >
                <div>
                  <div className="select-gated-content__recommended-access mar-b5">
                    <h3 className="text-md">
                      {i18n.t(`form:gated.access.${type}.heading`)}
                    </h3>

                    {type === "email" && (
                      <UILIB.SquareChip className="square-chip-primary">
                        {i18n.t(`form:gated.access.recommended`)}
                      </UILIB.SquareChip>
                    )}
                  </div>

                  <p className="text-grey mar-b0">
                    {i18n.t(`form:gated.access.${type}.body`)}
                  </p>
                </div>
              </UILIB.RadioTile>
            );
          })}
        </div>
      </div>

      {onSubmit && (
        <div className="select-gated-content__continue select-gated-content__inner">
          <UILIB.Button
            className="button-primary"
            iconRight={<UILIB.Icons icon="arrowRight" />}
            disabled={content.length === 0}
            onClick={handleSubmit}
          >
            {i18n.t(`form:gated.submit`)}
          </UILIB.Button>

          <div className="select-gated-content__help box">
            <h3 className="text-md mar-b5">
              {/* TODO: Replace the link in this string with an updated support doc when it is available and before gated content goes live. */}
              {i18n.t(`form:gated.help.heading`)}
            </h3>

            <p
              className="mar-b0"
              dangerouslySetInnerHTML={{
                __html: i18n.t("form:gated.help.body"),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

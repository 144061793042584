// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kv_g1Fk1LR4tLe1MkdmY{display:flex;flex-direction:column;gap:32px}.T6jF4l4BphJ0zcUA7_6l{flex:1}.WaXNqkIqEH_b3Fia40bz{align-self:flex-start}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/channel/facebookMessenger/general/index.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,QAAA,CAGJ,sBACI,MAAA,CAGJ,sBACI,qBAAA","sourcesContent":[".root {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n}\n\n.select {\n    flex: 1;\n}\n\n.submitButton {\n    align-self: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "kv_g1Fk1LR4tLe1MkdmY",
	"select": "T6jF4l4BphJ0zcUA7_6l",
	"submitButton": "WaXNqkIqEH_b3Fia40bz"
};
export default ___CSS_LOADER_EXPORT___;

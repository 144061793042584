import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'

class HookFilter extends Component {
    constructor(props) {
        super(props);
        // type - click/open
        // hook - object to put the filter(s) on
        this.state = {
            hook: {}
        }
        this.set = this.set.bind(this)
        this.check = this.check.bind(this)
    }

    componentDidMount() {
        var hook = this.props.hook;
        if (hook.mpz_filter === undefined) this.props.hook.mpz_filter = 1;
        if (!hook.mpz_unique_campaign || hook.mpz_unique_campaign === undefined) hook.mpz_unique_campaign = !!this.props.CampaignId;
        if (!hook.mpz_unique_link || hook.mpz_unique_link === undefined) hook.mpz_unique_link = !!this.props.link;
        if (!hook.mpz_filter_operator || hook.mpz_filter_operator === undefined) hook.mpz_filter_operator = '=';
        this.setState({ hook })
    }

    set(ev) {
        let value = ev.target.value
        if (parseInt(value) || parseInt(value) === 0) value = parseInt(value)
        var hook = this.state.hook;
        hook[ev.target.name] = value;
        this.setState({ hook })
    }

    check(ev) {
        var hook = this.state.hook;
        hook[ev.target.name] = ev.target.checked
        this.setState({ hook })
    }

    render() {
        return (
            <div>
                <div className="mar-b15 mar-t10">Only run this action if the following filters are met</div>
                <UILIB.Paper>
                    <div>When the contact <strong>{this.props.type}s</strong> emails {this.state.hook.mpz_unique_campaign ? <strong>in this campaign</strong> : ''}</div>
                    <div className="quickFlex mar-b10">
                        <div style={{ flexGrow: 1, marginRight: 10 }}>
                            <UILIB.Select value={this.state.hook.mpz_filter_operator} onChange={this.set} name="mpz_filter_operator" data={[{ label: "Exactly", value: "=" }, { label: "At Least", value: ">=" }]} />
                        </div>
                        <div style={{ flexGrow: 1, marginRight: 10 }}>
                            <UILIB.TextInput min="0" name="mpz_filter" type="number" value={this.state.hook.mpz_filter} onChange={this.set} />
                        </div>
                        <div>times (0 to turn off Filter)</div>
                    </div>
                    {!this.props.CampaignId && <UILIB.CheckBox outerClassName="mar-b10" checked={this.state.hook.mpz_unique_campaign} name="mpz_unique_campaign" onChange={this.check} >Count each campaign independently</UILIB.CheckBox>}
                    {this.props.type === 'click' && !this.props.link && <UILIB.CheckBox outerClassName="mar-b10" checked={this.state.hook.mpz_unique_link} name="mpz_unique_link" onChange={this.check} >Count each link independently</UILIB.CheckBox>}
                    <div>NOTE: if you do not filter then we will run this action EVERY time a contact {this.props.type}s an email</div>
                </UILIB.Paper>

                {!this.props.inline && <UILIB.Button text="Save" className="button-primary" onClick={() => this.props.close(this.state.hook)} />}

            </div >
        );
    }
}

export default HookFilter;
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import EditCustomisation from './edit'
import { connect } from 'react-redux';

@connect((store) => {
    return { siteMaster: store.siteMaster }
})

export default class ImageCustomiser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            customisations: [],
            status: "overview",
            selectedCustomisation: -1
        }
        this.editCustomisation = this.editCustomisation.bind(this);
        this.finishedEdit = this.finishedEdit.bind(this);
        this.removeCustomisation = this.removeCustomisation.bind(this);
        this.updateEdit = this.updateEdit.bind(this);
        this.generateImageUrl = this.generateImageUrl.bind(this);
    }
    componentWillMount() {

        var customisations = [];
        if (this.props.imgSrc.indexOf("?") > -1) {
            var imgSrcData = this.props.imgSrc.split("?")[1].split("&");
            imgSrcData.forEach(thing => {
                var line = thing.split("=");
                var tmpCnt = 0;
                while (tmpCnt < 20) {
                    if (line[0] == "text" + tmpCnt) {
                        if (!customisations[tmpCnt]) customisations.push({ text: line[1] })
                    }
                    if (line[0] == "font" + tmpCnt) customisations[tmpCnt].font = line[1]
                    if (line[0] == "fontSize" + tmpCnt) customisations[tmpCnt].fontSize = line[1]
                    if (line[0] == "fontColor" + tmpCnt) customisations[tmpCnt].fontColor = line[1]
                    if (line[0] == "textAlign" + tmpCnt) customisations[tmpCnt].textAlign = line[1]
                    if (line[0] == "left" + tmpCnt) customisations[tmpCnt].left = line[1]
                    if (line[0] == "top" + tmpCnt) customisations[tmpCnt].top = line[1]
                    if (line[0] == "rotation" + tmpCnt) customisations[tmpCnt].rotation = line[1]
                    tmpCnt++;
                }
            })
        }
        this.setState({ customisations })
    }
    removeCustomisation(cId) {
        var customisations = this.state.customisations;
        customisations.splice(cId, 1)
        this.setState({ customisations }, () => {
            var newImgUrl = this.generateImageUrl();
            this.props.onSave(newImgUrl)
        })
    }
    editCustomisation(cId) {
        var customisations = this.state.customisations;
        if (cId == -1) {
            customisations.push({
                text: "Sample Text",
                font: "Arial",
                fontSize: 14,
                fontColor: "#ffffff",
                textAlign: "left",
                left: 5,
                top: 5,
                rotation: 0
            })
            cId = customisations.length - 1;
        }
        this.setState({ customisations: customisations, status: "editting", selectedCustomisation: cId }, () => {
            var newImgUrl = this.generateImageUrl();
            this.props.onSave(newImgUrl)
        })
    }
    finishedEdit() {
        this.setState({ selectedCustomisation: -1, status: "overview" }, () => {
            var newImgUrl = this.generateImageUrl();
            this.props.onSave(newImgUrl)
        })
    }

    updateEdit(newData) {
        var customisations = this.state.customisations;

        customisations[this.state.selectedCustomisation] = newData;

        this.setState({ customisations }, () => {
            var newImgUrl = this.generateImageUrl();
            this.props.onSave(newImgUrl)
        })


    }

    generateImageUrl() {

        var aid = "";
        var fn = "";
        var fid = "";

        if (this.props.imgSrc.indexOf("?") > -1) {
            var imgSrcData = this.props.imgSrc.split("?")[1].split("&");
            imgSrcData.forEach(thing => {
                var line = thing.split("=");
                if (line[0] === "aid") aid = line[1];
                if (line[0] === "fn") fn = line[1];
                if (line[0] === "fid") fid = line[1];
            })
        }
        var imgSrcS = this.props.imgSrc;
        imgSrcS = imgSrcS.split("/");
        if (aid == "") aid = imgSrcS[imgSrcS.length - 4]
        if (fn == "") fn = imgSrcS[imgSrcS.length - 1]
        if (fid == "") fid = imgSrcS[imgSrcS.length - 2]


        var hostName = "https://" + this.props.siteMaster.trackingDomain;
        // hostName = "http://localhost:8081"
        var imgSrc = hostName + "/api/dynamicimage"
        imgSrc += "?&aid=" + aid
        imgSrc += "&fn=" + fn;
        imgSrc += "&fid=" + fid;

        this.state.customisations.forEach((c, index) => {
            var fontColor = c.fontColor.replace("#", "")
            imgSrc += `&text${index}=${c.text}`
            imgSrc += `&font${index}=${c.font}`
            imgSrc += `&fontSize${index}=${c.fontSize}`
            imgSrc += `&fontColor${index}=${fontColor}`
            imgSrc += `&textAlign${index}=${c.textAlign}`
            imgSrc += `&left${index}=${c.left}`
            imgSrc += `&top${index}=${c.top}`
            imgSrc += `&rotation${index}=${c.rotation}`
        })

        return imgSrc;
    }

    render() {
        var imgSrc = this.generateImageUrl();

        return <div>
            <div className="mar-b10" style={{ height: "200px", backgroundColor: "#EEEEEE", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div>
                    <img src={imgSrc} style={{ maxWidth: "100%", maxHeight: "200px", border: "1px solid grey" }} />
                </div>
            </div>
            {this.state.status == "overview" &&
                <div>
                    <h4 className="mar-b15">Customisations</h4>
                    {this.state.customisations.length < 1 && <div className="mar-b15">
                        You have not added any customisations yet
                    </div>}

                    {this.state.customisations.length > 0 && <div style={{ border: "1px solid #EEEEEE" }} className="mar-b15">
                        <table width="100%" cellPadding="10" cellSpacing="0">
                            {this.state.customisations.map((cust, index) => {
                                return <tr>
                                    <td>
                                        {cust.text}
                                    </td>
                                    <td align="right" width="150">
                                        <UILIB.Button className="button-xs mar-r5" text="Edit" onClick={() => { this.editCustomisation(index) }} />
                                        <UILIB.Button className="button-xs red" text="Delete" onClick={() => { this.removeCustomisation(index) }} />
                                    </td>
                                </tr>
                            })}
                        </table>
                    </div>}

                    <UILIB.Button className="button-xs" text="Add a Customisation" onClick={() => { this.editCustomisation(-1) }} />

                </div>}

            {this.state.status == "editting" && <EditCustomisation customisationId={this.state.selectedCustomisation} customisations={this.state.customisations} finishedEdit={this.finishedEdit} updateEdit={this.updateEdit} />}


        </div>
    }
}

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';

@connect((store) => {
    return { user: store.user }
})
export default class DeleteRolePop extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            alertMessage: ''
        };

        this.deleteRole = this.deleteRole.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    deleteRole() {
        var roleId = this.props.roleId;
        self = this;
        return axios
            .delete('/role/' + roleId)
            .then((res) => {
                self.props.deletedRole();
                self.closeDrawer();
            }).catch(err => {

                var errMessage = "Cannot remove Role";
                if (err.response && err.response.data && err.response.data.error) errMessage = err.response.data.error;
                if (err.data && err.data.error) errMessage = err.data.error;
                this.setState({
                    alertMessage: errMessage,
                    alertOpen: true
                })
            });
    }

    render() {

        return <div>

            <h4 className="mar-b25">{i18n.t('company:roles.deleteRoleHeader')}</h4>
            <div className="mar-b25">{i18n.t('company:roles.deleteRoleSubHeader')}</div>
            <UILIB.Button
                text={i18n.t('company:roles.deleteRoleButton')}
                className="button-primary" onClick={this.deleteRole}
            />
            {this.state.alertMessage != "" && <div className="text-red" style={{ marginTop: "10px" }}>{this.state.alertMessage}</div>}

        </div>
    }
};


import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import EmailTools from '~/Classes/emailValidator';
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return {
        user: store.user
    }
})

export default class GroupSettingsTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupName: '',
            friendlyName: '',
            groupID: 0,

            defaultFromName: '',
            defaultFromEmailAddress: '',
            defaultReplyAddress: '',
            defaultEmailSubject: '',
            sendNewSubscriberNotificationsTo: '',
            sendNewUnsubscribeNotificationsTo: '',
            sendDailyUpdateEmailTo: '',
            signupReminderText: '',
            alertMessage: '',
            alertOpen: false,
            saving: false,
            hiddenFromUnsubscribe: false,
            reminderCharCount: 0,
            errorHandling:
            {
                defaultFromName: '',
                defaultFromEmailAddress: '',
                defaultEmailSubject: '',
                defaultReplyAddress: '',
                sendNewSubscriberNotificationsTo: '',
                sendNewUnsubscribeNotificationsTo: '',
                sendDailyUpdateEmailTo: '',
                groupName: '',
                signupReminderText: ''
            },
            pageLoaded: false
        };
        this.updateTextField = this.updateTextField.bind(this);
        this.lookupGroup = this.lookupGroup.bind(this);
        this.saveFormChanges = this.saveFormChanges.bind(this);
        this.updateSwitchField = this.updateSwitchField.bind(this);
        this.updateReminderTextField = this.updateReminderTextField.bind(this);
    }


    componentDidMount() {
        this.setState({
            groupID: this.props.match.params.groupID
        });
        this.lookupGroup(this.props.match.params.groupID);
    }

    lookupGroup(groupID) {
        var self = this;
        axios.get('/group/' + groupID
        )
            .then((res) => {

                var theData = res.data.Group;
                if (theData.groupName === null) { theData.groupName = "" };
                if (theData.defaultFromName === null) { theData.defaultFromName = "" };
                if (theData.defaultFromEmailAddress === null) { theData.defaultFromEmailAddress = "" };
                if (theData.defaultEmailSubject === null) { theData.defaultEmailSubject = "" };
                if (theData.sendNewSubscriberNotificationsTo === null) { theData.sendNewSubscriberNotificationsTo = "" };
                if (theData.sendNewUnsubscribeNotificationsTo === null) { theData.sendNewUnsubscribeNotificationsTo = "" };
                if (theData.sendDailyUpdateEmailTo === null) { theData.sendDailyUpdateEmailTo = "" };
                if (theData.friendlyName === null) { theData.friendlyName = "" };
                if (theData.signupReminderText === null) { theData.signupReminderText = "" };

                self.setState({
                    groupName: theData.groupName,
                    defaultFromName: theData.defaultFromName,
                    defaultFromEmailAddress: theData.defaultFromEmailAddress,
                    defaultEmailSubject: theData.defaultEmailSubject,
                    defaultReplyAddress: theData.defaultReplyAddress,
                    sendNewSubscriberNotificationsTo: theData.sendNewSubscriberNotificationsTo,
                    sendNewUnsubscribeNotificationsTo: theData.sendNewUnsubscribeNotificationsTo,
                    sendDailyUpdateEmailTo: theData.sendDailyUpdateEmailTo,
                    friendlyName: theData.friendlyName,
                    signupReminderText: theData.signupReminderText,
                    hiddenFromUnsubscribe: theData.hiddenFromUnsubscribe,
                    pageLoaded: true,
                    reminderCharCount: theData.signupReminderText.length
                });
            })
            .catch(function (error) {

            });

    }

    updateTextField(event) {
        let value = event.currentTarget.value
        if (event.currentTarget.type === 'email' && value) value = value.toLowerCase()
        this.setState({
            [event.currentTarget.name]: value
        }, this.checkForm);
    }

    updateReminderTextField(event) {
        let value = event.currentTarget.value
        this.setState({
            [event.currentTarget.name]: value,
            reminderCharCount: value.length
        });
    }

    saveFormChanges() {

        var self = this;
        var errorState = this.state.errorHandling;
        errorState.defaultFromEmailAddress = "";
        errorState.defaultEmailSubject = "";
        errorState.defaultReplyAddress = '';
        errorState.sendNewSubscriberNotificationsTo = "";
        errorState.sendNewUnsubscribeNotificationsTo = "";
        errorState.sendDailyUpdateEmailTo = "";
        errorState.groupName = "";
        errorState.defaultFromName = "";
        errorState.signupReminderText = "";

        var hasError = false;

        if (this.state.defaultFromEmailAddress && EmailTools.validateEmail(this.state.defaultFromEmailAddress) == false) {
            errorState.defaultFromEmailAddress = i18n.t('subscribers:settings.emailAddressError');
            hasError = true;
        }
        if (this.state.defaultEmailSubject && this.state.defaultEmailSubject.length < 3) {
            errorState.defaultEmailSubject = i18n.t('subscribers:settings.defaultSubjectError');
            hasError = true;
        }

        if (this.state.sendNewSubscriberNotificationsTo && EmailTools.validateEmail(this.state.sendNewSubscriberNotificationsTo) == false) {
            errorState.sendNewSubscriberNotificationsTo = i18n.t('subscribers:settings.emailAddressError');
            hasError = true;
        }

        if (this.state.sendNewUnsubscribeNotificationsTo && EmailTools.validateEmail(this.state.sendNewUnsubscribeNotificationsTo) == false) {
            errorState.sendNewUnsubscribeNotificationsTo = i18n.t('subscribers:settings.emailAddressError');
            hasError = true;
        }

        if (this.state.defaultReplyAddress && EmailTools.validateEmail(this.state.defaultReplyAddress) == false) {
            errorState.defaultReplyAddress = i18n.t('subscribers:settings.emailAddressError');
            hasError = true;
        }

        if (this.state.sendDailyUpdateEmailTo && EmailTools.validateEmail(this.state.sendDailyUpdateEmailTo) == false) {
            errorState.sendDailyUpdateEmailTo = i18n.t('subscribers:settings.emailAddressError');
            hasError = true;
        }
        if (this.state.groupName.length < 3) {
            errorState.groupName = i18n.t('subscribers:settings.groupNameError');
            hasError = true;
        }
        if (this.state.defaultFromName && this.state.defaultFromName.length < 3) {
            errorState.defaultFromName = i18n.t('subscribers:settings.defaultFromNameError');
            hasError = true;
        }
        if (this.state.signupReminderText.length < 3) {
            errorState.signupReminderText = i18n.t('subscribers:settings.reminderError');
            hasError = true;
        }


        this.setState({ errorHandling: errorState });

        if (hasError === false) {

            this.setState({ saving: true })

            var theData = {

                groupName: this.state.groupName,
                defaultFromName: this.state.defaultFromName,
                defaultFromEmailAddress: this.state.defaultFromEmailAddress ? this.state.defaultFromEmailAddress.toLowerCase() : this.state.defaultFromEmailAddress,
                defaultEmailSubject: this.state.defaultEmailSubject,
                defaultReplyAddress: this.state.defaultReplyAddress ? this.state.defaultReplyAddress.toLowerCase() : this.state.defaultReplyAddress,
                sendNewSubscriberNotificationsTo: this.state.sendNewSubscriberNotificationsTo ? this.state.sendNewSubscriberNotificationsTo.toLowerCase() : this.state.sendNewSubscriberNotificationsTo,
                sendNewUnsubscribeNotificationsTo: this.state.sendNewUnsubscribeNotificationsTo ? this.state.sendNewSubscriberNotificationsTo.toLowerCase() : this.state.sendNewSubscriberNotificationsTo,
                sendDailyUpdateEmailTo: this.state.sendDailyUpdateEmailTo,
                friendlyName: this.state.friendlyName,
                signupReminderText: this.state.signupReminderText,
                hiddenFromUnsubscribe: this.state.hiddenFromUnsubscribe
            };

            axios.put('/group/' + this.state.groupID, theData)
                .then(function (response) {
                    self.setState({ alertOpen: true, alertMessage: i18n.t('subscribers:settings.saved'), saving: false });
                    self.lookupGroup(self.state.groupID)
                })
                .catch(function (error) {

                });
        }
    }

    updateSwitchField(event) {
        var state = this.state;
        state[event.target.name] = event.target.checked;
        this.setState(state);
    }

    render() {

        if (!this.state.pageLoaded) return <div></div>
        return <div>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={6} margin={0}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">{i18n.t('subscribers:settings.groupName')}</h4>
                        <UILIB.TextInput outerClassName="mar-b25" explainer={i18n.t('subscribers:settings.groupNameDesc')} type="text" placeholder={i18n.t('subscribers:settings.groupName')} name="groupName" value={this.state.groupName} onChange={this.updateTextField} error={this.state.errorHandling.groupName} />
                        <UILIB.Button className="button-primary" text={i18n.t('save')} onClick={this.saveFormChanges} saving={this.state.saving} />
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} margin={0}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">{i18n.t('subscribers:settings.friendlyName')}</h4>
                        <UILIB.TextInput outerClassName="mar-b25" explainer={i18n.t('subscribers:settings.friendlyNameDesc')} type="text" placeholder={i18n.t('subscribers:settings.friendlyName')} name="friendlyName" value={this.state.friendlyName} onChange={this.updateTextField} error={this.state.errorHandling.friendlyName} />
                        <UILIB.Button className="button-primary" text={i18n.t('save')} onClick={this.saveFormChanges} saving={this.state.saving} />
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={12} margin={0}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">{i18n.t('subscribers:settings.reminder')}</h4>
                        <UILIB.TextArea outerClassName="mar-b25" type="text" explainer={i18n.t('subscribers:settings.reminderDesc')} rows={5} placeholder={i18n.t('subscribers:settings.reminder')} name="signupReminderText" value={this.state.signupReminderText} onChange={this.updateReminderTextField} error={this.state.errorHandling.signupReminderText} iconBottomRight={this.state.reminderCharCount + "/1500"} maxLength={1500} />
                        <UILIB.Button className="button-primary" onClick={this.saveFormChanges} text={i18n.t('save')} saving={this.state.saving} />
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={6} margin={0}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">{i18n.t('subscribers:settings.defaults')}</h4>
                        <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('subscribers:settings.defaultFromName')} explainer={i18n.t('subscribers:settings.defaultFromNameDesc')} type="text" placeholder={i18n.t('subscribers:settings.defaultFromName')} name="defaultFromName" value={this.state.defaultFromName} onChange={this.updateTextField} error={this.state.errorHandling.defaultFromName} />
                        <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('subscribers:settings.defaultFromAddress')} explainer={i18n.t('subscribers:settings.defaultFromAddressDesc')} type="email" placeholder={i18n.t('subscribers:settings.defaultFromAddress')} name="defaultFromEmailAddress" value={this.state.defaultFromEmailAddress} onChange={this.updateTextField} error={this.state.errorHandling.defaultFromEmailAddress} />
                        <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('subscribers:settings.defaultReplyAddress')} explainer={i18n.t('subscribers:settings.defaultReplyAddressDesc')} type="email" placeholder="Enter an Email Subject" name="defaultReplyAddress" value={this.state.defaultReplyAddress} onChange={this.updateTextField} error={this.state.errorHandling.defaultReplyAddress} />
                        <UILIB.TextInput outerClassName="mar-b25" explainer={i18n.t('subscribers:settings.defaultSubjectDesc')} label={i18n.t('subscribers:settings.defaultSubject')} type="text" placeholder={i18n.t('subscribers:settings.defaultSubject')} name="defaultEmailSubject" value={this.state.defaultEmailSubject} onChange={this.updateTextField} error={this.state.errorHandling.defaultEmailSubject} />
                        <UILIB.Button className="button-primary" onClick={this.saveFormChanges} saving={this.state.saving}>{i18n.t('save')}</UILIB.Button>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} margin={0}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">{i18n.t('subscribers:settings.notifications')}</h4>
                        <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('subscribers:settings.newSubEmail')} explainer={i18n.t('subscribers:settings.newSubEmailDesc')} type="email" placeholder={i18n.t('subscribers:settings.newSubEmail')} name="sendNewSubscriberNotificationsTo" value={this.state.sendNewSubscriberNotificationsTo} onChange={this.updateTextField} error={this.state.errorHandling.sendNewSubscriberNotificationsTo} />
                        <UILIB.TextInput outerClassName="mar-b25" explainer={i18n.t('subscribers:settings.unsubEmailDesc')} label={i18n.t('subscribers:settings.unsubEmail')} type="email" placeholder={i18n.t('subscribers:settings.unsubEmail')} name="sendNewUnsubscribeNotificationsTo" value={this.state.sendNewUnsubscribeNotificationsTo} onChange={this.updateTextField} error={this.state.errorHandling.sendNewUnsubscribeNotificationsTo} />
                        <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('subscribers:settings.dailySummary')} explainer={i18n.t('subscribers:settings.dailySummaryDesc')} type="email" placeholder={i18n.t('subscribers:settings.dailySummary')} name="sendDailyUpdateEmailTo" value={this.state.sendDailyUpdateEmailTo} onChange={this.updateTextField} error={this.state.errorHandling.sendDailyUpdateEmailTo} />
                        <UILIB.Button className="button-primary" onClick={this.saveFormChanges} text={i18n.t('save')} saving={this.state.saving} />
                    </UILIB.Paper>

                    <UILIB.Paper>
                        <h4 className="mar-b25">{i18n.t('subscribers:settings.otherSettings')}</h4>
                        <UILIB.Toggle outerClassName="mar-b25" explainer={i18n.t('subscribers:settings.groupWontBeShowToUnsubs')} label={i18n.t('subscribers:settings.hiddenFromUnsubscribers')} name="hiddenFromUnsubscribe" value={this.state.hiddenFromUnsubscribe} onChange={this.updateSwitchField} />
                        <UILIB.Button className="button-primary" onClick={this.saveFormChanges} text={i18n.t('save')} saving={this.state.saving} />
                    </UILIB.Paper>



                </UILIB.Column>
            </UILIB.Row>
            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />

        </div >
    };
};
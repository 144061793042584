// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A7ZRy0jJ8_4GsKnpMQch{font-size:14px;font-weight:500;line-height:normal;margin-bottom:2px}.mpoMYo5L_eVPV8_2ovAm{font-size:13px;line-height:normal;color:var(--grey-800);margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/inboxSettings/channels/components/addChannelDialog/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,kBAAA,CACA,iBAAA,CAGF,sBACE,cAAA,CACA,kBAAA,CACA,qBAAA,CACA,eAAA","sourcesContent":[".radioHeading {\n  font-size: 14px;\n  font-weight: 500;\n  line-height: normal;\n  margin-bottom: 2px;\n}\n\n.radioSubheading {\n  font-size: 13px;\n  line-height: normal;\n  color: var(--grey-800);\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioHeading": "A7ZRy0jJ8_4GsKnpMQch",
	"radioSubheading": "mpoMYo5L_eVPV8_2ovAm"
};
export default ___CSS_LOADER_EXPORT___;

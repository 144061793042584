import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import emojis from "~/Classes/emojis";
import siteVars from "~/Classes/siteVars";
import { alterSiteDrawer } from "~/data/actions/siteActions";
import FileManager from "../../../../../includes/fileManager/fileManager";
import axios from "~/data/http/axios";
import LoadingIcons from "~/Common-Objects/LoadingIcons/loadingIcon1";
import DataTable1 from "~/Common-Objects/Tables/DataTable1/DataTable1";
import i18n from "~/i18n";

export default function Composer({
  editorDisabled = false,
  message = "",
  mergeTags = "",
  openMergeTags = false,
  setOpenMergeTags = () => { },
  setMessage = () => { },
  setEditorRef = () => { },
  typing = () => { },
  sendMessage = () => { }
}) {

  const editorRef = useRef(null);
  const dispatch = useDispatch();

  const handleKeyPress = useCallback((event) => {
    // Check if Ctrl (or Command on Mac) and Enter keys are pressed simultaneously
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      // Handle the Ctrl+Enter key press
      sendMessage();
    }
  }, [message]);

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('keydown', handleKeyPress);
    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (mergeTags) {
      if (openMergeTags) {
        onOpenMergeTags()
      } else {
        dispatch(alterSiteDrawer(false, false))
      }
    }
  }, [openMergeTags])

  useEffect(() => {
    init();
  });

  const init = () => {
    setEditorRef(editorRef);
  };

  const handleEditorCommand = (e) => {
    if (e.command === 'mpzFile') {
      const drawer = <FileManager fileTypes="images" onFileClick={r => {
        editorRef.current.editorCommands.execCommand('mceInsertContent', false, `<img src="${r.url}" width="100%"/>`)
        dispatch(alterSiteDrawer(false, false))
      }} />
      dispatch(alterSiteDrawer(true, true, 'right', drawer, true, '100vw', 'fix-height'))
    } else if (e.command === 'mpzMergeTags') {
      openMergeTags()
    }
  }

  const onOpenMergeTags = () => {
    const drawer = <MergeTags type="signature" onTagClick={v => {
      editorRef.current.editorCommands.execCommand('mceInsertContent', false, v)
      setOpenMergeTags(false)
    }} />
    dispatch(alterSiteDrawer(true, true, 'right', drawer, true, 500))
  }

  let editorDivStyle = {
    height: "100%",
    maxHeight: "500px",
    overflow: "auto"
  }
  if (editorDisabled) editorDivStyle.opacity = 0.5;
  let toolbar = "bold italic underline link | bullist numlist | formatselect";
  if (mergeTags) toolbar = "bold italic underline link | bullist numlist | formatselect";
  return (
    <>
      {(!editorDisabled) && <><Editor
        onInit={(_, editor) => (editorRef.current = editor)}
        init={{
          skin: 'dark',
          inline: true,
          menubar: false,
          browser_spellcheck: true,
          force_br_newlines: true,
          force_p_newlines: false,
          convert_urls: true,
          forced_root_block: '',
          plugins: ["autolink lists link charmap paste image"],
          selector: "#conversationsEditorDiv",
          toolbar: toolbar,
          paste_as_text: true,
          emojiData: emojis,
          imagetools_cors_hosts: [''],
          imagetools_proxy: siteVars.repoEndPoint.replace('https://', '').replace('http://', ''),
          imagetools_toolbar: "imageoptions",
          init_instance_callback: function (editor) {
            function showHideToolBar(e) {
              const tb = editor.theme.panel;
              if (!tb) return;
              var selectedText = editor.selection.getContent({ format: 'text' });
              if (selectedText && selectedText.length > 0) {
                tb.show();
                let tbEle = tb["$el"];
                if (tbEle) {
                  tbEle = tbEle[0];
                  let tbEleWidth = tbEle.style.width.replace("px", "");
                  if (!tbEleWidth) tbEleWidth = 350;
                  tbEle.style.top = (e.clientY - 70) + "px";
                  tbEle.style.left = (e.clientX - (tbEleWidth / 2)) + "px";
                }
              }
              else {
                tb.hide();
              }
            }
            let mousedown = false
            //catch ctrl + mac key plus enter
            editor.shortcuts.add('ctrl+13', '', () => {
            });
            editor.shortcuts.add('meta+13', '', () => {
            });

            editor.on('click', (e) => { showHideToolBar(e) });
            editor.on('focus', (e) => { showHideToolBar(e) });

            editor.on('mouseleave', e => {
              if (mousedown) showHideToolBar(e)
            })
            editor.on('keyUp', function (e) {
              setMessage(editor.getContent());
              typing();
              showHideToolBar(e);
            });
            editor.on('change', function (e) {
              setMessage(editor.getContent());
              showHideToolBar(e);
            });
          }
        }}
        onExecCommand={handleEditorCommand}
        initialValue={message}
        disabled={editorDisabled}
      />
        <div
          id="conversationsEditorDiv"
          data-placeholder={i18n.t("chat:inbox.conversation.composer.placeholder")}
          style={editorDivStyle}
        />
      </>}
    </>
  );
}


function MergeTags({ onTagClick = () => { }, type = "" }) {
  const [mergeTags, setMergeTags] = useState(null)

  useEffect(() => {
    axios.get('/mergeTags?type=' + type).then(res => {
      setMergeTags(res.data.MergeTags)
    })
  }, [type])

  if (!mergeTags) return <LoadingIcons />

  return (
    <>
      <h4 className="mar-b25">{i18n.t("chat:inbox.conversation.composer.insertMerge")}</h4>
      {<DataTable1
        tableData={mergeTags.map(tag => {
          return [{
            headerValue: " ",
            value: <a onClick={() => onTagClick(tag.tag)}>{tag.tag}</a>
          },
          {
            headerValue: " ",
            value: <div style={{ whiteSpace: "pre-wrap" }}>{tag.description}</div>
          }]
        })}
      />}
    </>

  )

}
import React from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import i18n from '~/i18n'

const placeholder = [{
    name: '1',
    value: 25
}, {
    name: '2',
    value: 25
}, {
    name: '3',
    value: 25
}, {
    name: '4',
    value: 25
}]

@connect((store) => { return { filters: store.filters, siteMaster: store.siteMaster } })


export default class CampaignPieGraph extends React.Component {
    constructor(props) {
        super(props);
        var siteColours = this.props.siteMaster.colours;

        this.state = {
            source: this.props.source,
            pageLoaded: false,
            stat: this.props.stat,
            unique: false,
            startDate: '',
            endDate: '',
            colors: [
                siteColours.$primary,
                siteColours.$secondary,
                siteColours['$status-red'],
                siteColours['$status-yellow'],
                siteColours['$status-green'],
                siteColours['$status-orange'],
                siteColours['$status-blue']
            ],
            graphData: []
        };
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.getStats()
    }

    componentDidUpdate(prevProps) {
        var refresh = false;
        if (this.props.stat !== this.state.stat) refresh = true;
        if (this.props.startDate !== this.state.startDate) refresh = true;
        if (this.props.endDate !== this.state.endDate) refresh = true;
        if (refresh) {

            this.setState({ stat: this.props.stat, startDate: this.props.startDate, endDate: this.props.endDate }, this.loadData)
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getStats() {
        this.loadData();
        this.timer = setInterval(this.loadData, 5000)
    }

    loadData() {
        var self = this;
        var queryStr = '?ignoreLog=1&stat=' + this.state.stat + '&source=' + this.state.source;
        axios.get('/group/' + this.props.groupId + '/form/' + this.props.formId + '/pie' + queryStr)
            .then(response => {
                var graphData = []
                response.data.Stats.forEach(row => {
                    graphData.push({
                        name: row.str,
                        value: parseInt(row.count)
                    })
                })

                this.setState({
                    graphData: graphData,
                    pageLoaded: true
                })
            })
            .catch(console.log)
    }


    render() {

        if (this.state.pageLoaded) {
            return <div className={this.props.className}>

                <h4 className="mar-b25" style={{ textTransform: "capitalize" }}>{this.state.stat} - {this.props.friendlyName}</h4>

                {!!this.state.graphData.length && <ResponsiveContainer height={300}>
                    <PieChart
                        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                        <Pie dataKey="value" nameKey="name" data={this.state.graphData} innerRadius={95} >
                            {this.state.graphData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={this.state.colors[index]} />
                            ))}
                        </Pie>
                        {/* <Legend verticalAlign="bottom" iconType="circle" iconSize={11} /> */}
                        <Legend verticalAlign="bottom" iconType="circle" iconSize={11} content={UILIB.CustomPieLegend} />
                        <Tooltip content={<UILIB.CustomTooltip overrideColor={true} />} />
                    </PieChart>
                </ResponsiveContainer>}
                {!this.state.graphData.length && <div style={{ opacity: 0.2 }}>
                    <ResponsiveContainer height={300}>
                        <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                            <Pie dataKey="value" nameKey="name" data={placeholder} innerRadius={95} >
                                {placeholder.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={this.state.colors[index]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>}
                {!this.state.graphData.length && <div style={{ position: 'absolute', height: '100%', width: '100%', top: '50%', left: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 20 }}>
                    No Data Available
                    <div style={{ fontSize: 14, marginTop: 10, textAlign: 'center' }}>There is no form activity</div>
                </div>}

            </div>
        }
        else {
            return <div className="loading">{i18n.t('loading')}</div>
        }
    }
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'

export default class addManualServer extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            name: '',
            host: '',
            port: 25,
            user: '',
            pass: '',
            sshPassword: '',
            sshUsername: '',
            errors: {
                name: "",
                host: "",
                port: "",
                user: "",
                pass: "",
                sshPassword: "",
                sshUsername: ""
            }
        }

        this.confirm = this.confirm.bind(this);
        this.updateField = this.updateField.bind(this);
        this.updateBoolField = this.updateBoolField.bind(this)
    }

    componentWillMount() {

    }



    confirm() {

        let errors = {
            name: "",
            host: "",
            port: "",
            user: "",
            pass: "",
            sshPassword: "",
            sshUsername: ""
        }

        let errored = false;
        if (!this.state.name || this.state.name.length < 5) {
            errored = true;
            errors.name = "Please enter a long server name";
        }
        if (!this.state.host) {
            errored = true;
            errors.name = "Please enter a valid host ip";
        }
        if (!this.state.port) {
            errored = true;
            errors.name = "Please enter a valid port";
        }

        if (errored) {
            this.setState({ errors })
            return;
        }

        var obj = {
            name: this.state.name,
            host: this.state.host,
            port: this.state.port,
            sshUsername: this.state.sshUsername,
            sshPassword: this.state.sshPassword,
            user: this.state.user,
            pass: this.state.pass
        }

        axios.post('/mailServer/add/manual', obj).then((res) => {
            this.props.close();
        }).catch(err => {
            this.props.close();
        });
    }

    updateField(ev, ev2) {

        this.setState({
            [ev.target.name]: ev.target.value
        })
    }

    updateBoolField(ev) {
        this.setState({
            [ev.currentTarget.name]: ev.currentTarget.checked
        })
    }

    render() {

        return <div>

            <h4 className="mar-b25">Add your own Mail Server</h4>
            <div className="mar-b25">Please enter the details for an email server you already own or have access to.</div>

            <UILIB.TextInput label="Server DNS Name" outerClassName="mar-b25" value={this.state.name} onChange={this.updateField} name="name" placeholder="Mail Server Name" error={this.state.errors.name} />
            <UILIB.TextInput label="IP Address" outerClassName="mar-b25" type="text" value={this.state.host} onChange={this.updateField} name="host" placeholder="xxx.xxx.xxx.xxx" error={this.state.errors.host} />
            <UILIB.TextInput label="Por" outerClassName="mar-b25" type="number" value={this.state.port} onChange={this.updateField} name="port" placeholder="22" error={this.state.errors.port} />
            <UILIB.TextInput label="Username (Optional)" outerClassName="mar-b25" type="text" value={this.state.user} onChange={this.updateField} name="user" placeholder="Leave Blank for no Auth" />
            <UILIB.TextInput label="Password (Optional)" outerClassName="mar-b25" type="text" value={this.state.pass} onChange={this.updateField} name="pass" placeholder="" />
            <UILIB.TextInput label="SSH Username (Optional)" outerClassName="mar-b25" type="text" value={this.state.sshUsername} onChange={this.updateField} name="sshUsername" placeholder="" />
            <UILIB.TextInput label="SSH Password (optional)" outerClassName="mar-b25" type="text" value={this.state.sshPassword} onChange={this.updateField} name="sshPassword" placeholder="" />


            <UILIB.Button text="Confirm" className="button-primary" onClick={this.confirm} />

        </div >
    }
};


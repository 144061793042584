import { init as initApm } from '@elastic/apm-rum'
import siteVars from './siteVars'

export default class Rum {
    static init() {
        if (siteVars.apm) {
            const apm = initApm({
                serviceName: 'transpond_ui',
                serverUrl: siteVars.apm,
                environment: siteVars.stripeKey.indexOf('_live_') > -1 ? 'production' : 'development',
                serviceVersion: siteVars.uiVersion + ""
            })
            document.apm = apm
        }
    }

    static trackEvent(event) {
        if (document.apm) {
            const transaction = document.apm.startTransaction('tracking_event', 'custom')
            transaction.addLabels({ tracking: true, event })
            setTimeout(() => {
                transaction.end()
            }, 1000)
        }
    }

    static setUserContext(id) {
        if (document.rum) {
            document.rum.setUserContext({ id })
        }
    }

    static addLabelsTransaction(labels) {
        if (document.apm) {
            const transaction = document.apm.getCurrentTransaction()
            if (transaction) {
                transaction.addLabels(labels)
            }
        }
    }

    static addLabelsGlobal(labels) {
        if (document.apm) {
            document.apm.addLabels(labels)
        }
    }
}
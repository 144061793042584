import React, { useState } from "react";
import TextInput from "../../../../components/textInput";
import AlertDialog from "../../../../components/alertDialog";
import i18n from "~/i18n";

export default function DeleteChannelDialog({
  channel,
  isOpen,
  onSubmit,
  disabled = false,
  ...rest
}) {
  const [deleteChannelName, setDeleteChannelName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  const needsConfirmation = channel?.status != "draft";

  async function handleSubmit() {
    setSubmitting(true);

    try {
      await validateForm();
      await onSubmit(channel);
    } finally {
      setSubmitting(false);
    }
  }

  async function validateForm() {
    try {

      if (needsConfirmation && (deleteChannelName !== channel.name)) {
        throw i18n.t("chat:settings.channels.deleteDialog.invalid")
      }

      setFormErrors(null);
    } catch (error) {
      setFormErrors({ deleteChannelName: error });
      throw error;
    }
  }


  if (!channel) return null;
  return (
    <AlertDialog
      open={isOpen}
      title={needsConfirmation ? i18n.t("chat:settings.channels.deleteDialog.header") : i18n.t("chat:settings.channels.deleteDialog.headerNoConfirmation")}
      description={i18n.t("chat:settings.channels.deleteDialog.description", { name: channel?.name })}
      ctaText={needsConfirmation ? i18n.t("chat:settings.channels.deleteDialog.confirm") : i18n.t("chat:settings.channels.deleteDialog.confirmNoConfirmation")}
      submitting={submitting}
      onCtaClick={handleSubmit}
      disabled={disabled}
      {...rest}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {needsConfirmation && <TextInput
          label={i18n.t("chat:settings.channels.deleteDialog.name")}
          value={deleteChannelName}
          disabled={submitting}
          // Issue with autofocus when opening dialog from a dropdown https://github.com/radix-ui/primitives/issues/1615
          autoFocus
          required
          onChange={(e) => setDeleteChannelName(e.target.value)}
          error={formErrors?.deleteChannelName}
        />}
      </form>
    </AlertDialog>
  );
}

import React from 'react'
import FormatNumberFuncs from '../../Classes/numberFormatFunctions';

export default function CustomTooltip({ active, payload, name, label, classes = "", showLabel = false, overrideColor = false, followColor = false, custom = {} }) {
    if (active && payload && payload.length) {
        return (
            <div className={"chart-custom-tooltip " + classes}>
                {showLabel && <div className='chart-custom-tooltip-header'>{label}</div>}
                <ul>
                    {payload.map((row, i) => {
                        const style = {}
                        if (overrideColor) {
                            style.color = payload[0].payload.fill
                        }
                        if (followColor) {
                            style.color = payload[i].color
                        }
                        return <li key={i} className={"chart-custom-tooltip-label chart-custom-tooltip-label-" + i} style={style}>
                            {!name && `${payload[i].name}: `} {`${FormatNumberFuncs.formatNumber(row.value)} ${Array.isArray(name) ? name[i] : name || ''}`}
                        </li>
                    })}
                </ul>
                {custom[label]}
            </div>
        );
    }

    return null;
};
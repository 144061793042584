import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'
import get from 'lodash/get'
import DragDropFunctions from '../../../dragDropFunctions';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import moment from 'moment';
import MPZLinkPicker from './linkPickerComponent.jsx'


//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class ComponentText extends Component {
    constructor(props) {
        super(props)
        this.change = this.change.bind(this);
        this.getValue = this.getValue.bind(this);
        this.openLinkPicker = this.openLinkPicker.bind(this);
        this.hideExternalPickers = this.hideExternalPickers.bind(this);
        this.pickNewLink = this.pickNewLink.bind(this);
        this.toggleHidePickers = this.toggleHidePickers.bind(this);

        this.state = {
            value: "",
            showLinkPicker: false
        }
    }

    componentDidMount() {
        const el = document.getElementById("ourInput_" + this.props.path)
        if (el) el.addEventListener('focusout', (event) => {
            this.elementDidBlur(event)
        })
        this.getValue();
    }
    componentWillUnmount() {
        const el = document.getElementById("ourInput_" + this.props.path)
        if (el) el.removeEventListener('focusout', this.elementDidBlur);
    }

    getValue() {
        var thisValue = this.props.value;
        if (this.props.elementData.linkedToCustomField) {
            var linkedFieldLocation = this.props.elementData.linkedToCustomField.replace("_localVariables_.", "");
            //get the current custom field

            var ourCustomFieldName = get(this.props.elementsData, this.props.selectedElement.path).localVariables[linkedFieldLocation].value;

            //lookup the custom field in the custom fields
            var ourCustomField = DragDropFunctions.customFields[ourCustomFieldName];
            if (ourCustomField && ourCustomField.type && ourCustomField.type == "BOOLEAN") {
                // fieldTypeProps.type = "number";
                // fieldTypeProps.min = 0;
                // fieldTypeProps.max = 1;
                // fieldTypeProps.placeholder = "1"
                // fieldTypeProps.readOnly = 'readonly'
                thisValue = 1;
            }
            // if (ourCustomField && ourCustomField.type && ourCustomField.type == "DATE") {
            //     fieldTypeProps.type = "date";
            //     fieldTypeProps.placeholder = "yyyy-mm-dd"
            // }
            // if (ourCustomField && ourCustomField.type && ourCustomField.type == "TIME") {
            //     fieldTypeProps.type = "time";
            //     fieldTypeProps.placeholder = "00:00:00"
            // }
            // if (ourCustomField && ourCustomField.type && ourCustomField.type == "NUMBER") {
            //     fieldTypeProps.type = "number";
            //     fieldTypeProps.placeholder = "0"
            // }
        }
        this.setState({ value: thisValue })
    }
    elementDidBlur(event) {

        var ourFieldSubType = (this.props.elementData && this.props.elementData.editableSubType ? this.props.elementData.editableSubType : "text").toLowerCase();
        var ourVal = event.currentTarget.value;
        if (ourFieldSubType == "link" || this.props.inputType == "link") {

            if (ourVal && ourVal.length > 3 && ourVal.indexOf('http://') < 0 && ourVal.indexOf('https://') < 0 && ourVal.indexOf("mailto:") < 0 && ourVal.indexOf('{{') !== 0) {
                if (ourVal.indexOf('@') > 0) {
                    ourVal = 'mailto://' + ourVal;
                }
                else {
                    ourVal = 'http://' + ourVal;
                }
            }
        }
        this.setState({ value: ourVal });
        this.props.updateVal(this.props.path, ourVal);

    }

    change(event, date) {
        var thisVal = event ? event.currentTarget.value : date;
        if (typeof thisVal === 'string') {
            // remove xss tags
            thisVal = thisVal.replace(/</g, "&lt;").replace(/>/g, "&gt;");
        }
        this.setState({ value: thisVal })
        if (!event && date) {
            this.props.updateVal(this.props.path, thisVal);
        }
    }



    openLinkPicker() {
        this.setState({ showLinkPicker: true })
    }
    hideExternalPickers() {
        this.setState({ showLinkPicker: false })
    }

    pickNewLink(value) {
        this.setState({ value: value, showLinkPicker: false })
        this.props.updateVal(this.props.path, value);
    }
    toggleHidePickers() {

    }
    render() {

        //if this element is linked to a custom field, lookup the custom field type.
        //if the custom field type is BOOL do something

        var ourFieldSubType = (this.props.elementData && this.props.elementData.editableSubType ? this.props.elementData.editableSubType : "text").toLowerCase();

        var fieldTypeProps = {
            type: "text",
            placeholder: this.props.title
        }

        let fieldValue = this.state.value;
        if (ourFieldSubType) {
            if (ourFieldSubType == "time") {
                fieldTypeProps.type = "time";
                fieldTypeProps.placeholder = "00:00:00";
            }
            if (ourFieldSubType == "date") {
                fieldTypeProps.type = "date";
                fieldTypeProps.placeholder = "yyyy/mm/dd";
                if (!moment(fieldValue).isValid()) fieldValue = ""
            }
            if (ourFieldSubType == "datetime") {
                fieldTypeProps.type = "datetime";
                fieldTypeProps.dateFormat = 'YYYY/MM/DD'
                fieldTypeProps.placeholder = "yyyy/mm/dd 00:00";
                if (!moment(fieldValue).isValid()) fieldValue = ""

            }
        }

        if (this.props.elementData.inputPlaceHolder) fieldTypeProps.placeholder = this.props.elementData.inputPlaceHolder;


        var inputStyle = {}
        if (ourFieldSubType == "link" || this.props.inputType == "link") {
            inputStyle.cursor = "pointer"
        }

        var style = {};
        if (this.props.elementData.optionFullWidth) style = { flex: "100%" }

        return <div className="dd_toolHolder" style={style}>
            <div className="dd_inputTool">

                <UILIB.TextInput
                    label={this.props.title}
                    {...fieldTypeProps}
                    value={fieldValue}
                    onChange={ourFieldSubType != "link" ? this.change : this.openLinkPicker}
                    onClick={ourFieldSubType != "link" ? this.change : this.openLinkPicker}
                    style={inputStyle}
                    readOnly={ourFieldSubType == "link"}
                    id={"ourInput_" + this.props.path}
                />

            </div>


            {(this.state.showLinkPicker == true) && <div style={{ position: "absolute", top: "45px", right: "10px" }}>
                <MPZLinkPicker save={this.pickNewLink} currVal={this.state.value} close={this.hideExternalPickers} toggleHidePickers={this.toggleHidePickers} elementsData={this.props.elementsData} />
            </div>}

        </div>


    }
}



// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CqDSySDdUNW1B0d48z2A label button:active{pointer-events:none}.CqDSySDdUNW1B0d48z2A label button::after{content:\"\";position:absolute;top:-1px;bottom:-1px;left:-1px;right:-1px;border:2px solid rgba(0,0,0,0);transition:border-color .2s ease;border-radius:inherit}.CqDSySDdUNW1B0d48z2A input:checked+label button::after{border-color:var(--purple-700)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/components/radioButton/index.module.scss"],"names":[],"mappings":"AAEI,0CAGE,mBAAA,CAGF,0CACE,UAAA,CACA,iBAAA,CACA,QAAA,CACA,WAAA,CACA,SAAA,CACA,UAAA,CACA,8BAAA,CACA,gCAAA,CACA,qBAAA,CAIJ,wDACE,8BAAA","sourcesContent":[".root {\n  label button {\n    &:active {\n      // Prevent the button from capturing the click and instead allow the label wrapper to do its thing.\n      // Using :active to still allow the button to have a hover state.\n      pointer-events: none;\n    }\n\n    &::after {\n      content: \"\";\n      position: absolute;\n      top: -1px;\n      bottom: -1px;\n      left: -1px;\n      right: -1px;\n      border: 2px solid transparent;\n      transition: border-color 0.2s ease;\n      border-radius: inherit;\n    }\n  }\n\n  input:checked + label button::after {\n    border-color: var(--purple-700);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CqDSySDdUNW1B0d48z2A"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";
import i18n from "~/i18n";

export default function InboxStatusDropdownMenu({
  conversationsStatusFilter = "unassigned",
  setConversationsStatusFilter = () => { },
  activeInbox = { name: "", unassignedChats: 0, mineChats: 0, assignedChats: 0 },
  disabled = false,
  aiEnabled = false,

}) {
  let statusCount
  let stats = activeInbox.stats || {};
  if (conversationsStatusFilter == "unassigned") statusCount = stats.unassignedChats || 0;
  if (conversationsStatusFilter == "mine") statusCount = stats.mineChats || 0;
  if (conversationsStatusFilter == "assigned") statusCount = stats.assignedChats || 0;
  if (conversationsStatusFilter == "ai") statusCount = stats.aiChats || 0;
  if (conversationsStatusFilter == "closed") statusCount = stats.closedChats || 0;
  if (conversationsStatusFilter == "spam") statusCount = stats.spamChats || 0;

  return (
    <UILIB.DropdownMenu.Root>
      <UILIB.DropdownMenu.Trigger
        disabled={disabled}
        variant="ghost"
        size="s"
        className={styles.trigger}
      >
        {statusCount > 0 ? statusCount : ""} <span style={{ textTransform: "capitalize" }} >{conversationsStatusFilter}</span>
      </UILIB.DropdownMenu.Trigger>

      <UILIB.DropdownMenu.Portal>
        <UILIB.DropdownMenu.Content align="start">
          <UILIB.DropdownMenu.Label>{i18n.t("chat:inbox.leftBar.statusDropdown.status")}</UILIB.DropdownMenu.Label>

          <UILIB.DropdownMenu.RadioGroup value={conversationsStatusFilter} onValueChange={setConversationsStatusFilter}>
            <UILIB.DropdownMenu.RadioItem value="unassigned" >
              {i18n.t("chat:inbox.leftBar.statusDropdown.unassigned")} {(stats.unassignedChats > 0) && <UILIB.SquareChip>{stats.unassignedChats}</UILIB.SquareChip>}
            </UILIB.DropdownMenu.RadioItem>

            <UILIB.DropdownMenu.RadioItem value="mine">
              {i18n.t("chat:inbox.leftBar.statusDropdown.mine")} {(stats.mineChats > 0) && <UILIB.SquareChip>{stats.mineChats}</UILIB.SquareChip>}
            </UILIB.DropdownMenu.RadioItem>

            <UILIB.DropdownMenu.RadioItem value="assigned">
              {i18n.t("chat:inbox.leftBar.statusDropdown.assigned")} {(stats.assignedChats > 0) && <UILIB.SquareChip>{stats.assignedChats}</UILIB.SquareChip>}
            </UILIB.DropdownMenu.RadioItem>

            {aiEnabled && <UILIB.DropdownMenu.RadioItem value="ai">
              {i18n.t("chat:inbox.leftBar.statusDropdown.ai")} {(stats.aiChats > 0) && <UILIB.SquareChip>{stats.aiChats}</UILIB.SquareChip>}
            </UILIB.DropdownMenu.RadioItem>}

            <UILIB.DropdownMenu.RadioItem value="closed">
              {i18n.t("chat:inbox.leftBar.statusDropdown.closed")}   {(stats.closedChats > 0) && <UILIB.SquareChip>{stats.closedChats}</UILIB.SquareChip>}
            </UILIB.DropdownMenu.RadioItem>

            <UILIB.DropdownMenu.RadioItem value="spam">
              {i18n.t("chat:inbox.leftBar.statusDropdown.spam")}   {(stats.spamChats > 0) && <UILIB.SquareChip>{stats.spamChats}</UILIB.SquareChip>}
            </UILIB.DropdownMenu.RadioItem>

          </UILIB.DropdownMenu.RadioGroup>

        </UILIB.DropdownMenu.Content>
      </UILIB.DropdownMenu.Portal>
    </UILIB.DropdownMenu.Root >
  );
}

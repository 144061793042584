import React, { Component } from 'react';
import queryString from 'query-string';

import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import DateTimeFunctions from '~/Classes/dateTimeFunctions'
import i18n from '~/i18n'
import { connect } from 'react-redux';
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
const Map = ReactMapboxGl({
    accessToken: "pk.eyJ1IjoiY29udGFjdGdhdGUiLCJhIjoiODI5ZDJlOWNiNGM5YTM4OGM1Mzk2ODA2MjI4MTI2YmQifQ.iEJBZeGfK-7O2nOkPxIBDA"
});

import SelectGroup from './selectGroup'
import AddIntegrationDrawer from '~/pages/cp/company/integrations/addApp/'

@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})
class SubscriberWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriber: undefined,
            integration: undefined,
            loaded: false,
            error: false,
            statsLoaded: false,
            importProgress: 0,
        }

        this.getSubscriber = this.getSubscriber.bind(this)
        this.getApplication = this.getApplication.bind(this)
        this.getStats = this.getStats.bind(this)
        this.resync = this.resync.bind(this)
        this.addIntegration = this.addIntegration.bind(this)
        this.checkForImportUpdate = this.checkForImportUpdate.bind(this)
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        this.setState(query, this.getApplication)
    }

    componentWillUnmount() {
        this.setState({
            unloading: true
        })
    }

    checkForImportUpdate() {
        if (this.state.unloading) return;
        var self = this;
        axios.get('/group/' + this.state.groupId + '/import/' + this.state.importId).then(res => {
            self.setState({ importProgress: res.data.PercentComplete })
            if (res.data.PercentComplete < 100) {
                this.theTimer = setTimeout(this.checkForImportUpdate, 1000);
            }
            else {
                self.setState({ importing: false, importProgress: 100 }, this.getSubscriber)
            }
        })
    }

    getSubscriber() {
        this.setState({
            loaded: false
        })
        var queryStr = '?'
        if (this.state.app) {
            queryStr += 'application=' + this.state.app
        }
        axios.get('/widget/subscriber/' + this.props.match.params.id + queryStr).then(response => {
            this.setState({
                subscriber: response.data,
                loaded: true,
                error: false
            }, this.getStats)
        }).catch(err => {
            this.setState({
                error: true,
                loaded: true
            })
        })
    }

    getApplication() {
        if (!this.state.app) return this.getSubscriber()
        axios.get('/accountMaster/application').then(response => {
            var app = response.data.Applications.find(a => a.ApplicationId == this.state.app)
            this.setState({
                integration: app,
                error: false
            }, this.getSubscriber)
        }).catch(err => {
            this.setState({
                error: true,
                loaded: true
            })
        })
    }

    getStats() {
        axios.get('/subscriber/' + this.state.subscriber.id + '/stats').then(response => {
            var updateObj = {
                stats: response.data.Stats,
                statsLoaded: true,
                sends: response.data.Stats.sends
            }
            if (response.data.Stats.geolocation) {
                updateObj.geo = response.data.Stats.geolocation
            }
            this.setState(updateObj, this.renderStats);
        })
    }

    addIntegration() {
        var drawer = <AddIntegrationDrawer appId={this.state.app} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, false, '100vw', 'full'));
    }

    resync() {
        var app = this.state.integration
        var self = this;
        function startSync(groupId) {
            axios.post('/group/' + groupId + '/import/application/' + app.id).then(response => {
                var id = response.data.id;
                self.setState({
                    groupId,
                    importId: id,
                    importing: true
                }, self.checkForImportUpdate)
            })
        }
        if (app.appSettings.groupId) {
            startSync(app.appSettings.groupId)
        } else {
            var drawerContent = <SelectGroup groups={this.state.groups} finish={startSync} />;
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
        }
    }

    render() {
        if (!this.state.loaded) return <div className="mar-t30"><UILIB.LoadingIcons iconType="2" /></div>
        if (this.state.error) {
            return <UILIB.Row>
                <UILIB.Column xs={12} sm={8} md={6} className="col-sm-offset-2 col-md-offset-3 mar-t30">
                    <UILIB.Paper className="center-xs">
                        {this.state.integration && <div>
                            <h4>Subscriber Not Found</h4>
                            {!this.state.importing && <p>You can resync your data between Transpond and {this.state.integration.Application.appName} by clicking <a onClick={this.resync} className="button button-xs primary">here</a></p>}
                            {this.state.importing && <div style={{ marginTop: "10px" }}><p>Performing Sync</p> <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.importProgress} val2={100} /></div>}
                        </div>}
                        {!this.state.integration && <div>
                            <h4>Integation Not Found</h4>
                            <p>You can setup your integration with Transpond by clicking <a onClick={this.addIntegration} className="button button-xs primary">here</a></p>
                        </div>}
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
        }

        if (this.state.statsLoaded) {
            var opens = this.state.sends.filter(s => s.openCount).length;
            var clicks = this.state.sends.filter(s => s.clickCount).length;
        }
        var colours = this.props.siteMaster.colours;
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} sm={8} md={6} className="col-sm-offset-2 col-md-offset-3">
                        <UILIB.Paper>

                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={12} md={6} lg={6}>
                                    <div className="subscriber-name">
                                        <p>{this.state.subscriber.emailAddress}</p>
                                    </div>
                                    <div className="date-added">
                                        {i18n.t('subscribers:subscriber.firstAdded')} {DateTimeFunctions.formatDateTime(this.state.subscriber.createdAt)}
                                    </div>
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={6} lg={6} className="align-right mar-b0">
                                    <div className="quickFlex" style={{ justifyContent: 'flex-end' }}>
                                        <UILIB.Score score={this.state.subscriber.score}></UILIB.Score>

                                        <UILIB.Button className="button-sml outline primary mar-b10" onClick={this.downloadAllData}>{i18n.t('subscribers:subscriber.download')}</UILIB.Button>
                                    </div>
                                </UILIB.Column>

                                <UILIB.Column xs={12} sm={12} md={12} lg={12} className="col-b30">
                                    {this.state.subscriber.GroupSubscribers.map(gs => {
                                        var group = gs.Group
                                        if (group.blackListGroup) return;
                                        var hasDeleteButton = false;
                                        if (this.state.subscriber.GroupSubscribers.length > 1) {
                                            hasDeleteButton = true;
                                        }
                                        var className = 'square-chip-green';
                                        var tooltip = "Active";
                                        if (!gs.active) {
                                            className = 'square-chip-yellow';
                                            tooltip = "Not Active";
                                        }
                                        if (gs.unsubscribe) {
                                            className = 'square-chip-orange';
                                            tooltip = "Unsubscribed";
                                        };
                                        if (this.state.subscriber.bounce || this.state.subscriber.blackListed) {
                                            className = 'square-chip-red';
                                            tooltip = this.state.subscriber.bounce ? "Bounced" : "Denylisted";
                                        };
                                        return <UILIB.SquareChip key={"GH_" + group.id} tooltip={tooltip} for={group.groupName} value={<span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => { this.props.history.push('/cp/groups/' + group.id) }}>{group.groupName}</span>} hasButton={hasDeleteButton} className={className} buttonAction={() => { this.deleteGroupConfirm(group) }} />
                                    })}

                                    {this.state.subscriber.blackListed && <UILIB.SquareChip key={"BGH"} tooltip="Denylisted" value={<span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => { this.props.history.push('/cp/groups/' + this.props.accountMaster.accountMaster.blackListGroupId) }}>Denylist</span>} hasButton={false} className="square-chip-black" />}
                                </UILIB.Column>
                                <UILIB.Column xs={4} sm={4} md={4} lg={4}>
                                    <p>Last Sent: <strong>{this.state.subscriber.lastSendDate ? DateTimeFunctions.formatDateTime(this.state.subscriber.lastSendDate) : 'Never'}</strong></p>
                                </UILIB.Column>
                                <UILIB.Column xs={4} sm={4} md={4} lg={4}>
                                    <p>Last Opened: <strong>{this.state.subscriber.lastOpened ? DateTimeFunctions.formatDateTime(this.state.subscriber.lastOpened) : 'Never'}</strong></p>
                                </UILIB.Column>
                                <UILIB.Column xs={4} sm={4} md={4} lg={4}>
                                    <p>Last Clicked: <strong>{this.state.subscriber.lastClicked ? DateTimeFunctions.formatDateTime(this.state.subscriber.lastClicked) : 'Never'}</strong></p>
                                </UILIB.Column>
                                {!this.state.statsLoaded && <UILIB.Row>
                                    <UILIB.Column xs={12} className="center-xs"><h2 className="loading">Loading Stats</h2></UILIB.Column>
                                </UILIB.Row>}
                                {this.state.statsLoaded && <UILIB.Row>

                                    <UILIB.Column xs={12} sm={12} md={6}>
                                        <UILIB.Paper className="mar-b0" style={{ minHeight: '225px' }}>
                                            <UILIB.Row className="mar-b10">
                                                <UILIB.Column xs={6} sm={6} md={6} lg={6} margin={0}>
                                                    <p className="text-secondary text-xsml uppercase no-marg">{i18n.t('subscribers:subscriber.opens')}</p>
                                                </UILIB.Column>
                                            </UILIB.Row>
                                            <UILIB.Row className="mar-b5">
                                                <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                                    <div className="quickFlex">
                                                        <p className="text-primary text-mxl no-marg">{opens}</p>
                                                        <p className="text-grey text-sml no-marg pad-left">/ {this.state.sends.length}</p>
                                                    </div>
                                                </UILIB.Column>
                                            </UILIB.Row>
                                            <UILIB.Row>
                                                <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                                    <UILIB.SmallLineGraph width="100%" height="4" textAlign={"end-xs text-grey"} hideText={false} onlyPercent={true} val1={opens} val2={this.state.sends.length} />
                                                </UILIB.Column>
                                            </UILIB.Row>
                                        </UILIB.Paper>
                                    </UILIB.Column>

                                    <UILIB.Column xs={12} sm={12} md={6}>
                                        <UILIB.Paper className="negative gradient mar-b0" style={{ minHeight: '225px' }}>
                                            <UILIB.Row className="mar-b10">
                                                <UILIB.Column xs={6} sm={6} md={6} lg={6} margin={0}>
                                                    <p className="text-xsml text-white uppercase no-marg">{i18n.t('subscribers:subscriber.clicks')}</p>
                                                </UILIB.Column>
                                            </UILIB.Row>
                                            <UILIB.Row className="mar-b5">
                                                <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                                    <div className="quickFlex">
                                                        <p className="text-mxl text-white no-marg">{clicks}</p>
                                                        <p className="text-sml text-white no-marg pad-left">/ {this.state.sends.length}</p>
                                                    </div>
                                                </UILIB.Column>
                                            </UILIB.Row>
                                            <UILIB.Row>
                                                <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                                    <UILIB.SmallLineGraph width="100%" height="4" textAlign={"end-xs text-white"} hideText={false} color={colours.$primary} onlyPercent={true} val1={clicks} val2={this.state.sends.length} />
                                                </UILIB.Column>
                                            </UILIB.Row>
                                        </UILIB.Paper>
                                    </UILIB.Column>

                                    {!!this.state.stats.tracking.length && <UILIB.Column xs={12} sm={12} md={6}>
                                        <UILIB.Paper className="mar-b0" style={{ minHeight: '225px' }}>
                                            <UILIB.Row className="mar-b10">
                                                <UILIB.Column xs={6} sm={6} md={6} lg={6} margin={0}>
                                                    <p className="text-secondary text-xsml uppercase no-marg">{i18n.t('subscribers:subscriber.tracking')}</p>
                                                </UILIB.Column>
                                            </UILIB.Row>
                                            <UILIB.Row className="mar-b5">
                                                <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                                    <div className="quickFlex">
                                                        <p className="text-primary text-mxl no-marg">{this.state.stats.tracking.length}</p>
                                                    </div>
                                                </UILIB.Column>
                                            </UILIB.Row>
                                        </UILIB.Paper>
                                    </UILIB.Column>}

                                    {!!this.state.geo.longitude && <UILIB.Column xs={12} sm={12} md={6} margin="0">
                                        <Map
                                            style="mapbox://styles/contactgate/cjg3exzgh160u2rp1nkgnn4na"
                                            center={[this.state.geo.longitude, this.state.geo.latitude]}
                                            id="mapHolder"
                                            zoom={[8]}
                                            containerStyle={{
                                                height: "225px",
                                                width: "100%",
                                                boxSizing: "border-box",
                                                borderRadius: "4px",
                                            }}>
                                            <Layer
                                                type="symbol"
                                                id="marker"
                                                layout={{ "icon-image": "marker-15" }}>
                                                <Feature coordinates={[this.state.geo.longitude, this.state.geo.latitude]} />
                                            </Layer>
                                        </Map>
                                    </UILIB.Column>}

                                </UILIB.Row>}
                            </UILIB.Row>
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
            </div>
        );
    }
}

export default SubscriberWidget;
module.exports = {
    csharp: `var client = new RestClient("https://{{url}}/transaction");
    var request = new RestRequest(Method.POST);
    request.AddHeader("Cache-Control", "no-cache");
    request.AddHeader("Content-Type", "application/json");
    request.AddHeader("x-access-token", "{{token}}");
    request.AddParameter("undefined", "{\n\t\"fromAddress\": \"{{fromAddress}}\",\n\t\"fromName\": \"{{fromName}}\",\n\t\"replyAddress\": \"{{replyAddress}}\",\n\t\"toAddress\": \"{{toAddress}}\",\n\t\"toName\": \"{{toName}}\",\n\t\"subject\": \"Test Email\",\n\t\"html\": \"<html><body>This is an email</body></html>\"\n}", ParameterType.RequestBody);
    IRestResponse response = client.Execute(request);`,
    curl: `curl -X POST \
https://{{url}}/transaction \
-H 'Cache-Control: no-cache' \
-H 'Content-Type: application/json' \
-H 'x-access-token: {{token}}' \
-d '{
    "fromAddress": "{{fromAddress}}",
    "fromName": "{{fromName}}",
    "replyAddress": "{{replyAddress}}",
    "toAddress": "{{toAddress}}",
    "toName": "{{toName}}",
    "subject": "Test Email",
    "html": "<html><body>This is an email</body></html>"
}'`,
    golang: `package main

import (
	"fmt"
	"strings"
	"net/http"
	"io/ioutil"
)

func main() {

	url := "https://{{url}}/transaction"

	payload := strings.NewReader("{\n\t\"fromAddress\": \"{{fromAddress}}\",\n\t\"fromName\": \"{{fromName}}\",\n\t\"replyAddress\": \"{{replyAddress}}\",\n\t\"toAddress\": \"{{toAddress}}\",\n\t\"toName\": \"{{toName}}\",\n\t\"subject\": \"Test Email\",\n\t\"html\": \"<html><body>This is an email</body></html>\"\n}")

	req, _ := http.NewRequest("POST", url, payload)

	req.Header.Add("x-access-token", "{{token}}")
	req.Header.Add("Content-Type", "application/json")
	req.Header.Add("Cache-Control", "no-cache")

	res, _ := http.DefaultClient.Do(req)

	defer res.Body.Close()
	body, _ := ioutil.ReadAll(res.Body)

	fmt.Println(res)
	fmt.Println(string(body))

}`,
    http: `POST /transaction HTTP/1.1
Host: {{url}}
x-access-token: {{token}}
Content-Type: application/json
Cache-Control: no-cache

{
	"fromAddress": "{{fromAddress}}",
	"fromName": "{{fromName}}",
	"replyAddress": "{{replyAddress}}",
	"toAddress": "{{toAddress}}",
	"toName": "{{toName}}",
	"subject": "Test Email",
	"html": "<html><body>This is an email</body></html>"
}`,
    java: `OkHttpClient client = new OkHttpClient();

MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\n\t\"fromAddress\": \"{{fromAddress}}\",\n\t\"fromName\": \"{{fromName}}\",\n\t\"replyAddress\": \"{{replyAddress}}\",\n\t\"toAddress\": \"{{toAddress}}\",\n\t\"toName\": \"{{toName}}\",\n\t\"subject\": \"Test Email\",\n\t\"html\": \"<html><body>This is an email</body></html>\"\n}");
Request request = new Request.Builder()
  .url("https://{{url}}/transaction")
  .post(body)
  .addHeader("x-access-token", "{{token}}")
  .addHeader("Content-Type", "application/json")
  .addHeader("Cache-Control", "no-cache")
  .build();

Response response = client.newCall(request).execute();`,
    javascript: `var settings = {
    "async": true,
    "crossDomain": true,
    "url": "https://{{url}}/transaction",
    "method": "POST",
    "headers": {
      "x-access-token": "{{token}}",
      "Content-Type": "application/json",
      "Cache-Control": "no-cache"
    },
    "processData": false,
    "data": {\n\t\t\"fromAddress\": \"{{fromAddress}}\",\n\t\t\"fromName\": \"{{fromName}}\",\n\t\t\"replyAddress\": \"{{replyAddress}}\",\n\t\t\"toAddress\": \"{{toAddress}}\",\n\t\t\"toName\": \"{{toName}}\",\n\t\t\"subject\": \"Test Email\",\n\t\t\"html\": \"<html><body>This is an email</body></html>\"\n\t}
};
  
$.ajax(settings).done(function (response) {
    console.log(response);
});`,
    python: `import requests

url = "https://{{url}}/transaction"

payload = "{\n\t\"fromAddress\": \"{{fromAddress}}\",\n\t\"fromName\": \"{{fromName}}\",\n\t\"replyAddress\": \"{{replyAddress}}\",\n\t\"toAddress\": \"{{toAddress}}\",\n\t\"toName\": \"{{toName}}\",\n\t\"subject\": \"Test Email\",\n\t\"html\": \"<html><body>This is an email</body></html>\"\n}"
headers = {
    'x-access-token': "{{token}}",
    'Content-Type': "application/json",
    'Cache-Control': "no-cache"
}

response = requests.request("POST", url, data=payload, headers=headers)

print(response.text)`,
    php: `<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => "https://{{url}}/transaction",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 30,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "POST",
  CURLOPT_POSTFIELDS => "{\n\t\"fromAddress\": \"{{fromAddress}}\",\n\t\"fromName\": \"{{fromName}}\",\n\t\"replyAddress\": \"{{replyAddress}}\",\n\t\"toAddress\": \"{{toAddress}}\",\n\t\"toName\": \"{{toName}}\",\n\t\"subject\": \"Test Email\",\n\t\"html\": \"<html><body>This is an email</body></html>\"\n}",
  CURLOPT_HTTPHEADER => array(
    "Cache-Control: no-cache",
    "Content-Type: application/json",
    "x-access-token: {{token}}"
  ),
));

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
  echo "cURL Error #:" . $err;
} else {
  echo $response;
}`,
    ruby: `require 'uri'
require 'net/http'

url = URI("https://{{url}}/transaction")

http = Net::HTTP.new(url.host, url.port)

request = Net::HTTP::Post.new(url)
request["x-access-token"] = '{{token}}'
request["Content-Type"] = 'application/json'
request["Cache-Control"] = 'no-cache'
request.body = "{\n\t\"fromAddress\": \"{{fromAddress}}\",\n\t\"fromName\": \"{{fromName}}\",\n\t\"replyAddress\": \"{{replyAddress}}\",\n\t\"toAddress\": \"{{toAddress}}\",\n\t\"toName\": \"{{toName}}\",\n\t\"subject\": \"Test Email\",\n\t\"html\": \"<html><body>This is an email</body></html>\"\n}"

response = http.request(request)
puts response.read_body`
}
import React from 'react'

export default function IconsChart({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";

    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 11.0001C2 10.7348 2.10536 10.4805 2.29289 10.293C2.48043 10.1054 2.73478 10.0001 3 10.0001H5C5.26522 10.0001 5.51957 10.1054 5.70711 10.293C5.89464 10.4805 6 10.7348 6 11.0001V16.0001C6 16.2653 5.89464 16.5196 5.70711 16.7072C5.51957 16.8947 5.26522 17.0001 5 17.0001H3C2.73478 17.0001 2.48043 16.8947 2.29289 16.7072C2.10536 16.5196 2 16.2653 2 16.0001V11.0001ZM8 7.00006C8 6.73484 8.10536 6.48049 8.29289 6.29295C8.48043 6.10542 8.73478 6.00006 9 6.00006H11C11.2652 6.00006 11.5196 6.10542 11.7071 6.29295C11.8946 6.48049 12 6.73484 12 7.00006V16.0001C12 16.2653 11.8946 16.5196 11.7071 16.7072C11.5196 16.8947 11.2652 17.0001 11 17.0001H9C8.73478 17.0001 8.48043 16.8947 8.29289 16.7072C8.10536 16.5196 8 16.2653 8 16.0001V7.00006ZM14 4.00006C14 3.73484 14.1054 3.48049 14.2929 3.29295C14.4804 3.10542 14.7348 3.00006 15 3.00006H17C17.2652 3.00006 17.5196 3.10542 17.7071 3.29295C17.8946 3.48049 18 3.73484 18 4.00006V16.0001C18 16.2653 17.8946 16.5196 17.7071 16.7072C17.5196 16.8947 17.2652 17.0001 17 17.0001H15C14.7348 17.0001 14.4804 16.8947 14.2929 16.7072C14.1054 16.5196 14 16.2653 14 16.0001V4.00006Z" fill={fillColor} />
        </svg>
    )
}
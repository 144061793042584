import React, { useState, useRef, useEffect } from "react";
import UILIB from "~/Common-Objects/Lib";
import InboxButton from "../../shared/inboxButton";
import i18n from "~/i18n";

export default function SmileyPickerTool({ userRole = 0, onSelect = () => { } }) {

  const [pickerOpen, setPickerOpen] = useState(false);

  useEffect(() => {
    setPickerOpen(false);
  }, []);


  const selectSmiley = (smiley) => {
    setPickerOpen(false);
    onSelect(smiley);
  }


  return (
    <>
      <UILIB.DropdownMenu.Root open={pickerOpen} onOpenChange={(e) => { setPickerOpen(e) }}>
        <UILIB.DropdownMenu.Trigger asChild>
          <InboxButton
            variant="ghost"
            size="s"
            iconOnly
            aria-label="Insert Emoji"
            data-tip={i18n.t("chat:inbox.conversation.composer.insertEmoji")} data-for="emoji"
            disabled={userRole > 1}
          >
            <UILIB.Icon name="emoji-smile" />
          </InboxButton>

        </UILIB.DropdownMenu.Trigger>

        <UILIB.DropdownMenu.Portal >
          <UILIB.DropdownMenu.Content align="start" className="conversations_composer_smileyPicker_popup">
            <UILIB.SmileyPicker onPick={selectSmiley} />
          </UILIB.DropdownMenu.Content>
        </UILIB.DropdownMenu.Portal>
      </UILIB.DropdownMenu.Root >

    </>

  );
}


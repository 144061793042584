import React, { Component } from 'react';
import axios from '../../../../../../data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import segmentsHelper from '../../../../../../Classes/segments'
import DateTimeFunctions from '../../../../../../Classes/dateTimeFunctions'

class TriggerFilters extends Component {
    constructor(props) {
        super(props);

        var stringOperators = segmentsHelper.getOperators('string');
        var intOperators = segmentsHelper.getOperators('int');
        var boolOperators = segmentsHelper.getOperators('bool');
        var dateOperators = segmentsHelper.getOperators('date');
        var selectOperators = segmentsHelper.getOperators('select')

        this.state = {
            loading: true,
            error: "",
            definition: undefined,
            adding: undefined,
            operators: {
                text: stringOperators,
                number: intOperators.filter(o => o.value.indexOf('in') === -1 && o.value.indexOf('between') === -1),
                date: dateOperators.filter(o => o.value.indexOf('between') === -1 && o.value.indexOf('days') === -1),
                select: selectOperators,
                boolean: boolOperators
            }
        }
        this.getTrigger = this.getTrigger.bind(this)
        this.update = this.update.bind(this)
        this.updatedate = this.updatedate.bind(this)
        this.add = this.add.bind(this)
        this.remove = this.remove.bind(this)
        this.save = this.save.bind(this)
        this.cancelSave = this.cancelSave.bind(this)
        this.getLookups = this.getLookups.bind(this)
    }

    componentDidMount() {
        this.getTrigger();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.trigger !== this.props.trigger) {
            this.getTrigger()
        }
    }

    getTrigger() {
        this.setState({ loading: true })
        axios.get("/application/" + this.props.appId + "/webhookdefinition?trigger=" + this.props.trigger).then(res => {
            this.setState({
                webhookdefinition: res.data,
            }, this.getLookups)
        })
    }

    getLookups() {
        const toLookup = Array.isArray(this.state.webhookdefinition.definition) ? this.state.webhookdefinition.definition.filter(d => d.type === 'select' && typeof d.options === 'string') : []

        if (!toLookup.length) return this.setState({ loading: false })

        var promises = toLookup.map(l => axios.get(l.options).then(res => {
            l.options = res.data
        }))

        Promise.all(promises).then(() => {
            this.setState({
                webhookdefinition: this.state.webhookdefinition,
                loading: false
            })
        }).catch(e => {
            console.log(e)
            this.setState({
                error: 'Connection Failed, please check your integration'
            })
        })
    }

    update(ev) {
        let adding = this.state.adding
        adding[ev.target.name] = ev.target.value;
        this.setState({ adding })
    }

    updatedate(ev, val) {
        let adding = this.state.adding
        adding.value = val
        this.setState({ adding })
    }

    add() {
        this.setState({
            adding: {}
        })
    }

    save() {
        this.props.filters.push(this.state.adding)
        if (typeof this.props.refresh === 'function') this.props.refresh()
        this.setState({
            adding: undefined
        })
    }
    cancelSave() {
        this.setState({
            adding: undefined
        })
    }

    remove(index) {
        this.props.filters.splice(index, 1)
        if (typeof this.props.refresh === 'function') this.props.refresh()
        this.setState({})
    }

    render() {
        if (this.state.error) return <UILIB.Indicator className="indicator-red">{this.state.error}</UILIB.Indicator>
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        let definition
        if (this.state.adding && this.state.adding.field) definition = this.state.webhookdefinition.definition.find(d => d.value === this.state.adding.field)
        return (
            <div className='mar-b25'>

                <div className="txtInput_label">Filtering (optional)</div>

                {!!this.state.adding && <UILIB.Paper style={{ textAlign: "left" }}>
                    <h4 className="mar-b25">Add a Filter</h4>
                    <div>

                        <UILIB.Select outerClassName="mar-b25" data={this.state.webhookdefinition.definition} placeholder="Select the Field to Filter" value={this.state.adding.field} name={'field'} onChange={this.update} />

                        {!!definition && <>
                            <UILIB.Select outerClassName="mar-b25" data={this.state.operators[definition.type]} placeholder="Select Operator" value={this.state.adding.operator} name={'operator'} onChange={this.update} />
                        </>}
                        {!!definition && this.state.adding.operator && this.state.adding.operator !== 'is blank' && this.state.adding.operator !== 'is not blank' && <div >
                            {definition.type === 'text' && <UILIB.TextInput outerClassName="mar-b25" value={this.state.adding.value} name={'value'} onChange={this.update} placeholder="Enter a Value" />}
                            {definition.type === 'select' && <UILIB.Select outerClassName="mar-b25" value={this.state.adding.value} name={'value'} onChange={this.update} placeholder="Select a value" data={definition.options} />}
                            {definition.type === 'number' && <UILIB.TextInput outerClassName="mar-b25" type="number" value={this.state.adding.value} name={'value'} onChange={this.update} placeholder="Enter a Value" />}
                            {definition.type === 'date' && <UILIB.TextInput outerClassName="mar-b25" type="date" value={this.state.adding.value} name={'value'} onChange={this.updatedate} placeholder="Choose a Date" />}
                            {definition.type === 'boolean' && <UILIB.Select outerClassName="mar-b25" value={this.state.adding.value} name={'value'} placeholder="Select a Value" onChange={this.update} data={[{
                                label: 'Yes',
                                value: true
                            }, {
                                label: 'No',
                                value: false
                            }]} />}

                        </div>}

                        <div className="quickFlex">
                            <UILIB.Button text="Save" className="button-primary mar-r10" onClick={this.save} disabled={!definition || !this.state.adding.operator} />
                            <UILIB.Button text="Cancel" onClick={this.cancelSave} />
                        </div>
                    </div>
                </UILIB.Paper>}
                {(!this.state.adding && this.props.filters.length > 0) && <div className="mar-b15">
                    {this.props.filters.map((trigger, index) => {
                        let def = this.state.webhookdefinition.definition.find(d => d.value === trigger.field)
                        if (!def) return null
                        let operator = this.state.operators[def.type].find(o => o.value === trigger.operator)
                        var triggerVal = trigger.value;
                        switch (def.type) {
                            case "date":
                                triggerVal = DateTimeFunctions.formatDateTime(trigger.value, 2);
                                break;
                            case "boolean":
                                triggerVal = trigger.value ? "Yes" : "No";
                                break;
                        }

                        return <UILIB.SquareChip className="square-chip-purple mar-r5" key={"filter_" + index} value={`"${def.label}" ${operator.label} "${triggerVal}"`} iconRight={<UILIB.Icons icon="crossCircle" style={{ height: 14, width: 14 }} />} onClick={() => this.remove(index)} />
                    })}
                </div>}
                {!this.state.adding && <UILIB.Button text={this.props.filters.length ? "Add another Filter" : "Add a Filter"} onClick={this.add} />}

                {this.props.wait && !!this.props.filters.length && <UILIB.WarningHolder className="mar-t10 mar-b20">Note: These filters will only apply to the original event (but we will get it's updated values)</UILIB.WarningHolder>}
            </div >
        );
    }
}

export default TriggerFilters;
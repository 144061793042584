import React, { Fragment, forwardRef, useEffect, useState } from 'react'
import moment from 'moment';
import UILIB from '~/Common-Objects/Lib'
import store from '~/data/store/store'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import SmileyPicker from './smileyPicker';
import CustomFieldPicker from './customFieldPicker'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask'
import AiContentGenerator from '~/pages/cp/includes/ai/contentGenerator';
import CampaignMergeTags from '~/pages/cp/includes/mergeTags/campaignMergeTags';

@connect((store) => {
    return { user: store.user, browser: store.browser, permissionStore: store.permission }
})
export default class TextInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
            showSuggestions: false,
            date: "",
            focused: false,
            dateFormat: this.props.dateFormat || "yyyy-MM-dd",
            inputSelection: { start: 0, end: 0 },
            selectedSuggestion: null,
            currVal: ""
        }
        this.keyDown = this.keyDown.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.singleDateChange = this.singleDateChange.bind(this);
        this.multiDateChange = this.multiDateChange.bind(this);
        this.clickToChange = this.clickToChange.bind(this);
        this.openSmileyPicker = this.openSmileyPicker.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.pickSmiley = this.pickSmiley.bind(this);
        this.openCustomFieldPicker = this.openCustomFieldPicker.bind(this)
        this.pickField = this.pickField.bind(this);
        this.isOutsideRange = this.isOutsideRange.bind(this)
        this.clickToClear = this.clickToClear.bind(this)
        this.telNoChange = this.telNoChange.bind(this);
        this.selectSuggestion = this.selectSuggestion.bind(this)
        this.onFocus = this.onFocus.bind(this)
        this.clickAway = this.clickAway.bind(this)
        this.multiDateStart = React.createRef();
        this.multiDateEnd = React.createRef();
        this.openAiContentGenerator = this.openAiContentGenerator.bind(this);
        this.openMergeTags = this.openMergeTags.bind(this);
    }

    componentDidMount() {
        if (this.props.focus && this.nameInput) {
            this.nameInput.focus();
        }
        if (this.props.focus && this.props.inputRef && this.props.inputRef.current) {
            this.props.inputRef.current.focus()
        }

        var state = store.getState();
        var dateFormat = "yyyy-MM-dd";
        if (state.user && state.user.userData && state.user.userData.dateFormat && !this.props.dateFormat) {
            dateFormat = state.user.userData.dateFormat

            if (dateFormat && dateFormat.length) {
                dateFormat = dateFormat.replace(/DD/g, 'dd').replace(/YY/g, "yy")
                if (this.props.includeTime) {
                    dateFormat += " HH:mm"
                }
            }
        }
        this.setState({
            dateFormat
        })

        if (this.props.suggestions && this.props.suggestions.length) {
            const found = this.props.suggestions.filter(s => s.toLowerCase().indexOf(this.props.value.toLowerCase()) > -1 && s.toLowerCase() !== this.props.value.toLowerCase())
            this.setState({ suggestions: found })
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.suggestions && prevProps.suggestions && this.props.suggestions.length !== prevProps.suggestions.length) {
            const found = this.props.suggestions.filter(s => s.toLowerCase().indexOf(this.props.value.toLowerCase()) > -1 && s.toLowerCase() !== this.props.value.toLowerCase())
            this.setState({ suggestions: found })
        }
        if (prevProps.value !== this.props.value && this.props.value !== this.state.selectedSuggestion) {
            if (this.props.suggestions && this.props.suggestions.length) {
                const found = this.props.suggestions.filter(s => s.toLowerCase().indexOf(this.props.value.toLowerCase()) > -1 && s.toLowerCase() !== this.props.value.toLowerCase())
                this.setState({ suggestions: found, showSuggestions: Boolean(found.length), selectedSuggestion: null })
            }
        }
    }

    selectSuggestion(value) {
        this.props.onChange({
            target: { value: value, name: this.props.name },
            currentTarget: { value: value, name: this.props.name },
        })
        this.setState({ showSuggestions: false, selectedSuggestion: value })
    }

    onKeyPress(event) {
        if (this.props.onEnterPress && event.key == 'Enter') {
            this.props.onEnterPress();
            event.stopPropagation();
            event.preventDefault();
            return false;
        } else if (this.props.onKeyPress) {
            this.props.onKeyPress(event);
        }
    }

    keyDown(evt) {
        if (evt.key === 'Escape' && this.props.onEscapePress) {
            this.props.onEscapePress();
            evt.stopPropagation();
            evt.preventDefault();
            return false;
        }
        if (this.props.onKeyDown && typeof this.props.onKeyDown === 'function') {
            this.props.onKeyDown(evt)
        }
    }


    clickToChange() {
        if (this.props.clickToChangeClicked) this.props.clickToChangeClicked(this.nameInput.value)
    }

    clickToClear() {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: ''
            },
            currentTarget: {
                name: this.props.name,
                value: ''
            }
        })
        this.setState({ currVal: '' })
    }

    openAiContentGenerator() {
        var ourEl = document.getElementById(this.props.id);
        var startChar = ourEl.selectionStart;
        var endChar = ourEl.selectionEnd;

        this.setState({ inputSelection: { start: startChar, end: endChar } });

        var drawerContent = <AiContentGenerator contentType="subject" insertText={this.pickField} close={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, false, "right", drawerContent, true, "700px", "drawer_dark"));
    }
    openMergeTags() {
        var ourEl = document.getElementById(this.props.id);
        var startChar = ourEl.selectionStart;
        var endChar = ourEl.selectionEnd;

        this.setState({ inputSelection: { start: startChar, end: endChar } });

        let drawerContent = <CampaignMergeTags
            hasWorkflowEmails={false}
            includeSubscriber={true}
            includeSubCustomFields={true}
            close={() => { this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true)); }}
            insertMergeTag={this.pickField}
            includeSystem={false}
            includeRss={this.props.includeRssMergeTags}
        />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, false, "right", drawerContent, true, "700px"));
    }
    openCustomFieldPicker() {
        var ourEl = document.getElementById(this.props.id);
        var startChar = ourEl.selectionStart;
        var endChar = ourEl.selectionEnd;

        this.setState({ inputSelection: { start: startChar, end: endChar } });

        var drawerContent = <CustomFieldPicker onPick={this.pickField} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "600px"));
    }

    isOutsideRange(date) {
        if (this.props.disallowPast) {
            if (moment() > date && !date.isSame(moment(), 'day')) {
                return true
            }
        }
        return false
    }

    pickField(theChar) {
        var ourEl = document.getElementById(this.props.id);
        var startPos = this.state.inputSelection.start;
        var endPos = this.state.inputSelection.end;
        ourEl.focus();

        if (document.selection) {
            sel = document.selection.createRange();
            sel.text = theChar;
        }
        else {
            ourEl.value = ourEl.value.substring(0, startPos)
                + theChar
                + ourEl.value.substring(endPos, ourEl.value.length);

            this.props.onChange({ target: ourEl, currentTarget: ourEl })
        }
        ourEl.setSelectionRange(startPos + theChar.length, startPos + theChar.length);
        this.closePopup();

    }

    openSmileyPicker() {

        var ourEl = document.getElementById(this.props.id);
        var startChar = ourEl.selectionStart;
        var endChar = ourEl.selectionEnd;

        this.setState({ inputSelection: { start: startChar, end: endChar } });

        var drawerContent = <SmileyPicker onPick={this.pickSmiley} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "400px"));
    }


    pickSmiley(theChar) {
        var ourEl = document.getElementById(this.props.id);
        var startPos = this.state.inputSelection.start;
        var endPos = this.state.inputSelection.end;
        ourEl.focus();

        if (document.selection) {
            sel = document.selection.createRange();
            sel.text = theChar;
        }
        else {

            ourEl.value = ourEl.value.substring(0, startPos)
                + theChar
                + ourEl.value.substring(endPos, ourEl.value.length);


            this.props.onChange({ target: ourEl, currentTarget: ourEl })
        }
        ourEl.setSelectionRange(startPos + theChar.length, startPos + theChar.length);
        this.closePopup();

    }

    closePopup() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", undefined, true));
    }

    telNoChange(valid, value) {
        this.props.onChange({
            target: { name: this.props.name, value },
            currentTarget: { name: this.props.name, value },
            valid
        });
    }

    singleDateChange(newDate) {
        this.setState({ date: newDate });
        this.props.onChange(this.props.field || this.props.name, moment(newDate));
        if (this.props.onBlur) this.props.onBlur()
    }

    multiDateChange(newVal, targetName) {

        var startDate = this.props.startDate;
        var endDate = this.props.endDate;

        if (targetName == "startDate") {
            startDate = moment(newVal);
        }
        if (targetName == "endDate") {
            endDate = moment(newVal);
        }
        this.props.updateDate({ startDate, endDate })
    }

    onFocus(e) {
        if (this.props.onFocus && typeof this.props.onFocus === 'function') {
            this.props.onFocus(e)
        }
        if (this.state.suggestions.length) {
            this.setState({ showSuggestions: true })
        }
    }

    clickAway() {
        if (this.state.showSuggestions) {
            this.setState({ showSuggestions: false })
        }
    }


    render() {
        var thisClasses = "txtInput ";
        var errorTxt = [];

        if (this.props.error) {
            thisClasses += "button-warning ";
            if (this.props.error != " ") {
                errorTxt.push(<UILIB.FormError key={errorTxt.length} >{this.props.error}</UILIB.FormError>);
            }
        }

        if (this.props.warning) {
            thisClasses += 'button-warning ';
            if (this.props.warning != " ") {
                errorTxt.push(<UILIB.FormWarning key={errorTxt.length}>{this.props.warning}</UILIB.FormWarning>);
            }
        }

        if (this.props.className) {
            thisClasses += this.props.className;
        }

        var inputType = "text";
        if (this.props.type) {
            inputType = this.props.type.toLowerCase();
        }

        var thisDate = undefined;
        if (inputType === "date" || inputType === 'datetime' || inputType === 'time') {
            if (typeof this.props.value === "object" || (this.props.value && this.props.value.length > 3)) {
                thisDate = new Date(Date.parse(this.props.value));
                // thisDate.setTime(thisDate.getTime() + (thisDate.getTimezoneOffset() * 60 * 1000))
            }
        }

        var isMobile = window.innerWidth <= 480;

        var thisStyle = {};
        if (this.props.style) {
            thisStyle = JSON.parse(JSON.stringify(this.props.style));
        }
        if (this.props.clickToChange) {
            thisStyle.paddingRight = "35px";
        }

        var value = this.props.value
        if (value === null) value = undefined

        var outerStyle = this.props.outerStyle || {};
        var finalInputType = this.props.type || 'text';

        var minDate = "";
        if (this.props.disallowPast) {
            minDate = moment().toDate();
        }

        let label = "";
        if ((this.props.label && this.props.labelOptions) || this.props.labelOptions) {
            label = <div className="txtInput_label_flex">
                <div className="txtInput_label">{this.props.label}</div>
                <div className="txtInput_labelOptions">{this.props.labelOptions}</div>
            </div>
        } else if (this.props.label) {
            label = <div className="txtInput_label">{this.props.label}</div>
        }

        let explainer = "";
        if (this.props.explainer) {
            explainer = <div className="txtInput_explainer">{this.props.explainer}</div>
        }

        if (this.props.explainerEnd) {
            explainer = <div className='quickFlex' style={{ justifyContent: 'space-between' }}>{explainer} {this.props.explainerEnd}</div>
        }

        let outerClassName = "textInputWrapper";
        if (this.props.outerClassName) outerClassName += " " + this.props.outerClassName;

        let iconLeft = "";
        if (this.props.iconLeft) {
            iconLeft = <div className="textInputIconLeft">{this.props.iconLeft}</div>
            thisStyle.paddingLeft = "40px"
        }
        var iconRight = [];
        if (this.props.iconRight) {
            // do this so we can add key to component
            iconRight.push(<Fragment key={iconRight.length}>{this.props.iconRight}</Fragment>)
        }

        if (inputType == "emojipicker" && this.props.id) {
            iconRight.push(
                <UILIB.Icons icon="smiley"
                    key={iconRight.length}
                    title="Insert an Emoji"
                    alt="Insert an Emoji"
                    data-tip="Insert Emoji"
                    data-for="emoji"
                    onClick={this.openSmileyPicker}
                />
            )
        }

        if (this.props.customFields) {
            iconRight.push(
                <UILIB.Icons
                    key={iconRight.length}
                    icon="list"
                    title="Insert Custom Field"
                    alt="Insert Custom Field"
                    data-tip="Insert Custom Field"
                    data-for="customfields"
                    onClick={this.openCustomFieldPicker} />

            )
        }
        if (this.props.mergeTags) {
            iconRight.push(
                <UILIB.Icons
                    key={iconRight.length}
                    icon="list"
                    title="Merge Tags"
                    alt="Merge Tags"
                    data-tip="Merge Tags"
                    data-for="mergeTags"
                    onClick={this.openMergeTags}
                />
            )
        }
        if (this.props.aiContent) {
            iconRight.push(
                <UILIB.Icons
                    key={iconRight.length}
                    icon="sparkle2"
                    title="AI Content Assistant"
                    alt="AI Content Assistant"
                    data-tip="AI Content Assistant"
                    data-for="aiContent"
                    onClick={this.openAiContentGenerator}
                    style={{ height: "20px", width: "20px" }}
                />
            )
        }

        if (iconRight.length) {
            iconRight = <div className="textInputIcon" style={this.props.iconRightStyle}>{iconRight}</div>
            thisStyle.paddingRight = "40px"
        }

        return <UILIB.ClickAway onClickAway={this.clickAway} enabled={Boolean(this.props.clickAway)}>
            <div className={outerClassName} style={outerStyle} >
                {label}

                <div className="txtInputInnerHolder">
                    {iconLeft}
                    {inputType === 'multidate' &&
                        <div className={"DateRangePicker " + this.props.className} style={{ position: "relative" }}>

                            <DatePicker
                                onChange={(newVal) => this.multiDateChange(newVal, "startDate")}
                                selected={this.props.startDate.toDate()}
                                disabled={this.props.disabled}
                                dateFormat={this.state.dateFormat || 'YYYY-MM-DD'}
                                selectsStart
                                id={this.props.id}
                                focused={this.state.focused}
                                maxDate={this.props.endDate.toDate()}
                                className={"DateRangePickerInput " + this.props.dateInputClass}
                                minDate={minDate}
                                ref={this.multiDateStart}
                                customInput={<CustomDatePicker classes={thisClasses} readOnlyInput={true} />}
                            />
                            <div className="DateRangeDivider">
                                <UILIB.Icons icon="arrowRight" color="#A9A9AD" className="vertical-center" />
                            </div>
                            <DatePicker
                                onChange={(newVal) => this.multiDateChange(newVal, "endDate")}
                                selected={this.props.endDate.toDate()}
                                disabled={this.props.disabled}
                                dateFormat={this.state.dateFormat || 'YYYY-MM-DD'}
                                selectsEnd
                                id={this.props.id}
                                focused={this.state.focused}
                                minDate={this.props.startDate.toDate()}
                                className={"DateRangePickerInput " + this.props.dateInputClass}
                                ref={this.multiDateEnd}
                                customInput={<CustomDatePicker classes={thisClasses} readOnlyInput={true} />}
                            />
                        </div>
                    }

                    {inputType === 'date' &&
                        <DatePicker
                            onCalendarOpen={this.props.handleCalendarOpen}
                            onCalendarClose={this.props.handleCalendarClose}
                            onChange={this.singleDateChange}
                            selected={thisDate}
                            disabled={this.props.disabled}
                            dateFormat={this.state.dateFormat}
                            readOnly={this.props.readOnly}
                            id={this.props.id}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            minDate={minDate}
                            ref={this.props.inputRef || ((input) => { this.nameInput = input; })}
                            customInput={<CustomDatePicker hideIcon={this.props.hideIcon} classes={thisClasses + " SingleDatePicker"} single={true} onDateChange={this.singleDateChange} />}
                        />
                    }

                    {inputType === 'time' &&
                        <DatePicker
                            showTimeSelect
                            showTimeSelectOnly
                            onCalendarOpen={this.props.handleCalendarOpen}
                            onCalendarClose={this.props.handleCalendarClose}
                            timeIntervals={this.props.timeInterval || 5}
                            onChange={this.singleDateChange}
                            selected={thisDate}
                            timeCaption="Time"
                            disabled={this.props.disabled}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            timeFormat="HH:mm"
                            dateFormat={"HH:mm"}
                            ref={this.props.inputRef || ((input) => this.nameInput = input)}
                            id={this.props.id}
                            customInput={<CustomDatePicker readOnlyInput={true} classes={thisClasses + " SingleDatePicker"} single={true} id={this.props.id} timeOnly={true} />}
                        />
                    }

                    {inputType === 'datetime' &&
                        <DatePicker
                            onCalendarOpen={this.props.handleCalendarOpen}
                            onCalendarClose={this.props.handleCalendarClose}
                            onChange={this.singleDateChange}
                            selected={thisDate}
                            disabled={this.props.disabled}
                            id={this.props.id}
                            showTimeSelect
                            timeCaption="Time"
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            dateFormat={this.state.dateFormat + " HH:mm"}
                            timeFormat="HH:mm"
                            timeIntervals={this.props.timeInterval || 5}
                            minDate={minDate}
                            ref={this.props.inputRef || ((input) => this.nameInput = input)}
                            customInput={<CustomDatePicker readOnlyInput={true} classes={thisClasses + " SingleDatePicker"} single={true} id={this.props.id} />}
                        />
                    }

                    {inputType == "telno" && <UILIB.TelephoneInput
                        name={this.props.name}
                        value={value}
                        onChange={this.telNoChange}
                        defaultCountry={this.props.defaultCountry || "gb"}
                        onlyCountries={this.props.onlyCountries}
                        allowDropdown={this.props.allowDropdown}
                        className={thisClasses}
                        id={this.props.id}
                        disabled={this.props.disabled ? 'disabled' : ''}
                        ref={this.props.inputRef || ((input) => { this.nameInput = input; })}
                        readOnly={this.props.readOnly ? 'readonly' : ''}
                        style={thisStyle}
                    />}

                    {inputType == "plaintext" && <div style={{ position: "relative", width: "100%" }}>

                        <div className={thisClasses} style={{ overflow: "auto" }}>
                            {value}
                        </div>

                    </div>}
                    {inputType !== 'date' && inputType != 'multidate' && inputType != 'telno' && inputType != "plaintext" && inputType != "time" && inputType !== 'datetime' && !this.props.mask &&
                        <input type={finalInputType}
                            min={this.props.min}
                            max={this.props.max}
                            placeholder={this.props.placeholder}
                            name={this.props.name}
                            value={value}
                            onChange={this.props.onChange}
                            onKeyPress={this.onKeyPress}
                            className={thisClasses}
                            autoComplete={this.props.autocomplete}
                            id={this.props.id}
                            disabled={this.props.disabled ? 'disabled' : ''}
                            ref={this.props.inputRef || ((input) => { this.nameInput = input; })}
                            onBlur={this.props.onBlur}
                            onFocus={this.onFocus}
                            onClick={this.props.onClick}
                            readOnly={this.props.readOnly ? 'readonly' : ''}
                            style={thisStyle}
                            maxLength={this.props.maxlength}
                            onKeyUp={this.props.onKeyUp}
                            onKeyDown={this.keyDown}
                            required={this.props.required}
                            autoFocus={this.props.autoFocus}
                        />}
                    {inputType !== 'date' && inputType != 'multidate' && inputType != 'telno' && inputType != "plaintext" && !!this.props.mask &&
                        <MaskedInput type={finalInputType}
                            mask={this.props.mask}
                            placeholder={this.props.placeholder}
                            name={this.props.name}
                            value={value}
                            onChange={this.props.onChange}
                            onKeyPress={this.onKeyPress}
                            className={thisClasses}
                            showMask={true} guide={false}
                            autoComplete={this.props.autocomplete}
                            id={this.props.id}
                            disabled={this.props.disabled ? 'disabled' : ''}
                            ref={this.props.inputRef || ((input) => { this.nameInput = input; })}
                            onBlur={this.props.onBlur}
                            onFocus={this.onFocus}
                            onClick={this.props.onClick}
                            readOnly={this.props.readOnly ? 'readonly' : ''}
                            style={thisStyle}
                            maxLength={this.props.maxlength}
                        />}
                    {this.props.clickToChange && <div className={"icon-magnifier textInputIcon " + (this.props.clickToClear ? 'leftMore' : '')} onClick={this.clickToChange} />}
                    {this.props.clickToClear && <div className="icon-cross2 textInputIcon" onClick={this.clickToClear} />}
                    {iconRight}

                </div>
                {errorTxt}
                {Boolean(this.state.suggestions.length) && this.state.showSuggestions && <div className={"dropDownResults show"} style={{ top: 30 }}>
                    {this.state.suggestions.sort().map((value, index) => {
                        return <div className="dropDownResult text-sml cursor-pointer" key={index} onClick={() => this.selectSuggestion(value)}>{value}</div>
                    })}
                </div>}
                {explainer}
            </div>
        </UILIB.ClickAway>
    }
}


const CustomDatePicker = forwardRef(({ value, onClick, classes, fullDate, disabled, single = false, readOnlyInput = false, onDateChange = () => { }, onBlur, id, timeOnly = false, hideIcon = false }, ref) => {
    const user = useSelector(state => state.user)
    const [date, setDate] = useState(readOnlyInput ? null : moment(value))

    const handleChange = e => {
        if (readOnlyInput || disabled) return
        const val = e.currentTarget.value
        setDate(val)
    }

    const checkDate = () => {
        if (readOnlyInput || disabled) return
        const validDate = moment(date, user.userData.dateFormat)
        if (validDate.isValid()) {
            if (fullDate) {
                const full = moment(full)
                validDate.hour(full.hour())
                validDate.minute(full.minute())
            }
            onDateChange(validDate)
        } else {
            setDate(moment(value))
        }
    }

    useEffect(() => {
        if (readOnlyInput || disabled) return
        setDate(value)
    }, [value])

    return (
        <>
            <input id={id} className={classes + ' datepicker ' + ((readOnlyInput || disabled) ? 'readonly' : '')} disabled={disabled} onClick={disabled ? undefined : onClick} ref={ref} value={readOnlyInput || disabled ? value : date} onBlur={checkDate} onChange={handleChange} />
            {!hideIcon && <UILIB.Icons icon={timeOnly ? "clock" : "calendar"} color="#A9A9AD" className={"vertical-center mar-r10 " + (single ? 'datepicker-icon-inline' : '')} onClick={disabled ? undefined : onClick} />}
        </>
    )
});
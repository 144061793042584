import React from 'react'

export default class IconsMoney extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 4.00006C3.46957 4.00006 2.96086 4.21077 2.58579 4.58585C2.21071 4.96092 2 5.46963 2 6.00006V10.0001C2 10.5305 2.21071 11.0392 2.58579 11.4143C2.96086 11.7893 3.46957 12.0001 4 12.0001V6.00006H14C14 5.46963 13.7893 4.96092 13.4142 4.58585C13.0391 4.21077 12.5304 4.00006 12 4.00006H4ZM6 10.0001C6 9.46963 6.21071 8.96092 6.58579 8.58585C6.96086 8.21077 7.46957 8.00006 8 8.00006H16C16.5304 8.00006 17.0391 8.21077 17.4142 8.58585C17.7893 8.96092 18 9.46963 18 10.0001V14.0001C18 14.5305 17.7893 15.0392 17.4142 15.4143C17.0391 15.7893 16.5304 16.0001 16 16.0001H8C7.46957 16.0001 6.96086 15.7893 6.58579 15.4143C6.21071 15.0392 6 14.5305 6 14.0001V10.0001ZM12 14.0001C12.5304 14.0001 13.0391 13.7893 13.4142 13.4143C13.7893 13.0392 14 12.5305 14 12.0001C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2108 12.5304 10.0001 12 10.0001C11.4696 10.0001 10.9609 10.2108 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12.0001C10 12.5305 10.2107 13.0392 10.5858 13.4143C10.9609 13.7893 11.4696 14.0001 12 14.0001Z" fill={color} />
        </svg>



    }
}        
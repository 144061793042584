import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'

class DrawerEditGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentGroupId: 0,
            currentSegmentId: 0,
            groups: []
        }

        this.selectGroup = this.selectGroup.bind(this);
        this.selectSegment = this.selectSegment.bind(this);
    }

    componentDidMount() {
        var groups = this.props.groups;
        if (this.props.groupId == undefined) {
            groups.unshift({ groupName: "Select a group", value: 0 });
        }
        this.setState({ currentGroupId: this.props.groupId, currentSegmentId: this.props.segmentId, groups: groups })
    }


    selectGroup(event) {
        var groups = this.state.groups;
        var currentGroupId = event.target.value;
        if (groups[0].groupName == "Select a group") {
            groups.splice(0, 1)
        }

        console.log(currentGroupId, groups)
        this.setState({ currentGroupId: currentGroupId, groups: groups }, this.props.selectGroup(event.target.value))
        if (!event.target.value) this.props.selectSegment(0)
    }
    selectSegment(event) {
        this.setState({ currentSegmentId: event.target.value }, this.props.selectSegment(event.target.value))
    }

    render() {

        return <div>
            <h4 className="mar-b25">Select some Contacts</h4>
            <div className="mar-b25">Choose the group and/or segment that your Automation relates to.</div>

            <div className="form-group">
                <label htmlFor="">Which group does this Automation Apply to?</label>
                <UILIB.Select value={this.state.currentGroupId} data={this.state.groups.map((group) => {
                    return { label: group.groupName, value: group.id }
                })} onChange={this.selectGroup} />
            </div>
            {this.state.currentGroupId && this.state.currentGroupId != 0 && this.props.segments && <div className="form-group">
                <label htmlFor="">And which segment? (Optional)</label>
                <UILIB.Select placeholder="No segment Needed" value={this.state.currentSegmentId} data={this.props.segments.filter(s => !s.readOnly).map((segment) => {
                    return { label: segment.name, value: segment.id }
                })} onChange={this.selectSegment} />
            </div>
            }
            {this.state.currentGroupId && this.state.currentGroupId != 0 && <UILIB.Button className="button-sml outline primary" text="I'm Finished!" onClick={this.props.finished} />}

        </div>

    }
}

export default DrawerEditGroup;
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';

import i18n from '~/i18n'
import SiteTrackingAutomate from '../automate';
import { alterSiteDrawer } from '../../../../data/actions/siteActions';

//CONNECT TO STORE
@connect((store) => {
    return {
        user: store.user
    }
})

export default class AccountTemplate extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = { tabIndex: 0, siteData: [] }
        this.tabChange = this.tabChange.bind(this);
        this.setInitialTab = this.setInitialTab.bind(this);
    }

    componentDidMount() {
        this.setInitialTab();
    }

    setInitialTab() {

        var theCurrPath = this.props.history.location.pathname.split("/");
        var currPath = "";
        if (theCurrPath.length > 3) {
            currPath = theCurrPath[4]
        }

        switch (currPath) {
            case "visitors":
                {
                    this.setState({ tabIndex: 1 })
                    break;
                }
            case "pages":
                {
                    this.setState({ tabIndex: 2 })
                    break;
                }
            case "campaigns":
                {
                    this.setState({ tabIndex: 3 })
                    break;
                }
            case "events":
                {
                    this.setState({ tabIndex: 4 })
                    break;
                }
            default:
                {
                    this.setState({ tabIndex: 0 });
                }
        }
    }


    tabChange(event) {
        var self = this;
        var index;
        if (event.currentTarget && event.currentTarget.id !== undefined) {
            index = event.currentTarget.id;
        }
        else if (event.currentTarget && event.currentTarget.value !== undefined) {
            index = event.currentTarget.value;
        }
        else {
            index = event;
        }
        index = String(index);

        self.setState({ tabIndex: index });
        switch (index) {
            case "0":
                this
                    .props
                    .history
                    .push('/cp/trackers/' + this.props.tracker.id);
                break;
            case "1":
                this
                    .props
                    .history
                    .push('/cp/trackers/' + this.props.tracker.id + '/visitors');
                break;
            case "2":
                this
                    .props
                    .history
                    .push('/cp/trackers/' + this.props.tracker.id + '/pages');
                break;
            case "3":
                this
                    .props
                    .history
                    .push('/cp/trackers/' + this.props.tracker.id + '/campaigns');
                break;
            case "4":
                this
                    .props
                    .history
                    .push('/cp/trackers/' + this.props.tracker.id + '/events');
                break;
        }
    }


    automate() {
        const content = <SiteTrackingAutomate TrackerId={this.props.tracker.id} trackerName={this.props.tracker.host} />
        this.props.dispatch(alterSiteDrawer(true, true, 'right', content, true, 500))
    }

    render() {
        var thisMenuOptions = {
            [i18n.t('campaigns:sitetracking.trackingoverview.overview')]: 0,
            [i18n.t('campaigns:sitetracking.trackingoverview.visitors')]: 1,
            [i18n.t('campaigns:sitetracking.trackingoverview.pages')]: 2,
            [i18n.t('campaigns:sitetracking.trackingoverview.campaigns')]: 3,
            [i18n.t('campaigns:sitetracking.trackingoverview.events')]: 4
        }

        return <UILIB.Paper>
            <UILIB.Row>
                <UILIB.Column xs={12} className="quickFlex mar-b25" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={() => { this.props.history.push('/cp/trackers') }}>{i18n.t('subscribers:subscriber.back')}</UILIB.Hint>
                    <UILIB.Button iconRight={<UILIB.Icons icon="lightning" />} onClick={this.automate.bind(this)}>Automate</UILIB.Button>
                </UILIB.Column>

                <UILIB.Column xs="12" className="quickFlex" style={{ alignItems: "center", justifyContent: "space-between" }}>
                    <h4 className="mar-b25">{i18n.t('campaigns:sitetracking.trackingoverview.sitetracking')} - {this.props.tracker.host}</h4>
                    <UILIB.TextInput outerClassName="mar-b25" type="multiDate" startDate={this.props.startDate} endDate={this.props.endDate} updateDate={this.props.updateDate}></UILIB.TextInput>
                </UILIB.Column>

                <UILIB.Column xs={12} hide={["md", "lg"]} className="mar-b25">

                    <UILIB.Select data={thisMenuOptions} onChange={this.tabChange} value={this.state.tabIndex} />

                </UILIB.Column>
                <UILIB.Column xs={12} hide={["xs", "sm"]} className="mar-b25">

                    {Object.keys(thisMenuOptions).map((value, index) => {
                        let thisOpt = thisMenuOptions[value];
                        return <UILIB.ButtonSimple key={"tab_" + index}
                            className="button-simple-fullsize mar-l5"
                            selected={this.state.tabIndex == thisOpt}
                            onClick={() => { this.tabChange(thisOpt) }}>
                            {value}
                        </UILIB.ButtonSimple>

                    })}

                </UILIB.Column>
            </UILIB.Row>

            {this.props.children}
        </UILIB.Paper>
    }
}
import React from 'react'

export default function StickyNavBottom({ show = false, hide = false, children }) {
    let classes = "stickynav-bottom"
    if (hide) classes += " stickynav-bottom-hide"
    if (show) classes += " stickynav-bottom-show"
    return (
        <div className={classes}>
            {children}
        </div>
    )
}
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import UILIB from "~/Common-Objects/Lib";
import axios from "~/data/http/axios";
import * as siteMaster from "~/data/actions/siteActions";
import i18n from '../../../../i18n'

export default function AutomationCancelTest({ AutomationId, finished = () => { } }) {
    const dispatch = useDispatch()
    const [cancelling, setCancelling] = useState(false)

    const confirm = async () => {
        setCancelling(true)
        const res = await axios.put('/automation/' + AutomationId + '/canceltest')
        setCancelling(false)
        dispatch(siteMaster.alterSiteDrawer(false, true, 'right', null))
        finished(res.data.status)
    }

    const cancel = () => {
        dispatch(siteMaster.alterSiteDrawer(false, true, 'right', null))
    }

    return (
        <div>
            <h4 className="mar-b25">{i18n.t("automation:add.cancelTestDrawer.header")}</h4>
            <div className="mar-b25">{i18n.t("automation:add.cancelTestDrawer.content")}</div>
            <div className="quickFlex">
                <UILIB.Button disabled={cancelling} className="button-primary mar-r10" onClick={confirm}>{i18n.t("automation:add.cancelTestDrawer.cancel")}</UILIB.Button>
                <UILIB.Button onClick={cancel}>{i18n.t("automation:add.cancelTestDrawer.keep")}</UILIB.Button>
            </div>
        </div>
    )
}
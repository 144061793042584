import React from 'react'

export default class IconsTwitter extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.999 5.78261C16.4837 6.01145 15.9302 6.16545 15.3492 6.23491C15.9426 5.87953 16.3976 5.31686 16.6119 4.64597C16.0573 4.97495 15.4424 5.21402 14.7876 5.34325C14.2637 4.78488 13.5175 4.43597 12.6915 4.43597C11.1052 4.43597 9.81945 5.72231 9.81945 7.30802C9.81945 7.53309 9.8453 7.75277 9.89376 7.96222C7.50685 7.84269 5.39078 6.69904 3.9736 4.96095C3.727 5.38525 3.58539 5.87846 3.58539 6.40559C3.58539 7.40171 4.09206 8.28098 4.86257 8.79573C4.39197 8.78065 3.94883 8.65143 3.56169 8.43659C3.56169 8.44897 3.56169 8.46028 3.56169 8.47266C3.56169 9.86453 4.55135 11.0254 5.86568 11.2887C5.625 11.3544 5.37086 11.3894 5.10863 11.3894C4.92395 11.3894 4.74357 11.3711 4.56858 11.3382C4.93418 12.4792 5.99491 13.31 7.25163 13.3332C6.26897 14.1037 5.03056 14.563 3.68446 14.563C3.45293 14.563 3.22409 14.5495 2.99902 14.5226C4.27028 15.3372 5.77953 15.8127 7.40185 15.8127C12.685 15.8127 15.5732 11.4362 15.5732 7.64077C15.5732 7.51639 15.5705 7.39255 15.5652 7.26925C16.1267 6.8638 16.614 6.35821 16.999 5.78261Z" fill={color} />
        </svg>

    }
}        
import React from 'react'
import {
    AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid
} from 'recharts';
import UILIB from '~/Common-Objects/Lib.jsx';
import axios from '~/data/http/axios';
import moment from 'moment';
import { connect } from 'react-redux';
import i18n from '~/i18n';
import axios2 from 'axios'
import NoData from './noDataChart';

@connect((store) => {
    return { siteMaster: store.siteMaster, user: store.user }
})

export default class CampaignChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            campaignData: [],
            loaded: false,
            changingRate: false,
            groupOptions: [{
                value: 'HOUR',
                label: i18n.t('campaigns:main.chart.24 hours'),
                color: 'purple',
                format: 'HH:mm'
            }, {
                value: 'DAYOFWEEK',
                label: i18n.t('campaigns:main.chart.week'),
                color: 'red',
                format: 'ddd'
            }, {
                value: 'MONTHNAME',
                label: i18n.t('campaigns:main.chart.month'),
                color: 'green',
                format: 'MM-DD'
            }, {
                value: 'YEAR',
                label: i18n.t('campaigns:main.chart.year'),
                color: 'blue',
                format: 'MMM'
            }],
            currentGroupBy: 1,
            startDate: moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            opensData: [],
            clicksData: [],
            clicksLoaded: false,
            opensLoaded: false
        };

        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();

        this.getCampaigns = this.getCampaigns.bind(this);
        this.changeTimePeriod = this.changeTimePeriod.bind(this);
        this.finishGetCampaigns = this.finishGetCampaigns.bind(this);
    }

    componentDidMount() {
        this.getCampaigns();
    }

    componentWillUnmount() {
        this.source.cancel('I cancelled this')
        this.source = this.CancelToken.source();
    }

    changeTimePeriod(index) {

        var startDate = moment();
        var endDate = moment().endOf('day')
        switch (index) {
            case 0:
                startDate.subtract(23, 'hours');
                endDate = moment()
                break;
            case 1:
                startDate.subtract(6, 'days').startOf('day');
                break;
            case 2:
                startDate.subtract(4, 'weeks').startOf('week');
                endDate = moment().endOf('week')
                break;
            case 3:
                startDate.subtract(11, 'months');
                endDate = moment().endOf('month')
                break;
        }

        this.setState({ startDate: startDate.format('YYYY-MM-DD HH:mm:ss'), endDate: endDate.format('YYYY-MM-DD HH:mm:ss'), currentGroupBy: index, changingRate: true }, this.getCampaigns);
    }

    getCampaigns() {
        var self = this;
        var opensData = [];
        var clicksData = [];
        var thisGroupBy = this.state.groupOptions[this.state.currentGroupBy].value;

        this.setState({ opensLoaded: false, clicksLoaded: false })

        axios.get('/campaign/stats/graph?group=' + thisGroupBy + '&stat=opens&unique=true&start=' + this.state.startDate + '&end=' + this.state.endDate + '&returnUser=true&returnMaster=true', {
            cancelToken: this.source.token
        }).then((res) => {

            res.data.Stats.forEach(row => {
                opensData.push({
                    date: row.str,
                    opens: row.value
                })
            })
            self.setState({ opensData: opensData, opensLoaded: true }, this.finishGetCampaigns)
        }).catch(() => { })

        axios.get('/campaign/stats/graph?group=' + thisGroupBy + '&stat=clicks&unique=true&start=' + this.state.startDate + '&end=' + this.state.endDate + '&returnUser=true&returnMaster=true', {
            cancelToken: this.source.token
        }).then((res) => {
            res.data.Stats.forEach(row => {
                clicksData.push({
                    date: row.str,
                    clicks: row.value
                })
            })
            self.setState({ clicksData: clicksData, clicksLoaded: true }, this.finishGetCampaigns)
        }).catch(() => { })

    }

    finishGetCampaigns() {
        if (!this.state.opensLoaded || !this.state.clicksLoaded) return
        var finalData = this.state.opensData.map((theLine, index) => {
            var clicks = this.state.clicksData.find((theClick) => theClick.date === theLine.date)
            var thisDateTime = moment(theLine.date).locale(this.props.user.userData.language).format(this.state.groupOptions[this.state.currentGroupBy].format);
            return { date: thisDateTime.toUpperCase(), Opens: theLine.opens, Clicks: clicks ? clicks.clicks : [] }
        })
        this.setState({ campaignData: finalData, loaded: true, changingRate: false })
    }

    render() {
        const noData = this.state.loaded && !this.state.campaignData.some(d => d.Clicks && d.Opens)
        return <UILIB.Paper loadingOverlay={this.state.changingRate} iconRight={<UILIB.Icons icon="pencil" color="grey" />} iconRightClick={this.props.edit}>
            {(this.state.loaded == false) && <div style={{ height: 350 }}>
                <h4 className="mar-b5">{i18n.t('campaignStats')}</h4>
                <UILIB.LoadingIcons iconType="2" />
            </div>}

            {(this.state.loaded == true) && <div>
                <div className="quickFlex" style={{ justifyContent: "space-between", alignItems: "initial" }}>
                    <div>
                        <h4 className="mar-b5">{i18n.t('campaignStats')}</h4>
                        {!noData && <div className="quickFlex">
                            <div className="dot dot-primary mar-r5" />
                            <div className="mar-r10">{i18n.t('opens')}</div>
                            <div className="dot dot-secondary mar-r5" />
                            <div>{i18n.t('clicks')}</div>
                        </div>}
                    </div>
                    <div>
                        {this.state.groupOptions.map((theOpt, index) => {
                            let selected = false;
                            if (index === this.state.currentGroupBy) selected = true;
                            let label = i18n.t('campaigns:main.chart.' + theOpt.label);
                            return <UILIB.ButtonSimple key={"gt_" + index} className={"mar-l5"} selected={selected} onClick={() => { this.changeTimePeriod(index) }}>
                                {label}
                            </UILIB.ButtonSimple>
                        })}
                    </div>
                </div>

                {noData && <NoData primary={this.props.siteMaster.colours.$primary} secondary={this.props.siteMaster.colours.$secondary} />}

                {!noData && <ResponsiveContainer height={300}>
                    <AreaChart data={this.state.campaignData} margin={{ top: 10, right: 0, left: 20, bottom: 0 }}>

                        <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                        <YAxis axisLine={false} hide={true} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                        <Tooltip content={<UILIB.CustomTooltip name={[i18n.t('opens'), i18n.t('clicks')]} />} />
                        <defs>
                            <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={this.props.siteMaster.colours.$primary} stopOpacity={0.3} />
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                            </linearGradient>
                            <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={this.props.siteMaster.colours.$secondary} stopOpacity={0.3} />
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid horizontal={false} strokeDasharray="2 2" stroke="#DADADC" />
                        <Area
                            type="monotone"
                            dot={false}
                            name={i18n.t('opens')}
                            dataKey="Opens"
                            stroke={this.props.siteMaster.colours.$primary}
                            strokeWidth={2}
                            fillOpacity={1}
                            fill="url(#colorUv1)"
                            activeDot={{ stroke: this.props.siteMaster.colours.$primary, strokeWidth: 2, fill: 'white' }}
                        />
                        <Area type="monotone"
                            dot={false}
                            name={i18n.t('clicks')}
                            dataKey="Clicks"
                            stroke={this.props.siteMaster.colours.$secondary}
                            strokeWidth={2}
                            fillOpacity={1}
                            fill="url(#colorUv2)"
                            activeDot={{ stroke: this.props.siteMaster.colours.$secondary, strokeWidth: 2, fill: 'white' }}
                        />
                    </AreaChart>

                </ResponsiveContainer>}

            </div>
            }

        </UILIB.Paper>
    };
};
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import i18n from '~/i18n';
import UILIB from '~/Common-Objects/Lib';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';

class ViewSubs extends Component {
    constructor(props) {
        super(props);
        let searchTimer;
        this.state = {
            loading: true,
            results: [],
            paging: false,
            firstLoad: true,
            customFields: [],
            page: 0,
            limit: 20,
            searchText: "",
            hardCodedFields: [
                { value: 'score', label: i18n.t('subscribers:segments.edit.fields.score'), fieldType: "NUMBER", optionsType: "NUMBER" },
                { value: 'country', label: i18n.t('subscribers:segments.edit.fields.country'), fieldType: "TEXT", optionsType: "TEXT" },
                { value: 'createdAt', label: i18n.t('subscribers:segments.edit.fields.created'), fieldType: "DATE", optionsType: "DATE" },
                { value: 'updatedAt', label: i18n.t('subscribers:segments.edit.fields.updated'), fieldType: "DATE", optionsType: "DATE" },
                { value: 'lastOpened', label: i18n.t('subscribers:segments.edit.fields.opened'), fieldType: "DATE", optionsType: "DATE" },
                { value: 'lastClicked', label: i18n.t('subscribers:segments.edit.fields.clicked'), fieldType: "DATE", optionsType: "DATE" },
                { value: 'lastSent', label: i18n.t('subscribers:segments.edit.fields.lastSent'), fieldType: "DATE", optionsType: "DATE" },
                { value: 'lastBounced', label: i18n.t('subscribers:segments.edit.fields.lastBounced'), fieldType: "DATE", optionsType: "DATE" }
            ]
        }
        this.getCustomFields = this.getCustomFields.bind(this)
        this.getSubs = this.getSubs.bind(this)
        this.nextPage = this.nextPage.bind(this)
        this.doesSegmentIncludeCampaignsOrGroups = this.doesSegmentIncludeCampaignsOrGroups.bind(this)
        this.changeSearchText = this.changeSearchText.bind(this);
    }

    componentDidMount() {
        this.getCustomFields()
    }

    componentWillUnmount() {
        clearTimeout(this.searchTimer)
    }

    getCustomFields() {
        axios.get('/customfield').then(response => {
            this.setState({
                customFields: response.data.CustomFields,
                loading: false
            }, this.getSubs)
        })
    }

    doesSegmentIncludeCampaignsOrGroups(segment) {
        return segment.queryObj.and.some(f => f.customFieldId === 'campaignId' || f.customFieldId === 'GroupId') || segment.queryObj.or.some(f => f.customFieldId === 'campaignId' || f.customFieldId === 'GroupId')
    }

    changeSearchText(event) {
        this.setState({ searchText: event.target.value, page: 0 }, () => {
            if (this.searchTimer) clearTimeout(this.searchTimer)
            this.searchTimer = setTimeout(this.getSubs, 250)
        })
    }

    getSubs() {
        this.setState({
            paging: true
        })
        var queryStr = '?ignoreLog=1&limit=' + this.state.limit + '&offset=' + this.state.page * this.state.limit
        if (this.props.campaign.options && this.props.campaign.options.filterSegments && !this.props.segments.some(segment => !segment.include) && !this.props.segments.some(this.doesSegmentIncludeCampaignsOrGroups)) {
            queryStr += '&filterSegments=true'
        }
        if (this.state.searchText && this.state.searchText.length > 3) {
            queryStr += '&emailAddress=' + this.state.searchText
        }

        const body = {
            Segments: this.props.segments,
            Groups: this.props.groups,
        }

        if (this.props.campaign.type === 6 && this.props.campaign.options && this.props.campaign.options.smsField) {
            // its an sms so send up the smsfield too
            body.smsField = this.props.campaign.options.smsField
        }

        axios.post('/campaign/countresults' + queryStr, body).then(response => {
            this.setState({
                firstLoad: false,
                paging: false,
                results: response.data.rows.map(row => {
                    var obj = {
                        emailAddress: {
                            headerValue: 'Email Address',
                            value: row.emailAddress,
                        }
                    }
                    if (this.props.groups.length && row.GroupSubscribers && row.GroupSubscribers.length) {
                        obj.groups = {
                            headerValue: 'Groups',
                            value: <div style={{ display: 'flex', flexWrap: 'wrap', overflowY: 'auto', overflowX: 'hidden', maxHeight: '80px' }}>
                                {row.GroupSubscribers.filter(gs => !!gs.Group).map(gs => <UILIB.SquareChip key={gs.id} className="square-chip-purple mar-b5 mar-r5">{gs.Group.groupName}</UILIB.SquareChip>)}
                            </div>
                        }
                    }

                    if (this.props.campaign.type === 6 && this.props.campaign.options && this.props.campaign.options.smsField) {
                        // its an sms so send up the smsfield too
                        obj.phoneNumber = {
                            headerValue: 'Phone Number',
                            value: row.customFields[this.props.campaign.options.smsField]
                        }
                    }
                    response.data.fields.filter(f => parseInt(f) || this.state.hardCodedFields.some(h => h.value == f)).forEach(f => {
                        if (parseInt(f)) {
                            var field = this.state.customFields.find(cf => cf.id == f)
                            if (field) {
                                var val = row.customFields[field.id]
                                if (field.fieldType === 'SELECT') {
                                    var option = field.options.find(o => o.value == val)
                                    if (option) val = option.label
                                }

                                if (field.fieldType === 'MULTISELECT' && Array.isArray(val)) {
                                    val = val.map(v => {
                                        var option = field.options.find(o => o.value == v)
                                        if (option) v = option.label
                                        return v
                                    }).join(', ')
                                }
                                obj[field.fieldName] = {
                                    headerValue: field.fieldDesc,
                                    value: val
                                }
                            }
                        } else {
                            var field = this.state.hardCodedFields.find(h => h.value == f)
                            if (field) {
                                var val = row[field.value]
                                if (field.fieldType == 'DATE' && val) {
                                    val = DateTimeFunctions.formatDateTime(val, '2')
                                }
                                obj[field.fieldName] = {
                                    headerValue: field.label,
                                    value: val
                                }
                            }
                        }
                    })
                    return obj
                })
            })
        })
    }

    nextPage(currentPage) {
        this.setState({
            page: currentPage
        }, this.getSubs)
    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        return (
            <div>
                <h4 className="mar-b25">{i18n.t('campaigns:standard.step1.viewSubsPopup.title')}</h4>
                <UILIB.TextInput
                    placeholder={i18n.t('subscribers:overview.searchPlaceholder')}
                    onChange={this.changeSearchText}
                    value={this.state.searchText}
                    iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                    outerClassName="mar-b25"
                />

                <UILIB.Paper isLoading={this.state.paging}>
                    <UILIB.DataTable1
                        noResultsTxt={this.state.firstLoad ? '' : i18n.t('campaigns:standard.step1.viewSubsPopup.noSubscribersFound')}
                        tableData={this.state.results}
                        dataUpdater={this.nextPage}
                        width="100%"
                        pageSize="100"
                        hasCheckBoxes="no"
                    />

                </UILIB.Paper>

                <div className="text-center">
                    <UILIB.PagingBlock totalRows={this.props.count} pageSize={this.state.limit} numberOfLinks="10" limit={10000} currentPage={this.state.page}
                        changePage={this.nextPage} text={i18n.t('campaigns:standard.step1.viewSubsPopup.page') + ":"} />
                </div>


            </div>
        );
    }
}

export default ViewSubs;
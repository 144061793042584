import React, { useState, useEffect, useRef } from "react";
import UILIB from "~/Common-Objects/Lib";
import Button from "../button";
import * as styles from "./index.module.scss";

// You can either pass in a Promise to the onCtaClick function or use the submitting boolean to disable the CTAs.
// The Promise method is simpler as it means you don't need to track any submitting state in the component that consumes this AlertDialog. Once the Promise is resolved the dialog will be automatically closed.
// The submitting boolean may be necessary if you have additional children in the AlertDialog such as a <form> which has it's own onSubmit method.
export default function AlertDialog({
  open = false,
  title,
  description,
  children,
  ctaText,
  ctaVariant = "danger",
  submitting = false,
  onCtaClick,
  onOpenChange,
  onClose = () => { },
  disabled = false,
  ...rest
}) {
  const isMounted = useRef(true);
  const [isOpen, setIsOpen] = useState(open);
  const [isSubmitting, setIsSubmitting] = useState(submitting);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (onOpenChange) onOpenChange(isOpen);
    if (!isOpen) onClose()
  }, [isOpen]);

  useEffect(() => {
    setIsSubmitting(submitting);
  }, [submitting]);

  // This function is called whenever the "Cancel" button is clicked or the escape key is pressed.
  function handleOpenChange(e) {
    if (isSubmitting === false) {
      setIsOpen(e);
    }
  }

  async function handleCtaClick() {
    try {
      setIsSubmitting(true);
      let clickErr = await onCtaClick();

      if (clickErr && clickErr.error) {
        setIsSubmitting(false);
        return;
      }

      // Sometimes we may want to redirect after the onCtaClick() function is called, and so the component won't be mounted.
      // We need this conditional to avoid memory leaks.
      if (isMounted.current) {
        setIsOpen(false);
      }
    } finally {
      if (isMounted.current) {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <UILIB.AlertDialog.Root
      open={isOpen}
      onOpenChange={handleOpenChange}
      {...rest}
    >
      <UILIB.AlertDialog.Portal>
        <UILIB.AlertDialog.Overlay />
        <UILIB.AlertDialog.Content>
          <UILIB.AlertDialog.Title>{title}</UILIB.AlertDialog.Title>

          {description && (
            <UILIB.AlertDialog.Description
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}

          {children && <div className="mar-t24">{children}</div>}

          <div className={styles.ctas}>
            <Button
              variant={ctaVariant}
              size="s"
              loading={isSubmitting}
              onClick={handleCtaClick}
              disabled={isSubmitting || disabled}
            >
              {ctaText}
            </Button>

            <UILIB.AlertDialog.Cancel asChild>
              <Button variant="secondary" size="s" >
                Cancel
              </Button>
            </UILIB.AlertDialog.Cancel>
          </div>
        </UILIB.AlertDialog.Content>
      </UILIB.AlertDialog.Portal>
    </UILIB.AlertDialog.Root>
  );
}

import React, { useState } from 'react'
import UILIB from '~/Common-Objects/Lib'
import AddFieldDrawer from '~/pages/cp/subscribers/customfields/incAddCustomField'

export default function SmsCampaignField({ campaign, customFields = [], updateCampaign, countSubs }) {
    const [addingField, setAddingField] = useState(false)

    const availableFields = customFields.filter(f => f.fieldType === 'TELNO').map(f => ({ label: f.fieldDesc, value: f.id }))

    const addField = () => {
        setAddingField(true)
    }

    const finishedAddingField = (newField) => {
        customFields.push(newField)
        campaign.options.smsField = newField.id
        updateCampaign()
        countSubs()
        setAddingField(false)
    }

    const set = (e) => {
        campaign.options.smsField = e.currentTarget.value
        countSubs()
        updateCampaign()
    }

    return (
        <UILIB.Paper style={{padding: 24}}>
            <h6 className=" mar-b20">Phone Number Field</h6>
            {!availableFields.length && <div className="text-warning text-med text-light mar-b20">You don't have any Phone Number fields configured</div>}
            {Boolean(availableFields.length) && <div className="mar-b20">
                {console.log(campaign.options)}
                <UILIB.Select data={availableFields} value={campaign.options.smsField} onChange={set} placeholder="Select Phone Number Field" />
            </div>}
            <UILIB.Button text="Add New Field" onClick={addField} className="button-sml" />

            <UILIB.Drawer isOpen={addingField} persist={true} standalone={true} width={300} showClose={true} close={() => setAddingField(false)}>
                <AddFieldDrawer getCustomFields={finishedAddingField} standalone={true} forceType="TELNO" />
            </UILIB.Drawer>
        </UILIB.Paper>
    )
}
import React from 'react'
import UILIB from '../../../Common-Objects/Lib.jsx';
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import i18n from '~/i18n';
import PermissionChecker from '~/Classes/permissions';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import axios2 from 'axios'

//wizards stuff
import IncGoAddGroup from '../subscribers/groups/incGoAddGroup.jsx'
import DrawerAddCampaignStep1 from '~/pages/cp/campaigns/add/drawerAddCampaignStep1';
import FormGroupSelect from '~/pages/cp/forms/GroupSelect';
import AutomationThemeSelector from '~/pages/cp/includes/automations/automationThemeSelector'
import Capsule from './capsule';
import AppHooks from '../company/integrations/apps/genericCp/hooks2'

import SubscribersOpened from './components/subscribersOpened';
import NewSubscribers from './components/newSubscribers';
import YourPlan from './components/yourPlan';

import CampaignChart from './components/campaignChart';
import CampaignTable from './components/campaignTable';

import QuickTips from './components/quickTips';
import LatestNews from './latestNews';

import AutomationPanel from './components/automationPanel';
import SiteVisitsPanel from './components/sitevisitsPanel';

import ChangePanel from './components/changePanel';
import ChangeChart from './components/changeChart';
import ChangeTable from './components/changeTable'

import FormChart from './components/formChart.jsx';
import AutomationChart from './components/automationChart'
import SiteVisitTable from './components/sitevisitTable.jsx';
import AutomationTable from './components/automationTable.jsx';
import FormTable from './components/formTable.jsx';
import SubscribersClicked from './components/subscribersClicked.jsx';
import GettingStarted from './gettingStarted.jsx';
import { getUserSettings, setUserSettings } from '../../../Classes/userSettings.jsx'

@connect((store) => {
	return { siteMaster: store.siteMaster, browser: store.browser, user: store.user, accountMaster: store.accountMaster, permissionStore: store.permission }
})

export default class ControlTemplate extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loaded: false,
			transactions: {
				current: 0,
				last: 0,
				increase: 0,
				decrease: 0
			},
			campaigns: [],
			opens: {
				current: 0,
				last: 0,
				increase: 0,
				decrease: 0
			},
			clicks: {
				current: 0,
				last: 0,
				increase: 0,
				decrease: 0
			},
			subscribers: {
				current: 0,
				last: 0,
				increase: 0,
				decrease: 0
			},
			groupCount: 0,
			apps: null,
			customFields: [],
			settings: null
		}

		this.CancelToken = axios2.CancelToken;
		this.source = this.CancelToken.source();

		this.getCampaigns = this.getCampaigns.bind(this);
		this.getStats = this.getStats.bind(this);
		// this.checkTransaction = this.checkTransaction.bind(this)
		// this.getTransaction = this.getTransaction.bind(this)
		this.getGroupCount = this.getGroupCount.bind(this);
		this.addCampaign = this.addCampaign.bind(this);

		//wizard stuff
		this.addGroup = this.addGroup.bind(this);
		this.addForm = this.addForm.bind(this);
		this.addAutomation = this.addAutomation.bind(this);
		this.addAutomationBack = this.addAutomationBack.bind(this);

		this.viewAppActions = this.viewAppActions.bind(this)
		this.changePanel = this.changePanel.bind(this)
	}

	componentDidMount() {
		// this.transactionTimer = setInterval(this.getTransaction, 1000 * 10)
		axios.get('/accountMaster/application').then(res => {
			this.setState({ apps: res.data.Applications })
			this.getStats();
		})
		axios.get('/customfield').then(res => {
			this.setState({ customFields: res.data.CustomFields })
		})
		getUserSettings().then(s => {
			let save = false
			if (!s.frontpage) s.frontpage = {}
			if (!s.frontpage.panels && this.props.accountMaster.panels) {
				save = true
				s.frontpage.panels = this.props.accountMaster.panels
			}
			if (!s.frontpage.chart && this.props.accountMaster.chart) {
				save = true
				s.frontpage.chart = this.props.accountMaster.chart
			}
			if (!s.frontpage.table && this.props.accountMaster.table) {
				save = true
				s.frontpage.table = this.props.accountMaster.table
			}
			this.setState({ settings: s }, () => {
				if (save) this.saveSettings()
			})
		})
	}

	componentWillUnmount() {
		this.source.cancel('I cancelled this')
		this.source = this.CancelToken.source();
		if (this.transactionTimer) {
			clearInterval(this.transactionTimer)
		}
	}

	saveSettings() {
		setUserSettings(this.state.settings).catch(console.log)
	}

	getStats() {
		if (this.props.permissionStore.permissions.campaigns === 'none') return this.getGroupCount()
		axios.get('/campaign/overview', {
			cancelToken: this.source.token
		}).then(response => {
			var opens = response.data.Opens;
			var clicks = response.data.Clicks;
			var subscribers = response.data.Subscribers;
			opens.increase = 0;
			opens.decrease = 0;
			if (opens.current > opens.last) {
				opens.increase = Math.round((opens.current / opens.last) * 100) - 100;
			} else {
				opens.decrease = 100 - (Math.round((100 / opens.last) * opens.current));
			}

			clicks.increase = 0;
			clicks.decrease = 0;
			if (clicks.current > clicks.last) {
				clicks.increase = Math.round((clicks.current / clicks.last) * 100) - 100;
			} else {
				clicks.decrease = 100 - (Math.round((100 / clicks.last) * clicks.current));
			}

			subscribers.increase = 0;
			subscribers.decrease = 0;
			if (subscribers.current > subscribers.last) {
				subscribers.increase = Math.round((subscribers.current / subscribers.last) * 100) - 100;
			} else {
				subscribers.decrease = 100 - (Math.round((100 / subscribers.last) * subscribers.current));
			}

			this.setState({
				clicks,
				opens,
				subscribers
			}, this.getCampaigns)
		}).catch(() => { })
	}

	getCampaigns() {
		axios.get('/campaign?sortColumn=startDate&sortOrder=desc&startRecord=0&recordLimit=5&dontCount=true&status=2&status=3&status=4&status=5&status=6&status=7&status=8&status=9]', {
			cancelToken: this.source.token
		}).then(response => {
			this.setState({
				campaigns: response.data.Campaigns.map((row, index) => {
					return {
						campaignName: {
							headerValue: i18n.t('campaigns:main.campaignName'),
							value: <a onClick={() => this.props.history.push('/cp/campaigns/view/' + row.id)} style={{ fontWeight: 600 }}>{row.campaignName}</a>,
							orderBy: false
						},
						statsOpened: {
							headerValue: i18n.t('campaigns:main.statsOpened'),
							value: <UILIB.SmallLineGraph color="#7246B1" width="100" height="5" val1={row.stats['Unique Opens'] || 0} val2={row.stats['Total Subscribers'] || 0} />,
							orderBy: false
						},
						statsClicked: {
							headerValue: i18n.t('campaigns:main.statsClicked'),
							value: <UILIB.SmallLineGraph color="#65B5B1" width="100" height="5" val1={row.stats['Unique Clicks'] || 0} val2={row.stats['Total Subscribers'] || 0} />,
							orderBy: false
						},
						statsBounces: {
							headerValue: i18n.t('campaigns:main.statsBounced'),
							value: <UILIB.SmallLineGraph color="#CC4E32" width="100" height="5" val1={row.stats['Hard Bounces'] || 0} val2={row.stats['Total Subscribers'] || 0} />,
							orderBy: false
						}
					}
				})
			}, this.getGroupCount)
		}).catch(() => { })
	}

	addCampaign() {
		var drawerContent = <DrawerAddCampaignStep1 history={this.props.history} />
		this.props.dispatch(siteMaster.alterSiteDrawer(true, false, "left", drawerContent, true, 500, "drawer_nopad"));
	}
	getGroupCount() {
		if (this.props.permissionStore.permissions.subscribers === 'none') return this.setState({ loaded: true })
		axios.get('/group/count', {
			cancelToken: this.source.token
		}).then(response => {
			this.setState({ groupCount: response.data.Count, loaded: true })
		}).catch(() => { })
	}

	//Wizard stuff

	addGroup() {
		var drawerContent = <IncGoAddGroup addedGroup={(newGroup) => {
			this.props.history.push(`/cp/groups/${newGroup.data.groupId}/add/import`)
		}} />
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
	}

	addForm() {
		var drawerContent = <FormGroupSelect history={this.props.history} />
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));

	}
	addAutomation() {
		var drawerContent = <AutomationThemeSelector scratch={() => { this.props.history.push('/cp/automation/addnew') }} select={this.addAutomationBack} />;
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
	}
	addAutomationBack(theme) {
		axios.post('/automation/theme/' + theme.id).then(response => {
			this.props.history.push('/cp/automation/addnew/' + response.data.Automation.id)
		})
	}

	viewAppActions(app) {
		const accApp = app.AccountApplications[0]
		var drawerContent = <AppHooks appSettings={accApp.appSettings} app={app} appBinding={accApp} customFields={this.state.customFields} onChange={() => { }} />
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
	}
	runSiteAnalyzer() {
		this.props.history.push("/cp/brandanalyzer");
	}
	changePanel(index) {
		var drawerContent = <ChangePanel current={this.state.settings.frontpage?.panels} index={index} onSave={panels => {
			const settings = this.state.settings
			if (!settings.frontpage) settings.frontpage = {}
			settings.frontpage.panels = panels
			this.setState({ settings }, this.saveSettings)
		}} />;
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, 400));
	}

	changeChart() {
		var drawerContent = <ChangeChart current={this.state.settings.frontpage?.chart} onSave={chart => {
			const settings = this.state.settings
			if (!settings.frontpage) settings.frontpage = {}
			settings.frontpage.chart = chart
			this.setState({ settings }, this.saveSettings)
		}} />;
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, 400));
	}

	changeTable() {
		var drawerContent = <ChangeTable current={this.state.settings.frontpage?.table} onSave={table => {
			const settings = this.state.settings
			if (!settings.frontpage) settings.frontpage = {}
			settings.frontpage.table = table
			this.setState({ settings }, this.saveSettings)
		}} />;
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, 400));

	}

	render() {

		if (!this.state.loaded || !this.state.apps || !this.state.settings) return <div><UILIB.LoadingIcons iconType="2" /></div>

		var openProgress = 100;
		if (this.state.opens.decrease) {
			openProgress = (100 / this.state.opens.last) * this.state.opens.current;
		}

		var subProgress = 100;
		if (this.state.subscribers.decrease) {
			subProgress = (100 / this.state.subscribers.last) * this.state.subscribers.current;
		}

		var hasGroups = this.state.groupCount > 0;
		var hasCampaigns = this.state.campaigns.length > 0;
		// var hasCampaigns = false;

		let canSeeCampaignStats = true;
		if (!PermissionChecker("campaigns", this.props.permissionStore.permissions, "read")) canSeeCampaignStats = false
		const canCreateCampaigns = PermissionChecker("campaigns", this.props.permissionStore.permissions, "write")
		const canSeeGroups = PermissionChecker("subscribers", this.props.permissionStore.permissions, "read")

		var isAdmin = true;
		if (this.props.user.userData.roleId != 1) {
			hasGroups = true;
			hasCampaigns = true;
			isAdmin = false;
		}

		let hasDoneSiteAnalysis = false
		if (this.props.accountMaster.accountMaster.twitterAddress) {
			hasDoneSiteAnalysis = true
		}
		const capsule = this.state.apps.find(a => a.ApplicationId === 8)

		const panels = this.state.settings.frontpage?.panels || ['opens', 'newcontacts', 'billing']
		const chart = this.state.settings.frontpage?.chart || 'campaigns'
		const table = this.state.settings.frontpage?.table || 'campaigns'

		let showBillingBox = false;
		if (this.props.accountMaster.accountMaster.billedOnContacts) showBillingBox = true;
		if (this.props.siteMaster.siteId == "1") showBillingBox = true;
		if (!hasGroups || !hasCampaigns) {
			if (capsule) {
				return <Capsule accApp={capsule} />
			}

			if (this.props.siteMaster.siteId === 1) {
				return <GettingStarted />;
			}

			return <div className="frontpage">
				<UILIB.Row>
					<UILIB.Column xs={12} className="hide-xs">
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }} className="mar-b25">
							<div>
								<div className="mar-b10 page-header">{i18n.t('dashboard:welcomePage.welcomeTo')} {this.props.siteMaster.siteName}</div>
								{i18n.t('dashboard:welcomePage.subHeader')}
							</div>
							<div>
								{this.props.siteMaster.supportUrl && this.props.siteMaster.supportUrl.length &&
									<UILIB.Button className="button-md button-primary" iconLeft={<UILIB.Icons icon="lifering" style={{ height: 20, width: 20 }} />} onClick={() => { window.open(this.props.siteMaster.supportUrl, '_blank') }} text={i18n.t('dashboard:welcomePage.helpAndSupport')} />
								}
							</div>
						</div>
					</UILIB.Column>

					<UILIB.Column xs={12} margin={0}>
						<UILIB.Paper style={{ display: "flex", overflow: "hidden" }}>
							<div className="row">
								<div className="col-xs-12 col-spacer-bottom" style={{ display: 'flex', marginBottom: 25 }}>
									{hasGroups == false && <div className='circle-number' style={{ marginRight: 20 }}>1</div>}
									{hasGroups == true && <UILIB.Icons icon="tickCircle" color="#65B5B1" style={{ height: 32, width: 32, marginRight: 20 }} />}
									<div style={{ flex: "1", zIndex: "2" }} className="mar-b15">
										<h4 className="mar-b5">{i18n.t('dashboard:welcomePage.addSubscribers')}</h4>
										<div className="mar-b15">
											{i18n.t('dashboard:welcomePage.addSubscribersSub')}
										</div>
										<div style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>
											<UILIB.Button iconLeft={<UILIB.Icons icon="plus" />} text={i18n.t('dashboard:welcomePage.addSubscribersButt1')} className="button-md mar-r10 button-primary mar-b10" onClick={this.addGroup} />
											{(this.props.siteMaster.siteId != "2") && <UILIB.Button text={i18n.t('dashboard:welcomePage.addSubscribersButt2')} className="button-md" onClick={() => { this.props.history.push('/cp/company/integrations') }} />}
										</div>
									</div>
								</div>

								<div className="col-xs-12" style={{ display: 'flex' }}>
									{hasCampaigns == false && <div className='circle-number' style={{ marginRight: 20 }}>2</div>}
									{hasCampaigns == true && <UILIB.Icons icon="tickCircle" color="#65B5B1" style={{ height: 32, width: 32, marginRight: 20 }} />}
									<div style={{ flex: "1", zIndex: "2" }}>
										<h4 className="mar-b5">{i18n.t('dashboard:welcomePage.sendYourFirstCampaign')}</h4>
										<div className="mar-b15">
											{i18n.t('dashboard:welcomePage.sendYourFirstCampaignSub')}
										</div>
										<div style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>
											<UILIB.Button iconLeft={<UILIB.Icons icon="plus" />} text={i18n.t('dashboard:welcomePage.sendYourFirstCampaignButt1')} className="button-md mar-r10 button-primary" onClick={this.addCampaign} />
											<UILIB.Button text={i18n.t('dashboard:welcomePage.sendYourFirstCampaignButt2')} className="button-md" onClick={this.addCampaign} />
										</div>
									</div>
								</div>
							</div>
						</UILIB.Paper>
					</UILIB.Column>

					<UILIB.Column xs={12} margin={0}>
						<UILIB.Paper style={{ display: "flex", overflow: "hidden" }}>
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
								<div>
									<h4 className="mar-b5">{i18n.t('dashboard:welcomePage.siteAnalyzer.heading1')}</h4>
									<div>{i18n.t('dashboard:welcomePage.siteAnalyzer.heading2')}</div>
								</div>
								<UILIB.Button className="button-md" iconLeft={<UILIB.Icons icon="wand" />} onClick={this.runSiteAnalyzer.bind(this)}>{i18n.t('dashboard:welcomePage.siteAnalyzer.analyzeSite')}</UILIB.Button>
							</div>
						</UILIB.Paper>
					</UILIB.Column>

					<UILIB.Column xs={12} sm={6} className="mar-b25 m-mar-b0">
						<UILIB.Paper style={{ display: "flex", overflow: "hidden", height: "100%" }}>
							<div style={{ marginRight: 20 }}>
								<UILIB.SquareChip className="square-chip-purple">{i18n.t('dashboard:welcomePage.optional')}</UILIB.SquareChip>
							</div>
							<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
								<h4 className="mar-b5">{i18n.t('dashboard:welcomePage.capture')}</h4>
								<div className="mar-b5">{i18n.t('dashboard:welcomePage.captureSub')}</div>
								<div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
									<UILIB.Button text={i18n.t('dashboard:welcomePage.captureButt1')} className="button-md" onClick={this.addForm} />
								</div>
							</div>
						</UILIB.Paper>
					</UILIB.Column>

					<UILIB.Column xs={12} sm={6} className="mar-b25 m-mar-b0">
						<UILIB.Paper style={{ display: "flex", overflow: "hidden", height: "100%" }} className="m-mar-b0">
							<div style={{ marginRight: 20 }}>
								<UILIB.SquareChip className="square-chip-purple">{i18n.t('dashboard:welcomePage.optional')}</UILIB.SquareChip>
							</div>
							<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
								<h4 className="mar-b5">{i18n.t('dashboard:welcomePage.automate')}</h4>
								<div className="mar-b5">
									{i18n.t('dashboard:welcomePage.automateSub')}
								</div>
								<div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
									<UILIB.Button text={i18n.t('dashboard:welcomePage.automateButt1')} className="button-md" onClick={this.addAutomation} />
								</div>
							</div>
						</UILIB.Paper>
					</UILIB.Column>

					<UILIB.Column xs={12} sm={6} className="mar-b25 m-mar-b0">
						<UILIB.Paper style={{ display: "flex", overflow: "hidden", height: "100%" }} className="m-mar-b0">
							<div style={{ marginRight: 20 }}>
								<UILIB.SquareChip className="square-chip-purple">{i18n.t('dashboard:welcomePage.optional')}</UILIB.SquareChip>
							</div>
							<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
								<h4 className="mar-b5">{i18n.t('dashboard:welcomePage.sendTransactions')}</h4>
								<div className="mar-b5">{i18n.t('dashboard:welcomePage.sendTransactionsSub')}</div>
								<div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
									<UILIB.Button text={i18n.t('dashboard:welcomePage.sendTransactionsButt1')} className="button-md" onClick={() => { this.props.history.push("/cp/transactions") }} />
								</div>
							</div>
						</UILIB.Paper>
					</UILIB.Column>

					<UILIB.Column xs={12} sm={6} className="mar-b25">
						<UILIB.Paper style={{ display: "flex", overflow: "hidden", height: "100%" }}>
							<div style={{ marginRight: 20 }}>
								<UILIB.SquareChip className="square-chip-purple">{i18n.t('dashboard:welcomePage.optional')}</UILIB.SquareChip>
							</div>
							<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
								<h4 className="mar-b5">{i18n.t('dashboard:welcomePage.siteTracking')}</h4>
								<div className="mar-b5">{i18n.t('dashboard:welcomePage.siteTrackingSub')}</div>
								<div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
									<UILIB.Button text={i18n.t('dashboard:welcomePage.siteTrackingButt1')} className="button-md" onClick={() => { this.props.history.push("/cp/trackers") }} />
								</div>
							</div>
						</UILIB.Paper>
					</UILIB.Column>
				</UILIB.Row>
			</div>
		}

		return <div className="frontpage">

			<UILIB.Row>
				<UILIB.Column xs={12} sm={12} md={showBillingBox ? 4 : 6} lg={showBillingBox ? 4 : 6} margin={0}>
					{panels[0] === 'opens' && <SubscribersOpened opens={this.state.opens} edit={() => this.changePanel(0)} />}
					{panels[0] === 'clicks' && <SubscribersClicked clicks={this.state.clicks} edit={() => this.changePanel(0)} />}
					{panels[0] === 'newcontacts' && <NewSubscribers subscribers={this.state.subscribers} edit={() => this.changePanel(0)} />}
					{panels[0] === 'automations' && <AutomationPanel edit={() => this.changePanel(0)} />}
					{panels[0] === 'sitevisits' && <SiteVisitsPanel edit={() => this.changePanel(0)} />}
				</UILIB.Column>

				<UILIB.Column xs={12} sm={12} md={showBillingBox ? 4 : 6} lg={showBillingBox ? 4 : 6} margin={0}>
					{panels[1] === 'opens' && <SubscribersOpened opens={this.state.opens} edit={() => this.changePanel(1)} />}
					{panels[1] === 'clicks' && <SubscribersClicked clicks={this.state.clicks} edit={() => this.changePanel(1)} />}
					{panels[1] === 'newcontacts' && <NewSubscribers subscribers={this.state.subscribers} edit={() => this.changePanel(1)} />}
					{panels[1] === 'automations' && <AutomationPanel edit={() => this.changePanel(1)} />}
					{panels[1] === 'sitevisits' && <SiteVisitsPanel edit={() => this.changePanel(1)} />}
				</UILIB.Column>

				{showBillingBox && <UILIB.Column xs={12} sm={12} md={4} lg={4} margin={0}>
					<YourPlan history={this.props.history} />
				</UILIB.Column>}

			</UILIB.Row>

			{/* {this.props.siteMaster.siteId == "1" && <LatestNews />} */}

			{canSeeCampaignStats && <UILIB.Row>
				<UILIB.Column xs={12} sm={12} md={12} lg={this.props.siteMaster.siteId == "1" ? 8 : 12} margin="0" className="hide-xs">
					{chart === 'campaigns' && <CampaignChart edit={this.changeChart.bind(this)} />}
					{chart === 'forms' && <FormChart edit={this.changeChart.bind(this)} />}
					{chart === 'automations' && <AutomationChart edit={this.changeChart.bind(this)} />}
				</UILIB.Column>
				{this.props.siteMaster.siteId == "1" && <UILIB.Column xs={12} sm={12} md={12} lg={4} margin={0}>
					<QuickTips history={this.props.history} addCampaign={this.addCampaign} />
				</UILIB.Column>}
			</UILIB.Row>
			}


			{canSeeCampaignStats && <UILIB.Row>
				<UILIB.Column xs={12} sm={12} md={12} margin="0" >
					{table === 'campaigns' && <CampaignTable addCampaign={this.addCampaign} campaigns={this.state.campaigns} canCreateCampaigns={canCreateCampaigns} history={this.props.history} edit={this.changeTable.bind(this)} />}
					{table === 'automations' && <AutomationTable edit={this.changeTable.bind(this)} />}
					{table === 'forms' && <FormTable edit={this.changeTable.bind(this)} />}
					{table === 'sitevisits' && <SiteVisitTable edit={this.changeTable.bind(this)} />}
				</UILIB.Column>
			</UILIB.Row>}


		</div>
	};
};

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import siteVars from './Classes/siteVars'

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        load: 'languageOnly', // this stops loading en-GB and only loads en
        backend: {
            loadPath: '/public/locales/{{lng}}/{{ns}}.json',
            // crossDomain: true,
            queryStringParams: { v: siteVars.i18nVersion }, //allows the version to change to force a reload
            parse: function (data) {
                data = JSON.parse(data);
                data = data[Object.keys(data)[0]];
                return data
            },
        },
        fallbackLng: 'en',
        // have a common namespace used around the full app
        ns: ['translations', 'nav', 'account', 'company', 'permissions', 'dashboard', 'campaigns', 'subscribers', 'templates', 'login', 'public', 'form', 'helplinks', 'automation', 'spamtester', 'support', 'social', 'chat'],
        defaultNS: 'translations',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        react: {
            wait: true
        },
        parseMissingKeyHandler: function (key) {
            var split = key.split('.')
            if (split.length > 1) return split[split.length - 1]
            else {
                return key.split(':')[key.split(':').length - 1]
            }
        }
    });


export default i18n;
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import IncAddCustomField from '~/pages/cp/subscribers/customfields/incAddCustomField'
import AddGroupView from '~/pages/cp/subscribers/groups/incGoAddGroup';
import i18n from '~/i18n'
import AutoMergeTags from '~/pages/cp/includes/mergeTags/campaignMergeTags';
import moment from 'moment';
import HelpPopup from '../helpPopup/helpPopup';

class DrawerActionStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerContent: undefined,
            step: this.props.step,
            addingTag: false,
            newTag: '',
            newTagError: '',
            errors: {},
            tags: this.props.tags
        }

        this.updateValue = this.updateValue.bind(this);
        this.saveStep = this.saveStep.bind(this);
        this.updateDateValue = this.updateDateValue.bind(this);
        this.updateMultiSelect = this.updateMultiSelect.bind(this)
        this.createField = this.createField.bind(this);
        this.createdField = this.createdField.bind(this);
        this.createGroup = this.createGroup.bind(this);
        this.createdGroup = this.createdGroup.bind(this);
        this.openMergeTags = this.openMergeTags.bind(this);
        this.closeMergeTags = this.closeMergeTags.bind(this);
        this.insertMergeTag = this.insertMergeTag.bind(this);
        this.updateSpecific = this.updateSpecific.bind(this)
        this.addTag = this.addTag.bind(this)
    }

    updateValue(event) {
        var step = this.state.step;
        if (!step.stepOptions[event.target.name]) {
            step.stepOptions[event.target.name] = {};
        }
        step.stepOptions[event.target.name].value = event.target.value;
        step.complete = false;

        if (event.target.name === 'customFieldId') {
            const field = this.props.customFields.find(f => f.value === event.target.value);
            if (field?.fieldType === 'DATE') {
                step.stepOptions.customFieldValue.value = "";
            }
        }

        this.setState({ step })
    }

    updateSpecific(field, value) {
        let split = field.split('.')
        let step = this.state.step;
        for (let i = 0; i < split.length - 1; i++) {
            step = step[split[i]]
        }
        step[split[split.length - 1]] = value
        this.setState({ step: this.state.step })
    }

    updateMultiSelect(value) {
        var options = []
        var customField = this.props.customFields.find(fd => fd.value == this.state.step.stepOptions.customFieldId.value)
        if (customField) {
            options = customField.options || []
        }
        var option = options.find(o => o.value == value)
        if (option) value = option.value
        var step = this.state.step;
        if (!step.stepOptions.customFieldValue) {
            step.stepOptions.customFieldValue = {};
        }
        if (!step.stepOptions.customFieldValue.value || !Array.isArray(step.stepOptions.customFieldValue.value)) step.stepOptions.customFieldValue.value = [];

        var index = step.stepOptions.customFieldValue.value.indexOf(value)
        if (index > -1) {
            step.stepOptions.customFieldValue.value.splice(index, 1)
        } else {
            step.stepOptions.customFieldValue.value.push(value)
        }

        this.setState({ step })
    }

    updateDateValue(event, dateEvent) {
        var step = this.state.step;
        let val = dateEvent;
        if (moment.isMoment(val)) {
            val = dateEvent.format("YYYY-MM-DD");
        }
        step.stepOptions.customFieldValue.value = val;
        step.complete = false;
        this.setState({ step })
    }

    createField() {
        var drawerContent = <IncAddCustomField getCustomFields={this.createdField} standalone={true} />
        this.setState({ drawerContent })
    }
    createdField(newField) {
        var customFieldId = newField.id;
        var step = this.state.step;
        step.stepOptions.customFieldId.value = customFieldId;
        step.complete = false;
        this.setState({ drawerContent: undefined, step }, () => { this.props.getCustomFields(false); })
    }

    createGroup() {
        var drawerContent = <AddGroupView addedGroup={this.createdGroup} dontCloseDrawer={true} />
        this.setState({ drawerContent })
    }
    createdGroup(groupData) {
        var groupId = groupData.data.groupId;
        var step = this.state.step;
        step.stepOptions.groupId.value = groupId;
        step.complete = false;
        this.setState({ drawerContent: undefined, step }, () => { this.props.getGroups(false); })
    }

    addTag() {
        if (!this.state.newTag.trim() || this.state.newTag.trim().length < 3) {
            return this.setState({
                newTagError: 'Invalid Tag'
            })
        }
        axios.post('/tags', {
            tagName: this.state.newTag,
            tagType: 0
        }).then(res => {
            const tags = res.data
            this.props.setTags(tags)
            const newTag = tags.find(t => t.tagName === this.state.newTag)
            var step = this.state.step;
            if (!step.stepOptions.tagId) {
                step.stepOptions.tagId = {};
            }
            step.stepOptions.tagId.value = newTag.id + "";
            step.complete = false;
            this.setState({ step, tags, addingTag: false })
        }).catch(e => {
            this.setState({
                newTagError: e.data.error
            })
        })
    }


    saveStep() {
        var errors = {};
        var error = false;
        var step = this.state.step;

        if (!step.stepOptions.actionType.value || step.stepOptions.actionType.value == "-1") {
            error = true;
            errors.actionType = i18n.t('automation:add.action.errType')
        }

        if (step.stepOptions.actionType.value == "0") {
            if (step.stepOptions.customFieldId.value == "-1") {
                error = true;
                errors.customFieldId = i18n.t('automation:add.action.errField')
            }
            if ((step.stepOptions.customFieldValue.value + "") == "" || (step.stepOptions.customFieldValue.value + "").length < 1) {
                error = true;
                errors.customFieldValue = i18n.t('automation:add.action.errValue')
            }
        }

        if (step.stepOptions.actionType.value == "1" || step.stepOptions.actionType.value == "2" || step.stepOptions.actionType.value == "3" || step.stepOptions.actionType.value == "11") {
            if (step.stepOptions.groupId.value == "-1") {
                error = true;
                errors.groupId = i18n.t('automation:add.action.errGroup')
            }
        }

        if (step.stepOptions.actionType.value == "5") {
            if (step.stepOptions.groupId.value == "-1") {
                error = true;
                errors.groupId = i18n.t('automation:add.action.errGroup')
            }
            if (!step.stepOptions.subscriberStatus || !step.stepOptions.subscriberStatus.value || step.stepOptions.subscriberStatus.value == "-1") {
                error = true;
                errors.subscriberStatus = i18n.t('automation:add.action.errStatus')
            }
        }


        if (step.stepOptions.actionType.value == "9" || step.stepOptions.actionType.value == "10") {
            if (!step.stepOptions.tagId || !step.stepOptions.tagId.value) {
                error = true;
                errors.tagId = i18n.t('required')
            }
        }

        this.setState({ errors })

        if (!error || this.props.isThemeDesigner) {
            //finish
            step.complete = true;
            step.wizardComplete = true;
            this.props.saveStep()
        }

    }

    openMergeTags(fieldName) {
        var drawerContent = <AutoMergeTags hasWorkflowEmails={false} includeSubscriber={true} includeSubCustomFields={true} close={this.closeMergeTags} insertMergeTag={this.insertMergeTag} fieldName={fieldName} />
        this.setState({ drawerContent })
    }

    closeMergeTags() {
        this.setState({ drawerContent: undefined })
    }

    insertMergeTag(value, fieldName) {
        this.props.step.stepOptions[fieldName].value += " " + value;
        this.setState({ drawerContent: undefined })
    }

    render() {
        const { readOnly } = this.props;

        if (this.state.drawerContent) return this.state.drawerContent;

        var actionTypes = [
            { label: i18n.t('automation:add.action.action9'), value: "9" },
            { label: i18n.t('automation:add.action.action10'), value: "10" },
            { label: i18n.t('automation:add.action.action0'), value: "0" },
            { label: i18n.t('automation:add.action.action1'), value: "1" },
            // { label: i18n.t('automation:add.action.action2'), value: "2" },
            { label: i18n.t('automation:add.action.action3'), value: "3" },
            { label: i18n.t('automation:add.action.action4'), value: "4" },
            { label: i18n.t('automation:add.action.action11'), value: "11" },
            { label: i18n.t('automation:add.action.action5'), value: "5" },
            { label: i18n.t('automation:add.action.action6'), value: "6" },
            { label: i18n.t('automation:add.action.action7'), value: "7" },
            { label: i18n.t('automation:add.action.action8'), value: "8" }
        ]
        if (this.props.steps[0].triggerType && this.props.steps[0].triggerType.value && this.props.steps[0].triggerType.value != "3") {
            //remove the SEND TO START step unless they have chosen an anniversary trigger
            actionTypes = actionTypes.filter(f => f.value != 6)
        }

        var customFieldInputType = "TEXT"
        var selectedCustomField;

        if (this.state.step.stepOptions.customFieldId.value && this.state.step.stepOptions.customFieldId.value != "-1") {
            selectedCustomField = this.props.customFields.find(fd => fd.value == this.state.step.stepOptions.customFieldId.value)
            if (selectedCustomField) {
                customFieldInputType = selectedCustomField.fieldType;
            }
        }

        var optionText = 'SELECT HERE'
        if (customFieldInputType === 'MULTISELECT') {
            if (selectedCustomField.options && selectedCustomField.options.length && this.state.step.stepOptions.customFieldValue && this.state.step.stepOptions.customFieldValue.value && this.state.step.stepOptions.customFieldValue.value.length) {
                var selectedOptions = selectedCustomField.options.filter(o => this.state.step.stepOptions.customFieldValue.value.indexOf(o.value) > -1)
                optionText = selectedOptions.map(o => o.label).join(', ') || 'SELECT HERE'
            }
        }

        var theCustomFields = this.props.customFields.filter(obj => {
            return obj.value !== "emailAddress" && !obj.readOnly;
        });

        var allGroups = JSON.parse(JSON.stringify(this.props.groups));
        allGroups.unshift({ label: i18n.t('automation:add.action.allGroups'), value: "ALL" })

        const inputProps = {
            disabled: readOnly
        };

        return <div data-test="drawer-action-step">
            <div className="automationStepContentHolder">
                <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="mar-b25">{i18n.t('automation:add.action.header')}</h4>
                    <HelpPopup type={"action"} />
                </div>
                <div className="mar-b25">{i18n.t('automation:add.action.subheader')}</div>

                <UILIB.Select outerClassName="mar-b25" name="actionType" placeholder={i18n.t('automation:add.action.selectAction')} data={actionTypes} onChange={this.updateValue} value={this.state.step.stepOptions.actionType.value} error={this.state.errors.actionType} {...inputProps} />

                {this.state.step.stepOptions.actionType.value == "0" && theCustomFields.length < 1 && !this.props.isThemeDesigner && <UILIB.WarningHolder className="mar-b25">
                    {i18n.t('automation:add.action.noFields')} <button className="a" onClick={this.createField} dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.action.noFieldsAction') }} {...inputProps} />
                </UILIB.WarningHolder>}
                {this.state.step.stepOptions.actionType.value == "0" && !this.props.isThemeDesigner && theCustomFields.length > 0 && <div>

                    <UILIB.Select label={i18n.t('automation:add.action.selectField')}
                        labelOptions={<button className="a" onClick={this.createField} {...inputProps}>{i18n.t('automation:add.action.createField')}</button>}
                        placeholder="Select a custom field"
                        data={theCustomFields.filter(cf => !!parseInt(cf.value))}
                        name="customFieldId"
                        onChange={this.updateValue}
                        value={this.state.step.stepOptions.customFieldId.value}
                        error={this.state.errors.customFieldId}
                        outerClassName="mar-b25"
                        {...inputProps}
                    />

                    {this.state.step.stepOptions.customFieldId.value != "-1" && <div>

                        {customFieldInputType == 'SELECT' && <UILIB.Select outerClassName="mar-b25" label="Enter or choose a Value" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} data={selectedCustomField.options} error={this.state.errors.customFieldValue} {...inputProps} />}
                        {customFieldInputType == 'BOOLEAN' && <UILIB.Select outerClassName="mar-b25" label="Enter or choose a Value" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} data={[{ value: 0, label: i18n.t('automation:add.action.off') }, { value: 1, label: i18n.t('automation:add.action.on') }]} error={this.state.errors.customFieldValue} {...inputProps} />}
                        {customFieldInputType == 'MULTISELECT' && <UILIB.ButtonSelect outerClassName="mar-b25" label="Enter or choose a Value" headerText={optionText}>
                            {selectedCustomField.options && !!selectedCustomField.options.length && <ul>
                                {selectedCustomField.options.map((theOpt, optIndex) => {
                                    return <li key={theOpt.value} onClick={() => this.updateMultiSelect(theOpt.value)}>
                                        <UILIB.CheckBox disabled={true} outerClassName="mar-r10" name={theOpt.value} checked={this.state.step.stepOptions.customFieldValue.value.indexOf(theOpt.value) > -1} />
                                        <a>{theOpt.label}</a>
                                    </li>
                                })}
                            </ul>}
                            {(!selectedCustomField.options || !selectedCustomField.options.length) && <span>{i18n.t('automation:add.action.noOption')}</span>}
                        </UILIB.ButtonSelect>}
                        {customFieldInputType == "TEXT" && <div>
                            <UILIB.TextInput labelOptions={<button className="a" onClick={() => this.openMergeTags("customFieldValue")} {...inputProps}>Merge Tags</button>} outerClassName="mar-b25" type="text" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />
                        </div>}
                        {customFieldInputType == "NUMBER" && <div className="row" style={{ margin: '0 -12px' }}>
                            <div className="col-xs-6">
                                <UILIB.Select outerClassName="mar-b25" value={this.state.step.stepOptions.customFieldId.increment} onChange={ev => this.updateSpecific('stepOptions.customFieldId.increment', ev.currentTarget.value)} data={[{ label: 'Specific Value', value: '' }, { label: 'Increment By', value: 'inc' }, { label: 'Decrement By', value: 'dec' }]} {...inputProps} />
                            </div>
                            <div className="col-xs-6">
                                <UILIB.TextInput outerClassName="mar-b25" type="number" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />
                            </div>
                        </div>}
                        {customFieldInputType == "DATE" && <div>
                            <UILIB.Select outerClassName="mar-b25" data={[{ label: "The date the action takes place", value: "{{CURRENT_DATE}}" }, { label: "A specific date", value: moment(this.state.step.stepOptions.customFieldValue.value).isValid() ? this.state.step.stepOptions.customFieldValue.value : moment().format("YYYY-MM-DD") }]} onChange={(event) => { this.updateDateValue("customFieldValue", event.target.value) }} value={this.state.step.stepOptions.customFieldValue.value} placeholder="Please select a value" {...inputProps} />
                            {this.state.step.stepOptions.customFieldValue.value != "{{CURRENT_DATE}}" && this.state.step.stepOptions.customFieldValue.value != "" &&
                                <UILIB.TextInput outerClassName="mar-b25" type="date" name="customFieldValue" onChange={(event, newVal) => { this.updateDateValue("customFieldValue", newVal) }} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />
                            }
                        </div>}
                        {customFieldInputType == "TELNO" && <UILIB.TextInput outerClassName="mar-b25" type="TELNO" name="customFieldValue" onChange={(event, newVal) => { this.updateDateValue("customFieldValue", newVal) }} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}

                    </div>
                    }

                </div>}


                {(this.state.step.stepOptions.actionType.value == "1" || this.state.step.stepOptions.actionType.value == "2" || this.state.step.stepOptions.actionType.value == "3" || this.state.step.stepOptions.actionType.value == "5") && this.props.groups.length < 1 && !this.props.isThemeDesigner && <UILIB.WarningHolder>
                    <div><strong>{i18n.t('automation:add.action.noGroups')}</strong></div>
                    <button className="a" onClick={this.createGroup} {...inputProps}>{i18n.t('automation:add.action.noGroupsAction')}</button>
                </UILIB.WarningHolder>
                }
                {(this.state.step.stepOptions.actionType.value == "1" || this.state.step.stepOptions.actionType.value == "2" || this.state.step.stepOptions.actionType.value == "3" || this.state.step.stepOptions.actionType.value == "11") && this.props.groups.length > 0 && !this.props.isThemeDesigner && <div>

                    <UILIB.Select
                        label={i18n.t('automation:add.action.selectGroup')}
                        labelOptions={<button className="a" onClick={this.createGroup} {...inputProps}>{i18n.t('automation:add.action.createGroup')}</button>}
                        placeholder={i18n.t('automation:add.action.selectGroup')}
                        name="groupId" value={this.state.step.stepOptions.groupId.value}
                        data={this.props.groups}
                        onChange={this.updateValue}
                        error={this.state.errors.groupId}
                        outerClassName="mar-b25"
                        {...inputProps}
                    />
                </div>
                }

                {(this.state.step.stepOptions.actionType.value == "5" && !this.props.isThemeDesigner) && <div>

                    <UILIB.Select
                        outerClassName="mar-b25"
                        name="subscriberStatus"
                        value={this.state.step.stepOptions.subscriberStatus ? this.state.step.stepOptions.subscriberStatus.value : "-1"}
                        placeholder="Select a Status"
                        data={[{ label: i18n.t('Active'), value: "1" }, { label: i18n.t('In-Active'), value: "0" }]}
                        onChange={this.updateValue}
                        error={this.state.errors.subscriberStatus}
                        {...inputProps}
                    />

                    {this.state.step.stepOptions.subscriberStatus && this.state.step.stepOptions.subscriberStatus.value != "-1" && <div className="form-group">

                        <UILIB.Select
                            outerClassName="mar-b25"
                            label={i18n.t('automation:add.action.inGroup')}
                            placeholder={i18n.t('automation:add.action.selectGroup')}
                            name="groupId"
                            value={this.state.step.stepOptions.groupId.value}
                            data={allGroups}
                            onChange={this.updateValue}
                            error={this.state.errors.groupId}
                            {...inputProps}
                        />
                    </div>}
                </div>}

                {((this.state.step.stepOptions.actionType.value == "9" || this.state.step.stepOptions.actionType.value == "10") && !this.props.isThemeDesigner) && !this.state.addingTag && <div>
                    <UILIB.Select
                        outerClassName="mar-b25"
                        label="Select Tag"
                        labelOptions={<button className="a" onClick={() => this.setState({ addingTag: true })} {...inputProps}>Create New Tag</button>}
                        name="tagId"
                        value={this.state.step.stepOptions.tagId ? this.state.step.stepOptions.tagId.value : -1}
                        data={this.state.tags.map(t => ({ label: t.tagName, value: t.id, iconRight: t.Application && this.state.tags.length < 200 ? <UILIB.Avatar src={t.Application.appLogoUri} style={{ height: 20, width: 20 }} /> : '' }))}
                        placeholder="Select a Tag"
                        onChange={this.updateValue}
                        error={this.state.errors.tagId}
                        {...inputProps}
                    />
                </div>}
                {((this.state.step.stepOptions.actionType.value == "9" || this.state.step.stepOptions.actionType.value == "10") && !this.props.isThemeDesigner) && this.state.addingTag && <div>

                    <UILIB.TextInput label="New Tag Name" value={this.state.newTag} placeholder="Tag Name" onChange={e => this.setState({ newTag: e.target.value })} error={this.state.newTagError} {...inputProps} />
                    <div className="quickFlex mar-t10 mar-b25">
                        <UILIB.Button className="button-primary mar-r5" onClick={this.addTag} text="Save" {...inputProps} />
                        <UILIB.Button onClick={() => this.setState({ addingTag: false })} text="Cancel" {...inputProps} />
                    </div>

                </div>}
            </div>
            <div className="automationStepSaveButtonHolder">
                {readOnly ? (
                    <>
                        <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                    </>
                ) : (
                    <UILIB.Button className="button-primary" text={i18n.t('save')} onClick={this.saveStep} />
                )}
            </div>

        </div >
    }
}

export default DrawerActionStep;
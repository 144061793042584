import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';

@connect((store) => {
    return { site: store.siteMaster, account: store.accountMaster }
})
class SendOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    componentDidMount() {

    }


    render() {

        return (
            <>
                <div className="mar-b25">
                    <h3 className="mar-b15">Send and Track</h3>
                    <p >Create, send and track email campaigns, use site tracking to view website visitors and interact with them, send transactional emails, and more...</p>
                </div>

                <UILIB.Row>
                    <UILIB.Column xs={12} sm={6}>
                        <UILIB.Paper>
                            <h3 className="mar-b10">Campaigns</h3>
                            <p>Design and send email campaigns, and AB Split Tests</p>
                            <UILIB.Button text="Click Here" onClick={() => { this.props.history.push("/cp/campaigns") }} />
                        </UILIB.Paper>
                    </UILIB.Column>

                    <UILIB.Column xs={12} sm={6}>
                        <UILIB.Paper>
                            <h3 className="mar-b10">Website Tracking</h3>
                            <p>Track visitors around your website, interact via instant chat.</p>
                            <UILIB.Button text="Click Here" onClick={() => { this.props.history.push("/cp/trackers") }} />
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6}>
                        <UILIB.Paper>
                            <h3 className="mar-b10">Transactional Emails</h3>
                            <p>Send one off emails to your customers</p>
                            <UILIB.Button text="Click Here" onClick={() => { this.props.history.push("/cp/transactions") }} />
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
            </>
        );
    }
}

export default SendOverview;
import React, { useState, useEffect, useRef } from "react";
import UILIB from "~/Common-Objects/Lib";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'
import axios from "~/data/http/axios";
import axios2 from "axios";
import * as styles from "./index.module.scss";
import AddInboxDialogue from "./components/addInboxDialogue";
import InboxTemplate from './inboxView';
import i18n from "~/i18n";

export default function InboxPage({ userRole = null }) {
  const history = useHistory();
  const cancelToken = useRef(axios2.CancelToken)
  const source = useRef(null)

  const { activeInboxId } = useParams();

  const [loadingInbox, setLoadingInbox] = UILIB.useCallback(true);
  const [inboxes, setInboxes] = UILIB.useCallback([]);
  const [activeInbox, setActiveInbox] = UILIB.useCallback({});
  const [staff, setStaff] = UILIB.useCallback([]);
  const [addingInbox, setAddingInbox] = UILIB.useCallback(false);
  const [apps, setApps] = UILIB.useCallback([]);
  const [newMessages, setNewMessages] = useState(false);
  const documentFocused = useDocumentFocused()
  useTitle(activeInbox, documentFocused, newMessages)

  const refreshTimer = useRef(null);

  useEffect(() => {
    loadApps();
    return () => {
      if (source.current) {
        source.current.cancel('cancelled')
      }
      if (refreshTimer.current) {
        clearTimeout(refreshTimer.current);
      }

    }
  }, []);

  useEffect(() => {
    firstLoad();
  }, [activeInboxId])


  const startInboxTimer = () => {
    if (refreshTimer.current) clearTimeout(refreshTimer.current);
    refreshTimer.current = setTimeout(() => {
      refreshInboxes();
      startInboxTimer();
    }, 10000);
  }


  const firstLoad = async () => {
    if (refreshTimer.current) clearTimeout(refreshTimer.current);
    await setLoadingInbox(true);
    await initialiseInboxes();
    await loadStaff();
    startInboxTimer();
    await setLoadingInbox(false);
    refreshInboxes();
  };

  const initialiseInboxes = async () => {
    await setActiveInbox({});
    await setInboxes([]);
    const inboxes = await loadInboxes();
    if (!inboxes.length) {
      window.open('/cp/conversations', '_self');
    }
    await setInboxes(inboxes);
    let activeInbox = inboxes[0];
    if (activeInboxId && !isNaN(activeInboxId)) {
      const foundActive = inboxes.find(f => f.id == activeInboxId);
      if (foundActive) activeInbox = foundActive;
    }
    else {
      window.open(`/cp/conversations/inbox/${activeInbox.id}`, '_self')
      return;
    }
    await setActiveInbox(activeInbox);
  }

  const loadStaff = async () => {
    try {
      const newStaff = (await axios.get(`/inboxes/${activeInboxId}/user`)).data
      await setStaff(newStaff);
    }
    catch (err) {
      history.push("/cp")
    }
  }

  const loadApps = async () => {
    try {
      const res = (await axios.get('/application/account')).data.Applications
      await setApps(res)
    } catch (e) { }
  }

  const loadInboxes = async () => {
    try {
      if (source.current) {
        source.current.cancel('cancelled')
      }
      const newSource = cancelToken.current.source()
      source.current = newSource
      return (await axios.get("/inboxes?currentInboxId=" + activeInboxId, { cancelToken: newSource.token })).data
    }
    catch (err) {
      history.push("/cp");
    }
  }

  const changeInbox = async (inboxId) => {
    if (inboxId == activeInboxId) return;
    await setLoadingInbox(true);
    history.push(`/cp/conversations/inbox/${inboxId}`)
  }

  const refreshInboxes = async () => {
    const inboxes = await loadInboxes();
    const foundActive = inboxes.find(f => f.id == activeInboxId);
    await setActiveInbox(foundActive);
  }

  const addedInbox = async (newInbox) => {
    // await setAddingInbox(false);
    // await setActiveInbox({});
    // await setInboxes([]);
    // await setLoadingInbox(true);
    // await setStaff([]);
    // removed above for now
    window.open(`/cp/conversations/inbox/${newInbox.id}`, '_self')

  }


  if (loadingInbox) return <div className={styles.loading}>
    <UILIB.LoadingIcons />
  </div>;
  return (
    <>
      <InboxTemplate
        activeInbox={activeInbox}
        inboxes={inboxes}
        staff={staff}
        changeInbox={changeInbox}
        setAddingInbox={setAddingInbox}
        apps={apps}
        key={"ibx_" + activeInboxId}
        setNewMessages={setNewMessages}
        userRole={userRole} />

      {addingInbox && <>
        <AddInboxDialogue
          onCreated={addedInbox}
          onClose={(isOpen) => {
            setAddingInbox(isOpen);
          }
          }
        />
      </>}
    </>
  );


}

function useTitle(activeInbox, focused, newChats) {
  const site = useSelector(state => state.siteMaster)
  useEffect(() => {
    let title = site.siteName
    if (window.location.origin === 'https://web.staging.dcloud.mpztesting.com') {
      title = 'TEST | ' + title
    } else if (window.location.origin.indexOf('http://localhost:8080') === 0 || window.location.origin.indexOf('http://127.0.0.1') === 0) {
      title = 'DEV | ' + title
    }
    if (activeInbox?.name) {
      title += ' | ' + activeInbox.name
    }
    if (!focused && newChats) title = i18n.t('chat:inbox.newMessage')
    document.title = title
  }, [activeInbox, focused, site, newChats])

  return null
}

function useDocumentFocused() {
  const [isFocused, setIsFocused] = useState(true);

  useEffect(() => {
    const onFocus = () => setIsFocused(true);
    const onBlur = () => setIsFocused(false);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return isFocused;
}
import React, { useState } from 'react'
import UILIB from '../../../../Common-Objects/Lib'

export default function AutomationFindContact({ finished = () => { } }) {
    const [contact, setContact] = useState(null)

    return (
        <div>
            <h4 className='mar-b25'>Find a Contact in this Automation</h4>
            <div className='mar-b25'>Search for a contact by email address, view which step they are on and the route they have taken.</div>
            <div className='mar-b25'>If they are on a wait step you can skip the wait time.</div>
            <UILIB.AutoComplete
                outerClassName="mar-b25"
                iconLeft={<UILIB.Icons icon="person" />}
                label="Search for Contact by Email"
                url="/subscriber?search={{name}}"
                parser={d => d.Subscribers.map(a => ({ label: a.emailAddress, value: a.id }))}
                onChange={e => setContact(e.value)}
            />
            <UILIB.Button disabled={!contact} className="button-primary" onClick={() => finished(contact)}>View Contact</UILIB.Button>

        </div>
    )
}
import React from 'react'

export default function IconsSwitch({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00003 5.00008C7.73481 5.00008 7.48046 5.10544 7.29292 5.29297C7.10539 5.48051 7.00003 5.73486 7.00003 6.00008C7.00003 6.2653 7.10539 6.51965 7.29292 6.70719C7.48046 6.89472 7.73481 7.00008 8.00003 7.00008H13.586L12.293 8.29308C12.1109 8.48168 12.0101 8.73429 12.0124 8.99648C12.0146 9.25868 12.1198 9.50949 12.3052 9.6949C12.4906 9.88031 12.7414 9.98548 13.0036 9.98775C13.2658 9.99003 13.5184 9.88924 13.707 9.70708L16.707 6.70708C16.8945 6.51955 16.9998 6.26525 16.9998 6.00008C16.9998 5.73492 16.8945 5.48061 16.707 5.29308L13.707 2.29308C13.6148 2.19757 13.5044 2.12139 13.3824 2.06898C13.2604 2.01657 13.1292 1.98898 12.9964 1.98783C12.8637 1.98668 12.732 2.01198 12.6091 2.06226C12.4862 2.11254 12.3745 2.18679 12.2806 2.28069C12.1867 2.37458 12.1125 2.48623 12.0622 2.60913C12.0119 2.73202 11.9866 2.8637 11.9878 2.99648C11.9889 3.12926 12.0165 3.26048 12.0689 3.38249C12.1213 3.50449 12.1975 3.61483 12.293 3.70708L13.586 5.00008H8.00003ZM12 15.0001C12.2652 15.0001 12.5196 14.8947 12.7071 14.7072C12.8947 14.5197 13 14.2653 13 14.0001C13 13.7349 12.8947 13.4805 12.7071 13.293C12.5196 13.1054 12.2652 13.0001 12 13.0001H6.41403L7.70703 11.7071C7.80254 11.6148 7.87872 11.5045 7.93113 11.3825C7.98354 11.2605 8.01113 11.1293 8.01228 10.9965C8.01344 10.8637 7.98813 10.732 7.93785 10.6091C7.88757 10.4862 7.81332 10.3746 7.71943 10.2807C7.62553 10.1868 7.51388 10.1125 7.39098 10.0623C7.26809 10.012 7.13641 9.98668 7.00363 9.98783C6.87085 9.98898 6.73963 10.0166 6.61763 10.069C6.49562 10.1214 6.38528 10.1976 6.29303 10.2931L3.29303 13.2931C3.10556 13.4806 3.00024 13.7349 3.00024 14.0001C3.00024 14.2652 3.10556 14.5196 3.29303 14.7071L6.29303 17.7071C6.48163 17.8892 6.73424 17.99 6.99643 17.9878C7.25863 17.9855 7.50944 17.8803 7.69485 17.6949C7.88026 17.5095 7.98543 17.2587 7.9877 16.9965C7.98998 16.7343 7.88919 16.4817 7.70703 16.2931L6.41403 15.0001H12Z" fill={fillColor} />
        </svg>

    )
}
import React from "react";
import { useDispatch } from "react-redux";
import * as styles from "./index.module.scss";
import Header from "../../../components/header";
import i18n from "~/i18n";

export default function FacebookMessengerChannelVisibility({
    channel,
    onUpdateChannel,
}) {
    const dispatch = useDispatch();

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inbox_settings_left_content">
            <Header type="facebookMessenger" option="visibility" />
        </div>
    );
}

import React from 'react'

export default class IconsCrossCircle extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 18.0001C12.1217 18.0001 14.1566 17.1572 15.6569 15.6569C17.1571 14.1566 18 12.1218 18 10.0001C18 7.87833 17.1571 5.8435 15.6569 4.34321C14.1566 2.84292 12.1217 2.00006 10 2.00006C7.87827 2.00006 5.84344 2.84292 4.34315 4.34321C2.84285 5.8435 2 7.87833 2 10.0001C2 12.1218 2.84285 14.1566 4.34315 15.6569C5.84344 17.1572 7.87827 18.0001 10 18.0001ZM8.707 7.29306C8.5184 7.1109 8.2658 7.01011 8.0036 7.01239C7.7414 7.01467 7.49059 7.11983 7.30518 7.30524C7.11977 7.49065 7.0146 7.74146 7.01233 8.00366C7.01005 8.26586 7.11084 8.51846 7.293 8.70706L8.586 10.0001L7.293 11.2931C7.19749 11.3853 7.12131 11.4957 7.0689 11.6177C7.01649 11.7397 6.9889 11.8709 6.98775 12.0037C6.9866 12.1364 7.0119 12.2681 7.06218 12.391C7.11246 12.5139 7.18671 12.6256 7.2806 12.7195C7.3745 12.8133 7.48615 12.8876 7.60905 12.9379C7.73194 12.9882 7.86362 13.0135 7.9964 13.0123C8.12918 13.0112 8.2604 12.9836 8.3824 12.9312C8.50441 12.8788 8.61475 12.8026 8.707 12.7071L10 11.4141L11.293 12.7071C11.4816 12.8892 11.7342 12.99 11.9964 12.9877C12.2586 12.9855 12.5094 12.8803 12.6948 12.6949C12.8802 12.5095 12.9854 12.2587 12.9877 11.9965C12.99 11.7343 12.8892 11.4817 12.707 11.2931L11.414 10.0001L12.707 8.70706C12.8892 8.51846 12.99 8.26586 12.9877 8.00366C12.9854 7.74146 12.8802 7.49065 12.6948 7.30524C12.5094 7.11983 12.2586 7.01467 11.9964 7.01239C11.7342 7.01011 11.4816 7.1109 11.293 7.29306L10 8.58606L8.707 7.29306Z" fill={color} />
        </svg>





    }
}        
import React, { Component } from 'react';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import MergeTags from '~/pages/cp/includes/mergeTags/mergeTags'
import thestore from '~/data/store/store'
import DragDropFunctions from '../../../../../dragDropFunctions'

export default class MergeTagsHolder extends Component {

    constructor(props) {
        super(props);
        this.openMergeTags = this.openMergeTags.bind(this);
        this.stopClick = this.stopClick.bind(this);
    }

    stopClick(event) {
        event.stopPropagation();
    }
    openMergeTags() {
        var self = this;
        var theDrawer = <MergeTags mergeTags={DragDropFunctions.mergeTags} onTagClick={(theTag) => { this.props.mergeToolUpdate(theTag); this.props.startBlur(); thestore.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true, null)); }} />
        this.props.stopBlur();
        thestore.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, undefined, undefined, (event) => { self.props.startBlur(true); }));
    }


    render() {
        this.openMergeTags();
        return <div onClick={this.stopClick}></div>

    }
}
import React from 'react'

export default class IconsHistory extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00003 15.1962C7.52174 14.92 6.91015 15.0839 6.634 15.5622C6.35786 16.0405 6.52174 16.6521 7.00003 16.9282L7.00457 16.9308C8.52869 17.8094 10.3 18.1612 12.0443 17.9316C13.7903 17.7017 15.4117 16.9021 16.6569 15.6568C17.9022 14.4115 18.7018 12.7901 18.9316 11.0441C19.1614 9.29805 18.8087 7.52497 17.9281 5.99984C17.0476 4.47472 15.6883 3.28277 14.0613 2.60888C12.4342 1.93499 10.6303 1.81681 8.92921 2.27267C7.22815 2.72853 5.72504 3.73296 4.65301 5.13017C4.01306 5.96423 3.54808 6.91045 3.27723 7.91232L2.32969 6.27113C2.05355 5.79284 1.44196 5.62896 0.963668 5.90511C0.485375 6.18125 0.3215 6.79284 0.597643 7.27113L2.46896 10.5124C2.88318 11.2298 3.80056 11.4756 4.518 11.0614L7.75922 9.19007C8.23752 8.91393 8.40139 8.30234 8.12525 7.82405C7.84911 7.34575 7.23752 7.18188 6.75922 7.45802L5.23476 8.33817C5.4411 7.62235 5.78031 6.94645 6.23976 6.34763C7.04378 5.29972 8.17112 4.5464 9.44692 4.2045C10.7227 3.8626 12.0757 3.95124 13.296 4.45666C14.5163 4.96208 15.5357 5.85604 16.1961 6.99988C16.8565 8.14373 17.1211 9.47354 16.9487 10.7831C16.7763 12.0926 16.1767 13.3086 15.2427 14.2426C14.3088 15.1766 13.0927 15.7763 11.7832 15.9487C10.4737 16.1211 9.14389 15.8566 8.00003 15.1962Z" fill={color}/>
            <path d="M12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5C10.7348 5 10.4804 5.10536 10.2929 5.29289C10.1054 5.48043 10 5.73478 10 6V10C10.0001 10.2652 10.1055 10.5195 10.293 10.707L13.121 13.536C13.2139 13.6289 13.3242 13.7026 13.4456 13.7529C13.567 13.8032 13.6971 13.8291 13.8285 13.8291C13.9599 13.8291 14.09 13.8032 14.2114 13.7529C14.3328 13.7026 14.4431 13.6289 14.536 13.536C14.6289 13.4431 14.7026 13.3328 14.7529 13.2114C14.8032 13.09 14.8291 12.9599 14.8291 12.8285C14.8291 12.6971 14.8032 12.567 14.7529 12.4456C14.7026 12.3242 14.6289 12.2139 14.536 12.121L12 9.586V6Z" fill={color}/>
        </svg>



    }
}        
import React, { Component, useState } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';
import SiteVars from '~/Classes/siteVars';
import axios from '~/data/http/axios';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import UnsubOptionsDrawer from './unsubOptionsDrawer';
import UpdateOptionsDrawer from './updateOptionsDrawer';


@connect((store) => {
    return { accountMaster: store.accountMaster, permissionStore: store.permission, siteMaster: store.siteMaster }
})
class LandingPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            templateImageLoaded: false,
            imgSrc: "",
            rndNo: -1
        }
        this.unmounting = false;
        this.editTemplate = this.editTemplate.bind(this);
        this.templateOption = this.templateOption.bind(this);
        this.templateOptionClose = this.templateOptionClose.bind(this);
        this.checkTemplateImageExists = this.checkTemplateImageExists.bind(this)
        this.test = this.test.bind(this)
    }

    componentDidMount() {

        var options = this.props.accountMaster.accountMaster.options || {}
        var imgSrc = SiteVars.repoEndPoint + 'siteContent/templates/' + this.props.template.id + '.jpg'
        if (this.props.group) {
            if (this.props.group.options && this.props.group.options[this.props.template.lookup])
                imgSrc = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.id + '/templates/screenshots/template' + this.props.group.options[this.props.template.lookup] + '.jpg'
        } else if (options[this.props.template.lookup]) {
            imgSrc = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.id + '/templates/screenshots/template' + options[this.props.template.lookup] + '.jpg'
        }
        this.setState({ imgSrc }, () => {
            this.checkTemplateImageExists()
        })

    }

    componentWillUnmount() {
        this.unmounting = true
    }

    checkTemplateImageExists() {
        if (this.unmounting) return
        if (this.state.templateImageLoaded) return;

        var self = this;
        var img = new Image();
        img.onload = function () { self.setState({ templateImageLoaded: true }) };
        img.onerror = function () {
            self.imageCheckTimer = setTimeout(self.checkTemplateImageExists, 2000);
        }
        img.src = this.state.imgSrc;
    }

    editTemplate() {
        var options = this.props.accountMaster.accountMaster.options || {}
        if (this.props.group) {
            if (!this.props.group.options) this.props.group.options = {}
            if (this.props.group.options && this.props.group.options[this.props.template.lookup]) {
                this.props.history.push('/cp/templates/add/dragDrop/' + this.props.group.options[this.props.template.lookup] + '?redirect=/cp/groups/' + this.props.group.id + '/landingpages')
            } else {
                axios.get('/accountMaster/sitetemplate?type=' + this.props.template.default).then(response => {
                    axios.post('/template', {
                        templateHTML: response.data.Template.templateHTML,
                        templateJson: response.data.Template.templateJson,
                        templateType: 5,
                        templateName: this.props.template.name
                    }).then(response => {
                        this.props.group.options[this.props.template.lookup] = response.data.Template.templateID
                        return axios.put('/group/' + this.props.group.id + '/options', this.props.group.options)
                    }).then(() => {
                        this.props.history.push('/cp/templates/add/dragDrop/' + this.props.group.options[this.props.template.lookup] + '?redirect=/cp/groups/' + this.props.group.id + '/landingpages')
                    })
                })
            }
        } else if (options[this.props.template.lookup]) {
            this.props.history.push('/cp/templates/add/dragDrop/' + options[this.props.template.lookup] + '?redirect=/cp/company/landingpages')
        } else {
            axios.get('/accountMaster/sitetemplate?type=' + this.props.template.default).then(response => {
                axios.post('/template', {
                    templateHTML: response.data.Template.templateHTML,
                    templateJson: response.data.Template.templateJson,
                    templateType: 5,
                    templateName: this.props.template.name
                }).then(response => {
                    if (this.props.group) {
                        this.props.group.options[this.props.template.lookup] = response.data.Template.templateID
                        return axios.put('/group/' + this.props.group.id + '/options', this.props.group.options)
                    } else {
                        options[this.props.template.lookup] = response.data.Template.templateID
                        return axios.put('/accountMaster', this.props.accountMaster.accountMaster)
                    }
                }).then(() => {
                    this.props.history.push('/cp/templates/add/dragDrop/' + (this.props.group ? this.props.group.options[this.props.template.lookup] : options[this.props.template.lookup]) + '?redirect=' + (this.props.group ? '/cp/groups/' + this.props.group.id + '/landingpages' : '/cp/company/landingpages'))
                })
            })
        }
    }

    templateOption(templateType) {
        var drawerContent = <UnsubOptionsDrawer group={this.props.group} closeDrawer={this.templateOptionClose} history={this.props.history} />
        if (this.props.template.lookup == "updateTemplateId") {
            drawerContent = <UpdateOptionsDrawer group={this.props.group} closeDrawer={this.templateOptionClose} history={this.props.history} />
        }
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px", undefined, this.templateOptionClose));
    }

    templateOptionClose() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        axios.get('/accountuser/alert?ignoreLog=1')
            .then(response => {
            })
    }

    test(type) {
        var drawerContent = <Test type={type} group={this.props.group} onTest={async contact => {
            const res = await axios.post('/accountMaster/generateKey', {
                SubscriberId: contact.id,
                CampaignId: parseInt(contact.CampaignId) || undefined,
            })
            window.open(window.location.protocol + '//' + this.props.siteMaster.trackingDomain + '/api/' + this.props.template.type + '?key=' + res.data.key, '_blank')
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, 'right', null))

        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, 'right', drawerContent, true, 500))
    }



    render() {
        var options = this.props.accountMaster.accountMaster.options || {}
        return <UILIB.Column xs={12} sm={6} md={4} lg={3} key={this.props.index} >
            <div className="templateCard">
                <div className="tcImage">
                    <div className="tcImageImgHolder">
                        {this.state.templateImageLoaded &&
                            <div className="tcImageImg" style={{ backgroundImage: "url(" + this.state.imgSrc + ")" }} />
                        }
                        {!this.state.templateImageLoaded && <UILIB.LoadingIcons iconType="2" />}

                    </div>
                </div>
                <div className="tcContent">
                    <h4>{this.props.template.name}</h4>
                    <p>{this.props.template.desc}</p>
                    <div className="typeWrap">

                        <div className="tcButton" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div className="quickFlex">
                                <UILIB.Button className="button-sml no-shadow outline primary mar-r5" onClick={this.editTemplate}>{i18n.t('translations:edit')}</UILIB.Button>
                                {(this.props.template.lookup == "unsubscribeTemplateId" || this.props.template.lookup == "updateTemplateId") && <UILIB.Button className="button-sml no-shadow outline secondary" onClick={this.templateOption}>{i18n.t('translations:options')}</UILIB.Button>}
                            </div>
                            <UILIB.Button className="button-sml button-primary mar-l5" onClick={() => this.test(this.props.template.type)}>Preview</UILIB.Button>
                        </div>
                    </div>

                </div>
            </div>
        </UILIB.Column >
    }
}

export default LandingPage;

function Test({ onTest = () => { }, type, group }) {
    const [contact, setContact] = useState(null)

    let url = "/subscriber?search={{name}}"
    if (type === 'unsubscribe') url += "&sent=1"
    if (group) url += "&group=" + group.id
    return (
        <>
            <h4 className='mar-b25'>Preview this Landing page as a Contact</h4>
            {type === 'unsubscribe' && <p className='mar-b25'>This will show you what the unsubscribe page will look like for a contact. <br /><br /> You can only preview unsubscribes for contacts that have been sent an email</p>}
            {type === 'update' && <p className='mar-b25'>This will show you what the update page will look like for a contact</p>}
            {!!group && <p className='mar-b25'>The contact must be in this group to preview</p>}
            <UILIB.AutoComplete
                outerClassName="mar-b25"
                iconLeft={<UILIB.Icons icon="person" />}
                label="Search for Contact by Email"
                url={url}
                parser={d => d.Subscribers.map(a => ({ label: a.emailAddress, value: a.id + '_' + a.lastSent }))}
                onChange={e => setContact(e.value)}
            />
            <UILIB.Button disabled={!contact} className="button-primary" onClick={() => onTest({ id: contact.split('_')[0], CampaignId: contact.split('_')[1] })}>Select Contact</UILIB.Button>

        </>
    )
}
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';
import axios from '~/data/http/axios';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class changeResend extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resendAfter: 0
        }

        this.updateVal = this.updateVal.bind(this);
        this.confirm = this.confirm.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    componentDidMount() {
        this.setState({ resendAfter: this.props.campaign.resendAfter })
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    confirm() {
        axios.put(`/campaign/${this.props.campaign.id}/resendAfter`, { resendAfter: this.state.resendAfter }).then(() => {
            this.props.updateCampaigns();
            this.closeDrawer();
        })
    }

    updateVal(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    render() {

        var dataVals = [];
        var tCnt = 0;
        while (tCnt < 365) {
            dataVals.push({ value: tCnt, label: tCnt + " days" })
            tCnt++;
        }
        return <div>
            <h4 className="mar-b25">{i18n.t('campaigns:overview.changeResendHeader', { campaignName: this.props.campaign.campaignName })}</h4>
            <div className="mar-b25">{i18n.t('campaigns:overview.changeResendDesc')}</div>

            <UILIB.Select
                outerClassName="mar-b25"
                data={dataVals}
                value={this.state.resendAfter}
                onChange={this.updateVal}
                name="resendAfter"
                disabled={this.props.campaign.status === 'Sending'}
            />

            <div className="quickFlex mar-b20">
                <UILIB.Button text={i18n.t('save')} className="button-primary mar-r10" disabled={this.props.campaign.status === 'Sending'} onClick={this.confirm} />
                <UILIB.Button text={i18n.t('cancel')} className="" onClick={this.closeDrawer} />
            </div>

            {this.props.campaign.status === 'Sending' && <div className='text-red'>
                You cannot cancel this whilst a campaign is sending. Either wait for the campaign to finish or pause the campaign.
            </div>}

        </div>

    }
};



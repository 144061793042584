import React from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib';

import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class MergeTags extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mergeTags: [],
            pageLoaded: false,
            filter: '',
            search: ''
        }

        this.returnTag = this.returnTag.bind(this);
        this.loadMergeTags = this.loadMergeTags.bind(this);

    }

    componentDidMount() {
        if (this.props.mergeTags && this.props.mergeTags.length) {
            this.setState({ mergeTags: this.props.mergeTags })
        } else {
            this.loadMergeTags();
        }
    }

    returnTag(theTag) {
        this.props.onTagClick(theTag);
    }

    loadMergeTags() {
        var queryStr = ''
        if (this.props.campaign && this.props.campaign.AutomationId)
            queryStr += '?AutomationId=' + this.props.campaign.AutomationId

        axios.get('/mergeTags' + queryStr).then((res) => {
            var existing = []
            if (this.props.form) {
                existing.push({
                    tag: '{{{__link}}}',
                    description: 'The Link to Confirm their Email Address',
                    type: 1
                })
            }
            var mergeTags = existing.concat(res.data.MergeTags)
            mergeTags.forEach(tag => {
                if (!tag.fieldType) {

                    var tmpTag = tag.tag.replace(/\{/g, '').replace(/\}/g, '')
                    var newDesc = i18n.t('mergeTags.' + tmpTag);
                    if (newDesc) {

                        tag.description = newDesc;
                    }
                }
            })
            if (this.props.fromDesigner) {
                //remove some system merge tags when using Automation designer
                let foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{__REPLYADDRESS}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{__FROMADDRESS}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{__SENDKEY}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{__FROMNAME}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{__SUBJECT}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{SENDING_ID}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{CAMPAIGN_ID}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{SUB_DATE_JOINED}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{SUBSCRIBER_ID}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{SUB_EMAILADDRESS}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{SUB_LAST_UPDATED}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{{__OPEN_LINK}}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{{__UNSUBSCRIBE_URL}}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{{__JUNK_URL}}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{{__UNSUBSCRIBE_LINK}}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{{__UPDATE_LINK}}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{{__UPDATE_URL}}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{{__JUNK_LINK}}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{{__PREVIEW_URL}}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{{__PREVIEW_LINK}}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{{__GDPRYES}}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
                foundIndex = mergeTags.findIndex(f => f.type == 1 && f.tag == "{{{__GDPRNO}}}")
                if (foundIndex > -1) mergeTags.splice(foundIndex, 1);
            }

            if (this.props.landingPage) {
                let foundIndex
                foundIndex = mergeTags.findIndex(f => f.tag == "{{unsubscribeOptions}}")
                if (foundIndex === -1) mergeTags.push({ type: "1", tag: "{{unsubscribeOptions}}", description: "Unsubscribe Options" })
                foundIndex = mergeTags.findIndex(f => f.tag == "{{updateFields}}")
                if (foundIndex === -1) mergeTags.push({ type: "1", tag: "{{updateFields}}", description: "Update Contact Options" })
                foundIndex = mergeTags.findIndex(f => f.tag == "{{companyname}}")
                if (foundIndex === -1) mergeTags.push({ type: "1", tag: "{{companyname}}", description: "Account Name" })
                foundIndex = mergeTags.findIndex(f => f.tag == "{{profilelink}}")
                if (foundIndex === -1) mergeTags.push({ type: "1", tag: "{{profilelink}}", description: "Link to Contact Profile" })
            }

            this.setState({ mergeTags: mergeTags, pageLoaded: true });
        });
    }

    render() {
        if (!this.state.pageLoaded) {
            return <UILIB.LoadingIcons iconType="2" />
        }
        if (this.state.pageLoaded === true) {
            const others = {}
            const otherTypes = []
            const appTypes = Array.from(new Set(this.state.mergeTags.map(t => t.subType))).filter(t => !!t)
            const apps = {}
            appTypes.forEach(t => apps[t] = [])

            let mergeTags = this.state.mergeTags.slice()
            if (this.state.search) {
                mergeTags = mergeTags.filter(m => (m.tag || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || (m.description || '').toLowerCase().indexOf(this.state.search.toLowerCase()) > -1)
            }

            mergeTags.forEach(t => {
                if (t.type && !parseInt(t.type)) {
                    if (!others[t.type]) {
                        others[t.type] = []
                        otherTypes.push(t.type)
                    }
                    others[t.type].push(t)
                }
                if (t.type === "6") t.type = "1"
                if (t.subType) {
                    if (!apps[t.subType]) {
                        apps[t.subType] = []
                    }
                    apps[t.subType].push(t)
                }
            })

            var currentFilter = this.state.filter;
            var availableFilters = [
                { label: i18n.t('all'), value: "" },
                { label: i18n.t('templates:mergetags.system'), value: "1" },
                { label: i18n.t('templates:mergetags.custom'), value: "2" },
                { label: 'Organisation Fields', value: "3" }
            ]
            appTypes.forEach(t => availableFilters.push({ label: t, value: t }))
            otherTypes.forEach(t => availableFilters.push({ label: t, value: t }))
            if (this.props.filter && Array.isArray(this.props.filter) && this.props.filter.length) {
                availableFilters = availableFilters.filter(f => this.props.filter.indexOf(f.value) > -1)
            }

            return <div>

                <h4 className="mar-b25">{i18n.t('templates:mergetags.header')}</h4>
                <div className="mar-b25">{i18n.t('templates:mergetags.subHeader')}</div>

                <UILIB.Select
                    label={i18n.t('filter')}
                    value={currentFilter}
                    onChange={e => this.setState({ filter: e.currentTarget.value })}
                    data={availableFilters}
                    outerClassName="mar-b10" />

                <UILIB.TextInput label="Search" value={this.state.search} onChange={e => this.setState({ search: e.currentTarget.value })} outerClassName="mar-b25" placeholder="Search for Name or Merge Tag" />


                {(!this.state.filter || this.state.filter === "1") && <UILIB.Paper>
                    <h4 className="mar-b15">{i18n.t('templates:mergetags.system')}</h4>
                    {!!mergeTags.filter(theFilt => theFilt.type == 1).length && <UILIB.DataTable1
                        noResultsTxt={""}
                        tableData={mergeTags.filter(theFilt => theFilt.type == 1).map(theTag => {
                            return [{
                                headerValue: " ",
                                value: <a onClick={() => { this.props.onTagClick(theTag.tag) }}>{theTag.tag}</a>
                            },
                            {
                                headerValue: " ",
                                value: <div style={{ whiteSpace: "pre-wrap" }}>{theTag.description}</div>
                            }]
                        })}
                        loadingData={false}
                        dataUpdater={() => { }}
                        width="100%"
                        hasCheckBoxes="no"
                    />}

                    {!mergeTags.filter(theFilt => theFilt.type == 1).length && <div className='quickFlex text-md mar-t20 text-center'>
                        No Tags Found
                    </div>}
                </UILIB.Paper>}

                {(!this.state.filter || this.state.filter === "2") && <UILIB.Paper>
                    <h4 className="mar-b15">{i18n.t('templates:mergetags.custom')}</h4>

                    {!!mergeTags.filter(theFilt => theFilt.type == 2 && !theFilt.subType).length && <UILIB.DataTable1
                        noResultsTxt={""}
                        tableData={mergeTags.filter(theFilt => theFilt.type == 2 && !theFilt.subType).map(theTag => {
                            return [{
                                headerValue: " ",
                                value: <a onClick={() => { this.props.onTagClick(theTag.tag) }}>{theTag.tag}</a>
                            },
                            {
                                headerValue: " ",
                                value: <div style={{ whiteSpace: "pre-wrap" }}>{theTag.description}</div>
                            }]
                        })}
                        loadingData={false}
                        dataUpdater={() => { }}
                        width="100%"
                        hasCheckBoxes="no"
                    />}

                    {!mergeTags.filter(theFilt => theFilt.type == 2 && !theFilt.subType).length && <div className='quickFlex text-md mar-t20 text-center'>
                        No Tags Found
                    </div>}

                </UILIB.Paper>}


                {(!this.state.filter || this.state.filter === "3") && <UILIB.Paper>
                    <h4 className="mar-b15">Organisation Fields</h4>

                    {!!mergeTags.filter(theFilt => theFilt.type == 3).length && <UILIB.DataTable1
                        noResultsTxt={""}
                        tableData={mergeTags.filter(theFilt => theFilt.type == 3).map(theTag => {
                            return [{
                                headerValue: " ",
                                value: <a onClick={() => { this.props.onTagClick(theTag.tag) }}>{theTag.tag}</a>
                            },
                            {
                                headerValue: " ",
                                value: <div style={{ whiteSpace: "pre-wrap" }}>{theTag.description}</div>
                            }]
                        })}
                        loadingData={false}
                        dataUpdater={() => { }}
                        width="100%"
                        hasCheckBoxes="no"
                    />}
                    {!mergeTags.filter(theFilt => theFilt.type == 3).length && <div className='quickFlex text-md mar-t20 text-center'>
                        No Tags Found
                    </div>}

                </UILIB.Paper>}


                {appTypes.map(prop => {
                    if (this.state.filter && this.state.filter != prop) return ''
                    return <UILIB.Paper key={prop}>
                        <h4 className="mar-b15">{prop}</h4>
                        {!!apps[prop].length && <UILIB.DataTable1
                            noResultsTxt={""}
                            tableData={apps[prop].map(theTag => {
                                return [{
                                    headerValue: " ",
                                    value: <a onClick={() => { this.props.onTagClick(theTag.tag) }}>{theTag.tag}</a>
                                },
                                {
                                    headerValue: " ",
                                    value: <div style={{ whiteSpace: "pre-wrap" }}>{theTag.description}</div>
                                }]
                            })}
                            loadingData={false}
                            dataUpdater={() => { }}
                            width="100%"
                            hasCheckBoxes="no"
                        />}
                        {!apps[prop].length && <div className='quickFlex text-md mar-t20 text-center'>
                            No Tags Found
                        </div>}

                    </UILIB.Paper>

                })}

                {otherTypes.map(prop => {
                    if (this.state.filter && this.state.filter != prop) return ''
                    if (others[prop].length) return <UILIB.Paper key={prop}>
                        <h4 className="mar-b15">{prop}</h4>
                        <UILIB.DataTable1
                            noResultsTxt={""}
                            tableData={others[prop].map(theTag => {
                                return [{
                                    headerValue: " ",
                                    value: <a onClick={() => { this.props.onTagClick(theTag.tag) }}>{theTag.tag}</a>
                                },
                                {
                                    headerValue: " ",
                                    value: <div style={{ whiteSpace: "pre-wrap" }}>{theTag.description}</div>
                                }]
                            })}
                            loadingData={false}
                            dataUpdater={() => { }}
                            width="100%"
                            hasCheckBoxes="no"
                        />

                    </UILIB.Paper>

                    return <div className='quickFlex text-md mar-t20 text-center'>
                        No Tags Found
                    </div>
                })}


            </div >
        }
        else {
            return <div></div>
        }
    };
}

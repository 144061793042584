import React from 'react';
import { Link } from 'react-router-dom';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import queryString from 'query-string';
import axios2 from 'axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import PermissionChecker from '~/Classes/permissions';
import { getUserSettings, setUserSettings } from '~/Classes/userSettings.jsx';
import i18n from '~/i18n'
import IncGoTickSubs from './incGoTickSubs.jsx';
import BulkEdit from './bulkEdit'
import ReactTooltip from 'react-tooltip';
import GroupAutomate from './automate.jsx';
import { alterSiteDrawer } from '../../../../data/actions/siteActions.jsx';

const SELECTED_FIELDS_LIMIT = 15;

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, browser: store.browser, permissionStore: store.permission, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

export default class GroupSubscribersComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            group: undefined,
            loadingData: 1,
            groupID: 0,
            subscribers: [],
            totalGroupSubscribers: 0,
            totalGroupSubscribersFiltered: null,
            customFields: [],
            currentPage: 0,
            sortDirection: "desc",
            sortColumn: "createdAt",
            pageSize: 15,
            totalSubsInGroup: 0,
            searchText: "",
            deleteConfirm: false,
            selectedFields: [],
            selectedSubscriber: 0,
            startRecord: 0,
            retry: 0,
            segmentsLoadingStats: true,
            segmentsData: [],
            segmentName: "All",
            segmentId: 0,
            overrideCount: null,
            blacklistFilter: 0,
            drawerOptions: {
                isOpen: false,
                innerPopOpen: false,
                toastDelete: false,
                toastCopy: false,
                toastStatus: false,
                toastMove: false,
                tickedTot: 0,
                tickedData: []
            },
            isBlacklist: false,
            fieldsFilter: '',
            systemFields: [
                { fieldName: 'score', fieldDesc: i18n.t('score') },
                { fieldName: 'status', fieldDesc: i18n.t('status') },
                { fieldName: 'createdAt', fieldDesc: i18n.t('created') },
                { fieldName: 'updatedAt', fieldDesc: i18n.t('lastUpdated') },
                { fieldName: 'lastSendDate', fieldDesc: i18n.t('lastSent') },
                { fieldName: 'lastOpened', fieldDesc: i18n.t('lastOpened') },
                { fieldName: 'lastClicked', fieldDesc: i18n.t('lastClicked') },
                { fieldName: 'lastBounced', fieldDesc: i18n.t('lastBounced') },
                { fieldName: 'tags', fieldDesc: i18n.t('tags') },
            ],
            selectedSystemFields: [],
            tags: [],
            linkTags: [],
            settings: null
        };

        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();

        this.retryTimer = null;

        this.tableDataGetter = this.tableDataGetter.bind(this);
        this.changePage = this.changePage.bind(this);
        this.goSearchText = this.goSearchText.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.changeSearchText = this.changeSearchText.bind(this);
        this.goViewSubscriber = this.goViewSubscriber.bind(this);
        this.mapDataToState = this.mapDataToState.bind(this);
        this.checkBoxes = this.checkBoxes.bind(this);
        this.countChecked = this.countChecked.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.searchKeyPress = this.searchKeyPress.bind(this);
        this.goAddSubsciberPage = this.goAddSubsciberPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.getCustomFields = this.getCustomFields.bind(this);
        this.selectFields = this.selectFields.bind(this);
        this.reloadAfterTickedAndChanged = this.reloadAfterTickedAndChanged.bind(this);
        this.getGroup = this.getGroup.bind(this);
        this.getSegments = this.getSegments.bind(this);
        this.changeSegment = this.changeSegment.bind(this);
        this.bulkEdit = this.bulkEdit.bind(this);
        this.filterFields = this.filterFields.bind(this);
        this.toggleAllFields = this.toggleAllFields.bind(this);
        this.getTags = this.getTags.bind(this);
        this.selectTag = this.selectTag.bind(this);
        this.getLinkTags = this.getLinkTags.bind(this);
        this.selectLinkTag = this.selectLinkTag.bind(this);
    }
    //component state
    componentDidMount() {
        var groupID = this.props.match.params.groupID;
        var isBlacklist = true;
        if (this.props.accountMaster.accountMaster.blackListGroupId != groupID) {
            isBlacklist = false;
        }
        //see if we have write access to the subscribers
        var writeAccess = PermissionChecker("subscribers", this.props.permissionStore.permissions, "write");

        getUserSettings().then(s => {
            if (!s.subscribers) s.subscribers = {}
            let save = false
            if (!s.subscribers.limit && this.props.accountMaster.tableLimits?.subscribers) {
                save = true
                s.subscribers.limit = this.props.accountMaster.tableLimits.subscribers
            }
            if (!s.subscribers.systemFields && this.props.accountMaster.systemFields?.[this.props.accountMaster.id]) {
                save = true
                s.subscribers.systemFields = this.props.accountMaster.systemFields[this.props.accountMaster.id].fieldName
            }
            if (!s.subscribers.fields && this.props.accountMaster.fields?.[this.props.accountMaster.id]) {
                save = true
                s.subscribers.fields = this.props.accountMaster.fields[this.props.accountMaster.id]
            }
            if (save) setUserSettings(s).catch(console.log)
            const obj = {
                settings: s,
                selectedSystemFields: s.subscribers.systemFields ?
                    this.state.systemFields.filter(f => s.subscribers.systemFields.some(s => s === f.fieldName)) :
                    [...this.state.systemFields],
                pageSize: s.subscribers.limit || 15,
                groupID,
                isBlacklist,
                writeAccess,
            }
            const query = queryString.parse(location.search);
            if (query.sortColumn) obj.sortColumn = query.sortColumn
            if (query.sortDirection) obj.sortDirection = query.sortDirection
            if (query.searchText) obj.searchText = query.searchText
            if (query.segmentId) obj.segmentId = query.segmentId
            if (parseInt(query.recordLimit)) obj.pageSize = parseInt(query.recordLimit)
            if (parseInt(query.pageSize)) obj.pageSize = parseInt(query.pageSize)
            if (parseInt(query.startRecord)) obj.currentPage = parseInt(query.startRecord) / obj.pageSize

            this.setState(obj, () => this.getSegments(false, false, true))
        })
    }

    componentDidUpdate() {
        if (!this.props.siteMaster.siteDrawer) return
        if (this.props.siteMaster.siteDrawer.isOpen !== this.state.editing) {
            this.setState({
                editing: this.props.siteMaster.siteDrawer.isOpen
            })
        }
    }

    componentWillUnmount() {
        this.source.cancel('I cancelled this')
        this.source = this.CancelToken.source();

        if (this.timer) clearTimeout(this.timer)
        if (this.refreshTimer) clearInterval(this.refreshTimer)
        if (this.retryTimer) clearTimeout(this.retryTimer)
    }

    getSegments(refresh, includeSegmentCount, continueToGetTagsEtc) {
        var self = this;
        axios.get(`/segment?basicView=true${!includeSegmentCount ? `&simple=true` : ""}&groupId=${this.state.groupID}`, {
            cancelToken: this.source.token
        }).then(response => {

            var segmentsData = [{
                name: 'All',
                id: 0,
                system: true,
                subscriberCount: this.state.totalSubsInGroup,
                label: 'All'
            }].concat(response.data.Segments);
            segmentsData = segmentsData.map(seg => {
                var segmentLabel = seg.name;
                if (seg.id == "Active") segmentLabel = i18n.t('Active');
                if (seg.id == "In-Active") segmentLabel = i18n.t('In-Active')
                if (seg.id == "Unsubscribed") segmentLabel = i18n.t('Unsubscribed')
                if (seg.id == "Bounced") segmentLabel = i18n.t('Bounced')
                if (seg.id == "Blacklisted") segmentLabel = i18n.t('Blacklisted')

                return { id: seg.id, label: segmentLabel, name: seg.name, createdAt: seg.createdAt, subscriberCount: seg.subscriberCount, system: seg.system }
            })

            if (refresh) {
                this.setState({
                    segmentsData: segmentsData
                }, () => {
                    this.getGroup(refresh)
                })
            } else {
                var segmentId = 0;
                var segmentName = "All";
                if (self.state.isBlacklist) {
                    segmentsData = segmentsData.filter(seg => seg.name === "Blacklisted");
                    if (segmentsData && segmentsData[0]) {
                        segmentId = segmentsData[0].id;
                        segmentName = segmentsData[0].name;
                    }
                }
                if (!isNaN(this.state.segmentId) || this.state.segmentId === 'Unsubscribed' || this.state.segmentId === 'In-Active' || this.state.segmentId === 'Bounced' || this.state.segmentId === 'Active') {
                    const seg = segmentsData.find(s => s.id == this.state.segmentId)
                    if (seg) {
                        segmentId = seg.id;
                        segmentName = seg.name
                    }
                }
                let newState = {
                    segmentsData: segmentsData,
                    segmentName: segmentName,
                    segmentId: segmentId
                }
                if (includeSegmentCount) {
                    newState.segmentsLoadingStats = false
                }
                this.setState(newState, () => {
                    if (continueToGetTagsEtc) {
                        this.getTags()
                    }
                })
            }
        })
    }
    async getTags() {
        let tags = await axios.get(`/tags?tagType=0`)
        tags = tags.data.map(t => ({ ...t, selected: false }))
        let adTags = await axios.get(`/tags?tagType=3`)
        tags = tags.concat(adTags.data.map(t => ({ ...t, selected: false })))

        tags = tags.sort((a, b) => {
            if (a.ApplicationId > b.ApplicationId) return 0;
            if (a.ApplicationId < b.ApplicationId) return -1;
            return 0;
        })

        this.setState({ tags }, this.getLinkTags)
    }
    getLinkTags() {
        axios.get(`/tags?tagType=1`).then(data => {
            this.setState({
                linkTags: data.data.map(t => ({ ...t, selected: false }))
            }, this.getGroup)
        })
    }
    getGroup(refresh) {
        axios.get('/group/' + this.state.groupID, {
            cancelToken: this.source.token
        })
            .then(response => {
                if (response.data.Group.sync) {
                    this.timer = setTimeout(() => this.getGroup(refresh), 5000)
                }
                else {
                    clearTimeout(this.timer)
                    clearInterval(this.refreshTimer)
                }
                var segmentSubs = response.data.Group.totalSubscribers;
                var foundSeg = this.state.segmentsData.find(seg => seg.id == this.state.segmentId);
                if (foundSeg) {
                    segmentSubs = foundSeg.subscriberCount
                }

                // if (this.state.segmentsData?.length) {
                //     const all = this.state.segmentsData.find(s => s.id === 0)
                //     if (all) all.subscriberCount = response.data.Group.totalSubscribers
                // }
                this.setState({
                    segmentsData: this.state.segmentsData,
                    totalSubsInGroup: response.data.Group.totalSubscribers,
                    totalGroupSubscribers: response.data.Group.totalSubscribers,
                    group: response.data.Group,
                    // totalGroupSubscribersFiltered: segmentSubs
                }, () => {
                    if (!refresh) this.getCustomFields()
                    else this.tableDataGetter()
                })
            })
    }
    //links out
    goViewSubscriber(event, subscriberID) {
        this.props.history.push('/cp/subscriber/' + subscriberID);
    }
    goAddSubsciberPage() {
        this.props.history.push('/cp/groups/' + this.state.groupID + '/add/');
    }
    searchKeyPress(event) {
        if (event.key === 'Enter') {
            this.goSearchText();
        }
    }
    //checkBox Checking
    checkAll(event, forcedUntick) {

        var isChecked;

        if (event) {
            isChecked = event.target.checked;
        }
        if (forcedUntick && forcedUntick === true) {
            isChecked = false;
        }
        //create a copy of the array with object.extend
        var newState = Object.assign([], this.state.subscribers);
        for (var x in Object.keys(newState)) {
            var thisProp = newState[x];
            var checkBox = React.cloneElement(thisProp.checkBox.value, { checked: isChecked });
            var headerValue = React.cloneElement(thisProp.checkBox.headerValue, { checked: isChecked });
            thisProp.checkBox.isChecked = isChecked;
            thisProp.checkBox.value = checkBox;
            thisProp.checkBox.headerValue = headerValue;
            newState[x] = thisProp;
        }
        //write it back to state
        this.setState({
            subscribers: newState
        }, this.countChecked);
    }
    countChecked() {
        var opts = this.state.drawerOptions;
        //count number of text boxes checked

        var tickedSubs = this
            .state
            .subscribers
            .filter(sub => sub.checkBox.isChecked == true);

        var totTicked = tickedSubs.length;

        var showModal = false;
        if (totTicked > 0) {
            showModal = true;
        }

        var isOpen = false;
        var drawerContent = <div></div>;
        if (totTicked > 0) {
            isOpen = true;
            drawerContent = <IncGoTickSubs checkAll={this.checkAll} groupId={this.state.groupID} subscribers={this.state.subscribers} totTicked={totTicked} reloadAfterTickedAndChanged={this.reloadAfterTickedAndChanged} currentSegment={this.state.segmentName} isBlackList={this.state.isBlacklist} />
        }
        this.props.dispatch(siteMaster.alterSiteDrawer(isOpen, false, "right", drawerContent, true));
    }
    checkBoxes(event) {
        var thisDataID = event
            .target
            .name
            .replace('sub', '');
        var isChecked = event.target.checked;
        var newState = this.state.subscribers;
        var checkBox = React.cloneElement(newState[thisDataID].checkBox.value, { checked: isChecked });
        newState[thisDataID].checkBox.isChecked = isChecked;
        newState[thisDataID].checkBox.value = checkBox;
        this.setState({
            subscribers: newState
        }, this.countChecked);
    }
    reloadAfterTickedAndChanged(changedExpected) {
        this.checkAll(null, true);
        if (this.refreshTimer) clearInterval(this.refreshTimer)
        this.refreshTimer = setInterval(() => this.getSegments(true, true, true), 5000)
        this.getSegments(true, true, true);
    }
    //search options
    changeSearchText(event) {
        this.setState({
            searchText: event.target.value,
            page: 0
        }, () => {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(this.goSearchText, 250)
        })
    }
    goSearchText() {
        this.changePage(0, null, null, null, null);
    }
    //paging
    changePageSize(pageSize) {
        const settings = this.state.settings
        settings.subscribers.limit = pageSize
        setUserSettings(settings).catch(console.log)
        this.setState({ settings, pageSize: pageSize, currentPage: 0 }, this.changePage);
    }
    changePage(currentPage, sortDirection, sortColumn, pageSize, searchText) {

        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!currentPage && currentPage != "0") {
            currentPage = this.state.currentPage;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var startRecord = currentPage * pageSize;

        this.setState({ sortColumn: sortColumn, sortDirection: sortDirection, pageSize: pageSize, currentPage: currentPage, searchText: searchText, startRecord: startRecord }, () => this.tableDataGetter(sortColumn, sortDirection, startRecord, pageSize, searchText));

    }
    //subsriber data stuff
    tableDataGetter(sortColumn, sortDirection, startRecord, pageSize, searchText) {
        if (this.state.isLoading) {
            this.source.cancel('I cancelled this')
            this.source = this.CancelToken.source();
        }

        var customFieldArray = []
        this.state.customFields.map(field => {
            if (field.selected) {
                customFieldArray.push(field.id)
            }
        })

        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!startRecord) {
            startRecord = this.state.startRecord;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var queryStr = '?sortColumn=' + sortColumn + '&sortOrder=' + sortDirection + '&startRecord=' + startRecord + '&recordLimit=' + pageSize + '&searchText=' + encodeURIComponent(searchText)

        let selectedTags = [];
        if (this.state.tags) {
            selectedTags = this.state.tags.filter(t => t.selected)
        }
        if (selectedTags.length) {
            queryStr += `&tags=${selectedTags.map(tag => tag.id).join(",")}`
        }
        if (this.state.linkTags) {
            let selectedLinkTags = this.state.linkTags.filter(t => t.selected)
            if (selectedLinkTags.length) {
                queryStr += `&linkTags=${selectedLinkTags.map(tag => tag.id).join(",")}`
            }
        }
        if (history.pushState) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + queryStr + (this.state.segmentId ? '&segmentId=' + this.state.segmentId : '');
            window.history.pushState({ path: newurl }, '', newurl);
        }
        if (this.state.segmentId !== 'All' && this.state.segmentId) {
            switch (this.state.segmentId) {
                case 'Active':
                    queryStr += '&active=true'
                    break;
                case 'In-Active':
                    queryStr += '&inactive=true'
                    break;
                case 'Unsubscribed':
                    queryStr += '&unsubscribed=true'
                    break;
                case 'Bounced':
                    queryStr += '&bounced=true'
                    break;
                default:
                    queryStr += '&segmentId=' + this.state.segmentId;
                    break;
            }
        }


        queryStr += `&fields=${JSON.stringify(
            this.state.customFields
                .filter((f) => f.selected)
                .slice(
                    0,
                    SELECTED_FIELDS_LIMIT - this.state.selectedSystemFields.length
                )
                .map((f) => f.id)
        )}`

        if (this.state.isBlacklist && this.state.blacklistFilter > 0) {
            queryStr += `&blacklistFilter=${this.state.blacklistFilter}`
        }

        this.setState({
            isLoading: true,
            totalGroupSubscribersFiltered: null
        })

        var promise
        if (this.state.isBlacklist) {
            promise = axios.get('/segment/' + this.state.segmentId + '/results' + queryStr + '&count=false', {
                cancelToken: this.source.token
            })
        } else {
            promise = axios.get('/group/' + this.props.match.params.groupID + '/subscriber' + queryStr, {
                cancelToken: this.source.token
            })
        }

        promise.then((res) => {
            //map the subscribers data to data we can send to the table component.
            this.mapDataToState(res.data.Subscribers, res.data.count, res.data.retry);
            if (res.data.retry) {
                this.retryTimer = setTimeout(this.tableDataGetter, 1500)
            }
        }).catch(console.log);

    }
    mapDataToState(theData, count, retry) {
        const totalTags = theData.reduce((a, b) => a + (b.Tags?.length || 0), 0)

        var theSubscribersData = theData.map((thisLine, index) => {
            var finalStatus = i18n.t('Active');
            var finalStatusCol = "square-chip-green";
            if (!thisLine.active) {
                finalStatusCol = "square-chip-orange";
                finalStatus = i18n.t('In-Active');
            }
            if (thisLine.unsubscribed) {
                finalStatusCol = "square-chip-red";
                finalStatus = i18n.t('Unsubscribed');
            }
            if (thisLine.bounced) {
                finalStatusCol = "square-chip-purple";
                finalStatus = i18n.t('Bounced')
            }
            if (thisLine.blackListed) {
                finalStatus = i18n.t('Blacklisted')
                finalStatusCol = "square-chip-black";
            }

            if (!thisLine.score) thisLine.score = 0

            let selectedTags = [];

            if (thisLine.Tags && thisLine.Tags.length && this.state.tags.length) {
                selectedTags = thisLine.Tags
            }

            const important = this.state.customFields.filter(f => f.important).sort((a, b) => {
                return a.fieldDesc.localeCompare(b.fieldDesc)
            })

            var line = {
                rowDeleted: { value: false, order: -1 },
                checkBox: {
                    headerValue: <UILIB.CheckBox
                        outerClassName="no-marg"
                        checked={false}
                        onChange={(event) => {
                            this.checkAll(event)
                        }} />,
                    orderBy: false,
                    value: <UILIB.CheckBox
                        outerClassName="no-marg"
                        checked={false}
                        onChange={(event) => {
                            this.checkBoxes(event)
                        }}
                        name={"sub" + index} />,
                    isCheckBox: true,
                    width: 30,
                    isChecked: false,
                    order: -3
                },
                gravatar: {
                    headerValue: "",
                    value: <UILIB.Avatar style={{ height: 'auto', width: '100%', maxWidth: "40px", maxHeight: "40px", marginRight: "10px" }} src={thisLine.accountImage} />,
                    width: 50,
                    order: -2
                },
                emailAddress: {
                    headerValue: i18n.t('emailAddress'),
                    value: <div>
                        <Link style={{ textDecoration: 'none' }} to={'/cp/subscriber/' + (thisLine.subscriberId || thisLine.id)}>{thisLine.emailAddress}</Link>
                        {thisLine.Organisation ? <div>
                            <a className='text-sml text-grey' onClick={() => this.props.history.push('/cp/subscribers/organisations/' + thisLine.Organisation.id)}>{thisLine.Organisation.name}</a>
                        </div> : ''}
                    </div>,
                    orderBy: true,
                    emailText: thisLine.emailAddress,
                    order: -1
                },
                status: {
                    headerValue: i18n.t('status'),
                    value: <UILIB.SquareChip className={finalStatusCol + " small-icon"} iconLeft={<UILIB.Icons icon="circle" />}>{finalStatus}</UILIB.SquareChip>,
                    orderBy: false,
                    order: 1 + important.length,
                    align: "center"
                },
                score: {
                    headerValue: i18n.t('score'),
                    value: <UILIB.Score score={thisLine.score} />,
                    orderBy: true,
                    order: 2 + important.length,
                    width: 100
                },
                createdAt: {
                    headerValue: i18n.t('created'),
                    value: DateTimeFunctions.formatDateTime(thisLine.addedToGroup || thisLine.createdAt, '2'),
                    orderBy: true,
                    order: 3 + important.length
                },
                updatedAt: {
                    headerValue: i18n.t('lastUpdated'),
                    value: DateTimeFunctions.formatDateTime(thisLine.lastUpdated || thisLine.addedToGroup || thisLine.updatedAt, '2'),
                    orderBy: true,
                    width: 200,
                    order: 4 + important.length
                },
                lastSendDate: {
                    headerValue: i18n.t('lastSent'),
                    value: thisLine.lastSendDate ? DateTimeFunctions.formatDateTime(thisLine.lastSendDate) : <span className="text-light-grey">{i18n.t('never')}</span>,
                    orderBy: true,
                    order: 5 + important.length
                },
                lastOpened: {
                    headerValue: i18n.t('lastOpened'),
                    value: thisLine.lastOpened ? DateTimeFunctions.formatDateTime(thisLine.lastOpened) : <span className="text-light-grey">{i18n.t('never')}</span>,
                    orderBy: true,
                    order: 6 + important.length
                },
                lastClicked: {
                    headerValue: i18n.t('lastClicked'),
                    value: thisLine.lastClicked ? DateTimeFunctions.formatDateTime(thisLine.lastClicked) : <span className="text-light-grey">{i18n.t('never')}</span>,
                    orderBy: true,
                    order: 7 + important.length
                },
                lastBounced: {
                    headerValue: i18n.t('lastBounced'),
                    value: thisLine.lastBounced ? DateTimeFunctions.formatDateTime(thisLine.lastBounced) : <span className="text-light-grey">{i18n.t('never')}</span>,
                    orderBy: true,
                    order: 8 + important.length
                },
                tags: {
                    headerValue: i18n.t('tags'),
                    value: selectedTags.map(tag => {
                        let icon = ""
                        let text = ""
                        let color = ""
                        if (tag.tagType === 0) {
                            icon = "person"
                            text = "Contact Tag"
                            color = "square-chip-primary"
                        }
                        if (tag.tagType === 1) {
                            icon = "link"
                            text = "Link Tag"
                            color = "square-chip-secondary"
                        }
                        if (totalTags > 30) {
                            return <UILIB.SquareChip iconLeft={<UILIB.Icons icon={icon} color="white" style={{ height: 10, width: 10 }} alt={text} title={text} />} key={thisLine.id + "_tag_" + tag.id} className={"square-chip-tag " + color} >{tag.tagName || tag}</UILIB.SquareChip>
                        } else {
                            return <UILIB.SquareChip iconLeft={<UILIB.Icons icon={icon} color="white" style={{ height: 10, width: 10 }} data-tip={text} data-for={thisLine.id + '_tag_' + tag.id} />} key={thisLine.id + "_tag_" + tag.id} className={"square-chip-tag " + color} >{tag.tagName || tag}</UILIB.SquareChip>
                        }
                    }),
                    orderBy: false,
                    order: 9
                }
            }

            if (!PermissionChecker('subscribers', this.props.permissionStore.permissions, "write")) {
                delete line.checkBox;
            }

            this.state.systemFields.forEach(field => {
                if (!this.state.selectedSystemFields.some(f => f.fieldName === field.fieldName)) {
                    delete line[field.fieldName]
                }
            })

            important.concat(this.state.customFields.filter(f => !f.important).sort((a, b) => {
                return a.fieldDesc.localeCompare(b.fieldDesc)
            })).filter(f => f.selected).forEach((field, index) => {
                var value = '';
                var found = thisLine.customFields.find(f => f.id === field.id)
                if (found) {
                    if (!Array.isArray(found.value)) {
                        found.value = [found.value]
                    }
                    found.value.forEach((val, i) => {
                        if (field.fieldType == 'DATE') {
                            found.value[i] = (val ? DateTimeFunctions.formatDateTime(val, "2") : '')
                        } else if (field.fieldType == 'NUMBER') {
                            found.value[i] = NumberFunctions.formatNumber(val)
                        } else if (field.fieldType == 'BOOLEAN') {
                            found.value[i] = (val && (val == true || val == 1) ? 'Yes' : 'No')
                        } else if ((field.fieldType == 'SELECT' || field.fieldType == 'MULTISELECT') && field.options && field.options.length) {
                            var option = field.options.find(o => o.value == val)
                            found.value[i] = option ? option.label : val
                        }
                    })
                    value = found.value.join(', ')

                }
                line['' + field.id + ''] = {
                    headerValue: field.fieldDesc,
                    value,
                    orderBy: true,
                    width: 200,
                    order: field.important ? index : index + this.state.systemFields.length + 5
                }
            })

            var options = {
                subscriberData: {
                    id: thisLine.subscriberId ? thisLine.subscriberId : thisLine.id,
                    order: 100
                },
            }
            //adds multiple objects together to create on object.
            return Object.assign(line, options);
        })

        let wasLoadingData = this.state.loadingData;

        this.setState({
            subscribers: theSubscribersData,
            totalGroupSubscribersFiltered: count !== undefined ? count : this.state.totalGroupSubscribers,
            loadingData: 0,
            isLoading: retry,
            retry: retry ? this.state.retry + 1 : 0
        }, () => {
            if (wasLoadingData) {
                this.getSegments(false, true, false)
            }
            this.countChecked()
        });
    }
    //Fields
    getCustomFields() {
        axios.get('/customfield').then((res) => {
            var customFields = res.data.CustomFields.filter(cf => cf.entityType === 1 || cf.entityType === 3 || !cf.entityType);
            customFields.forEach(f => {
                var found = false
                if (this.state.settings.subscribers.fields) {
                    found = this.state.settings.subscribers.fields.some(r => r == f.id)
                }
                f.selected = found
            });
            this.setState({ customFields }, this.tableDataGetter)
        }).catch(() => {
            this.tableDataGetter()
        });
    }
    selectFields(field) {
        var self = this

        if (!field.id) {
            // its a system field
            let selectedSystemFields = this.state.selectedSystemFields
            const index = selectedSystemFields.findIndex(f => f.fieldName === field.fieldName)
            if (index > -1) {
                selectedSystemFields.splice(index, 1)
            } else {
                selectedSystemFields.push(field)
            }
            const settings = this.state.settings
            settings.subscribers.systemFields = selectedSystemFields.map(f => f.fieldName)
            setUserSettings(settings).catch(() => { })
            this.setState({ selectedSystemFields, settings }, this.changePage);
        } else {
            var newFields = this.state.customFields;
            newFields.forEach(x => {
                if (x.id == field.id) {
                    x.selected = !x.selected;
                }
            })

            const settings = this.state.settings
            settings.subscribers.fields = newFields.filter(f => f.selected).map(f => f.id)
            setUserSettings(settings).catch(console.log)
            this.setState({ customfields: newFields, settings }, this.changePage);
        }
    }
    changeSegment(segment) {
        // console.log(segment)
        // this.getSegments(false, true, true)
        this.setState({
            segmentName: segment.name || segment,
            segmentId: segment.id || segment
        }, this.tableDataGetter)
    }
    bulkEdit() {
        var segment = parseInt(this.state.segmentId) ? this.state.segmentsData.find(s => s.id == this.state.segmentId) : this.state.segmentId;
        var drawerContent = <BulkEdit
            id={this.state.groupID}
            selectedTags={this.state.tags.filter(t => t.selected)}
            selectedLinkTags={this.state.linkTags.filter(t => t.selected)}
            searchText={this.state.searchText}
            segment={segment}
            isBlackList={this.state.isBlacklist ? this.state.segmentId : false}
            segmentTotal={this.state.totalGroupSubscribersFiltered}
            totalSubs={this.state.totalSubsInGroup}
            close={(rebuild) => {
                if (rebuild) {
                    if (this.refreshTimer) clearInterval(this.refreshTimer)
                    this.refreshTimer = setInterval(() => this.getSegments(true, true, true), 5000)
                    this.getSegments(true, true, true)
                }
                this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, false));
            }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, 400));
    }
    filterFields(text) {
        this.setState({
            fieldsFilter: text.toLowerCase()
        })
    }
    toggleAllFields(select) {
        var self = this
        var newFields = this.state.customFields;

        newFields.forEach(x => {
            x.selected = select;
        })

        const selectedSystemFields = select ? [...this.state.systemFields] : []
        const settings = this.state.settings
        settings.subscribers.fields = newFields.map(f => f.if)
        settings.subscribers.systemFields = this.state.systemFields.map(f => f.fieldName)
        setUserSettings(settings).catch(console.log)

        this.setState({ customfields: newFields, selectedSystemFields }, self.changePage);
    }
    selectTag(event) {
        let self = this;
        let tags = this.state.tags;
        let found = tags.find(f => f.id == event.target.name);
        found.selected = event.target.checked;
        this.setState({ tags }, () => {
            if (self.timer) clearTimeout(self.timer)
            self.timer = setTimeout(() => {
                self.changePage(0, null, null, null, null);
            }, 1000)

        })

    }
    selectLinkTag(event) {
        let self = this;
        let linkTags = this.state.linkTags;
        let found = linkTags.find(f => f.id == event.target.name);
        found.selected = event.target.checked;
        this.setState({ linkTags }, () => {
            if (self.timer) clearTimeout(self.timer)
            self.timer = setTimeout(() => {
                self.changePage(0, null, null, null, null);
            }, 1000)

        })
    }

    getFields() {
        if (this.state.fieldsFilter) {
            return this.state.systemFields.concat(this.state.customFields).filter(f => f.fieldDesc.toLowerCase().indexOf(this.state.fieldsFilter) > -1);
        } else {
            return this.state.systemFields.concat(this.state.customFields);
        }
    }

    allowSelectingAllFields(fields) {
        if (fields.length < SELECTED_FIELDS_LIMIT) return true;
        return false;
    }

    getSelectedFields(fields) {
        const selectedFields = fields.filter((field) => {
            if (field.id && field.selected) return field;

            if (this.state.selectedSystemFields.some((f) => f.fieldName === field.fieldName)) {
                return field;
            }
        });

        return selectedFields.slice(0, SELECTED_FIELDS_LIMIT);
    }

    automate() {
        const drawerContent = <GroupAutomate groupName={this.state.group.groupName} GroupId={this.state.group.id} />
        this.props.dispatch(alterSiteDrawer(true, true, 'right', drawerContent, true, 500))
    }

    render() {
        const fields = this.getFields();
        const selectedFields = this.getSelectedFields(fields);

        var tags = this.state.tags
        var linkTags = this.state.linkTags
        if (this.state.tagsFilter) {
            tags = tags.filter(t => t.tagName.toLowerCase().indexOf(this.state.tagsFilter.toLowerCase()) > -1)
            linkTags = linkTags.filter(t => t.tagName.toLowerCase().indexOf(this.state.tagsFilter.toLowerCase()) > -1)
        }
        const filteredTags = this.state.tags.filter(t => t.selected)
        const filteredLinkTags = this.state.linkTags.filter(t => t.selected)
        const allFilteredTags = [...filteredTags, ...filteredLinkTags]

        var currentSegment = this.state.segmentsData.find(seg => seg.id == this.state.segmentId)
        if (!currentSegment) {
            currentSegment = {
                label: "",
                subscriberCount: 0
            }
        }

        if (this.state.totalGroupSubscribersFiltered !== null && !this.state.searchText) {
            currentSegment.totalGroupSubscribersFiltered = this.state.totalGroupSubscribersFiltered
        }

        return <div>
            <UILIB.Row className="middle-xs">
                <UILIB.Column xs={12} sm={6} className="mar-b25">
                    <UILIB.TextInput
                        placeholder={i18n.t('subscribers:overview.searchPlaceholder')}
                        value={this.state.searchText}
                        onChange={this.changeSearchText}
                        clickToChangeClicked={this.goSearchText}
                        onKeyPress={this.searchKeyPress}
                        onEnterPress={() => { }}
                        className="no-marg"
                        iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                    />
                </UILIB.Column>

                <UILIB.Column xs={12} sm={6} className="end-xs mar-b25" style={{ display: 'flex' }}>
                    {!this.state.isBlacklist && <UILIB.Button className="mar-r10" iconRight={<UILIB.Icons icon="lightning" />} onClick={this.automate.bind(this)}>Automate</UILIB.Button>}
                    {this.state.isBlacklist && <UILIB.Button
                        onClick={() => this.props.history.push('/cp/groups/' + this.state.groupID + '/imports')}
                        className="mar-r10"
                    >
                        {i18n.t('subscribers:nav.imports')}
                    </UILIB.Button>}
                    <UILIB.Button
                        onClick={this.goAddSubsciberPage}
                        className="button-primary"
                        iconLeft={<UILIB.Icons icon="plus" />}>
                        {i18n.t('subscribers:nav.addSubscribers')}
                    </UILIB.Button>
                </UILIB.Column>
            </UILIB.Row>


            <UILIB.Row className="hide-xs">
                <UILIB.Column xs={12} sm={8} style={{ display: "flex" }} className="mar-b25">
                    {this.state.isBlacklist && <>
                        <UILIB.Select
                            inlineLabel="Denylist Filter"
                            name="blackListFilter"
                            data={[
                                { label: "All Contacts", value: 0 },
                                { label: "Unsubscribed", value: 1 },
                                { label: "Bounced", value: 2 }]}
                            value={this.state.blacklistFilter}
                            onChange={(ev) => {
                                this.setState({ blacklistFilter: ev.target.value }, this.tableDataGetter)
                            }}
                        />
                    </>}
                    {!this.state.isBlacklist && <UILIB.Select
                        data={this.state.segmentsData.map(s => {
                            let label = s.label
                            if (s.id === this.state.segmentId && this.state.totalGroupSubscribersFiltered) label += ` (${NumberFunctions.formatNumber(this.state.totalGroupSubscribersFiltered)})`
                            let iconRight
                            if (parseInt(s.id)) iconRight = <UILIB.SquareChip small={true} className={'square-chip-secondary'}>  {i18n.t("segment")}</UILIB.SquareChip>
                            return {
                                value: s.id,
                                label: label,
                                iconRight
                            }
                        })}
                        inlineLabel="Filter"
                        hideIconInHeader={true}
                        outerClassName="mar-r10"
                        dropdownStyle={{ width: "100vw", maxWidth: "500px" }}
                        disabled={this.state.editing}
                        value={this.state.segmentId}
                        onChange={e => this.changeSegment(e.target.value)} />}
                    {!this.state.isBlacklist && <UILIB.ButtonSelect
                        fixedHeightLarge={true}
                        className="mar-r10"
                        headerText={i18n.t('subscribers:overview.allFields')}
                        filter={true}
                        updateFilter={this.filterFields}
                        selectAll={this.allowSelectingAllFields(fields) ? () => this.toggleAllFields(true) : false}
                        clearAll={() => this.toggleAllFields(false)}
                    >
                        {fields && !!fields.length && (
                            <>
                                {fields.length >= SELECTED_FIELDS_LIMIT && (
                                    <div
                                        className="text-heavy text-grey"
                                        style={{ padding: "8px 16px 0" }}
                                        data-test="selected-fields-limit-message"
                                    >
                                        {`Select up to ${SELECTED_FIELDS_LIMIT} fields`}
                                    </div>
                                )}

                                <ul data-test="fields-list">
                                    {fields.map((field) => {
                                        const selected = selectedFields.some((f) => f === field);
                                        const isCheckboxDisabled = selected === false && selectedFields.length >= SELECTED_FIELDS_LIMIT;

                                        return (
                                            <li key={field.id || field.fieldName} className="quickFlex" style={{ justifyContent: "space-between" }}>
                                                {isCheckboxDisabled && (
                                                    <ReactTooltip
                                                        id={field.fieldName}
                                                        effect='solid'
                                                    >
                                                        The maximum number of fields have been selected
                                                    </ReactTooltip>
                                                )}

                                                <div data-tip data-for={field.fieldName}>
                                                    <UILIB.CheckBox
                                                        name={field.fieldName}
                                                        onChange={() => this.selectFields(field)}
                                                        checked={selected}
                                                        disabled={isCheckboxDisabled}
                                                    >
                                                        {field.fieldDesc}
                                                    </UILIB.CheckBox>
                                                </div>

                                                {field.Application && field.Application.appLogoUri && (
                                                    <UILIB.Avatar
                                                        for={field.id}
                                                        tooltip={field.Application.appName}
                                                        src={field.Application.appLogoUri}
                                                        style={{
                                                            height: 20,
                                                            width: 20,
                                                            padding: 0,
                                                            marginRight: 10,
                                                        }}
                                                    />
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        )}
                        {(!fields || fields.length === 0) && <div style={{ padding: 16 }}>{i18n.t('subscribers:overview.noCustomFields')}</div>}
                    </UILIB.ButtonSelect>}

                    {!this.state.isBlacklist && <UILIB.ButtonSelect
                        fixedHeightLarge={true}
                        headerText={allFilteredTags.length ? allFilteredTags.length + ' Tags Selected' : i18n.t('subscribers:overview.selectTags')}
                        filter={true}
                        updateFilter={t => this.setState({ tagsFilter: t })}
                    >

                        {tags && !!tags.filter(tag => tag.tagType == 0).length && <>
                            <div className='text-heavy text-grey' style={{ padding: '5px 12px' }}>Contact Tags</div>
                            <ul>
                                {tags.filter(tag => tag.tagType == 0).map((tag) => {
                                    return <li key={tag.id} className="quickFlex" style={{ justifyContent: 'space-between' }}>
                                        <UILIB.CheckBox outerClassName="full-width" name={tag.id} onChange={this.selectTag} checked={tag.selected}>{tag.tagName}</UILIB.CheckBox>
                                        {tag.Application && tag.Application.appLogoUri && tags.length < 200 && <UILIB.Avatar for={tag.id} tooltip={tag.Application.appName} src={tag.Application.appLogoUri} style={{ height: 20, width: 20, padding: 0, marginRight: 10 }} />}
                                    </li>
                                })}
                            </ul>
                        </>}
                        {tags && !!tags.filter(tag => tag.tagType == 3).length && <>
                            <div className='text-heavy text-grey' style={{ padding: '5px 12px' }}>SiteTracker Sources</div>
                            <ul>
                                {tags.filter(tag => tag.tagType == 3).map((tag) => {
                                    return <li key={tag.id} className="quickFlex" style={{ justifyContent: 'space-between' }}>
                                        <UILIB.CheckBox outerClassName="full-width" name={tag.id} onChange={this.selectTag} checked={tag.selected}>{tag.tagName}</UILIB.CheckBox>
                                        {tag.Application && tag.Application.appLogoUri && tags.length < 200 && <UILIB.Avatar for={tag.id || tag.value} tooltip={tag.Application.appName} src={tag.Application.appLogoUri} style={{ height: 20, width: 20, padding: 0, marginRight: 10 }} />}
                                    </li>
                                })}
                            </ul>
                        </>}
                        {linkTags && !!linkTags.length && <>
                            <div className='text-heavy text-grey' style={{ padding: '5px 12px' }}>Link Tags</div>
                            <ul>
                                {linkTags.map((tag) => {
                                    return <li key={tag.value} className="quickFlex" style={{ justifyContent: 'space-between' }}>
                                        <UILIB.CheckBox outerClassName="full-width" name={tag.id} onChange={this.selectLinkTag} checked={tag.selected}>{tag.tagName}</UILIB.CheckBox>
                                    </li>
                                })}
                            </ul>
                        </>}
                        {(!tags || !tags.length) && (!linkTags || !linkTags.length) && <ul>
                            <li><a>No Tags Found</a></li>
                        </ul>}
                    </UILIB.ButtonSelect>}

                    {this.state.totalGroupSubscribersFiltered !== null && this.state.totalGroupSubscribersFiltered !== undefined && <div className='quickFlex mar-l10' style={{ alignItems: 'center' }}>
                        {/* <strong>{NumberFunctions.formatNumber(this.state.totalGroupSubscribersFiltered)} Contacts</strong> */}
                    </div>}
                </UILIB.Column>
                <UILIB.Column xs={12} sm={4} className="end-xs mar-b25 hide-xs" style={{ display: "flex", alignItems: "center" }} >
                    <div>{i18n.t('showing')}</div>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.pageSize === 15} onClick={() => { this.changePageSize(15) }}>15</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 50} onClick={() => { this.changePageSize(50) }}>50</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 500} onClick={() => { this.changePageSize(500) }}>500</UILIB.ButtonSimple>
                </UILIB.Column>

                {(this.state.loadingData == false && this.state.writeAccess == true && this.state.isBlacklist == false) && <UILIB.Column xs={12} className="end-xs mar-b25">
                    <UILIB.Button
                        text={i18n.t('subscribers:index.bulk')}
                        className="button mar-r10"
                        onClick={this.bulkEdit}
                    />
                    <UILIB.Button
                        text={i18n.t('subscribers:index.export')}
                        className="button"
                        iconRight={<UILIB.Icons icon="download" />}
                        onClick={() => { this.props.history.push(`/cp/subscribers/export?gid=${this.state.groupID}`) }}
                    />
                </UILIB.Column>}

            </UILIB.Row >


            <UILIB.DataTable1
                noResultsTxt={PermissionChecker('subscribers', this.props.permissionStore.permissions, "write") ? <a onClick={this.goAddSubsciberPage} className="hide-xs"> {i18n.t('subscribers:overview.noSubscribers')} </a> : i18n.t('subscribers:overview.noSubscribers2')}
                tableData={this.state.subscribers}
                dataUpdater={this.changePage}
                loadingData={this.state.loadingData}
                sortedColumn={this.state.sortColumn}
                sortedDirection={this.state.sortDirection}
                width="100%"
                pageSize={this.state.pageSize}
                hasCheckBoxes={PermissionChecker('subscribers', this.props.permissionStore.permissions, "write") ? "multi" : false}
            />


            <UILIB.Row>
                <UILIB.Column xs={12} className="center-xs mar-t25">
                    <UILIB.PagingBlock
                        totalRows={this.state.totalGroupSubscribersFiltered}
                        pageSize={this.state.pageSize}
                        numberOfLinks="10"
                        currentPage={this.state.currentPage}
                        changePage={this.changePage}
                        text={i18n.t('page') + ":"} />
                </UILIB.Column>
            </UILIB.Row >

            <UILIB.SnackBar message={i18n.t('subscribers:index.resync')} open={this.state.retry > 2} autoCloseDuration={5000} autoclose={true} dismiss={() => this.setState({ retry: 0 })} />

        </div >
    };

};

import React, { Component } from 'react';
import axios from '~/data/http/axios';
import ActionHistory from './actionHistory';
import ActionDesc from './actionExplainer'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'
import NumberFormatFunctions from '~/Classes/numberFormatFunctions'

@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster }
})
class Actions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            actions: {},
        }
        this.getHookActions = this.getHookActions.bind(this);
        this.viewActionHistory = this.viewActionHistory.bind(this)
        this.viewDescription = this.viewDescription.bind(this)
    }

    componentDidMount() {
        this.getHookActions()
    }

    getHookActions() {
        axios.get('/application/account/' + this.props.appBinding.id + '/actions').then(response => {
            this.setState({
                actions: response.data,
                loading: false
            })
        })
    }

    viewActionHistory(action) {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <ActionHistory action={action} app={this.props.appData} accApp={this.props.appBinding} goTo={this.props.history.push} />, true, "100vw"));
    }

    viewDescription(action) {
        const drawer = <ActionDesc action={action} app={this.props.appData} accApp={this.props.appBinding} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, true));
    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        return (
            <UILIB.Paper>
                <UILIB.Row className="mar-b25">
                    <UILIB.Column xs={8}>
                        <h4 className="mar-b10 quickFlex"><div style={{ display: 'inline-flex', marginRight: 10 }}><UILIB.Icons icon="envelope" circle={true} /></div> Campaign and Automation Action History</h4>
                        {this.props.subtitle}
                    </UILIB.Column>
                    <UILIB.Column xs={4} className="end-xs">
                        <UILIB.Button text="Configure" className="button-primary" iconRight={<UILIB.Icons icon="cog" />} onClick={this.props.setup} />
                    </UILIB.Column>
                </UILIB.Row>

                <div className="quickFlex">
                    {this.props.appData.options.hooks.filter(h => !h.automationonly).map((hook, index) => {
                        return <UILIB.Paper key={index} style={{ flexGrow: 1, marginLeft: index ? '24px' : '', padding: 20 }}>
                            <div style={{ fontWeight: 600 }} className='mar-b5'>{hook.name}</div>
                            <p>Count: {NumberFormatFunctions.formatNumber(this.state.actions[hook.action])}</p>
                            {Boolean(hook.description) && <UILIB.Button text="description" className="button-sml mar-r10" onClick={() => this.viewDescription(hook)} />}
                            <UILIB.Button text="History" className="button-sml" onClick={() => this.viewActionHistory(hook.action)} />
                        </UILIB.Paper>
                    })}
                </div>
            </UILIB.Paper>
        );
    }
}

export default Actions;
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})
export default class IncAddCustomField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '',
        };

        this.closeDrawer = this.closeDrawer.bind(this);
        this.goDeleteField = this.goDeleteField.bind(this);

    }

    goDeleteField() {
        this.setState({
            error: ''
        })
        axios.delete('/customfield/' + this.props.fieldId)
            .then((res) => {
                this.props.getCustomFields();
                if (this.props.alert && typeof this.props.alert === 'function') {
                    this.props.alert('Field Deleted')
                }
                this.closeDrawer();

            }).catch(e => {
                this.setState({
                    error: e.data.error
                })
            })
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }


    render() {
        return <div>

            <h4 className="mar-b25">{i18n.t('subscribers:customfields.deleteHeader')}</h4>
            <div className="mar-b15">{i18n.t('subscribers:customfields.deleteSubHeader1')}</div>
            <div className="mar-b25">{i18n.t('subscribers:customfields.deleteSubHeader2')}</div>

            <UILIB.Button
                text={i18n.t('subscribers:customfields.deleteButton')}
                className="button-red mar-b25"
                onClick={this.goDeleteField}
                iconLeft={<UILIB.Icons icon="cross" />}
            />
            {!!this.state.error && <div>
                <p className='text-red'>{this.state.error}</p>
            </div>}
        </div >

    }
};


import React from 'react'
import ElementEditor from './elementEditor/elementEditor'

export default class DragDropRightTools extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDragging: false,
            holderWidth: 550
        }

        this.dragStart = this.dragStart.bind(this);
        this.dragEnd = this.dragEnd.bind(this);
        this.dragging = this.dragging.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.dragStart, false);
        document.addEventListener("mouseup", this.dragEnd, false);
        document.addEventListener("mousemove", this.dragging, false);
    }

    componentWillUnmount() {

        document.removeEventListener("mousedown", this.dragStart, false);
        document.removeEventListener("mouseup", this.dragEnd, false);
        document.removeEventListener("mousemove", this.dragging, false);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.selectedElement != this.props.selectedElement || this.props.marketOpen != nextProps.marketOpen || this.state.holderWidth != nextState.holderWidth) {
            return true;
        }
        return false;
    }

    dragStart(event) {
        if (event.target.id == "moveBar") {
            this.setState({ isDragging: true });
        }
    }
    dragEnd() {
        this.setState({ isDragging: false });
    }
    dragging(event) {
        if (this.state.isDragging) {
            event.preventDefault();
            var currX = document.body.clientWidth - event.clientX;
            if (currX < 495) currX = 495;

            this.setState({ holderWidth: currX });
        }
    }
    render() {

        var classes = "blocksPanel animatedPanel";
        if (this.props.selectedElement.isSelected === true) {
            classes += " expanded"
        }


        var style = {};

        if (this.state.isDragging) {
            style.transition = "none";
        }

        if (this.props.selectedElement.isSelected === true) {
            style.flex = "0 1 " + this.state.holderWidth + "px";
        }

        if (this.props.marketOpen) {
            style.width = "0px";
            style.flex = "0 0 0%"
            style.overflow = "hidden";
        }

        if (this.props.selectedElement.isSelected == false && this.props.templateType) {
            style.width = "0px";
            style.flex = "0 0 0%"
            style.overflow = "hidden";
        }



        return <div className={classes} style={style}>
            {this.props.selectedElement.isSelected === true && <div style={{ height: "100%", position: "relative" }}>
                <div style={{ width: "5px", height: "100%", backgroundColor: "#999999", cursor: "move", position: "absolute", left: "-5px", top: "0px" }} id="moveBar"></div>
                <div className="dd_elementEditorOuter">
                    <ElementEditor landingPage={this.props.landingPage} isLandingPage={this.props.isLandingPage} campaign={this.props.campaign} selectedElement={this.props.selectedElement} elementsData={this.props.templateJson} deselectElement={this.props.deselectElement} updateElementsData={this.props.updateElementsData} templateId={this.props.templateId} saveFaveColor={this.props.saveFaveColor} />
                </div>
            </div>
            }
            {this.props.selectedElement.isSelected === false &&
                <div style={{ height: "100%" }}>

                    <div className="editorTabs editorTabs-lineUnder" >
                        <div className="editorTab selected">Style</div>
                    </div>

                    <ElementEditor landingPage={this.props.landingPage} isLandingPage={this.props.isLandingPage} campaign={this.props.campaign} selectedElement={this.props.selectedElement} elementsData={this.props.templateJson} deselectElement={this.props.deselectElement} updateElementsData={this.props.updateElementsData} templateId={this.props.templateId} noTitleBar={true} />

                </div>
            }



        </div>
    }
}
import React from 'react'

export default class IconsLinkedIn extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7273 3H4.27273C3.56955 3 3 3.56955 3 4.27273V15.7273C3 16.4305 3.56955 17 4.27273 17H15.7273C16.4305 17 17 16.4305 17 15.7273V4.27273C17 3.56955 16.4305 3 15.7273 3ZM7.42527 14.4545H5.548V8.41418H7.42527V14.4545ZM6.46755 7.55064C5.86236 7.55064 5.373 7.06 5.373 6.45609C5.373 5.85218 5.863 5.36218 6.46755 5.36218C7.07082 5.36218 7.56145 5.85282 7.56145 6.45609C7.56145 7.06 7.07082 7.55064 6.46755 7.55064ZM14.4571 14.4545H12.5811V11.5171C12.5811 10.8165 12.5684 9.91536 11.6055 9.91536C10.6287 9.91536 10.4785 10.6784 10.4785 11.4662V14.4545H8.60255V8.41418H10.4035V9.23955H10.4289C10.6796 8.76482 11.2918 8.264 12.205 8.264C14.1058 8.264 14.4571 9.51509 14.4571 11.1416V14.4545Z" fill={color} />
        </svg>


    }
}        
import React from 'react'
import UILIB from '~/Common-Objects/Lib.jsx'
import i18n from '~/i18n'

import IncGoDeleteSubs from './incGoDeleteSubs.jsx';
import IncGoCopySubs from './incGoCopySubs.jsx';
import IncGoMoveSubs from './incGoMoveSubs.jsx';
import IncGoChangeStatus from './incGoChangeStatus.jsx';

export default class InGoTickSubs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clickedDrawer: null
        };

        this.clickOption = this.clickOption.bind(this);
        this.goBack = this.goBack.bind(this);
        this.closeAll = this.closeAll.bind(this);

    }

    clickOption(optType) {
        var theDrawer = "";
        switch (optType) {
            case "delete":
                var isBlackList = false;
                if (this.props.isBlackList) isBlackList = true
                theDrawer = <IncGoDeleteSubs goBack={this.goBack} subscribers={this.props.subscribers} groupId={this.props.groupId} reloadAfterTickedAndChanged={this.props.reloadAfterTickedAndChanged} isBlackList={isBlackList} />
                break;
            case "copy":
                theDrawer = <IncGoCopySubs goBack={this.goBack} subscribers={this.props.subscribers} groupId={this.props.groupId} reloadAfterTickedAndChanged={this.props.reloadAfterTickedAndChanged} />
                break;
            case "move":
                theDrawer = <IncGoMoveSubs goBack={this.goBack} subscribers={this.props.subscribers} groupId={this.props.groupId} reloadAfterTickedAndChanged={this.props.reloadAfterTickedAndChanged} />
                break;
            case "status":
                theDrawer = <IncGoChangeStatus goBack={this.goBack} subscribers={this.props.subscribers} groupId={this.props.groupId} reloadAfterTickedAndChanged={this.props.reloadAfterTickedAndChanged} />
                break;
        }

        this.setState({ clickedDrawer: theDrawer });
    }

    goBack() {
        this.setState({ clickedDrawer: null });
    }

    closeAll() {
        this.props.checkAll(null, true);
    }

    render() {

        if (this.state.clickedDrawer != null) {
            return <span>{this.state.clickedDrawer}</span>
        }
        else {
            let fontSize = 13
            if ((this.props.totTicked + "").length > 3) fontSize = 12
            return <div>
                <div className="quickFlex mar-b25">
                    <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.closeAll}>{i18n.t('goBack')}</UILIB.Hint>
                </div>
                <UILIB.Row>

                    <UILIB.Column xs={12} sm={12} md={12}>

                        <h4 className="mar-b25">{i18n.t('subscribers:overview.optionHeader')}</h4>
                        <div className="mar-b25">{i18n.t('subscribers:overview.optionSubHeader')}</div>

                        <ul className="list avatar with-lines with-arrow with-notification">
                            <li className="middle-md" onClick={() => { this.clickOption("delete") }} >
                                <div className="notify">
                                    <span style={{ fontSize }}>{this.props.totTicked}</span>
                                </div>
                                <p>{i18n.t('subscribers:overview.remove')}</p>
                                <div className="arrow">
                                    <UILIB.Icons icon="arrowRight" />
                                </div>

                            </li>
                            {!this.props.isBlackList &&
                                <li className="middle-md" onClick={() => { this.clickOption("copy") }} id="toastCopy">

                                    <div className="notify">
                                        <span style={{ fontSize }}>{this.props.totTicked}</span>
                                    </div>
                                    <p>{i18n.t('subscribers:overview.optionCopy')}</p>
                                    <div className="arrow">
                                        <UILIB.Icons icon="arrowRight" />
                                    </div>

                                </li>
                            }
                            {!this.props.isBlackList &&
                                <li className="middle-md" onClick={() => { this.clickOption("move") }} id="toastMove">

                                    <div className="notify">
                                        <span style={{ fontSize }}>{this.props.totTicked}</span>
                                    </div>
                                    <p>{i18n.t('subscribers:overview.optionMove')}</p>
                                    <div className="arrow">
                                        <UILIB.Icons icon="arrowRight" />
                                    </div>

                                </li>}
                            {!this.props.isBlackList &&
                                <li className="middle-md" onClick={() => { this.clickOption("status") }} id="toastStatus">
                                    <div className="notify">
                                        <span style={{ fontSize }}>{this.props.totTicked}</span>
                                    </div>
                                    <p>{i18n.t('subscribers:overview.optionChange')}</p>
                                    <div className="arrow">
                                        <UILIB.Icons icon="arrowRight" />
                                    </div>
                                </li>
                            }
                        </ul>
                    </UILIB.Column>
                </UILIB.Row>
            </div>
        }
    }
};


/*
<IncGoCopySubs groupId={this.state.groupID} subscribers={this.state.subscribers} otherGroupData={this.state.otherGroupData} clickDrawerLink={this.clickDrawerLink} reloadAfterTickedAndChanged={this.reloadAfterTickedAndChanged} />
<IncGoChangeStatus groupId={this.state.groupID} subscribers={this.state.subscribers} segments={this.state.segmentsData} currentSegment={this.state.segmentID} clickDrawerLink={this.clickDrawerLink} reloadAfterTickedAndChanged={this.reloadAfterTickedAndChanged} />
<IncGoMoveSubs groupId={this.state.groupID} subscribers={this.state.subscribers} otherGroupData={this.state.otherGroupData} clickDrawerLink={this.clickDrawerLink} reloadAfterTickedAndChanged={this.reloadAfterTickedAndChanged} />
*/
import React from 'react'
import { Switch, Redirect } from 'react-router-dom';

import GroupsTemplate from './template.jsx';
import SubscriberGroup from './subscribers';
import SubscriberGroupAdd from './add/index';
import SubscribersCopyPaste from './add/copypaste/index';
import SubscribersCopyGroup from './add/copygroup/index';
import SubscribersImport from './add/import/index';
import SubscribersImportSetup from './add/import/importSetup';
import SubscribersImportProgress from './add/import/importProgress';
import SubscribersAdd from './add/addSubscriber/addSubscriber'
import SubscribersGroupSettings from './settings/groupSettings';
import GroupAnalytics from './analytics/analytics';
import GroupUsers from './users/users';
import ImportsPage from './imports/imports';

import FormView from '~/pages/cp/groups/group/forms/view'
import FormSignups from '~/pages/cp/groups/group/forms/view/subs'
import FormsPage from '~/pages/cp/groups/group/forms'
import FormEdit from '~/pages/cp/groups/group/forms/edit'
import FormCreate from '~/pages/cp/groups/group/forms/add'

import Clean from './clean/clean'
import PermissionRouter from '~/Common-Objects/Router/permissionRoute'
import LandingPages from './landing'
import FormDownloads from './forms/view/downloads.jsx';

export default class GroupTemplateFP extends React.Component {

    constructor(props) {
        super(props);
        this.state = { groupName: "...", groupID: this.props.match.params.groupID };
    }

    render() {
        return <GroupsTemplate groupID={this.props.match.params.groupID} history={this.props.history}>
            <Switch>
                <PermissionRouter permissionName="subscribers" method="write" exact path="/cp/groups/:groupID/add" component={SubscriberGroupAdd} />
                <PermissionRouter permissionName="subscribers" method="write" exact path="/cp/groups/:groupID/add/subscriber" component={SubscribersAdd} />
                <PermissionRouter permissionName="subscribers" method="write" exact path="/cp/groups/:groupID/add/copypaste" component={SubscribersCopyPaste} />
                <PermissionRouter permissionName="subscribers" method="write" exact path="/cp/groups/:groupID/add/copygroup" component={SubscribersCopyGroup} />
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/groups/:groupID/add/import" component={SubscribersImport} />
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/groups/:groupID/add/import/setup/:importID" component={SubscribersImportSetup} />
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/groups/:groupID/add/import/progress/:importID" component={SubscribersImportProgress} />
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/groups/:groupID/analytics" component={GroupAnalytics} />
                <PermissionRouter permissionName="subscribers" method="write" exact path="/cp/groups/:groupID/clean" component={Clean} />
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/groups/:groupID/settings" component={SubscribersGroupSettings} />
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/groups/:groupID/imports" component={ImportsPage} />
                <PermissionRouter permissionName="subscribers" method="write" exact path="/cp/groups/:groupID/users" component={GroupUsers} />
                <PermissionRouter permissionName="subscribers" method="write" exact path="/cp/groups/:groupID/landingpages" component={LandingPages} />
                <PermissionRouter permissionName="forms" method="read" exact path="/cp/groups/:groupID/forms" component={FormsPage} />
                <PermissionRouter permissionName="forms" method="write" exact path="/cp/groups/:groupId/forms/create/:formId" component={FormCreate} />
                <PermissionRouter permissionName="forms" method="write" exact path="/cp/groups/:groupId/forms/create/:formId/:step" component={FormCreate} />
                <PermissionRouter permissionName="forms" method="write" exact path="/cp/groups/:groupId/forms/edit/:formId" component={FormEdit} />
                <PermissionRouter permissionName="forms" method="write" exact path="/cp/groups/:groupId/forms/edit/:formId/:step" component={FormEdit} />
                <PermissionRouter permissionName="forms" method="read" exact path="/cp/groups/:groupId/forms/view/:formId" component={FormView} />
                <PermissionRouter permissionName="forms" method="read" exact path="/cp/groups/:groupId/forms/view/:formId/signups" component={FormSignups} />
                <PermissionRouter permissionName="forms" method="read" exact path="/cp/groups/:groupId/forms/view/:formId/downloads" component={FormDownloads} />
                <PermissionRouter permissionName="subscribers" method="read" exact path="/cp/groups/:groupID" component={SubscriberGroup} />
                <Redirect to="/cp/home" push />
            </Switch>
        </GroupsTemplate>

    }
};


import React, { Component } from 'react';
import DragDropFunctions from '../../../dragDropFunctions';
import IncAddCustomField from '~/pages/cp/subscribers/customfields/incAddCustomField'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import UILIB from '../../../../../../../../Common-Objects/Lib';

@connect((store) => {
    return { user: store.user }
})

class CustomField extends Component {
    constructor(props) {
        super(props)
        this.change = this.change.bind(this);
        this.state = {
            currVal: ""
        }
        this.checkForUsedCustomFields = this.checkForUsedCustomFields.bind(this);
        this.addField = this.addField.bind(this);
        this.createdField = this.createdField.bind(this);
    }

    change(value) {

        if (typeof value == "object") {
            value = value.currentTarget.value;
        }
        var thisDataFieldName = Object.keys(DragDropFunctions.customFields).find(theField => theField == value);
        var thisVal = " ";
        var thisLabel = " ";
        var thisRequired = false;
        var thisFieldType = " ";

        if (thisDataFieldName) {
            var thisData = DragDropFunctions.customFields[thisDataFieldName];
            thisVal = thisDataFieldName;
            thisLabel = thisData.description;
            thisRequired = thisData.required;
            thisFieldType = thisData.type;
        }
        this.props.updateVal(this.props.path, { value: thisVal, label: thisLabel, required: thisRequired, fieldType: thisFieldType }, undefined, undefined);
    }

    componentDidMount() {
        this.checkForUsedCustomFields(this.props);
        this.setState({ currVal: this.props.value })
    }
    componentWillReceiveProps(nextProps) {
        this.checkForUsedCustomFields(nextProps);
        this.setState({ currVal: nextProps.value })
    }

    checkForUsedCustomFields(theProps) {

        var customFields = DragDropFunctions.customFields;
        var elementsData = theProps.elementsData;

        for (var x in customFields) {
            customFields[x].disabled = false;
            customFields[x].tooltip = '';
        }
        function checkNode(thisJson) {
            if (Array.isArray(thisJson)) {
                thisJson.forEach((ele, index) => {
                    if (typeof ele === "object") {
                        checkNode(ele);
                    }
                })
            }
            else {

                Object.keys(thisJson).forEach(ele => {
                    if (typeof thisJson[ele] == "object" && thisJson[ele]) {
                        if (thisJson[ele].editableType === "CustomField") {
                            var thisCustField = thisJson[ele].value;
                            if (customFields[thisCustField]) {

                                customFields[thisCustField].disabled = true;
                            }
                        }
                        checkNode(thisJson[ele]);
                    }
                })
            }
        }

        checkNode(elementsData);

    }

    addField() {
        var drawerContent = <IncAddCustomField getCustomFields={this.createdField} standalone={true} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px", undefined));
    }

    createdField(newField) {
        var customFieldId = "customField-" + newField.id;
        var self = this;
        DragDropFunctions.getCustomFields(DragDropFunctions.templateType).then(() => {
            self.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
            self.change(customFieldId)
        })
    }

    render() {

        var customFields = JSON.parse(JSON.stringify(DragDropFunctions.customFields));
        for (const prop in customFields) {
            if (customFields[prop].readOnly) delete customFields[prop];
        }
        if (this.props.elementData && this.props.elementData.fieldTypesDenied && typeof this.props.elementData.fieldTypesDenied == "object") {
            Object.keys(customFields).forEach(theField => {
                this.props.elementData.fieldTypesDenied.forEach(theDen => {
                    if (theDen == customFields[theField]?.type) delete customFields[theField];
                })

            })
        }

        if (this.props.elementData && this.props.elementData.fieldTypesDisabled && typeof this.props.elementData.fieldTypesDisabled == "object") {
            Object.keys(customFields).forEach(theField => {
                this.props.elementData.fieldTypesDisabled.forEach(theDen => {
                    if (theDen == customFields[theField]?.type) {
                        customFields[theField].disabled = true;
                        customFields[theField].tooltip = 'Field Type Not Allowed';
                    }
                })
            })
        }


        var options = [{
            value: '',
            label: 'Select Field'
        }].concat(Object.keys(customFields).map(row => {
            return {
                value: row,
                label: customFields[row].description,
                disabled: customFields[row].disabled,
                iconRight: customFields[row].Application ? <UILIB.Avatar src={customFields[row].Application.appLogoUri} style={{ height: 20, width: 20 }} /> : null,
                tooltip: customFields[row].tooltip
            }

        }))

        var classes = "dd_dragDrop";
        if (this.props.error) {
            classes += " multilineError";
        }
        var style = {};
        if (this.props.elementData.optionFullWidth) style = { flex: "100%" }

        const selected = DragDropFunctions.customFields[this.state.currVal]


        return <div className="dd_toolHolder" style={style}>
            <div className="dd_inputTool">

                <UILIB.Select filter={true} fixedHeightLarge={true} label={this.props.title} data={options} className={classes} onChange={this.change} value={this.state.currVal} />
                {selected && selected.helper && <UILIB.WarningHolder className="mar-t10">{selected.helper}</UILIB.WarningHolder>}
                <div className="text-right mar-t10 text-xsml">
                    <a onClick={this.addField}>Add a new Custom Field</a>
                </div>

            </div>


        </div>

    }
}

export default CustomField;
import React from "react";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { connect } from "react-redux";
import StripeAddCard from "~/pages/cp/includes/upgrade/stripeAddCard";

//CONNECT TO STORE
@connect((store) => {
  return { user: store.user };
})
export default class BuyDomainStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manuallyAddingCard: false,
      paymentCards: [],
      defaultCard: "",
      paymentCardId: 0,
      paymentMethod: "",
      pageLoading: true,
      paymentError: "",
    };

    this.getPaymentCards = this.getPaymentCards.bind(this);
    this.selectCard = this.selectCard.bind(this);
    this.addNewCard = this.addNewCard.bind(this);
    this.goBuy = this.goBuy.bind(this);
  }

  componentDidMount() {
    this.getPaymentCards();
  }

  getPaymentCards() {
    axios
      .get("/accountMaster/paymentMethod/stripe")
      .then((response) => {
        var defaultCard;
        var paymentMethod = "card";
        if (response.data.customer && response.data.customer.default_source) {
          defaultCard = response.data.customer.default_source;
          paymentMethod = "card";
        }
        this.setState({
          manuallyAddingCard: false,
          paymentCards: response.data.customer.sources.data,
          defaultCard,
          paymentCardId: defaultCard,
          paymentMethod,
          pageLoading: false,
          reloadingCard: false,
        });
      })
      .catch(console.log);
  }

  selectCard(event) {
    var self = this;
    var newCardId = event.target.value;
    this.setState({ reloadingCard: true });
    axios
      .put("/accountMaster/paymentMethod/stripe", {
        default_source: newCardId,
      })
      .then(() => {
        self.setState({
          manuallyAddingCard: false,
          paymentCardId: newCardId,
          reloadingCard: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  addNewCard() {
    var manuallyAddingCard = this.state.manuallyAddingCard;
    if (manuallyAddingCard) {
      manuallyAddingCard = false;
    } else {
      manuallyAddingCard = true;
    }
    this.setState({ manuallyAddingCard, paymentCardId: "" });
  }

  goBuy() {
    var self = this;
    this.setState({ pageLoading: true });
    axios
      .post("/accountMaster/domain/create/buy", {
        domainName: this.props.domainName,
      })
      .then((_resp) => {
        if (!_resp.data.success) {
          self.setState({ paymentError: _resp.data.error, pageLoading: false });
          return;
        }
        self.props.onBought();
      })
      .catch((err) => {
        self.setState({
          paymentError:
            "There was an error processing your payment. Please try again or try another payment method.",
          pageLoading: false,
        });
      });
  }

  render() {
    if (this.state.pageLoading)
      return (
        <div>
          <UILIB.LoadingIcons iconType="2" />
        </div>
      );

    var selectedPaymentCard = this.state.paymentCardId;
    if (!selectedPaymentCard || !selectedPaymentCard.length)
      selectedPaymentCard = this.state.defaultCard;

    return (
      <div>
        <h2 className="h4 mar-b25">Buy {this.props.domainName}</h2>
        <div className="mar-b25">
          Purchase this domain and we will automatically register it, and
          pre-configure it for email sending through this platform.
        </div>

        <UILIB.Paper className="paper-primary">
          <h3 className="h4 mar-b25">Purchase this Domain</h3>
          <table width="100%" cellPadding="10" cellSpacing="0">
            <tbody>
              <tr>
                <td width="100">Domain:</td>
                <td>{this.props.domainName}</td>
              </tr>
              <tr>
                <td width="100">Price:</td>
                <td>
                  {this.props.domainResult.symbol}
                  {this.props.domainResult.price} per year
                </td>
              </tr>
            </tbody>
          </table>
        </UILIB.Paper>

        <UILIB.WarningHolder className="mar-b25">
          Please Note: You will be billed automatically annually for this
          domain. You can stop the renewal at any time by simply deleting the
          domain. All domains are registered by Transpond but can be transferred
          to you if required.
        </UILIB.WarningHolder>

        {this.state.paymentCards &&
          this.state.paymentCards.length > 0 &&
          !this.state.manuallyAddingCard && (
            <UILIB.Row>
              <UILIB.Column xs={12} sm={12} margin={0}>
                {!!this.state.reloadingCard && (
                  <UILIB.LoadingIcons iconType="2" />
                )}
                {!this.state.reloadingCard && (
                  <div>
                    <UILIB.Select
                      label="Payment Method"
                      placeholder="Select an existing payment card..."
                      data={this.state.paymentCards.map((card) => {
                        return {
                          label:
                            card.brand +
                            " ending " +
                            card.last4 +
                            " (exp " +
                            card.exp_month +
                            "/" +
                            card.exp_year +
                            ")",
                          value: card.id,
                        };
                      })}
                      onChange={this.selectCard}
                      value={selectedPaymentCard}
                    />
                    <div
                      className="mar-t10"
                      style={{ textAlign: "right", fontSize: "14px" }}
                    >
                      or <a onClick={this.addNewCard}>Add a new Card</a>
                    </div>
                  </div>
                )}
              </UILIB.Column>
              {!!this.state.paymentError && (
                <UILIB.Column xs={12} sm={12} margin={0}>
                  <UILIB.Paper className="paper-red">
                    {this.state.paymentError}
                  </UILIB.Paper>
                </UILIB.Column>
              )}

              <UILIB.Column xs={12} sm={12} margin={0}>
                <UILIB.Button
                  disabled={this.state.reloadingCard}
                  className="button-primary mar-r5"
                  text={`Buy Domain (${
                    this.props.domainResult.symbol +
                    this.props.domainResult.price
                  })`}
                  onClick={this.goBuy}
                />
                <UILIB.Button
                  text="Forget it"
                  onClick={this.props.onCloseDrawer}
                />
              </UILIB.Column>
            </UILIB.Row>
          )}

        {this.state.paymentMethod == "card" &&
          (!this.state.paymentCards ||
            !this.state.paymentCards.length ||
            this.state.manuallyAddingCard) && (
            <UILIB.Row>
              <UILIB.Column xs={12} sm={12} margin={0}>
                <h3 className="h4 mar-b25">Add a Payment Method</h3>
                <StripeAddCard
                  close={this.getPaymentCards}
                  hasCloseButton={this.state.manuallyAddingCard}
                />
              </UILIB.Column>
            </UILIB.Row>
          )}
      </div>
    );
  }
}

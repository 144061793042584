var emojis = {
    emojiData: [
        { "character": "😂", "image": "1f602.png", "category": "Emoticons" },
        { "character": "😃", "image": "1f603.png", "category": "Emoticons" },
        { "character": "😄", "image": "1f604.png", "category": "Emoticons" },
        { "character": "😅", "image": "1f605.png", "category": "Emoticons" },
        { "character": "😆", "image": "1f606.png", "category": "Emoticons" },
        { "character": "😉", "image": "1f609.png", "category": "Emoticons" },
        { "character": "😊", "image": "1f60a.png", "category": "Emoticons" },
        { "character": "😋", "image": "1f60b.png", "category": "Emoticons" },
        { "character": "😌", "image": "1f60c.png", "category": "Emoticons" },
        { "character": "😍", "image": "1f60d.png", "category": "Emoticons" },
        { "character": "😏", "image": "1f60f.png", "category": "Emoticons" },
        { "character": "😒", "image": "1f612.png", "category": "Emoticons" },
        { "character": "😓", "image": "1f613.png", "category": "Emoticons" },
        { "character": "😔", "image": "1f614.png", "category": "Emoticons" },
        { "character": "😖", "image": "1f616.png", "category": "Emoticons" },
        { "character": "😘", "image": "1f618.png", "category": "Emoticons" },
        { "character": "😚", "image": "1f61a.png", "category": "Emoticons" },
        { "character": "😜", "image": "1f61c.png", "category": "Emoticons" },
        { "character": "😝", "image": "1f61d.png", "category": "Emoticons" },
        { "character": "😞", "image": "1f61e.png", "category": "Emoticons" },
        { "character": "😠", "image": "1f620.png", "category": "Emoticons" },
        { "character": "😡", "image": "1f621.png", "category": "Emoticons" },
        { "character": "😢", "image": "1f622.png", "category": "Emoticons" },
        { "character": "😣", "image": "1f623.png", "category": "Emoticons" },
        { "character": "😤", "image": "1f624.png", "category": "Emoticons" },
        { "character": "😥", "image": "1f625.png", "category": "Emoticons" },
        { "character": "😨", "image": "1f628.png", "category": "Emoticons" },
        { "character": "😩", "image": "1f629.png", "category": "Emoticons" },
        { "character": "😪", "image": "1f62a.png", "category": "Emoticons" },
        { "character": "😫", "image": "1f62b.png", "category": "Emoticons" },
        { "character": "😭", "image": "1f62d.png", "category": "Emoticons" },
        { "character": "😰", "image": "1f630.png", "category": "Emoticons" },
        { "character": "😱", "image": "1f631.png", "category": "Emoticons" },
        { "character": "😲", "image": "1f632.png", "category": "Emoticons" },
        { "character": "😳", "image": "1f633.png", "category": "Emoticons" },
        { "character": "😵", "image": "1f635.png", "category": "Emoticons" },
        { "character": "😷", "image": "1f637.png", "category": "Emoticons" },
        { "character": "😸", "image": "1f638.png", "category": "Emoticons" },
        { "character": "😹", "image": "1f639.png", "category": "Emoticons" },
        { "character": "😺", "image": "1f63a.png", "category": "Emoticons" },
        { "character": "😻", "image": "1f63b.png", "category": "Emoticons" },
        { "character": "😼", "image": "1f63c.png", "category": "Emoticons" },
        { "character": "😽", "image": "1f63d.png", "category": "Emoticons" },
        { "character": "😾", "image": "1f63e.png", "category": "Emoticons" },
        { "character": "😿", "image": "1f63f.png", "category": "Emoticons" },
        { "character": "🙀", "image": "1f640.png", "category": "Emoticons" },
        { "character": "🙅", "image": "1f645.png", "category": "Emoticons" },
        { "character": "🙆", "image": "1f646.png", "category": "Emoticons" },
        { "character": "🙇", "image": "1f647.png", "category": "Emoticons" },
        { "character": "🙈", "image": "1f648.png", "category": "Emoticons" },
        { "character": "🙉", "image": "1f649.png", "category": "Emoticons" },
        { "character": "🙊", "image": "1f64a.png", "category": "Emoticons" },
        { "character": "🙋", "image": "1f64b.png", "category": "Emoticons" },
        { "character": "🙌", "image": "1f64c.png", "category": "Emoticons" },
        { "character": "🙍", "image": "1f64d.png", "category": "Emoticons" },
        { "character": "🙎", "image": "1f64e.png", "category": "Emoticons" },
        { "character": "🙏", "image": "1f64f.png", "category": "Emoticons" },
        { "character": "✂", "image": "2702.png", "category": "Dingbats" },
        { "character": "✅", "image": "2705.png", "category": "Dingbats" },
        { "character": "✈", "image": "2708.png", "category": "Travel" },
        { "character": "✉", "image": "2709.png", "category": "Dingbats" },
        { "character": "✊", "image": "270a.png", "category": "Dingbats" },
        { "character": "✋", "image": "270b.png", "category": "Dingbats" },
        { "character": "✌", "image": "270c.png", "category": "Dingbats" },
        { "character": "✏", "image": "270f.png", "category": "Dingbats" },
        { "character": "✒", "image": "2712.png", "category": "Dingbats" },
        { "character": "✔", "image": "2714.png", "category": "Dingbats" },
        { "character": "✖", "image": "2716.png", "category": "Dingbats" },
        { "character": "✨", "image": "2728.png", "category": "Dingbats" },
        { "character": "✳", "image": "2733.png", "category": "Dingbats" },
        { "character": "✴", "image": "2734.png", "category": "Dingbats" },
        { "character": "❄", "image": "2744.png", "category": "Dingbats" },
        { "character": "❇", "image": "2747.png", "category": "Dingbats" },
        { "character": "❌", "image": "274c.png", "category": "Dingbats" },
        { "character": "❎", "image": "274e.png", "category": "Dingbats" },
        { "character": "❓", "image": "2753.png", "category": "Dingbats" },
        { "character": "❔", "image": "2754.png", "category": "Dingbats" },
        { "character": "❕", "image": "2755.png", "category": "Dingbats" },
        { "character": "❗", "image": "2757.png", "category": "Dingbats" },
        { "character": "❤", "image": "2764.png", "category": "Dingbats" },
        { "character": "➕", "image": "2795.png", "category": "Dingbats" },
        { "character": "➖", "image": "2796.png", "category": "Dingbats" },
        { "character": "➗", "image": "2797.png", "category": "Dingbats" },
        { "character": "➡", "image": "27a1.png", "category": "Dingbats" },
        { "character": "➰", "image": "27b0.png", "category": "Dingbats" },
        { "character": "🚀", "image": "1f680.png", "category": "Travel" },
        { "character": "🚃", "image": "1f683.png", "category": "Travel" },
        { "character": "🚄", "image": "1f684.png", "category": "Travel" },
        { "character": "🚅", "image": "1f685.png", "category": "Travel" },
        { "character": "🚇", "image": "1f687.png", "category": "Travel" },
        { "character": "🚉", "image": "1f689.png", "category": "Travel" },
        { "character": "🚌", "image": "1f68c.png", "category": "Travel" },
        { "character": "🚏", "image": "1f68f.png", "category": "Travel" },
        { "character": "🚑", "image": "1f691.png", "category": "Travel" },
        { "character": "🚒", "image": "1f692.png", "category": "Travel" },
        { "character": "🚓", "image": "1f693.png", "category": "Travel" },
        { "character": "🚕", "image": "1f695.png", "category": "Travel" },
        { "character": "🚗", "image": "1f697.png", "category": "Travel" },
        { "character": "🚙", "image": "1f699.png", "category": "Travel" },
        { "character": "🚚", "image": "1f69a.png", "category": "Travel" },
        { "character": "🚢", "image": "1f6a2.png", "category": "Travel" },
        { "character": "🚤", "image": "1f6a4.png", "category": "Travel" },
        { "character": "🚥", "image": "1f6a5.png", "category": "Travel" },
        { "character": "🚧", "image": "1f6a7.png", "category": "Travel" },
        { "character": "🚨", "image": "1f6a8.png", "category": "Travel" },
        { "character": "🚩", "image": "1f6a9.png", "category": "Travel" },
        { "character": "🚪", "image": "1f6aa.png", "category": "Travel" },
        { "character": "🚫", "image": "1f6ab.png", "category": "Travel" },
        { "character": "🚬", "image": "1f6ac.png", "category": "Travel" },
        { "character": "🚭", "image": "1f6ad.png", "category": "Travel" },
        { "character": "🚲", "image": "1f6b2.png", "category": "Travel" },
        { "character": "🚶", "image": "1f6b6.png", "category": "Travel" },
        { "character": "🚹", "image": "1f6b9.png", "category": "Travel" },
        { "character": "🚺", "image": "1f6ba.png", "category": "Travel" },
        { "character": "🚻", "image": "1f6bb.png", "category": "Travel" },
        { "character": "🚼", "image": "1f6bc.png", "category": "Travel" },
        { "character": "🚽", "image": "1f6bd.png", "category": "Travel" },
        { "character": "🚾", "image": "1f6be.png", "category": "Travel" },
        { "character": "🛀", "image": "1f6c0.png", "category": "Travel" },
        { "character": "Ⓜ", "image": "24c2.png", "category": "Symbols" },
        { "character": "🅰", "image": "1f170.png", "category": "Symbols" },
        { "character": "🅱", "image": "1f171.png", "category": "Symbols" },
        { "character": "🅾", "image": "1f17e.png", "category": "Symbols" },
        { "character": "🅿", "image": "1f17f.png", "category": "Symbols" },
        { "character": "🆎", "image": "1f18e.png", "category": "Symbols" },
        { "character": "🆑", "image": "1f191.png", "category": "Symbols" },
        { "character": "🆒", "image": "1f192.png", "category": "Symbols" },
        { "character": "🆓", "image": "1f193.png", "category": "Symbols" },
        { "character": "🆔", "image": "1f194.png", "category": "Symbols" },
        { "character": "🆕", "image": "1f195.png", "category": "Symbols" },
        { "character": "🆖", "image": "1f196.png", "category": "Symbols" },
        { "character": "🆗", "image": "1f197.png", "category": "Symbols" },
        { "character": "🆘", "image": "1f198.png", "category": "Symbols" },
        { "character": "🆙", "image": "1f199.png", "category": "Symbols" },
        { "character": "🆚", "image": "1f19a.png", "category": "Symbols" },
        { "character": "🇩🇪", "image": "1f1e9-1f1ea.png", "category": "Symbols" },
        { "character": "🇬🇧", "image": "1f1ec-1f1e7.png", "category": "Symbols" },
        { "character": "🇨🇳", "image": "1f1e8-1f1f3.png", "category": "Symbols" },
        { "character": "🇯🇵", "image": "1f1ef-1f1f5.png", "category": "Symbols" },
        { "character": "🇰🇷", "image": "1f1f0-1f1f7.png", "category": "Symbols" },
        { "character": "🇫🇷", "image": "1f1eb-1f1f7.png", "category": "Symbols" },
        { "character": "🇪🇸", "image": "1f1ea-1f1f8.png", "category": "Symbols" },
        { "character": "🇮🇹", "image": "1f1ee-1f1f9.png", "category": "Symbols" },
        { "character": "🇺🇸", "image": "1f1fa-1f1f8.png", "category": "Symbols" },
        { "character": "🇷🇺", "image": "1f1f7-1f1fa.png", "category": "Symbols" },
        { "character": "🈁", "image": "1f201.png", "category": "Symbols" },
        { "character": "🈂", "image": "1f202.png", "category": "Symbols" },
        { "character": "🈚", "image": "1f21a.png", "category": "Symbols" },
        { "character": "🈯", "image": "1f22f.png", "category": "Symbols" },
        { "character": "🈲", "image": "1f232.png", "category": "Symbols" },
        { "character": "🈳", "image": "1f233.png", "category": "Symbols" },
        { "character": "🈴", "image": "1f234.png", "category": "Symbols" },
        { "character": "🈵", "image": "1f235.png", "category": "Symbols" },
        { "character": "🈶", "image": "1f236.png", "category": "Symbols" },
        { "character": "🈷", "image": "1f237.png", "category": "Symbols" },
        { "character": "🈸", "image": "1f238.png", "category": "Symbols" },
        { "character": "🈹", "image": "1f239.png", "category": "Symbols" },
        { "character": "🈺", "image": "1f23a.png", "category": "Symbols" },
        { "character": "🉐", "image": "1f250.png", "category": "Symbols" },
        { "character": "🉑", "image": "1f251.png", "category": "Symbols" },
        { "character": "©", "image": "00a9.png", "category": "Misc" },
        { "character": "®", "image": "00ae.png", "category": "Misc" },
        { "character": "‼", "image": "203c.png", "category": "Misc" },
        { "character": "⁉", "image": "2049.png", "category": "Misc" },
        { "character": "8⃣", "image": "0038-20e3.png", "category": "Misc" },
        { "character": "9⃣", "image": "0039-20e3.png", "category": "Misc" },
        { "character": "7⃣", "image": "0037-20e3.png", "category": "Misc" },
        { "character": "6⃣", "image": "0036-20e3.png", "category": "Misc" },
        { "character": "1⃣", "image": "0031-20e3.png", "category": "Misc" },
        { "character": "0⃣", "image": "0030-20e3.png", "category": "Misc" },
        { "character": "2⃣", "image": "0032-20e3.png", "category": "Misc" },
        { "character": "3⃣", "image": "0033-20e3.png", "category": "Misc" },
        { "character": "5⃣", "image": "0035-20e3.png", "category": "Misc" },
        { "character": "4⃣", "image": "0034-20e3.png", "category": "Misc" },
        { "character": "#⃣", "image": "0023-20e3.png", "category": "Misc" },
        { "character": "™", "image": "2122.png", "category": "Misc" },
        { "character": "ℹ", "image": "2139.png", "category": "Misc" },
        { "character": "↔", "image": "2194.png", "category": "Misc" },
        { "character": "↕", "image": "2195.png", "category": "Misc" },
        { "character": "↖", "image": "2196.png", "category": "Misc" },
        { "character": "↗", "image": "2197.png", "category": "Misc" },
        { "character": "↘", "image": "2198.png", "category": "Misc" },
        { "character": "↙", "image": "2199.png", "category": "Misc" },
        { "character": "↩", "image": "21a9.png", "category": "Misc" },
        { "character": "↪", "image": "21aa.png", "category": "Misc" },
        { "character": "⌚", "image": "231a.png", "category": "Misc" },
        { "character": "⌛", "image": "231b.png", "category": "Misc" },
        { "character": "⏩", "image": "23e9.png", "category": "Misc" },
        { "character": "⏪", "image": "23ea.png", "category": "Misc" },
        { "character": "⏫", "image": "23eb.png", "category": "Misc" },
        { "character": "⏬", "image": "23ec.png", "category": "Misc" },
        { "character": "⏰", "image": "23f0.png", "category": "Misc" },
        { "character": "⏳", "image": "23f3.png", "category": "Misc" },
        { "character": "▪", "image": "25aa.png", "category": "Misc" },
        { "character": "▫", "image": "25ab.png", "category": "Misc" },
        { "character": "▶", "image": "25b6.png", "category": "Misc" },
        { "character": "◀", "image": "25c0.png", "category": "Misc" },
        { "character": "◻", "image": "25fb.png", "category": "Misc" },
        { "character": "◼", "image": "25fc.png", "category": "Misc" },
        { "character": "◽", "image": "25fd.png", "category": "Misc" },
        { "character": "◾", "image": "25fe.png", "category": "Misc" },
        { "character": "☀", "image": "2600.png", "category": "Misc" },
        { "character": "☁", "image": "2601.png", "category": "Misc" },
        { "character": "☎", "image": "260e.png", "category": "Misc" },
        { "character": "☑", "image": "2611.png", "category": "Misc" },
        { "character": "☔", "image": "2614.png", "category": "Misc" },
        { "character": "☕", "image": "2615.png", "category": "Food" },
        { "character": "☝", "image": "261d.png", "category": "Misc" },
        { "character": "☺", "image": "263a.png", "category": "Misc" },
        { "character": "♈", "image": "2648.png", "category": "Misc" },
        { "character": "♉", "image": "2649.png", "category": "Misc" },
        { "character": "♊", "image": "264a.png", "category": "Misc" },
        { "character": "♋", "image": "264b.png", "category": "Misc" },
        { "character": "♌", "image": "264c.png", "category": "Misc" },
        { "character": "♍", "image": "264d.png", "category": "Misc" },
        { "character": "♎", "image": "264e.png", "category": "Misc" },
        { "character": "♏", "image": "264f.png", "category": "Misc" },
        { "character": "♐", "image": "2650.png", "category": "Misc" },
        { "character": "♑", "image": "2651.png", "category": "Misc" },
        { "character": "♒", "image": "2652.png", "category": "Misc" },
        { "character": "♓", "image": "2653.png", "category": "Misc" },
        { "character": "♠", "image": "2660.png", "category": "Misc" },
        { "character": "♣", "image": "2663.png", "category": "Misc" },
        { "character": "♥", "image": "2665.png", "category": "Misc" },
        { "character": "♦", "image": "2666.png", "category": "Misc" },
        { "character": "♨", "image": "2668.png", "category": "Misc" },
        { "character": "♻", "image": "267b.png", "category": "Misc" },
        { "character": "♿", "image": "267f.png", "category": "Misc" },
        { "character": "⚓", "image": "2693.png", "category": "Misc" },
        { "character": "⚠", "image": "26a0.png", "category": "Misc" },
        { "character": "⚡", "image": "26a1.png", "category": "Misc" },
        { "character": "⚪", "image": "26aa.png", "category": "Misc" },
        { "character": "⚫", "image": "26ab.png", "category": "Misc" },
        { "character": "⚽", "image": "26bd.png", "category": "Misc" },
        { "character": "⚾", "image": "26be.png", "category": "Misc" },
        { "character": "⛄", "image": "26c4.png", "category": "Misc" },
        { "character": "⛅", "image": "26c5.png", "category": "Misc" },
        { "character": "⛎", "image": "26ce.png", "category": "Misc" },
        { "character": "⛔", "image": "26d4.png", "category": "Misc" },
        { "character": "⛪", "image": "26ea.png", "category": "Misc" },
        { "character": "⛲", "image": "26f2.png", "category": "Misc" },
        { "character": "⛳", "image": "26f3.png", "category": "Misc" },
        { "character": "⛵", "image": "26f5.png", "category": "Misc" },
        { "character": "⛺", "image": "26fa.png", "category": "Misc" },
        { "character": "⛽", "image": "26fd.png", "category": "Misc" },
        { "character": "⤴", "image": "2934.png", "category": "Misc" },
        { "character": "⤵", "image": "2935.png", "category": "Misc" },
        { "character": "⬅", "image": "2b05.png", "category": "Misc" },
        { "character": "⬆", "image": "2b06.png", "category": "Misc" },
        { "character": "⬇", "image": "2b07.png", "category": "Misc" },
        { "character": "⬛", "image": "2b1b.png", "category": "Misc" },
        { "character": "⬜", "image": "2b1c.png", "category": "Misc" },
        { "character": "⭐", "image": "2b50.png", "category": "Misc" },
        { "character": "⭕", "image": "2b55.png", "category": "Misc" },
        { "character": "🀄", "image": "1f004.png", "category": "Misc" },
        { "character": "🃏", "image": "1f0cf.png", "category": "Misc" },
        { "character": "🌀", "image": "1f300.png", "category": "Misc" },
        { "character": "🌁", "image": "1f301.png", "category": "Misc" },
        { "character": "🌂", "image": "1f302.png", "category": "Misc" },
        { "character": "🌃", "image": "1f303.png", "category": "Misc" },
        { "character": "🌄", "image": "1f304.png", "category": "Misc" },
        { "character": "🌅", "image": "1f305.png", "category": "Misc" },
        { "character": "🌆", "image": "1f306.png", "category": "Misc" },
        { "character": "🌇", "image": "1f307.png", "category": "Misc" },
        { "character": "🌈", "image": "1f308.png", "category": "Misc" },
        { "character": "🌉", "image": "1f309.png", "category": "Misc" },
        { "character": "🌊", "image": "1f30a.png", "category": "Misc" },
        { "character": "🌋", "image": "1f30b.png", "category": "Misc" },
        { "character": "🌌", "image": "1f30c.png", "category": "Misc" },
        { "character": "🌏", "image": "1f30f.png", "category": "Misc" },
        { "character": "🌑", "image": "1f311.png", "category": "Misc" },
        { "character": "🌓", "image": "1f313.png", "category": "Misc" },
        { "character": "🌔", "image": "1f314.png", "category": "Misc" },
        { "character": "🌕", "image": "1f315.png", "category": "Misc" },
        { "character": "🌙", "image": "1f319.png", "category": "Misc" },
        { "character": "🌛", "image": "1f31b.png", "category": "Misc" },
        { "character": "🌟", "image": "1f31f.png", "category": "Misc" },
        { "character": "🌠", "image": "1f320.png", "category": "Misc" },
        { "character": "🌰", "image": "1f330.png", "category": "Misc" },
        { "character": "🌱", "image": "1f331.png", "category": "Misc" },
        { "character": "🌴", "image": "1f334.png", "category": "Misc" },
        { "character": "🌵", "image": "1f335.png", "category": "Misc" },
        { "character": "🌷", "image": "1f337.png", "category": "Misc" },
        { "character": "🌸", "image": "1f338.png", "category": "Misc" },
        { "character": "🌹", "image": "1f339.png", "category": "Misc" },
        { "character": "🌺", "image": "1f33a.png", "category": "Misc" },
        { "character": "🌻", "image": "1f33b.png", "category": "Misc" },
        { "character": "🌼", "image": "1f33c.png", "category": "Misc" },
        { "character": "🌽", "image": "1f33d.png", "category": "Misc" },
        { "character": "🌾", "image": "1f33e.png", "category": "Misc" },
        { "character": "🌿", "image": "1f33f.png", "category": "Misc" },
        { "character": "🍀", "image": "1f340.png", "category": "Misc" },
        { "character": "🍁", "image": "1f341.png", "category": "Misc" },
        { "character": "🍂", "image": "1f342.png", "category": "Misc" },
        { "character": "🍃", "image": "1f343.png", "category": "Misc" },
        { "character": "🍄", "image": "1f344.png", "category": "Food" },
        { "character": "🍅", "image": "1f345.png", "category": "Food" },
        { "character": "🍆", "image": "1f346.png", "category": "Food" },
        { "character": "🍇", "image": "1f347.png", "category": "Food" },
        { "character": "🍈", "image": "1f348.png", "category": "Food" },
        { "character": "🍉", "image": "1f349.png", "category": "Food" },
        { "character": "🍊", "image": "1f34a.png", "category": "Food" },
        { "character": "🍌", "image": "1f34c.png", "category": "Food" },
        { "character": "🍍", "image": "1f34d.png", "category": "Food" },
        { "character": "🍎", "image": "1f34e.png", "category": "Food" },
        { "character": "🍏", "image": "1f34f.png", "category": "Food" },
        { "character": "🍑", "image": "1f351.png", "category": "Food" },
        { "character": "🍒", "image": "1f352.png", "category": "Food" },
        { "character": "🍓", "image": "1f353.png", "category": "Food" },
        { "character": "🍔", "image": "1f354.png", "category": "Food" },
        { "character": "🍕", "image": "1f355.png", "category": "Food" },
        { "character": "🍖", "image": "1f356.png", "category": "Food" },
        { "character": "🍗", "image": "1f357.png", "category": "Food" },
        { "character": "🍘", "image": "1f358.png", "category": "Misc" },
        { "character": "🍙", "image": "1f359.png", "category": "Misc" },
        { "character": "🍚", "image": "1f35a.png", "category": "Misc" },
        { "character": "🍛", "image": "1f35b.png", "category": "Misc" },
        { "character": "🍜", "image": "1f35c.png", "category": "Misc" },
        { "character": "🍝", "image": "1f35d.png", "category": "Misc" },
        { "character": "🍞", "image": "1f35e.png", "category": "Misc" },
        { "character": "🍟", "image": "1f35f.png", "category": "Misc" },
        { "character": "🍠", "image": "1f360.png", "category": "Misc" },
        { "character": "🍡", "image": "1f361.png", "category": "Misc" },
        { "character": "🍢", "image": "1f362.png", "category": "Misc" },
        { "character": "🍣", "image": "1f363.png", "category": "Misc" },
        { "character": "🍤", "image": "1f364.png", "category": "Animals" },
        { "character": "🍥", "image": "1f365.png", "category": "Misc" },
        { "character": "🍦", "image": "1f366.png", "category": "Food" },
        { "character": "🍧", "image": "1f367.png", "category": "Food" },
        { "character": "🍨", "image": "1f368.png", "category": "Food" },
        { "character": "🍩", "image": "1f369.png", "category": "Food" },
        { "character": "🍪", "image": "1f36a.png", "category": "Food" },
        { "character": "🍫", "image": "1f36b.png", "category": "Food" },
        { "character": "🍬", "image": "1f36c.png", "category": "Food" },
        { "character": "🍭", "image": "1f36d.png", "category": "Food" },
        { "character": "🍮", "image": "1f36e.png", "category": "Food" },
        { "character": "🍯", "image": "1f36f.png", "category": "Food" },
        { "character": "🍰", "image": "1f370.png", "category": "Food" },
        { "character": "🍱", "image": "1f371.png", "category": "Food" },
        { "character": "🍲", "image": "1f372.png", "category": "Food" },
        { "character": "🍳", "image": "1f373.png", "category": "Food" },
        { "character": "🍴", "image": "1f374.png", "category": "Misc" },
        { "character": "🍵", "image": "1f375.png", "category": "Food" },
        { "character": "🍶", "image": "1f376.png", "category": "Food" },
        { "character": "🍷", "image": "1f377.png", "category": "Food" },
        { "character": "🍸", "image": "1f378.png", "category": "Food" },
        { "character": "🍹", "image": "1f379.png", "category": "Food" },
        { "character": "🍺", "image": "1f37a.png", "category": "Food" },
        { "character": "🍻", "image": "1f37b.png", "category": "Food" },
        { "character": "🎀", "image": "1f380.png", "category": "Misc" },
        { "character": "🎁", "image": "1f381.png", "category": "Misc" },
        { "character": "🎂", "image": "1f382.png", "category": "Food" },
        { "character": "🎃", "image": "1f383.png", "category": "Misc" },
        { "character": "🎄", "image": "1f384.png", "category": "Misc" },
        { "character": "🎅", "image": "1f385.png", "category": "Misc" },
        { "character": "🎆", "image": "1f386.png", "category": "Misc" },
        { "character": "🎇", "image": "1f387.png", "category": "Misc" },
        { "character": "🎈", "image": "1f388.png", "category": "Misc" },
        { "character": "🎉", "image": "1f389.png", "category": "Misc" },
        { "character": "🎊", "image": "1f38a.png", "category": "Misc" },
        { "character": "🎋", "image": "1f38b.png", "category": "Misc" },
        { "character": "🎌", "image": "1f38c.png", "category": "Misc" },
        { "character": "🎍", "image": "1f38d.png", "category": "Misc" },
        { "character": "🎎", "image": "1f38e.png", "category": "Misc" },
        { "character": "🎏", "image": "1f38f.png", "category": "Misc" },
        { "character": "🎐", "image": "1f390.png", "category": "Misc" },
        { "character": "🎑", "image": "1f391.png", "category": "Misc" },
        { "character": "🎒", "image": "1f392.png", "category": "Misc" },
        { "character": "🎓", "image": "1f393.png", "category": "Misc" },
        { "character": "🎠", "image": "1f3a0.png", "category": "Misc" },
        { "character": "🎡", "image": "1f3a1.png", "category": "Misc" },
        { "character": "🎢", "image": "1f3a2.png", "category": "Misc" },
        { "character": "🎣", "image": "1f3a3.png", "category": "Misc" },
        { "character": "🎤", "image": "1f3a4.png", "category": "Misc" },
        { "character": "🎥", "image": "1f3a5.png", "category": "Misc" },
        { "character": "🎦", "image": "1f3a6.png", "category": "Misc" },
        { "character": "🎧", "image": "1f3a7.png", "category": "Misc" },
        { "character": "🎨", "image": "1f3a8.png", "category": "Misc" },
        { "character": "🎩", "image": "1f3a9.png", "category": "Misc" },
        { "character": "🎪", "image": "1f3aa.png", "category": "Misc" },
        { "character": "🎫", "image": "1f3ab.png", "category": "Misc" },
        { "character": "🎬", "image": "1f3ac.png", "category": "Misc" },
        { "character": "🎭", "image": "1f3ad.png", "category": "Misc" },
        { "character": "🎮", "image": "1f3ae.png", "category": "Misc" },
        { "character": "🎯", "image": "1f3af.png", "category": "Misc" },
        { "character": "🎰", "image": "1f3b0.png", "category": "Misc" },
        { "character": "🎱", "image": "1f3b1.png", "category": "Misc" },
        { "character": "🎲", "image": "1f3b2.png", "category": "Misc" },
        { "character": "🎳", "image": "1f3b3.png", "category": "Misc" },
        { "character": "🎴", "image": "1f3b4.png", "category": "Misc" },
        { "character": "🎵", "image": "1f3b5.png", "category": "Misc" },
        { "character": "🎶", "image": "1f3b6.png", "category": "Misc" },
        { "character": "🎷", "image": "1f3b7.png", "category": "Misc" },
        { "character": "🎸", "image": "1f3b8.png", "category": "Misc" },
        { "character": "🎹", "image": "1f3b9.png", "category": "Misc" },
        { "character": "🎺", "image": "1f3ba.png", "category": "Misc" },
        { "character": "🎻", "image": "1f3bb.png", "category": "Misc" },
        { "character": "🎼", "image": "1f3bc.png", "category": "Misc" },
        { "character": "🎽", "image": "1f3bd.png", "category": "Misc" },
        { "character": "🎾", "image": "1f3be.png", "category": "Misc" },
        { "character": "🎿", "image": "1f3bf.png", "category": "Misc" },
        { "character": "🏀", "image": "1f3c0.png", "category": "Misc" },
        { "character": "🏁", "image": "1f3c1.png", "category": "Misc" },
        { "character": "🏂", "image": "1f3c2.png", "category": "Misc" },
        { "character": "🏃", "image": "1f3c3.png", "category": "Misc" },
        { "character": "🏄", "image": "1f3c4.png", "category": "Misc" },
        { "character": "🏆", "image": "1f3c6.png", "category": "Misc" },
        { "character": "🏈", "image": "1f3c8.png", "category": "Misc" },
        { "character": "🏊", "image": "1f3ca.png", "category": "Misc" },
        { "character": "🏠", "image": "1f3e0.png", "category": "Misc" },
        { "character": "🏡", "image": "1f3e1.png", "category": "Misc" },
        { "character": "🏢", "image": "1f3e2.png", "category": "Misc" },
        { "character": "🏣", "image": "1f3e3.png", "category": "Misc" },
        { "character": "🏥", "image": "1f3e5.png", "category": "Misc" },
        { "character": "🏦", "image": "1f3e6.png", "category": "Misc" },
        { "character": "🏧", "image": "1f3e7.png", "category": "Misc" },
        { "character": "🏨", "image": "1f3e8.png", "category": "Misc" },
        { "character": "🏩", "image": "1f3e9.png", "category": "Misc" },
        { "character": "🏪", "image": "1f3ea.png", "category": "Misc" },
        { "character": "🏫", "image": "1f3eb.png", "category": "Misc" },
        { "character": "🏬", "image": "1f3ec.png", "category": "Misc" },
        { "character": "🏭", "image": "1f3ed.png", "category": "Misc" },
        { "character": "🏮", "image": "1f3ee.png", "category": "Misc" },
        { "character": "🏯", "image": "1f3ef.png", "category": "Misc" },
        { "character": "🏰", "image": "1f3f0.png", "category": "Misc" },
        { "character": "🐌", "image": "1f40c.png", "category": "Animals" },
        { "character": "🐍", "image": "1f40d.png", "category": "Animals" },
        { "character": "🐎", "image": "1f40e.png", "category": "Animals" },
        { "character": "🐑", "image": "1f411.png", "category": "Animals" },
        { "character": "🐒", "image": "1f412.png", "category": "Animals" },
        { "character": "🐔", "image": "1f414.png", "category": "Animals" },
        { "character": "🐗", "image": "1f417.png", "category": "Animals" },
        { "character": "🐘", "image": "1f418.png", "category": "Animals" },
        { "character": "🐙", "image": "1f419.png", "category": "Animals" },
        { "character": "🐚", "image": "1f41a.png", "category": "Animals" },
        { "character": "🐛", "image": "1f41b.png", "category": "Animals" },
        { "character": "🐜", "image": "1f41c.png", "category": "Animals" },
        { "character": "🐝", "image": "1f41d.png", "category": "Animals" },
        { "character": "🐞", "image": "1f41e.png", "category": "Animals" },
        { "character": "🐟", "image": "1f41f.png", "category": "Animals" },
        { "character": "🐠", "image": "1f420.png", "category": "Animals" },
        { "character": "🐡", "image": "1f421.png", "category": "Animals" },
        { "character": "🐢", "image": "1f422.png", "category": "Animals" },
        { "character": "🐣", "image": "1f423.png", "category": "Animals" },
        { "character": "🐤", "image": "1f424.png", "category": "Animals" },
        { "character": "🐥", "image": "1f425.png", "category": "Animals" },
        { "character": "🐦", "image": "1f426.png", "category": "Animals" },
        { "character": "🐧", "image": "1f427.png", "category": "Animals" },
        { "character": "🐨", "image": "1f428.png", "category": "Animals" },
        { "character": "🐩", "image": "1f429.png", "category": "Animals" },
        { "character": "🐫", "image": "1f42b.png", "category": "Animals" },
        { "character": "🐬", "image": "1f42c.png", "category": "Animals" },
        { "character": "🐭", "image": "1f42d.png", "category": "Animals" },
        { "character": "🐮", "image": "1f42e.png", "category": "Animals" },
        { "character": "🐯", "image": "1f42f.png", "category": "Animals" },
        { "character": "🐰", "image": "1f430.png", "category": "Animals" },
        { "character": "🐱", "image": "1f431.png", "category": "Animals" },
        { "character": "🐲", "image": "1f432.png", "category": "Animals" },
        { "character": "🐳", "image": "1f433.png", "category": "Animals" },
        { "character": "🐴", "image": "1f434.png", "category": "Animals" },
        { "character": "🐵", "image": "1f435.png", "category": "Animals" },
        { "character": "🐶", "image": "1f436.png", "category": "Animals" },
        { "character": "🐷", "image": "1f437.png", "category": "Animals" },
        { "character": "🐸", "image": "1f438.png", "category": "Animals" },
        { "character": "🐹", "image": "1f439.png", "category": "Animals" },
        { "character": "🐺", "image": "1f43a.png", "category": "Animals" },
        { "character": "🐻", "image": "1f43b.png", "category": "Animals" },
        { "character": "🐼", "image": "1f43c.png", "category": "Animals" },
        { "character": "🐽", "image": "1f43d.png", "category": "Misc" },
        { "character": "🐾", "image": "1f43e.png", "category": "Misc" },
        { "character": "👀", "image": "1f440.png", "category": "Misc" },
        { "character": "👂", "image": "1f442.png", "category": "Misc" },
        { "character": "👃", "image": "1f443.png", "category": "Misc" },
        { "character": "👄", "image": "1f444.png", "category": "Misc" },
        { "character": "👅", "image": "1f445.png", "category": "Misc" },
        { "character": "👆", "image": "1f446.png", "category": "Misc" },
        { "character": "👇", "image": "1f447.png", "category": "Misc" },
        { "character": "👈", "image": "1f448.png", "category": "Misc" },
        { "character": "👉", "image": "1f449.png", "category": "Misc" },
        { "character": "👊", "image": "1f44a.png", "category": "Emoticons" },
        { "character": "👋", "image": "1f44b.png", "category": "Emoticons" },
        { "character": "👌", "image": "1f44c.png", "category": "Emoticons" },
        { "character": "👍", "image": "1f44d.png", "category": "Emoticons" },
        { "character": "👎", "image": "1f44e.png", "category": "Emoticons" },
        { "character": "👏", "image": "1f44f.png", "category": "Emoticons" },
        { "character": "👐", "image": "1f450.png", "category": "Misc" },
        { "character": "👑", "image": "1f451.png", "category": "Misc" },
        { "character": "👒", "image": "1f452.png", "category": "Misc" },
        { "character": "👓", "image": "1f453.png", "category": "Misc" },
        { "character": "👔", "image": "1f454.png", "category": "Misc" },
        { "character": "👕", "image": "1f455.png", "category": "Misc" },
        { "character": "👖", "image": "1f456.png", "category": "Misc" },
        { "character": "👗", "image": "1f457.png", "category": "Misc" },
        { "character": "👘", "image": "1f458.png", "category": "Misc" },
        { "character": "👙", "image": "1f459.png", "category": "Misc" },
        { "character": "👚", "image": "1f45a.png", "category": "Misc" },
        { "character": "👛", "image": "1f45b.png", "category": "Misc" },
        { "character": "👜", "image": "1f45c.png", "category": "Misc" },
        { "character": "👝", "image": "1f45d.png", "category": "Misc" },
        { "character": "👞", "image": "1f45e.png", "category": "Misc" },
        { "character": "👟", "image": "1f45f.png", "category": "Misc" },
        { "character": "👠", "image": "1f460.png", "category": "Misc" },
        { "character": "👡", "image": "1f461.png", "category": "Misc" },
        { "character": "👢", "image": "1f462.png", "category": "Misc" },
        { "character": "👣", "image": "1f463.png", "category": "Misc" },
        { "character": "👤", "image": "1f464.png", "category": "Misc" },
        { "character": "👦", "image": "1f466.png", "category": "Misc" },
        { "character": "👧", "image": "1f467.png", "category": "Misc" },
        { "character": "👨", "image": "1f468.png", "category": "Misc" },
        { "character": "👩", "image": "1f469.png", "category": "Misc" },
        { "character": "👪", "image": "1f46a.png", "category": "Misc" },
        { "character": "👫", "image": "1f46b.png", "category": "Misc" },
        { "character": "👮", "image": "1f46e.png", "category": "Misc" },
        { "character": "👯", "image": "1f46f.png", "category": "Misc" },
        { "character": "👰", "image": "1f470.png", "category": "Misc" },
        { "character": "👱", "image": "1f471.png", "category": "Misc" },
        { "character": "👲", "image": "1f472.png", "category": "Misc" },
        { "character": "👳", "image": "1f473.png", "category": "Misc" },
        { "character": "👴", "image": "1f474.png", "category": "Misc" },
        { "character": "👵", "image": "1f475.png", "category": "Misc" },
        { "character": "👶", "image": "1f476.png", "category": "Misc" },
        { "character": "👷", "image": "1f477.png", "category": "Misc" },
        { "character": "👸", "image": "1f478.png", "category": "Misc" },
        { "character": "👹", "image": "1f479.png", "category": "Misc" },
        { "character": "👺", "image": "1f47a.png", "category": "Misc" },
        { "character": "👻", "image": "1f47b.png", "category": "Misc" },
        { "character": "👼", "image": "1f47c.png", "category": "Misc" },
        { "character": "👽", "image": "1f47d.png", "category": "Misc" },
        { "character": "👾", "image": "1f47e.png", "category": "Misc" },
        { "character": "👿", "image": "1f47f.png", "category": "Misc" },
        { "character": "💀", "image": "1f480.png", "category": "Misc" },
        { "character": "💁", "image": "1f481.png", "category": "Misc" },
        { "character": "💂", "image": "1f482.png", "category": "Misc" },
        { "character": "💃", "image": "1f483.png", "category": "Misc" },
        { "character": "💄", "image": "1f484.png", "category": "Misc" },
        { "character": "💅", "image": "1f485.png", "category": "Misc" },
        { "character": "💆", "image": "1f486.png", "category": "Misc" },
        { "character": "💇", "image": "1f487.png", "category": "Misc" },
        { "character": "💈", "image": "1f488.png", "category": "Misc" },
        { "character": "💉", "image": "1f489.png", "category": "Misc" },
        { "character": "💊", "image": "1f48a.png", "category": "Misc" },
        { "character": "💋", "image": "1f48b.png", "category": "Misc" },
        { "character": "💌", "image": "1f48c.png", "category": "Misc" },
        { "character": "💍", "image": "1f48d.png", "category": "Misc" },
        { "character": "💎", "image": "1f48e.png", "category": "Misc" },
        { "character": "💏", "image": "1f48f.png", "category": "Misc" },
        { "character": "💐", "image": "1f490.png", "category": "Misc" },
        { "character": "💑", "image": "1f491.png", "category": "Misc" },
        { "character": "💒", "image": "1f492.png", "category": "Misc" },
        { "character": "💓", "image": "1f493.png", "category": "Misc" },
        { "character": "💔", "image": "1f494.png", "category": "Misc" },
        { "character": "💕", "image": "1f495.png", "category": "Misc" },
        { "character": "💖", "image": "1f496.png", "category": "Misc" },
        { "character": "💗", "image": "1f497.png", "category": "Misc" },
        { "character": "💘", "image": "1f498.png", "category": "Misc" },
        { "character": "💙", "image": "1f499.png", "category": "Misc" },
        { "character": "💚", "image": "1f49a.png", "category": "Misc" },
        { "character": "💛", "image": "1f49b.png", "category": "Misc" },
        { "character": "💜", "image": "1f49c.png", "category": "Misc" },
        { "character": "💝", "image": "1f49d.png", "category": "Misc" },
        { "character": "💞", "image": "1f49e.png", "category": "Misc" },
        { "character": "💟", "image": "1f49f.png", "category": "Misc" },
        { "character": "💠", "image": "1f4a0.png", "category": "Misc" },
        { "character": "💡", "image": "1f4a1.png", "category": "Misc" },
        { "character": "💢", "image": "1f4a2.png", "category": "Misc" },
        { "character": "💣", "image": "1f4a3.png", "category": "Misc" },
        { "character": "💤", "image": "1f4a4.png", "category": "Misc" },
        { "character": "💥", "image": "1f4a5.png", "category": "Misc" },
        { "character": "💦", "image": "1f4a6.png", "category": "Misc" },
        { "character": "💧", "image": "1f4a7.png", "category": "Misc" },
        { "character": "💨", "image": "1f4a8.png", "category": "Misc" },
        { "character": "💩", "image": "1f4a9.png", "category": "Misc" },
        { "character": "💪", "image": "1f4aa.png", "category": "Misc" },
        { "character": "💫", "image": "1f4ab.png", "category": "Misc" },
        { "character": "💬", "image": "1f4ac.png", "category": "Misc" },
        { "character": "💮", "image": "1f4ae.png", "category": "Misc" },
        { "character": "💯", "image": "1f4af.png", "category": "Misc" },
        { "character": "💰", "image": "1f4b0.png", "category": "Misc" },
        { "character": "💱", "image": "1f4b1.png", "category": "Misc" },
        { "character": "💲", "image": "1f4b2.png", "category": "Misc" },
        { "character": "💳", "image": "1f4b3.png", "category": "Misc" },
        { "character": "💴", "image": "1f4b4.png", "category": "Misc" },
        { "character": "💵", "image": "1f4b5.png", "category": "Misc" },
        { "character": "💸", "image": "1f4b8.png", "category": "Misc" },
        { "character": "💹", "image": "1f4b9.png", "category": "Misc" },
        { "character": "💺", "image": "1f4ba.png", "category": "Misc" },
        { "character": "💻", "image": "1f4bb.png", "category": "Misc" },
        { "character": "💼", "image": "1f4bc.png", "category": "Misc" },
        { "character": "💽", "image": "1f4bd.png", "category": "Misc" },
        { "character": "💾", "image": "1f4be.png", "category": "Misc" },
        { "character": "💿", "image": "1f4bf.png", "category": "Misc" },
        { "character": "📀", "image": "1f4c0.png", "category": "Misc" },
        { "character": "📁", "image": "1f4c1.png", "category": "Misc" },
        { "character": "📂", "image": "1f4c2.png", "category": "Misc" },
        { "character": "📃", "image": "1f4c3.png", "category": "Misc" },
        { "character": "📄", "image": "1f4c4.png", "category": "Misc" },
        { "character": "📅", "image": "1f4c5.png", "category": "Misc" },
        { "character": "📆", "image": "1f4c6.png", "category": "Misc" },
        { "character": "📇", "image": "1f4c7.png", "category": "Misc" },
        { "character": "📈", "image": "1f4c8.png", "category": "Misc" },
        { "character": "📉", "image": "1f4c9.png", "category": "Misc" },
        { "character": "📊", "image": "1f4ca.png", "category": "Misc" },
        { "character": "📋", "image": "1f4cb.png", "category": "Misc" },
        { "character": "📌", "image": "1f4cc.png", "category": "Misc" },
        { "character": "📍", "image": "1f4cd.png", "category": "Misc" },
        { "character": "📎", "image": "1f4ce.png", "category": "Misc" },
        { "character": "📏", "image": "1f4cf.png", "category": "Misc" },
        { "character": "📐", "image": "1f4d0.png", "category": "Misc" },
        { "character": "📑", "image": "1f4d1.png", "category": "Misc" },
        { "character": "📒", "image": "1f4d2.png", "category": "Misc" },
        { "character": "📓", "image": "1f4d3.png", "category": "Misc" },
        { "character": "📔", "image": "1f4d4.png", "category": "Misc" },
        { "character": "📕", "image": "1f4d5.png", "category": "Misc" },
        { "character": "📖", "image": "1f4d6.png", "category": "Misc" },
        { "character": "📗", "image": "1f4d7.png", "category": "Misc" },
        { "character": "📘", "image": "1f4d8.png", "category": "Misc" },
        { "character": "📙", "image": "1f4d9.png", "category": "Misc" },
        { "character": "📚", "image": "1f4da.png", "category": "Misc" },
        { "character": "📛", "image": "1f4db.png", "category": "Misc" },
        { "character": "📜", "image": "1f4dc.png", "category": "Misc" },
        { "character": "📝", "image": "1f4dd.png", "category": "Misc" },
        { "character": "📞", "image": "1f4de.png", "category": "Misc" },
        { "character": "📟", "image": "1f4df.png", "category": "Misc" },
        { "character": "📠", "image": "1f4e0.png", "category": "Misc" },
        { "character": "📡", "image": "1f4e1.png", "category": "Misc" },
        { "character": "📢", "image": "1f4e2.png", "category": "Misc" },
        { "character": "📣", "image": "1f4e3.png", "category": "Misc" },
        { "character": "📤", "image": "1f4e4.png", "category": "Misc" },
        { "character": "📥", "image": "1f4e5.png", "category": "Misc" },
        { "character": "📦", "image": "1f4e6.png", "category": "Misc" },
        { "character": "📧", "image": "1f4e7.png", "category": "Misc" },
        { "character": "📨", "image": "1f4e8.png", "category": "Misc" },
        { "character": "📩", "image": "1f4e9.png", "category": "Misc" },
        { "character": "📪", "image": "1f4ea.png", "category": "Misc" },
        { "character": "📫", "image": "1f4eb.png", "category": "Misc" },
        { "character": "📮", "image": "1f4ee.png", "category": "Misc" },
        { "character": "📰", "image": "1f4f0.png", "category": "Misc" },
        { "character": "📱", "image": "1f4f1.png", "category": "Misc" },
        { "character": "📲", "image": "1f4f2.png", "category": "Misc" },
        { "character": "📳", "image": "1f4f3.png", "category": "Misc" },
        { "character": "📴", "image": "1f4f4.png", "category": "Misc" },
        { "character": "📶", "image": "1f4f6.png", "category": "Misc" },
        { "character": "📷", "image": "1f4f7.png", "category": "Misc" },
        { "character": "📹", "image": "1f4f9.png", "category": "Misc" },
        { "character": "📺", "image": "1f4fa.png", "category": "Misc" },
        { "character": "📻", "image": "1f4fb.png", "category": "Misc" },
        { "character": "📼", "image": "1f4fc.png", "category": "Misc" },
        { "character": "🔃", "image": "1f503.png", "category": "Misc" },
        { "character": "🔊", "image": "1f50a.png", "category": "Misc" },
        { "character": "🔋", "image": "1f50b.png", "category": "Misc" },
        { "character": "🔌", "image": "1f50c.png", "category": "Misc" },
        { "character": "🔍", "image": "1f50d.png", "category": "Misc" },
        { "character": "🔎", "image": "1f50e.png", "category": "Misc" },
        { "character": "🔏", "image": "1f50f.png", "category": "Misc" },
        { "character": "🔐", "image": "1f510.png", "category": "Misc" },
        { "character": "🔑", "image": "1f511.png", "category": "Misc" },
        { "character": "🔒", "image": "1f512.png", "category": "Misc" },
        { "character": "🔓", "image": "1f513.png", "category": "Misc" },
        { "character": "🔔", "image": "1f514.png", "category": "Misc" },
        { "character": "🔖", "image": "1f516.png", "category": "Misc" },
        { "character": "🔗", "image": "1f517.png", "category": "Misc" },
        { "character": "🔘", "image": "1f518.png", "category": "Misc" },
        { "character": "🔙", "image": "1f519.png", "category": "Misc" },
        { "character": "🔚", "image": "1f51a.png", "category": "Misc" },
        { "character": "🔛", "image": "1f51b.png", "category": "Misc" },
        { "character": "🔜", "image": "1f51c.png", "category": "Misc" },
        { "character": "🔝", "image": "1f51d.png", "category": "Misc" },
        { "character": "🔞", "image": "1f51e.png", "category": "Misc" },
        { "character": "🔟", "image": "1f51f.png", "category": "Misc" },
        { "character": "🔠", "image": "1f520.png", "category": "Misc" },
        { "character": "🔡", "image": "1f521.png", "category": "Misc" },
        { "character": "🔢", "image": "1f522.png", "category": "Misc" },
        { "character": "🔣", "image": "1f523.png", "category": "Misc" },
        { "character": "🔤", "image": "1f524.png", "category": "Misc" },
        { "character": "🔥", "image": "1f525.png", "category": "Misc" },
        { "character": "🔦", "image": "1f526.png", "category": "Misc" },
        { "character": "🔧", "image": "1f527.png", "category": "Misc" },
        { "character": "🔨", "image": "1f528.png", "category": "Misc" },
        { "character": "🔩", "image": "1f529.png", "category": "Misc" },
        { "character": "🔪", "image": "1f52a.png", "category": "Misc" },
        { "character": "🔫", "image": "1f52b.png", "category": "Misc" },
        { "character": "🔮", "image": "1f52e.png", "category": "Misc" },
        { "character": "🔯", "image": "1f52f.png", "category": "Misc" },
        { "character": "🔰", "image": "1f530.png", "category": "Misc" },
        { "character": "🔱", "image": "1f531.png", "category": "Misc" },
        { "character": "🔲", "image": "1f532.png", "category": "Misc" },
        { "character": "🔳", "image": "1f533.png", "category": "Misc" },
        { "character": "🔴", "image": "1f534.png", "category": "Misc" },
        { "character": "🔵", "image": "1f535.png", "category": "Misc" },
        { "character": "🔶", "image": "1f536.png", "category": "Misc" },
        { "character": "🔷", "image": "1f537.png", "category": "Misc" },
        { "character": "🔸", "image": "1f538.png", "category": "Misc" },
        { "character": "🔹", "image": "1f539.png", "category": "Misc" },
        { "character": "🔺", "image": "1f53a.png", "category": "Misc" },
        { "character": "🔻", "image": "1f53b.png", "category": "Misc" },
        { "character": "🔼", "image": "1f53c.png", "category": "Misc" },
        { "character": "🔽", "image": "1f53d.png", "category": "Misc" },
        { "character": "🕐", "image": "1f550.png", "category": "Misc" },
        { "character": "🕑", "image": "1f551.png", "category": "Misc" },
        { "character": "🕒", "image": "1f552.png", "category": "Misc" },
        { "character": "🕓", "image": "1f553.png", "category": "Misc" },
        { "character": "🕔", "image": "1f554.png", "category": "Misc" },
        { "character": "🕕", "image": "1f555.png", "category": "Misc" },
        { "character": "🕖", "image": "1f556.png", "category": "Misc" },
        { "character": "🕗", "image": "1f557.png", "category": "Misc" },
        { "character": "🕘", "image": "1f558.png", "category": "Misc" },
        { "character": "🕙", "image": "1f559.png", "category": "Misc" },
        { "character": "🕚", "image": "1f55a.png", "category": "Misc" },
        { "character": "🕛", "image": "1f55b.png", "category": "Misc" },
        { "character": "🗻", "image": "1f5fb.png", "category": "Misc" },
        { "character": "🗼", "image": "1f5fc.png", "category": "Misc" },
        { "character": "🗽", "image": "1f5fd.png", "category": "Misc" },
        { "character": "🗾", "image": "1f5fe.png", "category": "Misc" },
        { "character": "🗿", "image": "1f5ff.png", "category": "Misc" },
        { "character": "😀", "image": "1f600.png", "category": "Emoticons" },
        { "character": "😇", "image": "1f607.png", "category": "Emoticons" },
        { "character": "😈", "image": "1f608.png", "category": "Emoticons" },
        { "character": "😎", "image": "1f60e.png", "category": "Emoticons" },
        { "character": "😐", "image": "1f610.png", "category": "Emoticons" },
        { "character": "😑", "image": "1f611.png", "category": "Emoticons" },
        { "character": "😕", "image": "1f615.png", "category": "Emoticons" },
        { "character": "😗", "image": "1f617.png", "category": "Emoticons" },
        { "character": "😙", "image": "1f619.png", "category": "Emoticons" },
        { "character": "😛", "image": "1f61b.png", "category": "Emoticons" },
        { "character": "😟", "image": "1f61f.png", "category": "Emoticons" },
        { "character": "😦", "image": "1f626.png", "category": "Emoticons" },
        { "character": "😧", "image": "1f627.png", "category": "Emoticons" },
        { "character": "😬", "image": "1f62c.png", "category": "Emoticons" },
        { "character": "😮", "image": "1f62e.png", "category": "Emoticons" },
        { "character": "😯", "image": "1f62f.png", "category": "Emoticons" },
        { "character": "😴", "image": "1f634.png", "category": "Emoticons" },
        { "character": "😶", "image": "1f636.png", "category": "Emoticons" },
        { "character": "🚁", "image": "1f681.png", "category": "Travel" },
        { "character": "🚂", "image": "1f682.png", "category": "Travel" },
        { "character": "🚆", "image": "1f686.png", "category": "Travel" },
        { "character": "🚈", "image": "1f688.png", "category": "Travel" },
        { "character": "🚊", "image": "1f68a.png", "category": "Travel" },
        { "character": "🚍", "image": "1f68d.png", "category": "Travel" },
        { "character": "🚎", "image": "1f68e.png", "category": "Travel" },
        { "character": "🚐", "image": "1f690.png", "category": "Travel" },
        { "character": "🚔", "image": "1f694.png", "category": "Travel" },
        { "character": "🚖", "image": "1f696.png", "category": "Travel" },
        { "character": "🚘", "image": "1f698.png", "category": "Travel" },
        { "character": "🚛", "image": "1f69b.png", "category": "Travel" },
        { "character": "🚜", "image": "1f69c.png", "category": "Travel" },
        { "character": "🚝", "image": "1f69d.png", "category": "Travel" },
        { "character": "🚞", "image": "1f69e.png", "category": "Travel" },
        { "character": "🚟", "image": "1f69f.png", "category": "Travel" },
        { "character": "🚠", "image": "1f6a0.png", "category": "Travel" },
        { "character": "🚡", "image": "1f6a1.png", "category": "Travel" },
        { "character": "🚣", "image": "1f6a3.png", "category": "Travel" },
        { "character": "🚦", "image": "1f6a6.png", "category": "Travel" },
        { "character": "🚮", "image": "1f6ae.png", "category": "Travel" },
        { "character": "🚯", "image": "1f6af.png", "category": "Travel" },
        { "character": "🚰", "image": "1f6b0.png", "category": "Travel" },
        { "character": "🚱", "image": "1f6b1.png", "category": "Travel" },
        { "character": "🚳", "image": "1f6b3.png", "category": "Travel" },
        { "character": "🚴", "image": "1f6b4.png", "category": "Travel" },
        { "character": "🚵", "image": "1f6b5.png", "category": "Travel" },
        { "character": "🚷", "image": "1f6b7.png", "category": "Travel" },
        { "character": "🚸", "image": "1f6b8.png", "category": "Travel" },
        { "character": "🚿", "image": "1f6bf.png", "category": "Travel" },
        { "character": "🛁", "image": "1f6c1.png", "category": "Travel" },
        { "character": "🛂", "image": "1f6c2.png", "category": "Travel" },
        { "character": "🛃", "image": "1f6c3.png", "category": "Travel" },
        { "character": "🛄", "image": "1f6c4.png", "category": "Travel" },
        { "character": "🛅", "image": "1f6c5.png", "category": "Travel" },
        { "character": "🌍", "image": "1f30d.png", "category": "Symbols" },
        { "character": "🌎", "image": "1f30e.png", "category": "Symbols" },
        { "character": "🌐", "image": "1f310.png", "category": "Symbols" },
        { "character": "🌒", "image": "1f312.png", "category": "Symbols" },
        { "character": "🌖", "image": "1f316.png", "category": "Symbols" },
        { "character": "🌗", "image": "1f317.png", "category": "Symbols" },
        { "character": "🌘", "image": "1f318.png", "category": "Symbols" },
        { "character": "🌚", "image": "1f31a.png", "category": "Symbols" },
        { "character": "🌜", "image": "1f31c.png", "category": "Symbols" },
        { "character": "🌝", "image": "1f31d.png", "category": "Symbols" },
        { "character": "🌞", "image": "1f31e.png", "category": "Symbols" },
        { "character": "🌲", "image": "1f332.png", "category": "Symbols" },
        { "character": "🌳", "image": "1f333.png", "category": "Symbols" },
        { "character": "🍋", "image": "1f34b.png", "category": "Food" },
        { "character": "🍐", "image": "1f350.png", "category": "Food" },
        { "character": "🍼", "image": "1f37c.png", "category": "Food" },
        { "character": "🏇", "image": "1f3c7.png", "category": "Symbols" },
        { "character": "🏉", "image": "1f3c9.png", "category": "Food" },
        { "character": "🏤", "image": "1f3e4.png", "category": "Symbols" },
        { "character": "🐀", "image": "1f400.png", "category": "Animals" },
        { "character": "🐁", "image": "1f401.png", "category": "Animals" },
        { "character": "🐂", "image": "1f402.png", "category": "Animals" },
        { "character": "🐃", "image": "1f403.png", "category": "Animals" },
        { "character": "🐄", "image": "1f404.png", "category": "Animals" },
        { "character": "🐅", "image": "1f405.png", "category": "Animals" },
        { "character": "🐆", "image": "1f406.png", "category": "Animals" },
        { "character": "🐇", "image": "1f407.png", "category": "Animals" },
        { "character": "🐈", "image": "1f408.png", "category": "Animals" },
        { "character": "🐉", "image": "1f409.png", "category": "Animals" },
        { "character": "🐊", "image": "1f40a.png", "category": "Animals" },
        { "character": "🐋", "image": "1f40b.png", "category": "Animals" },
        { "character": "🐏", "image": "1f40f.png", "category": "Animals" },
        { "character": "🐐", "image": "1f410.png", "category": "Animals" },
        { "character": "🐓", "image": "1f413.png", "category": "Animals" },
        { "character": "🐕", "image": "1f415.png", "category": "Animals" },
        { "character": "🐖", "image": "1f416.png", "category": "Animals" },
        { "character": "🐪", "image": "1f42a.png", "category": "Animals" },
        { "character": "👥", "image": "1f465.png", "category": "Symbols" },
        { "character": "👬", "image": "1f46c.png", "category": "Symbols" },
        { "character": "👭", "image": "1f46d.png", "category": "Symbols" },
        { "character": "💭", "image": "1f4ad.png", "category": "Symbols" },
        { "character": "💶", "image": "1f4b6.png", "category": "Symbols" },
        { "character": "💷", "image": "1f4b7.png", "category": "Symbols" },
        { "character": "📬", "image": "1f4ec.png", "category": "Symbols" },
        { "character": "📭", "image": "1f4ed.png", "category": "Symbols" },
        { "character": "📯", "image": "1f4ef.png", "category": "Symbols" },
        { "character": "📵", "image": "1f4f5.png", "category": "Symbols" },
        { "character": "🔀", "image": "1f500.png", "category": "Symbols" },
        { "character": "🔁", "image": "1f501.png", "category": "Symbols" },
        { "character": "🔂", "image": "1f502.png", "category": "Symbols" },
        { "character": "🔄", "image": "1f504.png", "category": "Symbols" },
        { "character": "🔅", "image": "1f505.png", "category": "Symbols" },
        { "character": "🔆", "image": "1f506.png", "category": "Symbols" },
        { "character": "🔇", "image": "1f507.png", "category": "Symbols" },
        { "character": "🔉", "image": "1f509.png", "category": "Symbols" },
        { "character": "🔕", "image": "1f515.png", "category": "Symbols" },
        { "character": "🔬", "image": "1f52c.png", "category": "Symbols" },
        { "character": "🔭", "image": "1f52d.png", "category": "Symbols" },
        { "character": "🕜", "image": "1f55c.png", "category": "Symbols" },
        { "character": "🕝", "image": "1f55d.png", "category": "Symbols" },
        { "character": "🕞", "image": "1f55e.png", "category": "Symbols" },
        { "character": "🕟", "image": "1f55f.png", "category": "Symbols" },
        { "character": "🕠", "image": "1f560.png", "category": "Symbols" },
        { "character": "🕡", "image": "1f561.png", "category": "Symbols" },
        { "character": "🕢", "image": "1f562.png", "category": "Symbols" },
        { "character": "🕣", "image": "1f563.png", "category": "Symbols" },
        { "character": "🕤", "image": "1f564.png", "category": "Symbols" },
        { "character": "🕥", "image": "1f565.png", "category": "Symbols" },
        { "character": "🕦", "image": "1f566.png", "category": "Symbols" },
        { "character": "🕧", "image": "1f567.png", "category": "Symbols" }
    ],
    "emojiCats": ["Emoticons", "Dingbats", "Symbols", "Travel", "Misc", "Food", "Animals"]
}
module.exports = emojis
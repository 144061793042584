import React from "react";

export default function IconPencil({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.586 3.58604C13.7705 3.39502 13.9912 3.24266 14.2352 3.13784C14.4792 3.03302 14.7416 2.97785 15.0072 2.97554C15.2728 2.97323 15.5361 3.02384 15.7819 3.1244C16.0277 3.22496 16.251 3.37346 16.4388 3.56125C16.6266 3.74904 16.7751 3.97234 16.8756 4.21813C16.9762 4.46392 17.0268 4.72728 17.0245 4.99284C17.0222 5.2584 16.967 5.52084 16.8622 5.76485C16.7574 6.00886 16.605 6.22955 16.414 6.41404L15.621 7.20704L12.793 4.37904L13.586 3.58604ZM11.379 5.79304L3 14.172V17H5.828L14.208 8.62104L11.378 5.79304H11.379Z"
        fill={color}
      />
    </svg>
  );
}

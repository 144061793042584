import React from 'react'
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';

@connect((store) => {
    return { user: store.user }
})
export default class IncCapsuleChangeGroup extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return <div>

            <h4 className="mar-b25">Change the Groups</h4>
            <div className="mar-b25">You are about to set or change the groups that Dynamics syncs contacts and leads with.</div>
            <div className="mar-b25">If you proceed, your Dynamics contacts and leads will be automatically added to these groups. </div>
            <div className="mar-b25">If you delete either of the groups in the future, your Dynamics contacts and leads will stop being synced.</div>
            <div className="mar-b25">You can change these groups at any time.</div>

            <UILIB.Button text="Select these Groups" className="button-primary" onClick={this.props.changeTheGroup} />

        </div>
    }
};


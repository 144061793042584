import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

import SiteTrackingNav from './siteTrackingNav';
import NewUserForm from '../includes/bb_topNav/newUserForm/newUserForm';

@connect((store) => {
    return { site: store.siteMaster, account: store.accountMaster }
})
class SiteTrackingTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

        this.activate = this.activate.bind(this);
    }


    activate() {
        const drawer = <NewUserForm from="tracking" />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, 'right', drawer, true, '100vw'))
    }

    render() {
        if (this.props.hideTemplate) {
            return this.props.children
        }
        const account = this.props.account.accountMaster
        return <UILIB.Paper>
            <div className="page-header mar-b25">{i18n.t('campaigns:sitetracking.trackingsites.header')}</div>
            {(!account.approved && account.doneNewUserQuestions) && <UILIB.Paper className="paper-purple mar-b25 quickFlex">
                <UILIB.Icons icon="alert" color="#FFFFFF" style={{ height: 30, width: 30 }} className="mar-r10" />
                <div>
                    <strong>Account Awaiting Approval</strong> - We won't track site statistics until your account is approved
                </div>
            </UILIB.Paper>}
            {((!account.doneNewUserQuestions)) && <UILIB.Paper className="paper-purple mar-b25 quickFlex" style={{ justifyContent: "space-between" }}>
                <div className="quickFlex">
                    <UILIB.Icons icon="alert" color="#FFFFFF" style={{ height: 30, width: 30 }} className="mar-r10" />
                    <div>
                        <strong>Account Not Approved</strong> - Please verify your account now to activate Site Tracking.
                    </div>
                </div>
                <UILIB.Button className="button-white" onClick={this.activate.bind(this)}>Request Approval</UILIB.Button>
            </UILIB.Paper>}
            <SiteTrackingNav history={this.props.history} />
            {this.props.children}
        </UILIB.Paper >
    }
}

export default SiteTrackingTemplate;
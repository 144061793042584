import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import DateTimeFunctions from '~/Classes/dateTimeFunctions'
import IncDisconnect from './incDisconnect';

import ChangeGroup from './changeGroup';
import ApplicationTransactionsGraph from '../components/applicationTransactionsGraph'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class StripeConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            stripeSite: {},
            appData: {},
            appBinding: {},
            groupData: [],
            groupId: -1,
            importing: false,
            importId: -1,
            importProgress: 0
        };

        let timer;

        this.getStripeSite = this.getStripeSite.bind(this);
        this.getApp = this.getApp.bind(this);
        this.getAppBinding = this.getAppBinding.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.checkForImportUpdate = this.checkForImportUpdate.bind(this);
        this.resetGroup = this.resetGroup.bind(this);
        this.popDisconnect = this.popDisconnect.bind(this);
        this.goDisconnect = this.goDisconnect.bind(this);
    }

    componentDidMount() {
        this.getApp();
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer)
    }

    getApp() {
        var self = this;
        axios.get("/application/15").then(_theApp => {
            self.setState({ appData: _theApp.data.Application }, self.getAppBinding)
        })
    }

    getAppBinding() {
        var self = this;
        axios.get("/application/account/app/" + this.state.appData.id).then(_theResp => {
            var groupId = -1;
            if (_theResp.data.Application.appSettings && _theResp.data.Application.appSettings.groupId) groupId = _theResp.data.Application.appSettings.groupId;

            self.setState({ appBinding: _theResp.data.Application, groupId: groupId }, self.getStripeSite)
        })
    }

    getStripeSite() {

        var self = this;
        axios.get("/application/stripe/site").then(_theSite => {

            self.setState({ stripeSite: _theSite.data }, self.getGroups)
        }).catch(err => {
            this.props.history.push('/cp/company/integrations')
        })
    }

    getGroups() {
        var self = this;
        axios.get("/group").then(_theGroups => {
            self.setState({ groupData: _theGroups.data.Groups, isLoaded: true })
        })
    }


    resetGroup() {
        this.setState({ groupId: -1 })
    }
    updateGroup(newGroupId) {
        var self = this;
        var appBinding = this.state.appBinding;
        if (!appBinding.appSettings) appBinding.appSettings = {};

        appBinding.appSettings.groupId = newGroupId;
        axios.put("/application/account/" + this.state.appBinding.id, this.state.appBinding).then(_theRes => {
            return axios.get("/application/stripe/setupGroupAndImport")
        }).then(_res => {
            self.setState({ groupId: newGroupId, importing: true, importId: _res.data.id }, () => { self.checkForImportUpdate(); self.getGroups(); });

        })

    }

    checkForImportUpdate() {
        var self = this;
        axios.get('/group/' + this.state.groupId + '/import/' + this.state.importId).then(res => {
            self.setState({ importProgress: res.data.PercentComplete })
            if (res.data.PercentComplete < 100) {
                this.timer = setTimeout(this.checkForImportUpdate, 1000);
            }
            else {
                self.setState({ importing: false, importProgress: 100 }, this.getAppBinding)
            }
        })
    }

    popDisconnect() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <IncDisconnect goDisconnect={this.goDisconnect} />, true, "500px"));
    }

    goDisconnect() {
        var self = this;
        axios.get('/application/stripe/disconnect').then(res => {
            self.props.history.push('/cp/company/integrations')
        })
    }


    render() {

        if (!this.state.isLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>
        var currentGroup = this.state.groupData.find(theRow => theRow.id == this.state.groupId);
        if (this.state.groupId == '' || this.state.groupId == '-1' || !currentGroup) return <ChangeGroup appBinding={this.state.appBinding} updateGroup={this.updateGroup} />



        return <div>

            <UILIB.Row className="middle-xs mar-b10">
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="col-md-offset-2">
                    <div className="quickFlex">
                        <div className="integration-avatar rounded" style={{ backgroundImage: "url(" + this.state.appData.appLogoUri + ")" }}></div>
                        <p className="no-marg pad-left">Stripe is connected as "<strong>{this.state.stripeSite.business_name}</strong>"</p>
                    </div>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="end-xs">
                    <p className="no-marg">
                        <UILIB.Button className="button-sml grey" onClick={this.popDisconnect}>
                            Disconnect
                        </UILIB.Button>
                    </p>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="col-md-offset-2">
                    <UILIB.Paper className="height-100 mar-b0">
                        <h4 className="text-primary">Contacts</h4>
                        <p>Stripe is currently connected and is automatically syncing your contacts with the following group. If you add, update or delete a contact on Stripe it is automatically updated here.</p>
                        <p>
                            <div className="chip smallChip outline secondary" onClick={() => { this.props.history.push('/cp/groups/' + this.state.groupId); }}>{currentGroup.groupName}</div>
                            {!this.state.importing && this.state.appBinding.appSettings && this.state.appBinding.appSettings.lastSynced && <div className="text-xsml">Last synced: {DateTimeFunctions.formatDateTime(this.state.appBinding.appSettings.lastSynced, 1)} - <a onClick={() => { this.updateGroup(this.state.groupId) }}>Re-sync</a></div>}
                        </p>
                        <p className="no-marg"><UILIB.Button className="full-width button-md outline primary" onClick={this.resetGroup}>Change Group</UILIB.Button></p>
                        {this.state.importing && <div style={{ marginTop: "10px" }}><p>Performing Sync</p> <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.importProgress} val2={100} /></div>}
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={4} lg={4}>
                    <UILIB.Paper className="height-100 mar-b0">
                        <h4 className="text-primary">Sync history</h4>
                        <ApplicationTransactionsGraph applicationId={this.state.appData.id} />
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row className="mar-b60">
                <UILIB.Column xs={12} sm={12} md={8} lg={8} className="col-md-offset-2">
                    <UILIB.Paper className="height-100 mar-b0">
                        <UILIB.Row>
                            <UILIB.Column xs={12} margin={0}>
                                <h4 className="text-primary">Automation</h4>
                                <p>Automations are a great way to automatically make things happen based on your contacts activity. For example, you could send a welcome email when someone gets added to Stripe.</p>
                                <p><UILIB.Button text="Add an Automation" className="button-sml" onClick={() => { this.props.history.push('/cp/automation') }} /></p>
                            </UILIB.Column>
                        </UILIB.Row>

                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
        </div>

    };


};



import React, { useState } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import HelperFunctions from '~/Classes/helpers'
import HelpPopup from '../helpPopup/helpPopup';

export default function DrawerSplitStep({ step, readOnly, saveStep = () => { }, onBackClick }) {
    const [errors, setErrors] = useState({ splits: '' })

    const updateValue = e => {
        step.stepOptions.splits.value = Number(e.currentTarget.value)
        setErrors({ ...errors })
    }

    const save = () => {
        if (step.stepOptions.splits.value < 2) {
            step.complete = false
            return setErrors({ splits: 'must have at least 2 lanes' })
        } else {
            setErrors({ splits: '' })
        }

        const steps = step.steps || []
        if (steps.length < step.stepOptions.splits.value) {
            // add more
            const diff = step.stepOptions.splits.value - steps.length
            for (let i = 0; i < diff; i++) {
                steps.push([{
                    guid: HelperFunctions.createLocalGuid()
                }])
            }
        } else if (steps.length > step.stepOptions.splits.value) {
            // remove the last X steps
            steps.splice(step.stepOptions.splits.value, steps.length)
        }
        step.complete = true
        step.steps = steps

        saveStep()
    }

    return (
        <div data-test="drawer-split-step">
            <div className="automationStepContentHolder">

                <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="mar-b25">Split Contacts</h4>
                    <HelpPopup type={"split"} />
                </div>
                <div className="mar-b25">Split Contacts evenly down their their own lanes</div>

                <UILIB.TextInput label="Number of Lanes" type="number" name="splits" onChange={updateValue} value={step.stepOptions.splits.value} error={errors.splits} disabled={readOnly} />
            </div>


            <div className="automationStepSaveButtonHolder">
                {readOnly ? (
                    <>
                        <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                    </>
                ) : (
                    <UILIB.Button className="button-primary" text={i18n.t('save')} onClick={save} />
                )}
            </div>
        </div>
    )
}
import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import Button from "../../components/button";
import * as styles from "./index.module.scss";
import AddEditCustomFieldDialog from "./addEditFieldDialog";
import Header from "../../components/header";
import TierPermissions from '~/Classes/tierPermissions'
import i18n from "~/i18n";
import TierLimitHolder from "../../../../../Common-Objects/UI/Cards/TierLimitHolder";
import AlertDialog from '../../components/alertDialog';

export default function InboxCustomFields({ inbox }) {
    const dispatch = useDispatch();
    const account = useSelector((state) => state.accountMaster)

    const [fields, setFields] = useState(null);
    const [selectedField, setSelectedField] = useState(null);
    const [isAddFieldDialogOpen, setIsAddFieldDialogOpen] = useState(false);
    const [isEditFieldDialogOpen, setIsEditFieldDialogOpen] = useState(false);
    const [deletingField, setDeletingField] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const canUseFields = TierPermissions("chatCustomFields", account);

    const getFields = async () => {
        try {
            setDeletingField(null)
            setSelectedField(null)
            setIsAddFieldDialogOpen(false)
            setIsEditFieldDialogOpen(false)
            setIsDeleting(false)
            const { data } = await axios.get(`/inboxes/${inbox.id}/field`);
            setFields(data);
        } catch (error) {
            dispatch(handleGenericError(error));
        }
    }

    const onSubmit = async (name, type, options, agentSpecific, required) => {
        try {
            if (selectedField) {
                await axios.put(`/inboxes/${inbox.id}/field/${selectedField.id}`, {
                    name,
                    options,
                    agentSpecific,
                    required
                })
            } else {
                await axios.post(`/inboxes/${inbox.id}/field`, {
                    name,
                    type,
                    options,
                    agentSpecific,
                    required
                })
            }
            await getFields();
            alterSnackbar("success", i18n.t("chat:settings.fields.fieldAdded"));
        } catch (error) {
            dispatch(handleGenericError(error));
        }
    }

    useEffect(() => {
        if (!canUseFields.allowed) return
        getFields()
    }, [])

    if (!canUseFields.allowed) return <TierLimitHolder err="tierForbids" type="Chat Custom Fields" tierName={account.tier.name} />

    if (!fields) return <UILIB.LoadingIcons noClass />

    const data = fields.map(field => {
        return {
            name: {
                headerValue: 'Field Name',
                value: field.name
            },
            type: {
                headerValue: 'Type',
                value: i18n.t("chat:settings.fields.types." + field.type)
            },
            agentSpecific: {
                headerValue: 'User Specific',
                value: field.agentSpecific ? 'Yes' : 'No'
            },
            // required: {
            //     headerValue: 'Required',
            //     value: field.required ? 'Yes' : 'No'
            // },
            options: {
                headerValue: ' ',
                value: <div className={styles.tableActions}>
                    <UILIB.DropdownMenu.Root>
                        <UILIB.DropdownMenu.Trigger asChild>
                            <Button
                                aria-label="Additional options"
                                variant="ghost"
                                size="s"
                                iconOnly
                            >
                                <UILIB.Icon name="dots-vertical" />
                            </Button>
                        </UILIB.DropdownMenu.Trigger>

                        <UILIB.DropdownMenu.Portal>
                            <UILIB.DropdownMenu.Content align="end">
                                <UILIB.DropdownMenu.Item
                                    onClick={(e) => {
                                        setSelectedField(field)
                                        setIsEditFieldDialogOpen(true)
                                        return false
                                    }}
                                >
                                    {i18n.t("chat:settings.fields.edit")}
                                </UILIB.DropdownMenu.Item>
                                <UILIB.DropdownMenu.Item
                                    variant="danger"
                                    onClick={(e) => {
                                        setDeletingField(field)
                                        setIsDeleting(true)
                                        return false
                                    }}
                                >
                                    {i18n.t("chat:settings.fields.delete")}
                                </UILIB.DropdownMenu.Item>
                            </UILIB.DropdownMenu.Content>
                        </UILIB.DropdownMenu.Portal>
                    </UILIB.DropdownMenu.Root>
                </div>
            }
        }
    })

    return (
        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
            <Header type="inbox" option="fields" />
            <Button
                className={styles.addButton}
                size="s"
                iconLeft={<UILIB.Icon name="plus" />}
                onClick={() => setIsAddFieldDialogOpen(true)}
            >
                {i18n.t("chat:settings.fields.addField")}
            </Button>
            <UILIB.DataTable1 tableData={data} noResultsTxt={"No Custom Fields Created"} />

            <AddEditCustomFieldDialog
                onOpenChange={setIsEditFieldDialogOpen}
                fieldToEdit={selectedField}
                isOpen={isEditFieldDialogOpen}
                onSubmit={onSubmit}
            />

            <AddEditCustomFieldDialog
                onOpenChange={setIsAddFieldDialogOpen}
                isOpen={isAddFieldDialogOpen}
                onSubmit={onSubmit}
            />

            <DeleteField
                field={deletingField}
                isOpen={isDeleting}
                onOpenChange={getFields}
            />


        </div>
    )
}

function DeleteField({ field, isOpen = false, onOpenChange = () => { } }) {
    const [deleting, setDeleting] = useState(false)
    async function handleSubmit() {
        setDeleting(true);

        try {
            await axios.delete('/inboxes/' + field.InboxId + '/field/' + field.id)
        } finally {
            setDeleting(false)
        }
        onOpenChange()
    }

    return (
        <AlertDialog
            open={isOpen}
            title={i18n.t("chat:settings.fields.deleteTitle")}
            description={i18n.t("chat:settings.fields.deleteDesc", { name: field?.name })}
            ctaText={i18n.t("chat:settings.fields.deleteConfirm")}
            submitting={deleting}
            onCtaClick={handleSubmit}
            onClose={onOpenChange}
        >
        </AlertDialog>
    );
}
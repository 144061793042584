// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RSUMCRA3pEzQMit9GD1U{display:flex;gap:2px;color:var(--orange-400)}.I2NPUyWHa7f3W2jhCtJ6{height:15px;width:15px}", "",{"version":3,"sources":["webpack://./src/Common-Objects/UI/ScoreNew/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CACA,uBAAA,CAGF,sBACE,WAAA,CACA,UAAA","sourcesContent":[".root {\n  display: flex;\n  gap: 2px;\n  color: var(--orange-400);\n}\n\n.star {\n  height: 15px;\n  width: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "RSUMCRA3pEzQMit9GD1U",
	"star": "I2NPUyWHa7f3W2jhCtJ6"
};
export default ___CSS_LOADER_EXPORT___;

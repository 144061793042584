import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UILIB from '../../../../../../Common-Objects/Lib'
import axios from '../../../../../../data/http/axios'
import { alterSiteDrawer } from '../../../../../../data/actions/siteActions'

export default function DragDropFormOptions({ form, app }) {
    const dispatch = useDispatch()
    const account = useSelector(state => state.accountMaster)
    const [title, setTitle] = useState(form.options?.title || '')
    const [owner, setOwner] = useState(form?.options.capsuleOwner || '')
    const [owners, setOwners] = useState(null)
    const [teams, setTeams] = useState(null)
    const [tags, setTags] = useState(null)
    const [selectedTags, setSelectedTags] = useState(form.options?.tags || [])
    const [fields, setFields] = useState(null)
    const [urlToField, setUrlToField] = useState(form.options?.urlToField || '')

    const save = async () => {
        const options = { ...form.options, title, capsuleOwner: owner, tags: selectedTags, urlToField }
        await axios.put(`/group/${form.GroupId}/form/${form.id}`, {
            options: options
        })
        form.options = options
        dispatch(alterSiteDrawer(false, true, 'right', null))
    }

    const selectOwner = e => {
        setOwner(e.target.value)
    }

    const selectTag = e => {
        const arr = [...selectedTags]
        const index = arr.indexOf(e.target.value)
        if (index > -1) arr.splice(index, 1)
        else arr.push(e.target.value)
        setSelectedTags(arr)
    }

    useEffect(() => {
        if (app?.ApplicationId === 8) {
            axios.get('/application/capsule/users?skipOwning=true').then(res => setOwners(res.data.map(d => {
                d.value = 'owner-' + d.value
                return d
            })))
            axios.get('/application/capsule/teams').then(res => setTeams(res.data.map(d => {
                d.value = 'team-' + d.value
                return d
            })))
        }
    }, [app])

    useEffect(() => {
        axios.get('/tags').then(res => {
            setTags(res.data)
        })
        axios.get('/customfield').then(res => {
            setFields(res.data.CustomFields)

        })
    }, [])

    if (app?.ApplicationId === 8 && (!owners || !teams)) return <UILIB.LoadingIcons />
    if (!fields) return <UILIB.LoadingIcons />

    const capsuleOwners = app?.ApplicationId === 8 ? [{ label: 'Unassigned', value: 'none' }, ...owners.map(o => {
        o.group = 'Owner'
        return o
    }), ...teams.map(t => {
        t.group = 'Team'
        return t
    })] : []

    const stringFields = fields.filter(f => f.fieldType === 'TEXT').map(f => ({ label: f.fieldDesc, value: f.id }))

    return (
        <div>
            <h4 className='mar-b25'>Form Options</h4>
            <UILIB.TextInput outerClassName="mar-b25" label="Page Title" value={title} onChange={e => setTitle(e.target.value)} placeholder={`${account.accountMaster.accountName} | ${form.formName}`} />
            <UILIB.Select
                outerClassName="mar-b25" label="Map source URL to Custom Field" value={urlToField} onChange={e => setUrlToField(e.target.value)} data={[{ label: 'No Field Mapped', value: '' }].concat(stringFields)}
                explainer="When a contact signs up with this form, the URL they signed up from will be added to this custom field. You can then use this field to segment your contacts or automate based on it."

            />
            {app?.ApplicationId === 8 && !!owners?.length && <UILIB.Select
                outerClassName="mar-b25"
                label="Capsule Assigned User or Team"
                placeholder="Integration Owner"
                data={capsuleOwners}
                value={owner}
                onChange={selectOwner}
                explainer="Signups will be added to Capsule. Select which user/team to assign them to"
            />}
            {!!tags?.length && <UILIB.Select
                outerClassName="mar-b25"
                label="Tags"
                placeholder="Select Tags"
                data={tags.map(t => ({ label: t.tagName, value: t.id }))}
                multiple={true}
                value={selectedTags}
                onChange={selectTag}
                explainer="When a contact signs up with this form they will automatically get these tags"
            />}
            <UILIB.Button className="button-primary" onClick={save}>Save</UILIB.Button>
        </div>
    )
}
import React, { useState } from 'react'
import axios from '../../../data/http/axios'
import UILIB from '../../../Common-Objects/Lib'

function parser(data) {
    return data.Organisations.map(d => ({ label: d.name, value: d.id }))
}

export default function ChangeOrganisation({ SubscriberId, onChange = () => { }, onCancel = () => { } }) {
    const [newName, setNewName] = useState('')

    const save = async (id) => {
        await axios.put('/subscriber/' + SubscriberId + '/organisation', {
            OrganisationId: id
        })
        onChange()
    }

    const create = async () => {
        const response = await axios.post('/organisation', {
            name: newName
        })
        await save(response.data.Organisation.id)
    }

    return (
        <div>
            <div className="mar-b25">
                <div className="txtInput_label">Organisation</div>
                <UILIB.AutoComplete
                    url="/organisation?plain=true&limit=100&searchText={{name}}"
                    inputName="Organisation"
                    onKeyUp={setNewName}
                    parser={parser}
                    placeholder='Start Typing to Search or Create'
                    onChange={o => save(o.value)}
                />
            </div>
            <div className="quickFlex">

                <UILIB.Button disabled={newName < 3} text="Create" className="button-primary mar-r10" onClick={create} />
                <UILIB.Button text="Cancel" onClick={onCancel} />
            </div>

        </div>
    )
}
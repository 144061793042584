import React, { Component } from 'react';
import axios from '../../../../../data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import SiteVars from '~/Classes/siteVars';
import i18n from '~/i18n'
import Industries from '~/Classes/industries';
import { connect } from 'react-redux';
import tierPermissions from '~/Classes/tierPermissions';
import NewUserForm from '~/pages/cp/includes/bb_topNav/newUserForm/newUserForm'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here

@connect((store) => {
    return { accountMaster: store.accountMaster }
})
class AiContentGenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            industry: "",
            text: "",
            generatingText: false,
            generatedText: "",
            hasGeneratedText: false,
            generatingTextError: "",
            contentType: "",
            history: [],
            showHistory: false,
            historyCnt: 0,
            errorHolder: null
        }

        this.updateInput = this.updateInput.bind(this);
        this.generateText = this.generateText.bind(this);
        this.goInsert = this.goInsert.bind(this);
        this.tryAgain = this.tryAgain.bind(this);
        this.getHistoryCount = this.getHistoryCount.bind(this);
        this.getHistory = this.getHistory.bind(this);
        this.tierError = this.tierError.bind(this);
    }

    componentDidMount() {
        let contentType = "";
        if (this.props.contentType) contentType = this.props.contentType;
        this.setState({ industry: 'Other', contentType }, this.getHistoryCount)
    }
    updateInput(ev) {
        this.setState({
            generatingTextError: "",
            [ev.target.name]: ev.target.value
        })
    }

    async getHistoryCount() {
        let historyCnt = await axios.get('/ai/history/count?contentType=heading,article,paragraph')
        this.setState({ historyCnt: historyCnt.data.count })
    }
    async getHistory() {
        let history = await axios.get('/ai/history?contentType=heading,article,paragraph')
        this.setState({ history: history.data, showHistory: true })
    }

    async generateText() {
        if (!this.props.accountMaster.accountMaster.approved) {
            // show questionnaire
            var theDrawer = <NewUserForm from="campaign" closeNewUserForm={() => { }} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, false, "100vw", 'full'));
        } else {
            this.setState({ generatingText: true })
            //check permissions

            try {
                let response = await axios.post('/ai/template/generatetext', {
                    prompt: this.state.text,
                    industry: this.state.industry,
                    contentType: this.state.contentType
                })
                this.setState({ generatingText: false, generatedText: response.data.text, hasGeneratedText: true }, this.getHistoryCount)
            }
            catch (err) {
                let userPermissions = tierPermissions("users", this.props.accountMaster)
                let errorHolder = null;
                let generatingTextError = "";
                if (err && err.data && err.data.error && err.data.error.err) {
                    this.tierError(err.data.error);
                    return;
                }
                if (err && err.data && err.data.error && typeof err.data.error == "string") generatingTextError = err.data.error;
                this.setState({ generatingText: false, generatedText: "", hasGeneratedText: false, generatingTextError: generatingTextError })
            }
        }
    }

    tierError(err) {
        let userPermissions = tierPermissions("users", this.props.accountMaster)
        let errorHolder = <UILIB.TierLimitHolder darkmode={true} type={"AI Lookup"}
            err={err.err}
            allowedNumber={err.allowedNumber}
            tierName={userPermissions.tierName} />
        this.setState({ errorHolder: errorHolder })
    }

    goInsert(generatedText) {
        this.props.insertText(generatedText)
    }

    tryAgain() {
        this.setState({ generatedText: "", hasGeneratedText: false, generatingText: false })
    }
    render() {


        let okToSubmit = this.state.text && this.state.text.length > 10 && this.state.industry && this.state.contentType;
        let wordCount = this.state.text.split(" ").length - 1;
        if (wordCount < 0) wordCount = 0;
        let tooManyWords = wordCount > 500;
        if (tooManyWords) okToSubmit = false;
        let contentTypes = [
            {
                label: i18n.t('templates:aiContentAssistant.contentTypes.subjectLine'),
                value: "subject"
            }, {
                label: i18n.t('templates:aiContentAssistant.contentTypes.heading'),
                value: "heading"
            }, {
                label: i18n.t('templates:aiContentAssistant.contentTypes.paragraph'),
                value: "paragraph"
            }, {
                label: i18n.t('templates:aiContentAssistant.contentTypes.article'),
                value: "article"
            }]

        if (this.props.contentType === 'social post') {
            contentTypes.push({ label: 'Social Post', value: 'social post' })
        }

        if (this.props.contentType === 'sms') {
            contentTypes.push({ label: 'SMS', value: 'sms' })
        }

        let suggestions = [
            i18n.t('templates:aiContentAssistant.suggestions.suggestion1'),
            i18n.t('templates:aiContentAssistant.suggestions.suggestion2'),
            i18n.t('templates:aiContentAssistant.suggestions.suggestion3'),
            i18n.t('templates:aiContentAssistant.suggestions.suggestion4')

        ]



        let header = <div style={{ flex: "0" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center" }} className="mar-b25">
                <UILIB.Button className="button-darkmode-trans" text={i18n.t('templates:aiContentAssistant.close')} iconLeft={<UILIB.Icons icon="cross" color="#FFFFFF" />} onClick={this.props.close} />
                {(!this.state.showHistory && this.state.historyCnt > 0) && <>
                    <UILIB.Button
                        className="button-darkmode-trans"
                        text={i18n.t('templates:aiContentAssistant.history')}
                        iconLeft={<UILIB.Icons icon="history" color="#FFFFFF" />}
                        onClick={this.getHistory}
                        disabled={this.state.generatingText} />
                </>}
                {this.state.showHistory && <>
                    <UILIB.Button
                        className="button-darkmode-trans"
                        text={i18n.t('templates:aiContentAssistant.backToAiContentAssistant')}
                        iconLeft={<UILIB.Icons icon="arrowLeft" color="#FFFFFF" />}
                        onClick={() => { this.setState({ showHistory: false }) }}
                    />
                </>}

            </div>
            {!this.state.showHistory && <h3 className="mar-b25" style={{ display: "flex", alignItems: "center" }}>
                <UILIB.Icons icon="sparkle2" style={{ width: "30px", height: "35px" }} className="mar-r10" />
                <div>{i18n.t('templates:aiContentAssistant.aiContentAssistant')}</div>
                <UILIB.SquareChip className="square-chip-blue mar-l10">{i18n.t('templates:aiContentAssistant.beta')}</UILIB.SquareChip>

            </h3>}
            {this.state.showHistory && <h3 className="mar-b25" style={{ display: "flex", alignItems: "center" }}>
                <UILIB.Icons icon="history" style={{ width: "30px", height: "35px" }} className="mar-r10" />
                <div>{i18n.t('templates:aiContentAssistant.history')}</div>
            </h3>}
        </div >

        let footer = <div style={{ flex: "0" }}>
            {i18n.t('templates:aiContentAssistant.aiFooter')}
        </div>

        let main = <div style={{ flex: "1" }}>

            <div className="mar-b25" style={{ fontSize: "18px", lineHeight: "28px" }}>
                {i18n.t('templates:aiContentAssistant.iWouldLikeToWriteA')}
                <UILIB.Select
                    outerstyle={{ display: "inline-block" }}
                    outerClassName="select-darkmode mar-r10 mar-l10"
                    dropDownClassName="dropdown-darkmode"
                    className="button-darkmode button-xs button-rounded"
                    data={contentTypes}
                    type="text"
                    name="contentType"
                    value={this.state.contentType}
                    onChange={this.updateInput}
                />
                {i18n.t('templates:aiContentAssistant.relatedToThe')}

                <UILIB.Select
                    outerstyle={{ display: "inline-block" }}
                    outerClassName="select-darkmode mar-r10 mar-l10"
                    dropDownClassName="dropdown-darkmode"
                    className="button-darkmode button-xs button-rounded"
                    data={Industries.industryArray()}
                    type="text"
                    name="industry"
                    value={this.state.industry}
                    onChange={this.updateInput}
                />
                {i18n.t('templates:aiContentAssistant.industry')}.
            </div>


            <div style={{ display: "flex", position: "relative", flexDirection: "column", overflow: "hidden", padding: "15px" }} className="mar-b25 border-darkmode-light-to-dark">
                <div style={{ backgroundColor: "black", position: "absolute", right: "30px", top: "5px", fontSize: "10px", zIndex: "9999" }}>
                    <span style={{ color: tooManyWords ? "red" : "" }}>{wordCount}</span><span> / 500</span>
                </div>

                <UILIB.TextArea
                    className="txtInput-darkMode"
                    placeholder={i18n.t('templates:aiContentAssistant.whatDoYouWantToWriteAbout')}
                    style={{ height: "200px", border: "0px", resize: "none" }}
                    value={this.state.text}
                    onChange={this.updateInput}
                    name="text"
                    error={this.state.generatingTextError}
                />


                <UILIB.Button

                    style={{ width: "100%", textAlign: "center", display: "block", background: "none" }}
                    text={this.props.accountMaster.accountMaster.approved ? i18n.t('templates:aiContentAssistant.generateContent') : i18n.t('templates:aiContentAssistant.onlyApprovedAccounts')}
                    disabled={!okToSubmit}
                    onClick={this.generateText}
                    className="button-darkmode  border-darkmode-purple-to-blue-horizontal"
                />
            </div>
            <div className="mar-b25 text-grey">
                {i18n.t('templates:aiContentAssistant.aiContentAssistantNaturalLanguage')}

            </div>
            <div>
                {suggestions.map((suggestion, index) => {
                    return <UILIB.Button
                        key={index}
                        text={suggestion}
                        className="button-darkmode button-xs mar-r10 mar-b10"
                        onClick={() => { this.setState({ text: suggestion }) }}
                        style={{
                            fontWeight: "normal"
                        }} />
                })}

            </div>


        </div >;

        let loading = <div style={{ flex: "1", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <>
                <UILIB.AiLoadingIcon style={{ height: "200px", width: "400px" }} />
                <div>{i18n.t('templates:aiContentAssistant.aiGeneratingContent')}...</div>
            </>
        </div>


        let results = <div style={{ flex: "1", display: "flex", flexDirection: "column" }}>

            <div style={{ flex: "1" }}>
                <div className="mar-b25 border-darkmode-light-to-dark" style={{ display: "flex", flexDirection: "column", padding: "15px" }}>

                    <div className="mar-b15 text-white" style={{ maxHeight: "calc(100vh - 400px)", border: "0", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: this.state.generatedText }}>
                    </div>

                    <UILIB.Button

                        style={{ width: "100%", textAlign: "center", display: "block", background: "none" }}
                        text={i18n.t('templates:aiContentAssistant.insertIntoTemplate')}
                        onClick={() => { this.goInsert(this.state.generatedText) }}
                        className="button-darkmode  border-darkmode-purple-to-blue-horizontal"
                    />
                </div>
            </div>
            <div style={{ flex: "0" }} className="mar-b25">
                <UILIB.Button className="button-darkmode-trans" iconLeft={<UILIB.Icons icon="arrowLeft" color="#FFFFFF" />} text={i18n.t('templates:aiContentAssistant.tryAnotherPrompt')} onClick={this.tryAgain} />
            </div>
        </div>

        let history = <div style={{ flex: "1", overflow: "auto", marginBottom: "15px" }}>
            {this.state.history.map((line, index) => {
                let isLast = index == (this.state.history.length - 1)
                return <div key={"hist_" + index} style={{ display: "flex", flexDirection: "row", marginRight: "15px" }}>
                    <div className="mar-r10" style={{ flex: "0", display: "flex", flexDirection: "column" }}>
                        <div style={{ flex: "0", lineHeight: "10px", marginTop: "5px" }}>■</div>
                        {!isLast && <div style={{ flex: "1", borderLeft: "1px dashed grey", marginLeft: "50%" }}></div>}
                    </div>
                    <div style={{ flex: "1", paddingBottom: "40px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }} className="mar-b10">
                            <div style={{ flex: 1 }}>
                                <div className="text-heavy" style={{ textTransform: "capitalize" }}>"{line.prompt}"</div>
                                <div className="text-grey">
                                    <span style={{ textTransform: "capitalize" }}>{line.contentType}</span> ・ <span style={{ textTransform: "capitalize" }}>{line.industry}</span></div>
                            </div>
                            <div style={{ flex: "0 0 300" }}>
                                <UILIB.Button
                                    text={i18n.t('templates:aiContentAssistant.addToContent')}
                                    className="button-darkmode border-darkmode-purple-to-blue-horizontal"
                                    onClick={() => { this.goInsert(line.result) }}
                                />
                            </div>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: line.result }}>
                        </div>
                    </div>
                </div>

            })
            }

        </div >
        return (
            <>
                <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 50px)" }}>
                    {header}
                    {(this.state.errorHolder) && this.state.errorHolder}
                    {!this.state.errorHolder && !this.state.showHistory && this.state.generatingText && <>{loading}</>}
                    {!this.state.errorHolder && !this.state.showHistory && this.state.hasGeneratedText && <>{results}</>}
                    {!this.state.errorHolder && !this.state.showHistory && !this.state.generatingText && !this.state.hasGeneratedText && <>{main}</>}
                    {!this.state.errorHolder && this.state.showHistory && <>{history}</>}
                    {footer}
                </div>
            </>
        );
    }
}


export default AiContentGenerator;
import React from 'react'

export default function IconIntegrations({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.68391 4.27582C9.86051 4.09921 10.1 4 10.3498 4H15.0583C15.308 4 15.5476 4.09921 15.7242 4.27582C15.9008 4.45242 16 4.69195 16 4.9417V9.6502C16 9.89995 15.9008 10.1395 15.7242 10.3161C15.5476 10.4927 15.308 10.5919 15.0583 10.5919C14.8085 10.5919 14.569 10.4927 14.3924 10.3161C14.2158 10.1395 14.1166 9.89995 14.1166 9.6502V7.21496L8.19047 13.1411C8.10361 13.231 7.99969 13.3028 7.8848 13.3521C7.76991 13.4015 7.64634 13.4274 7.5213 13.4285C7.39627 13.4296 7.27226 13.4058 7.15653 13.3584C7.0408 13.3111 6.93566 13.2412 6.84724 13.1528C6.75882 13.0643 6.68889 12.9592 6.64155 12.8435C6.5942 12.7277 6.57037 12.6037 6.57146 12.4787C6.57254 12.3537 6.59852 12.2301 6.64787 12.1152C6.69723 12.0003 6.76897 11.8964 6.85891 11.8095L12.785 5.8834H10.3498C10.1 5.8834 9.86051 5.78419 9.68391 5.60758C9.50731 5.43098 9.40809 5.19145 9.40809 4.9417C9.40809 4.69195 9.50731 4.45242 9.68391 4.27582ZM4.54394 5.4011C4.89223 5.05282 5.3646 4.85716 5.85714 4.85716H7.71429C7.96056 4.85716 8.19674 4.95499 8.37088 5.12913C8.54503 5.30327 8.64286 5.53946 8.64286 5.78573C8.64286 6.032 8.54503 6.26819 8.37088 6.44233C8.19674 6.61647 7.96056 6.7143 7.71429 6.7143H5.85714V14.1429H13.2857V12.2857C13.2857 12.0395 13.3835 11.8033 13.5577 11.6291C13.7318 11.455 13.968 11.3572 14.2143 11.3572C14.4606 11.3572 14.6967 11.455 14.8709 11.6291C15.045 11.8033 15.1429 12.0395 15.1429 12.2857V14.1429C15.1429 14.6354 14.9472 15.1078 14.5989 15.4561C14.2506 15.8044 13.7783 16 13.2857 16H5.85714C5.3646 16 4.89223 15.8044 4.54394 15.4561C4.19566 15.1078 4 14.6354 4 14.1429V6.7143C4 6.22176 4.19566 5.74939 4.54394 5.4011Z" fill={fillColor} />
        </svg>
    )
}
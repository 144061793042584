import React from 'react'
import FormatNumberFuncs from '../../../Classes/numberFormatFunctions';
import useViewPort from '../../hooks/UseViewport';
import Button from '../../UI/Forms/Button/Button'
import ButtonSimple from '../../UI/Forms/ButtonSimple/ButtonSimple';
import Icons from '../../UI/Icons'

/* PAGING */
export default function PagingBlock(props) {
    const viewPort = useViewPort()
    const globalDisabled = props.disabled;
    const changePage = (e) => {
        const page = parseInt(e.currentTarget.id.replace('pagingLnk_', ''))
        props.changePage(page);
    }

    const generateLinks = () => {
        var pagesText = [];
        var startPage = 0;
        var endPage = 0;

        var currentPage = 0;
        if (props.currentPage) {
            currentPage = Number(props.currentPage);
        }

        var totalPages = Math.floor((Number(props.totalRows) / Number(props.pageSize)));
        if (!(Number(props.totalRows) % Number(props.pageSize))) {
            totalPages -= 1;
        }

        if (props.totalRows == props.pageSize) {
            totalPages = 0;
        }
        var numberOfLinks = 5;
        if (props.numberOfLinks && viewPort > 500) {
            numberOfLinks = Number(props.numberOfLinks);
        }

        if (currentPage > totalPages) {
            currentPage = totalPages;
        }
        if (totalPages >= 1) {
            startPage = currentPage - Math.floor(numberOfLinks / 2);
            endPage = currentPage + Math.floor(numberOfLinks / 2);

            if (startPage < 0) {
                endPage += startPage * -1;
                startPage = 0;
            }
            if ((totalPages - endPage) < 0) {
                startPage -= (totalPages - endPage) * -1;
            }

            if (startPage < 0) {
                startPage = 0;
            }
            if (endPage > totalPages) {
                endPage = totalPages;
            }

        }

        var x = startPage;
        while (x <= endPage) {
            if (x <= totalPages) {
                let classes = "page";
                if (x == currentPage) {
                    classes += " selected";
                }
                pagesText.push(
                    <div className={classes} key={"pagingLnk_" + x} >
                        <ButtonSimple id={"pagingLnk_" + x} onClick={globalDisabled ? ()=>{} : changePage}>{x + 1}</ButtonSimple>
                    </div>
                );
            }
            x++;
        }


        return pagesText
    }

    var pagesText = "";
    if (!props.totalRows || Number(props.totalRows) < 2) {
        return <span></span>
    } else {
        pagesText = generateLinks();
    }

    let totalPages = Math.floor((Number(props.totalRows) / Number(props.pageSize)));
    if (!(Number(props.totalRows) % Number(props.pageSize))) {
        totalPages -= 1;
    }

    if (totalPages === 0) return null

    return (
        <>
            <div className="pagination">
                <Button className="hide-xs" iconLeft={<Icons icon="arrowLeft" />} disabled={props.currentPage === 0 || globalDisabled} onClick={() => props.currentPage > 0 ? props.changePage(props.currentPage - 1) : {}}>Previous</Button>
                <Button className="show-xs" disabled={props.currentPage === 0 || globalDisabled} onClick={() => props.currentPage > 0 ? props.changePage(props.currentPage - 1) : {}}><Icons className="vertical-center" icon="arrowLeft" /></Button>
                <div className="pages">
                    {pagesText}
                </div>
                <Button className="hide-xs" iconRight={<Icons icon="arrowRight" />} disabled={globalDisabled || props.currentPage >= totalPages || (props.limit && (props.currentPage + 1) * props.pageSize >= props.limit)} onClick={() => props.currentPage < totalPages ? props.changePage(props.currentPage + 1) : {}}>Next</Button>
                <Button className="show-xs" disabled={globalDisabled || props.currentPage >= totalPages} onClick={() => props.currentPage < totalPages ? props.changePage(props.currentPage + 1) : {}}><Icons className="vertical-center" icon="arrowRight" /></Button>
            </div>
            {(props.currentPage + 1) * props.pageSize >= props.limit && <div className='mar-t10 text-xsml text-grey text-heavy'>Max {FormatNumberFuncs.formatNumber(props.limit)} returned in this view</div>}
        </>
    )

}
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import SiteVars from '~/Classes/siteVars'


@connect((store) => {
    return { accountMaster: store.accountMaster }
})
class StripeAddCard extends Component {
    constructor(props) {
        super(props);

        let stripe;

        this.state = {
            countries: undefined,
            name: undefined,
            country: '',
            zip: this.props.accountMaster.accountMaster.postCode || '',
            creatingCard: false,
            error: ""
        }

        this.addCard = this.addCard.bind(this);
        this.updateValue = this.updateValue.bind(this);

    }

    componentDidMount() {
        axios.get('/countries').then(res => {
            const countries = res.data.Countries
            let country = 'gb'
            if (this.props.accountMaster.accountMaster.country) {
                country = countries.find(c => c.name.toLowerCase() === this.props.accountMaster.accountMaster.country.toLowerCase())
                if (country) country = country.code
                else country = 'gb'
            }
            this.setState({ countries, country }, () => {
                this.stripe = Stripe(SiteVars.stripeKey);
                var elements = this.stripe.elements();
                this.cardNumber = elements.create('cardNumber', { classes: { base: 'form-group fakeInput mar-b0' }, placeholder: 'Card Number' });
                this.cardNumber.mount(this.refs.cardNumber);
                this.cardExpiry = elements.create('cardExpiry', { classes: { base: 'form-group fakeInput mar-b0' }, placeholder: 'Expiry Date' });
                this.cardExpiry.mount(this.refs.cardExpiry);
                this.cardCvc = elements.create('cardCvc', { classes: { base: 'form-group fakeInput mar-b0' }, placeholder: 'CVC / CVV' });
                this.cardCvc.mount(this.refs.cardCvc);
            })
        })
    }

    updateValue(event) {
        var state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state)
    }
    addCard(ev) {
        ev.preventDefault();
        this.setState({
            creatingCard: true,
            error: ""
        })
        this.stripe.createToken(this.cardNumber, { name: this.state.name, address_country: this.state.country, address_zip: this.state.zip }).then(result => {
            if (result.error) {
                return Promise.reject(result.error.message);
            } else {
                return axios.post('/accountMaster/paymentMethod/stripe', { token: result.token.id })
            }
        }).then((newCard) => {
            return axios.put('/accountMaster/paymentMethod/stripe', {
                default_source: newCard.data.source.id
            })
        }).then(() => {
            this.props.close()
        }).catch(err => {
            console.log(err);
            this.setState({
                error: err.data ? err.data.error || err.data.message || err.data : err,
                creatingCard: false
            })
        })
    }


    render() {
        if (!this.state.countries) return <UILIB.LoadingIcons iconType="2" />
        return <UILIB.Row>
            <UILIB.Column xs={12} className="mar-b15">
                <UILIB.TextInput placeholder="Name on Card" name="name" value={this.state.name} onChange={this.updateValue} className="stripe" />
            </UILIB.Column>
            <UILIB.Column xs={12} className="mar-b15">
                <div ref="cardNumber" id="card-number" className="txtInput"></div>
            </UILIB.Column>
            <UILIB.Column xs={6} className="mar-b15">
                <div ref="cardExpiry" id="card-expiry" className="txtInput"></div>
            </UILIB.Column>
            <UILIB.Column xs={6} className="mar-b15">
                <div ref="cardCvc" id="card-cvc" className="txtInput"></div>
            </UILIB.Column>
            <UILIB.Column xs={6} className="mar-b15">
                <UILIB.TextInput value={this.state.zip} onChange={this.updateValue} name="zip" placeholder={this.state.country === 'GB' ? 'Post Code' : 'Zip'} />
            </UILIB.Column>
            <UILIB.Column xs={6} className="mar-b15">
                <UILIB.Select style={{ paddingBottom: 6 }} data={this.state.countries.map(c => ({ label: c.name, value: c.code }))} placeholder="Country" value={this.state.country} onChange={this.updateValue} name="country" />
            </UILIB.Column>
            <UILIB.Column xs={12} sm={12} md={12} lg={12} className="mar-b15 quickFlex">
                <UILIB.Button className="button-primary" text="Add Card" onClick={this.addCard} disabled={this.state.creatingCard} iconLeft={<UILIB.Icons icon="plus" />} />
                {this.props.hasCloseButton && <UILIB.Button className="mar-l10" text="Cancel" onClick={this.props.close} />}
            </UILIB.Column>
            {this.state.error && this.state.error.length > 0 && <UILIB.Column xs={12} className="mar-b15 center-xs">
                <div className="text-red">{this.state.error}</div>
            </UILIB.Column>}
            <UILIB.Column xs={12} className="mar-b15">
                <div className="text-center text-sml">
                    Secure Payments by <a href="https://stripe.com" target="_blank">Stripe</a>
                    <div>
                        <a href="https://stripe.com" target="_blank"><img src="https://cdn1.ourmailsender.com/siteContent/assets/misc/stripePartner.png" style={{ border: "0px" }} /></a>
                    </div>
                </div>
            </UILIB.Column>

        </UILIB.Row>
    }
}

export default StripeAddCard;
import React from "react";

export default function IconPerson({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4001 8.0001C14.4001 9.69748 13.7258 11.3253 12.5256 12.5256C11.3253 13.7258 9.69748 14.4001 8.0001 14.4001C6.30271 14.4001 4.67485 13.7258 3.47461 12.5256C2.27438 11.3253 1.6001 9.69748 1.6001 8.0001C1.6001 6.30271 2.27438 4.67485 3.47461 3.47461C4.67485 2.27438 6.30271 1.6001 8.0001 1.6001C9.69748 1.6001 11.3253 2.27438 12.5256 3.47461C13.7258 4.67485 14.4001 6.30271 14.4001 8.0001ZM10.0001 6.0001C10.0001 6.53053 9.78938 7.03924 9.41431 7.41431C9.03924 7.78938 8.53053 8.0001 8.0001 8.0001C7.46966 8.0001 6.96096 7.78938 6.58588 7.41431C6.21081 7.03924 6.0001 6.53053 6.0001 6.0001C6.0001 5.46966 6.21081 4.96096 6.58588 4.58588C6.96096 4.21081 7.46966 4.0001 8.0001 4.0001C8.53053 4.0001 9.03924 4.21081 9.41431 4.58588C9.78938 4.96096 10.0001 5.46966 10.0001 6.0001ZM8.0001 9.6001C7.25643 9.59941 6.52282 9.77182 5.8573 10.1037C5.19179 10.4355 4.61264 10.9177 4.1657 11.5121C4.65229 12.0447 5.24464 12.4698 5.90487 12.7605C6.56511 13.0511 7.27872 13.2009 8.0001 13.2001C8.72148 13.2009 9.43509 13.0511 10.0953 12.7605C10.7556 12.4698 11.3479 12.0447 11.8345 11.5121C11.3876 10.9177 10.8084 10.4355 10.1429 10.1037C9.47738 9.77182 8.74376 9.59941 8.0001 9.6001Z"
        fill={color} />

    </svg>
  );
}


import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';
class DeleteApiKey extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
    }

    render() {

        return (
            <div>
                <h4 className="mar-b25">{i18n.t('company:api.deleteHeader')}</h4>
                <div className="mar-b25">{i18n.t('company:api.deleteSubHeader')}</div>

                <UILIB.Button
                    className="button-primary"
                    text={i18n.t('company:api.delete')}
                    onClick={this.props.confirm} />
            </div >
        );
    }
}

export default DeleteApiKey;
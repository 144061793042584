// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RsPEQQiLZ2jYQUDOv0t4{display:flex;align-items:center;gap:16px}.RsPEQQiLZ2jYQUDOv0t4 form{flex:1}.pZ4rIQXYkB0e2f2xYPlx{display:flex;align-items:center;justify-content:flex-end;gap:12px}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/aiAssistant/sources/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,2BACE,MAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,QAAA","sourcesContent":[".topRow {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n\n  form {\n    flex: 1;\n  }\n}\n\n.tableActions {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topRow": "RsPEQQiLZ2jYQUDOv0t4",
	"tableActions": "pZ4rIQXYkB0e2f2xYPlx"
};
export default ___CSS_LOADER_EXPORT___;

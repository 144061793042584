import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import moment from 'moment'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';

@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})


class MailServerSelector extends Component {
    constructor(props) {
        super(props);
        this.setMailServerGroup = this.setMailServerGroup.bind(this);
        this.setMailServers = this.setMailServers.bind(this);
    }

    setMailServerGroup(event) {
        var mgId = event.target.name;
        var checked = event.target.checked;
        var mailServerGroup = this.props.mailserverGroups.find(mg => mg.id == mgId);
        var mailServers = [];
        if (mailServerGroup.liveMailServers && mailServerGroup.liveMailServers.length) {
            mailServers = mailServerGroup.liveMailServers;
        }
        else {
            mailServers = mailServerGroup.mailServers;
        }

        if (!this.props.campaign.options) this.props.campaign.options = {}
        var existing = this.props.campaign.options.MailServers || [];

        mailServers.forEach(ms => {
            var found = existing.find(ex => ex == ms)
            if (!found && checked) {
                existing.push(ms)
            }
            if (found && !checked) {
                var remove = existing.indexOf(ms)
                existing.splice(remove, 1)
            }
        })
        this.props.campaign.options.MailServers = existing;
        this.props.updateCampaign();
    }

    setMailServers(ev) {
        var id = parseInt(ev.currentTarget.name)
        if (!this.props.campaign.options) this.props.campaign.options = {}
        var mailservers = this.props.campaign.options.MailServers || []
        if (ev.currentTarget.checked === true) {
            mailservers.push(id)
        } else {
            var index = mailservers.indexOf(id)
            if (index > -1) mailservers.splice(index, 1)
        }
        this.props.campaign.options.MailServers = mailservers
        this.props.updateCampaign();
    }

    render() {
        if (!this.props.accountMaster.accountMaster.options.allowMailServerSelection) return <div></div>

        let mailServerGroups = this.props.mailserverGroups;
        if (mailServerGroups && mailServerGroups.length) {
            let validGroups = mailServerGroups.filter(g => g.triggerType != 1)
            if (!validGroups || !validGroups.length) {
                return <div></div>
            }
            mailServerGroups = this.props.mailserverGroups.filter(g => g.triggerType != 1)
        }

        var mailserverText = i18n.t('campaigns:standard.step3.allAvailableMailServers')
        if (!this.props.mailservers.length) mailserverText = i18n.t('campaigns:standard.step3.loading') + '...'
        if (!this.props.campaign.options) this.props.campaign.options = {}
        else if (this.props.campaign.options.MailServers && this.props.campaign.options.MailServers.length) {
            mailserverText = this.props.campaign.options.MailServers.map(id => {
                var server = this.props.mailservers.find(ms => ms.id == id)
                if (server) {
                    return server.name
                }
            }).filter(ms => !!ms).join(', ')
        }


        return <div>

            <UILIB.ButtonSelect
                label={i18n.t('campaigns:standard.step3.mailservers')}
                headerText={mailserverText}
                name="MailServers"
                inputType="text"
                outerClassName="mar-b25"
            >

                {Boolean(mailServerGroups.length) && <UILIB.Paper className="mar-b25">
                    <h4 className="mar-b25">Server Groups</h4>
                    {mailServerGroups.filter(mg => mg.mailServers.length).map((mg, mgIndex) => {
                        var isChecked = false;

                        if (this.props.campaign.options.MailServers) {
                            var foundAll = true;

                            if (mg.liveMailServers && mg.liveMailServers.length) {
                                mg.liveMailServers.forEach(ms => {
                                    if (this.props.campaign.options.MailServers && this.props.campaign.options.MailServers.indexOf(ms) < 0) {
                                        foundAll = false;
                                    }
                                });
                            }
                            else {
                                mg.mailServers.forEach(ms => {
                                    if (this.props.campaign.options.MailServers && this.props.campaign.options.MailServers.indexOf(ms) < 0) {
                                        foundAll = false;
                                    }
                                })
                            }
                            if (foundAll) isChecked = true;
                        }
                        return <div key={mg.id} className="quickFlex mar-b10">
                            <div>
                                <UILIB.CheckBox checked={isChecked} name={mg.id} onChange={this.setMailServerGroup} />
                            </div>
                            <div>
                                {mg.name}
                            </div>
                        </div>
                    })}
                </UILIB.Paper>}

                <UILIB.Paper>
                    <h4 className="mar-b25">Dedicated Mail Servers</h4>
                    <UILIB.Row className="text-sml">
                        <UILIB.Column xs={1}></UILIB.Column>
                        <UILIB.Column xs={7}>{i18n.t('campaigns:standard.step3.mailServer')}</UILIB.Column>
                        <UILIB.Column xs={2}>{i18n.t('campaigns:standard.step3.age')}</UILIB.Column>
                        <UILIB.Column xs={2} className="center-xs">Status</UILIB.Column>
                    </UILIB.Row>
                    {this.props.mailservers.map((ms, msIndex) => {
                        var status = 'Good'
                        var statusColour = 'green'
                        if (ms.score > 20) {
                            status = 'Ok'
                            statusColour = 'yellow'
                        }
                        if (ms.score > 70) {
                            status = 'Bad'
                            statusColour = 'red'
                        }

                        var age = moment().diff(moment(ms.warmupStarted), 'days')
                        if (ms.maxWarmupDays > age)
                            age = ms.maxWarmupDays
                        var isChecked = false;
                        if (this.props.campaign.options.MailServers) {
                            if (this.props.campaign.options.MailServers.indexOf(ms.id) > -1) {
                                isChecked = true;
                            }
                        }
                        return <UILIB.Row key={ms.id} className="text-sml">
                            <UILIB.Column xs={1}>
                                <UILIB.CheckBox key={msIndex} checked={isChecked} name={ms.id} onChange={this.setMailServers} />
                            </UILIB.Column>
                            <UILIB.Column xs={7}>
                                <div>{ms.name}</div>
                                <div>{ms.host}</div>
                                {ms.notes && <div className="text-sml">{ms.notes}</div>}
                            </UILIB.Column>
                            <UILIB.Column xs={2}>{age} days</UILIB.Column>
                            <UILIB.Column xs={2} className="center-xs" style={{ color: statusColour }}>{status}</UILIB.Column>
                        </UILIB.Row>
                    })}
                </UILIB.Paper>

            </UILIB.ButtonSelect>

        </div>
    }
}

export default MailServerSelector;
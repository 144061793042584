import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import helpers from '~/Classes/helpers'
import NumberFormatFunctions from '~/Classes/numberFormatFunctions';
import EditLink from './editLinks'
import GenericStatsHolder from '../../shared/components/genericStatsHolder';
import InDepthChart from '../../shared/graphs/indepthChart'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

export default class CampaignsViewClicked extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignId: 0,
            pageLoaded: false,
            tableData: {},
            loadingData: 1,
            sortColumn: "link",
            sortDirection: "asc",
            searchText: "",
            pageSize: 50,
            totalRows: 0,
            currentPage: 0,
            startRecord: 0,
            startDate: null,
            lastName: null,
            rawData: [],
            stats: {}
        }
        this.loadStats = this.loadStats.bind(this);
        this.tableUpdater = this.tableUpdater.bind(this);
        this.iframeClick = this.iframeClick.bind(this);
        this.loadCampaignStats = this.loadCampaignStats.bind(this);
        this.editLink = this.editLink.bind(this)
    }

    componentDidMount() {
        helpers.createKey(0, 0, 0, this.props.campaignId).then(key => {
            this.setState({ campaignId: this.props.campaignId, key }, this.loadStats)
        })
    }

    componentDidUpdate(prevProps) {

        var update = false;
        var obj = {};
        if (this.state.startDate != this.props.startDate) {
            obj.startDate = this.props.startDate;
            update = true;
        }
        if (this.state.endDate != this.props.endDate) {
            obj.endDate = this.props.endDate;
            update = true;
        }
        if (this.props.showBots !== prevProps.showBots) update = true
        if (this.props.campaignId !== prevProps.campaignId) {
            obj.campaignId = this.props.campaignId;
            update = true;
        }
        if (update) {
            this.setState(obj, this.loadStats)
        }
    }

    loadStats(sortColumn, sortDirection, startRecord, pageSize, searchText) {
        this.setState({ loadingData: 1 });

        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!startRecord) {
            startRecord = this.state.startRecord;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var queries = []
        if (this.props.startDate) {
            queries.push('startDate=' + this.props.startDate.format('YYYY-MM-DD'))
        }
        if (this.props.endDate) {
            queries.push('endDate=' + this.props.endDate.format('YYYY-MM-DD 23:59:59'))
        }
        if (this.props.showBots) queries.push('bots=true')

        queries.push('sortColumn=' + sortColumn)
        queries.push('sortOrder=' + sortDirection)
        var query = queries.length ? '?' + queries.join('&') : '';

        var self = this;
        axios
            .get('/campaign/' + self.state.campaignId + '/stats/clicked' + query)
            .then((res) => {
                //sort the data
                var finalData = res.data.TemplateLinks.sort((a, b) => {
                    if (sortDirection === "asc") {
                        return b[sortColumn] < a[sortColumn]
                    }
                    else {
                        return b[sortColumn] > a[sortColumn]
                    }
                });
                var theTableData = finalData
                    .map(thisLine => {

                        return {

                            link: {
                                headerValue: i18n.t('campaigns:overview.linkUrl'),
                                value: <a onClick={() => {
                                    this.props.history.push('/cp/campaigns/view/' + this.state.campaignId + '/clicked/' + thisLine.id)
                                }}>{thisLine.link}</a>,
                                orderBy: true,
                                cellStyle: {
                                    wordBreak: 'break-all',
                                    whiteSpace: 'break-spaces'
                                }
                            },
                            Unique: {
                                headerValue: i18n.t('campaigns:overview.subscribers'),
                                value: <span>{thisLine.Unique}</span>,
                                orderBy: true

                            },
                            Total: {
                                headerValue: i18n.t('campaigns:overview.totalClicks'),
                                value: <span>{thisLine.Total}</span>,
                                orderBy: true
                            },
                            optionsCol: {
                                headerValue: " ",
                                value: <UILIB.OptionsDropdown popWidth="150px">
                                    <ul>
                                        <li>
                                            <a onClick={() => this.props.history.push('/cp/campaigns/view/' + this.state.campaignId + '/clicked/' + thisLine.id)}>{i18n.t('view')}</a>
                                        </li>

                                        <li>
                                            <a onClick={() => this.editLink(thisLine)}>Edit Link</a>
                                        </li>
                                    </ul>
                                </UILIB.OptionsDropdown>,
                                orderBy: false,
                                fixed: true,
                                width: 20
                            }
                        }
                    })
                self.setState({ tableData: theTableData, rawData: res.data.TemplateLinks, loadingData: 0, totalRows: res.data.countTotal }, this.loadCampaignStats);
            });
    }

    editLink(link) {
        const drawer = <EditLink link={link} TemplateId={this.props.campaign.TemplateId} CampaignId={this.state.campaignId} onFinish={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false));
            this.loadStats();
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, true, "500px"));
    }

    loadCampaignStats() {

        //loop campaign table stats and grab number of unique links clicked
        var linksClicked = 0;
        this.state.rawData.forEach(theLink => {
            if (Number(theLink.Unique) > 0) {
                linksClicked++;
            }
        })

        var self = this;
        var queryStr = '?ignoreLog=1'
        if (this.props.showBots) {
            queryStr += '&bots=true'
        }
        if (this.props.startDate) {
            queryStr += '&startDate=' + this.props.startDate.format('YYYY-MM-DD')
        }
        if (this.props.endDate) {
            queryStr += '&endDate=' + this.props.endDate.format('YYYY-MM-DD 23:59:59')
        }
        axios.get('/campaign/' + self.state.campaignId + '/stats' + queryStr)
            .then((res) => {
                var theStats = res.data.Stats;
                theStats["Total Links Clicked"] = linksClicked;
                this.setState({
                    stats: theStats,
                    pageLoaded: true
                });

            })
            .catch(ex => {
                console.log(ex)
            })
    }

    tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {
        var thisState = {};
        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!currentPage && currentPage != "0") {
            currentPage = this.state.currentPage;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var startRecord = currentPage * pageSize;

        this.setState({ sortColumn: sortColumn, sortDirection: sortDirection, pageSize: pageSize, currentPage: currentPage, searchText: searchText, startRecord: startRecord }, this.loadStats);

    }

    iframeClick(event) {

        var self = this;
        document.getElementById("theIframe").contentWindow.document.body.onclick = function (event) {
            event.preventDefault();
            if (event.target.dataset.link) {
                self.props.history.push('/cp/campaigns/view/' + self.state.campaignId + '/clicked/' + event.target.dataset.link)
            }
        }
    }

    render() {

        var colSizes = 6;
        if (this.props.campaign.type === 6) colSizes = 12

        return <div>
            <UILIB.Row>
                <UILIB.Column xs={12}>
                    <UILIB.Paper className="mar-b25">
                        <InDepthChart showBots={this.props.showBots} title={i18n.t('campaigns:overview.clicksTimeline')}
                            type="clicked" campaignId={this.props.campaignId} startDate={this.props.startDate} endDate={this.props.endDate}
                            seatchText={this.props.searchText} changedDate={this.props.changedDate} />
                    </UILIB.Paper>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={colSizes}>
                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={12} md={4} margin={0} className="mar-b25">
                            <GenericStatsHolder value={this.state.stats['Unique Clicks']} limit={this.state.stats["Unique Opens"]} header={i18n.t('campaigns:overview.subscribersClicked')} link={null} className="full-height" />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={12} md={4} margin={0} className="mar-b25">
                            <GenericStatsHolder value={this.state.stats['Total Links Clicked']} limit={this.state.tableData.length} header={i18n.t('campaigns:overview.linksClickedHeader')} link={null} className="full-height" />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={12} md={4} margin={0} className="mar-b25">
                            <GenericStatsHolder value={this.state.stats['Total Clicks']} limit={this.state.stats['Total Opens']} header={i18n.t('campaigns:overview.totalClicks')} link={null} className="full-height" />
                        </UILIB.Column>

                        <UILIB.Column xs={12}>
                            <UILIB.Paper>

                                <UILIB.DataTable1 noResultsTxt={<span>{i18n.t('campaigns:overview.noResults')}</span>} tableData={this.state.tableData} loadingData={this.state.loadingData}
                                    dataUpdater={this.tableUpdater} width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn={this.state.sortColumn}
                                    sortedDirection={this.state.sortDirection}
                                />
                            </UILIB.Paper>
                        </UILIB.Column>
                    </UILIB.Row>
                </UILIB.Column>

                {this.props.campaign.type !== 6 && <UILIB.Column xs={12} sm={colSizes}>
                    <UILIB.Paper>

                        <iframe frameBorder="0" src={"/preview?key=" + this.state.key + "&showClicks=true" + (this.props.showBots ? '&bots=true' : '')} style={{ width: "100%", overflow: "auto", height: "100vh" }} ref="theIframe" id="theIframe" onLoad={this.iframeClick} />
                    </UILIB.Paper>
                </UILIB.Column>}
            </UILIB.Row >

        </div >
    }
}
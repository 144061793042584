import { useEffect, useState } from "react"

export default function useInnerHeight() {
    const [height, setHeight] = useState(window.innerHeight)

    const handleResize = () => {
        setHeight(window.innerHeight)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return function () {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return height
}
import React from 'react'

export default class IconsBranchDown extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.12675 16.0383C5.51711 16.429 6.15027 16.4293 6.54097 16.0389C6.93166 15.6486 6.93194 15.0154 6.54158 14.6247L4.08308 12.1641L6.66891 12.1652C7.81818 12.1657 8.92058 11.7096 9.73359 10.8973C10.5466 10.085 11.0036 8.98301 11.0041 7.83374L11.0067 2.00041C11.0069 1.44813 10.5594 1.00022 10.0071 0.999975C9.45484 0.999734 9.00692 1.44725 9.00668 1.99954L9.00413 7.83287C9.00386 8.45171 8.75777 9.04509 8.31999 9.48248C7.88222 9.91988 7.28862 10.1655 6.66978 10.1652L4.084 10.1641L6.54461 7.70559C6.9353 7.31523 6.93558 6.68207 6.54523 6.29137C6.15487 5.90068 5.52171 5.9004 5.13101 6.29076L0.962528 10.4556C0.862794 10.5552 0.788502 10.6707 0.739655 10.7939C0.701596 10.8897 0.677915 10.9928 0.671272 11.1005C0.668635 11.1428 0.668678 11.1853 0.671403 11.2277C0.677765 11.3273 0.69873 11.423 0.732188 11.5127C0.780691 11.6429 0.857265 11.7651 0.961909 11.8698L5.12675 16.0383Z" fill={color} />
            <path d="M13.4559 18.0419C13.0656 17.6512 13.0658 17.0181 13.4565 16.6277L15.9172 14.1692L13.3314 14.1681C12.1821 14.1676 11.0801 13.7106 10.2678 12.8976C10.1498 12.7795 10.0394 12.6553 9.93672 12.5257C10.2236 12.3497 10.4912 12.1395 10.7332 11.8978C10.9778 11.6533 11.1903 11.3826 11.3677 11.0922C11.4576 11.2327 11.5629 11.3642 11.6826 11.484C12.12 11.9217 12.7134 12.1678 13.3323 12.1681L15.918 12.1692L13.4596 9.70861C13.0692 9.31792 13.0695 8.68475 13.4602 8.2944C13.8509 7.90404 14.484 7.90432 14.8744 8.29502L19.0353 12.4596C19.2185 12.6409 19.3319 12.8926 19.3318 13.1707C19.3317 13.4217 19.2392 13.651 19.0864 13.8265C19.0711 13.8441 19.0552 13.8611 19.0386 13.8777L14.8701 18.0426C14.4794 18.4329 13.8463 18.4326 13.4559 18.0419Z" fill={color} />
        </svg>



    }
}        
import React from "react"
import { useHistory } from 'react-router-dom'
import UILIB from '~/Common-Objects/Lib';

export default function TemplateCampaignChecker({ campaigns, close = () => { } }) {
    const history = useHistory()

    if (!campaigns || !campaigns.length) return null
    return (
        <div className="modal">
            <div className="content" >
                <div className="modalClose" onClick={close}><span className="icon-cross2"></span></div>
                <p className="text-center text-heavy text-primary">
                    We have detected this Template in use on Campaigns<br />
                    Editing this Template won't update the content of those Campaigns<br />
                    You have to edit them directly:
                </p>
                {campaigns.slice(0, 5).map(c => {
                    let classes = 'grey'
                    switch (c.status) {
                        case 'Awaiting Start':
                            classes = 'blue'
                            break;
                        case 'Queued':
                        case 'Processing':
                        case 'Sending':
                            classes = 'purple'
                            break;
                        case 'Paused':
                        case 'UnPausing':
                            classes = 'yellow';
                            break;
                        case 'Draft':
                            classes = 'grey';
                            break;
                    }
                    return <div key={c.id} className="modalCampaign">
                        <div className="campaignName text-sml text-heavy mar-r10">
                            {c.Automation ? c.Automation.name : c.campaignName}
                        </div>
                        <div className="flexSpaceBetween verticalAlign" style={{ minWidth: 205 }}>
                            <UILIB.SquareChip className={classes + ' mar-b0 mar-r10 small'}>{c.status}</UILIB.SquareChip>
                            <UILIB.Button className="button-sml full-width outline primary" text={c.AutomationId ? 'View Automation' : c.status === 'Draft' ? 'Edit Campaign' : 'View Campaigns'}
                                onClick={() => history.push(c.AutomationId ? '/cp/automation/addnew/' + c.AutomationId : c.status === 'Draft' ? '/cp/campaigns/add/standard/' + c.id + '/1' : '/cp/campaigns')}
                            />
                        </div>
                    </div>
                })}
                {campaigns.length > 5 && <div className="text-heavy text-primary mar-t10">
                    ...and {campaigns.length - 5} more
                </div>}
                <div className="row mar-t20">
                    <div className="col-xs-12 center-xs">
                        <UILIB.Button className="primary button-sml" text="Got It" onClick={close} />
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import axios from '../../../../../../data/http/axios'
import UILIB from '../../../../../../Common-Objects/Lib'
import DateTimeFunctions from '../../../../../../Classes/dateTimeFunctions'
import { SupportedApps } from '../../../social/create/shared'
import i18n from '../../../../../../i18n'

export default function SocialCamapaignClicks({ campaign, apps = [], posts = [] }) {
    const [clicks, setClicks] = useState(null)
    const [total, setTotal] = useState(null)
    const [page, setPage] = useState(0)
    const [showAnon, setShowAnon] = useState(true)
    const [searchText, setSearchText] = useState('')
    const history = useHistory()
    const timer = useRef(null)

    const getClicks = async (text) => {
        let q = `offset=${page * 30}&limit=30`
        if (!showAnon) q += '&known=true'
        if (text && text.length > 3) q += `&search=${text}`
        const res = await axios.get(`/campaign/${campaign.id}/stats/clickedSummary?${q}`)
        setTotal(res.data.countTotal)
        setClicks(res.data.LinksClicked)
    }

    const changeSearchText = e => {
        const value = e.target.value
        setSearchText(value)
        setPage(0)
        if (timer.current) clearTimeout(timer.current)
        timer.current = setTimeout(() => getClicks(value), 300)
    }

    useEffect(() => {
        getClicks()
    }, [page])

    useEffect(() => {
        setPage(0)
        getClicks()
    }, [showAnon])

    useEffect(() => {
        return function () {
            if (timer.current) clearTimeout(timer.current)
        }
    }, [])

    if (!clicks || total === null) return <UILIB.LoadingIcons />

    const data = clicks.map(row => {
        const post = posts.find(p => row.Campaign?.options?.SocialAccountId && p.SocialAccountId === row.Campaign?.options?.SocialAccountId)
        const app = SupportedApps.find(a => a.code === row.Campaign?.options?.AppCode)
        let source = app ? <div className="quickFlex" style={{ alignItems: 'center' }}><UILIB.Avatar style={{ height: 30, width: 30, marginRight: 10 }} src={app.logo} />{i18n.t('social:channels.' + app.code)}</div> : row.Campaign?.options?.AppCode
        if (post) {
            source = <div className="quickFlex" style={{ alignItems: 'center' }}><UILIB.Avatar style={{ height: 30, width: 30, marginRight: 10 }} src={app.logo} />{post.SocialAccount?.name}</div>
        }
        return {
            date: {
                headerValue: i18n.t('social:view.clicks.date'),
                value: DateTimeFunctions.formatDateTime(row.updatedAt),
            },
            source: {
                headerValue: i18n.t('social:view.clicks.source'),
                value: source
            },
            device: {
                headerValue: i18n.t('social:view.clicks.device'),
                value: row.device || 'Unknown'
            },
            subscriber: {
                headerValue: i18n.t('social:view.clicks.contact'),
                value: row.emailAddress ? <a onClick={() => history.push('/cp/subscriber/' + row.subscriberId)}>{row.emailAddress}</a> : i18n.t('social:view.clicks.anon')
            },
        }
    })

    return (
        <>
            <div className="row" style={{ alignItems: 'center' }}>
                <div className="col-xs-12 col-sm-6 col-md col-lg">
                    <UILIB.TextInput type="email" placeholder={i18n.t('social:view.clicks.search')} value={searchText} onChange={changeSearchText} onEnterPress={() => getClicks(searchText)} />
                </div>
                <div className="col-xs-12 col-sm-6 col-md col-lg">
                    <UILIB.Toggle after={i18n.t('social:view.clicks.showAnon')} checked={showAnon} onChange={e => setShowAnon(e.target.checked)} />
                </div>
            </div>
            {!clicks.length && <UILIB.WarningHolder className="mar-t20">{i18n.t('social:view.clicks.none')}</UILIB.WarningHolder>}
            {!!clicks.length && <>
                <UILIB.DataTable1
                    tableData={data}
                />
                <UILIB.PagingBlock totalRows={total} pageSize={30} currentPage={page} changePage={p => setPage(p)} />
            </>}
        </>
    )
}
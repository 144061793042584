import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UILIB from '../../../../Common-Objects/Lib'
import axios from '../../../../data/http/axios'
import helpers from '../../../../Classes/helpers'
import { alterSiteDrawer } from '../../../../data/actions/siteActions'

const options = [
    { label: 'When the email is sent', value: "15" },
    { label: 'When the contact opens the email', value: "8" },
    { label: 'When the contact clicks on a link', value: "9" },
    { label: 'If the contact unsubscribes', value: "13" },
    { label: 'If the email bounces', value: "12" }
]

export default function CampaignAutomate({ CampaignId, campaignName, save = () => { } }) {
    const site = useSelector(state => state.siteMaster)
    const history = useHistory()
    const dispatch = useDispatch()
    const [trigger, setTrigger] = useState('')
    const [name, setName] = useState(campaignName + ' Automation')

    const create = async () => {
        await save()
        const res = await axios.post('/automation', {
            name,
            version: 1,
            status: 'Draft',
            steps: [{
                "guid": helpers.createLocalGuid(),
                "type": "initial",
                "complete": true,
                "parentGuid": 0,
                "stepOptions": { "groupId": { "value": "-1", "required": true }, "campaignId": { "value": CampaignId, "required": true } },
                "triggerType": { "value": trigger, "required": true },
                "wizardComplete": true
            }],
            options: {
                source: 'Campaign Wizard'
            }
        })
        history.push('/cp/automation/addnew/' + res.data.Automation.id + '?redirect=/cp/campaigns/add/standard/' + CampaignId + '/3')
        dispatch(alterSiteDrawer(false, true, 'right', null))
    }

    return (
        <div>
            <div className="quickFlex mar-b10" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <h4>Create an new Automation</h4>
                {site.siteId === 1 && <UILIB.Hint href="https://kb.transpond.io/article/61-what-is-automation" iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>Learn More</UILIB.Hint>}
            </div>

            <p className='mar-b25 text-sml'>
                You can start a new automation based on activity on this Campaign. <br /><br />Then perform lots of actions such as adding a tag to a contact when they open the email to show they are engaged or send a follow-up email a few days later if they click on a specific link.
            </p>

            <UILIB.Select outerClassName="mar-b25" label="What would you like to trigger an automation?" data={options} value={trigger} onChange={e => setTrigger(e.target.value)} />

            {!!trigger && <UILIB.TextInput label="Give this new Automation a name" value={name} onChange={e => setName(e.target.value)} outerClassName="mar-b25" />}

            {!!trigger && !!name && <>
                <UILIB.WarningHolder className="mar-b25">This will create the automation and take you to the automation designer so you can setup any actions you want to happen when this automation is triggered</UILIB.WarningHolder>
                <UILIB.Button className="button-primary" onClick={create}>Create Automation</UILIB.Button>
            </>}
        </div>
    )
}
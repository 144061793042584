import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UILIB from '~/Common-Objects/Lib.jsx';
import axios from '~/data/http/axios';
import * as siteMaster from '~/data/actions/siteActions';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions';
import i18n from '~/i18n'
import SiteVars from '~/Classes/siteVars';
import DuplicateDrawer from '../groups/group/forms/duplicateDrawer'
import FormDelete from '../groups/group/forms/delete'
import PermissionChecker from '~/Classes/permissions';
import GroupSelect from './GroupSelect';
import axios2 from 'axios'
import ToggleDisabled from './disable';

@connect((store) => {
    return { permissionStore: store.permission, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})
class Forms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forms: [],
            loading: true,
            firstLoadFormCount: 0
        }

        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();

        this.getForms = this.getForms.bind(this)
        this.edit = this.edit.bind(this)
        this.view = this.view.bind(this)
        this.duplicate = this.duplicate.bind(this)
        this.wizard = this.wizard.bind(this)
        this.delete = this.delete.bind(this)
        this.addForm = this.addForm.bind(this)
        this.changeGroup = this.changeGroup.bind(this)
        this.disable = this.disable.bind(this)
    }

    componentDidMount() {
        this.getForms();
    }

    componentWillUnmount() {
        this.source.cancel('I cancelled this')
        this.source = this.CancelToken.source();
    }

    edit(row, returnUrl) {
        if (returnUrl) return '/cp/groups/' + row.GroupId + '/forms/edit/' + row.id
        this.props.history.push('/cp/groups/' + row.GroupId + '/forms/edit/' + row.id);
    }

    view(row, returnUrl) {
        if (returnUrl) return '/cp/groups/' + row.GroupId + '/forms/view/' + row.id
        this.props.history.push('/cp/groups/' + row.GroupId + '/forms/view/' + row.id);
    }

    duplicate(row) {
        var drawerContent = <DuplicateDrawer form={row} groupId={row.GroupId} finish={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
            this.getForms()
        }} cancel={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    wizard(row, returnUrl) {
        if (row.wizard && row.wizard.step1) {
            var redirect = '/cp/groups/' + row.GroupId + '/forms/create/' + row.id + '/2'
            if (returnUrl) return '/cp/templates/add/dragdrop?form=true&groupId=' + row.GroupId + '&formId=' + row.id + '&redirect=' + redirect
            this.props.history.push('/cp/templates/add/dragdrop?form=true&groupId=' + row.GroupId + '&formId=' + row.id + '&redirect=' + redirect);
        } else {
            if (returnUrl) return '/cp/groups/' + row.GroupId + '/forms/create/' + row.id
            this.props.history.push('/cp/groups/' + row.GroupId + '/forms/create/' + row.id);
        }
    }

    delete(row) {
        var drawerContent = <FormDelete formId={row.id} groupId={row.GroupId} onClose={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
            this.getForms()
        }} cancel={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    addForm() {
        var drawerContent = <GroupSelect history={this.props.history} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
        // show drawer asking for the group to create it for
    }

    changeGroup(form) {
        var drawerContent = <GroupSelect history={this.props.history} onSelect={group => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false));
            axios.put('/group/' + form.GroupId + '/form/' + form.id, {
                GroupId: group.id
            }).then(this.getForms)
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    getForms() {
        var canEdit = PermissionChecker('forms', this.props.permissionStore.permissions, "write");
        var canReadSubs = PermissionChecker('subscribers', this.props.permissionStore.permissions, "read");
        axios.get('/group/0/form', {
            cancelToken: this.source.token
        }).then(response => {

            let firstLoadFormCount = this.state.firstLoadFormCount;
            if (this.state.loading) {
                firstLoadFormCount = response.data.Forms.length
            }
            this.setState({
                forms: response.data.Forms.map((row, index) => {

                    var formChip = "";
                    switch (row.formType) {
                        case 0:
                            formChip = <UILIB.SquareChip className="square-chip-purple">{i18n.t('form:index.hosted')}</UILIB.SquareChip>
                            break;
                        case 1:
                            formChip = <UILIB.SquareChip className="square-chip-orange">{i18n.t('form:index.embed')}</UILIB.SquareChip>
                            break;
                        case 2:
                            formChip = <UILIB.SquareChip className="square-chip-red">{i18n.t('form:index.popup')}</UILIB.SquareChip>
                            break;
                        case 3:
                            formChip = <UILIB.SquareChip className="square-chip-blue">HTML</UILIB.SquareChip>
                            break;
                    }

                    let statusChip = <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-grey">{i18n.t('campaigns:status.Draft')}</UILIB.SquareChip>
                    if (row.status) statusChip = <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-green">Running</UILIB.SquareChip>
                    if (row.disabled) statusChip = <UILIB.SquareChip className="square-chip-grey">{i18n.t('form:index.disabled')}</UILIB.SquareChip>

                    let app = '';
                    if (row.app) {
                        app = <UILIB.Avatar for={row.id + '-' + row.app.id} key={row.id + '-' + row.app.id} style={{ height: 20, width: 20, marginLeft: 5 }} tooltip={row.app.Application.appName + ' Group'} src={row.app.Application.appLogoUri} />
                    }


                    let impressionsClass = "square-chip-red";
                    if (row.impressions > 0) impressionsClass = "square-chip-orange";
                    if (row.impressions > 10) impressionsClass = "square-chip-green";

                    const signupsAvg = (100 / row.impressions) * row.signups;
                    const signupsColor = signupsAvg > 50 ? 'square-chip-green' : signupsAvg > 25 ? 'square-chip-orange' : ''

                    let groupValue = <UILIB.SquareChip className="square-chip-large" iconRight={app ? app : ""}>{row.Group.groupName}</UILIB.SquareChip>
                    if (canReadSubs) {
                        groupValue = <UILIB.SquareChip
                            iconRight={app ? app : ""}
                            onClick={() => this.props.history.push('/cp/groups/' + row.GroupId)}
                            className="square-chip-purple square-chip-large">{row.Group.groupName}
                        </UILIB.SquareChip>
                    }

                    let formName = row.formName;
                    if (row.status && canReadSubs) {
                        formName = <Link to={this.view(row, true)} style={{ fontWeight: "bold", textDecoration: 'none' }}>{row.formName}</Link>
                    }
                    if (!row.status && canEdit) {
                        formName = <Link to={this.wizard(row, true)} style={{ fontWeight: "bold", textDecoration: 'none' }}>{row.formName}</Link>
                    }

                    let typeChip
                    if (row.options?.serve) {
                        typeChip = <UILIB.SquareChip className="square-chip-large square-chip-min-width square-chip-blue">{i18n.t('form:index.gated')}</UILIB.SquareChip>
                    } else {
                        typeChip = <UILIB.SquareChip className="square-chip-large square-chip-min-width square-chip-purple">{i18n.t('form:index.regular')}</UILIB.SquareChip>
                    }

                    return {
                        formImage: {
                            headerValue: "",
                            value: <img alt={row.formName} title={row.formName} style={{ width: "100px", maxHeight: "45px" }} src={SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/form" + row.id + ".jpg"} />,
                            orderBy: false,
                            width: 100
                        },
                        formName: {
                            headerValue: i18n.t('form:index.name'),
                            value: formName,
                            orderBy: false
                        },
                        groupName: {
                            headerValue: i18n.t('group'),
                            value: groupValue,
                        },
                        formType: {
                            headerValue: i18n.t('form:index.type'),
                            value: typeChip,
                        },
                        hosted: {
                            headerValue: i18n.t('form:index.hostedembedded'),
                            value: formChip,
                            align: "center",
                            width: 60
                        },
                        status: {
                            headerValue: i18n.t('status'),
                            value: statusChip,
                            orderBy: true,
                            align: "center",
                            width: 100
                        },
                        impressions: {
                            headerValue: i18n.t('form:index.impressions'),
                            value: <UILIB.SquareChip className={"square-chip-large square-chip-min-width " + impressionsClass}>{NumberFunctions.formatNumber(row.impressions)}</UILIB.SquareChip>,
                            orderBy: false,
                            helper: i18n.t('form:index.impressionsHelper'),
                            align: "center",
                            width: 160
                        },
                        signups: {
                            headerValue: i18n.t('form:index.signups'),
                            value: <div>
                                <div style={{ fontWeight: 'bold', color: 'black' }}>{NumberFunctions.formatNumber(row.signups)}</div>
                                <UILIB.SquareChip className={signupsColor}>{(signupsAvg || 0).toFixed(2)}%</UILIB.SquareChip>
                            </div>,
                            orderBy: false,
                            helper: i18n.t('form:index.signupsHelper'),
                            align: "center",
                            width: 160
                        },
                        createdAt: {
                            headerValue: i18n.t('form:index.created'),
                            value: DateTimeFunctions.formatDateTime(row.createdAt, '2'),
                            orderBy: false,
                            align: "center",
                            width: 60
                        },
                        optionsCol: {
                            headerValue: " ",
                            value: <UILIB.OptionsDropdown popWidth="150px">
                                <ul>
                                    {canReadSubs && <li>
                                        <a onClick={() => { this.view(row) }}>{i18n.t('form:index.view')}</a>
                                    </li>}
                                    {(canEdit == true && canReadSubs) && <li>
                                        <a onClick={() => { row.status ? this.edit(row) : this.wizard(row) }}>{i18n.t('form:index.edit')}</a>
                                    </li>}
                                    {(canEdit == true && canReadSubs) && <li>
                                        <a onClick={() => this.changeGroup(row)}>{i18n.t('form:index.changeGroup')}</a>
                                    </li>}
                                    {(canEdit == true) && <li>
                                        <a onClick={() => { this.duplicate(row) }}>{i18n.t('form:index.duplicate')}</a>
                                    </li>}
                                    {(canEdit == true) && <li>
                                        <a onClick={() => { this.disable(row) }}>{row.disabled ? i18n.t('form:index.enable') : i18n.t('form:index.disable')}</a>
                                    </li>}
                                    {(canEdit == true) && <li>
                                        <a onClick={() => { this.delete(row) }}>{i18n.t('form:index.delete')}</a>
                                    </li>}
                                </ul>
                            </UILIB.OptionsDropdown>,
                            orderBy: false,
                            fixed: true,
                            width: 20
                        }
                    }
                }),
                loading: false,
                firstLoadFormCount: firstLoadFormCount
            })
        }).catch(console.log)
    }

    async disable(form) {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <ToggleDisabled form={form} finished={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
            this.getForms()
        }} />, true, "400px"))
    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        let canWrite = PermissionChecker('forms', this.props.permissionStore.permissions, "write");
        return <UILIB.Paper>

            <div className="mar-b25 quickFlex" style={{ justifyContent: "space-between" }}>
                <div className="quickFlex m-mar-b10">
                    <div className='page-header'>{i18n.t('campaigns:overview.signupForms')}</div>
                    {this.props.siteMaster.siteId === 1 && <UILIB.Hint href="https://kb.transpond.io/article/127-signup-forms" iconLeft={<UILIB.Icons icon="questionCircle" />}>Learn about Signup Forms</UILIB.Hint>}
                </div>

                {canWrite && <UILIB.Button className="button-primary" iconLeft={<UILIB.Icons icon="plus" />} onClick={this.addForm}>{i18n.t('campaigns:overview.createForm')}</UILIB.Button>}
            </div>

            {(this.state.firstLoadFormCount < 1) && <UILIB.Paper>
                <UILIB.Row>
                    <UILIB.Column xs={12} sm={6} md={4}>
                        <img
                            src="https://cdn1.ourmailsender.com/siteContent/noneholders/forms-none.png"
                            style={{ width: "100%" }} />
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                        <UILIB.Icons icon="document" circle={true} style={{ height: 40, width: 40 }} className="mar-b15" />
                        <h4 className="mar-b15">{i18n.t('campaigns:overview.noFormsHeader')}</h4>
                        <div>{i18n.t('campaigns:overview.noFormsDescription')}</div>

                        {canWrite && <div className="mar-t25">
                            <UILIB.Button
                                className="button-primary"
                                onClick={this.addForm}
                                text={i18n.t('campaigns:overview.createForm')}
                                iconLeft={<UILIB.Icons icon="plus" />}
                            />
                        </div>
                        }
                    </UILIB.Column>
                </UILIB.Row>
            </UILIB.Paper>}
            {this.state.firstLoadFormCount > 0 && canWrite && <UILIB.DataTable1
                className="with-image"
                noResultsTxt={PermissionChecker('forms', this.props.permissionStore.permissions, "write") ? <a onClick={() => this.addForm()}> {i18n.t('form:index.noForms')}</a> : i18n.t('form:index.noForms2')}
                tableData={this.state.forms}
                loadingData={this.state.loading}
                width="100%"
                hasCheckBoxes="no"
            />
            }

        </UILIB.Paper>
    }
}

export default Forms;


import React, { useState } from 'react'
import UILIB from '../../../../../Common-Objects/Lib'
import i18n from '~/i18n';
import ExampleTemplate1 from '../themeSelector/exampleTemplate1';
import ExampleTemplate2 from '../themeSelector/exampleTemplate2';
import ExampleTemplate3 from '../themeSelector/exampleTemplate3';

export default function Summary({ job, setJob = () => { }, save = () => { }, back = () => { }, next = () => { } }) {

    const updateValue = (ev) => {
        let newJob = { ...job }
        if (ev.target.name == "url") {
            newJob.url = ev.target.value;
        }
        else {
            newJob.results.socialLinks[ev.target.name] = ev.target.value;
        }
        setJob(newJob);
    }

    let emailAddress = "";
    let facebook = "";
    let twitter = "";
    let linkedin = "";
    let instagram = "";
    if (job.results.socialLinks) {
        emailAddress = job.results.socialLinks.email
        facebook = job.results.socialLinks.facebook
        twitter = job.results.socialLinks.twitter
        linkedin = job.results.socialLinks.linkedin
        instagram = job.results.socialLinks.instagram
    }

    return (

        <UILIB.Row>
            <UILIB.Column xs={12} sm={10} className="col-sm-offset-1 mar-b25 text-heavy text-center">
                {i18n.t('templates:brandAnalyzer.summaryPage.heading')}
            </UILIB.Column>
            <UILIB.Column xs={12} >
                <UILIB.Paper className="mar-b0" >
                    <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div className="text-heavy">{i18n.t('templates:brandAnalyzer.summaryPage.companyInformation')}</div>
                        <UILIB.Hint className="hide-xs" iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>
                            {i18n.t('templates:brandAnalyzer.summaryPage.detailsCanBeChangedFromCompanyProfile')}
                        </UILIB.Hint>
                    </div>

                    <UILIB.Row className="mar-b25">
                        <UILIB.Column xs={12} sm={6} md={4} className="mar-b25">
                            <UILIB.TextInput onChange={updateValue} name="url" value={job.url} label={i18n.t('company:my.websiteAddress')} iconLeft={<UILIB.Icons icon="globe" />} />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} md={4} className="mar-b25">
                            <UILIB.TextInput onChange={updateValue} name="email" value={emailAddress} label={i18n.t('company:my.primaryEmailAddress')} iconLeft={<UILIB.Icons icon="envelope" />} />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} md={4} className="mar-b25">
                            <UILIB.TextInput onChange={updateValue} name="facebook" value={facebook} label={i18n.t('company:my.facebookAddress')} iconLeft={<UILIB.Icons icon="facebook" />} />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} md={4} className="mar-b25">
                            <UILIB.TextInput onChange={updateValue} name="twitter" value={twitter} label={i18n.t('company:my.twitterAddress')} iconLeft={<UILIB.Icons icon="twitter" />} />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} md={4} className="mar-b25">
                            <UILIB.TextInput onChange={updateValue} name="instagram" value={instagram} label={i18n.t('company:my.instagramAddress')} iconLeft={<UILIB.Icons icon="instagram" />} />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} md={4} className="mar-b25">
                            <UILIB.TextInput onChange={updateValue} name="linkedin" value={linkedin} label={i18n.t('company:my.linkedInAddress')} iconLeft={<UILIB.Icons icon="linkedin" />} />
                        </UILIB.Column>
                    </UILIB.Row>

                    <hr />

                    <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div className="text-heavy">{i18n.t('templates:brandAnalyzer.summaryPage.selectedColorTheme')}</div>
                        <UILIB.Hint className="hide-xs" iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>
                            {i18n.t('templates:brandAnalyzer.summaryPage.colorThemeCanBeChanged')}
                        </UILIB.Hint>
                    </div>

                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={6} md={4} className="mar-b25">
                            <ExampleTemplate1 colours={job.selected.colours} logo={job.selected.logo.url} font={job.selected.font} />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} md={4} className="mar-b25 hide-xs">
                            <ExampleTemplate2 colours={job.selected.colours} logo={job.selected.logo.url} font={job.selected.font} />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} md={4} className="mar-b25 hide-xs hide-sm">
                            <ExampleTemplate3 colours={job.selected.colours} logo={job.selected.logo.url} font={job.selected.font} />
                        </UILIB.Column>
                    </UILIB.Row>

                    <hr />

                    <div className="quickFlex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <UILIB.Button
                            text={i18n.t('templates:brandAnalyzer.summaryPage.backToThemePicker')}
                            onClick={() => { back() }}
                            iconLeft={<UILIB.Icons icon="arrowLeft" />}
                        />
                        <UILIB.Button
                            text={i18n.t('templates:brandAnalyzer.summaryPage.saveAndFinish')}
                            onClick={() => { next() }}
                            iconRight={<UILIB.Icons icon="arrowRight" />}
                            className="button-primary"
                        />
                    </div>

                </UILIB.Paper>
            </UILIB.Column>
        </UILIB.Row>



    )
}
import socketIOClient from "socket.io-client";
import config from '~/Classes/siteVars.js';
import axios from "~/data/http/axios";

export class UiSocket {

    constructor() {
        this.uiSocket = undefined;
    }

    async init(callback) {
        console.log("attempting ui sockets");
        //get accountMasterid from reduxPersist:user
        const account = JSON.parse(localStorage.getItem("reduxPersist:accountMaster"));
        const user = JSON.parse(localStorage.getItem("reduxPersist:user"));
        if (!account?.id) return;
        if (!user?.userID) return;
        if (!user?.loggedIn) return;
        console.log("start ui sockets");
        const accountMasterId = account.id;
        const accountUserId = user.userID;

        let authToken = await axios.post(`/accountmaster/socketauth`)
        if (!authToken) return;
        authToken = authToken.data;
        this.uiSocket = socketIOClient(
            config.uiSocketServer + "/dynamic_" + accountMasterId, {
            transports: ['websocket'],
            "path": "/uisocket",
            "query": {
                token: authToken
            },
            "forceNew": true
        }
        );

        return this.listeners(callback);
    }

    listeners(newEvent = () => { }) {
        this.uiSocket.on("connect", (data) => {
            console.log("UI Sockets connected");
            newEvent({
                event: "connected",
                data
            })
        });

        this.uiSocket.on("recieveBrowserNotification", async (data) => {

            //log if notifications are enabled
            if (Notification.permission !== "granted") {
                return;
            }
            let notification = new Notification(data.title, {
                body: data.message,
                icon: "https://cdn1.ourmailserv.com/siteContent/transpondLogos/Logomark_Purple.png"
            });
            notification.onclick = function (event) {
                event.preventDefault();
                window.open(data.link, '_blank');
            };

            newEvent({
                event: "recieveBrowserNotification",
                data
            })
        });


        this.uiSocket.on("connect_error", (err) => {
            console.log("connect_error");
            newEvent({
                event: "connect_error"
            })
        })
        this.uiSocket.on("custom_connect_error", (err) => {
            console.log("connect_error");
            newEvent({
                event: "connect_error"
            })
        })
        this.uiSocket.on("disconnect", data => {
            console.log("disconnected_event");
            newEvent({
                event: "disconnected",
                data
            })
        })
    }

    emit(event, data) {
        if (this.uiSocket && this.uiSocket.connected) {
            this.uiSocket.emit(event, data)
        }
    }


    disconnect() {
        if (this.uiSocket && this.uiSocket.connected) {
            console.log("disconnecting ui sockets");
            this.uiSocket.disconnect();
            this.uiSocket.close()
        }
    }
}
import React, { useState } from "react";
import UILIB from "~/Common-Objects/Lib";
import AlertDialog from "../../../components/alertDialog";
import axios from "~/data/http/axios";
import i18n from "~/i18n";

export default function AddInboxDialogue({
  onCreated = () => { },
  onClose = () => { }
}) {

  const [inboxName, setInboxName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    if (inboxName.length < 3 || !inboxName.trim().length) {
      setError(i18n.t("chat:inbox.leftBar.addInbox.errName"));
      setIsSubmitting(false);
      return { error: true };
    }

    try {
      let newInbox = await axios.post("/inboxes", {
        name: inboxName
      })
      onCreated(newInbox.data);
    }
    catch (err) {
      let error = i18n.t("chat:inbox.leftBar.addInbox.err");
      if (err?.data?.error) error = err.data.error;
      setError(error);
      setIsSubmitting(false);
      return { error: true };
    }


  }

  return (
    <AlertDialog
      open={true}
      title={i18n.t("chat:inbox.leftBar.addInbox.title")}
      description={i18n.t("chat:inbox.leftBar.addInbox.desc")}
      ctaText={i18n.t("chat:inbox.leftBar.addInbox.button")}
      ctaVariant="primary"
      submitting={isSubmitting}
      onCtaClick={handleSubmit}
      onOpenChange={onClose}
    >
      <UILIB.TextInput
        value={inboxName}
        focus={true}
        label={i18n.t("chat:inbox.leftBar.addInbox.name")}
        onChange={(e) => setInboxName(e.target.value)}
        onEnterPress={handleSubmit}
        error={error}
      />
    </AlertDialog>
  );
}

import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import thestore from '~/data/store/store'

import ComponentText from './text'
import ComponentSelect from './select'
import ArraySelect from './arraySelect';
import i18n from '~/i18n';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class PollPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            questionTypeOrder: ["question", "description", "answers", "allowMulti", "button", "link", "shape",
                "color", "showLabels", "positiveLabel", "inputType", "negativeLabel", "showPlaceHolder", "placeHolder"],
            questionTypes: [
                {
                    "label": i18n.t('templates:add.drag.polls.intro'),
                    "value": "intro",
                    "question": {
                        "editable": true,
                        "editableType": "text",
                        "value": i18n.t('templates:add.drag.polls.introDefaultQ')
                    },
                    "description": {
                        "editable": true,
                        "editableType": "text",
                        "value": i18n.t('templates:add.drag.polls.introDefaultDesc')
                    },
                    "rules": [],
                    "button": {
                        "editable": true,
                        "editableType": "text",
                        "value": i18n.t('templates:add.drag.polls.introDefaultButton')
                    }
                },
                {
                    "label": i18n.t('templates:add.drag.polls.satisfactionScore'),
                    "value": "satisfaction",
                    "question": {
                        "editable": true,
                        "editableType": "text",
                        "value": i18n.t('templates:add.drag.polls.satisfactionScoreQ')
                    },
                    "description": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    },
                    "rules": [],
                    "shape": {
                        "editable": true,
                        "editableType": "select",
                        "values": ["smiley", "star"],
                        "value": "smiley"
                    },
                    "color": {
                        "editable": true,
                        "editableType": "select",
                        "values": ["colorful", "mono"],
                        "value": "colorful"
                    },
                    "negativeLabel": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    },
                    "positiveLabel": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    }
                },
                {
                    "label": i18n.t('templates:add.drag.polls.promoterScore'),
                    "value": "promoter",
                    "question": {
                        "editable": true,
                        "editableType": "text",
                        "value": i18n.t('templates:add.drag.polls.promoterScoreQ')
                    },
                    "description": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    },
                    "rules": [],
                    "negativeLabel": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    },
                    "positiveLabel": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    }
                },
                {
                    "label": i18n.t('templates:add.drag.polls.multipleChoice'),
                    "value": "multiple",
                    "question": {
                        "editable": true,
                        "editableType": "text",
                        "value": i18n.t('templates:add.drag.polls.multipleChoiceQ')
                    },
                    "description": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    },
                    "rules": [],
                    "answers": {
                        "editable": true,
                        "editableType": "arraySelect",
                        "value": [{
                            label: i18n.t('templates:add.drag.polls.multipleChoiceA1'),
                            value: i18n.t('templates:add.drag.polls.multipleChoiceA1')
                        },
                        {
                            label: i18n.t('templates:add.drag.polls.multipleChoiceA2'),
                            value: i18n.t('templates:add.drag.polls.multipleChoiceA2')
                        }],
                    },

                    "allowMulti": {
                        "editable": true,
                        "editableType": "select",
                        "value": false,
                        "values": [{ label: i18n.t('templates:add.drag.polls.yes'), value: true }, { label: i18n.t('templates:add.drag.polls.no'), value: false }],
                    }
                },
                {
                    "label": i18n.t('templates:add.drag.polls.question'),
                    "value": "question",
                    "question": {
                        "editable": true,
                        "editableType": "text",
                        "value": i18n.t('templates:add.drag.polls.questionQ')
                    },
                    "description": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    },
                    "rules": [],
                    "inputType": {
                        "editable": true,
                        "editableType": "select",
                        "value": false,
                        "values": [{ label: i18n.t('templates:add.drag.polls.questionT1'), value: "text" }, { label: i18n.t('templates:add.drag.polls.questionT2'), value: "textarea" }],
                    },
                    "showPlaceHolder": {
                        "editable": true,
                        "editableType": "select",
                        "value": false,
                        "values": [{ label: i18n.t('templates:add.drag.polls.yes'), value: true }, { label: i18n.t('templates:add.drag.polls.no'), value: false }],
                    },
                    "placeHolder": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    },
                    "button": {
                        "editable": true,
                        "editableType": "text",
                        "value": i18n.t('templates:add.drag.polls.introDefaultButton')
                    }
                },
                {
                    "label": i18n.t('templates:add.drag.polls.likeDislike'),
                    "value": "like",
                    "question": {
                        "editable": true,
                        "editableType": "text",
                        "value": i18n.t('templates:add.drag.polls.likeDislikeQ')
                    },
                    "description": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    },
                    "rules": [],
                    "shape": {
                        "editable": true,
                        "editableType": "select",
                        "values": ["thumbs", "hearts"],
                        "value": "thumbs"
                    },
                    "color": {
                        "editable": true,
                        "editableType": "select",
                        "values": ["colour", "mono"],
                        "value": "colour"
                    },
                    "negativeLabel": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    },
                    "positiveLabel": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    }
                },
                {
                    "label": i18n.t('templates:add.drag.polls.outro'),
                    "value": "outro",
                    "question": {
                        "editable": true,
                        "editableType": "text",
                        "value": i18n.t('templates:add.drag.polls.outroQ')
                    },
                    "description": {
                        "editable": true,
                        "editableType": "text",
                        "value": i18n.t('templates:add.drag.polls.outroQ1')
                    },
                    "rules": [],
                    "button": {
                        "editable": true,
                        "editableType": "text",
                        "value": ""
                    },
                    "link": {
                        "editable": true,
                        "editableType": "link",
                        "value": ""
                    }
                }
            ],
            loaded: false,
            updated: new Date(),
            editQuestion: -1,
            selectedQuestion: 0
        }

        this.addQuestion = this.addQuestion.bind(this);
        this.changeQuestionType = this.changeQuestionType.bind(this);
        this.deleteQuestion = this.deleteQuestion.bind(this);
        this.editQuestion = this.editQuestion.bind(this);
        this.editUpdateVal = this.editUpdateVal.bind(this);
        this.highlightQuestion = this.highlightQuestion.bind(this);
    }

    componentDidMount() {
        this.props.updateVal('localVariables.tmpCurrentPollId', 0)
    }

    componentWillUnmount() {


    }

    editPoll() {
        var theDrawer = <PollEditor />
        thestore.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '100vw', undefined, () => { }));
    }

    addQuestion() {
        var pollQuestions = JSON.parse(JSON.stringify(this.props.localVariables.pollQuestions.value));
        var ourPos = pollQuestions.length - 1;
        if (ourPos < 0) ourPos = 0;
        pollQuestions.splice(ourPos, 0, this.state.questionTypes[0]);
        this.props.updateVal('localVariables.pollQuestions', pollQuestions);
    }
    changeQuestionType(index, event) {
        var pollQuestions = JSON.parse(JSON.stringify(this.props.localVariables.pollQuestions.value));
        pollQuestions[index] = this.state.questionTypes.find(q => q.value == event.target.value)
        this.props.updateVal('localVariables.pollQuestions', pollQuestions);
    }
    deleteQuestion(index) {
        var pollQuestions = JSON.parse(JSON.stringify(this.props.localVariables.pollQuestions.value));
        pollQuestions.splice(index, 1);
        this.setState({ selectedQuestion: -1 })
        this.props.updateVal('localVariables.pollQuestions', pollQuestions);
        // this.props.updateVal('localVariables.tmpCurrentPollId', 0)
    }
    editQuestion(index) {
        this.setState({ editQuestion: index })
    }
    editUpdateVal(path, value) {
        var pollQuestions = JSON.parse(JSON.stringify(this.props.localVariables.pollQuestions.value));
        var pollQuestion = pollQuestions[this.state.editQuestion];
        pollQuestion[path].value = value;
        this.props.updateVal('localVariables.pollQuestions', pollQuestions)
    }

    highlightQuestion(event, index) {
        if (this.state.selectedQuestion != index) {
            this.setState({ selectedQuestion: index })
            this.props.updateVal('localVariables.tmpCurrentPollId', index)
        }
    }

    render() {

        if (this.props.localVariables.pollQuestions.value.length < 1) {
            return <div>

                <UILIB.Button text={i18n.t('templates:add.drag.polls.addQTemplate')} className="button-md" onClick={this.addQuestion} />

            </div>
        }

        if (this.state.editQuestion > -1) {
            var currQuestion = this.props.localVariables.pollQuestions.value[this.state.editQuestion];

            return <div style={{ width: "100%" }}>
                <h4 className="mar-b20">{i18n.t('templates:add.drag.polls.editQuestion')} {this.state.editQuestion}</h4>
                {this.state.questionTypeOrder.map((q, index) => {
                    var ourProp = currQuestion[q];

                    if (ourProp && ourProp.editable) {
                        let title = q[0].toUpperCase() + q.substring(1);
                        switch (ourProp.editableType) {
                            case "text":
                                return <div key={"pEdit_" + index}>
                                    <ComponentText error={""} value={ourProp.value} title={title} updateVal={this.editUpdateVal} path={q} elementsData={[]} elementData={[]} selectedElement={false} localVariables={{}} />
                                </div>
                                break;
                            case "link":
                                return <div key={"pEdit_" + index}>
                                    <ComponentText error={""} value={ourProp.value} title={title} updateVal={this.editUpdateVal} path={q} elementsData={[]} elementData={[]} selectedElement={false} localVariables={{}} inputType="link" />
                                </div>
                                break;
                            case "select":
                                return <div key={"pEdit_" + index}>
                                    <ComponentSelect error={""} value={ourProp.value} title={title} options={ourProp.values} updateVal={this.editUpdateVal} path={q} elementData={[]} localVariables={{}} />
                                </div>
                                break;
                            case "arraySelect":
                                return <div key={"pEdit_" + index}>
                                    <ArraySelect value={ourProp.value} fieldLabelTxt={"Choice"} fieldValueTxt={"Value saved when answered"} title={title} updateVal={this.editUpdateVal} path={q} elementData={[]} localVariables={{}} valueCantBeBlank={true} />
                                </div>
                                break;
                        }
                    }
                })}


                <UILIB.Button text={i18n.t('templates:add.drag.polls.back')} className="button-md" onClick={() => { this.editQuestion(-1) }} />
            </div>
        }

        return <div style={{ width: "100%" }}>
            {this.props.localVariables.pollQuestions.value.map((question, index) => {
                var style = { width: "100%", border: "1px solid grey", borderRadius: "5px", padding: "10px", display: "flex", alignItems: "center" };
                if (this.state.selectedQuestion == index) {
                    style.border = "2px solid green";
                }
                return <div>
                    {question.value == "outro" && <UILIB.Button text={i18n.t('templates:add.drag.polls.addSection')} className="button-md mar-b20" onClick={this.addQuestion} />}
                    <div className="mar-b20" style={style} key={"PQ_" + index} onClick={(event) => { this.highlightQuestion(event, index) }}>

                        <div className="form-group mar-b0" style={{ flex: "1" }} >
                            {question.value == "outro" && <span>Outro</span>}
                            {question.value != "outro" && <UILIB.Select data={this.state.questionTypes.filter(q => q.value != "outro").map(q => {
                                return { label: q.label, value: q.value }
                            })} value={question.value} onChange={(event) => { this.changeQuestionType(index, event) }} />}

                        </div>
                        <div style={{ flex: "0 0", display: "flex", alignItems: "center" }}>
                            <UILIB.Icons className="mar-l10" icon="pencil" style={{ width: "15px", height: "15px" }} onClick={() => { this.editQuestion(index) }} />
                            {(this.props.localVariables.pollQuestions.value.length > 1 && question.value != "outro") && <UILIB.Icons icon="bin" style={{ width: "15px", height: "15px" }} onClick={() => { this.deleteQuestion(index) }} className="mar-l10" />}

                        </div>
                    </div>
                </div>
            })
            }


        </div>


    }
}



import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'

class AutomationOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {}
        }
        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        this.setState({
            options: this.props.options
        })
    }

    toggle(event) {
        var options = this.state.options
        options[event.currentTarget.name] = event.currentTarget.checked
        this.setState({
            options
        })
    }

    render() {
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12}>
                        <UILIB.HeaderText>Automation Options</UILIB.HeaderText>
                    </UILIB.Column>
                    <UILIB.Column xs={12} margin={0}>
                        <div className="form-group">
                            <UILIB.CheckBox checked={this.state.options.stopOnReply} name="stopOnReply" onClick={this.toggle}>Stop Emailing on Reply</UILIB.CheckBox>
                            <p className="text-grey text-sml">to do this ensure we are watching all reply addresses assigned to each email <a onClick={() => this.props.history.push('/cp/company/mailboxes')}>here</a></p>
                        </div>
                    </UILIB.Column>
                    <UILIB.Column xs={6}>
                        <UILIB.Button onClick={() => this.props.save(this.state.options)} className="button-md primary outline full-width">Save</UILIB.Button>
                    </UILIB.Column>
                    <UILIB.Column xs={6}>
                        <UILIB.Button onClick={this.props.cancel} className="button-md grey outline full-width">Cancel</UILIB.Button>
                    </UILIB.Column>
                </UILIB.Row>
            </div>
        );
    }
}

export default AutomationOptions;
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import {
  handleGenericError,
  alterSnackbar,
  alterSiteDrawer,
} from "~/data/actions/siteActions";
import Button from "../../components/button";
import SettingsSection from "../../components/settingsSection";
import TextInput from "../../components/textInput";
import * as styles from "./index.module.scss";
import FileManager from "~/pages/cp/includes/fileManager/fileManager";
import Header from "../../components/header";
import TextArea from "../../components/textArea";
import i18n from "~/i18n";

export default function AiAssistantChannels({ inbox, assistant, refresh }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [settings, setSettings] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    loadInitialData();
  }, []);

  async function loadInitialData() {
    let newSettings = {
      assistantName: assistant?.settings?.assistantName || "",
      avatarUrl: assistant?.settings?.avatarUrl || "",
      welcomeMessage: assistant?.settings?.responses?.welcomeMessage || "",
      noResponseMessage: assistant?.settings?.responses?.noResponseMessage || "",
      humanMessage: assistant?.settings?.responses?.humanMessage || "",
      aiFinished: assistant?.settings?.responses?.aiFinished || "",
      aiGoodbye: assistant?.settings?.responses?.aiGoodbye || "",
      emailIntroMessage: assistant?.settings?.responses?.emailIntroMessage || "",
      emailOutroMessage: assistant?.settings?.responses?.emailOutroMessage || "",

    };
    setSettings(newSettings);
    setLoading(false);
  }

  const saveSettings = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {

      let newErrors = {};
      if (!settings.assistantName || settings.assistantName.length < 3) newErrors.assistantName = i18n.t("chat:ai.settings.messages.errors.name");
      if (!settings.welcomeMessage || settings.welcomeMessage.length < 10) newErrors.welcomeMessage = i18n.t("chat:ai.settings.messages.errors.welcome");
      if (!settings.noResponseMessage || settings.noResponseMessage.length < 10) newErrors.noResponseMessage = i18n.t("chat:ai.settings.messages.errors.noResponse");
      if (!settings.humanMessage || settings.humanMessage.length < 10) newErrors.humanMessage = i18n.t("chat:ai.settings.messages.errors.humanMessage");
      if (!settings.aiFinished || settings.aiFinished.length < 10) newErrors.aiFinished = i18n.t("chat:ai.settings.messages.errors.finished");
      if (!settings.aiGoodbye || settings.aiGoodbye.length < 10) newErrors.aiGoodbye = i18n.t("chat:ai.settings.messages.errors.goodbye");

      if (!settings.emailIntroMessage || settings.emailIntroMessage.length < 10) newErrors.emailIntroMessage = i18n.t("chat:ai.settings.messages.errors.intro");
      if (!settings.emailOutroMessage || settings.emailOutroMessage.length < 10) newErrors.emailOutroMessage = i18n.t("chat:ai.settings.messages.errors.outro");

      setErrors(newErrors);
      if (Object.keys(newErrors).length > 0) {
        setSaving(false);
        return;
      }

      let saveSettings = { ...assistant.settings }
      saveSettings.assistantName = settings.assistantName;
      saveSettings.avatarUrl = settings.avatarUrl;
      saveSettings.responses = {
        welcomeMessage: settings.welcomeMessage,
        noResponseMessage: settings.noResponseMessage,
        humanMessage: settings.humanMessage,
        aiFinished: settings.aiFinished,
        aiGoodbye: settings.aiGoodbye,
        emailIntroMessage: settings.emailIntroMessage,
        emailOutroMessage: settings.emailOutroMessage,
      }
      await axios.put(`/inboxes/${inbox.id}/ai/${assistant.id}`,
        {
          settings: saveSettings
        });
      dispatch(
        alterSnackbar(true, i18n.t("chat:ai.settings.messages.saved"))
      );
    } catch (err) {
      dispatch(handleGenericError(err));
    }
    setSaving(false);
  };

  const openFileManager = (callback) => {
    const drawer = (
      <FileManager
        onFileClick={({ url }) => {
          callback(url);
        }}
        fileTypes="images"
      />
    );
    dispatch(
      alterSiteDrawer(true, true, "right", drawer, true, "100vw", "fix-height")
    );
  }

  const handleImageUpload = (url) => {
    setSettings({ ...settings, avatarUrl: url });
    dispatch(alterSiteDrawer(false));
  }

  if (loading) return <UILIB.LoadingIcons noClass />;

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inbox_settings_left_content" style={{ paddingBottom: 80 }}>
      <Header type="ai" option="settings" />
      <form className={styles.root} onSubmit={saveSettings} disabled={saving}>

        <SettingsSection
          heading={i18n.t("chat:ai.settings.setup.header")}
          subheading={i18n.t("chat:ai.settings.setup.subheader")}
        >
          <TextInput
            className="width-100"
            label={i18n.t("chat:ai.settings.setup.name")}
            value={settings?.assistantName || ""}
            onChange={(e) => {
              setSettings({ ...settings, assistantName: e.target.value });
            }}
            error={errors.assistantName}
          />

          <div>
            <div className={styles.manualLabel}>{i18n.t("chat:ai.settings.setup.avatar")}</div>
            <div className={styles.imageUploadWrapper}>

              <div className={styles.imageUpload}>
                <UILIB.AvatarNew src={settings.avatarUrl} size={40} />

                <Button
                  variant="secondary"
                  size="s"
                  iconLeft={<UILIB.Icon name="upload" />}
                  onClick={() =>
                    openFileManager(handleImageUpload)
                  }
                >
                  {i18n.t("chat:ai.settings.setup.upload")}
                </Button>
              </div>
            </div>
          </div>



        </SettingsSection>

        <hr />

        <SettingsSection
          heading={i18n.t("chat:ai.settings.messages.header")}
          subheading={i18n.t("chat:ai.settings.messages.subheader")}
        >
          <div className={styles.teaser}>
            <div className={styles.teaser_header}>{i18n.t("chat:ai.settings.messages.webchat.header")}</div>
            <div>{i18n.t("chat:ai.settings.messages.webchat.subheader")}</div>
            <TextInput
              className="width-100"
              label={i18n.t("chat:ai.settings.messages.webchat.welcome")}
              value={settings?.welcomeMessage || ""}
              onChange={(e) => {
                setSettings({ ...settings, welcomeMessage: e.target.value });
              }}
              error={errors.welcomeMessage}
            />

            <TextInput
              className="width-100"
              label={i18n.t("chat:ai.settings.messages.webchat.noResponse")}
              value={settings?.noResponseMessage || ""}
              onChange={(e) => {
                setSettings({ ...settings, noResponseMessage: e.target.value });
              }}
              error={errors.noResponseMessage}
            />

            <TextInput
              className="width-100"
              label={i18n.t("chat:ai.settings.messages.webchat.humanMessage")}
              value={settings?.humanMessage || ""}
              onChange={(e) => {
                setSettings({ ...settings, humanMessage: e.target.value });
              }}
              error={errors.humanMessage}
            />

            <TextInput
              className="width-100"
              label={i18n.t("chat:ai.settings.messages.webchat.finished")}
              value={settings?.aiFinished || ""}
              onChange={(e) => {
                setSettings({ ...settings, aiFinished: e.target.value });
              }}
              error={errors.aiFinished}
            />

            <TextInput
              className="width-100"
              label={i18n.t("chat:ai.settings.messages.webchat.goodbye")}
              value={settings?.aiGoodbye || ""}
              onChange={(e) => {
                setSettings({ ...settings, aiGoodbye: e.target.value });
              }}
              error={errors.aiGoodbye}
            />
          </div>

          <div className={styles.teaser}>
            <div className={styles.teaser_header}>{i18n.t("chat:ai.settings.messages.email.header")}</div>
            <div>{i18n.t("chat:ai.settings.messages.email.subheader")}</div>
            <TextArea
              uilibStyle={{ height: "150px" }}
              uilibOuterStyle={{ height: "150px" }}
              className={styles.textarea}
              value={settings?.emailIntroMessage || ""}
              error={errors.emailIntroMessage}
              label={i18n.t("chat:ai.settings.messages.email.intro")}
              onChange={(e) => {
                setSettings({ ...settings, emailIntroMessage: e.target.value });
              }}
            />
            <TextArea
              uilibStyle={{ height: "150px" }}
              uilibOuterStyle={{ height: "150px" }}
              className={styles.textarea}
              value={settings?.emailOutroMessage || ""}
              error={errors.emailOutroMessage}
              label={i18n.t("chat:ai.settings.messages.email.outro")}
              onChange={(e) => {
                setSettings({ ...settings, emailOutroMessage: e.target.value });
              }}
            />
          </div>

        </SettingsSection>

        <Button
          className={styles.submitButton}
          type="submit"
          size="s"
          loading={saving}
        >
          {i18n.t("chat:ai.settings.messages.save")}
        </Button>
      </form>
    </div>
  );
}

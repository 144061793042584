import React from "react";

export default function IconPeople3({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13 6C13 6.79565 12.6839 7.55871 12.1213 8.12132C11.5587 8.68393 10.7956 9 10 9C9.20435 9 8.44129 8.68393 7.87868 8.12132C7.31607 7.55871 7 6.79565 7 6C7 5.20435 7.31607 4.44129 7.87868 3.87868C8.44129 3.31607 9.20435 3 10 3C10.7956 3 11.5587 3.31607 12.1213 3.87868C12.6839 4.44129 13 5.20435 13 6ZM18 8C18 8.53043 17.7893 9.03914 17.4142 9.41421C17.0391 9.78929 16.5304 10 16 10C15.4696 10 14.9609 9.78929 14.5858 9.41421C14.2107 9.03914 14 8.53043 14 8C14 7.46957 14.2107 6.96086 14.5858 6.58579C14.9609 6.21071 15.4696 6 16 6C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8ZM14 15C14 13.9391 13.5786 12.9217 12.8284 12.1716C12.0783 11.4214 11.0609 11 10 11C8.93913 11 7.92172 11.4214 7.17157 12.1716C6.42143 12.9217 6 13.9391 6 15V18H14V15ZM6 8C6 8.53043 5.78929 9.03914 5.41421 9.41421C5.03914 9.78929 4.53043 10 4 10C3.46957 10 2.96086 9.78929 2.58579 9.41421C2.21071 9.03914 2 8.53043 2 8C2 7.46957 2.21071 6.96086 2.58579 6.58579C2.96086 6.21071 3.46957 6 4 6C4.53043 6 5.03914 6.21071 5.41421 6.58579C5.78929 6.96086 6 7.46957 6 8ZM16 18V15C16.0014 13.9833 15.7433 12.983 15.25 12.094C15.6933 11.9805 16.1568 11.9698 16.6049 12.0627C17.053 12.1556 17.474 12.3496 17.8357 12.6299C18.1974 12.9102 18.4903 13.2695 18.6921 13.6802C18.8939 14.091 18.9992 14.5424 19 15V18H16ZM4.75 12.094C4.25675 12.983 3.9986 13.9833 4 15V18H1V15C0.999808 14.542 1.10446 14.0901 1.30595 13.6789C1.50743 13.2676 1.80039 12.9079 2.16238 12.6274C2.52437 12.3469 2.94578 12.153 3.39431 12.0605C3.84284 11.9681 4.30658 11.9795 4.75 12.094Z"
        fill={color}
      />
    </svg>
  );
}

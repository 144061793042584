import React from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import i18n from '~/i18n'

export default class DeleteEvent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.deleteEvent = this.deleteEvent.bind(this);
    }

    componentDidMount() {

    }

    async deleteEvent() {
        await axios.delete('/accountMaster/tracker/events/' + this.props.event.id)
        this.props.close();
    }

    render() {
        return <>
            <h4 className="mar-b10">Delete the "{this.props.event.name}" Event</h4>
            <div className="mar-b25">Are you sure you would like to remove this event?</div>
            <UILIB.WarningHolder className="mar-b25">Deleting this event will also remove all of the event history associated with it</UILIB.WarningHolder>
            <div className="quick-flex">
                <UILIB.Button text="Contine and Delete" className="button-red mar-r5" onClick={this.deleteEvent} />
                <UILIB.Button text="Forget it" onClick={this.props.close} />
            </div>
        </>
    }
}

import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import SelectUser from '~/pages/cp/campaigns/add/steps/integration/selectUser'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip'
@connect((store) => {
    return { account: store.accountMaster }
})

class DrawerEditStep extends Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false, links: [], webhooks: [], users: [], drawerLoaded: false, drawerContent: undefined }
        this.getLinks = this.getLinks.bind(this);
        this.getWebhooks = this.getWebhooks.bind(this);
        this.addAction = this.addAction.bind(this);
        this.removeAction = this.removeAction.bind(this);
        this.saveWebhooks = this.saveWebhooks.bind(this);
    }

    componentDidMount() {
        this.getLinks();
    }
    getLinks() {
        axios.get('/template/' + this.props.step.campaign.TemplateId + '/links').then(response => {
            this.setState({
                links: response.data.Links,
            }, this.getWebhooks)
        }).catch(console.error)
    }

    getWebhooks() {
        axios.get('/webhook?CampaignId=' + this.props.step.campaign.id).then(response => {
            this.setState({
                webhooks: response.data.Webhooks
            }, this.getEndPoints)
        })
    }

    getEndPoints() {
        this.props.apps.forEach((app, index) => {
            if (!app.Application.options || !app.Application.options.outboundClick) return
            axios.get(app.Application.options.outboundClick.list).then(response => {
                var users = this.state.users
                users[index] = response.data.Users
                this.setState({
                    users,
                    loaded: true
                })
            })
        })
    }
    addAction(linkIndex, action) {
        var webhooks = this.state.webhooks
        var existing = webhooks.filter(w => w.options.link.split('?')[0] == this.state.links[linkIndex].split('?')[0])
        var drawerContent = <SelectUser existing={existing} users={this.state.users[action.appIndex]} select={(userIndex) => {

            var link = this.state.links[linkIndex]
            var app = this.props.apps[action.appIndex]
            var user = this.state.users[action.appIndex][userIndex]
            var option = app.Application.options.outboundClick.options[action.optionIndex]

            option.value = option.value.replace(/{{link}}/g, link)
            option.value = option.value.replace(/{{key}}/g, user.id)
            option.value = option.value.replace(/{{AccountMasterId}}/g, this.props.account.id)
            option.value = option.value.replace(/{{CampaignId}}/g, this.props.step.campaign.id)

            webhooks.push({
                webHookUri: option.value,
                hookType: 'CampaignClick',
                applicationId: app.Application.id,
                groupId: app.appSettings.groupId,
                options: {
                    CampaignId: this.props.step.campaign.id,
                    link,
                    key: user.id
                }
            })

            this.setState({ webhooks, drawerLoaded: false, drawerContent: undefined }, this.saveWebhooks)
        }} />
        this.setState({ drawerLoaded: true, drawerContent })
    }

    removeAction(index) {
        var webhooks = this.state.webhooks
        webhooks.splice(index, 1)
        this.setState({ webhooks }, this.saveWebhooks)
    }

    saveWebhooks() {
        console.log("SAVE");
        axios.post('/webhook/campaign', {
            CampaignId: this.props.step.campaign.id,
            webhooks: this.state.webhooks
        }).then(() => {

        })
    }
    render() {
        if (!this.state.loaded) return <div></div>

        var actions = []
        this.props.apps.forEach((app, appIndex) => {
            if (app.Application.options && app.Application.options.outboundClick) {
                app.Application.options.outboundClick.options.forEach((option, optionIndex) => {
                    actions.push({
                        app, appIndex, optionIndex,
                        label: option.label,
                        value: option.value
                    })
                })
            }
        })


        return <div>
            <h4 className="mar-b25">Integrations Actions</h4>
            <div className="mar-b25">We've found the following links in your Template. Please choose what happens when people click links.</div>
            {this.state.links.map((link, linkIndex) => {
                var hooks = this.state.webhooks.filter(hook => hook.options.link.split('?')[0] == link.split('?')[0])
                return <div>
                    <UILIB.Row className="middle-xs">
                        <UILIB.Column xs={12} sm={12} md={6}>
                            <p className="no-marg">{link}</p>
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={12} md={6} className="end-xs quickFlex">
                            {hooks.map((hook, hookIndex) => {
                                var appIndex = this.props.apps.findIndex(app => {
                                    return app.Application.options && app.Application.options.outboundClick && app.Application.options.outboundClick.options.some(o => o.value.split('?')[0] == hook.webHookUri.split('?')[0])
                                })
                                var app = this.props.apps[appIndex]
                                if (!app) return <div></div>

                                var userIndex = this.state.users[appIndex].findIndex(u => u.id === hook.options.key)
                                var user = this.state.users[appIndex][userIndex]
                                var name = ''
                                if (user.fullname) name = user.fullname
                                else if (user.firstName || user.lastName) name = (user.firstName || '') + ' ' + (user.lastName || '')
                                else if (user.username) name = user.username
                                else if (user.emailAddress) name = user.emailAddress

                                var optionIndex = app.Application.options.outboundClick.options.findIndex(o => o.value.split('?')[0] == hook.webHookUri.split('?')[0])
                                var option = app.Application.options.outboundClick.options[optionIndex]

                                return <div className="avatar-cross" data-tip>
                                    <ReactTooltip effect='solid' className={"tooltip"} >{app.Application.appName + ' - ' + name + ' - ' + option.label}</ReactTooltip>

                                    <img className="application" src={app.Application.appLogoUri} height="40px" width="40px" />
                                    {!!user.avatar && <img className="avatar" src={user.avatar} height="40px" width="40px" />}
                                    {!user.avatar && <UILIB.Initials size={40} name={name} />}
                                    <span className="remove icon-cross-circle" onClick={() => this.removeAction(hookIndex)} />
                                </div>
                            })}
                            <UILIB.ButtonSelect headerText="Select an action" width={'300px'} className="button-sml white with-icon" icon={true} data={actions.map(a =>
                                <a className="quickFlex" onClick={() => this.addAction(linkIndex, a)}><img height="40px" width="40px" style={{ borderRadius: '100%', marginRight: '10px' }} src={a.app.Application.appLogoUri} /> {a.label}</a>
                            )} />
                        </UILIB.Column>
                    </UILIB.Row>
                    {linkIndex !== this.state.links.length - 1 && <UILIB.Row>
                        <UILIB.Column xs={12}>
                            <div className="divider2 no-marg"></div>
                        </UILIB.Column>
                    </UILIB.Row>}
                </div>
            })}

            <UILIB.Drawer standalone={true} width={300} isOpen={this.state.drawerLoaded} showClose={true}>{this.state.drawerContent}</UILIB.Drawer>
        </div>


    }
}

export default DrawerEditStep;
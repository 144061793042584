
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import SiteVars from '~/Classes/siteVars';

class TemplateImageLoader extends Component {
    constructor(props) {
        super(props);
        this.timer;
        this.unmouting = false
        this.state = {
            templateImageLoaded: false,
            rndNo: Math.random() * 99999999,
            loadTries: 0
        }
        this.checkTemplateImageExists = this.checkTemplateImageExists.bind(this);

    }

    componentWillUnmount() {
        this.unmouting = true;
        clearTimeout(this.timer)
    }
    checkTemplateImageExists() {
        if (this.state.templateImageLoaded) return;
        if (this.unmouting) return
        if (this.state.loadTries > 10) {
            return;
        }
        var loadTries = this.state.loadTries + 1;
        this.setState({ loadTries })
        var self = this;
        var img = new Image();
        img.onload = function () { self.setState({ templateImageLoaded: true }) };
        img.onerror = function () {
            if (!self.unmouting) {
                self.timer = setTimeout(self.checkTemplateImageExists, 1000);
            }
        };
        img.src = this.props.imageUrl;
    }

    componentDidMount() {
        this.checkTemplateImageExists();
    }

    render() {
        var imageUrl = this.props.imageUrl + "?rnd=" + this.state.rndNo
        var backgroundImage = "url(" + imageUrl + ")";

        if (this.state.loadTries > 10) {
            backgroundImage = `url(${SiteVars.repoEndPoint}siteContent/assets/templates/common/unknownTemplate.jpg)`;
            return <div className="templateImageImg" style={{ backgroundImage: backgroundImage }} />
        }

        if (this.state.templateImageLoaded) {
            let className = "templateImageImg"
            if (this.props.type === 6) {
                className += ' phone'
            }
            if (this.props.form) {
                className += " form"
            }
            return <div className={className} style={{ backgroundImage: backgroundImage, cursor: this.props.onClick ? 'pointer' : '' }} onClick={this.props.onClick} />
        }
        else {
            return <UILIB.LoadingIcons iconType="2" />

        }

    }
}

export default TemplateImageLoader;

import React from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import i18n from '~/i18n'
import PermissionChecker from '~/Classes/permissions'
import queryString from 'query-string';
import FormatNumberFuncs from '../../../../Classes/numberFormatFunctions';

//CONNECT TO STORE
@connect((store) => {
    return { permissionStore: store.permission, accountMaster: store.accountMaster }
})

export default class ExportsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accountLoaded: false,
            groupsLoaded: false,
            segmentsLoaded: false,
            totalSubscribers: 0,
            segments: [],
            groups: [],
            filter: '',
            groupFilter: '',
            searchingGroups: false,
            subscriberStatus: '',
            currentSegment: undefined,
            isExporting: false,
            exportJobId: undefined,
            exportedSubscribersPct: 0,
            exportHistoryLoaded: false,
            exportHistory: []
        };

        this.getAccount = this.getAccount.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.getSegments = this.getSegments.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.goExport = this.goExport.bind(this);
        this.checkExport = this.checkExport.bind(this);

        this.getExportHistory = this.getExportHistory.bind(this);
        this.downloadExport = this.downloadExport.bind(this);
        this.unmounting = false
    }

    componentDidMount() {
        const query = queryString.parse(location.search);
        if (query && query.gid) {
            this.setState({ filter: "group", currentGroup: Number(query.gid) }, this.getGroups)
        }
        else {
            this.getGroups();
        }
        this.getAccount()
        this.getSegments()
        this.getExportHistory()
    }
    componentWillUnmount() {
        this.unmounting = true
        if (this.historyTimer) clearTimeout(this.historyTimer)
        if (this.exportTimer) clearTimeout(this.exportTimer)
    }

    getAccount() {
        axios.get('/accountmaster').then(response => {
            this.setState({
                accountLoaded: true,
                totalSubscribers: response.data.AccountMaster.totalSubscribers
            })
        })
    }

    updateGroupFilter(text) {
        this.setState({ groupFilter: text }, () => {
            if (this.groupTimer) clearTimeout(this.groupTimer)
            this.groupTimer = setTimeout(() => {
                this.setState({ searchingGroups: true })
                this.getGroups()
            }, 350)
        })
    }

    getGroups() {
        let q = '?count=true&hideUsers=true&all=true'
        if (this.state.groupFilter && this.state.groupFilter.length > 2) {
            q += '&searchText=' + this.state.groupFilter
        }
        axios.get('/group' + q).then(response => {
            this.setState({
                groupsLoaded: true,
                searchingGroups: false,
                groups: response.data.Groups
            })
        })
    }

    getSegments() {
        axios.get('/segment?count=true&notsystem=true').then(response => {
            this.setState({
                segments: response.data.Segments,
                segmentsLoaded: true
            })
        })
    }

    updateValue(ev) {
        this.setState({
            [ev.currentTarget.name]: ev.currentTarget.value
        })
    }

    goExport() {
        this.setState({
            isExporting: true
        })
        var self = this;
        var data = {};
        if (this.state.filter == 'segment') {
            data.segmentId = this.state.currentSegment;
        } else if (this.state.filter == 'group') {
            data.groupId = this.state.currentGroup;
        }

        data.subscriberStatus = this.state.subscriberStatus;

        self.setState({ alertOpen: true, alertMessage: i18n.t('subscribers:export.exporting') })

        axios.post('/export', data)
            .then((res) => {
                var jobId = res.data.ExportJob.id;
                self.setState({ exportStatus: i18n.t('subscribers:export.exporting'), exportJobId: jobId, isExporting: true })
                self.checkExport();
            }).catch(exception => {
                self.setState({ alertOpen: true, isExporting: false, alertMessage: i18n.t('subscribers:export.exportError') })
            });
    }

    checkExport() {
        if (this.exportTimer) clearTimeout(this.exportTimer);
        if (this.unmounting) return
        this
        var self = this;
        axios.get('/export/' + this.state.exportJobId)
            .then((res) => {
                if (!res.data.ExportJob) {
                    this.downloadExport(res.data)
                    self.setState({ isExporting: false, exportJobId: undefined });
                }
                else {
                    var totalDone = (res.data.ExportJob.exportedSubscribers / res.data.ExportJob.totalSubscribers) * 100;
                    self.setState({ exportedSubscribersPct: totalDone })
                    this.exportTimer = setTimeout(self.checkExport, 1000);
                }
            }).catch(exception => {
                console.log(exception)
            });
    }

    downloadExport(row) {
        axios.get('/export/' + row.id + '/file').then(res => {
            let link = document.createElement("a");
            link.href = res.data.url
            link.download = row.filename;
            link.click();
        })
    }

    getExportHistory() {
        if (this.historyTimer) clearTimeout(this.historyTimer);
        if (this.unmounting) return
        axios.get('/export').then(exportJobs => {
            if (this.unmounting) return
            this.historyTimer = setTimeout(this.getExportHistory, 5000)

            var tableOut = exportJobs.data.map((row) => {
                var statusClass = "square-chip-grey";
                if (row.status == "Finished") statusClass = "square-chip-green";
                if (row.status == "Pending" || row.status == "Processing") statusClass = "square-chip-orange";
                return {
                    createdAt: {
                        headerValue: i18n.t('subscribers:export.history.date'),
                        value: DateTimeFunctions.formatDateTime(row.createdAt, 3),
                        orderBy: false
                    },
                    status: {
                        headerValue: i18n.t('subscribers:export.history.status'),
                        value: <UILIB.SquareChip value={row.status} className={statusClass} />,
                        orderBy: false,
                        align: "center"
                    },
                    totalSubscribers: {
                        headerValue: i18n.t('subscribers:export.history.subscribers'),
                        value: FormatNumberFuncs.formatNumber(row.exportedSubscribers),
                        orderBy: false,
                        align: "center"
                    },
                    options: {
                        headerValue: "",
                        value: row.status == "Finished" ? <UILIB.Button iconRight={<UILIB.Icons icon="arrowDown" />} text={i18n.t('subscribers:export.history.download')} onClick={() => { this.downloadExport(row) }} /> : < UILIB.LoadingIcons iconType="2" />,
                        orderBy: false,
                        width: 20
                    }
                }
            })

            this.setState({
                exportHistory: tableOut,
                exportHistoryLoaded: true
            })

        }).catch(err => {
            console.log(err);
        })
    }
    render() {
        var addExportLoaded = (this.state.accountLoaded && this.state.groupsLoaded && this.state.segmentsLoaded)

        var disabled = this.state.isExporting;
        if (!disabled) {
            if (this.state.filter == 'segment' && !this.state.currentSegment) disabled = true;
            if (this.state.filter == 'group' && !this.state.currentGroup) disabled = true;
        }

        var stateOptions = [{ value: '', label: i18n.t('subscribers:export.allSubscriberStates') }, { value: 'active', label: i18n.t('subscribers:export.active') }, { value: 'bounce', label: i18n.t('subscribers:export.bounced') }, { value: 'blackListed', label: i18n.t('subscribers:export.blackListed') }]
        if (this.state.filter === 'group') {
            stateOptions.push({ label: i18n.t('In-Active'), value: 'in-active' }, { label: i18n.t('Unsubscribed'), value: 'unsubscribed' })
        }

        var delimiters = [{ label: 'Comma ,', value: ',' }, { label: 'Semi Colon ;', value: ';' }, { label: 'Pipe |', value: '|' }];
        var delimiter = delimiters.find(d => d.value == this.props.accountMaster.accountMaster.options.defaultDelimiter)
        if (!delimiter) {
            delimiter = "Comma ,";
        }
        else {
            delimiter = delimiter.label;
        }

        var showSubStatsDropDown = true;
        if (this.state.filter === 'segment') {
            var currSegment = this.state.segments.find(s => s.id == this.state.currentSegment)
            if (currSegment) {
                if (currSegment.readOnly == 1 && (currSegment.name == "Blacklisted" || currSegment.name == "Bounced")) {
                    showSubStatsDropDown = false;
                }
            }
        }
        return <div>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={6} margin={0} className="col-md-offset-3">
                    {(PermissionChecker("reporting", this.props.permissionStore.permissions, "write") == true) && <UILIB.Paper className="">
                        <UILIB.Row className="mar-b15">
                            <UILIB.Column xs={12}>
                                <h4 className="mar-b25">{i18n.t('subscribers:export.header')}</h4>
                                {!addExportLoaded && <div>< UILIB.LoadingIcons iconType="2" /></div >}
                                {addExportLoaded && <div>
                                    <div className="mar-b20">{i18n.t('subscribers:export.subHeader')}</div>
                                    <div className="form-group mar-b0">
                                        <UILIB.Select data={[{ value: '', label: i18n.t('subscribers:export.allSubscribers') + ' (' + FormatNumberFuncs.formatNumber(this.state.totalSubscribers) + ')' }, { value: 'group', label: i18n.t('subscribers:export.group') }, { value: 'segment', label: i18n.t('subscribers:export.segment') }]} name="filter" value={this.state.filter} onChange={this.updateValue} disabled={this.state.isExporting} />
                                    </div>
                                    {this.state.filter === 'segment' && <div className="form-group mar-b0">
                                        <div style={{ marginTop: "20px" }} />
                                        <UILIB.Select placeholder="Select Segment" data={this.state.segments.map(s => ({ value: s.id, label: i18n.t(s.name) + ' (' + FormatNumberFuncs.formatNumber(s.subscriberCount) + ')' }))} name="currentSegment" onChange={this.updateValue} value={this.state.currentSegment} disabled={this.state.isExporting} />
                                    </div>}
                                    {this.state.filter === 'group' && <div className="form-group mar-b0">
                                        <div style={{ marginTop: "20px" }} />
                                        <UILIB.Select placeholder="Select Group"
                                            data={this.state.groups.map(s => ({ value: s.id, label: s.groupName + ' (' + FormatNumberFuncs.formatNumber(s.all) + ')' }))}
                                            name="currentGroup"
                                            onChange={this.updateValue}
                                            value={this.state.currentGroup}
                                            filter={true}
                                            updateFilter={this.updateGroupFilter.bind(this)}
                                            loadingData={this.state.searchingGroups}
                                            disabled={this.state.isExporting}
                                        />
                                    </div>}
                                    {showSubStatsDropDown && <div className="form-group mar-t20 mar-b0">
                                        <UILIB.Select data={stateOptions} name="subscriberStatus" value={this.state.subscriberStatus} onChange={this.updateValue} disabled={this.state.isExporting} />
                                    </div>}
                                    {this.state.isExporting == true &&
                                        <div className="mar-t10">

                                            <div className="mar-b10" style={{ fontWeight: "bold" }}>{this.state.exportStatus}</div>
                                            <div className="mar-b20">{i18n.t('subscribers:export.exportInProgressText')}</div>
                                            <div style={{ height: "15px" }} />
                                            {this.state.exportedSubscribersPct === 0 && <UILIB.LoadingLine className="mar-t10" />}
                                            {this.state.exportedSubscribersPct > 0 && <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.exportedSubscribersPct} val2={100} />}

                                        </div>
                                    }
                                </div>}
                            </UILIB.Column>
                        </UILIB.Row>
                        {addExportLoaded && <UILIB.Row>
                            <UILIB.Column xs={12} sm={6} margin={0}>
                                {i18n.t('company:my.delimiter')}: <a onClick={() => { this.props.history.push('/cp/company') }}>{delimiter}</a>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} margin={0} className="end-xs">
                                <UILIB.Button text={i18n.t('subscribers:export.button')} onClick={this.goExport} disabled={disabled} className="button-primary" iconRight={<UILIB.Icons icon="arrowRight" />}></UILIB.Button>
                            </UILIB.Column>
                        </UILIB.Row>}
                    </UILIB.Paper>}
                    <UILIB.Paper>
                        <h4 className="mar-b25">{i18n.t('subscribers:export.history.header')}</h4>
                        {!this.state.exportHistoryLoaded && <UILIB.LoadingIcons iconType="2" />}

                        {this.state.exportHistoryLoaded && <div>
                            <div className="mar-b25">{i18n.t('subscribers:export.history.subHeader')}</div>
                            <UILIB.DataTable1
                                noResultsTxt={<span> {i18n.t('subscribers:export.history.noHistory')}</span>}
                                tableData={this.state.exportHistory}
                                loadingData={false}
                                dataUpdater={() => { }}
                                width="100%"
                                pageSize="100"
                                hasCheckBoxes="no"
                                sortedColumn={"createdAt"}
                                sortedDirection={"desc"}
                            />
                        </div>}
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
        </div >
    };

};

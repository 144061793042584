import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '../../../../../../Common-Objects/Lib';
import { connect } from 'react-redux';
import i18n from '~/i18n'

import IncAddCustomField from '~/pages/cp/subscribers/customfields/incAddCustomField'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class ImportFieldSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupID: 0,
            importID: 0,
            importData: [],
            importTable: "",
            addCustomFieldOpen: false,
            currentOptionNo: -1,
            submitButtonDisabled: true,
            customFields: [],
            fields: [],
            outputData: {},
            firstLoad: true,
            dateFormats: ["DD-MM-YYYY", "MM-DD-YYYY", "DD.MM.YYYY", "MM.DD.YYYY", "DD/MM/YYYY", "MM/DD/YYYY", "YYYY-MM-DD", "YYYY/MM/DD", "YYYY.MM.DD"],
            aap: undefined,
            errors: [],
            tagDelimiter: ','
        };
        this.getImportData = this.getImportData.bind(this);
        this.createImportTable = this.createImportTable.bind(this);
        this.goBack = this.goBack.bind(this);
        this.getCustomFields = this.getCustomFields.bind(this);
        this.addCustomFieldBack = this.addCustomFieldBack.bind(this);
        this.completeImport = this.completeImport.bind(this);
        this.setValue = this.setValue.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.setDateFormat = this.setDateFormat.bind(this);
        this.determineDateType = this.determineDateType.bind(this);
    }

    componentDidMount() {
        this.setState({
            groupID: this.props.match.params.groupID,
            importID: this.props.match.params.importID
        }, this.getImportData);

        axios.get('/accountMaster/application').then(response => {
            this.setState({
                app: response.data.Applications.find(a => a.appSettings.groupId == this.props.match.params.groupID && a.Application.options.importOnCreate)
            })
        })
    }

    getImportData() {
        var self = this;
        axios.get('/group/' + this.state.groupID + '/import/' + this.state.importID)
            .then(function (res) {
                let tmpErrors = [];
                if (res.data.errors) {
                    if (res.data.errors.duplicateColumns) {
                        res.data.errors.duplicateColumns.forEach(err => {
                            tmpErrors.push("Duplicate Column: " + err + ". Please remove the duplicate column from your import file and try again.")
                        })
                    }
                }
                self.setState({
                    importData: res.data.ImportJob,
                    errors: tmpErrors
                }, self.getCustomFields);
            })
            .catch(console.log);
    }

    getCustomFields() {
        var self = this;
        axios
            .get("/customfield")
            .then((res) => {
                self.setState({ customFields: res.data.CustomFields }, this.createImportTable);
            })
            .catch(function (error) { console.log("ERROR"); console.log(error) });
    }

    setTagDelimiter(e) {
        this.setState({ tagDelimiter: e.currentTarget.value }, this.createImportTable)
    }

    setValue(field, value, index) {

        var values = this.state.outputData;

        var found = this.state.customFields.find(c => c.id == value)


        // if (field.indexOf("_") > 0) {
        //     var fieldDat = field.split("_");
        //     field = fieldDat[1];
        //     value = {
        //         key: value,
        //         format: fieldDat[2]
        //     }
        // }
        // else {
        if (found && found.fieldType === "DATE") {
            value = {
                key: value,
                format: this.determineDateType(this.state.importData.csvSampleData.slice(1)[0][index])
            }
        }
        // }

        values[field] = value;
        this.setState({
            outputData: values
        }, this.createImportTable);
    }

    setDateFormat(field, value) {
        var values = this.state.outputData;
        values[field].format = value;
        this.setState({
            outputData: values
        }, this.createImportTable)
    }

    addCustomField(field, index) {
        var that = this;
        var values = this.state.outputData;
        var customFields = this.state.customFields;
        var drawerContent = <IncAddCustomField groupId={this.state.groupID} getCustomFields={(newField) => {
            if (newField.fieldType == 'DATE') {
                values[field] = {
                    key: newField.id,
                    format: this.determineDateType(this.state.importData.csvSampleData.slice(1)[0][index])
                }
            } else {
                values[field] = newField.id;
            }

            customFields.push(newField);
            that.setState({
                outputData: values,
                customFields
            }, that.createImportTable);
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    clearSelection(field) {
        var values = this.state.outputData;
        delete values[field];
        this.setState({
            outputData: values
        }, this.createImportTable)
    }

    createImportTable() {
        //Creates the import data, from the sample data stored against the import.

        var customFields = this.state.customFields;
        var header = this.state.importData.csvSampleData[0];
        var sampleData = this.state.importData.csvSampleData.slice(1);
        var currentValues = this.state.outputData;

        var foundEmail = false;
        var foundTags = false



        if (this.state.firstLoad) {
            // try and match the fields automatically
            header.forEach((head, index) => {
                var value = head.toLowerCase().replace(/\s/g, '')
                if (value.indexOf('email') > -1 && foundEmail == false) {
                    currentValues[head] = 'emailAddress'
                    foundEmail = true;
                } else if (value.toLowerCase().indexOf('tag') === 0 && foundTags == false) {
                    currentValues[head] = 'SubscriberTags'
                    foundTags = true;
                } else {
                    customFields.forEach(field => {

                        if (!field.found || field.found != true) {
                            if ((value.indexOf(field.fieldName.toLowerCase()) > -1 || value.indexOf(field.fieldDesc.toLowerCase()) > -1) && !field.readOnly) {
                                if (field.fieldType == 'DATE') {
                                    currentValues[head] = {
                                        key: field.id,
                                        format: this.determineDateType(sampleData[0][index])
                                    }
                                } else {
                                    currentValues[head] = field.id;
                                }
                                field.found = true;
                            }
                        }
                    })
                }
            })

        }

        var results = <>
            {header.map((headerField, headerIndex) => {
                var selectData = []
                var showEmail = true;
                let showTags = true
                for (var prop in currentValues) {
                    if (currentValues[prop] == 'emailAddress') showEmail = false;
                    if (currentValues[prop] == 'SubscriberTags') showTags = false;
                }
                selectData.push({ label: i18n.t('emailAddress'), value: 'emailAddress', disabled: !showEmail })
                selectData.push({ label: 'Tags', value: 'SubscriberTags', disabled: !showTags })
                customFields.filter(cf => !cf.readOnly).forEach((customField) => {
                    var show = true;
                    for (var prop in currentValues) {
                        if (currentValues[prop] == customField.id) show = false;
                    }
                    selectData.push({
                        iconRight: customField.Application ? <UILIB.Avatar src={customField.Application.appLogoUri} style={{ height: 20, width: 20 }} /> : '',
                        label: customField.fieldDesc,
                        value: customField.id,
                        disabled: customField.readOnly || !show
                    })
                })

                var headerText = 'Not Selected';
                var isDateSelected = false;
                var currentSelectedFieldId = '';
                var currentDateFormat = '';
                var hasValue = false;
                let isTags = false
                if (currentValues[headerField] !== undefined && currentValues[headerField] !== '') {
                    var thisKey = currentValues[headerField];
                    if (typeof (thisKey) === "object") thisKey = thisKey.key;
                    var found = customFields.find(c => c.id == thisKey)
                    headerText = found ? found.fieldDesc + (found.Application ? ` (${found.Application.appName})` : '') : thisKey;
                    if (found != null) {
                        hasValue = true;
                        if (found.fieldType === "DATE") {
                            isDateSelected = true;
                            currentSelectedFieldId = found.id;
                            currentDateFormat = currentValues[headerField].format;
                        }
                    } else if (thisKey == 'emailAddress') {
                        hasValue = true;
                        headerText = i18n.t('emailAddress');
                    } else if (thisKey == 'SubscriberTags') {
                        hasValue = true;
                        isTags = true
                        headerText = 'Tags';
                    }
                }

                var dateFormatSelect;
                if (hasValue && isDateSelected) {
                    dateFormatSelect = <UILIB.Select outerClassName="mar-b10" label={i18n.t('subscribers:add.import.mapping.selectDateFormat')} data={this.state.dateFormats} value={currentDateFormat} placeholder="Date Format" onChange={ev => this.setDateFormat(headerField, ev.target.value)} />
                }

                console.log(this.state.tagDelimiter)

                return <div key={headerIndex} style={{
                    marginBottom: "0px"
                }} className={"import-panel " + (hasValue ? 'complete' : '')} >
                    <div className="import-field">

                        {hasValue && <div className='quickFlex mar-b25'>
                            <UILIB.Icons icon="tickCircle" className="mar-r10" color="#65B5B1" style={{ height: 24, width: 24 }} />
                            <div style={{ fontSize: 20, fontWeight: 600, lineHeight: '40px' }}>{headerText}</div>
                        </div>}

                        {!hasValue && <UILIB.Select
                            outerClassName="mar-b25"
                            onChange={ev => this.setValue(headerField, ev.target.value, headerIndex)}
                            placeholder={i18n.t('subscribers:add.import.mapping.selectAnExistingField')}
                            data={selectData} />}

                        {!!dateFormatSelect && dateFormatSelect}


                        <div className={isTags ? 'mar-b10' : 'mar-b25'}>
                            {hasValue && <UILIB.Button
                                onClick={() => this.clearSelection(headerField)}
                                text={i18n.t('subscribers:add.import.mapping.selectAnotherField')}
                                className="button-small"
                                iconLeft={<UILIB.Icons icon="arrowsUpDown" />} />
                            }
                            {!hasValue && <UILIB.Button
                                onClick={() => this.addCustomField(headerField, headerIndex)}
                                text={i18n.t('subscribers:add.import.mapping.orAddANewField')}
                                className="button-primary button-small"
                                iconLeft={<UILIB.Icons icon="plus" />} />}
                        </div>

                        {isTags && <UILIB.TextInput outerClassName="mar-b10" label="Tag Delimeter" placeholder="," value={this.state.tagDelimiter} onChange={this.setTagDelimiter.bind(this)} />}

                    </div>

                    <div className={"sample-line header" + (hasValue ? ' pending' : '')}>{headerField}</div>
                    {sampleData.map((sample, index) => {
                        if (sample && sample[headerIndex]) {
                            if (index > 4) return;
                            let value = sample[headerIndex]
                            if (isTags && this.state.tagDelimiter) {
                                value = value.split(this.state.tagDelimiter).map((v, i) => <UILIB.SquareChip className="square-chip-small mar-r5" value={v} key={index + '_' + i} />)
                            }
                            return <div key={index} className={"sample-line" + (hasValue ? ' pending' : '')}>{value}</div>
                        }
                    })}

                </div>
            })
            }
        </>

        var allowSubmit = false;
        for (var prop in currentValues) {
            if (currentValues[prop] === 'emailAddress') allowSubmit = true;
        }

        this.setState({ importTable: results, submitButtonDisabled: !allowSubmit, firstLoad: false });

    }

    determineDateType(sample) {
        var options = this.state.dateFormats;
        var result;
        if (sample) {
            var splitChar = sample.indexOf('/') > -1 ? '/' : sample.indexOf('-') > -1 ? '-' : '.';
            if (sample.indexOf(splitChar) > 2) {
                result = options.find(o => o.indexOf(splitChar) > 2)
            } else {
                result = options.find(o => o.indexOf(splitChar) > -1)
            }
        }

        if (!result) result = options[0]

        return result;
    }

    goBack() {
        this.props.history.push('/cp/groups/groups/' + this.state.groupID + '/add/import')
    }

    addCustomFieldBack(isSuccess, newObject) {

        //Callback when someone has created a custom field
        //returns true/false issuccess, and the return values
        var customFields = this.state.customFields;
        if (isSuccess === true) {
            var currentTarget = this.state.currentOptionNo;
            var fieldName = newObject.fieldName;
            var fieldID = newObject.id;

            customFields[fieldID] = { name: fieldName, selected: currentTarget, customFieldID: fieldID };
            this.setState({ addCustomFieldOpen: false, customFields: customFields, currentOptionNo: -1 }, this.createImportTable);

        }
        else {
            this.setState({ addCustomFieldOpen: false });
        }
    }


    completeImport() {
        if (this.state.app && this.state.app.Application.options.importOnCreateRequired && Array.isArray(this.state.app.Application.options.importOnCreateRequired)) {
            const required = this.state.customFields.filter(cf => this.state.app.Application.options.importOnCreateRequired.indexOf(cf.fieldName) > -1)
            const errors = []
            const selected = []
            for (let prop in this.state.outputData) {
                selected.push(this.state.outputData[prop])
            }
            if (required.length) {
                required.forEach(field => {
                    if (!selected.some(s => s == field.id)) {
                        errors.push(field.fieldDesc + ' required to import into ' + this.state.app.Application.appName)
                    }
                })
            }
            if (errors.length) {
                return this.setState({ errors })
            } else {
                this.setState({ errors })
            }
        }

        var mapping = {};
        for (var prop in this.state.outputData) {
            mapping[prop.replace(/[\u200B-\u200D\uFEFF]/g, '')] = this.state.outputData[prop]
        }
        var self = this;
        axios.post('/group/' + this.state.groupID + "/import/" + this.state.importID + "/fieldBinds", { headerBinds: mapping, tagDelimiter: this.state.tagDelimiter }).then((res) => {
            if (res.data.importStatus == 2) {
                self.props.history.push('/cp/groups/' + self.state.groupID + '/add/import/progress/' + res.data.importID);
            }
        }).catch(console.log);
    }

    render() {
        const showAppWarning = this.state.app && this.state.app.Application.options.importOnCreateRequired && Array.isArray(this.state.app.Application.options.importOnCreateRequired)
        const warnings = []
        if (showAppWarning) {
            this.state.app.Application.options.importOnCreateRequired.forEach(prop => {
                const found = this.state.customFields.find(cf => cf.fieldName === prop)
                if (found) warnings.push(`"${found.fieldDesc}"`)
            })
        }

        let submitButtonDisabled = this.state.submitButtonDisabled;
        if (this.state.errors && this.state.errors.length) {
            submitButtonDisabled = true;
        }

        return <>
            <h4 className="mar-b25">{i18n.t('subscribers:add.import.mapping.selectColumns')}</h4>

            {!!warnings.length && <UILIB.SquareChip className='square-chip square-chip-large square-chip-red square-chip-min-width mar-b25'>
                Records missing {warnings.join(', ')} will not be added to {this.state.app ? this.state.app.Application.appName : ""}
            </UILIB.SquareChip>}

            <UILIB.Paper style={{ width: "100%", overflowX: "auto", overflowY: 'hidden', display: 'flex', padding: "15px" }}>
                {this.state.importTable}
            </UILIB.Paper>

            {!this.state.firstLoad && <div className="quickFlex" style={{ justifyContent: "flex-end", alignItems: "center" }}>
                {this.state.submitButtonDisabled && <span className="mar-r15 text-sml text-heavy text-red">Email Address Required</span>}
                {!!this.state.errors.length && this.state.errors.map((e, i) => <span key={'err' + i} className='mar-r15 text-sml text-heavy text-red'>{e}</span>)}
                <UILIB.Button
                    className="button-primary"
                    text={i18n.t('subscribers:add.import.mapping.import')}
                    name="copyPasteSubs"
                    disabled={submitButtonDisabled}
                    onClick={this.completeImport}
                    iconRight={<UILIB.Icons icon="arrowRight" />}
                />
            </div>}

            {this.state.firstLoad && <UILIB.LoadingIcons iconType="2" />}
        </>
    };
};
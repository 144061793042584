import React, { useState } from 'react'
import UILIB from '../../../../Common-Objects/Lib'
import axios from '../../../../data/http/axios'
import i18n from '../../../../i18n'
import copy from 'copy-to-clipboard';

export default function Enable2fa({ user, reload = () => { } }) {
    const [code, setCode] = useState('')
    const [enabling, setEnabling] = useState(false)
    const [image, setImage] = useState(null)
    const [error, setError] = useState('')
    const [tokens, setTokens] = useState(undefined)
    const [alert, setAlert] = useState({ open: false, message: '' })

    const enable = async () => {
        if (enabling) {
            setCode('')
            setEnabling(false)
        } else {
            const res = await axios.get('/accountUser/2fa')
            setImage(res.data.image)
            setEnabling(true)
        }
    }

    const save = async () => {
        const res = await axios.put('/accountUser/2fa', { twoFaCode: parseInt(code) })
        if (!res.data.success) {
            return setError('Code Incorrect')
        } else {
            setTokens(res.data.tokens)
            setCode('')
        }
    }

    return (
        <UILIB.Modal width="900px" noClickAway={true}>

            {!enabling && <div>
                <div className='mar-b10'>Due to account policy you must have two factor authentication enabled</div>
                {!user.registered && <UILIB.WarningHolder>Please confirm your email address before enabling</UILIB.WarningHolder>}
                {user.registered && <UILIB.Button className="button-sml button-primary" onClick={enable}>Enable</UILIB.Button>}
            </div>}

            {enabling && !tokens && <UILIB.Row>
                <UILIB.Column xs={12} sm={6}>
                    {!image && <UILIB.LoadingIcons iconType="2" />}
                    {!!image && <img src={image} style={{ width: "100%" }} />}
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6}>
                    <h4 className="mar-b25">{i18n.t('account:my.twoFa.scanQrCode')}</h4>
                    <div className='mar-b25' dangerouslySetInnerHTML={{ __html: i18n.t('account:my.twoFa.firstLoad') }}></div>
                    <UILIB.TextInput error={error} outerClassName="mar-b25" value={code} label="2FA Code" onChange={e => setCode(e.currentTarget.value)} onEnterPress={save} placeholder="2FA Code" />
                    <UILIB.Button iconRight={<UILIB.Icons icon="floppydisk" />} text="Save" onClick={save} className="button-primary" />
                </UILIB.Column>
            </UILIB.Row>}
            {!!tokens && <Tokens tokens={tokens} copied={reload} />}

        </UILIB.Modal>
    )
}

function Tokens({ tokens = [], copied = () => { } }) {
    const copyToClipBoard = () => {
        copy(tokens)
    }

    return (
        <>
            <h4 className='mar-b25'>Recovery Tokens</h4>
            <p>Take a note of these recovery tokens</p>
            <p>If you lose access to your authentication app you can use one of these tokens to login to your account</p>
            <p className='mar-b25'>You will only be able to use each token once</p>
            <code className='code code-copy mar-b10' onClick={copyToClipBoard}>
                <div className='code-copy-icon'><UILIB.Icons icon="copyClipboard" /></div>
                {tokens.map(t => <div>{t}</div>)}
            </code>
            <div className='quickFlex' style={{ flexDirection: 'row-reverse' }}>
                <UILIB.Button className="button-sml" onClick={copied}>Close</UILIB.Button>
            </div>
        </>
    )
}
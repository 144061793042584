import React from "react";
import * as RadixDialog from "@radix-ui/react-dialog";
import classnames from "classnames";
import { propToCssModule } from "~/Classes/styles";
import * as styles from "./index.module.scss";

const Dialog = { ...RadixDialog };

const Overlay = React.forwardRef(({ className, ...rest }, forwardedRef) => {
  const classes = classnames(styles.overlay, className);

  return (
    <RadixDialog.Overlay ref={forwardedRef} className={classes} {...rest} />
  );
});

const Content = React.forwardRef(
  ({ fullScreen, size = "r", className, ...rest }, forwardedRef) => {
    const classes = classnames(styles.content, className, {
      [styles.contentFullScreen]: fullScreen,
      [styles[`contentSize${propToCssModule(size)}`]]: size,
    });

    return (
      <RadixDialog.Content ref={forwardedRef} className={classes} {...rest} />
    );
  }
);

Dialog.Overlay = Overlay;
Dialog.Content = Content;

export default Dialog;

import React from 'react'

export default function IconCrossChunky({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.2045 5.2045C5.64384 4.76517 6.35616 4.76517 6.7955 5.2045L10 8.40901L13.2045 5.2045C13.6438 4.76517 14.3562 4.76517 14.7955 5.2045C15.2348 5.64384 15.2348 6.35616 14.7955 6.7955L11.591 10L14.7955 13.2045C15.2348 13.6438 15.2348 14.3562 14.7955 14.7955C14.3562 15.2348 13.6438 15.2348 13.2045 14.7955L10 11.591L6.7955 14.7955C6.35616 15.2348 5.64384 15.2348 5.2045 14.7955C4.76517 14.3562 4.76517 13.6438 5.2045 13.2045L8.40901 10L5.2045 6.7955C4.76517 6.35616 4.76517 5.64384 5.2045 5.2045Z" fill={fillColor} />
        </svg>

    )
}
import React from 'react'
import axios from '~/data/http/axios'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import PermissionChecker from '~/Classes/permissions';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import i18n from '~/i18n'
import IncGoTickTemplates from './incGoTickTemplates'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import SiteVars from '~/Classes/siteVars';
import TemplateOptions from './templateOptions'
import IncGoDeleteTemplate from './incGoDeleteTemplate.jsx';
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, permissionStore: store.permission }
})
export default class TemplatesTableView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            tableData: [],
            loadingData: 1,
            templates: this.props.templates,
            pageSize: 10,
            currentPage: 1,
            deleting: {}

        }
        this.loadTable = this.loadTable.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.countChecked = this.countChecked.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this)
        this.convertToPlainText = this.convertToPlainText.bind(this);
    }

    componentDidMount() {
        this.loadTable()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.templates.length != this.props.templates.length) {
            this.setState({ templates: this.props.templates }, this.loadTable)
        }
    }

    deleteTemplate(id) {
        const theDrawer = <IncGoDeleteTemplate templateId={id} goDelete={() => {
            let deleting = this.state.deleting
            deleting[id] = true
            this.setState({ deleting })
            axios.delete('/template/' + id).then((res) => {
                var deleting = this.state.deleting;
                delete deleting[id]
                this.setState({ deleting, currentPage: 1 })
                this.props.dispatch(siteMaster.alterSiteDrawer(false))
                this.props.getGroups(() => {
                    deleting = this.state.deleting
                    deleting[id] = false
                    this.setState({ deleting })
                });
            });
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true));
    }

    loadTable() {
        var canEdit = PermissionChecker('templates', this.props.permissionStore.permissions, "write");
        this.setState({ loadingData: 1 });
        var theTableData = this.state.templates.map((thisLine, index) => {
            var screenshotUrl = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + thisLine.id + ".jpg?rnd=" + this.props.now
            return {
                id: {
                    value: thisLine.id
                },
                checkBox: {
                    headerValue: canEdit ? <UILIB.CheckBox
                        checked={false}
                        onClick={(event) => {
                            this.checkAll(event)
                        }} /> : "",
                    orderBy: false,
                    value: canEdit ? <UILIB.CheckBox
                        checked={false}
                        onClick={(event) => {
                            this.checkBoxes(event)
                        }}
                        name={"template_" + index} /> : "",
                    isCheckBox: true,
                    width: 30,
                    isChecked: false,
                    order: 0,
                    rowId: thisLine.id
                },
                templateImage: {
                    headerValue: i18n.t('templates:index.preview'),
                    value: <img src={screenshotUrl} style={{ width: "80px", height: "45px" }} />,
                    orderBy: false,
                    width: 80
                },
                templateName: {
                    headerValue: i18n.t('templates:index.templateName'),
                    value: canEdit ? <a onClick={() => { this.props.goEditTemplate(thisLine.id, thisLine.templateType) }}>{thisLine.templateName}</a> : thisLine.templateName,
                    orderBy: false
                },
                public: {
                    headerValue: i18n.t('templates:index.public'),
                    value: <UILIB.SquareChip className={(thisLine.public ? 'square-chip-green' : 'square-chip-red')} for={'template' + thisLine.id} tooltip={thisLine.public ? i18n.t('templates:index.allUsersCan') : i18n.t('templates:index.onlyAdminsCan')}>{thisLine.public ? i18n.t('templates:index.public') : i18n.t('templates:index.private')}</UILIB.SquareChip>,
                },
                createdAt: {
                    headerValue: i18n.t('templates:index.created'),
                    value: DateTimeFunctions.formatDateTime(thisLine.dateAdded, 2),
                    orderBy: false
                },
                updatedAt: {
                    headerValue: i18n.t('templates:index.updated'),
                    value: DateTimeFunctions.formatDateTime(thisLine.lastUpdated, 2),
                    orderBy: false
                },
                optionsCol:
                {
                    headerValue: " ",
                    value: canEdit ? <UILIB.OptionsDropdown popWidth="150px">
                        <TemplateOptions deleted={this.props.deleted} template={thisLine} restoreDeleted={this.props.restoreDeleted} goEditTemplate={this.props.goEditTemplate} openEmailPreview={this.props.openEmailPreview} openSpamTest={this.props.openSpamTest} exportHtml={this.props.exportHtml} exportPdf={this.props.exportPdf} goChangeGroup={this.props.goChangeGroup} inviteUsers={this.props.inviteUsers} duplicate={this.props.duplicate} goDeleteTemplate={this.deleteTemplate} togglePublic={this.props.togglePublic} convertToPlainText={this.convertToPlainText} share={this.props.share} sendAsCampaign={this.props.sendAsCampaign} />
                    </UILIB.OptionsDropdown> : <span></span>,
                    orderBy: false,
                    fixed: true,
                    width: 20
                }
            }
        })
        this.setState({ tableData: theTableData, loadingData: 0 });
    }

    //checkBox Checking
    checkAll(event, forcedUntick) {
        var isChecked;
        if (event) {
            isChecked = event.target.checked;
        }
        if (forcedUntick && forcedUntick === true) {
            isChecked = false;
        }
        //create a copy of the array with object.extend
        var newState = Object.assign([], this.state.tableData);
        for (var x in Object.keys(newState)) {
            var thisProp = newState[x];
            var checkBox = React.cloneElement(thisProp.checkBox.value, { checked: isChecked });
            var headerValue = React.cloneElement(thisProp.checkBox.headerValue, { checked: isChecked });
            thisProp.checkBox.isChecked = isChecked;
            thisProp.checkBox.value = checkBox;
            thisProp.checkBox.headerValue = headerValue;
            newState[x] = thisProp;
        }
        //write it back to state
        this.setState({
            tableData: newState
        }, this.countChecked);
    }

    checkBoxes(event) {
        var thisDataID = event.target.name.replace('template_', '');
        var isChecked = event.target.checked;
        var newState = this.state.tableData;
        var checkBox = React.cloneElement(newState[thisDataID].checkBox.value, { checked: isChecked });
        newState[thisDataID].checkBox.isChecked = isChecked;
        newState[thisDataID].checkBox.value = checkBox;
        this.setState({
            templateData: newState
        }, this.countChecked);
    }
    countChecked() {
        // var opts = this.state.drawerOptions;

        var tickedTemplates = this.state.tableData.filter(template => template.checkBox.isChecked == true);
        var totTicked = tickedTemplates.length;
        var showModal = false;
        if (totTicked > 0) {
            showModal = true;
        }
        var isOpen = false;
        var drawerContent = <div></div>;
        if (totTicked > 0) {
            isOpen = true;
            drawerContent = <IncGoTickTemplates groupId={this.props.groupId}
                tickedTemplates={tickedTemplates}
                totTicked={totTicked}
                reloadAfterTickedAndChanged={this.props.getGroups}
                onDelete={ids => {
                    let obj = {}
                    ids.forEach(i => obj[i] = true)
                    this.setState({ deleting: obj })
                }} />
        }
        this.props.dispatch(siteMaster.alterSiteDrawer(isOpen, true, "right", drawerContent, true, undefined, "", () => {
            this.checkAll(undefined, true)
        }));
    }

    convertToPlainText(template) {
        var self = this;
        axios.post('/template/createPlainText/' + template.id).then((res) => {
            var newTemplate = res.data.Template;
            self.props.history.push(`/cp/templates/add/wysiwyg/${newTemplate.id}`)
        });
    }

    render() {

        return <div>
            <UILIB.Row margin={0}>
                <UILIB.Column xs={12} sm={12} margin={0}>
                    <UILIB.Paper>

                        <UILIB.DataTable1
                            className="with-image"
                            noResultsTxt={<span>{i18n.t('templates:index.noTemplates')}</span>}
                            tableData={this.state.tableData}
                            loadingData={this.state.loadingData}
                            deleting={this.state.deleting}
                            width="100%"
                            pageSize={this.state.pageSize}
                            hasCheckBoxes={PermissionChecker('templates', this.props.permissionStore.permissions, "write") ? "multi" : false}
                        />

                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
            {this.props.totalTemplates > this.props.pageSize && <UILIB.Row margin={0}>
                <UILIB.Column xs={12} className="center-xs mar-b25" margin={0}>
                    <UILIB.PagingBlock
                        totalRows={this.props.totalTemplates}
                        pageSize={this.props.pageSize}
                        numberOfLinks="10"
                        currentPage={this.props.currentPage}
                        changePage={this.props.changePage}
                        text={i18n.t('page') + ":"} />
                </UILIB.Column>
            </UILIB.Row>}

        </div>
    }
}
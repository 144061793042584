import axios from '~/data/http/axios';
import React, { Component } from 'react';
import { DropTarget, DragSource } from 'react-dnd'
import ItemTypes from './dndTypes'
import moment from 'moment';
import NumberFunctions from '~/Classes/numberFormatFunctions';
const dragSource = {
    beginDrag(props, monitor) {
        return {
            domainId: props.domain.id,
            currentGroupId: props.group.id,
            domainObject: props.domain
        }
    }
}


@DragSource(ItemTypes.DOMAIN, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview()
}))

class Domain extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    componentDidMount() {

    }
    render() {
        const { canDrop, isOver, connectDropTarget, connectDragSource, isDragging, moveElement, connectDragPreview } = this.props

        var domain = this.props.domain;
        var bestWeekTxt = "No Stats Yet";
        var bestWeekDiff = -1;
        var bestWeekDiffPct = -1;
        if (this.props.stats && this.props.stats.length) {
            var weekNowNo = Number(moment().format('w')) - 1;
            var weekNow = this.props.stats.find(s => Number(s.sentWeek) == weekNowNo);
            if (!weekNow) {
                weekNow = this.props.stats[this.props.stats.length - 1]
                weekNowNo = weekNow.sentWeek;
            }
            var bestWeek = weekNow;

            this.props.stats.forEach(stat => {
                if ((stat.sentWeek != weekNowNo) && stat.pctOpened && Number(stat.pctOpened) > bestWeek.pctOpened) {
                    bestWeek = stat
                }
            })

            if (bestWeek.sentWeek > -1) {
                bestWeekDiff = weekNowNo - Number(bestWeek.sentWeek)
                if (bestWeekDiff == 0) {
                    bestWeekTxt = "Open rate: " + NumberFunctions.formatNumber(weekNow.pctOpened, 2) + "% now";
                }
                else {
                    bestWeekTxt = "Open rate: " + NumberFunctions.formatNumber(bestWeek.pctOpened, 2) + "%  " + bestWeekDiff + " weeks ago. ";
                    bestWeekTxt += NumberFunctions.formatNumber(weekNow.pctOpened, 2) + "% now";
                    bestWeekDiffPct = bestWeek.pctOpened - weekNow.pctOpened;
                }
            }

        }


        var bestWeekColor = "grey";
        if (bestWeekDiffPct > -1 && bestWeekDiff > -1) {
            if (bestWeekDiffPct < 2 || bestWeekDiff == 0) {
                bestWeekColor = "green"
            }
            else {
                if (bestWeekDiffPct < 5) bestWeekColor = "yellow"
                if (bestWeekDiffPct >= 5 && bestWeekDiffPct < 10) bestWeekColor = "orange";
                if (bestWeekDiffPct >= 10) bestWeekColor = "red";
            }
        }

        var isLive = 0;
        if (this.props.stage && this.props.group.liveDomains.indexOf(domain.id) > -1) {
            isLive = 1;
        }


        var style = {
            fontSize: "11px", padding: "10px", display: "inline-block", border: "1px solid grey", borderRadius: "5px", flex: "0 0 190px", margin: "5px 5px", width: "180px", wordBreak: "break-all", height: "50px", cursor: "pointer",
            position: "relative"
        }
        if (this.props.style) {
            if (this.props.style.width) {
                style.width = this.props.style.width
                style.flex = "0 0 " + this.props.width
            }
        }
        if (isLive) {
            style.border = "3px solid green"
        }

        return connectDragPreview(connectDragSource(<div style={style}>
            {this.props.stage && isLive == 1 && <div style={{ position: "absolute", top: "-6px", right: "0px", width: "10px", height: "10px", backgroundColor: "green", borderRadius: "100px" }} onClick={(event) => { this.props.toggleActive(event, 'domain', domain, this.props.group) }}></div>}
            {this.props.stage && isLive == 0 && <div style={{ position: "absolute", top: "-4px", right: "2px", width: "10px", height: "10px", backgroundColor: "grey", borderRadius: "100px" }} onClick={(event) => { this.props.toggleActive(event, 'domain', domain, this.props.group) }}></div>}

            <div style={{ backgroundColor: bestWeekColor, position: "absolute", right: "5px", bottom: "5px", borderRadius: "100px", width: "10px", height: "10px" }} alt={bestWeekTxt} title={bestWeekTxt} />
            <div>{domain.domainName}</div>
        </div >))
    }
}
export default Domain;
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class Templatecard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {



        return <UILIB.Paper style={this.props.style}>
            <div style={{ display: 'flex', overflow: 'hidden', height: '100%' }}>
                <div className="mar-r20">{this.props.icon}</div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <h4 className="mar-b5">{this.props.title}</h4>
                    <div>{this.props.content}</div>
                    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start', marginTop: 10 }}>
                        {this.props.button}
                    </div>
                </div>
            </div>
        </UILIB.Paper>

    }
}

import React, { Component } from 'react';

class Draggable extends Component {
    constructor(props) {
        super(props)
        this.state = {}

        this.dragArea = React.createRef();

        this.handleDragOver = this.handleDragOver.bind(this)
        this.handleDrop = this.handleDrop.bind(this)
    }

    componentDidMount() {
        let el = this.dragArea.current
        el.addEventListener('dragover', this.handleDragOver)
        el.addEventListener('drop', this.handleDrop)
    }

    handleDragOver(ev) {
        ev.preventDefault();
    }

    handleDrop(ev) {
        if (!ev.dataTransfer.files.length) return ev.preventDefault();
        if (this.props.onAdd && typeof this.props.onAdd === 'function') {
            let files = ev.dataTransfer.files
            ev.preventDefault();
            this.props.onAdd(files)
        } else if (this.props.onDrop && typeof this.props.onDrop === 'function') {
            this.props.onDrop(ev, true)
        } else {
            ev.preventDefault();
        }
    }

    handleDragEnter(ev) {
        ev.preventDefault();
    }

    handleDragLeave(ev) {
        ev.preventDefault();
    }

    render() {
        return (
            <div
                ref={this.dragArea}
                {...this.props}>
                {this.props.children}
            </div>
        );
    }
}

export default Draggable;
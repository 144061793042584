// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oial_LcJ6fL_ZG7KUNOg{display:flex;flex-direction:column;gap:32px}.U66EDYAf58BeLxii8KzB{align-self:flex-start}.wo1r0yWbxzm6_0edUrQC{display:flex;align-items:center;justify-content:space-between;align-self:stretch;gap:32px}.iiIIbmzIh1y1tmK3icR0{display:flex;align-items:center;gap:16px}.DAsuzX24SATNR56xROLA{font-size:13px;font-weight:500;margin-bottom:4px}.egr6lMFgA2Kwfojbtmyg{width:100%;display:flex;padding:24px;flex-direction:column;align-items:flex-start;gap:16px;border-radius:8px;border:1px solid var(--grey-200, #E5E9EE)}.cp4UUFqCRIKJPP_iMZV8{font-size:16px;font-weight:500}.s_8DxohraiSWtXWSCSc3{width:100%}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/aiAssistant/settings/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,sBACE,qBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CAIF,sBACE,UAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,QAAA,CACA,iBAAA,CACA,yCAAA,CAGF,sBACE,cAAA,CACA,eAAA,CAGF,sBACE,UAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n}\n\n.submitButton {\n  align-self: flex-start;\n}\n\n.imageUploadWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  align-self: stretch;\n  gap: 32px;\n}\n\n.imageUpload {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.manualLabel {\n  font-size: 13px;\n  font-weight: 500;\n  margin-bottom: 4px;\n}\n\n\n.teaser {\n  width: 100%;\n  display: flex;\n  padding: 24px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 16px;\n  border-radius: 8px;\n  border: 1px solid var(--grey-200, #E5E9EE);\n}\n\n.teaser_header {\n  font-size: 16px;\n  font-weight: 500;\n}\n\n.textarea {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "oial_LcJ6fL_ZG7KUNOg",
	"submitButton": "U66EDYAf58BeLxii8KzB",
	"imageUploadWrapper": "wo1r0yWbxzm6_0edUrQC",
	"imageUpload": "iiIIbmzIh1y1tmK3icR0",
	"manualLabel": "DAsuzX24SATNR56xROLA",
	"teaser": "egr6lMFgA2Kwfojbtmyg",
	"teaser_header": "cp4UUFqCRIKJPP_iMZV8",
	"textarea": "s_8DxohraiSWtXWSCSc3"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here

import AddGroupView from '~/pages/cp/subscribers/groups/incGoAddGroup';
import ChangeGroupConfirm from './incChangeGroupConfirm';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class CapsuleConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactsGroupId: -1,
            groupData: [],
            isLoaded: false
        };

        this.changeGroup = this.changeGroup.bind(this);
        this.addAGroup = this.addAGroup.bind(this);
        this.addedGroup = this.addedGroup.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.changeGroupConfirm = this.changeGroupConfirm.bind(this);
        this.changeTheGroup = this.changeTheGroup.bind(this);
    }

    componentDidMount() {
        this.getGroups();
    }

    getGroups() {
        var self = this;
        axios.get("/group").then(_theGroups => {
            self.setState({ groupData: _theGroups.data.Groups, isLoaded: true })
        })
    }

    changeGroup(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    addAGroup(contactsType) {
        var drawerContent = <AddGroupView addedGroup={(event) => { this.addedGroup(event, contactsType) }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    addedGroup(event, contactsType) {
        var groupId = event.data.groupId;
        this.setState({ [contactsType]: groupId }, this.getGroups());
    }


    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    changeGroupConfirm() {
        var drawerContent = <ChangeGroupConfirm changeTheGroup={this.changeTheGroup} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    changeTheGroup() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
        this.props.updateGroup(this.state.contactsGroupId);
    }


    render() {

        if (this.state.isLoaded == false) return <div></div>

        var groupData = [];
        groupData = this.state.groupData.map(theRow => {
            return { label: theRow.groupName, value: Number(theRow.id) }
        });
        groupData.unshift({ label: "Select a Group", value: -1 });

        return <div>
            <UILIB.Row>
                <UILIB.Column xs={12} margin={0}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">Select a Group to Sync With</h4>
                        <p>Please select an existing Transpond group, or create a new one, where you would like contacts from TrustPilot to be created.</p>
                        {groupData.length &&
                            <div className="quickFlex">
                                <div className="form-group mar-b0"><UILIB.Select data={groupData} name="contactsGroupId" value={this.state.contactsGroupId} onChange={this.changeGroup} /></div>
                                <span style={{ marginLeft: "10px" }}>or</span>
                                <UILIB.Button text="Add a new Group" className="button-sml secondary outline" style={{ marginLeft: "10px" }} onClick={() => this.addAGroup("contactsGroupId")} />
                            </div>
                        }
                    </UILIB.Paper>
                </UILIB.Column>

                {this.state.contactsGroupId && this.state.contactsGroupId != -1 &&
                    <UILIB.Column xs={12} className="center-xs">
                        <UILIB.Button text="Save and Continue" onClick={this.changeGroupConfirm} />
                    </UILIB.Column>
                }
            </UILIB.Row>

        </div >

    };


};



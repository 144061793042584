import React from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import i18n from '~/i18n'

export default class AddEvent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            eventName: "",
            error: ""
        }
        this.save = this.save.bind(this);
    }

    componentDidMount() {

    }

    async save() {
        if (this.state.eventName.length < 4) {
            return this.setState({ error: "Please enter a longer event name" })
        }
        try {
            await axios.post('/accountMaster/tracker/events', { name: this.state.eventName })
            this.props.close();
        }
        catch (err) {
            let error = "There was a problem adding this event type, please try again"
            if (err && err.data && err.data.error) error = err.data.error;
            this.setState({ error })
        }
    }

    render() {
        return <>
            <h4 className="mar-b10">Add a new Event</h4>
            <div className="mar-b25">Please enter a name for your new event type</div>
            <UILIB.TextInput error={this.state.error} className="mar-b25" placeholder="Event name; ie Registration" value={this.state.eventName} onChange={(ev) => { this.setState({ eventName: ev.target.value }) }} />
            <UILIB.Button text="Continue" iconRight={<UILIB.Icons icon="arrowRight" />} disabled={this.state.eventName.length < 4} onClick={this.save} />
        </>
    }
}

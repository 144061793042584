import React from 'react'

export default class IconsLineArrowDown extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.29303 7.29308C5.48056 7.10561 5.73487 7.00029 6.00003 7.00029C6.26519 7.00029 6.5195 7.10561 6.70703 7.29308L10 10.5861L13.293 7.29308C13.3853 7.19757 13.4956 7.12139 13.6176 7.06898C13.7396 7.01657 13.8709 6.98898 14.0036 6.98783C14.1364 6.98668 14.2681 7.01198 14.391 7.06226C14.5139 7.11254 14.6255 7.18679 14.7194 7.28069C14.8133 7.37458 14.8876 7.48623 14.9379 7.60913C14.9881 7.73202 15.0134 7.8637 15.0123 7.99648C15.0111 8.12926 14.9835 8.26048 14.9311 8.38249C14.8787 8.50449 14.8025 8.61483 14.707 8.70708L10.707 12.7071C10.5195 12.8946 10.2652 12.9999 10 12.9999C9.73487 12.9999 9.48056 12.8946 9.29303 12.7071L5.29303 8.70708C5.10556 8.51955 5.00024 8.26525 5.00024 8.00008C5.00024 7.73492 5.10556 7.48061 5.29303 7.29308V7.29308Z" fill={color} />
        </svg>





    }
}        
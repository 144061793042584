import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import axios from '~/data/http/axios';
import ComponentSelect from './select'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class RssEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rssFields: [],
            rssContent: [],
            rssOk: false,
            refreshing: false,
            errors: {
                rssUrl: "",
                linkText: ""
            },
            changedRssUrl: false
        }

        this.changeRSSVal = this.changeRSSVal.bind(this);
        this.generateRss = this.generateRss.bind(this);
        this.selectRssItem = this.selectRssItem.bind(this);
        this.selectRssField = this.selectRssField.bind(this);
        this.updateField = this.updateField.bind(this);
    }

    componentDidMount() {

        if (this.props.localVariables && this.props.localVariables.rssFeed.value.rssItems && this.props.localVariables.rssFeed.value.rssItems.length) {
            console.log("RSS OK");
            this.setState({ rssOk: true })
        }
    }

    componentWillUnmount() {
    }

    changeRSSVal(property, value) {
        this.setState({ changedRssUrl: true })
        this.props.updateVal("localVariables.rssFeed.value", value, property);
    }
    updateField(fieldName, value) {
        this.props.updateVal("localVariables." + fieldName, value);
    }

    generateRss() {
        this.setState({ refreshing: true })
        var url = this.props.localVariables.rssFeed.value.rssUrl;
        var errors = {
            rssUrl: ""
        };
        if (!url || !url.length || (url.indexOf('https://') < 0 && url.indexOf('http://') < 0)) {
            errors.rssUrl = "Invalid RSS Feed URL"
            this.props.updateVal("localVariables.rssFeed.value", [], "rssItems");
            this.props.updateVal("localVariables.rssFeed.value", [], "rssFields");

            this.setState({
                errors,
                refreshing: false
            })

            return;
        }
        url = url.trim();

        this.props.updateVal("localVariables.rssFeed.value", url, "rssUrl");
        axios.get(`/api/rss?url=${url}`).then(_results => {
            var fields = [];
            var items = _results.data.items.map((item, index) => {
                if (index < 5) {
                    item.checked = true;
                }

                if (item.author && fields.indexOf("author") < 0) fields.push("author")
                if (item.category && fields.indexOf("category") < 0) fields.push("category")
                if (item.content && fields.indexOf("content") < 0) fields.push("content")
                if (item.created && fields.indexOf("created") < 0) fields.push("created")
                if (item.description && fields.indexOf("description") < 0) fields.push("description")
                if (item.media && fields.indexOf("media") < 0) fields.push("media")
                if (item.title && fields.indexOf("title") < 0) fields.push("title")
                if (item.url && fields.indexOf("url") < 0) fields.push("url")
                if (item["propertyfields:image"] && fields.indexOf("media") < 0) fields.push("media")

                return item;
            })

            if (this.state.changedRssUrl) {
                fields = fields.map(field => {
                    field = { value: field }
                    if (field.value == "title") field.checked = true;
                    if (field.value == "media") field.checked = true;
                    if (field.value == "description") field.checked = true;

                    return field;
                })
                this.props.updateVal("localVariables.rssFeed.value", fields, "rssFields");
            }


            this.props.updateVal("localVariables.rssFeed.value", items, "rssItems");

            this.setState({ errors, rssOk: true, refreshing: false, changedRssUrl: false })

        }).catch(err => {
            var error = "Invalid RSS Url or another problem occured"
            errors.rssUrl = error;
            this.setState({ errors, rssOk: false, refreshing: false, changedRssUrl: false })
        })
    }

    selectRssItem(checked, itemId) {
        var items = this.props.localVariables.rssFeed.value.rssItems;
        items[itemId].checked = checked;
        this.props.updateVal("localVariables.rssFeed.value", items, "rssItems");
    }
    selectRssField(checked, itemId) {
        var fields = this.props.localVariables.rssFeed.value.rssFields;
        fields[itemId].checked = checked;
        this.props.updateVal("localVariables.rssFeed.value", fields, "rssFields");
    }


    render() {

        var rssUrl = this.props.localVariables.rssFeed.value.rssUrl;
        var rssUrlValid = false;
        var style = { flex: "100%" }

        var items = [];
        var rssFields = []
        var feedType = 0;
        var offset = 0;

        var autoFeedItemData = [];
        var autoFeedItemCount = 1;
        var autoFeedInitialItemCount = 1;
        var x = 1;
        while (x < 30) {
            autoFeedItemData.push({ label: x + " Items", value: x })
            x++;
        }
        let autoFeedInitialItemData = JSON.parse(JSON.stringify(autoFeedItemData))
        autoFeedItemData.push({ label: "Any new content since the last send", value: -9999 })
        if (this.state.rssOk) {
            items = this.props.localVariables.rssFeed.value.rssItems;
            rssFields = this.props.localVariables.rssFeed.value.rssFields;
            feedType = this.props.localVariables.rssFeed.value.rssFeedType;
            autoFeedItemCount = this.props.localVariables.rssFeed.value.autoFeedItemCount;
            offset = Number(this.props.localVariables.rssFeed.value.offset) || 0;
            autoFeedInitialItemCount = this.props.localVariables.rssFeed.value.autoFeedInitialItemCount || 1;

            if (!items) items = [];
            if (!rssFields) rssFields = [];
            if (!feedType) feedType = 0;
            if (!autoFeedItemCount) autoFeedItemCount = 0;
            if (!autoFeedInitialItemCount) autoFeedInitialItemCount = 0;
        }

        var hasLinkSelected = rssFields.find(field => field.value == "url" && field.checked)

        var linkText = this.props.localVariables.linkText.value;
        return <div style={style}>
            <div className="dd_toolHolder mar-b15" style={style}>
                <div className="inputWrapper" style={{ display: "flex", width: "100%", alignItems: "flex-start" }}>

                    <UILIB.TextInput
                        placeHolder="RSS Feed Url"
                        outerStyle={{ flex: "1" }}
                        outerClassName="mar-r10"
                        value={rssUrl}
                        name="rssUrl"
                        onChange={(event) => { this.changeRSSVal("rssUrl", event.target.value) }}
                        error={this.state.errors.rssUrl.length > 0 ? this.state.errors.rssUrl : ""}
                        iconRight={this.state.refreshing ? <UILIB.LoadingIcons iconType="2" style={{ height: "20px", width: "20px" }} /> : ""}
                    />

                    <UILIB.Button disabled={this.state.refreshing} text={this.state.rssOk ? "Refresh" : "Save"} className="button-primary" onClick={this.generateRss} />


                </div>
            </div>

            {this.state.rssOk && <div>

                <ComponentSelect error={""} value={feedType} title={"Feed Type"} options={[{ label: "Static", value: 0 }, { label: "Dynamic", value: 1 }]} updateVal={(path, value) => { this.changeRSSVal("rssFeedType", value) }} path={""} elementData={[]} localVariables={{}} />

                {(feedType == 1) && <ComponentSelect
                    error={""}
                    value={autoFeedItemCount}
                    title={"Items to Display"}
                    options={autoFeedItemData}
                    updateVal={(path, value) => { this.changeRSSVal("autoFeedItemCount", value) }}
                    path={""}
                    elementData={[]}
                    localVariables={{}} />}
                {(feedType == 1 && autoFeedItemCount > -9999) && <div className="dd_toolHolder" style={style}>
                    <div className="dd_inputTool">
                        <UILIB.TextInput label="Item Offset" value={offset} type="number" name="rssUrl" onChange={(event) => { this.changeRSSVal("offset", event.target.value) }} placeHolder="Offset" />
                    </div>
                </div>}
                {(feedType == 1 && autoFeedItemCount == -9999) && <div className="dd_toolHolder" style={style}>
                    <div className="dd_inputTool">
                        <ComponentSelect
                            error={""}
                            value={autoFeedInitialItemCount}
                            title={"Items to Display on First Send"}
                            options={autoFeedInitialItemData}
                            updateVal={(path, value) => { this.changeRSSVal("autoFeedInitialItemCount", value) }}
                            path={""}
                            elementData={[]}
                            localVariables={{}} />
                    </div>
                </div>}

                {(feedType == 0) && <div>
                    <hr />
                    <div className="dd_toolHolder" >

                        <div className="txtInput_label" style={{ flex: "1" }}>Articles to Include</div>

                    </div>
                    <div className="mar-b10" style={{ marginLeft: "20px", border: "1px solid grey", height: "100%", maxHeight: "200px", overflow: "auto", padding: "10px" }}>
                        {items.map((item, index) => {
                            return <div key={"rssItem" + index} style={{ display: "flex" }} className="mar-b5">
                                <UILIB.CheckBox checked={item.checked} onClick={(event) => { this.selectRssItem(event.target.checked, index) }} className="mar-r10" />
                                <div>{item.title}</div>
                            </div>

                        })}
                    </div>
                </div>}

                <hr />
                <div className="dd_toolHolder" >

                    <div className="txtInput_label" style={{ flex: "1" }}>Content to Display</div>

                </div>
                <div className="mar-b10" style={{ marginLeft: "20px", border: "1px solid grey", height: "100%", maxHeight: "200px", overflow: "auto", padding: "10px" }}>
                    {rssFields.map((field, index) => {
                        return <div key={"rssField" + index} style={{ display: "flex" }} className="mar-b5">
                            <UILIB.CheckBox checked={field.checked} onClick={(event) => { this.selectRssField(event.target.checked, index) }} className="mar-r10" />
                            <div style={{ textTransform: "capitalize" }}>{field.value}</div>
                        </div>

                    })}
                </div>

                {hasLinkSelected && <div>
                    <hr />
                    <div className="dd_inputTool">

                        <div className="mar-r5" style={{ width: "100%" }}>
                            <UILIB.TextInput label="Link Text" value={linkText} name="linkText" onChange={(event) => { this.updateField("linkText", event.target.value) }} placeHolder="Link Text" />
                            {(this.state.errors.linkText) && <div style={{ fontSize: "12px", color: "red", flex: "1" }}>{this.state.errors.linkText}</div>}
                        </div>

                    </div>
                </div>}

            </div>
            }



        </div>


    }
}



import React, { useState } from "react";
import UILIB from "~/Common-Objects/Lib";

export default function FileSettings({ formFile, onSave }) {
  const [limit, setLimit] = useState(formFile.settings?.limit || "");
  const [expiry, setExpiry] = useState(formFile.settings?.expiry || 10);
  const [expiryUnit, setExpiryUnit] = useState(
    formFile.settings?.expiryUnit || "hours"
  );

  function save() {
    if (limit === "timed") return onSave({ limit, expiry, expiryUnit });
    onSave({ limit });
  }

  return (
    <>
      <h2 className="h4 mar-b15">File Settings</h2>

      <p className="mar-b25">
        Set if the file should only be allowed to be downloaded once per contact
        or only within a certain timeframe after the first download.
      </p>

      <UILIB.Select
        outerClassName="mar-b25"
        data={[
          { label: "No Limits", value: "" },
          { label: "Download Once", value: "once" },
          { label: "Time Limit", value: "timed" },
        ]}
        value={limit}
        onChange={(e) => setLimit(e.target.value)}
      />

      {limit === "timed" && (
        <div className="row mar-b25">
          <div className="col-xs-6">
            <UILIB.TextInput
              type="number"
              value={expiry}
              onChange={(e) => setExpiry(parseInt(e.target.value))}
            />
          </div>

          <div className="col-xs-6">
            <UILIB.Select
              data={[
                { label: "Hours", value: "hours" },
                { label: "Days", value: "days" },
                { label: "Months", value: "months" },
              ]}
              value={expiryUnit}
              onChange={(e) => setExpiryUnit(e.target.value)}
            />
          </div>
        </div>
      )}

      {limit === "timed" && (
        <UILIB.WarningHolder className="mar-b25">
          Once a contact has downloded this file the first time the link will
          work for {expiry} {expiryUnit}
        </UILIB.WarningHolder>
      )}

      {limit === "once" && (
        <UILIB.WarningHolder className="mar-b25">
          Each contact will only be able to download this file once from this
          form
        </UILIB.WarningHolder>
      )}

      <UILIB.Button className="button-primary" onClick={save}>
        Save
      </UILIB.Button>
    </>
  );
}

import React from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';
import { logoutAndClear } from '../../../data/actions/userActions';

@connect((store) => {
    return { user: store.user }
})
export default class CloseAccountDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            passwordErr: "",
            feedback: "",
            closed: false,
            loading: false,
            reasonSelect: ""
        };

        this.goClose = this.goClose.bind(this);
    }

    componentDidMount() {

    }

    goClose() {
        if (!this.state.password || this.state.password.length < 5) {
            this.setState({ passwordErr: i18n.t('company:my.closeAccount.incorrectPassword') })
            return
        }
        this.setState({ passwordErr: "", loading: true });
        axios.post("/accountmaster/closeaccount", { password: this.state.password, feedback: this.state.feedback, reasonSelect: this.state.reasonSelect }).then(res => {
            this.setState({ closed: true, loading: false })
        }).catch(err => {
            let error = i18n.t('company:my.closeAccount.incorrectPassword')
            this.setState({ passwordErr: error, loading: false });
        })
    }

    continue() {
        this.props.dispatch(logoutAndClear())
    }

    render() {
        let offboardReasons = [
            { label: i18n.t('company:my.closeAccount.feedBackOptions.tooComplicated'), value: "tooComplicated" },
            { label: i18n.t('company:my.closeAccount.feedBackOptions.tooExpensive'), value: "tooExpensive" },
            { label: i18n.t('company:my.closeAccount.feedBackOptions.featuresMissing'), value: "featuresMissing" },
            { label: i18n.t('company:my.closeAccount.feedBackOptions.temporary'), value: "temporary" },
            { label: i18n.t('company:my.closeAccount.feedBackOptions.somethingElse'), value: "somethingElse" },
        ]
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        if (this.state.closed) return <div>
            <h4 className="mar-b25">{i18n.t('company:my.closeAccount.closedHeader')}</h4>
            <div className="mar-b25">{i18n.t('company:my.closeAccount.closedDesc1')}</div>

            <UILIB.Button text={i18n.t('company:my.closeAccount.closedButt')} onClick={this.continue.bind(this)} />
        </div>
        return <div>
            <h4 className="mar-b25">{i18n.t('company:my.closeAccount.header')}</h4>
            <div className="mar-b25">{i18n.t('company:my.closeAccount.desc1')}</div>
            <div className="mar-b25">{i18n.t('company:my.closeAccount.desc2')}</div>

            <UILIB.Select label={i18n.t('company:my.closeAccount.whyAreYouClosing')} name="reasonSelect" value={this.state.reasonSelect} data={offboardReasons}
                onChange={(ev) => { this.setState({ reasonSelect: ev.target.value }) }}
                outerClassName="mar-b25" />

            {this.state.reasonSelect && <div>

                <UILIB.TextArea outerClassName="mar-b25" label={i18n.t('company:my.closeAccount.feedbackHeader')} name="feedback" style={{ height: "150px" }} placeholder={i18n.t('company:my.closeAccount.feedbackHeader')} value={this.state.feedback} onChange={(event) => { this.setState({ feedback: event.target.value }) }} />
                <UILIB.TextInput label={i18n.t('company:my.closeAccount.instructions')} outerClassName="mar-b25" name="password" type="password" placeholder={i18n.t('company:my.closeAccount.password')} value={this.state.password} error={this.state.passwordErr} onChange={(event) => { this.setState({ password: event.target.value }) }} />

                <UILIB.Button text={i18n.t('company:my.closeAccount.closeMyAccount')} className="button-red" onClick={this.goClose} />
            </div>
            }
        </div>

    };
};

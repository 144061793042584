import React from 'react'
import axios from '~/data/http/axios';
import rawAxios from 'axios'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import Industries from '../../../../../Classes/industries';
import TranspondSiteTracking from '../../../../../Classes/siteTrackingHelpers';
@connect((store) => {
    return { user: store.user, browser: store.browser, accountMaster: store.accountMaster, siteMaster: store.siteMaster, permissionStore: store.permission }
})
export default class NewUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formCompleteStatus: 0,
            countryData: [],
            hasVatError: false,
            selectedAddress: false,
            isCapsule: false,
            errors: {
                question1: "",
                question2: "",
                question3: "",
                question4: "",
                question5: "",
                accountType: "",
                address1: "",
                address2: "",
                address3: "",
                city: "",
                county: "",
                country: "",
                postCode: "",
                vatNumber: '',
                addressLookup: ""
            },
            answers:
            {
                question1: "",
                question2: "",
                question3: "",
                question4: "",
                question5: "",
                accountType: "",
                address1: this.props.accountMaster.accountMaster.address1 || '',
                address2: this.props.accountMaster.accountMaster.address2 || '',
                address3: this.props.accountMaster.accountMaster.address3 || '',
                city: this.props.accountMaster.accountMaster.city || '',
                county: this.props.accountMaster.accountMaster.county || '',
                country: this.props.accountMaster.accountMaster.country || '',
                postCode: this.props.accountMaster.accountMaster.postCode || '',
                vatNumber: '',
                companyId: '',
                addressLookup: ""
            }
        }

        this.updateVal = this.updateVal.bind(this);
        this.checkFormStep1 = this.checkFormStep1.bind(this);
        this.checkFormStep2 = this.checkFormStep2.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.changeAddress = this.changeAddress.bind(this);
    }

    async componentDidMount() {
        TranspondSiteTracking.pageChange(this.props.user.userData.emailAddress, { "popup.questions": true })
        let countryDat = await axios.get('/countries');
        countryDat = countryDat.data;

        let apps = await axios.get('/accountMaster/application')
        apps = apps.data.Applications

        let isCapsule = apps.find(a => a.ApplicationId === 8)
        if (isCapsule) isCapsule = true;
        this.setState({
            countryData: countryDat.Countries.map(thisLine => ({ label: thisLine.name, value: thisLine.name, currency: thisLine.currency, code: thisLine.code, isEU: thisLine.isEU })),
            isCapsule
        }, () => {
            if (!this.state.answers.country) {
                this.getLocation()
            }
        });
    }


    getLocation() {
        rawAxios({
            url: "https://ipinfo.io?token=c4ac161f272f10",
            json: true
        }).then(response => {
            const country = this.state.countryData.find(c => c.code === response.data.country)
            if (!country) return
            const answers = this.state.answers
            answers.country = country.value
            this.setState({ answers })
        }).catch(() => { })
    }

    updateVal(event) {
        var theVal = event.target.value;
        var theName = event.target.name;
        var answers = this.state.answers;
        answers[theName] = theVal;

        this.setState({ answers: answers })
    }

    checkFormStep1() {
        var answers = this.state.answers;
        var errors = { question1: "", question2: "", question3: "", question4: "", question5: "" }
        var formStatus = 1;
        if (!this.state.isCapsule && (answers.question1 === undefined || answers.question1 === "" || answers.question1.length < 2)) {
            errors.question1 = "Please enter a longer answer";
            formStatus = 0;
        }
        if (!this.state.isCapsule && (!answers.question2 === undefined || !answers.question2.trim())) {
            errors.question2 = "Please enter a longer answer";
            formStatus = 0;
        }
        if (answers.question3 === undefined || answers.question3 === "" || answers.question3.length < 2) {
            errors.question3 = "Please enter a longer answer";
            formStatus = 0;
        }
        if (answers.question4 === undefined || answers.question4 === "" || answers.question4.length < 2) {
            errors.question4 = "Please enter a longer answer";
            formStatus = 0;
        }
        if (answers.question5 === undefined || answers.question5 === "" || answers.question5.length < 2) {
            errors.question5 = "Please select a business type";
            formStatus = 0;
        }

        this.setState({ errors: errors, formCompleteStatus: formStatus });

    }

    async checkFormStep2() {
        const hasVatError = this.state.hasVatError
        this.setState({ hasVatError: false })
        var answers = this.state.answers;
        var errors = { address1: "", address2: "", address3: "", city: "", county: "", country: "", postCode: "" }

        var hasError = false;
        if (answers.address1 === undefined || answers.address1 === "" || answers.address1.length < 1) {
            errors.address1 = "Please enter a longer address";
            hasError = true;
        }
        if (answers.city === undefined || answers.city === "" || answers.city.length < 1) {
            errors.city = "Please enter a longer city";
            hasError = true;
        }
        if (!answers.country || answers.country.length < 1) {
            errors.country = "Please select a country";
            hasError = true;
        }
        if (answers.postCode === undefined || answers.postCode === "" || answers.postCode.length < 1) {
            errors.postCode = answers.coutnry === 'United Kingdom' ? i18n.t('company:my.postCodeError') : i18n.t('company:my.zipError');
            hasError = true;
        }

        this.setState({ errors: errors });

        if (!hasError) {
            const self = this;
            const country = this.state.countryData.find(c => c.value === answers.country)
            let currency = 'USD'
            let isEU = false
            if (country && country.currency) {
                currency = country.currency
                isEU = country.isEU
            }
            const data = {
                accountName: this.props.accountMaster.accountMaster.accountName,
                doneNewUserQuestions: true,
                newUserHowDidYouHear: answers.question1,
                newUserHowManySubs: answers.question2,
                newUserObtainSubscribers: answers.question3,
                newUserWebsiteAddress: answers.question4,
                industry: answers.question5,
                address1: answers.address1,
                address2: answers.address2,
                address3: answers.address3,
                city: answers.city,
                county: answers.county,
                postCode: answers.postCode,
                currency,
                vatNumber: answers.vatNumber,
                country: answers.country,
                isEU: isEU,
                from: this.props.from
            }

            if (isEU) {
                if (!answers.vatNumber) {
                    if (hasVatError) {
                        if (!this.state.answers.noVatReason) {
                            return this.setState({
                                errors: {
                                    noVatReason: 'Required'
                                },
                                hasVatError: true
                            })
                        } else {
                            data.noVatReason = this.state.answers.noVatReason
                        }
                    } else {
                        return this.setState({
                            errors: {
                                vatNumber: 'Required'
                            },
                            hasVatError: true
                        })
                    }
                }
                const result = await axios.post('/accountMaster/checkVat', {
                    country: country.code,
                    vatNumber: answers.vatNumber
                })
                if (!result.data.valid) {
                    if (hasVatError) {
                        if (!this.state.answers.noVatReason) {
                            return this.setState({
                                errors: {
                                    noVatReason: 'Required'
                                },
                                hasVatError: true
                            })
                        } else {
                            data.noVatReason = this.state.answers.noVatReason
                        }
                    } else {
                        return this.setState({
                            errors: {
                                vatNumber: 'Invalid VAT Number'
                            },
                            hasVatError: true
                        })
                    }
                }
            }

            await axios.put("/accountMaster", data)
            TranspondSiteTracking.pageChange(this.props.user.userData.emailAddress, { "popup.questionsDone": true })
            self.setState({ formCompleteStatus: 2 })
        }
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, false));
    }

    async changeAddress(selected) {
        try {
            let placeId = selected.value;
            let resp = await axios.get('/accountMaster/addresslookupindepth?placeid=' + placeId);
            let answers = this.state.answers;
            answers.address1 = resp.data.houseNo;
            answers.address2 = resp.data.street1;
            answers.address3 = resp.data.street2;
            answers.city = resp.data.town;
            answers.county = resp.data.county;
            answers.country = resp.data.country;
            answers.postCode = resp.data.postCode;

            let errors = this.state.errors;
            errors.address1 = "";
            errors.address2 = "";
            errors.address3 = "";
            errors.city = "";
            errors.county = "";
            errors.country = "";
            errors.postCode = "";
            errors.vatNumber = "";
            this.setState({ answers: answers, selectedAddress: true })
        }
        catch (err) {

        }
    }

    render() {
        let steps = [{
            title: "About You",
            onClick: () => { if (this.state.formCompleteStatus === 1) { this.setState({ formCompleteStatus: 0 }) } },
            selected: this.state.formCompleteStatus === 0,
            complete: this.state.formCompleteStatus > 0
        },
        {
            title: "Your Address",
            selected: this.state.formCompleteStatus === 1,
            complete: this.state.formCompleteStatus === 2
        },
        {
            title: "Finished",
            onClick: () => { },
            selected: this.state.formCompleteStatus === 2,
            complete: false
        }];

        const country = this.state.countryData.find(c => c.value === this.state.answers.country)
        const isEU = country && country.isEU


        let info = 'start using ' + this.props.siteMaster.siteName
        if (this.props.from === 'automation') {
            info = "can start automations"
        }
        if (this.props.from === 'campaign' || this.props.from === 'social') {
            info = "send your first campaign"
        }
        if (this.props.from === 'email') {
            info = "send your first email"
        }
        if (this.props.from === 'tracking') {
            info = "start tracking your first site"
        }
        if (this.props.from === 'form') {
            info = "deploy your first form"
        }
        if (this.props.from === 'payment') {
            info = "make your first payment"
        }
        if (this.props.from === 'account') {
            info = "change your email address"
        }
        if (this.props.from === 'clean') {
            info = "start a list clean"
        }

        return <UILIB.Row>
            <UILIB.Column xs={12} sm={8} className="col-sm-offset-2" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <h4 className="mar-b15">Get Approved</h4>
                <div className="mar-b25">Before you can {info}, please tell us a little bit about yourself. You should be done in less than 2 minutes.</div>
                <div><UILIB.ProgressNav steps={steps} /></div>
                <UILIB.Paper className="with_header" style={{ width: "100%" }}>
                    <div className="paper_content">
                        {(this.state.formCompleteStatus === 0) && <>
                            <UILIB.Row>

                                {(!this.state.isCapsule) && <UILIB.Column xs={12} sm={6}>
                                    <UILIB.TextInput outerClassName="mar-b25" label={`How did you hear about ${this.props.siteMaster.siteName}`} name="question1" value={this.state.answers.question1} error={this.state.errors.question1} onChange={this.updateVal} />
                                </UILIB.Column>}
                                {(!this.state.isCapsule) && <UILIB.Column xs={12} sm={6}>
                                    <UILIB.TextInput outerClassName="mar-b25" label="How many contacts do you currently have?" name="question2" value={this.state.answers.question2} error={this.state.errors.question2} onChange={this.updateVal} />
                                </UILIB.Column>}

                                <UILIB.Column xs={12} sm={6}>
                                    <UILIB.TextInput outerClassName="mar-b25" label="Where do you obtain contacts?" name="question3" value={this.state.answers.question3} error={this.state.errors.question3} onChange={this.updateVal} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6}>
                                    <UILIB.TextInput outerClassName="mar-b25" type="url" label="What's your website address?" name="question4" value={this.state.answers.question4} placeholder="http://" error={this.state.errors.question4} onChange={this.updateVal} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={6}>
                                    <UILIB.Select filter={true} data={Industries.industryArray()} outerClassName="mar-b25" label="Please choose the business type that best describes you?" name="question5" value={this.state.answers.question5} placeholder="Select a business type" error={this.state.errors.question5} onChange={this.updateVal} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12}>
                                    <UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon="arrowRight" />} text="Continue" onClick={this.checkFormStep1} />
                                </UILIB.Column>
                            </UILIB.Row>
                        </>
                        }
                        {(this.state.formCompleteStatus === 1) && <>
                            <div className="mar-b25">Finally, enter the address of yourself, or your business.</div>
                            <UILIB.Row>
                                {(!this.state.selectedAddress) && <UILIB.Column xs={12} margin="0" style={{ position: "relative" }}>
                                    <UILIB.AutoComplete
                                        iconLeft={<UILIB.Icons icon="person" />}
                                        label="Start typing your address"
                                        url="/accountMaster/addresslookup?search={{name}}"
                                        parser={d => d.map(a => ({ label: a.description, value: a.place_id }))}
                                        onChange={this.changeAddress}
                                        placeholder="123 longroad, demoton..."
                                        className="mar-b15"
                                        explainer={<a onClick={() => { this.setState({ selectedAddress: true }) }}>or Enter your Address Manually</a>}
                                    />

                                </UILIB.Column>}
                                {(this.state.selectedAddress) && <>
                                    <UILIB.Column xs={12}>
                                        <UILIB.Paper>
                                            <UILIB.Row>
                                                <UILIB.Column xs={12} sm={6}>
                                                    <h4 className="mar-b25">Your Address</h4>
                                                </UILIB.Column>
                                                <UILIB.Column xs={12} sm={6} className="text-right">
                                                    <UILIB.Button text="Change Address" className="button-sml" onClick={() => { this.setState({ selectedAddress: false }) }} />
                                                </UILIB.Column>
                                            </UILIB.Row>


                                            <UILIB.Row>
                                                <UILIB.Column xs={12} sm={12} md={6} margin="0" className="mar-b25">
                                                    <UILIB.TextInput label={i18n.t('company:my.address1')} type="text" placeholder={i18n.t('company:my.address1')} name="address1" error={this.state.errors.address1} value={this.state.answers.address1} onChange={this.updateVal} />
                                                </UILIB.Column>
                                                <UILIB.Column xs={12} sm={12} md={6} margin="0" className="mar-b25">
                                                    <UILIB.TextInput label={i18n.t('company:my.address2')} type="text" placeholder={i18n.t('company:my.address2')} name="address2" error={this.state.errors.address2} value={this.state.answers.address2} onChange={this.updateVal} />
                                                </UILIB.Column>
                                                <UILIB.Column xs={12} sm={12} md={6} margin="0" className="mar-b25">
                                                    <UILIB.TextInput label={i18n.t('company:my.address3')} type="text" placeholder={i18n.t('company:my.address3')} name="address3" error={this.state.errors.address3} value={this.state.answers.address3} onChange={this.updateVal} />
                                                </UILIB.Column>
                                                <UILIB.Column xs={12} sm={12} md={6} margin="0" className="mar-b25">
                                                    <UILIB.TextInput label={i18n.t('company:my.city')} type="text" placeholder={i18n.t('company:my.city')} name="city" error={this.state.errors.city} value={this.state.answers.city} onChange={this.updateVal} />
                                                </UILIB.Column>
                                                <UILIB.Column xs={12} sm={12} md={6} margin="0" className="mar-b25">
                                                    <UILIB.TextInput label={this.state.answers.country === 'United Kingdom' ? i18n.t('company:my.county') : i18n.t('company:my.state')} type="text" placeholder={this.state.answers.country === 'United Kingdom' ? i18n.t('company:my.county') : i18n.t('company:my.state')} name="county" error={this.state.errors.county} value={this.state.answers.county} onChange={this.updateVal} />
                                                </UILIB.Column>
                                                <UILIB.Column xs={12} sm={12} md={6} margin="0" className="mar-b25">
                                                    <UILIB.TextInput label={this.state.answers.country === 'United Kingdom' ? i18n.t('company:my.postCode') : i18n.t('company:my.zip')} type="text" placeholder={this.state.answers.country === 'United Kingdom' ? i18n.t('company:my.postCode') : i18n.t('company:my.zip')} name="postCode" error={this.state.errors.postCode} value={this.state.answers.postCode} onChange={this.updateVal} />
                                                </UILIB.Column>
                                                <UILIB.Column xs={12} sm={12} md={12} margin="0" >
                                                    <UILIB.Select label={i18n.t('company:my.country')} data={this.state.countryData} placeholder="Select a country" name="country" error={this.state.errors.country} value={this.state.answers.country} onChange={this.updateVal} />
                                                </UILIB.Column>
                                            </UILIB.Row>
                                        </UILIB.Paper>
                                    </UILIB.Column>
                                    {(isEU || this.state.answers.country === 'United Kingdom') && <UILIB.Column xs={12} sm={12} md={12} margin="0" >
                                        <UILIB.Paper>
                                            <h4 className="mar-b25">Your VAT Number (Optional)</h4>
                                            <UILIB.TextInput explainer="This helps us authenticate your company is genuine and will speed up verification if entered" label="" value={this.state.answers.vatNumber} name="vatNumber" onChange={this.updateVal} error={this.state.errors.vatNumber} placeholder="VAT Number" />
                                        </UILIB.Paper>
                                    </UILIB.Column>}
                                    {this.state.hasVatError && <UILIB.Column xs={12} sm={12} md={12} margin="0" className="mar-t25">
                                        <UILIB.TextArea label="VAT Number is required for EU countries to prove you are a company, if you are not able to provide a VAT number please evidence that you are a valid company (website address, company id etc.)" value={this.state.answers.noVatReason} name="noVatReason" onChange={this.updateVal} rows="4" error={this.state.errors.noVatReason} />
                                    </UILIB.Column>}
                                    <UILIB.Column xs={12} margin="0">
                                        <UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon="arrowRight" />} text="Finish" onClick={this.checkFormStep2} />
                                    </UILIB.Column>
                                </>}

                            </UILIB.Row>
                        </>}
                        {(this.state.formCompleteStatus === 2) && <>
                            <h4 className="mar-b25">You're all done!</h4>
                            <div className="mar-b15 text-strong">Your account is now
                                <UILIB.SquareChip
                                    className="square-chip-green mar-l5 mar-r5">
                                    Awaiting Approval
                                </UILIB.SquareChip>
                                and one of our team will look to approve your account within the next few minutes.</div>
                            <div className="mar-b25">
                                If you are still awaiting approval after an hour, have any questions or require any assistance please <a href={"mailto:" + this.props.siteMaster.supportEmail}>drop us an email</a>.
                            </div>
                            <UILIB.Button text="Finish" onClick={this.closeDrawer} className="button-primary" />
                        </>}
                        {(this.state.formCompleteStatus === 999) && <div>
                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={12} md={6} margin="0" className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('company:my.address1')} type="text" placeholder={i18n.t('company:my.address1')} name="address1" error={this.state.errors.address1} value={this.state.answers.address1} onChange={this.updateVal} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={6} margin="0" className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('company:my.address2')} type="text" placeholder={i18n.t('company:my.address2')} name="address2" error={this.state.errors.address2} value={this.state.answers.address2} onChange={this.updateVal} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={6} margin="0" className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('company:my.address3')} type="text" placeholder={i18n.t('company:my.address3')} name="address3" error={this.state.errors.address3} value={this.state.answers.address3} onChange={this.updateVal} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={6} margin="0" className="mar-b25">
                                    <UILIB.TextInput label={i18n.t('company:my.city')} type="text" placeholder={i18n.t('company:my.city')} name="city" error={this.state.errors.city} value={this.state.answers.city} onChange={this.updateVal} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={6} margin="0" className="mar-b25">
                                    <UILIB.TextInput label={this.state.answers.country === 'United Kingdom' ? i18n.t('company:my.county') : i18n.t('company:my.state')} type="text" placeholder={this.state.answers.country === 'United Kingdom' ? i18n.t('company:my.county') : i18n.t('company:my.state')} name="county" error={this.state.errors.county} value={this.state.answers.county} onChange={this.updateVal} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={6} margin="0" className="mar-b25">
                                    <UILIB.TextInput label={this.state.answers.country === 'United Kingdom' ? i18n.t('company:my.postCode') : i18n.t('company:my.zip')} type="text" placeholder={this.state.answers.country === 'United Kingdom' ? i18n.t('company:my.postCode') : i18n.t('company:my.zip')} name="postCode" error={this.state.errors.postCode} value={this.state.answers.postCode} onChange={this.updateVal} />
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={12} margin="0" className="mar-b25">
                                    <UILIB.Select label={i18n.t('company:my.country')} data={this.state.countryData} placeholder="Select a country" name="country" error={this.state.errors.country} value={this.state.answers.country} onChange={this.updateVal} />
                                </UILIB.Column>
                                {(isEU || this.state.answers.country === 'United Kingdom') && <UILIB.Column xs={12} sm={12} md={12} margin="0" className="mar-b25">
                                    <UILIB.TextInput explainer="This helps us authenticate your company is genuine and will speed up verification if entered" label="VAT Number" value={this.state.answers.vatNumber} name="vatNumber" onChange={this.updateVal} error={this.state.errors.vatNumber} placeholder="VAT Number" />
                                </UILIB.Column>}
                                {this.state.hasVatError && <UILIB.Column xs={12} sm={12} md={12} margin="0" className="mar-b25">
                                    <UILIB.TextArea label="VAT Number is required for EU countries to prove you are a company, if you are not able to provide a VAT number please evidence that you are a valid company (website address, company id etc.)" value={this.state.answers.noVatReason} name="noVatReason" onChange={this.updateVal} rows="4" error={this.state.errors.noVatReason} />
                                </UILIB.Column>}

                            </UILIB.Row>
                            <UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon="arrowRight" />} text="Finish" onClick={this.checkFormStep2} />
                        </div>}
                    </div>
                </UILIB.Paper>
            </UILIB.Column>
        </UILIB.Row >
    }
};



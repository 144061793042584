import React from 'react';
import { CustomPicker } from 'react-color'
import { Hue, Saturation } from 'react-color/lib/components/common'
import SiteVars from '~/Classes/siteVars';
import i18n from '~/i18n'
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'

@connect((store) => {
    return { account: store.accountMaster }
})
class MyPicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            textColor: this.props.hex
        }
        this.catchClick = this.catchClick.bind(this);
        this.goChange = this.goChange.bind(this);
        this.goSave = this.goSave.bind(this);
        this.addFaveColor = this.addFaveColor.bind(this);
        this.clickText = this.clickText.bind(this);
        this.changeText = this.changeText.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ textColor: nextProps.hex })
    }
    componentWillUnmount() {
        if (this.props.saveColor && !this.props.hasSaveButton) {
            this.props.saveColor(this.props);
        }
    }
    catchClick(event) {
        event.stopPropagation();
        event.target.focus();
    }
    clickText(event) {
        event.stopPropagation();
        event.target.focus();
    }
    changeText(event) {
        event.stopPropagation();
        event.preventDefault();
        var theCol = event.target.value;
        this.setState({ textColor: theCol })
        if (theCol.length > 6) {
            this.props.onChange(theCol);
        }
    }

    goChange(event, theCol) {
        event.stopPropagation();
        event.preventDefault();
        this.props.onChange(theCol);
    }

    goSave() {
        this.props.saveColor(this.props);
    }

    addFaveColor() {
        this.props.saveFaveColor(this.props.hex, "add");
        this.props.onChange(this.props.hex);
    }

    render() {

        var thisProps = {};
        if (this.props.hasSaveButton) {
            thisProps.onMouseDown = this.catchClick;
        }
        else {
            thisProps.onClick = this.catchClick;
        }

        const accountColours = this.props.account.accountMaster.options ? this.props.account.accountMaster.options.colours || [] : []

        return <div className="mpzColorPicker" {...thisProps}>

            <div className="topToolsHolder">
                <div className="saturation">
                    <Saturation hsl={this.props.hsl} hsv={this.props.hsv} onChange={this.props.onChange} />
                </div>
                <div className="hue">
                    <Hue hsl={this.props.hsl} onChange={this.props.onChange} direction="vertical" />
                </div>
            </div>
            <div className="mar-b5">
                <UILIB.TextInput
                    name="colourHolder"
                    type="text"
                    placeHolder=""
                    onMouseDown={this.clickText}
                    value={this.state.textColor}
                    onChange={this.changeText}
                    iconRight={<UILIB.Icons icon="plus" style={{ width: "15px", height: "15px" }} onClick={this.addFaveColor} />}
                />

            </div>
            <div className="colorTitle">{i18n.t('templates:add.drag.colours.theme')}</div>
            {this.props.globalColours.map((theCol, index) => {

                switch (theCol) {
                    case "transparent":
                        return <div key={"col-" + index} title={theCol} className="colorHolderSmall" style={{ backgroundImage: 'url(' + SiteVars.repoEndPoint + 'siteContent/assets/templates/common/empty-color.svg)', display: "inline-block" }} onMouseDown={(event) => { this.goChange(event, theCol) }}></div>
                        break
                    default:
                        return <div key={"col-" + index} title={theCol} className="colorHolderSmall" style={{ backgroundColor: theCol, display: "inline-block", border: "1px solid #DDDDDD" }} onMouseDown={(event) => { this.goChange(event, theCol) }} />
                }
            })}
            {this.props.favoriteColors.length > 0 &&
                <span>
                    <div className="colorTitle">{i18n.t('templates:add.drag.colours.favourite')}</div>
                    {
                        this.props.favoriteColors.map((theCol, index) => {
                            return <div key={"col-" + index} title={theCol} className="colorHolderSmall" style={{ backgroundColor: theCol, display: "inline-block", border: "1px solid #DDDDDD" }} onMouseDown={(event) => { this.goChange(event, theCol) }} />
                        })
                    }
                </span>
            }
            {accountColours && accountColours.length > 0 &&
                <span>
                    <div className="colorTitle">{i18n.t('templates:add.drag.colours.accountColours')}</div>
                    {
                        accountColours.map((theCol, index) => {
                            return <div key={"acc-" + index} title={theCol} className="colorHolderSmall" style={{ backgroundColor: theCol, display: "inline-block", border: "1px solid #DDDDDD" }} onMouseDown={(event) => { this.goChange(event, theCol) }} />
                        })
                    }
                </span>
            }
            {this.props.hasSaveButton === true && <a className="saveButton" onClick={this.goSave}>{i18n.t('templates:add.drag.colours.save')}</a>}
        </div>
    }
}
export default CustomPicker(MyPicker);
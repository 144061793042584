// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BPfN0DN0XJ39kxdsqeJx{width:100%}.NGMctW30f5NGaxxovE_5{background-color:var(--grey-50);border:1px solid var(--grey-200);border-radius:8px;padding:12px;width:100%}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/aiAssistant/test/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,+BAAA,CACA,gCAAA,CACA,iBAAA,CACA,YAAA,CACA,UAAA","sourcesContent":[".textarea {\n  width: 100%;\n}\n\n.responseBox {\n  background-color: var(--grey-50);\n  border: 1px solid var(--grey-200);\n  border-radius: 8px;\n  padding: 12px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "BPfN0DN0XJ39kxdsqeJx",
	"responseBox": "NGMctW30f5NGaxxovE_5"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'

export default class IconsShare extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 8C15.6091 8.00002 16.2039 7.8146 16.7051 7.4684C17.2063 7.1222 17.5902 6.63162 17.8059 6.06191C18.0215 5.49219 18.0586 4.87034 17.9122 4.27904C17.7658 3.68774 17.4428 3.15501 16.9863 2.7517C16.5298 2.34839 15.9613 2.09362 15.3565 2.02125C14.7516 1.94888 14.1391 2.06235 13.6003 2.34658C13.0615 2.6308 12.6221 3.0723 12.3403 3.61238C12.0585 4.15245 11.9479 4.7655 12.023 5.37L7.08299 7.84C6.65922 7.43136 6.12459 7.15642 5.54568 7.04945C4.96678 6.94247 4.3692 7.00819 3.82737 7.2384C3.28555 7.46862 2.82344 7.85317 2.4986 8.34414C2.17376 8.83511 2.00055 9.4108 2.00055 9.9995C2.00055 10.5882 2.17376 11.1639 2.4986 11.6549C2.82344 12.1458 3.28555 12.5304 3.82737 12.7606C4.3692 12.9908 4.96678 13.0565 5.54568 12.9496C6.12459 12.8426 6.65922 12.5676 7.08299 12.159L12.023 14.629C11.9355 15.3312 12.0991 16.0418 12.4848 16.6351C12.8705 17.2284 13.4535 17.6663 14.1309 17.8713C14.8082 18.0763 15.5362 18.0353 16.1862 17.7555C16.8362 17.4757 17.3663 16.9751 17.683 16.3422C17.9996 15.7093 18.0823 14.9849 17.9164 14.2969C17.7506 13.6089 17.3469 13.0017 16.7767 12.5826C16.2064 12.1635 15.5064 11.9595 14.8003 12.0066C14.0942 12.0537 13.4275 12.3489 12.918 12.84L7.97799 10.37C8.00825 10.1243 8.00825 9.87574 7.97799 9.63L12.918 7.16C13.456 7.68 14.19 8 15 8Z" fill={color} />
        </svg>
    }
}        
const defaults = {
    fontStyle: [{ label: "Normal", value: 'normal' }, { label: "Italic", value: 'italic' }],
    fontWeight: [{ label: "Normal", value: 'normal' }, { label: "Bold", value: 'bold' }],
    lineHeight: [{ label: "Normal", value: 'normal' }, { label: "Slight", value: '125%' }, { label: "1 1/2 Spacing", value: '150%' }, { label: "Double", value: '200%' }],
    letterSpacing: [{ label: "-5px", value: '-5px' }, { label: "-4px", value: '-4px' }, { label: "-3px", value: '-3px' }, { label: "-2px", value: '-2px' }, { label: "-1px", value: '-1px' }, { label: "Normal", value: 'normal' }, { label: "1px", value: '1px' }, { label: "2px", value: '2px' }, { label: "3px", value: '3px' }, { label: "4px", value: '4px' }, { label: "5px", value: '5px' }],
    borderStyle: [{ label: "None", value: "none" }, { label: "Solid Line", value: "solid" }, { label: "Dotted Line", value: "dotted" }, { label: "Dashed Line", value: "dashed" }],
    textDecoration: [{ label: "Underlined", value: "underline" }, { label: "No Underline", value: "none" }],
    horizontalAlign: [{ value: "left" }, { value: "center" }, { value: "right" }],
    verticalAlign: [{ value: "top" }, { value: "middle" }, { value: "bottom" }]
}

module.exports = defaults
import React from 'react'

export default function IconsClick({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (<svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10 18.0001C12.1217 18.0001 14.1566 17.1572 15.6569 15.6569C17.1571 14.1566 18 12.1218 18 10.0001C18 7.87833 17.1571 5.8435 15.6569 4.34321C14.1566 2.84292 12.1217 2.00006 10 2.00006C7.87827 2.00006 5.84344 2.84292 4.34315 4.34321C2.84285 5.8435 2 7.87833 2 10.0001C2 12.1218 2.84285 14.1566 4.34315 15.6569C5.84344 17.1572 7.87827 18.0001 10 18.0001ZM11 6.00006C11 5.73484 10.8946 5.48049 10.7071 5.29295C10.5196 5.10542 10.2652 5.00006 10 5.00006C9.73478 5.00006 9.48043 5.10542 9.29289 5.29295C9.10536 5.48049 9 5.73484 9 6.00006V10.0001C9.00006 10.2653 9.10545 10.5196 9.293 10.7071L12.121 13.5361C12.2139 13.629 12.3242 13.7027 12.4456 13.753C12.567 13.8032 12.6971 13.8291 12.8285 13.8291C12.9599 13.8291 13.09 13.8032 13.2114 13.753C13.3328 13.7027 13.4431 13.629 13.536 13.5361C13.6289 13.4432 13.7026 13.3329 13.7529 13.2115C13.8032 13.0901 13.8291 12.96 13.8291 12.8286C13.8291 12.6972 13.8032 12.5671 13.7529 12.4457C13.7026 12.3243 13.6289 12.214 13.536 12.1211L11 9.58606V6.00006Z" fill={color} />
    </svg>
    )
}
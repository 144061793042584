import React from 'react';

export default class OneColumn extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var styles = {
            cls1: {
                fill: 'rgba(0,0,0,0.05)'
            },
            cls2: {
                fill: '#fff'
            },
            cls3: {
                fill: 'rgba(0,0,0,0.2)'
            },
            cls4: {
                fill: 'rgba(0,0,0,0.1)'
            },
            cls5: {
                filter: 'url(#Rectangle_182)'
            }
        }
        return <svg id="Group_267" data-name="Group 267" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="svg-holder" viewBox="17141 6582 253 257">
            <defs>
                <filter id="Rectangle_182" x="17152" y="6588" width="230" height="251" filterUnits="userSpaceOnUse">
                    <feOffset dy="-5" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.039" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <rect id="Rectangle_181" data-name="Rectangle 181" className="outer" width="253" height="253" rx="3" transform="translate(17141 6582)" />
            <g style={styles.cls5} transform="matrix(1, 0, 0, 1, 0, 0)">
                <path id="Rectangle_182-2" data-name="Rectangle 182" style={styles.cls2} d="M3,0H209a3,3,0,0,1,3,3V233a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(17161 6602)" />
            </g>
            <path id="Rectangle_199" data-name="Rectangle 199" className="inner" d="M3,0H88a3,3,0,0,1,3,3V43a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(17171 6792)" />
            <path id="Rectangle_200" data-name="Rectangle 200" className="inner" d="M3,0H88a3,3,0,0,1,3,3V43a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(17272 6792)" />
            <rect id="Rectangle_183" data-name="Rectangle 183" className="inner" width="91" height="60" rx="3" transform="translate(17171 6652)" />
            <rect id="Rectangle_184" data-name="Rectangle 184" className="inner" width="91" height="60" rx="3" transform="translate(17272 6652)" />
            <rect id="Rectangle_185" data-name="Rectangle 185" style={styles.cls4} width="91" height="4" rx="2" transform="translate(17171 6732)" />
            <rect id="Rectangle_186" data-name="Rectangle 186" style={styles.cls4} width="91" height="4" rx="2" transform="translate(17272 6732)" />
            <rect id="Rectangle_189" data-name="Rectangle 189" style={styles.cls4} width="91" height="4" rx="2" transform="translate(17171 6756)" />
            <rect id="Rectangle_190" data-name="Rectangle 190" style={styles.cls4} width="91" height="4" rx="2" transform="translate(17272 6756)" />
            <rect id="Rectangle_187" data-name="Rectangle 187" style={styles.cls4} width="91" height="4" rx="2" transform="translate(17171 6744)" />
            <rect id="Rectangle_188" data-name="Rectangle 188" style={styles.cls4} width="91" height="4" rx="2" transform="translate(17272 6744)" />
            <rect id="Rectangle_191" data-name="Rectangle 191" style={styles.cls4} width="60.667" height="4" rx="2" transform="translate(17171 6768)" />
            <rect id="Rectangle_192" data-name="Rectangle 192" style={styles.cls4} width="60.667" height="4" rx="2" transform="translate(17272 6768)" />
            <rect id="Rectangle_198" data-name="Rectangle 198" className="inner" width="192" height="20" rx="3" transform="translate(17171 6612)" />
        </svg>

    }
}
import React, { useEffect, useState, useReducer } from "react";
import { useDispatch } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import TextInput from "../../components/textInput";
import Button from "../../components/button";
import * as styles from "./index.module.scss";
import AddSourceDialog from "./components/addSourceDialog";
import DeleteSourceDialog from "./components/deleteSourceDialog";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import i18n from "~/i18n";

export default function AiAssistantSources({ inbox, assistant }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [sources, setSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDeleteSourceDialogOpen, setIsDeleteSourceDialogOpen] =
    useState(false);
  const [isAddSourceDialogOpen, setIsAddSourceDialogOpen] = useState(false);


  const refreshSource = async (id) => {
    try {
      axios.put(`/inboxes/${inbox.id}/ai/${assistant.id}/sources/${id}/rebuild`)
      dispatch(alterSnackbar(true, i18n.t("chat:ai.sources.refreshing")));
    }
    catch (error) {
      dispatch(handleGenericError(error));
    }
  }

  const [tableData, updateTableData] = useReducer((state, action) => {
    return formatTableDataReducer(state, action, {
      setSelectedSource,
      setIsDeleteSourceDialogOpen,
      refreshSource
    });
  }, []);

  useEffect(() => {
    loadInitialData();
  }, []);

  async function loadInitialData() {
    try {
      await loadSources();
    } finally {
      setLoading(false);
    }
  }

  async function loadSources() {
    try {
      const { data } = await axios.get(
        `/inboxes/${inbox.id}/ai/${assistant.id}/sources`
      );
      setSources(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  useEffect(() => {
    updateTableData(sources);
  }, [sources]);

  async function addSource(values) {
    try {
      await axios.post(`/inboxes/${inbox.id}/ai/${assistant.id}/sources`, {
        url: values.url,
        provider: values.provider
      });

      loadSources();
      setIsAddSourceDialogOpen(false);
      dispatch(alterSnackbar(true, i18n.t("chat:ai.sources.added")));
    } catch (error) {
      dispatch(handleGenericError(error));
    }
  }



  async function deleteSource(source) {
    try {
      await axios.delete(
        `/inboxes/${inbox.id}/ai/${assistant.id}/sources/${source.id}`
      );
      loadSources();
      dispatch(alterSnackbar(true, i18n.t("chat:ai.sources.deleted")));
    } catch (error) {
      dispatch(handleGenericError(error));
    }
  }

  useEffect(() => {
    const filteredData = JSON.parse(JSON.stringify(sources));
    filteredData.forEach((source) => {
      source.filtered = !source.url.toLowerCase().includes(searchTerm.toLowerCase());
    });
    updateTableData(filteredData);
  }, [searchTerm]);

  if (loading) return <UILIB.LoadingIcons noClass />;

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inbox_settings_left_content">
      <Header type="ai" option="sources" />
      <div className={styles.topRow}>
        {(sources && sources.length > 0) && <form>
          <TextInput
            value={searchTerm}
            placeholder={i18n.t("chat:ai.sources.searchPlaceholder")}
            aria-label="Search for a source"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </form>}

        <Button
          size="s"
          iconLeft={<UILIB.Icon name="plus" />}
          onClick={() => {
            setSelectedSource(null);
            setIsAddSourceDialogOpen(true);
          }}
        >
          {i18n.t("chat:ai.sources.add")}
        </Button>
      </div>

      {sources.length > 0 && <>
        {sources.find(f => f.status === 1) && <div>
          <UILIB.WarningHolder className="text-left">
            <strong>{i18n.t("chat:ai.sources.scanningHeader")}</strong>
            <br /> {i18n.t("chat:ai.sources.scanningSubheader")}
          </UILIB.WarningHolder>
        </div>}

        <UILIB.DataTable1 tableData={tableData} />
      </>}

      <DeleteSourceDialog
        sourceToDelete={selectedSource}
        isOpen={isDeleteSourceDialogOpen}
        onSubmit={deleteSource}
        onOpenChange={(isOpen) => setIsDeleteSourceDialogOpen(isOpen)}
      />

      <AddSourceDialog
        inbox={inbox}
        isOpen={isAddSourceDialogOpen}
        onSubmit={addSource}
        onOpenChange={(isOpen) => setIsAddSourceDialogOpen(isOpen)}
      />
    </div>
  );
}

function formatTableDataReducer(state, action, options) {
  return action.filter(f => !f.filtered).map((source) => {

    const { id, url, status, embeddings } = source;

    let statusOut = <UILIB.Badge>{i18n.t("chat:ai.sources.table.queued")}</UILIB.Badge>;
    if (status === 1) {
      statusOut = <UILIB.Badge color={"orange"}>
        {i18n.t("chat:ai.sources.table.scanning")}
      </UILIB.Badge>
    }
    if (status === 2) {
      statusOut = <UILIB.Badge color={"teal"}>
        {i18n.t("chat:ai.sources.table.active")}
      </UILIB.Badge>
    }

    return {
      name: {
        headerValue: i18n.t("chat:ai.sources.table.website"),
        value: <span className="fw-medium">{url}</span>,
      },
      locations: {
        headerValue: i18n.t("chat:ai.sources.table.articles"),
        value: embeddings,
      },
      status: {
        headerValue: i18n.t("chat:ai.sources.table.status"),
        value: statusOut
      },
      options: {
        headerValue: "",
        value: (
          <div className={styles.tableActions}>
            <UILIB.DropdownMenu.Root>
              <UILIB.DropdownMenu.Trigger asChild>
                <Button
                  aria-label="Additional options"
                  variant="ghost"
                  size="s"
                  iconOnly
                >
                  <UILIB.Icon name="dots-vertical" />
                </Button>
              </UILIB.DropdownMenu.Trigger>

              <UILIB.DropdownMenu.Portal>
                <UILIB.DropdownMenu.Content align="end">
                  <UILIB.DropdownMenu.Item
                    onSelect={() => {
                      options.refreshSource(id);
                    }}>
                    {i18n.t("chat:ai.sources.refresh")}
                  </UILIB.DropdownMenu.Item>
                  <UILIB.DropdownMenu.Item
                    variant="danger"
                    onSelect={() => {
                      options.setSelectedSource(source);
                      options.setIsDeleteSourceDialogOpen(true);
                    }}
                  >
                    {i18n.t("chat:ai.sources.table.delete")}
                  </UILIB.DropdownMenu.Item>
                </UILIB.DropdownMenu.Content>
              </UILIB.DropdownMenu.Portal>
            </UILIB.DropdownMenu.Root>
          </div>
        ),
      },
    };
  });
}

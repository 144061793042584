import React, { useReducer, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import ChecklistPanel from "./components/checklistPanel";
import OtherFeaturesPanel from "./components/otherFeaturesPanel";
import SupportPanel from "./components/supportPanel";
import i18n from "~/i18n";

export default function GettingStarted() {
  const accountMaster = useSelector(
    (state) => state.accountMaster.accountMaster
  );

  const [completed, updateCompleted] = useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    {
      brandAnalyzer: false,
      createTemplate: false,
      addContacts: false,
      sendCampaign: false,
    }
  );

  useEffect(() => {
    getBrandAnalyzer();
    getGettingStartedData();
  }, []);

  function getBrandAnalyzer() {
    if (accountMaster.options.brandAnalyzer) {
      updateCompleted({ brandAnalyzer: true });
    }
  }

  const getGettingStartedData = async () => {
    const { data } = await axios.get("/accountMaster/gettingStarted");
    if (data.templatesCount > 0) {
      updateCompleted({ createTemplate: true });
    }

    if (data.contactsCount > 0) {
      updateCompleted({ addContacts: true });
    }

    if (data.campaignsCount > 0) {
      updateCompleted({ sendCampaign: true });
    }
  };

  return (
    <div className="getting-started-frontpage">
      <UILIB.Paper>
        <h1 className="h2 fw-medium mar-b10">
          {i18n.t("dashboard:gettingStarted.heading")}
        </h1>
        <h2 className="text-md fw-normal color-secondary">
          {i18n.t("dashboard:gettingStarted.subHeading")}
        </h2>

        <ChecklistPanel completedTasks={completed} />
      </UILIB.Paper>

      <div className="grid">
        <OtherFeaturesPanel />
        <SupportPanel />
      </div>
    </div>
  );
}

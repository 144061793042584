import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import UILIB from "../../../../Common-Objects/Lib";
import { useHistory } from 'react-router-dom';
import * as siteActions from '../../../../data/actions/siteActions';
import i18n from '~/i18n';

export default function brandAnalyzerRequiredDrawer({ urlOnCompletion }) {
    const history = useHistory()
    const dispatch = useDispatch()

    const goBrand = () => {
        dispatch(siteActions.alterFullpage(null));
        let url = "/cp/brandanalyzer"

        if (urlOnCompletion) {
            url += `?forward=${encodeURIComponent(urlOnCompletion)}`
        }
        history.push(url)
    }
    return (
        <UILIB.Modal width="900px">

            <div style={{ display: "flex", alignItems: "center" }}>

                <div >
                    <h4 className="mar-b15">{i18n.t('templates:brandAnalyzer.requiredModal.brandedTemplate')}</h4>
                    <div className="mar-b15">
                        {i18n.t('templates:brandAnalyzer.requiredModal.intro1')}
                    </div>
                    <div className="mar-b25">
                        {i18n.t('templates:brandAnalyzer.requiredModal.intro2')}
                    </div>
                    <UILIB.Button
                        text={i18n.t('templates:brandAnalyzer.requiredModal.getStarted')}
                        className="button-primary"
                        iconRight={<UILIB.Icons icon="arrowRight" />}
                        onClick={() => { goBrand() }}
                    />
                </div>
                <div className="mar-l20 hide-sm hide-xs" style={{ borderRadius: "20px", overflow: "hidden", width: "100%", maxWidth: "300px" }}>
                    <img src="https://cdn1.ourmailsender.com/siteContent/brandAnalyzer/popupCover.png" style={{ width: "100%" }} />
                </div>
            </div>

        </UILIB.Modal>
    )
}
import React from 'react';
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import Map from '../../common/map'
import Graph from '../visitors/graph'
import i18n from '~/i18n'
import axios from '~/data/http/axios'
import moment from 'moment';
import { connect } from 'react-redux';
@connect((store) => {
    return {
        filters: store.filters,
        siteMaster: store.siteMaster,
        user: store.user
    }
})
export default class AccountTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uniqueVisitors: 0,
            totalImpressions: 0,
            returningVisits: 0,
            firstVisits: 0,
            graphData: [],
            loaded: false,
            detectedCampaigns: []
        };
        this.getStats = this.getStats.bind(this);

    }

    componentDidMount() {

        this.getStats();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.startDate != this.props.startDate || prevProps.endDate != this.props.endDate) {
            this.setState({ loaded: false }, this.getStats);
        }
    }

    async getStats() {
        var queryStr = `?startDate=${this.props.startDate.format("YYYY-MM-DD")}&endDate=${this.props.endDate.format("YYYY-MM-DD")}&orderBy=NONE&orderDir=ASC&statsOnly=1`
        let stats = await axios.get('/accountMaster/tracker/' + this.props.tracker.id + '/visitors' + queryStr);
        stats = stats.data;

        let totalImpressions = 0;
        let firstVisits = 0;
        let returningVisits = 0;

        stats.graphData.forEach(row => {
            totalImpressions += row.impressions
            firstVisits += row.firstVisits;
            returningVisits += row.returningVisits;
        })


        let graphData = stats.graphData.map(row => {
            row.date = moment(row.str).locale(this.props.user.userData.language).format("DD MM YY")
            delete row.str;
            return row;
        })

        let detectedCampaigns = stats.detectedCampaigns.map(row => {
            row.date = moment(row.startDate).locale(this.props.user.userData.language).format("DD MM YY")
            return row
        })

        this.setState({ uniqueVisitors: stats.count, totalImpressions, firstVisits, returningVisits, graphData, loaded: true, detectedCampaigns: detectedCampaigns });
    }

    render() {



        var visLocTxt = i18n.t('campaigns:sitetracking.trackingoverview.visitorLocationsBetween');
        visLocTxt = visLocTxt.replace(/\[DATE1\]/g, DateTimeFunctions.formatDateTime(this.props.startDate, 2))
        visLocTxt = visLocTxt.replace(/\[DATE2\]/g, DateTimeFunctions.formatDateTime(this.props.endDate, 2))

        var visBetTxt = i18n.t('campaigns:sitetracking.trackingoverview.visitorsBetween');
        visBetTxt = visBetTxt.replace(/\[DATE1\]/g, DateTimeFunctions.formatDateTime(this.props.startDate, 2))
        visBetTxt = visBetTxt.replace(/\[DATE2\]/g, DateTimeFunctions.formatDateTime(this.props.endDate, 2))
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column className="col-xs-12 col-sm-6 col-md">
                        <UILIB.Paper className="dashHeight">
                            <UILIB.Header className="text-grey text-xsml uppercase">{i18n.t('campaigns:sitetracking.trackingoverview.uniqueVisitors')}</UILIB.Header>
                            {this.state.loaded && <div className="statistic">{NumberFunctions.formatNumber(this.state.uniqueVisitors)}</div>}
                            {!this.state.loaded && <UILIB.LoadingIcons iconType="2" />}
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column className="col-xs-12 col-sm-6 col-md">
                        <UILIB.Paper className="dashHeight">
                            <UILIB.Header className="text-grey text-xsml uppercase">{i18n.t('campaigns:sitetracking.trackingoverview.newVisitors')}</UILIB.Header>
                            {this.state.loaded && <div className="statistic">{NumberFunctions.formatNumber(this.state.firstVisits)}</div>}
                            {!this.state.loaded && <UILIB.LoadingIcons iconType="2" />}
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column className="col-xs-12 col-sm-6 col-md">
                        <UILIB.Paper className="dashHeight">
                            <UILIB.Header className="text-grey text-xsml uppercase">{i18n.t('campaigns:sitetracking.trackingoverview.returningVisitors')}</UILIB.Header>
                            {this.state.loaded && <div className="statistic">{NumberFunctions.formatNumber(this.state.returningVisits)}</div>}
                            {!this.state.loaded && <UILIB.LoadingIcons iconType="2" />}
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column className="col-xs-12 col-sm-6 col-md">
                        <UILIB.Paper className="dashHeight">
                            <UILIB.Header className="text-grey text-xsml uppercase">{i18n.t('campaigns:sitetracking.trackingoverview.pageViews')}</UILIB.Header>
                            {this.state.loaded && <div className="statistic">{NumberFunctions.formatNumber(this.state.totalImpressions)}</div>}
                            {!this.state.loaded && <UILIB.LoadingIcons iconType="2" />}
                        </UILIB.Paper>
                    </UILIB.Column >
                    <UILIB.Column className="col-xs-12 col-sm-6 col-md">
                        <UILIB.Paper className="dashHeight">
                            <UILIB.Header className="text-grey text-xsml uppercase">{i18n.t('campaigns:sitetracking.trackingoverview.campaigns')}</UILIB.Header>
                            {this.state.loaded && <div className="statistic">{NumberFunctions.formatNumber(this.state.detectedCampaigns.length)}</div>}
                            {!this.state.loaded && <UILIB.LoadingIcons iconType="2" />}
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>



                <UILIB.Row>
                    <UILIB.Column xs={12} sm={6} >
                        <UILIB.Paper >
                            <p className="text-grey text-sml">{visLocTxt}</p>
                            {!this.state.loaded && <div style={{ height: "400px" }}><UILIB.LoadingIcons iconType="2" /></div>}
                            {this.state.loaded && <Map trackerId={this.props.tracker.id} startDate={this.props.startDate} endDate={this.props.endDate} />}
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6}>
                        <UILIB.Paper>
                            <p className="text-grey text-sml">{visBetTxt}</p>
                            {!this.state.loaded && <div style={{ height: "400px" }}><UILIB.LoadingIcons iconType="2" /></div>}
                            {this.state.loaded && <Graph graphData={this.state.graphData} detectedCampaigns={this.state.detectedCampaigns} height={400} />}
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
            </div >
        );
    }
}

import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import i18n from '~/i18n'
import { connect } from 'react-redux';
import * as userActions from '../../../../data/actions/userActions';

@connect((store) => {
    return { siteMaster: store.siteMaster, accountUser: store.user }
})

export default class NpsScore extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            score: -1,
            comment: ""
        }

        this.submitScore = this.submitScore.bind(this);
        this.finish = this.finish.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {

    }

    submitScore(score) {
        this.setState({
            step: 1,
            score
        })
    }

    async finish(instaClose) {
        this.setState({ step: -1 })
        let comment = this.state.comment;
        if (this.state.score == -1) {
            comment += " [USER SKIPPED]"
        }
        await axios.post('/accountuser/npsscore', { score: this.state.score, comment: comment })
        if (instaClose) {
            this.close();
            return;
        }
        this.setState({
            step: 2
        })
    }

    close() {
        this.props.dispatch(userActions.loadUserRecord());
        this.props.close();
    }

    render() {

        if (this.state.step == -1) {
            return <UILIB.LoadingIcons iconType="2" />
        }

        return <div className="trans_modal" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <div className="npsHolder">

                {this.state.step == 0 && <UILIB.Paper>
                    <div className="npsClose">
                        <UILIB.Icons icon="cross" color="black" onClick={() => { this.finish(true) }} />
                    </div>
                    <h4 className="mar-b25">How likely are you to recommend {this.props.siteMaster.siteName} to a friend or coworker?</h4>
                    <div className="npsInnerHolder" >
                        <div className="hide-xs npsLabel">Not at all likely</div>
                        <div className="npsScore" onClick={() => { this.submitScore(0) }}>0</div>
                        <div className="npsScore" onClick={() => { this.submitScore(1) }}>1</div>
                        <div className="npsScore" onClick={() => { this.submitScore(2) }}>2</div>
                        <div className="npsScore" onClick={() => { this.submitScore(3) }}>3</div>
                        <div className="npsScore" onClick={() => { this.submitScore(4) }}>4</div>
                        <div className="npsScore" onClick={() => { this.submitScore(5) }}>5</div>
                        <div className="npsScore" onClick={() => { this.submitScore(6) }}>6</div>
                        <div className="npsScore" onClick={() => { this.submitScore(7) }}>7</div>
                        <div className="npsScore" onClick={() => { this.submitScore(8) }}>8</div>
                        <div className="npsScore" onClick={() => { this.submitScore(9) }}>9</div>
                        <div className="npsScore" onClick={() => { this.submitScore(10) }}>10</div>
                        <div className="hide-xs npsLabel">Extremely likely</div>
                    </div>
                </UILIB.Paper>}
                {this.state.step == 1 && <UILIB.Paper>
                    <h4 className="mar-b25">Do you have any other comments about {this.props.siteMaster.siteName}?</h4>
                    <UILIB.TextArea outerClassName="mar-b15" style={{ width: "100%", height: "120px" }} placeholder="Enter comments here..." name="comment" value={this.state.comment} onChange={(ev) => { this.setState({ comment: ev.target.value }) }} />
                    <div className="text-right">
                        <UILIB.Button className="button-primary" text="Finish" onClick={this.finish} />
                    </div>
                </UILIB.Paper>}

                {this.state.step == 2 && <UILIB.Paper>
                    <h4 className="mar-b25">Thankyou!</h4>
                    <div className="mar-b15">
                        Thanks for taking the time to let us know how we are doing.
                        Your comments and suggestions are really valuable.
                    </div>
                    <div className="text-right">
                        <UILIB.Button className="button-primary" text="Close" onClick={this.close} />
                    </div>
                </UILIB.Paper>}
            </div>
        </div >

    }

}


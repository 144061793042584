import React from 'react';

export default class Divider extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <hr className="divider" style={this.props.style} />
    }
}

import React, { Component } from 'react';
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import HookOptionsView from '~/pages/cp/includes/integrations/hookOptionsView'
import { alterSiteDrawer } from '~/data/actions/siteActions';
@connect((store) => {
  return { account: store.accountMaster }
})

class CampaignIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hooks: []
    }

    this.saveWebhooks = this.saveWebhooks.bind(this);
    this.continue = this.continue.bind(this);
  }

  componentDidMount() {

    let hooks = []
    let getters = []
    this.props.apps.filter(a => a.Application.options && a.Application.options.hooks && a.Application.options.hooks.length && (a.Application.options.useGenericConfig || a.Application.options.hooksOnly)).forEach(app => {
      app.props = {}
      app.Application.options.hooks.filter(h => !h.automationonly).forEach(hook => {
        if (hook.getters) {
          hook.getters.forEach(getter => {
            getters.push(axios.get(`/application/${app.Application.shortName}/${getter.endpoint}`).then(res => {
              app.props[getter.property] = res.data
            }))
          })
        }
        if (hook.dynamicBuild) {
          getters.push(axios.get(`/application/${app.Application.shortName}/${hook.dynamicBuild}`).then(res => {
            res.data.forEach(row => {
              if (hook.build.find(f => f.value === row.value)) return // we already have it mapped manually so ignore
              hook.build.push(row)
            })
          }))
        }
        hooks.push({
          ApplicationId: app.ApplicationId,
          appName: app.Application.appName,
          shortName: app.Application.shortName,
          logo: app.Application.appLogoUri,
          action: hook.action,
          build: hook.build,
          name: hook.name
        })
      })
    })
    Promise.all(getters).then(() => {
      this.setState({
        hooks,
        hooksLoaded: true
      })
    }).catch(() => {
      this.setState({
        hooks,
        hooksLoaded: true
      })
    })
  }

  saveWebhooks(webhooks) {
    // save
    this.props.campaign.options.webhooks = webhooks;
    this.props.saveCampaign(this.props.campaign);
  }

  continue() {
    this.props.dispatch(alterSiteDrawer(false, true, 'right', null))
  }


  render() {
    if (!this.state.hooksLoaded) {
      return <div className='quickFlex justify-center' style={{ flexDirection: 'column' }}>
        <UILIB.LoadingIcons iconType="2" />
        <div className='text-lg loading mar-t30 text-center'>Checking Integrations</div>
      </div>
    }

    return (
      <div>

        <UILIB.Paper style={{ height: 'calc(100vh - 175px)', overflow: 'auto' }}>
          <h4 className="mar-b25">
            Choose <span className="text-primary">integration actions</span> for your campaign. (OPTIONAL)
          </h4>
          <div className="mar-b25">
            Push data, or trigger things on the third party apps you've linked up when campaign actions occur.
            Otherwise click Next at the bottom of the page to carry on...
          </div>
          <HookOptionsView campaign={this.props.campaign} hooks={this.state.hooks} apps={this.props.apps} saveWebhooks={this.saveWebhooks} doingAction={doingAction => this.setState({ editingAction: doingAction })} />

        </UILIB.Paper>

        <UILIB.StickyNavBottom>
          <div className="quickFlex" style={{ flexDirection: 'row-reverse' }}>
            <UILIB.Button
              className={"button-primary"}
              iconRight={<UILIB.Icons icon="arrowRight" />}
              onClick={this.continue}
              disabled={this.state.editingAction}>
              Save
            </UILIB.Button>
          </div>
        </UILIB.StickyNavBottom>


      </div >
    );
  }
}

export default CampaignIntegration;
import React from 'react'
import UILIB from '~/Common-Objects/Lib'

import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import { connect } from 'react-redux';
import i18n from '~/i18n'
import siteVars from '~/Classes/siteVars';
import helpers from '~/Classes/helpers';
import moment from 'moment';
import PermissionChecker from '~/Classes/permissions';
//CONNECT TO STORE
@connect((store) => {
    return { siteMaster: store.siteMaster, user: store.user, accountMaster: store.accountMaster, permissionStore: store.permission }
})

export default class CampaignDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        helpers.createKey(0, 0, 0, this.props.campaign.id).then(key => {
            this.setState({ key })
        })
    }

    render() {
        if (!this.state.key) return <UILIB.LoadingIcons />

        let campaign = this.props.campaign;
        let sendingNotifications = false;
        if (campaign.sendUpdateEmails && campaign.sendUpdateEmailsRecipient && campaign.sendUpdateEmailsNextSend) {
            sendingNotifications = true
        }

        var resendDate = new Date();
        if (campaign.resendAfter != 0) {
            resendDate = DateTimeFunctions.formatDateTime(moment(campaign.finishedDate).add('days', campaign.resendAfter).format(), 2);
        }

        var recipients = []
        if (campaign.Groups && campaign.Groups.length) {
            recipients = recipients.concat(campaign.Groups.map(g => {
                return {
                    name: g.groupName,
                    id: g.id,
                    type: 'Group',
                    include: g.CampaignGroup.include
                }
            }))
        }

        if (campaign.Segments && campaign.Segments.length) {
            recipients = recipients.concat(campaign.Segments.map(g => {
                return {
                    name: g.name,
                    id: g.id,
                    type: 'Segment',
                    include: g.CampaignSegment.include
                }
            }))
        }

        var recipientsIncluded = recipients.filter(rec => rec.include == true)
        var recipientsExcluded = recipients.filter(rec => rec.include == false)

        var thisShareUrl = "https://" + this.props.siteMaster.trackingDomain + "/api/preview?key=" + this.state.key;

        var canViewSubscribers = PermissionChecker('subscribers', this.props.permissionStore.permissions, "read");

        return <UILIB.Paper id="campaignDetails">

            {sendingNotifications && <div style={{ position: 'absolute', right: 20, top: 20 }}>
                <UILIB.Button text="Disable Notifications" onClick={this.props.disableNotifications} className="button-sml outline primary" />
            </div>}

            <h4 className="mar-b24">{i18n.t('campaigns:overview.campaignDetails')}</h4>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={6} margin="0">
                    <label className="text-heavy">{i18n.t('campaigns:overview.dateStarted')}</label>
                    <p>{DateTimeFunctions.formatDateTime(campaign.startDate, '1')}</p>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} margin="0">
                    {!!campaign.maxSendDate && <div>
                        <label className="text-heavy">{i18n.t('campaigns:overview.dateFinishedEstimated')}</label>
                        <p>{DateTimeFunctions.formatDateTime(campaign.maxSendDate, '1')}</p>
                    </div>
                    }
                    {!campaign.maxSendDate && <div>
                        <label className="text-heavy">{i18n.t('campaigns:overview.dateFinished')}</label>
                        <p>{DateTimeFunctions.formatDateTime(campaign.finishedDate, '1')}</p>
                    </div>}
                </UILIB.Column>

                <UILIB.Column xs={12} sm={12} md={6} margin={0}>
                    <label className="text-heavy">{i18n.t('campaigns:overview.emailFrom')}</label>
                    <p>{campaign.fromName} {"<" + campaign.fromAddress + ">"}</p>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} margin={0}>
                    <label className="text-heavy">{i18n.t('campaigns:standard.replyAddress')}</label>
                    <p>{campaign.replyAddress}</p>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={12} md={6} margin="0">
                    <label className="text-heavy">{i18n.t('campaigns:overview.emailSubject')}</label>
                    <p>{campaign.subject}</p>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={6} margin="0">
                    <label className="text-heavy">{i18n.t('campaigns:overview.templateSent')}</label>
                    <p><a onClick={() => { this.props.history.push('/cp/campaigns/view/' + campaign.id + '/clicked') }}>{campaign.Template.templateName}</a></p>
                </UILIB.Column>
                {!!campaign.limit && <UILIB.Column xs={12} sm={12} md={6} margin="0">
                    <label className="text-heavy">{i18n.t('campaigns:overview.limit')}</label>
                    <p >{campaign.limit}</p>
                </UILIB.Column>}
                {!!campaign.resendAfter && <UILIB.Column xs={12} sm={12} md={6} margin="0">
                    <label className="text-heavy">{i18n.t('campaigns:overview.resendHeader')}</label>
                    <p >{i18n.t('campaigns:overview.resendText')} {resendDate}</p>
                </UILIB.Column>}
                {!!campaign.batchSize && <UILIB.Column xs={12} sm={12} md={6} margin="0">
                    <label className="text-heavy">{i18n.t('campaigns:overview.batch')}</label>
                    <p >{i18n.t('campaigns:overview.sending') + " " + campaign.batchSize + " " + i18n.t('campaigns:overview.emailsEvery') + " " + campaign.batchDelay + " " + campaign.batchUnit}</p>
                </UILIB.Column>}
                {!!this.props.junkReports && <UILIB.Column xs={12} sm={12} md={6} margin="0">
                    <label className="text-heavy">{i18n.t('campaigns:overview.junkReports')}</label>
                    <p >{this.props.junkReports}</p>
                </UILIB.Column>}
            </UILIB.Row>

            <div className="mar-b25">
                {/* <UILIB.Column xs={12} sm={12} margin={0}> */}
                    {!!recipientsIncluded.length && <UILIB.Row>
                        {/* <div className="text-heavy mar-b5">{i18n.t('campaigns:overview.groupsAndSegmentsIncluded')}</div> */}

                        <UILIB.Column xs={6} sm={6} md={6}>
                            <div className="text-heavy mar-b5">{i18n.t('campaigns:overview.groupsIncluded')}</div>
                            <div>
                                {recipientsIncluded.map((row, index) => {

                                    let className = "mar-r5 mar-b5";
                                    if (row.include) {
                                        className += " button-simple-lightgreen"
                                    } else {
                                        className += " button-simple-lightred"
                                    }

                                    if (row.type == 'Group'){
                                        return <UILIB.ButtonSimple
                                            key={"gKey" + index}
                                            className={className}
                                            selected={false}
                                            onClick={() => { if (canViewSubscribers) this.props.viewGroupOrSegment(row) }}
                                            iconRight={canViewSubscribers ? <UILIB.Icons icon="arrowRight" /> : ""}>
                                            {row.name}
                                        </UILIB.ButtonSimple>
                                    }
                                })}
                            </div>

                        </UILIB.Column>
                        <UILIB.Column xs={6} sm={6} md={6}>
                            <div className="text-heavy mar-b5">{i18n.t('campaigns:overview.segmentsIncluded')}</div>
                            {recipientsIncluded.map((row, index) => {

                                let className = "mar-r5 mar-b5";
                                if (row.include) {
                                    className += " button-simple-lightgreen"
                                } else {
                                    className += " button-simple-lightred"
                                }

                                if (row.type == 'Segment'){
                                    return <UILIB.ButtonSimple
                                        key={"gKey" + index}
                                        className={className}
                                        selected={false}
                                        onClick={() => { if (canViewSubscribers) this.props.viewGroupOrSegment(row) }}
                                        iconRight={canViewSubscribers ? <UILIB.Icons icon="arrowRight" /> : ""}>
                                        {row.name}
                                    </UILIB.ButtonSimple>
                                }
                                })}
                        </UILIB.Column>
                        

                        
                    </UILIB.Row>}

                    {!!recipientsExcluded.length && <UILIB.Row className='mar-t20'>
                        <UILIB.Column xs={6} sm={6} md={6}>
                            <div className="text-heavy mar-b5">{i18n.t('campaigns:overview.groupsExcluded')}</div>
                            <div>
                                {recipientsExcluded.map((row, index) => {

                                    let className = "mar-r5 mar-b5";
                                    if (row.include) {
                                        className += " button-simple-lightgreen"
                                    } else {
                                        className += " button-simple-lightred"
                                    }

                                    if (row.type == 'Group'){
                                        return <UILIB.ButtonSimple
                                            key={"exKey" + index}
                                            className={className}
                                            selected={false}
                                            onClick={() => { if (canViewSubscribers) this.props.viewGroupOrSegment(row) }}
                                            iconRight={canViewSubscribers ? <UILIB.Icons icon="arrowRight" /> : ""}>
                                            {row.name}
                                        </UILIB.ButtonSimple>
                                    }

                                })}
                            </div>

                        </UILIB.Column>
                        <UILIB.Column xs={6} sm={6} md={6}>
                            <div className="text-heavy mar-b5">{i18n.t('campaigns:overview.segmentsExcluded')}</div>
                            <div>
                                    {recipientsExcluded.map((row, index) => {

                                        let className = "mar-r5 mar-b5";
                                        if (row.include) {
                                            className += " button-simple-lightgreen"
                                        } else {
                                            className += " button-simple-lightred"
                                        }

                                        if (row.type == 'Segment'){
                                            return <UILIB.ButtonSimple
                                                key={"exKey" + index}
                                                className={className}
                                                selected={false}
                                                onClick={() => { if (canViewSubscribers) this.props.viewGroupOrSegment(row) }}
                                                iconRight={canViewSubscribers ? <UILIB.Icons icon="arrowRight" /> : ""}>
                                                {row.name}
                                            </UILIB.ButtonSimple>
                                        }

                                    })}
                                </div>

                        </UILIB.Column>
                        
                    </UILIB.Row>
                    } 

                {/* </UILIB.Column> */}

                {(campaign && campaign.options && campaign.options.MailServers && campaign.options.MailServers.length > -1) && <UILIB.Column xs={12} sm={12} margin={0}>
                    <div>
                        <label className="text-heavy">{i18n.t('campaigns:overview.serversSentFrom')}</label>
                        {campaign.options.MailServers.map((mailServer, index) => {
                            var serverFound = this.props.mailServers.find(ms => ms.id == mailServer)
                            if (serverFound) {
                                return <UILIB.ButtonSimple
                                    key={"sKey" + index}
                                    className={"buttonSimple"}
                                    selected={false}>
                                    <div>{serverFound.name}</div>
                                    {(serverFound.notes && serverFound.notes.length) && <div>{serverFound.notes}</div>}
                                </UILIB.ButtonSimple>
                            }
                        })}
                    </div>
                </UILIB.Column>}

                {(campaign && this.props.customFields && this.props.customFields.length > -1 && campaign.multiAddressRules && campaign.multiAddressRules.length > -1) && <UILIB.Column xs={12} sm={12} >
                    <div>

                        <label className="text-heavy">{i18n.t('campaigns:overview.multiAddressRules')}</label>

                        <table width="100%" cellPadding="5" cellSpacing="0">
                            {campaign.multiAddressRules.map((addressRule, index) => {
                                var currMatch = addressRule.match

                                var cField = this.props.customFields.find(cf => cf.id == addressRule.customField)
                                if (cField && cField.options && cField.options.length) {
                                    var cFieldValue = cField.options.find(cf => cf.value == currMatch)
                                    if (cFieldValue) currMatch = cFieldValue.label;
                                }
                                if (!cField) return <div></div>
                                return <tr key={"mAdd_" + index}>

                                    <td width="10">If</td>
                                    <td><div style={{ textAlign: "center", width: "100%", display: "inline-block", border: "1px solid grey", borderRadius: "5px", padding: "5px", fontSize: "12px" }}>{cField.fieldDesc}</div></td>
                                    <td width="10">=</td>
                                    <td><div style={{ textAlign: "center", width: "100%", display: "inline-block", border: "1px solid grey", borderRadius: "5px", padding: "5px", fontSize: "12px" }}>{currMatch}</div></td>

                                    <td width="100">send from</td>
                                    <td><div style={{ textAlign: "center", width: "100%", display: "inline-block", border: "1px solid grey", borderRadius: "5px", padding: "5px", fontSize: "12px" }}>{addressRule.fromName}</div></td>
                                    <td><div style={{ textAlign: "center", width: "100%", display: "inline-block", border: "1px solid grey", borderRadius: "5px", padding: "5px", fontSize: "12px" }}>{addressRule.fromEmail}</div></td>

                                </tr>
                            })}

                        </table>
                    </div>
                </UILIB.Column>}
            </div>
            <hr className="light-hr mar-b25" />
            <UILIB.Row>
                <UILIB.Column xs={12} margin={0}>
                    <div className="mar-b15"><strong>Share Campaign</strong></div>
                    <div className="quickFlex" style={{ width: '100%' }}>
                        <UILIB.SocialSharer
                            url={thisShareUrl}
                            title={campaign.subject}
                            summary={i18n.t('campaigns:FinishedAddingCampaignScreen.shareText') + " \'" + campaign.subject + "\'"}
                            imageUri={`${siteVars.repoEndPoint}userContent/` + this.props.user.userID + "/templates/screenshots/template" + campaign.TemplateId + ".jpg"}
                            hashTags={campaign.subject ? campaign.subject.replace(",", "") + ",email,emailcampaign,latestnews" : ''}
                            style={{ marginRight: "10px", textAlign: 'center' }}
                            size="25px"
                        />
                        <div style={{ flexGrow: 1 }}>
                            <UILIB.TextInput value={thisShareUrl} iconRight={<UILIB.Icons onClick={() => { this.props.shareClicked(thisShareUrl) }} icon="copyClipboard" style={{ width: "20px", height: "20px" }} />} readOnly={true} />
                        </div>
                    </div>
                </UILIB.Column>
            </UILIB.Row>
        </UILIB.Paper >
    }
}
import React from 'react'
import { Link } from 'react-router-dom'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import dateTimeFunction from '~/Classes/dateTimeFunctions';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import Undo from './undo'
import PermissionChecker from '~/Classes/permissions';
import i18n from '~/i18n'


//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, accountMaster: store.accountMaster }
})
export default class SubscriberImports extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imports: [],
            apps: [],
            total: 0,
            loadingData: 1,
            pageSize: 15,
            currentPage: 0,
            isBlacklist: false
        };

        this.getImports = this.getImports.bind(this);
        this.changePage = this.changePage.bind(this);
        this.downloadfile = this.downloadfile.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.undo = this.undo.bind(this)
        this.getApplications = this.getApplications.bind(this)
    }

    componentDidMount() {
        var groupID = this.props.match.params.groupID;
        var isBlacklist = true;
        if (this.props.accountMaster.accountMaster.blackListGroupId != groupID) {
            isBlacklist = false;
        }
        this.setState({
            isBlacklist
        })
        this.getApplications();
        this.timer = setInterval(this.getImports, 5000)
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer)
    }

    getApplications() {
        axios.get('/application').then(response => {
            this.setState({
                apps: response.data.Applications
            }, this.getImports)
        })
    }

    changePage(currentPage) {
        if (!currentPage && currentPage != "0") {
            currentPage = this.state.currentPage;
        }

        this.setState({ currentPage: currentPage }, this.getImports);

    }

    changePageSize(size) {
        this.setState({
            pageSize: size
        }, this.getImports)
    }

    downloadfile(job) {
        axios.get('/group/' + this.props.match.params.groupID + '/import/' + job.id + '/file')
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute('download', job.origFileName);
                document.body.appendChild(link);
                link.click();
            })
    }

    undo(job) {
        var drawerContent = <Undo confirm={() => {
            axios.delete('/group/' + this.props.match.params.groupID + '/import/' + job.id).then(this.getImports).catch(() => { })
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", drawerContent, true));
        }} cancel={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", drawerContent, true));
        }} />

        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));

    }

    getImports() {
        var canEdit = PermissionChecker('subscribers', this.props.permissionStore.permissions, "write");
        var self = this
        axios
            .get('/group/' + this.props.match.params.groupID + '/import?limit=' + this.state.pageSize + '&skip=' + this.state.currentPage * this.state.pageSize)
            .then((res) => {

                var imports = res
                    .data.Imports
                    .map((thisLine, index) => {
                        var text = thisLine.origFileName || 'From External Site'
                        var hasLink = !!thisLine.awsFileName
                        if (thisLine.AccountApplicationId) {
                            var app = this.state.apps.find(a => a.AccountApplications.length && a.AccountApplications[0].id == thisLine.AccountApplicationId)
                            if (app) text = app.appName
                        }
                        return {
                            fileName: {
                                headerValue: i18n.t('subscribers:add.import.progress.fileName'),
                                value: hasLink ? <span>{text} <a onClick={() => this.downloadfile(thisLine)}><i className="icon-download2"></i></a></span> : text,
                                orderBy: false
                            },
                            importStatus: {
                                headerValue: i18n.t('subscribers:add.import.progress.status'),
                                value: thisLine.deleting ? <span className="loading">Deleting</span> : <Link style={{ textDecoration: 'none' }} to={'/cp/groups/' + this.props.match.params.groupID + '/add/import/' + (thisLine.importStatus == 1 ? 'setup/' : 'progress/') + thisLine.id}>
                                    {i18n.t('subscribers:add.import.progress.status' + thisLine.importStatus)}
                                </Link>,
                                orderBy: false
                            },
                            recordsTotal: {
                                headerValue: i18n.t('subscribers:add.import.progress.total'),
                                value: thisLine.recordsExist + thisLine.newSubscribersImported,
                                orderBy: false,
                                align: "center"
                            },

                            recordsNew: {
                                headerValue: i18n.t('subscribers:add.import.progress.newRecords'),
                                value: thisLine.newSubscribersImported,
                                orderBy: false,
                                align: "center"
                            },
                            recordsUpdated: {
                                headerValue: i18n.t('subscribers:add.import.progress.updated'),
                                value: thisLine.recordsExist,
                                orderBy: false,
                                align: "center"
                            },
                            recordsDupes: {
                                headerValue: i18n.t('subscribers:add.import.progress.duplicates'),
                                value: thisLine.recordsDupes,
                                orderBy: false,
                                align: "center"
                            },
                            recordsInvalid: {
                                headerValue: i18n.t('subscribers:add.import.progress.invalid'),
                                value: thisLine.recordsInvalid,
                                orderBy: false,
                                align: "center"
                            },
                            recordsRequired: {
                                headerValue: i18n.t('subscribers:add.import.progress.missing'),
                                value: thisLine.recordsRequired,
                                orderBy: false
                            },
                            createdAt: {
                                headerValue: i18n.t('subscribers:add.import.progress.started'),
                                value: dateTimeFunction.formatDateTime(thisLine.createdAt),
                                orderBy: false
                            },
                            updatedAt: {
                                headerValue: i18n.t('subscribers:add.import.progress.finished'),
                                value: thisLine.importStatus >= 20 ? dateTimeFunction.formatDateTime(thisLine.updatedAt) : '',
                                orderBy: false
                            },
                            user: {
                                headerValue: i18n.t('subscribers:add.import.progress.importedBy'),
                                value: thisLine.AccountUser ? <div className="text-center">
                                    <UILIB.Avatar style={{ height: '30px', width: '30px' }} tooltip={thisLine.AccountUser.firstName + " " + thisLine.AccountUser.lastName} src={thisLine.AccountUser.profilePicFileName} /></div> : 'Unknown',
                                orderBy: false,
                                width: 50
                            },
                            options: {
                                headerValue: " ",
                                value: canEdit ? <UILIB.OptionsDropdown popWidth="150px">
                                    <ul>
                                        <li><a onClick={() => this.undo(thisLine)}>{i18n.t('subscribers:add.import.progress.undo')}</a></li>
                                    </ul>
                                </UILIB.OptionsDropdown> : "",
                                orderBy: false,
                                fixed: true,
                                fixedSml: true,
                                width: 20
                            }
                        }
                    })

                self.setState({ imports, total: res.data.count, loadingData: 0 });
            })
            .catch(console.log)
    }
    render() {

        var self = this;
        return <div>

            <UILIB.Row className="middle-lg middle-md">
                <UILIB.Column xs={6}>
                    <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={() => this.props.history.push('/cp/groups/' + this.props.match.params.groupID)}>{i18n.t('subscribers:nav.goBack')}</UILIB.Hint>
                </UILIB.Column>
                <UILIB.Column xs={6} className="end-xs mar-b25 hide-xs" style={{ display: "flex", alignItems: "center" }} >
                    <div>{i18n.t('showing')}</div>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.pageSize === 15} onClick={() => { this.changePageSize(15) }}>15</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 500} onClick={() => { this.changePageSize(500) }}>500</UILIB.ButtonSimple>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row>

                <UILIB.Column xs={12} >
                    <UILIB.Paper>

                        <UILIB.DataTable1
                            noResultsTxt={<a onClick={() => this.props.history.push('/cp/groups/' + this.props.match.params.groupID + '/add')}> {i18n.t('subscribers:imports.noImports')}</a>}
                            tableData={this.state.imports}
                            loadingData={this.state.loadingData}
                            dataUpdater={this.tableUpdater}
                            width="100%"
                            pageSize={this.state.pageSize}
                            hasCheckBoxes="no"
                        />


                    </UILIB.Paper>

                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row>
                <UILIB.Column xs={12} className="center-xs">
                    <UILIB.PagingBlock
                        totalRows={this.state.total}
                        pageSize={this.state.pageSize}
                        numberOfLinks="10"
                        currentPage={this.state.currentPage}
                        changePage={this.changePage}
                        text={i18n.t('page') + ":"} />
                </UILIB.Column>
            </UILIB.Row>

        </div>

    }
};

class TextFunctions {
    static checkForHtml(input) {
        function replaceAll(str, find, replace) {
            return str.replace(find, replace);
        }

        let err = false;
        if (input && input.length && typeof input === 'string') {
            const regex = /<(\S*?)[^>]>.?<\1>|<.*?>/gmi;
            input = replaceAll(input, regex, "")
        }
        return input
    }

    static removeScriptCapableTags(input) {

        let propsToRemove = ["onabort", "onauxclick", "onbeforeinput", "onbeforematch", "oncancel", "oncanplay", "oncanplaythrough",
            "onchange", "onclick", "onclose", "oncontextlost", "oncontextmenu", "oncontextrestored", "oncopy", "oncuechange", "oncut",
            "ondblclick", "ondrag", "ondragend", "ondragenter", "ondragleave", "ondragover", "ondragstart", "ondrop", "ondurationchange",
            "onemptied", "onended", "onformdata", "oninput", "oninvalid", "onkeydown", "onkeypress", "onkeyup", "onloadeddata",
            "onloadedmetadata", "onloadstart", "onmousedown", "onmouseenter", "onmouseleave", "onmousemove", "onmouseout", "onmouseover",
            "onmouseup", "onpaste", "onpause", "onplay", "onplaying", "onprogress", "onratechange", "onreset", "onsecuritypolicyviolation",
            "onseeked", "onseeking", "onselect", "onslotchange", "onstalled", "onsubmit", "onsuspend", "ontimeupdate", "ontoggle", "onvolumechange",
            "onwaiting", "onwebkitanimationend", "onwebkitanimationiteration", "onwebkitanimationstart", "onwebkittransitionend", "onwheel",
            "onblur", "onerror", "onfocus", "onload", "onresize", "onscroll", "onscrollend", "onafterprint", "onbeforeprint", "onbeforeunload", "onhashchange",
            "onlanguagechange", "onmessage", "onmessageerror", "onoffline", "ononline", "onpagehide", "onpageshow", "onpopstate", "onrejectionhandled", "onstorage",
            "onunhandledrejection", "onunload", "onreadystatechange", "onvisibilitychange", "onbounce", "onstart", "onfinish", "onanimationend", "onanimationiteration",
            "onanimationstart", "ontransitionend", "onbeforescriptexecute", "onloadend", "onpointerlockchange", "onpointerlockerror", "onselectstart"]
        input = input.replace(/\<script.+?\>/gmi, "");
        input = input.replace(/\<\/script.+?\>/gmi, "");
        propsToRemove.forEach(prop => {
            let rReg = new RegExp(prop + '=".*?"', 'gmi');
            input = input.replace(rReg, "")
            rReg = new RegExp(prop + '=', 'gmi');
            input = input.replace(rReg, "")
        })
        input = input.replace(/"javascript:/gmi, "\"")
        return input;
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}

export default TextFunctions;
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import ProgressBreakdown from './progressBreakdown';
import ProgressSubscribers from './progressSubscribers';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here


//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})



export default class ImportProgress extends React.Component {

    constructor(props) {
        super(props);

        let timer;

        this.state = {
            firstLoad: true,
            importData: {
                newSubscribersImported: 0,
                recordsDupes: 0,
                recordsExist: 0,
                recordsInvalid: 0
            },
            drawerContent: null,
            error: 0,
            percentComplete: 0
        };
        this.getImportData = this.getImportData.bind(this);
        this.progress = this.progress.bind(this)
    }

    componentDidMount() {
        this.setState({
            groupID: this.props.match.params.groupID,
            importID: this.props.match.params.importID
        }, this.getImportData);

        this.timer = setInterval(this.progress, 2000);


    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getImportData() {
        var self = this;
        axios.get('/group/' + this.state.groupID + '/import/' + this.state.importID + "?ignoreLog=1").then(function (res) {
            var importData = res.data.ImportJob;

            importData.importStatusText = i18n.t('subscribers:add.import.progress.status' + importData.importStatus)
            if (importData.importStatus == 99 && importData.error) {
                importData.importStatusText += (' - ' + importData.error);
            }

            if (importData.importStatus == 99 || importData.importStatus == 20) {
                clearInterval(self.timer);
            }

            self.setState({
                importData: importData,
                percentComplete: res.data.PercentComplete || 0,
                application: res.data.Application,
                firstLoad: false
            })
        }).catch(function (error) {
            console.log(error)
        });
    }
    progress = () => {
        // if (this.state.importData.recordsProcessed == this.state.importData.recordsTotal) {
        //     clearInterval(this.timer);
        // } else {

        this.getImportData();
        // }
    };

    viewSubs(type) {
        var drawerContent
        if (type == 'new' || type == 'updated') {
            drawerContent = drawerContent = <ProgressSubscribers groupId={this.state.groupID} application={this.state.application} importer={this.state.importData} importId={this.state.importID} type={type} update={Date.now()} />
        } else {
            drawerContent = drawerContent = <ProgressBreakdown groupId={this.state.groupID} application={this.state.application} importer={this.state.importData} importId={this.state.importID} type={type} update={Date.now()} />
        }
        if (this.props.standalone) {
            return this.setState({ drawerContent })
        }
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true, '100vw', 'full'));
    }

    render() {

        if (this.state.firstLoad) return <UILIB.LoadingIcons iconType="2" />
        const data = [{ name: 'recordsTotal', value: this.state.importData.newSubscribersImported }, { name: 'recordsDupes', value: this.state.importData.recordsDupes },
        { name: 'recordsExist', value: this.state.importData.recordsExist }, { name: 'recordsInvalid', value: this.state.importData.recordsInvalid }]

        const importJob = this.state.importData
        const totalProcessed = importJob ? importJob.importStatus === 20 ? importJob.recordsTotal : importJob.recordsInvalid + importJob.recordsExist + importJob.newSubscribersImported : 0

        return <>

            <UILIB.Row>
                <UILIB.Column xs={8} sm={6} margin={0}>
                    <span className={"text-heavy " + (this.state.importData.importStatus == 99 ? 'text-red' : '')}>{this.state.importData.importStatusText}</span>
                </UILIB.Column>
                {!importJob.AccountApplicationId || !importJob.recordsTotal && <UILIB.Column xs={4} sm={6} className="end-xs" margin={0}>
                    <span className="text-small text-primary text-heavy">{this.state.percentComplete}%</span>
                </UILIB.Column>}
                <UILIB.Column xs={12} className="mar-b10">
                    {!!importJob.AccountApplicationId && !!importJob.recordsTotal && <UILIB.SmallLineGraph width="100%" height="8" val1={totalProcessed} val2={importJob.recordsTotal} />}
                    {!importJob.AccountApplicationId || !importJob.recordsTotal && <UILIB.SmallLineGraph width="100%" height="8" hideText={true} onlyPercent={true} val1={this.state.percentComplete} val2={100} />}
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={6} md={3} margin={0} className="mar-b25">
                    <UILIB.Paper className="mar-b10 center-xs">
                        <h4 className="text-green mar-b25">{this.state.importData.newSubscribersImported}</h4>
                        <div className="mar-b15">{i18n.t('subscribers:add.import.progress.success')}</div>
                        <UILIB.Button iconRight={<UILIB.Icons icon="magnifier" />} onClick={() => { this.viewSubs('new') }}>{i18n.t('view')}</UILIB.Button>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} md={3} margin={0} className="mar-b25">
                    <UILIB.Paper className="mar-b10 center-xs">
                        <h4 className="text-purple mar-b25">{this.state.importData.recordsExist}</h4>
                        <div className="mar-b15">{i18n.t('subscribers:add.import.progress.updated')}</div>
                        <UILIB.Button iconRight={<UILIB.Icons icon="magnifier" />} onClick={() => { this.viewSubs('updated') }}>{i18n.t('view')}</UILIB.Button>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} md={3} margin={0} className="mar-b25">
                    <UILIB.Paper className="mar-b10 center-xs">
                        <h4 className="text-orange mar-b25">{this.state.importData.recordsDupes}</h4>
                        <div className="mar-b15">{i18n.t('subscribers:add.import.progress.duplicates')}</div>
                        <UILIB.Button iconRight={<UILIB.Icons icon="magnifier" />} onClick={() => { this.viewSubs('dupes') }}>{i18n.t('view')}</UILIB.Button>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} md={3} margin={0} className="mar-b25">
                    <UILIB.Paper className="mar-b10 center-xs">
                        <h4 className="text-red mar-b25">{this.state.importData.recordsInvalid + this.state.importData.recordsRequired}</h4>
                        <div className="mar-b15">{i18n.t('subscribers:add.import.progress.invalid')}</div>
                        <UILIB.Button iconRight={<UILIB.Icons icon="magnifier" />} onClick={() => { this.viewSubs('invalid') }}>{i18n.t('view')}</UILIB.Button>
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>

            {
                !!this.state.drawerContent && <UILIB.Drawer standalone={true} showClose={true} isOpen={true} close={() => this.setState({ drawerContent: null })} width="100vw">
                    {this.state.drawerContent}
                </UILIB.Drawer>
            }
        </>
    };
};


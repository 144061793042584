import React, { useEffect, useRef, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import UILIB from '../../../../../../Common-Objects/Lib'
import axios from '../../../../../../data/http/axios'
import i18n from '../../../../../../i18n'
import DateTimeFunctions from '../../../../../../Classes/dateTimeFunctions'

export default function FormDownloads() {
    const { groupId, formId } = useParams()
    const timer = useRef(null)
    const history = useHistory()
    const [form, setForm] = useState(null)
    const [downloads, setDownloads] = useState(null)
    const [total, setTotal] = useState(null)
    const [page, setPage] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [formFileId, setFormFileId] = useState(null)

    const getForm = async () => {
        const res = await axios.get(`/group/${groupId}/form/${formId}`)
        setForm(res.data.Form)
        getDownloads()
    }

    const getDownloads = async () => {
        if (timer.current) clearTimeout(timer.current)
        let q = `?limit=20&offset=${20 * page}`
        if (searchText && searchText.length > 2) q += `&search=${searchText}`
        if (formFileId) q += `&FormFileId=${formFileId}`
        const res = await axios.get(`/group/${groupId}/form/${formId}/download${q}`)
        setDownloads(res.data.rows)
        setTotal(res.data.count)
    }

    const updateSearch = e => {
        setSearchText(e.target.value)
        if (timer.current) clearTimeout(timer.current)
        timer.current = setTimeout(() => getDownloads(), 300)
    }

    useEffect(() => {
        getForm()
    }, [groupId, formId])

    useEffect(() => {
        getDownloads()
    }, [page, formFileId])

    if (!downloads || !form) return <UILIB.LoadingIcons />

    const data = downloads.map(row => {
        return {
            createdAt: {
                headerValue: 'Download Date',
                value: DateTimeFunctions.formatDateTime(row.createdAt),
            },
            emailAddress: {
                headerValue: 'Email Address',
                value: row.Subscriber ? <a onClick={() => history.push('/cp/subscriber/' + row.SubscriberId)}>{row.Subscriber.emailAddress}</a> : '--Removed--'
            },
            file: {
                headerValue: 'File',
                value: row.FormFile?.FileManagerFile?.friendlyName || row.FormFile?.url || 'DELETED'
            },
            downloads: {
                headerValue: 'Downloads',
                value: row.downloads
            }
        }
    })

    return (
        <div>
            <UILIB.Row className="middle-lg middle-md">
                <UILIB.Column md={6} sm={12} xs={12} className="mar-b25">
                    <UILIB.Select data={[{ label: "All Files", value: null }, ...form.FormFiles.map(ff => ({ label: ff.url || ff.FileManagerFile?.friendlyName, value: ff.id }))]} label="Filter by File" value={formFileId} onChange={e => setFormFileId(e.target.value)} />
                </UILIB.Column>
                <UILIB.Column md={6} sm={12} xs={12} className="mar-b25">
                    <UILIB.TextInput
                        label="Search for Email Address"
                        placeholder="email@address.com"
                        type="email"
                        style={{ minWidth: "250px" }}
                        value={searchText}
                        onChange={updateSearch}
                        onEnterPress={getDownloads}
                    />
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.DataTable1
                noResultsTxt={i18n.t('form:view.noSignups')}
                tableData={data}
                width="100%"
                pageSize={20}
                sortedColumn="createdAt"
                sortedDirection="desc"
                outerClassName="mar-b25" />


            <div className="center-xs">
                <UILIB.PagingBlock
                    totalRows={total}
                    pageSize={20}
                    numberOfLinks="10"
                    currentPage={page}
                    changePage={p => setPage(p)}
                    text={i18n.t('page') + ":"} />
            </div>
        </div >
    )
}
import React, { Component } from 'react';
import { DropTarget, DragSource } from 'react-dnd'
import ItemTypes from './dndTypes'
import UILIB from '~/Common-Objects/Lib'
const dropSource = {
    drop(props, monitor, component) {
        const incomingServer = monitor.getItem();
        if (props.group.id != incomingServer.currentGroupId) {
            props.serverChangeGroup(incomingServer.serverId, props.group.id, incomingServer.currentGroupId)
        }
        return {}
    },
    hover(props, monitor, component) {

    }
}

@DropTarget([ItemTypes.SERVER], dropSource, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget()
}))

class ServerHolder extends Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.changeRotation = this.changeRotation.bind(this);
    }

    changeRotation(event, isNumeric) {
        var group = this.props.group;
        var value = event.target.value;
        if (isNumeric) value = Number(value);
        group.mailServerRotationSettings[event.target.name] = value;
        this.props.updateState(this.props.group.id, group);
    }

    render() {
        const { canDrop, isOver, connectDropTarget } = this.props

        var server = this.props.server;

        var rotateValues = [];
        var tmpCnt = 0;

        var totMailServers = 0;
        if (this.props.group.mailServers && Array.isArray(this.props.group.mailServers)) {
            totMailServers = this.props.group.mailServers.length + 1
        }

        while (tmpCnt < totMailServers) {
            rotateValues.push(tmpCnt)
            tmpCnt++;
        }
        var rotationSettings = this.props.group.mailServerRotationSettings;
        if (!rotationSettings) rotationSettings = {};
        if (!rotationSettings.number) rotationSettings.number = 0;
        if (!rotationSettings.value) rotationSettings.value = 0;
        if (!rotationSettings.period) rotationSettings.period = "days";

        var rotationEnabled = false;
        return connectDropTarget(
            <div style={{ height: "100%", width: "100%", minHeight: "200px", display: "flex", flexDirection: "column" }}>
                <div style={{ flex: "1", overflow: "auto", paddingLeft: "20px", paddingRight: "20px" }}>{this.props.children}</div>
                {this.props.footer && <div style={{ flex: "0 0 60px", borderTop: "1px solid grey", padding: "20px", paddingTop: "5px", paddingBottom: "5px" }}>
                    <span className="mar-r10 mar-b0">Rotate</span>
                    <div className="form-group mar-r10 mar-b0" style={{ display: "inline-block", width: "50px" }}>
                        <UILIB.Select data={rotateValues} name="number" value={rotationSettings.number} onChange={(event) => { this.changeRotation(event, true) }} />
                    </div>
                    <span className="mar-r10 mar-b0">
                        servers
                    </span>
                    {rotationSettings.number > 0 && <span>
                        <span className="mar-r10 mar-b0">
                            every
                        </span>
                        <div className="form-group mar-r10 mar-b0" style={{ display: "inline-block", width: "50px" }}>
                            <UILIB.Select data={rotateValues} name="value" value={rotationSettings.value} onChange={(event) => { this.changeRotation(event, true) }} />
                        </div>
                        <div className="form-group mar-b0" style={{ display: "inline-block", width: "80px" }}>
                            <UILIB.Select name="period" value={rotationSettings.period} data={[{ label: "Days", value: "days" }, { label: "Weeks", value: "weeks" }, { label: "Months", value: "months" }]} onChange={this.changeRotation} />
                        </div>
                    </span>}
                </div>}

            </div>
        )
    }
}
export default ServerHolder;
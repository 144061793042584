import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import EmailTools from '~/Classes/emailValidator';
import i18n from '~/i18n'
import HelperFunctions from '~/Classes/helpers'
export default class MultiAddressRules extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rules: [],
            customFields: [],
            defaultEmail: "",
            defaultEmailErr: "",
            defaultFromName: "",
            defaultFromNameErr: "",
            defaultReplyAddress: "",
            defaultReplyAddressErr: "",
            genericError: "",
            loaded: false,
            savedRules: [],
            loadedSavedRules: false,
            savingRules: false,
            savingRulesErr: "",
            saveRulesName: "",
            saveRulesNameErr: "",
            lastLoadedRulesName: "",
            loadingRules: false,
            clearRules: false,
            mailServerGroups: []
        }

        this.addRule = this.addRule.bind(this);
        this.changeRuleVal = this.changeRuleVal.bind(this);
        this.removeRule = this.removeRule.bind(this);
        this.saveRules = this.saveRules.bind(this);
        this.loadRules = this.loadRules.bind(this);
        this.saveAndExit = this.saveAndExit.bind(this);
        this.changeDefaultEmail = this.changeDefaultEmail.bind(this);
        this.validateEmailAddress = this.validateEmailAddress.bind(this);
        this.getSavedRules = this.getSavedRules.bind(this);
        this.changeSaveRulesName = this.changeSaveRulesName.bind(this);
        this.saveRuleSet = this.saveRuleSet.bind(this);
        this.loadSavedRule = this.loadSavedRule.bind(this);
        this.deleteRule = this.deleteRule.bind(this);
        this.setToAny = this.setToAny.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.goClearRules = this.goClearRules.bind(this);
        this.cloneRule = this.cloneRule.bind(this);
        this.getDomains = this.getDomains.bind(this);
        this.checkMailServerGroups = this.checkMailServerGroups.bind(this);
        this.checkRuleMailServerGroup = this.checkRuleMailServerGroup.bind(this);
    }

    componentDidMount() {
        if (this.props.campaign.options && this.props.campaign.options.multiAddressRuleName) {
            var eRuleName = this.props.campaign.options.multiAddressRuleName;
            this.setState({ lastLoadedRulesName: eRuleName, saveRulesName: eRuleName })
        }
        this.checkMailServerGroups();
    }

    checkMailServerGroups() {
        axios.get('/mailServer/group').then(groupData => {
            this.setState({ mailServerGroups: groupData.data.Groups || [] });
            this.getCustomFields();
        }).catch(() => {
            this.getCustomFields();
        })
    }

    getCustomFields() {
        axios.get('/customField').then(cFields => {
            var customFields = [];
            cFields.data.CustomFields.forEach(cField => {
                if (!cField.hidden) {
                    customFields.push({ label: i18n.t('campaigns:multiEmailPicker.customField') + " -> " + cField.fieldDesc, value: cField.id, options: cField.options });
                }
            })
            this.setState({ customFields }, this.getGroups)
        })
    }

    getGroups() {
        axios.get('/group').then(gpDat => {
            var groups = gpDat.data.Groups.map(gp => {
                return { label: gp.groupName, value: gp.id };
            })

            var customFields = this.state.customFields;
            customFields.unshift({ label: i18n.t('campaigns:multiEmailPicker.aMemberOfGroup'), value: "mpzGroup", options: groups })
            this.setState({ groups }, this.getSavedRules)
        })
    }
    getSavedRules() {
        //get saved rules if there is some, otherwise defaults
        var rules = [];
        if (this.props.campaign.multiAddressRules) rules = this.props.campaign.multiAddressRules.map(tR => {
            tR.errors = { customField: "", match: "", replyAddress: "", fromEmail: "", fromName: "" }
            return tR
        });
        rules = this.getDomains(rules);
        rules.forEach(rule => {
            if (!rule.guid) {
                rule.guid = HelperFunctions.createLocalGuid()
            }
        })
        this.setState({ rules, defaultEmail: this.props.campaign.fromAddress, defaultFromName: this.props.campaign.fromName, defaultReplyAddress: this.props.campaign.replyAddress, loaded: true })
    }

    addRule() {
        var rules = this.state.rules;
        var guid = HelperFunctions.createLocalGuid();

        rules.push({ guid: guid, customField: this.state.customFields[0].value, match: "", fromName: "", fromEmail: "", replyAddress: "", errors: { customField: "", match: "", replyAddress: "", fromEmail: "", fromName: "" } })
        this.setState({ rules, loadedSavedRules: false, savingRules: false, savingRulesErr: "" })
    }

    removeRule(index) {
        var rules = this.state.rules;
        var guid = rules[index].guid;
        rules = rules.filter(r => r.parentRule != guid && r.guid != guid);
        this.setState({ rules, loadedSavedRules: false, savingRules: false, savingRulesErr: "" })
    }

    changeRuleVal(event, foundMailServerGroup) {

        var rules = this.state.rules;
        var thisData = event.target.name.split("_");
        var oldCustomFieldType = rules[thisData[0]].customField;
        var value = event.target.value;

        if (foundMailServerGroup.found && (thisData[1] == "fromEmail" || thisData[1] == "replyAddress") && value.indexOf("@") > -1) {
            value = value.split("@")[0];
        }

        var thisRule = rules[thisData[0]];
        thisRule[thisData[1]] = value;
        //update children
        if (thisData[1] == "match" || thisData[1] == "customField") {
            rules.filter(r => r.parentRule == thisRule.guid).forEach(rule => {
                rule[thisData[1]] = value;
            })
        }

        if (oldCustomFieldType != rules[thisData[0]].customField) {
            thisRule.match = "";

            //update children
            rules.filter(r => r.parentRule == thisRule.guid).forEach(rule => {
                rule.match = "";
            })

        }
        rules = this.getDomains(rules);
        this.setState({ rules, loadedSavedRules: false, savingRules: false, savingRulesErr: "" });
    }

    changeDefaultEmail(event) {

        this.setState({ [event.target.name]: event.target.value, loadedSavedRules: false, savingRules: false, savingRulesErr: "" })
    }

    changeSaveRulesName(event) {
        this.setState({ [event.target.name]: event.target.value, loadedSavedRules: false })
    }

    validateEmailAddress(emailAddress) {
        return axios.get('/accountMaster/domain/check?email=' + emailAddress)
            .then(response => {
                var isVerified = false;
                if (response.data.hasOwnProperty("Domain") === false) {
                    isVerified = true;
                }
                else {
                    isVerified = response.data.Domain.verified;
                }
                if (!response.data.Exists) {
                    isVerified = false;
                }
                return (isVerified)
            }).catch(() => {
                return false;
            })
    }

    saveAndExit() {

        this.setState({ loadedSavedRules: false })

        var defaultEmailErr = "";
        var defaultReplyAddressErr = "";
        var defaultFromNameErr = "";
        var rules = this.state.rules;
        var genericError = "";
        var failed = false;

        if (!this.state.defaultFromName || this.state.defaultFromName.length < 3) {
            defaultFromNameErr = "Invalid Name";
            failed = true;
        }

        if (!this.state.defaultEmail || this.state.defaultEmail.indexOf("@") < 1 || !EmailTools.validateEmail(this.state.defaultEmail)) {
            defaultEmailErr = "Invalid Email Address";
            failed = true;
        }
        if (!this.state.defaultReplyAddress || this.state.defaultReplyAddress.indexOf("@") < 1) {
            defaultReplyAddressErr = "Invalid Email Address";
            failed = true;
        }

        this.validateEmailAddress(this.state.defaultEmail).then(isValidated => {
            if (!isValidated) {
                failed = true;
                defaultEmailErr = <a href="/cp/company/domains" target="_blank">Address not Validated</a>;
            }

            var promises = [];
            rules.forEach((rule, ruleIndex) => {

                let foundMailServerGroup = this.checkRuleMailServerGroup(rule);
                var errors = { customField: "", match: "", replyAddress: "", fromEmail: "", fromName: "" };

                if ((!rule.customField || isNaN(rule.customField)) && rule.customField != "mpzGroup") {
                    errors.customField = "Invalid";
                    failed = true;
                }
                if (!rule.match || !(rule.match + "").length) {
                    errors.match = "Invalid";
                    failed = true;
                }
                if (!rule.fromName || !rule.fromName.length) {
                    errors.fromName = "Invalid";
                    failed = true;
                }
                if (foundMailServerGroup.found) {
                    if (!rule.fromEmail || !rule.fromEmail.length) {
                        errors.fromEmail = "Invalid";
                        failed = true;
                    }
                    if (!rule.replyAddress || !rule.replyAddress.length) {
                        errors.replyAddress = "Invalid";
                        failed = true;
                    }
                }
                else {
                    if (!rule.fromEmail || !rule.fromEmail.length || rule.fromEmail.indexOf("@") < 1 || !EmailTools.validateEmail(rule.fromEmail)) {
                        errors.fromEmail = "Invalid";
                        failed = true;
                    }
                    if (!rule.replyAddress || !rule.replyAddress.length || rule.replyAddress.indexOf("@") < 1) {
                        errors.replyAddress = "Invalid";
                        failed = true;
                    }
                    if (!errors.fromEmail || !errors.fromEmail.length) {
                        promises.push(this.validateEmailAddress(rule.fromEmail).then(_res => {
                            return { ruleIndex, result: _res }
                        }))
                    }
                }

                rule.errors = errors;
            })

            return Promise.all(promises)
        }).then(_results => {

            if (_results && _results.length) {
                _results.forEach(domainRes => {
                    if (!domainRes.result) {
                        rules[domainRes.ruleIndex].errors.fromEmail = "Not Verified";
                        failed = true;
                    }
                })
            }

            this.setState({ defaultReplyAddressErr, defaultEmailErr, defaultFromNameErr, rules, genericError })
            if (failed) return;

            //get rid of the error messages and stuff
            var finalRules = rules.map(rule => {
                delete rule.errors;
                return rule
            })
            this.props.createdRules(finalRules, this.state.defaultEmail, this.state.defaultFromName, this.state.defaultReplyAddress, this.state.lastLoadedRulesName);
        })



    }

    saveRules() {
        this.setState({ loadedSavedRules: false, savingRulesErr: "" })
        if (!this.state.rules || !this.state.rules.length) {
            this.setState({ savingRules: true, savingRulesErr: i18n.t('campaigns:multiEmailPicker.pleaseAddRulesWarning') })
            return;
        }

        this.setState({ savingRules: true, savingRulesErr: "" })

    }

    saveRuleSet() {
        if (!this.state.saveRulesName || !this.state.saveRulesName.length || this.state.saveRulesName.length < 3) {
            this.setState({ saveRulesNameErr: i18n.t('campaigns:multiEmailPicker.invalidName') })
            return;
        }
        axios.post('/campaign/campaignMultiAddressRule', { name: this.state.saveRulesName, rules: this.state.rules }).then(_res => {
            this.setState({ savingRules: false, saveRulesNameErr: "", saveRulesName: this.state.saveRulesName, lastLoadedRulesName: this.state.saveRulesName })
        }).catch(err => {

            this.setState({ saveRulesNameErr: err.data.error })
        })

    }

    loadRules() {
        this.setState({ loadedSavedRules: false, savingRules: false, savingRulesErr: "", loadingRules: true })
        axios.get('/campaign/campaignMultiAddressRule').then(_res => {
            var rules = _res.data.results;
            this.setState({ savedRules: rules, loadedSavedRules: true, loadingRules: false })
        })
    }

    loadSavedRule(index) {

        var currRuleName = this.state.savedRules[index].name;
        var rules = this.getDomains(this.state.savedRules[index].rules);

        rules.forEach(rule => {
            if (!rule.guid) {
                rule.guid = HelperFunctions.createLocalGuid()
            }
        })
        this.setState({ rules: rules, loadedSavedRules: false, saveRulesName: currRuleName, lastLoadedRulesName: currRuleName })
    }

    getDomains(rules) {
        rules = rules.map(r => {
            var trackingDomain = "";
            var trackingDomainId = 0;
            var cdnDomain = "";
            var cdnDomainId = 0;

            var sendingDomain = "";
            var sendingDomainId = 0;

            if (r.fromEmail && r.fromEmail.length) {
                var fromDomain = r.fromEmail.split('@')[1] || "";
                this.props.trackingDomains.forEach(tD => {
                    if (tD.authenticated && tD.domainName.indexOf(fromDomain) > -1) {
                        trackingDomain = tD.domainName;
                        trackingDomainId = tD.id;
                    }
                })
                this.props.cdnDomains.forEach(tD => {
                    if (tD.authenticated && tD.domainName.indexOf(fromDomain) > -1) {
                        cdnDomain = tD.domainName;
                        cdnDomainId = tD.id;
                    }
                })

                this.props.sendingDomain.forEach(d => {
                    if (d.domainName.toLowerCase() === fromDomain.toLowerCase() && d.verified && d.authenticated) {
                        sendingDomain = d.domainName;
                        sendingDomainId = d.id;
                    }
                })
            }
            r.trackingDomain = trackingDomain;
            r.trackingDomainId = trackingDomainId;
            r.cdnDomain = cdnDomain;
            r.cdnDomainId = cdnDomainId;
            r.sendingDomain = sendingDomain;
            r.sendingDomainId = sendingDomainId;
            return r;

        })
        return rules;
    }

    deleteRule(ruleId) {
        var _self = this;
        axios.delete('/campaign/campaignMultiAddressRule/' + ruleId).then(_res => {
            _self.loadRules();
        })
    }

    setToAny(ruleName, dontBlank) {
        var rules = this.state.rules;
        var thisData = ruleName.split("_");
        var thisRule = rules[thisData[0]];

        thisRule[thisData[1]] = dontBlank ? "MPZANYVAL" : "";
        rules.forEach(rule => {
            if (rule.parentRule != undefined) {
                if (rule.parentRule == thisRule.guid) {
                    rule[thisData[1]] = dontBlank ? "MPZANYVAL" : "";
                }
            }
        })
        this.setState({ rules, loadedSavedRules: false, savingRules: false, savingRulesErr: "" });
    }

    goClearRules() {
        this.setState({ rules: [], clearRules: false })
    }

    cloneRule(index) {
        var rules = this.state.rules;
        var cRule = JSON.parse(JSON.stringify(rules[index]))

        var lastIndex = index;
        rules.forEach((r, subIndex) => {
            if (r.parentRule == cRule.guid) {
                lastIndex = subIndex;
            }
        })
        cRule.guid = HelperFunctions.createLocalGuid();
        rules.splice(lastIndex + 1, 0, cRule)
        this.setState(rules);
    }

    addSubRule(index) {
        var rules = this.state.rules;
        var cRule = JSON.parse(JSON.stringify(rules[index]))
        cRule.parentRule = cRule.guid;
        cRule.guid = HelperFunctions.createLocalGuid();
        rules.splice(index + 1, 0, cRule)
        this.setState(rules);
    }

    checkRuleMailServerGroup(rule) {
        let found = false;
        let match = "";

        if (this.props.campaign && this.props.campaign.options && this.props.campaign.options.MailServers && this.props.campaign.options.MailServers.length) {
            //check to see if all the mailservers in this campaign match a groups mailservers
            this.state.mailServerGroups.filter(g => g.triggerType == 0).forEach(g => {
                if (g.liveDomains.length < 0) return;
                if (g.liveMailServers && g.liveMailServers.length) {
                    var foundAll = true;
                    g.liveMailServers.forEach((ms) => {
                        if (this.props.campaign.options.MailServers.indexOf(ms) < 0) foundAll = false;
                    })
                    if (foundAll) {
                        match = g.name;
                        found = true;
                    }
                }
            })
        }
        else {
            //check the rules FROM NAME matches any of our groups
            this.state.mailServerGroups.filter(g => g.triggerType == 1).forEach(g => {
                let parentRule = null;
                if (rule.parentRule && rule.parentRule.length) {
                    parentRule = this.state.rules.find(r => r.guid == rule.parentRule)
                }
                if (g.triggerValue.toLowerCase() == rule.fromName.toLowerCase()) {
                    found = true;
                    match = rule.fromName;
                }

                if (parentRule && g.triggerValue.toLowerCase() == parentRule.fromName.toLowerCase()) {
                    found = true;
                    match = parentRule.fromName;
                }
            })
        }
        return { found, match };
    }
    render() {

        if (!this.state.loaded) return <div><UILIB.LoadingIcons iconType="2" /></div>
        var noCustomFieldsLink = i18n.t('campaigns:multiEmailPicker.noCustomFieldsLink').split("[LINK]");
        noCustomFieldsLink.splice(0, 0, <a onClick={() => { window.open('/cp/subscribers/customfields', '_self') }}>{i18n.t('campaigns:multiEmailPicker.clickHere')}</a>)

        return <div>
            <h4 className="mar-b25">{i18n.t('campaigns:multiEmailPicker.header')}</h4>
            <div className="mar-b15">{i18n.t('campaigns:multiEmailPicker.headerDesc1')}</div>
            <div className="mar-b25">{i18n.t('campaigns:multiEmailPicker.headerDesc2')}</div>

            {this.state.customFields.length <= 0 && <div>
                <h4 className="text-red">{i18n.t('campaigns:multiEmailPicker.noCustomFields')}</h4>
                <div>{noCustomFieldsLink}</div>
            </div>}

            {(this.state.customFields.length > 0) && <div>

                <UILIB.Paper>
                    <UILIB.Row className="mar-b25">
                        <UILIB.Column xs={4} margin={0}>
                            <UILIB.TextInput label={i18n.t('campaigns:multiEmailPicker.defaultFromName')} name="defaultFromName" value={this.state.defaultFromName} onChange={this.changeDefaultEmail} error={this.state.defaultFromNameErr} />
                        </UILIB.Column>
                        <UILIB.Column xs={4} margin={0}>
                            <UILIB.TextInput label={i18n.t('campaigns:multiEmailPicker.defaultFromAddress')} name="defaultEmail" value={this.state.defaultEmail} onChange={this.changeDefaultEmail} error={this.state.defaultEmailErr} />
                        </UILIB.Column>
                        <UILIB.Column xs={4} margin={0}>
                            <UILIB.TextInput label={i18n.t('campaigns:multiEmailPicker.defaultReplyAddress')} name="defaultReplyAddress" value={this.state.defaultReplyAddress} onChange={this.changeDefaultEmail} error={this.state.defaultReplyAddressErr} />
                        </UILIB.Column>
                    </UILIB.Row>

                    <UILIB.Hint
                        style={{ margin: "0px" }}
                        iconLeft={<UILIB.Icons icon="questionCircle" />}>
                        {i18n.t('campaigns:multiEmailPicker.rulesExplainer')}
                    </UILIB.Hint>
                </UILIB.Paper>

                {this.state.rules.length > 0 &&
                    <UILIB.Paper>
                        <h4 className="mar-b25">{i18n.t('campaigns:multiEmailPicker.rules')}
                            {this.state.lastLoadedRulesName && <span style={{ marginLeft: "10px" }}>
                                ({this.state.lastLoadedRulesName})
                            </span>}
                        </h4>

                        <UILIB.Row>
                            <UILIB.Column xs="3">
                                <span className="text-sml grey">{i18n.t('campaigns:multiEmailPicker.field')}</span>
                            </UILIB.Column>
                            <UILIB.Column xs="2">
                                <span className="text-sml grey">{i18n.t('campaigns:multiEmailPicker.matches')}</span>
                            </UILIB.Column>
                            <UILIB.Column xs="2">
                                <span className="text-sml grey">{i18n.t('campaigns:multiEmailPicker.fromName')}</span>
                            </UILIB.Column>
                            <UILIB.Column xs="2">
                                <span className="text-sml grey">{i18n.t('campaigns:multiEmailPicker.fromAddress')}</span>
                            </UILIB.Column>
                            <UILIB.Column xs="2">
                                <span className="text-sml grey">{i18n.t('campaigns:multiEmailPicker.replyAddress')}</span>
                            </UILIB.Column>
                            <UILIB.Column xs="1">

                            </UILIB.Column>
                        </UILIB.Row>

                        {this.state.rules.map((rule, index) => {
                            var field = this.state.customFields.find(f => f.value == rule.customField)
                            var isSubRule = false;
                            if (rule.parentRule != undefined) isSubRule = true;
                            let foundMailServerGroup = this.checkRuleMailServerGroup(rule);
                            return <UILIB.Row key={"rule_" + index} className="mar-b10">
                                {isSubRule && <UILIB.Column xs="5"></UILIB.Column>}
                                {isSubRule && <UILIB.Column xs="7" style={{ textAlign: "center" }}>
                                    OR
                                </UILIB.Column>}
                                <UILIB.Column xs="3">
                                    {(isSubRule == false) && <UILIB.Select name={index + "_customField"} data={this.state.customFields} error={rule.errors.customField} placeHolder={i18n.t('campaigns:multiEmailPicker.selectACustomField')} onChange={(ev) => { this.changeRuleVal(ev, foundMailServerGroup.found) }} value={rule.customField} ></UILIB.Select>}
                                </UILIB.Column>
                                <UILIB.Column xs="2" style={{ borderRight: "2px solid #DDDDDD" }}>
                                    {!isSubRule && rule.match != "MPZANYVAL" && !!field && !!field.options && !!field.options.length && <UILIB.Select placeholder={i18n.t('campaigns:multiEmailPicker.select')} name={index + "_match"} value={rule.match} onChange={(ev) => { this.changeRuleVal(ev, foundMailServerGroup.found) }} error={rule.errors.match} data={field.options} />}
                                    {!isSubRule && rule.match != "MPZANYVAL" && (!field || !field.options || !field.options.length) && <UILIB.TextInput name={index + "_match"} value={rule.match} onChange={(ev) => { this.changeRuleVal(ev, foundMailServerGroup.found) }} error={rule.errors.match} />}
                                    {!isSubRule && rule.match != "MPZANYVAL" && field.value != "mpzGroup" && <div><a style={{ fontSize: "13px" }} onClick={() => { this.setToAny(index + "_match", true) }}>{i18n.t('campaigns:multiEmailPicker.orAnyValue')}</a></div>}
                                    {!isSubRule && rule.match == "MPZANYVAL" && <div>{i18n.t('campaigns:multiEmailPicker.anyValue')} - <a onClick={() => { this.setToAny(index + "_match", false) }}>{i18n.t('campaigns:multiEmailPicker.change')}</a></div>}
                                </UILIB.Column>
                                <UILIB.Column xs="2">
                                    <UILIB.TextInput name={index + "_fromName"} value={rule.fromName} onChange={(ev) => { this.changeRuleVal(ev, foundMailServerGroup.found) }} error={rule.errors.fromName} />
                                </UILIB.Column>
                                <UILIB.Column xs="2" >
                                    <div className="quickFlex">
                                        <div style={{ width: foundMailServerGroup.found ? "50%" : "100%" }}>
                                            <UILIB.TextInput name={index + "_fromEmail"} value={rule.fromEmail} onChange={(ev) => { this.changeRuleVal(ev, foundMailServerGroup.found) }} error={rule.errors.fromEmail} />
                                        </div>
                                        {foundMailServerGroup.found && <div style={{ fontSize: "12px", width: "50%" }}>@ Auto Domain</div>}
                                    </div>

                                    {!foundMailServerGroup.found && rule.trackingDomainId > 0 && <UILIB.SquareChip alt={rule.trackingDomain} title={rule.trackingDomain} value={"Tracking: " + rule.trackingDomain} className="square-chip-blue" style={{ padding: "4px 10px", maxWidth: "100%", marginTop: "10px" }} />}
                                </UILIB.Column>
                                <UILIB.Column xs="2">
                                    <div className="quickFlex">
                                        <div style={{ width: foundMailServerGroup.found ? "50%" : "100%" }}>
                                            <UILIB.TextInput name={index + "_replyAddress"} value={rule.replyAddress} onChange={(ev) => { this.changeRuleVal(ev, foundMailServerGroup.found) }} error={rule.errors.replyAddress} />
                                        </div>
                                        {foundMailServerGroup.found && <div style={{ fontSize: "12px", width: "50%" }}>@ Auto Domain</div>}
                                    </div>
                                    {!foundMailServerGroup.found && rule.cdnDomainId > 0 && <UILIB.SquareChip alt={rule.cdnDomain} title={rule.cdnDomain} value={"CDN: " + rule.cdnDomain} className="square-chip-blue" style={{ padding: "4px 10px", maxWidth: "100%", marginTop: "10px" }} />}

                                </UILIB.Column>
                                <UILIB.Column xs="1" className="quickFlex" style={{ justifyContent: "space-between", alignItems: "flex-start", paddingTop: "13px" }}>
                                    <UILIB.Icons icon="copyClipboard"
                                        className="mar-r10"
                                        alt={i18n.t('campaigns:multiEmailPicker.cloneLine')}
                                        title={i18n.t('campaigns:multiEmailPicker.cloneLine')}
                                        onClick={() => { this.cloneRule(index) }}
                                        style={{ width: "15px", height: "15px" }} />

                                    {!isSubRule && <UILIB.Icons icon="split"
                                        className="mar-r10"
                                        alt="Add an alternative address"
                                        title="Add an alternative address"
                                        onClick={() => { this.addSubRule(index) }}
                                        style={{ width: "15px", height: "15px" }}
                                    />}
                                    <UILIB.Icons icon="cross"
                                        alt={i18n.t('campaigns:multiEmailPicker.deleteLine')}
                                        title={i18n.t('campaigns:multiEmailPicker.deleteLine')}
                                        onClick={() => { this.removeRule(index) }}
                                        style={{ width: "15px", height: "15px" }}
                                        color="red"
                                    />
                                </UILIB.Column>


                            </UILIB.Row>

                        })}

                    </UILIB.Paper>
                }

                {this.state.genericError && this.state.genericError.length > 0 && <div className="error-warning mar-b25">{this.state.genericError}</div>}

                {(!this.state.savingRules && !this.state.clearRules && !this.state.loadedSavedRules && !this.state.loadingRules) &&
                    <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignContent: "center" }}>
                        <div>
                            <UILIB.Button
                                text={this.state.rules.length > 0 ? i18n.t('campaigns:multiEmailPicker.addAnotherRule') : i18n.t('campaigns:multiEmailPicker.addARule')}
                                onClick={this.addRule}
                            />
                            {(this.state.rules.length > 0) && <UILIB.Button
                                text={i18n.t('campaigns:multiEmailPicker.clearRules')}
                                className="mar-l10"
                                onClick={() => { this.setState({ clearRules: true }) }} />
                            }
                        </div>
                        <div>
                            <UILIB.Button
                                text={i18n.t('campaigns:multiEmailPicker.loadRules')}
                                className="mar-r10" onClick={this.loadRules} />

                            <UILIB.Button
                                text={i18n.t('campaigns:multiEmailPicker.saveRules')}
                                onClick={this.saveRules} />
                        </div>

                    </div>
                }

                {this.state.loadingRules && <div>
                    <UILIB.LoadingIcons iconType="2" />
                </div>}
                {this.state.loadedSavedRules && <div>
                    {(!this.state.savedRules || this.state.savedRules.length < 1) && <div>
                        <UILIB.WarningHolder className="mar-b15">
                            <div className="text-heavy">{i18n.t('campaigns:multiEmailPicker.notSavedDesc1')}</div>
                            <div className="mar-b15">{i18n.t('campaigns:multiEmailPicker.notSavedDesc2')}</div>
                        </UILIB.WarningHolder>
                        <UILIB.Button
                            text={i18n.t('campaigns:multiEmailPicker.forgetIt')}
                            onClick={() => { this.setState({ loadedSavedRules: false }) }}
                        />
                    </div>}
                    {(this.state.savedRules && this.state.savedRules.length > 0) && <div className="mar-b20">
                        <div className="text-heavy mar-b25">{i18n.t('campaigns:multiEmailPicker.loadRulesDesc')}</div>
                        <div className="mar-b25">
                            {this.state.savedRules.map((tR, index) => {
                                return <UILIB.Button
                                    key={"rules_" + index}
                                    className={"mar-r10 button-green"}
                                    onClick={() => { this.loadSavedRule(index) }}
                                    iconRight={<UILIB.Icons icon="cross"
                                        color="red"
                                        onClick={(event) => { event.stopPropagation(); this.deleteRule(tR.id) }} />}
                                    text={tR.name}
                                />

                            })}
                        </div>

                        <UILIB.Button text={i18n.t('campaigns:multiEmailPicker.orForgetIt')} className="button-sml outline grey" onClick={() => { this.setState({ loadedSavedRules: false }) }} />

                    </div>
                    }

                </div>}

                {this.state.savingRules && <div>
                    {(this.state.savingRulesErr && this.state.savingRulesErr.length) && <div>
                        <UILIB.WarningHolder className="mar-b15">{this.state.savingRulesErr}</UILIB.WarningHolder>
                        <UILIB.Button text={i18n.t('campaigns:multiEmailPicker.forgetIt')} className="mar-b20" onClick={() => { this.setState({ savingRules: false }) }} />
                    </div>}
                    {(!this.state.savingRulesErr || !this.state.savingRulesErr.length) && <div>
                        <div className="mar-b10">

                            <UILIB.TextInput label={i18n.t('campaigns:multiEmailPicker.enterNameForRuleSet')} type="text" name="saveRulesName" placeholder={i18n.t('campaigns:multiEmailPicker.yourNewRulesetName')} value={this.state.saveRulesName} onChange={this.changeSaveRulesName} error={this.state.saveRulesNameErr} />
                        </div>
                        <div className="quickFlex">
                            <UILIB.Button text={i18n.t('campaigns:multiEmailPicker.saveRules')} className="button-primary mar-r10" onClick={this.saveRuleSet} />
                            <UILIB.Button text={i18n.t('campaigns:multiEmailPicker.forgetIt')} onClick={() => { this.setState({ savingRules: false }) }} />
                        </div>
                    </div>}
                </div>}

                {(this.state.clearRules) && <div className="mar-b20">
                    <div className="mar-b10 text-strong">{i18n.t('campaigns:multiEmailPicker.clearRulesWarning')}</div>
                    <UILIB.Button text={i18n.t('campaigns:multiEmailPicker.yesPlease')} className="button-primary mar-r10" onClick={this.goClearRules} />
                    <UILIB.Button text={i18n.t('campaigns:multiEmailPicker.forgetIt')} onClick={() => { this.setState({ clearRules: false }) }} />
                </div>}

                {(!this.state.savingRules && !this.state.clearRules && !this.state.loadedSavedRules && !this.state.loadingRules) && <div>
                    <UILIB.Button
                        text={i18n.t('campaigns:multiEmailPicker.saveAndExit')}
                        className="button-primary"
                        onClick={this.saveAndExit}
                        iconLeft={<UILIB.Icons icon="tick" />}
                    />
                </div>
                }
            </div >

            }
        </div>
    }

}


import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import {
  emailChannelTabOptions,
  webchatChannelTabOptions,
  facebookMessengerChannelTabOptions,
} from "~/Classes/conversations";
import { handleGenericError } from "~/data/actions/siteActions";
import ChannelTemplate from "./template";

export default function InboxChannel({ userRole = null }) {
  const { path, url } = useRouteMatch();
  const { inboxId, channelId } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [channel, setChannel] = useState(null);
  const [inbox, setInbox] = useState(null);

  useEffect(() => {
    if (userRole > 0) return window.open("/", "_self");
    loadInitialData();
  }, []);

  async function loadInitialData() {
    try {
      await Promise.all([loadChannel(), loadInbox()]);
    } finally {
      setLoading(false);
    }
  }

  async function loadChannel() {
    try {
      const { data } = await axios.get(
        `/inboxes/${inboxId}/channel/${channelId}`
      );
      setChannel(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function loadInbox() {
    try {
      const { data } = await axios.get(`/inboxes/${inboxId}}`);
      setInbox(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function updateChannel(formattedData) {
    const mergedData = {
      ...channel,
      ...formattedData,
      settings: {
        ...channel.settings,
        ...formattedData.settings,
      },
    };

    try {
      const { data } = await axios.put(
        `/inboxes/${inboxId}/channel/${channelId}`,
        mergedData
      );
      setChannel(data);
    } catch (error) {
      throw error;
    }
  }

  if (loading) return <UILIB.LoadingIcons className="mar-t24" />;

  return (
    <Switch>
      <Route exact path={`${path}/:settingName`}>
        <ChannelTemplate
          inbox={inbox}
          channel={channel}
          onUpdateChannel={updateChannel}
        />
      </Route>

      {/* Redirect if "naked" route */}
      <Route exact path={path}>
        {channel.type === 1 && (
          <Redirect to={`${url}/${emailChannelTabOptions[0].value}`} />
        )}
        {channel.type === 2 && (
          <Redirect to={`${url}/${webchatChannelTabOptions[0].value}`} />
        )}
        {channel.type === 3 && (
          <Redirect to={`${url}/${facebookMessengerChannelTabOptions[0].value}`} />
        )}
      </Route>
    </Switch>
  );
}

import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import EmailValidator from '~/Classes/emailValidator'
import TemplateSelector from '../../campaigns/add/steps/step2/standardCampaignStep2'

@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster, accountMaster: store.accountMaster, doingOptions: false, currentAction: {} }
})
class DrawerEditAction extends Component {
    constructor(props) {
        super(props);

        this.state = { newAutomation: {}, mounted: false, automationErrors: {} }

        this.changeOption = this.changeOption.bind(this);
        this.changedAllOptions = this.changedAllOptions.bind(this);
        this.openTemplateSelector = this.openTemplateSelector.bind(this);
        this.saveCampaign = this.saveCampaign.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.checkSendingDomain = this.checkSendingDomain.bind(this);
    }

    componentDidMount() {
        this.setState({ newAutomation: this.props.newAutomation, mounted: true }, function () {
            if (this.props.changingEmailTemplate == true) this.openTemplateSelector();
        })
    }

    changeOption(event) {
        var newAutomation = this.state.newAutomation;
        var newValue = event.target.value;
        if (event.target.type == "email") {
            newValue = event.target.value.trim();
        }
        if (event.target.name.indexOf(".") > 0) {
            var theVal = event.target.name.split(".");
            if (!newAutomation[theVal[0]]) newAutomation[theVal[0]] = {};
            newAutomation[theVal[0]][theVal[1]] = newValue;
        }
        else {
            newAutomation[event.target.name] = newValue;
        }
        this.setState({ newAutomation: newAutomation })
    }


    validateForm() {
        var isOk = true;
        var automationErrors = {};

        this.props.currAction.hasOptions.forEach(theOption => {

            var thisValue = "";
            var thisFieldName = theOption.target;
            if (thisFieldName.indexOf(".") > 0) {
                var fieldDat = thisFieldName.split(".");
                if (this.state.newAutomation[fieldDat[0]] && this.state.newAutomation[fieldDat[0]][fieldDat[1]])
                    thisValue = this.state.newAutomation[fieldDat[0]][fieldDat[1]];
            }

            if (theOption.uiType == "email") {

                var thisEmail = thisValue;
                if (!EmailValidator.validateEmail(thisEmail)) {
                    isOk = false
                    automationErrors[theOption.target] = "Please enter a valid email address";
                } else {
                    // var emailStatus = this.checkSendingDomain(thisEmail)
                    // console.log(emailStatus);
                };
            }
        });
        if (!isOk) this.setState({ automationErrors })
        return isOk;
    }

    checkSendingDomain(theEmailAddress) {
        var finalEmailStatus = {
            verified: false,
            authenticated: false,
            errorMessage: ""
        };

        axios.get('/campaign/checkdomain?email=' + theEmailAddress)
            .then(response => {
                if (response.data.hasOwnProperty("Domain") === false) {
                    finalEmailStatus.verified = true;
                }
                else {
                    finalEmailStatus.verified = response.data.Domain.verified;
                    finalEmailStatus.authenticated = response.data.Domain.authenticated;
                }

                if (finalEmailStatus.isVerified) finalEmailStatus.errorMessage = 'Email Address Not Verified'
                if (response.data.Exists) finalEmailStatus.errorMessage = 'Domain not recognised'
            })
            .catch(err => {
                if (err.data && err.data.error) {
                    errorMessage.errorMessage = 'Invalid Email Address'
                } else {
                }
            }).finally(() => {
                return (finalEmailStatus);
            })
    }

    changedAllOptions() {
        var isOk = this.validateForm();
        if (isOk) this.props.updatedStep(this.state.newAutomation, false, false, this.props.automationIndex);
    }
    openTemplateSelector() {

        var isOk = this.validateForm();
        if (isOk) {
            var campaign = this.state.newAutomation.campaign
            var thisAutomation = this.state.newAutomation;
            campaign.type = 3
            campaign.AutomationId = this.props.automationId
            if (!this.props.automationDataId) {
                campaign.campaignName = 'Automation ' + this.props.automationName + ' Step ' + this.props.automationIndex
                thisAutomation.campaign = campaign;
            }

            this.saveCampaign().then(response => {

                thisAutomation.CampaignId = response.data.Campaign.id
                thisAutomation.campaign = response.data.Campaign
                if (this.props.automationDataId != undefined) {

                    //if we are updating an existing campaign, just inject the template type
                    if (thisAutomation.campaign.Template) {
                        thisAutomation.campaign.Template.templateType = 0;
                    }
                    if (!thisAutomation.campaign.Template) {
                        thisAutomation.campaign.Template = { templateType: 0 }
                    }

                }

                var params = 'redirect=' + encodeURI('/cp/automation/add/' + this.props.automationId + '?step=' + this.props.automationIndex)
                var drawerContent = <TemplateSelector params={params} campaign={thisAutomation.campaign} history={this.props.history} saveCampaign={this.saveCampaign} />
                this.props.dispatch(siteMaster.alterSiteDrawer(true, false, "right", drawerContent, true, '100vw', 'full campaign-holder'));

                this.props.updatedStep(thisAutomation, true, true, this.props.automationDataId);
            })

        }
    }

    saveCampaign() {
        var campaign = this.state.newAutomation.campaign
        if (campaign.id) {
            return axios.put('/campaign/' + campaign.id, { campaign })
        } else {
            return axios.post('/campaign', campaign)
        }
    }

    render() {

        if (!this.state.mounted) return <div><UILIB.LoadingIcons iconType="2" /></div>
        var customFields = this.props.customFields;
        var groups = this.props.groups;
        var doneAllOptions = true;
        var theHTML = [];

        this.props.currAction.hasOptions.map((theOption, index) => {

            var thisValue = "";
            var thisFieldName = theOption.target;
            if (thisFieldName.indexOf(".") > 0) {
                var fieldDat = thisFieldName.split(".");
                if (this.state.newAutomation[fieldDat[0]] && this.state.newAutomation[fieldDat[0]][fieldDat[1]])
                    thisValue = this.state.newAutomation[fieldDat[0]][fieldDat[1]];
            }
            else {
                if (this.state.newAutomation[theOption.target])
                    thisValue = this.state.newAutomation[theOption.target]
            }
            if (thisValue == "" && theOption.uiType != "emailTemplate") {
                doneAllOptions = false;
            }

            var thisErrorText = "";
            if (this.state.automationErrors && this.state.automationErrors[theOption.target]) {
                thisErrorText = this.state.automationErrors[theOption.target]
            }

            if (theOption.uiType == "select") {

                if (theOption.type == "customField" && theOption.dataType == "DATE") {
                    customFields = this.props.customFields.filter(theField => theField.fieldType == "DATE");
                }
                if (theOption.type == "group" && theOption.dataType == "NOTSELF") {
                    groups = this.props.groups.filter(theGroup => theGroup.id != this.props.groupId);
                }

                var dropDownData = [];
                if (theOption.type == "group") {
                    groups.forEach(theGroup => {
                        dropDownData.push({ "label": theGroup.groupName, "value": theGroup.id })
                    })
                }
                if (theOption.type == "customField") {
                    customFields.forEach(theField => {
                        dropDownData.push({ "label": theField.fieldDesc, "value": theField.id })
                    })
                }
                dropDownData.unshift({ "label": theOption.label, "value": "" })

                theHTML.push(<div key={"cField_" + index}>
                    <div className="form-group">
                        <label htmlFor="">{theOption.longDesc}</label>
                        <UILIB.Select name={theOption.target} data={dropDownData} onChange={this.changeOption} value={thisValue} />
                    </div>
                </div>)
            }


            if (theOption.uiType == "text") {
                theHTML.push(<div key={"cField_" + index}>
                    <div className="form-group">
                        <label htmlFor="">{theOption.longDesc}</label>
                        <UILIB.TextInput name={theOption.target} value={thisValue} onChange={this.changeOption} placeHolder={theOption.label} error={thisErrorText} />
                    </div>
                </div>)
            }

            if (theOption.uiType == "timePicker") {
                theHTML.push(<div key={"cField_" + index}>
                    <div className="form-group">
                        <label htmlFor="">{theOption.longDesc}</label>
                        <UILIB.TextInput type="time" name={theOption.target} value={thisValue} onChange={this.changeOption} placeHolder={theOption.label} error={thisErrorText} />
                    </div>
                </div>)
            }
            if (theOption.uiType == "email") {
                theHTML.push(<div key={"cField_" + index}>
                    <div className="form-group">
                        <label htmlFor="">{theOption.longDesc}</label>
                        <UILIB.TextInput type="email" name={theOption.target} value={thisValue} onKeyPress={this.changeOption} onChange={this.changeOption} placeHolder={theOption.label} error={thisErrorText} />
                    </div>
                </div>)
            }
            if (theOption.uiType == "delayEditor" && this.state.newAutomation.action == "email") {

                theHTML.push(<div key={"cField_" + index}>
                    <h4 className="text-secondary mar-b25">Wait...</h4>
                    <div className="form-group mar-b0">
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={12} md={6}>
                                <p style={{ marginRight: "10px" }}><UILIB.TextInput type="number" name="delay.value" value={thisValue.value} onChange={this.changeOption} /></p>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={12} md={6}>
                                <p style={{ marginRight: "10px" }}><UILIB.Select name="delay.unit" data={[{ label: 'Hours', value: 'hours', }, { label: 'Days', value: 'days' }, { label: 'Weeks', value: 'weeks' }, {
                                    label: 'Months', value: 'months'
                                }]} onChange={this.changeOption} value={thisValue.unit} /></p>
                            </UILIB.Column>
                        </UILIB.Row>
                    </div>
                    <h4>Then {this.props.currAction.label}...</h4>
                </div>)
            }

            if (theOption.uiType == "emailTemplate" && doneAllOptions) {
                doneAllOptions = false;
                theHTML.push(<div key={"cField_" + index}>
                    <UILIB.Row>
                        <UILIB.Column xs={12}>
                            <p className="text-sml no-marg">{theOption.longDesc}</p>
                        </UILIB.Column>
                    </UILIB.Row>
                    <UILIB.Row>
                        <UILIB.Column xs={12}>
                            <UILIB.Button text={theOption.label} className="outline primary button-sml" onClick={this.openTemplateSelector} />
                        </UILIB.Column>
                    </UILIB.Row>
                </div>)
            }

        })

        doneAllOptions && theHTML.push(<UILIB.Button text="Continue" className="outline primary button-sml" onClick={this.changedAllOptions} />)

        return <div>
            <div className="mar-b20"><h4>{this.props.currentTrigger.longDesc}</h4></div>
            {theHTML}
        </div >

    }
}

export default DrawerEditAction;
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})
export default class IncGoUpdateTemplateGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groupId: this.props.groupId,
            groupName: "",
            groupErr: ""
        };

        this.loadGroup = this.loadGroup.bind(this);
        this.updateGroupName = this.updateGroupName.bind(this);
        this.saveGroup = this.saveGroup.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    componentDidMount() {
        this.loadGroup()
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    loadGroup() {
        var self = this;
        axios.get('/templateGroup/' + this.state.groupId)
            .then(response => {
                self.setState({ groupName: response.data.templateGroup.groupName });
            });
    }

    updateGroupName(evt) {
        this.setState({
            groupName: evt.target.value
        })
    }


    saveGroup() {
        var newGroup = this.props.group;
        newGroup.groupName = this.state.groupName;
        var self = this;
        axios.put('/templateGroup/' + self.state.groupId,
            {
                groupName: self.state.groupName
            })
            .then(response => {
                self.closeDrawer();
                self.props.tableDataGetter(newGroup);
            }).catch(err => {
                self.setState({ groupErr: err.data.error });
            });
    }

    deleteGroup() {
        var self = this;
        axios.delete('/templateGroup/' + self.state.groupId).then(res => {
            self.closeDrawer();
            self.props.deleteGroup();
        })
    }

    render() {

        return <div>
            <h4 className="mar-b25">{i18n.t('templates:index.editHeader')}</h4>


            <UILIB.TextInput label={i18n.t('templates:index.groupName')} outerClassName="mar-b25" type="text" value={this.state.groupName} onChange={this.updateGroupName} placeholder={i18n.t('templates:index.groupName')} error={this.state.groupErr} />

            <div className="quickFlex">
                <UILIB.Button text={i18n.t('save')} className="button-primary mar-r10" onClick={this.saveGroup} />
                <UILIB.Button text={i18n.t('delete')} onClick={this.deleteGroup} />
            </div>
        </div >
    }
};


import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'

export default class ComponentSelect extends Component {
    constructor(props) {
        super(props)
        this.change = this.change.bind(this);
    }

    change(theVal) {
        this.props.updateVal(this.props.path, theVal);
    }

    render() {
        var style = {};
        if (this.props.elementData.optionFullWidth) style = { flex: "100%" }
        return <div className="dd_toolHolder" style={style}>
            <div className="dd_inputTool">
                <p className="txtInput_label">{this.props.title}</p>
                <div className="inputWrapper">
                    <div className="dd_alignHolder">
                        {this.props.options.map((theOpt, index) => {
                            var theType = theOpt.value;
                            var selected = "";
                            if (this.props.value === theType) {
                                selected = " selected";
                            }

                            var theTypeIcon = theType;
                            switch (theTypeIcon) {
                                case "flex-start":
                                    {
                                        theTypeIcon = "left";
                                        break;
                                    }
                                case "flex-end":
                                    {
                                        theTypeIcon = "right";
                                        break;
                                    }
                                default:
                                    {
                                        theTypeIcon = theTypeIcon;
                                    }
                            }
                            theTypeIcon = theTypeIcon.charAt(0).toUpperCase() + theTypeIcon.slice(1);
                            return <UILIB.ButtonSimple
                                className="button-simple-fullsize"
                                selected={this.props.value === theType}
                                onClick={() => { this.change(theType) }}
                                iconLeft={<UILIB.Icons
                                    icon={"align" + theTypeIcon} />} />

                        })}

                    </div>
                </div>
            </div>

        </div>





    }
}
import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'
import { Editor } from '@tinymce/tinymce-react';
import MergeTags from '~/pages/cp/includes/mergeTags/mergeTags'
import CampaignMergeTags from '../../../../../../includes/mergeTags/campaignMergeTags.jsx';
import FileManager from '~/pages/cp/includes/fileManager/fileManager'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import MPZColorPicker from './colorPickerComponent.jsx'
import MPZLinkPicker from './linkPickerComponent.jsx'
import DragDropFunctions from '../../../dragDropFunctions';
import AiContentGenerator from '../../../../../../includes/ai/contentGenerator';
import axios from '~/data/http/axios';
var timer;
import i18n from '~/i18n'
@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

export default class WysiwygEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            templateHTML: "",
            loaded: false,
            showColorPicker: false,
            lastColor: "",
            showLinkPicker: false,
            linkCurrVal: "",
            disableHidePickers: false,
            inlineDrawer: undefined,
            showAiCTA: true
        }
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleEditorCommand = this.handleEditorCommand.bind(this);
        this.openDrawer = this.openDrawer.bind(this);
        this.onMergeTagClick = this.onMergeTagClick.bind(this);
        this.pickNewColor = this.pickNewColor.bind(this);
        this.hideExternalPickers = this.hideExternalPickers.bind(this);
        this.pickNewLink = this.pickNewLink.bind(this);
        this.toggleHidePickers = this.toggleHidePickers.bind(this);
        this.clearContent = this.clearContent.bind(this);
        this.dismissAiCta = this.dismissAiCta.bind(this);
    }
    componentDidMount() {
        //window.addEventListener("click", this.hideExternalPickers);
        //window.addEventListener("blur", this.hideExternalPickers)
        let showAiCTA = true;
        if (this.props.user && this.props.user.userData && this.props.user.userData.firstLoad && this.props.user.userData.firstLoad.dismissedDragDropAICTA) {
            showAiCTA = false;
        }
        this.setState({
            templateHTML: this.props.value,
            loaded: true,
            showAiCTA
        })
        //export functions
        if (this.props.clearContent) {
            this.props.clearContent(this.clearContent);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.hideExternalPickers)
        window.removeEventListener("blur", this.hideExternalPickers)
        clearTimeout(timer);
    }

    handleEditorCommand(e) {
        if (e.command === "mpzFile") {
            this.openDrawer("fileManager");
        }
        if (e.command === "mpzMergeTags") {
            this.openDrawer("mpzMergeTags");
        }
        if (e.command === "mpzAiGenerator") {
            this.openDrawer("aiContentGenerator");
        }
        if (e.command === "mpzColorPicker") {
            this.openDrawer("mpzColorPicker", e)
        }
        if (e.command === "mpzPickLink") {
            this.openDrawer("mpzPickLink", e)
        }
    }
    clearContent() {
        return this.refs.tinyMCEEditor.editor.editorCommands.execCommand('mceSetContent', "", "");
    }
    openDrawer(ourDrawer, e) {
        if (ourDrawer === "fileManager") {
            var theDrawer = <FileManager onFileClick={(res) => { this.onFileManagerClick(res.url) }} fileTypes={"all"} />
            if (this.props.standalone) {
                this.setState({ inlineDrawer: theDrawer })
            }
            else {
                this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '100vw', 'fix-height'));
            }
        }
        if (ourDrawer === "mpzMergeTags") {

            var theDrawer = <MergeTags landingPage={this.props.isLandingPage} campaign={this.props.campaign} AutomationId={this.props.campaign ? this.props.campaign.AutomationId : undefined} onTagClick={this.onMergeTagClick} />

            if (this.props.additionalTools && this.props.additionalTools.length && this.props.additionalTools.indexOf("campaignMergeTags") > -1) {
                theDrawer = <CampaignMergeTags AutomationId={this.props.AutomationId} hasWorkflowEmails={this.props.hasWorkflowEmails} includeSubscriber={true} includeSubCustomFields={true} insertMergeTag={this.onMergeTagClick} />
            }
            if (this.props.standalone) {
                this.setState({ inlineDrawer: theDrawer })
            }
            else {
                this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, "500px"));
            }
        }

        if (ourDrawer === "mpzColorPicker") {
            this.setState({ showColorPicker: true, showLinkPicker: false })
        }
        if (ourDrawer === "mpzPickLink") {
            this.setState({ showLinkPicker: true, showColorPicker: false, linkCurrVal: e.value ? e.value : '' })
        }
        if (ourDrawer === "aiContentGenerator") {
            var theDrawer = <AiContentGenerator contentType="paragraph" insertText={this.onMergeTagClick} close={() => {
                this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
            }} />
            if (this.props.standalone) {
                this.setState({ inlineDrawer: theDrawer })
            }
            else {
                this.props.dispatch(siteMaster.alterSiteDrawer(true, false, "right", theDrawer, true, "700px", "drawer_dark"));
            }
        }
    }

    pickNewColor(thisVal) {
        var newCol = thisVal;
        if (typeof thisVal === "object") {
            newCol = thisVal.hex;
        }
        this.setState({ lastColor: newCol })
        clearTimeout(timer);
        timer = setTimeout(() => {
            this.refs.tinyMCEEditor.editor.editorCommands.execCommand('ForeColor', false, this.state.lastColor);
        }, 100);
    }

    pickNewLink(thisVal, target, title) {
        var linkAttrs = {
            href: thisVal,
            target: target,
            title: title
        };
        this.setState({ showLinkPicker: false, linkCurrVal: "" })
        this.refs.tinyMCEEditor.editor.editorCommands.execCommand('mceInsertLink', false, linkAttrs);

    }

    onMergeTagClick(tag) {
        this.refs.tinyMCEEditor.editor.editorCommands.execCommand('mceInsertContent', false, tag);
        if (this.props.standalone) {
            this.setState({ inlineDrawer: undefined })
        }
        else {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null));
        }
    }

    handleEditorChange(content) {
        var self = this;
        var content = content;
        self.setState({ templateHTML: content });
        clearTimeout(timer);
        timer = setTimeout(() => {
            self.props.updateVal(self.props.path, content);
        }, 100);
    }

    hideExternalPickers(e) {

        if (this.state.disableHidePickers) {
            return;
        }

        if (e && e.target) {
            if (e.target.className && e.target.className.indexOf('forecolor') > 1) return
            if (e.target.className && e.target.className.indexOf('-link') > 1) return
            if (e.target.children) {
                if (e.target.firstChild && e.target.firstChild.className && e.target.firstChild.className.indexOf('-link') > 1) return
            }

        }
        this.setState({ showColorPicker: false, showLinkPicker: false })
    }

    toggleHidePickers(doHide) {
        this.setState({ disableHidePickers: doHide })
    }

    dismissAiCta() {
        var firstLoad = { dismissedDragDropAICTA: false };
        if (typeof this.props.user.userData.firstLoad === "object" & this.props.user.userData.firstLoad != undefined) {
            firstLoad = this.props.user.userData.firstLoad;
        }
        if (firstLoad.dismissedDragDropAICTA && firstLoad.dismissedDragDropAICTA == true) return;
        firstLoad.dismissedDragDropAICTA = true;
        axios.put("/accountUser", { firstLoad: firstLoad })
        this.setState({ showAiCTA: false })
    }
    render() {
        if (!this.state.loaded) return <div></div>
        var advFontSizes = "";
        var xCnt = 8;
        while (xCnt < 160) {
            advFontSizes += xCnt + "px ";
            xCnt += 1;
        }
        var self = this;
        advFontSizes = advFontSizes.substr(0, advFontSizes.length - 1)

        var style = { position: "relative", width: "100%", flex: "100%" };
        var isSimple = false;
        var toolBar = `code  bold italic underline strikethrough  mpzColorPicker  bullist numlist  outdent indent  ${!this.props.hideMergeTags && 'mpzMergeTags'}  mpzLinkPicker unlink anchor hr   alignleft aligncenter alignright alignjustify  formatselect styleselect fontsizeselect fontselect  removeformat`

        let bgColor = DragDropFunctions.getParentColor(this.props?.elementsData, this.props?.selectedElement?.path);

        if (this.props.elementData) {
            if (this.props.elementData.wysiwygMode && this.props.elementData.wysiwygMode == "simple") {
                isSimple = true;
                toolBar = "bold italic underline strikethrough";
            }
            if (this.props.elementData && this.props.elementData.wysiwygHeight) {
                style.height = this.props.elementData.wysiwygHeight
            }
        }

        if (this.props.wysiwygMode && this.props.wysiwygMode == "simple") {
            isSimple = true;
            toolBar = "bold italic underline strikethrough";
        }

        if (this.props.additionalTools && Array.isArray(this.props.additionalTools) && this.props.additionalTools.length) {
            this.props.additionalTools.forEach(tool => {
                var toolName = tool;
                if (toolName == "campaignMergeTags") toolName = "mpzMergeTags"
                toolBar += " " + toolName;
            })
        }


        if (this.props.wysiwygHeight) {
            style.height = this.props.wysiwygHeight
        }

        if (this.props.error) {
            style.border = "1px solid red"
        }

        //work out background color
        var themeAdvancedFontsData = DragDropFunctions.lookupVariables('[OPTIONS.FONTARRAY]')
        var themeAdvancedFonts = "";
        themeAdvancedFontsData.forEach(f => {
            themeAdvancedFonts += f.label + "=" + f.value + ";";
        })

        let aiEnabled = this.props.siteMaster.siteId == 1;
        if (!aiEnabled) aiEnabled = this.props.siteMaster.aiEnabled;
        // let aiEnabled = false;
        // if (this.props.accountMaster.accountMaster.beta && this.props.accountMaster.accountMaster.beta.aiAssistant) aiEnabled = true;

        if (!isSimple) {
            var defaultFont = "Arial"
            if (this.props.localVariables && this.props.localVariables.textFontFamily) {
                defaultFont = this.props.localVariables.textFontFamily.value;
                defaultFont = DragDropFunctions.replaceGlobalVariable(defaultFont)
            }

            return <div>
                {/* <div style={{ margin: "0", color: "#202432", opacity: "0.6", fontSize: "12px", textTransform: "uppercase", marginBottom: "10px" }}>{this.props.title}</div> */}
                {(aiEnabled == true && this.state.showAiCTA == true) && <UILIB.Paper className="paper_aiAssistant_cta">
                    <div style={{ display: "flex", justifyContent: "space-between" }} className="mar-b15">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <UILIB.Icons square={true} color="#FFFFFF" icon="sparkle2" style={{ width: "25px", height: "25px" }} className="mar-r10" />
                            <div className="text-heavy">{i18n.t('templates:aiContentAssistant.promoHeader')}</div>
                        </div>
                        <div><UILIB.Icons icon="cross" color="#FFFFFF" onClick={this.dismissAiCta} /></div>
                    </div>
                    <div className="mar-b20">
                        {i18n.t('templates:aiContentAssistant.promoBody')}
                    </div>

                    <UILIB.Button className="button-darkmode-trans" text={i18n.t('templates:aiContentAssistant.tryItOut')} iconRight={<UILIB.Icons color="#FFFFFF" icon="arrowRight" />} onClick={() => { this.dismissAiCta(); this.openDrawer("aiContentGenerator") }} />
                </UILIB.Paper>
                }

                <div className="inlineTinyMce mce-noBottomBorder" style={style} >

                    <Editor
                        ref="tinyMCEEditor"
                        initialValue={this.state.templateHTML}
                        init={{
                            plugins: 'image mpzMergeTags code advlist mpzColorPicker mpzLinkPicker textcolor  lists link paste spellchecker  hr anchor',
                            toolbar: toolBar,
                            browser_spellcheck: true,
                            width: "100%",
                            menubar: false,
                            height: "100%",
                            force_br_newlines: true,
                            force_p_newlines: false,
                            convert_urls: false,
                            forced_root_block: '',
                            imagetools_cors_hosts: [''],
                            imagetools_proxy: 'cdn1.ourmailsender.com',
                            imagetools_toolbar: "imageoptions",

                            formats: [
                                { title: 'Header 1', block: 'h1' }, { title: 'Header 2', block: 'h2' }, { title: 'Header 3', block: 'h3' }, { title: 'Header 4', block: 'h4' }
                            ],
                            style_formats: [
                                { title: 'Table of Contents', selector: "*", classes: "mp_toc_title" }

                            ],
                            fontsize_formats: advFontSizes,
                            font_formats: themeAdvancedFonts,
                            forced_root_block: '',
                            init_instance_callback: function (editor) {
                                editor.on('click', function (e) {
                                    self.hideExternalPickers(e);
                                });
                                editor.on('keyUp', function (e) {
                                    self.handleEditorChange(e.target.innerHTML);
                                });
                                editor.on('change', function (e) {
                                    self.handleEditorChange(editor.getContent());
                                })
                            },
                            content_style: `body { font-family: ${defaultFont}; background-color: ${bgColor};}`
                        }}
                        onExecCommand={(ed, param1, param2) => { this.handleEditorCommand(ed); this.handleEditorChange(ed.target.getContent()); }}
                    />

                    {(this.state.showColorPicker == true) && <div style={{ position: "absolute", top: "45px", left: "140px" }}><MPZColorPicker color={this.state.lastColor} onChange={this.pickNewColor} disableAlpha={true} globalColours={this.props.globalColours} favoriteColors={this.props.favoriteColors} saveFaveColor={this.props.saveFaveColor} /></div>}

                    {(this.state.showLinkPicker == true) && <div style={{ position: "absolute", top: "45px", right: "10px" }}><MPZLinkPicker save={this.pickNewLink} currVal={this.state.linkCurrVal} close={this.hideExternalPickers} toggleHidePickers={this.toggleHidePickers} elementsData={this.props.elementsData} /></div>}

                </div>
                {(aiEnabled == true) && <div className="tinyMce_bottomTools" style={{ backgroundColor: bgColor }} >
                    <div
                        className="tinyMce_bottomTools_aiAssistant border-darkmode-purple-to-blue-horizontal"
                        onClick={() => { this.dismissAiCta(); this.openDrawer("aiContentGenerator") }}
                    >
                        <UILIB.Icons className="tinyMce_bottomTools_aiAssistant_icon" icon="sparkle2" color="#FFFFFF" style={{ width: "20px", height: "20px" }} />
                        <div className="tinyMce_bottomTools_aiAssistant_label">{i18n.t('templates:aiContentAssistant.aiContentAssistant')}</div>
                    </div>
                </div>}

            </div >
        }

        if (this.props.standalone) {
            return <div className={this.props.outerClassName}>
                <div className="dd_toolHolder" style={style}>
                    <div className="inlineTinyMce" style={style} >
                        <Editor
                            ref="tinyMCEEditor"
                            initialValue={this.state.templateHTML}
                            init={{
                                plugins: 'image mpzMergeTags code advlist mpzColorPicker mpzLinkPicker textcolor   link paste spellchecker  hr anchor ',
                                toolbar: toolBar,
                                browser_spellcheck: true,
                                width: "100%",
                                menubar: false,
                                height: "100%",
                                force_br_newlines: true,
                                force_p_newlines: false,
                                forced_root_block: false,
                                convert_urls: false,
                                convert_urls: false,
                                imagetools_cors_hosts: [''],
                                imagetools_proxy: 'cdn1.ourmailsender.com',
                                imagetools_toolbar: "imageoptions",
                                formats: [

                                    { title: 'Header 1', block: 'h1' }, { title: 'Header 2', block: 'h2' }, { title: 'Header 3', block: 'h3' }, { title: 'Header 4', block: 'h4' }
                                ],

                                content_style: '* { text-shadow: 1px 1px 1px #BBBBBB; }',
                                fontsize_formats: advFontSizes,

                                init_instance_callback: function (editor) {
                                    editor.on('click', function (e) {
                                        self.hideExternalPickers(e);
                                    });
                                    editor.on('keyDown', function (e) {
                                        if (e.keyCode === 13) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            return false;
                                        }
                                    });
                                    editor.on('keyUp', function (e) {
                                        self.handleEditorChange(e.target.innerHTML);
                                    });
                                }
                            }}
                            onExecCommand={(ed) => { this.handleEditorCommand(ed) }}
                            onChange={(e) => { this.handleEditorChange(e.target.getContent()) }}
                        />

                    </div >
                </div>
                {this.props.error && <UILIB.FormError key={"err"}>{this.props.error}</UILIB.FormError>}
                {this.state.inlineDrawer && <UILIB.Drawer close={() => { this.setState({ inlineDrawer: undefined }) }} standalone={true} isOpen={true} width={500} showClose={true}>{this.state.inlineDrawer}</UILIB.Drawer>}
                {(this.state.showColorPicker == true) && <div style={{ position: "absolute", top: "45px", left: "140px" }}><MPZColorPicker color={this.state.lastColor} onChange={this.pickNewColor} disableAlpha={true} globalColours={this.props.globalColours} favoriteColors={this.props.favoriteColors} saveFaveColor={this.props.saveFaveColor} /></div>}
                {(this.state.showLinkPicker == true) && <div style={{ position: "absolute", top: "45px", right: "10px" }}><MPZLinkPicker save={this.pickNewLink} currVal={this.state.linkCurrVal} close={this.hideExternalPickers} toggleHidePickers={this.toggleHidePickers} elementsData={this.props.elementsData} /></div>}
            </div>
        }

        return <div className="dd_toolHolder" style={style}>

            <div className="dd_inputTool">
                <p className="txtInput_label">{this.props.title}</p>
                <div className="inputWrapper">
                    <div className="inlineTinyMce" style={style}>


                        <Editor
                            ref="tinyMCEEditor"
                            initialValue={this.state.templateHTML}
                            init={{
                                plugins: 'image mpzMergeTags code advlist mpzColorPicker mpzLinkPicker textcolor   link paste spellchecker  hr anchor ',
                                toolbar: toolBar,
                                browser_spellcheck: true,
                                width: "100%",
                                menubar: false,
                                height: "100%",
                                force_br_newlines: true,
                                force_p_newlines: false,
                                forced_root_block: false,
                                convert_urls: false,
                                convert_urls: false,
                                imagetools_cors_hosts: [''],
                                imagetools_proxy: 'cdn1.ourmailsender.com',
                                imagetools_toolbar: "imageoptions",
                                formats: [

                                    { title: 'Header 1', block: 'h1' }, { title: 'Header 2', block: 'h2' }, { title: 'Header 3', block: 'h3' }, { title: 'Header 4', block: 'h4' }
                                ],

                                fontsize_formats: advFontSizes,

                                init_instance_callback: function (editor) {
                                    editor.on('click', function (e) {
                                        self.hideExternalPickers(e);
                                    });
                                    editor.on('keyDown', function (e) {
                                        if (e.keyCode === 13) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            return false;
                                        }
                                    });
                                    editor.on('keyUp', function (e) {
                                        self.handleEditorChange(e.target.innerHTML);
                                    });
                                }
                            }}
                            onExecCommand={(ed) => { this.handleEditorCommand(ed) }}
                            onChange={(e) => { this.handleEditorChange(e.target.getContent()) }}


                        />

                        {(this.state.showColorPicker == true) && <div style={{ position: "absolute", top: "45px", left: "140px" }}><MPZColorPicker color={this.state.lastColor} onChange={this.pickNewColor} disableAlpha={true} globalColours={this.props.globalColours} favoriteColors={this.props.favoriteColors} saveFaveColor={this.props.saveFaveColor} /></div>}
                        {(this.state.showLinkPicker == true) && <div style={{ position: "absolute", top: "45px", right: "10px" }}><MPZLinkPicker save={this.pickNewLink} currVal={this.state.linkCurrVal} close={this.hideExternalPickers} toggleHidePickers={this.toggleHidePickers} elementsData={this.props.elementsData} /></div>}
                    </div >
                </div>
            </div>
        </div >

    }
}



import React from "react";
import UILIB from "~/Common-Objects/Lib";
import DateTimeFunctions from "~/Classes/dateTimeFunctions";
import ReactTooltip from 'react-tooltip';

export default function ChatMessageGroup({
  isMe = false,
  isAi = false,
  status,
  className,
  children,
  createdAt,
  name,
  channelType = undefined,
  avatar,
  isStaff = false,
  id,
  ...rest
}) {
  if (isAi) {
    name = "AI Assistant";
  }

  const messageDate = DateTimeFunctions.formatDateTime(createdAt, "5");

  return (
    <div
      className={`chat-message-group-container ${isMe ? "chat-message-group-container--is-me" : ""
        } ${isAi ? "chat-message-group-container--is-ai" : ""}`}
      {...rest}
    >
      <UILIB.AvatarNew
        className={`chat-message__avatar ${isAi ? " chat-message__avatar-aiAssistant" : ""
          }`}
        size={24}
        src={avatar}
        name={name}
        color={isAi ? "black" : ""}
      />

      <div
        className={`chat-message-group ${isMe ? "chat-message-group--is-me" : ""
          } ${isAi ? "chat-message-group--is-ai" : ""}`}
      >
        {/* Messages go here */}
        {children}

        {messageDate && <div className="chat-message-meta">
          {/* Renamed from "chat-bubble-meta" */}
          <div className="chat-message-meta__time">
            <time dateTime={messageDate} className="text-xsml" data-for={'chatDate' + id} data-tip>
              <ReactTooltip effect='solid' className="tooltip" id={'chatDate' + id} >{DateTimeFunctions.formatDateTime(createdAt)}</ReactTooltip>
              {messageDate}
            </time>
            {isStaff && <span className="text-xsml"> - {name}</span>}

            {status == "read" && <UILIB.Icon name="message-read-check" />}
            {status == "delivered" && <UILIB.Icon name="message-sent-check" />}
          </div>
        </div>}
      </div>
    </div>
  );
}

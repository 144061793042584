import React, { useState, useEffect } from "react";
import UILIB from "~/Common-Objects/Lib";
import { useHistory } from "react-router-dom";
import axios from "~/data/http/axios";
import i18n from '~/i18n'

export default function SmsInterest({ submitFeedback = () => { }, error = "" }) {

    useEffect(() => {
    }, []);

    return (
        <>
            <UILIB.Dialog.Root open={true}>
                <UILIB.Dialog.Portal >
                    <UILIB.Dialog.Overlay />
                    <UILIB.Dialog.Content style={{ width: "480px" }}>
                        <div style={{
                            width: 478, height: 239,
                            backgroundImage: `url('https://cdn1.ourmailsender.com/siteContent/assets/misc/sms-interest-bg.svg')`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: 'cover'
                        }}>
                        </div>
                        <div style={{ padding: "32px" }}>
                            <h4 className="mar-b12">{i18n.t('company:feedbackPopups.sms.header')}</h4>
                            <div className="text-heavy mar-b12">
                                {i18n.t('company:feedbackPopups.sms.subHeader1')}
                            </div>
                            <div className="mar-b12">{i18n.t('company:feedbackPopups.sms.subHeader2')}</div>
                            <div className="mar-b30">{i18n.t('company:feedbackPopups.sms.subHeader3')}</div>

                            <div style={{ display: "flex" }}>
                                <UILIB.Button iconLeft={<UILIB.Icons icon="thumbsUp" />} className="button-primary mar-r10" text={i18n.t('company:feedbackPopups.sms.yesButton')} onClick={() => { submitFeedback(true) }} />
                                <UILIB.Button className="button-lightGrey" text={i18n.t('company:feedbackPopups.sms.noButton')} onClick={() => { submitFeedback(false) }} />
                            </div>

                            {error && <div className="mar-t12 text-red">{error}</div>}
                        </div>
                    </UILIB.Dialog.Content>
                </UILIB.Dialog.Portal >
            </UILIB.Dialog.Root>
        </>
    );
}

import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class addStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            level: 'READ',
            loaded: false
        };

        this.updateInput = this.updateInput.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.addUser = this.addUser.bind(this);
    }

    componentDidMount() {
        this.setState({
            user: this.props.users && this.props.users.length ? this.props.users[0].id : null
        })
    }

    updateInput(ev) {
        this.setState({ [ev.currentTarget.name]: ev.currentTarget.value })
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    addUser() {
        this.closeDrawer()
        this.props.add(this.state.user, this.state.level)
    }

    render() {
        if (!this.props.users || !this.props.users.length) {
            return <div>{i18n.t('permissions:users.noUsers')}</div>
        } else {
            return <div>
                <h4 className="mar-b25">{i18n.t('permissions:users.inviteUser')}</h4>


                <UILIB.Select name="user" data={this.props.users.map(user => {
                    return {
                        label: user.fullname,
                        value: user.id
                    }
                })} value={this.state.user} onChange={this.updateInput} />


                <UILIB.Select outerClassName="mar-b25" label={i18n.t('permissions:users.level')} name="level" data={[
                    { label: i18n.t('permissions:users.READ'), value: 'READ' },
                    { label: i18n.t('permissions:users.WRITE'), value: 'WRITE' }
                ]} value={this.state.level} onChange={this.updateInput} />

                <UILIB.Button text={i18n.t('permissions:users.add')} className="button-primary" onClick={this.addUser} />


            </div >
        }
    }
};



import React, { Fragment } from 'react'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import NumberFunctions from '~/Classes/numberFormatFunctions';
import StripeAddCard from './stripeAddCard'
import OldUpgrade from './upgrade'
import i18n from '~/i18n';
import NewUserForm from '~/pages/cp/includes/bb_topNav/newUserForm/newUserForm';
import TranspondSiteTracking from '../../../../Classes/siteTrackingHelpers';
import DowngradeToFree from './downgradeToFree';
import moment from 'moment';
//CONNECT TO STORE

@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})
export default class UpgradeDrawerTiers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accountMaster: {},
            currentBilling: {},
            creditPlans: [],
            plans: [],
            paymentCards: [],
            tiers: [],
            billingFrequencies: [{
                label: "Monthly",
                value: 0
            },
            {
                label: "Annually (10% Discount)",
                value: 1
            }],
            selectedExistingPlan: false,
            pageLoaded: false,
            tierChange: null,
            selectedTierId: this.props.accountMaster && this.props.accountMaster.accountMaster ? this.props.accountMaster.accountMaster.accountBillingTierId : -1,
            selectedPlanId: -1,
            selectedFrequencyId: this.props.accountMaster && this.props.accountMaster.accountMaster ? this.props.accountMaster.accountMaster.billingFrequency : -1,
            selectedPaymentCard: -1,
            changingPaymentCard: false,
            creditsSelected: 0,
            okToShowPricing: false,
            calculatedCost: {},
            voucherCode: "",
            voucherError: "",
            addingPaymentCard: false,
            stripeAuthPayment: undefined,
            paymentError: "",
            attemptingPayment: false,
            finishedPayment: false,
            blockReasons: []
        }
        this.loadBaseData = this.loadBaseData.bind(this);
        this.selectTier = this.selectTier.bind(this);
        this.calculatePrice = this.calculatePrice.bind(this);

        this.getAccountMaster = this.getAccountMaster.bind(this);
        this.goChangePlan = this.goChangePlan.bind(this);

        this.getPaymentCards = this.getPaymentCards.bind(this);
        this.selectPaymentcard = this.selectPaymentcard.bind(this);

        this.completePaymentIntent = this.completePaymentIntent.bind(this)
        this.errorPaymentIntent = this.errorPaymentIntent.bind(this)

        this.activateAccount = this.activateAccount.bind(this);
    }

    componentDidMount() {
        this.getAccountMaster();
    }

    async getAccountMaster() {
        let accMaster = await axios.get('/accountMaster')
        accMaster = accMaster.data.AccountMaster;
        this.setState({ accountMaster: accMaster }, this.loadBaseData)
    }
    async loadBaseData() {
        let currBilling = await axios.get('/accountMaster/usage');
        currBilling = currBilling.data.Billing;
        currBilling.friendlyPriceRaw = currBilling.friendlyPrice

        let tiers = await axios.get('/accountMaster/plan/tiers');
        tiers = tiers.data;

        //inject pay as you go tier, only if a user is already on credit billing
        if (this.props.accountMaster.accountMaster.accountType == "credit") {
            tiers.unshift({
                description: "Pay only for what you use",
                id: -9,
                name: "Pay as you Go",
                priceModifierFixed: 0,
                priceModifierPercent: 0,
            })
        }

        let creditPlans = await axios.get('/accountMaster/plan?type=1');
        creditPlans = creditPlans.data.Plans;

        let plans = await axios.get('/accountMaster/plan');
        plans = plans.data.Plans;

        let defaultPlanId = this.props.accountMaster && this.props.accountMaster.accountMaster ? this.props.accountMaster.accountMaster.AccountBillingId : -1
        let defaultTierId = this.props.accountMaster && this.props.accountMaster.accountMaster ? this.props.accountMaster.accountMaster.accountBillingTierId : -1
        let currentPlan = plans.find(plan => plan.id == this.props.accountMaster.accountMaster.AccountBillingId)

        let foundCurrentTier = tiers.find(t => t.id == defaultTierId)
        if (!foundCurrentTier) {
            foundCurrentTier = tiers[1];
            defaultTierId = foundCurrentTier.id;
        }
        if (this.props.accountMaster.accountMaster.accountType == "credit") {
            defaultTierId = -9;
        }

        this.setState({
            currentBilling: currBilling,
            creditPlans: creditPlans,
            plans: plans,
            tiers: tiers,
            selectedPlanId: defaultPlanId,
            selectedTierId: defaultTierId,
        }, this.getPaymentCards)
    }

    async selectTier(tier) {
        let selectedPlanId = -1;
        let selectedFrequencyId = -1;

        let currentTier = this.state.selectedTierId;
        let newTier = tier.id;

        if (currentTier == -9 && newTier != -9) {
            selectedFrequencyId = 0;
        }
        if (currentTier != -9 && newTier != -9) {
            if (this.state.selectedFrequencyId) {
                selectedFrequencyId = this.state.selectedFrequencyId;
            }
            else {
                selectedFrequencyId = 0;
            }
        }

        if (tier.id == this.props.accountMaster.accountMaster.accountBillingTierId) {
            //set defaults to current plan
            selectedPlanId = this.props.accountMaster.accountMaster.AccountBillingId;
            selectedFrequencyId = this.props.accountMaster.accountMaster.billingFrequency;
        }
        this.setState({
            selectedTierId: tier.id,
            selectedPlanId,
            okToShowPricing: false,
            selectedFrequencyId
        }, this.calculatePrice)
    }
    async calculatePrice() {

        const tierChangeResp = await axios.get('/accountMaster/plan/tierchange?tierId=' + (this.state.selectedTierId === -9 ? this.props.siteMaster.defaultTierId : this.state.selectedTierId))
        this.setState({ selectedExistingPlan: false, okToShowPricing: false, tierChange: tierChangeResp.data })

        const currentTier = this.state.tiers.find(t => t.id == this.state.selectedTierId);
        const existingTier = this.state.tiers.find(t => t.id == this.props.accountMaster.accountMaster.accountBillingTierId);
        const existingPlan = this.state.plans.find(p => p.id == this.props.accountMaster.accountMaster.AccountBillingId);

        let okToCalculatePricing = 0;
        if (existingTier?.id == currentTier?.id && existingPlan?.id == this.state.selectedPlanId && this.state.selectedFrequencyId == this.props.accountMaster.accountMaster.billingFrequency) {
            okToCalculatePricing = 3;
        }
        else if (this.state.selectedTierId == -9 && this.state.creditsSelected > 0) {
            okToCalculatePricing = 1;
        }
        else if (currentTier?.isFree) {
            okToCalculatePricing = 0;
        }
        else if (this.state.selectedTierId > 0 && this.state.selectedPlanId > 0 && this.state.selectedFrequencyId > -1) {
            okToCalculatePricing = 2;
        }

        if (!okToCalculatePricing) return;
        console.log(okToCalculatePricing)
        if (okToCalculatePricing == 3) {
            this.setState({ selectedExistingPlan: true, okToShowPricing: false })
            return;
        }
        let apiUrl = `/accountMaster/plan/`
        //credits
        if (okToCalculatePricing == 1) {
            //find a plan
            let matchingCreditPlan = {};
            this.state.creditPlans.forEach(plan => {
                if (plan.maxUniqueSends >= this.state.creditsSelected) {
                    if (!matchingCreditPlan.id || matchingCreditPlan.maxUniqueSends >= plan.maxUniqueSends) {
                        matchingCreditPlan = plan
                    }
                }
            })
            //default to the last credit plan if none match the contact count
            if (!matchingCreditPlan.id) matchingCreditPlan = this.state.creditPlans[this.state.creditPlans.length - 1];
            apiUrl += `${matchingCreditPlan.id}?credits=${this.state.creditsSelected}`
        }
        if (okToCalculatePricing == 2) {
            //subscription
            apiUrl += `${this.state.selectedPlanId}?billingFrequency=${this.state.selectedFrequencyId}&tierId=${this.state.selectedTierId}`
        }
        if (this.state.voucherCode) apiUrl += "&voucherCode=" + this.state.voucherCode
        let calculatedCost = await axios.get(apiUrl)
        calculatedCost = calculatedCost.data;
        if (calculatedCost.tierChange) {
            this.setState({ tierChange: calculatedCost.tierChange })
        }
        this.setState({ okToShowPricing: true, calculatedCost })
    }

    /* Card related */
    async getPaymentCards(skip) {
        let paymentCards = await axios.get('/accountMaster/paymentMethod/stripe')
        paymentCards = paymentCards.data;

        var defaultCard;
        if (paymentCards.customer && paymentCards.customer.default_source) {
            defaultCard = paymentCards.customer.default_source
        }
        this.setState({
            addingPaymentCard: false,
            paymentCards: paymentCards.customer.sources.data,
            selectedPaymentCard: defaultCard,
            pageLoaded: true
        },
            () => {
                if (this.state.selectedPlanId && this.state.selectedTierId) this.calculatePrice()
            })
    }

    async selectPaymentcard(event) {
        try {
            this.setState({ changingPaymentCard: true })
            var newCardId = event.target.value
            await axios.put('/accountMaster/paymentMethod/stripe', {
                default_source: newCardId
            })
            this.setState({ changingPaymentCard: false, selectedPaymentCard: newCardId })
        }
        catch (err) {
            console.log(err)
        }
    }

    async goChangePlan() {
        try {
            this.setState({ attemptingPayment: true, paymentError: "", blockReasons: [] })
            let selectedPlanId = this.state.selectedPlanId ? this.state.selectedPlanId : -1;
            let selectedTierId = this.state.selectedTierId;
            let selectedFrequencyId = this.state.selectedFrequencyId;

            if (this.state.selectedTierId == -9 && this.state.creditsSelected > 0) {
                //find a plan
                let matchingCreditPlan = {};
                this.state.creditPlans.forEach(plan => {
                    if (plan.maxUniqueSends >= this.state.creditsSelected) {
                        if (!matchingCreditPlan.id || matchingCreditPlan.maxUniqueSends >= plan.maxUniqueSends) {
                            matchingCreditPlan = plan
                        }
                    }
                })
                //default to the last credit plan if none match the contact count
                if (!matchingCreditPlan.id) matchingCreditPlan = this.state.creditPlans[this.state.creditPlans.length - 1];
                selectedPlanId = matchingCreditPlan.id;
                selectedTierId = 0;
                selectedFrequencyId = 2;
            }


            let savedPlan = await axios.put('/accountMaster/plan', {
                planId: selectedPlanId,
                tierId: selectedTierId,
                voucherCode: this.state.voucherCode,
                frequencyId: Number(selectedFrequencyId),
                credits: this.state.creditsSelected
            })
            this.setState({
                attemptingPayment: false
            })
            try {
                //send payment event
                TranspondSiteTracking.manualPageChange(null, null, null, null, null, "Payment",
                    {
                        value: this.state.calculatedCost.totalIncDiscount,
                        planId: selectedPlanId,
                        tierId: selectedTierId,
                        voucherCode: this.state.voucherCode,
                        frequencyId: Number(selectedFrequencyId),
                        credits: this.state.creditsSelected
                    })
            }
            catch (err) { console.log(err) }

            if (savedPlan.data.clientSecret) {
                this.setState({
                    stripeAuthPayment: savedPlan.data
                })
            } else {
                this.setState({ finishedPayment: true })
            }
        }
        catch (err) {
            let error = err;
            if (err.data.error) {
                if (err.data.error.error) {
                    error = err.data.error.error;
                }
                else {
                    error = err.data.error;
                }
            }
            else if (err.data.message) {
                error = err.data.message
            } else {
                error = err.data;
            }

            let blockReasons = []
            if (err && err.data && err.data.error && err.data.error.blockReasons) {
                blockReasons = err.data.error.blockReasons
            }
            this.setState({
                attemptingPayment: false,
                paymentError: error,
                blockReasons
            })
        }
    }

    async completePaymentIntent() {
        try {
            let response = await axios.put('/accountMaster/payment/stripeauth/' + this.state.stripeAuthPayment.OrderId)
            this.setState({ finishedPayment: true })
        }
        catch (err) {
            this.setState({
                paymentError: err.response.error || err.response,
            })
        }
    }

    async errorPaymentIntent(err) {
        // delete the order
        await axios.delete('/accountMaster/payment/' + this.state.stripeAuthPayment.OrderId + '?error=' + err).catch(() => { })
        this.setState({
            stripeAuthPayment: undefined,
            paymentError: err
        })
    }

    activateAccount() {
        var theDrawer = <NewUserForm from="payment" closeNewUserForm={() => { }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, false, "100vw", 'full'));
    }

    render() {

        if (this.state.finishedPayment) return <div>
            <h3 className="mar-b10">Payment Complete</h3>
            <div className="mar-b10">Thanks for your payment. Your order was a success and your plan changes have now taken effect.</div>
            <div className='mar-b25'>You can view an invoice for this transaction via the <a onClick={() => { this.props.history.push("/cp/company/payments") }}>Payments area</a>.</div>
            <UILIB.Button text="Finish" onClick={() => { this.props.close() }} />
        </div>

        if (this.state.stripeAuthPayment) return <UILIB.AuthStripe paymentMethod={this.state.stripeAuthPayment.paymentMethod} clientSecret={this.state.stripeAuthPayment.clientSecret} success={this.completePaymentIntent} error={this.errorPaymentIntent} />
        if (!this.props.siteMaster.defaultTierId) return <OldUpgrade {...this.props} />
        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />

        const allowed = this.props.accountMaster.accountMaster.approved && !this.props.accountMaster.accountMaster.deny

        let okToShowPricing = this.state.okToShowPricing;
        if (this.state.calculatedCost.okToChange == false) {
            okToShowPricing = false
        }

        let changingPlanMech = null;
        if (okToShowPricing) {
            if (this.state.accountMaster.billedOnContacts == 0 && this.state.selectedFrequencyId < 2 && this.state.calculatedCost.price > 0) {
                changingPlanMech = "contacts"
            }
            if (this.state.accountMaster.billedOnContacts == 1 && this.state.selectedFrequencyId < 2 && this.state.calculatedCost.price == 0) {
                changingPlanMech = "sends"
            }
        }

        let warning = ''

        const blockReasons = []
        const tierChange = this.state.tierChange
        if (tierChange && (this.state.selectedTierId > 0)) {
            Object.keys(tierChange.newAllowed).forEach(serviceName => {
                if (!tierChange.newAllowed[serviceName].allowed) {
                    if (tierChange.newAllowed[serviceName].err === 'tierOverLimit') {
                        switch (serviceName) {
                            case 'automation':
                                blockReasons.push(<span>Reduce the number of <a onClick={() => this.props.history.push('/cp/automation')}>Automations</a> running to {tierChange.newAllowed.automation.allowedNumber} (currently {tierChange.newAllowed.automation.currentNumber})</span>)
                                break;
                            case 'users':
                                blockReasons.push(<span>Reduce the number of <a onClick={() => this.props.history.push('/cp/company/staff')}>Users</a> to {tierChange.newAllowed.users.allowedNumber} (currently {tierChange.newAllowed.users.currentNumber})</span>)
                                break;
                            case 'webTracking':
                                blockReasons.push(<span>We are tracking too many of your <a onClick={() => this.props.history.push('/cp/trackers')}>Websites</a>, please archive some. {tierChange.newAllowed.webTracking.allowedNumber} allowed (currently {tierChange.newAllowed.webTracking.currentNumber})</span>)
                                break;
                            case 'replyTracking':
                                blockReasons.push(<span>Reduce the number of <a onClick={() => this.props.history.push('/cp/company/mailboxes')}>Mailboxes</a> we are checking for replies to {tierChange.newAllowed.replyTracking.allowedNumber} (currently {tierChange.newAllowed.replyTracking.currentNumber})</span>)
                                break;
                            case 'automationConditions':
                                blockReasons.push(<span>Pause/Delete <a onClick={() => this.props.history.push('/cp/automation')}>Automations</a> that are using too many condition steps ({tierChange.newAllowed.automationConditions.allowedNumber} allowed)</span>)
                                break;
                            case 'abSplitTests':
                                blockReasons.push(<span>Reduce the number of running <a onClick={() => this.props.history.push('/cp/campaigns/10')}>AB Split Tests</a> to {tierChange.newAllowed.abSplitTests.allowedNumber} (currently {tierChange.newAllowed.abSplitTests.currentNumber})</span>)
                                break;
                            case 'customTemplateBlocks':
                                blockReasons.push(<span>Reduce the number of custom template blocks you have created to {tierChange.newAllowed.customTemplateBlocks.allowedNumber} (currently {tierChange.newAllowed.customTemplateBlocks.currentNumber})</span>)
                                break;
                            case 'dynamicContent':
                                blockReasons.push(<span>Remove  draft campaigns you have which include RSS template blocks. New tier allows {tierChange.newAllowed.dynamicContent.allowedNumber} (currently {tierChange.newAllowed.dynamicContent.currentNumber})</span>)
                                break;
                            case 'contacts':
                                blockReasons.push(<span>Reduce the number of active contacts in your account to {tierChange.newAllowed.contacts.allowedNumber} (currently {tierChange.newAllowed.contacts.currentNumber})</span>)
                                break;

                        }
                    } else {
                        if (tierChange.newAllowed[serviceName].currentNumber) {
                            switch (serviceName) {
                                case 'automation':
                                    blockReasons.push(<span>Remove or Pause all <a onClick={() => this.props.history.push('/cp/automation')}>Automations</a></span>)
                                    break;
                                case 'users':
                                    blockReasons.push(<span>Remove all other <a onClick={() => this.props.history.push('/cp/company/staff')}>Users</a>, only 1 allowed</span>)
                                    break;
                                case 'webTracking':
                                    blockReasons.push(<span>Archive all websites we are tracking in <a onClick={() => this.props.history.push('/cp/trackers')}>Site Tracking</a></span>)
                                    break;
                                case 'replyTracking':
                                    blockReasons.push(<span>Remove all mailboxes we are monitoring for <a onClick={() => this.props.history.push('/cp/company/mailboxes')}>Reply Tracking</a></span>)
                                    break;
                                case 'automationConditions':
                                    blockReasons.push(<span>Remove or Pause all <a onClick={() => this.props.history.push('/cp/automation')}>Automations with Condition Steps</a></span>)
                                    break;
                                case 'abSplitTests':
                                    blockReasons.push(<span>Remove or Pause all <a onClick={() => this.props.history.push('/cp/campaigns/10')}>AB Split Tests</a></span>)
                                    break;
                                case 'customTemplateBlocks':
                                    blockReasons.push(<span>Remove all custom template blocks you have created</span>)
                                    break;
                                case 'dynamicContent':
                                    blockReasons.push(<span>Remove all draft campaigns you have which include RSS template blocks.</span>)
                                    break;
                            }
                        }
                    }
                }
            })
            if (blockReasons.length) {
                warning = <UILIB.Paper className="paper-orange paper-tight">
                    <div className='quickFlex text-heavy' style={{ alignItems: 'center' }}>
                        <UILIB.Icons icon="warning" color="white" className="mar-r10 flex-clean-up" />
                        <div>Quota exceeded. To continue you need to:</div>
                    </div>
                    <ul>
                        {blockReasons.map((r, i) => <li key={i}>{r}</li>)}
                    </ul>
                </UILIB.Paper>
            }
        }

        let discount = 0
        let discounts = [];

        if (okToShowPricing) {
            if (this.state.calculatedCost.voucherCodeDiscount) {
                discount += this.state.calculatedCost.voucherCodeDiscount
            }
            if (this.state.calculatedCost.discount) {
                discount += this.state.calculatedCost.discount
            }
            if (this.state.calculatedCost.discounts && Object.keys(this.state.calculatedCost.discounts).length > 1) {
                let totDiscs = 0;
                Object.keys(this.state.calculatedCost.discounts).forEach(disc => {
                    let discVal = this.state.calculatedCost.discounts[disc];
                    totDiscs += discVal;
                    if (discVal > 0) {
                        discounts.push(<div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignItems: "center" }}>
                            <div>{i18n.t('account:upgrade.' + disc)}</div>
                            <div style={{ flex: 1 }}><hr className="hr-dotted mar-0 mar-l10 mar-r10" /></div>
                            <div className="text-green">
                                - {this.state.calculatedCost.symbol + NumberFunctions.formatNumber(discVal, 2)}
                            </div>
                        </div>
                        )
                    }
                })
                if (totDiscs > this.state.calculatedCost.originalPlanPrice) {
                    discounts = [<div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div>Total Discount:</div>
                        <div style={{ flex: 1 }}><hr className="hr-dotted mar-0 mar-l10 mar-r10" /></div>
                        <div className="text-green">
                            - {this.state.calculatedCost.symbol + NumberFunctions.formatNumber(discount, 2)}
                        </div>
                    </div>]
                }
            }
        }


        let selectedTierTmp = this.state.tiers.find(t => t.id == this.state.selectedTierId);

        let isNextFree = this.props.accountMaster.accountMaster.nextAccountType && this.props.accountMaster.accountMaster.nextAccountType.toLowerCase() === "free"
        let currentlyFree = this.props.accountMaster.accountMaster.accountType && this.props.accountMaster.accountMaster.accountType.toLowerCase() === "free"
        var nextBillDate = moment(this.props.accountMaster.accountMaster.nextBillDate);
        var daysUntilDate = nextBillDate.diff(moment(), 'days')

        return <div>
            <h4 className="mar-b25">Upgrade your Account</h4>
            <div className="mar-b25">
                <span>Select the type of account you would like to use. </span>
                {this.props.siteMaster && this.props.siteMaster.siteId == 1 && <a href="https://transpond.io/pricing" target="_blank">Find out more about account types</a>}

            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "center", alignItems: "baseline" }} className="mar-b10">
                <div className="mar-r5">You have</div>
                <div style={{ fontSize: "30px" }} className="mar-r5">{this.props.accountMaster.accountMaster.totalActiveSubscribers} </div>
                <div>active contacts</div>
            </div>
            <div className="mar-b20" style={{ textAlign: "center" }}>
                <UILIB.SquareChip value="Active contacts are contacts that are not Denylisted, inactive or previously hard bounced" />
            </div>
            <hr />
            {/* {this.state.paymentError && this.state.paymentError.length > 0 && <div className="mar-b25 text-red">{this.state.paymentError}</div>} */}

            {/* tiers */}
            <UILIB.Row>
                {this.state.tiers.map((tier, index) => {
                    let tierSelected = false;
                    if (tier.id == this.state.selectedTierId) tierSelected = true;

                    return <UILIB.Column xs={6} sm={3} key={"tier_" + index} className="mar-b25">
                        <UILIB.FeatureCard
                            title={tier.name}
                            description={tier.description}
                            selected={tierSelected}
                            onClick={() => { this.selectTier(tier) }}
                            style={{ minHeight: "110px" }}
                        />

                    </UILIB.Column>
                })}

                {!!warning && <UILIB.Column xs={12} sm={12} md={12} lg={12} className='mar-b25'>
                    {warning}
                </UILIB.Column>}
            </UILIB.Row>

            <UILIB.Row>
                <UILIB.Column xs={12}>

                    {/* credit plans */}
                    {(this.state.selectedTierId == -9) &&
                        <UILIB.TextInput
                            outerClassName="mar-b25"
                            label="Enter the number of credits you would like to add"
                            explainer="1 Credit = 1 Email Sent to a Contact"
                            name="creditsSelected"
                            value={this.state.creditsSelected}
                            type="number" onChange={(ev) => { this.setState({ creditsSelected: ev.target.value }, this.calculatePrice) }} />
                    }

                    {!warning && this.state.selectedTierId > 0 && selectedTierTmp.isFree && <>
                        <DowngradeToFree close={this.props.close} currentlyFree={currentlyFree} nextFree={isNextFree} daysLeft={daysUntilDate} />
                    </>}
                    {/* subscription plans */}
                    {(this.state.selectedTierId > 0 && !selectedTierTmp.isFree) && <><UILIB.Row>
                        <UILIB.Column xs={12} sm={6} className="mar-b25">
                            <UILIB.Select

                                label="Choose the number of active contacts that you plan to use"
                                placeholder="Select number of active contacts"
                                value={this.state.selectedPlanId}
                                hideplaceholder={true}
                                data={this.state.plans.filter(p => {
                                    if (this.state.selectedTierId != p.AccountBillingTierId) return false;
                                    return true
                                }).map(p => {

                                    return {
                                        label: p.name,
                                        value: p.id
                                    }
                                })}
                                onChange={(ev) => { this.setState({ selectedPlanId: ev.target.value }, this.calculatePrice) }} />
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} className="mar-b25">
                            {/* subscription frequency */}
                            <UILIB.Select
                                label="Select how often you would like to be billed"
                                placeholder="Select billing frequency"
                                hideplaceholder={true}
                                value={this.state.selectedFrequencyId}
                                data={this.state.billingFrequencies}
                                onChange={(ev) => { this.setState({ selectedFrequencyId: parseInt(ev.target.value) }, this.calculatePrice) }} />
                        </UILIB.Column>
                    </UILIB.Row>
                        {(this.state.selectedExistingPlan) && <UILIB.Paper className="paper-green">
                            <h4 className="mar-b10">This is your current plan</h4>
                            <div>You can change the billing frequency, contact amount or plan type to upgrade or downgrade your account.</div>
                        </UILIB.Paper>}
                    </>


                    }

                    {(okToShowPricing == true && changingPlanMech == "contacts") &&
                        <UILIB.Paper style={{ border: "2px solid red" }}>
                            Your new subscription will be based on the number of contacts you have in your account, instead of the number of contacts you send emails to.
                        </UILIB.Paper>
                    }
                    {(okToShowPricing == true && changingPlanMech == "sends") &&
                        <UILIB.Paper style={{ border: "2px solid red" }}>
                            Your new subscription will be based on the number of contacts you send to, instead of the number of contacts you have in your account.
                        </UILIB.Paper>
                    }

                    {(okToShowPricing == false && this.state.calculatedCost.okToChange == false) && <UILIB.Paper className="paper-primary">
                        <h4 className="mar-b15">Changing from Annual to Monthly billing</h4>
                        <div className="mar-b10">Because you are currently using an Annual plan, you can only change to a Monthly plan in the last month of your Subscription</div>
                        <div className="mar-b10">You can change your Annual plan for any other Annual plan at any time.</div>
                        <div>You have  {this.state.calculatedCost.okToChangeInDays} days remaining before you can change back to a Monthly plan.</div>
                    </UILIB.Paper>
                    }

                    {(okToShowPricing == true) &&
                        <UILIB.Paper>
                            <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignItems: 'center' }}>
                                <h4 >Checkout</h4>
                                {!!this.state.calculatedCost.discount && <div className='text-xsml'>You will get a discount based on your progress through your current billing period</div>}
                            </div>

                            <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <div>Price:</div>
                                <div style={{ flex: 1 }}><hr className="hr-dotted mar-0 mar-l10 mar-r10" /></div>
                                <div>{this.state.calculatedCost.symbol + NumberFunctions.formatNumber(this.state.calculatedCost.originalPlanPrice, 2)}</div>
                            </div>
                            {(!!discount) && <Fragment>
                                {discounts}


                                <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <div>Sub-Total:</div>
                                    <div style={{ flex: 1 }}><hr className="hr-dotted mar-0 mar-l10 mar-r10" /></div>
                                    <div>
                                        {this.state.calculatedCost.symbol + NumberFunctions.formatNumber(this.state.calculatedCost.totalIncDiscount, 2)}
                                    </div>
                                </div>
                            </Fragment>}
                            <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <div>VAT ({this.state.calculatedCost.vatRate}%):</div>
                                <div style={{ flex: 1 }}><hr className="hr-dotted mar-0 mar-l10 mar-r10" /></div>
                                <div>
                                    {this.state.calculatedCost.symbol + NumberFunctions.formatNumber(this.state.calculatedCost.vat, 2)}
                                </div>
                            </div>
                            <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <div className="text-strong">Total:</div>
                                <div style={{ flex: 1 }}><hr className="hr-dotted mar-0 mar-l10 mar-r10" /></div>
                                <div className="text-strong">
                                    {this.state.calculatedCost.symbol + NumberFunctions.formatNumber(this.state.calculatedCost.totalIncDiscount + this.state.calculatedCost.vat, 2)}
                                </div>
                            </div>




                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={6} className={"mar-b25" + (!allowed ? ' col-sm-offset-6' : '')}>
                                    <div className="quickFlex mar-b25" style={{ alignItems: "flex-end" }}>
                                        <UILIB.TextInput outerStyle={{ flex: 1, width: "100%" }} name="voucherCode" label="Voucher Code" value={this.state.voucherCode} onChange={(ev) => { this.setState({ voucherCode: ev.target.value }) }} error={this.state.voucherError} />
                                        <UILIB.Button text="Apply" onClick={this.calculatePrice} className="mar-l10" />
                                    </div>
                                </UILIB.Column>
                                {allowed && <UILIB.Column xs={12} sm={6} className="mar-b25">
                                    {(this.state.calculatedCost.priceIncVat > 0) && <Fragment>
                                        {(!this.state.paymentCards || !this.state.paymentCards.length || this.state.addingPaymentCard) && <UILIB.Paper>
                                            <div className="txtInput_label">Add a new Payment Card</div>
                                            <StripeAddCard close={this.getPaymentCards} hasCloseButton={true} />
                                        </UILIB.Paper>}
                                        {(this.state.paymentCards && this.state.paymentCards.length > 0 && !this.state.addingPaymentCard) && <div>

                                            <UILIB.Select label="Payment Method"
                                                outerClassName="mar-b10"
                                                data={this.state.paymentCards.map((card) => {
                                                    return { label: card.brand + " ending " + card.last4 + " (exp " + card.exp_month + "/" + card.exp_year + ")", value: card.id }
                                                })}
                                                onChange={this.selectPaymentcard}
                                                value={this.state.selectedPaymentCard}
                                                disabled={this.state.changingPaymentCard}
                                                iconRight={this.state.changingPaymentCard ? <UILIB.LoadingIcons iconType="2" style={{ height: "20px", width: "20px" }} /> : ""}
                                            />
                                            <div style={{ textAlign: "right" }}>
                                                or <a onClick={() => {
                                                    let addingPaymentCard = true;
                                                    if (this.state.addingPaymentCard) addingPaymentCard = false;
                                                    this.setState({ addingPaymentCard })
                                                }}>Add a new Card</a>
                                            </div>

                                        </div>}
                                    </Fragment>}
                                </UILIB.Column>}
                            </UILIB.Row>

                            {(this.state.calculatedCost.priceIncVat <= 0) && <div className="text-right">
                                {!!this.state.calculatedCost.originalPlanPrice || 1 == 1 && <div className="mar-b25">
                                    There is no charge to pay now, but your account will be charged
                                    <strong> {this.state.calculatedCost.symbol + NumberFunctions.formatNumber(this.state.calculatedCost.originalPlanPrice, 2)} </strong>
                                    in <strong> {NumberFunctions.formatNumber(this.state.calculatedCost.daysRemaining)} </strong>
                                    days
                                </div>}
                                <UILIB.Button
                                    text="Continue"
                                    onClick={this.goChangePlan}
                                    className="button-primary"
                                    iconRight={<UILIB.Icons icon="arrowRight" />} />
                            </div>}

                            {(this.state.calculatedCost.priceIncVat > 0) && <div className="text-right">
                                {allowed && <div className="mar-b25">
                                    <div>By clicking the "Continue" button below you agree to our Terms and Conditions of use.</div>
                                    {this.state.selectedTierId > -9 && <Fragment>
                                        <div>Your card will be billed {this.state.calculatedCost.symbol}{NumberFunctions.formatNumber(this.state.calculatedCost.originalPlanPrice, 2) + " "}
                                            every {this.state.selectedFrequencyId == 1 ? <span>year </span> : <span>30 days </span>}
                                            when your subscription renews. </div>
                                        <div>You can change, or cancel your plan at any time.</div>
                                        {this.props.accountMaster.accountMaster.billingFrequency == 0 && this.state.selectedFrequencyId == 1 && <div className="text-purple">
                                            You have selected an Annual plan. <br />
                                            Please note you will not be able to change back to a Monthly plan until your reach the last 30 days of the subscription.
                                        </div>}
                                    </Fragment>}
                                </div>}
                                <div className='quickFlex end-xs' style={{ alignItems: "baseline" }}>
                                    {(this.props.accountMaster.accountMaster.deny || !this.props.accountMaster.accountMaster.approved) && <>
                                        <div className="mar-b20 text-orange text-heavy mar-r10">Your account must be approved before you can upgrade your account</div>
                                        {!this.props.accountMaster.accountMaster.doneNewUserQuestions && <UILIB.Button className="button-orange" onClick={this.activateAccount}>Request Approval</UILIB.Button>}
                                    </>
                                    }
                                    {!!this.state.paymentError && <UILIB.WarningHolder className="mar-r10">
                                        <div className='mar-b10 text-red text-heavy' style={{ fontSize: "16px" }}>{this.state.paymentError}</div>
                                        {this.state.blockReasons && this.state.blockReasons.map((reason, index) => {
                                            return <UILIB.SquareChip>{reason.friendlyName}</UILIB.SquareChip>
                                        })}
                                    </UILIB.WarningHolder>}
                                    {(!this.props.accountMaster.accountMaster.deny && this.props.accountMaster.accountMaster.approved) &&
                                        <UILIB.Button
                                            className="button-primary"
                                            text={"Pay " + this.state.calculatedCost.symbol + NumberFunctions.formatNumber(this.state.calculatedCost.totalIncDiscount + this.state.calculatedCost.vat, 2)}
                                            disabled={!!warning || !!this.state.attemptingPayment || !this.state.paymentCards || !this.state.paymentCards.length || !this.state.calculatedCost.okToChange}
                                            onClick={this.goChangePlan}
                                            iconRight={<UILIB.Icons icon={this.state.attemptingPayment ? "loading" : "arrowRight"} />}
                                        />
                                    }
                                </div>
                            </div>}
                        </UILIB.Paper>
                    }
                </UILIB.Column>
            </UILIB.Row>
        </div >
    }
}

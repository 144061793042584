import React from 'react'
import UILIB from '~/Common-Objects/Lib'


export default class FeatureCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let selectedIcon = selectedIcon = <UILIB.Icons icon="tickCircle" />
        let style = {};
        if (this.props.style) style = this.props.style;
        let className = "featureCard";
        this.props.selected ? className += " featureCard-selected" : className += ""
        if (this.props.className) className += " " + this.props.className;
        if (this.props.overlay) className += " featureCard-overlay"
        return <div className={className} onClick={this.props.onClick} style={style}>
            <div className="featureCard-icons-selected" style={{ opacity: this.props.selected ? 1 : 0 }}>{selectedIcon}</div>
            <div className="featureCard-icons">
                <div className="featureCard-icons-left">{this.props.iconLeft}</div>
            </div>
            <div className="featureCard-title">
                {this.props.title}
                <div className="featureCard-featureTag">{this.props.featureTag}</div>
            </div>
            <div className="featureCard-description">{this.props.description}</div>
            <div className="featureCard-cta">{this.props.cta}</div>
            {this.props.overlay && <div className="featureCard-overlay-content">{this.props.overlay}</div>}
        </div>
    }
}


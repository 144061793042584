import React from 'react'

export default function IconInput({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.5 7.00006C17.5 6.86745 17.4473 6.74028 17.3536 6.64651C17.2598 6.55274 17.1326 6.50006 17 6.50006L3 6.50006C2.86739 6.50006 2.74022 6.55274 2.64645 6.64651C2.55268 6.74028 2.5 6.86745 2.5 7.00006L2.5 13.0001C2.5 13.1327 2.55268 13.2598 2.64645 13.3536C2.74021 13.4474 2.86739 13.5001 3 13.5001L17 13.5001C17.1326 13.5001 17.2598 13.4474 17.3536 13.3536C17.4473 13.2598 17.5 13.1327 17.5 13.0001L17.5 7.00006ZM18.4142 5.58585C18.7893 5.96092 19 6.46963 19 7.00006L19 13.0001C19 13.5305 18.7893 14.0392 18.4142 14.4143C18.0391 14.7893 17.5304 15.0001 17 15.0001L3 15.0001C2.46957 15.0001 1.96086 14.7893 1.58579 14.4143C1.21071 14.0392 1 13.5305 1 13.0001L1 7.00006C1 6.46963 1.21071 5.96092 1.58579 5.58585C1.96086 5.21077 2.46957 5.00006 3 5.00006L17 5.00006C17.5304 5.00006 18.0391 5.21077 18.4142 5.58585Z" fill={fillColor} />
            <path d="M6.622 12.19L6.364 11.164H5.08L4.822 12.19H4L5.188 8.002H6.28L7.468 12.19H6.622ZM5.896 9.508L5.764 8.782H5.668L5.536 9.508L5.29 10.474H6.154L5.896 9.508Z" fill={fillColor} />
            <path d="M7.95498 7.75H8.79498V9.562H8.89098C8.90698 9.478 8.93698 9.4 8.98098 9.328C9.02898 9.256 9.08498 9.194 9.14898 9.142C9.21298 9.086 9.28498 9.044 9.36498 9.016C9.44498 8.984 9.52698 8.968 9.61098 8.968C9.97498 8.968 10.241 9.106 10.409 9.382C10.581 9.658 10.667 10.07 10.667 10.618C10.667 11.166 10.583 11.578 10.415 11.854C10.247 12.126 9.97898 12.262 9.61098 12.262C9.52698 12.262 9.44498 12.246 9.36498 12.214C9.28498 12.186 9.21298 12.146 9.14898 12.094C9.08498 12.038 9.02898 11.974 8.98098 11.902C8.93698 11.83 8.90698 11.752 8.89098 11.668H8.79498V12.19H7.95498V7.75ZM9.27498 11.578C9.43098 11.578 9.55498 11.53 9.64698 11.434C9.74298 11.334 9.79098 11.194 9.79098 11.014V10.216C9.79098 10.036 9.74298 9.898 9.64698 9.802C9.55498 9.702 9.43098 9.652 9.27498 9.652C9.12298 9.652 9.00498 9.688 8.92098 9.76C8.83698 9.828 8.79498 9.932 8.79498 10.072V11.158C8.79498 11.298 8.83698 11.404 8.92098 11.476C9.00498 11.544 9.12298 11.578 9.27498 11.578Z" fill={fillColor} />
            <path d="M12.4058 12.262C11.9658 12.262 11.6338 12.128 11.4098 11.86C11.1858 11.592 11.0738 11.178 11.0738 10.618C11.0738 10.058 11.1858 9.644 11.4098 9.376C11.6338 9.104 11.9658 8.968 12.4058 8.968C12.7178 8.968 12.9658 9.04 13.1498 9.184C13.3378 9.324 13.4718 9.524 13.5518 9.784L12.8738 10.084C12.8538 9.932 12.8078 9.82 12.7358 9.748C12.6638 9.672 12.5538 9.634 12.4058 9.634C12.1018 9.634 11.9498 9.814 11.9498 10.174V11.056C11.9498 11.416 12.1018 11.596 12.4058 11.596C12.5578 11.596 12.6698 11.558 12.7418 11.482C12.8178 11.402 12.8738 11.282 12.9098 11.122L13.5638 11.41C13.3918 11.978 13.0058 12.262 12.4058 12.262Z" fill={fillColor} />
        </svg>
    )
}
import React from "react";

export default function IconExternalLink({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3505 4.27582C10.5271 4.09921 10.7667 4 11.0164 4H15.7249C15.9747 4 16.2142 4.09921 16.3908 4.27582C16.5674 4.45242 16.6666 4.69195 16.6666 4.9417V9.6502C16.6666 9.89995 16.5674 10.1395 16.3908 10.3161C16.2142 10.4927 15.9747 10.5919 15.7249 10.5919C15.4752 10.5919 15.2356 10.4927 15.059 10.3161C14.8824 10.1395 14.7832 9.89995 14.7832 9.6502V7.21496L8.8571 13.1411C8.77023 13.231 8.66632 13.3028 8.55143 13.3521C8.43654 13.4015 8.31297 13.4274 8.18793 13.4285C8.06289 13.4296 7.93889 13.4058 7.82316 13.3584C7.70743 13.3111 7.60228 13.2412 7.51386 13.1528C7.42545 13.0643 7.35552 12.9592 7.30817 12.8435C7.26082 12.7277 7.237 12.6037 7.23808 12.4787C7.23917 12.3537 7.26515 12.2301 7.3145 12.1152C7.36385 12.0003 7.43559 11.8964 7.52554 11.8095L13.4517 5.8834H11.0164C10.7667 5.8834 10.5271 5.78419 10.3505 5.60758C10.1739 5.43098 10.0747 5.19145 10.0747 4.9417C10.0747 4.69195 10.1739 4.45242 10.3505 4.27582ZM5.21057 5.4011C5.55885 5.05282 6.03122 4.85716 6.52377 4.85716H8.38091C8.62718 4.85716 8.86337 4.95499 9.03751 5.12913C9.21165 5.30327 9.30948 5.53946 9.30948 5.78573C9.30948 6.032 9.21165 6.26819 9.03751 6.44233C8.86337 6.61647 8.62718 6.7143 8.38091 6.7143H6.52377V14.1429H13.9523V12.2857C13.9523 12.0395 14.0502 11.8033 14.2243 11.6291C14.3985 11.455 14.6346 11.3572 14.8809 11.3572C15.1272 11.3572 15.3634 11.455 15.5375 11.6291C15.7117 11.8033 15.8095 12.0395 15.8095 12.2857V14.1429C15.8095 14.6354 15.6138 15.1078 15.2655 15.4561C14.9173 15.8044 14.4449 16 13.9523 16H6.52377C6.03122 16 5.55885 15.8044 5.21057 15.4561C4.86229 15.1078 4.66663 14.6354 4.66663 14.1429V6.7143C4.66663 6.22176 4.86229 5.74939 5.21057 5.4011Z"
        fill={color}
      />
    </svg>
  );
}

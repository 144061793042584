import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';
import axios from '~/data/http/axios';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class cancelResend extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }

        this.confirm = this.confirm.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    componentDidMount() {
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    confirm() {
        this.props.cancelResendGo(this.props.campaign);
    }


    render() {
        return <div>
            <h4 className="mar-b25">{i18n.t('campaigns:overview.cancelResendHeader')}</h4>
            <div className="mar-b25">{i18n.t('campaigns:overview.cancelResendDesc')}</div>
            <div className="quickFlex mar-b20">
                <UILIB.Button text={i18n.t('continue')} disabled={this.props.campaign.status === 'Sending'} className="button-primary mar-r10" onClick={this.confirm} />
            </div>
            {this.props.campaign.status === 'Sending' && <div className='text-red'>
                You cannot cancel this whilst a campaign is sending. Either wait for the campaign to finish or pause the campaign.
            </div>}

        </div>

    }
};



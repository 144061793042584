import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';

export default class Select extends Component {
    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick(ev) {
        ev.preventDefault();
    }

    render() {
        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "label", this.props.localVariables)
        }

        return <select onClick={this.onClick} {...eleProps} readOnly={true}>
            {this.props.children}
        </select>



    }
}
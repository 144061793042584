import axios from '~/data/http/axios';
import React, { Component } from 'react';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'


@connect((store) => {
    return { user: store.user }
})
class SendAsCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignName: "",
            nameOk: false,
            error: ""
        }

        this.updateCampaignName = this.updateCampaignName.bind(this);
        this.continue = this.continue.bind(this);
    }

    updateCampaignName(ev) {
        let nameOk = false;
        if (ev.target.value && ev.target.value.length > 3) nameOk = true;
        this.setState({ campaignName: ev.target.value, nameOk: nameOk })
    }

    async continue() {
        this.setState({ saving: true, error: '' });
        if (!this.state.campaignName || this.state.campaignName.length < 3) {
            return this.setState({ saving: false, error: 'Name must be 3 or more Characters' })
        }
        let options = {}
        if (this.props.user.userData.timeZone) {
            options.timezone = this.props.user.userData.timeZone
        }
        try {
            let campaign = await axios.post('/campaign', {
                campaignName: this.state.campaignName,
                type: 1,
                startDate: new Date(),
                options
            })
            campaign = campaign.data.Campaign;

            let origTemplate = await axios.get("/template/" + this.props.templateId)
            origTemplate = origTemplate.data.template;

            let newTemplateData = {
                templateName: campaign.campaignName,
                templateJson: origTemplate.templateJson,
                templateType: origTemplate.templateType,
                templateHTML: origTemplate.templateHTML,
                CampaignId: campaign.id
            }
            let template = await axios.post('/template', newTemplateData)

            campaign.TemplateId = template.data.Template.templateID;
            await axios.put('/campaign/' + campaign.id, { campaign: campaign })

            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "left", null));
            this.props.history.push(`/cp/campaigns/add/standard/${campaign.id}/1`);

        }
        catch (err) {
            console.log(err);
            this.setState({ saving: false, error: i18n.t('campaigns:add.nameError') })
        }
    }

    render() {

        let okToSave = false;
        if (this.state.nameOk) okToSave = true;
        if (this.state.saving) okToSave = false;
        return <div>
            <h4 className="mar-b15">{i18n.t('templates:sendAsCampaign.header')}</h4>
            <div className="mar-b25">{i18n.t('templates:sendAsCampaign.subHeader')}</div>

            <UILIB.TextInput
                label={i18n.t('templates:sendAsCampaign.campaignName')}
                explainer={i18n.t('templates:sendAsCampaign.enterCampaignName')}
                placeholder={i18n.t('templates:sendAsCampaign.yourCampaignName')}
                outerClassName="mar-b25"
                name="campaignName"
                value={this.state.campaignName}
                onChange={this.updateCampaignName}
                onEnterPress={this.continue}
                disabled={this.state.saving}
                error={this.state.error}
                focus={true}
            />
            <UILIB.Button disabled={!okToSave} className="button-primary" onClick={this.continue} text={i18n.t('continue')} iconRight={<UILIB.Icons icon="arrowRight" />} />
        </div>
    }
}

export default SendAsCampaign;
export default function reducer(
    state = {
        siteName: '',
        id: 0,
        siteLogo: '',
        colours: {},
        currentNavTab: 0,
        currentPage: '',
        supportEmail: '',
        address: '',
        contactGateApiKey: '',
        customFooter: '',
        usersMustBeAuthenticated: false,
        campaignView: 'list',
        npsEnabled: false,
        siteDrawer: {
            isOpen: false,
            showClose: false,
            direction: "right",
            content: null,
            firstRun: false,
            width: null,
            className: "",
            onClose: undefined
        },
        connectionError: '',
        fullpage: null
    }, action) {
    switch (action.type) {
        case "INIT_SITE_DATA_LOAD":
            {
                var newState = action.payload;
                return newState;
            }

        case "CHANGE_MAINNAV_TAB":
            {
                var newState = {
                    ...state,
                    currentNavTab: action.payload
                }
                return newState;
            }

        case "CHANGE_SITE_DRAWER":
            {
                var newState = {
                    ...state,
                    siteDrawer: action.payload
                }
                return newState;
            }
        case "CHANGE_SITE_DRAWER_WIDTH":
            {
                var siteDrawer = state.siteDrawer;
                siteDrawer.width = action.payload
                var newState = {
                    ...state,
                    siteDrawer: siteDrawer
                }
                return newState;
            }
        case "CHANGE_PAGE":
            {
                var newState = {
                    ...state,
                    currentPage: action.payload
                }
                return newState
            }
        case "CHANGE_CAMPAIGN_VIEW":
            {
                console.log(state.campaignView, action.payload)
                return { ...state, campaignView: action.payload }
            }
        case "SET_CONNECTION_ERROR":
            return { ...state, connectionError: action.payload }
        case 'ALTER_SNACKBAR': {
            return { ...state, snackbar: action.payload }
        }
        case 'ALTER_FULLPAGE': {
            return { ...state, fullpage: action.payload }
        }
    }
    return state;
}

import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import UILIB from '../../../../../Common-Objects/Lib'
import axios from '../../../../../data/http/axios'
import i18n from '../../../../../i18n'
import * as siteMaster from '../../../../../data/actions/siteActions'
import PermissionChecker from '../../../../../Classes/permissions'
import CreateGroup from '../../groups/incGoAddGroup'
import EmailValidator from '../../../../../Classes/emailValidator'

export default function OrganisationSubscribers() {
    const params = useParams()
    const history = useHistory()
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(15)
    const [data, setData] = useState(null)
    const dispatch = useDispatch()
    const permissionStore = useSelector(state => state.permission)

    const getSubs = async () => {
        let query = `?offset=${limit * page}&limit=${limit}`

        const res = await axios.get('/organisation/' + params.id + '/subscribers' + query)
        setData(res.data.Subscribers)
        setTotal(res.data.total)
    }

    const removeFromGroup = (row) => {
        const drawer = <RemoveSubscriber subscriber={row} onFinish={r => {
            console.log(r)
            if (r) getSubs()
            dispatch(siteMaster.alterSiteDrawer(false))
        }} />
        dispatch(siteMaster.alterSiteDrawer(true, true, 'right', drawer, true, 400))
    }

    const addSubscriber = () => {
        const drawer = <AddSubscriber OrganisationId={params.id} onFinish={r => {
            dispatch(siteMaster.alterSiteDrawer(false, true, 'right', null))
            if (r) getSubs()
        }} />
        dispatch(siteMaster.alterSiteDrawer(true, true, 'right', drawer, true, 400))
    }

    useEffect(() => {
        getSubs()
    }, [params.id, limit, page])



    const writeAccess = PermissionChecker('subscribers', permissionStore.permissions, 'write')

    const tableData = !!data && data.map(row => {
        let finalStatus = i18n.t('Active');
        let finalStatusCol = "square-chip-green";

        if (row.groups) {
            var unsubbed = row.GroupSubscribers.filter(g => g.unsubscribe == true);
            var active = row.GroupSubscribers.filter(g => g.active == false);
            if (active.length) {
                finalStatusCol = "square-chip-orange";
                if (active.length == row.GroupSubscribers.length) finalStatus = i18n.t('In-Active')
                if (active.length < row.GroupSubscribers.length) finalStatus = i18n.t('InActiveInSome')
            }
            if (unsubbed.length) {
                finalStatusCol = "square-chip-red";
                if (unsubbed.length == row.GroupSubscribers.length) finalStatus = i18n.t('Unsubscribed')
                if (unsubbed.length < row.GroupSubscribers.length) finalStatus = i18n.t('UnsubscribedFromSome')
            }
        }
        if (row.bounced) {
            finalStatus = i18n.t('Bounced');
            finalStatusCol = "square-chip-purple";
        }
        if (row.blackListed) {
            finalStatus = i18n.t('Blacklisted');
            finalStatusCol = "square-chip-black";
        }

        return {
            emailAddress: {
                headerValue: 'Email Address',
                value: <a onClick={() => history.push('/cp/subscriber/' + row.id)}>{row.emailAddress}</a>
            },
            status: {
                headerValue: i18n.t('status'),
                value: <UILIB.SquareChip value={finalStatus} hasButton={false} className={"no-marg smallChip " + finalStatusCol} />,
                align: "center"
            },
            score: {
                headerValue: 'Score',
                align: "center",
                value: <UILIB.Score score={row.score} />
            },
            tags: {
                headerValue: 'Tags',
                value: <UILIB.Tags tagsSelected={row.Tags} disableEdit={true} />
            },
            options: {
                headerValue: ' ',
                value: <div className='quickFlex' style={{ justifyContent: 'end' }}>
                    < UILIB.Button className="mar-r10" text="View" onClick={() => history.push('/cp/subscriber/' + row.id)
                    } iconLeft={< UILIB.Icons icon="magnifier" />} />
                    {writeAccess && <UILIB.Button className="button-red" iconLeft={<UILIB.Icons icon="cross" />} text="Remove" onClick={() => removeFromGroup(row)} />}
                </div >
            }
        }
    })


    return (
        <UILIB.Paper isLoading={!data}>
            <div className="row">
                <div className="col-xs-12 col-md-6 mar-b25">
                    <h4>Contacts</h4>
                </div>
                <div className="col-xs-12 col-md-6 center-xs end-md mar-b25">
                    {writeAccess && <UILIB.Button className="button-primary" text="Add Contact" onClick={addSubscriber} iconRight={<UILIB.Icons icon="plus" />} />}
                </div>
            </div>

            <UILIB.DataTable1
                noResultsTxt={<span> No Contacts Found </span>}
                tableData={tableData}
                width="100%"
                className="mar-b25"
            />

            <div className="center-xs mar-t30">
                <UILIB.PagingBlock
                    totalRows={total}
                    pageSize={limit}
                    numberOfLinks="10"
                    currentPage={page}
                    changePage={p => setPage(p)}
                    text={i18n.t('page') + ":"} />
            </div>
        </UILIB.Paper>
    )
}


function RemoveSubscriber({ subscriber, onFinish = () => { } }) {
    const remove = async () => {
        await axios.put('/subscriber/' + subscriber.id + '/organisation', {
            OrganisationId: null
        })
        onFinish(true)
    }

    return (
        <div>
            <h4 className='mar-b25'>Remove Contact from Organisation</h4>
            <div className="mar-b25">Are you sure you want to remove <strong>{subscriber.emailAddress}</strong> from this Organisation?</div>
            <div className='mar-b25'>This will <strong>NOT</strong> delete the Contact</div>
            <div className="quickFlex">
                <UILIB.Button className="button-red mar-r10" text="Remove" onClick={remove} iconRight={<UILIB.Icons icon="cross" />} />
                <UILIB.Button className="" text="Cancel" onClick={() => onFinish()} />
            </div>
        </div>
    )
}

function subParser(results) {
    return results.Subscribers.map(row => ({
        label: row.emailAddress,
        value: row.id
    }))
}

function AddSubscriber({ OrganisationId, onFinish = () => { } }) {
    const [newEmail, setNewEmail] = useState('')
    const [creating, setCreating] = useState(false)
    const [error, setError] = useState('')

    const save = async (id) => {
        await axios.put('/subscriber/' + id + '/organisation', { OrganisationId })
        onFinish(true)
    }

    const startCreating = () => {
        if (!EmailValidator.validateEmail(newEmail)) {
            setError('Invalid Email Address')
        } else {
            setError('')
            setCreating(true)
        }
    }

    const create = async (GroupId) => {
        setCreating(false)
        try {
            await axios.post('/group/' + GroupId + '/subscriber', {
                emailAddress: newEmail,
                OrganisationId,
            })
            onFinish(true)
        } catch (e) {
            setError(e.data.error)
        }
    }

    let showError = !!error
    if (showError && error) {
        if (EmailValidator.validateEmail(newEmail))
            showError = false
    }

    if (creating) return <GroupSelector onSelect={create} />

    return (
        <div>
            <h4 className='mar-b25'>Add Contact</h4>


            <UILIB.AutoComplete
                label="Search by Email"
                focus
                placeholder="start typing to search"
                url="/subscriber?limit=100&search={{name}}"
                inputName="Organisation"
                onEnterPress={startCreating}
                onKeyUp={setNewEmail}
                parser={subParser}
                placeholder='Start Typing to Search or Create'
                onChange={o => save(o.value)}
                error={showError && error}
                outerClassName="mar-b25"
            />

            <div className="quickFlex">
                <UILIB.Button disabled={newEmail < 3} text="Create" className="button-primary mar-r10" onClick={startCreating} iconRight={<UILIB.Icons icon="plus" />} />
                <UILIB.Button text="Cancel" className="" onClick={() => onFinish()} />
            </div>
        </div >
    )
}


function GroupSelector({ onSelect = () => { } }) {
    const [groups, setGroups] = useState(null)
    const [groupId, setGroupId] = useState(null)
    const [creatingNew, setCreatingNew] = useState(false)

    const createdNew = (newGroup) => {
        setGroups([...groups, { label: newGroup.groupName, value: newGroup.groupId }])
        onSelect(newGroup.groupId)
    }

    useEffect(() => {
        axios.get('/group').then(res => setGroups(res.data.Groups).map(g => ({ label: g.groupName, value: g.id })))
    }, [])

    if (!groups) return <UILIB.LoadingIcons iconType="2" />

    if (creatingNew) return <CreateGroup addedGroup={createdNew} />

    return (
        <div>
            <h4 className='mar-b25'>Select a Group for the new Contact</h4>

            <UILIB.Select outerClassName="mar-b25" label="Existing Group" data={groups.map(g => ({ label: g.groupName, value: g.id }))} onChange={e => setGroupId(e.currentTarget.value)} placeholder="Select Group" />

            <div className="quickFlex">
                <UILIB.Button text="Select Group" disabled={!groupId} className="button-primary mar-r10" onClick={() => onSelect(groupId)} />
                <UILIB.Button text="Create Group" onClick={() => setCreatingNew(true)} />
            </div>
        </div>
    )
}
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib';
import i18n from '~/i18n'
import { connect } from 'react-redux';
import TemplateImageLoader from '~/pages/cp/automation/addNew/templateImageLoader.jsx'

@connect((store) => {
    return { accountMaster: store.accountMaster }
})
class FormConfirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {}
        }
        this.updateField = this.updateField.bind(this);
        this.checkForm = this.checkForm.bind(this);
        this.edit = this.edit.bind(this)
        this.next = this.next.bind(this)
    }

    componentDidMount() {
        this.checkForm()
    }

    updateField(ev, options) {
        var form = this.props.form;
        if (options) {
            form.options[ev.currentTarget.name] = ev.currentTarget.value
        } else {
            form[ev.currentTarget.name] = ev.currentTarget.value
        }
        this.checkForm()
        this.props.update()
    }

    checkForm() {
        var errors = {}
        var options = this.props.form.options;
        if (options.onCreate === 'redirect' && !options.createRedirectUrl) errors.createRedirectUrl = i18n.t('form:step2.errUrl');
        if (options.onCreate === 'message' && !options.createMessage) errors.createMessage = i18n.t('form:step2.errMessage');

        this.setState({
            errors
        })
    }

    edit() {
        this.props.save().then(() => {
            var redirect = '/cp/groups/' + this.props.groupId + '/forms/create/' + this.props.form.id + '/3'
            this.props.history.push('/cp/templates/add/dragdrop?form=true&groupId=' + this.props.groupId + '&formId=' + this.props.form.id + '&redirect=' + redirect + '&page=created');
        })
    }

    next() {
        this.props.form.wizard.step2 = true
        this.props.update()
        if (this.props.form.options.onCreate === 'replace') {
            this.edit()
        } else {
            this.props.changeStep(3, true)
        }
    }


    render() {
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} md={4} className="mar-b25">
                        <div className="campaign-thumbnail" style={{ width: "100%", height: "100%", minHeight: "150px" }}>
                            <TemplateImageLoader imageUrl={this.props.screenshotUrl} form={this.props.form.formType === 1} />
                        </div>
                    </UILIB.Column>
                    <UILIB.Column xs={12} md={8}>
                        <UILIB.Paper>

                            <h4 className="mar-b25">{i18n.t('form:step2.header')}</h4>


                            <UILIB.Select outerClassName="mar-b25" name="onCreate" value={this.props.form.options.onCreate} data={[
                                { label: i18n.t('form:step2.message'), value: 'message' },
                                { label: i18n.t('form:step2.redirect'), value: 'redirect', disabled: this.props.form.options?.serve === 'click' || this.props.form.options?.serve === 'clickButton' }
                            ]} onChange={ev => this.updateField(ev, true)} />


                            {this.props.form.options.onCreate !== 'replace' && <div>
                                {this.props.form.options.onCreate == 'redirect' && <UILIB.TextInput outerClassName="mar-b25" value={this.props.form.options.createRedirectUrl} error={this.state.errors.createRedirectUrl} placeholder={i18n.t('form:step2.url')} name="createRedirectUrl" onChange={ev => this.updateField(ev, true)} />}
                                {this.props.form.options.onCreate == 'message' && <UILIB.TextInput outerClassName="mar-b25" value={this.props.form.options.createMessage} error={this.state.errors.createMessage} placeholder={i18n.t('form:step2.success')} name="createMessage" onChange={ev => this.updateField(ev, true)} />}
                            </div>
                            }

                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
                <div className="quickFlex end-xs">
                    <UILIB.Button
                        className="button-primary"
                        iconRight={<UILIB.Icons icon="arrowRight" />}
                        disabled={!!Object.keys(this.state.errors).length}
                        text={this.props.form.options.onCreate === 'replace' ? i18n.t('form:step2.create') : i18n.t('form:step2.next')}
                        onClick={this.next}
                    />
                </div>
            </div >
        );
    }
}

export default FormConfirm
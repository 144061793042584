import React from 'react';
import UILIB from '../Lib';
export default class HelpLogo extends React.Component {
    render() {
        let style = {
            width: "15px",
            height: "15px",
            display: "inline-block"
        };
        if (this.props.style) style = this.props.style;

        return <UILIB.Icons icon="questionCircle" {...this.props} style={style} />

    }
}
import React from 'react'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

@connect((store) => {
    return {
        siteMaster: store.siteMaster
    }
})

export default class Hint extends React.Component {
    onClick(e) {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    }


    render() {
        let RootTag = 'div';
        if (this.props.href) {
            RootTag = 'a';
        } else if (this.props.to) {
            RootTag = Link;
        } else if (this.props.onClick) {
            RootTag = 'button';
        }

        let iconLeft = "";
        if (this.props.iconLeft) {
            iconLeft = <div className="hint-iconLeft" style={this.props.iconLeftStyle}>{this.props.iconLeft}</div>
        }

        let iconRight = "";
        if (this.props.iconRight) {
            iconRight = <div className="hint-iconRight" style={this.props.iconRightStyle}>{this.props.iconRight}</div>
        }

        let style = {};
        if (this.props.style) style = this.props.style;
        if (this.props.onClick || this.props.href) style.cursor = "pointer";

        let className = "hint hide-xs";
        if (this.props.className) className += " " + this.props.className;

        return (
            <RootTag className={className} style={style} href={this.props.href} target={this.props.target} to={this.props.to} onClick={this.onClick.bind(this)}>
                {iconLeft}
                {this.props.children}
                {iconRight}
            </RootTag>
        );
    }
}
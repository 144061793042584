import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'
import ControlTemplate from '../../../../../../frontpage';
import DragDropFunctions from '../../../dragDropFunctions';
export default class ArraySelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: []
        }
        this.addValue = this.addValue.bind(this);
        this.removeVal = this.removeVal.bind(this);
        this.changeVal = this.changeVal.bind(this);
        this.sortValues = this.sortValues.bind(this);
        this.processIncoming = this.processIncoming.bind(this);
    }

    componentDidMount() {
        this.processIncoming(this.props);
    }

    componentWillReceiveProps(nextProps) {

        this.processIncoming(nextProps);
    }

    processIncoming(props) {
        var theVal = props.value;

        if (theVal && typeof theVal != "object") {
            if (theVal.indexOf("customField-") > -1) {
                var ourCustomField = JSON.parse(JSON.stringify(DragDropFunctions.customFields))[theVal];
                if (ourCustomField.options && ourCustomField.options.length) {
                    theVal = ourCustomField.options
                } else {
                    theVal = [];
                }
            }
            else {
                theVal = []
            }
            props.updateVal(props.path, theVal);
        }

        this.setState({ value: theVal });
    }
    addValue() {
        var theVals = this.state.value;
        theVals.push({ label: "", value: "" });
        this.setState({ value: theVals })
    }

    removeVal(index) {
        var theVals = this.state.value;
        theVals.splice(index, 1);
        this.setState({ value: theVals })
        this.props.updateVal(this.props.path, theVals);
    }

    changeVal(event, index) {
        var theVals = this.state.value;
        var currVal = theVals[index];
        if (typeof currVal != "object") currVal = { value: currVal, label: currVal };
        if (event.target.name == "label") {
            let prevLabel = currVal.label;
            currVal.label = event.target.value;
            if ((currVal.value == "" || currVal.value == prevLabel) && this.props.valueCantBeBlank) {
                currVal.value = event.target.value;
            }
        }
        if (event.target.name == "value") currVal.value = event.target.value;

        theVals[index] = currVal;
        this.setState({ value: theVals })
        this.props.updateVal(this.props.path, theVals);
    }

    sortValues() {
        var theVals = this.state.value;
        theVals.sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
        })
        this.setState({ value: theVals })
        this.props.updateVal(this.props.path, theVals);
    }



    render() {

        var style = {};
        if (this.props.elementData.optionFullWidth) style = { flex: "100%" }

        return <div className="dd_toolHolder" style={style}>
            <div className="dd_inputTool">
                <p className="txtInput_label">{this.props.title}</p>
                <div className="inputWrapper">


                    {this.state.value.length > 0 && <div className="mar-b15">
                        {this.state.value.map((theVal, index) => {


                            var theValue = "";
                            var theLabel = "";

                            if (typeof theVal == "object") {
                                theValue = theVal.value;
                                theLabel = theVal.label;
                            }
                            else {
                                theValue = theVal;
                                theLabel = theVal;
                            }

                            let labelTxt = "Label"
                            let valueTxt = "Value"

                            if (this.props.fieldLabelTxt) labelTxt = this.props.fieldLabelTxt;
                            if (this.props.fieldValueTxt) valueTxt = this.props.fieldValueTxt
                            const isErroredValue = this.props.valueCantBeBlank && theValue == "";

                            return <div key={"theOpt-" + index} className="dd_subToolOptionHolder" style={{ position: "relative", padding: "10px", paddingRight: "25px" }}>
                                <div className="icon-cross" style={{ cursor: "pointer", position: "absolute", right: "5px", top: "5px" }} onClick={(event) => { this.removeVal(index) }} />
                                <div className="dd_toolHolder">
                                    <div className="dd_inputTool" style={{ marginBottom: "10px" }}>
                                        <UILIB.TextInput
                                            label={labelTxt}
                                            type="text"
                                            value={theLabel}
                                            name="label"
                                            placeHolder="Enter a label"
                                            onChange={(event) => { this.changeVal(event, index) }} />
                                    </div>
                                </div>
                                <div className="dd_toolHolder" >
                                    <div className="dd_inputTool" style={{ marginBottom: "0px" }}>
                                        <UILIB.TextInput
                                            label={valueTxt}
                                            type="text"
                                            value={theValue}
                                            name="value"
                                            placeHolder="Enter a value"
                                            onChange={(event) => { this.changeVal(event, index) }}
                                            style={isErroredValue ? { borderColor: "red" } : {}}
                                            error={isErroredValue ? "Value can't be blank" : ""}
                                        />
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    }

                    <div>
                        <UILIB.Button text="Add a Value" onClick={this.addValue} className="button-sml outline primary mar-r10" />
                        {this.state.value.length > 1 && <UILIB.Button text="Sort Values (A-Z)" onClick={this.sortValues} className="button-sml outline grey" />}
                    </div>

                </div>


            </div>

        </div>


    }
}



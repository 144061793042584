import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { parseChannelType } from "~/Classes/conversations";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import Button from "../../components/button";
import * as styles from "./index.module.scss";
import AddChannelDialog from "./components/addChannelDialog";
import DeleteChannelDialog from "./components/deleteChannelDialog";
import RenameChannelDialog from "./components/renameChannelDialog";
import Header from "../../components/header";
import i18n from "~/i18n";

export default function InboxChannels({ inbox }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [isAddChannelDialogOpen, setIsAddChannelDialogOpen] = useState(false);
  const [isRenameChannelDialogOpen, setIsRenameChannelDialogOpen] = useState(false);
  const [isDeleteChannelDialogOpen, setIsDeleteChannelDialogOpen] = useState(false);

  useEffect(() => {
    loadInitialData();
  }, []);

  async function loadInitialData() {
    try {
      await loadChannels();
    } finally {
      setLoading(false);
    }
  }

  async function loadChannels() {
    try {
      const { data } = await axios.get(`/inboxes/${inbox.id}/channel`);
      setChannels(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function addChannel(channelType) {
    const urlSuffix = parseChannelType(channelType).toLowerCase().replace(/\s/g, '');

    history.push(`/cp/conversations/${inbox.id}/channels/connect/${urlSuffix}`);
  }

  async function renameChannel(updatedChannel) {
    try {
      await axios.put(
        `/inboxes/${inbox.id}/channel/${updatedChannel.id}`,
        updatedChannel
      );

      loadInitialData();
      setIsRenameChannelDialogOpen(false);
      dispatch(alterSnackbar(true, i18n.t("chat:settings.channels.renamed")));
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function deleteChannel(channelToDelete) {
    try {
      await axios.delete(`/inboxes/${inbox.id}/channel/${channelToDelete.id}`);
      loadInitialData();
      setIsDeleteChannelDialogOpen(false);
      dispatch(alterSnackbar(true, i18n.t("chat:settings.channels.deleted")));
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function pauseChannel(channel) {
    try {
      await axios.put(`/inboxes/${inbox.id}/channel/${channel.id}`, {
        status: "paused",
      })
      await loadChannels()
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function enableChannel(channel) {
    try {
      await axios.put(`/inboxes/${inbox.id}/channel/${channel.id}`, {
        status: "running",
      })
      await loadChannels()
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  if (loading) return <UILIB.LoadingIcons noClass />;

  const emailChannelCount = channels.filter(c => c.type === 1).length;
  const activeEmailChannelCount = channels.filter(c => c.type === 1 && c.status === "running").length;
  return (
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
      <Header type="inbox" option="channels" />
      {channels.length > 0 && (
        <ul className={classnames(styles.channelsList, "faux-list")}>
          {channels.map((channel) => {
            const { id, name, type, status } = channel;
            let allowDeactivate = true;
            let allowDelete = true;
            if (type === 1 && emailChannelCount === 1) {
              allowDeactivate = false;
              allowDelete = false;
            }
            if (activeEmailChannelCount == 1 && channel.status == "running") {
              allowDelete = false;
              allowDeactivate = false;
            }
            let link = `/cp/conversations/${inbox.id}/channels/${id}`
            if (channel.status === 'draft' && channel.type === 1) link = `/cp/conversations/${inbox.id}/channels/connect/email?ChannelId=${id}`
            if (channel.status === 'draft' && channel.type === 2) link = `/cp/conversations/${inbox.id}/channels/connect/chat?ChannelId=${id}`
            if (channel.status === 'draft' && channel.type === 3) link = `/cp/conversations/${inbox.id}/channels/connect/facebookmessenger?ChannelId=${id}`

            return (
              <li key={id}>
                {/* Using a div here rather than a Link as we have a nested button */}
                <div className={styles.teaser}>
                  <Icon className={styles.teaserIcon} type={type} />
                  <div>
                    <Link className={styles.teaserLink} to={link}>
                      <h2 className={styles.teaserName}>{name}</h2>
                    </Link>

                    <span className={styles.teaserType}>
                      {parseChannelType(type)}
                    </span>
                  </div>

                  <Badge className={styles.teaserBadge} status={status} />

                  <UILIB.DropdownMenu.Root>
                    <UILIB.DropdownMenu.Trigger asChild>
                      <Button aria-label="Additional options" variant="ghost" size="s" iconOnly>
                        <UILIB.Icon name="dots-vertical" />
                      </Button>
                    </UILIB.DropdownMenu.Trigger>

                    <UILIB.DropdownMenu.Portal>
                      <UILIB.DropdownMenu.Content align="end">
                        {status === 'running' && allowDeactivate && <UILIB.DropdownMenu.Item onSelect={() => pauseChannel(channel)}>{i18n.t("chat:settings.channels.deActivate")}</UILIB.DropdownMenu.Item>}
                        {status === 'paused' && <UILIB.DropdownMenu.Item onSelect={() => enableChannel(channel)}>{i18n.t("chat:settings.channels.activate")}</UILIB.DropdownMenu.Item>}
                        <UILIB.DropdownMenu.Item onSelect={() => history.push(link)}>{i18n.t("chat:settings.channels.settings")}</UILIB.DropdownMenu.Item>
                        <UILIB.DropdownMenu.Item
                          onSelect={() => {
                            // Issue with autofocus when opening dialog from a dropdown https://github.com/radix-ui/primitives/issues/1615
                            setSelectedChannel(channel);
                            setIsRenameChannelDialogOpen(true);
                          }}
                        >
                          {i18n.t("chat:settings.channels.rename")}
                        </UILIB.DropdownMenu.Item>
                        {allowDelete && (
                          <>
                            <UILIB.DropdownMenu.Separator />

                            <UILIB.DropdownMenu.Item
                              variant="danger"
                              onSelect={() => {
                                // Issue with autofocus when opening dialog from a dropdown https://github.com/radix-ui/primitives/issues/1615
                                setSelectedChannel(channel);
                                setIsDeleteChannelDialogOpen(true);
                              }}
                            >
                              {i18n.t("chat:settings.channels.delete")}
                            </UILIB.DropdownMenu.Item>
                          </>
                        )}
                      </UILIB.DropdownMenu.Content>
                    </UILIB.DropdownMenu.Portal>
                  </UILIB.DropdownMenu.Root>
                </div>
              </li>
            );
          })}
        </ul>
      )}

      <Button
        className={styles.addButton}
        size="s"
        iconLeft={<UILIB.Icon name="plus" />}
        onClick={() => setIsAddChannelDialogOpen(true)}
      >
        {i18n.t("chat:settings.channels.addChannel")}
      </Button>

      <AddChannelDialog
        inboxChannels={channels}
        isOpen={isAddChannelDialogOpen}
        onSubmit={addChannel}
        onOpenChange={(isOpen) => setIsAddChannelDialogOpen(isOpen)}
      />

      <RenameChannelDialog
        key={`rename-${selectedChannel?.id}`} // For resetting dialog state when closed/opened
        channel={selectedChannel}
        isOpen={isRenameChannelDialogOpen}
        onSubmit={renameChannel}
        onOpenChange={(isOpen) => setIsRenameChannelDialogOpen(isOpen)}
      />

      <DeleteChannelDialog
        key={`delete-${selectedChannel?.id}`} // For resetting dialog state when closed/opened
        channel={selectedChannel}
        isOpen={isDeleteChannelDialogOpen}
        onSubmit={deleteChannel}
        onOpenChange={(isOpen) => setIsDeleteChannelDialogOpen(isOpen)}
      />
    </div>
  );
}

function Icon({ type, ...rest }) {
  let icon;

  switch (type) {
    case 1:
      icon = "mail-open";
      break;
    case 2:
      icon = "chat";
      break;
    case 3:
      icon = "facebook";
      break;
  }

  return <UILIB.Icon name={icon} {...rest} />;
}

function Badge({ status, ...rest }) {
  let color;
  let label;

  switch (status) {
    case "draft":
      label = i18n.t("chat:settings.channels.status.draft");
      break;

    case "running":
      color = "teal";
      label = i18n.t("chat:settings.channels.status.active")
      break;

    case "paused":
      label = i18n.t("chat:settings.channels.status.inactive")
  }

  return (
    <UILIB.Badge color={color} {...rest}>
      {label}
    </UILIB.Badge>
  );
}

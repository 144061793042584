import React from "react";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";

// Wraps the existing UILIB.TextArea component so we can override styles as the Conversations version has a few tweaks.
export default function ConversationsTextArea({
  label,
  description,
  className,
  uilibStyle = {},
  uilibOuterStyle = {},
  value = { value },
  error = { error },
  ...rest
}) {
  const id = window.crypto.randomUUID();

  const classes = classnames(styles.root, className);

  return (
    <div className={classes}>
      {label && <label htmlFor={id}>{label}</label>}

      {description && <div className={styles.description}>{description}</div>}

      <UILIB.TextArea id={id} style={uilibStyle} value={value} error={error} {...rest} outerStyle={uilibOuterStyle} />
    </div>
  );
}

import React from 'react'

export default function IconsOutbox({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00364 5.98746C8.26584 5.98974 8.51844 5.88894 8.70704 5.70679L9.00004 5.41379V9.99979C9.00004 10.265 9.1054 10.5194 9.29293 10.7069C9.48047 10.8944 9.73482 10.9998 10 10.9998C10.2653 10.9998 10.5196 10.8944 10.7071 10.7069C10.8947 10.5194 11 10.265 11 9.99979V5.41379L11.293 5.70679C11.4816 5.88894 11.7342 5.98974 11.9964 5.98746C12.2586 5.98518 12.5095 5.88001 12.6949 5.6946C12.8803 5.5092 12.9854 5.25838 12.9877 4.99619C12.99 4.73399 12.8892 4.48139 12.707 4.29279L10.707 2.29279C10.5195 2.10532 10.2652 2 10 2C9.73488 2 9.48057 2.10532 9.29304 2.29279L7.29304 4.29279C7.11088 4.48139 7.01009 4.73399 7.01237 4.99619C7.01465 5.25838 7.11981 5.5092 7.30522 5.6946C7.49063 5.88001 7.74144 5.98518 8.00364 5.98746ZM3.58579 3.58582C3.21071 3.96089 3 4.4696 3 5.00003V15C3 15.5305 3.21071 16.0392 3.58579 16.4142C3.96086 16.7893 4.46957 17 5 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0392 17 15.5305 17 15V5.00003C17 4.4696 16.7893 3.96089 16.4142 3.58582C16.0391 3.21074 15.5304 3.00003 15 3.00003H14.2071C13.9419 3.00003 13.6875 3.10539 13.5 3.29292C13.3125 3.48046 13.2071 3.73481 13.2071 4.00003C13.2071 4.26525 13.3125 4.5196 13.5 4.70714C13.6875 4.89467 13.9419 5.00003 14.2071 5.00003H15V12H13L12 14H8L7 12H5V5.00003H5.79289C6.05811 5.00003 6.31246 4.89467 6.5 4.70714C6.68754 4.5196 6.79289 4.26525 6.79289 4.00003C6.79289 3.73481 6.68754 3.48046 6.5 3.29292C6.31246 3.10539 6.05811 3.00003 5.79289 3.00003H5C4.46957 3.00003 3.96086 3.21074 3.58579 3.58582Z" fill={fillColor} />
        </svg>

    )
}
import React, { useEffect, useState, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import axios from '~/data/http/axios'
import UILIB from '~/Common-Objects/Lib'
import * as siteMasterActions from '~/data/actions/siteActions'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFormatFunctions from '~/Classes/numberFormatFunctions';
import SegmentEditor from '~/pages/cp/includes/segmentEditor/segmentEditor'
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area, Legend } from 'recharts';
import SmsPhone from '~/pages/cp/includes/sms/phone'
import i18n from '~/i18n'
import moment from 'moment'
import helpers from '~/Classes/helpers'
import SmsIPhone from '~/pages/cp/includes/sms/iphone'
import CampaignGraphSms from '../../shared/components/campaignGraphSms';


export default function SmsCampaign({ campaign, isAutomation }) {
    const { id } = useParams()

    const [stats, setStats] = useState({})
    const [customFields, setCustomFields] = useState([])
    const [graphData, setGraphData] = useState([])

    const [template, setTemplate] = useState(null)
    const [analysis, setAnalysis] = useState(null)

    const history = useHistory()
    const siteMaster = useSelector(state => state.siteMaster)
    const paperRef = useRef(null)
    const dispatch = useDispatch()

    const getStats = async () => {
        const res = await axios.get('/campaign/' + campaign.id + '/stats')
        setStats(res.data.Stats)
    }


    const graphOptions = [{
        label: i18n.t('campaigns:overview.statSends'),
        value: 'sends',
        link: 'sent'
    }, {
        label: i18n.t('campaigns:overview.statClicks'),
        value: 'clicks',
        link: 'clicked'
    }, {
        label: i18n.t('campaigns:overview.statUnsubscribes'),
        value: 'unsubscribes',
        link: 'unsubscribed'
    }]


    const getGraph = async () => {
        let queryStr = '?ignoreLog=1&group=DAYOFWEEK&stat=clicks&unique=true';
        queryStr += '&start=' + moment(campaign.startDate).format('YYYY-MM-DD');
        const end = campaign.finishedDate || new Date()
        queryStr += '&end=' + moment(end).format('YYYY-MM-DD');
        const res = await axios.get('/campaign/' + campaign.id + '/stats/graph' + queryStr)
        setGraphData(res.data.Stats.map(row => {
            return {
                date: row.str,
                clicks: row.value
            }
        }))
    }

    const getCustomFields = async () => {
        const res = await axios.get('/customfield')
        setCustomFields(res.data.CustomFields)
    }

    const viewGroupOrSegment = (row) => {
        if (row.type === 'Segment') {
            // show drawer in readonly mode
            const drawerContent = <SegmentEditor history={history} readonly={true} segmentId={row.id} close={() => dispatch(siteMasterActions.alterSiteDrawer(false))} />
            dispatch(siteMasterActions.alterSiteDrawer(true, true, "right", drawerContent, true, "100%"));
        } else {
            // go to group
            history.push('/cp/groups/' + row.id)
        }
    }

    useEffect(() => {
        getStats()
        getCustomFields()
        getGraph()
    }, [])

    useEffect(() => {
        if (campaign.TemplateId) {
            axios.get('/template/' + campaign.TemplateId).then(res => setTemplate(res.data.template))
        }
    }, [campaign.TemplateId])

    useEffect(() => {
        if (campaign.id) {
            const res = axios.get('/campaign/' + campaign.id + '/sms/estimate').then(res => setAnalysis(res.data))

        }
    }, [campaign.id])

    const statsTotalSubs = stats['Total Subscribers']
    const bouncePercent = (100 / (stats['Total Subscribers'] || 1)) * stats['Hard Bounces'] || 0;
    const colours = siteMaster.colours;

    let recipients = []
    if (campaign.Groups && campaign.Groups.length) {
        recipients = recipients.concat(campaign.Groups.map(g => {
            return {
                name: g.groupName,
                id: g.id,
                type: 'Group',
                include: g.CampaignGroup.include
            }
        }))
    }

    if (campaign.Segments && campaign.Segments.length) {
        recipients = recipients.concat(campaign.Segments.map(g => {
            return {
                name: g.name,
                id: g.id,
                type: 'Segment',
                include: g.CampaignSegment.include
            }
        }))
    }

    if (!template) return <UILIB.LoadingIcons iconType="2" />
    const result = helpers.getSmsCredits(campaign, template)

    const recipientsIncluded = recipients.filter(rec => rec.include == true)
    const recipientsExcluded = recipients.filter(rec => rec.include == false)
    const field = customFields.find(cf => cf.id == campaign.options.smsField)

    let height = 320
    if (paperRef.current) {
        height = paperRef.current.offsetHeight - 80
    }

    return (
        <div>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={3} margin={0}>
                    <UILIB.Paper className=" dashHeight">
                        <UILIB.Row className="mar-b10">
                            <UILIB.Column xs={6} sm={6} md={6} lg={8} margin={0}>
                                <p className="text-md text-heavy mar-b10 no-marg">{i18n.t('campaigns:overview.subscribersSentSMS')}</p>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row className="mar-b10">
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                <div className="quickFlex" style={{ justifyContent: 'space-between' }}>
                                    <p className="text-lg text-heavy  no-marg">{NumberFormatFunctions.formatNumber(statsTotalSubs) || 0}</p>
                                </div>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                {!!analysis && <UILIB.SmallLineGraphAlt width="100%" height="4" textAlign={"end-xs text-grey"} hideText={false} color={colours.$primary} onlyPercent={false} val2={(analysis.filter(a => a.count).map(a => (a.count)).reduce((acc, num) => acc + Number(num), 0)) || 0} val1={statsTotalSubs || 0} />}
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={12} md={3} margin={0}>
                    <UILIB.Paper className=" dashHeight">
                        <UILIB.Row className="mar-b10">
                            <UILIB.Column xs={6} sm={6} md={6} lg={8} margin={0}>
                                <p className="text-md text-heavy mar-b10 no-marg">{i18n.t('campaigns:overview.subscribersClickedSMS')}</p>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row className="mar-b10">
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                <div className="quickFlex" style={{ justifyContent: 'space-between' }}>
                                    {/* <p className="text-mxl  no-marg">{NumberFormatFunctions.formatNumber(stats['Unique Clicks']) || 0}</p> */}
                                    <p className="text-lg text-heavy no-marg">{NumberFormatFunctions.formatNumber(statsTotalSubs) || 0}</p>
                                </div>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                <UILIB.SmallLineGraphAlt width="100%" height="4" textAlign={"end-xs text-grey"} hideText={false} color={colours.$primary} onlyPercent={false} val1={stats['Unique Clicks'] || 0} val2={statsTotalSubs || 0} />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={12} md={3} margin={0}>
                    <UILIB.Paper className="dashHeight">
                        <UILIB.Row className="mar-b10">
                            <UILIB.Column xs={6} sm={6} md={6} lg={8} margin={0}>
                                <p className="text-md text-heavy mar-b10 no-marg">{i18n.t('campaigns:overview.statUnsubscribesSMS')}</p>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row className="mar-b10">
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                <div className="quickFlex" style={{ justifyContent: 'space-between' }}>
                                    <p className="text-lg text-heavy no-marg">{NumberFormatFunctions.formatNumber(statsTotalSubs) || 0}</p>
                                </div>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                <UILIB.SmallLineGraphAlt width="100%" height="4" textAlign={"end-xs text-grey"} hideText={false} onlyPercent={false} val1={stats['Total Unsubscribes'] || 0} val2={statsTotalSubs || 0} />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>


                <UILIB.Column xs={12} sm={12} md={3} margin={0}>
                    <UILIB.Paper className={"dashHeight " + (bouncePercent > 10 ? 'gradientRed' : 'gradient')}>
                        <UILIB.Row className="mar-b10">
                            <UILIB.Column xs={6} sm={6} md={6} lg={8} margin={0}>
                                <p className="text-md text-heavy mar-b10  no-marg">{i18n.t('campaigns:overview.statHardBouncesSMS')}</p>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row className="mar-b10">
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                <div className="quickFlex" style={{ justifyContent: 'space-between' }}>
                                    <p className="text-lg text-heavy no-marg">{NumberFormatFunctions.formatNumber(stats['Total Subscribers']) || 0}</p>
                                </div>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0}>
                                <UILIB.SmallLineGraphAlt width="100%" height="4" textAlign={"end-xs text-grey"} hideText={false} color={bouncePercent > 10 ? colours['$status-red'] : colours.$primary} onlyPercent={false} val1={stats['Hard Bounces'] || 0} val2={stats['Total Subscribers'] || 0} />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={6} lg={6}>
                    <UILIB.Paper className="paper-padding-24">
                        <p className="text-md text-heavy mar-b25">Campaign Details</p>
                        <UILIB.Row>
                            <UILIB.Column xs={12} md={12}>
                                <p className="text-heavy mar-b0">Name</p>
                                <p>{campaign.campaignName}</p>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} className='mar-b10'>
                                <p className="text-heavy mar-b0">From Name</p>
                                <p>{campaign.subject}</p>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} className='mar-b10'>
                                <p className="text-heavy mar-b0">Start Date</p>
                                <p>{DateTimeFunctions.formatDateTime(campaign.startDate, 1)}</p>
                            </UILIB.Column>

                            <UILIB.Column xs={12} sm={12} className=' mar-b10'>
                                <p className="text-heavy mar-b0">Recipients</p>
                                {!!analysis && analysis.filter(a => a.count).map(a => <div key={'co_' + a.country} className='mar-b10'>{NumberFormatFunctions.formatNumber(a.count)}</div>)}
                            </UILIB.Column>


                            <UILIB.Column xs={12} sm={6}>
                                <p className="text-heavy mar-b5">Groups</p>
                                {campaign.Groups.map((row, index) => {
                                    return <UILIB.SquareChip
                                        tooltip={"Group: " + row.groupName}
                                        for={row.groupName}
                                        key={"groupInc_" + index}
                                        className={"mar-r10 mar-b5 square-chip-green"}
                                    >
                                        {row.groupName}
                                    </UILIB.SquareChip>

                                })}
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6}>
                                <p className="text-heavy mar-b5">Segments</p>
                                {campaign.Segments.map((row, index) => {
                                    return <UILIB.SquareChip
                                        tooltip={"Segment: " + row.name}
                                        for={row.name}
                                        key={"segmentInc" + index}
                                        className={"mar-r10 mar-b5 square-chip-purple"}
                                    >
                                        {row.name}
                                    </UILIB.SquareChip>

                                })}
                            </UILIB.Column>


                        </UILIB.Row>
                    </UILIB.Paper>
                    <UILIB.Paper style={{ display: 'flex', justifyContent: 'center' }}>
                        <UILIB.Column xs={12}>
                            <SmsIPhone from={campaign.subject} content={result.html} />
                        </UILIB.Column>
                    </UILIB.Paper>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={12} md={6} lg={6}>
                    <CampaignGraphSms
                        startDate={campaign.startDate}
                        endDate={campaign.endDate}
                        campaignId={campaign.id}
                        onNavigate={(path) => { this.props.history.push('/cp/campaigns/view/' + campaign.id + '/' + path) }}
                        autoHeight={true}
                    />
                </UILIB.Column>

            </UILIB.Row>

        </div>
    )
}
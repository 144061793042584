import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function useSignature(channel) {
    const user = useSelector(state => state.user);
    const [signature, setSignature] = useState("");

    useEffect(() => {
        if (!channel?.settings?.signature) return;
        let str = parseSignature(channel.settings.signature, user);
        str = "<br/><br/>" + str;
        setSignature(str)
    }, [channel, user])

    return signature

}

export function getSignature(channel) {
    const user = useSelector(state => state.user);
    let signature = "";
    if (!channel?.settings?.signature) return signature;
    let str = parseSignature(channel.settings.signature, user);
    str = "<br/><br/>" + str;
    return str
}


export function parseSignature(signature, user) {
    let str = signature;
    if (!str) return ""
    if (str.indexOf('{{user_first_name}}' > -1)) str = str.replace('{{user_first_name}}', user.userData.firstName);
    if (str.indexOf('{{user_last_name}}' > -1)) str = str.replace('{{user_last_name}}', user.userData.lastName);
    if (str.indexOf('{{user_email}}' > -1)) str = str.replace('{{user_email}}', user.userData.emailAddress);
    return str
}
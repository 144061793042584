import React from 'react'

export default class ConversationsPromptsSuggestion extends React.Component {

    render() {
        let color = "#865ff1";
        if (this.props.color) color = this.props.color;

        return <svg width="85" height="72" style={this.props.style} viewBox="0 0 85 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.25 4.25H78.75V60C78.75 64.2802 75.2802 67.75 71 67.75H6.25V4.25Z" fill="white" stroke="url(#paint0_radial_2548_23609)" strokeWidth="0.5" />
            <path d="M6.5 39.5C6.5 37.567 8.067 36 10 36H66C67.933 36 69.5 37.567 69.5 39.5V45H10C8.067 45 6.5 43.433 6.5 41.5V39.5Z" fill={color} stroke={color} />
            <path d="M30.5 26C30.5 24.067 32.067 22.5 34 22.5H66C67.933 22.5 69.5 24.067 69.5 26V31.5H34C32.067 31.5 30.5 29.933 30.5 28V26Z" fill={color} stroke={color} />
            <g filter="url(#filter0_d_2548_23609)">
                <rect x="56" y="8.5" width="14" height="10" rx="5" fill="white" />
                <rect x="56.25" y="8.75" width="13.5" height="9.5" rx="4.75" stroke="#B5BAC0" strokeWidth="0.5" />
                <path d="M61.5 12L64.5 15" stroke="#7C8086" strokeWidth="0.75" strokeLinecap="round" />
                <path d="M64.5 12L61.5 15" stroke="#7C8086" strokeWidth="0.75" strokeLinecap="round" />
            </g>
            <g filter="url(#filter1_d_2548_23609)">
                <rect x="56" y="49" width="14" height="10" rx="5" fill="white" />
                <rect x="56.25" y="49.25" width="13.5" height="9.5" rx="4.75" stroke="#B5BAC0" strokeWidth="0.5" />
                <path d="M61.999 52.7511C62.0871 52.5007 62.261 52.2895 62.4899 52.155C62.7188 52.0205 62.9879 51.9713 63.2495 52.0162C63.5112 52.0611 63.7485 52.1971 63.9195 52.4002C64.0905 52.6033 64.184 52.8604 64.1836 53.1258C64.1836 53.8753 63.0595 54.25 63.0595 54.25M63.074 55.75H63.079" stroke="#7C8086" strokeWidth="0.75" strokeLinecap="round" />
            </g>
            <path d="M11 40.5H22" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M26 40.5H34" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M38 40.5H41" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M45 40.5H56" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M60 40.5H65" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M35 27H38" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M42 27H43" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M47 27H56" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M60 27H65" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
            <defs>
                <filter id="filter0_d_2548_23609" x="54" y="7" width="18" height="14" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="0.5" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0941176 0 0 0 0 0.0980392 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2548_23609" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2548_23609" result="shape" />
                </filter>
                <filter id="filter1_d_2548_23609" x="54" y="47.5" width="18" height="14" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="0.5" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0941176 0 0 0 0 0.0980392 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2548_23609" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2548_23609" result="shape" />
                </filter>
                <radialGradient id="paint0_radial_2548_23609" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(79.5573 68.5872) rotate(-138.715) scale(97.8886 118.602)">
                    <stop stop-color="#9B9FA6" />
                    <stop offset="0.494792" stop-color="#9B9FA6" stop-opacity="0" />
                </radialGradient>
            </defs>
        </svg>
    }
}        
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { SupportedApps, getSites, connect } from './shared'
import axios from '../../../../../data/http/axios'
import UILIB from '../../../../../Common-Objects/Lib'
import i18n from '../../../../../i18n'

export default function SocialCampaignSelectChannels({ campaign, refresh = () => { } }) {
    const history = useHistory()
    const site = useSelector(state => state.siteMaster)
    const user = useSelector(state => state.user)
    const [apps, setApps] = useState(null)
    const [accApps, setAccApps] = React.useState(null)
    const [campaigns, setCampaigns] = useState(campaign.Campaigns || [])
    const [socialAccounts, setSocialAccounts] = useState(null)
    const [popupLinks, setPopupLinks] = useState(null)
    const timer = useRef(null)

    const getApps = async () => {
        const socialRes = await axios.get('/social')
        setSocialAccounts(socialRes.data)
        axios.get('/accountMaster/application?includeSocialAccounts=true').then(async res => {
            if (accApps && res.data.Applications.length > accApps.length) {
                if (timer.current) clearInterval(timer.current)
                return setAccApps(res.data.Applications)
            }
            if (!accApps) {
                setAccApps(res.data.Applications)
                // auto add provider for all the connected apps on first load
                if (!campaign.Campaigns.length) {
                    for (const app of SupportedApps) {
                        if (app.disabled) continue
                        if (res.data.Applications.some(a => a.ApplicationId === app.id)) {
                            await addProvider(app.code, socialRes.data)
                        }
                    }
                }
            }
        })
        if (!apps) {
            axios.get('/application').then(res => {
                setApps(res.data.Applications.filter(a => SupportedApps.some(sa => sa.id === a.id)))
            })
        }
    }

    const getLinks = async () => {
        const links = {}
        for (const app of apps) {
            const link = await connect(site, user, app)
            links[app.id] = link
        }
        setPopupLinks(links)
    }

    const startTimer = () => {
        timer.current = setInterval(() => getApps(), 2000)
    }

    const addProvider = async (code, socials) => {
        const existing = campaigns.some(c => c.options.AppCode === code)
        if (!existing) {
            const templateRes = await axios.post('/template', {
                templateType: 30,
                templateName: campaign.campaignName + ' - ' + code,
                templateJson: {
                    content: campaign.Template.templateJson.content,
                    images: campaign.Template.templateJson.images
                }
            })
            const arr = socials || socialAccounts
            const social = arr ? arr.find(sa => sa.type === code) : undefined
            await axios.post('/campaign', {
                type: 31,
                campaignName: campaign.campaignName + ' - ' + code,
                TemplateId: templateRes.data.Template.templateID,
                parentCampaignId: campaign.id,
                options: {
                    AppCode: code,
                    SocialAccountId: social?.id
                }
            })
            await refresh()
            getSites(code, true)
        }
    }

    const remove = async (campaign) => {
        await axios.delete('/campaign/' + campaign.id)
        await refresh()
    }

    useEffect(() => {
        getApps()

        return function () {
            if (timer.current) clearInterval(timer.current)
        }
    }, [])

    useEffect(() => {
        setCampaigns(campaign.Campaigns)
    }, [campaign.Campaigns])

    useEffect(() => {
        if (apps?.length) {
            getLinks()
        }
    }, [apps])

    let submitDisabled = !campaigns.length


    return (
        <div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                    <h4 className="mar-b25 quickFlex">
                        {i18n.t('social:channels.header')}
                        <UILIB.Hint iconRight={<UILIB.Icons icon="external" />} target="_blank" href="https://kb.transpond.io/article/163-social-campaigns">{i18n.t('social:channels.help')}</UILIB.Hint>
                    </h4>
                </div>
            </div>
            {!apps || !accApps && <UILIB.LoadingIcons />}
            {!!apps?.length && !popupLinks && <UILIB.LoadingIcons />}
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                    {!!apps && !!accApps && !!popupLinks && SupportedApps.map((base, index) => {
                        const app = apps.find(a => a.id === base.id)
                        const accApp = accApps.find(a => a.ApplicationId === base.id)
                        const campaign = campaigns.find(c => c.options?.AppCode === base.code)
                        const link = popupLinks?.[base.id]
                        return <div key={index}>
                            <Channel link={link} base={base} app={app} accApp={accApp} campaign={campaign} connect={startTimer} add={addProvider} remove={remove} />
                        </div>
                    })}
                </div>
            </div>
            <UILIB.StickyNavBottom>
                <div className="quickFlex" style={{ flexDirection: 'row-reverse', alignItems: "center" }}>
                    <UILIB.Button iconRight={<UILIB.Icons icon="arrowRight" />} className={'button-primary mar-l5'} disabled={submitDisabled} onClick={() => history.push(`/cp/campaigns/add/social/${campaign.id}/2`)}>{i18n.t('campaigns:standard.nextStep')}</UILIB.Button>
                </div>
            </UILIB.StickyNavBottom >
        </div>
    )
}

function Channel({ base, app, accApp, campaign, link, connect = () => { }, add = () => { }, remove = () => { } }) {
    const [loading, setLoading] = useState(false)
    const toggle = async () => {
        setLoading(true)
        if (campaign) {
            await remove(campaign)
        } else {
            await add(base.code)
        }
        setLoading(false)
    }

    const openPopup = function (url) {
        // Define the size and position of the popup window
        const width = 1400;
        const height = 670;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        // Open the popup window
        window.open(url, '', `height=${height},width=${width},left=${left},top=${top},toolbar=no,menubar=no,status=no`);
        // window.open(url, '', 'width=' + width + ', height=' + height + ', left=' + left + ', top=' + top + ',toolbar=no,menubar=no,status=no');

        connect()
    }

    return <UILIB.Paper style={{ padding: 16, marginBottom: 12 }} className="with-shadow">
        <div className="quickFlex" style={{ alignItems: 'center' }}>
            <div className="mar-r20">
                {!!base.icon && <UILIB.Icons icon={base.icon} style={{ height: 20, width: 20 }} disabled={!campaign} />}
                {!base.icon && <img src={base.logo} style={{ height: 20 }} />}
            </div>
            <div style={{ flexGrow: 1 }}>
                <div><strong>{i18n.t('social:channels.' + base.code)}</strong></div>
                <div className="text-grey text-sml">{i18n.t('social:channels.' + base.code + '_DESC')}</div>
            </div>
            {!base.disabled && <div>
                {!accApp && <UILIB.Button target="_blank" className="button-primary button-sml" onClick={() => openPopup(link)}>{i18n.t('social:channels.connect')}</UILIB.Button>}
                {(!!accApp && loading) ? <UILIB.LoadingIcons style={{ width: 20, height: 20 }} /> : !!accApp && <><UILIB.Toggle onChange={toggle} checked={!!campaign} disabled={loading} /></>}
            </div>}
            {base.disabled && <div>
                <UILIB.SquareChip>{i18n.t('social:channels.disabled', { app: i18n.t('social:channels.' + base.code) })}</UILIB.SquareChip>
            </div>}
        </div>
    </UILIB.Paper>
}
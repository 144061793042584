import React, { useState } from "react";
import UILIB from "~/Common-Objects/Lib";

export default function AddLink({ onAdd }) {
  const [url, setUrl] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    onAdd(url);
  }

  return (
    <>
      <h2 className="h4 mar-b15">Enter your URL below</h2>

      <p className="mar-b25">
        You won't be able to stop people from sharing this link once it's been
        downloaded so you'll need to manage that yourself
      </p>

      <form onSubmit={handleSubmit}>
        <UILIB.TextInput
          focus
          outerClassName="mar-b25"
          value={url}
          label="URL"
          placeholder="https://mylink.com"
          onChange={(e) => setUrl(e.target.value)}
        />

        <UILIB.Button type="submit" disabled={!url} className="button-primary">
          Add
        </UILIB.Button>
      </form>
    </>
  );
}

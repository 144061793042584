import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import ArrowDown from '~/assets/images/icons/nav-arrow-down.svg';

class HubspotFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availableFields: [],
            ready: false,
            filter: ''
        }
        this.getAvailableFields = this.getAvailableFields.bind(this)
        this.toggleField = this.toggleField.bind(this)
        this.toggleAllFields = this.toggleAllFields.bind(this)
        this.filterFields = this.filterFields.bind(this)
    }

    componentDidMount() {
        this.getAvailableFields()
    }

    getAvailableFields() {
        axios.get('/application/nimble/fields').then(response => {
            this.setState({
                availableFields: response.data,
                ready: true
            })
        })
    }

    toggleField(ev) {
        if (ev.currentTarget.name === 'email') return
        if (!this.props.appBinding.appSettings) this.props.appBinding.appSettings = {};
        var settings = this.props.appBinding.appSettings;
        if (!settings.fields) settings.fields = [];
        if (ev.currentTarget.checked) {
            settings.fields.push(ev.currentTarget.name)
        } else {
            var index = settings.fields.indexOf(ev.currentTarget.name)
            if (index > -1) {
                settings.fields.splice(index, 1)
            }
        }
        this.setState({})
    }

    toggleAllFields(on) {
        if (!this.props.appBinding.appSettings) this.props.appBinding.appSettings = {};
        var settings = this.props.appBinding.appSettings;
        if (!settings.fields) settings.fields = [];
        if (on) {
            settings.fields = this.state.availableFields.slice()
        } else {
            settings.fields = ['email']
        }
        this.setState({})
    }

    filterFields(val) {
        this.setState({
            filter: val
        })
    }

    render() {
        if (!this.state.ready) return <UILIB.LoadingIcons iconType="2" />

        var availableFields = this.state.availableFields.slice()
        if (this.state.filter) {
            availableFields = availableFields.filter(f => f.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1)
        }

        availableFields = availableFields.sort((a, b) => {
            if (a < b) { return -1; }
            if (a > b) { return 1; }
            return 0;
        })

        return (
            <div>
                <UILIB.ButtonSelect width={450} className="with-icon white mar-b10" icon={ArrowDown} headerText="Select Fields" filter={true} updateFilter={this.filterFields} selectAll={() => this.toggleAllFields(true)} clearAll={() => this.toggleAllFields(false)} >
                    {availableFields && !!availableFields.length && <table width="100%" cellpadding="0" cellspacing="0">
                        {availableFields.map((field) => {
                            var selected = false
                            if (this.props.appBinding.appSettings && this.props.appBinding.appSettings.fields) {
                                selected = this.props.appBinding.appSettings.fields.some(p => p === field)
                            }
                            return <tr key={field}>
                                <td width="10" style={{ verticalAlign: "top" }}>
                                    <UILIB.CheckBox key={field} name={field} onChange={this.toggleField} checked={selected} />
                                </td>
                                <td className="pad-left" style={{ verticalAlign: "top" }}>
                                    <div>{field}</div>
                                </td>
                            </tr>
                        })}
                    </table>}
                </UILIB.ButtonSelect>
                {!!this.props.appBinding.appSettings && !!this.props.appBinding.appSettings.fields && <div>
                    {this.props.appBinding.appSettings.fields.map(p => {
                        var prop = this.state.availableFields.find(property => property === p)
                        if (!prop) return ''
                        return <span key={prop}><UILIB.SquareChip className="square-chip-primary" tooltip={prop} value={prop} for={prop} hasButton={true} iconRight={<UILIB.Icons icon="crossCircle" style={{ height: 14, width: 14 }} />} onClick={() => this.toggleField({
                            currentTarget: {
                                checked: false,
                                name: prop
                            }
                        })} /></span>
                    })}
                </div>}
            </div>
        );
    }
}

export default HubspotFields;
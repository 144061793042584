import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import axios from '../../../../data/http/axios'
import UILIB from '~/Common-Objects/Lib'
import NumberFunctions from '~/Classes/numberFormatFunctions'
import StripeAddCard from '~/pages/cp/includes/upgrade/stripeAddCard'

export default function TopupSms({ onClose = () => { } }) {
    const site = useSelector(state => state.siteMaster)
    const [account, setAccount] = useState(null)
    const [credits, setCredits] = useState(100)
    const [price, setPrice] = useState(0)
    const [paymentError, setPaymentError] = useState('')
    const [paymentCards, setPaymentCards] = useState([])
    const [manuallyAddingCard, setManuallyAddingCard] = useState(false)
    const [reloadingCard, setReloadingCard] = useState(false)
    const [selectedCard, setSelectedCard] = useState(null)
    const [checkingPrice, setCheckingPrice] = useState(false)
    const [buying, setBuying] = useState(false)
    const checkPriceTimeout = useRef(null)

    const getAccount = async () => {
        const res = await axios.get('/accountMaster')
        setAccount(res.data.AccountMaster)
    }


    const getPaymentCards = async () => {
        const res = await axios.get('/accountMaster/paymentMethod/stripe')
        let defaultCard;
        if (res.data.customer && res.data.customer.default_source) {
            defaultCard = res.data.customer.default_source
        }
        setPaymentCards(res.data.customer.sources.data)
        setManuallyAddingCard(false)
        setSelectedCard(defaultCard)
    }

    const selectCard = async (event) => {
        const newCardId = event.target.value
        setReloadingCard(true)
        await axios.put('/accountMaster/paymentMethod/stripe', {
            default_source: newCardId
        })
        setManuallyAddingCard(false)
        setReloadingCard(false)
        setSelectedCard(newCardId)
    }

    const addNewCard = () => {
        setManuallyAddingCard(s => !s)
        setSelectedCard(null)
    }

    const calcPrice = async () => {
        setCheckingPrice(true)
        const res = await axios.get('/accountMaster/sms/price?credits=' + credits)
        setPrice(res.data.price)
        setCheckingPrice(false)
    }

    const buy = async () => {
        setBuying(true)
        try {
            await axios.put('/accountMaster/sms/credits', { credits })
            onClose()
        } catch (e) {
            setPaymentError(e ? e.message || e : 'Error Taking Payment')
        }
        setBuying(false)
    }

    useEffect(() => {
        if (checkPriceTimeout.current) {
            clearTimeout(checkPriceTimeout.current)
        }
        checkPriceTimeout.current = setTimeout(calcPrice, 100)
    }, [credits])

    useEffect(() => {
        getAccount()
        getPaymentCards()
    }, [])

    if (!account) return <UILIB.LoadingIcons iconType="2" />

    let finalPrice = price;
    let vatAmt = 0;

    if (account.addVAT) {
        vatAmt = (finalPrice / 100) * 20
        finalPrice = finalPrice + vatAmt;
    }

    const currency = account.currency === "GBP" ? '£' : account.currency === 'USD' ? '$' : '€'

    return (
        <div>
            <h4 className="mar-b5">Top Up your SMS Credits</h4>
            <p className='text-grey mar-b25'>Choose the amount to purchase below. The more you buy, the more discount you'll receive.</p>
            {Boolean(paymentError && paymentError.length) && <div className="mar-b20 text-red">{paymentError}</div>}


            <UILIB.TextInput outerClassName="mar-b25" label={<span className='text-heavy'>{"Credits (£" + site.smsPrice / 100 + " per credit)"}</span>} disabled={buying} id="credits" name="credits" type="number" value={credits} onChange={e => setCredits(parseInt(e.target.value))} />



            <UILIB.Row>
                <div className="mar-t10 mar-b5" style={{ width: '100%', margin: '0 13px', display: "flex", justifyContent: 'space-between', fontSize: "14px" }}>
                    <span className='text-heavy'>Payment Card</span>
                    <a onClick={addNewCard}>Add New Card</a>
                </div>
            </UILIB.Row>
            <UILIB.Row>
                {(paymentCards && paymentCards.length > 0 && !manuallyAddingCard) &&
                    <UILIB.Column xs={12} className="mar-b20">
                        {!!reloadingCard && <UILIB.LoadingIcons iconType="2" />}
                        {!reloadingCard &&
                            <UILIB.Select style={{ paddingRight: 0 }} placeholder="Select an existing payment card..." data={paymentCards.map((card) => {
                                return { label: card.brand + " ending " + card.last4 + " (exp " + card.exp_month + "/" + card.exp_year + ")", value: card.id }
                            })} onChange={selectCard} value={selectedCard} />
                        }
                    </UILIB.Column>
                }
            </UILIB.Row>


            {(!paymentCards || !paymentCards.length || manuallyAddingCard) && <UILIB.Paper>
                <UILIB.Column xs={12} margin={0}>
                    <StripeAddCard close={getPaymentCards} hasCloseButton={manuallyAddingCard} />
                </UILIB.Column>
            </UILIB.Paper>
            }


            {(paymentCards && paymentCards.length > 0 && !manuallyAddingCard) && <div>
                <UILIB.Row >
                    <UILIB.Column xs={4} className="mar-b10 text-left text-sml">Price:</UILIB.Column>
                    {!checkingPrice && <UILIB.Column xs={4} margin={0} className="mar-b10 text-left text-sml">{currency}{NumberFunctions.formatNumber(price, 2)}</UILIB.Column>}
                    {checkingPrice && <UILIB.Column xs={4} margin={0} className="mar-b10 text-left text-sml loading">Loading</UILIB.Column>}

                </UILIB.Row>
                {account.addVAT && <UILIB.Row>
                    <UILIB.Column xs={4} className="mar-b10 text-left text-sml">VAT (20%):</UILIB.Column>
                    {!checkingPrice && <UILIB.Column xs={4} margin={0} className="mar-b10 text-left text-sml">{currency}{NumberFunctions.formatNumber(vatAmt, 2)}</UILIB.Column>}
                    {checkingPrice && <UILIB.Column xs={4} margin={0} className="mar-b10 text-left text-sml loading">Loading</UILIB.Column>}
                </UILIB.Row>}
                <UILIB.Row>
                    <UILIB.Column xs={4} className="mar-b25 text-left text-sml">Total to pay:</UILIB.Column>
                    {!checkingPrice && <UILIB.Column xs={4} className="mar-b25 text-sml text-heavy text-left">{currency}{NumberFunctions.formatNumber(finalPrice, 2)}</UILIB.Column>}
                    {checkingPrice && <UILIB.Column xs={4} className="mar-b25 text-lg text-left loading">Loading</UILIB.Column>}
                </UILIB.Row>

            </div>}


            <UILIB.Row>
                {Boolean(selectedCard) && Boolean(finalPrice) && finalPrice > 0 && <UILIB.Column xs={12}>
                    <UILIB.Button
                        disabled={buying}
                        className="button-primary"
                        text={"Purchase " + + NumberFunctions.formatNumber(credits, 1) + ' Credits'}
                        onClick={buy} />
                    {buying && <span className="mar-l10 loading text-grey">Working</span>}
                </UILIB.Column>}

            </UILIB.Row>



        </div >
    )
}
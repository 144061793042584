import React from 'react'

export default class IconsMedal extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 11.5951V8.625C6 8.19402 6.16857 7.7807 6.46863 7.47595C6.76869 7.1712 7.17565 7 7.6 7H12.4C12.8243 7 13.2313 7.1712 13.5314 7.47595C13.8314 7.7807 14 8.19402 14 8.625V11.5951C13.9366 11.586 13.8723 11.5736 13.8073 11.5577C12.8873 11.3322 11.9503 11.8612 11.69 12.7531C11.2048 14.4156 8.79525 14.4156 8.31004 12.7531C8.04976 11.8612 7.11275 11.3322 6.19277 11.5577C6.12774 11.5736 6.06345 11.586 6 11.5951Z" fill={color} />
            <path d="M6 13.6042C6.15318 13.5941 6.30832 13.5744 6.46497 13.5446C7.63239 16.8185 12.3676 16.8185 13.535 13.5446C13.6917 13.5744 13.8468 13.5941 14 13.6042V19L10 17.9688L6 19V13.6042Z" fill={color} />
            <path d="M8.31003 2.24693C8.79524 0.584356 11.2048 0.584356 11.69 2.24693C11.9503 3.13879 12.8873 3.6678 13.8072 3.44229C15.5223 3.02192 16.727 5.06244 15.4972 6.30464C14.8375 6.97099 14.8375 8.02901 15.4972 8.69536C16.727 9.93756 15.5223 11.9781 13.8073 11.5577C12.8873 11.3322 11.9503 11.8612 11.69 12.7531C11.2048 14.4156 8.79525 14.4156 8.31004 12.7531C8.04976 11.8612 7.11275 11.3322 6.19277 11.5577C4.47775 11.9781 3.27298 9.93756 4.50279 8.69536C5.16249 8.02901 5.16249 6.97099 4.50279 6.30464C3.27298 5.06244 4.47774 3.02191 6.19276 3.44229C7.11275 3.6678 8.04975 3.13879 8.31003 2.24693Z" fill={color} />
        </svg>




    }
}        
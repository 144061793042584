import React from 'react'
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class TemplateAddCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        let Component = <>
            <div className="template_select_row" onClick={this.props.buttonClick}>
                <div className="template_select_icon">
                    <UILIB.Icons icon={this.props.icon} circle={true} style={{ width: 30, height: 30 }} />
                </div>
                <div className="template_select_row_text">
                    <div>
                        <div className="template_select_row_title">{this.props.title}</div>
                        <div>{this.props.subTitle}</div>
                    </div>
                    <div className="template_select_row_button">
                        <UILIB.Button text={this.props.buttonText} className={this.props.buttonClass} onClick={this.props.buttonClick} />
                    </div>
                </div>

            </div>
            {this.props.showImage == "dragDrop" && <div className="template_select_dragDropImage" onClick={this.props.buttonClick}></div>}
            {this.props.showDivider && <div className="template_select_divider"></div>}
        </>

        if (this.props.paper) {
            return <UILIB.Paper style={this.props.paperStyle}>{Component}</UILIB.Paper>
        }

        return Component;

    };
}


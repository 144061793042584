import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '../../../../data/http/axios';
class Bounces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bounces: [],
            bounceSource: '',
            loaded: false
        }

        this.getBounces = this.getBounces.bind(this);
    }

    componentDidMount() {
        this.getBounces();
    }

    getBounces() {
        // get the bounces
        axios.get('/accountMaster/mailserverbounces?range=' + this.state.bounces + '&type=' + this.props.bounceType + '&mailServerId=' + this.props.mailServerId).then(response => {

            var bounceData = response.data.map(b => {
                if (!b.url || !b.url.length || b.url == null || b.url == undefined)
                    b.url = "Misc"
                if (!b.spamUrl || !b.spamUrl.length || b.spamUrl == null || b.spamUrl == undefined)
                    b.spamUrl = "Misc"
                return b
            })
            this.setState({
                bounces: bounceData,
                loaded: true
            }, this.getCampaigns)
        })
    }
    render() {
        if (!this.state.loaded) return <div><UILIB.LoadingIcons iconType="1" /></div>
        var bounces = []
        this.state.bounces.forEach(bounce => {
            var url = bounce.spamUrl.replace('http://', '').replace('https://', '')
            var found = bounces.find(b => b.url === url)
            if (!found) {
                found = { url, count: 0, bounces: [] }
                bounces.push(found)
            }
            found.count += parseInt(bounce.count)
            found.bounces.push(bounce)
        })
        if (!bounces.length) return <div>
            <h4 className="mar-b25">Todays <span style={{ textTransform: "capitalize" }}>{this.props.bounceType}</span> Bounces</h4>
            <p>Good news! There have been no {this.props.bounceType} bounces today so far!</p>
        </div>

        bounces.sort((a, b) => b.count - a.count)

        var url = this.state.bounceSource || bounces[0].url
        var expanded = bounces.find(b => b.url === url)
        var bounceData = bounces.map(b => {
            return { label: b.url + ' (' + b.count + ')', value: b.url }
        })
        return (
            <div>
                <h4 className="mar-b25">Todays <span style={{ textTransform: "capitalize" }}>{this.props.bounceType}</span> Bounces</h4>
                <p>We've grouped up todays {this.props.bounceType} bounced below so you can quickly determine where your sending issues lie. Please click the bounce sources below to view details of the bounces received.</p>

                <h4 className="mar-b25">Bounce Sources</h4>
                <div className="form-group">
                    <UILIB.Select data={bounceData} name="bounceSource" value={this.state.bounceSource} placeholder="Please select a bounce source" onChange={(event) => { this.setState({ bounceSource: event.target.value }) }} />
                </div>

                {this.state.bounceSource && this.state.bounceSource.length &&
                    <div style={{ overflow: "auto", border: "1px solid #DDDDDD", height: "calc(100vh - 340px)" }}>
                        <UILIB.Row style={{ margin: "0px" }}>
                            {!!expanded && <UILIB.Column xs={12} style={{ margin: "0px" }}>
                                <UILIB.Row style={{ borderBottom: '1px solid #f3f4f7', fontWeight: 'bold', margin: "0px" }}>
                                    <UILIB.Column xs={1} className="text-center">Count</UILIB.Column>
                                    <UILIB.Column xs={11}>Bounce Detail</UILIB.Column>
                                </UILIB.Row>
                            </UILIB.Column>}
                            {!!expanded && expanded.bounces.map((bounce, index) => {
                                return <UILIB.Column xs={12} key={index}>
                                    <UILIB.Row style={{ borderBottom: '1px solid #f3f4f7', margin: "0px" }}>
                                        <UILIB.Column xs={1} className="text-center">{bounce.count}</UILIB.Column>
                                        <UILIB.Column xs={11} style={{ wordBreak: "break-all" }}>{bounce.message}</UILIB.Column>
                                    </UILIB.Row>
                                </UILIB.Column>
                            })}
                        </UILIB.Row>
                    </div>
                }
            </div>
        );
    }
}

export default Bounces;
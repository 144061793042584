import React from 'react'

export default class IconsTablet extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6 2.00006C5.46957 2.00006 4.96086 2.21077 4.58579 2.58585C4.21071 2.96092 4 3.46963 4 4.00006V16.0001C4 16.5305 4.21071 17.0392 4.58579 17.4143C4.96086 17.7893 5.46957 18.0001 6 18.0001H14C14.5304 18.0001 15.0391 17.7893 15.4142 17.4143C15.7893 17.0392 16 16.5305 16 16.0001V4.00006C16 3.46963 15.7893 2.96092 15.4142 2.58585C15.0391 2.21077 14.5304 2.00006 14 2.00006H6ZM10 16.0001C10.2652 16.0001 10.5196 15.8947 10.7071 15.7072C10.8946 15.5196 11 15.2653 11 15.0001C11 14.7348 10.8946 14.4805 10.7071 14.293C10.5196 14.1054 10.2652 14.0001 10 14.0001C9.73478 14.0001 9.48043 14.1054 9.29289 14.293C9.10536 14.4805 9 14.7348 9 15.0001C9 15.2653 9.10536 15.5196 9.29289 15.7072C9.48043 15.8947 9.73478 16.0001 10 16.0001Z" fill={color} />
        </svg>



    }
}        
import React, { useEffect, useState } from "react";
import TextInput from "../textInput";
import * as styles from "./index.module.scss";

export default function ConversationsColorInput({ value, ...rest }) {
  const [formattedValue, setFormattedValue] = useState(value);

  useEffect(() => {
    if (value.length && value.charAt(0) !== "#") {
      setFormattedValue(`#${value}`);
    } else {
      setFormattedValue(value);
    }
  }, [value]);

  return (
    <div className={styles.root}>
      <TextInput value={formattedValue} maxlength="7" {...rest} />
      <span className={styles.swatch} style={{ background: formattedValue }} />
    </div>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".N1LJXpol4gMOQUE24v7k{display:flex;flex-direction:column;align-items:flex-start;gap:24px}.bkjyT6Qe4rHB8eVSJg9w{font-size:16px;line-height:normal;font-weight:600;margin-bottom:8px}.JHksj69JZlC4me_H7HQW{color:var(--grey-800);margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/components/settingsSection/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,QAAA,CAGF,sBACE,cAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBACE,qBAAA,CACA,eAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 24px;\n}\n\n.heading {\n  font-size: 16px;\n  line-height: normal;\n  font-weight: 600;\n  margin-bottom: 8px;\n}\n\n.subheading {\n  color: var(--grey-800);\n  margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "N1LJXpol4gMOQUE24v7k",
	"heading": "bkjyT6Qe4rHB8eVSJg9w",
	"subheading": "JHksj69JZlC4me_H7HQW"
};
export default ___CSS_LOADER_EXPORT___;

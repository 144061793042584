import React from 'react';
import UILIB from '~/Common-Objects/Lib';
import Options from '~/assets/images/icons/options.svg';

export default class OptionsDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    render() {

        return <>
            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', cursor: 'pointer' }} onClick={() => this.setState({ open: true })}>
                <div className="table-icon"><img src={Options} width="16px" /></div>
            </div>
            <UILIB.DropDown onClose={() => this.setState({ open: false })} align="right" top="45px" width={this.props.popWidth} isOpen={this.state.open} >
                {this.props.children}
            </UILIB.DropDown>
        </>
    };
};
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'
import ViewLine from './viewFailedLine'

@connect((store) => {
    return { user: store.user }
})
export default class ProgressBreakdown extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            pageLoaded: false,
            pageSize: 15,
            currentPage: 0,
            customfields: [],
            subscribers: [],
            tableData: [],
            totalSubs: 0,
            loadingData: 1,
            groupId: undefined,
            importId: undefined,
            type: undefined,
            exporting: false,
            updating: false,
            updatingSub: undefined,
            updateError: undefined,
            viewing: undefined
        };

        this.closeDrawer = this.closeDrawer.bind(this);
        this.getCustomFields = this.getCustomFields.bind(this);
        this.getSubscribers = this.getSubscribers.bind(this);
        this.buildTable = this.buildTable.bind(this);
        this.changePage = this.changePage.bind(this);
        this.delete = this.delete.bind(this);
        this.update = this.update.bind(this);
        this.export = this.export.bind(this);
        this.loopExportCheck = this.loopExportCheck.bind(this);
        this.stopUpdating = this.stopUpdating.bind(this);
        this.saveSubscriber = this.saveSubscriber.bind(this);
        this.checkBoxes = this.checkBoxes.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.countChecked = this.countChecked.bind(this);
        this.goDeleteSubs = this.goDeleteSubs.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
    }

    componentDidMount() {
        this._ismounted = true;
        this.getCustomFields()
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.update !== this.props.update) {
            this.setState({
                subscribers: [],
                tableData: [],
                pageLoaded: false
            }, this.getCustomFields)
        }
    }

    getCustomFields() {
        axios.get('/customfield').then(response => {
            this.setState({
                customfields: response.data.CustomFields
            }, this.getSubscribers)
        })
    }

    getSubscribers() {
        this.setState({
            loadingData: 1
        })
        var params = '?limit=' + this.state.pageSize + '&skip=' + (this.state.currentPage * this.state.pageSize);

        switch (this.props.type) {
            case 'dupes':
                params += '&type=4'
                break;
            case 'invalid':
                params += '&type=0'
                break;
        }
        if (this.props.type !== undefined) params += '&type=' + this.props.type;

        axios.get('/group/' + this.props.groupId + '/import/' + this.props.importId + '/failedimport' + params)
            .then(response => {

                this.setState({
                    subscribers: response.data.FailedImports,
                    tableData: [],
                    totalSubs: response.data.count
                }, this.buildTable)


            })
            .catch(console.log)
    }

    changePage(page) {
        this.setState({
            currentPage: page
        }, this.getSubscribers)
    }

    delete(ids) {
        axios.delete('/group/' + this.props.groupId + '/import/' + this.props.importId + '/failedimport', {
            data: { ids }
        }).then(response => {
            this.getSubscribers()
        })
    }

    goDeleteSubs() {
        var ids = this.state.subsToDelete.map(s => s.subscriberData.id)
        axios.delete('/group/' + this.props.groupId + '/import/' + this.props.importId + '/failedimport', {
            data: { ids }
        }).then(response => {
            this.setState({
                deleting: false
            }, this.getCustomFields)
        })
    }

    update(sub) {
        this.setState({
            updating: true,
            updatingSub: sub
        })
    }

    stopUpdating() {
        this.setState({
            updating: false,
            updatingSub: null
        }, this.getCustomFields)
    }

    stopDeleting() {
        this.setState({
            deleting: false
        }, this.getCustomFields)
    }

    updateSub(field, event) {
        var sub = this.state.updatingSub;
        if (field == 'emailAddress') {
            sub.emailAddress = event.target.value
        } else if (typeof field === 'object') {
            sub.raw[field.id] = event.target.value
        }
        this.setState({
            updateSub: sub
        })
    }

    saveSubscriber() {
        var sub = this.state.updatingSub;
        var updateObj = {
            emailAddress: sub.emailAddress,
            customFields: sub.raw && typeof sub.raw === 'object' ? sub.raw : {}
        }

        this.setState({
            updateError: undefined
        })

        axios.put('/group/' + this.props.groupId + '/import/' + this.props.importId + '/failedimport/' + sub.id, updateObj)
            .then(response => {
                this.stopUpdating()
            })
            .catch(err => {
                var message = i18n.t('subscribers:add.import.progress.errorUpdating')
                if (err.data.error) {
                    if (typeof err.data.error == 'string') {
                        message = err.data.error
                    } else if (Array.isArray(err.data.error)) {
                        message = err.data.error.map((e, i) => {
                            return <p className="warning" key={"err-" + i}>{e}</p>
                        })
                    }
                }
                this.setState({
                    updateError: <p className="warning">{message}</p>
                })
            })
    }

    export() {
        this.setState({
            exporting: true
        })
        axios.get('/group/' + this.props.groupId + '/import/' + this.props.importId + '/failedimport/export')
            .then(response => {
                this.loopExportCheck(response.data.ExportId)
            })
            .catch(() => {
                this.setState({
                    exporting: false
                })
            })
    }

    loopExportCheck(id) {
        var self = this;
        axios.get('/group/' + self.props.groupId + '/import/' + self.props.importId + '/failedimport/export/' + id)
            .then(response => {
                if (response.data.ExportJob && response.data.ExportJob.status) {
                    if (response.data.ExportJob.status = "Finished" && response.data.ExportJob.filePath) {
                        window.open(response.data.ExportJob.filePath, '_self');
                        self.setState({
                            exporting: false
                        })
                    }
                    else {
                        if (self._ismounted) {
                            setTimeout(() => { self.loopExportCheck(id) }, 2000)
                        }
                    }
                }
            })
            .catch(err => {
                self.setState({
                    exporting: false
                })
            })
    }

    checkBoxes(event) {
        var thisDataID = event
            .target
            .name
            .replace('sub', '');
        var isChecked = event.target.checked;
        var newState = this.state.tableData;
        var checkBox = React.cloneElement(newState[thisDataID].checkBox.value, { checked: isChecked });
        newState[thisDataID].checkBox.isChecked = isChecked;
        newState[thisDataID].checkBox.value = checkBox;
        this.setState({
            tableData: newState
        }, this.countChecked);
    }

    checkAll(event, forcedUntick) {
        var isChecked;

        if (event) {
            isChecked = event.target.checked;
        }
        if (forcedUntick && forcedUntick === true) {
            isChecked = false;
        }
        //create a copy of the array with object.extend
        var newState = Object.assign([], this.state.tableData);
        for (var x in Object.keys(newState)) {
            var thisProp = newState[x];
            var checkBox = React.cloneElement(thisProp.checkBox.value, { checked: isChecked });
            var headerValue = React.cloneElement(thisProp.checkBox.headerValue, { checked: isChecked });
            thisProp.checkBox.isChecked = isChecked;
            thisProp.checkBox.value = checkBox;
            thisProp.checkBox.headerValue = headerValue;
            newState[x] = thisProp;
        }
        //write it back to state
        this.setState({
            tableData: newState
        }, this.countChecked);
    }

    countChecked() {
        var tickedSubs = this.state.tableData.filter(sub => sub.checkBox.isChecked == true);
        var totTicked = tickedSubs.length;

        if (totTicked > 0) {
            // render the drawer to delete
            this.setState({
                totalChecked: totTicked,
                subsToDelete: tickedSubs,
                deleting: true
            })
        } else {
            // unrender the drawer to delete
            this.setState({
                totalChecked: 0,
                subsToDelete: [],
                deleting: false
            })
        }
    }

    buildTable() {
        var tableData = this.state.subscribers.map((thisLine, index) => {
            var thisNameAndLink = <a onClick={(e) => this.goViewSegment(e, thisLine.id)}>{thisLine.name}</a>;
            var thisOpts = <UILIB.OptionsDropdown popWidth="150px">
                <ul>
                    {!thisLine.ApplicationDataId && <li>
                        <a onClick={() => this.update(thisLine)}>{i18n.t('update')}</a>
                    </li>}
                    {!!thisLine.ApplicationDataId && <li>
                        <a onClick={() => this.setState({ viewing: thisLine })}>{i18n.t('view')}</a>
                    </li>}
                    <li>
                        <a onClick={() => this.delete([thisLine.id])}>{i18n.t('delete')}</a>
                    </li>
                </ul>
            </UILIB.OptionsDropdown>

            let nameCf
            if (this.props.application && this.props.application.ApplicationId === 8) {
                nameCf = this.state.customfields.find(cf => cf.fieldName === '_capsule_name')
            }

            const obj = {
                checkBox: {
                    headerValue: <UILIB.CheckBox
                        checked={false}
                        onClick={(event) => {
                            this.checkAll(event)
                        }} />,
                    orderBy: false,
                    value: <UILIB.CheckBox
                        checked={false}
                        onClick={(event) => {
                            this.checkBoxes(event)
                        }}
                        name={"sub" + index} />,
                    isCheckBox: true,
                    width: 30,
                    isChecked: false

                },
                emailAddress: {
                    headerValue: i18n.t('emailAddress'),
                    value: thisLine.emailAddress || '--NONE--',
                    orderBy: false
                },
                name: {
                    headerValue: nameCf ? 'Name' : '',
                    value: nameCf ? thisLine.raw[nameCf.id] : ''
                },
                reason: {
                    headerValue: i18n.t('subscribers:add.import.progress.reason'),
                    value: thisLine.reason,
                    orderBy: false
                },
                optionsCol: {
                    headerValue: " ",
                    value: thisOpts,
                    orderBy: false,
                    fixed: true,
                    fixedSml: true,
                    width: 20
                },
                application: {
                    headerValue: this.props.application && this.props.application.ApplicationId === 8 ? 'Integration' : '',
                    value: <div>
                        <UILIB.Button text="View & Fix" className="button-sml mar-r10" onClick={() => this.setState({ viewing: thisLine })} />
                    </div>
                },
                subscriberData: {
                    id: thisLine.id
                }
            }

            return obj
        })
        this.setState({
            tableData: tableData,
            pageLoaded: true,
            loadingData: 0
        })
    }

    changePageSize(size) {
        this.setState({
            pageSize: size
        }, this.getSubscribers)
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }


    render() {
        var text = i18n.t('subscribers:index.subscribers');
        switch (this.props.type) {
            case 'dupes':
                text = i18n.t('subscribers:add.import.progress.duplicates')
                break;
            case 'invalid':
                text = i18n.t('subscribers:add.import.progress.invalid')
                break;
        }
        return <div style={{ marginTop: "40px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className="mar-b25">
                <h4>{text}</h4>

                <div className="hide-xs" style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                    <div>{i18n.t('showing')}</div>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.pageSize === 15} onClick={() => { this.changePageSize(15) }}>15</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 500} onClick={() => { this.changePageSize(500) }}>500</UILIB.ButtonSimple>
                    <UILIB.Button className="mar-l10" iconRight={<UILIB.Icons icon="arrowDown" />} disabled={this.state.exporting} onClick={this.export} style={{ marginBottom: 10 }}>{i18n.t('subscribers:nav.export')}</UILIB.Button>
                </div>
            </div>

            <UILIB.Paper>
                <UILIB.DataTable1
                    noResultsTxt={<span> No {text} </span>}
                    tableData={this.state.tableData}
                    dataUpdater={this.changePage}
                    loadingData={this.state.loadingData}
                    width="100%"
                    pageSize={this.state.pageSize}
                    hasCheckBoxes="multi" />
            </UILIB.Paper>

            <div
                style={{
                    textAlign: "center",
                    marginTop: 10
                }}>
                <UILIB.PagingBlock
                    totalRows={this.state.totalSubs}
                    pageSize={this.state.pageSize}
                    numberOfLinks="10"
                    currentPage={this.state.currentPage}
                    changePage={this.changePage}
                    text="Page:" />
            </div>

            {this.state.updating && <UILIB.Drawer close={this.stopUpdating} isOpen={true} standalone={true} width={300} showClose={true}>
                <div>
                    <div className="title">
                        {i18n.t('subscribers:add.import.progress.subDetails')}
                    </div>
                    <div className={"form-group"} >
                        <label htmlFor="">{i18n.t('emailAddress')}</label>
                        <UILIB.TextInput type="text" placeholder={i18n.t('emailAddressPlaceholder')} name="emailAddress" value={this.state.updatingSub.emailAddress} onChange={(evt) => this.updateSub('emailAddress', evt)} focus={true} />
                    </div>

                    {this.state.customfields.map((field, index) => {
                        var thisVal = "";
                        if (this.state.updatingSub.raw && this.state.updatingSub.raw[field.id] !== undefined) {
                            thisVal = this.state.updatingSub.raw[field.id];
                        }
                        if (field.fieldType.toLowerCase() === "date") {
                            return <div className="form-group" key={index}>
                                <label htmlFor="">{field.fieldDesc}</label>
                                <UILIB.TextInput type={field.fieldType} placeholder={field.fieldDesc} name={field.fieldName} value={thisVal} onChange={() => this.updateSub(field, evt)} field={field} />
                            </div>
                        }
                        return <div className="form-group" key={index}>
                            <label htmlFor="">{field.fieldDesc}</label>
                            <UILIB.TextInput type={field.fieldType} placeholder={field.fieldDesc} name={field.fieldName} value={thisVal} onChange={evt => { this.updateSub(field, evt) }} />
                        </div>
                    })}

                    <UILIB.Button className="button-md outline primary" onClick={this.saveSubscriber}>{i18n.t('save')}</UILIB.Button>
                    {this.state.updateError}

                </div>
            </UILIB.Drawer>}
            {this.state.deleting && <UILIB.Drawer close={this.stopDeleting} isOpen={true} standalone={true} width={300} showClose={true}>
                <UILIB.Row>
                    <UILIB.Column xs={12} sm={12} md={12}>
                        <div>
                            <h4 className='mar-b25'>{i18n.t('subscribers:add.import.progress.deleteSubs')}</h4>
                            <p>{i18n.t('subscribers:add.import.progress.deleteLabel', { count: this.state.totalChecked })}</p>
                        </div>
                    </UILIB.Column>
                </UILIB.Row>

                <UILIB.Row>
                    <UILIB.Column xs={12} sm={12} md={12}>
                        <div className="mar-b20">
                            <p className="no-marg">
                                <UILIB.Button text={i18n.t('subscribers:add.import.progress.deleteConfirm')} className="outline primary" onClick={this.goDeleteSubs} />
                            </p>
                        </div>
                    </UILIB.Column>
                </UILIB.Row>
            </UILIB.Drawer>}

            {!!this.state.viewing && <UILIB.Drawer close={() => this.setState({ viewing: null })} isOpen={true} standalone={true} width={400} showClose={true}>
                <ViewLine failed={this.state.viewing} application={this.props.application} />
            </UILIB.Drawer>}
        </div>
    }
};


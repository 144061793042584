import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import i18n from '~/i18n'

class CopyGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            copySubs: false,
            groupName: this.props.group.groupName + ' - copy'
        }

        this.updateValue = this.updateValue.bind(this)
        this.updateCheckbox = this.updateCheckbox.bind(this)
        this.copyGroup = this.copyGroup.bind(this)
    }

    updateValue(ev) {
        this.setState({
            [ev.target.name]: ev.target.value
        })
    }

    updateCheckbox(ev) {
        this.setState({
            [ev.currentTarget.name]: ev.currentTarget.checked
        })
    }

    copyGroup() {
        axios.put('/group/' + this.props.group.id + '/copy', this.state).then(this.props.finish)
    }

    render() {
        return (
            <div>
                <h4 className="mar-b25">{i18n.t('subscribers:copyGroup.copy')} {this.props.group.groupName}</h4>

                <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('subscribers:copyGroup.newGroupName')} value={this.state.groupName} onChange={this.updateValue} name="groupName" />

                <UILIB.CheckBox outerClassName="mar-b25" checked={this.state.copySubs} onClick={this.updateCheckbox} name="copySubs">{i18n.t('subscribers:copyGroup.copySubscribersToNewGroup')}</UILIB.CheckBox>

                <UILIB.Button text={i18n.t('subscribers:copyGroup.copyGroup')} onClick={this.copyGroup} iconLeft={<UILIB.Icons icon="tick" />} />

            </div>
        );
    }
}

export default CopyGroup;
import React, { useState } from "react";
import AlertDialog from "../../../../components/alertDialog";
import i18n from "~/i18n";

export default function DeleteArticleDialog({
  articleToDelete,
  isOpen,
  onSubmit,
  ...rest
}) {
  const [submitting, setSubmitting] = useState(false);

  async function handleSubmit() {
    setSubmitting(true);

    try {
      await onSubmit(articleToDelete);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <AlertDialog
      open={isOpen}
      title={i18n.t("chat:ai.articles.delete.header")}
      description={i18n.t("chat:ai.articles.delete.desc", { title: articleToDelete?.pageTitle })}
      ctaText={i18n.t("chat:ai.articles.delete.confirm")}
      submitting={submitting}
      onCtaClick={handleSubmit}
      {...rest}
    />
  );
}

import React from 'react'

export default function IconEnvelopeOpen({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.94 6.41204C2.65235 6.59182 2.41515 6.84181 2.25071 7.1385C2.08627 7.43519 2 7.76883 2 8.10804V16C2 16.5305 2.21071 17.0392 2.58579 17.4143C2.96086 17.7893 3.46957 18 4 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4143C17.7893 17.0392 18 16.5305 18 16V8.10804C18 7.76883 17.9137 7.43519 17.7493 7.1385C17.5848 6.84181 17.3476 6.59182 17.06 6.41204L11.06 2.66204C10.7421 2.46337 10.3748 2.35803 10 2.35803C9.62516 2.35803 9.25786 2.46337 8.94 2.66204L2.94 6.41204ZM5.555 8.83504C5.44574 8.76215 5.32319 8.7115 5.19436 8.68598C5.06553 8.66046 4.93293 8.66056 4.80413 8.68628C4.67534 8.712 4.55287 8.76284 4.44372 8.83589C4.33457 8.90894 4.24088 9.00278 4.168 9.11204C4.09512 9.2213 4.04447 9.34384 4.01894 9.47268C3.99342 9.60151 3.99352 9.73411 4.01924 9.86291C4.07119 10.123 4.22434 10.3518 4.445 10.499L9.445 13.832C9.60933 13.9417 9.80245 14.0002 10 14.0002C10.1975 14.0002 10.3907 13.9417 10.555 13.832L15.555 10.499C15.7757 10.3518 15.9288 10.123 15.9808 9.86291C16.0327 9.60279 15.9792 9.3327 15.832 9.11204C15.6848 8.89138 15.456 8.73823 15.1959 8.68628C14.9358 8.63433 14.6657 8.68784 14.445 8.83504L10 11.798L5.555 8.83504Z" fill={fillColor} />
        </svg>
    )
}
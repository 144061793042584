import React from 'react'
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';

import ConversationsPromptsNewConv from './conversationsPromptsNewConv';
import ConversationsPromptsSuggestion from './conversationsPromptsSuggestion';

@connect((store) => {
    return {
        siteMaster: store.siteMaster
    }
})


export default class Icons extends React.Component {
    render() {
        let icon = undefined;
        let color = this.props.siteMaster.colours["$primary"];

        if (this.props.color) color = this.props.color;

        let style = {};
        if (this.props.style) style = JSON.parse(JSON.stringify(this.props.style));
        if (this.props.onClick) style.cursor = "pointer";

        let className = "";
        if (this.props.className) className += " " + this.props.className;

        const outerStyle = { ...style, ...this.props.outerStyle }
        if (this.props.circle) {
            outerStyle.borderRadius = '50%';
            outerStyle.backgroundColor = color && color.indexOf('#') === 0 ? `${hexToRgbA(color, 0.2)}` : 'transparent'
            outerStyle.padding = 5
            outerStyle.display = 'flex';
            outerStyle.justifyContent = 'center';
            outerStyle.alignItems = 'center';
        }
        if (this.props.square) {
            outerStyle.borderRadius = '4px';
            outerStyle.backgroundColor = color && color.indexOf('#') === 0 ? `${hexToRgbA(color, 0.2)}` : 'transparent'
            outerStyle.padding = 5
            outerStyle.display = 'flex';
            outerStyle.justifyContent = 'center';
            outerStyle.alignItems = 'center';
        }

        switch (this.props.icon) {
            case "conversationsPromptsNewConv":
                icon = <ConversationsPromptsNewConv color={color} style={style} />;
                break;
            case "conversationsPromptsSuggestion":
                icon = <ConversationsPromptsSuggestion color={color} style={style} />;
                break;

            default:
                icon = ""
                break;
        }

        if (!icon) return null
        if (this.props.svgOnly) {
            if (this.props["data-tip"] && this.props["data-for"]) {
                icon["data-tip"] = this.props["data-tip"]
                icon["data-for"] = this.props["data-for"]
            }
            return icon
        }

        return <div onClick={this.props.onClick} className={className} style={outerStyle} alt={this.props.alt} title={this.props.title} data-tip={this.props["data-tip"]} data-for={this.props["data-for"]}>
            {icon}
            {this.props["data-tip"] && this.props["data-for"] && <ReactTooltip id={this.props["data-for"]} effect="solid" />}
        </div>


    }
}

function hexToRgbA(hex, opacity = 1) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
    }
    throw new Error('Bad Hex');
}
import React from 'react'

export default function IconSelect({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.5 7.00006C17.5 6.86745 17.4473 6.74028 17.3536 6.64651C17.2598 6.55274 17.1326 6.50006 17 6.50006L3 6.50006C2.86739 6.50006 2.74022 6.55274 2.64645 6.64651C2.55268 6.74028 2.5 6.86745 2.5 7.00006L2.5 13.0001C2.5 13.1327 2.55268 13.2598 2.64645 13.3536C2.74021 13.4474 2.86739 13.5001 3 13.5001L17 13.5001C17.1326 13.5001 17.2598 13.4474 17.3536 13.3536C17.4473 13.2598 17.5 13.1327 17.5 13.0001L17.5 7.00006ZM18.4142 5.58585C18.7893 5.96092 19 6.46963 19 7.00006L19 13.0001C19 13.5305 18.7893 14.0392 18.4142 14.4143C18.0391 14.7893 17.5304 15.0001 17 15.0001L3 15.0001C2.46957 15.0001 1.96086 14.7893 1.58579 14.4143C1.21071 14.0392 1 13.5305 1 13.0001L1 7.00006C1 6.46963 1.21071 5.96092 1.58579 5.58585C1.96086 5.21077 2.46957 5.00006 3 5.00006L17 5.00006C17.5304 5.00006 18.0391 5.21077 18.4142 5.58585Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.1462 9.15234C11.2399 9.05879 11.3669 9.00624 11.4993 9.00624C11.6317 9.00624 11.7587 9.05879 11.8524 9.15234L13.4969 10.7955L15.1414 9.15234C15.1875 9.10468 15.2426 9.06666 15.3035 9.04051C15.3644 9.01436 15.43 9.00059 15.4963 9.00002C15.5626 8.99944 15.6283 9.01207 15.6897 9.03716C15.7511 9.06225 15.8068 9.0993 15.8537 9.14615C15.9006 9.193 15.9377 9.24872 15.9628 9.31004C15.9879 9.37137 16.0006 9.43708 16 9.50333C15.9994 9.56959 15.9856 9.63507 15.9595 9.69595C15.9333 9.75683 15.8952 9.81189 15.8475 9.85792L13.85 11.8539C13.7563 11.9474 13.6293 12 13.4969 12C13.3645 12 13.2375 11.9474 13.1438 11.8539L11.1462 9.85792C11.0526 9.76434 11 9.63744 11 9.50513C11 9.37281 11.0526 9.24591 11.1462 9.15234Z" fill={fillColor} />
        </svg>
    )
}
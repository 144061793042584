import React from 'react';
import { Link } from 'react-router-dom';
import axios from '~/data/http/axios';
import UILIB from '../../../Common-Objects/Lib';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import queryString from 'query-string';
import axios2 from 'axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions';
import PermissionChecker from '~/Classes/permissions';
import { getUserSettings, setUserSettings } from '~/Classes/userSettings';
import AddSubs from './addSubs'
import IncGoTickSubs from './incGoTickSubs'
import Bulk from './bulk'
import i18n from '~/i18n'
import ReactTooltip from "react-tooltip";

const SELECTED_FIELDS_LIMIT = 15;

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, browser: store.browser, permissionStore: store.permission, account: store.accountMaster }
})
export default class SubscribersComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allTotal: 0,
            total: 0,
            totalViewed: 0,
            subscribers: [],
            tableData: [],
            segments: [],
            segmentName: i18n.t('subscribers:overview.all'),
            segmentId: undefined,
            customFields: [],
            page: 0,
            retry: 0,
            mobilePageSize: 15,
            pageSize: 15,
            orderBy: 'emailAddress',
            orderDir: 'ASC',
            searchText: '',
            loadingData: 1,
            writeAccess: true,
            isLoading: true,
            isLoadingSegments: true,
            fieldsFilter: '',
            selectedTags: [],
            selectedLinkTags: [],
            systemFields: [
                { fieldName: 'score', fieldDesc: i18n.t('score') },
                { fieldName: 'status', fieldDesc: i18n.t('status') },
                { fieldName: 'createdAt', fieldDesc: i18n.t('created') },
                { fieldName: 'updatedAt', fieldDesc: i18n.t('lastUpdated') },
                { fieldName: 'lastSendDate', fieldDesc: i18n.t('lastSent') },
                { fieldName: 'lastOpened', fieldDesc: i18n.t('lastOpened') },
                { fieldName: 'lastClicked', fieldDesc: i18n.t('lastClicked') },
                { fieldName: 'lastBounced', fieldDesc: i18n.t('lastBounced') },
                { fieldName: 'tags', fieldDesc: i18n.t('tags') },
            ],
            selectedSystemFields: [],
            tags: [],
            tagsFilter: '',
            linkTags: [],
            linkTagsFilter: '',
            settings: null
        };

        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();
        this.retryTimer = null;
        this.firstLoad = true

        this.getAccountMaster = this.getAccountMaster.bind(this);
        this.getCustomFields = this.getCustomFields.bind(this);
        this.getSegments = this.getSegments.bind(this);
        this.getSubscribers = this.getSubscribers.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSegment = this.changeSegment.bind(this);
        this.addSubs = this.addSubs.bind(this);
        this.changeSearchText = this.changeSearchText.bind(this);
        this.searchKeyPress = this.searchKeyPress.bind(this);
        this.changePage = this.changePage.bind(this);
        this.selectFields = this.selectFields.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.countChecked = this.countChecked.bind(this);
        this.checkBoxes = this.checkBoxes.bind(this);
        this.reloadAfterTickedAndChanged = this.reloadAfterTickedAndChanged.bind(this);
        this.loadmore = this.loadmore.bind(this);
        this.filterFields = this.filterFields.bind(this)
        this.toggleAllFields = this.toggleAllFields.bind(this)
        this.getTags = this.getTags.bind(this);
        this.selectTag = this.selectTag.bind(this);
        this.getLinkTags = this.getLinkTags.bind(this)
        this.selectLinkTag = this.selectLinkTag.bind(this)
    }

    componentDidMount() {
        //see if we have write access to the subscribers
        var writeAccess = PermissionChecker("subscribers", this.props.permissionStore.permissions, "write");

        getUserSettings().then(s => {
            if (!s.subscribers) s.subscribers = {}
            let save = false
            if (!s.subscribers.limit && this.props.account.tableLimits?.subscribers) {
                save = true
                s.subscribers.limit = this.props.account.tableLimits.subscribers
            }
            if (!s.subscribers.systemFields && this.props.account.systemFields?.[this.props.account.id]) {
                save = true
                s.subscribers.systemFields = this.props.account.systemFields[this.props.account.id].map(f => f.fieldName)
            }
            if (!s.subscribers.fields && this.props.account.fields?.[this.props.account.id]) {
                save = true
                s.subscribers.fields = this.props.account.fields[this.props.account.id]
            }
            if (save) setUserSettings(s).catch(console.log)
            const obj = {
                settings: s,
                selectedSystemFields: s.subscribers.systemFields ?
                    this.state.systemFields.filter(f => s.subscribers.systemFields.some(s => s === f.fieldName)) :
                    [...this.state.systemFields],
                pageSize: s.subscribers.limit || 15,
                writeAccess,
            }

            const query = queryString.parse(location.search);
            if (query.orderBy) obj.orderBy = query.orderBy
            if (query.orderDir) obj.orderDir = query.orderDir
            if (query.searchText) obj.searchText = query.searchText
            if (query.segmentId) obj.segmentId = query.segmentId
            if (parseInt(query.limit)) obj.pageSize = parseInt(query.limit)
            if (parseInt(query.pageSize)) obj.pageSize = parseInt(query.pageSize)
            if (parseInt(query.offset)) obj.page = parseInt(query.offset) / obj.pageSize

            if (query.tags) {
                if (Array.isArray(query.tags)) {
                    obj.selectedTags = query.tags
                } else {
                    obj.selectedTags = [query.tags]
                }
            }

            if (query.linkTags) {
                if (Array.isArray(query.linkTags)) {
                    obj.selectedLinkTags = query.linkTags
                } else {
                    obj.selectedLinkTags = [query.linkTags]
                }
            }

            this.setState(obj, this.getAccountMaster)
        })

    }

    componentWillUnmount() {
        if (this.retryTimer) clearTimeout(this.retryTimer)
    }

    getAccountMaster() {
        axios.get('/accountMaster').then(response => {
            this.setState({
                total: 0,
                totalViewed: 0
            }, this.getCustomFields)
        })
    }

    getCustomFields() {
        axios.get('/customfield')
            .then(response => {
                var customFields = response.data.CustomFields.filter(cf => cf.entityType === 1 || cf.entityType === 3 || !cf.entityType);
                customFields.forEach(f => {
                    var found = false
                    if (this.state.settings.subscribers.fields) {
                        found = this.state.settings.subscribers.fields.some(r => r == f.id)
                    }
                    f.selected = found
                });
                this.setState({
                    customFields
                }, () => {
                    this.getTags();
                    this.getSegments();
                })
            })
            .catch(() => {
                this.getSegments();
                this.getSubscribers()
            })
    }

    getSegments() {
        axios.get('/segment')
            .then(response => {

                var segments = response.data.Segments;

                segments = segments.map(seg => {
                    seg.label = seg.name;
                    if (seg.readOnly || seg.readOnly == "1") {
                        if (seg.label == "Active") seg.label = i18n.t('Active');
                        if (seg.label == "In-Active") seg.label = i18n.t('In-Active')
                        if (seg.label == "Unsubscribed") seg.label = i18n.t('Unsubscribed')
                        if (seg.label == "Bounced") seg.label = i18n.t('Bounced')
                        if (seg.label == "Blacklisted") {
                            seg.label = i18n.t('Blacklisted')
                        }

                    }
                    return seg;
                })

                let segmentName = this.state.segmentName
                if (this.state.segmentId) {
                    let found = segments.find(s => s.id == this.state.segmentId)
                    if (found) segmentName = found.label
                }

                this.setState({
                    segmentName,
                    segments: segments,
                    isLoadingSegments: false
                })
            })
    }
    async getTags() {
        let tags = await axios.get(`/tags?tagType=0`)
        tags = tags.data.map(t => {
            return {
                label: t.tagName,
                value: t.id,
                Application: t.Application,
                ApplicationId: t.ApplicationId,
                tagType: t.tagType
            }
        })
        let adTags = await axios.get(`/tags?tagType=3`)
        tags = tags.concat(adTags.data.map(t => {
            return {
                label: t.tagName,
                value: t.id,
                Application: t.Application,
                ApplicationId: t.ApplicationId,
                tagType: t.tagType
            }
        }))

        tags = tags.sort((a, b) => {
            if (a.ApplicationId > b.ApplicationId) return 1;
            if (a.ApplicationId < b.ApplicationId) return -1;
            return 0;
        })

        tags.forEach(tag => {
            const selected = this.firstLoad ? this.state.selectedTags.some(s => parseInt(s) === tag.value) : false
            tag.selected = selected;
        })
        this.setState({
            tags: tags,
        }, this.getLinkTags)
    }

    getLinkTags() {
        axios.get(`/tags?tagType=1`).then(data => {
            this.setState({
                linkTags: data.data.map(t => {
                    const selected = this.firstLoad ? this.state.selectedLinkTags.some(s => parseInt(s) === t.id) : false
                    return {
                        label: t.tagName,
                        value: t.id,
                        selected,
                    }
                })
            }, this.getSubscribers)
            this.firstLoad = false
        })
    }

    selectTag(event) {
        let self = this;
        let tags = this.state.tags;
        let found = tags.find(f => f.value == event.target.name);
        found.selected = event.target.checked;
        this.setState({ tags }, () => {
            if (self.timer) clearTimeout(self.timer)
            self.timer = setTimeout(() => {
                self.changePage(0, null, null, null, null);
            }, 1000)

        })
    }

    selectLinkTag(event) {
        let self = this;
        let linkTags = this.state.linkTags;
        let found = linkTags.find(f => f.value == event.target.name);
        found.selected = event.target.checked;
        this.setState({ linkTags }, () => {
            if (self.timer) clearTimeout(self.timer)
            self.timer = setTimeout(() => {
                self.changePage(0, null, null, null, null);
            }, 1000)

        })
    }

    getSubscribers() {
        if (this.state.isLoading) {
            this.source.cancel('I cancelled this')
            this.source = this.CancelToken.source();
        }
        var queryStr = `?limit=${this.state.pageSize}&offset=${this.state.page * this.state.pageSize}&orderby=${this.state.orderBy}&orderdir=${this.state.orderDir}&search=${encodeURIComponent(this.state.searchText)}`

        var promise;

        if (history.pushState) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + queryStr + (this.state.segmentId ? '&segment=' + this.state.segmentId : '');
            const tags = this.state.tags ? this.state.tags.filter(t => t.selected) : []
            if (tags.length) {
                newurl += `&tags=` + tags.map(t => t.value).join('&tags=')
            }
            const linkTags = this.state.linkTags ? this.state.linkTags.filter(t => t.selected) : []
            if (linkTags.length) {
                newurl += `&linkTags=` + linkTags.map(t => t.value).join('&linkTags=')
            }
            window.history.pushState({ path: newurl }, '', newurl);
        }


        let selectedTags = [];
        if (this.state.tags) {
            selectedTags = this.state.tags.filter(t => t.selected)
        }
        if (selectedTags.length) {
            queryStr += `&tags=${selectedTags.map(tag => { return tag.value }).join(",")}`
        }
        if (this.state.linkTags) {
            let selectedLinkTags = this.state.linkTags.filter(t => t.selected)
            if (selectedLinkTags.length) {
                queryStr += `&linkTags=${selectedLinkTags.map(tag => { return tag.value }).join(",")}`
            }
        }

        queryStr += `&fields=${JSON.stringify(
            this.state.customFields
                .filter((f) => f.selected)
                .slice(
                    0,
                    SELECTED_FIELDS_LIMIT - this.state.selectedSystemFields.length
                )
                .map((f) => f.id)
        )}&count=true`;

        if (this.state.segmentId) {
            promise = axios.get('/segment/' + this.state.segmentId + '/results' + queryStr, {
                cancelToken: this.source.token
            })
        } else {
            promise = axios.get('/subscriber' + queryStr, {
                cancelToken: this.source.token
            })
        }

        this.setState({
            isLoading: true
        })

        promise.then(response => {
            const totalTags = response.data.Subscribers.reduce((a, b) => a + (b.Tags?.length || 0), 0)

            var arr = response.data.Subscribers.map((row, index) => {

                let selectedTags = [];
                if (row.Tags && row.Tags.length) {
                    selectedTags = row.Tags.map(tag => {
                        if (tag.id) return tag
                        let foundTag = this.state.tags.find(t => t.value == tag)
                        if (foundTag) {
                            return { tagName: foundTag.label }
                        }
                        else {
                            return ''
                        }
                    });
                }

                var finalStatus = i18n.t('Active');;
                var finalStatusCol = "square-chip-green";
                if (row.groups) {
                    var unsubbed = row.groups.filter(g => g.unsubscribe == true);
                    var active = row.groups.filter(g => g.active == false);
                    if (active.length) {
                        finalStatusCol = "square-chip-orange";
                        if (active.length == row.groups.length) finalStatus = i18n.t('In-Active')
                        if (active.length < row.groups.length) finalStatus = i18n.t('InActiveInSome')
                    }
                    if (unsubbed.length) {
                        finalStatusCol = "square-chip-red";
                        if (unsubbed.length == row.groups.length) finalStatus = i18n.t('Unsubscribed')
                        if (unsubbed.length < row.groups.length) finalStatus = i18n.t('UnsubscribedFromSome')
                    }
                }
                if (row.bounced) {
                    finalStatus = i18n.t('Bounced');
                    finalStatusCol = "square-chip-purple";
                }
                if (row.blackListed) {
                    finalStatus = i18n.t('Blacklisted');
                    finalStatusCol = "square-chip-black";
                }

                const important = row.customFields.filter(f => f.important).sort((a, b) => {
                    return a.fieldDesc.localeCompare(b.fieldDesc)
                })


                var result = {
                    subscriberData: { id: row.id, order: -5 },
                    rowDeleted: { value: false, order: -4 },
                    checkBox: {
                        headerValue: this.state.writeAccess ? <UILIB.CheckBox
                            outerClassName="no-marg"
                            checked={false}
                            onChange={(event) => {
                                this.checkAll(event)
                            }} /> : '',
                        orderBy: false,
                        value: this.state.writeAccess ? <UILIB.CheckBox
                            outerClassName="no-marg"
                            checked={false}
                            onChange={(event) => {
                                this.checkBoxes(event)
                            }}
                            name={"sub" + index} /> : '',
                        isCheckBox: true,
                        width: 30,
                        isChecked: false,
                        order: -3
                    },
                    gravatar: {
                        headerValue: "",
                        value: <UILIB.Avatar style={{ top: 3, height: 'auto', width: '100%', maxWidth: "40px", maxHeight: "40px", marginRight: "10px" }} src={row.accountImage} />,
                        width: 50,
                        order: -2
                    },
                    emailAddress: {
                        headerValue: i18n.t('emailAddress'),
                        value: <div>
                            <Link style={{ textDecoration: 'none' }} to={'/cp/subscriber/' + row.id}>{row.emailAddress}</Link>
                            {row.Organisation ? <div>
                                <Link className='text-sml text-grey' style={{ textDecoration: 'none' }} to={'/cp/subscribers/organisations/' + row.Organisation.id}>{row.Organisation.name}</Link>
                            </div> : ''}
                        </div>,
                        orderBy: true,
                        order: -1
                    },
                    score: {
                        headerValue: i18n.t('score'),
                        value: <UILIB.Score score={row.score} />,
                        orderBy: true,
                        order: 1 + important.length
                    },
                    status: {
                        headerValue: i18n.t('status'),
                        value: <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circle" />} className={finalStatusCol + " small-icon"}>{finalStatus}</UILIB.SquareChip>,
                        order: 2 + important.length,
                        align: "center"
                    },
                    createdAt: {
                        headerValue: i18n.t('created'),
                        value: DateTimeFunctions.formatDateTime(row.createdAt || row.addedToGroup, '2'),
                        orderBy: true,
                        width: 200,
                        order: 3 + important.length
                    },
                    updatedAt: {
                        headerValue: i18n.t('lastUpdated'),
                        value: DateTimeFunctions.formatDateTime(row.updatedAt || row.addedToGroup, '2'),
                        orderBy: true,
                        width: 200,
                        order: 4 + important.length
                    },
                    lastSendDate: {
                        headerValue: i18n.t('lastSent'),
                        value: row.lastSendDate ? DateTimeFunctions.formatDateTime(row.lastSendDate) : i18n.t('never'),
                        orderBy: true,
                        order: 5 + important.length
                    },
                    lastOpened: {
                        headerValue: i18n.t('lastOpened'),
                        value: row.lastOpened ? DateTimeFunctions.formatDateTime(row.lastOpened) : i18n.t('never'),
                        orderBy: true,
                        order: 6 + important.length
                    },
                    lastClicked: {
                        headerValue: i18n.t('lastClicked'),
                        value: row.lastClicked ? DateTimeFunctions.formatDateTime(row.lastClicked) : i18n.t('never'),
                        orderBy: true,
                        order: 7 + important.length
                    },
                    lastBounced: {
                        headerValue: i18n.t('lastBounced'),
                        value: row.lastBounced ? DateTimeFunctions.formatDateTime(row.lastBounced) : i18n.t('never'),
                        orderBy: true,
                        order: 8 + important.length
                    },
                    tags: {
                        headerValue: i18n.t('tags'),
                        value: selectedTags.map(tag => {
                            let icon = ""
                            let text = ""
                            let color = ""
                            if (tag.tagType === 0) {
                                icon = "person"
                                text = "Contact Tag"
                                color = "square-chip-primary"
                            }
                            if (tag.tagType === 1) {
                                icon = "link"
                                text = "Link Tag"
                                color = "square-chip-secondary"
                            }
                            if (totalTags > 30) {
                                return <UILIB.SquareChip iconLeft={<UILIB.Icons icon={icon} alt={text} title={text} color="white" style={{ height: 10, width: 10 }} />} key={"tag_" + tag.id} className={"square-chip-tag " + color} >{tag.tagName || tag}</UILIB.SquareChip>
                            } else {
                                return <UILIB.SquareChip iconLeft={<UILIB.Icons icon={icon} color="white" style={{ height: 10, width: 10 }} data-tip={text} data-for={row.id + '_tag_' + tag.id} />} key={"tag_" + tag.id} className={"square-chip-tag " + color} >{tag.tagName || tag}</UILIB.SquareChip>
                            }

                        }),
                        orderBy: false,
                        order: 9 + important.length
                    }
                }

                this.state.systemFields.forEach(field => {
                    if (!this.state.selectedSystemFields.some(f => f.fieldName === field.fieldName)) {
                        delete result[field.fieldName]
                    }
                })

                important.concat(row.customFields.filter(f => !f.important).sort((a, b) => {
                    return a.fieldDesc.localeCompare(b.fieldDesc)
                })).forEach((field, index) => {
                    if (!Array.isArray(field.value)) {
                        field.value = [field.value]
                    }
                    field.value.forEach((value, index) => {
                        if (field.fieldType == 'BOOLEAN') {
                            field.value[index] = value ? 'Yes' : 'No'
                        } else if (value !== undefined && value !== null) {
                            if (field.fieldType == 'DATE') {
                                field.value[index] = (value ? DateTimeFunctions.formatDateTime(value, "2") : '')
                            } else if (field.fieldType == 'NUMBER') {
                                field.value[index] = NumberFunctions.formatNumber(value)
                            } else if ((field.fieldType == 'SELECT' || field.fieldType == 'MULTISELECT') && field.options && field.options.length) {
                                var option = field.options.find(o => o.value == value)

                                field.value[index] = option ? option.label : value
                            } else {
                                field.value[index] = Array.isArray(value) ? value.join() : value
                            }
                        }
                    })
                    let headerValue = field.fieldDesc
                    if (field.Application) {
                        headerValue = <div className='vertical-center'>{headerValue} <UILIB.Avatar style={{ height: 20, width: 20, marginLeft: 5, backgroundColor: 'white', border: '1px solid grey' }} for={field.id + '-App'} tooltip={field.Application.appName} src={field.Application.appLogoUri} /></div>
                    }
                    result['' + field.id + ''] = {
                        headerValue,
                        value: field.value.join(', '),
                        orderBy: true,
                        width: 200,
                        order: field.important ? index : index + this.state.systemFields.length + 5
                    }
                })
                return result
            })


            if (response.data.retry) {
                if (this.retryTimer) clearTimeout(this.retryTimer)
                this.retryTimer = setTimeout(this.getSubscribers, 1000)
            }

            this.setState({
                subscribers: this.state.loadingmore ? this.state.subscribers.concat(response.data.Subscribers) : response.data.Subscribers,
                loadingmore: false,
                loadingData: 0,
                isLoading: response.data.retry,
                retry: response.data.retry ? this.state.retry + 1 : 0,
                totalViewed: response.data.count !== undefined ? response.data.count : this.state.totalViewed,
                tableData: this.state.loadingmore ? this.state.tableData.concat(arr) : arr,
                total: response.data.count,
                allTotal: !this.state.allTotal ? response.data.count : this.state.allTotal
            })

        })
    }

    changePageSize(size) {
        const settings = this.state.settings
        settings.subscribers.limit = size
        setUserSettings(settings).catch(console.log)
        this.setState({
            settings,
            page: 0,
            pageSize: size
        }, this.getSubscribers)
    }

    changeSegment(segment) {
        this.setState({
            segmentId: segment.id,
            segmentName: segment.name,
            totalViewed: segment.subscriberCount,
            page: 0
        }, this.getSubscribers)
    }

    addSubs() {
        var drawerContent = <AddSubs history={this.props.history} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    changeSearchText(evt) {
        this.setState({
            searchText: evt.target.value,
            page: 0
        }, () => {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(this.getSubscribers, 250)
        })
    }

    searchKeyPress() {
        this.setState({
            page: 0
        }, this.getSubscribers)
    }

    changePage(currentPage, sortDirection, sortColumn, pageSize, searchText) {
        if (!sortDirection) {
            sortDirection = this.state.orderDir;
        }
        if (!sortColumn) {
            sortColumn = this.state.orderBy;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!currentPage && currentPage != "0") {
            currentPage = this.state.page;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var startRecord = currentPage * pageSize;

        this.setState({ orderBy: sortColumn, orderDir: sortDirection, pageSize: pageSize, page: currentPage, searchText: searchText, startRecord: startRecord }, this.getSubscribers);

    }

    selectFields(field) {
        var self = this

        if (!field.id) {
            // its a system field
            let selectedSystemFields = this.state.selectedSystemFields
            const index = selectedSystemFields.findIndex(f => f.fieldName === field.fieldName)
            if (index > -1) {
                selectedSystemFields.splice(index, 1)
            } else {
                selectedSystemFields.push(field)
            }
            const settings = this.state.settings
            settings.subscribers.systemFields = selectedSystemFields.map(f => f.fieldName)
            setUserSettings(settings).catch(() => { })
            this.setState({ selectedSystemFields }, self.getSubscribers);
        } else {
            var newFields = this.state.customFields;
            newFields.forEach(x => {
                if (x.id == field.id) {
                    x.selected = !x.selected;
                }
            })

            const settings = this.state.settings
            settings.subscribers.fields = newFields.filter(f => f.selected).map(f => f.id)
            setUserSettings(settings).catch(console.log)
            this.setState({ customfields: newFields }, self.getSubscribers);
        }

    }

    checkAll(event, forcedUntick) {

        var isChecked;

        if (event) {
            isChecked = event.target.checked;
        }
        if (forcedUntick && forcedUntick === true) {
            isChecked = false;
        }
        //create a copy of the array with object.extend
        var newState = Object.assign([], this.state.tableData);
        for (var x in Object.keys(newState)) {
            var thisProp = newState[x];
            var checkBox = React.cloneElement(thisProp.checkBox.value, { checked: isChecked });
            var headerValue = React.cloneElement(thisProp.checkBox.headerValue, { checked: isChecked });
            thisProp.checkBox.isChecked = isChecked;
            thisProp.checkBox.value = checkBox;
            thisProp.checkBox.headerValue = headerValue;
            newState[x] = thisProp;
        }
        //write it back to state
        this.setState({
            tableData: newState
        }, this.countChecked);
    }

    countChecked() {
        var opts = this.state.drawerOptions;
        //count number of text boxes checked

        var tickedSubs = this.state.tableData.filter(sub => sub.checkBox.isChecked);

        var isOpen = false;
        var drawerContent = <div></div>;
        if (tickedSubs.length > 0) {
            isOpen = true;
            drawerContent = <IncGoTickSubs checkAll={this.checkAll} subscribers={this.state.tableData} totTicked={tickedSubs.length} reloadAfterTickedAndChanged={this.reloadAfterTickedAndChanged} />
        }
        this.props.dispatch(siteMaster.alterSiteDrawer(isOpen, false, "right", drawerContent, true));
    }
    checkBoxes(event) {
        var thisDataID = event.target.name.replace('sub', '');
        var isChecked = event.target.checked;
        var newState = this.state.tableData;
        var checkBox = React.cloneElement(newState[thisDataID].checkBox.value, { checked: isChecked });
        newState[thisDataID].checkBox.isChecked = isChecked;
        newState[thisDataID].checkBox.value = checkBox;

        this.setState({
            tableData: newState
        }, this.countChecked);
    }


    reloadAfterTickedAndChanged() {
        this.checkAll(null, true);
        this.getSegments();
        this.getSubscribers();
    }

    loadmore() {
        this.setState({
            pageSize: this.state.mobilePageSize + this.state.tableData.length,
            currentPage: 0,
            loadingmore: true
        }, this.getSubscribers)
    }

    loadmore() {
        this.setState({
            currentPage: this.state.currentPage + 1,
            startRecord: this.state.tableData.length,
            loadingmore: true
        }, this.getSubscribers)
    }

    filterFields(text) {
        this.setState({
            fieldsFilter: text.toLowerCase()
        })
    }

    toggleAllFields(select) {
        var self = this
        var newFields = this.state.customFields;

        newFields.forEach(x => {
            x.selected = select;
        })

        const selectedSystemFields = select ? [...this.state.systemFields] : []
        const settings = this.state.settings
        settings.subscribers.fields = newFields.map(f => f.if)
        settings.subscribers.systemFields = this.state.systemFields.map(f => f.fieldName)
        setUserSettings(settings).catch(console.log)
        this.setState({ customfields: newFields, selectedSystemFields, settings }, self.changePage);
    }

    bulkEdit() {
        const drawer = <Bulk onFinish={() => {
            this.getSubscribers()
            this.props.dispatch(siteMaster.alterSiteDrawer(false))
        }
        } />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, 'right', drawer, true, 400))
    }

    getFields() {
        if (this.state.fieldsFilter) {
            return this.state.systemFields.concat(this.state.customFields).filter(f => f.fieldDesc.toLowerCase().indexOf(this.state.fieldsFilter) > -1);
        } else {
            return this.state.systemFields.concat(this.state.customFields);
        }
    }

    allowSelectingAllFields(fields) {
        if (fields.length < SELECTED_FIELDS_LIMIT) return true;
        return false;
    }

    getSelectedFields(fields) {
        const selectedFields = fields.filter((field) => {
            if (field.id && field.selected) return field;

            if (this.state.selectedSystemFields.some((f) => f.fieldName === field.fieldName)) {
                return field;
            }
        });

        return selectedFields.slice(0, SELECTED_FIELDS_LIMIT);
    }

    render() {
        const fields = this.getFields();
        const selectedFields = this.getSelectedFields(fields);

        var tags = this.state.tags
        var linkTags = this.state.linkTags
        if (this.state.tagsFilter) {
            tags = tags.filter(t => t.label.toLowerCase().indexOf(this.state.tagsFilter.toLowerCase()) > -1)
            linkTags = linkTags.filter(t => t.label.toLowerCase().indexOf(this.state.tagsFilter.toLowerCase()) > -1)
        }

        const filteredTags = this.state.tags.filter(t => t.selected)
        const filteredLinkTags = this.state.linkTags.filter(t => t.selected)
        const allFilteredTags = [...filteredTags, ...filteredLinkTags]

        var segments = JSON.parse(JSON.stringify(this.state.segments));
        segments.unshift({
            name: 'All',
            subscriberCount: this.state.allTotal,
            id: undefined,
            readOnly: false,
            label: i18n.t('subscribers:overview.all')
        })

        return <div>
            <UILIB.Row>
                <UILIB.Column xs={12} sm={6} className="mar-b25">
                    <UILIB.TextInput
                        placeholder={i18n.t('subscribers:overview.searchPlaceholder')}
                        value={this.state.searchText}
                        onChange={this.changeSearchText}
                        clickToChangeClicked={this.searchKeyPress}
                        onEnterPress={this.searchKeyPress}
                        className="no-marg"
                        iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16, marginTop: "1px" }} color="#2B2F41" onClick={() => { }} />}
                    />
                </UILIB.Column>

                <UILIB.Column xs={12} sm={6} className="end-xs mar-b25 middle-xs quickFlex">
                    {this.state.writeAccess && <UILIB.Button className="mar-r10" onClick={this.bulkEdit.bind(this)}>
                        Change Status
                    </UILIB.Button>}
                    {(this.state.writeAccess === true) && <UILIB.Button
                        onClick={this.addSubs}
                        className="button-primary"
                        iconLeft={<UILIB.Icons icon="plus" />}>
                        {i18n.t('subscribers:nav.addSubscribers')}
                    </UILIB.Button>}
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row className="hide-xs">
                <UILIB.Column xs={12} sm={8} className="quickFlex mar-b25">

                    <UILIB.Select
                        outerClassName="mar-r5"
                        inlineLabel="Filter"
                        disabled={this.state.isLoadingSegments}
                        value={this.state.segmentId}
                        placeholderNoSelect="All"
                        autoWidth={true}
                        onChange={(e) => this.changeSegment(segments.find(s => s.id == e.target.value))}
                        fixedHeightLarge={true}
                        data={segments.map(s => {
                            let tmpLabel = <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex" }}>
                                    <div>{s.label}</div>
                                    {s.id === this.state.segmentId ? <div className="mar-l5">({NumberFunctions.formatNumber(this.state.total)})</div> : ''}
                                    {/* {(s.subscriberCount !== undefined) && <div className="mar-l5">
                                        ({NumberFunctions.formatNumber(s.subscriberCount)})
                                    </div>} */}
                                </div>
                                {(!s.readOnly && s.label != "All") &&
                                    <UILIB.SquareChip small={true} className={'square-chip-secondary mar-l5'}>  {i18n.t("segment")}</UILIB.SquareChip>
                                }
                            </div>;
                            return {
                                label: tmpLabel,
                                value: s.id
                            }
                        })}
                    />

                    <UILIB.ButtonSelect
                        fixedHeightLarge={true}
                        className="with-icon white mar-r10"
                        headerText={i18n.t('subscribers:overview.allFields')}
                        filter={true}
                        updateFilter={this.filterFields}
                        selectAll={this.allowSelectingAllFields(fields) ? () => this.toggleAllFields(true) : false}
                        clearAll={() => this.toggleAllFields(false)}
                    >
                        {fields && !!fields.length && (
                            <>
                                {fields.length >= SELECTED_FIELDS_LIMIT && (
                                    <div
                                        className="text-heavy text-grey"
                                        style={{ padding: "8px 16px 0" }}
                                        data-test="selected-fields-limit-message"
                                    >
                                        {`Select up to ${SELECTED_FIELDS_LIMIT} fields`}
                                    </div>
                                )}

                                <ul data-test="fields-list">
                                    {fields.map((field) => {
                                        const selected = selectedFields.some(f => f === field);
                                        const isCheckboxDisabled = selected === false && selectedFields.length >= SELECTED_FIELDS_LIMIT;

                                        return (
                                            <li key={field.id || field.fieldName} className="quickFlex" style={{ justifyContent: 'space-between' }}>
                                                {isCheckboxDisabled && (
                                                    <ReactTooltip
                                                        id={field.fieldName}
                                                        effect='solid'
                                                    >
                                                        The maximum number of fields have been selected
                                                    </ReactTooltip>
                                                )}

                                                <div data-tip data-for={field.fieldName}>
                                                    <UILIB.CheckBox
                                                        outerClassName="full-width"
                                                        name={field.fieldName}
                                                        onChange={() => this.selectFields(field)}
                                                        checked={selected}
                                                        disabled={isCheckboxDisabled}
                                                    >
                                                        {field.fieldDesc}
                                                    </UILIB.CheckBox>
                                                </div>

                                                {field.Application && field.Application.appLogoUri && (
                                                    <UILIB.Avatar
                                                        for={field.id}
                                                        tooltip={field.Application.appName}
                                                        src={field.Application.appLogoUri}
                                                        style={{ height: 20, width: 20, padding: 0, marginRight: 10 }}
                                                    />
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        )}
                        {(!fields || !fields.length) && <div style={{ padding: 16 }}>{i18n.t('subscribers:overview.noCustomFields')}</div>}
                    </UILIB.ButtonSelect>

                    <UILIB.ButtonSelect
                        disabled={this.state.isLoading}
                        fixedHeightLarge={true}
                        className="with-icon white mar-r10"
                        headerText={allFilteredTags.length ? allFilteredTags.length + ' Tags Selected' : i18n.t('subscribers:overview.selectTags')}
                        filter={true}
                        updateFilter={t => this.setState({ tagsFilter: t })}
                    >
                        {tags && !!tags.filter(tag => tag.tagType == 0).length && <>
                            <div className='text-heavy text-grey' style={{ padding: '5px 12px' }}>Contact Tags</div>
                            <ul>
                                {tags.filter(tag => tag.tagType == 0).map((tag) => {
                                    return <li key={tag.value} className="quickFlex" style={{ justifyContent: 'space-between' }}>
                                        <UILIB.CheckBox outerClassName="full-width" name={tag.value} onChange={this.selectTag} checked={tag.selected}>{tag.label}</UILIB.CheckBox>
                                        {tag.Application && tag.Application.appLogoUri && tags.length < 200 && <UILIB.Avatar for={tag.id || tag.value} tooltip={tag.Application.appName} src={tag.Application.appLogoUri} style={{ height: 20, width: 20, padding: 0, marginRight: 10 }} />}
                                    </li>
                                })}
                            </ul>
                        </>}
                        {tags && !!tags.filter(tag => tag.tagType == 3).length && <>
                            <div className='text-heavy text-grey' style={{ padding: '5px 12px' }}>SiteTracker Sources</div>
                            <ul>
                                {tags.filter(tag => tag.tagType == 3).map((tag) => {
                                    return <li key={tag.value} className="quickFlex" style={{ justifyContent: 'space-between' }}>
                                        <UILIB.CheckBox outerClassName="full-width" name={tag.value} onChange={this.selectTag} checked={tag.selected}>{tag.label}</UILIB.CheckBox>
                                        {tag.Application && tag.Application.appLogoUri && tags.length < 200 && <UILIB.Avatar for={tag.id || tag.value} tooltip={tag.Application.appName} src={tag.Application.appLogoUri} style={{ height: 20, width: 20, padding: 0, marginRight: 10 }} />}
                                    </li>
                                })}
                            </ul>
                        </>}

                        {linkTags && !!linkTags.length && <>
                            <div className='text-heavy text-grey' style={{ padding: '5px 12px' }}>Link Tags</div>
                            <ul>
                                {linkTags.map((tag) => {
                                    return <li key={tag.value} className="quickFlex" style={{ justifyContent: 'space-between' }}>
                                        <UILIB.CheckBox outerClassName="full-width" name={tag.value} onChange={this.selectLinkTag} checked={tag.selected}>{tag.label}</UILIB.CheckBox>
                                    </li>
                                })}
                            </ul>
                        </>}
                        {(!tags || !tags.length) && (!linkTags || !linkTags.length) && <ul>
                            <li><a>No Tags Found</a></li>
                        </ul>}
                    </UILIB.ButtonSelect>

                </UILIB.Column>

                <UILIB.Column xs={12} sm={4} className="hide-xs end-xs mar-b25" style={{ display: "flex", alignItems: "center" }}>
                    <div>{i18n.t('showing')}</div>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.pageSize === 15} onClick={() => { this.changePageSize(15) }}>15</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 50} onClick={() => { this.changePageSize(50) }}>50</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>
                    <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 500} onClick={() => { this.changePageSize(500) }}>500</UILIB.ButtonSimple>
                </UILIB.Column>

            </UILIB.Row >

            <UILIB.DataTable1
                noResultsTxt={<span className='hide-xs'> {i18n.t('subscribers:overview.noSubscribers')} </span>}
                tableData={this.state.tableData}
                dataUpdater={this.changePage}
                loadingData={this.state.loadingData}
                sortedColumn={this.state.orderBy}
                sortedDirection={this.state.orderDir}
                width="100%"
                changeColumnOrder={true}
                pageSize={this.state.pageSize}
                isLoading={this.state.isLoading}
                hasCheckBoxes={PermissionChecker('subscribers', this.props.permissionStore.permissions, "write") ? "multi" : false}
            />

            <UILIB.Row>
                <UILIB.Column xs={12} hide={["xs", "sm"]} className="center-xs mar-t25">
                    <UILIB.PagingBlock
                        totalRows={this.state.totalViewed}
                        pageSize={this.state.pageSize}
                        numberOfLinks="10"
                        currentPage={this.state.page}
                        changePage={this.changePage}
                        text={i18n.t('page') + ":"} />
                </UILIB.Column>
                {this.state.tableData.length < this.state.totalViewed && <UILIB.Column xs={12} hide={["md", "lg"]} className="center-xs">
                    <UILIB.Button className={"outline button-md primary " + (this.state.loadingmore ? 'loading' : '')} text={this.state.loadingmore ? 'loading' : "load more"} onClick={this.loadmore} />
                </UILIB.Column>}
            </UILIB.Row>

            <UILIB.SnackBar message={i18n.t('subscribers:index.resync')} open={this.state.retry > 2} autoCloseDuration={5000} autoclose={true} dismiss={() => this.setState({ retry: 0 })} />

        </div >
    };

};

import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
class MoveFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            folderId: undefined
        }
    }

    componentDidMount() {
        this.setState({
            folderId: this.props.currentFolder.folderID
        })
    }

    render() {
        return (
            <div>
                <h4 className="mar-b25">{i18n.t('templates:fileManager.popups.moveFile')}</h4>
                <UILIB.Select
                    data={this.props.folders.map(folder => { return { label: folder.folderName, value: folder.folderID } })}
                    value={this.state.folderId}
                    onChange={ev => this.setState({ folderId: ev.currentTarget.value })}
                    outerClassName="mar-b25"
                />

                <UILIB.Button
                    text={i18n.t('templates:fileManager.popups.confirm')}
                    className="button-primary"
                    iconRight={<UILIB.Icons icon="tick" />}
                    onClick={() => this.props.move(this.state.folderId)} />
            </div>
        );
    }
}

export default MoveFile;
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'

class TemplateImageLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateImageLoaded: false
        }
        this.checkTemplateImageExists = this.checkTemplateImageExists.bind(this);
    }

    checkTemplateImageExists() {
        if (this.state.templateImageLoaded) return;
        var self = this;
        var img = new Image();
        img.onload = function () { self.setState({ templateImageLoaded: true }) };
        img.onerror = function () { setTimeout(self.checkTemplateImageExists, 1000); };
        img.src = this.props.imageUrl;
    }

    componentDidMount() {
        this.checkTemplateImageExists();
    }

    render() {
        var backgroundImage = "url(" + this.props.imageUrl + ")";

        return <div>
            {this.state.templateImageLoaded && <div className="templateImageImg" style={{ backgroundImage: backgroundImage }} />}
            {!this.state.templateImageLoaded && <UILIB.LoadingIcons iconType="2" />}
        </div>
    }
}

export default TemplateImageLoader;
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
class renameFile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: this.props.file.friendlyName
        }

        this.updateField = this.updateField.bind(this);
    }

    updateField(ev) {
        this.setState({
            [ev.currentTarget.name]: ev.currentTarget.value
        })
    }

    render() {
        var headerTxt = i18n.t('templates:fileManager.popups.renameFileName');
        headerTxt = headerTxt.replace(/\[FILENAME\]/g, this.props.file.friendlyName)
        return (
            <div>
                <h4 className="mar-b25">{headerTxt}</h4>
                <UILIB.TextInput
                    name="name"
                    value={this.state.name}
                    onChange={this.updateField}
                    label={i18n.t('templates:fileManager.popups.fileName')}
                    outerClassName="mar-b25"
                />
                <UILIB.Button
                    text={i18n.t('templates:fileManager.popups.save')}
                    className="button-primary"
                    onClick={() => this.props.save(this.state.name)}
                />

            </div>
        );
    }
}

export default renameFile;
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import UILIB from '../../../../Common-Objects/Lib';
import axios from '../../../../data/http/axios';
import moment from 'moment';
import i18n from '~/i18n';
import FormatNumberFuncs from '../../../../Classes/numberFormatFunctions';
import DateTimeFunctions from '../../../../Classes/dateTimeFunctions';

export default function SiteVisitTable({ edit = () => { } }) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const history = useHistory()

    const getData = async () => {
        setLoading(true)
        const res = await axios.get('/accountMaster/tracker')
        var trackersData = res.data.Trackers.map((tracker) => {
            var ourDomain = tracker.host;
            if (!tracker.host || !tracker.host.length) ourDomain = "unknown";
            return {
                domain: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingsites.domain'),
                    value: ourDomain,
                    orderBy: false
                },
                paths: {
                    headerValue: 'Pages',
                    value: <span>{FormatNumberFuncs.formatNumber(tracker.SiteTrackerPaths.length)}</span>,
                    orderBy: false,
                    align: "center",
                    width: "200"
                },
                hits: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingsites.hits'),
                    value: <UILIB.SquareChip className="square-chip-primary square-chip-large">{FormatNumberFuncs.formatNumber(tracker.hits)}</UILIB.SquareChip>,
                    orderBy: false,
                    align: "center",
                    width: "100"
                },
                lastHit: {
                    headerValue: i18n.t('campaigns:sitetracking.trackingsites.lastHit'),
                    value: <span> {DateTimeFunctions.formatDateTime(tracker.lastHit)}</span>,
                    orderBy: false,
                    align: "center",
                    width: "200"
                }
            }
        })
        setData(trackersData)
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <UILIB.Paper isLoading={loading} iconRight={<UILIB.Icons icon="pencil" color="grey" />} iconRightClick={edit}>
            <div className="quickFlex mar-b24" style={{ justifyContent: "space-between" }}>
                <h4>Site Trackers</h4>
                <UILIB.Row>
                    <UILIB.Column xs={12} margin={0} className="end-xs">
                        <UILIB.Button className="primary" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => history.push('/cp/trackers')}>View Site Trackers</UILIB.Button>
                    </UILIB.Column>
                </UILIB.Row>
            </div>

            {!!data && <UILIB.DataTable1
                noResultsTxt={"No Sites Configured"}
                tableData={data}
            />}
            {!data && <div style={{ height: 300 }}><UILIB.LoadingIcons /></div>}
        </UILIB.Paper>
    )
}
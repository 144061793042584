import React from 'react'

export default function IconsXColorRounded({ color = "", style = {} }) {
    let className = "icons-icon";
    if (!style.borderRadius) style.borderRadius = "6px";
    return (

        <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill={color} />
            <g clip-path="url(#clip0_700_22599)">
                <path d="M13.2685 10.9793L18.2321 5.3335H17.0559L12.746 10.2356L9.30365 5.3335H5.33334L10.5388 12.7464L5.33334 18.6668H6.50963L11.061 13.49L14.6964 18.6668H18.6667L13.2682 10.9793H13.2685ZM11.6574 12.8117L11.13 12.0735L6.93347 6.19995H8.74018L12.1268 10.9401L12.6542 11.6783L17.0565 17.8398H15.2497L11.6574 12.812V12.8117Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_700_22599">
                    <rect width="13.3333" height="13.3333" fill="white" transform="translate(5.33318 5.33374)" />
                </clipPath>
            </defs>
        </svg>

    )
}
import React from 'react';

export default class MPZLogo extends React.Component {
	render() {
		return <svg style={this.props.style} onClick={this.props.onClick} width="200" viewBox="0 0 400 68" fill="none" xmlns="http://www.w3.org/2000/svg">

			<path d="M23.6863 5.66667L13.9297 11.3333L4.21053 5.66667L13.9297 0L23.6863 5.66667Z" fill="white" />
			<path d="M33.4447 11.3333L23.688 17L13.9314 11.3333L23.688 5.66665L33.4447 11.3333Z" fill="white" />
			<path d="M43.2002 17L33.4435 22.6667L23.6869 17L33.4435 11.3334L43.2002 17Z" fill="white" />
			<path d="M52.9557 22.6667L43.199 28.3333L33.4424 22.6667L43.199 17L52.9557 22.6667Z" fill="white" />
			<path d="M62.6741 28.3333L52.955 34L43.1983 28.3333L52.955 22.6666L62.6741 28.3333Z" fill="white" />
			<path d="M43.199 39.6666V51L33.4424 56.6666V45.3333L43.199 39.6666Z" fill="white" />
			<path d="M62.6761 28.3334V39.6667L52.957 45.3334V34L62.6761 28.3334Z" fill="white" />
			<path d="M23.688 51V62.3333L13.9314 68V56.6667L23.688 51Z" fill="white" />
			<path d="M23.6863 28.3333L13.9297 34L4.21053 28.3333L13.9297 22.6666L23.6863 28.3333Z" fill="white" />
			<path d="M33.4447 34L23.688 39.6667L13.9314 34L23.688 28.3334L33.4447 34Z" fill="white" />
			<path d="M43.2002 39.6667L33.4435 45.3333L23.6869 39.6667L33.4435 34L43.2002 39.6667Z" fill="white" />
			<path d="M13.9297 0V11.3333L4.21053 17V5.66667L13.9297 0Z" fill="white" />
			<path d="M13.9297 22.6666V34L4.21053 39.6666V28.3333L13.9297 22.6666Z" fill="white" />
			<path d="M23.6863 51L13.9297 56.6667L4.21053 51L13.9297 45.3333L23.6863 51Z" fill="white" />
			<path d="M13.9297 45.3333V56.6667L4.21053 62.3333V51L13.9297 45.3333Z" fill="white" />
			<path d="M98.0354 54.5545H105.98V20.9746H118.207V14.1112H86.3158V20.9746H98.0354V54.5545Z" fill="white" />
			<path d="M119.723 54.5545H127.499V40.3171C127.499 32.3759 129.414 29.5965 137.528 29.5965H140.965V21.6553H138.373C132.062 21.6553 128.513 24.4914 127.16 29.8234L126.146 21.9389H119.723V54.5545Z" fill="white" />
			<path d="M152.647 55.4621C159.915 55.4621 164.536 52.1722 166.846 47.7478L167.635 54.5545H174.001V32.8864C174.001 25.2288 169.607 21.0881 158.732 21.0881C148.816 21.0881 143.068 25.7961 142.449 33.4536H150.506C150.788 29.3696 153.267 27.1007 159.183 27.1007C164.592 27.1007 166.451 28.8591 166.451 31.5251C166.451 34.0776 164.198 34.815 158.169 35.4957C151.182 36.3465 148.703 36.857 145.829 38.5587C143.012 40.2037 141.435 42.9264 141.435 46.6133C141.435 52.1155 145.322 55.4621 152.647 55.4621ZM155.295 49.5062C151.576 49.5062 149.886 48.0881 149.886 45.5923C149.886 44.0608 150.45 43.1532 151.858 42.2457C153.379 41.3381 155.239 40.8843 159.634 40.3171C162.958 39.8633 165.268 39.2394 166.564 38.2184V39.6364C166.564 44.4011 162.056 49.5062 155.295 49.5062Z" fill="white" />
			<path d="M179.234 54.5545H187.123V38.0482C187.123 31.6952 189.827 27.7814 195.687 27.7814C201.321 27.7814 203.181 31.2982 203.181 36.857V54.5545H211.069V34.9284C211.069 26.4767 206.561 21.0881 198.279 21.0881C192.419 21.0881 188.475 24.0376 186.616 28.5755L185.883 21.9389H179.234V54.5545Z" fill="white" />
			<path d="M230.426 55.4621C240.568 55.4621 245.526 50.8108 245.526 44.4579C245.526 37.2541 239.554 35.7793 232.003 34.9852C226.087 34.3045 222.538 33.8507 222.538 31.0713C222.538 28.9158 224.059 27.2141 229.074 27.2141C233.694 27.2141 236.229 28.5755 236.793 31.8087H244.906C243.723 25.0587 238.99 21.0881 229.186 21.0881C219.608 21.0881 214.875 25.5124 214.875 31.5818C214.875 38.7289 221.073 40.2604 228.285 41.0545C234.144 41.6217 237.751 42.1322 237.751 44.9117C237.751 47.4075 235.835 48.9957 230.651 48.9957C225.524 48.9957 222.819 47.5209 222.03 43.7772H213.466C214.537 51.4348 219.833 55.4621 230.426 55.4621Z" fill="white" />
			<path d="M248.706 63.7498H256.313V49.0524C258.341 52.9663 262.173 55.2919 267.863 55.2919C276.315 55.2919 282.738 49.7331 282.738 38.8423V38.2184C282.738 27.1574 276.991 21.0881 268.032 21.0881C262.229 21.0881 258.06 23.9242 256.031 28.3486L255.299 21.9389H248.706V63.7498ZM265.441 48.7121C259.581 48.7121 256.144 44.8549 256.144 38.6154V38.1616C256.144 31.752 259.806 27.7246 265.61 27.7246C271.244 27.7246 274.794 31.3549 274.794 38.2184V38.7856C274.794 45.1385 271.526 48.7121 265.441 48.7121Z" fill="white" />
			<path d="M303.18 55.4621C314.054 55.4621 320.647 49.336 320.647 38.8423V38.1616C320.647 27.2709 313.547 21.0881 303.236 21.0881C293.151 21.0881 285.882 27.2141 285.882 38.1616V38.8423C285.882 49.4495 291.798 55.4621 303.18 55.4621ZM303.236 48.6554C297.264 48.6554 293.883 45.0818 293.883 38.7289V38.1616C293.883 31.3549 297.545 27.7246 303.349 27.7246C309.209 27.7246 312.702 31.5251 312.702 38.1616V38.7289C312.702 45.0818 309.378 48.6554 303.236 48.6554Z" fill="white" />
			<path d="M324.724 54.5545H332.612V38.0482C332.612 31.6952 335.316 27.7814 341.176 27.7814C346.81 27.7814 348.67 31.2982 348.67 36.857V54.5545H356.558V34.9284C356.558 26.4767 352.05 21.0881 343.768 21.0881C337.908 21.0881 333.964 24.0376 332.105 28.5755L331.372 21.9389H324.724V54.5545Z" fill="white" />
			<path d="M375.121 55.2919C381.206 55.2919 385.206 52.3423 387.066 47.8612L387.855 54.5545H394.39V12.7498H386.784V27.441C384.699 23.4704 380.812 21.1448 375.403 21.1448C366.5 21.1448 360.415 26.9872 360.415 37.7646V38.4452C360.415 49.6196 366.387 55.2919 375.121 55.2919ZM377.487 48.5986C371.909 48.5986 368.472 45.1953 368.472 38.3885V37.878C368.472 31.4116 371.627 27.6112 377.713 27.6112C383.46 27.6112 387.009 31.5818 387.009 37.9347V38.3885C387.009 44.7415 383.46 48.5986 377.487 48.5986Z" fill="white" />
		</svg>



	}
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';

@connect((store) => {
    return { user: store.user }
})
export default class DeleteStaffPop extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            error: ""
        };

        this.deleteDomain = this.deleteDomain.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }
    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    deleteDomain() {
        var domainId = this.props.domainId;
        self = this;
        axios.delete('/accountMaster/cdn/' + domainId).then((res) => {
            this.closeDrawer();
            this.props.onDeleted();
        }).catch(err => {
            let error = "";
            if (err && err.data && err.data.error) error = err.data.error;
            if (err && err.data && err.data.info && err.data.info.automations) error = "automation";
            let errorOut = <span>There was an error deleting your domain.</span>
            if (error == "automation") {
                errorOut = <UILIB.WarningHolder style={{ textAlign: "left" }}>
                    <div className="mar-b10">
                        You cannot delete this domain because it is being used in the following automations.
                    </div>
                    <div className="mar-b10">
                        {err.data.info.automations.map((auto, index) => {
                            return <div key={"auto_" + index} className="mar-b5"><a onClick={() => { window.open(`/cp/automation/addnew/${auto.id}`, '_blank') }}>{auto.name}</a></div>
                        })}
                    </div>
                    <div>
                        Please remove the domain from any email step in the automation(s) above to proceed.
                    </div>
                </UILIB.WarningHolder>
            }
            this.setState({ error: errorOut })
        })
    }

    render() {

        return <div>

            <h4 className="mar-b25">{i18n.t('company:domains.cdn.delete')}</h4>
            <div className="mar-b25">{i18n.t('company:domains.cdn.deleteConfirm')}</div>

            {this.state.error && <UILIB.WarningHolder>{this.state.error}</UILIB.WarningHolder>}
            {(!this.state.error) && <div>
                <UILIB.Button text={i18n.t('company:domains.cdn.deleteButton')} className="button-primary" onClick={this.deleteDomain} />
            </div>
            }
        </div >
    }
};


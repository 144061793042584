import Column1 from './1column';
import Column2 from './2column';
import Column3 from './3column';
import Column121 from './121column';
import Column13 from './13column';
import Column4 from './4column';
import Column12 from './12column';
import Column21 from './21column';
import Blank from './blank';
export default {
    Column1,
    Column2,
    Column3,
    Column121,
    Column13,
    Column4,
    Column12,
    Column21,
    Blank
}
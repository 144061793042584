import React from 'react'
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import UILIB from '~/Common-Objects/Lib'
import 'react-datepicker/dist/react-datepicker.css';

export default class DatePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            date: ""
        }
        this.keyDown = this.keyDown.bind(this);
    }

    componentDidMount() {
        if (this.props.focus) {
            this.nameInput.focus();
        }
    }

    keyDown(evt) {
        evt.preventDefault()
    }

    render() {
        var errorTxt = [];

        if (this.props.error && this.props.error.length > 0) {
            errorTxt.push(<UILIB.FormError>{this.props.error}</UILIB.FormError>);
        }

        var value = this.props.value
            ? moment(this.props.value)
            : this.state.date;
        return <span>
            <ReactDatePicker name={this.props.name} selected={value} onChange={this.props.onChange} onKeyDown={this.keyDown} dateFormat="DD/MM/YYYY"></ReactDatePicker>
            {errorTxt}
        </span>

    }
}

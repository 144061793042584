import React from 'react'

export default class IconsStarSolid extends React.Component {

    render() {
        let color = "#FF9425";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.78476 1.8734C9.16727 0.708868 10.8327 0.708868 11.2152 1.8734L12.5819 6.0342C12.753 6.55499 13.2436 6.9076 13.7971 6.9076H18.2198C19.4576 6.9076 19.9722 8.47446 18.9708 9.19418L15.3928 11.7657C14.945 12.0876 14.7576 12.6581 14.9287 13.1789L16.2953 17.3397C16.6778 18.5042 15.3305 19.4726 14.329 18.7529L10.7511 16.1814C10.3032 15.8595 9.69679 15.8595 9.24894 16.1814L5.67096 18.7529C4.66955 19.4726 3.32216 18.5042 3.70466 17.3397L5.07133 13.1789C5.24239 12.6581 5.055 12.0876 4.60715 11.7657L1.02917 9.19418C0.0277572 8.47446 0.542414 6.9076 1.78022 6.9076H6.20286C6.75642 6.9076 7.24703 6.55499 7.41809 6.0342L8.78476 1.8734Z" fill={color} />
        </svg>

    }

}        
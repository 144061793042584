import React from 'react'

export default class Icons extends React.Component {
    render() {
        let className = "indicator";
        if (this.props.className) className += " " + this.props.className;
        return <div onClick={this.props.onClick} className={className} style={this.props.style}>
            {this.props.leftIcon && <div className="indicator-leftIcon mar-r5">{this.props.leftIcon}</div>}
            <span>{this.props.children}</span>
            {this.props.rightIcon && <div className="indicator-rightIcon mar-l5">{this.props.rightIcon}</div>}
        </div>

    }
}
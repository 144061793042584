import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';


export default class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            optionsData: {},
            loaded: false
        }
        this.toggleExpand = this.toggleExpand.bind(this);
        this.clickCheckBox = this.clickCheckBox.bind(this);
        this.sendDataBack = this.sendDataBack.bind(this);
    }

    componentDidMount() {
        this.setState({ loaded: true, optionsData: this.props.data });
    }
    componentDidUpdate(prevProps) {
        this.setState({ optionsData: prevProps.data });
    }


    toggleExpand() {
        var expanded = this.state.expanded;
        if (expanded === true) {
            expanded = false;
        }
        else {
            expanded = true;
        }
        this.setState({ expanded: expanded });
    }

    clickCheckBox(el) {
        var thisName = el.currentTarget.name;
        var isChecked = el.currentTarget.checked;
        var theData = this.state.optionsData;
        var expanded = this.state.expanded;

        var mainChecked = this.state.mainChecked;
        if (thisName === "mainChecked") {
            mainChecked = isChecked;
            expanded = isChecked;
            //check/uncheck all sub options
            for (var x in theData) {
                theData[x][0].checked = isChecked;
            }
        }
        else {
            theData[thisName][0].checked = isChecked;
            //check/uncheck main one depending on all ticked or not
            var totCheckedPerms = theData.filter(thePerm => thePerm[0].checked === true).length;
            if (totCheckedPerms === theData.length) {
                mainChecked = true;
            }
            if (totCheckedPerms < theData.length) {
                mainChecked = false;
            }
        }
        this.setState({ mainChecked: mainChecked, optionsData: theData, expanded: expanded }, this.sendDataBack);
    }

    sendDataBack() {
        this.props.dataChange(this.props.name, this.state.optionsData);
    }

    render() {

        if (this.state.loaded === true) {
            var innerClasses = "accordion ";
            var wrapClasses = "accordion-wrap ";
            if (this.state.expanded === true) {
                innerClasses += " expanded ";
            }
            var totPerms = this.state.optionsData.length;
            var totCheckedPerms = this.state.optionsData.filter(thePerm => thePerm[0].checked === true).length;
            if (totCheckedPerms > 0) {
                wrapClasses += "primary ";
            }

            var mainChecked = false;
            if (totPerms === totCheckedPerms) {
                mainChecked = true;
            }

            return <div className={wrapClasses}>
                <div className={innerClasses}>
                    <div className="name-wrap">
                        <input type="checkbox" className="checkbox" name="mainChecked" checked={mainChecked} disabled={this.props.readOnly} onChange={this.clickCheckBox} />
                        <div className="name"><p>{i18n.t('permissions:' + this.props.title)}</p></div>
                    </div>
                    {this.state.expanded && <div className="icon"><span className="icon-minus" onClick={this.toggleExpand}></span></div>}
                    {!this.state.expanded && <div className="icon"><span className="icon-plus" onClick={this.toggleExpand}></span></div>}
                </div>
                {this.state.expanded &&
                    <div className="accordion-expand">
                        <ul>
                            {this.state.optionsData.map((thisLine, index) => {
                                return <li key={"opt-" + thisLine[0].id}>
                                    <UILIB.CheckBox checked={thisLine[0].checked} name={index} disabled={this.props.readOnly} onChange={this.clickCheckBox} />
                                    <a href="#!">{thisLine[0].name}</a>
                                </li>
                            })}


                        </ul>
                    </div>
                }
            </div>
        }
        else {
            return null
        }

    }
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UILIB from "~/Common-Objects/Lib";
import axios from "~/data/http/axios";

export default function InboxPage({ ...props }) {
    const account = useSelector((state) => state.accountMaster);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [labs, setLabs] = useState([]);
    const [overlay, setOverlay] = useState(undefined);
    useEffect(() => {
        getLabs();
    }, [])

    const getLabs = async () => {
        try {
            let labsData = await axios.get('/accountmaster/labs');
            labsData = labsData.data;
            setLabs(labsData);
            setLoading(false);
        }
        catch (err) {
            setLabs([]);
            setLoading(false);
        }
    }


    const clickOption = async (e, optionType, lab) => {
        let newOverlay = undefined;
        if (optionType === "moreInfo") {

            newOverlay = <UILIB.LabsMoreInfo labId={lab.id} onClose={() => { setOverlay(undefined); }} />

        }
        if (optionType === "toggle") {
            if (lab.active) {
                //remove
                newOverlay = <UILIB.LabsDisconnect labId={lab.id} onClose={() => { setOverlay(undefined); getLabs(); }} />
            }
            else {
                //add
                newOverlay = <UILIB.LabsEnable labId={lab.id} onClose={() => { setOverlay(undefined); getLabs(); }} />
            }
        }
        setOverlay(newOverlay);
    }






    if (loading) return <UILIB.LoadingIcons iconType="2" />;

    return (
        <>
            <UILIB.Row>
                <UILIB.Column xs={12} sm={10} className="col-md-offset-1">

                    <UILIB.Paper>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={6} >
                                <div className="labs_leftNav">
                                    <div>
                                        <h2 className="labs_header">Transpond Labs</h2>
                                        A whole new way to get involved. Get early access to new features, give feedback, and be part of building a better Transpond.
                                    </div>
                                    <div className="labs_leftNav_warning">
                                        <UILIB.Icons icon="alert" />
                                        <div>
                                            <div className="labs_leftNav_warningTitle">These are experimental features</div>
                                            <div>There might be bugs, may change significantly without notice, and could become paid features on release.</div>
                                        </div>
                                    </div>
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} className="labs_rightNav">
                                {(labs.length < 1) && <div className="labs_rightNav_item">
                                    <div>
                                        <div className="labs_rightNav_itemTitle">No Labs Available</div>
                                        <div className="labs_rightNav_itemDesc">
                                            There are no beta's available to join at the moment.<br />
                                            Check back soon!
                                        </div>
                                    </div>
                                </div>
                                }
                                {labs.map((lab, index) => {
                                    return <div className="labs_rightNav_item" key={"lab_" + index}>
                                        <div>
                                            <div className="labs_rightNav_itemTitle">{lab.fullName}</div>
                                            <div className="labs_rightNav_itemDesc">{lab.shortDescription}</div>
                                            <div className="labs_rightNav_itemMore" onClick={(e) => {
                                                clickOption(e, "moreInfo", lab);
                                            }}>
                                                <UILIB.Icons icon="questionCircle" color="#000" />
                                                <div>Learn more about {lab.fullName}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <UILIB.Toggle checked={lab.active} onChange={(e) => { clickOption(e, "toggle", lab) }} onClick={(e) => {
                                                clickOption(e, lab);
                                            }} />
                                        </div>
                                    </div>
                                })
                                }
                            </UILIB.Column>

                        </UILIB.Row>
                    </UILIB.Paper >
                </UILIB.Column>
            </UILIB.Row >
            {overlay}
        </>

    );
}

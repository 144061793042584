import React from "react";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";

export default function ChatAttachment({ className, children, ...rest }) {
  const classes = classnames(styles.root, className);

  return (
    <button className={classes} {...rest}>
      <div className={styles.iconWrapper}>
        <UILIB.Icon name="download" />
      </div>

      <div>
        <div className={styles.fileName}>Order #624-232589</div>
        <span className={styles.fileSize}>124kb</span>
      </div>
    </button>
  );
}

import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import Composer from '../../../../inbox/components/shared/composer'
import * as styles from "./index.module.scss";
import Button from '../../../../components/button';
import { alterSnackbar } from '../../../../../../../data/actions/siteActions';
import i18n from "~/i18n";

export default function EmailChannelSignature({ channel, onUpdateChannel = () => { } }) {
    const dispatch = useDispatch()
    const [signature, setSignature] = useState(channel.settings?.signature || "")
    const [openMergeTags, setOpenMergeTags] = useState(false)

    const save = () => {
        const obj = { ...channel, settings: { ...channel.settings, signature } }
        onUpdateChannel(obj)
        dispatch(alterSnackbar(true, i18n.t("chat:channel.email.signature.saved")))
    }

    return (
        <>
            <div className={styles.root}>
                <Composer message={signature} setMessage={setSignature} showToolbar={true} openMergeTags={openMergeTags} setOpenMergeTags={setOpenMergeTags} mergeTags='signature' />
            </div>
            <div className="quickFlex" style={{ justifyContent: 'space-between', width: '100%' }}>
                <Button size="s" onClick={save}>{i18n.t("chat:channel.email.signature.save")}</Button>
                <Button size="s" variant="secondary" onClick={() => setOpenMergeTags(v => !v)}>{i18n.t("chat:channel.email.signature.merge")}</Button>
            </div>
        </>
    )
}
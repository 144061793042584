import React from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import queryString from 'query-string';
import UILIB from '~/Common-Objects/Lib';
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission }
})

export default class OAuthResponse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appId: undefined,
            code: undefined,
            app: undefined,
            loadedData: false,
            failed: false,
            query: {}
        }
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        var appId = this.props.match.params.appId;
        var code = query.code;

        this.setState({ appId: appId, code: code, query }, this.getApp)
    }

    getApp() {
        if (!this.state.appId || !this.state.code) return this.setState({ failed: true })
        var appData;
        var self = this;
        var theData = {
            code: self.state.code,
            redirect_uri: window.location.href
        }

        for (var prop in this.state.query) {
            theData[prop] = this.state.query[prop]
        }

        axios.get("/application/" + this.state.appId).then(_theApp => {
            appData = _theApp.data.Application
            this.setState({
                add: appData
            })

            return axios.post('/application/external/oAuth/connect/' + self.state.appId, theData)
        }).then(resp => {
            if (appData.appConfigUri) {
                let appConfigUri = appData.appConfigUri;
                if (appConfigUri.indexOf("[APPID]")) appConfigUri = appConfigUri.replace("[APPID]", self.state.appId)
                if (resp.data.id) appConfigUri = appConfigUri += '?id=' + resp.data.id
                window.location.href = appConfigUri
            }
            else {
                window.location.href = '/cp/company/integrations/apps/integrationnoconfig/' + self.state.appId
            }
        }).catch(err => {
            this.setState({ failed: true })
            console.log(err);
        })
    }

    render() {

        if (this.state.failed) return <div>
            <UILIB.WarningHolder>Failed to connect the app, please try again</UILIB.WarningHolder>
        </div>

        return <div>
            <UILIB.LoadingIcons iconType="2" className="mar-b15" />
            <h4 className="text-center">Hooking Up the app... Wait a moment</h4>
        </div>
    };


};



import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { inboxTabOptions } from "~/Classes/conversations";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";
import i18n from "~/i18n";
import TierPermissions from "../../../../../../../Classes/tierPermissions";

export default function InboxDropdownMenu({
  activeInbox,
  inboxes,
  setAddingInbox = () => { },
  changeInbox = () => { },
  userRole = null,
}) {
  const history = useHistory();
  const account = useSelector((state) => state.accountMaster);

  return (
    <>
      <UILIB.DropdownMenu.Root>
        <UILIB.DropdownMenu.Trigger
          size="s"
          className={styles.trigger}
        >
          {activeInbox?.name}
        </UILIB.DropdownMenu.Trigger>

        <UILIB.DropdownMenu.Portal>
          <UILIB.DropdownMenu.Content>
            <UILIB.DropdownMenu.Label>{i18n.t("chat:inbox.leftBar.inboxDropdown.inboxes")}</UILIB.DropdownMenu.Label>

            <UILIB.DropdownMenu.RadioGroup value={activeInbox.id}>
              {inboxes.map((inbox) => {
                return (
                  <UILIB.DropdownMenu.RadioItem
                    key={"inbox_" + inbox.id}
                    className={styles.radioItem}
                    value={inbox.id}
                    onClick={() => { changeInbox(inbox.id) }}
                  >
                    <div className={styles.checkboxItemDetail}>
                      <span className={styles.checkboxItemInboxName}>
                        {inbox.name}
                      </span>
                      <span className={styles.checkboxItemOpenCount}>
                        {inbox.openChats} {i18n.t("chat:inbox.leftBar.inboxDropdown.open")}
                      </span>
                    </div>
                  </UILIB.DropdownMenu.RadioItem>
                );
              })}
            </UILIB.DropdownMenu.RadioGroup>

            <UILIB.DropdownMenu.Separator />

            {userRole == 0 && <>
              <UILIB.DropdownMenu.Item onSelect={setAddingInbox}>{i18n.t("chat:inbox.leftBar.inboxDropdown.create")}</UILIB.DropdownMenu.Item>

              <UILIB.DropdownMenu.Separator />

              <UILIB.DropdownMenu.Label>{i18n.t("chat:inbox.leftBar.inboxDropdown.settings")}</UILIB.DropdownMenu.Label>

              {inboxTabOptions.map(({ label, value, tierPermission }) => {
                if (tierPermission) {
                  if (!TierPermissions(tierPermission, account).allowed)
                    return null;
                }
                return (
                  <UILIB.DropdownMenu.Item
                    key={value}
                    onSelect={(e) => {
                      // Needed to prevent memory leak as React will try to close the dropdown after it has become unmounted (following route change). See the onSelect tooltip here for more information https://www.radix-ui.com/primitives/docs/components/dropdown-menu#item.
                      e.preventDefault();
                      history.push(
                        `/cp/conversations/${activeInbox.id}/${value}`
                      );
                    }}
                  >
                    {i18n.t(label)}
                  </UILIB.DropdownMenu.Item>
                );
              })}

              <UILIB.DropdownMenu.Separator />
            </>}

            <UILIB.DropdownMenu.Item
              onClick={() => window.open('https://kb.transpond.io/article/170-conversations-overview', '_blank')}
            >
              Help &amp; Support
            </UILIB.DropdownMenu.Item>

            <UILIB.DropdownMenu.Separator />

            <UILIB.DropdownMenu.Item
              variant="danger"
              onClick={() => history.push(`/cp/home`)}
            >
              <UILIB.Icon name="cross-large" />
              {i18n.t("chat:inbox.leftBar.inboxDropdown.close")}
            </UILIB.DropdownMenu.Item>
          </UILIB.DropdownMenu.Content>
        </UILIB.DropdownMenu.Portal>
      </UILIB.DropdownMenu.Root >
    </>
  );
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UILIB from '~/Common-Objects/Lib'
import NumberFunctions from '~/Classes/numberFormatFunctions'
import helpers from '~/Classes/helpers'
import * as siteMaster from '~/data/actions/siteActions';
import axios from '~/data/http/axios'
import Stats from './stats'
import Topup from './topup'
import AutomationThemeSelector from '~/pages/cp/includes/automations/automationThemeSelector'
import DrawerAddCampaignStep1 from '~/pages/cp/campaigns/add/drawerAddCampaignStep1';

import DrawerAddCustomField from '~/pages/cp/subscribers/customfields/incAddCustomField.jsx'
import LowCreditsSettings from './low'

export default function SmsSetup() {
    const [account, setAccount] = useState(null)
    const [showAutoTopupSettings, setShowAutoTopupSettings] = useState(false)
    const [showTopup, setShowTopup] = useState(false)
    const [showAddCustomField, setShowCustomField] = useState(false)
    const [errors, setErrors] = useState({})
    const [alert, setAlert] = useState('')
    const [customFields, setCustomFields] = useState([]);

    const [validSubscribers, setValidSubscribers] = useState(null)
    const [loading, setLoading] = useState(false)
    const [sendsThisMonth, setSendsThisMonth] = useState(null)
    const [loadingSends, setLoadingSends] = useState(false)
    const [clicksThisMonth, setClicksThisMonth] = useState(null)
    const [loadingClicks, setLoadingClicks] = useState(false)

    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector(state => state.user)

    const getCustomFields = async () => {
        const res = await axios.get('/customfield');
        setCustomFields(res.data.CustomFields.filter(c => c.fieldType === 'TELNO').map(cf => ({
            label: cf.fieldDesc,
            value: cf.id,
            fieldType: cf.fieldType
        })))
    }
    const getAccount = async () => {
        const res = await axios.get('/accountMaster')
        setAccount(res.data.AccountMaster)
        // setShowAutoTopupSettings(false)
        setShowTopup(false)
    }

    const updateOptions = (e) => {
        const options = account.options ? { ...account.options } : {}
        options[e.currentTarget.name] = e.currentTarget.value
        setAccount({ ...account, options })
    }

    const saveAccount = async () => {
        const errors = {}
        if (account.options && account.options.smsDefaultFromName) {
            const subjectChecker = helpers.smsIsValidSubject(options.smsDefaultFromName)
            if (subjectChecker.messages.length) {
                errors.smsDefaultFromName = subjectChecker.messages.join(', ')
            } else {
                const res = await axios.get('/campaign/checksms?content=' + options.smsDefaultFromName)
                if (res.data.length) {
                    errors.smsDefaultFromName = `From Name cannot contain ${res.data.map(d => `"${d}"`).join(' or ')}. `
                }
            }
        }
        setErrors(errors)
        if (!Object.keys(errors).length) {
            try {
                await axios.put('/accountMaster', account)
                setAlert('Changes Saved')
            } catch (e) {
                console.log(e)
                setAlert(e.data?.error || 'Something went wrong')
            }
        }

    }


    const createAutomation = () => {
        const drawerContent = <AutomationThemeSelector scratch={() => history.push('/cp/automation/addnew')} select={theme => {
            axios.post('/automation/theme/' + theme.id).then(response => {
                history.push('/cp/automation/addnew/' + response.data.Automation.id)
            })
        }} />
        dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
    }

    const createCampaign = () => {
        const drawerContent = <DrawerAddCampaignStep1 selectSms={6} history={history} />
        dispatch(siteMaster.alterSiteDrawer(true, false, "left", drawerContent, true, 500, "drawer_nopad"));
    }

    const countSubs = async () => {
        setLoading(true)
        const res = await axios.get('/accountMaster/sms/subscribers?field=-1')
        setValidSubscribers(res.data.count)
        setLoading(false)
    }

    const countSends = async () => {
        setLoadingSends(true)
        const res = await axios.get('/accountMaster/sms/sends')
        setSendsThisMonth(res.data.count)
        setLoadingSends(false)
    }

    const countClicks = async () => {
        setLoadingClicks(true)
        const res = await axios.get('/accountMaster/sms/clicks')
        setClicksThisMonth(res.data.count)
        setLoadingClicks(false)
    }

    const changeLowSettings = async (e) => {
        const value = e.target.value
        const options = account.options ? { ...account.options } : {}
        if (value === 'stop') {
            delete options.smsLowAlertEmail
            options.autoTopupSms = false
        } else if (value === 'email') {
            options.autoTopupSms = false
            options.smsLowAlertEmail = options.smsLowAlertEmail || user.userData.emailAddress
        } else if (value === 'topup') {
            options.autoTopupSms = true
        }
        setAccount({ ...account, options })
    }

    useEffect(() => {
        countSubs()
        countSends()
        countClicks()
        getCustomFields()
        getAccount();
    }, [])

    if (!account) return <UILIB.LoadingIcons iconType="2" />

    const remainingCredits = account.smsCredits || 0
    const options = account.options || {}
    const hasPhoneField = customFields.some(cf => cf.fieldType == "TELNO")

    return (
        <UILIB.Row>


            <UILIB.Column xs={12} md={4}>

                <UILIB.Paper style={{ padding: 23 }}>
                    <div className="text-left mar-b20 text-heavy">SMS Credits Available</div>
                    <div className="quickFlex" style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'baseline' }}>
                        <div className="mar-b15 text-heavy text-left" style={{ fontSize: 40 }}>{NumberFunctions.formatNumber(remainingCredits)}</div>
                        <UILIB.Button className="button-sml secondary mar-r5" text="Top Up" iconLeft={<UILIB.Icons icon='plus' />} onClick={() => setShowTopup(true)} />
                    </div>
                </UILIB.Paper>
            </UILIB.Column>
            <UILIB.Column xs={12} md={8} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', padding: 0 }}>

                <UILIB.Column xs={12} md={4} >
                    <UILIB.Paper style={{ padding: 23 }} >
                        <div className={"mar-b15 text-center text-heavy " + (loadingSends ? 'loading' : '')} style={{ fontSize: 40 }}>{loadingSends ? 'Loading' : sendsThisMonth}</div>
                        <div className="text-sml text-heavy text-center ">Messages Sent</div>
                        <div className="text-sml text-grey text-center ">Last 30 Days</div>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} md={4} >
                    <UILIB.Paper style={{ padding: 23 }} >
                        <div className={"mar-b15 text-center text-heavy " + (loadingClicks ? 'loading' : '')} style={{ fontSize: 40 }}>{loadingClicks ? 'Loading' : clicksThisMonth}</div>
                        <div className="text-sml text-heavy text-center ">Links Clicked</div>
                        <div className="text-sml text-grey text-center ">Last 30 Days</div>
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} md={4} >
                    <UILIB.Paper style={{ padding: 23 }} >
                        <div className={"mar-b15 text-center text-heavy" + (loading ? 'loading' : '')} style={{ fontSize: 40 }}>{loading ? 'Loading' : validSubscribers}</div>
                        <div className="text-sml text-heavy text-center ">SMS Contacts</div>
                        <div className="text-grey text-center" style={{ fontSize: 13 }}>Containing valid phone numbers</div>
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Column>

            <UILIB.Column xs={12} md={4} lg={4} >
                <UILIB.Row>

                    <UILIB.Column xs={12}>
                        <UILIB.Paper>
                            <h4 className="mar-b25">Defaults</h4>

                            <UILIB.TextInput outerClassName="mar-b25" label="Default From Name" value={options.smsDefaultFromName} name="smsDefaultFromName" placeholder="Your Company Name" onChange={updateOptions} error={errors.smsDefaultFromName} />
                            {hasPhoneField && <UILIB.Select explainer="The default custom field when creating a campaign" outerClassName="mar-b25" label="Phone Number Field" data={customFields} value={options.smsDefaultField} name="smsDefaultField" placeholder="Primary Tel" onChange={updateOptions} />}
                            {!hasPhoneField && <UILIB.WarningHolder>
                                <div className="text-red mar-b10">You must have a "Telephone Number" custom field setup before you can use SMS</div>
                                <div><UILIB.Button className="button-sml red" text="Setup a Custom Field" onClick={() => { setShowCustomField(true) }} /></div>
                            </UILIB.WarningHolder>}


                            {(hasPhoneField == true) && <UILIB.Button text="Save Defaults" className="button-primary" onClick={saveAccount} />}
                        </UILIB.Paper>
                    </UILIB.Column>


                    <UILIB.Column xs={12}>
                        <UILIB.Paper className="mar-b0">
                            <h4 className="mar-b25">Low Credit Settings</h4>
                            <LowCreditsSettings account={account} changeLowSettings={changeLowSettings} onChange={updateOptions} saveAccount={saveAccount} />
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
            </UILIB.Column>


            <UILIB.Column xs={12} sm={12} md={8} lg={8}>

                <Stats account={account} />

                <h4 className="mar-b25" >Where to use SMS</h4>

                <UILIB.Row>
                    <UILIB.Column xs={12} md={4}>
                        <UILIB.Paper className="mar-b0 text-center">
                            <h4 className="text-primary text-center mar-b15"><div><UILIB.Icons style={{ borderRadius: '100%', margin: '0 auto', width: 30 }} className='mar-b5' icon="lightning" circle={true} /></div>Automations</h4>
                            <p>Make things happen based on your contact activity.</p>
                            <p>

                                <UILIB.Button text="Create Automation" className="button-sml secondary" onClick={createAutomation} />
                            </p>
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={12} md={4}>
                        <UILIB.Paper className="height-100 mar-b0 text-center">
                            <h4 className="text-primary text-center mar-b15"><div style={{ borderRadius: '100%', margin: '0 auto', width: 30 }} ><UILIB.Icons icon="mobile" className="mar-b5" circle={true} /></div>Campaigns</h4>
                            <p>Create and send SMS campaigns to your contacts en-mass.</p>
                            <p>
                                <UILIB.Button text="Create Campaign" className="button-sml secondary" onClick={createCampaign} />
                            </p>
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={12} md={4}>
                        <UILIB.Paper className="height-100 mar-b0 text-center">
                            <h4 className="text-primary text-center mar-b15"><div style={{ borderRadius: '100%', margin: '0 auto', width: 30 }}><UILIB.Icons icon="save" className="mar-b5" circle={true} /></div>Transactions</h4>
                            <p>Send one off SMS messages from your website, or other external app.</p>
                            <p>
                                <UILIB.Button text="View Documentation" className="button-sml secondary" onClick={() => window.open('/apidocs/81', '_blank')} />
                            </p>
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>

            </UILIB.Column>


            <UILIB.Drawer standalone={true} persist={true} isOpen={showTopup} width={500} showClose={true} startClose={() => setShowTopup(false)}>
                <Topup onClose={getAccount} />
            </UILIB.Drawer>
            <UILIB.Drawer standalone={true} persist={true} isOpen={showAddCustomField} width={500} showClose={true} startClose={() => { setShowCustomField(false); }}>
                <DrawerAddCustomField forceType="TELNO" getCustomFields={() => { setShowCustomField(false); getCustomFields(); }} />
            </UILIB.Drawer>

            <UILIB.SnackBar message={alert} open={!!alert} dismiss={() => setAlert('')} autoclose={true} />
        </UILIB.Row >
    )
}
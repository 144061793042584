import React, { useEffect, useState } from 'react'
import moment from 'moment'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import helpers from '~/Classes/helpers'
import SendPreview from './preview'
import SendAnalysis from './analysis'
import SendStartTime from './startTime'
import SendRecipients from './recipients'
import Summary from './summary'
import SendGroupSubscribers from './subscribers'
import i18n from '~/i18n'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { alterSiteDrawer } from '~/data/actions/siteActions'
import NewUserForm from '~/pages/cp/includes/bb_topNav/newUserForm/newUserForm'
import numberFormatFunctions from '~/Classes/numberFormatFunctions'
import ViewSubs from '../../steps/step1/viewSubs'


export default function SmsSend({ campaign, subscriberCount = 0, sendingLimitReached = false, checkSendingLimit = () => { }, updateCampaign = () => { }, saveCampaign = () => { }, changeStep = () => { } }) {
    const [analysis, setAnalysis] = useState(null)
    const [template, setTemplate] = useState(null)
    const [canStart, setCanStart] = useState(false)
    const [errors, setErrors] = useState([])
    const history = useHistory()
    const siteMaster = useSelector(state => state.siteMaster)
    const accountMaster = useSelector(state => state.accountMaster)
    const dispatch = useDispatch()

    const analyseSend = async () => {
        const res = await axios.get('/campaign/' + campaign.id + '/sms/estimate')
        setAnalysis(res.data)
    }

    const send = async () => {
        if (canStart) {
            try {
                setErrors([])
                await saveCampaign()
                await axios.put('/campaign/' + campaign.id + '/start')
                history.push('/cp/campaigns/add/finished/' + campaign.id)
            } catch (err) {
                setErrors([{ value: err.data.error, click: siteMaster.hidePricing || accountMaster.accountMaster.invoice ? () => { } : () => history.push('/cp/company/subscription') }])
            }
        }
    }

    const save = async (step) => {
        await saveCampaign()
        if (step && parseInt(step)) {
            changeStep(step)
        } else {
            history.push('/cp/campaigns')
        }
    }

    const activate = () => {
        const drawer = <NewUserForm from="campaign" />
        dispatch(alterSiteDrawer(true, true, 'right', drawer, true, '100vw'))
    }

    const viewSubs = () => {
        var drawerContent = <ViewSubs campaign={campaign} segments={campaign.Segments} groups={campaign.Groups} count={subscriberCount} />
        dispatch(alterSiteDrawer(true, true, "right", drawerContent, true, "100%"));
    }

    useEffect(() => {
        analyseSend()
        axios.get('/template/' + campaign.TemplateId).then(res => {
            setTemplate(res.data.template)
        })
    }, [])

    if (!analysis || !template) return <UILIB.LoadingIcons iconType="2" />

    const result = helpers.getSmsCredits(campaign, template)


    return (
        <UILIB.Row>
            <UILIB.Column xs={12} sm={8}>
                <SendStartTime campaign={campaign} updateCampaign={updateCampaign} />
                <SendRecipients campaign={campaign} />
                <SendPreview campaign={campaign} content={template.templateHTML} edit={() => save(2)} />
            </UILIB.Column>

            <UILIB.Column xs={12} sm={4}>
                <SendGroupSubscribers campaign={campaign} subscriberCount={subscriberCount} save={viewSubs} />
                <SendAnalysis campaign={campaign} analysis={analysis} messages={result.messages} />
                <Summary campaign={campaign} messages={result.messages} template={template} subscriberCount={subscriberCount} sendingLimitReached={sendingLimitReached} checkSendingLimit={checkSendingLimit} setCanStart={setCanStart} saveErrors={errors} changeStep={changeStep} />
            </UILIB.Column>


            <UILIB.StickyNavBottom>
                <UILIB.Column xs={12} sm={12} className="text-center">
                    <div className="flexSpaceBetween">
                        <div className='quickFlex'>
                            <UILIB.Button className="mar-r15" onClick={() => save(2)} iconLeft={<UILIB.Icons icon="arrowLeft" />}>Back</UILIB.Button>
                            <UILIB.Button onClick={() => save()}>{i18n.t('campaigns:standard.step3.save')}</UILIB.Button>
                        </div>

                        {accountMaster.accountMaster.approved && <UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon='arrowRight' />} onClick={send} disabled={!canStart}>
                            {moment(campaign.startDate) <= moment() && i18n.t('campaigns:standard.step3.sendImmediate', { total: numberFormatFunctions.formatNumber(subscriberCount) })}
                            {moment(campaign.startDate) > moment() && i18n.t('campaigns:standard.step3.sendSchedule', { total: numberFormatFunctions.formatNumber(subscriberCount) })}
                        </UILIB.Button>}

                        {accountMaster.accountMaster.doneNewUserQuestions && !accountMaster.accountMaster.approved && <div className='quickFlex'>
                            <div className='text-primary text-heavy mar-r10'>Your account must be approved to send campaigns</div>
                            <UILIB.Button className="button-primary" onClick={send} iconRight={<UILIB.Icons icon='arrowRight' />} disabled={true}>{i18n.t('campaigns:standard.step3.sendImmediate')} </UILIB.Button>
                        </div>}
                        {!accountMaster.accountMaster.doneNewUserQuestions && <div className='quickFlex'>
                            <div className='text-orange text-heavy mar-r10'>Your account must be approved to send campaigns</div>
                            <UILIB.Button className="button-orange" onClick={activate}>Request Approval</UILIB.Button>
                        </div>}
                    </div>
                </UILIB.Column>
            </UILIB.StickyNavBottom>
        </UILIB.Row>
    )
}
import React from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import orderBy from 'lodash/orderBy';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import ReactTooltip from 'react-tooltip'
import PermissionChecker from '~/Classes/permissions';
import axios2 from 'axios'
//CONNECT TO STORE
@connect((store) => {
    return { permissionStore: store.permission, user: store.user }
})
export default class AddGroupsPop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupTableData: [],
            loadingData: 1,
            tableOpts:
            {
                sortDirection: "asc",
                sortColumn: "groupName",
                startRecord: 0,
                pageSize: 10,
                searchText: ""
            },
            totalGroups: -1,
            totalSegs: -1,
            checkedBoxes: [],
            sortedData: undefined,
            showSegCounts: true,
            totalContains: 0,
            openFor: 'both'
        }

        this.tableDataGetter = this.tableDataGetter.bind(this);
        this.checkBoxes = this.checkBoxes.bind(this);
        this.changeSearch = this.changeSearch.bind(this);
        this.processData = this.processData.bind(this);
        this.search = this.search.bind(this)
        this.addTempSegment = this.addTempSegment.bind(this);

        this.timer = null;
        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.getGroupSegCounts()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.getGroupSegCounts()
        }
        var pProps = JSON.stringify(prevProps);
        var cProps = JSON.stringify(this.props);
        if (cProps != pProps && this.state.sortedData != undefined) {
            this.processData();
        }
        if (prevProps.openFor != prevState.openFor) {
            this.state.openFor = this.props.openFor
            this.tableDataGetter()
        }
    }

    checkBoxes(event, theId, isGroup, name, queryObj) {
        var isChecked = event.target.checked;
        if (isChecked) {
            this.props.changedAddedGroups(theId, true, isGroup, this.props.included, name, queryObj)
        }
        else {
            this.props.changedAddedGroups(theId, false, isGroup, this.props.included, name)
        }
    }

    search(ev) {
        var tableOpts = this.state.tableOpts;
        tableOpts.searchText = ev.currentTarget.value;
        this.setState({
            tableOpts
        }, () => {
            clearTimeout(this.timer)
            this.timer = setTimeout(this.tableDataGetter, 250)
        })
    }

    changeSearch(theVal) {
        var tableOpts = this.state.tableOpts;
        tableOpts.searchText = theVal;
        this.setState({ tableOpts: tableOpts }, this.tableDataGetter)
    }

    async getGroupSegCounts() {
        let queryStr = '?'
        const [groupCount, segCount] = await Promise.all([axios.get('/group/count' + queryStr), axios.get('/segment/count' + queryStr)])
        const totGroups = Number(groupCount.data.Count);
        var showSegCounts = true;
        if (Number(segCount.data.count) > 40) showSegCounts = false;
        this.setState({ totalGroups: totGroups, totalSegs: Number(segCount.data.count), showSegCounts }, this.tableDataGetter)
    }

    async tableDataGetter(startRecord, sortDirection, sortColumn, pageSize, searchText) {
        var tableOpts = this.state.tableOpts;
        if (!sortColumn) {
            sortColumn = tableOpts.sortColumn;
        }
        var sortColumnSeg = sortColumn;
        if (sortColumn === "groupName") {
            sortColumnSeg = "name";
        }
        if (sortColumn === "subscriberCount") {
            sortColumnSeg = "total";
        }
        if (!sortDirection) {
            sortDirection = tableOpts.sortDirection;
        }
        if (!startRecord) {
            startRecord = tableOpts.startRecord;
        }
        if (!pageSize) {
            pageSize = tableOpts.pageSize;
        }
        if (!searchText) {
            searchText = tableOpts.searchText;
        }

        tableOpts.sortColumn = sortColumn;
        tableOpts.searchText = searchText;
        tableOpts.pageSize = pageSize;
        tableOpts.startRecord = startRecord;
        tableOpts.sortDirection = sortDirection;

        var queryStr = '?sortColumn=' + sortColumn + '&sortOrder=' + sortDirection + '&searchText=' + searchText + '&count=true&status=Active';
        var queryStrSeg = '?sortColumn=' + sortColumnSeg + '&sortOrder=' + sortDirection + '&searchText=' + searchText + '&active=true&basicView=true&notsystem=true&readOnlySegs=false';

        if (this.state.showSegCounts) {
            queryStrSeg += '&count=true'
        }
        else {
            queryStrSeg += '&count=false&simple=true'
        }

        if (this.props.campaign && this.props.campaign.type === 6) {
            if (this.props.campaign.options.smsField) {
                queryStr += ('&smsField=' + this.props.campaign.options.smsField)
                queryStrSeg += ('&smsField=' + this.props.campaign.options.smsField)
            } else {
                queryStr += '&isMobile=true'
                queryStrSeg += '&isMobile=true'
            }
        }

        this.setState({ loadingData: 1, tableOpts: tableOpts });


        var groups = [];
        var segments = [];

        this.source.cancel('Operation canceled by the user.');
        this.source = this.CancelToken.source();
        const results = await Promise.all([
            axios.get('/group' + queryStr, { cancelToken: this.source.token }),
            axios.get('/segment' + queryStrSeg, { cancelToken: this.source.token })
        ])
        groups = results[0].data.Groups
        segments = results[1].data.Segments
        var sortedData = [];


        !!(this.state.openFor == 'segments') && segments.forEach((thisSeg) => {
            if (!thisSeg.readOnly) {
                sortedData.push({
                    groupName: thisSeg.name,
                    id: thisSeg.id,
                    subscriberCount: thisSeg.subscriberCount,
                    isGroup: false,
                    createdAt: thisSeg.createdAt,
                    isChecked: false,
                    contains: thisSeg.contains,
                    queryObj: thisSeg.queryObj,
                    logo: thisSeg.CapsuleId ? results[1].data.logo : '',
                    tooltip: thisSeg.CapsuleId ? 'Capsule Filter' : ''
                })
            } else {
                console.log('ignoring', thisSeg)
            }
        });

        !!(this.state.openFor == 'groups') && groups.forEach((thisGroup) => {
            sortedData.push({ groupName: thisGroup.groupName, id: thisGroup.id, subscriberCount: thisGroup.totalSubscribers, isGroup: true, createdAt: thisGroup.dateCreated, isChecked: false })
        });

        sortedData = orderBy(sortedData, sortColumn, sortDirection);
        this.setState({ sortedData: sortedData }, () => {
            this.processData()
        })
    }

    processData() {
        var finalData = this.state.sortedData.filter(theLine => {
            var foundItem = this.props[theLine.isGroup ? 'groups' : 'segments'].find(theEl => theEl.id === theLine.id)
            if (foundItem) {
                var val = foundItem.include
                if (val === undefined) {
                    if (foundItem.CampaignGroup) val = foundItem.CampaignGroup.include
                    else val = foundItem.CampaignSegment.include
                }
                if (val !== this.props.included) return false;
            }
            return true;
        });


        const totalContains = finalData.reduce((acc, row) => {
            if (row.contains && this.props[row.isGroup ? 'groups' : 'segments'].some(theEl => theEl.id === row.id)) {
                acc += row.contains
            }
            return acc
        }, 0)

        var finalData = finalData.map((theLine, index) => {
            console.log(theLine)
            var groupType = theLine.isGroup ? i18n.t('campaigns:standard.step1.group') : i18n.t('campaigns:standard.step1.segment');
            var isChecked = this.props[theLine.isGroup ? 'groups' : 'segments'].some(theEl => theEl.id === theLine.id)
            const disabled = !isChecked && (totalContains + theLine.contains) > 6

            return {
                checkBox: {
                    headerValue: null,
                    orderBy: false,
                    value: <UILIB.CheckBox
                        checked={isChecked}
                        disabled={disabled}
                        onChange={(event) => this.checkBoxes(event, theLine.id, theLine.isGroup, theLine.groupName, theLine.queryObj)}
                        name={"sub" + index}
                    />,
                    isCheckBox: true,
                    width: 30,
                    isChecked: false,
                    order: 0
                },
                groupName: {
                    headerValue: i18n.t('campaigns:standard.step1.groupName'),
                    value: <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                        <span style={{ top: 5 }}>{theLine.groupName}</span>
                        {!!theLine.logo && <UILIB.Avatar for={theLine.id} style={{ height: 20, width: 20, marginLeft: 10, border: '1px solid grey' }} tooltip={theLine.tooltip} src={theLine.logo} />}
                        {disabled && <UILIB.SquareChip className="mar-l10">Disabled</UILIB.SquareChip>}
                    </div>
                    ,
                    rawVal: theLine.groupName,
                    orderBy: true
                },
                subscriberCount: {
                    headerValue: i18n.t('campaigns:standard.step1.SubscriberCount'),
                    value: <div style={{ textAlign: "center" }}>
                        {(theLine.isGroup == true) && NumberFunctions.formatNumber(theLine.subscriberCount)}
                        {(theLine.isGroup == false) && <span>
                            {(theLine.subscriberCount !== undefined && this.state.showSegCounts) ? NumberFunctions.formatNumber(theLine.subscriberCount) : <div><UILIB.Icons icon="questionCircle" data-tip="the rules in this segment limit live counting" data-for={'segment' + theLine.id} /></div>}
                        </span>}
                    </div >
                    ,
                    rawVal: theLine.subscriberCount,
                    width: 100
                },
                type: {
                    headerValue: i18n.t('campaigns:standard.step1.type'),
                    value: <UILIB.SquareChip small={true} className={theLine.isGroup ? 'square-chip-primary' : 'square-chip-secondary'}>
                        {i18n.t(groupType)}
                    </UILIB.SquareChip>
                    ,
                    width: 100,
                    orderBy: false,
                    align: 'center'
                },
                id: {
                    value: theLine.isGroup ? undefined : theLine.id
                },
                contains: {
                    value: theLine.isGroup || !isChecked ? 0 : theLine.contains
                },
                tooltip: disabled ? {
                    tip: 'Too many CONTAINS rules selected (MAX 6)',
                    for: theLine.isGroup ? 'group_check_' + theLine.id : 'seg_check_' + theLine.id
                } : {}
            }
        })
        this.setState({
            groupTableData: finalData, loadingData: 0, totalContains
        })
    }

    addTempSegment() {
        this.props.addTempSegment(this.props.included);
    }

    setSmsField(e) {
        const options = this.props.campaign.options || {}
        console.log(options)
        options.smsField = e.currentTarget.value
        console.log(options)
        this.props.campaign.options = options
        console.log(this.props.campaign)
        this.props.setCampaign()
        this.tableDataGetter()
    }

    render() {
        var canEditSegments = PermissionChecker('segments', this.props.permissionStore.permissions, "write");

        var buttonClasses = "";
        if (this.props.included === false) {
            buttonClasses += " button-secondary";
        }
        else {
            buttonClasses += " button-primary";
        }

        let smsFields = []
        if (this.props.campaign && this.props.campaign.type === 6 && this.props.customFields && this.props.customFields.length) {
            smsFields = this.props.customFields.filter(cf => cf.fieldType === 'TELNO')
        }

        return <div>
            <h4 className="mar-b10 cyt-inlcuded-drawer">{this.props.openFor == 'groups' ? i18n.t('campaigns:standard.step1.chooseGroups') : i18n.t('campaigns:standard.step1.chooseSegments')}</h4>
            {this.props.included && <div className="mar-b25">{i18n.t('campaigns:standard.step1.chooseIncluded')}</div>}
            {!this.props.included && <div className="mar-b25"> <div dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step1.chooseExcluded') }}></div></div>}
            {this.state.totalGroups === 0 && this.state.totalSegs === 0 && <div>
                <div className="mar-b25">{i18n.t('campaigns:standard.step1.noOption')}</div>
                <UILIB.Button className={buttonClasses} text={i18n.t('campaigns:standard.step1.addAGroup')} onClick={() => this.props.history.push("/cp/subscribers/groups")} />
            </div>}

            {(this.state.totalGroups > 0 || this.state.totalSegs > 0) && <div>
                {console.log(smsFields)}
                {!!smsFields.length && <div className="mar-b25">
                    <UILIB.Select
                        label={"SMS Field"}
                        data={smsFields.map(f => ({ label: f.fieldDesc, value: f.id }))}
                        onChange={this.setSmsField.bind(this)}
                        value={this.props.campaign.options.smsField} />
                </div>}
                <div className="mar-b25">
                    <UILIB.TextInput
                        name="searchText"
                        onChange={this.search}
                        value={this.state.tableOpts.searchText}
                        clickToChangeClicked={this.changeSearch}
                        style={{ paddingRight: "35px" }}
                        placeholder={this.props.openFor == 'groups' ? i18n.t('campaigns:standard.step1.searchGroup') : i18n.t('campaigns:standard.step1.searchSegment')}
                        iconLeft={<UILIB.Icons icon="magnifier" onClick={() => this.changeSearch()} />}
                    />
                </div>
                <div style={{ height: "calc(100vh - 275px)", overflow: "auto" }}>
                    <UILIB.DataTable1
                        noResultsTxt={i18n.t('campaigns:standard.step1.noSearch')}
                        tableData={this.state.groupTableData}
                        loadingData={this.state.loadingData}
                        dataUpdater={this.tableDataGetter}
                        width="95%"
                        pageSize="100"
                        hasCheckBoxes="no"
                        sortedColumn={this.state.tableOpts.sortColumn}
                        sortedDirection={this.state.tableOpts.sortDirection}
                    />
                </div>
                <div className="flexSpaceBetween mar-t10">
                    <UILIB.Button className="button-sml cyt-included-drawer-close" iconRight={<UILIB.Icons icon="cross" />} onClick={this.props.finish}>Close</UILIB.Button>
                    {(this.props.openFor == 'segments') && canEditSegments && <div className="text-sml mar-t15 text-right" style={{ marginBottom: "-20px" }}>
                        {i18n.t('campaigns:standard.step1.or')}
                        <a style={{ textDecoration: "underline" }} onClick={this.addTempSegment}>{i18n.t('campaigns:standard.step1.addSegmentOnlyForThis')} </a>
                    </div>}
                </div>

            </div>}
        </div >

    }
}
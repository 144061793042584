import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';
import { DropTarget, DragSource } from 'react-dnd'
import ItemTypes from '../../../dragDropItemTypes'
import { findDOMNode } from 'react-dom'
import UILIB from '~/Common-Objects/Lib';
import i18n from '~/i18n';

const dragSource = {
    beginDrag(props, monitor) {
        return { path: props.path }
    }
}

const dropSource = {
    drop() {
        return {}
    },
    hover(props, monitor, component) {
        const currentElementType = monitor.getItemType();

        if (monitor.isOver({ shallow: true }) === false) {
            return;
        }

        if (currentElementType === "newItem" && monitor.getItem().isNew === 0) {

            const currentElementPath = monitor.getItem().path;
            const currentElementContent = monitor.getItem().content;
            const hoveredElementPath = props.path;
            if (!currentElementContent.tuuid) {
                currentElementContent.tuuid = DragDropFunctions.createGUID();
            }
            var newIndex = Number(hoveredElementPath.substring(hoveredElementPath.lastIndexOf("[")).replace('[', '').replace(']', '')) + 1;
            var newPath = hoveredElementPath.substring(0, hoveredElementPath.lastIndexOf(".")) + ".elements[" + newIndex + "]"

            monitor.getItem().path = newPath;
            monitor.getItem().isNew = 1;
            props.insertElement(hoveredElementPath, currentElementContent, false);
            console.log("Return Div Item")
            return;

        }
        if (currentElementType === "canvasItem" || (currentElementType === 'newItem' && monitor.getItem().isNew === 1)) {
            const currentElementPath = monitor.getItem().path;
            const hoveredElementPath = props.path;

            if (currentElementPath === hoveredElementPath) {
                return;
            }
            const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top

            const direction = DragDropFunctions.movingUpDown(currentElementPath, hoveredElementPath);

            // Dragging downwards
            if (direction == 1 && hoverClientY < hoverMiddleY) {
                return
            }

            // // Dragging upwards
            if (direction == -1 && hoverClientY > hoverMiddleY) {
                return
            }

            props.moveElement(currentElementPath, hoveredElementPath, false);
            monitor.getItem().path = hoveredElementPath;
        }
    }
}


@DropTarget([ItemTypes.CANVASITEM, ItemTypes.NEWITEM], dropSource, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget()
}))

@DragSource(ItemTypes.CANVASITEM, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview()
}))


export default class TableItemHolder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mouseOver: false,
            hasErrors: false
        }
        this.clickHolder = this.clickHolder.bind(this);
        this.mouseOver = this.mouseOver.bind(this);
        this.mouseOut = this.mouseOut.bind(this);
    }
    clickHolder(event) {
        event.stopPropagation();
        this.props.clickElement(this.props.path)
    }
    mouseOver(event) {
        event.stopPropagation();
        this.setState({ mouseOver: true });
    }
    mouseOut(event) {
        this.setState({ mouseOver: false })
    }

    componentWillReceiveProps(nextProps) {
        var hasErrors = false;
        this.setState({ mouseOver: false })
    }

    render() {

        const { canDrop, isOver, connectDropTarget, connectDragSource, isDragging, moveElement, connectDragPreview } = this.props

        var isSelected = false;
        var isSelectedClass = "";

        if (this.props.selectedElement.path === this.props.path) {
            isSelected = true;
            isSelectedClass = "dd-element-selected";
        }
        if (this.state.mouseOver && isDragging != true) {
            isSelectedClass = "dd-element-selected";
        }
        if (this.props.showGuideLines) {
            isSelectedClass = "showGuideLines " + isSelectedClass
        }
        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "table", this.props.localVariables)
        }

        var thisStyle = {};

        //see if there are any errors preventing save
        var errorResults = DragDropFunctions.checkForErrors(this.props.elementData, false);
        if (errorResults.hasError && errorResults.errorType == 1) {
            thisStyle.border = "2px solid #FF0000";
        }

        var ElementType = "div";
        if (this.props.elementType) {
            ElementType = this.props.elementType;

        }
        var content = "";
        if (this.props.elementData.content) {
            content = this.props.elementData.content.value;

        }

        return connectDropTarget(connectDragPreview(<ElementType className="elementHolder" onClick={this.clickHolder} onMouseOver={this.mouseOver} onMouseOut={this.mouseOut} style={thisStyle}>
            <div className={"elementHolderTools " + isSelectedClass}>
                {isSelected &&
                    <div className="ui-dd-tools ui-dd-tools-top">
                        <div className="ui-dd-tools-icon">
                            <UILIB.Icons icon="copyClipboard"
                                color="white"
                                style={{ width: "18px", height: "18px" }}

                                alt={i18n.t('copy')}
                                title={i18n.t('copy')}
                                onClick={(event) => { event.stopPropagation(); this.props.copyElement(this.props.path) }}
                            />
                        </div>
                        <div className="ui-dd-tools-icon">
                            <UILIB.Icons icon="bin"
                                color="white"
                                style={{ width: "15px", height: "15px" }}

                                alt={i18n.t('delete')}
                                title={i18n.t('delete')}
                                onClick={(event) => { event.stopPropagation(); this.props.deleteElement(this.props.path) }}
                            />
                        </div>

                    </div>
                }
                {isSelected && <div className="ui-dd-tools ui-dd-tools-left" alt="Move" title="Move">
                    {connectDragSource(<div className="ui-dd-tools-icon" >
                        <UILIB.Icons icon="move"
                            color="white"
                            style={{ width: "15px", height: "15px" }}
                            alt="Move"
                            title="Move" />
                    </div>)}
                </div>}

            </div>

            <div {...eleProps} >
                {this.props.children}
                {content && content.length && <div dangerouslySetInnerHTML={{ __html: content }} className="remove-all-styles" onClick={this.clickHolder} />}
            </div>
        </ElementType>
        ))
    }
}



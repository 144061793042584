import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import i18n from '~/i18n'

export default class IncGoDeleteSubs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
            pageLoaded: true
        };

        this.goDeleteSubs = this.goDeleteSubs.bind(this);
    }

    goDeleteSubs() {
        var self = this;
        var theSubs = this.props.subscribers.filter(sub => sub.checkBox.isChecked == true);

        var idsToDelete = theSubs.map((element, index) => {
            return element.subscriberData.id;
        })
        var isBlackList = false;
        if (this.props.isBlackList) isBlackList = true

        this.setState({
            deleting: true,
            pageLoaded: false
        })

        axios.delete('/group/' + self.props.groupId + '/subscriber', {
            data: { ids: idsToDelete, fromBlackList: isBlackList }
        }).then((res) => {
            this.setState({
                deleting: false
            })
            self.props.reloadAfterTickedAndChanged();
        });
    }

    render() {
        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />
        return <div>
            <div className="quickFlex mar-b25">
                <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.goBack}>{i18n.t('goBack')}</UILIB.Hint>
            </div>


            <h4 className="mar-b25">{i18n.t('subscribers:overview.deleteHeader')}</h4>
            <div className="mar-b25">{i18n.t('subscribers:overview.deleteSubHeaderGroup')}</div>

            <UILIB.Button text={this.state.deleting ? i18n.t('deleting') : i18n.t('subscribers:overview.deleteButton')} className={"button-primary " + (this.state.deleting ? 'loading' : '')} onClick={this.goDeleteSubs} disabled={this.state.deleting} />

        </div>

    }
};


import React, { Component, Fragment } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';
import LandingPage from '../../../company/landing/landingPage'

@connect((store) => {
    return { accountMaster: store.accountMaster, permissionStore: store.permission, siteMaster: store.siteMaster }
})

class GroupLandingPages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [
                { name: i18n.t('company:landing.unsubscribeLabel'), desc: i18n.t('company:landing.unsubscribeText'), lookup: 'unsubscribeTemplateId', default: 'unsubscribeDefaultPage', id: 21966, type: 'unsubscribe' },
                { name: i18n.t('company:landing.updateLabel'), desc: i18n.t('company:landing.unsubscribeText'), lookup: 'updateTemplateId', default: 'updateDefaultPage', id: 21972, type: 'update' }
            ],
            group: undefined,
        }

        this.toggle = this.toggle.bind(this)
        this.getGroup = this.getGroup.bind(this)
    }

    componentDidMount() {
        this.getGroup()
    }

    getGroup() {
        axios.get('/group/' + this.props.match.params.groupID + '?skipCount=true').then(response => {
            this.setState({
                group: response.data.Group
            })
        })
    }

    toggle(ev) {
        if (!this.state.group.options) this.state.group.options = {}
        this.state.group.options.useCustomLanding = ev.target.checked
        this.setState({
            group: this.state.group
        })
        axios.put('/group/' + this.props.match.params.groupID + '/options', this.state.group.options)
    }

    render() {
        if (!this.state.group) return <UILIB.LoadingIcons iconType="2" />
        var options = this.state.group.options || {}
        return (
            <div>

                {this.props.siteMaster.siteId == 1 &&
                    <UILIB.Hint
                        className="hide-xs mar-l0 mar-b25"
                        style={{ width: 'fit-content' }}
                        iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}
                    >
                        Create custom unsubscribe and profile update landing pages.
                    </UILIB.Hint>
                }


                <div className="mar-b25" dangerouslySetInnerHTML={{ __html: i18n.t('subscribers:landing.account', { sitename: this.props.siteMaster.siteName }) }}></div>

                <div className="quickFlex mar-b25">
                    <div className="mar-r10">{i18n.t('subscribers:landing.toggle')}</div>
                    <UILIB.Toggle onChange={this.toggle} checked={options.useCustomLanding}></UILIB.Toggle>
                </div>
                {
                    options.useCustomLanding && <Fragment>
                        <UILIB.Paper>
                            <UILIB.Row>
                                {this.state.tableData.map((template, index) => {
                                    return <LandingPage key={"lp_" + index} template={template} history={this.props.history} group={this.state.group} />
                                })}
                            </UILIB.Row>
                        </UILIB.Paper>
                    </Fragment>
                }
            </div >
        );
    }
}

export default GroupLandingPages;
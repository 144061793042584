import React, { useState } from 'react'
import UILIB from '~/Common-Objects/Lib';
const tagTypes = [{ label: 'Contact/Organisation Tags', value: 0 }, { label: 'Link Tags', value: 1 }]
const explainers = [
    'Bound to contacts and organisations',
    'You can tag links to see which types of links contacts click'
]

export default function AddTag({ tag, defaultTagType, onConfirm = () => { }, onCancel = () => { } }) {
    const [name, setName] = useState(tag ? tag.tagName : '')
    const [tagType, setTagType] = useState(defaultTagType || 0)
    const [error, setError] = useState('')

    const save = async () => {
        if (!name || name.length < 3) {
            setError('tag names must be at least 3 characters long')
        } else {
            setError('')
        }
        try {
            await onConfirm(name, tagType)
        } catch (e) {
            console.log(e)
            setError(e.data.error)
        }
    }

    return (
        <div>
            <h4 className="mar-b25">{tag ? 'Edit' : 'Add'} Tag</h4>

            <UILIB.TextInput outerClassName="mar-b25" value={name} onEnterPress={save} focus onChange={e => setName(e.target.value)} error={error} label="Tag Name" />
            {!tag && <UILIB.Select outerClassName="mar-b25" value={tagType} data={tagTypes} onChange={e => setTagType(e.target.value)} explainer={explainers[tagType]} label="Tag Type" />}

            <div className="row">
                <div className="col-xs-6">
                    <UILIB.Button text={tag ? "Update" : "Create"} className="button-primary button-fullwidth" onClick={save} />
                </div>
                <div className="col-xs-6">
                    <UILIB.Button text="Cancel" className="button-fullwidth" onClick={onCancel} />
                </div>
            </div>
        </div>
    )
}
import React from 'react'
require('~/stylesheets/genericpagenotfound.css');
import UILIB from '~/Common-Objects/Lib.jsx';
import Man404 from '~/assets/images/genericpagenotfound/404guy.png'

export default class NotFoundOutPut extends React.Component {
    constructor(props) {
        super(props);
        this.tryAgain = this.tryAgain.bind(this);
    }

    tryAgain() {
        this.props.history.goBack();
    }

    render() {
        return <div className="container-fluid">
            <UILIB.Row className="center-xs middle-xs around-xs" style={{ height: "100vh" }}>
                <UILIB.Column>
                    <UILIB.Paper>
                        <UILIB.Row>
                            <UILIB.Column xs={12}>
                                <img src={Man404} width="200" />
                            </UILIB.Column>
                            <UILIB.Column xs={12}>
                                <div className="mar-b25 text-sxl">Oh No!</div>
                                <div className='mar-b25 text-lg2'>We can't find this page</div>
                            </UILIB.Column>
                            <UILIB.Column xs={12}>
                                <UILIB.Button text="Take me Back!" onClick={this.tryAgain} />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
        </div>
    }
};
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
@connect((store) => {
    return { user: store.user }
})
export default class IncGoUnsubscribe extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
        };

        this.unsubscribe = this.unsubscribe.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    unsubscribe() {
        var subscriberID = this.props.subscriberID;
        var self = this;

        var self = this;
        var data = {
            ids: [subscriberID],
        }
        if (this.props.fromBlackList) data.fromBlackList = true;
        axios.delete('/group/' + this.props.groupID + '/subscriber', { data }).then((data) => {
            self.props.removedSubscriber("group");
            self.closeDrawer();
        })
    }

    render() {

        return <div>

            <h4 className="mar-b25">{i18n.t('subscribers:remove.header')}</h4>
            <div className="mar-b25">{i18n.t('subscribers:remove.subHeader')}</div>

            <UILIB.Button text={i18n.t('subscribers:remove.button')} className="button-primary" onClick={this.unsubscribe} />

        </div>
    }
};


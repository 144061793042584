import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import UILIB from "~/Common-Objects/Lib";
import SettingsSection from "../../../components/settingsSection";
import TextInput from "../../../components/textInput";
import Button from "../../../components/button";
import * as styles from "./index.module.scss";
import Header from "../../../components/header";
import i18n from "~/i18n";

export default function WebchatChannelVisibility({
  channel, onUpdateChannel, inbox
}) {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [exclusions, setExclusions] = useState([]);
  const [inclusions, setInclusions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const inc = channel?.settings?.inclusions?.map(m => { return { value: m, errored: false } }) || [];
    const exc = channel?.settings?.exclusions?.map(m => { return { value: m, errored: false } }) || [];
    setExclusions(exc);
    setInclusions(inc);
    setLoading(false);
  }, []);

  //exclusions
  function addExclusion() {
    setExclusions([...exclusions, { value: "", errored: false }]);
  }
  function updateExclusion(e, index) {
    const updated = exclusions.map((exclusion, i) => {
      if (index === i) {
        return { value: e.target.value, errored: false };
      }

      return exclusion;
    });

    setExclusions(updated);
  }
  function deleteExclusion(index) {
    const filtered = exclusions.filter((_, i) => i !== index);
    setExclusions(filtered);
  }

  //inclusions
  function addInclusion() {
    setInclusions([...inclusions, { value: "", errored: false }]);
  }
  function updateInclusion(e, index) {
    const updated = inclusions.map((inclusion, i) => {
      if (index === i) {
        return { value: e.target.value, errored: false };
      }
      return inclusion;
    });
    setInclusions(updated);
  }
  function deleteInclusion(index) {
    const filtered = inclusions.filter((_, i) => i !== index);
    setInclusions(filtered);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitting(true);

    if (checkUrlsHaveIssues(inclusions, 0) || checkUrlsHaveIssues(exclusions, 1)) {
      dispatch(alterSnackbar(true, i18n.t("chat:channel.chat.visibility.errorSaving")));
      setSubmitting(false);
      return;
    }

    const data = {
      settings: {
        exclusions: exclusions.filter((exclusion) => exclusion !== "").map(m => m.value),
        inclusions: inclusions.filter((inclusion) => inclusion !== "").map(m => m.value)
      }
    };

    try {
      await onUpdateChannel(data);
      dispatch(alterSnackbar(true, i18n.t("chat:channel.chat.visibility.saved")));
    } catch (error) {
      dispatch(handleGenericError(error));
    } finally {
      setSubmitting(false);
    }
  }

  const checkUrlsHaveIssues = (objectToCheck, objType) => {
    let failed = false;
    for (let i = 0; i < objectToCheck.length; i++) {
      let obj = objectToCheck[i];
      let url = obj.value;
      if (url.startsWith("http://") || url.startsWith("https://")) {
        failed = true;
        obj.errored = i18n.t("chat:channel.chat.visibility.errorUrlIncludesDomain");
      }
      else if (url.trim().length < 1) {
        failed = true;
        obj.errored = i18n.t("chat:channel.chat.visibility.errorUrlEmpty");
      }
    }
    if (objType === 0) setInclusions([...objectToCheck]);
    if (objType === 1) setExclusions([...objectToCheck]);
    return failed;
  };

  if (loading) return null;

  return (
    // Purposely not putting the onSubmit on the form as it feels weird to have enter submit this form.
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
      <Header type="webchat" option="visibility" />
      <form className={styles.root}>

        <SettingsSection heading={i18n.t("chat:channel.chat.visibility.inclusions")} subheading={i18n.t("chat:channel.chat.visibility.inclusionsDesc")}>
          {inclusions.map((inclusion, index) => {
            return (
              <div key={index} className={styles.inputWrapper}>
                <TextInput
                  className="width-100"
                  placeholder="/campaign/*"
                  value={inclusion.value}
                  onChange={(e) => updateInclusion(e, index)}
                  error={inclusion.errored}
                />

                <Button
                  variant="ghost"
                  size="s"
                  iconOnly
                  aria-label="Delete"
                  onClick={() => deleteInclusion(index)}
                >
                  <UILIB.Icon name="trash" />
                </Button>
              </div>
            );
          })}

          <Button
            variant="secondary"
            size="s"
            iconLeft={<UILIB.Icon name="plus" />}
            onClick={addInclusion}
          >
            {i18n.t("chat:channel.chat.visibility.addInclusion")}
          </Button>
        </SettingsSection>
        <hr />
        <SettingsSection heading={i18n.t("chat:channel.chat.visibility.exclusions")} subheading={i18n.t("chat:channel.chat.visibility.exclusionsDesc")}>
          {exclusions.map((exclusion, index) => {
            return (
              <div key={index} className={styles.inputWrapper}>
                <TextInput
                  className="width-100"
                  placeholder="/campaign/*"
                  value={exclusion.value}
                  onChange={(e) => updateExclusion(e, index)}
                  error={exclusion.errored}
                />

                <Button
                  variant="ghost"
                  size="s"
                  iconOnly
                  aria-label="Delete"
                  onClick={() => deleteExclusion(index)}
                >
                  <UILIB.Icon name="trash" />
                </Button>
              </div>
            );
          })}

          <Button
            variant="secondary"
            size="s"
            iconLeft={<UILIB.Icon name="plus" />}
            onClick={addExclusion}
          >
            {i18n.t("chat:channel.chat.visibility.add")}
          </Button>
        </SettingsSection>

        <hr />
        <Button
          className={styles.submitButton}
          type="submit"
          size="s"
          loading={submitting}
          onClick={handleSubmit}
        >
          {i18n.t("chat:channel.chat.visibility.save")}
        </Button>
      </form>
    </div>
  );
}

import React from 'react';

export default class IconsNotEquals extends React.Component {


    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.16663 7.5H15.8333" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.16663 12.5H15.8333" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.8333 4.16669L4.16663 15.8334" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    }
}
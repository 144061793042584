import React from 'react'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'

export default class ButtonSimple extends React.Component {

    render() {

        let className = "buttonSimple";
        if (this.props.selected) className += " buttonSimple-selected"
        if (this.props.className) className += " " + this.props.className;

        let iconLeft = "";
        if (this.props.iconLeft) {
            let iconLeftClass = "buttonsimple-iconLeft";
            if (!this.props.children) iconLeftClass += " mar-r0"
            iconLeft = <div className={iconLeftClass} style={this.props.iconLeftStyle}>{this.props.iconLeft}</div>
        }

        let iconRight = "";
        if (this.props.iconRight) {
            let iconRightClass = "buttonsimple-iconRight";
            if (!this.props.children) iconRightClass += " mar-l0"
            iconRight = <div className={iconRightClass} style={this.props.iconRightStyle}>{this.props.iconRight}</div>
        }

        const child = <>
            {this.props.tooltip && <ReactTooltip effect='solid' className={"tooltip"} id={'toolTip_' + this.props.toolTipFor} >{this.props.tooltip}</ReactTooltip>}
            {iconLeft}
            {this.props.children}
            {iconRight}
        </>

        const props = {
            className,
            id: this.props.id,
            onClick: this.props.onClick,
            'data-tip': true,
            'data-for': 'toolTip_' + this.props.toolTipFor,
        }

        if (this.props.to) {
            return <Link to={this.props.to} style={{ textDecoration: 'none' }} {...props}>
                {child}
            </Link>
        } else {
            return <div {...props}>
                {child}
            </div>
        }


    }
}


import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

export default function SmsSendRecipients({ campaign }) {
    const groupsSendingTo = campaign.Groups.filter(row => row.include).map(g => { return { id: "g_" + g.id, type: 0, name: g.name } })
    const segsSendingTo = campaign.Segments.filter(row => row.include).map(g => { return { id: "g_" + g.id, type: 1, name: g.name } })
    const groupsNotSendingTo = campaign.Groups.filter(row => !row.include).map(g => { return { id: "g_" + g.id, type: 0, name: g.name } })
    const segsNotSendingTo = campaign.Segments.filter(row => !row.include).map(g => { return { id: "g_" + g.id, type: 1, name: g.name } })

    const groupsSegsSendingTo = groupsSendingTo.concat(segsSendingTo);
    const groupsSegsNotSendingTo = groupsNotSendingTo.concat(segsNotSendingTo);

    return (
        <UILIB.Paper className="mar-b20">
            <h5 className='mar-b10'>{i18n.t('campaigns:standard.step3.sendToAudience')}</h5>

            {(groupsSegsSendingTo && groupsSegsSendingTo.length > 0) && <UILIB.Row>
                <UILIB.Column xs={12} md={6} lg={6}>
                    <div className='mar-b5 text-heavy'>Groups Included</div>
                    {groupsSegsSendingTo.map((row, index) => {
                        if(row.type == 0) return <UILIB.SquareChip key={"gKey_" + index} tooltip={(row.type == 0 ? "Group: " : "Segment: ") + row.name} for={row.name} value={row.name} className={"square-chip-large mar-r5 mar-b5 square-chip-green"} />
                    })}

                </UILIB.Column>
                <UILIB.Column xs={12} md={6} lg={6}>
                    <div className='mar-b5 text-heavy'>Segments Included</div>
                    {groupsSegsSendingTo.map((row, index) => {
                        if(row.type != 0) return <UILIB.SquareChip key={"gKey_" + index} tooltip={(row.type == 0 ? "Group: " : "Segment: ") + row.name} for={row.name} value={row.name} className={"square-chip-large mar-r5 mar-b5 square-chip-purple"} />
                    })}
                </UILIB.Column>
            </UILIB.Row>}

            {/* {groupsSegsSendingTo.map((row, index) => {
                return <UILIB.SquareChip key={"gKey_" + index} tooltip={(row.type == 0 ? "Group: " : "Segment: ") + row.name} for={row.name} value={row.name} className={"square-chip-large mar-r5 " + (row.type == 0 ? 'square-chip-green' : 'square-chip-purple')} />
            })} */}

            {(groupsSegsNotSendingTo && groupsSegsNotSendingTo.length > 0) && <UILIB.Row className='mar-t25'>
                <UILIB.Column xs={12} md={6} lg={6}>
                    <div className='mar-b5 text-heavy'>Groups Excluded</div>
                    {groupsSegsNotSendingTo.map((row, index) => {
                        if(row.type == 0) return <UILIB.SquareChip key={"gKey_" + index} tooltip={(row.type == 0 ? "Group: " : "Segment: ") + row.name} for={row.name} value={row.name} className={"square-chip-large mar-r5 mar-b5 square-chip-red"} />
                    })}

                </UILIB.Column>
                <UILIB.Column xs={12} md={6} lg={6}>
                    <div className='mar-b5 text-heavy'>Segments Excluded</div> 
                    {groupsSegsNotSendingTo.map((row, index) => {
                        if(row.type != 0) return <UILIB.SquareChip key={"gKey_" + index} tooltip={(row.type == 0 ? "Group: " : "Segment: ") + row.name} for={row.name} value={row.name} className={"square-chip-large mar-r5 mar-b5 square-chip-orange"} />
                    })}
                </UILIB.Column>

            </UILIB.Row>}


            {/* {(groupsSegsNotSendingTo && groupsSegsNotSendingTo.length > 0) && <div className="mar-t25">
                <h4 className='mar-b10'>{i18n.t('campaigns:standard.step3.notSendToGroups')}</h4>
                {groupsSegsNotSendingTo.map((row, index) => {
                    return <UILIB.SquareChip key={"gKey_" + index} tooltip={(row.type == 0 ? "Group: " : "Segment: ") + row.name} for={row.name} value={row.name} className={"square-chip-large mar-r5 " + (row.type == 0 ? 'square-chip-red' : 'square-chip-orange')} />
                })}
            </div>} */}
        </UILIB.Paper>
    )
}
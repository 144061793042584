import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import moment from 'moment'
import Step from './step'

import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import SiteVars from '~/Classes/siteVars';

import DrawerEditGroup from './drawerEditGroup'
import DrawerAddStep from './drawerAddStep'
import DrawerAddAction from './drawerAddAction'
import DrawerEditStep from './drawerEditStep'
import DrawerEditAction from './drawerEditAction'
import DrawerDeleteConfirm from './drawerDeleteConfirm';

import queryString from 'query-string';

import TemplateImageLoader from './templateImageLoader'
import EmailPreviewer from '~/pages/cp/includes/emailPreviewer/emailPreviewer'

import Options from './options'
import IntegrationActions from './integrationActions'
var moduleDefinitions = require('./stepDefinitions')
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})


class AutomationCampaignWizard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            groups: [],
            segments: [],
            campaign: {},
            errors: [],
            drawerOpen: false,
            actions: moduleDefinitions.actions,
            triggers: moduleDefinitions.triggers,
            options: {},
            timeOptions: [],
            dayOptions: [],
            editingName: false
        }

        this.load = this.load.bind(this)
        this.save = this.save.bind(this)
        this.loadGroups = this.loadGroups.bind(this)
        this.loadSegments = this.loadSegments.bind(this)
        this.loadCustomFields = this.loadCustomFields.bind(this)
        this.selectGroup = this.selectGroup.bind(this)
        this.selectSegment = this.selectSegment.bind(this)
        this.addStep = this.addStep.bind(this)
        this.addAction = this.addAction.bind(this);
        this.addedStep = this.addedStep.bind(this);
        this.editStep = this.editStep.bind(this);
        this.deleteStep = this.deleteStep.bind(this)
        this.updateStep = this.updateStep.bind(this)
        this.rename = this.rename.bind(this)
        this.updateName = this.updateName.bind(this)
        this.finishRename = this.finishRename.bind(this)
        this.exit = this.exit.bind(this)
        this.start = this.start.bind(this)
        this.updateErrors = this.updateErrors.bind(this)
        this.editGroup = this.editGroup.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.editAction = this.editAction.bind(this);
        this.checkQueryString = this.checkQueryString.bind(this);
        this.openEmailPreview = this.openEmailPreview.bind(this);
        this.showOptions = this.showOptions.bind(this)
        this.loadIntegrations = this.loadIntegrations.bind(this);
    }

    componentDidMount() {
        var timeOptions = []
        var time = moment('00:00', 'HH:mm')
        while (true) {
            timeOptions.push(time.format('HH:mm'))
            time.add(10, 'minutes')
            if (!time.isSame(moment(), 'day')) break;
        }

        var dayOptions = []
        var day = moment().day("Monday")
        for (let i = 0; i < 7; i++) {
            dayOptions.push(day.format('dddd'))
            day.add(1, 'day')
        }

        var automationId = this.props.match.params.automationId
        this.setState({
            automationId,
            timeOptions,
            dayOptions
        }, this.load)
    }

    updateErrors(index, errs) {
        var errors = this.state.errors
        errors[index] = errs
        this.setState({
            errors
        })
    }

    load() {
        if (this.state.automationId) {
            axios.get('/automation/' + this.state.automationId).then(response => {
                var automation = response.data.Automation
                if (!automation.SegmentId) automation.SegmentId = undefined
                if (!automation.GroupId) automation.GroupId = undefined
                if (automation.steps && automation.steps.length) {
                    automation.steps.forEach(step => {
                        if (step.CampaignId) {
                            var campaign = automation.Campaigns.find(c => c.id === step.CampaignId)
                            delete campaign.templateHtml
                            step.campaign = campaign
                        }
                    })
                }

                this.setState({
                    automation: automation
                }, this.loadGroups)
            })
        } else {
            axios.post('/automation', {
                name: 'New Automation',
                status: 'Draft'
            }).then(response => {
                var automation = response.data.Automation
                this.setState({
                    automation: response.data.Automation,
                    automationId: response.data.Automation.id
                }, this.loadGroups)
            })
        }
    }

    save(silent) {

        if (!silent) this.setState({
            alertMessage: 'Saving',
            alertOpen: true
        })
        return axios.put('/automation/' + this.state.automationId, this.state.automation).then(response => {
            if (!silent) this.setState({
                alertMessage: 'Saved',
                alertOpen: true
            })
            return response
        })
    }

    loadGroups() {
        axios.get('/group?hideUsers=true&limit=1000').then(response => {
            this.setState({
                groups: response.data.Groups
            }, this.loadSegments)
        })
    }

    loadSegments() {
        axios.get('/segment?recordLimit=1000').then(response => {
            this.setState({
                segments: response.data.Segments
            }, this.loadCustomFields)
        })
    }

    loadCustomFields() {
        axios.get('/customfield').then(response => {
            this.setState({
                customFields: response.data.CustomFields.filter(c => !c.hidden)
            }, this.loadIntegrations)
        })
    }
    loadIntegrations() {

        axios.get('/accountMaster/application').then(_res => {
            this.setState({
                integrations: _res.data.Applications
            }, this.checkQueryString());
        })
    }

    checkQueryString() {
        const query = queryString.parse(this.props.location.search);
        var isReselect = query.reselect;
        var thisStepIndex = query.step;
        if (isReselect && thisStepIndex != undefined) {
            var thisStep = this.state.automation.steps[thisStepIndex];
            this.editAction(thisStep, thisStepIndex, true)
        }
        this.setState({ loaded: true })
    }
    rename() {
        this.setState({
            editingName: true
        })
    }

    updateName(ev) {
        var automation = this.state.automation;
        automation.name = ev.currentTarget.value;
        this.setState({
            automation
        })
    }

    finishRename() {
        this.setState({
            editingName: false
        }, this.save)
    }

    selectGroup(groupId) {
        var automation = this.state.automation
        automation.GroupId = groupId

        this.setState({
            automation
        })
    }

    selectSegment(segmentId) {
        var automation = this.state.automation
        automation.SegmentId = segmentId
        this.setState({
            automation
        })
    }


    addStep() {
        var automation = this.state.automation
        var group = this.state.groups.find(g => g.id == automation.GroupId) || {}
        var newAutomation = {
            delay: {
                value: 0,
                unit: 'hours',
                after: 1
            },
            sendTime: moment().startOf('hour').format('HH:mm'),
            customFieldId: 0,
            hits: 0,
            link: "",
            campaign: { fromName: group.defaultFromName, fromAddress: group.defaultFromEmailAddress, replyAddress: group.defaultReplyAddress }
        }

        var previousStep = undefined;

        if (this.state.automation.steps.length > 0) {
            previousStep = this.state.automation.steps[this.state.automation.steps.length - 1];
        }
        var drawerContent = <DrawerAddStep newAutomation={newAutomation} selectTrigger={this.addAction} finished={this.closeDrawer} triggers={this.state.triggers} customFields={this.state.customFields} history={this.props.history} previousStep={previousStep} />;
        this.setState({ drawerOpen: true }, () => {
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px", undefined, this.closeDrawer));
        });

    }

    addAction(newAutomation) {
        var previousStep = undefined;
        if (this.state.automation.steps.length > 0) {
            previousStep = this.state.automation.steps[this.state.automation.steps.length - 1];
        }
        var automationIndex = this.state.automation.steps.length;
        var drawerContent = <DrawerAddAction automationIndex={automationIndex} newAutomation={newAutomation} addedStep={this.addedStep} triggers={this.state.triggers} actions={this.state.actions} groups={this.state.groups} customFields={this.state.customFields} history={this.props.history} groupId={this.state.automation.GroupId} automationId={this.state.automationId} automationName={this.state.automation.name} previousStep={previousStep} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px", undefined, this.closeDrawer));
    }

    addedStep(newAutomation, dontCloseDrawer, goSave, automationDataId) {
        var theAutomation = this.state.automation;
        if (automationDataId != undefined) {
            delete newAutomation.needWizard;
            theAutomation.steps[automationDataId] = newAutomation;
        }
        else {
            theAutomation.steps.push(newAutomation);
        }
        this.setState({
            automation: theAutomation
        })
        if (!dontCloseDrawer) { this.closeDrawer() }
        if (goSave) {
            this.save()
        }
    }

    editStep(step, index, fromWizard) {

        var drawerContent = "";
        var currTrigger = this.state.triggers.initial;
        var previousStep = undefined;
        if (index > 0) {
            var previousStep = this.state.automation.steps[index - 1];
            if (this.state.triggers[previousStep.action]) {
                currTrigger = this.state.triggers[previousStep.action];
            }
        }
        var automationDataId = index;

        var currentTrigger = currTrigger.find(theTrig => theTrig.value == step.trigger);
        var drawerContent = <DrawerEditStep newAutomation={step} customFields={this.state.customFields} currentTrigger={currentTrigger} history={this.props.history} updateTrigger={this.editAction} automationDataId={automationDataId} previousStep={previousStep} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px", undefined, this.closeDrawer));
    }

    editAction(step, index, changedTemplate) {
        var currTrigger = this.state.triggers.initial;
        var previousStep = undefined;
        if (index > 0) {
            var previousStep = this.state.automation.steps[index - 1];
            if (this.state.triggers[previousStep.action]) {
                currTrigger = this.state.triggers[previousStep.action];
            }
        }

        var currentTrigger = currTrigger.find(theTrig => theTrig.value == step.trigger);

        var currAction = this.state.actions.find(theAct => theAct.value == step.action);
        var drawerContent = <DrawerEditAction automationIndex={index} automationDataId={index} newAutomation={step} updatedStep={this.addedStep} triggers={this.state.triggers} groups={this.state.groups} customFields={this.state.customFields} history={this.props.history} groupId={this.state.automation.GroupId} automationId={this.state.automationId} automationName={this.state.automation.name} currAction={currAction} changingEmailTemplate={changedTemplate} currentTrigger={currentTrigger} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px", undefined, this.closeDrawer));
    }


    editGroup() {
        var drawerContent = <DrawerEditGroup groupId={this.state.automation.GroupId} segmentId={this.state.automation.SegmentId} groups={this.state.groups} selectGroup={this.selectGroup} segments={this.state.segments} selectSegment={this.selectSegment} finished={this.closeDrawer} history={this.props.history} />;
        this.setState({ drawerOpen: true }, () => {
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px", undefined, this.closeDrawer));
        });

    }

    closeDrawer(openTemplateEditor) {
        this.setState({ drawerOpen: false })
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
    }

    updateStep(index, field, ev, cb) {
        var automation = this.state.automation
        if (field && ev) {
            var step = automation.steps[index] || {}
            step.update = Date.now()
            var split = field.split('.')
            split.forEach((prop, index) => {
                if (index < split.length - 1)
                    step = step[prop]
            })
            step[split[split.length - 1]] = ev.currentTarget.value
        }
        this.setState({
            automation
        }, () => {
            if (cb && typeof cb === 'function') {
                cb()
            }
        })
    }

    deleteStepWarn(index) {
        var drawerContent = <DrawerDeleteConfirm onConfirmDelete={() => { this.deleteStep(index) }} onDontDelete={this.closeDrawer} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "400px", undefined));
    }

    deleteStep(index) {
        var automation = this.state.automation
        automation.steps.splice(index)
        this.setState({
            automation
        }, this.closeDrawer);
    }

    exit() {
        this.save().then(() => {
            this.props.history.push('/cp/automation')
        })
    }

    openEmailPreview(templateId) {
        var drawerContent = <EmailPreviewer templateId={templateId} history={this.props.history} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
    }

    start() {
        this.save().then(() => {
            axios.put('/automation/' + this.state.automationId + '/start').then(() => {
                this.props.history.push('/cp/automation')
            })
        })
    }

    integrationActions(index) {
        var drawerContent = <IntegrationActions step={this.state.automation.steps[index]} apps={this.state.integrations} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw'));
    }

    checkTemplateImageExists(imageUrl) {
        if (this.state.templateImageLoaded) return;
        var self = this;
        var img = new Image();
        img.onload = function () { self.setState({ templateImageLoaded: true }) };
        img.onerror = function () { setTimeout(self.checkTemplateImageExists, 1000); };
        img.src = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + this.props.step.campaign.Template.id + ".jpg";
    }

    showOptions() {
        var drawerContent = <Options history={this.props.history} options={this.state.automation.options || {}} save={options => {
            this.closeDrawer()
            var automation = this.state.automation
            automation.options = options
            this.setState({
                automation
            }, this.save)

        }} cancel={this.closeDrawer} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "400px", undefined));
    }


    render() {
        if (!this.state.loaded) return <div className="mar-t40"><UILIB.LoadingIcons iconType="2" /></div>

        var hasErrors = false
        var stepsCompleted = this.state.automation.steps.length;
        var stepsCompletedData = this.state.automation.steps.filter((theStep) => {
            if (theStep.needWizard && theStep.needWizard == false) return true;
            if (!theStep.needWizard) return true;
            return false
        });

        if (!stepsCompletedData) stepsCompleted = 0;
        if (stepsCompletedData) stepsCompleted = stepsCompletedData.length
        if (stepsCompleted != this.state.automation.steps.length || !this.state.automation.GroupId || this.state.automation.steps.length == 0) hasErrors = true;
        var foundFirstNeedWizard = false;

        return (
            <div>
                <UILIB.Row className="mar-t30 mar-b10">
                    <UILIB.Column xs={12} sm={12} md={8} lg={6} margin={0} className="col-md-offset-2 col-lg-offset-3 middle-xs">
                        <UILIB.Row className="middle-xs">
                            <UILIB.Column xs={12} sm={12} md={6}>
                                {!this.state.editingName && <h4 className="mar-b25">{this.state.automation.name}</h4>}
                                {this.state.editingName && <UILIB.TextInput className="txtInput" value={this.state.automation.name} onChange={this.updateName} withIcon={"icon-check"} onEnterPress={this.finishRename} iconClick={this.finishRename} />}
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={12} md={6} className="end-xs">
                                <UILIB.Button text="edit name" className="outline primary mar-r10" onClick={() => this.setState({ editingName: true })} />
                                <UILIB.Button text="options" className="outline grey" onClick={this.showOptions} />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Column>
                </UILIB.Row>

                <UILIB.Row>
                    <UILIB.Column xs={12} sm={12} md={8} lg={6} margin={0} className="col-md-offset-2 col-lg-offset-3">
                        <UILIB.Paper>
                            <UILIB.Row>
                                <UILIB.Column xs={12} sm={12} md={1} margin={0}>
                                    <div className="step-circle primary">
                                        <span className="text-primary">1</span>
                                    </div>
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={9} margin={0}>
                                    {!this.state.automation.GroupId &&
                                        <div>
                                            <UILIB.Row>
                                                <UILIB.Column xs={12} margin={0}>
                                                    <h4>Let get started!</h4>
                                                </UILIB.Column>
                                            </UILIB.Row>
                                            <UILIB.Row>
                                                <UILIB.Column xs={12} margin={0}>
                                                    <p className="no-marg">Which group or segment of contact does this Automation relate to?</p>
                                                </UILIB.Column>
                                            </UILIB.Row>
                                            <UILIB.Row>
                                                <UILIB.Column xs={12} margin={0}>
                                                    <p className="no-marg">Click the "Edit" button to setup this step</p>
                                                </UILIB.Column>
                                            </UILIB.Row>
                                        </div>
                                    }
                                    {this.state.automation.GroupId &&
                                        <UILIB.Row>
                                            <UILIB.Column xs={12}>
                                                <p className="no-marg">Carry out the following automation(s) for contacts in the group <a className="text-heavy" onClick={this.editGroup}>"{this.state.groups.find(theGroup => theGroup.id == this.state.automation.GroupId).groupName}"</a></p>
                                            </UILIB.Column>
                                        </UILIB.Row>
                                    }
                                    {this.state.automation.SegmentId &&
                                        <UILIB.Row>
                                            <UILIB.Column xs={12} margin={0}>
                                                <p className="no-marg"> And the segment <a className="text-heavy" onClick={this.editGroup}>"{this.state.segments.find(theSeg => theSeg.id == this.state.automation.SegmentId).name}"</a></p>
                                            </UILIB.Column>
                                        </UILIB.Row>
                                    }
                                </UILIB.Column>
                                <UILIB.Column xs={12} sm={12} md={2} margin={0} className="end-xs">
                                    <UILIB.Button text="Edit" className="outline secondary" onClick={this.editGroup} />
                                </UILIB.Column>
                            </UILIB.Row>
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>

                <UILIB.Row>
                    <UILIB.Column xs={12} sm={12} md={8} lg={6} margin={0} className="col-md-offset-2 col-lg-offset-3">

                        {this.state.automation.GroupId && this.state.automation.steps.map((step, index) => {

                            var needWizard = false;
                            if (foundFirstNeedWizard == true) return;
                            if (step.needWizard == true) {
                                needWizard = true;
                                foundFirstNeedWizard = true;
                            }


                            if (this.state.errors[index]) {
                                var errors = this.state.errors[index]
                                if (step.action == 'email') {
                                    if (errors.fromName) hasErrors = true
                                    if (errors.fromAddress) hasErrors = true
                                    if (errors.replyAddress) hasErrors = true
                                    if (errors.subject) hasErrors = true
                                } else if (step.action == 'update') {
                                    if (errors.actionUpdate) hasErrors = true
                                    if (errors.actionValue) hasErrors = true
                                } else if (step.action !== 'inactive' && step.action !== 'active') {
                                    if (errors.actionGroup) hasErrors = true
                                }
                            }


                            var stepDescription = "";
                            var stepShortDesc = "";
                            var backgroundImage = "";
                            if (!needWizard) {
                                switch (step.trigger) {
                                    case "signup":
                                        stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' ' + (step.delay.after == 1 ? " after " : "before")) + ' a contact is added to this group, ';
                                        break;
                                    case "datetime":
                                        stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' ' + (step.delay.after == 1 ? " after " : "before")) + ' the date and time of the contacts "' + this.state.customFields.find(theField => theField.id == step.customFieldId).fieldDesc + '" field is reached, ';
                                        break;
                                    case "anniversary":
                                        stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' ' + (step.delay.after == 1 ? " after " : "before")) + ' the aniversary of the contacts "' + this.state.customFields.find(theField => theField.id == step.customFieldId).fieldDesc + '", ';
                                        break;
                                    case "update":

                                        stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' after a contacts "') + this.state.customFields.find(theField => theField.id == step.customFieldId).fieldDesc + '" field is update or changed, ';
                                        break;
                                    // case "link":
                                    //     stepDescription = step.delay.value + ' ' + step.delay.unit + ' after a contact clicks the link "' + step.link + '", ';
                                    //     break;
                                    case "all":
                                        stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' after ') + ' a contact has been sent the previous email, ';
                                        break;
                                    case "opened":
                                        stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' after ') + ' a contact has opened the previous email, ';
                                        break;
                                    case "clicked":
                                        stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' after ') + ' a contact has clicked the previous email, ';
                                        break;
                                    case "link":
                                        stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' after ') + ' a contact has clicked the link "' + step.link + '" in the previous email, ';
                                        break;
                                }
                            }

                            switch (step.action) {
                                case "email":
                                    stepShortDesc = "Send an Email";
                                    if (!needWizard) {
                                        stepDescription += " send the following email " + (step.sendTime ? "at the next " + step.sendTime : "");
                                        if (step.campaign && step.campaign.TemplateId)
                                            backgroundImage = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + step.campaign.TemplateId + ".jpg"

                                    }
                                    break;
                                case "update":
                                    stepShortDesc = "Update a Contact Field";
                                    if (!needWizard) stepDescription += ' set the field "' + this.state.customFields.find(theField => theField.id == step.actionUpdate).fieldDesc + '" with value "' + step.actionValue + '"' + (step.sendTime ? " at the next " + step.sendTime : "");
                                    break;
                                case "addToGroup":
                                    stepShortDesc = "Copy Contact";
                                    if (!needWizard) stepDescription += ' copy/add the contact to group "' + this.state.groups.find(theGroup => theGroup.id == step.actionGroup).groupName + '"' + (step.sendTime ? " at the next " + step.sendTime : "");
                                    break;
                                case "moveToGroup":
                                    stepShortDesc = "Move the Contact";
                                    if (!needWizard) stepDescription += ' move the contact to group "' + this.state.groups.find(theGroup => theGroup.id == step.actionGroup).groupName + '"' + (step.sendTime ? " at the next " + step.sendTime : "");
                                    break;
                                case "removeFromGroup":
                                    stepShortDesc = "Remove the Contact";
                                    if (!needWizard) stepDescription += ' remove the contact if it exists from the  group  "' + this.state.groups.find(theGroup => theGroup.id == step.actionGroup).groupName + '"' + (step.sendTime ? " at the next " + step.sendTime : "");
                                    break;
                                case "inactive":
                                    stepShortDesc = "De-Activate the Contact";
                                    stepDescription += ' set the contact to "In-Active"' + (step.sendTime ? " at the next " + step.sendTime : "");
                                    break;
                                case "active":
                                    stepShortDesc = "Activate the Contact";
                                    stepDescription += ' set the contact to "Active"' + (step.sendTime ? " at the next " + step.sendTime : "");
                                    break;
                                case "webhook":
                                    stepShortDesc = "Send a Webhook";
                                    stepDescription += ' push a webhook to ' + step.webhookUrl;
                                    break;
                            }



                            return <div>

                                <ul className="campaignSteps" style={{ height: "0" }}>
                                    <li className="selected last vertical">
                                        <div className="vertical above" />
                                    </li>
                                </ul>

                                <UILIB.Paper className="vertical" key={index}>

                                    <UILIB.Row>
                                        <UILIB.Column xs={12} sm={1}>
                                            <div className="step-circle primary">
                                                <span className="text-primary">{index + 2}</span>
                                            </div>
                                        </UILIB.Column>
                                        <UILIB.Column xs={12} sm={6}>
                                            <UILIB.Row>
                                                <UILIB.Column xs={12}>
                                                    <h4 className="no-marg">{stepShortDesc}</h4>
                                                </UILIB.Column>
                                            </UILIB.Row>
                                            <UILIB.Row>
                                                <UILIB.Column xs={12} className="mar-b10">
                                                    {!needWizard && <p className="no-marg">{stepDescription}</p>}
                                                    {needWizard && <p className="no-marg">Click the "Setup" button to setup this step</p>}
                                                </UILIB.Column>
                                            </UILIB.Row>
                                        </UILIB.Column>
                                        <UILIB.Column xs={12} sm={5}>
                                            <div className="quickFlex end-xs">
                                                {!needWizard && <div className="pad-right"><UILIB.Button text="Delete" className="outline grey" onClick={() => this.deleteStepWarn(index)} /></div>}
                                                {!needWizard && this.state.integrations && this.state.integrations.length && this.state.integrations.find(f => f.ApplicationId == 38) && <div className="pad-right"><UILIB.Button text="Integrations" className="outline primary" onClick={() => this.integrationActions(index)} /></div>}
                                                {needWizard && <div><UILIB.Button text="Setup" className="outline secondary" onClick={() => { this.editStep(step, index, true) }} /></div>}
                                                {!needWizard && <div><UILIB.Button text="Edit" className="outline secondary" onClick={() => { this.editStep(step, index, false) }} /></div>}
                                            </div>
                                        </UILIB.Column>
                                    </UILIB.Row>

                                    {!needWizard && step.action == "email" &&
                                        <UILIB.Row margin={0} >
                                            <UILIB.Column xs={12} sm={1}>
                                            </UILIB.Column>
                                            <UILIB.Column xs={12} sm={9} >
                                                <UILIB.Row>
                                                    <UILIB.Column xs={12} sm={6} margin={0}>
                                                        <div className="template-preview-sml" onClick={() => { this.openEmailPreview(step.campaign.TemplateId) }}>
                                                            <div className="magnifyIcon icon-magnifier icon24 text-white" />
                                                            <div className="templateImage">
                                                                <div className="templateImageHolder">
                                                                    <TemplateImageLoader imageUrl={backgroundImage} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </UILIB.Column>
                                                    <UILIB.Column xs={12} sm={6} margin={0}>
                                                        <div className="pad-left-1">
                                                            <div className="form-group mar-b0">
                                                                <label htmlFor="" className="mar-b5">From Name:</label>
                                                                <span className="text-sml text-primary">{step.campaign.fromName}</span>
                                                                <hr className="light-hr" />
                                                            </div>
                                                            <div className="form-group mar-b0">
                                                                <label htmlFor="" className="mar-b5">From Email Address:</label>
                                                                <span className="text-sml text-primary">{step.campaign.fromAddress}</span>
                                                                <hr className="light-hr" />
                                                            </div>
                                                            <div className="form-group mar-b0">
                                                                <label htmlFor="" className="mar-b5">Reply Email Address:</label>
                                                                <span className="text-sml text-primary">{step.campaign.replyAddress}</span>
                                                                <hr className="light-hr" />
                                                            </div>
                                                            <div className="form-group mar-b0">
                                                                <label htmlFor="" className="mar-b5">Subject:</label>
                                                                <span className="text-sml text-primary">{step.campaign.subject}</span>
                                                            </div>
                                                        </div>
                                                    </UILIB.Column>
                                                </UILIB.Row>
                                            </UILIB.Column>
                                            <UILIB.Column xs={12} sm={2} >

                                            </UILIB.Column>
                                        </UILIB.Row>
                                    }
                                </UILIB.Paper>
                            </div>

                        })}
                    </UILIB.Column>


                    {this.state.automation.GroupId && (this.state.automation.steps.length == 0 || (this.state.automation.steps.length > 0 && stepsCompleted == (this.state.automation.steps.length) && this.state.automation.steps[this.state.automation.steps.length - 1].action == "email")) && <UILIB.Column xs={12} className="center-xs">
                        <ul className="campaignSteps">
                            <li className="selected last vertical">
                                <div className="vertical above" />
                                <UILIB.Button text="add a step" className="button-sml primary full-width" onClick={this.addStep} />
                            </li>
                        </ul>
                    </UILIB.Column>}

                </UILIB.Row>
                <UILIB.Row className="">
                    <UILIB.Column xs={12} className="center-xs mar-t20">
                        <UILIB.Button text="Save & Exit" onClick={this.exit} className="outline min-width grey button-md mar-r10" />
                        <UILIB.Button text="Save & Start" disabled={hasErrors} onClick={this.start} className="primary min-width button-md mar-l10" />

                    </UILIB.Column>
                </UILIB.Row>
                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />

                {this.state.drawerOpen && <div style={{ position: "fixed", backgroundColor: "#FFFFFF", left: "0", top: "0", height: "100vh", width: "100vw", opacity: "0.5", zIndex: "10" }} />}
            </div >

        );
    }
}

export default AutomationCampaignWizard;
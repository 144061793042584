import React, { useEffect, useState, useReducer } from "react";
import { useDispatch } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import DateTimeFunctions from "~/Classes/dateTimeFunctions";
import TextInput from "../../components/textInput";
import Button from "../../components/button";
import * as styles from "./index.module.scss";
import AddArticleDialog from "./components/addArticleDialog";
import DeleteArticleDialog from "./components/deleteArticleDialog";
import Header from "../../components/header";
import i18n from "~/i18n";
import moment from "moment";

export default function AiAssistantArticles({ inbox, assistant }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = UILIB.useCallback(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDeleteArticleDialogOpen, setIsDeleteArticleDialogOpen] =
    useState(false);
  const [isAddArticleDialogOpen, setIsAddArticleDialogOpen] = useState(false);

  const [tableData, updateTableData] = useReducer((state, action) => {
    return formatTableDataReducer(state, action, {
      setSelectedArticle,
      setIsDeleteArticleDialogOpen,
      refreshArticle,
      editArticle
    });
  }, []);

  useEffect(() => {
    loadInitialData();
  }, []);

  async function loadInitialData() {
    try {
      await loadArticles();
    } finally {
      setLoading(false);
    }
  }

  async function loadArticles() {
    try {
      const { data } = await axios.get(
        `/inboxes/${inbox.id}/ai/${assistant.id}/embeddings`
      );
      setArticles(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  useEffect(() => {
    updateTableData(articles);
  }, [articles]);

  async function addArticle(values) {
    try {
      // TODO: This endpoint returns a `Error: 401 Incorrect API key provided` error but still creates an article/embedding.
      if (selectedArticle) {
        await axios.put(`/inboxes/${inbox.id}/ai/${assistant.id}/embeddings/${selectedArticle.id}`, {
          pageTitle: values.title,
          text: values.content,
          pageUrl: values.url,
        });
      }
      else {
        await axios.post(`/inboxes/${inbox.id}/ai/${assistant.id}/embeddings`, {
          pageTitle: values.title,
          text: values.content,
          pageUrl: values.url,
        });
      }
      loadArticles();
      setIsAddArticleDialogOpen(false);
      dispatch(alterSnackbar(true, i18n.t("chat:ai.articles.added")));
      loadArticles();
    } catch (error) {
      dispatch(handleGenericError(error));
    }
  }

  async function deleteArticle(article) {
    try {
      // TODO: Needs a DELETE endpoint creating.
      await axios.delete(
        `/inboxes/${inbox.id}/ai/${assistant.id}/embeddings/${article.id}`
      );
      dispatch(alterSnackbar(true, i18n.t("chat:ai.articles.deleted")));
      loadArticles();
    } catch (error) {
      dispatch(handleGenericError(error));
    }
  }

  async function refreshArticle(article) {
    try {
      await axios.put(
        `/inboxes/${inbox.id}/ai/${assistant.id}/embeddings/${article.id}/refresh`
      );

      loadArticles();
      dispatch(alterSnackbar(true, i18n.t("chat:ai.articles.refreshing")));
    } catch (error) {
      dispatch(handleGenericError(error));
    }
  }

  async function editArticle(article) {
    await setSelectedArticle(article);
    setIsAddArticleDialogOpen(true);
  }

  useEffect(() => {
    const filteredData = articles.filter((article) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      if (
        article.pageTitle.toLowerCase().includes(lowerCaseSearchTerm) ||
        article.pageUrl.toLowerCase().includes(lowerCaseSearchTerm)
      ) {
        return true;
      }

      return false;
    });

    updateTableData(filteredData);
  }, [searchTerm]);

  if (loading) return <UILIB.LoadingIcons noClass />;

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inbox_settings_left_content">
      <Header type="ai" option="articles" />
      <div className={styles.topRow}>
        <form>
          <TextInput
            value={searchTerm}
            placeholder={i18n.t("chat:ai.articles.searchPlaceholder")}
            aria-label="Search for an article"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </form>

        <Button
          size="s"
          iconLeft={<UILIB.Icon name="plus" />}
          onClick={() => {
            setSelectedArticle(null);
            setIsAddArticleDialogOpen(true);
          }}
        >
          {i18n.t("chat:ai.articles.addManually")}
        </Button>
      </div>

      {tableData.length > 0 && <UILIB.DataTable1 tableData={tableData} />}

      <DeleteArticleDialog
        articleToDelete={selectedArticle}
        isOpen={isDeleteArticleDialogOpen}
        onSubmit={deleteArticle}
        onOpenChange={(isOpen) => setIsDeleteArticleDialogOpen(isOpen)}
      />

      {isAddArticleDialogOpen && <AddArticleDialog
        isOpen={true}
        onSubmit={addArticle}
        selectedArticle={selectedArticle}
        onOpenChange={(isOpen) => setIsAddArticleDialogOpen(isOpen)}
        assistant={assistant}
        inbox={inbox}
      />}
    </div>
  );
}

function formatTableDataReducer(state, action, options) {
  return action.map((article) => {
    const { pageTitle, pageUrl, updatedAt, createdAt, InboxAiSource } = article;

    let lastUpdatedDate = DateTimeFunctions.formatDateTime(updatedAt);
    if (moment(createdAt).isAfter(moment().subtract(1, 'days'))) {
      lastUpdatedDate = <>
        {DateTimeFunctions.formatDateTime(updatedAt)}
        <UILIB.Badge color={"teal"} className="mar-l10">
          New
        </UILIB.Badge>
      </>
    }


    return {
      pageTitle: {
        headerValue: i18n.t("chat:ai.articles.pageTitle"),
        value: (
          <div className={styles.tablePageTitleWrapper}>
            <span className="fw-medium">{pageTitle}</span>
            <span className={styles.tablePageUrl}>{pageUrl}</span>
          </div>
        ),
      },
      source: {
        headerValue: i18n.t("chat:ai.articles.source"),
        value: InboxAiSource ? InboxAiSource.url : i18n.t("chat:ai.articles.manual")
      },
      lastUpdated: {
        headerValue: i18n.t("chat:ai.articles.lastUpdated"),
        value: lastUpdatedDate,
      },
      options: {
        headerValue: "",
        value: (
          <div className={styles.tableActions}>
            <UILIB.DropdownMenu.Root>
              <UILIB.DropdownMenu.Trigger asChild>
                <Button
                  aria-label="Additional options"
                  variant="ghost"
                  size="s"
                  iconOnly
                >
                  <UILIB.Icon name="dots-vertical" />
                </Button>
              </UILIB.DropdownMenu.Trigger>

              <UILIB.DropdownMenu.Portal>
                <UILIB.DropdownMenu.Content align="end">

                  {InboxAiSource && <UILIB.DropdownMenu.Item
                    onSelect={() => options.refreshArticle(article)}
                  >{i18n.t("chat:ai.articles.refresh")}</UILIB.DropdownMenu.Item>}

                  <UILIB.DropdownMenu.Item
                    onSelect={() => options.editArticle(article)}
                  >{i18n.t("chat:ai.articles.editLabel")}</UILIB.DropdownMenu.Item>


                  <UILIB.DropdownMenu.Separator />
                  <UILIB.DropdownMenu.Item
                    variant="danger"
                    onSelect={() => {
                      options.setSelectedArticle(article);
                      options.setIsDeleteArticleDialogOpen(true);
                    }}
                  >{i18n.t("chat:ai.articles.deleteArticle")}</UILIB.DropdownMenu.Item>
                </UILIB.DropdownMenu.Content>
              </UILIB.DropdownMenu.Portal>
            </UILIB.DropdownMenu.Root>
          </div>
        ),
      },
    };
  });
}

import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import SiteVars from '~/Classes/siteVars';
import { connect } from 'react-redux';
import i18n from '~/i18n'

@connect((store) => {
    return { accountMaster: store.accountMaster }
})

class FormEditor extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        var screenshotUrl
        if (this.props.form) screenshotUrl = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/form" + this.props.id + ".jpg?now=" + (this.props.now || Date.now());
        else screenshotUrl = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + this.props.id + ".jpg?now=" + (this.props.now || Date.now());

        return (
            <UILIB.Paper style={{ padding: "0", margin: "0" }}>

                {screenshotUrl &&
                    <div style={{ backgroundImage: "url(" + screenshotUrl + ")", height: "600px", position: "relative", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                        <div style={{ position: "absolute", right: "15px", top: "20px", display: 'flex', alignItems: 'center' }}>
                            <UILIB.Button
                                text={i18n.t('form:edit.editor.edit')}
                                onClick={this.props.edit}
                                className="button-primary"
                                iconLeft={<UILIB.Icons icon="pencil" />}
                            />
                            {/* {!this.props.form &&
                                <UILIB.Button
                                    text={i18n.t('form:edit.overview.change')}
                                    onClick={this.props.changeEmailTemplate}
                                    className="mar-l10"
                                />
                            } */}
                        </div>
                    </div>
                }

            </UILIB.Paper>
        );
    }
}

export default FormEditor;
import React, { useState } from 'react'
import UILIB from '~/Common-Objects/Lib';

export default function DeleteTag({ tag, onConfirm = () => { }, onCancel = () => { } }) {
    const [error, setError] = useState('')
    const confirm = async () => {
        try {
            await onConfirm()
        } catch (e) {
            setError(e.data.error)
        }
    }


    return (
        <div>
            <h4 className="mar-b25">Delete Tag</h4>
            <div className="mar-b25">Are you sure you want to delete {tag.tagName}?</div>

            <div className="row">
                <div className="col-xs-6">
                    <UILIB.Button text="Delete" className="button-red button-fullwidth" onClick={confirm} iconLeft={<UILIB.Icons icon="cross" />} />
                </div>
                <div className="col-xs-6">
                    <UILIB.Button text="Cancel" className="button-fullwidth" onClick={onCancel} />
                </div>

                {!!error && <div className='col-xs-12 mar-t10 text-red'>
                    {error}
                </div>}
            </div>

        </div>
    )
}
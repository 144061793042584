import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import axios from '~/data/http/axios';
import NumberFunctions from '~/Classes/numberFormatFunctions';
import Plan from './plan'
import AddCard from './addCardStripe';
import queryString from 'query-string';
import SiteVars from '../../../Classes/siteVars'

@connect((store) => {
    return { user: store.user, account: store.accountMaster, siteMaster: store.siteMaster }
})
class BillingPlans extends Component {
    constructor(props) {
        super(props)
        this.state = {
            plans: [],
            loaded: false,
            alertOpen: false,
            alertMessage: '',
            preview: false,
            newType: undefined,
            newPlan: {},
            paymentMethods: [],
            drawerOpen: false,
            processingPayment: false,
            error: '',
            voucherCode: '',
            currentVoucher: undefined,
            stripeAuthPayment: undefined
        }

        this.stripe = Stripe(SiteVars.stripeKey);

        this.getPlans = this.getPlans.bind(this);
        this.previewPlan = this.previewPlan.bind(this);
        this.selectPlan = this.selectPlan.bind(this);
        this.cancelChange = this.cancelChange.bind(this);
        this.addPaymentMethod = this.addPaymentMethod.bind(this);
        this.exitPaymentMethod = this.exitPaymentMethod.bind(this);
        this.updateVoucherCode = this.updateVoucherCode.bind(this);
        this.getFreePlan = this.getFreePlan.bind(this);
        this.completePaymentIntent = this.completePaymentIntent.bind(this)
        this.errorPaymentIntent = this.errorPaymentIntent.bind(this)
    }

    componentDidMount() {
        this.getFreePlan();
    }

    getFreePlan() {
        axios.get('/site/freePlan').then(_res => {
            this.setState({ freePlan: _res.data }, this.getPlans)
        })
    }

    getPlans() {
        var self = this;
        var plans = [];

        axios.get('/accountMaster/plan')
            .then(response => {
                plans = response.data.Plans;
                const query = queryString.parse(location.search);
                if (!self.state.loaded && query.planId) {
                    var newPlan = plans.find(p => p.id == query.planId)
                    var newType = query.type
                    self.setState({
                        plans: plans,
                        loaded: true,
                        newPlan: newPlan,
                        newType: newType
                    }, () => {
                        self.selectPlan()
                    })
                } else {
                    this.setState({
                        plans: plans,
                        loaded: true
                    })
                }

                if (this.props.account.accountMaster.signupAppId == '0') {
                    return axios.get('/accountMaster/paymentMethod');
                }
            })
            .then(response => {
                var paymentMethods = [];
                if (response) {
                    if (response.data.PaymentMethod) paymentMethods = response.data.PaymentMethod;
                    else if (response.data.customer.sources.data) paymentMethods = response.data.customer.sources.data;
                }
                self.setState({
                    paymentMethods: paymentMethods
                })
            }).catch(err => {
                console.log(err);
            })
    }

    previewPlan(type, plan) {
        axios.get('/accountMaster/plan/' + (plan ? plan.id : -1) + '?accountType=' + type)
            .then(response => {
                this.setState({
                    preview: true,
                    newType: type,
                    newPlan: plan,
                    ...response.data
                })
            })
    }

    cancelChange() {
        this.setState({
            preview: false
        })
    }

    selectPlan() {
        console.log('switching plans')
        this.setState({
            alertOpen: true,
            alertMessage: 'Switching Plans',
            drawerOpen: false,
            processingPayment: true
        })

        var voucherCode = undefined;
        if (this.state.voucherCode) voucherCode = this.state.voucherCode;

        axios.put('/accountMaster/plan', {
            id: this.state.newPlan ? this.state.newPlan.id : -1,
            accountType: this.state.newType,
            voucherCode: voucherCode
        }).then(response => {
            if (response.data.clientSecret) {
                this.setState({
                    stripeAuthPayment: response.data
                })
            } else {
                this.setState({
                    alertOpen: true,
                    alertMessage: 'Plan Switched',
                    processingPayment: false
                })
                this.props.close();
            }
        }).catch(err => {
            var message = 'Error Processing Payment'
            if (err.message === 'Voucher Code Already Used') {
                message = err.message
            }
            if (err.data.error === 'Cannot charge a customer that has no active card') {
                this.addPaymentMethod()
                return
            }
            this.setState({
                alertOpen: true,
                alertMessage: message,
                processingPayment: false,
                error: err.data.error
            })
        })
    }

    addPaymentMethod() {

        if (this.props.account.accountMaster.signupAppId == 0) {
            //load stripe 
            this.setState({ drawerOpen: true });
            return;
        }

        this.setState({
            alertOpen: true,
            alertMessage: 'Processing please wait...',
            drawerOpen: false,
            processingPayment: true
        })

        axios.get('/accountMaster/application').then(appsData => {
            var ourApp = appsData.data.Applications.find(theApp => theApp.id == this.props.account.accountMaster.signupAppId)
            //not found one, just process it and get a payment error
            if (!ourApp) this.selectPlan();
            this.setState({ appPaymentOpen: true })

            switch (ourApp.Application.appName) {
                case "Shopify":
                    //shopify

                    axios.post('/application/shopify/payments/create', { planId: this.state.newPlan.id, planType: this.state.newType }).then((response) => {
                        if (response.data.redirect) {
                            window.location = response.data.redirect;
                        } else if (response.data.ready) {
                            this.selectPlan();
                        } else {
                            // something went wrong
                        }
                    })
                    break;
                default:
                    this.selectPlan();
            }

        })

    }
    exitPaymentMethod() {
        this.setState({ drawerOpen: false })
    }

    updateVoucherCode(event) {
        var self = this;
        var voucherCode = this.state.voucherCode;
        //check voucher code
        axios.get('/voucherCode/' + voucherCode).then(_res => {
            self.setState({ currentVoucher: _res.data.VoucherCode })
        }).catch(err => {
            self.setState({ currentVoucher: undefined })
        })

    }

    completePaymentIntent() {
        axios.put('/accountMaster/payment/stripeauth/' + this.state.stripeAuthPayment.id).then(() => {
            this.setState({
                alertOpen: true,
                alertMessage: 'Plan Switched',
                processingPayment: false
            })
            this.props.close();
        }).catch(err => {
            this.setState({
                error: err.response.message || err.response
            })
        })
    }

    errorPaymentIntent(err) {
        this.setState({
            error: err.response.message || err.response
        })
    }

    render() {
        if (!this.state.loaded) return <div></div>
        var currentPlan = { name: this.props.account.accountMaster.billingName };
        var currentPlanPrice = 0;

        var currentIndex = this.state.plans.findIndex(p => p.id == this.props.account.accountMaster.AccountBillingId);

        if (currentIndex > -1) {
            currentPlan = this.state.plans[currentIndex];
            currentPlanPrice = this.state.plans[currentIndex].price;
            var nextBasic = this.state.plans[0], nextAdvanced = this.state.plans[0];
            if (this.props.account.accountMaster.accountType === 'basic') {
                if (currentIndex !== (this.state.plans.length - 1))
                    nextBasic = this.state.plans[currentIndex + 1];
                nextAdvanced = currentPlan;
            } else if (this.props.account.accountMaster.accountType === 'advanced') {
                if (currentIndex !== (this.state.plans.length - 1))
                    nextAdvanced = this.state.plans[currentIndex + 1];
                nextBasic = currentPlan;
            }
        }

        var newPriceFinal = this.state.price;
        if (this.state.currentVoucher) {
            if (this.state.currentVoucher.voucherType == 0) {
                newPriceFinal = newPriceFinal - (newPriceFinal * (this.state.currentVoucher.voucherValue / 100));
                if (newPriceFinal < 0) newPriceFinal = 0
            }
        }

        newPriceFinal = parseFloat(newPriceFinal).toFixed(2);

        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} sm={12} md={10} lg={8} className="col-md-offset-1 col-lg-offset-2" margin={0}>
                        <UILIB.Row className="mar-b5">
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0} className="center-xs csnter-sm center-md center-lg">
                                <p className="text-black text-md no-marg">Select your plan</p>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row className="mar-b40">
                            <UILIB.Column xs={12} sm={12} md={12} lg={12} margin={0} className="center-xs csnter-sm center-md center-lg">
                                <p className="text-grey text-lg no-marg text-light">You are currently on the <span className="text-green text-heavy">{this.props.account.accountMaster.accountType} plan</span> with <span className="text-green text-heavy">{currentPlan.name}</span></p>
                            </UILIB.Column>
                        </UILIB.Row>
                        <UILIB.Row className="m-mar-b30 mar-b10">
                            <UILIB.Column xs={12} sm={6} md={6}>
                                <Plan
                                    className={"grey"}
                                    currentPlan={this.props.account.accountMaster.accountType === 'free'}
                                    name={this.state.freePlan.name}
                                    description={'Always free, easy and simple forever'}
                                    ticks={['Unlimited Sending', 'Up to ' + this.state.freePlan.maxUniqueSends + ' subscribers', 'All basic features']}
                                    price={'free'}
                                    disabled={this.state.processingPayment || this.props.account.accountMaster.accountType === 'free'}
                                    select={this.previewPlan}
                                    type={'free'}
                                />
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} md={6}>
                                <Plan
                                    className={"primary"}
                                    currentPlan={this.props.account.accountMaster.accountType === 'basic'}
                                    name={'unlimited'}
                                    description={'Our most popular package'}
                                    ticks={['Unlimited Sending', 'Increased Subscribers', 'Unlimited Staff']}
                                    plans={this.props.account.accountMaster.accountType === 'basic' ? this.state.plans.filter(p => p.id !== currentPlan.id) : this.state.plans}
                                    selected={nextBasic}
                                    select={this.previewPlan}
                                    disabled={this.state.processingPayment}
                                    type={'basic'}
                                />
                            </UILIB.Column>
                            {/* <UILIB.Column xs={12} sm={4} md={4}>
                                <Plan
                                    className={"secondary comingsoon"}
                                    currentPlan={this.props.account.accountMaster.accountType === 'advanced'}
                                    name={'advanced'}
                                    description={'All advanced features'}
                                    ticks={['Unlimited Sending', 'Automation and More', 'Priority Support']}
                                    plans={this.props.account.accountMaster.accountType === 'advanced' ? this.state.plans.filter(p => p.id !== currentPlan.id) : this.state.plans}
                                    markup={this.props.siteMaster.advancedMarkup}
                                    selected={nextAdvanced}
                                    disabled={true}
                                    select={this.previewPlan}
                                    type={'advanced'}
                                    price={'coming soon'}
                                    comingsoon={true}
                                />
                            </UILIB.Column> */}
                        </UILIB.Row>

                        <UILIB.Row className={"plan-preview" + (this.state.preview ? '' : ' hide')}>
                            <UILIB.Column xs={12} md={10} lg={8} className="col-md-offset-1 col-lg-offset-2">
                                {this.state.changeNow && <UILIB.Row>
                                    <UILIB.Column xs={12} className="center-xs mar-b30">
                                        <span>You are changing to </span>
                                        <span className="text-primary text-heavy uppercase">{this.state.newType} - {this.state.newPlan.name}</span>
                                        <span> and it will cost you </span>
                                        <span className="text-primary text-heavy">{this.state.symbol}{NumberFunctions.formatNumber(newPriceFinal, 2)}</span>
                                        {this.state.price && currentPlanPrice > 0 &&
                                            <span>
                                                <span> now as there are </span>
                                                <span className="text-primary text-heavy">{NumberFunctions.formatNumber(this.state.daysRemaining)} days</span>
                                                <span> left until your next full bill</span>
                                            </span>
                                        }
                                    </UILIB.Column>
                                    {this.state.price && currentPlanPrice <= 0 &&
                                        <UILIB.Column xs={12} className="center-xs mar-b30">
                                            <UILIB.Row className="center-xs mar-b0" >
                                                <UILIB.Column xs={12} sm={8} className="center-xs mar-b10">
                                                    <div className="form-group mar-b0">
                                                        <div className="quickFlex center-xs">
                                                            <UILIB.TextInput name="voucherCode" value={this.state.voucherCode} placeholder="Voucher Code" onChange={(event) => { this.setState({ voucherCode: event.target.value }) }} />
                                                            <UILIB.Button className="button-sml outline grey" text="Apply" onClick={this.updateVoucherCode} />
                                                        </div>
                                                    </div>
                                                </UILIB.Column>

                                                {this.state.currentVoucher &&
                                                    <UILIB.Column xs={12} sm={8} className="text-left mar-b0">
                                                        <span className="text-green">
                                                            {this.state.currentVoucher.voucherType == 0 && <span><strong>{this.state.currentVoucher.voucherValue}% discount</strong> applied to your first month.</span>}
                                                        </span>
                                                    </UILIB.Column>}
                                            </UILIB.Row>
                                        </UILIB.Column>
                                    }

                                    <UILIB.Column xs={6} className="end-xs"><UILIB.Button className="outline grey button-sml no-shadow min-width" text="Cancel" onClick={this.cancelChange} /></UILIB.Column>

                                    {this.state.paymentMethods.length <= 0 && <UILIB.Column xs={6} className="start-xs"><UILIB.Button className="button-sml no-shadow min-width" text="Pay Now" onClick={this.addPaymentMethod} /></UILIB.Column>}
                                    {this.state.paymentMethods.length > 0 && <UILIB.Column xs={6} className="start-xs"><UILIB.Button className="button-sml no-shadow min-width" text="Pay Now" onClick={this.selectPlan} /></UILIB.Column>}
                                </UILIB.Row>}
                                {!this.state.changeNow && <UILIB.Row className="mar-b20">
                                    <UILIB.Column xs={12} className="center-xs mar-b30">
                                        <span>You are changing to </span>
                                        <span className="text-primary text-heavy uppercase">{this.state.newType}{this.state.newPlan ? ' - ' + this.state.newPlan.name : ''}</span>
                                        <span> you will be charged </span>
                                        <span className="text-primary text-heavy">{this.state.symbol}{NumberFunctions.formatNumber(this.state.price, 2)}</span>
                                        <span> in </span>
                                        <span className="text-primary text-heavy">{NumberFunctions.formatNumber(this.state.daysRemaining)} days</span>
                                        <span> as this is a downgrade</span>
                                    </UILIB.Column>
                                    <UILIB.Column xs={6} className="end-xs"><UILIB.Button className="outline grey button-sml no-shadow min-width" text="Cancel" onClick={this.cancelChange} /></UILIB.Column>
                                    <UILIB.Column xs={6} className="start-xs"><UILIB.Button className="button-sml no-shadow min-width" text="Downgrade" onClick={this.selectPlan} /></UILIB.Column>

                                </UILIB.Row>}
                                {this.state.processingPayment && <UILIB.Row>
                                    <UILIB.Column xs={12} className="center-xs"><span className="text-lg text-grey loading">Processing</span></UILIB.Column>
                                </UILIB.Row>}
                                {!!this.state.error && <UILIB.Row>
                                    <UILIB.Column xs={12} className="center-xs"><span className="text-lg text-red">{this.state.error}</span></UILIB.Column>
                                </UILIB.Row>}
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Column>
                </UILIB.Row>
                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
                <UILIB.Drawer standalone={true} isOpen={this.state.drawerOpen} width="100%" showClose={true} close={this.exitPaymentMethod} className="full" >
                    <AddCard stripe={this.stripe} close={this.selectPlan} />
                </UILIB.Drawer>
                {!!this.state.stripeAuthPayment && <UILIB.AuthStripe paymentMethod={this.state.stripeAuthPayment.paymentMethod} clientSecret={this.state.stripeAuthPayment.clientSecret} success={this.completePaymentIntent} error={this.errorPaymentIntent} />}

            </div >
        )
    }
}

export default BillingPlans;
import React from 'react'

export default function IconPlusChunky({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.25 9.99996C16.25 10.6903 15.6904 11.25 15 11.25H11.25L11.25 15C11.25 15.6904 10.6904 16.25 10 16.25C9.30965 16.25 8.75 15.6904 8.75 15L8.74999 11.25L5 11.25C4.30964 11.25 3.75 10.6903 3.75 9.99996C3.75 9.3096 4.30964 8.74996 5 8.74996L8.74998 8.74996L8.74997 4.99995C8.74997 4.30959 9.30961 3.74995 9.99996 3.74995C10.6903 3.74994 11.25 4.30959 11.25 4.99994L11.25 8.74996L15 8.74996C15.6904 8.74996 16.25 9.3096 16.25 9.99996Z" fill={fillColor} />
        </svg>

    )
}
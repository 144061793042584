import moment from 'moment';
import momentTZ from 'moment-timezone';

import store from '~/data/store/store';

// moment.updateLocale('de', {
//     relativeTime: {
//         future: "in %s",
//         past: "%s vor",
//         s: 'ein paar Sekunden',
//         ss: '%d Sekunden',
//         m: "eine Minute",
//         mm: "%d Minuten",
//         h: "eine Stunde",
//         hh: "%d Stunden",
//         d: "ein Tag",
//         dd: "%d Tage",
//         M: "ein Monat",
//         MM: "%d Monate",
//         y: "ein Jahr",
//         yy: "%d Jahre"
//     }
// });


class DateTimeFunctions {

    static formatDateTime(theDateTime, format) {
        if (!theDateTime) return ''
        var state = store.getState();
        //load users time zone

        var zone = state.user.userData.timeZone;
        var dateFormat = state.user.userData.dateFormat;
        var date = momentTZ.tz(theDateTime, zone);
        if (isNaN(date)) {
            return "Invalid Date"
        }

        if (format == "2") {
            //short date
            var theFormat = dateFormat;
            var newDate = moment(date).format(theFormat);
            return newDate;
        }
        else if (format == "3") {
            //long date
            var theFormat = dateFormat + " HH:mm:ss";
            return moment(date).format(theFormat);
        }
        else if (format == "4") {
            //sql style short date
            var theFormat = "YYYY-MM-DD";
            return moment(date).format(theFormat);
        }
        else if (format == "5") {
            //hours and minutes if today, day and month if not
            var today = moment().format('YYYY-MM-DD');
            var theDate = moment(date).format('YYYY-MM-DD');
            if (today == theDate) {
                var theFormat = "HH:mm";
                return moment(date).format(theFormat);
            }
            else {
                var theFormat = "DD MMM";
                return moment(date).format(theFormat);
            }
        }
        else {
            //date time
            var theFormat = dateFormat + " HH:mm";
            return moment(date).format(theFormat);
        }
    }

    static humanizeDuration(date, locale) {
        var dt = moment.duration(moment().diff(date, 'seconds'), 'seconds');
        if (locale) dt = dt.locale(locale);
        dt = dt.humanize();
        return dt;
    }

    static timeBetween(theDateTime) {

        var date = Date.parse(theDateTime);
        if (isNaN(date)) {
            return "Invalid Date"
        }
        var timeBetween = moment(date).fromNow()
        return timeBetween;

    }

    static getTimeZones() {
        var theData = momentTZ.tz.names();
        return theData;
    }

    static getCurrentTimeOffset() {
        var state = store.getState();
        var zone = state.user.userData.timeZone;
        console.log(momentTZ.tz(zone))
        return momentTZ.tz(zone)._offset / 60;
    }

    static getTimeZone(timezone) {
        if (!timezone) {
            var state = store.getState();
            timezone = state.user.userData.timeZone;
        }
        return momentTZ.tz(timezone).format("z");
    }
}

export default DateTimeFunctions;
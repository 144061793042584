import React, { useState, forwardRef, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import PermissionChecker from '~/Classes/permissions'

const Tab = forwardRef((props, ref) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const permissionStore = useSelector(state => state.permission)
    const accountStore = useSelector(state => state.accountMaster)
    const site = useSelector(state => state.siteMaster)
    const history = useHistory()

    const itemClicked = (e, sub) => {
        e.preventDefault()
        e.stopPropagation();
        if (sub.route) {
            history.push(sub.route)
        } else if (sub.action && typeof sub.action === 'function') {
            sub.action()
        }
        setMenuOpen(false)
    }

    var classes = "uiTab ";
    if (props.isSelected) {
        classes += " selected ";
    }

    if (props.beta && accountStore.accountMaster.beta && !accountStore.accountMaster.beta[props.beta]) return null
    if (props.mpz && site.siteId !== 1) return null

    if (!props.permission || PermissionChecker(props.permission, permissionStore.permissions, "read")) {
        const url = props.changeTab(props.tabId, true, true)
        const child = <>
            {props.label}
            {!!props.sub && !!props.sub.length && <Fragment>
                <div className="uiTabSub-indicator" />
                <div className={"uiTabSub " + (menuOpen ? 'open' : '')}>
                    {props.sub.map((sub, index) => {
                        if (sub.permission && sub.permission.name) {
                            if (permissionStore.permissions[sub.permission.name] !== sub.permission.value) return null
                        }
                        if (sub.beta && accountStore.accountMaster.beta && !accountStore.accountMaster.beta[sub.beta]) return null
                        if (sub.mpz && site.siteId !== 1) return null
                        if (sub.divider) return <div key={index} className="uiTabSubDivider"></div>
                        const child = <>
                            {!!sub.icon && typeof sub.icon === 'string' && <div className={sub.icon + ' uiTabSubIcon'}></div>}
                            {!!sub.icon && typeof sub.icon !== 'string' && <div className={'uiTabSubIcon'}>{sub.icon}</div>}
                            <div className="uiTabSubText">{sub.label}</div>
                            {!!sub.endIcon && <div className={'uiTabSubIconEnd'}>{sub.endIcon}</div>}
                        </>
                        if (sub.route) {
                            return <Link key={index} style={{ textDecoration: 'none' }} className="uiTabSubItem" to={sub.route} onClick={() => setMenuOpen(false)}>
                                {child}
                            </Link>
                        } else {
                            return <div key={index} className="uiTabSubItem" onClick={(e) => itemClicked(e, sub)}>
                                {child}
                            </div>
                        }
                    })}
                </div>
            </Fragment>}
        </>
        const _props = {
            className: classes,
            ref: ref,
            onMouseEnter: () => setMenuOpen(true),
            onMouseLeave: () => setMenuOpen(false)
        }
        if (url) {
            return <Link {..._props} style={{ textDecoration: 'none' }} ref={ref} to={url} onClick={(e) => {
                if (url == window.location.pathname) {
                    e.preventDefault();
                    setMenuOpen(!menuOpen)
                }
                else {
                    setMenuOpen(false)
                }
            }}>
                {child}
            </Link >
        } else {
            return <div {..._props} onClick={() => props.changeTab(props.tabId, true)}>
                {child}
            </div>
        }
    } else {
        return (null)
    }
})

export default Tab
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import UILIB from '../../../../../Common-Objects/Lib'
import axios from '../../../../../data/http/axios'
import PermissionChecker from '../../../../../Classes/permissions'

export default function OrganisationTags() {
    const params = useParams()
    const [tags, setTags] = useState(null)
    const permissionStore = useSelector(state => state.permission)

    const getTags = async () => {
        const res = await axios.get('/organisation/' + params.id + '/tags?tagType=0')
        setTags(res.data)
    }

    const tagsChanged = async (newTags) => {
        await axios.put('/organisation/' + params.id + '/tags', { tags: newTags })
        getTags()
    }

    useEffect(() => {
        getTags()
    }, [params.id])


    const writeAccess = PermissionChecker('subscribers', permissionStore.permissions, 'write')

    return (
        <UILIB.Paper isLoading={!tags}>
            <h4 className="mar-b25">Tags</h4>
            {!!tags && <UILIB.Tags tagsSelected={tags} tagsChanged={tagsChanged} disableEdit={!writeAccess} addStyle={{}} />}
        </UILIB.Paper>
    )
}
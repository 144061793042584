import React, { useEffect, useState } from "react";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area, CartesianGrid } from 'recharts';
import axios from "~/data/http/axios";
import { useSelector } from "react-redux";
import i18n from "~/i18n";

export default function AiAssistantHistoryGraph({ inbox, assistant }) {

    const siteMaster = useSelector((state) => state.siteMaster)

    const [start, setStart] = useState(7)
    const [graphData, setGraphData] = useState([])
    const [loading, setLoading] = useState(true);
    const startOptions = [
        { label: i18n.t("chat:ai.overview.graph.last7days"), value: 7 },
        { label: i18n.t("chat:ai.overview.graph.last2weeks"), value: 14 },
        { label: i18n.t("chat:ai.overview.graph.last30days"), value: 30 },
        { label: i18n.t("chat:ai.overview.graph.last3months"), value: 90 },
        { label: i18n.t("chat:ai.overview.graph.last6months"), value: 180 },
        { label: i18n.t("chat:ai.overview.graph.last12months"), value: 365 },
    ];

    useEffect(() => {
        getResponsesGraphData();
    }, [start])

    const getResponsesGraphData = async () => {
        let responsesGraphData = (await axios.get(`/inboxes/${inbox.id}/ai/${assistant.id}/history/graph?start=${start}`)).data
        responsesGraphData = responsesGraphData.map((data) => {
            return {
                date: data.date,
                count: data.count
            }
        })
        setGraphData(responsesGraphData)
        setLoading(false);
    }

    if (loading) return (
        <div></div>
    )


    return (
        <>
            <div className={styles.teaser}>
                <div className={styles.teaserFlexHeader}>
                    <h2 className={styles.heading}>{i18n.t("chat:ai.overview.graph.header")}</h2>
                    <div className={styles.teaserFlexFitContent}>
                        <UILIB.SelectNewDefault
                            value={start}
                            data={startOptions}
                            fullWidth
                            onValueChange={(val) => setStart(val)}
                        />
                    </div>
                </div>

                <ResponsiveContainer width="100%" height={300} >
                    <AreaChart data={graphData} margin={{ top: 0, right: 0, left: 20, bottom: 0 }}>
                        <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                        <YAxis axisLine={false} hide={true} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                        <Tooltip content={<UILIB.CustomTooltip name={"Responses"} />} />
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={siteMaster.colours.$primary} stopOpacity={0.3} />
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid horizontal={false} strokeDasharray="2 2" stroke="#DADADC" />
                        <Area
                            type="monotone"
                            dataKey={"count"}
                            fill="url(#colorUv)"
                            stroke={siteMaster.colours.$primary}
                            strokeWidth={2}
                            fillOpacity={1}
                            activeDot={{
                                stroke: siteMaster.colours.$primary, strokeWidth: 2, fill: 'white'
                            }}
                        />
                    </AreaChart>
                </ResponsiveContainer>

            </div>
        </>
    )
}

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

export default class CleanTemplateOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            loading: true,
            selectedOption: "",
            selectedGroupId: 0,
            selectedStatus: ""
        };
        this.getGroups = this.getGroups.bind(this);
        this.clickOption = this.clickOption.bind(this);
        this.goAction = this.goAction.bind(this);
    }

    componentDidMount() {
        if (this.props.siteMaster.listCleanDisabled) return this.props.history.push("/cp")
        this.getGroups();
    }

    getGroups() {
        axios.get('/group').then(response => {
            this.setState({
                groups: response.data.Groups.filter(g => g.id !== this.props.groupId).map((group) => {
                    return {
                        label: group.groupName,
                        value: group.id
                    }
                })
                ,
                loading: false
            })
        })
    }

    clickOption(optionType) {
        this.setState({ selectedOption: optionType })
    }

    goAction() {
        var self = this;
        var selectedOption = this.state.selectedOption;
        if (this.props.rType == "invalid") selectedOption = "delete";
        axios.put('/listcleaner/' + this.props.jobId + '/action',
            {
                action: selectedOption,
                type: this.props.rType,
                sourceId: this.props.job.groupId || this.props.job.groupIds,
                destinationId: this.state.selectedGroupId,
                newStatus: this.state.selectedStatus
            })
            .then(() => {
                self.props.close();
            })
            .catch(err => {
                self.props.close();
            })
    }


    render() {

        if (this.state.loading) return <div></div>

        if (this.state.selectedOption == "delete" || this.props.rType == "invalid") {
            return <div>
                <h4 className="mar-b25" style={{ textTransform: "capitalize" }}>{this.state.selectedOption} {this.props.rType} Email Addresses</h4>
                <div className="mar-b25">You are about to delete the {this.props.count} {this.props.rType} email addresses. This will actually move the email addresses to your Denylist group to ensure you do not accidentally import them again in the future.</div>
                <UILIB.Button text="Delete Them" onClick={this.goAction} />
            </div>
        }
        if (this.state.selectedOption == "copy" || this.state.selectedOption == "move") {
            return <div>
                <h4 className="mar-b25" style={{ textTransform: "capitalize" }}>{this.state.selectedOption} {this.props.rType} Email Addresses</h4>
                <div className="mar-b25">Please select the group you would like to {this.state.selectedOption} these email addresses to</div>

                <UILIB.Select outerClassName="mar-b25" value={this.state.selectedGroupId} data={this.state.groups} placeholder="Please select a group" onChange={(event) => { this.setState({ selectedGroupId: event.target.value }) }} />

                {!!this.state.selectedGroupId && <UILIB.Button text={this.state.selectedOption + " the Subscribers"} onClick={this.goAction} />}
            </div>
        }
        if (this.state.selectedOption == "status") {
            return <div>
                <h4 className="mar-b25" style={{ textTransform: "capitalize" }}>Change the status</h4>
                <div className="mar-b25">Please select the new status for the  {this.props.rType} email addresses</div>

                <UILIB.Select outerClassName="mar-b25" value={this.state.selectedStatus} data={['Active', 'In-Active', 'Unsubscribed']} placeholder="Please select a status" onChange={(event) => { this.setState({ selectedStatus: event.target.value }) }} />

                {!!this.state.selectedStatus && <UILIB.Button text={"Update the Subscribers"} onClick={this.goAction} />}
            </div>
        }
        return <div>

            <h4 className="mar-b25" style={{ textTransform: "capitalize" }}>{this.props.rType} Email Addresses</h4>
            <div className="mar-b25">What would you like to do with the {this.props.rType} email addresses that were found</div>

            <ul className="list avatar with-lines with-arrow with-notification">
                <li className="middle-md" onClick={() => { this.clickOption("delete") }} >
                    <div className="notify">
                        <span>{this.props.count}</span>
                    </div>
                    <p>{i18n.t('delete')}</p>
                    <div className="arrow">
                        <span className="icon-chevron-right"></span>
                    </div>

                </li>
                {!this.props.isBlackList &&
                    <li className="middle-md" onClick={() => { this.clickOption("copy") }} id="toastCopy">

                        <div className="notify">
                            <span>{this.props.count}</span>
                        </div>
                        <p>{i18n.t('subscribers:overview.optionCopy')}</p>
                        <div className="arrow">
                            <span className="icon-chevron-right"></span>
                        </div>

                    </li>
                }
                {!this.props.isBlackList &&
                    <li className="middle-md" onClick={() => { this.clickOption("move") }} id="toastMove">

                        <div className="notify">
                            <span>{this.props.count}</span>
                        </div>
                        <p>{i18n.t('subscribers:overview.optionMove')}</p>
                        <div className="arrow">
                            <span className="icon-chevron-right"></span>
                        </div>

                    </li>}
                {!this.props.isBlackList &&
                    <li className="middle-md" onClick={() => { this.clickOption("status") }} id="toastStatus">
                        <div className="notify">
                            <span>{this.props.count}</span>
                        </div>
                        <p>{i18n.t('subscribers:overview.optionChange')}</p>
                        <div className="arrow">
                            <span className="icon-chevron-right"></span>
                        </div>
                    </li>
                }
            </ul>
        </div>

    };
};
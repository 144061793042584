import React from "react";

export default function IconStar({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.868 2.884C10.547 2.112 9.453 2.112 9.132 2.884L7.302 7.285L2.549 7.666C1.716 7.733 1.378 8.773 2.013 9.317L5.633 12.419L4.527 17.056C4.333 17.869 5.218 18.512 5.932 18.076L10 15.591L14.069 18.076C14.782 18.512 15.667 17.869 15.473 17.056L14.367 12.419L17.987 9.317C18.622 8.773 18.284 7.733 17.451 7.667L12.699 7.285L10.868 2.884Z"
        fill={color}
      />
    </svg>
  );
}

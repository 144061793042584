// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mxJiJI6tcjaK5qJlzjpn{display:flex;align-items:center;gap:16px}.mxJiJI6tcjaK5qJlzjpn form{flex:1}._5s_90dZ3HgbIhu9KhyO{display:flex;align-items:center;gap:8px;font-size:13px;font-weight:500;color:var(--grey-950)}.A_bK0WAIfYR9yu8a6RKD{font-weight:normal;color:var(--grey-800)}.U10DarBqjuyFjKaq99FQ{display:flex;justify-content:flex-end}.rKwWnHKzARXaIKJkCjRA{align-self:flex-start}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/inboxSettings/agents/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,2BACE,MAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA,CACA,eAAA,CACA,qBAAA,CAGF,sBACE,kBAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,wBAAA,CAGF,sBACE,qBAAA","sourcesContent":[".topRow {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n\n  form {\n    flex: 1;\n  }\n}\n\n.tableUser {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  font-size: 13px;\n  font-weight: 500;\n  color: var(--grey-950);\n}\n\n.tableUserEmail {\n  font-weight: normal;\n  color: var(--grey-800);\n}\n\n.tableActions {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.addButton {\n  align-self: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topRow": "mxJiJI6tcjaK5qJlzjpn",
	"tableUser": "_5s_90dZ3HgbIhu9KhyO",
	"tableUserEmail": "A_bK0WAIfYR9yu8a6RKD",
	"tableActions": "U10DarBqjuyFjKaq99FQ",
	"addButton": "rKwWnHKzARXaIKJkCjRA"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { Switch } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react'

import AccountTemplate from './accountTemplate.jsx'
import AccountDetails from './myDetails/myDetails'


export default class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <AccountTemplate history={this.props.history}>
            <Switch>
                <ApmRoute exact path="/cp/account" component={AccountDetails} />
            </Switch>
        </AccountTemplate>

    }
};


import React, { Component } from 'react'
import { DropTarget, DragSource } from 'react-dnd'
import ItemTypes from '../../../dragDropItemTypes'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import DeleteBlockDrawer from './deleteBlockDrawer'
import { connect } from 'react-redux';
import axios from '~/data/http/axios';

const dragSource = {
    beginDrag(props) {
        return { path: "", isNew: 0, content: props.content }
    },
    endDrag(props, monitor) {
    }
}

@DragSource(ItemTypes.NEWHOLDER, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
}))

//CONNECT TO STORE 
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster }
})

export default class NewHolderObject extends Component {
    constructor(props) {
        super(props)
        this.deleteBlock = this.deleteBlock.bind(this);
        this.goDeleteBlock = this.goDeleteBlock.bind(this);
    }

    deleteBlock(event) {
        event.stopPropagation();
        event.preventDefault();
        var drawerContent = <DeleteBlockDrawer reallyDelete={this.goDeleteBlock} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    goDeleteBlock() {
        var self = this;
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", undefined, false));
        axios
            .delete('/templateComponent/' + self.props.blockId)
            .then(function (res) {
                self.props.updateBlocks();
            })
    }
    render() {

        const { canDrop, isOver, connectDragSource, isDragging } = this.props
        if (this.props.type == "userBlock") {
            return connectDragSource(<div className="newElementObject" id={this.props.id} >
                <div style={{ display: "flex", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }}>
                    <div className="text-xsml">{this.props.title}</div>
                </div>
                <div className="icon-cross newElementCloseButt" onClick={this.deleteBlock} alt="Delete Block" title="Delete Block" />
            </div>)
        }
        return connectDragSource(

            <div className="newElementObject" id={this.props.id}  >
                <img src={this.props.toolbarIcon} className="newElementObject-icon" />
                <div className="newElementObject-text">{this.props.title}</div>
            </div>

        )

    }
}
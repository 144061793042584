import React, { Component } from 'react'
import MPZColorPicker from './colorPickerComponent.jsx'
import SiteVars from '~/Classes/siteVars';


export default class ComponentSelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showPicker: false,
            pickerX: 0,
            pickerY: 0,
            arrowStyle: {}
        }

        this.change = this.change.bind(this);
        this.showPicker = this.showPicker.bind(this);
        this.positionPicker = this.positionPicker.bind(this);
    }

    showPicker() {

        var isShown = this.state.showPicker;
        if (isShown === true) {
            isShown = false
            this.setState({ showPicker: isShown })

        }
        else {
            isShown = true;
            this.setState({ showPicker: isShown }, this.positionPicker)

        }
    }
    change(thisVal) {
        if (typeof thisVal === "object") {
            this.props.updateVal(this.props.path, thisVal.hex);
        }
        else {
            this.props.updateVal(this.props.path, thisVal);
        }
    }

    positionPicker(event) {
        var thePop = this.refs.colorPopper.getBoundingClientRect();
        var theEle = this.refs.theColorHolder.getBoundingClientRect();

        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        var popWidth = 140;
        var popHeight = 193;

        var theElePosX = theEle.x;
        var theElePosY = theEle.y;

        var finalX = theEle.width + 5;
        var finalY = theEle.height - 25;
        var arrowStyle = { borderRight: "10px solid #FFFFFF", borderLeft: "0", left: "-10px", right: "unset" }

        var finalPosW = theElePosX + popWidth + 20;
        var finalPosH = theElePosY + popHeight + 20;

        if (finalPosW + 10 > windowWidth) {
            finalX = (popWidth * -1) + 25;
            arrowStyle = { borderLeft: "10px solid #FFFFFF", borderRight: "0", left: "unset", right: "-10px" }
        }


        if (finalPosH > windowHeight) {
            finalY = (popHeight * -1) + 25;
            arrowStyle = { borderLeft: "10px solid #FFFFFF", borderRight: "0", left: "unset", right: "-10px" }
        }
        this.setState({ pickerX: finalX, pickerY: finalY, arrowStyle: arrowStyle })
    }


    render() {



        var currentColorStyle = {};
        if (this.props.value === "transparent") {
            currentColorStyle.backgroundImage = 'url(' + SiteVars.repoEndPoint + 'siteContent/assets/templates/common/empty-color.svg)';
        }
        else {
            currentColorStyle.backgroundColor = this.props.value;
        }

        var style = {};
        if (this.props.elementData.optionFullWidth) style = { flex: "100%" }

        return <div className="dd_toolHolder" style={style}>
            <div className="dd_inputTool">
                <div className="txtInput_label">{this.props.title}</div>
                <div className="inputWrapper">
                    <div className="colorHolder" ref="theColorHolder" onClick={this.showPicker}>
                        <div className="colorHolderInner" style={currentColorStyle}></div>
                    </div>
                </div>
                {this.state.showPicker && <div>
                    <div onClick={this.showPicker} style={{ position: "fixed", top: "0px", left: "0px", right: "0px", bottom: "0px", zIndex: '1' }} />
                    <div className="dd_colorPickerPop" ref="colorPopper" style={{ position: "absolute", left: this.state.pickerX, top: this.state.pickerY }}>
                        <div className="dd_colorPickerPop_arrow" style={this.state.arrowStyle} />
                        <MPZColorPicker color={this.props.value} onChange={this.change} disableAlpha={true} globalColours={this.props.globalColours} favoriteColors={this.props.favoriteColors} saveFaveColor={this.props.saveFaveColor} />

                    </div>
                </div>
                }


            </div>
        </div >

    }
}



import React from 'react';

export default class OneColumn extends React.Component {
    render() {
        var styles = {
            cls1: {
                fill: 'rgba(0,0,0,0.05)'
            },
            cls2: {
                fill: '#fff'
            },
            cls3: {
                fill: 'rgba(0,0,0,0.2)'
            },
            cls4: {
                fill: 'rgba(0,0,0,0.1)'
            },
            cls5: {
                filter: 'url(#Rectangle_202)'
            }
        }
        return <svg id="Group_268" data-name="Group 268" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="svg-holder" viewBox="17414 6582 253 257">
            <defs>
                <filter id="Rectangle_202" x="17425" y="6588" width="230" height="251" filterUnits="userSpaceOnUse">
                    <feOffset dy="-5" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.039" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <rect id="Rectangle_201" data-name="Rectangle 201" className="outer" width="253" height="253" rx="3" transform="translate(17414 6582)" />
            <g style={styles.cls5} transform="matrix(1, 0, 0, 1, 0, 0)">
                <path id="Rectangle_202-2" data-name="Rectangle 202" style={styles.cls2} d="M3,0H209a3,3,0,0,1,3,3V233a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(17434 6602)" />
            </g>
            <path id="Rectangle_220" data-name="Rectangle 220" className="inner" d="M3,0H54a3,3,0,0,1,3,3V43a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(17445 6792)" />
            <path id="Rectangle_221" data-name="Rectangle 221" className="inner" d="M3,0H54a3,3,0,0,1,3,3V43a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(17512 6792)" />
            <path id="Rectangle_222" data-name="Rectangle 222" className="inner" d="M3,0H54a3,3,0,0,1,3,3V43a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(17579 6792)" />
            <rect id="Rectangle_203" data-name="Rectangle 203" className="inner" width="57" height="60" rx="3" transform="translate(17445 6652)" />
            <rect id="Rectangle_204" data-name="Rectangle 204" className="inner" width="57" height="60" rx="3" transform="translate(17512 6652)" />
            <rect id="Rectangle_214" data-name="Rectangle 214" className="inner" width="57" height="60" rx="3" transform="translate(17579 6652)" />
            <rect id="Rectangle_205" data-name="Rectangle 205" style={styles.cls4} width="57" height="4" rx="2" transform="translate(17445 6732)" />
            <rect id="Rectangle_206" data-name="Rectangle 206" style={styles.cls4} width="57" height="4" rx="2" transform="translate(17512 6732)" />
            <rect id="Rectangle_215" data-name="Rectangle 215" style={styles.cls4} width="57" height="4" rx="2" transform="translate(17579 6732)" />
            <rect id="Rectangle_209" data-name="Rectangle 209" style={styles.cls4} width="57" height="4" rx="2" transform="translate(17445 6756)" />
            <rect id="Rectangle_210" data-name="Rectangle 210" style={styles.cls4} width="57" height="4" rx="2" transform="translate(17512 6756)" />
            <rect id="Rectangle_216" data-name="Rectangle 216" style={styles.cls4} width="57" height="4" rx="2" transform="translate(17579 6756)" />
            <rect id="Rectangle_207" data-name="Rectangle 207" style={styles.cls4} width="57" height="4" rx="2" transform="translate(17445 6744)" />
            <rect id="Rectangle_208" data-name="Rectangle 208" style={styles.cls4} width="57" height="4" rx="2" transform="translate(17512 6744)" />
            <rect id="Rectangle_217" data-name="Rectangle 217" style={styles.cls4} width="57" height="4" rx="2" transform="translate(17579 6744)" />
            <rect id="Rectangle_211" data-name="Rectangle 211" style={styles.cls4} width="38" height="4" rx="2" transform="translate(17445 6768)" />
            <rect id="Rectangle_212" data-name="Rectangle 212" style={styles.cls4} width="38" height="4" rx="2" transform="translate(17512 6768)" />
            <rect id="Rectangle_218" data-name="Rectangle 218" style={styles.cls4} width="38" height="4" rx="2" transform="translate(17579 6768)" />
            <rect id="Rectangle_219" data-name="Rectangle 219" className="inner" width="192" height="20" rx="3" transform="translate(17444 6612)" />
        </svg>


    }
}
import React from 'react'
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

export default class SendDailyUpdateEmail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sendUpdateEmailsRecipients: [{
                emailAddress: '',
                error: ''
            }],
            rnd: -1
        }

        this.updateCheckBox = this.updateCheckBox.bind(this);
        this.addUpdateEmail = this.addUpdateEmail.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.deleteEmail = this.deleteEmail.bind(this);
        this.sendDataBack = this.sendDataBack.bind(this);
    }

    componentDidMount() {

        var sendUpdateEmailsRecipients = [{
            emailAddress: '',
            error: ''
        }];

        if (this.props.campaign.sendUpdateEmailsRecipient) {
            if (this.props.campaign.sendUpdateEmailsRecipient && this.props.campaign.sendUpdateEmailsRecipient.length) {
                if (this.props.campaign.sendUpdateEmailsRecipient.indexOf("||") > -1) {
                    var upEmailData = this.props.campaign.sendUpdateEmailsRecipient.split("||");
                    sendUpdateEmailsRecipients = [];
                    upEmailData.forEach(emailAddress => {
                        if (emailAddress && emailAddress.length) {
                            sendUpdateEmailsRecipients.push({ emailAddress: emailAddress, error: '' })
                        }
                    })
                }
                else {
                    sendUpdateEmailsRecipients[0].emailAddress = this.props.campaign.sendUpdateEmailsRecipient;
                }
            }
        }
        this.setState({ sendUpdateEmailsRecipients })

    }



    updateCheckBox(theEvent) {
        var theVal = theEvent.target.value;
        var targetName = theEvent.target.name;
        if (theEvent.target.type == "checkbox") {
            theVal = false;
            if (theEvent.target.checked == true) {
                theVal = true;
            }
        }
        this.props.campaign[targetName] = theVal
        this.props.updateData();
        this.props.checkForm();
        this.setState({ rnd: Math.random() * 99999 })
    }

    addUpdateEmail() {
        var sendUpdateEmailsRecipients = this.state.sendUpdateEmailsRecipients;
        sendUpdateEmailsRecipients.push({ emailAddress: "", error: "" })
        this.setState({ sendUpdateEmailsRecipients }, this.sendDataBack)
    }

    updateEmail(dataId, value) {
        var sendUpdateEmailsRecipients = this.state.sendUpdateEmailsRecipients;
        sendUpdateEmailsRecipients[dataId] = {
            emailAddress: value,
            error: ""
        }
        this.setState({ rnd: Math.random() * 99999, sendUpdateEmailsRecipients }, this.sendDataBack)
    }

    deleteEmail(dataId) {
        var sendUpdateEmailsRecipients = this.state.sendUpdateEmailsRecipients;
        sendUpdateEmailsRecipients.splice(dataId, 1)
        this.setState({ rnd: Math.random() * 99999, sendUpdateEmailsRecipients }, this.sendDataBack)
    }

    sendDataBack() {
        var outP = "";
        this.state.sendUpdateEmailsRecipients.forEach(email => {
            outP += email.emailAddress + "||";
        })
        this.props.campaign.sendUpdateEmailsRecipient = outP;
        this.props.updateData();
        this.props.checkForm();
    }
    render() {

        return <div>
            <UILIB.CheckBox

                checked={this.props.campaign.sendUpdateEmails}
                name="sendUpdateEmails" onChange={this.updateCheckBox}
            >
                <span className="txtInput_label">{i18n.t('campaigns:standard.step3.sendUpdateFor7Days')}</span>
            </UILIB.CheckBox>

            {this.props.campaign.sendUpdateEmails &&

                <UILIB.Paper className="mar-t15">

                    <div className="txtBox_label">{i18n.t('campaigns:standard.step3.sendDailyUpdatesTo')}...</div>
                    <div className="quickFlex">
                        {this.state.sendUpdateEmailsRecipients.map((emailData, emailIndex) => {
                            return <UILIB.TextInput
                                outerClassName="mar-b10 mar-r10"
                                key={"emailUpdate_" + emailIndex}
                                name="sendUpdateEmailsRecipient"
                                error={emailData.error}
                                value={emailData.emailAddress}
                                placeholder="your@emailaddress.com"
                                onChange={(ev) => { this.updateEmail(emailIndex, ev.target.value) }}
                                iconRight={
                                    emailIndex > 0 && <UILIB.Icons
                                        style={{ height: "10px", width: "10px" }}
                                        color="red" icon="cross"
                                        onClick={() => { this.deleteEmail(emailIndex) }} />
                                }
                            />
                        })}
                    </div>

                    {this.state.sendUpdateEmailsRecipients.length < 5 && <div>
                        <a
                            className="quickFlex "
                            onClick={this.addUpdateEmail}
                            style={{ alignItems: "center" }}
                        >
                            <UILIB.Icons icon="plus" className="mar-r5" />
                            {i18n.t('templates:preview.add')}
                        </a>
                    </div>}
                </UILIB.Paper>

            }
        </div>

    }
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';

@connect((store) => {
    return { user: store.user }
})
export default class IncGoDeleteGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
            error: ''
        };

        this.deleteGroup = this.deleteGroup.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    deleteGroup() {
        this.setState({
            deleting: true,
            error: ''
        })
        if (this.props.groupData.Applications && this.props.groupData.Applications.length) {
            this.props.groupData.Applications.forEach(app => axios.delete('/application/account/' + app.id).catch(() => { }))
        }
        return axios.delete('/group/' + this.props.groupData.id).then(() => {
            this.props.deletedGroup();
            this.closeDrawer();
        }).catch(err => {
            this.setState({
                deleting: false,
                error: err.data.error
            })
        })
    }

    render() {
        var disableDelete = false;
        if (this.props.groupData.Applications.length || this.props.groupData.automations.length) disableDelete = true;

        if (this.state.deleting) return <div className="text-center"><UILIB.LoadingIcons /></div>

        return <div>

            <h4 className="mar-b25">{this.props.groupData.groupName}</h4>
            <div className="mar-b25">{i18n.t('subscribers:index.deleteSubHeader')}</div>
            {this.props.groupData.Applications && !!this.props.groupData.Applications.length && <UILIB.Paper className="paper-red  mar-b25">
                <h4 className="mar-b15" style={{ color: "white" }}>{i18n.t('subscribers:index.deleteGroupApplicationHeader')}</h4>
                <div style={{ color: "white" }}>{i18n.t('subscribers:index.deleteGroupApplicationSubHeader')}</div>
                <ul>
                    {this.props.groupData.Applications.map(gd => {
                        return <li style={{ color: "white" }}>{gd.appName}</li>
                    })}
                </ul>
            </UILIB.Paper>}

            {this.props.groupData.automations && !!this.props.groupData.automations.length && <UILIB.Paper className="paper-red mar-b25">
                <h4 className="mar-b15" style={{ color: "white" }}>{i18n.t('subscribers:index.deleteGroupAutomationHeader')}</h4>
                <div style={{ color: "white" }}>{i18n.t('subscribers:index.deleteGroupAutomationSubHeader')}</div>
                <ul>
                    {this.props.groupData.automations.map(gd => {
                        return <li style={{ color: "white" }}>{gd.name}</li>
                    })}
                </ul>
            </UILIB.Paper>}

            <div className="mar-b25">
                <UILIB.Button
                    iconLeft={<UILIB.Icons icon="cross" />}
                    disabled={this.state.deleting || disableDelete}
                    text={i18n.t('subscribers:index.deleteButton')}
                    className="button-red mar-b25"
                    onClick={this.deleteGroup} />
            </div>

            {
                this.state.deleting && <div>
                    <span className="text-grey text-sml loading">Deleting</span>
                </div>
            }
            {!!this.state.error && <div>
                <span className="text-red text-sml">{this.state.error}</span>
            </div>}

        </div >
    }
};


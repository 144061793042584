import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import Release from './release'
import axios from '../../../data/http/axios';
import UILIB from '../../../Common-Objects/Lib';
import DateTimeFunctions from '../../../Classes/dateTimeFunctions';

export default function ReleasesTemplate() {
    const { id } = useParams()
    const history = useHistory()
    const [releases, setReleases] = useState(null)

    useEffect(() => {
        axios.get('/release').then(res => {
            setReleases(res.data)
        })
    }, [])

    if (!releases) return <UILIB.LoadingIcons />

    let selected = id
    if (!selected && releases && releases.length) selected = releases[0].id

    return (
        <UILIB.Paper style={{ height: 'calc(100vh - 130px)', overflow: 'auto' }}>
            <div className='releases'>
                <div className="releases-list">
                    <ul>
                        <h4 className='mar-b25'>Releases</h4>
                        {releases.map(r => <li
                            key={r.id}
                            className={"releases-list-item " + (selected == r.id ? 'releases-list-item-selected' : '')}
                            onClick={() => history.push('/cp/releases/' + r.id)}
                        >
                            <div>v{r.version}</div><div>{DateTimeFunctions.formatDateTime(r.releaseDate, "2")}</div>
                        </li>)}
                    </ul>
                </div>
                <div className='releases-item'>
                    {!!selected && <Release id={selected} />}
                </div>
            </div>
        </UILIB.Paper>
    )
}
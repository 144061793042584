import React, { useState } from "react";
import TextInput from "../textInput";
import AlertDialog from "../alertDialog";
import i18n from "~/i18n";

export default function RenameAiAssistantDialog({
  assistant,
  isOpen,
  onSubmit,
  ...rest
}) {
  const [newName, setNewName] = useState(assistant?.name);
  const [submitting, setSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  async function handleSubmit() {
    setSubmitting(true);

    try {
      await validateForm();
      await onSubmit(assistant, newName);
    }
    catch (error) {
      return { error: true };
    } finally {
      setSubmitting(false);
    }
  }

  async function validateForm() {
    try {
      if (newName.length === 0) {
        throw i18n.t("chat:settings.ai.renameDialog.required");
      }

      setFormErrors(null);
    } catch (error) {
      setFormErrors({ newName: error });
      throw error;
    }
  }

  return (
    <AlertDialog
      open={isOpen}
      title={i18n.t("chat:settings.ai.renameDialog.header")}
      ctaText={i18n.t("chat:settings.ai.renameDialog.button")}
      ctaVariant="primary"
      submitting={submitting}
      onCtaClick={handleSubmit}
      {...rest}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextInput
          label={i18n.t("chat:settings.ai.renameDialog.name")}
          value={newName}
          disabled={submitting}
          // Issue with autofocus when opening dialog from a dropdown https://github.com/radix-ui/primitives/issues/1615
          autoFocus
          onChange={(e) => setNewName(e.target.value)}
          error={formErrors?.newName}
        />
      </form>
    </AlertDialog>
  );
}

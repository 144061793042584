import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import Button from "../../components/button";
import * as styles from "./index.module.scss";
import AddEditAgentDialog from "./components/addEditAgentDialog";
import Header from "../../components/header";
import PermissionChecker from '~/Classes/permissions'
import i18n from "~/i18n";

export default function InboxAgents({ inbox }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const permissionStore = useSelector((state) => state.permission);

  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddAgentDialogOpen, setIsAddAgentDialogOpen] = useState(false);
  const [isEditAgentDialogOpen, setIsEditAgentDialogOpen] = useState(false);

  const canAddUsers = PermissionChecker("accountUsers", permissionStore.permissions, "write");

  const [tableData, updateTableData] = useReducer((state, action) => {
    return formatTableDataReducer(state, action, {
      setSelectedAgent,
      setIsEditAgentDialogOpen,
      changeRole,
      hasChat: inbox.InboxChannels.some(c => c.type === 2)
    }, user.userID);
  }, []);

  useEffect(() => {
    loadInitialData();
  }, []);

  async function loadInitialData() {
    try {
      await loadAgents();
    } finally {
      setLoading(false);
    }
  }

  async function loadAgents() {
    try {
      const { data } = await axios.get(`/inboxes/${inbox.id}/user`);
      setAgents(data);
      updateTableData(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function changeRole(agentId, role) {
    try {
      await axios.put(`/inboxes/${inbox.id}/user/${agentId}`, {
        role
      });

      loadAgents();
      dispatch(alterSnackbar(true, i18n.t("chat:settings.agents.roleUpdated")));
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function addAgent(userId, role, maxChats) {
    try {
      await axios.post(`/inboxes/${inbox.id}/user`, {
        AccountUserId: userId,
        role,
        maxChats
      });

      loadAgents();
      setIsAddAgentDialogOpen(false);
      dispatch(alterSnackbar(true, i18n.t("chat:settings.agents.added")));
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function editAgent(agentId, role, maxChats) {
    try {
      await axios.put(`/inboxes/${inbox.id}/user/${agentId}`, {
        role,
        maxChats
      });

      loadAgents();
      setIsEditAgentDialogOpen(false);
      dispatch(alterSnackbar(true, i18n.t("chat:settings.agents.edited")));
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  useEffect(() => {
    const filteredData = agents.filter(({ AccountUser: user }) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      if (
        user.fullname.toLowerCase().includes(lowerCaseSearchTerm) ||
        user.emailAddress.toLowerCase().includes(lowerCaseSearchTerm)
      ) {
        return true;
      }

      return false;
    });

    updateTableData(filteredData);
  }, [searchTerm]);

  if (loading) return <UILIB.LoadingIcons noClass />;

  const hasChat = inbox.InboxChannels.some(c => c.type === 2)

  return (
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
      <Header type="inbox" option="agents" />
      {tableData.length > 0 && <UILIB.DataTable1 tableData={tableData} />}

      <Button
        className={styles.addButton}
        size="s"
        iconLeft={<UILIB.Icon name="external-link" />}
        onClick={() => { window.open('/cp/company/staff', '_blank') }}
        disabled={!canAddUsers}
      >
        {i18n.t("chat:settings.agents.add")}
      </Button>

      {/* Add agent dialog */}
      <AddEditAgentDialog
        hasChat={hasChat}
        agents={agents}
        isOpen={isAddAgentDialogOpen}
        onAddSubmit={addAgent}
        onOpenChange={(isOpen) => setIsAddAgentDialogOpen(isOpen)}
      />

      {/* Edit agent dialog */}
      <AddEditAgentDialog
        hasChat={hasChat}
        agentToEdit={selectedAgent}
        isOpen={isEditAgentDialogOpen}
        onEditSubmit={editAgent}
        onOpenChange={(isOpen) => setIsEditAgentDialogOpen(isOpen)}
      />
    </div>
  );
}

function formatTableDataReducer(state, action, options, userId) {
  return action.map((agent) => {
    const { AccountUser: user, role } = agent;

    return {
      agent: {
        headerValue: i18n.t("chat:settings.agents.user"),
        value: (
          <div
            className={styles.tableUser}
          >
            <UILIB.AvatarNew
              src={user.profilePicFileName}
              name={user.firstName}
              size={20}
            />

            {user.fullname}
            <span className={styles.tableUserEmail}>{user.emailAddress}</span>
          </div>
        ),
      },
      access: {
        headerValue: " ",
        align: "end",
        value: <UILIB.SelectNewDefault
          value={role}
          fullWidth={true}
          disabled={user.id === userId}
          data={[
            { label: i18n.t("chat:settings.agents.admin"), value: 0 },
            { label: i18n.t("chat:settings.agents.agent"), value: 1 },
            { label: i18n.t("chat:settings.agents.viewer"), value: 2 },
          ]}
          align="end"
          triggerProps={{
            className: "fw-medium",
          }}
          onValueChange={(val) => options.changeRole(agent.id, val)}
          size="s"
        />,
      },
    };
  });
}

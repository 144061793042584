import React from 'react'

export default class IconsWorld extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.083 9.00006H6.029C6.118 7.45406 6.412 6.03006 6.866 4.88206C6.13501 5.32998 5.50842 5.92925 5.02838 6.63956C4.54834 7.34988 4.22598 8.15476 4.083 9.00006ZM10 2.00006C7.87827 2.00006 5.84344 2.84292 4.34315 4.34321C2.84285 5.8435 2 7.87833 2 10.0001C2 12.1218 2.84285 14.1566 4.34315 15.6569C5.84344 17.1572 7.87827 18.0001 10 18.0001C12.1217 18.0001 14.1566 17.1572 15.6569 15.6569C17.1571 14.1566 18 12.1218 18 10.0001C18 7.87833 17.1571 5.8435 15.6569 4.34321C14.1566 2.84292 12.1217 2.00006 10 2.00006V2.00006ZM10 4.00006C9.924 4.00006 9.768 4.03206 9.535 4.26206C9.297 4.49606 9.038 4.88506 8.798 5.44406C8.409 6.35106 8.125 7.58606 8.032 9.00006H11.968C11.875 7.58606 11.591 6.35106 11.202 5.44406C10.962 4.88406 10.702 4.49606 10.465 4.26206C10.232 4.03206 10.076 4.00006 10 4.00006ZM13.971 9.00006C13.882 7.45406 13.588 6.03006 13.134 4.88206C13.865 5.32998 14.4916 5.92925 14.9716 6.63956C15.4517 7.34988 15.774 8.15476 15.917 9.00006H13.971ZM11.968 11.0001H8.032C8.125 12.4141 8.409 13.6491 8.798 14.5561C9.038 15.1161 9.298 15.5041 9.535 15.7381C9.768 15.9681 9.924 16.0001 10 16.0001C10.076 16.0001 10.232 15.9681 10.465 15.7381C10.703 15.5041 10.963 15.1151 11.202 14.5561C11.591 13.6491 11.875 12.4141 11.968 11.0001ZM13.134 15.1181C13.588 13.9711 13.882 12.5461 13.971 11.0001H15.917C15.774 11.8454 15.4517 12.6502 14.9716 13.3606C14.4916 14.0709 13.865 14.6701 13.134 15.1181V15.1181ZM6.866 15.1181C6.412 13.9701 6.118 12.5461 6.03 11.0001H4.083C4.22598 11.8454 4.54834 12.6502 5.02838 13.3606C5.50842 14.0709 6.13501 14.6701 6.866 15.1181V15.1181Z" fill={color} />
        </svg>




    }
}        
import React from "react";
import UILIB from "~/Common-Objects/Lib";
import supportTeamImage from "../../../../assets/images/misc/support-team.png";
import i18n from "~/i18n";

export default function SupportPanel() {
  return (
    <UILIB.Paper className="frontpage-support-panel">
      <img
        src={supportTeamImage}
        alt="Headshots of all 5 members of the Transpond support team."
        className="frontpage-support-panel__img mar-b10"
      />

      <h2 className="h4 mar-b5">
        {i18n.t("dashboard:gettingStarted.support.heading")}
      </h2>

      <div
        dangerouslySetInnerHTML={{ __html: i18n.t("dashboard:gettingStarted.support.body") }}
      />

      <div className="frontpage-support-panel__ctas mar-t10">
        <UILIB.Button
          href="https://transpond.io/contactus"
          target="_blank"
          iconLeft={<UILIB.Icons icon="envelope" />}
        >
          {i18n.t("dashboard:gettingStarted.support.contactCta")}
        </UILIB.Button>

        <UILIB.Button
          href="https://kb.transpond.io/"
          target="_blank"
          iconLeft={<UILIB.Icons icon="lifering" />}
        >
          {i18n.t("dashboard:gettingStarted.support.supportCta")}
        </UILIB.Button>
      </div>
    </UILIB.Paper>
  );
}

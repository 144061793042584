import axios from '../data/http/axios'
import i18n from '../i18n'
export default {
    createKey: async function (SubscriberId, SenderId, Random, CampaignId) {
        const result = await axios.post('/accountMaster/generateKey', {
            SubscriberId, SenderId, Random, CampaignId
        })
        return result.data.key
    }
    ,
    createLocalGuid: function () {

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c2) {
            var r2 = Math.random() * 16 | 0, v2 = c2 == 'x' ? r2 : (r2 & 0x3 | 0x8);
            return v2.toString(16);
        });

    },
    downloadFile: function (url, filename, type) {
        return axios({
            url: url,
            method: "GET",
            responseType: "arraybuffer" // important
        }).then(response => {
            var data = response.data
            var blobData = [data]
            var blob = new Blob(blobData, { type: type || 'application/octet-stream' });
            if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE workaround for "HTML7007: One or more blob URLs were 
                // revoked by closing the blob for which they were created. 
                // These URLs will no longer resolve as the data backing 
                // the URL has been freed."
                window.navigator.msSaveBlob(blob, filename);
            }
            else {
                var blobURL = window.URL.createObjectURL(blob);
                var tempLink = document.createElement('a');
                tempLink.style.display = 'none';
                tempLink.href = blobURL;
                tempLink.setAttribute('download', filename);

                // Safari thinks _blank anchor are pop ups. We only want to set _blank
                // target if the browser does not support the HTML5 download attribute.
                // This allows you to download files in desktop safari if pop up blocking 
                // is enabled.
                if (typeof tempLink.download === 'undefined') {
                    tempLink.setAttribute('target', '_blank');
                }

                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(blobURL);
            }
        });

    },
    findLinks: function (str) {
        const reg = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi
        const links = []
        while (true) {
            const result = reg.exec(str)
            if (result) links.push(result[0])
            else break
        }
        return links
    },
    getSmsCredits: function (campaign, template) {
        const tracking = !campaign.options || !campaign.options.doNotTrackLinks
        let tempHtml = template.templateHTML || template
        if (typeof tempHtml !== 'string') tempHtml = ""
        const links = this.findLinks(tempHtml)
        if (tracking) {
            links.forEach(l => {
                tempHtml = tempHtml.replace(l, 'mpsms.io/c/######')
            })
        }
        tempHtml = tempHtml.replace(/\{\{__OPTOUT\}\}/, 'mpsms.io/####')
        const length = tempHtml.length
        const messages = length <= 160 ? 1 : 1 + Math.ceil((length - 160) / 153)
        return { html: tempHtml, messages, links }
    },
    smsIsValidSubject(subject) {
        const result = { valid: true, messages: [] }
        if (!subject) {
            result.valid = false
            result.messages.push('From Name is required')
            return result
        }
        if (subject.length < 3 || subject.length > 11) {
            result.valid = false
            result.messages.push('From Name must be between 3 and 11 characters')
        }

        if (!/^[a-z0-9]+$/i.test(subject)) {
            result.valid = false
            result.messages.push('From Name can only contain numbers and letters')
        }
        return result
    },
    hexToRgba(hex, opacity = 1) {
        let c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${opacity})`;
    },
    safeRedirect(url, target, history) {
        console.log('safeRedirect', url)
        url = decodeURIComponent(url)
        console.log('safeRedirect', url)

        let isFullUrl = false;
        let fullUrlObj = {}
        let failed = false;
        try {
            fullUrlObj = new URL(url)
            isFullUrl = true;
        }
        catch (err) { }

        if (!isFullUrl) {
            if (url.indexOf('/\\') === 0) failed = true
        }

        if (isFullUrl) {
            if (fullUrlObj && fullUrlObj.origin != window.location.host) failed = true
        }
        if (!isFullUrl) {
            if (url.substr(0, 2) === "//") failed = true;
            if (url.substr(0, 4) === "\\/\\/") failed = true;
        }
        if (failed) {
            url = '/cp'
        }

        if (target == "self") {
            window.open(url, '_self')
            return;
        }
        if (target == "return") {
            return url
        }

        history.push(url)
    },
    getCampaignType(type) {
        var thisIcon = "envelope"
        var thisAlt = i18n.t('standardCampaign')
        switch (type) {
            case 3:
                thisIcon = "lightning"
                thisAlt = i18n.t('Automation Campaigns')
                break;
            case 10:
                thisIcon = "split"
                thisAlt = i18n.t('abSplitTest')
                break;
            case 20:
                thisIcon = "envelopeOpen"
                thisAlt = i18n.t('directMail')
                break;
            case 30:
                thisIcon = "share"
                thisAlt = "Social Post"
                break;
            case 60:
                thisIcon = "megaphone"
                thisAlt = "Ad Campaign"
                break;
            case 6:
                thisIcon = "sms"
                thisAlt = i18n.t('sms')
                break;
        }
        return { icon: thisIcon, alt: thisAlt }
    }
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XCZv5ADnscTZ13bS9kMl{display:flex;flex-direction:column;gap:12px}.DzFhP1kjDHNZtBgSoGYS{display:flex;align-items:center;justify-content:space-between;gap:12px;padding:14px 16px;border:1px solid var(--grey-200);border-radius:8px;position:relative}.SA5J4Oar33l6ohM4nwC3{text-decoration:none}.SA5J4Oar33l6ohM4nwC3::after{content:\"\";position:absolute;inset:0}.cYK3DURIov48TdEqnKLc{font-size:13px;line-height:normal;letter-spacing:normal}.WPV7ym3fE9TfVguazRRz{margin-left:auto}.KwpzMs9jFyh7VhOVPs8y{align-self:flex-start}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/inboxSettings/aiAssistants/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CACA,iBAAA,CACA,gCAAA,CACA,iBAAA,CACA,iBAAA,CAGF,sBACE,oBAAA,CAEA,6BACE,UAAA,CACA,iBAAA,CACA,OAAA,CAIJ,sBACE,cAAA,CACA,kBAAA,CACA,qBAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,qBAAA","sourcesContent":[".assistantsList {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.teaser {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 12px;\n  padding: 14px 16px;\n  border: 1px solid var(--grey-200);\n  border-radius: 8px;\n  position: relative;\n}\n\n.teaserLink {\n  text-decoration: none;\n\n  &::after {\n    content: \"\";\n    position: absolute;\n    inset: 0;\n  }\n}\n\n.teaserName {\n  font-size: 13px;\n  line-height: normal;\n  letter-spacing: normal;\n}\n\n.teaserBadge {\n  margin-left: auto;\n}\n\n.addButton {\n  align-self: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assistantsList": "XCZv5ADnscTZ13bS9kMl",
	"teaser": "DzFhP1kjDHNZtBgSoGYS",
	"teaserLink": "SA5J4Oar33l6ohM4nwC3",
	"teaserName": "cYK3DURIov48TdEqnKLc",
	"teaserBadge": "WPV7ym3fE9TfVguazRRz",
	"addButton": "KwpzMs9jFyh7VhOVPs8y"
};
export default ___CSS_LOADER_EXPORT___;

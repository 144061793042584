import React from "react";

export default function IconCross({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46447 6.46446C6.652 6.27693 6.90636 6.17157 7.17157 6.17157C7.43679 6.17157 7.69114 6.27693 7.87868 6.46446L10 8.58578L12.1213 6.46446C12.3089 6.27693 12.5632 6.17157 12.8284 6.17157C13.0936 6.17157 13.348 6.27693 13.5355 6.46446C13.7231 6.652 13.8284 6.90635 13.8284 7.17157C13.8284 7.43679 13.7231 7.69114 13.5355 7.87868L11.4142 10L13.5355 12.1213C13.7231 12.3089 13.8284 12.5632 13.8284 12.8284C13.8284 13.0936 13.7231 13.348 13.5355 13.5355C13.348 13.7231 13.0936 13.8284 12.8284 13.8284C12.5632 13.8284 12.3089 13.7231 12.1213 13.5355L10 11.4142L7.87868 13.5355C7.69114 13.7231 7.43679 13.8284 7.17157 13.8284C6.90636 13.8284 6.652 13.7231 6.46447 13.5355C6.27693 13.348 6.17157 13.0936 6.17157 12.8284C6.17157 12.5632 6.27693 12.3089 6.46447 12.1213L8.58579 10L6.46447 7.87868C6.27693 7.69114 6.17157 7.43679 6.17157 7.17157C6.17157 6.90635 6.27693 6.652 6.46447 6.46446Z"
        fill={color}
      />
    </svg>
  );
}

import React from 'react'
import UILIB from '~/Common-Objects/Lib';
import SiteVars from '~/Classes/siteVars';
import ReactTooltip from 'react-tooltip'

export default class Avatar extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        var thisStyle = {};
        if (this.props.style) thisStyle = JSON.parse(JSON.stringify(this.props.style));
        var thisSRC = SiteVars.repoEndPoint + this.props.src;
        if (this.props.src && this.props.src.indexOf('http') == 0) thisSRC = this.props.src;
        thisStyle.backgroundImage = `url(${thisSRC})`;
        if (this.props.uploadProgress > 0) {
            thisStyle.opacity = "0.9";
        }
        if (this.props.onClick) {
            thisStyle.cursor = "pointer";
        }

        let className = "avatarHolder";
        if (this.props.className) className += " " + this.props.className;

        return <div className={className} style={thisStyle} data-tip data-for={this.props.for || this.props.tooltip ? 'avatar' + this.props.for : undefined} onClick={this.props.onClick}>
            {this.props.iconSrc && <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {this.props.iconSrc}
            </div>}
            {!!this.props.tooltip && <ReactTooltip effect='solid' className={"tooltip " + this.props.className} id={'avatar' + this.props.for} >{this.props.tooltip}</ReactTooltip>}
            <div className="avatarHolderInner">

                {
                    (() => {
                        if (this.props.uploadProgress > 0 && this.props.uploadProgress < 100) {
                            return <div style={{ width: "50%", position: "absolute", top: "50%", left: "25%" }}>
                                <UILIB.SmallLineGraph width="100%" height="2" val1={this.props.uploadProgress || 0} val2={100} hideText={true} />
                            </div>
                        }
                    })()
                }
            </div>
        </div >
    }
}

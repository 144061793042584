import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import NumberFormatFunctions from '~/Classes/numberFormatFunctions';
import { connect } from 'react-redux';

import RequiresSiteTracking from '../components/requiresSiteTracking'
import GenericLineGraph from '../graphs/genericLineGraph'

//CONNECT TO STORE
@connect((store) => {
	return { siteMaster: store.siteMaster, user: store.user, accountMaster: store.accountMaster }
})

export default class EventsView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			campaignId: null,
			campaign: {},
			pageLoaded: false,
			eventDataTimeLine: [],
			eventsData: [],
			eventsTable: []
		};
		this.getEvents = this.getEvents.bind(this);
	}

	componentDidMount() {
		this._ismounted = true;
		this.setState({
			campaign: this.props.campaign,
			campaignId: this.props.match.params.campaignId
		}, this.getEvents)
	}

	componentDidUpdate(prevProps) {
		if (this.props.startDate != prevProps.startDate || this.props.endDate != prevProps.endDate) {
			this.getEvents()
		}
	}

	async getEvents() {
		try {
			let eventDataTimeLine = await axios.get(`/campaign/${this.props.campaignId}/events/graphdata?startDate=${this.props.startDate.format("YYYY-MM-DD")}&endDate=${this.props.endDate.format("YYYY-MM-DD")}`)
			eventDataTimeLine = eventDataTimeLine.data;

			let eventsData = await axios.get(`/campaign/${this.props.campaignId}/events?startDate=${this.props.startDate.format("YYYY-MM-DD")}&endDate=${this.props.endDate.format("YYYY-MM-DD")}`)
			eventsData = eventsData.data;
			this.setState({ pageLoaded: true, eventDataTimeLine, eventsData }, this.generateEventsTable)
		}
		catch (err) {
			console.log(err);
		}
	}

	async generateEventsTable() {
		const eventsTable = this.state.eventsData.map(row => {
			return {
				name: {
					headerValue: "Event",
					value: row.eventName
				},
				hits: {
					headerValue: "Hits",
					value: row.count
				},
				value: {
					headerValue: "Value",
					value: this.props.accountMaster.accountMaster.currencySymbol + NumberFormatFunctions.formatNumber(row.totalValue, 2)
				}
			}
		})
		this.setState({ eventsTable })
	}
	render() {

		if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />
		if (!this.props.hasSiteTracking) return <RequiresSiteTracking history={this.props.history} />
		return <div>
			<UILIB.Row className="mar-b25">
				<UILIB.Column xs={12} sm={8}>
					<UILIB.Paper style={{ height: "100%" }}>
						<GenericLineGraph
							title={"Events Timeline"}
							data={this.state.eventDataTimeLine}
							height={200}
						/>
					</UILIB.Paper>
				</UILIB.Column>
				<UILIB.Column xs={12} sm={4}>
					<UILIB.Paper style={{ height: "100%" }}>
						<h4 className="mar-b25">What are Events</h4>
						<div className="mar-b10">
							<strong>Events can be sent in via <a onClick={() => { this.props.history.push('/cp/trackers') }}>Site Tracking</a> when customers trigger things like Payments or Registrations on your site or service.</strong>
						</div>
						<div className="mar-b25">
							Once events are being sent in via Site Tracking, you can start to find out just how succesful your campaigns are both in terms of
							conversion (registrations) and revenue (payments).
						</div>
						<UILIB.Button
							text="Get Started with Events"
							onClick={() => { this.props.history.push('/cp/trackers/events') }}
							iconRight={<UILIB.Icons icon="arrowRight" />}
						/>
					</UILIB.Paper>
				</UILIB.Column>
			</UILIB.Row>
			<UILIB.Row>
				<UILIB.Column xs={12}>
					<UILIB.Paper>
						<UILIB.DataTable1
							pageSize={10}
							noResultsTxt={"There were no events for the date range selected"}
							tableData={this.state.eventsTable}
							dataUpdater={() => { }}
							loadingData={this.state.pageLoaded}
							sortedColumn={""}
							sortedDirection={""}
							width="100%"
							hasCheckBoxes={true}
							className="mar-b15"
						/>
					</UILIB.Paper>
				</UILIB.Column>
			</UILIB.Row>

		</div >
	}
}
import React, { useEffect, useState } from 'react'
import EmailValidator from '../../../../Classes/emailValidator'
import UILIB from '../../../../Common-Objects/Lib'
import axios from '../../../../data/http/axios'

export default function DomainChangeEmail({ id, finish }) {
    const [domain, setDomain] = useState(null)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState('')

    const update = (e) => {
        setDomain({ ...domain, originalEmailAddress: e.currentTarget.value + '@' + domain.domainName })
    }

    const save = async () => {
        setError('')
        if (!EmailValidator.validateEmail(domain.originalEmailAddress)) {
            return setError('Invalid Email Address')
        }
        setSaving(true)
        await axios.put('/accountMaster/domain/' + id, {
            originalEmailAddress: domain.originalEmailAddress
        })
        setSaving(false)
        finish()
    }

    useEffect(() => {
        axios.get('/accountMaster/domain/' + id).then(res => setDomain(res.data))
    }, [id])

    if (!domain) return <UILIB.LoadingIcons />

    const split = domain.originalEmailAddress.split('@')
    const local = split.length > 1 ? split[0] : ''

    return (
        <div>
            <h3 className='mar-b20'>Change Preview Email Address</h3>
            <div className='mar-b20'>When sending preview emails for templates this will be the from address</div>

            <div className="quickFlex mar-b20">
                <div style={{ flexGrow: 1 }}>
                    <UILIB.TextInput value={local} onChange={update} placeholder="no-reply" />
                </div>
                <div style={{ flexGrow: 1, padding: '0 10px' }}>
                    @{domain.domainName}
                </div>
            </div>

            <div className="quickFlex">
                <UILIB.Button onClick={save} saving={saving}>Save</UILIB.Button>
                {!!error && <div className='text-red mar-l10'>{error}</div>}
            </div>
        </div>
    )
}
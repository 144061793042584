import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from '../../../../data/http/axios';
import UILIB from '../../../../Common-Objects/Lib';
import CreateSub from './createSubSelectGroup'
import CreateAutomation from '../automations/automationThemeSelector'
import CreateTemplate from '../../templates/add/templatesAdd'
import CreateCampaign from '../../campaigns/add/drawerAddCampaignStep1'
import { alterSiteDrawer } from '../../../../data/actions/siteActions';
import Rum from '../../../../Classes/rum';

export default function Progress() {
    const dispatch = useDispatch()
    const history = useHistory()
    const timer = useRef(null)
    const [progress, setProgress] = useState(null);
    const [open, setOpen] = useState(false);

    const getProgress = async () => {
        const res = await axios.get('/accountMaster/progress')
        setProgress(res.data)
    }

    const complete = async () => {
        Rum.trackEvent('getting_started_complete')
        await axios.put('/accountMaster/progress')
        getProgress()
    }


    const importContacts = () => {
        setOpen(false)
        const content = <CreateSub />
        dispatch(alterSiteDrawer(true, true, 'right', content, true, 500))
    }

    const brand = () => {
        setOpen(false)
        history.push('/cp/brandanalyzer')
    }

    const template = () => {
        setOpen(false)
        const content = <CreateTemplate history={history} />
        dispatch(alterSiteDrawer(true, true, 'right', content, true, '100vw'))
    }

    const campaign = () => {
        setOpen(false)
        const content = <CreateCampaign history={history} />
        dispatch(alterSiteDrawer(true, false, 'right', content, true, 500, 'drawer_nopad'))
    }

    const automation = () => {
        setOpen(false)
        function selectTheme(theme) {
            axios.post('/automation/theme/' + theme.id).then(response => {
                history.push('/cp/automation/addnew/' + response.data.Automation.id)
            })
        }
        const content = <CreateAutomation scratch={() => history.push('/cp/automation/addnew')} select={selectTheme} />
        dispatch(alterSiteDrawer(true, true, 'right', content, true, '100vw'))
    }

    const tracking = () => {
        setOpen(false)
        history.push('/cp/trackers')
    }

    const users = () => {
        setOpen(false)
        history.push('/cp/company/staff')
    }

    useEffect(() => {
        getProgress()
        timer.current = setInterval(getProgress, 60000)
        return function () {
            clearInterval(timer.current)
        }
    }, [])

    useEffect(() => {
        if (progress && Object.keys(progress).every(k => progress[k])) {
            clearInterval(timer.current)
        }
    }, [progress])

    useEffect(() => {
        if (open) getProgress()
    }, [open])

    if (!progress) return null

    let total = 0
    let finished = 0

    for (const prop in progress) {
        total++
        if (progress[prop]) finished++
    }

    if (total === finished) return null

    return (
        <>
            <UILIB.Button iconLeft={<UILIB.Icons icon="rocket" />} gaEvent="getting_started" onClick={() => setOpen(true)}>
                Get Started
                <UILIB.SquareChip className="square-chip-small square-chip-purple mar-l10">{finished}/{total}</UILIB.SquareChip>

            </UILIB.Button>
            <UILIB.DropDown isOpen={open} onClose={() => setOpen(false)} style={{ top: 25, width: 300 }}>
                <div style={{ padding: 16 }}>
                    <div style={{ fontWeight: 600, fontSize: 16, lineHeight: "24px", marginBottom: 4 }}>Getting Started</div>
                    <div style={{ fontSize: 12, lineHeight: "16px", marginBottom: 16, color: 'var(--grey-800)' }}>Get the most out of Transpond by completing the following tasks</div>
                    <div style={{ fontSize: 13, lineHeight: "16px", fontWeight: 600, color: 'var(--grey-900)' }}>
                        <UILIB.SmallLineGraph color="#7950EA" width="100%" height="4" val1={finished} val2={total}
                            overrideText={`${Math.round((100 / total) * finished)}% complete`} />
                    </div>
                </div>
                <Divider />
                <div style={{ padding: '8px 0', display: 'flex', flexDirection: 'column' }}>
                    <Feature label="Import your Contacts" name="import" done={progress.import} icon="upload" onClick={importContacts} />
                    <Feature label="Run the Brand Analyzer" name="brand" done={progress.brand} icon="palette" onClick={brand} />
                    <Feature label="Create a Template" name="template" done={progress.template} icon="template" onClick={template} />
                    <Feature label="Create an Email Campaign" name="campaign" done={progress.campaign} icon="envelopeOpen" onClick={campaign} />
                    <Feature label="Create an Automation" name="automation" done={progress.automation} icon="lightning" onClick={automation} />
                    <Feature label="Add Site Tracking" name="tracking" done={progress.tracking} icon="globe" onClick={tracking} />
                    <Feature label="Invite a Teammate" name="user" done={progress.users} icon="personAdd" onClick={users} />
                </div>
                <Divider />
                <div style={{ padding: 16 }}>
                    <a style={{ fontSize: 12, lineHeight: "16px", fontWeight: 500 }} onClick={complete}>Mark all as complete</a>
                </div>
            </UILIB.DropDown>
        </>
    )
}

function Divider() {
    return <div style={{ borderTop: '1px solid var(--grey-200)', width: '100%', height: 1 }}></div>
}

function Feature({ label, done, icon, name, onClick = () => { } }) {
    return <a
        className={"quickFlex " + (!done ? 'hoverable' : '')}
        style={{ alignItems: 'center', padding: '8px 16px' }}
        name={name}
        onClick={() => {
            if (done) return
            Rum.trackEvent('getting_started_' + name)
            onClick()
        }}
    >
        <UILIB.Icons style={{ marginRight: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }} icon={done ? 'tickCircle' : icon} color={done ? 'var(--teal-500)' : 'var(--grey-600)'} />
        <div style={{ flexGrow: 1, color: done ? 'var(--teal-500)' : 'var(--grey-950)', fontWeight: 500 }}>{label}</div>
        {!done && <UILIB.Icons onClick={onClick} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} icon="arrowRight" color="var(--grey-600)" />}
    </a>
}
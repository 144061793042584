import React from 'react'

export default function IconTextArea({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";

    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.5 5.00006C17.5 4.86745 17.4473 4.74028 17.3536 4.64651C17.2598 4.55274 17.1326 4.50006 17 4.50006L3 4.50006C2.86739 4.50006 2.74022 4.55274 2.64645 4.64651C2.55268 4.74028 2.5 4.86745 2.5 5.00006L2.5 15.0001C2.5 15.1327 2.55268 15.2598 2.64645 15.3536C2.74021 15.4474 2.86739 15.5001 3 15.5001L17 15.5001C17.1326 15.5001 17.2598 15.4474 17.3536 15.3536C17.4473 15.2598 17.5 15.1327 17.5 15.0001L17.5 5.00006ZM18.4142 3.58585C18.7893 3.96092 19 4.46963 19 5.00006L19 15.0001C19 15.5305 18.7893 16.0392 18.4142 16.4143C18.0391 16.7893 17.5304 17.0001 17 17.0001L3 17.0001C2.46957 17.0001 1.96086 16.7893 1.58579 16.4143C1.21071 16.0392 0.999999 15.5305 0.999999 15.0001L1 5.00006C1 4.46963 1.21071 3.96092 1.58579 3.58585C1.96086 3.21077 2.46957 3.00006 3 3.00006L17 3.00006C17.5304 3.00006 18.0391 3.21077 18.4142 3.58585Z" fill={fillColor} />
            <path d="M6.622 10.19L6.364 9.164H5.08L4.822 10.19H4L5.188 6.002H6.28L7.468 10.19H6.622ZM5.896 7.508L5.764 6.782H5.668L5.536 7.508L5.29 8.474H6.154L5.896 7.508Z" fill={fillColor} />
            <path d="M7.95498 5.75H8.79498V7.562H8.89098C8.90698 7.478 8.93698 7.4 8.98098 7.328C9.02898 7.256 9.08498 7.194 9.14898 7.142C9.21298 7.086 9.28498 7.044 9.36498 7.016C9.44498 6.984 9.52698 6.968 9.61098 6.968C9.97498 6.968 10.241 7.106 10.409 7.382C10.581 7.658 10.667 8.07 10.667 8.618C10.667 9.166 10.583 9.578 10.415 9.854C10.247 10.126 9.97898 10.262 9.61098 10.262C9.52698 10.262 9.44498 10.246 9.36498 10.214C9.28498 10.186 9.21298 10.146 9.14898 10.094C9.08498 10.038 9.02898 9.974 8.98098 9.902C8.93698 9.83 8.90698 9.752 8.89098 9.668H8.79498V10.19H7.95498V5.75ZM9.27498 9.578C9.43098 9.578 9.55498 9.53 9.64698 9.434C9.74298 9.334 9.79098 9.194 9.79098 9.014V8.216C9.79098 8.036 9.74298 7.898 9.64698 7.802C9.55498 7.702 9.43098 7.652 9.27498 7.652C9.12298 7.652 9.00498 7.688 8.92098 7.76C8.83698 7.828 8.79498 7.932 8.79498 8.072V9.158C8.79498 9.298 8.83698 9.404 8.92098 9.476C9.00498 9.544 9.12298 9.578 9.27498 9.578Z" fill={fillColor} />
            <path d="M12.4058 10.262C11.9658 10.262 11.6338 10.128 11.4098 9.86C11.1858 9.592 11.0738 9.178 11.0738 8.618C11.0738 8.058 11.1858 7.644 11.4098 7.376C11.6338 7.104 11.9658 6.968 12.4058 6.968C12.7178 6.968 12.9658 7.04 13.1498 7.184C13.3378 7.324 13.4718 7.524 13.5518 7.784L12.8738 8.084C12.8538 7.932 12.8078 7.82 12.7358 7.748C12.6638 7.672 12.5538 7.634 12.4058 7.634C12.1018 7.634 11.9498 7.814 11.9498 8.174V9.056C11.9498 9.416 12.1018 9.596 12.4058 9.596C12.5578 9.596 12.6698 9.558 12.7418 9.482C12.8178 9.402 12.8738 9.282 12.9098 9.122L13.5638 9.41C13.3918 9.978 13.0058 10.262 12.4058 10.262Z" fill={fillColor} />
        </svg>
    )
}
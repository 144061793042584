import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import SiteVars from '~/Classes/siteVars';
import InfiniteScroll from 'react-infinite-scroller';
import ReactTooltip from 'react-tooltip'
import DateTimeFunctions from '~/Classes/dateTimeFunctions'
import i18n from '~/i18n'
import { connect } from 'react-redux';
@connect((store) => {
    return { accountMaster: store.accountMaster }
})

class FileDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            files: []
        }
        this.goCheckBox = this.goCheckBox.bind(this);
    }

    componentDidMount() {
        this.setState({ files: this.props.files })
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.files) != JSON.stringify(prevProps.files)) {
            this.setState({ files: this.props.files });
        }
    }

    goCheckBox(event, index) {
        var thisFiles = this.state.files;
        thisFiles[index].isChecked = event.target.checked;
        this.setState({ files: thisFiles })
    }


    render() {
        const loader = <UILIB.LoadingLine key={0}></UILIB.LoadingLine>;
        if (this.props.files.length === 0 && this.props.searching) return <div className="infinite-holder"><UILIB.LoadingIcons iconType="2" /></div>
        var filesTicked = this.state.files.filter(theFile => theFile.isChecked);

        return (
            <div className="filemanager-tiles-listview-holder">

                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => this.props.loadMore(false)}
                    hasMore={this.props.hasMore}
                    loader={loader}
                    useWindow={false}
                    className="infinite-scroller" >

                    {!this.state.files.length && <div className="filemanager-tiles-listview-nofiles">
                        <div><strong>{i18n.t('templates:fileManager.details.noImages')}</strong></div>
                        {i18n.t('templates:fileManager.details.noImagesDetail')}
                    </div>}

                    {this.state.files.map((file, index) => {
                        var classes = "filemanager-tiles-listview-line";
                        var selected = (this.props.currentFile && this.props.currentFile.fileId == file.fileId)
                        if (selected) classes += " selected";
                        var url = SiteVars.repoEndPoint + file.filePath + encodeURIComponent(file.fileName);
                        var isImage = true;
                        var isSystem = false;
                        if (file.fileExtension == "SYS") {
                            isSystem = true;
                            if (file.fileName == "{{__COMPANYLOGO}}") {
                                url = SiteVars.repoEndPoint + this.props.accountMaster.accountMaster.companyLogo + '?' + this.props.tick;
                            }
                        }
                        if (file.fileExtension == "pdf") isImage = false;
                        var isChecked = file.isChecked ? file.isChecked : false;

                        return <div className={classes} >
                            <div className="filemanager-tiles-listview-line-tickbox" style={{ width: "20px" }}>
                                {!isSystem && <UILIB.CheckBox onClick={(event) => { this.goCheckBox(event, index) }} checked={isChecked} />}
                            </div>
                            {isImage && <div className="filemanager-tiles-listview-line-image" style={{ backgroundImage: "url('" + url + "')" }} />}
                            {isImage && <img src={url} style={{ display: "none" }} id={"aviaryImg_" + index} />}
                            {!isImage && <div className="filemanager-tiles-listview-line-image" style={{ padding: "10px" }}><div className="icon-file-empty" style={{ fontSize: "24px" }}></div></div>}
                            <div className="filemanager-tiles-listview-line-details">
                                <div className="name"><a onClick={() => this.props.select(file)}>{file.friendlyName}</a></div>
                                <div className="created uppercase">{i18n.t('templates:fileManager.details.date')} {DateTimeFunctions.formatDateTime(file.dateAdded, 2)}</div>
                            </div>
                            <div className="filemanager-tiles-listview-line-controls">
                                {!isSystem && <UILIB.Icons icon="copyClipboard" onClick={() => this.props.copy(SiteVars.repoEndPoint + file.filePath + file.fileName)} alt={i18n.t('templates:fileManager.details.copy')} title={i18n.t('templates:fileManager.details.copy')} />}
                                {!isSystem && <UILIB.Icons icon="folder" onClick={() => this.props.move(file)} alt={i18n.t('templates:fileManager.details.move')} title={i18n.t('templates:fileManager.details.move')} />}
                                {!isSystem && <UILIB.Icons icon="pencil" onClick={() => this.props.rename(file)} alt={i18n.t('templates:fileManager.details.rename')} alt={i18n.t('templates:fileManager.details.rename')} title={i18n.t('templates:fileManager.details.rename')} />}
                                {!isSystem && isImage && <UILIB.Icons icon="wand" onClick={() => this.props.aviaryEdit("aviaryImg_" + index, file)} alt={i18n.t('templates:fileManager.details.edit')} title={i18n.t('templates:fileManager.details.edit')} />}
                                {!isSystem && <UILIB.Icons icon="bin" onClick={() => this.props.delete(file)} alt={i18n.t('templates:fileManager.details.delete')} title={i18n.t('templates:fileManager.details.delete')} />}

                            </div>
                        </div>
                    })}

                    {this.props.searching && <UILIB.LoadingLine key={0}></UILIB.LoadingLine>}

                </InfiniteScroll>
                {/* {filesTicked.length > 0 && <UILIB.Button className="button-sml" text={"Delete " + filesTicked.length + " File(s)"} onClick={() => { this.props.delete(filesTicked) }} />} */}
                {filesTicked.length > 0 &&
                    <div className="file-manager-footer">
                        <UILIB.Button className="button-sml" text={filesTicked.length === 1 ? i18n.t('templates:fileManager.deleteSingle') : i18n.t('templates:fileManager.deleteMultiple', { count: filesTicked.length })} onClick={() => { this.props.delete(filesTicked) }} />
                    </div>
                }
            </div>
        );
    }
}

export default FileDetails;
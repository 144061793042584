import React from 'react'

export default function IconsOrganisations({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 17.9576C15.0915 17.9854 15.1878 18 15.2857 18H18.0714C18.3177 18 18.5539 17.9078 18.728 17.7437C18.9022 17.5796 19 17.3571 19 17.125C19 16.8929 18.9022 16.6704 18.728 16.5063C18.5539 16.3422 18.3177 16.25 18.0714 16.25V5.75C18.0714 5.28587 17.8758 4.84075 17.5275 4.51256C17.1792 4.18437 16.7068 4 16.2143 4H15V17.9576Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V16C1.73478 16 1.48043 16.1054 1.29289 16.2929C1.10536 16.4804 1 16.7348 1 17C1 17.2652 1.10536 17.5196 1.29289 17.7071C1.48043 17.8946 1.73478 18 2 18H5C5.26522 18 5.51957 17.8946 5.70711 17.7071C5.89464 17.5196 6 17.2652 6 17V15C6 14.7348 6.10536 14.4804 6.29289 14.2929C6.48043 14.1054 6.73478 14 7 14H9C9.26522 14 9.51957 14.1054 9.70711 14.2929C9.89464 14.4804 10 14.7348 10 15V17C10 17.2652 10.1054 17.5196 10.2929 17.7071C10.4804 17.8946 10.7348 18 11 18H14V4C14 3.46957 13.7893 2.96086 13.4142 2.58579C13.0391 2.21071 12.5304 2 12 2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579ZM7 5H5V7H7V5ZM5 9H7V11H5V9ZM11 5H9V7H11V5ZM9 9H11V11H9V9Z" fill={fillColor} />
        </svg>
    )
}
import React from "react";

export default function IconArrowRight({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.293 5.29303C10.4805 5.10556 10.7348 5.00024 11 5.00024C11.2652 5.00024 11.5195 5.10556 11.707 5.29303L15.707 9.29303C15.8945 9.48056 15.9998 9.73486 15.9998 10C15.9998 10.2652 15.8945 10.5195 15.707 10.707L11.707 14.707C11.5184 14.8892 11.2658 14.99 11.0036 14.9877C10.7414 14.9854 10.4906 14.8803 10.3052 14.6948C10.1198 14.5094 10.0146 14.2586 10.0123 13.9964C10.01 13.7342 10.1108 13.4816 10.293 13.293L12.586 11H5C4.73478 11 4.48043 10.8947 4.29289 10.7071C4.10536 10.5196 4 10.2652 4 10C4 9.73481 4.10536 9.48046 4.29289 9.29292C4.48043 9.10539 4.73478 9.00003 5 9.00003H12.586L10.293 6.70703C10.1055 6.5195 10.0002 6.26519 10.0002 6.00003C10.0002 5.73487 10.1055 5.48056 10.293 5.29303Z"
        fill={color}
      />
    </svg>
  );
}

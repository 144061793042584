import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import axios from '../../../../../data/http/axios'
import UILIB from '../../../../../Common-Objects/Lib'
import * as siteActions from '../../../../../data/actions/siteActions'
import DeleteOrg from '../remove'
import Subscribers from './subscribers'
import Tags from './tags'
import Fields from './fields'
import PermissionChecker from '../../../../../Classes/permissions'

export default function Organisation() {
    const params = useParams()
    const [organisation, setOrganisation] = useState(null)
    const [apps, setApps] = useState([])
    const dispatch = useDispatch()
    const history = useHistory()
    const permissionStore = useSelector(state => state.permission)

    const getOrg = async () => {
        const res = await axios.get('/organisation/' + params.id)
        setOrganisation(res.data.Organisation)
    }

    const getApps = async () => {
        const res = await axios.get('/organisation/' + params.id + '/application')
        setApps(res.data)
    }

    const deleteOrg = () => {
        const drawer = <DeleteOrg organisation={organisation} onFinish={r => {
            dispatch(siteActions.alterSiteDrawer(false))
            if (r) history.push('/cp/subscribers/organisations')
        }} />
        dispatch(siteActions.alterSiteDrawer(true, true, 'right', drawer, true, 400))
    }

    useEffect(() => {
        getOrg()
        getApps()
    }, [params.id])


    const writeAccess = PermissionChecker('subscribers', permissionStore.permissions, 'write')

    if (!organisation) return <UILIB.LoadingIcons iconType="2" />

    return (
        <div>
            <UILIB.Row>
                <UILIB.Column xs={12} sm={6} className="mar-b25">
                    <h4>{organisation.name}</h4>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="mar-b25 center-xs end-sm">
                    {apps.map(a => <UILIB.Button iconRight={<UILIB.Icons icon="newWindow" />} key={a.Application.id} text={"View in " + a.Application.appName} className="mar-r10" onClick={() => window.open(a.link, '_blank')} />)}
                    {writeAccess && <UILIB.Button text="Delete" className="button-red" onClick={deleteOrg} iconRight={<UILIB.Icons icon="cross" />} />}
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={4}>
                    <Tags organisation={organisation} />
                    <Fields organisation={organisation} refresh={getOrg} />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={8}>
                    <Subscribers />
                </UILIB.Column>
            </UILIB.Row>

        </div>
    )
}
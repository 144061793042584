import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'

export default class Breaker extends Component {
    constructor(props) {
        super(props)
        this.change = this.change.bind(this);
    }

    change(event) {
    }

    render() {

        return <div className="rightToolsBreaker">
            <div className="rightToolsBreaker-line" ></div>
            <div className="rightToolsBreaker-title" >{this.props.cat}</div>

        </div >
    }
}
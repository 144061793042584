import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';

@connect((store) => {
    return { siteMaster: store.siteMaster }
})
class ApplicationFieldsDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = { incoming: [], existing: [], loaded: false, tableData: [], results: {}, requiresEmailSelected: false, error: "", generalError: "" }

        this.finish = this.finish.bind(this)
        this.getFields = this.getFields.bind(this)
        this.create = this.create.bind(this)
        this.setField = this.setField.bind(this)
    }

    componentDidMount() {
        this.getFields()
    }

    setField(index, ev) {
        var results = this.state.results
        var field = this.state.incoming[index]
        for (var prop in results) {
            if (results[prop] == field.mapping + field.merge)
                delete results[prop]
        }
        results[ev.currentTarget.value] = field.mapping + field.merge
        this.setState({ results })
    }

    getFields() {
        var url = this.props.app.Application.options.fieldMapping
        if (this.props.groups) {
            url = url.replace('{{groups}}', this.props.groups)
            url = url.replace('{{group}}', this.props.groups[0])
        }
        axios.get(url).then(response => {
            var incoming = response.data.IncomingFields
            var existing = response.data.ExistingFields
            var requiresEmailSelected = false;
            if (response.data.requiresEmailSelected) requiresEmailSelected = response.data.requiresEmailSelected;

            var results = this.state.results
            incoming.forEach(inc => {
                var exists = existing.find(e => e.fieldName.toLowerCase() == (inc.merge ? inc.merge : inc.name).toLowerCase())
                if (exists) {
                    results[exists.fieldName] = inc.mapping + inc.merge
                }
            })


            this.setState({
                incoming,
                existing,
                results,
                loaded: true,
                requiresEmailSelected
            })
        }).catch(err => {

            var errorMsg = "There was a problem retrieving data";
            if (err.data.error) errorMsg = err.data.error;
            this.setState({ generalError: errorMsg, loaded: true })
        })
    }

    create(index) {
        var results = this.state.results
        var existing = this.state.existing
        var field = this.state.incoming[index]

        existing.push({
            fieldDesc: field.merge,
            fieldName: field.name
        })
        for (var prop in results) {
            if (results[prop] == field.mapping + field.merge)
                delete results[prop]
        }

        results[field.name] = field.mapping + field.merge

        this.setState({
            results, existing
        })
    }

    finish() {

        if (this.state.requiresEmailSelected && (!this.state.results.emailAddress || !this.state.results.emailAddress.length)) {
            this.setState({ error: "Please choose the field that contains email addresses" })
            return;
        }
        this.props.next(this.state.results)
    }

    render() {
        if (!this.state.loaded) return <div className="mar-t30"><UILIB.LoadingIcons iconType="2" /></div>

        if (this.state.generalError && this.state.generalError != "") return <div>
            <h4 className="mar-b25">Oh no!</h4>
            <div className="mar-b25">There was a problem grabbing data from the App. Here's the error we received back..</div>
            <UILIB.WarningHolder className="mar-b25">
                "{this.state.generalError}"
            </UILIB.WarningHolder>
            <div className="mar-b25">Maybe try again, or select a different data source...</div>
            <UILIB.Button text="Continue" className="button-primary" onClick={this.props.cancel} />
        </div >

        var existing = this.state.existing.map(e => {
            return {
                label: e.fieldDesc,
                value: e.fieldName,
                disabled: false
            }
        })

        for (var prop in this.state.results) {
            existing.find(e => e.value == prop).disabled = true;
        }
        var tableData = this.state.incoming.map((inc, index) => {
            var foundExistingField = false
            var value = ''
            for (var prop in this.state.results) {
                if (this.state.results[prop] == inc.mapping + inc.merge) {
                    value = prop;
                    foundExistingField = true
                }
            }


            return {
                incoming: {
                    headerValue: this.props.app.Application.appName + ' Field',
                    value: inc.friendlyName ? inc.friendlyName : inc.name
                },
                existing: {
                    headerValue: this.props.siteMaster.siteName + ' Field',
                    value: <div className="form-group no-marg"><UILIB.Select value={value} className="no-border" placeholder="Dont Import" onChange={ev => this.setField(index, ev)} data={existing} /></div>
                },
                create: {
                    headerValue: ' ',
                    value: <span>{!foundExistingField && <span><span style={{ marginRight: "10px" }}>or</span> <UILIB.Button text="create new" className="button-sml" onClick={() => this.create(index)} /></span>}</span>,
                    align: "right"
                }
            }
        })
        if (!this.state.requiresEmailSelected) {
            tableData.unshift({
                incoming: {
                    headerValue: this.props.app.Application.appName + ' Field',
                    value: "Email Address"
                },
                existing: {
                    headerValue: this.props.siteMaster.siteName + " Field",
                    value: "Email Address"
                },
                create: {
                    headerValue: '',
                    value: ''
                }
            })
        }
        return (
            <div style={{
                display: "flex", flexDirection: "column",
                height: "90vh"
            }}>

                <h4 className="mar-b25">What shall we Import?</h4>
                <div className="mar-b25">Select which fields from {this.props.app.Application.appName} we should import...</div>

                <div style={{ flex: "11", overflow: "auto" }} className="mar-b30">

                    <UILIB.DataTable1
                        tableData={tableData}
                        loadingData={false}
                        width="100%"
                        pageSize="100"
                        hasCheckBoxes="no"
                    />

                </div>
                {this.state.error && <UILIB.WarningHolder className="mar-b25">{this.state.error}</UILIB.WarningHolder>}
                <div className="quickFlex">
                    <UILIB.Button text="Cancel" onClick={this.props.cancel} className="mar-r10" />
                    <UILIB.Button text="Next" onClick={this.finish} className="button-primary" />
                </div>
            </div>
        );
    }
}

export default ApplicationFieldsDrawer;
import React from "react";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { connect } from "react-redux";
import i18n from "~/i18n";
import textFunctions from "~/Classes/textFunctions";
//CONNECT TO STORE
@connect((store) => {
  return { user: store.user };
})
export default class AuthenticateDomainAdrom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: "",
      inputErrors: "",
      domainData: {},
      dataLoaded: false,
      alertMessage: "",
      alertOpen: false,
    };

    this.loadAdromDomain = this.loadAdromDomain.bind(this);
  }

  componentDidMount() {
    this.loadAdromDomain();
  }

  loadAdromDomain() {
    if (this.state.dataLoaded) {
      this.setState({
        alertMessage: (
          <div style={{ marginBottom: "25px" }}>
            {i18n.t("company:domains.adromAuth.checking")}
          </div>
        ),
      });
    }
    var self = this;
    axios
      .post("/accountMaster/domain/adrom/getdnsconfig", {
        domainName: this.props.domain.domainName,
      })
      .then((resp) => {
        var passed = true;
        if (resp?.data?.dkimConfig?.status == "Error") passed = false;
        if (resp?.data?.spfConfig?.status == "Error") passed = false;
        if (resp?.data?.dmarcConfig?.status == "Error") passed = false;
        if (resp?.data?.mxConfig?.status == "Error") passed = false;

        if (passed) {
          console.log("on authed");
          self.props.onAuthenticated();
        } else {
          self.setState({
            domainData: resp.data,
            dataLoaded: true,
            domainName: this.props.domain.domainName,
            alertMessage: "",
          });
        }
      });
  }

  render() {
    var dkimClass = "secondary";
    var spfClass = "secondary";
    var mxClass = "secondary";
    var dmarcClass = "secondary";

    if (!this.state.dataLoaded) {
      return <div>Loading</div>;
    }

    if (this.state.domainData.dkimConfig.status != "Error")
      dkimClass = "paper-green";
    if (this.state.domainData.spfConfig.status != "Error")
      spfClass = "paper-green";
    if (this.state.domainData.dmarcConfig.status != "Error")
      dmarcClass = "paper-green";
    if (this.state.domainData.mxConfig.status != "Error")
      mxClass = "paper-green";

    return (
      <div>
        <h2 className="h4mar-b25">{i18n.t("company:domains.authHeader")}</h2>
        <p className="mar-b25">{i18n.t("company:domains.adromAuth.header")}</p>

        <UILIB.Paper className={dkimClass}>
          <h3 className="h4 mar-b25">DKIM</h3>
          <UILIB.TextInput
            label={
              <div
                dangerouslySetInnerHTML={{
                  __html: i18n.t("company:domains.adromAuth.dkim", {
                    sudomain: textFunctions.checkForHtml(
                      this.state.domainData.dkimConfig.subdomain
                    ),
                  }),
                }}
              ></div>
            }
            readOnly
            value={this.state.domainData.dkimConfig.dkimRecord}
          />
        </UILIB.Paper>

        <UILIB.Paper className={spfClass}>
          <h3 className="h4 mar-b25">SPF</h3>
          <UILIB.TextInput
            label={
              <div
                dangerouslySetInnerHTML={{
                  __html: i18n.t("company:domains.adromAuth.spf", {
                    sudomain: textFunctions.checkForHtml(
                      this.state.domainData.spfConfig.subdomain
                    ),
                  }),
                }}
              ></div>
            }
            readOnly
            value={this.state.domainData.spfConfig.spfRecord}
          />
        </UILIB.Paper>

        <UILIB.Paper className={mxClass}>
          <h3 className="h4 mar-b25">MX</h3>
          <UILIB.TextInput
            label={
              <div
                dangerouslySetInnerHTML={{
                  __html: i18n.t("company:domains.adromAuth.mx", {
                    sudomain: textFunctions.checkForHtml(
                      this.state.domainData.mxConfig.subdomain
                    ),
                  }),
                }}
              ></div>
            }
            readOnly
            value={this.state.domainData.mxConfig.mxRecords[0]}
          />
        </UILIB.Paper>

        <UILIB.Paper className={dmarcClass}>
          <h3 className="h4 mar-b25">DMARC</h3>
          <UILIB.TextInput
            label={
              <div
                dangerouslySetInnerHTML={{
                  __html: i18n.t("company:domains.adromAuth.dmarc", {
                    sudomain: textFunctions.checkForHtml(
                      this.state.domainData.dmarcConfig.subdomain
                    ),
                  }),
                }}
              ></div>
            }
            readOnly
            value={this.state.domainData.dmarcConfig.dmarcRecord}
          />
        </UILIB.Paper>

        <p className="mar-b25">{i18n.t("company:domains.authAllDone")}</p>
        {this.state.alertMessage}

        <UILIB.Button className="button-primary" onClick={this.loadAdromDomain}>
          Check again
        </UILIB.Button>
      </div>
    );
  }
}

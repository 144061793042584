import React, { useState, useEffect } from "react";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import Composer from "./composer";
import ComposerNewChat from "./composerNewChat";
import InboxButton from "../../shared/inboxButton";
import axios from "~/data/http/axios";
import { useSelector } from "react-redux";
import ChatHelpers from "./helpers/chatHelpers";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ConversationsButton from "../../../../components/button";
import i18n from "~/i18n";
import { parseChannelType } from "../../../../../../../Classes/conversations";
import ChangeContact from "./changeContact";

export default function InboxCenter({
  className,
  activeConversation = {},
  creatingConversation = null,
  createdConversation = () => { },
  activeInbox = () => { },
  subscriber = {},
  sentMessage = () => { },
  channels = [],
  chatLines = [],
  staff = [],
  doingWizard = false,
  wizardConversation = false,
  loadingChatLines = true,
  visitorTyping = undefined,
  sendAgentTyping = () => { },
  userRole = null,
  reloadConversation = () => { },
  updateConversations = () => { },
}) {
  const history = useHistory();
  const classes = classnames("inbox-center", className);
  const accountUser = useSelector((state) => state.user);

  const [firstRun, setFirstRun] = useState(true);
  const [chatLinesFinal, setChatLinesFinal] = useState([]);
  const [users, setUsers] = useState([])
  const [assignedTo, setAssignedTo] = useState(activeConversation.AccountUserId || "");

  useEffect(() => {
    axios.get("/accountUser").then((res) => {
      setUsers(res.data.AccountUsers)
    })
    setFirstRun(false);
  }, [])

  useEffect(() => {
    if (users.length) init()
  }, [users])

  useEffect(() => {
    if (!loadingChatLines) {
      init();
    }
  }, [loadingChatLines, chatLines]);

  useEffect(() => {
    init();
  }, [visitorTyping])

  const init = () => {
    let chatLinesOut = ChatHelpers.splitConvIntoGroups(chatLines, accountUser, users, subscriber);
    let lastCreatedAt = null;
    let lastChannelType = 0;
    let outPut = chatLinesOut.map((groupLine, chlIndex) => {
      const firstChatLineId = groupLine.chatLines.length ? groupLine.chatLines[0].id : 0;
      let outLine = [<UILIB.ChatMessageGroup
        status={groupLine.status}
        isMe={groupLine.isMe || groupLine.isStaff}
        isAi={groupLine.isAi}
        name={groupLine.name}
        avatar={groupLine.avatar}
        createdAt={groupLine.createdAt}
        channelType={groupLine.channelType}
        isStaff={groupLine.isStaff}
        id={firstChatLineId}
        key={"clg_" + chlIndex}
      >
        <div className="chat-message-bubbles">
          {groupLine.chatLines.map((chatLine, index) => {
            const attachments = chatLine.ChatAttachments || [];
            let chatLineOut = ChatHelpers.processChatLine(chatLine, groupLine.isMe || groupLine.isStaff, groupLine.isAi, history, userRole)
            if (chatLine.type === 4) return chatLineOut;
            if (attachments.length) {
              return (
                <>
                  {!!chatLine.text && <li className="chat-message-parent" key={"cl_" + index}>{chatLineOut}</li>}
                  {attachments.map(a => {
                    return <li className="chat-message-parent" key={"cl_attachment_" + a.id}>
                      {ChatHelpers.processChatLine(a, groupLine.isMe || groupLine.isStaff, groupLine.isAi, history, userRole, true, () => downloadAttachment(a))}
                    </li>
                  })}
                </>
              );
            } else {
              return (
                <li className="chat-message-parent" key={"cl_" + index}>{chatLineOut}</li>
              );
            }
          })}
        </div>
      </UILIB.ChatMessageGroup>]

      if (lastCreatedAt != null && moment(lastCreatedAt).format("YYYY-MM-DD") !== moment(groupLine.createdAt).format("YYYY-MM-DD")) {
        let theDateText = moment(groupLine.createdAt).format("dddd, Do MMMM YYYY");
        if (moment(groupLine.createdAt).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
          theDateText = "Today";
        }
        outLine.unshift(
          <div
            key={"cl_" + chlIndex + "_date"}
            className="chat-divider chat-divider--date"
          >
            {theDateText}
          </div >
        );
      }
      if (groupLine.chatLines.length === 1 && groupLine.chatLines[0].type == 4) {
        let newStaffId = groupLine.chatLines[0].text;
        if (!isNaN(newStaffId)) {
          newStaffId = Number(newStaffId);
          const foundStaffMember = staff.find((s) => s.AccountUserId == newStaffId);
          let foundStaffMemberName = foundStaffMember ? foundStaffMember?.AccountUser?.firstName + " " + foundStaffMember?.AccountUser?.lastName : "Unknown";
          const foundStaffMemberPicture = foundStaffMember ? foundStaffMember?.AccountUser?.profilePicFileName : "";
          if (newStaffId === -1) {
            outLine = [
              <div className="chat-divider chat-divider--assignee" key={'ot_-1'}>
                {i18n.t("chat:inbox.conversation.chat.assigneeRemoved")}
              </div>
            ]
          } else {
            outLine = [
              <div className="chat-divider chat-divider--assignee" key={'ot_-1'}>
                {i18n.t("chat:inbox.conversation.chat.assignedTo")}
                <div className="chat-divider__agent">
                  <UILIB.AvatarNew
                    size={16}
                    src={foundStaffMemberPicture}
                    name={foundStaffMemberName}
                  />
                  {foundStaffMemberName}
                </div>
              </div>
            ]
          }

        }
      }
      else if (groupLine.chatLines.length === 1 && groupLine.chatLines[0].type === 7) {
        outLine = [
          <div className="chat-divider chat-divider--assignee" key={'ot_-1'}>
            {groupLine.chatLines[0].text}
          </div>
        ]
      }
      else if (chlIndex > 0 && lastChannelType !== groupLine.channelType) {
        outLine.unshift(
          <div
            key={"cl_" + chlIndex + "_channel"}
            className="chat-divider chat-divider--channel"
          >
            {i18n.t("chat:inbox.conversation.chat.channelChanged", { channel: parseChannelType(groupLine.channelType) })}
          </div >
        );
      }
      lastChannelType = groupLine.channelType;
      lastCreatedAt = groupLine.createdAt;
      return outLine;
    });

    if (visitorTyping) {

      outPut.push(<UILIB.ChatMessageGroup
        status={0}
        isMe={false}
        isAi={false}
        name={subscriber.emailAddress}
        createdAt={undefined}
        channelType={1}
        key={"vT_1"}
        style={{ alignItems: "baseline" }}
      >
        <UILIB.LoadingIcons iconType={"3"} />
      </UILIB.ChatMessageGroup>);
    }
    setChatLinesFinal(outPut);
  };

  const closeConversation = async (assignTo) => {
    let postData = {};
    if (assignTo) postData.assignTo = assignTo;
    await axios.put(`/inboxes/chat/${activeConversation.id}/close`, postData);
    sentMessage(undefined, true);
  };

  const openConversation = async () => {
    await axios.put(`/inboxes/chat/${activeConversation.id}/open`);
    sentMessage(undefined, true);
  }

  const downloadAttachment = async (attachment) => {
    if (attachment.location.indexOf('https://') === 0) {
      window.open(attachment.location, '_blank')
    } else {
      const res = await axios.get(`/inboxes/chat/${activeConversation.id}/attachment/${attachment.id}`)
      window.open(res.data.link, '_blank')
    }
  }

  const changeAssignedTo = async (userId) => {
    setAssignedTo(userId || null);
    try {
      await axios.put(`/inboxes/chat/${activeConversation.id}/assign`, {
        AccountUserId: userId || null
      })
      updateConversations(true);
      //reloadConversation();
    } catch (e) {
      console.log(e)
    }
  }

  if (loadingChatLines)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ height: "110px" }}>
          <UILIB.LoadingIcons />
        </div>
        <div>{i18n.t("chat:inbox.conversation.chat.loading")}</div>
      </div>
    );

  let isCreatingConversation =
    creatingConversation && creatingConversation.subscriberId;


  let activeConversationTypeTxt = "";
  if (activeConversation.inboxChannelType === 1) {
    activeConversationTypeTxt = i18n.t("chat:inbox.conversation.chat.headerChannelTypes.email");
    if (activeConversation.subject) {
      activeConversationTypeTxt += ` | Subject: ${activeConversation.subject}`;
    }
  }
  if (activeConversation.inboxChannelType === 2) {
    activeConversationTypeTxt = i18n.t("chat:inbox.conversation.chat.headerChannelTypes.chat");
  }
  if (activeConversation.inboxChannelType === 3) {
    activeConversationTypeTxt = i18n.t("chat:inbox.conversation.chat.headerChannelTypes.facebook");
  }

  return (
    <main className={classes}>
      <header className="inbox-center__header">
        <h2 style={{ display: "flex", alignItems: 'center' }}>
          <Icon type={activeConversation.inboxChannelType} className="inbox-center__header__icon" />
          <div className="inbox-center__header_text">
            <div className="inbox-center__header_text_heading">
              <div>{subscriber.emailAddress}</div>
              {(activeConversation.inboxChannelType === 1 && !wizardConversation) &&
                <ChangeContact activeInbox={activeInbox} activeConversation={activeConversation} changedContact={updateConversations} />
              }
            </div>
            <div className="inbox-center__header_text_subHeading">
              {activeConversationTypeTxt}
            </div>
          </div>
        </h2>

        <div className="inbox-center__header__actions">

          {(!wizardConversation) && <UILIB.SelectNewDefault
            disabled={userRole > 1}
            value={assignedTo}
            align="end"
            data={[{ label: i18n.t("chat:inbox.conversation.chat.unassigned"), value: "" }, ...staff.filter(s => s.role < 2).map(s => {
              return {
                label: <div className="quickFlex"><UILIB.AvatarNew className="mar-r10" src={s.AccountUser.profilePicFileName} name={s.AccountUser.fullname} size={16} />{s.AccountUser.fullname}</div>,
                value: s.AccountUserId
              }
            })]}
            triggerProps={{ size: 's' }}
            onValueChange={(val) => { changeAssignedTo(val) }}
          />}
          {!doingWizard && <>

            {activeConversation.status < 2 && (
              <InboxButton
                size="s"
                disabled={doingWizard || userRole > 1}
                title={doingWizard ? i18n.t("chat:inbox.pleaseAddAChannelFirst") : ""}
                variant="secondary"
                iconLeft={<UILIB.Icon name="check" />}
                onClick={closeConversation}
              >
                {i18n.t("chat:inbox.conversation.chat.close")}
              </InboxButton>
            )}

            {activeConversation.status === 2 && (
              <InboxButton
                variant="secondary"
                size="s"
                disabled={doingWizard || userRole > 1}
                onClick={openConversation}
                aria-label={i18n.t("chat:inbox.conversation.chat.reOpen")}
                title={doingWizard ? i18n.t("chat:inbox.pleaseAddAChannelFirst") : i18n.t("chat:inbox.conversation.chat.reOpen")}
              >
                {i18n.t("chat:inbox.conversation.chat.reOpen")}
              </InboxButton>
            )}
            <ConversationOptionsDropdown conversation={activeConversation} wizardConversation={wizardConversation} onChanged={updateConversations}
              doingWizard={doingWizard}
              title={doingWizard ? i18n.t("chat:inbox.pleaseAddAChannelFirst") : ""}
              alt={doingWizard ? i18n.t("chat:inbox.pleaseAddAChannelFirst") : ""} />
          </>}
        </div>

      </header>

      <div className="inbox-center__chat-list">
        <ul className="inbox-center__chat-wrapper faux-list">
          {chatLinesFinal}
        </ul>
      </div>

      <div className="inbox-center__composer-wrapper">
        {!isCreatingConversation && (
          <Composer
            className="inbox-center__composer"
            activeConversation={activeConversation}
            sentMessage={sentMessage}
            staff={staff}
            doingWizard={doingWizard}
            wizardConversation={wizardConversation}
            closeConversation={closeConversation}
            visitorTyping={visitorTyping}
            sendAgentTyping={sendAgentTyping}
            userRole={userRole}
            reloadConversation={reloadConversation}
          />
        )}
      </div>

      {isCreatingConversation && (
        <ComposerNewChat
          className="inbox-center__composer"
          activeConversation={activeConversation}
          createdConversation={createdConversation}
          activeInbox={activeInbox}
          subscriber={subscriber}
          channels={channels}

        />
      )}
    </main>
  );
}

function ConversationOptionsDropdown({ conversation, wizardConversation, doingWizard, onChanged = () => { } }) {
  const [deleting, setDeleting] = useState(false)
  const [working, setWorking] = useState(false)
  const [error, setError] = useState('')

  const onDelete = async () => {
    setWorking(true)
    setError('')
    try {
      await axios.delete(`/inboxes/chat/${conversation.id}`)
      setDeleting(false)
      onChanged(true)
    } catch (e) {
      setError(i18n.t("chat:inbox.conversation.chat.options.error"))
    }
    setWorking(false)
  }

  const onSetSpam = async () => {
    setWorking(true)
    setError('')
    try {
      await axios.put(`/inboxes/chat/${conversation.id}/spam`)
      onChanged(true)
    } catch (e) {
      setError(i18n.t("chat:inbox.conversation.chat.options.error"))
    }
    setWorking(false);
  }
  const onSetNotSpam = async () => {
    setWorking(true)
    setError('')
    try {
      await axios.put(`/inboxes/chat/${conversation.id}/notspam`)
      onChanged(true)
    } catch (e) {
      setError(i18n.t("chat:inbox.conversation.chat.options.error"))
    }
    setWorking(false);
  }

  return (
    <UILIB.Dialog.Root open={deleting} onOpenChange={setDeleting}>
      <UILIB.Dialog.Trigger asChild>
        <UILIB.DropdownMenu.Root>
          <UILIB.DropdownMenu.Trigger asChild>
            <ConversationsButton aria-label="Additional options" variant="ghost" size="s" iconOnly>
              <UILIB.Icon name="dots-vertical" />
            </ConversationsButton>
          </UILIB.DropdownMenu.Trigger>

          <UILIB.DropdownMenu.Portal>
            <UILIB.DropdownMenu.Content align="end">
              {(conversation.status != 999 && !wizardConversation) && <UILIB.DropdownMenu.Item onSelect={onSetSpam}>{i18n.t("chat:inbox.conversation.chat.options.spam")}</UILIB.DropdownMenu.Item>}
              {(conversation.status == 999 && !wizardConversation) && <UILIB.DropdownMenu.Item onSelect={onSetNotSpam}>{i18n.t("chat:inbox.conversation.chat.options.notSpam")}</UILIB.DropdownMenu.Item>}
              {(!doingWizard) && <UILIB.DropdownMenu.Item onSelect={() => setDeleting(true)} variant="danger">{i18n.t("chat:inbox.conversation.chat.options.delete")}</UILIB.DropdownMenu.Item>}
            </UILIB.DropdownMenu.Content>
          </UILIB.DropdownMenu.Portal>
        </UILIB.DropdownMenu.Root>
      </UILIB.Dialog.Trigger>
      <UILIB.Dialog.Portal>
        <UILIB.Dialog.Overlay />
        <UILIB.Dialog.Content className="inbox__generic-modal">
          <div className="inbox__generic-modal__header">
            {i18n.t("chat:inbox.conversation.chat.options.deleteConfirm")}
          </div>

          <div className="inbox__generic-modal__description">
            {i18n.t("chat:inbox.conversation.chat.options.deleteDesc")}
          </div>

          <div className="inbox__generic-modal__cta">
            <InboxButton disabled={working} size="s" variant="danger" onClick={onDelete}>{i18n.t("chat:inbox.conversation.chat.options.deleteButton")}</InboxButton>
            <InboxButton disabled={working} size="s" variant="secondary" onClick={() => setDeleting(false)}>{i18n.t("chat:inbox.conversation.chat.options.deleteCancel")}</InboxButton>
          </div>

          {!!error && <div className="inbox__generic-modal__error">{error}</div>}

        </UILIB.Dialog.Content>
      </UILIB.Dialog.Portal >
    </UILIB.Dialog.Root>
  )
}

function Icon({ type, ...rest }) {
  let icon;

  switch (type) {
    case 1:
      icon = "mail-open";
      break;
    case 2:
      icon = "chat";
      break;
    case 3:
      icon = "facebook";
      break;
    default:
      icon = "chat";
      break;
  }

  return <UILIB.Icon name={icon} {...rest} />;
}
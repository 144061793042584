import React, { Fragment } from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import NumberFormat from '~/Classes/numberFormatFunctions'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import InboxResults from './inboxResults'
import ServerBlacklists from './serverBlacklists'
import spamFunctions from './spamFunctions';
//CONNECT TO STORE

import moment from 'moment'

@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

export default class SpamTesterAdvanced extends React.Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
            campaignId: this.props.campaignId,
            loaded: false,
            error: false,
            testResults: {},
            testData: undefined,
            testStarted: false,
            showDrawer: false,
            drawerContent: undefined,
            isRunning: false,
            providers: [],
            providersPicked: false,
            providersError: ""
        }

        this.createSpamTest = this.createSpamTest.bind(this);
        this.getSpamTestResults = this.getSpamTestResults.bind(this);
        this.viewInboxResultHeaders = this.viewInboxResultHeaders.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.showServerBlacklists = this.showServerBlacklists.bind(this);
    }

    componentDidMount() {
        this.getSpamTestInboxes();
        // this.createSpamTest()
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer)
        }
    }

    getSpamTestInboxes() {
        axios.get("/spamTest/inboxProviders").then(_providers => {
            var providers = _providers.data.map(p => {
                p.selected = true;
                return p;
            })
            this.setState({ providers: providers });
        })
    }

    createSpamTest() {


        var url = `/spamTest/campaign/` + this.state.campaignId
        var self = this;
        var providers = this.state.providers.filter(p => p.selected).map(p => { return p.id })

        if (!providers.length) {
            this.setState({ providersError: "Please select one or more services to check" })
            return;
        }
        this.setState({ providersPicked: true });
        axios
            .post(url, {
                providers: providers
            })
            .then((res) => {
                self.setState({ testData: res.data })
                self.getSpamTestResults();
            }).catch(function (error) {
                self.setState({ error: true })
                console.log(error);
            })
    }

    getSpamTestResults() {
        var self = this;
        axios
            .get(`/spamTest/${this.state.testData.id}`)
            .then((res) => {
                var testStarted = this.state.testStarted;
                var isRunning = this.state.isRunning;
                if (!this.state.testStarted && (res.data.emailSubject && res.data.emailSubject.length && res.data.emailSubject != undefined)) testStarted = true;

                var isRunning = moment(res.data.createdAt).diff(moment(new Date()), "minutes") > -3 ? true : false;

                if (isRunning && res.data.deliveryStats && res.data.deliveryStats.totalInbox && res.data.deliveryStats.totalSpam && res.data.deliveryStats.totalTests) {
                    if ((res.data.deliveryStats.totalSpam + res.data.deliveryStats.totalInbox) >= res.data.deliveryStats.totalTests) {
                        isRunning = false;
                    }
                }

                self.setState({ testData: res.data, testStarted, isRunning })
                if (this.timer) {
                    clearTimeout(this.timer)
                }
                this.timer = setTimeout(this.getSpamTestResults, 3000)
            }).catch(ex => {
                console.log(ex);
            })
    }

    viewInboxResultHeaders(results) {
        //opens drawer to view the spam test headers and stuff
        var drawerContent = <UILIB.Drawer close={this.closeDrawer} standalone={true} isOpen={true} width="100vw" showClose={true} clickOutside={false}>
            <InboxResults results={results} />
        </UILIB.Drawer>
        this.setState({ showDrawer: true, drawerContent: drawerContent })
    }

    showServerBlacklists(blacklists) {
        //opens drawer to view the blacklists for this server
        var drawerContent = <UILIB.Drawer close={this.closeDrawer} standalone={true} isOpen={true} width="100vw" showClose={true} clickOutside={false}>
            <ServerBlacklists blacklists={blacklists} />
        </UILIB.Drawer>
        this.setState({ showDrawer: true, drawerContent: drawerContent })
    }

    closeDrawer() {
        this.setState({ showDrawer: false, drawerContent: undefined })
    }

    render() {

        var deliveryStats = {
            totalInbox: 0,
            totalInboxPct: 0,
            totalInboxPctHeight: 0,
            totalSpam: 0,
            totalSpamPct: 0,
            totalSpamPctHeight: 0,
            totalUnknown: 0,
            totalUnknownPct: 0,
            totalUnknownHeight: 0,
            totalTests: 0,
            totalReceivedPct: 0
        }

        if (!this.state.providersPicked) return <div style={{ textAlign: "center", marginTop: "50px" }}>
            <h4 className="mar-b25">{i18n.t('spamtester:advanced.startATestHeader')}</h4>
            <div className="mar-b25">{i18n.t('spamtester:advanced.startATestDesc')}</div>
            <div style={{ width: "100%", maxWidth: "600px", margin: "0 auto" }} className="mar-b15">
                {this.state.providers.map((provider, index) => {
                    return <UILIB.SquareChip key={"provider_" + index} value={provider.ProviderName}
                        className={"mar-r10 mar-b10 " + (provider.selected ? "square-chip-secondary" : "square-chip-grey")}

                        iconRight={provider.selected ? <UILIB.Icons icon="tick" style={{ height: 14, width: 14 }} /> : <UILIB.Icons icon="cross" style={{ height: 14, width: 14 }} />}
                        onClick={() => {
                            var providers = this.state.providers;
                            var ourProvider = providers.find(pv => pv.id == provider.id)
                            if (ourProvider.selected) {
                                ourProvider.selected = false;
                            }
                            else {
                                ourProvider.selected = true;
                            }
                            this.setState({ providers });
                        }} />
                })}
            </div>
            {this.state.providersError && <div className="text-red mar-b10">{this.state.providersError}</div>}
            <UILIB.Button text={i18n.t('spamtester:advanced.goStartTest')} className="button-primary" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={this.createSpamTest} />

        </div>
        if (!this.state.testStarted) return <div style={{ textAlign: "center", marginTop: "50px" }}>
            <UILIB.LoadingIcons iconType="2" className="mar-b30" />
            <h4 className="mar-b25">{i18n.t('spamtester:advanced.loading1')}</h4>
            <div>{i18n.t('spamtester:advanced.loading2')}</div>
            <div className="loading">{i18n.t('spamtester:advanced.loading3')}</div>
        </div>
        if (this.state.error) return <div>{i18n.t('spamtester:advanced.error')}</div>
        if (this.state.showDrawer) return <div>{this.state.drawerContent}</div>

        var isStandardUser = true;
        var isAgencyUser = false;
        if (this.props.accountMaster.accountMaster.options && this.props.accountMaster.accountMaster.options.allowMailServerSelection) isStandardUser = false;
        if (this.props.siteMaster && this.props.siteMaster.siteId && this.props.siteMaster.siteId != 1) {
            isStandardUser = false;
            isAgencyUser = true;
        }


        if (this.state.testData && this.state.testData.deliveryStats) {
            if (this.state.testData.deliveryStats.totalTests) deliveryStats.totalTests = this.state.testData.deliveryStats.totalTests;
            if (this.state.testData.deliveryStats.totalInbox) {
                deliveryStats.totalInbox = this.state.testData.deliveryStats.totalInbox;
            }
            if (this.state.testData.deliveryStats.totalSpam) deliveryStats.totalSpam = this.state.testData.deliveryStats.totalSpam;
        }

        deliveryStats.totalUnknown = deliveryStats.totalTests - (deliveryStats.totalInbox + deliveryStats.totalSpam);

        deliveryStats.totalInboxPct = (deliveryStats.totalInbox / deliveryStats.totalTests) * 100;
        deliveryStats.totalSpamPct = (deliveryStats.totalSpam / deliveryStats.totalTests) * 100;
        deliveryStats.totalUnknownPct = (deliveryStats.totalUnknown / deliveryStats.totalTests) * 100;

        if (isStandardUser && this.state.testData && this.state.testData.deliveryStats.totalSpam) {
            deliveryStats.totalInboxPct = ((deliveryStats.totalInbox + deliveryStats.totalSpam) / deliveryStats.totalTests) * 100;
        }

        deliveryStats.totalInboxPctHeight = deliveryStats.totalInboxPct;
        deliveryStats.totalSpamPctHeight = deliveryStats.totalSpamPct
        deliveryStats.totalUnknownHeight = deliveryStats.totalUnknownPct;

        deliveryStats.totalReceivedPct = ((deliveryStats.totalInbox + deliveryStats.totalSpam)) / deliveryStats.totalTests * 100

        var spamResultsData = [];
        var serversData = [];

        var suggestions = {
            hasJunked: false,
            spfFail: false,
            dmarcFail: false,
            dkimFail: false,
            spamAssassin: false
        }

        var inboxStr = i18n.t('spamtester:advanced.inbox')
        var junkStr = i18n.t('spamtester:advanced.junk')
        var pass = i18n.t('spamtester:advanced.pass')
        var fail = i18n.t('spamtester:advanced.fail')

        if (this.state.testData && this.state.testData.inboxes) {
            //spam results data

            this.state.testData.inboxes.forEach(provider => {
                provider.SpamTestInboxes.forEach(inbox => {

                    var testResults = {
                        delivered: 0,
                        authentication: {
                            dkimPass: 0,
                            dmarcPass: 0,
                            spfPass: 0,
                        },
                        blackLists: -1,
                        senderScore: -1,
                        serverIp: ""
                    };
                    if (inbox.SpamTestInboxResults && inbox.SpamTestInboxResults.length) {
                        var spamTestRes = inbox.SpamTestInboxResults[0];
                        if (spamTestRes.deliveredType == 0) testResults.delivered = 1;
                        if (spamTestRes.deliveredType == 1) {
                            testResults.delivered = 2;
                            suggestions.hasJunked = true;
                        }
                        if (spamTestRes.SpamTestMailServerResult) {
                            testResults.authentication = spamTestRes.SpamTestMailServerResult.mailServerAuthentication;
                            testResults.blackLists = 0;
                            if (spamTestRes.SpamTestMailServerResult.mailServerBlackLists && spamTestRes.SpamTestMailServerResult.mailServerBlackLists.length) {
                                testResults.blackLists = spamTestRes.SpamTestMailServerResult.mailServerBlackLists.length
                            }
                            if (spamTestRes.SpamTestMailServerResult.mailServerSenderScore) testResults.senderScore = spamTestRes.SpamTestMailServerResult.mailServerSenderScore;
                            if (spamTestRes.SpamTestMailServerResult.SpamTestMailServer && spamTestRes.SpamTestMailServerResult.SpamTestMailServer.mailServerIp) testResults.serverIp = spamTestRes.SpamTestMailServerResult.SpamTestMailServer.mailServerIp;
                        }
                    }

                    if (testResults.authentication.spfPass == 1) suggestions.spfFail = true;
                    if (testResults.authentication.dmarcPass == 1) suggestions.dmarcFail = true;
                    if (testResults.authentication.dkimPass == 1) suggestions.dkimFail = true;


                    let foundBadHeaders = false;
                    if (testResults.delivered) {
                        Object.keys(inbox.SpamTestInboxResults[0]).forEach(header => {
                            let headerName = header;
                            let headerValue = inbox.SpamTestInboxResults[0][header];
                            let headerResult = spamFunctions.badHeaderCheck(headerName, headerValue)
                            if (headerResult.failed) foundBadHeaders = true;
                        })
                    }

                    let deliveredClick = () => { }
                    let sentBgColor = "bg-status-red"
                    if (testResults.delivered == 0) {
                        sentBgColor = "bg-status-grey";
                    }
                    if (testResults.delivered == 1) {
                        sentBgColor = "bg-status-green"
                    }
                    if (foundBadHeaders) {
                        sentBgColor = "bg-status-orange"
                        deliveredClick = () => { this.viewInboxResultHeaders(inbox.SpamTestInboxResults[0]) }
                    }
                    var tmpData = {
                        provider: {
                            headerValue: "Provider",
                            value: <span>{provider.providerName}</span>
                        },
                        inbox: {
                            headerValue: "Inbox",
                            value: <span>
                                {inbox.emailAddress}
                            </span>,
                            orderBy: false
                        },
                        delivered: {
                            headerValue: "Delivered",
                            value: <>
                                <div className={"text-center text-white " + sentBgColor} style={{ borderRadius: "5px", padding: "5px" }} onClick={deliveredClick}>
                                    {testResults.delivered == 0 ? "??" : testResults.delivered == 1 ? inboxStr : junkStr}
                                    {foundBadHeaders && <UILIB.Icons icon="alert" className="mar-l10" />}
                                </div>
                            </>,
                            width: 150,
                            align: "center"
                        },
                        spf: {
                            headerValue: "SPF",
                            value: <div className={"text-center text-white " + (testResults.authentication.spfPass == 0 ? "bg-status-grey" : testResults.authentication.spfPass == 1 ? "bg-status-orange" : "bg-status-green")} style={{ borderRadius: "5px", padding: "5px" }}>{testResults.authentication.spfPass == 0 ? "??" : testResults.authentication.spfPass == 1 ? fail : pass}</div>,
                            width: 100,
                            align: "center"
                        },
                        dmarc: {
                            headerValue: "DKIM",
                            value: <div className={"text-center text-white " + (testResults.authentication.dkimPass == 0 ? "bg-status-grey" : testResults.authentication.dkimPass == 1 ? "bg-status-orange" : "bg-status-green")} style={{ borderRadius: "5px", padding: "5px" }}>{testResults.authentication.dkimPass == 0 ? "??" : testResults.authentication.dkimPass == 1 ? fail : pass}</div>,
                            width: 100,
                            align: "center"
                        },
                        dkim: {
                            headerValue: "DMARC",
                            value: <div className={"text-center text-white " + (testResults.authentication.dmarcPass == 0 ? "bg-status-grey" : testResults.authentication.dmarcPass == 1 ? "bg-status-orange" : "bg-status-green")} style={{ borderRadius: "5px", padding: "5px" }}>{testResults.authentication.dmarcPass == 0 ? "??" : testResults.authentication.dmarcPass == 1 ? fail : pass}</div>,
                            width: 100,
                            align: "center"
                        },
                        server: {
                            headerValue: "Server",
                            value: <div className={"text-center text-white " + (testResults.serverIp ? "bg-status-green" : "bg-status-grey")} style={{ borderRadius: "5px", padding: "5px" }}>{testResults.serverIp ? testResults.serverIp : "??"}</div>,
                            width: 140
                        },
                        blackLists: {
                            headerValue: "Blacklists",
                            value: <div className={"text-center text-white " + (testResults.blackLists < 0 ? "bg-status-grey" : testResults.blackLists == 0 ? "bg-status-green" : "bg-status-black")} style={{ borderRadius: "5px", padding: "5px" }} onClick={() => { if (testResults.blackLists > 0) { this.showServerBlacklists(inbox.SpamTestInboxResults[0].SpamTestMailServerResult.mailServerBlackLists) } }}>
                                {testResults.blackLists < 0 ? "??" : testResults.blackLists}
                            </div>,
                            width: 100,
                            align: "center"
                        },
                        senderScore: {
                            headerValue: "SenderScore",
                            value: <div className={"text-center text-white " + (testResults.senderScore < 0 ? "bg-status-grey" : testResults.senderScore > 80 ? "bg-status-green" : testResults.senderScore > 60 ? "bg-status-orange" : "bg-status-red")} style={{ borderRadius: "5px", padding: "5px" }}>{testResults.senderScore < 0 ? "??" : testResults.senderScore}</div>,
                            width: 100,
                            align: "center"
                        },

                        options: {
                            headerValue: "",
                            value: <Fragment>
                                {!!testResults.delivered &&
                                    <UILIB.OptionsDropdown popWidth="150px">
                                        <ul>
                                            <li>
                                                <a onClick={() => { this.viewInboxResultHeaders(inbox.SpamTestInboxResults[0]) }}>{i18n.t('spamtester:advanced.viewHeaders')}</a>
                                                {testResults.blackLists > 0 && <a onClick={() => { this.showServerBlacklists(inbox.SpamTestInboxResults[0].SpamTestMailServerResult.mailServerBlackLists) }}>{i18n.t('spamtester:advanced.viewBlacklists')}</a>}
                                            </li>
                                        </ul>
                                    </UILIB.OptionsDropdown>
                                }
                            </Fragment>,
                            fixed: true,
                            width: 50
                        }
                    };

                    if (isStandardUser) {
                        delete tmpData.senderScore;
                        delete tmpData.blackLists;
                        delete tmpData.server;
                        tmpData.delivered.value = <div className={"text-center text-white " + (testResults.delivered == 0 ? "bg-status-grey" : "bg-status-green")} style={{ borderRadius: "5px", padding: "5px" }}>{testResults.delivered == 0 ? "??" : i18n.t('spamtester:advanced.yes')}</div>
                    }

                    if (isAgencyUser) {
                        delete tmpData.inbox;
                    }

                    spamResultsData.push(tmpData);
                })
            })
        }

        if (this.state.testData && this.state.testData.mailServers && this.state.testData.mailServers.length) {
            //servers results datat

            this.state.testData.mailServers.forEach(serverResult => {

                var tmpAuth = {
                    dkimPass: 0,
                    spfPass: 0,
                    dmarcPass: 0
                }

                if (serverResult.mailServerAuthentication) {
                    if (serverResult.mailServerAuthentication.dkimPass) tmpAuth.dkimPass = serverResult.mailServerAuthentication.dkimPass;
                    if (serverResult.mailServerAuthentication.spfPass) tmpAuth.spfPass = serverResult.mailServerAuthentication.spfPass;
                    if (serverResult.mailServerAuthentication.dmarcPass) tmpAuth.dmarcPass = serverResult.mailServerAuthentication.dmarcPass;
                }
                var tmpData = {
                    serverName: {
                        headerValue: "Name",
                        value: <span>{serverResult.SpamTestMailServer.mailServerHostName}</span>
                    },
                    ip: {
                        headerValue: "IP",
                        value: <span>{serverResult.SpamTestMailServer.mailServerIp}</span>
                    },
                    spf: {
                        headerValue: "SPF",
                        value: <div className={"text-center text-white " + (tmpAuth.spfPass == 0 ? "bg-status-grey" : tmpAuth.spfPass == 1 ? "bg-status-orange" : "bg-status-green")} style={{ borderRadius: "5px", padding: "5px" }}>{tmpAuth.spfPass == 0 ? "??" : tmpAuth.spfPass == 1 ? fail : pass}</div>,
                        width: 100,
                        align: "center"
                    },
                    blackLists: {
                        headerValue: "Blacklists",
                        value: <div className={"text-center text-white " + (!serverResult.mailServerBlackLists ? "bg-status-grey" : serverResult.mailServerBlackLists.length == 0 ? "bg-status-grey" : "bg-status-black")} style={{ borderRadius: "5px", padding: "5px" }} onClick={() => { if (serverResult.hasOwnProperty("mailServerBlackLists") && serverResult.mailServerBlackLists.length > 0) { this.showServerBlacklists(serverResult.mailServerBlackLists) } }}>
                            {!serverResult.mailServerBlackLists || !serverResult.mailServerBlackLists.length ? "0" : serverResult.mailServerBlackLists.length}
                            {!!serverResult.mailServerBlackLists && !!serverResult.mailServerBlackLists.length && <span className="icon-magnifier text-white mar-l10" />}
                        </div>,
                        width: 150,
                        align: "center"
                    },
                    senderScore: {
                        headerValue: "SenderScore",
                        value: <div className={"text-center text-white " + (!serverResult.mailServerSenderScore ? "bg-status-grey" : serverResult.mailServerSenderScore > 80 ? "bg-status-green" : serverResult.mailServerSenderScore >= 60 ? "bg-status-orange" : "bg-status-red")} style={{ borderRadius: "5px", padding: "5px" }}>{(!serverResult.mailServerSenderScore) ? "??" : serverResult.mailServerSenderScore}</div>,
                        width: 150,
                        align: "center"

                    }
                }

                if (isStandardUser) {
                    delete tmpData.blackLists;
                    delete tmpData.senderScore;
                }
                serversData.push(tmpData);
            })
        }

        if (this.state.testData && this.state.testData.spamAssassinResults && this.state.testData.spamAssassinResults.rules && this.state.testData.spamAssassinResults.rules.length) {
            suggestions.spamAssassin = true;
        }


        return <div>


            <UILIB.Row>
                <UILIB.Column xs={12} className="mar-b25 quickFlex">
                    <h4>{i18n.t('spamtester:advanced.results')}</h4>
                    <div
                        className={"text-center text-white mar-l10 " + (this.state.isRunning ? "bg-status-orange" : "bg-status-green")}
                        style={{ borderRadius: "3px", padding: "3px 8px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {this.state.isRunning && <UILIB.LoadingIcons className="mar-r10" iconType="2" style={{ width: "15px", height: "15px" }} />}
                        {this.state.isRunning ? i18n.t('spamtester:advanced.running') : i18n.t('spamtester:advanced.finished')}
                    </div>
                </UILIB.Column>
                <UILIB.Column xs={12} style={{ display: "flex" }} className="mar-b25">
                    {(deliveryStats.totalInboxPct > 0) && <div className="mar-r5 spamTestBar spamTestBar_inbox" style={{ width: NumberFormat.formatNumber(deliveryStats.totalInboxPct, 2) + "%" }}>
                        <div>{NumberFormat.formatNumber(deliveryStats.totalInboxPct, 2)}% {isStandardUser ? i18n.t('spamtester:advanced.delivered') : i18n.t('spamtester:advanced.inbox')}</div>
                    </div>}
                    {(!isStandardUser && deliveryStats.totalSpamPct > 0) && <div className="mar-r5 spamTestBar spamTestBar_spam" style={{ width: NumberFormat.formatNumber(deliveryStats.totalSpamPct, 2) + "%" }}>
                        {NumberFormat.formatNumber(deliveryStats.totalSpamPct, 2)}% {i18n.t('spamtester:advanced.junk')}
                    </div>}
                    {(deliveryStats.totalUnknownPct > 0) && <div className="spamTestBar spamTestBar_unknown" style={{ width: NumberFormat.formatNumber(deliveryStats.totalUnknownPct, 2) + "%" }}>
                        {NumberFormat.formatNumber(deliveryStats.totalUnknownPct, 2)}% {i18n.t('spamtester:advanced.unknown')}
                    </div>}
                </UILIB.Column>


                <UILIB.Column xs={12} sm={8} md={8} lg={8} className="mar-b25">
                    <UILIB.Paper style={{ height: "100%" }}>
                        <h4 className="mar-b20">
                            {i18n.t('spamtester:advanced.detailsHeader')}
                        </h4>
                        <UILIB.Row className="mar-b0">
                            <UILIB.Column xs={12} className="mar-b15">
                                <UILIB.TextInput value={this.state.testData.emailSubject} label={i18n.t('spamtester:advanced.subject')} readonly={true} />
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} md={6} lg={6}>
                                <UILIB.TextInput value={this.state.testData.emailFromName} label={i18n.t('spamtester:advanced.fromName')} readonly={true} />
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} md={6} lg={6}>
                                <UILIB.TextInput value={this.state.testData.emailFromAddress} label={i18n.t('spamtester:advanced.fromEmail')} readonly={true} />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={4} md={4} lg={4} className="mar-b25">
                    <UILIB.Paper style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                        <h4 className="mar-b20">
                            {i18n.t('spamtester:advanced.contentHeader')}
                        </h4>
                        <UILIB.Paper style={{ flex: "1 0 0", overflow: "auto", padding: "10px" }} className="mar-b0">
                            {(this.state.testData.spamAssassinResults === null || !this.state.testData.spamAssassinResults || !this.state.testData.spamAssassinResults.rules || !this.state.testData.spamAssassinResults.rules.length) && <div>
                                <p>{i18n.t('spamtester:advanced.noIssues')}</p>
                            </div>}
                            {(!!this.state.testData.spamAssassinResults && !!this.state.testData.spamAssassinResults.rules && !!this.state.testData.spamAssassinResults.rules.length) && <div>
                                <p>{i18n.t('spamtester:advanced.issues')}</p>
                                {this.state.testData.spamAssassinResults.rules.map(rule => {
                                    return <div>
                                        {rule.score}: {rule.friendlyDescription}
                                    </div>
                                })}
                            </div>}
                        </UILIB.Paper>
                    </UILIB.Paper>
                </UILIB.Column>

                {(!!(suggestions.hasJunked && !isStandardUser) || !!suggestions.spfFail || !!suggestions.dkimFail || !!suggestions.dmarcFail) &&
                    <UILIB.Column xs={12} className="mar-b0">
                        <UILIB.Paper className="paper-red">
                            <h4 className="mar-b20">{i18n.t('spamtester:advanced.suggestionsHeader')}</h4>
                            <ul>
                                {!!suggestions.hasJunked && !isStandardUser && <li>
                                    <div className="text-bold">{i18n.t('spamtester:advanced.suggSpam')}</div>
                                    <p style={{ color: "white" }}>{i18n.t('spamtester:advanced.suggSpamCon')}</p>
                                </li>}
                                {(!!suggestions.spfFail || !!suggestions.dkimFail || suggestions.dmarcFail) && <li>
                                    <div className="text-bold">{i18n.t('spamtester:advanced.suggAuth')}</div>
                                    <p style={{ color: "white" }}>{i18n.t('spamtester:advanced.suggAuthCon')}</p>
                                    <p style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: i18n.t('spamtester:advanced.suggAuthCon2') }}></p>
                                </li>}
                                {!!suggestions.spamAssassin && <li>
                                    <div className="text-bold">{i18n.t('spamtester:advanced.suggContent')}</div>
                                    <p style={{ color: "white" }}>{i18n.t('spamtester:advanced.suggContentCon')}</p>
                                </li>}
                            </ul>
                        </UILIB.Paper>
                    </UILIB.Column>
                }

                {!isStandardUser && <UILIB.Column xs={12} className="mar-b0">
                    <UILIB.Paper>
                        <h4 className="mar-b20">
                            {i18n.t('spamtester:advanced.mailservers')}
                        </h4>
                        <UILIB.DataTable1 noResultsTxt={"No results yet"} tableData={serversData} loadingData={this.state.testStarted} width="100%" pageSize="100" hasCheckBoxes="no" />

                    </UILIB.Paper>
                </UILIB.Column>
                }

                <UILIB.Column xs={12} sm={12} md={12} lg={12} className="mar-b0">
                    <UILIB.Paper>
                        <h4 className="mar-b20">
                            {i18n.t('spamtester:advanced.inboxResults')}
                        </h4>
                        <UILIB.DataTable1 noResultsTxt={"No results yet"} tableData={spamResultsData} loadingData={this.state.testStarted} width="100%" pageSize="100" hasCheckBoxes="no" />
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>


        </div >
    }
}

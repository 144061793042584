import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import InitialSetup from './initial';
import Disconnect from './disconnect';
import ApplicationTransactionsGraph from '../components/applicationTransactionsGraph'
import DateTimeFunctions from '~/Classes/dateTimeFunctions'

@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})
class PipedriveConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: true,
            appData: {},
            appBinding: {},
            groups: [],
            importing: false,
            importProgress: 0,
            importId: undefined,
            unloading: false
        };

        this.getApp = this.getApp.bind(this);
        this.getAppBinding = this.getAppBinding.bind(this);
        this.popDisconnect = this.popDisconnect.bind(this);
        this.getGroups = this.getGroups.bind(this)
        this.setup = this.setup.bind(this)
        this.checkForImportUpdate = this.checkForImportUpdate.bind(this)
        this.performSync = this.performSync.bind(this)
        this.getAvailableFields = this.getAvailableFields.bind(this)
    }

    componentDidMount() {
        this.getApp();
    }

    componentWillUnmount() {
        this.setState({
            unloading: true
        })
    }

    getAvailableFields() {
        axios.get('/application/pipedrive/refreshproperties').catch(() => { })
        axios.get('/application/pipedrive/availablefields').then(response => {
            this.setState({
                availableFields: response.data,
                ready: true
            })
        })
    }

    getApp(response) {
        if (response && response.data && response.data.id) {
            this.setState({
                importing: true,
                importId: response.data.id
            }, this.checkForImportUpdate)
        }
        axios.get("/application/47").then(response => {
            this.setState({
                appData: response.data.Application
            }, this.getAppBinding)
        })
    }

    getAppBinding() {
        axios.get("/application/account/app/" + this.state.appData.id).then(response => {
            var groupId = -1;
            if (response.data.Application.appSettings && response.data.Application.appSettings.groupId) groupId = response.data.Application.appSettings.groupId;

            this.setState({
                appBinding: response.data.Application,
                groupId: groupId
            }, this.getGroups)
        })
    }

    getGroups() {
        axios.get('/group').then(response => {
            this.setState({
                groups: response.data.Groups,
                loading: false
            }, this.getAvailableFields)
        })
    }

    checkForImportUpdate() {
        if (this.state.unloading) return;
        var self = this;
        axios.get('/group/' + this.state.appBinding.appSettings.groupId + '/import/' + this.state.importId).then(res => {
            self.setState({ importProgress: res.data.PercentComplete })
            if (res.data.PercentComplete < 100) {
                this.theTimer = setTimeout(this.checkForImportUpdate, 1000);
            }
            else {
                self.setState({ importing: false, importProgress: 100 }, this.getAppBinding)
            }
        })
    }

    popDisconnect() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <Disconnect goDisconnect={() => {
            axios.delete('/accountMaster/application/' + this.state.appBinding.id).then(res => {
                this.props.history.push('/cp/company/integrations')
            })
        }} />, true, "500px"));
    }

    setup(only) {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <InitialSetup only={only} appBinding={this.state.appBinding} finish={response => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false))
            this.getApp(response);
        }} />, true, "100vw"));
    }

    performSync() {
        axios.get('/application/pipedrive/setup?resync=true').then(response => {
            this.setState({
                importing: true,
                importId: response.data.id
            }, this.checkForImportUpdate)
        })
    }

    render() {

        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />

        var currentGroup = this.state.groups.find(g => g.id == this.state.appBinding.appSettings.groupId)
        if (!currentGroup) return <InitialSetup appBinding={this.state.appBinding} finish={this.getApp} />


        return <div>
            <UILIB.Row className="middle-xs mar-b10">
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="col-md-offset-2">
                    <div className="quickFlex">
                        <div className="integration-avatar rounded" style={{ backgroundImage: "url(" + this.state.appData.appLogoUri + ")" }}></div>
                        <p className="no-marg pad-left">Pipedrive. Connected</p>
                    </div>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="end-xs">
                    <p className="no-marg">
                        <UILIB.Button className="button-sml primary mar-r10" onClick={() => this.setup('group')}>Settings</UILIB.Button>
                        <UILIB.Button className="button-sml grey" onClick={this.popDisconnect}>Disconnect</UILIB.Button>
                    </p>
                </UILIB.Column>
            </UILIB.Row>
            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="col-md-offset-2">
                    <UILIB.Paper className="height-100 mar-b0">
                        <h4 className="mar-b25 text-primary">Contacts</h4>
                        <p>Pipedrive is currently connected and is automatically syncing your contacts with the following group. If you add, update or delete a contact on Pipedrive it is automatically updated here.</p>
                        <p>
                            <div className="chip smallChip outline secondary" onClick={() => { this.props.history.push('/cp/groups/' + this.state.groupId); }}>{currentGroup.groupName}</div>
                            {!this.state.importing && this.state.appBinding.appSettings && this.state.appBinding.appSettings.lastSynced && <div className="text-xsml">Last synced: {DateTimeFunctions.formatDateTime(this.state.appBinding.appSettings.lastSynced, 1)} - <a onClick={this.performSync}>Re-sync</a></div>}
                        </p>

                        <a onClick={() => this.setup('group')} className="text-sml text-underlined mar-r10">Configure</a>
                        {!this.state.importing && <a className="text-sml text-underlined" onClick={this.performSync}>Re-synchronise</a>}
                        {this.state.importing && <div style={{ marginTop: "10px" }}><p>Performing Sync</p> {
                            !!this.state.importProgress
                                ? <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.importProgress} val2={100} />
                                : <UILIB.LoadingLine />
                        }</div>}
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={4} lg={4}>
                    <UILIB.Paper className="height-100 mar-b0">
                        <h4 className="text-primary">Sync history</h4>
                        <ApplicationTransactionsGraph applicationId={this.state.appData.id} />
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
            <UILIB.Row className="mar-b60">
                <UILIB.Column xs={12} sm={12} md={8} lg={8} className="col-md-offset-2">
                    <UILIB.Paper className="height-100 mar-b0">
                        <UILIB.Row>
                            <UILIB.Column xs={12} margin={0}>
                                <h4 className="mar-b25 text-primary">Automation</h4>
                                <p>Automations are a great way to automatically make things happen based on your contacts activity. For example, you could send a welcome email when someone gets added to Pipedrive.</p>
                                <p><UILIB.Button text="Add an Automation" className="button-sml" onClick={() => { this.props.history.push('/cp/automation') }} /></p>
                            </UILIB.Column>
                        </UILIB.Row>

                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
        </div>
    };
}

export default PipedriveConfig;
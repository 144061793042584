import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib.jsx';
import axios from '~/data/http/axios';
import AddGroup from '../subscribers/groups/incGoAddGroup'
import FormPicker from '~/pages/cp/includes/formPicker'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import i18n from '~/i18n';

@connect((store) => {
    return { permissionStore: store.permission, accountMaster: store.accountMaster }
})
class FormGroupSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            searchText: '',
            selectedGroup: undefined,
            groups: [],
            addingGroup: false
        }

        this.getGroups = this.getGroups.bind(this)
        this.updateSearchText = this.updateSearchText.bind(this)
        this.setGroup = this.setGroup.bind(this)
        this.createGroup = this.createGroup.bind(this)
        this.addedGroup = this.addedGroup.bind(this)
        this.addForm = this.addForm.bind(this);
        this.formAdded = this.formAdded.bind(this);
    }

    componentDidMount() {
        this.getGroups()
    }

    getGroups() {
        var queryStr = ``
        if (this.state.searchText) queryStr += `?searchText=${this.state.searchText}`
        axios.get('/group/simple' + queryStr).then(response => {
            this.setState({
                loading: false,
                groups: response.data.Groups.sort((a, b) => {
                    if (a.groupName < b.groupName)
                        return -1;
                    if (a.groupName > b.groupName)
                        return 1;
                    return 0;
                })
            })
        })
    }

    updateSearchText(text) {
        this.setState({
            searchText: text
        }, () => {
            clearTimeout(this.searchTimer)
            this.searchTimer = setTimeout(this.getGroups, 150)
        })
    }

    setGroup(group) {
        this.setState({
            selectedGroup: group
        }, () => {
            if (this.props.onSelect && typeof this.props.onSelect === 'function') {
                this.props.onSelect(this.state.selectedGroup)
            } else {
                this.addForm()
            }
        })
    }

    createGroup() {
        this.setState({
            addingGroup: true
        })
    }

    addedGroup(newGroup) {
        this.setState({
            selectedGroup: { id: newGroup.data.groupId },
            addedGroup: false,
            addingGroup: false
        }, () => {
            if (this.props.onSelect && typeof this.props.onSelect === 'function') {
                this.props.onSelect(this.state.selectedGroup)
            } else {
                this.addForm()
            }
        })
    }

    addForm() {
        // this.props.history.push('/cp/groups/' + this.state.selectedGroup.id + '/forms/?create=true')
        var drawer = <FormPicker save={this.formAdded} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, true, "100%", "bg-main"));
    }

    formAdded(theme, themeType, options) {
        var self = this;
        var form = {
            formName: '',
            wizard: {
                step1: true
            },
            options,
            ThemeId: theme.id,
            formType: themeType,
        }
        return axios.post('/group/' + self.state.selectedGroup.id + '/form', form).then(response => {
            var redirect = '/cp/groups/' + self.state.selectedGroup.id + '/forms/create/' + response.data.Form.id + '/2'
            self.props.history.push('/cp/templates/add/dragdrop?form=true&groupId=' + self.state.selectedGroup.id + '&formId=' + response.data.Form.id + '&redirect=' + redirect);
        })
    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />
        if (this.state.addingGroup) return <AddGroup addedGroup={this.addedGroup} dontCloseDrawer={true} />
        return (
            <div>
                <h4 className="mar-b25">{i18n.t('campaigns:overview.firstLetsSelectAGroup')}</h4>
                <p className="mar-b25">{i18n.t('campaigns:overview.pleaseSelectOrCreateAGroupWhereNewSubscribers')}.</p>
                <UILIB.ButtonSelect
                    outerClassName="mar-b25"
                    filter={true}
                    autoClose={true}
                    updateFilter={this.updateSearchText}
                    headerText={this.state.selectedGroup ? this.state.selectedGroup.groupName : "..."}
                    label={this.state.selectedGroup ? this.state.selectedGroup.groupName : i18n.t('campaigns:overview.selectAnExistingGroup')}
                    data={this.state.groups.map((group, index) => {
                        return <a key={group.id} onClick={() => this.setGroup(group)}>{group.groupName}</a>
                    })}
                />

                <UILIB.Button text={i18n.t('campaigns:overview.orCreateANewGroup')} className="button" onClick={this.createGroup} iconRight={<UILIB.Icons icon="plus" />} />
            </div >
        );
    }
}

export default FormGroupSelect;
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import i18n from '~/i18n'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import AppFilterDrawer from './applicationFilterDrawer'
import AppFieldsDrawer from './applicationFieldsDrawer'
import AppAdditionalDrawer from './applicationAdditionalDrawer'
import AddIntegrationDrawer from '~/pages/cp/company/integrations/addApp/'
import ShowAllIntegrationsDrawer from '~/pages/cp/company/integrations/add'

@connect((store) => {
    return { siteMaster: store.siteMaster }
})
export default class AddGroupsTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { groupName: "", groupID: 0, ready: false, userApps: [], allApps: [] };
        this.goCopyPaste = this.goCopyPaste.bind(this);
        this.goImport = this.goImport.bind(this);
        this.goCopyGroup = this.goCopyGroup.bind(this);
        this.goAddSubscriber = this.goAddSubscriber.bind(this);
        this.getUserApps = this.getUserApps.bind(this)
        this.getAllApps = this.getAllApps.bind(this);
        this.selectApp = this.selectApp.bind(this);
        this.viewAllApps = this.viewAllApps.bind(this);
    }


    componentDidMount() {

        this.setState({ groupID: this.props.match.params.groupID }, this.getUserApps);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.groupID !== this.props.match.params.groupID) {
            this.setState({ groupID: this.props.match.params.groupID }, this.getUserApps);
        }
    }

    getUserApps() {
        axios.get('/accountMaster/application').then(response => {
            this.setState({
                userApps: response.data.Applications
            }, this.getAllApps)
        })
    }

    getAllApps() {
        axios.get('/application').then(response => {
            this.setState({
                allApps: response.data.Applications,
                ready: true
            })
        })
    }

    goCopyPaste() {
        this.props.history.push('/cp/groups/' + this.state.groupID + '/add/copypaste')
    }
    goImport() {
        this.props.history.push('/cp/groups/' + this.state.groupID + '/add/import')
    }
    goAddSubscriber() {
        this.props.history.push('/cp/groups/' + this.state.groupID + '/add/subscriber')
    }
    goCopyGroup() {
        this.props.history.push('/cp/groups/' + this.state.groupID + '/add/copyGroup')
    }
    viewAllApps() {
        var drawerContent = <ShowAllIntegrationsDrawer finish={(option) => {
            this.selectApp(option.id)
        }} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));

    }
    selectApp(appId) {
        var drawer = <AddIntegrationDrawer appId={appId} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, false, '100vw', 'full'));
    }

    import(app) {
        var groups = [], fields = {}, filters = {}
        var self = this

        var groupsDrawer = <AppFilterDrawer app={app} cancel={() => this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right"))} next={(_groups, _filters) => {
            groups = groups.concat(_groups)
            filters = _filters;
            if (app.Application.options.fieldMapping) {
                fieldsDrawer = <AppFieldsDrawer app={app} groups={groups} cancel={() => this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right"))} next={(_fields) => {
                    fields = _fields
                    // if (app.Application.options.additionalFilters) {
                    //     additionalDrawer = <AppAdditionalDrawer app={app} next={(results) => {
                    //         filters = results
                    //         start()
                    //     }} />
                    //     self.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", additionalDrawer, false));
                    // } else {
                    start()
                    // }
                }} />
                self.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", fieldsDrawer, false, '700px'));
            } else {
                start()
            }
        }} />
        var fieldsDrawer = <AppFieldsDrawer app={app} groups={groups} cancel={() => this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right"))} next={(_fields) => {
            fields = _fields
            if (app.Application.options.additionalFilters) {
                additionalDrawer = <AppAdditionalDrawer app={app} next={(results) => {
                    filters = results
                    start()
                }} />
                this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", additionalDrawer, false, '700px'));
            } else {
                start()
            }
        }} />

        var additionalDrawer = <AppAdditionalDrawer app={app} next={(results) => {
            filters = results
            start()
        }} />

        if (app.Application.options.importFilter) {
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", groupsDrawer, true, '500px'));
        } else if (app.Application.options.fieldMapping) {
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", fieldsDrawer, true, '500px'));
        } else {
            axios.post('/group/' + this.state.groupID + '/import/application/' + app.id).then(response => {
                var id = response.data.id
                this.props.history.push('/cp/groups/' + this.state.groupID + '/add/import/progress/' + id)
            })
        }

        function start() {
            self.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", undefined));
            var body = {}
            if (groups.length) body.groups = groups
            if (Object.keys(fields).length) body.fields = fields
            body.filters = filters
            axios.post('/group/' + self.state.groupID + '/import/application/' + app.id, body).then(response => {
                var id = response.data.id
                self.props.history.push('/cp/groups/' + self.state.groupID + '/add/import/progress/' + id)
            })
        }
    }

    render() {
        if (!this.state.ready) return <div className="mar-b30"><UILIB.LoadingIcons iconType="2" /></div>
        var userApps = this.state.userApps.filter(a => a.Application.options && a.Application.options.canImport)

        const currentApp = this.state.userApps.find(a => a.ApplicationId === 8 && a.appSettings && a.appSettings.groupId == this.state.groupID)

        var allApps = [];
        var appCols = 2;
        this.state.allApps.filter(a => a.options && a.options.canImport && !userApps.find(ua => ua.ApplicationId == a.id)).forEach((theApp, index) => {
            if (index < 4) allApps.push(theApp);
        })
        if (allApps.length) {
            appCols = Math.round(12 / allApps.length);
        }
        return <div>

            <UILIB.Row>
                <div className="col-xs-12">
                    <UILIB.Paper className={!!currentApp ? "with_header" : ''}>
                        {!!currentApp && <div className="paper_header" style={{ backgroundColor: '#453dd8', color: 'white' }}>
                            <div className="quickFlex">
                                <UILIB.Icons icon="capsule" style={{ height: 24, width: 24 }} color="white" />
                                <div className='mar-l20 text-heavy' style={{ fontSize: 16 }}>Adding Subscribers into this Group will Automatically add them them Capsule</div>
                            </div>
                        </div>}
                        <div className="paper_content">
                            <div className="row">
                                <div className="col-xs-12 col-md-3 col-md-spacer-right">
                                    <Card
                                        icon={<UILIB.Icons circle={true} icon="person" style={{ height: 32, width: 32 }} color="#7246B1" />}
                                        title={i18n.t('subscribers:add.addSingle')}
                                        content={i18n.t('subscribers:add.addSingleDetail')}
                                        button={<UILIB.Button text={i18n.t('subscribers:add.addSingleButton')} className="button-md" onClick={() => this.goAddSubscriber()} iconRight={<UILIB.Icons icon="arrowRight" />} />}
                                    />
                                </div>
                                <div className="col-xs-12 col-md-3 col-md-spacer-right" >
                                    <Card
                                        icon={<UILIB.Icons circle={true} icon="copyClipboard" style={{ height: 32, width: 32 }} color="#7246B1" />}
                                        title={i18n.t('subscribers:add.addCopy')}
                                        content={i18n.t('subscribers:add.addCopyDetail')}
                                        button={<UILIB.Button text={i18n.t('subscribers:add.addCopyButton')} className="button-md" onClick={() => this.goCopyPaste()} iconRight={<UILIB.Icons icon="arrowRight" />} />}
                                    />
                                </div>
                                <div className="col-xs-12 col-md-3 col-md-spacer-right">
                                    <Card
                                        icon={<UILIB.Icons circle={true} icon="upload" style={{ height: 32, width: 32 }} color="#7246B1" />}
                                        title={i18n.t('subscribers:add.addImport')}
                                        content={i18n.t('subscribers:add.addImportDetail')}
                                        button={<UILIB.Button text={i18n.t('subscribers:add.addImportButton')} className="button-md" onClick={() => this.goImport()} iconRight={<UILIB.Icons icon="arrowRight" />} />}
                                    />
                                </div>
                                <div className="col-xs-12 col-md-3">
                                    <Card
                                        icon={<UILIB.Icons circle={true} icon="switch" style={{ height: 32, width: 32 }} color="#7246B1" />}
                                        title={i18n.t('subscribers:add.addCopyGroup')}
                                        content={i18n.t('subscribers:add.addCopyGroupDetail')}
                                        button={<UILIB.Button text={i18n.t('subscribers:add.addCopyGroupButton')} className="button-md" onClick={() => this.goCopyGroup()} iconRight={<UILIB.Icons icon="arrowRight" />} />}
                                    />
                                </div>
                                {!!allApps.length && <div className="col-xs-12 col-spacer-top mar-t30">
                                    <UILIB.Row className="mar-t20 middle-xs">
                                        <UILIB.Column xs={12} sm={12} md={10} lg={10} margin={0}>
                                            <div className="quickFlex">
                                                <h4 className="mar-r15">{i18n.t('subscribers:add.appsIntro')}</h4>
                                                <UILIB.Row className="no-marg">
                                                    {allApps.map((theApp, index) => {
                                                        return <UILIB.Column xs={appCols} className="text-center" margin={0} key={"app" + index} >
                                                            <div style={{ cursor: "pointer", width: "50px", height: "50px" }} onClick={() => this.selectApp(theApp.id)}>
                                                                <img src={theApp.appLogoUri} alt={theApp.appName} title={theApp.appName} width="100%" />
                                                            </div>
                                                        </UILIB.Column>
                                                    })}
                                                </UILIB.Row>
                                            </div>
                                        </UILIB.Column>
                                        <UILIB.Column xs={6} sm={6} md={2} lg={2} margin={0} className="end-xs">
                                            <UILIB.Button onClick={this.viewAllApps} text={i18n.t('subscribers:add.viewAllIntegrations')} iconLeft={<UILIB.Icons icon="integrations" />} />
                                        </UILIB.Column>
                                    </UILIB.Row>
                                </div>}
                            </div>
                        </div>
                    </UILIB.Paper>
                </div>
                {userApps.map((app, index) => {
                    return <UILIB.Column key={"col_" + index} xs={12} sm={12} md={userApps.length == 1 ? 6 : 4}>
                        <UILIB.Paper>
                            <Card
                                icon={<img width={80} width={80} className="mar-r20" src={app.Application.appLogoUri} />}
                                title={app.Application.appName}
                                content={"Import from " + app.Application.appName}
                                button={<UILIB.Button text="Import" className="button-md" onClick={() => this.import(app)} />}
                            />
                        </UILIB.Paper>
                    </UILIB.Column>
                })}
            </UILIB.Row>


        </div >

    };
};

function Card({ icon, title, content, button }) {
    return (
        <div style={{ display: 'flex', overflow: 'hidden', height: '100%' }}>
            <div className="mar-r20">{icon}</div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <h4 style={{ marginBottom: 10 }}>{title}</h4>
                <div>{content}</div>
                <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start', marginTop: 10 }}>
                    {button}
                </div>
            </div>
        </div>
    )
}
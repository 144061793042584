import React from 'react'
import config from '~/Classes/siteVars.js';
import TemplateFromCode from '~/pages/cp/templates/add/fromCode/fromCode'
import Wysiwyg from '~/pages/cp/templates/add/wysiwyg/wysiwyg';
import DragDrop from '~/pages/cp/templates/add/dragDrop/dragDrop';
import AddCampaignStandard from '~/pages/cp/campaigns/add/standard/standardCampaign';
import CreateAutomationNew from '~/pages/cp/automation/addNew'
import DeliveryManager from '~/pages/cp/company/servers/deliveryManager'
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib.jsx'
import PermissionRouter from '../../Common-Objects/Router/permissionRoute'
import axios from '~/data/http/axios';
import * as user from '~/data/actions/userActions'; //now we can use user actions here
import * as siteActions from '~/data/actions/siteActions'; //now we can use user actions here
import SocketUser from './socketUser';
import * as SocketsUi from '../../Classes/socketsUi';
import GoogleAnalytics from '~/Classes/googleAnalytics';
import ConversationsRouter from '~/pages/cp/conversations/router';
import queryStringParser from 'query-string';

@connect((store) => {
    return {
        user: store.user,
        accountMaster: store.accountMaster,
        siteMaster: store.siteMaster
    }
})


export default class FullScreenTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loadingUser: false }

    }

    componentDidMount() {
        this.props.dispatch(user.loadUserRecord());
    }
    componentWillUnmount() {

    }

    async componentDidUpdate(prevProps) {
        if (prevProps.siteMaster && this.props.siteMaster) {
            if (prevProps.siteMaster.googleAnalyticsKey != this.props.siteMaster.googleAnalyticsKey) {
                try {
                    GoogleAnalytics.trackGAPageView(this.props.history.location.pathname)
                }
                catch (err) { }
            }
        }

        if (!prevProps.user.loggedIn && this.props.user.loggedIn) {
            //await SocketsUi.init();

            const query = queryStringParser.parse(location.search);
            if (query.AccountMasterId && parseInt(query.AccountMasterId) !== this.props.accountMaster.id) {
                this.props.dispatch(user.changeAccounts(query.AccountMasterId, this.props.history));
            }

            if (query.AccountMasterId) {
                let qs = ''
                for (let prop in query) {
                    if (prop !== 'AccountMasterId') qs += `${prop}=${query[prop]}&`
                }
                if (qs != '') {
                    qs = "?" + qs
                    qs = qs.substring(0, qs.length - 1)
                }
                var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + qs
                window.history.pushState({ path: newurl }, '', newurl);
            }

            const host = window.location.host
            if (host === 'localhost:8080' || host === '127.0.0.1:8080' || host === 'localhost:8082' || host === 'dev.web.mpzmail.com' || host === 'test.web.mpzmail.com' || host === 'web.capsule.mpztesting.com') {
                const res = await axios.get('/accountMaster/site')
                this.props.dispatch(siteActions.setSite(res.data))
                document.title = 'DEV || ' + res.data.siteName;
                const link = document.createElement('link');
                link.rel = 'stylesheet';
                link.href = config.getConnString() + '/style/style.css?id=' + res.data.siteId
                document.head.appendChild(link);
                setTimeout(() => {
                    const current = document.getElementById('mpMainStyle')
                    if (current) current.remove()
                }, 1000)

                // if (res.data.favicon) {
                //   processFavicon(res.data.favicon)
                // }
            }


        }
    }

    render() {

        if (this.props.user.dataUpToDate == true && this.props.user.loggedIn == true) {
            if (!this.props.accountMaster.id) {
                if (!this.state.loadingUser) {
                    this.setState({ loadingUser: true }, () => {
                        axios.get('/accountUser/me')
                    })
                }
                return <div></div>
            }

            return (
                <div>
                    <Switch>
                        <PermissionRouter permissionName="" method="" exact path="/cp/templates/add/wysiwyg/:templateID?" component={Wysiwyg} />
                        <PermissionRouter permissionName="" method="" exact path="/cp/templates/add/fromCode/:templateID?" component={TemplateFromCode} />
                        <PermissionRouter permissionName="" method="" exact path="/cp/templates/add/dragDrop/:templateID?" component={DragDrop} />
                        <PermissionRouter permissionName="templates" method="read" exact path="/cp/campaigns/add/standard/:campaignID?/:step?" component={AddCampaignStandard} />
                        <PermissionRouter permissionName="automations" method="read" exact path="/cp/automation/addnew/:automationId?" component={CreateAutomationNew} />
                        <PermissionRouter permissionName="company" method="read" exact path="/cp/company/servers/deliverymanager" component={DeliveryManager} />
                        <PermissionRouter permissionName="company" method="read" path="/cp/conversations/inbox/:activeInboxId?/:activeConversationId?" component={ConversationsRouter} />
                        <PermissionRouter permissionName="company" method="read" path="/cp/conversations/:inboxId" component={ConversationsRouter} />
                    </Switch >
                </div >
            );
        }
        else {
            //grabbing data - wait til its refreshed with an overlay
            return <UILIB.LoadingOverlay fullscreen={true} />
        }
    };
}
import React from 'react'

export default class QuestionCircle extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 10.0001C18 12.1218 17.1571 14.1566 15.6569 15.6569C14.1566 17.1572 12.1217 18.0001 10 18.0001C7.87827 18.0001 5.84344 17.1572 4.34315 15.6569C2.84285 14.1566 2 12.1218 2 10.0001C2 7.87833 2.84285 5.8435 4.34315 4.34321C5.84344 2.84292 7.87827 2.00006 10 2.00006C12.1217 2.00006 14.1566 2.84292 15.6569 4.34321C17.1571 5.8435 18 7.87833 18 10.0001ZM10 7.00006C9.8243 6.99989 9.65165 7.04602 9.49945 7.13379C9.34724 7.22157 9.22085 7.3479 9.133 7.50006C9.06957 7.61794 8.98311 7.72188 8.87876 7.80572C8.77441 7.88956 8.65429 7.9516 8.52552 7.98816C8.39675 8.02472 8.26194 8.03505 8.12911 8.01855C7.99627 8.00204 7.86809 7.95903 7.75218 7.89207C7.63628 7.82511 7.53499 7.73556 7.45433 7.62873C7.37367 7.5219 7.31529 7.39996 7.28263 7.27014C7.24997 7.14033 7.24371 7.00528 7.26421 6.873C7.28472 6.74072 7.33157 6.6139 7.402 6.50006C7.73222 5.92817 8.24191 5.48122 8.85203 5.22852C9.46214 4.97582 10.1386 4.9315 10.7765 5.10242C11.4143 5.27334 11.978 5.64996 12.38 6.17386C12.782 6.69777 13 7.33968 13 8.00006C13.0002 8.62068 12.8079 9.22609 12.4498 9.73291C12.0916 10.2397 11.5851 10.6231 11 10.8301V11.0001C11 11.2653 10.8946 11.5196 10.7071 11.7072C10.5196 11.8947 10.2652 12.0001 10 12.0001C9.73478 12.0001 9.48043 11.8947 9.29289 11.7072C9.10536 11.5196 9 11.2653 9 11.0001V10.0001C9 9.73484 9.10536 9.48049 9.29289 9.29295C9.48043 9.10542 9.73478 9.00006 10 9.00006C10.2652 9.00006 10.5196 8.8947 10.7071 8.70717C10.8946 8.51963 11 8.26528 11 8.00006C11 7.73484 10.8946 7.48049 10.7071 7.29295C10.5196 7.10542 10.2652 7.00006 10 7.00006ZM10 15.0001C10.2652 15.0001 10.5196 14.8947 10.7071 14.7072C10.8946 14.5196 11 14.2653 11 14.0001C11 13.7348 10.8946 13.4805 10.7071 13.293C10.5196 13.1054 10.2652 13.0001 10 13.0001C9.73478 13.0001 9.48043 13.1054 9.29289 13.293C9.10536 13.4805 9 13.7348 9 14.0001C9 14.2653 9.10536 14.5196 9.29289 14.7072C9.48043 14.8947 9.73478 15.0001 10 15.0001Z" fill={color} />
        </svg>
    }
}        
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import { connect } from 'react-redux';

import TrackingTemplate from './template';
import OverViewPage from './overview';
import EventsPage from './events/events';
import AdvancedPage from './advanced/advanced'
import SiteTrackerView from './view/index';

@connect((store) => {
    return { site: store.siteMaster, account: store.accountMaster }
})
class SiteTrackingOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            embedCode: "",
            tierAllowed: undefined,
            loaded: false
        }
        this.getAccount = this.getAccount.bind(this);
        this.getTier = this.getTier.bind(this);
    }

    componentDidMount() {
        this.getAccount();
    }

    async getAccount() {
        let response = await axios.get('/accountMaster')
        this.setState({
            account: response.data.AccountMaster,
            embedCode: '<script defer async type="text/javascript" id="mp-loader" src="https://' + this.props.site.trackingDomain + '/tracker?am=' + response.data.AccountMaster.base64id + '"></script>',
        }, this.getTier)

    }

    async getTier() {
        let res = await axios.get('/accountMaster/tier/webTracking')
        this.setState({ tierAllowed: res.data, loaded: true })

    }

    render() {
        if (!this.state.loaded) return <div></div>
        let hideTemplate = false;
        if (this.props.history && this.props.history.location && this.props.history.location.pathname) {
            let path = this.props.history.location.pathname.split("/")
            if (path && path.length > 3 && !isNaN(path[3])) {
                hideTemplate = true;
            }
        }
        let newProps = {
            embedCode: this.state.embedCode,
            tierAllowed: this.state.tierAllowed,
            history: this.props.history,
            getAccount: this.getAccount,
            account: this.state.account,
            hideTemplate: hideTemplate
        }



        //normal routes

        return <TrackingTemplate {...newProps}>
            <Switch>
                <Route exact path="/cp/trackers/events" render={() => <EventsPage {...newProps} />} />
                <Route exact path="/cp/trackers/advanced" render={() => <AdvancedPage {...newProps} />} />
                <Route path="/cp/trackers/:trackerId" render={(routeProps) => <SiteTrackerView {...newProps} {...routeProps} />} />
                <Route exact path="/cp/trackers/" render={() => <OverViewPage {...newProps} />} />
            </Switch>
        </TrackingTemplate>
    }
}

export default SiteTrackingOverview;
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import Action from './action'

class DynamicsActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            fields: {
                lead: [],
                contact: []
            }
        }
        this.save = this.save.bind(this)
        this.addAction = this.addAction.bind(this)
        this.update = this.update.bind(this)
        this.delete = this.delete.bind(this)
    }

    componentDidMount() {
        var validTypes = ['BigInt', 'Boolean', 'DateTime', 'Decimal', 'Double', 'Integer', 'Memo', 'Money', 'String']
        axios.get('/application/dynamics/availablefields').then(response => {
            var fields = {
                lead: response.data.lead.filter(field => validTypes.indexOf(field.type) > -1),
                contact: response.data.contact.filter(field => validTypes.indexOf(field.type) > -1)
            }
            this.setState({
                loaded: true,
                fields
            })
        })

        if (!this.props.app.appSettings.actions) this.props.app.appSettings.actions = {}

        if (!this.props.app.appSettings.actions.open) this.props.app.appSettings.actions.open = []
        if (!this.props.app.appSettings.actions.click) this.props.app.appSettings.actions.click = []
        if (!this.props.app.appSettings.actions.unsubscribe) this.props.app.appSettings.actions.unsubscribe = []
        if (!this.props.app.appSettings.actions.junk) this.props.app.appSettings.actions.junk = []
        if (!this.props.app.appSettings.actions.bounce) this.props.app.appSettings.actions.bounce = []
    }

    save() {
        var binding = this.props.app;
        axios.put("/application/account/" + binding.id, binding).then(() => {
            return axios.put('/application/dynamics/actions')
        }).then(this.props.onClose)
    }

    addAction(action, type) {
        this.props.app.appSettings.actions[action].push({ type })
        this.setState({})
    }

    update(type, action, index, ev) {
        var actions = this.props.app.appSettings.actions[action].filter(a => a.type === type)
        var obj = actions[index]
        if (obj) {
            obj[ev.currentTarget.name] = ev.currentTarget.value
        }
        this.setState({})
    }

    delete(type, action, index) {
        var actions = this.props.app.appSettings.actions[action].filter(a => a.type === type)
        var obj = actions[index]
        if (obj) {
            var mainIndex = this.props.app.appSettings.actions[action].findIndex(a => a === obj)
            if (mainIndex > -1) {
                this.props.app.appSettings.actions[action].splice(mainIndex, 1)
            }
        }
        this.setState({})
    }

    render() {
        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />
        var actions = this.props.app.appSettings.actions

        var leadOpens = actions.open.filter(a => a.type === 'lead')
        var contactOpens = actions.open.filter(a => a.type === 'contact')
        var leadClicks = actions.click.filter(a => a.type === 'lead')
        var contactClicks = actions.click.filter(a => a.type === 'contact')
        var leadUnsubs = actions.unsubscribe.filter(a => a.type === 'lead')
        var contactUnsubs = actions.unsubscribe.filter(a => a.type === 'contact')
        var leadJunks = actions.junk.filter(a => a.type === 'lead')
        var contactJunks = actions.junk.filter(a => a.type === 'contact')
        var leadBounces = actions.bounce.filter(a => a.type === 'lead')
        var contactBounces = actions.bounce.filter(a => a.type === 'contact')

        return (
            <div>
                <h4 className="mar-b25">Select which fields to update in specific scenarios</h4>

                <Action type="lead" action="open" app={this.props.app} fields={this.state.fields.lead} addAction={this.addAction} actions={leadOpens} update={this.update} delete={this.delete} />
                <Action type="contact" action="open" app={this.props.app} fields={this.state.fields.contact} addAction={this.addAction} actions={contactOpens} update={this.update} delete={this.delete} />
                <Action type="lead" action="click" app={this.props.app} fields={this.state.fields.lead} addAction={this.addAction} actions={leadClicks} update={this.update} delete={this.delete} />
                <Action type="contact" action="click" app={this.props.app} fields={this.state.fields.contact} addAction={this.addAction} actions={contactClicks} update={this.update} delete={this.delete} />
                <Action type="lead" action="unsubscribe" app={this.props.app} fields={this.state.fields.lead} addAction={this.addAction} actions={leadUnsubs} update={this.update} delete={this.delete} />
                <Action type="contact" action="unsubscribe" app={this.props.app} fields={this.state.fields.contact} addAction={this.addAction} actions={contactUnsubs} update={this.update} delete={this.delete} />
                <Action type="lead" action="junk" app={this.props.app} fields={this.state.fields.lead} addAction={this.addAction} actions={leadJunks} update={this.update} delete={this.delete} />
                <Action type="contact" action="junk" app={this.props.app} fields={this.state.fields.contact} addAction={this.addAction} actions={contactJunks} update={this.update} delete={this.delete} />
                <Action type="lead" action="bounce" app={this.props.app} fields={this.state.fields.lead} addAction={this.addAction} actions={leadBounces} update={this.update} delete={this.delete} />
                <Action type="contact" action="bounce" app={this.props.app} fields={this.state.fields.contact} addAction={this.addAction} actions={contactBounces} update={this.update} delete={this.delete} />

                <div className="mar-t30 mar-b30">
                    <UILIB.Button text="Save" onClick={this.save} className="button-primary" />
                </div>
            </div>
        );
    }
}

export default DynamicsActions;
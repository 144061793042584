import React from "react";

export default function IconEmojiSmile({ color = "currentColor", ...rest }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.652 12.182C12.3037 12.5999 11.8903 12.9313 11.4354 13.1575C10.9804 13.3836 10.4928 13.5 10.0003 13.5C9.50784 13.5 9.0202 13.3836 8.56523 13.1575C8.11025 12.9313 7.69685 12.5999 7.34863 12.182M8.64614 7.75C8.64614 8.164 8.50614 8.5 8.33364 8.5C8.16114 8.5 8.02114 8.164 8.02114 7.75C8.02114 7.336 8.16114 7 8.33364 7C8.50614 7 8.64614 7.336 8.64614 7.75ZM8.33364 7.75H8.34031V7.765H8.33364V7.75ZM11.9795 7.75C11.9795 8.164 11.8395 8.5 11.667 8.5C11.4945 8.5 11.3545 8.164 11.3545 7.75C11.3545 7.336 11.4945 7 11.667 7C11.8395 7 11.9795 7.336 11.9795 7.75ZM11.667 7.75H11.6736V7.765H11.667V7.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10" cy="10" r="7.5" stroke={color} strokeWidth="1.5" />
    </svg>
  );
}

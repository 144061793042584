import axios from 'axios';
import DbConn from '../../Classes/siteVars.js';
import TagManager from 'react-gtm-module';
import TranspondSiteTracking from '../../Classes/siteTrackingHelpers';
import connProps from '../../Classes/siteVars.js';

export function getSiteFromURL(url) {
    return function (dispatch) {
        DbConn.getConnString()
        var baseUrl = DbConn.getConnString();
        axios.post(baseUrl + '/siteFromURL', { url: url })
            .then(function (response) {
                if (response.data.success) {
                    if (response.data.favicon && response.data.favicon.indexOf('rel="icon"') > -1) {
                        checkFavicon(response.data.favicon)
                        // processFavicon(response.data.favicon)
                    }

                    if (response.data.googleAnalyticsKey) {
                        //init GA
                        // ReactGA.initialize(response.data.googleAnalyticsKey);
                    }
                    if (response.data.siteId && response.data.siteId === 1) {
                        try {
                            const tagManagerArgs = {
                                gtmId: connProps.gtmKey || "",
                            }
                            TagManager.initialize(tagManagerArgs)
                            let key = connProps.transpondKey || "Ng==";
                            if (window.location.hostname === "localhost" || window.location.hostname === "web.staging.dcloud.mpztesting.com") key = 'MQ%3D%3D'
                            TranspondSiteTracking.initialise(key)
                        }
                        catch (err) { console.log(err); }
                    }
                    //SET TOKEN AND USER ID IN USERS STORE
                    dispatch({ type: "INIT_SITE_DATA_LOAD", payload: response.data });
                    document.title = response.data.siteName;
                }
            })
            .catch(function (error) {
                //send error.message
                console.log(error.message);
            });
    }
}

export function routeLocationDidUpdate(location) {
    return function (dispatch) {
        dispatch({ type: "CHANGE_PAGE", payload: location });
    }
}

export function alterSiteDrawer(isOpen, showClose, direction, content, firstRun, width = null, className, onClose) {
    const options = { isOpen, showClose, direction, content, firstRun, width, className, onClose };

    return function (dispatch) {
        dispatch({ type: "CHANGE_SITE_DRAWER", payload: options });
    }
}

export function alterSiteDrawerWidth(newWidth) {

    return function (dispatch) {
        dispatch({ type: "CHANGE_SITE_DRAWER_WIDTH", payload: newWidth });
    }
}

export function changeMainNavTab(theTabID) {
    //sets the current nav ID
    return function (dispatch) {
        dispatch({ type: "CHANGE_MAINNAV_TAB", payload: theTabID });
    }
}

export function changeCampaignView(view) {
    return function (dispatch) {
        dispatch({ type: 'CHANGE_CAMPAIGN_VIEW', payload: view })
    }
}

export function setSite(site) {
    return function (dispatch) {
        dispatch({ type: "INIT_SITE_DATA_LOAD", payload: site });
    }
}

export function alterSnackbar(open, message, autoCloseDuration = 2000) {
    return function (dispatch) {
        dispatch({ type: "ALTER_SNACKBAR", payload: { open, message, autoCloseDuration } });
    }
}

export function alterFullpage(content) {
    return function (dispatch) {
        dispatch({ type: "ALTER_FULLPAGE", payload: content });
    }
}

export function handleGenericError(error) {
    const message =
        error?.data?.error || error?.message || "An unexpected error occurred";

    return function (dispatch) {
        dispatch(alterSnackbar(true, message, 5000));
    };
}

function processFavicon(favicon) {
    document.head.insertAdjacentHTML('afterbegin', favicon);
}

function checkFavicon(add) {
    var favicon = undefined;
    var nodeList = document.getElementsByTagName("link");
    for (var i = 0; i < nodeList.length; i++) {
        if ((nodeList[i].getAttribute("rel") == "icon") || (nodeList[i].getAttribute("rel") == "shortcut icon")) {
            favicon = nodeList[i].getAttribute("href");
        }
    }
    if (!favicon) {
        processFavicon(add)
    }
}
import React from "react";
import { Switch, Route, useParams, useRouteMatch } from "react-router-dom";
import { inboxTabOptions } from "~/Classes/conversations";
import UILIB from "~/Common-Objects/Lib";
import GeneralPage from "../general";
import ChannelsPage from "../channels";
import AiAssistantsPage from "../aiAssistants";
import AgentsPage from "../agents";
import TemplatesPage from '../templates';
import FieldsPage from '../fields'
import SettingsLayout from "../../components/settingsLayout";
import Button from "../../components/button";
import SettingsNavItem from "../../components/settingsNavItem";
import Breadcrumbs from "../../components/breadcrumbs";
import i18n from "~/i18n";

export default function InboxTemplate({ inbox, onUpdateName = () => { } }) {
  const { path, url } = useRouteMatch();
  const { inboxId, settingName } = useParams();

  const pathWithoutSettingName = path.slice(0, path.lastIndexOf("/"));
  const urlWithoutSettingName = url.slice(0, url.lastIndexOf("/"));

  const currentTab = inboxTabOptions.find((tab) => tab.value === settingName);

  const commonProps = { inbox, onUpdateName };

  return (
    <SettingsLayout
      heading={i18n.t(currentTab?.label)}
      subheading={currentTab?.subheading}
      backButton={
        <Button
          to={`/cp/conversations/inbox/${inboxId}`}
          variant="secondary"
          size="s"
          iconLeft={<UILIB.Icon name="chevron-left" />}
        >
          {i18n.t("chat:settings.back")}
        </Button>
      }
      sideNavList={
        <ul>
          {inboxTabOptions.map(({ label, value }) => {
            return (
              <li key={value}>
                <SettingsNavItem
                  to={`${urlWithoutSettingName}/${value}`}
                  selected={settingName === value}
                >
                  {i18n.t(label)}
                </SettingsNavItem>
              </li>
            );
          })}
        </ul>
      }
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumbs.Item to={urlWithoutSettingName}>
            <UILIB.Icon name="inbox" />
            {inbox?.name}
          </Breadcrumbs.Item>

          <Breadcrumbs.Item>{i18n.t(currentTab?.label)}</Breadcrumbs.Item>
        </Breadcrumbs>
      }
    >
      <Switch>
        <Route exact path={`${pathWithoutSettingName}/general`}>
          <GeneralPage {...commonProps} />
        </Route>

        <Route exact path={`${pathWithoutSettingName}/channels`}>
          <ChannelsPage {...commonProps} />
        </Route>

        <Route exact path={`${pathWithoutSettingName}/ai-assistants`}>
          <AiAssistantsPage {...commonProps} />
        </Route>

        <Route exact path={`${pathWithoutSettingName}/agents`}>
          <AgentsPage {...commonProps} />
        </Route>

        <Route exact path={`${pathWithoutSettingName}/templates`}>
          <TemplatesPage {...commonProps} />
        </Route>
        <Route exact path={`${pathWithoutSettingName}/fields`}>
          <FieldsPage {...commonProps} />
        </Route>
      </Switch>
    </SettingsLayout>
  );
}

import React from 'react'
import { useHistory } from 'react-router'
import UILIB from '~/Common-Objects/Lib'
import AutomationThemeSelector from '~/pages/cp/includes/automations/automationThemeSelector'
import DrawerAddCampaignStep1 from '~/pages/cp/campaigns/add/drawerAddCampaignStep1';
import { alterSiteDrawer } from '~/data/actions/siteActions'
import axios from '~/data/http/axios'
import { useDispatch } from 'react-redux'

export default function ActionExplainer({ app, accApp, action }) {
    const history = useHistory()
    const dispatch = useDispatch()

    const selectTheme = async (theme) => {
        const response = await axios.post('/automation/theme/' + (theme.id || theme))
        history.push('/cp/automation/addnew/' + response.data.Automation.id)
    }

    const createAutomation = async () => {
        // create an automation prefilled with this action
        if (action.automationThemeId) {
            selectTheme(action.automationThemeId)
        } else {
            const drawer = <AutomationThemeSelector scratch={() => history.push('/cp/automation/addnew')} select={selectTheme} />
            dispatch(alterSiteDrawer(true, true, 'right', drawer, true, '100vw', 'full'))
        }
    }

    const createCampaign = async () => {
        // ask for a template then create a campaign with that group and on the integration panel already
        let groups;
        if (accApp.appSettings.groupId) {
            groups = [{ id: accApp.appSettings.groupId, include: true }]
        }
        const drawer = <DrawerAddCampaignStep1 history={history} Groups={groups} />
        dispatch(alterSiteDrawer(true, false, 'right', drawer, true, 500, "drawer_nopad"))
    }

    return <div>
        <h4 className="mar-b25">{action.name}</h4>
        <div className="mar-b25">{action.description}</div>
        <UILIB.Button text="Create Campaign" onClick={createCampaign} className="full-width primary button-md mar-b20" />
        <UILIB.Button text="Create Automation" onClick={createAutomation} className="full-width primary button-md mar-b20" />
    </div>
}
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import UILIB from '../../../../../Common-Objects/Lib'
import axios from '../../../../../data/http/axios'
import SocialCampaignCreateContent from './content'
import SocialCampaignCreateSchedule from './schedule'
import SocialCampaignSelectChannels from './channels'
import SocialCampaignCreateCustomise from './customise'
import i18n from '../../../../../i18n'

export default function SocialCampaignCreate() {
    const { campaignId, step } = useParams()
    const history = useHistory()
    const [campaign, setCampaign] = useState(null)
    const [firstLoad, setFirstLoad] = useState(true)
    const [renaming, setRenaming] = useState(false)
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')

    const getCampaign = async () => {
        const res = await axios.get('/campaign/' + campaignId + '?templateIncludeJson=true')
        setCampaign(res.data.Campaign)
        if (firstLoad) {
            setName(res.data.Campaign.campaignName)
            setFirstLoad(false)
            if (res.data.Campaign.options.step) return history.push(`/cp/campaigns/add/social/${campaignId}/${res.data.Campaign.options.step}`)
            if (res.data.Campaign.Campaigns.length && step === '1') {
                if (res.data.Campaign.Campaigns.some(c => c.Template.templateJson.content !== res.data.Campaign.Template.templateJson.content)) {
                    history.push(`/cp/campaigns/add/social/${campaignId}/3`)
                } else {
                    history.push(`/cp/campaigns/add/social/${campaignId}/2`)
                }
            }
            else if (!step) history.push(`/cp/campaigns/add/social/${campaignId}/1`)
        }
    }

    const saveStep = async () => {
        await axios.put('/campaign/' + campaignId, {
            campaign: {
                options: {
                    ...campaign.options,
                    step
                }
            }
        })
    }

    const changeName = e => {
        setName(e.currentTarget.value)
    }

    const cancelNameChange = () => {
        setName(campaign.campaignName)
        setRenaming(false)
    }

    const saveName = async () => {
        if (name.trim().length < 3) return setNameError('Name must be at least 3 characters')
        setNameError('')
        await axios.put('/campaign/' + campaignId, {
            campaign: {
                campaignName: name.trim()
            }
        })
        setCampaign({ ...campaign, campaignName: name.trim() })
        setRenaming(false)
    }

    useEffect(() => {
        getCampaign()
    }, [campaignId])

    useEffect(() => {
        saveStep()
    }, [step])

    if (!campaign) return <UILIB.LoadingIcons />

    const hasChannels = campaign?.Campaigns?.length > 0
    const hasContent = campaign?.Template?.templateJson?.content.length > 0
    const hasContentForAllCampaigns = campaign?.Campaigns?.filter(c => c.Template?.templateJson?.content?.length > 0).length > 0

    const steps = [{
        title: i18n.t('social:nav.channels'),
        onClick: () => history.push(`/cp/campaigns/add/social/${campaignId}/1`),
        selected: step === '1',
        complete: hasChannels
    }, {
        title: i18n.t('social:nav.content'),
        onClick: () => {
            if (hasChannels) {
                history.push(`/cp/campaigns/add/social/${campaignId}/2`)
            }
        },
        selected: step === '2',
        complete: hasContent
    }, {
        title: i18n.t('social:nav.customise'),
        onClick: () => {
            if (hasContent) {
                history.push(`/cp/campaigns/add/social/${campaignId}/3`)
            }
        },
        selected: step === '3',
        complete: hasContentForAllCampaigns
    }, {
        title: i18n.t('social:nav.schedule'),
        onClick: () => {
            if (hasContentForAllCampaigns) {
                history.push(`/cp/campaigns/add/social/${campaignId}/4`)
            }
        },
        selected: step === '4',
        complete: step > 4
    }]

    return (
        <UILIB.Paper>
            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={12}>
                    {!renaming && <div className="mar-b25 quickFlex">
                        <div className="page-header mar-r10">{campaign.campaignName}</div>
                        <UILIB.Icons
                            style={{ width: "20px", height: "20px" }}
                            icon="pencil"
                            onClick={() => setRenaming(true)}
                        />
                    </div>}
                    {renaming && <div className="mar-b25 quickFlex">
                        <UILIB.TextInput
                            outerStyle={{ flex: "1" }}
                            autoFocus={true}
                            value={name}
                            onEnterPress={saveName}
                            onChange={changeName}
                            onEscapePress={cancelNameChange}
                            error={nameError}
                        />
                        <UILIB.Icons
                            style={{ flex: "0", width: "20px", height: "20px" }}
                            icon="tick"
                            onClick={saveName}
                            className="mar-l10"
                        />
                    </div>}

                    <UILIB.ProgressNav steps={steps} />


                </UILIB.Column>
            </UILIB.Row>

            <div className="campaign-holder">
                {step == 1 && <SocialCampaignSelectChannels campaign={campaign} refresh={getCampaign} />}
                {step == 2 && <SocialCampaignCreateContent campaign={campaign} refresh={getCampaign} />}
                {step == 3 && <SocialCampaignCreateCustomise campaign={campaign} refresh={getCampaign} />}
                {step == 4 && <SocialCampaignCreateSchedule campaign={campaign} refresh={getCampaign} />}
            </div>
        </UILIB.Paper>
    )
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import EmailTools from '~/Classes/emailValidator';
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})
export default class IncGoAddGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newGroupData: {
                groupName: this.props.groupName || "",
                friendlyName: this.props.friendlyName || "",
                defaultFromName: this.props.defaultFromName || "",
                defaultFromEmailAddress: this.props.defaultFromEmailAddress || "",
                signupReminderText: this.props.signupReminderText || ""
            },
            errors: {
                groupName: "",
                friendlyName: "",
                defaultFromName: "",
                defaultFromEmailAddress: "",
                signupReminderText: ""
            },
            groupCreateError: "",
            charCount: 0
        };

        this.updateValue = this.updateValue.bind(this);
        this.createGroup = this.createGroup.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.keyPress = this.keyPress.bind(this);
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    updateValue(evt) {
        var newGroupData = this.state.newGroupData;
        newGroupData[evt.target.name] = evt.target.value;
        this.setState({ newGroupData: newGroupData, charCount: evt.target.value.length });
    }

    keyPress(event) {
        if (event.key === 'Enter') {
            this.createGroup();
        }
    }

    createGroup() {
        var self = this;
        var errors = {
            groupName: "",
            friendlyName: "",
            defaultFromName: "",
            defaultFromEmailAddress: "",
            signupReminderText: ""
        };
        var newGroupData = this.state.newGroupData;

        var errored = false;
        if (newGroupData.groupName.length < 3) {
            errors.groupName = i18n.t('subscribers:index.alertName');
            errored = true;
        }
        if (newGroupData.friendlyName.length < 3) {
            errors.friendlyName = i18n.t('subscribers:index.alertFriendlyName');
            errored = true;
        }
        if (newGroupData.defaultFromName.length < 3) {
            errors.defaultFromName = i18n.t('subscribers:index.alertFromName');
            errored = true;
        }
        if (newGroupData.defaultFromEmailAddress.length < 3 || EmailTools.validateEmail(newGroupData.defaultFromEmailAddress) === false) {
            errors.defaultFromEmailAddress = i18n.t('subscribers:index.alertFromAddress');
            errored = true;
        }
        if (newGroupData.signupReminderText.length < 3) {
            errors.signupReminderText = i18n.t('subscribers:index.alertReminder');
            errored = true;
        }

        this.setState({ errors: errors })

        newGroupData.public = true;
        newGroupData.defaultReplyAddress = newGroupData.defaultFromEmailAddress;

        if (errored === false) {
            axios.post('/group', newGroupData)
                .then((groupDat) => {
                    self.props.addedGroup(groupDat);
                    if (!self.props.dontCloseDrawer) {
                        self.closeDrawer();
                    }
                })
                .catch((error) => {
                    console.log(error.data)
                    self.setState({ groupCreateError: error.data.error ? error.data.error.error : 'Error creating group' });
                })
        }
    }
    render() {
        return <div>
            <h4 className="mar-b25">{i18n.t('subscribers:index.addGroup')}</h4>
            <UILIB.TextInput outerClassName="mar-b25" type="text" name="groupName" explainer={i18n.t('subscribers:index.nameDetails')} label={i18n.t('subscribers:index.groupName')} value={this.state.newGroupData.groupName} onChange={this.updateValue} error={this.state.errors.groupName} focus={true} />
            <UILIB.TextInput outerClassName="mar-b25" type="text" name="friendlyName" explainer={i18n.t('subscribers:index.friendlyDetail')} label={i18n.t('subscribers:index.friendly')} value={this.state.newGroupData.friendlyName} onChange={this.updateValue} error={this.state.errors.friendlyName} />
            <UILIB.TextInput outerClassName="mar-b25" type="text" name="defaultFromName" explainer={i18n.t('subscribers:index.fromNameDetail')} label={i18n.t('subscribers:index.fromName')} value={this.state.newGroupData.defaultFromName} onChange={this.updateValue} error={this.state.errors.defaultFromName} />
            <UILIB.TextInput outerClassName="mar-b25" type="email" name="defaultFromEmailAddress" explainer={i18n.t('subscribers:index.fromAddressDetail')} label={i18n.t('subscribers:index.fromAddress')} value={this.state.newGroupData.defaultFromEmailAddress} onChange={this.updateValue} error={this.state.errors.defaultFromEmailAddress} />
            <UILIB.TextArea outerClassName="mar-b25" type="text" name="signupReminderText" explainer={i18n.t('subscribers:index.reminderTextDetail')} label={i18n.t('subscribers:index.reminderText')} value={this.state.newGroupData.signupReminderText} onChange={this.updateValue} error={this.state.errors.signupReminderText} rows="5" iconBottomRight={this.state.charCount + "/1500"} maxLength={1500} />
            {this.state.groupCreateError && <UILIB.FormError key={"err"} className="mar-b25">{this.state.groupCreateError}</UILIB.FormError>}
            <UILIB.Button text={i18n.t('subscribers:index.addGroup')} className="button-primary" iconLeft={<UILIB.Icons icon="plus" />} onClick={this.createGroup} />
        </div>
    }
};


import React, { useState } from 'react'
import axios from '../../../../data/http/axios'
import UILIB from '../../../../Common-Objects/Lib'

export default function RemoveOrganisation({ organisation, onFinish = () => { } }) {
    const [deleting, setDeleting] = useState(false)

    const confirm = async () => {
        setDeleting(true)
        await axios.delete('/organisation/' + organisation.id)
        setDeleting(false)
        onFinish(true)
    }

    return (
        <div>
            <h4 className='mar-b25'>Are you sure you want to delete {organisation.name}?</h4>
            <div className="mar-b25">We will not delete any Contacts who belong to this Organisation</div>

            <div className="quickFlex">
                <UILIB.Button disabled={deleting} text="Delete" className="button-red mar-r10" onClick={confirm} iconRight={<UILIB.Icons icon="cross" />} />
                <UILIB.Button disabled={deleting} text="Cancel" onClick={() => onFinish()} />
            </div>

            {deleting && <UILIB.LoadingIcons iconType="2" />}
        </div>
    )
}
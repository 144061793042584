import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from '~/data/http/axios';
import axios2 from 'axios'
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import DeleteCampaign from './deleteCampaign'
import PermissionChecker from '~/Classes/permissions';
import FinishPausedCampaign from './finishPausedCampaign'
import TemplateImageLoader from '~/pages/cp/automation/addNew/templateImageLoader.jsx'
import EmailPreviewer from '~/pages/cp/includes/emailPreviewer/emailPreviewer'
import SmsPreviewer from '~/pages/cp/includes/sms/preview'

/*add campaign dialogue*/
import DrawerAddCampaignStep1 from '~/pages/cp/campaigns/add/drawerAddCampaignStep1';
import DrawerChangeResend from './drawerChangeResend'
import DrawerCancelResend from './drawerCancelResend'
import DrawerResendToNonOpeners from './drawerResendToNonOpeners';
import SiteVars from '~/Classes/siteVars';
import i18n from '~/i18n';
import { getUserSettings, setUserSettings } from '~/Classes/userSettings';
import queryString from 'query-string';
import { AvailableColumns } from './consts'
import { SupportedApps } from './social/create/shared'
import FormatNumberFuncs from '../../../Classes/numberFormatFunctions';
import CampaignFoldersDrawer from './folders';
import ManageFolders from './manageFolders';
import helpers from '~/Classes/helpers'

//CONNECT TO STORE
@connect((store) => {
	return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

export default class CampaignsTemplate extends React.Component {
	constructor(props) {
		super(props);

		const query = queryString.parse(location.search);

		this.state = {
			rawCampaigns: [],
			campaigns: [],
			calendarEvents: [],
			alertBar: null,
			query,
			isModalWindowOpen: false,
			isDeleteModalOpen: false,
			deleteModalCampaignID: 0,
			inputfield: "no value",
			firstLoadDone: false,
			loadingData: 1,
			sortColumn: "startDate",
			sortDirection: "desc",
			searchText: "",
			popupAnchorEl: undefined,
			pageSize: 10,
			advanced: moment(this.props.user.userData.createdAt) < moment('2000-05-10'),
			mobilePageSize: 10,
			totalCampaigns: 0,
			currentPage: 0,
			startRecord: 0,
			alertMessage: '',
			alertOpen: false,
			campaignType: query.campaignType ? parseInt(query.campaignType) || 0 : 0,
			paging: false,
			viewType: "list",
			types: [{
				label: i18n.t('campaigns:type.all'),
				value: 0
			}, {
				label: i18n.t('standardCampaign'),
				value: 1
			}, {
				label: i18n.t('abSplitTest'),
				value: 10
			}, {
				label: i18n.t('sms'),
				beta: 'sms',
				value: 6
			}, {
				label: 'Social Post',
				beta: 'social',
				value: 30
			},
			// {
			// 	label: i18n.t('directMail'),
			// 	value: 20
			// },
			{
				label: i18n.t('Automation Campaigns'),
				value: 3
			},
			{
				label: i18n.t('rssResend'),
				value: 99
			}, {
				label: i18n.t('Ads'),
				value: 60
			}, {
				label: i18n.t('campaigns:main.archived'),
				value: -1
			}],
			statusOptions: [{
				label: i18n.t('campaigns:status.all'),
				value: ''
			}, {
				label: i18n.t('campaigns:status.Draft'),
				value: 'Draft'
			}, {
				label: i18n.t('campaigns:status.Awaiting Start'),
				value: 'Awaiting Start'
			}, {
				label: i18n.t('campaigns:status.Queued'),
				value: 'Queued'
			}, {
				label: i18n.t('campaigns:status.Processing'),
				value: 'Processing'
			}, {
				label: i18n.t('campaigns:status.Ready for Sending'),
				value: 'Ready for Sending'
			}, {
				label: i18n.t('campaigns:status.Sending'),
				value: 'Sending'
			}, {
				label: i18n.t('campaigns:status.Paused'),
				value: 'Paused'
			}, {
				label: i18n.t('campaigns:status.Finished'),
				value: 'Finished'
			}, {
				label: i18n.t('campaigns:status.Errored'),
				value: 'Errored'
			}, {
				label: i18n.t('campaigns:status.Finishing'),
				value: 'Finishing'
			}],
			statusOption: query.statusOption ? parseInt(query.statusOption) || 0 : 0,
			finishingCampaigns: [],
			dateFilter: 0,
			dateFilterTypes: [
				{ value: 0, label: i18n.t('campaigns:main.allTime') },
				{ value: 2, label: i18n.t('campaigns:main.today') },
				{ value: 3, label: i18n.t('campaigns:main.yesterday') },
				{ value: 6, label: i18n.t('campaigns:main.tomorrow') },
				{ value: 4, label: i18n.t('campaigns:main.last7Days') },
				{ value: 5, label: i18n.t('campaigns:main.last30Days') },
				{ value: 1, label: i18n.t('campaigns:main.customDateRange') },
			],
			dateFilterStartDate: moment().subtract(365, 'day'),
			dateFilterEndDate: moment().endOf("month"),
			firstCampaignCount: 0,
			settings: null,
			applications: [],
			folders: [],
			folderId: -1
		};

		this.tableUpdater = this.tableUpdater.bind(this);
		this.goSearchText = this.goSearchText.bind(this);
		this.changeSearchText = this.changeSearchText.bind(this);
		this.loadApplications = this.loadApplications.bind(this);
		this.loadCampaigns = this.loadCampaigns.bind(this);
		this.editCampaign = this.editCampaign.bind(this);
		this.addNewCampaign = this.addNewCampaign.bind(this);
		this.viewCampaign = this.viewCampaign.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
		this.deleteCampaign = this.deleteCampaign.bind(this);
		this.confirmDelete = this.confirmDelete.bind(this);
		this.cancelCampaign = this.cancelCampaign.bind(this);
		this.pauseCampaign = this.pauseCampaign.bind(this);
		this.resumeCampaign = this.resumeCampaign.bind(this);
		this.loadmore = this.loadmore.bind(this);
		this.duplicate = this.duplicate.bind(this);
		this.changeType = this.changeType.bind(this)
		this.renderTable = this.renderTable.bind(this)
		this.updateField = this.updateField.bind(this)
		this.toggleEdit = this.toggleEdit.bind(this)
		this.saveCampaign = this.saveCampaign.bind(this)
		this.cancelEdit = this.cancelEdit.bind(this)
		this.finishPausedCampaign = this.finishPausedCampaign.bind(this)
		this.confirmFinishPausedCampaign = this.confirmFinishPausedCampaign.bind(this)
		this.togglePublic = this.togglePublic.bind(this)
		this.editPausedTemplate = this.editPausedTemplate.bind(this)
		this.changeResend = this.changeResend.bind(this)
		this.cancelResend = this.cancelResend.bind(this)
		this.cancelResendGo = this.cancelResendGo.bind(this);
		this.reuseTemplate = this.reuseTemplate.bind(this)
		this.previewTemplate = this.previewTemplate.bind(this)
		this.toggleArchive = this.toggleArchive.bind(this)
		this.loadFolders = this.loadFolders.bind(this)
		this.setFolders = this.setFolders.bind(this)
		this.manageFolders = this.manageFolders.bind(this)
		this.setCampaignFolders = this.setCampaignFolders.bind(this)

		this.CancelToken = axios2.CancelToken;
		this.source = this.CancelToken.source();

		this.dateFilterUpdate = this.dateFilterUpdate.bind(this);
		this.changeDateFilterType = this.changeDateFilterType.bind(this);

		this.changeViewType = this.changeViewType.bind(this);
		this.calendarChangedDate = this.calendarChangedDate.bind(this);
		this.selectCalendarEvent = this.selectCalendarEvent.bind(this);
		this.disableNotifications = this.disableNotifications.bind(this)

		this.resendToNonOpeners = this.resendToNonOpeners.bind(this);
		this.resendToNonOpenersGo = this.resendToNonOpenersGo.bind(this);
	}

	handleChange(event) {
		this.setState({
			[event.currentTarget.name]: event.currentTarget.value
		});
	}

	componentDidMount() {
		if (this.state.pageSize > 100) this.changePageSize(10)

		getUserSettings().then(s => {
			let save = false
			if (!s.campaigns) s.campaigns = {}
			if (!s.campaigns.limit && this.props.accountMaster.tableLimit?.campaigns) {
				save = true
				s.campaigns.limit = this.props.accountMaster.tableLimit.campaigns
			}
			if (!s.campaigns.view && this.props.siteMaster.campaignView) {
				save = true
				s.campaigns.view = this.props.siteMaster.campaignView
			}
			if (save) setUserSettings(s).catch(console.log)

			const update = { settings: s }
			if (s.campaigns?.limit) update.pageSize = s.campaigns.limit
			if (s.campaigns?.view) {
				update.viewType = s.campaigns.view
			}

			if (s.campaigns?.columns) update.settings.campaigns.columns = mergeArrays(s.campaigns.columns, AvailableColumns, 'value')
			else update.settings.campaigns.columns = AvailableColumns.slice()

			if (s.campaigns?.advanced !== undefined) update.settings.campaigns.advanced = s.campaigns.advanced
			else update.settings.campaigns.advanced = moment(this.props.user.userData.createdAt) < moment('2024-05-01')

			if (!s.campaigns?.performance) s.campaigns.performance = {}
			if (!s.campaigns.performance.email) s.campaigns.performance.email = 'clicks'
			if (!s.campaigns.performance.social) s.campaigns.performance.social = 'engagement'
			if (!s.campaigns.performance.ads) s.campaigns.performance.ads = 'clicks'

			if (this.props.match.params.type && parseInt(this.props.match.params.type)) update.campaignType = parseInt(this.props.match.params.type)

			if (update.viewType === 'calendar') {
				update.dateFilter = 1
				update.pageSize = 1000
				update.dateFilterStartDate = moment().startOf('month').subtract(7, "days")
			} else {
				update.dateFilter = 0
				update.dateFilterStartDate = moment().subtract(365, 'day')
			}

			this.setState(update, this.loadApplications)
		})
	}

	componentDidUpdate(prevProps, prevState) {
		const query = queryString.parse(location.search);
		if (query.campaignType && parseInt(query.campaignType) !== this.state.campaignType && parseInt(query.campaignType) !== prevState.campaignType) {
			this.setState({
				campaignType: parseInt(query.campaignType),
				query,
			}, this.loadCampaigns)
		}
		if (!query.campaignType && this.state.query?.campaignType) {
			this.setState({
				campaignType: 0,
				query
			}, this.loadCampaigns)
		}
		if (prevProps.match.params.type !== this.props.match.params.type) {
			this.setState({
				campaignType: parseInt(this.props.match.params.type) || 0,
				query
			}, this.loadCampaigns)
		}
	}

	componentWillUnmount() {
		this.source.cancel('I cancelled this')
		this.source = this.CancelToken.source();
		clearTimeout(this.timer)
	}

	changeStatus(index) {
		this.setState({
			loadingData: 1,
			statusOption: index,
			currentPage: 0,
			startRecord: 0
		}, this.loadCampaigns)
	}

	addNewCampaign(startDate) {
		if (startDate && !moment(startDate).isValid()) startDate = null
		const allowedTypes = [1, 6, 10, 20, 30, 60]
		let type = this.state.campaignType
		if (!allowedTypes.includes(type)) type = undefined
		var drawerContent = <DrawerAddCampaignStep1 campaignType={type} history={this.props.history} startDate={startDate} />
		this.props.dispatch(siteMaster.alterSiteDrawer(true, false, "left", drawerContent, true, 500, "drawer_nopad"));
	}
	editCampaign(campaign, returnUrl) {
		var url = ''
		switch (campaign.type) {
			case 1:
				url = '/cp/campaigns/add/standard/' + campaign.id + '/1'
				break;
			case 10:
				url = '/cp/campaigns/add/ab/' + campaign.id
				break;
			case 20:
				url = '/cp/campaigns/add/directMail/' + campaign.id
				break;
			case 3:
				url = '/cp/campaigns/add/automation/' + campaign.id
				break;
			case 30:
				url = '/cp/campaigns/add/social/' + campaign.id + '/1'
				break;
			case 6:
				url = '/cp/campaigns/add/sms/' + campaign.id + '/1'
				break;
		}
		if (returnUrl) return url
		this.props.history.push(url);
	}
	editPausedTemplate(campaignId) {
		axios.get('/campaign/' + campaignId).then((_campaign) => {
			var campaign = _campaign.data.Campaign;

			var templateType = campaign.Template.templateType;
			var theURL = '/cp/templates/add/dragDrop/'
			if (templateType === 1) {
				theURL = "/cp/templates/add/fromCode/";
			}
			if (templateType === 2) {
				theURL = "/cp/templates/add/wysiwyg/";
			}
			theURL = theURL + campaign.TemplateId
			theURL += "?redirect=/cp/campaigns";
			this.props.history.push(theURL)
		}).catch(console.log)
	}

	previewTemplate(campaign) {
		var drawerContent
		var turnOffTracking = false;
		if (campaign.options && campaign.options.doNotTrackLinks && campaign.options.doNotTrackLinks == true) {
			turnOffTracking = true;
		}
		if (campaign.type === 6) {
			drawerContent = <SmsPreviewer campaignId={campaign.id} turnOffTracking={turnOffTracking} />
		} else if (campaign.type === 30) {
			return
		} else {
			drawerContent = <EmailPreviewer showSettings={true} templateId={campaign.TemplateId} subject={'TEST: ' + (campaign.subject || campaign.campaignName)} history={this.props.history} turnOffTracking={turnOffTracking} fromCampaignId={campaign.id} />
		}
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
	}

	viewCampaign(campaign, returnUrl) {
		let url = ''
		if (campaign.status === 'Quarantined') return
		if (campaign.type == 60) {
			url = '/cp/campaigns/viewad/' + campaign.id;
		}
		if (campaign.type === 30) {
			url = '/cp/campaigns/viewsocial/' + campaign.id;
		}
		if (!url) {
			url = '/cp/campaigns/view/' + campaign.id;
		}

		if (campaign.type === 1 && campaign.status === 'Awaiting Start') {
			url = '/cp/campaigns/add/finished/' + campaign.id
		}

		if (returnUrl) return url
		this.props.history.push(url)
	}

	finishPausedCampaign(id) {
		var drawerContent = <FinishPausedCampaign campaignId={id} confirm={this.confirmFinishPausedCampaign} />;
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, null, true));
	}
	confirmFinishPausedCampaign(id) {
		var finishingCampaigns = Array.isArray(this.state.finishingCampaigns) ? this.state.finishingCampaigns.push(id) : [id]
		this.setState({ finishingCampaigns })
		axios.put('/campaign/' + id + '/finish').then(this.loadCampaigns).catch(console.log)
	}

	cancelCampaign(id) {
		axios.put('/campaign/' + id + '/cancel').then(this.loadCampaigns).catch(console.log)
	}
	deleteCampaign(id) {
		var drawerContent = <DeleteCampaign campaignId={id} confirm={this.confirmDelete} />;
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, null, true));
	}
	confirmDelete(id) {
		axios.delete('/campaign/' + id).then(() => {
			this.setState({ alertMessage: i18n.t('campaigns:main.alertDeleted'), alertOpen: true }, this.loadCampaigns);
		})
	}
	pauseCampaign(id) {
		axios.put('/campaign/' + id + '/pause')
			.then(this.loadCampaigns).catch(console.log)
	}
	resumeCampaign(id) {
		axios.put('/campaign/' + id + '/resume')
			.then(this.loadCampaigns).catch(console.log)
	}

	changeType(value) {
		// console.log('CHANGE TYPE', value)
		this.setState({
			campaignType: value,
			loadingData: 1,
			currentPage: 0,
			startRecord: 0
		}, this.loadCampaigns)
	}

	duplicate(campaign, winning) {
		let header = i18n.t('campaigns:main.duplicateCampaign')
		if (winning) {
			header = i18n.t('campaigns:main.duplicateWinningCampaign')
		}
		var theDrawer = <UILIB.DrawerConfirm header={header} inputText={i18n.t('campaigns:main.campaignName')} input={true} value={campaign.campaignName + ' - copy'} confirmText="Copy" confirm={(name) => {
			axios.post('/campaign/copy', {
				winning,
				id: campaign.id,
				name,
				type: winning ? 1 : campaign.type
			}).then(res => {
				this.editCampaign(res.data.Campaign)
			})
		}} />
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true));
	}

	async loadApplications() {
		try {
			let applications = await axios.get('/application')
			this.setState({ applications: applications.data.Applications }, this.loadFolders)
		}
		catch (err) {
			this.loadFolders()
		}
	}

	async loadFolders() {
		const res = await axios.get('/campaign/folder?orderBy=name')
		const folders = res.data
		const updateObj = { folders }
		if (!folders.find(f => f.id === this.state.folderId)) updateObj.folderId = -1
		this.setState(updateObj, this.loadCampaigns)
	}

	loadCampaigns() {
		var sortColumn = this.state.sortColumn;
		var sortDirection = this.state.sortDirection;
		var startRecord = this.state.startRecord;
		var pageSize = this.state.pageSize;
		var searchText = this.state.searchText;
		var status = this.state.statusOption;

		if (this.state.gettingCampaigns) {
			this.source.cancel('I cancelled this')
			this.source = this.CancelToken.source();
		}

		this.setState({
			gettingCampaigns: true
		})

		clearTimeout(this.timer)

		status = this.state.statusOptions[status].value;


		if (history.pushState) {
			var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname
			if (this.state.campaignType) newurl += `${newurl[newurl.length - 1] === '?' ? '&' : '?'}campaignType=${this.state.campaignType}`
			if (this.state.statusOption) newurl += `${newurl[newurl.length - 1] === '?' ? '&' : '?'}statusOption=${this.state.statusOption}`
			window.history.pushState({ path: newurl }, '', newurl);
		}

		var queryStr = '?ignoreLog=1&sortColumn=' + sortColumn + '&sortOrder=' + sortDirection + '&searchText=' + searchText + '&startRecord=' + startRecord + '&recordLimit=' + pageSize + '&status=' + status
		if (this.state.campaignType) {
			if (this.state.campaignType == "3") {
				queryStr += '&automation=true'
			} else {
				queryStr += '&type=' + this.state.campaignType
			}
		}
		if (this.state.dateFilter) {
			queryStr += `&startDate=${this.state.dateFilterStartDate.format("YYYY-MM-DD")}&endDate=${this.state.dateFilterEndDate.format("YYYY-MM-DD")}&all=true`
		} else {
			queryStr += '&all=true'
		}

		if (this.state.viewType === 'calendar') queryStr += '&simple=true&hideStats=true&hideArchived=1&dontCount=true'

		if (!this.state.campaignType && !this.state.dateFilter && !this.state.searchText) {
			queryStr += '&hideArchived=1'
		}

		if (this.state.folderId !== -1 && this.state.folderId) {
			queryStr += '&folderId=' + this.state.folderId
		}

		if (!this.state.settings.campaigns.advanced) {
			queryStr += '&dontCount=true'
		}

		var url = '/campaign'

		var self = this;
		axios.get(url + queryStr, { cancelToken: this.source.token }).then((res) => {
			self.state.rawCampaigns.filter(c => Object.keys(c).some(f => f.indexOf('Editing') > 1)).forEach(c => {
				var found = res.data.Campaigns.find(cc => cc.id == c.id)
				if (found) {
					for (var prop in c) {
						if (prop.indexOf('Editing') > -1) {
							found[prop] = c[prop]
							var actual = prop.replace('Editing', '')
							if (c[actual] !== undefined)
								found[actual] = c[actual]
						}
					}
				}
			})

			self.timer = setTimeout(self.loadCampaigns, 10000)
			let firstCampaignCount = this.state.firstCampaignCount;
			if (!this.state.firstLoadDone) {
				firstCampaignCount = res.data.countTotal
			}
			self.setState({
				rawCampaigns: self.state.loadingmore ? self.state.rawCampaigns.concat(res.data.Campaigns) : res.data.Campaigns,
				loadingData: 0,
				gettingCampaigns: false,
				totalCampaigns: res.data.countTotal,
				paging: false,
				loadingmore: false,
				firstLoadDone: true,
				firstCampaignCount
			}, self.renderTable);
		}).catch(() => { });
	}

	toggleEdit(index, field) {
		var campaigns = this.state.rawCampaigns
		var campaign = campaigns[index]
		if (campaign[field + 'Editing']) {
			campaign[field + 'Editing'] = false
			delete campaign[field + 'Backup']
		} else {
			campaign[field + 'Editing'] = true
			campaign[field + 'Backup'] = campaign[field]
		}

		this.setState({
			rawCampaigns: campaigns
		})

		this.renderTable()
	}

	updateField(ev, index) {
		var campaigns = this.state.rawCampaigns
		var campaign = campaigns[index]

		campaign[ev.currentTarget.name] = ev.currentTarget.value
		this.setState({
			rawCampaigns: campaigns
		})

		this.renderTable()
	}

	cancelEdit(index, field) {
		var campaigns = this.state.rawCampaigns
		var campaign = campaigns[index]

		campaign[field + 'Editing'] = false
		campaign[field] = campaign[field + 'Backup']
		this.setState({
			rawCampaigns: campaigns
		})
		this.renderTable()
	}

	cancelResend(campaign) {

		var drawerContent = <DrawerCancelResend campaign={campaign} cancelResendGo={this.cancelResendGo} />
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true, '600px'));
	}

	cancelResendGo(campaign) {
		this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
		axios.put('/campaign/' + campaign.id, {
			campaign: {
				resendAfter: 0
			}
		}).then(() => {
			this.setState({
				alertMessage: 'Resend Cancelled',
				alertOpen: true
			})
			this.loadCampaigns()
		}).catch(this.loadCampaigns)
	}

	saveCampaign(index, field) {
		var campaign = this.state.rawCampaigns[index]
		axios.put('/campaign/' + campaign.id, { campaign })
		this.toggleEdit(index, field)
	}

	togglePublic(campaign) {
		axios.put('/campaign/' + campaign.id, { campaign: { public: !campaign.public } }).then(() => {
			this.setState({ alertMessage: 'Campaign made ' + (campaign.public ? 'Private' : 'Public'), alertOpen: true })
			this.loadCampaigns()
		})
	}

	reuseTemplate(campaign) {
		axios.post('/template/copy', {
			id: campaign.TemplateId,
			name: campaign.campaignName
		}).then(response => {
			if (response.data.Template) {
				var templateType = response.data.Template.templateType;
				var theURL = '/cp/templates/add/dragDrop/'
				if (templateType === 1) {
					theURL = "/cp/templates/add/fromCode/";
				}
				if (templateType === 2) {
					theURL = "/cp/templates/add/wysiwyg/";
				}
				theURL = theURL + response.data.Template.id;
				this.props.history.push(theURL)
			}
		})
	}

	async toggleArchive(campaign) {
		await axios.put('/campaign/' + campaign.id + '/archive')
		this.setState({
			alertMessage: i18n.t(campaign.archived ? 'campaigns:main.unarchiveMessage' : 'campaigns:main.archiveMessage', {
				campaign: campaign.campaignName
			}),
			alertOpen: true
		})
		this.loadCampaigns()
	}

	resendToNonOpeners(campaign) {
		var drawerContent = <DrawerResendToNonOpeners campaign={campaign} resendToNonOpenersGo={this.resendToNonOpenersGo} />
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true));
	}
	async resendToNonOpenersGo(campaign) {
		this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
		try {
			let newCampaign = await axios.post('/campaign/' + campaign.id + '/resendnoopens')
			this.props.history.push(`/cp/campaigns/add/standard/${newCampaign.data.id}/1`);
		}
		catch (err) {
			let message = i18n.t('campaigns:main.resendNonOpenersError')
			if (err && err.data && err.data.error) message = err.data.error;
			this.setState({
				alertMessage: message,
				alertOpen: true
			})
		}
	}

	renderTable() {
		var canEdit = PermissionChecker('campaigns', this.props.permissionStore.permissions, "write");

		var resends = [];

		var theCalendarData = this.state.rawCampaigns.map((thisLine, index) => {
			var startDate = moment(thisLine.startDate);
			var endDate = moment(thisLine.finishedDate || thisLine.estimatedEndDate || moment(thisLine.startDate).add(1, "hours"));
			let classes = "event ";
			switch (thisLine.status.toLowerCase()) {
				case "draft":
					classes += "grey"
					break;
				case "sending":
					classes += "purple"
					break;
				case "finished":
					classes += "primary"
					break;
				case "quarantined":
				case "errored":
					classes += "red"
					break;

				default:
					classes += "blue"
					break;
			}

			let calEv = {
				id: thisLine.id,
				title: thisLine.campaignName,
				allDay: false,
				start: startDate.toDate(),
				end: endDate.toDate(),
				status: thisLine.status,
				classes: classes,
				type: thisLine.type,
			}

			let iconDetails = helpers.getCampaignType(thisLine.type);
			let icon = iconDetails.icon;
			let type = iconDetails.alt;

			if (icon) calEv.iconRight = <UILIB.Icons icon={icon} style={{ height: 18, width: 18 }} alt={type} />

			if (thisLine.options && thisLine.options.resent) {

				let resendEv = { ...calEv }
				resendEv.start = endDate.toDate();
				resendEv.title = "Resend - " + resendEv.title;
				resends.push(resendEv)

				calEv.end = startDate.toDate();
			}

			return calEv;
		})

		if (resends.length) {
			theCalendarData = theCalendarData.concat(resends);
		}

		const accountPaused = this.props.accountMaster.accountMaster.paused
		var theCampaignsData = this.state.rawCampaigns.map((thisLine, index) => {
			var willResend = false;
			var resendDate = '';
			let resentData = ''


			if (thisLine.resendAfter != 0) {
				willResend = true;
				if (thisLine.finishedDate) {
					resendDate = i18n.t('campaigns:overview.resendText') + ' ' + DateTimeFunctions.formatDateTime(moment(thisLine.finishedDate).add(thisLine.resendAfter, 'days').format(), 2);
				} else {
					resendDate = i18n.t('campaigns:overview.resendTextNotFinished', { days: thisLine.resendAfter })
				}
			}

			var hasResent = false
			if (thisLine.options && thisLine.options.resent) {
				resentData = <div className="text-xsml">{DateTimeFunctions.formatDateTime(thisLine.options.resentDate, "2")} - {i18n.t('campaigns:main.resentCount', { count: NumberFunctions.formatNumber(thisLine.options.resentCount) })}</div>
				hasResent = true;
			}

			if (this.state.finishingCampaigns.length) {

				var finishingCampaigns = this.state.finishingCampaigns;
				finishingCampaigns.forEach((camp, index) => {
					if (camp == thisLine.id) {
						if (finishingCampaigns) {
							//found this campaign should be finished
							if (thisLine.status != 'Finished') {
								thisLine.status = 'Finished'
							} else {
								finishingCampaigns.splice(index, 1)
								this.setState({ finishingCampaigns })
							}
						}
					}
				})
			}

			var campaignName = thisLine.campaignName;

			var isAutomation = false;
			if (thisLine.Automation && thisLine.Automation.name) {
				isAutomation = true;
				campaignName = <>
					<div>Automation "{thisLine.Automation.name}"</div>
					<div className="text-xsml text-grey mar-b5">Email Step, Subject: {thisLine.subject}</div>
					<UILIB.Button className="button-xs" text={i18n.t('campaigns:main.viewAutomation')} onClick={(e) => { e.preventDefault(); this.props.history.push('/cp/automation/addnew/' + thisLine.Automation.id); }} />
				</>
			} else if (thisLine.archived) {
				campaignName += ` (${i18n.t('campaigns:main.archived')})`
			}

			var totalDelivered = thisLine.stats['Total Sent'] - (thisLine.stats['Hard Bounces'] + thisLine.stats['Soft Bounces'] + thisLine.stats['Total Dropped']);
			var totalSubscribers = thisLine.stats['Total Subscribers'];
			if (isAutomation) totalSubscribers = thisLine.stats['Total Sent']

			let iconDetails2 = helpers.getCampaignType(thisLine.type);
			let thisIcon = iconDetails2.icon;
			let thisAlt = iconDetails2.alt;

			if (thisLine.options && thisLine.options.recurringValue) {
				thisIcon = "icon-stream";
				thisAlt = i18n.t('rssResend');
			}

			var finalSegments = JSON.parse(JSON.stringify(thisLine.Segments));

			if (finalSegments && finalSegments.length) {
				finalSegments = finalSegments.filter(seg => {
					if (!seg.parentId || seg.parentId == null || seg.parentId == undefined) {
						return true;
					}
					else {
						return false;
					}
				})
			}

			var finishedOutput = <span></span>;
			if (thisLine.status === "Finished" && !hasResent && !thisLine.resendAfter && !isAutomation) {
				finishedOutput = <UILIB.SquareChip className="square-chip-green small-icon">{i18n.t('campaigns:status.Finished')}</UILIB.SquareChip>
			}
			if (thisLine.status === "Finished" && !hasResent && thisLine.resendAfter && !isAutomation) {
				finishedOutput = <UILIB.SquareChip className="square-chip-purple small-icon">{i18n.t('campaigns:status.Resending')}</UILIB.SquareChip>;
			}
			if (thisLine.status === "Finished" && hasResent && !isAutomation) {
				finishedOutput = <UILIB.SquareChip className="square-chip-green small-icon">{i18n.t('campaigns:status.Resent')}</UILIB.SquareChip>
			}

			var hasAnOption = false;
			if (!isAutomation && thisLine.status === "Draft" && canEdit) hasAnOption = true;
			if (thisLine.status != "Draft") hasAnOption = true;
			if (!isAutomation && (thisLine.status == "Sending" || thisLine.status == "Processing")) hasAnOption = true;
			if (!isAutomation && thisLine.status == "Paused") hasAnOption = true;
			if (!isAutomation && canEdit) hasAnOption = true;
			if (thisLine.type === 10 && canEdit) hasAnOption = true;
			if (!isAutomation && (thisLine.status === "Draft") && canEdit) hasAnOption = true;
			if (!isAutomation && (thisLine.status === "Paused") && canEdit) hasAnOption = true;
			if (thisLine.type === 10 && canEdit && thisLine.status === 'Finished') hasAnOption = true;
			if (!isAutomation && (thisLine.status === "Draft") && canEdit) hasAnOption = true;
			if (!isAutomation && (thisLine.status === "Paused") && canEdit) hasAnOption = true;
			if (!isAutomation && willResend && canEdit) hasAnOption = true;
			if (!isAutomation && thisLine.status === "Finished" && !this.props.siteMaster.hideSubDetail && moment.duration(moment(new Date()).diff(thisLine.finishedDate)).asDays() > 30 && canEdit) hasAnOption = true;
			if (thisLine.TemplateId && canEdit) hasAnOption = true;

			if (isAutomation && thisLine.status === 'Draft') hasAnOption = false

			let sendingNotifications = false;
			if (thisLine.sendUpdateEmails && thisLine.sendUpdateEmailsRecipient && thisLine.sendUpdateEmailsNextSend) {
				sendingNotifications = true
			}

			const isAd = thisLine.type === 60;
			const isSms = thisLine.type === 6

			let canDelete = moment.duration(moment(new Date()).diff(thisLine.finishedDate)).asDays() > 30;
			//alow resend to non opners, only if its 1 day old and type = 1 (Normal Campaign)
			let canResend = thisLine.type == 1;

			let application = {};
			if (thisLine.options?.ApplicationId) {
				application = this.state.applications.find(f => f.id == thisLine.options.ApplicationId)
			}

			let url = this.editCampaign(thisLine, true)
			if (thisLine.status !== "Draft") url = this.viewCampaign(thisLine, true)

			let sentPercentage;
			if (thisLine.stats['Total Sent'] > 0 && thisLine.stats['Total Subscribers'] > 0) {
				sentPercentage = Math.round((thisLine.stats['Total Sent'] / thisLine.stats['Total Subscribers']) * 100)
				sentPercentage += '%'
			}

			var obj = {
				id: {
					value: thisLine.id
				},
				icon: {
					headerValue: "",
					value: <UILIB.Icons circle={true} icon={thisIcon} alt={thisAlt} title={thisAlt} color="#A9A9AD" style={{ height: 30, width: 30 }} />,
					align: "center",
					width: 20
				},
				campaignImage: {
					headerValue: "",
					value: <span>
						{thisLine.TemplateId && <div className="campaign-thumbnail"><TemplateImageLoader onClick={() => this.previewTemplate(thisLine)} type={thisLine.type} imageUrl={SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + thisLine.TemplateId + ".jpg"} /></div>}
						{!thisLine.TemplateId && thisLine.type !== 10 && thisLine.type !== 20 && thisLine.type !== 60 && <div className="center-xs text-lg" style={{ border: '1px solid grey', borderRadius: '3px', padding: '10px 0', width: 76 }}>
							<UILIB.Icons icon="pencil" color="grey" />
						</div>}
						{thisLine.type === 10 && <div className="center-xs text-lg" style={{ border: '2px solid #33257E', borderRadius: '3px', padding: '10px 0', color: '#33257E', width: 76 }}>
							<UILIB.Icons icon="split" />
						</div>}
						{thisLine.type === 20 && <div className="center-xs text-lg icon-mailbox-full" style={{ border: '2px solid #33257E', borderRadius: '3px', padding: '10px 0', color: '#33257E', width: 76 }}>
							<UILIB.Icons icon="split" />
						</div>}
						{thisLine.type === 60 && <div className="center-xs text-lg" style={{ border: '1px solid #33257E', borderRadius: '3px', padding: '10px 0', color: '#33257E', width: 76 }}>
							<img style={{ width: "20px" }} src={application ? application.appLogoUri : <UILIB.Icons icon="megaphone" />} />
						</div>}
					</span>
					,
					orderBy: false,
					width: 96
				},
				campaignName: {
					headerValue: i18n.t('campaigns:main.campaignName'),
					value: <div style={{ width: "400px", whiteSpace: "break-spaces" }}>
						{thisLine.status === "Draft" && canEdit && !isAutomation && <span>
							<Link to={url} style={{ fontWeight: "600", textDecoration: 'none' }}>{campaignName}</Link>
						</span>}
						{thisLine.status === "Draft" && (!canEdit || isAutomation) && <span style={{ fontWeight: "600" }}>{campaignName}</span>}
						{thisLine.status !== "Draft" && <Link to={url} style={{ fontWeight: "600", textDecoration: 'none' }}>{campaignName}</Link>}

						{(thisLine.pollAnswers > 0) && <div className="mar-t5 mar-b5">
							<UILIB.SquareChip tooltip={thisLine.pollAnswers + " poll answers"} for={'pollStatus' + index} className="purple mar-b0" style={{ fontSize: "12px", padding: "3px 5px" }}>{i18n.t('campaigns:status.hasPolls')}</UILIB.SquareChip>
						</div>}

						{willResend == true && <div className="text-xsml">
							{resendDate} <div></div>
							<a onClick={() => { this.cancelResend(thisLine) }} className="mar-r10">{i18n.t('campaigns:overview.cancelResend')}</a>
							<a onClick={() => { this.changeResend(thisLine) }}>{i18n.t('campaigns:overview.changeResend')}</a>
						</div>}
						{!thisLine.pollAnswers && !thisLine.Automation && !willResend && !!thisLine.subject && <div className="text-xsml text-grey" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>Subject: {thisLine.subject}</div>}
					</div>,
					orderBy: true,
					width: 400
				},
				status: {
					headerValue: i18n.t('campaigns:main.status'),
					value: <span>
						{thisLine.status === "Draft" && <UILIB.SquareChip className="small-icon">{i18n.t('campaigns:status.Draft')}</UILIB.SquareChip>}
						{thisLine.status === "Awaiting Start" && <UILIB.SquareChip className="square-chip-blue small-icon">{i18n.t('campaigns:status.Awaiting Start')}</UILIB.SquareChip>}
						{thisLine.status === "Queued" && <UILIB.SquareChip className="square-chip-purple small-icon">{i18n.t('campaigns:status.Queued')}</UILIB.SquareChip>}
						{(thisLine.status === "Processing" || thisLine.status === 'Checking') && <UILIB.SquareChip className="square-chip-purple small-icon">{i18n.t('campaigns:status.Processing')}</UILIB.SquareChip>}
						{thisLine.status === "Ready for Sending" && <UILIB.SquareChip className="square-chip-purple small-icon">{i18n.t('campaigns:status.Ready for Sending')}</UILIB.SquareChip>}
						{(thisLine.status === "Sending" && !isAutomation) && <UILIB.SquareChip className="square-chip-purple small-icon">{i18n.t('campaigns:status.Sending')} {sentPercentage}</UILIB.SquareChip>}
						{thisLine.status === "Paused" && <UILIB.SquareChip className="square-chip-yellow small-icon">{i18n.t('campaigns:status.Paused')}</UILIB.SquareChip>}
						{thisLine.status === "UnPausing" && <UILIB.SquareChip className="square-chip-yellow small-icon">{i18n.t('campaigns:status.UnPausing')}</UILIB.SquareChip>}
						{(thisLine.status === "Finished" && !isAutomation) && finishedOutput}
						{thisLine.status === "Errored" && <UILIB.SquareChip tooltip={thisLine.error} for={'status' + index} className="square-chip-red small-icon">{i18n.t('campaigns:status.Errored')}</UILIB.SquareChip>}
						{thisLine.status === "Quarantined" && <UILIB.SquareChip tooltip={"We are just reviewing the content of this campaign"} for={'status' + index} className="square-chip-purple small-icon">{i18n.t('campaigns:status.Quarantined')}</UILIB.SquareChip>}
						{(isAutomation && (thisLine.status == "Sending" || thisLine.status == "Finished")) && <UILIB.SquareChip className="square-chip-purple small-icon">{i18n.t('campaigns:status.Sending')}</UILIB.SquareChip>}


						{resentData}
					</span>,
					orderBy: true,
					width: 150
				},
				startDate: {
					headerValue: i18n.t('campaigns:main.startDate'),
					value: thisLine.status.toLowerCase() === "sending" && !!thisLine.estimatedEndDate ? <div>
						<div>{DateTimeFunctions.formatDateTime(thisLine.startDate, 1)}</div>
						<div className="text-xsml">{i18n.t('campaigns:overview.dateFinishedEstimated')}: {DateTimeFunctions.formatDateTime(thisLine.estimatedEndDate, 1)}</div>
					</div> : thisLine.status.toLowerCase() == "draft" ? "" : <span>
						{thisLine.startDate != null && DateTimeFunctions.formatDateTime(thisLine.startDate, 1)}
						{thisLine.startDate == null && <span style={{ color: "#DDDDDD" }}>{i18n.t('campaigns:status.Not Started')}</span>}
					</span>,
					orderBy: true,
					width: 150
				},
				audience: {
					headerValue: i18n.t('campaigns:main.columns.audience'),
					value: <Audience campaign={thisLine} />,
					width: 400
				},
				folders: {
					headerValue: i18n.t('campaigns:main.columns.folders'),
					value: <CampaignFolders key={thisLine.id} selected={thisLine.CampaignFolders} onChange={v => this.setCampaignFolders(thisLine.id, v)} manage={() => this.setFolders(thisLine)} campaign={thisLine} folders={this.state.folders} />,
					width: 200
				},
				performance: {
					headerValue: i18n.t('campaigns:main.columns.performance'),
					value: <Performance campaign={thisLine} settings={this.state.settings.campaigns.performance} />,
					width: 240
				},
				"Total Sent": {
					headerValue: i18n.t('campaigns:overview.delivered'),
					value: !isAd && thisLine.type !== 30 && totalSubscribers !== undefined ? <UILIB.SmallLineGraph color={'#7246B1'} width="100" height="5" val1={thisLine.stats['Total Sent'] || 0} val2={totalSubscribers || 0} /> : "",
					orderBy: true,
					width: 175

				},
				"Unique Opens": {
					headerValue: i18n.t('campaigns:main.statsOpened'),
					value: isAd || thisLine.status.toLowerCase() == "draft" || isSms || thisLine.type === 30 ? "" : <span>
						<UILIB.SmallLineGraph color={'#65B5B1'} width="100" height="5" val1={thisLine.stats['Unique Opens'] || 0
						} val2={totalSubscribers || 0} />
					</span >,
					orderBy: true,
					width: 175,
				},
				"Unique Clicks": {
					headerValue: i18n.t('campaigns:main.statsClicked'),
					value: thisLine.status.toLowerCase() == "draft" || isSms ? "" : <UILIB.SmallLineGraph color={'#65B5B1'} width="100" height="5" val1={thisLine.stats['Unique Clicks'] || 0} val2={totalSubscribers || 0} />,
					orderBy: true,
					width: 175
				},
				"Hard Bounces": {
					headerValue: i18n.t('campaigns:main.statsBounced'),
					value: thisLine.status.toLowerCase() == "draft" || isSms || thisLine.type === 30 || isAd ? "" : <UILIB.SmallLineGraph color="#CC4E32" width="100" height="5" val1={thisLine.stats['Hard Bounces'] || 0} val2={totalSubscribers || 0} />,
					orderBy: true,
					width: 175
				},
				"Total Unsubscribes": {
					headerValue: i18n.t('campaigns:main.statsUnsubscribed'),
					value: thisLine.status.toLowerCase() == "draft" || thisLine.type === 30 || isAd ? "" : <UILIB.SmallLineGraph color="#CC4E32" width="100" height="5" val1={thisLine.stats['Total Unsubscribes'] || 0} val2={totalSubscribers || 0} />,
					orderBy: true,
					width: 175
				},
				outreach: {
					headerValue: "Outreach",
					value: <SocialStat campaign={thisLine} stat="outreach" />,
					width: 120
				},
				impressions: {
					headerValue: "Impressions",
					value: <SocialStat campaign={thisLine} stat="impressions" />,
					width: 120
				},
				engagement: {
					headerValue: "Engagement",
					value: <SocialStat campaign={thisLine} stat="engagement" />,
				},
				spend: {
					headerValue: "Spend",
					value: "",
				},
				endDate: {
					headerValue: "End Date",
					value: "",
				},
				// public: {
				// 	headerValue: i18n.t('campaigns:main.public'),
				// 	value: <UILIB.SquareChip className={"mar-b0 " + (thisLine.public ? 'primary' : 'secondary')} for={'campaign' + thisLine.id} tooltip={thisLine.public ? 'All Users can see this Campaign' : 'Only Admins and the Owner can see this Campaign'}>{thisLine.public ? 'Public' : 'Private'}</UILIB.SquareChip>
				// },
				optionsCol:
				{
					headerValue: " ",
					value: <Fragment>
						{thisLine.status != "UnPausing" && thisLine.status !== "Quarantined" && hasAnOption && !accountPaused &&
							<UILIB.OptionsDropdown popWidth="150px">
								<ul>
									{!isAutomation && thisLine.status === "Draft" && canEdit && !isAd && <li>
										<a onClick={() => this.editCampaign(thisLine)}>{i18n.t('edit')}</a>
									</li>}
									{thisLine.status != "Draft" && <li>
										<a onClick={() => { this.viewCampaign(thisLine) }}>{i18n.t('view')}</a>
									</li>}
									{!isAutomation && (thisLine.status === "Awaiting Start" || thisLine.status === 'Errored') && canEdit && !isAd && <li >
										<a onClick={() => { this.cancelCampaign(thisLine.id) }}>{thisLine.status === 'Errored' ? i18n.t('reset') : i18n.t('cancel')}</a>
									</li>}
									{!isAutomation && (thisLine.status == "Sending" || thisLine.status == "Processing") && canEdit && !isAd && <li>
										<a onClick={() => { this.pauseCampaign(thisLine.id) }}>{i18n.t('pause')}</a>
									</li>}
									{!isAutomation && thisLine.status == "Paused" && canEdit && !isAd && <li>
										<a onClick={() => { this.resumeCampaign(thisLine.id) }}>{i18n.t('resume')}</a>
									</li>}
									{!isAutomation && canEdit && !isAd && <li>
										<a onClick={() => this.duplicate(thisLine)}>{i18n.t('campaigns:main.duplicate')}</a>
									</li>}
									{thisLine.type === 10 && canEdit && thisLine.status === 'Finished' && <li>
										<a onClick={() => this.duplicate(thisLine, true)}>Copy Winning Test</a>
									</li>}
									{!isAutomation && (thisLine.status === "Draft") && canEdit && !isAd && <li>
										<a onClick={() => { this.deleteCampaign(thisLine.id) }}>{i18n.t('delete')}</a>
									</li>}
									{!isAutomation && !isSms && (thisLine.status === "Paused") && canEdit && !isAd && <li>
										<a onClick={() => { this.editPausedTemplate(thisLine.id) }}>{i18n.t('editTemplate')}</a>
									</li>}
									{!isAutomation && !isSms && (thisLine.status === "Paused") && canEdit && !isAd && <li>
										<a onClick={() => { this.finishPausedCampaign(thisLine.id) }}>Finish</a>
									</li>}

									{!isAutomation && !isSms && willResend && canEdit && !isAd && <li>
										<a onClick={() => { this.cancelResend(thisLine) }}>{i18n.t('campaigns:overview.cancelResend')}</a>
									</li>}
									{!isAutomation && !isSms && willResend && canEdit && !isAd && <li>
										<a onClick={() => { this.changeResend(thisLine) }}>{i18n.t('campaigns:overview.changeResend')}</a>
									</li>}
									{!isAutomation && thisLine.status === "Finished" && !this.props.siteMaster.hideSubDetail && canDelete && canEdit && !isAd && <li>
										<a onClick={() => { this.deleteCampaign(thisLine.id) }}>{i18n.t('delete')}</a>
									</li>}
									{!isAutomation && canEdit && !isAd && <li>
										<a onClick={() => this.togglePublic(thisLine)}>{thisLine.public ? i18n.t('makePrivate') : i18n.t('makePublic')}</a>
									</li>}
									{thisLine.TemplateId && !isSms && canEdit && !isAd && thisLine.type !== 30 && <li>
										<a onClick={() => this.reuseTemplate(thisLine)}>{i18n.t('campaigns:main.reuseTemplate')}</a>
									</li>}
									{(canEdit && (thisLine.status === 'Finished' || thisLine.status === 'Errored')) && !isAd && <li>
										<a onClick={() => this.toggleArchive(thisLine)}>{thisLine.archived ? i18n.t('campaigns:main.unarchive') : i18n.t('campaigns:main.archive')}</a>
									</li>}
									{(canEdit && (thisLine.status === 'Finished' && canResend)) && !isAd && <li>
										<a onClick={() => this.resendToNonOpeners(thisLine)}>{i18n.t('campaigns:main.resendToNonOpeners')}</a>
									</li>}
									{sendingNotifications && !isAd && <li>
										<a onClick={() => this.disableNotifications(thisLine)}>Turn off Notification Emails</a>
									</li>}
									{this.props.accountMaster.tier?.permissions?.campaignFolders?.allowed && <li>
										<a onClick={() => this.setFolders(thisLine)}>Set Folders</a>
									</li>}
								</ul>
							</UILIB.OptionsDropdown>
						}
					</Fragment >,
					orderBy: false,
					fixed: true,
					width: 20
				}
			}
			if (this.props.siteMaster.siteId === 2) {
				//delete obj.statsBounces
				//delete obj.processed
				delete obj["Hard Bounces"]
				delete obj["Total Sent"]
			}
			else {
				//delete obj.statsSent
				delete obj["Total Subscribers"]
			}

			if (!this.state.settings.campaigns.advanced) {
				delete obj["Total Subscribers"]
				delete obj["Total Sent"]
				delete obj["Unique Opens"]
				delete obj["Unique Clicks"]
				delete obj["Hard Bounces"]
				delete obj["Total Unsubscribes"]
				delete obj.outreach
				delete obj.impressions
				delete obj.engagement
				delete obj.spend
				delete obj.endDate
			} else {
				delete obj["performance"]
			}
			for (const col of this.state.settings.campaigns.columns) {
				if (!col.selected) {
					delete obj[col.value]
				}
			}

			if (!this.props.accountMaster.accountMaster.beta.social) {
				delete obj.outreach
				delete obj.impressions
				delete obj.engagement
			}
			if (!this.props.accountMaster.accountMaster.beta.ads) {
				delete obj.spend
				delete obj.endDate
			}

			if (this.state.campaignType === 30) {
				delete obj["Total Sent"]
				delete obj["Unique Opens"]
				delete obj["Unique Clicks"]
				delete obj["Hard Bounces"]
				delete obj["Total Unsubscribes"]
			}
			if (this.state.campaignType === 1 || this.state.campaignType === 10 || this.state.campaignType === 3 || this.state.campaignType === 99) {
				delete obj.outreach
				delete obj.impressions
				delete obj.engagement
				delete obj.spend
				delete obj.endDate
			}

			if (!this.props.accountMaster.tier?.permissions?.campaignFolders?.allowed) {
				delete obj.folders
			}

			return obj
		})

		this.setState({
			campaigns: theCampaignsData,
			calendarEvents: theCalendarData
		});
	}

	tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {

		var thisState = {};
		var paging = false
		if (!sortDirection) {
			sortDirection = this.state.sortDirection;
		}
		if (!sortColumn) {
			sortColumn = this.state.sortColumn;
		}
		if (!pageSize) {
			pageSize = this.state.pageSize;
			paging = true
		}
		if (!currentPage && currentPage != "0") {
			currentPage = this.state.currentPage;
		}
		if (!searchText) {
			searchText = this.state.searchText;
		}

		var startRecord = currentPage * pageSize;
		this.setState({ sortColumn: sortColumn, sortDirection: sortDirection, pageSize: pageSize, currentPage: currentPage, searchText: searchText, startRecord: startRecord, paging }, this.loadCampaigns);

	}


	changePageSize(pageSize) {
		const settings = this.state.settings
		settings.campaigns.limit = pageSize
		setUserSettings(settings).catch(console.log)
		this.setState({ settings })
		this.tableUpdater(0, null, null, pageSize, null);
	}

	//GROUP NAME SEARCH STUFF
	changeSearchText(event) {
		this.setState({ searchText: event.target.value }, () => {
			if (this.searchTimer) clearTimeout(this.searchTimer)
			this.searchTimer = setTimeout(this.goSearchText, 250)
		})
	}
	goSearchText() {
		this.tableUpdater(0, null, null, null, null);
	}

	loadmore() {
		this.setState({
			currentPage: this.state.currentPage + 1,
			startRecord: this.state.campaigns.length,
			loadingmore: true
		}, this.loadCampaigns)
	}

	changeResend(campaign) {
		var drawerContent = <DrawerChangeResend campaign={campaign} updateCampaigns={this.loadCampaigns} />
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true, '600px'));
	}

	dateFilterUpdate(event, event2) {
		this.setState({ dateFilterStartDate: event.startDate, dateFilterEndDate: event.endDate }, this.loadCampaigns)
	}

	changeDateFilterType(value) {
		var dateFilterStartDate = this.state.dateFilterStartDate;
		var dateFilterEndDate = this.state.dateFilterEndDate;

		if (value == 2) {
			//today
			dateFilterStartDate = moment().startOf("day");
			dateFilterEndDate = moment().endOf("day");
		}
		if (value == 3) {
			//yesterday
			dateFilterStartDate = moment().subtract(1, "day");
			dateFilterEndDate = moment().subtract(1, "day");
		}
		if (value == 4) {
			//last7Days
			dateFilterStartDate = moment().subtract(7, "day");
			dateFilterEndDate = moment().endOf("day");
		}
		if (value == 5) {
			//last30Days
			dateFilterStartDate = moment().subtract(30, "day");
			dateFilterEndDate = moment().endOf("day");
		}
		if (value == 6) {
			//tomorrow
			dateFilterStartDate = moment().add(1, "day").startOf("day");
			dateFilterEndDate = moment().add(1, "day").endOf("day");
		}
		this.setState({ dateFilterStartDate, dateFilterEndDate, dateFilter: value }, this.loadCampaigns)
	}

	changeViewType(newType) {
		const settings = this.state.settings
		settings.campaigns.view = newType
		setUserSettings(settings).catch(console.log)
		this.setState({ settings })
		var pageSize = settings.campaigns?.limit || 10;

		var newState = { viewType: newType, loadingData: 1, campaigns: [], rawCampaigns: [], calendarEvents: [], dateFilter: 0 }
		if (newType == "calendar") {
			pageSize = 1000;
			newState.dateFilter = 1;
			newState.dateFilterStartDate = moment().startOf("month").subtract(7, "days");
			newState.dateFilterEndDate = moment().endOf("month");
		}
		newState.pageSize = pageSize;

		this.setState(newState, this.loadCampaigns)
	}

	calendarChangedDate(event) {
		const start = moment(event).startOf("month");
		const end = moment(event).endOf("month");
		this.setState({ dateFilterStartDate: start, dateFilterEndDate: end, loadingData: 1, campaigns: [], rawCampaigns: [] }, this.loadCampaigns)
	}

	selectCalendarEvent(theEvent, event) {

		if (theEvent.status === "Draft") this.editCampaign(theEvent)
		if (theEvent.status != "Draft") this.viewCampaign(theEvent)

	}


	disableNotifications(campaign) {
		axios.put('/campaign/' + campaign.id, {
			campaign: { sendUpdateEmails: false }
		}).then(this.loadCampaigns)
	}

	toggleAdvanced() {
		const settings = this.state.settings
		settings.campaigns.advanced = !settings.campaigns.advanced
		this.setState({
			settings
		}, this.loadCampaigns)
		setUserSettings(settings).catch(console.log)
	}

	changeColumns(e) {
		const column = e.target.value
		const settings = this.state.settings
		const option = settings.campaigns.columns.find(c => c.value === column)
		if (!option) return
		option.selected = !option.selected
		this.setState({
			settings
		}, this.loadCampaigns)
		setUserSettings(settings).catch(console.log)
	}

	changeFolder(value) {
		this.setState({ folderId: value }, this.loadCampaigns)
	}

	setFolders(campaign) {
		var drawerContent = <CampaignFoldersDrawer campaign={campaign} onFinish={() => {
			this.props.dispatch(siteMaster.alterSiteDrawer(false))
			this.loadFolders()
		}} />
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true, '400px'));
	}

	manageFolders() {
		var content = <ManageFolders onFinish={() => {
			this.loadFolders()
		}} />
		this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", content, true, '400px'));
	}

	setCampaignFolders(id, folders) {
		const raw = this.state.rawCampaigns
		const found = raw.find(c => c.id === id)
		if (!found) return
		found.CampaignFolders = folders
		this.setState({ rawCampaigns: raw }, this.renderTable)
	}

	render() {
		if (!this.state.firstLoadDone || !this.state.settings) return <UILIB.LoadingIcons />

		const canEdit = PermissionChecker('campaigns', this.props.permissionStore.permissions, "write")
		const campaignFoldersAllowed = this.props.accountMaster.tier?.permissions?.campaignFolders?.allowed

		return <UILIB.Paper>
			<div className="mar-b25 quickFlex">
				<div className='page-header'>{i18n.t('nav:campaigns')}</div>
				{this.props.siteMaster.siteId == 1 && <UILIB.Hint href="https://kb.transpond.io/article/118-what-is-an-email-campaign" iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>Learn how to master campaigns</UILIB.Hint>}
			</div>

			{
				this.state.viewType == "list" &&
				<UILIB.Row>
					<UILIB.Column lg={6} md={6} sm={6} xs={12} className="mar-b25">
						<UILIB.TextInput
							placeholder={i18n.t('campaigns:main.searchPlaceholder')}
							onChange={this.changeSearchText}
							iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16, marginTop: "1px" }} color="#2B2F41" onClick={() => { }} />}
						/>
					</UILIB.Column>
					<UILIB.Column lg={6} md={6} sm={6} xs={6} hide={["xs"]} style={{ textAlign: "right" }} className="mar-b25">
						{PermissionChecker("reporting", this.props.permissionStore.permissions, "read") &&
							<UILIB.Button style={{ whiteSpace: "nowrap" }} className="mar-l10" iconLeft={<UILIB.Icons icon="document" />} text={i18n.t('campaigns:main.reports')} onClick={() => this.props.history.push('/cp/campaigns/reports')} />
						}
						{PermissionChecker('campaigns', this.props.permissionStore.permissions, "write") &&

							<UILIB.Button className="mar-l10 button-primary cyt-start-campaign" iconLeft={<UILIB.Icons icon="plus" color="white" />} name="campaigns_start_campaign" onClick={() => this.addNewCampaign()}>{i18n.t('campaigns:main.startCampaign')}</UILIB.Button>
						}
					</UILIB.Column>
				</UILIB.Row>
			}


			<UILIB.Row >
				<UILIB.Column xs={12} sm={12} md={12}>
					<UILIB.Row className="mar-b25">
						<UILIB.Column sm={12} margin={0} className="hide-xs" style={{ display: "flex", justifyContent: "space-between" }}>
							<div className="quickFlex">
								<UILIB.ButtonSimple className="button-simple-fullsize mar-r5" selected={this.state.viewType == "list"} onClick={() => { this.changeViewType("list") }} iconLeft={<UILIB.Icons icon="list" />} />
								<UILIB.ButtonSimple className="button-simple-fullsize mar-r5" selected={this.state.viewType == "calendar"} onClick={() => { this.changeViewType("calendar") }} iconLeft={<UILIB.Icons icon="calendar" />} />
								<UILIB.Select
									outerClassName="mar-r5"
									inlineLabel="Type"
									value={this.state.campaignType}
									placeholderNoSelect="All"
									autoWidth={true}
									onChange={(e) => this.changeType(e.target.value)}
									fixedHeightLarge={true}
									data={this.state.types.filter(t => !t.beta || (this.props.accountMaster.accountMaster.beta && this.props.accountMaster.accountMaster.beta[t.beta]))}
								/>
								<UILIB.Select
									outerClassName="mar-r5"
									inlineLabel="Status"
									value={this.state.statusOption}
									placeholderNoSelect="All"
									autoWidth={true}
									onChange={(e) => this.changeStatus(e.target.value)}
									fixedHeightLarge={true}
									data={this.state.statusOptions.map((status, index) => {
										return { value: index, label: status.label }
									})}
								/>
								{this.state.viewType != "calendar" && <UILIB.Select
									outerClassName="mar-r5"
									inlineLabel="Timeframe"
									value={this.state.dateFilter}
									autoWidth={true}
									placeholderNoSelect="All Time"
									onChange={(e) => this.changeDateFilterType(e.target.value)}
									fixedHeightLarge={true}
									data={this.state.dateFilterTypes}
								/>}
								{(this.state.viewType != "calendar" && this.state.dateFilter == 1) &&
									<UILIB.TextInput outerStyle={{ marginLeft: "5px" }} type="multiDate" startDate={this.state.dateFilterStartDate} endDate={this.state.dateFilterEndDate} updateDate={this.dateFilterUpdate}></UILIB.TextInput>
								}
								{this.props.siteMaster.siteId === 1 && <UILIB.Select
									inlineLabel="Folder"
									outerClassName="mar-r5"
									value={this.state.folderId}
									topAuthWidth={true}
									hideNoOptions={true}
									onChange={(e) => this.changeFolder(e.target.value)}
									data={campaignFoldersAllowed ? [{ label: 'Any', value: -1 }, ...this.state.folders.map((folder) => {
										return { value: folder.id, label: folder.name }
									})] : []}
									topCta={campaignFoldersAllowed ?
										<UILIB.Button className="button-sml" style={{ width: '100%' }} onClick={this.manageFolders}>Manage Folders</UILIB.Button> :
										<UILIB.Button className="button-sml" style={{ width: '100%' }} to="/cp/company/subscription">Upgrade Required</UILIB.Button>
									}
								/>}
							</div>
							{this.state.viewType != "calendar" && <div className="quickFlex">
								<UILIB.Select
									align="right"
									outerClassName="mar-r5"
									inlineLabel="Per Page"
									value={this.state.pageSize}
									data={[10, 30, 50, 100]}
									onChange={e => this.changePageSize(e.target.value)}
								/>
								<ColumnsButton
									columns={this.state.settings.campaigns.columns.filter(c => c.selected).map(c => c.value)}
									onChange={this.changeColumns.bind(this)}
									advanced={this.state.settings.campaigns.advanced}
									toggleAdvanced={this.toggleAdvanced.bind(this)}
								/>
							</div>}

						</UILIB.Column>
						<UILIB.Column xs={12} className="show-xs" style={{ display: "flex" }}>
							<UILIB.ButtonSelect className="mar-r5 mar-b5" selected={this.state.statusOption} headerText={this.state.statusOptions[this.state.statusOption].label} autoClose
								data={this.state.statusOptions.map((status, index) => {
									return <a key={"st_" + index} onClick={() => this.changeStatus(index)}>{status.label}</a>
								})
								}
							/>
							<UILIB.ButtonSelect className="mar-r5 mar-b5" selected={this.state.types.findIndex(t => t.value === this.state.campaignType)} headerText={this.state.types.find(t => t.value === this.state.campaignType).label} autoClose
								data={this.state.types.map((row, index) => {
									return <a key={"cs_" + index} onClick={() => this.changeType(row.value)}>{row.label}</a>
								})
								}
							/>
							{this.state.viewType != "calendar" && <UILIB.ButtonSelect className="mar-r5 mar-b5" selected={this.state.dateFilterTypes.findIndex(t => t.value === this.state.dateFilter)} headerText={this.state.dateFilterTypes.find(t => t.value === this.state.dateFilter).label} autoClose
								data={this.state.dateFilterTypes.map((row, index) => {
									return <a key={"cs2_" + index} onClick={() => { this.changeDateFilterType(row.value) }}>{row.label}</a>
								})
								}
							/>
							}
						</UILIB.Column>
					</UILIB.Row>
				</UILIB.Column>
			</UILIB.Row>

			{
				this.state.viewType == "list" && <div className="mar-b25">
					{(this.state.firstCampaignCount > 0) && <UILIB.DataTable1
						className="with-image"
						noResultsTxt={<span>
							{i18n.t('no') + " " +
								i18n.t('campaigns:status.' + this.state.statusOptions[this.state.statusOption].label) + " " +
								i18n.t('campaigns:main.campaignsFound')
							}
						</span>}
						tableData={this.state.campaigns}
						loadingData={this.state.loadingData}
						dataUpdater={this.tableUpdater}
						width="100%"
						pageSize="100"
						hasCheckBoxes="no"
						sortedColumn={this.state.sortColumn}
						sortedDirection={this.state.sortDirection}
					/>}
					{(this.state.firstCampaignCount < 1) && <UILIB.Paper>
						<UILIB.Row>
							<UILIB.Column xs={12} sm={6} md={4}>
								<img
									src="https://cdn1.ourmailsender.com/siteContent/noneholders/campaign-none.png"
									style={{ width: "100%" }} />
							</UILIB.Column>
							<UILIB.Column xs={12} sm={6} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
								<UILIB.Icons icon="envelopeOpen" circle={true} style={{ height: 40, width: 40 }} className="mar-b15" />
								<h4 className="mar-b15">{i18n.t('campaigns:main.noCampaignsHeader')}</h4>
								<div>{i18n.t('campaigns:main.noCampaignsDescription')}</div>

								{PermissionChecker('campaigns', this.props.permissionStore.permissions, "write") && <div className="mar-t25">
									<UILIB.Button
										className="button-primary"
										onClick={() => this.addNewCampaign()}
										text={i18n.t('campaigns:main.addCampaign')}
										iconLeft={<UILIB.Icons icon="plus" />}
									/>
								</div>
								}
							</UILIB.Column>
						</UILIB.Row>
					</UILIB.Paper>}
				</div>
			}

			{
				this.state.viewType == "list" && <UILIB.Row>
					<UILIB.Column xs={12} hide={["xs", "sm"]} className="center-xs">
						<UILIB.PagingBlock totalRows={this.state.totalCampaigns} pageSize={this.state.pageSize} numberOfLinks="10" currentPage={this.state.currentPage}
							changePage={this.tableUpdater} text={i18n.t('page') + ":"} />
					</UILIB.Column>
					{this.state.campaigns.length < this.state.totalCampaigns && <UILIB.Column xs={12} hide={["md", "lg"]} className="center-xs">
						<UILIB.Button className={"outline button-md primary " + (this.state.loadingmore ? 'loading' : '')} text={this.state.loadingmore ? 'loading' : "load more"} onClick={this.loadmore} />
					</UILIB.Column>}
				</UILIB.Row>
			}

			{
				this.state.viewType == "calendar" && <UILIB.Calendar
					events={this.state.calendarEvents}
					loading={this.state.loadingData}
					addEvent={canEdit && this.addNewCampaign}
					onChangeDate={this.calendarChangedDate}
					clickEvent={this.selectCalendarEvent}
				/>
			}



			<UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />

		</UILIB.Paper >
	}
}

function ColumnsButton({ columns, onChange, advanced, toggleAdvanced }) {
	const beta = useSelector(state => state.accountMaster.accountMaster.beta)
	const accountMaster = useSelector(state => state.accountMaster)
	const siteId = useSelector(state => state.siteMaster.siteId)
	const [data, setData] = useState(null)

	useEffect(() => {
		setData(JSON.parse(JSON.stringify(AvailableColumns)).map(o => {
			if (!advanced) o.disabled = o.value !== 'audience'
			else o.disabled = false

			if (o.fixed) o.disabled = false
			return o
		}).filter(o => {
			if (o.group === 'Social Campaigns' && !beta.social) return false
			if (o.group === 'Ad Campaigns' && !beta.ads) return false
			return true
		}).filter(o => {
			// check tier permissions
			if (o.mpz && siteId !== 1) return false
			if (!o.tierPermission) return true
			if (!accountMaster.tier?.permissions?.[o.tierPermission]?.allowed) return false
			return true
		}).map(o => {
			if (o.group) o.group = i18n.t('campaigns:main.columnGroups.' + o.group)
			o.label = i18n.t('campaigns:main.columns.' + o.label)
			return o
		}))
	}, [advanced, beta])

	if (!data) return null

	return (
		<UILIB.Select
			fixedHeader={<UILIB.Icons icon="table" color="#7C8086" style={{ height: 25 }} />}
			multiple={true}
			data={data}
			align="right"
			hideIconRight={true}
			fixedHeightLarge={true}
			value={columns}
			onChange={onChange}
			customHeader={<UILIB.Toggle after={i18n.t('campaigns:main.advanced')} value={advanced} onChange={toggleAdvanced} />}
		/>
	)
}

function Audience({ campaign }) {
	const parseName = (name) => {
		if (name.length > 20) return name.substring(0, 20) + '...'
		return name
	}


	let children = []
	if (campaign.type === 1 || campaign.type === 10) {
		// groups and segments from email and split tests
		const arr = campaign.Groups.concat(campaign.Segments.filter(s => !s.parentId)).sort((a, b) => Number((b.CampaignGroup || b.CampaignSegment).include) - Number((a.CampaignGroup || a.CampaignSegment).include))
		children = arr.map((g, i) => {
			let tooltip, tooltipFor
			if ((g.groupName || g.name).length > 20) {
				tooltip = (g.groupName || g.name)
				tooltipFor = campaign.id + '_' + g.id + "_expand"
			}
			if (!(g.CampaignGroup || g.CampaignSegment)?.include) {
				return <UILIB.SquareChip key={i} name={g.name || g.groupName} className="square-chip-grey" tooltip={tooltip} for={tooltipFor} tooltipDelayShow={250} iconLeft={<UILIB.Icons color="#7C8086" icon="exclude" style={{ height: 8, width: 8 }} />}>{parseName(g.groupName || g.name)}</UILIB.SquareChip>
			} else {
				return <UILIB.SquareChip key={i} name={g.name || g.groupName} className={g.CampaignGroup ? "square-chip-green" : "square-chip-purple"} tooltip={tooltip} for={tooltipFor} tooltipDelayShow={250}>{parseName(g.groupName || g.name)}</UILIB.SquareChip>
			}
		})
	}

	if (campaign.type === 30) {
		// social posts
		children = campaign.children.map(c => {
			const baseApp = SupportedApps.find(a => a.code === c.SocialAccount?.type)
			if (!baseApp) return null
			return <UILIB.SquareChip
				key={c.id}
				// leftEdgeContent={baseApp.chipIcon}
				iconLeft={baseApp.chipIcon}
				className="square-chip-grey"
				name={c.SocialAccount?.name}
			>
				{c.SocialAccount?.name}
			</UILIB.SquareChip>
		}).filter(c => !!c)
	}

	if (children.length > 6) {
		const more = children.slice(6)
		children = children.slice(0, 6)
		children.push(<UILIB.SquareChip key="more" tooltip={more.map(m => m.props.name).join(', ')} for={campaign.id + "_more"} className="square-chip-grey" >{more.length} more</UILIB.SquareChip>)
	}

	return <div className="quickFlex" style={{ gap: 8, flexWrap: 'wrap' }}>{children}</div>
}

function Performance({ campaign, settings }) {
	if (campaign.status === 'Draft') return null
	if (campaign.status === 'Awaiting Start') return null
	if (campaign.status === 'Processing') return null

	let total
	let value
	let colour = '#7246B1'
	let label

	if (campaign.type === 1 || campaign.type === 10 || campaign.type === 3) {
		// email campaign so show selected stat from the settings
		total = campaign.stats['Total Subscribers'] || 0
		if (settings.email === 'clicks') {
			label = i18n.t('campaigns:main.performance.clicks')
			value = campaign.stats['Unique Clicks'] || 0
		}
		if (settings.email === 'opens') {
			label = i18n.t('campaigns:main.performance.opens')
			value = campaign.stats['Unique Opens'] || 0
		}
	}

	if (campaign.type === 30) {
		// social posts
		value = 0
		for (const c of campaign.children) {
			if (settings.social === 'engagement') {
				if (!total) total = 0
				total += c.SocialPost?.impressions || 0
				value += c.SocialPost?.engagement || 0
			}
			if (settings.social === 'impressions') value += c.SocialPost?.impressions || 0
			if (settings.social === 'outreach') value += c.SocialPost?.outreach || 0
		}
		if (settings.social === 'engagement') label = i18n.t('campaigns:main.performance.engagement')
		if (settings.social === 'impressions') label = i18n.t('campaigns:main.performance.impressions')
		if (settings.social === 'outreach') label = i18n.t('campaigns:main.performance.outreach')

	}

	if (total !== undefined && value !== undefined) {
		return <UILIB.SmallLineGraph color={colour} width="100%" height="5" val1={value} val2={total} val1Label={label} />
	}

	if (total === undefined && value !== undefined) {
		return <UILIB.SmallLineGraph color={colour} width="100%" height="5" val1={value} val1Label={label} />
	}

	return null
}

function SocialStat({ campaign, stat }) {
	if (campaign.type !== 30) return null
	if (campaign.status !== 'Finished') return null
	let total = 0
	let value = 0
	for (const c of campaign.children) {
		total += c.SocialPost?.impressions || 0
		value += c.SocialPost?.[stat] || 0
	}

	if (stat === 'engagement') return <UILIB.SmallLineGraph color="#65B5B1" width="100" height="5" val1={value} val2={total} val1Label={"Engagement"} />

	return FormatNumberFuncs.formatNumber(value)
}

function CampaignFolders({ campaign, selected, folders, onChange = () => { }, manage = () => { } }) {
	const [show, setShow] = useState(false)
	const [saving, setSaving] = useState(false)

	const select = async e => {
		const id = parseInt(e.target.name)
		setSaving(true)
		const arr = selected.slice()
		const index = arr.findIndex(f => f.id === id)
		if (index > -1) {
			arr.splice(index, 1)
		} else {
			const folder = folders.find(f => f.id === id)
			arr.push(folder)
		}
		await axios.put('/campaign/' + campaign.id + '/folder', {
			folderId: arr.map(f => f.id)
		})
		setSaving(false)
		onChange(arr)
	}

	const onManage = () => {
		setShow(false)
		manage()
	}

	const add = <UILIB.Button className="button-xs button-lightGrey" onClick={() => setShow(!show)}>
		<UILIB.Icons icon="plus" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color="black" />
	</UILIB.Button>

	const children = selected.map(f => {
		return <UILIB.SquareChip key={f.id} name={f.name} className="square-chip-grey">{f.name}</UILIB.SquareChip>
	})

	const dropdown = show ? <UILIB.DropDown style={{ marginTop: 10, padding: 8, maxHeight: 250, overflowY: 'auto' }} onClose={() => setShow(false)}>
		<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
			{folders.map(f => {
				return <UILIB.CheckBox outerStyle={{ overflow: 'hidden' }} checked={selected.some(s => s.id === f.id)} name={f.id} disabled={saving} onChange={select}>{f.name}</UILIB.CheckBox>
			})}
			{!folders?.length && <UILIB.Button className="button-sml" style={{ width: '100%' }} onClick={onManage}>Manage Folders</UILIB.Button>}
		</div>
	</UILIB.DropDown> : null

	return <div className="quickFlex" style={{ gap: 8, flexWrap: 'wrap' }}>
		{children}
		{add}
		{dropdown}
	</div>
}

function mergeArrays(arr1, arr2, prop) {
	let newArray = arr1.slice()
	newArray.push(...arr2.filter(item => {
		if (prop) {
			if (arr1.some(i => i[prop] === item[prop])) return false
		} else {
			if (arr1.includes(item)) return false
		}
		newArray.push(item)
		return true
	}))
	// get the distinct items in the array based on the prop
	if (prop) {
		newArray = newArray.filter((item, index) => newArray.findIndex(i => i[prop] === item[prop]) === index)
	}
	return newArray
}
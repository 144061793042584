import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFormatFunctions from '~/Classes/numberFormatFunctions';

import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import SegmentEditor from '~/pages/cp/includes/segmentEditor/segmentEditor'

import CampaignPieChart from '../../shared/graphs/pie';
import i18n from '~/i18n'

import copy from 'copy-to-clipboard';

import Map from '../../shared/graphs/map'

import Progress from '../../shared/components/progress';
import GenericStatsHolder from '../../shared/components/genericStatsHolder';

import CampaignDetails from '../../shared/components/campaignDetails';
import CampaignGraph from '../../shared/components/campaignGraph';
import FormatNumberFuncs from '~/Classes/numberFormatFunctions';

//CONNECT TO STORE
@connect((store) => {
	return { siteMaster: store.siteMaster, user: store.user, accountMaster: store.accountMaster }
})

export default class CampaignsView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			timer: null,
			campaignId: null,
			pageLoaded: false,
			campaign: {},
			groups: [],
			stats: {
			},
			mailServers: [],
			groupOptions: [{
				value: 'HOUR',
				label: i18n.t('campaigns:overview.groupHour'),
				color: 'purple'
			}, {
				value: 'DAYOFWEEK',
				label: i18n.t('campaigns:overview.groupWeekday'),
				color: 'red'
			}, {
				value: 'WEEK',
				label: i18n.t('campaigns:overview.groupWeek'),
				color: 'green'
			}, {
				value: 'MONTHNAME',
				label: i18n.t('campaigns:overview.groupMonth'),
				color: 'blue'
			}, {
				value: 'YEAR',
				label: i18n.t('campaigns:overview.groupYear'),
				color: 'orange'
			}],
			currentGroupBy: 1,
			statOptions: [{
				label: i18n.t('campaigns:overview.statSends'),
				value: 'sends',
				link: 'sent'
			}, {
				label: i18n.t('campaigns:overview.statOpens'),
				value: 'opens',
				link: 'opened'
			}, {
				label: i18n.t('campaigns:overview.statClicks'),
				value: 'clicks',
				link: 'clicked'
			}, {
				label: i18n.t('campaigns:overview.statUnsubscribes'),
				value: 'unsubscribes',
				link: 'unsubscribed'
			}],
			currentStat: 1,
			geos: {
				type: "FeatureCollection", features: []
			},
			mapDataLoaded: false,
			customFieldsLoaded: false,
			customFields: []
		};

		this.startGetStats = this.startGetStats.bind(this);
		this.getStats = this.getStats.bind(this);
		this.loadGroups = this.loadGroups.bind(this);
		this.changeGroupType = this.changeGroupType.bind(this);
		this.changeStatType = this.changeStatType.bind(this);
		this.previewLinksClick = this.previewLinksClick.bind(this);
		this.shareClicked = this.shareClicked.bind(this);
		this.loadServers = this.loadServers.bind(this);
		this.loadCustomFields = this.loadCustomFields.bind(this);
		this.viewGroupOrSegment = this.viewGroupOrSegment.bind(this)
		this.disableNotifications = this.disableNotifications.bind(this)
	}

	componentDidMount() {
		this._ismounted = true;
		var campaign = this.props.campaign
		if (campaign.type === 10 && !campaign.parentCampaignId) {
			if (!campaign.Campaigns.find(c => c.campaignType === 11)) {
				this.props.history.push('/cp/campaigns/view/' + campaign.id + '/tests')
			}
		}
		this.setState({
			campaign: this.props.campaign,
			campaignId: this.props.match.params.campaignId
		}, this.loadServers)
	}

	componentDidUpdate() {
		if (this.props.campaign.updatedAt != this.state.campaign.updatedAt) {
			this.setState({ campaign: this.props.campaign })
		}
	}

	componentWillUnmount() {
		clearInterval(this.state.timer);
		this._ismounted = false;
	}

	loadServers() {
		if (this.state.campaign && this.state.campaign.options && this.state.campaign.options.MailServers && this.state.campaign.options.MailServers.length) {
			axios.get('/accountMaster/mailservers?dedicated=true').then(_result => {
				var mailServers = [];
				if (_result && _result.data && _result.data.MailServers && _result.data.MailServers.length) {
					mailServers = _result.data.MailServers.map(ms => {
						return {
							id: ms.id,
							name: ms.name,
							notes: ms.notes
						}
					})
				}
				this.setState({ mailServers }, this.loadGroups)
			})
		}
		else {
			this.loadGroups()
		}
	}
	loadGroups() {
		axios.get('/group/simple')
			.then(response => {
				this.setState({
					groups: response.data.Groups,
				}, this.loadCustomFields)
			})
			.catch(console.log)
	}
	loadCustomFields() {
		if (this.props.campaign.multiAddressRules && this.props.campaign.multiAddressRules.length) {
			axios.get('/customField')
				.then(response => {
					this.setState({
						customFields: response.data.CustomFields,
						customFieldsLoaded: true
					}, this.startGetStats)
				})
				.catch(console.log)
		}
		else {
			this.startGetStats()
		}
	}
	startGetStats() {
		this.getStats()
		// this.setTimeout(this.getStats, 5000)
		var timer = setInterval(this.getStats, 5000);
		this.setState({ timer, pageLoaded: true })
	}

	getStats() {
		var self = this;
		axios.get('/campaign/' + self.state.campaignId + '/stats?ignoreLog=1&startDate=' + this.props.startDate.format('YYYY-MM-DD') + '&endDate=' + this.props.endDate.format('YYYY-MM-DD'))
			.then((res) => {
				if (this.setState) {
					var stats = res.data.Stats
					if (this.props.isAutomation == true) {
						if (!stats['Total Subscribers']) stats['Total Subscribers'] = stats['Total Sent']

					}
					this.setState({
						mapDataLoaded: true,
						stats: res.data.Stats,
						geos: {
							type: "FeatureCollection",
							features: res.data.Maps.map(row => {
								return {
									type: "Feature",
									properties: {},
									geometry: {
										type: "Point",
										coordinates: [row.longitude, row.latitude]
									}
								}
							})
						}
					});
				} else {
					clearTimeout(this.state.timer);
				}
			})
			.catch(ex => {
				console.log(ex)
			})
	}

	changeGroupType(theIndex) {

		this.setState({ currentGroupBy: theIndex })
	}

	changeStatType(theIndex) {
		this.setState({ currentStat: theIndex });
	}

	previewLinksClick() {
		var self = this;
		document.getElementById("theIframe").contentWindow.document.body.onclick = function (event) {
			event.preventDefault();
		}
	}


	shareClicked(theVal) {

		copy(theVal);
		this.setState({ alertOpen: true, alertMessage: i18n.t('campaigns:FinishedAddingCampaignScreen.linkCopiedToClipBoard') })
	}

	viewGroupOrSegment(row) {
		if (row.type === 'Segment') {
			// show drawer in readonly mode
			var drawerContent = <SegmentEditor history={this.props.history} readonly={true} segmentId={row.id} close={() => this.props.dispatch(siteMaster.alterSiteDrawer(false))} />
			this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "100%"));
		} else {
			// go to group
			this.props.history.push('/cp/groups/' + row.id)
		}
	}

	disableNotifications() {
		axios.put('/campaign/' + this.state.campaign.id, {
			campaign: { sendUpdateEmails: false }
		}).then(this.props.reload)
	}

	render() {

		if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />

		var campaign = this.state.campaign
		if (campaign.Campaigns) {
			campaign = campaign.Campaigns.find(c => c.type === 11) || this.state.campaign
		}
		var pctSent = 0;
		if (this.state.stats['Total Sent'] && this.state.stats["Total Subscribers"] && this.state.stats['Total Sent'] > 0 && this.state.stats["Total Subscribers"] > 0) {
			const sent = campaign.status === 'Finished' ? this.state.stats["Total Subscribers"] : this.state.stats['Total Sent']
			pctSent = FormatNumberFuncs.formatNumber((sent / this.state.stats["Total Subscribers"]) * 100, 2)
		}
		var totalDelivered = this.state.stats['Total Subscribers'] - (this.state.stats['Hard Bounces'] + this.state.stats['Soft Bounces'] + this.state.stats['Total Dropped']);
		var statsTotalSubs = this.state.stats['Total Subscribers']
		let resent = ''
		if (campaign.options && campaign.options.resent) {
			resent = <span style={{ fontSize: "12px", marginLeft: 10, fontWeight: 'bold' }}>Resent {NumberFormatFunctions.formatNumber(campaign.options.resentCount)} emails on {DateTimeFunctions.formatDateTime(campaign.options.resentDate)}</span>
		}

		let canViewContacts = this.props.canViewContacts;
		return <div>


			<Progress stats={this.state.stats} campaign={campaign} pctSent={pctSent} extra={resent} />
			<UILIB.Row>
				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
					<GenericStatsHolder
						value={this.state.stats["Unique Opens"]}
						limit={this.props.siteMaster.hideSubDetail ? totalDelivered : statsTotalSubs}
						color={this.props.siteMaster.colours['$status-green']}
						header={i18n.t('campaigns:overview.subscribersOpened')}
						link={() => {
							if (canViewContacts) {
								this.props.history.push('/cp/campaigns/view/' + this.state.campaignId + '/opened')
							}
						}
						}
						linkText={canViewContacts ? i18n.t('campaigns:overview.view') : ""}
						linkIcon="arrowRight"
					/>
				</UILIB.Column>

				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
					<GenericStatsHolder
						value={this.state.stats["Unique Clicks"]}
						limit={this.props.siteMaster.hideSubDetail ? totalDelivered : this.state.stats["Unique Opens"]}
						color={this.props.siteMaster.colours['$status-blue']}
						header={i18n.t('campaigns:overview.subscribersClicked')}
						link={() => {
							if (canViewContacts) {
								this.props.history.push('/cp/campaigns/view/' + this.state.campaignId + '/clicked')
							}
						}
						}
						linkText={canViewContacts ? i18n.t('campaigns:overview.view') : ""}
						linkIcon="arrowRight"
					/>
				</UILIB.Column>

				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
					<GenericStatsHolder
						value={this.state.stats["Total Unsubscribes"]}
						limit={this.props.siteMaster.hideSubDetail ? totalDelivered : statsTotalSubs}
						color={this.props.siteMaster.colours['$status-orange']}
						header={i18n.t('campaigns:overview.statUnsubscribes')}
						link={() => {
							if (canViewContacts) {
								this.props.history.push('/cp/campaigns/view/' + this.state.campaignId + '/unsubscribed')
							}
						}
						}
						linkText={canViewContacts ? i18n.t('campaigns:overview.view') : ""}
						linkIcon="arrowRight"
					/>
				</UILIB.Column>

				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
					<GenericStatsHolder
						value={this.state.stats["Hard Bounces"]}
						limit={statsTotalSubs}
						color={this.props.siteMaster.colours['$status-red']}
						header={i18n.t('campaigns:overview.statHardBounces')}
						link={() => {
							if (canViewContacts) {
								this.props.history.push('/cp/campaigns/view/' + this.state.campaignId + '/bounced')
							}
						}
						}
						linkText={canViewContacts ? i18n.t('campaigns:overview.view') : ""}
						linkIcon="arrowRight"
					/>
				</UILIB.Column>
			</UILIB.Row>

			<UILIB.Row className={"soft-show-height " + (this.props.siteMaster.hideSubDetail ? '' : 'hide')}>
				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
					<GenericStatsHolder
						value={totalDelivered}
						limit={statsTotalSubs}
						header={i18n.t('campaigns:overview.delivered')}
						link={null}
						linkText={""}
						linkIcon=""
					/>
				</UILIB.Column>

				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0}>
					<GenericStatsHolder
						value={this.state.stats["Total Dropped"]}
						limit={statsTotalSubs}
						header={i18n.t('campaigns:overview.statNotSent')}
						link={null}
						linkText={""}
						linkIcon=""
					/>
				</UILIB.Column>

				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0} >
					<GenericStatsHolder
						value={this.state.stats["Total Clicks"]}
						limit={this.props.siteMaster.hideSubDetail ? totalDelivered : statsTotalSubs}
						header={i18n.t('campaigns:overview.totalClicks')}
						link={null}
						linkText={""}
						linkIcon=""
					/>

				</UILIB.Column>

				<UILIB.Column xs={12} sm={12} md={6} lg={3} margin={0} className="mar-b25">
					<GenericStatsHolder
						value={this.state.stats["Soft Bounces"]}
						limit={statsTotalSubs}
						header={i18n.t('campaigns:overview.statSoftBounces')}
						link={null}
						linkText={""}
						linkIcon=""
					/>

				</UILIB.Column>
			</UILIB.Row>


			<UILIB.Row>
				<UILIB.Column xs={12} sm={12} md={12} lg={6} xl={6} margin={0}>
					<CampaignDetails
						campaign={campaign}
						disableNotifications={this.disableNotifications}
						history={this.props.history}
						viewGroupOrSegment={this.viewGroupOrSegment}
						mailServers={this.state.mailServers}
						customFields={this.state.customFields}
						shareClicked={this.shareClicked}
						junkReports={this.state.stats['Unique Junk Reports']}
					/>
				</UILIB.Column>																
				<UILIB.Column xs={12} sm={12} md={12} lg={6} xl={6} margin={0}>
					<CampaignGraph
						startDate={this.props.startDate}
						endDate={this.props.endDate}
						campaignId={this.state.campaignId}
						onNavigate={(path) => { this.props.history.push('/cp/campaigns/view/' + this.state.campaignId + '/' + path) }}
						groupByData={this.state.groupOptions[this.state.currentGroupBy]}
						statOptions={this.state.statOptions[this.state.currentStat]}
						autoHeight={true}
					/>
				</UILIB.Column>
			</UILIB.Row>

			<UILIB.Row>
				<UILIB.Column xs={12} sm={12} md={6} lg={3}>
					<UILIB.Paper >
						<CampaignPieChart style={{ height: "500px" }} startDate={this.props.startDate} endDate={this.props.endDate} campaignId={this.state.campaignId} stat="country" groupByData={this.state.groupOptions[this.state.currentGroupBy]} unique={"true"}></CampaignPieChart>
					</UILIB.Paper>
				</UILIB.Column>
				<UILIB.Column xs={12} sm={12} md={6} lg={3}>
					<UILIB.Paper >
						<CampaignPieChart style={{ height: "500px" }} startDate={this.props.startDate} endDate={this.props.endDate} campaignId={this.state.campaignId} stat="device" groupByData={this.state.groupOptions[this.state.currentGroupBy]} unique={"true"}></CampaignPieChart>
					</UILIB.Paper>
				</UILIB.Column>
				<UILIB.Column xs={12} sm={12} md={12} lg={6}>
					<UILIB.Paper style={{ display: "flex", alignItems: "center" }}>

						{!this.state.mapDataLoaded && <UILIB.LoadingIcons iconType="2" />}
						{this.state.mapDataLoaded && <Map data={this.state.geos} />}
					</UILIB.Paper>
				</UILIB.Column>
			</UILIB.Row>


			<UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
		</div >
	}
}
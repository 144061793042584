import React from 'react'
import DBConnection from '~/Classes/siteVars';
import axios from 'axios';
import queryString from 'query-string';
import { connect } from 'react-redux';

//CONNECT TO STORE
@connect((store) => {
    return { siteMaster: store.siteMaster }
})

export default class UnsubscribeTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: '',
            options: {},
            error: '',
            loaded: false,
            accountMasterId: undefined,
            formId: undefined
        };
        this.getForm = this.getForm.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.hideErrorHolder = this.hideErrorHolder.bind(this)

    }

    componentDidMount() {
        this.getForm()
        this.hideErrorHolder(true)
    }


    hideErrorHolder(showHide) {
        if (this.refs.myForm) {
            var errorHolders = document.getElementsByClassName("mpmessageholder");
            if (errorHolders && errorHolders.style && errorHolders.style.display) {
                if (showHide) {
                    errorHolders.style.display = "block";
                }
                else {
                    errorHolders.style.display = "none";
                }
            }
        }
    }

    getForm() {
        const query = queryString.parse(this.props.location.search);
        var formId = query.fid;
        var accountMasterId = query.am;

        var baseUrl = DBConnection.getConnString()

        axios.get(baseUrl + '/form?plain=true&am=' + accountMasterId + '&fid=' + formId)
            .then(response => {
                if (response.data.type < 4) {
                    var child = document.getElementById("mpMainStyle");
                    child.parentNode.removeChild(child);
                }

                var theHTML = response.data.html;
                theHTML = theHTML.replace(/Any error messages will go here when subscribers join/g, '');
                this.hideErrorHolder(false);
                this.setState({
                    form: theHTML,
                    options: response.data.options,
                    accountMasterId,
                    formId,
                    loaded: true
                })
            })
            .catch(response => {
                console.log(response)
            })
    }


    handleSubmit(ev) {

        ev.preventDefault()
        var form = ev.target;
        var obj = {};
        for (var i = 0; i < form.elements.length; i++) {
            var e = form.elements[i];
            if (e.name) {
                obj[e.name] = e.type === 'checkbox' ? e.checked : e.value;
            }
        }

        this.hideErrorHolder(true)

        var baseUrl = DBConnection.getConnString()

        axios.post(baseUrl + '/form?am=' + this.state.accountMasterId + '&fid=' + this.state.formId, obj).then(response => {
            if (response.data.redirect) {
                window.location.href = response.data.redirect;
            } else if (response.data.message) {
                var errHolderNode = document.getElementById("mpmessage");
                if (!errHolderNode) {
                    errHolderNode = form
                }
                errHolderNode.innerHTML = response.data.message;
            } else if (response.data.replace) {
                this.setState({
                    form: response.data.form
                })
            }
        }).catch(err => {

            var finalErr = 'There was an error signing up to this list';
            if (err) {
                if (err.message) finalErr = err.message;
                if (err.response) {
                    if (err.response.data.error) finalErr = err.response.data.error;
                }
            }
            this.setState({
                error: finalErr
            })
            var errHolderNode = document.getElementById("mpmessage");
            if (errHolderNode) {
                errHolderNode.innerHTML = finalErr
            }
        })
    }

    render() {
        if (this.state.loaded) {
            return <div>
                <form onSubmit={this.handleSubmit} id={"mpform-" + this.state.formId} >
                    <div dangerouslySetInnerHTML={{ __html: this.state.form }} ref="myForm" />
                </form>
            </div>
        } else {
            return (null)
        }


    }
}

import React from 'react'

export default function IconsText({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 7.14286C17 7.37019 16.8946 7.5882 16.7071 7.74895C16.5196 7.90969 16.2652 8 16 8C15.7348 8 15.4804 7.90969 15.2929 7.74895C15.1054 7.5882 15 7.37019 15 7.14286V6.00006H11L11 14.0001H12C12.2652 14.0001 12.5196 14.1054 12.7071 14.293C12.8946 14.4805 13 14.7348 13 15.0001C13 15.2653 12.8946 15.5196 12.7071 15.7072C12.5196 15.8947 12.2652 16.0001 12 16.0001H8C7.73478 16.0001 7.48043 15.8947 7.29289 15.7072C7.10536 15.5196 7 15.2653 7 15.0001C7 14.7348 7.10536 14.4805 7.29289 14.293C7.48043 14.1054 7.73478 14.0001 8 14.0001H9L9 6.00006H5V7.14286C5 7.37019 4.89464 7.5882 4.70711 7.74895C4.51957 7.90969 4.26522 8 4 8C3.73478 8 3.48043 7.90969 3.29289 7.74895C3.10536 7.5882 3 7.37019 3 7.14286V4.85714C3 4.62981 3.10536 4.4118 3.29289 4.25105C3.48043 4.09031 3.73478 4 4 4L4.01194 4.00006H9.98806L10 4L10.0119 4.00006H15.9881C15.992 4.00002 15.996 4 16 4C16.2652 4 16.5196 4.09031 16.7071 4.25105C16.8946 4.4118 17 4.62981 17 4.85714V7.14286Z" fill={fillColor} />
        </svg>
    )
}
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import DBConnection from '~/Classes/siteVars';
import axios from 'axios';
import queryString from 'query-string';
import MPZBlackLogo from '~/assets/images/mpzLogos/mpzmail-logo-black.svg';
import { connect } from 'react-redux';
import { I18n } from 'react-i18next';

//CONNECT TO STORE
@connect((store) => {
    return { siteMaster: store.siteMaster }
})

export default class UnsubscribeTemplate extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = { groups: [], masterErrorMsg: '', isReady: false, hasUnsubscribed: false, hideGroups: false };
        this.unsubscribe = this.unsubscribe.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.getGroups()
    }

    getGroups() {
        var baseUrl = DBConnection.getConnString()
        const query = queryString.parse(this.props.location.search);
        var key = query.key;
        axios.get(baseUrl + '/groups?key=' + key)
            .then(response => {
                this.setState({
                    hideGroups: response.data.hideGroups,
                    groups: response.data.Groups,
                    isReady: true,
                    key
                })
            })
            .catch(response => {
                console.log(response)
                this.setState({

                    masterErrorMsg: response.data.error,
                    isReady: true
                })
            })
    }

    toggle(index, event) {
        var groups = this.state.groups;
        groups[index].unsubscribe = event.target.checked;
        this.setState({
            groups
        })
    }

    unsubscribe(blacklist) {
        const query = queryString.parse(this.props.location.search);
        var key = query.key;
        var groups = this.state.groups.filter(g => g.unsubscribe).map(group => group.id)
        if (this.state.groups.length < 2) groups = this.state.groups.map(group => group.id)


        var doBlacklist = 0;
        if (blacklist) doBlacklist = 1;
        //if theyve selected ALL groups, blacklist them at this point too
        if (groups.length == this.state.groups.length) doBlacklist = 1;


        var baseUrl = DBConnection.getConnString()
        axios.get(baseUrl + '/unsubscribe?key=' + key + '&groups=' + JSON.stringify(groups) + '&blacklist=' + doBlacklist)
            .then(response => {
                if (response.data.redirect) {
                    window.location.href = response.data.redirect
                } else {
                    this.setState({
                        masterErrorMsg: <I18n ns="translations">{(t, { i18n }) => (<div>{t('public:unsub.successfullyUnsubscribed')}</div>)}</I18n>,
                        hasUnsubscribed: true
                    })
                }
            })
            .catch(err => {

            })
    }

    render() {

        if (!this.state.isReady) return <div></div>;

        if (!this.state.groups.length) return (<I18n ns="translations">
            {
                (t, { i18n }) => (<div>
                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={8} md={6} className="col-sm-offset-2 col-md-offset-3" style={{ width: "100%" }} margin={0}>
                            <UILIB.Paper className="mar-t30">
                                <h4 className="mar-b25">You are unsubscribed</h4>
                                <div>You have been removed from all of our mailing lists</div>
                            </UILIB.Paper>
                        </UILIB.Column>
                    </UILIB.Row>
                </div>)
            }
        </I18n>)

        if (this.state.hasUnsubscribed) {
            return (<I18n ns="translations">
                {
                    (t, { i18n }) => (<div>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={8} md={6} className="col-sm-offset-2 col-md-offset-3" style={{ width: "100%" }} margin={0}>
                                <UILIB.Paper className="mar-t30">
                                    <h4 className="mar-b25">You have Unsubscribed</h4>
                                    <div>If you requested to unsubscribe from any or all groups, this has now been done.</div>
                                </UILIB.Paper>
                            </UILIB.Column>
                        </UILIB.Row>
                    </div>)
                }
            </I18n>)
        }

        return (<I18n ns="translations">
            {
                (t, { i18n }) => (<div>
                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={8} md={6} className="col-sm-offset-2 col-md-offset-3" style={{ width: "100%" }} margin={0}>
                            <UILIB.Paper className="mar-t30">

                                {this.state.groups.length < 2 && !this.state.hideGroups && <UILIB.Row>

                                    <UILIB.Column xs={12} className="center-xs">
                                        {t('public:unsub.singleGroup')} <strong>{this.state.groups[0].friendlyName || this.state.groups[0].groupName}</strong>
                                    </UILIB.Column>
                                    <UILIB.Column xs={12} className="center-xs">
                                        {this.state.groups[0].signupReminderText}
                                    </UILIB.Column>
                                </UILIB.Row>}

                                {this.state.groups.length >= 2 && !this.state.hideGroups && <UILIB.Row>
                                    <UILIB.Column xs={12} className="center-xs">{t('public:unsub.multipleGroups')}:</UILIB.Column>
                                    <UILIB.Column xs={12} >
                                        {this.state.groups.map((group, index) => {
                                            return <p key={group.id}>
                                                <UILIB.CheckBox key={group.id} name={group.friendlyName || group.groupName} checked={group.unsubscribe} onChange={(event) => { this.toggle(index, event) }}>{group.groupName}
                                                </UILIB.CheckBox>
                                            </p>
                                        })}
                                    </UILIB.Column>
                                </UILIB.Row>
                                }
                                <UILIB.Row>
                                    {!this.state.hideGroups && <UILIB.Column xs={12} margin={0} className="center-xs">

                                        <p><UILIB.Button className="button-md" onClick={() => { this.unsubscribe(false) }}>{t('public:unsub.unsubscribe')}</UILIB.Button></p>
                                        <p className="pad-left">or <a onClick={() => { this.unsubscribe(true) }}>{t('public:unsub.unsubscribeAll')}</a></p>


                                        <p>{this.state.masterErrorMsg}</p>
                                    </UILIB.Column>}
                                    {this.state.hideGroups && <UILIB.Column xs={12} margin={0} className="center-xs">
                                        <p><UILIB.Button className="button-md" onClick={() => { this.unsubscribe(true) }}>{t('public:unsub.unsubscribeAll')}</UILIB.Button></p>
                                    </UILIB.Column>}
                                </UILIB.Row>

                            </UILIB.Paper>
                        </UILIB.Column>
                    </UILIB.Row >
                    {this.props.siteMaster.siteLogo && this.props.siteMaster.siteLogo.length > 0 &&
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={8} md={6} className="col-sm-offset-2 col-md-offset-3 text-center" style={{ width: "100%" }} margin={0}>
                                <div style={{ fontSize: "14px", color: "#AAAAAA", marginBottom: "10px" }}>{t('public:unsub.poweredBy')}</div>
                                {this.props.siteMaster.siteId === 1 &&
                                    <div><a href="/"><img src={MPZBlackLogo} style={{ maxWidth: "100px", border: "0" }} /></a></div>
                                }
                                {!this.props.siteMaster.siteId === 1 &&
                                    <div><a href="/"><img src={this.props.siteMaster.siteLogo} style={{ maxWidth: "100px", border: "0" }} /></a></div>
                                }
                                <div style={{ fontSize: "12px", color: "#AAAAAA" }}>{t('public:unsub.marketing')}</div>

                            </UILIB.Column>
                        </UILIB.Row>
                    }
                </div>)
            }
        </I18n>)



    }
}

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})
export default class IncRenameCustomField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldDesc: this.props.field.fieldDesc
        };

        this.closeDrawer = this.closeDrawer.bind(this);
        this.goRenameField = this.goRenameField.bind(this);
        this.updateField = this.updateField.bind(this);

    }

    goRenameField() {
        self = this
        axios.put('/customfield/' + this.props.field.id, { fieldDesc: this.state.fieldDesc })
            .then((res) => {
                if (self.props.alert && typeof self.props.alert === 'function') {
                    self.props.alert('Field Renamed')
                }
                self.props.getCustomFields();
                self.closeDrawer();
            })
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    updateField(ev) {
        this.setState({ fieldDesc: ev.target.value });
    }

    render() {
        return <div>

            <h4 className="mar-b25">{i18n.t('subscribers:customfields.renameHeader')}</h4>
            <div className="mar-b15">{i18n.t('subscribers:customfields.renameSubHeader1')}</div>
            <div className="mar-b25">{i18n.t('subscribers:customfields.renameSubHeader2')}</div>

            <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('subscribers:customfields.renameFieldTitle')} type="text" name="fieldDesc" value={this.state.fieldDesc} onChange={this.updateField} />

            <UILIB.Button
                text={i18n.t('subscribers:customfields.renameButton')}
                className="button-primary"
                iconLeft={<UILIB.Icons icon="tick" />}
                onClick={this.goRenameField} />

        </div>

    }
};


import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import Button from "../../components/button";
import * as styles from "./index.module.scss";
import DeleteAiAssistantDialog from "../../components/deleteAiAssistantDialog";
import RenameAiAssistantDialog from "../../components/renameAiAssistantDialog";
import Header from "../../components/header";
import i18n from "~/i18n";

export default function InboxAiAssistants({ inbox }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [isRenameAssistantDialogOpen, setIsRenameAssistantDialogOpen] =
    useState(false);
  const [isDeleteAssistantDialogOpen, setIsDeleteAssistantDialogOpen] =
    useState(false);
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    loadInitialData();
  }, []);

  async function loadInitialData() {
    try {
      await loadAssistants();
      await loadChannels();
    } finally {
      setLoading(false);
    }
  }

  async function loadAssistants() {
    try {
      const { data } = await axios.get(`/inboxes/${inbox.id}/ai`);
      setAssistants(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function loadChannels() {
    try {
      const { data } = await axios.get(`/inboxes/${inbox.id}/channel`);
      setChannels(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }


  async function updateAssistant(assistant, payload) {
    try {
      await axios.put(
        `/inboxes/${inbox.id}/ai/${assistant.id}`,
        payload
      );

      loadInitialData();
      dispatch(alterSnackbar(true, i18n.t("chat:settings.ai.updated")));
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  const toggleAiStatus = async function (assistant) {
    try {
      const { data } = await axios.put(
        `/inboxes/${inbox.id}/ai/${assistant.id}/togglestatus`
      );
      loadInitialData();
      dispatch(alterSnackbar(true, i18n.t("chat:settings.ai.updated")));
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function deleteAssistant(assistantToDelete) {
    try {
      await axios.delete(`/inboxes/${inbox.id}/ai/${assistantToDelete.id}`);
      loadInitialData();
      setIsDeleteAssistantDialogOpen(false);
      dispatch(alterSnackbar(true, i18n.t("chat:settings.ai.deleted")));
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  async function renameAssistant(updatedAssistant, newName) {
    try {
      await updateAssistant(updatedAssistant, { name: newName });
      setIsRenameAssistantDialogOpen(false);
    } catch (error) {
      throw error;
    }
  }


  async function addAssistant() {
    history.push(`/cp/conversations/${inbox.id}/ai-assistants/setup`);
  }

  if (loading) return <UILIB.LoadingIcons noClass />;

  return (
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
      <Header type="inbox" option="ai-assistants" />
      {channels.length < 1 && <UILIB.Paper className="paper-dotted" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <UILIB.SquareChip className="square-chip-large square-chip-equalPadding mar-b15">
          <UILIB.Icons icon="sparkle" />
        </UILIB.SquareChip>
        <h5 className="mar-b10">{i18n.t("chat:settings.ai.noChannels")}</h5>
        <div className="mar-b25">{i18n.t("chat:settings.ai.noChannelsDesc")}</div>
        <UILIB.Button text={i18n.t("chat:settings.ai.noChannelButton")} className="button-primary" iconLeft={<UILIB.Icons icon="plus" />} onClick={() => history.push(`/cp/conversations/${inbox.id}/channels`)} />
      </UILIB.Paper>}

      {(channels.length > 0 && !assistants.length) && <UILIB.Paper className="paper-dotted" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <UILIB.SquareChip className="square-chip-large square-chip-equalPadding mar-b15">
          <UILIB.Icons icon="sparkle" />
        </UILIB.SquareChip>
        <h5 className="mar-b10">{i18n.t("chat:settings.ai.none")}</h5>
        <div className="mar-b25">{i18n.t("chat:settings.ai.noneDesc")}</div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}>
          <UILIB.Button text={i18n.t("chat:settings.ai.buttonCreate")} className="button-primary" iconLeft={<UILIB.Icons icon="plus" />} onClick={addAssistant} />
          <UILIB.Button text={i18n.t("chat:settings.ai.buttonLearn")} iconRight={<UILIB.Icons icon="external" />} disabled={true} />
        </div>
      </UILIB.Paper>}
      {(channels.length > 1 && assistants.length > 0) && <>
        <ul className={classnames(styles.assistantsList, "faux-list")}>
          {assistants.map((assistant) => {
            const { id, name, status, eligableToStart } = assistant;

            const enabled = status === 3;
            return (
              <li key={id}>
                <div className={styles.teaser}>
                  <div>
                    <Link
                      className={styles.teaserLink}
                      to={`/cp/conversations/${inbox.id}/ai-assistants/${id}`}
                    >
                      <h2 className={styles.teaserName}>{name}</h2>
                    </Link>
                  </div>

                  <Badge className={styles.teaserBadge} status={status} />

                  <UILIB.DropdownMenu.Root>
                    <UILIB.DropdownMenu.Trigger asChild>
                      <Button
                        aria-label="Additional options"
                        variant="ghost"
                        size="s"
                        iconOnly
                      >
                        <UILIB.Icon name="dots-vertical" />
                      </Button>
                    </UILIB.DropdownMenu.Trigger>

                    <UILIB.DropdownMenu.Portal>
                      <UILIB.DropdownMenu.Content align="end">
                        {eligableToStart && <UILIB.DropdownMenu.Item
                          onSelect={() => {
                            toggleAiStatus(assistant, { enabled: enabled ? false : true });
                          }}
                        >
                          {enabled ? i18n.t("chat:ai.statusDropDown.deActivate") : i18n.t("chat:ai.statusDropDown.activate")}
                        </UILIB.DropdownMenu.Item>
                        }

                        <UILIB.DropdownMenu.Item
                          onSelect={() => {
                            // Issue with autofocus when opening dialog from a dropdown https://github.com/radix-ui/primitives/issues/1615
                            setSelectedAssistant(assistant);
                            setIsRenameAssistantDialogOpen(true);
                          }}
                        >
                          {i18n.t("chat:settings.ai.rename")}
                        </UILIB.DropdownMenu.Item>

                        <UILIB.DropdownMenu.Separator />

                        <UILIB.DropdownMenu.Item
                          variant="danger"
                          onSelect={() => {
                            // Issue with autofocus when opening dialog from a dropdown https://github.com/radix-ui/primitives/issues/1615
                            setSelectedAssistant(assistant);
                            setIsDeleteAssistantDialogOpen(true);
                          }}
                        >
                          {i18n.t("chat:settings.ai.delete")}
                        </UILIB.DropdownMenu.Item>
                      </UILIB.DropdownMenu.Content>
                    </UILIB.DropdownMenu.Portal>
                  </UILIB.DropdownMenu.Root>
                </div>
              </li>
            );
          })}
        </ul >
        <Button
          className={styles.addButton}
          size="s"
          iconLeft={<UILIB.Icon name="plus" />}
          onClick={addAssistant}
        >
          {i18n.t("chat:settings.ai.create")}
        </Button>
      </>
      }


      <RenameAiAssistantDialog
        key={`rename-${selectedAssistant?.id}`} // For resetting dialog state when closed/opened
        assistant={selectedAssistant}
        isOpen={isRenameAssistantDialogOpen}
        onSubmit={renameAssistant}
        onOpenChange={(isOpen) => setIsRenameAssistantDialogOpen(isOpen)}
      />

      <DeleteAiAssistantDialog
        key={`delete-${selectedAssistant?.id}`} // For resetting dialog state when closed/opened
        assistant={selectedAssistant}
        isOpen={isDeleteAssistantDialogOpen}
        onSubmit={deleteAssistant}
        onOpenChange={(isOpen) => setIsDeleteAssistantDialogOpen(isOpen)}
      />
    </div>
  );
}


function Badge({ status, ...rest }) {
  let color;
  let label;

  switch (status) {
    case 0:
      label = i18n.t("chat:settings.ai.status.new");
      color = "grey";
      break;
    case 1:
      label = i18n.t("chat:settings.ai.status.building");
      color = "orange";
      break;
    case 2:
      label = i18n.t("chat:settings.ai.status.inactive");
      color = "grey";
      break;
    case 3:
      label = i18n.t("chat:settings.ai.status.active");
      color = "teal";
      break;
  }

  return (
    <UILIB.Badge color={color} {...rest}>
      {label}
    </UILIB.Badge>
  );
}

import React from 'react'

export default function IconsCapsule({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1815 0.677765C14.7334 0.677765 14.3687 1.04614 14.3687 1.49872C14.3687 1.95131 14.7334 2.31969 15.1815 2.31969C15.6296 2.31969 15.9944 1.95131 15.9944 1.49872C15.9944 1.04614 15.6296 0.677765 15.1815 0.677765Z" fill={fillColor} />
            <path d="M15.1815 4.46153C14.7334 4.46153 14.3687 4.82991 14.3687 5.28249C14.3687 5.73507 14.7334 6.10345 15.1815 6.10345C15.6296 6.10345 15.9944 5.73507 15.9944 5.28249C15.9944 4.82991 15.6296 4.46153 15.1815 4.46153Z" fill={fillColor} />
            <path d="M15.1815 8.24503C14.7334 8.24503 14.3687 8.61341 14.3687 9.066C14.3687 9.51858 14.7334 9.88696 15.1815 9.88696C15.6296 9.88696 15.9944 9.51858 15.9944 9.066C15.9944 8.61341 15.6296 8.24503 15.1815 8.24503Z" fill={fillColor} />
            <path d="M19.0017 0.677765C18.5535 0.677765 18.1888 1.04614 18.1888 1.49872C18.1888 1.95131 18.5535 2.31969 19.0017 2.31969C19.4498 2.31969 19.8145 1.95131 19.8145 1.49872C19.8145 1.04614 19.4498 0.677765 19.0017 0.677765Z" fill={fillColor} />
            <path d="M19.002 4.46153C18.5539 4.46153 18.1892 4.82991 18.1892 5.28249C18.1892 5.73507 18.5539 6.10345 19.002 6.10345C19.4502 6.10345 19.8149 5.73507 19.8149 5.28249C19.8149 4.82991 19.4502 4.46153 19.002 4.46153Z" fill={fillColor} />
            <path d="M19.002 8.24503C18.5539 8.24503 18.1892 8.61341 18.1892 9.066C18.1892 9.51858 18.5539 9.88696 19.002 9.88696C19.4502 9.88696 19.8149 9.51858 19.8149 9.066C19.8149 8.61341 19.4502 8.24503 19.002 8.24503Z" fill={fillColor} />
            <path d="M22.7544 0.677765C22.3063 0.677765 21.9415 1.04614 21.9415 1.49872C21.9415 1.95131 22.3063 2.31969 22.7544 2.31969C23.2025 2.31969 23.5672 1.95131 23.5672 1.49872C23.5724 1.04614 23.2077 0.677765 22.7544 0.677765Z" fill={fillColor} />
            <path d="M22.7547 4.46153C22.3066 4.46153 21.9419 4.82991 21.9419 5.28249C21.9419 5.73507 22.3066 6.10345 22.7547 6.10345C23.2029 6.10345 23.5676 5.73507 23.5676 5.28249C23.5728 4.82991 23.2081 4.46153 22.7547 4.46153Z" fill={fillColor} />
            <path d="M22.7547 8.24503C22.3066 8.24503 21.9419 8.61341 21.9419 9.066C21.9419 9.51858 22.3066 9.88696 22.7547 9.88696C23.2029 9.88696 23.5676 9.51858 23.5676 9.066C23.5676 8.61341 23.2081 8.24503 22.7547 8.24503Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 5.25914C0 2.63074 2.13074 0.5 4.75913 0.5C7.38753 0.5 9.51827 2.63074 9.51827 5.25913V18.4967C9.51827 21.1251 7.38753 23.2559 4.75913 23.2559C2.13074 23.2559 0 21.1252 0 18.4968V5.25914ZM1.3608 18.3476C1.40451 16.445 2.96346 14.9377 4.8393 14.982C6.71878 15.0263 8.20488 16.6076 8.15753 18.5102C8.11382 20.4128 6.55487 21.9202 4.67903 21.8758C2.80319 21.8315 1.31709 20.2503 1.3608 18.3476ZM3.62476 19.6626H5.89761V17.3573H3.62476V19.6626ZM4.91179 1.96271C6.78866 2.00883 8.2758 3.58949 8.2334 5.49321C8.191 7.39693 6.63512 8.9028 4.75825 8.85668C2.88137 8.81055 1.39423 7.22989 1.43663 5.32617C1.47903 3.42246 3.03491 1.91658 4.91179 1.96271Z" fill={fillColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.98 13.3801V23.5H24V13.3801H13.98ZM21.9641 20.7213H16.0188V19.4688H21.9641V20.7213ZM16.0188 17.406H21.9641V16.1535H16.0188V17.406Z" fill={fillColor} />
        </svg>

    )
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".J41Bkmk3WFuzH2HtqDIS{border:none;box-shadow:var(--shadow-large);position:fixed;top:50%;left:50%;transform:translate(-50%, -50%);height:100%}.nR_Jxjt8WJ22H_OFWh90{border-bottom:1px solid var(--grey-200)}.nR_Jxjt8WJ22H_OFWh90 .txtInput{border:none;box-shadow:none;height:56px;font-size:16px;padding:0 16px}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/inbox/components/conversation/chatView/findReply/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,8BAAA,CAEA,cAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,WAAA,CAIF,sBACE,uCAAA,CAEA,gCACE,WAAA,CACA,eAAA,CACA,WAAA,CACA,cAAA,CACA,cAAA","sourcesContent":[".content {\n  border: none;\n  box-shadow: var(--shadow-large);\n  //center the element in the middle of the screen\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  height: 100%;\n\n}\n\n.search {\n  border-bottom: 1px solid var(--grey-200);\n\n  :global(.txtInput) {\n    border: none;\n    box-shadow: none;\n    height: 56px;\n    font-size: 16px;\n    padding: 0 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "J41Bkmk3WFuzH2HtqDIS",
	"search": "nR_Jxjt8WJ22H_OFWh90"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import { Route, Switch, useParams, useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { aiAssistantTabOptions } from "~/Classes/conversations";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import SettingsLayout from "../../components/settingsLayout";
import SettingsNavItem from "../../components/settingsNavItem";
import Button from "../../components/button";
import Breadcrumbs from "../../components/breadcrumbs";
import Overview from "../overview";
import Sources from "../sources";
import Articles from "../articles";
import Channels from "../channels";
import History from "../history";
import Settings from "../settings";
import Test from "../test";
import i18n from "~/i18n";

import DeleteAiAssistantDialog from "../../components/deleteAiAssistantDialog";
import RenameAiAssistantDialog from "../../components/renameAiAssistantDialog";

export default function AiAssistantTemplate({ inbox, assistant, toggleAiStatus, loadInitialData = () => { }, ...rest }) {

  const history = useHistory();
  const dispatch = useDispatch();

  const { path, url } = useRouteMatch();
  const { inboxId, settingName } = useParams();

  const [isRenameAssistantDialogOpen, setIsRenameAssistantDialogOpen] = useState(false);
  const [isDeleteAssistantDialogOpen, setIsDeleteAssistantDialogOpen] = useState(false);

  const pathWithoutSettingName = path.slice(0, path.lastIndexOf("/"));
  const urlWithoutSettingName = url.slice(0, url.lastIndexOf("/"));

  const currentTab = aiAssistantTabOptions.find(
    (tab) => tab.value === settingName
  );


  const statusDropDown = <UILIB.DropdownMenu.Root>
    <UILIB.DropdownMenu.Trigger
      size="s"
    >
      {i18n.t("chat:ai.statusDropDown.actions")}
    </UILIB.DropdownMenu.Trigger>

    <UILIB.DropdownMenu.Portal>
      <UILIB.DropdownMenu.Content>

        <UILIB.DropdownMenu.Item onSelect={() => { setIsRenameAssistantDialogOpen(true) }}>{i18n.t("chat:ai.statusDropDown.rename")}</UILIB.DropdownMenu.Item>
        {assistant.status > 1 && <>
          {assistant.status === 3 && <UILIB.DropdownMenu.Item onSelect={toggleAiStatus}>{i18n.t("chat:ai.statusDropDown.deActivate")}</UILIB.DropdownMenu.Item>}
          {assistant.status !== 3 && <UILIB.DropdownMenu.Item onSelect={toggleAiStatus}>{i18n.t("chat:ai.statusDropDown.activate")}</UILIB.DropdownMenu.Item>}
        </>}
        <UILIB.DropdownMenu.Separator />

        <UILIB.DropdownMenu.Item variant="danger" onClick={() => { setIsDeleteAssistantDialogOpen(true) }} >{i18n.t("chat:ai.statusDropDown.deleteAssistant")}</UILIB.DropdownMenu.Item>
      </UILIB.DropdownMenu.Content>
    </UILIB.DropdownMenu.Portal>
  </UILIB.DropdownMenu.Root >

  const commonProps = { inbox, assistant, ...rest };

  const renameAssistant = async (updatedAssistant, newName) => {
    try {
      await axios.put(
        `/inboxes/${inbox.id}/ai/${assistant.id}`,
        { name: newName }
      );
      setIsRenameAssistantDialogOpen(false);
      loadInitialData();
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  };

  const deleteAssistant = async () => {
    try {
      await axios.delete(`/inboxes/${inbox.id}/ai/${assistant.id}`);
      history.push(`/cp/conversations/${inboxId}/ai-assistants`);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  };

  return (
    <SettingsLayout
      backButton={
        <Button
          to={`/cp/conversations/${inboxId}/ai-assistants`}
          variant="secondary"
          size="s"
          iconLeft={<UILIB.Icon name="chevron-left" />}
        >
          {i18n.t("chat:ai.header")}
        </Button>
      }
      sideNavList={
        <>
          <ul>
            {aiAssistantTabOptions.map(({ label, value }) => {
              return (
                <li key={value}>
                  <SettingsNavItem
                    to={`${urlWithoutSettingName}/${value}`}
                    selected={settingName === value}
                  >
                    {i18n.t(label)}
                  </SettingsNavItem>
                </li>
              );
            })}
          </ul>
        </>
      }
      breadcrumbs={<>
        <Breadcrumbs>
          <Breadcrumbs.Item to={`/cp/conversations/${inboxId}/general`}>
            <UILIB.Icon name="inbox" />
            {inbox?.name}
          </Breadcrumbs.Item>

          <Breadcrumbs.Item to={`/cp/conversations/${inboxId}/ai-assistants`}>
            <UILIB.Icon name="sparkle" />
            {i18n.t("chat:settings.nav.ai")}
          </Breadcrumbs.Item>

          <Breadcrumbs.Item to={urlWithoutSettingName}>
            {assistant?.name}
          </Breadcrumbs.Item>

          <Breadcrumbs.Item>{i18n.t(currentTab?.label)}</Breadcrumbs.Item>
        </Breadcrumbs>
      </>
      }
    >
      <Switch>
        <Route exact path={`${pathWithoutSettingName}/overview`}>
          <Overview {...commonProps} statusDropDown={statusDropDown} />
        </Route>
        <Route exact path={`${pathWithoutSettingName}/sources`}>
          <Sources {...commonProps} statusDropDown={statusDropDown} />
        </Route>

        <Route exact path={`${pathWithoutSettingName}/articles`}>
          <Articles {...commonProps} statusDropDown={statusDropDown} />
        </Route>

        <Route exact path={`${pathWithoutSettingName}/channels`}>
          <Channels {...commonProps} statusDropDown={statusDropDown} />
        </Route>

        <Route exact path={`${pathWithoutSettingName}/settings`}>
          <Settings {...commonProps} statusDropDown={statusDropDown} />
        </Route>

        <Route exact path={`${pathWithoutSettingName}/history`}>
          <History {...commonProps} statusDropDown={statusDropDown} />
        </Route>

        <Route exact path={`${pathWithoutSettingName}/test`}>
          <Test {...commonProps} statusDropDown={statusDropDown} />
        </Route>
      </Switch>

      <RenameAiAssistantDialog
        assistant={assistant}
        isOpen={isRenameAssistantDialogOpen}
        onSubmit={renameAssistant}
        onOpenChange={(isOpen) => setIsRenameAssistantDialogOpen(isOpen)}
      />

      <DeleteAiAssistantDialog
        assistant={assistant}
        isOpen={isDeleteAssistantDialogOpen}
        onSubmit={deleteAssistant}
        onOpenChange={(isOpen) => setIsDeleteAssistantDialogOpen(isOpen)}
      />

    </SettingsLayout>
  );
}


import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import i18n from '~/i18n'
import moment from 'moment';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})
export default class Analytics extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            loadingData: true,
            loadingPage: true,
            subscriberFields: [],
            sendingQueueFields: [{
                label: "Opened",
                value: "openCount",
                selected: false,
                database: "sendingqueues"
            },
            {
                label: "Clicked",
                value: "clickCount",
                selected: false,
                database: "sendingqueues"
            },
            {
                label: "Bounced",
                value: "bounced",
                selected: false,
                database: "sendingqueues"
            },
            {
                label: "Mailserver",
                value: "mailserverid",
                selected: false,
                database: "sendingqueues"
            }],
            campaignFields: [{
                label: "Campaign Name",
                value: "campaignName",
                selected: false,
                database: "campaigns"
            }],
            analyticsData: [],
            addingFieldType: "",
            dateFilterStartDate: moment().subtract(30, 'day'),
            dateFilterEndDate: moment().endOf("day")
        };

        this.getSubscriberFields = this.getSubscriberFields.bind(this);
        this.selectAvailableFields = this.selectAvailableFields.bind(this);
        this.getData = this.getData.bind(this);
        this.addFieldSelection = this.addFieldSelection.bind(this);
        this.dateFilterUpdate = this.dateFilterUpdate.bind(this);
        this.removeField = this.removeField.bind(this);
    }
    componentDidMount() {
        this.getSubscriberFields();
    }

    getSubscriberFields() {
        var self = this;
        axios.get(`/customField`).then(resp => {
            var subscriberFields = resp.data.CustomFields.map(f => {
                return {
                    label: f.fieldDesc,
                    value: f.id,
                    selected: false,
                    database: "subscribers"
                }
            });

            self.setState({ subscriberFields, loadingPage: false })
        }).catch(err => {
            console.log(err);
        })
    }

    addFieldSelection(event) {
        this.setState({ addingFieldType: event.target.value })
    }

    removeField(fieldType, value) {
        var selectedFields = this.state[fieldType];
        var foundIndex = -1;
        selectedFields.forEach((field, index) => {
            if (field.value == value) foundIndex = index;
        })
        if (foundIndex > -1) selectedFields.splice(foundIndex, 1)
        console.log(selectedFields, foundIndex);
        this.setState({ [fieldType]: selectedFields })
    }

    selectAvailableFields(event, dataType) {
        var availableFields = this.state.subscriberFields
        var stateType = "subscriberFields";

        if (dataType == "sendingqueues") {
            availableFields = this.state.sendingQueueFields;
            stateType = "sendingQueueFields";
        }
        if (dataType == "campaigns") {
            availableFields = this.state.campaignFields;
            stateType = "campaignFields";
        }
        var field = availableFields.find(f => f.value == event.target.value)
        field.selected = true;
        this.setState({ [stateType]: availableFields, addingFieldType: "" })
    }

    dateFilterUpdate(event) {
        this.setState({ dateFilterStartDate: moment(event.startDate), dateFilterEndDate: moment(event.endDate) })
    }
    getData() {
        this.setState({ loadingData: true })
        var self = this;
        var postBody = {
            "query": {
                "tableName": "subscribers",
                "fields": [],
                "dateRanges": [{ field: "createdAt", range: [this.state.dateFilterStartDate.format(), this.state.dateFilterEndDate.format()] }],
                "joins": []
            }
        }

        //custom fields
        this.state.subscriberFields.filter(f => f.selected == true).forEach(field => {
            postBody.query.fields.push({ "id": field.value, "group": true, "filters": [] })
        })

        //sending queue
        var hasSendingQueueOptions = this.state.sendingQueueFields.filter(f => f.selected == true);
        if (hasSendingQueueOptions && hasSendingQueueOptions.length) {

            //reset main query daterange cos will be overridden by sendingqueue
            postBody.query.dateRanges = [];

            this.state.sendingQueueFields.filter(f => f.selected == true).forEach(field => {
                var sendingQueueJoin = postBody.query.joins.find(j => j.tableName == "sendingqueues")
                if (!sendingQueueJoin) {
                    sendingQueueJoin = {
                        "tableName": "sendingqueues",
                        "fields": [],
                        "dateRanges": [{ field: "sendDate", range: [this.state.dateFilterStartDate.format(), this.state.dateFilterEndDate.format()] }],
                        "joins": []
                    }
                    postBody.query.joins.push(sendingQueueJoin)
                    sendingQueueJoin = postBody.query.joins.find(j => j.tableName == "sendingqueues")
                }
                sendingQueueJoin.fields.push({ "id": field.value, "group": true, "filters": [] })
            })

            //campaigns
            this.state.campaignFields.filter(f => f.selected == true).forEach(field => {
                var sendingQueuesJoin = postBody.query.joins.find(j => j.tableName == "sendingqueues")
                var campaignsJoin = sendingQueuesJoin.joins.find(j => j.tableName == "campaigns")
                if (!campaignsJoin) {
                    campaignsJoin = {
                        "tableName": "campaigns",
                        "fields": [],
                        "dateRanges": [{ field: "startDate", range: [this.state.dateFilterStartDate.format(), this.state.dateFilterEndDate.format()] }],
                        "joins": []
                    }
                    sendingQueuesJoin.joins.push(campaignsJoin)
                    campaignsJoin = sendingQueuesJoin.joins.find(j => j.tableName == "campaigns")
                }
                campaignsJoin.fields.push({ "id": field.value, "group": true, "filters": [] })
            })

        }


        axios.post(`/subscriber/analytics`, postBody).then(resp => {

            var analyticsData = [];
            resp.data.results.forEach(row => {
                var rowOut = [];
                Object.keys(row).forEach(key => {
                    var label = key;
                    var value = row[key];
                    if (label.indexOf("CF_") > -1) {
                        //custom field
                        var customField = this.state.subscriberFields.find(cf => Number(cf.value) == Number(label.split("CF_")[1]))
                        if (customField) label = customField.label;
                    }
                    else {
                        switch (label) {
                            case "SendingQueues_openCount":
                                label = "Opened"
                                value = value == 1
                                break;
                            case "SendingQueues_clickCount":
                                label = "Clicked"
                                value = value == 1
                                break;
                            case "SendingQueues_bounced":
                                label = "Bounced"
                                value = value == 1
                                break;
                            case "SendingQueues_mailserverid":
                                label = "Mailserver"
                                break;
                            case "Campaigns_campaignName":
                                label = "Campaign Name"
                        }
                    }
                    rowOut.push({
                        label: label,
                        value: value

                    })
                })
                analyticsData.push(rowOut);

            })

            self.setState({ analyticsData, loadingData: false, loadingPage: false })

        }).catch(err => {
            console.log(err);
            self.setState({ loadingData: false })
        })
    }



    render() {

        if (this.state.loadingPage) return <div></div>

        var hasSendingQueueOptions = false;
        var sendingQueueOptionsDetected = this.state.sendingQueueFields.filter(f => f.selected == true);
        if (sendingQueueOptionsDetected && sendingQueueOptionsDetected.length) {
            hasSendingQueueOptions = true;
        }

        var addAFieldSelection = [
            { value: "subscribers", label: "Contact Fields" },
            { value: "sendingqueues", label: "Email Statistics" }
        ]
        if (hasSendingQueueOptions) {
            addAFieldSelection.push({ value: "campaigns", label: "Campaign Fields" },)
        }

        return <div>

            <h4 className="mar-b25">{i18n.t('subscribers:nav.analytics')}</h4>


            <UILIB.Paper className="form-group mar-b0">
                <div className="quickFlex">
                    <UILIB.Select placeholder="Add a field" data={addAFieldSelection} onChange={this.addFieldSelection} value={this.state.addingFieldType} />
                    {this.state.addingFieldType && this.state.addingFieldType == "subscribers" && <UILIB.Select placeholder="Select Fields" data={this.state.subscriberFields.filter(s => s.selected == false)} onChange={(event) => { this.selectAvailableFields(event, "subscribers") }} className={"mar-l10"} />}
                    {this.state.addingFieldType && this.state.addingFieldType == "sendingqueues" && <UILIB.Select placeholder="Select Fields" data={this.state.sendingQueueFields.filter(s => s.selected == false)} onChange={(event) => { this.selectAvailableFields(event, "sendingqueues") }} className={"mar-l10"} />}
                    {this.state.addingFieldType && this.state.addingFieldType == "campaigns" && <UILIB.Select placeholder="Select Fields" data={this.state.campaignFields.filter(s => s.selected == false)} onChange={(event) => { this.selectAvailableFields(event, "campaigns") }} className={"mar-l10"} />}
                </div>

                <div className={"quickFlex mar-t15"} >
                    {this.state.subscriberFields.filter(s => s.selected == true).map((f, index) => {
                        return <UILIB.SquareChip value={f.label} className="square-chip-secondary" hasButton={true} iconRight={<UILIB.Icons icon="crossCircle" style={{ height: 14, width: 14 }} />} onClick={() => { this.removeField("subscriberFields", f.value) }} />
                    })}

                    {this.state.sendingQueueFields.filter(s => s.selected == true).map((f, index) => {
                        return <UILIB.SquareChip value={f.label} className="square-chip-secondary" hasButton={true} iconRight={<UILIB.Icons icon="crossCircle" style={{ height: 14, width: 14 }} />} onClick={() => { this.removeField("sendingQueueFields", f.value) }} />
                    })}

                    {this.state.campaignFields.filter(s => s.selected == true).map((f, index) => {
                        return <UILIB.SquareChip value={f.label} className="square-chip-secondary" hasButton={true} iconRight={<UILIB.Icons icon="crossCircle" style={{ height: 14, width: 14 }} />} onClick={() => { this.removeField("campaignFields", f.value) }} />
                    })}
                </div>

                <div>
                    <UILIB.TextInput style={{ boxShadow: "none", border: "2px solid grey" }} type="multiDate" startDate={this.state.dateFilterStartDate} endDate={this.state.dateFilterEndDate} updateDate={this.dateFilterUpdate}></UILIB.TextInput>
                </div>

                <div>
                    <UILIB.Button className="mar-t15" text="Generate" onClick={this.getData} />
                </div>

            </UILIB.Paper>



            <UILIB.Paper>
                {this.state.loadingData == true && <UILIB.LoadingIcons iconType="2" />}
                {this.state.loadingData == false && <div>

                    {!this.state.analyticsData.length && <div>No Results</div>}

                    {this.state.analyticsData.length && <table width="100%" cellPadding="10" cellSpacing="2">
                        <tr>
                            {this.state.analyticsData[0].map((row, index) => {
                                return <td key={"hd_" + index}>{row.label}</td>
                            })}
                        </tr>
                        {this.state.analyticsData.map((row, index) => {
                            return <tr key={"r_" + index}>
                                {row.map((col, cIndex) => {
                                    var value = col.value;
                                    var friendlyValue = col.value;
                                    var label = col.label;

                                    if (typeof value == "boolean") {
                                        if (value) {
                                            friendlyValue = "YES"
                                        } else {
                                            friendlyValue = "NO"
                                        }
                                    }
                                    return <td key={"r_" + index + "_c_" + cIndex}>
                                        {friendlyValue}
                                    </td>
                                })}
                            </tr>
                        })}

                    </table>}

                </div>}
            </UILIB.Paper>


        </div >

    }
};

import React from 'react'

export default class IconsNewWindow extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.68391 0.275817C5.86051 0.0992145 6.10004 0 6.34979 0H11.0583C11.308 0 11.5476 0.0992145 11.7242 0.275817C11.9008 0.45242 12 0.691946 12 0.9417V5.6502C12 5.89995 11.9008 6.13948 11.7242 6.31608C11.5476 6.49268 11.308 6.5919 11.0583 6.5919C10.8085 6.5919 10.569 6.49268 10.3924 6.31608C10.2158 6.13948 10.1166 5.89995 10.1166 5.6502V3.21496L4.19047 9.14108C4.10361 9.23102 3.99969 9.30276 3.8848 9.35212C3.76991 9.40147 3.64634 9.42745 3.5213 9.42854C3.39627 9.42962 3.27226 9.4058 3.15653 9.35845C3.0408 9.3111 2.93566 9.24117 2.84724 9.15275C2.75882 9.06433 2.68889 8.95919 2.64155 8.84346C2.5942 8.72773 2.57037 8.60373 2.57146 8.47869C2.57254 8.35365 2.59852 8.23008 2.64787 8.11519C2.69723 8.0003 2.76897 7.89639 2.85891 7.80952L8.78503 1.8834H6.34979C6.10004 1.8834 5.86051 1.78419 5.68391 1.60758C5.50731 1.43098 5.40809 1.19145 5.40809 0.9417C5.40809 0.691946 5.50731 0.45242 5.68391 0.275817ZM0.543944 1.4011C0.892226 1.05282 1.3646 0.857159 1.85714 0.857159H3.71429C3.96056 0.857159 4.19674 0.954991 4.37088 1.12913C4.54503 1.30327 4.64286 1.53946 4.64286 1.78573C4.64286 2.032 4.54503 2.26819 4.37088 2.44233C4.19674 2.61647 3.96056 2.7143 3.71429 2.7143H1.85714V10.1429H9.28571V8.28573C9.28571 8.03946 9.38355 7.80327 9.55769 7.62913C9.73183 7.45499 9.96801 7.35716 10.2143 7.35716C10.4606 7.35716 10.6967 7.45499 10.8709 7.62913C11.045 7.80327 11.1429 8.03946 11.1429 8.28573V10.1429C11.1429 10.6354 10.9472 11.1078 10.5989 11.4561C10.2506 11.8044 9.77826 12 9.28571 12H1.85714C1.3646 12 0.892226 11.8044 0.543944 11.4561C0.195663 11.1078 0 10.6354 0 10.1429V2.7143C0 2.22176 0.195663 1.74939 0.543944 1.4011Z" fill={color} />
        </svg>


    }
}        
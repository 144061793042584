import React from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import i18n from '~/i18n'
import moment from 'moment';

export default class TrackerImpressionsDrawer extends React.Component {

    constructor(props) {

        let timer;
        super(props);
        this.state = {
            loading: true,
            history: [],
            tableData: []
        }
        this.getHistory = this.getHistory.bind(this);
    }

    componentDidMount() {
        this.getHistory()
    }

    async getHistory() {
        let history = await axios.get(`/accountMaster/tracker/${this.props.tracker.id}/visitors?uuid=${this.props.uuid}&startDate=${this.props.startDate.format("YYYY-MM-DD")}&endDate=${this.props.endDate.format("YYYY-MM-DD")}`)
        let trackerLogs = history.data.TrackerLogs;
        let tableData = [];
        if (trackerLogs.length) {
            trackerLogs.forEach(row => {
                let deviceIcon = "alert";
                if (row.device == "desktop") deviceIcon = "pc";
                if (row.device == "phone") deviceIcon = "mobile";
                if (row.device == "bot") deviceIcon = "cog";
                var deviceTxt = <span>
                    <UILIB.Icons icon={deviceIcon} alt={row.device} title={row.device} />
                    {(row.closeCallCount && row.closeCallCount > 1) && <span className="mar-l5 icon-warning" style={{ color: "red" }} alt="Maybe Robot - Lots of clicks at same time" title="Maybe Robot - Lots of clicks at same time" />}
                </span>
                let event = null;
                if (row.event && row.event.siteTrackerEventName) {
                    event = row.event.siteTrackerEventName;
                }
                let sourceContent = "";
                if (row.adApplication) {
                    let click;
                    if (row.siteTrackerSourceAdCampaignId) {
                        click = () => { this.props.history.push('/cp/ads/campaign/' + row.siteTrackerSourceAdCampaignId) };
                    }
                    sourceContent = <>
                        <UILIB.Avatar
                            style={{ height: 25, width: 25, marginLeft: 5, backgroundColor: 'white', border: '1px solid grey' }}
                            src={row.adApplication.appLogoUri}
                            tooltip={row.adApplication.appName + ": " + row.campaignName}
                            onClick={click}
                        />

                    </>
                }

                tableData.push({
                    lastVisit: {
                        headerValue: i18n.t('campaigns:sitetracking.trackingvistors.lastVisit'),
                        value: DateTimeFunctions.formatDateTime(row.createdAt),
                        orderBy: false,
                        width: 150
                    },
                    path: {
                        headerValue: i18n.t('campaigns:sitetracking.trackingvistors.lastUrl'),
                        value: row.path
                    },
                    device: {
                        headerValue: i18n.t('campaigns:sitetracking.trackingvistors.device'),
                        value: deviceTxt,
                        align: "center"
                    }, source: {
                        headerValue: "Source",
                        value: sourceContent
                    },
                    eventName: {
                        headerValue: "Event",
                        value: event ? <UILIB.SquareChip>{event}</UILIB.SquareChip> : ""
                    }
                })
            })
        }


        this.setState({ tableData, loading: false })
    }


    render() {

        return (
            <div>
                <h4 className="mar-b15">Visit History</h4>
                <p className="mar-b25">Browse through the history of visits this user has made to your website and the pages they viewed.</p>
                <UILIB.DataTable1
                    noResultsTxt={i18n.t('campaigns:sitetracking.trackingvistors.noDataFound')}
                    tableData={this.state.tableData}
                    dataUpdater={() => { }}
                    width="100%"
                    pageSize="100"
                    hasCheckBoxes="no"
                    sortedColumn={""}
                    sortedDirection={""}
                    loadingData={this.state.loading}
                />
            </div >
        );
    }
}

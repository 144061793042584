import React, { Component } from 'react';
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import Step1 from '../steps/step1/standardCampaignStep1'
import AbTests from '../steps/abTests'
import AbSend from '../steps/abSend'
import EmailValidator from '~/Classes/emailValidator'


@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})
class ABSplitTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            campaignId: 0,
            campaign: {},
            campaigns: [],
            subscriberCount: 0,
            lastUpdated: Date.now(),
            testsInvalid: 0,
            edittingName: false
        }

        this.getCampaign = this.getCampaign.bind(this)
        this.getChildren = this.getChildren.bind(this)
        this.changeStep = this.changeStep.bind(this)
        this.saveCampaign = this.saveCampaign.bind(this)
        this.updateCampaign = this.updateCampaign.bind(this)
        this.checkSendingLimit = this.checkSendingLimit.bind(this)
        this.updateSubscriberCount = this.updateSubscriberCount.bind(this)
        this.addChild = this.addChild.bind(this)
        this.countSubs = this.countSubs.bind(this)
        this.deleteCampaign = this.deleteCampaign.bind(this)
        this.validateTests = this.validateTests.bind(this)
        this.saveCampaignName = this.saveCampaignName.bind(this);
    }

    componentDidMount() {
        this.getCampaign()
    }


    getCampaign() {
        axios.get('/campaign/' + this.props.match.params.campaignId).then(response => {
            if (!response.data.Campaign.options) response.data.Campaign.options = {}
            if (!response.data.Campaign.options.splitToSend) response.data.Campaign.options.splitToSend = 50
            if (!response.data.Campaign.options.splitDelayValue) response.data.Campaign.options.splitDelayValue = 2
            if (!response.data.Campaign.options.splitDelayUnit) response.data.Campaign.options.splitDelayUnit = 'hours'

            this.setState({
                campaign: response.data.Campaign,
                campaignId: response.data.Campaign.id
            }, this.getChildren)
        })
    }

    getChildren() {
        axios.get('/campaign/' + this.props.match.params.campaignId + '/children').then(response => {
            this.setState({
                campaigns: response.data,
                loaded: true
            }, () => { this.validateTests(); this.countSubs() })
        })
    }

    countSubs() {
        var queryStr = '?ignoreLog=1'
        if (!this.state.campaign.options) this.state.campaign.options = {}
        if (this.state.campaign.options && this.state.campaign.options.filterSegments) {
            queryStr += '&filterSegments=true'
        }
        axios.post('/campaign/count' + queryStr, {
            Segments: this.state.campaign.Segments,
            Groups: this.state.campaign.Groups
        }).then(response => {

            var subscriberCount = response.data.Count;
            if (this.state.campaign && this.state.campaign.limit && this.state.campaign.limit > 0) {
                if (subscriberCount > this.state.campaign.limit) subscriberCount = this.state.campaign.limit;
            }
            this.setState({
                subscriberCount: subscriberCount
            }, this.checkSendingLimit)
        })
    }

    changeStep(stepNo, save) {
        if (save) {
            axios.put('/campaign/' + this.state.campaignId, {
                campaign: this.state.campaign
            }).then(() => this.props.history.push('/cp/campaigns/add/ab/' + this.state.campaignId + '/' + stepNo))
        } else {
            this.props.history.push('/cp/campaigns/add/ab/' + this.state.campaignId + '/' + stepNo)
        }

    }

    saveCampaign() {
        return axios.put('/campaign/' + this.state.campaignId, {
            campaign: this.state.campaign
        })
    }

    updateCampaign(keepgoing) {
        var campaign = this.state.campaign;
        campaign.lastUpdated = Date.now()
        this.setState({
            campaign,
            lastUpdated: Date.now()
        }, () => { if (keepgoing) this.getChildren() }, this.validateTests)
    }

    deleteCampaign(id) {
        axios.delete('/campaign/' + id).then(() => {
            var campaigns = this.state.campaigns
            var index = campaigns.findIndex(c => c.id === id)
            if (index > -1) {
                campaigns.splice(index, 1)
            }
            this.setState({ campaigns, lastUpdated: Date.now() }, this.validateTests)
        })
    }

    checkSendingLimit() {
        this.saveCampaign().then(() => {
            axios.get('/campaign/' + this.state.campaign.id + '/checksendinglimit').then(response => {
                this.setState(response.data)
                if (response.data.newBillingPlan) {
                    axios.get('/accountMaster/plan/' + response.data.newBillingPlan.id).then(res => {
                        this.setState({
                            calculatedPrice: res.data
                        })
                    })
                }
            })
        })

    }

    updateSubscriberCount(subCount) {
        if (this.state.campaign && this.state.campaign.limit && this.state.campaign.limit > 0) {
            if (subCount > this.state.campaign.limit) {
                subCount = this.state.campaign.limit
            }
        }
        this.setState({ subscriberCount: subCount }, this.checkSendingLimit)
    }

    addChild(campaign) {
        var campaigns = this.state.campaigns
        campaigns.push(campaign)
        this.setState({ campaigns, lastUpdated: Date.now() }, this.validateTests)
    }

    validateTests() {
        var promises = []
        var results = []
        this.state.campaigns.forEach(campaign => {
            var cErrors = { campaignId: campaign.id, campaignName: "", subject: "", fromName: "", fromAddress: "", replyAddress: "", templateId: "", failed: false };

            if (!campaign.campaignName) {
                cErrors.campaignName = 'Campaign Name Required';
                cErrors.failed = true;
            }
            if (!campaign.TemplateId) {
                cErrors.templateId = 'Template Required';
                cErrors.failed = true;
            }
            if (!campaign.subject || campaign.subject.length < 4) {
                cErrors.subject = ' Invalid Subject';
                cErrors.failed = true;
            }
            if (!campaign.fromName || campaign.fromName.length < 4) {
                cErrors.fromName = 'Invalid From Name';
                cErrors.failed = true;
            }
            if (!campaign.replyAddress || !EmailValidator.validateEmail(campaign.replyAddress)) {
                cErrors.replyAddress = "Requires a valid Reply Address"
                cErrors.failed = true;
            }
            if (!campaign.fromAddress || campaign.fromAddress.length < 4 || !EmailValidator.validateEmail(campaign.fromAddress)) {
                cErrors.fromAddress = 'Invalid From Address';
                cErrors.failed = true;
            }
            else {
                promises.push(EmailValidator.checkEmailAuthed(campaign.fromAddress).then(res => {
                    if (res.error || !res.domainExists || !res.domainVerified || (this.props.siteMaster.usersMustBeAuthenticated && !res.domainAuthenticated)) {
                        cErrors.fromAddress = 'Domain Requires Authentication';
                        cErrors.failed = true;
                    }
                    return cErrors;
                }))
                return;

            }
            results.push(cErrors)

        })
        return Promise.all(promises).then((errR) => {
            if (errR && errR.length) {
                results = results.concat(errR)
            }
            this.setState({ testsInvalid: results });

        })
    }


    saveCampaignName(event) {
        var campaign = this.state.campaign;
        campaign.campaignName = event.target.value;
        this.setState({ campaign });
    }

    render() {

        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />

        var currentStep = this.props.match.params.step || 1;
        var stepClasses = { step1: "", step2: "", step3: "" }
        var hasFailedTests = false;

        if (this.state.testsInvalid) {
            var failedTests = this.state.testsInvalid.filter(t => t.failed == true)
            if (failedTests && failedTests.length) hasFailedTests = true;
        }

        if ((this.state.campaign.Groups.length > 0 || this.state.campaign.Segments.length > 0) && this.state.subscriberCount > 0) {
            stepClasses.step1 = "addcampaign-stepholder-step-complete"

            if (this.state.campaigns && this.state.campaigns.length) {
                stepClasses.step2 = "addcampaign-stepholder-step-complete"
                if (!hasFailedTests) {
                    stepClasses.step3 = "addcampaign-stepholder-step-complete"
                }
            }
        }

        switch (parseInt(currentStep)) {
            case 1:
                stepClasses.step1 = "addcampaign-stepholder-step-selected"
                break;
            case 2:
                stepClasses.step2 = "addcampaign-stepholder-step-selected"
                break;
            case 3:
                stepClasses.step3 = "addcampaign-stepholder-step-selected"
                break;
            default:
                stepClasses.step1 = "addcampaign-stepholder-step-selected"
                break;
        }

        let steps = [];
        steps.push({
            title: i18n.t('campaigns:add.recipients'),
            onClick: () => { this.changeStep(1) },
            selected: stepClasses.step1 == "addcampaign-stepholder-step-selected",
            complete: stepClasses.step1 == "addcampaign-stepholder-step-complete"
        })
        steps.push({
            title: i18n.t('campaigns:add.tests'),
            onClick: () => { if (stepClasses.step2 != "") { return this.changeStep(2) } },
            selected: stepClasses.step2 == "addcampaign-stepholder-step-selected",
            complete: stepClasses.step2 == "addcampaign-stepholder-step-complete"
        })
        steps.push({
            title: i18n.t('campaigns:add.send'),
            onClick: () => { if (stepClasses.step3 != "") { return this.changeStep(3) } },
            selected: stepClasses.step3 == "addcampaign-stepholder-step-selected",
            complete: stepClasses.step3 == "addcampaign-stepholder-step-complete"
        })
        return (
            <UILIB.Paper>
                <UILIB.Row>
                    <UILIB.Column xs={12} >
                        {this.state.edittingName && <div className="mar-b25 quickFlex">
                            <UILIB.TextInput
                                outerStyle={{ flex: "1" }}
                                autoFocus={true}
                                value={this.state.campaign.campaignName}
                                name="campaignName"
                                onChange={this.saveCampaignName} />
                            <UILIB.Icons
                                style={{ flex: "0", width: "20px", height: "20px" }}
                                icon="tick"
                                onClick={() => this.setState({ edittingName: false }, this.saveCampaign)}
                                className="mar-l10"
                            />
                        </div>
                        }
                        {!this.state.edittingName && <h4 className="mar-b25 quickFlex" style={{ alignItems: "center" }}>
                            <div className="page-header mar-r10">{this.state.campaign.campaignName}</div>
                            <UILIB.Icons
                                style={{ width: "20px", height: "20px" }}
                                icon="pencil"
                                onClick={() => this.setState({ edittingName: true })}
                            />
                        </h4>
                        }
                        <UILIB.ProgressNav steps={steps} />

                    </UILIB.Column>
                </UILIB.Row>

                <div className="campaign-holder">
                    {currentStep == 1 && <Step1 minimum={4} sendingLimitReached={this.state.sendingLimitReached} campaign={this.state.campaign} newBillingPlan={this.state.newBillingPlan} calculatedPrice={this.state.calculatedPrice} saveCampaign={this.saveCampaign} groups={this.state.campaign.Groups} segments={this.state.campaign.Segments} changeStep={this.changeStep} updateCampaign={this.updateCampaign} updated={this.state.lastUpdated} history={this.props.history} updateSubscriberCount={this.updateSubscriberCount} />}
                    {currentStep == 2 && <AbTests subscriberCount={this.state.subscriberCount} campaign={this.state.campaign} deleteCampaign={this.deleteCampaign} campaigns={this.state.campaigns} location={this.props.location} saveCampaign={this.saveCampaign} history={this.props.history} updated={this.state.lastUpdated} addCampaign={this.addChild} changeStep={this.changeStep} updateCampaign={this.updateCampaign} testsInvalid={this.state.testsInvalid} getChildren={this.getChildren} />}
                    {currentStep == 3 && <AbSend campaign={this.state.campaign} subscriberCount={this.state.subscriberCount} updateCampaign={this.updateCampaign} history={this.props.history} saveCampaign={this.saveCampaign} campaigns={this.state.campaigns} testsInvalid={this.state.testsInvalid} />}
                </div>
            </UILIB.Paper>
        );
    }
}

export default ABSplitTest;
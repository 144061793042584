import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';

export default class Tr extends Component {
    constructor(props) {
        super(props)

    }

    render() {

        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "tr", this.props.localVariables)
        }
        return <tr {...eleProps}>
            {this.props.children}
        </tr>

    }
}



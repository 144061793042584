import React, { Fragment } from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib';
import NumberFunction from '~/Classes/numberFormatFunctions'
import i18n from '~/i18n'
import axios2 from 'axios'
import SegmentEditor from '~/pages/cp/includes/segmentEditor/segmentEditor'
import AddGroupsPop from './addGroupsPop'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import ViewSubs from './viewSubs'
import CloneSegmentDrawer from './cloneSegmentDrawer'
import PermissionChecker from '~/Classes/permissions';
import SmsFieldSelector from '../../sms/field'
import Topup from '~/pages/cp/company/sms/topup'
import moment from 'moment';
import CampaignAdvancedOptions from '../advanced';

//CONNECT TO STORE
@connect((store) => {
    return { permissionStore: store.permission, user: store.user, siteMaster: store.siteMaster, account: store.accountMaster }
})

export default class StandardCampaignStep1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoaded: false,
            showGroups: false,
            showSegments: false,
            count: 0,
            allGroupData: [],
            showAdvanced: false,
            included: true,
            counting: false,
            openFor: 'both'
        }

        this.targetAdvance = React.createRef();

        let timer;

        this.addGroups = this.addGroups.bind(this);
        this.hideGroups = this.hideGroups.bind(this);
        this.addSegments = this.addSegments.bind(this);
        this.hideSegments = this.hideSegments.bind(this);
        this.changedAddedGroups = this.changedAddedGroups.bind(this);
        this.countSubs = this.countSubs.bind(this);
        this.initPage = this.initPage.bind(this);
        this.showHideAdvanced = this.showHideAdvanced.bind(this);
        this.autoExpandAdvance = this.autoExpandAdvance.bind(this);
        this.save = this.save.bind(this);
        this.update = this.update.bind(this)
        this.addTempSegment = this.addTempSegment.bind(this);
        this.addedTempSegment = this.addedTempSegment.bind(this);
        this.doesSegmentIncludeCampaignsOrGroups = this.doesSegmentIncludeCampaignsOrGroups.bind(this)
        this.editSegment = this.editSegment.bind(this);
        this.edittedSegment = this.edittedSegment.bind(this);
        this.viewSubs = this.viewSubs.bind(this)
        this.cloneSegment = this.cloneSegment.bind(this);
    }



    save() {
        this.props.saveCampaign()
            .then(() => {
                this.props.history.push('/cp/campaigns')
            })
    }

    addGroups(included) {
        this.setState({
            showGroups: true,
            included: included,
            openFor: 'groups'
        })
    }

    hideGroups() {
        this.setState({
            showGroups: false
        })
    }

    addSegments(included) {
        this.setState({
            showSegments: true,
            included: included,
            openFor: 'segments'
        })
    }

    hideSegments() {
        this.setState({
            showSegments: false
        })
    }

    update(ev) {
        var campaign = this.props.campaign
        if (ev.target.name == "addUtmTracking") {
            campaign[ev.currentTarget.name] = ev.currentTarget.checked
        } else if (ev.target.name == "filterSegments") {
            if (!campaign.options) campaign.options = {};
            campaign.options.filterSegments = ev.currentTarget.checked;
            this.countSubs()
        } else if (ev.target.name == 'doNotTrackLinks') {
            if (!campaign.options) campaign.options = {};
            campaign.options.doNotTrackLinks = !ev.currentTarget.checked;
        } else if (ev.target.name == 'doNotTrackOpens') {
            if (!campaign.options) campaign.options = {};
            campaign.options.doNotTrackOpens = !ev.currentTarget.checked;
        } else if (ev.target.name == "fixedCustomFields") {
            if (!campaign.options) campaign.options = {};
            campaign.options.fixedCustomFields = ev.currentTarget.checked;
        } else {
            campaign[ev.currentTarget.name] = ev.currentTarget.value
        }
        this.props.updateCampaign()
    }

    componentDidMount() {
        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();
        this.initPage()
    }

    initPage() {
        var self = this;
        var allGroupData = [];
        if (!this.props.campaign.options) this.props.campaign.options = {};
        if (this.props.campaign.options.filterSegments === undefined) this.props.campaign.options.filterSegments = this.props.account.accountMaster.options.filterSegmentsByDefault;

        axios.get('/group').then(groupData => {
            allGroupData = groupData.data.Groups;

            self.setState({ allGroupData: allGroupData, pageLoaded: true }, () => {
                self.countSubs();
            })
            // if (!this.props.groups.length && !this.props.segments.length) {
            //     this.addGroups(true)
            // }
        })

        this.setState({ showAdvanced: this.autoExpandAdvance() });
    }

    changedAddedGroups(theId, goAdd, isGroup, include, name, queryObj, temporary) {
        var groups = this.props.groups;
        var segments = this.props.segments;
        if (goAdd) {
            if (isGroup) {
                groups.push({ id: theId, include: include, name })
            }
            else {
                segments.push({ id: theId, include: include, name, queryObj, temporary })
            }
        } else {
            if (isGroup) {
                var index = groups.findIndex(g => g.id === theId);
                groups.splice(index, 1);
            }
            else {
                var index = segments.findIndex(g => g.id === theId);
                segments.splice(index, 1);
            }
        }

        var groupsIncluded = groups.filter(g => g.include);
        var segmentsIncluded = segments.filter(g => g.include);

        if (groupsIncluded.length === 0 && segmentsIncluded.length === 0) {

            //clear all groups and segments cos all we have left is not included stuff
            groups.splice(0, groups.length);
            segments.splice(0, segments.length);
            // this.setState({ count: 0, counting: true })
            this.props.updateSubscriberCount(0);
            this.props.updateCampaign()
            return;
        }


        var campaign = this.props.campaign;
        if (groupsIncluded.length == 1) {
            var group = this.state.allGroupData.find(g => g.id == groupsIncluded[0].id);
            if (group) {
                if (group.defaultFromName && !campaign.fromName) {
                    campaign.fromName = group.defaultFromName;
                }
                if (group.defaultFromEmailAddress && !campaign.fromAddress) {
                    campaign.fromAddress = group.defaultFromEmailAddress;
                }
                if (group.defaultReplyAddress && !campaign.replyAddress) {
                    campaign.replyAddress = group.defaultReplyAddress;
                }
                if (group.defaultEmailSubject && !campaign.subject) {
                    campaign.subject = group.defaultEmailSubject;
                }
            }
        }

        // if (segments.some(this.doesSegmentIncludeCampaignsOrGroups) || segments.some(s => !s.include)) {
        //     campaign.options.filterSegments = false
        // }

        this.props.updateCampaign()

        if (this.timer) {
            clearTimeout(this.timer)
        }
        this.timer = setTimeout(this.countSubs, 250)

    }

    doesSegmentIncludeCampaignsOrGroups(segment) {
        return segment.queryObj.and.some(f => f.customFieldId === 'campaignId' || f.customFieldId === 'GroupId') || segment.queryObj.or.some(f => f.customFieldId === 'campaignId' || f.customFieldId === 'GroupId')
    }

    countSubs() {
        if (this.state.counting) {
            this.source.cancel('I cancelled this')
            this.source = this.CancelToken.source();
        }

        this.setState({
            counting: true
        })

        var queryStr = '?ignoreLog=1'
        if (!this.props.campaign.options) this.props.campaign.options = {}
        if (this.props.campaign.options && this.props.campaign.options.filterSegments) {
            queryStr += '&filterSegments=true'
        }

        axios.post('/campaign/count' + queryStr, {
            Segments: this.props.segments,
            Groups: this.props.groups,
            smsField: this.props.campaign.options.smsField
        }, {
            cancelToken: this.source.token
        }).then(response => {
            this.setState({
                count: response.data.Count,
                counting: false
            }, this.props.updateSubscriberCount(response.data.Count))
        }).catch(err => {
            // console.log(err)
            if (axios2.isCancel(err)) {
                console.log('cancelled request')
            }
        })
    }

    autoExpandAdvance() {
        var groupsExcluded = this.props.groups.filter(group => group.CampaignGroup ? !group.CampaignGroup.include : !group.include);
        var segmentsExcluded = this.props.segments.filter(segment => segment.CampaignSegment ? !segment.CampaignSegment.include : !segment.include);
        var totalExcluded = groupsExcluded.length + segmentsExcluded.length;
        return ((totalExcluded > 0) || (this.props.campaign.batchSize > 0) || (this.props.campaign.batchDelay > 0) || (this.props.campaign.limit > 0) || !!this.props.campaign.addUtmTracking || !!this.props.campaign.options.fixedCustomFields) ? true : false
    }

    showHideAdvanced() {
        var showAdvanced = this.state.showAdvanced;
        if (showAdvanced === false) {
            showAdvanced = true;
        }
        else {
            showAdvanced = false;
        }
        this.setState({ showAdvanced: showAdvanced });
        if (showAdvanced) {
            setTimeout(this.scrollToTarget, 300)
        }

    }

    addTempSegment(isIncluded) {
        this.hideGroups();
        var rndNo = Math.round(Math.random() * 999999);
        var drawerContent = <SegmentEditor temporary={true} history={this.props.history} segName={"Campaign " + this.props.campaign.id + " Segment #" + rndNo} close={(resp) => { this.addedTempSegment(resp, isIncluded) }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "100%"));
    }

    addedTempSegment(resp, isIncluded) {
        var failed = false;
        if (!resp.data) failed = true;
        if (resp.data.error) failed = true;
        if (failed) {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, false));
            return;
        }
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, false));
        this.changedAddedGroups(resp.data.Segment.segmentId, true, false, isIncluded ? true : false, resp.data.Segment.name, resp.data.Segment.queryObj, true)
    }

    editSegment(segId) {
        var drawerContent = <SegmentEditor temporary={true} history={this.props.history} segmentId={segId} close={this.edittedSegment} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "100%"));
    }

    edittedSegment() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, false));
        this.countSubs();
    }

    viewSubs() {
        var drawerContent = <ViewSubs campaign={this.props.campaign} segments={this.props.segments} groups={this.props.groups} count={this.state.count} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "100%"));
    }

    cloneSegment(segment) {
        var drawerContent = <CloneSegmentDrawer goClone={() => { this.goCloneSegment(segment) }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    goCloneSegment(segment) {
        var self = this;
        var rndNo = Math.round(Math.random() * 999999);
        var segmentName = i18n.t('campaigns:main.campaign') + " " + this.props.campaign.id + " " + i18n.t('campaigns:main.segment') + "#" + rndNo
        axios.post('/segment/clone/' + segment.id, { temporary: true, name: segmentName }).then(segRes => {
            //remove the current segment from the campaign
            self.changedAddedGroups(segment.id, false, false, true)
            //add the new segment to the campaign
            self.changedAddedGroups(segRes.data.Segment.segmentId, true, false, true, segRes.data.Segment.name, segRes.data.Segment.queryObj, true)
            //edit the new segment
            self.editSegment(segRes.data.Segment.segmentId);
        }).catch(err => {
        })

    }

    topup() {
        const drawerContent = <Topup onClose={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false));
            this.countSubs()
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    scrollToTarget = () => {
        this.targetAdvance.current.scrollIntoView({ behavior: 'smooth' });
    };


    render() {

        var groupsIncluded = this.props.groups.filter(group => group.CampaignGroup ? group.CampaignGroup.include : group.include);
        var segmentsIncluded = this.props.segments.filter(segment => segment.CampaignSegment ? segment.CampaignSegment.include : segment.include);
        var totalIncluded = groupsIncluded.length + segmentsIncluded.length;

        var groupsExcluded = this.props.groups.filter(group => group.CampaignGroup ? !group.CampaignGroup.include : !group.include);
        var segmentsExcluded = this.props.segments.filter(segment => segment.CampaignSegment ? !segment.CampaignSegment.include : !segment.include);
        var totalExcluded = groupsExcluded.length + segmentsExcluded.length;


        var groupsNotCleaned = groupsIncluded.filter(group => {
            var thisGroup = this.state.allGroupData.find(g => g.id == group.id);
            if (!thisGroup) return false;
            if (!thisGroup.lastCleaned) return true;
            var diff = moment.duration(moment().diff(moment(thisGroup.lastCleaned))).asDays();
            if (diff > 90) return true;
        })

        var submitButtonClass = "";
        var submitDisabled = false;
        if (this.state.count <= 0 || (this.props.minimum && this.state.count < this.props.minimum)) {
            submitButtonClass += " button-primary ";
            submitDisabled = true;
        }
        else {
            submitButtonClass += " button-primary "
        }

        var canFilterSegments = true;
        // if (segmentsExcluded.length || this.props.segments.some(this.doesSegmentIncludeCampaignsOrGroups)) {
        //     canFilterSegments = false;
        // }

        var totalSubs = this.state.count

        if (this.props.campaign.limit && Number(this.props.campaign.limit) > 0) {
            if (Number(this.props.campaign.limit) < totalSubs) totalSubs = Number(this.props.campaign.limit)
        }

        const canEditSegments = PermissionChecker('segments', this.props.permissionStore.permissions, "write");
        const canEditSubscribers = PermissionChecker('subscribers', this.props.permissionStore.permissions, "write");

        const canViewSegments = PermissionChecker('segments', this.props.permissionStore.permissions, "read");
        const canViewSubscribers = PermissionChecker('subscribers', this.props.permissionStore.permissions, "read");

        const canViewRecipients = canViewSegments && canViewSubscribers;
        const isSms = this.props.campaign.type === 6
        if (isSms) {
            groupsNotCleaned = []
        }

        let showCount = !!totalIncluded
        if (isSms && !this.props.campaign.options.smsField) {
            showCount = false
        }

        let billedOnContacts = this.props.account.accountMaster.billedOnContacts;
        let showBilling = true;
        if (this.props.siteMaster.hidePricing) showBilling = false;
        if (this.props.account.accountMaster.invoice) showBilling = false;

        if (this.props.groups && this.props.segments && this.state.pageLoaded) {
            return <div>
                <UILIB.Row style={{ marginBottom: 0 }}>
                    <UILIB.Column xs={12} sm={12} md={8} lg={8} margin={0}>

                        <UILIB.Paper style={{ padding: "24px" }}>
                            <div className="mar-b25">
                                <h4 className="mar-b25 cyt-new-campaign-header2" dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step1.includeHeader2') }} />

                                {<UILIB.TextInput
                                    label={i18n.t('campaigns:standard.step1.groups')}
                                    placeholder={i18n.t('campaigns:standard.step1.searchGroup')}
                                    onClick={() => {
                                        this.addGroups(true)
                                    }}
                                    readOnly={true}
                                    iconLeft={<UILIB.Icons icon='magnifier' />}
                                    className='cyt-new-campaign-included-grp'
                                />
                                }

                                {(totalIncluded) > 0 && (groupsIncluded.length) > 0 && <div>
                                    <div>

                                        <div className="mar-b0" style={{ marginTop: "8px" }}>

                                            {(groupsIncluded.length) > 0 && groupsIncluded.map((theGroup, index) => {
                                                return <UILIB.SquareChip
                                                    tooltip={"Group"}
                                                    for={theGroup.id}
                                                    key={"group" + index}
                                                    className={"mar-r10"}
                                                    style={{ marginBottom: "8px" }}
                                                    iconRight={
                                                        <UILIB.Icons icon="cross" style={{ height: 14, width: 14 }} onClick={() => { this.changedAddedGroups(theGroup.id, false, true, true) }} alt="Remove" title="Remove" />
                                                    }>
                                                    {theGroup.name || theGroup.groupName}
                                                </UILIB.SquareChip>
                                            })}

                                        </div>
                                    </div>
                                </div>

                                }


                                <div className='mar-t25'>

                                    {<UILIB.TextInput
                                        label={i18n.t('campaigns:standard.step1.segments')}
                                        placeholder={i18n.t('campaigns:standard.step1.searchSegment')}
                                        onClick={() => {
                                            this.addSegments(true)
                                        }}
                                        readOnly={true}
                                        iconLeft={<UILIB.Icons icon='magnifier' />}
                                        className='cyt-new-campaign-included-seg'
                                    />
                                    }
                                </div>

                                {(totalIncluded) > 0 && (segmentsIncluded.length) > 0 && <div>

                                    <div className="mar-b0" style={{ marginTop: "8px" }}>

                                        {(segmentsIncluded.length) > 0 && segmentsIncluded.map((theGroup, index) => {
                                            return <UILIB.SquareChip
                                                tooltip={"Segment"}
                                                for={theGroup.id}
                                                key={"segment" + index}
                                                className={"mar-r10 square-chip-purple"}
                                                style={{ marginBottom: "8px" }}
                                                iconRight={<Fragment>
                                                    {theGroup.temporary && canEditSegments && <UILIB.Icons icon="pencil" style={{ height: 14, width: 14 }} onClick={() => { this.editSegment(theGroup.id) }} alt="Edit" title="Edit" className="mar-r5" />}
                                                    {!theGroup.temporary && canEditSegments && <UILIB.Icons icon="pencil" style={{ height: 14, width: 14 }} onClick={() => { this.cloneSegment(theGroup) }} alt="Edit" title="Edit" className="mar-r5" />}
                                                    <UILIB.Icons icon="cross" style={{ height: 14, width: 14 }} onClick={() => { this.changedAddedGroups(theGroup.id, false, false, true) }} alt="Remove" title="Remove" />
                                                </Fragment>
                                                }>
                                                {theGroup.name}

                                            </UILIB.SquareChip>

                                        })}

                                    </div>

                                </div>
                                }
                            </div>

                            <div className='mar-t25 mar-b-25'>

                                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                    <UILIB.Divider style={{ flexGrow: 1, borderTop: '1px solid #E5E9EE', margin: '0 8px 0 0' }}></UILIB.Divider>
                                    <span style={{ fontSize: '12px', fontWeight: '500', color: '#7C8086', whiteSpace: 'nowrap' }}>or</span>
                                    <UILIB.Divider style={{ flexGrow: 1, borderTop: '1px solid #E5E9EE', margin: '0 0 0 8px' }}></UILIB.Divider>
                                </div>

                            </div>

                            <div className="mar-t25">
                                <p>{i18n.t('campaigns:standard.step1.addASegment')}</p>
                            </div>

                            <div>
                                <UILIB.Button
                                    className="outline button-sml cyt-add-excl-seg-btn"
                                    iconLeft={<UILIB.Icons icon="plus" />}
                                    onClick={() => this.addTempSegment(this.state.included)}
                                >
                                    {i18n.t('campaigns:standard.step1.addExclusiveSegment')}
                                </UILIB.Button>
                            </div>
                        </UILIB.Paper>

                        <div>
                            <UILIB.Row className='mar-l2 mar-t10' style={{ marginBottom: 24 }}>
                                <div className={totalExcluded ? 'flexSpaceBetween' : "end-xs"} style={{ alignItems: 'center' }}>
                                    <a className='' style={{ alignItems: 'center', display: 'flex' }} >
                                        <UILIB.Toggle checked={this.state.showAdvanced} className='mar-r5 cyt-advance-toggle' onChange={this.showHideAdvanced}
                                        />
                                        {i18n.t('campaigns:standard.step1.advanced')}
                                    </a>
                                </div>
                            </UILIB.Row>
                        </div>




                        <UILIB.Row className={this.state.showAdvanced ? '' : 'display-no mar-b25'}>
                            <UILIB.Column xs={12} sm={12} md={8}>
                                {!!totalExcluded && <UILIB.Chip className="chip-small">{totalExcluded === 1 ? '1 Group or Segment excluded' : totalExcluded + ' Groups or Segments excluded'}</UILIB.Chip>}
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={12} md={8}>
                                {(this.state.count == 0) && (totalIncluded > 0) && !this.state.counting && <UILIB.Paper className="paper-red paper-tight">
                                    {i18n.t('campaigns:standard.step1.errorMsg')}
                                </UILIB.Paper>}
                            </UILIB.Column>
                        </UILIB.Row>

                        <div>
                            <UILIB.Row className={this.state.showAdvanced ? '' : 'display-no mar-b25'}>
                                <UILIB.Column xs={12} sm={12} md={12}>
                                    {
                                        <CampaignAdvancedOptions
                                            totalSubs={totalSubs}
                                            campaign={this.props.campaign}
                                            addGroups={this.addGroups}
                                            addSegments={this.addSegments}
                                            changedAddedGroups={this.changedAddedGroups}
                                            close={() => this.setState({ showAdvanced: false, showGroups: false, showSegments: false })}
                                            countSubs={this.countSubs}
                                            editSegment={this.editSegment}
                                            isSms={isSms}
                                            scrollIntoViewTarget={this.targetAdvance}
                                            showGroups={true}
                                            updateCampaign={this.props.updateCampaign}
                                            saveCampaign={this.props.saveCampaign}
                                            totalExcluded={totalExcluded}
                                            groupsExcluded={groupsExcluded}
                                            segmentsExcluded={segmentsExcluded}
                                            canEditSegments={canEditSegments}
                                        />
                                    }

                                </UILIB.Column>
                            </UILIB.Row>
                        </div>

                    </UILIB.Column>

                    <UILIB.Column xs={12} sm={12} md={4} lg={4}>
                        {isSms && <SmsFieldSelector campaign={this.props.campaign} customFields={this.props.customFields} updateCampaign={this.props.updateCampaign} countSubs={this.countSubs} />}
                        {<UILIB.Paper className={`mar-b25`} style={{ padding: 24 }}>
                            {<div>
                                {this.state.counting && <UILIB.LoadingIcons iconType="2" />}
                                {<h6 className="mar-b20">{i18n.t('campaigns:main.noOfRecipients')}</h6>}
                                {(!this.props.minimum || this.props.minimum <= totalSubs) && <div className="quickFlex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <div className="text-lg2 text-heavy cyt-view-total-subs" >{!!totalIncluded ? NumberFunction.formatNumber(totalSubs) : 0}</div>
                                    {canViewRecipients && <UILIB.Button className='cyt-view-total-btn' disabled={!totalIncluded} text={i18n.t('campaigns:main.viewRecipients')} onClick={this.viewSubs} />}
                                </div>}
                                {!this.state.counting && !!this.props.minimum && this.props.minimum > totalSubs && <UILIB.WarningHolder className="mar-t25">
                                    You are only sending to <strong>{totalSubs}</strong> Subscribers. The minimum required is <strong>{this.props.minimum}</strong>
                                </UILIB.WarningHolder>}

                                {!isSms && !billedOnContacts && this.props.sendingLimitReached && !this.props.newBillingPlan && <UILIB.WarningHolder className="mar-t25">
                                    {i18n.t('campaigns:standard.step3.exceedingLimit')} <a>{i18n.t('campaigns:standard.step3.changeFilters')}</a>
                                    {showBilling && <span>
                                        or <a onClick={() => this.props.history.push('/cp/company/subscription')}>{i18n.t('campaigns:standard.step3.upgradePlan')}</a>
                                    </span>}
                                </UILIB.WarningHolder>}
                                {!isSms && !billedOnContacts && this.props.sendingLimitReached && !!this.props.newBillingPlan && !!this.props.calculatedPrice && <div className="warning-holder mar-t25" dangerouslySetInnerHTML={{ __html: i18n.t('campaigns:standard.step3.exceedingLimitUpgrade', { plan: this.props.newBillingPlan.name, price: this.props.calculatedPrice.symbol + this.props.calculatedPrice.price }) }} />}
                                {!isSms && billedOnContacts && this.props.sendingLimitReached && <UILIB.WarningHolder className="mar-t25">
                                    You have exceeded your contact limit.
                                    {showBilling && <span>
                                        Please <a onClick={() => { this.props.history.push('/cp/company/subscription') }}>upgrade your account</a>
                                    </span>}
                                    {(!showBilling && this.props.siteMaster.supportEmail) && <span>
                                        Please <a href={"mailto:" + this.props.siteMaster.supportEmail}>contact support</a>
                                    </span>}
                                </UILIB.WarningHolder>}

                                {isSms && this.props.sendingLimitReached && <UILIB.WarningHolder className="mar-t25">Exceeding SMS Credits <a onClick={this.topup.bind(this)}>Top Up</a></UILIB.WarningHolder>}
                            </div>}
                        </UILIB.Paper>}
                        {/* {this.props.siteMaster.siteId != 2 && !this.props.siteMaster.listCleanDisabled && !!groupsNotCleaned && !!groupsNotCleaned.length && canEditSubscribers && */}
                        {/* {isSms} */}
                        {this.props.siteMaster.siteId != 2 && !isSms &&
                            <UILIB.Paper style={{ padding: 24, marginBottom: 0, minHeight: 214, position: 'relative' }}>
                                <UILIB.Row>
                                    <UILIB.Column xs={12} margin={0}>
                                        <div className="text-md mar-b10 quickFlex">
                                            {/* <UILIB.Icons className="mar-r5" icon="alert" color="white" style={{ width: "20px", height: "20px" }} /> */}
                                            <h6>List Cleaning</h6>
                                        </div>
                                        <div className="text-sml mar-b15" style={{ color: 'gray', fontSize: 12 }}>The following groups have not been cleaned recently. We recommend cleaning your groups before sending.</div>
                                        <UILIB.Row xs={12} >
                                            {groupsNotCleaned.map(g => {
                                                return <UILIB.Column xs={12} className="" style={{ width: '100%', display: 'flex', paddingBottom: "10px", borderBottom: "1px solid rgba(255,255,255,0.2)", justifyContent: "space-between" }} key={'dirty' + g.id}>
                                                    <div className="text-heavy">{g.groupName || g.name}</div>
                                                    <UILIB.Button iconLeft={<UILIB.Icons icon='sparkle' />} className="button-sml outline" text="Clean" onClick={() => {
                                                        window.open(`/cp/groups/${g.id}/clean`, '_blank');
                                                    }} />
                                                </UILIB.Column>
                                            })}
                                        </UILIB.Row>
                                        {this.props.siteMaster.siteId == 1 && <UILIB.Hint
                                            style={{ margin: '0' }}
                                            href="https://kb.transpond.io/article/113-how-to-clean-your-lists"
                                            iconLeft={<UILIB.Icons icon="questionCircle" style={{ height: 16, width: 16 }} />}>
                                            Find out more about List Cleaning
                                        </UILIB.Hint>
                                        }
                                    </UILIB.Column>
                                </UILIB.Row>
                            </UILIB.Paper>}
                    </UILIB.Column>
                </UILIB.Row>


                {totalIncluded > 0 && showCount && <UILIB.StickyNavBottom>
                    <div className="quickFlex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <UILIB.Button onClick={this.save}>{i18n.t('campaigns:standard.saveExit')}</UILIB.Button>
                        <UILIB.Button iconRight={<UILIB.Icons icon="arrowRight" />} className={submitButtonClass + ' mar-l5'} disabled={submitDisabled} onClick={() => { this.props.changeStep(2, true) }}> {!!isSms ? 'Continue' : i18n.t('campaigns:standard.nextStep')}</UILIB.Button>
                    </div>
                </UILIB.StickyNavBottom>}
                {(this.state.showGroups || this.state.showSegments) && this.state.included && <UILIB.Drawer startClose={this.state.showGroups ? this.hideGroups : this.hideSegments} standalone={true} isOpen={(this.state.showGroups || this.state.showSegments) && this.state.included} width={800} showClose={true} >
                    <AddGroupsPop finish={() => this.setState({ showGroups: false, showSegments: false })} isOpen={(this.state.showGroups || this.state.showSegments) && this.state.included} customFields={this.props.customFields} setCampaign={this.props.updateCampaign} changedAddedGroups={this.changedAddedGroups} history={this.props.history} groups={this.props.groups} segments={this.props.segments} openFor={this.state.openFor} updated={this.props.updated} included={this.state.included} addTempSegment={this.addTempSegment} campaign={this.props.campaign} />
                </UILIB.Drawer>}


                {(this.state.showGroups || this.state.showSegments) && !this.state.included && <UILIB.Drawer standalone={true} isOpen={(this.state.showGroups || this.state.showSegments) && !this.state.included} width={800} showClose={true} startClose={() => this.setState({ showGroups: false, showSegments: false })}>
                    <AddGroupsPop isOpen={(this.state.showGroups || this.state.showSegments) && !this.state.included} finish={() => this.setState({ showGroups: false, showSegments: false })} customFields={this.props.customFields} setCampaign={this.props.updateCampaign} changedAddedGroups={this.changedAddedGroups} history={this.props.history} groups={this.props.groups} segments={this.props.segments} openFor={this.state.openFor} updated={this.props.updated} included={this.state.included} addTempSegment={this.addTempSegment} campaign={this.props.campaign} />
                </UILIB.Drawer>}




            </div >
        }
        else {
            return <div style={{ marginTop: '50px' }}>
                <UILIB.LoadingIcons iconType="2" className="mar-b30" />
            </div>
        }
    }
}
import React from 'react'

export default function IconsXColor({ color = "", style = {} }) {
    let fillColor = "black";
    if (color) fillColor = color;
    let className = "icons-icon";

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} className={className}>
            <g clipPath="url(#clip0_87_3454)">
                <path className="animate-svg" d="M11.9027 8.46864L19.3482 0H17.5838L11.119 7.3532L5.95547 0H0L7.8082 11.1193L0 20H1.76443L8.59152 12.2348L14.0445 20H20L11.9023 8.46864H11.9027ZM9.48608 11.2173L8.69495 10.1101L2.40018 1.29968H5.11025L10.1902 8.40994L10.9813 9.51718L17.5847 18.7594H14.8746L9.48608 11.2177V11.2173Z" fill={fillColor} />
            </g>
            <defs>
                <clipPath id="clip0_87_3454">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
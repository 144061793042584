import React from 'react'

export default class IconsFacebook extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 3C6.13425 3 3 6.13425 3 10C3 13.5093 5.58533 16.4073 8.9535 16.9137V11.855H7.22158V10.0152H8.9535V8.79075C8.9535 6.76367 9.94108 5.87408 11.6258 5.87408C12.4325 5.87408 12.8595 5.93417 13.0613 5.961V7.56692H11.9122C11.197 7.56692 10.9473 8.24533 10.9473 9.0095V10.0152H13.0432L12.7592 11.855H10.9473V16.9283C14.3639 16.4651 17 13.5437 17 10C17 6.13425 13.8657 3 10 3Z" fill={color} />
        </svg>
    }
}        
import React from "react";
import i18n from "i18next";
import classNames from "classnames";
import {
  NOT_SETUP,
  getDomainStatus,
  getDomainFromSubdomain,
} from "~/Classes/domains";
import UILIB from "~/Common-Objects/Lib";
import DomainStatus from "./domainStatus";
import { useHasWriteAccess } from "~/Common-Objects/hooks/permissions";

export default function Subdomain({
  domain,
  domainType,
  headingLevel = 3,
  onAddClick,
  onDeleteClick,
  onAuthenticateClick,
  className,
  ...rest
}) {
  const hasWriteAccess = useHasWriteAccess("domains");
  const domainStatus = getDomainStatus(domain, domainType);

  const HeadingTag = `h${headingLevel}`;

  const classes = classNames("subdomain", className);

  return (
    <div className={classes} {...rest}>
      <div className="subdomain__header">
        <div>
          <div className="subdomain__name">
            <HeadingTag className="text-md fw-medium">
              {domain?.domainName ||
                i18n.t(`company:domains.${domainType.id}.domain`)}
            </HeadingTag>

            {domainStatus !== NOT_SETUP && (
              <UILIB.SquareChip className="capitalize">
                {i18n.t(`company:domains.${domainType.id}.domain`)}
              </UILIB.SquareChip>
            )}
          </div>

          <DomainStatus
            status={domainStatus}
            type={domainType}
            domainName={getDomainFromSubdomain(domain?.domainName)}
          />
        </div>

        <div className="subdomain__primary-ctas">
          {domainStatus === NOT_SETUP && hasWriteAccess && (
            <UILIB.Button
              iconLeft={<UILIB.Icons icon="plus" />}
              className="capitalize"
              onClick={onAddClick}
            >
              {i18n.t(`company:domains.${domainType.id}.add`)}
            </UILIB.Button>
          )}

          {domain?.authenticated === false && (
            <UILIB.Button onClick={onAuthenticateClick}>
              {i18n.t("company:domains.auth")}
            </UILIB.Button>
          )}

          {domainStatus !== NOT_SETUP && hasWriteAccess && (
            <button
              type="button"
              className="subdomain__delete-btn"
              aria-label={i18n.t("company:domains.deleteButton")}
              onClick={onDeleteClick}
            >
              <UILIB.Icons icon="bin" color="#000000" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import UILIB from '../../../../Common-Objects/Lib';
import DateTimeFunctions from '../../../../Classes/dateTimeFunctions';
import { alterSnackbar } from '../../../../data/actions/siteActions';
import axios from '../../../../data/http/axios';

export default function AutomationOptionContact({ AutomationId, contact, history, step, nextStep, refresh = () => { } }) {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [removing, setRemoving] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [skipping, setSkipping] = useState(false)
    const [isSkipping, setIsSkipping] = useState(false)

    const click = e => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(o => !o)
        return false
    }

    const skip = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSkipping(true)
        return false
    }

    const doSkip = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsSkipping(true)
        await axios.put('/automation/' + AutomationId + '/step/' + step.guid, {
            ids: [contact.AutomationQueues[0].id],
            action: 'move',
            destination: nextStep.guid
        })
        refresh()
        clear()
        return false
    }

    const exit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setRemoving(true)
        return false
    }

    const remove = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDeleting(true)
        const res = await axios.delete('/automation/' + AutomationId + '/subscriber/' + contact.id)
        if (!res.data.success) dispatch(alterSnackbar(true, 'Contact already removed from Automation'))
        else dispatch(alterSnackbar(true, 'Contact removed from Automation'))
        refresh()
        clear()
        return false
    }

    const clear = () => {
        setSkipping(false)
        setIsSkipping(false)
        setDeleting(false)
        setRemoving(false)
        setOpen(false)
    }

    const queueItem = contact.AutomationQueues[0]
    const logItem = history[history.length - 1] || { createdAt: queueItem.createdAt }

    const canSkip = (step.type === 'wait' || step.type === 'delay') && nextStep

    return (
        <div className="automationOptionContact" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return false
        }}>
            <UILIB.Icons icon="person" onClick={click} />
            {open && <UILIB.ClickAway enabled={true} onClickAway={() => setOpen(false)}><div className="automationOptionContactDetails">
                {!removing && !skipping && <>
                    <div className="row">
                        <div className="col-xs-6 text-heavy">Date Entered: </div>
                        <div className="col-xs-6">{DateTimeFunctions.formatDateTime(logItem.createdAt)}</div>
                        <div className="col-xs-6 text-heavy">Next Step: </div>
                        <div className="col-xs-6">{DateTimeFunctions.formatDateTime(queueItem.ActionDate)}</div>
                    </div>
                    <div className="quickFlex mar-t10">
                        <UILIB.Button className="button-sml button-primary mar-r10" disabled={!canSkip} onClick={skip}>Skip Step</UILIB.Button>
                        <UILIB.Button className="button-sml button-red" onClick={exit}>Exit Automation</UILIB.Button>
                    </div>
                </>}
                {removing && <div>
                    <div className="mar-b10 text-heavy">Remove Contact from Automation?</div>
                    <div className="mar-b10">They will be able to rejoin later</div>
                    <UILIB.Button disabled={deleting} onClick={remove} className="button-red button-sml mar-r10">Yes, Remove</UILIB.Button>
                    <UILIB.Button disabled={deleting} className="button-sml" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setRemoving(false)
                        return false
                    }}>No, Cancel</UILIB.Button>
                    {deleting && <UILIB.LoadingIcons className="mar-t20" />}
                </div>}
                {skipping && <div>
                    <div className="mar-b10 text-heavy">Move Contact to Next Step?</div>
                    <div className="mar-b10">This will skip any goal checks as well</div>
                    <UILIB.Button disabled={isSkipping} onClick={doSkip} className="button-primary button-sml mar-r10">Yes, Skip</UILIB.Button>
                    <UILIB.Button disabled={isSkipping} className="button-sml" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSkipping(false)
                        return false
                    }}>No, Cancel</UILIB.Button>
                    {isSkipping && <UILIB.LoadingIcons className="mar-t20" />}
                </div>}
            </div></UILIB.ClickAway>}
        </div>
    )
}
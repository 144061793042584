import React from 'react'

export default function IconSurveillance({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.35205 3.99775C3.89268 4.26297 3.55749 4.69981 3.4202 5.21216L1.86729 11.0077C1.73 11.5201 1.80187 12.066 2.06709 12.5254C2.3323 12.9847 2.76914 13.3199 3.2815 13.4572L4.55406 13.7982L3.28208 15.0702C3.09454 15.2577 2.98919 15.5121 2.98919 15.7773C2.98919 16.0425 3.09454 16.2968 3.28208 16.4844C3.46962 16.6719 3.72397 16.7773 3.98919 16.7773C4.2544 16.7773 4.50876 16.6719 4.69629 16.4844L6.78477 14.3959L9.07705 15.0101C9.58941 15.1474 10.1353 15.0755 10.5947 14.8103C11.0541 14.5451 11.3893 14.1083 11.5265 13.5959L13.0795 7.80035C13.2167 7.288 13.1449 6.74208 12.8797 6.28272C12.6144 5.82335 12.1776 5.48815 11.6652 5.35087L5.86969 3.79795C5.35733 3.66066 4.81142 3.73254 4.35205 3.99775Z" fill={color}/>
            <path d="M14.067 8.55619C14.2022 8.42895 14.3702 8.34187 14.5521 8.30469L16.7428 7.8564C16.9097 7.82228 17.0826 7.83138 17.245 7.88284C17.4075 7.93431 17.5541 8.02642 17.6709 8.15044C17.7877 8.27447 17.871 8.42629 17.9126 8.5915C17.9543 8.75672 17.9531 8.92984 17.9091 9.09445L16.3562 14.89C16.312 15.0546 16.2265 15.2051 16.1078 15.3273C15.9891 15.4496 15.8411 15.5395 15.6779 15.5884C15.5147 15.6374 15.3417 15.6439 15.1753 15.6073C15.0089 15.5706 14.8546 15.4921 14.7271 15.379L13.0541 13.8955C12.9151 13.7723 12.8132 13.6129 12.7597 13.4351C12.7063 13.2573 12.7033 13.0682 12.7513 12.8888L13.7866 9.0251C13.8347 8.84579 13.9318 8.68342 14.067 8.55619Z" fill={color}/>
        </svg>
    )
}
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import UILIB from '../../../../Common-Objects/Lib'
import axios from '../../../../data/http/axios'

export default function MergeTagWarnings({ html, id, landingPage = "" }) {
    const site = useSelector(state => state.siteMaster)
    const account = useSelector(state => state.accountMaster)
    const [results, setResults] = useState(null)

    useEffect(() => {
        if (html) {
            init(html)
        }
    }, [html])

    const init = async (html) => {
        if (account?.accountMaster?.options?.unsubscribeTemplateId == id) landingPage = 'unsubscribe'
        if (account?.accountMaster?.options?.updateTemplateId == id) landingPage = 'update'
        const result = (await axios.post('/template/mergeTagsTest?returnAll=true', {
            bodyHtml: html,
            type: landingPage
        })).data
        setResults(result)
    }

    if (!results) return <UILIB.LoadingIcons />

    return (
        <div style={{ position: 'relative' }}>
            {site.siteId === 1 && <UILIB.Hint style={{ position: 'absolute', top: 0, right: 0 }} href="https://kb.transpond.io/article/84-merge-tags" iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>Learn More</UILIB.Hint>}
            <h4 className="mar-b5">Found {results.length} Merge Tags</h4>
            <p className="mar-b25">Remember merge tags are case sensitive</p>
            {results.mergeTags.map((row, index) => {
                let classes = "linkChecker "
                if (row.status === 0) classes += 'red'
                if (row.status === 1) classes += 'green'
                return <div key={index} className={classes}>
                    <div className="linkTextHolder">
                        <div className="linkCheckerText">{row.value}</div>
                        <div className="linkCheckerUrl">{row.description}</div>
                    </div>
                    <div className="error">{row.status === 1 ? 'Valid' : 'Invalid'}</div>
                </div>
            })}
            {!results.success && <>
                {!!results.error && <div className='mar-b10'>There is a problem near: </div>}
                {!!results.error && <UILIB.WarningHolder className="mar-b20">{results.error}</UILIB.WarningHolder>}
                <div className='mar-b10'>The template will not be saved until resolved</div>
            </>}
        </div>
    )
}
import React, { useState } from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import { ChromePicker } from 'react-color'

export default function AccountColours({ colours, setColours = () => { }, className }) {
    const [colorPicker, setColorPicker] = useState([false, false, false, false, false])

    const addColour = () => {
        let _colours = [...colours]
        _colours.push('#ffffff')
        setColours(_colours)

    }

    const updateColour = (c, i) => {
        let _colours = [...colours]
        _colours[i] = c
        setColours(_colours)
    }

    const removeColour = (i) => {
        let _colours = [...colours]
        _colours.splice(i, 1)
        setColours(_colours)
    }

    const showColorPicker = (i) => {
        let arr = [...colorPicker]
        arr[i] = true
        console.log(arr)
        setColorPicker(arr)
    }

    const hideColorPicker = (i) => {
        let arr = [...colorPicker]
        arr[i] = false
        setColorPicker(arr)
    }

    const padding = new Array(5 - colours.length).fill(1)

    const popover = {
        position: 'absolute',
        zIndex: '2',
    }

    const editorStyle = {
        default: {
            picker: {
                fontFamily: '"Open Sans", sans-serif'
            }
        }
    }


    return (
        <div className="mar-b15 quickFlex" style={{ alignItems: "center", flexWrap: "wrap" }}>
            {colours.map((c, i) => {

                return <div key={i} onClick={() => showColorPicker(i)} className="color-picker">
                    <div className='color-picker-block' style={{ backgroundColor: c }} />
                    {colorPicker[i] ? <UILIB.ClickAway style={{}} enabled={true} onClickAway={() => hideColorPicker(i)}>
                        <div style={popover}>
                            <ChromePicker onChange={e => updateColour(e.hex, i)} color={c} disableAlpha={true} styles={editorStyle} />
                        </div>
                    </UILIB.ClickAway> : null}
                    <div style={{ flexGrow: 1 }}>{c}</div>
                    <UILIB.Icons className="vertical-center" icon="bin" onClick={() => removeColour(i)} />

                </div>
            })}


            {(colours.length < 5) && <UILIB.Button iconLeft={<UILIB.Icons icon="plus" />} className="mar-b10" onClick={addColour}>{i18n.t('account:my.addColour')}</UILIB.Button>}

        </div>
    )
}
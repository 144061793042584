import React from 'react'
import { connect } from 'react-redux'
import * as siteMaster from '~/data/actions/siteActions' //now we can use user actions here

@connect((store) => {
    return { siteMaster: store.siteMaster }
})
export default class SnackBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            hide: true
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.message !== prevProps.message || this.props.open !== prevProps.open) {
            this.setState({
                open: this.props.open,
                hide: false
            })
            if (this.props.autoclose) {
                if (this.timer) clearTimeout(this.timer)
                this.timer = setTimeout(() => {
                    this.setState({
                        open: false
                    }, () => {
                        this.props.dismiss()
                        setTimeout(() => {
                            this.setState({
                                hide: true
                            })
                        }, 300)
                    })
                }, this.props.autoCloseDuration || 2000)
            }
        }

        if (this.props.global) {
            if (this.props.siteMaster.snackbar) {
                const snackbar = this.props.siteMaster.snackbar
                if (
                    (snackbar.message && !prevProps.siteMaster.snackbar) ||
                    (snackbar.message && prevProps.siteMaster.snackbar && prevProps.siteMaster.snackbar.message !== snackbar.message) ||
                    (snackbar.open && !prevProps.siteMaster.snackbar) ||
                    (snackbar.open && prevProps.siteMaster.snackbar && prevProps.siteMaster.snackbar.open !== snackbar.open)
                ) {
                    this.setState({ hide: false })
                    this.timer = setTimeout(() => {
                        this.setState({
                            open: false
                        }, () => {
                            this.props.dispatch(siteMaster.alterSnackbar(false, ''))
                            setTimeout(() => {
                                this.setState({
                                    hide: true
                                })
                            }, 300)
                        })
                    }, snackbar.autoCloseDuration || 2000)
                }
            }

        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    render() {
        let message = this.props.message
        var classes = "snackbar";
        if (!this.props.global) {
            if (this.state.open) {
                classes += " show"
            }
            if (this.state.hide) {
                classes += " hide"
            }
        }
        else {
            const snackbar = this.props.siteMaster.snackbar
            if (snackbar) {
                if (snackbar.open) {
                    classes += ' show'
                }
                if (this.state.hide) {
                    classes += ' hide'
                }
                message = snackbar.message
            }
        }

        return <div onClick={this.props.dismiss} className={classes}>{message}</div>
    }

}
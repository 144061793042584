import React, { useState, useEffect } from "react";
import UILIB from "~/Common-Objects/Lib";
import Dialog from "../../../components/dialog";
import Button from "../../../components/button";
import TextInput from "../../../components/textInput";
import * as styles from "./index.module.scss";
import i18n from '~/i18n'
const types = ['text', 'textarea', 'select', 'number', 'date', 'boolean', 'url']

export default function AddEditFieldDialog({
	fieldToEdit,
	isOpen,
	onSubmit,
	...rest
}) {
	const [name, setName] = useState(fieldToEdit ? fieldToEdit.name : "");
	const [type, setType] = useState(fieldToEdit ? fieldToEdit.type : "text");
	const [options, setOptions] = useState(fieldToEdit ? fieldToEdit.options : []);
	const [agentSpecific, setAgentSpecific] = useState(fieldToEdit ? fieldToEdit.agentSpecific : false);
	const [required, setRequired] = useState(fieldToEdit ? fieldToEdit.required : false);
	const [submitting, setSubmitting] = useState(false);
	const [formErrors, setFormErrors] = useState(null);

	useEffect(() => {
		if (isOpen) {
			if (fieldToEdit) {
				setName(fieldToEdit.name);
				setType(fieldToEdit.type);
				setOptions(fieldToEdit.options);
				setAgentSpecific(fieldToEdit.agentSpecific);
				setRequired(fieldToEdit.required);
			} else {
				setName("");
				setType("text");
				setOptions([]);
				setAgentSpecific(false);
				setRequired(false);
			}
		}
	}, [isOpen]);

	async function handleSubmit() {
		setSubmitting(true);

		try {
			const hasErrors = await validateForm();
			if (hasErrors) return;
			await onSubmit(name, type, options, agentSpecific, required);
		} finally {
			setSubmitting(false);
		}
	}

	async function validateForm() {
		const errors = {}
		let hasErrors = false

		if (!name || name.trim().length < 3) {
			hasErrors = true
			errors.name = "Name must be at least 3 characters long."
		}

		if (type === 'select' && !options?.length) {
			hasErrors = true
			errors.options = "Select fields must have options."
		} else if (type === 'select') {
			if (options.some(o => !o.value || !o.value.trim())) {
				hasErrors = true
				errors.options = "All options must have a value."
			}

			const values = options.map(o => o.value)
			if (Array.from(new Set(values)).length !== values.length) {
				hasErrors = true
				errors.options = "Options must have unique values."
			}
		}

		if (hasErrors) {
			setFormErrors(errors)
		} else {
			setFormErrors(null)
		}
		return hasErrors
	}

	return (
		<Dialog
			fullScreen
			open={isOpen}
			title={`${fieldToEdit ? `Edit` : `Add`} Field`}
			description={
				fieldToEdit
					? "Edit this custom field."
					: "Add a custom field to conversations for agents to fill in."
			}
			submitting={submitting}
			{...rest}
		>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleSubmit();
				}}
			>


				<TextInput
					label="Field Name"
					value={name}
					aria-label="Field Name"
					onChange={e => setName(e.target.value)}
					error={formErrors?.name}
					className="mar-b24"
				/>

				<UILIB.SelectNew.Root
					className="mar-b24"
					value={type}
					error={formErrors?.type}
					onValueChange={(val) => setType(val)}
				>
					<UILIB.SelectNew.Trigger
						fullWidth
						label="Field Type"
						placeholder="Select Type…"
						disabled={!!fieldToEdit}
					/>

					<UILIB.SelectNew.Content fullWidth>
						<UILIB.SelectNew.Viewport>
							{types
								.map((type) => {
									return (
										<UILIB.SelectNew.Item
											key={type}
											value={type}
										>
											{i18n.t("chat:settings.fields.types." + type)}
										</UILIB.SelectNew.Item>
									)
								})}
						</UILIB.SelectNew.Viewport>
					</UILIB.SelectNew.Content>
				</UILIB.SelectNew.Root>

				{type === 'select' && (
					<Options options={options} setOptions={setOptions} formErrors={formErrors} />
				)}

				<UILIB.Toggle
					after="Agent Specific"
					afterSecondary="Value is independant to each user"
					checked={agentSpecific}
					onChange={() => setAgentSpecific(r => !r)}
					outerClassName="mar-b24" />

				{/* <UILIB.CheckBox
          checked={required}
          onChange={() => setRequired(r => !r)}
          outerClassName="mar-b24"
        >
          Required before closing chat
        </UILIB.CheckBox> */}

				<div className={styles.footer}>
					<Button type="submit" size="s" loading={submitting}>
						{`${fieldToEdit ? `Save` : `Add`} Field`}
					</Button>
				</div>
			</form>
		</Dialog>
	);
}


function Options({ options, formErrors, setOptions }) {
	const [draggedIndex, setDraggedIndex] = useState(null);
	const [dragging, setDragging] = useState(false);
	const [touched, setTouched] = useState({})

	const handleDragStart = (index) => {
		setDraggedIndex(index);
		setDragging(true);
	};

	const handleDragOver = (index) => {
		if (draggedIndex === index) return;

		const newOptions = [...options];
		const [draggedItem] = newOptions.splice(draggedIndex, 1);
		newOptions.splice(index, 0, draggedItem);

		setDraggedIndex(index);
		setOptions(newOptions);
	};

	const handleDrop = () => {
		setDraggedIndex(null);
		setDragging(false);
	};

	const addOption = () => {
		setOptions([...options, { label: '', value: '' }])
	}

	const editValue = (i, e) => {
		const rows = [...options]
		const row = rows[i]
		const name = e.target.name
		const value = e.target.value
		if (name === 'label' && !touched[i + 'value'] && (!row.value?.length || row.value === row.label)) row.value = value
		if (name === 'value' && !touched[i + 'label'] && (!row.label?.length || row.label === row.value)) row.label = value
		row[name] = value
		setOptions(rows)
	}

	return (
		<>
			<label className="mar-b8" style={{ display: 'block' }}>Options</label>
			{
				(options || []).map((row, i) => <div
					className="quickFlex mar-b8"
					style={{
						gap: 8,
						alignItems: i === 0 ? 'flex-end' : 'center',
						backgroundColor: dragging && draggedIndex === i ? '#f0f0f0' : 'transparent',
						border: dragging && draggedIndex === i ? '1px dashed #ccc' : 'none'
					}}
					key={i}
					onDragOver={(e) => {
						e.preventDefault();
						handleDragOver(i);
					}}
					onDrop={handleDrop}
				>
					<div
						style={{ cursor: 'grab' }}
						draggable
						onDragStart={() => handleDragStart(i)}
						onDragEnd={handleDrop}
					>
						<UILIB.Icons icon="drag" color="var(--grey-600)" style={{ display: 'flex', alignItems: 'center', marginBottom: i === 0 ? 4 : 0 }} />
					</div>
					{/* <TextInput
						label={!i && "Label"}
						value={row.label}
						aria-label="Label"
						name="label"
						onClick={() => setTouched({ ...touched, [i + 'label']: true })}
						outerStyle={{ flexGrow: 1 }}
						onChange={e => {
							editValue(i, e)
						}}
					/> */}
					<TextInput
						// label={!i && "Value"}
						value={row.value}
						aria-label="Value"
						name="value"
						onClick={() => setTouched({ ...touched, [i + 'value']: true })}
						outerStyle={{ flexGrow: 1 }}
						onChange={e => {
							editValue(i, e)
						}}
					/>
					{options.length > 1 && <Button size="s" onClick={() => {
						const newOptions = [...options]
						newOptions.splice(i, 1)
						setOptions(newOptions)
					}}>Remove</Button>}
				</div>)
			}
			<Button size="s" variant="secondary" className={formErrors?.options ? "mar-b8 mar-t20" : "mar-b24 mar-t20"} onClick={addOption}>Add Option</Button >
			{formErrors?.options && <div className="mar-b24" style={{ color: 'red' }}>{formErrors.options}</div>
			}
		</>
	)
}
import React from 'react'
import { Switch, Route } from 'react-router-dom';
import PermissionRouter from '~/Common-Objects/Router/permissionRoute'

import InboxPage from '~/pages/cp/conversations/inbox';
import InboxSettingsPage from "~/pages/cp/conversations/inboxSettings";
import InboxChannelPage from "~/pages/cp/conversations/channel";
import InboxAiAssistantPage from "~/pages/cp/conversations/aiAssistant";
import ConnectEmailChannelPage from "~/pages/cp/conversations/connect/email";
import ConnectWebchatChannelPage from "~/pages/cp/conversations/connect/webchat";
import ConnectFacebookMessengerChannelPage from '~/pages/cp/conversations/connect/facebookmessenger';
import ConversationsAiSetup from "~/pages/cp/conversations/inboxSettings/aiAssistants/wizard";
import UserPreferences from "~/pages/cp/conversations/userPreferences";
import axios from "~/data/http/axios";

export default class ConversationsRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            inboxUser: {},
            inboxId: -1
        }
        this.getUser = this.getUser.bind(this);
        this.updateInboxUser = this.updateInboxUser.bind(this);
    }

    componentDidMount() {
        let inboxId = -1;
        if (this.props.match.params.activeInboxId) {
            inboxId = this.props.match.params.activeInboxId;
        }
        if (this.props.match.params.inboxId) {
            inboxId = this.props.match.params.inboxId;
        }
        this.setState({ inboxId: inboxId }, () => {
            if (inboxId > 0) {
                this.getUser(inboxId);
            }
            else {
                this.setState({ loading: false })
            }
        });
    }

    async getUser() {
        try {
            let resp = await axios.get('/inboxes/' + this.state.inboxId + '/user/me');
            this.setState({ inboxUser: resp.data, loading: false });
        }
        catch (err) {
            window.open('/cp', '_self');
        }
    }

    async updateInboxUser(inboxUserData) {
        try {
            if (!inboxUserData) return;
            const newUser = await axios.put('/inboxes/' + this.state.inboxId + '/user/me', inboxUserData)
            this.getUser();
        }
        catch (err) {
            console.log(err);
        }
    }

    render() {

        if (this.state.loading) {
            return <div></div>
        }
        return (
            <div>
                <Switch>
                    <Route exact path="/cp/conversations/:inboxId/preferences" component={(props) => <UserPreferences inboxUser={this.state.inboxUser} updateInboxUser={this.updateInboxUser} {...props} />} />
                    <PermissionRouter permissionName="company" method="read" exact path="/cp/conversations/inbox/:activeInboxId?/:activeConversationId?" render={(props) => <InboxPage userRole={this.state.inboxUser?.role || 0} {...props} />} />
                    <PermissionRouter permissionName="company" method="read" exact path="/cp/conversations/:inboxId/ai-assistants/setup" render={(props) => <ConversationsAiSetup userRole={this.state.inboxUser?.role || 0} {...props} />} />
                    <PermissionRouter permissionName="company" method="read" exact path="/cp/conversations/:inboxId/channels/connect/email" render={(props) => <ConnectEmailChannelPage userRole={this.state.inboxUser?.role || 0} {...props} />} />
                    <PermissionRouter permissionName="company" method="read" exact path="/cp/conversations/:inboxId/channels/connect/webchat" render={(props) => <ConnectWebchatChannelPage userRole={this.state.inboxUser?.role || 0} {...props} />} />
                    <PermissionRouter permissionName="company" method="read" exact path="/cp/conversations/:inboxId/channels/connect/facebookmessenger" render={(props) => <ConnectFacebookMessengerChannelPage userRole={this.state.inboxUser?.role || 0} {...props} />} />
                    <PermissionRouter permissionName="company" method="read" path="/cp/conversations/:inboxId/channels/:channelId" render={(props) => <InboxChannelPage userRole={this.state.inboxUser?.role || 0} {...props} />} />
                    <PermissionRouter permissionName="company" method="read" path="/cp/conversations/:inboxId/ai-assistants/:assistantId" render={(props) => <InboxAiAssistantPage userRole={this.state.inboxUser?.role || 0} {...props} />} />
                    <PermissionRouter permissionName="company" method="read" path="/cp/conversations/:inboxId" render={(props) => <InboxSettingsPage userRole={this.state.inboxUser?.role || 0} {...props} />} />
                </Switch >
            </div >
        );
    }
}
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid
} from 'recharts';
import UILIB from '../../../../Common-Objects/Lib';
import axios from '../../../../data/http/axios';
import moment from 'moment';
import i18n from '~/i18n';
import axios2 from 'axios'
import NoData from './noDataChart';

export default function FormChart({ edit = () => { } }) {
    const [filters, setFilters] = useState({
        groupBy: 1,
        startDate: moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
    })
    const [forms, setForms] = useState(null)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const siteMaster = useSelector(state => state.siteMaster)

    const groupOptions = [{
        value: 'HOUR',
        label: i18n.t('campaigns:main.chart.24 hours'),
        color: 'purple',
        format: 'HH:mm'
    }, {
        value: 'DAYOFWEEK',
        label: i18n.t('campaigns:main.chart.week'),
        color: 'red',
        format: 'ddd'
    }, {
        value: 'MONTH',
        label: i18n.t('campaigns:main.chart.month'),
        color: 'green',
        format: 'MM-DD'
    }, {
        value: 'YEAR',
        label: i18n.t('campaigns:main.chart.year'),
        color: 'blue',
        format: 'MMM'
    }]

    const getData = async () => {
        setLoading(true)
        const results = await axios.get(`/group/0/form/0/graph?signupsonly=true&group=${groupOptions[filters.groupBy].value}&start=${filters.startDate}&end=${filters.endDate}`)
        const graphData = []
        results.data.Stats.forEach(row => {
            graphData.push({
                date: moment(row.str).format(groupOptions[filters.groupBy].format),
                opens: row.value.opens,
                signups: row.value.signups
            })
        })
        setData(graphData)
        setLoading(false)
    }

    const changeTimePeriod = (index) => {
        const fil = { ...filters }
        fil.groupBy = index
        let startDate = moment().startOf('day');;
        let endDate = moment().endOf('day')
        switch (index) {
            case 0:
                startDate.subtract(23, 'hours');
                break;
            case 1:
                startDate.subtract(1, 'week')
                break;
            case 2:
                startDate.subtract(1, 'month')
                break;
            case 3:
                startDate.subtract(11, 'months')
                break;
        }
        fil.startDate = startDate.format('YYYY-MM-DD HH:mm:ss')
        fil.endDate = endDate.format('YYYY-MM-DD HH:mm:ss')
        setFilters(fil)
    }

    useEffect(() => {
        getData()
    }, [filters])

    if (!data) return <UILIB.LoadingIcons />

    const noData = !loading && !data.some(d => d.opens || d.signups)

    return <UILIB.Paper isLoading={loading} iconRight={<UILIB.Icons icon="pencil" color="grey" />} iconRightClick={edit}>

        <div className="quickFlex" style={{ justifyContent: "space-between", alignItems: "initial" }}>
            <div>
                <h4 className="mar-b5">Form Stats</h4>
                {!noData && <div className="quickFlex">
                    <div className="dot dot-primary mar-r5" />
                    <div className="mar-r10">Impressions</div>
                    <div className="dot dot-secondary mar-r5" />
                    <div>Signups</div>
                </div>}
            </div>
            <div>
                {groupOptions.map((theOpt, index) => {
                    let selected = false;
                    if (index === filters.groupBy) selected = true;
                    let label = i18n.t('campaigns:main.chart.' + theOpt.label);
                    return <UILIB.ButtonSimple key={"gt_" + index} className={"mar-l5"} selected={selected} onClick={() => changeTimePeriod(index)}>
                        {label}
                    </UILIB.ButtonSimple>
                })}
            </div>
        </div>

        {noData && <NoData primary={siteMaster.colours.$primary} secondary={siteMaster.colours.$secondary} text="noFormData" />}
        {!noData && <ResponsiveContainer height={300}>
            <AreaChart data={data} margin={{ top: 10, right: 0, left: 20, bottom: 0 }}>

                <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                <YAxis axisLine={false} hide={true} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                <Tooltip content={<UILIB.CustomTooltip name={['Impressions', 'Signups']} />} />
                <defs>
                    <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={siteMaster.colours.$primary} stopOpacity={0.3} />
                        <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                    </linearGradient>
                    <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={siteMaster.colours.$secondary} stopOpacity={0.3} />
                        <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                    </linearGradient>
                </defs>
                <CartesianGrid horizontal={false} strokeDasharray="2 2" stroke="#DADADC" />
                <Area
                    type="monotone"
                    dot={false}
                    name="Impressions"
                    dataKey="opens"
                    stroke={siteMaster.colours.$primary}
                    strokeWidth={2}
                    fillOpacity={1}
                    fill="url(#colorUv1)"
                    activeDot={{ stroke: siteMaster.colours.$primary, strokeWidth: 2, fill: 'white' }}
                />
                <Area type="monotone"
                    dot={false}
                    name="Signups"
                    dataKey="signups"
                    stroke={siteMaster.colours.$secondary}
                    strokeWidth={2}
                    fillOpacity={1}
                    fill="url(#colorUv2)"
                    activeDot={{ stroke: siteMaster.colours.$secondary, strokeWidth: 2, fill: 'white' }}
                />
            </AreaChart>

        </ResponsiveContainer>}
    </UILIB.Paper>

}
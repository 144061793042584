import React, { Component } from 'react';

import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib';
import i18n from '~/i18n';
@connect((store) => {
    return { siteMaster: store.siteMaster }
})
class popPreviewForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: '100%'
        }
        this.changeView = this.changeView.bind(this)
    }

    changeView(view) {
        var views = ['100%', '768px', '480px']
        this.setState({
            width: views[view]
        })
    }

    render() {
        return (
            <UILIB.Row className="emailPreviewHolder" margin={0}>
                <UILIB.Column xs={12} sm={6} className="mar-b25">
                    <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-r5"
                        selected={this.state.width === "100%" && "selected"}
                        onClick={() => { this.changeView(0) }}>
                        <UILIB.Icons icon="pc" />
                    </UILIB.ButtonSimple>

                    <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-r5"
                        selected={this.state.width === "768px" && "selected"}
                        onClick={() => { this.changeView(1) }}>
                        <UILIB.Icons icon="tablet" />
                    </UILIB.ButtonSimple>

                    <UILIB.ButtonSimple
                        className="button-simple-fullsize "
                        selected={this.state.width === "480px" && "selected"}
                        onClick={() => { this.changeView(2) }}>
                        <UILIB.Icons icon="mobile" />
                    </UILIB.ButtonSimple>

                </UILIB.Column>


                <UILIB.Column xs={12} margin={0} className="mar-b0">
                    <div className="innerPreviewHolder" style={{ maxWidth: this.state.width, margin: "0 auto" }}>
                        <iframe
                            width="100%" height="100%" style={{ border: "0" }} src={'https://' + this.props.siteMaster.trackingDomain + '/form?fid=' + this.props.FormId + '&am=' + this.props.AccountMasterId + '&host=true'}
                        />
                    </div >
                </UILIB.Column >
            </UILIB.Row>



        );
    }
}

export default popPreviewForm;
import store from '../data/store/store'
var connProps = {
    uiVersion: 3.2,
    i18nVersion: '1.0.20',
    socketServer: "https://sockets.ourmailsender.com/",
    supportSocketServer: "https://api.ourmailsender.com",
    uiSocketServer: "https://api.ourmailsender.com",
    // getConnString: function () {
    //     if (window.location.host.indexOf('test.web.') === 0) {
    //         return 'https://test.api.mpzmail.com'
    //     } else if (window.location.host.indexOf('dev.web.') === 0) {
    //         return 'https://dev.api.ourmailsender.com'
    //     } else if (window.location.host.indexOf('web.capsule.mpztesting') === 0) {
    //         return 'https://api.capsule.mpztesting.com'
    //     } else if (window.location.host.indexOf('web.staging.mpztesting.com') === 0) {
    //         return 'https://api.staging.dcloud.mpztesting.com'
    //     } else if (window.location.host.indexOf('web.staging.dcloud.mpztesting.com') === 0) {
    //         return 'https://api.staging.dcloud.mpztesting.com'
    //     } else if (window.location.host.indexOf('master.web.staging.mpztesting.com') === 0) {
    //         return 'https://api.staging.dcloud.mpztesting.com'
    //     } else if (window.location.host.indexOf('d3j9dkdd5jo30y.amplifyapp.com') >= 0) {
    //         return 'https://api.staging.dcloud.mpztesting.com'
    //     } else if (window.location.host.indexOf('8080') === window.location.host.length - 4) {
    //         return 'http://localhost:8081'
    //     } else if (window.location.host.indexOf('8082') === window.location.host.length - 4) {
    //         return 'http://localhost:8081'
    //     } else {
    //         let result = 'https://api.ourmailsender.com'
    //         const state = store.getState()
    //         if (state.accountMaster?.id === 1330 || state.accountMaster?.id === 6) {
    //             return '/apiv1'
    //         } else if (state.siteMaster && state.siteMaster.trackingDomain) {
    //             result = 'https://' + state.siteMaster.trackingDomain
    //         }
    //         return result
    //     }
    // },
    getConnString: function () {
        if (window.location.host.indexOf('8080') === window.location.host.length - 4) {
            return 'http://localhost:8081'
        } else if (window.location.host.indexOf('8082') === window.location.host.length - 4) {
            return 'http://localhost:8081'
        } else {
            return '/apiv1'
        }
    },
    stripeKey: 'pk_live_P8zaZdJG6V76fqXkta2aT7b5',
    gtmKey: ""
};

if (window.location.host.indexOf('test.web.') === 0) {
    connProps.connString = 'https://test.api.mpzmail.com'
    connProps.repoEndPoint = "http://test.api.mpzmail.com:1236/"
    connProps.stripeKey = 'pk_test_KfFlIoGlrZFQdgtvT4o6wmND'
    connProps.supportSocketServer = 'https://test.api.mpzmail.com'
    connProps.uiSocketServer = "https://test.api.mpzmail.com"
} else if (window.location.host.indexOf('dev.web.') === 0) {
    connProps.connString = 'https://dev.api.ourmailsender.com'
    connProps.repoEndPoint = "https://cdn1.ourmailsender.com/"
    connProps.stripeKey = 'pk_test_KfFlIoGlrZFQdgtvT4o6wmND'
    connProps.supportSocketServer = 'https://dev.api.ourmailsender.com'
    connProps.uiSocketServer = "https://dev.api.ourmailsender.com"
} else if (window.location.host.indexOf('web.capsule.mpztesting') === 0) {
    connProps.connString = 'https://api.capsule.mpztesting.com'
    connProps.repoEndPoint = "https://api.capsule.mpztesting.com:1236/"
    connProps.stripeKey = 'pk_test_KfFlIoGlrZFQdgtvT4o6wmND'
    connProps.supportSocketServer = 'https://api.capsule.mpztesting.com'
    connProps.uiSocketServer = "hhttps://api.capsule.mpztesting.com"
} else if (window.location.host.indexOf('web.staging.mpztesting.com') === 0) {
    connProps.connString = 'https://api.staging.dcloud.mpztesting.com'
    connProps.repoEndPoint = "https://cdn2.staging.dcloud.mpztesting.com/"
    connProps.stripeKey = 'pk_test_KfFlIoGlrZFQdgtvT4o6wmND'
    connProps.supportSocketServer = 'https://api.staging.dcloud.mpztesting.com'
    connProps.uiSocketServer = "https://api.capsule.mpztesting.com"
    connProps.apm = "/apm"
} else if (window.location.host.indexOf('web.staging.dcloud.mpztesting.com') === 0) {
    connProps.connString = 'https://api.staging.dcloud.mpztesting.com'
    connProps.repoEndPoint = "https://cdn2.staging.dcloud.mpztesting.com/"
    connProps.stripeKey = 'pk_test_KfFlIoGlrZFQdgtvT4o6wmND'
    connProps.supportSocketServer = 'https://api.staging.dcloud.mpztesting.com'
    connProps.uiSocketServer = 'https://api.staging.dcloud.mpztesting.com'
    connProps.apm = "/apm"
} else if (window.location.host.indexOf('master.web.staging.mpztesting.com') === 0) {
    connProps.connString = 'https://api.staging.dcloud.mpztesting.com'
    connProps.repoEndPoint = "https://cdn2.staging.dcloud.mpztesting.com/"
    connProps.stripeKey = 'pk_test_KfFlIoGlrZFQdgtvT4o6wmND'
    connProps.supportSocketServer = 'https://api.staging.dcloud.mpztesting.com'
    connProps.uiSocketServer = 'https://api.staging.dcloud.mpztesting.com'
} else if (window.location.host.indexOf('d3j9dkdd5jo30y.amplifyapp.com') >= 0) {
    connProps.connString = 'https://api.staging.dcloud.mpztesting.com'
    connProps.repoEndPoint = "https://cdn2.staging.dcloud.mpztesting.com/"
    connProps.stripeKey = 'pk_test_KfFlIoGlrZFQdgtvT4o6wmND'
    connProps.supportSocketServer = 'https://api.staging.dcloud.mpztesting.com'
    connProps.uiSocketServer = 'https://api.staging.dcloud.mpztesting.com'
} else if (window.location.host.indexOf('8080') === window.location.host.length - 4) {
    connProps.connString = 'http://localhost:8081'
    connProps.repoEndPoint = "http://localhost:1234/"
    connProps.stripeKey = 'pk_test_KfFlIoGlrZFQdgtvT4o6wmND'
    connProps.supportSocketServer = 'http://localhost:3000'
    connProps.uiSocketServer = 'http://localhost:3500'
    connProps.apm = "http://localhost:8200"
} else if (window.location.host.indexOf('8082') === window.location.host.length - 4) {
    connProps.connString = 'http://localhost:8081'
    connProps.repoEndPoint = "https://cdn1.ourmailsender.com/"
    connProps.stripeKey = 'pk_test_KfFlIoGlrZFQdgtvT4o6wmND'
    connProps.supportSocketServer = 'http://localhost:3000'
    connProps.uiSocketServer = 'http://localhost:3500'
} else {
    connProps.connString = 'https://api.ourmailsender.com';
    connProps.repoEndPoint = "https://cdn1.ourmailsender.com/"
    connProps.gtmKey = 'GTM-W7KPSXK'
    connProps.supportSocketServer = 'https://api.ourmailsender.com';
    connProps.uiSocketServer = 'https://api.ourmailsender.com'
}

if (window.location.host.indexOf('app.transpond.io') >= 0 && !connProps.apm) {
    connProps.apm = "/apm"
}

export default connProps
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DpUci1CLVN1LW5tGEDD7{border-radius:8px}.ahFC1BBbnBb9sI1et_HZ{color:var(--grey-950)}.ahFC1BBbnBb9sI1et_HZ svg{color:var(--grey-800)}.AXOwqDhTy0LKYAFAAiPx.rkJhI6iWubAboZc2M8xo{color:var(--grey-800)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/inbox/components/shared/sendButton/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,qBAAA,CAEA,0BACE,qBAAA,CAIJ,2CACE,qBAAA","sourcesContent":[".root {\n  border-radius: 8px;\n}\n\n.rootVariantSecondary {\n  color: var(--grey-950);\n\n  svg {\n    color: var(--grey-800);\n  }\n}\n\n.rootVariantGhost.rootIconOnly {\n  color: var(--grey-800);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "DpUci1CLVN1LW5tGEDD7",
	"rootVariantSecondary": "ahFC1BBbnBb9sI1et_HZ",
	"rootVariantGhost": "AXOwqDhTy0LKYAFAAiPx",
	"rootIconOnly": "rkJhI6iWubAboZc2M8xo"
};
export default ___CSS_LOADER_EXPORT___;

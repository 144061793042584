import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { alterSnackbar } from "~/data/actions/siteActions";
import copy from "copy-to-clipboard";
import Header from "../../../components/header";
import Button from '../../../components/button'
import SettingsSection from "../../../components/settingsSection";
import UILIB from '~/Common-Objects/Lib';
import i18n from "~/i18n";

export default function EmailChannelForwarding({
    channel,
    onUpdateChannel,
}) {
    const dispatch = useDispatch();

    const [aliases, setAliases] = useState(channel.settings.aliases || [])
    const [submitting, setSubmitting] = useState(false)

    const updateAlias = (index, alias) => {
        const arr = [...aliases]
        arr[index] = alias
        setAliases(arr)
    }

    const remove = (index) => {
        const arr = [...aliases]
        arr.splice(index, 1)
        setAliases(arr)
    }

    function copyToClipboard(string) {
        copy(string);
        dispatch(alterSnackbar(true, "Copied to clipboard"));
    }

    async function saveAliases() {
        setSubmitting(true);
        try {
            await onUpdateChannel({ ...channel, settings: { ...channel.settings, aliases } });
            dispatch(alterSnackbar(true, i18n.t("chat:channel.email.forwarding.saved")));
        } catch (error) {
            dispatch(handleGenericError(error));
        } finally {
            setSubmitting(false);
        }
    }

    const domain = channel.settings.replyAddress.split('@')[1]
    const forwardToEmail = `${channel?.AccountMasterId}_${channel?.id}@mailin.mpzemail.com`

    return (
        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
            <Header type="email" option="forwarding" />
            <SettingsSection heading={i18n.t("chat:channel.email.forwarding.address")} subheading={i18n.t("chat:channel.email.forwarding.addressDesc", { address: channel.settings.replyAddress })}>
                <UILIB.TextInput
                    style={{ width: "100%" }}
                    outerStyle={{ width: "100%" }}
                    value={forwardToEmail} readOnly iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => copyToClipboard(forwardToEmail)} />} />
            </SettingsSection>
            <hr></hr>
            <SettingsSection heading={i18n.t("chat:channel.email.forwarding.alias")} subheading={i18n.t("chat:channel.email.forwarding.aliasDesc", { address: channel.settings.replyAddress })}>

            </SettingsSection>
            <div>
                {aliases.map((alias, index) => <Alias key={index} index={index} alias={alias} domain={domain} setAlias={updateAlias} onRemove={remove} />)}
                <Button size="s" iconLeft={<UILIB.Icon name="plus" />} variant="secondary" onClick={() => setAliases([...aliases, { email: channel.settings.replyAddress.split('@')[0], name: "" }])}>{i18n.t("chat:channel.email.alias.add")}</Button>
            </div>
            <div>
                <Button size="s" disabled={submitting} onClick={saveAliases}>{i18n.t("chat:channel.email.alias.save")}</Button>
            </div>

        </div>
    );
}


function Alias({ alias, index, domain, readOnly = false, setAlias = () => { }, onRemove = () => { } }) {

    return (
        <div className="quickFlex" style={{ width: '100%', marginBottom: 8 }}>
            <div style={{ flexGrow: 1, marginRight: 10 }}>
                <UILIB.TextInput disabled={readOnly} value={alias.email} placeholder={i18n.t("chat:channel.email.alias.local")} onChange={e => setAlias(index, { ...alias, email: e.target.value })} />
            </div>
            <div style={{ flexGrow: 1, marginRight: 10 }}>
                <UILIB.TextInput disabled={true} value={"@" + domain} />
            </div>
            <div style={{ flexGrow: 1, marginRight: 10 }}>
                <UILIB.TextInput disabled={readOnly} value={alias.name} placeholder={i18n.t("chat:channel.email.alias.from")} onChange={e => setAlias(index, { ...alias, name: e.target.value })} />
            </div>
            <div>
                <Button iconOnly disabled={readOnly} variant="ghost" onClick={() => onRemove(index)} size="s" aria-label="remove alias">
                    <UILIB.Icon name="trash" />
                </Button>
            </div>
        </div>
    )
}
import React from "react";

export default function IconMessageReadCheck({
  color = "currentColor",
  ...rest
}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3568 5.69129C14.4146 5.61157 14.4562 5.52124 14.4792 5.42543C14.5021 5.32962 14.506 5.23022 14.4906 5.13291C14.4752 5.03559 14.4409 4.94226 14.3894 4.85825C14.338 4.77425 14.2706 4.7012 14.1909 4.64329C14.1113 4.58537 14.0211 4.54373 13.9254 4.52073C13.8297 4.49773 13.7304 4.49382 13.6332 4.50924C13.536 4.52465 13.4428 4.55908 13.3589 4.61057C13.275 4.66205 13.202 4.72957 13.1442 4.80929L9.66533 9.59929L8.56734 8.5L7.67383 9.72642L9.22585 11.2803C9.30238 11.357 9.39461 11.4161 9.49617 11.4537C9.59773 11.4912 9.70622 11.5063 9.81416 11.4978C9.9221 11.4894 10.0269 11.4576 10.1214 11.4047C10.2159 11.3517 10.2978 11.2789 10.3615 11.1913L14.3568 5.69129Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35688 5.69129C9.41473 5.61157 9.45632 5.52124 9.4793 5.42543C9.50227 5.32962 9.50617 5.23022 9.49077 5.13291C9.47538 5.03559 9.44099 4.94226 9.38956 4.85825C9.33814 4.77425 9.2707 4.7012 9.19108 4.64329C9.11146 4.58537 9.02123 4.54373 8.92554 4.52073C8.82985 4.49773 8.73056 4.49382 8.63336 4.50924C8.53616 4.52465 8.44295 4.55908 8.35904 4.61057C8.27513 4.66205 8.20217 4.72957 8.14432 4.80929L4.66546 9.59929L2.78769 7.71929C2.71855 7.64769 2.63586 7.59059 2.54445 7.55133C2.45304 7.51206 2.35473 7.49142 2.25526 7.4906C2.1558 7.48979 2.05716 7.50881 1.96512 7.54656C1.87308 7.58432 1.78946 7.64005 1.71916 7.7105C1.64886 7.78095 1.59327 7.86472 1.55565 7.9569C1.51803 8.04909 1.49912 8.14786 1.50003 8.24745C1.50094 8.34703 1.52165 8.44543 1.56096 8.53692C1.60026 8.6284 1.65737 8.71113 1.72895 8.78029L4.22598 11.2803C4.30251 11.357 4.39474 11.4161 4.4963 11.4537C4.59787 11.4912 4.70635 11.5063 4.81429 11.4978C4.92223 11.4894 5.02705 11.4576 5.12154 11.4047C5.21603 11.3517 5.29794 11.2789 5.36163 11.1913L9.35688 5.69129Z"
        fill={color}
      />
    </svg>
  );
}

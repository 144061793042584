import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import UILIB from '../../../../Common-Objects/Lib'
import axios from '../../../../data/http/axios'
import i18n from '../../../../i18n'
import AddGroup from '../../subscribers/groups/incGoAddGroup'

export default function CreateSubSelectGroup() {
    const [addingGroup, setAddingGroup] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [groups, setGroups] = useState(null)
    const searchTimer = useRef(null)
    const history = useHistory()

    const getGroups = async (text) => {
        let q = '?'
        if (text) q += '&searchText=' + text
        const res = await axios.get('/group/simple' + q)
        setGroups(res.data.Groups)
    }

    const addedGroup = async (res) => {
        history.push('/cp/groups/' + res.data.group.groupId + '/add')
    }

    const selectGroup = (group) => {
        history.push('/cp/groups/' + group.id + '/add')
    }

    useEffect(() => {
        getGroups()
    }, [])

    useEffect(() => {
        if (searchTimer.current) clearTimeout(searchTimer.current)
        searchTimer.current = setTimeout(() => getGroups(searchText), 300)
    }, [searchText])

    if (!groups) return <UILIB.LoadingIcons iconType="2" />
    if (addingGroup) return <AddGroup addedGroup={addedGroup} dontCloseDrawer={true} />

    return (
        <div>
            <h4 className="mar-b25">{i18n.t('campaigns:overview.firstLetsSelectAGroup')}</h4>
            <p className="mar-b25">{i18n.t('campaigns:overview.pleaseSelectOrCreateAGroupWhereNewSubscribers')}.</p>
            <UILIB.ButtonSelect
                outerClassName="mar-b25"
                filter={true}
                autoClose={true}
                updateFilter={t => setSearchText(t)}
                headerText={"..."}
                label={i18n.t('campaigns:overview.selectAnExistingGroup')}
                data={groups.map(group => {
                    return <a key={group.id} onClick={() => selectGroup(group)}>{group.groupName}</a>
                })}
            >
                {/* <UILIB.Row>
                    {groups.map((group) => {
                        return <UILIB.Column xs={12} margin={0} key={group.id}>
                            <div className="highlightable" style={{ padding: '5px' }} onClick={() => selectGroup(group)}>{group.groupName}</div>
                        </UILIB.Column>
                    })}
                </UILIB.Row> */}
            </UILIB.ButtonSelect>

            <UILIB.Button text={i18n.t('campaigns:overview.orCreateANewGroup')} className="button" onClick={() => setAddingGroup(true)} iconRight={<UILIB.Icons icon="plus" />} />
        </div >
    );

}

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import UILIB from '../../../../../Common-Objects/Lib'
import axios from '../../../../../data/http/axios'
import i18n from '../../../../../i18n'
import FileManager from '../../../includes/fileManager/fileManager'
import { alterSiteDrawer } from '../../../../../data/actions/siteActions'
import { SocialImageSelector, SupportedApps, getMeta } from './shared'
import AiContentGenerator from '../../../includes/ai/contentGenerator'
import SmileyPicker from '../../../../../Common-Objects/UI/Forms/TextInput/smileyPicker'

export default function SocialCampaignCreateContent({ campaign, refresh = () => { } }) {
    const history = useHistory()
    const dispatch = useDispatch()
    const [content, setContent] = useState(campaign.Template.templateJson.content)
    const [images, setImages] = useState(campaign.Template.templateJson.images)
    const [originalJson] = useState(JSON.parse(JSON.stringify(campaign.Template.templateJson || {})))

    const saveTemplate = async (next) => {
        let innerHtml = images.length ? `<img src="${images[0].url || images[0]}"/>` : content
        if (!images?.length) {
            // use the social accounts connected as icons instead
            if (campaign.Campaigns.length === 1) {
                const app = SupportedApps.find(a => a.code === campaign.Campaigns[0].options?.AppCode)
                if (app) {
                    innerHtml = `<div style="display: flex; justify-content: center; align-items: center; height: 100%; width: 100%;"><img src="${app.logo}" style="height: 50px; width: 50px; border-radius: 50%; margin-right: 10px;"/><div>${content}</div></div>`
                }
            } else {
                innerHtml = `<div style="display: flex; justify-content: center; align-items: center; height: 100%; width: 100%; position: relative; flex-wrap: wrap;">
                ${campaign.Campaigns.map(c => {
                    const app = SupportedApps.find(a => a.code === c.options?.AppCode)
                    if (app) {
                        return `<img src="${app.logo}" style="width: 50%;"/>`
                    }
                }).join('')}
                </div>`
            }
        }
        const newHtml = `<html style="padding: 0; margin: 0;">
        <body style="height: 100%; width: 100%; display: flex; justify-content: center; margin: 0; padding: 0;">
        ${innerHtml}
        </body>
        </html>`
        const newJson = {
            content,
            images
        }
        await axios.put(`/template/${campaign.Template.id}`, {
            templateJson: newJson,
            templateHTML: newHtml
        })
        if (campaign.Campaigns?.length) {
            for (const childCampaign of campaign.Campaigns) {
                if (JSON.stringify(originalJson) === JSON.stringify(childCampaign.Template.templateJson)) {
                    await axios.put(`/template/${childCampaign.Template.id}`, {
                        templateJson: newJson,
                        templateHTML: newHtml
                    })
                }
            }
        }
        await refresh()
        if (next) history.push(next)
    }

    const openImageSelector = () => {
        const drawerContent = <FileManager fileTypes={"images"} system={false} onFileClick={e => {
            const arr = [...images]
            arr.push(e)
            setImages(arr)
            dispatch(alterSiteDrawer(false, true, 'right', null))
        }} />
        dispatch(alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'fix-height'));
    }

    const removeImage = (index) => {
        const arr = [...images]
        arr.splice(index, 1)
        setImages(arr)
    }

    const editImage = (index) => {
        const image = campaign.Template.templateJson.images[index]
        launchAviary(null, null, async newUrl => {
            const arr = [...images]
            const meta = await getMeta(newUrl)
            arr[index] = { ...arr[index], url: newUrl, imageWidth: meta.width, imageHeight: meta.height }
            setImages(arr)
        }, image.url || image, 'transform')
    }

    const openAi = () => {
        const content = <AiContentGenerator contentType="social post" insertText={text => {
            text = text.replace(/<br\/>/gi, '\n')
            setContent(text)
        }} close={() => dispatch(alterSiteDrawer(false, false, "right", null, true, 700, 'drawer_dark'))} />
        dispatch(alterSiteDrawer(true, false, "right", content, true, 700, 'drawer_dark'))
    }

    const openSmileyPicker = () => {
        var ourEl = document.getElementById("social_content");
        var startPos = ourEl.selectionStart;
        var endPos = ourEl.selectionEnd;
        const drawerContent = <SmileyPicker onPick={theChar => {
            ourEl.focus();
            if (document.selection) {
                sel = document.selection.createRange();
                sel.text = theChar;
            }
            else {
                ourEl.value = ourEl.value.substring(0, startPos) + theChar + ourEl.value.substring(endPos, ourEl.value.length);
            }
            setContent(ourEl.value)
            ourEl.setSelectionRange(startPos + theChar.length, startPos + theChar.length);
        }} />
        dispatch(alterSiteDrawer(true, true, "right", drawerContent, true, "400px"));
    }

    let submitDisabled = false

    return <div>
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                <h4 className="mar-b5">{i18n.t('social:content.header')}</h4>
                <div className="mar-b20 text-grey">{i18n.t('social:content.subheader')}</div>
            </div>
        </div>
        <UILIB.Row>
            <UILIB.Column xs={12} sm={12} md={6} lg={6}>
                <UILIB.TextArea id="social_content" emoji={true} style={{ resize: 'vertical' }} rows="8" outerClassName="mar-b25" label="Text" value={content} ai={true} aiClick={openAi} onChange={e => setContent(e.target.value)} placeholder={i18n.t('social:content.contentPlaceholder')} />
                <div className="txtInput_label">{i18n.t('social:content.media')}</div>
                <div className="quickFlex" style={{ alignItems: 'center' }}>
                    {images.map((image, index) => <SocialImageSelector key={index} image={image} onRemove={() => removeImage(index)} onEdit={() => editImage(index)} />)}
                    {!images.length && <SocialImageSelector onAdd={openImageSelector} />}
                </div>

            </UILIB.Column>
        </UILIB.Row>
        <UILIB.StickyNavBottom>
            <div className="quickFlex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                <div className="quickFlex" style={{ alignItems: 'center' }}>
                    <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} className={'button-primary mar-r10'} onClick={() => saveTemplate(`/cp/campaigns/add/social/${campaign.id}/1`)}>{i18n.t('social:common.back')}</UILIB.Button>
                    <UILIB.Button onClick={() => saveTemplate('/cp/campaigns')}>{i18n.t('campaigns:standard.saveExit')}</UILIB.Button>
                </div>
                <UILIB.Button iconRight={<UILIB.Icons icon="arrowRight" />} className={'button-primary mar-l5'} disabled={submitDisabled} onClick={() => saveTemplate(`/cp/campaigns/add/social/${campaign.id}/3`)}>{i18n.t('campaigns:standard.nextStep')}</UILIB.Button>
            </div>
        </UILIB.StickyNavBottom >
    </div >
}

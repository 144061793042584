import React, { useEffect, useState, useReducer, useRef } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import axios from "~/data/http/axios";
import { handleGenericError, alterSnackbar, alterSiteDrawer, } from "~/data/actions/siteActions";
import UILIB from "~/Common-Objects/Lib";
import Button from "../../../components/button";
import SettingsSection from "../../../components/settingsSection";
import * as styles from "./index.module.scss";
import TextInput from "../../../components/textInput";
import ColorInput from "../../../components/colorInput";
import RadioButton from "../../../components/radioButton";
import FileManager from "~/pages/cp/includes/fileManager/fileManager";
import Header from "../../../components/header";
import SiteVars from '~/Classes/siteVars';
import i18n from "~/i18n";

export default function WebchatChannelDesign({
  inbox,
  channel,
  onUpdateChannel,
}) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [agents, setAgents] = useState([]);
  const [launcherLogo, setLauncherLogo] = useState(channel.settings.launcherLogo);
  const [placement, setPlacement] = useState(channel.settings.widgetAlign);
  const [xPos, setXPos] = useState(channel.settings.xPos || 24);
  const [yPos, setYPos] = useState(channel.settings.yPos || 24);
  const [primaryColor, setPrimaryColor] = useState(channel.settings.primaryColor || "#000000");
  const [visitorMessagesColor, setVisitorMessagesColor] = useState(channel.settings.visitorMessagesColor || "#CCCCCC");
  const [agentMessagesColor, setAgentMessagesColor] = useState(channel.settings.agentMessagesColor || "#FFFFFF");
  const [rnd, setRnd] = useState(0)
  const saveTimeOut = useRef()

  const [initialAgent, updateInitialAgent] = useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    {
      selected: getSelectedInitialAgent(),
      customName: channel.settings.initialUserName,
      customImageSrc: channel.settings.initialAvatarUrl,
    }
  );

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    if (saveTimeOut.current) clearTimeout(saveTimeOut.current);
    saveTimeOut.current = setTimeout(() => {
      handleSubmit()
    }, 500);
  }, [placement, primaryColor, visitorMessagesColor, agentMessagesColor, launcherLogo, initialAgent, xPos, yPos])

  async function loadInitialData() {
    try {
      await loadAgents();
    } finally {
      setLoading(false);
    }
  }

  function getSelectedInitialAgent() {
    if (channel.settings.initialUserType === "user") {
      return channel.settings.initialUserId.toString();
    }

    return "custom";
  }

  async function loadAgents() {
    try {
      const { data } = await axios.get(`/inboxes/${inbox.id}/user`);
      setAgents(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  function openFileManager(callback) {
    const drawer = (
      <FileManager
        onFileClick={({ url }) => {
          callback(url);
        }}
        fileTypes="images"
      />
    );
    dispatch(
      alterSiteDrawer(true, true, "right", drawer, true, "100vw", "fix-height")
    );
  }

  async function handleLauncherLogoUpload(url) {
    setLauncherLogo(url);
    dispatch(alterSiteDrawer(false, true, "right", null, true));
  }

  async function handleInitialAgentCustomImageUpload(url) {
    updateInitialAgent({ customImageSrc: url });
    dispatch(alterSiteDrawer(false, true, "right", null, true));
  }

  async function handleSubmit() {
    setSubmitting(true);

    const data = {
      settings: {
        ...getFormattedInitialAgentData(),
        primaryColor: primaryColor,
        visitorMessagesColor: visitorMessagesColor,
        agentMessagesColor: agentMessagesColor,
        launcherLogo: launcherLogo,
        widgetAlign: placement,
        xPos: xPos,
        yPos: yPos
      },
    };

    try {
      await onUpdateChannel(data);
      dispatch(alterSnackbar(true, i18n.t("chat:channel.chat.design.saved")));
    } catch (error) {
      dispatch(handleGenericError(error));
    } finally {
      setSubmitting(false);
      setRnd(rnd + 1)
    }
  }


  function getFormattedInitialAgentData() {
    if (initialAgent.selected === "custom") {
      return {
        initialUserType: "custom",
        initialUserId: 0,
        initialUserName: initialAgent.customName,
        initialAvatarUrl: initialAgent.customImageSrc,
      };
    }

    const { AccountUser } = getAgent(initialAgent.selected);

    if (!AccountUser) return {
      initialUserType: "custom",
      initialUserId: 0,
      initialUserName: initialAgent.customName,
      initialAvatarUrl: initialAgent.customImageSrc,
    };

    return {
      initialUserType: "user",
      initialUserId: Number(initialAgent.selected),
      initialUserName: AccountUser.fullname,
      initialAvatarUrl: AccountUser.profilePicFileName,
    };
  }

  function getAgent(id) {
    return agents.find((agent) => agent.AccountUserId === Number(id)) || {}
  }

  const themes = [
    ["red", "#DD5C40", "#E7745A", "#E5E9EE"],
    ["orange", "#CD4902", "#DD5B15", "#E5E9EE"],
    ["yellow", "#D69D0C", "#E2AB1E", "#E5E9EE"],
    ["green", "#648030", "#81A240", "#E5E9EE"],
    ["teal", "#257C75", "#319B90", "#E5E9EE"],
    ["blue", "#2879A1", "#3896BF", "#E5E9EE"],
    ["purple", "#7950EA", "#865FF1", "#E5E9EE"],
    ["pink", "#CB5476", "#DB6587", "#E5E9EE"],
  ];

  const initialAgentSelectData = [
    ...agents.map((agent) => {
      const { AccountUser } = agent;

      return { value: AccountUser.id.toString(), label: AccountUser.fullname };
    }),
    { value: "custom", label: i18n.t("chat:channel.chat.design.customUser") },
  ];

  if (loading) return <UILIB.LoadingIcons noClass />;

  const selectedTheme = themes.find(f => f[1] == primaryColor);
  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6">
        <div className="inbox_settings_left_content">
          <Header type="webchat" option="design" />
          <SettingsSection heading={i18n.t("chat:channel.chat.design.theme")} subheading={i18n.t("chat:channel.chat.design.themeDesc")}>
            <div className={styles.themePicker}>
              <div className={styles.themePickerSwatches}>
                {themes.map((theme) => {
                  return (
                    <div key={theme[0]} className={classnames(styles.themePickerSwatch)}>
                      <input
                        className="visually-hidden"
                        type="radio"
                        id={theme[0]}
                        name="color"
                        value={theme[0]}
                        checked={selectedTheme && (selectedTheme[1] === theme[1])}
                        onChange={(e) => {
                          let clickedTheme = themes.find(f => f[0] == e.target.value);
                          setPrimaryColor(clickedTheme[1]);
                          setVisitorMessagesColor(clickedTheme[2]);
                          setAgentMessagesColor(clickedTheme[3]);
                        }
                        }
                      />
                      <label htmlFor={theme[0]} style={{ "--primaryColor-var": theme[1], "--visitorColor-var": theme[2], "--agentColor-var": theme[3] }}>
                        <span className="visually-hidden">{theme[0]}</span>
                      </label>
                    </div>
                  );
                })}

                <RadioButton
                  className={styles.themePickerCustomButtonWrapper}
                  name="color"
                  value="custom"
                  checked={!selectedTheme}
                  onChange={() => {
                    setPrimaryColor("#171819");
                    setVisitorMessagesColor("#37393C");
                    setAgentMessagesColor("#E2E8F0");
                  }}
                >
                  <Button variant="secondary" size="s" iconLeft={<UILIB.Icon name="swatch" />}>{i18n.t("chat:channel.chat.design.custom")}</Button>
                </RadioButton>
              </div>

              {!selectedTheme && (
                <div className={styles.customThemeWrapper}>
                  <ColorInput value={primaryColor} label={i18n.t("chat:channel.chat.design.primary")} placeholder="#171819" onChange={(e) => setPrimaryColor(e.target.value)} />
                  <ColorInput value={visitorMessagesColor} label={i18n.t("chat:channel.chat.design.visitorColor")} placeholder="#37393C" onChange={(e) => setVisitorMessagesColor(e.target.value)} />
                  <ColorInput value={agentMessagesColor} label={i18n.t("chat:channel.chat.design.agentColor")} placeholder="#E2E8F0" onChange={(e) => setAgentMessagesColor(e.target.value)} />
                </div>
              )}
            </div>
          </SettingsSection>
          <hr />

          <SettingsSection heading={i18n.t("chat:channel.chat.design.launcher")} subheading={i18n.t("chat:channel.chat.design.launcherDesc")}>
            <div className={styles.imageUploadWrapper}>
              <div className={styles.imageUpload}>
                <UILIB.AvatarNew src={launcherLogo} size={56} color={primaryColor} />
                <Button variant="secondary" size="s" iconLeft={<UILIB.Icon name="upload" />} onClick={() => openFileManager(handleLauncherLogoUpload)}>{i18n.t("chat:channel.chat.design.launcherUpload")}</Button>
              </div>

              <Button variant="danger" size="s" iconLeft={<UILIB.Icon name="cross-large" />} onClick={() => setLauncherLogo(null)}>{i18n.t("chat:channel.chat.design.launcherRemove")}</Button>
            </div>
          </SettingsSection>
          <hr />

          <SettingsSection
            heading={i18n.t("chat:channel.chat.design.placement")}
            subheading={i18n.t("chat:channel.chat.design.placementDesc")}
          >
            <div className={styles.placementWrapper}>
              <RadioButton name="placement" value="left" checked={placement === "left"} onChange={(e) => setPlacement(e.target.value)}>
                <Button variant="secondary" size="s" iconLeft={<UILIB.Icon name="arrow-left" />}>{i18n.t("chat:channel.chat.design.leftAligned")}</Button>
              </RadioButton>

              <RadioButton name="placement" value="right" checked={placement === "right"} onChange={(e) => setPlacement(e.target.value)}>
                <Button variant="secondary" size="s" iconLeft={<UILIB.Icon name="arrow-right" />}>{i18n.t("chat:channel.chat.design.rightAligned")}</Button>
              </RadioButton>
            </div>

            <div className={styles.placementWrapper}>
              <TextInput
                label={"Pixels from " + placement}
                value={xPos}
                style={{ width: 80 }}
                type="number"
                placeholder="24"
                onChange={(e) => setXPos(e.target.value)}
              />
              <TextInput
                label={"Pixels from Bottom"}
                value={yPos}
                style={{ width: 80 }}
                type="number"
                placeholder="24"
                onChange={(e) => setYPos(e.target.value)}
              />
            </div>

          </SettingsSection>
          <hr />

          <SettingsSection heading={i18n.t("chat:channel.chat.design.initial")} subheading={i18n.t("chat:channel.chat.design.initialDesc")}>
            <div className={styles.initialAgentSelectWrapper}>
              <UILIB.SelectNewDefault
                value={initialAgent.selected}
                data={initialAgentSelectData}
                fullWidth
                onValueChange={(val) => updateInitialAgent({ selected: val })}
              />
            </div>

            {initialAgent.selected === "custom" && (
              <div className={styles.customAgentWrapper}>
                <TextInput className="width-100" label={i18n.t("chat:channel.chat.design.customName")} value={initialAgent.customName} onChange={(e) => updateInitialAgent({ customName: e.target.value })} />

                <div className={styles.imageUploadWrapper}>
                  <div className={styles.imageUpload}>
                    <UILIB.AvatarNew src={initialAgent.customImageSrc} size={40} />

                    <Button variant="secondary" size="s" iconLeft={<UILIB.Icon name="upload" />} onClick={() => openFileManager(handleInitialAgentCustomImageUpload)}>
                      {i18n.t("chat:channel.chat.design.customAvatar")}
                    </Button>
                  </div>

                  <Button variant="danger" size="s" iconLeft={<UILIB.Icon name="cross-large" />} onClick={() => updateInitialAgent({ customImageSrc: null })}>
                    {i18n.t("chat:channel.chat.design.customRemove")}
                  </Button>
                </div>
              </div>
            )}
          </SettingsSection>
        </div >

      </div>
      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6">
        <UILIB.Paper className="paper-grey" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: 'calc(100vh - 100px)', height: 800, position: 'relative', }}>
          <iframe src={`${SiteVars.getConnString()}/webchat?am=${channel.AccountMasterId}&channelId=${channel.id}&uuid=1&mode=preview&rnd=${rnd}`} frameBorder="0" style={{ width: 500, height: '100%', position: 'absolute', padding: '20px 0' }}></iframe>
        </UILIB.Paper>
      </div>
    </>
  );
}

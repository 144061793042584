import React, { useState, useEffect } from "react";
import axios from "~/data/http/axios";
import { useSelector, useDispatch } from "react-redux";
import NewUserForm from "~/pages/cp/includes/bb_topNav/newUserForm/newUserForm";
import UILIB from "~/Common-Objects/Lib";
import { alterSiteDrawer } from '~/data/actions/siteActions';

export default function InboxPage({ labId = 0, onClose = () => { }, includeLearnMore = false }) {

    const dispatch = useDispatch();

    const account = useSelector((state) => state.accountMaster);
    const [lab, setLab] = useState({});
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        getLab();
    }, []);

    const getLab = async () => {
        let labData = await axios.get('/accountmaster/labs/' + labId);
        setLab(labData.data);
        setLoading(false);
    };

    const activateBeta = async () => {
        try {
            await axios.put('/accountmaster/labs', { betaId: labId });
            onClose(true);
        }
        catch (err) {
            onClose(false);
        }
    }

    const activateAccount = () => {
        //setOverlay(undefined);
        onClose(false);
        dispatch(alterSiteDrawer(true, true, 'right', <NewUserForm from={"labs-" + lab.name} />, true, '100vw'))
    }
    if (loading) return <></>;
    return (
        <UILIB.Dialog.Root open={true}>
            <UILIB.Dialog.Portal >
                <UILIB.Dialog.Overlay />
                <UILIB.Dialog.Content>
                    <div style={{ padding: "40px" }}>
                        <h4 className="mar-b10">Enable {lab.fullName}</h4>
                        <div className="mar-b10">This is a beta feature, there might be bugs and functionality might change</div>
                        <div className="mar-b10">It's completely free to try this beta, but in the future this might become a paid only option.</div>
                        <div className="mar-b25">Enabling this beta feature will make this feature available to all of your users.</div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {!!account.accountMaster.approved && <UILIB.Button className="button-primary mar-r10" text={"That's fine, let me try it"} onClick={activateBeta} />}
                                {!account.accountMaster.doneNewUserQuestions && !account.accountMaster.approved && <UILIB.Button className="button-orange mar-r10" onClick={activateAccount}>Request Approval</UILIB.Button>}
                                {account.accountMaster.doneNewUserQuestions && !account.accountMaster.approved && <UILIB.Button className="button-primary mar-r10" disabled={true} onClick={activateAccount}>Approval Pending</UILIB.Button>}
                                <UILIB.Button text="Cancel" onClick={() => { onClose(false) }} />
                            </div>
                            {(includeLearnMore && lab.helpLink && lab.helpLink.length > 5) && <div>
                                <UILIB.Hint
                                    onClick={() => {
                                        window.open(lab.helpLink, "_blank")
                                    }}
                                    iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>
                                    Learn More
                                </UILIB.Hint></div>}
                        </div>
                    </div>

                </UILIB.Dialog.Content>
            </UILIB.Dialog.Portal >
        </UILIB.Dialog.Root>
    )
}
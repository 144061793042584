import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

/*
CARD
Cards used for navigation and stuff. Includes a lovely big icon, title, text and button

PROPS:
icon            REACT               The icon to display as a react component
title           REACT/TEXT          The title. Can be react or text
subTitle        REACT/TEXT          The sub title.
buttonText      REACT/TEXT          The button text/react.
*/

/*
STYLING is take from WhiteBox
*/

export default class Card extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {

    var permission = this.props.permission;
    var roleClass = "warning";
    if (permission.value == 'write') roleClass = 'primary';
    if (permission.value == 'read') roleClass = 'secondary';

    var cantBeNone = false;
    if (permission.name == "Account Master") cantBeNone = true;
    if (permission.name == "Role") cantBeNone = true;

    return <UILIB.Paper className={"cards-rolegroup " + this.props.className} >
      <h4 className="cards-rolegroup-name">
        {i18n.t('permissions:' + permission.name)}
      </h4>
      <div className="cards-rolegroup-description">
        {i18n.t('permissions:description.' + permission.name)}
      </div>
      <div className="cards-rolegroup-options">

        <div className={"cards-rolegroup-option " + (permission.value == 'write' ? 'cards-rolegroup-option-selected' : '')}
          onClick={() => this.props.readonly ? null : this.props.onChange('write')}
        >
          <div className="cards-rolegroup-option-title">{i18n.t('permissions:users.WRITE')}</div>
          <UILIB.Radio
            name={permission.name}
            className={permission.value == 'write' ? 'primary' : ''}
            disabled={this.props.readonly}
            checked={permission.value == 'write'}
            value="write"
            onChange={() => this.props.onChange('write')}
          />
        </div>

        <div className={"cards-rolegroup-option " + (permission.value == 'read' ? 'cards-rolegroup-option-selected' : '')}
          onClick={() => this.props.readonly ? null : this.props.onChange('read')}
        >
          <div className="cards-rolegroup-option-title">{i18n.t('permissions:users.READ')}</div>
          <UILIB.Radio
            name={permission.name}
            className={permission.value == 'read' ? 'primary' : ''}
            disabled={this.props.readonly}
            checked={permission.value == 'read'}
            value="read"
            onChange={() => this.props.onChange('read')}
          />
        </div>

        <div className={"cards-rolegroup-option " + (permission.value == 'none' ? 'cards-rolegroup-option-selected' : '')}
          onClick={() => this.props.readonly || cantBeNone ? null : this.props.onChange('none')}
        >
          <div className="cards-rolegroup-option-title">{i18n.t('permissions:users.NONE')}</div>
          <UILIB.Radio
            name={permission.name}
            className={permission.value == 'read' ? 'primary' : ''}
            disabled={this.props.readonly || cantBeNone}
            checked={permission.value == 'none'}
            value="none"
            onChange={() => this.props.onChange('none')}
          />
        </div>


      </div>
    </UILIB.Paper>

  }
}

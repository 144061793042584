import React from 'react'
import UILIB from '~/Common-Objects/Lib.jsx'
import i18n from '~/i18n'

import IncGoDeleteSubs from './incGoDeleteSubs.jsx';
import IncGoChangeStatus from './incGoChangeStatus.jsx';

export default class InGoTickSubs extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            clickedDrawer: null
        };

        this.clickOption = this.clickOption.bind(this);
        this.goBack = this.goBack.bind(this);
        this.closeAll = this.closeAll.bind(this);

    }

    clickOption(optType) {
        var theDrawer = "";
        switch (optType) {
            case "delete":
                theDrawer = <IncGoDeleteSubs goBack={this.goBack} subscribers={this.props.subscribers} groupId={this.props.groupId} reloadAfterTickedAndChanged={this.props.reloadAfterTickedAndChanged} />
                break;
            case "status":
                theDrawer = <IncGoChangeStatus goBack={this.goBack} subscribers={this.props.subscribers} groupId={this.props.groupId} reloadAfterTickedAndChanged={this.props.reloadAfterTickedAndChanged} />
                break;
        }

        this.setState({ clickedDrawer: theDrawer });
    }

    goBack() {
        this.setState({ clickedDrawer: null });
    }

    closeAll() {
        this.props.checkAll(null, true);
    }

    render() {

        if (this.state.clickedDrawer != null) {
            return <span>{this.state.clickedDrawer}</span>
        }
        else {
            return <div>

                <div className="quickFlex mar-b25">
                    <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.closeAll}>{i18n.t('goBack')}</UILIB.Hint>
                </div>

                <h4 className="mar-b25">{i18n.t('subscribers:overview.optionHeader')}</h4>
                <div className="mar-b25">{i18n.t('subscribers:overview.optionSubHeader')}</div>

                <ul className="list avatar with-lines with-arrow with-notification">
                    <li className="middle-md" onClick={() => { this.clickOption("delete") }} >
                        <div className="notify">
                            <span>{this.props.totTicked}</span>
                        </div>
                        <p>{i18n.t('delete')}</p>
                        <div className="arrow">
                            <UILIB.Icons icon="arrowRight" />
                        </div>

                    </li>
                    <li className="middle-md" onClick={() => { this.clickOption("status") }} id="toastStatus">
                        <div className="notify">
                            <span>{this.props.totTicked}</span>
                        </div>
                        <p>{i18n.t('subscribers:overview.optionChange')}</p>
                        <div className="arrow">
                            <UILIB.Icons icon="arrowRight" />
                        </div>
                    </li>
                </ul>

            </div>
        }
    }
};
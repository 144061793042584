import React from "react";
import { useEffect, useState } from "react";
import axios from '~/data/http/axios';
import UILIB from "../Lib";
import Rum from '../../Classes/rum'

export default function NewFeatureFeedback({ featureType = "", featureSubType = "", closeDrawer = () => { }, hideCloseCross = false }) {

    const [notes, setNotes] = useState("");


    const submitInterestedIn = async () => {
        await axios.post('/accountuser/feedback', {
            type: featureType,
            notes: notes,
            campaignTypeInterestedIn: featureSubType
        })
        setNotes("");
        closeDrawer();
    }

    useEffect(() => {
        if (featureType === 'automationStepType' && featureSubType === 'SMS Automation') {
            Rum.trackEvent('feedback_sms_automations');
        } else if (featureType === 'campaign' && featureSubType === 'SMS Campaigns') {
            Rum.trackEvent('feedback_sms_campaigns');
        }
    }, [featureType, featureSubType])

    return <div className="campaign-type-selector">
        <div className="campaign-type-selector__content">
            {!hideCloseCross && <div className="exit" onClick={closeDrawer}>
                <span className="icon-cross2"></span>
            </div>}
            <h4 className="mar-b10">It looks like you are interested in {featureSubType} - So are we!</h4>

            <div className="mar-b10">
                We're currently exploring and collecting feedback on {featureSubType} and would love to know if it's something
                you'd like to see in Transpond.
            </div>
            <div className="mar-b20">
                Please leave any thoughts on how you'd like to use this feature below. Thank you!
            </div>
            <UILIB.TextArea outerClassName="mar-b10" name="interestedInCampaignTxt" value={notes} onChange={(e) => { setNotes(e.target.value) }} label="Comments" style={{ height: 150 }} placeholder="Enter your comments here..." />

            <div className="quickFlex">
                <UILIB.Button className="button-primary mar-r10" text="Submit and Close" onClick={submitInterestedIn} />
                <UILIB.Button text="Cancel" onClick={() => {
                    setNotes("");
                    closeDrawer();
                }} />
            </div>
        </div>
    </div>
}
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import ContactsMainNav from '../includes/contacts/contactsMainNav';


export default class SubscribersTemplateFP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupName: "",
            groupID: 0
        };

    }

    componentDidMount() {
        var groupID = this.props.groupID;
        this.setState({ groupID: groupID }, this.lookupGroup);
    }



    render() {


        return <UILIB.Paper>
            <div className="page-header mar-b25">{i18n.t('nav:subscribers')}</div>
            <ContactsMainNav history={this.props.history} />
            {this.props.children}
        </UILIB.Paper >

    }
}
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../../data/http/axios'
import UILIB from '../../../Common-Objects/Lib'
import * as siteActions from '../../../data/actions/siteActions'
import AddApp from '../company/integrations/addApp'
import SegmentEditor from '../includes/segmentEditor/segmentEditor'

export default function CapsuleFrontpage({ accApp }) {
    const [apps, setApps] = useState(null)
    const [customFields, setCustomFields] = useState(null)
    const [automationThemes, setAutomationThemes] = useState(null)
    const [groups, setGroups] = useState(null)
    const [segments, setSegments] = useState(null)
    const [campaignName, setCampaignName] = useState('My First Campaign')
    const [selected, setSelected] = useState(null)
    const siteMaster = useSelector(state => state.siteMaster)

    const history = useHistory()
    const dispatch = useDispatch()

    const createCampaign = () => {
        const data = {
            campaignName, type: 1
        }
        if (!selected) return
        if (selected.indexOf('group_') === 0) {
            data.Groups = [{
                id: parseInt(selected.split('_')[1]),
                include: true
            }]
        } else {
            data.Segments = [{
                id: parseInt(selected.split('_')[1]),
                include: true
            }]
        }
        dispatch(siteActions.alterSnackbar(true, 'Creating Campaign'))
        axios.post('/campaign', data).then(res => {
            history.push('/cp/campaigns/add/standard/' + res.data.Campaign.id + '/2')
        })
    }

    const createAutomation = (theme) => {
        dispatch(siteActions.alterSnackbar(true, 'Creating Automation'))
        if (!theme.id) {
            history.push('/cp/automation/addnew')
        } else {
            axios.post('/automation/theme/' + theme.id).then(response => {
                history.push('/cp/automation/addnew/' + response.data.Automation.id)
            })
        }
    }

    const setupApp = (id) => {
        var content = <AddApp history={this.props.history} appId={id} browse={url => {
            dispatch(siteActions.alterSiteDrawer(false, true, "right", null, false));
            if (url.indexOf('http') === 0) {
                window.open(url, '_self')
            } else {
                history.push(url)
            }
        }} />
        dispatch(siteActions.alterSiteDrawer(true, true, 'right', content, true, '100vw'))
    }

    useEffect(() => {
        if (selected === 'new') {
            setSelected('group_' + groups[0].id)
            dispatch(siteActions.alterSiteDrawer(true, true, 'right', <SegmentEditor close={async res => {
                await axios.get('/segment?notsystem=true').then(res => setSegments(res.data.Segments))
                setSelected('segment_' + res.data.Segment.segmentId)
                dispatch(siteActions.alterSiteDrawer(false, true, 'right', null))
            }} />, true, '100vw'))
        }
    }, [selected])

    useEffect(() => {
        axios.get('/application/account').then(res => setApps(res.data.Applications))
        axios.get('/customfield').then(res => setCustomFields(res.data.CustomFields))
        axios.get('/automation/themes').then(res => setAutomationThemes(res.data.Themes))
        axios.get('/segment?notsystem=true').then(res => setSegments(res.data.Segments))
        axios.get('/group').then(res => {
            setGroups(res.data.Groups)
            let found = res.data.Groups.find(g => g.id == accApp.appSettings.groupId)
            if (!found) found = res.data.Groups[0]
            setSelected('group_' + found.id)
        })
    }, [])

    if (!apps || !customFields || !automationThemes || !groups || !segments) return <UILIB.LoadingIcons iconType="2" />
    const app = apps.find(a => a.ApplicationId === 8)
    const showMailchimp = app.appSettings.hasMailchimp && !apps.some(a => a.ApplicationId === 10)

    const themes = []
    automationThemes.forEach(t => {
        if (t.name === 'Welcome Email') themes.push(t)
        if (t.name === 'Happy Birthday Email' && customFields.some(cf => cf.fieldType === 'DATE')) themes.push(t)
        if (t.name === 'Create Capsule Activity on Clicks') themes.push(t)
    })
    if (themes.length === 2) {
        themes.push({ name: 'Create from Scratch', description: 'Select your own trigger and actions', icon: 'pencil' })
    }

    const contacts = []
    groups.forEach(c => contacts.push({ value: 'group_' + c.id, label: c.groupName, iconRight: <UILIB.SquareChip small={true} className="square-chip-primary">Group</UILIB.SquareChip> }))
    segments.forEach(c => contacts.push({ value: 'segment_' + c.id, label: c.name, iconRight: <UILIB.SquareChip small={true} className="square-chip-secondary">Segment</UILIB.SquareChip> }))
    contacts.push({ value: 'new', label: 'or Create a new Segment', iconRight: <UILIB.SquareChip small={true} className="square-chip-blue">New</UILIB.SquareChip> })

    return (
        <div className="mar-b25">


            <UILIB.Row>
                <UILIB.Column xs={12} sm={6} md={8} className="mar-b25 mar-r0" style={{ paddingRight: "0" }} >

                    <div
                        className="hide-sm hide-md hide-lg"
                        style={{
                            width: "100%",
                            height: "40vh",
                            backgroundImage: "url(https://cdn1.ourmailsender.com/siteContent/assets/frontpage/capsuleFrontPageTemplate.png)",
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }} />

                    <UILIB.Paper style={{ borderTopRightRadius: "0", borderBottomRightRadius: "0" }} className="mar-b0">

                        <UILIB.Chip className="mar-b25" value={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Your contacts have been imported from <UILIB.Icons icon="capsule" className="mar-l5 mar-r5" style={{ height: 15, width: 15, display: "inline-block" }} color="black" /> Capsule</div>} />

                        <div className="mar-b25">
                            <h4 className="mar-b15">Time to send your first Campaign</h4>
                            Enter a campaign name, select your contacts and start designing your first email template.<br />
                        </div>

                        <UILIB.Row className="bottom-md">
                            <UILIB.Column xs={12} md={4}>
                                <UILIB.TextInput label="Campaign Name" value={campaignName} onChange={e => setCampaignName(e.currentTarget.value)} placeholder="My First Campaign" />
                            </UILIB.Column>
                            <UILIB.Column xs={12} md={4}>
                                <UILIB.Select label="Group or Segment" value={selected} onChange={e => setSelected(e.currentTarget.value)} data={contacts} />
                            </UILIB.Column>
                            <UILIB.Column xs={12} md={4}>
                                <UILIB.Button className="button-primary" onClick={createCampaign} disabled={!campaignName || campaignName.length < 3} iconLeft={<UILIB.Icons icon="plus" />}>Design a Template</UILIB.Button>
                            </UILIB.Column>
                        </UILIB.Row>

                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column className="hide-xs mar-b25 mar-l0" xs={12} sm={6} md={4} style={{ paddingLeft: "0" }}>
                    <div style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: "url(https://cdn1.ourmailsender.com/siteContent/assets/frontpage/capsuleFrontPageTemplate.png)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }} />
                </UILIB.Column>
            </UILIB.Row>



            <UILIB.Paper>
                <div className="row">
                    <div className="col-xs-12 mar-b10">
                        <div className='mar-b10 quickFlex col-spacer-bottom' style={{ fontWeight: 600, fontSize: 16, paddingBottom: 20 }}>
                            <div className="mar-r20" style={{ borderRadius: '50%', backgroundColor: '#453DD8', padding: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 35, width: 35 }}>
                                <UILIB.Icons icon="capsule" color="white" style={{ height: 18, width: 18 }} />
                            </div>
                            Power up your Capsule Integration with Automations
                        </div>
                    </div>

                    {themes.map((theme, index) => {
                        let classes = "col-xs-12 col-md-4"
                        if (index < themes.length - 1) {
                            classes += ' col-md-spacer-right'
                        }

                        return <div className={classes}>
                            <div style={{ display: 'flex' }}>
                                <div className="mar-r20">
                                    {theme.Application && theme.Application.appLogoUri ? <UILIB.Avatar src={theme.Application.appLogoUri} style={{ height: 30, width: 30 }} /> : theme.icon && theme.icon.indexOf('icon-') === 0 ? <span style={{ fontSize: 24, color: siteMaster.colours.$primary }} className={theme.icon} /> : <UILIB.Icons icon={theme.icon} circle={true} />}
                                </div>
                                <div>
                                    <h4 className="mar-b10">
                                        {theme.name}
                                    </h4>
                                    <div style={{ fontSize: 14 }} className="mar-b20">
                                        {theme.description}
                                    </div>
                                    <UILIB.Button text="Create Automation" className="button-md" onClick={() => createAutomation(theme)} iconRight={<UILIB.Icons icon="arrowRight" />} />
                                </div>
                            </div>
                        </div>
                    })}
                    {showMailchimp && <div className="col-xs-12 mar-t25">
                        <UILIB.Paper className="no-marg">
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div>
                                    <img src="https://cdn1.ourmailsender.com/siteContent/appLogos/mailchimpLogo.png" alt="mailchimp" style={{ borderRadius: '50%' }} height="52" />
                                </div>
                                <div style={{ flexGrow: 1, paddingLeft: 30 }}>
                                    <h4 className="mar-b10">Migrating from Mailchimp?</h4>
                                    <div style={{ fontSize: 14 }}>
                                        Bring all of your contacts, lists, images and templates to Transpond using our simple migration tool
                                    </div>
                                </div>
                                <div>
                                    <UILIB.Button text="Migrate Now" className="button-md" onClick={() => setupApp(10)} iconRight={<UILIB.Icons icon="arrowRight" />} />
                                </div>
                            </div>

                        </UILIB.Paper>
                    </div>}
                </div>
            </UILIB.Paper>
        </div >
    )
}
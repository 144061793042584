import React, { useState, useReducer } from "react";
import { useSelector } from "react-redux";
import i18n from "i18next";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import TextFunctions from "../../../../../Classes/textFunctions";
import { TRACKING_DOMAIN, TRANSPOND_CDN_DOMAIN } from "~/Classes/domains";

export default function AddSubdomain({
  domainType,
  topLevelDomain,
  className,
  onAdd,
  onAdded,
  ...rest
}) {
  const site = useSelector((state) => state.siteMaster);
  const [submitting, setSubmitting] = useState(false);

  const [formValues, updateFormValues] = useReducer(formValuesReducer, {
    domainName: `${domainType.id}`,
  });

  const [errors, updateErrors] = useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    {
      domainName: null,
    }
  );

  function handleInputChange(e) {
    updateFormValues({ [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitting(true);

    const formValuesFormatted = {
      ...formValues,
      domainName: `${formValues.domainName}.${topLevelDomain.domainName}`,
    };

    try {
      await onAdd(formValuesFormatted, domainType);
      onAdded && onAdded(domainType);
    } catch (error) {
      updateErrors({ domainName: error.data.error });
    } finally {
      setSubmitting(false);
    }
  }

  const classes = classnames("add-subdomain", className);

  const isTrackingDomain = domainType === TRACKING_DOMAIN;
  const cnameValue = isTrackingDomain
    ? site.trackingDomain
    : TRANSPOND_CDN_DOMAIN;

  return (
    <div className={classes} {...rest}>
      <h2 className="h4 mar-b15">
        {i18n.t(`company:domains.${domainType.id}.addHeader`)}
      </h2>

      <div
        dangerouslySetInnerHTML={{
          __html: i18n.t(`company:domains.${domainType.id}.addBody`, {
            siteName: site.siteName,
          }),
        }}
      />

      <form className="mar-t25" onSubmit={handleSubmit}>
        <label htmlFor="domainName" className="txtInput_label">
          {i18n.t(`company:domains.${domainType.id}.addInputLabel`)}
        </label>

        <div className="add-subdomain__name mar-b25">
          <UILIB.TextInput
            type="text"
            id="domainName"
            name="domainName"
            value={formValues.domainName}
            error={errors.domainName}
            focus
            required
            onChange={handleInputChange}
          />

          <div className="code">
            <span>.{topLevelDomain.domainName}</span>
          </div>
        </div>

        {/* <ol className="mar-b25">
          <li>
            <div
              dangerouslySetInnerHTML={{
                __html: i18n.t(
                  `company:domains.${domainType.id}.authenticateList1`,
                  {
                    domainName: TextFunctions.checkForHtml(topLevelDomain.domainName),
                  }
                ),
              }}
            />
            <table className="simple-table mar-t15 mar-b25">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Value</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <code className="code">CNAME</code>
                  </td>
                  <td>
                    <code className="code">{cnameValue}</code>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>

          <li>{i18n.t(`company:domains.${domainType.id}.authenticateList2`, { siteName: site.siteName })}</li>
        </ol> */}

        <UILIB.Button
          type="submit"
          saving={submitting}
          className="button-primary"
        >
          {i18n.t(`company:domains.${domainType.id}.add`)}
        </UILIB.Button>
      </form>
    </div>
  );
}

function formValuesReducer(state, action) {
  const newEvent = { ...state, ...action };

  if (action.domainName) {
    newEvent.domainName = newEvent.domainName.toLowerCase();
  }

  return newEvent;
}

import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import AutomationFunctions from '../automationFunctions'
import segmentsHelper from '~/Classes/segments.jsx'
import SegmentEditor from '~/pages/cp/includes/segmentEditor/segmentEditor'
import i18n from '~/i18n'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import TriggerFilters from '../integrations/generic/triggerFilters';
import CampaignSelector from './utils/campaignSelector';
import HelpPopup from '../helpPopup/helpPopup';
import moment from 'moment'

@connect((store) => {
    return { siteMaster: store.siteMaster }
})
class DrawerConditionStep extends Component {
    constructor(props) {
        super(props);

        var stringOperators = segmentsHelper.getOperators('string');
        var intOperators = segmentsHelper.getOperators('int');
        var boolOperators = segmentsHelper.getOperators('bool');
        var dateOperators = segmentsHelper.getOperators('date');

        dateOperators = dateOperators.filter(obj => {
            return obj.value !== "between" && obj.value !== "not between";
        });

        var groupOperators = segmentsHelper.groupOperators;

        this.state = {
            drawerContent: undefined,
            step: {},
            errors: {},
            operators: {
                TEXT: stringOperators,
                NUMBER: intOperators,
                DATE: dateOperators,
                GROUP: groupOperators,
                'TRUE/FALSE': boolOperators,
                BOOLEAN: boolOperators,
                SELECT: boolOperators,
                MULTISELECT: boolOperators,
                TELNO: stringOperators
            },
            campaignLinks: [],
            webhookDefinitions: [],
            tags: undefined
        }

        this.updateValue = this.updateValue.bind(this);
        this.saveStep = this.saveStep.bind(this);
        this.updateDate = this.updateDate.bind(this);
        this.createSegment = this.createSegment.bind(this);
        this.createdSegment = this.createdSegment.bind(this);
        this.getCampaignLinks = this.getCampaignLinks.bind(this);
        this.viewEditSegment = this.viewEditSegment.bind(this);
        this.validateLinks = this.validateLinks.bind(this)
        this.getAutomations = this.getAutomations.bind(this)
        this.getTags = this.getTags.bind(this)
    }

    componentDidMount() {

        //add fields if they dont exist
        if (this.props.step && this.props.step.stepOptions) {
            if (!this.props.step.stepOptions.groupId) this.props.step.stepOptions.groupId = { "value": "-1" }
            if (!this.props.step.stepOptions.subscriberState) this.props.step.stepOptions.subscriberState = { "value": "" }

            if (!this.props.step.stepOptions.filters) this.props.step.stepOptions.filters = [];
        }

        this.setState({ step: this.props.step }, () => {

            if (this.state.step.stepOptions.triggerType.value == "3" && this.state.step.stepOptions.campaignId.value != "-1" && this.state.step.stepOptions.workflowEmailGuid.value != "" && (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5")) {
                this.getCampaignLinks()
            }
            if (this.state.step.stepOptions.triggerType.value == "0" && this.state.step.stepOptions.workflowEmailGuid.value != "-1" && (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5")) {
                this.getCampaignLinks()
            }
            if (this.state.step.stepOptions.triggerType.value == "10") {
                this.getAutomations()
            }
            if (this.state.step.stepOptions.triggerType.value == "11") {
                this.getTags()
            }

            try {

                const getters = []
                const names = []

                const check = (initial) => {
                    if (isNaN(initial.triggerType.value)) {
                        // its an integration
                        const app = this.props.apps.find(a => a.Application.appName === initial.triggerType.value)
                        if (app) {
                            if (names.indexOf(initial.stepOptions.trigger.value) > -1) return
                            names.push(initial.stepOptions.trigger.value)
                            getters.push(axios.get('/application/' + app.ApplicationId + '/webhookdefinition?trigger=' + initial.stepOptions.trigger.value))
                        }
                    }
                }
                check(this.props.steps[0])
                if (getters.length) {
                    Promise.all(getters).then(results => {
                        this.setState({
                            webhookDefinitions: results.filter(r => r.data.condition).map(r => r.data)
                        })
                    })
                }
            } catch (e) {
                console.log(e)
                console.log(this.props)
            }

        });


    }

    getTags() {
        axios.get('/tags').then(response => {
            this.setState({ tags: response.data })
        })
    }

    updateValue(event) {

        var fieldName = event.target.name;
        var step = this.state.step;
        if (!step.stepOptions[fieldName]) step.stepOptions[fieldName] = { value: '' }
        if (fieldName === 'otherAutomations') {
            const arr = step.stepOptions[fieldName].value || []
            const index = arr.indexOf(event.target.value)
            if (index > -1) arr.splice(index, 1)
            else arr.push(event.target.value)
            step.stepOptions[fieldName].value = arr
        } else {
            step.stepOptions[fieldName].value = event.target.value;
        }
        step.complete = false;

        if (fieldName == "triggerType") {
            step.stepOptions.customFieldId.value = "-1"
            step.stepOptions.customFieldAction.value = "="
            step.stepOptions.customFieldValue.value = "-1"
            step.stepOptions.workflowEmailGuid.value = "-1";
            if (step.stepOptions.tagId) step.stepOptions.tagId.value = "-1";
            step.stepOptions.filters = step.stepOptions.filters || []
        }

        if (fieldName == "customFieldId") {
            step.stepOptions.customFieldAction.value = "="
            step.stepOptions.customFieldValue.value = "-1"
        }

        if (fieldName == "campaignId" || fieldName == "workflowEmailGuid" || (fieldName == "workflowEmailActionType" && (event.target.value == "3" || event.target.value == "5"))) {
            this.getCampaignLinks()
        }

        if (fieldName === 'triggerType' && event.target.value == "10" && !this.state.automations) {
            this.getAutomations()
        }
        if (fieldName === 'triggerType' && event.target.value == "11" && !this.state.tags) {
            if (!step.stepOptions.tagType) {
                step.stepOptions.tagType = { value: 'has' }
            }
            this.getTags()
        }
        if (fieldName === 'triggerType' && event.target.value == "12") {
            if (!step.stepOptions.dateBeforeAfter) step.stepOptions.dateBeforeAfter = { value: 'after' }
            if (!step.stepOptions.datetime) step.stepOptions.datetime = { value: new Date() }
        }
        let errors = {};
        this.setState({ step, errors })
    }

    updateDate(variableName, newVal) {
        var step = this.state.step;
        step.stepOptions[variableName].value = newVal.format("YYYY-MM-DD");
        step.complete = false;
        let errors = {};
        this.setState({ step, errors })
    }

    saveStep() {
        var errors = {};
        var error = false;
        var step = this.state.step;
        if (step.stepOptions.triggerType.value == "-1") {
            error = true;
            errors.triggerType = "Please select a Trigger"
        }
        if (step.stepOptions.triggerType.value == "0") {
            if (step.stepOptions.workflowEmailGuid.value == "-1") {
                error = true;
                errors.workflowEmailGuid = i18n.t('automation:add.wait.errEmail')
            }

            if (step.stepOptions.workflowEmailActionType.value === "3" && !step.stepOptions.linkClicked.value) {
                error = true;
                errors.linkClicked = i18n.t('automation:add.wait.linkClicked')
            }
        }

        if (step.stepOptions.triggerType.value == "1") {
            if (step.stepOptions.customFieldId.value == "-1") {
                error = true;
                errors.customFieldId = i18n.t('automation:add.wait.errField')
            }
            if (((step.stepOptions.customFieldValue.value + "") == "" || step.stepOptions.customFieldValue.value == "-1") && step.stepOptions.customFieldAction.value != "is blank" && step.stepOptions.customFieldAction.value != "is not blank") {
                error = true;
                errors.customFieldValue = i18n.t('automation:add.wait.errValue')
            }
        }
        if (step.stepOptions.triggerType.value == "2") {
            if (step.stepOptions.segmentId.value == "-1") {
                error = true;
                errors.segmentId = i18n.t('automation:add.wait.errSegment')
            }
        }
        if (step.stepOptions.triggerType.value == "3") {
            if (step.stepOptions.campaignId.value == "-1") {
                error = true;
                errors.campaignId = i18n.t('automation:add.wait.errCampaign')
            }
        }

        if (step.stepOptions.triggerType.value == "7") {
            if (step.stepOptions.groupId.value == "-1") {
                error = true;
                errors.groupId = i18n.t('automation:add.wait.errGroup')
            }
            if (step.stepOptions.subscriberState.value == "") {
                error = true;
                errors.subscriberState = i18n.t('automation:add.wait.errSubscriberState')
            }
        }

        if (step.stepOptions.triggerType.value == "9") {
            if (!step.stepOptions.formId?.value) {
                error = true;
                errors.formId = "required"
            }
            if (!step.stepOptions.formActivity?.value) {
                error = true;
                errors.formActivity = "required"
            }
            if (step.stepOptions.formActivity?.value === 'download' && !step.stepOptions.formFile?.value) {
                error = true;
                errors.formFile = "required"
            }
        }

        if (step.stepOptions.triggerType.value == "11") {
            if (!step.stepOptions.tagId || step.stepOptions.tagId?.value == "-1") {
                error = true;
                errors.tagId = 'Required'
            }
        }

        if (isNaN(step.stepOptions.triggerType.value)) {
            if (!step.stepOptions.filters || !step.stepOptions.filters.length) {
                error = true
                errors.triggerType = 'You must add at least 1 filter'
            }
        }

        this.setState({ errors })

        try {
            if (step.stepOptions.triggerType.value === "0" && step.stepOptions.workflowEmailGuid && step.stepOptions.workflowEmailGuid.value) {
                let found = AutomationFunctions.getStepByGuid(step.stepOptions.workflowEmailGuid.value, this.props.steps)
                if (found && found.stepOptions.campaign) {
                    step.stepOptions.campaignId.value = found.stepOptions.campaign.id
                }
            }
        } catch (e) { }

        if (!error || this.props.isThemeDesigner) {
            //finish
            step.complete = true;
            step.wizardComplete = true;
            this.props.saveStep()
        }

    }

    createSegment() {
        var drawerContent = <SegmentEditor history={this.props.history} close={this.createdSegment} />
        this.setState({ drawerContent }, () => {
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("100vw"));
        })
    }

    createdSegment(segmentData) {
        var segmentId = segmentData.data.Segment.segmentId;
        var step = this.state.step;
        step.stepOptions.segmentId.value = segmentId;
        step.complete = false;
        this.props.getSegments(false)
        this.setState({ drawerContent: undefined, step }, () => {
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("500px"));
        })
    }

    viewEditSegment() {
        var step = this.state.step;
        var segmentId = step.stepOptions.segmentId.value;
        var drawerContent = <SegmentEditor history={this.props.history} close={() => {
            this.props.getSegments(false)
            this.setState({ drawerContent: undefined })
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("500px"));
        }} segmentId={segmentId} />
        this.setState({ drawerContent }, () => {
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("100vw"));
        })
    }

    getCampaignLinks() {
        this.setState({ campaignLinks: [] })

        if (this.state.step.stepOptions.triggerType.value == "3" && this.state.step.stepOptions.campaignId.value) {
            var campaign = this.props.campaigns.find(cp => cp.value == this.state.step.stepOptions.campaignId.value)
            axios.get('/template/' + campaign.templateId + '/links').then(response => {
                var linkData = response.data.Links.map(l => { return { label: l, value: l } })
                this.setState({ campaignLinks: linkData }, this.validateLinks)
            })
        }

        if (this.state.step.stepOptions.triggerType.value == "0" && this.state.step.stepOptions.workflowEmailGuid.value) {
            var workflowEmails = AutomationFunctions.getWorkflowEmails(this.props.step.guid, this.props.steps);
            if (workflowEmails) {
                var workflowEmail = workflowEmails.find(wfs => wfs.guid == this.state.step.stepOptions.workflowEmailGuid.value)
                if (workflowEmail) {
                    var campaign = workflowEmail.stepOptions.campaign;
                    axios.get('/template/' + campaign.TemplateId + '/links').then(response => {
                        var linkData = response.data.Links.map(l => { return { label: l, value: l } })
                        this.setState({ campaignLinks: linkData }, this.validateLinks)
                    })
                }
            }
        }

    }

    async getAutomations() {
        const res = await axios.get('/automation?recordLimit=500')
        const automations = res.data.Automations.filter(a => a.id != this.props.automationId)
        this.setState({ automations })
    }

    validateLinks() {
        if (this.state.step.stepOptions.triggerType.value == "0" && this.state.step.stepOptions.workflowEmailActionType.value === "3" && this.state.step.stepOptions.linkClicked.value) {
            // check the current link value still exists
            if (!this.state.campaignLinks.some(l => l.value === this.state.step.stepOptions.linkClicked.value)) {
                let step = this.state.step
                step.stepOptions.linkClicked.value = ''
                this.setState({ step })
            }
        }
    }

    render() {
        const { readOnly } = this.props;

        if (this.state.drawerContent) return this.state.drawerContent;
        if (!this.state.step.stepOptions) return <div></div>

        var triggerTypes = [
            { label: i18n.t('automation:add.wait.trigger0'), value: "0" },
            { label: i18n.t('automation:add.wait.trigger1'), value: "1" },
            { label: i18n.t('automation:add.wait.trigger11'), value: "11" },
            { label: i18n.t('automation:add.wait.trigger2'), value: "2" },
            { label: i18n.t('automation:add.wait.trigger3'), value: "3" },
            { label: i18n.t('automation:add.wait.trigger7'), value: "7" },
            { label: i18n.t('automation:add.wait.trigger9'), value: "9" },
            { label: i18n.t('automation:add.wait.trigger10'), value: "10" },
            { label: i18n.t('automation:add.wait.trigger12'), value: "12" }
        ]

        if (this.state.webhookDefinitions.length) {
            triggerTypes.push(...this.state.webhookDefinitions.map(d => ({ label: d.label + ' Status', value: d.name })))
        }

        var workflowEmails = AutomationFunctions.getWorkflowEmails(this.props.step.guid, this.props.steps);


        var emailOptions = [
            { label: i18n.t('automation:add.wait.email0'), value: "0" },
            { label: i18n.t('automation:add.wait.email1'), value: "1" },
            { label: i18n.t('automation:add.wait.email2'), value: "2" },
            { label: i18n.t('automation:add.wait.email3'), value: "3" },
            { label: i18n.t('automation:add.wait.email4'), value: "4" },
            { label: i18n.t('automation:add.wait.email5'), value: "5" },
            { label: i18n.t('automation:add.wait.email6'), value: "6" }
        ]

        var customFieldActions = this.state.operators.TEXT;
        var customFieldInputType = "TEXT"
        var selectedCustomField;

        if (this.state.step.stepOptions && this.state.step.stepOptions.customFieldId && this.state.step.stepOptions.customFieldId.value && this.state.step.stepOptions.customFieldId.value != "-1") {
            selectedCustomField = this.props.customFields.find(fd => fd.value == this.state.step.stepOptions.customFieldId.value)
            if (selectedCustomField) {
                customFieldActions = this.state.operators[selectedCustomField.fieldType]
                customFieldInputType = selectedCustomField.fieldType;
            }
        }

        var currentSegmentIsUser = false;
        if (this.state.step.stepOptions && this.state.step.stepOptions.segmentId && this.state.step.stepOptions.segmentId.value && this.state.step.stepOptions.segmentId.value > 0) {

            var currSeg = this.props.segments.find(s => s.value == this.state.step.stepOptions.segmentId.value)

            if (currSeg && !currSeg.readOnly && !currSeg.CapsuleId) currentSegmentIsUser = true;
        }

        if (!this.state.step.stepOptions.workflowEmailActionFilterOperator) {
            this.state.step.stepOptions.workflowEmailActionFilterOperator = { value: "" }
        }
        if (!this.state.step.stepOptions.workflowEmailActionFilterValue) {
            this.state.step.stepOptions.workflowEmailActionFilterValue = { value: 1 }
        }

        var workflowEmailActionFilterOperatorOptions = [{
            value: "",
            label: i18n.t('automation:add.condition.atLeastOnce')
        }, {
            value: "=",
            label: i18n.t('automation:add.condition.exactlyX')
        }, {
            value: ">",
            label: i18n.t('automation:add.condition.atLeastX')
        }, {
            value: "<",
            label: i18n.t('automation:add.condition.lessThanX')
        }]

        var tmpGroups = JSON.parse(JSON.stringify(this.props.groups))

        let showScore = true;
        switch (this.state.step.stepOptions.triggerType.value) {
            case '0':
                if (!workflowEmails.length) showScore = false;
                if (this.state.step.stepOptions.workflowEmailGuid.value === '-1') showScore = false;
                break;
            case '1':
                if (this.state.step.stepOptions.customFieldId.value === '-1') showScore = false;
                break;
            case '2':
                if (this.state.step.stepOptions.segmentId.value === '-1') showScore = false;
                break;
            case '3':
                if (this.state.step.stepOptions.campaignId.value === '-1') showScore = false;
                break;
        }

        let webhookDefinition
        if (isNaN(this.state.step.stepOptions.triggerType.value)) {
            // find the integration its for
            webhookDefinition = this.state.webhookDefinitions.find(f => f.name === this.state.step.stepOptions.triggerType.value)
        }

        const inputProps = {
            disabled: readOnly
        };

        let form
        if (this.state.step.stepOptions.triggerType.value == "9" && this.state.step.stepOptions.formId?.value) {
            form = this.props.forms.find(f => f.id === this.state.step.stepOptions.formId?.value)
        }

        return <div data-test="drawer-condition-step">
            <div className="automationStepContentHolder">
                {this.state.step.type === 'condition' && <>
                    <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                        <h4 className="mar-b25">{i18n.t('automation:add.condition.header')}</h4>
                        <HelpPopup type={"condition"} />
                    </div>
                    <div className="mar-b25">{i18n.t('automation:add.condition.subheader')}</div>
                </>}
                {this.state.step.type === 'score' && <>
                    <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                        <h4 className="mar-b25">{i18n.t('automation:add.condition.score')}</h4>
                        <HelpPopup type={"score"} />
                    </div>
                    <div className="mar-b25">{i18n.t('automation:add.condition.scoreSubheader')}</div>
                </>}

                <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.wait.triggerType')} name="triggerType" placeholder={i18n.t('automation:add.wait.selectValue')} data={triggerTypes} onChange={this.updateValue} value={this.state.step.stepOptions.triggerType.value} error={this.state.errors.triggerType} {...inputProps} />

                {(this.state.step.stepOptions.triggerType.value == "0" && !workflowEmails.length) && <div>
                    {this.state.errors.workflowEmailGuid && this.state.errors.workflowEmailGuid.length > 0 && <UILIB.WarningHolder className="mar-b25">{i18n.t('automation:add.wait.pleaseAddCampaign')}</UILIB.WarningHolder>}
                    <UILIB.WarningHolder className="mar-b25">{i18n.t('automation:add.wait.noEmails')}</UILIB.WarningHolder>
                </div>}
                {(this.state.step.stepOptions.triggerType.value == "0" && workflowEmails.length > 0) && <div>

                    <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.wait.triggerEmail')} name="workflowEmailGuid" placeholder={i18n.t('automation:add.wait.selectWorkflow')} value={this.state.step.stepOptions.workflowEmailGuid.value} data={workflowEmails.map(wfe => { return { label: wfe.stepOptions.subject.value, value: wfe.guid } })} onChange={this.updateValue} error={this.state.errors.workflowEmailGuid} {...inputProps} />

                    {this.state.step.stepOptions.workflowEmailGuid.value != "-1" && <div >
                        <UILIB.Select outerClassName="mar-b25" data={emailOptions} placeholder={i18n.t('automation:add.wait.selectValue')} name="workflowEmailActionType" onChange={this.updateValue} value={this.state.step.stepOptions.workflowEmailActionType.value} {...inputProps} />
                    </div>}

                    {this.state.step.stepOptions.workflowEmailGuid.value != "-1" && (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5") && <div>
                        {!this.state.campaignLinks.length && <div></div>}
                        {this.state.campaignLinks.length > 0 && <div>
                            <UILIB.Select outerClassName="mar-b25" name="linkClicked" placeholder={i18n.t('automation:add.wait.selectValue')} data={this.state.campaignLinks} onChange={this.updateValue} value={this.state.step.stepOptions.linkClicked.value} error={this.state.errors.linkClicked} {...inputProps} />
                        </div>}
                    </div>}
                </div>}

                {this.state.step.stepOptions.triggerType.value == "1" && !this.props.isThemeDesigner && <div>

                    <UILIB.Select outerClassName="mar-b25" placeholder={i18n.t('automation:add.wait.selectField')} data={this.props.customFields} name="customFieldId" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldId.value} error={this.state.errors.customFieldId} {...inputProps} />

                    {this.state.step.stepOptions.customFieldId.value != "-1" && <div>

                        <UILIB.Select outerClassName="mar-b25" placeholder={i18n.t('automation:add.wait.selectValue')} data={customFieldActions} name="customFieldAction" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldAction.value} error={this.state.errors.customFieldAction} {...inputProps} />

                        {this.state.step.stepOptions.customFieldAction.value != "is blank" && this.state.step.stepOptions.customFieldAction.value != "is not blank" && <div>

                            {customFieldInputType === "TEXT" && <UILIB.TextInput outerClassName="mar-b25" type="text" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {(customFieldInputType === "NUMBER" || (customFieldInputType === "DATE" && this.state.step.stepOptions.customFieldAction.value.indexOf("be") > -1)) && <UILIB.TextInput outerClassName="mar-b25" type="number" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {customFieldInputType === "DATE" && (this.state.step.stepOptions.customFieldAction.value.indexOf("be") < 0) && <UILIB.TextInput outerClassName="mar-b25" type="date" name="customFieldValue" onChange={(event, newVal) => { this.updateDate("customFieldValue", newVal) }} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {customFieldInputType === "BOOLEAN" && <UILIB.Select outerClassName="mar-b25" name="customFieldValue" placeholder={i18n.t('automation:add.wait.selectValue')} data={[{ 'label': i18n.t('true'), 'value': true }, { 'label': i18n.t('false'), 'value': false }]} onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {(customFieldInputType === "SELECT" || customFieldInputType === 'MULTISELECT') && <UILIB.Select outerClassName="mar-b25" name="customFieldValue" placeholder={i18n.t('automation:add.wait.selectValue')} data={selectedCustomField.options ? selectedCustomField.options : []} onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                            {customFieldInputType === "TELNO" && <UILIB.TextInput outerClassName="mar-b25" type="TELNO" name="customFieldValue" onChange={this.updateValue} value={this.state.step.stepOptions.customFieldValue.value} error={this.state.errors.customFieldValue} {...inputProps} />}
                        </div>}
                    </div>
                    }
                </div>}

                {this.state.step.stepOptions.triggerType.value == "2" && !this.props.isThemeDesigner && <div>
                    {!this.props.segments.length &&
                        <UILIB.WarningHolder >
                            <div><strong>{i18n.t('automation:add.wait.noSegments')}</strong></div>
                            <button className="a" onClick={this.createSegment} {...inputProps}>{i18n.t('automation:add.wait.pleaseCreateSegment')}</button>
                        </UILIB.WarningHolder>}
                    {this.props.segments.length > 0 &&
                        <div>

                            <UILIB.Select
                                outerClassName="mar-b25"
                                label={i18n.t('automation:add.wait.selectSegment')}
                                labelOptions={<button className="a" onClick={this.createSegment} {...inputProps}>{i18n.t('automation:add.wait.createSegment')}</button>}
                                placeholder={i18n.t('automation:add.wait.selectSegment')}
                                name="segmentId"
                                value={this.state.step.stepOptions.segmentId.value}
                                data={this.props.segments}
                                onChange={this.updateValue}
                                error={this.state.errors.segmentId}
                                {...inputProps}
                            />
                            {(currentSegmentIsUser == true) && <div className="mar-t10"><button className="a" onClick={this.viewEditSegment} {...inputProps}>{i18n.t('automation:add.wait.editSegment')}</button></div>}
                        </div>
                    }
                </div>
                }
                {this.state.step.stepOptions.triggerType.value == "3" && !this.props.isThemeDesigner && <div>
                    {!this.props.campaigns.length &&
                        <UILIB.WarningHolder>
                            <div><strong>{i18n.t('automation:add.wait.noCampaigns')}</strong></div>
                        </UILIB.WarningHolder>}
                    {this.props.campaigns.length > 0 && <div>

                        <CampaignSelector
                            label={i18n.t('automation:add.wait.selectCampaign')}
                            outerClassName="mar-b25"
                            placeholder={i18n.t('automation:add.wait.selectCampaign')}
                            name="campaignId"
                            value={this.state.step.stepOptions.campaignId.value}
                            allowAnyCampaign={""}
                            onChange={this.updateValue}
                            error={this.state.errors.campaignId} {...inputProps}
                        />

                        {this.state.step.stepOptions.campaignId.value != "-1" && <div>
                            <UILIB.Select outerClassName="mar-b25" data={emailOptions} name="workflowEmailActionType" onChange={this.updateValue} value={this.state.step.stepOptions.workflowEmailActionType.value} {...inputProps} />
                        </div>}
                        {this.state.step.stepOptions.campaignId.value != "-1" && (this.state.step.stepOptions.workflowEmailActionType.value == "3" || this.state.step.stepOptions.workflowEmailActionType.value == "5") && <div>
                            <UILIB.TextInput label={i18n.t('automation:add.initial.link')} outerClassName="mar-b25" placeholder={i18n.t('automation:add.initial.enterLink')} name="linkClicked" value={this.state.step.stepOptions.linkClicked.value} onChange={this.updateValue} error={this.state.errors.link} {...inputProps} />
                        </div>}
                        {(this.state.step.stepOptions.workflowEmailActionType.value == '0' || this.state.step.stepOptions.workflowEmailActionType.value == '2') && <div>
                            <div className="quickFlex">
                                <div style={{ flex: "1" }}>
                                    <UILIB.Select outerClassName="mar-b25" name="workflowEmailActionFilterOperator" onChange={this.updateValue} data={workflowEmailActionFilterOperatorOptions} value={this.state.step.stepOptions.workflowEmailActionFilterOperator.value} {...inputProps} />
                                </div>
                                {!!this.state.step.stepOptions.workflowEmailActionFilterOperator.value && <div style={{ flex: "1" }}>
                                    <UILIB.TextInput outerClassName="mar-l10 mar-b25" type="number" min="1" onChange={this.updateValue} name="workflowEmailActionFilterValue" value={this.state.step.stepOptions.workflowEmailActionFilterValue.value} {...inputProps} />
                                </div>}
                            </div>
                        </div>}
                    </div>}
                </div>
                }

                {this.state.step.stepOptions.triggerType.value == "7" && !this.props.isThemeDesigner && <div>


                    {!tmpGroups && <UILIB.WarningHolder>
                        <div><strong>{i18n.t('automation:add.initial.noGroups')}</strong></div>
                    </UILIB.WarningHolder>}

                    {tmpGroups.length > 0 && <div>

                        <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.initial.selectGroup')} placeholder={i18n.t('automation:add.initial.selectGroupPlaceholder')} name="groupId" value={this.state.step.stepOptions.groupId.value} data={tmpGroups} onChange={this.updateValue} error={this.state.errors.groupId} {...inputProps} />

                        {this.state.step.stepOptions.groupId.value && <div>
                            <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.initial.selectState')} placeholder={i18n.t('automation:add.initial.selectStatePlaceholder')} name="subscriberState" value={this.state.step.stepOptions.subscriberState ? this.state.step.stepOptions.subscriberState.value : ''} data={[{ label: i18n.t('automation:add.initial.active'), value: "active" }, { label: i18n.t('automation:add.initial.inactive'), value: "inactive" }]} onChange={this.updateValue} error={this.state.errors.subscriberState} {...inputProps} />
                        </div>}
                    </div>}

                </div>}


                {this.state.step.stepOptions.triggerType.value == "9" && !this.props.isThemeDesigner && <div>


                    {!this.props.forms.length && <UILIB.WarningHolder>
                        <div><strong>No Groups Added</strong></div>
                    </UILIB.WarningHolder>}

                    {this.props.forms.length > 0 && <div>

                        <UILIB.Select outerClassName="mar-b25" label="Select Form" name="formId" value={this.state.step.stepOptions.formId?.value} data={this.props.forms.map(f => ({ label: f.formName, value: f.id }))} onChange={this.updateValue} error={this.state.errors.formId} {...inputProps} />

                        {!!form && <div>
                            <UILIB.Select
                                outerClassName="mar-b25" label="Form Activity" name="formActivity" value={this.state.step.stepOptions.formActivity?.value}
                                data={[{ label: 'Signed Up', value: "signup" }, { label: 'Downloaded File', value: "download", disabled: !form.FormFiles.length }]}
                                onChange={this.updateValue}
                                error={this.state.errors.formActivity} {...inputProps} />
                        </div>}
                        {!!form && this.state.step.stepOptions.formActivity?.value === 'download' && <div>
                            <UILIB.Select
                                outerClassName="mar-b25" label="File" name="formFile" value={this.state.step.stepOptions.formFile?.value}
                                data={[{ label: 'Any File', value: '-1' }, ...form.FormFiles.map(ff => ({ label: ff.url || ff.FileManagerFile.friendlyName, value: ff.id }))]}
                                onChange={this.updateValue}
                                error={this.state.errors.formFile} {...inputProps} />
                        </div>}
                    </div>}

                </div>}

                {this.state.step.stepOptions.triggerType.value == "10" && <div>
                    {!this.state.automations && <UILIB.LoadingIcons />}
                    {!!this.state.automations && <div className="mar-b20">
                        {!this.state.automations.length && <UILIB.WarningHolder>No Other Automations Found</UILIB.WarningHolder>}
                        {!!this.state.automations.length && <UILIB.Select name="otherAutomations" onChange={this.updateValue} data={this.state.automations.map(a => ({ label: a.name, value: a.id }))} value={this.state.step.stepOptions.otherAutomations?.value} multiple={true} label="Automations" />}
                    </div>}
                </div>}

                {this.state.step.stepOptions.triggerType.value == "11" && <div>
                    {!this.state.tags && <UILIB.LoadingIcons />}
                    {!!this.state.tags && <div className="mar-b20">
                        {!this.state.tags.length && <UILIB.WarningHolder>No Tags Found</UILIB.WarningHolder>}
                        {!!this.state.tags.length && <UILIB.Select outerClassName="mar-b15" name="tagType" onChange={this.updateValue} data={[{ value: 'has', label: 'Has a Tag' }, { value: 'has not', label: 'Does not have a tag' }]} value={this.state.step.stepOptions.tagType?.value} />}
                        {!!this.state.tags.length && <UILIB.Select name="tagId" onChange={this.updateValue} data={this.state.tags.map(a => ({ label: a.tagName, value: a.id }))} value={this.state.step.stepOptions.tagId?.value} label="Tag" />}
                    </div>}
                </div>}

                {this.state.step.stepOptions.triggerType.value == "12" && <div>
                    {/* before/after a specific date */}
                    <UILIB.Select label={i18n.t('automation:add.condition.datetimeLabel')} outerClassName="mar-b15" name="dateBeforeAfter" onChange={this.updateValue} data={[
                        { label: i18n.t('automation:add.condition.before'), value: 'before' },
                        { label: i18n.t('automation:add.condition.after'), value: 'after' },
                    ]} value={this.state.step.stepOptions.dateBeforeAfter?.value} />
                    <UILIB.TextInput type="datetime" value={this.state.step.stepOptions.datetime?.value} name="datetime" onChange={(e, v) => this.updateValue({ target: { value: v, name: 'datetime' } })} />
                </div>}

                {!!webhookDefinition && !this.props.isThemeDesigner && <div>
                    <TriggerFilters filters={this.state.step.stepOptions.filters || []} trigger={webhookDefinition.name} appId={webhookDefinition.ApplicationId} />
                </div>}

                {this.state.step.type === 'score' && showScore && <div>

                    <div className="txtInput_label" htmlFor="">{i18n.t('automation:add.condition.scoreLabel')}</div>
                    <div className="quickFlex">
                        <div style={{ flex: "1" }}>
                            <UILIB.Select outerClassName="mar-r10" name="scoreChange" value={this.state.step.stepOptions.scoreChange.value} onChange={this.updateValue} data={[{
                                label: 'Increment',
                                value: 'inc'
                            }, {
                                label: 'Decrement',
                                value: 'dec'
                            }]}
                                {...inputProps}
                            />
                        </div>
                        <div style={{ flex: "1" }}>
                            <UILIB.TextInput type="number" name="scoreValue" value={this.state.step.stepOptions.scoreValue.value} onChange={this.updateValue} {...inputProps} />
                        </div>
                    </div>
                </div>}

            </div>
            <div className="automationStepSaveButtonHolder">
                {readOnly ? (
                    <>
                        <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                    </>
                ) : (
                    <UILIB.Button className="button-primary" text={i18n.t('save')} onClick={this.saveStep} />
                )}
            </div>
        </div>
    }
}

export default DrawerConditionStep;
import React from 'react'
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})
export default class IncSalesmateChangeGroup extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return <div>

            <h4 className="mar-b25">Change the Groups</h4>
            <div className="mar-b25">You are about to set or change the groups that Salesmate syncs contacts with.</div>
            <div className="mar-b25">If you proceed, your Salesmate contacts  be automatically added to this group. </div>
            <div className="mar-b25">If you delete this group in the future, your Salesmate contacts will stop being synced.</div>
            <div className="mar-b25">You can change this group at any time.</div>

            <UILIB.Button text="Select this Group" className="button-primary" onClick={this.props.changeTheGroup} />

        </div>
    }
};


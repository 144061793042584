import React, { useState, useEffect } from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'

export default function ManagerCampaignFolders({ onFinish = () => { } }) {
    const [folders, setFolders] = useState(null)
    const [adding, setAdding] = useState(false)
    const [newName, setNewName] = useState('')
    const [renaming, setRenaming] = useState({})
    const [deleting, setDeleting] = useState(null)

    const getFolders = async () => {
        const res = await axios.get('/campaign/folder?orderBy=name')
        setFolders(res.data)
    }

    const toggleRenaming = folder => {
        const obj = { ...renaming }
        if (obj.hasOwnProperty(folder.id)) {
            delete obj[folder.id]
        } else {
            obj[folder.id] = folder.name
        }
        setRenaming(obj)
    }

    const editName = (id, value) => {
        const obj = { ...renaming }
        obj[id] = value
        setRenaming(obj)
    }

    const saveFolder = async folder => {
        if (renaming[folder.id].trim().length < 3) return
        await axios.put('/campaign/folder/' + folder.id, {
            name: renaming[folder.id]
        })
        await getFolders()
        toggleRenaming(folder)
        onFinish()
    }

    const save = async () => {
        if (newName.trim().length < 3) return
        await axios.post('/campaign/folder', {
            name: newName.trim()
        })
        await getFolders()
        setNewName('')
        setAdding(false)
        onFinish()
    }

    const confirmDelete = async () => {
        await axios.delete('/campaign/folder/' + deleting.id)
        await getFolders()
        setDeleting(null)
        onFinish()
    }

    useEffect(() => {
        getFolders()
    }, [])

    if (!folders) return <UILIB.LoadingIcons />

    const iconStyle = { display: 'flex', alignItems: 'center', cursor: 'pointer' }
    const textStyle = { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }

    return (
        <div>
            <h4 className="mar-b20">Manage Campaign Folders</h4>
            {!adding && <UILIB.Button className="button-primary mar-b20" onClick={() => setAdding(true)}>Add Folder</UILIB.Button>}
            {adding && <div className="quickFlex mar-b20" style={{ gap: 10, alignItems: 'center' }}>
                <UILIB.TextInput focus outerStyle={{ flexGrow: 1 }} value={newName} onChange={e => setNewName(e.target.value)} placeholder="Folder Name"
                    onEscapePress={() => {
                        setAdding(false)
                        setNewName('')
                    }}
                    onEnterPress={save}
                />
                <UILIB.Icons style={iconStyle} icon="tick" color="black" onClick={save} />
                <UILIB.Icons style={iconStyle} icon="cross" color="black" onClick={() => {
                    setAdding(false)
                    setNewName('')
                }} />
            </div>}
            {folders.map(folder => {
                return (
                    <UILIB.Paper key={folder.id} style={{ padding: 12, alignItems: 'center', justifyContent: 'space-between' }} className="mar-b10 quickFlex">
                        {renaming.hasOwnProperty(folder.id) && <div className="quickFlex" style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: 10 }}>
                            <UILIB.TextInput className="txtInput_small" focus outerStyle={{ flexGrow: 1 }} value={renaming[folder.id]}
                                onChange={(e) => editName(folder.id, e.target.value)}
                                onEnterPress={() => saveFolder(folder)}
                                onEscapePress={() => toggleRenaming(folder)}
                            />
                            <UILIB.Icons style={iconStyle} icon="tick" color="black" onClick={() => saveFolder(folder)} />
                            <UILIB.Icons style={iconStyle} icon="cross" color="black" onClick={() => toggleRenaming(folder)} />
                        </div>}

                        {!renaming.hasOwnProperty(folder.id) && <> <div style={textStyle}>{folder.name}</div>
                            <div className="quickFlex" style={{ alignItems: 'center', gap: 10 }}>
                                {deleting === folder && <>
                                    <UILIB.Button className="button-xs button-red" onClick={confirmDelete}>Delete</UILIB.Button>
                                    <UILIB.Button className="button-xs" onClick={() => setDeleting(null)}>Cancel</UILIB.Button>
                                </>}
                                {deleting !== folder && <>
                                    <UILIB.Icons style={iconStyle} icon="pencil" color="black" onClick={() => toggleRenaming(folder)} />
                                    <UILIB.Icons style={iconStyle} icon="crossCircle" color="red" onClick={() => setDeleting(folder)} />
                                </>}
                            </div>
                        </>}
                    </UILIB.Paper>
                )
            })}
        </div>
    )
}
import React, { Component } from 'react'
import DragDropFunctions from '../../../../dragDropFunctions';

export default class Option extends Component {
    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick(ev) {
        ev.preventDefault();
    }

    render() {
        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "label", this.props.localVariables)
        }

        if (typeof this.props.elementData.content.value == "object") {
            return <optgroup disabled selected hidden>{this.props.elementData.content.value.map((theOpt, index) => {
                var theVal = theOpt;
                var theLab = theOpt;
                if (typeof theOpt == "object") {
                    theVal = theOpt.value;
                    theLab = theOpt.label;
                }
                return <option onClick={this.onClick} {...eleProps} disabled selected hidden value={theOpt}>{theLab}</option>
            })}
            </optgroup>
        }

        var thisVal = DragDropFunctions.lookupLocalVariables(this.props.localVariables, this.props.elementData.content.value);

        return <option onClick={this.onClick} {...eleProps} disabled selected hidden value={thisVal}>{thisVal}</option>


    }
}
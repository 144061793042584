import React, { useState, useEffect } from 'react'
import axios from "~/data/http/axios";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import TextInput from "../../components/textInput";
import Button from "../../components/button";
import * as styles from "./index.module.scss";
import AlertDialog from '../../components/alertDialog';
import Composer from '../../inbox/components/shared/composer';
import Header from '../../components/header';
import Dialog from '../../components/dialog';
import { parseChannelType } from '~/Classes/conversations';
import i18n from "~/i18n";

export default function InboxTemplates({ inbox }) {
    const [templates, setTemplates] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [deletingTemplate, setDeletingTemplate] = useState(null);


    useEffect(() => {
        getTemplates()
    }, [inbox.id])

    const getTemplates = async () => {
        const res = await axios.get(`/inboxes/${inbox.id}/template`)
        setTemplates(res.data)
        setSelectedTemplate(null)
        setDeletingTemplate(null)
    }

    if (!templates) return <UILIB.LoadingIcons />

    const data = templates.filter((template) => {
        if (!searchTerm) return true;
        return template.name.toLowerCase().includes(searchTerm.toLowerCase()) || template.text.toLowerCase().includes(searchTerm.toLowerCase())
    }).map(template => {
        return {
            className: styles.tableRow,
            id: { value: template.id },
            name: {
                headerValue: i18n.t("chat:settings.replies.name"),
                value: template.name
            },
            // type: {
            //     headerValue: 'Type',
            //     value: <UILIB.SquareChip>{parseChannelType(template.type)}</UILIB.SquareChip>
            // },
            options: {
                headerValue: "",
                value: (
                    <div className={styles.tableActions}>
                        <UILIB.DropdownMenu.Root>
                            <UILIB.DropdownMenu.Trigger asChild>
                                <Button
                                    aria-label="Additional options"
                                    variant="ghost"
                                    size="s"
                                    iconOnly
                                >
                                    <UILIB.Icon name="dots-vertical" />
                                </Button>
                            </UILIB.DropdownMenu.Trigger>

                            <UILIB.DropdownMenu.Portal>
                                <UILIB.DropdownMenu.Content align="end">
                                    <UILIB.DropdownMenu.Item
                                        variant="danger"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            setDeletingTemplate(template)
                                            return false
                                        }}
                                    >
                                        {i18n.t("chat:settings.replies.remove")}
                                    </UILIB.DropdownMenu.Item>
                                </UILIB.DropdownMenu.Content>
                            </UILIB.DropdownMenu.Portal>
                        </UILIB.DropdownMenu.Root>
                    </div>
                ),
            },
        }
    })

    return (
        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
            <Header type="inbox" option="templates" />
            <div className={styles.topRow}>
                <form>
                    <TextInput
                        value={searchTerm}
                        placeholder={i18n.t("chat:settings.replies.searchPlaceholder")}
                        aria-label="Search for a reply"
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                    />
                </form>

                <Button
                    size="s"
                    iconLeft={<UILIB.Icon name="plus" />}
                    onClick={() => {
                        setSelectedTemplate({})
                    }}
                >
                    {i18n.t("chat:settings.replies.new")}
                </Button>
            </div>

            {data.length > 0 && <UILIB.DataTable1 tableData={data} rowClick={row => setSelectedTemplate(templates.find(t => t.id === row.id.value))} />}
            {data.length === 0 && !searchTerm && <div className={styles.noData}>{i18n.t("chat:settings.replies.noReplies")}</div>}
            {data.length === 0 && searchTerm && <div className={styles.noData}>{i18n.t("chat:settings.replies.noFound")}</div>}

            <DeleteTemplate
                template={deletingTemplate}
                isOpen={!!deletingTemplate}
                onOpenChange={getTemplates}
            />

            <EditTemplate template={selectedTemplate} isOpen={!!selectedTemplate} InboxId={inbox.id} onFinish={getTemplates} />
        </div>
    )
}

function EditTemplate({ template, InboxId, isOpen, onFinish = () => { } }) {
    const [name, setName] = useState('')
    const [text, setText] = useState('')
    const [type, setType] = useState(1)
    const [errors, setErrors] = useState({})

    const classes = classnames(
        styles.root,
    );

    const save = async () => {
        const errs = {}
        if (!name) errs.name = i18n.t("chat:settings.replies.required")
        if (!text) errs.text = i18n.t("chat:settings.replies.required")
        setErrors(errs)
        if (Object.keys(errs).length) return
        if (template.id) {
            await axios.put(`/inboxes/${InboxId}/template/${template.id}`, { name, text, type })
        } else {
            await axios.post(`/inboxes/${InboxId}/template`, { name, text, type })
        }
        onFinish()
        setName('')
        setText('')
    }

    useEffect(() => {
        if (template) {
            setName(template.name)
            setText(template.text)
        }
    }, [template])

    return (
        <Dialog fullScreen open={isOpen} onOpenChange={o => {
            if (!o) onFinish()
        }} title={(template?.id ? i18n.t("chat:settings.replies.editHeader") : i18n.t("chat:settings.replies.addHeader"))}>
            <div className={styles.topRow}>
                <form>
                    <TextInput
                        label={i18n.t("chat:settings.replies.editTitle")}
                        value={name}
                        placeholder={i18n.t("chat:settings.replies.editTitleDesc")}
                        aria-label="Saved Reply Name"
                        onChange={e => setName(e.target.value)}
                        error={errors.name}
                    />
                </form>
            </div>
            <div>
                <div className={styles.label}>{i18n.t("chat:settings.replies.editContent")}</div>
                <div className={classes}>
                    <Composer
                        error={errors.text}
                        message={text}
                        setMessage={setText}
                    />
                </div>
            </div>

            <div className={styles.footer}>
                <Button onClick={save} size="s">
                    {`${template?.id ? i18n.t("chat:settings.replies.editUpdate") : i18n.t("chat:settings.replies.editCreate")}`}
                </Button>
            </div>
        </Dialog>
    )
}

function DeleteTemplate({ template, isOpen = false, onOpenChange = () => { } }) {
    const [deleting, setDeleting] = useState(false)
    async function handleSubmit() {
        setDeleting(true);

        try {
            await axios.delete('/inboxes/' + template.InboxId + '/template/' + template.id)
        } finally {
            setDeleting(false)
        }
        onOpenChange()
    }

    return (
        <AlertDialog
            open={isOpen}
            title={i18n.t("chat:settings.replies.deleteTitle")}
            description={i18n.t("chat:settings.replies.deleteDesc", { name: template?.name })}
            ctaText={i18n.t("chat:settings.replies.deleteConfirm")}
            submitting={deleting}
            onCtaClick={handleSubmit}
        >
        </AlertDialog>
    );
}
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import EmojiData from '~/Classes/emojis';
import SiteVars from '~/Classes/siteVars';

export default class SmileyPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentCat: "Emoticons"
        }

        this.changeCat = this.changeCat.bind(this);
        this.pickEmoji = this.pickEmoji.bind(this);
    }

    changeCat(event) {
        this.setState({ currentCat: event.target.value });
    }
    pickEmoji(theChar) {
        this.props.onPick(theChar);
    }
    render() {
        let repoEndPoint = SiteVars.repoEndPoint;
        if (window.location.host.indexOf('localhost:8080') > -1 || window.location.host.indexOf('127.0.0.1') > -1 || window.location.host.indexOf('web.staging.dcloud.mpztesting.com/') > -1) {
            repoEndPoint = "https://cdn1.ourmailsender.com/";
        }
        return <div>
            <h4 className="mar-b25">Add an Emoticon</h4>

            <UILIB.Select outerClassName="mar-b25" label="Category" data={EmojiData.emojiCats} onChange={this.changeCat} value={this.state.currentCat} />
            {EmojiData.emojiData.filter(theEmoji => theEmoji.category == this.state.currentCat).map((theEmoji, index) => {
                var theImage = repoEndPoint + "siteContent/emojis/" + theEmoji.image;
                return <span style={{ paddingRight: "5px", paddingBottom: "5px", cursor: "pointer" }} key={index}>
                    <img key={index} src={theImage} style={{ width: "25px", height: "25px" }} onClick={() => { this.pickEmoji(theEmoji.character) }} />
                </span>
            })}
        </div>

    }

}

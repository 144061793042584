import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster }
})

export default class CopyPasteAddGroupsTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupName: "",
            groupID: 0,
            inputField: "",
            importValueIsError: "",
            formHasErrors: true,
            containsHeader: false,
            isBlackList: false,
            otherGroupData: [{ label: i18n.t('subscribers:add.import.importThem'), value: 1 }, { label: i18n.t('subscribers:add.import.dontImportThem'), value: 0 }, { label: i18n.t('subscribers:add.import.removeFromOtherGroups'), value: 2 }],
            importDuplicates: 1,
            app: undefined
        };
        this.updateImportValue = this.updateImportValue.bind(this);
        this.importSubscribers = this.importSubscribers.bind(this);
        this.onChange = this.onChange.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
    }

    lookupGroup(groupID) {
        var self = this;
        axios.get('/group/' + groupID).then((res) => {
            self.setState({ groupName: res.data.groupName });
        }).catch(function () {
            self.props.history.push('/cp/groups/');
        });
    }

    componentDidMount() {
        var isBlackList = true;
        if (this.props.accountMaster.accountMaster.blackListGroupId != this.props.match.params.groupID) {
            isBlackList = false;
        }

        axios.get('/accountMaster/application').then(response => {
            this.setState({
                app: response.data.Applications.find(a => a.appSettings.groupId == this.props.match.params.groupID && a.Application.options.importOnCreate)
            })
        })

        this.setState({ groupID: this.props.match.params.groupID, isBlackList: isBlackList }, () => { this.lookupGroup(this.props.match.params.groupID) });
    }

    goBack() {
        this.props.history.push('/cp/groups/' + this.state.groupID + '/add/')
    }

    updateImportValue(evt) {
        var thisText = evt.target.value;
        if (thisText.length < 3) {
            this.setState({ importValueIsError: i18n.t('subscribers:add.copy.alertPleaseCopy') });
            this.setState({ formHasErrors: true });
        } else {
            this.setState({ importValueIsError: "" });
            this.setState({ formHasErrors: false });
        }
        var totalNewLines = thisText.split('\n').length;
        var totalTabs = thisText.split('\t').length;
        var containsHeader = totalNewLines > 1 && ((totalTabs - 1) / (totalNewLines - 1)) % 1 === 0;

        this.setState({ inputfield: evt.target.value, containsHeader });
    }

    onChange(event) {
        var state = this.state;
        state[event.target.name] = event.target.checked;
        this.setState(state)
    }
    changeStatus(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    importSubscribers() {
        var postData = {
            subscriberData: this.state.inputfield,
            containsHeader: this.state.containsHeader,
            importDuplicates: this.state.importDuplicates
        }
        if (this.state.isBlackList === true) {
            postData.subscriberState = "Blacklisted"
        }
        if (this.state.formHasErrors == false) {
            if (this.state.app) {
                postData.webhooksInbound = this.state.app.Application.options.importOnCreate
            }
            axios.post('/group/' + this.state.groupID + '/import/text', postData).then((res) => {
                if (res.data.excel) {
                    this.props.history.push('/cp/groups/' + this.state.groupID + '/add/import/setup/' + res.data.importJobId);
                } else {
                    this.props.history.push('/cp/groups/' + this.state.groupID + '/add/import/progress/' + res.data.id);
                }
            }).catch((err) => {
                this.setState({ importValueIsError: err.data.error });
            });
        }
    }

    render() {
        return <div>


            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={8} margin={0}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">
                            {i18n.t('subscribers:add.copy.details')}
                        </h4>

                        <UILIB.TextArea
                            label={i18n.t('subscribers:add.copy.label')}
                            onChange={this.updateImportValue}
                            error={this.state.importValueIsError}
                            rows="20"
                            outerClassName="mar-b25" />

                        <UILIB.Toggle
                            label={i18n.t('subscribers:add.copy.header')}
                            before={i18n.t('no')}
                            after={i18n.t('yes')}
                            value={this.state.containsHeader}
                            name="containsHeader"
                            onChange={this.onChange}
                            className="mar-b25" />

                        <UILIB.Select
                            name="importDuplicates"
                            data={this.state.otherGroupData}
                            onChange={this.changeStatus}
                            value={this.state.importDuplicates}
                            label={i18n.t('subscribers:add.import.whatDoYouWantToHappenGroup')}
                            outerClassName="mar-b25"
                        />



                        {!!this.state.app && <p className='text-md text-heavy text-primary'>Copying into this Group will also add the Contacts to {this.state.app.Application.appName}</p>}
                        <UILIB.Button
                            className="button-primary"
                            onClick={this.importSubscribers}
                            text={i18n.t('subscribers:add.copy.button')}
                            iconRight={<UILIB.Icons icon="arrowRight" />}
                        />

                    </UILIB.Paper >
                    {this.state.formError}
                </UILIB.Column >
                <UILIB.Column xs={12} sm={12} md={4} margin={0}>
                    {!this.state.isBlackList && <UILIB.Paper className="paper-primary">
                        <h4 className="mar-b25">{i18n.t('subscribers:add.single.infoHeader')}</h4>
                        <p>{i18n.t('subscribers:add.single.infoHeader2')}</p>
                        <h4 className="mar-b25">{i18n.t('subscribers:add.single.infoSubHeader')}</h4>
                        <p>{i18n.t('subscribers:add.single.infoText')}</p>
                        <p>{i18n.t('subscribers:add.single.infoText2')}</p>
                    </UILIB.Paper>
                    }
                    {this.state.isBlackList && <UILIB.Paper className="paper-primary">
                        <h4 className="mar-b25">
                            {i18n.t('subscribers:add.fromGroup.addBlacklistHeader')}
                        </h4>
                        <p>{i18n.t('subscribers:add.fromGroup.addBlacklistDesc')}</p>

                    </UILIB.Paper>}
                </UILIB.Column>
            </UILIB.Row >

        </div >
    };
};

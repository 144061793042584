import React from "react";
import axios from "axios";
import DBConn from "~/Classes/siteVars.js";
import { connect } from "react-redux";
import queryString from "query-string";
import UILIB from "~/Common-Objects/Lib";
import GoogleAnalytics from "../../Classes/googleAnalytics";
import helpers from "~/Classes/helpers";

@connect((store) => {
  return {
    user: store.user,
    siteMaster: store.siteMaster,
  };
})
export default class ActivateTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      invalid: false,
      linkUrl: "/",
    };
  }

  componentDidMount() {
    const query = queryString.parse(location.search);
    const ourToken = query.token;

    var baseUrl = DBConn.getConnString();
    //lookup token and see if its a brand new user
    axios
      .put(baseUrl + "/register", { token: ourToken })
      .then(() => {
        if (query.redirect) {
          const redirect = helpers.safeRedirect(query.redirect, "return", null);
          const linkUrl = `${this.state.linkUrl}?redirect=${redirect}`;
          this.setState({ linkUrl });
        }

        this.timerId = setTimeout(() => this.autoRedirect(), 3000);
      })
      .catch(() => {
        this.setState({ invalid: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.siteMaster && this.props.siteMaster) {
      if (
        prevProps.siteMaster.googleAnalyticsKey !=
        this.props.siteMaster.googleAnalyticsKey
      ) {
        try {
          GoogleAnalytics.trackGAPageView(this.props.history.location.pathname);
        } catch (err) { }
      }
    }
  }

  autoRedirect() {
    this.props.history.push(this.state.linkUrl);
  }

  render() {
    if (this.state.loading) {
      return (
        <UILIB.LoadingIcons
          iconType="2"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)",
          }}
        />
      );
    }

    return (
      <div
        className="quickFlex"
        style={{
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        {this.state.invalid ? (
          <UILIB.Paper className="with_header">
            <div
              className="paper_header"
              style={{
                backgroundColor: this.props.siteMaster.colours["$primary"],
                color: "white",
              }}
            >
              <div className="quickFlex">
                <UILIB.Icons
                  icon="alert"
                  color={"#FFFFFF"}
                  style={{ width: "20px", height: "20px" }}
                />
                <div className="mar-l10 text-heavy" style={{ fontSize: 16 }}>
                  Something went wrong
                </div>
              </div>
            </div>
            <div className="paper_content">
              <div className="mar-b25">
                Link Expired, please login and request verification again
              </div>
              <UILIB.Button
                text="Continue"
                onClick={() => {
                  this.props.history.push("/");
                }}
              />
            </div>
          </UILIB.Paper>
        ) : (
          <UILIB.Paper>
            <p>
              Your account has been successfully verified. Redirecting you to
              login in a few seconds…
            </p>
          </UILIB.Paper>
        )}
      </div>
    );
  }
}

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFormatFunctions from '~/Classes/numberFormatFunctions';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import CampaignPieChart from '../../shared/graphs/pie';
import i18n from '~/i18n'
import Map from '../../shared/graphs/map'
import GenericStatsHolder from '../../shared/components/genericStatsHolder';
import InDepthChart from '../../shared/graphs/indepthChart'

import RequiresSiteTracking from '../../shared/components/requiresSiteTracking'

//CONNECT TO STORE
@connect((store) => {
	return { siteMaster: store.siteMaster, user: store.user, accountMaster: store.accountMaster }
})

export default class VisitorsView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageLoaded: false,
			visitorsTable: []
		};
		this.getVisitors = this.getVisitors.bind(this);
	}

	componentDidMount() {
		this._ismounted = true;
		this.getVisitors();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.endDate != this.props.endDate || prevProps.startDate != this.props.startDate) {
			this.getVisitors();
		}
	}

	async getVisitors() {
		let visitors = await axios.get(`/ads/campaigns/${this.props.adCampaign.id}/visitors?startDate=${this.props.startDate.format("YYYY-MM-DD")}&endDate=${this.props.endDate.format("YYYY-MM-DD")}`)
		visitors = visitors.data;
		let visitorsTable = visitors.map(row => {
			return {
				"subscriber": {
					headerValue: "Subscriber",
					value: row.SubscriberId ? <a onClick={() => { window.open(`/cp/subscriber/` + row.SubscriberId, '_blank') }} style={{ display: "flex" }}>
						<span>{row.emailAddress}</span>
						<UILIB.Icons icon="external" className="mar-l10" />
					</a> : "Anonymous"
				},
				"date": {
					headerValue: "Date",
					value: row.createdAt ? DateTimeFunctions.formatDateTime(row.createdAt) : ""
				},
				"site": {
					headerValue: "Site",
					value: <a onClick={() => { window.open(`/cp/trackers/` + row.SiteTrackerId, '_blank') }} style={{ display: "flex" }}>
						<span>{row.url}</span>
						<UILIB.Icons icon="external" className="mar-l10" /></a>
				},
				"path": {
					headerValue: "URL",
					value: row.path
				},
				"events": {
					headerValue: "Event(s)",
					value: row.event && row.event.name ? <UILIB.SquareChip>{row.event.name}</UILIB.SquareChip> : ""
				}
			}
		})
		this.setState({ pageLoaded: true, visitorsTable })
	}
	render() {

		if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />
		if (!this.props.hasSiteTracking) return <RequiresSiteTracking history={this.props.history} />
		let campaign = this.props.campaign;

		return <div>
			<UILIB.Row>
				<UILIB.Column xs={12}>
					<UILIB.Paper>
						<UILIB.DataTable1
							pageSize={10}
							noResultsTxt={"We haven't tracked any visitors coming from this campaign yet"}
							tableData={this.state.visitorsTable}
							dataUpdater={() => { }}
							loadingData={this.state.pageLoaded}
							sortedColumn={""}
							sortedDirection={""}
							width="100%"
							hasCheckBoxes={true}
							className="mar-b15"
						/>
					</UILIB.Paper>
				</UILIB.Column>
			</UILIB.Row>

		</div >
	}
}
import React, { Component } from 'react';
import UILIB from '../../../../../Common-Objects/Lib';
import axios from '~/data/http/axios'
import { connect } from 'react-redux';
import AddGroupView from '~/pages/cp/subscribers/groups/incGoAddGroup';
import SegmentEditor from '~/pages/cp/includes/segmentEditor/segmentEditor'
import IncAddCustomField from '~/pages/cp/subscribers/customfields/incAddCustomField'
import TriggerFilters from '../integrations/generic/triggerFilters'
import AddTrackingPage from '../../../tracking/addPage'
import i18n from '~/i18n'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { isSegmentValidForAutomation } from '../../utils';
import CampaignSelector from './utils/campaignSelector';
import HelpPopup from '../helpPopup/helpPopup';
@connect((store) => {
    return { siteMaster: store.siteMaster }
})
class DrawerStepInitial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerContent: undefined,
            step: this.props.step,
            errors: {},
            siteTrackers: [],
            siteTrackerEvents: [],
            siteTrackersAny: [],
            siteTrackersLoading: false,
            siteTrackersLinks: [],
            siteTrackerLinksLoading: false,
            siteTrackersLinksSearchText: "",
            invalidSegment: false
        }
        this.updateTriggerType = this.updateTriggerType.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.createGroup = this.createGroup.bind(this);
        this.createdGroup = this.createdGroup.bind(this);
        this.createSegment = this.createSegment.bind(this);
        this.createdSegment = this.createdSegment.bind(this);
        this.createField = this.createField.bind(this);
        this.createdField = this.createdField.bind(this);
        this.saveStep = this.saveStep.bind(this);
        this.getSiteTrackers = this.getSiteTrackers.bind(this);
        this.updateSiteTrackerLinks = this.updateSiteTrackerLinks.bind(this);
        this.viewEditSegment = this.viewEditSegment.bind(this);
        this.createSitePage = this.createSitePage.bind(this)
        this.createdSitePage = this.createdSitePage.bind(this)
    }

    componentDidMount() {
        if (this.state.step.triggerType.value == "7" || this.state.step.triggerType.value == "20") {
            this.getSiteTrackers();
            this.updateSiteTrackerLinks();
        }
    }

    createField() {
        var drawerContent = <IncAddCustomField getCustomFields={this.createdField} forceType={"DATE"} standalone={true} />
        this.setState({ drawerContent })
    }
    createdField(newField) {
        var customFieldId = newField.id;
        var step = this.state.step;
        step.stepOptions.customFieldId.value = customFieldId;
        step.complete = false;
        this.setState({ drawerContent: undefined, step }, () => { this.props.getCustomFields(false); })
    }
    createSegment() {
        var drawerContent = <SegmentEditor history={this.props.history} close={(segData) => {
            this.createdSegment(segData);
        }} />
        this.setState({ drawerContent }, () => {
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("100vw"));
        })
    }
    createdSegment(segmentData) {
        var segmentId = segmentData.data.Segment.segmentId;
        var step = this.state.step;
        step.stepOptions.segmentId.value = segmentId;
        step.complete = false;
        this.setState({ drawerContent: undefined, step }, () => {
            this.props.getSegments(false);
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("500px"));;
        })
    }
    viewEditSegment() {
        var step = this.state.step;
        var segmentId = step.stepOptions.segmentId.value;
        var drawerContent = <SegmentEditor history={this.props.history} close={() => {
            this.setState({ drawerContent: undefined })
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("500px"));
        }} segmentId={segmentId} />
        this.setState({ drawerContent }, () => {
            this.props.dispatch(siteMaster.alterSiteDrawerWidth("100vw"));
        })
    }
    createGroup() {
        var drawerContent = <AddGroupView addedGroup={this.createdGroup} dontCloseDrawer={true} />
        this.setState({ drawerContent })
    }
    createdGroup(groupData) {
        var groupId = groupData.data.groupId;
        var step = this.state.step;
        step.stepOptions.groupId.value = groupId;
        step.complete = false;
        this.setState({ drawerContent: undefined, step }, () => { this.props.getGroups(false); })
    }
    createSitePage() {
        const drawerContent = <AddTrackingPage SiteTrackerId={this.state.step.stepOptions.trackingId.value} onCreated={this.createdSitePage} onCancel={() => this.setState({ drawerContent: undefined })} />
        this.setState({ drawerContent })
    }
    createdSitePage(newPage) {
        this.updateSiteTrackerLinks();
        var step = this.state.step;
        step.stepOptions.url.value = newPage.path
        this.setState({ drawerContent: undefined, step })
    }
    updateTriggerType(event) {
        var triggerType = event.target.value;
        var stepOptions = {};

        if (triggerType != "5") {
            //add group id
            var groupId = this.state.step.stepOptions && this.state.step.stepOptions.groupId ? this.state.step.stepOptions.groupId.value : "-1";
            if (groupId == "-1000" && (triggerType != 1 || triggerType != 2)) groupId = -1
            stepOptions.groupId = { required: true, value: groupId }
        }
        if (triggerType == "1" || triggerType == "2") {
            //add segment id
            var segmentId = this.state.step.stepOptions && this.state.step.stepOptions.segmentId ? this.state.step.stepOptions.segmentId.value : "-1";
            stepOptions.segmentId = { required: true, value: segmentId }
        }
        if (triggerType == "3" || triggerType == "4") {
            //add date field
            var customFieldId = this.state.step.stepOptions && this.state.step.stepOptions.customFieldId ? this.state.step.stepOptions.customFieldId.value : "-1";
            var onBeforeDate = this.state.step.stepOptions && this.state.step.stepOptions.onBeforeDate ? this.state.step.stepOptions.onBeforeDate.value : "on";
            var onBeforeDateValue = this.state.step.stepOptions && this.state.step.stepOptions.onBeforeDateValue ? this.state.step.stepOptions.onBeforeDateValue.value : "0";
            var onBeforeDateUnits = this.state.step.stepOptions && this.state.step.stepOptions.onBeforeDateUnits ? this.state.step.stepOptions.onBeforeDateUnits.value : "minute";
            stepOptions.customFieldId = { required: true, value: customFieldId }
            stepOptions.onBeforeDate = { required: true, value: onBeforeDate }
            stepOptions.onBeforeDateValue = { value: onBeforeDateValue }
            stepOptions.onBeforeDateUnits = { value: onBeforeDateUnits }
        }
        if (triggerType == "5") {
            //add date field
            var link = this.state.step.stepOptions && this.state.step.stepOptions.link ? this.state.step.stepOptions.link.value : "https://";
            stepOptions.link = { required: true, value: link }
        }
        if (triggerType == "6" || triggerType == "8" || triggerType == "9" || triggerType == "15") {
            //campaign id for reply tracking or campaignId for open
            var campaignId = this.state.step.stepOptions && this.state.step.stepOptions.campaignId ? this.state.step.stepOptions.campaignId.value : "-1";
            stepOptions.campaignId = { required: true, value: campaignId }
        }
        if (triggerType == "7") {
            //visits a page on your website
            var url = this.state.step.stepOptions && this.state.step.stepOptions.url ? this.state.step.stepOptions.url.value : "-1";
            var trackingId = this.state.step.stepOptions && this.state.step.stepOptions.trackingId ? this.state.step.stepOptions.trackingId.value : "-1";
            var visits = Number(this.state.step.stepOptions && this.state.step.stepOptions.visits ? this.state.step.stepOptions.visits.value : 0);
            stepOptions.url = { required: true, value: url }
            stepOptions.trackingId = { required: true, value: trackingId }
            stepOptions.visits = { required: true, value: visits }
            this.getSiteTrackers();
        }
        if (triggerType == "20") {
            //site tracking event
            var eventId = this.state.step.stepOptions && this.state.step.stepOptions.eventId ? this.state.step.stepOptions.eventId.value : "-1";
            var trackingId = this.state.step.stepOptions && this.state.step.stepOptions.trackingId ? this.state.step.stepOptions.trackingId.value : "-1";
            var value = this.state.step.stepOptions && this.state.step.stepOptions.value ? this.state.step.stepOptions.value.value : 0;
            var operator = Number(this.state.step.stepOptions && this.state.step.stepOptions.operator ? this.state.step.stepOptions.operator.value : "=");
            stepOptions.eventId = { required: true, value: eventId }
            stepOptions.trackingId = { required: false, value: trackingId }
            stepOptions.value = { required: false, value: value }
            stepOptions.operator = { required: false, value: operator }
            this.getSiteTrackers();
        }

        if (triggerType == "8") {
            // came from another automation or api call
        }

        if (triggerType == "17") {
            const formId = this.state.step.stepOptions && this.state.step.stepOptions.formId ? this.state.step.stepOptions.formId.value : ""
            const formType = this.state.step.stepOptions && this.state.step.stepOptions.formType ? this.state.step.stepOptions.formType.value : ""
            const formContactType = this.state.step.stepOptions && this.state.step.stepOptions.formContactType ? this.state.step.stepOptions.formContactType.value : "-1"
            stepOptions.formId = { required: true, value: formId }
            stepOptions.formType = { required: true, value: formType }
            stepOptions.formContactType = { required: true, value: formContactType }
        }

        if (triggerType == "19") {
            const formId = this.state.step.stepOptions && this.state.step.stepOptions.formId ? this.state.step.stepOptions.formId.value : ""
            const formFile = this.state.step.stepOptions && this.state.step.stepOptions.formFile ? this.state.step.stepOptions.formFile.value : "-1"
            stepOptions.formId = { required: true, value: formId }
            stepOptions.formFile = { required: true, value: formFile }
        }

        if (triggerType == "18") {
            const tagId = this.state.step.stepOptions && this.state.step.stepOptions.tagId ? this.state.step.stepOptions.tagId.value : ""
            stepOptions.tagId = { required: true, value: tagId }
        }

        if (!parseInt(triggerType)) {
            let app = this.props.applications.find(a => a.Application.appName === triggerType)
            if ((this.props.isThemeDesigner && this.props.app) || app) {
                stepOptions.trigger = { value: '', required: true }
                if (stepOptions.filters) delete stepOptions.filters;
            }
        }


        var step = this.state.step;
        step.triggerType.value = triggerType;
        step.stepOptions = stepOptions;
        step.complete = false;
        this.setState({ step })
    }
    updateValue(event) {
        let eventTargetName = "";
        let eventTargetValue = "";

        if (event.target) {
            eventTargetName = event.target.name;
            eventTargetValue = event.target.value;
        }
        else if (event.type && event.type == "checkbox") {
            eventTargetName = event.name;
            eventTargetValue = event.value;
        }
        // type: "checkbox", name: "url", value: event.target.value, checked: event.target.checked
        let errors = this.state.errors;
        if (errors[eventTargetName]) {
            errors[eventTargetName] = "";
            this.setState({ errors })
        }

        var step = this.state.step;
        step.complete = false;
        if (!step.stepOptions[eventTargetName]) step.stepOptions[eventTargetName] = {};
        step.stepOptions[eventTargetName].value = eventTargetValue;

        if (eventTargetName == "trackingId") {
            this.updateSiteTrackerLinks();
        }
        if (eventTargetName == "trigger") {
            //changing app trigger type, reset filters
            step.stepOptions.filters = [];
        }
        this.setState({ step })
    }
    saveStep() {
        var errors = {};
        var error = false;
        var step = this.state.step;
        if (step.triggerType.value == "-1") {
            errors.triggerType = " ";
            error = true;
        }

        this.setState({ invalidSegment: false })


        if (step.triggerType.value != "-1" && (step.triggerType.value == "0" || step.triggerType.value == "1" || step.triggerType.value == "2" || step.triggerType.value == "3" || step.triggerType.value == "4") && step.stepOptions.groupId.value == "-1") {
            errors.groupId = " ";
            error = true;
        }

        if (step.triggerType.value === "1" && step.stepOptions.segmentId.value !== "-1") {
            // check if they have selected a segment with rules that will break the logic (dates)
            const segment = this.props.segments.find(s => s.value == step.stepOptions.segmentId.value)
            if (segment) {
                if (!isSegmentValidForAutomation(segment)) {
                    errors.segmentId = "segment contains incompatible rules";
                    this.setState({ invalidSegment: true })
                    error = true;
                }
            } else {
                errors.segmentId = "Segment Not Found";
                error = true;
            }
        }

        if (step.triggerType.value == "0" || step.triggerType.value == "3" || step.triggerType.value == "4" || ((step.triggerType.value == "1" || step.triggerType.value == "2") && step.stepOptions.groupId.value != "-1000")) {
            if (!step.stepOptions.subscriberState ||
                (step.stepOptions.subscriberState &&
                    (!step.stepOptions.subscriberState.value || !step.stepOptions.subscriberState.value.length)
                )) {
                errors.subscriberState = " ";
                error = true;
            }
        }


        if ((step.triggerType.value == "1" || step.triggerType.value == "2") && step.stepOptions.segmentId.value == "-1") {
            errors.segmentId = " ";
            error = true;
        }

        if (step.triggerType.value == "3" || step.triggerType.value == "4") {

            if (step.stepOptions.customFieldId.value == "-1") {
                errors.customFieldId = " ";
                error = true;
            }
            if ((step.stepOptions.onBeforeDate.value == "before" || step.stepOptions.onBeforeDate.value == "after") && (step.stepOptions.onBeforeDateValue.value == "0" || step.stepOptions.onBeforeDateValue.value == "")) {
                errors.onBeforeDateValue = " ";
                error = true;
            }
        }

        if (step.triggerType.value == "5" && (!step.stepOptions.link.value || step.stepOptions.link.value == "" || step.stepOptions.link.value.length < 10 || step.stepOptions.link.value.indexOf(".") < 0)) {
            errors.link = " ";
            error = true;
        }

        if ((step.triggerType.value == "8" || step.triggerType.value == "9") && (!step.stepOptions.campaignId.value || step.stepOptions.campaignId.value == "" || step.stepOptions.campaignId.value == "-1")) {
            errors.campaignId = " ";
            error = true;
        }

        if (step.triggerType.value == "7") {
            if (!step.stepOptions.trackingId.value || step.stepOptions.trackingId.value == "" || step.stepOptions.trackingId.value == "-1") {
                errors.trackingId = " ";
                error = true;
            }
            if (step.stepOptions.url.value == "-1") {
                errors.url = " ";
                error = true;
            }
        }
        if (step.triggerType.value == "20") {
            if (!step.stepOptions.eventId.value || step.stepOptions.eventId.value == "" || step.stepOptions.eventId.value == "-1") {
                errors.eventId = " ";
                error = true;
            }
        }

        if (step.triggerType.value == "10" || step.triggerType.value == "11" || step.triggerType.value == '12' || step.triggerType.value == '13') {
            error = false;
        }

        if (step.triggerType.value === "18") {
            // link tag
            if (!step.stepOptions.tagId.value || step.stepOptions.tagId.value == "-1") {
                errors.tagId = " "
                error = true;
            }
        }

        if (!parseInt(step.triggerType.value)) {
            let app = this.props.applications.find(a => a.Application.appName === step.triggerType.value)
            if (app) {
                if (!step.stepOptions.trigger.value) {
                    error = true
                    errors.trigger = ' '
                } else {
                    error = false
                }
            }
        }


        this.setState({ errors })

        if (this.props.isThemeDesigner) {
            error = false;
        }

        if (!error) {
            step.complete = true;
            if (!this.props.isThemeDesigner) step.wizardComplete = true;
            //finish
            this.props.saveStep()
        }

    }

    async getSiteTrackers() {
        this.setState({ siteTrackersLoading: true })
        try {
            let siteTrackers = await axios.get('/accountMaster/tracker')
            siteTrackers = siteTrackers.data.Trackers.map(t => { return { label: t.host, value: t.id } });
            let siteTrackersAny = JSON.parse(JSON.stringify(siteTrackers));
            siteTrackersAny.unshift({ label: "Any Site Tracker", value: "-1" })

            let siteTrackerEvents = await axios.get('/accountMaster/tracker/events')
            siteTrackerEvents = siteTrackerEvents.data.map(m => { return { label: m.name, value: m.id, system: m.system } });
            this.setState({ siteTrackers, siteTrackerEvents, siteTrackersAny, siteTrackersLoading: false })
        }
        catch (err) {
            this.setState({ siteTrackersLoading: false })
        }
    }

    updateSiteTrackerLinks() {
        this.setState({ siteTrackerLinksLoading: true })
        axios.get('/accountMaster/tracker/' + this.state.step.stepOptions.trackingId.value + '/path').then(_res => {
            var siteTrackersLinks = _res.data.TrackerPaths.map(t => ({ label: t.path, value: t.path }));
            siteTrackersLinks = siteTrackersLinks.sort((a, b) => {
                if (a.label > b.label) return 1;
                if (a.label < b.label) return -1;
                return 0;
            })
            siteTrackersLinks.unshift({ label: i18n.t('automation:add.initial.anyLink'), value: '' })
            this.setState({ siteTrackersLinks, siteTrackerLinksLoading: false })
        }).catch(err => {
            this.setState({ siteTrackerLinksLoading: false })
        })
    }

    render() {
        const { readOnly } = this.props;

        if (this.state.drawerContent) return this.state.drawerContent;

        var triggerOptions = [
            { label: i18n.t('automation:add.initial.joinsGroup'), value: "0", group: "Groups and Segments" },
            { label: i18n.t('automation:add.initial.joinsSegment'), value: "1", group: "Groups and Segments" },
            { label: i18n.t('automation:add.initial.exitsSegment'), value: "2", group: "Groups and Segments" },
            { label: i18n.t('automation:add.initial.anniversary'), value: "3", group: "Date Triggers" },
            { label: i18n.t('automation:add.initial.exactDate'), value: "4", group: "Date Triggers" },
            { label: i18n.t('automation:add.initial.clicksLink'), value: "5", group: "Email Activity" },
            { label: i18n.t('automation:add.initial.replies'), value: "6", group: "Email Activity" },
            { label: i18n.t('automation:add.initial.visits'), value: "7", group: "Site Tracking" },
            { label: i18n.t('automation:add.initial.siteTrackingEvent'), value: "20", group: "Site Tracking" },
            { label: i18n.t('automation:add.initial.linktag'), value: "18", group: "Email Activity" },
            { label: i18n.t('automation:add.initial.formSignup'), value: "17", group: "Form Activity" },
            { label: i18n.t('automation:add.initial.formFileDownload'), value: "19", group: "Form Activity" },
            { label: i18n.t('automation:add.initial.campaignSentAny'), value: "11", group: "Email Activity" },
            { label: i18n.t('automation:add.initial.campaignSentSpecific'), value: "15", group: "Email Activity" },
            { label: i18n.t('automation:add.initial.campaignOpenAny'), value: "10", group: "Email Activity" },
            { label: i18n.t('automation:add.initial.campaignOpen'), value: "8", group: "Email Activity" },
            { label: i18n.t('automation:add.initial.campaignClick'), value: "9", group: "Email Activity" },
            { label: i18n.t('automation:add.initial.campaignBounce'), value: "12", group: "Email Activity" },
            { label: i18n.t('automation:add.initial.campaignUnsub'), value: "13", group: "Email Activity" },
            { label: i18n.t('automation:add.initial.invalidclean'), value: "16", group: "List Cleaning" },
            { label: i18n.t('automation:add.initial.anotherAutomation'), value: "14" }
        ]

        // hide list clean option for Adrom
        if (this.props.siteMaster.siteId === 2) {
            triggerOptions = triggerOptions.filter(o => o.value !== "16")
        }

        var dateFields = this.props.customFields.filter(fd => fd.fieldType == "DATE")

        var currentSegmentIsUser = false;
        if (this.state.step.stepOptions && this.state.step.stepOptions.segmentId && this.state.step.stepOptions.segmentId.value && this.state.step.stepOptions.segmentId.value > 0) {

            var currSeg = this.props.segments.find(s => s.value == this.state.step.stepOptions.segmentId.value)
            if (currSeg && !currSeg.readOnly && !currSeg.CapsuleId) currentSegmentIsUser = true;
        }

        // let apps = this.props.applications.filter(app => app.Application.options.useGenericConfig && app.Application.options.triggers && app.Application.options.triggers.length)
        let apps = this.props.applications.filter(app => app.Application.options.triggers && app.Application.options.triggers.length)
        if (this.props.isThemeDesigner && this.props.app && this.props.app.options.triggers && this.props.app.options.triggers.length) {
            apps.push({ Application: this.props.app, ApplicationId: this.props.app.id })
        }

        if (apps && apps.length) {
            apps.forEach(app => {
                triggerOptions.push({
                    label: 'When something happens on ' + app.Application.appName,
                    value: app.Application.appName,
                    group: 'Integrations'
                })
            })
        }


        let selectedApp
        if (this.state.step.triggerType.value != '-1' && apps.some(a => a.Application.appName === this.state.step.triggerType.value)) {
            selectedApp = apps.find(a => a.Application.appName === this.state.step.triggerType.value)
            if (selectedApp) {
                selectedApp.Application.options.triggers.sort((a, b) => (a.label > b.label) ? 1 : -1)
                if (!this.state.step.stepOptions.filters) this.state.step.stepOptions.filters = []
            }
        }

        var tmpGroups = JSON.parse(JSON.stringify(this.props.groups))
        if (this.state.step.triggerType.value == "1" || this.state.step.triggerType.value == "2" && tmpGroups.length) {
            tmpGroups.unshift({ value: "-1000", label: "Any Group" })
        }

        var visits = 0
        if (this.state.step.stepOptions.visits) {
            visits = this.state.step.stepOptions.visits.value
        }

        let tmpCampaigns = [];
        if (this.props.campaigns) {
            tmpCampaigns = JSON.parse(JSON.stringify(this.props.campaigns))
            tmpCampaigns.unshift({ value: "-1000", label: "Any Campaign" })
        }

        const inputProps = {
            disabled: readOnly,
        }

        let form
        if (this.state.step.triggerType.value === '19') {
            form = this.props.forms.find(f => f.id == this.state.step.stepOptions.formId.value)
        }

        let paymentEventSelected = false;
        if (this.state.step.triggerType.value == "20") {
            if (this.state.step.stepOptions.eventId && this.state.step.stepOptions.eventId.value) {
                let foundSelectedEvent = this.state.siteTrackerEvents.find(f => f.value == this.state.step.stepOptions.eventId.value)
                if (foundSelectedEvent && foundSelectedEvent.system == 1 && foundSelectedEvent.label == "Payment") {
                    paymentEventSelected = true;
                }
            }
        }

        return <div data-test="drawer-initial-step">
            <div className="automationStepContentHolder">
                <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="mar-b25">{i18n.t('automation:add.initial.header')}</h4>
                    <HelpPopup type={"trigger"} />
                </div>

                <div className="mar-b25">{i18n.t('automation:add.initial.subHeader')}</div>

                <UILIB.Select fixedHeightLarge={true} outerClassName="mar-b25" label={i18n.t('automation:add.initial.trigger')} placeholder={i18n.t('automation:add.initial.selectTrigger')} name="triggerType" value={this.state.step.triggerType.value} data={triggerOptions} onChange={this.updateTriggerType} error={this.state.errors.triggerType} {...inputProps} />


                {/* Group Select for Types 0-4 */}
                {(!this.props.isThemeDesigner && this.state.step.triggerType.value != "-1" && (this.state.step.triggerType.value == "0" || this.state.step.triggerType.value == "1" || this.state.step.triggerType.value == "2" || this.state.step.triggerType.value == "3" || this.state.step.triggerType.value == "4")) &&
                    <div>
                        {!tmpGroups || !tmpGroups.length && <UILIB.WarningHolder className="mar-b25">
                            <div><strong>{i18n.t('automation:add.initial.noGroups')}</strong></div>
                            <button onClick={this.createGroup} className="a" {...inputProps}>{i18n.t('automation:add.initial.pleaseCreateGroup')}</button>
                        </UILIB.WarningHolder>}

                        {tmpGroups.length > 0 && <div>

                            <UILIB.Select label={i18n.t('automation:add.initial.selectGroup')}
                                labelOptions={<button onClick={this.createGroup} className="a" {...inputProps}>{i18n.t('automation:add.initial.createGroup')}</button>}
                                outerClassName="mar-b25"
                                placeholder={i18n.t('automation:add.initial.selectGroupPlaceholder')}
                                name="groupId" value={this.state.step.stepOptions.groupId.value}
                                data={tmpGroups}
                                onChange={this.updateValue}
                                error={this.state.errors.groupId}
                                {...inputProps}
                            />

                            {this.state.step.stepOptions.groupId.value && (this.state.step.stepOptions.groupId.value != "-1000") && <div>
                                <UILIB.Select label={i18n.t('automation:add.initial.selectState')} outerClassName="mar-b25" placeholder={i18n.t('automation:add.initial.selectStatePlaceholder')} name="subscriberState" value={this.state.step.stepOptions.subscriberState ? this.state.step.stepOptions.subscriberState.value : ''} data={[{ label: i18n.t('automation:add.initial.active'), value: "active" }, { label: i18n.t('automation:add.initial.inactive'), value: "inactive" }]} onChange={this.updateValue} error={this.state.errors.subscriberState} {...inputProps} />
                            </div>}
                        </div>}

                        {/* Segment Select for Types 1-2 */}
                        {(this.state.step.stepOptions.groupId.value != "-1" && this.state.step.triggerType.value != "-1" && (this.state.step.triggerType.value == "1" || this.state.step.triggerType.value == "2")) && !this.props.isThemeDesigner && <div>
                            {!this.props.segments.length &&
                                <UILIB.WarningHolder className="mar-b25">
                                    <div><strong>{i18n.t('automation:add.initial.noSegments')}</strong></div>
                                    <button onClick={this.createSegment} className="a" {...inputProps}>{i18n.t('automation:add.initial.pleaseCreateSegment')}</button>
                                </UILIB.WarningHolder>}
                            {this.props.segments.length > 0 &&
                                <div>
                                    <UILIB.Select
                                        label={i18n.t('automation:add.initial.selectSegment')}
                                        labelOptions={<button onClick={this.createSegment} className="a" {...inputProps}>{i18n.t('automation:add.initial.createSegment')}</button>}
                                        outerClassName="mar-b25"
                                        placeholder={i18n.t('automation:add.initial.selectSegment')}
                                        name="segmentId"
                                        value={this.state.step.stepOptions.segmentId.value}
                                        data={this.props.segments}
                                        onChange={this.updateValue}
                                        error={this.state.errors.segmentId}
                                        explainer={currentSegmentIsUser ? <button onClick={this.viewEditSegment} className="a" {...inputProps}>{i18n.t('automation:add.initial.editSegment')}</button> : ""}
                                        {...inputProps}
                                    />
                                </div>}
                        </div>}

                        {/* Date Select for types 3-4 */}
                        {(this.state.step.stepOptions.groupId.value != "-1" && this.state.step.triggerType.value != "-1" && (this.state.step.triggerType.value == "3" || this.state.step.triggerType.value == "4")) && <div>
                            {!dateFields.length &&
                                <UILIB.WarningHolder className="mar-b25">
                                    <div><strong>{i18n.t('automation:add.initial.noDates')}</strong></div>
                                    <button onClick={this.createField} className="a" {...inputProps}>{i18n.t('automation:add.initial.pleaseCreateDate')}</button>
                                </UILIB.WarningHolder>}
                            {dateFields.length > 0 &&
                                <div>

                                    <div className="quickFlex" style={{ justifyContent: "space-between", alignItems: "center" }}>

                                        {(this.state.step.stepOptions.onBeforeDate.value == "before" || this.state.step.stepOptions.onBeforeDate.value == "after") &&
                                            <div className="mar-b25">
                                                <UILIB.TextInput name="onBeforeDateValue" type="number" value={this.state.step.stepOptions.onBeforeDateValue.value} onChange={this.updateValue} error={this.state.errors.onBeforeDateValue} {...inputProps} />
                                            </div>
                                        }
                                        {(this.state.step.stepOptions.onBeforeDate.value == "before" || this.state.step.stepOptions.onBeforeDate.value == "after") &&
                                            <div className="mar-b25">
                                                <UILIB.Select name="onBeforeDateUnits" value={this.state.step.stepOptions.onBeforeDateUnits.value} data={[{ label: i18n.t('automation:add.initial.minutes'), value: "minute" }, { label: i18n.t('automation:add.initial.hours'), value: "hour" }, { label: i18n.t('automation:add.initial.days'), value: "day" }, { label: i18n.t('automation:add.initial.months'), value: "month" }]} onChange={this.updateValue} {...inputProps} />
                                            </div>
                                        }
                                        <div className="mar-b25">
                                            <UILIB.Select
                                                name="onBeforeDate"
                                                align={this.state.step.stepOptions.onBeforeDate.value == "on" ? 'left' : 'right'}
                                                value={this.state.step.stepOptions.onBeforeDate.value}
                                                data={[{ label: i18n.t('automation:add.initial.on'), value: "on" }, { label: i18n.t('automation:add.initial.before'), value: "before" }, { label: i18n.t('automation:add.initial.after'), value: "after" }]}
                                                onChange={this.updateValue} {...inputProps}
                                            />
                                        </div>
                                    </div>
                                    <UILIB.Select outerClassName="mar-b25" label={i18n.t('automation:add.initial.selectDate')} labelOptions={<button onClick={this.createField} className="a" {...inputProps}>{i18n.t('automation:add.initial.createDate')}</button>} placeholder={i18n.t('automation:add.initial.selectDate')} name="customFieldId" value={this.state.step.stepOptions.customFieldId.value} data={dateFields} onChange={this.updateValue} error={this.state.errors.customFieldId} {...inputProps} />
                                </div>}
                        </div>}


                    </div>
                }
                {/* Link Select for type 5 */}
                {(this.state.step.triggerType.value == "5") && <div>
                    <UILIB.TextInput label={i18n.t('automation:add.initial.link')} outerClassName="mar-b25" placeholder={i18n.t('automation:add.initial.enterLink')} name="link" value={this.state.step.stepOptions.link.value} onEnterPress={this.saveStep} onChange={this.updateValue} error={this.state.errors.link} {...inputProps} />

                    <UILIB.WarningHolder className="mar-b25">
                        {i18n.t('automation:add.initial.linkSub')}
                    </UILIB.WarningHolder>
                </div>
                }
                {!this.props.isThemeDesigner && this.state.step.triggerType.value == "6" && <div>

                    <CampaignSelector
                        label={i18n.t('automation:add.initial.selectCampaign')}
                        outerClassName="mar-b25"
                        placeholder={i18n.t('automation:add.initial.selectCampaign')}
                        name="campaignId"
                        value={this.state.step.stepOptions.campaignId.value}
                        allowAnyCampaign={-1}
                        onChange={this.updateValue}
                        error={this.state.errors.campaignId} {...inputProps}
                    />

                </div>}
                {(!this.props.isThemeDesigner && (this.state.step.triggerType.value == "8" || this.state.step.triggerType.value == "9" || this.state.step.triggerType.value == "15")) && <div>
                    {!tmpCampaigns.length &&
                        <UILIB.WarningHolder className="mar-b25">
                            <div><strong>{i18n.t('automation:add.initial.noCampaigns')}</strong></div></UILIB.WarningHolder>}
                    {tmpCampaigns.length > 0 &&
                        <div>
                            <CampaignSelector
                                label={i18n.t('automation:add.initial.selectCampaign')}
                                outerClassName="mar-b25"
                                placeholder={i18n.t('automation:add.initial.selectCampaign')}
                                name="campaignId"
                                value={this.state.step.stepOptions.campaignId.value}
                                allowAnyCampaign={-1000}
                                onChange={this.updateValue}
                                error={this.state.errors.campaignId} {...inputProps}
                            />
                        </div>}
                </div>}
                {(this.state.step.triggerType.value == "7" && !this.props.isThemeDesigner) && <div>
                    {((!this.state.siteTrackers || this.state.siteTrackers.length < 1) && !this.state.siteTrackersLoading) && <UILIB.WarningHolder className="mar-b25">
                        <div><strong dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.initial.noTracking') }} ></strong></div></UILIB.WarningHolder>}

                    {this.state.siteTrackersLoading && <UILIB.LoadingIcons iconType="2" />}
                    {(this.state.siteTrackers && this.state.siteTrackers.length >= 1) && <div>

                        {!this.state.siteTrackersLoading && <div>

                            <UILIB.Select label={i18n.t('automation:add.initial.selectTracker')} outerClassName="mar-b25" placeholder={i18n.t('automation:add.initial.selectTracker')} name="trackingId" value={this.state.step.stepOptions.trackingId.value} data={this.state.siteTrackers} onChange={this.updateValue} error={this.state.errors.trackingId} {...inputProps} />

                            {(this.state.step.stepOptions.trackingId && this.state.step.stepOptions.trackingId.value && this.state.step.stepOptions.trackingId.value != "-1") && <>

                                {this.state.siteTrackerLinksLoading && <UILIB.LoadingIcons iconType="2" />}
                                {!this.state.siteTrackerLinksLoading && <>
                                    {(!this.state.siteTrackersLinks || this.state.siteTrackersLinks.length < 1) && <UILIB.WarningHolder className="mar-b25">
                                        <div><strong>{i18n.t('automation:add.initial.noPages')}</strong></div>
                                    </UILIB.WarningHolder>}
                                    {(this.state.siteTrackersLinks && this.state.siteTrackersLinks.length >= 1) && <>

                                        <UILIB.Select
                                            label={i18n.t('automation:add.initial.url')}
                                            labelOptions={<button onClick={this.createSitePage} className="a" {...inputProps}>Add New URL</button>}
                                            outerClassName="mar-b25"
                                            value={this.state.step.stepOptions.url.value}
                                            data={this.state.siteTrackersLinks}
                                            filter={true}
                                            onChange={(e) => this.updateValue({ type: "checkbox", name: "url", value: e.target.value })}
                                        />

                                        <UILIB.TextInput label={"Only when the have visited x times"} outerClassName="mar-b25" type="number" value={visits} name="visits" placeholder="visits" onChange={this.updateValue} {...inputProps} />


                                        <UILIB.WarningHolder className="mar-b25">
                                            {i18n.t('automation:add.initial.selectPageWarning')}
                                        </UILIB.WarningHolder>

                                    </>}
                                </>}
                            </>}
                        </div>}
                    </div>}
                </div>}


                {(this.state.step.triggerType.value == "20" && !this.props.isThemeDesigner) && <div>
                    {this.state.siteTrackersLoading && <UILIB.LoadingIcons iconType="2" />}
                    {!this.state.siteTrackersLoading && <div>
                        <UILIB.Select
                            label={i18n.t('automation:add.initial.selectEventTriggered')}
                            outerClassName="mar-b25"
                            placeHolder={i18n.t('automation:add.initial.selectEventTriggered')}
                            name="eventId"
                            value={this.state.step.stepOptions.eventId.value}
                            data={this.state.siteTrackerEvents}
                            filter={true}
                            onChange={this.updateValue}
                            error={this.state.errors.eventId}
                            {...inputProps}
                        />
                        {this.state.step.stepOptions.eventId.value != "-1" && <>
                            <UILIB.Select
                                label={i18n.t('automation:add.initial.selectTracker') + " (" + i18n.t('automation:add.initial.optional') + ")"}
                                outerClassName="mar-b25"
                                name="trackingId"
                                value={this.state.step.stepOptions.trackingId.value}
                                data={this.state.siteTrackersAny}
                                onChange={this.updateValue}
                                error={this.state.errors.trackingId}
                                {...inputProps} />

                            {paymentEventSelected && <div>
                                <div className="txtInput_label">{i18n.t('automation:add.initial.paymentFiltering') + " (" + i18n.t('automation:add.initial.optional') + ")"}</div>
                                <div className="mar-b10">The payment must be</div>
                                <div style={{ display: "flex" }}>
                                    <UILIB.Select
                                        outerstyle={{ flex: "1" }}
                                        label=""
                                        outerClassName="mar-b0 mar-r10"
                                        placeholder=""
                                        name="operator"
                                        value={this.state.step.stepOptions.operator ? this.state.step.stepOptions.operator.value : ""}
                                        data={[{ label: "Equal to", value: "=" }, { label: "Less than or equal to", value: "<=" }, { label: "Greater than or equal to", value: ">=" }]}
                                        onChange={this.updateValue}
                                        {...inputProps} />

                                    <UILIB.TextInput
                                        outerstyle={{ flex: "1" }}
                                        type="number"
                                        className="mar-b0"
                                        value={this.state.step.stepOptions.value ? this.state.step.stepOptions.value.value : 0}
                                        name="value"
                                        onChange={this.updateValue}
                                        {...inputProps}
                                    />
                                </div>
                            </div>}
                        </>}
                    </div>}
                </div>}
                {this.state.step.triggerType.value == "13" &&
                    <div>

                        <UILIB.Select
                            label={i18n.t('automation:add.initial.selectGroup')}
                            labelOptions={!this.props.isThemeDesigner ? <button onClick={this.createGroup} className="a" {...inputProps}>{i18n.t('automation:add.initial.createGroup')}</button> : ""}
                            outerClassName="mar-b25"
                            placeholder={i18n.t('automation:add.initial.selectGroupPlaceholder')}
                            name="groupId"
                            value={this.state.step.stepOptions.groupId.value}
                            data={[{
                                label: i18n.t('automation:add.initial.anyGroup'),
                                value: ""
                            }].concat(this.props.groups)}
                            onChange={this.updateValue}
                            {...inputProps}
                        />
                    </div>
                }

                {this.state.step.triggerType.value == "17" && this.state.step.stepOptions.formId &&
                    // form signup
                    <div>
                        <UILIB.Select
                            data={[{ label: i18n.t('automation:add.initial.formAll'), value: '-1' }, ...this.props.forms.map(form => ({ label: form.formName, value: form.id, iconRight: form.Group ? <UILIB.SquareChip>{form.Group.groupName}</UILIB.SquareChip> : '' }))]}
                            label={i18n.t('automation:add.initial.formSelect')}
                            placeholder={i18n.t('automation:add.initial.formSelect')}
                            name="formId"
                            value={this.state.step.stepOptions.formId.value}
                            onChange={this.updateValue}
                            outerClassName="mar-b25"
                            {...inputProps}
                        />

                        {!!this.state.step.stepOptions.formId.value &&
                            <>
                                <UILIB.Select
                                    data={[
                                        { label: i18n.t('automation:add.initial.formInitial'), value: "formSignup" },
                                        { label: i18n.t('automation:add.initial.formConfirm'), value: "formConfirm" }
                                    ]}
                                    label={i18n.t('automation:add.initial.formStatus')}
                                    placeholder={i18n.t('automation:add.initial.formStatus')}
                                    value={this.state.step.stepOptions.formType.value}
                                    name="formType"
                                    onChange={this.updateValue}
                                    outerClassName="mar-b25"
                                    {...inputProps}
                                />
                                <UILIB.Select
                                    data={[
                                        { label: i18n.t('automation:add.initial.formContactStatusBoth'), value: "-1" },
                                        { label: i18n.t('automation:add.initial.formContactStatusNew'), value: "newContact" },
                                        { label: i18n.t('automation:add.initial.formContactStatusUpdate'), value: "updatedContact" }
                                    ]}
                                    label={i18n.t('automation:add.initial.formContactStatus')}
                                    value={this.state.step.stepOptions.formContactType.value}
                                    name="formContactType"
                                    onChange={this.updateValue}
                                    outerClassName="mar-b25"
                                    {...inputProps}
                                />
                            </>
                        }

                    </div>
                }


                {this.state.step.triggerType.value == "18" &&
                    <div>

                        <UILIB.Select
                            label={i18n.t('automation:add.initial.selectLinkTag')}
                            outerClassName="mar-b25"
                            placeholder={i18n.t('automation:add.initial.selectLinkTagPlaceholder')}
                            name="tagId"
                            error={this.state.errors.tagId}
                            value={this.state.step.stepOptions.tagId.value}
                            data={this.props.linktags.map(t => ({ label: t.tagName, value: t.id }))}
                            onChange={this.updateValue}
                            {...inputProps}
                        />
                    </div>
                }

                {this.state.step.triggerType.value == "19" &&
                    <div>
                        <UILIB.Select
                            data={[{ label: i18n.t('automation:add.initial.formAll'), value: '-1' }, ...this.props.forms.filter(f => f.FormFiles.length).map(form => ({ label: form.formName, value: form.id, iconRight: form.Group ? <UILIB.SquareChip>{form.Group.groupName}</UILIB.SquareChip> : '' }))]}
                            label={i18n.t('automation:add.initial.formSelect')}
                            placeholder={i18n.t('automation:add.initial.formSelect')}
                            name="formId"
                            value={this.state.step.stepOptions.formId.value}
                            onChange={this.updateValue}
                            outerClassName="mar-b25"
                            {...inputProps}
                        />
                        {this.state.step.stepOptions.formId.value !== '-1' && form && <UILIB.Select
                            data={[{ label: 'Any File', value: '-1' }, ...form.FormFiles.map(ff => ({ label: ff.url || ff.FileManagerFile?.friendlyName, value: ff.id }))]}
                            label="File"
                            name="formFile"
                            value={this.state.step.stepOptions.formFile.value}
                            onChange={this.updateValue}
                            outerClassName="mar-b25"
                            {...inputProps}
                        />}
                    </div>
                }

                {selectedApp && <div>

                    <UILIB.Select label={selectedApp.Application.appName + " Trigger"} outerClassName="mar-b25" placeholder="Select the Trigger" data={selectedApp.Application.options.triggers} name="trigger" value={this.state.step.stepOptions.trigger.value} onChange={this.updateValue} {...inputProps} />
                    {!!this.state.step.stepOptions.trigger.value && <TriggerFilters filters={this.state.step.stepOptions.filters} trigger={this.state.step.stepOptions.trigger.value} appId={selectedApp.ApplicationId} />}
                </div>}
                {this.props.automationStatus && this.props.automationStatus != "Draft" && this.props.firstRunAddExistng && <UILIB.WarningHolder className="mar-b25">
                    {i18n.t('automation:add.initial.allSubs')}
                </UILIB.WarningHolder>}
                {this.props.automationStatus && this.props.automationStatus != "Draft" && !this.props.firstRunAddExistng && <UILIB.WarningHolder className="mar-b25">
                    {i18n.t('automation:add.initial.newSubs')}
                </UILIB.WarningHolder>}

                {this.state.invalidSegment && <UILIB.WarningHolder className="mar-b25">
                    The segment contains the matching of an exact date. Contacts will only be added to the automation if they are updated on this date. Please consider using a wait step instead.
                </UILIB.WarningHolder>}
            </div>

            <div className="automationStepSaveButtonHolder">
                {readOnly ? (
                    <>
                        <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                    </>
                ) : (
                    <UILIB.Button className="button-primary" onClick={this.saveStep}>{i18n.t('save')}</UILIB.Button>
                )}
            </div>
        </div>
    }
}



export default DrawerStepInitial;
import React, { useState, useEffect } from "react";
import Dialog from "../../../../components/dialog";
import Button from "../../../../components/button";
import TextInput from "../../../../components/textInput";
import TextArea from "../../../../components/textArea";
import i18n from "~/i18n";
import axios from "~/data/http/axios";

export default function AddArticleDialog({ inbox, isOpen, onSubmit, selectedArticle, assistant, ...rest }) {
  const [submitting, setSubmitting] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [url, setUrl] = useState("");
  const [formErrors, setFormErrors] = useState(null);
  const [articleId, setArticleId] = useState(null);
  const [loading, setLoading] = useState(true);
  //on init
  useEffect(() => {
    if (selectedArticle) {
      loadArticle();
    }
    else {
      setLoading(false);
    }
  }, []);

  async function loadArticle() {
    try {
      const { data } = await axios.get(
        `/inboxes/${inbox.id}/ai/${assistant.id}/embeddings/${selectedArticle.id}`
      );
      setArticleId(data.id);
      setTitle(data.pageTitle);
      setContent(data.text);
      setUrl(data.pageUrl);
      setLoading(false);
    } catch (error) {
      throw error;
    }
  }

  async function handleSubmit() {
    setSubmitting(true);

    try {
      await validateForm();
      const values = { title, content, url };
      await onSubmit(values);
    } finally {
      setSubmitting(false);
    }
  }

  async function validateForm() {
    try {
      let errors = null;

      if (!title) {
        errors = { ...errors, title: i18n.t("chat:ai.articles.add.errTitle") };
      }

      if (!content) {
        errors = { ...errors, content: i18n.t("chat:ai.articles.add.errContent") };
      }

      if (url && !/^(http|https):\/\/[^ "]+$/.test(url)) {
        errors = { ...errors, url: i18n.t("chat:ai.articles.add.errUrl") };
      }

      if (errors) throw errors;

      setFormErrors(null);
    } catch (error) {
      setFormErrors(error);
      throw error;
    }
  }

  const isAiArticle = selectedArticle ? selectedArticle.InboxAiSourceId > 0 : false;
  const titleText = articleId ? i18n.t("chat:ai.articles.edit.header") : i18n.t("chat:ai.articles.add.header");
  const descriptionText = articleId ? i18n.t("chat:ai.articles.edit.description") : i18n.t("chat:ai.articles.add.description");
  const submitButtonText = articleId ? i18n.t("chat:ai.articles.edit.button") : i18n.t("chat:ai.articles.add.button");
  if (loading) return <></>;
  return (
    <Dialog
      fullScreen
      open={isOpen}
      title={titleText}
      description={descriptionText}
      submitting={submitting}
      {...rest}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextInput
          label={i18n.t("chat:ai.articles.add.title")}
          value={title}
          error={formErrors?.title}
          className="mar-b24"
          onChange={(e) => setTitle(e.target.value)}
        />

        <TextArea
          label={i18n.t("chat:ai.articles.add.content")}
          value={content}
          error={formErrors?.content}
          className="mar-b24"
          onChange={(e) => setContent(e.target.value)}
          style={{ height: "200px" }}
        />

        <TextInput
          label={i18n.t("chat:ai.articles.add.url")}
          placeholder="https://"
          value={url}
          error={formErrors?.url}
          onChange={(e) => setUrl(e.target.value)}
        />
        {isAiArticle && <div className="mar-t40">
          If you proceed, this article will not be updated automatically in the future based on your website content.
        </div>}
        <Button className="mar-t40" type="submit" size="s" loading={submitting}>
          {submitButtonText}
        </Button>
      </form>
    </Dialog>
  );
}

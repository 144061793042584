import React, { Component } from 'react';
import { connect } from 'react-redux';
import SiteVars from '~/Classes/siteVars';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import i18n from '~/i18n';
import UILIB from '~/Common-Objects/Lib'
import moment from 'moment';
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, permissionStore: store.permission }
})
class ThemePanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenshotUrl: '',
            templateImageLoaded: false,
            error: false
        }
        this.checkTemplateImageExists = this.checkTemplateImageExists.bind(this)
        this.initialise = this.initialise.bind(this);
    }

    componentWillUnmount() {
        this.unmounting = true
    }

    componentDidMount() {
        this.initialise();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme.id != this.props.theme.id) this.initialise();
    }

    initialise() {
        let url = ''
        if (this.props.theme.type === 1 || this.props.theme.type === 2 || this.props.theme.type === 0) {
            url = SiteVars.repoEndPoint + 'siteContent/themes/theme' + this.props.theme.id + ".jpg"
        } else {
            url = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + this.props.theme.id + ".jpg"
        }
        this.setState({ screenshotUrl: url })
        if (url) {
            this.checkTemplateImageExists()
        }
    }

    checkTemplateImageExists() {

        if (this.unmounting) return
        if (this.state.templateImageLoaded) return;

        var self = this;
        var img = new Image();
        img.onload = function () { self.setState({ templateImageLoaded: true, error: false }) };
        img.onerror = function () {
            self.setState({ error: true })
            self.imageCheckTimer = setTimeout(self.checkTemplateImageExists, 2000);
        }
        img.src = this.state.screenshotUrl
    }

    render() {

        if (this.props.theme.type === 1 || this.props.theme.type === 2 || this.props.theme.type == 0) {
            var classes = "template-themeselector-theme";
            if (this.props.selected) {
                classes += " template-themeselector-theme-selected";
            }

            var screenshotUrl = this.state.screenshotUrl

            var style = {};
            if (this.props.style) {
                style = this.props.style;
            }

            var isNew = false;
            if (this.props.theme.createdAt) {
                var timeDiff = moment().diff(moment(this.props.theme.createdAt), 'days');
                if (timeDiff < 90) isNew = true;
            }

            let isBrandAnalyzer = false;
            if (this.props.theme.brandAnalyzer) {
                isBrandAnalyzer = true;
            }
            let doPreview = true;
            if (this.props.theme.type === 0) doPreview = false;

            return (
                <UILIB.Paper className={classes} onClick={this.props.select} style={style}>
                    {isBrandAnalyzer && <div className="template-themeselected-theme-brandanalyzer">Branded</div>}
                    {isNew && <div className="template-themeselected-theme-new">NEW</div>}
                    {doPreview && <div className="template-themeselected-theme-toolBar">
                        <UILIB.Icons icon="magnifier" onClick={this.props.preview} style={{ width: "15px", height: "15px" }} />
                    </div>}

                    <div className="template-themeselected-theme-image" style={{ backgroundImage: "url(" + screenshotUrl + ")" }} />

                    <div className="template-themeselected-theme-bottomholder">
                        <div className="template-themeselected-theme-title">
                            {this.props.theme.name}
                        </div>
                    </div>
                </UILIB.Paper >
            );
        } else {
            var classes = "template-themeselector-theme";
            if (this.props.selected) {
                classes += " template-themeselector-theme-selected";
            }

            var screenshotUrl = this.state.screenshotUrl
            var dateAdded = this.props.theme.createdAt ? this.props.theme.createdAt : this.props.theme.dateAdded;
            return (
                <UILIB.Paper className={classes} onClick={this.props.select}>
                    <div className={(this.props.className ? this.props.className : '') + " template-themeselected-theme-image"} style={{ backgroundImage: screenshotUrl ? "url(" + screenshotUrl + ")" : "" }}>
                        {this.state.error && <div style={{ height: 250 }}> <UILIB.LoadingIcons iconType="2" /></div>}
                    </div>
                    <div className="template-themeselected-theme-bottomholder">
                        <div className="template-themeselected-theme-title">
                            {this.props.theme.templateName || this.props.theme.name}
                        </div>
                        <div className="template-themeselected-theme-date">{i18n.t('templates:add.created')}: {DateTimeFunctions.formatDateTime(dateAdded, 2)}</div>
                    </div>
                </UILIB.Paper>
            );
        }
    }
}

export default ThemePanel;
import React from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import BasicView from './sections/basic';
import ThemesView from '~/pages/cp/templates/add/dragDrop/themes/themes';
import SavedView from './sections/saved';
import CampaignsView from './sections/campaigns';
import AdvancedView from './sections/advanced';

import DragDropFunctions from '~/pages/cp/templates/add/dragDrop/dragDropFunctions';
import PermissionChecker from '~/Classes/permissions';

import BrandAnalyzerRequiredModal from '~/pages/cp/includes/brandanalyzer/brandAnalyzerRequiredModal';

import i18n from '~/i18n'
//CONNECT TO STORE
@connect((store) => {
    return { permissionStore: store.permission, user: store.user, accountMaster: store.accountMaster }
})

export default class StandardCampaign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            selectedTemplate: false
        }

        this.changeStep = this.changeStep.bind(this);
        this.selectTemplate = this.selectTemplate.bind(this);
        this.selectTheme = this.selectTheme.bind(this);
        this.createFromCode = this.createFromCode.bind(this);
        this.createFromWysi = this.createFromWysi.bind(this);
        this.forwardToUrl = this.forwardToUrl.bind(this);
    }

    componentDidMount() {
        if (this.props.campaign.TemplateId) {
            var templateType = this.props.campaign.Template.templateType;

            var theURL = '/cp/templates/add/dragDrop/'
            if (templateType === 1) {
                theURL = "/cp/templates/add/fromCode/";
            }
            if (templateType === 2) {
                theURL = "/cp/templates/add/wysiwyg/";
            }
            theURL = theURL + this.props.campaign.TemplateId
            if (this.props.params != undefined) theURL += "?" + this.props.params
            this.forwardToUrl(theURL);
            return;
        }
        var canAddTemplates = PermissionChecker('templates', this.props.permissionStore.permissions, "write");
        if (!canAddTemplates) this.setState({ currentStep: 2 })

    }

    changeStep(event) {
        var self = this;
        var index;
        if (event.currentTarget && event.currentTarget.id) {
            index = event.currentTarget.id;
        }
        else if (event.currentTarget && event.currentTarget.value) {
            index = event.currentTarget.value;
        }
        else {
            index = event;
        }
        index = String(index);
        this.setState({
            currentStep: index
        })
    }

    forwardToUrl(url) {

        this.props.history.push(url);

    }

    selectTheme(theme) {
        //if its a brandanalyzer template, and then havent done BA yet push them there
        if (theme && theme.brandAnalyzer && theme.brandAnalyzer == true && this.props.accountMaster.accountMaster.options) {
            if (!this.props.accountMaster.accountMaster.options.brandAnalyzer || !this.props.accountMaster.accountMaster.options.brandAnalyzer.colours || !Array.isArray(this.props.accountMaster.accountMaster.options.brandAnalyzer.colours) || !this.props.accountMaster.accountMaster.options.brandAnalyzer.font || !this.props.accountMaster.accountMaster.options.brandAnalyzer.logo) {
                let url = this.props.history.location.pathname;
                this.props.dispatch(siteMaster.alterFullpage(<BrandAnalyzerRequiredModal urlOnCompletion={url} />));
                return;
            }
        }
        this.setState({ selectedTemplate: true })
        var json = theme.templateJson;
        json = DragDropFunctions.replaceGlobalOptions(json, true);
        json = DragDropFunctions.replaceDefaultColors(json);
        var newTemplate = {
            templateName: this.props.campaign.campaignName,
            templateJson: json,
            templateType: theme.editorType,
            templateHTML: theme.templateHTML,
            CampaignId: this.props.campaign.id
        }
        axios.post('/template', newTemplate)
            .then(response => {
                newTemplate.id = response.data.Template.templateID
                this.props.campaign.TemplateId = newTemplate.id;
                return this.props.saveCampaign(this.props.campaign);
            }).then(() => {

                var url = "/cp/templates/add/dragDrop/" + newTemplate.id + (this.props.params ? '?' + this.props.params : '');
                switch (theme.editorType) {
                    case 1:
                        url = "/cp/templates/add/fromCode/" + newTemplate.id + (this.props.params ? '?' + this.props.params : '');
                        break;
                    case 2:
                        url = "/cp/templates/add/wysiwyg/" + newTemplate.id + (this.props.params ? '?' + this.props.params : '');
                        break
                }
                this.forwardToUrl(url)

                // this.props.history.push("/cp/templates/add/dragDrop/" + newTemplate.id + (this.props.params ? '?' + this.props.params : ''))
            })
    }

    createFromCode() {
        this.setState({ selectedTemplate: true })
        var newTemplate = {
            templateName: this.props.campaign.campaignName,
            templateJson: {},
            templateType: 1,
            CampaignId: this.props.campaign.id
        }
        axios.post('/template', newTemplate).then(response => {
            newTemplate.id = response.data.Template.templateID
            this.props.campaign.TemplateId = newTemplate.id;
            if (this.props.selectedTemplate && typeof this.props.selectedTemplate === 'function') {
                this.props.selectedTemplate(1)
            }
            return this.props.saveCampaign(this.props.campaign);
        }).then(() => {
            this.forwardToUrl("/cp/templates/add/fromCode/" + newTemplate.id + (this.props.params ? '?' + this.props.params : ''))
        })
    }

    createFromWysi() {
        this.setState({ selectedTemplate: true })
        var newTemplate = {
            templateName: this.props.campaign.campaignName,
            templateJson: {},
            templateType: 2,
            CampaignId: this.props.campaign.id
        }
        axios.post('/template', newTemplate).then(response => {
            newTemplate.id = response.data.Template.templateID
            this.props.campaign.TemplateId = newTemplate.id;
            if (this.props.selectedTemplate && typeof this.props.selectedTemplate === 'function') {
                this.props.selectedTemplate(2)
            }
            return this.props.saveCampaign(this.props.campaign);
        }).then(() => {
            this.forwardToUrl("/cp/templates/add/wysiwyg/" + newTemplate.id + (this.props.params ? '?' + this.props.params : ''))
        })
    }

    selectTemplate(template) {
        this.setState({ selectedTemplate: true })

        var newTemplate = Object.assign({}, template)
        delete newTemplate.id;
        newTemplate.parentTemplateId = template.id
        if (newTemplate.templateType === 4) {
            newTemplate.templateType = 1;
        }
        newTemplate.CampaignId = this.props.campaign.id
        var theURL = '/cp/templates/add/dragDrop/'
        axios.post('/template', newTemplate)
            .then(response => {
                newTemplate.id = response.data.Template.templateID
                var templateType = newTemplate.templateType;

                if (templateType == 1) {
                    theURL = "/cp/templates/add/fromCode/";
                }

                if (templateType == 2) {
                    theURL = "/cp/templates/add/wysiwyg/";
                }
                if (this.props.campaign.Template) {
                    this.props.campaign.Template.templateType = templateType;
                }
                this.props.campaign.TemplateId = newTemplate.id;
                if (this.props.selectedTemplate && typeof this.props.selectedTemplate === 'function') {
                    this.props.selectedTemplate(newTemplate.templateType)
                }
                return this.props.saveCampaign(this.props.campaign);
            }).then(() => {
                theURL += newTemplate.id
                if (this.props.params) {
                    theURL += '?' + this.props.params
                }
                this.forwardToUrl(theURL);

            })
    }

    render() {

        if (this.state.selectedTemplate) {
            return <div><UILIB.LoadingIcons iconType="2" /></div>
        }

        var thisMenuOptions = {
            "Basic": { index: 1 },
            "Themes": { index: 0 },
            "Saved Templates": { index: 2 },
            "Campaigns": { index: 3 },
            "Advanced": { index: 4 }
        }

        var canAddTemplates = PermissionChecker('templates', this.props.permissionStore.permissions, "write");
        return <div>

            <div className="mar-b25">
                {i18n.t('campaigns:standard.step2.header')}
            </div>

            <UILIB.Row >

                <UILIB.Column xs={12} hide={["sm", "xs"]} className="mar-b25">

                    {canAddTemplates && <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-l5"
                        selected={this.state.currentStep == 0}
                        onClick={() => { this.changeStep(0) }}>
                        {i18n.t('templates:add.themes')}
                    </UILIB.ButtonSimple>}

                    <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-l5"
                        selected={this.state.currentStep == 2}
                        onClick={() => { this.changeStep(2) }}>
                        {i18n.t('templates:add.savedTemplates')}
                    </UILIB.ButtonSimple>
                    <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-l5"
                        selected={this.state.currentStep == 3}
                        onClick={() => { this.changeStep(3) }}>
                        {i18n.t('templates:add.campaigns')}
                    </UILIB.ButtonSimple>
                    {canAddTemplates && <UILIB.ButtonSimple
                        className="button-simple-fullsize mar-l5"
                        selected={this.state.currentStep == 4}
                        onClick={() => { this.changeStep(4) }}>
                        {i18n.t('templates:add.advancedTxt')}
                    </UILIB.ButtonSimple>}
                </UILIB.Column>

                <UILIB.Column xs={12} hide={["md", "lg"]} className="mar-b25" >
                    <UILIB.Select data={thisMenuOptions} onChange={this.changeStep} value={this.state.currentStep} />
                </UILIB.Column>

            </UILIB.Row>

            {this.state.currentStep == 0 && <ThemesView onSelect={this.selectTheme} />}
            {this.state.currentStep == 2 && <SavedView onSelect={this.selectTemplate} />}
            {this.state.currentStep == 3 && <CampaignsView onSelect={this.selectTemplate} />}
            {this.state.currentStep == 4 && <AdvancedView onSelect={this.selectTemplate} saveCampaign={this.props.saveCampaign} changeStep={this.props.changeStep} campaign={this.props.campaign} history={this.props.history} createFromCode={this.createFromCode} createFromWysi={this.createFromWysi} />}



            {!!this.props.changeStep && <div>
                <UILIB.Button onClick={() => { this.props.changeStep(1, false) }} iconLeft={<UILIB.Icons icon="arrowLeft" />}>Back</UILIB.Button>
            </div>}

        </div>

    }
}
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import DBConnection from '~/Classes/siteVars';
import axios from 'axios';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { I18n } from 'react-i18next';
import Highlight from 'react-highlight'
import AceEditor from 'react-ace';

const codes = {
    csharp: `var client = new RestClient("https://{{url}}/{{endpoint}}");
var request = new RestRequest(Method.{{method}});
request.AddHeader("Cache-Control", "no-cache");
request.AddHeader("Content-Type", "application/json");
request.AddHeader("x-access-token", "{{token}}");
request.AddParameter("undefined", "{{body}}", ParameterType.RequestBody);
IRestResponse response = client.Execute(request);`,
    curl: `curl -X POST
https://{{url}}/{{endpoint}}
-H 'Cache-Control: no-cache'
-H 'Content-Type: application/json'
-H 'x-access-token: {{token}}'
-d '{{body}}'`,
    golang: `package main
import (
    "fmt"
    "strings"
    "net/http"
    "io/ioutil"
)

func main() {
    url := "https://{{url}}/{{endpoint}}"

    payload := strings.NewReader("{{body}}")

    req, _ := http.NewRequest("{{method}}", url, payload)

    req.Header.Add("x-access-token", "{{token}}")
    req.Header.Add("Content-Type", "application/json")
    req.Header.Add("Cache-Control", "no-cache")

    res, _ := http.DefaultClient.Do(req)

    defer res.Body.Close()
    body, _ := ioutil.ReadAll(res.Body)

    fmt.Println(res)
    fmt.Println(string(body))

}`,
    http: `POST /transaction HTTP/1.1
Host: {{url}}
x-access-token: {{token}}
Content-Type: application/json
Cache-Control: no-cache

{{body}}`,
    java: `OkHttpClient client = new OkHttpClient();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{{body}}");
Request request = new Request.Builder()
    .url("https://{{url}}/{{endpoint}}")
    .{{method}}(body)
    .addHeader("x-access-token", "{{token}}")
    .addHeader("Content-Type", "application/json")
    .addHeader("Cache-Control", "no-cache")
    .build();

Response response = client.newCall(request).execute();`,
    javascript: `var settings = {
"async": true,
"crossDomain": true,
"url": "https://{{url}}/{{endpoint}}",
"method": "{{method}}",
"headers": {
    "x-access-token": "{{token}}",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache"
},
"processData": false,
"data": {
    {{body}}}
    };

$.ajax(settings).done(function (response) {
    console.log(response);
}); `,
    python: `import requests

url = "https://{{url}}/{{endpoint}}"

payload = "{{body}}"
headers = {
    'x-access-token': "{{token}}",
    'Content-Type': "application/json",
    'Cache-Control': "no-cache"
}

response = requests.request("{{method}}", url, data = payload, headers = headers)

print(response.text)`,
    php: `<? php

$curl = curl_init();

curl_setopt_array($curl, array(
    CURLOPT_URL => "https://{{url}}/{{endpoint}}",
    CURLOPT_RETURNTRANSFER => true,
    CURLOPT_ENCODING => "",
    CURLOPT_MAXREDIRS => 10,
    CURLOPT_TIMEOUT => 30,
    CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
    CURLOPT_CUSTOMREQUEST => "{{method}}",
    CURLOPT_POSTFIELDS => "{{body}}",
    CURLOPT_HTTPHEADER => array(
        "Cache-Control: no-cache",
        "Content-Type: application/json",
        "x-access-token: {{token}}"
    ),
));

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
    echo "cURL Error #:".$err;
} else {
    echo $response;
} `,
    ruby: `require 'uri'
require 'net/http'

url = URI("https://{{url}}/{{endpoint}}")

http = Net:: HTTP.new(url.host, url.port)

request = Net:: HTTP:: {{method}}.new(url)
request["x-access-token"] = '{{token}}'
request["Content-Type"] = 'application/json'
request["Cache-Control"] = 'no-cache'
request.body = "{{body}}"

response = http.request(request)
puts response.read_body`
}

//CONNECT TO STORE
@connect((store) => {
    return { siteMaster: store.siteMaster }
})

export default class ApiDocs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 1,
            loadingArticle: 1,
            apiCats: [],
            currentDoc: {},
            currentDocId: 1,
            code: "curl",
            mobileNavShown: false
        }
        this.getApiCats = this.getApiCats.bind(this);
        this.getArticle = this.getArticle.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.docId && this.props.match.params.docId !== this.state.currentDocId) {
            this.setState({ currentDocId: this.props.match.params.docId }, this.getApiCats);
            return;
        }
        this.getApiCats()
    }

    componentDidUpdate(prevProps) {
        if (!this.state.loading) {
            if (this.props.match.params.docId !== this.state.currentDocId) {
                this.setState({
                    currentDocId: this.props.match.params.docId,
                    loadingArticle: 1
                }, () => this.getArticle(this.props.match.params.docId));
            }
        }
    }

    getApiCats() {
        var self = this;
        var baseUrl = DBConnection.getConnString()
        axios.get(baseUrl + '/api/apiDoc/categories').then(response => {

            var apiCats = [];
            var apiData = [];
            apiData = JSON.stringify(response.data.ApiCats).replace(/MPZMail/g, this.props.siteMaster.siteName);
            apiData = JSON.stringify(response.data.ApiCats).replace(/Transpond/g, this.props.siteMaster.siteName);
            apiData = JSON.parse(apiData);

            apiData.filter(
                theDoc => theDoc.parentId == 0 || !theDoc.parentId
            ).forEach((parentBlog, parentIndex) => {
                var theChildren = [];
                response.data.ApiCats.forEach(theChild => {
                    if (theChild.parentId == parentBlog.id) {
                        theChildren.push(theChild);
                    }
                });
                parentBlog.children = theChildren;
                apiCats.push(parentBlog);
            });

            self.setState({
                loading: 0,
                apiCats: apiCats
            }, () => this.getArticle(this.state.currentDocId))
        }).catch(err => {
            console.log(err)
        })
    }

    getArticle(articleId) {
        var self = this;
        var baseUrl = DBConnection.getConnString()
        axios.get(baseUrl + "/api/apiDoc/" + articleId).then(response => {

            var thisDoc = JSON.stringify(response.data.ApiDocs).replace(/web.mpzmail.com/g, window.location.hostname)
                .replace(/MPZMail/gi, this.props.siteMaster.siteName)
                .replace(/api.mpzmail.com/g, this.props.siteMaster.trackingDomain)
                .replace(/http:\/\/mpzmail.com\/contactus/gi, 'mailto:' + this.props.siteMaster.supportEmail)
                .replace(/http:\/\/transpond.io\/contactus/gi, 'mailto:' + this.props.siteMaster.supportEmail)
                .replace(/https:\/\/transpond.io\/contactus/gi, 'mailto:' + this.props.siteMaster.supportEmail)
                .replace(/app.transpond.io/g, window.location.hostname)
                .replace(/api.transpond.io/g, this.props.siteMaster.trackingDomain)
                .replace(/Transpond/gi, this.props.siteMaster.siteName)
                .replace(/mpzTestKey/g, "apiTestKey")
                .replace(/Mike Way/g, "Test User")
                .replace(/mike.way/g, "test.user")
                .replace(/\"Mike\"/g, "\"test\"")
                .replace(/\"Way\"/g, "\"user\"")
                .replace(/test.com/g, 'example.com')
                ;
            thisDoc = JSON.parse(thisDoc);
            self.setState({ currentDoc: thisDoc, loadingArticle: 0 })
        });
    }


    changePage(articleId) {
        this.setState({ mobileNavShown: false })
        this.props.history.push('/apidocs/' + articleId)
    }


    render() {

        if (this.state.loading == 1) return <div></div>
        var style = { __html: '' }
        if (this.props.siteMaster.siteId !== 1) {
            style = {
                __html: `
                            .agencyHide { display: none; }
                    `}
        }


        let code = ""
        if (!this.state.loadingArticle) {
            code = codes[this.state.code];
            code = code.replace(/{{url}}/g, this.props.siteMaster.trackingDomain)
            code = code.replace(/{{endpoint}}/g, this.state.currentDoc.requestUrl)
            code = code.replace(/{{method}}/g, this.state.currentDoc.requestType.toUpperCase())
            code = code.replace(/{{token}}/g, this.state.currentDoc.requiresAuth ? "YOUR_ACCESS_TOKEN" : "")
            var codeExample = this.state.currentDoc.requestExample;
            if (this.state.code == "csharp") {
                codeExample = codeExample.replace(/\n/g, "")
                codeExample = codeExample.replace(/\"/g, "\\\"")
            }
            code = code.replace(/{{body}}/g, codeExample)

        }

        let leftNavClasses = "apiDocs-leftMenu";
        if (this.state.mobileNavShown) leftNavClasses += " apiDocs-leftMenu-forceShowMob";
        return <div className="apiDocs">

            <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/highlight.js/9.12.0/styles/foundation.min.css"></link>
            {/* this is fine, not a user input controlled element */}
            <style dangerouslySetInnerHTML={style} />
            <div className="apiDocHeader" style={{ backgroundColor: this.props.siteMaster.colours.$altBackgroundColor }}>
                <img src={this.props.siteMaster.siteLogo} style={{ width: "100px" }} />
            </div>
            <div className="apiDocContent">
                <div className="apiDocsLeft mar-b0">
                    <div className="apiDocs-mobile-navTitle" onClick={() => {
                        let shown = this.state.mobileNavShown
                        if (shown) {
                            shown = false
                        }
                        else {
                            shown = true;
                        }
                        this.setState({ mobileNavShown: shown })
                    }}>
                        <div>Browse Topics</div>
                        <div>
                            <UILIB.Icons icon={this.state.mobileNavShown ? "arrowDown" : "arrowRight"} />
                        </div>
                    </div>
                    <div className={leftNavClasses}>
                        <ul>
                            {this.state.apiCats.map((theCat, index) => {
                                return <li key={index} className={"primaryCat" + (theCat.id == this.state.currentDocId ? ' selected' : '')}>
                                    <div onClick={() => this.changePage(theCat.id)}>
                                        <a>
                                            {theCat.apiDocTitle}
                                        </a>
                                    </div>
                                    {theCat.children && <ul>
                                        {theCat.children.map(theChild => {
                                            return <li key={theChild.id} className={"secondaryCat" + (theChild.id == this.state.currentDocId ? ' selected' : '')} onClick={() => this.changePage(theChild.id)}>
                                                <a>
                                                    {theChild.apiDocTitle}
                                                </a>
                                            </li>
                                        })}
                                    </ul>}
                                </li>
                            })}
                        </ul>
                    </div>
                </div>

                {!this.state.loadingArticle &&
                    <div className="apiDocsRight mar-b0">

                        <UILIB.Row>
                            <UILIB.Column xs={12}>
                                <h2>{this.state.currentDoc.apiDocTitle}</h2>
                                <div dangerouslySetInnerHTML={{ __html: this.state.currentDoc.headerDescription }} />
                                {(this.state.currentDoc.relatedArticles && this.state.currentDoc.relatedArticles.length > 0) && <div>
                                    <h4 className="mar-b10">Related Articles</h4>
                                    <ul>
                                        {this.state.currentDoc.relatedArticles.map((article, index) => {
                                            return <li><a onClick={() => { this.props.history.push('/apidocs/' + article.id) }}>{article.apiDocTitle}</a></li>
                                        })}
                                    </ul>
                                </div>}
                            </UILIB.Column>
                        </UILIB.Row>

                        {this.state.currentDoc.requestType && <UILIB.Row>
                            {this.state.currentDoc.requiresAuth && <UILIB.Column xs={12} className="mar-b15">
                                <UILIB.SquareChip className="square-chip-red" buttonIcon="icon-link" hasButton={true} value="Requires x-access-token Authentication" onClick={() => this.props.history.push('/apidocs/1')} />
                            </UILIB.Column>}

                            <UILIB.Column xs={12}><h3>Request</h3>
                                <p>Send a {this.state.currentDoc.requestType.toUpperCase()} to the api endpoint</p>
                                <h4>Api Endpoint</h4>
                                <div className="apiDocs-link-block">
                                    {"https://" + this.props.siteMaster.trackingDomain + this.state.currentDoc.requestUrl}
                                </div>
                            </UILIB.Column>

                            {this.state.currentDoc.queryData && JSON.parse(this.state.currentDoc.queryData).length > 0 &&
                                <UILIB.Column xs={12}>
                                    <h4>Query String</h4>
                                    <table className="apiDocs-table">
                                        {JSON.parse(this.state.currentDoc.queryData).map((queryItem, index) => {
                                            return <tr key={index}>
                                                <td>{queryItem.fieldName}</td>
                                                <td>{queryItem.dataType}</td>
                                                <td>{queryItem.required == "on" ? <div className="apiDocs-required">Required</div> : ""}</td>
                                                <td>{queryItem.description}</td>
                                            </tr>
                                        })}

                                    </table>
                                </UILIB.Column>}

                            {this.state.currentDoc.postData && JSON.parse(this.state.currentDoc.postData).length > 0 &&
                                <UILIB.Column xs={12}>
                                    <h4>Request</h4>
                                    <table className="apiDocs-table">
                                        {JSON.parse(this.state.currentDoc.postData).map((queryItem, index) => {
                                            return <tr key={index}>
                                                <td>
                                                    {queryItem.fieldName.indexOf('.') <= 0 && <span>{queryItem.fieldName}</span>}
                                                    {queryItem.fieldName.indexOf('.') > 0 && <span>{queryItem.fieldName.split('.').map((subCat, index2) => {
                                                        return <span key={"s_" + index + "_" + index2}>
                                                            {(index2 > 0) && <span> -> </span>}
                                                            {subCat}
                                                        </span>
                                                    })}</span>}
                                                </td>
                                                <td>{queryItem.dataType}</td>
                                                <td>{queryItem.required == "on" ? <div className="apiDocs-required">Required</div> : ""}</td>
                                                <td>{queryItem.description}</td>
                                            </tr>
                                        })}

                                    </table>
                                </UILIB.Column>}

                            {this.state.currentDoc.responseData && JSON.parse(this.state.currentDoc.responseData).length > 0 &&
                                <UILIB.Column xs={12}>
                                    <h4>Response</h4>
                                    <table className="apiDocs-table">
                                        {JSON.parse(this.state.currentDoc.responseData).map((queryItem, index) => {
                                            return <tr key={index}>
                                                <td>
                                                    {queryItem.fieldName.indexOf('.') <= 0 && <span>{queryItem.fieldName}</span>}
                                                    {queryItem.fieldName.indexOf('.') > 0 && <span>{queryItem.fieldName.split('.').map((subCat, index2) => {
                                                        return <span key={"b_" + index + "_" + index2}>
                                                            {(index2 > 0) && <span> -> </span>}
                                                            {subCat}
                                                        </span>
                                                    })}</span>}
                                                </td>
                                                <td>{queryItem.dataType}</td>
                                                <td>{queryItem.required == "on" ? <div className="apiDocs-required">Required</div> : ""}</td>
                                                <td>{queryItem.description}</td>
                                            </tr>
                                        })}

                                    </table>
                                </UILIB.Column>}

                            {this.state.currentDoc.requestExample && <UILIB.Column xs={12}>
                                <h3>Sample request</h3>
                                <Highlight className='json'>
                                    {this.state.currentDoc.requestExample}
                                </Highlight>

                            </UILIB.Column>}

                            {this.state.currentDoc.responseExample && <UILIB.Column xs={12}>
                                <h3>Sample response</h3>
                                <Highlight className='json'>
                                    {this.state.currentDoc.responseExample}
                                </Highlight>

                            </UILIB.Column>}

                            <UILIB.Column xs={12}>
                                <div style={{ "display": "flex", justifyContent: "space-between" }}>
                                    <h3>Code Sample</h3>
                                    <div>
                                        <UILIB.Select
                                            value={this.state.code}
                                            data={Object.keys(codes).map(option => {
                                                return {
                                                    label: option,
                                                    value: option
                                                }
                                            })} onChange={ev => {
                                                this.setState({ code: ev.currentTarget.value })
                                            }}
                                            outerStyle={{ width: "100%", maxWidth: "250px" }} />

                                    </div>

                                </div>

                                <AceEditor
                                    mode={this.state.code === 'curl' ? 'powershell' : this.state.code}
                                    theme="xcode"
                                    name="code"
                                    width="100%"
                                    height="200px"
                                    readOnly={true}
                                    wrapEnabled={true}
                                    showPrintMargin={false}
                                    editorProps={{ $blockScrolling: true }}
                                    value={code}
                                />
                            </UILIB.Column>


                        </UILIB.Row>}





                    </div>

                }
            </div>
        </div >

    };

}

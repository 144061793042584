import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import ServerHolder from './serverHolder';
import DomainHolder from './domainHolder';
import Server from './server';
import Domain from './domain';

class UnassignedHolder extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {
        var ourGroup = this.props.groups.find(g => g.id == -1);
        return <div style={{ display: "flex", flexDirection: "column", height: "100%", backgroundColor: "white" }}>
            <div style={{ flex: "0 0 50%", height: "50%", boxShadow: "none" }} className="mar-b0">
                <div style={{ textAlign: "center", fontSize: "20px", padding: "20px" }}>Unassigned Servers</div>

                {ourGroup.serversTmp.length < 0 &&
                    <h4 style={{ color: "#999999" }} className="mar-b0">No Spare Servers</h4>
                }
                {ourGroup.serversTmp.length > -1 && <div style={{ height: "calc(100% - 70px)", overflow: "auto", padding: "20px", paddingTop: "0px" }}>
                    <ServerHolder group={ourGroup} serverChangeGroup={this.props.serverChangeGroup}>
                        {ourGroup.serversTmp.map((sv, svIndex) => {

                            return <Server key={"server_" + svIndex} server={sv} group={ourGroup} style={{ width: "95%" }} />

                        })}
                    </ServerHolder>
                </div>
                }
            </div>

            <div style={{ flex: "0 0 50%", height: "50%", boxShadow: "none" }} className="mar-b0">
                <div style={{ textAlign: "center", fontSize: "20px", padding: "20px" }}>Unassigned Domains</div>

                {ourGroup.domainsTmp.length < 0 &&
                    <h4 style={{ color: "#999999" }} className="mar-b0">No Spare Domains</h4>
                }
                {ourGroup.domainsTmp.length > -1 && <div style={{ height: "calc(100% - 70px)", overflow: "auto", padding: "20px", paddingTop: "0px" }}>
                    <DomainHolder group={ourGroup} domainChangeGroup={this.props.domainChangeGroup}>
                        {ourGroup.domainsTmp.map((dm, svIndex) => {

                            return <Domain key={"domain_" + svIndex} domain={dm} group={ourGroup} style={{ width: "95%" }} />

                        })}
                    </DomainHolder>
                </div>
                }
            </div>

        </div>
    }
}
export default UnassignedHolder;
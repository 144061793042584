import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import fontsHelper from '~/Classes/fonts'
import MPZColorPicker from '~/pages/cp/templates/add/dragDrop/rightTools/elementEditor/components/colorPickerComponent.jsx'
import ddDefaultsHelper from '~/Classes/dragAndDropDefaults'
import MergeTags from '~/pages/cp/includes/mergeTags/mergeTags';
import DragDropFunctions from '~/pages/cp/templates/add/dragDrop/dragDropFunctions'

export default class EditCustomisation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customisation: {},
            loaded: false,
            showFontColorPicker: false,
            drawerContent: undefined
        }

        this.saveTextColor = this.saveTextColor.bind(this);
        this.clickChecker = this.clickChecker.bind(this);
        this.changeVal = this.changeVal.bind(this);

        this.openMergeTags = this.openMergeTags.bind(this);
        this.insertMergeTag = this.insertMergeTag.bind(this);
    }

    componentDidMount() {

        var customisation = this.props.customisations[this.props.customisationId]

        this.setState({ customisation, loaded: true })

    }

    componentWillUnmount() {
    }


    clickChecker(event) {
        if (this.state.showFontColorPicker) {
            this.setState({ showFontColorPicker: false })
        }
    }
    changeVal(event, targetName) {
        var customisation = this.state.customisation;
        var fName = "";
        var fVal = "";
        if (targetName) {
            fName = targetName;
            fVal = event;
        }
        else {
            fName = event.target.name;
            fVal = event.target.value;
        }
        customisation[fName] = fVal;
        this.setState({ customisation })
        this.props.updateEdit(customisation)
    }
    saveTextColor(event) {
        var customisation = this.state.customisation;
        customisation.fontColor = event.hex;
        this.setState({ customisation })
        this.props.updateEdit(customisation)
    }

    openMergeTags(fieldName) {
        var drawerContent = <div style={{ height: "400px", overflow: "auto" }}>
            <MergeTags mergeTags={DragDropFunctions.mergeTags} onTagClick={(value) => { this.insertMergeTag(value, fieldName) }} />
        </div>
        this.setState({ drawerContent })
    }


    insertMergeTag(value, fieldName) {
        this.state.customisation[fieldName] += " " + value;
        this.setState({ drawerContent: undefined })
    }

    render() {
        if (this.state.drawerContent) return this.state.drawerContent;
        if (this.state.loaded == false) return <div></div>

        var fontSizes = [];
        var tmpCnt = 8;
        while (tmpCnt < 500) {
            fontSizes.push({ label: tmpCnt + "px", value: tmpCnt })
            tmpCnt++;
        }

        var fonts = [];
        fontsHelper.forEach(font => {
            fonts.push({ label: font.label, value: font.label })
        })
        var horizontalAlign = ddDefaultsHelper.horizontalAlign.map(hz => {

            return { label: hz.value, value: hz.value }
        })

        return <div onClick={this.clickChecker}>
            <h4 className="mar-b10">Add/Edit a Customisation</h4>
            <UILIB.Row className="mar-b0" >
                <UILIB.Column xs={12} className="form-group mar-b10" margin={0}>

                    <UILIB.TextInput explainer={<a onClick={() => this.openMergeTags("text")} className="text-xsml">Merge Tags</a>} label="Text to Add" name="text" value={this.state.customisation.text} onChange={this.changeVal} />

                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="form-group mar-b10" margin={0}>

                    <UILIB.Select label="Font" name="font" data={fonts} value={this.state.customisation.font} onChange={this.changeVal} />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="form-group mar-b10" margin={0}>

                    <UILIB.Select label="Font Size" name="fontSize" data={fontSizes} value={this.state.customisation.fontSize} onChange={this.changeVal} />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="form-group mar-b10" margin={0} style={{ position: "relative" }}>
                    <div className="txtInput_label mar-b5">Font Color</div>
                    <div style={{ width: "24px", height: "24px", borderRadius: "2px", background: "#fff", border: "1px solid #3e4661", cursor: "pointer", transition: "all 0.3s ease", position: "relative", backgroundColor: this.state.customisation.fontColor }} onClick={() => { this.setState({ showFontColorPicker: true }) }}></div>
                    {this.state.showFontColorPicker == true && <div style={{ position: "absolute", left: "50px", top: "10px" }}>
                        <MPZColorPicker color={this.state.customisation.fontColor} onChange={this.saveTextColor} disableAlpha={true} globalColours={[]} favoriteColors={[]} saveFaveColor={() => { }} />
                    </div>}
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="form-group mar-b10" margin={0}>

                    <UILIB.Select label="Text Align" name="textAlign" data={horizontalAlign} value={this.state.customisation.textAlign} onChange={this.changeVal} />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="form-group mar-b10" margin={0}>

                    <UILIB.TextInput label="Left" name="left" type="number" value={this.state.customisation.left} onChange={this.changeVal} />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="form-group mar-b10" margin={0}>

                    <UILIB.TextInput label="Top" name="top" value={this.state.customisation.top} onChange={this.changeVal} />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="form-group mar-b10" margin={0}>

                    <UILIB.DragBar label="Rotation" value={this.state.customisation.rotation} name="mapZoom" minVal={0} maxVal={360} onChange={(newVal) => { this.changeVal(newVal, 'rotation'); }} placeholder="Post/Zip Code" />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="form-group mar-b10 end-xs" margin={0}>
                    <UILIB.Button className="button-primary" text="Save" onClick={this.props.finishedEdit} />
                </UILIB.Column>
            </UILIB.Row>
        </div >
    }
}

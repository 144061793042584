import React, { useState, useReducer } from "react";
import { useDispatch } from "react-redux";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import Button from "../../../components/button";
import * as styles from "./index.module.scss";
import TextInput from "../../../components/textInput";
import Header from "../../../components/header";
import i18n from "~/i18n";

export default function FacebookMessengerChannelWelcomeMessages({
    channel,
    onUpdateChannel,
}) {
    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false);
    const [values, updateValues] = useReducer(
        (state, action) => {
            return { ...state, ...action };
        },
        {
            welcomeEmailMessage: channel.settings.welcomeEmailMessage || "",
            emailPromptMessage: channel.settings.emailPromptMessage || "",
            invalidEmailMessage: channel.settings.invalidEmailMessage || "",
            existingMessage: channel.settings.existingMessage || "",
        }
    );

    async function handleSubmit(e) {
        e.preventDefault();
        setSubmitting(true);

        const data = { settings: values };

        try {
            await onUpdateChannel(data);
            dispatch(alterSnackbar(true, i18n.t("chat:channel.facebook.messages.saved")));
        } catch (error) {
            dispatch(handleGenericError(error));
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
            <Header type="facebookMessenger" option="welcome-messages" />
            <form className={styles.root} onSubmit={handleSubmit}>
                <TextInput
                    label={i18n.t("chat:channel.facebook.messages.welcome")}
                    description={i18n.t("chat:channel.facebook.messages.welcomeDesc")}
                    value={values.welcomeEmailMessage}
                    onChange={(e) => updateValues({ welcomeEmailMessage: e.target.value })}
                />

                <TextInput
                    label={i18n.t("chat:channel.facebook.messages.email")}
                    description={i18n.t("chat:channel.facebook.messages.emailDesc")}
                    value={values.emailPromptMessage}
                    onChange={(e) => updateValues({ emailPromptMessage: e.target.value })}
                />

                <TextInput
                    label={i18n.t("chat:channel.facebook.messages.invalid")}
                    description={i18n.t("chat:channel.facebook.messages.invalidDesc")}
                    value={values.invalidEmailMessage}
                    onChange={(e) => updateValues({ invalidEmailMessage: e.target.value })}
                />

                <Button
                    className={styles.submitButton}
                    type="submit"
                    size="s"
                    loading={submitting}
                >
                    {i18n.t("chat:channel.facebook.messages.save")}
                </Button>
            </form>
        </div>
    );
}

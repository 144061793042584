import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';
import LandingPage from './landingPage'
@connect((store) => {
    return { accountMaster: store.accountMaster, permissionStore: store.permission, siteMaster: store.siteMaster }
})
class LandingPages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableData: [
                { name: i18n.t('company:landing.unsubscribeLabel'), type: 'unsubscribe', desc: i18n.t('company:landing.unsubscribeText'), lookup: 'unsubscribeTemplateId', default: 'unsubscribeDefaultPage', id: 21966 },
                { name: i18n.t('company:landing.updateLabel'), type: 'update', desc: i18n.t('company:landing.unsubscribeText'), lookup: 'updateTemplateId', default: 'updateDefaultPage', id: 21972 }
            ]
        }
    }

    render() {

        return (
            <div>

                {this.props.siteMaster.siteId == 1 && <div className="quickFlex mar-b25">
                    <UILIB.Hint
                        iconLeft={<UILIB.Icons icon="questionCircle" />}
                        className="mar-l0"
                    >
                        {i18n.t('company:landing.subHeader')}
                    </UILIB.Hint>
                </div>}


                <UILIB.Row>

                    {this.state.tableData.map((template, index) => {
                        return <LandingPage key={"lp_" + index} template={template} history={this.props.history} />
                    })}
                </UILIB.Row>
            </div>
        );
    }
}

export default LandingPages;
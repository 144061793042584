import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here

import AddGroupView from '~/pages/cp/subscribers/groups/incGoAddGroup';
import ChangeGroupConfirm from './incChangeGroupConfirm';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class StripeConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupId: -1,
            groupData: [],
            isLoaded: false
        };

        this.changeGroup = this.changeGroup.bind(this);
        this.addAGroup = this.addAGroup.bind(this);
        this.addedGroup = this.addedGroup.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.changeGroupConfirm = this.changeGroupConfirm.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.changeTheGroup = this.changeTheGroup.bind(this);
    }

    componentDidMount() {
        this.getGroups();
    }

    getGroups() {
        var self = this;
        axios.get("/group").then(_theGroups => {
            self.setState({ groupData: _theGroups.data.Groups, isLoaded: true })
        })
    }

    changeGroup(event) {
        this.setState({ groupId: event.target.value })
    }

    addAGroup() {
        var drawerContent = <AddGroupView addedGroup={this.addedGroup} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    addedGroup(event) {
        var groupId = event.data.groupId;
        this.setState({ groupId: groupId }, this.getGroups());
    }

    changeGroupConfirm() {
        var drawerContent = <ChangeGroupConfirm changeTheGroup={this.changeTheGroup} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    changeTheGroup() {
        this.props.updateGroup(this.state.groupId);
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    render() {

        if (this.state.isLoaded == false) return <div></div>

        var groupData = [];
        groupData = this.state.groupData.map(theRow => {
            return { label: theRow.groupName, value: Number(theRow.id) }
        });
        groupData.unshift({ label: "Select a Group", value: -1 });

        return <div>
            <UILIB.Paper>
                <h4 className="mar-b25">Lets get started!</h4>
                <p>Please select a group which will be synced with your Stripe contacts</p>
                <UILIB.Row margin={0}>
                    {groupData.length &&
                        <UILIB.Column xs={12}>
                            <div className="quickFlex">
                                <div className="form-group mar-b0"><UILIB.Select data={groupData} name="groupId" value={this.state.groupId} onChange={this.changeGroup} /></div>
                                <span style={{ marginLeft: "10px" }}>or</span>
                                <UILIB.Button text="Add a new Group" className="button-sml outline secondary" style={{ marginLeft: "10px" }} onClick={this.addAGroup} />
                            </div>
                        </UILIB.Column>
                    }
                    <UILIB.Column xs={12} margin={0}>
                        {this.state.groupId != -1 && <UILIB.Button text="Continue" className="button-sml" onClick={this.changeGroupConfirm} />}
                    </UILIB.Column>
                </UILIB.Row>
            </UILIB.Paper>
        </div >

    };


};



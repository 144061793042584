import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'

class DrawerDeleteConfirm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <div className="mar-b30">
                <h4 className="mar-b25">Really delete this?</h4>
                <div className="mar-b25">Are you sure you want to delete this step?</div>
                <p>Warning: If you delete this step, all subsequent steps will also be deleted!</p>
            </div>
            <div className="quickFlex">
                <p className="mar-r10"><UILIB.Button text="Delete It!" className="outline red button-sml" onClick={this.props.onConfirmDelete} /></p>
                <p><UILIB.Button text="Forget It!" className="outline primary button-sml" onClick={this.props.onDontDelete} /></p>
            </div>

        </div>


    }
}

export default DrawerDeleteConfirm;
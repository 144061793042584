import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '../../../../data/http/axios';

class DeleteFromGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.deleteFromGroup = this.deleteFromGroup.bind(this);
    }

    deleteFromGroup() {
        axios.put('/mailServer/group/' + this.props.group.id + '?serverId=' + this.props.server.id + '&action=remove').then(_gDat => {
            this.props.finished();
        })
    }


    render() {
        return (
            <div>
                <h4 className="mar-b25">Remove Server from Group</h4>
                <p>Are you sure you would like to remove server <strong>{this.props.server.name}</strong> from the group <strong>{this.props.group.name}</strong>?</p>
                <p>The server won't be deleted, it will simply be removed from the group.</p>
                <UILIB.Button className="button-sml" text="Remove from Group" onClick={this.deleteFromGroup} />
            </div>
        );
    }
}

export default DeleteFromGroup;
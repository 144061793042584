import React from "react";
import classnames from "classnames";
import i18n from "~/i18n";

export default function PollResultsOverview({
  completed,
  total,
  className,
  ...rest
}) {
  const completionPercentage = ((completed / total) * 100 || 0).toFixed();

  const classes = classnames("campaign-poll-results-overview", className);

  return (
    <div className={classes} {...rest}>
      <h3 className="h5">{i18n.t("campaigns:polls.responses")}</h3>

      <div className="campaign-poll-results-overview__stats">
        <div className="h1 mar-b5">{completed} </div>
        <span className="h4">/ {total}</span>
      </div>

      <div className="campaign-poll-results-overview__completion-rate">
        <span className="fw-medium">{completionPercentage}%</span>

        <div className="campaign-poll-results-overview__completion-bar">
          <span
            className="campaign-poll-results-overview__completion-bar-filled"
            style={{ width: `${completionPercentage}%` }}
          />
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import UILIB from '../../../../Common-Objects/Lib';
import { connect } from 'react-redux';

import QuickTipsLink from './quickTipsLink';
@connect((store) => {
    return { siteMaster: store.siteMaster, user: store.user }
})

export default class QuickTips extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };

    }

    render() {
        return <UILIB.Paper className="m-mar-b0">

            <div>
                <h4 className="mar-b5">Quick &amp; Useful Links</h4>
                <div className="mar-b15">Make the most out of Transpond</div>

                <div className="simpleList mar-b30">
                    <div className="simpleList-item simpleList-item-dividingLine simpleList-item-clickable" onClick={() => { window.open("https://kb.transpond.io/hc/en-us/articles/360007483571-Adding-a-new-Group", "_blank") }}>
                        <div><a>How to add a new group</a></div>
                        <div><UILIB.Icons icon="arrowRight" /></div>
                    </div>
                    <div className="simpleList-item simpleList-item-dividingLine simpleList-item-clickable" onClick={() => { window.open("https://kb.transpond.io/article/118-what-is-an-email-campaign", "_blank") }}>
                        <div><a>How to send a campaign</a></div>
                        <div><UILIB.Icons icon="arrowRight" /></div>
                    </div>
                    <div className="simpleList-item simpleList-item-dividingLine simpleList-item-clickable" onClick={() => { window.open("https://kb.transpond.io/hc/en-us/articles/360008359591-Adding-a-Custom-Field", "_blank") }}>
                        <div><a>Adding a custom field</a></div>
                        <div><UILIB.Icons icon="arrowRight" /></div>
                    </div>
                </div>
            </div>
            {!!this.props.siteMaster.supportUrl && <div>
                <UILIB.Paper className="paper-primary paper-tight quickFlex mar-b0" style={{ justifyContent: "space-between", padding: "24px" }}>
                    <div className="mar-r20" style={{ fontSize: "16px", fontWeight: 600, lineHeight: "22px" }}>We're here to help every step of the way</div>
                    <div ><UILIB.Button href={this.props.siteMaster.supportUrl} target="_blank" style={{ whiteSpace: 'nowrap' }} className="button-primary button-lighten" iconLeft={<UILIB.Icons icon="lifering" style={{ width: "20px", height: "20px" }} />}>Support Docs</UILIB.Button></div>
                </UILIB.Paper>
            </div>}
        </UILIB.Paper >
    };
};

import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import Fields from './fields';
import Hooks from './hooks';
import AddGroupView from '~/pages/cp/subscribers/groups/incGoAddGroup';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';

@connect((store) => {
    return { user: store.user }
})
class HubspotInitial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupId: undefined,
            loaded: false,
            groups: [],
            showFields: false,
            showAction: false
        }

        this.getGroups = this.getGroups.bind(this)
        this.save = this.save.bind(this)
        this.changeGroup = this.changeGroup.bind(this)
        this.addAGroup = this.addAGroup.bind(this)
        this.toggleOptions = this.toggleOptions.bind(this);
    }

    componentDidMount() {
        this.getGroups()
        this.setState({
            groupId: this.props.appBinding.appSettings ? this.props.appBinding.appSettings.groupId : undefined
        })
    }

    getGroups() {
        axios.get('/group').then(response => {
            this.setState({
                loaded: true,
                groups: response.data.Groups.map(g => {
                    return {
                        label: g.groupName,
                        value: g.id
                    }
                })
            })
        })
    }

    changeGroup(ev) {
        this.setState({
            groupId: ev.currentTarget.value
        })
    }

    addAGroup() {
        var drawerContent = <AddGroupView addedGroup={(response) => {
            var groupId = response.data.groupId;
            this.setState({ groupId }, this.getGroups);
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    save() {
        var binding = this.props.appBinding;
        if (!binding.appSettings) binding.appSettings = {}
        binding.appSettings.groupId = this.state.groupId
        axios.put("/application/account/" + binding.id, binding).then(() => {
            var params = '?resync=true'
            return axios.get('/application/pipedrive/setup' + params)
        }).then(this.props.finish)
    }

    toggleOptions(optionType) {
        var opt = this.state[optionType];
        if (opt) {
            opt = false
        }
        else {
            opt = true
        }
        this.setState({ [optionType]: opt })
    }
    render() {
        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} margin={0}>
                        <UILIB.Paper className="primary">
                            <h4 className="mar-b25 text-white">Select a Group to Sync With</h4>
                            <p className="text-white">
                                Please select an existing Transpond group, or create a new one, where you would like contacts from Pipedrive to be created.
                            </p>
                            {(this.state.groups.length > 0) &&
                                <div className="quickFlex">
                                    <div className="form-group mar-b0"><UILIB.Select data={this.state.groups} placeholder="--Select a Group--" name="groupId" value={this.state.groupId} onChange={this.changeGroup} /></div>
                                    <span style={{ marginLeft: "10px" }}>or</span>
                                    <UILIB.Button text="Add a new Group" className="button-sml white outline" style={{ marginLeft: "10px" }} onClick={this.addAGroup} />
                                </div>
                            }
                            {(this.state.groups.length <= 0) && <UILIB.Button text="Add a new Group" className="button-sml white outline" style={{ marginLeft: "10px" }} onClick={this.addAGroup} />}
                        </UILIB.Paper>
                    </UILIB.Column>

                    {this.state.groupId && this.state.groupId != -1 && <UILIB.Column xs={12} margin={0}>
                        <UILIB.Paper>
                            <div className="quickFlex">
                                <div style={{ flex: "1" }}>
                                    <h4>Fields to Synchronise</h4>
                                    <p>Select which of your Pipedrive Fields we should import for you Contacts.</p>
                                </div>
                                <div style={{ flex: "0 0 150", textAlign: "right" }}>
                                    <UILIB.Button text={this.state.showFields ? "Hide" : "Show"} className={"button-md primary" + (this.state.showFields ? "" : " outline")} onClick={() => { this.toggleOptions('showFields') }} />
                                </div>
                            </div>
                            {this.state.showFields && <div>
                                <Fields appBinding={this.props.appBinding} />
                            </div>}
                        </UILIB.Paper>

                    </UILIB.Column>}


                    {this.state.groupId && this.state.groupId != -1 && <UILIB.Column xs={12} margin={0}>
                        <UILIB.Paper>
                            <div className="quickFlex">
                                <div style={{ flex: "1" }}>
                                    <h4>Update Pipedrive</h4>
                                    <p>Update fields in Pipedrive when Contacts interact with your emails.</p>
                                </div>
                                <div style={{ flex: "0 0 150", textAlign: "right" }}>
                                    <UILIB.Button text={this.state.showActions ? "Hide" : "Show"} className={"button-md primary" + (this.state.showActions ? "" : " outline")} onClick={() => { this.toggleOptions('showActions') }} />
                                </div>
                            </div>
                            {this.state.showActions && <div>
                                <Hooks appSettings={this.props.appBinding.appSettings} app={this.props.appBinding} />
                            </div>
                            }
                        </UILIB.Paper>
                    </UILIB.Column>}

                    {this.state.groupId && this.state.groupId != -1 &&
                        <UILIB.Column xs={12} className="center-xs">
                            <UILIB.Button text="Save and Continue" onClick={this.save} />
                        </UILIB.Column>
                    }
                </UILIB.Row>
            </div>
        );
    }
}

export default HubspotInitial;
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import AddDomainPop from './addDomainPop.jsx';
import BuyDomainPop from './buyDomainPop.jsx';
import BuyDomainStep2Pop from './buyDomainStep2Pop.jsx';
import DeleteDomainPop from './deleteDomainPop.jsx';
import AuthDomainPop from './authDomainPop.jsx';

import AddDomainPopAdrom from './addDomainPopAdrom.jsx';
import AuthDomainAdromPop from './authDomainAdromPop.jsx';

import BlacklistDrawer from './blacklistDrawer.jsx';
import SetToDefaultPreviewDomain from './setToDefaultPreviewDomain.jsx';

import AddTracking from './addTracking';
import DeleteTracking from './deleteTracking';
import AddCDN from './addCDN';
import DeleteCDN from './deleteCDN';
import i18n from '~/i18n';
import PermissionChecker from '~/Classes/permissions'

import SetupMxRecordDrawer from './setupMxRecordDrawer';
import DomainChangeEmail from './changePreviewEmail.jsx';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

export default class CompanyDomains extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoaded: false,
            domainsData: [],
            tracking: [],
            cdn: [],
            alertMessage: '',
            alertOpen: false,
            writeAccess: true,
            showAdvanced: false,
            isAdrom: false
        };
        this.loadDomains = this.loadDomains.bind(this);

        this.addDomain = this.addDomain.bind(this);
        this.addedDomain = this.addedDomain.bind(this);

        this.buyDomain = this.buyDomain.bind(this);
        this.buyDomainStep2 = this.buyDomainStep2.bind(this);

        this.deleteDomain = this.deleteDomain.bind(this);
        this.resendAuthEmail = this.resendAuthEmail.bind(this);
        this.authDomain = this.authDomain.bind(this);
        this.addTracking = this.addTracking.bind(this);
        this.checkTracking = this.checkTracking.bind(this);
        this.deleteTracking = this.deleteTracking.bind(this);

        this.addCDN = this.addCDN.bind(this);
        this.checkCDN = this.checkCDN.bind(this);
        this.deleteCDN = this.deleteCDN.bind(this);
        this.deletedDomain = this.deletedDomain.bind(this);

        this.closeDrawer = this.closeDrawer.bind(this);
        this.showBlacklistHelper = this.showBlacklistHelper.bind(this);
        this.makeDefaultPreviewDomain = this.makeDefaultPreviewDomain.bind(this);
        this.saveDefaultPreviewDomain = this.saveDefaultPreviewDomain.bind(this);

        this.setupMxRecord = this.setupMxRecord.bind(this);
        this.finishSetupMxRecord = this.finishSetupMxRecord.bind(this);
    }

    componentDidMount() {
        //see if we have write access to the company
        var writeAccess = PermissionChecker("domains", this.props.permissionStore.permissions, "write");

        var isAdrom = false;
        //check if they are adrom, for domain auth. Prob need to make this a little more generic later on

        if (this.props.siteMaster.siteId == "2") {
            isAdrom = true;
        }

        this.setState({ writeAccess: writeAccess, isAdrom: isAdrom }, this.loadDomains())
    }

    //Load Staff stuff
    loadDomains() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        var domainsData = [];
        axios.get('/accountMaster/domain')
            .then((domainsData) => {
                this.setState({ domainsData: domainsData.data.domains, pageLoaded: true });
            });

        axios.get('/accountMaster/tracking')
            .then(response => {
                this.setState({
                    tracking: response.data.Domains,
                    showAdvanced: this.state.showAdvanced ? true : !!response.data.Domains.length
                })
            })

        axios.get('/accountMaster/cdn')
            .then(response => {
                this.setState({
                    cdn: response.data.Domains,
                    showAdvanced: this.state.showAdvanced ? true : !!response.data.Domains.length
                })
            })
    }


    //add domain
    addDomain() {
        var drawerContent = <AddDomainPop onAddedDomain={this.addedDomain} />;
        if (this.state.isAdrom) {
            drawerContent = <AddDomainPopAdrom onAddedDomain={this.addedDomain} />;
        }

        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }
    addedDomain() {
        this.setState({ alertOpen: true, alertMessage: i18n.t('company:domains.alertDomainAdded') })
        this.loadDomains();
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
    }

    buyDomain() {
        var drawerContent = <BuyDomainPop continue={this.buyDomainStep2} closeDrawer={this.closeDrawer} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "700px"));
    }
    buyDomainStep2(domainName, domainResult) {
        var drawerContent = <BuyDomainStep2Pop domainName={domainName} domainResult={domainResult} finished={() => { this.closeDrawer(); this.loadDomains(); }} closeDrawer={this.closeDrawer} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "700px"));
    }

    //delete domain
    deleteDomain(domainId) {
        var drawerContent = <DeleteDomainPop domainId={domainId} onDeleted={this.deletedDomain} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    deletedDomain() {
        this.setState({ alertOpen: true, alertMessage: i18n.t('company:domains.alertDomainDeleted') })
        this.loadDomains();
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
    }

    authDomain(domain) {
        var drawerContent = <AuthDomainPop domain={domain} onAuthed={this.loadDomains} />;
        if (this.state.isAdrom) {
            drawerContent = <AuthDomainAdromPop domain={domain} onAuthed={this.loadDomains} />;
        }
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "460px"));
    }

    addTracking() {
        var drawerContent = <AddTracking onAddedDomain={this.addedDomain} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    deleteTracking(domainId) {
        var drawerContent = <DeleteTracking domainId={domainId} onDeleted={this.loadDomains} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    checkTracking(id) {
        this.setState({
            alertOpen: true, alertMessage: i18n.t('company:domains.tracking.checking')
        })
        axios.put('/accountMaster/tracking/' + id + '/authenticate').then(response => {
            if (response.data.error) {
                this.setState({
                    alertOpen: true, alertMessage: response.data.error
                })
            } else {
                this.loadDomains()
                this.setState({
                    alertOpen: true, alertMessage: i18n.t('company:domains.tracking.authed')
                })
            }
        })
    }

    addCDN() {
        var drawerContent = <AddCDN onAddedDomain={this.addedDomain} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    deleteCDN(domainId) {
        var drawerContent = <DeleteCDN domainId={domainId} onDeleted={this.loadDomains} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    checkCDN(id) {
        this.setState({
            alertOpen: true, alertMessage: i18n.t('company:domains.cdn.checking')
        })
        axios.put('/accountMaster/cdn/' + id + '/authenticate').then(response => {
            if (response.data.error) {
                this.setState({
                    alertOpen: true, alertMessage: response.data.error
                })
            } else {
                this.loadDomains()
                this.setState({
                    alertOpen: true, alertMessage: i18n.t('company:domains.cdn.authed')
                })
            }
        })
    }

    resendAuthEmail(thisDomain) {
        var self = this;
        axios.get('/accountMaster/domain/resendAuthEmail/' + thisDomain.id)
            .then(() => {
                self.setState({ alertOpen: true, alertMessage: i18n.t('company:domains.alertEmailSent') })
            }).catch(err => {
                self.setState({ alertOpen: true, alertMessage: i18n.t('company:domains.alertErrorEmail') })
            })
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", undefined, false));
    }

    showBlacklistHelper(domain) {
        var drawerContent = <BlacklistDrawer domain={domain} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, 600));
    }

    makeDefaultPreviewDomain(domain) {
        var drawerContent = <SetToDefaultPreviewDomain domain={domain} onSelected={this.saveDefaultPreviewDomain} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }
    saveDefaultPreviewDomain(domain) {
        this.closeDrawer();
        var self = this;
        axios.put('/accountMaster/domain/' + domain.id + '/setPreviewDefault').then(() => {
            self.loadDomains();
        })
    }

    setupMxRecord(domain) {
        var drawerContent = <SetupMxRecordDrawer domain={domain} finished={this.finishSetupMxRecord} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "100vw"));
    }
    finishSetupMxRecord(domain) {
        this.loadDomains();
        this.closeDrawer();
    }

    changeEmailAddress(domain) {
        var drawerContent = <DomainChangeEmail id={domain.id} finish={this.loadDomains} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, 500));

    }

    render() {


        if (this.state.pageLoaded === false) {
            return <div></div>
        }
        else {
            const isPaid = this.props.accountMaster.accountMaster.accountType !== 'free' || this.props.siteMaster.siteId !== 1
            const hasDomains = !!this.state.tracking.length || !!this.state.cdn.length
            var hasDefaultPreviewDomain = this.state.domainsData.find(d => d.defaultPreviewDomain == true)

            return <div>

                <UILIB.Paper>
                    <UILIB.Row className="middle-xs">
                        <UILIB.Column xs={12} sm={6} className="mar-b25">
                            <div className="quickFlex">
                                <h4>{i18n.t('company:domains.sending.header')}</h4>
                                {this.props.siteMaster.siteId == 1 && <UILIB.Hint href="https://kb.transpond.io/article/106-email-configuration" iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>Learn how domains work.</UILIB.Hint>}
                            </div>
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} className="text-right mar-b25" style={{ display: 'flex', justifyContent: 'end' }}>
                            {this.state.writeAccess &&
                                <UILIB.Button style={{ whiteSpace: "nowrap" }} className="button-primary" iconLeft={<UILIB.Icons icon="plus" />} text={i18n.t('company:domains.sendingAdd')} onClick={this.addDomain} />
                            }
                            {(this.state.writeAccess && this.props.siteMaster.siteId == 1) &&
                                <UILIB.Button style={{ whiteSpace: "nowrap" }} className="mar-l10" text={"Buy a new Domain"} onClick={this.buyDomain} />
                            }
                        </UILIB.Column>
                    </UILIB.Row>

                    <UILIB.Row>
                        {this.state.domainsData.length > 0 && this.state.domainsData.map((theDomain, index) => {
                            return <UILIB.Column xs={12} sm={6} md={4} lg={3} key={index} className="mar-b25">
                                <UILIB.DomainCard cardType="domain" domain={theDomain} history={this.props.history} changeEmailAddress={this.changeEmailAddress.bind(this)} deleteDomain={this.deleteDomain} resendAuthEmail={this.resendAuthEmail} authDomain={this.authDomain} writeAccess={this.state.writeAccess} showBlacklists={this.showBlacklistHelper} hasDefaultPreviewDomain={hasDefaultPreviewDomain} makeDefaultPreviewDomain={this.makeDefaultPreviewDomain} showSetupMxRecord={this.state.isAdrom == false} setupMxRecord={this.setupMxRecord} />
                            </UILIB.Column>
                        })}
                    </UILIB.Row>
                </UILIB.Paper>
                <UILIB.Row>
                    <UILIB.Column xs={12} className="center-xs mar-b25">
                        <UILIB.Button
                            text={i18n.t('company:domains.advancedOptions')}
                            iconRight={<UILIB.Icons icon={"arrowRight"} className={this.state.showAdvanced ? 'flipRight' : ''} />}
                            onClick={() => this.setState({ showAdvanced: !this.state.showAdvanced })}
                        />
                    </UILIB.Column>
                </UILIB.Row>
                {this.state.showAdvanced && <div>
                    <UILIB.Paper>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={6} className="mar-b25">
                                <div className="quickFlex">
                                    <h4>{i18n.t('company:domains.trackingHeader')}</h4>
                                    <UILIB.Hint iconLeft={<UILIB.Icons icon="questionCircle" />}>{i18n.t('company:domains.trackingDesc')}</UILIB.Hint>
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} className="text-right mar-b25">
                                {this.state.writeAccess &&
                                    <UILIB.Button style={{ whiteSpace: "nowrap" }} className="button-primary" iconLeft={<UILIB.Icons icon="plus" />} text={i18n.t('company:domains.trackingAdd')} onClick={this.addTracking} />
                                }
                            </UILIB.Column>

                            {this.state.tracking.length > 0 && this.state.tracking.map((theDomain, index) => {
                                return <UILIB.Column xs={12} sm={6} md={4} lg={3} key={index} >
                                    <UILIB.DomainCard cardType="tracking" domain={theDomain} history={this.props.history} deleteDomain={this.deleteTracking} authDomain={domain => this.checkTracking(domain.id)} writeAccess={this.state.writeAccess} />
                                </UILIB.Column>
                            })}
                            {!this.state.tracking.length && <UILIB.Column xs={12} sm={6} md={4} lg={3} >
                                <BlankCard header="No Tracking Domains Setup" subheader="Setup a tracking domain to improve deliverability" buttonText="Add Domain" buttonLink={this.addTracking} helpLink="https://kb.transpond.io/article/106-email-configuration#what-is-a-tracking-domain" />
                            </UILIB.Column>}

                        </UILIB.Row>
                    </UILIB.Paper>

                    <UILIB.Paper>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={6} className="mar-b25">
                                <div className="quickFlex">
                                    <h4>{i18n.t('company:domains.cdnHeader')}</h4>
                                    <UILIB.Hint iconLeft={<UILIB.Icons icon="questionCircle" />}>{i18n.t('company:domains.cdnDesc')}</UILIB.Hint>
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6} className="text-right">
                                {this.state.writeAccess &&
                                    <UILIB.Button style={{ whiteSpace: "nowrap" }} className="button-primary" iconLeft={<UILIB.Icons icon="plus" />} text={i18n.t('company:domains.cdnAdd')} onClick={this.addCDN} />
                                }
                            </UILIB.Column>

                            {this.state.cdn.length > 0 && this.state.cdn.map((theDomain, index) => {
                                return <UILIB.Column xs={12} sm={6} md={4} lg={3} key={index} className="mar-b25">
                                    <UILIB.DomainCard cardType="cdn" domain={theDomain} history={this.props.history} deleteDomain={this.deleteCDN} authDomain={domain => this.checkCDN(domain.id)} writeAccess={this.state.writeAccess} />
                                </UILIB.Column>
                            })}
                            {!this.state.cdn.length && <UILIB.Column xs={12} sm={6} md={4} lg={3} >
                                <BlankCard header="No CDN Domains Setup" subheader="Setup a CDN domain to improve deliverability" buttonText="Add Domain" buttonLink={this.addCDN} helpLink="https://kb.transpond.io/article/106-email-configuration#what-is-a-CDN-domain" />
                            </UILIB.Column>}

                        </UILIB.Row>
                    </UILIB.Paper>
                </div>
                }

                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />


            </div >
        }
    };


};


function BlankCard({ header, subheader, buttonText, buttonLink, helpLink }) {
    return (
        <UILIB.Paper>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                <div className='cards-domain-name text-center no-marg mar-b25'>{header}</div>
                <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }} className="mar-b25">
                    {subheader}
                    <UILIB.Hint className="mar-t10" href={helpLink} iconLeft={<UILIB.Icons icon="questionCircle" />}>Learn More</UILIB.Hint>
                </div>
                <div className='cards-domain-buttons'>
                    <UILIB.Button onClick={buttonLink} iconLeft={<UILIB.Icons icon="plus" />}>{buttonText}</UILIB.Button>
                </div>
            </div>
        </UILIB.Paper>
    )
}
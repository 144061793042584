import React from 'react'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

export default class IntegrationsMasterPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className="integrationsHolder">
            <Router history={this.props.history}>
                <Switch>
                    <Redirect to="/" push />
                </Switch>
            </Router>
        </div>

    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib';
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
import i18n from '~/i18n';
import trackingImage from '../../../../assets/images/misc/tracking.jpg';
import styleImage from '../../../../assets/images/misc/style.jpg';
import scalabilityImage from '../../../../assets/images/misc/scalability.jpg';


@connect((store) => {
    return { siteMaster: store.siteMaster, user: store.user }
})
class TransactionsWelcome extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {

    }



    render() {

        // something classy explaining what transactional emails are and how to use them
        var explainerGetStartedLink = i18n.t('campaigns:transactions.explainerHowToSetupLink');

        if (explainerGetStartedLink && explainerGetStartedLink.length) {

            explainerGetStartedLink = explainerGetStartedLink.split('\[EXPLAINERLINK\]');
            explainerGetStartedLink.splice(1, 0, <a onClick={() => this.props.history.push('/cp/transactions/settings')}>{i18n.t('campaigns:transactions.settings')}</a>)

            explainerGetStartedLink.forEach((expTxt, index) => {
                if (typeof expTxt === "string" && expTxt.indexOf('\[SUPPORTLINK\]') > -1) {

                    var expDat = expTxt.split('\[SUPPORTLINK\]');
                    expDat.splice(1, 0, <a onClick={() => window.open(this.props.siteMaster.supportUrl, '_blank')}>{this.props.siteMaster.supportUrl}</a>)
                    explainerGetStartedLink.splice(index, 1);
                    explainerGetStartedLink = explainerGetStartedLink.concat(expDat);
                }

            })
        }

        return <div>

            <UILIB.Row className="middle-sm">
                <UILIB.Column xs={12} sm={4} className="col-sm-offset-2 mar-b25">
                    <div className='page-header'>{i18n.t('campaigns:transactions.explainerHeader')}</div>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={4} className="end-sm hide-xs mar-b25">
                    <UILIB.Button className="button-primary" onClick={() => this.props.history.push('/cp/transactions/settings')} iconRight={<UILIB.Icons icon="arrowRight" />}>
                        {i18n.t('campaigns:transactions.explainerButton')}
                    </UILIB.Button>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={5} className="col-sm-offset-2 mar-b25">
                    {i18n.t('campaigns:transactions.explainerDescription')}
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row className="show-xs mar-b25">
                <UILIB.Column xs={12}>
                    <UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon="arrowRight" />}>
                        {i18n.t('campaigns:transactions.explainerButton')}
                    </UILIB.Button>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row className="middle-sm mar-b25">
                <UILIB.Column xs={12} sm={4} className="col-sm-offset-2">
                    <img className="img-corners" src={trackingImage} width="100%" alt={i18n.t('campaigns:transactions.explainerTracking')} />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={4}>
                    <h4 className="pad-left-1 m-pad-0 mar-b25">{i18n.t('campaigns:transactions.explainerTracking')}</h4>
                    <p className="pad-left-1 m-pad-0 mar-b25">{i18n.t('campaigns:transactions.explainerTrackingDescription')}</p>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row className="middle-sm mar-b30">
                <UILIB.Column xs={12} sm={4} className="col-sm-offset-2 hide-xs">
                    <h4 className="pad-right-1 m-pad-0 mar-b25">{i18n.t('campaigns:transactions.explainerStyle')}</h4>
                    <p className="pad-right-1 m-pad-0 mar-b25">{i18n.t('campaigns:transactions.explainerStyleDescription')}</p>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={4}>
                    <img className="img-corners" src={styleImage} width="100%" alt={i18n.t('campaigns:transactions.explainerStyle')} />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={4} className="col-sm-offset-2 show-xs">
                    <h4 className="pad-right-1 m-pad-0 text-primary mar-b25">{i18n.t('campaigns:transactions.explainerStyle')}</h4>
                    <p className="pad-right-1 m-pad-0 mar-b25">{i18n.t('campaigns:transactions.explainerStyleDescription2')}</p>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row className="middle-sm mar-b30">
                <UILIB.Column xs={12} sm={4} className="col-sm-offset-2">
                    <img className="img-corners" src={scalabilityImage} width="100%" alt={i18n.t('campaigns:transactions.explainerDeliverability')} />
                </UILIB.Column>
                <UILIB.Column xs={12} sm={4}>
                    <h4 className="pad-left-1 m-pad-0 mar-b25">{i18n.t('campaigns:transactions.explainerDeliverability')}</h4>
                    <p className="pad-left-1 m-pad-0 mar-b25">{i18n.t('campaigns:transactions.explainerDeliverabilityDescription')}</p>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row className="mar-b60">
                <UILIB.Column xs={12} sm={8} className="col-sm-offset-2">
                    <h4 className="mar-b25">{i18n.t('campaigns:transactions.explainerHowToSetup')}</h4>
                    <p className="mar-b25">{explainerGetStartedLink}</p>
                </UILIB.Column>
            </UILIB.Row>

        </div>

    }
}

export default TransactionsWelcome;
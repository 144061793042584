import React from 'react'

export default class IconsUndo extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.78055 16.7615C11.2912 17.1663 12.8939 17.0535 14.3329 16.4412C15.772 15.8289 16.9646 14.7523 17.7204 13.3832C18.4763 12.014 18.7519 10.4312 18.5033 8.88716C18.2546 7.34313 17.4961 5.92682 16.3487 4.86416C15.8514 4.40366 15.2935 4.02106 14.6941 3.72478C14.6669 3.70951 14.639 3.6955 14.6103 3.68281C13.6665 3.23236 12.6355 2.99983 11.5933 3C10.2317 2.99978 8.89477 3.39695 7.74962 4.14903C6.4424 5.00756 5.46034 6.2791 4.96008 7.76086C4.7071 8.51016 4.58469 9.29139 4.59265 10.0714L3.10713 8.58589C2.91959 8.39835 2.66524 8.29299 2.40002 8.29299C2.13481 8.29299 1.88045 8.39835 1.69292 8.58589C1.50538 8.77342 1.40002 9.02778 1.40002 9.29299C1.40002 9.55821 1.50538 9.81257 1.69292 10.0001L5.22845 13.5356C5.41599 13.7232 5.67034 13.8285 5.93556 13.8285C6.20077 13.8285 6.45513 13.7232 6.64266 13.5356L10.1782 10.0001C10.3657 9.81257 10.4711 9.55821 10.4711 9.29299C10.4711 9.02778 10.3657 8.77342 10.1782 8.58589C9.99066 8.39835 9.73631 8.29299 9.47109 8.29299C9.20588 8.29299 8.95152 8.39835 8.76399 8.58589L6.64467 10.7052C6.53499 9.93566 6.60572 9.14641 6.85725 8.40138C7.21442 7.34349 7.91555 6.43567 8.84883 5.82274C9.78211 5.2098 10.8938 4.92705 12.0065 5.01959C13.1192 5.11212 14.1689 5.57462 14.9881 6.3333C15.8073 7.09198 16.3488 8.10314 16.5263 9.2055C16.7038 10.3079 16.5071 11.4379 15.9675 12.4154C15.4278 13.3929 14.5764 14.1615 13.549 14.5987C12.5215 15.0358 11.3773 15.1163 10.2988 14.8273L10.2981 14.8296C9.76467 14.6867 9.21633 15.0033 9.07339 15.5367C8.93045 16.0702 9.24709 16.6185 9.78055 16.7615Z" fill={color} />
        </svg>






    }
}        
import axios from '~/data/http/axios';
import React from 'react'
import UILIB from '../Lib';
import i18n from '~/i18n'

export default class AddTagHolder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newTagName: "",
            newTagNameErr: "",
            destroyingTag: false,
            destroyingTagData: {},
            loadingTags: true,
            existingTags: [],
            creatingTag: false,
            updatingTag: false,
            tagSearch: ""
        }

        this.getTags = this.getTags.bind(this);
        this.destroyTag = this.destroyTag.bind(this);
        this.destroyTagGo = this.destroyTagGo.bind(this);
        this.destroyTagCancel = this.destroyTagCancel.bind(this);
        this.createTag = this.createTag.bind(this);
        this.selectTag = this.selectTag.bind(this);

        this.container = React.createRef();
    }

    componentDidUpdate(prevProps, newState) {
        if (this.container.current) {
            const elementRect = this.container.current.getBoundingClientRect();
            const isOffRight = elementRect.right > window.innerWidth;
            if (isOffRight) {
                this.container.current.style.right = (elementRect.right - window.innerWidth) + 'px';
            }
        }
        if (prevProps.tagsSelected != this.props.tagsSelected) {
            this.setState({ updatingTag: 0 })
        }
        if (!prevProps.open && this.props.open) {
            this.getTags()
        }
    }
    getTags() {
        axios.get(`/tags?tagType=${this.props.tagType}`).then(tags => {
            let existing = tags.data;
            this.setState({ existingTags: existing, loadingTags: false })
        })

    }
    destroyTag(tag, event) {
        event.stopPropagation();
        this.setState({ destroyingTag: true, destroyingTagData: tag });
    }
    destroyTagGo() {
        this.setState({ destroyingTag: false, destroyingTagData: {} });
        axios.delete(`/tags/${this.state.destroyingTagData.id}`).then(() => {
            this.getTags();
        }).catch(() => {
            this.getTags();
        })
    }
    destroyTagCancel() {
        this.setState({ destroyingTag: false, destroyingTagData: {} })
    }
    createTag() {
        this.setState({ newTagNameErr: "" })
        if (!this.state.newTagName || this.state.newTagName.length < 3) {
            this.setState({ newTagNameErr: i18n.t('subscribers:tags.enterLongerTagName') });
            return;
        }
        this.setState({ creatingTag: true })
        axios.post(`/tags`, { tagType: this.props.tagType, tagName: this.state.newTagName }).then(tags => {
            let existing = tags.data;
            this.setState({ creatingTag: false, newTagName: "", existingTags: existing })
        }).catch(err => {
            let error = i18n.t('subscribers:tags.errorCreatingTag');
            if (err && err.data && err.data.error) error = err.data.error;
            this.setState({ newTagNameErr: error, creatingTag: false })
        })
    }
    selectTag(tag) {
        this.setState({ updatingTag: tag.id })
        let newTags = JSON.parse(JSON.stringify(this.props.tagsSelected));
        newTags.push(tag);
        this.props.tagsChanged(newTags)

    }

    render() {
        if (this.state.loadingTags) return <div></div>
        let existingTags = JSON.parse(JSON.stringify(this.state.existingTags));
        existingTags = existingTags.filter(tag => !this.props.tagsSelected.find(tg => tg.id === tag.id))
        let existingTagsCount = existingTags.length;
        if (this.state.tagSearch && this.state.tagSearch.length > 0) {
            existingTags = existingTags.filter(tag => tag.tagName.toLowerCase().indexOf(this.state.tagSearch.toLowerCase()) > -1)
        }
        let classes = "tagsAddHolder"
        if (this.props.open) classes += ' tagsAddHolderOpen'

        return <UILIB.ClickAway onClickAway={this.props.stopAddingTag} enabled={true}>
            <div className={classes} ref={this.container}>
                <div className="tagsHolderClose " onClick={this.props.stopAddingTag}>
                    <UILIB.Icons icon="cross" color="#666666" style={{ height: 18, width: 18 }} />
                </div>
                {this.props.loadingTags == true && <UILIB.LoadingIcons iconType="2" />}
                {this.state.destroyingTag == true && <div>
                    <h4 className="mar-b25">Delete a tag</h4>
                    <p className="text-sml">
                        {i18n.t('subscribers:tags.areYouSureDelete')}
                        <br />
                        {i18n.t('subscribers:tags.areYouSureDeleteWarning')}
                    </p>
                    <div>
                        <UILIB.Button text={i18n.t('subscribers:tags.deleteIt')} className="button-sml red mar-r5" onClick={this.destroyTagGo} />
                        <UILIB.Button text={i18n.t('subscribers:tags.forgetIt')} className="button-sml" onClick={this.destroyTagCancel} />
                    </div>
                </div>}
                {this.state.destroyingTag == false && this.state.loadingTags == false && <div>
                    <div className="mar-b20">
                        <div className='quickFlex' style={{ alignItems: "flex-start" }}>
                            <div style={{ flexGrow: 1, marginRight: 8 }}>
                                <UILIB.TextInput type="text" placeholder={i18n.t('subscribers:tags.addANewTag')}
                                    onChange={(ev) => { this.setState({ newTagName: ev.target.value }) }}
                                    onKeyPress={(ev) => { if (ev.key == 'Enter') this.createTag() }}
                                    value={this.state.newTagName} error={this.state.newTagNameErr} disabled={this.state.creatingTag} />
                            </div>
                            <UILIB.Button text={i18n.t('subscribers:tags.addTag')} className="button-primary" onClick={this.createTag} />
                        </div>
                    </div>
                    <hr />
                    {existingTagsCount > 0 && <div>
                        <div style={{ fontSize: 14, fontWeight: 600, lineHeight: '16px' }} className="mar-b10">{i18n.t('subscribers:tags.selectExistingTag')}</div>

                        <UILIB.TextInput
                            iconLeft={<UILIB.Icons icon={"magnifier"} />}
                            outerClassName="mar-b10"
                            placeholder="Search tags"
                            value={this.state.tagSearch}
                            onChange={(ev) => { this.setState({ tagSearch: ev.target.value }) }}
                        />
                        <div className="tagsInnerHolder">
                            {existingTags.map((tag, index) => {
                                return <UILIB.SquareChip
                                    onClick={() => this.selectTag(tag)} className="square-chip-tag" key={tag.id}
                                    iconRight={
                                        <UILIB.Icons icon="bin" onClick={e => { if (!this.state.updatingTag) this.destroyTag(tag, e) }} style={{ height: 12, width: 12 }} />
                                    }
                                >{tag.tagName}</UILIB.SquareChip>
                            })}
                        </div>
                    </div>}
                    {existingTagsCount <= 0 && <div className="mar-t30">
                        <div className="text-center text-sml text-heavy">{i18n.t('subscribers:tags.noTagsLeftToAdd')}</div>
                        <div className="text-center text-sml">{i18n.t('subscribers:tags.addNewTagUsingOptions')}</div>
                    </div>}
                </div>}
            </div>
        </UILIB.ClickAway>
    }

}

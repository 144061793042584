import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions';
import { connect } from 'react-redux';
import i18n from '~/i18n'


//CONNECT TO STORE
@connect((store) => {
	return { user: store.user, accountMaster: store.accountMaster }
})

export default class CampaignsViewSent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			visitors: [],
			count: 0,
			page: 0,
			pageSize: 20,
			orderBy: 'lastVisit',
			orderDir: 'DESC',
			campaignId: -1,
			pageLoaded: false
		}
		this.getVisitors = this.getVisitors.bind(this)
		this.tableUpdater = this.tableUpdater.bind(this)
	}

	componentDidMount() {

		this.setState({ campaignId: this.props.match.params.campaignId, pageLoaded: true }, this.getVisitors);
	}

	tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {
		if (!currentPage) currentPage = this.state.page;
		if (!sortDirection) sortDirection = this.state.orderDir;
		if (!sortColumn) sortColumn = this.state.orderBy;

		this.setState({
			page: currentPage,
			orderDir: sortDirection,
			orderBy: sortColumn
		}, this.getVisitors)
	}

	getVisitors() {
		var queryStr = `?campaignId=${this.props.match.params.campaignId}&orderBy=${this.state.orderBy}&orderDir=${this.state.orderDir}&limit=${this.state.pageSize}&offset=${this.state.pageSize * this.state.page}`
		if (this.props.startDate && this.props.endDate) queryStr += '&startDate=' + this.props.startDate.format('YYYY-MM-DD') + '&endDate=' + this.props.endDate.format('YYYY-MM-DD')
		axios.get('/accountMaster/tracker/campaignvisitors' + queryStr).then(response => {


			this.setState({
				visitors: response.data.TrackerLogs.map(row => {
					var deviceTxt = <span>
						<span className={row.device == "desktop" ? "icon-screen" : row.device == "phone" ? "icon-phone" : row.device == "bot" ? "icon-surveillance" : "icon-question-circle"} alt={row.device} title={row.device} />
						{(row.closeCallCount && row.closeCallCount > 1) && <span className="mar-l5 icon-warning" style={{ color: "red" }} alt="Maybe Robot - Lots of clicks at same time" title="Maybe Robot - Lots of clicks at same time" />}
					</span>
					return {
						lastVisit: {
							headerValue: i18n.t('campaigns:sitetracking.trackingvistors.lastVisit'),
							value: DateTimeFunctions.formatDateTime(row.lastVisit),
							orderBy: true
						},
						subscriberId: {
							headerValue: i18n.t('campaigns:sitetracking.trackingvistors.subscriber'),
							value: row.SubscriberId ? <a onClick={() => this.props.history.push('/cp/subscriber/' + row.SubscriberId)} style={{ fontWeight: "600" }}>{row.emailAddress}</a> : i18n.t('campaigns:sitetracking.trackingvistors.anonymous'),
							orderBy: true
						},
						device: {
							headerValue: i18n.t('campaigns:sitetracking.trackingvistors.device'),
							value: deviceTxt,
							align: "center"
						},
						country: {
							headerValue: i18n.t('campaigns:sitetracking.trackingvistors.country'),
							value: row.country
						},
						visits: {
							headerValue: i18n.t('campaigns:sitetracking.trackingvistors.visits'),
							value: NumberFunctions.formatNumber(row.visits),
							orderBy: true
						},

						pageCount: {
							headerValue: i18n.t('campaigns:sitetracking.trackingvistors.impressions'),
							value: NumberFunctions.formatNumber(row.pageCount),
							orderBy: true
						},
						userAgent: {
							headerValue: i18n.t('campaigns:sitetracking.trackingvistors.browser'),
							value: row.userAgent,
							orderBy: false
						},

						recentPath: {
							headerValue: i18n.t('campaigns:sitetracking.trackingvistors.lastUrl'),
							value: row.recentPath
						}
					}
				}),
				// return {
				// 		lastVisit: {
				// 			headerValue: i18n.t('campaigns:sitetracking.campaignTrackingView.lastVisit'),
				// 			value: DateTimeFunctions.formatDateTime(row.lastVisit),
				// 			orderBy: true
				// 		},
				// 		subscriberId: {
				// 			headerValue: i18n.t('campaigns:sitetracking.campaignTrackingView.subscriber'),
				// 			value: row.SubscriberId ? <a onClick={() => this.props.history.push('/cp/subscriber/' + row.SubscriberId)} style={{ fontWeight: "bold" }}>{row.emailAddress}</a> : i18n.t('campaigns:sitetracking.campaignTrackingView.anonymous'),
				// 			orderBy: true
				// 		},
				// 		device: {
				// 			headerValue: i18n.t('campaigns:sitetracking.campaignTrackingView.device'),
				// 			value: row.device
				// 		},
				// 		country: {
				// 			headerValue: i18n.t('campaigns:sitetracking.campaignTrackingView.country'),
				// 			value: row.country
				// 		},
				// 		visits: {
				// 			headerValue: i18n.t('campaigns:sitetracking.campaignTrackingView.visits'),
				// 			value: NumberFunctions.formatNumber(row.visits),
				// 			orderBy: true,
				// 			align: "center"
				// 		},
				// 		host: {
				// 			headerValue: i18n.t('campaigns:sitetracking.campaignTrackingView.host'),
				// 			value: <a onClick={() => { this.props.history.push('/cp/trackers/' + row.SiteTrackerId) }}>{row.host}</a>,
				// 			orderBy: false
				// 		},
				// 		recentPath: {
				// 			headerValue: i18n.t('campaigns:sitetracking.campaignTrackingView.lastUrl'),
				// 			value: row.recentPath
				// 		}
				// 	}
				// }),
				count: response.data.count,
				loading: false
			})
		})
	}


	render() {

		if (!this.state.pageLoaded) return <div></div>


		return <div>

			<div className="mar-b25">{i18n.t('campaigns:sitetracking.campaignTrackingView.introText')} <a onClick={() => { this.props.history.push('/cp/trackers/') }}>{i18n.t('campaigns:sitetracking.campaignTrackingView.introTextLink')}</a></div>

			<UILIB.Paper isLoading={this.state.loading}>
				<UILIB.DataTable1
					noResultsTxt={i18n.t('campaigns:sitetracking.campaignTrackingView.noDataFound')}
					tableData={this.state.visitors}
					dataUpdater={this.tableUpdater}
					loadingData={false}
					width="100%"
					pageSize="100"
					hasCheckBoxes="no"
					sortedColumn={this.state.orderBy}
					sortedDirection={this.state.orderDir}
				/>
			</UILIB.Paper>
			<UILIB.Row>
				<UILIB.Column xs={12} className="center-xs">
					<UILIB.PagingBlock totalRows={this.state.count} pageSize={this.state.pageSize} numberOfLinks="10" currentPage={this.state.page}
						changePage={this.tableUpdater} text={i18n.t('page') + ":"} />
				</UILIB.Column>
			</UILIB.Row>


		</div>
	}
}
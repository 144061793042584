import React, { useEffect, useState } from 'react'
import axios from '../../../../data/http/axios'
import UILIB from '../../../../Common-Objects/Lib'

export default function TagLinks({ tag, onChange = () => { } }) {
    const [links, setLinks] = useState(null)
    const [adding, setAdding] = useState(false)
    const [addingLink, setAddingLink] = useState('')
    const [saving, setSaving] = useState(false)
    const [deleting, setDeleting] = useState({})

    const getLinks = async () => {
        setLinks((await axios.get('/tags/linktag/' + tag.id)).data)
    }

    const remove = async (link) => {
        setDeleting(d => ({ ...d, [link.id]: true }))
        await axios.delete('/tags/linktag/' + link.id)
        getLinks()
        onChange()
    }

    const save = async () => {
        let url = addingLink
        if (url.indexOf('http') !== 0 && url.indexOf('mailto:') !== 0 && url.indexOf('tel:') !== 0) url = 'https://' + url
        setSaving(true)
        const res = await axios.post('/tags/linktag', {
            TagId: tag.id,
            link: url
        })
        setSaving(false)
        setAdding(false)
        setAddingLink('')
        setLinks([...links, res.data])
        onChange()
    }

    useEffect(() => {
        getLinks()
    }, [tag.id])

    if (!links) return <UILIB.LoadingIcons />

    return (
        <div>
            <h4 className="mar-b25">{tag.tagName} Links</h4>
            {!links.length && <UILIB.Paper className="paper-lightGrey paper-tight text-center">
                No Links Assigned
            </UILIB.Paper>}
            <div className='quickFlex mar-b10'>
                <UILIB.TextInput focus type="url" outerStyle={{ flexGrow: 1 }} value={addingLink} onChange={e => setAddingLink(e.currentTarget.value)} placeholder="https://example.com" onEnterPress={save} />
                <UILIB.Button className="mar-l5 button-md button-primary" disabled={saving || !addingLink.trim()} onClick={save} iconLeft={<UILIB.Icons icon="plus" />}>Add</UILIB.Button>
            </div>

            {links.map((link, index) => {
                const style = { alignItems: 'center', padding: '5px 0' }
                if (index) style.borderTop = '2px solid #DFDBD9'
                return <div className="quickFlex" style={style} key={link.id}>
                    <div style={{ flexGrow: 1 }} className="text-heavy">{link.link}</div>
                    <UILIB.Button className="mar-l5 button-sml button-red" style={{ padding: '2px 12px' }} disabled={deleting[link.id]} onClick={() => remove(link)}>Remove</UILIB.Button>
                </div>
            })}

            {!!links.length && <div className="txtInput_explainer">If you remove a link it will remove this tag from contacts who have clicked this link</div>}
        </div>
    )

}
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import ArrowDown from '~/assets/images/icons/nav-arrow-down.svg';

class HubspotFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availableFields: [],
            ready: false,
            filter: ''
        }
        this.getAvailableFields = this.getAvailableFields.bind(this)
        this.toggleField = this.toggleField.bind(this)
        this.toggleAllFields = this.toggleAllFields.bind(this)
        this.filterFields = this.filterFields.bind(this)
    }

    componentDidMount() {
        this.getAvailableFields()
    }

    getAvailableFields() {
        axios.get('/application/hubspot/availablefields').then(response => {
            this.setState({
                availableFields: response.data,
                ready: true
            })
        })
    }

    toggleField(ev) {
        if (!this.props.appBinding.appSettings) this.props.appBinding.appSettings = {};
        var settings = this.props.appBinding.appSettings;
        if (!settings.property) settings.property = [];
        if (ev.currentTarget.checked) {
            settings.property.push(ev.currentTarget.name)
        } else {
            var index = settings.property.indexOf(ev.currentTarget.name)
            if (index > -1) {
                settings.property.splice(index, 1)
            }
        }
        this.setState({})
    }

    toggleAllFields(on) {
        if (!this.props.appBinding.appSettings) this.props.appBinding.appSettings = {};
        var settings = this.props.appBinding.appSettings;
        if (!settings.property) settings.property = [];
        if (on) {
            settings.property = this.state.availableFields.map(f => f.value)
        } else {
            settings.property = []
        }
        this.setState({})
    }

    filterFields(val) {
        this.setState({
            filter: val
        })
    }

    render() {
        if (!this.state.ready) return <UILIB.LoadingIcons iconType="2" />

        var availableFields = this.state.availableFields.slice()
        if (this.state.filter) {
            availableFields = availableFields.filter(f => f.label.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1 || f.value.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1)
        }

        availableFields = availableFields.sort((a, b) => {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })

        return (
            <div>
                <UILIB.ButtonSelect width={450} className="with-icon white mar-b10" icon={ArrowDown} headerText="Select Fields" filter={true} updateFilter={this.filterFields} selectAll={() => this.toggleAllFields(true)} clearAll={() => this.toggleAllFields(false)} >
                    {availableFields && !!availableFields.length && <table width="100%" cellpadding="0" cellspacing="0">
                        {availableFields.map((field) => {
                            var selected = false
                            if (this.props.appBinding.appSettings && this.props.appBinding.appSettings.property) {
                                selected = this.props.appBinding.appSettings.property.some(p => p === field.value)
                            }
                            return <tr key={field.value}>
                                <td width="10" style={{ verticalAlign: "top" }}>
                                    <UILIB.CheckBox key={field.value} name={field.value} onChange={this.toggleField} checked={selected} />
                                </td>
                                <td className="pad-left" style={{ verticalAlign: "top" }}>
                                    <div>{field.label}</div>
                                    {field.value !== field.label ? <div className="text-xsml">{field.value}</div> : ""}
                                </td>
                            </tr>
                        })}
                    </table>}
                </UILIB.ButtonSelect>
                {!!this.props.appBinding.appSettings && !!this.props.appBinding.appSettings.property && <div>
                    {this.props.appBinding.appSettings.property.map(p => {
                        var prop = this.state.availableFields.find(property => property.value === p)
                        if (!prop) return ''
                        return <span key={prop.value}><UILIB.SquareChip className="square-chip-primary" tooltip={prop.value} value={prop.label} for={prop.value} hasButton={true} iconRight={<UILIB.Icons icon="crossCircle" style={{ height: 14, width: 14 }} />} onClick={() => this.toggleField({
                            currentTarget: {
                                checked: false,
                                name: prop.value
                            }
                        })} /></span>
                    })}
                </div>}
            </div>
        );
    }
}

export default HubspotFields;
import React, { Component, Fragment } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import ComponentText from './components/text'
import ComponentWysiwyg from './components/wysiwyg'
import ComponentSelect from './components/select'
import ColorPicker from './components/colorPicker'
import HorizontalAlign from './components/horizontalAlign'
import VerticalAlign from './components/verticalAlign'
import InputTool from './components/inputTool'
import ImagePicker from './components/imagePicker'
import Breaker from './components/breaker'
import Expand from './components/expand'
import Video from './components/video'
import VimeoVideo from './components/vimeoVideo'
import DailyMotionVideo from './components/dailyMotionVideo'
import CustomField from './components/customField'
import DragDropFunctions from '../../dragDropFunctions';
import HTMLBlock from './components/htmlBlock'
import ArraySelect from './components/arraySelect';
import ClusterHolder from './components/clusterHolder';
import UILIB from '~/Common-Objects/Lib'
import CodeEditor from './components/codeEditor';
import PollPicker from './components/pollPicker';
import GoogleMaps from './components/googleMaps'
import RssEditor from './components/rssEditor';
import TagManager from './components/tagManager';
import Explainer from './components/explainer';
// import ImageEditor from './components/imageEditor';
import i18n from '~/i18n';

export default class ElementEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentTab: null,
            thisElement: [],
            loaded: false,
            externalDataError: false
        }
        this.updateVal = this.updateVal.bind(this);
        this.renderCatGroup = this.renderCatGroup.bind(this);
        this.init = this.init.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.init(nextProps, false)
    }

    async init(theProps, isNext) {


        var selectedElement = theProps.selectedElement;

        var currentTab;
        var thisElement;

        if (selectedElement.path && selectedElement.path != "" && selectedElement.path != null && selectedElement.path != undefined) {
            thisElement = cloneDeep(get(theProps.elementsData, selectedElement.path));
        }
        else {
            thisElement = cloneDeep(theProps.elementsData.globalVariables);
        }
        if (thisElement && thisElement.editableTabs) {
            currentTab = thisElement.editableTabs[0];
        }

        if (theProps.selectedElement.path == this.props.selectedElement.path && this.state.currentTab) currentTab = this.state.currentTab;

        let dataError = false;
        if (thisElement && thisElement.localVariables) {
            let newLocalVars = await DragDropFunctions.checkForExternalData(thisElement.localVariables)
            if (newLocalVars?.errors?.length) {
                dataError = true;
            }
            if (newLocalVars && newLocalVars.localVars) {
                thisElement.localVariables = newLocalVars.localVars;
            }
        }
        this.setState({ currentTab: currentTab, thisElement: thisElement, loaded: true, externalDataError: dataError });
    }

    async updateVal(thePath, theVal, property, otherVals) {


        var theElementsData = cloneDeep(this.props.elementsData);
        var selectedElement = this.props.selectedElement;
        var thisProp = get(this.state.thisElement, thePath);
        var thePathFinal = "";

        //if we are updating this element, or a global variable
        if (selectedElement.path && selectedElement.path != "" && selectedElement.path != null && selectedElement.path != undefined) {
            thePathFinal = selectedElement.path + thePath;
            set(theElementsData, thePathFinal, thisProp);
            set(theElementsData, thePathFinal + ".contentDate", moment());
        }
        else {
            //html/body/global variables
            thePathFinal = "globalVariables." + thePath;
            theElementsData.elements[0].contentDate = moment();
        }

        var finalVal = "";
        if (typeof theVal == "object" && theVal.value) {
            finalVal = theVal.value;
        }
        else if (typeof theVal == "boolean") {
            finalVal = String(theVal.value);
        }
        else {
            finalVal = theVal;
        }

        //if it is updating another property other than value
        if (property) {
            thisProp[property] = finalVal;
        }
        else {
            thisProp.value = finalVal;
        }


        var promises = [];
        //if the element has an onchange parameter, go through it.
        if (thisProp.onChange) {
            if (thisProp.onChange.update) {
                var toChange = thisProp.onChange.update;
                if (Array.isArray(toChange)) {
                    for (var each in toChange) {
                        var thisPath = this.props.selectedElement.path + "." + toChange[each].path;
                        var valueType = toChange[each].valueType;
                        if (typeof theVal == "object") {
                            var ourVal = String(theVal[valueType]);
                            if (valueType == "required" && ourVal == "false") ourVal = "";
                            if (valueType == "required" && ourVal == "true") ourVal = "required";
                            set(theElementsData, thisPath, ourVal);
                        }
                        else {
                            set(theElementsData, thisPath, theVal);
                        }
                    }
                }
            }
            if (thisProp.onChange.getData && this.state.thisElement && this.state.thisElement.localVariables) {
                //reloads all external data in the app
                promises.push(DragDropFunctions.checkForExternalData(this.state.thisElement.localVariables));
            }
        }
        Promise.all(promises).then(_newLocalVariables => {
            if (_newLocalVariables && _newLocalVariables.length) {
                if (_newLocalVariables[0].updated) {

                    set(theElementsData, this.props.selectedElement.path + ".localVariables", _newLocalVariables[0].localVars)
                }
            }
            set(theElementsData, thePathFinal, thisProp);
            this.props.updateElementsData(theElementsData, this.props.selectedElement.path);
            return true
        })
    }

    changeTab(tab) {
        this.setState({ currentTab: tab });
    }


    renderCatGroup(editableOptions, globalColours, currentCatGroup) {
        var self = this;

        function getComponentType(theOpt, index, globalColours, currentCatGroup) {
            var compType = theOpt.editableType;
            var theVal = theOpt.value;
            var thePath = theOpt.path;
            var theLabel = theOpt.label;
            var htmlText = theOpt.HTMLText
            var theUnit = "";
            var options = {};
            var editableDirection = "top";
            var editableUnits;
            var localVariables = {};
            var lineOut = "";


            if (self.state.thisElement.localVariables) {
                localVariables = self.state.thisElement.localVariables;
            }
            if (theOpt.editableUnit) {
                theUnit = theOpt.editableUnit;
            }

            if (theOpt.editableDirection) {
                editableDirection = theOpt.editableDirection;
            }
            if (theOpt.editableUnits) {
                editableUnits = theOpt.editableUnits;
            }

            var requiredErr = false;
            if (theOpt.valueBlankWillError && !theVal) {
                requiredErr = true;
            }

            if (typeof theVal != "string" && typeof theVal != "object") {
                theVal = String(theVal);
            }
            if (typeof (theVal) != "object" && theVal.indexOf('global_') > 0) {
                if (self.props.elementsData.globalVariables[theVal.split("_.")[1]]) {
                    theVal = self.props.elementsData.globalVariables[theVal.split("_.")[1]].value;
                }
            }


            if (typeof (theVal) != "object" && theVal.indexOf('_localVariables_') >= 0) {
                theVal = DragDropFunctions.lookupLocalVariables(localVariables, theVal)
            }

            if (theOpt.options) {
                options = theOpt.options;
                if (options.indexOf('_localVariables_') >= 0) {
                    options = DragDropFunctions.lookupLocalVariables(localVariables, options)
                }

            }
            switch (compType) {
                case "Text":
                    lineOut = <ComponentText campaign={self.props.campaign} error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} path={thePath} elementsData={self.props.elementsData} elementData={theOpt} selectedElement={self.props.selectedElement} localVariables={localVariables} />;
                    break;
                case "Wysiwyg":
                    lineOut = <ComponentWysiwyg selectedElement={self.props.selectedElement} landingPage={self.props.landingPage} isLandingPage={self.props.isLandingPage} campaign={self.props.campaign} error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} path={thePath} globalColours={globalColours} favoriteColors={self.props.elementsData.globalVariables.favoriteColors} saveFaveColor={self.props.saveFaveColor} elementData={theOpt} elementsData={self.props.elementsData} localVariables={localVariables} />;
                    break;
                case "Select":
                    lineOut = <ComponentSelect error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} options={options} updateVal={self.updateVal} path={thePath} elementData={theOpt} localVariables={localVariables} />;
                    break;
                case "ColorPicker":
                    lineOut = <ColorPicker error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} path={thePath} globalColours={globalColours} favoriteColors={self.props.elementsData.globalVariables.favoriteColors} saveFaveColor={self.props.saveFaveColor} elementData={theOpt} localVariables={localVariables} />;
                    break;
                case "UnitInput":
                    lineOut = <InputTool error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} path={thePath} unit={theUnit} className={editableDirection} editableUnits={editableUnits} elementData={theOpt} localVariables={localVariables} />;
                    break;
                case "HorizontalAlign":
                    lineOut = <HorizontalAlign error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={options} path={thePath} elementData={theOpt} localVariables={localVariables} />;
                    break;
                case "VerticalAlign":
                    lineOut = <VerticalAlign error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={options} path={thePath} elementData={theOpt} localVariables={localVariables} />;
                    break;
                case "ImagePicker":
                    lineOut = <ImagePicker error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={options} path={thePath} elementData={theOpt} localVariables={localVariables} globalVariables={self.props.elementsData.globalVariables} />;
                    break;
                case "Video":
                    lineOut = <Video error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={options} path={thePath} elementData={theOpt} templateId={self.props.templateId} localVariables={localVariables} />;
                    break;
                case "VimeoVideo":
                    lineOut = <VimeoVideo error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={options} path={thePath} elementData={theOpt} localVariables={localVariables} />;
                    break;
                case "DailyMotionVideo":
                    lineOut = <DailyMotionVideo error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={options} path={thePath} elementData={theOpt} localVariables={localVariables} />;
                    break;
                case "GoogleMaps":
                    lineOut = <GoogleMaps error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={options} path={thePath} elementData={theOpt} localVariables={localVariables} />;
                    break;
                case "CustomField":
                    lineOut = <CustomField error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={DragDropFunctions.customFields} path={thePath} elementsData={self.props.elementsData} elementData={theOpt} localVariables={localVariables} />;
                    break;
                case "HTMLBlock": //Use this to insert dangerously set HTML anywhere you like :D
                    lineOut = <HTMLBlock key={"Comp_" + index} value={htmlText} elementData={theOpt} localVariables={localVariables} />;
                    break;
                case "ArraySelect": //Use this to allow people to build array values
                    lineOut = <ArraySelect key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} path={thePath} elementData={theOpt} localVariables={localVariables} />;
                    break;
                case "html": //use this for code editor 
                    lineOut = <CodeEditor campaign={self.props.campaign} error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={DragDropFunctions.customFields} path={thePath} elementsData={self.props.elementsData} elementData={theOpt} localVariables={localVariables} />
                    break;
                case "PollPicker":
                    lineOut = <PollPicker error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={DragDropFunctions.customFields} path={thePath} elementsData={self.props.elementsData} elementData={theOpt} localVariables={localVariables} deselectElement={self.props.deselectElement} />
                    break;
                case "RssEditor":
                    lineOut = <RssEditor error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={DragDropFunctions.customFields} path={thePath} elementsData={self.props.elementsData} elementData={theOpt} localVariables={localVariables} deselectElement={self.props.deselectElement} />
                    break;
                case "TagManager":
                    lineOut = <TagManager error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={DragDropFunctions.customFields} path={thePath} elementsData={self.props.elementsData} elementData={theOpt} localVariables={localVariables} deselectElement={self.props.deselectElement} />
                    break;
                case "Explainer":
                    lineOut = <Explainer value={theVal} elementData={theOpt} />
                    break;
                // case "ImageEditor":
                //     lineOut = <ImageEditor error={requiredErr} key={"Comp_" + index} value={theVal} title={theLabel} updateVal={self.updateVal} options={DragDropFunctions.customFields} path={thePath} elementsData={self.props.elementsData} elementData={theOpt} localVariables={localVariables} deselectElement={self.props.deselectElement} />
                //     break;
            }

            return lineOut;
        }

        var foundClusters = [];
        var currentCat = "";
        var foundOptsInCat = 0;

        var linesOut = editableOptions.map((theOpt, index) => {
            var lineOut = [];
            var theCat = "";
            if (theOpt.category) {
                theCat = theOpt.category;
            }

            if (theCat != currentCat) {
                if (foundOptsInCat > 0) {
                    lineOut.push(<Breaker cat={theCat} />)
                }
                currentCat = theCat;
            }

            if (!theOpt.categoryGroup && currentCatGroup != null) {
                return;
            }

            if (theOpt.categoryGroup && theOpt.categoryGroup != currentCatGroup) { return }
            if (theOpt.editableTab === this.state.currentTab) {
                var foundInCluster = foundClusters.find(c => c == theOpt.cluster);
                if (theOpt.cluster && !foundInCluster) {
                    foundClusters.push(theOpt.cluster);
                    foundInCluster = true;
                    var clusterMembers = editableOptions.filter(e => e.cluster == theOpt.cluster);
                    if (clusterMembers) {
                        clusterMembers = clusterMembers.map((c, sIndex) => {
                            return getComponentType(c, sIndex, globalColours, currentCatGroup)
                        });
                    }
                    var cStyle = {};
                    // "cluster": "border1",
                    // "clusterType": "border",
                    // "clusterCssType": "borderStyle"

                    switch (theOpt.clusterType) {
                        case "border":
                            var borderWidth = "0px";
                            var borderColor = "#000000";
                            var borderStyle = "solid";

                            var cBorderWidth = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "borderWidth")
                            if (cBorderWidth && cBorderWidth.value) borderWidth = cBorderWidth.value;
                            var cBorderStyle = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "borderStyle")
                            if (cBorderStyle && cBorderStyle.value) borderStyle = cBorderStyle.value;
                            var cBorderColor = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "borderColor")
                            if (cBorderColor && cBorderColor.value) borderColor = cBorderColor.value;

                            cStyle.borderTop = borderWidth + " " + borderStyle + " " + borderColor;
                            cStyle.width = "100%";
                            cStyle.position = "absolute";
                            cStyle.top = "50%";
                            cStyle.transform = "translateY(-50%)";

                            var html = <div style={cStyle} />;
                            lineOut.push(<ClusterHolder clusterMembers={clusterMembers} title={theOpt.clusterLabel} html={html} index={index} />)
                            break;
                        case "font":
                            var fontSize = "14px";
                            var fontFamily = "arial";
                            var fontColor = "#000000";

                            var cfontColor = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "color")
                            if (cfontColor && cfontColor.value) fontColor = cfontColor.value;
                            var cfontFamily = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "fontFamily")
                            if (cfontFamily && cfontFamily.value) fontFamily = cfontFamily.value;
                            var cfontSize = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "fontSize")
                            if (cfontSize && cfontSize.value) fontSize = cfontSize.value;

                            cStyle.width = "100%";
                            cStyle.position = "absolute";
                            cStyle.top = "50%";
                            cStyle.transform = "translateY(-50%)";
                            cStyle.textAlign = "center";
                            cStyle.fontFamily = fontFamily;
                            cStyle.fontSize = fontSize;
                            cStyle.color = fontColor;

                            var html = <div style={cStyle}>A</div>;
                            lineOut.push(<ClusterHolder clusterMembers={clusterMembers} title={theOpt.clusterLabel} html={html} index={index} />)
                            break;

                        case "socialIcon":
                            var imgWidth = "";
                            var imgUrl = "";

                            var cimgWidth = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "iconSize")
                            if (cimgWidth && cimgWidth.value) imgWidth = cimgWidth.value;
                            var cimgUrl = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "iconType")
                            if (cimgUrl && cimgUrl.value) imgUrl = 'https://cdn1.ourmailsender.com/siteContent/assets/templates/socialMediaIcons/x-' + cimgUrl.value + '.png';

                            cStyle.width = "100%";
                            cStyle.height = "100%";
                            cStyle.backgroundImage = "url(" + imgUrl + ")";
                            cStyle.backgroundSize = imgWidth + " " + imgWidth;
                            cStyle.backgroundPosition = "center center";
                            cStyle.backgroundRepeat = "no-repeat";

                            var html = <div style={cStyle}></div>;
                            lineOut.push(<ClusterHolder clusterMembers={clusterMembers} title={theOpt.clusterLabel} html={html} index={index} />)
                            break;


                        case "colourImage":
                            var bgColor = "";
                            var bgImg = "";

                            var cBgColor = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "backgroundColor")
                            if (cBgColor && cBgColor.value) bgColor = cBgColor.value;
                            var cBgImg = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "backgroundImage")
                            if (cBgImg && cBgImg.value) bgImg = cBgImg.value;

                            cStyle.width = "100%";
                            cStyle.height = "100%";
                            cStyle.backgroundColor = bgColor;
                            if (bgImg) {
                                cStyle.backgroundImage = "url(" + bgImg + ")";
                                cStyle.backgroundSize = "100% 100%";
                                cStyle.backgroundPosition = "center center";
                                cStyle.backgroundRepeat = "no-repeat";
                            }

                            var html = <div style={cStyle}></div>;
                            lineOut.push(<ClusterHolder clusterMembers={clusterMembers} title={theOpt.clusterLabel} html={html} index={index} />)
                            break;

                        case "padding":
                            var imgPaddingLeft = "0px";
                            var imgPaddingLeftVal = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "paddingLeft");
                            if (imgPaddingLeftVal) imgPaddingLeft = Number(imgPaddingLeftVal.value.replace("px", "")) / 4 + "px";
                            imgPaddingLeft += " solid green"

                            var imgPaddingRight = "0px";
                            var imgPaddingRightVal = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "paddingRight");
                            if (imgPaddingRightVal) imgPaddingRight = Number(imgPaddingRightVal.value.replace("px", "")) / 4 + "px";
                            imgPaddingRight += " solid green"

                            var imgPaddingTop = "0px";
                            var imgPaddingTopVal = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "paddingTop");
                            if (imgPaddingTopVal) imgPaddingTop = Number(imgPaddingTopVal.value.replace("px", "")) / 4 + "px";
                            imgPaddingTop += " solid green"

                            var imgPaddingBottom = "0px";
                            var imgPaddingBottomVal = editableOptions.find(e => e.cluster == theOpt.cluster && e.clusterCssType == "paddingBottom");
                            if (imgPaddingBottomVal) imgPaddingBottom = Number(imgPaddingBottomVal.value.replace("px", "")) / 4 + "px";
                            imgPaddingBottom += " solid green"

                            var html = <div style={{ borderLeft: imgPaddingLeft, borderRight: imgPaddingRight, borderTop: imgPaddingTop, borderBottom: imgPaddingBottom, width: "100%", height: "100%" }}></div>;
                            lineOut.push(<ClusterHolder clusterMembers={clusterMembers} title={theOpt.clusterLabel} html={html} index={index} />)
                            break;
                    }

                }

                if (!foundInCluster) {
                    lineOut.push(getComponentType(theOpt, index, globalColours, currentCatGroup))
                }

                foundOptsInCat++;
                return lineOut;
            }
        })

        return linesOut;
    }


    render() {

        if (this.state.loaded === false || !this.state.thisElement) {
            return <UILIB.LoadingIcons iconType="2" />
        }

        var localVariables = {};

        if (this.state.thisElement.localVariables) {
            localVariables = this.state.thisElement.localVariables;
        }

        var editableOptions = [];
        function findElements(thisEl, thePath) {
            if (thisEl.hasOwnProperty("contentDate") && thePath != "") {
                return
            }
            Object.keys(thisEl).map((theEl, index) => {
                var tmpPath = thePath;
                var theLine = thisEl[theEl];
                if (typeof theLine === "object" && theLine != null) {
                    if (isNaN(theEl) == false) {
                        tmpPath += "[" + theEl + "].";
                    }
                    else {
                        tmpPath += theEl + ".";
                    }
                    if (theLine.editable && typeof theLine.editable == "string") {
                        if (theLine.editable.indexOf("localVariables_") > 0) {
                            theLine.editable = DragDropFunctions.lookupLocalVariables(localVariables, theLine.editable)
                        }
                    }
                    if (theLine.editable) {
                        theLine.path = tmpPath.substr(0, tmpPath.length - 1)
                        editableOptions.push(theLine);
                        return;
                    }

                    findElements(theLine, tmpPath);
                }
            })

            return;
        }
        findElements(this.state.thisElement, "");

        var editableTabs = [];
        if (this.state.thisElement && this.state.thisElement.editableTabs) {
            editableTabs = this.state.thisElement.editableTabs;
        }

        editableOptions = sortBy(editableOptions, ["editableOrder", "categoryGroup", "category"]);

        var currentCatGroup = -1;
        var defaultCatGroup = undefined;

        if (this.state.thisElement.defaultCatagoryGroup) {
            defaultCatGroup = this.state.thisElement.defaultCatagoryGroup
        }

        //get global colours
        var globalColours = DragDropFunctions.getTemplateColours(this.props.elementsData.globalVariables);

        var blocksContentStyle = {};
        if (this.props.noTitleBar == true) {
            blocksContentStyle.height = "calc(100vh - 111px)";
        }

        return <Fragment>
            {this.state.thisElement.name && <div className="editorBlockTitle">
                <div>{this.state.thisElement.name}</div>
                {!!this.state.thisElement.warning && <div className='editorBlockTitleWarning'>{this.state.thisElement.warning}</div>}
            </div>}
            {(this.state.externalDataError) && <div className="editorExternalDataError">
                <div className="mar-b15">There has been a problem connecting to {this.state.thisElement.name}.</div>
                {(this.state.thisElement.applicationId) && <>
                    <div className="mar-b20">Please try reconnecting your {this.state.thisElement.name} integration.</div>
                    <UILIB.Button text={`Reconnect to ${this.state.thisElement.name}`}
                        onClick={() => {
                            window.open('/cp/company/integrations?show=' + this.state.thisElement.applicationId, '_blank');
                        }}
                    />
                </>}
                {(!this.state.thisElement.applicationId) && <>
                    <div>Please try again later.</div>
                </>}

            </div>}
            {(!this.state.externalDataError) && <>
                <div className="editorTabs">

                    {editableTabs.map((tab, index) => {
                        var thisClasses = "editorTab";
                        if (this.state.currentTab === tab) {
                            thisClasses += " selected ";
                        }
                        return <div className={thisClasses} key={"tab_" + index} onClick={() => { this.changeTab(tab) }} >{i18n.t('templates:add.drag.tabs.' + tab)}</div>
                    })}

                </div>

                <div className="blocksContent" style={blocksContentStyle}>
                    {this.state.externalDataError && <div className="externalDataError">
                    </div>}
                    {(!this.state.externalDataError) && editableOptions.map((theOpt, index) => {
                        if (theOpt.editableTab == this.state.currentTab) {
                            if (theOpt.categoryGroup != currentCatGroup) {
                                currentCatGroup = theOpt.categoryGroup;
                                if (currentCatGroup != "" && currentCatGroup != null && currentCatGroup != undefined) {
                                    var isExpanded = false;
                                    if (defaultCatGroup === currentCatGroup || theOpt.categoryGroupExpanded == true) {
                                        isExpanded = true;
                                    }
                                    return <Expand key={"opts_" + index} label={currentCatGroup} expanded={isExpanded}>{this.renderCatGroup(editableOptions, globalColours, currentCatGroup)}</Expand>
                                }
                                else {
                                    return <div key={"opts_" + index} className="dd_categoryGroup"><div className="expandChildren">{this.renderCatGroup(editableOptions, globalColours, currentCatGroup)}</div></div>
                                }
                            }
                        }
                    })}


                </div>
                {
                    this.props.selectedElement.isSelected &&
                    <div className="rightEditorBack">
                        <UILIB.Button className="button-primary" text={i18n.t('continue')} onClick={this.props.deselectElement} />
                    </div>
                }
            </>
            }
        </Fragment>

    }
}



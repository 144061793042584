module.exports = {
    triggers: {
        initial: [
            {
                label: 'A Contact joined',
                longDesc: 'When a contact was added to your group or segment',
                value: 'signup',
                icon: 'icon-user-plus'
            }, {
                label: 'A Date and Time',
                longDesc: 'When a date and time is reached',
                value: 'datetime',
                icon: 'icon-alarm2',
                hasOptions: {
                    type: "customField",
                    dataType: "DATE",
                    target: "customFieldId",
                    label: "The Contact Field",
                    longDesc: "Pick a contact field that we will watch for the Date Time"
                }
            }, {
                label: 'A Contact Anniversary',
                longDesc: 'When a year has passed since the date in a contact date field',
                value: 'anniversary',
                icon: 'icon-gift',
                hasOptions: {
                    type: "customField",
                    dataType: "DATE",
                    target: "customFieldId",
                    label: "The Contact Field",
                    longDesc: "Pick a contact field that we will monitor for an Anniversary"
                }
            }, {
                label: 'A contact was updated',
                longDesc: 'When a contact field is changed',
                value: 'update',
                icon: 'icon-pencil-line',
                hasOptions: {
                    type: "customField",
                    dataType: "ANY",
                    target: "customFieldId",
                    label: "The Contact Field",
                    longDesc: "Pick a contact field that we will watch for changes"
                }
            }],
        email: [{
            label: 'Sent the last Email',
            longDesc: 'When contacts have been sent the email in the previous step',
            value: 'all',
            icon: 'icon-paper-plane',
        }, {
            label: 'Opened the last email',
            longDesc: 'When contacts have opened the last email',
            icon: 'icon-paper-plane',
            value: 'opened'
        }, {
            label: 'Clicked any link',
            longDesc: 'When contacts click any link in the last email',
            icon: 'icon-paper-plane',
            value: 'clicked'
        }, {
            label: 'Clicked a Specific link',
            longDesc: 'When a contact clicks a specific link in the last email',
            icon: 'icon-paper-plane',
            value: 'link',
            hasOptions: {
                type: "specificLink",
                dataType: "CAMPAIGNLINKS",
                target: "customFieldId",
                label: "The Link Clicked",
                longDesc: "Select the link that needs to be clicked in the previous campaign before this action can proceed."
            }
        }]
    },
    actions: [{
        label: 'Send an Email',
        longDesc: 'Send the affected contact(s) an email',
        value: 'email',
        icon: 'icon-envelope',
        hasOptions: [
            {
                target: "delay",
                label: "After",
                longDesc: "The delay after the action has occured",
                uiType: "delayEditor"
            },
            // {
            //     target: "sendTime",
            //     label: "Select a Time",
            //     longDesc: "Select the time of day to send",
            //     uiType: "timePicker"
            // },
            {
                target: "campaign.fromName",
                label: "The name of the person it is From",
                longDesc: "Enter the FROM Name",
                uiType: "text"
            },
            {
                target: "campaign.fromAddress",
                label: "The FROM Email Address",
                longDesc: "Enter the FROM Email Address",
                uiType: "email"
            },
            {
                target: "campaign.replyAddress",
                label: "The REPLY Email Address",
                longDesc: "Enter the REPLY Email Address",
                uiType: "email"
            },
            {
                target: "campaign.subject",
                label: "The Email Subject",
                longDesc: "Enter the Email Subject",
                uiType: "text"
            }
            ,
            {
                target: "campaign.TemplateId",
                label: "Design Email Template",
                longDesc: "Design the Email Template to send",
                uiType: "emailTemplate"
            }

        ]
    }, {
        label: 'Update a Field',
        longDesc: 'Update a field against the affected contact(s)',
        value: 'update',
        icon: 'icon-pencil-line',
        hasOptions: [{
            target: "delay",
            label: "After",
            longDesc: "The delay after the action has occured",
            uiType: "delayEditor"
        }, {
            type: "customField",
            dataType: "ANY",
            target: "actionUpdate",
            label: "Select a field",
            longDesc: "Pick the contact field to update",
            uiType: "select"
        },
        {
            target: "actionValue",
            label: "Enter a new Value",
            longDesc: "Enter the value that will be inserted",
            uiType: "text"
        }]
    }, {
        label: 'Copy the Contact',
        longDesc: 'Create a copy of the affected contact(s) in another group',
        value: 'addToGroup',
        icon: 'icon-user-plus',
        hasOptions: [{
            target: "delay",
            label: "After",
            longDesc: "The delay after the action has occured",
            uiType: "delayEditor"
        }, {
            type: "group",
            dataType: "NOTSELF",
            target: "actionGroup",
            label: "Group to Copy To",
            longDesc: "Select a group to copy the contact to",
            uiType: "select"
        }]
    }, {
        label: 'Move the Contact',
        longDesc: 'Move the affected contact(s) to another group',
        value: 'moveToGroup',
        icon: 'icon-users2',
        hasOptions: [{
            target: "delay",
            label: "After",
            longDesc: "The delay after the action has occured",
            uiType: "delayEditor"
        }, {
            type: "group",
            dataType: "NOTSELF",
            target: "actionGroup",
            label: "Group to Move To",
            longDesc: "Select a group to move the contact to",
            uiType: "select"
        }]
    }, {
        label: 'Remove the Contact',
        longDesc: 'Remove the affected contact(s) from the group',
        value: 'removeFromGroup',
        icon: 'icon-user-minus',
        hasOptions: [{
            target: "delay",
            label: "After",
            longDesc: "The delay after the action has occured",
            uiType: "delayEditor"
        }, {
            type: "group",
            dataType: "ALL",
            target: "actionGroup",
            label: "Group to Remove From",
            longDesc: "Select a group to remove the contact from",
            uiType: "select"
        }]
    }, {
        label: 'Activate the Contact',
        longDesc: 'Mark the contact as active in the group',
        value: 'active',
        icon: 'icon-check'
    }, {
        label: 'De-Activate the Contact',
        longDesc: 'Mark the contact as in-active in the group',
        value: 'inactive',
        icon: 'icon-user-lock'
    },
    {
        label: 'Fire a Webhook',
        longDesc: 'Send a notification to a webhook',
        value: 'webhook',
        icon: 'icon-arrow-up-right',
        hasOptions: [{
            target: "webhookUrl",
            label: "Enter a new URL",
            longDesc: "Enter the webhook url that will be posted to",
            uiType: "text"
        }]
    }
    ]
}
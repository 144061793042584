import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import axiosInstance from '../../../../../../data/http/axios';
import TierPermissions from '../../../../../../Classes/tierPermissions';
import TierLimitHolder from '../../../../../../Common-Objects/UI/Cards/TierLimitHolder';

@connect((store) => {
    return { site: store.siteMaster, account: store.accountMaster }
})
class FormSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            domains: [],
            loading: true
        }

        this.copyToClipboard = this.copyToClipboard.bind(this)
    }

    copyToClipboard(key) {
        this.setState({
            alertOpen: true,
            alertMessage: 'copied to clipboard'
        })
        copy(key)
    }

    componentDidMount() {
        axiosInstance.get('/accountMaster/tracking').then(response => {
            this.setState({
                loading: false,
                domains: response.data.Domains.filter(d => d.authenticated && d.sslDone)
            })
        })
    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />

        const gatedContentPermissions = TierPermissions('gatedContent', this.props.account)
        if (this.props.form.options?.serve && !gatedContentPermissions.allowed) {
            // return a blocked alert asking them to upgrade
            return <TierLimitHolder type={"Gated Content"}
                err={"tierForbids"}
                tierName={this.props.account.tier.name} />
        }

        var domain = this.props.site.trackingDomain
        let ownDomin = false
        if (this.state.domains && this.state.domains.length) {
            domain = this.state.domains[0].domainName
            ownDomin = true
        }
        const isFree = this.props.account && this.props.account.accountMaster && this.props.account.accountMaster.accountType == 'free' && ownDomin;
        var url = (isFree ? 'http://' : 'https://') + domain + '/form?am=' + this.props.account.id + '&fid=' + this.props.form.id
        var link = '<script type="text/javascript" src="' + url + '"></script>'
        var rawScript = `<form action=\"${url}\" method=\"post\" id=\"mpform-${this.props.form.id}\" class=\"mpform\">
            ${this.props.form.formHTML}
        </form>`
        return (
            <div>
                {this.props.form.formType !== 0 && <div>
                    {this.props.form.formType !== 3 && <UILIB.TextInput
                        label={i18n.t('form:edit.deploy.embed')}
                        value={link}
                        readonly={true}
                        iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => { this.copyToClipboard(link) }} />}
                    />}
                    {this.props.form.formType === 3 && <UILIB.TextArea
                        label={i18n.t('form:edit.deploy.embed')}
                        rows={8} value={rawScript}
                        readonly={true}
                        iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => { this.copyToClipboard(rawScript) }} />}
                    />}

                </div>}

                {
                    this.props.form.formType === 0 && <div>
                        <UILIB.TextInput
                            label={i18n.t('form:edit.deploy.hosted')}
                            value={url + '&host=true'}
                            iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => { this.copyToClipboard(url + '&host=true') }} />}
                            readOnly={true} />
                    </div>
                }

                {
                    this.props.form.formType == 2 && <div>
                        <UILIB.TextInput
                            label={i18n.t('form:edit.deploy.popup')}
                            value={"mpmPopup()"}
                            iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => { this.copyToClipboard("mpmPopup()") }} />}
                            readOnly={true} />
                    </div>
                }


                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
            </div >
        );
    }
}

export default FormSettings;
import React, { Component } from 'react';

class SelectUser extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="text-lg text-primary center-xs mar-b20">Select User</div>
                <ul className="list avatar with-lines">

                    {this.props.users.map((user, index) => {
                        var name = ''
                        if (user.fullname) name = user.fullname
                        else if (user.firstName || user.lastName) name = (user.firstName || '') + ' ' + (user.lastName || '')
                        else if (user.username) name = user.username
                        else if (user.emailAddress) name = user.emailAddress

                        var exists = this.props.existing.find(e => e.options.key == user.id)

                        return <li className={"middle-xs" + (exists ? ' disabled' : '')} key={index} onClick={exists ? () => { } : () => { this.props.select(index) }} >
                            {!!user.avatar && <div className="avatar">
                                <img style={{ height: 'auto', width: '45px', marginRight: '15px' }} src={user.avatar} />
                            </div>}
                            <p className="no-marg">{name}</p>
                        </li>
                    })}

                </ul>
            </div>
        );
    }
}

export default SelectUser;
import React from 'react'

export default class IconsArrowBounce extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.1303 12.5037C16.3178 12.6912 16.5721 12.7965 16.8372 12.7966C17.1024 12.7965 17.3567 12.6912 17.5442 12.5037C17.7317 12.3162 17.837 12.0619 17.8371 11.7967V6.13986C17.837 5.8747 17.7317 5.62041 17.5442 5.43291C17.3567 5.24541 17.1024 5.14005 16.8372 5.14001H11.1804C10.9182 5.14457 10.6683 5.25192 10.4845 5.43893C10.3007 5.62594 10.1978 5.87766 10.1978 6.13986C10.1978 6.40207 10.3007 6.65379 10.4845 6.8408C10.6683 7.02781 10.9182 7.13516 11.1804 7.13971L14.4596 7.13971C14.4539 7.14529 14.4483 7.15095 14.4428 7.15667L9.14981 12.4497L3.85681 7.15667C3.66928 6.9692 3.41497 6.86388 3.14981 6.86388C2.88465 6.86388 2.63034 6.9692 2.44281 7.15667C2.25534 7.3442 2.15002 7.5985 2.15002 7.86367C2.15002 8.12883 2.25534 8.38314 2.44281 8.57067L8.44281 14.5707C8.63034 14.7581 8.88465 14.8635 9.14981 14.8635C9.41498 14.8635 9.66928 14.7581 9.85681 14.5707L15.8374 8.5901V11.7967C15.8374 12.0619 15.9428 12.3162 16.1303 12.5037Z" fill={color} />
        </svg>



    }
}        
import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Legend } from 'recharts';
import UILIB from '~/Common-Objects/Lib';

import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
import NumberFormat from '~/Classes/numberFormatFunctions';
import i18n from '~/i18n';

const Map = ReactMapboxGl({
    accessToken: "pk.eyJ1IjoiY29udGFjdGdhdGUiLCJhIjoiODI5ZDJlOWNiNGM5YTM4OGM1Mzk2ODA2MjI4MTI2YmQifQ.iEJBZeGfK-7O2nOkPxIBDA"
});

class TransactionsOverview extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        var features = this.props.geos.features.map((feature, index) => {
            return <Feature key={"mapC_" + index} coordinates={feature.geometry.coordinates} properties={{
                'marker-color': '#3bb2d0',
                'marker-size': 'large',
                'marker-symbol': 'circle-15'
            }} />
        })

        return (
            <div>
                <div className="quickFlex" style={{ alignContent: "center", justifyContent: "space-between" }}>
                    <div className="page-header mar-b25">{i18n.t('campaigns:transactions.analytics')}</div>
                    <UILIB.TextInput outerClassName="mar-b25" type="multiDate" startDate={this.props.startDate} endDate={this.props.endDate} updateDate={this.props.updateDate}></UILIB.TextInput>
                </div>
                <UILIB.Row >
                    <UILIB.Column xs={12} sm={6} md={3} lg={3}>
                        <UILIB.Paper>
                            <h4 className='mar-b25'>{i18n.t('campaigns:transactions.requests')}</h4>
                            <div className="statistic">
                                {NumberFormat.formatNumber(this.props.filtered.sent + this.props.filtered.failed)}
                                <span className="text-sml text-grey pad-left">({NumberFormat.formatNumber(this.props.filtered.pending)} pending)</span>
                            </div>
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} md={3} lg={3} margin={0}>
                        <UILIB.Paper >
                            <h4 className="mar-b25">{i18n.t('campaigns:transactions.opens')}</h4>
                            <div className="statistic" style={{ height: 50 }}>{NumberFormat.formatNumber(this.props.filtered.opened)}</div>
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} md={3} lg={3} margin={0}>
                        <UILIB.Paper>
                            <h4 className="mar-b25">{i18n.t('campaigns:transactions.clicks')}</h4>
                            <div className="statistic" style={{ height: 50 }}>{NumberFormat.formatNumber(this.props.filtered.clicked)}</div>
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={6} md={3} lg={3} margin={0}>
                        <UILIB.Paper>
                            <h4 className="mar-b25">{i18n.t('campaigns:transactions.bounces')}</h4>
                            <div className="statistic" style={{ height: 50 }}>{NumberFormat.formatNumber(this.props.filtered.bounced + this.props.filtered.failed)}</div>
                        </UILIB.Paper>
                    </UILIB.Column>

                    <UILIB.Column xs={12} sm={12} md={12} lg={6} margin={0}>
                        <UILIB.Paper>
                            <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignItems: "initial" }}>
                                <h4 className="mar-b5">{i18n.t('campaigns:main.chart.header', { label: this.props.groupOptions[this.props.currentGroupBy].label })}</h4>
                                <div>
                                    {this.props.groupOptions.map((theOpt, index) => {
                                        return <UILIB.ButtonSimple
                                            key={"gt_" + index}
                                            className={"mar-l5"} s
                                            selected={index === this.props.currentGroupBy}
                                            onClick={() => { this.props.changeTimePeriod(index) }}>
                                            {i18n.t('campaigns:main.chart.' + theOpt.label)}
                                        </UILIB.ButtonSimple>
                                    })}
                                </div>
                            </div>

                            <ResponsiveContainer height={300}>
                                <LineChart data={this.props.chartData} margin={{ top: 10, right: 0, left: 20, bottom: 0 }}>
                                    <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fontSize: 10, fill: '#999', fontFamily: "Open Sans", fontWeight: 600 }} />
                                    <YAxis axisLine={false} hide={true} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontFamily: "Open Sans", fontWeight: 600 }} />
                                    <Tooltip content={<UILIB.CustomTooltip showLabel={true} followColor={true} />} />
                                    <Legend verticalAlign="bottom" iconType="circle" iconSize={11} content={UILIB.CustomPieLegend} />
                                    <CartesianGrid horizontal={false} strokeDasharray="2 2" stroke="#DADADC" />
                                    <Line
                                        type="monotone" strokeWidth={2}
                                        activeDot={{ stroke: this.props.siteMaster.colours.$secondary, strokeWidth: 2, fill: 'white' }}
                                        dot={false}
                                        dataKey={i18n.t('sends')}
                                        stroke={this.props.siteMaster.colours.$secondary}
                                    />
                                    <Line type="monotone" strokeWidth={2} dot={false} dataKey={i18n.t('opens')} stroke={this.props.siteMaster.colours.$primary}
                                        activeDot={{ stroke: this.props.siteMaster.colours.$primary, strokeWidth: 2, fill: 'white' }} />
                                    <Line type="monotone" strokeWidth={2} dot={false} dataKey={i18n.t('clicks')} stroke={this.props.siteMaster.colours['$status-orange']}
                                        activeDot={{ stroke: this.props.siteMaster.colours['$status-orange'], strokeWidth: 2, fill: 'white' }} />
                                    <Line type="monotone" strokeWidth={2} dot={false} dataKey={i18n.t('bounces')} stroke={this.props.siteMaster.colours.$warning}
                                        activeDot={{ stroke: this.props.siteMaster.colours.$warning, strokeWidth: 2, fill: 'white' }} />
                                </LineChart>
                            </ResponsiveContainer>

                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={12} sm={12} md={12} lg={6}>
                        <UILIB.Paper className="dashHeight large">
                            <Map
                                style="mapbox://styles/contactgate/cjxq92mlz0c5s1cne6vo6tw6q"
                                id={"campaign"}
                                containerStyle={{
                                    height: "360px",
                                    width: "100%",
                                    boxSizing: "border-box",
                                    borderRadius: "4px",
                                }}
                                zoom={[1]}>
                                <Layer type="heatmap">
                                    {features}
                                </Layer>
                            </Map>
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>

            </div >
        );

    }
}

export default TransactionsOverview;
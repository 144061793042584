import React, { useEffect, useState, Fragment } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import textFunctions from '~/Classes/textFunctions';
export default function DrawerActivateFirstRun({ activateOption = () => { }, automation, initialStep }) {
    const [allSubs, setAllSubs] = useState(false)
    const [delay, setDelay] = useState(false)
    const [options, setOptions] = useState(automation.options)

    const updateVal = e => {
        setOptions({ ...options, [e.currentTarget.name]: e.currentTarget.value })
    }

    useEffect(() => {
        if (delay && !options.batchSize) {
            setOptions({ ...options, batchSize: 1000, batchDelay: 5, batchUnit: 'minutes' })
        }
    }, [delay])

    var hasSegments = initialStep.triggerType.value == "1";
    var group = i18n.t('automation:add.live.group');
    var groupSegmentTxt = group;
    if (hasSegments) groupSegmentTxt = i18n.t('automation:add.live.groupAndSeg');
    if (Array.isArray(initialStep.triggerSteps) && initialStep.triggerSteps.length) {
        if (initialStep.triggerSteps.some(st => st.triggerType.value == "1" || st.triggerType.value == "2")) {
            console.log('ARGH')
            group = i18n.t('automation:add.live.groupOrSeg');
        }
    }
    group = textFunctions.checkForHtml(group);
    groupSegmentTxt = textFunctions.checkForHtml(groupSegmentTxt);

    const units = [
        { label: 'minutes', value: 'minutes' },
        { label: 'hours', value: 'hours' }
    ]

    return <div>
        {!allSubs && <Fragment>
            <h4 className="mar-b25">{i18n.t('automation:add.live.header')}</h4>
            <div className="mar-b15" dangerouslySetInnerHTML={{
                __html: i18n.t('automation:add.live.subheader1', {
                    group,
                    groupSegmentTxt
                })
            }}></div>
            <div className="mar-b15" style={{ fontWeight: "bold" }} dangerouslySetInnerHTML={{
                __html: i18n.t('automation:add.live.subheader2', {
                    group,
                    groupSegmentTxt
                })
            }}></div>
            <div className="mar-b15">{i18n.t('automation:add.live.subheader3')}</div>
            <div className="quickFlex">
                <UILIB.Button text={i18n.t('automation:add.live.onlyNew')} className="button-primary mar-r10" onClick={() => activateOption(false)} />
                <UILIB.Button text={i18n.t('automation:add.live.all')} onClick={() => setAllSubs(true)} />
            </div>
        </Fragment>
        }
        {allSubs && <div >
            <UILIB.Toggle explainer={"If set, this will slow down how quick we process the initial step the first time you run this automation and if you import more contacts"} outerClassName="mar-b25" value={delay} after='Stagger Contacts on Start' onChange={() => setDelay(d => !d)} />
            {delay &&
                <div className="quickFlex mar-b25">
                    <div style={{ flex: "1" }}>
                        <UILIB.TextInput label="Batch Size" type="number" value={options.batchSize} name="batchSize" onChange={updateVal} />
                    </div>
                    <div style={{ flex: "1" }}>
                        <UILIB.TextInput outerClassName="mar-l10" label="Delay" type="number" value={options.batchDelay} name="batchDelay" onChange={updateVal} />
                    </div>
                    <div style={{ flex: "1" }}>
                        <UILIB.Select outerClassName="mar-l10" label="Unit" data={units} value={options.batchUnit} name="batchUnit" onChange={updateVal} />
                    </div>
                </div>
            }
            <div className="quickFlex">
                <UILIB.Button text="Start Automation" className="button-primary mar-r10" onClick={() => activateOption(true, options)} />
                <UILIB.Button text="Back" onClick={() => setAllSubs(false)} />
            </div>
        </div>
        }
    </div >
}
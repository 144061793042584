import React from "react";

export default function IconsTikTok({ color = "#7246B1", ...rest }) {
  return (
    <svg
      className="icons-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.2011 5.43894C15.0926 5.38285 14.987 5.32136 14.8846 5.2547C14.5869 5.05788 14.3139 4.82597 14.0716 4.56396C13.4652 3.87021 13.2388 3.16641 13.1554 2.67365H13.1587C13.0891 2.26464 13.1179 2 13.1222 2H10.3606V12.6786C10.3606 12.822 10.3606 12.9637 10.3546 13.1037C10.3546 13.1211 10.3529 13.1372 10.3519 13.1559C10.3519 13.1637 10.3519 13.1717 10.3502 13.1797C10.3502 13.1817 10.3502 13.1838 10.3502 13.1858C10.3211 13.5689 10.1983 13.939 9.99258 14.2636C9.78685 14.5881 9.50453 14.8571 9.17043 15.0469C8.82223 15.245 8.42841 15.3489 8.0278 15.3484C6.74113 15.3484 5.69833 14.2992 5.69833 13.0035C5.69833 11.7078 6.74113 10.6586 8.0278 10.6586C8.27136 10.6584 8.51342 10.6967 8.745 10.7722L8.74835 7.96036C8.04534 7.86955 7.33114 7.92542 6.65081 8.12445C5.97048 8.32347 5.33877 8.66134 4.79555 9.11672C4.31956 9.53029 3.91939 10.0238 3.61306 10.5749C3.49648 10.7759 3.05665 11.5835 3.00339 12.8943C2.96989 13.6383 3.19332 14.4091 3.29985 14.7277V14.7344C3.36684 14.922 3.62646 15.5621 4.04954 16.1018C4.3907 16.5347 4.79377 16.9149 5.24577 17.2304V17.2236L5.25246 17.2304C6.58938 18.1388 8.07168 18.0792 8.07168 18.0792C8.32828 18.0688 9.18785 18.0792 10.164 17.6166C11.2467 17.1037 11.863 16.3396 11.863 16.3396C12.2568 15.8831 12.5699 15.3628 12.7889 14.8011C13.0388 14.1441 13.1222 13.3563 13.1222 13.0414V7.37615C13.1557 7.39625 13.6019 7.69137 13.6019 7.69137C13.6019 7.69137 14.2448 8.1034 15.2477 8.37172C15.9672 8.56266 16.9367 8.60286 16.9367 8.60286V5.86135C16.597 5.8982 15.9073 5.79101 15.2011 5.43894Z"
        fill={color}
      />
    </svg>
  );
}

import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import helpers from '~/Classes/helpers'
class CampaignSelector extends Component {
    constructor(props) {
        super(props);

        let searchTimer;

        this.state = {
            campaigns: [],
            loading: true
        }
        this.getCampaigns = this.getCampaigns.bind(this);
        this.selectCampaign = this.selectCampaign.bind(this);
    }

    componentDidMount() {
        this.getCampaigns();
    }
    async getCampaigns(searchText) {
        this.setState({
            loading: true
        })
        let self = this;
        if (this.searchTimer) {
            this.searchTimer = clearTimeout(this.searchTimer)
        }
        let timeOut = 0;
        if (searchText) {
            searchText = '&searchText=' + searchText
            timeOut = 1000;
        }
        let url = '/campaign?sortOrder=desc&simple=true&all=true' + searchText;
        this.searchTimer = setTimeout(() => {
            axiosCampaigns(url)

        }, timeOut);

        async function axiosCampaigns(url) {
            let response = await axios.get(url)
            let campaigns = response.data.Campaigns;
            if (self.props.hasOwnProperty("allowAnyCampaign")) {
                campaigns.unshift({
                    id: self.props.allowAnyCampaign, campaignName: "Any Campaign"
                });
                campaigns.forEach(c => {
                    const iconInfo = helpers.getCampaignType(c.type);
                    let campaignNameFull = c.campaignName;
                    if (c.type) {
                        campaignNameFull = <><UILIB.Icons icon={iconInfo.icon} color="grey" alt={iconInfo.alt} title={iconInfo.alt} /> {c.campaignName}</>;
                    }
                    if (c.Automation?.id) {
                        campaignNameFull = <>
                            <UILIB.Icons icon={iconInfo.icon} color="grey" alt={iconInfo.alt} title={iconInfo.alt} /> Automation Email Campaign  {c.Automation.name} Subject: {c.subject}
                        </>
                    }
                    c.campaignNameFull = campaignNameFull;
                })
                self.setState({
                    campaigns: campaigns,
                    loading: false
                })
            }
        }
    }

    selectCampaign(val) {
        this.props.onChange({
            target: {
                value: val,
                name: this.props.name
            },
            currentTarget: {
                value: val,
                name: this.props.name
            }
        })
    }

    render() {
        let headerText = this.props.placeholder
        let found = this.state.campaigns.find(w => w.id == this.props.value)
        if (found) headerText = found.campaignName;

        return <UILIB.ButtonSelect
            label={this.props.label}
            outerClassName={this.props.outerClassName}
            name={this.props.name}

            error={this.props.error}
            {...this.props.inputProps}

            filter={true}
            updateFilter={this.getCampaigns}
            headerText={headerText}
            data={this.state.campaigns.map((c, i) => {
                let selected = false;
                if (this.props.value == c.id) selected = true;
                return <UILIB.CheckBox
                    key={i}
                    name={c.id}
                    onChange={() => { this.selectCampaign(c.id) }}
                    checked={selected}>
                    {c.campaignNameFull}
                </UILIB.CheckBox>
            }
            )}
            loadingData={this.state.loading} >
        </UILIB.ButtonSelect >
    }
}

export default CampaignSelector;
import React from 'react'

export default class Row extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var classes = "row ";
        if (this.props.className) {
            classes += this.props.className;
        }

        return <div className={classes} style={this.props.style} onClick={this.props.onClick}> {this.props.children}</div >

    }
}

import React, { Component } from 'react'
import axios from '~/data/http/axios';
import SiteVars from '~/Classes/siteVars';
import UILIB from '~/Common-Objects/Lib'

export default class GoogleMaps extends Component {
    constructor(props) {
        super(props)

        let timer;
        this.state = {
            errors: {
                mapPostCode: ""
            }
        }

        this.changeVal = this.changeVal.bind(this);
        this.updateMap = this.updateMap.bind(this);
    }

    componentDidMount() {
        this.updateMap();
    }

    updateMap() {
        var self = this;
        var data = {
            postCode: this.props.localVariables.googleMapsPostCode.value,
            zoom: this.props.localVariables.googleMapsZoom.value,
            maptype: this.props.localVariables.googleMapsMapType.value,
            showMarker: this.props.localVariables.googleMapsShowMarkers.value,
            markerColor: this.props.localVariables.googleMapsMarkersColor.value
        }
        axios.post("/application/googlemaps/createstaticmap", data).then(mapData => {
            var finalUrl = SiteVars.repoEndPoint + mapData.data.filePath + mapData.data.fileName
            self.props.updateVal("localVariables.googleMapsScreenshot", finalUrl);
        });

    }

    changeVal(property, newVal) {
        clearTimeout(this.timer)
        this.timer = setTimeout(this.updateMap, 2000);
        this.props.updateVal("localVariables." + property, newVal);
    }

    render() {
        var style = {};
        if (this.props.elementData.optionFullWidth) style = { flex: "100%" }
        var mapPostCode = this.props.localVariables.googleMapsPostCode.value;
        var mapZoom = this.props.localVariables.googleMapsZoom.value;
        var mapType = this.props.localVariables.googleMapsMapType.value;
        var showMarker = this.props.localVariables.googleMapsShowMarkers.value;
        var markerColor = this.props.localVariables.googleMapsMarkersColor.value;
        return <div>
            <div className="dd_toolHolder" style={style}>
                <div className="dd_inputTool">
                    <UILIB.TextInput
                        label={"Post/Zip Code"}
                        value={mapPostCode}
                        name="mapPostCode"
                        onChange={(event) => { this.changeVal("googleMapsPostCode", event.target.value) }}
                        placeHolder="Post/Zip Code"
                        error={this.state.errors.mapPostCode.length > 0 ? this.state.errors.mapPostCode : ""}
                    />
                </div>
            </div>
            <div className="dd_toolHolder" style={style}>
                <div className="dd_inputTool">

                    <UILIB.DragBar label="Zoom" value={mapZoom} name="mapZoom" minVal={1} maxVal={20} onChange={(newVal) => { this.changeVal("googleMapsZoom", newVal) }} placeholder="Post/Zip Code" />

                </div>
            </div>
            <div className="dd_toolHolder" style={style}>
                <div className="dd_inputTool">

                    <UILIB.Select label="Map Type" name="mapType" data={[{ value: "roadmap", label: "Road Map" }, { value: "satellite", label: "Satellite" }, { value: "hybrid", label: "Hybrid" }, { value: "terrain", label: "Terrain" }]} className="dd_dragDrop" onChange={(event) => { this.changeVal("googleMapsMapType", event.target.value) }} value={mapType} />

                </div>
            </div>
            <div className="dd_toolHolder" style={style}>
                <div className="dd_inputTool">
                    <UILIB.Select label="Show Marker" name="showMarker" data={[{ value: true, label: "Yes" }, { value: false, label: "No" }]} className="dd_dragDrop" onChange={(event) => { this.changeVal("googleMapsShowMarkers", event.target.value) }} value={showMarker} />

                </div>
            </div>
            {(showMarker == "true" || showMarker == true) && <div className="dd_toolHolder" style={style}>
                <div className="dd_inputTool">

                    <UILIB.Select label="Marker Colour" name="markerColor" data={[{ value: "red", label: "Red" }, { value: "blue", label: "Blue" }, { value: "green", label: "Green" }, { value: "yellow", label: "Yellow" }, { value: "purple", label: "Purple" }, { value: "black", label: "Black" }, { value: "white", label: "White" }, { value: "gray", label: "Gray" }]} className="dd_dragDrop" onChange={(event) => { this.changeVal("googleMapsMarkersColor", event.target.value) }} value={markerColor} />

                </div>
            </div>}



        </div>

    }
}
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';


export default class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: this.props.expanded
        }
        this.toggleExpand = this.toggleExpand.bind(this);
    }

    componentDidMount() {
    }
    componentDidUpdate() {
        if (this.props.expanded !== this.state.expanded) {
            this.setState({ expanded: this.props.expanded })
        }
    }


    toggleExpand() {
        if (this.props.automatic) return
        var expanded = this.state.expanded;
        if (expanded === true) {
            expanded = false;
        }
        else {
            expanded = true;
        }
        this.setState({ expanded: expanded });
    }

    clickCheckBox(el) {
        var thisName = el.currentTarget.name;
        var isChecked = el.currentTarget.checked;
        var theData = this.state.optionsData;
        var expanded = this.state.expanded;

        var mainChecked = this.state.mainChecked;
        if (thisName === "mainChecked") {
            mainChecked = isChecked;
            expanded = isChecked;
            //check/uncheck all sub options
            for (var x in theData) {
                theData[x][0].checked = isChecked;
            }
        }
        else {
            theData[thisName][0].checked = isChecked;
            //check/uncheck main one depending on all ticked or not
            var totCheckedPerms = theData.filter(thePerm => thePerm[0].checked === true).length;
            if (totCheckedPerms === theData.length) {
                mainChecked = true;
            }
            if (totCheckedPerms < theData.length) {
                mainChecked = false;
            }
        }
        this.setState({ mainChecked: mainChecked, optionsData: theData, expanded: expanded }, this.sendDataBack);
    }

    sendDataBack() {
        this.props.dataChange(this.props.name, this.state.optionsData);
    }

    render() {

        var innerClasses = "accordion plain ";
        var wrapClasses = "accordion-wrap plain ";
        if (this.state.expanded === true) {
            innerClasses += " expanded ";
        }

        return <div className={wrapClasses}>
            <div className={innerClasses} onClick={this.toggleExpand}>
                {this.props.header}
            </div>
            {this.state.expanded &&
                <div className="accordion-expand plain">
                    {this.props.content || this.props.children}
                </div>
            }
        </div>

    }
}

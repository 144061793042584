import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import Theme from '~/pages/cp/templates/add/dragDrop/themes/theme';
import InfiniteScroll from 'react-infinite-scroller';

class CampaignTemplates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            templates: [],
            count: 0,
            page: 0,
            limit: 6,
            searchText: '',
            hasMore: false
        }

        this.getTemplates = this.getTemplates.bind(this);
        this.updateSearchText = this.updateSearchText.bind(this);
    }

    componentDidMount() {
        this.getTemplates()
    }

    getTemplates(reload) {
        var queryStr = `?limit=${this.state.limit}&offset=${reload ? 0 : this.state.templates.length}&sortColumn=id&sortOrder=desc&status=all`;
        if (this.state.searchText) queryStr += '&searchText=' + this.state.searchText;

        axios.get('/campaign/templates' + queryStr).then(response => {
            var templates = reload ? [] : this.state.templates;
            templates = templates.concat(response.data.Templates)
            this.setState({
                loaded: true,
                count: response.data.count,
                templates,
                hasMore: response.data.count > templates.length
            })
        })
    }

    updateSearchText(ev) {
        this.setState({
            searchText: ev.currentTarget.value
        }, () => {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => this.getTemplates(true), 250)
        })
    }

    render() {
        if (this.state.loaded) {

            const loader = <UILIB.LoadingLine key={0}></UILIB.LoadingLine>;
            return (
                <div>
                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={6} className="mar-b25">
                            <UILIB.TextInput
                                name="searchText"
                                clickToChangeClicked={this.getTemplates}
                                onEnterPress={this.getTemplates}
                                onChange={this.updateSearchText}
                                value={this.state.searchText}
                                style={{ paddingRight: "35px" }}
                                placeholder={i18n.t('campaigns:standard.step2.saved.search')}
                                iconLeft={<UILIB.Icons icon="magnifier" onClick={() => { }} />}
                            />
                        </UILIB.Column>
                    </UILIB.Row>


                    <UILIB.Paper className="mar-b25">
                        <div ref={(ref) => this.scrollParentRef = ref}>

                            <InfiniteScroll
                                pageStart={0}
                                loadMore={() => { this.getTemplates(false) }}
                                hasMore={this.state.hasMore}
                                loader={loader}
                                useWindow={false}
                                getScrollParent={() => this.scrollParentRef}
                            >
                                <UILIB.Row>
                                    {!this.state.templates.length && <div style={{ width: '100%' }} className="center-xs center-sm center-md center-lg"><span >{i18n.t('campaigns:standard.step2.saved.none')}</span></div>}
                                    {this.state.templates.map((template, index) => {
                                        return <UILIB.Column xs={12} sm={6} md={4} lg={3} className="themes-advanced" key={index}>
                                            <Theme theme={template} className="extraSpace" select={() => this.props.onSelect(template)} />
                                        </UILIB.Column>
                                    })}
                                </UILIB.Row>
                            </InfiniteScroll>
                        </div>
                    </UILIB.Paper>

                </div>

            );
        } else {
            return <div style={{ marginTop: '50px' }}>
                <UILIB.LoadingIcons iconType="2" className="mar-b30" />
            </div>
        }
    }
}

export default CampaignTemplates;
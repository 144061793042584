import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'

class BounceActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            action: '',
            actioning: false
        }

        this.editValue = this.editValue.bind(this)
        this.finish = this.finish.bind(this)
    }

    editValue(ev) {
        this.setState({ [ev.currentTarget.name]: ev.currentTarget.value })
    }

    finish() {
        this.setState({ actioning: true })
        axios.put('/campaign/' + this.props.campaignId + '/bounces', {
            action: this.state.action
        }).then(() => {
            this.setState({
                actioning: false
            })
            this.props.finish()
        })
    }

    render() {
        var actions = [{ label: 'Delete', value: 'delete' }, { label: 'Denylist', value: 'blacklist' }]
        let actionFriendly = "";
        if (this.state.action) actionFriendly = this.state.action[0].toUpperCase() + this.state.action.substring(1);
        return (
            <div>
                <h4 className="mar-b25">Perform an action on all the hard bounces from this campaign</h4>

                <UILIB.Select outerClassName="mar-b25" data={actions} disabled={this.state.actioning} value={this.state.action} placeholder="Select Action" name="action" onChange={this.editValue} />

                {!!this.state.action && !this.state.actioning && <UILIB.Button text={actionFriendly} onClick={this.finish} iconRight={<UILIB.Icons icon="arrowRight" />} />}
                {this.state.actioning && <UILIB.LoadingIcons iconType="2" />}
            </div>
        );
    }
}

export default BounceActions;
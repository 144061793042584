import React from 'react'

export default class CircularChip extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var thisClass = "circularChip";
        if (this.props.className) {
            thisClass += " " + this.props.className;
        }

        return <div className={thisClass} style={this.props.style} onClick={this.props.onClick} alt={this.props.alt} title={this.props.alt}>{this.props.children}</div>

    }
}

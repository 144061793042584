import React from 'react'
import { Link } from 'react-router-dom'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import FormDelete from './delete';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions';
import SiteVars from '~/Classes/siteVars';
import DuplicateDrawer from './duplicateDrawer';
import FormPicker from '~/pages/cp/includes/formPicker'
import PermissionChecker from '~/Classes/permissions';
import ToggleDisabled from '../../../forms/disable';
@connect((store) => {
    return { permissionStore: store.permission, accountMaster: store.accountMaster }
})
export default class Forms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 1,
            forms: []
        }

        this.getForms = this.getForms.bind(this);
        this.edit = this.edit.bind(this);
        this.wizard = this.wizard.bind(this)
        this.delete = this.delete.bind(this);
        this.addForm = this.addForm.bind(this);
        this.view = this.view.bind(this)
        this.duplicate = this.duplicate.bind(this)
        this.formAdded = this.formAdded.bind(this)
        this.disable = this.disable.bind(this)
    }


    componentDidMount() {
        this.getForms();
    }

    getForms() {
        var canEdit = PermissionChecker("forms", this.props.permissionStore.permissions, "write")
        axios.get('/group/' + this.props.match.params.groupID + '/form').then(response => {
            this.setState({
                loading: 0,
                forms: response.data.Forms.map((row, index) => {
                    var formChip = "";
                    switch (row.formType) {
                        case 0:
                            formChip = <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-purple">{i18n.t('form:index.hosted')}</UILIB.SquareChip>
                            break;
                        case 1:
                            formChip = <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-orange">{i18n.t('form:index.embed')}</UILIB.SquareChip>
                            break;
                        case 2:
                            formChip = <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-red">{i18n.t('form:index.popup')}</UILIB.SquareChip>
                            break;
                        case 3:
                            formChip = <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-blue">HTML</UILIB.SquareChip>
                            break;
                    }

                    let statusChip = <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-grey">{i18n.t('campaigns:status.Draft')}</UILIB.SquareChip>
                    if (row.status) statusChip = <UILIB.SquareChip iconLeft={<UILIB.Icons icon="circleSolid" />} className="square-chip-green">Running</UILIB.SquareChip>
                    if (row.disabled) statusChip = <UILIB.SquareChip className="square-chip-grey">{i18n.t('form:index.disabled')}</UILIB.SquareChip>

                    let impressionsClass = "square-chip-red";
                    if (row.impressions > 0) impressionsClass = "square-chip-orange";
                    if (row.impressions > 10) impressionsClass = "square-chip-green";

                    const signupsAvg = (100 / row.impressions) * row.signups;
                    const signupsColor = signupsAvg > 50 ? 'square-chip-green' : signupsAvg > 25 ? 'square-chip-orange' : ''

                    let typeChip
                    if (row.options?.serve) {
                        typeChip = <UILIB.SquareChip className="square-chip-large square-chip-min-width square-chip-blue">{i18n.t('form:index.gated')}</UILIB.SquareChip>
                    } else {
                        typeChip = <UILIB.SquareChip className="square-chip-large square-chip-min-width square-chip-purple">{i18n.t('form:index.regular')}</UILIB.SquareChip>
                    }


                    return {
                        formImage: {
                            headerValue: "",
                            value: <img alt={row.formName} title={row.formName} style={{ width: "100px", maxHeight: "45px" }} src={SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/form" + row.id + ".jpg"} />,
                            orderBy: false,
                            width: 100
                        },
                        formName: {
                            headerValue: i18n.t('form:index.name'),
                            value: <Link to={row.status ? this.view(row.id, true) : this.wizard(row, true)} style={{ fontWeight: "600", textDecoration: 'none' }}>{row.formName}</Link>,
                            orderBy: false
                        },
                        formType: {
                            headerValue: i18n.t('form:index.type'),
                            value: typeChip,
                        },
                        hosted: {
                            headerValue: i18n.t('form:index.hostedembedded'),
                            value: formChip,
                            orderBy: false,
                            align: "center",
                            width: 60
                        },
                        status: {
                            headerValue: i18n.t('form:index.status'),
                            value: statusChip,
                            orderBy: true,
                            align: "center",
                            width: 100
                        },
                        impressions: {
                            headerValue: i18n.t('form:index.impressions'),
                            value: <UILIB.SquareChip className={"square-chip-large square-chip-min-width " + impressionsClass}>{NumberFunctions.formatNumber(row.impressions)}</UILIB.SquareChip>,
                            orderBy: false,
                            helper: i18n.t('form:index.impressionsHelper'),
                            align: "center",
                            width: 160
                        },
                        signups: {
                            headerValue: i18n.t('form:index.signups'),
                            value: <div>
                                <div style={{ fontWeight: 'bold', color: 'black' }}>{NumberFunctions.formatNumber(row.signups)}</div>
                                <UILIB.SquareChip className={signupsColor}>{(signupsAvg || 0).toFixed(2)}%</UILIB.SquareChip>
                            </div>,
                            orderBy: false,
                            helper: i18n.t('form:index.signupsHelper'),
                            align: "center",
                            width: 160
                        },
                        createdAt: {
                            headerValue: i18n.t('form:index.created'),
                            value: DateTimeFunctions.formatDateTime(row.createdAt, '2'),
                            orderBy: false,
                            align: "center",
                            width: 60
                        },
                        optionsCol: {
                            headerValue: " ",
                            value: <UILIB.OptionsDropdown popWidth="150px">
                                <ul>
                                    <li>
                                        <a onClick={() => { this.view(row.id) }}>{i18n.t('form:index.view')}</a>
                                    </li>
                                    {(canEdit == true) && <li>
                                        <a onClick={() => { row.status ? this.edit(row.id) : this.wizard(row) }}>{i18n.t('form:index.edit')}</a>
                                    </li>}
                                    {(canEdit == true) && <li>
                                        <a onClick={() => { this.duplicate(row) }}>{i18n.t('form:index.duplicate')}</a>
                                    </li>}
                                    {(canEdit == true) && <li>
                                        <a onClick={() => { this.disable(row) }}>{row.disabled ? i18n.t('form:index.enable') : i18n.t('form:index.disable')}</a>
                                    </li>}
                                    {(canEdit == true) && <li>
                                        <a onClick={() => { this.delete(row.id) }}>{i18n.t('form:index.delete')}</a>
                                    </li>}
                                </ul>
                            </UILIB.OptionsDropdown>,
                            orderBy: false,
                            fixed: true,
                            width: 20
                        }
                    }
                })
            })
        })
    }

    edit(id, returnUrl) {
        if (returnUrl) return '/cp/groups/' + this.props.match.params.groupID + '/forms/edit/' + id
        this.props.history.push('/cp/groups/' + this.props.match.params.groupID + '/forms/edit/' + id);
    }

    view(id, returnUrl) {
        if (returnUrl) return '/cp/groups/' + this.props.match.params.groupID + '/forms/view/' + id
        this.props.history.push('/cp/groups/' + this.props.match.params.groupID + '/forms/view/' + id);
    }

    duplicate(row) {
        var drawerContent = <DuplicateDrawer form={row} groupId={this.props.match.params.groupID} finish={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
            this.getForms()
        }} cancel={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    wizard(row, returnUrl) {
        if (row.wizard && row.wizard.step1) {
            var redirect = '/cp/groups/' + this.props.match.params.groupID + '/forms/create/' + row.id + '/2'
            if (returnUrl) return '/cp/templates/add/dragdrop?form=true&groupId=' + this.props.match.params.groupID + '&formId=' + row.id + '&redirect=' + redirect
            this.props.history.push('/cp/templates/add/dragdrop?form=true&groupId=' + this.props.match.params.groupID + '&formId=' + row.id + '&redirect=' + redirect);
        } else {
            if (returnUrl) return '/cp/groups/' + this.props.match.params.groupID + '/forms/create/' + row.id
            this.props.history.push('/cp/groups/' + this.props.match.params.groupID + '/forms/create/' + row.id);
        }
    }

    delete(id) {
        var drawerContent = <FormDelete formId={id} groupId={this.props.match.params.groupID} onClose={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
            this.getForms()
        }} cancel={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true));
    }

    addForm() {
        var drawer = <FormPicker save={this.formAdded} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, true, "100%"));
    }

    formAdded(theme, themeType, options) {
        var self = this;
        var form = {
            formName: '',
            wizard: {
                step1: true
            },
            options,
            ThemeId: theme.id,
            formType: themeType,
        }
        return axios.post('/group/' + self.props.match.params.groupID + '/form', form).then(response => {
            var redirect = '/cp/groups/' + self.props.match.params.groupID + '/forms/create/' + response.data.Form.id + '/2'
            self.props.history.push('/cp/templates/add/dragdrop?form=true&groupId=' + this.props.match.params.groupID + '&formId=' + response.data.Form.id + '&redirect=' + redirect);
        }).catch(console.log)
    }

    async disable(form) {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <ToggleDisabled form={form} finished={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true));
            this.getForms()
        }
        } />, true, "400px"));
    }

    render() {

        var canEdit = PermissionChecker("forms", this.props.permissionStore.permissions, "write")

        let noResultsTxt = i18n.t('form:index.noForms');
        if (canEdit) noResultsTxt = <div>
            <div className="mar-b25"><a onClick={this.addForm}>{i18n.t('form:index.noForms')}</a></div>
            <UILIB.Button className="button-primary" onClick={this.addForm} text={i18n.t('form:index.add')} iconLeft={<UILIB.Icons icon="plus" />} />
        </div>
        return <div>
            {(canEdit == true) && <div className="mar-b25 text-right">
                <UILIB.Button className="button-primary" onClick={this.addForm} text={i18n.t('form:index.add')} iconLeft={<UILIB.Icons icon="plus" />} />
            </div>}

            <UILIB.DataTable1
                noResultsTxt={noResultsTxt}
                tableData={this.state.forms}
                loadingData={this.state.loading}
                width="100%"
                hasCheckBoxes="no"
                className="with-image"
            />

        </div>

    };
};
import React, { useState, useEffect, useRef } from "react";
import UILIB from "~/Common-Objects/Lib";
import InboxLeftSidebar from "./components/leftSidebar";
import ConversationHolder from "./components/conversation";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "~/data/http/axios";
import axios2 from "axios";
import * as styles from "./index.module.scss";
import queryString from 'query-string';
import i18n from "~/i18n";
import useInnerHeight from "../../../../Common-Objects/hooks/UseInnerHeight";

export default function InboxPage({
    activeInbox = {},
    inboxes = [],
    staff = [],
    apps = [],
    changeInbox = () => { },
    setAddingInbox = () => { },
    setNewMessages = () => { },
    userRole = null
}) {
    const history = useHistory();
    const user = useSelector(state => state.user);

    const { activeConversationId } = useParams();
    const [doingWizard, setDoingWizard] = useState(false);
    const [aiEnabled, setAiEnabled] = useState(false);
    const [totalConversationsToShow, setTotalConversationsToShow] = useState(0)

    const [firstLoad, setFirstLoad] = useState(true);
    const [loadingConversations, setLoadingConversations] = useState(true);
    const [loadingConversation, setLoadingConversation] = UILIB.useCallback(true);

    const [conversations, setConversations] = useState([]);
    const [totalConversations, setTotalConversations] = useState(0);
    const [activeConversation, setActiveConversation] = useState({});
    const [conversationsStatusFilter, setConversationsStatusFilter] = useState(queryString.parse(location.search)?.status || "unassigned");
    const [conversationsOrder, setConversationsOrder] = useState("newest");
    const [conversationsSearchText, setConversationsSearchText] = useState("");
    const [creatingConversation, setCreatingConversation] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [changingStatus, setChangingStatus] = useState(false)
    const [mainChatLoading, setMainChatLoading] = useState(false)

    const [currentConversationsPage, setCurrentConversationsPage] = useState(0)
    const [changingPage, setChangingPage] = useState(false)

    const height = useInnerHeight()

    const conversationTimer = useRef(null);
    const cancelToken = useRef(axios2.CancelToken)
    const source = useRef(null)
    const searchTimer = useRef(null)



    const clearTimers = () => {
        if (source.current) {
            source.current.cancel('cancelled')
        }
        if (conversationTimer.current) {
            clearTimeout(conversationTimer.current);
        }
        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
        }
    }

    useEffect(() => {
        return () => {
            if (source.current) {
                source.current.cancel('cancelled')
            }
            if (conversationTimer.current) {
                clearTimeout(conversationTimer.current);
            }
            if (searchTimer.current) {
                clearTimeout(searchTimer.current);
            }
        }
    }, [])

    useEffect(() => {
        if (firstLoad) return;
        updateConversations()
    }, [height])

    useEffect(() => {
        initialiseInboxes();
    }, [activeInbox.id])

    useEffect(() => {
        clearTimers();
        startConversationsTimer()
        setChangingStatus(true);
    }, [activeInbox.id, firstLoad, activeConversationId, activeConversation, conversationsStatusFilter, conversationsOrder, conversationsSearchText]);

    useEffect(() => {
        updateConversations();
    }, [activeConversationId])

    useEffect(() => {
        if (firstLoad) return;
        if (activeConversation.id == activeConversationId || activeConversationId == "new") return;
        changedConversation();
    }, [activeConversation]);

    useEffect(() => {
        if (firstLoad) return;
        setCurrentConversationsPage(0);
        updateConversations(true);
    }, [conversationsStatusFilter, conversationsOrder])

    useEffect(() => {
        if (firstLoad) return;
        if (!conversationsSearchText) updateConversations(true)
        else {
            if (searchTimer.current) clearTimeout(searchTimer.current)
            searchTimer.current = setTimeout(() => {
                updateConversations(true)
            }, 500);
        }
    }, [conversationsSearchText])

    useEffect(() => {
        if (!isLoading) {
            updateConversations();
        }
    }, [currentConversationsPage])


    const startConversationsTimer = () => {
        if (conversationTimer.current) clearTimeout(conversationTimer.current);
        conversationTimer.current = setTimeout(() => {
            if (conversationsStatusFilter === "closed" || conversationsStatusFilter === "spam") return;
            updateConversations();
            startConversationsTimer();
        }, 10000);
    }


    const initialiseInboxes = async () => {
        setFirstLoad(true);
        await updateConversations();

    };
    const updateConversations = async (closing) => {
        clearTimers();
        setIsLoading(true)
        setHasMore(false)
        let tmpActiveConversationId = activeConversationId;
        if (activeConversationId == "new") {
            if (firstLoad) return history.push(`/cp/conversations/inbox/${activeInbox.id}`);
            return;
        }

        const wasFirstLoad = firstLoad
        if (wasFirstLoad || closing) {
            setLoadingConversation(true);
            setLoadingConversations(true);
            setFirstLoad(false);
        }
        if (closing) tmpActiveConversationId = undefined;
        let statusFilter = conversationsStatusFilter;

        let currConversation = {};
        if (tmpActiveConversationId) {
            //we have a conversation in the url, check it out
            currConversation = await loadConversation(tmpActiveConversationId);
            if (wasFirstLoad && currConversation) {
                if (currConversation.status == 0 || currConversation.status == 1) {
                    if (currConversation.AccountUserId) {
                        if (currConversation.AccountUserId == user.userID) {
                            statusFilter = "mine";
                        } else {
                            statusFilter = "assigned";
                        }
                    } else {
                        statusFilter = "unassigned";
                    }
                }
                if (currConversation.status == 2) statusFilter = "closed";
            }
        }

        if (activeInbox.settings?.wizard) setDoingWizard(true);
        if (activeInbox.InboxAis && activeInbox.InboxAis.filter(a => a.status == 3).length > 0) setAiEnabled(true);

        let findPageConversationId;
        if (firstLoad && activeConversationId) findPageConversationId = activeConversationId;

        const _conversationsData = await loadConversations(activeInbox.id, statusFilter, findPageConversationId);
        const incomingChatData = _conversationsData?.chats || [];
        const totalChats = _conversationsData?.totalCount || 0;
        setTotalConversations(totalChats);
        if (_conversationsData.page) setCurrentConversationsPage(_conversationsData.page)

        setChangingPage(false);

        if (incomingChatData.length && !currConversation?.id) {
            setLoadingConversation(true);
            currConversation = incomingChatData[0];
            history.push(`/cp/conversations/inbox/${activeInbox.id}/${currConversation.id}?status=${conversationsStatusFilter}`);
        }
        if (!incomingChatData.length && activeConversationId && !currConversation.id) {
            history.push(`/cp/conversations/inbox/${activeInbox.id}?status=${conversationsStatusFilter}`);
        }
        if (incomingChatData.length > conversations.length && !wasFirstLoad && statusFilter !== 'closed') setNewMessages(incomingChatData.length - conversations.length)
        else setNewMessages(false)
        // if (activeConversationId && !currConversation.id) return history.push(`/cp/conversations/inbox/${activeInbox.id}`);

        if (incomingChatData.length) setHasMore(true)
        else setHasMore(false)

        setConversations(incomingChatData);
        setActiveConversation(currConversation);
        setLoadingConversations(false);
        setConversationsStatusFilter(statusFilter);
        setLoadingConversation(false);
        setIsLoading(false)
        setChangingStatus(false);
    };
    const changedConversation = () => {
        setLoadingConversation(false);
    }
    //loading stuff
    const loadConversations = async (inboxId, statusFilter, findPageConversationId) => {
        try {
            const _totalConversationsToShow = Math.floor((height - 121 - 65 - 49) / 68);
            setTotalConversationsToShow(_totalConversationsToShow)
            if (!inboxId) inboxId = activeInbox.id;
            if (!statusFilter) statusFilter = conversationsStatusFilter;
            let offset = currentConversationsPage * _totalConversationsToShow

            let newSource;
            if (source.current) {
                source.current.cancel('cancelled')
                newSource = cancelToken.current.source()
                source.current = newSource
            }

            let url = `/inboxes/chat?InboxId=${inboxId}&status=${statusFilter}&order=${conversationsOrder}&searchText=${conversationsSearchText}&limit=${_totalConversationsToShow}&offset=${offset}`;
            if (findPageConversationId) url += `&findPageId=${findPageConversationId}`

            const resp = await axios.get(
                url,
                { cancelToken: newSource?.token }
            )

            return resp.data;
        }
        catch (error) {
            if (!error) return []
            history.push("/cp");
        }
    }
    const loadConversation = async (conversationId) => {
        try {
            let conversation = await axios.get(`/inboxes/chat/${conversationId}`);
            conversation = conversation.data;
            return conversation;
        }
        catch (error) {
            history.push("/cp");
        }
    }
    //modifiers
    const changeConversation = async (conversationId) => {
        if (conversationId == activeConversation.id) return;
        setCreatingConversation({});
        await setLoadingConversation(true);
        history.push(`/cp/conversations/inbox/${activeInbox.id}/${conversationId}?status=${conversationsStatusFilter}`);
    }
    const changeFilters = async (status, order, searchText) => {
        setCurrentConversationsPage(0)
        if (status) {
            if (status == conversationsStatusFilter) return;
            setLoadingConversations(true);
            setConversationsStatusFilter(status);
            setActiveConversation({});
        }
        if (order) setConversationsOrder(order);
        if (searchText != undefined) {
            setConversationsSearchText(searchText);
        }
    }
    const createConversation = async (contactId, emailAddress) => {
        setActiveConversation({});
        setCreatingConversation({ subscriberId: contactId, emailAddress });
        history.push(`/cp/conversations/inbox/${activeInbox.id}/new?status=${conversationsStatusFilter}`);
    }
    const createdConversation = async (conversationId) => {
        setCurrentConversationsPage(0)
        setCreatingConversation({});
        changeConversation(conversationId)
    }

    const changeConversationsPage = (page) => {
        setChangingPage(true)
        setCurrentConversationsPage(page)
    }

    if (firstLoad) return <UILIB.LoadingIcons />

    const hasActiveConversation = (activeConversation && activeConversation.id) || (creatingConversation && creatingConversation.subscriberId);

    return (
        <div className={`inbox-page ${styles.root} ${!hasActiveConversation ? styles.rootEmpty : ""}`}>
            <InboxLeftSidebar
                className="inbox-page__left-sidebar"
                inboxes={inboxes}
                activeInbox={activeInbox}
                changeInbox={changeInbox}
                conversations={conversations}
                activeConversation={activeConversation}
                changeConversation={changeConversation}
                conversationsStatusFilter={conversationsStatusFilter}
                conversationsOrder={conversationsOrder}
                conversationSearchText={conversationsSearchText}
                changeFilters={changeFilters}
                createConversation={createConversation}
                doingWizard={doingWizard}
                setAddingInbox={setAddingInbox}
                aiEnabled={aiEnabled}
                isLoading={isLoading}
                hasMore={hasMore}
                changingStatus={changingStatus || mainChatLoading}
                loadingConversations={loadingConversations}
                userRole={userRole}
                totalConversations={totalConversations}
                changeConversationsPage={changeConversationsPage}
                currentConversationsPage={currentConversationsPage}
                totalConversationsToShow={totalConversationsToShow}
                changingPage={changingPage}
            />

            {!loadingConversations && !hasActiveConversation && <>
                <div className="inbox-page__center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className={styles.emptyState}>
                        <div className={styles.emptyStateIconWrapper}>
                            <UILIB.Icons icon="thumbsUp" />
                        </div>
                        <h3 className={styles.emptyStateTitle}>{i18n.t("chat:inbox.conversation.emptyHeader")}</h3>
                        <div className={styles.emptyStateDescription}>{i18n.t("chat:inbox.conversation.emptySubheader")}</div>
                    </div>
                </div>
            </>

            }

            {loadingConversations && <div className="inbox-page__center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <UILIB.LoadingIcons />
                </div>
            </div>}

            {!loadingConversations && hasActiveConversation && !loadingConversation && <ConversationHolder
                hasActiveConversation={hasActiveConversation}
                creatingConversation={creatingConversation}
                createdConversation={createdConversation}
                activeConversation={activeConversation}
                changeConversation={changeConversation}
                updateConversations={updateConversations}
                activeInbox={activeInbox}
                staff={staff}
                doingWizard={doingWizard}
                apps={apps}
                key={"conv_" + activeConversationId}
                userRole={userRole}
                setMainChatLoading={setMainChatLoading}
            />}



        </div>
    );
}

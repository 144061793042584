import React, { useState, memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { handleGenericError, alterSnackbar } from "~/data/actions/siteActions";
import UILIB from "~/Common-Objects/Lib";
import DateTimeFunctions from "~/Classes/dateTimeFunctions";
import SettingsSection from "../../../components/settingsSection";
import Button from "../../../components/button";
import * as styles from "./index.module.scss";
import Header from "../../../components/header";
import i18n from "~/i18n";

export default function WebchatChannelAgentAvailability({
  channel,
  onUpdateChannel,
}) {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [agentAvailability, setAgentAvailability] = useState(
    channel.settings.availabilityType.toString()
  );
  const [schedule, setSchedule] = useState(
    channel.settings.availabilitySchedule
  );
  const [timezone, setTimezone] = useState(channel.settings.timezone);

  function addSchedule() {
    setSchedule([
      ...schedule,
      {
        day: 60,
        from: "09:00",
        to: "17:00",
      },
    ]);
  }

  function updateSchedule(key, val, index) {
    const updated = schedule.map((pattern, i) => {
      if (index === i) {
        return { ...pattern, [key]: val };
      }

      return pattern;
    });

    setSchedule(updated);
  }

  function duplicateSchedule(index) {
    const updated = [
      ...schedule.slice(0, index),
      schedule[index],
      ...schedule.slice(index),
    ];

    setSchedule(updated);
  }

  function deleteSchedule(index) {
    const filtered = schedule.filter((_, i) => i !== index);

    setSchedule(filtered);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitting(true);

    const data = {
      settings: {
        availabilityType: Number(agentAvailability),
        availabilitySchedule: schedule,
        timezone,
      },
    };

    try {
      await onUpdateChannel(data);
      dispatch(alterSnackbar(true, i18n.t("chat:channel.chat.agentAvailability.saved")));
    } catch (error) {
      dispatch(handleGenericError(error));
    } finally {
      setSubmitting(false);
    }
  }

  const timezonesSelectOptions = useMemo(() => {
    return DateTimeFunctions.getTimeZones().map((timezone) => {
      return { value: timezone, label: timezone };
    });
  }, [DateTimeFunctions]);

  const agentAvailabilitySelectOptions = [
    { label: i18n.t("chat:channel.chat.agentAvailability.options.users"), value: "0" },
    { label: i18n.t("chat:channel.chat.agentAvailability.options.schedule"), value: "1" },
  ];

  const daySelectOptions = [
    { label: i18n.t("chat:channel.chat.agentAvailability.schedule.select.every"), value: "50" },
    { label: i18n.t("chat:channel.chat.agentAvailability.schedule.select.weekdays"), value: "60" },
    { label: i18n.t("chat:channel.chat.agentAvailability.schedule.select.weekends"), value: "70" },
    { label: i18n.t("chat:channel.chat.agentAvailability.schedule.select.monday"), value: "1" },
    { label: i18n.t("chat:channel.chat.agentAvailability.schedule.select.tuesday"), value: "2" },
    { label: i18n.t("chat:channel.chat.agentAvailability.schedule.select.wednesday"), value: "3" },
    { label: i18n.t("chat:channel.chat.agentAvailability.schedule.select.thursday"), value: "4" },
    { label: i18n.t("chat:channel.chat.agentAvailability.schedule.select.friday"), value: "5" },
    { label: i18n.t("chat:channel.chat.agentAvailability.schedule.select.saturday"), value: "6" },
    { label: i18n.t("chat:channel.chat.agentAvailability.schedule.select.sunday"), value: "0" },
  ];

  return (
    // Purposely not putting the onSubmit on the form as it feels weird to have enter submit this form.
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 inbox_settings_left_content">
      <Header type="webchat" option="agent-availability" />
      <form className={styles.root}>
        <SettingsSection heading={i18n.t("chat:channel.chat.agentAvailability.availability")}>
          <div className={styles.halfWidthWrapper}>
            <UILIB.SelectNewDefault
              value={agentAvailability}
              data={agentAvailabilitySelectOptions}
              fullWidth
              onValueChange={setAgentAvailability}
            />
          </div>
        </SettingsSection>
        <hr />

        {agentAvailability === "1" && (
          <>
            <SettingsSection
              heading={i18n.t("chat:channel.chat.agentAvailability.schedule.header")}
              subheading={i18n.t("chat:channel.chat.agentAvailability.schedule.subheader")}
            >
              {schedule.length > 0 && (
                <div className={styles.scheduleWrapper}>
                  {schedule.map((pattern, index) => {
                    const { day, from, to } = pattern;

                    return (
                      <div key={index} className={styles.inputWithInlineActions}>
                        <UILIB.SelectNewDefault
                          value={day.toString()}
                          data={daySelectOptions}
                          fullWidth
                          onValueChange={(val) =>
                            updateSchedule("day", val, index)
                          }
                        />

                        <MemoizedSelectNewDefault
                          value={from}
                          data={hoursSelectOptions}
                          onValueChange={(val) =>
                            updateSchedule("from", val, index)
                          }
                        />

                        <MemoizedSelectNewDefault
                          value={to}
                          data={hoursSelectOptions}
                          onValueChange={(val) =>
                            updateSchedule("to", val, index)
                          }
                        />

                        <Button
                          variant="ghost"
                          size="s"
                          iconOnly
                          aria-label="Duplicate"
                          onClick={() => duplicateSchedule(index)}
                        >
                          <UILIB.Icon name="copy" />
                        </Button>

                        <Button
                          variant="ghost"
                          size="s"
                          iconOnly
                          aria-label="Delete"
                          onClick={() => deleteSchedule(index)}
                        >
                          <UILIB.Icon name="trash" />
                        </Button>
                      </div>
                    );
                  })}
                </div>
              )}

              <Button
                variant="secondary"
                size="s"
                iconLeft={<UILIB.Icon name="plus" />}
                onClick={addSchedule}
              >
                {i18n.t("chat:channel.chat.agentAvailability.schedule.add")}
              </Button>
            </SettingsSection>
            <hr />
          </>
        )}

        {/* TODO: moment is used to generate the hours options which also appears
      to be causing performance issues. I've done some memoization which
      improves performance slightly, but we'll need to come up with an
      alternative to rendering every locale, and probably migrate away from
      moment now that it is no longer actively maintained. */}
        <SettingsSection heading={i18n.t("chat:channel.chat.agentAvailability.timezone")}>
          <div className={styles.halfWidthWrapper}>
            <MemoizedSelectNewDefault
              value={timezone}
              data={timezonesSelectOptions}
              onValueChange={setTimezone}
            />
          </div>
        </SettingsSection>
        <hr />

        <Button
          className={styles.submitButton}
          type="submit"
          size="s"
          loading={submitting}
          onClick={handleSubmit}
        >
          {i18n.t("chat:channel.chat.agentAvailability.save")}
        </Button>
      </form>
    </div>
  );
}

let hoursSelectOptions = [];
let datetime = moment().startOf("day");

while (datetime < moment().endOf("day")) {
  hoursSelectOptions.push({
    label: datetime.format("HH:mm"),
    value: datetime.format("HH:mm"),
  });

  datetime.add(30, "minutes");
}

const MemoizedSelectNewDefault = memo(({ ...rest }) => {
  return <UILIB.SelectNewDefault fullWidth {...rest} />;
});

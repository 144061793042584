import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';
import axios from '~/data/http/axios';
import moment from 'moment';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class resendToNonOpeners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.confirm = this.confirm.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    componentDidMount() {
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    confirm() {
        this.props.resendToNonOpenersGo(this.props.campaign);
    }


    render() {

        let error = "";
        if (moment().diff(this.props.campaign.finishedDate, "hours") < 24) {
            let sinceFinished = 24 - moment().diff(this.props.campaign.finishedDate, "hours");
            error = i18n.t('campaigns:overview.resendPleaseWait', { hours: sinceFinished });
        }
        if (this.props.campaign.options && this.props.campaign.options.resentToNonSubsDate) {
            error = i18n.t('campaigns:overview.resendPreviouslySent')
        }
        return <div>
            <h4 className="mar-b25">{i18n.t('campaigns:overview.resendNonOpenerHeader')}</h4>
            {error && <>
                <div className="mar-b25">{error}</div>
            </>}
            {!error && <><div className="mar-b25">{i18n.t('campaigns:overview.resendNonOpenerDesc')}</div>
                <div className="quickFlex">
                    <UILIB.Button text={i18n.t('continue')} className="button-primary mar-r10" onClick={this.confirm} />
                    <UILIB.Button text={i18n.t('cancel')} className="" onClick={this.closeDrawer} />
                </div>
            </>}


        </div>

    }
};



import React, { Component } from 'react'
import DragDropFunctions from '../../../dragDropFunctions';
import { DropTarget, DragSource } from 'react-dnd'
import ItemTypes from '../../../dragDropItemTypes'
import { findDOMNode } from 'react-dom'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib';
import i18n from '~/i18n';

const dragSource = {
    beginDrag(props, monitor) {
        return { path: props.path }
    }
}

const dropSource = {
    drop() {
        return {}
    },
    hover(props, monitor, component) {
        const currentElementType = monitor.getItemType();

        if (currentElementType === "newHolder" && monitor.getItem().isNew === 0) {
            const currentElementPath = monitor.getItem().path;
            const currentElementContent = monitor.getItem().content;
            const hoveredElementPath = props.path;

            var newIndex = Number(hoveredElementPath.substring(hoveredElementPath.lastIndexOf("[")).replace('[', '').replace(']', '')) + 1;
            var newPath = hoveredElementPath.substring(0, hoveredElementPath.lastIndexOf(".")) + ".elements[" + newIndex + "]"
            if (!currentElementContent.tuuid) {
                currentElementContent.tuuid = DragDropFunctions.createGUID();
            }
            monitor.getItem().path = newPath;
            monitor.getItem().isNew = 1;
            props.insertElement(hoveredElementPath, currentElementContent, false);
            console.log("Return Div Canvas")
            return;
        }
        if (currentElementType === "canvasHolder" || (currentElementType === 'newHolder' && monitor.getItem().isNew === 1)) {
            const currentElementPath = monitor.getItem().path;
            const hoveredElementPath = props.path;

            if (currentElementPath === hoveredElementPath) {
                return
            }

            const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top

            const direction = DragDropFunctions.movingUpDown(currentElementPath, hoveredElementPath);

            // Dragging downwards
            if (direction == 1 && hoverClientY < hoverMiddleY) {
                return
            }

            // // Dragging upwards
            if (direction == -1 && hoverClientY > hoverMiddleY) {
                return
            }


            props.moveElement(currentElementPath, hoveredElementPath, false);
            monitor.getItem().path = hoveredElementPath;
        }
    }
}

@DropTarget([ItemTypes.CANVASHOLDER, ItemTypes.NEWHOLDER], dropSource, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget()
}))

@DragSource(ItemTypes.CANVASHOLDER, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview()
}))

//CONNECT TO STORE 
@connect((store) => {
    return { user: store.user }
})

export default class DivCanvasHolder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mouseOver: false
        }
        this.clickHolder = this.clickHolder.bind(this);
        this.mouseOver = this.mouseOver.bind(this);
        this.mouseOut = this.mouseOut.bind(this);
        this.saveBlock = this.saveBlock.bind(this);
    }
    clickHolder(event) {
        event.stopPropagation();
        this.props.clickElement(this.props.path)
    }
    mouseOver(event) {
        event.stopPropagation();
        this.setState({ mouseOver: true });
    }
    mouseOut(event) {
        this.setState({ mouseOver: false })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ mouseOver: false })
    }

    saveBlock() {

        var theOptions = {
            "type": "holder",
            "title": "Unnamed Block",
            "content":
                this.props.elementData,
            "elementId": 0
        }

        var saveProps = {};
        saveProps.name = "New Block";
        saveProps.options = theOptions;
        saveProps.componentType = 2;


        axios
            .post('/templateComponent', saveProps)
            .then(function (res) {
                // alert("SAVED");
            })
    }

    render() {

        const { canDrop, isOver, connectDropTarget, connectDragSource, isDragging, moveElement, connectDragPreview } = this.props

        var isSelected = false;
        var isSelectedClass = "";

        if (this.props.selectedElement.path === this.props.path) {
            isSelected = true;
            isSelectedClass = "dd-element-selected";
        }
        if (this.state.mouseOver && isDragging != true) {
            isSelectedClass = "dd-element-selected";
        }
        if (this.props.showGuideLines) {
            isSelectedClass = "showGuideLines " + isSelectedClass
        }
        //figure out if we are the last element holder
        var hasOtherCanvasasData = JSON.stringify(this.props.elementsData);
        var hasOtherCanvasas = 0;
        var oCfoundAtPosition = 0;
        var oCcount = 0;
        while (oCfoundAtPosition != -1 && hasOtherCanvasas < 2) {
            oCfoundAtPosition = hasOtherCanvasasData.indexOf("canvasHolder", oCfoundAtPosition);
            if (oCfoundAtPosition != -1) {
                oCcount++;
                oCfoundAtPosition++;
            }
        }
        hasOtherCanvasas = oCcount;

        var eleProps;
        if (this.props.elementData.props) {
            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "table", this.props.localVariables)
        }
        return connectDropTarget(connectDragPreview(<div className="elementHolder" onClick={this.clickHolder} onMouseOver={this.mouseOver} onMouseOut={this.mouseOut}>
            <div className={"elementHolderTools " + isSelectedClass}>
                {isSelected &&
                    <div className="ui-dd-tools ui-dd-tools-top">

                        <div className="ui-dd-tools-icon">
                            <UILIB.Icons icon="copyClipboard"
                                color="white"
                                style={{ width: "18px", height: "18px" }}
                                alt={i18n.t('copy')}
                                title={i18n.t('copy')}
                                onClick={(event) => { event.stopPropagation(); this.props.copyElement(this.props.path) }}
                            />
                        </div>
                        {hasOtherCanvasas > 1 && <div className="ui-dd-tools-icon">
                            <UILIB.Icons
                                icon="bin"
                                color="white"
                                style={{ width: "18px", height: "18px" }}
                                alt={i18n.t('delete')}
                                title={i18n.t('delete')}
                                onClick={(event) => { event.stopPropagation(); this.props.deleteElement(this.props.path) }}
                            />
                        </div>}
                        {this.props.user.userID === 6 && <div className="ui-dd-tools-icon">
                            <UILIB.Icons
                                icon="cog"
                                color="white"
                                style={{ width: "18px", height: "18px" }}
                                alt={i18n.t('exclude')}
                                title={i18n.t('exclude')}
                                onClick={(event) => { event.stopPropagation(); this.saveBlock() }}
                            />
                        </div>
                        }

                    </div>
                }
                {isSelected && <div className="ui-dd-tools ui-dd-tools-left" alt="Move" title="Move" >
                    {connectDragSource(<div className="ui-dd-tools-icon" >
                        <UILIB.Icons icon="move"
                            color="white"
                            style={{ width: "15px", height: "15px" }}
                            alt="Move"
                            title="Move" />
                    </div>)}
                </div>}

            </div>

            <div {...eleProps}>

                {this.props.children}

            </div>
        </div>
        ))
    }
}



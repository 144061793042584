import React from 'react'
import * as styles from './index.module.scss'
import * as conversations from '~/Classes/conversations.js'
import i18n from '~/i18n'

export default function Header({ type, option, heading, subheading }) {
    let arr, found
    if (type && option) {
        switch (type) {
            case 'webchat': arr = conversations.webchatChannelTabOptions; break;
            case 'email': arr = conversations.emailChannelTabOptions; break;
            case 'inbox': arr = conversations.inboxTabOptions; break;
            case 'ai': arr = conversations.aiAssistantTabOptions; break;
            case 'facebookMessenger': arr = conversations.facebookMessengerChannelTabOptions; break;
        }
    }
    if (arr) found = arr.find(a => a.value === option)
    return (
        <header className={styles.header}>
            <h1 className="h2">{i18n.t(found?.label || heading)}</h1>
            <p className="text-grey mar-b0">{i18n.t(found?.subheading || subheading)}</p>
        </header>
    )
}
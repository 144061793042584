import React from 'react'
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import spamFunctions from './spamFunctions';
//CONNECT TO STORE

@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster }
})

export default class SpamTesterAdvanced extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }


    }

    render() {
        var tableData = [];
        if (this.props.results && this.props.results.mailHeaders && Object.keys(this.props.results.mailHeaders).length) {
            Object.keys(this.props.results.mailHeaders).forEach(header => {
                let headerName = header;
                let headerValue = this.props.results.mailHeaders[header];
                let isBadResult = spamFunctions.badHeaderCheck(headerName, headerValue)

                let headerStyle = {};
                let warningTxt;
                if (isBadResult.failed) {
                    headerStyle.backgroundColor = "red";
                    headerStyle.color = "white";
                    warningTxt = <UILIB.WarningHolder>
                        {isBadResult.message}
                    </UILIB.WarningHolder>
                }

                var tmpData = {
                    headerName: {
                        headerValue: "Header",
                        value: <span style={{ headerStyle }}>{header}</span>
                    },
                    warning: {
                        headerValue: "Advice",
                        value: warningTxt
                    },
                    val: {
                        headerValue: "Value",
                        value: <span>
                            {this.props.results.mailHeaders[header]
                            }</span >
                    }
                }
                tableData.push(tmpData)
            })
        }
        return <div>
            <UILIB.DataTable1 noResultsTxt={"No results yet"} tableData={tableData} loadingData={false} width="100%" pageSize="100" hasCheckBoxes="no" />

        </div>
    }
}

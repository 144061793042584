import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';
import copy from 'copy-to-clipboard';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFormatFunctions from '~/Classes/numberFormatFunctions';
import axios from '~/data/http/axios'
import CustomersView from './includes/customers'
import CustomerPaymentsView from './includes/customerPayments'
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class Affiliate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            affiliateLink: "https://" + window.location.hostname + "/register?aff=" + this.props.user.accountUserId,
            alertOpen: false,
            alertMessage: "",
            myAccount: {},
            stats: {
                customers: 0,
                customerPayments: 0,
                customerPaymentsDue: 0,
                customerPaymentsPaid: 0
            },
            pageLoaded: false,
            termsAccepted: false
        };

        this.updateStats = this.updateStats.bind(this);
        this.loadAccount = this.loadAccount.bind(this);
        this.updatePaypal = this.updatePaypal.bind(this);
        this.saveAccount = this.saveAccount.bind(this);
        this.acceptTerms = this.acceptTerms.bind(this);
    }

    componentDidMount() {
        this.loadAccount();
    }

    async loadAccount() {
        let account = await axios.get('/accountUser/me')
        account = account.data.AccountUserData;
        let termsAccepted = account.firstLoad && account.firstLoad.affiliateTermsAccepted ? true : false
        this.setState({ myAccount: account, pageLoaded: true, termsAccepted })
    }

    updateInput(ev) {
        this.setState({ [ev.currentTarget.name]: ev.currentTarget.value })
    }

    copyToClipboard() {
        this.setState({
            alertOpen: true,
            alertMessage: 'copied to clipboard'
        })
        copy(this.state.affiliateLink)
    }

    updateStats(type, value) {
        var state = this.state;
        state.stats[type] = value
        this.setState(state)
    }

    updatePaypal(event) {
        var myAccount = this.state.myAccount;
        myAccount.affiliatePaypalEmail = event.target.value;
        this.setState({ myAccount: myAccount })
    }

    acceptTerms() {
        var myAccount = this.state.myAccount;
        myAccount.affiliateTermsAccepted = true;
        this.setState({ myAccount: myAccount, termsAccepted: true }, this.saveAccount)
    }
    saveAccount() {
        var self = this;
        this.setState({ alertMessage: "Saving Details", alertOpen: true })
        axios.put('/accountuser', (this.state.myAccount)).then((res) => {
            self.setState({ alertMessage: "Details Saved", alertOpen: true })
        }).catch(error => {
        })
    }

    render() {
        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />

        let acceptedTerms = this.state.termsAccepted;

        return <UILIB.Paper>

            <div className="page-header mar-b25">{i18n.t('account:affiliate.overview.header')}</div>

            <UILIB.Row>
                {!acceptedTerms && <UILIB.Column xs={12} className="mar-b25">
                    <UILIB.Paper className="paper-red">
                        <h4 className="mar-b10">Terms &amp; Conditions</h4>
                        <div className="mar-b15">
                            Please read the <a style={{ color: "white" }} href="https://transpond.io/affiliate_terms" target="_blank">Affiliate Terms and Conditions</a> and once happy click the "Accept Terms" button below to activate your Affiliate account.
                        </div>
                        <UILIB.Button text="I Accept the Affiliate Terms" onClick={this.acceptTerms} />
                    </UILIB.Paper>
                </UILIB.Column>}

                <UILIB.Column xs={12} sm={6} className="mar-b25">
                    <UILIB.Paper style={{ height: "100%" }}>
                        <h4 className="mar-b5" >{i18n.t('company:affiliate.link')}</h4>
                        <p >{i18n.t('company:affiliate.shareLink')}</p>
                        <UILIB.TextInput
                            value={this.state.affiliateLink}
                            iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => { this.copyToClipboard() }} />}
                            readOnly={true} />
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} className="mar-b25">
                    <UILIB.Paper style={{ height: "100%" }}>
                        <h4 className="mar-b5">{i18n.t('company:affiliate.Resources')}</h4>
                        <p >{i18n.t('company:affiliate.resourceInfo')}</p>
                        <UILIB.Button text={i18n.t('company:affiliate.soon')} onClick={this.viewResources} disabled={true} className="secondary" />
                    </UILIB.Paper>
                </UILIB.Column >

                <UILIB.Column xs={12} sm={8}>
                    <UILIB.Paper>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <h4 className="mar-b5">{i18n.t('company:affiliate.costumers')}</h4>
                                <p>{i18n.t('company:affiliate.costumerinfo')}</p>
                            </div>
                            <div style={{ fontSize: "30px" }}>{this.state.stats.customers} 🧍</div>
                        </div>
                        <CustomersView updateStats={this.updateStats} />
                    </UILIB.Paper>

                    <UILIB.Paper>

                        <h4 className="mar-b5">{i18n.t('company:affiliate.payment')}</h4>
                        <p>{i18n.t('company:affiliate.veiwpayments')}</p>

                        <CustomerPaymentsView updateStats={this.updateStats} />
                    </UILIB.Paper>


                </UILIB.Column>
                <UILIB.Column xs={12} sm={4}>


                    <UILIB.Paper className="text-center">
                        <div className="mar-b10">{i18n.t('company:affiliate.com')}</div>
                        <div style={{ fontSize: "30px" }}>&pound;{NumberFormatFunctions.formatNumber(this.state.stats.customerPayments, 2)}</div>
                    </UILIB.Paper>

                    <UILIB.Row>
                        <UILIB.Column xs={12} sm={6} >
                            <UILIB.Paper className="text-center">
                                <div className="mar-b10">{i18n.t('company:affiliate.paid')}</div>
                                <div style={{ fontSize: "30px" }}>&pound;{NumberFormatFunctions.formatNumber(this.state.stats.customerPaymentsPaid, 2)}</div>
                            </UILIB.Paper>
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={6} >
                            <UILIB.Paper className="text-center">
                                <div className="mar-b10">{i18n.t('company:affiliate.Upcoming')}</div>
                                <div style={{ fontSize: "30px" }}>&pound;{NumberFormatFunctions.formatNumber(this.state.stats.customerPaymentsDue, 2)}</div>
                            </UILIB.Paper>
                        </UILIB.Column>
                    </UILIB.Row>

                    <UILIB.Paper>
                        <h4 className="mar-b5">{i18n.t('company:affiliate.paymentdet')}</h4>
                        <p>{i18n.t('company:affiliate.enterdet')}</p>
                        <UILIB.TextInput outerClassName="mar-b10" name="affiliatePaypalEmail" error={(!this.state.myAccount.affiliatePaypalEmail || this.state.myAccount.affiliatePaypalEmail.length < 5) ? i18n.t('company:affiliate.broke') : ''} value={this.state.myAccount.affiliatePaypalEmail} onChange={this.updatePaypal} placeholder="your.paypal@email.com" />
                        <UILIB.Button text="Save" className="button-primary" onClick={this.saveAccount} />
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row >
            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
        </UILIB.Paper >
    }

};
import React from "react";
import Icons from "../../Icons";

// Display chat bubbles in a conversation

export default function ChatMessage({
  sent,
  note,
  className,
  message,
  isMe = false,
  isAi = false,
  isAttachment = false,
  size,
  draft = false,
  download = () => { }
}) {
  let classes = "";

  if (isMe && !isAi) {
    classes += " chat-message--is-me";
  }

  if (isAi) {
    classes += " chat-message--is-ai";
  }

  if (note) {
    classes += " type--is-note";
  }

  if (isAttachment) {
    classes += " chat-message--is-attachment";
  }

  if (draft) {
    classes += " chat-message--is-draft";
  }

  if (isAttachment) {
    return <div className={`chat-message ${classes}`}>
      <div className="chat-message__attachment">
        <div className="chat-message__attachment__icon" onClick={download}>
          <Icons icon="download3" color="#453DD8" style={{ height: 30, width: 30 }} />
        </div>
        <div className="chat-message__attachment__info">
          <div>{message}</div>
          <div className="chat-message__attachment__info__size">{humanizeBytes(size)}</div>
        </div>
      </div>
    </div>
  }

  return (
    <>
      {isAi && <div className="chat-message__ai-indicator"><span>AI</span>Answer</div>}
      {draft && <div className="chat-message__draft-indicator">Draft</div>}
      <p
        className={`chat-message ${classes}`}
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>
    </>
  );
}

function humanizeBytes(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}
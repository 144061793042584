import React, { Component } from 'react';
import axios from '../../../../../../data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import ArrowDown from '~/assets/images/icons/nav-arrow-down.svg';

class selectFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            availableFields: {
                contact: [],
                lead: []
            },
            contactFilter: '',
            leadFilter: ''
        }
        this.toggleAllFields = this.toggleAllFields.bind(this)
        this.selectField = this.selectField.bind(this)
        this.filterFields = this.filterFields.bind(this)
        this.checkFields = this.checkFields.bind(this)
    }

    componentDidMount() {
        if (!this.props.appBinding.appSettings.fields) this.props.appBinding.appSettings.fields = {}

        if (!this.props.appBinding.appSettings.fields.contact) this.props.appBinding.appSettings.fields.contact = [
            { label: 'Email', value: 'emailaddress1', type: 'String' },
            { label: 'Contact', value: 'contactid', type: 'Uniqueidentifier' },
            { label: 'First Name', value: 'firstname', type: 'String' },
            { label: 'Last Name', value: 'lastname', type: 'String' }
        ]
        if (!this.props.appBinding.appSettings.fields.lead) this.props.appBinding.appSettings.fields.lead = [
            { label: 'Email', value: 'emailaddress1', type: 'String' },
            { label: 'Lead', value: 'leadid', type: 'Uniqueidentifier' },
            { label: 'First Name', value: 'firstname', type: 'String' },
            { label: 'Last Name', value: 'lastname', type: 'String' }
        ]

        axios.get('/application/dynamics/availablefields').then(response => {
            this.setState({
                availableFields: response.data
            }, this.checkFields)
        })
    }

    checkFields() {
        var available = this.state.availableFields
        this.props.appBinding.appSettings.fields.contact.forEach((field, index) => {
            if (typeof field === 'string') {
                var found = available.contact.find(f => f.value === field)
                if (found) {
                    this.props.appBinding.appSettings.fields.contact[index] = found
                }
            }
        })
        this.props.appBinding.appSettings.fields.lead.forEach((field, index) => {
            if (typeof field === 'string') {
                var found = available.lead.find(f => f.value === field)
                if (found) {
                    this.props.appBinding.appSettings.fields.lead[index] = found
                }
            }
        })
        this.setState({
            loading: false
        })
    }

    toggleAllFields(type, select) {
        var arr = this.props.appBinding.appSettings.fields ? this.props.appBinding.appSettings.fields[type] || [] : []
        if (select) {
            arr = this.state.availableFields[type].map(f => f.value)
        } else {
            arr = type = 'contact' ?
                [
                    { label: 'Email', value: 'emailaddress1', type: 'String' },
                    { label: 'Contact', value: 'contactid', type: 'Uniqueidentifier' }
                ] : [
                    { label: 'Email', value: 'emailaddress1', type: 'String' },
                    { label: 'Lead', value: 'leadid', type: 'Uniqueidentifier' }
                ]
        }
        if (!this.props.appBinding.appSettings.fields) this.props.appBinding.appSettings.fields = { contact: [], lead: [] }
        this.props.appBinding.appSettings.fields[type] = arr
        this.setState({})
    }

    selectField(type, field) {
        var arr = this.props.appBinding.appSettings.fields ? this.props.appBinding.appSettings.fields[type] || [] : []
        var index = arr.findIndex(a => (a.value || a) === field.value)

        if (index > -1) {
            arr.splice(index, 1)
        } else {
            arr.push(field)
        }
        if (!this.props.appBinding.appSettings.fields) this.props.appBinding.appSettings.fields = { contact: [], lead: [] }
        this.props.appBinding.appSettings.fields[type] = arr
        this.setState({})
    }

    filterFields(type, val) {
        this.setState({
            [type + 'Filter']: val
        })
    }

    render() {
        if (this.state.loading) return <UILIB.LoadingIcons iconType="2" />

        var currentContactFields = this.props.appBinding.appSettings.fields ? this.props.appBinding.appSettings.fields.contact ||
            [
                { label: 'Email', value: 'emailaddress1', type: 'String' }
            ] : [
            { label: 'Email', value: 'emailaddress1', type: 'String' },
            { label: 'First Name', value: 'firstname', type: 'String' },
            { label: 'Last Name', value: 'lastname', type: 'String' }
        ]
        var currentLeadFields = this.props.appBinding.appSettings.fields ? this.props.appBinding.appSettings.fields.lead ||
            [
                { label: 'Email', value: 'emailaddress1', type: 'String' }
            ] : [
            { label: 'Email', value: 'emailaddress1', type: 'String' },
            { label: 'First Name', value: 'firstname', type: 'String' },
            { label: 'Last Name', value: 'lastname', type: 'String' }
        ]

        var availableContactFields = this.state.availableFields.contact.map(field => {
            field.selected = currentContactFields.some(f => (f.value || f) === field.value)
            return field
        })

        var availableLeadFields = this.state.availableFields.lead.map(field => {
            field.selected = currentLeadFields.some(f => (f.value || f) === field.value)
            return field
        })

        var filteredContacts = availableContactFields.slice()
        var filteredLeads = availableLeadFields.slice()
        if (this.state.contactFilter) {
            filteredContacts = filteredContacts.filter(f => f.value.toLowerCase().indexOf(this.state.contactFilter.toLowerCase()) > -1 || f.label.toLowerCase().indexOf(this.state.contactFilter.toLowerCase()) > -1)
        }
        if (this.state.leadFilter) {
            filteredLeads = filteredLeads.filter(f => f.value.toLowerCase().indexOf(this.state.leadFilter.toLowerCase()) > -1 || f.label.toLowerCase().indexOf(this.state.leadFilter.toLowerCase()) > -1)
        }

        var lookups = this.state.availableFields.lead.filter(field => field.type === 'Lookup' && currentContactFields.indexOf(field.value) > -1)
        lookups.push(...this.state.availableFields.contact.filter(field => field.type === 'Lookup' && currentContactFields.indexOf(field.value) > -1 && lookups.findIndex(l => l.value === field.value) === -1))

        return (
            <div>
                <div className="text-md mar-b20">Contact Fields</div>
                <UILIB.ButtonSelect width={450} fixedHeightLarge={true} className="with-icon white mar-b10" icon={ArrowDown} headerText="Select Fields" filter={true} updateFilter={ev => this.filterFields('contact', ev)} selectAll={() => this.toggleAllFields('contact', true)} clearAll={() => this.toggleAllFields('contact', false)} >
                    {filteredContacts && !!filteredContacts.length && <ul>
                        {filteredContacts.map((field) => {
                            return <li key={field.value}><UILIB.CheckBox key={field.value} name={field.label} disabled={field.value === 'emailaddress1'} onChange={() => this.selectField('contact', field)} checked={field.selected}><span className="pad-left">{field.label + (field.value !== field.label ? ` (${field.value})` : '')}</span></UILIB.CheckBox></li>
                        })}
                    </ul>}
                </UILIB.ButtonSelect>
                <div className="mar-b30">
                    {currentContactFields.filter(f => availableContactFields.find(fi => fi.value === (f.value || f))).map(f => {
                        var field = availableContactFields.find(fi => fi.value === (f.value || f))
                        return <UILIB.SquareChip
                            key={"contact" + field.value}
                            tooltip={field.value}
                            for={field.value}
                            value={field.label}
                            className="mar-r5 mar-b5"
                        />
                    })}
                </div>
                <div className="text-md mar-b20">Lead Fields</div>
                <UILIB.ButtonSelect width={450} fixedHeightLarge={true} className="with-icon white mar-b10" icon={ArrowDown} headerText="Select Fields" filter={true} updateFilter={ev => this.filterFields('lead', ev)} selectAll={() => this.toggleAllFields('lead', true)} clearAll={() => this.toggleAllFields('lead', false)} >
                    {filteredLeads && !!filteredLeads.length && <ul>
                        {filteredLeads.map((field) => {
                            return <li key={field.value}><UILIB.CheckBox key={field.value} name={field.label} disabled={field.value === 'emailaddress1'} onChange={() => this.selectField('lead', field)} checked={field.selected}><span className="pad-left">{field.label + (field.value !== field.label ? ` (${field.value})` : '')}</span></UILIB.CheckBox></li>
                        })}
                    </ul>}
                </UILIB.ButtonSelect>
                <div className="mar-b30">
                    {currentLeadFields.filter(f => availableLeadFields.find(fi => fi.value === (f.value || f))).map(f => {
                        var field = availableLeadFields.find(fi => fi.value === (f.value || f))
                        return <UILIB.SquareChip
                            key={"lead" + field.value}
                            tooltip={field.value}
                            for={field.value}
                            value={field.label}
                            className="mar-r5 mar-b5"
                        />
                    })}
                </div>
                {!!lookups.length && <div>
                    <p>You have selected {lookups.length} fields that need to be looked up, please select which field to display from those lookups</p>
                    {lookups.map((lookup, index) => {
                        return <div key={index}>
                            {JSON.stringify(lookup, null, 2)}
                        </div>
                    })}
                </div>}
            </div>
        );
    }
}

export default selectFields;
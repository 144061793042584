import React, { Component } from 'react';
import { DropTarget, DragSource } from 'react-dnd'
import ItemTypes from './dndTypes'

const dragSource = {
    beginDrag(props, monitor) {
        return {
            serverId: props.server.id,
            currentGroupId: props.group.id,
            serverObject: props.server
        }
    }
}


@DragSource(ItemTypes.SERVER, dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview()
}))

class Server extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {
        const { canDrop, isOver, connectDropTarget, connectDragSource, isDragging, moveElement, connectDragPreview } = this.props

        var server = this.props.server;
        var isLive = 0;
        if (this.props.stage && this.props.group.liveMailServers.indexOf(server.id) > -1) {
            isLive = 1;
        }
        var style = {
            position: "relative", fontSize: "11px", padding: "10px", display: "inline-block", border: "1px solid grey", borderRadius: "5px", flex: "0 0 190px", margin: "5px 5px", height: "50px", cursor: "pointer", width: "180px", wordBreak: "break-all"
        }
        if (this.props.style) {
            if (this.props.style.width) {
                style.width = this.props.style.width
                style.flex = "0 0 " + this.props.width
            }
        }
        if (isLive) {
            style.border = "3px solid green"
        }

        return connectDragPreview(connectDragSource(<div style={style}>
            {this.props.stage && isLive == 1 && <div style={{ position: "absolute", top: "-6px", right: "0px", width: "10px", height: "10px", backgroundColor: "green", borderRadius: "100px" }} onClick={(event) => { this.props.toggleActive(event, 'server', server, this.props.group) }}></div>}
            {this.props.stage && isLive == 0 && <div style={{ position: "absolute", top: "-4px", right: "2px", width: "10px", height: "10px", backgroundColor: "grey", borderRadius: "100px" }} onClick={(event) => { this.props.toggleActive(event, 'server', server, this.props.group) }}></div>}

            <span className="icon icon-server"></span> {server.name}
        </div >))
    }
}
export default Server;
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UILIB from '../../../../Common-Objects/Lib'
import i18n from '~/i18n'
import PermissionChecker from '~/Classes/permissions';
import axios from '~/data/http/axios';
import * as siteMaster from '~/data/actions/siteActions';
import DeleteTag from './delete'
import AddTag from './add'
import TagLinks from './links'
const tagTypes = [{ label: 'Contact/Organisation Tags', value: 0 }, { label: 'Link Tags', value: 1 }, { label: 'Advertising Tags', value: 3 }]

export default function Tags() {
    const dispatch = useDispatch()
    const permissions = useSelector(state => state.permission)
    const history = useHistory()
    const [tagType, setTagType] = useState(0)
    const [loading, setLoading] = useState(true)
    const [tags, setTags] = useState(null)
    const [alert, setAlert] = useState({ message: '', open: false })

    const getTags = async () => {
        setLoading(true)
        const res = await axios.get('/tags/stats?tagType=' + tagType)
        setTags(res.data)
        setLoading(false)
    }

    const addTag = () => {
        dispatch(siteMaster.alterSiteDrawer(true, true, 'right', <AddTag defaultTagType={tagType} onConfirm={async (name, tagType) => {
            await axios.post('/tags', {
                tagName: name,
                tagType: tagType
            })
            dispatch(siteMaster.alterSiteDrawer(false))
            getTags()
        }} onCancel={() => dispatch(siteMaster.alterSiteDrawer(false))} />, true, '300px'))
    }

    const edit = (tag) => {
        dispatch(siteMaster.alterSiteDrawer(true, true, 'right', <AddTag tag={tag} onConfirm={async (name) => {
            await axios.put('/tags/' + tag.id, {
                tagName: name,
            })
            dispatch(siteMaster.alterSiteDrawer(false))
            getTags()
        }} onCancel={() => dispatch(siteMaster.alterSiteDrawer(false))} />, true, '300px'))

    }

    const viewSubs = tag => {
        let query = '?'
        if (tagType === 0) {
            query += 'tags=' + (tag.TagId || tag.id)
        } else if (tagType === 1) {
            query += 'linkTags=' + (tag.TagId || tag.id)
        } else if (tagType === 3) {
            query += 'adTags=' + (tag.TagId || tag.id)
        }
        history.push('/cp/subscribers' + query)
    }

    const deleteTag = tag => {
        dispatch(siteMaster.alterSiteDrawer(true, true, 'right', <DeleteTag tag={tag} onConfirm={async () => {
            await axios.delete('/tags/' + tag.id)
            setAlert({ message: 'Tag Removed', open: true })
            dispatch(siteMaster.alterSiteDrawer(false))
            getTags()
        }} onCancel={() => dispatch(siteMaster.alterSiteDrawer(false))} />, true, '300px'))
    }

    const viewLinks = tag => {
        const content = <TagLinks tag={tag} onChange={getTags} />
        dispatch(siteMaster.alterSiteDrawer(true, true, 'right', content, true, 500))
    }

    useEffect(() => {
        getTags()
    }, [tagType])

    const writeAccess = PermissionChecker("subscribers", permissions.permissions, "write")

    if (!tags) return <UILIB.LoadingIcons iconType="2" />

    const tableData = tags.map(t => {
        const opened = t.count - t.notOpened
        const clicked = t.count - t.notClicked
        const sent = t.count - t.notSent

        const sentAvg = (100 / t.count) * sent
        const openedAvg = (100 / t.count) * opened
        const clickedAvg = (100 / t.count) * clicked

        const sentColor = sentAvg > 50 ? 'square-chip-green' : sentAvg > 25 ? 'square-chip-orange' : ''
        const openColor = openedAvg > 25 ? 'square-chip-green' : openedAvg > 10 ? 'square-chip-orange' : ''
        const clickColor = clickedAvg > 15 ? 'square-chip-green' : clickedAvg > 5 ? 'square-chip-orange' : ''

        let name = t.tagName
        if (t.Application && t.Application.appLogoUri && tags.length < 200) {
            name = <div style={{ position: 'relative' }}>{name} <UILIB.Avatar style={{ position: 'absolute', height: '30px', width: '30px', marginLeft: "5px", top: -5, border: '1px solid grey' }} tooltip={t.Application.appName} for={t.id} src={t.Application.appLogoUri} /></div>
        }

        let appOption = "";
        if (t.Application) {
            if (t.Application.link) {
                appOption = <li><a target="_blank" href={t.Application.link}>Edit in {t.Application.appName}</a></li>
            }
            else {
                appOption = <li className='read-only'><a>Edit in {t.Application.appName}</a></li>
            }
        }

        return {
            tag: {
                headerValue: 'Tag',
                value: name
            },
            count: {
                align: 'center',
                headerValue: 'Contacts',
                value: <UILIB.SquareChip className="square-chip-large square-chip-purple text-clickable square-chip-min-width" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => viewSubs(t)}>{t.count}</UILIB.SquareChip>
            },
            links: tagType === 1 ? {
                align: 'center',
                headerValue: 'Links',
                value: <UILIB.SquareChip className="square-chip-large square-chip-primary text-clickable square-chip-min-width" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => viewLinks(t)}>{t.links || 0}</UILIB.SquareChip>
            } : {},
            sent: tagType === 0 ? {
                align: 'center',
                headerValue: 'Received Emails',
                value: <div>
                    <div style={{ fontWeight: 'bold', color: 'black' }}>{sent}</div>
                    <UILIB.SquareChip className={sentColor}>{(sentAvg || 0).toFixed(2)}%</UILIB.SquareChip>
                </div>
            } : {},
            opened: tagType === 0 ? {
                align: 'center',
                headerValue: 'Opened',
                value: <div>
                    <div style={{ fontWeight: 'bold', color: 'black' }}>{opened}</div>
                    <UILIB.SquareChip className={openColor}>{(openedAvg || 0).toFixed(2)}%</UILIB.SquareChip>
                </div>
            } : {},
            clicked: tagType === 0 ? {
                align: 'center',
                headerValue: 'Clicked',
                value: <div>
                    <div style={{ fontWeight: 'bold', color: 'black' }}>{clicked}</div>
                    <UILIB.SquareChip className={clickColor}>{(clickedAvg || 0).toFixed(2)}%</UILIB.SquareChip>
                </div>
            } : {},
            score: {
                align: 'center',
                headerValue: 'Avg Score',
                value: <UILIB.Score className="quickFlex justify-center" score={t.score} />
            },
            optionsCol: {
                headerValue: " ",
                value: <UILIB.OptionsDropdown popWidth="150px">
                    <ul>
                        <li>
                            <a onClick={() => viewSubs(t)}>Contacts</a>
                        </li>
                        {tagType === 1 && <li>
                            <a onClick={() => viewLinks(t)}>Links</a>
                        </li>}
                        {!t.Application && <li>
                            <a onClick={() => edit(t)}>Edit</a>
                        </li>}
                        {!t.Application && <li>
                            <a onClick={() => deleteTag(t)}>Delete</a>
                        </li>}
                        {appOption}
                    </ul>
                </UILIB.OptionsDropdown>,
                fixed: true,
                fixedSml: true,
                width: 20
            }
        }
    })


    return (
        <div>
            <UILIB.Row className="mar-b25">
                <UILIB.Column xs={6} sm={6} md={4} lg={3}>
                    <UILIB.Select data={tagTypes} inlineLabel="Filter" value={tagType} onChange={e => setTagType(e.currentTarget.value)} />
                </UILIB.Column>
                {(writeAccess === true) && <UILIB.Column xs={6} sm={6} md={8} lg={9} className="end-xs">
                    <UILIB.Button className="button-primary" iconLeft={<UILIB.Icons icon="plus" />} onClick={addTag}>Add Tag</UILIB.Button>
                </UILIB.Column>}
            </UILIB.Row>

            <UILIB.DataTable1
                noResultsTxt={<span>No Tags Found</span>}
                tableData={tableData}
                sortedColumn="tag"
                sortedDirection="ASC"
                width="100%"
                pageSize="100"
                hasCheckBoxes="no"
                loadingData={loading}
            />


            <UILIB.SnackBar message={alert.message} open={alert.open} autoclose={true} dismiss={() => setAlert({ ...alert, open: false })} />
        </div>
    )
}
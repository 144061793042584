import React from 'react'
import UILIB from '../../../../Common-Objects/Lib';
import i18n from '~/i18n';
import { useSelector } from 'react-redux';
import FormatNumberFuncs from '../../../../Classes/numberFormatFunctions';


export default function SubscribersClicked({ clicks, edit = () => { } }) {
    const siteMaster = useSelector(state => state.siteMaster)
    let increaseHolder = <UILIB.Indicator className="indicator-grey">0.00%</UILIB.Indicator>;
    if ((clicks.increase || clicks.decrease) !== Infinity) {
        if (clicks.increase) increaseHolder = <UILIB.Indicator leftIcon={<UILIB.Icons icon="arrowUp" color={siteMaster.colours["$status-green"]} />} className=" indicator-green">{FormatNumberFuncs.formatNumber(clicks.increase, 2)}%</UILIB.Indicator>
        if (clicks.decrease) increaseHolder = <UILIB.Indicator leftIcon={<UILIB.Icons icon="arrowDown" color={siteMaster.colours["$status-red"]} />} className="indicator-red">{FormatNumberFuncs.formatNumber(clicks.decrease, 2)}%</UILIB.Indicator>
    }
    return <UILIB.Paper className="m-mar-b0" iconRight={<UILIB.Icons icon="pencil" color="grey" />} iconRightClick={edit}>

        <h4 className="mar-b5">{i18n.t('dashboard:welcomePage.subscriberClicks')}</h4>
        <div className="mar-b24">{i18n.t('dashboard:welcomePage.thisMonth')}</div>
        <div className="quickFlex">
            <h4 className="mar-r16 statistic">{FormatNumberFuncs.formatNumber(clicks.current)}</h4>
            {increaseHolder}
            <div style={{ height: "40px" }} />
        </div>


    </UILIB.Paper >
}
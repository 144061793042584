import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'

class ApplicationAdditionalDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = { results: {} }
        this.finish = this.finish.bind(this)
        this.updateCheckbox = this.updateCheckbox.bind(this)
        this.getInput = this.getInput.bind(this)
    }

    finish() {
        return this.props.next(this.state.results)
    }

    updateCheckbox(ev) {
        var results = this.state.results
        results[ev.currentTarget.name] = ev.currentTarget.checked
        this.setState({
            results
        })
    }

    getInput(query, index) {
        switch (query.type) {
            case 'BOOLEAN':
                return <div className="form-group" key={index}>
                    <UILIB.CheckBox checked={this.state.results[query.field]} name={query.field} onChange={this.updateCheckbox}>{query.label}</UILIB.CheckBox>
                </div>
        }
    }

    render() {
        var app = this.props.app.Application
        return (
            <div>

                <h4 className="mar-b25">Import from {this.props.app.Application.appName}</h4>
                <div className="mar-b25">Select the filters</div>

                {!!app.options.additionalFilters.query && <div className="mar-b25">
                    {app.options.additionalFilters.query.map(this.getInput)}
                </div>}

                <UILIB.Button text="Next" onClick={this.finish} className="button-primary" />

            </div>
        );
    }
}

export default ApplicationAdditionalDrawer;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u_pFUTYokomrnRSh00Fi{display:flex;align-items:center;gap:16px}.u_pFUTYokomrnRSh00Fi form{flex:1}.FXLjirZnYJLrdvJLDdnC{display:flex;flex-direction:column}.lrlkH3Dz42fp6nJBCe7B{color:var(--grey-800);font-size:13px;max-width:300px;overflow:hidden;text-overflow:ellipsis}.mDneLAZeL0i4JKgbhhMY{display:flex;align-items:center;justify-content:flex-end;gap:12px}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/aiAssistant/articles/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,2BACE,MAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,qBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,sBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,QAAA","sourcesContent":[".topRow {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n\n  form {\n    flex: 1;\n  }\n}\n\n.tablePageTitleWrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.tablePageUrl {\n  color: var(--grey-800);\n  font-size: 13px;\n  max-width: 300px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.tableActions {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topRow": "u_pFUTYokomrnRSh00Fi",
	"tablePageTitleWrapper": "FXLjirZnYJLrdvJLDdnC",
	"tablePageUrl": "lrlkH3Dz42fp6nJBCe7B",
	"tableActions": "mDneLAZeL0i4JKgbhhMY"
};
export default ___CSS_LOADER_EXPORT___;

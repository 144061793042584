import React, { useEffect, useState, useRef, Fragment } from 'react'
import axios from '~/data/http/axios'
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions'
import config from '~/Classes/siteVars.js';
import i18n from '~/i18n';

export default function UserSessions() {
    const [sessions, setSessions] = useState([])
    const timer = useRef(null)

    const getSessions = async () => {
        const res = await axios.get('/accountuser/me/sessions')

        setSessions(res.data)
    }

    const clearSession = async (session) => {
        await axios.delete('/accountuser/session/' + session.id)
        getSessions()
    }

    const clearAll = async () => {
        await axios.delete('/accountuser/session/all')
        getSessions()
    }

    useEffect(() => {
        getSessions()
        timer.current = setInterval(getSessions, 5000)
        return function () {
            clearInterval(timer.current)
        }
    }, [])


    let tableData = [];
    if (sessions && sessions.length) {
        tableData = sessions.map(sess => {

            let hasFlag = sess.geo && sess.geo.country_code && sess.geo.country_code != "-";

            let deviceIcon = "icon-laptop";
            let deviceAlt = "Computer";
            if (sess.device) {
                if (sess.device.type == "phone") {
                    deviceIcon = "icon-phone";
                    deviceAlt = "Mobile"
                }
                if (sess.device.type == "tablet") {
                    deviceIcon = "icon-tablet";
                    deviceAlt = "Tablet"
                }
            }
            return {
                country: {
                    headerValue: " ",
                    value: hasFlag ?
                        <div style={{ borderRadius: "100px", overflow: "hidden", width: "20px", height: "20px", backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url(${config.repoEndPoint + "siteContent/countries/raw/" + sess.geo.country_code.toLowerCase() + ".png"})` }} title={sess.geo.country_name} alt={sess.geo.country_name} />
                        : <div style={{ borderRadius: "100px", overflow: "hidden", width: "20px", height: "20px", backgroundColor: "#DDDDDD" }} alt="Unknown" title="Unknown" />,
                    orderBy: false,
                    width: 20,
                    align: "left"
                },
                device: {
                    headerValue: " ",
                    value: <div className={"icon " + deviceIcon} alt={deviceAlt} title={deviceAlt} />,
                    width: 20,
                    align: "left"
                },
                account: {
                    headerValue: i18n.t('account:my.yourActiveSessionsAccount'),
                    value: <div>{sess.account ? sess.account.accountName : "N/A"} {sess.self ? <span>(You)</span> : ""}</div>,
                    orderBy: false,
                    align: "left"
                },
                lastOnline: {
                    headerValue: i18n.t('account:my.yourActiveSessionsLastOnline'),
                    value: DateTimeFunctions.formatDateTime(sess.date),
                    orderBy: false,
                    align: "left",
                    width: 150
                },
                options: {
                    headerValue: " ",
                    value: sess.self ? <div></div> : <UILIB.Button text={i18n.t('account:my.yourActiveSessionsLogoff')} className="button-sml red outline" onClick={() => clearSession(sess)} />,
                    orderBy: false,
                    align: "right",
                    width: 100
                }
            }
        })
    }
    return (
        <div>
            <h4 className="mar-b25">{i18n.t('account:my.yourActiveSessions')}</h4>
            <div className="mar-b25">{i18n.t('account:my.yourActiveSessionsSubHeader')}</div>

            <div className="mar-b25">
                <UILIB.DataTable1
                    noResultsTxt={""}
                    tableData={tableData}
                    loadingData={false}
                    width="100%"
                    hasCheckBoxes="no"
                />
            </div>
            <div className="text-right text-heavy text-sml">
                <a onClick={clearAll}>{i18n.t('account:my.yourActiveSessionsLogoutAll')}</a>
            </div>
        </div >
    )
}
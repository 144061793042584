import React from 'react'

export default class IconsBell extends React.Component {

    render() {
        let color = "#7246B1";
        let className = "icons-icon";
        if (this.props.color) color = this.props.color;
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.61085 2.12166C7.04373 2.39798 5.65056 3.28553 4.73783 4.58905C3.8251 5.89256 3.46757 7.50527 3.74389 9.07239L4.3666 12.6039L3.79311 13.4229C3.67971 13.5849 3.61688 13.7769 3.61258 13.9746C3.60828 14.1723 3.66269 14.3669 3.76894 14.5337C3.87518 14.7005 4.02849 14.832 4.20949 14.9117C4.39048 14.9913 4.59103 15.0155 4.78578 14.9812L16.6035 12.8975C16.7982 12.8631 16.9784 12.7717 17.1212 12.635C17.264 12.4982 17.3631 12.3222 17.4059 12.1291C17.4487 11.936 17.4333 11.7346 17.3616 11.5503C17.29 11.366 17.1653 11.2071 17.0033 11.0936L16.1843 10.5201L15.5616 6.98862C15.2853 5.42149 14.3977 4.02832 13.0942 3.11559C11.7907 2.20286 10.178 1.84533 8.61085 2.12166ZM11.3892 17.8786C10.6057 18.0167 9.79931 17.838 9.14755 17.3816C8.49579 16.9252 8.05202 16.2287 7.91385 15.4451L13.8227 14.4032C13.9609 15.1868 13.7821 15.9931 13.3257 16.6449C12.8694 17.2966 12.1728 17.7404 11.3892 17.8786Z" fill={color} />
        </svg>
    }
}

import React from "react";
import i18n from "i18next";
import { useSelector } from "react-redux"
import classNames from "classnames";
import { SENDING_DOMAIN, getDomainStatus } from "~/Classes/domains";
import UILIB from "~/Common-Objects/Lib";
import DomainStatus from "./domainStatus";
import { useHasWriteAccess } from "~/Common-Objects/hooks/permissions";

export default function SendingDomain({
  domain,
  hideMxRecordsCta,
  onDeleteClick,
  onResendAuthEmailClick,
  onAuthenticateClick,
  onMxRecordsClick,
  onSetDefaultPreviewClick,
  onChangePreviewAddressClick,
  className,
  children,
  ...rest
}) {
  const {
    domainName: name,
    verified,
    authenticated,
    defaultPreviewDomain,
    originalEmailAddress,
  } = domain;

  const site = useSelector(state => state.siteMaster)
  const hasWriteAccess = useHasWriteAccess("domains");

  const classes = classNames("sending-domain", className);

  return (
    <div className={classes} {...rest}>
      <div className="sending-domain__inner">
        <div className="sending-domain__header">
          <div>
            <div className="sending-domain__name">
              <h3 className="text-md fw-medium">{name}</h3>

              {defaultPreviewDomain === true && (
                <UILIB.SquareChip className="square-chip-primary">
                  {i18n.t("company:domains.defaultPreviewDomain")}
                </UILIB.SquareChip>
              )}
            </div>

            <DomainStatus
              status={getDomainStatus(domain, SENDING_DOMAIN)}
              type={SENDING_DOMAIN}
              domainName={name}
              email={originalEmailAddress}
            />
          </div>

          <div className="sending-domain__primary-ctas">
            {verified === false && (
              <UILIB.Button
                className="button-primary"
                onClick={onResendAuthEmailClick}
              >
                {i18n.t("company:domains.resend")}
              </UILIB.Button>
            )}

            {hasWriteAccess && (
              <button
                type="button"
                className="sending-domain__delete-btn"
                aria-label="Delete Domain"
                onClick={onDeleteClick}
              >
                <UILIB.Icons icon="bin" color="#000000" />
              </button>
            )}
          </div>
        </div>

        {verified === true && authenticated === false && (
          <div className="sending-domain__authenticate-cta">
            <div>
              <h4 className="text-xsml mar-b5">
                {i18n.t("company:domains.sending.authenticateHeader")}
              </h4>
              <p className="text-xsml mar-b0">
                {i18n.t("company:domains.sending.authenticateBody", { siteName: site.siteName })}
              </p>
            </div>

            <UILIB.Button
              className="button-primary"
              onClick={onAuthenticateClick}
            >
              {i18n.t("company:domains.auth")}
            </UILIB.Button>
          </div>
        )}

        {verified === true && authenticated === true && (
          <div className="sending-domain__secondary-ctas">
            {!hideMxRecordsCta && (
              <UILIB.Button
                iconLeft={<UILIB.Icons icon="cog" />}
                onClick={onMxRecordsClick}
              >
                {i18n.t("company:domains.setupMxRecord")}
              </UILIB.Button>
            )}

            {defaultPreviewDomain === true ? (
              <UILIB.Button
                iconLeft={<UILIB.Icons icon="eye" />}
                onClick={onChangePreviewAddressClick}
              >
                {i18n.t("company:domains.changePreviewEmail")}
              </UILIB.Button>
            ) : (
              <UILIB.Button
                iconLeft={<UILIB.Icons icon="envelope" />}
                onClick={onSetDefaultPreviewClick}
              >
                {i18n.t("company:domains.makeDefaultPreviewDomain")}
              </UILIB.Button>
            )}
          </div>
        )}
      </div>

      {children && (
        <div className="sending-domain__subdomains sending-domain__inner">
          <h4 className="text-md fw-medium mar-b5">
            {i18n.t("company:domains.improveEmailDeliverabilityHeader")}
          </h4>
          <p className="text-xsml mar-b15">
            {i18n.t("company:domains.improveEmailDeliverabilityBody")}
          </p>

          {children}
        </div>
      )}
    </div>
  );
}

import React from "react";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { connect } from "react-redux";
import i18n from "~/i18n";
import copy from "copy-to-clipboard";

//CONNECT TO STORE
@connect((store) => {
  return { user: store.user, siteMaster: store.siteMaster };
})
export default class MxRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertOpen: false,
      alertMessage: "",
      error: "",
      failed: {
        spf: false,
        dkim: false,
        mx: false,
      },
    };

    this.copyClipboard = this.copyClipboard.bind(this);
    this.checkMxRecord = this.checkMxRecord.bind(this);
  }

  componentDidMount() {}

  copyClipboard(value) {
    copy(value);
    this.setState({
      alertOpen: true,
      alertMessage: i18n.t("copiedToClipBoard"),
    });
  }

  async checkMxRecord() {
    this.setState({ error: "" });
    try {
      let result = await axios.put(
        `/accountMaster/domain/${this.props.domain.id}/authenticatemx`
      );
      if (!result.data.success) {
        let failed = { spf: false, dkim: false, mx: false };
        if (result.data.failed) failed = result.data.failed;
        return this.setState({ error: result.data.error, failed });
      }
      this.props.onChecked();
    } catch (err) {
      this.setState({ error: "MX Record was not found" });
    }
  }

  render() {
    let spfHost = "spf.ourmailsender.com";
    if (this.props.siteMaster.spfRecordUrl)
      spfHost = this.props.siteMaster.spfRecordUrl + ".ourmailsender.com";
    return (
      <div>
        <h4 className="mar-b25">
          {i18n.t("company:domains.mxRecord.setupMxRecordHeader")}
        </h4>
        <div className="mar-b10">
          {i18n.t("company:domains.mxRecord.setupMxRecordSubHeader")}
        </div>
        <div className="mar-b25">
          {i18n.t("company:domains.mxRecord.setupMxRecordInstructions")}
        </div>

        <UILIB.WarningHolder className="mar-b25">
          <table
            width="100%"
            cellPadding="10"
            cellSpacing="0"
            className="table-cont2 with-lines"
          >
            <thead>
              <tr>
                <th align="left">Type</th>
                <th align="left">Name</th>
                <th align="left">Value</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td align="left">MX</td>
                <td align="left">
                  <UILIB.TextInput
                    value={"mpmail." + this.props.domain.domainName}
                    readOnly
                    iconRight={
                      <UILIB.Icons
                        icon="copyClipboard"
                        onClick={() => {
                          this.copyClipboard(
                            `mpmail.${this.props.domain.domainName}`
                          );
                        }}
                      />
                    }
                    error={this.state.failed.mx == true ? " " : ""}
                  />
                </td>
                <td align="left">
                  <UILIB.TextInput
                    value={"mail.ourmailsender.com"}
                    readOnly
                    iconRight={
                      <UILIB.Icons
                        icon="copyClipboard"
                        onClick={() => {
                          this.copyClipboard(`mail.ourmailsender.com`);
                        }}
                      />
                    }
                    error={this.state.failed.mx == true ? " " : ""}
                  />
                </td>
              </tr>
              <tr>
                <td align="left">CNAME</td>
                <td align="left">
                  <UILIB.TextInput
                    value={
                      " mp1._domainkey.mpmail." + this.props.domain.domainName
                    }
                    readOnly
                    iconRight={
                      <UILIB.Icons
                        icon="copyClipboard"
                        onClick={() => {
                          this.copyClipboard(
                            `mp1._domainkey.mpmail.${this.props.domain.domainName}`
                          );
                        }}
                      />
                    }
                    error={this.state.failed.dkim == true ? " " : ""}
                  />
                </td>
                <td align="left">
                  <UILIB.TextInput
                    value={"dkim.ourmailsender.com"}
                    readOnly
                    iconRight={
                      <UILIB.Icons
                        icon="copyClipboard"
                        onClick={() => {
                          this.copyClipboard(`dkim.ourmailsender.com`);
                        }}
                      />
                    }
                    error={this.state.failed.dkim == true ? " " : ""}
                  />
                </td>
              </tr>
              <tr>
                <td align="left">TXT</td>
                <td align="left">
                  <UILIB.TextInput
                    value={"mpmail." + this.props.domain.domainName}
                    readOnly
                    iconRight={
                      <UILIB.Icons
                        icon="copyClipboard"
                        onClick={() => {
                          this.copyClipboard(
                            `mpmail.${this.props.domain.domainName}`
                          );
                        }}
                      />
                    }
                    error={this.state.failed.spf == true ? " " : ""}
                  />
                </td>
                <td align="left">
                  <UILIB.TextInput
                    value={`v=spf1 include:${spfHost} ~all`}
                    readOnly
                    iconRight={
                      <UILIB.Icons
                        icon="copyClipboard"
                        onClick={() => {
                          this.copyClipboard(`v=spf1 include:${spfHost} ~all`);
                        }}
                      />
                    }
                    error={this.state.failed.spf == true ? " " : ""}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </UILIB.WarningHolder>

        <h4 className="mar-b25">
          {i18n.t("company:domains.mxRecord.setupMxFinishInstructions")}
        </h4>

        {this.state.error && (
          <UILIB.FormError>{this.state.error}</UILIB.FormError>
        )}

        <UILIB.Button
          text={i18n.t("company:domains.mxRecord.checkButton")}
          className="button-sml primary"
          onClick={this.checkMxRecord}
        />

        <UILIB.SnackBar
          message={this.state.alertMessage}
          open={this.state.alertOpen}
          autoclose={true}
          dismiss={() => this.setState({ alertOpen: false })}
        />
      </div>
    );
  }
}

import React, { useState } from "react";
import { useSelector } from "react-redux";
import i18n from "i18next";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import { TRACKING_DOMAIN, TRANSPOND_CDN_DOMAIN } from "~/Classes/domains";
import { useIsTranspond } from "~/Common-Objects/hooks/siteMaster";
import TextFunctions from "~/Classes/textFunctions";

export default function AuthenticateSubdomain({
  domain,
  domainType,
  className,
  onAuthenticate,
  onAuthenticated,
  ...rest
}) {
  const isTranspond = useIsTranspond();
  const site = useSelector((state) => state.siteMaster);

  const [submitting, setSubmitting] = useState(false);

  async function handleAuthenticateClick(e) {
    e.preventDefault();

    setSubmitting(true);

    try {
      await onAuthenticate(domain, domainType);
      onAuthenticated && onAuthenticated(domainType);
    } finally {
      setSubmitting(false);
    }
  }

  const isTrackingDomain = domainType === TRACKING_DOMAIN;

  const cnameValue = isTrackingDomain
    ? site.trackingDomain
    : TRANSPOND_CDN_DOMAIN;

  const classes = classnames("authenticate-subdomain", className);

  return (
    <div className={classes} {...rest}>
      <h2 className="h4 mar-b15">
        {i18n.t(`company:domains.${domainType.id}.authenticateHeader`)}
      </h2>

      <p>{i18n.t(`company:domains.${domainType.id}.authenticateBody`)}</p>

      <ol className="mar-b25">
        <li>
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                `company:domains.${domainType.id}.authenticateList1`,
                {
                  domainName: TextFunctions.checkForHtml(domain.domainName),
                }
              ),
            }}
          />
          <table className="simple-table mar-t15 mar-b25">
            <thead>
              <tr>
                <th>Type</th>
                <th>Value</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <code className="code">CNAME</code>
                </td>
                <td>
                  <code className="code">{cnameValue}</code>
                </td>
              </tr>
            </tbody>
          </table>
        </li>

        <li>
          {i18n.t(`company:domains.${domainType.id}.authenticateList2`, {
            siteName: site.siteName,
          })}
        </li>
      </ol>

      {isTranspond && (
        <div className="box mar-b25">
          <h3 className="text-md mar-b5">
            {i18n.t("helplinks:general.helpHeader")}
          </h3>
          <p
            className="mar-b0"
            dangerouslySetInnerHTML={{
              __html: i18n.t("helplinks:domains.manageDns"),
            }}
          />
        </div>
      )}

      <UILIB.Button
        type="button"
        className="button-primary"
        saving={submitting}
        onClick={handleAuthenticateClick}
      >
        {i18n.t(`company:domains.${domainType.id}.authenticate`)}
      </UILIB.Button>
    </div>
  );
}

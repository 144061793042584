import React, { Component } from 'react';
import axios from '~/data/http/axios'
import UILIB from '~/Common-Objects/Lib'
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
import moment from 'moment';

const Map = ReactMapboxGl({
    accessToken: "pk.eyJ1IjoiY29udGFjdGdhdGUiLCJhIjoiODI5ZDJlOWNiNGM5YTM4OGM1Mzk2ODA2MjI4MTI2YmQifQ.iEJBZeGfK-7O2nOkPxIBDA"
});

class TrackingMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            geos: {
                type: "FeatureCollection", features: []
            }
        }
        this.getGeos = this.getGeos.bind(this)
    }

    componentDidMount() {
        this.getGeos();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate != this.props.startDate || prevProps.endDate != this.props.endDate) {
            this.getGeos();
        }
    }

    getGeos() {
        var start, end = moment();

        var queryStr = '?'
        if (this.props.trackerId) queryStr += 'trackerId=' + this.props.trackerId + '&'
        if (this.props.pathId) queryStr += 'pathId=' + this.props.pathId + '&'
        if (this.props.startDate) start = this.props.startDate;
        if (this.props.endDate) end = this.props.endDate;
        queryStr += '&startDate=' + start.format('YYYY-MM-DD');
        queryStr += '&endDate=' + end.format('YYYY-MM-DD');

        axios.get('/accountMaster/tracker/geo' + queryStr).then(response => {
            this.setState({
                loaded: true,
                geos: {
                    type: "FeatureCollection",
                    features: response.data.Maps.map(row => {
                        return {
                            type: "Feature",
                            properties: {},
                            geometry: {
                                type: "Point",
                                coordinates: [row.longitude, row.latitude]
                            }
                        }
                    })
                }
            });
        })
    }


    render() {
        if (!this.state.loaded) return <div style={{ height: '400px' }}><UILIB.LoadingIcons iconType="2" /></div>
        var features = this.state.geos.features.map((feature, index) => {
            return <Feature key={"mapC_" + index} coordinates={feature.geometry.coordinates} properties={{
                'marker-color': '#3bb2d0',
                'marker-size': 'large',
                'marker-symbol': 'circle-15'
            }} />
        })
        return (
            <Map
                style="mapbox://styles/contactgate/cjxq92mlz0c5s1cne6vo6tw6q"
                id={"tracking" + (this.props.trackerId ? (this.props.trackerId + (this.props.pathId ? this.props.pathId : '')) : '')}
                containerStyle={{
                    height: "400px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "4px",
                }}
                zoom={[1]}>
                <Layer type="heatmap">
                    {features}
                </Layer>
            </Map>
        );
    }
}

export default TrackingMap;
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UILIB from '../../../Common-Objects/Lib'
const kbArticle = 'https://kb.transpond.io/article/107-customizing-your-account#two-factor-authentication'

export default function Soft2faTrigger() {
    const dispatch = useDispatch()
    const site = useSelector(state => state.siteMaster)
    console.log(site)
    useEffect(() => {
        dispatch({ type: "LOGGEDIN_FAILED", payload: '' })
    }, [])
    return (
        <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <UILIB.Paper className="with_header" style={{ margin: '0 64px' }}>
                <div className="paper_header" style={{ backgroundColor: site.colours["$primary"], color: 'white' }}>
                    Login Verification Required
                </div>
                <div className="paper_content">
                    <p>This is the first time we have detected you have logged in from this browser, <strong>please check your email</strong> and click the link in our email to verify your login.</p>
                    <p>Once you’ve logged in using the verification link, to prevent this happening again you can setup 2FA to secure your account by following <a href={kbArticle}>these instructions</a>.</p>
                </div>
            </UILIB.Paper>
        </div>
    )
}
import React from "react";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";

export default function Score({ score, starCount = 5, className, ...rest }) {
  const classes = classnames(styles.root, className);

  return (
    <div className={classes} {...rest}>
      {Array.from(Array(starCount).keys()).map((_, index) => {
        return (
          <UILIB.Icon
            key={index}
            className={styles.star}
            name={score >= index + 1 ? "star" : "star-outline"}
          />
        );
      })}
    </div>
  );
}

import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';
class DeleteMailBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    render() {

        return (
            <div>
                <h4 className="mar-b25">{i18n.t('company:mailboxes.deleteConfirm')}</h4>
                <UILIB.Button className="button-primary" text={i18n.t('yes')} onClick={this.props.confirm} />
            </div >
        );
    }
}

export default DeleteMailBox;
import React from 'react';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'

@connect((store) => {
    return { siteMaster: store.siteMaster }
})

export default class Pie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [{ value: 1 }]
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                data: this.props.data
            })
        }, 1000)
    }

    render() {
        var total = this.state.data.reduce((a, b) => ({ value: a.value + b.value })).value;
        var percentages = this.state.data.map(row => (100 / total) * row.value);

        var siteColours = this.props.siteMaster.colours
        var available = [
            siteColours.$primary,
            siteColours.$secondary,
            siteColours['$status-red'],
            siteColours['$status-yellow'],
            siteColours['$status-green'],
            siteColours['$status-orange'],
            siteColours['$status-blue']
        ]

        var circles = this.state.data.map((row, index) => {
            var array = percentages[index] + " " + percentages.filter((p, i) => i !== index).reduce((a, b) => a + b, 0)
            var offset = 100 - (percentages.filter((p, i) => i < index).reduce((a, b) => a + b, 0)) + 25

            return <circle
                className="CircularProgress-Fg"
                key={index}
                cx="21"
                cy="21"
                strokeWidth={this.props.strokeWidth}
                r="15.91549430918954"
                style={{
                    strokeDasharray: array,
                    strokeDashoffset: offset,
                }}
                fill="transparent"
                stroke={available[index]}
            />
        })

        return (
            <UILIB.Row>
                <UILIB.Column xs={12} className="center-xs">
                    <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 42 42">
                        <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
                        {circles}
                    </svg >
                </UILIB.Column>
                <UILIB.Column xs={12} className="center-xs">
                    {this.state.data.map((row, index) => {
                        return <span style={{ color: available[index] }} className="uppercase"><i className="circle" /> {row.label}</span>
                    })}
                </UILIB.Column>
            </UILIB.Row>


        );
    }
}

Pie.defaultProps = {
    radius: 100
};

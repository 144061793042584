import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, site: store.siteMaster }
})

export default class addStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: "",
            inputErrors: {
                domain: ""
            }
        };

        this.updateInput = this.updateInput.bind(this);
        this.checkDomain = this.checkDomain.bind(this);
    }

    updateInput(ev) {
        this.setState({ [ev.currentTarget.name]: ev.currentTarget.value })
    }

    checkDomain() {
        var inputErrors = this.state.inputErrors;
        inputErrors.domain = this.state.domain ? "" : 'Enter a domain';
        this.setState({ inputErrors: inputErrors });
        if (inputErrors.domain) return

        this.setState({
            adding: true
        })

        var data = { domainName: this.state.domain.toLowerCase() };
        axios.post("/accountMaster/cdn", data).then(res => {
            this.props.onAddedDomain();
        }).catch(err => {
            console.log(err);
            var theErr = this.state.inputErrors;
            theErr.domain = err.data.error;
            this.setState({ inputErrors: theErr, adding: false })
        })
    }

    render() {
        let domainError = this.state.inputErrors.domain
        if (this.state.inputErrors.domain && this.state.inputErrors.domain.indexOf('Invalid CAA') > -1 && this.props.site.siteId === 1) {
            domainError = <span>{this.state.inputErrors.domain} <a href="https://kb.transpond.io/article/106-email-domain-configuration#what-is-a-caa-record" target="_blank">See Details</a></span>
        }

        return <div>
            <h4 className="mar-b25">{i18n.t('company:domains.cdn.add')}</h4>
            <div className="mar-b15">{i18n.t('company:domains.cdn.header')}</div>
            <div className="mar-b25" dangerouslySetInnerHTML={{ __html: i18n.t('company:domains.cdn.subHeader') }}></div>

            <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('company:domains.cdn.subDomain')} focus={true} type="text" placeholder={i18n.t('company:domains.cdn.subDomain')} name="domain" error={domainError} value={this.state.domain} onChange={this.updateInput} />
            <UILIB.Button outerClassName="button-primary mar-b25" text={i18n.t('continue')} className="outline primary" onClick={this.checkDomain} />

            {this.state.adding && <span className="text-sml text-primary loading">{i18n.t('company:domains.cdn.adding')}</span>}

            {this.props.site.siteId == 1 &&
                <div className="mar-t25">
                    <UILIB.Hint className="no-marg" href="https://kb.transpond.io/article/106-email-configuration#how-to-setup-a-CDN-domain">
                        <div>
                            <div style={{ fontWeight: "bold" }}>Need help?</div>
                            <div>Check out our guide.</div>
                            <a>Click Here</a>
                        </div>
                    </UILIB.Hint>

                </div>
            }

        </div >
    }
};



import React, { useEffect, useState } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import axios from '../../../../../data/http/axios';

export default function DeleteFile({ file, deleteFile = () => { } }) {
    const [formFiles, setFormFiles] = useState(null)

    useEffect(() => {
        axios.get(`/fileManager/0/file/${file.fileId}/check`).then(res => {
            setFormFiles(res.data)
        })
    }, [file.id])

    if (!formFiles) return <UILIB.LoadingIcons />
    var theMessage = i18n.t('templates:fileManager.popups.areYouSureYouWantToDeleteThisFile');
    if (file && file.length > 1) {
        theMessage = i18n.t('templates:fileManager.popups.areYouSureYouWantToDeleteXFiles');
        theMessage = theMessage.replace(/\[NOFILES\]/g, file.length);
    }

    return (
        <div>
            <h4 className="mar-b25">{theMessage}</h4>
            {!formFiles.length && <UILIB.Button
                className="button-red"
                text={i18n.t('delete')}
                onClick={deleteFile}
            />}
            {!!formFiles.length && <UILIB.WarningHolder>
                File cannot be deleted as its in use on the following forms: <br />
                <ul style={{ textAlign: 'left' }}>
                    {formFiles.map(ff => <li key={ff.id}>{ff.Form.formName}</li>)}
                </ul>
            </UILIB.WarningHolder>}
        </div>
    );
}
import React from 'react'
import LoginPage from '~/pages/login/login';
import RegisterPage from '~/pages/login/register';
import ReminderPage from '~/pages/login/reminder';
import ResetPage from '../login/reset';
import { Router, Switch } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react'

import helpers from '~/Classes/helpers';

import { connect } from 'react-redux';
import queryString from 'query-string'
import GoogleAnalytics from '../../Classes/googleAnalytics';

@connect((store) => {
  return {
    user: store.user,
    siteMaster: store.siteMaster
  }
})

export default class LoginTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.loadContactGate = this.loadContactGate.bind(this);
  }

  loadContactGate(callBack) {
    if (this.props.location.pathname !== '/register') {
      const script = document.createElement("script");
      script.src = "https://app.contactgate.com/app/?uid=2015623-1";
      script.async = true;
      script.onreadystatechange = callBack;
      script.onload = callBack;
      document.body.appendChild(script);
    }

  }

  componentDidUpdate(prevProps) {
    if (prevProps.siteMaster && this.props.siteMaster) {
      if (prevProps.siteMaster.googleAnalyticsKey != this.props.siteMaster.googleAnalyticsKey) {
        try {
          GoogleAnalytics.trackGAPageView(this.props.history.location.pathname)
        }
        catch (err) { }
      }
    }

    if (this.props.user.loggedIn) {
      var query = queryString.parse(location.search);
      if (query.redirect) {
        helpers.safeRedirect(query.redirect, "self", this.props.history)
      } else {
        this.props.history.push('/cp')
      }
    }
  }

  render() {
    //check theyre logged in and if so redirect
    if (this.props.user.loggedIn) {
      var query = queryString.parse(location.search);

      if (query.redirect) {
        helpers.safeRedirect(query.redirect, "self", this.props.history)
      } else {
        window.open('/cp', '_self');
      }
      return null;
    }


    if (this.props.siteMaster.contactGateApiKey && this.props.siteMaster.contactGateApiKey.length > 3 && this.props.user.dataUpToDate == true && window.innerWidth > 1025) {
      this.loadContactGate(function () {

        contactGate.init({
          websiteVersion: 2,
          isAnon: 1
        });
        contactGate.startUp();
      })
    }

    return <div className="no-pad height-100-vh">
      <Router history={this.props.history}>
        <Switch>
          <ApmRoute exact path="/" component={LoginPage} history={this.props.history} />
          <ApmRoute exact path="/register" component={RegisterPage} history={this.props.history} />
          <ApmRoute exact path="/reminder" component={ReminderPage} history={this.props.history} />
          <ApmRoute exact path="/reset" component={ResetPage} history={this.props.history} />
        </Switch>
      </Router>


    </div>


  };
};
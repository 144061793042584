import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import NumberFormatFunctions from '../../../../Classes/numberFormatFunctions';

@connect((store) => {
	return { siteMaster: store.siteMaster }
})

export default class SmallLineGraphAlt extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {

	}

	componentDidUpdate(prevProps) {

	}


	render() {

		var width = this.props.width;
		var height = this.props.height;
		var val1 = this.props.val1;
		var val2 = this.props.val2;

		var percent = 0
		var percentRaw = 0;
		if (val2) {
			percentRaw = (val1 / val2) * 100;
			percent = Math.ceil(percentRaw);
		} else {
			percent = 100
		}

		var percentDisplay = NumberFormatFunctions.formatNumber(percentRaw, 2);
		var val1Display = NumberFormatFunctions.formatNumber(val1);
		var val2Display = NumberFormatFunctions.formatNumber(val2);
		// const viewBox = `0 0 ${width.replace('%', '')} ${height}`;

		var text = <div className={this.props.textAlign || ''} style={{display: 'flex', justifyContent: 'space-between'}}>
			<div>
				<span style={{ fontWeight: "bold" }}>{val1Display}{this.props.val1Label ? ' ' + this.props.val1Label : ''}</span>
				{val2 !== undefined && <span style={{ fontSize: "12px" }}> / {val2Display} </span>}
			</div>
			<div>({percentDisplay}%)</div>
		</div>

		if (this.props.onlyPercent) {
			text = <div className={this.props.textAlign || 'text-grey'}>
				<span>{percentDisplay}%</span>
			</div>
		}

		if (this.props.overrideText) {
			text = <div className={this.props.textAlign}>
				{this.props.overrideText}
			</div>
		}

		return <Fragment>
			
			<svg
				onClick={this.props.onClick}
				className={"smallLineGraph " + (this.props.className ? this.props.className : '')}
				width={width}
				height={height}
			>
				<defs>
					<linearGradient id="grad1">
						<stop offset="50%" stopColor={this.props.siteMaster.colours.$primary} stopOpacity="1" />
						<stop offset="100%" stopColor={this.props.siteMaster.colours.$secondary} stopOpacity="1" />
					</linearGradient>
				</defs>
				<rect width={percent + "%"} height={height} rx={height / 2} ry={height / 2} style={{ fill: this.props.color || "url(#grad1)", strokeWidth: "0" }} />
			</svg >
			
				{!this.props.hideText && text}
			
			

		</Fragment>
	}
}

import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js/fn/promise';
import { Router, Switch } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import thestore from './data/store/store'
import * as SiteActions from './data/actions/siteActions';
import { ApmRoute } from '@elastic/apm-rum-react'

import { createBrowserHistory as browserHistory } from 'history'

import LoginTemplate from '~/pages/masterTemplates/login.jsx';
import ControlPanelTemplate from '~/pages/masterTemplates/controlPanel.jsx';
import ControlPanelFullScreenTemplate from '~/pages/masterTemplates/controlPanelFullScreen.jsx'
import SiteOfflineTemplate from '~/pages/masterTemplates/siteOffline.jsx';
import PageOfflineTemplate from '~/pages/masterTemplates/pageNotFound.jsx';
import ActivateTemplate from '~/pages/masterTemplates/activate.jsx';
import UnsubscribePage from '~/pages/masterTemplates/unsubscribe.jsx';
import UpdatePage from './pages/masterTemplates/update.jsx';
import JunkPage from './pages/masterTemplates/junk';
import VerifyDomainPage from './pages/masterTemplates/verifyDomain.jsx';
import TemplatePreview from './pages/masterTemplates/templatePreview.jsx';
import ConfirmRegister from './pages/masterTemplates/register';
import FormPage from './pages/masterTemplates/form';
import Integrations from './pages/masterTemplates/integrations';
import ApiDocs from './pages/masterTemplates/apiDocs';
import GdprPage from './pages/masterTemplates/gdpr.jsx'
import OAuthAuthorise from './pages/masterTemplates/oAuth2/authorise';
import AppConnect from './pages/masterTemplates/appConnect/appConnect'; //this route is used for pushing people to who are connecting to MPZ from an external account but asks them to login or register first
import Widgets from './pages/masterTemplates/widget'
import UILIB from '~/Common-Objects/Lib.jsx';
import GoogleAnalytics from './Classes/googleAnalytics';
import TranspondTracking from './Classes/siteTrackingHelpers';

import './i18n';
import Fullpage from './Common-Objects/Fullpage';
import Soft2faTrigger from './pages/login/soft2fa/soft2faTrigger';
import Soft2faAccept from './pages/login/soft2fa/soft2faAccept';
import { UiSocket } from "~/Classes/socketsUi";
import Rum from './Classes/rum';

require('./stylesheets/app.css');

persistStore(thestore, {
    blacklist: ['siteMaster']
}, () => { thestore.dispatch });
//lookup the host URL and get the SITE STORE definitions
var hostFull = window.location.host.split('/')[0];
thestore.dispatch(SiteActions.getSiteFromURL(hostFull));

var doAnalytics = true;
if (hostFull.indexOf("adrom") > 0) {
    doAnalytics = false
}

const theHistory = browserHistory();
let socketsConnected = false;
let uiSocket = null;
let lastAccountMasterId = null;

if (doAnalytics == true) {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.id = "stripeLib";
    document.body.appendChild(script);
}

theHistory.listen(location => {
    let theState = thestore.getState();
    GoogleAnalytics.trackGAPageView(location.pathname);
    TranspondTracking.pageChange(theState.user.userData.emailAddress, null, 124555);
    thestore.dispatch(SiteActions.routeLocationDidUpdate(location))
});

//listen for redux state change
thestore.subscribe(() => {
    let theState = thestore.getState();
    if (theState.user.loggedIn && !socketsConnected) {
        uiSocket = new UiSocket();
        uiSocket.init();
        lastAccountMasterId = theState.accountMaster.id;
        socketsConnected = true;
        return;
    }
    if (theState.user.loggedIn == false && socketsConnected) {
        uiSocket.disconnect();
        socketsConnected = false;
        return;
    }
    if (theState.user.loggedIn && theState.accountMaster.id != lastAccountMasterId && socketsConnected) {
        uiSocket.disconnect();
        uiSocket.init();
        lastAccountMasterId = theState.accountMaster.id;
        return;
    }
});

Rum.init()

try {
    document.getElementsByClassName('initialLoad')[0].remove()
} catch (e) { }


ReactDOM.render(

    <Provider store={thestore}>
        <div>
            <Router history={theHistory}>

                <Switch>
                    <ApmRoute exact path="/" component={LoginTemplate} history={theHistory} />

                    {/* Registration and login stuff */}
                    <ApmRoute path="/register" component={LoginTemplate} history={theHistory} />
                    <ApmRoute path="/reminder" component={LoginTemplate} history={theHistory} />
                    <ApmRoute path="/reset" component={LoginTemplate} history={theHistory} />
                    <ApmRoute path="/activate" component={ActivateTemplate} history={theHistory} />
                    <ApmRoute path="/verifyDomain/:token?" component={VerifyDomainPage} history={theHistory} />
                    <ApmRoute path="/confirm" component={ConfirmRegister} history={theHistory} />
                    <ApmRoute path="/soft2fa" component={Soft2faTrigger} />
                    <ApmRoute path="/confirmlogin" component={Soft2faAccept} />

                    {/* campaign landing pages  */}
                    <ApmRoute path="/unsubscribe" component={UnsubscribePage} />
                    <ApmRoute path="/update" component={UpdatePage} />
                    <ApmRoute path="/junk" component={JunkPage} />
                    <ApmRoute path="/preview" component={TemplatePreview} />
                    <ApmRoute path="/gdpr" component={GdprPage} />
                    <ApmRoute path="/form" component={FormPage} />

                    {/* Integrations landing pages */}
                    <ApmRoute path="/integrations" component={Integrations} />

                    {/* Widgets */}
                    <ApmRoute path="/widgets" component={Widgets} />

                    {/* Oauth */}
                    <ApmRoute path="/oauth2/authorise" component={OAuthAuthorise} />
                    <ApmRoute path="/apidocs/:docId?" component={ApiDocs} />
                    <ApmRoute path="/appconnect/:appId?" component={AppConnect} />
                    {/* Normal site stuff */}
                    <ApmRoute exact path="/cp/templates/add/fromCode/:templateID?" component={ControlPanelFullScreenTemplate} history={theHistory} />
                    <ApmRoute exact path="/cp/templates/add/wysiwyg/:templateID?" component={ControlPanelFullScreenTemplate} history={theHistory} />
                    <ApmRoute exact path="/cp/templates/add/dragDrop/:templateID?" component={ControlPanelFullScreenTemplate} history={theHistory} />
                    <ApmRoute exact path="/cp/automation/addnew/:automationId?" component={ControlPanelFullScreenTemplate} history={theHistory} />
                    <ApmRoute exact path="/cp/company/servers/deliverymanager" component={ControlPanelFullScreenTemplate} history={theHistory} />

                    <ApmRoute exact path="/cp/conversations" component={ControlPanelTemplate} history={theHistory} />
                    <ApmRoute path="/cp/conversations/:param?" component={ControlPanelFullScreenTemplate} history={theHistory} />

                    <ApmRoute path="/cp" component={ControlPanelTemplate} history={theHistory} />
                    <ApmRoute path="/offline" component={SiteOfflineTemplate} history={theHistory} />

                    <ApmRoute component={PageOfflineTemplate} />

                </Switch >

                <UILIB.Drawer isOpen={false} showClose={true} direction="left" history={theHistory}></UILIB.Drawer>
                <UILIB.SnackBar global={true} />
                <Fullpage />
                <div id="footerHolder" />
            </Router >


        </div >
    </Provider >
    ,
    document.getElementById('root'));

registerServiceWorker();
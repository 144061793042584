export const NOT_SETUP = 1;
export const REQUIRES_VERIFICATION = 2;
export const REQUIRES_AUTHENTICATION = 3;
export const VERIFIED = 4;
export const AUTHENTICATED = 5;

export const SENDING_DOMAIN = {
  id: "sending",
  path: "domain",
  label: "Sending",
};
export const TRACKING_DOMAIN = {
  id: "tracking",
  path: "tracking",
  label: "Tracking",
};
export const CDN_DOMAIN = {
  id: "cdn",
  path: "cdn",
  label: "CDN",
};
export const TRANSPOND_CDN_DOMAIN = "cdn1.ourmailsender.com";

export function getDomainFromSubdomain(subdomain) {
  if (subdomain) {
    const [_, ...domain] = subdomain.split(".");
    return domain.join(".");
  }

  return null;
}

export function getDomainStatus(domain, type) {
  if (type.id === SENDING_DOMAIN.id) {
    return getSendingDomainStatus(domain);
  }

  return getSubdomainStatus(domain);
}

function getSendingDomainStatus(domain) {
  if (domain.authenticated) {
    return AUTHENTICATED;
  } else if (domain.verified) {
    return VERIFIED;
  }

  return REQUIRES_VERIFICATION;
}

function getSubdomainStatus(domain) {
  if (domain?.authenticated) {
    return AUTHENTICATED;
  } else if (domain?.authenticated === false) {
    return REQUIRES_AUTHENTICATION;
  }

  return NOT_SETUP;
}

export function mergeSubdomainsUnderSendingDomains({ sending, tracking, cdn }) {
  const trackingSubdomains = [];
  const otherTrackingSubdomains = tracking
    .filter((subdomain) => {
      return sending.every((domain) => {
        if (
          getDomainFromSubdomain(subdomain.domainName) !== domain.domainName
        ) {
          return true;
        }

        trackingSubdomains.push({
          ...subdomain,
          domainType: TRACKING_DOMAIN.id,
        });
        return false;
      });
    })
    .map((subdomain) => {
      return { ...subdomain, domainType: TRACKING_DOMAIN.id };
    });

  const cdnSubdomains = [];
  const otherCdnSubdomains = cdn
    .filter((subdomain) => {
      return sending.every((domain) => {
        if (
          getDomainFromSubdomain(subdomain.domainName) !== domain.domainName
        ) {
          return true;
        }

        cdnSubdomains.push({ ...subdomain, domainType: CDN_DOMAIN.id });
        return false;
      });
    })
    .map((subdomain) => {
      return { ...subdomain, domainType: CDN_DOMAIN.id };
    });

  const sendingMerged = sending.map((domain) => {
    const matchingTrackingSubdomains = splitMatchingSubdomains(
      domain.domainName,
      trackingSubdomains
    );

    const matchingCdnSubdomains = splitMatchingSubdomains(
      domain.domainName,
      cdnSubdomains
    );

    return {
      ...domain,
      domainType: SENDING_DOMAIN.id,
      trackingSubdomains: matchingTrackingSubdomains,
      cdnSubdomains: matchingCdnSubdomains,
    };
  });

  return [...sendingMerged, ...otherTrackingSubdomains, ...otherCdnSubdomains];
}

function splitMatchingSubdomains(domainName, subdomains) {
  return subdomains.filter((subdomain) => {
    return getDomainFromSubdomain(subdomain.domainName) === domainName;
  });
}

import React, { Component } from 'react';
import CampaignMergeTags from '~/pages/cp/includes/mergeTags/campaignMergeTags';
import HookFilter from './hookFilter'
import UILIB from '~/Common-Objects/Lib'

class IntegrationHook extends Component {
    constructor(props) {
        super(props)
        this.state = {
            drawerContent: undefined,
            drawerOpen: false,
            drawerPosX: 0,
            drawerPosY: 0
        }

        this.update = this.update.bind(this)
        this.updateDate = this.updateDate.bind(this)
        this.updateArray = this.updateArray.bind(this)
        this.openMergeTags = this.openMergeTags.bind(this)
        this.insertMergeTag = this.insertMergeTag.bind(this)
        this.setFilters = this.setFilters.bind(this)
        this.addRow = this.addRow.bind(this)
        this.removeRow = this.removeRow.bind(this)
    }

    componentDidMount() {
        if (this.props.hook.build.some(b => b.multi)) {
            this.props.hook.build.filter(b => b.multi).forEach(build => {
                if (!Array.isArray(this.props.action[build.name]) || !this.props.action[build.name].length) {
                    this.props.action[build.name] = []
                }
            })
        }
    }

    update(ev, option, row) {
        var action = this.props.action
        if (option) {
            action[option][row][ev.target.name] = ev.target.value
        } else {
            action[ev.target.name] = ev.target.value
        }
        this.setState({})
        if (this.props.update) this.props.update()
    }

    updateDate(field, value, option, row) {
        var action = this.props.action
        if (option) {
            action[option][row][field] = value
        } else {
            action[field] = value
        }
        this.setState({})
        if (this.props.update) this.props.update()
    }

    updateArray(field, value) {
        var action = this.props.action
        let currentValue = action[field]
        if (!Array.isArray(currentValue)) currentValue = []
        let index = currentValue.indexOf(value)
        if (index === -1) currentValue.push(value)
        else currentValue.splice(index, 1)
        action[field] = currentValue
        this.setState({})
        if (this.props.update) this.props.update()
    }

    openMergeTags(event, field, option, row) {


        var child = event.target.getBoundingClientRect();
        var childPosX = child.left;
        var childPosY = child.top;
        var childHeight = child.height;
        var parent = document.getElementById("hookOuterHolder").getBoundingClientRect();
        var parentPosX = parent.left;
        var parentPosY = parent.top;

        var y = childPosY - parentPosY + (50);
        var x = childPosX - parentPosX;
        x -= 600 - child.width;



        var drawerContent = <CampaignMergeTags hasWorkflowEmails={true} includeSubscriber={true} includeSubCustomFields={true} close={() => {
            this.setState({ drawerOpen: false, drawerContent: undefined })
        }} fieldName={field} hasParentCampaign={true} insertMergeTag={(value) => {
            this.insertMergeTag(field, value, option, row)
        }} />
        this.setState({
            drawerContent, drawerOpen: true,
            drawerPosX: x,
            drawerPosY: y
        })
    }

    insertMergeTag(field, value, option, row) {
        var action = this.props.action
        var currVal
        if (option) {
            currVal = action[option][row][field]
        } else {
            currVal = action[field]
        }

        if (currVal === undefined) currVal = ""
        currVal += " " + value;

        if (option) {
            action[option][row][field] = currVal
        } else {
            action[field] = currVal
        }

        this.setState({
            drawerContent: undefined, drawerOpen: true
        })
        if (this.props.update) this.props.update()
    }

    setFilters() {
        var drawerContent = <HookFilter CampaignId={this.props.CampaignId} link={this.props.link} type={this.props.type.value} hook={this.props.action} close={() => {
            this.setState({
                drawerContent: undefined, drawerOpen: false
            })
        }} />
        this.setState({
            drawerContent, drawerOpen: true
        })
    }

    addRow(name) {
        if (!Array.isArray(this.props.action[name])) {
            this.props.action[name] = []
        }
        this.props.action[name].push({})
        this.setState({})
    }

    removeRow(name, index) {
        this.props.action[name].splice(index, 1)
        this.setState({})
    }

    render() {
        // the hook (like update contact etc)
        var hook = this.props.hook
        // this instance of the hook
        var action = this.props.action

        return <div >

            {hook.build.filter(b => !b.multi).map((item, itemIndex) => {
                var options = Array.isArray(item.options) ? item.options.slice() : item.options
                if (typeof options === 'string') {
                    options = Array.isArray(this.props[options]) ? this.props[options].slice() : this.props[options]
                }
                var type = item.type
                if (type.indexOf('__lookup=') === 0) {
                    // look it up based on the a value from another build option
                    // example __lookup=field.type will use another build with the value of field and use the type from the selected option
                    let lookup = item.type.split('=')[1]
                    let prop = ''
                    if (lookup.indexOf('.') > -1) {
                        let split = lookup.split('.')
                        lookup = split[0]
                        prop = split[1]
                    }

                    let found = hook.build.find(b => b.value === lookup)
                    if (found) {
                        if (!Array.isArray(found.options)) {
                            found.options = this.props[found.options].slice()
                        }
                        if (Array.isArray(found.options)) {
                            let option = found.options.find(o => (o.value || o) == action[found.value])
                            if (option) {
                                type = option.type || 'text'
                                if (type === 'select' || type === 'boolean' || type === 'multiselect') options = option.options
                            }
                        }
                    }
                }
                var dateselect = ''
                var numberselect = ''
                var actionDateOptions = [{ label: "Set to the date of the action", value: "__now" }]
                var tmpCnt = 1;
                if (type === 'datetime') {
                    if (action[item.value] && typeof action[item.value] != "object" && action[item.value].indexOf("__now") > -1) {
                        dateselect = '__now'
                        while (tmpCnt < 90) {
                            actionDateOptions.push({ label: `Action Date +${tmpCnt} days`, value: `__now_` + tmpCnt })
                            tmpCnt++;
                        }

                    }
                }

                if (type === 'number') {
                    if (action[item.value] === '__inc') {
                        numberselect = '__inc'
                    }
                    if (action[item.value] === '__dec') {
                        numberselect = '__dec'
                    }
                }

                if (type === 'select' && !options) return null

                let multiSelectValue = '--None--';
                if (type === 'multiselect') {
                    if (!Array.isArray(action[item.value])) action[item.value] = []
                    if (action[item.value].length) multiSelectValue = action[item.value].map(v => {
                        let option = options.find(o => o.value == v)
                        if (option) return option.label;
                        return v
                    }).join(', ')
                }

                return <div className="mar-b10" key={"appOption_" + itemIndex}>
                    {type === 'text' && <UILIB.TextInput label={item.label} labelOptions={item.mergeTags && <a onClick={(event) => this.openMergeTags(event, item.value)}>Merge Tags</a>} style={{ backgroundColor: 'transparent' }} placeholder={item.placeholder || "Select a " + item.label} name={item.value} value={action[item.value]} onChange={this.update} error={item.valid === false ? 'Required' : ''} />}
                    {type === 'select' && <UILIB.Select label={item.label} labelOptions={item.mergeTags && <a onClick={(event) => this.openMergeTags(event, item.value)}>Merge Tags</a>} data={options} placeholder={item.placeholder || "Select a " + item.label} name={item.value} value={action[item.value]} onChange={this.update} error={item.valid === false ? 'Required' : ''} />}
                    {type === 'boolean' && <UILIB.Select label={item.label} labelOptions={item.mergeTags && <a onClick={(event) => this.openMergeTags(event, item.value)}>Merge Tags</a>} data={options || [{ label: "True", value: 1 }, { label: "False", value: 0 }]} placeholder={item.placeholder || "Select a " + item.label} name={item.value} value={action[item.value]} onChange={this.update} error={item.valid === false ? 'Required' : ''} />}
                    {type === 'number' && <div className="row">
                        {!item.fixed && <div className={numberselect ? 'col-xs-12' : 'col-xs-6'} style={{ margin: 'auto 0 0 0' }}>
                            <UILIB.Select style={{ backgroundColor: 'transparent' }} data={[{ label: 'Increment', value: '__inc' }, { label: 'Decrement', value: '__dec' }, { label: 'Specific Number', value: '' }]} value={numberselect} name={item.value} onChange={this.update} error={item.valid === false ? 'Required' : ''} />
                        </div>}
                        {(!numberselect || item.fixed) && <div className={item.fixed ? 'col-xs-12' : "col-xs-6"}>
                            <UILIB.TextInput label={item.label} labelOptions={<a onClick={(event) => this.openMergeTags(event, item.value)}>Merge Tags</a>} style={{ backgroundColor: 'transparent' }} type="number" placeholder={item.placeholder || "Select a " + item.label} name={item.value} value={action[item.value]} onChange={this.update} error={item.valid === false ? 'Required' : ''} />
                        </div>}
                    </div>}

                    {type === 'multiselect' && <UILIB.ButtonSelect inputType="text" headerText={multiSelectValue}>
                        {options.map(o => {
                            return <div><UILIB.CheckBox labelClass="text-black" className="green" onChange={() => this.updateArray(item.value, o.value)} checked={action[item.value].indexOf(o.value) > -1}>{o.label}</UILIB.CheckBox></div>
                        })}
                    </UILIB.ButtonSelect>}

                    {type === 'datetime' && <div className="row" style={{ alignItems: 'flex-end' }}>
                        <div className="col-xs-6" >
                            <UILIB.Select label={item.label} style={{ backgroundColor: 'transparent' }} data={[{ label: 'Action Date', value: '__now' }, { label: 'Specific Date', value: '' }]} value={dateselect} name={item.value} onChange={this.update} error={item.valid === false ? 'Required' : ''} />
                        </div>
                        {dateselect && <div className="col-xs-6">
                            <UILIB.Select style={{ backgroundColor: 'transparent' }} data={actionDateOptions} name={item.value} value={action[item.value]} onChange={this.update} error={item.valid === false ? 'Required' : ''} />
                        </div>}
                        {!dateselect && <div className="col-xs-6">
                            <UILIB.TextInput style={{ backgroundColor: 'transparent' }} type="date" field={item.value} value={action[item.value]} onChange={this.updateDate} error={item.valid === false ? 'Required' : ''} />
                        </div>}
                    </div>}
                </div>
            })}

            {hook.build.filter(b => b.multi).map((item, index) => {
                let rows = action[item.name]
                if (!Array.isArray(rows)) return <div></div>
                return <div key={'multi_' + index}>
                    {Boolean(item.label) && <div className="mar-b10 text-heavy">{item.label}</div>}
                    {rows.map((row, rowIndex) => {
                        return <UILIB.Row key={'multi_' + index + '_row_' + rowIndex}>
                            {item.columns.map((column, colIndex) => {
                                var options = Array.isArray(column.options) ? column.options.slice() : column.options
                                if (typeof options === 'string') {
                                    options = Array.isArray(this.props[options]) ? this.props[options].slice() : this.props[options]
                                }
                                var type = column.type
                                if (type.indexOf('__lookup=') === 0) {
                                    // look it up based on the a value from another build option
                                    // example __lookup=field.type will use another build with the value of field and use the type from the selected option
                                    let lookup = type.split('=')[1]
                                    let prop = ''
                                    if (lookup.indexOf('.') > -1) {
                                        let split = lookup.split('.')
                                        lookup = split[0]
                                        prop = split[1]
                                    }

                                    let found = item.columns.find(c => c.value === lookup)
                                    if (found) {
                                        if (!Array.isArray(found.options)) {
                                            found.options = this.props[found.options].slice()
                                        }
                                        if (Array.isArray(found.options)) {
                                            let option = found.options.find(o => (o.value || o) == row[found.value])
                                            if (option) {
                                                type = option.type || 'text'
                                                if (type === 'select' || type === 'multiselect') options = option.options
                                            }
                                        }
                                    } else {
                                        type = 'text'
                                    }
                                }

                                var dateselect = ''
                                var numberselect = ''
                                var actionDateOptions = [{ label: "Set to the date of the action", value: "__now" }]
                                var tmpCnt = 1;
                                if (type === 'datetime') {
                                    if (row[column.value] && typeof row[column.value] != "object" && row[column.value].indexOf("__now") > -1) {
                                        dateselect = '__now'
                                        while (tmpCnt < 90) {
                                            actionDateOptions.push({ label: `Action Date +${tmpCnt} days`, value: `__now_` + tmpCnt })
                                            tmpCnt++;
                                        }

                                    }
                                }

                                if (type === 'number') {
                                    if (row[column.value] && row[column.value].value === '__inc') {
                                        numberselect = '__inc'
                                    }
                                    if (row[column.value] && row[column.value].value === '__dec') {
                                        numberselect = '__dec'
                                    }
                                }

                                let itemValue = ""
                                if (row[column.value] !== undefined) {
                                    itemValue = row[column.value]
                                }

                                let multiSelectValue = '--None--';
                                if (type === 'multiselect') {
                                    if (!Array.isArray(itemValue)) itemValue = []

                                    if (itemValue.length) multiSelectValue = itemValue.map(v => {
                                        let option = options.find(o => o.value == v)
                                        if (option) return option.label;
                                        return v
                                    }).join(', ')
                                }

                                return <UILIB.Column xs={column.width} key={'multi_' + index + '_row_' + rowIndex + '_col_' + colIndex} className="form-group">
                                    <label htmlFor="">{column.label}
                                        {column.mergeTags && type === 'text' && <div style={{ position: "absolute", right: "0", top: "0" }}><a onClick={(e) => this.openMergeTags(e, column.value, item.name, rowIndex)}>Merge Tags</a></div>}
                                    </label>
                                    {type === 'text' && <UILIB.TextInput placeholder={column.placeholder || column.label} name={column.value} value={itemValue} onChange={e => this.update(e, item.name, rowIndex)} />}
                                    {type === 'boolean' && <UILIB.CheckBox checked={itemValue} onChange={e => this.update(e, item.name, rowIndex)} name={column.value}>{column.label}</UILIB.CheckBox>}
                                    {type === 'select' && <UILIB.Select data={options} placeholder={column.placeholder || column.label} name={column.value} value={itemValue} onChange={e => this.update(e, item.name, rowIndex)} />}
                                    {type === 'number' && <div className="row">
                                        {!column.fixed && <div className={numberselect ? 'col-xs-12' : 'col-xs-6'}>
                                            <UILIB.Select data={[{ label: 'Increment', value: '__inc' }, { label: 'Decrement', value: '__dec' }, { label: 'Specific Number', value: '' }]} value={numberselect} name={column.value} onChange={e => this.update(e, item.name, rowIndex)} />
                                        </div>}
                                        {!numberselect && <div className={column.fixed ? 'col-xs-12' : "col-xs-6"}>
                                            <UILIB.TextInput type="number" placeholder={column.placeholder || column.label} name={column.value} value={itemValue} onChange={e => this.update(e, item.name, rowIndex)} />
                                        </div>}
                                    </div>}
                                    {type === 'multiselect' && <UILIB.ButtonSelect inputType="text" headerText={multiSelectValue}>
                                        {options.map(o => {
                                            return <div><UILIB.CheckBox onChange={ev => this.updateArray(item.value, o.value, item.name, rowIndex)} checked={itemValue.indexOf(o.value) > -1}>{o.label}</UILIB.CheckBox></div>
                                        })}
                                    </UILIB.ButtonSelect>}
                                    {type === 'datetime' && <div className="row">
                                        <div className={'col-xs-4'}>
                                            <UILIB.Select data={[{ label: 'Action Date', value: '__now' }, { label: 'Specific Date', value: '' }]} value={dateselect} name={column.value} onChange={e => this.update(e, item.name, rowIndex)} />
                                        </div>
                                        {dateselect && <div className="col-xs-8">
                                            <UILIB.Select style={{ backgroundColor: 'transparent' }} data={actionDateOptions} name={column.value} value={itemValue} onChange={e => this.update(e, item.name, rowIndex)} />
                                        </div>}
                                        {!dateselect && <div className="col-xs-8">
                                            <UILIB.TextInput style={{ backgroundColor: 'transparent' }} type="date" field={column.value} placeholder={column.placeholder || column.label} value={itemValue} onChange={(f, v) => this.updateDate(f, v, item.name, rowIndex)} />
                                        </div>}
                                    </div>}
                                </UILIB.Column>
                            })}
                            <UILIB.Column xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                                <UILIB.Button text="X" className="button-red" onClick={() => this.removeRow(item.name, rowIndex)} />
                            </UILIB.Column>
                        </UILIB.Row>
                    })}
                    <UILIB.Button text="Add" onClick={() => this.addRow(item.name)} className="button-primary" />
                </div>
            })}

            {
                (this.state.drawerOpen && this.state.drawerContent) && <div style={{ width: 600, left: this.state.drawerPosX, top: this.state.drawerPosY, position: "absolute", zIndex: 99 }}>
                    <UILIB.Paper className="no-shadow" style={{ maxHeight: 400, overflow: 'auto', }}>{this.state.drawerContent}</UILIB.Paper>
                </div>
            }


        </div >
    }
}

export default IntegrationHook;
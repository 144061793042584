
import React, { useEffect, useState } from 'react';
import UILIB from '../../../../../Common-Objects/Lib';
import axios from '../../../../../data/http/axios';
import i18n from '../../../../../i18n';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SiteVars from '~/Classes/siteVars';
import TemplateImageLoader from '~/pages/cp/automation/addNew/templateImageLoader.jsx';
import DateTimeFunctions from '../../../../../Classes/dateTimeFunctions';
import FormatNumberFuncs from '../../../../../Classes/numberFormatFunctions';
import { handleGenericError, alterSnackbar, alterSiteDrawer, } from "../../../../../data/actions/siteActions";
import copy from 'copy-to-clipboard';
import helpers from '~/Classes/helpers'
import CampaignFolders from '../../folders'
import SmsIPhone from '~/pages/cp/includes/sms/iphone'

export default function FinishedAddingCampaign({ match }) {
    const dispatch = useDispatch();
    const account = useSelector((state) => state.accountMaster);
    const siteMaster = useSelector((state) => state.siteMaster);
    const user = useSelector((state) => state.user);
    const history = useHistory();
    const [campaignId, setCampaignId] = useState(null)
    const [campaign, setCampaign] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [socialDrawer, setSocialDrawer] = useState(undefined)
    const [hasSocialsBeta, setHasSocialsBeta] = useState(account.accountMaster?.beta?.social || false)
    const [key, setKey] = useState(null)
    const [subscriberCount, setSubscriberCount] = useState(0)
    const [template, setTemplate] = useState(null)
    const [analysis, setAnalysis] = useState(null)
    useEffect(() => {
        const campaignId = match?.params?.campaignId;
        if (!campaignId) {
            history.push('/cp/campaigns')
        }
        setCampaignId(campaignId);
    }, [])

    useEffect(() => {
        if (campaignId) {
            loadCampaign();
        }
    }, [campaignId])

    useEffect(() => {
    if (campaign.TemplateId) {
        axios.get('/template/' + campaign.TemplateId).then(res => setTemplate(res.data.template))
    }
    }, [campaign.TemplateId])

    
    useEffect( () => {
        if(campaignId){
            const res =  axios.get('/campaign/' + campaignId + '/sms/estimate').then(res => setAnalysis(res.data))
            
        }
    }, [campaignId])

    

    const loadCampaign = async () => {
        try {
            let campaign = await axios.get('/campaign/' + campaignId);
            campaign = campaign.data.Campaign;

            const key = await helpers.createKey(0, 0, 0, campaignId)

            //count recipients
            var queryStr = '?ignoreLog=1'
            if (campaign.options?.filterSegments) queryStr += '&filterSegments=true'

            const stats = await axios.post('/campaign/count' + queryStr, {
                Segments: campaign.Segments,
                Groups: campaign.Groups,
                smsField: campaign?.options?.smsField
            });


            setSubscriberCount(stats.data.Count || 0)
            setCampaign(campaign);
            setLoaded(true);
            setKey(key);
            if (campaign.status == "Draft") {
                redirectToEditCampaign(campaign);
                return;
            }
        }
        catch (error) {
            history.push('/cp/campaigns')
        }
    }

    const shareClicked = (theVal) => {
        copy(theVal);
        dispatch(alterSnackbar(true, i18n.t("chat:channel.chat.design.saved")));
    }

    const createSocialCampaign = async () => {
        try {
            setLoaded(false);
            const newCampaign = await axios.post('/campaign', {
                campaignName: campaign.campaignName + " - Social Post",
                type: 30
            });
            await axios.put('/campaign/' + newCampaign.data.Campaign.id + "/socialpost/fromcampaign/" + campaign.id);
            history.push('/cp/campaigns/add/social/' + newCampaign.data.Campaign.id + '/1');

            setLoaded(true);
        }
        catch (error) {
            dispatch(handleGenericError("There was a problem creating a social campaign, please try again."));
        }
    }

    const editCampaignWarning = () => {
        const drawerContent = <UILIB.Dialog.Root open={true}>
            <UILIB.Dialog.Portal >
                <UILIB.Dialog.Overlay />
                <UILIB.Dialog.Content>
                    <div style={{ padding: "40px" }}>
                        <h4 className="mar-b10">Are you sure?</h4>
                        <div className="mar-b10">Editing this campaign will put the campaign back into <strong>draft mode</strong>.</div>
                        <div>
                            <UILIB.Button className="button-red mar-r10" text={"Yes, edit this campaign"} onClick={goEditCampaign} />
                            <UILIB.Button text="Cancel" onClick={() => { setSocialDrawer(undefined) }} />
                        </div>
                    </div>
                </UILIB.Dialog.Content>
            </UILIB.Dialog.Portal>
        </UILIB.Dialog.Root>;

        setSocialDrawer(drawerContent);
    }

    const goEditCampaign = async () => {
        try {
            await axios.put('/campaign/' + campaign.id + '/cancel');
            redirectToEditCampaign();

        }
        catch (error) {
            dispatch(handleGenericError("There was a problem editing the campaign, please try again."));
        }
    }

    const redirectToEditCampaign = (camp) => {
        if (!camp) camp = campaign
        var url = ''
        switch (camp.type) {
            case 1:
                url = '/cp/campaigns/add/standard/' + camp.id + '/1'
                break;
            case 10:
                url = '/cp/campaigns/add/ab/' + camp.id
                break;
            case 20:
                url = '/cp/campaigns/add/directMail/' + camp.id
                break;
            case 3:
                url = '/cp/campaigns/add/automation/' + camp.id
                break;
            case 30:
                url = '/cp/campaigns/add/social/' + camp.id + '/1'
                break;
            case 6:
                url = '/cp/campaigns/add/sms/' + camp.id + '/1'
                break;
        }
        history.push(url)
    }

    const folders = () => {
        const content = <CampaignFolders campaign={campaign} onFinish={() => dispatch(alterSiteDrawer(false, true, 'right', null))} />
        dispatch(alterSiteDrawer(true, true, 'right', content, true, 400))
    }

    var totalSubs = subscriberCount

    if (campaign.limit && Number(campaign.limit) > 0) {
		if (Number(campaign.limit) < totalSubs) totalSubs = Number(campaign.limit)
	}


    if (!template) return <UILIB.LoadingIcons iconType="2" />

    const result = helpers.getSmsCredits(campaign, template)


    if (!loaded) return <div></div>

    const isMpz = siteMaster.siteId == 1;
    const isSms = campaign.type === 6
    const thisShareUrl = "https://" + siteMaster.trackingDomain + "/api/preview?key=" + key;
    const scheduled = campaign.startDate && new Date(campaign.startDate) > new Date();

    return <div>
        <UILIB.Row>
            <UILIB.Column xs={12} sm={12} md={12} lg={6}>
                <UILIB.Paper className="mar-b0">
                    {scheduled && <>
                        <h2 className="mar-b10">Your Campaign is Scheduled!</h2>
                        <p className="text-grey mar-b25">It will be to sent to {FormatNumberFuncs.formatNumber(totalSubs)} contacts on {DateTimeFunctions.formatDateTime(campaign.startDate, 0)}</p>
                    </>}
                    {!scheduled && <>
                        <h2 className="mar-b10">Your Campaign is on its way!</h2>
                        <p className="text-grey mar-b25">It will be sent immediately to {FormatNumberFuncs.formatNumber(totalSubs)} subscribers</p>
                    </>}
                    <UILIB.Paper className="paper-padding-24">
                        <p className="text-md text-heavy">Campaign Details</p>
                        <UILIB.Row>
                            <UILIB.Column xs={12} md={12}>
                                <p className="text-heavy mar-b0">Name</p>
                                <p>{campaign.campaignName}</p>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6}>
                                <p className="text-heavy mar-b0">From Name</p>
                                <p>{campaign.subject}</p>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6}>
                                <p className="text-heavy mar-b0">Start Date</p>
                                <p>{DateTimeFunctions.formatDateTime(campaign.startDate, 1)}</p>
                            </UILIB.Column>

                            <UILIB.Column xs={12} sm={6}>
                                <p className="text-heavy mar-b0">Recipients</p>
                                {!!analysis && analysis.filter(a => a.count).map(a => <div key={'co_' + a.country} className='mar-b10'>{FormatNumberFuncs.formatNumber(totalSubs)}</div>)}
                            </UILIB.Column>

                            <UILIB.Column xs={12} sm={6}>
                                <p className="text-heavy mar-b0">Credits Required</p>
                                {!!analysis && analysis.filter(a => a.count).map(a => <div key={'cr_' + a.country} className='mar-b10'>{FormatNumberFuncs.formatNumber(totalSubs * result.messages * 5)}</div>)}
                            </UILIB.Column>

                            <UILIB.Column xs={12} sm={6}>
                                <p className="text-heavy mar-b5">Groups</p>
                                {campaign.Groups.map((row, index) => {
                                    return <UILIB.SquareChip
                                        tooltip={"Group: " + row.groupName}
                                        for={row.groupName}
                                        key={"groupInc_" + index}
                                        className={"mar-r10 mar-b5 square-chip-green"}
                                    >
                                        {row.groupName}
                                    </UILIB.SquareChip>

                                })}
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6}>
                                <p className="text-heavy mar-b5">Segments</p>
                                {campaign.Segments.map((row, index) => {
                                    return <UILIB.SquareChip
                                        tooltip={"Segment: " + row.name}
                                        for={row.name}
                                        key={"segmentInc" + index}
                                        className={"mar-r10 mar-b5 square-chip-purple"}
                                    >
                                        {row.name}
                                    </UILIB.SquareChip>

                                })}
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>

                    {isMpz && !isSms && <UILIB.Paper className="mar-b0 paper-padding-24" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                        <div style={{ display: "flex", gap: 8 }} className="mar-b16">
                            <UILIB.Icons icon="facebookColorRounded" style={{ height: 32, width: 32, borderRadius: 100 }} color="#1877F2" />
                            <UILIB.Icons icon="instagramColorRounded" style={{ height: 32, width: 32, borderRadius: 100 }} color="#E63A7E" />
                            <UILIB.Icons icon="xColorRounded" style={{ height: 32, width: 32, borderRadius: 100 }} color="#000000" />
                            <UILIB.Icons icon="linkedInColorRounded" style={{ height: 32, width: 32, borderRadius: 100 }} color="#0A66C2" />

                        </div>
                        <p className="text-md text-heavy">Share this Campaign</p>
                        <p className="text-grey">Design, schedule, and publish posts across Facebook, X (formerly Twitter), Instagram and LinkedIn all from one place, then monitor their success and impact.</p>
                        {!hasSocialsBeta && <div style={{ display: "flex" }}>
                            <UILIB.Button text={"Enable and create Campaign"}
                                iconRight={<UILIB.SquareChip
                                    tooltip={"Beta Feature"}
                                    for={"Beta Feature"}
                                    className={"mar-r10  square-chip-purple"}
                                >Labs</UILIB.SquareChip>}
                                onClick={() => {
                                    setSocialDrawer(<UILIB.LabsEnable labId={9} onClose={(labAdded) => { setSocialDrawer(undefined); setHasSocialsBeta(labAdded) }} />)
                                }} className="mar-r10" />

                            <UILIB.Hint
                                onClick={() => {
                                    setSocialDrawer(<UILIB.LabsMoreInfo labId={9} onClose={() => { setSocialDrawer(undefined) }} />)
                                }}
                                iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>
                                Learn More
                            </UILIB.Hint>


                        </div>}

                        {hasSocialsBeta && <>
                            <UILIB.Button text="Create a Social Campaign" onClick={createSocialCampaign} />
                        </>}

                    </UILIB.Paper>}
                    {isMpz && !isSms && <UILIB.Paper className="mar-t0 paper-padding-24" style={isMpz ? { borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 } : {}}>
                        <p className="text-md text-heavy">{isMpz ? "Or share manually" : "Share"}</p>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ paddingRight: 10, borderRight: "1px solid #D9DDE3" }}>
                                <UILIB.SocialSharer
                                    url={thisShareUrl}
                                    title={campaign.subject}
                                    summary={i18n.t('campaigns:FinishedAddingCampaignScreen.shareText') + " \'" + campaign.subject + "\'"}
                                    imageUri={`${SiteVars.repoEndPoint}userContent/` + user.userID + "/templates/screenshots/template" + campaign.Template.id + ".jpg"}
                                    hashTags={campaign.subject.replace(",", "") + ",email,emailcampaign,latestnews"}
                                />
                            </div>

                            <div style={{ paddingLeft: 15, flex: 1 }}>
                                <UILIB.TextInput
                                    value={thisShareUrl}
                                    iconRight={<UILIB.Icons icon="copyClipboard" onClick={() => { shareClicked(thisShareUrl) }} />}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </UILIB.Paper>}

                    {!scheduled && <div className="quickFlex">
                        <UILIB.Button className="mar-r10" onClick={folders}>Add to Folders</UILIB.Button>
                        <UILIB.Button text="View all Campaigns" onClick={() => { history.push("/cp/campaigns") }} />
                        <UILIB.Button className="button-primary mar-l10" text="View Analytics" onClick={() => { history.push("/cp/campaigns/view/" + campaignId) }} iconLeft={<UILIB.Icons icon="magnifier" />} />
                    </div>}
                    {scheduled && <div className="quickFlex">
                        <UILIB.Button className="mar-r10" onClick={folders}>Add to Folders</UILIB.Button>
                        <UILIB.Button text="View all Campaigns" onClick={() => { history.push("/cp/campaigns") }} />
                        <UILIB.Button className="button-primary mar-l10" text="Edit Campaign" onClick={editCampaignWarning} iconLeft={<UILIB.Icons icon="pencil" />} />
                    </div>
                    }

                </UILIB.Paper>
            </UILIB.Column>
            <UILIB.Column xs={12} sm={12} md={12} lg={6}>
                <UILIB.Paper className="mar-b0" style={{ height: "100%", display: 'flex', justifyContent: 'center' }}>
                    {!isSms &&<TemplateImageLoader
                        onClick={() => { }}
                        type={1}
                        imageUrl={SiteVars.repoEndPoint + 'userContent/' + account.id + "/templates/screenshots/template" + campaign.TemplateId + ".jpg"}
                    />}

                    {isSms && <SmsIPhone from={campaign.subject} content={result.html} />}
                </UILIB.Paper>
            </UILIB.Column>
        </UILIB.Row>
        {socialDrawer}

    </div>
}

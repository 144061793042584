import React from 'react'
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import AdvancedTemplate from './advanced/advanced'
import i18n from '~/i18n'
import Themes from './dragDrop/themes'
import TemplateAddCard from './templateAddCard';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class TemplatesTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.openFromCode = this.openFromCode.bind(this);
        this.openWysiwyg = this.openWysiwyg.bind(this);
        this.openDragDrop = this.openDragDrop.bind(this);
        this.openAdvanced = this.openAdvanced.bind(this);
    }



    openFromCode() {
        this
            .props
            .history
            .push('/cp/templates/add/fromCode/');
    }

    openWysiwyg() {
        this
            .props
            .history
            .push('/cp/templates/add/wysiwyg/');
    }

    openDragDrop(type) {
        var query = ''
        if (type) {
            query = '?type=' + type

            this
                .props
                .history
                .push('/cp/templates/add/dragdrop' + query);
        } else {
            var drawerContent = <Themes history={this.props.history} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true, '100vw', 'full'));
        }
    }

    openAdvanced() {
        var drawerContent = <AdvancedTemplate history={this.props.history} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true, '100vw', 'full'));
    }

    render() {
        if (this.props.match && this.props.match.params && this.props.match.params.editorTypeId) {
            if (this.props.match.params.editorTypeId == 1) {
                //just show drag and drop editor
                let defaultThemeGroupId = null;
                if (this.props.match.params.defaultThemeGroupId) defaultThemeGroupId = this.props.match.params.defaultThemeGroupId
                return <UILIB.Paper>
                    <Themes history={this.props.history} defaultThemeGroupId={defaultThemeGroupId} />
                </UILIB.Paper>
            }
        }

        return <UILIB.Paper>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={6} lg={6} className="quickFlex mar-b25">
                    <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={() => { this.props.history.push('/cp/templates/') }}>{i18n.t('templates:add.goBackToTemplates')}</UILIB.Hint>
                </UILIB.Column>


                <UILIB.Column xs="12" >
                    <h4 className="mar-b25">{i18n.t('templates:add.header')}</h4>
                </UILIB.Column>

                <UILIB.Column xs={12} sm={6}>

                    <TemplateAddCard
                        icon="move"
                        title={i18n.t('templates:add.dragTitle')}
                        subTitle={i18n.t('templates:add.dragSubTitle')}
                        buttonText={i18n.t('templates:add.dragButton')}
                        buttonClick={() => { this.openDragDrop() }}
                        buttonClass="button-primary"
                        showDivider={false}
                        showImage={"dragDrop"}
                        paper={true}
                        paperStyle={{ height: "100%", display: "flex", flexDirection: "column" }}
                    />

                </UILIB.Column>
                <UILIB.Column xs={12} sm={6}>

                    <UILIB.Paper className="mar-b0">

                        <TemplateAddCard
                            icon="list"
                            title={i18n.t('templates:add.wysiTitle')}
                            subTitle={i18n.t('templates:add.wysiSubTitle')}
                            buttonText={i18n.t('templates:add.wysiButton')}
                            buttonClick={this.openWysiwyg}
                            buttonClass=""
                            showDivider={true}
                            showImage={""}
                        />

                        <TemplateAddCard
                            icon="code"
                            title={i18n.t('templates:add.codeTitle')}
                            subTitle={i18n.t('templates:add.codeSubTitle')}
                            buttonText={i18n.t('templates:add.codeButton')}
                            buttonClick={this.openFromCode}
                            buttonClass=""
                            showDivider={true}
                            showImage={""}
                        />

                        <TemplateAddCard
                            icon="list"
                            title={i18n.t('templates:add.advancedTitle')}
                            subTitle={i18n.t('templates:add.advancedSubTitle')}
                            buttonText={i18n.t('templates:add.advancedButton')}
                            buttonClick={this.openAdvanced}
                            buttonClass=""
                            showDivider={false}
                            showImage={""}
                        />


                    </UILIB.Paper>



                </UILIB.Column>

            </UILIB.Row>

        </UILIB.Paper>

    };
}


import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here

@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})
class UntrackableCampaigns extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    componentDidMount() {

    }


    render() {

        console.log(this.props.campaigns)
        let tableData = this.props.campaigns.map(row => {
            return {
                name: {
                    headerValue: "Campaign Name",
                    value: row.name
                },
                reason: {
                    headerValue: "Reason",
                    value: <UILIB.SquareChip className="square-chip-red" value={row.utmNotAppliedReason} style={{ whiteSpace: "normal" }} />
                }
            }
        })

        return <div>
            <UILIB.Button text="Back" iconLeft={<UILIB.Icons icon="arrowLeft" />} className="button-md mar-b25" onClick={this.props.exit} />
            <h4 className="mar-b15">Untrackable Campaigns</h4>
            <div>Here is a list of Campaigns that we have not been able to track, along with the reason they can't be tracked</div>
            <UILIB.Paper>
                <UILIB.DataTable1
                    noResultsTxt={""}
                    tableData={tableData}
                    dataUpdater={() => { }}
                    loadingData={false}
                    sortedColumn={""}
                    sortedDirection={""}
                    width="100%"
                    isLoading={false}
                    hasCheckBoxes={false}
                    className="mar-b25"
                />
            </UILIB.Paper>
        </div >
    };
}

export default UntrackableCampaigns;
import React from 'react'
import axios from 'axios'
import UILIB from '~/Common-Objects/Lib'
import DBConn from '~/Classes/siteVars.js'
import { connect } from 'react-redux';
import queryString from 'query-string';
@connect((store) => {
    return {
        user: store.user, siteMaster: store.siteMaster
    }
})

export default class ActivateTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountMasterData: {},
            requiresPassword: false,
            pageLoaded: false,
            firstName: "",
            lastName: "",
            password: "",
            errors: {
                password: null,
                firstName: null,
                lastName: null
            },
            tokenInvalid: false,
            processing: false
        }

        this.inputChange = this.inputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        const query = queryString.parse(location.search);
        const ourToken = query.token;

        var baseUrl = DBConn.getConnString()

        //lookup token and see if its a brand new user
        axios.get(baseUrl + '/register?token=' + ourToken).then(res => {
            if (res.data.Success) {
                this.setState({
                    accountMasterData: res.data.AccountMaster,
                    requiresPassword: res.data.RequiresPassword,
                    pageLoaded: true
                })
            } else {
                this.setState({
                    tokenInvalid: true
                })
            }
        }).catch(err => {
            this.setState({ tokenInvalid: true })
        })
    }

    inputChange(ev) {
        this.setState({ [ev.currentTarget.name]: ev.currentTarget.value });
    }

    handleSubmit() {

        var self = this;
        const query = queryString.parse(location.search);
        const ourToken = query.token;
        var errors = this.state.errors;

        this.setState({ processing: true })

        errors.firstName = "";
        errors.lastName = "";
        errors.password = "";

        var data = {
            token: ourToken
        }
        var fail = 0;

        if (this.state.requiresPassword) {
            data.firstName = this.state.firstName;
            data.lastName = this.state.lastName;
            data.password = this.state.password;

            if (!this.state.firstName || this.state.firstName.length < 2) {
                errors.firstName = "Please enter a valid First Name";
                fail = 1;
            }
            if (!this.state.lastName || this.state.lastName.length < 2) {
                errors.lastName = "Please enter a valid Last Name";
                fail = 1;
            }
            if (!this.state.password || this.state.password.length < 8) {
                errors.password = "Password must to be at least 8 characters long, contain an uppercase and lowercase character and at least 1 number";
                fail = 1;
            }
        }

        if (fail === 1) {
            this.setState({ errors: errors, processing: false })
        }
        else {

            var baseUrl = DBConn.getConnString()

            axios.put(baseUrl + '/register', data).then(res => {
                self.props.dispatch({ type: "LOGGEDIN_SUCCESS", payload: res.data });
                var query = queryString.parse(location.search);

                if (query.redirect) {
                    console.log('redirecting to', console.log(query.redirect))
                    window.open(query.redirect, '_self');
                } else {
                    window.open('/cp', '_self');
                }
            }).catch(err => {
                errors.password = "There was a problem registering your account"
                if (err) {
                    if (err.response.data) {
                        if (err.response.data.error) {
                            errors.password = err.response.data.error;
                        }
                    }
                }
                self.setState({ errors: errors, processing: false })
            })
        }
    }


    render() {

        if (this.state.tokenInvalid) {
            return <div className="quickFlex" style={{ alignItems: "center", justifyContent: "center", height: "100vh" }}>
                <UILIB.Paper className="with_header">
                    <div className="paper_header" style={{ backgroundColor: this.props.siteMaster.colours["$primary"], color: 'white' }}>
                        <div className="quickFlex" style={{ alignItems: "center" }}>
                            <UILIB.Icons icon="alert" color={"#FFFFFF"} style={{ width: "20px", height: "20px" }} />
                            <div className='mar-l10 text-heavy' style={{ fontSize: 16 }}>Token Has Expired</div>
                        </div>
                    </div>
                    <div className="paper_content">
                        <div className="mar-b10">The token has expired.</div>
                        <div className="mar-b25">You might need to ask the person who invited you to invite you again!</div>
                        <UILIB.Button text="Continue" onClick={() => { this.props.history.push('/') }} />
                    </div>
                </UILIB.Paper>
            </div>

        }
        if (this.state.pageLoaded === false) {
            return <div></div>
        }
        else {
            return <div className="quickFlex" style={{ alignItems: "center", justifyContent: "center", height: "100vh" }}>
                <UILIB.Paper className="with_header">
                    <div className="paper_header" style={{ backgroundColor: this.props.siteMaster.colours["$primary"], color: 'white' }}>
                        <div className="quickFlex" style={{ alignItems: "center" }}>
                            <UILIB.Icons icon="alert" color={"#FFFFFF"} style={{ width: "20px", height: "20px" }} />
                            <div className='mar-l10 text-heavy' style={{ fontSize: 16 }}>{this.props.siteMaster.siteName} Account Invitation</div>
                        </div>
                    </div>
                    <div className="paper_content">
                        <div className="quickFlex" style={{ alignItems: "flex-start" }}>
                            <div className="mar-r15">
                                <UILIB.Avatar src={this.state.accountMasterData.logo} />
                            </div>
                            <div>
                                <div className="text-heavy mar-b15">Welcome to {this.state.accountMasterData.name}'s Email Marketing</div>
                                {this.state.requiresPassword && <div>You've been invited to join {this.state.accountMasterData.name}'s Email Marketing Account. Please enter your details below to get started...</div>}
                                {!this.state.requiresPassword && <div>You've been invited to join {this.state.accountMasterData.name}'s Email Marketing Account. Please click Activate to get started...</div>}
                                {(this.state.requiresPassword) && <div className="mar-t25">
                                    <div className="quickFlex">
                                        <div style={{ flex: "1" }}>
                                            <UILIB.TextInput outerClassName="mar-b15 mar-r15" type="text" name="firstName" label="Enter your First Name" onKeyPress={this.inputChange} onChange={this.inputChange} error={this.state.errors.firstName} focus={true} />
                                        </div>
                                        <div style={{ flex: "1" }}>
                                            <UILIB.TextInput outerClassName="mar-b15" type="text" name="lastName" label="Enter your Last Name" onKeyPress={this.inputChange} onChange={this.inputChange} error={this.state.errors.lastName} />
                                        </div>

                                    </div>
                                    <UILIB.TextInput autocomplete="new-password" type="password" name="password" label="Enter your password" className="txtInput lg" onChange={this.inputChange} error={this.state.errors.password} onKeyPress={this.inputChange} />
                                </div>
                                }
                                <UILIB.Button className="button-primary mar-t25" text="Activate" onClick={this.handleSubmit} disabled={this.state.processing} />
                            </div>
                        </div>

                    </div>
                </UILIB.Paper >
            </div >

        }


    };
};
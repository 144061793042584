import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions';

import moment from 'moment';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import PermissionChecker from '~/Classes/permissions'
import queryString from 'query-string';
import EmailValidator from '~/Classes/emailValidator'

import UpgradeDrawerTiers from '~/pages/cp/includes/upgrade/upgradeTiers'

@connect((store) => {
    return { user: store.user, account: store.accountMaster, siteMaster: store.siteMaster, permissionStore: store.permission }
})

class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            usage: 0,
            allowed: 0,
            billing: undefined,
            next: undefined,
            stripe: {
                name: undefined,
                address_line1: undefined,
                address_city: undefined,
                address_state: undefined
            },
            account: null,
            stripeKey: 1,
            drawer: '',
            writeAccess: PermissionChecker("company", this.props.permissionStore.permissions, "write"),
            alertOpen: false,
            alertMessage: ''
        }

        this.getUsage = this.getUsage.bind(this);
        this.showPlans = this.showPlans.bind(this);
    }

    componentDidMount() {
        axios.get('/accountMaster?recount=true').then(res => this.setState({ account: res.data.AccountMaster }))
        this.getUsage()
        const query = queryString.parse(location.search);
        if (query.planId) {
            setTimeout(this.showPlans, 100)
        }
    }

    getUsage() {
        axios.get('/accountMaster/usage')
            .then(response => {

                this.setState({
                    billing: response.data.Billing,
                    next: response.data.NextBilling,
                    loaded: true
                })
            })
    }

    showPlans() {
        // var drawerContent = <UpgradeDrawer close={() => {
        //     this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null,));
        //     this.getUsage();

        // }} />
        // if (this.props.account.accountMaster && this.props.account.accountMaster.beta && this.props.account.accountMaster.beta.billingByContact) {
        let drawerContent = <UpgradeDrawerTiers history={this.props.history} close={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null));
            this.getUsage();
        }} />
        // }
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "1000px"));
    }


    toggleEmailInvoices() {
        const account = this.state.account
        const options = account.options || {}
        options.emailRenewals = !options.emailRenewals
        this.setState({ account }, () => {
            if (!options.emailRenewals || EmailValidator.validateEmail(options.emailRenewalsAddress)) {
                this.saveAccount();
            }
        })
    }

    updateInvoiceEmail(e) {
        const account = this.state.account
        const options = account.options || {}
        options.emailRenewalsAddress = e.target.value
        this.setState({ account })
    }

    saveAccount() {
        let error = ''
        const options = this.state.account.options || {}
        if (options.emailRenewals) {
            if (!EmailValidator.validateEmail(options.emailRenewalsAddress)) {
                error = 'Invalid Email'
            }
        }
        this.setState({
            emailRenewalsAddressError: error
        })
        if (error) {
            return
        }
        axios.put('/accountMaster', this.state.account).then(() => {
            this.setState({
                alertOpen: true,
                alertMessage: 'Email Preferences Saved'
            })
        })
    }

    render() {
        if (!this.state.loaded || !this.state.account) return <UILIB.LoadingIcons />

        var nextBillDate = moment(this.props.account.accountMaster.nextBillDate);
        var daysUntilDate = nextBillDate.diff(moment(), 'days')

        var price;

        if (this.state.billing.friendlyPrice) {
            let friendlyPrice = this.state.billing.friendlyPrice;
            if (this.props.account.accountMaster.billingFrequency == 1) {
                friendlyPrice = parseInt(friendlyPrice);
                friendlyPrice = friendlyPrice * 12;
                friendlyPrice = friendlyPrice - ((friendlyPrice / 100) * 10)
            }
            price = this.state.billing.symbol + NumberFunctions.formatNumber(friendlyPrice, 2);
        } else {
            price = 'free'
        }

        var isNextFree = false;
        if (this.state.next && this.props.account.accountMaster.nextAccountType && this.props.account.accountMaster.nextAccountType.toUpperCase() === "FREE") {
            isNextFree = true;
        }

        const allowed = this.props.account.accountMaster.approved && !this.props.account.accountMaster.deny
        const emailInvoices = this.state.account && this.state.account.options && this.state.account.options.emailRenewals
        const invoiceEmail = emailInvoices ? this.state.account.options.emailRenewalsAddress : ''

        const overLimit = this.props.account.accountMaster.totalActiveSubscribers > this.state.billing.maxUniqueSends
        const overDeleteLimit = this.props.account.accountMaster.totalDeletedSubscribers > this.state.billing.maxUniqueSends

        const isCreditAccount = this.props.account.accountMaster.billingFrequency == 2;
        const isBilledOnContacts = this.props.account.accountMaster.billedOnContacts;

        let hasPendingPlanChange = this.props.account.accountMaster.nextAccountBillingId;

        return (
            <div>
                <UILIB.Paper>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <div className="mar-b10">Your current plan</div>
                            {(isCreditAccount == false) && <h4 className="quickFlex" style={{ alignContent: "flex-start" }}>
                                <span style={{ textTransform: "capitalize" }}>{this.props.account.accountMaster.accountType}</span>
                                <span className="mar-l10 mar-r10">-</span>
                                <span style={{ textTransform: "capitalize" }}>{this.state.billing.name}  {(isCreditAccount == false && isBilledOnContacts == true) ? <span>Contacts</span> : <span>Sends</span>} </span>
                                {this.props.account.accountMaster.accountBillingTierName &&
                                    <span>
                                        <UILIB.SquareChip className="mar-l5 square-chip-green">{this.props.account.accountMaster.accountBillingTierName}</UILIB.SquareChip>
                                    </span>
                                }
                            </h4>
                            }
                            {(isCreditAccount == true) && <h4>Pay As You Go</h4>}

                        </div>

                        <div className="col-xs-12 col-md-6 end-xs quickFlex">
                            {this.state.writeAccess && !this.props.account.accountMaster.invoice && <div>
                                {this.props.account.accountMaster.deny && <UILIB.WarningHolder>Account Blocked</UILIB.WarningHolder>}
                                {isCreditAccount && <UILIB.Button text="Top Up" className="button-primary mar-r10" onClick={this.showPlans} />}
                                {!this.props.account.accountMaster.deny && <UILIB.Button text="Change Plan" className="button-primary" onClick={this.showPlans} />}

                            </div>}
                        </div>

                    </div>
                </UILIB.Paper>



                {!!hasPendingPlanChange && <UILIB.Paper className="paper-blue">
                    <h4 className="mar-b10">Your plan is changing</h4>
                    <span >You have chosen to downgrade your account. </span>
                    <span>Your new plan will come into effect on </span>
                    <span className="text-heavy">{DateTimeFunctions.formatDateTime(this.props.account.accountMaster.nextBillDate, 2)}</span>
                </UILIB.Paper >
                }

                <UILIB.Row>
                    <UILIB.Column xs={12} md={8}>


                        {(isCreditAccount == false && isBilledOnContacts == false) && <UILIB.Paper>
                            <div className="mar-b25">
                                <div className="quickFlex mar-b15" style={{ justifyContent: "space-between", alignContent: "center" }}>
                                    <div className="quickFlex">
                                        <UILIB.Icons icon="outbox" className="mar-r10 icons-holder-circle-primary" />
                                        <h4>Send Limit</h4>
                                    </div>
                                    <div><h4>{this.props.account.accountMaster.maxUniqueSends - this.props.account.accountMaster.remainingEmails} / {this.state.billing.maxUniqueSends}</h4></div>
                                </div>
                                <div className="quickFlex">
                                    <div style={{ width: "40px" }}></div>
                                    <div style={{ flex: "1" }}>
                                        <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.props.account.accountMaster.maxUniqueSends - this.props.account.accountMaster.remainingEmails} val2={this.state.billing.maxUniqueSends} />
                                        <div>You have sent to {this.props.account.accountMaster.maxUniqueSends - this.props.account.accountMaster.remainingEmails} unique email addresses this month. Your current plan allows up to {this.state.billing.maxUniqueSends} sends.</div>
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className="mar-b25">
                                <div className="quickFlex mar-b10" style={{ justifyContent: "space-between", alignContent: "center" }}>
                                    <div className="quickFlex">
                                        <UILIB.Icons icon="calendar" className="mar-r10 icons-holder-circle-primary" />
                                        <h4>{NumberFunctions.formatNumber(daysUntilDate)} Days</h4>
                                    </div>
                                </div>
                                <div className="quickFlex">
                                    <div style={{ width: "40px" }}></div>
                                    <div style={{ flex: "1" }}>
                                        <div>until you are next charged</div>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div className="quickFlex mar-b10" style={{ justifyContent: "space-between", alignContent: "center" }}>
                                <div className="quickFlex">
                                    <UILIB.Icons icon="money" className="mar-r10 icons-holder-circle-primary" />
                                    <h4>
                                        {price}
                                        {this.props.account.accountMaster.addVAT && price !== 'free' && <span className="mar-l5 text-sml">+ VAT</span>}
                                    </h4>
                                </div>
                            </div>
                            <div className="quickFlex">
                                <div style={{ width: "40px" }}></div>
                                <div style={{ flex: "1" }}>
                                    <div>current fee per billing period</div>
                                </div>
                            </div>
                        </UILIB.Paper>}

                        {(isCreditAccount == false && isBilledOnContacts == true) && <UILIB.Paper>
                            <div className="mar-b25">
                                <div className="quickFlex mar-b15" style={{ justifyContent: "space-between", alignContent: "center" }}>
                                    <div className="quickFlex">
                                        <UILIB.Icons icon="avatars3" className="mar-r10 icons-holder-circle-primary" />
                                        <h4>Contact Limit</h4>
                                    </div>
                                    <div><h4>{this.props.account.accountMaster.totalActiveSubscribers} / {this.state.billing.maxUniqueSends}</h4></div>
                                </div>
                                <div className="quickFlex">
                                    <div style={{ width: "40px" }}></div>
                                    <div style={{ flex: "1" }}>
                                        <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.props.account.accountMaster.totalActiveSubscribers} val2={this.state.billing.maxUniqueSends} />
                                        {overLimit && <div>
                                            <div className="text-red mar-b10 text-sml">
                                                <strong>You have more active contacts in your account than your subscription allows. </strong>
                                                <span> You will not be able to send until you have either removed subscribers, or changed your plan.</span>
                                            </div>
                                            {allowed && <UILIB.Button disabled={!allowed} text="upgrade" className="button-primary" onClick={this.showPlans} />}
                                        </div>
                                        }
                                        {(overLimit == false) && <div className="text-sml">You have {this.props.account.accountMaster.totalActiveSubscribers} active contacts in your account. Your current plan allows up to {this.state.billing.maxUniqueSends} contacts.</div>}
                                        {(overDeleteLimit == true) && <div>
                                            <div className="text-red mar-b10 mar-t10 text-sml">
                                                <strong>You've reached your deletion limit</strong>
                                                <span> You have deleted <strong>{this.props.account.accountMaster.totalDeletedSubscribers}</strong> contacts this month which is more than your contact limit.
                                                    Please upgrade you subscription to continue.</span>
                                            </div>
                                            {allowed && <UILIB.Button disabled={!allowed} text="upgrade" className="button-primary" onClick={this.showPlans} />}
                                        </div>}
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className="mar-b25">
                                <div className="quickFlex mar-b10" style={{ justifyContent: "space-between", alignContent: "center" }}>
                                    <div className="quickFlex">
                                        <UILIB.Icons icon="calendar" className="mar-r10 icons-holder-circle-primary" />
                                        <h4>{NumberFunctions.formatNumber(daysUntilDate)} Days</h4>
                                    </div>
                                </div>
                                <div className="quickFlex">
                                    <div style={{ width: "40px" }}></div>
                                    <div style={{ flex: "1" }}>
                                        <div>until you are next charged</div>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div className="quickFlex mar-b10" style={{ justifyContent: "space-between", alignContent: "center" }}>
                                <div className="quickFlex">
                                    <UILIB.Icons icon="money" className="mar-r10 icons-holder-circle-primary" />
                                    <h4>
                                        {price}
                                        {this.props.account.accountMaster.addVAT && price !== 'free' && <span className="mar-l5 text-sml">+ VAT</span>}
                                    </h4>
                                </div>
                            </div>
                            <div className="quickFlex">

                                <div style={{ width: "40px" }}></div>
                                <div style={{ flex: "1" }}>
                                    <div>current fee per billing period</div>
                                </div>
                            </div>
                        </UILIB.Paper>}

                        {(isCreditAccount == true) && <UILIB.Paper>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <div className="quickFlex mar-b10" style={{ justifyContent: "space-between", alignContent: "center" }}>
                                        <div className="quickFlex">
                                            <UILIB.Icons icon="money" className="mar-r10 icons-holder-circle-primary" />
                                            <h4>{NumberFunctions.formatNumber(this.props.account.accountMaster.credits)}</h4>
                                        </div>
                                    </div>
                                    <div className="quickFlex">
                                        <div style={{ width: "40px" }}></div>
                                        <div style={{ flex: "1" }}>
                                            <div>credits remaining to send with</div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.writeAccess && !this.props.account.accountMaster.invoice && <div><UILIB.Button text="Top Up" className="button-primary mar-r10" onClick={this.showPlans} /></div>}
                            </div>
                        </UILIB.Paper>}
                    </UILIB.Column>

                    <UILIB.Column xs={12} md={4}>

                        {this.props.account.accountMaster.signupAppId == '0' && <UILIB.Paper>
                            <h4 className="mar-b25">Payment Methods</h4>
                            <UILIB.Stripe key={this.state.stripeKey} writeAccess={this.state.writeAccess} />
                        </UILIB.Paper>}



                        {(this.state.writeAccess && price != 'free' && !isNextFree) && <UILIB.Paper>


                            <UILIB.Toggle className="mar-r10" after={"Email Renewal Invoices" + (emailInvoices ? " to:" : "")} checked={emailInvoices} onChange={this.toggleEmailInvoices.bind(this)} />
                            {emailInvoices &&

                                <div className="quickFlex mar-t20" style={{ width: "100%", alignItems: "start" }}>
                                    <UILIB.TextInput outerStyle={{ flex: "1" }} error={this.state.emailRenewalsAddressError} value={invoiceEmail} placeholder="email@address.com" type="email" onChange={this.updateInvoiceEmail.bind(this)} />
                                    <UILIB.Button text="Save" className="mar-l10 button-primary" onClick={this.saveAccount.bind(this)} />
                                </div>

                            }

                        </UILIB.Paper>
                        }





                    </UILIB.Column>
                </UILIB.Row >

                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
                {this.state.drawer}
            </div >
        );
    }
}

export default Subscription;
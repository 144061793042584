import React from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import moment from 'moment';
import axios2 from 'axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';

@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster }
})

export default class GenericLineGraph extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }

    componentDidUpdate() {
    }

    render() {

        let chartData = this.props.data || [];
        let linearGradients = [];
        let areas = [];

        let colours = ["$primary", "$secondary", "$status-blue", "$status-green", "$status-orange", "$status-purple", "$status-yellow", "$status-red"]
        let allProps = [];

        chartData.forEach(row => {
            Object.keys(row).forEach(key => {
                if (allProps.indexOf(key) < 0) allProps.push(key);
            })
        })
        allProps.forEach((key, index) => {
            if (key != "date" && key != "name") {
                let color = colours[index]
                if (!color) color = "$status-grey"
                color = this.props.siteMaster.colours[color];
                linearGradients.push(
                    <linearGradient key={"l_" + index} id={`color${index}`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={color} stopOpacity={0.3} />
                        <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                    </linearGradient>
                )
                areas.push(<Area key={"a_" + index}
                    type="monotone"
                    fill={`url(#color${index})`}
                    dataKey={key}
                    stroke={color}
                    strokeWidth={2}
                    fillOpacity={1}
                    activeDot={{ stroke: color, strokeWidth: 2, fill: 'white' }} />
                )
            }
        })

        return <div>
            <h4 className="mar-b25">{this.props.title}</h4>
            <ResponsiveContainer height={this.props.height || 110}>
                <AreaChart data={chartData} margin={{ top: 0, right: 0, left: 20, bottom: 0 }}>
                    <XAxis dataKey="date" axisLine={false} tickLine={false} padding={{ left: 15 }} tick={{ fontSize: 10, fill: '#999', fontFamily: "Open Sans", fontWeight: 600 }} />
                    <YAxis axisLine={false} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontFamily: "Open Sans", fontWeight: 600 }} />
                    <Tooltip content={<UILIB.CustomTooltip showLabel={true} />} />
                    <defs>
                        {linearGradients}
                    </defs>
                    {areas}
                </AreaChart>
            </ResponsiveContainer>
        </div >
    }
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T0L_Sy1hg8BVjdYFTdqT{border-radius:8px}.pWqbndOCoXitHDTh_zzY,.qQpDMo3ty0TU6Dm8Gb2G{color:var(--grey-950)}.pWqbndOCoXitHDTh_zzY:hover,.qQpDMo3ty0TU6Dm8Gb2G:hover{background-color:var(--grey-50)}.pWqbndOCoXitHDTh_zzY:focus,.qQpDMo3ty0TU6Dm8Gb2G:focus{background-color:var(--grey-0)}.pWqbndOCoXitHDTh_zzY{border-color:var(--grey-300);box-shadow:0px 1px 1px 0px rgba(23,24,25,.05)}.pWqbndOCoXitHDTh_zzY svg{color:var(--grey-800)}.qQpDMo3ty0TU6Dm8Gb2G.Gj6zfDTqjsjkCUcJm1ON{color:var(--grey-800)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/inbox/components/shared/inboxButton/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,4CAEE,qBAAA,CAEA,wDACE,+BAAA,CAGF,wDACE,8BAAA,CAIJ,sBACE,4BAAA,CACA,6CAAA,CAEA,0BACE,qBAAA,CAIJ,2CACE,qBAAA","sourcesContent":[".root {\n  border-radius: 8px;\n}\n\n.rootVariantSecondary,\n.rootVariantGhost {\n  color: var(--grey-950);\n\n  &:hover {\n    background-color: var(--grey-50);\n  }\n\n  &:focus {\n    background-color: var(--grey-0);\n  }\n}\n\n.rootVariantSecondary {\n  border-color: var(--grey-300);\n  box-shadow: 0px 1px 1px 0px rgba(23, 24, 25, 0.05);\n\n  svg {\n    color: var(--grey-800);\n  }\n}\n\n.rootVariantGhost.rootIconOnly {\n  color: var(--grey-800);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "T0L_Sy1hg8BVjdYFTdqT",
	"rootVariantSecondary": "pWqbndOCoXitHDTh_zzY",
	"rootVariantGhost": "qQpDMo3ty0TU6Dm8Gb2G",
	"rootIconOnly": "Gj6zfDTqjsjkCUcJm1ON"
};
export default ___CSS_LOADER_EXPORT___;

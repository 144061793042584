import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "~/data/http/axios";
import { handleGenericError } from "~/data/actions/siteActions";
import Button from "../../components/button";
import TextArea from "../../components/textArea";
import SettingsSection from "../../components/settingsSection";
import * as styles from "./index.module.scss";
import Header from "../../components/header";
import i18n from "~/i18n";

export default function AiAssistantTest({ inbox, assistant }) {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [question, setQuestion] = useState("");
  const [response, setResponse] = useState(null);
  const [formErrors, setFormErrors] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitting(true);

    try {
      await validateForm();
      await testAi();
    } finally {
      setSubmitting(false);
    }
  }

  async function validateForm() {
    try {
      if (question.length === 0) {
        throw i18n.t("chat:ai.test.invalid");
      }

      setFormErrors(null);
    } catch (error) {
      setFormErrors({ question: error });
      throw error;
    }
  }

  async function testAi() {
    try {
      const { data } = await axios.post(
        `/inboxes/${inbox.id}/ai/${assistant.id}/test`,
        {
          question,
        }
      );
      setResponse(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inbox_settings_left_content">
      <Header type="ai" option="test" />
      <form onSubmit={handleSubmit} disabled={submitting}>
        <SettingsSection
          heading={i18n.t("chat:ai.test.question")}
          subheading={i18n.t("chat:ai.test.subheader")}
        >
          <TextArea
            value={question}
            error={formErrors?.question}
            className={styles.textarea}
            onChange={(e) => setQuestion(e.target.value)}
          />

          <Button
            type="submit"
            size="s"
            className="mar-t12"
            loading={submitting}
          >
            {i18n.t("chat:ai.test.button")}
          </Button>
        </SettingsSection>
      </form>

      {response && (
        <SettingsSection heading="Response">
          <div
            className={styles.responseBox}
            dangerouslySetInnerHTML={{
              __html: !!response.error
                ? i18n.t("chat:ai.test.noResponse")
                : response.response,
            }}
          />
        </SettingsSection>
      )}
    </div>
  );
}

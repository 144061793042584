import React from "react";
import classnames from "classnames";
import { propToCssModule } from "~/Classes/styles";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";

const InboxButton = React.forwardRef(
  ({ variant, iconOnly, className, dropDownOptions = [], dropDownChange = () => { }, dropDownSelected = "",
    popupAlign = "start",
    popupMinWidth = 240, ...rest }, forwardedRef) => {
    const classes = classnames(styles.root, className, {
      [styles[`rootVariant${propToCssModule(variant)}`]]: variant,
      [styles.rootIconOnly]: iconOnly,
    });

    const buttonStyle = {};
    if (dropDownOptions && dropDownOptions.length > 0) {
      dropDownOptions = dropDownOptions.filter(f => f.value != dropDownSelected)
    }
    if (dropDownOptions && dropDownOptions.length > 0) {
      buttonStyle.borderTopRightRadius = 0;
      buttonStyle.borderBottomRightRadius = 0;

    }

    return (<div style={{ display: "flex", alignItems: "stretch" }}>
      <UILIB.ButtonNew
        ref={forwardedRef}
        variant={variant}
        iconOnly={iconOnly}
        className={classes}
        {...rest}
        style={buttonStyle}
      />
      {(dropDownOptions && dropDownOptions.length > 0) && <UILIB.DropdownMenu.Root>
        <UILIB.DropdownMenu.Trigger size="s" disabled={rest.disabled} style={{
          backgroundColor: "black",
          borderTopLeftRadius: "0",
          borderBottomLeftRadius: "0",
          padding: "5px 7px",
          borderWidth: "0 0 0 1px",
          borderLeftColor: "#595C61"
        }}></UILIB.DropdownMenu.Trigger>

        <UILIB.DropdownMenu.Portal>
          <UILIB.DropdownMenu.Content align={popupAlign} style={{ minWidth: popupMinWidth }}>
            <UILIB.DropdownMenu.RadioGroup value={1}>
              {dropDownOptions.map((option, index) => {
                return <UILIB.DropdownMenu.RadioItem
                  key={"sendOpt_" + index}
                  value={option.value}
                  onClick={() => { dropDownChange(option.value) }}
                >
                  <div className={styles.checkboxItemDetail}>
                    <span className={styles.checkboxItemInboxName}>
                      {option.label}
                    </span>

                  </div>
                </UILIB.DropdownMenu.RadioItem>
              })}

            </UILIB.DropdownMenu.RadioGroup>
          </UILIB.DropdownMenu.Content>
        </UILIB.DropdownMenu.Portal>
      </UILIB.DropdownMenu.Root >}
    </div>
    );
  }
);

export default InboxButton;

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import helpers from '~/Classes/helpers'
import siteVars from '~/Classes/siteVars';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here

import ViewClicked from './clicked';
import TableView from './tableView';

import SelectFields from '../fieldSelect'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

export default class CampaignsViewClicked extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabType: "Template View",
            startDate: null,
            endDate: null,
            alertMessage: "",
            alertOpen: false,
            campaignId: this.props.match.params.campaignId,
            showBots: false,
            pageLoaded: false
        }
        this.tabChange = this.tabChange.bind(this);
        this.exportCampaign = this.exportCampaign.bind(this)
        this.update = this.update.bind(this)
    }

    componentDidMount() {
        var obj = {}
        if (this.props.campaign.type === 10 && !this.props.campaign.parentCampaignId) {
            obj.tabType = 'List View'
        }
        this._ismounted = true
        obj.pageLoaded = true;
        this.setState(obj)
    }

    componentWillUnmount() {
        this._ismounted = false
    }

    componentDidUpdate(prevProps) {
        var update = false;
        var obj = {};
        if (this.state.startDate != this.props.startDate) {
            obj.startDate = this.props.startDate;
            update = true;
        }
        if (this.state.endDate != this.props.endDate) {
            obj.endDate = this.props.endDate;
            update = true;
        }
        if (update) {
            this.setState(obj)
        }
    }

    update(ev) {
        this.setState({
            [ev.target.name]: ev.target.checked
        })
    }

    tabChange(theType) {

        this.setState({ tabType: theType })
    }


    exportCampaign() {
        var filter = "clicked";
        if (this.state.tabType == "List View") filter = "clickedFull";

        let drawerContent = <SelectFields filter={filter}
            showBots={this.state.showBots}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            campaignId={this.state.campaignId}
            finished={() => {
                this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", undefined, true, '500px'));
            }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '500px'));
    }

    render() {

        if (!this.state.pageLoaded) <UILIB.LoadingIcons type="2" />
        var thisMenuOptions = {
            "Template View": 0,
            "List View": 1
        }

        return <div>

            <UILIB.Row >
                <UILIB.Column xs="12" sm="12" md="4" lg="4" xl="4" className="mar-b25">
                    {(this.props.campaign.type !== 10 || this.props.campaign.parentCampaignId) && <div>
                        <UILIB.ButtonSimple
                            className="button-simple-fullsize"
                            selected={this.state.tabType == "Template View"}
                            onClick={() => { this.tabChange('Template View') }}
                            title="Grid View"
                            alt="Grid View"
                            iconLeft={<UILIB.Icons icon="grid" />}
                        />
                        <UILIB.ButtonSimple
                            className="button-simple-fullsize mar-l5"
                            selected={this.state.tabType == "List View"}
                            onClick={() => { this.tabChange('List View') }}
                            title="List View"
                            alt="List View"
                            iconLeft={<UILIB.Icons icon="list" />}
                        />

                    </div>}
                </UILIB.Column>

                <UILIB.Column xs="12" sm="12" md="8" className="end-xs mar-b25  campaign-view-controls">
                    <UILIB.Toggle after={i18n.t('campaigns:overview.showBotClicks')} checked={this.state.showBots} name="showBots" onChange={this.update} className="mar-r10 m-mar-b10"></UILIB.Toggle>
                    <UILIB.TextInput type="multiDate" startDate={this.props.startDate} endDate={this.props.endDate} updateDate={this.props.updateDate}></UILIB.TextInput>
                    {(this.props.canExport == true) && <UILIB.Button
                        className="mar-l10 m-mar-l0 m-mar-t10"
                        onClick={this.exportCampaign}
                        text={i18n.t('subscribers:nav.export') + " " + i18n.t('campaigns:nav.clicked')}
                        iconRight={<UILIB.Icons icon="download" />}
                    />}
                </UILIB.Column>


            </UILIB.Row>

            {this.state.tabType == "Template View" && <ViewClicked showBots={this.state.showBots} campaign={this.props.campaign} campaignId={this.state.campaignId} startDate={this.state.startDate} endDate={this.state.endDate} history={this.props.history} searchText={this.state.searchText} changedDate={this.props.changedDate} />}
            {this.state.tabType == "List View" && <TableView showBots={this.state.showBots} campaign={this.props.campaign} campaignId={this.state.campaignId} startDate={this.state.startDate} endDate={this.state.endDate} history={this.props.history} />}1

        </div>
    }
}
import React, { useEffect, useState } from 'react'
import axios from '../../../data/http/axios'
import UILIB from '../../Lib'

export default function TagSelect({ selected = [], tagType = 0, explainer = "", add = () => { }, remove = () => { } }) {
    const [tags, setTags] = useState(null)
    const [addingText, setAddingText] = useState('')
    const [error, setError] = useState('')

    const getTags = async () => {
        setTags((await axios.get('/tags?tagType=' + tagType)).data)
    }

    const selectTag = (e) => {
        const id = parseInt(e.currentTarget.name)
        const arr = [...selected]
        const index = arr.findIndex(t => t == id)
        if (index > -1) remove(id)
        else add(id)
    }

    const saveTag = async () => {
        if (!addingText || !addingText.trim()) return
        setError('')
        try {
            const res = await axios.post('/tags', {
                tagType,
                tagName: addingText
            })
            await getTags()
            add(res.data.find(t => t.tagName === addingText).id)
            setAddingText('')
        } catch (e) {
            setError(e.data.error)
        }
    }

    useEffect(() => {
        getTags()
    }, [tagType])

    useEffect(() => {
        setError('')
    }, [addingText])

    if (!tags) return <UILIB.LoadingIcons />

    let headerText = "Select Tags"
    if (selected.length) {
        headerText = <div>
            {selected.map(id => {
                const tag = tags.find(t => t.id === id)
                if (!tag) return ''
                return <UILIB.SquareChip key={id} className="square-chip-tag mar-r10">{tag.tagName}</UILIB.SquareChip>
            })}
        </div>
    }

    return (
        <>
            <UILIB.ButtonSelect headerText={headerText}>
                <div className="quickFlex" style={{ padding: '10px 10px 0 10px' }}>
                    <UILIB.TextInput outerStyle={{ flexGrow: 1 }} error={error} outerClassName="mar-r10" placeholder="New Tag Name" value={addingText} onEnterPress={saveTag} onChange={e => setAddingText(e.currentTarget.value)} />
                    <UILIB.Button onClick={saveTag} disabled={!addingText.trim()} className="button-md button-primary">Add New</UILIB.Button>
                </div>
                <ul>
                    {tags.map(tag => {
                        return <li key={tag.id} className="quickFlex" style={{ justifyContent: 'space-between' }}>
                            <UILIB.CheckBox outerClassName="full-width" name={tag.id} onChange={selectTag} checked={selected.indexOf(tag.id) > -1}>{tag.tagName}</UILIB.CheckBox>
                        </li>
                    })}
                </ul>
            </UILIB.ButtonSelect>
            {!!explainer && <div className="txtInput_explainer">{explainer}</div>}
        </>
    )
}
import React from 'react'

import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApmRoute } from '@elastic/apm-rum-react'

import CompanyTemplate from './companyTemplate.jsx'
import CompanyDetails from './companyDetails'
import StaffDetails from './staff/staff'
import RoleDetails from './roles/roles'
import RoleAddEdit from './roles/edit/viewEditRole'
import CompanyDomains from './domains/domains'
import CompanyDomainsNew from './domainsNew/index';
import Subscription from './subscription'
import ApiKeys from './apikeys/apikeys'
import Integrations from './integrations/integrations'
import Payments from './payments/payments'
import LandingPages from './landing'
import IntegrationsOAuthResponse from './integrations/oAuth/response/'
import IntegrationsOAuth1Reponse from './integrations/oAuth/response/oauth1'

import StripeFailedPayment from './payments/stripeAuthPayment'

import CapsuleConfig from './integrations/apps/capsule'
import StripeConfig from './integrations/apps/stripe'
import GoldmineConfig from './integrations/apps/goldmine'
import DynamicsConfig from './integrations/apps/dynamics'
import DynamicsClean from './integrations/apps/dynamics/clean'
import TrustPilotConfig from './integrations/apps/trustpilot'
import TrustPilotConfigAddBusiness from './integrations/apps/trustpilot/addBusiness'
import SalesmateAddAccount from './integrations/apps/salesMate/addAccount'
import SalesmateGroups from './integrations/apps/salesMate/changeGroup'
import SalesmateFields from './integrations/apps/salesMate/changeFields'
import SalesmateConfig from './integrations/apps/salesMate'
import HubspotConfig from './integrations/apps/hubspot'
import PipedriveConfig from './integrations/apps/pipedrive'
import NimbleConfig from './integrations/apps/nimble'
import Mailchimp from './integrations/apps/mailchimp'
import Twitter from './integrations/apps/twitter'
import TwitterConnect from './integrations/apps/twitter/connect'
import GenericAppCP from './integrations/apps/genericCp'
import GenericAppNoConfig from './integrations/apps/genericNoConfig'
import GenericAds from './integrations/apps/ads';
import GenericSocial from './integrations/apps/social';
import Mailboxes from './mailboxes'
import Mailbox from './mailboxes/history'

import Servers from './servers'
import ServerReports from './servers/reports'

import SmsSetup from './sms'


@connect((store) => {
    return {
        siteMaster: store.siteMaster, accountMaster: store.accountMaster
    }
})
export default class Account extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {};
    }
    render() {
        var showBilling = true;
        if (this.props.siteMaster.hidePricing) showBilling = false;
        if (this.props.accountMaster.accountMaster.invoice) showBilling = false;

        return <CompanyTemplate history={this.props.history}>
            <Switch>
                <ApmRoute exact path="/cp/company" component={CompanyDetails} />
                <ApmRoute exact path="/cp/company/staff" component={StaffDetails} />
                <ApmRoute exact path="/cp/company/roles" component={RoleDetails} />
                <ApmRoute exact path="/cp/company/roles/edit/:roleID" component={RoleAddEdit} />
                <ApmRoute exact path="/cp/company/domainsLegacy" component={CompanyDomains} />
                <ApmRoute exact path="/cp/company/domains" component={CompanyDomainsNew} />
                {showBilling && <ApmRoute exact path="/cp/company/subscription" component={Subscription} />}
                {showBilling && <ApmRoute exact path="/cp/company/payments" component={Payments} />}
                {showBilling && <ApmRoute exact path="/cp/company/authpayment" component={StripeFailedPayment} />}
                <ApmRoute exact path="/cp/company/sms" component={SmsSetup} />
                <ApmRoute exact path="/cp/company/landingpages" component={LandingPages} />
                <ApmRoute exact path="/cp/company/apikeys" component={ApiKeys} />
                <ApmRoute exact path="/cp/company/mailboxes" component={Mailboxes} />
                <ApmRoute path="/cp/company/mailboxes/:id" component={Mailbox} />
                <ApmRoute exact path="/cp/company/servers" component={Servers} />
                <ApmRoute exact path="/cp/company/servers/reports" component={ServerReports} />
                <ApmRoute exact path="/cp/company/servers/:id/reports" component={ServerReports} />
                <ApmRoute exact path="/cp/company/servers/reports" component={ServerReports} />
                <ApmRoute exact path="/cp/company/integrations" component={Integrations} />
                <ApmRoute exact path="/cp/company/integrations/oauth/response/:appId" component={IntegrationsOAuthResponse} />
                <ApmRoute exact path="/cp/company/integrations/oauth1/response/:appId" component={IntegrationsOAuth1Reponse} />
                <ApmRoute exact path="/cp/company/integrations/apps/capsule" component={CapsuleConfig} />
                <ApmRoute exact path="/cp/company/integrations/apps/stripe" component={StripeConfig} />
                <ApmRoute exact path="/cp/company/integrations/apps/goldmine" component={GoldmineConfig} />
                <ApmRoute exact path="/cp/company/integrations/apps/dynamics" component={DynamicsConfig} />
                <ApmRoute exact path="/cp/company/integrations/apps/dynamics/clean" component={DynamicsClean} />
                <ApmRoute exact path="/cp/company/integrations/apps/trustpilot" component={TrustPilotConfig} />
                <ApmRoute exact path="/cp/company/integrations/apps/trustpilot/addBusiness" component={TrustPilotConfigAddBusiness} />
                <ApmRoute exact path="/cp/company/integrations/apps/salesmate/addAccount" component={SalesmateAddAccount} />
                <ApmRoute exact path="/cp/company/integrations/apps/salesmate/changeGroup" component={SalesmateGroups} />
                <ApmRoute exact path="/cp/company/integrations/apps/salesmate" component={SalesmateConfig} />
                <ApmRoute exact path="/cp/company/integrations/apps/salesmate/changeFields" component={SalesmateFields} />
                <ApmRoute exact path="/cp/company/integrations/apps/hubspot" component={HubspotConfig} />
                <ApmRoute exact path="/cp/company/integrations/apps/pipedrive" component={PipedriveConfig} />
                <ApmRoute exact path="/cp/company/integrations/apps/nimble" component={NimbleConfig} />
                <ApmRoute exact path="/cp/company/integrations/apps/mailchimp" component={Mailchimp} />
                <ApmRoute exact path="/cp/company/integrations/apps/twitter/connect" component={TwitterConnect} />
                <ApmRoute exact path="/cp/company/integrations/apps/twitter" component={Twitter} />
                <ApmRoute exact path="/cp/company/integrations/apps/ads/:appId" component={GenericAds} />
                <ApmRoute exact path="/cp/company/integrations/apps/social/:socialAccountId" component={GenericSocial} />
                <ApmRoute exact path="/cp/company/integrations/apps/integrationconfig/:appId" component={GenericAppCP} />
                <ApmRoute exact path="/cp/company/integrations/apps/integrationnoconfig/:appId" component={GenericAppNoConfig} />
            </Switch>
        </CompanyTemplate>

    }
};


import React from 'react'
import ReactTooltip from 'react-tooltip'

export default function ({ tabs = [], selected, onChange = () => { } }) {
    return (
        <div className='viewSubscriber-history-nav quickFlex'>
            {tabs.map((tab, index) => {
                return <div key={index}
                    data-tip data-for={'subtab' + index}
                    className={"viewSubscriber-history-nav-option" + (selected == tab.value ? " viewSubscriber-history-nav-option-selected" : "") + (tab.disabled ? ' disabled' : '')}
                    onClick={() => tab.disabled ? {} : onChange(tab.value)}>
                    {tab.label}

                    {tab.tooltip && <ReactTooltip effect='solid' id={'subtab' + index}>{tab.tooltip}</ReactTooltip>}
                </div>
            })}
        </div>
    )
}
import React from 'react'
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import NumberFunctions from '~/Classes/numberFormatFunctions';
import DateFunctions from '~/Classes/dateTimeFunctions';
import StripeAddCard from './stripeAddCard'
//CONNECT TO STORE

@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster }
})

export default class UpgradeDrawer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accountMaster: {},
            pageLoaded: false,
            changingPlan: false,
            plans: [],
            creditPlans: [],
            currentBilling: {},
            newPlanId: -1,
            newPlan: {},
            voucherCode: "",
            currentVoucher: "",
            paymentMethod: "",
            paymentCards: [],
            manuallyAddingCard: false,
            paymentCardId: "",
            paymentError: "",
            defaultCard: "",
            billingFrequency: -1,
            voucherError: "",
            stripeAuthPayment: undefined,
            reloadingCard: false,
            credits: 0
        }

        this.loadPlans = this.loadPlans.bind(this);
        this.changePlan = this.changePlan.bind(this);
        this.loadCurrentBilling = this.loadCurrentBilling.bind(this);

        this.loadAccountMaster = this.loadAccountMaster.bind(this);
        this.changeInput = this.changeInput.bind(this);
        this.checkVoucher = this.checkVoucher.bind(this);
        this.getPaymentCards = this.getPaymentCards.bind(this);
        this.addNewCard = this.addNewCard.bind(this);
        this.selectCard = this.selectCard.bind(this);
        this.goChangePlan = this.goChangePlan.bind(this);
        this.changeFrequency = this.changeFrequency.bind(this);
        this.loadCreditPlans = this.loadCreditPlans.bind(this)

        this.completePaymentIntent = this.completePaymentIntent.bind(this)
        this.errorPaymentIntent = this.errorPaymentIntent.bind(this)
        this.changeCredits = this.changeCredits.bind(this)
    }

    componentDidMount() {
        this.loadAccountMaster();
    }

    loadAccountMaster() {
        axios.get('/accountMaster')
            .then((res) => {
                this.setState({ accountMaster: res.data.AccountMaster, changingPlan: true }, this.loadCurrentBilling)
            })
    }
    loadCurrentBilling() {
        axios.get('/accountMaster/usage')
            .then(response => {
                response.data.Billing.friendlyPriceRaw = response.data.Billing.friendlyPrice
                this.setState({
                    currentBilling: response.data.Billing,
                }, this.loadCreditPlans)
            })
    }

    loadPlans() {
        var self = this;
        var plans = [];
        axios.get('/accountMaster/plan').then(response => {
            plans = response.data.Plans;
            return axios.get('/site/freePlan')
        }).then((_res) => {
            if (!plans.some(p => p.id === _res.data.id)) {
                _res.data.friendlyPrice = 0;
                _res.data.friendlyPriceRaw = 0;
                _res.data.symbol = plans[0].symbol;
                _res.data.isFree = true
                plans.unshift(_res.data)
            }
            this.setState({
                plans: plans,
                pageLoaded: true,
            }, this.getPaymentCards)
        }).catch(err => {
            console.log(err);
        })
    }

    loadCreditPlans() {
        axios.get('/accountMaster/plan?type=1').then(response => {
            this.setState({
                creditPlans: response.data.Plans
            }, this.loadPlans)
        })
    }

    getPaymentCards(skip) {
        axios.get('/accountMaster/paymentMethod/stripe').then(response => {

            var defaultCard;
            var paymentMethod;
            if (response.data.customer && response.data.customer.default_source) {
                defaultCard = response.data.customer.default_source
                paymentMethod = "card"
            }

            this.setState({
                manuallyAddingCard: false,
                paymentCards: response.data.customer.sources.data,
                defaultCard,
                paymentCardId: defaultCard,
                paymentMethod
            }, () => !skip ? this.changePlan(null, true) : undefined)
        }).catch(console.log)
    }

    changePlan(event, override) {
        var _event = event;
        var newPlanId;
        if (_event) {
            newPlanId = _event.target.value;
        } else if (!override) {
            return
        } else {
            newPlanId = this.state.newPlanId
        }

        this.setState({
            newPlanId,
            changingPlan: true
        })
        if (newPlanId > -1) {
            axios.get('/accountMaster/plan/' + newPlanId + '?billingFrequency=' + this.state.billingFrequency + (this.state.billingFrequency == 2 ? '&credits=' + this.state.credits : ''))
                .then(response => {
                    this.setState({
                        newPlan: response.data,
                        changingPlan: false
                    })
                })
        }
    }



    changeInput(event) {
        var state = this.state;
        this.state[event.target.name] = event.target.value;
        this.setState(state)
    }

    checkVoucher() {
        axios.get('/voucherCode/' + this.state.voucherCode).then(_res => {
            if (_res.data.VoucherCode && _res.data.VoucherCode.used) {
                this.setState({ currentVoucher: undefined, voucherError: "Voucher previously used" })
                return;
            }
            this.setState({ currentVoucher: _res.data.VoucherCode, voucherError: "" })
        }).catch(() => {
            this.setState({ currentVoucher: undefined, voucherError: "Invalid or Expired voucher code" })
        });
    }

    addNewCard() {
        var manuallyAddingCard = this.state.manuallyAddingCard;
        if (manuallyAddingCard) {
            manuallyAddingCard = false
        } else {
            manuallyAddingCard = true;
        }
        this.setState({ manuallyAddingCard, paymentCardId: "" })
    }

    selectCard(event) {
        var self = this;
        var newCardId = event.target.value
        this.setState({ reloadingCard: true })
        axios.put('/accountMaster/paymentMethod/stripe', {
            default_source: newCardId
        }).then(() => {
            self.setState({ manuallyAddingCard: false, paymentCardId: newCardId, reloadingCard: false })
        }).catch(err => {
            console.log(err)
        })

    }


    goChangePlan() {
        this.setState({
            pageLoaded: false
        })
        var ourPlan = this.state.newPlan
        var voucherCode = undefined;
        if (this.state.voucherCode) voucherCode = this.state.voucherCode;

        axios.put('/accountMaster/plan', {
            planId: this.state.newPlanId ? this.state.newPlanId : -1,
            accountType: ourPlan.isFree ? 'free' : 'paid',
            voucherCode: voucherCode,
            frequencyId: Number(this.state.billingFrequency),
            credits: this.state.credits
        }).then(response => {
            if (response.data.clientSecret) {
                this.setState({
                    stripeAuthPayment: response.data
                })
            } else {
                this.props.close();
            }
        }).catch(err => {
            var error = "There was a problem processing your payment";

            if (err.data && err.data.error) error = err.data.error;
            this.setState({
                pageLoaded: true,
                paymentError: error
            })
        })
    }

    changeFrequency(event) {
        let newFrequency = Number(event.target.value);
        this.setState({ billingFrequency: newFrequency, currentVoucher: "", voucherCode: "", voucherError: "" }, () => {
            this.changePlan(null, true)
        })
    }

    completePaymentIntent() {
        axios.put('/accountMaster/payment/stripeauth/' + this.state.stripeAuthPayment.OrderId).then(() => {

            this.setState({
                alertOpen: true,
                alertMessage: 'Plan Switched',
                processingPayment: false,
                pageLoaded: true,
                stripeAuthPayment: undefined
            })
            this.props.close();
        }).catch(err => {
            console.log(err)
            this.setState({
                paymentError: err.response.message || err.response,
                pageLoaded: true
            })
        })
    }

    errorPaymentIntent(err) {
        // delete the order
        axios.delete('/accountMaster/payment/' + this.state.stripeAuthPayment.OrderId + '?error=' + err).catch(() => { })
        this.setState({
            pageLoaded: true,
            stripeAuthPayment: undefined,
            paymentError: err
        })
    }

    changeCredits(ev) {
        var credits = parseInt(ev.currentTarget.value);
        if (isNaN(credits)) credits = 0;
        this.setState({
            credits
        })

        if (this.creditsTimer) clearTimeout(this.creditsTimer)
        this.creditsTimer = setTimeout(() => {

            var foundPlan

            this.state.creditPlans.forEach(plan => {

                if (plan.maxUniqueSends >= credits) {
                    if (!foundPlan || foundPlan.maxUniqueSends >= plan.maxUniqueSends) {

                        foundPlan = plan
                    }
                }
            })
            this.setState({
                newPlan: foundPlan,
                newPlanId: foundPlan ? foundPlan.id : undefined,
                changingPlan: true
            })

            axios.get('/accountMaster/plan/' + foundPlan.id + '?credits=' + credits)
                .then(response => {
                    this.setState({
                        newPlan: response.data,
                        newPlanId: foundPlan.id,
                        changingPlan: false
                    }, () => { console.log(this.state) })
                })
        }, 200)


    }

    render() {
        if (this.state.stripeAuthPayment) return <UILIB.AuthStripe paymentMethod={this.state.stripeAuthPayment.paymentMethod} clientSecret={this.state.stripeAuthPayment.clientSecret} success={this.completePaymentIntent} error={this.errorPaymentIntent} />
        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />

        var newPlan = {};
        var currPlan = this.state.currentBilling

        var currPlanExpires = this.props.accountMaster.accountMaster.nextBillDate;

        var finalPrice = 0;
        var finalPriceRaw = 0;
        var discount = 0;
        var voucherDiscount = 0;
        var vatAmt = 0;
        var currentSubDiscount = 0;

        if (this.state.newPlanId != -1 && this.state.billingFrequency > -1) {
            newPlan = this.state.newPlan;
            finalPriceRaw = Number(newPlan.price);
            currentSubDiscount = Number(newPlan.discount) || 0;

            if (discount < 0) discount = 0;
            if (currentSubDiscount < 0) currentSubDiscount = 0;

            if (newPlan.changeNow) {
                finalPrice = finalPriceRaw - discount - currentSubDiscount;
                if (this.state.currentVoucher && this.state.currentVoucher.voucherValue > 0) {
                    voucherDiscount = ((finalPrice / 100) * this.state.currentVoucher.voucherValue)
                    finalPrice = finalPrice - voucherDiscount;
                }
            } else {
                finalPrice = finalPriceRaw
            }
            if (this.state.accountMaster.addVAT) {
                vatAmt = (finalPrice / 100) * 20
            }
            finalPrice = finalPrice + vatAmt;
        }

        var selectedPaymentCard = this.state.paymentCardId;
        if (!selectedPaymentCard || !selectedPaymentCard.length) selectedPaymentCard = this.state.defaultCard;

        let changingPlanMech = null;

        let currPlanMonthlyLimitTxt = <span>{NumberFunctions.formatNumber(currPlan.maxUniqueSends)} Unique Emails per month</span>
        let newPlanMonthlyLimitTxt = <span>
            Send to {NumberFunctions.formatNumber(newPlan.maxUniqueSends)} Unique Emails per month
        </span>;
        if (this.state.billingFrequency == 1) {
            newPlanMonthlyLimitTxt = <span>
                Send to {NumberFunctions.formatNumber(newPlan.maxUniqueSends)} Unique Emails per month, for a year
            </span>
        }

        if (this.props.accountMaster.accountMaster.billedOnContacts) {
            currPlanMonthlyLimitTxt = <span>{NumberFunctions.formatNumber(currPlan.maxUniqueSends)} Contact Limit</span>

        }

        if (this.state.billingFrequency < 2 && newPlan.price > 0) {
            newPlanMonthlyLimitTxt = <span>{NumberFunctions.formatNumber(newPlan.maxUniqueSends)} Contacts</span>
        }


        //displays the billing message to let them know they are changing from contacts to sends depending on which plan they are going for
        if (this.props.accountMaster.accountMaster.billedOnContacts == 0 && this.state.billingFrequency < 2 && newPlan.price > 0) {
            changingPlanMech = "contacts"
        }
        if (this.props.accountMaster.accountMaster.billedOnContacts == 1 && this.state.billingFrequency < 2 && newPlan.price == 0) {
            changingPlanMech = "sends"
        }


        let availablePlans = this.state.plans.filter((plan) => {
            //check they dont already have this plan
            if (plan.id == this.props.accountMaster.accountMaster.AccountBillingId) {
                //and they are already on the same frequency
                if (this.props.accountMaster.accountMaster.billingFrequency == this.state.billingFrequency) {
                    return false
                }
            }
            return true;
        })

        return <div>
            <h4 className="mar-b25">Upgrade your Account</h4>

            {this.state.paymentError && this.state.paymentError.length > 0 && <div className="mar-b20 text-red">{this.state.paymentError}</div>}

            <UILIB.Row>
                {this.props.accountMaster.accountMaster.billingFrequency !== 2 && <UILIB.Column xs={12} margin={0}>
                    <UILIB.Paper className="paper-secondary">
                        <p className="mar-b10">Your Current Plan</p>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={7} margin={0}>
                                <div className="mar-b5">{currPlan.name}</div>
                                <div className="text-xsml">{currPlanMonthlyLimitTxt}</div>
                                {this.state.newPlanId != -1 && <div className="text-xsml">Your next payment is due {DateFunctions.formatDateTime(currPlanExpires, 2)}</div>}
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={5} className="text-right" margin={0}>
                                {currPlan.symbol + NumberFunctions.formatNumber(currPlan.friendlyPriceRaw, 2)} per month
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>}



                <UILIB.Column xs={12} margin={0}>
                    <UILIB.Paper className="paper-primary">
                        <p className="mar-b10 text-white" style={{ fontWeight: "600" }}>Your new Plan</p>
                        <UILIB.Row>
                            <UILIB.Column xs={12} margin={this.state.billingFrequency === -1 ? 0 : undefined}>
                                <div className="form-group mar-b5">
                                    <UILIB.Select value={this.state.billingFrequency} placeholder="Please select a billing frequency" onChange={this.changeFrequency} data={[{ label: "Monthly", value: 0 }, { label: "Annually (10% Discount)", value: 1 }, { label: "Pay As You Go", value: 2 }]} />
                                </div>
                            </UILIB.Column>
                        </UILIB.Row>

                        {(this.state.billingFrequency != -1 && this.state.billingFrequency != 2) && <UILIB.Row>
                            <UILIB.Column xs={12} sm={this.state.newPlanId != -1 ? 7 : 12} margin={0}>
                                <div className="form-group mar-b5">
                                    <UILIB.Select placeholder="Please select a new plan" value={this.state.newPlanId} onChange={this.changePlan} data={availablePlans.map((plan) => {
                                        return { value: plan.id, label: plan.name }
                                    })} />
                                </div>
                            </UILIB.Column>

                            {this.state.newPlanId != -1 && !isNaN(finalPriceRaw) && <UILIB.Column xs={12} sm={5} className="text-right" margin={0}>
                                {newPlan.symbol + NumberFunctions.formatNumber(finalPriceRaw, 2)} per {this.state.billingFrequency == 1 ? <span>year</span> : <span>month</span>}
                            </UILIB.Column>}

                            {this.state.newPlanId != -1 && <UILIB.Column xs={12} margin={0}>
                                <div className="text-xsml">{newPlanMonthlyLimitTxt}</div>
                            </UILIB.Column>}
                            {this.state.newPlanId != -1 && this.props.accountMaster.accountMaster.billingFrequency == 2 && this.props.accountMaster.accountMaster.credits > 0 && <UILIB.Column xs={12} className="mar-t10" margin={0}>
                                <div className="text-xsml">The remaining {NumberFunctions.formatNumber(this.props.accountMaster.accountMaster.credits)} will be used before using subscription</div>
                            </UILIB.Column>}
                        </UILIB.Row>}

                        {this.state.billingFrequency == 2 && <UILIB.Row>
                            <UILIB.Column xs={12} margin={0}>
                                <div className="form-group">
                                    <label htmlFor="">Number of Credits</label>
                                    <UILIB.TextInput type="number" value={this.state.credits} onChange={this.changeCredits} />
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} margin={0}>
                                Selecting <strong>Pay As You Go</strong> uses 1 credit per email rather than having a <strong>Subscription</strong> which allows you to send to the same email address multiple times in a billing period
                            </UILIB.Column>
                        </UILIB.Row>}

                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
            {changingPlanMech && <UILIB.Row>
                <UILIB.Column xs={12}>
                    <UILIB.Paper style={{ border: "2px solid red" }}>
                        {(changingPlanMech == "contacts") && <div>
                            Your new subscription will be based on the number of contacts you have in your account, instead of the number of contacts you send emails to.
                        </div>}
                        {(changingPlanMech == "sends") && <div>
                            Your new subscription will be based on the number of contacts you send to, instead of the number of contacts you have in your account.
                        </div>}
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>}

            {
                this.state.newPlanId != -1 && newPlan.changeNow && !isNaN(finalPrice) && <div>
                    {this.state.changingPlan && <UILIB.Row><UILIB.Column xs={12}><UILIB.LoadingIcons iconType="2" /></UILIB.Column></UILIB.Row>}
                    {!this.state.changingPlan && <div>
                        {(this.state.billingFrequency != 1) && <UILIB.Row margin={0}>
                            <UILIB.Column xs={12} sm={9} margin={0} className="mar-b20">
                                <div className="form-group mar-b0">
                                    <UILIB.TextInput name="voucherCode" placeholder="Voucher Code" value={this.state.voucherCode} onChange={this.changeInput} error={this.state.voucherError} />
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={3} margin={0} className=" mar-b20 text-right">
                                <UILIB.Button text="Apply" onClick={this.checkVoucher} className="button-sml full-width" />
                            </UILIB.Column>
                        </UILIB.Row>}

                        <UILIB.Row>


                            <UILIB.Column xs={12} sm={12} margin={0}>
                                <UILIB.Row>
                                    <UILIB.Column xs={8} margin={0} className="mar-b10 text-right text-sml">Plan Price:</UILIB.Column>
                                    <UILIB.Column xs={4} margin={0} className="mar-b10 text-right text-sml">{newPlan.symbol}{NumberFunctions.formatNumber(finalPriceRaw, 2)}</UILIB.Column>
                                </UILIB.Row>
                                {discount > 0 && <UILIB.Row>
                                    <UILIB.Column xs={8} margin={0} className="mar-b10 text-right text-sml">Discount:</UILIB.Column>
                                    <UILIB.Column xs={4} margin={0} className="mar-b10 text-right text-sml">- {newPlan.symbol}{NumberFunctions.formatNumber(discount, 2)}</UILIB.Column>
                                </UILIB.Row>}
                                {currentSubDiscount > 0 && <UILIB.Row>
                                    <UILIB.Column xs={8} margin={0} className="mar-b10 text-right text-sml">Discount for Current Subscription:</UILIB.Column>
                                    <UILIB.Column xs={4} margin={0} className="mar-b10 text-right text-sml">- {newPlan.symbol}{NumberFunctions.formatNumber(currentSubDiscount, 2)}</UILIB.Column>
                                </UILIB.Row>}
                                {(voucherDiscount > 0) && <UILIB.Row>
                                    <UILIB.Column xs={8} margin={0} className="mar-b10 text-right text-sml">Voucher:</UILIB.Column>
                                    <UILIB.Column xs={4} margin={0} className="mar-b10 text-right text-sml">- {newPlan.symbol}{NumberFunctions.formatNumber(voucherDiscount, 2)}</UILIB.Column>
                                </UILIB.Row>}
                                {this.state.accountMaster.addVAT && <UILIB.Row>
                                    <UILIB.Column xs={8} margin={0} className="mar-b10 text-right text-sml">VAT (20%):</UILIB.Column>
                                    <UILIB.Column xs={4} margin={0} className="mar-b10 text-right text-sml">+ {newPlan.symbol}{NumberFunctions.formatNumber(vatAmt, 2)}</UILIB.Column>
                                </UILIB.Row>}
                                <UILIB.Row>
                                    <UILIB.Column xs={8} className="text-right text-sml">Total to pay now:</UILIB.Column>
                                    <UILIB.Column xs={4} className="text-lg text-right">{newPlan.symbol}{NumberFunctions.formatNumber(finalPrice, 2)}</UILIB.Column>
                                </UILIB.Row>

                            </UILIB.Column>

                            {finalPrice > 0 && <div style={{ width: "100%" }}>
                                <UILIB.Column xs={12} margin={0}>
                                    <div className="form-group">
                                        <UILIB.Select name="paymentMethod" value={this.state.paymentMethod} placeholder="Select a payment method" data={[{ label: "Credit/Debit Card", value: "card" }]} onChange={this.changeInput} />
                                    </div>
                                </UILIB.Column>


                                {(this.state.paymentMethod && this.state.paymentMethod == "card" && this.state.paymentCards && this.state.paymentCards.length > 0 && !this.state.manuallyAddingCard) &&
                                    <UILIB.Column xs={12} margin={0} className="mar-b20">
                                        {!!this.state.reloadingCard && <UILIB.LoadingIcons iconType="2" />}
                                        {!this.state.reloadingCard && <div className="form-group mar-b0">

                                            <UILIB.Select placeholder="Select an existing payment card..." data={this.state.paymentCards.map((card) => {
                                                return { label: card.brand + " ending " + card.last4 + " (exp " + card.exp_month + "/" + card.exp_year + ")", value: card.id }
                                            })} onChange={this.selectCard} value={selectedPaymentCard} />
                                            <div className="mar-t10" style={{ textAlign: "right", fontSize: "14px" }}>
                                                or <a onClick={this.addNewCard}>Add a new Card</a>
                                            </div>
                                        </div>}
                                    </UILIB.Column>
                                }

                                {(this.state.paymentMethod && this.state.paymentMethod == "card" && (!this.state.paymentCards || !this.state.paymentCards.length || this.state.manuallyAddingCard)) &&
                                    <UILIB.Column xs={12} margin={0}>

                                        <StripeAddCard close={() => this.getPaymentCards(true)} hasCloseButton={this.state.manuallyAddingCard} />

                                    </UILIB.Column>
                                }
                            </div>
                            }
                            {(finalPrice == 0 || (this.state.paymentCardId && this.state.paymentCardId.length > 0)) && <UILIB.Column xs={12} margin={0}>
                                <p className="mar-b20">By clicking the "Continue" button below you agree to our Terms and Conditions of use. {this.state.billingFrequency < 2 && <span>Your card will be billed {newPlan.symbol}{NumberFunctions.formatNumber(finalPriceRaw, 2)} every {this.state.billingFrequency == 1 ? <span>year</span> : <span>30 days</span>} when your subscription renews. You can change, or cancel your plan at any time.</span>}</p>
                                {this.state.paymentError && this.state.paymentError.length > 0 && <div className="mar-b20 text-red">{this.state.paymentError}</div>}
                                <UILIB.Button className="button-md" text={"Pay " + newPlan.symbol + NumberFunctions.formatNumber(finalPrice, 2)} onClick={this.goChangePlan} />
                            </UILIB.Column>
                            }
                        </UILIB.Row>
                    </div>}

                </div>
            }

            {
                this.state.newPlanId != -1 && !newPlan.changeNow && !isNaN(finalPrice) && <div>
                    <UILIB.Row>
                        <UILIB.Column xs={12}>
                            <p className="text-md center-xs">You are downgrading your account</p>
                            {!!finalPrice && <p className="center-xs">we will not charge you now but your account will be charged <strong>{newPlan.symbol + NumberFunctions.formatNumber(finalPriceRaw, 2)}</strong> in <strong>{NumberFunctions.formatNumber(newPlan.daysRemaining)}</strong> days</p>}
                        </UILIB.Column>
                        <UILIB.Column xs={12} className="center-xs">
                            <UILIB.Button text="Downgrade" onClick={this.goChangePlan} className="button-md" />
                        </UILIB.Column>
                    </UILIB.Row>
                </div>
            }


        </div >
    }
}

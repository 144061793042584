// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EXEisM9O5RCrRFQYJsMi{display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));gap:16px}.icz9KJ5Uz0UjKHmT07QQ{position:relative}.W7ihXM2AG61amkhSkjmf{position:absolute;right:10px;bottom:3px}.dblNyeON6hSuO3OdU8Vy{border:1px solid var(--grey-200);border-radius:8px;padding:24px;box-shadow:var(--shadow-extra-small);margin-top:24px}.dblNyeON6hSuO3OdU8Vy p{font-size:12px;color:var(--grey-800)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/aiAssistant/sources/components/addSourceDialog/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,+CAAA,CACA,QAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,UAAA,CACA,UAAA,CAGF,sBACE,gCAAA,CACA,iBAAA,CACA,YAAA,CACA,oCAAA,CACA,eAAA,CAEA,wBACE,cAAA,CACA,qBAAA","sourcesContent":[".halfWidthWrapper {\n  display: grid;\n  grid-template-columns: repeat(2, minmax(0, 1fr));\n  gap: 16px;\n}\n\n.providerInputWrapper {\n  position: relative;\n}\n\n.providerInputSpinner {\n  position: absolute;\n  right: 10px;\n  bottom: 3px;\n}\n\n.provider {\n  border: 1px solid var(--grey-200);\n  border-radius: 8px;\n  padding: 24px;\n  box-shadow: var(--shadow-extra-small);\n  margin-top: 24px;\n\n  p {\n    font-size: 12px;\n    color: var(--grey-800);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"halfWidthWrapper": "EXEisM9O5RCrRFQYJsMi",
	"providerInputWrapper": "icz9KJ5Uz0UjKHmT07QQ",
	"providerInputSpinner": "W7ihXM2AG61amkhSkjmf",
	"provider": "dblNyeON6hSuO3OdU8Vy"
};
export default ___CSS_LOADER_EXPORT___;

import React, { Fragment } from 'react'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import UILIB from '../../../../Common-Objects/Lib';
import SegmentRuleLine from './segmentRuleLine'
import i18n from '~/i18n'
import moment from 'moment'
import DateTimeFunctions from '../../../../Classes/dateTimeFunctions';
import NumberFunctions from '../../../../Classes/numberFormatFunctions';
import axios2 from 'axios'
import segmentsHelper from '../../../../Classes/segments'
import SegmentAutomate from './automate';
import { isSegmentValidForAutomation } from '../../automation/utils';
import { alterFullpage } from '../../../../data/actions/siteActions';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, account: store.accountMaster, site: store.siteMaster }
})

export default class SegmentEditor extends React.Component {
    constructor(props) {
        super(props);

        var stringOperators = segmentsHelper.getOperators('string');
        var intOperators = segmentsHelper.getOperators('int');
        var boolOperators = segmentsHelper.getOperators('bool');
        var dateOperators = segmentsHelper.getOperators('date');
        var groupOperators = segmentsHelper.getOperators('group');
        var selectOperators = segmentsHelper.getOperators('select');
        var blankOperators = stringOperators.filter(o => o.value === 'is not blank' || o.value === 'is blank')
        var linkTagOperators = segmentsHelper.getOperators('linkTag')
        var scoreOperators = segmentsHelper.getOperators('score')
        this.state = {
            pageLoaded: true,
            segmentName: "",
            errors: {
                segmentName: "",
                groups: []
            },
            aggs: {},
            segmentData: [],
            groupData: [],
            campaignData: [],
            allCampaignData: [],
            subscriberTags: [],
            linkTags: [],
            fields: [],
            loaded: false,
            searchResults: [],
            loadingSearchResults: true,
            totalSubs: undefined,
            totalSearchResults: 0,
            searchText: "",
            sortDirection: "asc",
            sortColumn: "emailAddress",
            pageSize: 10,
            currentPage: 0,
            ignoreOrg: false,
            operators: {
                TEXT: stringOperators,
                SELECT: selectOperators,
                MULTISELECT: selectOperators,
                NUMBER: intOperators,
                DATE: dateOperators,
                GROUP: groupOperators,
                'TRUE/FALSE': boolOperators,
                BOOLEAN: boolOperators,
                CAMPAIGN: boolOperators,
                TELNO: stringOperators,
                SUBSCRIBERTAGS: blankOperators.concat(boolOperators),
                LINKTAGS: linkTagOperators,
                SCORE: scoreOperators,
                COUNTRY: boolOperators,
                DOMAIN: stringOperators.slice().filter(o => o.value === '=' || o.value === '!=')
            },
            loadingCampaignData: false,
            automating: false,
            countryCodes: []
        }

        this.updateValue = this.updateValue.bind(this);
        this.loadSegment = this.loadSegment.bind(this);
        this.loadGroups = this.loadGroups.bind(this);
        this.loadCampaigns = this.loadCampaigns.bind(this);
        this.getFields = this.getFields.bind(this);
        this.flattenSegmentData = this.flattenSegmentData.bind(this);
        this.unflattenSegmentData = this.unflattenSegmentData.bind(this);
        this.updateSegment = this.updateSegment.bind(this);
        this.addSegment = this.addSegment.bind(this);
        this.deleteSegment = this.deleteSegment.bind(this);
        this.saveSegment = this.saveSegment.bind(this);
        this.copyRule = this.copyRule.bind(this);
        this.changeAndOr = this.changeAndOr.bind(this);
        this.addSegGroup = this.addSegGroup.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.changeSearchText = this.changeSearchText.bind(this);
        this.getResults = this.getResults.bind(this);
        this.changeTableOptions = this.changeTableOptions.bind(this);
        this.loadSubscriberTags = this.loadSubscriberTags.bind(this);
        this.filterCampaigns = this.filterCampaigns.bind(this);
        this.loadLinkTags = this.loadLinkTags.bind(this)
        this.automate = this.automate.bind(this)
        this.loadCountries = this.loadCountries.bind(this);
        this.checkForEmailDomains = this.checkForEmailDomains.bind(this)
    }

    componentDidMount() {
        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();

        if (this.props.segmentId) {
            this.setState({ segmentId: this.props.segmentId }, this.loadSegment)
        }
        else {
            if (this.props.segName) {
                this.setState({ segmentName: this.props.segName })
            }
            var segmentData = {
                id: undefined,
                queryObj: {
                    "and": [{
                        "customFieldId": "emailAddress",
                        "operator": "=",
                        "value": ""
                    }],
                    "or": []
                }
            }
            this.flattenSegmentData([segmentData])
        }

        axios.get('/accountMaster/totalsubs').then(res => {
            this.setState({ totalSubs: res.data.total })
        })
    }

    componentWillUnmount() {
        this.props.dispatch(alterFullpage(null))
    }

    loadSegment() {
        axios.get('/segment/' + this.props.segmentId).then((res) => {
            this.setState({ segmentName: res.data.Segments[0].name, id: this.props.segmentId, ignoreOrg: res.data.Segments[0].ignoreOrg }, () => {
                this.flattenSegmentData(res.data.Segments);
            })
        })
    }

    async flattenSegmentData(segmentData) {
        const campaignIds = []
        var finalSegs = segmentData.map(seg => {
            var segmentAndData = seg.queryObj.and.map(s => {
                if (s.customFieldId === 'campaignId' && !campaignIds.some(c => c == s.value)) campaignIds.push(s.value)
                return { customFieldId: s.customFieldId, operator: s.operator, value: s.value, andOr: "and", lastUpdated: new Date(), table: s.table, orgOnly: s.orgOnly, days: s.days }
            })
            var segmentOrData = seg.queryObj.or.map(s => {
                if (s.customFieldId === 'campaignId' && !campaignIds.some(c => c == s.value)) campaignIds.push(s.value)
                return { customFieldId: s.customFieldId, operator: s.operator, value: s.value, andOr: "or", lastUpdated: new Date(), table: s.table, orgOnly: s.orgOnly, days: s.days }
            })
            return { id: seg.id, queryObj: segmentAndData.concat(segmentOrData) }
        })

        const updateObj = { segmentData: finalSegs }
        if (campaignIds.length) {
            const res = await axios.get('/campaign?hideStats=true&simple=true&includeChildren=true&id=' + campaignIds.join('&id='))
            updateObj.allCampaignData = res.data.Campaigns.map(c => {
                return {
                    value: c.id,
                    label: c.campaignName,
                    iconRight: c.startDate ? <UILIB.SquareChip>{DateTimeFunctions.formatDateTime(c.startDate, 2)}</UILIB.SquareChip> : null
                }
            })
        }

        this.setState(updateObj, this.getFields)
    }

    unflattenSegmentData(segmentData) {
        var finalSegs = segmentData.map(seg => {
            var segmentDataFinal = {
                id: seg.id,
                queryObj: {
                    or: [],
                    and: []
                }
            }
            seg.queryObj.forEach(theSeg => {
                var injectVal = { customFieldId: theSeg.customFieldId, operator: theSeg.operator, value: theSeg.value, table: theSeg.table, orgOnly: theSeg.orgOnly, days: theSeg.days };
                if (theSeg.andOr == "and") {
                    segmentDataFinal.queryObj.and.push(injectVal)
                }
                if (theSeg.andOr == "or") {
                    segmentDataFinal.queryObj.or.push(injectVal)
                }
            })
            return segmentDataFinal;
        })
        return finalSegs;
    }

    updateValue(event) {
        let errors = this.state.errors;
        errors.segmentName = "";

        this.setState({ [event.target.name]: event.target.value, errors })
    }

    getFields() {
        var self = this;
        axios.get('/customfield?orderBy=fieldDesc&orderDir=asc').then((res) => {
            let hasDiffTypes = false
            const firstType = res.data.CustomFields[0]?.entityType
            if (firstType && !res.data.CustomFields.every(f => f.entityType === firstType)) {
                hasDiffTypes = true
            }

            function parseEntityType(type) {
                switch (type) {
                    case 1: return i18n.t("subscribers:customfields.contactFields");
                    case 2: return i18n.t("subscribers:customfields.orgFields");
                    case 3: return i18n.t("subscribers:customfields.contactOrgFields")
                    default: return ''
                }
            }

            var fields = [
                { value: 'emailAddress', label: i18n.t('emailAddress'), fieldType: "TEXT", optionsType: "TEXT", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'emailDomain', label: i18n.t('subscribers:segments.edit.fields.domainName'), fieldType: "TEXT", optionsType: "DOMAIN", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'GroupId', label: i18n.t('group'), fieldType: "TEXT", optionsType: "GROUP", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'score', label: i18n.t('subscribers:segments.edit.fields.score'), fieldType: "SCORE", optionsType: "SCORE", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'country', label: i18n.t('subscribers:segments.edit.fields.country'), fieldType: "TEXT", optionsType: "COUNTRY", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'createdAt', label: i18n.t('subscribers:segments.edit.fields.created'), fieldType: "DATE", optionsType: "DATE", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'updatedAt', label: i18n.t('subscribers:segments.edit.fields.updated'), fieldType: "DATE", optionsType: "DATE", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'lastOpened', label: i18n.t('subscribers:segments.edit.fields.opened'), fieldType: "DATE", optionsType: "DATE", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'lastClicked', label: i18n.t('subscribers:segments.edit.fields.clicked'), fieldType: "DATE", optionsType: "DATE", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'lastBounced', label: i18n.t('subscribers:segments.edit.fields.lastBounced'), fieldType: "DATE", optionsType: "DATE", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'lastSent', label: i18n.t('subscribers:segments.edit.fields.lastSent'), fieldType: "DATE", optionsType: "DATE", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'sentWithin', label: i18n.t('subscribers:segments.edit.fields.sentWithin'), fieldType: "NUMBER", optionsType: "NUMBER", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'campaignId', label: i18n.t('subscribers:segments.edit.fields.campaignSentTo'), fieldType: "TEXT", optionsType: "CAMPAIGN", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'subscriberTags', label: i18n.t('subscribers:segments.edit.fields.tags'), fieldType: "TEXT", optionsType: "SUBSCRIBERTAGS", fType: "", group: i18n.t("subscribers:customfields.systemFields") },
                { value: 'linkTags', label: i18n.t('subscribers:segments.edit.fields.linkTags'), fieldType: "TEXT", optionsType: "LINKTAGS", fType: "", group: i18n.t("subscribers:customfields.systemFields") }

            ].concat(res.data.CustomFields.map(field => {
                let iconRight;
                if (field.Application) {
                    iconRight = <UILIB.Avatar style={{ height: 20, width: 20, border: '1px solid grey' }} for={field.id} tooltip={field.Application.appName} src={field.Application.appLogoUri} />
                }
                return {
                    value: field.id,
                    label: field.fieldDesc,
                    fieldType: field.fieldType,
                    optionsType: field.fieldType,
                    options: field.options,
                    iconRight: iconRight,
                    entityType: field.entityType,
                    // fType: field.Application ? field.Application.appName : ""
                    fType: "",
                    group: hasDiffTypes ? parseEntityType(field.entityType) : i18n.t("subscribers:customfields.customFields")
                }
            }))

            fields = fields.sort((a, b) => {
                if (a.fType > b.fType) return 1;
                if (a.fType < b.fType) return -1;

                if (a.fieldDesc < b.fieldDesc) return 1;
                if (a.fieldDesc > b.fieldDesc) return -1;

                return 0;
            })
            // var filtered = this.state.segmentData.map(theSeg => {
            //     theSeg.queryObj = theSeg.queryObj.filter(segment => {
            //         var found = fields.find(f => f.value == segment.customFieldId)
            //         return !!found
            //     })
            //     return theSeg
            // });

            self.setState({
                fields
            }, self.loadGroups)
        })
    }

    loadGroups() {
        var self = this;
        //load all user groups for use in dropdowns
        axios.get('/group?recordLimit=10000').then((res) => {
            var groupData = res.data.Groups.map(tG => {
                return { value: tG.id, label: tG.groupName }
            })
            self.setState({ groupData }, () => this.loadCampaigns())
        })
    }
    filterCampaigns(filter) {
        let self = this;
        clearTimeout(self.campaignTimer);
        if (filter) {
            self.campaignTimer = setTimeout(function () {
                self.loadCampaigns(filter);
            }, 500)
        } else if (!filter) {
            self.loadCampaigns(filter);
        }
    }
    loadCampaigns(filter) {

        var self = this;
        self.setState({ loadingCampaignData: true })
        //load all campaigns for use in dropdowns
        let uri = '/campaign?sortColumn=startDate&sortOrder=desc&startRecord=0&recordLimit=30&type=1&hideStats=true&type=10&type=11&simple=true&includeChildren=true';
        if (filter) uri += "&searchText=" + filter;
        axios.get(uri).then((res) => {
            let allCampaignData = this.state.allCampaignData || []
            var campaignData = res.data.Campaigns.map(tG => {
                var campaignName = tG.campaignName;
                if (tG.parentCampaignId && !isNaN(tG.parentCampaignId)) {
                    var parentCampaign = res.data.Campaigns.find(cp => cp.id == tG.parentCampaignId)
                    if (parentCampaign) {
                        campaignName = parentCampaign.campaignName + " > " + campaignName + `(#${tG.id})`;
                    }
                }
                if (!allCampaignData.some(d => d.value === tG.id)) {
                    allCampaignData.push({
                        value: tG.id,
                        label: campaignName,
                        iconRight: tG.startDate ? <UILIB.SquareChip>{DateTimeFunctions.formatDateTime(tG.startDate, 2)}</UILIB.SquareChip> : null
                    })
                }
                return {
                    value: tG.id,
                    label: campaignName,
                    iconRight: tG.startDate ? <UILIB.SquareChip>{DateTimeFunctions.formatDateTime(tG.startDate, 2)}</UILIB.SquareChip> : null
                }
            })
            self.setState({ campaignData, allCampaignData, loadingCampaignData: false }, () => {
                if (!filter) {
                    this.loadSubscriberTags();
                }
            })

        })
    }

    async loadSubscriberTags() {
        var self = this;
        //load all campaigns for use in dropdowns
        let tagData = await axios.get('/tags?tagType=0')
        tagData = tagData.data.map(tG => {
            return {
                value: tG.id,
                label: tG.tagName,
                iconRight: tG.Application && tagData.data.length < 200 ? <UILIB.Avatar tooltip={tG.Application.appName} for={tG.id} style={{ height: 20, width: 20, border: '1px solid grey' }} src={tG.Application.appLogoUri} /> : null
            }
        })

        let adTagsData = await axios.get('/tags?tagType=3')
        tagData = tagData.concat(adTagsData.data.map(tG => {
            return {
                value: tG.id,
                label: tG.tagName,
                iconRight: tG.Application && adTagsData.data.length < 200 ? <UILIB.Avatar tooltip={tG.Application.appName} for={tG.id} style={{ height: 20, width: 20, border: '1px solid grey' }} src={tG.Application.appLogoUri} /> : null
            }
        }))

        self.setState({ subscriberTags: tagData }, this.loadLinkTags)
    }

    loadLinkTags() {
        axios.get('/tags?tagType=1').then(res => {
            this.setState({
                linkTags: res.data.map(t => ({ value: t.id, label: t.tagName }))
            }, this.loadCountries)
        })
    }

    async loadCountries() {
        let countries = await axios.get('/countries');
        countries = countries.data.Countries;
        let countryCodes = countries.map(c => ({ value: c.code, label: c.code }))
        this.setState({
            countryCodes: countryCodes,
            loaded: true
        }, this.getResults)

    }
    addSegment(subSegId) {
        var segs = this.state.segmentData;
        var andOr = segs[subSegId].queryObj[0] ? segs[subSegId].queryObj[0].andOr : 'and';
        var segmentData = { customFieldId: "emailAddress", operator: "=", value: "", andOr: andOr, lastUpdated: new Date() }
        segs[subSegId].queryObj.push(segmentData)
        this.setState({ segmentData: segs })
    }

    updateSegment(newData, dataId, changeType, segmentGroupId) {
        var self = this;
        var segments = this.state.segmentData[segmentGroupId];
        newData.lastUpdated = new Date();
        if (changeType == "customFieldId") {
            //changing custom field so just reset value and operator
            newData.value = "";
            newData.operator = "="

            var ourField = this.state.fields.find(fd => fd.value == newData.customFieldId);
            if (ourField && ourField.fieldType) {
                var defaultOperator = this.state.operators[ourField.fieldType]
                if (defaultOperator && defaultOperator.length) {
                    newData.operator = defaultOperator[0].value;
                }
            }

            delete newData.table;
            if (newData.customFieldId == "GroupId") {
                newData.table = "groupSubscriber"
                newData.value = this.state.groupData && this.state.groupData.length ? this.state.groupData[0].value : 0
            }
            if (newData.customFieldId == "campaignId") {
                newData.table = "campaigns"
                newData.value = this.state.campaignData && this.state.campaignData.length ? this.state.campaignData[0].value : 0
            }
            if (newData.customFieldId == "subscriberTags") {
                newData.table = "subscriberTags"
                newData.value = this.state.subscriberTags && this.state.subscriberTags.length ? this.state.subscriberTags[0].value : 0
            }
        }
        if (changeType == "operator") {
            var oldOperator = segments.queryObj[dataId].operator;
            if (newData.operator == "between" || newData.operator == "not between" && (oldOperator != "between" && oldOperator != "not between")) {
                newData.value = [moment().utc(), moment().utc()];
            }
            if ((oldOperator == "between" || oldOperator == "not between") && (newData.operator != "between" && newData.operator != "not between")) {
                newData.value = moment().utc();
            }
        }

        segments.queryObj[dataId] = newData;
        var segmentData = this.state.segmentData;
        segmentData[segmentGroupId] = segments;
        this.setState({ segmentData }, () => {

            clearTimeout(self.updateTimer);
            self.updateTimer = setTimeout(function () {
                self.getResults();
            }, 500)

        })
    }

    deleteSegment(dataId, segmentGroupId) {
        var segments = this.state.segmentData;
        segments[segmentGroupId].queryObj.splice(dataId, 1);
        this.setState({ segmentData: segments }, this.getResults)
    }

    copyRule(dataId, segmentGroupId) {
        var segments = this.state.segmentData;
        segments[segmentGroupId].queryObj.push(segments[segmentGroupId].queryObj[dataId]);
        this.setState({ segmentData: segments }, this.getResults)
    }

    saveSegment(close) {
        if (this.props.readonly) return true
        var errors = {
            segmentName: "",
            groups: []
        };
        var errored = false;
        if (!this.state.segmentName || this.state.segmentName.length < 2) {
            errors.segmentName = i18n.t('subscribers:segments.edit.pleaseEnterALongerSegmentName')
            errored = true;
        }
        //make sure all field values are ok
        this.state.segmentData.forEach((segGroup, dataId) => {
            segGroup.queryObj.forEach((theSeg, lineId) => {
                if (theSeg.value === '' && theSeg.operator !== 'is blank' && theSeg.operator !== 'is not blank') {
                    if (!errors.groups[dataId]) errors.groups[dataId] = {};
                    if (!errors.groups[dataId].lines) errors[dataId].lines = {};
                    errors.groups[dataId].lines[lineId] = i18n.t('subscribers:segments.edit.pleaseEnterAValue')
                    errored = true;
                }
            })
        })

        if (errored) {
            this.setState({ errors: errors })
            return false;
        }

        const save = () => {
            this.props.dispatch(alterFullpage(null))
            var segmentData = {
                name: this.state.segmentName,
                ignoreOrg: this.state.ignoreOrg,
                queryObj: this.unflattenSegmentData(this.state.segmentData),
                readOnly: false
            }

            if (this.props.temporary) {
                segmentData.temporary = true
            }
            var promise;
            if (this.props.segmentId) {
                promise = axios.put('/segment/' + this.props.segmentId, segmentData)
            } else {
                promise = axios.post('/segment', segmentData)
            }
            return promise.then((resp) => {
                if (!this.props.segmentId) {
                    this.setState({ id: resp.data.Segment.id || resp.data.Segment.segmentId })
                }
                if (close) this.props.close(resp)
                return true
            }).catch(err => {
                let errors = this.state.errors;
                let error = "Error saving segment"
                if (err && err.data && err.data.error) {
                    error = err.data.error;
                }
                errors.segmentName = error;
                this.setState({ errors, loaded: true })
                return false
            })
        }

        if (this.checkForEmailDomains()) {
            // show a modal
            const modal = <UILIB.Modal width='900px' titleStyle={{ backgroundColor: this.props.site.colours['$primary'] }} title={<div style={{ color: 'white', fontSize: 16 }}>{i18n.t("subscribers:segments.edit.domainSearchModal.header")}</div>}>
                <div>
                    <p dangerouslySetInnerHTML={{
                        __html: i18n.t("subscribers:segments.edit.domainSearchModal.content", {
                            siteName: this.props.site.siteName
                        })
                    }}></p>
                    <div className="quickFlex">
                        <UILIB.Button className="button-primary mar-r10" onClick={() => this.props.dispatch(alterFullpage(null))}>{i18n.t("subscribers:segments.edit.domainSearchModal.edit")}</UILIB.Button>
                        <UILIB.Button className="button-red" onClick={save}>{i18n.t("subscribers:segments.edit.domainSearchModal.save")}</UILIB.Button>
                    </div>
                </div>
            </UILIB.Modal>
            this.props.dispatch(alterFullpage(modal))
        } else {
            save()
        }

        // this.setState({ loaded: false })

    }

    changeTableOptions(currentPage, sortDirection, sortColumn, pageSize, searchTextUnused) {
        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!currentPage && currentPage != "0") {
            currentPage = this.state.currentPage;
        }

        var startRecord = currentPage * pageSize;
        if (sortDirection != this.state.sortDirection || sortColumn != this.state.sortColumn || pageSize != this.state.pageSize) {
            currentPage = 0;
        }
        this.setState({ sortColumn: sortColumn, sortDirection: sortDirection, pageSize: pageSize, currentPage: currentPage, startRecord: startRecord }, this.getResults);
    }

    getResults() {
        var failed = false
        let contains = 0
        this.state.segmentData.forEach(segment => {
            segment.queryObj.forEach(seg => {
                if (segment.operator === 'contain' || segment.operator === 'not contain') contains++;
                if (seg.operator === 'is not blank' || seg.operator === 'is blank') return
                if ((!seg.value && typeof seg.value !== 'boolean') || seg.value.length < 1) failed = true;
            })

        })
        if (failed) {
            return;
        }

        if (contains > 3) return

        if (this.state.loadingSearchResults) {
            this.source.cancel('I cancelled this')
            this.source = this.CancelToken.source();
        }

        var queryObj = this.unflattenSegmentData(this.state.segmentData)
        if (this.state.searchText && this.state.searchText.length) {
            queryObj[0].queryObj.and.push({
                customFieldId: "emailAddress",
                operator: "contain",
                value: this.state.searchText
            })
        }
        this.setState({ loadingSearchResults: true })

        var offset = 0;
        if (this.state.currentPage) {
            offset = this.state.currentPage * this.state.pageSize;
        }
        var queryStr = '?orderby=' + this.state.sortColumn + '&orderdir=' + this.state.sortDirection + '&offset=' + offset + '&limit=' + this.state.pageSize

        axios.post(`/segment/quickQuery${queryStr}`, {
            queryObj: queryObj,
            ignoreOrg: this.state.ignoreOrg
        }, {
            cancelToken: this.source.token
        }).then(_res => {
            var cFieldData = [];
            if (_res.data.rows && _res.data.rows.length) {
                _res.data.rows.forEach(tR => {
                    if (tR.customFields && tR.customFields.length) {
                        tR.customFields.forEach(tF => {
                            var foundField = this.state.fields.find(sF => sF.value == tF.id);
                            if (foundField) cFieldData[tF.id] = this.state.fields.find(sF => sF.value == tF.id)
                        })
                    }
                })
            }

            this.setState({
                aggs: _res.data.aggs,
                searchResults: _res.data.rows.map(theRow => {

                    var groupsData = theRow.groups.map(g => {
                        var className = "square-chip-green";
                        var gText = i18n.t('Active');
                        if (!g.active) {
                            className = "square-chip-orange"
                            gText = i18n.t('In-Active')
                        }
                        if (g.unsubscribe) {
                            className = "square-chip-red"
                            gText = i18n.t('Unsubscribed')
                        }
                        var foundGroup = this.state.groupData.find(sg => sg.value == g.GroupId)
                        if (!foundGroup) return;
                        var groupname = foundGroup.label;
                        return <UILIB.SquareChip key={"status_" + g.GroupId} value={groupname} className={className + " mar-r10"} />
                    })
                    var obj = {
                        emailAddress: {
                            headerValue: i18n.t('subscribers:segments.edit.emailAddress'),
                            value: <a onClick={() => window.open('/cp/subscriber/' + theRow.id, '_blank')}>{theRow.emailAddress}</a>,
                            orderBy: true,
                            order: 0
                        },
                        createdAt: {
                            headerValue: i18n.t('subscribers:segments.edit.dateAdded'),
                            value: DateTimeFunctions.formatDateTime(theRow.createdAt),
                            orderBy: true,
                            order: 1
                        },
                        updatedAt: {
                            headerValue: i18n.t('subscribers:segments.edit.dateUpdated'),
                            value: DateTimeFunctions.formatDateTime(theRow.updatedAt),
                            orderBy: true,
                            order: 2
                        },
                        score: {
                            headerValue: i18n.t('subscribers:segments.edit.score'),
                            value: <UILIB.Score score={theRow.score} />,
                            orderBy: true,
                            order: 3
                        },
                        groups: {
                            headerValue: i18n.t('subscribers:segments.edit.groups'),
                            value: groupsData,
                            orderBy: false,
                            order: 4
                        }
                    }

                    if (Object.keys(cFieldData).length) {
                        Object.keys(cFieldData).forEach((tF, index) => {
                            var foundVal = theRow.customFields.find(cF => cF.id == tF);
                            var rowVal = "";
                            if (foundVal) {
                                if (Array.isArray(foundVal.value)) {

                                    if (cFieldData[tF].options && cFieldData[tF].options.length) {
                                        foundVal.value.forEach(tV => {
                                            var optVal = cFieldData[tF].options.find(opt => opt.value == tV)
                                            if (optVal) {
                                                rowVal += optVal.label + ", "
                                            }
                                            else {
                                                rowVal += tV + ", "
                                            }
                                        })
                                        rowVal = rowVal.substring(0, rowVal.length - 2)
                                    }
                                    else {
                                        rowVal = String(foundVal.value);
                                    }
                                }
                                else {
                                    rowVal = String(foundVal.value);
                                    if (cFieldData[tF].fieldType === 'DATE') {
                                        rowVal = DateTimeFunctions.formatDateTime(rowVal, "2")
                                        if (rowVal === 'Invalid Date') rowVal = ''
                                    }
                                    if (cFieldData[tF].fieldType === 'BOOLEAN') {
                                        rowVal = rowVal === 'true' ? 'Yes' : 'No'
                                    }
                                    if (cFieldData[tF].fieldType === 'SELECT' && cFieldData[tF].options && cFieldData[tF].options.length) {
                                        const option = cFieldData[tF].options.find(o => o.value == rowVal)
                                        rowVal = option ? option.label : rowVal
                                    }
                                }
                            }
                            if (rowVal === 'null') rowVal = ''
                            obj['' + tF + ''] = {
                                headerValue: cFieldData[tF].label,
                                value: rowVal,
                                orderBy: true,
                                order: index + 5
                            }

                        })
                    }

                    return obj;
                }),
                totalSearchResults: _res.data.count,
                loadingSearchResults: false
            })
        }).catch(err => {
            console.log(err)
            if (axios2.isCancel(err)) {
                console.log('cancelled request')
            }
        })
    }

    changeAndOr(event, segmentGroupId) {
        var newVal = event.target.value;
        var segments = this.state.segmentData;
        var theSegs = segments[segmentGroupId];
        theSegs.queryObj.forEach(seg => {
            seg.andOr = newVal
            seg.lastUpdated = new Date()
        })
        segments[segmentGroupId] = theSegs;

        this.setState({ segmentData: segments }, this.getResults)

    }

    addSegGroup() {
        var segmentData = this.state.segmentData;
        segmentData.push({
            id: undefined,
            queryObj: [{
                andOr: "and",
                customFieldId: "emailAddress",
                lastUpdated: new Date(),
                operator: "=",
                table: undefined,
                value: ""
            }]
        })
        this.setState({ segmentData })
    }

    deleteGroup(segmentGroupId) {
        var segmentData = this.state.segmentData;
        segmentData.splice(segmentGroupId, 1);
        this.setState({ segmentData }, this.getResults)
    }

    changeSearchText(event) {
        var self = this;
        this.setState({ searchText: event.target.value }, () => {
            if (self.timer) clearTimeout(self.timer);
            self.timer = setTimeout(self.getResults, 250);
        })
    }

    toggleIgnoreOrg() {
        this.setState({
            ignoreOrg: !this.state.ignoreOrg
        }, this.getResults)
    }

    async automate() {
        const canContinue = await this.saveSegment(false)
        if (!canContinue) return
        this.props.dispatch(alterFullpage(<UILIB.Drawer ignoreOverflow={true} standalone={true} width={500} showClose={true} isOpen={true} startClose={() => this.props.dispatch(alterFullpage(null))}>
            <SegmentAutomate SegmentId={this.state.id} segmentName={this.state.segmentName} />
        </UILIB.Drawer>))
    }

    checkForEmailDomains() {
        //iterate over the filters and check if there are emailAddress contains or endsWith conditions, if so suggest using the domainName field instead
        let contains = false;
        let endsWith = false;
        let domainName = false;
        this.state.segmentData.forEach(segGroup => {
            segGroup.queryObj.forEach(seg => {
                if (seg.customFieldId === 'emailAddress') {
                    if (seg.operator === 'contain' || seg.operator === 'not contain') contains = true;
                    if (seg.operator === 'not end with' || seg.operator === 'end with') endsWith = true;
                }
                if (seg.customFieldId === 'emailDomain') domainName = true;
            })
        })
        return (contains || endsWith) && !domainName
    }


    render() {
        if (!this.state.loaded || this.state.totalSubs === undefined) {
            return <UILIB.LoadingIcons iconType="2" />
        }

        var missingValues = false;
        var onlyContains = true;
        var totalContains = 0;

        var pagingTotal = this.state.totalSearchResults;
        if (pagingTotal > 10000) pagingTotal = 10000;
        this.state.segmentData.forEach(segGroup => {
            segGroup.queryObj.forEach(tS => {
                if (tS.operator === 'contain' || tS.operator === 'not contain') totalContains++;
                if (tS.operator !== 'contain') onlyContains = false
                if (tS.operator === 'is not blank' || tS.operator === 'is blank') return
                if (tS.value === undefined || tS.value === "" || tS.value === null) missingValues = true;
            })
        })

        var totalSubscribers = this.state.totalSubs;

        const cantAutomate = this.state.segmentData.some(s => !isSegmentValidForAutomation(s))

        const showDomainNameSuggestions = this.checkForEmailDomains()

        return <div>

            <div className="quickFlex mar-b25" style={{ alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ alignItems: "center" }}>
                    <h4 className="mar-b5 cyt-segment-editor-drawer">{i18n.t('subscribers:segments.edit.segmentEditor')}</h4>
                    <div>{i18n.t(this.props.readonly ? 'subscribers:segments.edit.subTitleReadonly' : 'subscribers:segments.edit.subTitle')}</div>
                </div>
                {!!this.state.segmentId && <div>
                    Segment Id: {this.state.segmentId}
                </div>}
            </div>

            <UILIB.TextInput outerClassName="mar-b25" type="text" label={i18n.t('subscribers:segments.edit.enterANameForYourSegment')} name="segmentName" error={this.state.errors.segmentName} value={this.state.segmentName} onChange={this.updateValue} disabled={this.props.readonly} />

            {(totalSubscribers > 1000 && onlyContains) && <div className=" mar-b25 text-red" dangerouslySetInnerHTML={{ __html: i18n.t('subscribers:segments.containWarning') }}></div>}
            {totalSubscribers > 100000 && <div className="mar-b25 text-red" dangerouslySetInnerHTML={{ __html: i18n.t('subscribers:segments.containWarningSubscriberLimit') }}></div>}
            {totalContains > 3 && <div className="mar-b25 text-red" dangerouslySetInnerHTML={{ __html: i18n.t('subscribers:segments.containWarningLimit') }}></div>}

            {this.props.temporary && <div>
                <UILIB.Paper className="paper-purple" style={{ padding: "15px" }}>
                    <div style={{ fontWeight: "bold" }}>{i18n.t('subscribers:segments.temporaryHeader')}</div>
                    {i18n.t('subscribers:segments.temporarySubHeader')}
                </UILIB.Paper>
            </div>}

            <UILIB.Paper className="mar-b25 paper-inner">

                <h4 className="mar-b25 cyt-add-excl-your-segment">{i18n.t('subscribers:segments.edit.yourSegment')}</h4>
                <UILIB.CheckBox outerClassName="mar-b20" disabled={this.props.readonly} checked={!this.state.ignoreOrg} onChange={this.toggleIgnoreOrg.bind(this)}>Search parent Organisations <UILIB.Icons icon="questionCircle" data-tip="when enabled includes contacts whose parent organisation match these rules" data-for="ignoreOrg" /></UILIB.CheckBox>
                {showDomainNameSuggestions && <UILIB.WarningHolder className="mar-b20">
                    You have email address wildcard searching which could be slow, try using the <strong>Email Domain Name</strong> field if you are looking for specific domains
                </UILIB.WarningHolder>}

                {this.state.segmentData.map((thisSeg, segIndex) => {
                    var andOr = thisSeg.queryObj[0] ? thisSeg.queryObj[0].andOr : 'and';

                    const contactOnlyFields = andOr === 'and' && thisSeg.queryObj.filter(cf => parseInt(cf.customFieldId)).some(cf => {
                        const field = this.state.fields.find(f => f.value == cf.customFieldId)
                        return field && field.entityType === 1
                    })

                    const hasMixedEntityTypes = contactOnlyFields && thisSeg.queryObj.filter(cf => parseInt(cf.customFieldId)).some(cf => {
                        const field = this.state.fields.find(f => f.value == cf.customFieldId)
                        return field && field.entityType === 2
                    })

                    return <UILIB.Paper key={"segGroup_" + segIndex} className={this.state.segmentData.length == 1 ? "paper-inner mar-b15" : "paper-inner mar-b15"}>
                        <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignItems: "center" }}>
                            <div className="quickFlex" style={{ alignItems: "center" }}>
                                <div className="mar-r10 text-heavy">{i18n.t('subscribers:segments.edit.forContactsThatMatch')}</div>
                                <UILIB.Select outerClassName="mar-r10" name="andOr" onChange={(event) => { this.changeAndOr(event, segIndex) }} value={andOr} data={[{ label: i18n.t('all'), value: "and" }, { label: i18n.t('any'), value: "or" }]} disabled={this.props.readonly} />
                                <div className="text-heavy">{i18n.t('subscribers:segments.edit.ofTheFollowingRules')}</div>
                            </div>
                            {segIndex > 0 && !this.props.readonly && <UILIB.Button text={i18n.t('subscribers:segments.edit.deleteGroup')} onClick={() => { this.deleteGroup(segIndex) }} />}
                        </div>
                        {thisSeg.queryObj.map((theSeg, index) => {
                            return <SegmentRuleLine allCampaignData={this.state.allCampaignData} contactOnlyFields={contactOnlyFields} key={index} isCapsule={!!this.state.segmentData.CapsuleId} totalContains={totalContains} totalSubscribers={totalSubscribers} readonly={this.props.readonly} addSegment={this.addSegment} linkTags={this.state.linkTags} lastUpdated={theSeg.lastUpdated} fields={this.state.fields} segmentData={theSeg} dataId={index} updateSegment={this.updateSegment} deleteSegment={this.deleteSegment} groupData={this.state.groupData} campaignData={this.state.campaignData} errors={this.state.errors.groups[segIndex]} totalRules={thisSeg.queryObj.length} copyRule={this.copyRule} andOr={theSeg.andOr} segmentGroupId={segIndex} operators={this.state.operators} subscriberTags={this.state.subscriberTags} loadCampaigns={this.filterCampaigns} loadingCampaignData={this.state.loadingCampaignData} countryCodes={this.state.countryCodes} />
                        })}
                        {hasMixedEntityTypes && <div className='text-red text-heavy text-xsml'>WARNING! This set of rules has a mixture of Contact only fields and Organisation only fields, you might get incorrect results</div>}
                    </UILIB.Paper>
                })}
                {!this.props.readonly && <div className="end-xs quickFlex mar-t15" >
                    <div onClick={this.addSegGroup} className="quickFlex text-xsml" style={{ cursor: 'pointer', alignItems: 'start' }}>
                        <UILIB.Icons icon="plus" /> {i18n.t('subscribers:segments.edit.addAGroup')}
                    </div>
                </div>}
            </UILIB.Paper>

            {!missingValues && <UILIB.Row>
                {!!this.state.totalSearchResults && <Fragment>
                    <UILIB.Column xs={3} margin={0}>
                        <UILIB.Paper className="paper-inner">
                            <div className="mar-b5">Sent To Previously</div>
                            <h4>{NumberFunctions.formatNumber(this.state.aggs.sent ? this.state.aggs.sent : 0)}</h4>
                            <div className="text-xsml">{(((100 / this.state.totalSearchResults) * (this.state.aggs.sent || 0) || 0)).toFixed(2)}%</div>
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={3} margin={0}>
                        <UILIB.Paper className="paper-inner">
                            <div className="mar-b5">Opened Previously</div>
                            <h4>{NumberFunctions.formatNumber(this.state.aggs.opened ? this.state.aggs.opened : 0)}</h4>
                            <div className="text-xsml">{(((100 / this.state.totalSearchResults) * (this.state.aggs.opened || 0) || 0)).toFixed(2)}%</div>

                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={3} margin={0}>
                        <UILIB.Paper className="paper-inner">
                            <div className="mar-b5">Clicked Previously</div>
                            <h4>{NumberFunctions.formatNumber(this.state.aggs.clicked ? this.state.aggs.clicked : 0)}</h4>
                            <div className="text-xsml">{(((100 / this.state.totalSearchResults) * (this.state.aggs.clicked || 0) || 0)).toFixed(2)}%</div>

                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={3} margin={0}>
                        <UILIB.Paper className="paper-inner" style={{ height: "calc(100% - 25px)" }}>
                            <div className="mar-b10">Average Score</div>
                            <UILIB.Score hideRules={true} score={this.state.aggs.score || 0} />
                        </UILIB.Paper>
                    </UILIB.Column>
                </Fragment>}

                <UILIB.Column xs={12} sm={12} margin={0}>
                    <UILIB.Paper className="paper-inner" >
                        <UILIB.Row>
                            <UILIB.Column xs={12} md={6} className="mar-b25">
                                <h4>{NumberFunctions.formatNumber(this.state.totalSearchResults)} {i18n.t('subscribers:segments.edit.subscribersMatchYourRules')}</h4>
                            </UILIB.Column>
                            <UILIB.Column xs={12} md={6} className="end-xs mar-b25">
                                <UILIB.TextInput
                                    outerStyle={{ width: "100%", minWidth: "200px", maxWidth: "500px", margin: '0 0 0 auto' }}
                                    clickToChangeClicked={this.getResults}
                                    placeholder={i18n.t('subscribers:segments.edit.searchForEmail')}
                                    onChange={this.changeSearchText}
                                    value={this.state.searchText}
                                    iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                                />
                            </UILIB.Column>
                        </UILIB.Row>
                        <div>

                            {!this.state.totalSearchResults && <p>{i18n.t('subscribers:segments.edit.tryBroadeningYourRules')}</p>}
                            {!!this.state.totalSearchResults && <p>{i18n.t('subscribers:segments.edit.heresASample')}</p>}
                        </div>

                        {!!this.state.totalSearchResults &&
                            <UILIB.Row margin={0}>
                                <UILIB.Column xs={12}>
                                    {this.state.loadingSearchResults && <UILIB.LoadingIcons iconType="2" />}
                                    {!this.state.loadingSearchResults && <UILIB.DataTable1
                                        noResultsTxt={i18n.t('subscribers:segments.edit.noSubscribersMatch')}
                                        tableData={this.state.searchResults}
                                        loadingData={this.state.loadingSearchResults}
                                        dataUpdater={this.changeTableOptions}
                                        width="100%"
                                        pageSize="10"
                                        hasCheckBoxes="no"
                                        sortedColumn={this.state.sortColumn}
                                        sortedDirection={this.state.sortDirection}
                                    />}
                                </UILIB.Column>
                                <UILIB.Column xs={12} className="center-xs mar-t20" margin={0}>
                                    <UILIB.PagingBlock
                                        totalRows={pagingTotal}
                                        pageSize={this.state.pageSize}
                                        numberOfLinks="10"
                                        currentPage={this.state.currentPage}
                                        changePage={this.changeTableOptions}
                                        text={i18n.t('page') + ":"} />

                                </UILIB.Column>
                            </UILIB.Row>
                        }
                    </UILIB.Paper>
                </UILIB.Column>

            </UILIB.Row>
            }
            <UILIB.Row>
                <UILIB.Column xs={12} style={{ display: "flex", justifyContent: "end" }}>
                    {this.state.errors.segmentName && <UILIB.FormError className="mar-r10">{this.state.errors.segmentName}</UILIB.FormError>}
                    {!this.props.temporary && !missingValues && <UILIB.Button className="mar-r10" for="segment-automate" tooltip={cantAutomate ? 'Some date rules are invalid for Automation' : ''} iconRight={<UILIB.Icons icon="lightning" />} onClick={() => cantAutomate ? undefined : this.automate()}>Automate</UILIB.Button>}
                    <UILIB.Button disabled={this.props.readonly || missingValues} iconRight={<UILIB.Icons icon="tick" />} className="button-primary cyt-add-excl-save-segment" text={i18n.t('subscribers:segments.edit.saveSegment')} onClick={() => this.saveSegment(true)} />
                </UILIB.Column>
            </UILIB.Row>

        </div >


    };
}


import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
//import HookAction from '~/pages/cp/includes/integrations/hook'
import HookOptionsView from '~/pages/cp/includes/integrations/hookOptionsView'
class GenericHooks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ready: false,
            hooks: [],
            apps: []
        }
        this.saveWebHooks = this.saveWebHooks.bind(this)
        this.getApps = this.getApps.bind(this);
        this.processApps = this.processApps.bind(this);
    }

    componentDidMount() {
        console.log("Before", this.props.appSettings)
        this.getApps();
    }

    getApps() {
        axios.get('/accountMaster/application').then(response => {
            var apps = response.data.Applications
            this.setState({
                apps: apps
            }, this.processApps)
        })
    }

    processApps() {
        let hooks = []
        let getters = []
        let settings = this.props.appSettings;
        var ourApp = this.state.apps.filter(a => a.ApplicationId == this.props.app.id)
        ourApp.filter(a => a.Application.options && a.Application.options.hooks && a.Application.options.hooks.length && (a.Application.options.useGenericConfig || a.Application.options.hooksOnly)).forEach(app => {
            app.props = {}
            app.Application.options.hooks.filter(h => !h.automationonly).forEach(hook => {
                if (hook.getters) {
                    hook.getters.forEach(getter => {
                        getters.push(axios.get(`/application/${app.Application.shortName}/${getter.endpoint}`).then(res => {
                            app.props[getter.property] = res.data
                        }))
                    })
                }
                if (hook.dynamicBuild) {
                    getters.push(axios.get(`/application/${app.Application.shortName}/${hook.dynamicBuild}`).then(res => {
                        res.data.forEach(row => {
                            if (hook.build.find(f => f.value === row.value)) return // we already have it mapped manually so ignore
                            hook.build.push(row)
                        })
                    }))
                }
                if (!settings[hook.action]) settings[hook.action] = {}
                if (!settings[hook.action].open) settings[hook.action].open = []
                if (!settings[hook.action].click) settings[hook.action].click = []
                if (!settings[hook.action].unsubscribe) settings[hook.action].unsubscribe = []
                if (!settings[hook.action].junk) settings[hook.action].junk = []
                if (!settings[hook.action].bounce) settings[hook.action].bounce = []
                hooks.push({
                    ApplicationId: app.ApplicationId,
                    appName: app.Application.appName,
                    shortName: app.Application.shortName,
                    logo: app.Application.appLogoUri,
                    action: hook.action,
                    build: hook.build,
                    name: hook.name
                })
            })
        })
        Promise.all(getters).then(() => {
            this.setState({
                hooks,
                ready: true
            })
        })
    }

    processAppsOld() {
        let app = this.state.apps.find(app => app.id == this.props.app.id)
        let settings = this.props.appSettings;
        let getters = []
        if (app.options && app.options.hooks && app.options.hooks.length) {
            app.options.hooks.forEach(hook => {
                if (!settings[hook.action]) settings[hook.action] = {}
                if (!settings[hook.action].open) settings[hook.action].open = []
                if (!settings[hook.action].click) settings[hook.action].click = []
                if (!settings[hook.action].unsubscribe) settings[hook.action].unsubscribe = []
                if (!settings[hook.action].junk) settings[hook.action].junk = []
                if (!settings[hook.action].bounce) settings[hook.action].bounce = []
                hook.appName = app.shortName
                hook.ApplicationId = app.id
                if (hook.getters && hook.getters.length) {
                    hook.getters.forEach(getter => {
                        getters.push(axios.get(`/application/${this.props.app.shortName}/${getter.endpoint}`).then(res => {
                            this.setState({
                                [getter.property]: res.data
                            })
                        }))
                    })
                }
            })

            this.setState({
                hooks: app.options.hooks,
                ready: true
            })
        }
        Promise.all(getters).then(() => {
            this.getApps();
        })
    }

    saveWebHooks(newHooks) {
        var appSettings = this.props.appSettings;
        this.props.app.options.hooks.forEach(hook => {
            var hookAction = hook.action;
            appSettings[hookAction] = {};
        })

        newHooks.forEach(newHook => {
            var hookAction = newHook.options.action;
            if (!appSettings[hookAction][newHook.hookType]) appSettings[hookAction][newHook.hookType] = [];
            appSettings[hookAction][newHook.hookType].push(newHook.options)
        })

        this.props.onChange();
        this.setState({})
    }

    render() {


        if (!this.state.ready) return <UILIB.LoadingIcons iconType="2" />

        var savedWebhooks = [];
        this.props.app.options.hooks.forEach(hook => {
            var hookAction = hook.action;
            if (this.props.appSettings[hookAction]) {
                Object.keys(this.props.appSettings[hookAction]).forEach(key => {
                    if (Array.isArray(this.props.appSettings[hookAction][key])) {
                        this.props.appSettings[hookAction][key].forEach(action => {
                            savedWebhooks.push({
                                "hookType": key,
                                "applicationId": this.props.app.id,
                                "options": {
                                    "action": hookAction,
                                    ...action
                                }
                            })
                        })
                    }
                })
            }
        })

        return (
            <>
                <HookOptionsView hooks={this.state.hooks} apps={this.state.apps} saveWebhooks={this.saveWebHooks} webhooks={savedWebhooks} doingAction={this.props.editing} />
                {!!this.props.onClose && <UILIB.Button text="Save & Exit" className="mar-b20" onClick={this.props.onClose} />}
            </>
        );
    }
}

export default GenericHooks;
import axios from '~/data/http/axios';
import SiteVars from '~/Classes/siteVars';
import { UIEvent, PhotoEditorSDKUI } from "photoeditorsdk";

export function launchAviary(fileObject, folderId, onSave, overRideFilePath, defaultTool = 'filter', app) {
    var fName = "";
    var editor = document.getElementById('popImageEditor');
    var editorOverlay = document.getElementById('popImageEditorOverlay');

    if (!editor) {
        editor = document.createElement('div')
        editor.id = "popImageEditor"
        document.body.appendChild(editor);
    }
    if (!editorOverlay) {
        editorOverlay = document.createElement('div')
        editorOverlay.id = "popImageEditorOverlay"
        document.body.appendChild(editorOverlay);
    }
    var promise = Promise.resolve();

    if (overRideFilePath) {
        promise = axios.post('/fileManager/file/byFilePath', { filePath: overRideFilePath })
    }
    promise.then((fileObj) => {
        var theLanguage = 'en';
        var user = localStorage.getItem('reduxPersist:user') ? JSON.parse(localStorage.getItem('reduxPersist:user')) : {}
        if (user && user.userData && user.userData.language == "de") theLanguage = 'de';

        if (fileObj) {
            fileObject = fileObj.data.file;
            folderId = fileObject.FileManagerFolderId;
            fileObject.fileId = fileObject.id;

        }
        const isDev = window.location.host.indexOf('8080') === window.location.host.length - 4
        fName = fileObject.fileName;
        var fileUrl = SiteVars.repoEndPoint + fileObject.filePath + fileObject.fileName;
        var random = Math.round(Math.random() * 9999999999)
        fileUrl = fileUrl + "?rnd=" + random;
        if (fileUrl.indexOf('http://localhost') === -1) {
            fileUrl = fileUrl.replace(/http\:/g, 'https:')
        }
        if (!isDev) fileUrl = fileUrl.replace(/http\:/g, 'https:')

        console.log(fileUrl)

        let categories = [
            {
                identifier: 'imgly_transforms_common',
                items: [
                    { identifier: 'imgly_transform_common_custom' },
                    { identifier: 'imgly_transform_common_square' },
                    { identifier: 'imgly_transform_common_4' },
                    { identifier: 'imgly_transform_common_16' },
                    { identifier: 'imgly_transform_common_3' },
                    { identifier: 'imgly_transform_common_9' },
                ],
            },
            {
                identifier: 'imgly_transforms_facebook',
                items: [
                    { identifier: 'imgly_transform_facebook_profile' },
                    { identifier: 'imgly_transform_facebook_title' },
                    { identifier: 'imgly_transform_facebook_post' },
                ],
            },
            {
                identifier: 'imgly_transforms_instagram',
                items: [
                    { identifier: 'imgly_transform_instagram_square' },
                    { identifier: 'imgly_transform_instagram_story' },
                    { identifier: 'imgly_transform_instagram_landscape' },
                    { identifier: 'imgly_transform_instagram_portrait' },
                ],
            },
            {
                identifier: 'imgly_transforms_twitter',
                items: [
                    { identifier: 'imgly_transform_twitter_post' },
                    { identifier: 'imgly_transform_twitter_profile' },
                    { identifier: 'imgly_transform_twitter_title' },
                ],
            },
            {
                identifier: 'custom_transforms_linkedin',
                name: 'LinkedIn',
                items: [{
                    identifier: "custom_transform_linkedin_post",
                    name: 'LinkedIn Post',
                    thumbnailURI: 'https://cdn1.ourmailsender.com/siteContent/appLogos/76/post.svg',
                    dimensions: {
                        x: 1200,
                        y: 627,
                    },
                    forceDimensions: false,
                    lockDimensions: true,
                }]
            }
        ]

        if (app === 'FACEBOOK') {
            categories = categories.filter(c => c.identifier === 'imgly_transforms_facebook')
            categories[0].items = categories[0].items.filter(i => i.identifier === 'imgly_transform_facebook_post')
        }
        if (app === 'INSTAGRAM') {
            categories = categories.filter(c => c.identifier === 'imgly_transforms_instagram')
        }
        if (app === 'LINKEDIN') {
            categories = categories.filter(c => c.identifier === 'custom_transforms_linkedin')
        }
        if (app === 'TWITTER') {
            categories = categories.filter(c => c.identifier === 'imgly_transforms_twitter')
            categories[0].items = categories[0].items.filter(i => i.identifier === 'imgly_transform_twitter_post')
        }

        return PhotoEditorSDKUI.init({
            language: theLanguage,
            container: "#popImageEditor",
            image: fileUrl, // Image url or Image path relative to assets folder
            license: '{"api_token":"hx8FstzTK-4cieX2J08nCg","app_identifiers":["127.0.0.1","localhost","159.65.94.120","138.68.145.175","dev.web.mpzmail.com","web.mpzmail.com","mp.adrom.net","news.estatetrack.co.uk","new.mailvio.com","app.transpond.io"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":false,"expires_at":null,"features":["camera","library","export","adjustment","filter","focus","overlay","text","transform"],"issued_at":1699373326,"minimum_sdk_version":"1.0","owner":"MPZMail Limited","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"nMKpT3vcBxZhT1wO27a3V+QLIGEi3SZMv4d7JoNpAUM70BMclJsDXfBWm+usqB8x9Vs1pKeSvVvY6t4CuvojHS3Qj23hb5s/mqgIEV2bjJfdmIusLqgsHKrhkwFlTDk3MuXY1Ja6csQmnU1rAZ32HiIUkT+XiisdABhTriWvGzi5SJ1z5rgloPWxK/zPuj698COBBTvKUeJhrDUlBGGqYmCVjbbgufWhYM8LaETNLU/XGEObyHxecIU25EF6hsRLkWWd8yuWKYeDqFuJwk2H+LQzXTfRwoB4YXtPfe2nGYjmWLJphC3ALoOF4n8I7LUDJqT7wwheaqCbxEss8FU056veqU4rAl5b/XXmMonWGrz+UjGjchkcvY15bOzMByXrTWsxHcOT3DT+GNAnwt3CLlJvgqH3/AkRNgt9csBQSo8R5qZl0yJYfQp9yp9mdvRnv/Mxbk6ytvvrqDjFq4Zwgmw0uMt1ztQv8ddiIWZwW5QZV0101mJISd0lyMrPntolta0jFIs6VDU01/Kj4f8PeZ3joLuPKh+MIMKSgFEtpOGKYHUaF7y43lHj5N/e5wyLxP4F71sJNLTm7r8s0vFBSUbIAlnQlVCvplX72uO6HwI/9MfM2t0JLs16cCwHT0vU4T4J2IvUlHvjKhE1EET4k0FyYimKn251xBP1ur9WUG4="}',
            theme: "light",
            defaultTool: defaultTool,
            assetBaseUrl: "/public/assets",
            engine: {
                backgroundColor: [0, 0, 0, 0]
            },
            export: {
                image:
                {
                    format: 'image/jpeg', // Possible values: `image/png`, `image/jpeg`
                    exportType: 'image', // Possible values: `image` or `data-url`
                    quality: defaultTool === 'transform' ? 1 : 0.9,
                    enableDownload: false
                }
            },
            custom: {
                themes: {
                    light: {
                        typography: {
                            provider: 'google',
                            fontFamily: 'Roboto',
                            // Most users load font while initializing their app
                            // In that case, you can choose to skip loading it again
                            skipLoading: false
                        }
                    }
                },

                languages: {
                    en: {
                        mainCanvasActions: {
                            buttonExport: 'Save'
                        }
                    }
                }
            },
            transform: {
                categories,
                flattenCategories: false,
            },
        })
    }).then(editor => {

        editor.on(UIEvent.EXPORT, (imageSrc) => {

            document.getElementById("popImageEditorOverlay").style.display = "block";
            var fNameD = fName.split(".");
            var fileNameExt = fNameD[fNameD.length - 1]
            fName = fName.replace("." + fileNameExt, "");
            fName = fName + "_c_" + Math.round(Math.random() * 999999) + "." + fNameD[fNameD.length - 1]
            var saveData = {
                rawData: imageSrc.src || imageSrc,
                fileName: fName
            }
            axios.post('/fileManager/' + folderId + '/file/rawData', saveData).then(res => {
                onSave(SiteVars.repoEndPoint + res.data.File.filePath + res.data.File.fileName);
                document.getElementById("popImageEditor").remove();
                document.getElementById("popImageEditorOverlay").remove();
            }).catch(err => {
                console.log(err);
            })

        });

        editor.on(UIEvent.CLOSE, (imageSrc) => {
            document.getElementById("popImageEditor").remove();
            document.getElementById("popImageEditorOverlay").remove();
        });

    });
}
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import helpers from '~/Classes/helpers'
import Phone from './phone'

export default function PreviewSms({ campaignId, templateId, turnOffTracking }) {
    const [template, setTemplate] = useState(null)
    const [campaign, setCampaign] = useState(null)

    useEffect(() => {
        if (campaignId) {
            axios.get('/campaign/' + campaignId).then(res => setCampaign(res.data.Campaign))
        }
    }, [campaignId])

    useEffect(() => {
        axios.get('/template/' + templateId).then(res => setTemplate(res.data.template))
    }, [templateId])

    useEffect(() => {
        if (campaign && campaign.TemplateId) {
            axios.get('/template/' + campaign.TemplateId).then(res => setTemplate(res.data.template))
        }
    }, [campaign])

    if (!template) return <UILIB.LoadingIcons iconType="2" />

    let tempHtml = template.templateHTML
    const links = helpers.findLinks(template.templateHTML)
    if (!turnOffTracking) {
        links.forEach(l => {
            tempHtml = tempHtml.replace(l, 'mpsms.io/c/######')
        })
    }
    tempHtml = tempHtml.replace(/\{\{__OPTOUT\}\}/, 'mpsms.io/####')

    const timestamp = campaign && campaign.startDate ? moment(campaign.startDate).format('DD MMM, HH:mm') : null
    return (
        <UILIB.Row className="center-xs">
            <Phone content={tempHtml} from={campaign ? campaign.subject : ''} timestamp={timestamp} />
        </UILIB.Row>
    )
}
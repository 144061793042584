import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import { connect } from 'react-redux';
import PermissionChecker from '~/Classes/permissions';
import i18n from '~/i18n';
import ContactsMainNav from '../../includes/contacts/contactsMainNav';
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, accountMaster: store.accountMaster, siteMaster: store.siteMaster }
})

export default class GroupTemplateFP extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            groupName: "",
            groupID: 0,
            tabIndex: 0,
            isBlacklist: true
        };
        this.tabChange = this.tabChange.bind(this);
        this.setInitialTab = this.setInitialTab.bind(this);
    }

    componentDidMount() {
        var groupID = this.props.groupID;
        var isBlacklist = true;
        if (this.props.accountMaster.accountMaster.blackListGroupId != groupID) {
            isBlacklist = false;
        }
        this.setState({
            groupID: groupID,
            isBlacklist: isBlacklist
        }, this.lookupGroup);
        this.setInitialTab();
    }

    componentDidUpdate(prevProps) {
        if (prevProps != this.props) {
            this.setInitialTab();
        }
        if (this.props.groupID !== this.state.groupID) {
            var groupID = this.props.groupID;
            var isBlacklist = true;
            if (this.props.accountMaster.accountMaster.blackListGroupId != groupID) {
                isBlacklist = false;
            }
            this.setState({
                groupID: groupID,
                isBlacklist: isBlacklist
            }, this.lookupGroup);
        }
    }

    setInitialTab() {
        var foundTab = 0;
        var theCurrPath = this.props.history.location.pathname.split("/");

        if (theCurrPath.length > 4) {
            if (theCurrPath[4] == 'add') {
                foundTab = 1;
                this.setState({ tabIndex: 1 });
            }
            if (theCurrPath[4] == 'analytics') {
                foundTab = 1;
                this.setState({ tabIndex: 2 });
            }
            if (theCurrPath[4] == 'settings') {
                foundTab = 1;
                this.setState({ tabIndex: 3 });
            }
            if (theCurrPath[4] == 'forms') {
                foundTab = 1;
                this.setState({ tabIndex: 4 });
            }
            if (theCurrPath[4] == 'imports') {
                foundTab = 1;
                this.setState({ tabIndex: 5 });
            }
            if (theCurrPath[4] == 'users') {
                foundTab = 1;
                this.setState({ tabIndex: 6 });
            }
            if (theCurrPath[4] == 'clean') {
                foundTab = 1;
                this.setState({ tabIndex: 7 });
            }

            if (theCurrPath[4] == 'landingpages') {
                foundTab = 1;
                this.setState({ tabIndex: 8 });
            }

        }

        if (foundTab === 0) {
            this.setState({ tabIndex: 0 });
        }
    }

    tabChange(event) {
        var self = this;
        var index = event;
        if (event.currentTarget) {
            if (event.currentTarget.id) index = event.currentTarget.id !== undefined;
            if (event.currentTarget.value) index = event.currentTarget.value !== undefined;
        }
        index = String(index);
        self.setState({ tabIndex: index });
        switch (index) {
            case "0":
                self.props.history.push('/cp/groups/' + self.state.groupID);
                break;
            case "1":
                self.props.history.push('/cp/groups/' + self.state.groupID + '/add');
                break;
            case "2":
                self.props.history.push('/cp/groups/' + self.state.groupID + '/analytics');
                break;
            case "3":
                self.props.history.push('/cp/groups/' + self.state.groupID + '/settings');
                break;
            case "4":
                self.props.history.push('/cp/groups/' + self.state.groupID + '/forms');
                break;
            case "5":
                self.props.history.push('/cp/groups/' + self.state.groupID + '/imports');
                break;
            case "6":
                self.props.history.push('/cp/groups/' + self.state.groupID + '/users');
                break;
            case "7":
                self.props.history.push('/cp/groups/' + self.state.groupID + '/clean');
                break;
            case "8":
                self.props.history.push('/cp/groups/' + self.state.groupID + '/landingpages');
                break;
        }
    }

    lookupGroup() {
        var self = this;
        axios.get('/group/' + this.state.groupID)
            .then((res) => {
                self.setState({ groupName: res.data.Group.groupName });
            })
            .catch(function (error) {
                self.props.history.push('/cp/subscribers');
            });

    }

    render() {


        var thisMenuOptions = [
            { label: i18n.t('subscribers:nav.overview'), value: 0, permission: '', method: "" },
            { label: i18n.t('subscribers:nav.addSubscribers'), value: 1, permission: 'subscribers', method: "write", className: 'highlighted' }
        ]

        if (!this.state.isBlacklist) {
            thisMenuOptions.push({ label: i18n.t('subscribers:nav.analytics'), value: 2, permission: 'subscribers', method: "read" });
            if (this.props.siteMaster.siteId != 2 && !this.props.siteMaster.listCleanDisabled) {
                thisMenuOptions.push({
                    label: <div>{i18n.t('subscribers:nav.cleanList')} <UILIB.SquareChip className="square-chip-green mar-l5">New</UILIB.SquareChip></div>,
                    mobilelabel: i18n.t('subscribers:nav.cleanList'),
                    value: 7, permission: 'subscribers', method: "write"
                });
            }
            thisMenuOptions.push({ label: i18n.t('subscribers:nav.forms'), value: 4, permission: 'forms', method: "read" })
            thisMenuOptions.push({ label: i18n.t('company:nav.landingpages'), value: 8, permission: 'subscribers', method: "write" })
            thisMenuOptions.push({ label: i18n.t('subscribers:nav.users'), value: 6, permission: 'subscribers', method: "write" })
            thisMenuOptions.push({ label: i18n.t('subscribers:nav.settings'), value: 3, permission: 'subscribers', method: "write" })
        }

        thisMenuOptions.push({ label: i18n.t('subscribers:nav.imports'), value: 5, permission: 'subscribers', method: "read" });


        var finalMenuOptions = thisMenuOptions.filter((thisOpt, index) => {
            if (thisOpt.beta) {
                if (!this.props.accountMaster.accountMaster.beta || !this.props.accountMaster.accountMaster.beta[thisOpt.beta]) return false
            }
            if (!thisOpt.permission || PermissionChecker(thisOpt.permission, this.props.permissionStore.permissions, thisOpt.method)) {
                return true
            }
        });


        let hideNav = false;
        //hide nav if on import setup
        const regex = /\/cp\/groups\/?.*\/add\/import\/setup/gmi;
        var hasMatch = regex.exec(this.props.history.location.pathname);
        if (hasMatch) hideNav = true;

        return <UILIB.Paper>

            {this.state.isBlacklist && <div>
                <div className="page-header mar-b25">{i18n.t('nav:subscribers')}</div>
                <ContactsMainNav history={this.props.history} />
            </div>}


            {(!this.state.isBlacklist && !hideNav) && <UILIB.Row>
                <UILIB.Column xs={12} sm={12} className="quickFlex mar-b25" style={{ justifyContent: "space-between" }}>
                    <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={() => { this.props.history.push('/cp/subscribers/groups') }}>{i18n.t('subscribers:nav.goBackToGroups')}</UILIB.Hint>
                    <span className="text-xsml text-grey">Id: {this.state.groupID}</span>
                </UILIB.Column>
                <UILIB.Column xs={12} className="mar-b25">
                    <h4 className="page-header">{this.state.groupName}</h4>
                </UILIB.Column>

                <UILIB.Column xs={12} hide={["md", "lg"]} className="mar-b25">
                    <UILIB.Select data={finalMenuOptions} onChange={this.tabChange} value={this.state.tabIndex} />
                </UILIB.Column>

                <UILIB.Column xs={12} hide={["xs", "sm"]} className="mar-b25 sub-nav-holder">

                    {finalMenuOptions.map((thisOpt, index) => {
                        return <UILIB.ButtonSimple
                            key={"tab_" + index}
                            className="button-simple-fullsize sub-nav-button"
                            selected={this.state.tabIndex == thisOpt.value}
                            onClick={() => { this.tabChange(thisOpt.value) }}>
                            {thisOpt.label}
                        </UILIB.ButtonSimple>
                    })}

                </UILIB.Column>
            </UILIB.Row>
            }

            {this.props.children}

        </UILIB.Paper >

    }
}
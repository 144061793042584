import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import UILIB from '../../Lib'
import * as siteMaster from '../../../data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n';

export default function DrawerConfirm({ confirm = () => { }, inputText = "", header = "", subheader = "", input = false, value = '', confirmText = "", showCancel = false, cancelText = "", required = false, cancel = () => { } }) {
    const [textInput, setTextInput] = useState(value || '')
    const [error, setError] = useState('')
    const [closing, setClosing] = useState(false)
    const dispatch = useDispatch()

    const closeDrawer = () => {
        dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    const onConfirm = async () => {
        if (required && (textInput && !textInput.trim())) {
            return setError('Required')
        }
        setClosing(true)
        await confirm(textInput ? textInput : "");
        setClosing(false)
        closeDrawer();
    }

    const onCancel = () => {
        closeDrawer();
        cancel();
    }

    const changeText = (event) => {
        setTextInput(event.target.value)
    }

    return (
        <div>
            {!!header && <h4 className="mar-b25">{header}</h4>}
            {!!subheader && <div className="mar-b25">{subheader}</div>}


            {!!input &&
                <UILIB.TextInput placeholder={inputText} error={error} value={textInput} onChange={changeText} className="mar-b25" />
            }
            <div className="row">
                <div className="col-xs-6">
                    <UILIB.Button text={confirmText ? confirmText : i18n.t('yes')} disabled={closing} className={"button-primary " + (showCancel ? 'button-fullwidth' : '')} onClick={onConfirm} iconLeft={<UILIB.Icons icon="tick" />} />
                </div>
                <div className="col-xs-6">
                    {!!showCancel && <UILIB.Button className="button-fullwidth" disabled={closing} text={cancelText ? cancelText : i18n.t('no')} onClick={onCancel} />}
                </div>
            </div>
        </div >
    )
};
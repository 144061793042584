import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})
export default class IncGoDeleteTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deleting: false
        };

        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
    }

    deleteTemplate() {
        this.setState({ deleting: true })
        if (this.props.goDelete && typeof this.props.goDelete === 'function') {
            this.props.goDelete()
        } else {
            axios.delete('/template/' + this.props.templateId).then(() => {
                this.closeDrawer();
                this.props.tableDataGetter();
            });
        }
    }

    render() {

        return <div>

            <h4 className='mar-b25'>{i18n.t('templates:index.deleteHeaderThis')}</h4>
            <p className="mar-b25" >{i18n.t('templates:index.deleteSubHeaderThis')}</p>


            <UILIB.Button text={i18n.t('templates:index.deleteButton')} className="button-primary mar-b25" disabled={this.state.deleting} onClick={this.deleteTemplate} />
            {this.state.deleting && <UILIB.LoadingIcons iconType="2" />}
        </div >
    }
};


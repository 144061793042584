import React, { useState } from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'

export default function CopySegment({ segment, close }) {
    const [name, setName] = useState(segment.name + ' Copy')

    const save = async () => {
        const response = await axios.post('/segment/clone/' + segment.id, { name })
        close(response.data.Segment)
    }

    return (<div>

        <h4 className="mar-b25">{i18n.t('subscribers:segments.copyHeader', { name: segment.name })}</h4>


        <UILIB.TextInput value={name} onChange={e => setName(e.target.value)} label="New Segment Name" className="mar-b25" />

        <div className="row">
            <div className="col-xs-6">
                <UILIB.Button text={i18n.t('save')} className="button-primary button-fullwidth" onClick={save} iconLeft={<UILIB.Icons icon="tick" />} />
            </div>
            <div className="col-xs-6">
                <UILIB.Button text={i18n.t('cancel')} className="button-fullwidth" onClick={() => close()} iconLeft={<UILIB.Icons icon="cross" />} />
            </div>

        </div>
    </div>)
}
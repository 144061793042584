import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
class renameFolder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: this.props.folder.folderName
        }

        this.updateField = this.updateField.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.folder.folderID !== this.props.folder.folderID) {
            this.setState({
                name: this.props.folder.folderName
            })
        }
    }

    updateField(ev) {
        this.setState({
            [ev.currentTarget.name]: ev.currentTarget.value
        })
    }

    render() {
        var headerTxt = i18n.t('templates:fileManager.popups.renameFolderName');
        headerTxt = headerTxt.replace(/\[FOLDERNAME\]/g, this.props.folder.folderName)
        return (
            <div>
                <h4 className="mar-b25">{headerTxt}</h4>
                <UILIB.TextInput
                    name="name"
                    value={this.state.name}
                    onChange={this.updateField}
                    label={i18n.t('templates:fileManager.popups.folderName')}
                    outerClassName="mar-b25"
                />
                <UILIB.Button
                    text={i18n.t('templates:fileManager.popups.save')}
                    className="button-primary"
                    onClick={() => this.props.save(this.state.name)}
                />

            </div>
        );
    }
}

export default renameFolder;
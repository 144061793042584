import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib';
import AutoMergeTags from '~/pages/cp/includes/mergeTags/campaignMergeTags';
import AutomationFunctions from '../../automationFunctions'
import i18n from '~/i18n';

class GenericIntegrationSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hook: undefined,
            errors: {},
            drawerContent: undefined,
            hasWorkflowEmails: false,
            workflowEmails: [],
            mergeTags: []
        }

        this.update = this.update.bind(this)
        this.updateBy = this.updateBy.bind(this)
        this.updateDate = this.updateDate.bind(this)
        this.updateArray = this.updateArray.bind(this)
        this.saveStep = this.saveStep.bind(this)
        this.openMergeTags = this.openMergeTags.bind(this)
        this.closeMergeTags = this.closeMergeTags.bind(this)
        this.insertMergeTag = this.insertMergeTag.bind(this)
        this.addRow = this.addRow.bind(this)
        this.removeRow = this.removeRow.bind(this)
    }

    componentDidMount() {
        let hooks = this.props.accApp.Application.options.hooks
        let hook = hooks.find(h => h.action === this.props.step.action)

        var workflowEmails = AutomationFunctions.getWorkflowEmails(this.props.step.guid, this.props.steps);
        var hasWorkflowEmails = false;
        var initialStepTriggerType = this.props.steps[0].triggerType.value;
        if (workflowEmails.length || (initialStepTriggerType == "5" || initialStepTriggerType == "8" || initialStepTriggerType == "9" || initialStepTriggerType == "10" || initialStepTriggerType == "11")) hasWorkflowEmails = true

        if (hook.build.some(b => b.multi)) {
            hook.build.filter(b => b.multi).forEach(build => {
                if (!Array.isArray(this.props.step.stepOptions[build.name]) || !this.props.step.stepOptions[build.name].length) {
                    this.props.step.stepOptions[build.name] = []
                }
            })
        }

        this.setState({
            hook,
            errors: this.props.step.stepOptions.errors || {},
            workflowEmails,
            hasWorkflowEmails
        })
    }

    update(ev, option, row) {
        let step = this.props.step
        var value = ev.currentTarget.value;
        if (ev.target.type == "checkbox") {
            if (ev.target.checked) {
                value = true
            }
            else {
                value = false;
            }
        }
        if (option) {
            step.stepOptions[option][row][ev.currentTarget.name] = value
        } else {
            if (!step.stepOptions[ev.currentTarget.name]) step.stepOptions[ev.currentTarget.name] = { value: undefined }
            step.stepOptions[ev.currentTarget.name].value = value
            if ((value === '__inc' || value === '__dec') && !step.stepOptions[ev.currentTarget.name].by) step.stepOptions[ev.currentTarget.name].by = 1
            //see if this option has children
            if (this.state.hook && this.state.hook.build) {
                this.state.hook.build.filter(b => !b.multi).forEach(hook => {
                    if (hook.type.indexOf(`__lookup=${ev.currentTarget.name}.`) > -1) {
                        if (step.stepOptions[hook.value] && step.stepOptions[hook.value].value) {
                            //reset the value of any children cos the parent has changed
                            step.stepOptions[hook.value].value = "";
                        }
                    }
                })
            }
        }
        this.setState({})
    }

    updateBy(ev) {
        let step = this.props.step
        var value = ev.currentTarget.value;
        if (!step.stepOptions[ev.currentTarget.name]) step.stepOptions[ev.currentTarget.name] = { value: undefined }
        step.stepOptions[ev.currentTarget.name].by = parseInt(value)
        this.setState({})
    }

    updateDate(field, value, option, row) {
        let step = this.props.step
        if (option) {
            step.stepOptions[option][row][field] = value
        } else {
            step.stepOptions[field].value = value
        }
        this.setState({})
    }

    updateArray(field, value) {
        let step = this.props.step
        if (!step.stepOptions[field]) {
            step.stepOptions[field] = { value: '' }
        }
        let currentValue = step.stepOptions[field].value
        if (!Array.isArray(currentValue)) currentValue = []
        let index = currentValue.indexOf(value)
        if (index === -1) currentValue.push(value)
        else currentValue.splice(index, 1)
        step.stepOptions[field].value = currentValue
        this.setState({})
    }

    saveStep() {
        if (this.props.isThemeDesigner) return this.props.saveStep()
        let errors = {}
        this.state.hook.build.forEach(item => {
            if ((!this.props.step.stepOptions[item.value] || !this.props.step.stepOptions[item.value].value) && item.required) {
                errors[item.value] = 'Required'
            }
        })
        this.props.step.stepOptions.errors = errors
        if (Object.keys(errors).length) {
            this.setState({
                errors
            })
        } else {
            this.props.step.complete = true;
            this.props.step.wizardComplete = true;
            this.props.saveStep()
        }
    }

    openMergeTags(fieldName, option, row) {
        var drawerContent = <AutoMergeTags hasWorkflowEmails={this.state.hasWorkflowEmails} includeSubscriber={true} includeSubCustomFields={true} close={this.closeMergeTags} insertMergeTag={(value) => this.insertMergeTag(value, fieldName, option, row)} fieldName={fieldName} />
        this.setState({ drawerContent })
    }

    closeMergeTags() {
        this.setState({ drawerContent: undefined })
    }

    insertMergeTag(value, fieldName, option, row) {
        if (option) {
            if (this.props.step.stepOptions[option][row][fieldName]) {
                this.props.step.stepOptions[option][row][fieldName] += " " + value;
            } else {
                this.props.step.stepOptions[option][row][fieldName] = value;
            }
        } else {
            if (this.props.step.stepOptions[fieldName].value) {
                this.props.step.stepOptions[fieldName].value += " " + value;
            } else {
                this.props.step.stepOptions[fieldName].value = value;
            }
        }
        this.setState({ drawerContent: undefined })
    }

    addRow(name) {
        if (!Array.isArray(this.props.step.stepOptions[name])) {
            this.props.step.stepOptions[name] = []
        }
        this.props.step.stepOptions[name].push({})
        this.setState({})
    }

    removeRow(name, index) {
        this.props.step.stepOptions[name].splice(index, 1)
        this.setState({})
    }

    render() {
        const { readOnly } = this.props;

        if (this.state.drawerContent) return this.state.drawerContent;

        let hook = this.state.hook;
        let stepOptions = this.props.step.stepOptions

        if (!hook) return <div></div>

        const inputProps = {
            disabled: readOnly
        };

        return <div data-test="configure-generic-integration-automation">
            <div className="automationStepContentHolder" style={{ paddingRight: "12px" }}>
                <h4 className="mar-b25">{this.props.accApp.Application.appName}</h4>
                <div className="mar-b25">{hook.name}</div>
                <div className="automationStepSaveButtonHolder">
                    {readOnly ? (
                        <>
                            <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                            <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                        </>
                    ) : (
                        <UILIB.Button className="button-primary" text="Save" onClick={this.saveStep} />
                    )}
                </div>

                <div className="row">
                    {!this.props.isThemeDesigner && hook.build.filter(b => !b.multi).map((item, index) => {
                        var options = Array.isArray(item.options) ? item.options.slice() : item.options
                        if (typeof options === 'string') {
                            options = Array.isArray(this.props.accApp[options]) ? this.props.accApp[options].slice() : this.props.accApp[options]
                        }

                        if (Array.isArray(options) && options.every(o => o.hasOwnProperty('id'))) options = options.map(o => {
                            o.label = o.label;
                            o.value = o.id;
                            return o;
                        })

                        var type = item.type
                        if (type.indexOf('__lookup=') === 0) {
                            // look it up based on the a value from another build option
                            // example __lookup=field.type will use another build with the value of field and use the type from the selected option
                            let lookup = item.type.split('=')[1]
                            let prop = ''
                            if (lookup.indexOf('.') > -1) {
                                let split = lookup.split('.')
                                lookup = split[0]
                                prop = split[1]
                            }
                            let found = hook.build.find(b => b.value === lookup)
                            if (found) {
                                if (!Array.isArray(found.options) && this.props.accApp[found.options]) {
                                    found.options = this.props.accApp[found.options].slice()
                                }
                                if (Array.isArray(found.options)) {
                                    let option = found.options.find(o => (o.value || o) == stepOptions[found.value].value)
                                    if (option) {
                                        type = option.type || 'text'
                                        if (type === 'select' || type === 'multiselect') options = option.options
                                    }
                                }
                            } else {
                                type = 'text'
                            }
                        }

                        if (Array.isArray(options) && item.allowBlank) {
                            options = [{ label: item.blankLabel || item.placeholder || 'None', value: '' }].concat(options)
                        }

                        try {
                            if (item.if) {
                                // eval and hide if false
                                if (!eval(item.if)) {
                                    return ''
                                }
                            }
                        } catch (e) {
                            console.log(e)
                        }

                        var dateselect = ''
                        var numberselect = ''
                        var actionDateOptions = [{ label: "Set to the date of the action", value: "__now" }]
                        var tmpCnt = 1;
                        if (type === 'datetime') {
                            if (stepOptions[item.value] && stepOptions[item.value].value && typeof stepOptions[item.value].value != "object" && stepOptions[item.value].value.indexOf("__now") > -1) {
                                dateselect = '__now'
                                while (tmpCnt < 90) {
                                    actionDateOptions.push({ label: `Action Date +${tmpCnt} days`, value: `__now_` + tmpCnt })
                                    tmpCnt++;
                                }

                            }
                        }

                        if (type === 'number') {
                            if (stepOptions[item.value] && stepOptions[item.value].value === '__inc') {
                                numberselect = '__inc'
                            }
                            if (stepOptions[item.value] && stepOptions[item.value].value === '__dec') {
                                numberselect = '__dec'
                            }
                        }

                        var itemValue = "";
                        if (stepOptions[item.value]) {
                            itemValue = stepOptions[item.value].value;
                        }

                        var itemBy = 1;
                        if (stepOptions[item.value] && stepOptions[item.value].by) {
                            itemBy = stepOptions[item.value].by;
                        }

                        if (type === 'select' && options === null) return null

                        let multiSelectValue = item.placeholder || '--None--';
                        if (type === 'multiselect') {
                            if (!Array.isArray(itemValue)) itemValue = []

                            if (itemValue.length) multiSelectValue = itemValue.map(v => {
                                let option = options.find(o => o.value == v)
                                if (option) return option.label;
                                return v
                            }).join(', ')
                        }

                        let label = item.label;
                        let labelOptions = "";
                        if (item.mergeTags && type === 'text') labelOptions = <button className="a" onClick={() => this.openMergeTags(item.value)} {...inputProps}>Merge Tags</button>;
                        if (item.mergeTags && type === 'number') labelOptions = <button className="a" onClick={() => this.openMergeTags(item.value)} {...inputProps}>Merge Tags</button>;
                        let classes = "mar-b10"
                        if (item.col) classes += ' col-xs-' + item.col
                        else classes += ' col-xs-12'

                        return <div key={index} className={classes}>

                            {type === 'text' && <UILIB.TextInput label={label} labelOptions={labelOptions} placeholder={item.placeholder || item.label} name={item.value} value={itemValue} onChange={e => this.update(e)} error={this.state.errors[item.value]} {...inputProps} />}
                            {type === 'boolean' && <UILIB.CheckBox checked={itemValue} onChange={e => this.update(e)} name={item.value} {...inputProps}>{item.label}</UILIB.CheckBox>}
                            {type === 'select' && <UILIB.Select label={label} data={options} placeholder={item.allowBlank ? undefined : item.placeholder || item.label} name={item.value} value={itemValue} onChange={e => this.update(e)} error={this.state.errors[item.value]} {...inputProps} />}
                            {type === 'number' && <>

                                {!item.fixed && <div className='row' style={{ alignItems: 'flex-end' }}>
                                    <div className="col-xs-6">
                                        <UILIB.Select label={label} data={[{ label: 'Increment', value: '__inc' }, { label: 'Decrement', value: '__dec' }, { label: 'Specific Number', value: '' }]} value={numberselect} name={item.value} onChange={e => this.update(e)} error={this.state.errors[item.value]} {...inputProps} />
                                    </div>
                                    <div className="col-xs-6">
                                        {(itemValue === '__inc' || itemValue === '__dec') && <UILIB.TextInput type={item.mergeTags ? "text" : "number"} max={item.max} min={item.min} placeholder="By" name={item.value} value={itemBy} onChange={e => this.updateBy(e)} error={this.state.errors[item.value]} labelOptions={labelOptions ? ' ' : ''} onKeyDown={(ev) => { if (ev.target.value && ev.target.value.indexOf("{") == -1) { ev.target.value = ev.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0'); } }} {...inputProps} />}
                                        {itemValue !== '__inc' && itemValue !== '__dec' && <UILIB.TextInput type={item.mergeTags ? "text" : "number"} max={item.max} min={item.min} placeholder={item.placeholder || item.label} name={item.value} value={itemValue} onChange={e => this.update(e)} error={this.state.errors[item.value]} labelOptions={labelOptions} onKeyDown={(ev) => { if (ev.target.value && ev.target.value.indexOf("{") == -1) { ev.target.value = ev.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0'); } }} {...inputProps} />}
                                    </div>
                                </div>}
                                {item.fixed && !numberselect && <UILIB.TextInput label={label} type={item.mergeTags ? "text" : "number"} max={item.max} min={item.min} placeholder={item.placeholder || item.label} name={item.value} value={itemValue} onChange={e => this.update(e)} error={this.state.errors[item.value]} labelOptions={labelOptions} onKeyDown={(ev) => { if (ev.target.value && ev.target.value.indexOf("{") == -1) { ev.target.value = ev.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0'); } }} {...inputProps} />}
                            </>
                            }
                            {type === 'multiselect' && <UILIB.ButtonSelect label={label} headerText={multiSelectValue} {...inputProps}>
                                <ul>
                                    {options.map(o => {
                                        return <li><UILIB.CheckBox onChange={() => this.updateArray(item.value, o.value)} checked={itemValue.indexOf(o.value) > -1} {...inputProps}>{o.label}</UILIB.CheckBox></li>
                                    })}
                                </ul>
                            </UILIB.ButtonSelect>}
                            {type === 'datetime' && <>
                                <div className="txtInput_label">
                                    {label}
                                </div>
                                <div className="quickFlex">
                                    <div style={{ flex: "1" }}>
                                        <UILIB.Select data={[{ label: 'Action Date', value: '__now' }, { label: 'Specific Date', value: '' }]} value={dateselect} name={item.value} onChange={e => this.update(e)} error={this.state.errors[item.value]} {...inputProps} />
                                    </div>
                                    {dateselect && <div style={{ flex: "1" }} className="mar-l10">
                                        <UILIB.Select style={{ backgroundColor: 'transparent' }} data={actionDateOptions} name={item.value} value={itemValue} onChange={e => this.update(e)} error={item.valid === false ? 'Required' : ''} {...inputProps} />
                                    </div>}
                                    {!dateselect && <div style={{ flex: "1" }} className="mar-l10">
                                        <UILIB.TextInput style={{ backgroundColor: 'transparent' }} type="date" field={item.value} placeholder={item.placeholder || item.label} value={itemValue} onChange={this.updateDate} error={this.state.errors[item.value]} {...inputProps} />
                                    </div>}
                                </div>
                            </>}
                        </div>
                    })}

                    {!this.props.isThemeDesigner && hook.build.filter(b => b.multi).map((item, index) => {
                        let rows = stepOptions[item.name]
                        if (!Array.isArray(rows)) return <div></div>
                        return <div key={'multi_' + index} className="mar-b10 col-xs-12">
                            {Boolean(item.label) && <div className="txtInput_label mar-b10">{item.label}</div>}
                            {rows.map((row, rowIndex) => {
                                return <div key={'multi_' + index + '_row_' + rowIndex} className="quickFlex mar-b10">
                                    {item.columns.map((column, colIndex) => {
                                        var options = Array.isArray(column.options) ? column.options.slice() : column.options
                                        if (typeof options === 'string') {
                                            options = Array.isArray(this.props.accApp[options]) ? this.props.accApp[options].slice() : this.props.accApp[options]
                                        }
                                        var type = column.type
                                        if (type.indexOf('__lookup=') === 0) {
                                            // look it up based on the a value from another build option
                                            // example __lookup=field.type will use another build with the value of field and use the type from the selected option
                                            let lookup = type.split('=')[1]
                                            let prop = ''
                                            if (lookup.indexOf('.') > -1) {
                                                let split = lookup.split('.')
                                                lookup = split[0]
                                                prop = split[1]
                                            }

                                            let found = item.columns.find(c => c.value === lookup)
                                            if (found) {
                                                if (!Array.isArray(found.options)) {
                                                    found.options = this.props.accApp[found.options].slice()
                                                }
                                                if (Array.isArray(found.options)) {
                                                    let option = found.options.find(o => (o.value || o) == row[found.value])
                                                    if (option) {
                                                        type = option.type || 'text'
                                                        if (type === 'select' || type === 'multiselect') options = option.options
                                                    }
                                                }
                                            } else {
                                                type = 'text'
                                            }
                                        }

                                        var dateselect = ''
                                        var numberselect = ''
                                        var actionDateOptions = [{ label: "Set to the date of the action", value: "__now" }]
                                        var tmpCnt = 1;
                                        if (type === 'datetime') {
                                            if (row[column.value] && typeof row[column.value] != "object" && row[column.value].indexOf("__now") > -1) {
                                                dateselect = '__now'
                                                while (tmpCnt < 90) {
                                                    actionDateOptions.push({ label: `Action Date +${tmpCnt} days`, value: `__now_` + tmpCnt })
                                                    tmpCnt++;
                                                }

                                            }
                                        }

                                        if (type === 'number') {
                                            if (row[column.value] && row[column.value].value === '__inc') {
                                                numberselect = '__inc'
                                            }
                                            if (row[column.value] && row[column.value].value === '__dec') {
                                                numberselect = '__dec'
                                            }
                                        }

                                        let itemValue = ""
                                        if (row[column.value] !== undefined) {
                                            itemValue = row[column.value]
                                        }

                                        let multiSelectValue = item.placeholder || '--None--';
                                        if (type === 'multiselect') {
                                            if (!Array.isArray(itemValue)) itemValue = []

                                            if (itemValue.length) multiSelectValue = itemValue.map(v => {
                                                let option = options.find(o => o.value == v)
                                                if (option) return option.label;
                                                return v
                                            }).join(', ')
                                        }

                                        let label = column.label;
                                        let labelOptions = "";
                                        if (column.mergeTags && type === 'text') labelOptions = <button className="a" onClick={() => this.openMergeTags(column.value, item.name, rowIndex)} {...inputProps}>Merge Tags</button>;
                                        return <div style={{ flex: "1" }} key={'multi_' + index + '_row_' + rowIndex + '_col_' + colIndex}>

                                            {type === 'text' && <UILIB.TextInput label={label} labelOptions={labelOptions} placeholder={column.placeholder || column.label} name={column.value} value={itemValue} onChange={e => this.update(e, item.name, rowIndex)} {...inputProps} />}
                                            {type === 'boolean' && <UILIB.CheckBox label={label} checked={itemValue} onChange={e => this.update(e, item.name, rowIndex)} name={column.value} {...inputProps}>{column.label}</UILIB.CheckBox>}
                                            {type === 'select' && <UILIB.Select filter={true} label={label} data={options} placeholder={column.placeholder || column.label} name={column.value} value={itemValue} onChange={e => this.update(e, item.name, rowIndex)} {...inputProps} />}
                                            {type === 'number' && <div className="quickFlex">
                                                {!column.fixed && <div style={{ flex: "1" }}>
                                                    <UILIB.Select data={[{ label: 'Increment', value: '__inc' }, { label: 'Decrement', value: '__dec' }, { label: 'Specific Number', value: '' }]} value={numberselect} name={column.value} onChange={e => this.update(e, item.name, rowIndex)} {...inputProps} />
                                                </div>}
                                                {!numberselect && <div style={{ flex: "1" }} className="mar-l10">
                                                    <UILIB.TextInput type="number" placeholder={column.placeholder || column.label} name={column.value} value={itemValue} onChange={e => this.update(e, item.name, rowIndex)} {...inputProps} />
                                                </div>}
                                            </div>}
                                            {type === 'multiselect' && <UILIB.ButtonSelect label={label} inputType="text" headerText={multiSelectValue} {...inputProps}>
                                                {options.map(o => {
                                                    return <div><UILIB.CheckBox onChange={ev => this.updateArray(item.value, o.value, item.name, rowIndex)} checked={itemValue.indexOf(o.value) > -1} {...inputProps}>{o.label}</UILIB.CheckBox></div>
                                                })}
                                            </UILIB.ButtonSelect>}
                                            {type === 'datetime' && <div className="quickFlex">
                                                <div style={{ flex: "1" }}>
                                                    <UILIB.Select data={[{ label: 'Action Date', value: '__now' }, { label: 'Specific Date', value: '' }]} value={dateselect} name={column.value} onChange={e => this.update(e, item.name, rowIndex)} {...inputProps} />
                                                </div>
                                                {dateselect && <div style={{ flex: "1" }} className="mar-l10">
                                                    <UILIB.Select style={{ backgroundColor: 'transparent' }} data={actionDateOptions} name={column.value} value={itemValue} onChange={e => this.update(e, item.name, rowIndex)} {...inputProps} />
                                                </div>}
                                                {!dateselect && <div style={{ flex: "1" }} className="mar-l10">
                                                    <UILIB.TextInput style={{ backgroundColor: 'transparent' }} type="date" field={column.value} placeholder={column.placeholder || column.label} value={itemValue} onChange={(f, v) => this.updateDate(f, v, item.name, rowIndex)} {...inputProps} />
                                                </div>}
                                            </div>}
                                        </div>
                                    })}
                                    <div style={{ flex: "0", alignItems: 'center' }}>
                                        <button onClick={() => this.removeRow(item.name, rowIndex)} {...inputProps}>
                                            <UILIB.Icons icon="bin" />
                                        </button>
                                    </div>
                                </div>
                            })}
                            <UILIB.Button text="Add" onClick={() => this.addRow(item.name)} className="button-primary" {...inputProps} />
                        </div>
                    })}
                </div>
            </div>
        </div >
    }
}

export default GenericIntegrationSettings;
import React, { useState } from "react";
import i18n from "i18next";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";

export default function DeleteDomain({
  domain,
  domainType,
  className,
  onDelete,
  onDeleted,
  ...rest
}) {
  const [deleting, setDeleting] = useState(false);

  async function handleDelete() {
    setDeleting(true);

    try {
      await onDelete(domain, domainType);
      onDeleted && onDeleted(domainType);
    } finally {
      setDeleting(false);
    }
  }

  const classes = classnames("delete-domain", className);

  return (
    <div className={classes} {...rest}>
      <h2 className="h4 mar-b15">{i18n.t("company:domains.deleteHeader")}</h2>

      <p>{i18n.t("company:domains.deleteSubHeader")}</p>

      {domainType?.id === 'tracking' && <p>{i18n.t("company:domains:deleteTrackingWarning")}</p>}

      <UILIB.Button
        className="button-red"
        saving={deleting}
        onClick={handleDelete}
      >
        {i18n.t("company:domains.deleteButton")}
      </UILIB.Button>
    </div>
  );
}

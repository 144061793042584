import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
class DeleteFolder extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <h4 className="mar-b25">{i18n.t('templates:fileManager.popups.areYouSureYouWantToDeleteThisFolder')}</h4>
                <UILIB.Button className="button-red" text={i18n.t('templates:fileManager.popups.yes')} onClick={this.props.deleteFolder} />
            </div>
        );
    }
}

export default DeleteFolder;
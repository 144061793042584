import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import { connect } from 'react-redux';
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class CampaignsViewLinkInDepth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignId: this.props.match.params.campaignId,
            linkId: this.props.match.params.linkID,
            pageLoaded: false,
            tableData: {},
            loadingData: 1,
            sortColumn: "emailAddress",
            sortDirection: "asc",
            searchText: "",
            pageSize: 50,
            totalRows: 0,
            currentPage: 0,
            startRecord: 0
        }
        this.loadStats = this.loadStats.bind(this);
        this.tableUpdater = this.tableUpdater.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSearchText = this.changeSearchText.bind(this);
        this.goSearchText = this.goSearchText.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.goViewSubscriber = this.goViewSubscriber.bind(this);
    }

    componentDidMount() {
        this.loadStats()
    }

    goViewSubscriber(subID) {
        this.props.history.push('/cp/subscriber/' + subID);
    }

    loadStats(sortColumn, sortDirection, startRecord, pageSize, searchText) {
        this.setState({ loadingData: 1 });

        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!startRecord) {
            startRecord = this.state.startRecord;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var query = '?sortColumn=' + sortColumn + '&sortOrder=' + sortDirection + '&searchText=' + searchText + '&startRecord=' + startRecord + '&recordLimit=' + this.state.pageSize;
        if (this.props.startDate) {
            query += '&startDate=' + this.props.startDate.format('YYYY-MM-DD');
        }

        if (this.props.endDate) {
            query += '&endDate=' + this.props.endDate.format('YYYY-MM-DD 23:59:59');
        }

        var self = this;
        axios
            .get('/campaign/' + self.state.campaignId + '/templatelink/' + this.state.linkId + '/subscribers' + query)
            .then((res) => {
                var theTableData = res.data.Clicks.map(thisLine => {
                    return {
                        gravatar: {
                            headerValue: "",
                            value: <UILIB.Avatar style={{ height: 'auto', width: '100%', maxWidth: "40px", maxHeight: "40px", marginRight: "10px" }} src={thisLine.gravatar} />,
                            width: 50,
                            orderBy: false
                        },
                        emailAddress: {
                            headerValue: i18n.t('emailAddress'),
                            value: <a onClick={() => { this.goViewSubscriber(thisLine.SubscriberId) }}>{thisLine.Subscriber.emailAddress}</a>,
                            orderBy: true
                        },
                        createdAt: {
                            headerValue: i18n.t('campaigns:nav.clicked'),
                            value: <span>{DateTimeFunctions.formatDateTime(thisLine.createdAt, 1)}</span>,
                            orderBy: true
                        },
                        optionsCol:
                        {
                            headerValue: " ",
                            value: <UILIB.OptionsDropdown popWidth="150px" history={this.history}>
                                <ul>
                                    <li>
                                        <a onClick={() => { this.goViewSubscriber(thisLine.SubscriberId) }}>{i18n.t('campaigns:overview.viewSubscriber')}</a>
                                    </li>
                                </ul>
                            </UILIB.OptionsDropdown>
                            ,
                            orderBy: false,
                            fixed: true,
                            width: 20
                        }
                    }
                })
                self.setState({ tableData: theTableData, loadingData: 0, totalRows: res.data.count, pageLoaded: true });
            });
    }

    tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {
        var thisState = {};
        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!currentPage && currentPage != "0") {
            currentPage = this.state.currentPage;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var startRecord = currentPage * pageSize;

        this.setState({ sortColumn: sortColumn, sortDirection: sortDirection, pageSize: pageSize, currentPage: currentPage, searchText: searchText, startRecord: startRecord }, this.loadStats);

    }
    changePageSize(pageSize) {
        this.tableUpdater(null, null, null, pageSize, null);
    }

    //GROUP NAME SEARCH STUFF
    changeSearchText(event) {
        this.setState({ searchText: event.target.value })
    }
    goSearchText() {
        this.tableUpdater(0, null, null, null, null);
    }
    keyPress(event) {
        if (event.key === 'Enter') {
            this.goSearchText();
        }
    }


    render() {
        return <div>


            <UILIB.Paper>
                <UILIB.Row>
                    <UILIB.Column xs={12} sm={6}>

                        <UILIB.TextInput
                            outerStyle={{ flex: "1" }}
                            clickToChangeClicked={this.goSearchText}
                            placeholder={i18n.t('searchForEmail')}
                            onChange={this.changeSearchText}
                            onKeyPress={this.keyPress}
                            iconLeft={<UILIB.Icons icon="magnifier" style={{ height: 16, width: 16 }} color="#2B2F41" onClick={() => { }} />}
                        />

                    </UILIB.Column>

                    <UILIB.Column md={6} hide={["sm", "xs"]} className="mar-b25" style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                        <div>{i18n.t('showing')}</div>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l15" selected={this.state.pageSize === 10} onClick={() => { this.changePageSize(10) }}>10</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 50} onClick={() => { this.changePageSize(50) }}>50</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 100} onClick={() => { this.changePageSize(100) }}>100</UILIB.ButtonSimple>
                        <UILIB.ButtonSimple className="button-simple-fullsize mar-l5" selected={this.state.pageSize === 250} onClick={() => { this.changePageSize(250) }}>250</UILIB.ButtonSimple>

                    </UILIB.Column>
                </UILIB.Row>
                <UILIB.Row>
                    <UILIB.Column xs={12} className="mar-b25">

                        <UILIB.DataTable1 noResultsTxt={<span> {i18n.t('noResults')}</span>} tableData={this.state.tableData} loadingData={this.state.loadingData}
                            dataUpdater={this.tableUpdater} width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn={this.state.sortColumn}
                            sortedDirection={this.state.sortDirection}
                        />


                    </UILIB.Column>
                    <UILIB.Column xs={12}>
                        <div style={{ textAlign: "center" }}>
                            <UILIB.PagingBlock totalRows={this.state.totalRows} pageSize={this.state.pageSize} numberOfLinks="10" currentPage={this.state.currentPage}
                                changePage={this.tableUpdater} text={i18n.t('page') + ":"} />
                        </div>
                    </UILIB.Column>
                </UILIB.Row>
            </UILIB.Paper>

        </div>
    }
}
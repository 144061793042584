import React from 'react'

export default class IconPc extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V13C17 13.5304 16.7893 14.0391 16.4142 14.4142C16.0391 14.7893 15.5304 15 15 15H12.78L12.903 15.489L13.707 16.293C13.8468 16.4329 13.942 16.611 13.9806 16.805C14.0192 16.9989 13.9993 17.2 13.9237 17.3827C13.848 17.5654 13.7199 17.7215 13.5555 17.8314C13.391 17.9413 13.1978 18 13 18H7C6.80225 18 6.60895 17.9413 6.44454 17.8314C6.28013 17.7215 6.15199 17.5654 6.07632 17.3827C6.00065 17.2 5.98085 16.9989 6.01942 16.805C6.05798 16.611 6.15319 16.4329 6.293 16.293L7.097 15.489L7.22 15H5C4.46957 15 3.96086 14.7893 3.58579 14.4142C3.21071 14.0391 3 13.5304 3 13V5ZM8.771 12H5V5H15V12H8.771Z" fill={color} />
        </svg>
    }
}        
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UILIB from '../../../../../Common-Objects/Lib'
import axios from '../../../../../data/http/axios'
import helpers from '../../../../../Classes/helpers'
import { alterSiteDrawer } from '../../../../../data/actions/siteActions'

const options = [{
    "label": "Initial Signup",
    "value": "formSignup"
},
{
    "label": "Signup Confirmed",
    "value": "formConfirm"
}]

export default function FormAutomate({ FormId, GroupId, formName, edit = true, save = () => { } }) {
    const site = useSelector(state => state.siteMaster)
    const history = useHistory()
    const dispatch = useDispatch()
    const [trigger, setTrigger] = useState('')
    const [name, setName] = useState(formName + ' Automation')

    const create = async () => {
        await save()
        const res = await axios.post('/automation', {
            name,
            version: 1,
            status: 'Draft',
            steps: [{
                "guid": helpers.createLocalGuid(),
                "type": "initial",
                "complete": true,
                "parentGuid": 0,
                "stepOptions": {
                    "formId": {
                        "value": FormId,
                        "required": true
                    },
                    "groupId": {
                        "value": "-1",
                        "required": true
                    },
                    "formType": {
                        "value": trigger,
                        "required": true
                    },
                    "formContactType": {
                        "value": "-1",
                        "required": true
                    }
                },
                "triggerType": {
                    "value": "17",
                    "required": true
                },
                "wizardComplete": true
            }],
            options: {
                source: 'Form ' + (edit ? 'Wizard' : 'View')
            }
        })
        history.push('/cp/automation/addnew/' + res.data.Automation.id + `?redirect=/cp/groups/${GroupId}/forms/${edit ? 'create' : 'view'}/${FormId}${edit ? '/6' : ''}`)
        dispatch(alterSiteDrawer(false, true, 'right', null))
    }

    return (
        <div>
            <div className="quickFlex mar-b10" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <h4>Create an new Automation</h4>
                {site.siteId === 1 && <UILIB.Hint href="https://kb.transpond.io/article/61-what-is-automation" iconLeft={<UILIB.Icons icon="questionCircle" color="#2B2F41" style={{ height: 16, width: 16 }} />}>Learn More</UILIB.Hint>}
            </div>

            <p className='mar-b25 text-sml'>
                You can start a new automation based on when someone signs up with this form. <br /><br />Then perform lots of actions such as automatically sending the contact to a chain of emails after signup.
            </p>

            <UILIB.Select outerClassName="mar-b25" label="Should this run when they first sign up or when they have confirmed their email address?" data={options} value={trigger} onChange={e => setTrigger(e.target.value)} />

            {!!trigger && <UILIB.TextInput label="Give this new Automation a name" value={name} onChange={e => setName(e.target.value)} outerClassName="mar-b25" />}

            {!!trigger && !!name && <>
                <UILIB.WarningHolder className="mar-b25">This will create the automation and take you to the automation designer so you can setup any actions you want to happen when this automation is triggered</UILIB.WarningHolder>
                <UILIB.Button className="button-primary" onClick={create}>Create Automation</UILIB.Button>
            </>}
        </div>
    )
}
import React, { Component } from 'react';
import moment from 'moment'
import axios from '~/data/http/axios'
import UILIB from '~/Common-Objects/Lib'
import dateTimeFunctions from '~/Classes/dateTimeFunctions'
import numberFunctions from '~/Classes/numberFormatFunctions'
import helpers from '~/Classes/helpers'

class MailserverReports extends Component {
    constructor(props) {
        super(props)

        this.state = {
            initialLoad: true,
            loading: true,
            fromDate: moment().startOf('month'),
            mailserver: {},
            toDate: moment(),
            reports: [],
            filtered: []
        }

        this.getReports = this.getReports.bind(this)
        this.updateDate = this.updateDate.bind(this)
        this.exportReport = this.exportReport.bind(this)
        this.toggleMailserver = this.toggleMailserver.bind(this)
    }

    componentDidMount() {
        var promise
        if (this.props.match.params.id) {
            promise = axios.get('/mailServer/' + this.props.match.params.id)
        } else {
            promise = axios.get('/accountMaster/mailservers')
        }
        promise.then(response => {
            this.setState({
                initialLoad: false,
                mailserver: response.data.MailServers || response.data.MailServer
            }, this.getReports)
        })
    }

    updateDate(ev) {
        this.setState({
            fromDate: ev.startDate,
            toDate: ev.endDate
        }, this.getReports)
    }

    toggleMailserver(id) {
        let filtered = this.state.filtered
        let index = filtered.indexOf(id)
        if (index > -1) filtered.splice(index, 1)
        else filtered.push(id)
        this.setState({ filtered }, this.getReports)
    }

    getReports() {
        this.setState({
            loading: true
        })
        let queryStr = '?fromDate=' + this.state.fromDate.format('YYYY-MM-DD')
        queryStr += '&toDate=' + this.state.toDate.format('YYYY-MM-DD')
        let promise
        if (this.props.match.params.id) {
            promise = axios.get('/mailServer/' + this.props.match.params.id + '/reports' + queryStr)
        } else {
            if (this.state.filtered && this.state.filtered.length) {
                queryStr += '&id=' + this.state.filtered.join('&id=')
            }
            promise = axios.get('/mailServer/reports' + queryStr)
        }
        promise.then(response => {
            this.setState({
                reports: response.data,
                loading: false
            })
        })
    }

    exportReport() {
        let queryStr = '?fromDate=' + this.state.fromDate.format('YYYY-MM-DD')
        queryStr += '&toDate=' + this.state.toDate.format('YYYY-MM-DD')
        queryStr += '&export=true'

        let url, name
        if (this.props.match.params.id) {
            url = '/mailServer/' + this.props.match.params.id + '/reports' + queryStr
            name = (this.state.mailserver.notes || this.state.mailserver.name) + '-MailserverReport.csv'
        } else {
            if (this.state.filtered && this.state.filtered.length) {
                queryStr += '&id=' + filtered.join('&id=')
            }
            url = '/mailServer/reports' + queryStr
            name = 'MailserverReport.csv'
        }

        helpers.downloadFile(url, name);
    }

    render() {
        if (this.state.initialLoad) return <UILIB.LoadingIcons iconType="2" />

        let totals = { sends: 0, opens: 0, clicks: 0, bounces: 0, unsubscribes: 0 }

        let tableData = this.state.reports.map(row => {
            totals.sends += row.sends
            totals.opens += row.opens
            totals.clicks += row.clicks
            totals.bounces += row.hardbounces
            totals.unsubscribes += row.unsubscribes
            return {
                date: {
                    headerValue: 'Date',
                    value: dateTimeFunctions.formatDateTime(row.fromDate, "2"),
                },
                sends: {
                    headerValue: 'Sends',
                    value: numberFunctions.formatNumber(row.sends)
                },
                opens: {
                    headerValue: 'Opens',
                    value: numberFunctions.formatNumber(row.opens)
                },
                clicks: {
                    headerValue: 'Clicks',
                    value: numberFunctions.formatNumber(row.clicks)
                },
                bounces: {
                    headerValue: 'Bounces',
                    value: numberFunctions.formatNumber(row.hardbounces)
                },
                unsubscribes: {
                    headerValue: 'Unsubscribes',
                    value: numberFunctions.formatNumber(row.unsubscribes)
                }
            }
        })

        let averages = {
            opens: (100 / totals.sends) * totals.opens,
            clicks: (100 / totals.sends) * totals.clicks,
            bounces: (100 / totals.sends) * totals.bounces,
            unsubscribes: (100 / totals.sends) * totals.unsubscribes
        }

        tableData.push({
            date: {
                headerValue: 'Date',
                value: <span><strong>Total</strong></span>,
            },
            sends: {
                headerValue: 'Sends',
                value: <span><strong>{numberFunctions.formatNumber(totals.sends)}</strong></span>
            },
            opens: {
                headerValue: 'Opens',
                value: <span><strong>{numberFunctions.formatNumber(totals.opens)}</strong></span>
            },
            clicks: {
                headerValue: 'Clicks',
                value: <span><strong>{numberFunctions.formatNumber(totals.clicks)}</strong></span>
            },
            bounces: {
                headerValue: 'Bounces',
                value: <span><strong>{numberFunctions.formatNumber(totals.bounces)}</strong></span>
            },
            unsubscribes: {
                headerValue: 'Unsubscribes',
                value: <span><strong>{numberFunctions.formatNumber(totals.unsubscribes)}</strong></span>
            }
        })

        tableData.push({
            date: {
                headerValue: 'Date',
                value: '',
            },
            sends: {
                headerValue: 'Sends',
                value: ''
            },
            opens: {
                headerValue: 'Opens',
                value: <span><strong>{numberFunctions.formatNumber(averages.opens, 2)}%</strong></span>
            },
            clicks: {
                headerValue: 'Clicks',
                value: <span><strong>{numberFunctions.formatNumber(averages.clicks, 2)}%</strong></span>
            },
            bounces: {
                headerValue: 'Bounces',
                value: <span><strong>{numberFunctions.formatNumber(averages.bounces, 2)}%</strong></span>
            },
            unsubscribes: {
                headerValue: 'Unsubscribes',
                value: <span><strong>{numberFunctions.formatNumber(averages.unsubscribes, 2)}%</strong></span>
            }
        })

        let filteredText = 'All Mailservers'
        if (this.state.filtered.length) {
            filteredText = this.state.filtered.length + ' Mailserver(s)'
        }

        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} md={6} lg={8} margin={0} className="quickFlex">
                        <h4 style={{ paddingTop: '15px', marginBottom: '15px', marginRight: '15px' }}>{Array.isArray(this.state.mailserver) ? 'All Servers' : this.state.mailserver.notes || this.state.mailserver.name} - Reports</h4>
                        {Array.isArray(this.state.mailserver) && <UILIB.ButtonSelect className="mar-r10" width={'250px'} headerText={filteredText}>
                            {this.state.mailserver.map(ms => {
                                return <div className="form-group mar-b5">
                                    <UILIB.CheckBox onChange={() => this.toggleMailserver(ms.id)} labelClass="no-marg" checked={this.state.filtered.indexOf(ms.id) > -1}>{ms.notes || ms.name}</UILIB.CheckBox>
                                </div>
                            })}
                        </UILIB.ButtonSelect>}
                        <UILIB.Button text="export" className="button-sml" onClick={this.exportReport} />
                    </UILIB.Column>
                    <UILIB.Column xs={12} md={6} lg={4} margin={0}>
                        <div className="form-group form-group-icon mar-b0">
                            <UILIB.TextInput type="multiDate" startDate={this.state.fromDate} endDate={this.state.toDate} updateDate={this.updateDate}></UILIB.TextInput>
                        </div>
                    </UILIB.Column>
                    <UILIB.Column xs={12}>
                        <UILIB.Paper isLoading={this.state.loading}>
                            <UILIB.DataTable1
                                tableData={tableData}
                                width="100%"
                                pageSize="100"
                                hasCheckBoxes="no"
                            />
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
            </div>
        );
    }
}

export default MailserverReports;
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class addDOmainPopAdrom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            domainName: "",
            inputErrors: {
                domainName: ""
            }
        };

        this.updateInput = this.updateInput.bind(this);
        this.checkDomain = this.checkDomain.bind(this);
    }

    componentDidMount() { }

    updateInput(ev) {
        this.setState({ [ev.currentTarget.name]: ev.currentTarget.value })
    }

    checkDomain() {
        var self = this;
        var thisDomain = this.state.domainName;
        var inputErrors = this.state.inputErrors;
        inputErrors.domainName = "";

        if (thisDomain.length < 3 || thisDomain.indexOf(".") < 1 || thisDomain.indexOf("@") > 0) {
            inputErrors.domainName = "Invalid domain name"
            self.setState({ inputErrors: inputErrors });
        } else {

            var data = {
                domainName: thisDomain
            }

            axios.post('/accountMaster/domain/adrom/create', data).then(response => {
                self.props.onAddedDomain();
            }).catch(err => {
                this.state.inputErrors.domainName = err.response.data.error ? err.response.data.error : err.data.error ? err.data.error : err.error ? err.error : 'There was a problem';
            });
        }


    }

    render() {
        return <div>
            <h4 className="mar-b25">{i18n.t('company:domains.addDomain')}</h4>
            <div className="mar-b25">{i18n.t('company:domains.adromHeader')}</div>
            <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('company:domains.adromLabel')} focus={true} type="text" placeholder="Domain Name" name="domainName" error={this.state.inputErrors.domainName} value={this.state.domainName} onChange={this.updateInput} />
            <UILIB.Button text={i18n.t('continue')} className="button-primary" onClick={this.checkDomain} />
        </div >
    }
};



import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import DateTimeFunctions from '~/Classes/dateTimeFunctions'
import DragDropFunctions from './dragDropFunctions';


export default class TemplateHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            history: [],
            loaded: false,
            renderedPreview: "",
            masterTemplate: "",
            selectedId: "",
            restoreWarn: false,
            templateJson: {},
            daySummary: [],
            startDate: null,
            hasMore: false,
            total: 0,
            page: 0,
            limit: 50
        }
        this.getHistory = this.getHistory.bind(this);
        this.selectHistory = this.selectHistory.bind(this);
        this.restoreTemplate = this.restoreTemplate.bind(this);
        this.init = this.init.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.loadMore = this.loadMore.bind(this)
    }

    componentDidMount() {
        this.init();
    }

    async init() {
        let masterTemplate = await axios.get('/theme/98');
        let daySummary = await axios.get("/template/history/" + this.props.templateId + "/days")
        daySummary = daySummary.data.map(m => {
            return {
                label: m,
                value: m
            }
        })
        let startDate = null;
        if (daySummary && daySummary.length) {
            startDate = daySummary[0].label
        }
        this.setState({ startDate, daySummary: daySummary, loaded: true, masterTemplate: masterTemplate.data.Theme.templateJson }, () => {
            if (startDate) this.getHistory()
        })
    }

    async getHistory() {
        const offset = this.state.page * 50
        if (offset >= 10000) return this.setState({ hasMore: false, fetching: false })
        const q = "?startDate=" + this.state.startDate + '&offset=' + (this.state.page * 50)
        const res = await axios.get("/template/history/" + this.props.templateId + q);
        const history = [...this.state.history, ...res.data.logs]
        this.setState({
            history,
            fetching: false,
            total: res.data.total,
            hasMore: history.length < res.data.total
        })
    }

    async selectHistory(hist) {
        this.setState({ selectedId: hist.id, restoreWarn: false })
        let history = await axios.get("/template/history/" + this.props.templateId + "/" + hist.id);
        let templateJson = JSON.parse(history.data.body).templateJson;
        templateJson = DragDropFunctions.convertToFullJson(templateJson, this.state.masterTemplate, this.props.layouts, this.props.blocks, this.props.components)

        let body = DragDropFunctions.generateHtmlFromJson(templateJson, false, templateJson.globalVariables)

        this.setState({ renderedPreview: body, templateJson: templateJson })
    }

    async restoreTemplate() {
        this.props.restore(this.state.templateJson)
    }

    loadMore() {
        if (this.state.fetching) return
        this.setState({
            fetching: true,
            page: this.state.page + 1
        }, this.getHistory)
    }

    changeDate(ev) {
        this.setState({
            startDate: ev.target.value,
            page: 0,
            total: 0,
            history: []
        }, this.getHistory)
    }
    render() {
        if (!this.state.loaded) return <UILIB.LoadingIcons iconType="2" />
        if (!this.state.daySummary.length) {
            return <div>
                <h3 className="mar-b10">No History Yet</h3>
                <div>There is no saved history for this template yet.</div>
                <div>Please try again shortly.</div>
            </div>
        }
        if (!this.state.history.length) return <div>
            <h3 className="mar-b10">No History for {DateTimeFunctions.formatDateTime(this.state.startDate, 2)}</h3>
            <div>There is no history for the date you have selected.</div>
        </div>

        const loader = <UILIB.LoadingLine key={0}></UILIB.LoadingLine>

        return <div>
            <h3 className="mar-b10">Template History</h3>
            <div className="mar-b25">View and restore the history for this template by clicking on the items below.</div>
            <div style={{ display: "flex" }}>
                <div style={{ flex: "0 0 250px", padding: 10 }} className="mar-r15">
                    <UILIB.Select label="Show history for:" data={this.state.daySummary} value={this.state.startDate} onChange={this.changeDate} />
                    <div style={{ height: "calc(100vh - 250px)", overflow: 'auto' }} ref={r => this.scrollRef = r}>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadMore}
                            hasMore={this.state.hasMore}
                            loader={loader}
                            useWindow={false}
                            getScrollParent={() => this.scrollRef}
                        >
                            {
                                this.state.history.map((hist) => {
                                    let selected = "";
                                    if (hist.id == this.state.selectedId) selected = "selected"
                                    return <div key={hist.id} className={"template_history_line " + selected} onClick={() => { this.selectHistory(hist) }}>
                                        <UILIB.Avatar for={'user_' + hist.AccountUserId} src={hist.profilePicFileName} title={hist.AccountUserFullName} style={{ width: "20px", height: "20px" }} />
                                        <div className='date'>{DateTimeFunctions.formatDateTime(hist.createdAt)}</div>

                                    </div>
                                })
                            }
                        </InfiniteScroll>
                    </div>
                </div>
                <UILIB.Paper style={{ flex: "1", padding: "0", position: "relative" }}>
                    <iframe style={{ width: "100%", height: "100%" }} frameBorder="0" srcDoc={this.state.renderedPreview} />
                    {(this.state.selectedId != "") && <>

                        {(!this.state.restoreWarn) && <div style={{ position: "absolute", zIndex: "99999", bottom: "10px", right: "10px" }}>
                            <UILIB.Button text={"Restore"} iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => { this.setState({ restoreWarn: true }) }} />
                        </div>}
                        {(this.state.restoreWarn) && <div style={{ position: "absolute", zIndex: "99999", bottom: "10px", right: "10px", backgroundColor: "white", padding: "20px" }}>
                            <h4 className="mar-b10">Really restore this template?</h4>
                            <div className="mar-b25">Are you sure you would like to restore this template?</div>
                            <UILIB.Button text="Yes - Restore It" className="mar-r10" onClick={this.restoreTemplate} />
                            <UILIB.Button text="No - Forget It" onClick={() => { this.setState({ restoreWarn: false }) }} />
                        </div>}
                    </>}
                </UILIB.Paper>
            </div>
        </div>

    }
}
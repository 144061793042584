import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import DateTimeFunctions from '~/Classes/dateTimeFunctions'
import IncDisconnect from './incDisconnect';

import ChangeGroup from './changeGroup';
import ApplicationTransactionsGraph from '../components/applicationTransactionsGraph'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster }
})

export default class TrustPilotConfig extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            appData: {},
            appBinding: {},
            groupData: [],
            contactsGroupId: -1,
            importing: false,
            importId: -1,
            importProgress: 0
        };

        this.getApp = this.getApp.bind(this);
        this.getAppBinding = this.getAppBinding.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.checkForImportUpdate = this.checkForImportUpdate.bind(this);
        this.resetGroup = this.resetGroup.bind(this);
        this.popDisconnect = this.popDisconnect.bind(this);
        this.goDisconnect = this.goDisconnect.bind(this);
    }

    componentDidMount() {
        this.getApp();
    }

    componentWillUnmount() {
        if (this.theTimer) clearTimeout(this.theTimer)
    }

    getApp() {
        var self = this;
        axios.get("/application/36").then(_theApp => {
            self.setState({ appData: _theApp.data.Application }, self.getAppBinding)
        })
    }

    getAppBinding() {
        var self = this;
        axios.get("/application/account/app/" + self.state.appData.id).then(_theResp => {
            var contactsGroupId = -1;

            if (!_theResp.data.Application.appSettings || !_theResp.data.Application.appSettings.businessData) {
                window.open('/cp/company/integrations/apps/trustpilot/addBusiness', '_self')
                return;
            }
            if (_theResp.data.Application.appSettings && _theResp.data.Application.appSettings.contactsGroupId) contactsGroupId = _theResp.data.Application.appSettings.contactsGroupId;
            self.setState({ appBinding: _theResp.data.Application, contactsGroupId: contactsGroupId }, self.getGroups)
        })
    }

    getGroups() {
        var self = this;
        axios.get("/group").then(_theGroups => {
            self.setState({ groupData: _theGroups.data.Groups, isLoaded: true })
        })
    }


    resetGroup() {
        this.setState({ contactsGroupId: -1 })
    }
    updateGroup(contactsGroupId) {
        var self = this;
        var appBinding = this.state.appBinding;
        if (!appBinding.appSettings) appBinding.appSettings = {};

        appBinding.appSettings.contactsGroupId = contactsGroupId;

        axios.put("/application/account/" + this.state.appBinding.id, this.state.appBinding).then(_theRes => {
            return axios.get("/application/trustpilot/setupGroupAndImport?forceResync=true")
        }).then(_res => {
            self.setState({ contactsGroupId: contactsGroupId, importing: true, importId: _res.data.contactsJob.id }, () => {
                self.checkForImportUpdate();
                self.getGroups();
            });

        })

    }

    checkForImportUpdate() {
        var self = this;
        axios.get('/group/' + this.state.contactsGroupId + '/import/' + this.state.importId).then(res => {
            self.setState({ importProgress: res.data.PercentComplete })
            if (res.data.PercentComplete < 100) {
                this.theTimer = setTimeout(this.checkForImportUpdate, 1000);
            }
            else {
                self.setState({ importing: false, importProgress: 100 }, this.getAppBinding)
            }
        })
    }

    popDisconnect() {
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <IncDisconnect goDisconnect={this.goDisconnect} />, true, "500px"));
    }

    goDisconnect() {
        var self = this;
        axios.get('/application/trustpilot/disconnect').then(res => {
            self.props.history.push('/cp/company/integrations')
        })
    }


    render() {

        if (!this.state.isLoaded) return <div><UILIB.LoadingIcons iconType="2" /></div>
        var currentContactsGroup = this.state.groupData.find(theRow => theRow.id == this.state.contactsGroupId);
        if (!currentContactsGroup || this.state.contactsGroupId == '' || this.state.contactsGroupId == '-1') return <ChangeGroup appBinding={this.state.appBinding} updateGroup={this.updateGroup} />

        return <div>

            <UILIB.Row className="middle-xs mar-b25">
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="col-md-offset-2">
                    <div className="quickFlex">
                        <div className="integration-avatar rounded" style={{ backgroundImage: "url(" + this.state.appData.appLogoUri + ")" }}></div>
                        <p className="no-marg pad-left">TrustPilot is Connected.</p>
                    </div>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="end-xs">
                    <p className="no-marg">
                        <UILIB.Button className="button-sml grey" onClick={this.popDisconnect}>
                            Disconnect
                        </UILIB.Button>
                    </p>
                </UILIB.Column>
            </UILIB.Row>

            {this.state.appBinding.appSettings && this.state.appBinding.appSettings.businessData &&
                <UILIB.Row className="middle-xs mar-b10">
                    <UILIB.Column xs={12} sm={12} md={8} lg={8} className="col-md-offset-2">

                        <UILIB.Paper className="height-100">
                            <h4 className="mar-b25">Connected Sites</h4>
                            {this.state.appBinding.appSettings.businessData.map((theBiz, index) => {
                                return <UILIB.Button text={theBiz.name} className="primary mar-r10" onClick={() => this.props.history.push('/cp/company/integrations/apps/trustpilot/addbusiness')} />
                            })}

                            <UILIB.Button text="Manage Sites" className="grey" onClick={() => this.props.history.push('/cp/company/integrations/apps/trustpilot/addbusiness')} />
                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>}

            <UILIB.Row className="mar-b25">



                <UILIB.Column xs={12} sm={12} md={4} lg={4} className="col-md-offset-2">
                    <UILIB.Paper className="height-100">
                        <h4 className="mar-b25">Contacts</h4>
                        <p>TrustPilot is currently connected and is automatically syncing your contacts with the following group. Whenever new reviews are added to TrustPilot, reviewer details are added here.</p>
                        <UILIB.Row className="mar-b25">
                            <UILIB.Column xs={12} sm={6} className="mar-b25">
                                <UILIB.SquareChip className="square-chip-purple" onClick={() => this.props.history.push('/cp/groups/' + this.state.contactsGroupId)}>{currentContactsGroup.groupName}</UILIB.SquareChip>
                            </UILIB.Column>

                            <UILIB.Column xs={12}>
                                {!this.state.importing && this.state.appBinding.appSettings && this.state.appBinding.appSettings.lastSynced && <div className="text-xsml">Last synced: {DateTimeFunctions.formatDateTime(this.state.appBinding.appSettings.lastSynced, 1)} - <a onClick={() => { this.updateGroup(this.state.contactsGroupId, this.state.leadsGroupId) }}>Re-sync</a></div>}
                            </UILIB.Column>
                        </UILIB.Row>

                        <p className="no-marg"><UILIB.Button className="full-width button-md outline primary" onClick={this.resetGroup}>Change Groups</UILIB.Button></p>
                        {this.state.importing && <div style={{ marginTop: "10px" }}><p>Performing Sync</p> <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.importProgress} val2={100} /></div>}
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={4} lg={4}>
                    <UILIB.Paper className="height-100">
                        <h4 className="mar-b25">Sync history</h4>
                        <ApplicationTransactionsGraph applicationId={this.state.appData.id} />
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row className="mar-b60">
                <UILIB.Column xs={12} sm={12} md={8} lg={8} className="col-md-offset-2">
                    <UILIB.Paper className="height-100">
                        <UILIB.Row>
                            <UILIB.Column xs={12} margin={0}>
                                <h4 className="mar-b25">Automation</h4>
                                <p>Automations are a great way to automatically make things happen based on your contacts activity. For example, you could send a thankyou email when somebody adds a new TrustPilot Review.</p>
                                <p><UILIB.Button text="Add an Automation" className="button-sml" onClick={() => { this.props.history.push('/cp/automation') }} /></p>
                            </UILIB.Column>
                        </UILIB.Row>

                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
        </div>

    };


};



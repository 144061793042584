import React from 'react'
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PermissionsChecker from '../../../../Classes/permissions'
import Icons from '../../Icons'
import ReactTooltip from 'react-tooltip';
import GAFunctions from '../../../../Classes/googleAnalytics';
import Rum from '../../../../Classes/rum'

@connect((store) => {
    return { permissionStore: store.permission, account: store.accountMaster, user: store.user }
})
export default class TheButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            clearSaved: false
        }
        this.timer
        this.timer2
        this.onClick = this.onClick.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.saving && !this.props.saving) {
            this.setState({
                clearSaved: true
            }, () => {
                if (this.timer) clearTimeout(this.timer)
                this.timer = setTimeout(() => {
                    this.setState({
                        clearSaved: false,
                    })
                }, 2000)
            })
        }
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer)
    }

    onClick(e) {
        if (this.props.gaEvent) {
            GAFunctions.tagManagerEvent(this.props.gaEvent, this.props.account.id, this.props.user.accountUserId, this.props.user.userData.emailAddress)
            Rum.trackEvent(this.props.gaEvent)
        }
        if (this.props.onClick) {
            this.props.onClick(e)
        }
    }

    render() {
        var theText = "";
        var theClass = "button";

        if (this.props.hide || (this.props.show !== undefined && !this.props.show)) {
            return (null)
        }

        if (this.props.className) {
            theClass += " " + this.props.className;
        }

        if (this.props.children) {
            theText = this.props.children;
        }
        else {
            theText = this.props.text;
        }

        if (this.props.saving) {
            theClass += " button-saving"
        }

        var allowed = true
        if (this.props.permission) {
            allowed = PermissionsChecker(this.props.permission, this.props.permissionStore.permissions)
        }
        let iconLeft = "";
        if (this.props.iconLeft) {
            iconLeft = <div className="button-iconLeft" style={this.props.iconLeftStyle}>{this.props.iconLeft}</div>
            theClass += " button-icon-left"
        }

        let iconRight = "";
        if (this.props.iconRight) {
            iconRight = <div className="button-iconRight" style={this.props.iconRightStyle}>{this.props.iconRight}</div>
            theClass += " button-icon-right"
        }

        const iconStyleRight = this.props.iconRightStyle || {}
        if (this.props.hasOwnProperty('saving')) {
            iconStyleRight.display = 'inline-block'
            iconStyleRight.top = 10
            iconStyleRight.marginLeft = 10
            iconStyleRight.position = 'relative'
        }
        let title = "";
        if (this.props.title || this.props.alt) {
            if (this.props.title) title = this.props.title;
            if (this.props.alt) title = this.props.alt;
        }

        let RootTag = 'button';
        if (this.props.href) {
            RootTag = 'a';
        } else if (this.props.to) {
            RootTag = Link;
        }

        if (allowed) {
            return (
                <>
                    <RootTag href={this.props.href} name={this.props.name || this.props.gaEvent} type={this.props.type} data-tip={this.props.tooltip && this.props.for ? true : undefined} data-for={this.props.for} to={this.props.to} onClick={this.onClick} target={this.props.target} id={this.props.id} disabled={this.props.disabled} className={theClass} style={this.props.style} title={this.props.title}>
                        {iconLeft}
                        {theText}
                        {!this.props.hasOwnProperty('saving') && iconRight}
                    </RootTag>
                    {!!this.props.tooltip && !!this.props.for && <ReactTooltip className="tooltip" effect="solid" id={this.props.for}>{this.props.tooltip}</ReactTooltip>}
                    {this.props.saving && <div className="button-iconRight" style={iconStyleRight} data-test="button-saving-icon"><Icons style={{ height: 25, width: 25 }} icon={'loading'} /></div>}
                    {this.props.hasOwnProperty('saving') && this.state.clearSaved && !this.props.saving && <div className="button-iconRight" style={iconStyleRight} data-test="button-saved-icon"><Icons style={{ height: 25, width: 25 }} icon={'tick'} /></div>}
                </>
            );
        } else {
            return null;
        }

    }
}


import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import NewSubscribers from './newSubscribers'
import moment from 'moment'

export default class GroupAnalyticsTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupID: undefined,
            pageReady: false,
            startDate: moment().subtract(30, "days"),
            endDate: moment()
        };

        this.updateDate = this.updateDate.bind(this);
    }


    componentDidMount() {
        var thisGroupID = this.props.match.params.groupID;
        this.setState({ groupID: thisGroupID, pageReady: true });
    }

    updateDate(event) {
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if (event.startDate) startDate = event.startDate;
        if (event.endDate) endDate = event.endDate;
        this.setState({ startDate: startDate, endDate: endDate })
    }



    render() {
        if (!this.state.pageReady) return <div></div>
        return <div>
            <div className="mar-b25 quickFlex" style={{ justifyContent: "end" }} >
                <div style={{ width: "100%", maxWidth: "400px" }}>
                    <UILIB.TextInput type="multiDate" startDate={this.state.startDate} endDate={this.state.endDate} updateDate={this.updateDate}></UILIB.TextInput>
                </div>
            </div>
            <UILIB.Paper>
                <h4 className="mar-b25">{i18n.t('subscribers:analytics.newSubscribersJoined')}</h4>
                <NewSubscribers groupId={this.state.groupID} startDate={this.state.startDate} endDate={this.state.endDate} />
            </UILIB.Paper>

        </div>

    };
};
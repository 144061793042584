import React from 'react'
import UILIB from '../../../Lib';

export default class ErrorHolder extends React.Component {
    render() {
        var rndNo = Math.floor((Math.random() * 999999) + 1);
        let classes = 'formerror '
        if (this.props.className) classes += this.props.className
        return <div {...this.props} className={classes} key={"err-" + rndNo} style={{ display: "flex" }}>
            <span className="mar-r5"><UILIB.Icons icon="warning" color={"#dd4124"} /></span>
            {this.props.children}
        </div>
    }
}

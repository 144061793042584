export default {
    badHeaderCheck: async function (headerName, headerValue) {
        let object = {
            failed: false,
            message: ""
        }
        if (headerName) headerName = headerName.toLowerCase();
        if (headerName == "x-ms-exchange-organization-pcl") {
            if (headerValue && !isNaN(headerValue)) {
                headerValue = Number(headerValue)
                if (headerValue >= 4) {
                    object.failed = true;
                    object.message = "The content of your message resembles a phishing attempt";
                }
            }
        }
        if (headerName == "x-ms-exchange-organization-scl") {
            if (headerValue && !isNaN(headerValue)) {
                headerValue = Number(headerValue)
                if (headerValue >= 5 && headerValue <= 8) {
                    object.failed = true;
                    object.message = "The content of your message may be considered spam";
                }
                if (headerValue > 8) {
                    object.failed = true;
                    object.message = "The content of your message WILL be considered spam";
                }
            }
        }
        if (headerName == "x-microsoft-antispam" && headerValue.indexOf("BCL:") > -1) {
            headerValue = headerValue.split("BCL:")[1].replace(";", "")
            if (headerValue && !isNaN(headerValue)) {
                headerValue = Number(headerValue)
                if (headerValue >= 6) {
                    object.failed = true;
                    object.message = "The sending infrastructure detected is marked as bad. Please contact support."
                }
            }
        }
        return object
    }
}
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import moment from 'moment'
import i18n from '~/i18n';
import axios2 from 'axios'
import { connect } from 'react-redux';

@connect((store) => {
    return { siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})
class AddReport extends Component {
    constructor(props) {
        super(props)

        let searchTimer;
        this.state = {
            step: 0,
            totalSteps: 2,
            name: '',
            type: '',
            running: false,
            campaigns: [],
            fieldFilter: '',
            fields: [],
            selectedFields: [],
            folders: [],
            campaignsFilter: this.props.accountMaster.tier?.permissions?.campaignFolders?.allowed ? "" : "select",
            filters: {
                endDate: moment(),
                startDate: moment().subtract(1, 'month'),
                campaigns: [],
                folderId: '',
                bounceType: ''
            },
            selectLabels: false

        }

        this.CancelToken = axios2.CancelToken;
        this.source = this.CancelToken.source();

        this.nextStep = this.nextStep.bind(this)
        this.selectType = this.selectType.bind(this)
        this.setDate = this.setDate.bind(this)
        this.updateCampaigns = this.updateCampaigns.bind(this)
        this.updateFilter = this.updateFilter.bind(this)
        this.run = this.run.bind(this)
        this.checkReport = this.checkReport.bind(this);
        this.updateName = this.updateName.bind(this)
        this.all = this.all.bind(this)
        this.toggle = this.toggle.bind(this)
        this.allCampaigns = this.allCampaigns.bind(this)
        this.getCustomFields = this.getCustomFields.bind(this);
        this.getCampaigns = this.getCampaigns.bind(this);
        this.getFolders = this.getFolders.bind(this)
    }

    componentDidMount() {

        this.refs.carousel.style.width = (window.innerWidth * this.state.totalSteps) + 'px';
        this.refs.carousel.style.left = '0px';
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.getCustomFields();
    }

    async getCustomFields() {
        let response = await axios.get('/customfield')
        this.setState({
            fields: response.data.CustomFields,
            selectedFields: response.data.CustomFields.map(f => f.id)
        }, this.getFolders)
    }
    async getCampaigns() {
        this.setState({
            loadingCampaigns: true
        })
        this.source.cancel('I cancelled this')
        this.source = this.CancelToken.source();
        let self = this;
        if (this.searchTimer) {
            this.searchTimer = clearTimeout(this.searchTime)
        }
        let timeOut = 0;
        let searchText = '';
        if (this.state.campaignFilter) {
            searchText = '&searchText=' + this.state.campaignFilter
            timeOut = 1000;
        }
        let url = '/campaign?sortOrder=desc&simple=true&all=true' + searchText;
        this.searchTimer = setTimeout(() => {
            axiosCampaigns(url)

        }, timeOut);

        async function axiosCampaigns(url) {
            let response = await axios.get(url, { cancelToken: self.source.token })
            self.setState({
                campaigns: response.data.Campaigns,
                loadingCampaigns: false,
                loaded: true
            })
        }
    }

    getFolders() {
        axios.get('/campaign/folder').then(response => {
            this.setState({
                folders: response.data
            }, this.getCampaigns)
        })

    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer)
    }

    updateDimensions() {
        this.refs.carousel.style.width = (window.innerWidth * this.state.totalSteps) + 'px';
        this.refs.carousel.style.left = '-' + (window.innerWidth * this.state.step) + 'px';
    }

    nextStep(step) {
        this.setState({
            currentStep: step
        })
        this.refs.carousel.style.left = '-' + (window.innerWidth * step) + 'px';
    }

    selectType(type) {
        this.setState({
            type,
            name: type + ' report ' + moment().format('YYYY-MM-DD HHmm')
        }, () => {
            this.nextStep(1)
        })
    }

    setDate(name, value) {
        var filters = this.state.filters;
        filters[name] = value
        this.setState({
            filters
        })
    }

    updateCampaigns(event) {
        var filters = this.state.filters;
        var campaigns = filters.campaigns;
        var id = parseInt(event.currentTarget.name)
        if (event.currentTarget.checked) {
            var index = this.state.campaigns.findIndex(c => c.id === id)
            if (index === -1) return
            campaigns.push(this.state.campaigns[index])
        } else {
            var index = campaigns.findIndex(c => c.id === id)
            if (index === -1) return
            campaigns.splice(index, 1)
        }
        this.setState({
            filters
        })
    }

    updateName(ev) {
        this.setState({
            name: ev.currentTarget.value
        })
    }

    updateFilter(ev) {
        var filters = this.state.filters
        filters[ev.currentTarget.name] = ev.currentTarget.value;
        this.setState({ filters })
    }

    all(clear) {
        if (clear) {
            this.setState({
                selectedFields: []
            })
        } else {
            this.setState({
                selectedFields: this.state.fields.map(f => f.id)
            })
        }
    }

    toggle(ev) {
        let id = parseInt(ev.currentTarget.name)
        let selectedFields = this.state.selectedFields
        let index = selectedFields.indexOf(id)
        if (index > -1) selectedFields.splice(index, 1)
        else selectedFields.push(id)
        this.setState({ selectedFields })
    }

    run() {
        this.setState({
            running: true
        })
        var filters = JSON.parse(JSON.stringify(this.state.filters))
        if (this.state.campaignsFilter !== "select") {
            delete filters.campaigns
        } else {
            filters.campaigns = filters.campaigns.map(c => c.id)
        }
        if (this.state.campaignsFilter !== "folder") {
            delete filters.folderId
        }

        axios.post('/reports', {
            name: this.state.name || this.state.type + ' report',
            type: this.state.type,
            fields: this.state.selectedFields,
            filters,
            selectLabels: this.state.selectLabels
        }).then(response => {
            this.setState({
                report: response.data.Report
            }, () => {
                this.checkReport()
                this.timer = setInterval(this.checkReport, 5000)
            })
        })
    }

    checkReport() {
        axios.get('/reports/' + this.state.report.id).then(response => {
            var report = response.data.Report;
            if (report.status == 'finished') {
                clearInterval(this.timer)
                this.props.download(report)
            }
        })
    }

    allCampaigns(clear) {
        let filters = this.state.filters
        if (!clear) {
            filters.campaigns = this.state.campaigns.slice()
        } else {
            filters.campaigns = []
        }
        this.setState({
            filters
        })
    }

    selectFolder(e) {
        const filters = this.state.filters
        filters.folderId = e.target.value
        this.setState({ filters })
    }

    render() {

        if (this.state.running) {
            return <div className="center-xs">
                <h4 className="mar-b25 text-primary loading">{i18n.t('campaigns:reports.reportRunning')}</h4>
                <div>{i18n.t('campaigns:reports.youCanLeaveThisPage')}</div>
            </div>
        }

        this.state.filters.campaigns.forEach(c => {
            if (c.Automation) {
                c.campaignName = c.Automation.name + ' - ' + c.subject
            }
        })
        this.state.campaigns.forEach(c => {
            if (c.Automation) {
                c.campaignName = c.Automation.name + ' - ' + c.subject
            }
        })

        let campaigns = this.state.campaigns

        let canUseCampaignFolders = this.props.accountMaster.tier?.permissions?.campaignFolders?.allowed
        if (this.props.siteMaster.siteId !== 1) canUseCampaignFolders = false

        return <div className="container-fluid">
            <div className="carousel-drawer mar-t20" ref="carousel">
                <div className="segments">
                    <div className="eachSegment" style={{ width: "auto", padding: "0 145px" }}>
                        <div className="mar-b25">
                            <h4>{i18n.t('campaigns:reports.whatWouldYouLikeToReportOn')}</h4>
                        </div>


                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-lg-4 mar-b25">
                                <Card
                                    onClick={() => { this.selectType("campaign") }}
                                    icon="megaphone"
                                    title={i18n.t('campaigns:reports.campaignStats')} />

                            </div>
                            <div className="col-xs-12 col-sm-6 col-lg-4 mar-b25">

                                <Card
                                    onClick={() => { this.selectType("sent") }}
                                    icon="envelope"
                                    title={i18n.t('campaigns:reports.sent')} />

                            </div>
                            <div className="col-xs-12 col-sm-6 col-lg-4 mar-b25">
                                <Card
                                    onClick={() => { this.selectType("open") }}
                                    icon="envelopeOpen"
                                    title={i18n.t('campaigns:reports.campaignOpens')} />


                            </div>
                            <div className="col-xs-12 col-sm-6 col-lg-3 mar-b25">
                                <Card
                                    onClick={() => { this.selectType("bounce") }}
                                    icon="arrowBounce"
                                    title={i18n.t('campaigns:reports.bounces')} />

                            </div>
                            <div className="col-xs-12 col-sm-6 col-lg-3 mar-b25">
                                <Card
                                    onClick={() => { this.selectType("click") }}
                                    icon="handPointingUp"
                                    title={i18n.t('campaigns:reports.campaignClicks')} />

                            </div>
                            <div className="col-xs-12 col-sm-6 col-lg-3 mar-b25">
                                <Card
                                    onClick={() => { this.selectType("unsubscribe") }}
                                    icon="warning"
                                    title={i18n.t('campaigns:reports.campaignUnsubscribes')} />

                            </div>
                            <div className="col-xs-12 col-sm-6 col-lg-3 mar-b25">
                                <Card
                                    onClick={() => { this.selectType("deliverability") }}
                                    icon="outbox"
                                    title={i18n.t('campaigns:reports.campaignDeliverability')} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="segments">
                    <div className="eachSegment" style={{ width: "auto", padding: "0 145px" }}>
                        <UILIB.Paper>
                            <div className="quickFlex mar-b25">
                                <UILIB.Hint className="mar-0" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={() => { this.nextStep(0) }}>{i18n.t('subscribers:subscriber.back')}</UILIB.Hint>
                            </div>
                            <h4 className="mar-b25">{i18n.t('campaigns:reports.createReport')}</h4>
                            <UILIB.TextInput outerClassName="mar-b25" label={i18n.t('campaigns:reports.reportName')} value={this.state.name} onChange={this.updateName} placeholder={i18n.t('campaigns:reports.reportName')} />

                            <UILIB.Paper>
                                <h4 className="mar-b25">{i18n.t('campaigns:reports.filters')}</h4>

                                <UILIB.Row>
                                    <UILIB.Column xs={12} sm={6} className="mar-b25">
                                        <UILIB.TextInput label={i18n.t('campaigns:reports.startDate')} type="date" name="startDate" onChange={(ev, val) => this.setDate('startDate', val)} value={this.state.filters.startDate} placeholder={i18n.t('campaigns:reports.startDate')} />
                                    </UILIB.Column>
                                    <UILIB.Column xs={12} sm={6} className="mar-b25">
                                        <UILIB.TextInput label={i18n.t('campaigns:reports.endDate')} type="date" name="endDate" onChange={(ev, val) => this.setDate('endDate', val)} value={this.state.filters.endDate} placeholder={i18n.t('campaigns:reports.endDate')} />
                                    </UILIB.Column>


                                    <UILIB.Column xs={12} sm={6} md={this.state.type === 'bounce' ? 4 : 6} className="mar-b25">
                                        <div className="row">

                                            {canUseCampaignFolders && <div className={this.state.campaignsFilter === "" ? "col-xs-12" : "col-xs-6"}>
                                                <UILIB.Select label={i18n.t('campaigns:reports.campaignS')} value={this.state.campaignsFilter} onChange={e => this.setState({ campaignsFilter: e.target.value })}
                                                    data={[{ label: "All Campaigns", value: "" }, { label: "Select Campaigns", value: "select" }, { label: "Campaign Folder", value: "folder" }]} />
                                            </div>}
                                            {this.state.campaignsFilter === "select" && <div className={canUseCampaignFolders ? "col-xs-6" : "col-xs-12"}>
                                                <UILIB.ButtonSelect
                                                    label={canUseCampaignFolders ? <div>&nbsp;</div> : i18n.t('campaigns:reports.campaignS')}
                                                    filter={true}
                                                    dataStyle={{ maxHeight: 300, overflow: 'auto' }}
                                                    selectAll={() => this.allCampaigns()}
                                                    clearAll={() => this.allCampaigns(true)}
                                                    updateFilter={campaignFilter => this.setState({ campaignFilter }, this.getCampaigns)}
                                                    headerText={this.state.filters.campaigns.length > 1 ? `${this.state.filters.campaigns.length} Campaigns` : this.state.filters.campaigns.map(c => c.campaignName).join(', ') || i18n.t('allCampaigns')}
                                                    data={campaigns.map((c, i) => <UILIB.CheckBox key={i} name={c.id} onChange={this.updateCampaigns} checked={this.state.filters.campaigns.some(f => f.id == c.id)}>{c.campaignName}</UILIB.CheckBox>)}
                                                    loadingData={this.state.loadingCampaigns}>
                                                </UILIB.ButtonSelect>
                                            </div>}
                                            {this.state.campaignsFilter === "folder" && <div className="col-xs-6">
                                                <UILIB.Select label="&nbsp;" value={this.state.filters.folderId} onChange={this.selectFolder.bind(this)} data={this.state.folders.map(f => ({ label: f.name, value: f.id }))} placeholder="Select Folder" />
                                            </div>}
                                        </div>
                                    </UILIB.Column>
                                    <UILIB.Column xs={12} sm={6} md={this.state.type === 'bounce' ? 4 : 6} className="mar-b25">
                                        <UILIB.ButtonSelect dataStyle={{ maxHeight: 300, overflow: 'auto' }}
                                            label={i18n.t('campaigns:reports.fields')} filter={true} selectAll={() => this.all()}
                                            headerText={this.state.selectedFields.length === this.state.fields.length ? 'All Fields' : this.state.selectedFields.length + ' Fields'} clearAll={() => this.all(true)} updateFilter={fieldFilter => this.setState({ fieldFilter })}
                                            data={this.state.fields.filter(f => f.fieldDesc.indexOf(this.state.fieldFilter) > -1).map(f => <UILIB.CheckBox labelClass="no-marg" key={f.id} checked={this.state.selectedFields.indexOf(f.id) > -1} name={f.id} onChange={this.toggle}>{f.fieldDesc}</UILIB.CheckBox>)}
                                        >

                                        </UILIB.ButtonSelect>
                                        <UILIB.Toggle className="mar-t10" after="Use labels on select fields instead of values" checked={this.state.selectLabels} onChange={e => this.setState({ selectLabels: !this.state.selectLabels })} />
                                    </UILIB.Column>

                                    {this.state.type === 'bounce' && <UILIB.Column xs={12} sm={6} md={4} className="mar-b25">
                                        <UILIB.Select label={i18n.t('campaigns:reports.bounceType')} value={this.state.filters.bounceType} name="bounceType" onChange={this.updateFilter} data={[{ label: i18n.t('campaigns:reports.allBounces'), value: '' }, { label: i18n.t('campaigns:reports.hardBounces'), value: 'hard' }, { label: i18n.t('campaigns:reports.softBounces'), value: 'soft' }]} />
                                    </UILIB.Column>}
                                </UILIB.Row>
                            </UILIB.Paper>

                            <UILIB.Button disabled={this.state.running} className="button-primary" text={i18n.t('campaigns:reports.run')} onClick={this.run} />


                        </UILIB.Paper>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default AddReport;

function Card({ icon, title, content, onClick, chip }) {
    return (
        <UILIB.Paper className="no-marg">
            <div style={{ display: 'flex', overflow: 'hidden', height: '100%', alignItems: 'center' }}>
                <div className="mar-r20"><UILIB.Icons icon={icon} circle={true} style={{ height: 32, width: 32 }} /></div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h4 style={{ marginBottom: 10 }}>{title}</h4>
                    <div>{content}</div>
                    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start', marginTop: 10 }}>
                        <UILIB.Button onClick={onClick} iconRight={<UILIB.Icons icon="arrowRight" />}>{i18n.t('campaigns:reports.select')}</UILIB.Button>
                    </div>
                </div>
            </div>
        </UILIB.Paper>
    )
}
import React, { Component } from 'react';

class OneTwocolumn extends Component {
    render() {
        var styles = {
            cls1: {
                fill: 'rgba(0,0,0,0.05)'
            },

            cls2: {
                fill: '#fff'
            },

            cls3: {
                fill: 'rgba(0,0,0,0.2)'
            },

            cls4: {
                fill: 'rgba(0,0,0,0.1)'
            },

            cls5: {
                filter: 'url(#Rectangle_202)'
            }
        }
        return (
            <svg className="svg-holder" id="Group_277" data-name="Group 277" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="16918 7101 253 257">
                <defs>
                    <filter id="Rectangle_202" x="16929" y="7107" width="230" height="251" filterUnits="userSpaceOnUse">
                        <feOffset dy="-5" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.039" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                </defs>
                <rect id="Rectangle_262" data-name="Rectangle 262" className="outer" width="253" height="253" rx="3" transform="translate(16918 7101)" />
                <g transform="matrix(1, 0, 0, 1, 0, 0)">
                    <path id="Rectangle_202-2" data-name="Rectangle 202" style={styles.cls2} d="M3,0H209a3,3,0,0,1,3,3V233a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(16938 7121)" />
                </g>
                <rect id="Rectangle_203" data-name="Rectangle 203" className="inner" width="40.25" height="40.25" rx="3" transform="translate(16949 7171)" />
                <path id="Rectangle_260" data-name="Rectangle 260" className="inner" d="M3,0H37.25a3,3,0,0,1,3,3V33.25a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(16949 7321)" />
                <rect id="Rectangle_246" data-name="Rectangle 246" className="inner" width="141.25" height="70" rx="3" transform="translate(16999 7171)" />
                <path id="Rectangle_261" data-name="Rectangle 261" className="inner" d="M3,0H138.25a3,3,0,0,1,3,3V33a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(16999 7321)" />
                <rect id="Rectangle_205" data-name="Rectangle 205" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16949 7231)" />
                <rect id="Rectangle_256" data-name="Rectangle 256" style={styles.cls4} width="141" height="4" rx="2" transform="translate(16999 7261)" />
                <rect id="Rectangle_209" data-name="Rectangle 209" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16949 7255)" />
                <rect id="Rectangle_258" data-name="Rectangle 258" style={styles.cls4} width="141" height="4" rx="2" transform="translate(16999 7285)" />
                <rect id="Rectangle_207" data-name="Rectangle 207" style={styles.cls4} width="40" height="4" rx="2" transform="translate(16949 7243)" />
                <rect id="Rectangle_257" data-name="Rectangle 257" style={styles.cls4} width="141" height="4" rx="2" transform="translate(16999 7273)" />
                <rect id="Rectangle_211" data-name="Rectangle 211" style={styles.cls4} width="20" height="4" rx="2" transform="translate(16949 7267)" />
                <rect id="Rectangle_259" data-name="Rectangle 259" style={styles.cls4} width="106" height="4" rx="2" transform="translate(16999 7297)" />
                <rect id="Rectangle_219" data-name="Rectangle 219" className="inner" width="192" height="20" rx="3" transform="translate(16948 7131)" />
            </svg>
        );
    }
}

export default OneTwocolumn;
import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { inboxTabOptions } from "~/Classes/conversations";
import { handleGenericError } from "~/data/actions/siteActions";
import InboxTemplate from "./template";

export default function InboxSettings({ userRole = null }) {
  const { path, url } = useRouteMatch();
  const { inboxId } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [inbox, setInbox] = useState(null);

  useEffect(() => {
    if (userRole > 0) return window.open("/", "_self");
    loadInitialData();
  }, []);

  async function loadInitialData() {
    try {
      await loadInbox();
    } finally {
      setLoading(false);
    }
  }

  async function loadInbox() {
    try {
      const { data } = await axios.get(`/inboxes/${inboxId}`);
      setInbox(data);
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }

  if (loading) return <UILIB.LoadingIcons className="mar-t24" />;

  return (
    <Switch>
      <Route exact path={`${path}/:settingName`}>
        <InboxTemplate inbox={inbox} onUpdateName={loadInbox} />
      </Route>

      {/* Redirect if "naked" route */}
      <Route exact path={path}>
        <Redirect to={`${url}/${inboxTabOptions[0].value}`} />
      </Route>
    </Switch>
  );
}

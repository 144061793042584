import React, { useEffect, useState } from 'react'
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios';
import textFunctions from '~/Classes/textFunctions';
import myBackgroundImage from '~/assets/images/misc/iPhone.png'

async function getTemplate(id) {
    const result = await axios.get('/template/' + id)
    return result.data.template
}

export default function ({ from, timestamp, content, size = "", templateId, refresh }) {
    const [template, setTemplate] = useState(null)

    useEffect(() => {
        if (templateId) {
            getTemplate(templateId).then(setTemplate)
        }
    }, [refresh, templateId])

    const containerStyle = {
        backgroundImage: `url(${myBackgroundImage})`,
        backgroundSize: 'cover', // Adjusts the size of the image
        backgroundPosition: 'center', // Centers the image
        backgroundColor: 'transparent',
        width: 300, // Example width
        border: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
      };

    if (content === null && !template) return <UILIB.LoadingIcons iconType="2" />

    const divideTextByParts = (text, numParts) => {
        const words = text.split(' '); // Split the content into words
        const partSize = Math.ceil(words.length / numParts); // Calculate the size of each part
      
        // Create an array of divided parts
        const dividedParts = [];
        for (let i = 0; i < words.length; i += partSize) {
          dividedParts.push(words.slice(i, i + partSize).join(' '));
        }
      
        return dividedParts;
      }

    const contentLength = Math.round(content.length/120);

    let html = ((template ? textFunctions.checkForHtml(template.templateHTML) : textFunctions.checkForHtml(content)) || '').replace(/\n/gi, '<br />')

    let htmlArray = divideTextByParts(html, contentLength)

    let contentReturn = htmlArray.map(element => {
                            return <div className={"smsContent " + size}>
                                        <div dangerouslySetInnerHTML={{ __html: element }}></div>
                                    </div>
                        });

    return (
        <div>
            <div className={"smsView " + size} style={containerStyle}>
                <div className="smsHeader" style={{position: 'relative', border: 0, top: 97, left: 24, width: '84%', padding: 0}}>
                    {!!from && <div className='title' style={{fontSize: 8, fontWeight: '500', lineHeight: '12px'}}>
                        {from}
                    </div> }
                    {!from && <div className='title' style={{fontSize: 8, fontWeight: '500', lineHeight: '12px'}}>
                        {'unknown'}
                    </div>}
                </div>

                <div className="smsMessageHolder">
                    <div className="smsMessages">
                        {contentReturn}
                    </div>
             </div>
            </div>
            <div style={{textAlign: '-webkit-center', color: 'var(--grey-700)',  textAlign: 'center', fontSize: 12}}>
                <div>This preview is only an approximation of how your message  will look.</div>
                <div>Your SMS may appear slightly different on each device</div>
            </div>
        </div>
    )
}
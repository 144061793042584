import React, { useState } from 'react'
import UILIB from '../../../../../Common-Objects/Lib'
import { useHistory, useParams } from 'react-router-dom';
import i18n from '~/i18n';

export default function SiteAnalyzerFinished({ job, setJob = () => { }, back = () => { }, next = () => { } }) {

    const history = useHistory()

    const exit = (goToTemplates) => {
        if (goToTemplates) {
            //go to the branded group
            history.push("/cp/templates/add/1/1")
            return;
        }

        history.push("/");

    }

    const params = new URLSearchParams(window.location.search)
    let forwardUrl = params.get('forward')

    return (

        <UILIB.Row>
            <UILIB.Column xs={12} sm={10} className="col-sm-offset-1">
                <h4 className="mar-b10 text-center">
                    {i18n.t('templates:brandAnalyzer.finishedPage.heading')}
                </h4>
                <div className="mar-b25 text-center">
                    {i18n.t('templates:brandAnalyzer.finishedPage.subHeading')}
                </div>

                <div className="quickFlex mar-b25" style={{ justifyContent: "space-between", alignItems: "center", width: "100%", maxWidth: "700px", margin: "0 auto" }}>
                    <div className="mar-r15 ">
                        <img className="mar-b25" src="https://cdn1.ourmailsender.com/siteContent/brandAnalyzer/finished_image1.png" style={{ width: "100%" }} />
                        <div className="text-heavy text-center">{i18n.t('templates:brandAnalyzer.finishedPage.weveCreatedBrandedTemplates')}</div>
                    </div>
                    <div className="mar-l15">
                        <img className="mar-b25" src="https://cdn1.ourmailsender.com/siteContent/brandAnalyzer/finished_image2.png" style={{ width: "100%" }} />
                        <div className="text-heavy text-center">{i18n.t('templates:brandAnalyzer.finishedPage.youCanEditCompanyInformation')}</div>
                    </div>
                </div>

                <div className="quickFlex" style={{ justifyContent: "center" }}>
                    {!forwardUrl && <>
                        <UILIB.Button text={i18n.t('templates:brandAnalyzer.finishedPage.backToDashboard')} className="button-primary mar-r5" onClick={() => { exit(false) }} />
                        <UILIB.Button text={i18n.t('templates:brandAnalyzer.finishedPage.exploreEmailTemplates')} className="button-primary mar-l5" onClick={() => exit(true)} />
                    </>
                    }
                    {forwardUrl && <>
                        <UILIB.Button text={i18n.t('templates:brandAnalyzer.finishedPage.continue')} iconRight={<UILIB.Icons icon="arrowRight" />} className="button-primary mar-l5" onClick={() => history.push(forwardUrl)} />
                    </>}
                </div>

            </UILIB.Column >

        </UILIB.Row >
    )
}
import React from 'react'

export default class Radio extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var classes = "radio-container";
        if (this.props.className) {
            classes += " " + this.props.className;
        }
        if (this.props.disabled) {
            classes += " disabled";
        }
        return <label className={classes}>{!this.props.right && this.props.label}
            {this.props.disabled && <input type="radio" name={this.props.name} value={this.props.value} checked={this.props.checked} disabled />}
            {!this.props.disabled && <input type="radio" name={this.props.name} value={this.props.value} onChange={this.props.onChange} checked={this.props.checked} />}
            <span className="checkmark"></span> {!!this.props.right && this.props.label}
        </label>
    }
}

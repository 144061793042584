import React from 'react'

export default function IconHiddenInput({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.5 7.00006C17.5 6.86745 17.4473 6.74028 17.3536 6.64651C17.2598 6.55274 17.1326 6.50006 17 6.50006L3 6.50006C2.86739 6.50006 2.74022 6.55274 2.64645 6.64651C2.55268 6.74028 2.5 6.86745 2.5 7.00006L2.5 13.0001C2.5 13.1327 2.55268 13.2598 2.64645 13.3536C2.74021 13.4474 2.86739 13.5001 3 13.5001L17 13.5001C17.1326 13.5001 17.2598 13.4474 17.3536 13.3536C17.4473 13.2598 17.5 13.1327 17.5 13.0001L17.5 7.00006ZM18.4142 5.58585C18.7893 5.96092 19 6.46963 19 7.00006L19 13.0001C19 13.5305 18.7893 14.0392 18.4142 14.4143C18.0391 14.7893 17.5304 15.0001 17 15.0001L3 15.0001C2.46957 15.0001 1.96086 14.7893 1.58579 14.4143C1.21071 14.0392 1 13.5305 1 13.0001L1 7.00006C1 6.46963 1.21071 5.96092 1.58579 5.58585C1.96086 5.21077 2.46957 5.00006 3 5.00006L17 5.00006C17.5304 5.00006 18.0391 5.21077 18.4142 5.58585Z" fill={fillColor} />
        </svg>
    )
}
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import UILIB from '../../../../../Common-Objects/Lib';
import i18n from '~/i18n'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import FileManager from '~/pages/cp/includes/fileManager/fileManager'
import DrawerAddCampaignStep1 from '~/pages/cp/campaigns/add/drawerAddCampaignStep1';
import BrandAnaylzerComponent from '../../brandanalyzer';
import { alterFullpage } from '../../../../../data/actions/siteActions';

export default function BBTopNavNavigation({ counts }) {
  const [tabIndex, setTabIndex] = useState(0)
  const history = useHistory()
  const location = useLocation();
  const dispatch = useDispatch()
  const account = useSelector(state => state.accountMaster)
  const site = useSelector(state => state.siteMaster)


  const setInitialTab = () => {
    var index = 0;
    var theCurrPath = location.pathname.split("/");

    if (theCurrPath.length > 1) {
      switch (theCurrPath[2]) {
        case 'campaigns':
        case 'templates':
          index = 2
          break;
        case 'subscribers':
        case 'groups':
        case 'subscriber':
          index = 1;
          break;
        case 'automation':
          index = 3;
          break;
        case 'forms':
        case 'trackers':
          index = 4;
          break;
        case 'conversations':
          index = 5;
          break;
      }
    }

    if (tabIndex !== index) {
      setTabIndex(index)
    }
  }

  const tabChange = (index, returnUrl) => {
    switch (index) {
      case 0:
        if (returnUrl) return '/cp/home';
        history.push('/cp/home');
        break;
      case 2:
        if (returnUrl) return '/cp/campaigns';
        history.push('/cp/campaigns');
        break;
      case 1:
        if (returnUrl) return '/cp/subscribers';
        history.push('/cp/subscribers');
        break;
      case 3:
        if (returnUrl) return '/cp/automation';
        history.push('/cp/automation')
        break;
      case 4:
        if (returnUrl) return '/cp/forms';
        history.push('/cp/forms')
        break;
      case 5:
        if (returnUrl) return '/cp/conversations';
        if (account?.accountMaster?.beta?.chat) {
          return window.open('/cp/conversations/inbox', '_self')
        }
        history.push('/cp/conversations');
        break;
    }
    setTabIndex(index)
  }

  const showFileManager = () => {
    const theDrawer = <FileManager fileTypes={"all"} />
    dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '100vw', 'fix-height'));
  }

  const startCampaign = () => {
    const theDrawer = <DrawerAddCampaignStep1 history={history} />
    dispatch(siteMaster.alterSiteDrawer(true, false, "right", theDrawer, true, 500, "drawer_nopad"));
  }

  const runSiteAnalyser = () => {
    dispatch(siteMaster.alterSiteDrawer(true, true, 'right', <BrandAnaylzerComponent />, true, '100vw'))
  }

  const showCampaignCreate = (type) => {
    const theDrawer = <DrawerAddCampaignStep1 history={history} campaignType={type} />
    dispatch(siteMaster.alterSiteDrawer(true, false, "right", theDrawer, true, 500, "drawer_nopad"));
  }

  const socialTeaser = () => {
    const content = <UILIB.LabsEnable labId={9} onClose={(labAdded) => {
      dispatch(alterFullpage(undefined))
      if (labAdded) showCampaignCreate(30)
    }} includeLearnMore={true} />
    dispatch(alterFullpage(content))
  }

  useEffect(() => {
    setInitialTab()
  }, [])

  useEffect(() => {
    setInitialTab()
  }, [location])

  const subscrberRoutes = [{
    label: i18n.t('nav:dropdown.subscribers.all'),
    route: '/cp/subscribers',
    icon: <UILIB.Icons icon="avatars2" style={{ width: 20, height: 20 }} color="#A9A9AD" />
  }, {
    label: i18n.t('nav:dropdown.subscribers.groups'),
    route: '/cp/subscribers/groups',
    icon: <UILIB.Icons icon="avatars3" style={{ width: 20, height: 20 }} color="#A9A9AD" />
  }, {
    label: i18n.t('nav:dropdown.subscribers.segments'),
    route: '/cp/subscribers/segments',
    icon: <UILIB.Icons icon="filter" style={{ width: 20, height: 20 }} color="#A9A9AD" />
  }, {
    label: i18n.t('nav:dropdown.subscribers.blacklist'),
    route: '/cp/groups/' + account.accountMaster.blackListGroupId,
    icon: <UILIB.Icons icon="crossCircle" style={{ width: 20, height: 20 }} color="#A9A9AD" />,
  }]

  const campaignRoutes = [{
    label: "All Campaigns",
    route: '/cp/campaigns',
    icon: <UILIB.Icons icon="paperPlane" style={{ width: 20, height: 20 }} color="#A9A9AD" />
  }, {
    label: "Email",
    route: '/cp/campaigns?campaignType=1',
    icon: <UILIB.Icons icon="envelope" style={{ width: 20, height: 20 }} color="#A9A9AD" />
  }, {
    label: "A/B Split",
    route: '/cp/campaigns?campaignType=10',
    icon: <UILIB.Icons icon="split" style={{ width: 20, height: 20 }} color="#A9A9AD" />
  }, {
    label: "Social",
    route: account.accountMaster?.beta?.social ? '/cp/campaigns?campaignType=30' : false,
    action: socialTeaser,
    icon: <UILIB.Icons icon="share" style={{ width: 20, height: 20 }} color="#A9A9AD" />,
    mpz: true
  }, {
    label: "SMS",
    route: account.accountMaster?.beta?.sms ? '/cp/campaigns?campaignType=6' : false,
    action: () => showCampaignCreate(6),
    icon: <UILIB.Icons icon="sms" style={{ width: 20, height: 20 }} color="#A9A9AD" />,
    mpz: true
  }, {
    label: "Ads",
    route: account.accountMaster?.beta?.ads ? '/cp/campaigns?campaignType=60' : false,
    action: () => showCampaignCreate(60),
    icon: <UILIB.Icons icon="megaphone" style={{ width: 20, height: 20 }} color="#A9A9AD" />,
    mpz: true,
    beta: 'ads'
  }, {
    divider: true
  }, {
    label: i18n.t('nav:templates'),
    route: '/cp/templates',
    icon: <UILIB.Icons icon="template" style={{ width: 20, height: 20 }} color="#A9A9AD" />
  }]

  const websiteRoutes = [{
    label: 'Forms',
    route: '/cp/forms',
    icon: <UILIB.Icons icon="stack" style={{ width: 20, height: 20 }} color="#A9A9AD" />
  }, {
    label: 'Tracking',
    route: '/cp/trackers',
    icon: <UILIB.Icons icon="globe" style={{ width: 20, height: 20 }} color="#A9A9AD" />
  }]


  let supportLabel = i18n.t('nav:interact');
  if (counts && counts.support) {
    supportLabel = <>{i18n.t('nav:interact')} <UILIB.CircularChip className="secondary circularChip-smallText mar-l10">{counts.support}</UILIB.CircularChip></>;
  }

  const isMpz = site.siteId == 1;

  return <div className="mob-hide">
    <UILIB.Tabs value={tabIndex} onChange={tabChange} supportReturnUrl={true}>

      <UILIB.Tab label={i18n.t('nav:dashboard')} />
      <UILIB.Tab permission="subscribers" label={i18n.t('nav:subscribers')} sub={subscrberRoutes} />
      <UILIB.Tab permission="campaigns" label={i18n.t('nav:campaigns')} sub={campaignRoutes} />
      {/* <UILIB.Tab permission="templates" label={i18n.t('nav:templates')} /> */}
      <UILIB.Tab permission="automations" label={i18n.t('nav:automation')} />
      <UILIB.Tab permission="forms" label={i18n.t('nav:Websites')} sub={websiteRoutes} />
      {isMpz && <UILIB.Tab permission="subscribers" label={<>
        Conversations
        <UILIB.SquareChip className="mar-l5 square-chip-green">Beta</UILIB.SquareChip>
      </>} />}
    </UILIB.Tabs>
  </div >
}
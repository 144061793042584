import React, { Component } from 'react'
import UILIB from '~/Common-Objects/Lib'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import axios from '~/data/http/axios';
import TagSelect from '../../../../../../../../Common-Objects/UI/TagSelect';
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})


export default class ComponentTagManager extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tags: [],
            selectedTags: []
        }
        this.findTags = this.findTags.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        if (this.props.value != prevProps.value) this.init();
    }

    async init() {
        let tags = await axios.get('/tags?tagType=1')
        let selectedTags = await axios.post('/tags/linktag/search', { link: this.props.value })
        this.setState({ tags: tags.dat, selectedTags: selectedTags.data })
    }

    findTags() {
        axios.post('/tags/linktag/search', { link: this.props.value }).then(res => this.setState({ selectedTags: res.data }))
    }

    add(TagId) {
        axios.post('/tags/linktag', {
            TagId: TagId,
            link: this.props.value
        }).then(this.findTags)
    }

    remove(TagId) {
        const found = this.state.selectedTags.find(t => t.TagId === TagId)
        if (found) {
            axios.delete('/tags/linktag/' + found.id).then(this.findTags)
        } else {
        }
    }

    render() {
        var style = {};
        if (this.props.elementData?.optionFullWidth || this.props.optionFullWidth) style = { flex: "100%" }
        let helpText = this.props.elementData?.helpText ? i18n.t(this.props.elementData.helpText) : "";

        return <>
            <div className="dd_toolHolder" style={style}>
                <div className="dd_inputTool">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="txtInput_label">{this.props.title}</div>
                        {helpText && <div><UILIB.Icons icon="questionCircle" alt={helpText} title={helpText} /></div>}
                    </div>
                    <TagSelect selected={this.state.selectedTags.map(t => t.TagId)} tagType={1} add={this.add.bind(this)} remove={this.remove.bind(this)} explainer={this.state.selectedTags.length ? "Removing tags will remove them from contacts who have already clicked this link" : ''} />
                </div>
            </div>

        </>
    }
}

import React from 'react'
import ReactDOM from 'react-dom';

export default class bb_dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false, ourWidth: 0, offSet: 0, align: this.props.align || 'left' };
        this.handleClick = this.handleClick.bind(this);
        this.checkListener = this.checkListener.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.outerCont = React.createRef();
    }

    componentDidUpdate() {
        if (this.props.hasOwnProperty('isOpen') && this.props.isOpen !== this.state.isOpen) {
            this.setState({ isOpen: this.props.isOpen })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.isOpen != this.state.isOpen) {
            this.setState({ isOpen: nextState.isOpen }, () => { this.checkListener(); });
            return true;
        }
        if (nextProps != this.props) {
            return true;
        }
        if (nextState.align !== this.state.align) return true
        return false;
    }
    componentDidMount() {
        var ourWidth = this.outerCont.offsetWidth;

        this.setState({ isOpen: this.props.hasOwnProperty('isOpen') ? !!this.props.isOpen : true, ourWidth: ourWidth }, () => { this.checkListener(); });
    }

    checkListener() {
        this.handleResize();
        if (this.state.isOpen === true) {
            document.addEventListener('click', this.handleClick, false);
        }
        else {
            document.removeEventListener('click', this.handleClick, false);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick, false);
    }

    handleClick(e) {
        //prevents parent click if needed
        if (this.props.preventDefault === true) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (!ReactDOM.findDOMNode(this).contains(e.target) || (e.target.localName === "a" && !this.props.preventAutoClose)) {
            this.setState({ isOpen: false });
            this.props.onClose(this.props.id);
        }
    }

    handleResize() {
        let documentWidth = window.width || document.documentElement.clientWidth || document.body.clientWidth;;
        let ourDd = this.outerCont.current;
        let ddPosition = ourDd.getBoundingClientRect();

        let ddRight = ddPosition.x + ourDd.clientWidth;

        if (ddRight > documentWidth) {
            this.setState({ align: 'right' })
        }
    }

    render() {
        var theStyle = this.props.style ? JSON.parse(JSON.stringify(this.props.style)) : {};

        if (this.props.align === "left") {
            theStyle.left = 0;
            theStyle.right = "unset"
        }
        if (this.props.align === "right") {
            theStyle.right = 0;
            theStyle.left = "unset";
        }

        if (this.props.top) {
            theStyle.top = this.props.top;
        }

        if (this.props.width) {
            theStyle.width = this.props.width;
        }

        theStyle.display = "none";
        if (this.state.isOpen === true || this.props.hasOwnProperty('isOpen')) {
            theStyle.display = this.props.display || "block";
        }

        let classes = "topnav-dropdown " + this.props.className + ' '
        if (this.state.isOpen) classes += ' open'

        return <div className="pos-rel">
            <div style={theStyle} className={classes} ref={this.outerCont}>
                {this.props.children}
            </div>
        </div>

    }
}
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import axios from '../../../../data/http/axios';
import { alterSiteDrawer } from '../../../../data/actions/siteActions';
import { connect } from "react-redux";
@connect((store) => { return {}; })
class DrawerStepRemove extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteType: 0,
            subscribers: 0,
            ready: false,
            currentContacts: this.props.currentContacts || 'ignore',
            destination: this.props.destination || '',
            processing: false
        }
    }

    componentDidMount() {
        if (this.props.status === 'Paused' && this.props.guid && this.props.AutomationId) {
            axios.get(`/automation/${this.props.AutomationId}/v2/steps/${this.props.guid}/subscribers`).then(res => {
                this.setState({
                    ready: true,
                    subscribers: res.data.total
                })
            })
        } else {
            this.setState({ ready: true })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentContacts === 'move' && this.state.currentContacts !== 'move') {
            this.props.removeHighlight()
            this.setState({ destination: '' })
        }
    }

    render() {

        if (!this.state.ready) return <UILIB.LoadingIcons />

        let explainer = ''
        if (this.state.currentContacts === 'remove') explainer = "Removed contacts will be able to enter this Automation again"
        if (this.state.currentContacts === 'ignore') explainer = "Ignored contacts will not continue in this Automation"
        if (this.state.currentContacts === 'move') explainer = "Moved contacts will enter that step when the Automation is resumed"
        const currentContacts = this.state.subscribers ? <div className="mar-b25">
            <div className="mar-b10">There are {this.state.subscribers} contacts on this step, they will not continue in this automation if you delete this step. What would you like to do with them?</div>
            <div className="quickFlex" >
                <UILIB.Select outerstyle={{ flexGrow: 1 }} data={[
                    { label: 'Ignore these contacts', value: 'ignore' },
                    { label: 'Remove the contacts from the automation', value: 'remove' },
                    { label: 'Move the contacts to another step', value: 'move' }
                ]} value={this.state.currentContacts} onChange={e => this.setState({ currentContacts: e.target.value })} />
                {this.state.currentContacts === 'move' && <UILIB.Button className="mar-l10" onClick={() => this.props.chooseAStepAndReturn(this.props.step)}>{this.state.destination ? 'Change Step' : 'Select Step'}</UILIB.Button>}
            </div>
            <div className="txtInput_explainer">{explainer}</div>
        </div> : ''

        var thisStep = this.props.parentStep[this.props.dataId]
        if (thisStep.type == "condition") {
            return <div>
                <h4 className="mar-b25">{i18n.t('automation:add.remove.header')}</h4>
                <div className="mar-b10">You are about to remove a condition step.</div>
                <div className="mar-b25">If the step has children you can choose to merge either the YES or NO children into the current branch.</div>

                <UILIB.Select outerClassName="mar-b25" data={[
                    { label: "Remove this step and all of it's children", value: 0 },
                    { label: "Remove this step but keep children in the YES branch", value: 1 },
                    { label: "Remove this step but keep children in the NO branch", value: 2 },
                ]} name="deleteType" value={this.state.deleteType} onChange={(ev) => { this.setState({ deleteType: ev.target.value }) }} />

                {currentContacts}

                <UILIB.Button
                    disabled={this.state.currentContacts === 'move' && !this.state.destination}
                    className="button-primary"
                    text={i18n.t('automation:add.remove.confirm')}
                    onClick={() => { this.setState({ processing: true }), this.props.confirmRemoveStep(this.props.parentStep, this.props.dataId, this.state.deleteType) }}
                />
                {this.state.processing && <UILIB.LoadingIcons />}
            </div >
        }
        return <div>
            <h4 className="mar-b25">{i18n.t('automation:add.remove.header')}</h4>
            <div className={"mar-b25"}>{i18n.t('automation:add.remove.subheader')}</div>
            {currentContacts}
            <div className="quickFlex">
                <UILIB.Button
                    disabled={this.state.currentContacts === 'move' && !this.state.destination}
                    className="button-primary" text={i18n.t('automation:add.remove.confirm')}
                    onClick={() => { this.setState({ processing: true }), this.props.confirmRemoveStep(this.props.parentStep, this.props.dataId, null, this.state.currentContacts, this.state.destination) }}
                />
                <UILIB.Button text={i18n.t('automation:add.remove.cancel')} className="mar-l10" onClick={() => {
                    this.props.dispatch(alterSiteDrawer(false, true, "right", null));
                }} />
            </div>
            {this.state.processing && <UILIB.LoadingIcons />}
        </div>
    }
}

export default DrawerStepRemove;
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'

@connect((store) => {
    return {
        siteMaster: store.siteMaster,
        accountMaster: store.accountMaster
    };
})

class HelpPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            content: [
                {
                    type: "trigger",
                    header: "About Triggers",
                    description: `Triggers are the first \"steps\" that appear in your automation, and define which contacts are added to your automation. 
                    Once a contact matches the criteria of a trigger, they will be added to the automation and will begin to progress through the steps.`,
                    video: 'https://cdn1.ourmailsender.com/siteContent/help/automation/videos/automationTriggers.mp4',
                    helpLink: 'https://kb.transpond.io/article/146-how-to-use-automations'
                },
                {
                    type: "action",
                    header: "About Actions",
                    description: `Action steps allow you to make a change to the contact. For example updating a field, changing their group, or adding a tag.`,
                    video: 'https://cdn1.ourmailsender.com/siteContent/help/automation/videos/automationAction.mp4',
                    helpLink: 'https://kb.transpond.io/article/146-how-to-use-automations'
                },
                {
                    type: "condition",
                    header: "About Conditions",
                    description: `Condition steps allow you to check if a contact meets a certain criteria. 
                    For example, if they have a certain tag, or if they have a certain field value.
                    Condition steps split your automation into two paths. A \"Yes\" and \"No\" path. 
                    If the contact meets the criteria, they will progress down the \"Yes\" path, otherwise they will progress down the \"No\" path.
                    `,
                    video: 'https://cdn1.ourmailsender.com/siteContent/help/automation/videos/automationCondition.mp4',
                    helpLink: 'https://kb.transpond.io/article/146-how-to-use-automations'
                },
                {
                    type: "delay",
                    header: "About Delays",
                    description: `Delay steps allow you to pause the contact's progress through the automation for a certain amount of time.`,
                    video: 'https://cdn1.ourmailsender.com/siteContent/help/automation/videos/automationDelay.mp4',
                    helpLink: 'https://kb.transpond.io/article/146-how-to-use-automations'
                },
                {
                    type: "email",
                    header: "About Emails",
                    description: `Email steps allow you to send an email to the contact.`,
                    video: 'https://cdn1.ourmailsender.com/siteContent/help/automation/videos/automationEmail.mp4',
                    helpLink: 'https://kb.transpond.io/article/146-how-to-use-automations'
                },
                {
                    type: "goal",
                    header: "About Goals",
                    description: `Goal steps allow you to stop a contact's progress through the automation 
                    if they meet a certain criteria, including simply reaching the goal step itself.
                    `,
                    video: 'https://cdn1.ourmailsender.com/siteContent/help/automation/videos/automationGoal.mp4',
                    helpLink: 'https://kb.transpond.io/article/146-how-to-use-automations'
                },
                {
                    type: "notification",
                    header: "About Notifications",
                    description: `Notification steps allow you to send a custom email notification to an email address of your choice.`,
                    video: 'https://cdn1.ourmailsender.com/siteContent/help/automation/videos/automationNotification.mp4',
                    helpLink: 'https://kb.transpond.io/article/146-how-to-use-automations'
                },
                {
                    type: "redirect",
                    header: "About Redirects",
                    description: `Redirect steps allow you to redirect the contact to a different step in the automation. Useful for moving contacts between different paths.`,
                    video: 'https://cdn1.ourmailsender.com/siteContent/help/automation/videos/automationRedirect.mp4',
                    helpLink: 'https://kb.transpond.io/article/146-how-to-use-automations'
                },
                // {
                //     type: "sms",
                //     header: "About SMS",
                //     description: `SMS steps allow you to send an SMS message to the contact.`,
                //     video: ''
                // },
                {
                    type: "split",
                    header: "About Splits",
                    description: `Split steps allow you to split your automation into two or more paths. 
                    Contacts are then randomly sent down each path.
                    A split step is a great way to implement A/B testing in your automation for example.`,
                    video: 'https://cdn1.ourmailsender.com/siteContent/help/automation/videos/automationSplit.mp4',
                    helpLink: 'https://kb.transpond.io/article/146-how-to-use-automations'
                },
                {
                    type: "wait",
                    header: "About Waits",
                    description: `Wait steps allow you to pause the contact's progress through the automation until a certain criteria is met. 
                    A contact will not progress from a wait step until the criteria is met.`,
                    video: 'https://cdn1.ourmailsender.com/siteContent/help/automation/videos/automationWait.mp4',
                    helpLink: 'https://kb.transpond.io/article/146-how-to-use-automations'
                },
                {
                    type: "webhook",
                    header: "About Webhooks",
                    description: `Webhook steps allow you to send a HTTP POST request to a URL of your choice.`,
                    video: 'https://cdn1.ourmailsender.com/siteContent/help/automation/videos/automationWebhook.mp4',
                    helpLink: 'https://kb.transpond.io/article/146-how-to-use-automations'
                },
                {
                    type: "score",
                    header: "About Scoring",
                    description: `Every contact has a score associated for them for each automation.
                                Score steps allow you to add or remove points from this score.
                                You can then use goal steps to action based on the score the contact has achieved.
                                `,
                    video: 'https://cdn1.ourmailsender.com/siteContent/help/automation/videos/automationScore.mp4',
                    helpLink: 'https://kb.transpond.io/article/146-how-to-use-automations'
                }
            ]
        }
    }

    render() {
        if (this.props.siteMaster.siteId != 1) return null
        let foundContent = this.state.content.find(contentItem => contentItem.type.toLowerCase() == this.props.type.toLowerCase())
        if (!foundContent) return null;

        // <UILIB.Icons icon={"questionCircle"} />
        return <div onClick={(ev) => { ev.preventDefault(); ev.stopPropagation(); }}>
            <UILIB.PopOver
                trigger={<UILIB.Button text={"Help"} className="button-small" iconRight={<UILIB.Icons icon="youtube" />} />}
                sideOffset={15}
                side={"left"}
                align={"top"}
                alignOffset={20}
                avoidCollisions={true}
            >
                <div style={{ width: 600 }}>
                    {foundContent.video && <div className="mar-b15" >
                        <UILIB.VideoPlayer loop={true} width={600} url={foundContent.video} playing={true} controls={true} style={{ backgroundColor: "black", border: "1px solid black" }} />
                    </div>}
                    <div>
                        <h4 className="mar-b10">{foundContent.header}</h4>
                        <div dangerouslySetInnerHTML={{ __html: foundContent.description }} />
                    </div>
                    {foundContent.helpLink && <div className="mar-t15">
                        <UILIB.Button text="Find out more" onClick={() => { window.open(foundContent.helpLink, "_blank") }} />
                    </div>}
                </div>
            </UILIB.PopOver>
        </div >
    }
}

export default HelpPopup;
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import i18n from '~/i18n';
const ignorePermissions = ["apikeys"]

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class CompanyRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoaded: false,
            role: {},
            permissions: [],
            readOnly: "",
            roleName: "",
            newName: "",
            roleCreatedAt: "",
            alertMessage: '',
            alertOpen: false,
            grouping: [],
            editName: false
        };
        this.loadRole = this.loadRole.bind(this);
        this.updatePermission = this.updatePermission.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.loadRole()
    }

    //Load Role
    loadRole() {
        var roleDat = [];
        axios.get('/role/' + this.props.match.params.roleID)
            .then((roleData) => {
                var createdAt = DateTimeFunctions.formatDateTime(roleData.data.Role.createdAt, 2);
                var permissions = roleData.data.Role.permissions
                var grouping = roleData.data.Role.grouping.sort((a, b) => {
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                })
                console.log(grouping)
                grouping.forEach((g, index) => {
                    g.color = "";
                    switch (g.name) {
                        case "company":
                            g.color = "blue";
                            break;
                        case "subscribers":
                            g.color = "purple";
                            break;
                    }
                })
                this.setState({ permissions: permissions, pageLoaded: true, readOnly: roleData.data.Role.readOnly, roleName: roleData.data.Role.name, roleCreatedAt: createdAt, grouping: grouping });
            })
            .catch(console.log);
    }

    save() {
        this.setState({
            alertMessage: 'Saving Role',
            alertOpen: true
        })

        axios.put('/role/' + this.props.match.params.roleID, { permissions: this.state.permissions }).then(() => {
            this.setState({
                alertMessage: 'Role Saved',
                alertOpen: true
            })
        }).catch(console.log)
    }

    updatePermission(permissionName, value) {

        var permissions = this.state.permissions;
        permissions[permissionName] = value;

        this.setState({
            permissions
        }, this.save)
    }

    saveName() {
        if (!this.state.newName.length) {
            this.setState({
                alertMessage: 'Role Name Required',
                alertOpen: true
            })
        } else {
            axios.put('/role/' + this.props.match.params.roleID, { name: this.state.newName }).then(() => {
                this.setState({
                    alertMessage: 'Role Saved',
                    roleName: this.state.newName,
                    alertOpen: true,
                    editName: false
                })
            }).catch(console.log)
        }
    }


    render() {


        if (this.state.pageLoaded === false) {
            return <div></div>
        }
        else {

            var cards = [];
            this.state.grouping.forEach((group, index) => {
                var permissionName = group.name;
                var parentpermission = this.state.permissions[permissionName]
                let parentClass = "";
                let parentColor = group.color;
                if (parentColor) parentClass += "cards-rolegroup-option-" + parentColor;

                cards.push(
                    <UILIB.Column xs={12} md={6} lg={3} key={"group-" + index}>
                        <UILIB.RoleGroupCard className={parentClass} permission={{ name: permissionName, value: parentpermission }} onChange={(value) => this.updatePermission(permissionName, value)} readonly={this.state.readOnly} />
                    </UILIB.Column>
                )

                group.children.forEach((child, childIndex) => {
                    if (ignorePermissions.indexOf(child) > -1) return;
                    var permissionName = child;
                    var permission = this.state.permissions[permissionName]
                    var readOnly = this.state.readOnly;
                    let cardStyle = {};
                    if (!readOnly && parentpermission == "none") {
                        readOnly = true;
                        parentClass += " cards-rolegroup-option-unavailable"
                        permission = "none";
                    }
                    cards.push(<UILIB.Column xs={12} md={6} lg={3} key={"groupChild-" + index + "_" + childIndex}>
                        <UILIB.RoleGroupCard className={parentClass} style={cardStyle} permission={{ name: permissionName, value: permission }} onChange={(value) => this.updatePermission(permissionName, value)} readonly={readOnly} />
                    </UILIB.Column>)
                })
            })
            return <div>

                <UILIB.Row>
                    <UILIB.Column xs={12} sm={12} md={6} lg={6} className="quickFlex mar-b25">
                        <UILIB.Hint
                            className="mar-0"
                            iconLeft={<UILIB.Icons icon="arrowLeft" />}
                            onClick={() => { this.props.history.goBack() }}>
                            {i18n.t('subscribers:subscriber.back')}
                        </UILIB.Hint>
                    </UILIB.Column>

                    <UILIB.Column xs={12} className="quickFlex" style={{ justifyContent: "space-between" }}>
                        <h4 className="mar-b25">
                            {!this.state.editName && <div className='quickFlex'>
                                {this.state.roleName}
                                {!this.state.readOnly && <UILIB.Icons onClick={() => this.setState({ editName: true, newName: this.state.roleName })} className="mar-l10 cursor-pointer" icon="pencil" />}
                            </div>}
                            {this.state.editName && <div className='quickFlex'>
                                <UILIB.TextInput value={this.state.newName} onChange={e => this.setState({ newName: e.currentTarget.value })} placeholder="Role Name" outerClassName="mar-r10" />
                                <UILIB.Button className="button-sml mar-r10" onClick={this.saveName.bind(this)}>Save</UILIB.Button>
                                <UILIB.Button className="button-sml" onClick={() => this.setState({ editName: false })}>Cancel</UILIB.Button>
                            </div>}
                            {this.state.readOnly && <UILIB.SquareChip
                                className="square-chip-grey mar-l10">
                                {i18n.t('company:roles.readOnly')}
                            </UILIB.SquareChip>}
                        </h4>
                        <UILIB.SquareChip
                            className="square-chip-grey mar-b25">
                            {i18n.t('created')} {this.state.roleCreatedAt}
                        </UILIB.SquareChip>

                    </UILIB.Column>

                    {cards}

                </UILIB.Row>



                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />

            </div >
        }
    };


};



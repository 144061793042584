import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n'
import { connect } from 'react-redux';

@connect((store) => {
    return { site: store.siteMaster, account: store.accountMaster }
})


class ChangeEmailTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div>

                <h4 className="mar-b25">{i18n.t('form:edit.template.header')}</h4>
                <div className="mar-b10">{i18n.t('form:edit.template.text1')}</div>
                <div className="mar-b10">{i18n.t('form:edit.template.text2')}</div>
                <div className="mar-b25">{i18n.t('form:edit.template.text3')}</div>

                <div className="quickFlex">
                    <UILIB.Button className="button-primary mar-r10" text={i18n.t('form:edit.template.dnd')} onClick={() => { this.props.goChangeEmailTemplate(0) }} />
                    <UILIB.Button text={i18n.t('form:edit.template.code')} onClick={() => { this.props.goChangeEmailTemplate(1) }} />
                </div>
            </div >
        );
    }
}

export default ChangeEmailTemplate;
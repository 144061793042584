import React from 'react'
import UILIB from '../../../../../../Common-Objects/Lib'

export default function EnableSendConfirmation({ onFinish = () => { } }) {
    return (
        <div>

            <h4 className="mar-b25">Enable Confirmation Emails</h4>
            <p>Once enabled contacts will need to confirm their email addresses before they are available for marketing emails</p>
            <p>A default confirmation email and confirmed landing page will be generated for you to edit</p>
            <p>This cannot be turned off once enabled</p>
            <div className="row">
                <div className="col-xs-6">
                    <UILIB.Button text="Enable" className="button-fullwidth button-primary" onClick={() => onFinish(true)} />
                </div>
                <div className="col-xs-6">
                    <UILIB.Button text="Cancel" className="button-fullwidth button-grey" onClick={() => onFinish(false)} />
                </div>
            </div>
        </div>
    )
}
import React from 'react'
import { Editor } from '@tinymce/tinymce-react';

export default class TextInput extends React.Component {
    constructor(props) {
        super(props);

        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    handleEditorChange(e, editor) {

        var content = editor.getContent();
        var resp = { currentTarget: { value: content } }
        this.props.onChange(resp);
    }
    render() {

        return <div className="textInputWrapper">
            <Editor
                ref="tinyMCEEditor"
                initialValue={this.props.value}
                init={{
                    plugins: 'mpzFileManager mpzMergeTags code advlist textcolor colorpicker emoticons imagetools  link paste spellchecker table hr anchor',
                    toolbar: 'code | bold italic underline strikethrough | forecolor backcolor | bullist numlist | outdent indent | table | link unlink | alignleft aligncenter alignright alignjustify | hr styleselect  fontselect fontsizeselect | mpzFileManager mpzMergeTags',
                    width: "100%",
                    statusbar: false,
                    menubar: false
                }}
                onExecCommand={(event) => { this.handleEditorCommand(event) }}
                onChange={this.handleEditorChange}
                onKeyUp={this.handleEditorChange}
            />

        </div>
    }
}

import React from 'react'

export default function IconsTag({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.707 9.29306C17.8945 9.48059 17.9998 9.7349 17.9998 10.0001C17.9998 10.2652 17.8945 10.5195 17.707 10.7071L10.707 17.7071C10.5195 17.8945 10.2652 17.9998 10 17.9998C9.73484 17.9998 9.48053 17.8945 9.293 17.7071L2.293 10.7071C2.2 10.6143 2.12624 10.5041 2.07596 10.3828C2.02568 10.2615 1.99986 10.1314 2 10.0001V5.00006C2 4.20441 2.31607 3.44135 2.87868 2.87874C3.44129 2.31613 4.20435 2.00006 5 2.00006H10C10.256 2.00006 10.512 2.09806 10.707 2.29306L17.707 9.29306ZM5 6.00006C5.26522 6.00006 5.51957 5.8947 5.70711 5.70717C5.89464 5.51963 6 5.26528 6 5.00006C6 4.73484 5.89464 4.48049 5.70711 4.29295C5.51957 4.10542 5.26522 4.00006 5 4.00006C4.73478 4.00006 4.48043 4.10542 4.29289 4.29295C4.10536 4.48049 4 4.73484 4 5.00006C4 5.26528 4.10536 5.51963 4.29289 5.70717C4.48043 5.8947 4.73478 6.00006 5 6.00006Z" fill={fillColor} />
        </svg>
    )
}
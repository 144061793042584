import React from 'react'

export default class IconDocument extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 4.00006C4 3.46963 4.21071 2.96092 4.58579 2.58585C4.96086 2.21077 5.46957 2.00006 6 2.00006H10.586C11.1164 2.00017 11.625 2.21096 12 2.58606L15.414 6.00006C15.7891 6.37505 15.9999 6.88367 16 7.41406V16.0001C16 16.5305 15.7893 17.0392 15.4142 17.4143C15.0391 17.7893 14.5304 18.0001 14 18.0001H6C5.46957 18.0001 4.96086 17.7893 4.58579 17.4143C4.21071 17.0392 4 16.5305 4 16.0001V4.00006ZM6 10.0001C6 9.73484 6.10536 9.48049 6.29289 9.29295C6.48043 9.10542 6.73478 9.00006 7 9.00006H13C13.2652 9.00006 13.5196 9.10542 13.7071 9.29295C13.8946 9.48049 14 9.73484 14 10.0001C14 10.2653 13.8946 10.5196 13.7071 10.7072C13.5196 10.8947 13.2652 11.0001 13 11.0001H7C6.73478 11.0001 6.48043 10.8947 6.29289 10.7072C6.10536 10.5196 6 10.2653 6 10.0001ZM7 13.0001C6.73478 13.0001 6.48043 13.1054 6.29289 13.293C6.10536 13.4805 6 13.7348 6 14.0001C6 14.2653 6.10536 14.5196 6.29289 14.7072C6.48043 14.8947 6.73478 15.0001 7 15.0001H13C13.2652 15.0001 13.5196 14.8947 13.7071 14.7072C13.8946 14.5196 14 14.2653 14 14.0001C14 13.7348 13.8946 13.4805 13.7071 13.293C13.5196 13.1054 13.2652 13.0001 13 13.0001H7Z" fill={color} />
        </svg>


    }
}        
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import SiteVars from '~/Classes/siteVars';
import { connect } from 'react-redux';
import TemplateImageLoader from '../add/templateImageLoader'
import EmailPreviewer from '~/pages/cp/includes/emailPreviewer/emailPreviewer'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
//CONNECT TO STORE
@connect((store) => {
    return { accountMaster: store.accountMaster }
})

class AutomationView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            automation: undefined,
            loaded: false,
            groups: [],
            segments: [],
            customFields: []

        }
        this.getAutomation = this.getAutomation.bind(this)
        this.loadGroups = this.loadGroups.bind(this);
        this.loadSegments = this.loadSegments.bind(this);
        this.loadCustomFields = this.loadCustomFields.bind(this);
        this.openEmailPreview = this.openEmailPreview.bind(this);
        this.pauseAutomation = this.pauseAutomation.bind(this);
        this.restartAutomation = this.restartAutomation.bind(this);
        this.showStepStats = this.showStepStats.bind(this);
        this.showStepCampaignStats = this.showStepCampaignStats.bind(this);
    }

    componentDidMount() {
        this.loadGroups()
    }

    loadGroups() {
        axios.get('/group?hideUsers=true&limit=1000').then(response => {
            this.setState({
                groups: response.data.Groups
            }, this.loadSegments)
        })
    }
    loadSegments() {
        axios.get('/segment?recordLimit=1000').then(response => {
            this.setState({
                segments: response.data.Segments
            }, this.loadCustomFields)
        })
    }

    loadCustomFields() {
        axios.get('/customfield').then(response => {
            this.setState({
                customFields: response.data.CustomFields.filter(c => !c.hidden)
            }, this.getAutomation)
        })
    }



    getAutomation() {
        axios.get('/automation/' + this.props.match.params.automationId).then(response => {
            var automation = response.data.Automation
            if (automation.steps && automation.steps.length) {
                automation.steps.forEach(step => {
                    if (step.CampaignId) {
                        var campaign = automation.Campaigns.find(c => c.id === step.CampaignId)
                        step.campaign = campaign
                    }
                })
            }
            this.setState({
                automation,
                AutomationId: this.props.match.params.automationId,
                loaded: true
            })
        })
    }

    openEmailPreview(templateId) {
        var drawerContent = <EmailPreviewer templateId={templateId} history={this.props.history} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
    }

    pauseAutomation() {
        axios.put('/automation/' + this.state.automation.id + '/pause').then(this.getAutomation)
    }

    restartAutomation() {
        axios.put('/automation/' + this.state.automation.id + '/resume').then(this.getAutomation)
    }

    showStepStats(stepId) {
        this.props.history.push("/cp/automation/view/" + this.state.automation.id + "/stats/" + stepId)
    }

    showStepCampaignStats(stepId, statsType) {
        this.props.history.push("/cp/automation/view/" + this.state.automation.id + "/campaignStats/" + stepId + "/" + statsType)
    }

    render() {
        if (!this.state.loaded) return <div><UILIB.LoadingIcons iconType="2" /></div>

        return <div>
            <UILIB.Row className="mar-t30">
                <UILIB.Column xs={12} sm={12} md={5} lg={4} className="col-md-offset-2 col-lg-offset-3">
                    <span className="nav-pageBackLink" onClick={() => { this.props.history.push('/cp/automation') }}>
                        <div className="icon">
                            <span className="icon-arrow-left"></span>
                        </div>
                        <p>Back to Automations</p>
                    </span>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={12} md={3} lg={2} className="end-xs">
                    {this.state.automation.status == 'Running' && <UILIB.Button text="Pause" className="button-sml outline grey with-icon" onClick={this.pauseAutomation}>Pause <span className="icon-pause-circle"></span></UILIB.Button>}
                    {this.state.automation.status == 'Paused' && <UILIB.Button text="Re-Start" className="button-sml outline primary with-icon" onClick={this.restartAutomation}>Restart <span className="icon-refresh"></span></UILIB.Button>}
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={8} lg={6} className="col-md-offset-2 col-lg-offset-3">

                    <UILIB.Row className="middle-xs">
                        <UILIB.Column xs={12} sm={12} md={6}>
                            <h4 className="mar-b25">{this.state.automation.name}</h4>
                        </UILIB.Column>
                        <UILIB.Column xs={12} sm={12} md={6} className="end-xs">
                            <div>
                                {this.state.automation.status == 'Running' && <p className="no-marg text-green">This automation is currently <span className="text-heavy">"Running"</span></p>}
                                {this.state.automation.status == 'Paused' && <p className="no-marg text-grey">This automation is currently <span className="text-heavy">"Paused"</span></p>}
                            </div>
                        </UILIB.Column>
                    </UILIB.Row>

                    <UILIB.Paper>
                        <UILIB.Row margin={0}>
                            <UILIB.Column xs={12} sm={12} md={1} margin={0}>
                                <div className="step-circle primary">
                                    <span className="text-primary">1</span>
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={12} md={11} margin={0}>
                                <p className="no-marg">
                                    Carry out the following automation(s) for contacts in the group
                                    <span className="text-heavy text-primary"> {this.state.groups.find(theGroup => theGroup.id == this.state.automation.GroupId).groupName}</span>
                                </p>

                                {this.state.automation.SegmentId &&
                                    <p className="no-marg">
                                        And the segment <span className="text-heavy text-primary">{this.state.segments.find(theSeg => theSeg.id == this.state.automation.SegmentId).name}</span>
                                    </p>
                                }
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>

                    {this.state.automation.steps.map((step, stepIndex) => {
                        console.log(step);
                        var stepShortDesc = "";
                        var stepDescription = "";
                        var backgroundImage = "";
                        switch (step.trigger) {
                            case "signup":
                                stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' ' + (step.delay.after == 1 ? " after " : "before")) + ' a contact is added to this group, ';
                                break;
                            case "datetime":
                                stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' ' + (step.delay.after == 1 ? " after " : "before")) + ' the date and time of the contacts "' + this.state.customFields.find(theField => theField.id == step.customFieldId).fieldDesc + '" field is reached, ';
                                break;
                            case "anniversary":
                                stepDescription = (step.delay.value == 0 ? "On " : step.delay.value + ' ' + step.delay.unit + ' ' + (step.delay.after == 1 ? " after " : "before")) + ' the aniversary of the contacts "' + this.state.customFields.find(theField => theField.id == step.customFieldId).fieldDesc + '", ';
                                break;
                            case "update":
                                stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' after') + ' a contacts "' + this.state.customFields.find(theField => theField.id == step.customFieldId).fieldDesc + '" field is update or changed, ';
                                break;
                            // case "link":
                            //     stepDescription = step.delay.value + ' ' + step.delay.unit + ' after a contact clicks the link "' + step.link + '", ';
                            //     break;
                            case "all":
                                stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' after ') + ' a contact has been sent the previous email, ';
                                break;
                            case "opened":
                                stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' after ') + ' a contact has opened the previous email, ';
                                break;
                            case "clicked":
                                stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' after ') + ' a contact has clicked the previous email, ';
                                break;
                            case "link":
                                stepDescription = (step.delay.value == 0 ? "When " : step.delay.value + ' ' + step.delay.unit + ' after ') + ' a contact has clicked the link "' + step.link + '" in the previous email, ';
                                break;
                        }


                        switch (step.action) {
                            case "email":
                                stepShortDesc = "Send an Email";
                                stepDescription += " send the following email " + (step.sendTime ? "at the next " + step.sendTime : "")
                                backgroundImage = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + step.campaign.TemplateId + ".jpg"

                                break;
                            case "update":
                                stepShortDesc = "Update a Contact Field";
                                stepDescription += ' set the field "' + this.state.customFields.find(theField => theField.id == step.actionUpdate).fieldDesc + '" with value "' + step.actionValue + '"' + (step.sendTime ? "at the next " + step.sendTime : "");
                                break;
                            case "addToGroup":
                                stepShortDesc = "Copy Contact";
                                stepDescription += ' copy/add the contact to group "' + this.state.groups.find(theGroup => theGroup.id == step.actionGroup).groupName + '"' + (step.sendTime ? "at the next " + step.sendTime : "");
                                break;
                            case "moveToGroup":
                                stepShortDesc = "Move the Contact";
                                stepDescription += ' move the contact to group "' + this.state.groups.find(theGroup => theGroup.id == step.actionGroup).groupName + '"' + (step.sendTime ? "at the next " + step.sendTime : "");
                                break;
                            case "removeFromGroup":
                                stepShortDesc = "Remove the Contact";
                                stepDescription += ' remove the contact if it exists from the  group  "' + this.state.groups.find(theGroup => theGroup.id == step.actionGroup).groupName + '"' + (step.sendTime ? "at the next " + step.sendTime : "");
                                break;
                            case "inactive":
                                stepShortDesc = "De-Activate the Contact";
                                stepDescription += ' set the contact to "In-Active"' + (step.sendTime ? "at the next " + step.sendTime : "");
                                break;
                            case "active":
                                stepShortDesc = "Activate the Contact";
                                stepDescription += ' set the contact to "Active"' + (step.sendTime ? "at the next " + step.sendTime : "");
                                break;
                            case "webhook":
                                stepShortDesc = "Send a webhook";
                                stepDescription += ' push a webhook to ' + step.webhookUrl;
                                break;

                        }


                        return <div>

                            <ul className="campaignSteps" style={{ height: "0" }}>
                                <li className="selected last vertical">
                                    <div className="vertical above" />
                                </li>
                            </ul>

                            <UILIB.Paper className="vertical" key={stepIndex}>

                                <UILIB.Row>
                                    <UILIB.Column xs={12} sm={1}>
                                        <div className="step-circle primary">
                                            <span className="text-primary">{stepIndex + 2}</span>
                                        </div>
                                    </UILIB.Column>
                                    <UILIB.Column xs={12} sm={11}>
                                        <UILIB.Row>
                                            <UILIB.Column xs={12} sm={7}>
                                                <h4 className="no-marg">{stepShortDesc}</h4>
                                                <p>{stepDescription}</p>
                                            </UILIB.Column>
                                            <UILIB.Column xs={12} sm={5}>
                                                <div className="quickFlex end-xs">
                                                    {step.action == "email" && <div className="pad-right"><UILIB.Button text={"Queued (" + (step.campaign.emailsQueued ? step.campaign.emailsQueued : 0) + ")"} className="button-sml outline primary" onClick={() => { this.showStepCampaignStats(stepIndex, 0) }} /></div>}
                                                    {step.action == "email" && <div className="pad-right"><UILIB.Button text={"Sent (" + (step.campaign.emailsSent ? step.campaign.emailsSent : 0) + ")"} className="button-sml outline primary" onClick={() => { this.showStepCampaignStats(stepIndex, 1) }} /></div>}
                                                    {step.action != "email" && step.hits > 0 && <div className="pad-right"><UILIB.Button text={"Processed (" + step.hits + ")"} className="button-sml outline primary" onClick={() => { this.showStepStats(stepIndex) }} /></div>}
                                                    {step.action != "email" && step.hits <= 0 && <div><UILIB.Button text={"Processed (" + step.hits + ")"} className="button-sml outline grey" disabled={true} /></div>}
                                                </div>
                                            </UILIB.Column>
                                        </UILIB.Row>
                                    </UILIB.Column>

                                </UILIB.Row>

                                {step.action == "email" &&
                                    <UILIB.Row margin={0} >
                                        <UILIB.Column xs={12} sm={1}>
                                        </UILIB.Column>
                                        <UILIB.Column xs={12} sm={9} >
                                            <UILIB.Row>
                                                <UILIB.Column xs={12} sm={6} margin={0}>
                                                    <div className="template-preview-sml" onClick={() => { this.openEmailPreview(step.campaign.TemplateId) }}>
                                                        <div className="magnifyIcon icon-magnifier icon24 text-white" />
                                                        <div className="templateImage">
                                                            <div className="templateImageHolder">
                                                                <TemplateImageLoader imageUrl={backgroundImage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </UILIB.Column>
                                                <UILIB.Column xs={12} sm={6} margin={0}>
                                                    <div className="pad-left-1">
                                                        <div className="form-group mar-b0">
                                                            <label htmlFor="" className="mar-b5">From Name:</label>
                                                            <span className="text-sml text-primary">{step.campaign.fromName}</span>
                                                            <hr className="light-hr" />
                                                        </div>
                                                        <div className="form-group mar-b0">
                                                            <label htmlFor="" className="mar-b5">From Email Address:</label>
                                                            <span className="text-sml text-primary">{step.campaign.fromAddress}</span>
                                                            <hr className="light-hr" />
                                                        </div>
                                                        <div className="form-group mar-b0">
                                                            <label htmlFor="" className="mar-b5">Reply Email Address:</label>
                                                            <span className="text-sml text-primary">{step.campaign.replyAddress}</span>
                                                            <hr className="light-hr" />
                                                        </div>
                                                        <div className="form-group mar-b0">
                                                            <label htmlFor="" className="mar-b5">Subject:</label>
                                                            <span className="text-sml text-primary">{step.campaign.subject}</span>
                                                        </div>
                                                    </div>
                                                </UILIB.Column>
                                            </UILIB.Row>
                                        </UILIB.Column>
                                        <UILIB.Column xs={12} sm={2} >

                                        </UILIB.Column>
                                    </UILIB.Row>
                                }
                            </UILIB.Paper>
                        </div>
                    })}

                </UILIB.Column>
            </UILIB.Row>

        </div >
    }
}

export default AutomationView;
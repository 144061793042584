export const AvailableColumns = [{
    group: 'General',
    label: 'audience',
    value: 'audience',
    selected: true
}, {
    group: 'General',
    label: 'folders',
    value: 'folders',
    selected: true,
    fixed: true,
    tierPermission: 'campaignFolders', // check if they have permission to see this column
    mpz: true // only show on site1
}, {
    group: 'Email Campaigns',
    label: 'opened',
    value: 'Unique Opens',
    campaignType: 'email',
    selected: true
}, {
    group: 'Email Campaigns',
    label: 'clicked',
    value: 'Unique Clicks',
    campaignType: 'email',
    selected: true
}, {
    group: 'Email Campaigns',
    label: 'bounces',
    value: 'Hard Bounces',
    campaignType: 'email',
    selected: true
}, {
    group: 'Email Campaigns',
    label: 'unsubscribes',
    value: 'Total Unsubscribes',
    campaignType: 'email',
    selected: true
}, {
    group: 'Social Campaigns',
    label: 'outreach',
    value: 'outreach',
    selected: true
}, {
    group: 'Social Campaigns',
    label: 'impressions',
    value: 'impressions',
    selected: true
}, {
    group: 'Social Campaigns',
    label: 'engagement',
    value: 'engagement',
    selected: true
}, {
    group: 'Ad Campaigns',
    label: 'spend',
    value: 'spend',
    selected: true
}, {
    group: 'Ad Campaigns',
    label: 'endDate',
    value: 'endDate',
    selected: true
}]
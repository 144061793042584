import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import AddStepHolder from './addStepHolder';

@connect((store) => {
    return { account: store.accountMaster }
})
class AddStepDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moveChildren: false,
            content: undefined,
            chosenType: "",
            newFeatureSelected: ""
        }

        this.chooseStepType = this.chooseStepType.bind(this);
    }
    chooseStepType(type, secondary) {
        if (type === 'condition' && !this.props.conditionPermission.allowed) {
            return this.setState({
                content: <div>
                    <UILIB.SquareChip className="mar-b25" iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={() => this.setState({ content: undefined })}>Go Back</UILIB.SquareChip>
                    <UILIB.TierLimitHolder
                        type="Condition Step"
                        err={this.props.conditionPermission.err}
                        allowedNumber={this.props.conditionPermission.allowedNumber}
                        tierName={this.props.conditionPermission.tierName}
                        onUpgrade={() => {
                            this.props.save().then(() => {
                                this.setState({ content: undefined })
                                this.props.history.push('/cp/company/subscription')
                            })
                        }}
                    />
                </div>
            })
        }
        if ((type == "condition" || type == 'split') && this.props.parentStep[this.props.dataId + 1] && !secondary) {
            return this.setState({ chosenType: type, moveChildren: true })
        }
        this.props.chosenStepType(type, this.props.parentStep, this.props.dataId, secondary);
    }

    render() {
        if (this.state.newFeatureSelected) {
            return <UILIB.NewFeatureFeedback
                featureType="automationStepType"
                featureSubType={this.state.newFeatureSelected}
                closeDrawer={() => this.setState({ newFeatureSelected: "" })}
                hideCloseCross={true}
            />
        }
        if (this.state.content) return this.state.content

        if (this.state.moveChildren) {
            return <div>
                <h4 className="mar-b25">{i18n.t('automation:add.addStep.move')}</h4>
                <div className="mar-b25">{i18n.t('automation:add.addStep.moveSub')}</div>
                <div className="quickFlex">
                    <UILIB.Button text={i18n.t('automation:add.addStep.moveYes')} className="button-primary mar-r10" onClick={() => { this.chooseStepType(this.state.chosenType, "yes") }} />
                    <UILIB.Button text={i18n.t('automation:add.addStep.moveNo')} onClick={() => { this.chooseStepType(this.state.chosenType, "no") }} />
                </div>
            </div>
        }

        var pipedrive = this.props.applications.find(a => a.Application.appName === 'Pipedrive')
        let generic = this.props.applications.filter(a => a.Application.options.hooks && a.Application.options.hooks.length && a.Application.options.hooks.some(h => h.automationaction))

        if (this.props.themeapp) {
            if (this.props.themeapp.options && this.props.themeapp.options.hooks && this.props.themeapp.options.hooks.length && this.props.themeapp.options.hooks.some(h => h.automationaction)) {
                generic = [{ ApplicationId: this.props.themeapp.id, Application: this.props.themeapp }]
            } else {
                generic = []
            }
        }

        var hasStepsAfter = false;
        if (this.props.parentStep[this.props.dataId + 1]) {
            hasStepsAfter = true;
        }

        return <div>
            <h4 className="mar-b5">{i18n.t('automation:add.addStep.add')}</h4>
            <div className="mar-b25">{i18n.t('automation:add.addStep.addSub')}</div>

            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6 mar-b15">
                        <AddStepHolder
                            icon={<UILIB.Icons icon="envelope" circle={true} />}
                            onClick={() => { this.chooseStepType("email") }}
                            title={i18n.t('automation:add.addStep.email')}
                            subTitle={i18n.t('automation:add.addStep.emailSub')}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 mar-b15">
                        <AddStepHolder
                            icon={<UILIB.Icons icon="mobile" circle={true} />}
                            onClick={() => {
                                if (this.props?.account?.accountMaster?.beta?.sms) {
                                    this.chooseStepType("sms")
                                }
                                else {
                                    this.setState({ newFeatureSelected: "SMS Automation" })
                                }
                            }}
                            title={i18n.t('automation:add.addStep.sms')}
                            subTitle={i18n.t('automation:add.addStep.smsSub')}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 mar-b15">
                        <AddStepHolder
                            icon={<UILIB.Icons icon="clock" circle={true} />}
                            onClick={() => { this.chooseStepType("delay") }}
                            title={i18n.t('automation:add.addStep.delay')}
                            subTitle={i18n.t('automation:add.addStep.delaySub')}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 mar-b15">
                        <AddStepHolder
                            icon={<UILIB.Icons icon="download" circle={true} />}
                            onClick={() => { this.chooseStepType("wait") }}
                            title={i18n.t('automation:add.addStep.wait')}
                            subTitle={i18n.t('automation:add.addStep.waitSub')}

                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 mar-b15">
                        <AddStepHolder
                            icon={<UILIB.Icons icon="branchDown" circle={true} />}
                            onClick={() => this.chooseStepType("condition")}
                            title={i18n.t('automation:add.addStep.condition')}
                            subTitle={i18n.t('automation:add.addStep.conditionSub')}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 mar-b15">
                        <AddStepHolder
                            icon={<UILIB.Icons icon="cog" circle={true} />}
                            onClick={() => { this.chooseStepType("action") }}
                            title={i18n.t('automation:add.addStep.action')}
                            subTitle={i18n.t('automation:add.addStep.actionSub')}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 mar-b15">
                        <AddStepHolder
                            icon={<UILIB.Icons icon="transaction" circle={true} />}
                            onClick={() => { this.chooseStepType("split") }}
                            title={"Split"}
                            subTitle={"Split Contacts Evenly"}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 mar-b15">
                        <AddStepHolder
                            icon={<UILIB.Icons icon="medal" circle={true} />}
                            onClick={() => { this.chooseStepType("goal") }}
                            title={i18n.t('automation:add.addStep.goal')}
                            subTitle={i18n.t('automation:add.addStep.goalSub')}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 mar-b15">
                        <AddStepHolder
                            icon={<UILIB.Icons icon="megaphone" circle={true} />}
                            onClick={() => { this.chooseStepType("notification") }}
                            title={i18n.t('automation:add.addStep.notification')}
                            subTitle={i18n.t('automation:add.addStep.notificationSub')}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 mar-b15">
                        <AddStepHolder
                            icon={<UILIB.Icons icon="lightning" circle={true} />}
                            onClick={() => { this.chooseStepType("webhook") }}
                            title={i18n.t('automation:add.addStep.webhook')}
                            subTitle={i18n.t('automation:add.addStep.webhookSub')}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 mar-b15">
                        <AddStepHolder
                            icon={<UILIB.Icons icon="arrowsUpDown" circle={true} />}
                            onClick={() => { this.chooseStepType("score") }}
                            title={i18n.t('automation:add.condition.scoreScore')}
                            subTitle={i18n.t('automation:add.condition.scoreDetail')}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 mar-b15">
                        <AddStepHolder
                            icon={<UILIB.Icons icon="arrowBounce" circle={true} />}
                            onClick={() => { if (!hasStepsAfter) { this.chooseStepType("redirect") } }}
                            title={i18n.t('automation:add.addStep.redirect')}
                            subTitle={i18n.t('automation:add.addStep.redirectSub')}
                            style={{ opacity: !hasStepsAfter ? "1" : "0.3" }}
                            alt={hasStepsAfter ? "Only available if there are no steps after" : ""}

                        />
                    </div>
                    {generic.map(app => {
                        return app.Application.options.hooks.filter(h => h.automationaction).map((hook, hookIndex) => {
                            try {
                                let triggers = this.props.trigger.stepOptions.trigger ? [this.props.trigger.stepOptions.trigger.value] : []
                                if (this.props.trigger.triggerSteps && this.props.trigger.triggerSteps.length) {
                                    triggers.push(...this.props.trigger.triggerSteps.filter(s => s.triggerType.value === app.Application.appName).map(s => s.stepOptions.trigger.value))
                                }
                                if (hook.triggerFilter && Array.isArray(hook.triggerFilter) && hook.triggerFilter.length) {
                                    if (!triggers.some(t => hook.triggerFilter.indexOf(t.value || t) > -1)) return null
                                }
                            } catch (e) { }

                            return <div className="col-xs-12 col-sm-6 mar-b15" key={app.Application.appName + hookIndex}>
                                <AddStepHolder
                                    icon={<img src={app.Application.appLogoUri} height="30" />}
                                    onClick={() => this.chooseStepType(app.ApplicationId, hookIndex)}
                                    title={app.Application.appName}
                                    subTitle={hook.name}
                                />
                            </div>
                        })
                    })}
                </div>


            </div>
        </div>
    }
}

export default AddStepDrawer;
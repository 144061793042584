import React from "react";
import classnames from "classnames";
import UILIB from "~/Common-Objects/Lib";
import * as styles from "./index.module.scss";

// Wraps the existing UILIB.TextInput component so we can override styles as the Conversations version has a few tweaks.
export default function ConversationsTextInput({
  label,
  description,
  className,
  outerStyle,
  ...rest
}) {
  const id = window.crypto.randomUUID();

  const classes = classnames(styles.root, className);

  return (
    <div className={classes} style={outerStyle}>
      {label && <label htmlFor={id}>{label}</label>}

      {description && <div className={styles.description}>{description}</div>}

      <UILIB.TextInput id={id} {...rest} />
    </div>
  );
}

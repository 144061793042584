import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import i18n from '~/i18n'

export default class RenameSegment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            segment: undefined,
            pageLoaded: false,
            error: '',
            currentName: ''
        };

        this.getSegment = this.getSegment.bind(this);
        this.updateField = this.updateField.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.getSegment();
    }

    getSegment() {
        var self = this;

        axios.get('/segment/' + this.props.segmentId).then(response => {
            self.setState({ segment: response.data.Segments, pageLoaded: true, currentName: response.data.Segments[0].name });
        }).catch(console.log)
    }

    save() {
        if (!this.state.segment[0].name) {
            return this.setState({
                error: i18n.t('subscribers:segments.invalidError')
            })
        } else {
            axios.put('/segment/' + this.props.segmentId, { name: this.state.segment[0].name })
                .then(this.props.close).catch(err => {
                    this.setState({
                        error: 'Name Already In Use'
                    })
                })
        }
    }

    updateField(ev) {
        var segment = this.state.segment;
        segment[0].name = ev.currentTarget.value;
        this.setState({
            segment
        })
    }

    render() {
        if (this.state.pageLoaded === true) {
            return <div>

                <h4 className="mar-b25">{i18n.t('subscribers:segments.renameHeader')} "{this.state.currentName}"</h4>

                <UILIB.TextInput className="mar-b25" value={this.state.segment[0].name} onChange={this.updateField} label={i18n.t('subscribers:segments.segmentName')} error={this.state.error} />

                <UILIB.Button className="button-primary" text={i18n.t('save')} iconLeft={<UILIB.Icons icon="tick" />} onClick={this.save} />

            </div>
        }
        else {
            return <div></div>
        }
    }
};


import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import Themes from '~/Common-Objects/Themes'
import Theme from './theme'

class BasicTheme extends Component {
    constructor(props) {
        super(props)
        this.state = {
            themes: [],
            count: 0,
            limit: 12,
            page: 0,
            loaded: false
        }

        this.getThemes = this.getThemes.bind(this);
    }

    componentDidMount() {
        this.getThemes()
    }

    getThemes() {
        var queryStr = `?type=0&limit=${this.state.limit}&offset=${this.state.limit * this.state.page}`
        axios.get('/theme' + queryStr)
            .then(response => {
                this.setState({
                    themes: response.data.Themes,
                    count: response.data.count,
                    loaded: true
                })
            })
    }

    render() {
        if (this.state.loaded) {
            var theme0 = this.state.themes.find(t => t.name == 'Blank');
            var theme1 = this.state.themes.find(t => t.name == '1 Column');
            var theme2 = this.state.themes.find(t => t.name == '2 Column');
            var theme3 = this.state.themes.find(t => t.name == '3 Column');
            var theme4 = this.state.themes.find(t => t.name == '4 Column');
            var theme5 = this.state.themes.find(t => t.name == '1-2 Column');
            var theme6 = this.state.themes.find(t => t.name == '2-1 Column');

            return (

                <UILIB.Paper>
                    <UILIB.Row >
                        {this.state.themes.map((theme, index) => {
                            return <UILIB.Column
                                key={"theme_" + index}
                                xs={12}
                                sm={4}
                                lg={3}>

                                <Theme theme={theme} select={() => this.props.onSelect(theme)}></Theme>
                            </UILIB.Column>
                        })}

                    </UILIB.Row>
                </UILIB.Paper>

            );
        } else {
            return <div style={{ marginTop: '50px' }}>
                <UILIB.LoadingIcons iconType="2" width="100px" />
            </div>
        }
    }
}

export default BasicTheme;
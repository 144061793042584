import React, { Component } from 'react';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import HookActions from './hook'
import HookFilter from './hookFilter';

@connect((store) => {
    return { account: store.accountMaster }
})

class IntegrationAddOption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ourHook: {
                hookType: "",
                applicationId: "",
                options: {
                    action: "",
                    link: "",
                }
            },
            selectedAction: {},
            selectedHook: {},
            actionType: "",
            actionHook: undefined
        }
        this.addHook = this.addHook.bind(this)
        this.updateVal = this.updateVal.bind(this);
    }

    componentDidMount() {
        if (this.props.existing) {

            var selectedAction = this.props.actions.find(ac => ac.value == this.props.existing.hookType)
            var actionType = this.props.existing.hookType;

            var selectedHook = {};
            var actionHook = "";
            this.props.hooks.forEach((hk, index) => {
                if (hk.ApplicationId == this.props.existing.applicationId && hk.action == this.props.existing.options.action) {
                    selectedHook = hk;
                    actionHook = index + "";
                }
            })

            this.setState({ ourHook: this.props.existing, selectedAction, actionType, selectedHook, actionHook });
        }
    }

    addHook(addIt) {
        let hook = this.state.selectedHook
        let ourHook = this.state.ourHook
        let invalid = false
        if (hook && hook.build && hook.build.length && hook.build.some(b => b.required)) {
            hook.build.filter(b => b.required).forEach(build => {
                let value = ourHook.options[build.value]
                let valid = true
                if (value === undefined) {
                    valid = false
                } else if (typeof value === 'string' && value.trim() === '') {
                    valid = false
                }

                build.valid = valid;
                if (!valid) invalid = true;
            })
        }

        this.setState({
            selectedHook: hook
        })
        if (invalid) return

        let webhooks = this.props.webhooks
        if (addIt) webhooks.push(this.state.ourHook)
        this.props.finished(webhooks)
    }
    updateVal(event) {
        var newState = this.state;
        if (event.target.name == "actionType") {
            newState.ourHook.applicationId = "";
            newState.ourHook.options = { action: "", link: "" }
            var action = this.props.actions.find(ac => ac.value == event.target.value)
            newState.ourHook.hookType = action.value
            newState.selectedAction = action;

            newState.actionType = event.target.value;
            newState.actionHook = undefined;
            newState.selectedHook = {};
        }
        if (event.target.name == "actionHook") {

            var hook = this.props.hooks[event.target.value];
            newState.ourHook.applicationId = hook.ApplicationId;
            newState.ourHook.options.action = hook.action;
            newState.selectedHook = hook;
            newState.actionHook = event.target.value;
        }
        if (event.target.name == "actionOption") {
            newState.ourHook.options.link = event.target.value;
        }

        this.setState(newState)
    }

    render() {

        var selectedApp;
        if (this.state.selectedHook) {
            selectedApp = this.props.apps.find(app => app.ApplicationId == this.state.selectedHook.ApplicationId)
        }

        var goodToSave = false;
        if (this.state.actionType !== "" && this.state.actionHook !== undefined) {
            if (Object.keys(this.state.selectedAction).length > 0 && (
                (!this.state.selectedAction.options || !Object.keys(this.state.selectedAction.options).length)
                ||
                (this.state.selectedAction.options && Object.keys(this.state.selectedAction.options).length > 0)
            )) {
                goodToSave = true;
            }
        }

        return (
            <div id="hookOuterHolder" style={{ width: "100%" }}>
                {!this.props.existing && <UILIB.Header >Add a new Action</UILIB.Header>}
                {this.props.existing && <UILIB.Header>Update this Action</UILIB.Header>}
                <div className="mar-b10">

                    <UILIB.Select label="When a contact.." placeholder="Select a Campaign Action" name="actionType" data={this.props.actions} value={this.state.actionType} onChange={this.updateVal} />
                </div>

                {(this.state.actionType) && <>
                    <div className="mar-b10">
                        <UILIB.Select label="Perform the following action.." placeholder="Select an Integration Action" name="actionHook" data={this.props.hooks.map((hk, hkIndex) => {
                            return { value: hkIndex, label: `${hk.name} in ${hk.appName}` }
                        })} value={this.state.actionHook} onChange={this.updateVal} />
                    </div>

                    {(this.state.actionHook !== undefined && this.state.selectedAction.options != undefined && this.state.selectedAction.options.length > 0) && <div className="mar-b10">
                        <UILIB.Select label="When the contact clicks.." placeholderNoSelect="Select Link" name="actionOption" data={this.state.selectedAction.options.map(a => {
                            return { value: a, label: a ? a : 'Any Link' }
                        })} value={this.state.ourHook.options.link} onChange={this.updateVal} />
                    </div>}

                </>}

                {(Object.keys(this.state.selectedAction).length > 0 && ((!this.state.selectedAction.options || !this.state.selectedAction.options.length) || (this.state.selectedAction.options && this.state.selectedAction.options.length > 0 && this.state.ourHook.options.link !== ""))) && <div>
                    {this.state.selectedHook.build && this.state.selectedHook.build.length && <div>
                        <HookActions {...selectedApp.props}
                            CampaignId={this.props.campaignId}
                            link={this.state.ourHook.options.link}
                            hook={this.state.selectedHook}
                            type={this.state.selectedAction}
                            action={this.state.ourHook.options}
                        />
                    </div>
                    }

                </div>}

                {(this.state.actionHook != "") && (this.state.actionType === 'click' || this.state.actionType === 'open') && <HookFilter hook={this.state.ourHook.options} type={this.state.actionType} CampaignId={this.props.campaignId} inline={true} />}

                <UILIB.Button text={this.props.existing ? "Update Action" : "Add Action"} className="button-primary mar-r10" disabled={!goodToSave} onClick={() => { this.addHook(!this.props.existing) }} />
                <UILIB.Button text="Forget It" onClick={() => { this.addHook(false) }} />




            </div >
        );
    }
}

export default IntegrationAddOption;
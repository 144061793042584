import React from 'react'
require('~/stylesheets/genericpagenotfound.css');

import OfflineWeb from '~/assets/images/genericpagenotfound/noweb-desktop.png'
import OfflineMob from '~/assets/images/genericpagenotfound/noweb-mobile.png'

import UILIB from '~/Common-Objects/Lib.jsx';

export default class NotFoundOutPut extends React.Component {
  constructor(props) {
    super(props);
    this.tryAgain = this.tryAgain.bind(this);
  }

  tryAgain() {
    this.props.history.goBack();
    // window.history.back();
  }

  render() {
    return <div className="container-fluid">
      <UILIB.Row className="center-xs middle-xs around-xs" style={{ height: "100vh" }}>
        <UILIB.Column>
          <img src={OfflineWeb} style={{ width: "100%", maxWidth: "100px" }} className="mar-b25" />
          <h1 className="mar-b15">Oh No!</h1>
          <h4 style={{ marginBottom: "30px" }}>The interweb has disappeared</h4>
          <UILIB.Button text="Try Again..." onClick={this.tryAgain} />
        </UILIB.Column>
      </UILIB.Row >
    </div >
  }
};
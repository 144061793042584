import React from 'react'

export default class IconsArrowLeft extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.70682 14.695C9.51929 14.8825 9.26498 14.9878 8.99982 14.9878C8.73465 14.9878 8.48034 14.8825 8.29282 14.695L4.29282 10.695C4.10535 10.5075 4.00003 10.2532 4.00003 9.98801C4.00003 9.72284 4.10535 9.46854 4.29282 9.28101L8.29282 5.28101C8.48142 5.09885 8.73402 4.99806 8.99622 5.00034C9.25841 5.00261 9.50923 5.10778 9.69464 5.29319C9.88004 5.4786 9.98521 5.72941 9.98749 5.99161C9.98977 6.25381 9.88897 6.50641 9.70682 6.69501L7.41382 8.98801H14.9998C15.265 8.98801 15.5194 9.09337 15.7069 9.2809C15.8945 9.46844 15.9998 9.72279 15.9998 9.98801C15.9998 10.2532 15.8945 10.5076 15.7069 10.6951C15.5194 10.8827 15.265 10.988 14.9998 10.988H7.41382L9.70682 13.281C9.89429 13.4685 9.9996 13.7228 9.9996 13.988C9.9996 14.2532 9.89429 14.5075 9.70682 14.695Z" fill={color} />
        </svg>


    }
}        
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class BindCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            levels: [{ label: i18n.t('permissions:users.READ'), value: 'READ' }, { label: i18n.t('permissions:users.WRITE'), value: 'WRITE' }]
        };
    }

    render() {

        var theUser = this.props.userData;

        var isOurUser = false;
        if (theUser.id === this.props.user.userID) {
            isOurUser = true;
        }
        var fullName = null;
        var emailAddress = theUser.emailAddress;
        if (theUser.firstName != null) {
            fullName = theUser.firstName;
        }
        if (theUser.lastName != null) {
            fullName += " " + theUser.lastName;
        }
        if (fullName === null) {
            fullName = i18n.t('permissions:users.noName');
        }

        var cardType = "primary";
        var buttType = "";

        if (this.props.level == "ADMIN") {
            cardType = "secondary";
            buttType = "secondary";
        }
        if (theUser.registered === false) {
            cardType = "";
        }

        var canDelete = !isOurUser
        if (canDelete) {
            canDelete = (this.props.bind.accessLevel !== 'ADMIN' || this.props.myLevel === 'ADMIN')
        }

        if (this.props.readOnly) canDelete = false;

        let isCreatedBy = false
        if (this.props.CreatedById && this.props.CreatedById === theUser.id) {
            isCreatedBy = true
        }

        return <UILIB.Paper>
            {canDelete && <div style={{ position: "absolute", right: "15px", top: "15px" }}>
                <UILIB.Icons icon="cross" onClick={() => { this.props.onDelete(theUser.id) }} />
            </div>}

            {isCreatedBy && <div style={{ position: "absolute", left: "15px", top: "15px" }}>
                <UILIB.SquareChip className="chipSmall">Creator</UILIB.SquareChip>
            </div>}



            <UILIB.Avatar style={{ height: 'auto', width: '100%', maxWidth: "65px", maxHeight: "65px" }} src={theUser.profilePicFileName}></UILIB.Avatar>

            <h4 className="mar-b15">{fullName}</h4>
            <div className="mar-b15">{theUser.emailAddress}</div>

            <div className="options">
                {(() => {
                    if (isOurUser || !canDelete) {
                        return <UILIB.Button className="button-sml min-shadow grey" headerText={i18n.t('options')} style={{ textAlign: "center" }}>{i18n.t('permissions:users.' + this.props.level)}</UILIB.Button>
                    }
                    else {
                        return <UILIB.ButtonSelect
                            autoClose
                            className={buttType}
                            headerText={i18n.t('permissions:users.' + this.props.level)}
                            data={
                                this.state.levels.map((level, index) => {
                                    return <a key={index} onClick={() => { this.props.update(this.props.userData.id, level.value) }} style={{ textTransform: "uppercase" }}>{level.label}</a>
                                })
                            }>
                        </UILIB.ButtonSelect>
                    }
                })()}
            </div>
        </UILIB.Paper >

    }
}

import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n.js'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import SmileyPicker from '../TextInput/smileyPicker';

@connect((store) => {
    return { user: store.user }
})
export default class TextArea extends React.Component {
    constructor(props) {
        super(props)
        this.openSmileyPicker = this.openSmileyPicker.bind(this);
        this.pickSmiley = this.pickSmiley.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openSmileyPicker() {

        var ourEl = document.getElementById(this.props.id);
        var startChar = ourEl.selectionStart;
        var endChar = ourEl.selectionEnd;

        this.setState({ inputSelection: { start: startChar, end: endChar } });

        var drawerContent = <SmileyPicker onPick={this.pickSmiley} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "400px"));
    }


    pickSmiley(theChar) {
        var ourEl = document.getElementById(this.props.id);
        var startPos = this.state.inputSelection.start;
        var endPos = this.state.inputSelection.end;
        ourEl.focus();

        if (document.selection) {
            sel = document.selection.createRange();
            sel.text = theChar;
        }
        else {

            ourEl.value = ourEl.value.substring(0, startPos)
                + theChar
                + ourEl.value.substring(endPos, ourEl.value.length);


            this.props.onChange({ target: ourEl, currentTarget: ourEl })
        }
        ourEl.setSelectionRange(startPos + theChar.length, startPos + theChar.length);
        this.closePopup();

    }

    closePopup() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", undefined, true));
    }

    render() {
        var errorTxt = [];
        var thisClasses = "txtInput ";
        if (this.props.error && this.props.error.length > 0) {
            thisClasses += "button-warning ";

            if (Array.isArray(this.props.error)) {
                this.props.error.forEach((err, index) => {
                    let classes = ''
                    if (index) classes += ' mar-t0'
                    if (index === this.props.error.length - 1) classes += ' mar-b10'
                    errorTxt.push(<UILIB.FormError className={classes} key={"err" + this.props.name + '-' + index}>{err}</UILIB.FormError>);
                })
            } else {
                errorTxt.push(<UILIB.FormError key={"err" + this.props.name} className={this.props.errorClass}>{this.props.error}</UILIB.FormError>);
            }
        }

        if (this.props.className) thisClasses += " " + this.props.className;

        let outerClassName = "textInputWrapper";
        if (this.props.outerClassName) outerClassName += " " + this.props.outerClassName;

        var thisStyle = {};
        if (this.props.style) {
            thisStyle = JSON.parse(JSON.stringify(this.props.style));
        }

        let outerStyle = {};
        if (this.props.outerStyle) outerStyle = this.props.outerStyle;

        let label = "";
        if ((this.props.label && this.props.labelOptions) || this.props.labelOptions) {
            label = <div className="txtInput_label_flex">
                <div className="txtInput_label">{this.props.label}</div>
                <div className="txtInput_labelOptions">{this.props.labelOptions}</div>
            </div>
        } else if (this.props.label) {
            label = <div className="txtInput_label">{this.props.label}</div>
        }

        let explainer = "";
        if (this.props.explainer) {
            explainer = <div className="txtInput_explainer">{this.props.explainer}</div>
        }

        let iconRight = this.props.iconRight
        let iconBottomRight = this.props.iconBottomRight;
        if (this.props.emoji) {
            iconRight = <UILIB.Icons icon="smiley"
                title="Insert an Emoji"
                alt="Insert an Emoji"
                data-tip="Insert Emoji"
                data-for="emoji"
                onClick={this.openSmileyPicker}
            />
        }
        if (iconRight) thisStyle.paddingRight = "40px"

        return <div className={outerClassName} style={outerStyle}>
            {label}
            <div className="txtInputInnerHolder">
                <textarea id={this.props.id} maxLength={this.props.maxLength} className={thisClasses} rows={this.props.rows} name={this.props.name} placeholder={this.props.placeholder} onChange={this.props.onChange} value={this.props.value} style={thisStyle} disabled={this.props.disabled} />
                {this.props.ai && <div style={{ position: 'absolute', bottom: 15, right: 10 }}>
                    <div
                        className="tinyMce_bottomTools_aiAssistant border-darkmode-purple-to-blue-horizontal"
                        onClick={this.props.aiClick}
                    >
                        <UILIB.Icons className="tinyMce_bottomTools_aiAssistant_icon" icon="sparkle2" color="#FFFFFF" style={{ width: "20px", height: "20px" }} />
                        <div className="tinyMce_bottomTools_aiAssistant_label">{this.props.aiPrompt || i18n.t('templates:aiContentAssistant.aiContentAssistant')}</div>
                    </div></div>}
                {!!iconRight && <div className="textInputIcon">{iconRight}</div>}
                {!!iconBottomRight && <div className="textAreaIconBottomRight">{iconBottomRight}</div>}
            </div>
            {errorTxt}
            {explainer}

        </div>
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QZPkomoeyH1c7RPNbonv{display:flex;flex-direction:column;gap:24px}.AkO39uY6KtlkcY4Sdi1t{display:block;color:var(--grey-800);margin-bottom:8px}.n1md7hGZDs6GcxycEGwQ{display:flex;flex-direction:column;gap:16px;font-size:14px}.n1md7hGZDs6GcxycEGwQ h3{font-size:inherit;font-weight:500;margin-bottom:8px}.n1md7hGZDs6GcxycEGwQ p:last-of-type{margin-bottom:0}.JjEST9zpwMOR7W0DrsIl{padding:16px;border:1px solid var(--grey-300);border-radius:8px}.uh4cd8IjYFnWZdXMAK9V{align-self:flex-start}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/aiAssistant/history/components/viewHistoryItemDialog/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,sBACE,aAAA,CACA,qBAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,cAAA,CAEA,yBACE,iBAAA,CACA,eAAA,CACA,iBAAA,CAGF,qCACE,eAAA,CAIJ,sBACE,YAAA,CACA,gCAAA,CACA,iBAAA,CAGF,sBACE,qBAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.timestamp {\n  display: block;\n  color: var(--grey-800);\n  margin-bottom: 8px;\n}\n\n.contentWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  font-size: 14px;\n\n  h3 {\n    font-size: inherit;\n    font-weight: 500;\n    margin-bottom: 8px;\n  }\n\n  p:last-of-type {\n    margin-bottom: 0;\n  }\n}\n\n.box {\n  padding: 16px;\n  border: 1px solid var(--grey-300);\n  border-radius: 8px;\n}\n\n.closeBtn {\n  align-self: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "QZPkomoeyH1c7RPNbonv",
	"timestamp": "AkO39uY6KtlkcY4Sdi1t",
	"contentWrapper": "n1md7hGZDs6GcxycEGwQ",
	"box": "JjEST9zpwMOR7W0DrsIl",
	"closeBtn": "uh4cd8IjYFnWZdXMAK9V"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

import { Editor } from '@tinymce/tinymce-react';
import axios from '~/data/http/axios';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import MergeTags from '~/pages/cp/includes/mergeTags/mergeTags'
import FileManager from '~/pages/cp/includes/fileManager/fileManager'
import i18n from '~/i18n'
import EmailPreviewer from '~/pages/cp/includes/emailPreviewer/emailPreviewer'
import ReactTooltip from 'react-tooltip'
import jQuery from 'jquery';
import queryString from 'query-string';
import CheckLinksButton from '../checkLinksButton';
import AiContentGenerator from '../../../includes/ai/contentGenerator';
import fonts from '~/Classes/fonts'
import DragDropFunctions from '../dragDrop/dragDropFunctions';
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster }
})

export default class FromCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templateHTML: "",
            initialHTML: "",
            templateLoaded: false,
            templateID: 0,
            templateName: "",
            editorHeight: 100,
            isSaving: "",
            popups: {
                exiting: false
            },
            currentDrawer: null,
            alertMessage: '',
            alertOpen: false,
            CampaignId: undefined,
            fromSource: 'campaign',
            redirect: "",
            canBeConvertedToDD: false,
            templateNameErr: false,
            advancedFonts: fonts.map((font) => { return font.label + "=" + font.value }).join(';'),
            advancedFontsImport: fonts.map((font) => { return "@import url('https://fonts.googleapis.com/css?family=" + font.label + "')" }).join(';')
        };

        this.linksExitPage = this.linksExitPage.bind(this);
        this.createNewTemplate = this.createNewTemplate.bind(this);
        this.saveTemplate = this.saveTemplate.bind(this);
        this.loadTemplate = this.loadTemplate.bind(this);
        this.onMergeTagClick = this.onMergeTagClick.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.updateTextBox = this.updateTextBox.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.openDrawer = this.openDrawer.bind(this);
        this.handleEditorCommand = this.handleEditorCommand.bind(this);
        this.onFileManagerClick = this.onFileManagerClick.bind(this);
        this.openEmailPreview = this.openEmailPreview.bind(this);
        this.reselectTemplate = this.reselectTemplate.bind(this);
        this.convertToDD = this.convertToDD.bind(this);
        this.updateTemplateName = this.updateTemplateName.bind(this);
        this.checkLinks = this.checkLinks.bind(this);
    }

    componentDidMount() {
        var thisTemplateID = this.props.match.params.templateID;

        const query = queryString.parse(this.props.location.search);

        if (query.redirect) {
            var fromSource = "campaign";
            if (query.redirect.indexOf('/automation/')) {
                fromSource = "automation"
            }
            this.setState({
                redirect: query.redirect,
                fromSource: fromSource
            })
        }

        if (!thisTemplateID) {
            this.createNewTemplate();
        }
        else {
            this.setState({ templateID: thisTemplateID }, this.loadTemplate)
        }

        var height = this.refs.fullScreenMid.clientHeight;
        this.setState({ editorHeight: height });
    }

    updateTemplateName(ele) {
        this.setState({ templateName: ele.currentTarget.value });
    }

    handleEditorChange = (e) => {
        this.setState({ templateHTML: e.target.getContent() });
    }
    handleEditorCommand = (e) => {
        if (e.command === "mpzFile") {
            this.openDrawer("fileManager");
        }
        if (e.command === "mpzMergeTags") {
            this.openDrawer("mpzMergeTags");
        }
        if (e.command === "mpzFileInline") {
            this.openDrawer("fileManager2", e)
        }
    }

    onFileManagerClick(imgURL, event) {

        var finalImg = "<img src='" + imgURL + "'>";
        if (event) {
            jQuery("input[id$='-inp']").val(imgURL)
        }
        else {
            this.refs.tinyMCEEditor.editor.editorCommands.execCommand('mceInsertContent', false, finalImg);
        }
        this.closeDrawer();

    }

    createNewTemplate() {

        const query = queryString.parse(this.props.location.search);
        var from = query.from;
        var self = this;

        var templateHtml = '';
        var promise = Promise.resolve();
        if (from) {
            //from theme
            promise = axios.get('/theme/' + from)
        }

        promise.then((theme) => {
            if (theme) {
                templateHtml = theme.data.Theme.templateHTML
            }
            return axios
                .post('/template',
                    {
                        "templateName": "Unnamed Template",
                        "templateType": 2,
                        "templateHTML": templateHtml
                    })
        }).then(function (res) {
            self.setState({ templateID: res.data.Template.templateID }, self.refreshPage)
        })
    }

    refreshPage() {
        window.open('/cp/templates/add/wysiwyg/' + this.state.templateID, '_self');
    }

    linksExitPage(doPrompt) {
        if (!this.state.templateName || this.state.templateName.length < 3) {
            this.setState({
                alertMessage: i18n.t('templates:add.drag.longer'),
                alertOpen: true,
                templateNameErr: true
            });
        }
        else {
            this.saveTemplate(1)
        }
    }

    updateTextBox(event) {
        var theName = event.currentTarget.name;
        var theVal = event.currentTarget.value;
        this.setState({ [theName]: theVal })
    }

    reselectTemplate() {
        axios.put('/campaign/' + this.state.CampaignId,
            {
                campaign: {
                    TemplateId: null
                }
            })
            .then(() => {
                this.props.history.push('/cp/campaigns/add/standard/' + this.state.CampaignId + '/2')
            })
    }


    async saveTemplate(doExitPage) {
        var self = this;
        self.setState({
            alertMessage: i18n.t('templates:add.wysi.alertSaving'), alertOpen: true
        });
        try {
            var saveProps = {};
            var newHTML = self.state.templateHTML;

            newHTML = newHTML.replace(/\<\!\-\- TPFONTS\-\-\>[\s\S]*\<\!\-\- ETPFONTS\-\-\>/gmi, "");
            const fontsUsed = DragDropFunctions.findFontsReturnScripts(newHTML);
            const fontsUsedData = fontsUsed.filter(theFont => theFont.src == "google");

            if (fontsUsedData.length > 0) {
                var newFonts = "";
                newFonts = "<!-- TPFONTS-->";
                fontsUsedData.forEach(theFont => {
                    const theFontName = theFont.label.replace(/ /g, '');
                    const theFontFolder = theFontName.toLowerCase();
                    newFonts += `<style type="text/css"> 
                    @font-face {
                        font-family: ${theFont.label};
                        src: url(https://cdn1.ourmailsender.com/fonts/${theFontFolder}/${theFontName}-Regular.ttf);
                      }
                      </style>
                    `
                });
                newFonts += "<!-- ETPFONTS-->";
                if (newHTML.indexOf("<head>") > -1) {
                    newHTML = newHTML.replace(/\<head\>/gmi, "<head>" + newFonts);
                }
                else {
                    newHTML = newFonts + newHTML;
                }
            }

            const templateErrs = await axios.post('/template/mergeTagsTest', { bodyHtml: newHTML || ' ' })
            if (!templateErrs.data.success) {
                this.setState({
                    alertMessage: i18n.t('templates:add.wysi.mergeTagProblem') + templateErrs.data.error, alertOpen: true
                });
                return;
            }

            if (this.state.templateName) {
                saveProps.templateName = this.state.templateName;
            }
            saveProps.templateHTML = newHTML || ' ';
            const saveRes = await axios.put('/template/' + self.state.templateID, saveProps)

            this.setState({ templateHTML: saveProps.templateHTML })

            this.refs.tinyMCEEditor.editor.editorCommands.execCommand('mceSetContent', false, saveProps.templateHTML);
            var canBeConvertedToDD = false;
            if (saveRes.data.canBeConvertedToDD) {
                canBeConvertedToDD = true;
            }
            this.setState({
                alertMessage: i18n.t('templates:add.wysi.alertSaved'), alertOpen: true, canBeConvertedToDD
            });

            switch (doExitPage) {
                case 0:
                    break;
                case 1:
                    if (this.state.redirect) {
                        return this.props.history.push(this.state.redirect)
                    }
                    if (this.state.CampaignId) {
                        this
                            .props
                            .history
                            .push('/cp/campaigns/add/standard/' + this.state.CampaignId + '/3');
                    } else {
                        this
                            .props
                            .history
                            .push('/cp/templates/');
                    }
                    break;
                case 2:
                    this.openEmailPreview();
                    break
                case 3:
                    var url = "/cp/templates/add/fromCode/" + this.state.templateID;
                    if (this.state.redirect && this.state.redirect.length) {
                        url += "?redirect=" + this.state.redirect
                    }

                    this.props.history.push(url)
                    break
            }
        }
        catch (error) {
            var errMessage = i18n.t('templates:add.wysi.alertError')
            if (error.friendlyError) {
                errMessage = error.errorText
            }
            this.setState({
                alertMessage: errMessage, alertOpen: true
            });
        }
    }

    openEmailPreview() {
        var drawerContent = <EmailPreviewer templateId={this.state.templateID} history={this.props.history} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
    }

    loadTemplate() {
        axios
            .get('/template/' + this.state.templateID
            )
            .then((res) => {
                var theHTML = res.data.template.templateHTML;
                var templateName = res.data.template.templateName;

                this.setState({ templateName: templateName, templateHTML: theHTML, templateLoaded: true, CampaignId: res.data.template.CampaignId })
            }).catch(function (error) {
            })
    }


    onMergeTagClick(tag) {
        this.refs.tinyMCEEditor.editor.editorCommands.execCommand('mceInsertContent', false, tag);
        this.closeDrawer();
    }

    openDrawer(ourDrawer, e) {
        if (ourDrawer === "fileManager") {
            var theDrawer = <FileManager onFileClick={(res) => { this.onFileManagerClick(res.url) }} fileTypes={"images"} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '100vw', 'fix-height'));
        }
        if (ourDrawer === "fileManager2") {
            var theDrawer = <FileManager onFileClick={(res) => { this.onFileManagerClick(res.url, e) }} fileTypes={"all"} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '100vw', 'fix-height'));
        }
        if (ourDrawer === "mpzMergeTags") {
            var theDrawer = <MergeTags onTagClick={this.onMergeTagClick} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, 500));
        }
        if (ourDrawer === "aiContentGenerator") {

            var theDrawer = <AiContentGenerator contentType="paragraph" insertText={this.onMergeTagClick} close={() => {
                this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
            }} />
            this.props.dispatch(siteMaster.alterSiteDrawer(true, false, "right", theDrawer, true, "700px", "drawer_dark"));
        }

    }
    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true, null));
    }


    closePopup(eleName) {
        var popups = this.state.popups;
        popups[eleName] = false;
        this.setState({ popups: popups })
    }
    convertToDD() {
        var self = this;
        return axios.put('/template/convertToDragDrop/' + self.state.templateID).then(() => {
            this.props.history.push('/cp/templates/add/dragDrop/' + self.state.templateID)
        }).catch(err => {
            var errMessage = i18n.t('templates:add.wysi.alertError')
            if (err && err.data && err.data.error) {
                errMessage = err.data.error
            }
            self.setState({
                alertMessage: errMessage, alertOpen: true
            });

        })
    }

    checkLinks() {
        return this.saveTemplate(0);
    }

    render() {

        var templateNameCSS = {};
        var templateNameClass = ''
        if (this.state.templateNameErr === true) {
            templateNameCSS.borderBottom = "2px solid red";
        }

        let aiEnabled = this.props.siteMaster.siteId == 1;
        if (!aiEnabled) aiEnabled = this.props.siteMaster.aiEnabled;

        return <div className="fullScreenHolder">
            {fonts.filter(theFontStyle => theFontStyle.src == "google").map((theFont, index) => {
                return <link key={"linkk_" + index} href={"https://fonts.googleapis.com/css?family=" + theFont.label} rel="stylesheet" />
            })}

            <div style={{ flex: "0" }}>
                <div className="dragDropToolBar" id="dragDropToolBar">
                    <div className="templateTitle">
                        {!this.state.CampaignId && <UILIB.TextInput style={templateNameCSS} className={"txtInput editor " + templateNameClass} value={this.state.templateName} onChange={this.updateTemplateName} placeholder={this.state.form ? i18n.t('templates:index.enterAFormName') : i18n.t('templates:index.enterATemplateName')} />}
                    </div>
                    <div className="actions">
                        <div className="actions-holder">
                            <UILIB.Button
                                onClick={() => { this.saveTemplate(3) }}
                                text={i18n.t('templates:add.wysi.editInCodeEditor')}
                            />
                        </div>
                        {(this.state.CampaignId && this.state.fromSource == 'campaign') && <div className="actions-holder">
                            <UILIB.Button
                                onClick={() => { this.reselectTemplate() }}
                                text={i18n.t('templates:add.wysi.changeTemplate')}
                            />
                        </div>}

                        <div className="actions-holder">
                            <CheckLinksButton templateId={this.state.templateID} onClick={this.checkLinks} />
                        </div>

                        <div className="actions-holder">
                            <UILIB.Button
                                onClick={() => { this.saveTemplate(2) }}
                                text={i18n.t('templates:index.preview')}
                                iconLeft={<UILIB.Icons icon="eye" style={{ height: "18px", width: "18px" }} />}
                            />
                        </div>
                        <div className="actions-holder">
                            <UILIB.Button
                                className="button-primary"
                                onClick={() => { this.linksExitPage(this.state.CampaignId ? 0 : 1) }}
                                text={i18n.t('templates:add.drag.saveContinue')}
                                iconRight={<UILIB.Icons icon="arrowRight" />}
                            />
                        </div>

                    </div>
                </div>
                {
                    this.state.canBeConvertedToDD && <div className="nav-banner paper-orange text-center">
                        <span className="text-sml text-md mar-r10">Can be converted to Drag and Drop</span>
                        <UILIB.Button text="Convert" onClick={this.convertToDD} />
                    </div>
                }
            </div>

            <UILIB.Paper className="inlineTinyMce" ref="fullScreenMid" style={{ flex: "1" }}>

                {(() => {
                    if (this.state.templateLoaded) {
                        return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            <Editor
                                ref="tinyMCEEditor"
                                initialValue={this.state.templateHTML}
                                init={{
                                    plugins: 'fullpage image imagetools mpzFileManager mpzMergeTags code advlist textcolor colorpicker emoticons link paste spellchecker table hr anchor lists',
                                    toolbar: 'code | bold italic underline strikethrough | forecolor backcolor | bullist numlist | outdent indent | table | mpzFileManager | link unlink | alignleft aligncenter alignright alignjustify | hr styleselect  fontselect fontsizeselect | mpzMergeTags',
                                    width: "100%",
                                    height: "100%",
                                    menubar: false,
                                    force_br_newlines: false,
                                    force_p_newlines: false,
                                    convert_urls: false,
                                    imagetools_cors_hosts: [''],
                                    imagetools_proxy: 'cdn1.ourmailsender.com',
                                    imagetools_toolbar: "imageoptions",
                                    font_formats: this.state.advancedFonts,
                                    content_style: this.state.advancedFontsImport,
                                }}
                                onExecCommand={(ed) => { this.handleEditorCommand(ed) }}
                                onChange={this.handleEditorChange}
                            />
                            {(aiEnabled == true) && <div className="tinyMce_bottomTools" >
                                <div
                                    className="tinyMce_bottomTools_aiAssistant border-darkmode-purple-to-blue-horizontal"
                                    onClick={() => { this.openDrawer("aiContentGenerator") }}
                                >
                                    <UILIB.Icons className="tinyMce_bottomTools_aiAssistant_icon" icon="sparkle2" color="#FFFFFF" style={{ width: "20px", height: "20px" }} />
                                    <div className="tinyMce_bottomTools_aiAssistant_label">{i18n.t('templates:aiContentAssistant.aiContentAssistant')}</div>
                                </div>
                            </div>}
                        </div>
                    }
                })()
                }

            </UILIB.Paper>

            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />


            {this.state.currentDrawer}
        </div>


    };
}
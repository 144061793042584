import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'
import NumberFormatFunctions from '~/Classes/numberFormatFunctions';
import ViewResultsPop from './viewResultsPop'
import ViewOptionsPop from './viewOptionsPop'
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

export default class CleanTemplate extends React.Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
            pageLoaded: false,
            groups: [],
            job: {},
            jobId: 0,
            jobResults: [],
            alertMessage: "",
            alertOpen: false,
            exporting: 0,
            exportedSubscribersPct: 0
        };

        this.getGroup = this.getGroup.bind(this);
        this.getJob = this.getJob.bind(this);
        this.getJobResults = this.getJobResults.bind(this);
        this.viewResults = this.viewResults.bind(this);
        this.viewOptions = this.viewOptions.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.export = this.export.bind(this);
    }

    componentDidMount() {
        if (this.props.siteMaster.listCleanDisabled) return this.props.history.push("/cp")
        this.setState({
            jobId: this.props.match.params.jobId,
        }, this.getJob);
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer)
    }

    getJob() {
        var self = this;
        axios.get('/listcleaner/' + this.state.jobId)
            .then(response => {
                self.setState({ pageLoaded: true, job: response.data }, () => {
                    self.getGroup();
                    self.getJobResults()
                })
            }).catch(err => {
                self.props.history.push(`/cp/subscribers/clean/`)
            })
    }
    getGroup() {
        axios.get('/group')
            .then(response => {
                this.setState({ groups: response.data.Groups })
            });
    }

    getJobResults() {
        var self = this;
        axios.get('/listcleaner/' + this.state.jobId + '/results?orderBy=createdAt&orderDir=desc').then(_resp => {

            var tableData = _resp.data.results.map((item) => {

                var validColor = "red";
                if (item.valid == 1) validColor = "green"
                if (item.disposable == 1) validColor = "orange"
                if (item.free == 1) validColor = "orange"

                return {
                    emailAddress: {
                        headerValue: i18n.t('emailAddress'),
                        value: item.emailAddress
                    },

                    valid: {
                        headerValue: "Valid",
                        value: <UILIB.SquareChip value={item.valid == "1" ? "Valid" : "Not Valid"} className={item.valid == "1" ? "square-chip-green" : "square-chip-red"} />,
                        align: "center"
                    },
                    acceptAll: {
                        headerValue: "Catch All",
                        value: <UILIB.CheckBox checked={item.acceptAll} />,
                        align: "center"

                    },
                    disposable: {
                        headerValue: "Disposable",
                        value: <UILIB.CheckBox checked={item.disposable} />,
                        align: "center"
                    },
                    free: {
                        headerValue: "free address",
                        value: <UILIB.CheckBox checked={item.free} />,
                        align: "center"
                    },
                    noMx: {
                        headerValue: "No Mailserver",
                        value: <UILIB.CheckBox checked={item.noMx} />,
                        align: "center"
                    },
                    role: {
                        headerValue: "Role Based",
                        value: <UILIB.CheckBox checked={item.role} />,
                        align: "center"
                    }
                }
            })
            self.setState({ jobResults: tableData }, () => {
                if (this.state.job.status != 5) self.timer = setTimeout(self.getJob, 5000)
            })
        })
    }

    viewResults(rType) {
        var drawerContent = <ViewResultsPop jobId={this.state.job.id} rType={rType} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "100vw"));
    }

    viewOptions(rType, count) {
        var drawerContent = <ViewOptionsPop jobId={this.state.job.id} rType={rType} job={this.state.job} count={count} groupId={this.state.job.groupId} close={this.closeDrawer} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
    }

    closeDrawer() {
        this.props.dispatch(siteMaster.alterSiteDrawer(false, false, undefined, "", false));
        this.setState({ alertMessage: "Subscribers Updated", alertOpen: true })
        this.getJob()
    }

    export(exportType) {
        var exporting = 0;
        switch (exportType) {
            case "perfect":
                exporting = 1;
                break;
            case "imperfect":
                exporting = 2
                break;
            case "invalid":
                exporting = 3
                break;
        }
        this.setState({ exporting: exporting })

        var self = this;

        axios.post(`/listcleaner/${this.state.jobId}/export?type=${exportType}`)
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `listClean${this.state.jobId}_${exportType}.csv`); //or any other extension
                document.body.appendChild(link);
                link.click();
                self.setState({ exporting: 0 })
            }).catch(exception => {
                self.setState({ exporting: 0 })
            });
    }

    render() {

        if (!this.state.pageLoaded) return <UILIB.LoadingIcons iconType="2" />

        var importStatusText = "Queued";
        if (this.state.job.status == 1) importStatusText = "Copying Data";
        if (this.state.job.status == 2) importStatusText = "Preparing to Start";
        if (this.state.job.status == 3) importStatusText = "In Progress";
        if (this.state.job.status == 4) importStatusText = "Saving Results";
        if (this.state.job.status == 5) importStatusText = "Finished";
        var percentComplete = NumberFormatFunctions.formatNumber((this.state.job.doneCount / this.state.job.subCount) * 100, 2);

        var groupName = "'Group not found'";
        if (this.state.job.groupId) {
            const group = this.state.groups.find(g => g.id === this.state.job.groupId)
            if (group) groupName = '"' + group.groupName + '"';
        } else {
            groupName = this.state.job.groupIds.map(id => {
                const group = this.state.groups.find(g => g.id === id)
                if (group) {
                    return <UILIB.SquareChip key={id} className="square-chip-large mar-r10">{group.groupName}</UILIB.SquareChip>
                } else {
                    return <UILIB.SquareChip key={id} className="square-chip-large mar-r 10">Group Deleted</UILIB.SquareChip>
                }
            })
        }

        if (this.state.job.status == 5) return <div>
            <UILIB.Row >
                <UILIB.Column xs={12} margin={0}>
                    <UILIB.Paper >
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={12} margin={0}>
                                <h4 className="mar-b25">We finished cleaning your list {groupName}</h4>
                                <UILIB.Row >

                                    <UILIB.Column xs={12} sm={12} className="end-xs" margin={0}>
                                        <span className="text-small text-primary text-heavy">{this.state.job.doneCount} ({percentComplete}%)</span>
                                    </UILIB.Column>
                                    <UILIB.Column xs={12} className="mar-b20">
                                        <UILIB.SmallLineGraph width="100%" height="8" hideText={true} onlyPercent={true} val1={this.state.job.doneCount} val2={this.state.job.subCount} />
                                    </UILIB.Column>
                                </UILIB.Row>
                                {(this.state.job.perfectCount > 0) && <p className="text-heavy">Great news! We found {this.state.job.perfectCount} perfect emails which you can be pretty confident will deliver beautifully.</p>}
                                {(this.state.job.imperfectCount > 0) && <p>We found {this.state.job.imperfectCount} emails that were delivered, but may have been free email addresses, role based emails or a catch all email addresses. In other words they are not ideal. We would recommend removing them from your group.</p>}
                                {(this.state.job.imperfectCount > 0) && <p className="text-red text-heavy">Importantly we found  {this.state.job.failedCount} emails that were completely undeliverable, or were marked as dangerous. You should remove these emails from your lists IMMEDIATELY. They will actually contribute towards your good email addresses not being delivered.</p>}
                            </UILIB.Column>

                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>


                <UILIB.Column xs={12} sm={4} margin={0}>
                    <UILIB.Paper className="primary text-center mar-b0">
                        Perfect
                        <div className="text-xl">{this.state.job.perfectCount}</div>
                        <div className="text-xsml mar-b15">You can be confident these addresses will deliver.</div>
                        {!!this.state.job.perfectCount && <UILIB.Button className="outline mar-r10 button-md" text="Options" onClick={() => { this.viewOptions('perfect', this.state.job.perfectCount) }} />}
                        <UILIB.Button className="outline mar-r10 button-md" disabled={this.state.exporting > 0} text="Export" onClick={() => { this.export('perfect') }} />
                        <UILIB.Button className="outline button-md" text="View" onClick={() => { this.viewResults('perfect') }} />
                        {(this.state.exporting == 1) && <div>
                            <UILIB.LoadingLine />
                        </div>}
                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={4} margin={0} >
                    <UILIB.Paper className="secondary text-center mar-b15">
                        Valid but not 100%
                        <div className="text-xl">{this.state.job.imperfectCount}</div>
                        <div className="text-xsml mar-b15">These may not deliver, be careful.</div>
                        {!!this.state.job.removedInvalid && <div className="text-md mar-b15">You removed these addresses</div>}

                        {!!this.state.job.imperfectCount && <UILIB.Button className="outline button-md mar-r10" text="Options" onClick={() => { this.viewOptions('imperfect', this.state.job.imperfectCount) }} />}
                        <UILIB.Button className="outline mar-r10 button-md" disabled={this.state.exporting > 0} text="Export" onClick={() => { this.export('imperfect') }} />
                        <UILIB.Button className="outline button-md" text="View" onClick={() => { this.viewResults('imperfect') }} />
                        {(this.state.exporting == 2) && <div>
                            <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.exportedSubscribersPct} val2={100} />
                        </div>}

                    </UILIB.Paper>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={4} margin={0}>
                    <UILIB.Paper className="negative text-center mar-b15">
                        Invalid
                        <div className="text-xl">{this.state.job.failedCount}</div>
                        <div className="text-xsml mar-b15">Remove these addresses immediately!</div>
                        {!!this.state.job.removedInvalid && <div className="text-md mar-b15">You removed these addresses</div>}
                        {!this.state.job.removedInvalid && <UILIB.Button className="outline mar-r10 button-md" text="Delete/DenyList" onClick={() => this.viewOptions('invalid', this.state.job.failedCount)} />}
                        <UILIB.Button className={"outline mar-r10 button-md "} disabled={this.state.exporting > 0} text="Export" onClick={() => this.export('invalid')} />
                        <UILIB.Button className="outline button-md" text="View" onClick={() => this.viewResults('invalid')} />
                        {(this.state.exporting == 3) && <div>
                            <UILIB.SmallLineGraph width="100%" height="5" hideText={true} val1={this.state.exportedSubscribersPct} val2={100} />
                        </div>}
                    </UILIB.Paper>
                </UILIB.Column>

            </UILIB.Row >
            <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
        </div>

        return <div>
            <UILIB.Row >
                <UILIB.Column xs={12} margin={0}>
                    <UILIB.Paper>
                        <h4 className="mar-b25">We're cleaning your list..</h4>
                        <p>This can take a long time if you have a large list. You are free to leave this page. We will send you an alert when the cleaning has finished.</p>
                        <UILIB.Row >
                            <UILIB.Column xs={8} sm={6} margin={0}>
                                <span className={"text-heavy"}>{importStatusText}</span>
                            </UILIB.Column>
                            <UILIB.Column xs={4} sm={6} className="end-xs" margin={0}>
                                <span className="text-small text-primary text-heavy">{this.state.job.doneCount} ({percentComplete}%)</span>
                            </UILIB.Column>
                            <UILIB.Column xs={12} className="mar-b10">
                                <UILIB.SmallLineGraph width="100%" height="8" hideText={true} onlyPercent={true} val1={this.state.job.doneCount} val2={this.state.job.subCount} />
                            </UILIB.Column>
                        </UILIB.Row>
                    </UILIB.Paper>
                </UILIB.Column>


                {this.state.jobResults && this.state.jobResults.length > 0 &&
                    <UILIB.Column xs={12} sm={4} margin={0}>
                        <UILIB.Paper className="primary text-center">
                            Perfect
                            <div className="text-xl">{this.state.job.perfectCount}</div>
                            <div className="text-xsml">You can be confident these addresses will deliver.</div>
                        </UILIB.Paper>
                    </UILIB.Column>
                }
                {this.state.jobResults && this.state.jobResults.length > 0 &&
                    <UILIB.Column xs={12} sm={4} margin={0} >
                        <UILIB.Paper className="secondary text-center">
                            Valid but not 100%
                            <div className="text-xl">{this.state.job.imperfectCount}</div>
                            <div className="text-xsml">You can send to these addresses, but you might not get through.</div>
                        </UILIB.Paper>
                    </UILIB.Column>
                }
                {this.state.jobResults && this.state.jobResults.length > 0 &&
                    <UILIB.Column xs={12} sm={4} margin={0}>
                        <UILIB.Paper className="negative text-center">
                            Invalid
                            <div className="text-xl">{this.state.job.failedCount}</div>
                            <div className="text-xsml">Do not send to these addresses. They will cause big problems.</div>
                        </UILIB.Paper>
                    </UILIB.Column>
                }
            </UILIB.Row >


            {this.state.jobResults && this.state.jobResults.length > 0 && <UILIB.Paper>
                <div className="title">Live Results..</div>
                <p>Here's a live log of the last 30 emails cleaned, just incase you are interested.</p>
                <UILIB.DataTable1 noResultsTxt={<span> {i18n.t('noResults')}</span>} tableData={this.state.jobResults} loadingData={false}
                    width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn={"createdAt"}
                    sortedDirection={"desc"}
                />
            </UILIB.Paper>}


        </div >

    };
};
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import queryString from 'query-string';

class CleanDynamics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            success: false,
            finished: false,
            processing: false,
            count: 0,
            error: ''
        }
        this.clean = this.clean.bind(this)
    }

    componentDidMount() {
        this.clean()
    }

    clean() {
        const query = queryString.parse(location.search);
        let importIds = query.importIds
        if (!importIds || !Array.isArray(importIds) || importIds.length !== 2) {
            return this.setState({
                error: 'Invalid Link, please try again'
            })
        }
        axios.get('/application/dynamics/clean?importIds=' + importIds[0] + '&importIds=' + importIds[1]).then(res => {
            res.data.processing = false
            this.setState(res.data)
        }).catch(err => {
            this.setState({
                error: err.data.error
            })
        })
    }

    render() {
        return (
            <div className="row">
                <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-sm-offset-1 col-md-offset-2 col-lg-offset-3 center-xs">
                    <div className="paper-class">
                        <h4 className="mar-b25">Remove Deleted Dynamics Leads/Contacts from Transpond</h4>
                        {!!this.state.error && <p className="text-red">{this.state.error}</p>}
                        {!this.state.success && !this.state.error && <p className="loading">Processing</p>}
                        {this.state.success && !this.state.finished && <p>The delete is taking a while, you will be emailed the results, you can leave this screen now</p>}
                        {this.state.success && this.state.finished && <p>Delete Complete....Deleted {this.state.count} Contacts</p>}
                    </div>

                </div>
            </div>
        );
    }
}

export default CleanDynamics;
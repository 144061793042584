import React from "react";
import classnames from "classnames";
import * as styles from "./index.module.scss";

export default function RadioTile({ value, className, children, disabled = false, ...rest }) {
  const classes = classnames(styles.root, className, disabled ? styles.disabled : "");

  return (
    <div className={classes}>
      <input
        type="radio"
        id={value}
        value={value}
        className="visually-hidden"
        disabled={disabled}
        {...rest}
      />

      <label htmlFor={value}>
        <span className={styles.button} />
        {children}
      </label>
    </div>
  );
}

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import i18n from '~/i18n'

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class GroupStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertBar: null,
            pageLoaded: false,
            myAccessLevel: '',
            accountUsersData: [],
            users: [],
            alertMessage: '',
            alertOpen: false,
            accessLevel: undefined,
            userId: undefined,
            deleteStaff: false,
            addStaff: false
        };
        this.loadStaff = this.loadStaff.bind(this);
        this.loadUsers = this.loadUsers.bind(this);
        this.addUser = this.addUser.bind(this);
        this.userSelected = this.userSelected.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.userDeleted = this.userDeleted.bind(this);
        this.updateBind = this.updateBind.bind(this);
        this.updateInput = this.updateInput.bind(this);
        this.stopAll = this.stopAll.bind(this);
    }

    componentDidMount() {
        this.loadStaff()
    }

    //Load Staff stuff
    loadStaff() {
        axios.get('/template/' + this.props.templateId + '/user')
            .then((usersData) => {
                var me = usersData.data.AccountUsers.find(au => au.AccountUserId == this.props.user.userID);
                this.setState({
                    accountUsersData: usersData.data.AccountUsers,
                    myAccessLevel: me ? me.accessLevel : undefined
                }, this.loadUsers);
            })
            .catch((err) => {

            });
    }

    loadUsers() {
        axios.get('/accountUser')
            .then(response => {
                var users = response.data.AccountUsers.filter(u => {
                    return !this.state.accountUsersData.find(user => user.AccountUserId == u.id)
                })
                this.setState({
                    users: users,
                    pageLoaded: true
                })
            })
            .catch((err) => {
                console.log(err)
            });
    }

    addUser() {
        this.setState({
            addStaff: true,
            userId: this.state.users.length ? this.state.users[0].id : undefined,
            accessLevel: 'READ'
        })
    }

    userSelected() {
        if (this.state.userId && this.state.accessLevel) {
            axios.post('/template/' + this.props.templateId + '/user', {
                AccountUserId: this.state.userId,
                accessLevel: this.state.accessLevel
            }).then(() => {
                this.setState({ alertOpen: true, addStaff: false, alertMessage: i18n.t('permissions:users.alertAdded') });
                this.loadStaff()
            }).catch(() => { })
        }
    }

    deleteUser(userId) {
        this.setState({
            deletingUser: userId,
            deleteStaff: true
        })
    }

    userDeleted() {
        var users = this.state.accountUsersData;
        var userIndex = users.findIndex(u => u.AccountUserId == this.state.deletingUser);
        axios.delete('/template/' + this.props.templateId + '/user/' + this.state.deletingUser).then(() => {
            this.setState({ alertOpen: true, deleteStaff: false, deletingUser: undefined, alertMessage: i18n.t('permissions:users.alertRemoved') });
            this.loadStaff()
        })
    }

    updateBind(userId, level) {
        var users = this.state.accountUsersData;
        var user = users.find(u => u.AccountUserId == userId);
        user.accessLevel = level;
        axios.put('/template/' + this.props.templateId + '/user/' + userId, {
            accessLevel: level
        }).then(() => {
            this.setState({ alertOpen: true, alertMessage: i18n.t('permissions:users.alertUpdated'), accountUsersData: users });
        })
    }

    updateInput(evt) {
        this.setState({
            [evt.currentTarget.name]: evt.currentTarget.value
        })
    }

    stopAll() {
        this.setState({
            addStaff: false,
            deleteStaff: false,
            userId: undefined,
            deletingUser: undefined
        })
    }

    render() {
        if (this.state.pageLoaded === false) {
            return <div></div>
        }
        else {
            return <div>
                <div className="end-xs mar-b25">
                    <UILIB.Button iconLeft={<UILIB.Icons icon="plus" />} onClick={this.addUser} >{i18n.t('permissions:users.addStaff')}</UILIB.Button>
                </div>

                <UILIB.Row>
                    {this.state.accountUsersData.map((theUser, index) => {
                        return <UILIB.Column xs={12} sm={6} md={6} lg={3} key={theUser.AccountUserId}>
                            <UILIB.BindCard userData={theUser.AccountUser} myLevel={this.state.myAccessLevel} bind={theUser} level={theUser.accessLevel} user={this.props.user} update={this.updateBind} onDelete={this.deleteUser} />
                        </UILIB.Column>
                    })}
                </UILIB.Row>


                <UILIB.Drawer close={this.stopAll} standalone={true} isOpen={this.state.addStaff} showClose={true}>
                    {(!this.state.users || !this.state.users.length) ? <div>{i18n.t('permissions:users.noUsers')}</div>
                        :
                        <div>
                            <h4 className="mar-b25">{i18n.t('permissions:users.inviteUser')}</h4>

                            <UILIB.Select outerClassName="mar-b25" name="userId" data={this.state.users.map(user => {
                                return {
                                    label: user.fullname,
                                    value: user.id
                                }
                            })} value={this.state.user} onChange={this.updateInput} />


                            <UILIB.Select outerClassName="mar-b25" label={i18n.t('permissions:users.level')} name="accessLevel" data={[
                                { label: i18n.t('permissions:users.READ'), value: 'READ' },
                                { label: i18n.t('permissions:users.WRITE'), value: 'WRITE' }
                            ]} value={this.state.level} onChange={this.updateInput} />

                            <UILIB.Button text={i18n.t('permissions:users.add')} className="button-primary" onClick={this.userSelected} />

                        </div>
                    }
                </UILIB.Drawer>
                <UILIB.Drawer close={this.stopAll} standalone={true} isOpen={this.state.deleteStaff} showClose={true}>
                    <div>
                        <h4 className="mar-b25">{i18n.t('permissions:users.removeHeader')} {i18n.t('template')}?</h4>

                        <div className="quickFlex">
                            <UILIB.Button text={i18n.t('yes')} className="button-primary mar-r10" onClick={this.userDeleted} />
                            <UILIB.Button text={i18n.t('cancel')} onClick={this.stopAll} />
                        </div>
                    </div>
                </UILIB.Drawer>


                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
            </div >
        }
    };


};



import React, { useReducer, useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import { wizardStepsReducer, initialWizardStepsState } from "~/Classes/wizard";
import { alterSnackbar, handleGenericError } from "~/data/actions/siteActions";
import copy from "copy-to-clipboard";
import i18n from "~/i18n";
import Button from "../../components/button";
import * as styles from "./index.module.scss";
import TextInput from "../../components/textInput";

export default function ConnectWebchatChannelPage({ userRole = null }) {
  const { siteMaster } = useSelector((state) => state);
  const { inboxId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedTrackers, setSelectedTrackers] = useState([]);

  const [addingNewWebsite, setAddingNewWebsite] = useState(false);
  const [savingNewWebsite, setSavingNewWebsite] = useState(false);
  const [newWebsiteName, setNewWebsiteName] = useState("");
  const [newWebsiteNameError, setNewWebsiteNameError] = useState("");
  const [channelname, setChannelName] = useState("");

  const [steps, updateSteps] = useReducer(
    wizardStepsReducer,
    initialWizardStepsState(2)
  );

  const [accountMaster, updateAccountMaster] = useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    {
      loading: true,
      data: null,
    }
  );

  const [trackers, updateTrackers] = useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    {
      loading: true,
      data: [],
    }
  );

  const [formErrors, updateFormErrors] = useReducer(
    (state, action) => {
      return { ...state, ...action };
    },
    {
      trackers: null,
    }
  );

  useEffect(() => {
    if (userRole > 0) return window.open("/", "_self");
    loadInitialData();
  }, []);

  async function loadInitialData() {
    await Promise.all([getAccountMaster(), getTrackers()]);
  }
  async function getAccountMaster() {
    try {
      const { data } = await axios.get("/accountMaster");
      updateAccountMaster({ data: data.AccountMaster });
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    } finally {
      updateAccountMaster({ loading: false });
    }
  }
  async function getTrackers() {
    try {
      updateTrackers({ loading: true });
      const { data } = await axios.get("/accountMaster/tracker");
      updateTrackers({ data: data.Trackers });
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    } finally {
      updateTrackers({ loading: false });
    }
  }
  function handleTrackerChange(id) {
    if (selectedTrackers.includes(id)) {
      setSelectedTrackers((prev) => prev.filter((tracker) => tracker !== id));
    } else {
      setSelectedTrackers((prev) => [...prev, id]);
    }
  }
  async function handleStep1Submit(e) {
    if (e) e.preventDefault();
    try {
      await validateStep1Form();
      updateSteps({ type: "complete", index: 0 });
    } catch (error) {
      setSaving(false);
    }
  }
  async function validateStep1Form() {
    const inputs = await Promise.allSettled([validateStep1()]);
    const hasInvalidInput = inputs.some((input) => input.status === "rejected");

    if (hasInvalidInput) throw new Error(i18n.t("chat:channel.connect.chat.step2.invalid"));
  }
  async function validateStep1() {
    try {
      if (selectedTrackers.length === 0) {
        throw i18n.t("chat:channel.connect.chat.step2.errRequired");
      }
      updateFormErrors({ trackers: null });
    } catch (error) {
      updateFormErrors({ trackers: error });
      throw error;
    }
  }
  async function handleStep2Submit(e) {
    e.preventDefault();
    try {
      if (!channelname || channelname.trim().length < 4) {
        throw i18n.t("chat:channel.connect.chat.step3.required");
      }
      if (channelname.length > 100) {
        throw i18n.t("chat:channel.connect.chat.step3.maxlength");
      }
      updateSteps({ type: "complete", index: 2 });
      const newChannel = await connectChannel();
      history.push(`/cp/conversations/${inboxId}/channels/${newChannel.id}`);

    } catch (error) {
      console.log(error);
      updateFormErrors({ channelName: error });
      setSaving(false);
    }
  }
  async function connectChannel() {
    try {
      const { data } = await axios.post(`/inboxes/${inboxId}/channel`, {
        name: channelname,
        status: 'draft',
        type: 2,
        siteTrackers: selectedTrackers
      });
      return data;
    } catch (error) {
      dispatch(handleGenericError(error));
      throw error;
    }
  }
  function copyToClipboard(string) {
    copy(string);
    dispatch(alterSnackbar(true, "Copied to clipboard"));
  }
  const saveNewWebsite = async () => {
    try {
      setNewWebsiteNameError("");
      let tmpNewWebsiteName = newWebsiteName.toLowerCase().replace("https://", "").replace("http://", "").replace("www.", "").trim("");
      if (!tmpNewWebsiteName || tmpNewWebsiteName.length < 5) {
        setNewWebsiteNameError("Invalid URL");
        return
      }

      if (tmpNewWebsiteName.indexOf(".") < 0 && tmpNewWebsiteName.indexOf(":") < 0) {
        setNewWebsiteNameError("Invalid URL");
        return
      }

      if (tmpNewWebsiteName.length > 254) {
        setNewWebsiteNameError("URL is too long");
        return
      }

      setSavingNewWebsite(true);
      const resp = await axios.post('/accountMaster/tracker', { host: tmpNewWebsiteName })
      setSavingNewWebsite(false);
      setAddingNewWebsite(false);
      setNewWebsiteName("");
      setSelectedTrackers((prev) => [...prev, resp.data.id]);
      getTrackers();
      updateSteps({ type: "complete", index: 0 });
    }
    catch (e) {
      setNewWebsiteNameError(e?.data?.error || "Error adding site")
      setSavingNewWebsite(false);
    }
  }
  const embedCode = `<script src="https://${siteMaster.trackingDomain}/tracker?am=${accountMaster.data?.base64id}"></script>`;

  return (
    <div className="wizard-page connect-webchat-channel-page" >

      <a onClick={() => history.goBack()} className="wizard-page__close">
        <UILIB.Icons icon="cross" color="black" />
      </a>

      <div className="wizard-page__inner">
        <div className="center-xs mar-b60">
          <h1 className="h2 mar-b10">{i18n.t("chat:channel.connect.chat.header")}</h1>
          <p className="text-md text-grey">{i18n.t("chat:channel.connect.chat.subheader")}</p>
        </div>

        <ol className="faux-list">
          <li>
            <UILIB.WizardStep
              heading={i18n.t("chat:channel.connect.chat.step1.websites")}
              onClick={() => updateSteps({ type: "collapse", index: 0 })} {...steps[0]}
            >
              {(trackers && trackers.data?.length > 0) && <>
                <div className="mar-t18">
                  <div className="text-grey mar-b25">{i18n.t("chat:channel.connect.chat.step1.selectWebsites")}</div>
                  <div className="mar-b25 connect-webchat-channel-page__trackers">
                    {trackers.data.map((tracker) => {
                      const { id, host, InboxChannelId: inboxChannelId } = tracker;
                      const webchatAlreadyEnabled = inboxChannelId !== null;
                      return (
                        <UILIB.CheckBox key={id} name="access" value={id} checked={selectedTrackers.includes(id)} disabled={webchatAlreadyEnabled} onChange={() => handleTrackerChange(id)} >
                          {host}
                          {webchatAlreadyEnabled && (
                            <span className="fw-normal">
                              &nbsp;{i18n.t("chat:channel.connect.chat.step2.alreadyEnabled")}
                            </span>
                          )}
                        </UILIB.CheckBox>
                      );
                    })}
                  </div>
                  {formErrors.trackers && <UILIB.FormError className="mar-b25">{formErrors.trackers}</UILIB.FormError>}
                  <div style={{ display: "flex" }} className="mar-b25">
                    <Button className="mar-r10" variant="primary" size="s" iconRight={<UILIB.Icon name="arrow-right" />} onClick={handleStep1Submit}>{i18n.t("chat:channel.connect.chat.step1.saveAndContinue")}</Button>
                    <Button variant="secondary" size="s" onClick={() => { setAddingNewWebsite(true) }}>{i18n.t("chat:channel.connect.chat.step1.websiteNotListed")}</Button>
                  </div>
                </div>
              </>}


              {(addingNewWebsite || !trackers?.data?.length) && <>
                <div className={addingNewWebsite ? styles.teaser + " " + styles.teaser_border + " mar-b25" : styles.teaser + " no-pad mar-b25"}>
                  {addingNewWebsite && <h4 className="mar-b5">{i18n.t("chat:channel.connect.chat.step1.addAWebsite")}</h4>}
                  <div className="text-grey">{i18n.t("chat:channel.connect.chat.step1.addAWebsiteDesc")}</div>
                  <div className="text-heavy mar-b0">{i18n.t("chat:channel.connect.chat.step1.trackingCode")}</div>
                  <div className="width-100" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 8 }}>
                    <UILIB.TextInput
                      className="txtInput-greyedOut"
                      outerClassName="width-100"
                      style={{ padding: 6 }}
                      value={embedCode}
                      readOnly={true}
                    />
                    <Button variant="secondary" size="s"
                      onClick={() => { copyToClipboard(embedCode) }}>{"Copy"}</Button>
                  </div>

                  <div className="text-heavy mar-b0">{i18n.t("chat:channel.connect.chat.step1.websiteAddress")}</div>
                  <UILIB.TextInput
                    style={{ padding: 6 }}
                    value={newWebsiteName}
                    onChange={(ev) => { setNewWebsiteName(ev.target.value) }}
                    outerClassName="width-100"
                    placeholder="mywebsite.com"
                    error={newWebsiteNameError}
                  />

                  <div style={{ display: "flex" }}>
                    <Button disabled={savingNewWebsite} className="mar-r10" variant="primary" size="s" iconRight={<UILIB.Icon name="arrow-right" />} onClick={saveNewWebsite}>{i18n.t("chat:channel.connect.chat.step1.saveAndContinue")}</Button>
                    <Button disabled={savingNewWebsite} variant="secondary" size="s" onClick={() => { setAddingNewWebsite(false) }}>{i18n.t("chat:channel.connect.chat.step1.cancel")}</Button>
                  </div>
                </div>

              </>}

              <UILIB.Paper className="paper-information">
                <div dangerouslySetInnerHTML={{ __html: i18n.t("chat:channel.connect.chat.step1.trackingInfo") }} />

              </UILIB.Paper>

            </UILIB.WizardStep>
          </li>
          <li>
            <UILIB.WizardStep heading={i18n.t("chat:channel.connect.chat.step2.details")} onClick={() => updateSteps({ type: "collapse", index: 1 })} {...steps[1]}>
              <div className="mar-t15">
                {i18n.t("chat:channel.connect.chat.step2.header")}

                <UILIB.TextInput maxlength="100" className="mar-t15" value={channelname} onChange={(ev) => { setChannelName(ev.target.value) }} error={formErrors.channelName} />
              </div>
              <UILIB.Button className="button-primary mar-t18" onClick={handleStep2Submit}>{i18n.t("chat:channel.connect.chat.step3.continue")}</UILIB.Button>
            </UILIB.WizardStep>
          </li>

        </ol>
      </div>
    </div >
  );
}

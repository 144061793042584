import React, { Component, Fragment } from 'react';
import UILIB from '~/Common-Objects/Lib'
import SiteVars from '~/Classes/siteVars';
import InfiniteScroll from 'react-infinite-scroller';
import i18n from '~/i18n'
import { connect } from 'react-redux';
import Draggable from '../../../../Common-Objects/UI/Draggable'

@connect((store) => {
    return { accountMaster: store.accountMaster }
})

class FileTiles extends Component {
    constructor(props) {
        super(props);
    }




    render() {
        const loader = <UILIB.LoadingLine key={0}></UILIB.LoadingLine>;
        if (this.props.files.length === 0 && this.props.searching) return <div className="infinite-holder" style={{ height: " calc(100vh - 220px)" }}><UILIB.LoadingIcons iconType="2" /></div>
        return (
            <div className="filemanager-tiles-holder">

                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => this.props.loadMore(false)}
                    hasMore={this.props.hasMore}
                    loader={loader}
                    className="infinite-scroller"
                    useWindow={false}>
                    <Draggable className="infinite-holder" onAdd={this.props.onAdd}>
                        {this.props.files.length === 0 && !this.props.searching && <div className="filemanager-tiles-holder-dragdroparea col-xs-12">
                            {i18n.t('templates:fileManager.dragDrop')}
                        </div>}

                        <div className="row no-marg">

                            {this.props.files.map((file, index) => {
                                var tileClasses = "filemanager-tile";
                                var selected = (this.props.currentFile && this.props.currentFile.fileId == file.fileId)
                                if (selected) tileClasses += " filemanager-tile-selected";
                                var url = SiteVars.repoEndPoint + file.filePath + encodeURIComponent(file.fileName) + '?' + this.props.tick;
                                var isImage = true;
                                var isSystem = false;
                                if (file.fileExtension == "SYS") {
                                    isSystem = true;
                                    if (file.fileName == "{{__COMPANYLOGO}}") {
                                        url = SiteVars.repoEndPoint + this.props.accountMaster.accountMaster.companyLogo + '?' + this.props.tick;
                                    }
                                }

                                if (file.fileExtension == "pdf") isImage = false;

                                return <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3" key={"fileTile_" + index}>

                                    <div className={tileClasses} onClick={() => this.props.fileSelected(file)}>
                                        <div className="filemanager-tile-image" style={{ backgroundImage: "url('" + url + ")'" }}>
                                            {isImage && <img src={url} style={{ display: "none" }} id={"aviaryImg_" + index} />}
                                            {!isImage && !selected && <div className="filemanager-tile-controls">
                                                <div className="filemanager-tile-pdf">
                                                    <div className="filemanager-tile-pdf-name">
                                                        <UILIB.Icons icon="document" />
                                                    </div>
                                                    <div className="filemanager-tile-pdf-name">{file.friendlyName}</div>
                                                </div>
                                            </div>}
                                        </div>
                                        {selected && <div className="filemanager-tile-controls">
                                            {!isSystem && <div className="filemanager-tile-controls-top">

                                                <div>
                                                    <UILIB.Icons
                                                        icon="folder"
                                                        onClick={() => this.props.move()}
                                                        alt={i18n.t('templates:fileManager.details.move')}
                                                        title={i18n.t('templates:fileManager.details.move')}
                                                        className="mar-r10"
                                                    />

                                                    <UILIB.Icons
                                                        icon="copyClipboard"
                                                        onClick={() => this.props.copy(url)}
                                                        alt={i18n.t('templates:fileManager.details.copy')}
                                                        title={i18n.t('templates:fileManager.details.copy')}
                                                    />
                                                </div>
                                                <div>
                                                    {isImage && <UILIB.Icons
                                                        className="mar-r10"
                                                        icon="wand"
                                                        onClick={() => this.props.aviaryEdit("aviaryImg_" + index, file)}
                                                        alt={i18n.t('templates:fileManager.details.edit')}
                                                        title={i18n.t('templates:fileManager.details.edit')}
                                                    />}
                                                    <UILIB.Icons
                                                        icon="bin"
                                                        onClick={() => this.props.delete()}
                                                        alt={i18n.t('templates:fileManager.details.delete')}
                                                        title={i18n.t('templates:fileManager.details.delete')}
                                                    />
                                                </div>

                                            </div>}

                                            <div className="filemanager-tile-controls-selectIcon">
                                                {/* <UILIB.Icons
                                                    icon="arrowSelect"
                                                    onClick={() => this.props.select()}
                                                    alt={i18n.t('templates:fileManager.details.select')}
                                                    title={i18n.t('templates:fileManager.details.select')}
                                                /> */}
                                                <UILIB.Button text={i18n.t('templates:fileManager.details.select')} onClick={() => this.props.select()} />
                                            </div>

                                            <div className="filemanager-tile-controls-lower">

                                                <div className="filemanager-tile-controls-name">{file.friendlyName}</div>
                                                {!isSystem && <UILIB.Icons className="mar-l10" icon="pencil" onClick={() => this.props.rename()} alt={i18n.t('templates:fileManager.details.rename')} title={i18n.t('templates:fileManager.details.rename')} />}


                                            </div>

                                        </div>}
                                    </div>
                                </div>
                            })}

                            {this.props.searching && <UILIB.LoadingLine key={0}></UILIB.LoadingLine>}

                        </div>
                    </Draggable>
                </InfiniteScroll>
            </div >

        );
    }
}

export default FileTiles;
import React, { useState, useEffect } from "react";
import UILIB from "~/Common-Objects/Lib";
import { useHistory } from "react-router-dom";
import axios from "~/data/http/axios";
import i18n from "~/i18n";
import { useDispatch, useSelector } from 'react-redux';
import Rum from '../../../Classes/rum'

export default function InboxPage({ ...props }) {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const account = useSelector(state => state.accountMaster)
    const [labsDrawer, setLabsDrawer] = useState(undefined);
    const [hasChatBeta, setHasConversationsBeta] = useState(account?.accountMaster?.beta?.chat);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        let inboxes = await axios.get('/inboxes')
        inboxes = inboxes.data;
        if (inboxes.length > 0) {
            return history.push("/cp/conversations/inbox/" + inboxes[0].id);
        } else {
            Rum.trackEvent('conversations_landing_beta')
        }
        setLoading(false);
    }

    const createInbox = async () => {
        let { data } = await axios.post("/inboxes", {
            name: "Support"
        });
        history.push("/cp/conversations/inbox/" + data.id);
    }



    if (loading) return <UILIB.LoadingIcons iconType="2" />;


    return (
        <UILIB.Paper style={{ paddingBottom: 0 }}>
            <UILIB.Row className="mar-b25">
                <UILIB.Column xs={12} sm={6}>
                    <h2>{i18n.t('chat:landing.mainheader')}</h2>
                </UILIB.Column>
                <UILIB.Column xs={12} sm={6} style={{ textAlign: "right" }}>
                    {hasChatBeta && <UILIB.Button text="Create  Inbox" iconLeft={<UILIB.Icons icon="plus" />} onClick={createInbox} />}
                </UILIB.Column>
            </UILIB.Row>

            <UILIB.Row style={{ marginBottom: 50 }}>
                <UILIB.Column xs={12} lg={10}
                    className="col-lg-offset-1"
                    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                    <h2 className="mar-b15">{i18n.t('chat:landing.subheader')}</h2>
                    <div className="mar-b25">{i18n.t('chat:landing.desc')}</div>
                    {!hasChatBeta && <div className="mar-b25 text-heavy">
                        Conversations is currently a new feature that is currently available in BETA form.
                    </div>}
                    <div style={{ display: "flex", alignItems: "center" }}>

                        {!hasChatBeta && <><UILIB.Button text={i18n.t('chat:landing.labsEnable')}
                            iconRight={<UILIB.SquareChip
                                tooltip={"Beta Feature"}
                                for={"Beta Feature"}
                            >Labs</UILIB.SquareChip>}
                            className="mar-r10 button-primary"
                            onClick={() => {
                                setLabsDrawer(<UILIB.LabsEnable labId={6} onClose={(labAdded) => {
                                    setLabsDrawer(undefined);
                                    if (labAdded) {
                                        createInbox();
                                        return;
                                    }
                                    setHasConversationsBeta(labAdded)
                                }} />)
                            }} />
                        </>}

                        {hasChatBeta && <UILIB.Button
                            text={i18n.t('chat:landing.create')}
                            iconLeft={<UILIB.Icons icon="plus" />}
                            onClick={createInbox}
                            className="mar-r10 button-primary" />
                        }
                        <UILIB.Button
                            className="button-lightGrey"
                            text={i18n.t('chat:landing.help')}
                            onClick={() => window.open("https://kb.transpond.io/article/170-conversations-overview", "_blank")}
                            iconRight={<UILIB.Icons icon="external" />} />
                    </div>
                </UILIB.Column>
            </UILIB.Row>
            <div style={{ textAlign: "center" }}>
                <img
                    src="https://cdn1.ourmailsender.com/siteContent/conversations/attract/conversationsAttract.png"
                    style={{ width: "100%", maxWidth: "1000px" }}></img>
            </div>
            {labsDrawer}
        </UILIB.Paper >
    );
}

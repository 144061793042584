import React from 'react'
import axios from '../../../../data/http/axios'
import { connect, useSelector } from 'react-redux';
import BBNotifications from './bb_notifications/bb_notifications'
import BBAccount from './bb_account/bb_account'
import BBMobileNav from './bb_mobileNav/bb_mobileNav'
import BBStandardNav from './bb_standardNav/bb_standardNav'
import Banner from './banner'
import { I18n } from 'react-i18next'
import CompanySelect from './bb_topNav_CompanySelect'
import UILIB from '../../../../Common-Objects/Lib';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import CreateCampaign from '../../campaigns/add/drawerAddCampaignStep1'
import CreateTemplate from '../../templates/add/templatesAdd'
import CreateForm from '../../forms/GroupSelect'
import CreateAutomation from '../automations/automationThemeSelector'
import CreateSub from './createSubSelectGroup'
import PermissionChecker from '../../../../Classes/permissions'
import NewUserForm from './newUserForm/newUserForm';
import moment from 'moment'
import queryString from 'query-string'

import PremiumSupport from './premiumSupportDrawer'
import UpgradeDrawer from '../upgrade/upgradeTiers'
import Suggestion from './suggestion'
import BBMobileCompanySelect from "./bbMobileCompanySelect";

/*add campaign dialogue*/
import DrawerAddCampaignStep1 from '~/pages/cp/campaigns/add/drawerAddCampaignStep1';
import Progress from './progress';

import FeedbackPopups from './feedbackpopups'
@connect((store) => {
  return { user: store.user, browser: store.browser, accountMaster: store.accountMaster, siteMaster: store.siteMaster, permissionStore: store.permission }
})

class BBTopNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawer: null,
      changingAccounts: false,
      createOpen: false,
      switchAccountContent: "",
      counts: {
        support: 0
      },
      lastCheck: moment()
    };
    this.addNewCampaign = this.addNewCampaign.bind(this);
    this.changeAccount = this.changeAccount.bind(this);
    this.goUpgrade = this.goUpgrade.bind(this);
    this.goPremiumSupport = this.goPremiumSupport.bind(this);
    this.suggestion = this.suggestion.bind(this);
    this.createNew = this.createNew.bind(this)
    this.hasPermission = this.hasPermission.bind(this)
    this.getAlerts = this.getAlerts.bind(this);
    this.sendChatNotification = this.sendChatNotification.bind(this)
    this.doUserQuestions = this.doUserQuestions.bind(this)
    this.timer;
  }


  componentDidMount() {
    this.getAlerts()
    this.timer = setInterval(this.getAlerts, 10000);

    const query = queryString.parse(location.search);
    if (query.showNewUserForm) this.doUserQuestions()
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  doUserQuestions() {
    if (this.props.accountMaster.accountMaster.doneNewUserQuestions || this.props.accountMaster.accountMaster.approved) return
    setTimeout(() => {
      this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", <NewUserForm />, true, '100vw', 'full'));
    }, 500)
  }

  async getAlerts() {
    let counts = { ...this.state.counts };
    if (this.props.accountMaster.accountMaster?.beta?.chat) {
      let supportCnt = await axios.get("/inboxes/chat/new/count?lastCheck=" + this.state.lastCheck.format('YYYY-MM-DD HH:mm:ss'))
      if (supportCnt && supportCnt.data) {
        counts.support = supportCnt.data.count;
        if (supportCnt.data.recent) {
          //this.sendChatNotification(supportCnt.data.recent)
        }
      }
    }
    this.setState({ counts, lastCheck: moment() })
  }

  sendChatNotification(recent) {
    if (!this.props.user.userData.chatOnline) return
    if (this.props.notifications?.permission !== 'granted') return

    this.props.notifications.trigger(
      this.props.siteMaster.siteName + ' Marketing',
      {
        body: 'Chat Available',
        tag: recent.id
      }
    ).then(notification => {
      if (notification) {
        notification.addEventListener('click', (e) => {
          this.props.history.push('/cp/interact/chat?chatId=' + e.target.tag)
        })
      }
    })
  }

  addNewCampaign() {

    var drawerContent = <DrawerAddCampaignStep1 history={this.props.history} />
    this.props.dispatch(siteMaster.alterSiteDrawer(true, false, "left", drawerContent, true, 500, "drawer_nopad"));
  }

  goUpgrade() {
    var drawerContent = <UpgradeDrawer history={this.props.history} close={() => {
      this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null));
    }} />
    this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "1000px"));
  }

  goPremiumSupport() {
    var drawerContent = <PremiumSupport close={() => {
      this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null));
    }} />
    this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "left", drawerContent, true, "400px"));
  }

  changeAccount() {
    this.setState({ switchAccountContent: <CompanySelect close={() => { this.setState({ switchAccountContent: "" }) }} onChangeAccount={() => this.setState({ changingAccounts: true })} changingAccounts={() => { this.setState({ changingAccounts: true }) }} /> });
    return;
  }

  suggestion() {
    var theDrawer = <Suggestion finish={() => {
      this.props.dispatch(siteMaster.alterSiteDrawer(false));
    }}></Suggestion>
    this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, false, '500px'));
  }

  createNew(type) {
    this.setState({ createOpen: false })
    let drawerContent = ''
    let width = 500
    let classname = ""
    let showClose = true
    switch (type) {
      case 'campaign':
        drawerContent = <CreateCampaign history={this.props.history} />
        classname = 'drawer_nopad'
        showClose = false
        break;
      case 'subscriber':
        drawerContent = <CreateSub history={this.props.history} />
        break;
      case 'template':
        width = "100vw";
        drawerContent = <CreateTemplate history={this.props.history} />
        break;
      case 'automation':
        width = "100vw";
        drawerContent = <CreateAutomation scratch={() => this.props.history.push('/cp/automation/addnew')} select={this.selectTheme.bind(this)} />
        break;
      case 'form':
        drawerContent = <CreateForm history={this.props.history} />
        break;
    }
    if (drawerContent) {
      this.props.dispatch(siteMaster.alterSiteDrawer(true, showClose, "right", drawerContent, false, width, classname));
    }
  }

  selectTheme(theme) {
    axios.post('/automation/theme/' + theme.id).then(response => {
      this.setState({
        alertMessage: i18n.t('automation:overview.automationDuplicated'),
        alertOpen: true
      })
      this.props.history.push('/cp/automation/addnew/' + response.data.Automation.id)
    })
  }

  hasPermission(name, method) {
    return PermissionChecker(name, this.props.permissionStore.permissions, method)
  }


  render() {
    if (!this.props.user.loggedIn) return <div></div>

    var loginCnt = 0;
    if (this.props.user && this.props.user.userData && this.props.user.userData.loginCount && this.props.user.userData.loginCount) {
      loginCnt = this.props.user.userData.loginCount
    }
    var showBilling = true;

    if (this.props.siteMaster.hidePricing) showBilling = false;
    if (this.props.accountMaster.accountMaster.invoice) showBilling = false;
    if (this.props.accountMaster.accountMaster.accountType != "free") showBilling = false;

    let canSwitchAccount = true;
    if (this.props.siteMaster.blockSwitchAccount) canSwitchAccount = false;

    let isCreditAccount = this.props.accountMaster.accountMaster.billingFrequency == 2;
    let creditAccountCredits = this.props.accountMaster.accountMaster.credits || 0;
    const canUpgrade = this.props.accountMaster.accountMaster.approved && !this.props.accountMaster.accountMaster.deny;
    const canCreate = this.hasPermission('campaigns', 'write') || this.hasPermission('subscribers', 'write') || this.hasPermission('templates', 'write') || this.hasPermission('automations', 'write') || this.hasPermission('forms', 'write')

    return <I18n ns="nav">
      {
        (t, { i18n }) => (
          <div>
            <Banner history={this.props.history} />
            <nav className="cp-header">

              <div className="cp-header-start">
                <div className="cp-header-start-navigation">
                  <BBStandardNav history={this.props.history} counts={this.state.counts} />
                  <div className="hide-md hide-lg pos-rel">
                    <BBMobileCompanySelect onChangeAccount={() => this.setState({ changingAccounts: true })} />
                  </div>
                </div>
              </div >

              <div className="cp-header-end">
                <ul className="cp-header-end-ul">
                  {this.props.siteMaster.siteId === 1 && <li className="hide-xs hide-sm hide-md"><Progress /></li>}
                  {!isCreditAccount && <li className="hide-xs hide-sm hide-md">
                    {canCreate && <UILIB.ButtonSelect headerText={t('translations:createNew')} iconLeft={<UILIB.Icons icon="plus" />} overrideButtonClass={true} hideIconRight={true} className="outline primary">
                      <ul className='bb_account'>
                        {this.hasPermission('campaigns', 'write') && <li onClick={() => this.createNew('campaign')}>
                          <UILIB.Icons icon="outbox" style={{ height: 20, width: 20 }} color="#A9A9AD" />
                          {t('translations:campaign')}
                        </li>}
                        {this.hasPermission('subscribers', 'write') && <li onClick={() => this.createNew('subscriber')}>
                          <UILIB.Icons icon="personAdd" style={{ height: 20, width: 20 }} color="#A9A9AD" />
                          {t('translations:Subscriber')}
                        </li>}
                        {this.hasPermission('templates', 'write') && <li onClick={() => this.createNew('template')}>
                          <UILIB.Icons icon="template" style={{ height: 20, width: 20 }} color="#A9A9AD" />
                          {t('translations:template')}
                        </li>}
                        {this.hasPermission('automations', 'write') && <li onClick={() => this.createNew('automation')}>
                          <UILIB.Icons icon="lightning" style={{ height: 20, width: 20 }} color="#A9A9AD" />
                          {t('translations:automation')}
                        </li>}
                        {this.hasPermission('forms', 'write') && <li onClick={() => this.createNew('form')}>
                          <UILIB.Icons icon="stack" style={{ height: 20, width: 20 }} color="#A9A9AD" />
                          {t('translations:signupForm')}
                        </li>}
                      </ul>
                    </UILIB.ButtonSelect>}
                  </li>}
                  {isCreditAccount && <li className="hide-xs hide-sm hide-md">
                    <UILIB.Button
                      text={creditAccountCredits + " Credits"}
                      iconRight={<UILIB.Icons icon="arrowRight" />}
                      onClick={() => {
                        if (canUpgrade) {
                          this.goUpgrade()
                        }
                      }} />
                  </li>}
                  <li className="hide-xs hide-sm hide-md">
                    <BBNotifications history={this.props.history} changingAccounts={this.state.changingAccounts} />
                  </li>
                  <li className="nav-item-hide last hide-xs">
                    <BBAccount
                      history={this.props.history}
                      onChangeAccount={() => this.setState({ changingAccounts: true })}
                    />
                  </li>
                  <li className="nav-icon hide-md hide-lg mar-l10">
                    <BBMobileNav history={this.props.history} />
                  </li>
                </ul>
              </div>
            </nav >


            <div className={"connectionError " + (this.props.siteMaster.connectionError ? 'open' : '')}>
              <div className="connectionErrorText">
                {this.props.siteMaster.connectionError}
              </div>
            </div>

            <section className="nav-main">
              {this.props.children}
            </section>

            {this.state.drawer}
            <FeedbackPopups />
          </div >
        )
      }
    </I18n>

  }
}


function withHooks(Component) {
  return function Wrapped(props) {
    const account = useSelector(state => state.accountMaster)
    const hook = UILIB.useNotifications({ disable: !account.accountMaster?.beta?.chat })
    return <Component {...props} notifications={hook} />
  }
}

export default withHooks(BBTopNav);
import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';


export default class WarningHolder extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        let className = "warning-holder";
        if (this.props.className) className += " " + this.props.className;
        if (this.props.darkmode) className += " warning-holder-darkmode"
        let iconLeft = "";
        if (this.props.iconLeft) {
            iconLeft = <div className="warning-holder-iconLeft">{this.props.iconLeft}</div>;
        }
        let style = {};
        if (this.props.style) style = this.props.style;

        return <div className={className} style={style}>
            {iconLeft}
            <div className="warning-holder-inner">
                {this.props.children}
            </div>
        </div>
    }
}


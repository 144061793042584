import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import automationFunctions from '../automationFunctions';
import DateTimeFunctions from '../../../../../Classes/dateTimeFunctions';
import HelpPopup from '../helpPopup/helpPopup';

@connect((store) => {
    return { user: store.user }
})
class DrawerDelayStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerContent: undefined,
            step: this.props.step,
            errors: {}
        }

        this.updateValue = this.updateValue.bind(this);
        this.saveStep = this.saveStep.bind(this);
        this.chooseDaysOfWeek = this.chooseDaysOfWeek.bind(this);
        this.updateDateValue = this.updateDateValue.bind(this);
    }

    componentDidMount() {
        if (!this.state.step.stepOptions.delay_time_timezone?.value) {
            this.updateValue({ target: { name: 'delay_time_timezone', value: this.props.user.userData.timeZone } })
        }
    }

    updateValue(event) {
        var step = this.state.step;
        if (!step.stepOptions[event.target.name]) step.stepOptions[event.target.name] = {}
        step.stepOptions[event.target.name].value = event.target.value;
        step.complete = false;
        this.setState(step)
    }

    updateDateValue(event, dateEvent) {
        var step = this.state.step;
        step.stepOptions.delay_exactDate.value = dateEvent.format("YYYY-MM-DD");
        step.complete = false;
        this.setState(step)
    }

    chooseDaysOfWeek(event) {
        var step = this.state.step;
        var days = step.stepOptions.delay_daysOfWeek.value;
        var isChecked = event.target.checked;
        var ourDay = event.target.name;

        var foundDay = days.indexOf(ourDay)
        if (!isChecked && foundDay > -1) days.splice(foundDay, 1)
        if (isChecked && foundDay == -1) days.push(ourDay)
        step.stepOptions.delay_daysOfWeek.value = days;
        step.complete = false;
        this.setState(step);
    }

    saveStep() {
        var errors = {};
        var error = false;
        var step = this.state.step;

        if (this.state.step.stepOptions.delay_unit.value.indexOf("next_") < 0) {
            if (this.state.step.stepOptions.delay_value.value == "") {
                error = true;
                errors.delay_value = " "
            }
        }
        if (step.stepOptions.delay_unit.value == "next_day" && !step.stepOptions.delay_daysOfWeek.value.length) {
            error = true;
            errors.delay_dayOfWeek = i18n.t('automation:add.delay.error')
        }

        this.setState({ errors })

        if (!error) {
            //finish
            step.complete = true;
            step.wizardComplete = true;
            this.props.saveStep()
        }

    }

    render() {
        const { readOnly } = this.props;

        var typeData = automationFunctions.delayTypes();
        var minsData = automationFunctions.delayMins();
        var hoursData = automationFunctions.delayHours();
        var weekDays = automationFunctions.delayWeekDays();
        var daysOfMonth = automationFunctions.delayDaysOfMonth();


        var dateAndTimePicker = <div className="row" style={{ alignItems: "center" }}>
            <div className="col-xs-6">
                <UILIB.Select name="delay_time_timezone" data={DateTimeFunctions.getTimeZones()} value={this.state.step.stepOptions.delay_time_timezone?.value} onChange={this.updateValue} />
            </div>
            <div className="col-xs-6 quickFlex" style={{ alignItems: "center" }}>
                <UILIB.Select outerstyle={{ flexGrow: 1 }} name="delay_time_hours" data={hoursData} onChange={this.updateValue} value={this.state.step.stepOptions.delay_time_hours.value} error={this.state.errors.delay_time_hours} />

                <div style={{ margin: '0 2px' }}>:</div>
                <UILIB.Select outerstyle={{ flexGrow: 1 }} name="delay_time_mins" data={minsData} onChange={this.updateValue} value={this.state.step.stepOptions.delay_time_mins.value} error={this.state.errors.delay_time_mins} />

            </div>
        </div>

        const inputProps = {
            disabled: readOnly
        };

        return <div data-test="drawer-delay-step">
            <div className="automationStepContentHolder">
                <div className="mar-b25" style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 className="mar-b25">{i18n.t('automation:add.delay.header')}</h4>
                    <HelpPopup type={"delay"} />
                </div>

                <div className="mar-b25">{i18n.t('automation:add.delay.subheader')}</div>

                <div className="txtInput_label">{i18n.t('automation:add.delay.wait')}</div>
                {this.state.step.stepOptions.delay_unit.value.indexOf("next_") < 0 &&
                    <div className="row mar-b25">
                        <div className="col-xs-6"><UILIB.TextInput name="delay_value" onChange={this.updateValue} value={this.state.step.stepOptions.delay_value.value} error={this.state.errors.delay_value} type="number" {...inputProps} /></div>
                        <div className="col-xs-6"><UILIB.Select autoWidth={true} name="delay_unit" data={typeData} onChange={this.updateValue} value={this.state.step.stepOptions.delay_unit.value} error={this.state.errors.delay_unit} {...inputProps} /></div>
                    </div>
                }
                {this.state.step.stepOptions.delay_unit.value.indexOf("next_") >= 0 && <UILIB.Select outerClassName="mar-b25" name="delay_unit" data={typeData} onChange={this.updateValue} value={this.state.step.stepOptions.delay_unit.value} error={this.state.errors.delay_unit} {...inputProps} />}



                {this.state.step.stepOptions.delay_unit.value == "next_time" && <div className="form-group">
                    <div className="txtInput_label">{i18n.t('automation:add.delay.untilTime')}</div>
                    {dateAndTimePicker}
                    <UILIB.WarningHolder className="mar-t25">
                        {i18n.t('automation:add.delay.subsBefore', {
                            time: this.state.step.stepOptions.delay_time_hours.value + ':' + this.state.step.stepOptions.delay_time_mins.value
                        })}
                        <br /><br />
                        {i18n.t('automation:add.delay.subsAfter', {
                            time: this.state.step.stepOptions.delay_time_hours.value + ':' + this.state.step.stepOptions.delay_time_mins.value
                        })}
                    </UILIB.WarningHolder>
                </div>}
                {this.state.step.stepOptions.delay_unit.value == "next_day" && <div>
                    <div className="txtInput_label">{i18n.t('automation:add.delay.untilDayWeek')}</div>
                    <div className="mar-b25">
                        {weekDays.map((theD, index) => {
                            var checked = false;
                            var values = this.state.step.stepOptions.delay_daysOfWeek.value;
                            if (values.indexOf(theD.value) > -1) checked = true;
                            return <UILIB.CheckBox outerClassName="mar-b10" key={"day_" + index} name={theD.value} outerStyle={{ display: "inline-flex", marginRight: "15px", marginBottom: "10px" }} onChange={this.chooseDaysOfWeek} checked={checked} {...inputProps}>{theD.label}</UILIB.CheckBox>
                        })}
                        {this.state.errors.delay_dayOfWeek && <UILIB.WarningHolder>{this.state.errors.delay_dayOfWeek}</UILIB.WarningHolder>}
                    </div>

                    <div className="txtInput_label">{i18n.t('automation:add.delay.atTime')}</div>
                    {dateAndTimePicker}

                </div>}

                {this.state.step.stepOptions.delay_unit.value == "next_monthday" && <div>

                    <div className="txtInput_label" >{i18n.t('automation:add.delay.untilDayMonth')}</div>
                    <UILIB.Select outerClassName="mar-b25" name="delay_dayOfMonth" data={daysOfMonth} onChange={this.updateValue} value={this.state.step.stepOptions.delay_dayOfMonth.value} error={this.state.errors.delay_dayOfMonth} {...inputProps} />


                    <div className="txtInput_label">{i18n.t('automation:add.delay.atTime')}</div>
                    {dateAndTimePicker}

                </div>}
                {this.state.step.stepOptions.delay_unit.value == "next_yearday" && <div>

                    <div className="txtInput_label" >{i18n.t('automation:add.delay.untilDayYear')}</div>
                    <UILIB.TextInput outerClassName="mar-b25" type="date" name="delay_exactDate" onChange={this.updateDateValue} value={this.state.step.stepOptions.delay_exactDate.value} error={this.state.errors.delay_exactDate} {...inputProps} />


                    <div className="txtInput_label">{i18n.t('automation:add.delay.atTime')}</div>
                    {dateAndTimePicker}

                </div>}
            </div>

            <div className="automationStepSaveButtonHolder">
                {readOnly ? (
                    <>
                        <p>{i18n.t('automation:add.running.pauseToChangeSettings')}</p>
                        <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={this.props.onBackClick}>{i18n.t('goBack')}</UILIB.Button>
                    </>
                ) : (
                    <UILIB.Button className="button-primary" text={i18n.t('save')} onClick={this.saveStep} />
                )}
            </div>
        </div>
    }
}

export default DrawerDelayStep;
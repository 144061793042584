// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T7meezcaifDdHNAVHVMA{border:1px solid var(--grey-200);padding:16px;display:inline-flex;border-radius:8px;gap:8px;transition:background .2s ease;align-items:center}.T7meezcaifDdHNAVHVMA:hover,.T7meezcaifDdHNAVHVMA:focus-visible{background:var(--grey-100)}.JKTqlkdYRdqdqcL3vNqy{flex:0}.sF2rm1sxlUaOncMl2gde{font-weight:bold;font-size:16px}.E9LY5SlVl8_lnAe5nW79{font-size:12px;color:var(--Grey-800, #595C61)}.rMbYYxybjz_vulAlR0s_{flex:0}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/inbox/components/conversation/chatView/helpers/components/option/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,gCAAA,CACA,YAAA,CACA,mBAAA,CACA,iBAAA,CACA,OAAA,CACA,8BAAA,CACA,kBAAA,CAEA,gEAEE,0BAAA,CAKJ,sBACE,MAAA,CAGF,sBACE,gBAAA,CACA,cAAA,CAGF,sBACE,cAAA,CACA,8BAAA,CAIF,sBACE,MAAA","sourcesContent":[".root {\n  border: 1px solid var(--grey-200);\n  padding: 16px;\n  display: inline-flex;\n  border-radius: 8px;\n  gap: 8px;\n  transition: background 0.2s ease;\n  align-items: center;\n\n  &:hover,\n  &:focus-visible {\n    background: var(--grey-100);\n  }\n\n}\n\n.leftIcon {\n  flex: 0;\n}\n\n.text {\n  font-weight: bold;\n  font-size: 16px;\n}\n\n.subText {\n  font-size: 12px;\n  color: var(--Grey-800, #595C61);\n\n}\n\n.rightIcon {\n  flex: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "T7meezcaifDdHNAVHVMA",
	"leftIcon": "JKTqlkdYRdqdqcL3vNqy",
	"text": "sF2rm1sxlUaOncMl2gde",
	"subText": "E9LY5SlVl8_lnAe5nW79",
	"rightIcon": "rMbYYxybjz_vulAlR0s_"
};
export default ___CSS_LOADER_EXPORT___;

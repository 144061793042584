import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import { connect } from 'react-redux';
import { XAxis, YAxis, Tooltip, Label, ResponsiveContainer, AreaChart, Area, CartesianGrid, Legend, ReferenceDot, ReferenceLine } from 'recharts';
import moment from 'moment';


@connect((store) => {
    return {
        filters: store.filters,
        siteMaster: store.siteMaster,
        user: store.user
    }
})
class TrackingGraph extends Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
            colors: ["#A9A9AD", "#43B7B2", "#33257E", "#DD4124"],
            labels: [
                { value: "impressions", label: "Impressions", hide: false, color: this.props.siteMaster.colours.$primary },
                { value: "firstVisits", hide: false, label: "First Visits", color: this.props.siteMaster.colours.$secondary },
                { value: "returningVisits", label: "Returning Visitors", hide: false, color: this.props.siteMaster.colours["$status-red"] },
                { value: "uniqueVisitors", label: "Unique Visitors", hide: false, color: this.props.siteMaster.colours["$status-blue"] },
                { value: "registrationEvents", label: "Registrations", hide: false, color: this.props.siteMaster.colours["$status-green"] },
                { value: "paymentEvents", label: "Payments", hide: false, color: this.props.siteMaster.colours["$status-yellow"] }
            ]
        };

        this.selectBar = this.selectBar.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    selectBar(ev) {

        let labels = this.state.labels;
        let found = labels.find(v => v.value == ev.dataKey)
        if (found) {
            if (found.hide) {
                found.hide = false
            }
            else {
                found.hide = true
            }

        }
        this.setState({ labels })
    }


    render() {
        var height = 320;
        if (this.props.height) height = this.props.height;

        if (!this.props.graphData || !this.props.graphData.length) return <div style={{ height: height + "px" }}><UILIB.LoadingIcons iconType="2" /></div>


        return <div>


            <ResponsiveContainer height={height}>
                <AreaChart data={this.props.graphData}
                    margin={{ top: 0, right: 0, left: 20, bottom: 0 }}>
                    <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                    <YAxis axisLine={false} hide={true} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                    <Tooltip />
                    <Legend onClick={this.selectBar} />
                    <CartesianGrid horizontal={false} strokeDasharray="2 2" stroke="#DADADC" />
                    <defs>
                        {this.state.labels.map(row => {
                            return <linearGradient id={"color_" + row.value} x1="0" y1="0" x2="0" y2="1">
                                < stop offset="5%" stopColor={row.color} stopOpacity={0.3} />
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                            </linearGradient>
                        })}

                    </defs>

                    {this.state.labels.map(row => {
                        return <Area
                            name={row.label}
                            dot={false}
                            hide={row.hide}
                            type="monotone"
                            dataKey={row.value}
                            fill={`url(#color_${row.value}`}
                            stroke={row.color}
                            strokeWidth={2}
                            fillOpacity={1}
                            activeDot={{ stroke: row.color, strokeWidth: 2, fill: 'white' }}
                        />
                    })}
                    {this.props.detectedCampaigns && this.props.detectedCampaigns.map(row => {
                        return <ReferenceDot
                            isFront
                            x={row.date}
                            y={0}
                            fill="red"
                            stroke="none"
                            shape={(props) => {

                                return <svg x={props.cx - 10} y={props.cy - 10} width={20} height={20} >
                                    <clipPath id="circleClip">
                                        <circle cx="10" cy="10" r="10" fill="white" />
                                    </clipPath>
                                    {row.type == "email" && <UILIB.Icons svgOnly={true} icon="envelope" />}
                                    {row.type == "ad" && <image
                                        clip-path="url(#circleClip)"
                                        id="avatarImg"
                                        x="0"
                                        y="0"
                                        r="100"
                                        href={row.Application.Application.appLogoUri}
                                        height="20"
                                        width="20" />
                                    }
                                    <circle clip-path="url(#circleClip)" cx="10" cy="10" r="10" fill="none"
                                        stroke='#000000'
                                        strokeWidth='2' />
                                </svg>

                            }}
                        />
                    })}

                    {this.props.detectedCampaigns && this.props.detectedCampaigns.map(row => {

                        return <ReferenceLine stroke="#DDDDDD" strokeDasharray="3 3" x={row.date} label={
                            <Label angle={-90} value={row.campaignName} fontSize={10} position="insideStart" />
                        } />
                    })}

                </AreaChart>
            </ResponsiveContainer>

        </div >
    }
}

export default TrackingGraph;
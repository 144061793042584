import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib';
import Fields from './fields';
import Hooks from './hooks2';
import AddGroupView from '~/pages/cp/subscribers/groups/incGoAddGroup';
import SegmentEditor from '~/pages/cp/includes/segmentEditor/segmentEditor'
import GroupEditor from '~/pages/cp/subscribers/groups/incGoAddGroup'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';

@connect((store) => {
    return { user: store.user, siteMaster: store.siteMaster }
})
class GenericAppInitial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupId: undefined,
            loaded: false,
            groups: [],
            showFields: false,
            showAction: false,
            app: {},
            customFields: [],
            resync: false,
            saving: false
        }

        this.getGroups = this.getGroups.bind(this)
        this.save = this.save.bind(this)
        this.changeGroup = this.changeGroup.bind(this)
        this.addAGroup = this.addAGroup.bind(this)
        this.toggleOptions = this.toggleOptions.bind(this);
        this.getCustomFields = this.getCustomFields.bind(this);
    }

    componentDidMount() {
        this.getGroups()
        this.setState({
            groupId: this.props.appBinding.appSettings ? this.props.appBinding.appSettings.groupId : undefined,
            app: this.props.app
        })
    }

    getGroups() {
        axios.get('/group').then(response => {
            this.setState({
                groups: response.data.Groups.map(g => {
                    return {
                        label: g.groupName,
                        value: g.id
                    }
                })
            }, this.getCustomFields)
        })
    }

    getCustomFields() {
        axios.get('/customfield').then(response => {
            this.setState({
                loaded: true,
                customFields: response.data.CustomFields
            })
        })
    }

    changeGroup(ev, group) {
        if (!group) {
            this.setState({
                groupId: ev.currentTarget.value,
                resync: true
            })
        } else {
            this.props.appBinding.appSettings[group.value + 'GroupId'] = ev.currentTarget.value
            this.setState({})
        }
    }

    addAGroup(group) {
        var self = this;
        var drawerContent = <AddGroupView dontCloseDrawer={true} addedGroup={(response) => {
            var groupId = response.data.groupId;
            if (self.props.drawer && typeof self.props.drawer == "object") {
                self.setState({
                    drawerContent: null
                })
            }
            else {
                self.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", null, true, "500px"));
            }
            if (group) {
                self.props.appBinding.appSettings[group.value + 'GroupId'] = groupId;
                self.getGroups()
            } else {
                self.setState({ groupId }, self.getGroups);
            }
        }} />
        if (self.props.drawer) {
            self.setState({
                drawerContent
            })
        } else {
            self.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px"));
        }
    }

    save() {
        this.setState({
            saving: true
        })
        var binding = this.props.appBinding;
        if (!binding.appSettings) binding.appSettings = {}
        binding.appSettings.groupId = this.state.groupId
        binding.appSettings.doneInitial = true;
        axios.put("/application/account/" + binding.id, binding).then(() => {
            var params = ''
            if (this.state.resync) {
                params = '?resync=true'
            }
            return axios.get(`/application/${this.state.app.shortName}/setup` + params)
        }).then(this.props.finish)
    }

    toggleOptions(optionType) {
        var opt = this.state[optionType];
        if (opt) {
            opt = false
        }
        else {
            opt = true
        }
        this.setState({ [optionType]: opt })
    }

    render() {
        if (!this.state.loaded) return <div>
            <UILIB.LoadingIcons iconType="2" className="mar-b15" />
            <div className="text-center">Grabbing the App Config</div>
        </div>
        let hasHooks = this.props.app.options.hooks && this.props.app.options.hooks.length
        let fixedFields = !!this.props.app.options.fixedFields

        let hookCount = 0
        if (hasHooks) {
            this.props.app.options.hooks.forEach(hook => {
                if (this.props.appBinding.appSettings[hook.action]) {
                    Object.keys(this.props.appBinding.appSettings[hook.action]).forEach(action => {
                        hookCount += this.props.appBinding.appSettings[hook.action][action].length || 0
                    })
                }
            })
        }

        let multipleGroups = this.props.app.options.multipleGroups || []
        let showFieldSettings = (this.props.app.options.skipSync || this.state.groupId && this.state.groupId != -1) && !fixedFields
        if (multipleGroups.length && !fixedFields) {
            multipleGroups.forEach(g => {
                if (this.props.appBinding.appSettings[g.value + 'GroupId']) showFieldSettings = true
            })
        }

        return (
            <div>
                <UILIB.Row>
                    {/* group select */}
                    {!this.props.app.options.skipSync && <UILIB.Column xs={12} margin={0}>
                        {(!multipleGroups.length) && <UILIB.Paper>
                            <UILIB.Header>Select an {this.props.siteMaster.siteName} Group to Sync With</UILIB.Header>
                            <p>
                                Please select the {this.props.siteMaster.siteName} group where you would like contacts from {this.state.app.appName} to be synchronised into.
                            </p>
                            {(this.state.groups.length > 0) &&
                                <div className="quickFlex">
                                    <div className="form-group mar-b0"><UILIB.Select data={this.state.groups} placeholder={`Select the ${this.props.siteMaster.siteName} group`} name="groupId" value={this.state.groupId} onChange={this.changeGroup} /></div>
                                    <span style={{ marginLeft: "10px" }}>or</span>
                                    <UILIB.Button text="Add a new Group" className="button-sml white outline" style={{ marginLeft: "10px" }} onClick={() => this.addAGroup()} />
                                </div>
                            }
                            {(this.state.groups.length <= 0) && <UILIB.Button text="Add a new Group" className="button-sml white outline" style={{ marginLeft: "10px" }} onClick={() => this.addAGroup()} />}
                        </UILIB.Paper>}
                        {!!multipleGroups.length && <UILIB.Paper className="primary">
                            <UILIB.Header>Select {this.props.siteMaster.siteName} Groups to Sync With</UILIB.Header>
                            <p className="text-white">
                                Please select the {this.props.siteMaster.siteName} groups where you would like {multipleGroups.map(g => g.label).join(' and ')} from {this.state.app.appName} to be synchronised into.
                            </p>
                            {multipleGroups.map(group => <div className="quickFlex mar-b5">
                                <div className="form-group mar-b0">
                                    <UILIB.Select data={this.state.groups} placeholder={'Select ' + group.label + ' Group'} name={group.value + "GroupId"} value={this.props.appBinding.appSettings[group.value + "GroupId"]} onChange={e => this.changeGroup(e, group)} />
                                </div>
                                <span style={{ marginLeft: "10px" }}>or</span>
                                <UILIB.Button text="Add a new Group" className="button-sml white outline" style={{ marginLeft: "10px" }} onClick={() => this.addAGroup(group)} />
                            </div>)}
                        </UILIB.Paper>}
                    </UILIB.Column>}

                    {/* field sync */}
                    {showFieldSettings && <UILIB.Column xs={12} margin={0}>
                        {!multipleGroups.length && <UILIB.Paper>
                            <div className="quickFlex">
                                <div style={{ flex: "1" }}>
                                    <UILIB.Header>Additional Fields</UILIB.Header>
                                    <p>Select which {this.state.app.appName} fields we should import for you Contacts</p>
                                    {/* <p>Here you can select which {this.state.app.appName} additional fields we should import for you Contacts.</p> */}
                                </div>
                                <div style={{ flex: "0 0 150", textAlign: "right" }}>
                                    <UILIB.Button text={(this.state.showFields ? "Hide" : "Show") + " (" + (this.props.appBinding.appSettings.fields ? this.props.appBinding.appSettings.fields.length : 0) + ")"} className={"button-md primary" + (this.state.showFields ? "" : " outline")} onClick={() => { this.toggleOptions('showFields') }} />
                                </div>
                            </div>
                            {this.state.showFields && <div>
                                <Fields appBinding={this.props.appBinding} app={this.props.app} onChange={() => this.setState({ resync: true })} />
                            </div>}
                        </UILIB.Paper>}
                        {multipleGroups.map((group, index) => {
                            if (!this.props.appBinding.appSettings[group.value + 'GroupId']) return null
                            if (!this.props.appBinding.appSettings.fields) this.props.appBinding.appSettings.fields = {}
                            if (!this.props.appBinding.appSettings.fields[group.value]) this.props.appBinding.appSettings.fields[group.value] = []
                            let fields = this.props.appBinding.appSettings.fields[group.value]
                            return <UILIB.Paper key={index}>
                                <div className="quickFlex">
                                    <div style={{ flex: "1" }}>
                                        <UILIB.Header>Select {group.label} Fields to Sync</UILIB.Header>
                                    </div>
                                    <div style={{ flex: "0 0 150", textAlign: "right" }}>
                                        <UILIB.Button text={(this.state.showFields ? "Hide" : "Show") + " (" + fields.length + ")"} className={"button-md primary" + (this.state.showFields ? "" : " outline")} onClick={() => { this.toggleOptions('showFields' + group.value) }} />
                                    </div>
                                </div>
                                {this.state['showFields' + group.value] && <div>
                                    <Fields appBinding={this.props.appBinding} app={this.props.app} group={group} onChange={() => this.setState({ resync: true })} />
                                </div>}
                            </UILIB.Paper>
                        })}

                    </UILIB.Column>}

                    {/* hooks */}
                    {showFieldSettings && hasHooks && <UILIB.Column xs={12} margin={0}>
                        <UILIB.Paper>
                            <div className="quickFlex">
                                <div style={{ flex: "1" }}>
                                    <UILIB.Header>Update {this.state.app.appName}</UILIB.Header>
                                    <p>Update {this.state.app.appName} when Contacts interact with your emails.</p>
                                </div>
                                <div style={{ flex: "0 0 150", textAlign: "right" }}>
                                    <UILIB.Button text={(this.state.showActions ? "Hide" : "Show") + " (" + hookCount + ")"} className={"button-md primary" + (this.state.showActions ? "" : " outline")} onClick={() => { this.toggleOptions('showActions') }} />
                                </div>
                            </div>
                            {this.state.showActions && <div>
                                <Hooks appSettings={this.props.appBinding.appSettings} app={this.props.app} appBinding={this.props.appBinding} customFields={this.state.customFields} onChange={() => this.setState({ resync: true })} editing={isEditing => this.setState({ editingHooks: isEditing })} />
                            </div>}
                        </UILIB.Paper>
                    </UILIB.Column>}

                    {this.state.saving && <UILIB.Column xs={12} className="center-xs mar-b40">
                        <UILIB.LoadingIcons iconType="2" />
                    </UILIB.Column>}

                    {(showFieldSettings || (fixedFields && this.state.groupId)) && <UILIB.Column xs={12} className="center-xs">
                        <UILIB.Button text="Save and Continue" onClick={this.save} disabled={this.state.editingHooks || this.state.saving} />
                    </UILIB.Column>}
                </UILIB.Row>

                <UILIB.Drawer isOpen={!!this.state.drawerContent} startClose={() => this.setState({ drawerContent: undefined })} standalone={true} showClose={true}>
                    {this.state.drawerContent}
                </UILIB.Drawer>
            </div>
        );
    }
}

export default GenericAppInitial;
import React from 'react'

export default function IconsOffice({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 4.00006C4 3.46963 4.21071 2.96092 4.58579 2.58585C4.96086 2.21077 5.46957 2.00006 6 2.00006H14C14.5304 2.00006 15.0391 2.21077 15.4142 2.58585C15.7893 2.96092 16 3.46963 16 4.00006V16.0001C16.2652 16.0001 16.5196 16.1054 16.7071 16.293C16.8946 16.4805 17 16.7348 17 17.0001C17 17.2653 16.8946 17.5196 16.7071 17.7072C16.5196 17.8947 16.2652 18.0001 16 18.0001H13C12.7348 18.0001 12.4804 17.8947 12.2929 17.7072C12.1054 17.5196 12 17.2653 12 17.0001V15.0001C12 14.7348 11.8946 14.4805 11.7071 14.293C11.5196 14.1054 11.2652 14.0001 11 14.0001H9C8.73478 14.0001 8.48043 14.1054 8.29289 14.293C8.10536 14.4805 8 14.7348 8 15.0001V17.0001C8 17.2653 7.89464 17.5196 7.70711 17.7072C7.51957 17.8947 7.26522 18.0001 7 18.0001H4C3.73478 18.0001 3.48043 17.8947 3.29289 17.7072C3.10536 17.5196 3 17.2653 3 17.0001C3 16.7348 3.10536 16.4805 3.29289 16.293C3.48043 16.1054 3.73478 16.0001 4 16.0001V4.00006ZM7 5.00006H9V7.00006H7V5.00006ZM9 9.00006H7V11.0001H9V9.00006ZM11 5.00006H13V7.00006H11V5.00006ZM13 9.00006H11V11.0001H13V9.00006Z" fill={fillColor} />
        </svg>
    )
}
import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import PermissionChecker from '~/Classes/permissions';
import AddService from './add'
import i18n from '~/i18n';
import queryString from 'query-string'

// import MailChimp from './mailchimp'
import AddApp from './addApp/'
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission }
})

export default class CompanyIntegrations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 1,
            integrations: []
        };

        this.getApplications = this.getApplications.bind(this)
        this.add = this.add.bind(this);
        this.goConfig = this.goConfig.bind(this);
    }

    componentDidMount() {
        this.getApplications(true)
    }

    getApplications(firstRun) {

        axios.get('/accountMaster/application').then(response => {
            this.setState({
                loading: 0,
                apps: response.data.Applications,
            }, () => {
                if (firstRun == true) {
                    var query = queryString.parse(this.props.location.search)
                    if (query.show && parseInt(query.show)) {
                        this.view(undefined, parseInt(query.show))
                    }
                }
            })
        })
    }

    add(selected) {
        var drawerContent = <AddService finish={(option) => {
            this.view(undefined, option.id)
        }} option={selected} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, '100vw', 'full'));
    }

    goConfig(app) {
        if (app.Application.appConfigUri) {
            let appConfigUri = app.Application.appConfigUri;
            if (appConfigUri.indexOf("[APPID]")) appConfigUri = appConfigUri.replace("[APPID]", app.Application.id)
            if (appConfigUri.indexOf('http') === 0) {
                window.open(appConfigUri, '_self');
            } else {
                this.props.history.push(appConfigUri + '?id=' + app.id)
            }
        } else {
            this.props.history.push('/cp/company/integrations/apps/integrationnoconfig/' + app.Application.id)
        }
    }

    view(row, appId) {
        var drawer = <AddApp appId={appId} history={this.props.history} browse={url => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, false));
            if (url.indexOf('http') === 0) {
                window.open(url, '_self')
            } else {
                this.props.history.push(url)
            }
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, false, '100vw', 'full'));
    }

    delete(id) {
        var self = this;
        axios.delete('/accountMaster/application/' + id).then(res => {
            self.getApplications();
        })
    }

    render() {
        if (!this.state.apps) return <UILIB.LoadingIcons />
        return <div>

            <UILIB.Row>
                {PermissionChecker('company', this.props.permissionStore.permissions, "write") && <UILIB.Column xs={12} sm={12} className="mar-b25" style={{ textAlign: "right" }}>
                    <UILIB.Button
                        className="button-primary"
                        iconLeft={<UILIB.Icons icon="plus" />}
                        onClick={this.add}>
                        {i18n.t('company:integrations.main.add')}
                    </UILIB.Button>
                </UILIB.Column>}
                <UILIB.Column xs={12} >
                    {this.state.apps.map(app => {
                        return <App app={app} goConfig={this.goConfig} key={app.id} />
                    })}
                </UILIB.Column>
            </UILIB.Row>
        </div>
    };


};


function App({ app, goConfig = () => { } }) {
    const name = app.appSettings?.UniqueName || app.appSettings?.subdomain || app.appSettings?.company_domain
    return (
        <UILIB.Paper className="quickFlex" style={{ padding: 24 }}>
            <div className="mar-r20">
                <div className="avatar" style={{ backgroundImage: "url(" + app.Application.appLogoUri + ")", width: 48, height: 48, backgroundSize: "48px 48px" }} />
            </div>
            <div style={{ flexGrow: 1 }}>
                <div className="text-heavy" style={{ marginBottom: 4 }}>{app.Application.appName}{name ? <UILIB.SquareChip className="mar-l10">{name}</UILIB.SquareChip> : ''}</div>
                <div>{app.Application.appDescription}</div>
            </div>
            <div className="quickFlex" style={{ justifyContent: 'flex-end' }}>

                <UILIB.Button text={i18n.t('company:integrations.main.manage')} iconLeft={<UILIB.Icons icon="cog" />} onClick={() => goConfig(app)} className="button-sml primary outline" />
            </div>
        </UILIB.Paper >
    )
}
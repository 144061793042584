// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Oiw1A5uF_xyVuN8R7Wiw{border-color:var(--grey-300);box-shadow:0px 1px 1px 0px rgba(23,24,25,.05);font-weight:600;max-width:100%;overflow:hidden}.Oiw1A5uF_xyVuN8R7Wiw:hover{background-color:var(--grey-50)}.Oiw1A5uF_xyVuN8R7Wiw:focus{background-color:var(--grey-0)}.DfFDqEJl_UygnUoQSzUb{display:flex;flex-direction:column}.kxLpqtOr0VGBcDGAVj9y[data-state=checked] .aFIv__fv2lXpx7SSa74u{color:var(--purple-800)}.TiZW0wbPNHXSyCeieqrz{font-size:12px;color:var(--grey-800)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/inbox/components/leftSidebar/inboxDropdownMenu/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,4BAAA,CACA,6CAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA,CAEA,4BACE,+BAAA,CAGF,4BACE,8BAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CAIA,gEACE,uBAAA,CAIJ,sBACE,cAAA,CACA,qBAAA","sourcesContent":[".trigger {\n  border-color: var(--grey-300);\n  box-shadow: 0px 1px 1px 0px rgba(23, 24, 25, 0.05);\n  font-weight: 600;\n  max-width: 100%;\n  overflow: hidden;\n\n  &:hover {\n    background-color: var(--grey-50);\n  }\n\n  &:focus {\n    background-color: var(--grey-0);\n  }\n}\n\n.checkboxItemDetail {\n  display: flex;\n  flex-direction: column;\n}\n\n.checkboxItemInboxName {\n  .radioItem[data-state=\"checked\"] & {\n    color: var(--purple-800);\n  }\n}\n\n.checkboxItemOpenCount {\n  font-size: 12px;\n  color: var(--grey-800);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trigger": "Oiw1A5uF_xyVuN8R7Wiw",
	"checkboxItemDetail": "DfFDqEJl_UygnUoQSzUb",
	"radioItem": "kxLpqtOr0VGBcDGAVj9y",
	"checkboxItemInboxName": "aFIv__fv2lXpx7SSa74u",
	"checkboxItemOpenCount": "TiZW0wbPNHXSyCeieqrz"
};
export default ___CSS_LOADER_EXPORT___;

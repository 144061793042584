import React from "react";

export default function IconsPersonAdd({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 9.00006C8.79565 9.00006 9.55871 8.68399 10.1213 8.12138C10.6839 7.55877 11 6.79571 11 6.00006C11 5.20441 10.6839 4.44135 10.1213 3.87874C9.55871 3.31613 8.79565 3.00006 8 3.00006C7.20435 3.00006 6.44129 3.31613 5.87868 3.87874C5.31607 4.44135 5 5.20441 5 6.00006C5 6.79571 5.31607 7.55877 5.87868 8.12138C6.44129 8.68399 7.20435 9.00006 8 9.00006ZM8 11.0001C9.5913 11.0001 11.1174 11.6322 12.2426 12.7574C13.3679 13.8826 14 15.4088 14 17.0001H2C2 15.4088 2.63214 13.8826 3.75736 12.7574C4.88258 11.6322 6.4087 11.0001 8 11.0001ZM16 7.00006C16 6.73484 15.8946 6.48049 15.7071 6.29295C15.5196 6.10542 15.2652 6.00006 15 6.00006C14.7348 6.00006 14.4804 6.10542 14.2929 6.29295C14.1054 6.48049 14 6.73484 14 7.00006V8.00006H13C12.7348 8.00006 12.4804 8.10542 12.2929 8.29295C12.1054 8.48049 12 8.73484 12 9.00006C12 9.26528 12.1054 9.51963 12.2929 9.70717C12.4804 9.8947 12.7348 10.0001 13 10.0001H14V11.0001C14 11.2653 14.1054 11.5196 14.2929 11.7072C14.4804 11.8947 14.7348 12.0001 15 12.0001C15.2652 12.0001 15.5196 11.8947 15.7071 11.7072C15.8946 11.5196 16 11.2653 16 11.0001V10.0001H17C17.2652 10.0001 17.5196 9.8947 17.7071 9.70717C17.8946 9.51963 18 9.26528 18 9.00006C18 8.73484 17.8946 8.48049 17.7071 8.29295C17.5196 8.10542 17.2652 8.00006 17 8.00006H16V7.00006Z" fill={fillColor} />
        </svg>
    )
}
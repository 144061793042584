import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n'
import * as siteMaster from '~/data/actions/siteActions';

//CONNECT TO STORE
@connect((store) => {
    return {
        user: store.user, site: store.siteMaster
    }
})

export default class ViewCampaignTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            campaign: this.props.campaign,
            campaignId: this.props.campaignId,
            currentPath: ''
        }

        this.tabChange = this.tabChange.bind(this);
        this.setInitialTab = this.setInitialTab.bind(this);
        this.externalLinkToCampaign = this.externalLinkToCampaign.bind(this);
    }

    componentDidMount() {
        this.setInitialTab()
    }
    componentDidUpdate(prevProps) {
        var path = this.props.history.location.pathname.split("/")[5];
        if (path !== this.state.currentPath) {
            this.setInitialTab();
        } else {
            if (this.props.campaign.type === 10 && this.props.campaign.Campaigns) {
                if (prevProps.campaign.Campaigns.length !== this.props.campaign.Campaigns.length) {
                    this.setInitialTab()
                }
            }
        }
    }
    setInitialTab() {
        var theCurrPath = this.props.history.location.pathname.split("/");

        var theTab = 0;
        if (theCurrPath.length > 2) {
            switch (theCurrPath[5]) {
                case "visitors":
                    {
                        theTab = 1;
                        break;
                    }
                case "events":
                    {
                        theTab = 2;
                        break;
                    }
                default:
                    {
                        theTab = 0;
                    }
            }
        }
        if (theTab != this.state.theTab) {
            this.setState({ tabIndex: theTab, currentPath: theCurrPath[5] });
        }
    }
    tabChange(event, returnUrl) {
        var self = this;
        var index;
        if (event.currentTarget && event.currentTarget.id !== undefined) {
            index = event.currentTarget.id;
        }
        else if (event.currentTarget && event.currentTarget.value !== undefined) {
            index = event.currentTarget.value;
        }
        else {
            index = event;
        }
        index = String(index);
        var theURL = "";
        if (!returnUrl) self.setState({ tabIndex: index });
        switch (index) {
            case "0":
                theURL = '/cp/campaigns/viewad/' + this.state.campaignId;
                break;
            case "1":
                theURL = '/cp/campaigns/viewad/' + this.state.campaignId + '/visitors';
                break;
            case "2":
                theURL = '/cp/campaigns/viewad/' + this.state.campaignId + '/events';
                break;
        }
        if (returnUrl) return theURL;
        this.props.history.push(theURL);
    }

    externalLinkToCampaign() {
        window.open(this.props.adCampaign.adCampaignUrl, `_blank`);
    }

    render() {
        var thisMenuOptions = [
            {
                label: i18n.t('campaigns:nav.overview'),
                value: 0
            },
            {
                label: "Visitors",
                value: 1
            },
            {
                label: "Events",
                value: 2
            }
        ]

        var header = <div style={{ display: "flex", alignItems: "center" }}>
            {this.props.application && <img style={{ width: "40px" }} className="mar-r15" src={this.props.application.appLogoUri} />}
            <h1 className="page-header">{this.state.campaign.campaignName}</h1>
        </div>

        return <UILIB.Paper>

            <UILIB.Row className="mar-b25">
                <UILIB.Column xs={12} className="mar-b15" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {header}
                    <UILIB.Button text={"View in " + this.props.application.appName} onClick={this.externalLinkToCampaign} iconRight={<UILIB.Icons icon="external" />} />
                </UILIB.Column>
                <UILIB.Column xs={12} className="mar-b30">
                    <span className="fw-medium">Campaign ID: {this.state.campaign.id}</span>
                </UILIB.Column>

                <UILIB.Column xs={12} hide={["md", "lg"]}>
                    <UILIB.Select data={thisMenuOptions} onChange={this.tabChange} value={this.state.tabIndex} />
                </UILIB.Column>
                <UILIB.Column xs={12} hide={["xs", "sm"]} className="sub-nav-holder" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>{thisMenuOptions.map((option, index) => {
                        var label = option.label;
                        if (option.value == 11 && this.state.campaign.pollAnswers) {
                            label = <span>
                                {label}
                                <span style={{ marginLeft: "5px", color: "white", textTransform: "uppercase", borderRadius: "100px", backgroundColor: "green", padding: "1px 7px", fontSize: "9px" }}>
                                    {this.state.campaign.pollAnswers}
                                </span>
                            </span>;
                        }
                        return <UILIB.ButtonSimple key={"tab_" + index} to={this.tabChange(option.value, true)} className="button-simple-fullsize sub-nav-button" selected={this.state.tabIndex == option.value}>{label}</UILIB.ButtonSimple>
                    })}
                    </div>

                    <UILIB.TextInput type="multiDate" startDate={this.props.startDate} endDate={this.props.endDate} updateDate={this.props.updateDate} />

                </UILIB.Column>

                <UILIB.Column xs={12} className="mar-t30">
                    {this.props.children}
                </UILIB.Column>

            </UILIB.Row>

        </UILIB.Paper >
    }
}
import React from 'react'
import i18n from '~/i18n'
import AddTagHolder from './AddTagHolder';
import Icons from '../UI/Icons'
import Drawer from '../UI/Drawer/Drawer'
import SquareChip from '../UI/Chips/SquareChip'
import Warn from './Warn'

export default class Tags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tagType: 0,
            addingTag: false,
            warn: undefined
        }
        this.addTag = this.addTag.bind(this);
        this.stopAddingTag = this.stopAddingTag.bind(this);
        this.removeTag = this.removeTag.bind(this);
    }

    componentDidMount() {
        let tagType = 0;
        if (this.props.tagType) tagType = this.props.tagType;
        this.setState({ tagType })
    }


    addTag() {
        this.setState({ addingTag: true })
    }
    stopAddingTag() {
        this.setState({ addingTag: false })
    }

    removeTag(tag, force) {
        //removes a tag from an entity (Group, Subscriber, Campaign)
        if (tag.ApplicationId === 8 && !force) {
            // warn them that this will update capsule
            return this.setState({
                warn: tag
            })
        } else {
            let tagsSelected = JSON.parse(JSON.stringify(this.props.tagsSelected)).filter(t => t.id != tag.id);
            this.props.tagsChanged(tagsSelected);
            this.setState({
                warn: undefined
            })
        }

    }

    render() {
        let classes = "tagsHolderOuter "
        if (this.props.outerClassName) {
            classes += this.props.outerClassName
        }
        const addStyle = this.props.addStyle || { fontSize: 12, position: 'absolute', bottom: 40 }
        return <>
            <div className={classes} style={{ ...this.props.style, position: 'relative' }}>
                {this.props.tagsSelected.sort((a, b) => (a.tagName || tag).localeCompare(b.tagName || tag)).length > 0 && this.props.tagsSelected.map((tag, index) => {
                    let icon = "person"
                    let text = "Contact Tag"
                    let classes = "square-chip-primary"
                    if (tag.tagType === 1) {
                        icon = "link"
                        text = "Link Tag"
                        classes = "square-chip-secondary"
                    }
                    return <SquareChip
                        key={"tag_" + index}
                        className={"square-chip-tag " + classes}
                        iconLeft={<Icons icon={icon} color="white" style={{ height: 12, width: 12 }} data-tip={text} data-for={'tag_' + index} />}
                        iconRight={
                            !this.props.disableEdit ? <Icons icon="crossChunky" onClick={() => this.removeTag(tag)} style={{ height: 12, width: 12 }} /> : ''
                        }
                    >{tag.tagName || tag}</SquareChip>
                })}
                {!!this.props.readOnly && this.props.readOnly.map((tag, index) => {
                    let icon = "person"
                    let text = "Contact Tag"
                    let classes = "square-chip-primary"
                    if (tag.tagType === 1) {
                        icon = "link"
                        text = "Link Tag"
                        classes = "square-chip-secondary"
                    }
                    return <SquareChip
                        key={"tag_ro_" + index}
                        iconLeft={<Icons icon={icon} color="white" style={{ height: 12, width: 12 }} data-tip={text} data-for={'tag_' + index} />}
                        className={"square-chip-tag " + classes}
                    >{tag.tagName || tag}</SquareChip>
                })}
                {!this.props.disableEdit && this.props.tagsSelected.length <= 0 &&
                    <SquareChip>
                        {i18n.t('subscribers:tags.noTagsSelected')}
                    </SquareChip>
                }
                {!this.props.disableEdit && !this.state.addingTag &&
                    <SquareChip
                        iconLeft={<Icons icon="plusChunky" style={{ height: 12, width: 12 }} />}
                        onClick={this.addTag}
                    >
                        {i18n.t('subscribers:tags.addATag')}
                    </SquareChip>
                }
            </div>
            <AddTagHolder
                open={!this.props.disableEdit && this.state.addingTag == true}
                tagType={this.state.tagType}
                stopAddingTag={this.stopAddingTag}
                tagsSelected={this.props.tagsSelected}
                tagsChanged={this.props.tagsChanged}
            />

            <Drawer standalone={true} width={400} isOpen={!!this.state.warn}>
                <Warn onConfirm={() => this.removeTag(this.state.warn, true)} onCancel={() => this.setState({ warn: undefined })} />
            </Drawer>
        </>
        return
    }

}

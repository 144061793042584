// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NG788dDVVF2yk7u8tXgi{width:100%;display:flex;padding:24px;flex-direction:column;align-items:flex-start;gap:16px}.R4ga5VazAmAmB8379PDp{border-radius:8px;border:1px solid var(--grey-200, #E5E9EE)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/connect/webchat/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,QAAA,CAGF,sBACE,iBAAA,CACA,yCAAA","sourcesContent":[".teaser {\n  width: 100%;\n  display: flex;\n  padding: 24px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 16px;\n}\n\n.teaser_border {\n  border-radius: 8px;\n  border: 1px solid var(--grey-200, #E5E9EE);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teaser": "NG788dDVVF2yk7u8tXgi",
	"teaser_border": "R4ga5VazAmAmB8379PDp"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { useHistory } from 'react-router-dom'
import UILIB from '~/Common-Objects/Lib'
import i18n from '~/i18n';

export default function TierLimitHolder({ err = "", type = "", tierName = "", allowedNumber, onUpgrade, small = false, darkmode = false }) {
    const history = useHistory()

    const upgrade = () => {
        if (onUpgrade) {
            onUpgrade()
        } else {
            history.push('/cp/company/subscription')
        }
    }

    let classes = "text-left mar-b25 "
    if (small) classes += 'warning-holder-small'

    let buttonClasses = "";
    if (darkmode) buttonClasses += " button-darkmode-trans"

    if (err == "tierForbids") {
        return <UILIB.WarningHolder darkmode={darkmode} className={classes} iconLeft={small ? undefined : <UILIB.Icons icon="alert" />}>
            <h4 className="mar-b15">Please upgrade to use {type}</h4>
            <div>You are subscribed to the <UILIB.SquareChip className="square-chip-green">{tierName}</UILIB.SquareChip> tier which does not include {type}.</div>
            <div className="mar-b25">To continue please upgrade your subscription.</div>
            <UILIB.Button className={buttonClasses} onClick={upgrade} text="Upgrade your Account" />
        </UILIB.WarningHolder>
    }

    if (err == "tierOverLimit") {
        let message = `or delete existing ${type}s.`
        if (type === 'Site Tracker') {
            message = 'or archive existing domains'
        } else if (type === 'Condition Step') {
            message = 'or pause other automations with conditions steps'
        } else if (type === "AI Lookup") {
            message = "or wait until your limit resets when you are next billed";
        }
        return < UILIB.WarningHolder darkmode={darkmode} className={classes} iconLeft={< UILIB.Icons icon="alert" />}>
            <h4 className="mar-b15">You have created too many {type}s</h4>
            <div>You are subscribed to the <UILIB.SquareChip className="square-chip-green">{tierName}</UILIB.SquareChip> tier which allows <strong>up to {allowedNumber}</strong> {type}s. You have reached this limit.</div>
            <div className="mar-b25">To continue please either upgrade your subscription, {message}</div>
            <UILIB.Button className={buttonClasses} onClick={upgrade} text="Upgrade your Account" />
        </UILIB.WarningHolder >
    }

    return <></>
}

export default function reducer(

    state = {
        permissions: [],
        loading: true,
    },

    action) {
    switch (action.type) {
        case "LOADUSER_PERMISSIONS":
            {
                var newState = { ...state, "permissions": action.payload, loading: false };
                return newState;
            }
    }
    return state;
}

import React from 'react';

export default class OneColumn extends React.Component {
    render() {
        var styles = {
            cls1: {
                fill: 'rgba(0,0,0,0.05)'
            },
            cls2: {
                fill: '#fff'
            },
            cls3: {
                fill: 'rgba(0,0,0,0.2)'
            },
            cls4: {
                fill: 'rgba(0,0,0,0.1)'
            },
            cls5: {
                filter: 'url(#Rectangle_234)'
            }
        }
        return <svg id="Group_270" data-name="Group 270" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="svg-holder" viewBox="17141 6855 253 257">
            <defs>
                <filter id="Rectangle_234" x="17152" y="6861" width="230" height="251" filterUnits="userSpaceOnUse">
                    <feOffset dy="-5" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.039" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <rect id="Rectangle_233" data-name="Rectangle 233" className="outer" width="253" height="253" rx="3" transform="translate(17141 6855)" />
            <g style={styles.cls5} transform="matrix(1, 0, 0, 1, 0, 0)">
                <path id="Rectangle_234-2" data-name="Rectangle 234" style={styles.cls2} d="M3,0H209a3,3,0,0,1,3,3V233a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(17161 6875)" />
            </g>
            <rect id="Rectangle_235" data-name="Rectangle 235" className="inner" width="192" height="20" rx="3" transform="translate(17171 6885)" />
            <rect id="Rectangle_236" data-name="Rectangle 236" className="inner" width="192" height="60" rx="3" transform="translate(17171 6925)" />
            <rect id="Rectangle_237" data-name="Rectangle 237" style={styles.cls4} width="192" height="4" rx="2" transform="translate(17172 7085)" />
            <rect id="Rectangle_238" data-name="Rectangle 238" style={styles.cls4} width="192" height="4" rx="2" transform="translate(17172 7097)" />
            <rect id="Rectangle_240" data-name="Rectangle 240" className="inner" width="91" height="60" rx="3" transform="translate(17171 7005)" />
            <rect id="Rectangle_241" data-name="Rectangle 241" className="inner" width="91" height="60" rx="3" transform="translate(17272 7005)" />
        </svg>


    }
}
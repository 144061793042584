import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import i18n from '~/i18n'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';

class AutomationStepStats extends Component {
    constructor(props) {
        super(props)
        this.state = {
            automation: undefined,
            loaded: false,
            tableData: {},
            sortColumn: "createdAt",
            sortDirection: "desc",
            searchText: "",
            pageSize: 50,
            totalRows: 0,
            currentPage: 0,
            startRecord: 0
        }
        this.getAutomation = this.getAutomation.bind(this);
        this.changeSearchText = this.changeSearchText.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.goSearchText = this.goSearchText.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.tableUpdater = this.tableUpdater.bind(this);
        this.loadStats = this.loadStats.bind(this);
    }

    componentDidMount() {
        this.getAutomation()
    }

    getAutomation() {
        axios.get('/automation/' + this.props.match.params.automationId).then(response => {
            var automation = response.data.Automation
            if (automation.steps && automation.steps.length) {
                automation.steps.forEach(step => {
                    if (step.CampaignId) {
                        var campaign = automation.Campaigns.find(c => c.id === step.CampaignId)
                        step.campaign = campaign
                    }
                })
            }
            this.setState({
                automation,
                AutomationId: this.props.match.params.automationId,
            }, this.loadStats)
        })
    }


    changeSearchText(event) {
        this.setState({ searchText: event.target.value })
    }
    keyPress(event) {
        if (event.key === 'Enter') {
            this.goSearchText();
        }
    }
    goSearchText() {
        this.tableUpdater(0, null, null, null, null);
    }
    changePageSize(pageSize) {
        this.tableUpdater(null, null, null, pageSize, null);
    }
    tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {
        var thisState = {};
        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!currentPage && currentPage != "0") {
            currentPage = this.state.currentPage;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var startRecord = currentPage * pageSize;

        this.setState({ sortColumn: sortColumn, sortDirection: sortDirection, pageSize: pageSize, currentPage: currentPage, searchText: searchText, startRecord: startRecord }, this.loadStats);

    }

    loadStats(sortColumn, sortDirection, startRecord, pageSize, searchText) {
        this.setState({ loadingData: 1 });

        if (!sortColumn) {
            sortColumn = this.state.sortColumn;
        }
        if (!sortDirection) {
            sortDirection = this.state.sortDirection;
        }
        if (!startRecord) {
            startRecord = this.state.startRecord;
        }
        if (!pageSize) {
            pageSize = this.state.pageSize;
        }
        if (!searchText) {
            searchText = this.state.searchText;
        }

        var query = '?sortColumn=' + sortColumn + '&sortOrder=' + sortDirection + '&searchText=' + searchText + '&startRecord=' + startRecord + '&recordLimit=' + this.state.pageSize;

        var self = this;
        axios
            .get('/automation/' + self.state.automation.id + '/stats/steps/' + this.props.match.params.stepId + query)
            .then((res) => {
                console.log(res.data.StepStats);
                var theTableData = res.data.StepStats
                    .map(thisLine => {
                        return {
                            emailAddress: {
                                headerValue: i18n.t('emailAddress'),
                                value: <a onClick={() => { this.props.history.push('/cp/subscriber/' + thisLine.SubscriberId) }}>{thisLine.emailAddress}</a>,
                                orderBy: true
                            },
                            createdAt: {
                                headerValue: "Action Date",
                                value: <span>{DateTimeFunctions.formatDateTime(thisLine.createdAt, 1)}</span>,
                                orderBy: true
                            },
                            optionsCol:
                            {
                                headerValue: " ",
                                value: <UILIB.OptionsDropdown popWidth="150px" history={this.history}>
                                    <ul>
                                        <li>
                                            <a onClick={() => { this.props.history.push('/cp/subscriber/' + thisLine.SubscriberId) }}>{i18n.t('campaigns:overview.viewSubscriber')}</a>
                                        </li>
                                    </ul>
                                </UILIB.OptionsDropdown>
                                ,
                                orderBy: false,
                                fixed: true,
                                width: 20
                            }
                        }
                    })
                self.setState({ tableData: theTableData, loadingData: 0, totalRows: res.data.countTotal, pageLoaded: true, loaded: true });
            });
    }

    render() {
        if (!this.state.loaded) return <div><UILIB.LoadingIcons iconType="2" /></div>

        return <div>
            <UILIB.Row className="mar-b5">

                <UILIB.Column xs={12} sm={12}>
                    <span className="nav-pageBackLink" onClick={() => { this.props.history.push('/cp/automation/view/' + this.state.automation.id) }}>
                        <div className="icon">
                            <span className="icon-arrow-left"></span>
                        </div>
                        <p>Back to "{this.state.automation.name}"</p>
                    </span>
                </UILIB.Column>

            </UILIB.Row>
            <UILIB.Row>
                <UILIB.Column md={4} sm={12} xs={12}>
                    <UILIB.TextInput className="txtInput" placeholder={i18n.t('searchForEmail')} onChange={this.changeSearchText} onKeyPress={this.keyPress} />
                </UILIB.Column>
                <UILIB.Column md={2} sm={12} xs={12}>
                    <UILIB.Button className="button button-md full-width min-shadow" onClick={this.goSearchText} style={{ minWidth: "150px" }}>{i18n.t('search')}</UILIB.Button>
                </UILIB.Column>

                <UILIB.Column md={6} hide={["sm", "xs"]}>
                    <div className="showing">
                        <p>{i18n.t('showing')}</p>
                        <ul>
                            <li className={this.state.pageSize === 10 && 'selected'} onClick={() => { this.changePageSize(10) }}>10</li>
                            <li className={this.state.pageSize === 50 && 'selected'} onClick={() => { this.changePageSize(50) }}>50</li>
                            <li className={this.state.pageSize === 100 && 'selected'} onClick={() => { this.changePageSize(100) }}>100</li>
                            <li className={this.state.pageSize === 250 && 'selected'} onClick={() => { this.changePageSize(250) }}>250</li>
                        </ul>
                    </div>
                </UILIB.Column>
            </UILIB.Row>
            <UILIB.Row>
                <UILIB.Column xs={12} sm={12} md={12}>
                    <UILIB.Paper>

                        <UILIB.DataTable1 noResultsTxt={<span> {i18n.t('noResults')}</span>} tableData={this.state.tableData} loadingData={this.state.loadingData}
                            dataUpdater={this.tableUpdater} width="100%" pageSize="100" hasCheckBoxes="no" sortedColumn={this.state.sortColumn}
                            sortedDirection={this.state.sortDirection}
                        />

                    </UILIB.Paper>
                    <div style={{ textAlign: "center" }}>
                        <UILIB.PagingBlock totalRows={this.state.totalRows} pageSize={this.state.pageSize} numberOfLinks="10" currentPage={this.state.currentPage}
                            changePage={this.tableUpdater} text="Page:" />
                    </div>
                </UILIB.Column>
            </UILIB.Row>

        </div >
    }
}

export default AutomationStepStats;
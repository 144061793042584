import React from 'react'
import { useSelector } from 'react-redux'

export default function IconLoading({ color = "", style = {} }) {
    const site = useSelector(state => state.siteMaster)
    let fillColor = site.colours.$primary;
    if (color) fillColor = color;
    let className = "icons-icon spin";

    return (
        <svg style={style} className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.61563 2.49196C9.85821 2.18146 10.2309 1.99361 10.6237 2.02433C11.9862 2.13087 13.3025 2.58511 14.4445 3.34823C15.7601 4.22728 16.7855 5.47671 17.391 6.93852C17.9965 8.40032 18.1549 10.0089 17.8463 11.5607C17.5376 13.1126 16.7757 14.538 15.6568 15.6568C14.538 16.7757 13.1126 17.5376 11.5607 17.8463C10.0089 18.1549 8.40033 17.9965 6.93852 17.391C5.47671 16.7855 4.22728 15.7601 3.34823 14.4445C2.58511 13.3025 2.13087 11.9862 2.02433 10.6237C1.99361 10.2309 2.18146 9.85821 2.49196 9.61563C2.95503 9.25386 3.60494 9.25386 4.06801 9.61563C4.37851 9.85821 4.55065 10.2315 4.59577 10.6229C4.69437 11.4784 4.99509 12.3014 5.47679 13.0223C6.07454 13.9169 6.92416 14.6141 7.91819 15.0259C8.91222 15.4376 10.006 15.5454 11.0613 15.3355C12.1165 15.1256 13.0858 14.6074 13.8466 13.8466C14.6074 13.0858 15.1256 12.1165 15.3355 11.0613C15.5454 10.006 15.4376 8.91222 15.0259 7.91819C14.6141 6.92416 13.9169 6.07454 13.0223 5.47679C12.3014 4.99509 11.4784 4.69437 10.6229 4.59577C10.2315 4.55065 9.85821 4.37851 9.61563 4.06801C9.25386 3.60494 9.25386 2.95503 9.61563 2.49196Z" fill={fillColor} />
        </svg>

    )
}
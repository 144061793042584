import React from 'react'

export default class IconsWand extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 8C3.17681 8 3.34638 8.07024 3.4714 8.19526C3.59643 8.32029 3.66667 8.48986 3.66667 8.66667V9.33333H4.33333C4.51014 9.33333 4.67971 9.40357 4.80474 9.5286C4.92976 9.65362 5 9.82319 5 10C5 10.1768 4.92976 10.3464 4.80474 10.4714C4.67971 10.5964 4.51014 10.6667 4.33333 10.6667H3.66667V11.3333C3.66667 11.5101 3.59643 11.6797 3.4714 11.8047C3.34638 11.9298 3.17681 12 3 12C2.82319 12 2.65362 11.9298 2.5286 11.8047C2.40357 11.6797 2.33333 11.5101 2.33333 11.3333V10.6667H1.66667C1.48986 10.6667 1.32029 10.5964 1.19526 10.4714C1.07024 10.3464 1 10.1768 1 10C1 9.82319 1.07024 9.65362 1.19526 9.5286C1.32029 9.40357 1.48986 9.33333 1.66667 9.33333H2.33333V8.66667C2.33333 8.48986 2.40357 8.32029 2.5286 8.19526C2.65362 8.07024 2.82319 8 3 8Z" fill={color} />
            <path d="M4.08926 1.24408C3.93298 1.0878 3.72101 1 3.5 1C3.27899 1 3.06702 1.0878 2.91074 1.24408C2.75446 1.40036 2.66667 1.61232 2.66667 1.83333V2.66667H1.83333C1.61232 2.66667 1.40036 2.75446 1.24408 2.91074C1.0878 3.06702 1 3.27899 1 3.5C1 3.72101 1.0878 3.93298 1.24408 4.08926C1.40036 4.24554 1.61232 4.33333 1.83333 4.33333H2.66667V5.16667C2.66667 5.38768 2.75446 5.59964 2.91074 5.75592C3.06702 5.9122 3.27899 6 3.5 6C3.72101 6 3.93298 5.9122 4.08926 5.75592C4.24554 5.59964 4.33333 5.38768 4.33333 5.16667V4.33333H5.16667C5.38768 4.33333 5.59964 4.24554 5.75592 4.08926C5.9122 3.93298 6 3.72101 6 3.5C6 3.27899 5.9122 3.06702 5.75592 2.91074C5.59964 2.75446 5.38768 2.66667 5.16667 2.66667H4.33333V1.83333C4.33333 1.61232 4.24554 1.40036 4.08926 1.24408Z" fill={color} />
            <path d="M10.4714 1.19526C10.3464 1.07024 10.1768 1 10 1C9.82319 1 9.65362 1.07024 9.5286 1.19526C9.40357 1.32029 9.33333 1.48986 9.33333 1.66667V2.33333H8.66667C8.48986 2.33333 8.32029 2.40357 8.19526 2.5286C8.07024 2.65362 8 2.82319 8 3C8 3.17681 8.07024 3.34638 8.19526 3.4714C8.32029 3.59643 8.48986 3.66667 8.66667 3.66667H9.33333V4.33333C9.33333 4.51014 9.40357 4.67971 9.5286 4.80474C9.65362 4.92976 9.82319 5 10 5C10.1768 5 10.3464 4.92976 10.4714 4.80474C10.5964 4.67971 10.6667 4.51014 10.6667 4.33333V3.66667H11.3333C11.5101 3.66667 11.6797 3.59643 11.8047 3.4714C11.9298 3.34638 12 3.17681 12 3C12 2.82319 11.9298 2.65362 11.8047 2.5286C11.6797 2.40357 11.5101 2.33333 11.3333 2.33333H10.6667V1.66667C10.6667 1.48986 10.5964 1.32029 10.4714 1.19526Z" fill={color} />
            <path d="M9.43858 6.61058C9.25408 6.41956 9.03339 6.26719 8.78939 6.16237C8.54538 6.05756 8.28294 6.00238 8.01738 6.00008C7.75182 5.99777 7.48846 6.04837 7.24267 6.14893C6.99688 6.24949 6.77357 6.398 6.58579 6.58579C6.398 6.77357 6.2495 6.99688 6.14893 7.24267C6.04837 7.48846 5.99777 7.75182 6.00008 8.01738C6.00238 8.28294 6.05756 8.54538 6.16237 8.78939C6.26719 9.03339 6.41956 9.25408 6.61058 9.43858L7.40358 10.2316L10.2316 7.40358L9.43858 6.61058ZM11.6456 8.81758L17.9033 15.0753C18.2938 15.4658 18.2938 16.0989 17.9033 16.4895L16.4894 17.9033C16.0989 18.2938 15.4658 18.2938 15.0753 17.9034L8.81658 11.6456L11.6456 8.81758Z" fill="#2B2F41" />
        </svg>



    }
}        
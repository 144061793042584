import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'


export default class PremiumSupportDrawer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>

            <h4 className="mar-b25">Premium Support</h4>

            <div className="mar-b25">Hey there! Thanks for being a premium subscriber. As part of your subscription we would like to offer you the following support routes that are not all available to everyone.</div>


            <div className="quicklinks">
                <div className={'mar-b20 quickFlex center-xs width-100'}>
                    <span className={"icon-mic text-lg text-primary"}></span>
                    <p className="pad-left-1 no-marg">
                        <strong>Skype us!</strong> We are "Transpond"
                    </p>
                    <div className="mar-l-auto">
                        <UILIB.Button className="primary button-sml" text="Skype Us" onClick={() => { window.open('skype://transpond', '_self') }} />
                    </div>
                </div>
                <div className="divider2 light no-marg mar-b20 "></div>
                <div className={'mar-b20 quickFlex center-xs width-100'}>
                    <span className={"icon-envelope text-lg text-primary"}></span>
                    <p className="pad-left-1 no-marg">
                        <strong>Email your Account Manager</strong>
                    </p>
                    <div className="mar-l-auto">
                        <UILIB.Button className="primary button-sml" text="Email Us" onClick={() => { window.open('mailto:mike@mpzmail.com', '_self') }} />
                    </div>
                </div>
                <div className="divider2 light no-marg mar-b20 "></div>
                <div className={'mar-b20 quickFlex center-xs width-100'}>
                    <span className={"icon-lifebuoy text-lg text-primary"}></span>
                    <p className="pad-left-1 no-marg">
                        <strong>Open a Support Ticket</strong>
                    </p>
                    <div className="mar-l-auto">
                        <UILIB.Button className="primary button-sml" text="Open Ticket" onClick={() => { window.open('https://kb.transpond.io/hc/en-us/requests/new', '_blank') }} />
                    </div>
                </div>


            </div>

        </div >
    }
}

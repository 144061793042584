import React from "react";
import UILIB from "~/Common-Objects/Lib";

// New paging component. Note this is different to table pagination which can be found in the Tables folder
export default function Paging({
    totalItems = 0,
    currentPage = 0,
    pageSize = 10,
    pagesToShow = 5,
    loading = true,
    changePage = () => { }
}) {

    if (loading) {
        return <div className="paging_component">
            <UILIB.LoadingIcons iconType="2" style={{ width: 15, height: 15, top: 0 }} />
        </div>
    }

    currentPage++;

    const showArrowLeft = currentPage > 1;

    let totalPages = Math.ceil(totalItems / pageSize);

    const showArrowRight = currentPage < totalPages;

    if (totalPages < 1) totalPages = 1;

    let startPage = 1;
    let endPage = 1;

    if (totalPages <= pagesToShow) {
        startPage = 1;
        endPage = totalPages + 1;
    }

    if (totalPages > pagesToShow) {
        startPage = currentPage - Math.floor(pagesToShow / 2);
        endPage = currentPage + Math.floor(pagesToShow / 2);

        if (startPage < 1) {
            startPage = 1;
            endPage = pagesToShow + 1;
        }

        if (endPage > totalPages) {
            startPage = totalPages - pagesToShow;
            endPage = totalPages + 1;
        }
    }

    let pagesOut = [];
    for (let i = startPage; i < endPage; i++) {
        pagesOut.push(i);
    }


    return (
        <div className="paging_component">
            <div className="paging_component_leftArrow">
                <UILIB.Icons className={"mar-b0 paging_component_pageHolder_arrowComponent " + (!showArrowLeft ? "paging_component_pageHolder_arrowComponent_active" : "")} icon="arrowLeft" onClick={() => { if (showArrowLeft) changePage(currentPage - 2) }} />
            </div>
            <div className="paging_component_pageHolder">

                {pagesOut.map((page, i) => {
                    const isCurrentPage = currentPage === page;
                    let thisClass = "paging_component_pageItem"
                    if (isCurrentPage) {
                        thisClass += " paging_component_pageItem_active"
                        return <div key={"pagingPage_" + i} className={thisClass}>{page}</div>
                    }
                    return <a key={"pagingPage_" + i} className={thisClass} onClick={() => { changePage(page - 1) }}>{page}</a>
                })}

            </div>
            <div className="paging_component_rightArrow">
                <UILIB.Icons className={"mar-b0 paging_component_pageHolder_arrowComponent " + (!showArrowRight ? "paging_component_pageHolder_arrowComponent_active" : "")} icon="arrowRight" onClick={() => { if (showArrowRight) changePage(currentPage) }} />
            </div>
        </div>
    );
}

import React from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import NumberFunctions from '~/Classes/numberFormatFunctions'
import EditNotification from './editNotification';
import EditEvents from './editEvents';
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'
//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, siteMaster: store.siteMaster, accountMaster: store.accountMaster }
})

export default class AccountTemplate extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loadingPaths: true,
            paths: [],
        }
        this.getPaths = this.getPaths.bind(this)
        this.tableUpdater = this.tableUpdater.bind(this)
        this.editNotification = this.editNotification.bind(this);
        this.updatePath = this.updatePath.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
    }

    componentDidMount() {
        this.getPaths()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate != this.props.startDate || prevProps.endDate != this.props.endDate) {
            this.getPaths()
        }
    }

    addEvent(path) {
        var drawerContent = <EditEvents path={path} tracker={this.props.tracker} close={() => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
            this.getPaths();
        }} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "400px"));
    }

    getPaths() {

        axios.get('/accountMaster/tracker/' + this.props.tracker.id + '/path?startDate=' + this.props.startDate.format("YYYY-MM-DD") + '&endDate=' + this.props.endDate.format("YYYY-MM-DD")).then(response => {

            this.setState({
                loadingPaths: false,
                paths: response.data.TrackerPaths.map(path => {

                    var thisNotifications = <UILIB.Icons icon="cross" onClick={() => { this.editNotification(path) }} />
                    if (path.sendNotifications) {
                        thisNotifications = <UILIB.Icons icon="tick" onClick={() => { this.editNotification(path) }} />
                    }

                    let events = [];
                    if (path.SiteTrackerEventPaths && path.SiteTrackerEventPaths.length) {
                        path.SiteTrackerEventPaths.forEach(event => {
                            events.push(<UILIB.SquareChip iconRight={<UILIB.Icons onClick={() => { this.deleteEvent(path, event) }} icon="cross" style={{ width: "15px", height: "15px" }} />} className="mar-r10">{event.eventName}</UILIB.SquareChip>)
                        })
                    }
                    events.push(
                        <UILIB.ButtonSimple className="" onClick={() => { this.addEvent(path) }}>Add Event</UILIB.ButtonSimple>
                    )
                    return {
                        path: {
                            headerValue: i18n.t('campaigns:sitetracking.trackingpages.websitePath'),
                            value: path.path,
                        },
                        hits: {
                            headerValue: i18n.t('campaigns:sitetracking.trackingpages.hits'),
                            value: NumberFunctions.formatNumber(path.hits),
                            width: 80,
                            align: "center"
                        },
                        notifications: {
                            headerValue: i18n.t('campaigns:sitetracking.trackingpages.notifications'),
                            value: thisNotifications,
                            align: "center"
                        },
                        events: {
                            headerValue: "Events",
                            value: events,
                            align: "center"
                        },
                        lastHit: {
                            headerValue: i18n.t('campaigns:sitetracking.trackingpages.lastHit'),
                            value: DateTimeFunctions.formatDateTime(path.updatedAt),
                            width: 250,
                            align: "center"
                        },

                        options:
                        {
                            headerValue: "",
                            value: <UILIB.OptionsDropdown popWidth="150px">
                                <ul>
                                    <li>
                                        <a onClick={() => { this.editNotification(path) }}>{i18n.t('campaigns:sitetracking.trackingpages.setupNotifications')}</a>
                                    </li>
                                </ul>
                            </UILIB.OptionsDropdown>,
                            orderBy: false,
                            fixed: true,
                            width: 20
                        }

                    }
                })
            })
        })
    }

    tableUpdater(currentPage, sortDirection, sortColumn, pageSize, searchText) {

    }

    editNotification(path) {
        var drawerContent = <EditNotification path={path} tracker={this.props.tracker} updatePath={this.updatePath} />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "400px"));
    }

    updatePath(path, closeDrawer) {
        var self = this;
        if (closeDrawer) {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, false, "right", null, true));
        }
        axios.put('/accountMaster/tracker/' + this.props.tracker.id + '/path/' + path.id, path).then(() => {
            self.getPaths();
        }).catch();

    }

    async deleteEvent(path, event) {
        await axios.delete(`/accountMaster/tracker/${this.props.tracker.id}/path/${path.id}/event/${event.id}`)
        this.getPaths();
    }
    render() {
        if (this.state.loadingPaths) {
            return <UILIB.LoadingIcons iconType="2" />
        }
        return (
            <div>
                <UILIB.Row>
                    <UILIB.Column xs={12} md={12}>
                        <UILIB.Paper isLoading={this.state.loadingPaths} className="full-height-not-mob">

                            <UILIB.DataTable1
                                noResultsTxt={i18n.t('campaigns:sitetracking.trackingpages.noDataFound')}
                                tableData={this.state.paths}
                                dataUpdater={this.tableUpdater}
                                loadingData={false}
                                width="100%"
                                pageSize="100"
                                hasCheckBoxes="no"
                                sortedColumn={this.state.orderBy}
                                sortedDirection={this.state.orderDir}
                            />

                        </UILIB.Paper>
                    </UILIB.Column>
                </UILIB.Row>
            </div>
        );
    }
}

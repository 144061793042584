import React from 'react';

export default class IconsStartsWith extends React.Component {


    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83337 12.5C7.21409 12.5 8.33337 11.3807 8.33337 10C8.33337 8.61929 7.21409 7.5 5.83337 7.5C4.45266 7.5 3.33337 8.61929 3.33337 10C3.33337 11.3807 4.45266 12.5 5.83337 12.5Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.33337 7.5V12.5" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.1666 12.5C15.5473 12.5 16.6666 11.3807 16.6666 10C16.6666 8.61929 15.5473 7.5 14.1666 7.5C12.7859 7.5 11.6666 8.61929 11.6666 10C11.6666 11.3807 12.7859 12.5 14.1666 12.5Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.6666 5.83331V12.5" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 15.8333H2.49996C2.08329 15.8333 1.66663 15.4167 1.66663 15V14.1717" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    }
}

import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '../../../../data/http/axios';
import moment from 'moment';
import {
    LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

class MailServerStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: [],
            loadedStats: false,
            startDate: moment().subtract(1, "months").startOf("day"),
            endDate: moment().endOf("day"),
            bounceFilter: ""
        }

        let searchTimer;

        this.getStats = this.getStats.bind(this);
        this.updateDate = this.updateDate.bind(this);
        this.updateBounceFilter = this.updateBounceFilter.bind(this);
    }

    componentDidMount() {
        this.getStats();
    }

    getStats(fromPage) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            // get the bounces
            axios.get(`/mailserver/${this.props.mailServer.id}/stats?fromDate=${this.state.startDate.format("YYYY-MM-DD")}&toDate=${this.state.endDate.format("YYYY-MM-DD")}&bounceFilter=${this.state.bounceFilter}`).then(response => {

                var stats = response.data.map(s => {
                    s.Sent = s.sent;
                    s.SoftBounced = s.bounced;
                    delete s.sent;
                    delete s.bounced;
                    return s
                })

                this.setState({
                    stats: stats,
                    loadedStats: true
                })
            })
        }, fromPage ? 2000 : 0)

    }

    updateDate(event) {

        this.setState({ startDate: event.startDate, endDate: event.endDate, loadedStats: false }, () => { this.getStats(true) })

    }
    updateBounceFilter(event) {

        this.setState({ bounceFilter: event.target.value, loadedStats: false }, () => { this.getStats(true) })

    }
    render() {

        return (
            <div>
                <UILIB.Row margin={0}>
                    <UILIB.Column xs={6} >
                        <h4 className="mar-b25">Mail Server Stats</h4>
                        <div className="mar-b25">{this.props.mailServer.name} ({this.props.mailServer.host})</div>
                    </UILIB.Column>
                    <UILIB.Column xs={6} style={{ textAlign: "right" }} >
                        <div className="form-group form-group-icon mar-b0" style={{ width: "100%", maxWidth: "400px", display: "inline-block" }}>
                            <UILIB.TextInput style={{ boxShadow: "none", border: "2px solid grey" }} type="multiDate" startDate={this.state.startDate} endDate={this.state.endDate} updateDate={this.updateDate}></UILIB.TextInput>
                        </div>
                    </UILIB.Column>
                    <UILIB.Column xs={12} margin={0}>
                        <UILIB.Paper style={{ width: "100%", height: "400px", position: "relative" }}>
                            {!this.state.loadedStats && <div style={{ position: "absolute", top: "calc(50% - 25px", left: "calc(50% - 25px" }}><UILIB.LoadingIcons /></div>}
                            {this.state.loadedStats && <ResponsiveContainer width="100%" height={400} >
                                <LineChart data={this.state.stats}>
                                    <Tooltip wrapperStyle={{ zIndex: "9999" }} />

                                    <XAxis dataKey="date" axisLine={false} tickLine={false} padding={{ left: 15 }} tick={{ fontSize: 10, fill: '#999', fontFamily: "Open Sans", fontWeight: 600 }} />
                                    <YAxis axisLine={false} tickLine={false} width={50} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontFamily: "Open Sans", fontWeight: 600 }} />

                                    <Line type="monotone" dataKey="Sent" stroke="#8884d8" strokeWidth={2} dot={false} />
                                    <Line type="monotone" dataKey="SoftBounced" stroke="#BD6F6F" strokeWidth={2} dot={false} />
                                    <Legend />
                                </LineChart>
                            </ResponsiveContainer>}
                        </UILIB.Paper>
                    </UILIB.Column>
                    <UILIB.Column xs={12} margin={0} className="form-group">
                        <label for="">Filter bounces by bounce source (mimecast, etc)</label>
                        <UILIB.TextInput type="text" name="bounceFilter" value={this.state.bounceFilter} onChange={this.updateBounceFilter} />
                    </UILIB.Column>
                </UILIB.Row>

            </div>
        );
    }
}

export default MailServerStats;
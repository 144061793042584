import React, { Component } from 'react';

class TwoOneColumn extends Component {
    render() {
        var styles = {
            cls1: {
                fill: 'rgba(0,0,0,0.05)'
            },
            cls2: {
                fill: '#fff'
            },
            cls3: {
                fill: 'rgba(0,0,0,0.2)'
            },
            cls4: {
                fill: 'rgba(0,0,0,0.1)'
            },
            cls5: {
                filter: 'url(#Rectangle_264)'
            }
        }
        return (
            <svg id="Group_276" data-name="Group 276" xmlns="http://www.w3.org/2000/svg" className="svg-holder" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="17191 7101 253 257">
                <defs>
                    <filter id="Rectangle_264" x="17202" y="7107" width="230" height="251" filterUnits="userSpaceOnUse">
                        <feOffset dy="-5" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood floodOpacity="0.039" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                </defs>
                <rect id="Rectangle_263" data-name="Rectangle 263" className="outer" width="253" height="253" rx="3" transform="translate(17191 7101)" />
                <g transform="matrix(1, 0, 0, 1, 0, 0)">
                    <path id="Rectangle_264-2" data-name="Rectangle 264" style={styles.cls2} d="M3,0H209a3,3,0,0,1,3,3V233a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(17211 7121)" />
                </g>
                <path id="Rectangle_279" data-name="Rectangle 279" className="inner" d="M3,0H37.25a3,3,0,0,1,3,3V33.25a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(17373 7321)" />
                <path id="Rectangle_278" data-name="Rectangle 278" className="inner" d="M3,0H138.25a3,3,0,0,1,3,3V33a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(17221 7321)" />
                <rect id="Rectangle_272" data-name="Rectangle 272" className="inner" width="40.25" height="40.25" rx="3" transform="translate(17373 7171)" />
                <rect id="Rectangle_274" data-name="Rectangle 274" style={styles.cls4} width="40" height="4" rx="2" transform="translate(17373 7231)" />
                <rect id="Rectangle_275" data-name="Rectangle 275" style={styles.cls4} width="40" height="4" rx="2" transform="translate(17373 7255)" />
                <rect id="Rectangle_276" data-name="Rectangle 276" style={styles.cls4} width="40" height="4" rx="2" transform="translate(17373 7243)" />
                <rect id="Rectangle_277" data-name="Rectangle 277" style={styles.cls4} width="20" height="4" rx="2" transform="translate(17373 7267)" />
                <rect id="Rectangle_265" data-name="Rectangle 265" className="inner" width="141.25" height="70" rx="3" transform="translate(17222 7171)" />
                <rect id="Rectangle_267" data-name="Rectangle 267" style={styles.cls4} width="141" height="4" rx="2" transform="translate(17222 7261)" />
                <rect id="Rectangle_268" data-name="Rectangle 268" style={styles.cls4} width="141" height="4" rx="2" transform="translate(17222 7285)" />
                <rect id="Rectangle_269" data-name="Rectangle 269" style={styles.cls4} width="141" height="4" rx="2" transform="translate(17222 7273)" />
                <rect id="Rectangle_270" data-name="Rectangle 270" style={styles.cls4} width="106" height="4" rx="2" transform="translate(17222 7297)" />
                <rect id="Rectangle_266" data-name="Rectangle 266" className="inner" width="192" height="20" rx="3" transform="translate(17221 7131)" />
            </svg>

        );
    }
}

export default TwoOneColumn;
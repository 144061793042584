import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import CompanySelect from "./bb_topNav_CompanySelect";

export default function BBMobileCompanySelect({ onChangeAccount }) {
  const { siteMaster, user } = useSelector((state) => state);
  const accountMaster = useSelector(
    (state) => state.accountMaster.accountMaster
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const [accounts, setAccounts] = useState([]);

  async function getAccounts() {
    try {
      const accounts = await axios.get("/accountUser/me/accounts");
      setAccounts(accounts.data.Accounts);
    } catch {
      setAccounts([]);
    } finally {
      setLoadingAccounts(false);
    }
  }

  function handleTriggerClick() {
    setIsDropdownOpen(true);
    getAccounts();
  }

  const blockSwitchCompany = !!siteMaster.blockSwitchAccount;

  return (
    <div className="mobile-company-select pos-rel">
      <button
        type="button"
        className="account-menu-button"
        onClick={() => blockSwitchCompany ? undefined : handleTriggerClick()}
      >
        <span className="account-menu-button-name">
          {accountMaster.accountName}
        </span>

        <UILIB.Avatar for="account" src={user.userData.profilePicFileName} />

        {!blockSwitchCompany && <UILIB.Icons color="#666666" icon="smallArrowDown" />}
      </button>

      <UILIB.DropDown
        isOpen={isDropdownOpen}
        onClose={() => setIsDropdownOpen(false)}
      >
        <CompanySelect
          activeAccount={accountMaster}
          accounts={accounts}
          loadedAccounts={!loadingAccounts}
          isOpen={isDropdownOpen}
          onChangeAccount={onChangeAccount}
        />
      </UILIB.DropDown>
    </div>
  );
}

import React from 'react'
import UILIB from '~/Common-Objects/Lib';
export default class ActivityLog extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {

        var thisDeviceType = <span className="icon-envelope"></span>;

        switch (this.props.devType) {
            case "phone":
                {
                    thisDeviceType = <span className="icon-phone"></span>;
                    break;
                }
            case "tablet":
                {
                    thisDeviceType = <span className="icon-tablet"></span>
                    break;
                }
            case "desktop":
                {
                    thisDeviceType = <span className="icon-desktop"></span>
                    break;
                }
            case "car":
                {
                    thisDeviceType = <span className="icon-car"></span>
                    break;
                }
            default:
                {
                    thisDeviceType = <span className={this.props.devType}></span>
                    break;
                }
        }


        return <div className="activity-class">
            <UILIB.Paper>
                <div className="title">
                    <div className="text-sml text-grey">{this.props.title}</div>
                </div>
                <div className="log-time">
                    <div className="text-xsml text-grey text-heavy">{this.props.time}</div>
                </div>
                <div className="device-icon">
                    {thisDeviceType}
                </div>

            </UILIB.Paper>

            {this.props.isLast === false && <div className="activity-log-activator"></div>}
        </div>
    }
}

import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib.jsx'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import i18n from '~/i18n'

@connect((store) => {
    return { user: store.user }
})
export default class IncTypeSelectOptions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            optionsData: [{
                value: "",
                label: "",
                erroredLabel: "",
                erroredValue: ""
            }]
            ,
            doingBulkImport: false,
            bulkImportData: "",
            bulkError: "",
            pageLoading: false
        };

        this.updateInput = this.updateInput.bind(this);
        this.addNewOption = this.addNewOption.bind(this);
        this.checkAndSave = this.checkAndSave.bind(this);
        this.deleteOption = this.deleteOption.bind(this);
        this.saveBulk = this.saveBulk.bind(this);
        this.addCommonData = this.addCommonData.bind(this);
        this.clearAll = this.clearAll.bind(this);
    }

    componentDidMount() {
        if (this.props.fieldData && this.props.fieldData.options) {
            this.setState({ optionsData: this.props.fieldData.options })
        }
    }

    updateInput(event, index, dataType) {
        var optionsData = this.state.optionsData;
        optionsData[index][dataType] = event.target.value;
        this.setState(optionsData)
    }
    addNewOption() {
        var optionsData = this.state.optionsData;
        optionsData.push({ value: '', label: '', erroredLabel: false, erroredValue: false })
        this.setState(optionsData);
    }
    deleteOption(index) {
        var optionsData = this.state.optionsData;
        if (optionsData.length < 2) return;
        optionsData = optionsData.splice(index, 1);
        this.setState(optionsData);
    }
    checkAndSave() {
        //check all options have values + labels
        var failed = false;
        var optionsData = this.state.optionsData;
        optionsData.forEach((opt, index) => {
            if (opt.value == '') { failed = true; optionsData[index].erroredValue = i18n.t('subscribers:customfields.multi.errorValue'); }
            else { optionsData[index].erroredValue = ""; }
            if (opt.label == '') { failed = true; optionsData[index].erroredLabel = i18n.t('subscribers:customfields.multi.errorLabel'); }
            else { optionsData[index].erroredLabel = ""; }
        });
        this.setState({ optionsData });
        if (!failed) {
            var fieldData = this.props.fieldData;
            fieldData.options = optionsData.map((opt) => {
                return { label: opt.label, value: opt.value }
            })
            this.props.saveData(fieldData)
        }
    }

    saveBulk() {
        var bulkData = this.state.bulkImportData;
        if (!bulkData || !bulkData.length) {
            this.setState({ bulkError: i18n.t('subscribers:customfields.multi.errorBulk') })
            return;
        }

        var dataOut = this.state.optionsData;
        bulkData.split(/\n/g).forEach(theLine => {
            if (!theLine) return;
            if (theLine.indexOf('\t')) {
                var theDat = theLine.split(/\t/g);
                if (theDat.length > 1) {
                    if (dataOut.find(dd => dd.label == theDat[0])) return
                    dataOut.push({ label: theDat[0], value: theDat[1], errorLabel: "", erroredValue: "" })
                    return;
                }
            }

            if (dataOut.find(dd => dd.label == theLine)) return
            dataOut.push({ label: theLine, value: theLine, errorLabel: "", erroredValue: "" })
        })


        this.setState({ bulkImportData: "", doingBulkImport: false, optionsData: dataOut })

    }

    addCommonData(dataType) {
        var self = this;
        var dataOut = this.state.optionsData.filter((item) => item.name != "" && item.name != undefined && item.name != null)

        if (dataType == "countries") {
            this.setState({ pageLoading: true });
            axios.get("/countries").then(_countries => {
                var data = _countries.data.Countries;

                data.forEach(country => {
                    var found = dataOut.find(theDat => theDat.value == country.name);
                    if (!found) {
                        dataOut.push({ label: country.name, value: country.name, errorLabel: "", erroredValue: "" })
                    }
                })
                self.setState({ bulkImportData: "", doingBulkImport: false, optionsData: dataOut, pageLoading: false })
            }).catch(err => {
                console.log(err);
                return;
            })
        }
    }

    clearAll() {
        this.setState({
            optionsData: [{
                value: "",
                label: "",
                erroredLabel: "",
                erroredValue: ""
            }]
        })
    }

    render() {

        if (this.state.pageLoading) return <UILIB.LoadingIcons iconType="2" />
        if (this.state.doingBulkImport) {
            return <div style={{ display: "flex", flexDirection: "column", height: "90vh" }}>

                <h4 className="mar-b25">{i18n.t('subscribers:customfields.multi.bulkheader')}</h4>
                <div className="mar-b25">{i18n.t('subscribers:customfields.multi.bulksubheader')}</div>

                <UILIB.Paper>
                    <h4 className="mar-b25">{i18n.t('subscribers:customfields.multi.copypasteheader')}</h4>
                    <div className="mar-b25">{i18n.t('subscribers:customfields.multi.copypaste')}</div>

                    <UILIB.TextArea name="bulkImportVals" outerClassName="mar-b25" style={{ height: "250px" }} placeholder={i18n.t('subscribers:customfields.multi.copypasteaction')} value={this.state.bulkImportData} onChange={(event) => { this.setState({ bulkImportData: event.target.value }) }} error={this.state.bulkError} />

                    <UILIB.Button className="button-md primary" iconLeft={<UILIB.Icons icon="tick" />} text={i18n.t('subscribers:customfields.multi.save')} onClick={this.saveBulk} />
                </UILIB.Paper>

                <UILIB.Paper>
                    <h4 className="mar-b25">{i18n.t('subscribers:customfields.multi.pre')}</h4>
                    <div className="mar-b25">{i18n.t('subscribers:customfields.multi.predetail')}</div>
                    <UILIB.Button text={i18n.t('subscribers:customfields.multi.countries')} className="button-sml secondary" onClick={() => { this.addCommonData("countries") }} />
                </UILIB.Paper>

            </div >
        }

        return <div style={{
            display: "flex", flexDirection: "column",
            height: "90vh"
        }}>
            <div>
                <h4 className="mar-b25">{i18n.t('subscribers:customfields.multi.header')}</h4>
                <div className="mar-b25">{i18n.t('subscribers:customfields.multi.subheader')}</div>
            </div>

            <UILIB.Paper style={{ flex: "11", overflow: "auto" }} className="mar-b30">
                {this.state.optionsData.map((opt, index) => {
                    return <div key={"opt_" + index} style={{ display: "flex", alignItems: "center" }} className="mar-b5">
                        <div style={{ flex: "1" }}>
                            <UILIB.TextInput outerClassName="mar-r5" value={opt.label} error={opt.erroredLabel} placeholder={i18n.t('subscribers:customfields.multi.label')} name="placeholder" onChange={(event) => { this.updateInput(event, index, "label") }} />
                        </div>
                        <div style={{ flex: "1" }}>
                            <UILIB.TextInput outerClassName="mar-r10" value={opt.value} error={opt.erroredValue} placeholder={i18n.t('subscribers:customfields.multi.value')} name="value" onChange={(event) => { this.updateInput(event, index, "value") }} />
                        </div>
                        <div style={{ flex: "0" }}>
                            {this.state.optionsData.length > 1 &&
                                <UILIB.Icons icon="bin" onClick={() => { this.deleteOption(index) }} style={{ cursor: "pointer" }} />
                            }
                        </div>
                    </div>
                })}
            </UILIB.Paper>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <UILIB.Button className="button-primary" text={i18n.t('subscribers:customfields.multi.save')} onClick={this.checkAndSave} iconLeft={<UILIB.Icons icon="tick" />} />
                <div>
                    <UILIB.Button className="mar-r10" text={i18n.t('subscribers:customfields.multi.addAnother')} onClick={this.addNewOption} iconLeft={<UILIB.Icons icon="plus" />} />
                    <UILIB.Button className="mar-r10" text={i18n.t('subscribers:customfields.multi.addBulk')} onClick={() => { this.setState({ doingBulkImport: true }) }} />
                    {(this.state.optionsData && this.state.optionsData.length > 1) && <UILIB.Button className="mar-r10" text={i18n.t('subscribers:customfields.multi.clear')} onClick={this.clearAll} />}
                </div>


            </div>
        </div >
    }
};


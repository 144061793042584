import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import * as siteMaster from '~/data/actions/siteActions';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import AutomationFunctions from './automationFunctions'
import segmentsHelper from '~/Classes/segments.jsx'
import SiteVars from '~/Classes/siteVars';
import TemplateImageLoader from './templateImageLoader.jsx'
import i18n from '~/i18n'
import Pipedrive from './integrations/pipedrive/option'
import GenericOption from './integrations/generic/option'
import SmsPhone from '~/pages/cp/includes/sms/phone'
import SmsIPhone from '../../includes/sms/iphone'
import TopupSms from '~/pages/cp/company/sms/topup'
import textFunctions from '~/Classes/textFunctions';
import StepStatsHolder from './stepStatsHolder';
import PermissionChecker from '~/Classes/permissions';
function checkActivationErrors(activateError, step, outerStyle, overrideBorder) {
    if (activateError && activateError != "" && !step.complete) {
        outerStyle.border = "1px solid red";
        overrideBorder = true;
    }
}

@connect((store) => {
    return { permissionStore: store.permission, accountMaster: store.accountMaster }
})

class AutomationOption extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.topupSms = this.topupSms.bind(this)
        this.goTo = this.goTo.bind(this)
    }

    topupSms(e) {
        e.stopPropagation()
        const drawer = <TopupSms onClose={() => this.props.dispatch(siteMaster.alterSiteDrawer(false))} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, true, "500px", undefined))
    }

    goTo(e, path) {
        e.stopPropagation()
        this.props.saveAndGo(path)
    }

    render() {
        const { automationId } = this.props;
        let className = "automationOption";
        if (this.props.disabled) className += ' automationDisabled'
        if (this.props.highlightStep) className += ' automationHighlight'
        var step = this.props.step;
        var isRunning = this.props.automationStatus == "Running" || this.props.automationStatus == 'Starting' || this.props.automationStatus == 'Archived' || this.props.automationStatus === 'Errored' || this.props.automationStatus === 'Testing';
        var hideDeleteButton = false;
        var isDisabled = false;
        if (isRunning || this.props.choosing) {
            //choosing a step for stuff like the redirect step. Hides controls
            hideDeleteButton = true;
        }
        if (this.props.hideAllOptions) {
            hideDeleteButton = true;
        }

        if (this.props.disabled) hideDeleteButton = true;

        if (this.props.choosing && this.props.choosingSourceGuid == step.guid) {
            isDisabled = true;
        }

        let stepStats = this.props.stepStats.find(s => s.StepGuid == step.guid);
        let stepStatsQueued = stepStats?.Queued || 0;
        let stepStatsProcessed = stepStats?.Processed || 0;

        const stepStatsHolderProps = {
            automationStatus: this.props.automationStatus,
            step,
            automationId,
            disabled: this.props.disabled
        }

        let canViewContacts = PermissionChecker('subscribers', this.props.permissionStore.permissions, "read")
        if (!canViewContacts) stepStatsHolderProps.disabled = true;

        let statsQueuedHolder = (
            <StepStatsHolder type="queued" {...stepStatsHolderProps}>
                {stepStatsQueued}
            </StepStatsHolder>
        );
        let statsProcessedHolder = (
            <StepStatsHolder type="processed" {...stepStatsHolderProps}>
                {stepStatsProcessed}
            </StepStatsHolder>
        );

        var outerStyle = {};
        if (this.props.outerStyle) outerStyle = this.props.outerStyle;
        var overrideBorder = false;

        if (isDisabled) {
            outerStyle.opacity = 0.3
        }

        checkActivationErrors(this.props.activateError, step, outerStyle, overrideBorder)

        const editStep = isDisabled ? undefined : () => this.props.editStep(this.props.step)

        if (step.type == "email") {

            let backgroundImage = "";
            if (step.stepOptions?.campaign?.TemplateId) {
                backgroundImage = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + step.stepOptions.campaign.TemplateId + ".jpg"
            }
            return <div id={this.props.id} className={className + " autoEmail"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                {!step.complete && <div>
                    <div className="autoEmailPreview">
                        {backgroundImage && <TemplateImageLoader imageUrl={backgroundImage} />}
                    </div>
                    <div className="autoEmailSubject">
                        <div className="automationToDoHolder">{i18n.t('automation:add.prompts.defineAnEmail')}</div>
                    </div>
                </div>
                }
                {step.complete && <div>
                    <div className="autoEmailPreview">
                        <TemplateImageLoader imageUrl={backgroundImage} />
                    </div>
                    <div className="autoEmailSubject">
                        {step.stepOptions.subject.value}
                    </div>
                </div>
                }
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }

        if (step.type === 'sms') {
            return <div id={this.props.id} className={className + " autoSms"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                {Boolean(step.stepOptions.subject.value) && Boolean(step.stepOptions?.campaign?.TemplateId) && <div className="phone">
                    <SmsIPhone from={step.stepOptions.subject.value} content={""} size="tiny" />
                </div>}
                {(!Boolean(step.stepOptions.subject.value) || !Boolean(step.stepOptions.campaign.TemplateId)) && <div className="iconMedalHolder"><div className="icon-smartphone" /></div>}
                <div className="autoEmailSubject">
                    Send An SMS <strong>{step.stepOptions.subject.value ? `from ${step.stepOptions.subject.value}` : ''}</strong>
                </div>
                <div className="mar-t5 text-xsml text-heavy text-grey">{this.props.accountMaster.accountMaster.smsCredits} Credit Remaining </div>
                <UILIB.Button className="primary mar-t5" text="Top Up" onClick={this.topupSms} />
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }

        if (step.type == "notification") {

            var outPutText = [];
            if (step.complete) {
                //notifications
                outPutText.push(<span>{i18n.t('automation:add.options.notify')}</span>);
                step.stepOptions.toAddresses.forEach(address => {
                    outPutText.push(<span><strong>{address.value}</strong></span>);
                    outPutText.push(<span>, </span>)
                })
                outPutText.splice(outPutText.length - 1, 1)
            }
            return <div id={this.props.id} className={className + " autoNotification"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                <div className="iconMedalHolder"><UILIB.Icons icon="megaphone" color="purple" style={{ width: 32, height: 32, margin: "0 auto" }} /></div>
                {!step.complete && <div className="automationToDoHolder">{i18n.t('automation:add.prompts.defineANotification')}</div>}
                {step.complete && <div>{outPutText}</div>}
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }

        if (step.type == "webhook") {

            var outPutText = "";
            if (step.complete) {
                //notifications
                outPutText = <span>{i18n.t('automation:add.options.webhook') + " " + step.stepOptions.url.value}</span>;
            }

            return <div id={this.props.id} className={className + " autoNotification"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                <div className="iconMedalHolder"><UILIB.Icons icon="lightning" color="purple" style={{ width: 32, height: 32, margin: "0 auto" }} /></div>
                {(step.complete == false) && <div className="automationToDoHolder">{i18n.t('automation:add.prompts.defineAWebhook')}</div>}
                {(step.complete == true) && <div>{outPutText}</div>}
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }

        if (step.type == "condition") {

            var outputText = "";
            if (step.complete) {
                if (step.stepOptions.triggerType.value == "0") {
                    //workflow campaign actions
                    var theEmail = AutomationFunctions.getStepByGuid(step.stepOptions.workflowEmailGuid.value, this.props.allSteps);
                    var theEmailSubject = "";
                    if (theEmail && theEmail.stepOptions && theEmail.stepOptions.subject) theEmailSubject = theEmail.stepOptions.subject.value;
                    theEmailSubject = textFunctions.checkForHtml(theEmailSubject);
                    outputText = theEmailSubject;

                    let linkClickedValue = textFunctions.checkForHtml(step.stepOptions.linkClicked.value);

                    if (step.stepOptions.workflowEmailActionType.value == "0") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.opened', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "1") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.notOpened', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "2") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.anyLink', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "3") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.link', { subject: theEmailSubject, link: linkClickedValue }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "4") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.openedNoLinks', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "5") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.openedNoLink', { subject: theEmailSubject, link: linkClickedValue }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "6") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.replied', { subject: theEmailSubject }) }}></span>
                }

                if (step.stepOptions.triggerType.value == "1") {
                    //custom field values
                    var customFieldName = "";
                    var customFieldAction = step.stepOptions.customFieldAction.value;
                    var customFieldValue = step.stepOptions.customFieldValue.value;

                    var customField = this.props.customFieldsData.find(fd => fd.value == step.stepOptions.customFieldId.value)
                    if (customField) {
                        customFieldName = customField.label;

                        var customFieldType = customField.fieldType;
                        var segRules = segmentsHelper.getOperators('string');
                        if (customFieldType == "TEXT") {
                            segRules = segmentsHelper.getOperators('string');
                        }
                        if (customFieldType == "NUMBER") {
                            segRules = segmentsHelper.getOperators('int');
                        }
                        if (customFieldType == "TRUE/FALSE" || customFieldType == "BOOLEAN") {
                            segRules = segmentsHelper.getOperators('bool');
                        }
                        if (customFieldType == "DATE") {
                            segRules = segmentsHelper.getOperators('date');
                            if (customFieldAction.indexOf('be') < 0 && customFieldAction.indexOf('blank') < 0) {
                                customFieldValue = DateTimeFunctions.formatDateTime(customFieldValue, 2);
                            } else {
                                customFieldValue = "";
                            }
                        }

                        var foundSegmentRule = segRules.find(sf => sf.value == customFieldAction)
                        if (foundSegmentRule) customFieldAction = foundSegmentRule.label
                    }


                    customFieldValue = customFieldValue + "";
                    outputText = <span><strong>{customFieldName}</strong> {customFieldAction} <strong>{customFieldValue}</strong></span>
                }

                if (step.stepOptions.triggerType.value == "2") {
                    //segments
                    var segmentName = "";

                    var segment = this.props.segmentsData.find(sg => sg.value == step.stepOptions.segmentId.value)
                    if (segment) {
                        segmentName = segment.label;
                    }
                    segmentName = textFunctions.checkForHtml(segmentName)
                    outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.segment', { name: segmentName }) }}></span>
                }
                if (step.stepOptions.triggerType.value == "3") {
                    //normal campaign actions
                    var theEmail = this.props.campaignsData.find(cp => cp.value == step.stepOptions.campaignId.value)

                    var theEmailSubject = "";
                    var theReplyAddress = "";
                    if (theEmail && theEmail.label) {
                        theEmailSubject = theEmail.label;
                        theReplyAddress = theEmail.replyAddress

                    }
                    theEmailSubject = textFunctions.checkForHtml(theEmailSubject)
                    outputText = theEmailSubject;
                    var theLink = textFunctions.checkForHtml(step.stepOptions.linkClicked.value);


                    if (step.stepOptions.campaignId.value === "") theEmailSubject = i18n.t('automation:add.options.anyCampaign')

                    if (step.stepOptions.workflowEmailActionType.value == "0") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.opened', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "1") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.notOpened', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "2") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.anyLink', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "3") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.link', { subject: theEmailSubject, link: theLink }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "4") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.openedNoLinks', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "5") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.openedNoLink', { subject: theEmailSubject, link: theLink }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "6") {
                        var mailbox

                        if (theReplyAddress) {
                            mailbox = this.props.mailboxes.find(mb => mb.emailAddress.toLowerCase() === theReplyAddress.toLowerCase())
                        }
                        if (step.stepOptions.campaignId.value == "-1") {
                            outputText = <div>
                                <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.repliedAny') }}></span>
                                {!this.props.mailboxes.length && <div className="text-red mar-t10 text-heavy">No Mailboxes not being monitored <UILIB.Button onClick={(e) => this.goTo(e, '/cp/company/mailboxes')} text="Setup" className="mar-l10 red outline" /></div>}
                            </div>
                        } else {
                            outputText = <div>
                                <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.replied', { subject: theEmailSubject }) }}></span>
                                {!this.props.mailboxes.length || (!!theReplyAddress && (!mailbox || !mailbox.active)) && <div className="text-red mar-t10 text-heavy">{theReplyAddress || 'No Mailboxes'} not being monitored <UILIB.Button onClick={(e) => this.goTo(e, '/cp/company/mailboxes')} text="Setup" className="mar-l10 red outline" /></div>}
                            </div>
                        }
                    }

                    if (step.stepOptions.workflowEmailActionFilterOperator && step.stepOptions.workflowEmailActionFilterOperator.value) {
                        var filter = ""
                        if (step.stepOptions.workflowEmailActionFilterOperator.value === '=') filter += i18n.t('automation:add.condition.exactlyXView', { view: step.stepOptions.workflowEmailActionFilterValue.value })
                        if (step.stepOptions.workflowEmailActionFilterOperator.value === '>') filter += i18n.t('automation:add.condition.atLeastXView', { view: step.stepOptions.workflowEmailActionFilterValue.value })
                        if (step.stepOptions.workflowEmailActionFilterOperator.value === '<') filter += i18n.t('automation:add.condition.lessThanXView', { view: step.stepOptions.workflowEmailActionFilterValue.value })
                        outputText = <span>{outputText} {filter}</span>
                    }
                }

                if (step.stepOptions.triggerType.value == "7") {
                    var groupName = "";
                    var foundGroup = this.props.groupsData.find(gp => gp.value == this.props.step.stepOptions.groupId.value);
                    if (foundGroup) groupName = foundGroup.label;
                    groupName = textFunctions.checkForHtml(groupName);
                    let subscriberState = this.props.step.stepOptions.subscriberState.value;
                    subscriberState = textFunctions.checkForHtml(subscriberState);

                    var message = i18n.t('automation:add.condition.groupWithState');
                    message = message.replace(/\[groupName\]/g, "<strong>" + groupName + "</strong>");
                    message = message.replace(/\[subscriberState\]/g, "<strong>" + subscriberState + "</strong>");

                    outputText = <span dangerouslySetInnerHTML={{
                        __html: message
                    }}></span>;
                }

                if (step.stepOptions.triggerType.value == "9") {
                    var formName = "FORM NOT FOUND";
                    var form = this.props.forms.find(f => f.id == this.props.step.stepOptions.formId?.value);
                    if (form) formName = form.formName;
                    formName = textFunctions.checkForHtml(formName);

                    let message = ""
                    if (this.props.step.stepOptions.formActivity?.value === 'download') {
                        message = i18n.t('automation:add.condition.formDownload', { formName })
                    } else if (this.props.step.stepOptions.formActivity?.value === 'signup') {
                        message = i18n.t('automation:add.condition.formSignup', { formName })
                    }

                    outputText = <span dangerouslySetInnerHTML={{
                        __html: message
                    }}></span>;
                }

                if (step.stepOptions.triggerType.value == "10") {
                    outputText = i18n.t('automation:add.condition.inOtherAutomation', { count: step.stepOptions.otherAutomations?.value?.length || 0 })
                }

                if (step.stepOptions.triggerType.value == "11") {
                    const tag = step.stepOptions.tagId?.value ? this.props.tags.find(t => t.id === step.stepOptions.tagId.value) : null
                    const tagOption = step.stepOptions.tagType?.value === 'has not' ? 'Does not have ' : 'Has '
                    if (tag) {
                        outputText = <div>{tagOption} the <strong>{tag.tagName}</strong> Tag</div>
                    } else {
                        outputText = `${tagOption} a Tag`
                    }
                }

                if (step.stepOptions.triggerType.value === '12') {
                    outputText = i18n.t('automation:add.condition.datetimeOption', {
                        beforeAfter: step.stepOptions.datetime?.beforeAfter?.value === 'before' ? i18n.t("automation:add.condition.before") : i18n.t("automation:add.condition.after"),
                        datetime: DateTimeFunctions.formatDateTime(step.stepOptions.datetime?.value)
                    })
                }

                if (isNaN(step.stepOptions.triggerType.value)) {
                    const app = this.props.applications.find(a => a.Application.options && a.Application.options.triggers && a.Application.options.triggers.some(t => t.value === step.stepOptions.triggerType.value))
                    if (app) {
                        const trigger = app.Application.options.triggers.find(t => t.value === step.stepOptions.triggerType.value)
                        if (trigger) outputText = <span>Check the <strong>{trigger.label}</strong> Status</span>
                    }
                    if (!outputText) outputText = <span>Integration Not Found</span>
                }
            }
            return <div id={this.props.id} className={className + " autoCondition"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                <div className="iconArrowsSplitHolder"><UILIB.Icons icon="split" color="#fff" style={{ width: 20, height: 20 }} /></div>
                {!step.complete && <div className="automationToDoHolder">{i18n.t('automation:add.prompts.defineACondition')}</div>}
                {step.complete && <div>{outputText}</div>}

                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }

        if (step.type == "delay") {
            var outputText = "";
            var unitType = i18n.t(step.stepOptions.delay_unit.value);
            let delayValue = textFunctions.checkForHtml(step.stepOptions.delay_value.value);
            let delayHours = textFunctions.checkForHtml(step.stepOptions.delay_time_hours.value);
            let delayMins = textFunctions.checkForHtml(step.stepOptions.delay_time_mins.value);
            let delayDayOfMonth = textFunctions.checkForHtml(step.stepOptions.delay_dayOfMonth.value);
            let delayExactDate = textFunctions.checkForHtml(step.stepOptions.delay_exactDate.value);

            if (unitType.indexOf('next') < 0) {
                outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.delayNext', { number: delayValue, unit: unitType }) }}></span>;
            }
            if (unitType == "next_time") {
                outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.delayNextTime', { hour: delayHours, minute: delayMins + " " + DateTimeFunctions.getTimeZone(step.stepOptions.delay_time_timezone?.value) }) }}></span>;
            }
            if (unitType == "next_day") {

                var theDays = step.stepOptions.delay_daysOfWeek.value.map(tD => {
                    if (tD == "0") return i18n.t('sunday')
                    if (tD == "1") return i18n.t('monday')
                    if (tD == "2") return i18n.t('tuesday')
                    if (tD == "3") return i18n.t('wednesday')
                    if (tD == "4") return i18n.t('thursday')
                    if (tD == "5") return i18n.t('friday')
                    if (tD == "6") return i18n.t('saturday')
                })

                outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.delayNextDay', { day: theDays.join(', ') }) }}></span>;
            }
            if (unitType == "next_monthday") {
                outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.delayNextMonthday', { day: delayDayOfMonth }) }}></span>
            }
            if (unitType == "next_yearday") {
                outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.delayNextYearday', { date: DateTimeFunctions.formatDateTime(delayExactDate, 2) }) }}></span>
            }
            return <div id={this.props.id} className={className + " autoDelay"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                {!step.complete && <div className="automationToDoHolder">{i18n.t('automation:add.prompts.defineADelay')}</div>}
                {step.complete && <div className="autoEmailTitle">{outputText}</div>}
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }

        if (step.type == "wait") {

            var outputText = "";
            if (step.complete) {
                if (step.stepOptions.triggerType.value == "0") {
                    //workflow campaign actions
                    var theEmail = AutomationFunctions.getStepByGuid(step.stepOptions.workflowEmailGuid.value, this.props.allSteps);
                    var theEmailSubject = "";
                    if (theEmail && theEmail.stepOptions && theEmail.stepOptions.subject) {
                        theEmailSubject = theEmail.stepOptions.subject.value;
                    }
                    theEmailSubject = textFunctions.checkForHtml(theEmailSubject);
                    outputText = theEmailSubject;
                    let linkValue = textFunctions.checkForHtml(step.stepOptions.linkClicked.value)
                    if (step.stepOptions.workflowEmailActionType.value == "0") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.opened', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "1") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.notOpened', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "2") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.anyLink', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "3") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.link', { subject: theEmailSubject, link: linkValue }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "4") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.openedNoLinks', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "5") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.openedNoLink', { subject: theEmailSubject, link: linkValue }) }}></span>
                }

                if (step.stepOptions.triggerType.value == "1") {
                    //custom field values
                    var customFieldName = "";
                    var customFieldAction = step.stepOptions.customFieldAction.value;
                    var customFieldValue = step.stepOptions.customFieldValue.value;

                    var customField = this.props.customFieldsData.find(fd => fd.value == step.stepOptions.customFieldId.value)
                    if (customField) {
                        customFieldName = customField.label;

                        var customFieldType = customField.fieldType;
                        var segRules = segmentsHelper.getOperators('string');
                        if (customFieldType == "TEXT") {
                            segRules = segmentsHelper.getOperators('string');
                        }
                        if (customFieldType == "NUMBER") {
                            segRules = segmentsHelper.getOperators('int');
                        }
                        if (customFieldType == "TRUE/FALSE" || customFieldType == "BOOLEAN") {
                            segRules = segmentsHelper.getOperators('bool');
                        }
                        if (customFieldType == "DATE") {
                            segRules = segmentsHelper.getOperators('date');
                        }

                        var foundSegmentRule = segRules.find(sf => sf.value == customFieldAction)
                        if (foundSegmentRule) {
                            customFieldAction = foundSegmentRule.label
                            var customFieldVal = foundSegmentRule.value;

                            if (customFieldType == "DATE") {
                                if (customFieldVal.length > 2) {
                                    customFieldAction = customFieldAction.replace(' x ', ' ' + customFieldValue + ' ')
                                    customFieldValue = "";
                                }
                                else {
                                    customFieldValue = DateTimeFunctions.formatDateTime(customFieldValue, 2);
                                }
                            }

                        }
                    }
                    customFieldValue = customFieldValue + "";
                    outputText = <span><strong>{customFieldName}</strong> {customFieldAction} <strong>{customFieldValue}</strong></span>
                }

                if (step.stepOptions.triggerType.value == "2") {
                    //segments
                    var segmentName = "";

                    var segment = this.props.segmentsData.find(sg => sg.value == step.stepOptions.segmentId.value)
                    if (segment) {
                        segmentName = segment.label;
                    }
                    segmentName = textFunctions.checkForHtml(segmentName);
                    outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.segments', { segment: segmentName }) }}></span>
                }
                if (step.stepOptions.triggerType.value == "3") {
                    //normal campaign actions
                    var theEmail = this.props.campaignsData.find(cp => cp.value == step.stepOptions.campaignId.value)

                    var theEmailSubject = "";
                    if (theEmail && theEmail.label) theEmailSubject = theEmail.label;
                    theEmailSubject = textFunctions.checkForHtml(theEmailSubject);
                    outputText = theEmailSubject;
                    var theLink = textFunctions.checkForHtml(step.stepOptions.linkClicked.value);
                    if (step.stepOptions.campaignId.value === "") theEmailSubject = i18n.t('automation:add.options.anyCampaign');
                    if (step.stepOptions.workflowEmailActionType.value == "0") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.opened', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "1") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.notOpened', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "2") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.anyLink', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "3") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.link', { subject: theEmailSubject, link: theLink }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "4") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.openedNoLinks', { subject: theEmailSubject }) }}></span>
                    if (step.stepOptions.workflowEmailActionType.value == "5") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.openedNoLink', { subject: theEmailSubject, link: theLink }) }}></span>
                }

                if (step.stepOptions.triggerType.value == "7") {
                    var groupName = "";
                    var foundGroup = this.props.groupsData.find(gp => gp.value == this.props.step.stepOptions.groupId.value);
                    if (foundGroup) groupName = foundGroup.label;

                    var message = i18n.t('automation:add.wait.groupWithState');

                    groupName = textFunctions.checkForHtml(groupName);
                    let subscriberStateValue = textFunctions.checkForHtml(this.props.step.stepOptions.subscriberState.value)

                    message = message.replace(/\[groupName\]/g, "<strong>" + groupName + "</strong>");
                    message = message.replace(/\[subscriberState\]/g, "<strong>" + subscriberStateValue + "</strong>");


                    outputText = <span dangerouslySetInnerHTML={{
                        __html: message
                    }}></span>;
                }

                let app;
                this.props.applications.forEach(a => {
                    if (a.Application.appName === step.stepOptions.triggerType.value) app = a
                })

                if (app) {
                    let trigger = app.Application.options.triggers.find(t => t.value === step.stepOptions.trigger.value)
                    outputText = <div>
                        <img height="40" src={app.Application.appLogoUri} />
                        <div>the <strong>{app.Application.appName}</strong> action <strong>{trigger ? trigger.label : ''}</strong></div>
                    </div>
                }
            }
            return <div id={this.props.id} className={className + " autoWait"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                {!step.complete && <div className="automationToDoHolder">{i18n.t('automation:add.prompts.defineWaitFor')}</div>}
                {step.complete && <div className="autoEmailTitle">{i18n.t('automation:add.prompts.waitHereUntil')} {outputText}</div>}
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>

        }

        if (step.type == "action") {

            var outputText = "";
            if (step.complete) {
                //actions

                if (step.stepOptions.actionType.value == "0") {
                    var customFieldName = "";
                    var customField = this.props.customFieldsData.find(fd => fd.value == step.stepOptions.customFieldId.value)
                    if (customField) {
                        customFieldName = customField.label;
                        customFieldName = textFunctions.checkForHtml(customFieldName);
                    }

                    if (step.stepOptions.customFieldId.increment) {
                        outputText = <span>{step.stepOptions.customFieldId.increment === 'inc' ? 'Increment' : 'Decrement'} <strong>{customFieldName}</strong> by <strong>{step.stepOptions.customFieldValue.value}</strong></span>
                    } else {
                        let tmpVal = "";
                        if (Array.isArray(step.stepOptions.customFieldValue.value)) {
                            tmpVal = JSON.parse(JSON.stringify(step.stepOptions.customFieldValue.value)).join(', ');
                        }
                        else {
                            tmpVal = step.stepOptions.customFieldValue.value;
                            if (tmpVal == "{{CURRENT_DATE}}") tmpVal = "The action date"
                        }
                        tmpVal = textFunctions.checkForHtml(tmpVal);

                        outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.actionUpdate', { field: customFieldName, value: tmpVal }) }}></span>
                    }
                }
                if (step.stepOptions.actionType.value == "1" || step.stepOptions.actionType.value == "2" || step.stepOptions.actionType.value == "3" || step.stepOptions.actionType.value == "11") {
                    var groupName = "";
                    var foundGroup = this.props.groupsData.find(gp => gp.value == this.props.step.stepOptions.groupId.value);
                    if (foundGroup) {
                        groupName = foundGroup.label;
                        groupName = textFunctions.checkForHtml(groupName);
                    }

                    if (step.stepOptions.actionType.value == "1") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.actionCopy', { group: groupName }) }}></span>
                    if (step.stepOptions.actionType.value == "2") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.actionMove', { group: groupName }) }}></span>
                    if (step.stepOptions.actionType.value == "3") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.actionDelete', { group: groupName }) }}></span>
                    if (step.stepOptions.actionType.value == "11") outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.actionUnsubscribeGroup', { group: groupName }) }}></span>
                }

                if (step.stepOptions.actionType.value == "4") {
                    outputText = <span>{i18n.t('automation:add.options.actionUnsubscribe')}</span>
                }
                if (step.stepOptions.actionType.value == "5") {
                    var groupName = "", tStatus = i18n.t("In-Active");
                    if (this.props.step.stepOptions.subscriberStatus && this.props.step.stepOptions.subscriberStatus.value == "1") tStatus = i18n.t("Active");
                    var foundGroup = this.props.groupsData.find(gp => gp.value == this.props.step.stepOptions.groupId.value);
                    if (foundGroup) {
                        groupName = foundGroup.label;
                        groupName = textFunctions.checkForHtml(groupName);
                    }
                    if (this.props.step.stepOptions.groupId.value == "ALL") groupName = i18n.t("allGroups");
                    outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.options.actionStatus', { status: tStatus, group: groupName }) }}></span>
                }
                if (step.stepOptions.actionType.value == "6") {
                    outputText = <span>{i18n.t('automation:add.options.actionReset')}</span>
                }
                if (step.stepOptions.actionType.value == "7") {
                    outputText = <span>{i18n.t('automation:add.action.action7')}</span>
                }
                if (step.stepOptions.actionType.value == "8") {
                    outputText = <span>{i18n.t('automation:add.action.action8')}</span>
                }
                if (step.stepOptions.actionType.value == "9") {
                    let tag
                    if (step.stepOptions.tagId && step.stepOptions.tagId.value) {
                        tag = this.props.tags.find(t => t.id == step.stepOptions.tagId.value)
                    }
                    outputText = <div>
                        <span>{i18n.t('automation:add.action.action9')}</span>
                        {!!tag && <div className="text-center text-heavy text-green-auto mar-t10">{tag.tagName}</div>}
                    </div>
                }
                if (step.stepOptions.actionType.value == "10") {
                    let tag
                    if (step.stepOptions.tagId && step.stepOptions.tagId.value) {
                        tag = this.props.tags.find(t => t.id == step.stepOptions.tagId.value)
                    }
                    outputText = <div>
                        <span>{i18n.t('automation:add.action.action10')}</span>
                        {!!tag && <div className="text-center text-heavy text-red mar-t10">{tag.tagName}</div>}
                    </div>
                }

                if (step.stepOptions.actionType.value == "11") {
                }

            }
            return <div id={this.props.id} className={className + " autoAction"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                <div className="iconCogHolder"><UILIB.Icons icon="cog" color="green" style={{ width: 32, height: 32, margin: "0 auto" }} /></div>
                {!step.complete && <div className="automationToDoHolder">{i18n.t('automation:add.prompts.defineAnAction')}</div>}
                {step.complete && <div>{outputText}</div>}
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }

        if (step.type === 'split') {
            var outPutText = <span><strong>Split Contacts</strong></span>;
            return <div id={this.props.id} className={className + " autoCondition"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                <div className="iconArrowsSplitHolder"><UILIB.Icons icon="expand" color="#fff" style={{ width: 20, height: 20 }} /></div>
                <div>{outPutText}</div>
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }
        if (step.type === 'combine') {
            var outPutText = <span><strong>Combine Split</strong></span>;
            return <div id={this.props.id} className={className + " autoCondition"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                <div className="iconArrowsSplitHolder"><UILIB.Icons icon="contract" color="#fff" style={{ width: 20, height: 20 }} /></div>
                <div>{outPutText}</div>
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }
        if (step.type == "goal") {

            var outputText = "";
            if (step.complete) {
                //goals
                outputText = step.stepOptions.name.value;

            }

            if (step.stepOptions.goalEndAction && step.stepOptions.goalEndAction.value && step.stepOptions.goalEndAction.value == "2") {
                if (step.stepOptions.goalEndAutomation && step.stepOptions.goalEndAutomation.value) {
                    let automation = this.props.automations.find(a => a.id == step.stepOptions.goalEndAutomation.value)
                    if (automation) {
                        let automationName = textFunctions.checkForHtml(automation.name);
                        if (step.stepOptions.triggerType.value == "6") {
                            outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.goal.sendToAutomationOptionScore', { automation: automationName }) }} />
                        } else {
                            outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.goal.sendToAutomationOption', { automation: automationName }) }} />
                        }
                    } else {
                        step.complete = false
                    }
                }
            }


            return <div id={this.props.id} className={className + " autoGoal"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                <div className="iconMedalHolder"><UILIB.Icons icon="medal" color="orange" style={{ width: 32, height: 32, margin: "0 auto" }} /></div>
                {!step.complete && <div className="automationToDoHolder">{i18n.t('automation:add.prompts.defineAGoal')}</div>}
                {step.complete && <div>{outputText}</div>}
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }
        if (step.type === 'score') {
            return <div id={this.props.id} className={className + " autoNotification"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                <div className="iconMedalHolder"><UILIB.Icons icon="arrowsUpDown" color="purple" style={{ width: 32, height: 32, margin: "0 auto" }} /></div>
                {(step.complete == false) && <div className="automationToDoHolder">{i18n.t('automation:add.prompts.defineAWebhook')}</div>}
                {(step.complete == true) && <div>{i18n.t('automation:add.condition.score')}</div>}
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }
        if (step.type === 'notification') {
            return <div id={this.props.id} className={className + " autoNotification"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                <div className="iconMedalHolder"><UILIB.Icons icon="megaphone" color="purple" style={{ width: 32, height: 32, margin: "0 auto" }} /></div>
                {(step.complete == false) && <div className="automationToDoHolder">{i18n.t('automation:add.prompts.defineAWebhook')}</div>}
                {(step.complete == true) && <div>{i18n.t('automation:add.condition.score')}</div>}
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }
        if (step.type === 'redirect') {
            var completedText = i18n.t("automation:add.redirect.optionText");
            if (completedText && completedText.length) {
                completedText = completedText.replace(/\[GUID\]/g, step.stepOptions.redirectGuid.value)
            }

            outerStyle.border = "1px solid blue";
            return <div id={this.props.id} className={className + " autoNotification"} onClick={editStep} style={outerStyle}>
                {statsQueuedHolder}
                <div className="iconMedalHolder"><UILIB.Icons icon="arrowBounce" color="blue" style={{ width: 32, height: 32, margin: "0 auto" }} /></div>
                {(step.complete == false) && <div className="automationToDoHolder">{i18n.t('automation:add.prompts.defineARedirect')}</div>}
                {(step.complete == true) && <div>{completedText}</div>}
                {!hideDeleteButton && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid) }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
                {statsProcessedHolder}
                {this.props.contactOnStep}
            </div>
        }
        if (step.type == "initial") {
            let stepArray = [this.props.step];
            if (this.props.step.triggerSteps) {
                stepArray = stepArray.concat(this.props.step.triggerSteps)
            }
            let outPutArray = [];
            stepArray.forEach((subStep, stepIndex) => {
                stepStats = this.props.stepStats.find(s => s.StepGuid == subStep.guid);
                stepStatsQueued = stepStats?.Queued || 0;
                stepStatsProcessed = stepStats?.Processed || 0;

                let initialStepStatsHolderProps = { ...stepStatsHolderProps };
                initialStepStatsHolderProps.step = subStep;

                statsQueuedHolder = (
                    <StepStatsHolder type="queued" {...initialStepStatsHolderProps}>
                        {stepStatsQueued}
                    </StepStatsHolder>
                );
                statsProcessedHolder = (
                    <StepStatsHolder type="processed" {...initialStepStatsHolderProps}>
                        {stepStatsProcessed}
                    </StepStatsHolder>
                );

                var outputText = "";
                if (subStep.triggerType.value == "0") {
                    var groupName = "";
                    var foundGroup = this.props.groupsData.find(gp => gp.value == subStep.stepOptions.groupId.value);
                    if (foundGroup) {
                        groupName = textFunctions.checkForHtml(foundGroup.label);
                    }
                    let subscriberStateValue = subStep.stepOptions.subscriberState && subStep.stepOptions.subscriberState.value ? textFunctions.checkForHtml(subStep.stepOptions.subscriberState.value) : '';

                    if (subStep.stepOptions.subscriberState) {
                        outputText = <span dangerouslySetInnerHTML={{
                            __html: i18n.t('automation:add.initial.render0WithState', {
                                groupName,
                                subscriberState: subscriberStateValue
                            })
                        }}></span>;
                    } else {
                        outputText = <span dangerouslySetInnerHTML={{
                            __html: i18n.t('automation:add.initial.render0WithoutState', {
                                groupName
                            })
                        }}></span>;
                    }
                }
                if (subStep.triggerType.value == "1") {
                    var groupName = "";
                    var segmentName = "";
                    var foundGroup = this.props.groupsData.find(gp => gp.value == subStep.stepOptions.groupId.value);
                    if (foundGroup) {
                        groupName = textFunctions.checkForHtml(foundGroup.label);
                    }
                    if (subStep.stepOptions.groupId.value == "-1000") groupName = "Any Group"
                    var foundSegment = this.props.segmentsData.find(sg => sg.value == subStep.stepOptions.segmentId.value);
                    if (foundSegment) {
                        segmentName = textFunctions.checkForHtml(foundSegment.label);
                    }
                    outputText = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('automation:add.initial.render1', {
                            groupName,
                            segmentName
                        })
                    }} ></span>;
                }
                if (subStep.triggerType.value == "2") {
                    var groupName = "";
                    var segmentName = "";
                    var foundGroup = this.props.groupsData.find(gp => gp.value == subStep.stepOptions.groupId.value);
                    if (foundGroup) {
                        groupName = textFunctions.checkForHtml(foundGroup.label);
                    }
                    if (subStep.stepOptions.groupId.value == "-1000") groupName = "Any Group"
                    var foundSegment = this.props.segmentsData.find(sg => sg.value == subStep.stepOptions.segmentId.value);
                    if (foundSegment) {
                        segmentName = textFunctions.checkForHtml(foundSegment.label);
                    }
                    outputText = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('automation:add.initial.render2', {
                            groupName,
                            segmentName
                        })
                    }} ></span>;
                }
                if (subStep.triggerType.value == "3") {
                    var groupName = "";
                    var customFieldName = "";
                    var foundGroup = this.props.groupsData.find(gp => gp.value == subStep.stepOptions.groupId.value);
                    if (foundGroup) groupName = textFunctions.checkForHtml(foundGroup.label);
                    var foundCustomField = this.props.customFieldsData.find(cf => cf.value == subStep.stepOptions.customFieldId.value);
                    if (foundCustomField) customFieldName = textFunctions.checkForHtml(foundCustomField.label);

                    if (subStep.stepOptions.onBeforeDate.value == "on") {
                        outputText = <span dangerouslySetInnerHTML={{
                            __html: i18n.t('automation:add.initial.render3On', {
                                groupName,
                                customFieldName
                            })
                        }} ></span>;
                    }
                    if (subStep.stepOptions.onBeforeDate.value == "before") {
                        outputText = <span dangerouslySetInnerHTML={{
                            __html: i18n.t('automation:add.initial.render3Before', {
                                groupName, customFieldName,
                                number: textFunctions.checkForHtml(subStep.stepOptions.onBeforeDateValue.value),
                                unit: textFunctions.checkForHtml(subStep.stepOptions.onBeforeDateUnits.value)
                            })
                        }} ></span>;
                    }
                    if (subStep.stepOptions.onBeforeDate.value == "after") {
                        outputText = <span dangerouslySetInnerHTML={{
                            __html: i18n.t('automation:add.initial.render3After', {
                                groupName, customFieldName,
                                number: textFunctions.checkForHtml(subStep.stepOptions.onBeforeDateValue.value),
                                unit: textFunctions.checkForHtml(subStep.stepOptions.onBeforeDateUnits.value)
                            })
                        }} ></span>;
                    }
                }
                if (subStep.triggerType.value == "4") {
                    var groupName = "";
                    var customFieldName = "";
                    var foundGroup = this.props.groupsData.find(gp => gp.value == subStep.stepOptions.groupId.value);
                    if (foundGroup) groupName = textFunctions.checkForHtml(foundGroup.label);
                    var foundCustomField = this.props.customFieldsData.find(cf => cf.value == subStep.stepOptions.customFieldId.value);
                    if (foundCustomField) customFieldName = textFunctions.checkForHtml(foundCustomField.label);

                    if (subStep.stepOptions.onBeforeDate.value == "on") {
                        outputText = <span dangerouslySetInnerHTML={{
                            __html: i18n.t('automation:add.initial.render4On', {
                                groupName,
                                customFieldName
                            })
                        }} ></span>;
                    }
                    if (subStep.stepOptions.onBeforeDate.value == "before") {
                        outputText = <span dangerouslySetInnerHTML={{
                            __html: i18n.t('automation:add.initial.render4Before', {
                                groupName,
                                customFieldName,
                                number: textFunctions.checkForHtml(subStep.stepOptions.onBeforeDateValue.value),
                                unit: textFunctions.checkForHtml(subStep.stepOptions.onBeforeDateUnits.value)
                            })
                        }} ></span>;
                    }
                    if (subStep.stepOptions.onBeforeDate.value == "after") {
                        outputText = <span dangerouslySetInnerHTML={{
                            __html: i18n.t('automation:add.initial.render4After', {
                                customFieldName,
                                groupName,
                                customFieldName,
                                number: textFunctions.checkForHtml(subStep.stepOptions.onBeforeDateValue.value),
                                unit: textFunctions.checkForHtml(subStep.stepOptions.onBeforeDateUnits.value)
                            })
                        }} ></span>;
                    }
                }
                if (subStep.triggerType.value == "5") {
                    outputText = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('automation:add.initial.render5', {
                            link: textFunctions.checkForHtml(subStep.stepOptions.link.value)
                        })
                    }} ></span>
                }
                if (subStep.triggerType.value == "6") {
                    var theEmail = this.props.campaignsData.find(cp => cp.value == subStep.stepOptions.campaignId.value)
                    var theEmailSubject = "";
                    var theReplyAddress = "";
                    var mailbox
                    if (theEmail && theEmail.label) {
                        theEmailSubject = theEmail.label;
                        theReplyAddress = theEmail.replyAddress
                    }

                    if (theReplyAddress) {
                        mailbox = this.props.mailboxes.find(mb => mb.emailAddress.toLowerCase() === theReplyAddress.toLowerCase())
                    }

                    if (step.stepOptions.campaignId.value == "-1") {
                        outputText = <div><span dangerouslySetInnerHTML={{
                            __html: i18n.t('automation:add.initial.render6Any')
                        }} ></span>
                            {!this.props.mailboxes.length && <div className="text-red mar-t10 text-heavy">No mailboxes not being monitored <UILIB.Button onClick={(e) => this.goTo(e, '/cp/company/mailboxes')} text="Setup" className="mar-l10 red outline" /></div>}
                            {!!this.props.mailboxes.length && <div className="mar-t10">This will trigger for campaign replies to: {this.props.mailboxes.filter(mb => mb.active).map(mb => mb.emailAddress).join(', ')}</div>}
                        </div>
                    } else {
                        outputText = <div><span dangerouslySetInnerHTML={{
                            __html: i18n.t('automation:add.initial.render6', {
                                subject: textFunctions.checkForHtml(theEmailSubject)
                            })
                        }} ></span>
                            {!!theReplyAddress && (!mailbox || !mailbox.active) && <div className="text-red mar-t10 text-heavy">{theReplyAddress} not being monitored <UILIB.Button onClick={(e) => this.goTo(e, '/cp/company/mailboxes')} text="Setup" className="mar-l10 red outline" /></div>}
                        </div>
                    }
                }
                if (subStep.triggerType.value == "8") {

                    var theEmail = this.props.campaignsData.find(cp => cp.value == subStep.stepOptions.campaignId.value)
                    var theEmailSubject = "";
                    if (theEmail && theEmail.label) theEmailSubject = theEmail.label;

                    outputText = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('automation:add.initial.render8', {
                            subject: textFunctions.checkForHtml(theEmailSubject)
                        })
                    }} ></span>
                }
                if (subStep.triggerType.value == "9") {

                    var theEmail = this.props.campaignsData.find(cp => cp.value == subStep.stepOptions.campaignId.value)
                    var theEmailSubject = "";
                    if (theEmail && theEmail.label) theEmailSubject = theEmail.label;

                    outputText = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('automation:add.initial.render9', {
                            subject: textFunctions.checkForHtml(theEmailSubject)
                        })
                    }} ></span>
                }
                if (subStep.triggerType.value == "7") {
                    let replacementText = subStep.stepOptions.url.value;
                    if (replacementText == "") replacementText = "Any Page";
                    outputText = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('automation:add.initial.render7', {
                            url: textFunctions.checkForHtml(replacementText)
                        })
                    }} ></span>
                }
                if (subStep.triggerType.value == "20") {
                    console.log(subStep)
                    let foundEvent = this.props.siteTrackerEvents.find(f => f.value == subStep.stepOptions.eventId.value);
                    let eventName = "";
                    if (foundEvent) eventName = foundEvent.label;
                    outputText = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('automation:add.initial.render20', {
                            eventName: eventName
                        })
                    }} ></span>
                }
                if (subStep.triggerType.value == "12") {
                    outputText = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('automation:add.initial.campaignBounce')
                    }} ></span>
                }
                if (subStep.triggerType.value == "10") {
                    outputText = <span>{i18n.t('automation:add.initial.render10')}</span>;
                }
                if (subStep.triggerType.value == "11") {
                    outputText = <span>{i18n.t('automation:add.initial.render11')}</span>;
                }
                if (subStep.triggerType.value == "13") {
                    if (subStep.stepOptions.groupId && subStep.stepOptions.groupId.value) {
                        let group = this.props.groupsData.find(g => g.value == subStep.stepOptions.groupId.value)
                        outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.initial.render13Group', { groupName: group ? textFunctions.checkForHtml(group.label) : '' }) }}></span>;
                    } else {
                        outputText = <span dangerouslySetInnerHTML={{ __html: i18n.t('automation:add.initial.render13') }}></span>;
                    }
                }
                if (subStep.triggerType.value == "14") {
                    let automation

                    outputText = <span>{i18n.t('automation:add.initial.render14')}</span>;


                }
                if (subStep.triggerType.value == "15") {
                    var theEmail = this.props.campaignsData.find(cp => cp.value == subStep.stepOptions.campaignId.value)
                    var theEmailSubject = "";
                    if (theEmail && theEmail.label) theEmailSubject = theEmail.label;

                    outputText = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('automation:add.initial.render15', {
                            subject: textFunctions.checkForHtml(theEmailSubject)
                        })
                    }} ></span>
                }
                if (subStep.triggerType.value == "16") {
                    outputText = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('automation:add.initial.render16')
                    }} ></span>
                }
                if (subStep.triggerType.value == "17") {
                    if (subStep.stepOptions.formId && subStep.stepOptions.formId.value && subStep.stepOptions.formId.value === '-1') {
                        outputText = <span dangerouslySetInnerHTML={{
                            __html: i18n.t('automation:add.initial.render17Any')
                        }} ></span>
                    } else {
                        let form = this.props.forms.find(f => f.id == subStep.stepOptions.formId.value)
                        outputText = <span dangerouslySetInnerHTML={{
                            __html: i18n.t('automation:add.initial.render17', { formName: form ? form.formName : 'FORM DELETED' })
                        }} ></span>
                    }
                }
                if (this.props.step.triggerType.value == "18") {
                    const tag = this.props.linktags.find(t => t.id === this.props.step.stepOptions.tagId.value)
                    outputText = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('automation:add.initial.render18', { tag: tag ? tag.tagName : 'NOT FOUND' })
                    }} ></span>
                }
                if (this.props.step.triggerType.value == "19") {
                    const form = this.props.forms.find(t => t.id === this.props.step.stepOptions.formId.value)
                    const name = this.props.step.stepOptions.formId.value === '-1' ? 'Any Form' : form?.formName || 'NOT FOUND'
                    outputText = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('automation:add.initial.render19', { form: name })
                    }} ></span>
                }
                if (!parseInt(subStep.triggerType.value)) {
                    let app = this.props.applications.find(a => a.Application.appName === subStep.triggerType.value)
                    if (this.props.isThemeDesigner && this.props.Application) {
                        app = { ApplicationId: this.props.Application.id, Application: this.props.Application }
                    }
                    if (app) {
                        let trigger = app.Application.options.triggers.find(t => t.value === subStep.stepOptions.trigger.value)
                        if (!trigger) {
                            subStep.complete = false
                        }
                        outputText = <div>
                            <img src={app.Application.appLogoUri} height="40" alt="" />
                            <div className="text-med text-heavy mar-b10">{app.Application.appName}</div>
                            <div>{trigger ? trigger.label : 'Select Trigger'}</div>
                        </div>
                    }
                }
                let timerVal = ''
                if (this.props.options && this.props.options.batchDelay && this.props.options.batchSize && this.props.options.batchUnit) {
                    timerVal = <div style={{ position: 'absolute', top: 5, right: 8, cursor: 'pointer', fontSize: 18 }}>
                        <span className="icon-timer"></span>
                    </div>
                }
                outerStyle.flex = "1";
                outerStyle.display = "flex";
                outerStyle.alignItems = "center";
                outerStyle.maxWidth = "240px";
                outerStyle.marginLeft = "0px";
                outerStyle.marginRight = "0px";


                let isLast = stepIndex == stepArray.length - 1;
                let isFirst = stepIndex == 0;
                if (stepArray.length > 1) {
                    if (!isLast) outerStyle.marginRight = "15px";
                    if (!isFirst) outerStyle.marginLeft = "15px";
                }

                let posClass = " ";
                if (stepArray.length > 1) {
                    posClass = "linesBelowFull "
                    if (isFirst) posClass = "linesBelowLeft "
                    if (isLast) posClass = "linesBelowRight "
                }
                let parentStep = this.props.stepsData;
                let dataId = this.props.dataId;
                if (stepIndex > 0) {
                    parentStep = this.props.step.triggerSteps;
                    dataId = stepIndex - 1
                }

                let editableStep = subStep;
                if (this.props.choosing) {
                    editableStep = this.props.step;
                }

                let outerStyleC = { ...outerStyle }
                checkActivationErrors(this.props.activateError, subStep, outerStyleC, overrideBorder)

                const automationStatus = this.props.automationStatus

                outPutArray.push(<div key={stepIndex} className={posClass + className} onClick={() => this.props.disabled ? {} : this.props.editStep(editableStep)} style={outerStyleC}>
                    <div>
                        {timerVal}
                        {statsQueuedHolder}
                        {!subStep.complete && <div className="automationToDoHolder">{i18n.t('automation:add.prompts.defineWorkflowTrigger')}</div>}
                        {(!isFirst && !hideDeleteButton) &&
                            <div
                                className="automationRemoveStep"
                                onClick={(event) => { event.stopPropagation(); this.props.removeStep(parentStep, dataId, step.guid) }}
                            >
                                <UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} />
                            </div>}
                        {subStep.complete && <div>{outputText}</div>}
                        {statsProcessedHolder}
                        {this.props.contactOnStep}
                    </div>
                    {(!isLast) && <div style={{ position: "absolute", right: "-23px", top: "calc(50% - 10px)", zIndex: "9999" }}>or</div>}
                    {(!this.props.disabled && isLast && automationStatus !== 'Running' && automationStatus !== 'Starting' && automationStatus !== 'Archived' && automationStatus !== 'Errored' && automationStatus !== 'Testing')
                        && <div style={{ position: "absolute", top: "calc(50% - 10px)", right: "-40px" }} className="automationAddStepButton mar-l15" >
                            <UILIB.Icons icon="plus" style={{ display: 'flex' }} onClick={(ev) => this.props.addTrigger(ev)} />
                        </div>}
                </div >)

            })
            return <div id={this.props.id} style={{ display: "flex", justifyContent: "center", marginBottom: stepArray.length > 1 ? "30px" : "" }}>{outPutArray}</div>
        }

        if (step.type == 'pipedrive') {
            return <Pipedrive id={this.props.id} pipedrive={this.props.applications.find(a => a.Application.appName === 'Pipedrive')} style={overrideBorder ? outerStyle : undefined} step={this.props.step} steps={this.props.allSteps} disabled={this.props.disabled} editStep={this.props.disabled ? () => { } : this.props.editStep} statsQueuedHolder={statsQueuedHolder} statsProcessedHolder={statsProcessedHolder} isRunning={isRunning} removeStep={() => {
                this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid)
            }} />
        }

        if (parseInt(step.type)) {
            let accApp = this.props.applications.find(a => a.ApplicationId === step.type)
            if (this.props.isThemeDesigner && this.props.Application) {
                accApp = { ApplicationId: this.props.Application.id, Application: this.props.Application }
            }
            return <GenericOption
                id={this.props.id}
                accApp={accApp}
                step={step}
                editStep={this.props.disabled ? () => { } : this.props.editStep}
                steps={this.props.allSteps}
                statsQueuedHolder={statsQueuedHolder}
                statsProcessedHolder={statsProcessedHolder}
                contactOnStep={this.props.contactOnStep}
                isRunning={isRunning}
                removeStep={() => {
                    this.props.removeStep(this.props.stepsData, this.props.dataId, step.guid)
                }}
                isThemeDesigner={this.props.isThemeDesigner}
            />
        }

        return <div id={this.props.id} ></div>
    }
}

export default AutomationOption;
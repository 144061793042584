// import ReactGA from 'react-ga4';
import thestore from '../data/store/store'
class GAFunctions {

    static trackGAPageView(pathname) {
        try {
            let ourState = thestore.getState();
            if (ourState && ourState.siteMaster && ourState.siteMaster.googleAnalyticsKey) {
                // ReactGA.set({ page: pathname });
                // ReactGA.pageview(location.pathname);
            }
        }
        catch (e) { }
    }

    static trackGAEvent(eventType, accountId, accountUserId, emailAddress) {
        try {
            var rnd = accountUserId + "_" + accountId;
            let ourState = thestore.getState();
            if (ourState && ourState.siteMaster && ourState.siteMaster.googleAnalyticsKey) {

                // ReactGA.send({
                //     hitType: "event",
                //     event: eventType,
                //     accountId: rnd,
                //     email: emailAddress
                // });
            }
        }
        catch (e) { }
    }

    static tagManagerEvent(eventType, accountId, accountUserId, email) {
        try {
            console.log("FIRE TAG EVENT START", eventType)
            var rnd = accountUserId + "_" + accountId;
            let ourState = thestore.getState();
            if (ourState && ourState.siteMaster && ourState.siteMaster.googleAnalyticsKey) {
                if (window && window.dataLayer) {
                    console.log("FIRE TAG EVENT END")
                    window.dataLayer.push({
                        event: eventType,
                        accountId: rnd,
                        email
                    });
                }

            }
        }
        catch (err) {
            console.log(err)
        }
    }
}
export default GAFunctions;
import React from 'react'

export default class IconsReply extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";
        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.70703 3.29303C7.8945 3.48056 7.99982 3.73487 7.99982 4.00003C7.99982 4.26519 7.8945 4.5195 7.70703 4.70703L5.41403 7.00003H11C12.8565 7.00003 14.637 7.73753 15.9498 9.05028C17.2625 10.363 18 12.1435 18 14V16C18 16.2652 17.8947 16.5196 17.7071 16.7071C17.5196 16.8947 17.2652 17 17 17C16.7348 17 16.4805 16.8947 16.2929 16.7071C16.1054 16.5196 16 16.2652 16 16V14C16 12.6739 15.4732 11.4022 14.5356 10.4645C13.5979 9.52681 12.3261 9.00003 11 9.00003H5.41403L7.70703 11.293C7.80254 11.3853 7.87872 11.4956 7.93113 11.6176C7.98354 11.7396 8.01113 11.8709 8.01228 12.0036C8.01344 12.1364 7.98813 12.2681 7.93785 12.391C7.88757 12.5139 7.81332 12.6255 7.71943 12.7194C7.62553 12.8133 7.51388 12.8876 7.39098 12.9379C7.26809 12.9881 7.13641 13.0134 7.00363 13.0123C6.87085 13.0111 6.73963 12.9835 6.61763 12.9311C6.49562 12.8787 6.38528 12.8025 6.29303 12.707L2.29303 8.70703C2.10556 8.5195 2.00024 8.26519 2.00024 8.00003C2.00024 7.73487 2.10556 7.48056 2.29303 7.29303L6.29303 3.29303C6.48056 3.10556 6.73487 3.00024 7.00003 3.00024C7.26519 3.00024 7.5195 3.10556 7.70703 3.29303V3.29303Z" fill={color}/>
        </svg>

    }
}        
import React, { useState } from "react";
import i18n from "i18next";
import UILIB from "~/Common-Objects/Lib";

export default function AddContentButton({ onSelect }) {
  const [isAddOpen, setIsAddOpen] = useState(false);

  function handleSelect(type) {
    onSelect(type);
    setIsAddOpen(false);
  }

  return (
    <div>
      <UILIB.Button
        className="button-primary"
        iconLeft={<UILIB.Icons icon="plus" />}
        onClick={() => setIsAddOpen((prev) => !prev)}
      >
        {i18n.t("form:gated.empty.cta")}
      </UILIB.Button>

      <UILIB.DropDown
        className="select-gated-content__add-dropdown"
        isOpen={isAddOpen}
        onClose={() => setIsAddOpen(false)}
      >
        <ul>
          <li>
            <button onClick={() => handleSelect("file")}>
              <UILIB.Icons icon="document" color="#A9A9AD" />{" "}
              {i18n.t("form:gated.add.file")}
            </button>
          </li>
          <li>
            <button onClick={() => handleSelect("link")}>
              <UILIB.Icons icon="link" color="#A9A9AD" />{" "}
              {i18n.t("form:gated.add.link")}
            </button>
          </li>
        </ul>
      </UILIB.DropDown>
    </div>
  );
}

import React from 'react'

export default class IconsAvatars3 extends React.Component {

    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 6.00006C13 6.79571 12.6839 7.55877 12.1213 8.12138C11.5587 8.68399 10.7956 9.00006 10 9.00006C9.20435 9.00006 8.44129 8.68399 7.87868 8.12138C7.31607 7.55877 7 6.79571 7 6.00006C7 5.20441 7.31607 4.44135 7.87868 3.87874C8.44129 3.31613 9.20435 3.00006 10 3.00006C10.7956 3.00006 11.5587 3.31613 12.1213 3.87874C12.6839 4.44135 13 5.20441 13 6.00006ZM18 8.00006C18 8.53049 17.7893 9.0392 17.4142 9.41427C17.0391 9.78935 16.5304 10.0001 16 10.0001C15.4696 10.0001 14.9609 9.78935 14.5858 9.41427C14.2107 9.0392 14 8.53049 14 8.00006C14 7.46963 14.2107 6.96092 14.5858 6.58585C14.9609 6.21077 15.4696 6.00006 16 6.00006C16.5304 6.00006 17.0391 6.21077 17.4142 6.58585C17.7893 6.96092 18 7.46963 18 8.00006ZM14 15.0001C14 13.9392 13.5786 12.9218 12.8284 12.1716C12.0783 11.4215 11.0609 11.0001 10 11.0001C8.93913 11.0001 7.92172 11.4215 7.17157 12.1716C6.42143 12.9218 6 13.9392 6 15.0001V18.0001H14V15.0001ZM6 8.00006C6 8.53049 5.78929 9.0392 5.41421 9.41427C5.03914 9.78935 4.53043 10.0001 4 10.0001C3.46957 10.0001 2.96086 9.78935 2.58579 9.41427C2.21071 9.0392 2 8.53049 2 8.00006C2 7.46963 2.21071 6.96092 2.58579 6.58585C2.96086 6.21077 3.46957 6.00006 4 6.00006C4.53043 6.00006 5.03914 6.21077 5.41421 6.58585C5.78929 6.96092 6 7.46963 6 8.00006ZM16 18.0001V15.0001C16.0014 13.9833 15.7433 12.9831 15.25 12.0941C15.6933 11.9806 16.1568 11.9699 16.6049 12.0628C17.053 12.1557 17.474 12.3497 17.8357 12.63C18.1974 12.9103 18.4903 13.2695 18.6921 13.6803C18.8939 14.091 18.9992 14.5424 19 15.0001V18.0001H16ZM4.75 12.0941C4.25675 12.9831 3.9986 13.9833 4 15.0001V18.0001H1V15.0001C0.999808 14.5421 1.10446 14.0902 1.30595 13.6789C1.50743 13.2677 1.80039 12.908 2.16238 12.6275C2.52437 12.347 2.94578 12.153 3.39431 12.0606C3.84284 11.9681 4.30658 11.9796 4.75 12.0941Z" fill={color} />
        </svg>





    }
}        
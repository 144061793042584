import React from 'react';
import { connect } from 'react-redux';

@connect((store) => {
	return { siteMaster: store.siteMaster }
})

export default class CircularProgress extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			percentage: 0,
			counter: 0
		};

		this.startCount = this.startCount.bind(this);
	}

	componentDidUpdate(prevProps) {
		var update = false;
		var obj = {};
		if ((prevProps.total !== this.props.total) || (prevProps.amount !== this.props.amount)) {
			var percentage = Math.ceil((100 / this.props.total) * this.props.amount);

			if (percentage > 100) percentage = 100;
			if (percentage < 0) percentage = 0;

			update = true;
			obj.percentage = percentage;
		}

		if (update) {
			this.setState(obj, this.startCount)
		}
	}

	componentDidMount() {
		if (this.props.amount || this.props.total) {
			setTimeout(this.startCount, 100); //add a wait here to show the progress bar moving rather than just rendering at the correct position
		}
	}

	startCount() {
		if (this.state.counter !== this.props.amount) {
			var percentage = Math.ceil((100 / this.props.total) * this.props.amount);
			if (!percentage) percentage = 0;
			var count = this.state.counter;
			var difference = this.props.amount - this.state.counter;
			var increaseAmount = Math.ceil(difference / 20);

			count += increaseAmount;

			if (count > this.props.amount) count = this.props.amount;
			this.setState({
				percentage,
				counter: count
			}, () => {
				setTimeout(this.startCount, 15)
			})
		}
	}

	render() {
		var strokeWidth = this.props.strokeWidth || this.props.radius / 10;
		const radius = this.props.radius - strokeWidth / 2;
		const width = this.props.radius * 2;
		const height = this.props.radius * 2;
		const viewBox = `0 0 ${width} ${height}`;
		const dashArray = radius * Math.PI * 2;
		var dashOffset = dashArray - dashArray * this.state.percentage / 100;
		var colour = this.props.colour || 'positive';
		var textClasses = 'result'
		var svgClasses = "";

		if (this.props.threshold) {
			if (colour == 'negative') {
				if (this.props.threshold > this.state.percentage) {
					colour = 'positive';
				}
			} else {
				if (this.props.threshold > this.state.percentage) {
					colour = 'negative';
				}
			}
		}

		if (colour == 'negative') textClasses += ' secondary'

		if (this.props.onClick) {
			svgClasses += " circularHover"
		}

		var sizeChange = this.props.radius / 100;

		return (
			<svg
				onClick={this.props.onClick}
				className="CircularProgress"
				width={this.props.radius * 2}
				height={this.props.radius * 2}
				viewBox={viewBox}
				className={svgClasses}>
				<linearGradient id="positive">
					<stop offset="50%" stopColor={this.props.siteMaster.colours.$primary} stopOpacity="1" />
					<stop offset="100%" stopColor={this.props.siteMaster.colours.$secondary} stopOpacity="1" />
				</linearGradient>
				<linearGradient id="negative">
					<stop offset="50%" stopColor={this.props.siteMaster.colours.$warning} stopOpacity="1" />
					<stop offset="100%" stopColor={this.props.siteMaster.colours.$secondary} stopOpacity="1" />
				</linearGradient>
				<circle
					className="CircularProgress-Fg"
					cx={-Math.abs(this.props.radius)}
					cy={this.props.radius}
					r={radius}
					transform="rotate(270)"
					stroke={"url(#" + colour + ")"}
					strokeLinecap="round"
					strokeWidth={`${strokeWidth}px`}
					style={{
						strokeDasharray: dashArray,
						strokeDashoffset: dashOffset,
					}} />
				<text className="title" x={this.props.radius / 2} y={(this.props.radius / 2) + 10}>
					{this.props.title}
				</text>
				<text className={textClasses} style={{ fontSize: (44 * sizeChange) + 'px' }} x={this.props.radius / 2} y={this.props.radius + (this.props.title ? 8 : -2)}>
					{this.state.counter}
				</text>
				<text className="total" style={{ fontSize: (14 * (sizeChange < 1 ? 0.75 : sizeChange)) + 'px' }} x={this.props.radius / 2} y={this.props.radius + ((this.props.title ? 40 : 30) * sizeChange)}>
					/ {this.props.total} ({this.state.percentage}%)
				</text>
			</svg >
		);
	}
}

CircularProgress.defaultProps = {
	radius: 100
};

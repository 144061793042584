import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user }
})

export default class buyDomain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            domainName: "",
            domainChecked: false,
            domainAvailable: false,
            checkingDomain: false,
            domainResult: {},
            errors: {
                general: ""
            }
        };

        this.updateInput = this.updateInput.bind(this);
        this.checkDomain = this.checkDomain.bind(this);
        this.swapDomain = this.swapDomain.bind(this);
    }

    componentDidMount() { }

    updateInput(ev) {
        this.setState({ [ev.currentTarget.name]: ev.currentTarget.value, domainChecked: false })
    }

    swapDomain() {
        this.setState({ domainAvailable: false, domainChecked: false, checkingDomain: false })
    }
    checkDomain() {
        var errors = this.state.errors;
        errors.general = "";
        this.setState({ errors: errors, domainChecked: false, checkingDomain: true })
        axios.post('/accountMaster/domain/create/check', { domainName: this.state.domainName })
            .then(response => {

                if (!response.data.available) errors.general = "This domain is already taken";
                if (!response.data.success && response.data.error) errors.general = response.data.error;

                this.setState({ domainChecked: true, domainAvailable: response.data.available, errors, checkingDomain: false, domainResult: response.data })
            })
            .catch(err => {
                var errors = this.state.errors;
                errors.general = "Domain not Available";
                if (err.data) {
                    errors.general = err.data.error ? err.data.error : "Domain not available"
                }

                this.setState({ errors, checkingDomain: false })
            })
    }

    render() {
        return <div>
            <h4 className="mar-b25">Buy a New Domain</h4>
            <div className="mar-b10 text-heavy">If you would like to send email campaigns from a brand new domain, you can purchase a new domain and have it automatically setup ready for sending.</div>
            <div className="mar-b10">No DNS Setup Required!</div>
            <div className="mar-b25">To get started, please enter a domain name, and we will check wether it is available..</div>


            {!this.state.domainAvailable && <div>
                <UILIB.TextInput outerClassName="mar-b25" label="Enter a Domain Name..." name="domainName" onChange={this.updateInput} error={this.state.errors.general} value={this.state.domainName} />

                {this.state.checkingDomain && <UILIB.LoadingIcons iconType="2" />}
                {!this.state.checkingDomain && <UILIB.Button text="Check Domain" onClick={this.checkDomain} className={"button-primary"} />}

            </div>
            }

            {this.state.domainAvailable && <div>
                <UILIB.Paper className="paper-primary">
                    <h4 className="mar-b25">Domain Available</h4>
                    <div>Good news, the domain {this.state.domainName} is available to purchase, pre-configured for email sending.</div>
                </UILIB.Paper>

                <UILIB.Button text="Continue" className="mar-r5" onClick={() => { this.props.continue(this.state.domainName, this.state.domainResult) }} />
                or <a onClick={this.swapDomain}>try another domain</a>
            </div>}

        </div>
    }
};



import React from 'react'

export default function IconBookmark({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";

    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.5 15.5C2.5 16.6046 3.39543 17.5 4.5 17.5H15.5C16.6046 17.5 17.5 16.6046 17.5 15.5V7.07843C17.5 6.54799 17.2893 6.03929 16.9142 5.66421L14.3358 3.08579C13.9607 2.71071 13.452 2.5 12.9216 2.5H4.5C3.39543 2.5 2.5 3.39543 2.5 4.5V15.5ZM12.5 13C12.5 14.3807 11.3807 15.5 10 15.5C8.61929 15.5 7.5 14.3807 7.5 13C7.5 11.6193 8.61929 10.5 10 10.5C11.3807 10.5 12.5 11.6193 12.5 13ZM5.5 4.5C4.94772 4.5 4.5 4.94772 4.5 5.5V6.5C4.5 7.05228 4.94772 7.5 5.5 7.5H11.5C12.0523 7.5 12.5 7.05228 12.5 6.5V5.5C12.5 4.94772 12.0523 4.5 11.5 4.5H5.5Z" fill={fillColor} />
        </svg>
    )
}
import React, { useState, useEffect } from 'react'
import axios from '../../../data/http/axios'
import UILIB from '../../../Common-Objects/Lib'

export default function BulkChangeStatus({ onFinish = () => { } }) {
    const [status, setStatus] = useState('')
    const [segments, setSegments] = useState(null)
    const [segment, setSegment] = useState(null)
    const [options, setOptions] = useState({ unblacklist: false, unbounce: false })

    const start = async () => {
        await axios.put('/subscriber/bulk/changestatus', {
            segmentId: segment,
            status: status,
            ...options
        })
        onFinish()
    }

    useEffect(() => {
        axios.get('/segment?notsystem=true&recordLimit=1000').then(res => setSegments(res.data.Segments.filter(s => !s.readOnly)))
    }, [])

    const statuses = [
        { label: 'Active', value: "Active" },
        { label: 'In-Active', value: "In-Active" },
        { label: 'Unsubscribed', value: "Unsubscribed" },
        { label: 'Denylisted', value: "Blacklisted" },
        { label: 'Bounced', value: "Bounced" }
    ]

    if (!segments) return <UILIB.LoadingIcons />

    return (
        <div>
            <h4 className='mar-b25'>Change Subscriber Status</h4>
            <div className='mar-b20'>Change all the status of all contacts that match a segment</div>
            <UILIB.Select outerClassName="mar-b20" label="Segment" placeholder="Select Segment" value={segment} data={segments.map(s => ({ label: s.name, value: s.id }))} onChange={e => setSegment(e.currentTarget.value)} />
            <UILIB.Select outerClassName="mar-b20" label="Status to change to" placeholder="Select Status" value={status} data={statuses} onChange={e => setStatus(e.currentTarget.value)} />

            {!!segment && !!status && <div className='mar-b20'>
                Changing status will update all contacts that match this segment, this is done in the background and we will notify you when complete
                {(status === 'In-Active' || status === 'Unsubscribed') && <div className='mar-t10'>Making contacts Active, In-Active or Unsubscribed will update their status in all their groups</div>}
                {(status === 'Active' || status === 'In-Active' || status === 'Unsubscribed') && <div className='mar-t10'>
                    <UILIB.CheckBox outerClassName="mar-b10" checked={options.unblacklist} onChange={() => setOptions({ ...options, unblacklist: !options.unblacklist })}>Remove from Denylist</UILIB.CheckBox>
                    <UILIB.CheckBox checked={options.unbounce} onChange={() => setOptions({ ...options, unbounce: !options.unbounce })}>Remove from Bounce List</UILIB.CheckBox>
                </div>}
                {/* {status === 'Active' && <div className='mar-t10'>Making contacts Active will only make them active for sending if their status was In-Active rather than Denylisted, Bounced or Unsubscribed</div>} */}
            </div>}

            <UILIB.Button disabled={!segment || !status} classNam="button-primary" onClick={start}>Change Status</UILIB.Button>
        </div>
    )
}
import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '../../../../data/http/axios';

class Suggestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            details: '',
            msg: ''
        }

        this.update = this.update.bind(this)
        this.send = this.send.bind(this)
    }

    update(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    send() {
        axios.post('/suggestion', { type: this.state.type, details: this.state.details }).then(() => {
            this.setState({
                msg: 'we have received your suggestion and will contact you shortly'
            }, () => {
                setTimeout(this.props.finish, 2000)
            })
        })
    }

    render() {
        var types = ['Integration', 'Feature', 'Other']

        return (
            <div>
                <h4 className="mar-b25">Got a Suggestion?</h4>

                <UILIB.Select outerClassName="mar-b25" label="Category" data={types} placeholder="Select Category" name="type" value={this.state.type} onChange={this.update}></UILIB.Select>

                <UILIB.TextArea label="Request" outerClassName="mar-b25" rows="5" placeholder="enter some details" name="details" value={this.state.details} onChange={this.update}></UILIB.TextArea>
                <UILIB.Button className="button-primary" disabled={!this.state.type || !this.state.details} onClick={this.send}>Send</UILIB.Button>
                {!!this.state.msg && <p className="text-sml mar-t10">{this.state.msg}</p>}
            </div >
        );
    }
}

export default Suggestion;
import React, { Component } from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import AddCampaignDrawer from '~/pages/cp/includes/campaignInADrawer'
import DeleteTestDrawer from './delete'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import { connect } from 'react-redux';
import TemplateImageLoader from '~/pages/cp/automation/addNew/templateImageLoader.jsx'
import SiteVars from '~/Classes/siteVars';
import i18n from '~/i18n'
@connect((store) => {
    return { user: store.user, permissionStore: store.permission, accountMaster: store.accountMaster }
})
class AbTests extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

        this.addTest = this.addTest.bind(this)
        this.deleteCampaign = this.deleteCampaign.bind(this)
        this.save = this.save.bind(this)
        this.duplicateCampaign = this.duplicateCampaign.bind(this)
    }

    addTest(campaign) {
        // show drawer to add campaign (same as automations)
        if (!campaign) {
            // defaults
            campaign = {
                AccountMasterId: this.props.campaign.AccountMasterId,
                type: 10,
                parentCampaignId: this.props.campaign.id,
                campaignName: 'Test - ' + (this.props.campaigns.length + 1)
            }
        }
        var drawerContent = <AddCampaignDrawer history={this.props.history} campaignId={this.props.campaign.id} campaign={campaign} onUpdate={this.props.updateCampaign} onCreate={this.props.addCampaign} redirect={this.props.location.pathname} nameLabel="Name" />;
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px", undefined));
    }

    deleteCampaign(id) {
        var drawerContent = <DeleteTestDrawer deleteCampaign={() => {
            this.props.deleteCampaign(id);
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", undefined, true, "500px", undefined));
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawerContent, true, "500px", undefined));
    }
    duplicateCampaign(dupeCampaign) {

        var self = this;
        axios.post('/campaign/copy', {
            id: dupeCampaign.id,
            name: 'Test - ' + (this.props.campaigns.length + 1)
        }).then(res => {
            self.props.getChildren();
        })
    }
    save() {
        this.props.saveCampaign()
            .then(() => {
                this.props.history.push('/cp/campaigns')
            })
    }

    render() {
        var noneAvailable = '' // add template text with button
        var canAddMore = true
        if (this.props.subscriberCount <= (this.props.campaigns.length + 2)) canAddMore = false

        if (!this.props.campaigns.length) {
            return <UILIB.Row className="mar-b25">
                <UILIB.Column xs={12} sm={12} className="animate" >
                    <UILIB.Paper>
                        <h4 className="mar-b25">{i18n.t('campaigns:abSplitTest.tests.intro')}</h4>
                        <p>{i18n.t('campaigns:abSplitTest.tests.subText')}</p>
                        <UILIB.Button
                            iconLeft={<UILIB.Icons icon="plus" />}
                            className="button-primary"
                            text={i18n.t('campaigns:abSplitTest.tests.addTest')}
                            onClick={() => this.addTest()} />
                    </UILIB.Paper>
                </UILIB.Column>
            </UILIB.Row>
        }

        var testsInvalid = false;
        if (this.props.campaigns.length < 1) testInvalid = true;
        if (this.props.testsInvalid && this.props.testsInvalid.length) {
            var foundFailed = this.props.testsInvalid.filter(test => test.failed == true)
            if (foundFailed.length > 0) testsInvalid = true;
        }


        return (
            <div>
                <div className="quickFlex" style={{ justifyContent: "space-between", alignContent: "center" }}>
                    <div>
                        {!canAddMore &&
                            <UILIB.WarningHolder className="warning-holder-nopadding quickFlex mar-b25 mar-r15">
                                <UILIB.Icons className="mar-r10" icon="alert" style={{ width: "20px", height: "20px" }} color="red" />
                                <span>
                                    {i18n.t('campaigns:abSplitTest.tests.maxTests')}
                                </span>
                            </UILIB.WarningHolder>
                        }
                    </div>
                    <div className="end-xs mar-b25">
                        <UILIB.Button
                            iconLeft={<UILIB.Icons icon="plus" />}
                            className="button-primary"
                            text={i18n.t('campaigns:abSplitTest.tests.addTest')}
                            onClick={() => this.addTest()}
                            disabled={!canAddMore} />

                    </div>
                </div>
                <UILIB.Paper className="mar-b25">

                    <UILIB.DataTable1
                        noResultsTxt={noneAvailable}
                        tableData={this.props.campaigns.map((campaign, index) => {
                            var errors = {};
                            var hasErrors = false;
                            if (this.props.testsInvalid && this.props.testsInvalid.length) {
                                errors = this.props.testsInvalid.find(t => t.campaignId == campaign.id)
                                if (errors && errors.failed) hasErrors = true;
                            }

                            return {
                                campaignImage: {
                                    headerValue: "",
                                    value: <span>
                                        {campaign.TemplateId && <div className="campaign-thumbnail">
                                            <TemplateImageLoader imageUrl={SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + campaign.TemplateId + ".jpg"} />
                                        </div>}
                                        {!campaign.TemplateId && <div className="campaign-thumbnail" style={{ border: "1px solid red" }}>
                                            <div className="campaign-thumbnail-pencil"><UILIB.Icons icon="pencil" onClick={() => this.addTest(campaign)} /></div>
                                        </div>}

                                    </span>,
                                    orderBy: false,
                                    width: 100
                                },
                                name: {
                                    headerValue: i18n.t('campaigns:abSplitTest.tests.name'),
                                    value: errors && <a onClick={() => this.addTest(campaign)}>{campaign.campaignName || ("Test " + (index + 1))}</a>
                                },
                                subject: {
                                    headerValue: i18n.t('campaigns:abSplitTest.tests.subject'),
                                    value: errors && errors.subject ? <UILIB.WarningHolder className="warning-holder-nopadding quickFlex text-center text-red">
                                        {errors.subject}
                                    </UILIB.WarningHolder> : campaign.subject
                                },
                                fromName: {
                                    headerValue: i18n.t('campaigns:abSplitTest.tests.fromName'),
                                    value: errors && errors.fromName ? <UILIB.WarningHolder className="warning-holder-nopadding quickFlex text-center text-red">
                                        {errors.fromName}
                                    </UILIB.WarningHolder> : campaign.multiAddressRules?.length ? 'From Multi Sender Rules' : campaign.fromName
                                },
                                fromAddress: {
                                    headerValue: i18n.t('campaigns:abSplitTest.tests.fromAddress'),
                                    value: errors && errors.fromAddress ? <UILIB.WarningHolder className="warning-holder-nopadding quickFlex text-center text-red">
                                        {errors.fromAddress}
                                    </UILIB.WarningHolder> : campaign.multiAddressRules?.length ? 'From Multi Sender Rules' : campaign.fromAddress
                                },
                                replyAddress: {
                                    headerValue: i18n.t('campaigns:abSplitTest.tests.replyAddress'),
                                    value: errors && errors.replyAddress ? <UILIB.WarningHolder className="warning-holder-nopadding quickFlex text-center text-red">
                                        {errors.replyAddress}
                                    </UILIB.WarningHolder> : campaign.multiAddressRules?.length ? 'From Multi Sender Rules' : campaign.replyAddress
                                },
                                optionsCol: {
                                    headerValue: " ",
                                    value: <UILIB.OptionsDropdown popWidth="150px">
                                        <ul>
                                            <li>
                                                <a onClick={() => { this.addTest(campaign) }}>{i18n.t('edit')}</a>
                                            </li>
                                            {(!hasErrors && canAddMore) && <li>
                                                <a onClick={() => { this.duplicateCampaign(campaign) }}>{i18n.t('copy')}</a>
                                            </li>}
                                            <li>
                                                <a onClick={() => { this.deleteCampaign(campaign.id) }}>{i18n.t('delete')}</a>
                                            </li>

                                        </ul>
                                    </UILIB.OptionsDropdown>,
                                    orderBy: false,
                                    fixed: true,
                                    width: 20
                                }
                            }
                        })}
                        loadingData={false}
                        width="100%"
                        pageSize="100"
                        hasCheckBoxes="no"
                    />
                </UILIB.Paper>


                {testsInvalid && <div className="quickFlex center-xs  mar-b25">
                    <UILIB.WarningHolder>
                        {i18n.t('campaigns:abSplitTest.tests.invalidTests')}
                    </UILIB.WarningHolder>
                </div>}
                <div className="quickFlex" style={{ justifyContent: "space-between", alignContent: "center" }}>
                    <UILIB.Button className="button-md mar-r5 " onClick={this.save}>{i18n.t('campaigns:standard.saveExit')}</UILIB.Button>
                    <UILIB.Button
                        className={'button-primary '}
                        disabled={testsInvalid}
                        onClick={() => { this.props.changeStep(3, true) }}
                        iconRight={<UILIB.Icons icon="arrowRight" />
                        }>
                        {i18n.t('campaigns:standard.nextStep')}
                    </UILIB.Button>
                </div>

            </div >
        );
    }
}

export default AbTests;
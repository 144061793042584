import React from 'react'
import UILIB from '~/Common-Objects/Lib'
import numberFormatFunctions from '~/Classes/numberFormatFunctions'

export default function SmsSendAnalysis({ campaign, analysis, messages }) {
    let total = 0

    analysis.forEach(a => total += a.count)

    if (campaign.limit && Number(campaign.limit) > 0) {
        if (Number(campaign.limit) < total) total = Number(campaign.limit)
    }

    return (
        <UILIB.Paper className="mar-b20">
            <h4 className="mar-b20">Country Breakdown</h4>
            <div className="flexSpaceBetween mar-b25">
                <div>
                    <div className='mar-b5' style={{ color: '#999' }}>Country</div>
                    {analysis.filter(a => a.count).map(a => <div key={'c_' + a.country} className='text-heavy'>{a.country}</div>)}
                </div>
                <div className='quickFlex' style={{ justifyContent: 'flex-end' }}>
                    <div className='mar-r20'>
                        <div className='mar-b5' style={{ color: '#999' }}>Recipients</div>
                        {analysis.filter(a => a.count).map(a => <div key={'co_' + a.country} className='text-heavy text-right'>{numberFormatFunctions.formatNumber(total)}</div>)}
                    </div>
                    <div>
                        <div className='mar-b5' style={{ color: '#999' }}>Credits</div>
                        {analysis.filter(a => a.count).map(a => <div key={'cr_' + a.country} className='text-heavy text-right'>{numberFormatFunctions.formatNumber(total * messages)}</div>)}
                    </div>
                </div>
            </div>
            <div className="flexSpaceBetween">
                <div>Total: <span className="text-heavy">{total * messages}</span></div>
                <div>Supported Countries: <span className="text-heavy">{analysis.map(a => a.country).join(', ')}</span></div>
            </div>
        </UILIB.Paper>
    )
}
import React, { useState, useEffect } from 'react'
import DateTimeFunctions from '../../../../../../Classes/dateTimeFunctions'
import FormatNumberFuncs from '../../../../../../Classes/numberFormatFunctions'
import UILIB from '../../../../../../Common-Objects/Lib'
import axios from '../../../../../../data/http/axios'
const limit = 20

export default function MailchimpTemplates() {
    const [campaigns, setCampaigns] = useState(null)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [viewing, setViewing] = useState(null)
    const [alert, setAlert] = useState({ open: false, message: '' })

    const getCampaigns = async () => {
        setLoading(true)
        const res = await axios.get('/application/mailchimp/campaigns?offset=' + page * limit + '&limit=' + limit)
        setCampaigns(res.data.campaigns)
        setTotal(res.data.total)
        setLoading(false)
    }

    useEffect(() => {
        getCampaigns()
    }, [page])

    if (!campaigns) return <UILIB.LoadingIcons iconType="2" />

    const tableData = campaigns.map(c => {
        return {
            name: {
                headerValue: 'Name',
                value: c.settings.title
            },
            sendDate: {
                headerValue: 'Send Date',
                value: DateTimeFunctions.formatDateTime(c.send_time)
            },
            subject: {
                headerValue: 'Subject',
                value: c.settings.subject_line
            },
            fromName: {
                headerValue: 'From Name',
                value: c.settings.from_name
            },
            fromName: {
                headerValue: 'Reply Address',
                value: c.settings.reply_to
            },
            recipients: {
                headerValue: 'Recipients',
                value: FormatNumberFuncs.formatNumber(c.recipients.recipient_count)
            },
            opens: {
                headerValue: 'Opens',
                value: c.report_summary ? FormatNumberFuncs.formatNumber(c.report_summary.unique_opens) : ''
            },
            clicks: {
                headerValue: 'Clicks',
                value: c.report_summary ? FormatNumberFuncs.formatNumber(c.report_summary.subscriber_clicks) : ''
            },
            view: {
                headerValue: ' ',
                value: <div className='quickFlex'>
                    <UILIB.Button text="View & Import" className="button-sml" onClick={() => setViewing(c)} />
                </div>
            }
        }
    })

    return (
        <UILIB.Paper isLoading={loading}>
            <h4 className="mar-b25">Import Email Content from Mailchimp Campaigns</h4>
            <p>We can import your Mailchimp content and you can edit them with our WYSIWYG editor. If you want to use our Drag and Drop editor you will need to create it from scratch or use one of our themes</p>
            <div className='mar-b30'>
                <UILIB.DataTable1 tableData={tableData} holderStyle={{ height: 'calc(100vh - 335px)', overflowX: 'auto' }} />
            </div>
            <div className='center-xs'>
                <UILIB.PagingBlock totalRows={total} currentPage={page} changePage={p => setPage(p)} pageSize={limit} />
            </div>

            {!!viewing && <UILIB.Drawer isOpen={true} standalone={true} close={() => setViewing(null)} width="100vw" showClose={true}>
                <ViewCampaign campaign={viewing} close={() => { setViewing(null); setAlert({ open: true, message: 'Template Imported' }) }} />
            </UILIB.Drawer>}

            <UILIB.SnackBar autoclose={true} dismiss={() => setAlert({ open: false, message: '' })} message={alert.message} open={alert.open} />
        </UILIB.Paper>
    )
}

function ViewCampaign({ campaign, close = () => { } }) {
    const [content, setContent] = useState(null)
    const [selectingTags, setSelectingTags] = useState(false)
    const [alert, setAlert] = useState({ open: false, message: '' })

    const importContent = async (mapping) => {
        setAlert({ open: true, message: 'Importing Template' })
        await axios.post('/application/mailchimp/campaign', { html: content.html, mapping, name: campaign.settings.title })
        close()
    }

    useEffect(() => {
        axios.get('/application/mailchimp/campaigns/' + campaign.id + '/content').then(res => {
            setContent(res.data)
        })
    }, [campaign.id])

    if (!content) return <UILIB.LoadingIcons iconType="2" />

    return (
        <div>
            <div className='mar-b30 end-xs'>

                <UILIB.Button text="Import" className="button-md mar-r10" onClick={() => setSelectingTags(true)} />
                <UILIB.Button text="Cancel" className="button-md grey" onClick={close} />
            </div>
            <UILIB.Paper>
                <iframe width="100%" height="600px" srcDoc={content.html} frameBorder="0"></iframe>
            </UILIB.Paper>

            {selectingTags && <UILIB.Drawer isOpen={true} standalone={true} close={() => setSelectingTags(false)} showClose={true} width={800}>
                <SelectMergeTags html={content.html} close={importContent} />
            </UILIB.Drawer>}

            <UILIB.SnackBar autoclose={true} dismiss={() => setAlert({ open: false, message: '' })} message={alert.message} open={alert.open} />
        </div>
    )
}

function SelectMergeTags({ html, close = () => { } }) {
    const [foundTags, setFoundTags] = useState(null)
    const [customFields, setCustomFields] = useState(null)

    const setValue = (v, t) => {
        const tags = [...foundTags]
        const index = tags.findIndex(tag => tag.mailchimp === t.mailchimp)
        if (index > -1) {
            tags[index].mpz = v
            setFoundTags(tags)
        }
    }

    const finish = () => {
        const result = {}
        for (const tag of foundTags) {
            if (tag.mpz) {
                result[tag.mailchimp] = tag.mpz
            }
        }
        close(result)
    }

    useEffect(() => {
        axios.post('/application/mailchimp/campaigntags', { html }).then(res => setFoundTags(res.data.map(t => ({ mailchimp: t, mpz: undefined }))))
        axios.get('/customfield').then(res => setCustomFields(res.data.CustomFields))
    }, [html])

    if (!foundTags || !customFields) return <UILIB.LoadingIcons iconType="2" />
    const ready = (foundTags && customFields)

    return (
        <div>
            <h4 className="mar-b25">Convert Merge Tags</h4>
            {!ready && <div>
                <p>Checking the content for merge tags</p>
                <UILIB.LoadingIcons iconType="2" />
            </div>}
            {ready && <div>
                <p>Any merge tags you don't select will need to be manually changed in our WYSIWYG editor</p>
                <UILIB.Row className="col-spacer-bottom middle-xs" style={{ padding: '10px 0' }}>
                    <UILIB.Column xs={5}><strong>Mailchimp Merge Tag</strong></UILIB.Column>
                    <UILIB.Column xs={5}><strong>Transpond Merge Tag</strong></UILIB.Column>
                    <UILIB.Column xs={2}></UILIB.Column>
                </UILIB.Row>
                {!!foundTags.length && foundTags.map((t, i) => <UILIB.Row className="col-spacer-bottom middle-xs" style={{ padding: '10px 0' }} key={i}>
                    <UILIB.Column xs={5}>{t.mailchimp}</UILIB.Column>
                    <UILIB.Column xs={5}>
                        <UILIB.Select placeholder="Select Field" data={customFields.map(c => ({ label: c.fieldDesc, value: c.id }))} value={t.mpz} onChange={e => setValue(e.currentTarget.value, t)} />
                    </UILIB.Column>
                    <UILIB.Column xs={2}>
                        <UILIB.Button className="button-sml grey" text="Ignore" onClick={() => setValue('', t)} />
                    </UILIB.Column>
                </UILIB.Row>)}
                {!foundTags.length && <p>No unknown merge tags found, ok to import</p>}
                <div className='mar-t30'>
                    <UILIB.Button text="Import Template" className="button-md" onClick={finish} />
                </div>
            </div>}
        </div>
    )
}
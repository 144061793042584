import React, { Component } from 'react';
import UILIB from '~/Common-Objects/Lib'
import axios from '~/data/http/axios'
import { connect } from 'react-redux';
import DrawerStatsViewer from './drawerStatsViewer'
import DrawerStuckViewer from './drawerStuckViewer'
import TemplateImageLoader from './templateImageLoader.jsx'
import SiteVars from '~/Classes/siteVars';
import i18n from '~/i18n';
import PermissionChecker from '~/Classes/permissions';
import SmsPhone from '~/pages/cp/includes/sms/phone'
import GenericStatsHolder from '~/pages/cp/campaigns/views/shared/components/genericStatsHolder';
@connect((store) => {
    return { permissionStore: store.permission, accountMaster: store.accountMaster }
})
class DrawerRunningStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            campaignStats: {},
            drawer: undefined
        }
        this.getCampaignStats = this.getCampaignStats.bind(this);
        this.viewStats = this.viewStats.bind(this);
        this.countStuck = this.countStuck.bind(this)
    }

    componentDidMount() {
        if (this.props.step.type == "email") {
            this.getCampaignStats();
        }
        else {
            this.setState({ loaded: true })
        }

        if (this.props.nextStep && this.props.step.type !== 'initial') {
            this.countStuck()
        }
    }

    countStuck() {
        this.setState({ gettingStuck: true })
        axios.get('/automation/' + this.props.automationId + '/v2/steps/' + this.props.step.guid + '/stuck?count=true').then(response => {
            this.setState({ stuck: response.data.count, gettingStuck: false })
        })
    }

    getCampaignStats() {
        axios.get('/campaign/' + this.props.step.stepOptions.campaign.id + '/stats?ignoreLog=1').then(stats => {
            this.setState({ loaded: true, campaignStats: stats.data.Stats })
        })
    }

    viewStats(type) {
        var drawer
        if (type === 'stuck') {
            drawer = <UILIB.Drawer close={() => this.setState({ drawer: undefined })} isOpen={true} standalone={true} width="100%" showClose={true} clickOutside={false} >
                <DrawerStuckViewer step={this.props.step} automationId={this.props.automationId} nextStep={this.props.nextStep} refresh={this.countStuck} goTo={this.props.goTo} />
            </UILIB.Drawer>
        } else {
            drawer = <UILIB.Drawer close={() => this.setState({ drawer: undefined })} isOpen={true} standalone={true} width="100%" showClose={true} clickOutside={false} >
                <DrawerStatsViewer type={type} step={this.props.step} automationId={this.props.automationId} />
            </UILIB.Drawer>
        }
        this.setState({ drawer: drawer })

    }
    render() {

        if (!this.state.loaded) return <div><UILIB.LoadingIcons iconType="2" /></div>
        var processed = 0;
        var queued = 0;
        var total = -2;
        var conditionTrue = 0;
        var conditionFalse = 0;
        var campaignSent = 0;
        var campaignOpened = 0;
        var campaignClicked = 0;
        var campaignBounced = 0;


        if (this.props.stats) {
            var stepStats = this.props.stats.find(s => s.StepGuid == this.props.step.guid)
            if (stepStats) {
                processed = stepStats.Processed;
                queued = stepStats.Queued;
                total = processed + queued;
                conditionTrue = stepStats.ConditionTrue;
                conditionFalse = stepStats.ConditionFalse;
            }
        }

        if (this.state.campaignStats.hasOwnProperty('Total Sent')) campaignSent = this.state.campaignStats['Total Sent'];
        if (this.state.campaignStats.hasOwnProperty('Unique Opens')) campaignOpened = this.state.campaignStats['Unique Opens'];
        if (this.state.campaignStats.hasOwnProperty('Unique Clicks')) campaignClicked = this.state.campaignStats['Unique Clicks'];
        if (this.state.campaignStats.hasOwnProperty('Hard Bounces')) campaignBounced = this.state.campaignStats['Hard Bounces'];

        var templateImage = "";
        if (this.props.step.type == "email" && this.props.step.stepOptions?.campaign?.TemplateId) {
            templateImage = SiteVars.repoEndPoint + 'userContent/' + this.props.accountMaster.accountMaster.id + "/templates/screenshots/template" + this.props.step.stepOptions.campaign.TemplateId + ".jpg"
        }

        let canViewContacts = PermissionChecker('subscribers', this.props.permissionStore.permissions, "read")
        return <div className="automationStepContentHolder" data-test="drawer-running-step">
            <div className="drawerRunningStep-header mar-b25">
                <div>
                    <h2 className="h3 mar-b10">{i18n.t('automation:add.running.header')}</h2>
                    <p className="mar-b0">{i18n.t('automation:add.running.subheader')}</p>
                </div>

                <UILIB.Button onClick={this.props.onViewTriggerSettingsClick}>
                    {this.props.step.type === "initial"
                        ? i18n.t("automation:add.running.showInitialStepSettingsAction")
                        : i18n.t("automation:add.running.showStepSettingsAction")}
                </UILIB.Button>
            </div>

            {this.props.step.type != "goal" && <div className="quickFlex">
                <div style={{ flex: "1" }} className="mar-r15">
                    <GenericStatsHolder
                        value={processed}
                        limit={total}
                        header={i18n.t('automation:add.running.processed')}
                        link={() => { if (canViewContacts) { this.viewStats('processed') } }}
                        linkText={canViewContacts ? i18n.t('automation:add.running.view') : ""}
                        linkIcon="arrowRight"
                    />

                </div>
                <div style={{ flex: "1" }}>
                    <GenericStatsHolder
                        value={queued}
                        limit={total}
                        header={i18n.t('automation:add.running.queued')}
                        link={() => { if (canViewContacts) { this.viewStats('queued') } }}
                        linkText={canViewContacts ? i18n.t('automation:add.running.view') : ""}
                        linkIcon="arrowRight"
                    />

                </div>
            </div>
            }

            {
                this.props.step.type == "email" && <div>

                    <UILIB.Paper style={{ borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", padding: "0px" }}>
                        <div style={{ opacity: "0.4", height: "200px" }}>
                            <TemplateImageLoader imageUrl={templateImage} />
                        </div>
                        <div style={{ position: "absolute", top: "20px", left: "20px" }}>
                            <div className="text-lg">{this.props.step.stepOptions.subject.value}</div>
                            <div>
                                From {this.props.step.stepOptions.fromName.value} ({this.props.step.stepOptions.fromAddress.value})
                            </div>
                        </div>
                        {(this.props.step.stepOptions?.campaign?.TemplateId) &&
                            <div style={{ position: "absolute", right: "20px", bottom: "20px", cursor: "pointer" }} alt="Preview Email Template" title="Preview Email Template" onClick={() => { this.props.loadEmailPreview(this.props.step.stepOptions.campaign.TemplateId) }}>
                                <UILIB.Button text="Preview" iconLeft={<UILIB.Icons icon="magnifier" />} />

                            </div>
                        }
                    </UILIB.Paper>

                    <UILIB.Paper style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={6}>
                                <GenericStatsHolder
                                    value={campaignOpened}
                                    limit={processed}
                                    header={i18n.t('automation:add.running.opened')}
                                    link={() => { if (canViewContacts) { this.viewStats('opened') } }}
                                    linkText={canViewContacts ? i18n.t('automation:add.running.view') : ""}
                                    linkIcon="arrowRight"
                                />
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6}>
                                <GenericStatsHolder
                                    value={campaignClicked}
                                    limit={processed}
                                    header={i18n.t('automation:add.running.clicked')}
                                    link={() => { if (canViewContacts) { this.viewStats('clicked') } }}
                                    linkText={canViewContacts ? i18n.t('automation:add.running.view') : ""}
                                    linkIcon="arrowRight"
                                />

                            </UILIB.Column>
                            <UILIB.Column xs={12}>
                                <GenericStatsHolder
                                    value={campaignBounced}
                                    limit={processed}
                                    header={i18n.t('automation:add.running.bounced')}
                                    link={() => { if (canViewContacts) { this.viewStats('bounced') } }}
                                    linkText={canViewContacts ? i18n.t('automation:add.running.view') : ""}
                                    linkIcon="arrowRight"
                                />
                            </UILIB.Column>
                        </UILIB.Row>
                        {(PermissionChecker('campaigns', this.props.permissionStore.permissions, "read") == true) && <div className="center-xs">
                            <UILIB.Button text={i18n.t('automation:add.running.campaignDetails')} className="button-primary" onClick={() => this.props.goTo('/cp/campaigns/view/' + this.props.step.stepOptions.campaign.id, '_blank')} />
                        </div>}

                    </UILIB.Paper>
                </div>
            }

            {
                this.props.step.type == "sms" && <div>
                    <UILIB.Paper style={{ borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", padding: "0px" }}>

                        <div className="text-white" style={{ height: 200, backgroundColor: "#000000", position: "relative", textAlign: "left" }}>
                            <div style={{ opacity: "0.4", height: 200, display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                                <div className="phone">
                                    <SmsPhone content={null} refresh={this.props.step.stepOptions.refresh} from={this.props.step.stepOptions.subject.value} templateId={this.props.step.stepOptions?.campaign?.TemplateId} />
                                </div>
                            </div>
                        </div>
                    </UILIB.Paper>

                    <UILIB.Paper style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
                        <UILIB.Row>
                            <UILIB.Column xs={12} sm={6}>
                                <GenericStatsHolder
                                    value={campaignClicked}
                                    limit={processed}
                                    header={i18n.t('automation:add.running.clicked')}
                                    link={() => { if (canViewContacts) { this.viewStats('clicked') } }}
                                    linkText={canViewContacts ? i18n.t('automation:add.running.view') : ""}
                                    linkIcon="arrowRight"
                                />
                            </UILIB.Column>
                            <UILIB.Column xs={12} sm={6}>
                                <GenericStatsHolder
                                    value={campaignBounced}
                                    limit={processed}
                                    header={i18n.t('automation:add.running.bounced')}
                                    link={() => { if (canViewContacts) this.viewStats('bounced') }}
                                    linkText={canViewContacts ? i18n.t('automation:add.running.view') : ""}
                                    linkIcon="arrowRight"
                                />
                            </UILIB.Column>
                            {(PermissionChecker('campaigns', this.props.permissionStore.permissions, "read") == true) && <UILIB.Column xs={12} className="center-xs">
                                <UILIB.Button text={i18n.t('automation:add.running.campaignDetails')} className="outline primary button-sml" onClick={() => this.props.goTo('/cp/campaigns/view/' + this.props.step.stepOptions.campaign.id, '_blank')} />
                            </UILIB.Column>}
                        </UILIB.Row>
                    </UILIB.Paper>
                </div>
            }

            {
                this.props.step.type == "condition" && <div className="quickFlex">
                    <div style={{ flex: "1" }} className="mar-r15">
                        <GenericStatsHolder
                            value={conditionTrue}
                            limit={total}
                            header={i18n.t('yes')}
                            link={() => { if (canViewContacts) this.viewStats('yes') }}
                            linkText={canViewContacts ? i18n.t('automation:add.running.view') : ""}
                            linkIcon="arrowRight"
                        />


                    </div>

                    <div style={{ flex: "1" }} >
                        <GenericStatsHolder
                            value={conditionFalse}
                            limit={total}
                            header={i18n.t('no')}
                            link={() => { if (canViewContacts) this.viewStats('no') }}
                            linkText={canViewContacts ? i18n.t('automation:add.running.view') : ""}
                            linkIcon="arrowRight"
                        />
                    </div>
                </div>
            }

            {
                this.props.step.type == "goal" && <div>
                    <GenericStatsHolder
                        value={conditionTrue}
                        limit={total}
                        header={i18n.t('automation:add.running.achieved')}
                        link={() => { if (canViewContacts) this.viewStats('yes') }}
                        linkText={canViewContacts ? i18n.t('automation:add.running.view') : ""}
                        linkIcon="arrowRight"
                    />
                </div>

            }

            {
                this.state.gettingStuck && <div>
                    <UILIB.LoadingIcons iconType="2" />
                </div>
            }
            {
                !!this.state.stuck && <div>
                    <GenericStatsHolder
                        value={this.state.stuck}
                        limit={total}
                        header={i18n.t('automation:add.stuck.stuck')}
                        link={() => { if (canViewContacts) this.viewStats('stuck') }}
                        linkText={canViewContacts ? i18n.t('automation:add.running.view') : ""}
                        linkIcon="magnifier"
                    />
                </div>
            }

            {
                !this.props.step.parentGuid && Boolean(this.props.options.batchSize) && Boolean(this.props.options.batchDelay) && Boolean(this.props.options.batchUnit) && <div>
                    <div className="text-sml text-grey text-heavy">Batch Settings: <span className="text-black">{this.props.options.batchSize} every {this.props.options.batchDelay} {this.props.options.batchUnit}</span></div>
                </div>
            }

            <div style={{ fontSize: "10px", textAlign: "right" }}>
                Step GUID: {this.props.step.guid}
            </div>
            {this.state.drawer && this.state.drawer}
        </div >

    }
}

export default DrawerRunningStep;
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import FormatNumberFuncs from '../../../../../../Classes/numberFormatFunctions'
import UILIB from '../../../../../../Common-Objects/Lib'
import axios from '../../../../../../data/http/axios'
import IncGoAddGroup from '../../../../subscribers/groups/incGoAddGroup'
import ImportProgressContent from '../../../../groups/group/add/import/importProgress'
import { alterSiteDrawer } from '../../../../../../data/actions/siteActions'

export default function MailchimpLists() {
    const [lists, setLists] = useState(null)
    const [groups, setGroups] = useState(null)
    const [config, setConfig] = useState({
        list: undefined, group: undefined, mapping: undefined, tags: false
    })
    const [complete, setComplete] = useState({
        step1: false,
        step2: false
    })
    const [step, setStep] = useState(1)

    const reset = () => {
        setConfig({ list: undefined, group: undefined, mapping: undefined, tags: false })
        setComplete({
            step1: false,
            step2: false
        })
        setStep(1)
    }

    useEffect(() => {
        axios.get('/application/mailchimp/groups').then(res => {
            setLists(res.data.Groups)
        })

        axios.get('/group').then(res => {
            setGroups(res.data.Groups)
        })
    }, [])

    const steps = [{
        title: 'Select List',
        onClick: () => setStep(1),
        selected: step === 1,
        complete: complete.step1
    }, {
        title: 'Select Fields',
        onClick: () => {
            if (complete.step1) setStep(2)
        },
        selected: step === 2,
        complete: complete.step1 && complete.step2
    }, {
        title: 'Start Import',
        onClick: () => {
            if (complete.step2) setStep(3)
        },
        selected: step === 3,
        complete: complete.step1 && complete.step2
    }]

    return (
        <UILIB.Row style={{ justifyContent: 'center' }}>
            <UILIB.Column xs={12} sm={12} md={10} lg={8}>
                <UILIB.Paper>
                    <div className="quickFlex mar-b10" style={{ alignItems: 'flex-end' }}>
                        <h4 className='mar-r10'>Import Mailchimp Lists</h4>
                        <div>You can run this import wizard multiple times for each Mailchimp list you want to import.</div>
                    </div>

                    <UILIB.ProgressNav steps={steps} />

                    {step === 1 && <SelectList lists={lists} groups={groups} config={config} setGroups={setGroups} setConfig={setConfig} complete={() => {
                        setComplete({ ...complete, step1: true })
                        setStep(2)
                    }} />}

                    {step === 2 && <SelectMapping list={config.list} config={config} setConfig={setConfig} setStep={setStep} complete={() => {
                        setComplete({ ...complete, step2: true })
                        setStep(3)
                    }} />}

                    {step === 3 && <ImportProgress config={config} reset={reset} />}
                </UILIB.Paper>
            </UILIB.Column>
        </UILIB.Row>
    )
}

function SelectList({ lists, groups, config, setGroups = () => { }, setConfig = () => { }, complete = () => { } }) {
    const [creatingGroup, setCreatingGroup] = useState(false)

    const createdGroup = (res) => {
        setGroups([...groups, res.data.group])
        setConfig(c => ({ ...c, group: res.data.group }))
        setCreatingGroup(false)
    }

    const select = (ev) => {
        const name = ev.currentTarget.name
        const value = name === 'group' ? parseInt(ev.currentTarget.value) : ev.currentTarget.value
        if (name === 'group') {
            setConfig(c => ({ ...c, group: groups.find(g => g.id === value) }))
        } else {
            setConfig(c => ({ ...c, list: lists.find(g => g.id === value) }))
        }
    }

    if (!lists || !groups) return <UILIB.LoadingIcons />

    const capsuleGroup = config.group && config.group.Applications?.some(a => a.ApplicationId === 8)

    return (
        <>
            <div className='mar-b20'>
                Transpond groups are not distinct like Mailchimp. A contact can be in multiple groups and it shares custom fields and segment membership.
                <br />We will import the subscription status of the contacts from Mailchimp, if the contact already exists in Transpond the Mailchimp status will overwrite their current status.
            </div>
            <div className=''>
                <UILIB.Select
                    name="list"
                    onChange={select}
                    outerClassName="mar-b10"
                    label="Mailchimp List"
                    data={lists.map(l => ({ label: l.name + ` (${FormatNumberFuncs.formatNumber(l.total)})`, value: l.id }))}
                    value={config.list?.id}
                    placeholder="Select List"
                />
                <div className='mar-b20 quickFlex' style={{ alignItems: 'flex-end' }}>
                    <UILIB.Select outerstyle={{ flexGrow: 1 }} name="group" onChange={select} label="Transpond Group" data={groups.map(g => ({ label: g.groupName, value: g.id }))} value={config.group?.id} placeholder="Select Group" />
                    <UILIB.Button className="mar-l10 button-secondary" onClick={() => setCreatingGroup(config.list || {})}>Or create a new Group</UILIB.Button>
                </div>
                <div className="quickFlex" style={{ flexDirection: 'row-reverse' }}>
                    <div className='quickFlex' style={{ alignItems: 'center' }}>
                        {capsuleGroup && <div className='mar-r10 text-orange'>Importing new contacts into the Capsule group will add those contacts to Capsule</div>}
                        <UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={complete} disabled={!config.group || !config.list}>Continue</UILIB.Button>
                    </div>
                </div>
            </div>
            {!!creatingGroup && <UILIB.Drawer standalone={true} isOpen={true} showClose={true} width={400} startClose={() => setCreatingGroup(false)}>
                <IncGoAddGroup dontCloseDrawer={true} addedGroup={createdGroup}
                    groupName={creatingGroup.name} friendlyName={creatingGroup.name}
                    defaultFromName={creatingGroup.defaults?.from_name}
                    defaultFromEmailAddress={creatingGroup.defaults?.from_email}
                    signupReminderText={creatingGroup.reminder}
                />
            </UILIB.Drawer>}
        </>
    )
}

function SelectMapping({ list, config, setConfig = () => { }, setStep = () => { }, complete = () => { } }) {
    const [mergeTags, setMergeTags] = useState(null)
    const [customFields, setCustomFields] = useState(null)
    const [apps, setApps] = useState(null)
    const [chooseField, setChooseField] = useState(false)

    const selectMergeTags = (e => {
        const value = e.currentTarget.value
        const mapping = config.mapping || []
        const index = mapping.findIndex(m => m.id === value)
        if (index === -1) {
            mapping.push({ id: value, fieldId: undefined })
        } else {
            mapping.splice(index, 1)
        }
        setConfig(c => ({ ...c, mapping: [...mapping] }))
    })

    const setFieldMapping = (id, fieldId) => {
        const mapping = config.mapping || []
        const index = mapping.findIndex(m => m.id === id)
        if (index > -1) {
            if (fieldId === undefined) {
                mapping.splice(index, 1)
            } else {
                mapping[index].fieldId = fieldId
            }
        }
        setConfig(c => ({ ...c, mapping: [...mapping] }))
    }

    const createNew = async (mergeTag) => {
        try {
            const body = {
                fieldName: '_mailchimp_' + list.id + '_' + mergeTag.merge,
                fieldDesc: list.name + ' - ' + mergeTag.name,
                fieldType: mergeTag.type || 'TEXT',
                options: mergeTag.options,
                ApplicationId: 10
            }
            const res = await axios.post('/customfield', body)
            const newField = res.data.CustomField
            setCustomFields([...customFields, newField])
            setFieldMapping(mergeTag.merge, newField.fieldName)
        } catch (e) {
            console.log(e)
            if (e.data.error === 'CustomField already exists') {
                // find it and select it
                const found = customFields.find(cf => cf.fieldName === '_mailchimp_' + list.id + '_' + mergeTag.merge)
                if (found) {
                    setFieldMapping(mergeTag.merge, found.fieldName)
                }
            }
        }
    }

    const onBlur = () => {
        const notFinished = (config.mapping || []).some(m => !m.fieldId)
        if (notFinished) {
            setChooseField(true)
        }
    }

    useEffect(() => {
        axios.get('/application/mailchimp/fields?group=' + list.id).then(res => {
            setMergeTags(res.data.IncomingFields.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            }))
            setCustomFields(res.data.ExistingFields)
        })
        axios.get('/accountMaster/application').then(res => setApps(res.data.Applications))
    }, [list])

    if (!mergeTags || !customFields || !apps) return <UILIB.LoadingIcons />

    const mapping = config.mapping || []

    const notFinished = mapping.some(m => !m.fieldId)

    const data = customFields.map(f => {
        const app = apps.find(a => a.ApplicationId === f.ApplicationId)
        return {
            label: f.fieldDesc,
            value: f.fieldName,
            iconRight: app ? <UILIB.Avatar style={{ height: 20, width: 20, border: '1px solid grey' }} for={f.id} tooltip={app.Application.appName} src={app.Application.appLogoUri} /> : undefined
        }
    })

    const tableData = mergeTags.filter(t => mapping.some(m => m.id === t.merge)).map(mergeTag => {
        const listField = mapping.find(m => m.id === mergeTag.merge)
        return {
            mailchimp: {
                headerValue: 'Mailchimp Field',
                value: mergeTag.name
            },
            mpz: {
                headerValue: 'Transpond Field',
                value: <div className="form-group no-marg">
                    <UILIB.Select placeholder="Select Field" data={data} value={listField ? listField.fieldId : ''} onChange={e => setFieldMapping(mergeTag.merge, e.currentTarget.value)} />
                </div>
            },
            options: {
                headerValue: ' ',
                value: <div className="quickFlex">
                    <UILIB.Button text="Create Field" className="button-sml mar-r10" onClick={() => createNew(mergeTag)} disabled={listField && listField.fieldId} />
                    <UILIB.Button text="Remove" className="button-sml grey" onClick={() => setFieldMapping(mergeTag.merge, undefined)} />
                </div>
            }
        }
    })

    const capsuleSelected = customFields.filter(cf => cf.ApplicationId === 8).some(cf => mapping.some(mt => mt.fieldId === cf.fieldName))

    return (
        <>
            <div className='mar-b20'>
                By default we will only import the Mailchimp email address and their marketing status. You can select below additional Mailchimp fields you want to import.
                <br />You will need then to tell us which Transpond fields those Mailchimp fields will map on to, or tell us to create new fields for them.
            </div>
            <div className='mar-b20'>
                <div className="row mar-b20" style={{ alignItems: 'flex-end' }}>
                    <div className="col-xs-6">
                        <UILIB.Select label="Select Mailchimp Fields to Import" multiple={true} data={mergeTags.map(t => ({ value: t.merge, label: t.name }))} value={mapping.map(m => m.id)} onChange={selectMergeTags} onBlur={onBlur} />
                    </div>
                    <div className="col-xs-6">
                        <UILIB.Button className="button-primary" onClick={() => setChooseField(true)}>Map to Transpond</UILIB.Button>
                    </div>
                </div>

                {mapping.map((map, index) => {
                    const mcField = mergeTags.find(t => t.merge == map.id)
                    let classes = 'mar-r10 '
                    if (map.fieldId) classes += 'square-chip-green'
                    else classes += 'square-chip-orange'
                    return <UILIB.SquareChip key={index} className={classes}>{mcField.name}</UILIB.SquareChip>
                })}
            </div>
            {notFinished && <div className='mar-b20 text-red'>You have selected some Mailchimp fields that are not mapped to Transpond fields, please <strong>map to Transpond</strong> fields</div>}

            <UILIB.Toggle
                outerClassName="mar-b20"
                after="Import Tags"
                value={config.tags}
                onChange={() => setConfig(c => ({ ...c, tags: !c.tags }))}
                explainer="When selected we will add Mailchimp tags to contacts we import, we will not remove any existing tags if those contacts already exist in Transpond"
            />


            <div className="quickFlex" style={{ justifyContent: 'space-between' }}>
                <UILIB.Button iconLeft={<UILIB.Icons icon="arrowLeft" />} onClick={() => setStep(1)}>Back</UILIB.Button>
                <div className='quickFlex' style={{ alignItems: 'center' }}>
                    <UILIB.Button className="button-primary" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={complete} disabled={notFinished}>Start Import</UILIB.Button>
                </div>

            </div>

            {!!chooseField && <UILIB.Drawer standalone={true} isOpen={true} showClose={true} width={800} startClose={() => setChooseField(false)}>
                <div>
                    <h4 className="mar-b25">Select or create fields to bind Mailchimp fields to</h4>

                    <div className='mar-b30'>
                        <UILIB.DataTable1 tableData={tableData} holderStyle={{ height: 'calc(100vh - 210px)', overflowX: 'auto' }} />
                    </div>

                    <div className="quickFlex">
                        <UILIB.Button text="Save" className="button-md" onClick={() => setChooseField(false)} />
                        {capsuleSelected && <div className='mar-l10 text-orange'>Warning! Selecting a Capsule field will update that field in Capsule</div>}
                    </div>

                </div>
            </UILIB.Drawer>}
        </>
    )
}

function ImportProgress({ config, reset = () => { } }) {
    const [imports, setImports] = useState(null)
    const dispatch = useDispatch()

    const startImport = async () => {
        const body = {
            imports: [{ group: config.group.id, list: config.list.id, mapping: config.mapping, tags: config.tags }]
        }
        const res = await axios.post('/application/mailchimp/import', body)
        setImports(res.data)
    }

    const finish = () => {
        dispatch(alterSiteDrawer(false, true, 'right', null))
    }

    useEffect(() => {
        startImport()
    }, [])


    if (!imports) return <UILIB.LoadingIcons />

    const importJob = imports[0]

    return (
        <>
            <ImportProgressContent standalone={true} match={{ params: { groupID: importJob.GroupId, importID: importJob.id } }} />
            <div className="quickFlex mar-t20" style={{ justifyContent: 'space-between' }}>
                <UILIB.Button onClick={reset}>Import Another List</UILIB.Button>
                <UILIB.Button className="button-primary" onClick={finish}>Finish</UILIB.Button>
            </div>
        </>
    )
}
import React from 'react'

export default function IconMenu({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 5.00006C3 4.73484 3.10536 4.48049 3.29289 4.29295C3.48043 4.10542 3.73478 4.00006 4 4.00006H16C16.2652 4.00006 16.5196 4.10542 16.7071 4.29295C16.8946 4.48049 17 4.73484 17 5.00006C17 5.26528 16.8946 5.51963 16.7071 5.70717C16.5196 5.8947 16.2652 6.00006 16 6.00006H4C3.73478 6.00006 3.48043 5.8947 3.29289 5.70717C3.10536 5.51963 3 5.26528 3 5.00006ZM3 10.0001C3 9.73484 3.10536 9.48049 3.29289 9.29295C3.48043 9.10542 3.73478 9.00006 4 9.00006H16C16.2652 9.00006 16.5196 9.10542 16.7071 9.29295C16.8946 9.48049 17 9.73484 17 10.0001C17 10.2653 16.8946 10.5196 16.7071 10.7072C16.5196 10.8947 16.2652 11.0001 16 11.0001H4C3.73478 11.0001 3.48043 10.8947 3.29289 10.7072C3.10536 10.5196 3 10.2653 3 10.0001ZM3 15.0001C3 14.7348 3.10536 14.4805 3.29289 14.293C3.48043 14.1054 3.73478 14.0001 4 14.0001H16C16.2652 14.0001 16.5196 14.1054 16.7071 14.293C16.8946 14.4805 17 14.7348 17 15.0001C17 15.2653 16.8946 15.5196 16.7071 15.7072C16.5196 15.8947 16.2652 16.0001 16 16.0001H4C3.73478 16.0001 3.48043 15.8947 3.29289 15.7072C3.10536 15.5196 3 15.2653 3 15.0001Z" fill={fillColor} />
        </svg>
    )
}
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import UILIB from '../../../../../../Common-Objects/Lib'
import * as siteActions from '../../../../../../data/actions/siteActions'
import axios from '../../../../../../data/http/axios'
import Images from './images'
import Templates from './templates'
// import Lists from './lists'
import Lists from './listsV2'

export default function MailchimpConfig() {
    const [app, setApp] = useState(null)
    const [accApp, setAccApp] = useState(null)
    const [stats, setStats] = useState(null)
    const dispatch = useDispatch()
    const history = useHistory()

    const selectImages = () => {
        const content = <Images />
        dispatch(siteActions.alterSiteDrawer(true, true, 'right', content, true, '100vw'))
    }

    const selectTemplates = () => {
        const content = <Templates />
        dispatch(siteActions.alterSiteDrawer(true, true, 'right', content, true, '100vw'))
    }

    const selectLists = () => {
        const content = <Lists />
        dispatch(siteActions.alterSiteDrawer(true, true, 'right', content, true, '100vw'))
    }

    const disconnect = () => {
        const content = <UILIB.DrawerConfirm header="Disconnect Mailchimp" subheader='Are you sure you want to disconnect Mailchimp?' confirm={async () => {
            await axios.delete('/application/account/' + accApp.id)
            history.push('/cp/company/integrations')
        }} />
        dispatch(siteActions.alterSiteDrawer(true, true, 'right', content, true))
    }

    useEffect(() => {
        axios.get('/application/10').then(res => {
            setApp(res.data.Application)
        })
        axios.get('/application/account/app/10').then(res => {
            setAccApp(res.data.Application)
        })
    }, [])

    useEffect(() => {
        if (app && accApp) {
            axios.get('/application/mailchimp/available').then(res => {
                setStats(res.data)
            })
        }
    }, [app, accApp])

    if (!app || !accApp) return <UILIB.LoadingIcons iconType="2" />

    return (
        <UILIB.Row>
            <UILIB.Column xs={12} >
                <UILIB.Paper>
                    <UILIB.Row className="middle-xs">
                        <UILIB.Column xs={8} style={{ display: 'flex', alignItems: 'center' }} className="mar-b25">
                            <img src={app.appLogoUri} height="60" style={{ borderRadius: '50%' }} />
                            <div>
                                <p className='pad-left mar-b10 mar-t0 text-md2'>Connected to Mailchimp</p>
                                <p className='pad-left no-marg'>You can perform a manual import of your contacts, templates and images. We do not keep things automatically in sync with Mailchimp</p>
                            </div>
                        </UILIB.Column>
                        <UILIB.Column xs={4} className="end-xs mar-b25">
                            <UILIB.Button text="Disconnect" className="button-sml grey" onClick={disconnect} />
                        </UILIB.Column>
                        {!stats && <UILIB.Column xs={12} className="mar-b30 col-spacer-top">
                            <UILIB.LoadingIcons iconType="2" className="mar-t30" />
                        </UILIB.Column>}
                        {!!stats && <>
                            <UILIB.Column xs={12} className="mar-b30 col-spacer-top">
                            </UILIB.Column>
                            <UILIB.Column xs={12} md={4} className="col-md-spacer-right">
                                <div style={{ display: 'flex' }}>
                                    <div className="mar-r20">
                                        <UILIB.Icons circle={true} icon="avatars3" style={{ height: 32, width: 32 }} color="#7246B1" />
                                    </div>
                                    <div>
                                        <div style={{ fontSize: 20, fontWeight: 'bold' }} className="mar-b10">
                                            Audience
                                        </div>
                                        <div style={{ fontSize: 14 }} className="mar-b20">
                                            {stats.lists} lists available
                                        </div>
                                        <UILIB.Button text="Setup Import" className="button-md" onClick={selectLists} />
                                    </div>
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} md={4} className="col-md-spacer-right">
                                <div style={{ display: 'flex' }}>
                                    <div className="mar-r20">
                                        <UILIB.Icons circle={true} icon="template" style={{ height: 32, width: 32 }} color="#7246B1" />
                                    </div>
                                    <div>
                                        <div style={{ fontSize: 20, fontWeight: 'bold' }} className="mar-b10">
                                            Templates
                                        </div>
                                        <div style={{ fontSize: 14 }} className="mar-b20">
                                            Select which campaigns to import content from
                                        </div>
                                        <UILIB.Button text="Select Campaigns" className="button-md" onClick={selectTemplates} />
                                    </div>
                                </div>
                            </UILIB.Column>
                            <UILIB.Column xs={12} md={4}>
                                <div style={{ display: 'flex' }}>
                                    <div className="mar-r20">
                                        <UILIB.Icons circle={true} icon="image" style={{ height: 32, width: 32 }} color="#7246B1" />
                                    </div>
                                    <div>
                                        <div style={{ fontSize: 20, fontWeight: 'bold' }} className="mar-b10">
                                            Images
                                        </div>
                                        <div style={{ fontSize: 14 }} className="mar-b20">
                                            {stats.files} files available
                                        </div>
                                        <UILIB.Button text="Select Files" className="button-md" onClick={selectImages} />
                                    </div>
                                </div>
                            </UILIB.Column>


                        </>}
                    </UILIB.Row>

                </UILIB.Paper>

            </UILIB.Column>
        </UILIB.Row>
    )
}
import React, { Fragment } from 'react'
import axios from '~/data/http/axios';
import UILIB from '../../../../Common-Objects/Lib';
import { connect } from 'react-redux';
import i18n from '~/i18n'
import PermissionChecker from '~/Classes/permissions'
import NewUserForm from './newUserForm/newUserForm'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import copyClipboard from 'copy-to-clipboard';
import AddApp from '../../company/integrations/addApp'
import SiteVars from '~/Classes/siteVars'
import NumberFunctions from '../../../../Classes/numberFormatFunctions'
import moment from 'moment';

@connect((store) => {
    return { user: store.user, browser: store.browser, accountMaster: store.accountMaster, siteMaster: store.siteMaster, permissionStore: store.permission }
})
export default class banner extends React.Component {
    constructor(props) {
        super(props);
        this.doUserQuestions = this.doUserQuestions.bind(this);
        this.promoClose = this.promoClose.bind(this);
        this.getOrders = this.getOrders.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.resend = this.resend.bind(this)
        this.getExpiredApps = this.getExpiredApps.bind(this)
        this.reconnect = this.reconnect.bind(this)
        this.deleteApp = this.deleteApp.bind(this)
        this.getRelease = this.getRelease.bind(this)
        this.acknowldgeRelease = this.acknowldgeRelease.bind(this)
        this.getAlert = this.getAlert.bind(this)
        this.acknowldgeAlert = this.acknowldgeAlert.bind(this)

        this.state = {
            currVoucherCode: "QUICK50",
            alertOpen: false,
            alertMessage: "",
            dismissedBanner: false,
            pageLoaded: false,
            unpaid: [],
            disabledApps: [],
            releases: [],
            account: undefined,
            alert: undefined,
            me: undefined,
            modal: ''
        }
    }

    componentDidMount() {
        this.getOrders()
        this.ordersTimer = setInterval(this.getOrders, 60 * 1000 * 10)
        this.getRelease()
        this.getAccount()
        this.getAlert()
        this.setState({ pageLoaded: true })
    }

    componentWillUnmount() {
        clearInterval(this.ordersTimer)
    }

    getAlert() {
        axios.get('/systemalert').then(res => {
            this.setState({
                alert: res.data
            })
        })
        axios.get('/accountUser/me').then(res => {
            this.setState({
                me: res.data.AccountUserData
            })
        })
    }

    acknowldgeAlert() {
        axios.put('/systemalert/' + this.state.alert.id + '/acknowledge').then(this.getAlert)
    }

    viewAlert() {
        const modal = <UILIB.Modal width="900px">
            <div dangerouslySetInnerHTML={{ __html: this.state.alert.body }}></div>
            <div className="quickFlex mar-t20" style={{ justifyContent: 'center', alignItems: 'center' }}>
                <UILIB.Button className="button-md button-secondary" onClick={() => this.setState({ modal: '' })}>Close</UILIB.Button>
            </div>
        </UILIB.Modal>
        this.setState({ modal })
    }

    getAccount() {
        axios.get('/accountMaster').then(res => {
            this.setState({ account: res.data.AccountMaster })
        })
    }

    doUserQuestions() {
        var theDrawer = <NewUserForm closeNewUserForm={this.closeNewUserForm} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, false, "100vw", 'full'));
    }

    getOrders() {
        this.getExpiredApps()
        if (!this.props.siteMaster.hidePricing) {
            axios.get('/accountMaster/payments/unpaid?ignoreLog=true').then(response => {
                this.setState({
                    unpaid: response.data.Orders
                })
            })
        }
    }

    getExpiredApps() {
        if (this.props.user.userData.roleId !== 1) return

        axios.get('/accountMaster/application/disabled?ignoreLog=true').then(response => {
            this.setState({
                disabledApps: response.data.Applications
            })
        })
    }

    promoClose() {

        var options = this.props.accountMaster.accountMaster.options;
        if (!options) options = {};
        options.dismissedVoucher = this.state.currVoucherCode;
        this.setState({ dismissedBanner: false })
        axios.put("/accountMaster", { accountName: this.props.accountMaster.accountMaster.accountName, options: options })
    }

    copyToClipboard(code) {
        this.setState({
            alertOpen: true,
            alertMessage: 'Code copied to clipboard'
        })
        copyClipboard(code)
    }

    resend() {
        axios.put('/accountMaster/resend').then(() => {
            // sent
            this.setState({
                alertOpen: true,
                alertMessage: 'Email Sent'
            })
        }).catch(err => {
            let error = "Token already used"
            if (err && err.data && err.data.message) {
                error = err.data.message;
            }
            if (error == "Rate limit exceeded") error = "Please try again in 30 seconds"

            this.setState({
                alertOpen: true,
                alertMessage: error
            })
            // show error
        })
    }

    reconnect(accApp) {
        var drawer = <AddApp history={this.props.history} reconnect={true} appId={accApp.ApplicationId} browse={url => {
            this.props.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, false));
            if (url.indexOf('http') === 0) {
                window.open(url, '_self')
            } else {
                this.props.history.push(url)
            }
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", drawer, false, '100vw', 'full'));
    }

    deleteApp(accApp) {
        var theDrawer = <UILIB.DrawerConfirm header={'Are you sure you want to disconnect from ' + accApp.Application.appName + '?'} showCancel={true} confirm={() => {
            axios.delete('/accountMaster/application/' + accApp.id).then(this.getExpiredApps)
        }} />
        this.props.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, false));
    }

    getRelease() {
        axios.get('/release').then(res => {
            this.setState({ releases: res.data })
        })
    }

    acknowldgeRelease(release, goto) {
        axios.put('/release/' + release.id + '/acknowledge').then(() => {
            this.getRelease()
            if (goto) {
                this.props.history.push(goto)
            }
        })

    }

    render() {
        if (this.state.pageLoaded == false) return <div></div>

        var account = this.props.accountMaster.accountMaster;
        var user = this.props.user.userData
        var percentageLeft = (100 / account.maxUniqueSends) * account.remainingEmails;
        let percentageLeftMessage = i18n.t('dashboard:bannerMessages.subscriberLimitNearlyReached') + ` (${account.remainingEmails} left)`
        if (account.billedOnContacts) {

            let deletedContactsPct = 100 - ((100 / account.maxUniqueSends) * account.totalDeletedSubscribers);
            if (deletedContactsPct < 10) {
                let remainingDeletes = account.maxUniqueSends - account.totalDeletedSubscribers;
                percentageLeft = deletedContactsPct;
                if (deletedContactsPct <= 0) {
                    percentageLeftMessage = `You've deleted more contacts than your contact limit. Please upgrade your account.`;
                }
                else {
                    percentageLeftMessage = `You've nearly deleted more contacts than your contact limit. (${remainingDeletes} contacts left)`;
                }
            }

            let contactsPct = 100 - ((100 / account.maxUniqueSends) * account.totalActiveSubscribers);
            if (contactsPct < 10) {
                let remainingContacts = account.maxUniqueSends - account.totalActiveSubscribers;
                percentageLeft = contactsPct;
                if (contactsPct <= 0) {
                    percentageLeftMessage = `You've reached your contact limit. Please upgrade your account.`;
                }
                else {
                    percentageLeftMessage = `You've nearly reached your contact limit. (${remainingContacts} contacts left)`;
                }
            }

        }

        if (isNaN(percentageLeft)) percentageLeft = 100;

        var credits = -1

        if (account.billingFrequency === 2) {
            // credits
            credits = account.credits
            percentageLeft = 100
        }


        var promoBanner = false;
        if (this.props.siteMaster.siteId != 1 || this.state.dismissedBanner == true || (this.props.accountMaster.accountMaster.options && this.props.accountMaster.accountMaster.options.dismissedVoucher && this.props.accountMaster.accountMaster.options.dismissedVoucher == this.state.currVoucherCode) || (this.props.accountMaster.accountMaster.accountType && this.props.accountMaster.accountMaster.accountType != "free")) {
            promoBanner = false;
        }


        var message = '';
        var cta = '';
        var classes = ""
        var icon = "alert"

        var showBilling = true;
        if (this.props.siteMaster.hidePricing) showBilling = false;
        if (this.props.accountMaster.accountMaster.invoice) showBilling = false

        if (percentageLeft <= 10) {
            message = percentageLeftMessage
            if (PermissionChecker('company', this.props.permissionStore.permissions, "write") && showBilling)
                cta = <UILIB.Button className="button-small button-opaque" onClick={() => this.props.history.push('/cp/company/subscription')}>{i18n.t('dashboard:bannerMessages.upgrade')}</UILIB.Button>
            else
                message += ' - ' + i18n.t('dashboard:bannerMessages.contactAccountAdministratorToUpgrade');

            if (account.accountType === 'paid' && percentageLeft > 0) {
                icon = ""
                classes = " nav-banner-tight"
            } else {
                classes = " paper-orange"

            }
        }

        if (account.billingFrequency === 2 && credits <= 100) {
            message = 'Email Credits Almost Expended (' + NumberFunctions.formatNumber(credits) + ' left)';
            if (PermissionChecker('company', this.props.permissionStore.permissions, "write") && !account.invoice)
                cta = <UILIB.Button className="button-small button-opaque" onClick={() => this.props.history.push('/cp/company/subscription')}>Buy More</UILIB.Button>
            else
                message += ' - ' + i18n.t('dashboard:bannerMessages.contactAccountAdministratorToUpgrade');
            classes = " paper-orange"
        }

        if (account.hasOwnProperty('approved') && !account.approved && account.doneNewUserQuestions) {
            message = i18n.t('dashboard:bannerMessages.awaitingApproval');
            if (this.state.account && this.state.account.options && this.state.account.options.questionnaireDate && moment(this.state.account.options.questionnaireDate) < moment().subtract(1, 'day')) {
                cta = this.props.siteMaster.supportEmail ? <UILIB.Button className="button-small button-opaque" iconRight={<UILIB.Icons icon="envelope" />} href={"mailto:" + this.props.siteMaster.supportEmail + '?subject=Request Account Approval: ' + this.props.accountMaster.accountMaster.accountName}>Contact Support</UILIB.Button> : '';
            }
            classes = " paper-primary";
        }

        if (account.testingMode) {
            message = i18n.t('dashboard:bannerMessages.testingMode') // 'Testing Mode Enabled - emails won\'t actually be sent even if they look like they did'
            cta = ''
            classes = ' paper-primary'
        }

        // if (account.hasOwnProperty('doneNewUserQuestions') && !account.doneNewUserQuestions) {
        //     message = <div>{i18n.t('dashboard:bannerMessages.awaitingActivation')}</div>;
        //     cta = <UILIB.Button className="button-small button-opaque" onClick={this.doUserQuestions} iconRight={<UILIB.Icons icon="arrowRight" />}>{i18n.t('dashboard:bannerMessages.activateYourAccount')}</UILIB.Button>
        //     classes = " paper-orange"
        // }

        if (user.hasOwnProperty('registered') && !user.registered) {
            message = i18n.t('dashboard:bannerMessages.awaitingConfirmation');
            cta = <UILIB.Button className="button-small button-opaque" onClick={this.resend} iconRight={<UILIB.Icons icon="envelope" />}>{i18n.t('dashboard:bannerMessages.resend')}</UILIB.Button>;
            classes = " paper-primary"
        }

        var uiVesion = this.props.siteMaster.uiVersion
        if (this.props.accountMaster.accountMaster.version && this.props.accountMaster.accountMaster.version > uiVesion)
            uiVesion = this.props.accountMaster.accountMaster.version

        if (SiteVars.uiVersion < uiVesion) {
            message = i18n.t('dashboard:bannerMessages.newVersionAvailable');
            message = message.replace(/\[SITENAME\]/g, this.props.siteMaster.siteName);
            message = message.replace(/\[VERSION\]/g, uiVesion);
            message = <span dangerouslySetInnerHTML={{ __html: message }} />
            cta = <UILIB.Button className="button-small button-opaque" onClick={() => { window.open('/', '_self') }}>{i18n.t('dashboard:bannerMessages.getNewVersion')}</UILIB.Button>;
            classes = " paper-primary"
        }

        if (this.props.siteMaster.siteId === 1 && this.state.releases.length && this.state.releases[0].build > this.props.user.userData.version) {
            const release = this.state.releases[0]
            classes = " paper-primary"
            icon = "sparkle"
            message = `Transpond has updated ${release.version}${release.title ? ` - ${release.title}` : ''}`
            cta = <div className='quickFlex'>
                <UILIB.Button text="Find out more" iconRight={<UILIB.Icons icon="arrowRight" />} className="mar-r10 button-sml button-lighten" onClick={() => this.acknowldgeRelease(release, '/cp/releases/' + release.id)} />
                <UILIB.Button text={<UILIB.Icons icon="cross" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />} className="button-sml button-lighten" onClick={() => this.acknowldgeRelease(release)} />
            </div>
        }

        if (this.state.alert && this.state.me && this.state.alert.id > this.state.me.systemAlertSeen) {
            classes = " paper-primary"
            icon = "alert"
            if (this.state.alert.level === 0) {
                classes = " paper-secondary"
                icon = "wave"
            }
            if (this.state.alert.level === 2) {
                classes = " paper-red"
                icon = "alert"
            }
            message = this.state.alert.message
            if (this.state.alert.ctaMessage && this.state.alert.ctaLink) {
                cta = <div className='quickFlex'>
                    <UILIB.Button text={this.state.alert.ctaMessage} iconRight={<UILIB.Icons icon="arrowRight" />} className="mar-r10 button-sml button-lighten" onClick={() => window.open(this.state.alert.ctaLink, 'blank')} />
                    <UILIB.Button text={<UILIB.Icons icon="cross" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />} className="button-sml button-lighten" onClick={this.acknowldgeAlert} />
                </div>
            } else if (this.state.alert.body) {
                cta = <div className='quickFlex'>
                    <UILIB.Button text={this.state.alert.ctaMessage || "View Notes"} iconRight={<UILIB.Icons icon="arrowRight" />} className="mar-r10 button-sml button-lighten" onClick={this.viewAlert.bind(this)} />
                    <UILIB.Button text={<UILIB.Icons icon="cross" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />} className="button-sml button-lighten" onClick={this.acknowldgeAlert} />
                </div>
            } else {
                cta = <div className='quickFlex'>
                    <UILIB.Button text={<UILIB.Icons icon="cross" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />} className="button-sml button-lighten" onClick={this.acknowldgeAlert} />
                </div>
            }
        }

        if (account.hasOwnProperty('deny') && account.deny && account.denyReason) {
            message = i18n.t('dashboard:bannerMessages.accountBlocked') + ' - ' + account.denyReason;
            classes = " paper-red"
            switch (account.denyReason.toLowerCase()) {
                case "failed to take payments":
                    message = <span dangerouslySetInnerHTML={{
                        __html: i18n.t('dashboard:bannerMessages.accountPausedPaymentFailed', {
                            linkClick: '/cp/company/payments'
                        })
                    }
                    }></span >;
                    cta = <UILIB.Button className="button-small button-opaque" onClick={() => { this.props.history.push('/cp/company/payments') }}>{i18n.t('dashboard:bannerMessages.viewPayments')}</UILIB.Button>
                    break;

                default:
                    cta = <UILIB.Button href={"mailto:" + this.props.siteMaster.supportEmail + '?subject=Retry Approval For ' + account.accountName} iconRight={<UILIB.Icons icon="envelope" style={{ height: 20, width: 20 }} />} className="button-small button-opaque">Retry Approval</UILIB.Button>
                    break;
            }
        } else if (this.state.unpaid.length) {
            message = `You have ${this.state.unpaid.length} unpaid invoices, your Account will be blocked if you do not settle your account`
            cta = <UILIB.Button className="button-small button-opaque" iconRight={<UILIB.Icons icon="arrowRight" />} onClick={() => { this.props.history.push('/cp/company/payments') }}>View Invoices</UILIB.Button>
            classes = " paper-red"
        } else if (account.paused) {
            message = `Your account has been paused, you cannot send campaigns until resolved: ${account.pausedReason}`
            classes = " paper-orange"
        } else if (this.state.disabledApps.length) {
            var app = this.state.disabledApps[0]
            message = `Your ${app.Application.appName} integration has encountered a problem`
            cta = <div className="quickFlex end-xs forceFlex">
                <UILIB.Button onClick={() => this.reconnect(app)} className="button button-small button-opaque" iconRight={<UILIB.Icons icon="arrowRight" />} >Reconnect</UILIB.Button>
                <UILIB.Button onClick={() => this.deleteApp(app)} className="button button-small mar-l10 button-opaque" iconRight={<UILIB.Icons icon="cross" />} >Delete</UILIB.Button>
            </div>
            classes = " paper-red"
        }

        if (cta != "" || message != "") {
            return <Fragment>
                {!!this.state.modal && this.state.modal}
                <div className={"nav-banner quickFlex" + classes} style={{ justifyContent: "space-between", alignItems: "center" }}>
                    <div className="quickFlex" style={{ alignItems: "center" }}>
                        {!!icon && <UILIB.Icons icon={icon} style={{ height: 20, width: 20 }} className="mar-r10 hide-xs" />}
                        <div style={{ fontWeight: 'bold' }}>{message}</div>
                    </div>
                    <div>
                        {cta}
                    </div>
                </div>

                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
            </Fragment >
        }

        if (promoBanner) {
            return <div className="nav-banner quickFlex paper-primary" style={{ justifyContent: "space-between", alignItems: "center" }}>

                <div className="quickFlex" style={{ alignItems: "center" }}>
                    {/* <p className="text-white text-md text-heavy no-marg m-mar-b15">Here's a little welcome gift! Get 25% off your first month by Simply entering in this code at checkout!</p> */}
                    <p className="text-white text-md text-heavy no-marg m-mar-b15">Flash Deal! 50% off all sending plans. Limited time only! Enter this code when <a style={{ color: '#FFFFFF', textDecoration: "underline" }} onClick={() => this.props.history.push('/cp/company/subscription')}>upgrading</a>...</p>
                    <UILIB.Button className="button-small button-opaque" style={{ marginLeft: 20 }} onClick={() => this.copyToClipboard(this.state.currVoucherCode)} iconRight={<UILIB.Icons icon="copyClipboard" />}>{this.state.currVoucherCode}</UILIB.Button>
                </div>
                <div className="exit">
                    <UILIB.Button className="button-small button-opaque mar-r10" onClick={() => this.props.history.push('/cp/company/subscription')} iconRight={<UILIB.Icons icon="arrowRight" />}>Upgrade</UILIB.Button>
                    <UILIB.Button className="button-small button-opaque mar-r10" onClick={this.promoClose} iconRight={<UILIB.Icons icon="cross" />}>Clear</UILIB.Button>
                </div>
                <UILIB.SnackBar message={this.state.alertMessage} open={this.state.alertOpen} autoclose={true} dismiss={() => this.setState({ alertOpen: false })} />
            </div>
        }

        return <div></div>
    }
};



import React from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib'
import DbConn from '~/Classes/siteVars.js'
import i18n from '~/i18n'
import { I18n } from 'react-i18next';
import queryString from 'query-string';
import LeftPanel from './loginAttractMode.jsx';

@connect((store) => {
    return {
        siteMaster: store.siteMaster
    }
})
export default class ResetTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { password: '', repeatPassword: '', email: '', guid: '', success: false, strength: 0, strongEnough: true };
        this.handleChange = this.handleChange.bind(this);
        this.send = this.send.bind(this);
        this.checkStrength = this.checkStrength.bind(this);
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        var email = query.email;
        var guid = query.guid;

        this.setState({
            email, guid
        })
    }

    handleChange(event) {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        }, this.checkStrength);
    }

    checkStrength() {
        var password = this.state.password;
        var numberCheck = /\d+/g;
        var textCheck = /\D/g;
        var upperCheck = /[A-Z]/;
        var lowerCheck = /[a-z]/;
        var strength = 0;

        if (password.length > 8) strength += 1
        if (numberCheck.test(password)) strength += 1
        if (textCheck.test(password)) strength += 1
        if (upperCheck.test(password)) strength += 1
        if (lowerCheck.test(password)) strength += 1

        var error = ''

        if (strength >= 5 && this.state.repeatPassword) {
            if (password !== this.state.repeatPassword) {
                error = <UILIB.FormError>{i18n.t('login:reset.noMatch')}</UILIB.FormError>
            }
        }



        this.setState({
            strength,
            strongEnough: strength >= 5,
            masterErrorMsg: error
        })
    }

    send() {
        if (!this.state.password) {
            return this.setState({
                masterErrorMsg: <UILIB.FormError>{i18n.t('login:reset.required')}</UILIB.FormError>
            })
        }
        if (!this.state.repeatPassword || this.state.password !== this.state.repeatPassword) {
            return this.setState({
                masterErrorMsg: <UILIB.FormError>{i18n.t('login:reset.noMatch')}</UILIB.FormError>
            })
        }

        var self = this;
        var baseUrl = DbConn.getConnString()
        axios.post(baseUrl + '/resetpassword',
            {
                emailAddress: this.state.email,
                token: this.state.guid,
                password: this.state.password
            })
            .then(function (response) {
                var respData = response.data;
                if (respData.success === false) {
                    self.setState({ masterErrorMsg: <UILIB.FormError>{response.data.message}</UILIB.FormError> });
                }
                if (respData.success === true) {
                    self.setState({ masterErrorMsg: <div className="mar-t10">Your password has been reset! Continue to login...</div>, success: true });
                }
            }).catch(error => {
                console.log(error)
                let message = 'Something went wrong'
                if (error && error.response && error.response.data && error.response.data.error) {
                    message = error.response.data.error
                }
                if (error && error.response && error.response.data && error.response.data.message) {
                    message = error.response.data.message
                }
                self.setState({ masterErrorMsg: <UILIB.FormError>{message}</UILIB.FormError> });
            });
    }

    render() {


        return (<I18n ns="translations">
            {
                (t, { i18n }) => (
                    <div className="login-holder">

                        {(this.props.siteMaster.siteName === "MPZMail" || this.props.siteMaster.siteName === "Transpond") &&
                            <LeftPanel history={this.props.history} />
                        }
                        <div className="login-right-panel">
                            <div className="login-right-panel-login">
                                <UILIB.Paper className="login-right-panel-login_loginBox">

                                    <h3 className="mar-b15">{i18n.t('login:reset.reset')} <span>{this.props.siteMaster.siteName}</span> {i18n.t('login:reminder.header2')}</h3>
                                    <div className="mar-b40">{i18n.t('login:reset.new')}</div>

                                    <UILIB.TextInput
                                        outerClassName="mar-b24"
                                        type="password"
                                        name="password"
                                        placeholder={i18n.t('login:reset.password')}
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleChange}
                                        error={!this.state.strongEnough ? i18n.t('login:reset.strong') : ''}
                                        maxLength="255" />

                                    <UILIB.TextInput
                                        type="password"
                                        name="repeatPassword"
                                        placeholder={i18n.t('login:reset.repeat')}
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleChange}
                                        outerClassName="mar-b24"
                                        maxLength="255"
                                    />

                                    {this.state.masterErrorMsg}

                                    {!this.state.success && <UILIB.Button className="button-primary" text={i18n.t('login:reset.button')} onClick={this.send} />}
                                    {this.state.success && <UILIB.Button className="button-primary" text={i18n.t('login:reset.login')} onClick={() => this.props.history.push('/')} />}

                                </UILIB.Paper>
                            </div >
                        </div >
                    </div >
                )
            }
        </I18n>)


    }
};
import React, { Component } from 'react';
import UILIB from '../../../../../../../../Common-Objects/Lib';
import thestore from '~/data/store/store'
import FileManager from '~/pages/cp/includes/fileManager/fileManager'
import * as siteMaster from '~/data/actions/siteActions'; //now we can use user actions here
import MergeTags from '~/pages/cp/includes/mergeTags/mergeTags'
import DragDropFunctions from '../../../dragDropFunctions'
import axios from '~/data/http/axios';
import ComponentTagManager from './tagManager';

export default class LinkPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            linkUrl: "",
            linkUrlErr: "",
            linkType: 0,
            emailMessage: "",
            emailSubject: "",
            formData: [],
            selectedTags: [],
            formAllowData: false,
            anchorsDetected: [],
            anchorWarning: ""
        }

        this.updateVal = this.updateVal.bind(this);
        this.save = this.save.bind(this);
        this.openFilePicker = this.openFilePicker.bind(this);
        this.insertMergeTag = this.insertMergeTag.bind(this);
        this.getForms = this.getForms.bind(this);
        this.findAnchors = this.findAnchors.bind(this);
    }

    componentDidMount() {
        this.findAnchors();
        if (this.props.currVal) {
            var theVal = this.props.currVal;
            var anchorWarning = "";
            var emailMessage = "";
            var emailSubject = "";
            var formAllowData = false;
            var ourState = thestore.getState();
            var ourAccMasterId = 0;
            if (ourState.accountMaster && ourState.accountMaster.id) {
                ourAccMasterId = ourState.accountMaster.id
            }
            var linkType = 0;

            if (theVal.toLowerCase().indexOf("mailto:") === 0) {
                theVal = theVal.replace(/mailto\:/g, '')

                if (theVal.indexOf('?') > 0) {
                    var tmpDat1 = theVal.split('?')
                    var theVal = tmpDat1[0];
                    var tmpDat = tmpDat1[1];
                    var emailData = [tmpDat]
                    if (tmpDat.indexOf('&') > 0) {
                        emailData = tmpDat.split('&')
                    }
                    emailData.forEach(tLine => {
                        if (tLine.indexOf('=') > 0) {
                            var tDat = tLine.split('=')
                            if (tDat[0] == "subject") {
                                emailSubject = decodeURIComponent(tDat[1])
                            }
                            if (tDat[0] == "body") {
                                emailMessage = decodeURIComponent(tDat[1]);
                            }
                        }
                    })
                }
                linkType = 1;
            }
            if (theVal.toLowerCase().indexOf(`/usercontent/${ourAccMasterId}`) > -1) {
                linkType = 2;
            }
            if (theVal.toLowerCase().indexOf('/form?am=') > -1) {
                linkType = 3;
                if (theVal.indexOf("&key={{__SENDKEY}}") > -1) {
                    formAllowData = true;
                }
            }
            if (theVal.indexOf('tel:') === 0) {
                linkType = 4
            }
            if (theVal.indexOf('#') === 0) {
                linkType = 5;
                let foundAnchor = this.state.anchorsDetected.find(an => an.value == theVal);
                if (!foundAnchor) {
                    anchorWarning = `Previously selected anchor "${theVal}" no longer exists in your template. Please select another Anchor.`
                }
                if (!this.state.anchorsDetected.length) {
                    linkType = 0;
                    anchorWarning = "";
                    theVal = "";
                }
            }
            this.setState({ linkUrl: theVal, linkType, emailMessage, emailSubject, formAllowData, anchorWarning }, () => {
                if (linkType == 3) {
                    this.getForms(true)
                }
            })
        }
    }

    save() {
        var errored = false;
        var errMessage = "";
        var thisLink = this.state.linkUrl;
        if (this.state.linkType == "0" && thisLink.length) {
            if (thisLink.indexOf("http://") < 0 && thisLink.indexOf("https://") < 0 && thisLink.indexOf("@") > -1) {
                thisLink = "mailto:" + thisLink;
            }
            else if (thisLink.indexOf("http://") == -1 && thisLink.indexOf("https://") == -1 && thisLink.indexOf("}}") == -1) {
                thisLink = "http://" + thisLink;
            }
            if (thisLink.indexOf(".") == -1 && thisLink.indexOf("}}") == -1) {
                errored = true;
                errMessage = "Please enter a valid URL";
            }
        }
        if (this.state.linkType == "1" && thisLink.length) {
            if (thisLink.indexOf("mailto:") == -1 && thisLink.indexOf("}}") == -1) {
                thisLink = "mailto:" + thisLink;
            }
            if (thisLink.indexOf("@") == -1 && thisLink.indexOf("}}") == -1) {
                errored = true;
                errMessage = "Please enter a valid email address";
            }
            if (!errored) {
                if (this.state.emailSubject && this.state.emailSubject.length) {
                    var delim = '?';
                    if (thisLink.indexOf('?') > 0) delim = '&';
                    var emailSubj = encodeURIComponent(this.state.emailSubject)
                    thisLink += `${delim}subject=${emailSubj}`;
                }
                if (this.state.emailMessage && this.state.emailMessage.length) {
                    var delim = '?';
                    if (thisLink.indexOf('?') > 0) delim = '&';
                    var emailMsg = encodeURIComponent(this.state.emailMessage)
                    thisLink += `${delim}body=${emailMsg}`
                }
            }
        }
        if (this.state.linkType == "2" && thisLink.length) {
            if (thisLink.length < 3) {
                errored = true;
                errMessage = "Please select a file";
            }

        }


        if (this.state.linkType == "4" && thisLink.length) {
            if (thisLink.indexOf('tel:') === -1) {
                thisLink = 'tel:' + thisLink
            }
        }

        if (errored) {
            this.setState({ linkUrlErr: errMessage })
            return;
        }
        thisLink = thisLink.trim();

        let openType = "_blank";
        if (this.state.linkType == 5) {
            openType = "_self";
        }

        this.props.save(thisLink, openType, "")
    }

    getForms(updateState) {
        var self = this;
        return axios.get('/group/0/form').then(resp => {
            if (updateState) {
                self.setState({ formData: resp.data.Forms.filter(f => f.formType == 0) })
                return;
            }
            return { type: "form", data: resp.data.Forms.filter(f => f.formType == 0) }
        })
    }
    updateVal(ev) {
        var self = this;
        var state = this.state;
        var promises = [];
        var targetValue = ev.target.value;
        state.anchorWarning = "";
        if (ev.target.name == "linkType") {
            state.linkUrl = "";
            state.linkUrlErr = "";
            state.formAllowData = false;
            if (targetValue == 3 || targetValue == "3") {
                promises.push(this.getForms())
            }
        }
        if (ev.target.name == "formAllowData") {
            if (this.state.formAllowData) {
                targetValue = false;
            }
            else {
                targetValue = true;
            }
        }

        if (ev.target.name == "formAllowData" || (ev.target.name == "linkUrl" && state.linkType == 3)) {
            if (ev.target.name == "formAllowData") {
                if (targetValue == true) {
                    state.linkUrl += "&key={{__SENDKEY}}"
                }
                else {
                    state.linkUrl = state.linkUrl.replace("&key={{__SENDKEY}}", "");
                }
            }
            if (ev.target.name == "linkUrl") {
                if (state.formAllowData) {
                    targetValue += "&key={{__SENDKEY}}"
                }
                else {
                    targetValue = targetValue.replace("&key={{__SENDKEY}}", "");
                }
            }
        }

        state[ev.target.name] = targetValue;



        if (promises.length) {
            Promise.all(promises).then(_resp => {
                if (_resp && _resp[0] && _resp[0].type == "form") {

                    state.formData = _resp[0].data
                }
                self.setState(state)
            })
        } else {
            this.setState(state)
        }



    }

    openFilePicker() {
        var self = this;
        this.props.toggleHidePickers(true);
        var theDrawer = <FileManager onFileClick={(theFile) => {

            self.setState({ linkUrl: theFile.url }, () => {
                thestore.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true, null));
                setTimeout(() => {
                    self.props.toggleHidePickers(false);
                }, 500);
            });

        }} />
        thestore.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, '100vw', undefined, () => {
            setTimeout(() => {
                self.props.toggleHidePickers(false);
            }, 500);
        }));
    }

    insertMergeTag(field) {
        var self = this;
        var state = this.state;
        this.props.toggleHidePickers(true);
        var theDrawer = <MergeTags mergeTags={DragDropFunctions.mergeTags} onTagClick={(theTag) => {
            if (state[field] && state[field].length) {
                state[field] = state[field] + " " + theTag;
            }
            else {
                state[field] = theTag;
            }
            self.setState(state)
            thestore.dispatch(siteMaster.alterSiteDrawer(false, true, "right", null, true, null));
            setTimeout(() => {
                self.props.toggleHidePickers(false);
            }, 500);
        }} />
        thestore.dispatch(siteMaster.alterSiteDrawer(true, true, "right", theDrawer, true, undefined, undefined, (event) => {
            setTimeout(() => {
                self.props.toggleHidePickers(false);
            }, 500);
        }));
    }

    findAnchors() {
        let anchorsDetected = DragDropFunctions.findAllAnchors(this.props.elementsData)
        this.setState({ anchorsDetected })

    }

    render() {

        var fileNameD = "";
        var fileNameDat = this.state.linkUrl.split("/");
        if (fileNameDat.length) {
            fileNameD = fileNameDat[fileNameDat.length - 1];
        }

        let linkTypes = [
            { label: "Website Link", value: 0 },
            { label: "Email Address", value: 1 },
            { label: "Telephone Number", value: 4 },
            { label: "File", value: 2 },
            { label: "Link to Form", value: 3 }
        ]

        if (this.state.anchorsDetected.length) {
            linkTypes.push({
                label: "Link to an Anchor",
                value: 5
            })
        }

        return <div className="mpzLinkPicker" onClick={(ev) => { ev.preventDefault(); ev.stopPropagation(); }}>

            <UILIB.Select outerClassName="mar-b20" label="Link Type" name="linkType" value={this.state.linkType} onChange={this.updateVal} data={linkTypes} />


            {this.state.linkType == 0 && <div className="mar-b20">
                <UILIB.TextInput label="Website Address" value={this.state.linkUrl} name="linkUrl" onChange={this.updateVal} error={this.state.linkUrlErr} placeholder="https://" />
                <div className="text-xsml mar-t10 mar-b10 text-right"><a onClick={() => { this.insertMergeTag('linkUrl') }}>Insert Merge Tag</a></div>
                <ComponentTagManager value={this.state.linkUrl} title="Link Tags" />
            </div>}


            {this.state.linkType == 4 && <div className="mar-b20">
                <UILIB.TextInput label="Telephone Number" value={this.state.linkUrl} name="linkUrl" onChange={this.updateVal} error={this.state.linkUrlErr} placeholder="tel:" />
            </div>}

            {this.state.linkType == 1 && <div>
                <UILIB.TextInput outerClassName="mar-b20" label="Email Address" value={this.state.linkUrl} name="linkUrl" placeholder="Email Address" onChange={this.updateVal} error={this.state.linkUrlErr} />

                <div className="mar-b20">
                    <UILIB.TextInput label="Email Subject (Optional)" value={this.state.emailSubject} name="emailSubject" placeholder="Email Subject" onChange={this.updateVal} />
                    <div className="text-right mar-t10 text-xsml"><a onClick={() => { this.insertMergeTag('emailSubject') }}>Insert Merge Tag</a></div>
                </div>

                <div className="mar-b20">
                    <UILIB.TextInput label="Email Message (Optional)" value={this.state.emailMessage} name="emailMessage" placeholder="Email Message" onChange={this.updateVal} />
                    <div className="text-right mar-t10 text-xsml"><a onClick={() => { this.insertMergeTag('emailMessage') }} >Insert Merge Tag</a></div>
                </div>
            </div>}

            {this.state.linkType == 2 && <div>
                <div className="txtInput_label">File URL:</div>
                {(this.state.linkUrl && this.state.linkUrl.length > 0) && <div className="txtInput mar-b15">{fileNameD}</div>}
                {(this.state.linkUrlErr && this.state.linkUrlErr.length > 0) && <UILIB.WarningHolder className="mar-b15">{this.state.linkUrlErr}</UILIB.WarningHolder>}
                <UILIB.Button text="Pick a File" className="button-primary mar-b20" onClick={this.openFilePicker} />
            </div>}

            {this.state.linkType == 3 && <div>
                <div>
                    <div className="txtInput_label">Form to Link To:</div>

                    {(this.state.formData.length > 0) && <UILIB.Select outerClassName="mar-b20" placeholder="Please select a Form" data={this.state.formData.map(frm => {
                        return { label: frm.formName + (frm.Group ? frm.Group.groupName ? ' (For group ' + frm.Group.groupName + ')' : '' : ''), value: `https://api.ourtransactionalemails.com/form?am=${frm.AccountMasterId}&fid=${frm.id}&host=true` }
                    })} value={this.state.linkUrl.replace('&key={{__SENDKEY}}', '')} name="linkUrl" onChange={this.updateVal} error={this.state.linkUrlErr} />}

                    {(!this.state.formData.length) && <UILIB.WarningHolder className="mar-b20">
                        <div>You have not added any forms yet</div>
                        <a onClick={() => { window.open('/cp/forms', '_blank') }}>Add a Form</a>
                    </UILIB.WarningHolder>}


                </div>
                {(this.state.linkUrl && this.state.linkUrl.length) && <div>
                    <UILIB.Select label="Pre-populate fields with contacts existing details, if sent from a campaign or automation" outerClassName="mar-b20" data={[{ label: "Yes", value: true }, { label: "No", value: false }]} value={this.state.formAllowData} name="formAllowData" onChange={this.updateVal} />
                </div>}
            </div>}

            {this.state.linkType == 5 && <div>
                <div className="txtInput_label">Anchor:</div>
                <UILIB.Select
                    name="linkUrl"
                    placeholder="Pick an Anchor"
                    data={this.state.anchorsDetected}
                    value={this.state.linkUrl}
                    onChange={this.updateVal}
                    className="mar-b25" />
                {this.state.anchorWarning && <UILIB.WarningHolder className="mar-b20">
                    {this.state.anchorWarning}
                </UILIB.WarningHolder>}
            </div>}

            <UILIB.Button className="button-primary mar-r5" text="Save Link" onClick={this.save} />
            <UILIB.Button className="" text="Forget It" onClick={this.props.close} />

        </div>
    }
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "~/data/http/axios";
import UILIB from "~/Common-Objects/Lib";
import Button from "../../components/button";
import * as styles from "./index.module.scss";

import DateTimeFunctions from "~/Classes/dateTimeFunctions";
import { useHistory } from "react-router-dom";
import HistoryGraph from "./historyGraph";
import Header from "../../components/header";
import i18n from "~/i18n";

export default function AiAssistantArticles({ inbox, assistant, statusDropDown }) {

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [historyData, setHistoryData] = useState([]);
  const [historyCount, setHistoryCount] = useState(0);
  const [articlesCount, setArticlesCount] = useState(0);

  useEffect(() => {
    getStats()
  }, []);



  const getStats = async () => {
    const articlesCount = (await axios.get(`/inboxes/${inbox.id}/ai/${assistant.id}/embeddings/total`)).data
    const history = (await axios.get(`/inboxes/${inbox.id}/ai/${assistant.id}/history`)).data
    setHistoryCount(history.count)
    setArticlesCount(articlesCount.total)
    setHistoryData(history.rows)
    setLoading(false);
    generateTableData()
  }


  const tableData = historyData.map((history) => {
    const { createdAt, Subscriber, success } = history;

    return {
      timestamp: {
        headerValue: i18n.t("chat:ai.overview.timestamp"),
        headerStyle: { fontWeight: "normal" },
        value: DateTimeFunctions.formatDateTime(createdAt),
      },
      email: {
        headerValue: i18n.t("chat:ai.overview.email"),
        headerStyle: { fontWeight: "normal" },
        value: Subscriber?.emailAddress ? <Link to={'/cp/subscriber/' + Subscriber.id}>{Subscriber?.emailAddress}</Link> : i18n.t("chat:ai.overview.unknown")
      },
      success: {
        headerValue: i18n.t("chat:ai.history.customerSatisfied"),
        headerStyle: { fontWeight: "normal" },
        value: (
          <UILIB.Badge color={success ? "teal" : "grey"}>
            {success ? i18n.t("chat:ai.overview.yes") : i18n.t("chat:ai.overview.no")}
          </UILIB.Badge>
        ),
        align: "center"
      }

    };
  });

  return <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 inbox_settings_left_content" style={{ paddingBottom: 80 }}>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1 className={"h2 mar-r10"}>{assistant.name}</h1>
        {assistant.status == 3 && <UILIB.Badge size="sm" color={"teal"}>{i18n.t("chat:ai.overview.status.active")}</UILIB.Badge>}
        {assistant.status == 2 && <UILIB.Badge color={"grey"}>{i18n.t("chat:ai.overview.status.inactive")}</UILIB.Badge>}
        {assistant.status == 1 && <UILIB.Badge color={"orange"}>{i18n.t("chat:ai.overview.status.building")}</UILIB.Badge>}
        {assistant.status == 0 && <UILIB.Badge color={"grey"}>{i18n.t("chat:ai.overview.status.draft")}</UILIB.Badge>}
      </div>
      <div>
        {statusDropDown}
      </div>
    </div>
    <UILIB.Row>
      <UILIB.Column xs={12} md={6} className="mar-b20">
        <div className={styles.teaser}>
          <div>
            <h2 className={styles.heading}>{i18n.t("chat:ai.overview.responses")}</h2>
            <div>{i18n.t("chat:ai.overview.responsesDesc")}</div>
          </div>
          <div className={styles.teaserLower}>
            <div className={styles.teaserStat}>{historyCount}</div>
            <div>

              <Button
                variant="secondary"
                size="s"
                iconRight={<UILIB.Icon name="arrow-right" />}
                onClick={() => { history.push(`/cp/conversations/${inbox.id}/ai-assistants/${assistant.id}/history`) }}
              >
                {i18n.t("chat:ai.overview.responsesButton")}
              </Button>

            </div>
          </div>
        </div>
      </UILIB.Column>
      <UILIB.Column xs={12} md={6} className="mar-b20">
        <div className={styles.teaser}>
          <div>
            <h2 className={styles.heading}>{i18n.t("chat:ai.overview.articles")}</h2>
            <div>{i18n.t("chat:ai.overview.articlesDesc")}</div>
          </div>
          <div className={styles.teaserLower}>
            <div className={styles.teaserStat}>{articlesCount}</div>
            <div>

              <Button
                variant="secondary"
                size="s"
                iconRight={<UILIB.Icon name="arrow-right" />}
                onClick={() => { history.push(`/cp/conversations/${inbox.id}/ai-assistants/${assistant.id}/articles`) }}
              >  {i18n.t("chat:ai.overview.articlesButton")}
              </Button>

            </div>
          </div>
        </div>
      </UILIB.Column>
      <UILIB.Column xs={12} className="mar-b40">
        <HistoryGraph inbox={inbox} assistant={assistant} />
      </UILIB.Column>
      <UILIB.Column xs={12}>
        <h2 className={styles.heading}>{i18n.t("chat:ai.overview.latest")}</h2>
        <UILIB.DataTable1 tableData={tableData} pageSize={10} />
      </UILIB.Column>
    </UILIB.Row>
  </div>

}

import React, { Component } from 'react';
import DateTimeFunctions from '~/Classes/dateTimeFunctions';
import AutomationFunctions from '../../automationFunctions';

class PipedriveOption extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        var pipedrive = this.props.pipedrive
        if (!pipedrive) {
            return <div id={this.props.id} className="automationOption" onClick={() => this.props.editStep(this.props.step)}>
                Pipedrive no longer connected
            </div>
        }
        var classes = ' green'
        var outerStyle = {}
        if (!this.props.step.stepOptions.triggerType || this.props.step.stepOptions.triggerType.value == "0") {
            var field = pipedrive.fields.find(f => f.key === this.props.step.stepOptions.field.value)
            var fieldStr = field && <div>Update <strong>{field.name}</strong></div>

            var valueStr = ''
            var ready = true

            function getType(type) {
                switch (type) {
                    case 'enum':
                    case 'set':
                        return 'select';
                    case 'double':
                    case 'monetary':
                    case 'int':
                        return 'number';
                    case 'date':
                        return 'date'
                    default:
                        return 'text'
                }
            }
            var value = this.props.step.stepOptions.value.value
            var type = field ? getType(field.field_type) : ''
            if (!field) {
                classes = ' incomplete'
                fieldStr = <div>Define Pipedrive Action</div>
                ready = false
            }
            if (type === 'date') {
                if (moment(value).isValid()) {
                    valueStr = <div>to <strong>{DateTimeFunctions.formatDateTime(value)}</strong></div>
                } else {
                    valueStr = <div>to the date of the action</div>
                }
            } else if (type === 'select') {
                var option = field.options.find(o => o.id == value)
                if (option) {
                    valueStr = <div>to <strong>{option.label}</strong></div>
                }
            } else if (type === 'number') {
                if (value === 'increment') {
                    fieldStr = <div><strong>Increment {field.name}</strong> by <strong>one</strong></div>
                } else if (value === 'decrement') {
                    fieldStr = <div><strong>Decrement {field.name}</strong> by <strong>one</strong></div>
                } else {
                    valueStr = <div>to <strong>{value}</strong></div>
                }
            } else {

                var workflowEmails = AutomationFunctions.getWorkflowEmails(this.props.step.guid, this.props.steps);
                var campaignStep = workflowEmails.find(c => c.stepOptions.campaign.id == this.props.step.stepOptions.campaignId)
                var campaign
                if (campaignStep) {
                    campaign = campaignStep.stepOptions.campaign
                }
                switch (value) {
                    case 'campaignName':
                        if (!campaign) {
                            valueStr = <div>Please Select a Campaign</div>
                        } else {
                            valueStr = <div>to <strong>{campaign ? campaign.campaignName : 'the campaign name'}</strong></div>
                        }
                        break;

                    case 'subject':
                        if (!campaign) {
                            valueStr = <div>Please Select a Campaign</div>
                        } else {
                            valueStr = <div>to <strong>{campaign ? campaign.subject : 'the campaign subject'}</strong></div>
                        }
                        break;

                    case 'fromName':
                        if (!campaign) {
                            valueStr = <div>Please Select a Campaign</div>
                        } else {
                            valueStr = <div>to <strong>{campaign ? campaign.fromName : 'the campaign from name'}</strong></div>
                        }
                        break;

                    case 'fromAddress':
                        if (!campaign) {
                            valueStr = <div>Please Select a Campaign</div>
                        } else {
                            valueStr = <div>to <strong>{campaign ? campaign.fromAddress : 'the campaign from address'}</strong></div>
                        }
                        break;

                    case 'replyName':
                        if (!campaign) {
                            valueStr = <div>Please Select a Campaign</div>
                        } else {
                            valueStr = <div>to <strong>{campaign ? campaign.replyName : 'the campaign reply name'}</strong></div>
                        }
                        break;

                    case 'replyAddress':
                        if (!campaign) {
                            valueStr = <div>Please Select a Campaign</div>
                        } else {
                            valueStr = <div>to <strong>{campaign ? campaign.replyAddress : 'the campaign reply address'}</strong></div>
                        }
                        break;

                    case 'link':
                        if (!campaign) {
                            valueStr = <div>Please Select a Campaign</div>
                        } else {
                            valueStr = <div>to <strong>the link they clicked</strong></div>
                        }
                        break;
                    default:
                        if (ready) valueStr = <div>to <strong>{value}</strong></div>
                        break;
                }
            }
            return <div id={this.props.id} className={"automationOption" + classes} onClick={() => this.props.editStep(this.props.step)} style={this.props.style || outerStyle}>
                {this.props.statsQueuedHolder}
                <img src={pipedrive.Application.appLogoUri} height="40" alt="" />
                {fieldStr}
                {valueStr}
                {this.props.statsProcessedHolder}
                {!this.props.isRunning && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep() }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
            </div>
        }
        if (this.props.step.stepOptions.triggerType.value == "1") {
            var failed = false;
            if (this.props.step.stepOptions.activity && this.props.step.stepOptions.activity.subject && (!this.props.step.stepOptions.activity.subject.value || this.props.step.stepOptions.activity.subject.value.length < 3)) {
                failed = true;
                classes = ' incomplete';
            }
            if (this.props.step.stepOptions.activity && this.props.step.stepOptions.activity.type && (!this.props.step.stepOptions.activity.type.value)) {
                failed = true;
                classes = ' incomplete';
            }
            return <div id={this.props.id} className={"automationOption " + classes} onClick={() => this.props.editStep(this.props.step)} style={this.props.style || outerStyle}>
                {this.props.statsQueuedHolder}
                <img src={pipedrive.Application.appLogoUri} height="40" alt="" />
                {failed && <div>Define Pipedrive Action</div>}
                {!failed && <div>Create Pipedrive <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>{this.props.step.stepOptions.activity.type.value}</span> Activity with subject <span style={{ fontWeight: "bold" }}>{this.props.step.stepOptions.activity.subject.value}</span></div>}
                {this.props.statsProcessedHolder}
                {!this.props.isRunning && <div className="automationRemoveStep" onClick={(event) => { event.stopPropagation(); this.props.removeStep() }}><UILIB.Icons icon="bin" color="#DD4124" style={{ width: 16, height: 16 }} /></div>}
            </div>
        }
    }
}

export default PipedriveOption;
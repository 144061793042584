// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LMybYTDQx7uJ0zYvyyhC label{font-size:13px;font-weight:500;margin-bottom:4px}.LMybYTDQx7uJ0zYvyyhC .QHhyXJk4vzrBjJJxFF1I{color:var(--grey-800);margin-bottom:24px}.LMybYTDQx7uJ0zYvyyhC .txtInput{padding:8.5px 10px;border-color:var(--grey-300)}", "",{"version":3,"sources":["webpack://./src/pages/cp/conversations/components/textInput/index.module.scss"],"names":[],"mappings":"AACE,4BACE,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,4CACE,qBAAA,CACA,kBAAA,CAGF,gCACE,kBAAA,CACA,4BAAA","sourcesContent":[".root {\n  label {\n    font-size: 13px;\n    font-weight: 500;\n    margin-bottom: 4px;\n  }\n\n  .description {\n    color: var(--grey-800);\n    margin-bottom: 24px;\n  }\n\n  :global(.txtInput) {\n    padding: 8.5px 10px;\n    border-color: var(--grey-300);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LMybYTDQx7uJ0zYvyyhC",
	"description": "QHhyXJk4vzrBjJJxFF1I"
};
export default ___CSS_LOADER_EXPORT___;

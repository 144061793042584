import React from 'react'

export default function IconShieldTick({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";

    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.166 4.99897C5.06114 4.96297 7.84481 3.87743 10 1.94397C12.155 3.87779 14.9387 4.9637 17.834 4.99997C17.944 5.64997 18 6.31997 18 7.00097C18 12.226 14.66 16.671 10 18.318C5.34 16.67 2 12.225 2 6.99997C2 6.31797 2.057 5.64997 2.166 4.99897ZM13.707 8.70697C13.8892 8.51837 13.99 8.26577 13.9877 8.00357C13.9854 7.74137 13.8802 7.49056 13.6948 7.30515C13.5094 7.11974 13.2586 7.01457 12.9964 7.0123C12.7342 7.01002 12.4816 7.11081 12.293 7.29297L9 10.586L7.707 9.29297C7.5184 9.11081 7.2658 9.01002 7.0036 9.0123C6.7414 9.01457 6.49059 9.11974 6.30518 9.30515C6.11977 9.49056 6.0146 9.74137 6.01233 10.0036C6.01005 10.2658 6.11084 10.5184 6.293 10.707L8.293 12.707C8.48053 12.8944 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8944 9.707 12.707L13.707 8.70697Z" fill={fillColor} />
        </svg>
    )
}
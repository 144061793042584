import React from 'react'

export default function IconsTemplate({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (

        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 4.00006C3 3.73484 3.10536 3.48049 3.29289 3.29295C3.48043 3.10542 3.73478 3.00006 4 3.00006H16C16.2652 3.00006 16.5196 3.10542 16.7071 3.29295C16.8946 3.48049 17 3.73484 17 4.00006V6.00006C17 6.26528 16.8946 6.51963 16.7071 6.70717C16.5196 6.8947 16.2652 7.00006 16 7.00006H4C3.73478 7.00006 3.48043 6.8947 3.29289 6.70717C3.10536 6.51963 3 6.26528 3 6.00006V4.00006ZM3 10.0001C3 9.73484 3.10536 9.48049 3.29289 9.29295C3.48043 9.10542 3.73478 9.00006 4 9.00006H10C10.2652 9.00006 10.5196 9.10542 10.7071 9.29295C10.8946 9.48049 11 9.73484 11 10.0001V16.0001C11 16.2653 10.8946 16.5196 10.7071 16.7072C10.5196 16.8947 10.2652 17.0001 10 17.0001H4C3.73478 17.0001 3.48043 16.8947 3.29289 16.7072C3.10536 16.5196 3 16.2653 3 16.0001V10.0001ZM14 9.00006C13.7348 9.00006 13.4804 9.10542 13.2929 9.29295C13.1054 9.48049 13 9.73484 13 10.0001V16.0001C13 16.2653 13.1054 16.5196 13.2929 16.7072C13.4804 16.8947 13.7348 17.0001 14 17.0001H16C16.2652 17.0001 16.5196 16.8947 16.7071 16.7072C16.8946 16.5196 17 16.2653 17 16.0001V10.0001C17 9.73484 16.8946 9.48049 16.7071 9.29295C16.5196 9.10542 16.2652 9.00006 16 9.00006H14Z" fill={fillColor} />
        </svg>


    )
}
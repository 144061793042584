import React from 'react';

export default class IconsClicked extends React.Component {


    render() {
        let color = "#7246B1";
        if (this.props.color) color = this.props.color;
        let className = "icons-icon";

        return <svg className={className} style={this.props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 7.5L11.6667 17.5L13.1667 13.1667L17.5 11.6667L7.5 7.5Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 1.83331L6.66667 4.24998" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.25004 6.66667L1.83337 6" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.6667 3.41669L10 5.00002" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.99996 10L3.41663 11.6667" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    }
}

import React, { Component } from 'react'

import DragDropFunctions from '../../../dragDropFunctions';
import { connect } from 'react-redux';
import UILIB from '~/Common-Objects/Lib';
var needsRefresh = false;
import SiteVars from '~/Classes/siteVars';

//CONNECT TO STORE
@connect((store) => {
    return { user: store.user, accountMaster: store.accountMaster }
})

export default class Img extends Component {
    constructor(props) {
        super(props)

        this.timer;
        this.observer;
        this.saveTimer;
        this.needsRefresh = true;
        this.generatingImage = false;

        this.state = {
            imageWidth: 0,
            randomImageId: Math.floor((Math.random() * 999999999) + 1),
            imageId: Math.floor((Math.random() * 999999999) + 1),
            holderId: Math.floor((Math.random() * 999999999) + 1),
            isAutoHeight: false,
            tooHigh: false
        }
        this.onClick = this.onClick.bind(this);
        this.handleImageChanged = this.handleImageChanged.bind(this);
        this.updatePropsSize = this.updatePropsSize.bind(this);
        this.checkImageSize = this.checkImageSize.bind(this);

    }

    componentWillReceiveProps(nextProps) {
    }
    componentDidMount() {
        this.unmounting = false;
        try {
            this.observer = new ResizeObserver((event) => { this.handleImageChanged("resize") });
            this.observer.observe(document.getElementById("img_" + this.props.tuuid.tuuid));
            this.pObserver = new MutationObserver((event) => { this.handleImageChanged("mutate") });
            this.pObserver.observe(document.getElementById("img_" + this.props.tuuid.tuuid), { attributes: true });
        }
        catch (err) {
            console.log(err)
        }
    }

    componentWillUnmount() {
        this.unmounting = true;
        if (this.timer) clearTimeout(this.timer);
        try {
            this.observer.disconnect();
            this.pObserver.disconnect();
        }
        catch (err) { }
    }

    checkImageSize() {
        var tooHigh = this.state.tooHigh;
        var theImg = this.refs[this.props.tuuid.tuuid];
        if (theImg && theImg.offsetHeight && !isNaN(theImg.offsetHeight) && theImg.offsetHeight > 1500) {
            tooHigh = true;
        }
        else {
            tooHigh = false;
        }
        if (tooHigh != this.state.tooHigh) {
            this.setState({ tooHigh })
        }
    }
    handleImageChanged(eventType, forced) {

        var savedHeight = false;
        var savedWidth = false;
        var self = this;
        this.checkImageSize();

        var ev = document.getElementById("img_" + self.props.tuuid.tuuid)
        if (ev) {
            var newHeight = String(ev.offsetHeight);
            var newWidth = String(ev.offsetWidth);
            if (this.props.localVariables.imageHeight && this.props.localVariables.imageHeight.value && this.props.localVariables.imageHeight.value == "auto") {
                newHeight = "auto";
            }
            this.updatePropsSize(newWidth, newHeight);
        }

        clearTimeout(self.timer);
        self.timer = setTimeout(() => {
            if (this.unmounting) return

            if (!this.props.tuuid) return;
            if (this.props.localVariables && this.props.localVariables.imageHeight && this.props.localVariables.imageHeight.value) {
                savedHeight = this.props.localVariables.imageHeight.value.replace('px', '').replace('%', '');;
            }
            if (this.props.localVariables && this.props.localVariables.imageWidth && this.props.localVariables.imageWidth.value) {
                savedWidth = this.props.localVariables.imageWidth.value.replace('px', '').replace('%', '');;
            }
            if (!self.props.templateId) {
                return;
            }
            if (!self.props.localVariables || !self.props.localVariables.imageAutoResized) {
                return;
            }
            if (!self.props.localVariables || !self.props.localVariables.imageSrc || !self.props.localVariables.imageSrc.value || self.props.localVariables.imageSrc.value.indexOf('REPOENDPOINT') > -1) {
                return;
            }

            if (this.props.localVariables && this.props.localVariables.autoResizeImage) {
                var fUri = this.props.localVariables.imageSrc.value;
                if (this.props.localVariables.autoResizeImage.value == false || this.props.localVariables.autoResizeImage.value == "false") {
                    if (fUri.indexOf("_mpi_") > -1) {
                        var spDat = fUri.split("_mpi_");
                        fUri = spDat[0] + spDat[spDat.length - 1];
                        this.props.elementData.props.src.value = fUri
                        this.props.localVariables.imageSrc.value = fUri;
                        this.setState({
                            randomImageId: Math.floor((Math.random() * 999999999) + 1)
                        })

                    }
                    return;
                }
                if (fUri.indexOf("/dynamicimage?") > -1) {
                    return;
                }
            }
            if (savedHeight == "auto" && !this.state.isAutoHeight) {
                if (fUri.indexOf("_mpi_") > -1) {
                    var spDat = fUri.split("_mpi_");
                    fUri = spDat[0] + spDat[spDat.length - 1];
                    this.props.elementData.props.src.value = fUri
                    this.props.localVariables.imageSrc.value = fUri;
                }
                this.setState({ isAutoHeight: true }, () => { clearTimeout(self.timer); self.timer = setTimeout(() => { this.handleImageChanged("forced") }), 2000 })
                return;
            }
            if (savedHeight != "auto" && this.state.isAutoHeight) {
                this.setState({ isAutoHeight: false })
            }

            var ev = document.getElementById("img_" + self.props.tuuid.tuuid)
            if (!ev) return;
            var newWidth = String(ev.offsetWidth);
            var newHeight = String(ev.offsetHeight);
            if (!isNaN(newWidth)) newWidth = Number(newWidth);
            if (!isNaN(newHeight)) newHeight = Number(newHeight);


            var origImageUri = self.props.localVariables.imageSrc.value
            var urlWidth = 0;
            var urlHeight = 0;
            if (origImageUri.indexOf("_mpi_") > 0) {
                var tDat = origImageUri.split("_mpi_");
                origImageUri = tDat[0] + tDat[tDat.length - 1];
                var savedDimensions = tDat[1].split('_');
                urlWidth = Number(savedDimensions[1]);
                urlHeight = Number(savedDimensions[0]);
            }

            if (urlHeight != newHeight || urlWidth != newWidth) {
                if (!isNaN(savedHeight) && newHeight <= 0) newHeight = savedHeight;
                if (!isNaN(savedWidth) && newWidth <= 0) newWidth = savedWidth;
                if (newHeight > 3000) newHeight = 3000;
                if (newWidth > 3000) newWidth = 3000;

                self.props.updateImage({ tuuid: self.props.tuuid.tuuid, imageUri: origImageUri, imageHeight: newHeight, imageWidth: newWidth, imgPath: self.props.path });

            }
        }, 2000);

    }

    updatePropsSize(newWidth, newHeight) {
        this.props.elementData.props.width.finalValue = newWidth;
        if (!this.props.elementData.props.style) this.props.elementData.props.style = {};
        if (!this.props.elementData.props.style["max-width"]) this.props.elementData.props.style["max-width"] = {};
        if (!this.props.elementData.props.style["min-width"]) this.props.elementData.props.style["min-width"] = {};
        this.props.elementData.props.style["max-width"].finalValue = this.props.elementData.props.width.value;
        this.props.elementData.props.style["min-width"].finalValue = this.props.elementData.props.width.value;
        this.props.elementData.props.style["min-width"].value = "";
        this.props.elementData.props.style["max-width"].value = "";
        if (this.props.localVariables.imageHeight) {
            this.props.localVariables.imageHeight.finalValue = newHeight + (newHeight == "auto" ? "" : "px");

        }
        if (this.props.elementData.props.height) {
            this.props.elementData.props.height.finalValue = newHeight;
        }
    }


    onClick(event) {
        //event.preventDefault();
    }

    render() {

        var eleProps;
        var isEditable = false;
        var isSelected = false;

        var thisPath = this.props.elementData.props.src.value;
        if (this.props.selectedElement.isSelected && this.props.selectedElement.path == this.props.currentParentPath) {
            isSelected = true;
        }

        if (this.props.elementData.props.src.editable) {
            isEditable = true;
        }
        if (this.props.elementData.props) {

            eleProps = DragDropFunctions.generatePropsForStage(this.props.elementData.props, this.props.globalVariables, "img", this.props.localVariables)
        }

        var fileUrl = eleProps.src;

        if (fileUrl == "{{__COMPANYLOGO}}") {
            fileUrl = SiteVars.repoEndPoint + this.props.accountMaster.accountMaster.companyLogo;
            isEditable = false;
        }
        if (this.needsRefresh == true) {
            this.needsRefresh = false;
            fileUrl += "?rnd=" + Math.round(Math.random() * 99999999);
        }

        var dHeight = "";
        if (eleProps && eleProps.style && eleProps.style.height) {
            dHeight = eleProps.style.height;
        }

        var imageReloading = false;
        if (this.props.tuuid && this.props.imageReloading) imageReloading = true;
        this.handleImageChanged();

        var tuuid = 0;
        if (this.props.tuuid && this.props.tuuid.tuuid) tuuid = this.props.tuuid.tuuid;



        return <div>

            <div style={{ position: "relative" }}>

                <div style={{ opacity: imageReloading ? "0.5" : "1" }}>
                    <img id={"img_" + tuuid} {...eleProps} src={fileUrl} onClick={this.onClick} ref={tuuid} onLoad={this.handleImageChanged} />
                </div>
                {this.state.tooHigh && <div style={{ width: "100%", position: "absolute", top: "0px", left: "0px", textAlign: "center", border: "1px dotted red", backgroundColor: "white", padding: "6px", fontSize: "12px", color: "red" }}>WARNING: Images over 1600px high may not display in some Email Clients</div>}
                {imageReloading && <div style={{ position: "absolute", left: "0", right: "0", top: "0", bottom: "0" }}><UILIB.LoadingIcons iconType="2" /></div>}
            </div>
        </div >


    }
}
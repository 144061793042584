import React from 'react'
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import {
    CartesianGrid, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart
} from 'recharts';
import moment from 'moment';

@connect((store) => {
    return { siteMaster: store.siteMaster, user: store.user }
})
export default class GroupAnalyticsNewSubscribers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            subscribersJoined: [],
            startDate: moment(),
            endDate: moment()
        };

        this.getSubscribersJoined = this.getSubscribersJoined.bind(this);
    }


    componentDidMount() {
        this.setState({ startDate: this.props.startDate, endDate: this.props.endDate }, this.getSubscribersJoined)
    }

    componentDidUpdate(prevProps, prevState, snapShot) {

        if (prevProps.startDate.format() != this.props.startDate.format() || prevProps.endDate.format() != this.props.endDate.format()) {
            this.setState({ ready: false, startDate: this.props.startDate, endDate: this.props.endDate }, this.getSubscribersJoined);
        }
    }

    getSubscribersJoined() {
        var self = this;
        axios.get(`/group/${this.props.groupId}/subscriber/addedgraph?startDate=${this.state.startDate.format("YYYY-MM-DD")}&endDate=${this.state.endDate.format("YYYY-MM-DD")}`)
            .then(response => {
                self.setState({
                    subscribersJoined: response.data.Stats,
                    ready: true
                })
            })
            .catch(console.log)
    }

    render() {

        if (this.state.ready) {
            return <ResponsiveContainer height={300}>
                <AreaChart data={this.state.subscribersJoined} margin={{ top: 10, right: 0, left: 20, bottom: 0 }}>
                    <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                    <YAxis axisLine={false} hide={true} tickLine={false} width={20} padding={{ bottom: 15 }} tick={{ fontSize: 10, fill: '#999', fontWeight: 600 }} />
                    <defs>
                        <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={this.props.siteMaster.colours.$primary} stopOpacity={0.3} />
                            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                        </linearGradient>
                    </defs>
                    <Tooltip content={<UILIB.CustomTooltip name={"Contacts"} />} />
                    <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={this.props.siteMaster.colours.$primary} stopOpacity={0.3} />
                        <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                    </linearGradient>
                    <CartesianGrid horizontal={false} strokeDasharray="2 2" stroke="#DADADC" />
                    <Area type="monotone"
                        dot={false}
                        name="Contacts"
                        dataKey="subscribers" strokeWidth="0"
                        stroke={this.props.siteMaster.colours.$primary}
                        strokeWidth={2}
                        fillOpacity={1}
                        fill="url(#colorUv1)"
                        activeDot={{ stroke: this.props.siteMaster.colours.$primary, strokeWidth: 2, fill: 'white' }}
                    />
                </AreaChart>
            </ResponsiveContainer>

        } else {
            return <div><UILIB.LoadingIcons iconType="2" /></div>
        }
    };
};
import React from 'react'

export default function IconsStack({ color = "", style = {} }) {
    let fillColor = "#7246B1";
    if (color) fillColor = color;
    let className = "icons-icon";
    return (
        <svg className={className} style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 3.00006C6.73478 3.00006 6.48043 3.10542 6.29289 3.29295C6.10536 3.48049 6 3.73484 6 4.00006C6 4.26528 6.10536 4.51963 6.29289 4.70717C6.48043 4.8947 6.73478 5.00006 7 5.00006H13C13.2652 5.00006 13.5196 4.8947 13.7071 4.70717C13.8946 4.51963 14 4.26528 14 4.00006C14 3.73484 13.8946 3.48049 13.7071 3.29295C13.5196 3.10542 13.2652 3.00006 13 3.00006H7ZM4 7.00006C4 6.73484 4.10536 6.48049 4.29289 6.29295C4.48043 6.10542 4.73478 6.00006 5 6.00006H15C15.2652 6.00006 15.5196 6.10542 15.7071 6.29295C15.8946 6.48049 16 6.73484 16 7.00006C16 7.26528 15.8946 7.51963 15.7071 7.70717C15.5196 7.8947 15.2652 8.00006 15 8.00006H5C4.73478 8.00006 4.48043 7.8947 4.29289 7.70717C4.10536 7.51963 4 7.26528 4 7.00006ZM2 11.0001C2 10.4696 2.21071 9.96092 2.58579 9.58585C2.96086 9.21077 3.46957 9.00006 4 9.00006H16C16.5304 9.00006 17.0391 9.21077 17.4142 9.58585C17.7893 9.96092 18 10.4696 18 11.0001V15.0001C18 15.5305 17.7893 16.0392 17.4142 16.4143C17.0391 16.7893 16.5304 17.0001 16 17.0001H4C3.46957 17.0001 2.96086 16.7893 2.58579 16.4143C2.21071 16.0392 2 15.5305 2 15.0001V11.0001Z" fill={fillColor} />
        </svg>
    )
}
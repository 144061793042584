import React from 'react';
import axios from '~/data/http/axios';
import UILIB from '~/Common-Objects/Lib'
import { connect } from 'react-redux';
import i18n from '~/i18n';

export default class CloseAccountDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            done: false,
            loading: false
        };

        this.inviteSupport = this.inviteSupport.bind(this);
    }

    componentDidMount() {

    }

    async inviteSupport() {

        try {
            this.setState({ loading: true })
            await axios.put("/accountmaster/invitesupport")
            this.props.finish()
            this.setState({ done: true, loading: false })
        }
        catch (err) {
            this.setState({ loading: false })
        }
    }

    render() {
        if (this.state.done) return <div>
            <h4 className="mar-b25">Support Invited</h4>
            <p>The support team can now access your account. This access will be revoked automatically after 24 hours.</p>
        </div>

        return <div>
            <h4 className="mar-b25">Invite Support</h4>
            <div className="mar-b15">If a support member has asked for access to your account to help resolve a support request, please click the following button.</div>
            <div className="mar-b25">This will allow the support team to access your account for up to 24 hours</div>

            <UILIB.Button text="Invite Support" className="button-md" onClick={this.inviteSupport} />

        </div >

    };
};

import React, { useState, useEffect } from 'react'
import axios from '../../../../../../data/http/axios'
import UILIB from '../../../../../../Common-Objects/Lib'

const pageSize = 10

export default function MailchimpImages() {
    const [folders, setFolders] = useState(null)
    const [selectedFolder, setSelectedFolder] = useState(0)
    const [files, setFiles] = useState(null)
    const [importing, setImporting] = useState(false)
    const [alert, setAlert] = useState({ open: false, message: '' })
    const [page, setPage] = useState(0)

    const toggleCheckbox = (id) => {
        const _files = [...files]
        const index = _files.findIndex(f => f.id === id)
        if (index !== -1) _files[index].checked = !_files[index].checked
        setFiles(_files)
    }

    const checkAll = () => {
        const _files = [...files]
        if (_files.some(f => f.checked)) {
            _files.forEach(f => f.checked = false)
        } else {
            _files.forEach(f => f.checked = true)
        }
        setFiles(_files)
    }

    const importFiles = async () => {
        setAlert({ open: true, message: 'Importing Files' })
        setImporting(true)
        await axios.post('/application/mailchimp/files', {
            files: files.filter(f => f.folder_id == selectedFolder && f.checked)
        })
        setAlert({ open: true, message: 'Imported Files' })
        setImporting(false)
        checkAll()
    }

    useEffect(() => {
        axios.get('/application/mailchimp/folders').then(res => {
            setFolders(res.data)
        })
        axios.get('/application/mailchimp/files').then(res => {
            setFiles(res.data.files)
        })
    }, [])

    useEffect(() => {
        setPage(0)
    }, [selectedFolder])

    if (!folders) return <UILIB.LoadingIcons iconType="2" />

    const noFolder = files ? files.filter(f => f.folder_id === 0) : []

    const all = files ? files.filter(f => f.folder_id == selectedFolder) : []
    const filtered = all.slice(pageSize * page, (pageSize * page) + pageSize).map(f => {
        const isGif = f.thumbnail_url.indexOf('.gif') === f.thumbnail_url.length - 4
        return {
            checkbox: {
                headerValue: <UILIB.CheckBox checked={all.every(f => f.checked)} onChange={checkAll} />,
                isCheckBox: true,
                width: 30,
                value: <UILIB.CheckBox checked={f.checked} onChange={() => toggleCheckbox(f.id)} />
            },
            image: {
                headerValue: ' ',
                value: <div style={{
                    height: 50,
                    backgroundImage: 'url(' + (isGif ? f.full_size_url : f.thumbnail_url) + ')',
                    backgroundPosition: 'top',
                    backgroundSize: 'cover'
                }}></div>
            },
            name: {
                headerValue: 'Name',
                value: <a href={f.full_size_url} target="blank" alt={f.name}>{f.name}</a>
            }
        }
    })

    const selected = all.filter(a => a.checked)

    const totalRows = Math.ceil(all / pageSize)

    return (
        <div>
            <UILIB.Paper>
                <div className="row bottom-xs">
                    <div className="col-xs-6 mar-b25">
                        <UILIB.Select label="Folder" data={[{ name: 'No Folder', id: 0, file_count: noFolder.length }].concat(folders).map(f => ({ label: `${f.name} (${f.file_count})`, value: f.id }))} value={selectedFolder} onChange={e => setSelectedFolder(e.currentTarget.value)} />
                    </div>
                    <div className="col-xs-6 end-xs">
                        <UILIB.Button
                            text={importing ? `Importing ${selected.length} Files` : selected.length ? 'Import ' + selected.length + ' Files' : 'Select Files to Import'}
                            className="mar-b30 button-md" disabled={importing || !selected.length} onClick={importFiles} />
                    </div>
                </div>

                <UILIB.DataTable1 tableData={filtered} />

                <div className='text-center mar-t40'>
                    <UILIB.PagingBlock totalRows={totalRows} currentPage={page} totalRows={all.length} pageSize={pageSize} changePage={p => setPage(parseInt(p))} />
                </div>
            </UILIB.Paper>

            <UILIB.SnackBar message={alert.message} open={alert.open} autoclose={true} dismiss={() => setAlert({ open: false, message: '' })} />
        </div>
    )
}